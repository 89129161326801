var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-detail-root" },
    [
      _c("el-form", { ref: "refForm", attrs: { model: _vm.formData } }, [
        _vm.formData.gridList && _vm.formData.gridList.length > 0
          ? _c(
              "div",
              {
                directives: [{ name: "masonry", rawName: "v-masonry" }],
                key: _vm.formData.key,
                staticClass: "v-grid-list",
                attrs: {
                  "item-selector": ".block-item",
                  "transition-duration": "0.3s",
                  gutter: "24",
                },
              },
              _vm._l(
                _vm.formData.gridList ? _vm.formData.gridList : [],
                function (bigItem, bigIndex) {
                  return _c(
                    "div",
                    { key: bigIndex, staticClass: "block-item" },
                    [
                      _c("div", { staticClass: "top-title" }, [
                        _c("div", { staticClass: "full-title" }, [
                          _c("div", { staticClass: "big-title" }, [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: bigItem.isMust,
                                    expression: "bigItem.isMust",
                                  },
                                ],
                                staticClass: "weight",
                              },
                              [_vm._v("*")]
                            ),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v(_vm._s(bigItem.title)),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "btn-layout" },
                            [
                              bigItem.template
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDownTemplate(
                                            bigItem.template
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("下载模板")]
                                  )
                                : _vm._e(),
                              bigItem.example
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleLookSample(
                                            bigItem.example
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("查看示例 ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "remarks" }, [
                          _vm._v(
                            " " + _vm._s(bigItem.extObj.description) + " "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "content-layout" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "gridList." + bigIndex + ".imageUrlList",
                                rules: [
                                  {
                                    required: bigItem.isMust,
                                    message: "请上传" + bigItem.title,
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pic-layout" },
                                [
                                  _vm._l(
                                    bigItem.imageUrlList
                                      ? bigItem.imageUrlList
                                      : [],
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "item" },
                                        [
                                          _c("el-image", {
                                            ref: `myImgB${bigIndex}S${index}`,
                                            refInFor: true,
                                            staticClass: "icon",
                                            attrs: {
                                              src: item,
                                              fit: "cover",
                                              "initial-index": index,
                                              "preview-src-list":
                                                bigItem.imageUrlList,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "preview" },
                                            [
                                              _c("el-image", {
                                                staticClass: "btn-icon",
                                                attrs: { src: _vm.SeeIcon },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handlePreview(
                                                      `myImgB${bigIndex}S${index}`
                                                    )
                                                  },
                                                },
                                              }),
                                              !_vm.disabled
                                                ? _c("el-image", {
                                                    staticClass: "btn-icon",
                                                    attrs: { src: _vm.DelIcon },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleDeleteImage(
                                                          bigIndex,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  !_vm.hideAddPicBtn(bigItem)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "item add-pic",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleUpload(bigIndex)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "image-wrap" },
                                            [
                                              _c("el-image", {
                                                attrs: { src: _vm.AddIcon },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("span", { staticClass: "text" }, [
                                            _vm._v("上传照片"),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                          bigItem.filedList && bigItem.filedList.length > 0
                            ? _c(
                                "div",
                                { staticClass: "input-layout" },
                                _vm._l(
                                  bigItem.filedList,
                                  function (subItem, subIndex) {
                                    return _c(
                                      "div",
                                      {
                                        key: subIndex,
                                        class: {
                                          "input-item": true,
                                          "fill-wrap":
                                            bigItem.filedList.length % 2 !==
                                              0 &&
                                            subIndex ===
                                              bigItem.filedList.length - 1,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "gridList." +
                                                bigIndex +
                                                ".filedList." +
                                                subIndex +
                                                ".modeValue",
                                              rules: [
                                                {
                                                  required: subItem.isMust,
                                                  message: subItem.tip
                                                    ? subItem.tip + "不能为空"
                                                    : "值不能为空",
                                                  trigger: "blur",
                                                },
                                              ],
                                            },
                                          },
                                          [
                                            _c("DynamicFiled", {
                                              attrs: {
                                                type: subItem.type,
                                                disabled: _vm.disabled,
                                                "data-item": subItem,
                                              },
                                              on: {
                                                areaChange: function ($event) {
                                                  return _vm.areaChangeGrid(
                                                    bigIndex,
                                                    subIndex
                                                  )
                                                },
                                              },
                                              model: {
                                                value: subItem.modeValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    subItem,
                                                    "modeValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "subItem.modeValue",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              0
            )
          : _vm._e(),
        _c("div", { staticClass: "bottom-dynamic-filed" }, [
          _vm.formData.filedList && _vm.formData.filedList.length > 0
            ? _c(
                "div",
                { staticClass: "input-layout" },
                _vm._l(_vm.formData.filedList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: {
                        "input-item": true,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "filedList." + index + ".modeValue",
                            rules: [
                              {
                                required: item.isMust,
                                message: item.tip
                                  ? item.tip + "不能为空"
                                  : "值不能为空",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("DynamicFiled", {
                            attrs: {
                              type: item.type,
                              disabled: _vm.disabled,
                              "data-item": item,
                            },
                            on: {
                              areaChange: function ($event) {
                                return _vm.areaChange(index)
                              },
                            },
                            model: {
                              value: item.modeValue,
                              callback: function ($$v) {
                                _vm.$set(item, "modeValue", $$v)
                              },
                              expression: "item.modeValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      !_vm.disabled
        ? _c(
            "div",
            { staticClass: "bottom-btn-layout" },
            [
              _c("el-button", { on: { click: _vm.handlePreStep } }, [
                _vm._v("上一步"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.handleSubmit } },
                [_vm._v("提 交")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("UploadImageView", {
        ref: "imgupload",
        attrs: { "request-id": "1", imgType: _vm.imgType },
        on: { upload: _vm.uploadSubmit },
      }),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: { "on-close": _vm.closeViewer, "url-list": _vm.urls },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }