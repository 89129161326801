var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "notice" }, [
    _vm._m(0),
    _c("div", { staticClass: "comContent" }, [
      _c(
        "div",
        { staticStyle: { "padding-top": "10px" } },
        [
          _c(
            "el-form",
            {
              ref: "search",
              staticStyle: { "text-align": "left" },
              attrs: { model: _vm.search, inline: true },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "title", label: "公告标题：" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入公告标题",
                      clearable: "",
                      size: "mini",
                    },
                    model: {
                      value: _vm.search.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.search, "title", $$v)
                      },
                      expression: "search.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "date", label: "公告更新时间：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      size: "small",
                      "range-separator": "-",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.searchDate,
                      callback: function ($$v) {
                        _vm.searchDate = $$v
                      },
                      expression: "searchDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.searchList(1)
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.reset("search")
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.add },
                    },
                    [_vm._v("新建公告")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("MyTable", {
            attrs: {
              "table-data": _vm.tableData,
              index: _vm.index,
              selection: _vm.selection,
              page: _vm.page,
              operation: _vm.operation,
              "table-option": _vm.tableOption,
            },
            on: {
              "update:tableOption": function ($event) {
                _vm.tableOption = $event
              },
              "update:table-option": function ($event) {
                _vm.tableOption = $event
              },
              "page-change": _vm.getList,
            },
            scopedSlots: _vm._u([
              {
                key: "type",
                fn: function (scope) {
                  return [
                    _c("el-row", [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.type === 1 ? "公告" : "质量公示") +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "status",
                fn: function (scope) {
                  return [
                    _c("el-row", [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.status ? "已发布" : "未发布") +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "menu",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "btn-warp" },
                      [
                        !scope.row.status
                          ? _c(
                              "el-link",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.release(scope.row)
                                  },
                                },
                              },
                              [_vm._v("发布")]
                            )
                          : _vm._e(),
                        scope.row.status
                          ? _c(
                              "el-link",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.offShelf(scope.row)
                                  },
                                },
                              },
                              [_vm._v("下架")]
                            )
                          : _vm._e(),
                        !scope.row.status
                          ? _c(
                              "el-link",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        !scope.row.status
                          ? _c(
                              "el-link",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.del(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                        scope.row.status
                          ? _c(
                              "el-link",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.detail(scope.row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "font" }, [_vm._v("公告设置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }