<template>
  <div>
    <el-dialog
      title="设置价格"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form size="small" class="form-centent">
        <div class="row">
          <div class="col">
            <div class="tip">当前价格</div>
            <div class="value">
              <el-input
                disabled
                :value="rowData && rowData.salePrice ? rowData.salePrice : ''"
              />
            </div>
          </div>
          <div class="col">
            <div class="tip">调整价格为</div>
            <div class="value"><el-input v-model="formIn.salePrice" /></div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="tip">当前市场价格</div>
            <div class="value">
              <el-input
                disabled
                :value="
                  rowData && rowData.marketPrice ? rowData.marketPrice : ''
                "
              />
            </div>
          </div>
          <div class="col">
            <div class="tip">调整价格为</div>
            <div class="value"><el-input v-model="formIn.marketPrice" /></div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="tip">当前零售价</div>
            <div class="value">
              <el-input
                disabled
                :value="
                  rowData && rowData.purchasePrice ? rowData.purchasePrice : ''
                "
              />
            </div>
          </div>
          <div class="col">
            <div class="tip">调整价格为</div>
            <div class="value"><el-input v-model="formIn.purchasePrice" /></div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="doUpdate()">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { commentSave } from "@/api/businessManage";
import _ from "lodash";
import { skuUpdate } from "@/api/salesControl/commodity";
import { updatePriceOrStock } from "@/api/goods";

export default {
  components: {},
  props: {
    requestId: {
      type: String,
      default: () => "",
    },

    limit: {
      type: Number,
      default: () => 10,
    },
  },
  computed: {
    headers: function () {
      return {
        Authorization: "Bearer " + getToken(),
      };
    },
  },
  data() {
    return {
      dialogVisible: false,

      rowData: null,
      formIn: {
        salePrice: "",
        marketPrice: "",
        purchasePrice: "",
      },
    };
  },
  methods: {
    doUpdate() {
      if (!this.formIn.salePrice || this.formIn.salePrice ==0) {
        this.$message.error("请输入当前价格调整价");
        return;
      }
      if (!this.formIn.marketPrice || this.formIn.marketPrice ==0) {
        this.$message.error("请输入当前市场价格调整价");
        return;
      }
      if (!this.formIn.purchasePrice || this.formIn.marketPrice ==0) {
        this.$message.error("请输入当前零售价调整价");
        return;
      }
      let data = {
        id: this.rowData.skuId,
        beSynPrice: false, //能编辑，肯定是不同步
      };
      // _.merge(data, this.rowData);
      _.merge(data, this.formIn);

      updatePriceOrStock(data).then((res) => {
        if (res) {
          this.$message.success("更新价格成功");
          this.$emit("updateSuccess");
          this.handleClose();
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    init() {
      //
    },
    clear() {
      this.rowData = null;
      for (let key in this.formIn) {
        this.formIn[key] = "";
      }
    },
    open(item) {
      this.clear();
      this.dialogVisible = true;
      this.rowData = _.cloneDeep(item);
    },
  },
};
</script>
<style scoped lang="scss">
.form-centent {
  .row {
    display: flex;

    &:not(:first-child) {
      margin-top: 24px;
    }

    .col {
      flex: 1;
      display: flex;
      flex-direction: column;

      &:nth-child(2) {
        margin-left: 24px;
      }

      .tip {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
