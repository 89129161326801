<template>
  <div class="shop-info-root tab-content new-cls">
    <el-form :rules="rules" :model="formIn" ref="shopForm">

      <el-row>
        <el-col :span="24">
          <div class="form ">
            <el-form-item prop="shopName" label="店铺名称" label-width="140px">
              <el-input size="small" placeholder="请输入店铺名称" v-model="formIn.shopName" maxlength="20"
                style="width: 220px;" />
            </el-form-item>
            <el-form-item prop="shopLogo" label="店铺LOGO" style="width: 500px;" label-width="140px">
              <div class="public-pic-layout">
                <div v-if="formIn.shopLogo" class="item">
                  <el-image :ref="`myImgLogo`" :src="formIn.shopLogo" fit="cover" class="icon" :initial-index="0"
                    :preview-src-list="[formIn.shopLogo]" />
                  <div class="preview">
                    <el-image :src="SeeIcon" class="btn-icon" @click="handlePreview(`myImgLogo`, false)" />
                    <el-image :src="DelIcon" class="btn-icon" @click="handleDeleteImage('logo')" />
                  </div>
                </div>
                <div v-else class="item add-pic" @click="handleUpload('logo')">
                  <div class="image-wrap">
                    <el-image :src="AddIcon" />
                  </div>
                  <span class="text">上传图片</span>
                </div>
              </div>
            </el-form-item>

            <el-form-item prop="shopServiceTel" label="客服电话" style="width: 500px;" label-width="140px">
              <el-input size="small" placeholder="请输入客服电话" v-model="formIn.shopServiceTel" maxlength="15"
                style="width: 220px;" />
            </el-form-item>

            <el-form-item prop="shopHeadOperations" label="运营负责人" style="width: 500px;" label-width="140px">
              <el-input size="small" placeholder="请输入运营负责人" v-model="formIn.shopHeadOperations" maxlength="15"
                style="width: 220px;" />
            </el-form-item>

            <el-form-item prop="shopContactNumber" label="运营联系电话" style="width: 500px;" label-width="140px">
              <el-input size="small" placeholder="请输入运营联系电话" v-model="formIn.shopContactNumber" maxlength="15"
                style="width: 220px;" />
            </el-form-item>

            <el-form-item label="销售政策与条款" style="width: 500px" label-width="140px" prop="orderDealTimeRule">
              <el-input type="textarea" v-model="formIn.orderDealTimeRule" maxlength="100" show-word-limit
                style="width: 440px;" resize="none" :rows="4" placeholder="请填写100字以内销售政策与条款"></el-input>
            </el-form-item>

            <el-form-item label="配送政策" style="width: 500px" label-width="140px" prop="sendRule">
              <el-input type="textarea" v-model="formIn.sendRule" maxlength="100" show-word-limit style="width: 440px;"
                resize="none" :rows="4" placeholder="请填写100字以内配送政策"></el-input>
            </el-form-item>

            <el-form-item label="企业介绍" style="width: 500px" label-width="140px" prop="shopDesc">
              <el-input type="textarea" v-model="formIn.shopDesc" maxlength="200" show-word-limit style="width: 440px;"
                resize="none" :rows="6" placeholder="请填写200字以内企业介绍"></el-input>
            </el-form-item>

            <el-form-item label="店铺公告" style="width: 500px" label-width="140px" prop="shopAnnouncement">
              <el-input type="textarea" v-model="formIn.shopAnnouncement" maxlength="200" show-word-limit
                style="width: 440px;" resize="none" :rows="6" placeholder="请填写200字以内店铺公告"></el-input>
            </el-form-item>

          </div>
        </el-col>

      </el-row>
    </el-form>

    <UploadImageView ref="imgupload" request-id="1" :imgType="imgType" @upload="uploadSubmit" />
  </div>
</template>

<script>
import UploadImageView from "@/views/myQualification/components/UploadImage.vue";

const IConst_Small = require("@/assets/public/icon-small.png");
const IConst_See_Icon = require("@/assets/zz/see-icon.png");
const IConst_Del_Icon = require("@/assets/zz/del-icon.png");
const IConst_Add_Icon = require("@/assets/zz/add-icon.png");

import { getShipInfor, updateShipInfor, shopInformationInfoNew, saveOrUpdateInformation } from "@/api/shop";

import _ from "lodash";

export default {
  components: { UploadImageView },
  data() {
    return {
      SeeIcon: IConst_See_Icon,
      DelIcon: IConst_Del_Icon,
      AddIcon: IConst_Add_Icon,

      imgType: "",
      IConst_Max_Pic_Count: 5,
      imageUrlList: [],

      shopDetail: {

      },

      formIn: {
        remark: '',//驳回原因
        auditStatus: "", //状态码
        shopId: "",
        shopName: "", //店铺名称
        shopLogo: "", //店铺logo
        shopServiceTel: "", // 客户电话
        shopCompanyName: "", //店铺公司名称
        shopCompanyAddress: "", //店铺公司地址
        shopHeadOperations: "", //店铺运营负责人
        shopContactNumber: "", //联系电话
        shopErpCode: "", //店铺erp编码
        shopExtendInfo: {}
      },
      rules: {
        shopName: [{ required: true, message: "请输入店铺名称", trigger: "blur" }],
        shopLogo: [{ required: true, message: "请输入店铺logo", trigger: "blur" }],
        shopServiceTel: [{ required: true, message: "请输入客户电话", trigger: "blur" }],
        shopCompanyName: [{ required: true, message: "请输入店铺公司名称", trigger: "blur" }],
        shopCompanyAddress: [{ required: true, message: "请输入店铺公司地址", trigger: "blur" }],
        shopHeadOperations: [{ required: true, message: "请输入店铺运营负责人", trigger: "blur" }],
        shopContactNumber: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        orderDealTimeRule: [{ required: true, message: "请输入销售政策与条款", trigger: "blur" }],
        sendRule: [{ required: true, message: "请输入配送政策", trigger: "blur" }],
        shopDesc: [{ required: true, message: "请输入企业介绍", trigger: "blur" }],
        shopAnnouncement: [{ required: true, message: "请输入店铺公告", trigger: "blur" }],
      }
    };
  },
  created() {
    this.doLoadInfo();
  },
  computed: {
    showSave() {
      return !this.formIn.auditStatus || this.formIn.auditStatus === "PRESERVE";
    },
  },
  methods: {
    doLoadInfo() {
      shopInformationInfoNew().then((data) => {
        if (data) {
          this.shopDetail = data;
          for (let key in data) {
            if (this.formIn.hasOwnProperty(key) && data[key]) {
              this.formIn[key] = data[key];
            }
          }
        }
      });
    },
    doUpdateInfo(auditStatus) {
      this.$refs['shopForm'].validate((valid) => {
        if (valid) {
          let data = {};
          _.merge(data, this.formIn);
          // if (auditStatus) {
          //   data.auditStatus = auditStatus;
          // }
          data.shopExtendInfo = this.formIn.shopExtendInfo
          saveOrUpdateInformation(data).then((res) => {
            if (res) {
              if (auditStatus === "NOT_PASS") {
                this.doLoadInfo();
                this.$message.success("提交成功");
              } else {
                this.$message.success("保存成功");
              }
            }
          });
        }
      })
      // if(!this.formIn.shopName){
      //   this.$message.warning("请填写店铺名称！");
      //   return
      // }

    },
    handleUpload(tag) {
      this.$refs.imgupload.open(tag);
    },
    uploadSubmit(obj) {
      const { type, url } = obj;
      if (type === "logo") {
        this.formIn.shopLogo = url;
      }
    },
    handlePreview(refName) {
      if (this.$refs[refName]) {
        if (this.$refs[refName] instanceof Array) {
          this.$refs[refName][0].showViewer = true;
        } else {
          this.$refs[refName].showViewer = true;
        }
      }
    },
    handleDeleteImage(tag) {
      if (tag === "logo") {
        this.formIn.shopLogo = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style/index.scss";

</style>
