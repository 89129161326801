var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c(
      "div",
      { staticClass: "headerTop" },
      [
        _c(
          "el-form",
          {
            ref: "formTool",
            staticStyle: { "text-align": "left" },
            attrs: { model: _vm.formTool, inline: true },
          },
          [
            _c(
              "el-form-item",
              [
                _c("MyButton", {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-plus",
                    text: "添加",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addOrUpdateHandle(false)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "center" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              data: _vm.menuList,
              "row-key": "id",
              "tree-props": {
                children: "children",
                hasChildren: "hasChildrens",
              },
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "name",
                label: "菜单名称",
                "show-overflow-tooltip": true,
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "type",
                label: "类型",
                width: "200",
                align: "left",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.type === "0"
                        ? _c("el-tag", { attrs: { type: "dark" } }, [
                            _vm._v("菜单"),
                          ])
                        : _vm._e(),
                      scope.row.type === "1"
                        ? _c("el-tag", { attrs: { type: "info" } }, [
                            _vm._v("按钮"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "permission",
                label: "权限标识",
                "show-overflow-tooltip": true,
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "操作",
                align: "left",
                "class-name": "small-padding fixed-width",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm.permissions.sys_menu_add
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.addOrUpdateHandle(
                                    false,
                                    scope.row.id
                                  )
                                },
                              },
                            },
                            [_vm._v("添加")]
                          )
                        : _vm._e(),
                      _vm.permissions.sys_menu_edit
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.addOrUpdateHandle(
                                    true,
                                    scope.row.id
                                  )
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          )
                        : _vm._e(),
                      _vm.permissions.sys_menu_del
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(scope.row)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm.addOrUpdateVisible
          ? _c("MenuForm", {
              ref: "addOrUpdate",
              on: { refreshDataList: _vm.getList },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }