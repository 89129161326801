<template>
    <el-dialog 
        :title="product?.goodsName || ''" 
        :visible.sync="visible"
        width="600px" 
        :before-close="handleCancel" 
        top="5vh"
        @opened="getTree"
    >
        <div class="sales-control-dialog">
            <div class="structure">
                <div class="searchArea">
                    <el-button type="text" @click="handleAllSales">一键全部销售</el-button>
                    <el-input class="searchIpt" placeholder="请输入架构名称" suffix-icon="el-icon-search" size="mini"
                        style="visibility: auto" v-model="treeSearchText">
                    </el-input>
                </div>
                <div class="treeBox">
                    <el-tree 
                        node-key="id" 
                        ref="tree" 
                        :data="organizationData" 
                        :highlight-current="true"
                        @node-click="getCheckKey" 
                        :props="defaultProps" 
                        :filter-node-method="filterNode" 
                        :default-expand-all="false"
                        :expand-on-click-node="false"
                        @node-expand="saveCurrentExpandKeys"
                        :default-expanded-keys="currentExpandKeys"
                    >
                        <div class="custom-tree-node" slot-scope="{ node, data }">
                            <span>{{ data.name }}</span>
                            <div v-if="data.item">
                                <!-- 价格区间 -->
                                <div>{{data.item.price}}</div>
                                <!-- saleStatus	销售开关: 1 销售,2 下架 -->
                                <el-switch 
                                    v-model="data.item.checked" 
                                    active-color="#f5222d" 
                                    inactive-color="#8c8c8c" 
                                    active-text="已销售" 
                                    inactive-text="未销售"
                                    @change="handleSave(data)"
                                />
                            </div>
                        </div>
                    </el-tree>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { Loading } from 'element-ui';
import { 
    querySaleAreaListBySalePlanId,
    editSaleAreaStatus
 } from '@/api/saleRule/productLine'
 import {
  regionTree,
} from "@/api/businessPlatform/relationshipMaintenance";

export default {
    name: 'ProductSalesControlDialog',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        product: {
            type: Object,
            default: () => { 
                return {
                    id: '',
                    goodsName: '',
                    salePlanId: ''
                }
            }
        }
    },
    watch: {
    treeSearchText(val) {
        this.$refs.tree.filter(val);
      }
    },
    data() {
        return {
            treeSearchText: '',
            defaultProps: {
                children: "children",
                label: "name",
            },
            organizationData: [],
            regionTreedata: [],
            userSelectDataArr:[],
            options: {
                lock: true,
                text: '加载中......',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            },
            currentExpandKeys: [],
        }
    },
    computed: {

    },
    methods: {
        getTree () {
            regionTree().then((res) => {
                if (res.code == 0) {
                    this.regionTreedata = res.data;
                    this.getOrganizationData()
                }
            });
        },
        getOrganizationData() {
            const { salePlanId } = this.product
            if (!salePlanId) return
            const loading = Loading.service(this.options);
            querySaleAreaListBySalePlanId(salePlanId).then(res => {
                if (res && res.length > 0) {
                    this.userSelectDataArr = res
                    res.forEach(item => {
                        item.checked = item.saleStatus === 1
                    })
                    const trees = this.filterTree(this.regionTreedata, res)
                    this.organizationData = trees
                    this.$nextTick(() => {
                        loading.close();
                        // if(this.organizationData.length > 0 && this.currentExpandKeys.length > 0){

                        // }
                    })
                }
                loading.close();
            })
        },
        filterTree (tree, nodes){
            return tree.filter((node) => {
                // 检查当前节点的ID是否在ids数组中
                const findeItem = nodes.find(item => item.areaCode == node.id)
                if (findeItem) {
                        // 如果当前节点在ids数组中，递归过滤其子节点
                    if (node.children) {
                        node.children = this.filterTree(node.children, nodes);
                    }
                    node.item = findeItem
                    // 保留当前节点
                    return true;
                } else {
                    // 如果当前节点不在ids数组中，检查是否有子节点在ids数组中
                    if (node.children) {
                        const filteredChildren = this.filterTree(node.children, nodes);
                        // 如果有子节点在ids数组中，保留当前节点并替换其子节点为过滤后的子节点
                        if (filteredChildren.length > 0) {
                            node.children = filteredChildren;
                            node.item = findeItem
                            return true;
                        }
                    }
                    // 否则，不保留当前节点
                    return false;
                }
            });
        },
        handleSave(e) {
            const obj = [
                {
                    id:e.item.id,
                    status: e.item.checked? 1 : 2,
                }
            ]
            editSaleAreaStatus(obj).then(res => {
                if (res) {
                    this.$message.success('保存成功')
                    this.getOrganizationData()
                }
            })
        },
        handleOneKeySales() {
            this.userSelectDataArr.forEach(item => {
                item.status = 1
            })
            editSaleAreaStatus(this.userSelectDataArr).then(res => {
                if (res) {
                    this.$message.success('保存成功')
                    this.getOrganizationData()
                }
            })
            // this.handleSave(node)
        },
        handleSubmit() {

        },
        // 全部销售
        handleAllSales() {
            this.$alert('确定全部销售？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                showCancelButton: true,
                callback: action => {
                    
                    if (action === 'confirm') {
                        // 全部销售
                        this.handleOneKeySales()
                    }
                }
            });
        },
        handleCancel() {
            this.$emit('cancel')
        },
        filterNode(value, data) {
            // return data.name.indexOf(value) !== -1
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        getCheckKey(data) {
            // this.$refs.tree1.setCheckedKeys([data.id])
            // this.treeSearchText = data.name
        },
        saveCurrentExpandKeys(data) {
            // this.currentExpandKeys = data.expandedKeys
            console.log('data', data);
            this.currentExpandKeys = [data.id]
        }

    }
}
</script>


<style scoped lang="scss">
.sales-control-dialog {
    height: 70vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .structure {
        width: 550px;
        background: #ffffff;

        .footer {
            border: 1px solid #dbdbdb;
            border-top: none;
            border-radius: 0 0 4px 4px;
        }

        .treeBox {
            border-radius: 4px 4px 0 0;
            padding-top: 10px;
            height: 60vh;
            overflow-y: auto;
            scrollbar-width: none;
            /* Firefox隐藏滚动条 */
            -ms-overflow-style: none;
            /* Internet Explorer 10+隐藏滚动条 */
            border: 1px solid #dbdbdb;

            // border-top: none;
            ::-webkit-scrollbar {
                width: 0;
                /* Safari,Chrome 隐藏滚动条 */
                height: 0;
                /* Safari,Chrome 隐藏滚动条 */
                display: none;
                /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
            }
        }

        .searchArea {
            border-bottom: none;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .searchIpt {
                width: 200px;
            }
        }
    }
    .custom-tree-node {
        display: flex;
        flex:1;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
    }
}
</style>