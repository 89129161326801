<!--  -->
<template>
  <div class="classification">
    <div class="header"> 
      <el-button size="small" @click="backPage()">返回</el-button>
    </div>

    <div
      class="center"
      style="position: relative"
      v-loading="loading"
      element-loading-text="正在加载页面，请稍候！"
    >
      <MyTable
        :table-data="tableData"
        :index="index"
        :selection="selection"
        
        :operation="operation"
        :showPagination="false"
        :table-option.sync="tableOption" 
      >
       
      </MyTable>
    </div>
  </div>
</template>

<script>
import MyTable from "@/components/myTable"; 
import { getStatisticSalesOrderBillsDetail} from "@/api/statistics";  
export default {
  name: "reconciliationDetail",
  components: { MyTable },
  data() {
    return { 
      
      operation: false,
      selection: false,
      index: false,
      tableData: [],
      tableOption: [ 
        { label: "下单时间", prop: "orderTime", width: "160px" },
        { label: "完成收货时间", prop: "finishDate", width: "160px" },
        { label: "订单编号", prop: "orderCode", width: "160px" },
        { label: "订单状态", prop: "statusStr", width: "160px" },
        { label: "客户名称", prop: "payShopName", width: "220px" },
        { label: "商品名称", prop: "goodsName", width: "220px" },
        { label: "生产厂家", prop: "goodsFactory", width: "220px" },
        { label: "商品规格", prop: "goodsSpecification", width: "220px" },
        { label: "采购数量", prop: "qty", width: "220px" },
        { label: "商品单价（元）", prop: "price", width: "220px" },
        { label: "小计（元）", prop: "totalPrice" },
      ],
      loading: false,
      
    };
  },
  computed: {},
  watch: {},
  created() {
    this.pageBills();
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  methods: { 
    // 查询订单商品明细
    pageBills() { 
      this.loading = true;
      const params = {
        orderId: this.$route.query.id, 
      };
      getStatisticSalesOrderBillsDetail(params)
        .then((res) => {
          if (res) { 
            this.tableData = res; 
            this.loading = false;
          } else {
            this.tableData = []; 
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    backPage(){
      this.$router.go(-1);
    }
  },
};
</script>
<style lang="scss" scoped>
.classification {
  padding: 0px;
}
.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 24px 0px 24px !important;
  border-bottom: 1px solid #f5f5f5;
  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }
  .el-form-item {
    margin: 0px 0px 0px 8px !important;
  }
}
.center {
  margin-top: 16px;
  padding: 0 24px 0 24px;
  height: calc(100% - 131px);
  ::v-deep .imageP {
    width: calc(100% - 110px);
    p {
      margin: 0 0 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .shangjia {
    position: absolute;
    z-index: 2;
    bottom: -45px;
  }
}
</style>
