var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "classification",
    },
    [
      _c("el-form", { ref: "formTool", attrs: { inline: true } }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "flex-box" },
            [
              _c(
                "el-row",
                { staticStyle: { width: "calc(100% - 60px)" } },
                [
                  _c("goods-three-class", {
                    ref: "three-class",
                    attrs: {
                      formTool: _vm.formTool,
                      isShowLable: true,
                      styleLable: "width:250px; margin-right: 10px",
                      lableText: "商品分类",
                    },
                  }),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          size: "small",
                          placeholder: "药品名称",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.searchResult(1)
                          },
                        },
                        model: {
                          value: _vm.formTool.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "name", $$v)
                          },
                          expression: "formTool.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          size: "small",
                          placeholder: "厂家名称",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.searchResult(1)
                          },
                        },
                        model: {
                          value: _vm.formTool.factory,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "factory", $$v)
                          },
                          expression: "formTool.factory",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.searchResult(1)
                            },
                          },
                        },
                        [_vm._v("检索并选择商品")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100px" },
                          attrs: { size: "small", type: "plain" },
                          on: {
                            click: function ($event) {
                              return _vm.searchReset(1)
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100px" },
                          attrs: {
                            disabled: !_vm.addGoodsArr.length,
                            size: "small",
                            type: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.bathUpdateByProduct(1)
                            },
                          },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: "tableCont" },
            [
              _c("giftGoodsList", {
                attrs: { records: _vm.records },
                on: {
                  removeSelectedItem: _vm.updateSelectedGoods,
                  addGunalian: _vm.addGunalian,
                  updateRecords: _vm.updateRecords,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "el-page" },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.pageObj.total,
                  page: _vm.pageObj.page.current,
                  limit: _vm.pageObj.page.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.pageObj.page, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.pageObj.page, "size", $event)
                  },
                  pagination: _vm.changeSize,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }