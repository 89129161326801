<template>
  <div>
    <el-form ref="formTool" :inline="true" style="text-align: left">
      <el-table
        v-loading="loading"
        :data="areaData"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" :selectable="selectable">
        </el-table-column>
        <el-table-column label="客户名称" prop="customerShopName" width="150">
        </el-table-column>
        <el-table-column label="销售定价" prop="price" width="170">
          <template slot-scope="scope">
            <div class="unit-box">
              <span
                ><el-input
                  :disabled="scope.row.status == 'PUTAWAY' ? true : false"
                  v-model="scope.row.price"
                  class="tag-input"
                  style="width: 100px"
                  size="mini"
                  oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''"
                ></el-input
              ></span>
              <span @click="openDaliog(scope.row, scope.$index)" class="unit-s">
                <el-tag
                  type="primary"
                  size="medium"
                  style="border: 1px solid #f5222d"
                >
                  元/{{ scope.row.goodsSkuUnit }}</el-tag
                >
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="合作医药公司名称" prop="shopName" width="200">
          <template slot-scope="scope">
            <el-select
              size="mini"
              :disabled="scope.row.status == 'PUTAWAY' ? true : false"
              v-model="scope.row.shopName"
              @change="
                (val) => {
                  reservationChange(val, scope.$index, 1);
                }
              "
            >
              <el-option
                v-for="item in medicineManager"
                :key="item.shopId"
                :label="item.shopName"
                :value="item"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="医药公司康贝" prop="shopName" width="130">
          <template slot-scope="scope">
            <el-select
              size="mini"
              :disabled="scope.row.status == 'PUTAWAY' ? true : false"
              v-model="scope.row.merchantNo"
              @visible-change="
                (val) => {
                  // getListByShopIdFn(val, scope.row.drugLedgerRules.shopId,1);
                  getListByShopIdFn(val, scope.row, 1);
                }
              "
              @change="
                (val) => {
                  merchantNoChange(val, scope.$index, 1, scope.row);
                }
              "
            >
              <!-- <el-option
                  v-for="item in scope.row.merchantNoArr"
                  :key="item"
                  :label="item"
                  :value="item"
                /> -->
              <el-option
                v-for="(item, key) in listByShopId"
                :key="key"
                :label="item.merchantNo"
                :value="item"
              >
                <span style="color: #8492a6; font-size: 13px">
                  康贝号: {{ item.merchantNo }}</span
                >
                <span> 康贝店铺: {{ item.merchantName }}</span>
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="销售金额" prop="amount" width="120">
          <template slot-scope="scope">
            <span
              ><el-input
                :disabled="scope.row.status == 'PUTAWAY' ? true : false"
                v-model="scope.row.amount"
                class="tag-input"
                style="width: 100px"
                size="mini"
                oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''"
              ></el-input
            ></span>
          </template>
        </el-table-column>
        <el-table-column label="地区公司名称" prop="city_shopName" width="200">
          <template slot-scope="scope">
            <el-select
              size="mini"
              clearable
              v-model="scope.row.city_shopName"
              :disabled="scope.row.status == 'PUTAWAY' ? true : false"
              @change="
                (val) => {
                  reservationChange(val, scope.$index, 2);
                }
              "
            >
              <el-option
                v-for="(item, index) in cityManager"
                :key="item.shopId + index"
                :label="item.shopName"
                :value="item"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="地区公司康贝" prop="city_shopName" width="130">
          <template slot-scope="scope">
            <el-select
              size="mini"
              clearable
              v-model="scope.row.city_merchantNo"
              :disabled="scope.row.status == 'PUTAWAY' ? true : false"
              @visible-change="
                (val) => {
                  //  getListByShopIdFn(val, scope.row.cityLedgerRules.shopId,2);
                  getListByShopIdFn(val, scope.row, 2);
                }
              "
              @change="
                (val) => {
                  merchantNoChange(val, scope.$index, 2, scope.row);
                }
              "
            >
              <!-- <el-option
                  v-for="item in scope.row.city_merchantNoArr"
                  :key="item"
                  :label="item"
                  :value="item"
                /> -->
              <el-option
                v-for="(item, key) in listByShopId2"
                :key="key"
                :label="item.merchantNo"
                :value="item"
              >
                <span style="color: #8492a6; font-size: 13px">
                  康贝号: {{ item.merchantNo }}</span
                >
                <span> 康贝店铺: {{ item.merchantName }}</span>
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="服务费金额" prop="city_amount" width="120">
          <template slot-scope="scope">
            <span
              ><el-input
                :disabled="
                  scope.row.city_shopName == '' || scope.row.status == 'PUTAWAY'
                    ? true
                    : false
                "
                v-model="scope.row.city_amount"
                style="width: 100px"
                size="mini"
                oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''"
              ></el-input
            ></span>
          </template>
        </el-table-column>
        <el-table-column
          label="省区公司名称"
          prop="serviceShopName"
          width="200"
        >
          <template slot-scope="scope">
            <el-select
              size="mini"
              :disabled="scope.row.status == 'PUTAWAY' ? true : false"
              v-model="scope.row.serviceShopName"
              @change="
                (val) => {
                  reservationChange(val, scope.$index, 3);
                }
              "
            >
              <el-option
                v-for="item in provinceManager"
                :key="item.shopId"
                :label="item.shopName"
                :value="item"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          label="省区公司康贝"
          prop="serviceShopName"
          width="130"
        >
          <template slot-scope="scope">
            <el-select
              size="mini"
              :disabled="scope.row.status == 'PUTAWAY' ? true : false"
              v-model="scope.row.serviceMerchantNo"
              @visible-change="
                (val) => {
                  // getListByShopIdFn(val, scope.row.cityLedgerRules.serviceShopId,3);
                  getListByShopIdFn(val, scope.row, 3);
                }
              "
              @change="
                (val) => {
                  merchantNoChange(val, scope.$index, 3, scope.row);
                }
              "
            >
              <!-- <el-option
                  v-for="item in scope.row.serviceMerchantNoArr"
                  :key="item"
                  :label="item"
                  :value="item"
                /> -->
              <el-option
                v-for="(item, key) in listByShopId3"
                :key="key"
                :label="item.merchantNo"
                :value="item"
              >
                <span style="color: #8492a6; font-size: 13px">
                  康贝号: {{ item.merchantNo }}</span
                >
                <span> 康贝店铺: {{ item.merchantName }}</span>
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          label="地区需转账省区"
          prop="serviceAmount"
          width="120"
        >
          <template slot-scope="scope">
            <span
              ><el-input
                :disabled="scope.row.status == 'PUTAWAY' ? true : false"
                v-model="scope.row.serviceAmount"
                style="width: 100px"
                size="mini"
                oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''"
              ></el-input
            ></span>
          </template>
        </el-table-column>

        <el-table-column label="省学术公司" prop="studyShopName" width="200"> 
          <template slot-scope="scope">
            <el-select
              size="mini"
              :disabled="scope.row.status == 'PUTAWAY' ? true : false"
              v-model="scope.row.studyShopName"
              @change="
                (val) => {
                  reservationChange(val, scope.$index, 4);
                }
              "
            >
              <el-option
                v-for="item in provinceManager"
                :key="item.shopId"
                :label="item.shopName"
                :value="item"
              />
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="省学术康贝" prop="studyMerchantNo" width="200"> 
          <template slot-scope="scope">
            <el-select
              size="mini"
              :disabled="scope.row.status == 'PUTAWAY' ? true : false"
              v-model="scope.row.studyMerchantNo"
              @visible-change="
                (val) => {
                  // getListByShopIdFn(val, scope.row.cityLedgerRules.serviceShopId,3);
                  getListByShopIdFn(val, scope.row, 4);
                }
              "
              @change="
                (val) => {
                  merchantNoChange(val, scope.$index, 4, scope.row);
                }
              "
            > 
              <el-option
                v-for="(item, key) in listByShopId4"
                :key="key"
                :label="item.merchantNo"
                :value="item"
              >
                <span style="color: #8492a6; font-size: 13px">
                  康贝号: {{ item.merchantNo }}</span
                >
                <span> 康贝店铺: {{ item.merchantName }}</span>
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="省区需转学术" prop="studyAmount" width="140">
          <template slot-scope="scope">
            <div class="unit-box">
              <span>
                <el-input
                  :disabled="scope.row.status == 'PUTAWAY' ? true : false"
                  v-model="scope.row.studyAmount"
                  style="width: 100px"
                  size="mini"
                  oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''"
                >
                
                </el-input></span
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="启用状态"
          prop="status"
          align="center"
          fixed="right"
          min-width="105"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == 'PUTAWAY'" style="color: #f5222d"
              >已启用</span
            >
            <span v-if="scope.row.status == 'SOLDOUT'">未启用</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
            label="启用状态"
            prop="status"
            align="center"
            fixed="right"
            min-width="105"
          >
            <template slot-scope="scope">
              <div class="switchView">
                <el-switch
                  v-model="scope.row.status"
                  active-value="PUTAWAY"
                  inactive-value="SOLDOUT"
                  active-color="#67c23a"
                  inactive-color="#999"
                  active-text="上架"
                  inactive-text="下架"
                  class="switchStyle"
                  v-if="scope.row.productLineId"
                  @change="changeStatus(scope.row, scope.$index)"
                />
              </div>
            </template>
          </el-table-column> -->
      </el-table>
    </el-form>
    <Pagination
      v-show="areaData.length > 0"
      :total="page.total"
      :page-sizes="page.pageSizes"
      :page.sync="page.current"
      :limit.sync="page.size"
      @pagination="pageChange"
    />
    <el-dialog
      title="选择单位"
      :visible.sync="dialogVisible"
      width="400px"
      :before-close="handleClose"
    >
      <div class="content-box">
        <span
          :class="unitKey == key ? 'unit active' : 'unit'"
          v-for="(item, key) in unitList"
          :key="key"
          @click="choseUnit(item, key)"
          >{{ item }}</span
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="choseUnitSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAreaCompany,
  shopSelfBatchByProvince,
  getPageShopSkuPrice,
  skuUpdateStatus,
  getProductLineSkuList,
  getListByShopId,
} from "@/api/saleRule/productLine";
import Pagination from "@/components/Pagination";
import { color } from "echarts";
export default {
  components: { Pagination },
  props: {
    goodsId: {
      type: String,
      default() {
        return null;
      },
    },
    goodsName: {
      type: String,
      default() {
        return "";
      },
    },
    orgCode: {
      type: String,
      default() {
        return null;
      },
    },
    orgName: {
      type: String,
      default() {
        return "";
      },
    },
    shopName: {
      type: String,
      default() {
        return "";
      },
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    }, // 列表数据
    unitList: {
      type: Array,
      default() {
        return [];
      },
    }, // 单位数据
  },
  data() {
    return {
      formTool: {},
      loading: false,
      rules: {
        price: [{ required: true, message: "请输入销售定价", trigger: "blur" }],
      },
      dialogVisible: false,
      provinceCode: 430000,
      provinceName: "",
      areaListArr: [],
      areaData: [],
      medicineManager: [],
      cityManager: [],
      provinceManager: [],
      goodsSkuUnit: "盒",
      unitKey: 0,
      companyList: [], //医药公司名称
      deatilData: [], //详情数据
      listByShopId: [],
      listByShopId2: [], //地区公司康贝
      listByShopId3: [], //省区区公司康贝
      listByShopId4: [], //省学术康贝
      page: {
        total: 10,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50, 100],
      },
    };
  },
  created() {
    // this.init(this.provinceCode);
  },
  //   @Schema(description = "状态：PUTAWAY.上架,SOLDOUT.下架")
  // private String status;
  methods: {
    selectable(row, index) {
      return row.status != "PUTAWAY";
    },
    resetData() {
      this.page.current = 1;
      this.page.size = 10;
      this.total = 10;
      this.areaData = [];
    },
    init() {
      // if (provinceCode) {
      //   this.provinceCode = provinceCode;
      //   this.getAreaCompanyFn(); // 统一分账获取省地办
      // } else {
      this.getAreaCompanyFn(); // 统一分账获取省地办
      // }
    },
    // 根据店铺获取已有康贝号列表
    getListByShopIdFn(val, row, type) {
      console.log(row);
      let shopId = null;
      if (type == 1) {
        shopId = row.shopId;
      }

      if (type == 2) {
        shopId = row.city_shopId;
      }

      if (type == 3) {
        shopId = row.serviceShopId;
      }
      if (type == 4) {
        shopId = row.studyShopId;
      }
      if (!shopId) {
        this.$message.error("shopId获取失败！");
        return;
      }
      let params = {
        shopId: shopId,
      };
      getListByShopId(params).then((res) => {
        if (res) {
          if (type == 1) {
            this.listByShopId = res;
          }
          if (type == 2) {
            this.listByShopId2 = res;
          }
          if (type == 3) {
            this.listByShopId3 = res;
          }
          if (type == 4) {
            this.listByShopId4 = res;
          }
        }
      });
    },
    // 统一分账获取省地办
    getAreaCompanyFn() {
      this.loading = true;
      let params = {
        orgCode: this.orgCode,
      };
      getAreaCompany(params).then((res) => {
        this.cityManager = res.cityManager; //市级 公司 列表
        this.provinceManager = res.provinceManager; // 省级公司列表
        this.medicineManager = res.medicineManager; //医药公司列表
        this.getSelfPriceAndLedgerRuleFn();
      });
    },

    // 获取分账管理列表
    getSelfPriceAndLedgerRuleFn() {
      console.log(this.goodsId);
      if (this.goodsId == "") return;
      let params = {
        goodsId: this.goodsId,
        orgCode: this.orgCode,
        orgName: this.orgName,
        shopName: this.shopName,
        current: this.page.current,
        size: this.page.size,
      };
      getPageShopSkuPrice(params)
        .then((res) => {
          this.loading = false;
          if (res && res.records && res.records.length) {
            this.page.total = res.total;
            // this.areaData = res.records;
            this.initShopSkuListPrice(res.records);
          } else {
            this.areaData = [];
          }
        })
        .catch((error) => {
          this.areaData = [];
          this.loading = false;
        });
    },

    initShopSkuListPrice(data) {
      data.map((e) => {
        // item.areaCode = e.areaCode;
        // item.areaName = e.areaName;
        // item.ledgerRulesId = e.ledgerRulesId;
        // item.productLineId = e.productLineId;
        // item.goodsId = e.goodsId;
        // item.goodsName = e.goodsName;
        // item.goodsSkuPriceId = e.goodsSkuPriceId;
        // item.goodsSkuUnit = e.goodsSkuUnit;
        // item.provinceCode = e.provinceCode;
        // item.provinceName = e.provinceName;
        // item.price = e.price; //销售定价
        // item.status = e.status;

        //医药公司
        if (e.drugLedgerRules) {
          e.amount = e.drugLedgerRules.amount;
          e.drug_id = e.drugLedgerRules.id;
          e.drug_ledgerRulesId = e.drugLedgerRules.ledgerRulesId;
          e.merchantNo = e.drugLedgerRules.merchantNo;
          e.shopId = e.drugLedgerRules.shopId;
          e.shopName = e.drugLedgerRules.shopName;
        }
        if (e.cityLedgerRules) {
          //地区公司
          e.city_id = e.cityLedgerRules.id;
          e.city_ledgerRulesId = e.cityLedgerRules.ledgerRulesId;
          e.city_merchantNo = e.cityLedgerRules.merchantNo;
          e.city_amount = e.cityLedgerRules.amount;
          e.city_shopId = e.cityLedgerRules.shopId;
          e.city_shopName = e.cityLedgerRules.shopName;
          //地区需转省区公司
          e.serviceAmount = e.cityLedgerRules.serviceAmount;
          e.serviceMerchantNo = e.cityLedgerRules.serviceMerchantNo;
          e.serviceShopId = e.cityLedgerRules.serviceShopId;
          e.serviceShopName = e.cityLedgerRules.serviceShopName;
            //省区学术
          e.studyAmount = e.cityLedgerRules.studyAmount;
          e.studyMerchantNo = e.cityLedgerRules.studyMerchantNo;
          e.studyShopId = e.cityLedgerRules.studyShopId;
          e.studyShopName = e.cityLedgerRules.studyShopName;
        } else {
          if (this.cityManager && this.cityManager.length) {
            const cityDefault = this.cityManager[0];
            e.city_id = "";
            e.city_ledgerRulesId = "";
            e.city_merchantNo = cityDefault.merchantNo;
            e.city_amount = "";
            e.city_shopId = cityDefault.shopId;
            e.city_shopName = cityDefault.shopName;
          }
          const provinceDefault = this.provinceManager[0];
          e.serviceAmount = "";
          e.serviceMerchantNo = provinceDefault.merchantNo;
          e.serviceShopId = provinceDefault.shopId;
          e.serviceShopName = provinceDefault.shopName;
        }
      });
      this.areaData = data;
      // console.log(this.areaData,'--this.areaData')
    },

    // 点击选择单位choseUnit
    choseUnit(e, key) {
      // console.log(e, "e");  console.log(key, "key");
      this.unitKey = key;
      this.goodsSkuUnit = e;
    },

    // 确认选择单位
    choseUnitSure() {
      this.areaData[this.currentRowIndex].goodsSkuUnit = this.goodsSkuUnit;
      this.dialogVisible = false;
    },

    // 多康贝选择
    merchantNoChange(val, index, type, scope) {
      //这样就可以传递自定义的参数了
      console.log(val, "--val--", index, "--index--", type, "type");

      if (type == 1) {
        this.areaData[index].merchantNo = val.merchantNo;
      }
      // console.log(this.areaData[index]);
      if (type == 2) {
        this.areaData[index].city_merchantNo = val.merchantNo;
      }
      if (type == 3) {
        this.areaData[index].serviceMerchantNo = val.merchantNo;
      }
      if (type == 4) {
        this.areaData[index].studyMerchantNo = val.merchantNo;
      }
    },

    // 设置下拉选择 值
    reservationChange(val, index, type) {
      //这样就可以传递自定义的参数了
      console.log(val, "--val--", index, "--index--", type, "type");

      if (type == 1) {
        this.areaData[index].shopName = val.shopName;
        this.areaData[index].shopId = val.shopId;
        this.areaData[index].merchantNo = val.merchantNo;

        // 调用sku接口
        this.getProductLineSkuList(val.shopId, "select", index);
      }
      // console.log(this.areaData[index]);
      if (type == 2) {
        this.areaData[index].city_shopName = val.shopName;
        this.areaData[index].city_shopId = val.shopId;
        this.areaData[index].city_merchantNo = val.merchantNo;

      }
      if (type == 3) {
        this.areaData[index].serviceShopName = val.shopName;
        this.areaData[index].serviceShopId = val.shopId;
        this.areaData[index].serviceMerchantNo = val.merchantNo;

      }
      if (type == 4) {
        this.areaData[index].studyShopName = val.shopName;
        this.areaData[index].studyShopId = val.shopId;
        this.areaData[index].studyMerchantNo = val.merchantNo; 

      }
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }, value) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: value.length,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },

    // 打开选择单位弹窗
    openDaliog(row, rowIndex) {
      this.dialogVisible = true;
      this.currentRowIndex = rowIndex;
      console.log(row, rowIndex);
    },

    getProductLineSkuList(shopId, type, index) {
      let params = {
        goodsId: this.goodsId,
        shopId: shopId,
        orgCodeSet: [this.orgCode], //必传
      };
      console.log(params, "params");
      getProductLineSkuList(params)
        .then((res) => {
          let resp = res;
          console.log(type, "type");
          this.areaData.map((item) => {
            for (let e of resp) {
              if (type == "select") {
                // console.log(e,'e')
                if (this.areaData[index].orgCode == e.orgCode) {
                  console.log(e, "e");
                  this.areaData[index].productLineId = e.productLineId;
                  // this.areaData[index].goodsSkuPriceId = e.goodsSkuPriceId;
                  break;
                }
              } else {
                if (item.orgCode == e.orgCode && !item.productLineId) {
                  item.productLineId = e.productLineId;
                  // item.goodsSkuPriceId = e.goodsSkuPriceId;
                }
              }
            }
          });

          console.log(this.areaData, "this.areaData");
        })
        .catch((err) => {});
    },

    // 全局应用设置价格数据
    save(from) {
      // console.log("from", from);
      // 全局设置 循环填充数据
      for (let item of this.areaData) {
        // 如果本条数据是上架状态，本条数据不做修改
        if (item.status == "PUTAWAY") {
          continue;
        }
        if (from.price) {
          item.price = parseFloat(from.price); //销售定价
        }
        if (from.amount) {
          item.amount = parseFloat(from.amount); //销售金额
        }
        if (from.city_amount && item.city_shopName != "") {
          item.city_amount = parseFloat(from.city_amount); //服务费用
        }
        if (item.city_shopName == "") {
          item.city_amount = "";
        }
        if (from.serviceAmount) {
          item.serviceAmount = parseFloat(from.serviceAmount); //地区需转账省区
        }
        if (from.goodsSkuUnit) {
          item.goodsSkuUnit = from.goodsSkuUnit;
        }
      }

      // console.log(this.areaData, "this.areaData");
      this.$forceUpdate();
    },

    // 提交数据
    submit(from) {
      console.log(this.areaData, "this.areaData");

      let params = [];
      for (let item of this.areaData) {
        // 如果本条数据全部是空 中断本次循环 此条数据不进入数据池
        if (
          !item.price &&
          !item.amount &&
          !item.city_amount &&
          !item.serviceAmount
        ) {
          continue;
        }

        if (!item.city_shopName) {
          item.city_includeService = false;
        }

        if (!item.price || parseFloat(item.price) == 0) {
          this.$message({
            message: `【${item.customerShopName}】"销售定价"必须填写 ！`,
            type: "warning",
          });
          console.log(item);

          return;
        } else {
          item.price = parseFloat(item.price);
        }

        if (!item.amount || parseFloat(item.amount) == 0) {
          this.$message({
            message: `【${item.customerShopName}】"销售金额"必须填写！`,
            type: "warning",
          });
          return;
        } else {
          item.amount = parseFloat(item.amount);
        }

        if (
          (!item.city_amount || parseFloat(item.city_amount) == 0) &&
          item.city_shopName
        ) {
          this.$message({
            message: `【${item.customerShopName}】"服务费金额"必须填写！`,
            type: "warning",
          });
          return;
        } else {
          if (item.city_amount) {
            item.city_amount = parseFloat(item.city_amount);
          } else {
            item.city_amount = null;
          }
        }

        if (!item.serviceAmount || parseFloat(item.serviceAmount) == 0) {
          this.$message({
            message: `【${item.customerShopName}】"地区需转账省区"必须填写！`,
            type: "warning",
          });
          return;
        } else {
          item.serviceAmount = parseFloat(item.serviceAmount);
        }

        if (
          item.serviceAmount &&
          item.city_amount &&
          parseFloat(item.serviceAmount) > parseFloat(item.city_amount)
        ) {
          this.$message({
            message: `【${item.customerShopName} "地区需转账省区" 必须小于等于 "服务费金额"！`,
            type: "warning",
          });
          return;
        }

        if (!item.merchantNo || !item.merchantNo) {
          this.$message({
            message: `【${item.customerShopName}】医药公司康贝号为空！`,
            type: "warning",
          });
          return;
        }

        if (item.city_shopName != null && item.city_shopName != "") {
          if (item.city_merchantNo == null || item.city_merchantNo == "") {
            this.$message({
              message: `【${item.customerShopName}】地区公司康贝号为空！`,
              type: "warning",
            });
            return;
          }
        }

        if (!item.serviceMerchantNo) {
          this.$message({
            message: `【${item.customerShopName}】省区公司康贝号为空！`,
            type: "warning",
          });
          return;
        }

        if (item.studyAmount && !item.studyShopName) {
          this.$message({
            message: `【${item.orgName}】【${item.shopName}】省学术公司为空！`,
            type: "warning",
          });
          return;
        }

        if (item.studyAmount && !item.studyMerchantNo) {
        this.$message({
          message: `【${item.orgName}】【${item.shopName}】省学术康贝为空！`,
          type: "warning",
        });
        return ;
      } 
 
        if (
          item.status != "PUTAWAY" &&
          item.serviceAmount &&
          item.studyAmount &&
          item.studyAmount > item.serviceAmount
        ) {
          this.$message({
            message: `【${item.orgName} "省区转学术" 必须小于等于 "地区需转账省区"！`,
            type: "warning",
          });
          return false;
        }

        const {
          goodsSkuUnit,
          orgCode,
          orgName,
          provinceName,
          provinceCode,
          price,
          shopId,
          shopName,
          merchantNo,
          serviceShopId,
          serviceShopName,
          serviceAmount,
          serviceMerchantNo,
          goodsSkuPriceId,
          amount,
          customerShopId,
          customerShopName,
          studyAmount,
          studyMerchantNo,
          studyShopId,
          studyShopName,
        } = item;
        const { goodsId, goodsName } = this;
        // 拼装给后端的数据
        let obj = {
          goodsId,
          goodsName,
          goodsSkuUnit,
          orgCode,
          orgName,
          provinceName,
          provinceCode,
          price,
          customerShopId,
          customerShopName,
        };
        obj.status = item.status;
        obj.id = item.id ? item.id : null;
        obj.goodsSkuPriceId = goodsSkuPriceId ? goodsSkuPriceId : null;
        obj.productLineId = item.productLineId ? item.productLineId : null;
        obj.ledgerRulesId = item.ledgerRulesId ? item.ledgerRulesId : null;
        obj.drugLedgerRules = {
          shopId,
          shopName,
          merchantNo,
          amount,
        };
        obj.drugLedgerRules.costType = "DETAIL";
        obj.drugLedgerRules.includeService = false;
        obj.drugLedgerRules.id = item.drug_id ? item.drug_id : null;
        obj.drugLedgerRules.ledgerRulesId = item.drug_ledgerRulesId
          ? item.drug_ledgerRulesId
          : null;

        obj.cityLedgerRules = {
          id: item.city_id ? item.city_id : null,
          ledgerRulesId: item.city_ledgerRulesId
            ? item.city_ledgerRulesId
            : null,
          amount: item.city_amount || null,
          merchantNo: item.city_merchantNo,
          shopId: item.city_shopId,
          shopName: item.city_shopName,
          includeService: item.city_shopName ? true : false,
          costType: "SERVICE",
        };
        obj.cityLedgerRules = { 
          studyAmount,
          studyMerchantNo,
          studyShopId,
          studyShopName,
          serviceShopId,
          serviceShopName,
          serviceAmount,
          serviceMerchantNo,
          ...obj.cityLedgerRules,
        };
        params.push(obj);
      }
      if (params.length == 0) {
        this.$message({
          message: `请设置分账！`,
          type: "warning",
        });
        return;
      }
      console.log(params, "params--");
      this.productLineAreaPrices(params);
    },

    productLineAreaPrices(params) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      shopSelfBatchByProvince(params)
        .then((res) => {
          loading.close();
          setTimeout(() => {
            this.$message({
              message: `提交成功！`,
              type: "success",
            });
            this.init();
          }, 2000);
        })
        .catch((err) => {
          setTimeout(() => {
            loading.close();
          }, 2000);
        });
    },

    handleClose() {
      this.dialogVisible = false;
    },
    pageChange(e) {
      this.page.size = e.limit;
      this.page.current = e.page;
      this.getSelfPriceAndLedgerRuleFn();
    },
    handleSelectionChange(val) {
      this.$emit("rowDidChange", val);
    },
  },
};
</script>

<style lang="scss">
.tag-input {
  width: 200px;
}
.content-box {
  margin: 10px;
  .unit {
    padding: 5px;
    border: solid 1px #eee;
    color: #333;
    border-radius: 5px;
    margin: 0 5px;
    line-height: 40px;
  }
  .unit:hover {
    background: rgb(255, 73, 73);
    color: #fff;
    cursor: pointer;
  }
  .active {
    background: rgb(255, 73, 73);
    color: #fff;
  }
}
.unit-box {
  display: flex;
  align-items: center;
}
.unit-s {
  margin: 5px;
  cursor: pointer;
  color: #000;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.switchView {
  .switchStyle {
    height: 30px;
    line-height: 30px;
  }

  .el-switch__label {
    min-width: 65px !important;
    height: 30px !important;
    position: absolute;
    left: 0px !important;
    border-radius: 15px;
    display: none;
    color: #fff !important;
    &--left {
      z-index: 9;
      left: 10px !important;
    }

    &--right {
      z-index: 9;
      left: -20px !important;
    }

    &.is-active {
      display: block;
    }
  }

  .el-switch__core {
    width: 65px !important;
    height: 30px !important;
    border-radius: 15px;
    &::after {
      top: 4px;
      left: 5px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
    }
  }

  .is-checked {
    .el-switch__core {
      &::after {
        left: 55px !important;
      }
    }
  }
}
</style>
