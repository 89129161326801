<template>
  <div class="dialog">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      :lock-scroll="true"
      :close-on-click-modal="false"
      width="818px"
      :before-close="handleClose"
    >
      <section>
        <div class="serach">
          <el-input
            prefix-icon="el-icon-search"
            v-model="formTool.goodsName"
            placeholder="请输入商品名称"
            clearable
            size="small"
            style="width: 200px"
            @change="change"
          />
        </div>
        <div>
          <goodsItem
            :goodsList="goodsList"
            @chooseItem="chooseItem"
            class="goods-content"
          />
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="page.pageSizes"
            layout="total, sizes, prev, pager, next"
            :total="page.total"
          >
          </el-pagination>
        </div>
      </section>
      <div class="btnBox">
        <el-button size="small" @click="handleClose">取消</el-button>
        <el-button type="primary" size="small" @click="chooseGoods"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import goodsItem from "./controlSalesGoodsItem.vue";
import { getGoodsPage } from "@/api/product";
export default {
  components: {
    goodsItem,
  },
  props: {
    btnType: {
      type: String,
      default: "",
    },
  },
  computed: {
    visible() {
      return this.dialogShow;
    },
  },
  data() {
    return {
      showForm: false, //是否展示表单
      dialogShow: false,
      isDisabled: false,
      pageType: "",
      formTool: {
        name: "",
      },
      title: "",
      goodsList: [],
      selectItems: [],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
    };
  },
  mounted() {
    this.getGoods();
  },
  methods: {
    init(row, type, title = "") {
      this.title = "";
      if (type == "detail") {
        this.isDisabled = true;
        this.title = "推广商品详情";
      }
      if (type == "edit") {
        this.isDisabled = false;
        this.title = "编辑推广商品";
      }
      if (type == "add") {
        this.isDisabled = false;
        this.title = "新增推广商品";
        this.showForm = false;
      }
      if (type == "again") {
        this.isDisabled = true;
        this.title = "重新提交推广商品";
      }
      if (!this.title && title) {
        this.title = title;
      }
      this.dialogShow = true;
      this.$forceUpdate();
    },
    // 搜索
    change(e) {
      console.log(e);
      this.page.current = 1;
      this.getGoods();
    },
    // 页码变化
    handleCurrentChange(e) {
      this.page.current = e;
      this.getGoods();
    },
    // 条数变化
    handleSizeChange(e) {
      this.page.size = e;
      this.page.current = 1;
      this.getGoods();
    },
    // 获取商品列表
    getGoods() {
      const params = {
        size: this.page.size,
        current: this.page.current,
        goodsName: this.formTool.goodsName,
      };
      getGoodsPage(params).then((res) => {
        if (res) {
          this.goodsList = res.records;
          this.page.total = res.total;

          this.$forceUpdate();
        }
      });
    },
    handleClose() {
      this.dialogShow = false;
    },
    chooseItem(arr) {
      this.selectItems = arr;
    },
    chooseGoods() {
      if (!this.selectItems.length) {
        // this.$message.warning('请选择推广商品')
        this.$message.warning("请选择商品");
        return;
      }
      this.dialogShow = false;
      this.$emit("didSelectItem", this.selectItems[0]);
    },
  },
};
</script>
<style lang="scss">
.btnBox {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.serach {
  margin-bottom: 10px;
}

.goods-content {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
