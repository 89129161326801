<template>
  <div class="classification">
    <div class="eachRow">
      <div class="titleView">
        <div class="rowLine"></div>
        <span class="eachRowTitle">图文海报信息</span>
      </div>
      <el-divider></el-divider>
    </div>
    <div style="padding: 24px 0;">
      <el-form ref="form" :model="form" :inline="false" label-width="100px" label-position="right"
        style="text-align:left;">
        <!-- <el-form-item prop="posterName"
                      label="审核意见">
          <el-input v-model="form.posterName"
                    type="textarea"
                    style="width: 300px;"
                    placeholder="审核意见"
                    clearable
                    size="small" />
        </el-form-item> -->
        <el-form-item prop="posterName" label="海报名称">
          <el-input v-model="form.posterName" style="width: 300px;" placeholder="海报名称" clearable size="small" />
        </el-form-item>
        <el-form-item prop="posterCopyWriter" label="发圈文案">
          <el-input v-model="form.posterCopyWriter" type="textarea" style="width: 300px;" placeholder="发圈文案" clearable
            size="small" autosize />
        </el-form-item>
        <el-form-item prop="posterName" label="海报内容">
          <imgUpload v-model="form.image" :isEdit="disabled" :limit="9" @onRemoveHandler="onRemoveHandler"
            style="position:relative;top:0px;">
            <template slot="tip">
              <p class="el-upload__tip">
                备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过10M
              </p>
            </template>
          </imgUpload>
        </el-form-item>
        <el-form-item prop="posterName" label=" ">
          <el-button size="small" type="primary" @click="chooseGoods()">选择产品</el-button>
        </el-form-item>

        <section class="goods-list-warp">
          <div v-for="(item, key) in selectedData" :key="key" class="goods-list">
            <div style="display: flex;justify-content: flex-start;align-items: center;">
              <img :src="item.image || avater" style="width: 80px;height: 80px;" alt="">
              <div style="margin-left: 10px;">
                <p style="margin: 3px 0;">{{ item.name }}</p>
                <p style="margin: 3px 0;">{{ item.factory }}</p>
                <p style="margin: 3px 0;">{{ item.specification }}</p>
              </div>
              <!-- <div>
                <span>￥{{ item.lowestSalePrice }}~￥{{ item.highestSalePrice }}</span>
              </div> -->
            </div>
          </div>
          <div v-if="id && this.form.checkStatus != '1'">
            <el-input style="width: 50%;" type="textarea" placeholder="请输入内容" v-model="form.checkOpinion"
              :disabled="true">
            </el-input>
          </div>
        </section>


        <div class="footer-warp">
          <el-divider></el-divider>
          <div class="footer-btn">
            <el-button size="small" type="primary" @click="submit()">保存</el-button>
            <el-button size="small" style="margin-left:12px" @click="cancel()">返回</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <associationGoods ref="association-goods" @chooseGoodsEmit="chooseGoodsEmit" />

  </div>
</template>
  
<script>
import {
  operation, salesposterinfo, salesposterinfoAdd, salesposterinfoUpdate
} from "@/api/salesControl/commodity";

import imgUpload from '@/components/imgUpload'
import associationGoods from './component/associationGoods'
export default {
  props: {},
  data () {
    return {
      disabled: false,
      id: '',
      form: {
        posterName: '',
        posterCopyWriter: '',
        image: [],
        relevanceGoodsName: '', //关联商品名称，使用逗号分隔
        relevanceGoodsId: '',//关联商品id，使用逗号分隔
      },
      selectedData: []
    }
  },
  components: { imgUpload, associationGoods },
  created () {
    this.id = this.$route.query?.id || ''
    if (this.id) {
      this.query(this.id)
    }
  },
  mounted () {

  },
  watch: {},
  methods: {
    // 选择产品
    chooseGoods () {
      this.$refs['association-goods'].open();
    },

    chooseGoodsEmit (selectedData) {
      this.selectedData = selectedData;
    },
    submit () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          console.log(this.selectedData, '---this.selectedData')
          if (this.selectedData) {
            this.form.relevanceGoodsId = '';
            this.form.relevanceGoodsName = '';
            for (let i = 0; i < this.selectedData.length; i++) {
              this.form.relevanceGoodsId += this.selectedData[i].goodsId + ','
              this.form.relevanceGoodsName += this.selectedData[i].name + ','
            }
          }
          if (this.form.relevanceGoodsId.indexOf(',') != -1) {
            this.form.relevanceGoodsId = this.form.relevanceGoodsId.slice(0, this.form.relevanceGoodsId.length - 1);
          }
          if (this.form.relevanceGoodsName.indexOf(',') != -1) {
            this.form.relevanceGoodsName = this.form.relevanceGoodsName.slice(0, this.form.relevanceGoodsName.length - 1);
          }

          let params = {
            posterId: this.form?.posterId || '',
            posterName: this.form.posterName,
            relevanceGoodsName: this.form.relevanceGoodsName,
            relevanceGoodsId: this.form.relevanceGoodsId,
            posterCopyWriter: this.form.posterCopyWriter,
            imageUrl: this.form.image.join(','),
          }

          console.log(params)

          if (this.id) {
            params.id = this.id;
            salesposterinfoUpdate(params).then(res => {
              this.$message.success('保存成功')
              this.cancel()
            })
          } else {
            salesposterinfoAdd(params).then(res => {
              this.$message.success('保存成功')
              this.cancel()
            })
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    cancel () {
      this.$router.push('/salesControlMange/salesControl-placard-list')
    },
    query (id) {
      salesposterinfo(id).then(res => {
        this.form = res
        let image = []
        if (this.form.imageUrl) {
          image = this.form.imageUrl.split(',')
        }
        this.selectedData = res.goodsBaseVOList;
        for (let i = 0; i < res.goodsBaseVOList.length; i++) {
          this.selectedData[i].goodsId = res.goodsBaseVOList[i].id;
        }
        console.log(this.selectedData, '---this.selectedData')
        this.$set(this.form, 'image', image)
        // this.$set(this, 'selectedData', res.goodsBaseVOList)
        console.log(this.form.image)
      })
    },
    onRemoveHandler (file) {
      console.log(file)
    }
  }
}

</script>
<style lang="scss" scoped>
.eachRow {
  background-color: #fff;
  border-radius: 4px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
}

.titleView {
  display: flex;
  align-items: center;

  .rowLine {
    width: 4px;
    border-color: 1px;
    background-color: var(--main-color);
    height: 16px;
    margin-right: 8px;
  }

  .eachRowTitle {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
  }

  .cpxhead {
    display: flex;
    align-items: center;
  }

  .mark {
    color: #f56c6c;
    font-size: 12px;
    margin-left: 10px;
    width: 200px;
    line-height: 1.5;
    display: inline-block;
    font-weight: bold;
  }
}

.footer-warp {
  width: 100%;
  margin-top: 40px;
}

.footer-btn {
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin-left: 100px;
}

.goods-list-warp {
  width: 100%;
  margin-left: 100px;
}

.goods-list {
  width: 50%;
  border-bottom: solid 1px #f5f5f5;
  padding-bottom: 10px;

  &:nth-last-child(1) {
    border-bottom: none;
  }
}
</style>

