<!--  -->
<template>
  <div class="classification">
    <div class="header"  v-if="0">
      <!-- 业务员设置,暂时影藏，测试要求43661: -->
      <el-form ref="formTool" :model="formTool" :inline="true" style="text-align: left">
        <el-form-item prop="nameLike" label="">
          <el-input v-model="formTool.nameLike" placeholder="请输入业务员姓名" clearable size="small" />
        </el-form-item>
        <el-form-item prop="phone" label="">
          <el-input v-model="formTool.phone" placeholder="请输入联系方式" clearable size="small" />
        </el-form-item>
        <!-- <el-form-item prop="cooperativeState" label="审核状态">
          <el-select v-model="formTool.cooperativeState" clearable style="width: 120px;" size="small" placeholder="类别">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="reset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tabs" type="border-card">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="可选合作" :name="'1'"></el-tab-pane>
        <el-tab-pane label="意向合作" :name="'2'"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="center" style="position: relative">
      <MyTable :table-data="tableData" :index="index" :selection="selection" :page="page" :operation="operation"
        :table-option.sync="tableOption" @page-change="getList" @handleSelectionChange="handleSelectionChange">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 50]" layout="sizes, prev, pager, next" :total="page.total">
        </el-pagination>
        <template slot="menu" slot-scope="scope" style="width:100px;">
          <div  v-if="activeName == '2'">
            <!-- <el-link :underline="false" type="primary" @click="detailHandle(scope.row, '同意合作')"
              v-if="scope.row.cooperativeState == 'NO' " class="btn-list">同意合作</el-link>
            <el-link :underline="false" type="primary" @click="detailHandle(scope.row, '取消合作')"
              v-if="scope.row.cooperativeState == 'YES' " class="btn-list">取消合作</el-link>
            <el-link :underline="false" type="primary"
              v-if="scope.row.cooperativeState == 'CANCEL' " :disabled="true"
              class="btn-list">
              取消合作
            </el-link> -->

            <!-- cooperativeStateName -->

            <el-link :underline="false" type="primary" @click="detailHandle(scope.row, scope.row.cooperativeStateName)"
              v-if="scope.row.businessType =='INIT' "  :disabled="scope.row.cooperativeStateName == '邀请中' " class="btn-list">{{scope.row.cooperativeStateName}}</el-link>

              <el-link :underline="false" type="primary" @click="detailHandle(scope.row, scope.row.cooperativeStateName)"
              v-else-if="scope.row.cooperativeStateName =='取消合作'  &&  scope.row.businessType =='INUSE' " class="btn-list" >{{scope.row.cooperativeStateName}}</el-link>

              <el-link :underline="false" type="primary"  :disabled="true"
              v-else class="btn-list" >{{scope.row.cooperativeStateName}}</el-link>

          </div>

          <div  v-if="activeName == '1'">
            <!--flag 是否可邀请1可邀请0不可邀请 -->
            <el-link :underline="false" type="primary" @click="areaSalesInvite(scope.row, '邀请合作')"
              v-if="scope.row.flag == 1" class="btn-list">邀请合作</el-link>
          </div>
        </template>
      </MyTable>
    </div>

  </div>
</template>

<script>
import MyTable from "@/components/myTable";

import { mapGetters } from 'vuex'
import { getSalesCooperation, salesCooperationExamine, getSalesUserListPage, areaSalesInvite } from "@/api/externalSalesRule";
import avater from "/public/img/default.png";

export default {
  components: { MyTable, },
  data() {
    return {
      avater: avater,
      formTool: {
      },
      activeName: '1',
      btnType: 'add',
      selectionList: [],
      operation: true,
      selection: false,
      index: true,
      tableData: [],
      options: [{
        value: 'YES',
        label: '已合作'
      },
      {
        value: 'NO',
        label: '未合作'
      },
      {
        value: 'CANCEL',
        label: '取消合作'
      },],
      tableOption: [
        { label: "业务员ID", prop: "salesJobNumber", width: 120 },
        // { label: "业务员姓名", prop: "nameLike", width: 200 },
        { label: "客户数量", prop: "clientQuantity",width: 100  },
        { label: "已接品种数量（剩余可接数量）", prop: "cooperationBreedQuantity" },
        { label: "已接品种类型", prop: "cooperationBreedType" },
        { label: "累计销售数量", prop: "sellQuantityTotal" ,width: 150},
        { label: "累计销售金额", prop: "sellAmountTotal" ,width: 150},
        { label: "可负责客户类型", prop: "customerType" },
        { label: "可负责商品类型", prop: "goodsType" },
      ],
      page: {
        total: 10,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      dialogShow: false
    };
  },
  computed: {},
  watch: {},
  created() {

  },
  mounted() {
    this.getList();
  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
  computed: {
    ...mapGetters(['permissions']),
  },
  methods: {
    handleClick() {
      this.getList(1);
    },
    // 页码变化
    handleCurrentChange(e) {
      this.page.current = e;
      this.getList();
    },

    // 条数变化
    handleSizeChange(e) {
      this.page.size = e
      this.page.current = 1
      this.getList();
    },

    detailHandle(row, btnType) {
      this.$confirm(`确定${btnType}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          salesCooperationExamine({
            id: row.id,
            status:( btnType == '同意合作' || btnType == '邀请中') ? 'YES' : 'CANCEL'
          }).then((res) => {
            if (res !=undefined) {
              this.$message.success('提交成功')
              this.getList();
            }
          })
        })
        .catch(() => {

        })
    },

    // 重置查询条件
    reset() {
      this.formTool = {};
      this.getList(1);
    },

    // 搜索
    getList(current) {
      if (current) {
        // 搜索条件改变从第一页开始查询
        this.page.current = current;
      }

      const params = {
        ...this.formTool,
        current: this.page.current,
        size: this.page.size
      };
      params.areaCode = this.$route.query.areaCode;
      params.goodsId = this.$route.query.goodsId;
      params.shopId = this.$route.query.shopId;
      if (this.activeName == '2') {
        getSalesCooperation(params).then((res) => {
          if (res) {
            this.tableData = res.records;
            this.page.total = res.total;
          } else {
            this.tableData = [];
            this.page.total = 0;
          }
        });
      } else {
        // 可选合作
        getSalesUserListPage(params).then((res) => {
          if (res) {
            this.tableData = res.records;
            this.page.total = res.total;
          } else {
            this.tableData = [];
            this.page.total = 0;
          }
        });
      }
    },

    // 邀请业务员
    areaSalesInvite(row) {
      let params = {};
      params.userId = row.salesUserId;
      params.goodsId = this.$route.query.goodsId;
      areaSalesInvite(params).then((res) => {
        if (res) {
          this.$message.success('提交成功！');
          this.getList(1)
        }
      })
    },
    // 勾选
    handleSelectionChange(val) {
      this.selectionList = val;
    },

  },
};
</script>
<style lang='scss' scoped>
@import "@/styles/variables.scss";

.classification {
  padding: 0px;

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .top {
    display: flex;
    padding: 10px 0 10px 0;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .label {
        width: 120px;
      }

      .button {
        margin-right: 20px;

        ::v-deep .el-button {
          color: $subMenuActiveText;
          background-color: $butColor;
          border-color: $butColor;

          &:hover {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:active {
            color: $subMenuActiveText;
            background: $butColor;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:focus {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
        }
      }
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn-warp {
    display: flex;
    justify-content: space-around;


  }
}

.tabs {
  margin: 0 30px;
}
</style>
