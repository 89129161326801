var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "classification" }, [
    _c("div", { staticClass: "eachRow" }, [
      _c(
        "div",
        { staticClass: "titleView" },
        [
          _vm._m(0),
          _vm.data && _vm.data.length
            ? _c(
                "el-button",
                {
                  staticClass: "right",
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.clickAdd },
                },
                [_vm._v("新建")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        _vm._l(_vm.data, function (item, index) {
          return _vm.data && _vm.data.length
            ? _c("div", { key: index, staticClass: "advise-list" }, [
                _c("div", { staticClass: "advise-list-feedback" }, [
                  _c(
                    "div",
                    { staticClass: "feedback-left" },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c("div", { staticClass: "feedback-left-title" }, [
                          _vm._v("投诉反馈："),
                        ]),
                        _c("div", [_vm._v(_vm._s(item.adviseInfo))]),
                      ]),
                      _c("ImgUpload", {
                        staticStyle: {
                          position: "relative",
                          top: "0",
                          left: "80px",
                        },
                        attrs: { limit: 5, isEdit: true },
                        model: {
                          value: item.image,
                          callback: function ($$v) {
                            _vm.$set(item, "image", $$v)
                          },
                          expression: "item.image",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "feedback-right" }, [
                    _c("div", { staticClass: "feedback-right-time" }, [
                      _vm._v(_vm._s(item.createTime)),
                    ]),
                    _c("p", { staticClass: "status-box" }, [
                      _vm._v(_vm._s(item.status == 0 ? "处理中" : "已完成")),
                    ]),
                  ]),
                ]),
                item.status == "1"
                  ? _c(
                      "div",
                      { staticClass: "advise-list-reply" },
                      [
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c(
                            "div",
                            { staticClass: "advise-list-reply-title" },
                            [_vm._v("投诉回复：")]
                          ),
                          item.reply
                            ? _c("div", [
                                _vm._v(" " + _vm._s(item.reply) + " "),
                              ])
                            : _vm._e(),
                        ]),
                        item.replyImage && item.replyImage.length
                          ? _c("ImgUpload", {
                              staticStyle: {
                                position: "relative",
                                top: "0",
                                left: "80px",
                              },
                              attrs: { limit: 5, isEdit: true },
                              model: {
                                value: item.replyImage,
                                callback: function ($$v) {
                                  _vm.$set(item, "replyImage", $$v)
                                },
                                expression: "item.replyImage",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e()
        }),
        0
      ),
      !_vm.data.length
        ? _c(
            "div",
            { staticClass: "no-data" },
            [
              _c("el-empty", { attrs: { description: " " } }),
              _vm._v(" 尊敬的用户，如在平台上遇到了未达到您预期的体验，请不吝"),
              _c(
                "span",
                { staticClass: "add-btn", on: { click: _vm.clickAdd } },
                [_vm._v("发起投诉建议")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { display: "flex" } }, [
      _c("div", { staticClass: "rowLine" }),
      _c("span", { staticClass: "eachRowTitle" }, [_vm._v("投诉建议")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }