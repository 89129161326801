<template>
     <el-table :data="tableData" style="width: 100%"  :height="tableHeight" v-if="tableHeight" ref="tableRef">
        <el-table-column prop="incomeTime" label="出入账时间" >
        </el-table-column>
        <el-table-column prop="erpCode" label="客户ERP编码"  >
        </el-table-column>
        <el-table-column prop="customerName" label="客户名称" >
        </el-table-column>
        <el-table-column label="收支金额（元）" align="right">
            <div  slot-scope="scope" class="flexRight" >
                <div>{{ scope.row.amountType == 'INCOME' ?'+':'-' }}{{scope.row.amount.toFixed(2)}}</div>
                <!-- <div>余额：{{scope.row.currentAmount}}</div> -->
            </div>
        </el-table-column>
        <el-table-column prop="type" label="收支类型" >
            <!-- amountType	income,expenditure 收入，提现,可用值:INCOME,EXPENDITURE -->
            <div  slot-scope="scope">
                {{ scope.row.amountType == 'INCOME' ?'收入':'提现' }}
            </div>
        </el-table-column>
        <el-table-column prop="orderTime" label="订单创建时间" >
        </el-table-column>
        <el-table-column prop="orderCode" label="订单ID" >
        </el-table-column>
        <el-table-column prop="status" label="银行是否已清算" >
            <div  slot-scope="scope">
                是
            </div>
        </el-table-column>
    </el-table>
  
</template>
<script>
    import { getMediCompanyWithDrawList, getMediDeptWithDrawList } from "@/api/fund/fundApi";
    export default{
        props:{
            tableHeight:{
                type:Number,
                default:()=>0
            },
            fundType:{
                type:String,
                default:()=>''
            }
        },
        watch:{
            // tableData(newVal, oldVal){
            //     if (newVal != oldVal) {
            //         this.$nextTick(() => {
            //         this.$refs.tableRef.bodyWrapper.scrollTop =0;
            //         this.$refs.tableRef.doLayout()
            //         })
            //     }
            // }
        },
        data(){
            return {
                tableData:[]
            }
        },
        mounted(){
           
        },
        methods:{
           async getList(params){
                const resp = this.fundType == 'dept' ? await getMediDeptWithDrawList(params) : await getMediCompanyWithDrawList(params)
                if(resp && resp.records){
                    this.tableData = resp.records
                }else{
                    this.tableData = []
                }
                this.$emit('pageInfoDidChange',{ total : resp.total, current:resp.current })
           }
        }
    }
</script>
<style lang="scss" scoped>
</style>