// 店铺信息缓存
const state = {
    platformBond: null  // 平台保证金
  };
  
  const mutations = {
    SET_PLATFORM_BOND: (state, data) => {
      state.platformBond = data;
    },
  };
  const actions = {
    setPlatformBond({ commit }, load) {
      commit("SET_PLATFORM_BOND", load);
    },
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
  };
  