var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mainView" }, [
    _c("div", { staticClass: "header" }, [_vm._v("加入仓库")]),
    _c(
      "div",
      {
        staticClass: "eachRow",
        staticStyle: { "border-bottom": "1px solid #f5f5f5" },
      },
      [
        _vm.form.goodsBaseInfo && _vm.form.goodsBaseInfo.status == "SY_SOLDOUT"
          ? _c("div", [
              _c("span", [_vm._v(" 下架原因 ")]),
              _c(
                "div",
                { staticStyle: { padding: "10px 0", width: "600px" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      value: _vm.form.goodsBaseInfo.checkReason,
                      disabled: "",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._m(0),
        _c(
          "div",
          { staticClass: "goodsInfoView" },
          [
            _vm.activeName == "SKU_FAIL" ||
            _vm.activeName == "SKU_AWAIT" ||
            _vm.activeName == "add"
              ? _c(
                  "el-button",
                  {
                    staticClass: "detailBtn",
                    attrs: { type: "text" },
                    on: { click: _vm.detailError },
                  },
                  [_vm._v("详情/纠错")]
                )
              : _vm._e(),
            _c("el-image", {
              staticStyle: { width: "100px", height: "100px" },
              attrs: {
                src: _vm.form.goodsBaseInfo.image || _vm.avater,
                "preview-src-list": [
                  _vm.form.goodsBaseInfo.image || _vm.avater,
                ],
                alt: "",
              },
            }),
            _c("div", { staticClass: "goodsInfoText" }, [
              _c("div", { staticClass: "textInLine" }, [
                _c("span", { staticClass: "textBetween" }, [
                  _vm._v("产品名称"),
                ]),
                _c("span", [
                  _vm._v(_vm._s(_vm.form.goodsBaseInfo.name || "-")),
                ]),
              ]),
              _c("div", { staticClass: "textInLine" }, [
                _c("span", { staticClass: "textBetween" }, [
                  _vm._v("批准文号"),
                ]),
                _c("span", [
                  _vm._v(_vm._s(_vm.form.goodsBaseInfo.approvalNumber || "-")),
                ]),
              ]),
              _c("div", { staticClass: "textInLine" }, [
                _c("span", { staticClass: "textBetween" }, [_vm._v("规格")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.form.goodsBaseInfo.specification || "-")),
                ]),
              ]),
              _c("div", { staticClass: "textInLine" }, [
                _c("span", { staticClass: "textBetween" }, [
                  _vm._v("生产厂家"),
                ]),
                _c("span", [
                  _vm._v(_vm._s(_vm.form.goodsBaseInfo.factory || "-")),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "eachRow" },
      [
        _vm._m(1),
        _vm._l(_vm.form.skuList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "table",
              staticStyle: {
                padding: "8px 0",
                "border-bottom": "1px solid #f5f5f5",
              },
            },
            [
              _c("div", { staticClass: "tableBox" }, [
                _c(
                  "span",
                  { staticClass: "spanStyle" },
                  [
                    _vm._v(" SKU状态 "),
                    _c("el-input", {
                      staticClass: "inputStyle",
                      attrs: {
                        disabled: true,
                        value: _vm.getStatus(item.status),
                        size: "mini",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "spanStyle" },
                  [
                    _vm._v(" ERP编码 "),
                    _c("el-input", {
                      staticClass: "inputStyle",
                      attrs: { disabled: true, size: "mini" },
                      model: {
                        value: item.medicineCode,
                        callback: function ($$v) {
                          _vm.$set(item, "medicineCode", $$v)
                        },
                        expression: "item.medicineCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "spanStyle" },
                  [
                    _vm._v(" 价格/元 "),
                    _c("el-input", {
                      staticClass: "inputStyle",
                      attrs: { disabled: true, size: "mini" },
                      model: {
                        value: item.salePrice,
                        callback: function ($$v) {
                          _vm.$set(item, "salePrice", $$v)
                        },
                        expression: "item.salePrice",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "spanStyle" },
                  [
                    _vm._v(" 市场价/元 "),
                    _c("el-input", {
                      staticClass: "inputStyle",
                      attrs: { disabled: true, size: "mini" },
                      model: {
                        value: item.marketPrice,
                        callback: function ($$v) {
                          _vm.$set(item, "marketPrice", $$v)
                        },
                        expression: "item.marketPrice",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "spanStyle" },
                  [
                    _vm._v(" 建议零售价/元 "),
                    _c("el-input", {
                      staticClass: "inputStyle",
                      attrs: { disabled: true, size: "mini" },
                      model: {
                        value: item.purchasePrice,
                        callback: function ($$v) {
                          _vm.$set(item, "purchasePrice", $$v)
                        },
                        expression: "item.purchasePrice",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "tableBox2" }, [
                _c(
                  "div",
                  { staticClass: "tableBox2Inline" },
                  [
                    _c(
                      "el-link",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.disabled,
                            expression: "!disabled",
                          },
                        ],
                        attrs: {
                          disabled:
                            item.status == "PUTAWAY" ||
                            item.status == "AWAIT" ||
                            item.status == "FAIL" ||
                            item.status == "SY_SOLDOUT",
                          underline: false,
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.listing(item)
                          },
                        },
                      },
                      [_vm._v("上架")]
                    ),
                    _c(
                      "el-link",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.disabled,
                            expression: "!disabled",
                          },
                        ],
                        staticStyle: { "margin-left": "16px" },
                        attrs: {
                          disabled:
                            item.status == "SOLDOUT" ||
                            item.status == "AWAIT" ||
                            item.status == "FAIL" ||
                            item.status == "SY_SOLDOUT",
                          underline: false,
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.delist(item)
                          },
                        },
                      },
                      [_vm._v("下架")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "tableBox2Inline" },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: { underline: false, type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.detail(item)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                    _c(
                      "el-link",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.disabled,
                            expression: "!disabled",
                          },
                        ],
                        staticStyle: { "margin-left": "16px" },
                        attrs: {
                          disabled: item.status == "SY_SOLDOUT",
                          underline: false,
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.goAddSku(item)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "16px" },
                        attrs: { underline: false, type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.caozuo(item)
                          },
                        },
                      },
                      [_vm._v("操作日志")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        }),
        !_vm.disabled
          ? _c(
              "div",
              {
                staticClass: "addGoods",
                on: {
                  click: function ($event) {
                    return _vm.goAddSku()
                  },
                },
              },
              [
                _c("span", { staticClass: "addFont" }, [_vm._v(" + ")]),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px", color: "#595959" } },
                  [_vm._v(" 添加SKU ")]
                ),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          { staticStyle: { "margin-top": "10px" } },
          [
            _c(
              "el-button",
              {
                staticStyle: { width: "160px" },
                attrs: { size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "商品信息变更日志",
              visible: _vm.dialogVisible,
              width: "70%",
              "before-close": _vm.handleClose,
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  height: "600px",
                  width: "100%",
                  overflow: "auto",
                },
              },
              [
                _c("MyTable", {
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: {
                    tableData: _vm.tableData,
                    index: false,
                    operation: false,
                    "table-option": _vm.tableOption,
                  },
                  on: {
                    "update:tableOption": function ($event) {
                      _vm.tableOption = $event
                    },
                    "update:table-option": function ($event) {
                      _vm.tableOption = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titleView" }, [
      _c("div", { staticClass: "rowLine" }),
      _c("span", { staticClass: "eachRowTitle" }, [_vm._v("商品基本信息")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titleView" }, [
      _c("div", { staticClass: "rowLine" }),
      _c("span", { staticClass: "eachRowTitle" }, [_vm._v("SKU信息")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }