var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.formTool.extendParams, function (item, key) {
      return _c(
        "div",
        { key: key },
        [
          item.input == 0 && (item.goodsBaseShow || _vm.isShowAll)
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: item.name } },
                        [
                          _c(
                            "span",
                            {
                              class: _vm.changeExtendParams[item.fieldName]
                                ? "lable-color-correct"
                                : "",
                              attrs: { slot: "label" },
                              slot: "label",
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          item.input == 1 && (item.goodsBaseShow || _vm.isShowAll)
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: item.name } },
                        [
                          _c(
                            "span",
                            {
                              class: _vm.changeExtendParams[item.fieldName]
                                ? "lable-color-correct"
                                : "",
                              attrs: { slot: "label" },
                              slot: "label",
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              type: "textarea",
                              rows: 2,
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          item.input == 2 && (item.goodsBaseShow || _vm.isShowAll)
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: item.name } },
                        [
                          _c(
                            "span",
                            {
                              class: _vm.changeExtendParams[item.fieldName]
                                ? "lable-color-correct"
                                : "",
                              attrs: { slot: "label" },
                              slot: "label",
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _c(
                            "el-select",
                            {
                              attrs: { size: "small", placeholder: "请选择" },
                              model: {
                                value: item.value,
                                callback: function ($$v) {
                                  _vm.$set(item, "value", $$v)
                                },
                                expression: "item.value",
                              },
                            },
                            _vm._l(item.selectList, function (e, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: { label: e, value: e },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          item.input == 3 && (item.goodsBaseShow || _vm.isShowAll)
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: item.name } },
                        [
                          _c(
                            "span",
                            {
                              class: _vm.changeExtendParams[item.fieldName]
                                ? "lable-color-correct"
                                : "",
                              attrs: { slot: "label" },
                              slot: "label",
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _c("fileUpload", {
                            on: { onRemoveHandler: _vm.onRemoveHandler },
                            model: {
                              value: _vm.formTool.fileList,
                              callback: function ($$v) {
                                _vm.$set(_vm.formTool, "fileList", $$v)
                              },
                              expression: "formTool.fileList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }