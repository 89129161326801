var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "afterSale classification" }, [
    _c(
      "div",
      { staticStyle: { padding: "0px" } },
      [
        _c("p", [_vm._v("正在开发中")]),
        _c("StepWay", {
          attrs: {
            "step-array": [
              { name: "提交申请" },
              { name: "卖家审批" },
              { name: "买家退货" },
              { name: "卖家收货" },
              { name: "退款中" },
              { name: "完成" },
            ],
            "index-step": _vm.indexStep,
          },
          on: { stepBtn: _vm.stepBtn },
        }),
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "" } },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  size: "small",
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticStyle: { display: "flex", "align-items": "center" },
                    attrs: { label: "售后商品", prop: "name" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "margin-left": "-100px",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/shop.png"),
                            width: "100px",
                            alt: "",
                          },
                        }),
                        _c("div", { staticStyle: { "margin-left": "20px" } }, [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                margin: "0",
                                "line-height": "20px",
                              },
                            },
                            [_vm._v("葡萄糖注射液")]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                margin: "0",
                                "line-height": "20px",
                              },
                            },
                            [_vm._v("国药准字H43022082")]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                margin: "0",
                                "line-height": "20px",
                              },
                            },
                            [_vm._v("湖南科伦制药有限公司")]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                margin: "0",
                                "line-height": "20px",
                              },
                            },
                            [_vm._v("82S*1瓶/1瓶")]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "售后数量", prop: "region" } },
                  [_c("el-input-number")],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "售后方式", prop: "region" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择活动区域" },
                        model: {
                          value: _vm.ruleForm.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "region", $$v)
                          },
                          expression: "ruleForm.region",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "区域一", value: "shanghai" },
                        }),
                        _c("el-option", {
                          attrs: { label: "区域二", value: "beijing" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "售后原因" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          prop: "region",
                          placeholder: "请选择活动区域",
                        },
                        model: {
                          value: _vm.ruleForm.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "region", $$v)
                          },
                          expression: "ruleForm.region",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "区域一", value: "shanghai" },
                        }),
                        _c("el-option", {
                          attrs: { label: "区域二", value: "beijing" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "售后金额", prop: "jine" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请输入内容" },
                      model: {
                        value: _vm.ruleForm.jine,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "jine", $$v)
                        },
                        expression: "ruleForm.jine",
                      },
                    }),
                    _vm._v(" 元 "),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "说明", prop: "desc" } },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", placeholder: "200字以内" },
                      model: {
                        value: _vm.ruleForm.desc,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "desc", $$v)
                        },
                        expression: "ruleForm.desc",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-bottom": "0px!important" },
                    attrs: { label: "图片" },
                  },
                  [
                    _c(
                      "ImgUpload",
                      {
                        staticStyle: { position: "relative", top: "-8px" },
                        attrs: { limit: 3 },
                        model: {
                          value: _vm.ruleForm.imageList,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "imageList", $$v)
                          },
                          expression: "ruleForm.imageList",
                        },
                      },
                      [
                        _c("template", { slot: "tip" }, [
                          _c(
                            "p",
                            {
                              staticClass: "el-upload__tip",
                              staticStyle: { margin: "0px" },
                            },
                            [
                              _vm._v(
                                " 备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过5M;第一张图片会作为商品展示图 "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.submitForm("ruleForm")
                          },
                        },
                      },
                      [_vm._v("提交")]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.resetForm("ruleForm")
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }