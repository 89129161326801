<template>
    <div class="fundMain" v-if="canEdit">
        <div class="stepView">
            <el-steps :active="activeIndex" align-center>
                <el-step title="企业类型"></el-step>
                <el-step title="信息上传"></el-step>
                <el-step title="等待审核"></el-step>
            </el-steps>
        </div>
        <el-scrollbar style="height: calc(100vh - 260px);width: 100%;" wrap-style="overflow-x:hidden;" >
            <div v-if="activeIndex == 1" class="columnCenter">
                <div class="notiView">请填写您的收款账户，以便顾客能够向您支付款项。</div>
                <div class="authType">
                    <div v-for="item, index in authTypes" :key="index" class="eachRow"
                        :class="authTypeIndex == index ? 'rowSelect' : ''" @click="authTypeIndex = index">
                        <div class="name" :class="authTypeIndex == index ? 'divSelect' : ''">{{ item.name }}</div>
                        <div class="txt-line-2 desc" :class="authTypeIndex == index ? 'divSelect' : ''">{{ item.desc }}
                        </div>
                        <img :src="selectIcon" alt="" class="icon" v-if="authTypeIndex == index">
                    </div>
                </div>
                <el-divider></el-divider>
            </div>
            <div v-if="activeIndex == 2 && infoStep == 1" class="columnCenter">
                <div class="inLine">
                    <div class="formView">
                        <div class="topView">
                            <span class="require">*</span>
                            <span class="title">法人身份证正反面</span>
                            <!-- <el-link :underline="false" style="margin-left: auto;color: #f5222d;">查看示例</el-link> -->
                        </div>
                        <el-divider></el-divider>
                        <div class="idcardImg">
                            <div @click="handleUpload('idcard_front')" class="imgViewSurround">
                                <div class="addImage">
                                    <el-image :src="IConst_Add_Icon" />
                                </div>
                                <img :src="idcard_front" alt="" v-if="idcard_front" class="img"
                                    @click.stop="handlePreview('idcard_front')" />
                                <el-image :src="IConst_Del_Icon" class="delicon" v-if="idcard_front"
                                    @click.stop="deleImage('idcard_front')" />
                            </div>
                            <div style="width: 12px;"></div>
                            <div @click="handleUpload('idcard_back')" class="imgViewSurround">
                                <div class="addImage">
                                    <el-image :src="IConst_Add_Icon" />
                                </div>
                                <img :src="idcard_back" alt="" v-if="idcard_back" class="img"
                                    @click.stop="handlePreview('idcard_back')" />
                                <el-image :src="IConst_Del_Icon" class="delicon" v-if="idcard_back"
                                    @click.stop="deleImage('idcard_back')" />
                            </div>
                        </div>
                        <div class="inputView">
                            <el-form>
                                <el-form-item label="身份证号码" required>
                                    <el-input placeholder="请输入" size="small" v-model="form.idCard.idCardNo"></el-input>
                                </el-form-item>
                                <el-form-item label="有效期" required>
                                    <el-date-picker v-model="form.validateTimeIdcard" type="daterange" range-separator="至"
                                        start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%;" size="small"
                                        :disabled="form.timeType == '长期有效'" @change="changeIdCardTime"></el-date-picker>
                                </el-form-item>
                                <el-form-item>
                                    <el-radio-group v-model="form.timeType">
                                        <el-radio label="固定期限">固定期限</el-radio>
                                        <el-radio label="长期有效">长期有效</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <div class="formView">
                        <div class="topView">
                            <span class="require">*</span>
                            <span class="title">营业执照</span>
                            <!-- <el-link :underline="false" style="margin-left: auto;color: #f5222d;">查看示例</el-link> -->
                        </div>
                        <el-divider></el-divider>
                        <div class="idcardImg">
                            <div @click="handleUpload('business_license')" class="imgViewSurround">
                                <div class="addImage">
                                    <el-image :src="IConst_Add_Icon" />
                                </div>
                                <img :src="business_license" alt="" v-if="business_license" class="img"
                                    @click.stop="handlePreview('business_license')" />
                                <el-image :src="IConst_Del_Icon" class="delicon" v-if="business_license"
                                    @click.stop="deleImage('business_license')" />
                            </div>
                        </div>
                        <div class="inputView">
                            <el-form>
                                <el-form-item label="统一社会信用代码" required>
                                    <el-input placeholder="请输入" size="small"
                                        v-model="form.merchant.businessLicenseNo"></el-input>
                                </el-form-item>
                                <el-form-item label="有效期至" required>
                                    <el-date-picker v-model="form.validateTimeBusiness" type="date" placeholder="选择日期"
                                        style="width: 100%;" size="small" :disabled="form.businessTimeType == '长期有效'"
                                        @change="changeBusinessTime"></el-date-picker>
                                </el-form-item>
                                <el-form-item>
                                    <el-radio-group v-model="form.businessTimeType">
                                        <el-radio label="固定期限">固定期限</el-radio>
                                        <el-radio label="长期有效">长期有效</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
                <div class="inLine bottom">
                    <el-form :inline="true" label-position="top">
                        <el-form-item label="法人姓名" required>
                            <el-input placeholder="请输入" size="small" v-model="form.idCard.realName"
                                style="width: 300px;"></el-input>
                        </el-form-item>
                        <el-form-item label="法人手机号" required>
                            <el-input placeholder="请输入" size="small" v-model="form.merchant.legalPhone"
                                style="width: 300px;"></el-input>
                        </el-form-item>
                        <el-form-item label="商户名称" required>
                            <el-input placeholder="请输入" size="small" v-model="form.merchant.merchantName"
                                style="width: 300px;"></el-input>
                        </el-form-item>
                        <el-form-item label="所在地区" required>
                            <el-cascader :options="areaList" clearable size="small" placeholder="请选择地区"
                                v-model="form.merchant.addressInfo" :props="areaProps" style="width: 300px;" @change="handleChangeArea" ref="areaRef" >
                                <template slot-scope="{ node, data }">
                                    <span>{{ data.title }}</span>
                                </template>
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="详细地址" required>
                            <el-input placeholder="请输入" size="small" v-model="form.merchant.addrDetail"
                                style="width: 300px;"></el-input>
                        </el-form-item>
                        <el-form-item label="注册邮箱">
                            <el-input placeholder="请输入" size="small" v-model="form.merchant.regEmail"
                                style="width: 300px;"></el-input>
                        </el-form-item>
                        <el-form-item label="商户别名（选填）">
                            <el-input placeholder="请输入" size="small" v-model="form.merchant.merchantNameAlias"
                                style="width: 300px;"></el-input>
                        </el-form-item>
                    </el-form>
                </div>

            </div>
            <div v-if="activeIndex == 2 && infoStep == 2" class="columnCenter">
                <div class="inLine" style="align-items: flex-start;">
                    <div class="formView">
                        <div class="topView">
                            <span class="require">*</span>
                            <span class="title">企业基本用户信息</span>
                        </div>
                        <el-divider></el-divider>
                        <div class="inputView">
                            <el-form>
                                <el-form-item label="开户行" required>
                                    <el-select v-model="form.merchant.bankName" filterable remote reserve-keyword value-key="itemCode"
                                        placeholder="请输入" :remote-method="remoteMethod" size="small" style="width:100%;" @change="changeBankCode">
                                        <el-option v-for="item,index in bankList" :key="index" :label="item.itemName"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="基本账户号" required>
                                    <el-input placeholder="请输入" size="small" v-model="form.merchant.bankCardNo"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <div class="formView">
                        <div class="topView">
                            <span class="require">*</span>
                            <span class="title">绑定银行卡信息（用于收款）</span>
                        </div>
                        <el-divider></el-divider>
                        <div class="inputView">
                            <el-form>
                                <el-form-item>
                                    <el-radio-group v-model="form.accountType" @change="changeAccountType">
                                        <el-radio label="同基本户">同基本户</el-radio>
                                        <el-radio label="其他对公账户">其他对公账户</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="开户行" required v-if="form.accountType == '其他对公账户'">
                                    <el-select v-model="form.bankCard.bankName" filterable remote reserve-keyword value-key="itemCode"
                                        placeholder="请输入" :remote-method="remoteMethod" size="small" style="width:100%;" @change="changeBankCodeBranch">
                                        <el-option v-for="item,index in bankList" :key="index" :label="item.itemName"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="开户地" required>
                                    <el-cascader :options="areaList" clearable size="small" placeholder="请选择开户地"
                                        v-model="form.bankCard.addressInfo" :props="areaProps" style="width: 100%;" @change="changeOpenBankAddress">
                                        <template slot-scope="{ node, data }">
                                            <span>{{ data.title }}</span>
                                        </template>
                                    </el-cascader>
                                </el-form-item>
                                <el-form-item label="开户支行" required>
                                    <!-- <el-input placeholder="请输入" size="small" v-model="form.idcard"></el-input> -->
                                    <el-select v-model="form.bankCard.linkedBrbankname" placeholder="请选择开户支行" size="small" style="width:100%;" @focus="getBankCodeList" value-key="cnaps_code"  @change="changeBankBranch">
                                        <el-option v-for="item,index in linkBankList" :key="index" :label="item.brabank_name"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="银行卡号" required v-if="form.accountType == '其他对公账户'">
                                    <el-input placeholder="请输入" size="small" v-model="form.bankCard.bankCardNo"></el-input>
                                </el-form-item>
                                <el-form-item label="支付密码" required>
                                    <el-input placeholder="请输入" size="small" v-model="form.password" show-password maxlength="6"></el-input>
                                </el-form-item>
                                <el-form-item label="手机验证码" required>
                                    <el-input placeholder="请输入" size="small" v-model="msgCode">
                                        <div slot="append">
                                            <el-button style="color: #f5222d;" v-if="sendStatus == 'init'"
                                                @click="acquire">获取验证码</el-button>
                                            <el-statistic v-else-if="sendStatus == 'sending'" :value="deadline2"
                                                @finish="finish" format="ss" time-indices>
                                                <template slot="suffix"> 秒后重发 </template>
                                            </el-statistic>
                                        </div>
                                    </el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
        <div >
            <el-button type="defalut" size="small" @click="goBack" v-if="activeIndex == 2">上一步</el-button>
            <el-button type="primary" size="small" @click="goNext">{{ (activeIndex == 2 && infoStep == 2) ? '提交信息' : '下一步'
            }}</el-button>
        </div>
        <KangbeiUploadImage ref="imgupload" @upload="uploadSubmit" :memberId="form.memberId"/>
        <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="urls" />
    </div>
    <div v-else>
        <FundAccountDetail @edit="changeEdit" ref="detailForm"/>
    </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import selectIcon from "@/assets/zz/selected-icon.png";
import KangbeiUploadImage from "./component/kangbeiUploadImage.vue";
import FundAccountDetail from "./fundAccountDetail.vue";
import IConst_Add_Icon from "@/assets/zz/add-icon.png"
import IConst_Del_Icon from "@/assets/zz/del-icon.png"
import { preRegister, dicItemExtList, cityAll, getBankCode, applyVerifyCode , openAcctApply, verifyCode, queryAuditResult, getShopMerchantNo, getShopMerchantDetail,shopMerchantDetail} from "@/api/fund/fundApi";
import dayjs from "dayjs";
import _ from  'lodash'
export default {
    components: {
        KangbeiUploadImage,
        ElImageViewer,
        FundAccountDetail
    },
    data() {
        return {
            areaProps: { value: "id", label: "title" },
            areaList: [],//开户地列表
            bankList: [],//银行列表
            linkBankList:[],//开户行支行
            activeIndex: 1,
            // authTypes: [{ name: '企业经营', desc: '需要准备营业执照，法人身份证，法人银行卡信息' }, { name: '个体经营', desc: '需要准备营业执照，法人身份证，法人银行卡信息' }],
            authTypes: [{ name: '企业经营', desc: '需要准备营业执照，法人身份证，法人银行卡信息' }],
            authTypeIndex: 0,
            selectIcon: selectIcon,
            IConst_Add_Icon: IConst_Add_Icon,
            IConst_Del_Icon: IConst_Del_Icon,
            action: process.env.VUE_APP_BASE_API + '/shop/shopcertify/upload',
            idcard_front: '',
            idcard_back: '',
            business_license: '',
            msgCode:'',
            form: {
                timeType: '',//身份证有效期
                businessTimeType: '',//营业执照有效期
                accountType: '同基本户',//账户类型  对公|基本户
                address: '',
                validateTimeIdcard: '',
                validateTimeBusiness: '',
                memberId: '',// "97becc1f90404a3aa1132108f0f1dd85",
                password: '',// "C2HbcMis6K7lkcaLf0C9e0UoV40q8IO07ZdagcI46QSzUzdWiZzLs6ZZjxxmkC67uFmI/sFJmgjnGP4qL+YdDUHuH3Ax4VR4vAuQwk2Wrn2Q/qo7Zilkf84B3aAJcEEwuwYXqh97SdkejHy8o1wPre4FjEF25g5YPV58e5jY7Lk=",
                merchant: { //商户信息
                    merchantName: '',// "湖南永正医药有限公司",
                    merchantNameAlias: '',// "湖南永正医药有限公司",
                    merchantType: '',// "C",
                    regEmail: '',// "cc@hzypk.com",
                    businessLicenseNo: '',// "91430100329379204L",
                    addrProvinceCode: '',// "430000",
                    addrCityCode: '',// "430100",
                    addrAreaCode: '',// "430121",
                    addrDetail: '',//"湖南省长沙市长沙县黄花镇合心路95号A栋4层413房",
                    businessValidStart: '',// "2015-01-21",
                    businessValidEnd: '',// "9999-12-31",
                    contactsName: '',// "范永芳",
                    contactsPhone: '',// "18907323218",
                    legalPhone: '',// "18907323218",
                    bankCode: '',// "01040000",
                    bankName: '',// "中国银行",
                    bankCardNo: '',// "611942008888"
                    addressInfo:null
                },
                idCard: {
                    idCardNo: '',// "41280119830315001X",
                    realName: '',// "范永芳",
                    periodOfValidStart: '',// "2015-02-17",
                    periodOfValidEnd: '',// "2035-02-17"
                },
                bankCard: { //企业银行卡信息，对公账户
                    bankCode: '',// "01040000",
                    bankName: '',// "中国银行",
                    bankCardNo: '',// "611942008888",
                    linkedBrbankno: '',// "104551004137",
                    linkedBrbankname: '',//"中国银行股份有限公司长沙市星沙汽配城支行",
                    linkedAcctname: '',// "湖南永正医药有限公司",
                    addrProvinceCode: '',// "430000",
                    addrCityCode: '',// "430100"
                    linkedBrbankInfo:null,// {brabank_name: "中国建设银行股份有限公司唐山冶金支行"cnaps_code: "105124000046"}
                    addressInfo:null
                }
            },
            showViewer: false,
            urls: [],
            infoStep: 1,
            deadline2: Date.now() + 1000 * 60, //倒计时
            sendStatus: 'init',
            canEdit:true,
        }
    },
    computed: {

    },
    created(){
        this.initData()    
        //this.getBankCodeList()
    },
    mounted() {
    },
    methods: {
        async initData(){
            await this.getAreaList()
            await this.getAuditResult()//获取当前审核状态
            await this.getGetKangbeiRegisterInfo()
        },
        async getAuditResult(){
            const res = await getShopMerchantNo()//获取当前店铺的康贝号
            if ( res && res.data) {
                const resp = await queryAuditResult({ merchantNo:res.data })//使用康贝号查询当前康贝注册状态
                 //0待审核 1审核通过 -1审核驳回修改 -2审核拒绝 3作废
                 if (resp.data != null) {//提交过不能编辑
                    this.canEdit = false
                    this.$nextTick(()=>{
                        this.$refs.detailForm.showStatusText(resp.data)
                    })
                }
            }
        },
        async getGetKangbeiRegisterInfo(){
            const resp = await getShopMerchantDetail()
            if (resp && resp.data) {
                let newForm = _.cloneDeep(resp.data) 
                const { merchant, idCard, bankCard } = newForm
                if (merchant.businessValidEnd) {
                    newForm['validateTimeBusiness'] = dayjs(merchant.businessValidEnd).toDate()
                    if (merchant.businessValidEnd == '9999-12-31') {
                        newForm['businessTimeType'] = '长期有效'
                    }else{
                        newForm['businessTimeType'] = '固定期限'
                    }
                }
                if (idCard.periodOfValidEnd && idCard.periodOfValidStart) {
                    newForm.validateTimeIdcard = [dayjs(idCard.periodOfValidStart).toDate(), dayjs(idCard.periodOfValidEnd).toDate()]
                    if (idCard.periodOfValidEnd == '9999-12-31') {
                        newForm['timeType'] = '长期有效'
                    }else{
                        newForm['timeType'] = '固定期限'
                    }
                }
                if (merchant.addrProvinceCode && merchant.addrCityCode && merchant.addrAreaCode) {
                    // this.getAddressStr(merchant.addrProvinceCode,merchant.addrCityCode,merchant.addrAreaCode)
                    merchant.addressInfo = [Number(merchant.addrProvinceCode) ,Number(merchant.addrCityCode) , Number(merchant.addrAreaCode) ]
                }
                if(bankCard.linkedBrbankno){//存在支行编码 则 选择了不同号
                    newForm.accountType = '其他对公账户'
                }
                if (bankCard.addrProvinceCode && bankCard.addrCityCode) {
                    bankCard.addressInfo = [Number(bankCard.addrProvinceCode),Number(bankCard.addrCityCode),Number(newForm.addrAreaCode)]
                }
                if (bankCard.linkedBrbankno && bankCard.linkedBrbankname) {
                    bankCard.linkedBrbankInfo = {brabank_name: bankCard.linkedBrbankname,cnaps_code: bankCard.linkedBrbankno}
                }
                if(newForm.idCardFrontUrl){
                    this.idcard_front = newForm.idCardFrontUrl
                }
                if(newForm.idCardBackUrl){
                    this.idcard_back = newForm.idCardBackUrl
                }
                if(newForm.businessLicenseUrl){
                    this.business_license = newForm.businessLicenseUrl
                }
                this.form = newForm
                this.$refs.detailForm.resetAreaList(this.areaList)
                this.$refs.detailForm.resetForm(newForm)
                this.$refs.detailForm.resetImageUrl({
                    idcard_front:this.idcard_front,
                    idcard_back:this.idcard_back,
                    business_license:this.business_license
                })
            }
        },
        changeBankBranch(e){
            // 选择支行 brabank_name: "中国建设银行总行(不受理个人业务)" cnaps_code: "105100000017"
            this.form.bankCard.linkedBrbankname = e.brabank_name
            this.form.bankCard.linkedBrbankno = e.cnaps_code
            
        },
        changeAccountType(){
            this.linkBankList = []
            this.form.bankCard.linkedBrbankno = ''
        },
        changeBankCode(e){
            this.form.merchant.bankCode = e.itemCode
            this.form.merchant.bankName = e.itemName
        },
        changeBankCodeBranch(e){
            this.form.bankCard.bankCode = e.itemCode
            this.form.bankCard.bankName = e.itemName
        },
        changeOpenBankAddress(e){

            this.linkBankList = []
            this.form.bankCard.linkedBrbankno = ''

        },
        handleChangeArea(e) {
            // const areaStrArr = this.$refs.areaRef.getCheckedNodes()[0].pathLabels
            
        },
        async getAreaList() {
            // 获取地区列表
            const resp = await cityAll()
            if (resp && resp.data) {
                this.areaList = resp.data
            }
        },
        async getBankCodeList(){
            const { addressInfo } = this.form.bankCard
            if (!addressInfo || !addressInfo.length) {
                this.$message.warning('请选择地区')
                return 
            }
            let obj = {
                city_code: addressInfo[1],//	支行城市
            }
            if (this.form.accountType == '同基本户') {
                const { bankCode, bankName  } = this.form.merchant
                if (!bankCode) {
                    this.$message.warning('请选择开户银行')
                    return 
                }
                obj.bank_code = bankCode//	银行编码		false	
                obj.brabank_name = bankName//	支行名称		false	
            } else {
                const { bankCode, bankName  } = this.form.bankCard
                if (!bankCode) {
                    this.$message.warning('请选择开户银行')
                    return 
                }
                obj.bank_code = bankCode//	银行编码		false	
                obj.brabank_name = bankName//	支行名称		false	
            }
          
            const resp = await getBankCode(obj)
            if(resp && resp.data && resp.data.card_list){
                this.linkBankList = resp.data.card_list
            }
        },
       
        remoteMethod: _.throttle( async function(e) {
            let obj = {
                itemName: e,
                parentCode: 'bank'
            }
            const resp = await dicItemExtList(obj)
            if(resp && resp.data){
                this.bankList = resp.data
            }
        },1000) ,
        handleUpload(type) {

            this.$refs["imgupload"].open(type);
        },
        uploadSubmit(e) {
            const { data } = e
            const { type } = data///文件类型（ idcard_front 身份证正面照 idcard_back 身份证反面照 bankcard银行卡 business_license营业执照）
            const { url } = data.oss
            this[type] = url
            if ("business_license" == type) {
                if (data.ocr && data.ocr.data) {
                    const { name, credit, address } = data.ocr.data
                    this.form.merchant.merchantName = name
                    this.form.merchant.businessLicenseNo = credit
                    this.form.merchant.addrDetail = address
                } else {
                    this.$message.error('请上传正确的图片')
                    return
                }
            } else if ("idcard_front" == type) {
                if (data.ocr && data.ocr.error) {
                    this.$message.error('请上传正确的图片')
                    return
                }
                if (data.ocr && data.ocr.num) {
                    const { num, name } = data.ocr
                    this.form.idCard.idCardNo = num
                    this.form.idCard.realName = name
                }
            } else if ("idcard_back" == type) {
                if (data.ocr && data.ocr.success) {
                    const { end_date , start_date} = data.ocr
                    this.form.validateTimeIdcard = [dayjs(start_date).toDate(), dayjs(end_date).toDate()]
                    this.form.timeType = '固定期限'
                    // this.form.idCard.periodOfValidEnd = dayjs(end_date).format('yyyy-MM-dd') 
                    // this.form.idCard.periodOfValidStart =  dayjs(start_date).format('yyyy-MM-dd') 
                }else{
                    this.$message.error('请上传正确的图片')
                    return
                }
            }
        },
        handlePreview(type) {
            // 预览
            this.urls = [this[type]]
            this.showViewer = true
        },
        deleImage(type) {
            // 删除图片
            this[type] = ''
        },
        closeViewer() {
            this.showViewer = false
        },
        goBack() {
            if (this.infoStep == 2) {
                this.infoStep = 1
                this.activeIndex = 2
            } else if (this.infoStep == 1) {
                this.activeIndex = 1
            }

        },
        async goNext() {
            console.log('this.form', this.form);
            if (this.activeIndex == 1) {
                // 康贝开户-预注册
                const { username } = this.$store.state.user.user_info;
                var reg = /^1[3-9]\d{9}$/
                if (!reg.test(username)) {
                    this.$message.warning('用户手机号码不正确')
                    return
                }
                const resp = await preRegister({
                    merchantType: "C",//	商户类型，A个人 B个体 C企业
                    phone: username
                })
                if (resp && resp.data) {
                    this.form.memberId = resp.data
                    this.activeIndex += 1
                    this.infoStep = 1
                }

            } else if (this.activeIndex == 2) {
                if (this.activeIndex == 2 && this.infoStep == 2) {
                    verifyCode({
                        memberId: this.form.memberId,
                        merchantType: "C",
                        verifyCode: this.msgCode
                    }).then((resp)=>{
                        if(resp.code == 0){
                            this.doVarify()
                        }
                    })
                  
                }
                this.infoStep = 2
            }

        },

        async acquire() {
            // 发送验证码
            const resp = await applyVerifyCode()
            if(resp.code == 0){
                this.deadline2 = Date.now() + 1000 * 60
                this.sendStatus = 'sending';
            }
        },
        async doVarify (){
            const { validateTimeBusiness, validateTimeIdcard } = this.form
            if (validateTimeIdcard && validateTimeIdcard.length) {
                this.form.idCard.periodOfValidStart = dayjs(validateTimeIdcard[0]).format('YYYY-MM-DD')
                this.form.idCard.periodOfValidEnd = dayjs(validateTimeIdcard[1]).format('YYYY-MM-DD')
            }
            if (this.form.timeType == '长期有效') {
                this.form.idCard.periodOfValidEnd = '9999-12-31'
            }
            if (validateTimeBusiness) {
                this.form.merchant.businessValidEnd = dayjs(validateTimeBusiness).format('YYYY-MM-DD')
            }
            if (this.form.businessTimeType == '长期有效') {
                this.form.merchant.businessValidEnd = '9999-12-31'
            }
            if (this.form.merchant.addressInfo && this.form.merchant.addressInfo.length) {
                this.form.merchant.addrProvinceCode = this.form.merchant.addressInfo[0]
                this.form.merchant.addrCityCode = this.form.merchant.addressInfo[1]
                this.form.merchant.addrAreaCode = this.form.merchant.addressInfo[2]
            }
            if (this.form.bankCard.addressInfo && this.form.bankCard.addressInfo.length) {
                this.form.bankCard.addrProvinceCode = this.form.bankCard.addressInfo[0]
                this.form.bankCard.addrCityCode = this.form.bankCard.addressInfo[1]
                this.form['addrAreaCode'] = this.form.bankCard.addressInfo.length > 1 ? this.form.bankCard.addressInfo[2] :''
            }
            if (this.form.accountType == '同基本户') {
                this.form.bankCard.bankCardNo = this.form.merchant.bankCardNo  //如果设置为同基本户 提交时需要将对公账户设置为基本户一致
                this.form.bankCard.bankName = this.form.merchant.bankName  //如果设置为同基本户 提交时需要将开户行设置为基本户一致
                this.form.bankCard.bankCode = this.form.merchant.bankCode  //如果设置为同基本户 提交时需要将开户行设置为基本户一致
            }
            this.form.bankCard.linkedAcctname = this.form.merchant.merchantName
            this.form.merchant.contactsName = this.form.idCard.realName  //联系人使用法人
            this.form.merchant.contactsPhone = this.form.merchant.legalPhone //联系电话使用法人电话

            let otherForm = _.cloneDeep(this.form)
            otherForm['idCardFrontUrl'] =   this.idcard_front
            otherForm['idCardBackUrl'] =   this.idcard_back
            otherForm['businessLicenseUrl'] =   this.business_license

            shopMerchantDetail(otherForm)//暂存康贝信息 多传几个    "idCardFrontUrl": "","idCardBackUrl": "","bankCardUrl": "","businessLicenseUrl": ""
            const resp = await openAcctApply(this.form)
            if(resp.code == 0){
                this.$message.success('申请成功')
                setTimeout(() => {
                    this.initData()
                }, 1000);
            }
            

        },
        changeBusinessTime() {
            // 选择营业执照固定时间
            this.form.businessTimeType = '固定期限'
        },
        changeIdCardTime() {
            // 选择身份证固定时间
            this.form.timeType = '固定期限'
        },
        finish(){
            this.sendStatus = 'init'
            this.deadline2 = Date.now() + 1000 * 60
        },
        changeEdit(){
            this.activeIndex = 1
            this.canEdit = true
        },
        // getAddressStr(p,c,a){
        //     let strArr = []
        //     this.areaList.forEach((e)=>{
        //         if (e.id == p) {
        //             strArr.push(e.title)
        //             if (e.children && e.children.length) {
        //                 e.children.forEach((l)=>{
        //                     if (l.id == c) {
        //                         strArr.push(l.title)
        //                     }
        //                     if (l.children && l.children.length) {
        //                         l.children.forEach((k)=>{
        //                             if (k.id == a) {
        //                                 strArr.push(k.title)
        //                             }
        //                         })
        //                     }
        //                 })
        //             }
        //         }
        //     })
        //     console.log(strArr);
            
        // }
    }
}
</script>
<style lang="scss" scoped>
.fundMain {
    background-color: #fff;
    width: 100%;
    min-height: 100%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;

    .stepView {
        width: 40vw;

        .el-steps {
            padding: 0px !important;
            margin: 0px !important;
        }
    }

    .columnCenter {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .notiView {
        font-size: 16px;
        color: #595959;
        border: 1px solid #d9d9d9;
        width: 30vw;
        height: 100px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        margin-top: 48px;
    }

    .authType {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 24px 0px;

        .eachRow {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            overflow: hidden;
            background-color: #fafafa;
            padding: 12px;
            margin: 0px 12px;
            position: relative;
            cursor: pointer;

            .name {
                font-size: 16px;
                color: #262626;
                font-weight: 600;
            }

            .desc {
                font-size: 14px;
                color: #595959;
                line-height: 1.5;
                width: 160px;
                margin-top: 12px;
            }

            .divSelect {
                color: #f5222d;
            }

            .icon {
                position: absolute;
                right: 0px;
                bottom: 0px;
            }
        }

        .rowSelect {
            background-color: #FEEAEA;

        }
    }

    .inLine {
        display: flex;
        align-items: center;

        .formView {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            width: 500px;
            margin: 24px 12px;

            .topView {
                display: flex;
                align-items: center;
                margin: 12px;

                .require {
                    color: #f5222d;
                }

                .title {
                    font-weight: 700;
                    font-size: 16px;
                    margin-left: 12px;
                }
            }

            .idcardImg {
                display: flex;
                align-items: center;
                margin: 12px;

                .imgViewSurround {
                    border: 1px solid #d9d9d9;
                    border-radius: 4px;
                    width: 160px;
                    height: 100px;
                    position: relative;
                    .addImage{
                        display: flex;align-items: center;justify-content: center;height: 100%;
                    }
                }
            }

            .inputView {
                margin: 12px;
            }
        }
    }

    .bottom {
        width: 1000px;
        margin: 12px;
    }

    .img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .delicon {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 4px;

        :hover {
            background-color: #000;
        }
    }
}
</style>