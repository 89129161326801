<template>
  <div class="contentCont">
    <MyTable
      :table-data="tableData"
      :index="index"
      :selection="selection"
      :page="page"
      :showpage="false"
      :operation="operation"
      :table-option.sync="tableOption"
      @page-change="getList"
    >
      <template slot="menu" slot-scope="scope">
        <el-link
          type="primary"
          :underline="false"
          @click="detailBtn(scope.row)"
        >
          详情
        </el-link>
        <el-link
          type="primary"
          style="margin-left: 10px"
          :underline="false"
          @click="editorBtn(scope.row)"
        >
          修改
        </el-link>
        <el-link
          type="primary"
          style="margin-left: 10px"
          :underline="false"
          @click="deleteBtn(scope.row)"
        >
          删除
        </el-link>
      </template>
      <template slot="address" slot-scope="scope">
        <span
          >{{ scope.row.receiverProvinceName }}{{ scope.row.receiverCityName
          }}{{ scope.row.receiverAreaName
          }}{{ scope.row.receiverAddrDetail }}</span
        >
      </template>
      <template slot="province" slot-scope="scope">
        <span>{{ areaList(scope.row.areaList) }}</span>
      </template>
    </MyTable>
  </div>
</template>
<script>
import MyTable from "@/components/myTable";
import {
  getShopCustomerServiceAddresses,
  getShopCustomerServiceAddresse,
  deleteCustomerServiceAddresses,
  getShopCustomerServiceAddressesNew,
} from "@/api/shopmange/index";
export default {
  components: { MyTable },
  data() {
    return {
      operation: false,
      selection: false,
      index: false,
      tableData: [],
      tableOption: [
        { label: "收货人", prop: "receiver" },
        { label: "联系电话", prop: "receiverPhone" }, // 这里表示自定义列, slot: true
        { label: "收货地址", prop: "address", slot: true },
        { label: "快递说明", prop: "expressDesc" },
        // { label: '生效省份', prop: 'province', slot: true, },
        { label: "创建时间", prop: "createTime" },
        { label: "最后修改时间", prop: "updateTime" },
        {
          label: "操作",
          prop: "menu",
          slot: true,
          width: "200px",
          align: "left",
        },
      ],
      page: {
        total: 10,
        current: 1,
        size: 10,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    areaList(val) {
      let thatString = "";
      let obj = {};
      val = val.reduce(function (a, b) {
        obj[b.province] ? "" : (obj[b.province] = true && a.push(b));
        return a;
      }, []);
      val?.forEach((res) => {
        if (thatString) {
          thatString += `、${res.provinceName}`;
        } else {
          thatString = res.provinceName;
        }
        return thatString;
      });
      return thatString;
    },
    detailBtn(row) {
      getShopCustomerServiceAddresse({ id: row.id }).then((res) => {
        // console.log(res);
        res.caozuoType = "detail";
        this.$parent.getafterMarketDetail(res);
      });
    },
    editorBtn(row) {
      getShopCustomerServiceAddresse({ id: row.id }).then((res) => {
        res.caozuoType = "editor";
        this.$parent.getafterMarketDetail(res);
      });
    },
    getList(val) {
      getShopExpressFees(val ?? {}).then((res) => {
        this.tableData = res ?? [];
      });
    },
    deleteBtn(row) {
      this.$confirm("此操作将删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteCustomerServiceAddresses({ id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //旧代码问题，这个把上面的给屏蔽掉了
    getList(val) {
      // getShopCustomerServiceAddresses(val ?? {}).then((res) => {
      //   this.tableData = res ?? [];
      // });
      //换个接口
      getShopCustomerServiceAddressesNew(val ?? {}).then((res) => {
        this.tableData = res ?? [];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.contentCont {
  padding: 24px;
  padding-top: 0px;
  height: calc(100% - 33px);
  overflow: auto;
}
</style>
