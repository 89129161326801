var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info_box" },
    [
      _c(
        "el-form",
        {
          ref: "editForm",
          attrs: { "label-width": "120px", model: _vm.formTool, size: "small" },
        },
        [
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("基本信息")]),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品属性" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.info.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "type", $$v)
                            },
                            expression: "info.type",
                          },
                        },
                        _vm._l(_vm.propertyList, function (item) {
                          return _c(
                            "el-radio",
                            { key: item.value, attrs: { label: item.value } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-form-item", { attrs: { label: "商品分类" } }, [
                    _c("span", { staticClass: "oneClass" }, [
                      _vm._v(
                        _vm._s(_vm.info.goodsOneClassName) +
                          " " +
                          _vm._s(_vm.info.goodsTwoClassName) +
                          " " +
                          _vm._s(_vm.info.goodsThreeClassName)
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品名称", disabled: "" },
                        model: {
                          value: _vm.info.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "name", $$v)
                          },
                          expression: "info.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品简称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品简称", disabled: "" },
                        model: {
                          value: _vm.info.shortName,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "shortName", $$v)
                          },
                          expression: "info.shortName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("详细信息")]),
          _vm.info.type == "DRUG"
            ? _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "通用名",
                            prop: "commonName",
                            rules: {
                              required: true,
                              message: "请填写通用名",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入通用名",
                              disabled: "",
                            },
                            model: {
                              value: _vm.info.commonName,
                              callback: function ($$v) {
                                _vm.$set(_vm.info, "commonName", $$v)
                              },
                              expression: "info.commonName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-form-item", { attrs: { label: "图片" } }, [
                    _c(
                      "div",
                      { staticClass: "imgs_box" },
                      _vm._l(_vm.info.imageList, function (item, i) {
                        return _c("el-image", {
                          key: i,
                          attrs: { src: item, "preview-src-list": [item] },
                        })
                      }),
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品详情" } },
                    [
                      _c("wangEnduit", {
                        ref: "wangEnduit",
                        attrs: {
                          value: _vm.info.details,
                          "is-clear": _vm.isClear,
                          disabled: "",
                        },
                        on: { changeEndit: _vm.watchEndit },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [
            _vm._v("商品价格标签"),
          ]),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "售价前缀" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入售价前缀", disabled: "" },
                        model: {
                          value: _vm.info.sellingPricePrefix,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "sellingPricePrefix", $$v)
                          },
                          expression: "info.sellingPricePrefix",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "市场价" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.info.marketPriceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "marketPriceType", $$v)
                            },
                            expression: "info.marketPriceType",
                          },
                        },
                        _vm._l(_vm.priceKindList, function (item) {
                          return _c(
                            "el-radio",
                            { key: item.value, attrs: { label: item.value } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "价格标签模式" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.info.priceTagType,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "priceTagType", $$v)
                            },
                            expression: "info.priceTagType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "cash" } }, [
                            _vm._v("现金"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("商品价格")]),
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.info.skus } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "goodsSkuName",
                  label: "SKU名称",
                  "min-width": "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".goodsSkuName",
                              rules: {
                                required: true,
                                message: "请填写SKU名称",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "4",
                                placeholder: "请填写SKU名称",
                                disabled: "",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changMoney(scope.$index, scope.row)
                                },
                              },
                              model: {
                                value: scope.row.goodsSkuName,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "goodsSkuName", $$v)
                                },
                                expression: "scope.row.goodsSkuName",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsSkuUnit",
                  label: "单位",
                  "min-width": "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".goodsSkuUnit",
                              rules: {
                                required: true,
                                message: "单位",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "50%" },
                              attrs: {
                                maxlength: "4",
                                placeholder: "单位",
                                disabled: "",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changMoney(scope.$index, scope.row)
                                },
                              },
                              model: {
                                value: scope.row.goodsSkuUnit,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "goodsSkuUnit", $$v)
                                },
                                expression: "scope.row.goodsSkuUnit",
                              },
                            }),
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  underline: false,
                                  disabled: "",
                                },
                                on: { click: _vm.choiceDanwei },
                              },
                              [_vm._v("选择单位")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "salePrice",
                  label: "零售价/元",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".salePrice",
                              rules: {
                                required: true,
                                message: "请填写零售价",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "4",
                                placeholder: "请填写零售价",
                                disabled: "",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changMoney(scope.$index, scope.row)
                                },
                              },
                              model: {
                                value: scope.row.salePrice,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "salePrice", $$v)
                                },
                                expression: "scope.row.salePrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "marketPrice",
                  label: "市场价/元",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".marketPrice",
                              rules: {
                                required: true,
                                message: "请填写市场价",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "4",
                                placeholder: "请填写市场价",
                                disabled: "",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changMoney(scope.$index, scope.row)
                                },
                              },
                              model: {
                                value: scope.row.marketPrice,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "marketPrice", $$v)
                                },
                                expression: "scope.row.marketPrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "purchasePrice",
                  label: "批发价/元",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".purchasePrice",
                              rules: {
                                required: true,
                                message: "请填写批发价",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请填写批发价",
                                disabled: "",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changMoney(scope.$index, scope.row)
                                },
                              },
                              model: {
                                value: scope.row.purchasePrice,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "purchasePrice", $$v)
                                },
                                expression: "scope.row.purchasePrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "startWholesale",
                  label: "起批量",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".startWholesale",
                              rules: {
                                required: true,
                                message: "请填写起批量",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请填写起批量",
                                    disabled: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.changMoney(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.startWholesale,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "startWholesale", $$v)
                                    },
                                    expression: "scope.row.startWholesale",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsSkuInventory",
                  label: "库存",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "skus." + scope.$index + ".goodsSkuInventory",
                              rules: {
                                required: true,
                                message: "请填写库存",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请填写库存",
                                disabled: "",
                              },
                              model: {
                                value: scope.row.goodsSkuInventory,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "goodsSkuInventory", $$v)
                                },
                                expression: "scope.row.goodsSkuInventory",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "expireTime", label: "有效期至", width: "230" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".expireTime",
                              rules: {
                                type: "string",
                                required: true,
                                message: "请填写有效期至",
                                trigger: ["change", "blur"],
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                "value-format": "yyyy-MM-dd hh:mm:ss",
                                type: "datetime",
                                placeholder: "选择日期",
                              },
                              model: {
                                value: scope.row.expireTime,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "expireTime", $$v)
                                },
                                expression: "scope.row.expireTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "producedTime",
                  label: "生产日期",
                  width: "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".producedTime",
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "140px" },
                              attrs: {
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: "选择日期",
                              },
                              model: {
                                value: scope.row.producedTime,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "producedTime", $$v)
                                },
                                expression: "scope.row.producedTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "left_topic mb20",
              staticStyle: { "margin-top": "16px" },
            },
            [_vm._v("税务编码")]
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "税务分类编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入售价前缀", disabled: "" },
                        model: {
                          value: _vm.info.taxCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "taxCode", $$v)
                          },
                          expression: "info.taxCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "金蝶云编码" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入金蝶云编码",
                          disabled: "",
                        },
                        model: {
                          value: _vm.info.kingdeeCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "kingdeeCode", $$v)
                          },
                          expression: "info.kingdeeCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("规格参数")]),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "规格",
                        prop: "specification",
                        rules: {
                          required: true,
                          message: "请填写规格",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入规格", disabled: "" },
                        model: {
                          value: _vm.info.specification,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "specification", $$v)
                          },
                          expression: "info.specification",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "生产厂家",
                        prop: "factory",
                        rules: {
                          required: true,
                          message: "请填写生产厂家",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入生产厂家", disabled: "" },
                        model: {
                          value: _vm.info.factory,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "factory", $$v)
                          },
                          expression: "info.factory",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "批准文号",
                        prop: "approvalNumber",
                        rules: {
                          required:
                            _vm.formTool.type == "TCMDP" ||
                            _vm.formTool.type == "OTHER"
                              ? false
                              : true,
                          message: "请填写批准文号",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入批准文号", disabled: "" },
                        model: {
                          value: _vm.info.approvalNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "approvalNumber", $$v)
                          },
                          expression: "info.approvalNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _vm.info.type == "TCMDP"
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "等级", prop: "level" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入等级", disabled: "" },
                            model: {
                              value: _vm.info.level,
                              callback: function ($$v) {
                                _vm.$set(_vm.info, "level", $$v)
                              },
                              expression: "info.level",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _vm.info.type == "TCMDP"
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产地", prop: "placeOrigin" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入产地", disabled: "" },
                            model: {
                              value: _vm.info.placeOrigin,
                              callback: function ($$v) {
                                _vm.$set(_vm.info, "placeOrigin", $$v)
                              },
                              expression: "info.placeOrigin",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "存储条件",
                        prop: "storageCondition",
                        rules: {
                          required: _vm.formTool.type == "DRUG" ? true : false,
                          message: "请填写存储条件",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入存储条件", disabled: "" },
                        model: {
                          value: _vm.info.storageCondition,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "storageCondition", $$v)
                          },
                          expression: "info.storageCondition",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _vm.info.type == "DRUG"
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "处方类型",
                            prop: "prescriptionType",
                            rules: {
                              required: true,
                              message: "请填写处方类型",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入处方类型",
                              disabled: "",
                            },
                            model: {
                              value: _vm.info.prescriptionType,
                              callback: function ($$v) {
                                _vm.$set(_vm.info, "prescriptionType", $$v)
                              },
                              expression: "info.prescriptionType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _vm.info.type == "DRUG"
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "剂型",
                            prop: "dosageForm",
                            rules: {
                              required: true,
                              message: "请填写剂型",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入剂型", disabled: "" },
                            model: {
                              value: _vm.info.dosageForm,
                              callback: function ($$v) {
                                _vm.$set(_vm.info, "dosageForm", $$v)
                              },
                              expression: "info.dosageForm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "中包装",
                        prop: "middlePack",
                        rules: {
                          required: true,
                          message: "请填写中包装",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "请输入中包装" },
                        model: {
                          value: _vm.info.middlePack,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "middlePack", $$v)
                          },
                          expression: "info.middlePack",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "大包装",
                        prop: "bigPack",
                        rules: {
                          required: true,
                          message: "请填写大包装",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "请输入大包装" },
                        model: {
                          value: _vm.info.bigPack,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "bigPack", $$v)
                          },
                          expression: "info.bigPack",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商品条形码",
                        prop: "barCode",
                        rules: {
                          required: true,
                          message: "请填写商品条形码",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: "",
                          placeholder: "请输入商品条形码",
                        },
                        model: {
                          value: _vm.info.barCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "barCode", $$v)
                          },
                          expression: "info.barCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("p", { staticClass: "markedWords mt10" }, [
            _vm._v(
              "温暖提示：部分商品包装更换频繁，如货品与图片不一致，请以收到的商品实物为准。如页面存在有效期信息，为库存产品最近有效期，实际产品有效期以到货产品有效期为准。如发现商品存在质量问题，请拨打药潺潺质量投诉专线电话：400-888-6688"
            ),
          ]),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("溯源信息")]),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "码上放心追溯码" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入码上放心追溯码",
                          disabled: "",
                        },
                        model: {
                          value: _vm.info.barCodeRetrospection,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "barCodeRetrospection", $$v)
                          },
                          expression: "info.barCodeRetrospection",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "imgs_box",
                          staticStyle: { "margin-top": "15px" },
                        },
                        _vm._l(_vm.info.barCodeImage, function (item, i) {
                          return _c("el-image", {
                            key: i,
                            attrs: { src: item, "preview-src-list": [item] },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("服务与承诺")]),
          _c(
            "div",
            { staticClass: "pl20" },
            [
              _c(
                "el-checkbox-group",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.info.servicePromise,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "servicePromise", $$v)
                    },
                    expression: "info.servicePromise",
                  },
                },
                [
                  _c("el-checkbox", { attrs: { label: "exemption_postage" } }, [
                    _vm._v("直邮包邮"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "lightning" } }, [
                    _vm._v("闪电配送"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "ticket_sale" } }, [
                    _vm._v("带票销售"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("服务说明")]),
          _c("div", { staticClass: "pl20" }, [
            _c("div", { staticClass: "firstTitle mb10" }, [
              _vm._v("商品价格说明"),
            ]),
            _c("div", { staticClass: "secondTitle mb20" }, [
              _vm._v(
                "促销活动、商品进价调整、市场环境等因素均会影响商品售价，最终以订单结算页价格为准，购买前请仔细核对"
              ),
            ]),
            _c("div", { staticClass: "firstTitle mb10" }, [
              _vm._v("产品包装说明"),
            ]),
            _c("div", { staticClass: "secondTitle mb20" }, [
              _vm._v(
                "厂家药品批次存在更新，涉及内外包装、说明书等，以到货实物为准，如有疑问，可点击纠错按钮或联系客服"
              ),
            ]),
            _c("div", { staticClass: "firstTitle mb10" }, [
              _vm._v("商品购买权限说明"),
            ]),
            _c("div", { staticClass: "secondTitle mb20" }, [
              _vm._v(
                "内容由于药品特殊性，部分商品提示暂无购买权限，属于正常情况，具体以购买页面为准"
              ),
            ]),
          ]),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("配送说明")]),
          _c("div", { staticClass: "secondTitle pl20" }, [
            _vm._v("下单成功后，商家立即安排配送。"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }