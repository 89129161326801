<template>
  <div class="comContent">
    <div class="search-from">
      <el-form ref="searchForm" :model="searchForm" :inline="true" style="text-align: left">
        <!-- <el-form-item>
          <el-select size="mini" v-model="searchForm.payeeStatus" placeholder="结算状态" style="width: 120px">
            <el-option v-for="item in payeeStatusList" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item>
          <el-input v-model="searchForm.orderCode" placeholder="订单编号" clearable maxlength="20" size="small"
            style="width: 120px" />
        </el-form-item>

        <el-form-item>
          <el-input v-model="searchForm.shopName" placeholder="采购方名称" clearable maxlength="20" size="small"
            style="width: 120px" />
        </el-form-item>

        <el-form-item>
          <el-input v-model="searchForm.shopAccount" placeholder="采购方账号" clearable maxlength="20" size="small"
            style="width: 120px" />
        </el-form-item>

        <el-form-item>
          <el-select size="mini" v-model="searchForm.province" placeholder="省" @change="selectedHandle"
            style="width: 120px">
            <el-option v-for="item in provinceData" :label="item.rname" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select size="mini" v-model="searchForm.city" placeholder="市" @change="selectedHandleCity"
            style="width: 120px">
            <el-option v-for="item in cityData" :label="item.rname" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select size="mini" v-model="searchForm.district" placeholder="区" style="width: 120px">
            <el-option v-for="item in areaData" :label="item.rname" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="140px">
          <el-date-picker style="width: 300px" v-model="searchForm.date" type="daterange" size="small"
            value-format="yyyy-MM-dd" range-separator="-" start-placeholder="对账开始日期" end-placeholder="对账结束日期" />
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="reset('searchForm')">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="exportUrl(1)">导出</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="kaipiao(1)">开票</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <settlementTable :showSummary="true" :pageType="'payment-page'" :table-data="tableData" :index="index"
        :selection="true" :page="page" v-loading="loading" :showpage="true" :operation="false"
        :table-option.sync="tableOption" @detail-operate="detailOperate" @page-change="getList"
        @handleSelectionChange="handleSelectionChange" :expand="true">
        <template slot="payShopName" slot-scope="scope">
          <span @click="toDetailPage(scope.row)" class="to-deatil">{{
            scope.row.payShopName
          }}</span>
        </template>
        <!-- platformServiceMoney -->
        <template slot="platformServiceMoney" slot-scope="scope">
          <span>
            ￥ {{ scope.row.platformServiceMoney | pirceFormatter(scope.row.platformServiceMoney) }}
          </span>
        </template>
        <template slot="promotionMoney" slot-scope="scope">
          <span>
            ￥ {{ scope.row.promotionMoney | pirceFormatter(scope.row.promotionMoney) }}
          </span>
        </template>
        
        <template slot="allAmount" slot-scope="scope">
          <span>
            ￥ {{ scope.row.allAmount | pirceFormatter(scope.row.allAmount) }}
          </span>
        </template>
        <template slot="toAmount" slot-scope="scope">
          <span>
            ￥ {{ scope.row.toAmount | pirceFormatter(scope.row.toAmount) }}
          </span>
        </template>
        
        <template slot="cbAmount" slot-scope="scope">
          <span> 
            ￥ {{ scope.row.cbAmount | pirceFormatter(scope.row.cbAmount) }}
          </span>
        </template>

        <template slot="cbToAmount" slot-scope="scope">
          <span>
            ￥ {{ scope.row.cbToAmount | pirceFormatter(scope.row.cbToAmount) }}
          </span>
        </template>
        <template slot="expand" slot-scope="scope">
          <myTable :table-data="detailList" :index="index" :selection="false" :operation="false"
            :table-option.sync="tableInfoOption" :expand="false">
            <template slot="price" slot-scope="scope">
              <span>
                ￥ {{ scope.row.price | pirceFormatter(scope.row.price) }}
              </span>
            </template>
            <template slot="servicePrice" slot-scope="scope">
              <span>
                ￥ {{ scope.row.servicePrice | pirceFormatter(scope.row.servicePrice) }}
              </span>
            </template>

            <template slot="platformServiceMoney" slot-scope="scope">
              <span>
                ￥ {{ scope.row.platformServiceMoney | pirceFormatter(scope.row.platformServiceMoney) }}
              </span>
            </template>
            <template slot="promotionMoney" slot-scope="scope">
              <span>
                ￥ {{ scope.row.promotionMoney | pirceFormatter(scope.row.promotionMoney) }}
              </span>
            </template>
          </myTable>
        </template>
      </settlementTable>
    </div>
    <el-dialog title="提醒" :close-on-click-modal="false" :visible.sync="dialogVisible" width="30%"
      :before-close="handleClose">
      <span>您有订单未勾选开票，请问是否确认进行开票</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">取消</el-button>
        <el-button type="primary" @click="submitOne" size="small">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="申请开票" :close-on-click-modal="false" class="dialogSecond" :visible.sync="dialogVisibleSecond"
      width="1200px" :before-close="handleCloseSecond">
      <div>
        <el-form :model="formInline" ref="formInline" class="demo-form-inline" :rules="rules">
          <el-form-item label="开票类型" prop="type">
            <el-checkbox-group v-model="formInline.type">
              <el-checkbox label="1">普通发票（纸质）</el-checkbox>
              <el-checkbox label="2">普通发票（电子）</el-checkbox>
              <el-checkbox label="3">增值税专用发票（纸质）</el-checkbox>
              <el-checkbox label="4">增值税专用发票（电子）</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <myTable :showpage="true" :page="kaipiaopage" :table-data="kaipiaoData" :index="true" :selection="false"
            :operation="false" @page-change="getListKaipiao" :table-option.sync="kaipiaoOption" :expand="false">
            <template slot="platformServiceMoney" slot-scope="scope">
              <span>
                ￥   {{ scope.row.platformServiceMoney | pirceFormatter(scope.row.platformServiceMoney) }}
              </span>
            </template>
            <template slot="promotionMoney" slot-scope="scope">
              <span>
                ￥ {{ scope.row.promotionMoney | pirceFormatter(scope.row.promotionMoney) }}
              </span>
            </template>
            <template slot="allAmount" slot-scope="scope">
              <span>
                ￥ {{ scope.row.allAmount | pirceFormatter(scope.row.allAmount) }}
              </span>
            </template>
            <template slot="toAmount" slot-scope="scope">
              <span>
                ￥{{ scope.row.toAmount | pirceFormatter(scope.row.toAmount) }}
              </span>
            </template>
            
            <template slot="cbAmount" slot-scope="scope">
              <span> 
                ￥ {{ scope.row.cbAmount | pirceFormatter(scope.row.cbAmount) }}
              </span>
            </template>
    
            <template slot="amount" slot-scope="scope">
              <span>
                ￥ {{ scope.row.amount | pirceFormatter(scope.row.amount) }}
              </span>
            </template>
            <template slot="freightPrice" slot-scope="scope">
              <span>
                ￥ {{ scope.row.freightPrice | pirceFormatter(scope.row.freightPrice) }}
              </span>
            </template>
            
          </myTable>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseSecond" size="small">取消</el-button>
        <el-button type="primary" @click="submitTwo" size="small">申请开票</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import settlementTable from "../reconciliation/paymentTable/settlementTable.vue";
import myTable from "@/components/myTable";
import avater from "/public/img/default.png";
import { getProvince, getCity, getArea } from "@/api/shopmange/index";
import { dfcTree } from "@/api/businessPlatform/relationshipMaintenance";
import {
  accountCheckingPayPage,
  accountCheckingPageDetail,
  accountCheckingPayExcel,
} from "@/api/accountChecking/index"; 
import {
  closePayGet,
  closePayOpen,
  invoice
} from "@/api/invoice";
import { closePayPage } from "@/api/settlement/index";

export default {
  components: { settlementTable, myTable },
  // props: {
  //   accountType: {
  //     type: Number,
  //     default () {
  //       return 1;
  //     },
  //   },
  // },
  data() {
    return {
      orderIdList: [],
      firstEnter: true,
      kaipiaopage: {
        total: 1,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      kaipiaoData: [],
      kaipiaoOption: [
        { label: "订单编号", prop: "orderCode" , width: 180},
        { label: "订单创建时间", prop: "createDate", width: 180 },
        { label: "订单总金额(元)", prop: "allAmount",slot:true },
        { label: "订单商品金额(元)", prop: "amount",slot:true },
        // { label: "订单运费(元)", prop: "freightPrice",slot:true },
        // { label: "订单应结算金额(元)", prop: "amount" ,slot:true},
        { label: "平台服务费金额(元)", prop: "platformServiceMoney" ,slot:true},
      ],
      dialogVisible: false,
      dialogVisibleSecond: false,
      selectionList: [],
      loading: false,
      selectData: new Date(),
      avater: avater,
      statusList: [],
      searchForm: {
        payeeStatus: '',
        shopName: '',
        shopAccount: '',
        startOrderTime: '',
        endOrderTime: '',
        province: '',
        city: '',
        district: '',
        orderCode: '',
        date: [],
      },
      rules: {
        type:
          [
            { required: true, message: '请选择开票类型', trigger: 'change' }
          ]
      },
      formInline: {
        type: []
      },
      payeeStatusList: [
        {
          id: '0',
          name: "未结算",
        },
        {
          id: '1',
          name: "已结算",
        },
      ], //企业类型
      companyList: [
        {
          id: 1,
          name: "诊所",
        },
        {
          id: 2,
          name: "春播运营组织",
        },
        {
          id: 3,
          name: "医药公司",
        },
      ], //企业类型

      areaData: [],
      cityData: [],
      provinceData: [],
      allTreedata: [], //省级组织树
      cityTree: [], //地级组织树
      name: "",
      dateKey: 2,
      operation: false,
      selection: true,
      index: false,
      tableData: [
      ],
      detailList: [],
      tableInfoData: [],
      stProvinceAmtPo: {},
      tableOption: [
        { label: "创建时间", prop: "createDate", width: 160 },
        { label: "采购方名称", prop: "payShopName", width: 260, slot: true },
        { label: "订单编号", prop: "orderCode", width: 200 },
        { label: "采购方企业类型", prop: "payShopType", width: 130 },
        { label: "结算状态", prop: "payeeStatus", width: 120 },
        { label: "开票状态", prop: "technicalSerialStatus", width: 120 },

        { label: "应收订单总金额(元)", prop: "allAmount", width: 150, slot: true},
        { label: "到账总金额(元)", prop: "toAmount", width: 160, slot: true },
        // { label: "收款状态", prop: "payeeStatus", width: 120 },
        // { label: "付款方名称", prop: "cbPayShopName", width: 200 },

        { label: "应付总金额(元)", prop: "cbAmount", width: 140, slot: true },
        { label: "实付总金额(元)", prop: "cbToAmount", width: 140, slot: true },
        { label: "技术服务费(元)", prop: "platformServiceMoney", width: 200, slot: true },
        { label: "推广服务费(元)", prop: "promotionMoney", width: 200, slot: true },
        // { label: "状态", prop: "", width: 120 },
      ],
      tableInfoOption: [
        { label: "", prop: "", width: 100 },
        { label: "商品名称", prop: "name" },
        { label: "商品规格", prop: "specification" },
        { label: "单位", prop: "goodsSkuUnit" },
        { label: "商品单价(元)", prop: "price" , slot: true},
        { label: "商品件数", prop: "qty" },
        { label: "单件商品应收金额(元)", prop: "servicePrice", slot: true },
        { label: "技术服务费(元)", prop: "platformServiceMoney", slot: true },
        { label: "推广服务费(元)", prop: "promotionMoney" , slot: true},
      ],
      page: {
        total: 1,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      isReport: true,
    };
  },
  filters: {
    pirceFormatter(price) {
      if (price != null) {
        return parseFloat(price).toFixed(2);
      } else {
        return "0.00";
      }
    },
  },
  created() { },
  mounted() {
    // this.searchForm.type = this.accountType; // 账户类型对应页面类型，角色：1.省地医药，2.学术，3.云药库
    // 获取省市区 地域树
    this.getProvinceData();
    // 获取省地办 组织树
    this.getTeamTree();
    this.getList(1);
  },
  methods: {
    // 关闭第一层弹窗
    handleClose() {
      this.firstEnter = false
      this.dialogVisible = false
    },
    // 关闭第二层弹窗
    handleCloseSecond() {
      this.kaipiaoData = []
      // this.formInline.type = []
      this.orderIdList = []
      this.$refs.formInline.resetFields()
      this.dialogVisibleSecond = false
    },
    submitOne() {
      this.firstEnter = false
      this.dialogVisible = false
      this.closePayOpen()
    },
    submitTwo() {
      this.$refs.formInline.validate(valid => {
        if (valid) {
          let params = {
            ...this.formInline,
            orderIds: this.orderIdList.join(',')
          }
          params.type = params.type.join(',')
          invoice(params).then(res => {
            if (res) {
              this.$message.success('提交成功')
              this.getList()
            } else {
              this.$message.error('提交失败')
            }
            this.orderIdList = []
            this.kaipiaoData = []
            this.$refs.formInline.resetFields()
            this.dialogVisibleSecond = false
          })
        } else {
          this.$message.warning('请先选择开票类型')
        }
      })
    },
    // 展开明细列表
    getDeatilList(row) {
      let params = {
        type: '1',
        orderId: row.orderId,
      };
      delete params.date;
      accountCheckingPageDetail(params).then((res) => {
        // console.log(res);
        if (res) {
          this.detailList = res;
        } else {
        }
      });
    },
    // 跳转详情页面
    toDetailPage(row, type) {
      // let queryParams = `payShopId=${row.payShopId}&type=1&payShopName=${row.payShopName}`;
      // this.$router.push(
      //   "./financialcenter-reconciliation-paymentDetails?" + queryParams
      // );
    },
    // 操作按钮
    detailOperate(row, type) {
      if (type == 1) {
        this.getDeatilList(row);
      }
    },
    // 开票分页
    getListKaipiao() {
      this.closePayOpen()
    },
    // 开票
    kaipiao() {
      if (this.selectionList.length == 0) {
        this.$message.warning('请先选择开票的数据')
        return
      } else {
        this.selectionList.forEach(item => {
          this.orderIdList.push(item.orderId)
        })
        this.closePayOpen()
      }
    },
    // 查询开票的数据
    closePayOpen() {
      let params = {
        size: this.kaipiaopage.size,
        current: this.kaipiaopage.current,
        orderIds: this.orderIdList.length > 0 ? this.orderIdList.join() : ''
      }
      closePayOpen(params).then(res => {
        if (res) {
          this.kaipiaoData = res.records
          this.kaipiaopage.total = res.total
          this.dialogVisibleSecond = true
        }
      })
    },
    // 表格 多选
    handleSelectionChange(e) {
      this.selectionList = e
    },
    // 获取整个团队组织树
    getTeamTree() {
      dfcTree({ type: "" }).then((res) => {
        if (res.code == 0) {
          this.allTreedata = res.data;
          // console.log(this.allTreedata, "---allTreedata");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    chooseProvinceTeam(e) {
      this.searchForm.provinceOrgName = e.name;
      this.searchForm.provinceOrgCode = e.weight;
      // this.cityTree = [];
      this.cityTree = e.children;
      if (this.searchForm.cityOrgCode) {
        this.searchForm.cityOrgName = "";
        this.searchForm.cityOrgCode = "";
      }
    },
    // 选择市级组织
    chooseCityTeam(e) {
      this.searchForm.cityOrgName = e.name;
      this.searchForm.cityOrgCode = e.weight;
      this.$forceUpdate();
    },
    exportUrl() {
      let params = {
        ...this.searchForm,
        startOrderTime: this.searchForm.date ? this.searchForm.date[0] : '',
        endOrderTime: this.searchForm.date ? this.searchForm.date[1] : '',
      };
      delete params.date;
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // let apiPath = "/statistic/account/checking/pay/excel";
      let apiPath = "/statistic/account/checking/closePay/excel"
      let exportName = "发票申请列表";
      try {
        accountCheckingPayExcel(params, apiPath, exportName).then((res) => {
          setTimeout(() => {
            loading.close();
          }, 500);
        });
      } catch (error) {
        setTimeout(() => {
          loading.close();
        }, 2000);
      }
    },

    reset(formName) {
      //清空方法
      for (var key in this.searchForm) {
        this.searchForm[key] = "";
      }
      this.searchForm.date = [];
      this.page.current = 1;
      this.getList();
      // console.log(this.searchForm);
    },
    getList(type) {
      this.loading = true;
      if (type == 1) {
        this.page.current = 1;
      }
      let params = {
        ...this.searchForm,
        startOrderTime: this.searchForm.date ? this.searchForm.date[0] : '',
        endOrderTime: this.searchForm.date ? this.searchForm.date[1] : '',
        current: this.page.current,
        size: this.page.size,
      };
      delete params.date;
      closePayGet(params).then((res) => {
        // console.log(res);
        this.loading = false;
        if (res && res.beNeedInvoice) {
          if (res.beNeedInvoice && this.firstEnter) {
            // this.dialogVisible = true   //开票提示弹窗。阿强让先不弹出，这里有问题
          }

        }
        if (res && res.viewVO) { 
          this.tableData = res.viewVO.records;
          this.page.total = res.viewVO.total;
        } else {
          this.tableData = [];
          this.page.total = 0;
        }
      });
    },

    //省份的选择按钮
    selectedHandle(val) {
      console.log(val, "val");
      this.getCityBtn(val);
    },

    //城市的选择按钮
    selectedHandleCity(val) {
      this.getAreaBtn(val);
    },
    getProvinceData() {
      getProvince().then((res) => {
        if (res.code == 0) {
          this.provinceData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },
    getCityBtn(val) {
      getCity({ id: val }).then((res) => {
        if (res.code == 0) {
          this.cityData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },

    getAreaBtn(val) {
      getArea({ id: val }).then((res) => {
        if (res.code == 0) {
          this.areaData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialogSecond {
  ::v-deep .el-dialog__body {
    height: 600px;
    overflow: auto;
  }
}

.reportContent {
  background: #fff;
  // padding:0 0 10px 0;
  margin-top: -0px;
}

.header {
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;

  .title {
    line-height: 30px;
    font-size: 18px;

    span {
      font-size: 14px;
      // color: #595959;
    }
  }
}

.info-list {
  line-height: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  // margin-left: 105px;
  margin-top: 20px;

  // margin: 0 auto;
  .list {
    margin-right: 20px;

    span {
      font-size: 21px;
      color: #f5222d;
    }
  }
}

.date-picker {
  margin-left: 20px;
}

.active-button {
  background: #ffece8;
  color: #f5222d;
  border: solid 1px #ffece8;
}

.goods-name {
  width: 200px;
  margin-left: 0px;

  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.goods-info-list {
  border-bottom: solid 1px #ccc;
}

.goods-info-list:nth-last-child(1) {
  border: none;
}

.unit-list {
  display: flex;

  .li {
    width: 140px;
  }
}

.search-from {
  margin-top: 20px;
}

.to-deatil {
  color: #f5222d;
  cursor: pointer;
}

::v-deep .el-table .cell {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>