<template>
  <div class="select-type-root grid">
    <div
      :class="{ item: true, focus: selectedIndex === index }"
      v-for="(item, index) in [
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1,
      ]"
      :key="index"
      @click="handleSelect(index)"
    >
      <el-image :src="bgIcon" class="bg" />
      <el-image :src="icon" class="icon" />
      <div class="right-content">
        <div class="title">一口价</div>
        <div class="desp">不可以使用商家券不可以使用商家券</div>
      </div>
    </div>
  </div>
</template>

<script>
const IConst_icon = require("@/assets/chatservice.png");
const IConst_Bg = require("@/assets/public/type_bg.png");
export default {
  data() {
    return {
      icon: IConst_icon,
      bgIcon: IConst_Bg,

      selectedIndex: -1,
    };
  },

  methods: {
    handleSelect(index) {
      this.selectedIndex = index;

      this.$emit("nextStep");
    },
  },
};
</script>

<style scoped lang="scss">
.select-type-root {
  &.grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .item {
      width: calc(25% - 16px);
      height: 99px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      margin-top: 16px;
      margin-left: 16px;
      padding: 16px;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      position: relative;
      cursor: pointer;

      &.focus {
        border: 1px solid #f5222d;
        border-radius: 4px;
        box-shadow: 0px 6px 10px 0px rgba(255, 77, 80, 0.2),
          0px 3px 4px -5px rgba(255, 77, 80, 0.5);
      }

      .bg {
        position: absolute;
        bottom: 0;
        right: 16px;
        width: 56px;
        height: 69px;
        z-index: 0;
      }

      .icon {
        width: 48px;
        height: 48px;
      }

      .right-content {
        position: relative;
        flex: 1;
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        z-index: 1;

        .title {
          font-size: 16px;
          font-weight: 600;
          color: #141b2b;
        }

        .desp {
          font-size: 14px;
          font-weight: 400;
          color: #595959;
          margin-top: 2px;
        }
      }
    }
  }
}
</style>
