import request from '@/utils/request'

// 获取组织架构树
export function teamOrgsProductLine(params) {
  return request({
    url: `sales-rule/team/teamOrgs/productLine/${params.id}`,
    method: 'get'
  })
}

// 获取省市区
export function regionAreaTree(params) {
  return request({
    url: `shop/region/areaTree/${params.level}`,
    method: 'get',
  })
}

// 根据组织id获取城市选中列表    

export function teamOrgAreaRelationsOrgArea(params) {
  return request({
    url: "sales-rule/team/teamOrgAreaRelations/org/area",
    method: 'get',
    params
  })
}

// 新增组织架构
export function addTeamOrgs(data) {
  return request({
    url: `sales-rule/team/teamOrgs`,
    method: 'post',
    data
  })
}

// 编辑组织架构
export function editTeamOrgs(data) {
  return request({
    url: `sales-rule/team/teamOrgs/${data.id}`,
    method: 'put',
    data
  })
}

// 删除
export function delTeamOrgs(data) {
  return request({
    url: `sales-rule/team/teamOrgs/${data.id}`,
    method: 'delete'
  })
}

// 根据组织id获取组织及其组织成员
export function teamMembersListAllSons(params) {
  return request({
    url: "sales-rule/team/teamMembers/listAllSons",
    method: 'get',
    params
  })
}

// 新增团队人员
export function addTeamMembers(data) {
  return request({
    url: `sales-rule/team/teamMembers`,
    method: 'post',
    data
  })
}

// 删除团队人员
export function delTeamMembers(data) {
  return request({
    url: `sales-rule/team/teamMembers/${data.id}`,
    method: 'DELETE'
  })
}

// 未分配买家列表
export function teamTerminalsList(params) {
  return request({
    url: `sales-rule/team/teamTerminals/member/unallocated/${params.id}`,
    method: 'get'
  })
}

// 已分配买家列表
export function teamTerminals(params) {
  return request({
    url: `sales-rule/team/teamTerminals`,
    method: 'get',
    params
  })
}

//  批量添加组织地区关联
export function teamOrgAreaRelationsBatch(data) {
  return request({
    url: `sales-rule/team/teamOrgAreaRelations/batch`,
    method: 'post',
    data
  })
}

//  批量新增分配买家
export function teamTerminalsBatch(data) {
  return request({
    url: `sales-rule/team/teamTerminals/batch`,
    method: 'post',
    data
  })
}

// 批量取消分配
export function teamTerminalsBatchDel(data) {
  return request({
    url: `sales-rule/team/teamTerminals/batchDel`,
    method: 'post',
    data
  })
}

// 批量修改采购定量
export function batchUpdateQuantity(data) {
  return request({
    url: `sales-rule/team/teamTerminals/batchUpdateQuantity`,
    method: 'post',
    data
  })
}
