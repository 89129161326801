<template>
  <div class="info-detail-root">
    <el-form ref="refForm" :model="formData">
      <div v-if="formData.gridList && formData.gridList.length > 0" :key="formData.key" class="v-grid-list" v-masonry
        item-selector=".block-item" transition-duration="0.3s" gutter="24">
        <div class="block-item" v-for="(bigItem, bigIndex) in formData.gridList
          ? formData.gridList
          : []" :key="bigIndex">
          <!-- 标题层 -->
          <div class="top-title">
            <div class="full-title">
              <div class="big-title">
                <span class="weight" v-show="bigItem.isMust">*</span>
                <span class="txt">{{ bigItem.title }}</span>
              </div>
              <div class="btn-layout">
                <el-button v-if="bigItem.template" type="text"
                  @click="handleDownTemplate(bigItem.template)">下载模板</el-button>
                <el-button v-if="bigItem.example" type="text" @click="handleLookSample(bigItem.example)">查看示例
                </el-button>
              </div>
            </div>
            <div class="remarks">
              {{ bigItem.extObj.description }}
            </div>
          </div>
          <div class="content-layout">
            <el-form-item :prop="'gridList.' + bigIndex + '.imageUrlList'" :rules="[
              {
                required: bigItem.isMust,
                message: '请上传' + bigItem.title,
                trigger: 'change',
              },
            ]">
              <!-- 图片层 -->
              <div class="pic-layout">
                <div class="item" v-for="(item, index) in bigItem.imageUrlList
                  ? bigItem.imageUrlList
                  : []" :key="index">
                  <el-image :ref="`myImgB${bigIndex}S${index}`" :src="item" fit="cover" class="icon"
                    :initial-index="index" :preview-src-list="bigItem.imageUrlList" />
                  <div class="preview">
                    <el-image @click="handlePreview(`myImgB${bigIndex}S${index}`)" :src="SeeIcon" class="btn-icon" />
                    <el-image v-if="!disabled" @click="handleDeleteImage(bigIndex, index)" :src="DelIcon"
                      class="btn-icon" />
                  </div>
                </div>
                <div class="item add-pic" v-if="!hideAddPicBtn(bigItem)" @click="handleUpload(bigIndex)">
                  <div class="image-wrap">
                    <el-image :src="AddIcon" />
                  </div>
                  <span class="text">上传照片</span>
                </div>
              </div>
            </el-form-item>
            <!-- 动态Filed -->
            <div class="input-layout" v-if="bigItem.filedList && bigItem.filedList.length > 0">
              <div :class="{
                'input-item': true,
                'fill-wrap':
                  bigItem.filedList.length % 2 !== 0 &&
                  subIndex === bigItem.filedList.length - 1,
              }" v-for="(subItem, subIndex) in bigItem.filedList" :key="subIndex">
                <el-form-item :prop="
                  'gridList.' +
                  bigIndex +
                  '.filedList.' +
                  subIndex +
                  '.modeValue'
                " :rules="[
  {
    required: subItem.isMust,
    message: subItem.tip ? subItem.tip + '不能为空' : '值不能为空',
    trigger: 'blur',
  },
]">
                  <DynamicFiled :type="subItem.type" :disabled="disabled" v-model="subItem.modeValue" :data-item="subItem"
                    @areaChange="areaChangeGrid(bigIndex, subIndex)" />
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-dynamic-filed">
        <div class="input-layout" v-if="formData.filedList && formData.filedList.length > 0">
          <div :class="{
            'input-item': true,
          }" v-for="(item, index) in formData.filedList" :key="index">
            <el-form-item :prop="'filedList.' + index + '.modeValue'" :rules="[
              {
                required: item.isMust,
                message: item.tip ? item.tip + '不能为空' : '值不能为空',
                trigger: 'blur',
              },
            ]">
              <DynamicFiled :type="item.type" :disabled="disabled" v-model="item.modeValue" :data-item="item"
                @areaChange="areaChange(index)" />
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>

    <div class="bottom-btn-layout" v-if="!disabled">
      <el-button @click="handlePreStep">上一步</el-button>
      <!-- <el-button @click="handleSaveDraft" v-if="showDraftBtn">保存草稿</el-button> -->
      <el-button type="danger" @click="handleSubmit">提 交</el-button>
    </div>

    <UploadImageView ref="imgupload" request-id="1" :imgType="imgType" @upload="uploadSubmit" />

    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="urls" />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

import DynamicFiled from "../components/dynamicFiled.vue";
//该组件是复制 @/views/merchantsettlement/myupload.vue，因为这里的代码会被复制到 大商场共同使用
import UploadImageView from "../components/UploadImage.vue";

const IConst_See_Icon = require("@/assets/zz/see-icon.png");
const IConst_Del_Icon = require("@/assets/zz/del-icon.png");
const IConst_Add_Icon = require("@/assets/zz/add-icon.png");

import {
  getCertifyDetail,
  doTmpSaveCertify,
  doCommitCertify,
  doCommitCertifyTempData
} from "@/api/myQualification/index";

import { viewHelp, IConst_State } from "../util/ViewHelp";
const debounce = (function () {
  let timer = 0;
  return function (func, delay) {
    clearTimeout(timer);
    timer = setTimeout(func, delay);
  };
})();

export default {
  components: { DynamicFiled, UploadImageView, ElImageViewer },
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      //图片预览
      showViewer: false,
      urls: [],

      IConst_State: IConst_State,

      SeeIcon: IConst_See_Icon,
      DelIcon: IConst_Del_Icon,
      AddIcon: IConst_Add_Icon,

      showImageViewer: false,
      previewUrls: null,
      initialIndex: 0,

      imgType: "",
      IConst_Max_Pic_Count: 5,
      shopId: null,
      /**
      formData =  {
        //底部的动态域字段
        filedList: [
          { type: 1, modeValue: "x", isMust: false ,modeValueName:""},
          { type: 2, modeValue: "key1", isMust: false },
          { type: 3, modeValue: ["key2"], isMust: true },
          { type: 4, modeValue: [130000, 130200, 130202], isMust: false },
        ],

        gridList: [
          {
            isMust: true,
            title: "营业执照1",
            imageUrlList: [],
            example: '', //示例
            template: '', //模版
            filedList: [
              { type: 1, modeValue: "", isMust: false },
              { type: 2, modeValue: "", isMust: true },
              { type: 3, modeValue: "", isMust: false },
              { type: 4, modeValue: "", isMust: true },
              { type: 1, modeValue: "", isMust: false },
            ],
          },

        ],
      },
       */
      formData: {
        //底部的动态域字段
        filedList: [],

        gridList: [],
      },

      currentBigIndex: -1, //当前资质图片 gridList索引
    };
  },
  computed: {
    status() {
      const status = this.$store.state.qualification.detail
        ? this.$store.state.qualification.detail.status
        : "";
      return status;
    },
    showDraftBtn() {
      return this.status === this.IConst_State.None || this.status === "";
    },
  },
  created() {
    if (!this.$store.state.qualification.detail) {
      this.doLoadCertifyDetail();
    } else {
      this.formData = viewHelp.assistQualificationDetail(
        this.$store.state.qualification.detail
      );

    }
    
  },
  watch: {
    'formData': {
      handler(newVal, oldVal) {
        if (newVal) {
          this.watchFormData(newVal, oldVal)
        }
      },
      immediate:false,
      deep: true, //deep表示深度监听对象内部属性的变化 
      debounce: 1500
    },
  },

  methods: {
    
    watchFormData(newVal, oldVal) {
      if(this.$store.state.qualification.detail.id) return
      debounce(() => { 
        this.doCommitCertifyTempData();
      }, 500)
    },
    areaChangeGrid(bigIndex, subIndex) {
      // this.$refs['refForm'].fields.forEach(item => {
      //   if (item.prop == 'gridList.' +bigIndex +'.filedList.' +subIndex +'.modeValue') {
      //     item.resetField();
      //   }
      // })
    },
    areaChange(index) {
      // this.$refs['refForm'].fields.forEach(item => {
      //   if (item.prop == 'filedList.' + index + '.modeValue') {
      //     item.resetField();
      //   }
      // })
    },
    closeViewer() {
      this.showViewer = false;
    },
    handleDownTemplate(fileUrl) {
      window.open(fileUrl);
    },
    handleLookSample(fileUrl) {
      if (fileUrl && fileUrl.indexOf('pdf') > -1) {
        window.open(fileUrl, '_blank')
      } else {

        this.urls = [fileUrl];
        this.showViewer = true;
      }
    },
    handlePreview(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName][0].showViewer = true;
      }
    },
    handleDeleteImage(bigIndex, samllIndex) {
      const smallItem = this.formData.gridList[bigIndex].imageUrlList;
      smallItem.splice(samllIndex, 1);
      this.valiadteFormNow(bigIndex)
    },
    hideAddPicBtn(bigItem) {
      return (
        this.disabled ||
        (bigItem.imageUrlList &&
          bigItem.imageUrlList instanceof Array &&
          bigItem.imageUrlList.length >= this.IConst_Max_Pic_Count)
      );
    },
    handleSaveDraft() {
      this.$refs.refForm.validate((valid, fields) => {
        if (valid) {
          this.doSaveDraft();
        } else {
          for (const key in fields) {
            if (Object.hasOwnProperty.call(fields, key)) {
              const result = fields[key];
              if (result && result.length && result[0].message) {
                this.$message.error(result[0].message)
              }
            }
          }
        }
      });
    },
    uploadSubmit(obj) {
      const { type, url } = obj;
      const bigItem = this.formData.gridList[this.currentBigIndex];
      bigItem.imageUrlList.push(url);
      this.valiadteFormNow(this.currentBigIndex)
    },

    valiadteFormNow(index) { 
      this.$refs.refForm.validateField('gridList.' + index + '.imageUrlList')
    },

    handlePreStep() {
      this.$emit("pre-step");
    },
    handleSubmit() {
      this.$refs.refForm.validate((valid, fields) => {
        if (valid) {
          this.doSaveCommit();
        } else {
          for (const key in fields) {
            if (Object.hasOwnProperty.call(fields, key)) {
              const result = fields[key];
              if (result && result.length && result[0].message) {
                this.$message.error(result[0].message)
                return
              }
            }
          }
        }
      });
    },

    handleUpload(bigIndex) {
      this.currentBigIndex = bigIndex;
      this.$refs["imgupload"].open(this.imgType);
    },
    doLoadCertifyDetail(nextStep) {
      const params = {};
      const selectedCompanyTypes =
        this.$store.state.qualification.selectedCompanyTypes;
      if (
        selectedCompanyTypes &&
        selectedCompanyTypes instanceof Array &&
        selectedCompanyTypes.length > 0
      ) {
        params.typeCode = selectedCompanyTypes
          .map((item) => item.typeCode)
          .join(",");
      }

      getCertifyDetail(params).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            const shopDetail = res.data; 
            this.$store.dispatch(
              "qualification/setDetail",
              _.cloneDeep(shopDetail)
            );
            if (nextStep) {
              //进入下一步,应该就是提交
              this.$emit("submit");
            } else {
              this.formData = viewHelp.assistQualificationDetail(res.data);
            }
          }
        } else {
          viewHelp.showErrorMsg(this.$message, res);
        }
      });
    },
    doSaveDraft() {
      //保存草稿

      const serveData = viewHelp.generateServeData(this.formData);

      let typeCode = "";
      if (this.$store.state.qualification.selectedCompanyTypes) {
        const typeCodeArr =
          this.$store.state.qualification.selectedCompanyTypes.map(
            (item) => item.typeCode
          );
        typeCode = typeCodeArr.join(",");
      }
      const data = { ...serveData, shopTypeCode: typeCode };

      doTmpSaveCertify(data).then((res) => {
        if (res.code === 0) {
          this.doLoadCertifyDetail();
          viewHelp.showSuccessMsg(this.$message, "保存草稿成功");
        } else {
          viewHelp.showErrorMsg(this.$message, res);
        }
      });
      //
    },
    // 临时保存输入值
    doCommitCertifyTempData() { 
      const serveData = viewHelp.generateServeData(this.formData);
      let typeCode = "";
      if (this.$store.state.qualification.selectedCompanyTypes) {
        const typeCodeArr =
          this.$store.state.qualification.selectedCompanyTypes.map(
            (item) => item.typeCode
          );
        typeCode = typeCodeArr.join(",");
      }
      const data = { ...serveData, channel: 3 };
      if(typeCode && typeCode !='null'){
        data.shopTypeCode = typeCode;
      }
      doCommitCertifyTempData(data).then((res) => {
        if (res.code === 0) {
        } else {
        }
      });
    },
    doSaveCommit() {
      //修改或提交
      const serveData = viewHelp.generateServeData(this.formData);

      let typeCode = "";
      if (this.$store.state.qualification.selectedCompanyTypes) {
        const typeCodeArr =
          this.$store.state.qualification.selectedCompanyTypes.map(
            (item) => item.typeCode
          );
        typeCode = typeCodeArr.join(",");
      }
      const data = { ...serveData, shopTypeCode: typeCode };

      doCommitCertify(data).then((res) => {
        if (res.code === 0) {
          this.doLoadCertifyDetail(true);
        } else {
          viewHelp.showErrorMsg(this.$message, res);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.info-detail-root {
  width: 1012px;
  --border-line-color: #d9d9d9;

  display: flex;
  flex-direction: column;
}

.v-grid-list {
  padding-bottom: 8px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--border-line-color);
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);

  // column-count: 2;
  // column-width: 494px; /* 每列每个元素最小的宽度 */
  // column-gap: 24px; /* 每列的距离，不设置这个可以通过margin来设置边距 */
}

/**瀑布块 */
.block-item {
  display: inline-block;
  --btn-focus-color: #cf1422;

  flex-shrink: 0;
  width: 494px; //固定宽度
  background: #ffffff;
  border: 1px solid var(--border-line-color);
  border-radius: 4px;

  margin-bottom: 24px;
  margin-right: 0;

  .top-title {
    display: flex;
    flex-direction: column;
    padding: 12px 16px 12px 8px;
    border-bottom: 1px solid var(--border-line-color);

    .full-title {
      display: flex;
      align-items: center;

      box-sizing: border-box;
      justify-content: space-between;

      .big-title {
        display: flex;
        align-items: center;

        .weight {
          width: 8px;
          height: 22px;
          font-size: 14px;
          font-weight: 400;
          color: #f6212d;
          line-height: 22px;
          position: relative;
          top: 2px;
        }

        .txt {
          font-size: 14px;
          font-weight: 700;
          color: #262626;
        }
      }
    }

    .btn-layout {
      .el-button--text {
        color: var(--btn-focus-color);
      }

      ::v-deep .el-button {
        padding: 0;

        +.el-button {
          margin-left: 16px;
        }
      }
    }

    .remarks {
      padding-left: 8px;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 400;
      color: #8c8c8c;
      margin-top: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .content-layout {
    padding: 16px;
    box-sizing: border-box;

    .pic-layout {
      display: flex;

      .item {
        width: 86px;
        height: 86px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        position: relative;
        overflow: hidden;

        &+.item {
          margin-left: 8px;
        }

        .icon {
          position: relative;
          width: 100%;
          height: 100%;
        }

        .preview {
          position: absolute;
          visibility: hidden;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0.5;
          background: #000000;
          display: flex;
          align-items: center;
          justify-content: center;

          .btn-icon {
            width: 16px;
            height: 16px;
            cursor: pointer;

            &+.btn-icon {
              margin-left: 8px;
            }
          }
        }

        &:hover {
          .preview {
            visibility: visible;
          }
        }

        &.add-pic {
          border: 1px dashed #d9d9d9;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .image-wrap {
            border: 1px dashed #d9d9d9;
            width: 20px;
            height: 20px;

            &>* {
              width: 100%;
              height: 100%;
            }
          }

          .text {
            font-size: 14px;
            font-weight: 400;
            color: #595959;
            margin-top: 8px;
          }
        }
      }
    }

    .input-layout {
      display: flex;
      flex-wrap: wrap;
      padding-top: 8px;
      box-sizing: border-box;

      .input-item {
        margin-top: 8px;
        width: calc(50% - 4px);

        &:nth-child(2n) {
          margin-left: 8px;
        }

        &.fill-wrap {
          width: 100%;
        }
      }
    }
  }
}

/**底部动态域 */
.bottom-dynamic-filed {
  display: flex;
  flex-wrap: wrap;
  padding-top: 24px;
  padding-bottom: 32px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--border-line-color);

  .input-layout {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    box-sizing: border-box;

    .input-item {
      margin-top: 8px;
      width: calc(33.33333% - 6px);

      &:nth-child(3n + 2),
      &:nth-child(3n) {
        margin-left: 8px;
      }

      &.fill-wrap {
        width: 100%;
      }
    }
  }
}

/**按钮层 */
.bottom-btn-layout {
  display: flex;
  justify-content: center;
  margin-top: 32px;

  ::v-deep .el-button {
    width: 120px;

    &+.el-button {
      margin-left: 12px;
    }
  }

  ::v-deep .el-button--danger {
    background-color: #f5222d;
    border-color: #f5222d;

    &:hover {
      background: #f78989;
      border-color: #f78989;
    }
  }
}

/**el-form */
.el-form {
  .el-form-item {
    margin: 0 !important;
    padding: 0;
    box-sizing: border-box;
  }
}

::v-deep .el-form-item__content {
  line-height: inherit;

  .el-form-item__error {
    padding-top: 0px;
  }
}
</style>
