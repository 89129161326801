<template>
    <div class="dialog">
        <el-dialog title="试用任务" :visible.sync="visible" :lock-scroll="true" width="818px" :before-close="handleClose">
            <el-form :model="formTool" ref="formTool" label-width="140px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="probationDays" label="试用期"
                            :rules="{ required: true, message: '请填写试用期', trigger: 'blur' }">
                            <el-input v-model="formTool.probationDays" placeholder="请输入" clearable size="small"
                                style="width:80%" /> 
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="probationAmount" label="试用任务要求"
                            :rules="{ required: true, message: '请填写试用任务要求', trigger: 'blur' }">
                            <el-input v-model="formTool.probationAmount" placeholder="请输入" clearable size="small"
                                style="width:80%" />
                            {{ goodsUnit }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="cooperateDays" label="合作期"
                            :rules="{ required: true, message: '请填写合作期', trigger: 'blur' }">
                            <el-input v-model="formTool.cooperateDays" placeholder="请输入" clearable size="small"
                                style="width:80%" />
                            天
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="btnBox">
                    <el-button size="small" @click="handleClose">取消</el-button>
                    <el-button type="primary" size="small" @click="submit">确认</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>

import { updateTaskRule } from "@/api/externalSalesRule";
export default {
    components: {

    },
    props: {
        goodsUnit: {
            type: String,
            default: ''
        }
    },
    computed: {
        visible() {
            return this.dialogShow
        }
    },
    data() {
        return {
            formTool: {
                probationDays: '', //试用期目标天数 
                cooperateDays: '',//合作天数
                probationAmount: '', //业务员试用期目标件数
            },
            dialogShow: false,
            areaList: [],
            newAreaList: []
        }
    },
    created() {

    },
    methods: {

        handleClose() {
            this.dialogShow = false;
        },
        submit() { 
            let params = {};
            params.goodsId = this.$route.query.goodsId;
            params.deptId = this.$route.query.deptId;
            params.probationDays = this.formTool.probationDays;//试用期目标天数 
            params.probationAmount = this.formTool.probationAmount;//业务员试用期目标件数
            params.cooperateDays = this.formTool.cooperateDays;//合作天数
            params.areaList = this.newAreaList; 
            if(params.areaList.length==0){
                this.$message.error('请选择设置区域！');
                return;
            }
            this.$refs['formTool'].validate((valid)=>{
                if(valid){
                    updateTaskRule(params).then((res) => {
                        if (res) {
                            this.$message.success('提交成功');
                            this.$emit('getList')
                            this.handleClose();
                        }
                    });
                }
            })
          
        },

        areaTreeFind(list) {
            this.newAreaList = [];
            for (let i = 0; i < list.length; i++) {
                if(this.newAreaList.indexOf(list[i].id) == -1){
                   this.newAreaList.push(list[i].id); 
                }
            }
        },

        init(row) {
            // console.log(row[0], 'row')
            if(!row || row.length == 0) return this.$message.error('请选择区域');
            this.formTool.probationDays = row[0].probationDays;
            this.formTool.probationAmount = row[0].probationAmount;
            this.formTool.cooperateDays = row[0].cooperateDays;
            this.dialogShow = true;
            this.areaList = row;
            this.newAreaList = [];
            this.areaTreeFind(this.areaList);
            console.log(this.newAreaList, 'newAreaList')
        }
    }
}
</script>
<style lang="scss">
.btnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}</style>