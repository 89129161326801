<template>
  <div class="company-type-root">
    <div class="pic-wrap">
      <el-image :src="require('@/assets/zz/top-tip-pic.png')" />
    </div>
    <span class="big-tip">入驻问题欢迎拨打客服热线: 4000819577</span>

    <div class="gird-type">
      <div
        :class="{ item: true, selected: item.selected }"
        v-for="(item, index) in companyTypeList"
        :key="index"
        @click="handleClick(item)"
      >
        {{ item.typeName }}

        <el-image
          :src="require('@/assets/zz/selected-icon.png')"
          class="selected-icon"
        />
      </div>
    </div>

    <div class="bottom-btn-layout">
      <el-button type="danger" @click="handleNextStep">下一步</el-button>
    </div>
  </div>
</template>

<script>
import { getCompanyTypes } from "@/api/myQualification/index";

export default {
  components: {},
  data() {
    return {
      companyTypeList: [],
    };
  },
  created() {
    this.doCompanyTypeList();
  },
  methods: {
    clearSelected() {
      this.companyTypeList.forEach((item) => {
        item.selected = false;
      });
    },
    handleClick(item) {
      this.clearSelected();
      item.selected = item.selected ? false : true;
    },
    handleNextStep() {
      const selectedCompanyTypes = this.companyTypeList.filter(
        (item) => item.selected
      );
      if (!selectedCompanyTypes || selectedCompanyTypes.length == 0) {
        this.$message.warning("请选择企业类型");
        return;
      }
      this.$emit("next-step", selectedCompanyTypes);
    },

    doCompanyTypeList() {
      const params = {
        type: 1,
      };

      const selectedCompanyTypes =
        this.$store.state.qualification.selectedCompanyTypes;

      getCompanyTypes(params).then((res) => {
        if (res.code == 0) {
          if (res.data && res.data instanceof Array) {
            this.companyTypeList = res.data.map((item) => {
              item.selected = false;

              if (
                selectedCompanyTypes &&
                selectedCompanyTypes instanceof Array
              ) {
                item.selected = selectedCompanyTypes.some(
                  (subItem) => subItem.typeCode === item.typeCode
                );
              }

              return item;
            });
          }
        } else {
          viewHelp.showErrorMsg(this.$message, res);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.company-type-root {
  --border-line-color: #d9d9d9;

  display: flex;
  flex-direction: column;
  align-items: center;

  .pic-wrap {
    width: 684px;
    height: 144px;
  }

  .big-tip {
    font-size: 14px;
    font-weight: 400;
    color: #8c8c8c;
    margin-top: 8px;
  }

  .gird-type {
    width: 100%;
    padding: 20px 32px;
    margin-top: 32px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--border-line-color);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .item {
      margin: 4px;
      height: 32px;
      padding: 0 16px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      background: linear-gradient(90deg, #eaeaea, #f8f8f8 100%);
      border-radius: 4px;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: #595959;
      cursor: pointer;
      position: relative;

      .selected-icon {
        display: none;
      }

      &.selected {
        background: linear-gradient(90deg, #ffd4d5, #ffecec);
        color: #f6212d;

        .selected-icon {
          position: absolute;
          display: block;
          bottom: 0;
          right: 0;
          width: 24px;
          height: 20px;
          z-index: 1;
        }
      }
    }
  }

  /**按钮层 */
  .bottom-btn-layout {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    ::v-deep .el-button {
      width: 120px;

      & + .el-button {
        margin-left: 12px;
      }
    }

    ::v-deep .el-button--danger {
      background-color: #f5222d;
      border-color: #f5222d;

      &:hover {
        background: #f78989;
        border-color: #f78989;
      }
    }
  }
}
</style>
