import request from '@/utils/request'
// import qs from 'qs'
// import website from "@/utils/website";

// export function login(data) {
//   return request({
//     url: '/vue-admin-template/user/login',
//     method: 'post',
//     data
//   })
// }

export function getMenu(id) {
  return request({
    url: '/admin/menu',
    params: { parentId: id },
    method: 'get'
  })
}

export function fetchMenuTree(lazy, parentId) {
  return request({
    url: '/admin/menu/tree',
    method: 'get',
    params: { lazy: lazy, parentId: parentId }
  })
}

export function fetchMenuTreeShop(lazy, parentId) {
  return request({
    url: '/admin/menu/treeShop',
    method: 'get',
    params: { lazy: lazy, roleId: parentId }
  })
}

export function addObj(obj) {
  return request({
    url: '/admin/menu',
    method: 'post',
    data: obj
  })
}

export function getObj(id) {
  return request({
    url: '/admin/menu/' + id,
    method: 'get'
  })
}

export function delObj(id) {
  return request({
    url: '/admin/menu/' + id,
    method: 'delete'
  })
}

export function putObj(obj) {
  return request({
    url: '/admin/menu',
    method: 'put',
    data: obj
  })
}

export function clearMenuCache() {
  return request({
    url: '/admin/menu/cache',
    method: 'delete'
  })
}
// 根据商品id查询当前商品菜单点击率前四清单
export function memberMenuClickFrequency(shopId) {
  return request({
    url: `/member/memberMenuClickFrequency/${shopId}`,
    method: 'get'
  })
}

// 更新商铺菜单点击频次
export function updateClickFrequency(data) {
  return request({
    url: '/member/memberMenuClickFrequency/updateClickFrequency',
    method: 'post',
    data
  })
}