var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "headerTabs",
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        _vm._l(_vm.tabsList, function (item, index) {
          return _c(
            "el-tab-pane",
            { key: index, attrs: { label: item.label, name: item.name } },
            [
              item.value > 0
                ? _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(" " + _vm._s(item.label) + " "),
                      _c("el-badge", {
                        staticClass: "item",
                        attrs: { value: item.value },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        }),
        1
      ),
      _c("div", { staticClass: "rightCont" }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }