var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "uploadWrapper" },
    [
      _c(
        "vuedraggable",
        {
          staticClass: "vue-draggable",
          class: { single: _vm.isSingle, maxHidden: _vm.isMaxHidden },
          attrs: { tag: "ul", draggable: ".draggable-item" },
          on: { start: _vm.onDragStart, end: _vm.onDragEnd },
          model: {
            value: _vm.imgList,
            callback: function ($$v) {
              _vm.imgList = $$v
            },
            expression: "imgList",
          },
        },
        [
          _vm._l(_vm.imgList, function (item, index) {
            return _c(
              "li",
              {
                key: item + index,
                staticClass: "draggable-item",
                style: { width: _vm.width + "px", height: _vm.height + "px" },
              },
              [
                _c("el-image", {
                  attrs: { src: item, "preview-src-list": [item] },
                }),
                !_vm.isEdit
                  ? _c(
                      "div",
                      {
                        staticClass: "shadow",
                        on: {
                          click: function ($event) {
                            return _vm.onRemoveHandler(index)
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-delete" })]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c(
            "el-upload",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEdit,
                  expression: "!isEdit",
                },
              ],
              ref: "uploadRef",
              staticClass: "uploadBox",
              style: { width: _vm.width + "px", height: _vm.height + "px" },
              attrs: {
                slot: "footer",
                disabled: _vm.isEdit,
                action: _vm.action,
                accept: ".jpg,.jpeg,.png,.gif",
                "show-file-list": false,
                multiple: !_vm.isSingle,
                limit: _vm.limit,
                "before-upload": _vm.beforeUpload,
                headers: _vm.headers,
                "on-success": _vm.onSuccessUpload,
                "on-exceed": _vm.onExceed,
                "on-error": _vm.onError,
              },
              slot: "footer",
            },
            [
              _c("i", { staticClass: "el-icon-plus uploadIcon" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isUploading,
                        expression: "isUploading",
                      },
                    ],
                    staticClass: "uploading",
                  },
                  [_vm._v("正在上传...")]
                ),
                !_vm.isUploading &&
                _vm.limit &&
                _vm.limit !== 99 &&
                !_vm.isSingle
                  ? _c("span", { staticClass: "limitTxt" }, [
                      _vm._v("最多" + _vm._s(_vm.limit) + "张"),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        2
      ),
      _vm._t("tip"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }