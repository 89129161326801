<!--  -->
<template>
  <div class='classification' v-loading="loading">
  <!-- <Tabs :tabsList="tabsList"
          :activeName.sync="activeName"
                            @tab-click="handleChange" /> -->
    <el-form ref="formTool" :inline="true">
      <div>
        <div class="flex-box">
          <el-row   style="width:calc(100% - 60px)">
            <goods-three-class ref="three-class" :formTool="formTool" :isShowLable="true"
              :styleLable="'width:250px; margin-right: 10px'" :lableText="'商品分类'"></goods-three-class>

            <el-form-item prop="">
              <el-input v-model="formTool.name" size="small" placeholder="药品名称" clearable
                @keyup.enter.native="searchResult(1)" style="width: 200px;" />
            </el-form-item>

            <el-form-item prop="">
              <el-input v-model="formTool.factory" size="small" placeholder="厂家名称" clearable
                @keyup.enter.native="searchResult(1)" style="width: 200px;" />
            </el-form-item>
            <el-form-item prop="">
              <el-button size="small" type="primary" @click="searchResult(1)">检索并选择商品</el-button> 
            </el-form-item>
            <el-form-item prop="">
              <el-button size="small" type="plain" style="width: 100px;"
                @click="searchReset(1)">重置</el-button>
            </el-form-item> 
            <el-form-item prop="">
              <el-button :disabled="!addGoodsArr.length" size="small" type="primary" style="width: 100px;"
                @click="bathUpdateByProduct(1)">确定</el-button>
            </el-form-item> 
          </el-row>

        </div>
        <div class="" ref="tableCont">
          <giftGoodsList :records="records" @removeSelectedItem="updateSelectedGoods" @addGunalian="addGunalian"
            @updateRecords="updateRecords" />
        </div>
        <div class="el-page">
          <Pagination :total="pageObj.total" :page.sync="pageObj.page.current" :limit.sync="pageObj.page.size"
            @pagination="changeSize" />
        </div>
      </div>
    </el-form> 
  </div>
</template>

<script>
import Tabs from '@/components/Tabs'
import Pagination from '@/components/Pagination'
import giftGoodsList from './giftGoodsList'
import GoodsThreeClass from "@/components/goodsThreeClass";
import { goodsClassRelationsSelectGoods, bathUpdateByProduct } from '@/api/classificationmanagement'
import { queryFullGiftPage } from "@/api/gift";
import { goodsclassOne, goodsclassTwo } from "@/api/commodityreference";

import { mapGetters } from 'vuex'
export default {
  components: { Tabs, Pagination, giftGoodsList, GoodsThreeClass, },
  props: {
    rowData: {
      type: Object,
      defult: {}
    },
    status: {
      type: String,
      data: ''
    },
  },
  data() {
    return {
      loading: false,
      btnType: 'show',
      isShowSelectedResult: true,
      addList: [],
      addGoodsArr: [],
      activeName: '1', 
      pageObj: {
        // 分页对象
        position: 'right', // 分页组件位置
        total: 0,
        page: {
          size: 10,
          current: 1,
        }
      },
      formTool: {
        goodsOneClassId: '',
        goodsTwoClassId: '',
        goodsThreeClassId: '',
      },
      tableData: [],
      records: [],
      tabsList: [ // tabs 传的值
        {
          name: '1',
          label: '指定商品',
          value: 0,
        },
      ],

      props: {
        label: 'name',
        value: 'id',
      },
    };
  },
  computed: {
    // ...mapGetters(["couponGoods"]),
    isShowSave() {
      return !this.isShowSelectedResult
    }
  },
  watch: {},
  methods: {
    updateRecords(val) {
      console.log(val, 'val')
      this.records = val;
    },
    updateSelectedGoods(val) {
      if (this.isShowSelectedResult) {
        this.records = this.records.filter(item => {
          return item.skuId != val.skuId
        })
        this.$emit('choice', this.records)
      }
    },
    // 条数变化
    changeSize() {
      this.getList()
    },
    searchResult() {
      console.log(this.formTool)
      this.getList()
    },
    // 重置查询
    searchReset(){
      this.formTool ={};
      this.pageObj.page.current =1;  
      this.$refs["three-class"].resetForm(); 
      this.getList();
    },
    // 查询关联分类列表
    getList(current) {
      if (current) {
        // this.btnType = 'showFalse'
        this.pageObj.page.current = current
      }
      let params = {
        ...this.pageObj.page, 
      }
      params.name = this.formTool.name;
      params.factory = this.formTool.factory;
      params.oneOperationClassId  = this.formTool.goodsOneClassId;
      params.twoOperationClassId  = this.formTool.goodsTwoClassId;
      params.threeOperationClassId  = this.formTool.goodsThreeClassId;
      params.goodsId = this.$route.query.goodsId;
      queryFullGiftPage(params).then(res => {
        this.records = res.records
        this.pageObj.total = res.total;
      })
    },

    // 添加关联分类
    addGunalian(item) {
      this.addGoodsArr = [];
      if (item.clickNone) {
        this.addGoodsArr.push(item)
        this.isShowSelectedResult = true;
      } 
      // console.log(this.addGoodsArr, '---this.addGoodsArr')
      // console.log(item, 'item')
    },
    // 真正关联的接口
    bathUpdateByProduct() { 
      this.$emit('choice', this.addGoodsArr)
      // this.$message.success("保存成功")
      this.$parent.beforeClose() 
    },
    init() {
      this.getList();
    }
  },
  created() {

  },
  mounted() {
    this.$refs["three-class"].query()
    // this.getList()
  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
}
</script>
<style lang='scss' scoped>
.classification {
  height: 100%;
  padding: 0px;
  overflow: hidden;

  .tabs {
    ::v-deep .headerTabs {
      padding: 0px;
    }
  }

  .flex-box {
    width: 100%;
    display: flex;
    // padding: 10px 0;
    // flex-wrap: wrap;
    padding: 12px 0px;

    // border-bottom: 1px solid #f5f5f5;
    .el-row {
      .el-col {
        padding-right: 0px !important;
      }
    }

    .tab_sea {
      padding: 0px 5px;
    }
  }

  .contentCont {
    height: 532px;
    overflow: auto;
    padding: 0 0px;

    .contentInner {
      // height: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  .el-page {
    padding: 0px !important;
  }
}
</style>