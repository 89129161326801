
<!--  -->
<template>
  <div class="comContent">
    <div class="title">
      <div>
        <el-form ref="formTool"
                 :model="formTool"
                 :inline="true">
          <el-form-item label="海报名称"
                        prop="posterName">
            <el-input v-model="formTool.posterName"
                      style="width: 150px;"
                      size="small"
                      placeholder="请输入海报名称" />
          </el-form-item>
          <!-- <el-form-item label="部门负责人"
                        prop="fullName">
            <el-input v-model="formTool.fullName"
                      style="width: 150px;"
                      size="small"
                      placeholder="请输入部门负责人" />
          </el-form-item>
          <el-form-item label="部门ERPid"
                        prop="phone">
            <el-input v-model="formTool.phone"
                      style="width: 150px;"
                      size="small"
                      placeholder="请输入部门ERPid" />
          </el-form-item> -->
          <el-form-item label="审核状态"
                        prop="checkStatus">
            <el-select v-model="formTool.checkStatus"
                       style="width: 150px;"
                       size="small"
                       placeholder="请选择审核状态">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>
      </div>
      <div>
        <MyButton type="primary"
                  text="查询"
                  @click="getList(1)" />
        <el-button size="small"
                   @click="reset()">重置</el-button>
        <MyButton type="primary"
                  text="新建海报"
                  @click="editInfo()" />
      </div>
    </div>
    <div class="center">
      <MyTable :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption">
        <template slot="checkStatus"
                  slot-scope="scope">
          <span>{{scope.row.checkStatus == '0' ? '待审核' : scope.row.checkStatus == '1' ? '审核通过' :  scope.row.checkStatus == '2' ? '驳回' : ''}}</span>
        </template>
        <template slot="caozuo"
                  slot-scope="scope">
          <div style="display:flex">
            <el-link type="primary"
                     :underline="false"
                     size="small"
                     @click="editInfo(scope.row)">编辑</el-link>
            <!-- <el-link type="primary"
                     style="margin-left:8px"
                     :underline="false"
                     size="small"
                     @click="relevance(scope.row,'edit')">关联商品</el-link> -->
          </div>
        </template>
      </MyTable>
    </div>
    <!-- <el-dialog title="关联商品"
               :visible.sync="dialogVisible"
               width="900px"
               height="600px"
               :before-close="handleClose">
      <div>
        <div>商品名称：
          <el-input placeholder="输入商品名称"
                    style="width: 200px;"
                    size="small"
                    @change="change"
                    v-model="dialogInput">
          </el-input>
        </div>
        <div style="height: 500px;">
          <MyTable :table-data="dialogtableData"
                   style="height: calc(100% - 30px);"
                   :index="dialogindex"
                   :selection="dialogselection"
                   :page="dialogpage"
                   :operation="dialogoperation"
                   :table-option.sync="dialogtableOption"
                   @page-change="dialoggetList"
                   @handleSelectionChange="currentChange">
            <template slot="posterName"
                      slot-scope="scope">
              <div style="display: flex;justify-content: flex-start;align-items: center;">
                <img :src="scope.row.image || avater"
                     style="width: 80px;height: 80px;"
                     alt="">
                <div style="margin-left: 10px;">
                  <p style="margin: 3px 0;">{{  scope.row.name}}</p>
                  <p style="margin: 3px 0;">{{  scope.row.factory }}</p>
                  <p style="margin: 3px 0;">{{  scope.row.specification }}</p>
                </div>
              </div>
            </template>
            <template slot="price"
                      slot-scope="scope">
              <span>￥{{scope.row.lowestSalePrice}}~￥{{scope.row.highestSalePrice}}</span>
            </template>
          </MyTable>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="submit"
                   size="small">确 定</el-button>
        <el-button @click="handleClose"
                   size="small">取 消</el-button>

      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import avater from "/public/img/default.png"
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable'
import { relevancePage, salesposterinfoPage, salesposterinfoRelevance } from '@/api/salesControl/commodity'

export default {
  components: { MyButton, MyTable }, // , MenuForm, MyTabs, MyTable, MyTypes
  data () {
    return {
      id: '',
      dialogInput: '',
      avater: avater,
      dialogVisible: false,
      clearable: true,
      formTool: {
        posterName: '',
        checkStatus: ''
      },
      options: [
        {
          value: '0',
          label: '待审核'
        },
        {
          value: '1',
          label: '审核通过'
        }, {
          value: '2',
          label: '驳回'
        }],
      operation: false,
      selection: false,
      index: false, // 不展示序号，自己重新定义了序号
      tableData: [
       
      ],
      tableOption: [
        { label: '海报ID', prop: 'posterId' },
        { label: '海报名称', prop: 'posterName' },
        { label: '审核状态', prop: 'checkStatus', slot: true }, // 这里表示自定义列 , slot: true
        // { label: '在职状态', prop: 'roleCode' },
        { label: '创建时间', prop: 'createTime' },
        // { label: '用户ID', prop: 'userCode' },
        // { label: '角色', prop: 'roleDesc' },
        { label: '关联商品', prop: 'relevanceGoodsName' },
        { label: '操作', prop: 'caozuo', slot: true, width: '220px' }
      ],
      page: { total: 10, current: 1, size: 10 },

      // 下面是弹窗中的
      selectedData: [],
      dialogoperation: false,
      dialogselection: true,
      dialogindex: false, // 不展示序号，自己重新定义了序号
      dialogtableData: [
      ],
      dialogtableOption: [
        { label: '商品信息', prop: 'posterName', slot: 'true' },
        { label: '销售价格(元)', prop: 'price', slot: 'true' },
      ],
      dialogpage: { total: 10, current: 1, size: 10 },
    }
  },
  computed: {
  },
  watch: {},
  created () {
    this.relevancePage()
    this.getList()
  },
  mounted () { },
  methods: {
    // 弹窗切换分页
    dialoggetList () {

    },
    // 弹窗搜索事件
    change () {
      this.relevancePage()
      // console.log(this.dialogInput)
    },
    // 关闭弹窗
    handleClose () {
      this.dialogpage.current = 1
      this.dialogInput = ''
      this.dialogVisible = false
    },
    // 确认关联
    submit () {
      if (this.selectedData.length == 0) {
        this.$message.warning('请先选择关联的商品')
        return
      }
      let params = {
        id: this.id,
        goodsInfoQOS: this.selectedData
      }
      salesposterinfoRelevance(params).then(res => {
        this.$message.success('关联成功')
        this.handleClose()
      })
    },
    // 关联商品
    relevance (row) {
      this.dialogVisible = true
      this.id = row.id
    },
    // 新增或编辑部门 
    editInfo (row) {
      if (row) {
        this.$router.push({
          path: '/salesControlMange/salesControl-placard-add',
          query: {
            id: row.id
          }
        })
      } else {
        this.$router.push({
          path: '/salesControlMange/salesControl-placard-add',
          query: {
            id: ''
          }
        })
      }
    },
    getList (num) {
      if (num) {
        this.page.current = num
      }
      console.log(this.page, 'getList')
      const { current, size } = this.page
      let d = { current, size, ...this.formTool };
      this.listLoading = true;
      d.clientTag='sell';
      // @Parameter(name = "clientTag",description = "客户端标识（app：app端，sell:卖家端, ops：ops端）"),
      salesposterinfoPage(d).then((res) => {
        this.tableData = res.records
        this.page.total = res.total
        this.listLoading = false
      }).catch(() => {
        this.listLoading = false
      })
    },
    cancal () {
      this.dialogPermissionVisible = false
    },
    reset () {
      this.formTool = { posterName: '', checkStatus: '' }
      // this.$refs.formTool.resetFields()
      this.page.current = 1
      this.getList(1)
    },
    currentChange (val) {
      this.selectedData = val
    },
    relevancePage () {
      const { current, size } = this.dialogpage
      let params = {
        current, size,
        name: this.dialogInput
      }
      relevancePage(params).then(res => {
        this.dialogtableData = res.records
        this.dialogpage.total = res.total
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.comContent {
  padding: 0px;
  .title {
    height: 56px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f5f5ff;
    .el-form-item {
      margin-bottom: 0px !important;
    }
  }
  .center {
    margin: 0 24px;
    margin-top: 16px;
    height: calc(100% - 131px);
  }
}
</style>
