var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "gift", staticStyle: { color: "#f5222d!important" } },
    [_vm._v("赠")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }