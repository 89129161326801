// import auth from '@/plugins/auth'
import router, { constantRoutes, dynamicRoutes } from "@/router";
// import { getRouters } from '@/api/menu'
import { getRouteList } from "@/api/route";
import { getMenu } from "@/api/user";
import Layout from "@/layout/index";
import ParentView from "@/components/ParentView";
import InnerLink from "@/layout/components/InnerLink";
import { deepClone } from "@/utils/index";
import { isURL, validatenull } from "@/utils/validate";
import webiste from "@/utils/website";
import { Message } from "element-ui";
// const Routes = () => {
//   return {
//     demo: () => import('@/views/demo/index.vue')
//   }
// }

function addPath(ele, first) {
  const menu = webiste.menu;
  const propsConfig = menu.props;
  const propsDefault = {
    label: propsConfig.label || "name",
    path: propsConfig.path || "path",
    icon: propsConfig.icon || "icon",
    children: propsConfig.children || "children",
  };
  const icon = ele[propsDefault.icon];
  ele[propsDefault.icon] = validatenull(icon) ? menu.iconDefault : icon;
  const isChild =
    ele[propsDefault.children] && ele[propsDefault.children].length !== 0;
  if (!isChild) ele[propsDefault.children] = [];
  if (!isChild && first && !isURL(ele[propsDefault.path])) {
    ele[propsDefault.path] = ele[propsDefault.path] + "/index";
  } else {
    ele[propsDefault.children].forEach((child) => {
      addPath(child);
    });
  }
}

const ResetMenu = (d, index) => {
  let new_routes = [];
  for (let i = 0; i < d.length; i++) {
    // console.log(d[i].rules)
    const { name, path, icon, children, showState, dotNum } = d[i];
    let d_li = {
      name: path.substr(1).replaceAll("/", "_"),
      path: index == 0 ? path : path.substr(1),
      component: index == 0 ? "Layout" : path.substr(1).replaceAll("-", "/"),
      hidden: showState == "1",
      meta: { title: name, icon, dotNum },
    };
    if (children && children.length > 0) {
      d_li.children = ResetMenu(children, index + 1);
      d_li.redirect = d_li.children[0].path;
    }
    new_routes.push(d_li);
    // if (rules && rules.length > 0) {
    //   if (rules.includes(my_rule)) {
    //     // str = str.replaceAll('apples','oranges');
    //     let d_li = { name, path, hidden, component, meta }
    //     if (children && children.length > 0) {
    //       d_li.children = CheckRoles(children, my_rule)
    //     }
    //     new_routes.push(d_li)
    //   }
    // }
  }
  return new_routes;
};

const CheckRoles = (d, my_rule) => {
  let new_routes = [];
  for (let i = 0; i < d.length; i++) {
    // console.log(d[i].rules)
    const { rules, name, path, hidden, component, meta, children } = d[i];
    if (rules && rules.length > 0) {
      if (rules.includes(my_rule)) {
        let d_li = { name, path, hidden, component, meta };
        if (children && children.length > 0) {
          d_li.children = CheckRoles(children, my_rule);
        }
        new_routes.push(d_li);
      }
    }
  }
  return new_routes;
};

const permission = {
  state: {
    routes: [],
    addRoutes: [],
    defaultRoutes: [],
    topbarRouters: [],
    sidebarRouters: [],
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes;
      state.routes = constantRoutes.concat(routes);
    },
    SET_DEFAULT_ROUTES: (state, routes) => {
      state.defaultRoutes = constantRoutes.concat(routes);
    },
    SET_TOPBAR_ROUTES: (state, routes) => {
      state.topbarRouters = routes;
    },
    SET_SIDEBAR_ROUTERS: (state, routes) => {
      state.sidebarRouters = routes;
    },
  },
  actions: {
    // 生成路由
    GenerateRoutesb({ commit, dispatch, state }, obj) {
      const { id, type } = obj;
      return new Promise((resolve) => {
        getMenu(id).then((res) => {  
          if (res.data) {
            let menu = ResetMenu(deepClone(res.data), 0);  
            const sdata = filterMenusUndefined(deepClone(menu)) || []; // routers res.data 
            const rdata = filterMenusUndefined(deepClone(menu)) || []; 
            const sidebarRoutes = filterAsyncRouter(sdata);
            const rewriteRoutes = filterAsyncRouter(rdata, false, true); 
            commit("SET_ROUTES", rewriteRoutes);
            commit("SET_SIDEBAR_ROUTERS", constantRoutes.concat(sidebarRoutes));
            commit("SET_DEFAULT_ROUTES", sidebarRoutes);
            commit("SET_TOPBAR_ROUTES", sidebarRoutes);
            resolve(rewriteRoutes);
          } else {
            const sidebarRoutes = filterAsyncRouter([]);
            const rewriteRoutes = filterAsyncRouter([], false, true);
            commit("SET_ROUTES", rewriteRoutes);
            commit("SET_SIDEBAR_ROUTERS", constantRoutes.concat(sidebarRoutes));
            commit("SET_DEFAULT_ROUTES", sidebarRoutes);
            commit("SET_TOPBAR_ROUTES", sidebarRoutes);
            resolve(rewriteRoutes);
            Message.error("用户未设置任何权限,请联系管理员");
          
          }
        });
      });
    },

    // 更新路由-dot信息
    ClearRoutesDot({ commit, state }, path) {
      const routerArray = state.sidebarRouters;
      const subPath = path.split("/");
      subPath[1] && (subPath[1] = `/${subPath[1]}`);
      function loop(routes) {
        for (let index = 0; index < routes.length; index++) {
          let el = routes[index];
          if (subPath.includes(el.path)) {
            el.meta.dotNum = 0;
          }
          if (el.children && el.children.length > 0) {
            loop(el.children);
          }
        }
      }
      loop(routerArray);
      commit("SET_ROUTES", routerArray); 
    },
  },
};

// 过滤菜单中不存在的路由
function filterMenusUndefined(menuData) {
  if (!menuData) return [];
  menuData.forEach((item) => {
    if (item.children) {
      item.children = item.children.filter((child) => {  
        const ishave = checkRouteExists(child.path); 
        if(ishave){
         return child; 
        } 
      });
    }
  });
  menuData = menuData.filter((e)=>{ 
    return e.children && e.children.length > 0
  }) 
  return menuData;
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) { 
  return asyncRouterMap.filter((route) => { 
    if (type && route.children) {
      route.children = filterChildren(route.children);
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === "Layout") {
        route.component = Layout;
      } else if (route.component === "ParentView") {
        route.component = ParentView;
      } else if (route.component === "InnerLink") {
        route.component = InnerLink;
      } else {
        route.component = loadView(route.component); 
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type);
    } else {
      delete route["children"];
      delete route["redirect"];
    }

    return true;
  });
}

// 校验路由string是否存在于views文件夹中
function checkRouteExists(file) {
  file = file.replace(/-/g, "/");
  const requirePlugin = require.context("./../../views", true, /\.vue$/);
  let isHaveModule = false;
  let fileArr = requirePlugin.keys(); // 获取项目中views文件夹下所有的文件名 及路径 
  fileArr.map((item) => {
    let strFirstIndex = item.indexOf("/") + 1;
    let strLastIndex = item.lastIndexOf(".vue");
    let itemString = item.substring(strFirstIndex, strLastIndex);
    if (itemString === file) { 
      isHaveModule = true;
    }
  });
  return isHaveModule;
}

function filterChildren(childrenMap, lastRouter = false) {
  var children = [];
  childrenMap.forEach((el, index) => { 
    if (el.children && el.children.length) {
      if (el.component === "ParentView" && !lastRouter) {
        el.children.forEach((c) => {
          c.path = el.path + "/" + c.path; 
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }
          children.push(c);
        });
        return;
      }
    } 
    if (lastRouter) {
      el.path = lastRouter.path + "/" + el.path;
    }
    children = children.concat(el);
  });
  return children;
}

// 动态路由遍历，验证是否具备权限
export function filterDynamicRoutes(routes) {
  const res = [];
  routes.forEach((route) => {
    if (route.permissions) {
      if (auth.hasPermiOr(route.permissions)) {
        res.push(route);
      }
    } else if (route.roles) {
      if (auth.hasRoleOr(route.roles)) {
        res.push(route);
      }
    }
  });
  return res;
}

export const loadView = (view) => {
  // console.log(`@/views/${view}.vue`)
  // console.log('113', view, process.env.NODE_ENV)
  return (resolve) => require([`@/views/${view}.vue`], resolve);
  // if (process.env.NODE_ENV === 'development') {
  //   // return (resolve) => require([`@/views/${view}`], resolve)
  //   return (resolve) => require([`@/views/${view}.vue`], resolve)
  //   // return () => require(`@/views/${view}`)
  //   // return () => import(`@/views/${view}`)
  // } else {
  //   // 使用 import 实现生产环境的路由懒加载
  //   // return () => import(`@/views/${view}`)
  //   return () => require(`@/views/${view}`)
  // }
};

export default permission;
