import request from '@/utils/request'

// 获取行政区 
export function regionTree(data) {
  return request({
    url: `/shop/region/tree`,
    method: 'get',
    // params: data
  })
}

// 获取组织架构树
export function dfcTree(data) {
  return request({
    url: `/shop/dfc/tree`,
    method: 'get',
    params: data
  })
}

// 查询：商户下拉列表
export function dfcQuery(data) {
  return request({
    url: `/shop/dfc/query`,
    method: 'get',
    // params: data
  })
}

// 查询组织关联商户
export function dfcRel(data) {
  return request({
    url: `/shop/dfc/rel`,
    method: 'get',
    params: data
  })
}

// 添加组织关联商户
export function dfcAdd(data) {
  return request({
    url: `/shop/dfc`,
    method: 'PUT',
    data
  })
}

// 移除组织关联商户
export function dfcDel(data) {
  return request({
    url: `/shop/dfc/${data}`,
    method: 'delete'
  })
}

// 查询：地区公司已分配
export function alreadyAllocation(data) {
  return request({
    url: `/shop/dfc/already/allocation`,
    method: 'get',
    params:data
  })
}

// 查询：地区公司待分配
export function waitAllocation(data) {
  return request({
    url: `/shop/dfc/wait/allocation`,
    method: 'post',
    data
  })
}

// 确认分配
export function enterAllocation(data) {
  return request({
    url: `/shop/dfc/enter/allocation`,
    method: 'post',
    data
  })
}

// 添加组织关系
export function addOrg(data) {
  return request({
    url: `/shop/dfc/add/org`,
    method: 'put',
    data
  })
}

// 编辑组织关系
export function editOrg(data) {
  return request({
    url: `/shop/dfc/edit/org`,
    method: 'put',
    data
  })
}

// 删除组织关系
export function deleteOrg(data) {
  return request({
    url: `/shop/dfc/org/${data}`,
    method: 'DELETE',
    data
  })
}

// 查询地区列表
export function orgArea(data) {
  return request({
    url: `/shop/dfc/org/area`,
    method: 'GET',
    params:data
  })
}

// 批量添加组织-地区关联关系
export function dfcBatch(data) {
  return request({
    url: `/shop/dfc/batch`,
    method: 'POST',
    data
  })
}

// 授权
export function authorization(data) {
  return request({
    url: `/shop/dfc/authorization`,
    method: 'put',
    data
  })
}

// 获取组织对应的省市区树
export function orgTree(data) {
  return request({
    url: `/shop/dfc/org/tree`,
    method: 'post',
    data
  })
}

// 修改数据统一查看
// /organization/data/unity/view

export function dataUnityView(data) {
  return request({
    url: `/shop/organization/data/unity/view`,
    method: 'PUT',
    data
  })
}

// 根据当前登录人获取是否主省区
// /shop/dfc/data/unity
export function dfcDataUnity(data) {
  return request({
    url: `/shop/dfc/data/unity`,
    method: 'GET',
    params:data
  })
}
 