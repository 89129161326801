var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("groupList"),
      _c("searchArea", { on: { search: _vm.search } }),
      _c("Tabs", {
        staticStyle: { padding: "0 0px" },
        attrs: { tabsList: _vm.tabsList, activeName: _vm.activeName },
        on: {
          "tab-click": _vm.handleClick,
          "update:activeName": function ($event) {
            _vm.activeName = $event
          },
          "update:active-name": function ($event) {
            _vm.activeName = $event
          },
        },
      }),
      _c("MyTable", {
        attrs: {
          "table-data": _vm.tableData,
          index: _vm.index,
          selection: _vm.selection,
          page: _vm.page,
          operation: _vm.operation,
          "table-option": _vm.tableOption,
        },
        on: {
          "update:tableOption": function ($event) {
            _vm.tableOption = $event
          },
          "update:table-option": function ($event) {
            _vm.tableOption = $event
          },
          "page-change": _vm.getList,
        },
        scopedSlots: _vm._u([
          {
            key: "status",
            fn: function (scope) {
              return [
                _c("el-row", [
                  _vm._v(" " + _vm._s(_vm.getstatusVal(scope.row.state)) + " "),
                ]),
              ]
            },
          },
          {
            key: "info",
            fn: function (scope) {
              return [
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c("div", { staticClass: "goodsInfo" }, [
                        _c("div", { staticClass: "img" }, [
                          _c("img", {
                            attrs: {
                              src: scope.row.goodsImg
                                ? scope.row.goodsImg
                                : _vm.avater,
                              alt: "",
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "content" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(scope.row.goodsName)),
                          ]),
                          _c("div", { staticClass: "company" }, [
                            _vm._v(_vm._s(scope.row.goodsFactory)),
                          ]),
                          _c("div", { staticClass: "spec" }, [
                            _vm._v(_vm._s(scope.row.goodsSpecification)),
                          ]),
                          _c("div", { staticClass: "no" }, [
                            _vm._v(_vm._s(scope.row.goodsApprovalNumber)),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "price",
            fn: function (scope) {
              return [
                _c("el-row", [
                  _vm._v(
                    " 拼团价: ￥" +
                      _vm._s(Number(scope.row.groupBuyPrice).toFixed(2))
                  ),
                ]),
                _c("el-row", [
                  _vm._v(
                    " 起拼数量: " + _vm._s(scope.row.minSingleBuyCount) + " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "inventory",
            fn: function (scope) {
              return [
                _c("el-row", [
                  _vm._v(
                    " 活动总限购数量: " +
                      _vm._s(scope.row.maxGroupTotalCount) +
                      " "
                  ),
                ]),
                _c("el-row", [
                  _vm._v(
                    " 单客户限购数量: " +
                      _vm._s(scope.row.userMaxTotalBuyCount) +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "time",
            fn: function (scope) {
              return [
                _c("el-row", [
                  _vm._v(
                    " " + _vm._s(scope.row.startDate + "~" + scope.row.endDate)
                  ),
                ]),
              ]
            },
          },
          {
            key: "menu",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  { staticClass: "btn-warp" },
                  [
                    scope.row.state === 1
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    scope.row.state === 1
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.del(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    scope.row.state !== 1
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.detail(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }