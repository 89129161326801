<template>
  <el-dialog :title="title" :visible.sync="visible" :lock-scroll="true" width="718px" :before-close="handleClose">
    <div class="">
      <div style="margin-left:80px;">
        保证金充值金额请大于等于两万元
      </div>
      <div style="padding: 24px 0;">
        <el-form ref="form-data" :model="form" :inline="false" label-width="150px" label-position="right" :rules="rules"
          style="text-align:left;">
          <el-form-item   label="收款户名">
            <el-input v-model="form.payee" style="width: 400px;"  clearable size="small" :disabled="true"  />
          </el-form-item>
          <el-form-item   label="开户银行">
            <el-input v-model="form.payeeAccount" style="width: 400px;"  clearable size="small" :disabled="true"  /> 
          </el-form-item>
          <el-form-item  label="收款账号">
            <el-input v-model="form.payeeBank" style="width: 400px;"  clearable size="small" :disabled="true"  />  
          </el-form-item> 
          <el-form-item prop="amount" label="打款金额">
            <el-input v-model.trim="form.amount" style="width: 400px;" placeholder="请输入" clearable size="small"
              onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" :maxlength="10" :disabled="isDisabled" />
          </el-form-item>

          <el-form-item prop="remitTime" label="汇款日期">
            <el-date-picker v-model="form.remitTime" style="width: 400px;" value-format="yyyy-MM-dd HH:mm:ss"
              :disabled="isDisabled" type="datetime" placeholder="选择日期" />
          </el-form-item>

          <el-form-item prop="remitNo" label="打款尾号">
            <el-input v-model.trim="form.remitNo" style="width: 400px;" placeholder="请输入" clearable size="small"
              show-limit-word :maxlength="8" @keyup.native="proving2" :disabled="isDisabled" />
          </el-form-item>

          <el-form-item prop="remitTitle" label="打款抬头">
            <el-input v-model.trim="form.remitTitle" style="width: 400px;" placeholder="请输入" clearable size="small"
              :disabled="isDisabled" show-limit-word max-length="50" />
          </el-form-item>

          <el-form-item prop="fileList" label="打款凭证">
            <fileUpload v-model="fileList" @onRemoveHandler="onRemoveHandler" :limit="3" :isEdit="isDisabled" v-if="fileListShow">
            </fileUpload>
          </el-form-item>

          <el-form-item label="审核意见" v-if="isDisabled">
            <el-input v-model.trim="form.remarks" style="width: 400px;" placeholder="" clearable size="small"
              type="textarea" show-limit-word max-length="200" :disabled="isDisabled" />
          </el-form-item>

          <el-form-item>
            <div class="footer-btn">
              <el-button size="small" type="primary" @click="saveSubmit()" :disabled="isDisabled">提交审核</el-button>
              <el-button size="small" type="plain" @click="handleClose()">返回</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>
    
<script>
import fileUpload from "@/components/fileUpload";
import { submitCert, platformApproveInfo, guaranteeApproveInfo } from "@/api/surety";
import {informationPlatform} from "@/api/shop"
import avater from "/public/img/default.png";
import imgUpload from '@/components/imgUpload';
export default {
  props: {},
  components: { imgUpload, fileUpload },

  data() {
    return {
      fileList: [
        // {
        //   "url": "https://eshop-develop.oss-cn-hangzhou.aliyuncs.com/goods/店铺32131_2023110509455080757858.png",
        //   "name": "店铺32131_2023110509455080757858.png",
        //   "status": "success"
        // }
      ],
      avater: avater,
      disabled: false,
      id: '',
      pageType: '',
      isDisabled: false,
      form: {
        amount: null,
        remitTime: null,
        remitNo: null,
        remitTitle: null,
        cert: '',
        cert2: '',
        cert3: '',
        fileList: [],
        payee:'',
        payeeBank:'',
        payeeAccount:''
      },
      rules: {
        amount: [{ required: true, message: '请填写打款凭证', trigger: 'blur' }],
        remitTime: [{ required: true, message: '请填写汇款日期', trigger: 'blur' }],
        remitNo: [{ required: true, message: '请填写打款尾号', trigger: 'blur' }],
        remitTitle: [{ required: true, message: '请填写打款抬头', trigger: 'blur' }],
        fileList: [{ required: true, message: '请上传打款凭证', trigger: 'blur' }],
      },
      title: '',
      visible: false,
      fileListShow:true,
    }
  },
  created() {

  },
  mounted() {
  },
  watch: {},
  methods: {
    // 平台信息查询
    informationPlatform(){
      informationPlatform({}).then((res)=>{
        this.form.payee = res.yykAccountName;
        this.form.payeeBank = res.bankDeposit;
        this.form.payeeAccount = res.bankCardNumber;
        console.log(res,'res')
      }).catch()
    }, 
    //      验证只能输入正数，且保留2位小数
    proving1() {
      this.form.amount = this.form.amount.replace(/^(0|[1-9]\d*)(\.\d{1,2})?$/, '');
    },
    proving2() {
      this.form.remitNo = this.form.remitNo.replace(/[^\.\d]/g, '');
      this.form.remitNo = this.form.remitNo.replace('.', '');
    },
    // 删除上传的文件
    onRemoveHandler(file) {
      let index = this.fileList.indexOf(file);
      this.fileList.splice(index, 1);
    },

    saveSubmit() {   
      let params = { ...this.form };
      if (this.fileList.length == 0) {
        this.$message.error('请上传打款凭证');
        return;
      }

      if (this.fileList) {
        params.cert = this.fileList[0].url;
        if (this.fileList.length > 1) {
          params.cert2 = this.fileList[1].url;
        }
        if (this.fileList.length > 2) {
          params.cert3 = this.fileList[2].url;
        }
      }
      this.form.fileList = this.fileList;

      this.$refs['form-data'].validate((valid) => {
        if (valid) {
          submitCert(params).then((res) => {
            if (res) {
              this.$message.success('提交成功');
              this.$emit('getList')
              this.handleClose();
              this.form.fileList = [];
              this.fileList = [];
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    handleClose() {
      Object.keys(this.form).forEach((key) => (this.form[key] = ""));
      this.form.fileList = [];
      this.fileList = [];
      this.$refs['form-data'].resetFields();
      this.visible = false;
    },
    init(row, type) {
   
      
      this.title = '保证金充值';
      if (type == 'detail') {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
      // console.log(row, '---row')
      this.form.fileList = [];
      this.fileList = [];
      if (row && row.id) {
        this.query(row.id);
      } else {
        this.visible = true;
      }
      this.informationPlatform()
      
      this.$forceUpdate();
    },
    query(id) {
      console.log(id)
      this.form.approveId = id;
      this.fileListShow = false;
      guaranteeApproveInfo({ id: id }).then(res => {
        this.form = Object.assign(this.form, res);
        if (this.form.amount) {
          this.form.amount = this.form.amount.toFixed(2)
        }
        // 创建一个数组，包含证书名称  
        var certs = ['cert', 'cert2', 'cert3'];
        // 遍历数组  
        var fileList = []
        for (var i = 0; i < certs.length; i++) {
          var cert = res[certs[i]];
          if (cert) {
            fileList.push({
              url: cert,
              name: cert.indexOf('http') != -1 ? cert.substring(cert.lastIndexOf('/') + 1, cert.length) : cert,
              "status": "success"
            });
          } 
        } 
        this.fileList = fileList;
        this.form.fileList = fileList;
        this.fileListShow = true;
        this.$nextTick(() => { this.visible = true; })
      })
    },

  }
}

</script>
<style lang="scss" scoped>
.eachRow {
  background-color: #fff;
  border-radius: 4px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
}

.titleView {
  display: flex;
  align-items: center;

  .rowLine {
    width: 4px;
    border-color: 1px;
    background-color: var(--main-color);
    height: 16px;
    margin-right: 8px;
  }

  .eachRowTitle {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
  }

  .cpxhead {
    display: flex;
    align-items: center;
  }

  .mark {
    color: #f56c6c;
    font-size: 12px;
    margin-left: 10px;
    width: 200px;
    line-height: 1.5;
    display: inline-block;
    font-weight: bold;
  }
}

.goods-list-warp {
  width: 100%;
  margin-left: 100px;
  max-height: 500px;
  overflow-y: auto;
}

.goods-list {
  width: 50%;
  border-bottom: solid 1px #f5f5f5;
  padding-bottom: 10px;

  &:nth-last-child(1) {
    border-bottom: none;
  }
}

.footer-btn {
  width: 410px;
  display: flex;
  justify-content: space-between;
}
</style>
  
  