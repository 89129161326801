<template>
  <div class="content">
    <Tabs :tabsList="tabsList"
          style="padding:0 24px"
          :activeName="activeVal"
          @tab-click="handleChange">
      <el-form v-if="activeVal=='2'"
               :inline="true"
               size="small"
               :model="searchFrom"
               class="demo-form-inline">
        <el-form-item>
          <el-input v-model="searchFrom.feeName"
                    style="width:200px"
                    placeholder="请输入运费名称"
                    @input="searchHandle"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchFrom.feeType"
                     style="width:200px;"
                     placeholder="请选择运费类型"
                     @change="searchHandle">
            <el-option v-for="item in [{label:'商家承担运费 ',value:0},{label:'自定义运费',value:1}]"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker size="small"
                          style="margin-right: 10px;"
                          v-model="searchFrom.createTime"
                          @change="searchHandle"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          type="daterange"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     icon="el-icon-plus"
                     @click="handleFreight">新增运费</el-button>
        </el-form-item>

      </el-form>
      <el-button type="primary"
                 v-if="activeVal=='3'"
                 icon="el-icon-plus"
                 size="small"
                 @click="onSubmit">新增地址</el-button>
    </Tabs>
    <!-- <Announcement ref="Announcement"
                  v-if="activeVal=='1'"></Announcement>
    <Freight ref="Freight"
             v-if="activeVal=='2'"></Freight> -->
    <Aftermarket ref="Aftermarket"
                 v-if="activeVal=='3'"></Aftermarket>

    <!-- <BillingSetup ref="billingSetup"
                  v-if="activeVal=='4'"></BillingSetup> -->
    <!-- <RateInformation ref="rateInformation"
                     v-if="activeVal=='5'"></RateInformation> -->
    <el-dialog title="新增运费"
               :visible.sync="dialogVisible"
               width="480px"
               :before-close="handleClose">
      <el-form :model="ruleForm"
               label-position="top"
               size="small"
               :rules="rules"
               ref="ruleForm"
               label-width="100px"
               style="padding: 4px;"
               :disabled="typeFreight=='detail'"
               class="demo-ruleForm">
        <el-form-item label="区域"
                      prop="areaList">
          <div style="width: 100%;height: 120px;overflow-y: auto;">
            <el-tree :props="{label:'rname',children: 'children',}"
                     :data="areaTreeData"
                     node-key="id"
                     ref="tree"
                     show-checkbox>
            </el-tree>
          </div>

        </el-form-item>
        <el-form-item label="是否包邮"
                      prop="feeType">
          <el-radio-group v-model="ruleForm.feeType">
            <el-radio :label="0">商家承担运费</el-radio>
            <el-radio :label="1">自定义运费</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="运费名称"
                      prop="feeName">
          <el-input v-model="ruleForm.feeName"
                    placeholder="请输入运费名称"></el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.feeType"
                      label="满足以下金额包邮"
                      prop="freeShippingOrderAmount">
          <el-input v-model="ruleForm.freeShippingOrderAmount"
                    placeholder="请输入金额">
            <div slot="prefix">￥</div>
          </el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.feeType"
                      label="不满足条件时收取以下金额运费"
                      prop="carriageAmount">
          <el-input v-model="ruleForm.carriageAmount"
                    placeholder="请输入金额">
            <div slot="prefix">￥</div>
          </el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.feeType"
                      label="低于等于以下金额不允许下单"
                      prop="minOrderAmount">
          <el-input v-model="ruleForm.minOrderAmount"
                    placeholder="请输入金额">
            <div slot="prefix">￥</div>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button size="small"
                   @click="handleClose">取 消</el-button>
        <el-button :disabled="typeFreight=='detail'"
                   size="small"
                   type="primary"
                   @click="postShopExpressFees">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="新增地址"
               :visible.sync="dialogAddress"
               width="480px"
               :before-close="handleClose">
      <el-form :model="ruleForm"
               label-position="top"
               size="small"
               :rules="rules"
               ref="ruleForm"
               label-width="100px"
               style="padding: 4px;"
               :disabled="typeAfterMarket=='detail'"
               class="demo-ruleForm">
        <el-form-item label="生效省份"
                      prop="areaList">
          <div style="width: 100%;height: 120px;overflow-y: auto;">
            <el-tree :props="{label:'rname',children: 'children',}"
                     :data="areaTreeData"
                     node-key="id"
                     ref="tree"
                     show-checkbox>
            </el-tree>
          </div>

        </el-form-item>

        <el-form-item label="收货人"
                      required>
          <el-input v-model="ruleForm.receiver"
                    placeholder="请输入收货人，限10个字"></el-input>
        </el-form-item>
        <el-form-item label="联系电话"
                      required>
          <el-input v-model="ruleForm.receiverPhone"
                    placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="收货地址"
                      required>
          <el-select v-model="ruleForm.province"
                     style="width:130px;margin-right: 8px;"
                     value-key="id"
                     placeholder="请选择省份"
                     @change="selectedHandle">
            <el-option v-for="item in provinceData"
                       :key="item.id"
                       :label="item.rname"
                       :value="item">
            </el-option>
          </el-select>
          <el-select v-model="ruleForm.city"
                     style="width:138px;margin-right: 8px;"
                     placeholder="请选择城市"
                     value-key="id"
                     @change="selectedHandleCity">
            <el-option v-for="item in cityData"
                       :key="item.id"
                       :label="item.rname"
                       :value="item">
            </el-option>
          </el-select>
          <el-select v-model="ruleForm.area"
                     style="width:138px"
                     placeholder="请选择区域"
                     value-key="id"
                     @change="selectedHandleArea">
            <el-option v-for="item in areaData"
                       :key="item.id"
                       :label="item.rname"
                       :value="item">
            </el-option>
          </el-select>
          <el-input style="margin-top:8px"
                    v-model="ruleForm.receiverAddrDetail"
                    placeholder="请输入详细地址">
          </el-input>
        </el-form-item>
        <el-form-item label="快递说明"
                      required>
          <el-input v-model="ruleForm.expressDesc"
                    placeholder="请输入快递说明，例如：退货不支持申通、邮政、天天快递。">
          </el-input>
        </el-form-item>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取 消</el-button>
        <el-button type="primary"
                   @click="afterMarketBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Tabs from "@/components/Tabs"
// import Announcement from "./components/announcement"
// import Freight from "./components/freight"
import Aftermarket from "./components/aftermarket"
// import BillingSetup from "./components/billingSetup.vue"
// import RateInformation from "./components/rateInformation.vue"
import { getProvince, getCity, getArea, postShopExpressFees, putShopExpressFee, getAreaTree, shopCustomerServiceAddresses, putCustomerServiceAddresses } from '@/api/shopmange/index'
export default {
  components: { Tabs, Aftermarket },
  data () {
    return {
      dialogAddress: false,
      formLabelAlign: {},
      dialogVisible: false,
      activeVal: '3',
      value1: "",
      searchFrom: {},
      value: '',
      tabsList: [ // tabs 传的值
        // {
        //   name: '1',
        //   label: '店铺公告',
        //   value: 0,
        // },
        // {
        //   name: '2',
        //   label: '运费设置',
        //   value: 0,
        // },
        {
          name: '3',
          label: '售后地址',
          value: 0,
        },
        // {
        //   name: '4',
        //   label: '开票设置',
        //   value: 0,
        // },
        // {
        //   name: '5',
        //   label: '费率信息',
        //   value: 0,
        // },
      ],
      options: [{
        value: '选项1',
        label: '黄金糕'
      },],
      value: '',


      ruleForm: {},
      rules: {
        areaList: [
          { required: true, message: '请选择区域', trigger: 'blur' }
        ],
        feeType: [
          { required: true, message: '请选择是否包邮', trigger: 'change' }
        ],
        feeName: [
          { required: true, message: '请填写运费名称', trigger: 'blur' }
        ],
        freeShippingOrderAmount: [
          { required: true, message: '请填写金额', trigger: 'blur' }
        ],
        carriageAmount: [
          { required: true, message: '请填写金额', trigger: 'blur' }
        ],
        minOrderAmount: [
          { required: true, message: '请填写金额', trigger: 'blur' }
        ],

      },
      provinceData: [],
      cityData: [],
      areaData: [],
      propsData: {},
      areaTreeData: [],

      // 运费设置弹窗类型typeFreight  详情 detail  编辑 editor  新增 add
      typeFreight: 'add',
      //售后设置弹窗类型typeAfterMarket  详情 detail  编辑 editor  新增 add
      typeAfterMarket: 'add'

    }
  },
  mounted () {
    this.getArea()
    this.getProvinceData()
  },
  methods: {
    //运费设置中获取详情展示的弹窗
    getFreightDetail (res) {
      this.typeFreight = res.type
      this.ruleForm = res
      this.dialogVisible = true
      this.$nextTick(() => {
        let newArray = []
        res.areaList.forEach(re => {
          newArray.push({ id: re.city, label: re.cityName })
        })
        this.$refs.tree.setCheckedNodes(newArray);
      })
    },
    //售后设置中获取详情展示的弹窗
    getafterMarketDetail (res) {
      this.typeAfterMarket = res.type
      res.province = { id: Number(res.receiverProvince), rname: res.receiverProvinceName }
      this.getCityBtn(res.province)
      res.city = { id: Number(res.receiverCity), rname: res.receiverCityName }
      this.getAreaBtn(res.city)
      res.area = { id: Number(res.receiverArea), rname: res.receiverAreaName }
      this.dialogAddress = true
      this.$nextTick(() => {
        let newArray = []
        res.areaList.forEach(re => {
          newArray.push({ id: re.city, label: re.cityName })
        })
        this.ruleForm = res
        this.$refs.tree.setCheckedNodes(newArray);
      })
    },
    //获取整个省市区的树
    getArea () {
      getAreaTree({ level: 2 }).then(res => {
        if (res.code == 0) {
          this.areaTreeData = res.data
        }
      })
    },
    //切换tabs按钮
    handleChange (val) {
      this.activeVal = val
    },


    onSubmit () {
      this.typeAfterMarket = 'add'
      this.dialogAddress = true
      this.getProvinceData()
    },
    //新增运费按钮事件
    handleFreight () {
      this.typeFreight = "add"
      this.dialogVisible = true;
      this.getProvinceData()
    },
    getProvinceData () {
      getProvince().then(res => {
        if (res.code == 0) {
          this.provinceData = res.data
        } else {
          this.$message({ type: 'warning', message: res.msg })
        }
      })
    },
    handleClose () {
      this.dialogVisible = false;
      this.dialogAddress = false;
      this.resetForm('ruleForm')
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.dialogAddress = false
      this.dialogVisible = false
      this.$refs[formName].resetFields();
      this.$data[formName] = JSON.parse(JSON.stringify(this.$options.data()[formName]))
      this.$refs.tree.setCheckedNodes([]);
    },
    searchHandle () {
      this.searchFrom.beginDate = this.searchFrom.createTime?.[0] ?? ''
      this.searchFrom.endDate = this.searchFrom.createTime?.[1] ?? ''
      delete this.searchFrom.createTime
      this.$refs['Freight'].getList(this.searchFrom)
    },
    //省份的选择按钮
    selectedHandle (val) {
      this.$set(this.ruleForm, 'city', {})
      this.getCityBtn(val)
    },
    getCityBtn (val) {
      getCity({ id: val.id }).then(res => {
        if (res.code == 0) {
          this.cityData = res.data
        } else {
          this.$message({ type: 'warning', message: res.msg })
        }
      })
    },
    //城市的选择按钮
    selectedHandleCity (val) {
      this.$set(this.ruleForm, 'city', val)
      this.getAreaBtn(val)
    },
    getAreaBtn (val) {
      getArea({ id: val.id }).then(res => {
        if (res.code == 0) {
          this.areaData = res.data
        } else {
          this.$message({ type: 'warning', message: res.msg })
        }
      })
    },
    //地区的选择按钮
    selectedHandleArea (val) {
      this.$set(this.ruleForm, 'area', val)
    },
    handleArea (val) {
      console.log(this.ruleForm);

    },
    postShopExpressFees () {
      let checkArray = this.$refs.tree.getCheckedNodes()
      console.log(checkArray)
      let areaList = []
      checkArray.forEach(element => {
        if (element.parentId == -1) {
          areaList.push({
            "province": element.id,
            "provinceName": element.rname,
            "city": '',
            "cityName": '',
          })
        } else {
          areaList.push({
            "province": '',
            "provinceName": '',
            "city": element.id,
            "cityName": element.rname,
          })
        }
      });
      this.ruleForm.areaList = areaList
      if (this.ruleForm.id) {
        putShopExpressFee(this.ruleForm).then(res => {
          this.dialogVisible = false
          this.$refs['Freight'].getList(this.searchFrom)
          this.resetForm('ruleForm')
        })
      } else {
        postShopExpressFees(this.ruleForm).then(res => {
          this.dialogVisible = false
          this.$refs['Freight'].getList(this.searchFrom)
          this.resetForm('ruleForm')
        })
      }

    },
    afterMarketBtn () {
      let checkArray = this.$refs.tree.getCheckedNodes()
      let areaList = []
      checkArray.forEach(element => {
        if (element.parentId == -1) {
          areaList.push({
            "province": element.id,
            "provinceName": element.rname,
            "city": '',
            "cityName": '',
          })
        } else {
          areaList.push({
            "province": '',
            "provinceName": '',
            "city": element.id,
            "cityName": element.rname,
          })
        }
      });
      this.ruleForm.type = '2'
      this.ruleForm.areaList = areaList
      this.ruleForm.receiverProvince = this.ruleForm.province.id
      this.ruleForm.receiverProvinceName = this.ruleForm.province.rname
      this.ruleForm.receiverCity = this.ruleForm.city.id
      this.ruleForm.receiverCityName = this.ruleForm.city.rname
      this.ruleForm.receiverArea = this.ruleForm.area.id
      this.ruleForm.receiverAreaName = this.ruleForm.area.rname
      delete this.ruleForm.province
      delete this.ruleForm.city
      delete this.ruleForm.area
      if (this.ruleForm.id) {
        putCustomerServiceAddresses(this.ruleForm).then(res => {
          this.$message({ type: 'success', message: '修改成功！' })
          this.dialogAddress = false
          this.resetForm('ruleForm')
          this.$refs.Aftermarket.getList()
        })

      } else {
        shopCustomerServiceAddresses(this.ruleForm).then(res => {
          this.$message({ type: 'success', message: '保存成功！' })
          this.dialogAddress = false
          this.resetForm('ruleForm')
          this.$refs.Aftermarket.getList()
        })

      }

    },
  }
}
</script>
<style lang="scss" scoped>
.content {
  background-color: #fff;
  height: calc(100vh - 140px);
  ::v-deep .el-tabs__item {
    padding: 0 12px;
    margin-right: 0px;
  }
}
</style>