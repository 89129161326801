<template>
    <el-table :data="tableData" style="width: 100%" :height="tableHeight" v-if="tableHeight" ref="tableRef">
        <el-table-column prop="submitTime" label="申请时间"  >
        </el-table-column>
        <el-table-column prop="id" label="申请ID"  >
        </el-table-column>
        <el-table-column prop="deptName" label="运营项目"  >
        </el-table-column>
        <el-table-column prop="operateName" label="运营姓名"  >
        </el-table-column>
        <el-table-column prop="operateContact" label="运营联系方式"  >
        </el-table-column>
        <el-table-column label="申请金额（元）" >
            <div slot-scope="scope" class="flexRight">
                <div>{{ scope.row.amount.toFixed(2) }}</div>
            </div>
        </el-table-column>
        <el-table-column label="提现状态" >
            <div slot-scope="scope" class="flexRight">
                <div>{{withdrawStatus[scope.row.expenditureStatus]  }}</div>
            </div>
            
        </el-table-column>
        <el-table-column  label="操作" >
            <template slot-scope="scope">
                <el-link :underline="false" @click="confirmAction(scope.row)">同意提现</el-link>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
// 部门申请列表页面
import { pageResultSubmit } from "@/api/fund/fundApi";
export default {
    props: {
        tableHeight: {
            type: Number,
            default: () => 0
        }
    },
    watch:{
        // tableData(newVal, oldVal){
        //     if (newVal != oldVal) {
        //         this.$nextTick(() => {
        //         this.$refs.tableRef.bodyWrapper.scrollTop =0;
        //         this.$refs.tableRef.doLayout()
        //         })
        //     }
        // }
    },
    data() {
        return {
            tableData:[],
            withdrawStatus:{
                INIT:'',
                SUBMIT:'提现申请',
                PROCESS:'提现处理中',
                SUCCESS:'提现完成'
            }
        }
    },
    mounted() {

    },
    methods: {
        confirmAction(e){
            this.$confirm('同意提现申请?', '提示', {
                confirmButtonText: '同意',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit('agreeWithdraw',e )
            })
        },
        async getList(params){
            const resp = await pageResultSubmit(params)
            if(resp && resp.records){
                this.tableData = resp.records
            }else{
                this.tableData = []
            }
            this.$emit('pageInfoDidChange',{ total : resp.total, current:resp.current })
        }
    }
    
}
</script>
<style lang="scss" scoped></style>