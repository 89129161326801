<template>
  <div class="comContent">
    <searchForm v-model="searchData"
                :expanded="expanded"
                @handleExpand="handleExpand"
                @getList="getList"
                :labelList="searchLabelList"
                :searchData="searchData"
                @reset="resetForm"
                @export="exportShopList"
                :showExport="true"
                :isShowAddress="true"></searchForm>

    <div class="statics">
      <div :class="{ item: true, red: slectedIndex === 0 }"
           @click="handleClickBlock(0)">
        <div class="tip">资质证件更新</div>
        <div class="value">
          {{ statisticsData.v1 }}
        </div>
      </div>
      <div :class="{ item: true, red: slectedIndex === 1 }"
           @click="handleClickBlock(1)">
        <div class="tip">资质证件过期</div>
        <div class="value">
          {{ statisticsData.v2 }}
        </div>
      </div>
      <div :class="{ item: true, red: slectedIndex === 2 }"
           @click="handleClickBlock(2)">
        <div class="tip">企业名称更新</div>
        <div class="value">{{ statisticsData.v3 }}</div>
      </div>
      <div :class="{ item: true, red: slectedIndex === 3 }"
           @click="handleClickBlock(3)">
        <div class="tip">地址更新</div>
        <div class="value">{{ statisticsData.v4 }}</div>
      </div>
    </div>

    <myTable :index="index"
             :selection="false"
             :page="page"
             :showpage="true"
             :operation="false"
             v-loading="loading"
             :table-data="tableData"
             :table-option.sync="tableOption"
             @page-change="getList"
             :expand="false">
      <template slot="shopStatus"
                slot-scope="scope">
        <span v-if="scope.row.shopStatus == 1"> 未开户 </span>
        <span v-if="scope.row.shopStatus == 2"> 已开户 </span>
      </template>
      <template slot="operation"
                slot-scope="scope">
        <div style="margin: 0 0px">
          <el-link type="primary"
                   :underline="false"
                   @click="checkingQualification(scope.row, 1)"
                   style="margin-right: 10px">
            查看资质
          </el-link>
        </div>
        <div style="margin: 0 0px">
          <el-link type="primary"
                   :underline="false"
                   @click="showErpDailog(scope.row, 1)"
                   style="margin-right: 10px">
            编辑ERP编码
          </el-link>
        </div>
        <div style="margin: 0 0px">
          <el-link type="primary"
                   :underline="false"
                   @click="showCustomer(scope.row, 1)"
                   style="margin-right: 10px">
            信息变更记录
          </el-link>
        </div>
      </template>
    </myTable>

    <el-dialog title="编辑ERP编码"
               :visible.sync="visibleErp"
               width="30%"
               :before-close="handleCloseErp">
      <el-form :model="dialogFormErp"
               status-icon
               ref="dialogFormErp"
               label-width="100px"
               class="demo-ruleForm">
        <div class="dialog-body">
          <el-form-item label="客户名称:">
            {{ dialogFormErp.shopName }}</el-form-item>
          <el-form-item label="客户ERP编码:">
            <el-input v-model="dialogFormErp.number"
                      size="small"
                      clearable
                      style="width: 200px"></el-input>
          </el-form-item>
        </div>
        <div class="flex-end">
          <el-button size="small"
                     type="primary"
                     style="margin-left: 8px"
                     @click="handleCloseErp()">返回</el-button>
          <el-button size="small"
                     type="primary"
                     style="margin-left: 8px"
                     @click="submitErp(1)">确认</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog title="客户信息变更日志"
               :visible.sync="visibleCustomer"
               width="50%"
               :before-close="handleCloseCustomer"
               class="dialog-customer">
      <el-form :model="dialogFormCustomer"
               status-icon
               ref="dialogFormCustomer"
               label-width="100px"
               class="demo-ruleForm">
        <div class="dialog-center-customer">
          <el-table :data="tableDataCustomer"
                    border
                    style="width: 100%">
            <el-table-column prop="changeLog"
                             label="变更内容">
              <template slot-scope="scope">
                <div class="content-change">
                  <div class="list">【经营范围】：</div>
                  <div class="list before">
                    {{ scope.row.businessScopeBeforeModification || "" }}
                  </div>
                  <div class="list after">
                    {{ scope.row.businessScopeAfterModification || "" }}
                  </div>
                </div>
                <div class="content-change">
                  <div class="list">【收货地址变更】：</div>
                  <div class="list before">
                    {{ scope.row.basicInformationBeforeModification || "" }}
                  </div>
                  <div class="list after">
                    {{ scope.row.basicInformationAfterModification || "" }}
                  </div>
                </div>
                <div class="content-change">
                  <div class="list">【药品经营许可证】：</div>
                  <div class="list">
                    {{ scope.row.qualificationInformation || "" }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="变更时间"
                             prop="changeTime">
              <template slot-scope="scope">{{ scope.row.changeTime }}</template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import searchForm from "../component/searchForm.vue";
import myTable from "@/components/myTable";
import {
  getErpShopPage,
  getListByType,
  exportShopList,
  changeShopInfo,
  updateErpShop,
  getErpShopSum,
} from "@/api/businessManage";

export default {
  components: { searchForm, myTable },
  props: {},
  data () {
    return {
      expanded: false,
      slectedIndex: -1,

      //1资质证件更新 2资质证件过期 3企业名称更新 4地址更新
      statisticsData: {
        v1: 0, //
        v2: 0, //
        v3: 0, //
        v4: 0, //
      },
      erpShopSum: null,

      changeLog: "", // 变更内容
      changeTime: "", // 变更时间
      visibleCustomer: false,
      visibleErp: false,
      loading: false,
      operation: false,
      selection: true,
      index: false,
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      dialogFormErp: { number: "", shopId: null },
      dialogFormCustomer: { id: null },
      tableDataCustomer: [],
      tableData: [],

      tableOption: [
        { label: "客户名称", prop: "shopName", width: 260 },
        { label: "客户地址", prop: "address", width: 260 },
        { label: "客户类型", prop: "typeName", width: 100 },
        { label: "省", prop: "provinceName" },
        { label: "市", prop: "cityName" },
        { label: "区", prop: "areaName", width: 120 },
        { label: "联系人", prop: "concatName", width: 100 },
        { label: "联系电话", prop: "concatPhone", width: 120 },
        {
          label: "开户状态",
          prop: "shopStatus",
          width: 100,
          slot: true,
        },
        { label: "客户ID", prop: "shopCode", width: 200 },
        { label: "ERP编码", prop: "number", width: 200 },
        { label: "首次下单时间", prop: "firstDate", width: 160 },
        { label: "最后下单时间", prop: "lastDate", width: 160 },
        {
          label: "操作",
          prop: "operation",
          slot: true,
          width: 120,
          // fixed: "right",
        },
      ],
      searchData: {
        keyWords: "",
        shopName: "",
        shopStatus: "",
        typeCode: "",
      },
      searchLabelList: [
        {
          name: "客户Id/ERP编码/客户名称",
          fieldName: "keyWords",
          type: "input",
          width: "220",
          disabled: false,
        },
        // {
        //   name: "省",
        //   fieldName: "province",
        //   type: "province",
        // },
        // {
        //   name: "市",
        //   fieldName: "city",
        //   type: "city",
        // },
        // {
        //   name: "区",
        //   fieldName: "area",
        //   type: "area",
        // },
        // {
        //   name: "客户类型",
        //   fieldName: "typeCode",
        //   width: 150,
        //   type: "select",
        //   disabled: false,
        //   select: [],
        // },
        // {
        //   name: "开户状态",
        //   width: 150,
        //   fieldName: "shopStatus",
        //   type: "select",
        //   disabled: false,
        //   select: [
        //     {
        //       name: "未开户",
        //       id: 1,
        //     },
        //     {
        //       name: "已开户",
        //       id: 2,
        //     },
        //   ],
        // },
      ],
    };
  },
  filters: {},
  created () {
    const { tab } = this.$route.query
    console.log(tab, 'tab')
    if (tab) {
      if (tab == '未开户') {
        this.expanded = true
        this.searchLabelList = [
          {
            name: "客户Id",
            fieldName: "shopCode",
            type: "input",
            disabled: false,
          },
          {
            name: "ERP编码",
            fieldName: "number",
            type: "input",
          },
          {
            name: "客户名称",
            fieldName: "shopName",
            type: "input",
          },
          {
            name: "省",
            fieldName: "province",
            type: "province",
          },
          {
            name: "市",
            fieldName: "city",
            type: "city",
          },
          {
            name: "区",
            fieldName: "area",
            type: "area",
          },
          {
            name: "客户类型",
            fieldName: "typeCode",
            width: 150,
            type: "select",
            disabled: false,
            select: [],
          },
          {
            name: "开户状态",
            width: 150,
            fieldName: "shopStatus",
            type: "select",
            disabled: false,
            select: [
              {
                name: "未开户",
                id: 1,
              },
              {
                name: "已开户",
                id: 2,
              },
            ],
          },
        ];
        this.$nextTick(() => {
          this.searchData.shopStatus = 1
        })

      } else {
        this.slectedIndex = Number(tab)
      }
    }
  },
  async mounted () {
    await this.getListByType();
    await this.getErpShopSum()
    // await this.getList(1);

  },
  methods: {
    async getErpShopSum () {
      getErpShopSum().then((data) => {
        this.erpShopSum = data;
        if (Array.isArray(data) && data.length > 0) {
          data.forEach((item) => {
            if (item.type == 1) {
              this.statisticsData.v1 = item.total;
            } else if (item.type == 2) {
              this.statisticsData.v2 = item.total;
            } else if (item.type == 3) {
              this.statisticsData.v3 = item.total;
            } else if (item.type == 4) {
              this.statisticsData.v4 = item.total;
            }
          });
        }
        this.getList(1);
      });
    },
    handleClickBlock (index) {
      if (index === this.slectedIndex) {
        this.slectedIndex = -1;
      } else {
        this.slectedIndex = index;
      }

      this.getList(1);
    },
    handleExpand (value) {
      this.expanded = value
      if (value) {
        this.searchLabelList = [
          {
            name: "客户Id",
            fieldName: "shopCode",
            type: "input",
            disabled: false,
          },
          {
            name: "ERP编码",
            fieldName: "number",
            type: "input",
          },
          {
            name: "客户名称",
            fieldName: "shopName",
            type: "input",
          },
          {
            name: "省",
            fieldName: "province",
            type: "province",
          },
          {
            name: "市",
            fieldName: "city",
            type: "city",
          },
          {
            name: "区",
            fieldName: "area",
            type: "area",
          },
          {
            name: "客户类型",
            fieldName: "typeCode",
            width: 150,
            type: "select",
            disabled: false,
            select: [],
          },
          {
            name: "开户状态",
            width: 150,
            fieldName: "shopStatus",
            type: "select",
            disabled: false,
            select: [
              {
                name: "未开户",
                id: 1,
              },
              {
                name: "已开户",
                id: 2,
              },
            ],
          },
        ];
      } else {
        this.searchLabelList = [
          {
            name: "客户Id/ERP编码/客户名称",
            fieldName: "keyWords",
            type: "input",
            width: "220",
            disabled: false,
          },
          // {
          //   name: "省",
          //   fieldName: "province",
          //   type: "province",
          // },
          // {
          //   name: "市",
          //   fieldName: "city",
          //   type: "city",
          // },
          // {
          //   name: "区",
          //   fieldName: "area",
          //   type: "area",
          // },
          // {
          //   name: "客户类型",
          //   fieldName: "typeCode",
          //   width: 150,
          //   type: "select",
          //   disabled: false,
          //   select: [],
          // },
          // {
          //   name: "开户状态",
          //   width: 150,
          //   fieldName: "shopStatus",
          //   type: "select",
          //   disabled: false,
          //   select: [
          //     {
          //       name: "未开户",
          //       id: 1,
          //     },
          //     {
          //       name: "已开户",
          //       id: 2,
          //     },
          //   ],
          // },
        ];
      }
    },
    //changeShopInfo 获取客户信息变更日志
    changeShopInfo (row) {
      changeShopInfo({
        // id: row.id,
        sellerShopId: row.drugId,
      }).then((res) => {
        console.log(res, "res");
        this.tableDataCustomer = [];
        if (res.data) {
          this.tableDataCustomer.push(res.data);
        }
        // this.changeLog = res.data.changeLog;
        // this.changeTime = res.data.changeTime;
      });
    },

    // 获取客户类型列表
    async getListByType () {
      getListByType({
        type: 0, //0 客户类型
      }).then((res) => {
        console.log(res);
        let selectList = [];
        for (let i = 0; i < res.data.length; i++) {
          selectList.push({
            id: res.data[i].typeCode,
            name: res.data[i].typeName,
          });
        }
        // console.log(selectList, "selectList---selectList");
        for (let i = 0; i < this.searchLabelList.length; i++) {
          if (this.searchLabelList[i].fieldName == "typeCode") {
            this.searchLabelList[i].select = selectList;
          }
        }
      });
    },

    async getList (current) {
      let parmas = { ...this.searchData };
      parmas.provinceName = this.searchData.province;
      parmas.cityName = this.searchData.city;
      parmas.areaName = this.searchData.area;
      delete parmas.province;
      delete parmas.city;
      delete parmas.area;

      parmas.size = this.page.size;
      current == 1
        ? (parmas.current = 1)
        : (parmas.current = this.page.current);

      if (
        this.slectedIndex != -1 &&
        this.erpShopSum &&
        Array.isArray(this.erpShopSum)
      ) {
        //0开始
        let type = this.slectedIndex + 1;
        const findedItem = this.erpShopSum.find((item) => item.type == type);
        if (findedItem) {
          if (type == 1) {
            parmas.certifyUpdated = findedItem.shopIds;
          } else if (type == 2) {
            parmas.certifyExpired = findedItem.shopIds;
          } else if (type == 3) {
            parmas.nameUpdated = findedItem.shopIds;
          } else if (type == 4) {
            parmas.addressUpdated = findedItem.shopIds;
          }
        }
      }

      getErpShopPage(parmas).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        } else {
          this.tableData = [];
          this.page.total = 0;
        }
        // console.log(res, "---res");
      });
    },

    exportShopList (current) {
      if (this.searchData.firstDate) {
        this.searchData.startTime = this.searchData.date[0];
        this.searchData.endTime = this.searchData.date[1];
      }
      console.log(this.searchData);
      let parmas = { ...this.searchData };
      parmas.size = this.page.size;
      current == 1
        ? (parmas.current = 1)
        : (parmas.current = this.page.current);
      exportShopList(parmas).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        } else {
          this.tableData = [];
          this.page.total = 0;
        }
      });
    },

    handleCloseErp () {
      this.visibleErp = false;
      this.dialogFormErp.shopId = null;
    },
    handleCloseCustomer () {
      this.visibleCustomer = false;
      this.dialogFormCustomer.id = null;
    },
    showErpDailog (row) {
      this.dialogFormErp.number = row.number || "";
      this.dialogFormErp.shopId = row.shopId;
      this.dialogFormErp.id = row.id;
      this.dialogFormErp.drugstoreBranchId = row.drugId;
      this.dialogFormErp.shopName = row.shopName;
      this.visibleErp = true;
    },
    showCustomer (row) {
      this.dialogFormCustomer.id = row.id;
      this.dialogFormCustomer.shopName = row.shopName;
      this.visibleCustomer = true;
      this.changeShopInfo(row);
    },
    resetForm () {
      this.page.current = 1;
      for (const key in this.searchData) {
        this.searchData[key] = "";
      }
      this.getList(1);
    },
    // 查看详情
    checkDetails (row) {
      // http://localhost:8081/#/myQualification/myQualification-qualificationWithUser?shopId=1681475774828863489
    },
    // 查看资质
    checkingQualification (row) {
      this.$router.push(
        `/myQualification/myQualification-qualificationWithUser?shopId=` +
        row.drugId
      );
    },
    // 提交erp
    submitErp () {
      if (!this.dialogFormErp.number) {
        this.$message.error("请输入erp编码");
        return;
      }
      const { number, shopId, id, drugstoreBranchId } = this.dialogFormErp;
      updateErpShop({ number, shopId, id, drugstoreBranchId }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.visibleErp = false;
          this.$message.success("提交成功");
          this.getList(1);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.el-table {
  height: calc(100% - 138px) !important;
}
::v-deep.zt__table {
  height: calc(100% - 118px) !important;
}

::v-deep.el-table .el-table__body-wrapper {
  height: calc(100% - 78px) !important;
  overflow-y: scroll !important;
}
.flex-end {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.content-change {
  .list {
    font-size: 14px;
  }
}
.dialog-center-customer {
  height: 360px;
}
.dialog-customer {
}
::v-deep.dialog-customer .el-table {
  height: calc(100% - 30px) !important;
}
::v-deep.dialog-customer .zt__table {
  height: calc(100% - 30px) !important;
}

::v-deep.dialog-customer .el-table .el-table__body-wrapper {
  height: calc(100% - 35px) !important;
  overflow-y: scroll !important;
}

.statics {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  .item {
    cursor: pointer;
    height: 80px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 0 16px;
    box-sizing: border-box;
    min-width: 120px;

    &:not(:first-child) {
      margin-left: 8px;
    }

    .tip {
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      color: #000000;
    }

    .value {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      color: #000000;
      margin-top: 8px;

      .small {
        font-size: 16px;
        font-weight: 700;
        text-align: left;
      }
    }

    &.red {
      background: #f5222d;
      border: 1px solid #f5222d;

      .tip {
        color: #ffffff;
      }
      .value {
        color: #ffffff;
      }
    }
  }
}
</style>
