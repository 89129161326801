var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c(
      "div",
      {
        staticClass: "header",
        staticStyle: {
          "font-weight": "600",
          "border-bottom": "1px solid #ededed",
          "padding-bottom": "12px",
        },
      },
      [_vm._v(" 活动名称 ")]
    ),
    _c(
      "div",
      { staticClass: "center" },
      [
        _c("p", { staticStyle: { "font-weight": "400" } }, [
          _vm._v(
            "活动时间：" +
              _vm._s(_vm.info.startTime ? _vm.info.startTime : "") +
              "~" +
              _vm._s(_vm.info.endTime ? _vm.info.endTime : "")
          ),
        ]),
        _vm.info.goodsFrom == 1
          ? _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.active(_vm.info)
                  },
                },
              },
              [_vm._v("立即报名")]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("wangEnduit", {
              ref: "wangEnduit",
              attrs: { value: _vm.info.description, isDisabled: true },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }