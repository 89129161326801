var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "资质需求",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("企业资质")]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(_vm._s(_vm.companyQualification)),
          ]),
          _c("div", { staticClass: "title" }, [_vm._v("商品药检报告")]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(_vm._s(_vm.goodsReport[0])),
          ]),
          _c("div", { staticClass: "title" }, [_vm._v("商品首营资料")]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(_vm._s(_vm.goodsReport[1])),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }