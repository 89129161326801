<template>
  <div class="classification">
    <div class="card">
      <div class="staus_bar fl_bet border">
        <div class="detail_left">
          <div class="fl_cen">
            <div class="status" v-if="orderList && orderList.status != undefined && orderList.status != null">
              <el-image class="icon_status" :src="getStatus(1, orderList.status)" />
              <div class="name" :class="getStatus(3, orderList.status)">
                {{ getStatus(2, orderList.status) }}
              </div>
            </div>
            <div class="items">
              <div class="title">订单编号</div>
              <div style="height: 22px">{{ orderList.orderCode || '' }}</div>
            </div>
            <div class="items">
              <div class="title">下单时间</div>
              <div style="height: 22px">{{ orderList.orderTime || '' }}</div>
            </div>
            <div v-if="orderList.status == 1 && orderList.payTime" class="items">
              <div class="title">付款时间</div>
              <div style="height: 22px; color: #e4393c">
                {{ orderList.payTime || ' ' }}
              </div>
            </div>
          </div>
        </div>
        <div class="detail_right">
          <!-- <el-button v-if="
              Number(orderList.status) == 1 
            " type="danger emphasis_btn" @click.stop="qrCodeCreate(orderList)">立即付款</el-button> -->
          <!-- <el-button v-if="
              Number(orderList.status) == 3 
            "
                     type="danger emphasis_btn">确认收货</el-button> -->
          <!-- <el-button v-if="Number(orderList.status) == 1" :disabled="deliverControl == '1'" class="cancel_btn"
            :loading="isLoading" @click.stop="cancelClick(id)">取消订单</el-button> -->
        </div>
      </div>
      <div class="staus_plan fl_left">
        <div :class="indexStep >= 1 ? 'active' : ''" class="step">
          <div class="fl_center">
            <div class="status">
              <el-image v-if="indexStep > 1" class="icon_svg" :src="icon" />
              <div v-else class="idx">1</div>
            </div>
            <div class="name" :class="indexStep > 1 ? 'sueccss' : ''">
              提交订单
            </div>
          </div>
          <div class="time">{{ orderList.orderTime || '' }}</div>
        </div>
        <div :class="indexStep >= 1 ? 'active' : ''" class="line" />
        <div :class="indexStep >= 2 ? 'active' : ''" class="step">
          <div class="fl_center">
            <div class="status fl_center">
              <el-image v-if="indexStep > 2" class="icon_svg" :src="icon" />
              <div v-else class="idx">2</div>
            </div>
            <div class="name" :class="indexStep > 2 ? 'sueccss' : ''">
              付款成功
            </div>
          </div>
          <div class="time">{{ orderList.payTime || '' }}</div>
        </div>
        <div :class="indexStep >= 2 ? 'active' : ''" class="line" />
        <div :class="indexStep >= 3 ? 'active' : ''" class="step">
          <div class="fl_center">
            <div class="status">
              <el-image v-if="indexStep > 3" class="icon_svg" :src="icon" />
              <div v-else class="idx">3</div>
            </div>
            <div class="name" :class="indexStep > 3 ? 'sueccss' : ''">
              商品出库
            </div>
          </div>
          <div class="time">{{ orderList.deliveryTime || '' }}</div>
        </div>
        <div :class="indexStep >= 3 ? 'active' : ''" class="line" />

        <div :class="indexStep >= 4 ? 'active' : ''" class="step">
          <div class="fl_center">
            <div class="status">
              <el-image v-if="indexStep > 4" class="icon_svg" :src="icon" />
              <div v-else class="idx">4</div>
            </div>
            <div class="name" :class="indexStep > 4 ? 'sueccss' : ''">
              配送完成
            </div>
          </div>
          <div class="time">{{ orderList.signDate || '' }}</div>
        </div>
        <div :class="indexStep >= 4 ? 'active' : ''" class="line" />
        <div :class="indexStep >= 5 ? 'active' : ''" class="step">
          <div class="fl_center">
            <div class="status">
              <el-image v-if="indexStep == 5" class="icon_svg" :src="icon" />
              <div v-else class="idx">5</div>
            </div>
            <div class="name" :class="indexStep > 5 ? 'sueccss' : ''">
              交易完成
            </div>
          </div>
          <div class="time">{{ orderList.finishDate || '' }}</div>
        </div>
      </div>
    </div>

    <div class="info card">
      <el-tabs v-model="orderLogistics" class="headerTabs" @tab-click="handleClick" v-if="orderLogisticsList">
        <el-tab-pane :label="item.label" :name="item.expressDeliveryNumber" v-for="(item, index) in orderLogisticsList"
          :key="index">
          <span slot="label" style="font-size: 14px;">
            <div v-if="item.expressTag == 1">
              <div>自主配送：{{ item.deliveryDriver || '--' }}</div>
              <div>联系电话：{{ item.driverPhone }}</div>
            </div>
            <div v-else>
              <div style="line-height: 20px;">
                {{ item.kuaidiName }}
              </div>
              <div style="line-height: 20px;">
                {{ item.expressDeliveryNumber }}
              </div>
            </div>
          </span>
        </el-tab-pane>
      </el-tabs>
      <el-collapse v-model="activeName">
        <el-collapse-item name="1">
          <template slot="title">
            <div style="line-height: 20px; height: 80px">
              <div style="color: #f56c6c; font-weight: 600">
                {{ expressData.step || '暂无物流数据' }}
              </div>
              <div>
                {{ expressData.description || '' }}
              </div>
            </div>
          </template>
          <div style="padding-top: 10px">
            <el-timeline v-if="expressData.child && expressData.child.length > 0">
              <el-timeline-item v-for="(activity, index) in expressData.child" :key="index" color="#F5222D">
                <div style="font-weight: 600">{{ activity.step || '' }}</div>
                <div>{{ activity.description || '' }}</div>
                <el-image v-if="activity.image" style="display:block; width: 100px; height: 100px"
                  :src="activity.image || ''" :preview-src-list="[activity.image || '']">
                </el-image>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="info card fl_cen">
      <div class="info_list">
        <div class="title">收件人信息</div>
        <div class="lists">
          <div class="item">
            <span>收货人：</span>{{ orderList.contactsName || '--' }}
          </div>
          <div class="item">
            <span>地址：</span>{{ orderList.province || ''
            }}{{ orderList.city || '' }}{{ orderList.district || ''
}}{{ orderList.address || '' }}
          </div>
          <div class="item">
            <span>手机号：</span>{{ orderList.contactsPhone || '--' }}
          </div>
          <div class="item">
            <span>配送方式：</span>{{ getTransWayText(orderList.dispatchWay) }}
          </div>
        </div>
      </div>

      <div class="info_list">
        <div class="title">客户信息</div>
        <div class="lists">
          <div class="item">
            <span>客户名称：</span>{{ orderList.payShopName || '--' }}
          </div>
        </div>
      </div>

      <div class="info_list" style="padding-right: 40px;">
        <div v-if="orderList.status == 2 || orderList.status == 3 || orderList.status == 4 || orderList.status == 8"
          class="lists" style="margin-top:0px">
          <!-- <div class="title">配送信息</div> -->
          <Tabs :tabsList="tabsList" class="title" :activeName.sync="tabsActiveName" @tab-click="handleChange" />
          <div v-if="tabsActiveName == '1'" class="list_input fl_cen">
            <div>
              <el-autocomplete v-model="form.companyName" :disabled="deliverControl == '1'" class="formInput"
                placeholder="填写快递公司" :fetch-suggestions="querySearchAsync" value-key="name" @select="handleSelect" />
              <el-input v-model="form.nums" :disabled="deliverControl == '1'"
                onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')" class="formInput" placeholder="填写快递单号" />
            </div>
            <!-- <el-button class="formbutton" :loading="isBtnLoading" @click.stop="addKuaidi">{{ form.nums ? '修改' : '提交'}}</el-button>  -->
            <el-button class="formbutton" :disabled="deliverControl == '1'" :loading="isBtnLoading"
              @click.stop="addKuaidi">{{ '提交' }}</el-button>
            <el-button class="formbutton" :loading="isBtnLoading" @click.stop="updateLogistics"
              :disabled="disabledUpdate">{{ '更新' }}</el-button>
          </div>

          <div v-else class="list_input fl_cen">
            <div>
              <el-input v-model="form.companyName" :disabled="deliverControl == '1'" class="formInput"
                placeholder="填写司机姓名" />
              <el-input v-model="form.nums" :disabled="deliverControl == '1'"
                onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')"
                maxlength="11" class="formInput" placeholder="填写司机联系方式" />
            </div>
            <el-button class="formbutton" :disabled="deliverControl == '1'" :loading="isBtnLoading"
              @click.stop="addKuaidi">{{ '提交' }}</el-button>
          </div>

        </div>
        <div v-else class="lists" style="margin-top:0px">
          <div v-if="this.tabsActiveName == '2'">
            <div class="title">配送信息</div>
            <div class="item">
              <span>司机姓名：</span>{{ orderList.deliveryDriver || '--' }}
            </div>
            <div class="item">
              <span>联系方式：</span>{{ orderList.driverPhone || '--' }}
            </div>
            <div class="item">
              <span>预计送达：</span>{{ orderList.finishDate || '--' }}
            </div>
          </div>
          <div v-else>
            <div class="title">配送信息</div>
            <div class="item">
              <span>快递公司：</span>{{ orderList.kuaidiName || '--' }}
            </div>
            <div class="item">
              <span>快递单号：</span>{{ orderList.number || '--' }}
            </div>
            <div class="item">
              <span>预计送达：</span>{{ orderList.finishDate || '--' }}
            </div>
          </div>
        </div>
      </div>
      <div class="info_list">
        <div class="title">付款信息</div>
        <div class="lists">
          <div class="item">
            <span>实付总额：</span>￥{{ orderList.totalPrice || '--' }}
          </div>
          <!-- 改为支付方式+支付金额 -->
          <!-- 支付方式：微信支付、支付宝支付、康贝支付、余额支付、线下对公打款 -->
          <!-- 1.微信支付/支付宝支付/康贝支付  -->
          <!-- 2 康贝支付-->
          <!--  余额支付 balanceAmount (组合支付，余额 + 其他)-->
          <div class="item"><span>支付方式：</span>
            <span v-if="orderList.balanceAmount && parseFloat(orderList.balanceAmount) > 0">
              余额支付 ￥ {{ parseFloat(orderList.balanceAmount).toFixed(2) || '--' }}
            </span>
            <span
              v-if="orderList.balanceAmount && parseFloat(orderList.balanceAmount) > 0 && (parseFloat(orderList.totalPrice) - parseFloat(orderList.balanceAmount) > 0)"> 
                {{ orderList.payTypeName  }}
              ￥ {{ (parseFloat(orderList.totalPrice) - parseFloat(orderList.balanceAmount)).toFixed(2) }}
            </span>
            <span v-if="parseFloat(orderList.balanceAmount) == 0"> 
                {{ orderList.payTypeName }}
              ￥ {{ parseFloat(orderList.totalPrice).toFixed(2) || '--' }}
            </span>
          </div>

          <div class="item">
            <span>付款时间：</span>{{ orderList.payTime || '' }}
          </div>
        </div>
      </div>
    </div>

    <div class="commodity card">
      <!-- <publicTable :table-data="list" :colum-obj="columObj" :page-obj="pageObj" /> -->
      <el-table :data="list" style="width: 100%">
        <el-table-column label="商品信息" min-width="250">
          <template slot-scope="scope">
            <div class="commodity_info">
              <div class="img">
                <el-image :src="scope.row.image || ''" :preview-src-list="[scope.row.image || '']" />
              </div>
              <div class="goodsInfo">
                <div class="name">{{ scope.row.name || '' }}</div>
                <div class="factory">{{ scope.row.factory || '' }}</div>
              </div>
            </div>
            <div class="commodity_info" v-for="(giftItem, giftIndex) in scope.row.giftGoodsList" :key="giftIndex">
              <div class="img">
                <el-image :src="giftItem.image || ''" :preview-src-list="[giftItem.image || '']" />
              </div>
              <div class="goodsInfo">
                <div class="name">{{ giftItem.name || '' }}</div>
                <div class="factory">{{ giftItem.factory || '' }}</div>
                <GiftTag />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="规格" width="120">
          <template slot-scope="scope">
            <div class="commodity_info">{{ scope.row.specification || '' }}</div>
            <div class="commodity_info" v-for="(giftItem, giftIndex) in scope.row.giftGoodsList" :key="giftIndex">
              <div class="specification">{{ giftItem.specification || '' }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="单价">
          <template slot-scope="scope">
            <div class="commodity_info">￥{{ scope.row.price || '' }}</div>
            <div class="commodity_info" v-for="(giftItem, giftIndex) in scope.row.giftGoodsList" :key="giftIndex">
              <div class="price">￥{{ giftItem.salePrice || '' }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="需求量">
          <template slot-scope="scope">
            <div class="commodity_info">{{ scope.row.qty || '0' }}</div>
            <div class="commodity_info" v-for="(giftItem, giftIndex) in scope.row.giftGoodsList" :key="giftIndex">
              <div class="qty">{{ giftItem.qty || '' }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="实发量" prop="shipmentsAmount" width="120">
          <template slot-scope="scope">
            <div class="commodity_info">{{ scope.row.shipmentsAmount || '0' }}</div>
            <div class="commodity_info" v-for="(giftItem, giftIndex) in scope.row.giftGoodsList" :key="giftIndex">
              <div class="qty">{{ giftItem.shipmentsAmount }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="批号" prop="batchNumber" min-width="100">
          <template slot-scope="scope">
            <div class="commodity_info">{{ scope.row.batchNumber || '' }}</div>
            <div class="commodity_info" v-for="(giftItem, giftIndex) in scope.row.giftGoodsList" :key="giftIndex">
              <div class="batchNumber">{{ giftItem.batchNumber || '' }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="溯源码" prop="traceableCode" min-width="100">
          <template slot-scope="scope">
            <div class="commodity_info">{{ scope.row.traceableCode || '' }}</div>
            <div class="commodity_info" v-for="(giftItem, giftIndex) in scope.row.giftGoodsList" :key="giftIndex">
              <div class="traceableCode">{{ giftItem.traceableCode || '' }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商户补贴" prop="traceableCode" min-width="100">
          <template slot-scope="scope">
            <span class="commodity_info"
              style="font-weight: 400;color: #F5222d;font-size: 16px;flex-direction: column;align-items: flex-start;justify-content: center;">
              -￥{{ scope.row.couponDiscount }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="平台补贴" prop="traceableCode" min-width="100">
          <template slot-scope="scope">
            <span class="commodity_info"
              style="font-weight: 400;color: #F5222d;font-size: 16px;flex-direction: column;align-items: flex-start;justify-content: center;">
              -￥{{ scope.row.platformDiscount }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="小计" width="120">
          <template slot-scope="scope">
            <span class="commodity_info"
              style="font-weight: 600;color: #F5222d;font-size: 16px;flex-direction: column;align-items: flex-start;justify-content: center;">
              ￥{{ scope.row.smallPrice }}
              <!-- <div style="font-weight: 400;font-size: 14px;">
                优惠券：-￥{{ scope.row.couponDiscount }}
              </div>
              <div style="font-weight: 400;font-size: 14px;">
                平台补贴：-￥{{ scope.row.platformDiscount }}
              </div> -->
            </span>
            <div class="commodity_info" v-for="(giftItem, giftIndex) in scope.row.giftGoodsList" :key="giftIndex">

            </div>
          </template>
        </el-table-column>

      </el-table>
      <div class="price_detail">
        <div class="items">
          <span class="left">商品件数：</span>
          <span class="right">{{ orderList.sumQty || 0 }}件</span>
        </div>
        <div class="items">
          <span class="left">商品总价：</span>
          <span class="right">¥{{ orderList.amount || 0 }}</span>
        </div>
        <div class="items">
          <span class="left">运费：</span>
          <span class="right">¥{{ orderList.freightPrice || 0 }}</span>
        </div>
        <div class="items">
          <span class="left">商户补贴：</span>
          <span class="right">-¥{{ orderList.discountAmount || 0.00 }}</span>
        </div>
        <div class="items">
          <span class="left">平台补贴：</span>
          <span class="right">-¥{{ orderList.platformDiscountAmount || 0.00 }}</span>
        </div>
        <div class="items">
          <span class="left">应付总额：</span>
          <span class="right all">¥{{ orderList.totalPrice || 0 }}</span>
        </div>
      </div>
    </div>
    <!-- 评论详情列表模块 CommentList-->
    <CommentList :commentList="commentList"></CommentList>
    <QualiAftersale ref="qualiAftersaleRef" :orderDetail="orderList" />
    <el-dialog :visible.sync="isQrCode" width="800px" :destroy-on-close="true" :close-on-click-modal="false"
      :close-on-press-escape="false" :before-close="qrCodeBtn" class="codeCont">
      <div class="qrcodeCon">
        <p class="qrTip">请尽快付款！订单号：{{ orderList.orderCode }}</p>
        <p class="qrdesc">请您在5分钟内完成支付，否则订单会被自动取消！</p>
        <div class="qrCont">
          <p>
            应付金额：
            <span>¥{{ total }}</span>
          </p>
          <div id="qrcode" ref="qrcode" class="qrcodeImg" />
        </div>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { checkImages } from '@/utils/status'
import { buttonView } from '@/api/externalSalesRule/index'
import ImgUpload from "@/components/imgUpload";
import Tabs from '@/components/Tabs'
import GiftTag from '@/components/giftTag/index.vue'
import {
  getPurchaseDetailSaleInfo,
  cancelPurchase,
  getKuaidi,
  payResult,
  payPurchase,
  expressStep,
  saveExpress,
  delivery,
  commentList,
  updateExpress,
  queryOrderExpressDeliveryListByOrderId,
  saveSelfOwnedLogistics
} from '@/api/servicecenter/pendingPayment.js'
import QRCode from 'qrcodejs2'
import { mapState } from 'vuex'
import { returnFloat, correspondingVal, accMul } from '@/utils/validate'
import publicTable from '@/components/publicTable'
import QualiAftersale from './components/qualiAftersale.vue'
import CommentList from './commentList'
import { mapGetters } from 'vuex'

export default {
  name: 'PurchaseDetail',
  components: { publicTable, Tabs, ImgUpload, CommentList, QualiAftersale, GiftTag },
  data () {
    return {
      deliverControl: '', // 发货控制
      orderLogisticsList: [],
      tabsList: [ // tabs 传的值
        {
          name: '1',
          label: '三方物流',
          value: 0,
        },
        {
          name: '2',
          label: '自主配送',
          value: 0,
        }
      ],
      tabsActiveName: '1',
      activeName: '1', // 手风琴展开
      expressData: {
        step: ''
      },
      radio: '',
      isBtnLoading: false,
      form: {
        nums: '',
        companyName: '',
        company: ''
      },
      isLoading: false,
      paydialog: false,
      id: '',
      orderId: '',
      list: [],
      orderList: {},
      icon: require('@/icons/img/icon_detail_status.png'),
      indexStep: 0,
      pageObj: {
        // 分页对象
        isShow: false,
        position: 'right', // 分页组件位置
        total: 0,
        page: {
          size: 10,
          current: 1,
          goodsName: '',
          supplierId: '',
          type: ''
        }
      },
      tableData: [],
      columObj: {
        tableName: 'multipleTable',
        // 选择框
        isShow: false,
        selection: false,
        // 选择框根据条件是否可选
        selectable: () => {
          return true
        },
        lazy: 'true',
        loading: false,
        // column列,columType(列类型,可选text(默认为普通文字模式),input(input可编辑框),switch(switch开关),image(图片),operation(操作按钮))
        // prop(参数),label(列名),width(宽度),align(对齐方式),sortable(是否支持排序)
        columnData: [
          {
            imageText: true,
            prop: 'image',
            prop2: 'name',
            prop3: 'factory',
            label: '商品信息',
            width: '300',
            align: 'left',
            sortable: false,
            ownDefinedReturn: (row) => {
              return row.factory
            }
          },
          {
            text: true,
            prop: 'specification',
            label: '规格',
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              // returnFloat(accMul(row.qty, row.price))
              return row.specification
            }
          },
          {
            ownDefined: true,
            prop: 'price',
            label: '单价',
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              return ('￥' + returnFloat(row.price))
            }
          },

          // {
          //   text: true,
          //   prop: 'qty',
          //   label: '数量',
          //   width: '200',
          //   align: 'center'
          // },
          {
            text: true,
            prop: 'qty',
            label: '需求量',
            width: '100',
            align: 'center'
          },
          {
            text: true,
            prop: 'shipmentsAmount',
            label: '实发量',
            width: '100',
            align: 'center'
          },
          {
            text: true,
            prop: 'coupon',
            label: '商户补贴',
            width: '100',
            align: 'center'
          },
          {
            text: true,
            prop: "batchNumber",
            label: "批号",
            width: "200",
            align: "center",
          },
          {
            text: true,
            prop: "traceableCode",
            label: "溯源码",
            width: "200",
            align: "center",
          },
          {
            smallPrice: true,
            // ownStyle: true,
            prop: "qty",
            prop2: "price",
            prop3: "couponDiscount",
            prop4: "platformDiscount",
            slot: true,
            label: "小计",
            width: "140",
            align: "center",
            // ownDefinedReturn: (row) => {
            //   return returnFloat(accMul(row.qty, row.price));
            // },
          },
        ]
      },
      isQrCode: false,
      qrcode: null,
      total: '',
      orderPayId: '',
      status: '',
      commentList: [],//订单评论列表 
      showViewer: true,
      labelWidth: '80px',

      action: process.env.VUE_APP_BASE_API + "/product/goodsbase/upload",
      dialogVisible: false,
      imageList: [],
      formData: {
        comment: "",
        id: '',
        orderCode: ''
      }, //评论内容
      orderLogistics: '',
      isUpdateLogistics: false, //是否更新物流
      disabled: true,
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      texts: ["极差", "失望", "一般", "满意", "惊喜"],
      disabledUpdate: false,//是否禁止更新三方物流
      returnFloat: returnFloat,
      accMul: accMul,
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState('App', {
      selectList: 'selectList'
    }),
    getStatus () {
      return (type, status) => checkImages(type, status)
    }
  },
  methods: {
    // 根据商户查询数据权限判断前端按钮展示
    buttonView () {
      buttonView().then(res => {
        if (res) {
          this.deliverControl = res.deliverControl
        }
      })
    },
    closeViewer () {
      this.showViewer = false
    },
    // 切换物流单号
    handleClick () {
      this.choseLogistics();
    },

    // 当前选择的三方物流
    choseLogistics (type) {
      this.tabsActiveName = '1'
      let item = {};
      for (let i = 0; i < this.orderLogisticsList.length; i++) {
        //expressTag 0为标准物流，1为自有物流
        if (this.orderLogisticsList[i].expressTag == 1) {
          // this.form.company =  this.orderLogisticsList[i].kuaidiId;
          // this.form.companyName = this.orderLogisticsList[i].deliveryDriver; 
          // this.form.nums = this.orderLogisticsList[i].driverPhone;
          // this.form.orderId = this.id;
          this.form.orderExpressDeliveryId = this.orderLogisticsList[i].id;
        } else {
          // 当前选中的
          if (this.orderLogisticsList[i].expressDeliveryNumber == this.orderLogistics) {
            if (type == 0) {
              item = this.orderLogisticsList[type];
            } else {
              item = this.orderLogisticsList[i];
            }
            if (this.orderLogisticsList[i].sendExpressFlag) {
              this.disabledUpdate = true;
            } else {
              this.disabledUpdate = false;
            }
            this.form.company = item.kuaidiId;
            this.form.companyName = item.kuaidiName;
            this.form.nums = item.expressDeliveryNumber;
            this.form.orderId = this.id;
            this.form.orderExpressDeliveryId = item.id;
          }
        }

      }
      this.getExpressStep();
    },

    //查询订单评论列表
    commentListFn () {
      // console.log(this.orderList, 'orderlist')
      commentList({
        orderCode: this.orderList.orderCode
      }).then((res) => {
        if (res.code == 0) {
          this.commentList = res.data;
          for (let i = 0; i < this.commentList.length; i++) {
            if (this.commentList[i].image) {
              this.commentList[i].imageList = this.commentList[i].image.split(',')
            }
          }
        }
      }).catch((err) => { })
    },
    getTransWayText (e) {
      if (e) {
        if (e == '1') {
          return '自主物流'
        } else {
          return '快递'
        }
      } else {
        return '--'
      }
    },

    //切换tabs按钮
    handleChange () {
      this.form.companyName = ''
      this.form.nums = ''
      if (this.tabsActiveName == '1') {
        this.choseLogistics();
        return
      }
    },
    handleSelect (e) {
      this.form.company = e.value
    },
    querySearchAsync (queryString, cb) {
      getKuaidi({ name: queryString }).then((res) => {
        console.log(res.data)
        cb(res.data)
      })
    },
    // 查询订单物流信息
    queryOrderExpress () {
      queryOrderExpressDeliveryListByOrderId({
        orderId: this.id
      }).then((res) => {
        this.orderLogisticsList = res.data;
        this.orderLogistics = res.data[0].expressDeliveryNumber;
        // console.log(this.orderLogistics, 'this.orderLogistics')
        // console.log(res, 'resresresres') 
        if (this.tabsActiveName == '1') {
          this.choseLogistics();
        }
      }).catch((err) => { })
    },
    // 更新物流
    updateLogistics () {
      this.isUpdateLogistics = true;
      let data = {
        orderId: this.id,
        kuaidiId: this.form.company,
        expressDeliveryNumber: this.form.nums,
        orderExpressDeliveryId: this.form.orderExpressDeliveryId
      }
      console.log(data)
      updateExpress(data)
        .then((response) => {
          if (response.code == 0) {
            this.isBtnLoading = false
            this.$message.success('快递信息提交成功～')
            this.queryOrderExpress();   // 查询快递信息列表
          } else {
            this.isBtnLoading = false
            this.$message.error(response.msg)
          }
        })
        .catch((error) => {
          if (error && error.msg) {
            this.$message.error(error.msg)
          }
          this.isBtnLoading = false
        })
    },
    // 新增物流
    addKuaidi () {
      this.isBtnLoading = true
      if (!this.form.companyName) {
        if (this.tabsActiveName == '1') {
          this.$message.error('请填写快递公司～')
        } else {
          this.$message.error('请填写司机姓名～')
        }
        this.isBtnLoading = false
        return false
      }
      if (!this.form.nums) {
        if (this.tabsActiveName == '1') {
          this.$message.error('请填写快递单号～')
        } else {
          this.$message.error('请填写司机联系方式～')
        }
        this.isBtnLoading = false
        return false
      }
      if (this.tabsActiveName == '1') {
        let data = {
          orderId: this.id,
          kuaidiId: this.form.company,
          expressDeliveryNumber: this.form.nums
        }
        saveExpress(data)
          .then((response) => {
            if (response.code == 0) {
              this.isBtnLoading = false
              this.$message.success('快递信息提交成功～')
              this.getData();   // 查询快递信息列表
            } else {
              this.isBtnLoading = false
              this.$message.error(response.msg)
            }
          })
          .catch((error) => {
            if (error && error.msg) {
              this.$message.error(error.msg)
            }
            this.isBtnLoading = false
          })
      } else {
        // console.log(this.form)
        let data = {
          orderId: this.id,
          deliveryDriver: this.form.companyName,
          driverPhone: this.form.nums
        }
        // 自主配送
        saveSelfOwnedLogistics(data)
          .then((response) => {
            if (response.code == 0) {
              this.isBtnLoading = false
              this.$message.success('自主配送提交成功～')
              this.getData()
            } else {
              this.isBtnLoading = false
              this.$message.error(response.msg)
            }
          })
          .catch((error) => {
            this.isBtnLoading = false
            if (error && error.msg) {
              this.$message.error(error.msg)
            }
          })
      }
    },

    // 页码变化
    handleCurrentChange (e) {
      this.pageObj.pageData.current = e
    },
    // 条数变化
    handleSizeChange (e) {
      this.pageObj.pageData.size = e
      this.pageObj.pageData.current = 1
    },
    statusBtn (row) {
      return correspondingVal(
        'basePurchaseOrderEnum',
        row,
        'status',
        this.selectList
      )
    },
    qrCodeCreate () {
      payPurchase(this.id)
        .then((res) => {
          if (res.code == 0) {
            this.isQrCode = true
            this.qrlink = res.data.qrCode
            this.orderId = res.data.orderId
            this.total = res.data.amount
            this.$nextTick(() => {
              this.newqrcode()
              let setTime = setInterval(() => {
                if (this.isQrCode) {
                  if (this.status == '2') {
                    clearInterval(setTime)
                    this.isQrCode = false
                    this.$message.success('订单支付成功')
                  } else if (this.status == '3') {
                    clearInterval(setTime)
                  } else {
                    this.payResult(res.data.id)
                  }
                } else {
                  clearInterval(setTime)
                }
              }, 2000)
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch(() => {
          this.isQrCode = false
        })
    },
    qrCodeBtn () {
      this.isQrCode = false
      this.status = '3'
    },
    newqrcode () {
      if (this.qrcode) {
        this.qrcode.clear()
      }
      this.qrcode = new QRCode('qrcode', {
        width: 200, // 设置宽度，单位像素
        height: 200, // 设置高度，单位像素
        text: this.qrlink // 设置二维码内容或跳转地址
      })
    },
    payResult (id) {
      payResult(id).then((res) => {
        this.status = res.data.status
      })
    },
    // 查询详情
    getData () {
      getPurchaseDetailSaleInfo(this.id)
        .then((res) => {
          this.list = res.data.orderDetailVo
          this.statusStyle(res.data.status)
          this.tabsActiveName = '1'
          this.orderList = res.data
          if (this.orderList.beDelivery == '1') {
            this.tabsActiveName = '2'
            this.form.companyName = this.orderList.deliveryDriver
            this.form.nums = this.orderList.contactsPhone
          }

          // if (this.orderList.status == '2') {
          //   this.tabsActiveName = '1'
          //   this.form.companyName = this.orderList.kuaidiName
          //   this.form.nums = this.orderList.number
          //   this.form.company = this.orderList.kuaidiId
          // }
          this.commentListFn(); //订单评论列表 
          this.queryOrderExpress();   // 查询快递信息列表
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    //  获取快递100物流信息
    getExpressStep () {
      if (!this.form.orderExpressDeliveryId) return
      expressStep({
        orderId: this.id,
        orderExpressDeliveryId: this.form.orderExpressDeliveryId
      }).then((res) => {
        if (res.code == 0 && res.data) {
          this.expressData = res.data
        } else {
          this.expressData = []
          // this.$message.error('暂无数据')
        }
      })
    },
    // 取消订单
    cancelClick (id) {
      this.isLoading = true
      this.$confirm('订单取消后，订单消失，需要您再次下单', '取消订单', {
        confirmButtonText: '确认',
        cancelButtonText: '返回',
        type: 'warning'
      })
        .then(() => {
          cancelPurchase(id)
            .then((response) => {
              let res = response
              if (res.code == 0) {
                this.isLoading = false
                this.$message.success('订单取消成功')
                this.getData()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch(() => {
              this.isLoading = false
            })
        })
        .catch(() => {
          this.isLoading = false
        })
    },

    statusStyle (status) {
      status = Number(status)
      console.log(status)
      // 1.待付款,2.待发货, 3.已发货,4.待收货，5.交易取消, 6申请退款 7.已退款  8.已完成
      // 1待付款 2.待发货 3.配送中 4.待收货 5.交易取消, 6申请退款 7.已退款 8 配送完成 9 已完成 10 售后 11 待审核 new
      if (status == 1) {
        this.indexStep = 1
      } else if (status == 2 || status == 11) {
        this.indexStep = 2
      } else if (status == 3) {
        this.indexStep = 3
      } else if (status == 8 || status == 4) {
        this.indexStep = 4
      } else if (status == 9 || status == 10) {
        this.indexStep = 5
      } else {
        this.indexStep = 0
      }
    }
  },
  mounted () {
    this.buttonView()
    const { id, orderId } = this.$route.query
    this.id = id
    this.orderId = orderId
    this.getData()
    // this.$refs['qualiAftersaleRef'].getDetail()
    // 查询快递信息列表
    this.queryOrderExpress();
  }

}
</script>
<style lang="scss" scoped>
@import "../../styles/main";

.classification {
  background: #eee;

  .lists {
    height: auto !important;
  }

  .commodity {
    ::v-deep .el-table {
      .el-table__header {
        thead {
          th {
            background-color: #fff !important;
          }
        }
      }
    }

    .commodity_info {
      display: flex;
      justify-content: flex-start;
      flex: 1;
      align-items: center;
      // height: 100px;

      .img {
        width: 80px;
        height: 80px;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .goodsInfo {
        .name {
          font-size: 14px;
          color: #333;
          margin-bottom: 10px;
        }

        .factory {
          font-size: 12px;
          color: #999;
        }
      }
    }
  }

  .fl_cen {
    justify-content: flex-start;
  }

  .list_input {
    margin-top: 0px;
  }

  ::v-deep .el-tabs__header {
    line-height: inherit;
    height: auto;
  }

  .info_list {
    ::v-deep .el-tabs__header {
      line-height: inherit;
      height: auto;
    }

    ::v-deep .el-tabs__item {
      height: auto;
      line-height: inherit;
      font-size: 14px;
    }
  }
}

.commemt-list {
  .list {
    padding: 10px;
    border-bottom: 1px solid #ebeef5;

    &:nth-last-child(1) {
      border-bottom: none;
    }
  }

  .list-title {
    font-size: 14px;
    color: #595959;
    display: flex;
    justify-content: space-between;
    line-height: 30px;

    .name {
      font-size: 16px;
      font-weight: bold;
    }

    .time {
      text-align: right;
    }
  }

  .list-t {
    font-size: 14px;
    color: #595959;
    line-height: 30px;
    padding: 0 10px;

    .title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
}

.list-star {
  //display: flex;
  //justify-content: flex-start;
  margin: 10px 0;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #595959;
}

.label-list {
  width: 100%;
  // display: flex;
  //justify-content: flex-start;
}

.comment-hover {}

.no-commnet {
  font-size: 14px;
  color: #595959;
  line-height: 30px;
  padding: 15px;
}

.headerTabs {
  margin-bottom: 30px;
  margin-top: -20px;
}

.formbutton {
  height: 92px;
  margin-top: 3px;
}

::v-deep.el-button.is-disabled:hover {
  background-color: #ebeef5 !important;
  color: #c0c4cc !important;
}

::v-deep.el-tabs__item {
  padding-left: 0px !important;
}</style>
