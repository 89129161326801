<template>
  <el-dialog :title="title" :visible.sync="visible" :lock-scroll="true" width="718px" :before-close="handleClose"
    append-to-body>
    <div class="">
      <div style="padding: 24px 0;">
        <el-form ref="form-data" :model="form" :inline="false" label-width="100px" label-position="right" :rules="rules"
          style="text-align:left;">
          <el-form-item prop="consultResult" label="说明" >
            <el-input v-model.trim="form.consultResult" placeholder="请输入200字以内的说明" show-word-limit maxlength="200" clearable size="auto" type="textarea" style="width:500px" />
          </el-form-item>

          <el-form-item prop="imageList" label="上传凭证(最多5张)">
            <imgUpload v-model="imageList"   :limit="5" style="position:relative;top:-8px">
            </imgUpload>
          </el-form-item>   
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" type="plain" @click="handleClose()">取消</el-button>
      <el-button size="small" type="primary" @click="submit()" >确认提交</el-button>
    </span>
  </el-dialog>
</template>
    
<script>
import fileUpload from "@/components/fileUpload";
import avater from "/public/img/default.png";
import imgUpload from '@/components/imgUpload';
import { saveProof } from "@/api/shop";

export default {
  props: {},
  components: { imgUpload, fileUpload },

  data() {
    return {
      avater: avater,
      disabled: false,
      id: '',
      pageType: '',
      isDisabled: false,
      form: {
        consultResult: ''
      },
      imageList:[],
      rules: {
        imageList: [
          { required: true, message: '请上传凭证', trigger: 'change',validator: (rule, value, callback) => {
             console.log(this.imageList)
            if (this.imageList.length < 1) {
              callback(new Error('请上传图片'));
            } else {
              callback();
            }
          } },
        ],
        consultResult: [
          { required: true, message: '请填写说明', trigger: 'blur' },
        ],
      },
      title: '举证',
      visible: false,
    }
  },
  created() {

  },
  mounted() {

  },
  watch: {},
  methods: {
    submit() { 
      this.$refs['form-data'].validate((valid) => {
        if (valid) {
          this.disabled = true;
          let params = {
            afterSaleId: this.id,
            consultResult: this.form.consultResult,
            consultImage: this.imageList.join(',')
          }
          console.log(params) 
          saveProof(params).then(res => {
            this.$message.success('提交成功');
            this.handleClose();
            this.$emit('getList');
          }).catch(err => {
            this.disabled = false;
          })
        } else {
          return false;
        }
      });
    },

    handleClose() {
      this.visible = false;
      this.$emit("handleClose")
    },

    init(row, type, pageType) {  
      this.visible = true;
      this.id = row.id;
    },
  }
}

</script>
<style lang="scss" scoped>  
.footer-btn {
  width: 80%;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
}
::v-deep .el-dialog__footer {
  border-top: 1px #f5f5f5 solid;
  padding-top: 15px;
}
</style>
  
  