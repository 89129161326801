var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-button",
    {
      attrs: {
        type: _vm.type,
        icon: _vm.icon,
        disabled: _vm.disabled,
        loading: _vm.loading,
        size: _vm.size,
        round: _vm.round,
        plain: _vm.plain,
        circle: _vm.circle,
      },
      on: { click: _vm.click },
    },
    [_vm._v(_vm._s(_vm.text))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }