import request from "@/utils/request";

// 获取店铺基本信息
export function getShipInfor() {
  return request({
    url: `/shop/shop/basic/information`,
    method: "GET",
  });
}

// 更新店铺基本信息
export function updateShipInfor(data) {
  return request({
    url: `/shop/shop/basic/information`,
    method: "PUT",
    data: data,
  });
}

//获取开户设置
export function queryShopOpenAccountSettings() {
  return request({
    url: `/shop/settings/expansion/information/queryShopOpenAccountSettings`,
    method: "GET",
  });
}

//更新开户设置
export function updateShopSettingsExpansionInformation(data) {
  return request({
    url: `/shop/settings/expansion/information/updateShopSettingsExpansionInformation`,
    method: "PUT",
    data: data,
  });
}

//查询商户发货配送设置
export function queryShopDeliveryDistributionSettings() {
  return request({
    url: `/shop/settings/expansion/information/queryShopDeliveryDistributionSettings`,
    method: "GET",
  });
}

//更新商户发货配送设置
export function updateShopDeliveryDistributionSettings(data) {
  return request({
    url: `/shop/settings/expansion/information/updateShopDeliveryDistributionSettings`,
    method: "PUT",
    data: data,
  });
}

//查询商户工作日设置
export function queryDaySettings() {
  return request({
    url: `/shop/settings/expansion/information/queryDaySettings`,
    method: "GET",
  });
}

//更新商户工作日设置
export function updateDaySettings(data) {
  return request({
    url: `/shop/settings/expansion/information/updateDaySettings`,
    method: "PUT",
    data: data,
  });
}

//查询商户售后设置置
export function queryAfterSalesSettings() {
  return request({
    url: `/shop/settings/expansion/information/queryAfterSalesSettings`,
    method: "GET",
  });
}

//更新商户售后设置
export function updateAfterSalesSettings(data) {
  return request({
    url: `/shop/settings/expansion/information/updateAfterSalesSettings`,
    method: "PUT",
    data: data,
  });
}

//获取平台指标
export function getIndicatorInfo(type) {
  return request({
    url: `/shop/platform/service/indicator/${type}`,
    method: "GET",
  });
}
//更新商户支付方式
export function updateOfflinePayment(data) {
  return request({
    url: `/shop/settings/expansion/information/updateOfflinePayment`,
    method: "POST",
    data: data,
  });
}
//获取商户支付方式
export function queryOfflinePayment(data) {
  return request({
    url: `/shop/settings/expansion/information/queryOfflinePayment?shopId=`,
    method: "GET",
    params:data
  });
}
//获取商户保证金详情
export function guaranteeDetail(data) {
  return request({
    url: `/payment/guarantee/platform/info`,
    method: "GET",
    params:data
  });
}

//查询店铺基本信息(New)
export function shopInformationInfoNew(data) {
  return request({
    url: `/shop/shop/basic/information/info`,
    method: "GET",
    params:data
  });
}

//更新店铺基本信息(New)
export function saveOrUpdateInformation(data) {
  return request({
    url: `/shop/shop/basic/information/saveOrUpdate`,
    method: "POST",
    data: data,
  });
}


//查询店铺运费模板
export function shopExpressFees(data) {
  return request({
    url: `/shop/shop/shopExpressFees`,
    method: "GET",
    params:data
  });
}
 
//更新店铺运费模板  
export function updateShopExpressFees(data) {
  return request({
    url: `/shop/shop/shopExpressFees`,
    method: "POST",
    data: data,
  });
}

//删除店铺运费模板
export function deleteShopExpressFees(id) {
  return request({
    url: `/shop/shop/shopExpressFees/${id}`,
    method: "DELETE" 
  });
}

// 修改店铺运费模板
export function editShopExpressFees(data) {
  return request({
    url: `/shop/shop/shopExpressFees/${data.id}`,
    method: "PUT",
    data: data 
  });
}

// 查询店铺是否需要引导（true为需要，false为不需要）
// ：/shop/basic/information/guide

export function informationGuide(data) {
  return request({
    url: `/shop/shop/basic/information/guide`,
    method: "GET",
    params:data
  });
}

// /settings/expansion/information/queryShopSettingsCompleteStatus
// 查询店铺设置是否完成
export function queryShopSettingsCompleteStatus(data) {
  return request({
    url: `/shop/settings/expansion/information/queryShopSettingsCompleteStatus`,
    method: "GET",
    params:data
  });
}

// /settings/expansion/information/shopSettingsExamine
// 店铺设置审核
export function shopSettingsExamine(data) {
  return request({
    url: `/shop/settings/expansion/information/shopSettingsExamine`,
    method: "POST",
    data:data
  });
}

//saveProof
export function saveProof(data) {
  return request({
    url: `/order/aftersale/saveProof`,
    method: "POST",
    data:data
  });
}

// /setting/deptSettingsExpansionInformation/deptAfterSaleExamine
// 售后设置审核
export function deptAfterSaleExamine(data) {
  return request({
    url: `/shop/setting/deptSettingsExpansionInformation/deptAfterSaleExamine`,
    method: "POST",
    data:data
  });
}

// /shop/settings/expansion/information/queryDeptAfterSalesSettingsStatus
// 查询挂靠部门售后设置状态
export function queryDeptAfterSalesSettingsStatus(data) {
  return request({
    url: `/shop/settings/expansion/information/queryDeptAfterSalesSettingsStatus`,
    method: "GET",
    params:data
  });
}

// 查询平台设置信息
// /shop/platform/basic/information 
export function informationPlatform (data){
  return request({
    url: `/shop/platform/basic/information`,
    method: "GET",
    params:data
  });
}

// 
// /payment/shopKangbei/validOfflineOpening
// 店铺是否开通线下支付
export function validOfflineOpening (data){
  return request({
    url: `/payment/shopKangbei/validOfflineOpening`,
    method: "POST",
    data:data
  });
}