import { render, staticRenderFns } from "./provinceFixPrice.vue?vue&type=template&id=b85a1b72&scoped=true"
import script from "./provinceFixPrice.vue?vue&type=script&lang=js"
export * from "./provinceFixPrice.vue?vue&type=script&lang=js"
import style0 from "./provinceFixPrice.vue?vue&type=style&index=0&id=b85a1b72&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b85a1b72",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/godata/pipelines/eShop-Portal-Web-Release-Prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b85a1b72')) {
      api.createRecord('b85a1b72', component.options)
    } else {
      api.reload('b85a1b72', component.options)
    }
    module.hot.accept("./provinceFixPrice.vue?vue&type=template&id=b85a1b72&scoped=true", function () {
      api.rerender('b85a1b72', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/shopOperationCenter/provinceFixPrice.vue"
export default component.exports