var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: !_vm.form.menuId ? "新增" : "编辑",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色名称", prop: "roleName" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入角色名称",
                  disabled: _vm.type == "see",
                },
                model: {
                  value: _vm.form.roleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "roleName", $$v)
                  },
                  expression: "form.roleName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "角色标识", prop: "roleCode" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入角色标识",
                  disabled: _vm.type == "see" || _vm.type == "edit",
                },
                model: {
                  value: _vm.form.roleCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "roleCode", $$v)
                  },
                  expression: "form.roleCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "角色描述", prop: "roleDesc" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入角色描述",
                  disabled: _vm.type == "see",
                },
                model: {
                  value: _vm.form.roleDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "roleDesc", $$v)
                  },
                  expression: "form.roleDesc",
                },
              }),
            ],
            1
          ),
          _vm.type == "see"
            ? _c(
                "el-form-item",
                { attrs: { label: "创建时间", prop: "" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.createTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "createTime", $$v)
                      },
                      expression: "form.createTime",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.type != "see"
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.dataFormSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }