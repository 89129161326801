<!--  -->
<template>
  <div class="classification">
    <div class="header">
      <!-- <span class="font">控销申请-控销团队</span> -->
      <el-form ref="formTool"
               :model="formTool"
               :inline="true"
               style="text-align: left">
        <el-form-item prop="name"
                      style="margin:0px !important"
                      label="">
          <el-input v-model="formTool.name"
                    placeholder="请输入申请人姓名"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="name"
                      label="">
          <el-input v-model="formTool.phone"
                    placeholder="请输入申请人电话"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     type="primary"
                     @click="clickSearch(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     @click="reset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="center"
         style="position: relative">
      <Tabs @tab-click="handleClick"
            :tabsList="tabsList"
            :activeName.sync="activeName"></Tabs>
      <MyTable :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getList"
               @handleSelectionChange="handleSelectionChange">
        <template slot="address"
                  slot-scope="scope">
          <el-row>
            {{ scope.row.address }}
          </el-row>
        </template>

        <template slot="demoState"
                  slot-scope="scope">
          <el-row v-if="scope.row.demoState == 0"> 待审核 </el-row>
          <el-row v-if="scope.row.demoState == 1"> 已通过 </el-row>
          <el-row v-if="scope.row.demoState == 2"> 已驳回 </el-row>
        </template>

        <template slot="caozuo"
                  slot-scope="scope">
          <el-link :underline="false"
                   type="primary"
                   class="btn"
                   @click="delailHandle(scope.row)">查看</el-link>
          <el-link :underline="false"
                   style="margin-left:10px"
                   v-if="scope.row.demoState == 0"
                   type="primary"
                   class="btn"
                   @click="aduitHandle(scope.row)">审核通过</el-link>
          <el-link :underline="false"
                   style="margin-left:10px"
                   v-if="scope.row.demoState == 0"
                   type="primary"
                   class="btn"
                   @click="reject(scope.row)">驳回</el-link>
          <!-- <el-link
            :underline="false"
            style="margin-left: 16px"
            type="primary"
            @click="delailHandle(scope.row)"
            >详情</el-link
          > -->
        </template>
      </MyTable>
    </div>
    <el-dialog width="40%"
               title="编辑经销组织"
               :visible.sync="show"
               :close-on-click-modal="false">
      <div class="jxContent">
        <div class="tree">
          <el-tree ref="cityTree"
                   node-key="id"
                   :data="cityData"
                   :highlight-current="true"
                   @node-click="getNodeKey"
                   :props="cityDefaultProps"></el-tree>
        </div>
        <div class="btn">
          <el-button size="mini"
                     @click="cancel">取消</el-button>
          <el-button type="primary"
                     size="mini"
                     @click="submitExamine">审核通过</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import { drugenumeration } from "@/utils/enumeration";
import {
  getTeamApplys,
  soldout,
  teamApplysAgree,
  teamApplysReject,
} from "@/api/saleRule/productLine";
import { regionAreaTree } from "@/api/saleRule/saleF.js";

import avater from "/public/img/default.png";
import Tabs from "@/components/Tabs";
export default {
  components: { MyTable, Tabs },
  data () {
    return {
      avater: avater,
      formTool: {
        name: "",
        phone: "",
      },
      activeName: "first", // tabs 传的值
      tabsList: [
        // tabs 传的值
        {
          name: "first",
          label: "全部申请",
          value: 0,
          state: "",
        },
        {
          name: "aduit",
          label: "待审核",
          value: 0,
        },
        {
          name: "pass",
          label: "已通过",
          value: 0,
        },
        {
          name: "fail",
          label: "已驳回",
          value: 0,
        },
      ],
      selectionList: [],
      operation: false,
      selection: false,
      index: true,
      tableData: [],
      tableOption: [
        { label: "负责人姓名", prop: "managerName" },
        { label: "负责人联系方式", prop: "contactPhone" },
        { label: "申请类型", prop: "applyType" },
        { label: "申请控销品", prop: "goodsName" },
        { label: "省市地", prop: "address", slot: true }, // 这里表示自定义列
        { label: "审核状态", prop: "demoState", slot: true }, //0待审，1通过，2驳回
        { label: "申请时间", prop: "createTime" },
        { label: "操作", prop: "caozuo", slot: true, width: '180px' }
      ],
      page: {
        total: 0,
        current: 1,
        size: 20,
        pageSizes: [10, 20, 30, 50],
      },
      show: false,
      cityData: [],
      cityDefaultProps: {
        children: "children",
        label: "rname",
      },
      currennode: {
        id: null,
      },
      rowId: null,
      type: null,
    };
  },
  computed: {},
  watch: {
    // cityData: {
    //   deep: true,
    //   immediate: true,
    //   handler(val) {
    //     this.$nextTick(() => {
    //       this.currennode = this.findNode(this.cityData, (node) => {
    //         return !node.children;
    //       });
    //       this.$refs.cityTree.setCurrentKey(this.currennode.id);
    //     });
    //   },
    // },
  },
  created () {
    // this.getList();
    // this.getRegionAreaTree();
  },
  mounted () { 
    if(this.$store.state.tabStatus.salesControlTeamTab){
      this.activeName = this.$store.state.tabStatus.salesControlTeamTab 
      // this.handleClick(this.activeName); 
      this.handleClick(this.activeName);
    }else{
       this.getList();
      this.getRegionAreaTree();  
    }
  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    // tab 切换
    handleClick (e) {
      console.log(e,'---');
      let state = "";
      if (e == "first") {
        state = "";
      }
      if (e == "aduit") {
        state = 0;
      }
      if (e == "pass") {
        state = 1;
      }
      if (e == "fail") {
        state = 2;
      }
      this.$store.dispatch("tabStatus/setSalesControlTeamTab",e)
      this.state = state;
      this.getNameList(1);
    },

    delailHandle (row) {
      this.$router.push({
        path: "/salesControlMange/salesControl-team-detail",
        query: {
          id: row.id,
        },
      });
      console.log(row);
    },
    getRegionAreaTree () {
      let data = {
        level: 3,
      };
      regionAreaTree(data).then((res) => {
        if (+res.code === 0) {
          this.cityData = res.data;
        }
      });
    },
    // 重置查询条件
    reset () {
      this.formTool = {
        name: "",
        approvalNumber: "",
      };
      this.getList();
    },

    // 切换分页
    getList () {
      this.getNameList();
    },
    // 勾选
    handleSelectionChange (val) {
      this.selectionList = val;
    },
    // 获取tab状态码
    getState () {
      let state = "";
      for (let i = 0; i < this.tabsList.length; i++) {
        if (this.activeName === this.tabsList[i].name) {
          state = this.tabsList[i].state;
        }
      }
      return state;
    },
    // 查询出售中的商品
    getNameList (current) {
      if (current) {
        // 搜索条件改变从第一页开始查询
        this.page.current = current;
      }
      // debugger
      const params = {
        ...this.formTool,
        current: this.page.current,
        size: this.page.size,
        state: this.state,
      };
      getTeamApplys(params).then((res) => {
        if (res) { 
          this.tableData = []; 
          console.log('table','---list')
          this.tableData = res.page.records;
          this.tabsList[1].value = res.awaitCheckQty;
          // console.log(this.tableData, "image");
          this.page.total = res.page.total;
          if (this.tableData.length > 0) {
            this.tableData.forEach((item) => {
              item.type = drugenumeration(item.type);
            });
          }
        } else {
          this.tableData = [];
          this.page.total = 0;
        }
      });
    },
    aduitHandle (row) {
      console.log(row);
      this.rowId = row.id;
      this.type = row.type;
      if (row.type === 1) {
        let data = {
          id: row.id,
          type: row.type,
        };
        teamApplysAgree(data).then((res) => {
          if (res) {
            this.$message.success("操作成功");
            this.getList();
            this.show = false;
          }
        });
      } else {
        this.show = true;
      }
    },
    // 审核
    reject (row) {
      let params = {
        id: row.id,
      };
      teamApplysReject(params).then((res) => {
        if (res) {
          this.$message.success("操作成功");
          this.getList();
        }
      });
    },
    // 搜索
    clickSearch (current) {
      this.getNameList(current);
    },
    // 选择城市
    getNodeKey (e) {
      this.$nextTick(() => {
        if (!e.children) {
          this.currennode = e;
        }
        this.$refs.cityTree.setCurrentKey(this.currennode.id);
      });
    },
    //查找树节点无子节点的叶节点
    findNode (tree, func) {
      for (const node of tree) {
        if (func(node)) return node;
        if (node.children) {
          const res = this.findNode(node.children, func);
          if (res) return res;
        }
      }
      return null;
    },
    submitExamine () {
      console.log(this.currennode.id);
      if (!this.currennode.id || this.currennode.id === null) {
        this.$message({
          message: "请先选择城市",
          type: "warning",
        });
        return false;
      }
      this.teamApplysAgreeFun();
    },
    teamApplysAgreeFun () {
      let params = {
        id: this.rowId,
        type: this.type,
        areaCode: this.currennode.id,
        areaName: this.currennode.rname,
      };
      teamApplysAgree(params).then((res) => {
        if (res) {
          this.$message.success("操作成功");
          this.getList();
          this.show = false;
        }
      });
    },
    cancel () {
      this.$nextTick(() => {
        this.currennode = {
          id: null,
        };
        this.$refs.cityTree.setCurrentKey(this.currennode.id);
        this.$refs.cityTree.setc(this.currennode.id);
      });
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
.classification {
  padding: 0px;
  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;
    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }
    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }
  background: white;
  .top {
    display: flex;
    padding: 10px 0 10px 0;
    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      .label {
        width: 120px;
      }
      .button {
        margin-right: 20px;
        ::v-deep .el-button {
          color: $subMenuActiveText;
          background-color: $butColor;
          border-color: $butColor;
          &:hover {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
          &:active {
            color: $subMenuActiveText;
            background: $butColor;
            background-color: $butColor;
            border-color: $butColor;
          }
          &:focus {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
        }
      }
    }
  }
  .center {
    // margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 165px);
    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }
  .btn {
    // margin: 0 10px;
  }
}
.jxContent {
  .tree {
    border-top: 1px solid #dbdbdb;
    box-sizing: border-box;
    padding: 10px;
    min-height: 100px;
    max-height: 300px;
    overflow: auto;
  }
  .btn {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 50px;
    border-top: 1px solid #dbdbdb;
  }
  ::v-deep
    .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    color: #f5222d;
    font-weight: bold;
    background-color: #fff !important;
  }
}
</style>
