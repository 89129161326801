var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "billing" },
    [
      _c(
        "el-form",
        {
          ref: "formInline",
          attrs: { model: _vm.formInline, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "开票类型：", prop: "invoiceType" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.formInline.invoiceType,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "invoiceType", $$v)
                    },
                    expression: "formInline.invoiceType",
                  },
                },
                [
                  _c("el-checkbox", { attrs: { label: "1" } }, [
                    _vm._v("普通发票（纸质）"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "2" } }, [
                    _vm._v("普通发票（电子）"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "3" } }, [
                    _vm._v("增值税专用发票（纸质）"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "4" } }, [
                    _vm._v("增值税专用发票（电子）"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "送达时间：", prop: "deliveryWay" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formInline.deliveryWay,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "deliveryWay", $$v)
                    },
                    expression: "formInline.deliveryWay",
                  },
                },
                _vm._l(_vm.radioList, function (item, index) {
                  return _c(
                    "el-radio",
                    { key: index, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.instructions))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.formInline.deliveryWay == "1729306113932353538"
            ? _c(
                "el-form-item",
                { attrs: { label: "说明：", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px" },
                    attrs: { type: "textarea", maxlength: "200" },
                    model: {
                      value: _vm.formInline.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "remark", $$v)
                      },
                      expression: "formInline.remark",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }