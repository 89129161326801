var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "formTool",
          attrs: { model: _vm.formTool, "label-width": "100px" },
        },
        [
          _c("div", { staticClass: "title-form" }, [
            _c("span", [
              _vm._v(" 企业名称: " + _vm._s(_vm.companyItem.sellerName) + " "),
            ]),
            _c("span", [
              _vm._v(
                " 企业税号: " + _vm._s(_vm.companyItem.sellerTaxpayerId) + " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "header", staticStyle: { margin: "10px 0" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单创建时间", prop: "date" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      size: "small",
                      "range-separator": "-",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                    },
                    model: {
                      value: _vm.formTool.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "date", $$v)
                      },
                      expression: "formTool.date",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订单编号" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.formTool.orderCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formTool,
                          "orderCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formTool.orderCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客户名称" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.formTool.clientName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formTool,
                          "clientName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formTool.clientName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "0px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.reset()
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.goAddTax()
                        },
                      },
                    },
                    [_vm._v("开票")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("MyTable", {
            attrs: {
              showpage: true,
              rowKeyProps: "id",
              "table-data": _vm.tableData,
              index: _vm.index,
              selection: _vm.selection,
              page: _vm.page,
              operation: _vm.operation,
              "table-option": _vm.tableOption,
            },
            on: {
              "update:tableOption": function ($event) {
                _vm.tableOption = $event
              },
              "update:table-option": function ($event) {
                _vm.tableOption = $event
              },
              "page-change": _vm.getList,
              handleSelectionChange: _vm.handleSelectionChange,
            },
            scopedSlots: _vm._u([
              {
                key: "orderAmount",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.orderAmount || "0.00") + " "),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }