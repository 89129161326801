var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        { staticStyle: {} },
        [
          _c(
            "el-row",
            { staticClass: "lable-list" },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "formOld",
                      attrs: {
                        "label-width": "120px",
                        size: "small",
                        disabled: true,
                        "label-position": "right",
                      },
                    },
                    [
                      _c("formList", {
                        ref: "form-list",
                        attrs: { formTool: _vm.formOld, source: 1 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 2 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    "label-width": "120px",
                    size: "small",
                    disabled: true,
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品详情" } },
                    [
                      _c("wangEnduit", {
                        attrs: {
                          value: _vm.formOld.detail,
                          "is-clear": _vm.isClear,
                          isDisabled: true,
                        },
                        on: { changeEndit: _vm.watchEndit },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "160px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.nextPage },
            },
            [_vm._v("下一步")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "160px" },
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.back()
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }