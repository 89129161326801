var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _c("div", { staticClass: "headerTopcont" }, [
        _c("img", {
          staticClass: "logoImg",
          attrs: {
            src: require("@/assets/logo-chunbo.png"),
            width: "100px",
            height: "40px",
            alt: "",
          },
        }),
        _c("div", { staticClass: "headerCompany" }, [
          _c("div", { staticClass: "companyName" }, [
            _vm._v(_vm._s(_vm.shopData ? _vm.shopData.shopName : "")),
          ]),
          _c("span", [
            _vm._v(
              "店铺状态：" +
                _vm._s(
                  _vm.shopData ? _vm.shopStatus[_vm.shopData.shopStatus] : ""
                )
            ),
          ]),
          _c("span", { staticClass: "erp" }, [
            _vm._v(
              "ERP对接：" +
                _vm._s(
                  _vm.shopData ? _vm.erpStatus[_vm.shopData.shopErpStatus] : ""
                )
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "right-menu" },
          [
            _c(
              "div",
              { staticStyle: { "margin-right": "16px", color: "white" } },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      cursor: "pointer",
                      "font-size": "14px",
                      position: "relative",
                      top: "-1px",
                    },
                    on: { click: _vm.tousu },
                  },
                  [_vm._v("投诉建议")]
                ),
              ]
            ),
            _c(
              "el-dropdown",
              { staticClass: "avatar-container", attrs: { trigger: "click" } },
              [
                _c("div", { staticClass: "avatar-wrapper" }, [
                  _c("span", { staticClass: "user-avatar" }, [
                    _vm._v(_vm._s(_vm.name)),
                  ]),
                  _c("i", {
                    staticClass: "el-icon-arrow-down el-icon--right downIcon",
                  }),
                ]),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "user-dropdown",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.goInfo.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("span", { staticStyle: { display: "block" } }, [
                          _vm._v("个人中心"),
                        ]),
                      ]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.logout.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("span", { staticStyle: { display: "block" } }, [
                          _vm._v("退出"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      [
        _c(
          "div",
          {
            class:
              _vm.showTips || !_vm.shopCompleteStatus ? "tips" : "tips_none",
          },
          [
            !_vm.shopCompleteStatus
              ? _c("div", { staticClass: "text" }, [
                  _c("i", {
                    staticClass: "el-icon-warning",
                    staticStyle: { color: "#F5A300" },
                  }),
                  _vm._v(" 如需发布商品，请先完成店铺设置 "),
                  _vm.beMainDept
                    ? _c("span", { on: { click: _vm.goShopManage } }, [
                        _vm._v("去设置》"),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.shopInfo && _vm.shopInfo.auditStatus == "NOT_PASS"
              ? _c("div", { staticClass: "text" }, [
                  _c("i", {
                    staticClass: "el-icon-warning",
                    staticStyle: { color: "#F5A300" },
                  }),
                  _vm._v("店铺审核未通过，请修改后重新提交审核 "),
                  _vm.beMainDept
                    ? _c("span", { on: { click: _vm.showRemark } }, [
                        _vm._v("查看原因"),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.shopInfo && _vm.shopInfo.auditStatus == "AWAIT"
              ? _c("div", { staticClass: "text" }, [
                  _c("i", {
                    staticClass: "el-icon-warning",
                    staticStyle: { color: "#F5A300" },
                  }),
                  _vm._v("店铺审核中，请耐心等待 "),
                ])
              : _vm._e(),
            _vm.shopInfo && _vm.shopInfo.auditStatus == "PRESERVE"
              ? _c("div", { staticClass: "text" }, [
                  _c("i", {
                    staticClass: "el-icon-warning",
                    staticStyle: { color: "#F5A300" },
                  }),
                  _vm._v("店铺信息已保存为草稿 "),
                  _vm.beMainDept
                    ? _c(
                        "div",
                        {
                          class: !_vm.isShopSettingPage
                            ? "isShopSetting"
                            : "notShopSetting",
                          on: { click: _vm.goShopManage },
                        },
                        [_vm._v("，请提交审核")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            class:
              _vm.deptStatus == "NOT_PASS" && !_vm.beMainDept
                ? "tips"
                : "tips_none",
          },
          [
            _c("div", { staticClass: "text" }, [
              _c("i", {
                staticClass: "el-icon-warning",
                staticStyle: { color: "#F5A300" },
              }),
              _vm._v("售后设置审核未通过，请修改后重新提交审核 "),
              _c("span", { on: { click: _vm.showAfterRemarkFn } }, [
                _vm._v("查看原因"),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            class:
              _vm.deptStatus == "AWAIT" && !_vm.beMainDept
                ? "tips"
                : "tips_none",
          },
          [_vm._m(0)]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "店铺信息审核未通过原因",
              visible: _vm.showShopManageRemark,
              width: "30%",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showShopManageRemark = $event
              },
            },
          },
          [
            _c(
              "div",
              { staticStyle: { height: "200px", "overflow-y": "auto" } },
              [
                _vm.shopInfo && _vm.shopInfo.auditStatus == "NOT_PASS"
                  ? _c("div", [
                      _c(
                        "div",
                        { staticStyle: { margin: "20px 0px 20px 20px" } },
                        [
                          _c("span", { staticStyle: { color: "000" } }, [
                            _vm._v(_vm._s(_vm.shopInfo.remark)),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c("el-button", { on: { click: _vm.closeRemark } }, [
                  _vm._v("关闭"),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "驳回原因",
              visible: _vm.showAfterRemark,
              width: "30%",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showAfterRemark = $event
              },
            },
          },
          [
            _c(
              "div",
              { staticStyle: { height: "200px", "overflow-y": "auto" } },
              [
                _c("div", { staticStyle: { margin: "20px 0px 20px 20px" } }, [
                  _c("span", { staticStyle: { color: "000" } }, [
                    _vm._v(_vm._s(_vm.deptRemark)),
                  ]),
                ]),
              ]
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c("el-button", { on: { click: _vm.closeAfterRemark } }, [
                  _vm._v("我知道了"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.goAfterInfo },
                  },
                  [_vm._v("去修改")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _c("sidebar", { staticClass: "sidebar-container" }),
      _c(
        "div",
        { staticClass: "main-container" },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [_c("navbar")],
            1
          ),
          _c("app-main"),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text" }, [
      _c("i", {
        staticClass: "el-icon-warning",
        staticStyle: { color: "#F5A300" },
      }),
      _vm._v("售后设置审核中，请耐心等待 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }