<template>
  <div class="detail comContent">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="170px"
      :disabled="type == 2"
    >
      <div class="activeInfo">
        <div class="tit">拼团活动信息</div>
        <div class="content">
          <el-form-item label="活动名称"> {{ groupInfo.name }} </el-form-item>
          <el-form-item label="活动说明">
            {{ groupInfo.remark }}
          </el-form-item>
          <el-form-item label="拼团活动时间">
            <el-date-picker
              v-model="groupInfo.date"
              disabled
              value-format="yyyy-MM-dd"
              type="daterange"
              size="small"
              range-separator="-"
              start-placeholder="拼团开始"
              end-placeholder="拼团结束"
            />
            <span class="mark">
              活动开始前可修改拼团信息，开始后不允许修改</span
            >
          </el-form-item>
        </div>
      </div>
      <div class="goodsInfo">
        <div class="tit">拼团商品信息</div>
        <div
          class="goodsInfoBox"
          @click="openGoodsListTemp"
          v-if="goodsInfo && goodsInfo.goodsId"
        >
          <img :src="goodsInfo.image || goodsInfo.goodsImg" />
          <div class="info">
            <!-- <div>商品编号：{{  }}</div>
            <div>商品ERP编码：{{  }}</div> -->
            <div>商品名称：{{ goodsInfo.name || goodsInfo.goodsName }}</div>
            <div>
              规格：{{
                goodsInfo.specification || goodsInfo.goodsSpecification
              }}
            </div>
            <div>
              生产厂家：{{ goodsInfo.factory || goodsInfo.goodsFactory }}
            </div>
            <div>零售价：￥{{ Number(goodsInfo.salePrice).toFixed(2) }}</div>
          </div>
        </div>
        <el-button type="primary" size="small" v-else @click="openGoodsList"
          >选择商品</el-button
        >
      </div>
      <div class="spellGoodsConfig" v-if="goodsInfo && goodsInfo.goodsId">
        <div class="tit">配置拼团商品</div>
        <div class="content">
          <el-form-item label="拼团价格(￥)" prop="groupBuyPrice">
            <el-input
              v-model="ruleForm.groupBuyPrice"
              size="small"
              placeholder="请输入"
              style="width: 30%"
            ></el-input>
            <span class="mark"> 每家参团药店采购价格</span>
          </el-form-item>
          <el-form-item label="起拼下限" prop="minSingleBuyCount">
            <el-input
              v-model="ruleForm.minSingleBuyCount"
              size="small"
              type="number"
              placeholder="请输入"
              oninput="
                if (value.length > 4) {
                  value = value.slice(0, 4);
                }
                value = value.replace(/[^0-9]/g, '');
              "
              style="width: 30%"
            ></el-input>
            <span class="mark"> 每家参团药店单次最低采购数量</span>
          </el-form-item>
          <el-form-item
            label="累计拼团上限"
            prop="userMaxTotalBuyCount"
          >
            <el-input
              v-model="ruleForm.userMaxTotalBuyCount"
              size="small"
              type="number"
              placeholder="请输入"
              oninput="
                if (value.length > 8) {
                  value = value.slice(0, 8);
                }
                value = value.replace(/[^0-9]/g, '');
              "
              style="width: 30%"
            ></el-input>
            <span class="mark"> 每家参团药店累计最高采购数量</span>
          </el-form-item>
          <el-form-item label="拼团活动库存" prop="maxGroupTotalCount">
            <el-input
              v-model="ruleForm.maxGroupTotalCount"
              size="small"
              type="number"
              placeholder="请输入"
              oninput="
                if (value.length > 8) {
                  value = value.slice(0, 8);
                }
                value = value.replace(/[^0-9]/g, '');
              "
              style="width: 30%"
            ></el-input>

            <span class="mark"> 所有参团药店采购总数量不能大于该数值</span>
          </el-form-item>
        </div>
        <el-form-item v-if="!isDisabled">
          <el-button size="small" @click="cancel">取消</el-button>
          <el-button type="primary" size="small" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <div style="text-align: left; margin-top: 10px" v-if="isDisabled">
      <el-button type="primary" size="small" @click="cancel">返回</el-button>
    </div>
    <goodsDialog
      :dialog-visible="dialogVisible"
      @chooseGoodsItem="chooseGoodsItem"
    />
  </div>
</template>

<script>
import { groupBuyDetail } from "@/api/spellGroup/spellGroupActive";
import goodsDialog from "./components/goodsDialog";
import {
  editGroupBuyGoods,
  groupBuyGoodsDetail,
  saveGoodsSkuConfig,
} from "@/api/spellGroup/spellGroupGoods";
import { moneyFix } from "@/utils/validate";
export default {
  components: { goodsDialog },
  data() {
    return {
      groupInfo: {
        name: "",
        remark: "",
        date: [],
      },
      ruleForm: {
        groupBuyPrice: "",
        minSingleBuyCount: "",
        userMaxTotalBuyCount: "",
        maxGroupTotalCount: "",
      },
      rules: {
        groupBuyPrice: [
          { required: true, validator: moneyFix, trigger: "blur" },
        ],
        minSingleBuyCount: [
          { required: true, message: "请输入数量", trigger: "blur" },
        ],
        userMaxTotalBuyCount: [
          { required: true, message: "请输入数量", trigger: "blur" },
        ],
        maxGroupTotalCount: [
          { required: true, message: "请输入数量", trigger: "blur" },
        ],
      },
      dialogVisible: false,
      goodsInfo: {},
    };
  },
  computed: {
    groupId() {
      return this.$route.query.groupBuyId;
    },
    type() {
      return this.$route.query.type;
    },
    id() {
      return this.$route.query.id;
    },
    isDisabled: function () {
      return this.$route.query.type == 2 ? true : false;
    },
  },
  created() {
    if (this.id) {
      this.getDetail();
    }
    this.getGroupDetail();
  },
  methods: {
    getDetail() {
      groupBuyGoodsDetail({ id: this.id }).then((res) => {
        if (res) {
          this.getDetailInfo(res);
        }
      });
    },
    chooseGoodsItem(item) {
      this.goodsInfo = item;
    },
    openGoodsList() {
      this.dialogVisible = true;
    },
    openGoodsListTemp() {
      if (+this.type === 3) {
        this.dialogVisible = true;
      }
    },
    getGroupDetail() {
      groupBuyDetail({ id: this.groupId }).then((res) => {
        if (res) {
          this.groupInfo = res;
          this.groupInfo.date = [res.startDate, res.endDate];
        }
      });
    },
    editGroupGoods() {
      let data = {
        ...this.ruleForm,
        // goodsId: this.goodsInfo.goodsId,
        id: this.id,
      };
      editGroupBuyGoods(data).then((res) => {
        this.$message.success("保存成功");
        this.$router.replace("/spellGroup/spellGroup-goodsList-index");
      });
    },
    addGroupGoods() {
      let data = {
        ...this.ruleForm,
        // goodsId: this.goodsInfo.goodsId,
        goodsSkuId: this.goodsInfo.goodsSkuId,
        groupBuyId: this.groupId,
      };
      saveGoodsSkuConfig(data).then((res) => {
        this.$message.success("添加成功");
        this.$router.replace("/spellGroup/spellGroup-goodsList-index");
      });
    },
    // 点击提交保存
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.type == 1) {
            this.editGroupGoods();
          } else if (this.type == 2) {
          } else {
            this.addGroupGoods();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancel() {
      this.$router.replace("/spellGroup/spellGroup-goodsList-index");
    },
    getDetailInfo(res) {
      for (const key in this.ruleForm) {
        if (key == "groupBuyPrice") {
          this.ruleForm[key] = Number(res[key]).toFixed(2);
        } else {
          this.ruleForm[key] = res[key];
        }
      }
      this.goodsInfo = res;
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  .tit {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #262626;
    padding: 20px 0;
  }
  .goodsInfoBox {
    display: flex;
    align-items: center;
    padding-left: 50px;
    box-sizing: border-box;
    width: 45%;
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
    .info {
      margin-left: 20px;
      color: #333;
      font-size: 14px;
      line-height: 30px;
    }
  }
  .mark {
    color: #f56c6c;
    font-size: 12px;
    margin-left: 10px;
    width: 200px;
    line-height: 1.5;
    display: inline-block;
    font-weight: bold;
    vertical-align: middle;
  }
}
</style>
