var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contentCont" },
    [
      _c("MyTable", {
        attrs: {
          "table-data": _vm.tableData,
          index: _vm.index,
          selection: _vm.selection,
          page: _vm.page,
          showpage: false,
          operation: _vm.operation,
          "table-option": _vm.tableOption,
        },
        on: {
          "update:tableOption": function ($event) {
            _vm.tableOption = $event
          },
          "update:table-option": function ($event) {
            _vm.tableOption = $event
          },
          "page-change": _vm.getList,
        },
        scopedSlots: _vm._u([
          {
            key: "menu",
            fn: function (scope) {
              return [
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary", underline: false },
                    on: {
                      click: function ($event) {
                        return _vm.detailBtn(scope.row)
                      },
                    },
                  },
                  [_vm._v(" 详情 ")]
                ),
                _c(
                  "el-link",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { type: "primary", underline: false },
                    on: {
                      click: function ($event) {
                        return _vm.editorBtn(scope.row)
                      },
                    },
                  },
                  [_vm._v(" 修改 ")]
                ),
                _c(
                  "el-link",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { type: "primary", underline: false },
                    on: {
                      click: function ($event) {
                        return _vm.deleteBtn(scope.row)
                      },
                    },
                  },
                  [_vm._v(" 删除 ")]
                ),
              ]
            },
          },
          {
            key: "address",
            fn: function (scope) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(scope.row.receiverProvinceName) +
                      _vm._s(scope.row.receiverCityName) +
                      _vm._s(scope.row.receiverAreaName) +
                      _vm._s(scope.row.receiverAddrDetail)
                  ),
                ]),
              ]
            },
          },
          {
            key: "province",
            fn: function (scope) {
              return [
                _c("span", [_vm._v(_vm._s(_vm.areaList(scope.row.areaList)))]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }