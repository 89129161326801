var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qualification-step-root" },
    [
      _c("StepView", {
        attrs: {
          "current-index": _vm.localCurrentIndex,
          "step-list": _vm.stepList,
        },
      }),
      _c(
        "section",
        { staticClass: "content" },
        [
          _vm.localCurrentIndex === 1
            ? [
                _c("CompanyTypeView", {
                  on: { "next-step": _vm.handleNextStep },
                }),
              ]
            : _vm.localCurrentIndex === 2
            ? [
                _c("InfoDetailView", {
                  on: {
                    "pre-step": _vm.handlePreStep,
                    submit: _vm.handleSubmit,
                  },
                }),
              ]
            : _vm.localCurrentIndex === 3
            ? [
                _c("StatusView", {
                  on: { "change-info": _vm.handleChangeInfo },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }