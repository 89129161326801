var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "numberValidateFormOne",
          staticClass: "ruleFormCont",
          attrs: {
            model: _vm.numberValidateFormOne,
            "label-width": "100px",
            "label-position": "top",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "企业名称", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "38vw" },
                attrs: { placeholder: "请输入企业名称" },
                model: {
                  value: _vm.numberValidateFormOne.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.numberValidateFormOne, "name", $$v)
                  },
                  expression: "numberValidateFormOne.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所在区域", prop: "provinceName" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "align-items": "center",
                    "justify-content": "space-between",
                    width: "38vw",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "12vw" },
                      attrs: { placeholder: "请选择省" },
                      on: { change: _vm.provinceBtn },
                      model: {
                        value: _vm.numberValidateFormOne.provinceName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.numberValidateFormOne,
                            "provinceName",
                            $$v
                          )
                        },
                        expression: "numberValidateFormOne.provinceName",
                      },
                    },
                    _vm._l(_vm.provinceData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.rname, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "12vw" },
                      attrs: { placeholder: "请选择市" },
                      on: { change: _vm.cityBtn },
                      model: {
                        value: _vm.numberValidateFormOne.cityName,
                        callback: function ($$v) {
                          _vm.$set(_vm.numberValidateFormOne, "cityName", $$v)
                        },
                        expression: "numberValidateFormOne.cityName",
                      },
                    },
                    _vm._l(_vm.cityData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.rname, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "12vw" },
                      attrs: { placeholder: "请选择区" },
                      on: { change: _vm.areaBtn },
                      model: {
                        value: _vm.numberValidateFormOne.areaName,
                        callback: function ($$v) {
                          _vm.$set(_vm.numberValidateFormOne, "areaName", $$v)
                        },
                        expression: "numberValidateFormOne.areaName",
                      },
                    },
                    _vm._l(_vm.areaData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.rname, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "详细地址", prop: "address" } },
            [
              _c("el-input", {
                staticStyle: { width: "38vw" },
                attrs: { placeholder: "请输入详细地址" },
                model: {
                  value: _vm.numberValidateFormOne.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.numberValidateFormOne, "address", $$v)
                  },
                  expression: "numberValidateFormOne.address",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发票类型", prop: "invoiceType" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.numberValidateFormOne.invoiceType,
                    callback: function ($$v) {
                      _vm.$set(_vm.numberValidateFormOne, "invoiceType", $$v)
                    },
                    expression: "numberValidateFormOne.invoiceType",
                  },
                },
                _vm._l(_vm.invoicetypeData, function (item, index) {
                  return _c(
                    "el-checkbox",
                    {
                      key: index,
                      attrs: { label: item.code, name: item.name },
                    },
                    [_vm._v(_vm._s(item.name || ""))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.formOneload,
                    size: "large",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitFormOne("numberValidateFormOne")
                    },
                  },
                },
                [_vm._v("下一步")]
              ),
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "tip", staticStyle: { "margin-bottom": "20px" } },
            [
              _vm._v(
                " 提示：为保证结果准确性，请保证企业类型、证照编码与实际相符 "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }