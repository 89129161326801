var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c("el-scrollbar", { staticStyle: { height: "calc(100vh - 148px)" } }, [
        _c("div", { staticClass: "mainContainer" }, [
          _c("div", { staticClass: "warView" }, [
            _c("span", [
              _vm._v(
                "本信息用于国家电子税务局第三方开票服务报备注册，配置完成之后请点击登录按钮进行登录"
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "stepView" },
            [
              _c(
                "el-steps",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    active: _vm.stepInsecond ? 2 : 1,
                    "finish-status": "success",
                    simple: "",
                  },
                },
                [
                  _c("el-step", { attrs: { title: "税局报备" } }),
                  _c("el-step", { attrs: { title: "业务配置" } }),
                  _c("el-step", { attrs: { title: "审核并登录" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticStyle: { height: "16px" } }),
          _vm.loaded && _vm.stepInsecond
            ? _c("div", { staticClass: "secondView" }, [
                _c(
                  "div",
                  { staticClass: "linkText", on: { click: _vm.openLinkUrl } },
                  [_vm._v(_vm._s(_vm.linkUrl))]
                ),
                _c(
                  "div",
                  { staticClass: "copyText", on: { click: _vm.openLinkUrl } },
                  [_vm._v("复制并打开")]
                ),
                _c(
                  "div",
                  { staticClass: "infoView" },
                  [
                    _c(
                      "el-form",
                      { attrs: { "label-width": "120px" } },
                      [
                        _c("el-form-item", { attrs: { label: "销方名称" } }, [
                          _c("div", { staticClass: "infoInLine" }, [
                            _c("div", { staticClass: "infoDetail" }, [
                              _vm._v(" " + _vm._s(_vm.compForm.shopName) + " "),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "infoCopy",
                                on: {
                                  click: function ($event) {
                                    return _vm._copy(_vm.compForm.shopName)
                                  },
                                },
                              },
                              [_vm._v("复制")]
                            ),
                          ]),
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "登录账号",
                              prop: "sellerTaxpayerId",
                            },
                          },
                          [
                            _c("div", { staticClass: "infoInLine" }, [
                              _c("div", { staticClass: "infoDetail" }, [
                                _vm._v(_vm._s(_vm.compForm.mobile)),
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass: "infoCopy",
                                  on: {
                                    click: function ($event) {
                                      return _vm._copy(_vm.compForm.mobile)
                                    },
                                  },
                                },
                                [_vm._v("复制")]
                              ),
                            ]),
                          ]
                        ),
                        _c("el-form-item", { attrs: { label: "密码" } }, [
                          _c("div", { staticClass: "infoInLine" }, [
                            _c("div", { staticClass: "infoDetail" }, [
                              _vm._v("88888888"),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "infoCopy",
                                on: {
                                  click: function ($event) {
                                    return _vm._copy("88888888")
                                  },
                                },
                              },
                              [_vm._v("复制")]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "descView" }, [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          _vm.descDialogShow = true
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-thumb" }),
                      _c("span", [_vm._v("点击查看配置文件")]),
                    ]
                  ),
                  _c("div", { staticStyle: { height: "24px" } }),
                ]),
              ])
            : _vm.loaded && !_vm.stepInsecond
            ? _c("div", { staticClass: "lineView" }, [
                _c(
                  "div",
                  { staticClass: "eachView" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "compForm",
                        attrs: {
                          "label-width": "120px",
                          rules: _vm.rules,
                          required: "",
                          model: _vm.compForm,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "企业名称" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "20vw" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.compForm.shopName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.compForm, "shopName", $$v)
                                },
                                expression: "compForm.shopName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "企业税号",
                              prop: "sellerTaxpayerId",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "20vw" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.compForm.sellerTaxpayerId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.compForm,
                                    "sellerTaxpayerId",
                                    $$v
                                  )
                                },
                                expression: "compForm.sellerTaxpayerId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "税务机关名称",
                              prop: "taxAuthoritiesName",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "20vw" },
                                attrs: { filterable: "", "value-key": "code" },
                                on: { change: _vm.taxIdDidChange },
                                model: {
                                  value: _vm.compForm.taxAuthoritiesName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.compForm,
                                      "taxAuthoritiesName",
                                      $$v
                                    )
                                  },
                                  expression: "compForm.taxAuthoritiesName",
                                },
                              },
                              _vm._l(_vm.taxOrgList, function (item, index) {
                                return _c("el-option", {
                                  key: item.code,
                                  attrs: { label: item.name, value: item },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "税务机关代码", required: "" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "20vw" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.compForm.taxAuthoritiesCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.compForm,
                                    "taxAuthoritiesCode",
                                    $$v
                                  )
                                },
                                expression: "compForm.taxAuthoritiesCode",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "地区编码", prop: "zipCode" } },
                          [
                            _c("el-autocomplete", {
                              staticStyle: { width: "20vw" },
                              attrs: {
                                placeholder: "请输入",
                                "fetch-suggestions": _vm.querySearchZipCode,
                              },
                              on: { select: _vm.handleSelectZipCode },
                              model: {
                                value: _vm.compForm.zipCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.compForm, "zipCode", $$v)
                                },
                                expression: "compForm.zipCode",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "开票人手机号", prop: "mobile" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "20vw" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.compForm.mobile,
                                callback: function ($$v) {
                                  _vm.$set(_vm.compForm, "mobile", $$v)
                                },
                                expression: "compForm.mobile",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticStyle: { width: "16px" } }),
                _c(
                  "div",
                  { staticClass: "eachView" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "personForm",
                        attrs: {
                          "label-width": "120px",
                          model: _vm.personForm,
                          rules: _vm.personRules,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "纳税人类型",
                              prop: "taxPayerType",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "20vw" },
                                model: {
                                  value: _vm.personForm.taxPayerType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.personForm,
                                      "taxPayerType",
                                      $$v
                                    )
                                  },
                                  expression: "personForm.taxPayerType",
                                },
                              },
                              _vm._l(
                                _vm.taxPayerTypeList,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.codeText,
                                      value: item.codeValue,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "组织类型", prop: "orgType" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "20vw" },
                                model: {
                                  value: _vm.personForm.orgType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.personForm, "orgType", $$v)
                                  },
                                  expression: "personForm.orgType",
                                },
                              },
                              _vm._l(_vm.orgTypeList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.codeText,
                                    value: item.codeValue,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "开票业务" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "20vw" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.personForm.businessName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personForm, "businessName", $$v)
                                },
                                expression: "personForm.businessName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "税控设备类型",
                              prop: "taxDriverType",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "20vw" },
                                model: {
                                  value: _vm.personForm.taxDriverType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.personForm,
                                      "taxDriverType",
                                      $$v
                                    )
                                  },
                                  expression: "personForm.taxDriverType",
                                },
                              },
                              _vm._l(
                                _vm.taxDriverTypeList,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.codeText,
                                      value: item.codeValue,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "用户名", prop: "username" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "20vw" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.personForm.username,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personForm, "username", $$v)
                                },
                                expression: "personForm.username",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "开户行", prop: "sellerBank" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "20vw" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.personForm.sellerBank,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personForm, "sellerBank", $$v)
                                },
                                expression: "personForm.sellerBank",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "银行账号", prop: "sellerAccount" },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "20vw" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.personForm.sellerAccount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personForm, "sellerAccount", $$v)
                                },
                                expression: "personForm.sellerAccount",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "注册电话", prop: "sellerTel" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "20vw" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.personForm.sellerTel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personForm, "sellerTel", $$v)
                                },
                                expression: "personForm.sellerTel",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "注册地址", prop: "areaName" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "6vw" },
                                attrs: { placeholder: "请选择省" },
                                on: { change: _vm.selectedHandle },
                                model: {
                                  value: _vm.personForm.provinceName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.personForm,
                                      "provinceName",
                                      $$v
                                    )
                                  },
                                  expression: "personForm.provinceName",
                                },
                              },
                              _vm._l(_vm.provinceData, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.rname, value: item.id },
                                })
                              }),
                              1
                            ),
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  "margin-left": "1vw",
                                  width: "6vw",
                                },
                                attrs: { placeholder: "请选择市" },
                                on: { change: _vm.selectedHandleCity },
                                model: {
                                  value: _vm.personForm.cityName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.personForm, "cityName", $$v)
                                  },
                                  expression: "personForm.cityName",
                                },
                              },
                              _vm._l(_vm.cityData, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.rname, value: item.id },
                                })
                              }),
                              1
                            ),
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  "margin-left": "1vw",
                                  width: "6vw",
                                },
                                attrs: { placeholder: "请选择区" },
                                on: { change: _vm.selectedHandleArea },
                                model: {
                                  value: _vm.personForm.areaName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.personForm, "areaName", $$v)
                                  },
                                  expression: "personForm.areaName",
                                },
                              },
                              _vm._l(_vm.areaData, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.rname, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "address" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "20vw" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.personForm.address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personForm, "address", $$v)
                                },
                                expression: "personForm.address",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "btnView" },
                      [
                        _vm.isSubmit
                          ? _c("el-button", { attrs: { type: "info" } }, [
                              _vm._v("已提交，请耐心等待"),
                            ])
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.submitAction },
                              },
                              [_vm._v("提交注册申请")]
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticStyle: { height: "24px" } }),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "示例",
            visible: _vm.descDialogShow,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.descDialogShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "tips-list" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "text text-a" }, [
                _c("span", [_vm._v("第一步")]),
              ]),
              _c("div", { staticClass: "text" }, [_vm._v(" 登录销项开票 ")]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "text text-a" }, [
                _c("span", [_vm._v("第二步")]),
              ]),
              _c("div", { staticClass: "text" }, [
                _vm._v(" 全电设置>开票业务配置>添加配置 "),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "text text-a" }, [
                _c("span", [_vm._v("第三步 ")]),
              ]),
              _c("div", { staticClass: "text" }, [_vm._v(" 输入接入配置 ")]),
            ]),
          ]),
          _c("div", { staticClass: "tips-img" }, [
            _c("div", { staticClass: "img" }, [
              _c("img", { attrs: { src: _vm.desc1, alt: "" } }),
            ]),
            _c("div", { staticClass: "img" }, [
              _c("img", { attrs: { src: _vm.desc2, alt: "" } }),
            ]),
            _c("div", { staticClass: "img" }, [
              _c("img", { attrs: { src: _vm.desc3, alt: "" } }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }