var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "classification",
      attrs: {
        "element-loading-text": "拼命加载中",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)",
      },
    },
    [
      _c(
        "div",
        { staticClass: "eachRow" },
        [
          _vm._m(0),
          _c("el-divider"),
          _vm.form.status == "SY_SOLDOUT"
            ? _c("div", [
                _c("span", [_vm._v(" 下架原因 ")]),
                _c(
                  "div",
                  { staticStyle: { padding: "10px 0", width: "600px" } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        value: _vm.form.checkReason,
                        disabled: "",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.form.status == "FAIL"
            ? _c("div", [
                _c("span", [_vm._v(" 驳回原因 ")]),
                _c(
                  "div",
                  { staticStyle: { padding: "10px 0" } },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", disabled: "" },
                      model: {
                        value: _vm.form.rejectReason,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "rejectReason", $$v)
                        },
                        expression: "form.rejectReason",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 30 } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    disabled: _vm.disabled,
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "ERP编码" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "请输入" },
                            model: {
                              value: _vm.form.medicineCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "medicineCode", $$v)
                              },
                              expression: "form.medicineCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "teshu" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.form.beSynPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "beSynPrice", $$v)
                                },
                                expression: "form.beSynPrice",
                              },
                            },
                            [_vm._v("自动同步价格（ERP系统对接后生效）")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "teshu" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              on: { change: _vm.changeBeSynStock },
                              model: {
                                value: _vm.form.beSynStock,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "beSynStock", $$v)
                                },
                                expression: "form.beSynStock",
                              },
                            },
                            [_vm._v("自动同步库存（ERP系统对接后生效）")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { staticClass: "teshu" }, [
                        _c(
                          "div",
                          { staticClass: "siwtchView" },
                          [
                            _c("el-switch", {
                              staticClass: "switchStyle",
                              attrs: {
                                size: "small",
                                disabled:
                                  _vm.form.status == "AWAIT" ||
                                  _vm.form.status == "FAIL",
                                "active-color": "#13ce66",
                                "inactive-color": "#ff4949",
                                "active-text": "上架",
                                "inactive-text": "下架",
                              },
                              on: { change: _vm.change },
                              model: {
                                value: _vm.shangxiajia,
                                callback: function ($$v) {
                                  _vm.shangxiajia = $$v
                                },
                                expression: "shangxiajia",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "价格/元", prop: "salePrice" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.form.beSynPrice,
                              type: "number",
                              size: "small",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.form.salePrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "salePrice", $$v)
                              },
                              expression: "form.salePrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "市场价/元" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.form.beSynPrice,
                              type: "number",
                              size: "small",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.form.marketPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "marketPrice", $$v)
                              },
                              expression: "form.marketPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "建议零售价/元" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.form.beSynPrice,
                              type: "number",
                              size: "small",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.form.purchasePrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "purchasePrice", $$v)
                              },
                              expression: "form.purchasePrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "有效期至", prop: "expireTime" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "small",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              type: "datetime",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.form.expireTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "expireTime", $$v)
                              },
                              expression: "form.expireTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "生产日期", prop: "producedTime" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "small",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              type: "datetime",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.form.producedTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "producedTime", $$v)
                              },
                              expression: "form.producedTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "起批量", prop: "startWholesale" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "calc(100% - 100px)" },
                            attrs: {
                              type: "number",
                              size: "small",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.form.startWholesale,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "startWholesale", $$v)
                              },
                              expression: "form.startWholesale",
                            },
                          }),
                          _c(
                            "el-checkbox",
                            {
                              staticStyle: { "margin-left": "16px" },
                              model: {
                                value: _vm.form.useTimes,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "useTimes", $$v)
                                },
                                expression: "form.useTimes",
                              },
                            },
                            [_vm._v("倍数购买")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单位", prop: "goodsSkuUnit" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { size: "small", placeholder: "请选择" },
                              model: {
                                value: _vm.form.goodsSkuUnit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "goodsSkuUnit", $$v)
                                },
                                expression: "form.goodsSkuUnit",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "总库存", prop: "goodsSkuInventory" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.form.beSynStock,
                              type: "number",
                              size: "small",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.form.goodsSkuInventory,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "goodsSkuInventory", $$v)
                              },
                              expression: "form.goodsSkuInventory",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单个药店采购上限" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "calc(100% - 75px)" },
                            attrs: {
                              disabled: _vm.purchaseUpperLimit,
                              type: "number",
                              size: "small",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.form.purchaseUpperLimit,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "purchaseUpperLimit", $$v)
                              },
                              expression: "form.purchaseUpperLimit",
                            },
                          }),
                          _c(
                            "el-checkbox",
                            {
                              staticStyle: { "margin-left": "16px" },
                              on: { change: _vm.changeBox },
                              model: {
                                value: _vm.purchaseUpperLimit,
                                callback: function ($$v) {
                                  _vm.purchaseUpperLimit = $$v
                                },
                                expression: "purchaseUpperLimit",
                              },
                            },
                            [_vm._v("不限")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "上限刷新周期" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              size: "small",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.form.upperLimitRefreshCycle,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "upperLimitRefreshCycle",
                                  $$v
                                )
                              },
                              expression: "form.upperLimitRefreshCycle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "上限启用日期" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "small",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              type: "datetime",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.form.upperLimitStartTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "upperLimitStartTime", $$v)
                              },
                              expression: "form.upperLimitStartTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "装箱量" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              size: "small",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.form.goodsSkuBox,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "goodsSkuBox", $$v)
                              },
                              expression: "form.goodsSkuBox",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品标题", prop: "goodsSkuTitle" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "请输入" },
                            model: {
                              value: _vm.form.goodsSkuTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "goodsSkuTitle", $$v)
                              },
                              expression: "form.goodsSkuTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "SKU名称" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "请输入" },
                            model: {
                              value: _vm.form.goodsSkuName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "goodsSkuName", $$v)
                              },
                              expression: "form.goodsSkuName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品批号" } },
                        [
                          _c("el-input", {
                            attrs: {
                              onkeyup:
                                "this.value=this.value.replace(/[^\\w_]/g,'');",
                              size: "small",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.form.batchNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "batchNumber", $$v)
                              },
                              expression: "form.batchNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticStyle: { display: "flex" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "14px", "margin-top": "10px" },
                  },
                  [_vm._v("批件/药检报告等：")]
                ),
                _c(
                  "el-upload",
                  {
                    staticClass: "upload-demo",
                    attrs: {
                      disabled: _vm.disabled,
                      action:
                        "https://gateway.cbyyk.com/product/goodsbase/upload",
                      multiple: "",
                      "on-preview": _vm.preview,
                      "on-remove": _vm.handleChange,
                      "on-success": _vm.successUpload,
                      "on-error": _vm.errorUpload,
                      accept: ".jpg,.jpeg,.png,.pdf",
                      "file-list": _vm.fileList,
                    },
                  },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "small", type: "primary" } },
                      [_vm._v("点击上传")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip",
                      },
                      [_vm._v("上传pdf或图片，且不超过10M")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { display: "flex", "margin-left": "40px" } },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "14px", "margin-top": "10px" },
                  },
                  [_vm._v("商品图片：")]
                ),
                _c(
                  "div",
                  { staticStyle: { "padding-top": "8px" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.imgRegion,
                          callback: function ($$v) {
                            _vm.imgRegion = $$v
                          },
                          expression: "imgRegion",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("使用平台图片"),
                        ]),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("上传图片"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "imgUpload",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.imgRegion == 2,
                            expression: "imgRegion == 2",
                          },
                        ],
                        staticStyle: { position: "relative", top: "0px" },
                        attrs: { isEdit: _vm.disabled },
                        model: {
                          value: _vm.imageList,
                          callback: function ($$v) {
                            _vm.imageList = $$v
                          },
                          expression: "imageList",
                        },
                      },
                      [
                        _c("template", { slot: "tip" }, [
                          _c(
                            "p",
                            {
                              staticClass: "el-upload__tip",
                              staticStyle: { margin: "10px 0 14px" },
                            },
                            [
                              _vm._v(
                                " 备注:图片格式为jpg或png，建议尺寸800*800，图片大小不得超过2M; "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.disabled,
                      expression: "!disabled",
                    },
                  ],
                  staticStyle: { width: "160px" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit },
                },
                [_vm._v("保存并提交")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "160px" },
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
          _vm.showViewer
            ? _c("el-image-viewer", {
                attrs: { "on-close": _vm.closeViewer, "url-list": [_vm.url] },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titleView" }, [
      _c("div", { staticClass: "rowLine" }),
      _c("span", { staticClass: "eachRowTitle" }, [_vm._v("SKU信息")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }