<template>
  <div class="dialog">
    <el-dialog
      title="设置税率"
      :visible.sync="visible"
      :lock-scroll="true"
      width="618px"
      :before-close="handleClose"
    >
      <el-form
        ref="formTool"
        :model="formTool"
        label-width="180px"
        :rules="rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="发票类型">
              <el-select
                v-model="formTool.invoiceType"
                size="small"
                style="width: 300px"
              >
                <el-option
                  :label="item.codeText"
                  :value="item.codeValue"
                  v-for="(item, index) in invoiceTypeList"
                  :key="index"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="projectName" label="项目名称">
              <!-- <el-input
                v-model="formTool.projectName"
                clearable
                size="small"
                style="width: 300px"
              /> -->
              <el-autocomplete
                v-model="formTool.projectName"
                class="formInput"
                placeholder="填写项目名称"
                :fetch-suggestions="querySearchAsync"
                value-key="name"
                  style="width: 300px"
                @select="handleSelect"
                size="small"
              >
                <template slot-scope="{ item }">
                  <div class="name" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                      {{ item.spmc }}
                    </div>
                </template>
              </el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="taxRate" label="税率/征收率">
              <!-- <el-input
                v-model="formTool.taxRate"
                clearable
                size="small"
                :disabled="false"
                style="width: 300px"
              /> -->
              <el-select
                v-model="formTool.taxRate"
                size="small"
                style="width: 300px"
              >
                <el-option
                  :label="item.label"
                  :value="item.value"
                  v-for="(item, index) in rateList"
                  :key="index"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="btnBox">
          <el-button size="small" @click="handleClose">取消</el-button>
          <el-button type="primary" size="small" @click="submit"
            >确定</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
// import { updateRateRule } from "@/api/externalSalesRule";
import {
  getInvoiceType,
  updateElephantInvoice,
  getElephantInvoiceById,
  invoiceTitleRateTypes,
} from "@/api/invoice";

export default {
  components: {},
  props: {},
  computed: {
    visible() {
      return this.dialogShow;
    },
  },
  data() {
    return {
      formTool: {
        id: "",
        projectName: "",
        taxRate: "",
        invoiceType: "",
        productClassesCode: "",//  编码
      },
      rules: {
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        taxRate: [{ required: true, message: "请输入税率", trigger: "blur" }],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "change" },
        ],
      },
      invoiceTypeList: [],
      dialogShow: false,
      taxList: [],
      rateList: [
        // { value: 0, label: "免税" },
        { value: 0.01, label: "1%" },
        // { value: 0.015, label: "1.5%" },
        { value: 0.03, label: "3%" },
        { value: 0.04, label: "4%" },
        { value: 0.05, label: "5%" },
        { value: 0.06, label: "6%" },
        { value: 0.09, label: "9%" },
        { value: 0.1, label: "10%" },
        { value: 0.11, label: "11%" },
        { value: 0.12, label: "12%" },
        { value: 0.13, label: "13%" },
        { value: 0.16, label: "16%" },
        { value: 0.17, label: "17%" },
      ],
    };
  },
  created() {},
  methods: { 
    querySearchAsync(queryString, cb) {
      if (queryString === "") {
        cb([]);
        return;
      }
      invoiceTitleRateTypes({ spmcStr: queryString }).then((res) => {
        console.log(res);
        var restaurants = res;
        var results = queryString
          ? restaurants.filter(this.createStateFilter(queryString))
          : restaurants;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          console.log(results);
          cb(results);
        }, 3000 * Math.random());
      });
    },

    createStateFilter(queryString) {
      return (state) => {
        // console.log(state);
        // spmc --- 搜索的字段名
        return (
          state.spmc.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    handleSelect(e) {
      // console.log(e);
      this.formTool.projectName = e.spmc;
      this.formTool.productClassesCode = e.spbm;
      this.rateList.map((item) => {
        if (item.label === e.zzssl) {
          this.formTool.taxRate = item.value;
        }
      });
    },
    // 获取发票类型
    async getInvoiceTypeAction() {
      const resp = await getInvoiceType();
      if (resp) {
        let tmp = [];
        for (const key in resp) {
          if (Object.hasOwnProperty.call(resp, key)) {
            const element = resp[key];
            tmp.push({
              codeValue: key,
              codeText: element,
            });
          }
        }
        this.invoiceTypeList = tmp;
      }
    },
    handleClose() {
      this.dialogShow = false;
    },

    // 提交 设置默认税率
    submit() {
      let params = {
        ...this.formTool,
      };
      this.$refs.formTool.validate((valid) => {
        if (valid) {
          updateElephantInvoice(params).then((res) => {
            if (res) {
              this.$message.success("设置成功");
              this.$emit("getList");
              this.dialogShow = false;
            }
          });
        }
      });
    },

    init(row, rate, promoteRate) {
      console.log(row,'---row');
      this.dialogShow = true;
      this.formTool.id = row.id;
      if (row.projectName) {
        this.formTool.projectName = row.projectName;
      }
      if (row.taxRate) {
        this.formTool.taxRate = row.taxRate;
      }
      if (row.invoiceType) {
        this.formTool.invoiceType = row.invoiceType;
      }
      this.getInvoiceTypeAction();
      // this.queryTaxList();
    },
  },
};
</script>
<style lang="scss">
.btnBox {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
