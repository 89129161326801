var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请确定要发布的商品",
            visible: _vm.dialogVisible,
            width: "918px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "txt-content" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    " 请确认商品信息与实际一致，以下信息在发布后不可修改 "
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "public-tip" },
                [
                  _c("el-image", {
                    staticClass: "small-icon",
                    attrs: { src: _vm.smallIcon },
                  }),
                  _c("span", [
                    _vm._v("该商品无需提交平台审核，可直接发布上架"),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "big-title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.detail ? _vm.detail.compGoodsBaseVo.name : "") +
                    " "
                ),
              ]),
              _c(
                "div",
                _vm._l(_vm.productInfos, function (item, index) {
                  return _c("div", { key: index, staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "small-tip commo-text-ideograph" },
                      [_vm._v(_vm._s(item.tip))]
                    ),
                    _vm._v(" ： "),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(" " + _vm._s(item.value) + " "),
                    ]),
                  ])
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "pic-content" }, [
              _c(
                "div",
                { staticClass: "img-frame" },
                [
                  _c("el-image", {
                    staticClass: "big-img",
                    attrs: {
                      src: _vm.detail ? _vm.detail.compGoodsBaseVo.image : "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "multi small pic" },
                _vm._l(
                  _vm.detail ? _vm.detail.compGoodsBaseVo.imageList : [],
                  function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "muti-frame" },
                      [
                        _c("el-image", {
                          staticClass: "multi-img",
                          attrs: { src: item },
                        }),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text", icon: "el-icon-warning-outline" },
                  on: { click: _vm.handleDetailError },
                },
                [_vm._v(" 商品纠错 ")]
              ),
              _c(
                "div",
                [
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: !_vm.detail },
                      on: {
                        click: function ($event) {
                          return _vm.handleConfirm()
                        },
                      },
                    },
                    [_vm._v("确认，下一步")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }