import request from '@/utils/request'

//首页左侧实时数据
export function adaption(data) {
  return request({
    url: "/statistic/settings/home/page/adaption",
    method: "post",
    data: data,
  });
}
