var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "leftPage" }, [
    _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "huizong" }, [_vm._v("今日运营汇总")]),
      _c("span", [_vm._v(_vm._s(_vm.nowDate))]),
      _c("span", [_vm._v(_vm._s(_vm.nowDay))]),
    ]),
    _c(
      "div",
      { staticClass: "topBox" },
      _vm._l(_vm.topBoxData, function (item, index) {
        return _c("div", { key: index, staticClass: "everyBox" }, [
          _c("div", { staticClass: "biaoti" }, [
            _vm._v(" " + _vm._s(item.label) + " "),
            index == 0
              ? _c("span", { staticStyle: { color: "#8c8c8c" } }, [
                  _vm._v("(元)"),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "number" }, [
            _vm._v(" " + _vm._s(item.value) + " "),
            index == 0
              ? _c("span", { staticStyle: { "font-size": "20px" } })
              : _vm._e(),
          ]),
          _c("div", { staticClass: "yesterday" }, [
            _vm._v(" 昨日 "),
            _c("span", [
              _vm._v(" " + _vm._s(item.yesterday) + " "),
              index == 0
                ? _c("span", { staticStyle: { "font-size": "12px" } })
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "ratio", staticStyle: { "font-weight": "500" } },
            [
              _vm._v(" 环比 "),
              _c(
                "span",
                { style: item.ratio >= 0 ? "color:#F5222D" : "color:#17A83C" },
                [
                  item.ratio >= 0
                    ? _c("img", {
                        staticStyle: { width: "8px" },
                        attrs: {
                          src: require("@/assets/shangzhang.png"),
                          alt: "",
                        },
                      })
                    : _c("img", {
                        staticStyle: { width: "8px" },
                        attrs: { src: require("@/assets/xiadie.png"), alt: "" },
                      }),
                  _vm._v(
                    " " +
                      _vm._s(item.ratio >= 0 ? "+" : "") +
                      " " +
                      _vm._s(item.ratio) +
                      " % "
                  ),
                ]
              ),
            ]
          ),
        ])
      }),
      0
    ),
    _c("div", { staticClass: "itemBox" }, [
      _c("div", { staticClass: "title" }, [_vm._v("订单中心")]),
      _c(
        "div",
        { staticClass: "mainBox" },
        _vm._l(_vm.firstBoxData, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "everyBox",
              style: { cursor: item.path ? "pointer" : "" },
              on: {
                click: function ($event) {
                  return _vm.tiaozhuan(item)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "everyTitle",
                  style: {
                    padding:
                      index == 0 || index == 7 ? "0 0 0 0px" : "0 0 0 40px",
                  },
                },
                [_vm._v(_vm._s(item.label) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "everyNumber",
                  style: {
                    color: `${item.color}`,
                    border: index == 6 ? "none" : "",
                    padding:
                      index == 0 || index == 7 ? " 0 0 0 0px" : "0 0 0 40px",
                  },
                },
                [_vm._v(" " + _vm._s(item.value))]
              ),
            ]
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "itemBox" }, [
      _c("div", { staticClass: "title" }, [_vm._v("客户中心")]),
      _c(
        "div",
        { staticClass: "mainBox" },
        _vm._l(_vm.secondBoxData, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "everyBox",
              style: { cursor: item.path ? "pointer" : "" },
              on: {
                click: function ($event) {
                  return _vm.tiaozhuan(item)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "everyTitle",
                  style: {
                    padding:
                      index == 0 || index == 7 ? "0 0 0 0px" : "0 0 0 40px",
                  },
                },
                [_vm._v(_vm._s(item.label) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "everyNumber",
                  style: {
                    color: `${item.color}`,
                    border: index == 6 ? "none" : "",
                    padding:
                      index == 0 || index == 7 ? " 0 0 0 0px" : "0 0 0 40px",
                  },
                },
                [_vm._v(" " + _vm._s(item.value))]
              ),
            ]
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "itemBox" }, [
      _c("div", { staticClass: "title" }, [_vm._v("商品中心")]),
      _c(
        "div",
        { staticClass: "mainBox" },
        _vm._l(_vm.thirdBoxData, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "everyBox",
              style: { cursor: item.path ? "pointer" : "" },
              on: {
                click: function ($event) {
                  return _vm.tiaozhuan(item)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "everyTitle",
                  style: {
                    padding:
                      index == 0 || index == 7 ? "0 0 0 0px" : "0 0 0 40px",
                  },
                },
                [_vm._v(_vm._s(item.label) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "everyNumber",
                  style: {
                    color: `${item.color}`,
                    border: index == 6 || index == 8 ? "none" : "",
                    padding:
                      index == 0 || index == 7 ? " 0 0 0 0px" : "0 0 0 40px",
                  },
                },
                [_vm._v(" " + _vm._s(item.value))]
              ),
            ]
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "itemBox" }, [
      _c("div", { staticClass: "title" }, [_vm._v("营销推广")]),
      _c(
        "div",
        { staticClass: "mainBox" },
        _vm._l(_vm.fourthBoxData, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "everyBox",
              style: {
                cursor: item.path ? "pointer" : "",
                margin: index == 7 || index == 8 ? "20px 0 0 0" : "",
              },
              on: {
                click: function ($event) {
                  return _vm.tiaozhuan(item)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "everyTitle",
                  style: {
                    padding:
                      index == 0 || index == 7 ? "0 0 0 0px" : "0 0 0 40px",
                  },
                },
                [_vm._v(_vm._s(item.label) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "everyNumber",
                  style: {
                    color: `${item.color}`,
                    border: index == 6 || index == 8 ? "none" : "",
                    padding:
                      index == 0 || index == 7 ? " 0 0 0 0px" : "0 0 0 40px",
                  },
                },
                [
                  _vm._v(" " + _vm._s(item.value)),
                  item.value2
                    ? _c("span", [_vm._v("/" + _vm._s(item.value2))])
                    : _vm._e(),
                ]
              ),
              item.label != "推广商品数" &&
              item.label != "推广合作/待审核" &&
              item.label != "单品包邮商品数"
                ? _c(
                    "div",
                    {
                      staticClass: "day",
                      style: {
                        border: index == 6 || index == 8 ? "none" : "",
                        padding:
                          index == 0 || index == 7
                            ? " 0 0 0 0px"
                            : "0 0 0 40px",
                      },
                    },
                    [_vm._v(" 昨日 " + _vm._s(item.yesterday) + " ")]
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }