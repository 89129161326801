<template>
    <div class="classfication">
        <el-scrollbar style="height: calc(100vh - 148px);">
            <div class="topView">
                <div style="padding:10px 0px;font-weight: 600;font-size: 18px;">蓝字发票开具</div>
                <div class="topViewForm">
                    <div class="invoiceView" v-for="item, index in invoiceArr">
                        <div class="titleView">
                            <div class="titleViewLine"></div>
                            <span>{{ item.title }}</span>
                        </div>
                        <div class="formView" :class="index == invoiceArr.length - 1 ? 'hideBorder' : ''">
                            <el-form >
                                <el-form-item label="名称" label-width="150px">
                                    <el-select 
                                        v-model="item.sellerName" 
                                        size="small"
                                        style="width:90%"
                                        :disabled="item.type == 'saler'" 
                                        :remote-method="remoteMethod"
                                        :loading="loading"
                                        filterable
                                        remote
                                        reserve-keyword
                                        clearable
                                        @change="changeShop"
                                    >
                                        <el-option 
                                            v-for="item in options"
                                            :key="item.shopId"
                                            :label="item.shopName"
                                            :value="item.shopId"
                                        />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label-width="150px" >
                                    <template slot="label">
                                        <div class="formItem">
                                            <span>统一社会信用代码纳税人识别号</span>
                                        </div>
                                    </template>
                                    <el-input v-model="item.sellerTaxpayerId" size="small" style="width:90%;" disabled />
                                </el-form-item>
                                <el-form-item label="地址" label-width="150px">
                                    <el-input v-model="item.detailAddress" size="small" style="width:90%" disabled />
                                </el-form-item>
                                <el-form-item label="电话" label-width="150px">
                                    <el-input v-model="item.sellerTel" size="small" style="width:90%" disabled />
                                </el-form-item>
                                <el-form-item label="开户行" label-width="150px">
                                    <el-input v-model="item.sellerBank" size="small" style="width:90%" disabled />
                                </el-form-item>
                                <el-form-item label="账号" label-width="150px">
                                    <el-input v-model="item.sellerAccount" size="small" style="width:90%" disabled />
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
            <div >
                <div class="centerView" >
                    <div class="topTitle">
                        <div class="title">发票明细</div>
                        <el-date-picker
                            style="width: 150px;"
                            v-model="monthValue"
                            type="month"
                            placeholder="选择月"
                            size="small"
                            @change="changeMonth"
                        >
                        </el-date-picker>
                        <span class="titleMoney">未开票金额:{{monthTotal}}</span>
                    </div>
                    <div class="centerForm" >
                        <el-table ref="multipleTable" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
                            <el-table-column type="selection" width="55" align="center">
                            </el-table-column>
                            <el-table-column label="项目名称" width="300">
                                <template slot-scope="scope">{{ scope.row.projectName }}</template>
                            </el-table-column>
                            <el-table-column prop="specification" label="型号规格">
                            </el-table-column>
                            <el-table-column prop="unit" label="单位" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="number" label="数量" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column  label="单价（含税）" show-overflow-tooltip>
                                <template slot-scope="scope">￥{{ fixtoTwo(scope.row.price )}}</template>
                            </el-table-column>
                            <el-table-column prop="index" label="全额（含税）" show-overflow-tooltip>
                                <template slot-scope="scope">￥{{ fixtoTwo(scope.row.payeeAmount )}}</template>
                            </el-table-column>
                            <el-table-column prop="index" label="税率/征收率" show-overflow-tooltip>
                                <template slot-scope="scope">{{ getTaxtRate( scope.row.taxRate) }}%</template>
                            </el-table-column>
                            <el-table-column prop="index" label="税额" show-overflow-tooltip>
                                <template slot-scope="scope">￥{{ fixtoTwo(scope.row.taxAmount )}}</template>
                            </el-table-column>
                        </el-table>
                        <Pagination :total="page.total"
                        :page.sync="page.current"
                        :limit.sync="page.size"
                        @pagination="changePage" />
                    </div>
                    <div class="centerFooter">
                        <div class="footerText">价格合计（大写）：</div>
                        <div class="footerText priceRed">{{toChies(selectTotal)}}</div>
                        <div style="width: 16px;"></div>
                        <div class="footerText">合计税额：</div>
                        <div class="footerText priceRed">¥{{selectTax}}</div>
                    </div>
                </div>
                <div class="centerView">
                    <div style="padding:10px 0px;font-weight: 600;font-size: 18px;">备注信息</div>
                    <div class="renarkInput">
                        <el-input type="textarea"  maxlength="200"  placeholder="请输入内容" v-model="remark"  :autosize="{ minRows:4, maxRows: 8}"></el-input>
                    </div>
                </div>
                <div class="centerView" >
                    <!-- <div style="padding:10px 0px;font-weight: 600;font-size: 18px;">经办人信息</div>
                    <div class="inputContainer">
                        <div class="inputView">
                            <span>用户名</span>
                            <el-input v-model="form.remark" size="small" style="width:90%" />
                        </div>
                        <div class="inputView">
                            <span>国籍(地区)</span>
                            <el-input v-model="form.remark" size="small" style="width:90%" />
                        </div>
                        <div class="inputView">
                            <span>证件号码</span>
                            <el-input v-model="form.remark" size="small" style="width:90%" />
                        </div>
                        <div class="inputView">
                            <span>自然人纳税人识别</span>
                            <el-input v-model="form.remark" size="small" style="width:90%" />
                        </div>
                        <div class="inputView">
                            <span>证件类型</span>
                            <el-input v-model="form.remark" size="small" style="width:90%" />
                        </div>
                    </div> -->
                    <div class="centerFooter">
                        <!-- <el-button type="default" plain style="width: 100px;">保存草稿</el-button>
                        <el-button type="default" plain style="width: 100px;">预览发票</el-button> -->
                        <el-button type="primary" style="width: 100px;" @click="goAddTax">发票开具</el-button>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { getShopsUnderShop ,getShopInfoByShopId, getInvoiceListBySellerIdAndPayerId, getInvoiceTotalBySellerIdAndPayerId, doInvoice,cominvoicetitleinfo } from "@/api/invoice";
import { currentDetails } from "@/api/myQualification/index";
import _ from "lodash";
import dayjs from "dayjs";
export default {
    components: { Pagination },
    data() {
        return {
            invoiceArr: [
                { type: 'buyer', title: '购买方' },
                { type: 'saler', title: '销售方',sellerTaxpayerId:'' },
            ],
            tableData: [],
            remark: '',
            shops:[],
            options:[],
            loading:false,
            monthValue:dayjs().format('YYYY-MM'),
            monthTotal:'0',
            page:{
                size:10,
                current:1,
                total:0
            },
            selectList:[],
            selectTotal:0,
            selectTax:0
        }
    },
    mounted() {
        this.getShops()
        this.getUserShopInfo()
    },
    methods: {
        /**
         * 发票开具
         * @param {*} e 
         */
        async goAddTax(){
            const {invoiceType} = this.$route.query
            if (!this.selectList.length) {
                this.$message.warning('请选择开票数据')
                return
            }
            let tmp = []
            this.selectList.forEach((e)=>{
                tmp.push(e.id)
            })
            const obj = {
                itemIdSet:tmp,
                totalAmount: this.selectTotal,
                saleNum: this.invoiceArr[1].sellerTaxpayerId,
                companyName: this.invoiceArr[1].sellerName,
                customerSaleNum: this.invoiceArr[0].sellerTaxpayerId,
                customerName: this.invoiceArr[0].sellerName,
                customerAddress: this.invoiceArr[0].sellerAddress,
                customerTel: this.invoiceArr[0].sellerTel,
                customerBank: this.invoiceArr[0].sellerBank,
                customerAccount: this.invoiceArr[0].sellerAccount,
                // customerType: this.invoiceArr[0].,
                // player: "",
                invoiceType: invoiceType,
                remark: this.remark
            }
            await doInvoice(obj).then((resp)=>{
                if (resp) {
                    this.$message.success('开票成功')
                    this.getInvoiceList();
                }else{
                    this.$message.error('开票失败，重新登陆') 
                    sessionStorage.removeItem('sellerTaxpayerId')
                    // 开票失败，重新登陆
                }
            }).catch((err)=>{
                if (err && err.msg) {
                    this.$message.error(err.msg)
                    // 开票失败，重新登陆
                     sessionStorage.removeItem('sellerTaxpayerId')
                } 
            })
          
        },
        changeShop(e){
            if (!e) {
                return
            }
            this.invoiceArr[0].sellerTaxpayerId = ''; 
            this.invoiceArr[0].sellerAddress = '';
            this.invoiceArr[0].sellerTel = '';
            this.invoiceArr[0].sellerBank = '';
            this.invoiceArr[0].sellerAccount = '';
            this.invoiceArr[0].sellerShopId= e;
            // console.log(e)
            this.tableData = [];
            // this.getInvoiceList();
            this.getSelfShopInfo(e,0)
            
        },
        remoteMethod(query) {
             if (query !== '') {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.options = this.shops.filter(item => {
                        return item.shopName.indexOf(query) > -1;
                    });
                }, 200);
            } else {
                this.options = [];
            }
        },
        async getInvoiceList(){
            if (!this.invoiceArr[0].sellerShopId) {
                return
            }
            const obj = {
                payeeShopId:  this.invoiceArr[1].sellerShopId,//收款方
                payerShopId: this.invoiceArr[0].sellerShopId,//付款方
                // payeeShopId:'1660492419589783554',
                // payerShopId:'1674351858247335937',
                year: dayjs(this.monthValue).format('YYYY'),
                mouth: Number(dayjs(this.monthValue).format('MM'))
            }
            let _this = this
            const moneyResp = await getInvoiceTotalBySellerIdAndPayerId(obj)
            if (moneyResp) {
                _this.monthTotal = moneyResp
            }else{
                _this.monthTotal = '0'
            }
            obj['current'] = this.page.current
            obj['size'] = this.page.size
            const resp = await getInvoiceListBySellerIdAndPayerId(obj)
            if (resp && resp.records && resp.records.length ) {
                _this.tableData = resp.records
                _this.page.total = resp.total
            }else{
                _this.tableData = []
            }
        },
        async getUserShopInfo (){
            const resp = await currentDetails()
            if (resp && resp.data) {
                this.sellerShopId = resp.data.id
                this.getSelfShopInfo(this.sellerShopId,1)
            }
        },
        async getSelfShopInfo(shopId,index){
            if (this.sellerShopId) {
                let flag = null
                this.shops.forEach(shop => {
                    if (shopId == shop.shopId) {
                        flag = shop.flag
                    }
                });
               
                const resp = flag == 0 ? await cominvoicetitleinfo(shopId) : await getShopInfoByShopId(shopId)
                // const resp = await cominvoicetitleinfo(shopId)
                if (resp) {
                    if (resp.data) {
                        const detailAddress = resp.data.recipientsAddress || '' 
                        Object.assign(this.invoiceArr[index],resp.data,{ detailAddress : detailAddress})
                        if (index == 0) {
                            this.getInvoiceList()
                        }    
                    }else{
                        const province = resp.province || '' 
                        const city = resp.city || '' 
                        const area = resp.area || '' 
                        const address = resp.address || '' 
                        Object.assign(this.invoiceArr[index],resp,{ detailAddress : province + city + area  + address})
                        if (index == 0) {
                            this.getInvoiceList()
                        }
                    }
                }else{
                    this.$message.warning('当前购买方未设置发票信息')
                }
            }
        },
        async getShops(){
            const resp = await getShopsUnderShop()
            if (resp) {
                this.shops = resp
            }
        },
        changeMonth(){
            this.getInvoiceList()
        },
        changePage () {
            this.getInvoiceList()
        },
        fixtoTwo(e){
            if (e) {
                return e.toFixed(2)
            }else{
                return '0.00'
            }
        },
        getTaxtRate(e){
            if (!e) {
                return '0'
            }else{
                return parseFloat(e)*100
            }
        },
        countTotalMoney(arr){
            let money = 0
            let tax = 0
            arr.forEach(v => {
                if (v.payeeAmount) {
                    money += parseFloat(v.payeeAmount)
                }
                if (v.taxAmount) {
                    tax += parseFloat(v.taxAmount)
                }
            });
            this.selectTotal = money.toFixed(2)
            this.selectTax = tax.toFixed(2)
        },
        handleSelectionChange(e){
            this.selectList = e
            this.countTotalMoney(e)
        },
        toChies(price) {
            let fraction = ['角', '分'];
            let digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
            let unit = [['圆', '万,', '亿,'], ['', '拾', '佰', '仟']];
            let head = price < 0 ? '欠' : '';
            price = Math.abs(price);
            let upper = '';
            for (let i = 0; i < fraction.length; i++) {
            upper += (digit[Math.floor(price * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
            }
            upper = upper || '';
            price = Math.floor(price);
            for (let i = 0; i < unit[0].length && price > 0; i++) {
            let p = '';
            for (let j = 0; j < unit[1].length && price > 0; j++) {
                p = digit[price % 10] + unit[1][j] + p;
                price = Math.floor(price / 10);
            }
            upper = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + upper;
            }
            return head + upper.replace(/(零.)*零圆/, '圆').replace(/(零.)+/g, '零').replace(/^$/, '零圆')
            // console.log(head + upper.replace(/(零.)*零圆/, '圆').replace(/(零.)+/g, '零').replace(/^整$/, '零圆整'))
 
      },
    }
}
</script>
<style lang="scss" scoped>
.topView {
    display: flex;
    background-color: #fff;
    padding: 16px;
    border-radius: 4px;
    flex-direction: column;

    .topViewForm {
        display: flex;
        flex-direction: row;
    }

    .invoiceView {
        display: flex;
        flex-direction: column;
        flex: 1;

        .titleView {
            display: flex;
            align-items: center;
            margin-left: 166px;
            height: 72px;

            .titleViewLine {
                width: 4px;
                height: 16px;
                background: #f5222d;
                border-radius: 1px;
                margin-right: 5px;
            }

            span {
                font-weight: 600;
                color: #262626;
                font-size: 16px;
            }
        }

        .formView {
            border-right: 1px #eee solid;
            padding-left: 16px;
        }

        .hideBorder {
            border-right: none;
        }

        .formItem {
            display: flex;
            align-items: center;
            line-height: 22px;
        }

    }
}

.centerView {
    display: flex;
    background-color: #fff;
    padding: 16px;
    border-radius: 4px;
    flex-direction: column;
    margin-top: 16px;
    .topTitle{
        display: flex;
        align-items: center;   
        padding: 16px 0px;
        .title{
            font-weight: 600;
            font-size: 18px;
            margin-right: 16px;
        }
        .titleMoney{
            font-size: 14px;
            color: #262626;
            margin-left: 16px;
        }
    }
    .centerForm {
        border: 1px solid #eee;
        border-radius: 4px;
        overflow: hidden;
    }

    .centerFooter {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 50px;

        .footerText {
            font-size: 16px;
            color: #595959;
        }

        .priceRed {
            color: var(--main-color);
            font-weight: 600;
        }
    }
    .inputContainer{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .inputView{
            display: flex;
            align-items: center;
            margin-right: 80px;
            height: 50px;
            span{
                width: 120px;
            }
        }
    }
    
}
</style>