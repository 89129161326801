<!-- 订单评论 -->
<template>
  <div>
    <el-dialog title="订单评论" :visible.sync="dialogVisible" width="50%" :rules="rules" :before-close="handleClose">
      <el-form size="small" :model="formData" class="demo-form-inline"> 
        <el-form-item label="评价" :label-width="labelWidth">
          <el-input  type="textarea" :rows="2" v-model.trim="formData.comment" style="width: 100%" maxlength="200"></el-input>
        </el-form-item>
        <el-form-item label="上传图片" :label-width="labelWidth">
          <ImgUpload v-model="imageList" :limit="5"></ImgUpload>
          <div>图片格式为jpg或png，图片大小不得超过5M</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitUpload()">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// comInvoiceTitleInfo
import { commentSave } from "@/api/businessManage";

import ImgUpload from "../imgUpload";
export default {
  components: { ImgUpload },
  props: {
    requestId: {
      type: String,
      default: () => "",
    },

    limit: {
      type: Number,
      default: () => 10,
    },
  },
  computed: {
    headers: function () {
      return {
        Authorization: "Bearer " + getToken(),
      };
    },
  },
  data() {
    return {
      colors:["#99A9BF", "#F7BA2A", "#FF9900"],
      labelWidth: '80px',
      disabled: false,
      orderCode: '',
      action: process.env.VUE_APP_BASE_API + "/product/goodsbase/upload",
      dialogVisible: false,
      imageList: [],
      formData: { comment: "" }, //评论内容
      rules: {
        ruleName: [{ required: true, message: "请输入评价", trigger: "blur" }],
      },
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    init(){
      this.formData.comment =''; 
      this.imageList =[]
    },
    submitUpload() { 
      const {
        comment
      } = this.formData;

      let imageUrl = this.imageList.join(',')
      let params = {
        comment,
        image: imageUrl,
        orderCode: this.orderCode,
        source: 2 //来源(1-大商城，2-卖家端)
      }  
      // console.log(params) 
      if (!comment) {
        this.$message.warning('请填写评论内容！')
        return
      }

      commentSave(params).then((res) => { 
        if (res.code == 0) {
          this.$message.success('提交成功')
           this.dialogVisible = false; 
        } else {
          this.$message.error(res.msg)
          this.handleClose()
        }
      }).catch((err) => {
       
        this.handleClose()
      })

    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    onSuccessUpload(res, file, fileList) {
      // console.log(res,'---')
      // console.log( this.fileList,' this.fileList---')
      this.fileLength++;
      // 这里需要根据你自己的接口返回数据格式和层级来自行修改
      if (res.data) {
        // 判断接口上传成功
        if (this.fileList.length < this.limit) {
          // 未超限时，把接口返回的图片url地址添加到fileList
          let datas = res.data;
          datas.name = res.data.fileName;
          this.fileList.push(datas);
        }
      } else {
        // 判断接口上传失败
        this.syncElUpload();
        this.$refs.uploadRef.clearFiles();
        this.$message({ type: "error", message: res.msg });
      }
      if (this.fileLength === fileList.length) {
        this.isUploading = false;
      }
    },
    success(res) {
      console.log(res);
    },
  },
};
</script>
<style scoped>
.list-t {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
}

.header {
  font-size: 16px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

::v-deep .el-rate__icon {
  font-size: 30px;
}

.label-list {
  display: flex;
  justify-content: space-between;
}
</style>
