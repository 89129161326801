var render = function render(_c, _vm) {
  return _c(
    "div",
    { staticStyle: { padding: "30px" } },
    [
      _c("el-alert", {
        attrs: { closable: false, title: "menu 1-2-2", type: "warning" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }