var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.timeCheck(_vm.order)
    ? _c(
        "div",
        { staticClass: "countDownView" },
        [
          _c(
            "el-statistic",
            {
              ref: "statistic",
              attrs: {
                format: _vm.timeFormat(_vm.order),
                value: _vm.timeValue(_vm.order),
                "time-indices": "",
                "value-style": { color: "#F99B0D", fontSize: "14px" },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "titleText",
                  attrs: { slot: "suffix" },
                  slot: "suffix",
                },
                [_vm._v(_vm._s(_vm.titleText(_vm.order)))]
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }