import request from '@/utils/request' 

// 全球找药 列表
export function getSeekDrug(data) {
    return request({
        url: `/order/seekdrugapply/payee/page`,
        method: 'get',
        params: data
    })
}

// 接单 
export function acceptSeekDrug(data) {
    return request({
        url: `/order/seekdrugapply/payee/accept`,
        method: 'put',
        data: data
    })
}
// 补款通知 
export function informSeekDrug(data) {
    return request({
        url: `/order/seekdrugapply/payee/inform`,
        method: 'put',
        data: data
    })
}

 