<template>
  <div class="content" @click="getChatInfo">
    <img src="@/assets/chatservice.png" alt="">
    <span>进入客服</span>
    <!-- <div class="inner"> -->
      <!-- <iframe :src="urlLink"
              style="width:680px;height:610px"
              frameborder="0"></iframe> -->
      <!-- <div class="right">
          <p class="itemTitle">我的订单</p> -->
      <!-- <div class="itemInner">
            <el-image :src="item.image||avater"></el-image>
            <div class="itemDesc">
              <p class="title"
                 style="">{{ item.name }}</p>
              <p>{{ item.specification }}</p>
              <p class="price"> <span style="font-size:12px">
                  ￥
                </span>
                {{ item.salePrice??'' }}</p>
              <p>{{ item.factory }}</p>
            </div>
          </div> -->
      <!-- </div> -->
    <!-- </div> -->
  </div>
</template>
<script>
import chatBg from "/public/img/chatBg.png"
import { doChatServices } from "@/api/chatRoom/chatServices"
import { getToken } from "@/utils/auth";
export default {
  data () {
    return {
      urlLink: '',
      chatBg: chatBg
    }
  },
  mounted () {
    // this.getChatInfo()
  },
  methods: {
    
    getChatInfo () {
      const data = {shopId :"" ,token :getToken(),personType:'1'}//必传店铺 id,客服传空 / 用户 token/ 用户类型 （1：商家 2：客户）
      doChatServices(data)
      // setTimeout(() => {
      //   childWindow.postMessage(data,targetUrl)
      // },1000);
      // getChatinfo().then(res => {
      //   if (res.code == 0) {
      //     this.urlLink = `http://localhost:5173/?appKey=${res.data.appKey}&fromAccount=${res.data.fromAccount}&fromToken=${res.data.fromToken}`
      //     window.open(this.urlLink,'_blank')
      //   }

      // })
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  background-color: #fff;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img{
    width: 66px;
    height: 66px;
    animation: chatImage 4s linear infinite;
  }
  span{
    margin-top: 10px;
    color: #666;
  }
  .inner {
    width: 728px;
    height: 658px;
    padding: 16px;
    border-radius: 4px;
    background-color: #fff;
  }

  // @keyframes chatImage {
  //   0%{
  //     transform: rotateY(0deg) ;
  //   }
  //   33%{
  //     transform:  rotateY(90deg) ;
  //   }
  //   66%{
  //     transform:  rotateY(180deg) ;
  //   }
  // }
}
</style>