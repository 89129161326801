import request from '@/utils/request'
import { Message } from 'element-ui'
// 查询提现明细
export function getMediCompanyWithDrawList(data) {
    return request({
      url: "/payment/incomeExpenditure/pageResult",
      method: "get",
      params:data
    });
  }
//   查询可提现订单
  export function getMediCompanyWithDrawOrderList(data) {
    return request({
      url: "/payment/incomeExpenditure/pageResultEnable",
      method: "get",
      params:data
    });
  }
// 提现
export function doWithdraw(data) {
    return request({
      url: "/payment/incomeExpenditure/withdrawal",
      method: "post",
      data
    });
  }
    
// 可提现金额
export function withdrawalMoney() {
  return request({
    url: "/payment/shopAmount/withdrawal",
    method: "get",
    params:{}
  });
}
    
// 部门申请数量
export function submitCnt() {
  return request({
    url: "/payment/incomeExpenditure/submitCnt",
    method: "get",
    params:{}
  });
}  
// 提现短信校验
export function withdrawalValida(data) {
  return request({
    url: "/payment/incomeExpenditure/withdrawalValida",
    method: "post",
    data
  });
}
// 部门提现申请列表
export function pageResultSubmit(data) {
  return request({
    url: "/payment/incomeExpenditure/pageResultSubmit",
    method: "get",
    params:data
  });
}  
// / 导出医药公司流水明细
export function downloadWithdrawList(data) {
  request({
    url: `/payment/incomeExpenditure/exportReport`,
    method: 'get',
    params:data,
    responseType: 'blob'
  }).then(response => {
    // 处理返回的文件流
    const blob = response.data
    if (blob && blob.size === 0) {
      Message.warning('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = '收支明细';
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  })
}

// 部门提现明细列表
export function getMediDeptWithDrawList(data) {
  return request({
    url: "/payment/deptIncomeExpenditure/pageResult",
    method: "get",
    params:data
  });
}
//   部门查询可提现订单
export function getMediDeptWithDrawOrderList(data) {
  return request({
    url: "/payment/deptIncomeExpenditure/pageResultEnable",
    method: "get",
    params:data
  });
}
//部门提现申请
export function deptIncomeExpenditureWithdrawal(data) {
  return request({
    url: "/payment/deptIncomeExpenditure/withdrawal",
    method: "post",
    data
  });
}
// 同意部门提现
export function doDeptWithdraw(data) {
  return request({
    url: "/payment/incomeExpenditure/agreewithdrawal",
    method: "post",
    data
  });
}
// 提现短信校验 部门
export function withdrawalValidaByDept(data) {
  return request({
    url: "/payment/incomeExpenditure/deptWithdrawalValida",
    method: "post",
    data
  });
}



// / 导出部门流水明细
export function downloadWithdrawListByDept(data) {
  request({
    url: `/payment/deptIncomeExpenditure/exportResult`,
    method: 'get',
    params:data,
    responseType: 'blob'
  }).then(response => {
    // 处理返回的文件流
    const blob = response.data
    if (blob && blob.size === 0) {
      Message.warning('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = '收支明细';
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  })
}
// 康贝开户-预注册
export function preRegister(data) {
  return request({
    url: "/payment/register/preRegister",
    method: "post",
    data
  });
}
// 数据字典-列表搜索
export function dicItemExtList(data) {
  return request({
    url: "/payment/kangbei/dicItemExtList",
    method: "post",
    data
  });
}
//   获取地区列表
export function cityAll(data) {
  return request({
    url: "/payment/kangbei/cityAll",
    method: "get",
    params:data
  });
}
// 支行查询
export function getBankCode(data) {
  return request({
    url: "/payment/kangbei/bankCode",
    method: "post",
    data
  });
}
//  康贝开户-绑定手机短信验证码
export function applyVerifyCode(data) {
  return request({
    url: "/payment/register/applyVerifyCode",
    method: "get",
    params:data
  });
}
// 康贝开户-绑定手机短信验证码验证
export function verifyCode(data) {
  return request({
    url: "/payment/register/verifyCode",
    method: "post",
    data
  });
}
// 康贝开户申请
export function openAcctApply(data) {
  return request({
    url: "/payment/register/openAcctApply",
    method: "post",
    data
  });
}
// 康贝开户-查询商户审核结果
export function queryAuditResult(data) {
  return request({
    url: "/payment/register/queryAuditResult",
    method: "post",
    data
  });
}
// 获取商铺康贝商户号
export function getShopMerchantNo(data) {
  return request({
    url: "/payment/shopKangbei/getShopMerchantNo",
    method: "get",
    params:data
  });
}
// 获取商铺康贝信息-暂存
export function getShopMerchantDetail(data) {
  return request({
    url: "/payment/shopKangbei/getShopMerchantDetail",
    method: "get",
    params:data
  });
}
// 更新康贝信息-暂存
export function shopMerchantDetail(data) {
  return request({
    url: "/payment/shopKangbei/shopMerchantDetail",
    method: "post",
    data
  });
}
