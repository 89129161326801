<template>
    <div class="dialog">
        <el-dialog :title="title" :visible.sync="visible" :lock-scroll="true" width="818px" :before-close="handleClose"
            append-to-body>
            <section>
                <div class="serach">
                    <el-form ref="formTool" :model="formTool" :inline="true">
                        <el-select v-model="formTool.goodsOneClassId" size="small" placeholder="商品一级分类" clearable
                            style="max-width:187px" @change="changeOneClassId">
                            <el-option v-for="item in goodsOneClassIdOptions" :key="item.id" :label="item.name"
                                :value="item.id" />
                        </el-select>
                        <el-select v-model="formTool.goodsTwoClassId" size="small" placeholder="商品二级分类" clearable
                            style="max-width:187px;margin-left:16px" @change="changeTwoClassId">
                            <el-option v-for="item in goodsTwoClassIdOptions" :key="item.id" :label="item.name"
                                :value="item.id" />
                        </el-select>
                        <el-select v-model="formTool.goodsThreeClassId" size="small" placeholder="商品三级分类" clearable
                            style="max-width:187px;margin-left:16px">
                            <el-option v-for="item in goodsThreeClassIdOptions" :key="item.id" :label="item.name"
                                :value="item.id" />
                        </el-select>
                        <el-form-item prop="goodsName" label="商品名称" style="margin-top: 10px;">
                            <el-input v-model.trim="formTool.goodsName" clearable="" size="small"
                                placeholder="请输入内容"></el-input>
                        </el-form-item>
                        <el-form-item prop="factory" label="厂家名称" style="margin-top: 10px;">
                            <el-input v-model.trim="formTool.factory" clearable="" size="small"
                                placeholder="请输入内容"></el-input>
                        </el-form-item>

                        <el-form-item prop="factory" label="" style="margin-top: 10px;">
                            <el-button type="primary" size="small" @click="getGoods(1)">查询</el-button>
                        </el-form-item>
                   
                    </el-form>
                </div>
                <div>
                    <goodsItem :goodsList="goodsList" @chooseItem="chooseItem" class="goods-content" />
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page.sync="page.current" :page-sizes="page.pageSizes"
                        layout="total, sizes, prev, pager, next" :total="page.total">
                    </el-pagination>
                </div>
            </section>
            <div class="btnBox">
                <el-button size="small" @click="handleClose">取消</el-button>
                <el-button type="primary" size="small" @click="chooseGoods">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { goodsclassOne, goodsclassTwo } from '@/api/commodityreference'

import goodsItem from "./goodsItem.vue";
import { queryGoodsBasePage } from "@/api/product";
import {   getNotcheckGoods } from "@/api/goods";
import { queryFullGiftPage} from "@/api/gift";

export default {
    components: {
        goodsItem
    },
    props: {
        btnType: {
            type: String,
            default: ''
        },
    },
    computed: {
        visible() {
            return this.dialogShow
        }
    },
    data() {
        return {
            goodsOneClassIdOptions: [],
            goodsTwoClassIdOptions: [],
            goodsThreeClassIdOptions: [],
            showForm: false, //是否展示表单
            dialogShow: false,
            isDisabled: false,
            pageType: '',
            formTool: {
                goodsOneClassId:'',
                goodsTwoClassId:'',
                goodsThreeClassId:'',
                name: ''
            },
            title: '',
            goodsList: [],
            selectItems: [],
            page: {
                total: 10,
                current: 1,
                size: 10,
                pageSizes: [10, 20, 30, 50],
            },
        }
    },
    mounted() {

    },
    methods: {
        init(row, type) {
            this.title = '';
            this.isDisabled = false;
            this.title = '指定商品'
            this.showForm = false;
            this.getGoods(1);
            this.dialogShow = true;
            this.$forceUpdate();
            this.page.current = 1;
            this.query()
        },
        // 搜索
        change(e) {
            console.log(e)
            this.getGoods(1)
        },
        // 页码变化
        handleCurrentChange(e) {
            this.page.current = e;
            this.getGoods()
        },
        // 条数变化
        handleSizeChange(e) {
            this.page.size = e
            this.page.current = 1;
            this.getGoods()
        },
        // 获取商品列表
        getGoods(current) {
            const params = {
                size: this.page.size,
                current: current ? current : this.page.current,
                name: this.formTool.goodsName,
                ...this.formTool
            };
            queryFullGiftPage(params).then((res) => {
                if (res) {
                    this.goodsList = res.data.records;
                    this.page.total = res.data.total;
                    this.$forceUpdate()
                }
            });
        },
        handleClose() {
            this.page.current = 1;
            this.dialogShow = false;
        },
        chooseItem(arr) {
            this.selectItems = arr;
            console.log(arr,'arr')
        },
        chooseGoods() {
            if (!this.selectItems.length) {
                this.$message.warning('请选择推广商品')
                return
            }
            this.dialogShow = false;
            this.$emit('didSelectItem', this.selectItems[0])
        },
        query() {
            goodsclassOne().then(res => {
                if (res.code == 0) {
                    this.goodsOneClassIdOptions = res.data
                }
            }).catch(err => {
                console.log(err)
            })
        },
        // 切换一级分类
        changeOneClassId(val) {
            if (val) {
                this.goodsclassTwo(val)
            } else {
                this.formTool.goodsTwoClassId = ''
                this.goodsTwoClassIdOptions = []
                this.formTool.goodsThreeClassId = ''
                this.goodsThreeClassIdOptions = []
            }
        },
        // 切换二级分类
        changeTwoClassId(val) {
            if (val) {
                this.goodsclassThree(val)
            } else {
                this.formTool.goodsThreeClassId = ''
                this.goodsThreeClassIdOptions = []
            }
        },
        // 查询一级分类
        goodsclassOne() {
            goodsclassOne().then(res => {
                if (res.code == 0) {
                    this.goodsOneClassIdOptions = res.data
                    if (this.goodsOneClassIdOptions.length > 0) {
                        this.formTool.goodsOneClassId = this.goodsOneClassIdOptions[0].id
                    } else {
                        this.formTool.goodsOneClassId = ''
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        },
        // 查询二级分类
        goodsclassTwo(id) {
            goodsclassTwo(id).then(res => {
                if (res.code == 0) {
                    this.goodsTwoClassIdOptions = res.data
                    if (this.goodsTwoClassIdOptions.length > 0) {
                        this.formTool.goodsTwoClassId = ''
                        this.formTool.goodsThreeClassId = ''
                        this.goodsThreeClassIdOptions = []
                        // this.goodsclassThree(this.goodsTwoClassIdOptions[0].id)
                    } else {
                        this.formTool.goodsTwoClassId = ''
                        this.formTool.goodsThreeClassId = ''
                        this.goodsThreeClassIdOptions = []
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        },
        // 查询三级分类
        goodsclassThree(id) {
            goodsclassTwo(id).then(res => {
                if (res.code == 0) {
                    this.goodsThreeClassIdOptions = res.data
                    if (this.goodsThreeClassIdOptions.length > 0) {
                        this.formTool.goodsThreeClassId = ''
                    } else {
                        this.formTool.goodsThreeClassId = ''
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        },
    }
}
</script>
<style lang="scss">
.btnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.serach {
    margin-bottom: 10px;
}

.goods-content {

    overflow-y: auto;
}
</style>