<template>
  <div class="notice">
    <div class="header">
      <div class="font">公告设置</div>
    </div>

    <div class="comContent">
      <div style="padding-top:10px">
        <el-form ref="search"
                 :model="search"
                 :inline="true"
                 style="text-align: left">
          <el-form-item prop="title"
                        label="公告标题：">
            <el-input v-model="search.title"
                      placeholder="请输入公告标题"
                      clearable
                      size="mini" />
          </el-form-item>
          <el-form-item prop="date"
                        label="公告更新时间：">
            <el-date-picker v-model="searchDate"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            size="small"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期" />
          </el-form-item>
          <el-form-item>
            <el-button size="small"
                       type="primary"
                       @click="searchList(1)">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="small"
                       @click="reset('search')">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="small"
                       type="primary"
                       @click="add">新建公告</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="content">
        <MyTable :table-data="tableData"
                 :index="index"
                 :selection="selection"
                 :page="page"
                 :operation="operation"
                 :table-option.sync="tableOption"
                 @page-change="getList">
          <template slot="type"
                    slot-scope="scope">
            <el-row>
              {{ scope.row.type === 1 ? "公告" : "质量公示" }}
            </el-row>
          </template>
          <template slot="status"
                    slot-scope="scope">
            <el-row>
              {{ scope.row.status ? "已发布" : "未发布" }}
            </el-row>
          </template>
          <template slot="menu"
                    slot-scope="scope">
            <div class="btn-warp">
              <el-link :underline="false"
                       style="margin-left: 10px"
                       type="primary"
                       v-if="!scope.row.status"
                       @click="release(scope.row)">发布</el-link>
              <el-link :underline="false"
                       style="margin-left: 10px"
                       type="primary"
                       v-if="scope.row.status"
                       @click="offShelf(scope.row)">下架</el-link>
              <el-link :underline="false"
                       style="margin-left: 10px"
                       type="primary"
                       v-if="!scope.row.status"
                       @click="edit(scope.row)">编辑</el-link>
              <el-link :underline="false"
                       style="margin-left: 10px"
                       type="primary"
                       v-if="!scope.row.status"
                       @click="del(scope.row)">删除</el-link>
              <el-link :underline="false"
                       style="margin-left: 10px"
                       type="primary"
                       v-if="scope.row.status"
                       @click="detail(scope.row)">详情</el-link>
            </div>
          </template>
        </MyTable>
      </div>
    </div>
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import { del, noticeList, putOff, putOn } from "@/api/operationCenter/notice";
export default {
  name: "notice",
  components: { MyTable },
  data () {
    return {
      search: {
        title: "",
      },
      searchDate: [],
      operation: true,
      selection: false,
      index: true,
      tableData: [],
      tableOption: [
        { label: "公告标题", prop: "title" },
        { label: "公告类型", prop: "type", slot: true },
        // { label: "更新人", prop: "updateBy" },
        // { label: "发布人", prop: "createBy" },
        { label: "状态", prop: "status", slot: true },
        { label: "排序", prop: "sort" },
        { label: "公告更新时间", prop: "updateTime" },
        // { label: "公告发布时间", prop: "createTime" },
      ],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
    };
  },
  created () {
    this.getList();
  },
  methods: {
    // 点击查询
    searchList (current) {
      if (current) {
        this.page.current = current
      }
      this.getList();
    },
    reset (formName) {
      this.$refs[formName].resetFields();
      this.searchDate = [];
      this.getList();
    },
    // 公布列表
    getList () {
      const params = {
        ...this.search,
        current: this.page.current,
        startDate:
          !this.searchDate || this.searchDate.length == 0
            ? ""
            : this.searchDate[0], // 开始时间
        endDate:
          !this.searchDate || this.searchDate.length == 0
            ? ""
            : this.searchDate[1], // 结束时间
        size: this.page.size,
      };
      noticeList(params).then((res) => {
        this.tableData = res.records;
        this.page.total = res.total || 0;
      });
    },
    // 发布
    release (row) {
      console.log(row);
      putOn({ id: row.id }).then((res) => {
        if (res) {
          this.$message({
            message: "发布成功",
            type: "success",
          });
          this.getList();
        }
      });
    },
    // 下架
    offShelf (row) {
      putOff({ id: row.id }).then((res) => {
        if (res) {
          this.$message({
            message: "下架成功",
            type: "success",
          });
          this.getList();
        }
      });
    },
    // 新建
    add () {
      this.$router.push({
        path: "/operationCenter/operationCenter-notice-detail",
      })
    },
    // 编辑
    edit (row) {
      this.$router.push({
        path: "/operationCenter/operationCenter-notice-detail",
        query: {
          type: 1,
          id: row.id
        }
      })
    },
    // 删除
    del (row) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del({ id: row.id }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getList();
          });
        })
        .catch(() => { });
    },
    // 详情
    detail (row) {
      this.$router.push({
        path: "/operationCenter/operationCenter-notice-detail",
        query: {
          type: 2,
          id: row.id
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.notice {
  background-color: #ffffff;
  .header {
    padding: 24px;
    padding-bottom: 0px;
    border-bottom: 1px solid #f5f5f5;
    .font {
      padding: 4px 0;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
      margin-bottom: 10px;
    }
  }
  .content {
    height: calc(100% - 160px);
  }
}
</style>
