
<template>
    <div class="classification">
        <div class="header-money">
            <div class="cloum-lf">
                <div class="cloum-i">
                    当前余额 <span>￥ {{ (lockAmount + useAmount) | filterMoney }}</span>
                </div>
                <div class="cloum-i">
                    订单占用余额 <span>￥ {{ lockAmount | filterMoney }}</span>
                </div>
                <div  class="cloum-i">
                    当前可用余额 <span>￥ {{ useAmount | filterMoney }}</span>
                </div>

                <div  class="cloum-i">
                    保证金阈值 <span>￥  {{ stopThreshold | filterMoney }} </span>
                </div>

            </div>
            <div>
                <el-button size="small" type="primary" @click="rechargeSubmit()">保证金充值</el-button>
            </div>
        </div>
        <div class="tips">
            线下打款时，订单会占用保证金，当订单金额＞未占用金额时，客户无法使用线下打款方式付款；
            <br />线下打款订单完结后，释放占用的保证金。
        </div>
        <div class="tips">
            当前余额若小于保证金阈值，则无法提现。
        </div>
        <div class="header">
            <el-form ref="formTool" :inline="true" style="text-align: left">
                <div>
                    <el-form-item prop="nameLike" label="">
                        <el-tabs v-model="activeName" @tab-click="tabClick">
                            <el-tab-pane label="保证金变动记录" name="first">
                            </el-tab-pane>
                            <el-tab-pane label="保证金充值审核" name="second">
                            </el-tab-pane>
                        </el-tabs>
                    </el-form-item>
                </div>
            </el-form>
        </div>
        <recordList ref="record-list" v-if="activeName == 'first'" />
        <rechargeAduitList ref="recharge-aduit-list" v-if="activeName == 'second'" />
        <addDialog ref="add-dialog" @getList="getList" />

    </div>
</template>
  
<script>
import MyTable from "@/components/myTable";
import recordList from "./recordList.vue";
import rechargeAduitList from "./rechargeAduitList.vue";
import addDialog from "./components/chargeMoney.vue";
import { guaranteeInfo } from "@/api/surety";
export default {
    components: { MyTable, recordList, rechargeAduitList, addDialog },
    data() {
        return {
            useAmount: null,
            lockAmount: null,
            activeName: 'first',
            stopThreshold:null,
            
        };
    },
    computed: {},
    watch: {},
    created() {

    },
    mounted() {
        if(this.$store.state.tabStatus.suretyTab){
            this.activeName = this.$store.state.tabStatus.suretyTab;
        }
        this.query();
    },
    beforeCreate() { },
    beforeMount() { },
    beforeUpdate() { },
    updated() { },
    beforeDestroy() { },
    destroyed() { },
    activated() { },
    computed: {

    },
    methods: {
        tabClick() {
            // console.log(this.activeName, "activeName");
            this.$store.dispatch("tabStatus/setSuretyTab", this.activeName)
        },
        getList() {
            this.activeName = 'second';
            this.$refs['recharge-aduit-list'].getList()
        },
        rechargeSubmit(row = {}) {
            this.$refs['add-dialog'].init(row, 'add')
        },
        query() {
            guaranteeInfo({}).then(res => {
                this.useAmount = res.useAmount;
                this.lockAmount = res.lockAmount;
                this.oriAmount = res.oriAmount;
                this.stopThreshold = res.stopThreshold;//停用阈值
            })
        },
    },
    filters: {
        filterMoney(e) {
            return e ? parseFloat(e).toFixed(2) : '0.00';
        },
    },

};
</script>
<style lang='scss' scoped>
@import "@/styles/variables.scss";

.classification {
    background: white;
    overflow: inherit;

    .header {
        width: calc(100% - 24px);
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 0px 24px 0px 24px !important;
        border-bottom: 1px solid #f5f5f5;

        .font {
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 600;
            text-align: left;
            color: #262626;
        }

        .el-form-item {
            margin: 0px 0px 0px 8px !important;
        }
    }



    .top {
        display: flex;
        padding: 10px 0 10px 0;

        .item {
            display: flex;
            align-items: center;
            margin-right: 20px;

            .label {
                width: 120px;
            }

            .button {
                margin-right: 20px;

                ::v-deep .el-button {
                    color: $subMenuActiveText;
                    background-color: $butColor;
                    border-color: $butColor;

                    &:hover {
                        color: $subMenuActiveText;
                        background-color: $butColor;
                        border-color: $butColor;
                    }

                    &:active {
                        color: $subMenuActiveText;
                        background: $butColor;
                        background-color: $butColor;
                        border-color: $butColor;
                    }

                    &:focus {
                        color: $subMenuActiveText;
                        background-color: $butColor;
                        border-color: $butColor;
                    }
                }
            }
        }
    }

    .center {
        margin-top: 16px;
        padding: 0 0px !important;
        height: calc(100% - 131px);

    }

    .btn-warp {
        display: flex;
        justify-content: space-around;

    }
}

.header-money {
    width: calc(100% - 40px);
    margin: 20px 20px 0;
    display: flex;
    justify-content: space-between;
    font-size: 16px;

}

.cloum-lf {
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
}

.cloum-i {
     margin-right: 20px;
    span {
        color: #F5222D;
    }
}

.tips {
    font-size: 14px;
    color: #8c8c8c;
    margin: 0;
    margin-left: 20px;

}
</style>
  