<!--  保证金变动记录 -->
 
<template>
  <div class="content">
    <div class="header">

      <el-form ref="formTool" :model="formTool" :inline="true" style="text-align: left"> 
        <el-row style="margin: 10px  0 10px">
          <el-form-item label="">
            <el-input v-model.trim="formTool.voucherCode" placeholder="请输入申请ID" clearable size="small" />
          </el-form-item>
          <el-form-item label="申请时间" prop="date">
            <el-date-picker style="width: 100%" v-model="formTool.date" value-format="yyyy-MM-dd" type="daterange"
              size="small" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" />
          </el-form-item>
        
          <el-form-item label="">
            <el-select v-model="formTool.paymentStatus" clearable style="width: 160px;" size="small" placeholder="请选择缴费状态">
              <el-option v-for="item in paymentStatusOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="getList(1)">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="reset()">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="exportUrl()">导出</el-button>
          </el-form-item>
        </el-row>

      </el-form>
    </div>

    <div class="center" style="position: relative"   :style="contentHeight ? `height:${contentHeight}px!important` : ''">
      <MyTable :table-data="tableData" :index="index" :selection="selection" :page="page" :operation="operation"
        :table-option.sync="tableOption" @page-change="getList" @handleSelectionChange="handleSelectionChange"> 
        <template slot="paymentStatus" slot-scope="scope">
         <span> {{ scope.row.paymentStatus | paymentStatus }}</span>
        </template> 
        <template slot="menu" slot-scope="scope">
          <el-link :underline="false" type="primary" @click="detailHandle(scope.row, '查看缴费明细')" class="btn-list">查看缴费明细</el-link>
          <el-link :underline="false" type="primary" @click="detailHandle(scope.row, '查看凭证')" 
            class="btn-list">查看凭证</el-link>
        </template> 
      </MyTable>
    </div>
    <addDialog ref="add-dialog" @resetSubmit="resetSubmit" />
    <resetDialog ref="rest-dialog" @getList="getList" />
    <recordDialog ref="record-dialog"/>
    
  </div>
</template>
  
<script>
  
const paymentStatusOptions = [  
  { value: 0, label: "未缴费" },  
  { value: 1, label: "审核中" },  
  { value: 2, label: "审核通过" },  
  { value: 3, label: "驳回" },  
];  
 
const paymentFilters = { 
  paymentStatus: function (value) {
    const options = paymentStatusOptions.find((option) =>
      option.value == value);
    return options ? options.label : value === 0 ? '未缴费' : '';
  }, 
};
import MyTable from "@/components/myTable";
import addDialog from "./components/recordCertificate.vue";
import recordDialog from "./components/recordMoney.vue"; 
import resetDialog from "./components/submitCertificate.vue"; 

import avater from "/public/img/default.png";
import {  fileExport } from "@/api/surety";
import { payvoucherinfoGet,payvoucherinfoResubmit } from  "@/api/offlinePayment"; 

export default {
  components: { MyTable, addDialog,recordDialog ,resetDialog},
  data() {
    return {
      contentHeight:0,
      activeName: 'first',
      avater: avater,
      formTool: {
      },
      operation: true,
      selection: false,
      index: true,
      tableData: [],
      tableOption: [
        { label: "申请ID", prop: "voucherCode" },
        { label: "申请时间", prop: "createTime" },
        { label: "缴款金额（元）", prop: "paymentAmount",type:'money' },
        { label: "审核时间", prop: "updateTime", },
        { label: "缴费状态", prop: "paymentStatus", slot: true }, 
      ], 
      paymentStatusOptions:paymentStatusOptions,
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      dialogShow: false
    };
  },
  computed: {},
  filters: paymentFilters,
  watch: {},
  created() {
    this.getList();
  },
  mounted() { },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() {
    this.getWindowView()
  },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
  computed: {

  },
  methods: {
   
    // 页码变化
    handleCurrentChange(e) {
      this.page.current = e
    },

    // 条数变化
    handleSizeChange(e) {
      this.page.size = e
      this.page.current = 1
    },

    detailHandle(row, btnType) {
      if (btnType == '查看凭证') {
        this.$refs['add-dialog'].init(row, 'detail')
      }
      if (btnType == '查看缴费明细') {
        this.$refs['record-dialog'].init(row, 'detail')
      }
    },

    // 重新提交凭证
    resetSubmit(row){
      this.$refs['rest-dialog'].init(row,'reset' )
    },

    // 重置查询条件
    reset() {
      this.formTool = {};
      this.getList(1);
    },
    // userLogExport

    exportUrl() {
      const params = {
        ...this.formTool,
        current: this.page.current,
        size: this.page.size
      };
      if (this.formTool.date) {
        params.strTime = this.formTool.date[0];
        params.endTime = this.formTool.date[1];
      }
      delete params.date;
      fileExport('/order/purchase/payvoucherinfo/shop/excel', 'GET', params).then((res) => { }).catch((err) => {

      })
    },
    // 搜索
    getList(current) {
      if (current) {
        this.page.current = current;
      }
      const params = {
        ...this.formTool,
        current: this.page.current,
        size: this.page.size
      };
      if (this.formTool.date) {
        params.strTime = this.formTool.date[0];
        params.endTime = this.formTool.date[1];
      }
      delete params.date;
      payvoucherinfoGet(params).then((res) => {
        if (res) {
          this.tableData = res.records;
          this.page.total = res.total;
        } else {
          this.tableData = [];
          this.page.total = 0;
        }
      }); 
    },

    // 勾选
    handleSelectionChange(val) {
      this.selectionList = val;
    },
    getWindowView() { 
      let innerHeight = window.innerHeight; //获取页面高度 
      let headHeight = 60;
      headHeight = this.$refs['formTool'].$el.clientHeight; //获取子页面head 高度   
      let contentHeight = innerHeight -208- headHeight; 
      this.contentHeight = contentHeight;
    }
  },
};
</script>
<style lang='scss' scoped>  
 
.content { 
  .center { 
  } 
  .btn-warp {
    display: flex;
    justify-content: space-around; 
  }
}
.btn-list {
  margin: 0 10px;
}
::v-deep .el-table {
  height: calc(100% - 120px) !important;
  overflow: auto;
}

</style>
  