var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "classification" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "centerView" },
      _vm._l(_vm.applicationList, function (item) {
        return _c(
          "div",
          {
            staticClass: "eachrow",
            on: {
              click: function ($event) {
                return _vm.goNext(item)
              },
            },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/" + item.icon), alt: "" },
            }),
            _c("span", [_vm._v(_vm._s(item.name))]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "topText" }, [
      _c("span", [_vm._v("开票业务")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }