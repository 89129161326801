var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reason-root" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "驳回原因",
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "420px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.onClosed,
          },
        },
        [
          _c("div", { staticClass: "content form-content" }, [
            _c("div", { staticClass: "row mutlti" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("驳回原因")]),
              _c(
                "div",
                { staticClass: "value input fill" },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 4, maxlength: "250" },
                    model: {
                      value: _vm.reason,
                      callback: function ($$v) {
                        _vm.reason = $$v
                      },
                      expression: "reason",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }