<template>
  <div class="classification">
    <div class="eachRow">
      <div class="titleView">
        <div style="display: flex">
          <div class="rowLine"></div>
          <span class="eachRowTitle">投诉建议</span>
        </div>
        <el-button
          v-if="data && data.length"
          class="right"
          type="primary"
          @click="clickAdd"
          size="mini"
          >新建</el-button
        >
      </div>
      <div class="content">
        <div
          v-if="data && data.length"
          class="advise-list"
          v-for="(item, index) in data"
          :key="index"
        >
          <div class="advise-list-feedback">
            <div class="feedback-left">
              <div style="display: flex">
                <div class="feedback-left-title">投诉反馈：</div>
                <div>{{ item.adviseInfo }}</div>
              </div>
              <ImgUpload
                v-model="item.image"
                :limit="5"
                :isEdit="true"
                style="position: relative; top: 0; left: 80px"
              >
              </ImgUpload>
            </div>
            <div class="feedback-right">
              <div class="feedback-right-time">{{ item.createTime }}</div>
              <p class="status-box">{{ item.status == 0 ? "处理中" : "已完成" }}</p>
            </div>
          </div>
          <div class="advise-list-reply" v-if="item.status == '1'">
            <div style="display: flex">
              <div class="advise-list-reply-title">投诉回复：</div>
              <div v-if="item.reply">
                {{ item.reply }}
              </div>
            </div>
            <ImgUpload
              v-if="item.replyImage && item.replyImage.length"
              v-model="item.replyImage"
              :limit="5"
              :isEdit="true"
              style="position: relative; top: 0; left: 80px"
            >
            </ImgUpload>
          </div>
        </div>
      </div>
      <div class="no-data" v-if="!data.length">
        <el-empty description=" " />
        尊敬的用户，如在平台上遇到了未达到您预期的体验，请不吝<span
          class="add-btn"
          @click="clickAdd"
          >发起投诉建议</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import ImgUpload from "@/components/imgUpload";
import { page, insert, list } from "@/api/suggestion";
export default {
  props: {},
  data() {
    return {
      data: [],
    };
  },
  components: { ImgUpload },
  created() {
    this.list();
  },
  mounted() {},
  watch: {},
  methods: {
    list() {
      list().then((res) => {
        if (res.code == 0) {
          this.data = res.data;
          this.data.forEach((item) => {
            if (item.image) {
              item.image = item.image.split(",");
            }
            if (item.replyImage) {
              item.replyImage = item.replyImage.split(",");
            }
          });
        } else {
          this.data = [];
        }
      });
    },
    clickAdd() {
      this.$router.push("/suggestion/suggestion-add");
    },
  },
};
</script>
<style lang="scss" scoped>
.eachRow {
  // height: calc(100% - 24px);
  // overflow: auto;
  margin-top: 24px;
}
.titleView {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .rowLine {
    width: 4px;
    border-color: 1px;
    background-color: var(--main-color);
    height: 16px;
    margin-right: 8px;
  }

  .eachRowTitle {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
  }
}
.content {
  overflow: auto;
  .advise-list {
    border: 1px solid #d7dce0;
    padding: 24px;
    border-radius: 6px;
    margin-top: 18px;
    &-feedback {
      display: flex;
      justify-content: space-between;
      .feedback-left {
        font-size: 16px;
        &-title {
          min-width: 82px;
        }
      }
      .feedback-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #77828c;
        font-size: 14px;
        &-time {
          min-width: 160px;
          text-align: right;
        }
        .status-box {
          border: 1px solid #d7dce0;
          width: 100px;
          text-align: center;
          padding: 4px 0;
          border-radius: 4px;
          margin-left: 30px;
        }
      }
    }
    &-reply {
      &-title {
        min-width: 82px;
      }
    }
  }
}
.no-data {
  height: calc(100% - 19px);
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  .add-btn {
    color: red;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
