<template>
  <div class="">
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form :model="ruleForm" ref="formRef" label-width="140px" class="demo-ruleForm">
        <el-form-item label="操作时间：" prop="money">
          <span>{{ row.createTime }}</span>
        </el-form-item>
        <!-- <el-form-item label="操作人：" prop="money">
          <span>林琴</span>
        </el-form-item> -->
        <el-form-item label="变动金额（元）：" prop="money">
          <span v-if="row.type == 1" class="add"> + <span> {{ row.amount ?
            parseFloat(row.amount).toFixed(2) : '0.00' }}</span></span>
          <span v-if="row.type == 2" class="reduce"> - <span>{{ row.amount ?
            parseFloat(row.amount).toFixed(2) : '0.00' }}</span></span>
        </el-form-item>
        <el-form-item label="操作原因：" prop="money"> 
          <span>{{   row.reason  | filterReason}}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
const  reasonJson =
          [  
              {  
                  "key": "RECHARGE",  
                  "code": 1,  
                  "desc": "保证金充值"  
              },  
              {  
                  "key": "MORTGAGE_FUND",  
                  "code": 2,  
                  "desc": "订单资金冻结"  
              },  
              {  
                  "key": "SERVICE_CHARGE",  
                  "code": 3,  
                  "desc": "服务费扣除"  
              },  
              {  
                  "key": "AFTERMARKET",  
                  "code": 4,  
                  "desc": "售后资金扣减"  
              },  
              {  
                  "key": "SETTLEMENT",  
                  "code": 5,  
                  "desc": "订单资金解冻"  
              },  
              {  
                  "key": "LOCK_SERVICE",  
                  "code": 7,  
                  "desc": "服务费冻结"  
              },  
              {  
                  "key": "UNLOCK_SERVICE",  
                  "code": 8,  
                  "desc": "服务费解冻"  
              },  
              {  
                  "key": "TIMEOUT_INDEMNITY_LOCK",  
                  "code": 9,  
                  "desc": "订单超时赔付金额冻结"  
              },  
              {  
                  "key": "TIMEOUT_INDEMNITY_UNLOCK",  
                  "code": 10,  
                  "desc": "订单超时赔付金额解冻"  
              },  
              {  
                  "key": "TIMEOUT_INDEMNITY_CHARGE",  
                  "code": 11,  
                  "desc": "订单超时赔付金额扣减"  
              },  
              {  
                  "key": "PLATFORM_INCREASE",  
                  "code": 12,  
                  "desc": "平台增额"  
              },  
              {  
                  "key": "PLATFORM_SUB",  
                  "code": 13,  
                  "desc": "平台扣减"  
              }  
          ];
export default {
  props: {
  },
  data () {
    return { 
      row: {},
      title: '提示',
      type: '1',
      dialogVisible: false,
      ruleForm: {
        money: '',
        reason: ''
      },
    }
  },
  components: {},
  created () {

  },
  mounted () {

  },
  filters:{
    filterReason :function(e){
      for(let i=0;i<reasonJson.length;i++){
        if(e == reasonJson[i].code){
          return reasonJson[i].desc;
        }
      }
    }
  },
  watch: {},
  methods: {
    open (row, title) {
      this.row = row;
      this.title = title;
      this.dialogVisible = true;
    },
    handleClose () {
      this.dialogVisible = false;
    }
  }
}

</script>
<style lang="scss" scoped>
.reduce {
  color: green;
}

.add {
  color: #F5222D;
}
</style>
