var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        _vm.item.marketPrice != null && Number(_vm.item.marketPrice) > 0
          ? "teshu"
          : "itemCont",
    },
    [
      _c(
        "div",
        { staticClass: "boxTop" },
        [
          _vm.item.marketPrice != null && Number(_vm.item.marketPrice) > 0
            ? _c(
                "el-tooltip",
                { attrs: { placement: "right", effect: "light" } },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _c("img", {
                      staticStyle: { width: "200px", height: "242px" },
                      attrs: {
                        src: require("../../assets//dalibaohuodong.png"),
                        alt: "",
                      },
                    }),
                  ]),
                  _vm.item.marketPrice != null &&
                  Number(_vm.item.marketPrice) > 0
                    ? _c("img", {
                        staticClass: "dalibao",
                        attrs: {
                          src: require("../../assets/dalibao.png"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "itemImg" },
            [
              _c("el-image", {
                staticStyle: {
                  width: "188px",
                  height: "188px",
                  padding: "10px",
                },
                attrs: {
                  src: _vm.item.image || _vm.avater,
                  alt: "",
                  lazy: "",
                  "preview-src-list": _vm.item.image ? [_vm.item.image] : [],
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "rightBox" }, [
            _c(
              "div",
              {
                staticClass: "itemBox name",
                staticStyle: { "padding-bottom": "20px" },
              },
              [_vm._v(" " + _vm._s(_vm.item.name) + " ")]
            ),
            _c("div", { staticClass: "itemBox factory" }, [
              _vm._v(" " + _vm._s(_vm.item.specification) + " "),
            ]),
            _c("div", { staticClass: "itemBox factory" }, [
              _vm._v(
                " 有效期至：" +
                  _vm._s(
                    _vm.item.expireTime
                      ? _vm.item.expireTime.substring(0, 10)
                      : ""
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "itemBox factory" }, [
              _vm._v(" " + _vm._s(_vm.item.approvalNumber) + " "),
            ]),
            _c("div", { staticClass: "itemBox factory" }, [
              _vm._v(" " + _vm._s(_vm.item.factory) + " "),
            ]),
            _c(
              "div",
              {
                staticClass: "itemBox",
                staticStyle: {
                  width: "100%",
                  "justify-content": "space-between",
                  "padding-top": "10px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "price",
                        staticStyle: {
                          color: "#f6212d",
                          "font-size": "20px",
                          "justify-content": "start",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-size": "14px" } }, [
                          _vm._v(" ￥ "),
                        ]),
                        _vm._v(" " + _vm._s(_vm.item.salePrice) + " "),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "price",
                        staticStyle: {
                          "justify-content": "start",
                          "font-size": "12px",
                          color: "#595959",
                          "line-height": "26px",
                        },
                      },
                      [
                        _vm.item.marketPrice != null &&
                        Number(_vm.item.marketPrice) > 0
                          ? _c(
                              "div",
                              { staticStyle: { "font-weight": "400" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "text-decoration": "line-through",
                                      color: "#8c8c8c",
                                    },
                                  },
                                  [_vm._v("￥" + _vm._s(_vm.item.marketPrice))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "16px",
                                      color: "#595959",
                                      "margin-left": "3px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        (
                                          (Number(_vm.item.salePrice) /
                                            Number(_vm.item.marketPrice)) *
                                          10
                                        ).toFixed(1)
                                      ) + "折"
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                " 数量：" +
                                  _vm._s(_vm.item.goodsSkuInventory) +
                                  " "
                              ),
                            ]),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticStyle: { "justify-content": "end" } }),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "bottomBox" }, [
        _c("div", [
          _c("img", {
            staticStyle: {
              width: "20px",
              heihgt: "20px",
              position: "relative",
              top: "5px",
            },
            attrs: { src: require("../../assets/shop.png"), alt: "" },
          }),
          _c(
            "span",
            {
              staticClass: "company",
              on: {
                click: function ($event) {
                  return _vm.handleDetail(_vm.item)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.item.shopName) + " ")]
          ),
          _c("img", {
            staticStyle: {
              width: "20px",
              heihgt: "20px",
              position: "relative",
              top: "5px",
              cursor: "pointer",
            },
            attrs: { src: require("@/assets/call.png"), alt: "" },
            on: {
              click: function ($event) {
                return _vm.handleChat(_vm.item)
              },
            },
          }),
        ]),
        _c(
          "div",
          [
            _vm.item.marketPrice != null && Number(_vm.item.marketPrice) > 0
              ? _c(
                  "el-tooltip",
                  { attrs: { placement: "right", effect: "light" } },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _c("img", {
                        staticStyle: { width: "200px", height: "242px" },
                        attrs: {
                          src: require("../../assets//dalibaohuodong.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _c(
                      "el-button",
                      {
                        staticClass: "gouwuche",
                        class:
                          _vm.item.marketPrice != null &&
                          Number(_vm.item.marketPrice) > 0
                            ? "teshuBtn"
                            : "",
                        attrs: {
                          type: "",
                          size: "small",
                          icon: "el-icon-shopping-cart-1",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleAddbuy(_vm.item)
                          },
                        },
                      },
                      [_vm._v(" 购物车")]
                    ),
                  ],
                  1
                )
              : _c(
                  "el-button",
                  {
                    staticClass: "gouwuche",
                    class:
                      _vm.item.marketPrice != null &&
                      Number(_vm.item.marketPrice) > 0
                        ? "teshuBtn"
                        : "",
                    attrs: {
                      type: "",
                      size: "small",
                      icon: "el-icon-shopping-cart-1",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleAddbuy(_vm.item)
                      },
                    },
                  },
                  [_vm._v(" 购物车")]
                ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }