<template>
    <div class="mainView">
        <el-scrollbar style="height: calc(100vh - 148px);">
            <div class="eachRow flexRowCenter">
                <div v-for="item in dateTypeList" class="dateType" :class="dateSelect == item ? 'dateTypeSelect' : ''"
                    @click="handleClickDateType(item)">{{ item }}</div>
                <el-date-picker v-model="timeRange" type="daterange" unlink-panels size="small" range-separator="-"
                    start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @change="didSelectDate"
                    ref="datePicker" :clearable="false">
                </el-date-picker>
                <div style="width: 16px;"></div>
                <el-select v-model="goodsType" style="width: 130px" size="small">
                    <el-option label="消肿止痛贴" value="消肿止痛贴" />
                </el-select>
                <el-button size="small" @click="reset()" type="default" plain style="margin-left: 16px;">重置</el-button>
                <el-button size="small" @click="query()" type="primary">查询</el-button>
            </div>
            <div class="divider" />
            <div class="eachRow">
                <div class="flexRowCenter">
                    <span class="titleName">{{userInfo.shopName || '省区'}}</span>
                    <span class="timeText">统计时间：{{getTimeShow()}}</span>
                    <span class="timeText">更新时间：{{freshTime}}</span>
                    <!-- <el-button size="small" @click="download(1)" type="default" plain style="margin-left: auto;">导出</el-button> -->
                </div>
                <div class="columnflexRowCenter" style="height: 100px;">
                    <div v-for="item in orderCountList" class="columnView">
                        <span class="columnViewTitle">{{item.name}}</span>
                        <div class="flexRowEnd">
                            <!-- <span class="prefix" v-show="item.needPreFix">¥</span> -->
                            <span class="money">{{fixStr(item.count,item.key)}}</span>
                            <!-- <span class="endfix" v-show="item.needEndFix">件</span> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider" />
            <div class="eachRow">
                <el-table :data="tableData" d style="width: 100%" highlight-current-row  @current-change="handleCurrentChange" ref="elTable">
                    <el-table-column label="排名"  type="index" width="60">
                    </el-table-column>
                    <el-table-column prop="district" label="采购方名称" >
                    </el-table-column>
                  
                    <el-table-column label="采购件数（件）">
                        <template slot-scope="scope">
                            <span>{{scope.row.provincialBusinessOrderNumber || 0}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="应付总额（元）" >
                        <template slot-scope="scope">
                            <span>¥{{fixtoTwo(scope.row.provincialBusinessNeedPayTotal)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="provincialBusinessOrderVolumeTotal" label="采购订单" />
                    <el-table-column prop="provincialBusinessOrderFulfillmentQuantity" label="完成订单" />
                    <el-table-column label="完成收货（件）">
                        <template slot-scope="scope">
                            <span>{{scope.row.provincialBusinessNumberOfCompletedProducts || 0}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="应到总额（元）" >
                        <template slot-scope="scope">
                            <span>¥{{fixtoTwo(scope.row.provincialBusinessAmountReceivableTotal)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="实到总额（元）">
                        <template slot-scope="scope">
                            <span>¥{{fixtoTwo(scope.row.provincialBusinessAmountReceivedTotal)}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-scrollbar>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import NProgress from 'nprogress' // progress bar
import { queryProvinceOrderDetailsStatistics, queryFinancialCollectionStatistics, provinceOfficeOfAcquisitionPage, exportStatistics, exportProvinceStatistics } from "@/api/statistics";
import dayjs from 'dayjs'
export default {
    components:{Pagination},
    data() {
        return {
            dateTypeList: ['本月', '今年'],
            dateSelect: '本月',
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近六个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            timeRange: [],
            goodsType: "消肿止痛贴",
            orderCountList:[
                {name:'采购件数（件）',count:0, key:'purchaseQuantityTotal'},
                {name:'采购订单',count:0 ,key:'orderVolumeTotal'},
                {name:'应付总额（元）',count:0,key:'amountReceivableTotal'},
                {name:'完成订单',count:0, key:'confirmTheDeliveryOrder'},
                {name:'完成收货（件）',count:0, key:'confirmReceiptOfGoods'},
                {name:'应到总额（元）',count:0,  key:'shouldBeCreditedMoney'},
                {name:'实到总额（元）',count:0, key:'amountReceivedTotal'},
            ],
            tableData: [],
            provinceData:[],
            goodsWaitingToBeSent: {orderQuantity: 0,goodsQuantity: 0},//待发货
            inDelivery:  {orderQuantity: 0,goodsQuantity: 0},//配送中
            deliveryCompleted: {orderQuantity: 0,goodsQuantity: 0},//配送完成
            completed:  {orderQuantity: 0,goodsQuantity: 0},//完成订单
            cancelAnOrder:  {orderQuantity: 0,goodsQuantity: 0},//取消订单
            afterSale:  {orderQuantity: 0,goodsQuantity: 0},//售后订单
            currentRow:null,
            provinceSelectData:null,
            page: {
                total: 10,
                current: 1,
                size: 10,
                pageSizes: [10, 20, 30, 50, 100]
            },
            freshTime:'',
            userInfo:{}
        }

    },
    mounted(){
       this.getStatisticData('init')
       if (this.$store.state.user.user_info) {
            this.userInfo = this.$store.state.user.user_info
       }
    },
    methods: {

        async getStatisticData (loadType){
            const obj = this.pendParams();
            if (loadType == 'init') {
                NProgress.start();
                try {
                    const rsp = await queryFinancialCollectionStatistics(obj)
                    if (rsp.data ) {
                        this.freshTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm')
                        NProgress.done();
                        const rspData = rsp.data
                        if (rspData.provincialBusinessTotalDataList) {
                            this.tableData = rspData.provincialBusinessTotalDataList
                            this.provinceSelectData = this.tableData[0]
                            this.getStatisticDataProvince(obj)
                        }
                        this.orderCountList.forEach((e)=>{
                            if (rspData[e.key]) {
                                e.count = rspData[e.key]
                            }else{
                                e.count = 0;
                            }
                        })
                    }else{
                        NProgress.done();
                    }
                } catch (error) {
                    NProgress.done();
                }
            }else{

                this.getStatisticDataProvince(obj)
            }
        },

        async getStatisticDataProvince (obj){
            
            if (this.provinceSelectData) {
                obj['orgCode'] = this.provinceSelectData.orgCode
            }
            const provinceRspCount = await queryProvinceOrderDetailsStatistics(obj)
            if (provinceRspCount.data) {
                this.goodsWaitingToBeSent = provinceRspCount.data.goodsWaitingToBeSent //待发货
                this.inDelivery =  provinceRspCount.data.inDelivery //配送中
                this.deliveryCompleted = provinceRspCount.data.deliveryCompleted //配送完成
                this.completed = provinceRspCount.data.completed //完成订单
                this.cancelAnOrder =  provinceRspCount.data.cancelAnOrder //取消订单
                this.afterSale = provinceRspCount.data.afterSale //售后订单
            }

            obj['size'] = this.page.size
            obj['current'] = this.page.current
            const detailList = await provinceOfficeOfAcquisitionPage(obj)

            if (detailList.data && detailList.data.records) {
                this.page.total = detailList.data.total
                this.provinceData = detailList.data.records
            }
        },
        exportStatisticsAll(){
            // 导出 1
            const obj = this.pendParams();
            exportStatistics(obj);
           
        },
        exportStatisticsByOrg(){
            // 导出 2
            let obj = this.pendParams();
            if (this.provinceSelectData) {
                obj['orgCode'] = this.provinceSelectData.orgCode
            }
            exportProvinceStatistics(obj);
        },
        pendParams(){
            let obj = {
                tag : 'district', //science 查询财务中心统计-学术 ； yyk ： 查询财务中心统计-云药库 ； province ： 查询财务中心统计-省区 district地区
            }
            switch (this.dateSelect) {
                case '本月':
                    const timeRangeLastMonth = this.getStartMonthDay()
                    obj['startTime'] = timeRangeLastMonth[0]
                    obj['endTime'] = timeRangeLastMonth[1]
                    break;
                case '今年':
                    const timeRangeToday = this.getStartYearDay()
                    obj['startTime'] = timeRangeToday[0]
                    obj['endTime'] = timeRangeToday[1]
                    break;
                case '':
                    let s = ''
                    let e = ''
                    if (this.timeRange[0]) {
                        s = dayjs(this.timeRange[0]).format('YYYY-MM-DD'); 
                    }
                    if (this.timeRange[1]) {
                        e = dayjs(this.timeRange[1]).format('YYYY-MM-DD'); 
                    }
                    obj['startTime'] = s
                    obj['endTime'] = e
                    break;
                default:
                    break;
            }
            return obj
        },
        getStartYearDay(){
            const endTime = dayjs(new Date()).format('YYYY-MM-DD');
            // const start = new Date();
            // start.setTime(start.getTime() - 3600 * 1000 * 24 * day);
            // let startTime = dayjs(start).format('YYYY-MM-DD')
            const startTime = dayjs().startOf('year').format('YYYY-MM-DD')
            return [startTime, endTime];
        },
        getStartMonthDay(){
            const endTime = dayjs(new Date()).format('YYYY-MM-DD');
            const startTime = dayjs().startOf('month').format('YYYY-MM-DD')
            return [startTime, endTime];
        },
        handleClickDateType(name) {
            this.dateSelect = name
            this.timeRange = []
            this.page.current = 1;
            this.getStatisticData('init')
        },
        didSelectDate() {
            this.page.current = 1;
            this.dateSelect = ''
            this.getStatisticData('init')
        },
        reset() {
            // 重置
            this.setCurrent()
            this.timeRange = []
            this.page.current = 1;
            this.provinceSelectData = null
            this.dateSelect = '本月'
            this.getStatisticData('init')
        },
        query() {
            // 查询
            this.getStatisticData('init')
        },
        download(type) {
            // 导出
            if (type == 1) {
                this.exportStatisticsAll()
            } else if (type == 2) {
                this.exportStatisticsByOrg()
            }
        },
        //格式化金额(后面两位小数会四舍五入)
        moneyFormats(value){
            let a=Number(value);  //转为数字格式
            let b=a.toLocaleString('zh', { style: 'currency', currency: 'CNY' });	
            return b
        },
        fixStr(str = '', key){
            // str = str.toString();
            // const result = str.replace(/(?=\B(\d{3})+$)/g, ',');
            if (key == 'amountReceivableTotal' || key == 'amountReceivedTotal' || key == 'shouldBeCreditedMoney') {
                return this.fixtoTwo(str)
            } else {
                return str || '0';
            }
        },
        fixtoTwo(str = 0.00){
            if (str) {
                str = parseFloat(str)
                return str.toFixed(2).toString()
            }
            return '0.00'
        },
        handleCurrentChange(val){
            this.currentRow = val
            this.provinceSelectData = val
            this.requestProvinceData()
        },
        setCurrent(row) {
            this.$refs.elTable.setCurrentRow(row);
        },
        requestProvinceData(){
            // 获取下级表单的数据
            this.page.current = 1
            this.getStatisticData()

        },
        pageChange(){
            this.getStatisticData()
        },
        getTimeShow(){
           
            let s = ''
            let e = ''
            let timeArr = []
            if (this.dateSelect == '本月') {

                timeArr = this.getStartMonthDay()

            }else if (this.dateSelect == '今年') {

                timeArr = this.getStartYearDay()

            } else {

                timeArr = this.timeRange

            }
            if (! timeArr || !timeArr.length) {
                return ''
            }
            if (timeArr[0]) {
                s = dayjs(timeArr[0]).format('YYYY年MM月DD日'); 
            }
            if (timeArr[1]) {
                e = dayjs(timeArr[1]).format('YYYY年MM月DD日'); 
            }

            return s +' 至 '+ e
        },
        getIndex(row){
            row += 1
            const {current, size} = this.page
            return (current - 1) * size + row
        }
    }
}
</script>
<style lang="scss" scoped>
.mainView {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.flexRowCenter {
    display: flex;
    align-items: center;
}

.dateType {
    cursor: pointer;
    color: #595959;
    font-size: 16px;
    margin-right: 24px;
    font-weight: 600;
    line-height: 24px;
}

.dateTypeSelect {
    color: #262626;
    border-bottom: 2px solid #F5222D;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
}

.divider {
    height: 16px;
}

.eachRow {
    padding: 12px 24px;
    background-color: #fff;
    border-radius: 4px;
}

.titleName {
    font-size: 16px;
    font-weight: 600;
    color: #262626;
}

.timeText {
    font-size: 12px;
    font-weight: 400;
    color: #8c8c8c;
    margin-left: 16px;
}
.columnflexRowCenter{
    display: flex;
    align-items: center;
    div:nth-child(1){
        padding-left: 0px;
    }
    div:last-child{
        border-right: none;
    }
    .columnView{
        display: flex;
        flex-direction: column;
        // flex: 1;
        border-right: 1px solid #eee;
        padding-right: 16px;
        padding-left: 16px;
        .columnViewTitle{
            color: #595959;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 12px;
        }
    }
}
.flexRowEnd{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .prefix{
        color: #262626;
        font-size: 18px;
        margin-bottom: 2px;
    }
    .money{
        font-size: 26px;
        color: #262626;
        margin: 0px 2px;
    }
    .endfix{
        color: #8C8C8C;
        font-size: 14px;
        margin-bottom: 2px;
    }
}
.flex1{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-size: 14px;
    color: #8c8c8c;
    height: 100%;
    display: flex;
    align-items: center;
}
.flex2{
    display: flex;
    flex: 2;
    justify-content: flex-start;
    font-size: 14px;
    color: #595959;
    padding-left: 16px;
}
.orderNumberText{
    font-size: 20px;
    font-weight: 500;
    color: #262626;
    border-left:  1px solid #f2f2f2;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 16px;
}
.orderTypeText{
    font-size: 14px;
    color: #8c8c8c;
    margin-left: 3px;
}
.orderHeader{
    height: 108px;
    background-color: #F5F5F5;
    margin: 14px 0px;
    display: flex;
    flex-direction: column;
    border: 1px solid #f2f2f2;
}
.orderRow{
    display: flex;
    align-items: center;
    flex: 1;
    background-color: #ffffff;
}
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
    width: 280px;
}
</style>