<template>
  <div class="goodsDetailDlg">
    <el-dialog
      title="搜索明细数据"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
    >
      <div class="goods-info">
        <div class="title">关键词</div>
        <div class="info">{{ goodsDetail.name }}</div>
      </div>
      <div class="goods-detail">
        <div class="title">搜索明细</div>
        <el-tabs v-model="tabPosition" @tab-click="tabChange" size="mini">
          <el-tab-pane label="搜索趋势" name="searchTrend" />
          <el-tab-pane label="推荐SKU" name="recommendSKU" />
        </el-tabs>
        <div v-if="tabPosition == 'searchTrend'">
          <el-radio-group
            v-model="trendTabPosition"
            style="margin-bottom: 10px"
            size="mini"
            @input="trendChange"
          >
            <el-radio-button label="day">当日趋势</el-radio-button>
            <el-radio-button label="month">最近30天趋势</el-radio-button>
          </el-radio-group>

          <highcharts :options="chartOptions" v-if="chartOptions" />
        </div>
        <div v-if="tabPosition == 'recommendSKU'">
          <el-form
            ref="formTool"
            label-width="90px"
            :model="formTool"
            size="small"
            :inline="true"
          >
            <el-row style="display: flex">
              <el-form-item label="">
                <el-input v-model="formTool.name" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="">
                <el-date-picker
                  v-model="formTool.date"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  size="small"
                  range-separator="-"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                />
              </el-form-item>
              <div class="btn-box">
                <el-button type="primary" size="small" @click="getList(1)"
                  >查 询</el-button
                >
                <el-button size="small" @click="handleReset">重 置</el-button>
                <el-button size="small" style="float: right" @click="handleExport"
                  >导出</el-button
                >
              </div>
            </el-row>
          </el-form>
          <div
            class="center"
            :style="contentHeight ? `height:${contentHeight}px!important` : ''"
          >
            <MyTable
              :table-data="tableData"
              :index="index"
              :selection="selection"
              :page="page"
              :operation="operation"
              :table-option.sync="tableOption"
              @page-change="getList"
            >
            </MyTable>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MyTable from "@/components/myTable";

export default {
  components: { MyTable },
  data() {
    return {
      dialogVisible: false,
      formTool: {
        name: "",
        branch: "",
        date: [],
      },
      tableData: [],
      tabPosition: "searchTrend",
      trendTabPosition: "day",
      operation: false,
      selection: false,
      index: false,
      contentHeight: 400,
      tableOption: [
        { label: "SKU编码", prop: "shopName" },
        { label: "商户名称", prop: "shopTypeName" },
        { label: "商品名称", prop: "loginUserName" },
        { label: "厂家", prop: "submitTime" },
        { label: "规格", prop: "auditTime" },
        { label: "活动类型", prop: "auditTime" },
        { label: "价格", prop: "address", sortable: "custom" },
        {
          label: "点击量",
          prop: "address",
          sortable: "custom",
          customHeader: "true",
          content: "当前关键词搜索结果页该SKU的时击次数合计",
        },
        {
          label: "点击转化率",
          prop: "address",
          sortable: "custom",
          customHeader: "true",
          content: "点击转化率=点击量/推荐次数*100%",
        },
      ],
      page: {
        total: 0,
        current: 1,
        size: 30,
        pageSizes: [30, 50, 100, 500],
      },
      goodsDetail: {
        name: "橘红颗粒",
      },
      chartOptions: undefined,
    };
  },
  async mounted() {
    await this.getData(1);
  },
  methods: {
    init(row, data) {
      this.dialogVisible = true;
      this.tableData = data;
      this.setTableSecond();
    },
    async getData(type, data) {},
    handleClose() {
      this.dialogVisible = false;
    },
    setTableSecond(arr, data) {
      const _arr = [
        { day: "0点", amount: 2 },
        { day: "1点", amount: 5 },
        { day: "2点", amount: 2 },
        { day: "3点", amount: 0 },
        { day: "4点", amount: 0 },
        { day: "5点", amount: 10 },
        { day: "6点", amount: 11 },
        { day: "7点", amount: 21 },
        { day: "8点", amount: 21 },
        { day: "9点", amount: 21 },
        { day: "10点", amount: 21 },
        { day: "11点", amount: 21 },
      ];
      // 设置销售趋势图表
      let tmp = [];
      let days = [];
      _arr.forEach((e) => {
        tmp.push(parseFloat(e.amount));
        days.push(e.id);
      });
      this.chartOptions = {
        colors: ["#F5222D"],
        credits: {
          enabled: false,
        },
        chart: {
          height: this.tableHeight - 24,
        },
        exporting: { enabled: false },
        title: {
          text: `<span style="color:#262626;font-size:16px;font-weight:600;">搜索量/点击量</span>`,
          align: "left",
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          spline: {
            marker: {
              radius: 4,
              lineColor: "#666666",
              lineWidth: 1,
            },
          },
          series: {
            dataLabels: {
              enabled: true,
              format: "{point.y:.2f}",
            },
          },
        },
        xAxis: {
          categories: days,
        },
        tooltip: {
          pointFormat: "<span> 销售额：{point.y:.2f}</span>",
        },
        series: [
          {
            name: "销售额",
            data: tmp,
          },
        ],
      };
    },
    handleReset() {
      this.chartOptions = undefined;
      this.formTool = {
        name: "",
        branch: "",
        date: [],
      };
    },
    tabChange() {},
    trendChange() {},
  },
};
</script>

<style lang="scss" scoped>
.goodsDetailDlg {
  .goods-info,
  .goods-detail {
    .title {
      font-size: 16px;
      font-weight: bold;
      border-left: 3px solid red;
      padding-left: 10px;
      margin-bottom: 24px;
      color: #f6212d;
    }
  }
  .goods-detail {
    margin-top: 32px;
    .btn-box {
      margin-left: 12px;
      flex: 1;
    }
  }

  ::v-deep .el-tabs__header {
    height: 36px;
    line-height: 36px;
    margin-bottom: 16px !important;
  }
}
</style>
