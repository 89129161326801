<!--:span="24"
 * @FilePath: /eshop-ops-web/src/views/goodsManage/baseDepot/components/goodsInfo.vue
 * @Description: 
 * @Author: xiexingzhong2012@sina.cn
 * @Date: 2023-07-24 20:01:25
 * @LastEditTime: 2023-07-30 20:22:44
 * @LastEditors: xiexingzhong2012@sina.cn
-->
<template>
  <div class="">
    <div style="mainView">
      <el-row class="lable-list">
        <el-col :span="11">
          <el-form ref="goods-info" label-width="120px" size="small" :rules="rules" :model="formTool" :disabled="disabled"
            label-position="right">
            <!-- <formList
              ref="form-list"
              :formTool="formTool"
              :source="1"
            ></formList> -->
            <div class="">
              <el-col :span="24" v-if="$route.query.activeName != 'BASE_AWAIT'">
                <el-form-item label="驳回原因">
                  <el-input v-model="formTool.checkReason" type="textarea" :disabled="true" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="商品类型" prop="goodsType">
                      <el-radio-group v-model="formTool.goodsType" @change="changeType(formTool.goodsType)">
                        <el-radio v-for="item in formTool.typeList" :key="item.id" :label="item.id">{{ item.name
                        }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <el-form-item label="产品名称" prop="name">
                      <el-input v-model="formTool.name" placeholder="请输入" />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <el-form-item label="规格" prop="specification">
                      <el-input v-model="formTool.specification" placeholder="请输入" />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <el-form-item label="批准文号" prop="approvalNumber">
                      <el-input v-model="formTool.approvalNumber" placeholder="请输入" />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <el-form-item label="生产厂家" prop="factory">
                      <el-input v-model="formTool.factory" placeholder="请输入" />
                    </el-form-item>
                  </el-col>
                </el-row>


                <el-row v-if="showProducer">
                  <el-col :span="24">
                    <el-form-item label="产地" prop="origin">
                      <el-input v-model="formTool.origin" placeholder="请输入" />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <el-form-item label="品牌名" prop="brand">
                      <el-input v-model="formTool.brand" placeholder="请输入" />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <el-form-item label="单位" prop="unit"> 
                      <el-select v-model="formTool.unit" placeholder="请选择" size="small" style="width: 100%">
                        <el-option v-for="item in unitList" :key="item" :label="item" :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <!-- 商品分类属性 -->
                <dynamic-attribute ref="dynamic-attribute" :formTool="formTool" :isShowAll="true"></dynamic-attribute>

                <el-row>
                  <el-col :span="24">
                    <el-form-item label="图片" prop="imageList">
                      <imgUpload v-model="formTool.imageList" :limit="5" :isEdit="disabled" />
                      <div class="upload-tips">
                        上传图片大小需低于2M，图片格式PNG,JPG,JPEG，图片尺寸建议800
                        x 800px
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </div>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="2">
      <el-col :span="24">
        <el-form label-width="120px" size="small" :disabled="true" label-position="right">
          <el-form-item label="商品详情">
            <wangEnduit :value="formTool.detail" :is-clear="isClear" @changeEndit="watchEndit" />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <div class="mt20">
      <el-button type="primary" style="width: 160px" size="small" @click="submit" v-if="!disabled">保存并提交</el-button>
      <el-button size="small" style="width: 160px" @click="back()">返回</el-button>
    </div>
  </div>
</template>
<script>
// ON_SALE:销售中，
// MERCHANT_REMOVAL.商家下架，
// PLATFORM_REMOVAL:平台下架，
// BASE_AWAIT:基库待审核，
// BASE_ALREADY:审核通过，
// SKU_AWAIT：上架待审核，
// SKU_FAIL：商品审核未通过，
// BASE_TEMPORARY_FAIL：基库审核未通过
import imgUpload from "@/components/imgUpload";
import fileUpload from "@/components/fileUpload";
import wangEnduit from "@/components/wangEnduit";
import DynamicAttribute from "@/components/dynamicAttribute";
import GoodsThreeClass from "@/components/goodsThreeClass";
import getComList from "@/utils/getComList";


import {
  getAttributeByTypeId,
  getGoodsTemporaryDetail,
} from "@/api/goodsManage/baseDepot.js";
import {
  getunitList, 
} from "@/api/productManage/auditProduct";
export default {
  components: {
    imgUpload,
    wangEnduit,
    fileUpload,
    DynamicAttribute,
    GoodsThreeClass, //商品三级分类

  },
  props: {},
  data() {
    return {
      unitList:[],
      isReady: true,
      id: null,
      status: null,
      pageType: null,
      disabled: false,
      formTool: {
        goodsType: null,
        details: null, // 商品详情
        goodsOneClassId: null,
        goodsTwoClassId: null,
        goodsThreeClassId: null,
        typeList: [],
        name: null, // 产品名称
        imageList: [], // 图片
        fileListStr: null,
        fileList: [],
        extendParams: [], //拓展属性
        businessScopeId: null, //经营范围
        specification: null,
        approvalNumber: null,
        factory: null,
        origin: '', //产地
        brand: '', //品牌名
        unit:null,//单位
      },
      rules: {
        goodsType: [
          { required: true, message: "请输入商品类型", trigger: "blur" },
        ],
        goodsOneClassId: [
          { required: true, message: "请输入第一级分类", trigger: "change" },
        ],
        goodsTwoClassId: [
          { required: true, message: "请输入第二级分类", trigger: "change" },
        ],
        goodsThreeClassId: [
          { required: true, message: "请输入第三级分类", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入产名称", trigger: "blur" }],
        businessScopeId: [
          { required: true, message: "请输入经营范围", trigger: "blur" },
        ],
        specification: [
          { required: true, message: "请输入规格", trigger: "blur" },
        ],
        approvalNumber: [
          { required: true, message: "请输入批准文号", trigger: "blur" },
        ],
        factory: [
          { required: true, message: "请输入生产厂家", trigger: "blur" },
        ],
        origin: [
          { required: true, message: "请输入产地", trigger: "blur" },
        ],
        imageList: [
          { required: true, message: "请上传图片", trigger: "change" },
        ],
        unit: [
          { required: true, message: "请选择单位", trigger: "change" },
        ],
      },
      isClear: false, // 清除富文本编辑内容
      changeExtendParams: {},
      showProducer: false,
    };
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    if (this.$route.query.status) {
      this.status = this.$route.query.status;
    }
    if (this.$route.query.type !== "edit") {
      this.disabled = true;
    }
    this.typeList();
    this.getunitList();
  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
  methods: {
        // 获取单位字典
        getunitList() {
      getunitList().then((res) => {
        if (res.code == 0) {
          this.unitList = res.data || [];
        }
      });
    },
    // 判断当前商品类型是否是中药饮片，是的话显示产地必填
    isShowProducer(goodsType, goodsTypeList) { 
      let show = false;
      for (let i = 0; i < goodsTypeList.length; i++) {
        if (goodsType == goodsTypeList[i].id && goodsTypeList[i].name.indexOf('中药') != -1) {
          console.log(goodsTypeList[i].name, '------------name') 
          show = true;
        }
      }
      this.showProducer = show;
    },

    async typeList() {
      let typeList = await getComList.goodsType; // 商品类型
      // this.formTool.scopeList = await getComList.scopeList; //经营范围
      let scopeList = await getComList.scopeList; //经营范围
      this.$set(this.formTool, "scopeList", scopeList);
      this.$set(this.formTool, "typeList", typeList);
      
      // 查询详情信息
      if (this.$route.query.goodsTemporaryId != undefined) {
        this.id = this.$route.query.goodsTemporaryId;
        this.getDetail(this.id);
      }else if (this.formTool.typeList.length > 0) {
        this.formTool.goodsType = this.formTool.typeList[0].id;
      }
      this.$forceUpdate();
    },

    back() {
      this.$emit("back");
    },

    /**
     * @description: 初始化加载 配置的 list： 1. 商品类型 2. 经营范围
     * @return {*}
     */
    formatterStr(formTool) {
      if (formTool.fileListStr && formTool.fileListStr.indexOf("http") != -1) {
        formTool.fileList = this.strTofileList(formTool.fileListStr);
      }
      if (formTool.imageList && formTool.imageList.indexOf("http") != -1) {
        formTool.imageList = this.strToArr(formTool.imageList);
      } else {
        formTool.imageList = [];
      }
    },
    changeType(typeId) {
      this.getAttributeByTypeId(typeId);
    },
    getAttributeByTypeId(typeId) {
      getAttributeByTypeId({
        typeId: typeId,
        isMerchant: false, //是否根据商户去查，运营端传false 查全部
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let extendParams = res.data;

          for (let i = 0; i < extendParams.length; i++) {
            if (extendParams[i].input == "2") {
              extendParams[i].selectList =
                extendParams[i].selectEnum.split("，");
            }
            if (extendParams[i].input == "3") {
              extendParams[i].selectList =
                extendParams[i].selectEnum.split(",");
            }
            extendParams[i].value = "";
          }
          this.formTool.extendParams = extendParams;
          this.$forceUpdate();
          this.$nextTick(() => {
            this.$refs["goods-info"].clearValidate();
            this.strTofileList(this.formTool.fileListStr);
             this.isShowProducer(this.formTool.goodsType, this.formTool.typeList); 
          });

        } else {
          this.$message({
            message: res.msg,
            type: "erro",
          });
        }
      });
    },

    getDetail(id) {
      /**
       * @description:   // 1.获取商品详情信息，2.映射到formTool 对象 。
       * @return {*}
       */
      const params = {
        id: id,
        isMerchant: true, //false 查所有拓展属性 true查询必填
      };
      getGoodsTemporaryDetail(params).then((res) => {
        if (res.code === 0) {
          this.$nextTick(() => {
            // 基库详情
            let compGoodsTemporaryEntity = res.data.compGoodsTemporaryEntity;
            this.formTool = Object.assign(
              this.formTool,
              compGoodsTemporaryEntity
            ); //合并2个对象
            this.formTool.extendParams = res.data.extendParams;
            this.$nextTick(() => {
              this.$set(
                this.formTool,
                "extendParams",
                res.data.extendParams
              );
              this.extendParamsFormatter(this.formTool);
            });
 
            this.formatterStr(this.formTool); //数据类型转换
            // console.log(this.formTool.goodsType,'--this.formTool.goodsType')
             this.isShowProducer(this.formTool.goodsType, this.formTool.typeList);
          });  
          this.$forceUpdate();
        }
        // this.isReady = false; //赋值后开启校验。
      });
    },

    extendParamsFormatter() {
      for (let i = 0; i < this.formTool.extendParams.length; i++) {
        if (this.formTool.extendParams[i].input == "2") {
          this.formTool.extendParams[i].selectList =
            this.formTool.extendParams[i].selectEnum.split("，");
        }
        if (this.formTool.extendParams[i].input == "3") {
          this.formTool.fileList = this.strTofileList(
            this.formTool.extendParams[i].value
          );
        }
      }
    },

    /**
     * @functionName: onRemoveHandler
     * @description: 删除上传的文件
     * @return {*}
     */
    onRemoveHandler(file) {
      let index = this.formTool.fileList.indexOf(file);
      this.formTool.fileList.splice(index, 1);
    },

    /**
     * @description: submit 方法 提交form 数据给业务调用方
     * @return {*} fromlToll
     */
    submit() {
      const params = Object.assign({}, this.formTool);

      let fileListStr = "";

      if (this.formTool.fileList) {
        for (let j = 0; j < this.formTool.fileList.length; j++) {
          fileListStr += this.formTool.fileList[j].url + ",";
        }
      }

      this.formTool.fileListStr = fileListStr;

      if (this.formTool.fileList) {
        for (let i = 0; i < this.formTool.extendParams.length; i++) {
          if (
            this.formTool.fileList.length > 0 &&
            this.formTool.extendParams[i].input == "3"
          ) {
            this.formTool.extendParams[i].value = this.formTool.fileListStr;
          }
        }
      }

      if (this.formTool.extendParams) {
        let extendParamsList = [...this.formTool.extendParams];
        let extendParamsObj = {};
        for (let i = 0; i < extendParamsList.length; i++) {
          extendParamsObj[extendParamsList[i].fieldName] =
            extendParamsList[i].value;
        }
        params.extendParams = extendParamsObj;
      } else {
        this.formTool.extendParams = [];
      }

      params.image = params.imageList[0];
      params.imageList = params.imageList.join();

      // console.log(params);
      const {
        name,
        goodsOneClassId,
        goodsTwoClassId,
        goodsThreeClassId,
        imageList,
        goodsType,
        extendParams,
        businessScopeId,
        specification,
        approvalNumber,
        factory,
        detail,
        image,
        goodsExtendId,
        origin,
        brand,
        unit
      } = params;
      let paramsData = {
        name,
        goodsOneClassId,
        goodsTwoClassId,
        goodsThreeClassId,
        imageList,
        goodsType,
        businessScopeId,
        specification,
        approvalNumber,
        factory,
        detail,
        image,
        goodsExtendId,
        origin,
        brand,
        unit
      };
      if (this.id != undefined) {
        paramsData.id = this.id;
      }

      this.$refs["goods-info"].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "请填写完整信息！",
            type: "warning",
          });
        } else {
          this.$emit("submit-goods", {
            compGoodsTemporaryEntity: paramsData,
            extendParams: extendParams,
          });
        }
      });
    },

    // 驳回
    rejectGoods(row) {
      let params = {};
      this.$emit("reject-goods", parmas);
    },

    watchEndit(val) {
      // 监听富文本编辑传过来的数据
      this.formTool.detail = val;
    },

    // 上传图片的字符串转 list
    strToArr(str) {
      let list = [];
      let newStr = str;
      if (newStr.indexOf(",") != -1) {
        let arr = newStr.split(",");
        arr.map((item) => {
          if (item.indexOf("http") != -1) {
            list.push(item);
          }
        });
      } else if (newStr.indexOf("http") != -1) {
        list.push(newStr);
      }
      return list;
    },

    // 上传文件的字符串转 fileList
    strTofileList(fileListStr) {
      console.log(fileListStr);
      let fileList = [];
      let newStr = fileListStr;
      if (newStr && newStr.indexOf(",") != -1) {
        let arr = newStr.split(",");
        arr.map((item) => {
          if (item.indexOf("http") != -1) {
            fileList.push({
              name:
                item.indexOf(item) != -1
                  ? item.substring(item.lastIndexOf("/") + 1)
                  : item,
              url: item,
            });
          }
        });
      } else if (newStr && newStr.indexOf("http") != -1) {
        fileList.push({
          name: newStr.substring(newStr.lastIndexOf("/") + 1),
          url: newStr,
        });
      }
      return fileList;
    },
  },
};
</script>
<style lang="scss" scoped>
.mainView {
  width: 100%;
  display: flex;
}

.comContent {
  padding: 0px;
}

.addGoods {
  width: 100%;
  height: 40px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;

  .addFont {
    // border: 1px dashed #d9d9d9;
    display: flex;
    width: 18px;
    font-size: 16px;
    justify-content: center;
    margin-right: 5px;
  }
}

.header {
  height: 56px;
  line-height: 56px;
  padding: 0 24px;
  border-bottom: 1px solid #f5f5f5;
}

.product {
  // margin: 0 24px;
  margin-bottom: 24px;
}

.firstTitle {
  font-size: 16px;
  color: #262626;
  font-weight: 700;
}

.secondTitle {
  font-size: 14px;
  // color: #999999;
}

.upload-tips {
  font-size: 12px;
  color: #8c8c8c;
}
</style>
