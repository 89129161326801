<template>
    <div class="comContent">
        <div class="expand-form">
            <div style="width: 100%; margin-top: 20px;" class="flex-box">
                <div>
                    <el-button size="small" type="plain" @click="goBack">返回上一页</el-button>
                </div>

                <div>
                    <el-button size="small" type="primary" @click="CreateGift">新建</el-button>
                </div>
            </div>
        </div>

        <myTable :index="index" :selection="false" :page="page" :showpage="true" :operation="false" v-loading="loading"
            :table-data="tableData" :table-option.sync="tableOption" @page-change="getList" :expand="false">
            
            <template slot="operation" slot-scope="scope">
                <div style="margin: 0 0px">
                    <el-link type="primary" :underline="false" @click="HandlerClick(scope.row, 'detail')"
                        style="margin-right: 10px">
                        详情
                    </el-link>
                    <el-link type="primary" v-if="scope.row.status == 'PASS' || scope.row.status == 'SOLDOUT' || scope.row.status =='REJECT' " :underline="false" @click="HandlerClick(scope.row, 'edit')"
                        style="margin-right: 10px">
                        编辑
                    </el-link>
                    <el-link type="primary" :underline="false" @click="UpdateStatus(scope.row, 'PUTAWAY')"
                        v-if="scope.row.status == 'PASS' || scope.row.status == 'SOLDOUT'" style="margin-right: 10px">
                        上架活动
                    </el-link>
                    <el-link type="primary" :underline="false" @click="UpdateStatus(scope.row, 'SOLDOUT')"
                        v-if="scope.row.status == 'PUTAWAY'" style="margin-right: 10px">
                        下架活动
                    </el-link>
                </div>
            </template>
            <template slot="status" slot-scope="scope">
                <div v-if="scope.row.status == 'PASS'">审核通过</div>
                <div v-if="scope.row.status == 'CHECKING'">待审核</div>
                <div v-if="scope.row.status == 'REJECT'">审核不通过</div> 
                <div v-if="scope.row.status == 'PUTAWAY'">上架</div> 
                <div v-if="scope.row.status == 'SOLDOUT'">下架</div> 
            </template>
        </myTable>
        <goodsDialog ref="goodsDialog" />
    </div>
</template>
<script>


import myTable from "@/components/myTable";
import goodsDialog from "./components/goodsDialog";
import { giftPayeeList ,giftAudit} from "@/api/gift";
// import { skuStatus, } from '@/api/commodityList/index'
export default {
    components: { myTable, goodsDialog },
    props: {},
    data() {
        return {
            expanded: false,
            isExpanded: false,
            loading: false,
            operation: true,
            selection: true,
            index: false,
            page: {
                total: 0,
                current: 1,
                size: 10,
                pageSizes: [10, 20, 30, 50],
            },
            tableData: [],
            tableOption: [
                { label: "促销标题", prop: "promotionTitle", },
                { label: "原品", prop: "goodsSkuName", },
                { label: "赠品", prop: "giftSkuName", },
                // { label: "赠品数量", prop: "qty", },
                { label: "促销区域", prop: "promotionArea", },
                { label: "审核状态", prop: "status", slot:true },
                { label: "促销开始时间", prop: "startTime", },
                { label: "促销结束时间", prop: "endTime", },
                { label: "添加时间", prop: "createTime", },
                { label: "操作", prop: "operation", slot: true },
            ],
        };
    },

    created() { },
    mounted() {
        this.getList();
    },
    methods: {
        // 上架
        listing(item) {
            let params = {
                id: item.id,
                status: 'PUTAWAY'
            }
            giftAudit(params).then(res => {
                if (res) {
                    this.$message.success('上架成功')
                    this.getList()
                } else {
                    this.$message.success(res.msg)
                }
            })
        },
        // 下架
        delist(item) {
            let params = {
                id: item.id,
                status: 'SOLDOUT'
            }
            giftAudit(params).then(res => {
                if (res) {
                    this.$message.success('下架成功')
                    this.getList()
                } else {
                    this.$message.success('下架失败')
                }
            })
        },
        // 选择商品
        chooseGood(row) {
            this.dialogVisible = true;
            this.rowData = row;
        },
        choice(val) {
            this.rowData.skuList = val
            // this.beforeClose()
        },
        getList(current) {
            if(!this.$route.query.goodsSkuId || this.$route.query.goodsSkuId == 'null') return
            if (current) {
                this.page.current
            }
            let params = {};
            params.current = this.page.current;
            params.size = this.page.size;
            params.goodsSkuId = this.$route.query.goodsSkuId || '';
            giftPayeeList(params).then((res) => {
                console.log(res, '--res')
                this.tableData = res.records;
            }).catch((err) => { })
        },

        goBack() {
            this.$router.back()
        },
        UpdateStatus(row, type) {
            if (type == 'PUTAWAY') {
                this.listing(row)
            }
            if (type == 'SOLDOUT') {
                this.delist(row)
            }
        },
        HandlerClick(row, type) {
            if (type == 'edit') {
                this.$router.push(`./salesControl-fullGift-createGift?id=${row.id}&type=${type}&goodsSkuId=${this.$route.query.goodsSkuId}&goodsId=${this.$route.query.goodsId}`);
            }
            if (type == 'detail') {
                this.$router.push(`./salesControl-fullGift-createGift?id=${row.id}&type=${type}&goodsSkuId=${this.$route.query.goodsSkuId}&goodsId=${this.$route.query.goodsId}`);
            }
        },
        CreateGift() {
            this.$router.push(`./salesControl-fullGift-createGift?goodsSkuId=${this.$route.query.goodsSkuId}&goodsId=${this.$route.query.goodsId}`);
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep.el-table {
    height: calc(100% - 138px) !important;
}

::v-deep.zt__table {
    height: calc(100% - 118px) !important;
}

::v-deep.el-table .el-table__body-wrapper {
    height: calc(100% - 78px) !important;
    overflow-y: scroll !important;
}

.expand-form {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.flex-box {
    display: flex;
    justify-content: space-between;
}
</style>
  