<template>
  <div class="select-type-root grid">
    <div :class="{ item: true, focus: selectedIndex === index }"
      v-for="(item, index) in beMainDept ? typeList : typeList2" :key="index" @click="handleSelect(index)">
      <el-image :src="bgIcon" class="bg" />
      <el-image :src="item.url" class="icon" />
      <div class="right-content">
        <div class="title">{{ item.label }}</div>
        <div class="desp">{{ item.remarks }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const IConst_icon = require("@/assets/chatservice.png");
const IConst_Bg = require("@/assets/public/type_bg.png");
const IConst_G1 = require("@/assets/public/g1.png");
const IConst_G2 = require("@/assets/public/g4.png");
const IConst_G3 = require("@/assets/public/g2.png");
const IConst_G4 = require("@/assets/public/g3.png");

import { queryDeptShopInfoByLogUser } from "@/api/salesControl/commodity";
export default {
  data() {
    return {
      icon: IConst_icon,
      bgIcon: IConst_Bg,

      selectedIndex: -1,
      typeList: [
        {
          label: "一口价",
          value: 1,
          remarks: "可以使用商家券",
          url: IConst_G1,
        },
        {
          label: "特价",
          value: 2,
          remarks: "不可以使用商家券",
          url: IConst_G2,
        },
        {
          label: "单品包邮",
          value: 3,
          remarks: "客户购买单品达到一定数量后单品直接包邮",
          url: IConst_G3,
        },
        {
          label: "赠品",
          value: 4,
          remarks: "可添加至其他活动中作为赠品销售",
          url: IConst_G4,
        },
      ],
      typeList2: [
        {
          label: "单品包邮",
          value: 3,
          remarks: "客户购买单品达到一定数量后单品直接包邮",
          url: IConst_G3,
        },
      ],
      
      beMainDept: true,
    };
  },
  mounted() {
    this.deptShopInfoByLogUser();
  },
  methods: {
    handleSelect(index) {
      this.selectedIndex = index; 
      if(this.beMainDept){
        this.$store.dispatch("publicGoods/setSlectType", this.typeList[index]); 
      }else{
        this.$store.dispatch("publicGoods/setSlectType", this.typeList2[index]); 
      }
      this.$emit("nextStep");
    },
    // 根据登录人查询部门信息
    deptShopInfoByLogUser() {
      queryDeptShopInfoByLogUser().then(res => {
        if (res) {
          if (res.beMainDept) {
            this.beMainDept = true;
          } else {
            this.beMainDept = false;
          }
        }
      }).catch((err) => { })
    }
  },
};
</script>

<style scoped lang="scss">
.select-type-root {
  &.grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .item {
      width: calc(25% - 16px);
      height: 99px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      margin-top: 16px;
      margin-left: 16px;
      padding: 16px;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      position: relative;
      cursor: pointer;

      &.focus {
        border: 1px solid #f5222d;
        border-radius: 4px;
        box-shadow: 0px 6px 10px 0px rgba(255, 77, 80, 0.2),
          0px 3px 4px -5px rgba(255, 77, 80, 0.5);
      }

      .bg {
        position: absolute;
        bottom: 0;
        right: 16px;
        width: 56px;
        height: 69px;
        z-index: 0;
      }

      .icon {
        width: 48px;
        height: 48px;
      }

      .right-content {
        position: relative;
        flex: 1;
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        z-index: 1;

        .title {
          font-size: 16px;
          font-weight: 600;
          color: #141b2b;
        }

        .desp {
          font-size: 14px;
          font-weight: 400;
          color: #595959;
          margin-top: 2px;
        }
      }
    }
  }
}
</style>
