var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "left", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.$index) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Title" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.title) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Author", width: "110", align: "left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.author))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Pageviews", width: "110", align: "left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.pageviews) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "status-col",
              label: "Status",
              width: "110",
              align: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm._f("statusFilter")(scope.row.status),
                        },
                      },
                      [_vm._v(_vm._s(scope.row.status))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "left",
              prop: "created_at",
              label: "Display_time",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("i", { staticClass: "el-icon-time" }),
                    _c("span", [_vm._v(_vm._s(scope.row.display_time))]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }