<template>
  <div class="dialog">
    <el-dialog
      title="操作历史"
      :visible.sync="visible"
      :lock-scroll="true"
      width="618px"
      :before-close="handleClose"
    >
      <el-form ref="formTool" :model="formTool" label-width="180px">
        <el-table
          :data="tableData"
          style="width: 100%; margin-bottom: 20px; min-height: 200px"
          row-key="id"
          :border="true"
          type="selection"
        >
          <el-table-column
            prop="billingTime"
            label="操作时间"
             width="200px"
          ></el-table-column>
          <el-table-column prop="billingType" label="操作"></el-table-column>
          <el-table-column prop="status" label="详情">
            <template slot-scope="scope">
              <!-- <span> {{ scope.row.status | billingStatusType }} </span> -->
              {{scope.row.status }}
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
        </el-table>
      </el-form>
      <div class="btnBox">
        <el-button size="small" @click="handleClose">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { updateRateRule } from "@/api/externalSalesRule";
import { queryBillingRecordVO } from "@/api/invoice";
const billingStatusOptions = [
  // { id: "", label: "全部" },
  { id: "FINISHED", label: "开票成功" },
  { id: "FAIL", label: "开票失败" },
  { id: "NOTINVOICED", label: "待开票" },
  { id: "PROCESS", label: "开票中" },
  { id: "OFFLINEBILLINGFINISHED", label: "线下开票" },
];

const billingStatusFilters = {
  billingStatusType: function (value) {
    if (!value) return "";
    const options = billingStatusOptions.find((option) => option.id === value);
    return options ? options.label : "";
  },
};
export default {
  components: {},
  props: {},
  computed: {
    visible() {
      return this.dialogShow;
    },
  },
  filters: billingStatusFilters,
  data() {
    return {
      tableData: [],
      formTool: {
        salesUserRate: "",
        rate: "",
        promoteRate: "",
      },
      dialogShow: false,
      areaList: [],
      newAreaList: [],
      promoteRateNew: "",
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.dialogShow = false;
    },
    submit() {},

    init(row) {
      console.log(row, "row");
      // this.recordId = row.recordId;
      this.getList(row);
      this.dialogShow = true;

    },
    getList(row) {
      let parmas = {
        recordId: row.recordId,
        orderCode: row.relatedOrderCode, 
      };
      queryBillingRecordVO(parmas).then((res) => {
        console.log(res,'---res')
        this.tableData = res;
      });
    },
  },
};
</script>
<style lang="scss">
.btnBox {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.el-table--border {
  border: 1px solid #f5f5f5 !important;
  border-bottom: 1px solid #f5f5f5 !important;
}
</style>
