<template>
  <div class="classification">
    <div class="header">
      <el-form ref="formTool" :model="formTool" :inline="true" @submit.native.prevent style="text-align: left">
        <el-form-item prop="name" label="">
          <el-input style="width: 260px" v-model="formTool.name" placeholder="请搜索业务员名称/业务员账号" clearable size="small" />
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)">查询</el-button>
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button size="small" type="primary" plain @click="goExport">导出</el-button>
        <el-button size="small" type="primary" @click="openOpDetail()">新建</el-button>
      </div>
    </div>
    <div class="center" style="position: relative">
      <MyTable :index="false" :table-data="tableData" :page="page" :table-option.sync="tableOption" @page-change="tablePageChange">
        <template slot="tools" slot-scope="scope">
          <div class="table-op-col">
            <el-link type="primary" :underline="false" size="small" @click="openOpDetail(2, scope.row)">编辑</el-link>
          </div>
        </template>
      </MyTable>
    </div>
    <OpDetail ref="opDetailRef" @onRefresh="handleRefresh" />
  </div>
</template>

<script>
import MyTable from "@/components/myTable/index.vue";
import OpDetail from "./dialog/op-detail.vue";
import { csoMemberPage, csoMemberExport } from "@/api/complianceCso/salesman";

export default {
  components: {
    MyTable,
    OpDetail,
  },
  data() {
    return {
      formTool: { name: "" },
      tableData: [],
      tableOption: [
        { label: "业务员名称", prop: "name" },
        { label: "业务员账号", prop: "userTel" },
        { label: "更新时间", prop: "updateTime" },
        { label: "创建时间", prop: "createTime" },
        { label: "操作", prop: "tools", slot: true, width: "80px" },
      ],
      page: { total: 0, current: 1, size: 10 },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    openOpDetail(action = 1, row = null) {
      this.$refs.opDetailRef.open({ action, row });
    },
    getList(num) {
      if (num) {
        this.page.current = num;
      }
      const { current, size } = this.page;
      let params = { current, size, ...this.formTool };
      this.listLoading = true;
      csoMemberPage(params)
        .then((res) => {
          this.tableData = res.records;
          this.page.total = res.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    handleRefresh() {
      this.getList(1);
    },
    tablePageChange() {
      this.getList();
    },
    handleReset() {
      for (let key in this.formTool) {
        this.formTool[key] = "";
      }
      this.getList(1);
    },
    goExport() {
      csoMemberExport(this.formTool).then(res => {
        console.log('导出信息成功', res);
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.classification {
  padding: 0px;
  background: white;

  .header {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);
  }

  .table-op-col {
    display: flex;
    align-items: center;

    .el-link {
      &+.el-link {
        margin-left: 8px;
      }
    }
  }
}
</style>