var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c("div", { staticClass: "eachRow" }, [_vm._m(0), _c("el-divider")], 1),
      _c(
        "div",
        { staticStyle: { padding: "24px 0" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "text-align": "left" },
              attrs: {
                model: _vm.form,
                inline: false,
                "label-width": "100px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "posterName", label: "海报名称" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      placeholder: "海报名称",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.form.posterName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "posterName", $$v)
                      },
                      expression: "form.posterName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "posterCopyWriter", label: "发圈文案" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "textarea",
                      placeholder: "发圈文案",
                      clearable: "",
                      size: "small",
                      autosize: "",
                    },
                    model: {
                      value: _vm.form.posterCopyWriter,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "posterCopyWriter", $$v)
                      },
                      expression: "form.posterCopyWriter",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "posterName", label: "海报内容" } },
                [
                  _c(
                    "imgUpload",
                    {
                      staticStyle: { position: "relative", top: "0px" },
                      attrs: { isEdit: _vm.disabled, limit: 9 },
                      on: { onRemoveHandler: _vm.onRemoveHandler },
                      model: {
                        value: _vm.form.image,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "image", $$v)
                        },
                        expression: "form.image",
                      },
                    },
                    [
                      _c("template", { slot: "tip" }, [
                        _c("p", { staticClass: "el-upload__tip" }, [
                          _vm._v(
                            " 备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过10M "
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "posterName", label: " " } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.chooseGoods()
                        },
                      },
                    },
                    [_vm._v("选择产品")]
                  ),
                ],
                1
              ),
              _c(
                "section",
                { staticClass: "goods-list-warp" },
                [
                  _vm._l(_vm.selectedData, function (item, key) {
                    return _c("div", { key: key, staticClass: "goods-list" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-start",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "80px", height: "80px" },
                            attrs: { src: item.image || _vm.avater, alt: "" },
                          }),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "10px" } },
                            [
                              _c("p", { staticStyle: { margin: "3px 0" } }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                              _c("p", { staticStyle: { margin: "3px 0" } }, [
                                _vm._v(_vm._s(item.factory)),
                              ]),
                              _c("p", { staticStyle: { margin: "3px 0" } }, [
                                _vm._v(_vm._s(item.specification)),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ])
                  }),
                  _vm.id && this.form.checkStatus != "1"
                    ? _c(
                        "div",
                        [
                          _c("el-input", {
                            staticStyle: { width: "50%" },
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入内容",
                              disabled: true,
                            },
                            model: {
                              value: _vm.form.checkOpinion,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "checkOpinion", $$v)
                              },
                              expression: "form.checkOpinion",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "footer-warp" },
                [
                  _c("el-divider"),
                  _c(
                    "div",
                    { staticClass: "footer-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submit()
                            },
                          },
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "12px" },
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.cancel()
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("associationGoods", {
        ref: "association-goods",
        on: { chooseGoodsEmit: _vm.chooseGoodsEmit },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titleView" }, [
      _c("div", { staticClass: "rowLine" }),
      _c("span", { staticClass: "eachRowTitle" }, [_vm._v("图文海报信息")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }