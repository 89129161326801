var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c(
        "div",
        { staticClass: "headerTop" },
        [
          _c(
            "el-form",
            {
              ref: "formTool",
              staticStyle: { "text-align": "left" },
              attrs: { model: _vm.formTool, inline: true },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("MyButton", {
                    attrs: {
                      type: "primary",
                      size: "small",
                      icon: "el-icon-plus",
                      text: "添加",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.roleEdit(false, "add")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("MyTable", {
            attrs: {
              "table-data": _vm.tableData,
              index: _vm.index,
              selection: _vm.selection,
              page: _vm.page,
              operation: _vm.operation,
              "table-option": _vm.tableOption,
            },
            on: {
              "update:tableOption": function ($event) {
                _vm.tableOption = $event
              },
              "update:table-option": function ($event) {
                _vm.tableOption = $event
              },
              "page-change": _vm.getList,
              handleSelectionChange: _vm.currentChange,
            },
            scopedSlots: _vm._u([
              {
                key: "caozuo",
                fn: function (scope) {
                  return [
                    _vm.permissions.sys_role_add
                      ? _c(
                          "el-link",
                          {
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.roleEdit(scope.row, "see")
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                    _vm.permissions.sys_role_edit
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "8px" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.roleEdit(scope.row, "edit")
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.permissions.sys_role_perm
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "8px" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.handlePermission(
                                  scope.row,
                                  scope.index
                                )
                              },
                            },
                          },
                          [_vm._v("权限")]
                        )
                      : _vm._e(),
                    _vm.permissions.sys_role_del
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "8px" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row, scope.index)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "myDialog",
          attrs: {
            visible: _vm.dialogPermissionVisible,
            "close-on-click-modal": false,
            title: "分配权限",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPermissionVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "默认页面：角色登录系统后默认打开的页面（若未分配权限、设置默认页面，会导致登陆后页面为空）"
            ),
          ]),
          _c(
            "div",
            { staticClass: "dialog-main-tree" },
            [
              _c("el-tree", {
                ref: "menuTree",
                staticClass: "filter-tree",
                attrs: {
                  data: _vm.treeData,
                  "default-checked-keys": _vm.checkedKeys,
                  "check-strictly": false,
                  props: _vm.defaultProps,
                  "filter-node-method": _vm.filterNode,
                  "node-key": "id",
                  "highlight-current": "",
                  "show-checkbox": "",
                  "default-expand-all": "",
                },
                on: { "node-click": _vm.getNodeKey },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ node, data }) {
                      return _c(
                        "div",
                        {
                          staticClass: "custom-tree-node",
                          staticStyle: { width: "100%" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c("div", [_vm._v(_vm._s(node.label))]),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.nodeCheckedId == data.id,
                                      expression: "nodeCheckedId == data.id",
                                    },
                                  ],
                                  staticClass: "addAccount",
                                },
                                [_vm._v("默认菜单")]
                              ),
                            ]
                          ),
                        ]
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "default" },
                  on: {
                    click: function ($event) {
                      return _vm.cancal()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.updatePermession(_vm.roleId)
                    },
                  },
                },
                [_vm._v("更 新")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("RoleEdit", { ref: "roleEdit", on: { sure: _vm.roleEditSure } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }