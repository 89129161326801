<template>
    <div class="mainView">
        <div class="flexRowCenter">
            <div class="titleDiv">销售定价</div>
            <div class="inputDiv" style="display: flex;align-items: center;">
                <el-input placeholder="请输入" size="small" v-model="form.price"
                    oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''">
                    <template slot="prefix">
                        <div class="prefixView">¥</div>
                    </template>
                </el-input>
                <span @click="openDaliog()" style="margin-left: 10px;">
                    <el-tag type="primary" size="medium" style="border:1px solid #F5222D;">
                        {{ form.goodsSkuUnit }}</el-tag>
                </span>
            </div>
        </div>
        <div class="flexRowCenter">
            <div class="flexRowCenter">
                <div class="titleDiv">合作医药公司</div>
                <div class="inputDiv">
                    <el-select size="small" v-model="form.shopName" @change="(val) => {
                        reservationChange(val, 1);
                    }
                        ">
                        <el-option v-for="item in medicineManager" :key="item.shopId" :label="item.shopName"
                            :value="item" />
                    </el-select>
                </div>
            </div>
            <div class="flexRowCenter">
                <div class="titleDiv">医药公司康贝</div>
                <div class="inputDiv">
                    <el-select size="small" v-model="form.merchantNo" @change="(val) => {
                        reservationChange(val, 1);
                    }
                        ">
                        <el-option v-for="item in form.merchantNoArr" :key="item" :label="item" :value="item" />
                    </el-select>
                </div>
            </div>
            <div class="flexRowCenter">
                <div class="titleDiv">销售金额</div>
                <div class="inputDiv">
                    <el-input placeholder="请输入" size="small" v-model="form.amount"
                        oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''"></el-input>
                </div>
            </div>
        </div>
        <div class="flexRowCenter">
            <div class="flexRowCenter">
                <div class="titleDiv">地区公司名称</div>
                <div class="inputDiv">
                    <el-select size="small" clearable v-model="form.city_shopName" @change="(val) => {
                        reservationChange(val, 2);
                    }
                        ">
                        <el-option v-for="item, index in cityManager" :key="item.shopId + index" :label="item.shopName"
                            :value="item" />
                    </el-select>
                </div>
            </div>
            <div class="flexRowCenter">
                <div class="titleDiv">地区公司康贝</div>
                <div class="inputDiv">
                    <el-select size="small" clearable v-model="form.city_merchantNo" @change="(val) => {
                        reservationChange(val, 2);
                    }
                        ">
                        <el-option v-for="item in form.city_merchantNoArr" :key="item" :label="item" :value="item" />
                    </el-select>
                </div>
            </div>
            <div class="flexRowCenter">
                <div class="titleDiv">服务费金额</div>
                <div class="inputDiv">
                    <el-input placeholder="请输入" clearable size="small" v-model="form.city_amount"
                        :disabled="!form.city_shopId"
                        oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''"></el-input>
                </div>
            </div>
        </div>
        <div class="flexRowCenter">
            <div class="flexRowCenter">
                <div class="titleDiv">省区公司名称</div>
                <div class="inputDiv">
                    <el-select size="small" v-model="form.serviceShopName" @change="(val) => {
                        reservationChange(val, 3);
                    }
                        ">
                        <el-option v-for="item in provinceManager" :key="item.shopId" :label="item.shopName"
                            :value="item" />
                    </el-select>
                </div>
            </div>
            <div class="flexRowCenter">
                <div class="titleDiv">省公司康贝</div>
                <div class="inputDiv">
                    <el-select size="small" v-model="form.serviceMerchantNo" @change="(val) => {
                        reservationChange(val, 3);
                    }
                        ">
                        <el-option v-for="item in form.serviceMerchantNoArr" :key="item" :label="item" :value="item" />
                    </el-select>
                </div>
            </div>
            <div class="flexRowCenter">
                <div class="titleDiv">地区需转省区公司</div>
                <div class="inputDiv">
                    <el-input placeholder="请输入" size="small" v-model="form.serviceAmount"
                        oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''"></el-input>
                </div>
            </div>
        </div>
        <div style="height: 30px;"></div>
        <div class="flexRowCenter">
            <div class="flexRowCenter"></div>
            <div class="flexRowCenter"></div>
            <div class="flexRowCenter">
                <div class="flexEnd">
                    <el-button size="small" type="primary" @click="saveInfo">保存</el-button>
                    <el-button size="small" type="default" @click="backPage">返回</el-button>
                </div>
            </div>
        </div>
        <el-dialog append-to-body title="选择单位" :visible.sync="dialogVisible" width="400px" :before-close="handleClose">
            <div class="content-box">
                <span :class="unitKey == key ? 'unit active' : 'unit'" v-for="(item, key) in unitList" :key="key"
                    @click="choseUnit(item, key)">{{ item }}</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="choseUnitSure">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {
    getAreaCompany,
    shopSelfBatchByProvince,
    getProductLineSkuList,
} from "@/api/saleRule/productLine";
export default {
    props: {
        customerDatas: {
            type: Array,
            default() {
                return [];
            },
        },
        orgCode: {
            type: String,
            default() {
                return '';
            },
        },
        unitList: {
            type: Array,
            default() {
                return [];
            },
        },
        goodsId: {
            type: String,
            default() {
                return '';
            },
        },
        goodsName: {
            type: String,
            default() {
                return '';
            },
        },

    },
    data() {
        return {
            form: {
                shopId: '',
                shopName: '',
                merchantNo: '',
                merchantNoArr: [],
                city_shopId: '',
                city_shopName: '',
                city_merchantNo: '',
                city_merchantNoArr: [],
                serviceShopId: '',
                serviceShopName: '',
                serviceMerchantNo: '',
                serviceMerchantNoArr: [],
                price: '',
                amount: '',
                city_amount: '',
                serviceAmount: '',
                goodsSkuUnit: "盒",
            },
            unitKey: '',
            goodsSkuUnit: '',
            dialogVisible: false,
            cityManager: [],
            provinceManager: [],
            medicineManager: [],
            productLineId: '',
        }
    },
    mounted() {
        this.getAreaCompanyFn()
    },
    methods: {
        resetData(){
            this.productLineId = ''
            this.form = {
                shopId: '',
                shopName: '',
                merchantNo: '',
                merchantNoArr: [],
                city_shopId: '',
                city_shopName: '',
                city_merchantNo: '',
                city_merchantNoArr: [],
                serviceShopId: '',
                serviceShopName: '',
                serviceMerchantNo: '',
                serviceMerchantNoArr: [],
                price: '',
                amount: '',
                city_amount: '',
                serviceAmount: '',
                goodsSkuUnit: "盒",
            }
        },
        // 设置下拉选择 值
        reservationChange(val, type) {
            //这样就可以传递自定义的参数了
            let arr = []
            if (val.merchantNo) {
                arr = val.merchantNo.split(',');
            }
            if (type == 1) {
                this.form.shopName = val.shopName;
                this.form.shopId = val.shopId;
                this.form.merchantNoArr = arr
                this.form.merchantNo = arr[0] || '';
                // 调用sku接口
                this.getProductLineSkuList(val.shopId, "select");
            }
            // console.log(this.form);
            if (type == 2) {
                this.form.city_shopName = val.shopName;
                this.form.city_shopId = val.shopId;
                this.form.city_merchantNoArr = arr;
                this.form.city_merchantNo = arr[0] || '';
            }
            if (type == 3) {
                this.form.serviceShopName = val.shopName;
                this.form.serviceShopId = val.shopId;
                this.form.serviceMerchantNoArr = arr;
                this.form.serviceMerchantNo = arr[0] || '';
            }
        },
        getProductLineSkuList(shopId) {
            let params = {
                goodsId: this.goodsId,
                shopId: shopId,
                orgCodeSet: [this.orgCode], //必传
            };
            console.log(params, "params");
            getProductLineSkuList(params)
                .then((res) => {
                    let resp = res;
                    this.productLineId = resp[0].productLineId;
                })
                .catch((err) => { });
        },

        openDaliog() {
            this.dialogVisible = true;
        },
        // 统一分账获取省地办
        getAreaCompanyFn() {
            let params = {
                orgCode: this.orgCode
            };
            getAreaCompany(params).then((res) => {
                this.cityManager = res.cityManager; //市级 公司 列表
                this.provinceManager = res.provinceManager; // 省级公司列表
                this.medicineManager = res.medicineManager; //医药公司列表
            });
        },
        handleClose() {
            this.dialogVisible = false;
        },
        // 点击选择单位choseUnit
        choseUnit(e, key) {
            // console.log(e, "e");  console.log(key, "key");
            this.unitKey = key;
            this.goodsSkuUnit = e;
        },

        // 确认选择单位
        choseUnitSure() {
            this.form.goodsSkuUnit = this.goodsSkuUnit;
            this.dialogVisible = false;
        },
        backPage() {
            this.$emit('closePage', '');
        },
        saveInfo() {
            console.log(this.form);
            if (!this.form.price) { this.$message.warning('请输入销售定价'); return; }
            if (!this.form.shopId) { this.$message.warning('请选择合作医药公司'); return; }
            if (!this.form.amount) { this.$message.warning('请输入销售金额'); return; }
            if (this.form.city_shopId) { 
                if (!this.form.city_amount) { this.$message.warning('请输入服务费'); return; }
            }
            if (!this.form.serviceShopId) { this.$message.warning('请选择省区公司'); return; }
            if (!this.form.serviceAmount) { this.$message.warning('请输入地区需转账省区'); return; }
            const { goodsId, goodsName } = this;
            let params = [];
            for (let index = 0; index < this.customerDatas.length; index++) {
                const item = this.customerDatas[index];
                let obj = {
                    goodsId: goodsId,
                    goodsName: goodsName,
                    goodsSkuUnit: this.form.goodsSkuUnit,
                    orgCode: item.orgCode,
                    orgName: item.orgName,
                    provinceName: item.provinceName,
                    provinceCode: item.provinceCode,
                    price: this.form.price,
                    customerShopId: item.customerShopId,
                    customerShopName: item.customerShopName
                };
                obj.status = item.status;
                obj.id = item.id ? item.id : null;
                obj.goodsSkuPriceId = item.goodsSkuPriceId ? item.goodsSkuPriceId : null;
                obj.productLineId = this.productLineId ? this.productLineId : null;
                obj.ledgerRulesId = item.ledgerRulesId ? item.ledgerRulesId : null;
                obj.drugLedgerRules = { shopId: this.form.shopId, shopName: this.form.shopName, merchantNo: this.form.merchantNo, amount: this.form.amount };
                obj.drugLedgerRules.costType = "DETAIL";
                obj.drugLedgerRules.includeService = false;
                obj.drugLedgerRules.id = item.drug_id ? item.drug_id : null;
                obj.drugLedgerRules.ledgerRulesId = item.drug_ledgerRulesId
                    ? item.drug_ledgerRulesId
                    : null;
                obj.cityLedgerRules = {
                    id: item.city_id ? item.city_id : null,
                    ledgerRulesId: item.city_ledgerRulesId
                        ? item.city_ledgerRulesId
                        : null,
                    amount: this.form.city_amount || null,
                    merchantNo: this.form.city_merchantNo,
                    shopId: this.form.city_shopId,
                    shopName: this.form.city_shopName,
                    includeService: this.form.city_shopName ? true : false,
                    costType: "SERVICE",
                };
                obj.cityLedgerRules = {
                    serviceShopId: this.form.serviceShopId,
                    serviceShopName: this.form.serviceShopName,
                    serviceAmount: this.form.serviceAmount,
                    serviceMerchantNo: this.form.serviceMerchantNo,
                    ...obj.cityLedgerRules,
                };
                params.push(obj);
            }
            this.productLineAreaPrices(params)
        },
        productLineAreaPrices(params) {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            shopSelfBatchByProvince(params)
                .then((res) => {
                    loading.close();
                    setTimeout(() => {
                        this.$message({
                            message: `提交成功！`,
                            type: "success",
                        });
                        this.$emit('addMutilSuccess','');
                    }, 2000);
                })
                .catch((err) => {
                    setTimeout(() => {
                        loading.close();
                    }, 2000);
                });
        },
    }
}
</script>
<style lang="scss" scoped>
.mainView {
    .flexRowCenter {
        display: flex;
        align-items: center;
        flex: 1;
        height: 60px;
    }

    .titleDiv {
        width: 120px;
    }

    .inputDiv {
        width: 150px;
        margin-left: 10px;
    }

    .flexEnd {
        width: 280px;
        justify-content: flex-end;
        display: flex;
        align-items: center;
    }

    .prefixView {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}</style>