var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("div", { staticClass: "expand-form" }, [
        _c(
          "div",
          {
            staticClass: "flex-box",
            staticStyle: { width: "100%", "margin-top": "20px" },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "plain" },
                    on: { click: _vm.goBack },
                  },
                  [_vm._v("返回上一页")]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.CreateGift },
                  },
                  [_vm._v("新建")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("myTable", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: {
          index: _vm.index,
          selection: false,
          page: _vm.page,
          showpage: true,
          operation: false,
          "table-data": _vm.tableData,
          "table-option": _vm.tableOption,
          expand: false,
        },
        on: {
          "update:tableOption": function ($event) {
            _vm.tableOption = $event
          },
          "update:table-option": function ($event) {
            _vm.tableOption = $event
          },
          "page-change": _vm.getList,
        },
        scopedSlots: _vm._u([
          {
            key: "operation",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  { staticStyle: { margin: "0 0px" } },
                  [
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.HandlerClick(scope.row, "detail")
                          },
                        },
                      },
                      [_vm._v(" 详情 ")]
                    ),
                    scope.row.status == "PASS" ||
                    scope.row.status == "SOLDOUT" ||
                    scope.row.status == "REJECT"
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "margin-right": "10px" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.HandlerClick(scope.row, "edit")
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        )
                      : _vm._e(),
                    scope.row.status == "PASS" || scope.row.status == "SOLDOUT"
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "margin-right": "10px" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.UpdateStatus(scope.row, "PUTAWAY")
                              },
                            },
                          },
                          [_vm._v(" 上架活动 ")]
                        )
                      : _vm._e(),
                    scope.row.status == "PUTAWAY"
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "margin-right": "10px" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.UpdateStatus(scope.row, "SOLDOUT")
                              },
                            },
                          },
                          [_vm._v(" 下架活动 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "status",
            fn: function (scope) {
              return [
                scope.row.status == "PASS"
                  ? _c("div", [_vm._v("审核通过")])
                  : _vm._e(),
                scope.row.status == "CHECKING"
                  ? _c("div", [_vm._v("待审核")])
                  : _vm._e(),
                scope.row.status == "REJECT"
                  ? _c("div", [_vm._v("审核不通过")])
                  : _vm._e(),
                scope.row.status == "PUTAWAY"
                  ? _c("div", [_vm._v("上架")])
                  : _vm._e(),
                scope.row.status == "SOLDOUT"
                  ? _c("div", [_vm._v("下架")])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("goodsDialog", { ref: "goodsDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }