var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-dlg-root" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog-common",
          attrs: {
            title: "关联商品",
            visible: _vm.dialogVisible,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.onClosed,
          },
        },
        [
          _c("div", [
            _c(
              "div",
              [
                _vm._v(" 商品名称： "),
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "输入商品名称", size: "small" },
                  on: { change: _vm.change },
                  model: {
                    value: _vm.dialogInput,
                    callback: function ($$v) {
                      _vm.dialogInput = $$v
                    },
                    expression: "dialogInput",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("MyTable", {
                  attrs: {
                    "table-data": _vm.dialogtableData,
                    index: _vm.dialogindex,
                    selection: false,
                    page: _vm.dialogpage,
                    operation: _vm.dialogoperation,
                    "table-option": _vm.dialogtableOption,
                  },
                  on: {
                    "update:tableOption": function ($event) {
                      _vm.dialogtableOption = $event
                    },
                    "update:table-option": function ($event) {
                      _vm.dialogtableOption = $event
                    },
                    "page-change": _vm.dialoggetList,
                    handleSelectionChange: _vm.currentChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "select",
                      fn: function (scope) {
                        return [
                          _c("el-checkbox", {
                            on: {
                              change: function ($event) {
                                return _vm.handleSelect(scope.$index)
                              },
                            },
                            model: {
                              value: scope.row.selected,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "selected", $$v)
                              },
                              expression: "scope.row.selected",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "posterName",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-start",
                                "align-items": "center",
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "80px", height: "80px" },
                                attrs: {
                                  src: scope.row.image || _vm.avater,
                                  alt: "",
                                },
                              }),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "10px" } },
                                [
                                  _c(
                                    "p",
                                    { staticStyle: { margin: "3px 0" } },
                                    [_vm._v(_vm._s(scope.row.name))]
                                  ),
                                  _c(
                                    "p",
                                    { staticStyle: { margin: "3px 0" } },
                                    [_vm._v(_vm._s(scope.row.factory))]
                                  ),
                                  _c(
                                    "p",
                                    { staticStyle: { margin: "3px 0" } },
                                    [_vm._v(_vm._s(scope.row.specification))]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "price",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              "￥" +
                                _vm._s(scope.row.lowestSalePrice) +
                                "~￥" +
                                _vm._s(scope.row.highestSalePrice)
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }