import request from '@/utils/request'
// 创建会话
export function createChat(data) {
    return request({
        url: `/im/create/session`,
        method: "POST",
        data
    });
}

// 发送消息
export function sendChat(data) {
    return request({
        url: `/im/send/news`,
        method: "POST",
        data
    });
}


// 发送消息
export function getChatinfo(data) {
    return request({
        url: `/im/chat/center`,
        method: "GET",
        params: data
    });
}

//获取买卖双方的订单

export function purchaseWait(data) {
    return request({
        url: `/order/order/purchase/wait`,
        method: "GET",
        params: data
    });
}