<template>
  <div class="comContent">
    <div class="header">
      <el-form
        ref="searchForm"
        :model="searchForm"
        :inline="true"
        style="text-align: left"
      >
        <el-form-item prop="name">
          <el-input
            v-model="searchForm.name"
            placeholder="请输入活动名称"
            clearable
            maxlength="20"
            size="small"
          />
        </el-form-item>
        <el-form-item prop="state">
          <el-select
            size="mini"
            v-model="searchForm.state"
            placeholder="请选择活动状态"
          >
            <el-option
              v-for="item in statusList"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="searchDate">
          <el-date-picker
            v-model="searchDate"
            value-format="yyyy-MM-dd"
            type="daterange"
            size="small"
            range-separator="-"
            start-placeholder="拼团开始"
            end-placeholder="拼团结束"
          />
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="search(1)"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="reset('searchForm')">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="add()">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <MyTable
        :table-data="tableData"
        :index="index"
        :selection="selection"
        :page="page"
        :operation="operation"
        :table-option.sync="tableOption"
        @page-change="getList" 
      >
        <template slot="state" slot-scope="scope">
          <el-row>
            {{ getstatusVal(scope.row.state) }}
          </el-row>
        </template>
        <template slot="menu" slot-scope="scope">
          <div class="btn-warp">
            <el-link
              :underline="false"
              style="margin-right: 10px"
              type="primary"
              v-if="scope.row.state === 0"
              @click="release(scope.row)"
              >发布</el-link
            >
            <el-link
              :underline="false"
              style="margin-right: 10px"
              type="primary"
              v-if="scope.row.state === 2"
              @click="closeGroup(scope.row)"
              >关闭</el-link
            >
            <el-link
              :underline="false"
              style="margin-right: 10px"
              type="primary"
              v-if="scope.row.state === 0 || scope.row.state === 1"
              @click="edit(scope.row)"
              >编辑</el-link
            >
            <el-link
              :underline="false"
              style="margin-right: 10px"
              type="primary"
              v-if="scope.row.state === 0"
              @click="del(scope.row)"
              >删除</el-link
            >
            <el-link
              :underline="false"
              style="margin-right: 10px"
              type="primary"
              v-if="scope.row.state === 3 || scope.row.state === 4 || scope.row.state === 2"
              @click="detail(scope.row)"
              >详情</el-link
            >
          </div>
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import {
  closeGroupBuy,
  delGroupBuy,
  groupBuyList,
  groupBuyListLimit,
  publishGroupBuy,
} from "@/api/spellGroup/spellGroupActive";
export default {
  name: "spellGroup",
  components: { MyTable },
  data() {
    return {
      searchForm: {
        name: "",
        state: null,
      },
      statusList: [
        {
          value: 0,
          label: "待发布",
        },
        {
          value: 1,
          label: "待开始",
        },
        {
          value: 2,
          label: "进行中",
        },
        {
          value: 3,
          label: "拼团成功",
        },
        {
          value: 4,
          label: "拼团失败",
        },
      ],
      operation: true,
      selection: false,
      index: false,
      tableData: [],
      tableOption: [
        { label: "活动名称", prop: "name" },
        { label: "成团人数", prop: "minMemberCount" },
        { label: "拼团开始时间", prop: "startDate" },
        { label: "拼团结束时间", prop: "endDate" },
        { label: "拼团创建时间", prop: "createTime" },
        { label: "活动状态", prop: "state", slot: true },
      ],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      searchDate: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search(current) {
      if (current) {
        this.page.current = current;
      }
      this.getList();
    },
    reset(formName) {
      this.$refs[formName].resetFields();
      this.searchDate = [];
      this.getList();
    },
    getList() {
      const params = {
        ...this.searchForm,
        current: this.page.current,
        startDate:
          !this.searchDate || this.searchDate.length == 0
            ? ""
            : this.searchDate[0], // 开始时间
        endDate:
          !this.searchDate || this.searchDate.length == 0
            ? ""
            : this.searchDate[1], // 结束时间
        size: this.page.size,
      };
      groupBuyList(params).then((res) => {
        this.tableData = res;
      });
      this.getLimit(params);
    },
    getLimit(params) {
      groupBuyListLimit(params).then((res) => {
        this.page.total = +res || 0;
      });
    },
    // 新建
    add() {
      this.$router.push({
        path: "/spellGroup/spellGroup-spellGroupList-detail",
      });
    },
    // 编辑
    edit(row) {
      this.$router.push({
        path: "/spellGroup/spellGroup-spellGroupList-detail",
        query: {
          type: 1,
          id: row.id,
        },
      });
    },
    // 删除
    del(row) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delGroupBuy({ id: row.id }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getList();
          });
        })
        .catch(() => {});
    },
    // 详情
    detail(row) {
      this.$router.push({
        path: "/spellGroup/spellGroup-spellGroupList-detail",
        query: {
          type: 2,
          id: row.id,
        },
      });
    },
    getstatusVal(status) {
      return this.statusList[status].label;
    },
    // 发布
    release(row) {
      publishGroupBuy({ id: row.id }).then((res) => {
        if (+res.code === 0) {
          this.$message({
            message: "发布成功",
            type: "success",
          });
          this.getList();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 关闭
    closeGroup(row) {
      closeGroupBuy({ id: row.id }).then((res) => {
        if (+res.code === 0) {
          this.$message({
            message: "已关闭",
            type: "success",
          });
          this.getList();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 16px 0 8px;
}
::v-deep .el-form-item {
  margin-bottom: 0px!important;
}
::v-deep .el-form-item__content {
  line-height: 32px;
}
::v-deep .el-table {
   
}
.content {
   height: calc(100vh - 264px);
}
::v-deep .pagination-container {
  padding-bottom: 24px;
}
::v-deep .el-table__body-wrapper {
  height: calc(100% - 48px)!important;
}

</style>
