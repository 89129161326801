<template>
  <!--售后设置，独立售后（部门） -->
  <div class="content">
    <div class="show-manage-warp">
      <el-form :rules="rules" :model="formIn" ref="shopForm" :disabled="isDisabled"> 
        <div class="form shop-manage">
          <div class="title">
            <span class="text">售后信息</span>
          </div>
          <div class="block-content">
            <el-form-item prop="consignee" label="收货人" label-width="120px">
              <el-input size="small" placeholder="请输入" v-model="formIn.consignee" maxlength="25" style="width: 320px;"/>
            </el-form-item>
            <el-form-item prop="consigneePhone" label="电话" label-width="120px">
              <el-input size="small" placeholder="请输入" v-model="formIn.consigneePhone" maxlength="12"  style="width: 320px;"/>
            </el-form-item>
            <el-form-item label="省市区" label-width="120px" prop="provinceName">
              <div>
                <el-select size="mini"
                             v-model="formIn.provinceName"
                             placeholder="省"
                             @change="selectedHandle"
                             style="width: 320px;margin-right: 5px;">
                    <el-option v-for="item in provinceData"
                               :label="item.rname"
                               :value="item"
                               :key="item.id"></el-option>
                  </el-select>
              </div>
              <div>
                <el-select size="mini"
                            v-model="formIn.cityName"
                            placeholder="市"
                            @change="selectedHandleCity"
                            style="width: 320px;margin-right: 5px;">
                  <el-option v-for="item in cityData"
                              :label="item.rname"
                              :value="item"
                              :key="item.id"></el-option>
                </el-select>
              </div>
              <div>
                <el-select size="mini"
                  v-model="formIn.areaName"
                  @change="selectedHandleArea"
                  placeholder="区"
                  style="width: 320px;margin-right: 5px;">
                  <el-option v-for="item in areaData"
                              :label="item.rname"
                              :value="item"
                              :key="item.id"></el-option>
                </el-select>
              </div>
         </el-form-item>  
            <el-form-item prop="consigneeAddress" label="收货地址" label-width="120px">
              <el-input size="small" placeholder="请输入" v-model="formIn.consigneeAddress" maxlength="100"  style="width: 320px;" />
            </el-form-item>
          </div>
  
          <div class="title">
            <span class="text">退换货流程</span>
          </div>
          <div class="block-content">
            <el-form-item prop="changingRefundingInstructions" label="退换货流程" label-width="120px">
              <el-input type="textarea" maxlength="2000" show-word-limit resize="none" :rows="6" placeholder="请输入"  
                v-model="formIn.changingRefundingInstructions"></el-input>
            </el-form-item>
          </div> 
        </div>
      </el-form>
      <div style="margin-left: 30px; margin-top:40px" v-if="!isDisabled">
        <el-button class="el_btn" @click="goSave" type="primary" >提交审核</el-button>
      </div>
    </div>
  </div>

</template>

<script>
   
 
import { queryAfterSalesSettings, updateAfterSalesSettings , deptAfterSaleExamine} from "@/api/shop";
import { getProvince, getCity, getArea } from "@/api/shopmange/index";
import _ from "lodash";
import router from "@/router";

export default {
  components: {   },
  inject: ['reloadShopAll'],

  data() {
    return {  
      formIn: {
        consignee:'',
        consigneePhone:'',
        consigneeAddress:'',
        changingRefundingInstructions:'',
        provinceName: "",
        province: "",
        cityName: "",
        city: "",
        areaName: "",
        area: "",
      },
      rules: { 
        provinceName: [{ required: true,  trigger: "change" ,validator: (rule, value, callback) => {
          if (this.areaData.length > 0 && !this.formIn.areaName) {
              callback(new Error("请选择省市区"));
            } else if (this.cityData.length > 0 && !this.formIn.cityName) {
              callback(new Error("请选择省市区"));
            } else if (this.provinceData.length > 0 && !this.formIn.provinceName) {
              callback(new Error("请选择省市区"));
            } else {
              callback();
            } 
        }}], 
        consignee: [{ required: true, message: "请输入收货人", trigger: "blur" }], 
        consigneePhone: [{ required: true, message: "请输入电话", trigger: "blur" }], 
        consigneeAddress: [{ required: true, message: "请输入收货地址", trigger: "blur" }], 
        changingRefundingInstructions: [{ required: true, message: "请输入退换货流程", trigger: "blur" }], 
      }, 
      cityData:[],
      areaData:[],
      provinceData:[],
      isDisabled:false, //是否禁用
    };
    
  },
  created() { 
    this.doLoadInfo();
	   this.getProvinceData(); //获取省地区域树 
  },
  computed: {
    
  },
  methods: {

	  //省份的选择按钮
	   selectedHandle(val) {
	    // console.log(val, "val");
	    this.formIn.provinceName = val.rname;
	    this.formIn.province = val.id;
	    this.cityData =[];
	    this.formIn.cityName = "";
	    this.formIn.city = "";
	    this.areaData =[];
	    this.formIn.areaName = "";
	    this.formIn.area = "";
	    this.getCityBtn(val.id);
	  },
	  
	  //城市的选择按钮
	  selectedHandleCity(val) {
	    // console.log(val, "val");
	    this.formIn.cityName = val.rname;
	    this.formIn.city = val.id;
	    this.areaData =[];
	    this.formIn.areaName = "";
	    this.formIn.area = "";
	    this.getAreaBtn(val.id);
	  },
	  
	  //区域的选择按钮
	  selectedHandleArea(val) { 
	    this.formIn.areaName = val.rname;
	    this.formIn.area = val.id; 
	  },
	  getProvinceData() {
	    getProvince().then((res) => {
	      if (res.code == 0) {
	        this.provinceData = res.data;
	      } else {
	        this.$message({ type: "warning", message: res.msg });
	      }
	    });
	  },
	  getCityBtn(val) {
	    getCity({ id: val }).then((res) => {
	      if (res.code == 0) {
	        this.cityData = res.data;
	      } else {
	        this.$message({ type: "warning", message: res.msg });
	      }
	    });
	  },
	  
	  getAreaBtn(val) {
	    getArea({ id: val }).then((res) => {
	      if (res.code == 0) {
	        this.areaData = res.data;
	      } else {
	        this.$message({ type: "warning", message: res.msg });
	      }
	    });
	  },
    goNext() {
      this.$refs.shopForm.validate((valid) => {
        if (valid) {
         this.doUpdateInfo("NEXT")
        } else {
          return false;
        }
      });
    },
    goSave() {
      this.$refs.shopForm.validate((valid) => {
        if (valid) {
         this.doUpdateInfo()
        } else {
          return false;
        }
      });
    },
    doLoadInfo() {
      queryAfterSalesSettings().then((data) => {
        if (data) {
          for (let key in data) {
            if (this.formIn.hasOwnProperty(key) && data[key]) {
              this.formIn[key] = data[key];
            }
          }
          if(!data.modifiableFlag) {
            this.isDisabled = true;
          }else{
            this.isDisabled = false;
          }
          if(data.province){
            this.getCityBtn(data.province);
          }
          if(data.city){
            this.getAreaBtn(data.city);
          }
        }
      });
    },
    doUpdateInfo(status) { 
      let data = {};
      _.merge(data, this.formIn); 
      deptAfterSaleExamine(data).then((res) => {
        if (res) {
           this.$message.success("提交成功");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style/index.scss";
</style>
