<template>
  <div class="shop-info-root tab-content new-cls">
    <div class="form">
      <div class="row">
        <div class="tip"><span class="weight">*</span>开户方式</div>
        <div class="value checkbox big-w">
          <el-select
            v-model="formIn.accountOpeningMethodId"
            placeholder="请选择"
            size="small"
            @change="handleChange"
          >
            <el-option
              v-for="item in indicatorList ? indicatorList : []"
              :key="item.id"
              :label="item.instructions"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row contine" v-if="formIn.medalPicture">
        <div class="tip"></div>
        <div class="value fill">
          <div class="small-tip">
            <span class="small-icon">
              <el-image :src="formIn.medalPicture" class="icon" />
            </span>
            <span>{{ formIn.medalName }}</span>
          </div>
        </div>
      </div>
      <div class="row multi">
        <div class="tip">附加说明</div>
        <div class="value input big-w">
          <el-input
            type="textarea"
            maxlength="100"
            show-word-limit
            resize="none"
            :rows="6"
            placeholder="请输入"
            v-model="formIn.accountOpeningInstructions"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="bottom-btn">
      <el-button type="primary" class="fix-four" @click="doUpdateInfo"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import {
  queryShopOpenAccountSettings,
  updateShopSettingsExpansionInformation,
  getIndicatorInfo,
} from "@/api/shop";
import _ from "lodash";

export default {
  components: {},
  data() {
    return {
      indicatorList: [],

      formIn: {
        accountOpeningMethodId: "", //开户方式id（关联平台拓展信息表中的信息）
        accountOpeningInstructions: "", //开户方式说明
        medalPicture: "", //勋章图片
        medalName: "", //勋章名称
        shopId: "",
        weight:0
      },
    };
  },
  created() {
    this.getIndicatorInfo();
    this.doLoadInfo();
  },
  methods: {
    getIndicatorInfo() {
      let type = "OPEN_ACCOUNT";
      getIndicatorInfo(type).then((data) => {
        if (data instanceof Array) {
          this.indicatorList = data;
        }
      });
    },
    handleChange(val) {
      if (val) {
        const findItem = this.indicatorList.find((item) => item.id === val);
        this.formIn.medalPicture =
          findItem && findItem.medalPicture ? findItem.medalPicture : "";
        this.formIn.medalName =
          findItem && findItem.medalName ? findItem.medalName : "";
        this.formIn.weight = 
          findItem && findItem.competitivePower ? findItem.competitivePower : 0;
      }
    },
    doLoadInfo() {
      queryShopOpenAccountSettings().then((data) => {
        if (data) {
          for (let key in data) {
            if (this.formIn.hasOwnProperty(key) && data[key]) {
              this.formIn[key] = data[key];
            }
          }
          
        }
      });
    },
    doUpdateInfo() {
      if (!this.formIn.accountOpeningMethodId) {
        this.$message.warning("请选择开户方式");
        return;
      }
      let data = {};
      _.merge(data, this.formIn);
      updateShopSettingsExpansionInformation(data).then((res) => {
        if (res) {
          if (!this.formIn.shopId) {
            this.doLoadInfo();
          }
          this.$message.success("保存成功");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
