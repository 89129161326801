var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contentForm" },
    [
      _c(
        "el-form",
        {
          attrs: {
            size: "mini",
            "label-width": "80px",
            model: _vm.formLabelAlign,
          },
        },
        [
          0
            ? [
                _c("el-form-item", { attrs: { label: "快递类型" } }, [
                  _c(
                    "div",
                    { staticClass: "line" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "344px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formLabelAlign.expressesName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formLabelAlign, "expressesName", $$v)
                          },
                          expression: "formLabelAlign.expressesName",
                        },
                      }),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            width: "623",
                            trigger: "click",
                          },
                          model: {
                            value: _vm.visibleCan,
                            callback: function ($$v) {
                              _vm.visibleCan = $$v
                            },
                            expression: "visibleCan",
                          },
                        },
                        [
                          _c("div", [
                            _c("div", { staticClass: "titleClass" }, [
                              _c("p", { staticStyle: { margin: "0px" } }, [
                                _vm._v("快递公司"),
                              ]),
                              _c("i", {
                                staticClass: "el-icon-close",
                                staticStyle: { "font-size": "16px" },
                                on: {
                                  click: function ($event) {
                                    _vm.visibleCan = false
                                  },
                                },
                              }),
                            ]),
                            _c(
                              "div",
                              { staticClass: "kuaidiClass" },
                              _vm._l(
                                _vm.dictExpressesData,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      class: { active: _vm.isSelected(item) },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectHandle(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "name" }, [
                                        _vm._v(_vm._s(item.name)),
                                      ]),
                                      _c("p", { staticClass: "code" }, [
                                        _vm._v(_vm._s(item.code)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "font-size": "16px",
                                  color: "#262626",
                                  "line-height": "24px",
                                  "font-weight": "600",
                                  margin: "0px",
                                },
                              },
                              [_vm._v(" 快运公司 ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "kuaidiClass" },
                              _vm._l(_vm.ExpressesData, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    class: { active: _vm.isSelected(item) },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectHandle(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                    _c("p", { staticClass: "code" }, [
                                      _vm._v(_vm._s(item.code)),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                type: "primary",
                                size: "small",
                                disabled: _vm.isdisabled,
                              },
                              slot: "reference",
                            },
                            [_vm._v("选择快递")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: "发货省份" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "168px", "margin-right": "8px" },
                        attrs: {
                          "value-key": "id",
                          placeholder: "请选择省份",
                          disabled: _vm.isdisabled,
                        },
                        on: { change: _vm.selectedHandle },
                        model: {
                          value: _vm.formLabelAlign.province,
                          callback: function ($$v) {
                            _vm.$set(_vm.formLabelAlign, "province", $$v)
                          },
                          expression: "formLabelAlign.province",
                        },
                      },
                      _vm._l(_vm.provinceData, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.rname, value: item },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "168px" },
                        attrs: {
                          placeholder: "请选择城市",
                          "value-key": "id",
                          disabled: _vm.isdisabled,
                        },
                        on: { change: _vm.selectedHandleCity },
                        model: {
                          value: _vm.formLabelAlign.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.formLabelAlign, "city", $$v)
                          },
                          expression: "formLabelAlign.city",
                        },
                      },
                      _vm._l(_vm.cityData, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.rname, value: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "615px" },
              attrs: { label: "销售政策与条款", "label-width": "120px" },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "50",
                  "show-word-limit": "",
                  resize: "none",
                  rows: 4,
                  disabled: _vm.isdisabled,
                  placeholder:
                    "例如：工作日早八点半，晚5点半，非工作日不处理。",
                },
                model: {
                  value: _vm.formLabelAlign.orderDealTimeRule,
                  callback: function ($$v) {
                    _vm.$set(_vm.formLabelAlign, "orderDealTimeRule", $$v)
                  },
                  expression: "formLabelAlign.orderDealTimeRule",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "615px" },
              attrs: { label: "配送政策", "label-width": "120px" },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "50",
                  "show-word-limit": "",
                  resize: "none",
                  rows: 4,
                  disabled: _vm.isdisabled,
                  placeholder:
                    "例如：每天15点前的订单当天发，15点后的订单第二天发，周六日节假日正常发货",
                },
                model: {
                  value: _vm.formLabelAlign.sendRule,
                  callback: function ($$v) {
                    _vm.$set(_vm.formLabelAlign, "sendRule", $$v)
                  },
                  expression: "formLabelAlign.sendRule",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "615px" },
              attrs: { label: "企业介绍", "label-width": "120px" },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "200",
                  "show-word-limit": "",
                  resize: "none",
                  rows: 6,
                  disabled: _vm.isdisabled,
                  placeholder:
                    "例如：电子版开户，无需提供纸质资质，发票随货同行。",
                },
                model: {
                  value: _vm.formLabelAlign.shopDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.formLabelAlign, "shopDesc", $$v)
                  },
                  expression: "formLabelAlign.shopDesc",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "615px" },
              attrs: { label: "店铺公告", "label-width": "120px" },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "200",
                  "show-word-limit": "",
                  resize: "none",
                  rows: 6,
                  disabled: _vm.isdisabled,
                  placeholder:
                    "例如：店铺公告:本公司于9月2日至9月24日升级系统，开级期国暂信发货。",
                },
                model: {
                  value: _vm.formLabelAlign.shopAnnouncement,
                  callback: function ($$v) {
                    _vm.$set(_vm.formLabelAlign, "shopAnnouncement", $$v)
                  },
                  expression: "formLabelAlign.shopAnnouncement",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { staticStyle: { "margin-top": "40px" } }, [
            _c(
              "div",
              [
                !_vm.isdisabled || !_vm.formLabelAlign.id
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { width: "160px" },
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.handleSave },
                      },
                      [_vm._v("保存")]
                    )
                  : _vm._e(),
                _vm.formLabelAlign.id && _vm.isdisabled
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { width: "160px" },
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.editorHandle },
                      },
                      [_vm._v("编辑")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }