var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("span", { staticClass: "font" }, [_vm._v("免审商品")]),
        _c(
          "el-form",
          {
            ref: "formTool",
            attrs: {
              model: _vm.formTool,
              inline: true,
              "label-width": "120px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "goodsOneClassId" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { "max-width": "130px" },
                    attrs: {
                      size: "small",
                      placeholder: "一级分类",
                      clearable: "",
                    },
                    on: { change: _vm.changeOneClassId },
                    model: {
                      value: _vm.formTool.goodsOneClassId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "goodsOneClassId", $$v)
                      },
                      expression: "formTool.goodsOneClassId",
                    },
                  },
                  _vm._l(_vm.goodsOneClassIdOptions, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "goodsTwoClassId" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { "max-width": "130px" },
                    attrs: {
                      size: "small",
                      placeholder: "二级分类",
                      clearable: "",
                    },
                    on: { change: _vm.changeTwoClassId },
                    model: {
                      value: _vm.formTool.goodsTwoClassId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "goodsTwoClassId", $$v)
                      },
                      expression: "formTool.goodsTwoClassId",
                    },
                  },
                  _vm._l(_vm.goodsTwoClassIdOptions, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "goodsThreeClassId" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { "max-width": "130px" },
                    attrs: {
                      size: "small",
                      placeholder: "三级分类",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formTool.goodsThreeClassId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "goodsThreeClassId", $$v)
                      },
                      expression: "formTool.goodsThreeClassId",
                    },
                  },
                  _vm._l(_vm.goodsThreeClassIdOptions, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "name" } },
              [
                _c("el-input", {
                  staticStyle: { width: "280px" },
                  attrs: {
                    placeholder: "请输入商品名称/批准文号/生产厂家",
                    size: "small",
                    clearable: "",
                  },
                  on: {
                    change: _vm.queryFactoryFn,
                    blur: _vm.queryFactoryFn,
                    clear: _vm.clearGoodsFactroy,
                  },
                  model: {
                    value: _vm.formTool.keyword,
                    callback: function ($$v) {
                      _vm.$set(_vm.formTool, "keyword", $$v)
                    },
                    expression: "formTool.keyword",
                  },
                }),
              ],
              1
            ),
            _vm.factoryList.length > 0
              ? _c(
                  "el-form-item",
                  { attrs: { prop: "factory" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { "max-width": "130px" },
                        attrs: {
                          size: "small",
                          placeholder: "请筛选生产厂家",
                          clearable: "",
                        },
                        model: {
                          value: _vm.formTool.factory,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "factory", $$v)
                          },
                          expression: "formTool.factory",
                        },
                      },
                      _vm._l(_vm.factoryList, function (item, key) {
                        return _c("el-option", {
                          key: key,
                          attrs: { label: item, value: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              [
                _c("MyButton", {
                  attrs: { type: "primary", size: "small", text: "查询" },
                  on: {
                    click: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                }),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.reset()
                      },
                    },
                  },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "formDiv" },
      [
        _c("div", [_vm._v("无法满足上新需求？")]),
        _c(
          "el-button",
          {
            staticClass: "bigButton",
            attrs: { type: "primary", icon: "el-icon-plus" },
            on: {
              click: function ($event) {
                return _vm.addProduct()
              },
            },
          },
          [_vm._v("新增药品 ")]
        ),
      ],
      1
    ),
    _c("div", { ref: "tableCont", staticClass: "contentCont" }, [
      _c(
        "div",
        { staticClass: "contentContinner" },
        _vm._l(_vm.tableData, function (item, idnex) {
          return _c("tableItem", { key: idnex, attrs: { item: item } })
        }),
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "el-page" },
      [
        _c("Pagination", {
          attrs: {
            total: _vm.page.total,
            page: _vm.page.current,
            limit: _vm.page.size,
            "page-sizes": _vm.pageSizes,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.page, "current", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.page, "size", $event)
            },
            pagination: _vm.pageChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }