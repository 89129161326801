var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qualification-page-root comContent" },
    [
      _vm.state === 1
        ? [
            _c("QualificationStep", {
              attrs: { "current-index": _vm.currentIndex },
            }),
          ]
        : _vm.state === 2
        ? [
            _c("QualificationResult", {
              on: { "change-info": _vm.handleChangeInfo },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }