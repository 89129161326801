<template>
  <div class="comContent">
    <groupList></groupList>
    <searchArea @search="search" />
    <Tabs @tab-click="handleClick"
          style="padding:0 0px"
          :tabsList="tabsList"
          :activeName.sync="activeName" />
    <MyTable :table-data="tableData"
             :index="index"
             :selection="selection"
             :page="page"
             :operation="operation"
             :table-option.sync="tableOption"
             @page-change="getList">
      <template slot="status"
                slot-scope="scope">
        <el-row>
          {{ getstatusVal(scope.row.state) }}
        </el-row>
      </template>
      <template slot="info"
                slot-scope="scope">
        <el-row>
          <el-col :span="24">
            <div class="goodsInfo">
              <div class="img">
                <img :src="
                    scope.row.goodsImg
                      ? scope.row.goodsImg
                      : avater
                  "
                     alt="" />
              </div>
              <div class="content">
                <div class="name">{{ scope.row.goodsName }}</div>
                <div class="company">{{ scope.row.goodsFactory }}</div>
                <div class="spec">{{ scope.row.goodsSpecification }}</div>
                <div class="no">{{ scope.row.goodsApprovalNumber }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </template>
      <template slot="price"
                slot-scope="scope">
        <el-row> 拼团价: ￥{{ Number(scope.row.groupBuyPrice).toFixed(2) }}</el-row>
        <el-row> 起拼数量: {{ scope.row.minSingleBuyCount }} </el-row>
      </template>
      <template slot="inventory"
                slot-scope="scope">
        <el-row> 活动总限购数量: {{ scope.row.maxGroupTotalCount }} </el-row>
        <el-row> 单客户限购数量: {{ scope.row.userMaxTotalBuyCount }} </el-row>
        <!-- <el-row> 剩余活动库存: {{ scope.row.userMaxTotalBuyCount }} </el-row> -->
      </template>
      <template slot="time"
                slot-scope="scope">
        <el-row> {{ scope.row.startDate + "~" + scope.row.endDate}}</el-row>
      </template>
      <template slot="menu"
                slot-scope="scope">
        <div class="btn-warp">
          <!-- <el-link
            :underline="false"
            style="margin-left: 10px"
            type="primary"
            v-if="scope.row.state === 2"
            @click="release(scope.row)"
            >上线</el-link
          > -->
          <!-- <el-link
            :underline="false"
            style="margin-left: 10px"
            type="primary"
            v-if="scope.row.state === 2"
            @click="offShelf(scope.row)"
            >下线</el-link
          > -->
          <el-link
            :underline="false"
            style="margin-left: 10px"
            type="primary"
            v-if="scope.row.state === 1"
            @click="edit(scope.row)"
            >编辑</el-link
          >
          <el-link
            :underline="false"
            style="margin-left: 10px"
            type="primary"
            v-if="scope.row.state === 1"
            @click="del(scope.row)"
            >删除</el-link
          >
          <el-link
            :underline="false"
            style="margin-left: 10px"
            type="primary"
            v-if="scope.row.state !== 1"
            @click="detail(scope.row)"
            >详情</el-link
          >
        </div>
      </template>
    </MyTable>
  </div>
</template>

<script>
import groupList from "./components/groupList";
import searchArea from "./components/searchArea";
import Tabs from "@/components/Tabs";
import MyTable from "@/components/myTable";
import avater from "/public/img/default.png";
import {
  delGroupBuyGoods,
  groupBuyGoodsList,
} from "@/api/spellGroup/spellGroupGoods";
export default {
  components: { groupList, searchArea, Tabs, MyTable },
  data () {
    return {
      avater: avater,
      tabsList: [
        {
          name: "first",
          label: "全部",
        },
        {
          name: "1",
          label: "待开始",
        },
        {
          name: "2",
          label: "进行中",
        },
        {
          name: "3",
          label: "拼团成功",
        },
        {
          name: "4",
          label: "拼团失败",
        },
      ],
      activeName: "first", // tabs 传的值
      operation: true,
      selection: false,
      index: false,
      tableData: [],
      searchForm: {},
      tableOption: [
        { label: "活动名称", prop: "name",  align: "left"   },
        { label: "活动状态", prop: "status", slot: true, align: "left" },
        {
          label: "商品信息",
          prop: "info",
          slot: true,
          width: "200",
          align: "left",
        },
        {
          label: "拼团价格",
          prop: "price",
          slot: true,
          width: "160",
          align: "left",
        },
        {
          label: "活动库存",
          prop: "inventory",
          slot: true,
          width: "160",
          align: "left",
        },
        { label: "销售数据", prop: "salesCount", align: "left" },
        {
          label: "活动时间",
          prop: "time",
          slot: true,
          width: "190",
          align: "left",
        },
        // { label: "创建时间", prop: "menu" },
      ],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
    };
  },
  created () {
    this.getList();
  },
  methods: {
    // 获取商品列表
    search (current, form) {
      if (current) {
        this.page.current = current;
        this.searchForm = form;
      }
      this.getList();
    },
    getstatusVal (status) {
      return this.tabsList[status]?.label;
    },
    getList () {
      const params = {
        ...this.filtterForm(),
        current: this.page.current,
        size: this.page.size,
        state: this.activeName == "first" ? "" : this.activeName,
      };
      groupBuyGoodsList(params).then((res) => {
        this.tableData = res.records;
        this.page.total = res.total;
      });
    },
    filtterForm () {
      let data = {};
      for (const key in this.searchForm) {
        if (key != "date") {
          data[key] = this.searchForm[key];
        }
      }
      if (this.searchForm.date && this.searchForm.date.length > 0) {
        data["startDate"] = this.searchForm.date[0];
        data["endDate"] = this.searchForm.date[1];
      }
      return data;
    },
    handleClick (val) {
      this.activeName = val;
      this.getList();
    },
    // 删除
    del(row) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delGroupBuyGoods({ id: row.id }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getList();
          });
        })
        .catch(() => { });
    },
    // 编辑
    edit (row) {
      this.$router.push({
        path: "/spellGroup/spellGroup-goodsList-detail",
        query: {
          groupBuyId: row.groupBuyId,
          id: row.id,
          type: 1,
        },
      });
    },
    // 详情
    detail (row) {
      this.$router.push({
        path: "/spellGroup/spellGroup-goodsList-detail",
        query: {
          groupBuyId: row.groupBuyId,
          type: 2,
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsInfo {
  display: flex;
  align-items: center;
  .img {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    flex: 1;
    font-size: 12px;
    color: #333;
    margin-left: 3px;
    .name {
      font-size: 14px;
      font-weight: 600;
    }
    .company {
      color: #999999;
    }
  }
}
</style>
