var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c(
          "el-form",
          {
            ref: "searchTool",
            staticStyle: { "text-align": "left", display: "flex" },
            attrs: { model: _vm.searchTool, inline: true },
          },
          [
            _c(
              "el-form-item",
              {
                staticStyle: { margin: "0px !important" },
                attrs: { prop: "name" },
              },
              [
                _c("el-input", {
                  attrs: {
                    placeholder: "搜索活动名称/商品名称",
                    size: "small",
                  },
                  model: {
                    value: _vm.searchTool.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchTool, "name", $$v)
                    },
                    expression: "searchTool.name",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-left": "16px !important" },
                attrs: { prop: "status" },
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: "请选择",
                    },
                    model: {
                      value: _vm.searchTool.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchTool, "status", $$v)
                      },
                      expression: "searchTool.status",
                    },
                  },
                  _vm._l(_vm.optionsStatus, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c("MyButton", {
                  attrs: { type: "primary", text: "检索" },
                  on: {
                    click: function ($event) {
                      return _vm.getActivityList()
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.reset()
                      },
                    },
                  },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c("MyButton", {
                  attrs: { type: "primary", text: "新建活动" },
                  on: {
                    click: function ($event) {
                      return _vm.initActivity()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "center" },
      [
        _c(
          "div",
          { staticClass: "left-part" },
          [
            _c("MyTable", {
              attrs: {
                "table-data": _vm.tableData,
                index: _vm.index,
                showpage: false,
                selection: false,
                page: _vm.page,
                operation: false,
                "table-option": _vm.tableOption,
              },
              on: {
                rowClick: _vm.rowClick,
                "update:tableOption": function ($event) {
                  _vm.tableOption = $event
                },
                "update:table-option": function ($event) {
                  _vm.tableOption = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "startDate",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.startDate + "-" + scope.row.endDate
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "status",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.checkStatus(scope.row.status)) + " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "inventoryNumber",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.inventoryNumber +
                                "/" +
                                scope.row.claimsNumber
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "rt-content right-part" },
          [
            _vm.mode === "edit"
              ? _c(
                  "div",
                  { staticClass: "right-header" },
                  [
                    _vm.clickRow.status == "0" || _vm.clickRow == "add"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.save("0")
                              },
                            },
                          },
                          [_vm._v("保存")]
                        )
                      : _vm._e(),
                    _vm.clickRow.status == "0" || _vm.clickRow == "add"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.save("1")
                              },
                            },
                          },
                          [_vm._v("提交")]
                        )
                      : _vm._e(),
                    _vm.clickRow.status == "2"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.cancellation()
                              },
                            },
                          },
                          [_vm._v("作废")]
                        )
                      : _vm._e(),
                    _vm.clickRow.status == "2"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.stop()
                              },
                            },
                          },
                          [_vm._v("停止")]
                        )
                      : _vm._e(),
                    _vm.clickRow.status == "0" ||
                    _vm.clickRow.status == "1" ||
                    (_vm.clickRow.status && _vm.clickRow.claimsNumber == 0)
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteActivity()
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.clickRow
              ? _c(
                  "el-form",
                  {
                    ref: "couponForm",
                    staticClass: "product",
                    attrs: {
                      disabled: _vm.formTool.status != "0",
                      "label-width": "100px",
                      model: _vm.formTool,
                      rules: _vm.rules,
                      size: "small",
                    },
                  },
                  [
                    _c("div", { staticClass: "base-info" }, [
                      _c("div", { staticClass: "left_topic mb20" }, [
                        _vm._v("基本信息"),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            display: "flex",
                            "justify-content": "space-between",
                          },
                        },
                        [
                          _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 10 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "活动名称",
                                            prop: "name",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: _vm.mode === "view",
                                              placeholder: "请输入活动名称",
                                              maxlength: "10",
                                            },
                                            model: {
                                              value: _vm.formTool.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formTool,
                                                  "name",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "formTool.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 10 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "活动时间",
                                            prop: "date",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              disabled: _vm.mode === "view",
                                              "value-format": "yyyy-MM-dd",
                                              type: "daterange",
                                              size: "small",
                                              "range-separator": "-",
                                              "start-placeholder": "开始日期",
                                              "end-placeholder": "结束日期",
                                            },
                                            model: {
                                              value: _vm.formTool.date,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formTool,
                                                  "date",
                                                  $$v
                                                )
                                              },
                                              expression: "formTool.date",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 10 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "活动类型",
                                            prop: "type",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                size: "small",
                                                disabled: _vm.mode === "view",
                                                clearable: "",
                                                placeholder: "活动类型",
                                              },
                                              model: {
                                                value: _vm.formTool.type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formTool,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression: "formTool.type",
                                              },
                                            },
                                            _vm._l(
                                              _vm.options,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.id,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 10 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "活动预算",
                                            prop: "limitFlag",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: { input: _vm.changeHuodong },
                                              model: {
                                                value: _vm.formTool.limitFlag,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formTool,
                                                    "limitFlag",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formTool.limitFlag",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 0 } },
                                                [_vm._v("不限")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("限制")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.formTool.limitFlag == 1
                                            ? _c("el-input", {
                                                staticStyle: {
                                                  width: "140px",
                                                  "margin-left": "16px",
                                                },
                                                attrs: {
                                                  min: "0",
                                                  type: "number",
                                                  size: "small",
                                                  placeholder: "请输入上限",
                                                },
                                                on: {
                                                  input: (e) =>
                                                    _vm.limitMoneyReg(e),
                                                },
                                                model: {
                                                  value:
                                                    _vm.formTool.limitMoney,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formTool,
                                                      "limitMoney",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formTool.limitMoney",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.clickRow.inventoryNumber
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "49%",
                                    display: "flex",
                                    "justify-content": "space-around",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        color: "black",
                                        "font-size": "26px",
                                        "font-weight": "600",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v(" 总量 ")]),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formTool.inventoryNumber
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        color: "black",
                                        "font-size": "26px",
                                        "font-weight": "600",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v(" 已领 ")]),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.formTool.claimsNumber) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        color: "black",
                                        "font-size": "26px",
                                        "font-weight": "600",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v(" 已用 ")]),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.formTool.useNumber) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _c("el-divider"),
                    _c(
                      "div",
                      { staticClass: "base-info" },
                      [
                        _vm.clickRow.status == "0" || _vm.clickRow == "add"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "anniu",
                                staticStyle: { "z-index": "1" },
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addCouponList()
                                  },
                                },
                              },
                              [_vm._v("新建")]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "left_topic mb20" }, [
                          _vm._v("优惠券设置 "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "custom-table" },
                          _vm._l(
                            _vm.formTool.couponList,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: {
                                    width: "520px",
                                    padding: "10px",
                                    "background-color": "#eee",
                                    margin: "0 10px 10px 0",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "优惠券名称",
                                        prop: "couponList[" + index + "].name",
                                        rules: [
                                          {
                                            required: true,
                                            message: "优惠券名称不能为空",
                                            trigger: "blur",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "space-between",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "160px" },
                                            attrs: {
                                              size: "small",
                                              placeholder: "优惠券名称",
                                              maxlength: "10",
                                            },
                                            model: {
                                              value: item.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "name",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "item.name",
                                            },
                                          }),
                                          _vm.formTool.couponList.length > 1
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticClass: "anniu",
                                                  attrs: {
                                                    size: "mini",
                                                    type: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteCouponList(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "有效时间",
                                        prop: "couponList[" + index + "].date",
                                        rules: [
                                          {
                                            required: true,
                                            message: "有效时间不能为空",
                                            trigger: "change",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          size: "small",
                                          type: "daterange",
                                          "value-format": "yyyy-MM-dd",
                                          "range-separator": "-",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                        },
                                        model: {
                                          value: item.date,
                                          callback: function ($$v) {
                                            _vm.$set(item, "date", $$v)
                                          },
                                          expression: "item.date",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "券类型",
                                        prop:
                                          "couponList[" +
                                          index +
                                          "].couponType",
                                        rules: [
                                          {
                                            required: true,
                                            message: "券类型不能为空",
                                            trigger: "change",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: item.couponType,
                                            callback: function ($$v) {
                                              _vm.$set(item, "couponType", $$v)
                                            },
                                            expression: "item.couponType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 0 } },
                                            [_vm._v("满减券")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [_vm._v("满折券")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 2 } },
                                            [_vm._v("满数量减券")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "券总量",
                                        prop:
                                          "couponList[" +
                                          index +
                                          "].inventoryNumber",
                                        rules: [
                                          {
                                            required: true,
                                            message: "券总量不能为空",
                                            trigger: "blur",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "160px" },
                                        attrs: {
                                          min: "0",
                                          type: "number",
                                          size: "small",
                                          placeholder: "券总量",
                                        },
                                        on: {
                                          input: (e) =>
                                            _vm.inventoryNumberReg(e, index),
                                        },
                                        model: {
                                          value: item.inventoryNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "inventoryNumber",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "item.inventoryNumber",
                                        },
                                      }),
                                      _vm.formTool.status != 0
                                        ? _c("span", [
                                            _vm._v(
                                              " 已领" +
                                                _vm._s(item.claimsNumber) +
                                                "/已用" +
                                                _vm._s(item.useNumber) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("div", [_vm._v("适用对象")]),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "客户类型",
                                        prop:
                                          "couponList[" +
                                          index +
                                          "].shopTypeCode",
                                        rules: [
                                          {
                                            required: true,
                                            message: "客户类型不能为空",
                                            trigger: "change",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          model: {
                                            value: item.shopTypeCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "shopTypeCode",
                                                $$v
                                              )
                                            },
                                            expression: "item.shopTypeCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.shopTypeCodeList,
                                          function (item, index) {
                                            return _c(
                                              "el-checkbox",
                                              {
                                                key: index,
                                                attrs: {
                                                  label: item.value,
                                                  name: "type",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.label))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "新老客户",
                                        prop:
                                          "couponList[" +
                                          index +
                                          "].limitUserType",
                                        rules: [
                                          {
                                            required: true,
                                            message: "客户类型不能为空",
                                            trigger: "change",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          model: {
                                            value: item.limitUserType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "limitUserType",
                                                $$v
                                              )
                                            },
                                            expression: "item.limitUserType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                label: "0",
                                                name: "type",
                                              },
                                            },
                                            [_vm._v("新客户")]
                                          ),
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                label: "1",
                                                name: "type",
                                              },
                                            },
                                            [_vm._v("老客户")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "每人限领",
                                        prop:
                                          "couponList[" +
                                          index +
                                          "].limitUserNumberFlag",
                                        rules: _vm.validatorEveryBody(item),
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: item.limitUserNumberFlag,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "limitUserNumberFlag",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.limitUserNumberFlag",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 0 } },
                                            [_vm._v("不限")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [_vm._v("限制")]
                                          ),
                                          item.limitUserNumberFlag == 1
                                            ? _c("el-input", {
                                                staticStyle: { width: "80px" },
                                                attrs: {
                                                  min: "0",
                                                  onkeyup:
                                                    "value=value.replace(/[^\\d]/g,'')",
                                                  type: "number",
                                                  size: "small",
                                                  placeholder: "请输入金额",
                                                },
                                                model: {
                                                  value: item.limitUserNumber,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "limitUserNumber",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "item.limitUserNumber",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "领取方式",
                                        prop:
                                          "couponList[" +
                                          index +
                                          "].claimsType",
                                        rules: _vm.validatorMethods(item),
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: item.claimsType,
                                            callback: function ($$v) {
                                              _vm.$set(item, "claimsType", $$v)
                                            },
                                            expression: "item.claimsType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 0 } },
                                            [_vm._v("消费前发")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [_vm._v("消费后发")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  item.couponType == 0
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "优惠方式",
                                            prop:
                                              "couponList[" +
                                              index +
                                              "].reductionType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value: item.reductionType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "reductionType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.reductionType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 0 } },
                                                [_vm._v("循环满减")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("阶梯满减")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.reductionType == 0 &&
                                  item.couponType == 0
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "",
                                            prop:
                                              "couponList[" +
                                              index +
                                              "].reductionType",
                                            rules: _vm.validatorMethod(item),
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _vm._v(" 每满 "),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  min: "0",
                                                  type: "number",
                                                  size: "small",
                                                  placeholder: "金额",
                                                },
                                                on: {
                                                  input: (e) =>
                                                    _vm.thresholdReg(
                                                      "threshold",
                                                      e,
                                                      index,
                                                      0
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    item.itemList[0].threshold,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item.itemList[0],
                                                      "threshold",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.itemList[0].threshold",
                                                },
                                              }),
                                              _vm._v(" 元减 "),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  min: "0",
                                                  type: "number",
                                                  size: "small",
                                                  placeholder: "金额",
                                                },
                                                on: {
                                                  input: (e) =>
                                                    _vm.thresholdReg(
                                                      "discount",
                                                      e,
                                                      index,
                                                      0
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    item.itemList[0].discount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item.itemList[0],
                                                      "discount",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.itemList[0].discount",
                                                },
                                              }),
                                              _vm._v(" 元 "),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.reductionType == 1 &&
                                  item.couponType == 0
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "",
                                            prop:
                                              "couponList[" +
                                              index +
                                              "].reductionType",
                                            rules: _vm.validatorMethod(item),
                                          },
                                        },
                                        _vm._l(item.itemList, function (el, i) {
                                          return _c(
                                            "div",
                                            { key: i },
                                            [
                                              _vm._v(" 一次买满 "),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  min: "0",
                                                  type: "number",
                                                  size: "small",
                                                  placeholder: "金额",
                                                },
                                                on: {
                                                  input: (e) =>
                                                    _vm.thresholdReg(
                                                      "threshold",
                                                      e,
                                                      index,
                                                      i
                                                    ),
                                                },
                                                model: {
                                                  value: el.threshold,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      el,
                                                      "threshold",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "el.threshold",
                                                },
                                              }),
                                              _vm._v(" 元减 "),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  min: "0",
                                                  type: "number",
                                                  size: "small",
                                                  placeholder: "金额",
                                                },
                                                on: {
                                                  input: (e) =>
                                                    _vm.thresholdReg(
                                                      "discount",
                                                      e,
                                                      index,
                                                      i
                                                    ),
                                                },
                                                model: {
                                                  value: el.discount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      el,
                                                      "discount",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "el.discount",
                                                },
                                              }),
                                              _vm._v(" 元 "),
                                              item.itemList.length > 1
                                                ? _c(
                                                    "el-link",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "12px",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                        disabled:
                                                          _vm.formTool.status !=
                                                          "0",
                                                        underline: false,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteRow(
                                                            index,
                                                            i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 删除")]
                                                  )
                                                : _vm._e(),
                                              i == item.itemList.length - 1
                                                ? _c(
                                                    "el-link",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "12px",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                        disabled:
                                                          _vm.formTool.status !=
                                                          "0",
                                                        underline: false,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addRow(
                                                            index,
                                                            i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 增加下一阶梯")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e(),
                                  item.couponType == 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "优惠方式",
                                            prop:
                                              "couponList[" +
                                              index +
                                              "].reductionType",
                                          },
                                        },
                                        _vm._l(item.itemList, function (el, i) {
                                          return _c(
                                            "div",
                                            { key: i },
                                            [
                                              _vm._v(" 满 "),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  min: "0",
                                                  type: "number",
                                                  size: "small",
                                                  placeholder: "金额",
                                                },
                                                on: {
                                                  input: (e) =>
                                                    _vm.thresholdReg(
                                                      "threshold",
                                                      e,
                                                      index,
                                                      i
                                                    ),
                                                },
                                                model: {
                                                  value: el.threshold,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      el,
                                                      "threshold",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "el.threshold",
                                                },
                                              }),
                                              _vm._v(" 元享 "),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  min: "0",
                                                  type: "number",
                                                  size: "small",
                                                  placeholder: "折",
                                                },
                                                on: {
                                                  input: (e) =>
                                                    _vm.thresholdReg(
                                                      "discount",
                                                      e,
                                                      index,
                                                      i
                                                    ),
                                                },
                                                model: {
                                                  value: el.discount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      el,
                                                      "discount",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "el.discount",
                                                },
                                              }),
                                              _vm._v(" 折 "),
                                              item.itemList.length > 1
                                                ? _c(
                                                    "el-link",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "12px",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                        disabled:
                                                          _vm.formTool.status !=
                                                          "0",
                                                        underline: false,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteRow(
                                                            index,
                                                            i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 删除")]
                                                  )
                                                : _vm._e(),
                                              i == item.itemList.length - 1
                                                ? _c(
                                                    "el-link",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "12px",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                        disabled:
                                                          _vm.formTool.status !=
                                                          "0",
                                                        underline: false,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addRow(
                                                            index,
                                                            i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 增加下一阶梯")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e(),
                                  item.couponType == 2
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "优惠方式",
                                            prop:
                                              "couponList[" +
                                              index +
                                              "].reductionType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value: item.reductionType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "reductionType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.reductionType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 0 } },
                                                [_vm._v("循环满数量减")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("阶梯满数量减")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.reductionType == 0 &&
                                  item.couponType == 2
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "",
                                            prop:
                                              "couponList[" +
                                              index +
                                              "].reductionType",
                                            rules: _vm.validatorMethod(item),
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _vm._v(" 每满 "),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  min: "0",
                                                  type: "number",
                                                  size: "small",
                                                  placeholder: "数量",
                                                },
                                                on: {
                                                  input: (e) =>
                                                    _vm.thresholdReg(
                                                      "threshold",
                                                      e,
                                                      index,
                                                      0
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    item.itemList[0].threshold,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item.itemList[0],
                                                      "threshold",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.itemList[0].threshold",
                                                },
                                              }),
                                              _vm._v(" 销售单位减 "),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  min: "0",
                                                  type: "number",
                                                  size: "small",
                                                  placeholder: "金额",
                                                },
                                                on: {
                                                  input: (e) =>
                                                    _vm.thresholdReg(
                                                      "discount",
                                                      e,
                                                      index,
                                                      0
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    item.itemList[0].discount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item.itemList[0],
                                                      "discount",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.itemList[0].discount",
                                                },
                                              }),
                                              _vm._v(" 元 "),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.reductionType == 1 &&
                                  item.couponType == 2
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "",
                                            prop:
                                              "couponList[" +
                                              index +
                                              "].reductionType",
                                            rules: _vm.validatorMethod(item),
                                          },
                                        },
                                        _vm._l(item.itemList, function (el, i) {
                                          return _c(
                                            "div",
                                            { key: i },
                                            [
                                              _vm._v(" 一次买满 "),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  min: "0",
                                                  type: "number",
                                                  size: "small",
                                                  placeholder: "数量",
                                                },
                                                on: {
                                                  input: (e) =>
                                                    _vm.thresholdReg(
                                                      "threshold",
                                                      e,
                                                      index,
                                                      i
                                                    ),
                                                },
                                                model: {
                                                  value: el.threshold,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      el,
                                                      "threshold",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "el.threshold",
                                                },
                                              }),
                                              _vm._v(" 销售单位减 "),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  min: "0",
                                                  type: "number",
                                                  size: "small",
                                                  placeholder: "金额",
                                                },
                                                on: {
                                                  input: (e) =>
                                                    _vm.thresholdReg(
                                                      "discount",
                                                      e,
                                                      index,
                                                      i
                                                    ),
                                                },
                                                model: {
                                                  value: el.discount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      el,
                                                      "discount",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "el.discount",
                                                },
                                              }),
                                              _vm._v(" 元 "),
                                              item.itemList.length > 1
                                                ? _c(
                                                    "el-link",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "12px",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                        disabled:
                                                          _vm.formTool.status !=
                                                          "0",
                                                        underline: false,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteRow(
                                                            index,
                                                            i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 删除")]
                                                  )
                                                : _vm._e(),
                                              i == item.itemList.length - 1
                                                ? _c(
                                                    "el-link",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "12px",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                        disabled:
                                                          _vm.formTool.status !=
                                                          "0",
                                                        underline: false,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addRow(
                                                            index,
                                                            i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 增加下一阶梯")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "单张最多减",
                                        prop:
                                          "couponList[" +
                                          index +
                                          "].limitReductionMoneyFlag",
                                        rules: _vm.validatorjine(item),
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: item.limitReductionMoneyFlag,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "limitReductionMoneyFlag",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.limitReductionMoneyFlag",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: {
                                                label: 0,
                                                disabled:
                                                  _vm.zuiduojianDisabled,
                                              },
                                            },
                                            [_vm._v("不限")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [_vm._v("限制")]
                                          ),
                                          item.limitReductionMoneyFlag == 1
                                            ? _c("el-input", {
                                                staticStyle: { width: "120px" },
                                                attrs: {
                                                  size: "small",
                                                  type: "number",
                                                  placeholder: "请输入金额",
                                                  maxlength: "5",
                                                },
                                                on: {
                                                  input: (e) =>
                                                    _vm.limitReductionMoneyReg(
                                                      e,
                                                      index
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    item.limitReductionMoney,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "limitReductionMoney",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.limitReductionMoney",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "适用商品",
                                        prop:
                                          "couponList[" +
                                          index +
                                          "].limitGoods",
                                        rules: _vm.validatorShangpin(item),
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: item.limitGoods,
                                            callback: function ($$v) {
                                              _vm.$set(item, "limitGoods", $$v)
                                            },
                                            expression: "item.limitGoods",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 0 } },
                                            [_vm._v("全部商品")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [_vm._v("指定商品")]
                                          ),
                                        ],
                                        1
                                      ),
                                      item.skuList.length > 0
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "12px",
                                              },
                                              attrs: {
                                                type: "primary",
                                                size: "small",
                                                disabled: false,
                                                underline: false,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.chooseGood(item)
                                                },
                                              },
                                            },
                                            [_vm._v(" 已选")]
                                          )
                                        : _vm._e(),
                                      item.limitGoods == 1
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "12px",
                                              },
                                              attrs: {
                                                type: "primary",
                                                size: "small",
                                                disabled: false,
                                                underline: false,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.chooseGood(item)
                                                },
                                              },
                                            },
                                            [_vm._v(" 选择")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.dialogVisible,
              title: "指定商品",
              top: "10vh",
              width: "1000px",
              "before-close": _vm.beforeClose,
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _vm.dialogVisible
              ? _c("TicketAssociativeClassification", {
                  attrs: { status: _vm.formTool.status, rowData: _vm.rowData },
                  on: { choice: _vm.choice },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }