import request from '@/utils/request'


export function getCouponActivitys(data) {
  return request({
    url: '/marketing/coupon/activity/list',
    method: 'get',
    params: data
  })
}

export function addCouponActivity(data) {
  return request({
    url: '/marketing/coupon/couponActivitys',
    method: 'post',
     data
  })
}

export function submitCouponActivity(id) {
  return request({
    url: '/marketing/coupon/couponActivitys/submit/' + id,
    method: 'post',
  })
}

export function getCouponActivityDetail(id) {
  return request({
    url: '/marketing/coupon/couponActivitys/' + id,
    method: 'get',
  })
}

// 活动详情
export function activityInfo(id) {
  return request({
    url: `/marketing/coupon/activity/info/${id}`,
    method: 'get',
  })
}

// 新增活动
export function addActivityInfo(data) {
  return request({
    url: `/marketing/coupon/activity/add`,
    method: 'POST',
    data
  })
}

// 新增活动
export function editActivityInfo(data) {
  return request({
    url: `/marketing/coupon/activity/edit`,
    method: 'put',
    data
  })
}

// 删除活动
export function deleteActivityInfo(id) {
  return request({
    url: `/marketing/coupon/activity/delete/${id}`,
    method: 'DELETE'
  })
}

// 停止活动
export function stopActivityInfo(id) {
  return request({
    url: `/marketing/coupon/activity/stop/${id}`,
    method: 'PUT'
  })
}

// 作废活动
export function disableActivityInfo(id) {
  return request({
    url: `/marketing/coupon/activity/disable/${id}`,
    method: 'PUT'
  })
}
