<!-- 佣金审核 -->
<template>
  <div class="comContent">
    <div class="header">
      <span class="font">佣金审核</span>
      <el-form ref="formTool"
               :model="formTool"
               :inline="true"
               style="text-align:left;">
        <el-form-item prop="shopName">
          <el-input v-model="formTool.shopName"
                    placeholder="请输入商品名称"
                    size="small" />
        </el-form-item>
        <el-form-item prop="status">
          <el-form-item prop="auditType">
            <el-select size="small"
                       v-model="formTool.auditType"
                       clearable
                       placeholder="状态">
              <el-option v-for="item in options"
                         :key="item.code"
                         :label="item.value"
                         :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item prop="date">
          <el-date-picker v-model="formTool.date"
                          value-format="yyyy-MM-dd"
                          type="daterange"
                          size="small"
                          range-separator="-"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item>
          <MyButton type="primary"
                    text="查询"
                    @click="getcheckList(1)" />
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     @click="reset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="center">
      <MyTable :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getcheckList">
        <template slot="amount"
                  slot-scope="scope">
          <span>￥{{scope.row.amount}}</span>
        </template>
        <template slot="discountAmount"
                  slot-scope="scope">
          <span>￥{{scope.row.discountAmount}}</span>
        </template>
        <template slot="actualAmount"
                  slot-scope="scope">
          <span>￥{{scope.row.actualAmount}}</span>
        </template>
        <template slot="pingzhneg"
                  slot-scope="scope">
          <el-link type="primary"
                   :underline="false"
                   @click="look(scope.row)"> 查看凭证</el-link>
        </template>
        <template slot="caozuo"
                  slot-scope="scope">
          <el-link type="primary"
                   :disabled="scope.row.status != '待平台审核'"
                   :underline="false"
                   @click="agree(scope.row)">审核通过</el-link>
          <el-link type="primary"
                   :disabled="scope.row.status != '待平台审核'"
                   :underline="false"
                   style="margin-left:10px"
                   @click="reject(scope.row)">驳回</el-link>
          <el-link type="primary"
                   :underline="false"
                   style="margin-left:10px"
                   @click="detail(scope.row)"> 查看明细</el-link>
        </template>
      </MyTable>
    </div>
    <!-- 发起审核 -->
    <el-dialog title="付款备注"
               :visible.sync="payVisible"
               width="30%">
      <el-input type="textarea"
                placeholder="请输入内容"
                :disabled="pingzhengDisabled"
                v-model="textarea"
                maxlength="200"
                show-word-limit>
      </el-input>
      <ImgUpload v-model="imageList"
                 :isEdit="pingzhengDisabled"
                 :limit="3"
                 style="position:relative;top:-8px">
        <!-- <template slot="tip">
          <p class="el-upload__tip"
             style="margin:0px">
            备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过5M;最多上传三张图片
          </p>
        </template> -->
      </ImgUpload>
      <span slot="footer"
            class="dialog-footer"
            v-if="!pingzhengDisabled">
        <!-- <el-button size="small"
                   @click="payVisible = false">取消</el-button>
        <el-button size="small"
                   type="primary"
                   @click="submit">提交</el-button> -->
      </span>
    </el-dialog>
    <!-- 查看明细 -->
    <el-dialog title="明细"
               :visible.sync="detailVisible"
               width="50%">
      <div class="search_income">
        <div>
          收入：<span>{{ totalPayeeAmount }}</span>元
        </div>
        <!-- <div style="margin-left:100px">
          退款：<span>{{ money }}</span>元
        </div> -->
      </div>
      <div class="detailTable">
        <MyTable :table-data="detailTbleData"
                 class="detailTableHeight"
                 :selection="detailSelection"
                 :page="detailPage"
                 :operation="detailOperation"
                 :table-option.sync="detailTableOption"
                 @page-change="detailChange">
        </MyTable>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import ImgUpload from '@/components/imgUpload'
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable'
import { drugenumeration } from '@/utils/enumeration'
import { auditTypeList, managerRecord, payeeRecordPage, commissionAudit, commissionOverrule } from '@/api/financialcenter.js'

export default {
  components: { MyButton, MyTable, ImgUpload },
  data () {
    return {
      shopId: '',
      commissionDate: '',
      totalPayeeAmount: '',
      formTool: {
        shopName: '',
        date: [],
        auditType: '',
      },
      options: [],
      operation: false,
      selection: false,
      index: false,
      tableData: [
      ],
      tableOption: [
        { label: '佣金收取年份', prop: 'commissionDate' },
        { label: '应缴纳佣金金额', prop: 'amount', slot: true }, // 这里表示自定义列, slot: true
        { label: '佣金优惠金额', prop: 'discountAmount', slot: true },
        { label: '实际需缴纳佣金金额', prop: 'actualAmount', slot: true },
        { label: '生成时间', prop: 'initTime' },
        { label: '最后付款时间', prop: 'lastPayTime' },
        { label: '是否逾期', prop: 'dlay' },
        { label: '付款凭证', prop: 'pingzhneg', slot: true },
        // { label: '确认收款时间', prop: 'lastPayTime' },
        { label: '状态', prop: 'status' },
        { label: '备注/原因', prop: 'remark' },
        { label: '操作', prop: 'caozuo', slot: true, width: '200' },
      ],
      page: {
        total: 10,
        current: 1,
        size: 10
      },
      // 发票表格内的内容
      detailVisible: false,
      detailSelection: false,
      detailPage: {
        total: 10,
        current: 1,
        size: 10
      },
      detailTbleData: [
      ],
      detailOperation: false,
      detailTableOption: [
        { label: '交易流水号', prop: 'paySerialNo' },
        { label: '订单号', prop: 'orderCode' },
        { label: '金额', prop: 'payAmount' },
        { label: '记录时间', prop: 'createTime' }
      ],
      // 凭证禁用
      pingzhengDisabled: false,
      // 付款备注
      payVisible: false,
      textarea: '',
      imageList: [],

    };
  },
  computed: {},
  watch: {},
  methods: {
    // 审核状态列表
    auditTypeList () {
      auditTypeList().then(res => {
        if (res.code == 0) {
          this.options = res.data
        }
      })
    },
    getcheckList (current) {
      if (current) {
        this.page.current = current
      }
      this.managerRecord()
    },
    // 查询列表
    managerRecord () {
      let params = {
        current: this.page.current,
        size: this.page.size,
        auditType: this.formTool.auditType,
        shopName: this.formTool.shopName,
        startDate: !this.formTool.date || this.formTool.date.length == 0 ? '' : this.formTool.date[0], // 开始时间
        endDate: !this.formTool.date || this.formTool.date.length == 0 ? '' : this.formTool.date[1], // 结束时间
      }
      managerRecord(params).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.records
        }
      })
    },
    // 详情分页
    detailChange () {
      this.detail()
    },
    // 查看明细
    detail (row) {
      this.detailPage.current = row ? 1 : this.detailPage.current
      this.commissionDate = row ? row.commissionDate : this.commissionDate
      this.shopId = row ? row.shopId : this.shopId
      this.detailVisible = true
      let params = {
        shopId: this.shopId,
        current: this.detailPage.current,
        size: this.detailPage.size,
        orderCode: '',
        status: '',
        startDate: '',
        endDate: '',
      }
      params.startDate = this.commissionDate + '-01'
      let nextMonth = dayjs(params.startDate).get('month') < 11 ? dayjs(params.startDate).get('month') + 2 : 1
      let nextYear = dayjs(params.startDate).get('month') < 11 ? dayjs(params.startDate).get('year') : dayjs(params.startDate).get('year') + 1
      params.endDate = nextYear + '-' + (nextMonth < 10 ? '0' + nextMonth : nextMonth) + '-' + '01'
      console.log(params)
      payeeRecordPage(params).then(res => {
        if (res.code == 0) {
          this.detailTbleData = res.data.pageData.records
          this.detailPage.total = res.data.pageData.total
          this.totalPayeeAmount = res.data.totalPayeeAmount
        }
      })
    },
    // 查看凭证
    look (row) {
      this.textarea = row.remark
      this.imageList = row.payCertificate ? row.payCertificate.split(',') : []
      this.pingzhengDisabled = true
      this.payVisible = true
    },
    // 审核通过
    agree (row) {
      let params = {
        id: row.id
      }
      commissionAudit(params).then(res => {
        if (res.code == 0) {
          this.$message.success('审核通过')
          this.managerRecord()
        }
      })
    },
    // 审核驳回
    reject (row) {
      let params = {
        id: row.id
      }
      commissionOverrule(params).then(res => {
        if (res.code == 0) {
          this.$message.success('已驳回')
          this.managerRecord()
        }
      })
    },
    reset () {
      this.formTool.auditType = ''
      this.formTool.shopName = ''
      this.formTool.date = []
    },
  },
  created () {
    this.auditTypeList()
    this.managerRecord()
  },
  mounted () {

  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
}
</script>
<style lang='scss' scoped>
.comContent {
  padding: 0px;
}
.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px !important;
  border-bottom: 1px solid #f5f5f5;
  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }
  .el-form-item {
    margin: 0px 0px 0px 8px !important;
  }
}
.center {
  margin-top: 16px;
  padding: 0 24px 0 24px;
  height: calc(100% - 131px);
}
.search_income {
  display: flex;
  margin: 15px 0;
  font-size: 14px;
  color: #8c8c8c;
  text-align: left;
  div {
    width: 200px;
  }
  span {
    font-size: 20px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    color: #f6212d;
  }
}
.detailTable {
  height: 500px;
  overflow: auto;
  .detailTableHeight {
    height: calc(100% - 42px);
  }
}
</style>
