var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "上传附件",
        visible: _vm.visible,
        "lock-scroll": true,
        width: "618px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("ossFileUpload", {
        ref: "refPicFileUpload",
        staticStyle: { "margin-bottom": "10px" },
        attrs: {
          limit: _vm.maxFileCount,
          accept: _vm.picFileExt,
          maxFileLength: 10,
          ossUpload: true,
          ossPathDir: "shop/pic/",
          disabled: _vm.disabled,
        },
        model: {
          value: _vm.picFileList,
          callback: function ($$v) {
            _vm.picFileList = $$v
          },
          expression: "picFileList",
        },
      }),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }