<template>
    <div class="loginMain">
        <div class="warView">
            <i class="el-icon-warning" style="color: #f99b0d; margin-right: 10px;margin-top: 3px;" />
            <span>本平台发票管理为全电发票管理，包括增值税专用发票、普通发票，是全面数字化的发票，是与纸质发票具有同等法律效力的全新发票，不以纸质形式存在、不用介质支撑、不需申请领用。电子发票将纸质发票的票面信息全面数字化，通过标签管理将多个票种集成归并为电子发票单一票种，设立税务数字账户，实现全国统一赋码、智能赋予发票开具金额总额度、自动流转交付。</span>
        </div>
        <div class="formView" v-if="taxInfo &&  taxInfoShow ">
            <el-form ref="loginForm" :model="loginForm" label-width="100px" :rules="rules" >
                <!-- <el-form-item >
                    <div style="font-size: 18px;color: #595959;font-weight: 600;">甘肃省局 电子税务局</div>
                </el-form-item> -->
                <el-form-item label="销售方税号" class="formLabel" >
                    <el-input v-model="taxInfo.sellerTaxpayerId" placeholder="请输入" class="formInput" size="small" disabled></el-input>
                </el-form-item>
                <el-form-item label="用户名" class="formLabel" prop="account">
                    <el-input v-model="loginForm.account" placeholder="请输入"  class="formInput" size="small"></el-input>
                </el-form-item>
                <el-form-item label="密码" class="formLabel" prop="password">
                    <el-input v-model="loginForm.password" placeholder="请输入" class="formInput" size="small" show-password></el-input>
                </el-form-item>
               <el-form-item label="责任人类型" class="formLabel" prop="personType">
                <el-select v-model="loginForm.personType" class="formInput" size="small">
                    <el-option  
                        v-for="(item, index) in personTypeList"
                        :key="index"
                        :label="item.codeText"
                        :value="item.codeValue" 
                    />
                </el-select>
               </el-form-item>
            </el-form>
            <el-button type="primary" class="formButton"  style="width: calc(20vw + 100px);margin-top: 30px;" @click="login()" :loading="loginStatus == 'logining'">验证并登录</el-button>
        </div>
        <div class="unAuthView" v-if="!taxInfo && taxInfoShow">
            <img :src="unAuth" alt="" style="width: 35vw;height: 100%;">
            <el-button type="primary" @click="goAuth">跳转至“数电配置”菜单</el-button>
        </div>
        <el-dialog   :visible.async="codeDialogShow" title="短信验证" width="30%" :before-close="codeDialogWillDismiss"  append-to-body>
            <div class="codeDialogContainer">
                <div class="warView">
                    <i class="el-icon-warning" style="color: #f99b0d; margin-right: 10px;margin-top: 3px;" />
                    <span>为了您的帐号安全和信用安全，请进行安全验证。验证码将以短信形式发送至电子税务局绑定的手机号.</span>
                </div>
                <div style="height: 24px;"></div>
                <el-form >
                    <el-form-item label="验证码">
                        <div class="rowInLine">
                            <el-input v-model="code" placeholder="请输入"  size="small" style="wdith:100px"></el-input>
                            <div style="width: 24px;"></div>
                            <el-button type="primary"  size="small" @click="acquire" v-if="sendStatus == 'init'">发送</el-button>
                            <el-statistic 
                                v-else-if="sendStatus == 'sending'"
                                :value="deadline2"
                                @finish="finish"
                                format="ss"
                                time-indices>
                                <template slot="suffix"> 秒后重发 </template>
                            </el-statistic>
                            <el-button type="primary"  size="small" @click="acquire" v-else-if="sendStatus == 'reSend'">重新发送</el-button>
                        </div>
                    </el-form-item>
                </el-form>
                <div style="height: 24px;"></div>
                <div class="btnView">
                    <el-button type="default" plain size="large" @click="codeDialogWillDismiss">取消</el-button>
                    <el-button type="primary"  size="large" @click="varifyCode">确定</el-button>
                </div>
            </div>
        </el-dialog>
        <el-dialog v-if="taxInfo" :visible.async="qrcodeDialogShow" title="身份认证" width="25%" :before-close="qrcodeDialogWillDismiss"  append-to-body >
            <div class="codeDialogContainer">
                <div class="warView">
                    <i class="el-icon-warning" style="color: #f99b0d; margin-right: 10px;margin-top: 3px;" />
                    <span>请使用税务APP扫描下方二维码进行身份认证。</span>
                </div>
                <div style="height:10px;"></div>
                <el-form label-width="100px" label-position="left">
                    <el-form-item label="销方名称">
                        <el-input v-model="taxInfo.sellerName" placeholder="请输入"  size="small" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="电子税局帐号">
                        <el-input v-model="taxInfo.mobile" placeholder="请输入"  size="small" disabled></el-input>
                    </el-form-item>
                </el-form>
                <div id="qrcode" ref="qrcode" class="qrcodeImg" />
                <div class="freshView" @click="qrcodeDialogWillShow">
                    <i class="el-icon-refresh"></i>
                    <span>刷新二维码</span>
                </div>
                <div class="freshView"><span>可将二维码图片发给认证人员进行身份认证，手机端完成认证后自动同步认证结果，开展后续数电业务操作</span></div>
                <div style="height:20px;"></div>
                <div class="btnView">
                    <el-button type="default" plain size="large" @click="qrcodeDialogWillDismiss">取消</el-button>
                    <el-button type="primary"  size="large" @click="varifyScan">我已扫码</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import { invoiceLogin, getPersonInChargeType, getInvoiceQrCode, getTaxInfoByShopId, invoiceQrCodeQuery ,getElephantInvoiceById} from "@/api/invoice";
import { currentDetails } from "@/api/myQualification/index";
    export default{ 
      
        data(){
            var validateAccount = (rule, value, callback) => {
                if (!value) {
                     callback(new Error('请输入账号'));
                }
                callback();
            };
            var validatePassword = (rule, value, callback)=>{
                if (!value) {
                     callback(new Error('请输入密码'));
                }
                callback();
            }
            var validatePersonType = (rule, value, callback)=>{
                if (!value) {
                     callback(new Error('请选择责任人'));
                }
                callback();
            }
            return{
                loginForm:{
                    taxId:'',
                    // account:'13083767928',
                    // password:'love492744760P',
                    account:'',
                    password:'',
                    personType:''
                },
                code:'',
                rules:{
                    account:[
                        { validator: validateAccount, trigger: 'blur' }
                    ],
                    password:[
                        { validator: validatePassword, trigger: 'blur' }
                    ],
                    personType:[
                        { validator: validatePersonType, trigger: 'blur' }
                    ]
                },
                codeDialogShow:false,
                qrcodeDialogShow:false,
                deadline2: Date.now() + 1000 * 60, //倒计时
                sendStatus:'init',
                loginStatus:'init',
                functionCode:'0',//方法编号：0:login(首次登录),1:send_sms(获取验证码),2:select_zrrlx(选择责任人),3:sms_login(短信登录登录验证)
                personTypeList:[],
                qrcode: null,
                qrlink:'',
                sellerShopId:'',
                shopName:'',
                taxInfo:null,
                qrCodeData:{},
                unAuth:'https://eshop-prod.oss-cn-chengdu.aliyuncs.com/shop/f9dfe25bd9944dac89232090bf9801ad.png',
                taxInfoShow:false,
                soucreType:'', //来源类型
                companyItem:{}, //选择的主体参数
            }
        },
        mounted(){
            // window.addEventListener('keydown',this.didClickKey,true)
            this.init();
        },
        beforeDestroy(){
            // window.removeEventListener('keydown',this.didClickKey,true)
        },
        methods:{
            init(id,type,item){
                if(type){
                    this.soucreType = type
                }
                if(item){ 
                    this.companyItem =item;
                }
                this.getPersonInChargeTypeList()
                this.getUserShopInfo(id)
            },
            goAuth(){
                this.$router.push({
                    path:'/invoiceCenter/invoiceCenter-invoiceInfoManege'
                })
            },
            async getUserShopInfo (id){
                const resp = await currentDetails()
                // if (resp && resp.data) {
                //     this.sellerShopId = resp.data.id
                //     this.shopName = resp.data.shopName
                //     this.getDataDetail()
                // } 
                if(id){ 
                    this.sellerShopId = id;
                    this.getDataDetail()
                }else if( this.$route.query.id){
                    this.sellerShopId = this.$route.query.id;
                    this.getDataDetail()
                }else{
                    this.loaded = true;
                    this.sellerShopId = resp.data.id;
                }
            },
            async getDataDetail (){ 
                const resp = await getElephantInvoiceById(this.sellerShopId)
                if (resp) {
                    this.taxInfo = resp.data; 
                }  
                console.log( this.taxInfo ,'--- this.taxInfo ')
                 this.taxInfoShow = true;
            },
            async getPersonInChargeTypeList(){
                const resp = await getPersonInChargeType({})
                if (resp) {
                    let tmp = []
                    for (const key in resp) {
                        if (Object.hasOwnProperty.call(resp, key)) {
                            const element = resp[key];
                            tmp.push({
                                codeValue: key,
                                codeText : element
                            })
                        }
                    }
                    this.personTypeList = tmp
                }
            },
            async submitLogin(functionCode){
                // 如果type 为 sendeCode | functionCode 固定为1
                let _this = this
                 _this.functionCode = functionCode;
                try {
                    await this.$refs['loginForm'].validate()
                   
                    _this.loginStatus = 'logining'
                    let obj = {
                        saleNum:_this.taxInfo.sellerTaxpayerId,
                        userName:_this.loginForm.account,
                        password:_this.loginForm.password,
                        functionCode: functionCode
                    }
                    // console.log(this.code,'---code')
                    if (_this.code && _this.functionCode == '3') { 
                        obj['verifyCode'] = _this.code
                    }
                    if (_this.functionCode == '2') {
                        obj['personInChargeType'] = _this.loginForm.personType
                    }
                    // console.log(obj,'---obj')
                    try {
                        const resp = await invoiceLogin(obj);
                        // _this.functionCode = resp
                        switch (resp) {
                            case 0:
                                // 登录成功
                                _this.qrcodeDialogWillShow()
                                break;
                            case 1:
                                // 1:send_sms(获取验证码)
                                _this.codeDialogShow = true;
                                break;
                            case 2:
                                // 2:select_zrrlx(选择责任人)
                                _this.submitLogin('2')
                                break;
                            case 3:
                                // 3:sms_login(短信登录登录验证)")
                                // this.qrcodeDialogShow = true
                                break;
                            default:
                                break;
                        }
                        _this.loginStatus = 'init'
                        
                    } catch (error) {
                        _this.loginStatus = 'init'
                    }
                    
                } catch (error) {
                
                    return
                }
            },
            //方法编号：0:login(首次登录),1:send_sms(获取验证码),2:select_zrrlx(选择责任人),3:sms_login(短信登录登录验证)
            login(){
                // 登录
                this.submitLogin('0')
                // this.$router.push({
                //         path:'/invoiceApplication'
                //     })
            },
            acquire () {
                // 发送验证码
                this.deadline2 = Date.now() + 1000 * 60
                this.submitLogin('1')
                this.sendStatus = 'sending';

            },
            varifyCode(){
                // 验证验证码
                if (!this.code) {
                    this.$message.warning('请输入验证码')
                    return
                } 
                this.submitLogin('3')
            },
            async varifyScan(){
                let obj = { 
                    saleNum : this.taxInfo.sellerTaxpayerId,
                    userName : this.taxInfo.mobile,
                    checkId: this.qrCodeData.checkId
                }
                const resp = await invoiceQrCodeQuery(obj)
                // 来自自动开票则跳转到自动开票页面
              
                if (resp) {
                    sessionStorage.setItem('sellerTaxpayerId',JSON.stringify(this.taxInfo.sellerTaxpayerId))
                    if (this.soucreType  == 'invoicingStepTwo') {
                        // 存储sessionStorage
                       this.$emit('submit','two') 
                    }else{
                        this.$router.push({
                            path:'/invoiceApplication'
                        })
                    }
                } 
            },
            finish(){
                this.sendStatus = 'reSend'
                this.deadline2 = Date.now() + 1000 * 60
            },
            newqrcode () {

                if(this.$refs.qrcode){
                    this.$refs.qrcode.innerHTML = '';
                    // let link = 'www.baidu.com' + parseInt(Math.random()*255)
                    this.qrcode = new QRCode('qrcode', {
                        width: 200, 
                        height: 200, 
                        text: this.qrlink 
                    })
                } 
            },
            qrcodeDialogWillDismiss(){
                this.qrcodeDialogShow = false
            },
            qrcodeDialogWillShow(){
                let _this = this
                if (!this.taxInfo) {
                    this.$message.warning('获取用户开票信息失败，请重新进入页面')
                    return
                }
                let obj = {
                    saleNum : this.taxInfo.sellerTaxpayerId,
                    userName : this.taxInfo.mobile
                }
                getInvoiceQrCode(obj).then((res)=>{
                    if (res && res.qrCode) {
                        _this.qrCodeData = res
                        if (!this.qrcodeDialogShow) {
                            this.qrcodeDialogShow = true
                        }
                        this.$nextTick(()=>{
                            setTimeout(() => {
                                _this.qrlink  = res.qrCode
                                _this.newqrcode()
                            }, 500);
                        })
                    }
                })
            },
            codeDialogWillDismiss(){
                this.codeDialogShow = false
            }
        }
    }
</script>
<style lang="scss" scoped>
.loginMain{
    padding: 16px;
    background-color: #fff;
    height: calc(100vh - 148px);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .unAuthView{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .warView{
        background: #fff9e6;
        border: 1px solid #ffdb88;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        span{
            font-size: 14px;
            color: #626D77;
            line-height: 20px;
        }
    }
    .formView{
        width: 500px;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .formLabel{
            text-align-last: justify;
        }
        .formInput{
            text-align-last: left;
            width: 20vw;
        }
    }
    .codeDialogContainer{
        .rowInLine{
            display: flex;
            align-items: center;
        }
        .btnView{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .qrcodeImg{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .freshView{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        cursor: pointer;
        span{
            font-size: 14px;
            color: #595959;
        }
    }
}
</style>