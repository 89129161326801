let timeOfmsg;

function receiveMessage(event){
    // 从子页面回传消息 为 1  表示已经收到消息 ，移除定时器
    if (event.data.sign == 1) {  
        if (event.data.msg === 'getMsg' && event.data.sign == 1) {
            //alert("post message complate, close the timer.");
            clearInterval(timeOfmsg);
        }
    }
}
export function doChatServices(data) {
    console.log(process.env.VUE_APP_CHAT_URL);
    const targetUrl = process.env.VUE_APP_CHAT_URL;
    // const targetUrl = 'http://localhost:5173/';
    const childWindow = window.open(targetUrl,'_blank');
    // 向子页面发送消息 时间间隔一秒钟
    timeOfmsg = setInterval(() => { childWindow.postMessage(data,targetUrl) }, 1000);
    // 监听子页面的回调消息
    window.addEventListener("message", receiveMessage, false);
}