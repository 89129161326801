var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification card new" },
    [
      _c("div", { staticClass: "search fl_bet" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "right fl_cen" },
          [
            _c("el-date-picker", {
              staticClass: "picker",
              attrs: {
                size: "small",
                type: "daterange",
                align: "left",
                "unlink-panels": "",
                "value-format": "yyyy-MM-dd",
                "start-placeholder": "开始日期",
                "range-separator": "-",
                "end-placeholder": "结束日期",
                "picker-options": _vm.pickerOptions,
                clearable: "",
              },
              on: {
                change: function ($event) {
                  return _vm.queryTableData(1)
                },
              },
              model: {
                value: _vm.time,
                callback: function ($$v) {
                  _vm.time = $$v
                },
                expression: "time",
              },
            }),
            _c("el-input", {
              staticStyle: { width: "176px", "margin-left": "8px" },
              attrs: { size: "small", placeholder: "订单号", clearable: "" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.queryTableData(1)
                },
              },
              model: {
                value: _vm.query.code,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "code", $$v)
                },
                expression: "query.code",
              },
            }),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.queryTableData(1)
                  },
                },
              },
              [_vm._v("查询")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.reset()
                  },
                },
              },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "tables collapse_list" },
        [
          _vm.tableData.length > 0
            ? _c(
                "el-collapse",
                {
                  attrs: { loading: _vm.isLoading, accordion: "" },
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                _vm._l(_vm.tableData, function (row, index) {
                  return _c(
                    "el-collapse-item",
                    { key: index, attrs: { name: row.id } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("div", { staticClass: "top_list fl_bet" }, [
                          _c("div", { staticClass: "items fl" }, [
                            _c("div", { staticClass: "item big" }, [
                              _vm._v(_vm._s(row.orderTime)),
                            ]),
                            _c("div", { staticClass: "item nums" }, [
                              _c("span", [_vm._v("订单号：")]),
                              _c("span", { staticClass: "msg" }, [
                                _vm._v(_vm._s(row.orderCode)),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "status fl_cen" },
                              [
                                _c("el-image", {
                                  staticClass: "icon_status",
                                  attrs: { src: _vm.getStatus(1, row.status) },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "text",
                                    class: _vm.getStatus(3, row.status),
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getStatus(2, row.status)) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "btns",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.stopClick.apply(null, arguments)
                                },
                              },
                            },
                            [
                              row.clinicName
                                ? _c(
                                    "el-button",
                                    { staticClass: "popover_btn" },
                                    [_vm._v(_vm._s(row.clinicName))]
                                  )
                                : _vm._e(),
                              row.serialStatus == "CHECK"
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "detail_btn",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.watchBtn(
                                            row.id,
                                            row.orderId
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("同意开票 ")]
                                  )
                                : _vm._e(),
                              row.serialStatus == "CHECK"
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "detail_btn",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.uploadFaPiao(
                                            row.id,
                                            row.orderId
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("上传开票 ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "bom_list" },
                        _vm._l(_vm.tableList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "list fl_cen border_no",
                              attrs: { loading: _vm.isLoading },
                            },
                            [
                              _c("div", { staticClass: "left fl_cen" }, [
                                _c(
                                  "div",
                                  { staticClass: "img fl_center" },
                                  [
                                    _c("el-image", {
                                      attrs: { src: item.image },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "names" }, [
                                  _c("div", [_vm._v(_vm._s(item.goodsName))]),
                                  _c("div"),
                                ]),
                              ]),
                              _c("div", { staticClass: "cen" }, [
                                _vm._v(_vm._s(item.specification)),
                              ]),
                              _c("div", { staticClass: "right fl_end" }, [
                                _c("div", [_vm._v("× " + _vm._s(item.qty))]),
                                _c("div", [
                                  _vm._v("￥ " + _vm._s(item.smallPrice)),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    2
                  )
                }),
                1
              )
            : _c("el-empty", {
                staticStyle: { height: "100%", width: "100%" },
                attrs: { description: "暂无数据" },
              }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.query.pages,
              page: _vm.query.current,
              limit: _vm.query.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.query, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.changePage,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传开票",
            visible: _vm.dialogVisible,
            width: "60%",
            "before-close": () => {
              _vm.dialogVisible = false
            },
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("p", { staticStyle: { margin: "10px 0px" } }, [
            _vm._v(
              "上传附件信息(上传实物盖章发票清晰扫描文件，多张发票请扫描成一个PDF，文件命名规格:公司名称 +结算单号"
            ),
          ]),
          _c(
            "div",
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: {
                    action: "#",
                    data: _vm.fileData,
                    "on-preview": _vm.handlePreview,
                    "on-remove": _vm.handleRemove,
                    "before-remove": _vm.beforeRemove,
                    multiple: "",
                    limit: 1,
                    "on-change": _vm.change,
                    "on-exceed": _vm.handleExceed,
                    "file-list": _vm.fileList,
                    "on-success": _vm.success,
                    "auto-upload": false,
                    accept: ".jpg, .png, .pdf",
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("点击上传")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm._v(
                        "附件不能超过10M，最多上传1个，文件格式PDF、JPG、PNG"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "p",
            { staticClass: "tip", staticStyle: { "margin-top": "10px" } },
            [
              _vm._v(
                "说明:提交发票时发票信息列表中的开票金额累加后应大于等于结算单的计算总金额"
              ),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitUpload } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "search_name" }, [_vm._v("待开发票")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }