<template>
  <div class="zt__table">
    <!-- :max-height="maxHeight" -->
    <el-table v-loading="isLoading"
              :max-height="maxHeight"
              highlight-current-row
              :selectableCanBeDisable="selectableCanBeDisable"
              :data="tableData"
              :border="border"
              :span-method="arraySpanMethod"
              :row-key="getRowKeys"
              :expand-row-keys="expands"
              :header-cell-style="headerStyle"
              @sort-change="sortChange"
              @cell-click="cellClick"
              @selection-change="handleSelectionChange"
              @current-change="handleCurrentChange"
              @row-click="rowClick">
      <el-table-column v-if="selection"
                       type="selection"
                       width="55"
                       :selectable="selectable"
                       align="left" />
      <el-table-column v-if="index"
                       type="index"
                       label="序号"
                       align="left"
                       width="50">
        <template slot-scope="scope">
          <span>{{ scope.$index + (page.current - 1) * page.size + 1 }}</span>
        </template>
      </el-table-column>

      <el-table-column type="expand"
                       width="1">
        <template slot-scope="props">
          <slot name="expand"
                :row="props.row"
                :$index="props.$index" />
        </template>
      </el-table-column>

      <template v-for="(item, i) in tableOption">
        <el-table-column :key="i"
                         :min-width="item.minWidth"
                         :max-width="item.maxWidth"
                         :width="item.width"
                         :prop="item.prop"
                         :label="item.label"
                         :align="item.align || 'left'"
                         :sortable="item.sortable || false"
                         :show-overflow-tooltip="item.overHidden || false"
                         :fixed="item.fixed">
          <template slot-scope="scope">
            <slot v-if="item.slot"
                  :name="scope.column.property"
                  :row="scope.row"
                  :$index="scope.$index" />
            <span v-else>{{ scope.row[scope.column.property] }}</span>
          </template>
        </el-table-column>
      </template>

      <el-table-column align="left"
                       label="详情"
                       width="200px">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="expandRow(scope.$index, scope.row)"
                     style="color:#F5222D">订单明细</el-button>
          <el-tooltip placement="top-start"
                      width="200">
            <section slot="content">
              <div>订单编号:{{scope.row.orderCode}}</div>
              <div>康贝流水号:{{scope.row.paymentFlow}}</div>
            </section>
            <el-button type="text"
                       style="margin-left: 10px">单号流水</el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <template slot="empty">
        <el-empty v-if="tableData.length == 0"
                  description="" />
      </template>
    </el-table>
    <!-- <el-empty v-else
              style="height:100%"
              description="暂无数据" /> -->

    <Pagination v-show="page.total > 0 && showpage"
                :total="page.total"
                :page-sizes="page.pageSizes"
                :page.sync="page.current"
                :limit.sync="page.size"
                @pagination="pageChange" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  name: "ZtTable",
  components: {
    Pagination,
  },
  data () {
    return {
      expands: [],
    };
  },
  props: {
    pageType: {
      type: String,
      default () {
        return "";
      },
    },
    selectableCanBeDisable: {
      type: Boolean,
      default () {
        return false;
      },
    },
    maxHeight: {
      type: String,
      default () {
        return "100%";
      },
    },
    isLoading: {
      type: Boolean,
      default () {
        return false;
      },
    },
    selection: {
      type: Boolean,
      default () {
        return false;
      },
    }, // 是否设置勾选，默认不设置
    index: {
      type: Boolean,
      default () {
        return true;
      },
    }, // 是否设置序号，默认设置
    showpage: {
      // 是否显示分页组件
      type: Boolean,
      default () {
        return true;
      },
    },
    expand: {
      // 是否显示expand扩展组件
      type: Boolean,
      default () {
        return false;
      },
    },
    border: {
      type: Boolean,
      default () {
        return false;
      },
    }, // 是否设置边框，默认不要
    operation: {
      type: Boolean,
      default () {
        return false;
      },
    }, // 是否有操作列，默认无
    tableData: {
      type: Array,
      default () {
        return [];
      },
    }, // 列表数据
    tableOption: {
      type: Array,
      default () {
        return [];
      },
    }, // 表头
    page: {
      type: Object,
      default () {
        return {
          total: 0,
          current: 1,
          page: 10,
          pageSizes: [10, 20, 30, 50],
        };
      },
    }, // 分页
  },
  computed: {
    // currentPage: {
    //   get() {
    //     return this.page.current
    //   },
    //   set(val) {
    //     this.$emit('update:page.current', val)
    //   }
    // },
    // limit: {
    //   get() {
    //     return this.page.size
    //   },
    //   set(val) {
    //     this.$emit('update:page.size', val)
    //   }
    // }
  },
  methods: {
      getRowKeys (row,index) {
        // 加随机数 避免 测试环境 脏数据重复的orderCode 渲染不出来
      return row.orderCode + Math.random() * 1000;
    },
    expandRow (index, row, type) {
      if (this.expands.indexOf(row.orderCode) < 0) {
        this.expands = [];
        this.expands.push(row.orderCode);
        // console.log(this.expands);
      } else {
        this.expands = [];
      }
      console.log(row)
      this.$emit("detail-operate", row, 1);
    },
    detailOperate (row, type) {
      console.log(row)
      this.$emit("detail-operate", row, 2);
    },
    headerStyle ({ row, column, rowIndex, columnIndex }) {
      // row[2].colSpan = 2; //第二个表头占两格
      // row[1].colSpan = 0; //第一个表头占零格
      // if (columnIndex === 1) {
      //   //隐藏第一个表头
      //   return "display: none";
      // }
    },
    selectable (row, index) {
      // 可点击确认按钮
      if (this.$props.selectableCanBeDisable) {
        return row.beAllowOperation;
      } else {
        return true;
      }
    },

    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      // 第一列和第二列合并，隐藏展开箭头所占的空位
      // if (columnIndex === 1) {
      //   return [2, 0];
      // }
      // if (columnIndex === 2) {
      //   return [1, 2];
      // }

      console.log(column.label, '--column.label ')

      // 付款方名称后面几列合并
      if (this.pageType === "payment-page") {
        if (column.label === "付款方名称") {
          console.log('columnIndex', columnIndex)
          return [1, 4];
        }
        // 后面的5列需隐藏的设置【0，0】
        if (columnIndex === 14 || columnIndex === 15 || columnIndex === 16 || columnIndex === 13) {
          return [0, 0];
        }
      }
    },
    pageChange () {
      this.$emit("page-change");
    },
    cellClick (row, column, cell, event) {
      this.$emit("cell-click", { row, column, cell, event });
    },
    rowClick (row, column, event) {
      this.$emit("rowClick", { row, column, event });
    },
    handleSelectionChange (val) {
      this.$emit("handleSelectionChange", val);
    },
    handleCurrentChange (val) {
      this.$emit("handleCurrentChange", val);
    },
    sortChange (val) {
      this.$emit("sort-change", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.zt__table {
  height: 100%;
  width: 100%;
  ::v-deep .el-table {
    height: 100%;
    .el-table__body-wrapper {
      height: calc(100% - 48px);
      overflow: auto;
      // overflow-x: hidden;
    }
  }
  ::v-deep .el-pagination {
    padding: 10px 0 0 0;
  }
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
/*此处为新增CSS，在vue中使用要加/deep/*/
::v-deep .el-table__expand-icon {
  visibility: hidden;
}

::v-deep .el-table__expand-icon {
  display: none;
}
</style>
