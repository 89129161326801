<template>
    <div class="classification">
        <el-scrollbar style="height: calc(100vh - 148px);">
            <div class="mainContainer">
                <div class="warView">
                    <span>本信息用于国家电子税务局第三方开票服务报备注册，配置完成之后请点击登录按钮进行登录</span>
                </div>
                <div class="stepView">
                    <el-steps :active="stepInsecond?2:1" finish-status="success" simple style="margin-top: 20px">
                        <el-step title="税局报备"></el-step>
                        <el-step title="业务配置"></el-step>
                        <el-step title="审核并登录"></el-step>
                    </el-steps>
                </div>
                <div style="height: 16px;"></div>
                <div class="secondView" v-if="loaded && stepInsecond">
                    <div class="linkText" @click="openLinkUrl">{{linkUrl}}</div>
                    <div class="copyText" @click="openLinkUrl">复制并打开</div>
                    <div class="infoView">
                        <el-form label-width="120px">
                            <el-form-item label="销方名称" >
                                <div class="infoInLine">
                                    <div class="infoDetail">
                                        {{compForm.shopName}}
                                    </div>
                                    <span class="infoCopy" @click="_copy(compForm.shopName)">复制</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="登录账号" prop="sellerTaxpayerId">
                                <div class="infoInLine">
                                    <div class="infoDetail">{{compForm.mobile}}</div>
                                    <span class="infoCopy" @click="_copy(compForm.mobile)">复制</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="密码" >
                                <div class="infoInLine">
                                    <div class="infoDetail">88888888</div>
                                    <span class="infoCopy" @click="_copy('88888888')">复制</span>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="descView">
                        <div @click="descDialogShow=true">
                            <i class="el-icon-thumb"></i>
                            <span>点击查看配置文件</span>
                        </div>
                        <div style="height: 24px;"></div>
                        <!-- <el-button type="primary" @click="checkLogin">我已完成 去登录</el-button> -->
                    </div>
                </div>
                <div class="lineView" v-else-if="loaded && !stepInsecond">
                    <div class="eachView">
                        <el-form label-width="120px" :rules="rules" required ref="compForm" :model="compForm">
                            <el-form-item label="企业名称" >
                                <el-input placeholder="请输入" style="width: 20vw;" v-model="compForm.shopName"
                                    ></el-input>
                            </el-form-item>
                            <el-form-item label="企业税号" prop="sellerTaxpayerId">
                                <el-input placeholder="请输入" style="width: 20vw;"
                                    v-model="compForm.sellerTaxpayerId"></el-input>
                            </el-form-item>
                            <el-form-item label="税务机关名称"  prop="taxAuthoritiesName">
                                <el-select v-model="compForm.taxAuthoritiesName" style="width: 20vw;"   
                                    @change="taxIdDidChange" filterable  value-key="code">
                                    <el-option v-for="(item, index) in taxOrgList" :key="item.code" :label="item.name"
                                        :value="item"/>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="税务机关代码" required>
                                <el-input placeholder="请输入" style="width: 20vw;" 
                                    v-model="compForm.taxAuthoritiesCode"></el-input>
                            </el-form-item>
                            <el-form-item label="地区编码"  prop="zipCode">
                                <el-autocomplete placeholder="请输入" style="width: 20vw;" v-model="compForm.zipCode"
                                    :fetch-suggestions="querySearchZipCode" @select="handleSelectZipCode"></el-autocomplete>
                            </el-form-item>
                            <el-form-item label="开票人手机号" prop="mobile">
                                <el-input placeholder="请输入" style="width: 20vw;" v-model="compForm.mobile"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div style="width: 16px;"></div>
                    <div class="eachView">
                        <el-form label-width="120px" ref="personForm" :model="personForm" :rules="personRules">
                            <el-form-item label="纳税人类型" prop="taxPayerType">
                                <el-select v-model="personForm.taxPayerType" style="width: 20vw;">
                                    <el-option v-for="(item, index) in taxPayerTypeList" :key="index" :label="item.codeText"
                                        :value="item.codeValue" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="组织类型" prop="orgType">
                                <el-select v-model="personForm.orgType" style="width: 20vw;">
                                    <el-option v-for="(item, index) in orgTypeList" :key="index" :label="item.codeText"
                                        :value="item.codeValue" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="开票业务" >
                                <el-input placeholder="请输入" style="width: 20vw;" v-model="personForm.businessName" ></el-input>
                            </el-form-item>
                            <el-form-item label="税控设备类型" prop="taxDriverType">
                                <el-select v-model="personForm.taxDriverType" style="width: 20vw;">
                                    <el-option v-for="(item, index) in taxDriverTypeList" :key="index" :label="item.codeText"
                                        :value="item.codeValue" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="用户名" prop="username">
                                <el-input placeholder="请输入" style="width: 20vw;" v-model="personForm.username"></el-input>
                            </el-form-item>
                            <el-form-item label="开户行" prop="sellerBank">
                                <el-input placeholder="请输入" style="width: 20vw;" v-model="personForm.sellerBank">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="银行账号" prop="sellerAccount">
                                <el-input placeholder="请输入" style="width: 20vw;" v-model="personForm.sellerAccount">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="注册电话" prop="sellerTel">
                                <el-input placeholder="请输入" style="width: 20vw;" v-model="personForm.sellerTel">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="注册地址" prop="areaName">
                                <el-select v-model="personForm.provinceName" placeholder="请选择省" style="width: 6vw"
                                    @change="selectedHandle">
                                    <el-option v-for="(item, index) in provinceData" :key="index" :label="item.rname"
                                        :value="item.id" />
                                </el-select>
                                <el-select v-model="personForm.cityName" placeholder="请选择市"
                                    style="margin-left: 1vw; width: 6vw" @change="selectedHandleCity">
                                    <el-option v-for="(item, index) in cityData" :key="index" :label="item.rname"
                                        :value="item.id" />
                                </el-select>
                                <el-select v-model="personForm.areaName" placeholder="请选择区"
                                    style="margin-left: 1vw; width: 6vw" @change="selectedHandleArea">
                                    <el-option v-for="(item, index) in areaData" :key="index" :label="item.rname"
                                        :value="item.id" />
                                </el-select>
                            </el-form-item>
                            <el-form-item prop="address">
                                <el-input placeholder="请输入" style="width: 20vw;" v-model="personForm.address">

                                </el-input>
                            </el-form-item>
                        </el-form>
                        <div class="btnView">
                            
                            <el-button type="info" v-if="isSubmit">已提交，请耐心等待</el-button>
                            <el-button type="primary" @click="submitAction" v-else>提交注册申请</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height:24px;"></div>
        </el-scrollbar>
        <el-dialog title="示例" :visible.sync="descDialogShow" width="1000px">
            <div  class="tips-list">
                <div  class="item">
                    <div class="text text-a">
                        <span>第一步</span>  
                    </div>
                    <div class="text">
                         登录销项开票
                    </div>
                </div>
                <div  class="item">
                    <div class="text text-a">
                        <span>第二步</span>    
                    </div>
                    <div class="text">
                        全电设置>开票业务配置>添加配置
                    </div>
                </div>
                <div  class="item">
                    <div class="text text-a">
                        <span>第三步 </span>     
                    </div>
                    <div class="text">
                         输入接入配置
                    </div>
                </div>
            </div>
            <div   class="tips-img" >
                <div class="img">
                    <img :src="desc1" alt="" >
                </div>
                <div class="img">
                    <img :src="desc2" alt="" >
                </div>
                <div class="img">
                    <img :src="desc3" alt="" >
                </div> 
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getCity, getArea } from "@/api/shopmange/index";
import { regionAreaTree } from "@/api/saleRule/saleF";
import { getOrgType, getTaxCode, getZipCode, getTaxPayerType, getTaxDriverType, taxSave, getTaxInfoByShopId,getElephantInvoiceById } from "@/api/invoice";
import { currentDetails } from "@/api/myQualification/index";
export default {

    data() {
        return {
            rules:{
                sellerTaxpayerId:[
                    { required: true, message: '请输入企业税号', trigger: 'blur' },
                    { min: 15, max: 20, message: '长度在 15 到 20 个字符', trigger: 'blur' }
                ],
                taxAuthorities:[
                    { required: true, message: '请选择税务机关', trigger: 'blur' },
                ],
                zipCode:[
                    { required: true, message: '请选择地区编码', trigger: 'blur' },
                ],
                mobile:[
                    { required: true, message: '请输入开票人手机号', trigger: 'blur' },
                ],
              
            },
            compForm: {
                shopName: '',//企业名称
                sellerTaxpayerId: '',//企业税号
                taxAuthoritiesName: '',//	税务机关名称
                taxAuthoritiesCode: '',//	税务机关代码
                zipCode: '',//	地区编码
                mobile: '',//	开票人手机号		true	
            },
            personRules:{
                taxPayerType:[
                    { required: true, message: '请选择纳税人类型', trigger: 'blur' },
                ],
                orgType:[
                    { required: true, message: '请选择组织类型', trigger: 'blur' },
                ],
                taxDriverType:[
                    { required: true, message: '请选择税控设备类型', trigger: 'blur' },
                ],
                username:[
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                ],
                sellerBank:[
                    { required: true, message: '请输入开户行', trigger: 'blur' },
                ],
                sellerAccount:[
                    { required: true, message: '请输入银行账号', trigger: 'blur' },
                ],
                sellerTel:[
                    { required: true, message: '请输入注册电话', trigger: 'blur' },
                ],
                areaName:[
                    { required: true, message: '请选择省市区', trigger: 'blur' },
                ],
                address:[
                    { required: true, message: '请输入详细地址', trigger: 'blur' },
                ]
            },
            personForm: {
                businessName:'销项开票',
                taxPayerType: '',//	纳税人类型,可用值:NORMAL,MINI		true	string
                orgType: '',//	组织类型,可用值:PARENT_COMPANY,SUB_COMPANY		true	string
                openServices: '',//	开通业务,可用值:SALE		true	string
                taxDriverType: '',//	税控设备类型,可用值:NULL,SERVICE,COMMISSION,IS_NULL		true	string
                sellerBank: '',//	开户行		true	string
                sellerAccount: '',//	银行账号		true	string
                sellerTel: '',//	注册电话		true	string
                username: '',//	用户名		true	string
                address: '',//	地址		true	string
                province: '',
                provinceName: '',
                city: '',
                cityName: '',
                area: '',
                areaName: ''
            },
            provinceData: [],
            cityData: [],
            areaData: [],
            zipCodeList: [],
            taxOrgList: [],
            orgTypeList: [],
            taxPayerTypeList:[],//纳税人类型
            taxDriverTypeList:[],//
            stepInsecond:false,
            loaded:false,
            linkUrl:'https://portal.ele-cloud.com/omp-portal-web-110101087/#/login',
            descDialogShow:false,
            desc1:'https://eshop-prod.oss-cn-chengdu.aliyuncs.com/goods/ad62073313b74e7aaa89eb0b68d22065.png',
            desc2:'https://eshop-prod.oss-cn-chengdu.aliyuncs.com/goods/38e88be324804046b29e77b4cf014ffe.png',
            desc3:'https://eshop-prod.oss-cn-chengdu.aliyuncs.com/goods/3eeecfede09148f69a9f13e07f8ff4b2.png',
            isDisabled:false,
            isSubmit:false,//是否提交过数据
        }
    },
    computed: {
        
    },
    mounted() {

        this.getRegionAreaTree()
        this.getTaxOrgList()
        this.getOrgList()
        this.getZipCodeList()
        this.getTaxPayerTypeList()
        this.getTaxDriverTypeList()
        this.getUserShopInfo()

    },
    methods: {
        async getDataDetail (){
            try {
                const resp = await getElephantInvoiceById(this.sellerShopId)
                this.loaded = true
                if (resp) {
                    const { 
                        sellerShopId,
                        sellerName,
                        sellerTaxpayerId,
                        taxAuthoritiesCode,
                        taxAuthoritiesName,
                        zipCode,
                        mobile,
                        taxPayerType,
                        orgType,
                        openServices,
                        taxDriverType,
                        sellerBank,
                        sellerAccount,
                        sellerTel,
                        username,
                        province,
                        provinceCode,
                        city,
                        cityCode,
                        area,
                        areaCode,
                        address,
                        status
                    } = resp.data
                    this.sellerShopId = sellerShopId
                    this.compForm.shopName = sellerName//企业名称
                    this.compForm.sellerTaxpayerId = sellerTaxpayerId,//企业税号
                    this.compForm.taxAuthoritiesName = taxAuthoritiesName //	税务机关名称
                    this.compForm.taxAuthoritiesCode = taxAuthoritiesCode//	税务机关代码
                    this.compForm.zipCode = zipCode//	地区编码
                    this.compForm.mobile = mobile//	开票人手机号		true	
    
                    this.personForm.businessName = '销项开票'
                    this.personForm.taxPayerType = taxPayerType//	纳税人类型,可用值:NORMAL,MINI		true	string
                    this.personForm.orgType = orgType//	组织类型,可用值:PARENT_COMPANY,SUB_COMPANY		true	string
                    this.personForm.openServices = openServices//	开通业务,可用值:SALE		true	string
                    this.personForm.taxDriverType = taxDriverType//	税控设备类型,可用值:NULL,SERVICE,COMMISSION,IS_NULL		true	string
                    this.personForm.sellerBank = sellerBank//	开户行		true	string
                    this.personForm.sellerAccount = sellerAccount//	银行账号		true	string
                    this.personForm.sellerTel = sellerTel//	注册电话		true	string
                    this.personForm.username = username//	用户名		true	string
                    this.personForm.address = address//	地址		true	string
                    this.personForm.province = provinceCode
                    this.personForm.provinceName = province
                    this.personForm.city = cityCode
                    this.personForm.cityName = city
                    this.personForm.area = areaCode
                    this.personForm.areaName = area
    
                    this.stepInsecond = status
                    this.isSubmit = true
                }
            } catch (error) {
                this.loaded = true
            }
        },
        async getUserShopInfo (){
            const resp = await currentDetails()
            // if (resp && resp.data) {
            //     this.sellerShopId = resp.data.id
            //     this.compForm.shopName = resp.data.shopName
            //     this.getDataDetail()
            // }
            if( this.$route.query.id){
                this.sellerShopId = this.$route.query.id;
                this.getDataDetail()
            }else{
                this.loaded = true;
                this.sellerShopId = resp.data.id;
            }
            
        },
        async getTaxDriverTypeList (){
            const resp = await getTaxDriverType()
            if (resp ) {
                let tmp = []
                for (const key in resp) {
                    if (Object.hasOwnProperty.call(resp, key)) {
                        const element = resp[key];
                        tmp.push({
                            codeValue: key,
                            codeText: element
                        })
                    }
                }
                this.taxDriverTypeList = tmp
            }
        },
        async getTaxPayerTypeList (){
            const resp = await getTaxPayerType()
            if (resp) {
                let tmp = []
                for (const key in resp) {
                    if (Object.hasOwnProperty.call(resp, key)) {
                        const element = resp[key];
                        tmp.push({
                            codeValue: key,
                            codeText: element
                        })
                    }
                }
                this.taxPayerTypeList = tmp
            }
        },
        async getOrgList() {
            const resp = await getOrgType()
            if (resp) {
                let tmp = []
                for (const key in resp) {
                    if (Object.hasOwnProperty.call(resp, key)) {
                        const element = resp[key];
                        tmp.push({
                            codeValue: key,
                            codeText: element
                        })
                    }
                }
                this.orgTypeList = tmp
            }
        },
        async getTaxOrgList() {
            const resp = await getTaxCode()
            if (resp) {
                this.taxOrgList = resp
            }
        },
        async getZipCodeList() {
            const resp = await getZipCode()
            if (resp) {
                resp.forEach((e) => {
                    e['value'] = `${e.name} / ${e.code}`
                })
                this.zipCodeList = resp
            }
        },
        // 获取省市区列表
        getRegionAreaTree(leve) {
            let data = {
                level: 3,
            };
            regionAreaTree(data).then((res) => {
                if (res.code === 0) {
                    this.provinceData = res.data;
                    if (this.personForm.province) {
                        this.getCityBtn(this.personForm.province);
                    }
                    if (this.personForm.city) {
                        this.getAreaBtn(this.personForm.city);
                    }
                }
            });
        },
        selectedHandle(val) {
            console.log(val, "val");
            this.personForm.cityName = "";
            this.personForm.areaName = "";
            this.personForm.province = val + "";
            this.provinceData.forEach((item) => {
                if (item.id == val) {
                    this.personForm.provinceName = item.rname;
                }
            });
            this.getCityBtn(val);
        },

        getCityBtn(val) {
            getCity({
                id: val,
            }).then((res) => {
                if (res.code == 0) {
                    this.cityData = res.data;
                } else {
                    this.$message({
                        type: "warning",
                        message: res.msg,
                    });
                }
            });
        },
        //城市的选择按钮
        selectedHandleCity(val) {
            console.log(val);
            this.personForm.city = val + "";
            this.cityData.forEach((item) => {
                if (item.id == val) {
                    this.personForm.cityName = item.rname;
                }
            });
            this.personForm.areaName = "";
            this.getAreaBtn(val);
        },
        getAreaBtn(val) {
            getArea({
                id: val,
            }).then((res) => {
                if (res.code == 0) {
                    this.areaData = res.data;
                } else {
                    this.$message({
                        type: "warning",
                        message: res.msg,
                    });
                }
            });
        },
        //地区的选择按钮
        selectedHandleArea(val) {
            this.personForm.area = val + "";
            this.areaData.forEach((item) => {
                if (item.id == val) {
                    this.personForm.areaName = item.rname;
                }
            });
        },
        taxIdDidChange(e) {
            this.compForm.taxAuthoritiesCode = e.code
            this.compForm.taxAuthoritiesName = e.name

        },
        querySearchZipCode(queryString, cb) {
            var zipCodeList = this.zipCodeList;
            var results = queryString ? zipCodeList.filter(this.createFilter(queryString)) : zipCodeList;
            cb(results);
        },
        createFilter(queryString) {
            return (e) => {
                return (e.name.indexOf(queryString) > -1);
            };
        },
        handleSelectZipCode(e) {
            this.compForm.zipCode = e.code
        },
        async submitAction(){
            console.log(this.compForm);
            
            try {
                await this.$refs['compForm'].validate()
                await this.$refs['personForm'].validate()
                let obj = {
                    sellerShopId: this.sellerShopId ,
                    sellerName: this.compForm.shopName,
                    sellerTaxpayerId: this.compForm.sellerTaxpayerId,
                    taxAuthoritiesCode: this.compForm.taxAuthoritiesCode,
                    taxAuthoritiesName: this.compForm.taxAuthoritiesName,
                    zipCode: this.compForm.zipCode,
                    mobile: this.compForm.mobile,
                    taxPayerType: this.personForm.taxPayerType,
                    orgType: this.personForm.orgType,
                    openServices: "SALE",
                    taxDriverType: this.personForm.taxDriverType,
                    sellerBank: this.personForm.sellerBank,
                    sellerAccount: this.personForm.sellerAccount,
                    sellerTel: this.personForm.sellerTel,
                    username: this.personForm.username,
                    province: this.personForm.provinceName,
                    provinceCode: this.personForm.province,
                    city: this.personForm.cityName,
                    cityCode:this.personForm.city,
                    area: this.personForm.areaName,
                    areaCode: this.personForm.area,
                    address: this.personForm.address
                }
                const resp = await taxSave(obj)
                if(resp){
                    this.$message.success('保存成功')
                    // this.getDataDetail()
                    this.$router.push({
                        path:'/invoiceCenter/invoiceCenter-invoiceHeaderList', 
                    })
                }

            } catch (error) {
                return
            }
        },
        // 复制操作
        _copy(context) {
            navigator.clipboard.writeText(context)
            .then(() => {
                this.$message.success('复制成功');
            })
            .catch(err => {

            });
        },
        openLinkUrl(){
            this._copy(this.linkUrl)
            this.$nextTick(()=>{
                window.open(this.linkUrl,'_blank')
            })
        },
        checkLogin(){
            this.$router.push({
                path:'/invoiceCenter/invoiceCenter-invoiceLogin?id=' +this.$route.query.id, 

            })
        }
    }
}
</script>
<style lang="scss" scoped>
.mainContainer {
    width: 70vw;
    display: flex;
    flex-direction: column;
    margin: auto;
    flex: 1;

    .warView {
        background: #fff9e6;
        border: 1px solid #ffdb88;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        margin-top: 16px;
        justify-content: center;
        align-items: center;
        span {
            font-size: 14px;
            color: #626D77;
            line-height: 20px;
        }
    }

    .stepView {}

    .lineView {
        display: flex;
        flex: 1;
        .eachView {
            display: flex;
            flex: 1;
            flex-direction: column;
            border: 1px solid #eee;
            border-radius: 4px;
            padding: 16px 0px;
        }
    }

    .btnView {
        display: flex;
        // justify-content: flex-end;
        // padding-right: 6vw;
        justify-content: center;
        align-items: center;
    }
    .linkText{
        cursor: pointer;
        font-size: 22px;
        color: #595959;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: underline;
        margin-top: 16px;
    }
    .copyText{
        cursor: pointer;
        font-size: 26px;
        color: #259BD5;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: underline;
        margin-top: 32px;
    }
    .infoView{
        background: #fff9e6;
        border: 1px solid #ffdb88;
        border-radius: 4px;
        display: flex;
        margin: auto;
        margin-top: 16px;
        justify-content: center;
        align-items: center;
        width: 30vw;
        .infoInLine{
            display: flex;
            align-items: center;
            .infoDetail{
                width: 10vw;
                color: #595959;
                font-size: 16px;
                font-weight: 600;
            }
            span{
                margin-left: 30px;
                color: #259BD5;
                cursor: pointer;
            }
        }
    }
    .descView{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 16px 0px;
        cursor: pointer;
        span{
            color: #595959;
            font-size: 16px;
            text-decoration: underline;
        }
    }

        
}
.tips-list {
    height: 60px;width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    .item {
        margin: auto;color: #262626;font-size: 16px;font-weight: 600;
        .text { 
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: left;
            color: #cf1422;
            line-height: 24px;
        }
        .text-a {
            font-size: 16px;
            color: #111111; 
            text-align: center;
            span {
                position: relative; 
               z-index: 11;
            }
            &::before {
                display: inline-block;
                content: '';
                width: 16px;
                height: 16px;
                background: linear-gradient(167deg,#ffcce3 0%, #ea5863 100%);
                border-radius: 50%;
                box-shadow: 0px 3px 12px 0px rgba(246,147,142,0.13); 
                position: relative;
                left: 50px;
                z-index: 0!important;
            }
        }
    }
    
}

.tips-img {
   // height: 24vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: top; 
    .img {
        width: 29.6%;
        height: 288px;
        border-radius: 10px;
        overflow: hidden;
        //margin: 0 18px;
        img {
            width: 296px;
            height: 288px;
        }
    }
}
</style>