<template>
  <div class="goodsCards">
    <div class="itemCont"
         :class="active == index ? 'activeClass' : 'remove'"
         v-for="(item,index) in goodsData"
         :key="index"
         @click="choiceItem(item,index)">
      <div class="boxTop">
        <div class="itemImg">
          <el-image :src="item.image ||avater"
                    alt=""
                    lazy
                    :preview-src-list="item.image?[item.image]:[]"
                    style="width: 188px; height: 188px;padding: 10px" />
        </div>
        <div class="rightBox">
          <!-- <el-tooltip class="item"
                      effect="dark"
                      :content="item.name"
                      placement="top"> -->
          <div class="itemBox name"
               :style="params.chemName != item.name ? 'color:red;padding-bottom:20px' : 'padding-bottom:20px'">
            名称：{{ item.name||'--' }}
          </div>
          <!-- </el-tooltip> -->
          <!-- <el-tooltip class="item"
                      effect="dark"
                      :content="item.specification"
                      placement="top"> -->
          <div class="itemBox factory"
               :style="params.specification != item.specification ? 'color:red !important' : ''">
            规格：{{ item.specification||'--' }}
          </div>
          <!-- </el-tooltip> -->
          <!-- <el-tooltip class="item"
                      effect="dark"
                      :content="item.barCode"
                      placement="top"> -->
          <div class="itemBox factory">
            商品条形码：{{ item.barCode||'--' }}
          </div>
          <!-- </el-tooltip> -->
          <!-- <el-tooltip class="item"
                      effect="dark"
                      :content="item.approvalNumber"
                      placement="top"> -->
          <div class="itemBox factory">
            批准文号：{{ item.approvalNumber||'--' }}
          </div>
          <!-- </el-tooltip> -->
          <!-- <el-tooltip class="item"
                      effect="dark"
                      :content="item.factory"
                      placement="top"> -->
          <div class="itemBox factory"
               :style="params.factory != item.factory ? 'color:red !important' : ''">
            厂家：{{ item.factory ||'--'}}
          </div>
          <!-- </el-tooltip> -->
          <!-- <el-tooltip class="item"
                      effect="dark"
                      :content="item.middlePack"
                      placement="top"> -->
          <div class="itemBox factory">
            中包装：{{ item.middlePack||'--' }}
          </div>
          <!-- </el-tooltip> -->
          <!-- <el-tooltip class="item"
                      effect="dark"
                      :content="item.bigPack"
                      placement="top"> -->
          <div class="itemBox factory">
            大包装：{{ item.bigPack ||'--'}}
          </div>
          <div class="itemBox factory" v-if="item.origin">
            产地：{{ item.origin ||'--'}}
          </div>
          <!-- </el-tooltip> -->
          <!-- <div class="itemBox"
             style="width:100%;justify-content: space-between;padding-top:10px">
          <div style="width:100%;display:flex;justify-content: space-between;">
            <div class="price"
                 style="color: red;font-size:20px; justify-content:start;color:#F6212D">
              <span style="font-size:12px">
                ￥
              </span>
              {{ item.salePrice }}
            </div>
            <div class="price"
                 style="justify-content:start;font-size:12px;color:#595959;line-height:26px">
              数量：{{ item.goodsSkuInventory }}
            </div>
          </div>
          <div style="justify-content:end" />
        </div> -->
        </div>
      </div>
      <!-- <div class="bottomBox">
      <div>
        <img src="../../assets/shop.png"
             alt=""
             style="width:20px;heihgt:20px;position:relative;top:5px">
        <span class="company">
          {{ item.shopName }}
        </span>
      </div>
      <div>
        <el-button type=""
                   size="small"
                   icon="el-icon-shopping-cart-1"
                   style="color:#F5222D;background:#e4fffb;border:1px solid #74CFCB"
                   @click="handleAddbuy(item)">
          购物车</el-button>
      </div>
    </div> -->
    </div>
  </div>

</template>
<script>
import avater from "/public/img/default.png"
export default {
  props: {
    params: {
      type: Object,
      default: () => {
        return {}
      }
    },
    goodsData: {
      type: Array,
      default: () => {
        return []
      }
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      avater: avater,
    }
  },
  computed: {
    active: {
      get () {
        return this.activeIndex
      },
      set (val) {
        this.$emit('update:activeIndex', val)
      }
    }
  },
  methods: {
    handleAddbuy (item) { // 加入购物车
      this.$parent.handleAddbuy(item)
    },
    // 选中商品
    choiceItem (item, index) {
      this.active = index
      this.$emit('choiceItem', item)
    },
  }

}
</script>
<style lang="scss" scoped>
.activeClass {
  border: 2px solid #f5222d !important;
}
.remove {
  border: 1px solid #dcdfe6 !important;
}
.goodsCards {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 24px;
  justify-content: space-between;
  align-content: flex-start;
  .itemCont {
    // width: 16.1%;
    // min-width: 214px;
    width: 410px;
    // height: 205px;
    border: 1px solid #dcdfe6;
    margin: 0px 0px 10px 0px;
    border-radius: 4px;
    .boxTop {
      display: flex;
      align-items: center;
      .itemBox {
        width: 220px;
        padding: 3px 10px 3px 0px;
        // display: flex;
        // justify-content: flex-start;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        color: #262626;
      }
    }
    .bottomBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      .company {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #8c8c8c;
        position: relative;
      }
    }
    .name {
      display: block !important;
      font-size: 16px;
      font-weight: 600;
    }
    .factory {
      color: #8c8c8c !important;
      font-size: 14px;
    }

    .itemImg {
      // height: 180px;
      // width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .rightBox {
      padding-top: 15px;
    }
    .price {
      font-weight: 700;
      justify-content: end;
      font-size: 12px;
      color: #595959;
    }
    .shopCar {
      justify-content: center;
    }
  }
}
</style>
