<template>
  <div class="detail-root">
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible" width="918px"
      @closed="onClosed" v-loading="loading">
      <div class="content form-content form-content-new" v-if="mainInfo">
        <div class="row mutlti big">
          <div class="tip">合同名称</div>
          <div class="value input">
            <el-input size="small" maxlength="20" v-model="mainInfo.contractName" disabled></el-input>
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip">合同编码</div>
          <div class="value input">
            <el-input size="small" maxlength="20" disabled v-model="mainInfo.contractCode"></el-input>
            <!-- <div class="value-desc">合同编码由系统自动生成</div> -->
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip">协议公司</div>
          <div class="value input">
            <el-input size="small" maxlength="20" v-model="mainInfo.draftShopName" disabled></el-input>
          </div>
        </div>
      </div>
      <div class="content form-content">
        <div class="row mutlti big" style="margin-top: 12px">
          <div class="tip">关联产品</div>
          <div class="value">
            <div class="goods-info" v-for="(li,i) in goodsList" :key="i">
              <el-image :src="li.image || avater" lazy :preview-src-list="[li.image || avater]" style="width: 100px; height: 100px" />
              <div class="txt-info">
                <div class="name">{{ li.goodsName }}</div>
                <div class="factory">{{ li.factory }}</div>
                <div class="factory">{{ li.specification }}</div>
                <div class="factory">{{ li.approvalNumber }}</div>
                <div class="factory">商品编码：{{ li.goodsCode }}</div>
              </div>
              <div class="goods-info-del" v-if="action == 1 || action == 2" @click="goodsDel(i)"><i class="el-icon-delete"></i></div>
            </div>
          </div>
          <!-- <el-button v-if="!isLook" size="small" type="primary" @click="handleSelectGoods">选择产品</el-button> -->
        </div>
        <div class="row mutlti big">
          <div class="tip">合同范围</div>
          <div class="value input">
            <el-tree ref="treeRef" :key="treeKey" :data="treeData" show-checkbox node-key="id" :props="defaultProps" :default-checked-keys="defaultCheckedData"></el-tree>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">取 消</el-button>
        <el-button v-if="!isLook" size="small" type="primary" @click="handleSave">保存并提交</el-button>
      </div>
    </el-dialog>
    <!-- <selectGoods ref="refSelectGoods" @choose="didSelectItem" /> -->
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
// import selectGoods from "./select-goods.vue";
import avater from "/public/img/default.png";
import { contractDetail, contractEdit } from "@/api/compliance/contractManager";
import { regionTree } from "@/api/businessPlatform/relationshipMaintenance";
export default {
  // components: { selectGoods },
  data() {
    return {
      action: 1, //1 新增  2 编辑 3 查看
      dialogVisible: false,
      mainId: null, // 合同ID
      loading: false,
      avater: avater,
      mainInfo: null, // 合同详情
      goodsList: [], //选择的商品 array
      defaultProps: {
        children: 'children',
        label: 'name',
        disabled: this.treeIsDisabled,
      },
      defaultCheckedData: [],
      treeData: [],
      treeKey:0,
    };
  },
  computed: {
    title() {
      let txt = "合同";
      if (this.action === 2) {
        txt = `编辑${txt}`;
      } else if (this.action === 3) {
        txt = `查看${txt}`;
      } else {
        txt = `新增${txt}`;
      }
      return txt;
    },
    isLook() {
      return this.action === 3;
    },
  },
  mounted(){
    this.initTreeData();
  },
  methods: {
    treeIsDisabled(){
      if(this.action == 3){
        return true
      }
    },
    initTreeData(){
      this.treeData = [];
      regionTree().then(res=>{
        if(res.code == 0 && res.data && res.data.length>0){
          let treeData = res.data.map(item=>{
            let citys = item.children.map(li=>{
              delete li.children;
              return li;
            })
            return { ...item, children: citys }
          })
          // console.log(treeData)
          this.treeData = treeData;
        }
      })
    },
    open(options = null) {
      this.treeKey++;
      if (options) {
        const { action, row } = options;
        this.action = action || 1;
        this.mainId = row.id || null;
        // this.mainInfo = row;
        // this.goodsList = cloneDeep(row.contractGoodsList) || [];
        this.getDetail();
      }
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    onClosed() {
      this.reset();
    },
    goodsDel(i){
      this.goodsList.splice(i,1);
    },
    reset() {
      this.action = 1;
      this.mainId = "";
      this.mainInfo = null;
      this.goodsList = [];
      this.defaultCheckedData = [];
    },
    
    getDetail() {
      if (this.mainId) {
        this.loading = true;
        this.defaultCheckedData = [];
        // 替换新的获取详情方法
        contractDetail(this.mainId)
          .then((data) => {
            this.loading = false;
            this.mainInfo = data;
            this.goodsList = cloneDeep(data.contractGoodsList) || [];
            this.defaultCheckedData = data.areaList ? data.areaList.map(li => li.areaCode) :[];
          })
      }
    },
    handleSave() {
      if (this.goodsList.length == 0) {
        this.$message.warning("请选择商品!");
        return;
      }
      const data = {
        id: this.mainId,
        contractGoodsList: this.goodsList,
      };
      contractEdit(data).then((res) => {
        if (res) {
          this.$message.warning("合同药品编辑完成!");
          this.$emit("onRefresh");
          this.dialogVisible = false;
        }
      });
      // if (this.posterId) {
      //   //编辑
      //   // updateScienceProfile(data).then((res) => {
      //   //   if (res) {
      //   //     this.$emit("onRefresh");
      //   //     this.dialogVisible = false;
      //   //   }
      //   // });
      // } else {
      //   //保存
      //   // addScienceProfile(data).then((res) => {
      //   //   if (res) {
      //   //     this.$emit("onRefresh");
      //   //     this.dialogVisible = false;
      //   //   }
      //   // });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-root {
  .form-content {
    &-new {
      display: flex;
      flex-wrap: wrap;

      .row {
        width: 48%;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 0;
      box-sizing: border-box;

      &.mutlti {
        align-items: flex-start;
      }

      &.contine {
        padding-top: 0;
      }

      &.big {
        .tip {
          height: 32px;
          line-height: 32px;
        }
      }

      .tip {
        width: auto;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        text-align: right;
        color: #262626;
      }

      .value {
        margin-left: 16px;

        &-desc {
          line-height: 1;
          margin-top: 6px;
          color: #8c8c8c;
          font-size: 13px;
        }

        &.input {
          width: 300px;

          ::v-deep .el-date-editor.el-input,
          ::v-deep .el-date-editor.el-input__inner {
            width: 100%;
          }
        }

        &.fill {
          flex: 1;
          width: 0;
        }

        .small-tip {
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #8c8c8c;

          &.warning {
            color: #e6a23c;
          }
        }

        &.mutlti {
          flex: 1;
          width: 0;
          display: flex;

          &.v {
            flex-direction: column;

            .in-row {
              &:not(:first-child) {
                margin-top: 8px;
              }
            }
          }
        }

        .flag-block {
          width: 100%;
          background: #fafafa;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 8px 16px 16px 16px;
          box-sizing: border-box;
          flex-wrap: wrap;

          .flag-title {
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            margin-right: 8px;
          }

          &>* {
            margin-top: 8px;
          }
        }
      }

      .in-row {
        .value {
          margin-left: 0;
        }
      }
    }

    .opinion {
      color: #f00;
    }

    .goods-info {
      display: flex;
      align-items: center;
      margin-right: 30px;
      margin-bottom: 2px;
      padding: 5px;
      position: relative;
      cursor: pointer;
      &:hover{
        background-color: #eee;
        .goods-info-del{
          display: flex;
        }
      }
      &-del{
        position: absolute;
        z-index: 10;
        right: -24px;
        top: 0;
        color: #fff;
        display: none;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 24px;
        font-size: 15px;
        background: #f00;
        cursor: pointer;
      }

      .txt-info {
        display: flex;
        flex-direction: column;
        margin-left: 12px;

        .name {
          font-size: 16px;
          font-weight: 600;
          color: var(--color-26);
        }

        .factory {
          color: var(--color-59);
          font-size: 14px;
          margin-top: 3px;
        }
      }
    }
  }

  ::v-deep .el-dialog__body {
    max-height: 60vh;
    overflow-y: auto;
    min-height: 240px;
  }
}
</style>
