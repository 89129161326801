var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "classification" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small" },
            on: {
              click: function ($event) {
                return _vm.backPage()
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "center",
        staticStyle: { position: "relative" },
        attrs: { "element-loading-text": "正在加载页面，请稍候！" },
      },
      [
        _c("MyTable", {
          attrs: {
            "table-data": _vm.tableData,
            index: _vm.index,
            selection: _vm.selection,
            operation: _vm.operation,
            showPagination: false,
            "table-option": _vm.tableOption,
          },
          on: {
            "update:tableOption": function ($event) {
              _vm.tableOption = $event
            },
            "update:table-option": function ($event) {
              _vm.tableOption = $event
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }