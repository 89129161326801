var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialogMain" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "80px", "label-position": "left" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "发票类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.form.invoiceType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "invoiceType", $$v)
                    },
                    expression: "form.invoiceType",
                  },
                },
                _vm._l(_vm.invoiceTypeList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.codeText, value: item.codeValue },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "rowEnd" },
              [
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.cancel } },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.addSure },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }