const fieldType = { Iconst_Input: 1, Iconst_Pic: 2 };
//市场信息调查
const template_info = [
  {
    filedLabel: "任务说明",
    fieldName: "taskSpecification",
    type: fieldType.Iconst_Input,
    isMust: false,
    hide: true,
  },
  {
    filedLabel: "采集时间",
    fieldName: "pickTime",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "调研目的",
    fieldName: "researchPurpose",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "调研对象",
    fieldName: "researchName",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "客户名称",
    fieldName: "custName",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "基本情况",
    fieldName: "description",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "详细地址",
    fieldName: "custAddrDetail",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "竞品情况",
    fieldName: "content",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "总结",
    fieldName: "summary",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "商户照片",
    fieldName: "imgUrl",
    type: fieldType.Iconst_Pic,
    isMust: true,
  },
];
//客户拜访
const template_clientVisit = [
  {
    filedLabel: "任务说明",
    fieldName: "taskSpecification",
    type: fieldType.Iconst_Input,
    isMust: false,
    hide: true,
  },
  {
    filedLabel: "拜访日期",
    fieldName: "accessTime",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "客户类型",
    fieldName: "custName",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "拜访对象",
    fieldName: "visitObj",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "协访人员",
    fieldName: "visitName",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "签到时间",
    fieldName: "signTime",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "拜访目的",
    fieldName: "visitPurpose",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "客户反馈",
    fieldName: "customerFeedback",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "详细地址",
    fieldName: "signAddrName",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "总结",
    fieldName: "summary",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "图片上传(现场照片/门头照片)",
    fieldName: "imgUrl",
    type: fieldType.Iconst_Pic,
    isMust: true,
  },
];
//学术培训
const template_academic = [
  {
    filedLabel: "任务说明",
    fieldName: "taskSpecification",
    type: fieldType.Iconst_Input,
    isMust: false,
    hide: true,
  },
  {
    filedLabel: "标题",
    fieldName: "title",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "培训日期",
    fieldName: "trainTime",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "产品名称",
    fieldName: "skuName",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "客户名称",
    fieldName: "custName",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "客户类型",
    fieldName: "custType",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "客户地址",
    fieldName: "custAddr",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "培训对象",
    fieldName: "trainName",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "参加人数",
    fieldName: "trainPerson",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "培训方式",
    fieldName: "trainType",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "培训地址",
    fieldName: "trainAddrDetail", // fieldName: "trainAddrDetail",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "培训地点",
    fieldName: "trainAddr", // fieldName: "trainAddrName",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "培训老师",
    fieldName: "trainTeacher",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "培训内容",
    fieldName: "content",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "培训总结",
    fieldName: "summary",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "培训课件",
    fieldName: "trainCoursewareImgUrl",
    type: fieldType.Iconst_Pic,
    isMust: true,
  },
  {
    filedLabel: "培训照片",
    fieldName: "trainImgUrl",
    type: fieldType.Iconst_Pic,
    isMust: true,
  },
  {
    filedLabel: "其他文件",
    fieldName: "otherImgUrl",
    type: fieldType.Iconst_Pic,
    isMust: false,
  },
];
//终端开发
const template_terminal = [
  {
    filedLabel: "任务说明",
    fieldName: "taskSpecification",
    type: fieldType.Iconst_Input,
    isMust: false,
    hide: true,
  },
  {
    filedLabel: "标题",
    fieldName: "title",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "产品名称",
    fieldName: "skuName",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "客户类型",
    fieldName: "custName",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "购进日期",
    fieldName: "purchaseTime",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "产品陈列图片",
    fieldName: "displayImgUrl",
    type: fieldType.Iconst_Pic,
    isMust: true,
  },
  {
    filedLabel: "客户购进凭证",
    fieldName: "purchaseImgUrl",
    type: fieldType.Iconst_Pic,
    isMust: true,
  },
  {
    filedLabel: "门店环境照片",
    fieldName: "environmentImgUrl",
    type: fieldType.Iconst_Pic,
    isMust: true,
  },
  {
    filedLabel: "其他文件",
    fieldName: "otherImgUrl",
    type: fieldType.Iconst_Pic,
    isMust: false,
  },
];
//患者教育
const template_educate = [
  {
    filedLabel: "任务说明",
    fieldName: "taskSpecification",
    type: fieldType.Iconst_Input,
    isMust: false,
    hide: true,
  },
  {
    filedLabel: "标题",
    fieldName: "title",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "活动时间",
    fieldName: "educationTime",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "产品名称",
    fieldName: "skuName",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "活动主题",
    fieldName: "educationTheme",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "活动目的",
    fieldName: "purpose",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "活动地点",
    fieldName: "educationAddr",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "活动地址",
    fieldName: "addrDetail",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "活动总结",
    fieldName: "summary",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "活动照片",
    fieldName: "imgUrl",
    type: fieldType.Iconst_Pic,
    isMust: true,
  },
];
//广告宣传
const template_ad = [
  {
    filedLabel: "任务说明",
    fieldName: "taskSpecification",
    type: fieldType.Iconst_Input,
    isMust: false,
    hide: true,
  },
  {
    filedLabel: "标题",
    fieldName: "title",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "产品名称",
    fieldName: "skuName",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "生产企业",
    fieldName: "factory",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "宣传目的",
    fieldName: "purpose",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "宣传方式",
    fieldName: "way",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "宣传时间",
    fieldName: "advertisingTime",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "效果预期",
    fieldName: "effectExpectation",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "费用预算",
    fieldName: "expenseBudget",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "宣传物料数量",
    fieldName: "quantityMaterial",
    type: fieldType.Iconst_Input,
    isMust: true,
  },
  {
    filedLabel: "宣传物料设计",
    fieldName: "materialImgUrl",
    type: fieldType.Iconst_Pic,
    isMust: true,
  },
  {
    filedLabel: "宣传照片",
    fieldName: "imgUrl",
    type: fieldType.Iconst_Pic,
    isMust: true,
  },
  {
    filedLabel: "其他文件",
    fieldName: "otherImgUrl",
    type: fieldType.Iconst_Pic,
    isMust: false,
  },
];

export class metricsHelp {
  static bagStatusList = {
    1: "新建",
    2: "进行中",
    3: "待上传凭证",
    4: "凭证待审核",
    5: "凭证驳回",
    6: "已完成",
    7: "待开发票",
  };

  static status(bagStatus) {
    //指标状态：
    let status = "";
    if (metricsHelp.bagStatusList[bagStatus]) {
      status = metricsHelp.bagStatusList[bagStatus];
    }
    return status;
  }
}

export class childTaskHelp {
  /**
   * 子任务详情字段域配置
   * filedLabel:字段显示名称
   * fieldName:字段值对应字段属性
   * type:字段类型
   * isMust:是不是必填
   */
  static fieldType = fieldType;

  // 0. 草稿 1.待CSO公司审核, 2.待医药公司审核, 3.CSO公司驳回, 4.医药公司通过 , 5.医药公司驳回
  static taskStatusList = {
    0: "草稿",
    1: "待CSO公司审核",
    2: "待医药公司审核",
    3: "CSO公司驳回",
    4: "医药公司通过",
    5: "医药公司驳回",
    6: "平台审核",
    7: "平台审核驳回",
  };

  static status(taskStatus) {
    //任务状态：
    let status = "";
    if (childTaskHelp.taskStatusList[taskStatus]) {
      status = childTaskHelp.taskStatusList[taskStatus];
    }
    return status;
  }
}

export class taskHelp {
  //任务类型:
  static taskTypeList = [
    {
      value: 1,
      label: "客户拜访",
      template: template_clientVisit,
    },
    { value: 2, label: "学术培训", template: template_academic },
    { value: 3, label: "市场信息调研", template: template_info },
    { value: 4, label: "终端开发", template: template_terminal },
    { value: 5, label: "患者教育", template: template_educate },
    { value: 6, label: "广告宣传", template: template_ad },
  ];

  static taskStatusList = {
    1: "待开始",
    2: "执行中",
    3: "CSO提交",
    4: "医药公司审核通过",
  };

  static status(taskStatus) {
    //任务状态：
    let status = "";
    if (taskHelp.taskStatusList[taskStatus]) {
      status = taskHelp.taskStatusList[taskStatus];
    }
    return status;
  }
}

export const IConst_clientTag = {
  yygs: "YYGS",
  cso: "CSO",
  platform: "PLATFORM",
};
