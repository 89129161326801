import request from '@/utils/request'
// 对应后端product 服务
 
export function getSalesRelation(params) {
    return request({
        url: `/product/sales/external/seller/relation/page`,
        method: 'GET',
        params:params
    })
} 
// /sales/external/seller/goods/page
// 卖家分页查询商品列表
export function getGoodsPage(params) {
    return request({
        url: `/product/sales/external/seller/goods/page`,
        method: 'GET',
        params:params
    })
} 

// /goodsbase/goods/unit/feeRate/{shopId}/{goodsId}
// 商品平台服务费比例及单位信息
export function getUnitFeeRate(data) {
    return request({
        url: `/product/goodsbase/goods/unit/feeRate/${data.shopId}/${data.goodsId}`,
        method: 'GET' 
    })
} 


//代理--------- 新增代理控销sku
// /sales/sku/addAgencyControlPinSku
export function addAgencyControlPinSku(data) {
    return request({
        url: `/product/sales/sku/addAgencyControlPinSku`,
        method: 'POST',
        data
    })
} 
//代理--------- 编辑代理控销sku
// /sales/sku/updateAgencyControlPinSku
export function updateAgencyControlPinSku(data) {
    return request({
        url: `/product/sales/sku/updateAgencyControlPinSku`,
        method: 'POST',
        data
    })
} 

// queryAgencyControlPinSku/{goodsSkuId} 查询
export function queryAgencyControlPinSku(goodsSkuId) {
    return request({
        url: `/product/sales/sku/queryAgencyControlPinSku/${goodsSkuId}`,    
        method: 'GET', 
    })
}    