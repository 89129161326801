
const getDefaultState = () => {
  return {
    goods: []
    // roles: 'doctor'
  }
}

const state = getDefaultState()

const mutations = {
  SET_GOODS: (state, goods) => {
    state.goods = goods
  },
  REMOVE_GOODS: (state, item) => {
    state.goods = state.goods.filter(g => g.skuId !== item.skuId)
    console.log('state.goods', state.goods)
  }
}

const actions = {
  // remove token
  addGoods({ commit }, goods) {
    commit('SET_GOODS', goods)
  },
  removeGoods({ commit }, item) {
    console.log('action === removeGoods')
    commit('REMOVE_GOODS', item)
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

