<!-- 订单下面的表格组件 -->
<template>
  <div class="order">
    <div style="display: flex; align-items: center; flex: 1">
      <div style="display: flex">
        <div style="width: 350px">
          <span class="titleClass">商品信息</span>
        </div>
        <div style="width: 80px">
          <span class="titleClass">单价（元）</span>
        </div>
        <div style="width: 120px">
          <span class="titleClass">数量</span>
        </div>
        <div style="width: 120px">
          <span class="titleClass">商品操作</span>
        </div>
      </div>
      <div style="display: flex; flex: 1">
        <div style="display: flex; flex: 1">
          <span class="titleClass">收货信息</span>
        </div>
        <div style="display: flex; flex: 1">
          <span class="titleClass">物流信息</span>
        </div>
        <div style="width: 120px">
          <span class="titleClass">操作</span>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <div
      v-for="(propsrow, row) in tableData"
      :key="row"
      style="
        display: flex;
        flex: 1;
        border-radius: 4px;
        margin: 10px 0px;
        flex-direction: column;
        overflow: hidden;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
      "
    >
      <div
        style="
          background-color: #edf6f6;
          width: 100%;
          display: flex;
          align-items: center;
          padding: 10px 12px;
          position: relative;
        "
      >
        <div class="item" />
        <span class="descText"> 创建时间：</span>

        <span class="descText">{{ propsrow.orderTime }}</span>
        <span class="marrinLeft"
          >{{ propsrow.status == "1" ? "应付总额：" : "实付总额：" }}
        </span>
        <span class="descText">￥{{ propsrow.copeAmount }} </span>
        <span class="marrinLeft">
          {{ routerType == "procument" ? "商户" : "用户" }}：</span
        >
        <span class="descText">
          {{
            routerType == "procument"
              ? propsrow.payeeShopName
              : propsrow.payShopName
          }}
        </span>
        <span class="marrinLeft">订单号：</span>
        <span class="descText">{{ propsrow.orderCode }}</span>
        <span class="lastStatus" style="margin-left: auto">
          <div>
            <el-image
              class="icon_status"
              :src="getStatus(1, propsrow.status)"
              style="height: 20px; width: 20px"
            />
            <span class="descText" :class="getStatus(3, propsrow.status)">{{
              getStatus(2, propsrow.status)
            }}</span>
          </div>
          <!-- <div>30:30后未付款订单将自动取消</div> -->
          <TimeoutTag :order="propsrow" />
        </span>

        <span
          style="color: orange"
          v-if="
            propsrow.confirmReceiptDeadlineTimestamp && propsrow.status != 9
          "
        >
          <el-statistic
            @finish="hilarity(propsrow, row)"
            ref="statistic"
            format="DD天HH:mm:ss"
            :value="
              Date.now() + parseInt(propsrow.confirmReceiptDeadlineTimestamp)
            "
            title=""
            time-indices
            :value-style="{ color: '#f5222d', fontSize: '14px' }"
          >
            后订单完成
          </el-statistic>
        </span>
      </div>
      <div style="display: flex; flex: 1; align-items: center">
        <div style="display: flex; flex-direction: column">
          <div v-for="(item, index) in propsrow.orderDetailVo" :key="index">
            <div style="display: flex; flex: 1; align-items: center">
              <div
                class="thClass columnFlex"
                style="width: 350px; border-right: 1px solid #ebeef5"
              >
                <div class="imageBox">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="item.image || avater"
                  />
                  <div>
                    <p class="name">{{ item.name }}</p>
                    <p class="duanluo pihao">{{ item.approvalNumber }}</p>
                    <p class="duanluo">{{ item.factory }}</p>
                    <p class="duanluo">{{ item.specification }}</p>
                  </div>
                </div>
                <div>
                  <div
                    v-for="(giftTtem, giftIndex) in item.giftGoodsList"
                    :key="giftIndex"
                    class="inLine"
                  >
                    <GiftTag />
                    <span class="inLineName">{{ giftTtem.name }}</span>
                    <span class="inLineQty">x{{ giftTtem.qty }}</span>
                  </div>
                </div>
              </div>
              <div class="thClass" style="width: 80px">
                <div
                  style="
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <p v-if="routerType == 'procument'" class="duanluo">
                    ￥{{ item.price }}
                  </p>
                  <p v-else class="duanluo">
                    ￥{{ item.servicePrice || item.price }}
                  </p>
                </div>
              </div>
              <div class="thClass" style="width: 120px">
                <div
                  style="
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    flex-direction: column;
                  "
                >
                  <p
                    class="duanluo"
                    :class="item.shipmentsAmount != item.qty ? 'titleRed' : ''"
                  >
                    发货量：{{ item.shipmentsAmount || "0" }}
                  </p>
                  <p
                    class="duanluo"
                    v-if="item.shipmentsAmount != item.qty"
                    :class="item.shipmentsAmount != item.qty ? 'titleRed' : ''"
                  >
                    需求量：{{ item.qty }}
                  </p>
                </div>
              </div>
              <div class="thClass" style="width: 120px">
                <div
                  class="submit"
                  style="
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <el-link
                    v-if="routerType == 'procument'"
                    :disabled="
                      !(
                        propsrow.status == '8' &&
                        (item.afterSaleStatus == 'SHOP_REJECT' ||
                          !item.afterSaleStatus)
                      ) ||
                      (deliverControl == '1' && routerType != 'procument')
                    "
                    :underline="false"
                    type="primary"
                    class="btn"
                    @click="applyService(item, 'APPLY_FOR')"
                    >申请售后</el-link
                  >

                  <el-link
                    v-else
                    style="color: #595959 !important"
                    :disabled="getDisableStatus(propsrow, item)"
                    :underline="false"
                    type="primary"
                    class="btn"
                    @click="cancelSale(item, 'CANCEL_SALE', propsrow)"
                    >发起退货</el-link
                  >
                  <div
                    style="
                      font-size: 14px;
                      font-weight: 400;
                      color: #595959 !important;
                    "
                  >
                    {{
                      btnStr(propsrow.invoiceAfterSaleStatus)
                        ? `发票售后` + btnStr(propsrow.invoiceAfterSaleStatus)
                        : ""
                    }}
                  </div>
                  <div
                    style="
                      font-size: 14px;
                      font-weight: 400;
                      color: #595959 !important;
                    "
                  >
                    {{
                      btnStr(propsrow.certificationStatus)
                        ? `资质售后` + btnStr(propsrow.certificationStatus)
                        : ""
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <tr style="display: flex; flex: 1">
          <th
            class="thClass"
            style="text-align: left; display: flex; flex: 1; padding-left: 12px"
            :style="{
              height: propsrow.orderDetailVo.length * lineHeight + 'px',
            }"
          >
            <div
              style="
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
              "
            >
              <p
                v-if="!propsrow.erpShopCode && routerType == 'sale'"
                class="duanluo"
                style="color: #f5222d; cursor: pointer"
                @click="checkQualification(propsrow)"
              >
                下载首营资质
              </p>
              <p
                v-if="
                  routerType == 'sale' &&
                  deliverControl != '1' &&
                  propsrow.status != '12'
                "
                class="duanluo"
                style="cursor: pointer"
                @click="showErpDailog(propsrow)"
              >
                ERP编码：{{ propsrow.erpShopCode }}
                <i class="el-icon-edit" style="color: #f5222d"></i>
              </p>
              <p
                v-if="
                  routerType == 'sale' &&
                  deliverControl == '1' &&
                  propsrow.status != '12'
                "
                class="duanluo"
              >
                ERP编码：{{ propsrow.erpShopCode }}
              </p>
              <p
                class="duanluo"
                v-if="
                  propsrow.orderDetailVo.length &&
                  propsrow.orderDetailVo[0].certificationFlag
                "
                style="color: red !important; cursor: pointer"
                @click="checkQualification(propsrow)"
              >
                {{ propsrow.orderDetailVo[0].certificationFlag }}
              </p>
              <p
                class="duanluo"
                v-if="
                  propsrow.orderDetailVo.length &&
                  propsrow.orderDetailVo[0].contactsName
                "
              >
                {{ propsrow.orderDetailVo[0].contactsName }}
              </p>
              <p
                class="duanluo"
                v-if="
                  propsrow.orderDetailVo.length &&
                  propsrow.orderDetailVo[0].contactsPhone
                "
              >
                {{ propsrow.orderDetailVo[0].contactsPhone }}
              </p>
              <p
                class="duanluo"
                v-if="
                  propsrow.orderDetailVo.length &&
                  propsrow.orderDetailVo[0].address
                "
              >
                {{ propsrow.orderDetailVo[0].address }}
              </p>
              <OrderInvoiceComponnet
                :invoiceInfo="propsrow.invoiceTitleInfo"
                v-if="propsrow.invoiceTitleInfo"
                @didClickInvoice="
                  showQualificationInvoiceDialog(propsrow.invoiceTitleInfo)
                "
              />
            </div>
          </th>
          <th
            class="thClass"
            style="text-align: left; display: flex; flex: 1"
            :style="{
              height: propsrow.orderDetailVo.length * lineHeight + 'px',
            }"
          >
            <div style="padding-left: 12px">
              <p class="duanluo">
                {{
                  propsrow.orderDetailVo.length
                    ? propsrow.orderDetailVo[0].expressTime
                    : ""
                }}
              </p>
              <p class="duanluo">
                {{
                  propsrow.orderDetailVo.length
                    ? propsrow.orderDetailVo[0].expressStatus
                    : ""
                }}
              </p>
              <p class="duanluo txt-line-2">
                {{
                  propsrow.orderDetailVo.length
                    ? propsrow.orderDetailVo[0].expressDesc
                    : ""
                }}
              </p>
              <span class="detail">
                <el-link
                  v-if="
                    propsrow.orderDetailVo.length &&
                    propsrow.orderDetailVo[0].expressTime
                  "
                  :underline="false"
                  type="primary"
                  class="btn"
                  @click="goWuliuDetail(propsrow.id, propsrow.orderId)"
                  >物流详情</el-link
                >
              </span>
            </div>
          </th>
          <th
            class="thClass"
            style="width: 120px"
            :style="{
              height: propsrow.orderDetailVo.length * lineHeight + 'px',
            }"
          >
            <div
              class="submit"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                flex: 1;
              "
            >
              <el-link
                :underline="false"
                type="primary"
                class="btn"
                @click="
                  goDetail(
                    propsrow.id,
                    propsrow.orderId,
                    propsrow.selfCheckStatus
                  )
                "
                >订单详情</el-link
              >
              <el-link
                v-if="propsrow.status == '1' && routerType == 'procument'"
                :disabled="loading"
                :underline="false"
                type="primary"
                class="btn"
                @click="payBtn(propsrow)"
                >付款</el-link
              >
              <el-link
                v-if="routerType != 'procument'"
                :disabled="
                  loading ||
                  (deliverControl == '1' && routerType != 'procument')
                "
                :underline="false"
                type="primary"
                class="btn"
                @click="checkQualification(propsrow)"
                >查看资质</el-link
              >
              <el-image-viewer
                v-if="showViewer"
                :on-close="closeViewer"
                :url-list="[url]"
              />
              <el-link
                v-if="
                  propsrow.status == '9' &&
                  propsrow.serialStatus &&
                  routerType == 'procument' &&
                  propsrow.serialStatus != 'ALREADY'
                "
                :disabled="
                  (propsrow.serialStatus != 'OPEN' &&
                    propsrow.serialStatus != 'ERROR') ||
                  (deliverControl == '1' && routerType != 'procument')
                "
                :underline="false"
                type="primary"
                class="btn"
                @click="watchBtn(propsrow)"
                >{{
                  propsrow.serialStatus == "OPEN"
                    ? "申请开票"
                    : propsrow.serialStatus == "CHECK"
                    ? "开票审核中"
                    : propsrow.serialStatus == "ING"
                    ? "开票中"
                    : propsrow.serialStatus == "ERROR"
                    ? "开票失败"
                    : ""
                }}</el-link
              >
              <el-link
                v-if="
                  routerType == 'sale' &&
                  propsrow.serialStatus == 'OPEN' &&
                  propsrow.status != '12' &&
                  propsrow.status != '1'  &&
                  propsrow.status != '5'
                "
                :disabled="
                  (propsrow.serialStatus != 'OPEN' &&
                    propsrow.serialStatus != 'ERROR') ||
                  (deliverControl == '1' && routerType != 'procument')
                "
                :underline="false"
                type="primary"
                class="btn"
                @click="uploadInvoice(propsrow)"
                >{{
                  propsrow.serialStatus == "OPEN" ? "上传发票" : ""
                }}</el-link
              >
              <el-link
                v-if="
                  propsrow.serialStatus && 
                  propsrow.serialStatus == 'ALREADY'
                " 
                :underline="false"
                type="primary"
                class="btn"
                @click="look(propsrow)"
                >查看发票
              </el-link>

              <!-- <el-link v-if="(propsrow.status == '9') &&
                routerType == 'sale'
                "
                       :disabled="deliverControl == '1' && routerType != 'procument'"
                       :underline="false"
                       type="primary"
                       class="btn"
                       @click="commentOrderHander(propsrow)">订单评论
              </el-link> -->

              <el-link
                v-if="
                  propsrow.contractId &&
                  (routerType == 'procument' || routerType == 'sale')
                "
                :disabled="deliverControl == '1' && routerType != 'procument'"
                :underline="false"
                type="primary"
                class="btn"
                @click="lookContract(propsrow)"
                >查看合同
              </el-link>

              <el-link
                v-if="propsrow.beExistsVoucher"
                :underline="false"
                type="primary"
                class="btn"
                @click="checkCertificate(propsrow)"
                >查看凭证
              </el-link>
            </div>
          </th>
        </tr>
      </div>
      <p
        class="duanluo qulificationText"
        v-show="propsrow.orderCertification"
        @click="showQualificationDialog(propsrow)"
      >
        <i class="el-icon-info" style="color: #f5222d"></i
        ><el-link>资质需求：点击查看用户随货资质需求</el-link>
      </p>
      <el-tooltip effect="dark" placement="top" v-if="propsrow.orderMessage">
        <template #content>
          <div style="max-width: 20vw; text-align: center">
            {{ propsrow.orderMessage || "" }}
          </div>
        </template>
        <div class="duanluo remarkText txt-line-1">
          <span> 药店留言：{{ propsrow.orderMessage || "" }} </span>
          <!-- <span v-if="btnStr(propsrow.certificationStatus)">
            资质售后：{{ btnStr(propsrow.certificationStatus) || '' }}
          </span> -->
        </div>
      </el-tooltip>
    </div>
    <el-dialog
      title="编辑ERP编码"
      :visible.sync="visibleErp"
      width="30%"
      :before-close="handleCloseErp"
    >
      <el-form
        :model="dialogFormErp"
        status-icon
        ref="dialogFormErp"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="dialog-body">
          <el-form-item label="客户名称:">
            {{ dialogFormErp.payShopName }}</el-form-item
          >
          <el-form-item label="客户ERP编码:">
            <el-input
              v-model="dialogFormErp.erpShopCode"
              size="small"
              clearable
              style="width: 200px"
            ></el-input>
          </el-form-item>
        </div>
        <div class="flex-end">
          <el-button
            size="small"
            type="primary"
            style="margin-left: 8px"
            @click="handleCloseErp()"
            >返回</el-button
          >
          <el-button
            size="small"
            type="primary"
            style="margin-left: 8px"
            @click="submitErp(1)"
            >确认</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <UploadInvoice 
      ref="uploadInvoice" 
      @getList="getList"
      :limit="1"
    />
    <CommentOrder ref="commentOrder"></CommentOrder>
  </div>
</template>

<script>
import { buttonView } from "@/api/externalSalesRule/index";
import {
  invoiceRecord,
  getDownloadUrl,
} from "@/api/servicecenter/pendingPayment.js";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { checkStatus } from "@/utils/status";
import avater from "/public/img/default.png";
import { updateErpShopCode } from "@/api/businessManage";
import UploadInvoice from "../uploadInvoice";
import CommentOrder from "@/components/CommentOrder";
import { Upload } from "element-ui";
import { certificationStatusStr } from "@/utils/certificationStatus";
import OrderInvoiceComponnet from "./components/orderInvoiceComponnet.vue";
import GiftTag from "@/components/giftTag/index.vue";
import TimeoutTag from "./components/timeoutTag.vue";
export default {
  components: {
    ElImageViewer,
    Upload,
    UploadInvoice,
    CommentOrder,
    OrderInvoiceComponnet,
    GiftTag,
    TimeoutTag,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    routerType: {
      type: String,
      default: "sale",
    },
  },
  data() {
    return {
      deliverControl: "", // 发货控制
      showViewer: false,
      url: "",
      avater: avater,
      dialogFormErp: { erpShopCode: "", payShopId: null, payeeShopId: "" },
      visibleErp: false,
      lineHeight: 180,
    };
  },
  computed: {
    getStatus() {
      return (type, status) => checkStatus(type, status);
    },
  },
  watch: {},
  methods: {
    getDisableStatus(propsrow, item) {
      if (
        !(
          (propsrow.status == "2" || propsrow.status == "11") &&
          (item.afterSaleStatus == "SHOP_REJECT" || !item.afterSaleStatus)
        ) ||
        (this.deliverControl == "1" && this.routerType != "procument")
      ) {
        // if(propsrow.status == '11' ){
        //   // 在11 待开户状态  允许商家退货
        //   return false
        // }
        return true;
      }
      return false;
    },
    hilarity(e, index) {
      // this.tableData[index].countDownTime = ''
      // this.getList()
    },
    getList() {
      this.$emit("queryTableData");
    },
    // 查看开票信息
    showQualificationInvoiceDialog(e) {
      this.$emit("showQualificationInvoiceDialog", e);
    },
    // 查看资质需求
    showQualificationDialog(e) {
      this.$emit("showQualificationReportDialog", e);
    },
    btnStr(status) {
      // console.log(certificationStatusStr[status], 'certificationStatusStr[status]')
      return certificationStatusStr[status] || "";
    },
    // 查看凭证 （线下打款凭证）
    checkCertificate(row) {
      this.$emit("checkCertificate", row);
    },
    // 根据商户查询数据权限判断前端按钮展示
    buttonView() {
      buttonView().then((res) => {
        if (res) {
          this.deliverControl = res.deliverControl;
        }
      });
    },
    // 订单评论
    commentOrderHander(row) {
      this.$refs["commentOrder"].dialogVisible = true;
      this.$refs["commentOrder"].orderCode = row.orderCode;
      this.$refs["commentOrder"].init();
    },
    // 上传发票
    uploadInvoice(row) {
      this.$refs["uploadInvoice"].uploadFaPiao(row);
    },
    handleCloseErp() {
      this.visibleErp = false;
      this.dialogFormErp.shopId = null;
    },
    // 提交erp
    submitErp() {
      if (!this.dialogFormErp.erpShopCode) {
        this.$message.error("请输入erp编码");
        return;
      }
      const { payeeShopId, erpShopCode, payShopId } = this.dialogFormErp;
      if (!payeeShopId) return;
      updateErpShopCode({ payeeShopId, erpShopCode, payShopId }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.visibleErp = false;
          this.$message.success("提交成功");
          this.$emit("erp-click");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    showErpDailog(row) {
      if (!row.selfCheckStatus) {
        this.dialogFormErp.erpShopCode = row.erpShopCode || "";
        this.dialogFormErp.payeeShopId = row.payeeShopId || "";
        this.dialogFormErp.payShopId = row.payShopId;
        this.dialogFormErp.payShopName = row.payShopName;
        this.visibleErp = true;
      } else {
        this.$confirm(
          "自营商品的订单需要地区公司、省区公司审核完成，医药公司再做发货",
          "订单审核中",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            // type: 'warning',
            showCancelButton: false,
          }
        )
          .then(() => {})
          .catch(() => {});
      }
    },
    // 关闭弹窗
    closeViewer() {
      this.showViewer = false;
    },
    // 查看
    look(type) {
      console.log(type.id, "type");
      invoiceRecord(type.id).then((res) => {
        // this.url = res;
        // this.showViewer = true;
        // if(res){
        //   window.open(res)
        // }
      });
    },

    // 查看合同
    lookContract(row) {
      getDownloadUrl({ signTaskId: row.contractId }).then((res) => {
        if (res) {
          window.open(res.downloadUrl, "_blank");
        }
      });
    },

    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // if (rowIndex == 0) {
      if (columnIndex == 1) {
        return [1, 8];
      } else {
        return [0, 0];
      }
      // }
    },
    // 查看资质
    checkQualification(row) {
      console.log(row, "row");
      // this.$router.push({ path: '/ordercenter/ordercenter-qualification', query: { id: row.payShopId, btnShow: false } })
      this.$router.push({
        path: "/myQualification/myQualification-qualificationWithUser",
        query: { shopId: row.payShopId },
      });
    },
    // 申请售后
    applyService(item, type) {
      // this.$emit('applyService', row)
      this.$router.push({
        path: "/ordercenter/ordercenter-afterSale",
        query: {
          detailId: item.detailId,
          type: type,
          routerType: this.routerType,
        },
      });
    },
    // 取消售卖
    cancelSale(item, type, row) {
      const queryObj =  {
        detailId: item.detailId,
        type: type,
        routerType: this.routerType,
        payShopId : row.payShopId,
      }
      this.$router.push({
        path: "/ordercenter/ordercenter-afterSale",
        query:queryObj,
      });
    },
    // 订单详情
    goDetail(id, orderId, selfCheckStatus) {
      this.$emit("goDetail", id, orderId, selfCheckStatus);
    },
    // 取消订单
    cancelClick(id) {
      this.$emit("cancelClick", id);
    },
    // 付款
    payBtn(row) {
      this.$emit("payBtn", row);
    },
    // 申请开票
    watchBtn(row) {
      this.$emit("watchBtn", row);
    },
    // 物流详情
    goWuliuDetail(id, orderId) {
      this.$emit("goWuliuDetail", id, orderId);
    },
  },
  created() {
    this.buttonView();
    console.log(this.tableData, "13488188003");
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang="scss" scoped>
.order {
  display: flex;
  flex-direction: column;
  flex: 1;

  ::v-deep tbody {
    tr :first-child {
      th {
        padding: 10px 0;
      }
    }
  }

  .inLine {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #595959;
    margin: 5px 12px;
    font-weight: 400;

    .inLineName {
      margin-left: 5px;
    }

    .inLineQty {
      margin-left: 10px;
    }
  }
}

// ::v-deep .el-table__expanded-cell {
//   padding: 0px 0px;
// }

.builtinTableClass {
  width: 100%;
  border-color: #ebeef5;
}

.titleClass {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #262626;
  height: 35px;
}

.thClass {
  text-align: center;
  height: 180px;
  display: flex;
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  align-items: center;

  // padding: 5px 0px;

  div {
    color: #8c8c8c !important;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
  }

  .submit {
    text-align: center !important;
  }

  .btn {
    display: block;
    padding: 5px 0;
  }

  .titleRed {
    color: var(--main-color);
  }
}

.columnFlex {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
}

.qulificationText {
  padding-left: 12px;
  text-align: left;
  display: flex;
  align-items: center;
}

.remarkText {
  padding-left: 26px;
  text-align: left;
  line-height: 2;
  width: 350px;
}

.imageBox {
  display: flex;
  align-items: center;
  margin-left: 12px;

  .name {
    margin: 7px 0;
    color: #262626;
  }

  .pihao {
    color: #595959;
  }

  .el-image {
    padding: 0px !important;
  }
}

.duanluo {
  margin: 3px;
  font-weight: 400;
  color: #595959;
  font-size: 14px;

  .detail {
    float: right;
  }
}

.lastStatus {
  float: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  background: #edf6f6;
  // padding: 10px 10px;
  // border-radius: 4px;
  border-bottom: none;
}

::v-deep
  .el-table--enable-row-transition
  .el-table__body
  td.el-table__expanded-cell {
  background: #fff;
  padding: 0 0 10px 0;
}

::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__expanded-cell {
  background: #fff !important;
}

.el-table tr:hover {
  background: #fff !important;
}

.el-table td.el-table__cell div {
  // color: white;
  text-align: left;
}

::v-deep .el-table tbody tr:hover > td {
  background: #edf6f6 !important;
}

.icon_status {
  height: 20px;
  width: 20px;
  vertical-align: top;
}

.text {
  margin-left: 10px;
}

.yellow {
  color: #f99b0d;
}

.green {
  color: #32bf78;
}

.gray {
  color: #8c8c8c;
}

.marrinLeft {
  margin-left: 16px;
  font-size: 14px;
  color: #595959;
}

.descText {
  font-size: 14px;
  color: #595959;
}

::v-deep .el-table__body-wrapper .el-table__row td.el-table__cell {
  border-radius: 10px 10px 0 0;
}

::v-deep .el-table th.el-table__cell.is-leaf {
  // border: none;
}

::v-deep .el-table__row.expanded {
  // margin-bottom: 20px;
}

::v-deep .el-table__body {
  margin-top: 10px;
}

.toolTip {
  width: 40vw;
}
</style>
