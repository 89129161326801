<template>
    <div class="fundCenterView" ref="fundCenterView">
        <el-row>
            <el-col :span="6">
                <div class="grid-content ">
                    <div class="inline">
                        <img :src="fundamount" alt="" class="icon">
                        <span class="title">可提现总金额（元）</span>
                    </div>
                    <div class="moneyLine">
                        <span class="moneyType">￥</span>
                        <span class="money">{{ getMoney(canWithdrawMoney) }}</span>
                        <el-button size="mini" type="primary" @click="doWithDraw">提现</el-button>
                    </div>
                </div>
            </el-col>
            <!-- <el-col :span="12">
                <div class="grid-content">
                    
                    <div class="inline">
                        <img :src="fundaccount" alt="" class="icon">
                        <div class="title">收款账户</div>
                    </div>
                    <div class="account">账户名/账户ID：湖南德延科技有限公司/C3857394</div>
                    <div class="account">绑定银行卡：汉口银行（7528）</div>
                </div>
            </el-col> -->
        </el-row>
        <div class="tableView">
            <div class="tableTabs">
                <el-form ref="form" :model="form" :inline="true">
                    <el-form-item>
                        <el-input v-model.trim="form.orderId" size="small" placeholder="请输入订单ID" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker v-model="form.dateValue" type="daterange" range-separator="-"
                            start-placeholder="订单开始日期" end-placeholder="订单结束日期" size="small" clearable>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model.trim="form.erpOrName" size="small" placeholder="请输入客户ERP编码/客户名称" style="width:240px"
                            clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="form.type" placeholder="收支类型" size="small" clearable>
                            <el-option v-for="item in withdrawType" :key="item.code" :label="item.value" :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="small" type="primary" @click="getList">查询</el-button>
                        <el-button size="small" type="primary" @click="exportWithdraw">导出</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <withdrawListTable :tableHeight="tableHeight"  ref="withdrawTable" @pageInfoDidChange="pageInfoDidChange" fundType="dept"/>
            <div class="paninationView">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <fundWithdrawDialog :tableHeight="dialogTableHeight" @deptWithdrawSuccess="didClickConfirm"
            :dialogVisible="withdrawDialogVisible" @dialogDimiss="didClickCancel" ref="withDrawDialogRef" fundType="dept"/>
    </div>
</template>
<script>
import fundWithdrawDialog from "./component/fundWithdrawDialog";
import withdrawListTable from "./component/withdrawListTable";
import moneyListTable from "./component/moneyListTable";
import fundaccount from "@/assets/fundaccount.png";
import fundcard from "@/assets/fundcard.png";
import fundamount from "@/assets/fundamount.png";
import check from "@/assets/check.png";
import uncheck from "@/assets/uncheck.png";
import { withdrawalMoney, submitCnt, withdrawalValida,downloadWithdrawListByDept } from "@/api/fund/fundApi";
import dayjs from "dayjs";
import _ from "lodash";
export default {
    components: { fundWithdrawDialog, withdrawListTable, moneyListTable },
    data() {
        return {
            tableHeight: 0,
            dialogTableHeight: 0,
            withdrawDialogVisible: false,
            fundaccount: fundaccount,
            fundamount: fundamount,
            fundcard: fundcard,
            uncheck: uncheck,
            check: check,
            autoWithdraw: false,
            form: {
                erpOrName: '',
                dateValue: [],
                type: '',
                orderId:''
            },
            varifyCode:'',//短信验证码
            codeVisible:false,
            currentPage:1,
            pageSize:10,
            total:10,
            codeValidateData:undefined,//短信验证
            codeValidateSelectOrderList:[],//短信验证再次验证选择的订单
            withdrawType:[{ code : 'INCOME', value :'收入' },{ code : 'EXPENDITURE', value :'提现' }],
            canWithdrawMoney:'',//可提现金额
        }
    },
    mounted() {
        this.tableHeight = this.$refs.fundCenterView.offsetHeight - 120 - 56 - 40
        this.dialogTableHeight = this.$refs.fundCenterView.offsetHeight * 0.6
        this.getWithDrawMoney()
        this.getList()
    },
    methods: {
        getMoney(e){
            if(!e){
                return '0.00'
            }else{
                return e.toFixed(2)
            }
        },
        pendParams( type = 'query' ){
            let obj = type == 'query' ? {current:this.currentPage,size:this.pageSize} : {}
            
            obj['name'] = this.form.erpOrName || null //	请输入客户ERP编码/客户名称	query	false	
            obj['orderCode'] = this.form.orderId || null //	订单编码	query	false	
            if (this.form.dateValue && this.form.dateValue.length) {
                // startDate:'',//	订单创建开始时间	query	false	
                // endDate:'',// 订单创建结束时间
                obj['startDate'] = dayjs(this.form.dateValue[0]).format('YYYY-MM-DD')
                obj['endDate'] = dayjs(this.form.dateValue[1]).format('YYYY-MM-DD')
            }
            obj['amountType'] = this.form.type || null //amountType:'',//	余额变动类型,可用值:INCOME,EXPENDITURE	query	false	

            return obj
        },
        exportWithdraw(){
            // 导出提现记录
            const obj = this.pendParams('export')

            downloadWithdrawListByDept(obj)
        },
       
       doWithDraw(){
            this.$refs.withDrawDialogRef.getList()
            this.withdrawDialogVisible = true

       },
        handleClick(e) {
            this.currentPage = 1
            this.getList()
        },
        async didClickConfirm(e,v) {
            this.getList()
            
        },
        async getWithDrawMoney(){
            const resp = await withdrawalMoney()
            if (resp) {
                this.canWithdrawMoney = resp
            }
        },
        didClickCancel() {
            this.withdrawDialogVisible = false
        },
        getList(){

            const obj = this.pendParams()
            this.$refs.withdrawTable?.getList(obj)
        },
        handleSizeChange(e){
            // this.resetPageInfo()
            this.pageSize = e
            this.getList()
        },
        handleCurrentChange(e){
            // this.resetPageInfo()
            this.currentPage = e
            this.getList()
        },
        applyAccount(){
            // 申请开户
            this.$router.push({
                path:'/fund/fund-fundAccount',
                
            })
        },
        pageInfoDidChange(e){
            // 分页数据改变  得到总页数等 current: 1   total: 95
            this.currentPage = e.current
            this.total = e.total
        }
    }
}
</script>
<style lang="scss" scoped>
.fundCenterView {
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    height: 100%;

    .row-bg {
        background: #fff;
    }

    .grid-content {
        height: 120px;
        padding: 24px;
    }

    .tableView {
        background-color: #fff;
        padding: 0px 24px;

        .tableTabs {
            ::v-deep(.el-tabs__active-bar) {
                bottom: 6px !important;
            }

            ::v-deep(.el-date-editor .el-range-input) {
                width: 50%;
            }
        }

        .flexRight {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
        }
    }

    .inline {
        display: flex;
        align-items: center;

        .icon {
            width: 22px;
            height: 22px;
        }

        .title {
            color: #595959;
            font-size: 14px;
            margin-left: 5px;
        }
    }

    .moneyLine {
        display: flex;
        align-items: flex-end;
        color: rgba($color: #000000, $alpha: 0.85);
        margin-top: 10px;

        .moneyType {
            font-weight: 700;
            font-style: 16px;
            // margin-bottom: 3px;
        }

        .money {
            font-weight: 700;
            font-size: 30px;
            line-height: 1;
            margin-right: 12px;
        }
    }

    .account {
        margin-top: 10px;
        color: #262626;
        font-size: 14px;
        font-weight: 400;
    }

    .cardView {
        margin-top: 10px;

    }

    .checkView {
        display: flex;
        align-items: center;
        margin-left: 24px;
        cursor: pointer;
    }
    .paninationView{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>