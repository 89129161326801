<template>
  <div class="classification">
    <div class="metrics-infos">
      <div class="sigle-info">
        <span class="tip">任务名称：</span>
        <span>{{ task ? task.name : "" }}</span>
      </div>
      <div class="sigle-info">
        <span class="tip">指标名称：</span>
        <span>{{ task ? task.targetName : "" }}</span>
      </div>
      <div class="sigle-info">
        <span class="tip">合同名称：</span>
        <span>{{ task ? task.contractName : "" }}</span>
      </div>
      <div class="sigle-info">
        <span class="tip">CSO公司名称：</span>
        <span>{{ task ? task.coopShopName : "" }}</span>
      </div>
    </div>
    <div class="header">
      <el-form
        ref="formTool"
        :model="formTool"
        :inline="true"
        style="text-align: left"
      >
        <el-form-item prop="name" label="">
          <el-input
            style="width: 150px"
            v-model="formTool.submitter"
            placeholder="请输入提交人"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="name" label="提交时间">
          <el-date-picker
            size="small"
            v-model="formTool.sumitTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="name" label="">
          <el-select
            style="width: 150px"
            size="small"
            placeholder="请选择状态"
            clearable
            v-model="formTool.taskStatus"
          >
            <el-option
              v-for="item in auditStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button size="small" @click="handleBack">返回</el-button>
        <el-button size="small" @click="handleBatchCheck" v-if="showBatch">
          批量审核</el-button
        >
      </div>
    </div>

    <div class="center" style="position: relative">
      <MyTable
        :index="false"
        :selection="true"
        :table-data="tableData"
        :page="page"
        :table-option.sync="tableOption"
        @handleSelectionChange="handleSelectionChange"
        @page-change="getList"
      >
        <template slot="taskStatus" slot-scope="scope">
          <span>{{ status(scope.row.taskStatus) }}</span>
        </template>
        <template slot="operate" slot-scope="scope">
          <div class="table-op-col">
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="handleLook(scope.$index)"
              >查看</el-link
            >
            <el-link
              v-if="scope.row.taskStatus === 2"
              type="primary"
              :underline="false"
              size="small"
              @click="handleCheck(scope.$index)"
              >审核</el-link
            >
          </div>
        </template>
      </MyTable>
    </div>

    <childTaskDetailDlg
      ref="refChildTaskDetailDlg"
      @onRefresh="handleRefresh"
    />
    <batchCheckDlg ref="refBatchCheckDlg" @onConfirm="handleConfirmCheck" />
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import Tabs from "@/components/Tabs";
import { querySalesScienceProfilePage } from "@/api/salesControl/academic";

import childTaskDetailDlg from "@/views/compliance/task/dialog/childTaskDetailDlg.vue";
import batchCheckDlg from "@/views/compliance/childtask/dialog/batchCheckDlg.vue";

import {
  contractDoTaskPage,
  contractDoTaskAudit,
} from "@/api/compliance/childtask";
import { childTaskHelp, IConst_clientTag } from "@/utils/viewHelp";
import dayjs from "dayjs";
import { contractTargetTaskDetail } from "@/api/compliance/task";

export default {
  components: {
    MyTable,
    Tabs,
    childTaskDetailDlg,
    batchCheckDlg,
  },
  data() {
    return {
      targetId: "",
      taskId: "",
      task: null, //任务对象

      auditStatusList: [],
      formTool: { submitter: "", sumitTime: null, taskStatus: "" },
      tableData: [],
      tableOption: [
        { label: "提交人", prop: "createBy" },
        { label: "提交时间", prop: "subTime" },
        { label: "状态", prop: "taskStatus", slot: true },
        { label: "操作", prop: "operate", slot: true, width: "120px" },
      ],
      page: { total: 0, current: 1, size: 10 },

      selectedList: [], //选中的列表，待导出
    };
  },
  created() {
    this.targetId = this.$route.query.targetId;
    this.taskId = this.$route.query.taskId;

    for (let key in childTaskHelp.taskStatusList) {
      this.auditStatusList.push({
        value: key,
        label: childTaskHelp.taskStatusList[key],
      });
    }
    this.getTaskDetail();
    this.getList(1);
  },
  computed: {
    showBatch() {
      let ret = false;
      if (Array.isArray(this.tableData)) {
        ret = this.tableData.some((item) => item.taskStatus === 2);
      }
      return ret;
    },
  },
  methods: {
    status(taskStatus) {
      return childTaskHelp.status(taskStatus);
    },
    handleSelectionChange(list) {
      this.selectedList = list;
    },
    handleLook(index) {
      const row = this.tableData[index];

      this.openChildTaskDetail({ action: 3, id: row.id });
    },
    handleCheck(index) {
      const row = this.tableData[index];

      this.openChildTaskDetail({ action: 4, id: row.id });
    },
    handleBatchCheck() {
      if (this.selectedList.length <= 0) {
        this.$message.warning("请选择待审核的数据项！");
        return;
      }
      const taskStatusValus = Object.keys(childTaskHelp.taskStatusList);
      let bRet = this.selectedList.some((item) => {
        return item.taskStatus != taskStatusValus[2];
      });
      if (bRet) {
        this.$message.warning("包含了非医药公司待审核的数据项！");
        return;
      }

      this.$refs.refBatchCheckDlg.open({ count: this.selectedList.length });
    },
    handleConfirmCheck(params) {
      this.doAudit(params.type, params.reason);
    },
    doAudit(type, reason = "", cb) {
      let status = "";

      status = type === 1 ? "PASS" : "NOT_PASS";
      const idList = this.selectedList.map((item) => item.id);
      const data = {
        ids: idList,
        status: status,
        rejectContent: reason,
      };
      contractDoTaskAudit(data).then((res) => {
        if (res) {
          this.$refs.refBatchCheckDlg.close();
          this.handleRefresh();
        }
      });
    },
    openChildTaskDetail({ action, id }) {
      this.$refs.refChildTaskDetailDlg.open({
        clientTag: IConst_clientTag.yygs,
        action: action,
        id: id,
      });
    },
    getList(num) {
      if (num) {
        this.page.current = num;
      }
      const { current, size } = this.page;
      let params = {
        targetId: this.targetId,
        taskId: this.taskId,
        current,
        size,
        ...this.formTool,
      };
      delete params.sumitTime;
      if (
        Array.isArray(this.formTool.sumitTime) &&
        this.formTool.sumitTime.length === 2
      ) {
        params.startTime = dayjs(this.formTool.sumitTime[0]).format(
          "YYYY-MM-DD"
        );
        params.endTime = dayjs(this.formTool.sumitTime[1]).format("YYYY-MM-DD");
      }
      this.listLoading = true;
      contractDoTaskPage(params)
        .then((res) => {
          this.selectedList = [];
          this.tableData = res.records;
          this.page.total = res.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    handleRefresh() {
      this.getList(1);
    },
    handleReset() {
      for (let key in this.formTool) {
        this.formTool[key] = "";
      }
      this.getList(1);
    },
    handleBack() {
      this.$router.back();
    },
    getTaskDetail() {
      contractTargetTaskDetail(this.taskId).then((data) => {
        if (data) {
          this.task = data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.classification {
  padding: 0px;

  .metrics-infos {
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0px 24px 0px 24px;
    box-sizing: border-box;
    font-size: 14px;
    color: #262626;

    .sigle-info {
      .tip {
        color: #a0a0a0;
      }
      + .sigle-info {
        margin-left: 12px;
      }
    }
  }

  .header {
    height: 56px;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px - 44px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn-warp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .el-link {
      // margin: 5px;
    }
  }

  .table-op-col {
    display: flex;
    .el-link {
      & + .el-link {
        margin-left: 8px;
      }
    }
  }
}
</style>
