var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "structure" }, [
          _c(
            "div",
            {
              staticClass: "searchArea",
              staticStyle: {
                padding: "0px",
                "justify-content": "space-between",
              },
            },
            [
              _c("span", { staticClass: "tit" }, [_vm._v("组织架构名称")]),
              _c("el-input", {
                staticClass: "searchIpt",
                staticStyle: { visibility: "auto" },
                attrs: {
                  placeholder: "请输入组织架构名称",
                  "suffix-icon": "el-icon-search",
                  size: "mini",
                },
                model: {
                  value: _vm.treeVal1,
                  callback: function ($$v) {
                    _vm.treeVal1 = $$v
                  },
                  expression: "treeVal1",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "treeBoxParent" }, [
            _c(
              "div",
              { staticClass: "treeBox" },
              [
                _c("div", { staticClass: "title" }, [_vm._v("组织架构")]),
                _c("el-tree", {
                  ref: "tree1",
                  attrs: {
                    "node-key": "id",
                    data: _vm.treedata,
                    props: _vm.defaultProps,
                    "highlight-current": true,
                    "expand-on-click-node": false,
                    "filter-node-method": _vm.filterNode,
                  },
                  on: { "node-click": _vm.gettreeData },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "small",
                      disabled: !_vm.treedata || _vm.treedata.length == 0,
                    },
                    on: { click: _vm.showOutDialog },
                  },
                  [_vm._v("区域设置")]
                ),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "resultArea" }, [
          _c("div", { staticClass: "resultHearder" }, [
            _vm._v(_vm._s(_vm.resultHearderTit)),
          ]),
          _c("div", { staticClass: "resultContent" }, [
            _c("div", { staticClass: "contentItemMini item" }, [
              _c("div", { staticClass: "title" }, [_vm._v("组织关联商户")]),
              _c("div", { staticClass: "searchArea architecture" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { padding: "5px 0 0 0" },
                        attrs: {
                          filterable: "",
                          clearable: "",
                          size: "mini",
                          placeholder: "请选择省级供应商",
                        },
                        model: {
                          value: _vm.proviceValue,
                          callback: function ($$v) {
                            _vm.proviceValue = $$v
                          },
                          expression: "proviceValue",
                        },
                      },
                      _vm._l(_vm.proviceOptions, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              "margin-left": "5px",
                              height: "fit-content",
                              visibility: "hidden",
                            },
                            attrs: { type: "primary", size: "mini" },
                          },
                          [_vm._v("添加")]
                        ),
                        _vm.checkNodeDetail &&
                        _vm.checkNodeDetail.weight &&
                        _vm.authorizationShow
                          ? _c(
                              "el-checkbox",
                              {
                                staticStyle: { "margin-left": "5px" },
                                attrs: { "true-label": 1, "false-label": 0 },
                                on: { change: _vm.checkDataOnlyOne },
                                model: {
                                  value: _vm.dataUnityFlag,
                                  callback: function ($$v) {
                                    _vm.dataUnityFlag = $$v
                                  },
                                  expression: "dataUnityFlag",
                                },
                              },
                              [_vm._v(" 数据统一查看 ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div"),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { padding: "5px 0" },
                        attrs: {
                          clearable: "",
                          filterable: "",
                          size: "mini",
                          placeholder: "请选择公司",
                        },
                        model: {
                          value: _vm.companyValue,
                          callback: function ($$v) {
                            _vm.companyValue = $$v
                          },
                          expression: "companyValue",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.shopId,
                          attrs: { label: item.shopName, value: item.shopId },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          "margin-left": "5px",
                          height: "fit-content",
                        },
                        attrs: {
                          type: "primary",
                          disabled:
                            _vm.checkNodeDetail == null ||
                            !_vm.proviceValue ||
                            !_vm.companyValue,
                          size: "mini",
                        },
                        on: { click: _vm.dfcAdd },
                      },
                      [_vm._v("添加")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "list" }, [
                _c("div", { staticClass: "listContent" }, [
                  _c(
                    "ul",
                    _vm._l(_vm.teamList, function (item, index) {
                      return _c("li", { key: index }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "nameBox" }, [
                            _c(
                              "div",
                              {
                                staticClass: "name",
                                staticStyle: { color: "#aaaaaa" },
                              },
                              [_vm._v(_vm._s(item.type))]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "desc" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "name",
                                  staticStyle: { color: "#333" },
                                },
                                [_vm._v(_vm._s(item.shopName))]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "margin-left": "5px",
                                    height: "fit-content",
                                    "justify-content": "space-between",
                                  },
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.dfcDel(item)
                                    },
                                  },
                                },
                                [_vm._v("移除")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "contentItem item" },
              [
                _c(
                  "el-transfer",
                  {
                    ref: "transfer",
                    staticStyle: { display: "flex" },
                    attrs: {
                      filterable: "",
                      "filter-method": _vm.filterMethod,
                      titles: ["地区公司未分配市场", "地区公司已分配市场"],
                      "filter-placeholder": "请输入诊所名称",
                      data: _vm.allData,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ option }) {
                          return _c("div", { staticClass: "spanList" }, [
                            _vm._v(_vm._s(option.address || "") + " "),
                            _c("div", { staticClass: "spanTitle" }, [
                              _vm._v(_vm._s(option.shopName || "")),
                            ]),
                          ])
                        },
                      },
                    ]),
                    model: {
                      value: _vm.data,
                      callback: function ($$v) {
                        _vm.data = $$v
                      },
                      expression: "data",
                    },
                  },
                  [
                    _c("el-divider"),
                    _c(
                      "span",
                      { attrs: { slot: "left-footer" }, slot: "left-footer" },
                      [
                        _vm._v(
                          "待分配（" +
                            _vm._s(_vm.allData.length - _vm.data.length) +
                            "）"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "space-between",
                          flex: "1",
                        },
                        attrs: { slot: "right-footer" },
                        slot: "right-footer",
                      },
                      [
                        _c("span", [
                          _vm._v("已分配（" + _vm._s(_vm.data.length) + "）"),
                        ]),
                        _c(
                          "el-button",
                          {
                            staticClass: "transfer-footer",
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.submit },
                          },
                          [_vm._v("确认分配")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "区域设置",
            visible: _vm.showEditDialog,
            width: "60%",
            "close-on-click-modal": false,
            "before-close": _vm.outDialogClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showEditDialog = $event
            },
          },
        },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                width: "30%",
                title: _vm.insideTit,
                visible: _vm.showInsideDialog,
                "append-to-body": "",
                "close-on-click-modal": false,
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showInsideDialog = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "editArea" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dialogForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.dialogForm,
                        "status-icon": "",
                        rules: _vm.dialogRules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "组织架构", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "10" },
                            model: {
                              value: _vm.dialogForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.dialogForm, "name", $$v)
                              },
                              expression: "dialogForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.addNode("dialogForm")
                                },
                              },
                            },
                            [_vm._v("提交")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.resetForm("dialogForm")
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "dialogContent" }, [
            _c("div", { staticClass: "cLeft item" }, [
              _c("div", { staticClass: "cHeader" }, [
                _c("div", { staticClass: "tit" }, [_vm._v("组织架构")]),
                _c(
                  "div",
                  { staticClass: "ipt" },
                  [
                    _c("el-input", {
                      staticStyle: { visibility: "auto" },
                      attrs: {
                        size: "mini",
                        placeholder: "请您输入组织名称",
                        "suffix-icon": "el-icon-search",
                      },
                      model: {
                        value: _vm.zhTreeVal,
                        callback: function ($$v) {
                          _vm.zhTreeVal = $$v
                        },
                        expression: "zhTreeVal",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "dialogtree" },
                [
                  _c("el-tree", {
                    ref: "zhtree",
                    attrs: {
                      "node-key": "id",
                      data: _vm.treedata,
                      props: _vm.defaultProps,
                      "highlight-current": true,
                      "expand-on-click-node": false,
                      "filter-node-method": _vm.filterNode,
                    },
                    on: { "node-click": _vm.getNodeKey },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "cRight item" }, [
              _c(
                "div",
                { staticClass: "cHeader" },
                [
                  _c("div", { staticClass: "tit" }, [_vm._v("销售地域")]),
                  _c("el-input", {
                    staticStyle: { visibility: "auto" },
                    attrs: {
                      size: "mini",
                      placeholder: "请输入地域名称",
                      "suffix-icon": "el-icon-search",
                    },
                    model: {
                      value: _vm.cityVal,
                      callback: function ($$v) {
                        _vm.cityVal = $$v
                      },
                      expression: "cityVal",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "dialogtree" },
                [
                  _c("el-tree", {
                    ref: "cityTree",
                    attrs: {
                      "show-checkbox": "",
                      "node-key": "id",
                      "default-expanded-keys": _vm.expandedKeys,
                      "default-checked-keys": _vm.checkedKeys,
                      data: _vm.regionTreedata,
                      props: _vm.defaultProps,
                      "filter-node-method": _vm.filterNode,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "outDialogBtn" },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.outDialogClose } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.teamOrgAreaRelationsBatchFun },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }