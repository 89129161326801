var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.visible,
            "lock-scroll": true,
            width: "1000px",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formTool",
              staticStyle: { "text-align": "left" },
              attrs: {
                model: _vm.formTool,
                inline: true,
                "label-width": "140px",
              },
            },
            [
              _c("div", { staticClass: "goodList" }, [
                _c(
                  "div",
                  { staticClass: "img-div" },
                  [
                    _c("el-image", {
                      staticClass: "l",
                      attrs: {
                        src: _vm.formTool.image
                          ? _vm.formTool.image
                          : _vm.avater,
                        alt: "",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "r" }, [
                  _c("div", [
                    _c("span", { staticClass: "txt-line-1 name" }, [
                      _vm._v(_vm._s(_vm.formTool.name)),
                    ]),
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "rr txt-line-1" }, [
                      _vm._v(_vm._s(_vm.formTool.specification)),
                    ]),
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "rr txt-line-1" }, [
                      _vm._v(_vm._s(_vm.formTool.approvalNumber)),
                    ]),
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "rr txt-line-1" }, [
                      _vm._v(_vm._s(_vm.formTool.factory)),
                    ]),
                  ]),
                ]),
              ]),
              _vm.pageType != "add"
                ? _c(
                    "el-row",
                    { staticClass: "reject-c" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(" 驳回原因 "),
                      ]),
                      _c("el-input", {
                        staticStyle: { width: "50%" },
                        attrs: {
                          type: "textarea",
                          clearable: "",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formTool.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "remark", $$v)
                          },
                          expression: "formTool.remark",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "left_topic mb20",
                  staticStyle: { "margin-top": "10px" },
                },
                [_vm._v("推广费用设置")]
              ),
              _c(
                "el-row",
                { staticClass: "list" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "salesUserRate",
                        label: "推广费用比例",
                        rules: {
                          required: true,
                          message: "",
                          trigger: "blur",
                          validator: _vm.checkNumber,
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          clearable: "",
                          size: "small",
                          disabled: _vm.isDisabled,
                        },
                        on: { input: _vm.salesUserRateSet },
                        model: {
                          value: _vm.formTool.salesUserRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "salesUserRate", $$v)
                          },
                          expression: "formTool.salesUserRate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" % (输入范围5~50) "),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "list" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "promoteRate", label: "推广服务费比例" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", clearable: "", size: "small" },
                        model: {
                          value: _vm.formTool.promoteRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "promoteRate", $$v)
                          },
                          expression: "formTool.promoteRate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" % "),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "left_topic mb20",
                  staticStyle: { "margin-top": "10px" },
                },
                [_vm._v("试用期任务及合作期限")]
              ),
              _c(
                "el-row",
                { staticClass: "list" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "probationDays",
                        label: "试用期",
                        rules: {
                          required: true,
                          message: "请填写试用期",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          clearable: "",
                          size: "small",
                          disabled: _vm.isDisabled,
                        },
                        model: {
                          value: _vm.formTool.probationDays,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "probationDays", $$v)
                          },
                          expression: "formTool.probationDays",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" 天 "),
                  _c(
                    "span",
                    { staticStyle: { "margin-left": "10px", color: "#999" } },
                    [_vm._v("建议不超过90天")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "list" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "probationAmount",
                        label: "试用任务要求",
                        rules: {
                          required: true,
                          message: "请填写试用任务要求",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          clearable: "",
                          size: "small",
                          disabled: _vm.isDisabled,
                        },
                        model: {
                          value: _vm.formTool.probationAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "probationAmount", $$v)
                          },
                          expression: "formTool.probationAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" " + _vm._s(_vm.formTool.goodsSkuUnit || "") + " "),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "list" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "cooperateDays",
                        label: "合作期",
                        rules: {
                          required: true,
                          message: "请填写合作期",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          clearable: "",
                          size: "small",
                          disabled: _vm.isDisabled,
                        },
                        model: {
                          value: _vm.formTool.cooperateDays,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "cooperateDays", $$v)
                          },
                          expression: "formTool.cooperateDays",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" 天 "),
                  _c(
                    "span",
                    { staticStyle: { "margin-left": "10px", color: "#999" } },
                    [_vm._v("建议大于365天")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isDisabled
            ? _c(
                "div",
                { staticClass: "btnBox" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.chooseGoods },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }