<template>
  <div class="first">
    <div class="row">
      <div class="search-frame">
        <el-image :src="searchIcon" class="search-icon" />
        <div class="input-frame">
          <el-input v-model="keywords" />
        </div>
        <div class="search-btn" @click="handleSearch">搜索</div>
      </div>
    </div>
    <div class="center">
      <goodsItem class="goodsItem" v-for="(item, index) in records" :key="index" :item="item" @addGunalian="addGunalian">
      </goodsItem>
    </div>
    <div class="bottom">
      <el-button class="bototmButton" type="primary" size="small" @click="submit">确认商品</el-button>
      <Pagination :total="page.total" :page.sync="page.current" :limit.sync="page.size" :pageSizes="page.sizes"
        @pagination="changeSize" />
    </div>
  </div>
</template>
  
<script>
import { queryActivitySku } from '@/api/platformActivity/index'
import goodsItem from './goodsItem.vue'
import Pagination from '@/components/Pagination'
const ICONST_searchIcon = require("@/assets/public/search_icon.png");
export default {
  props: ['id'],
  data () {
    return {
      addList: [],
      searchIcon: ICONST_searchIcon,
      keywords: '',
      records: [
      ],
      page: {
        total: 0,
        current: 1,
        size: 12,
        sizes: [12, 24, 36, 48]
      }
    }
  },
  components: { goodsItem, Pagination },
  async created () {
    // console.log(this.id)
    await this.queryActivitySku()
  },
  mounted () {

  },
  watch: {},
  methods: {
    // 查询所有的商品
    async queryActivitySku (current) {
      if (current) {
        this.page.current = current
      }
      let params = {
        activityId: this.id,
        size: this.page.size,
        current: this.page.current,
        nameLike: this.keywords,
        shopList: [this.$store.state.user.user_info.shopId]
      }
      const res = await queryActivitySku(params)
      if (res && res.records) {
        this.records = res.records
      }
      this.page.total = res.total

      if (this.addList.length > 0 && this.records.length > 0) {
        this.addList.map(el => {
          this.records.map(e => {
            if (el.skuId == e.skuId) {
              e.clickNone = true
            }
            return { ...e }
          })
        })
      }

    },
    handleSearch () {
      this.queryActivitySku(1)
    },
    // updateSelectedGoods (val) {
    //   if (this.isShowSelectedResult) {
    //     this.records = this.records.filter(item => {
    //       return item.skuId != val.skuId
    //     })
    //     this.$emit('choice', this.records)
    //   }
    // },
    // 添加关联分类
    addGunalian (item) {
      // console.log(this.addList, JSON.stringify('addList'))
      if (item.clickNone) {
        this.addList.push(item)
      } else {
        if (this.addList.length > 0) {
          this.addList.forEach((el, index) => {
            if (el.skuId == item.skuId) {
              this.addList.splice(index, 1)
            }
          })
        }
      }
    },
    changeSize () {
      // console.log(this.page)
      this.queryActivitySku()
    },
    // 确认提交
    submit () {
      if (this.addList.length == 0) {
        this.$message.warning('请先选择活动商品')
        return
      }
      // console.log(this.addList)
      let arr = []
      this.addList.map(el => {
        arr.push(el.skuId)
      })
      this.$emit('changeStep', 2)
      this.$emit('getSkuList', this.addList)
    }
  }
}

</script>
<style lang="scss" scoped>
.first {
  height: 100%;

  .row {
    margin: 0 auto;
    width: 600px;
    display: flex;
    align-items: center;

    .search-frame {
      margin-top: 12px;
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      border-radius: 50%;
      border: 2px solid #ff4d4f;
      border-radius: 22px;
      box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08),
        0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      display: flex;
      align-items: center;

      .search-icon {
        width: 24px;
        height: 24px;
        margin-left: 16px;
      }

      .input-frame {
        flex: 1;
        height: 100%;
        box-sizing: border-box;

        ::v-deep .el-input {
          height: 100%;
        }

        ::v-deep .el-input__inner {
          border: 0;
          height: 100%;
          box-sizing: border-box;
        }
      }

      .search-btn {
        width: 100px;
        height: 40px;
        background: #f5222d;
        border-radius: 0px 20px 20px 0px;
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .center {
    margin-top: 10px;
    height: calc(100% - 102px);
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .goodsItem {
      height: 290px;
    }
  }

  .bottom {
    position: relative;

    .bototmButton {
      position: absolute;
    }
  }
}
</style>
