<template>
  <div>
    <el-dialog
      title="订单评论"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <div class="content">
        <div class="block">
          <div class="row">
            <div class="tip"><span class="weight">*</span>商品</div>
            <div class="remarks">商品是否与描述相符</div>
            <div class="value">
              <el-rate
                :value="detail ? detail.goodsScore : 0"
                disabled
                :colors="['#f5222d', '#f5222d', '#f5222d']"
                disabled-void-color="#d9d9d9"
                void-color="#d9d9d9"
                :texts="texts"
                show-text
                text-color="#f5222d"
              >
              </el-rate>
            </div>
          </div>
          <div class="row v-gap">
            <div class="tip"><span class="weight">*</span>物流</div>
            <div class="remarks">物流是否按时到达</div>
            <div class="value">
              <el-rate
                :value="detail ? detail.logisticScore : 0"
                disabled
                :colors="['#f5222d', '#f5222d', '#f5222d']"
                disabled-void-color="#d9d9d9"
                void-color="#d9d9d9"
                :texts="texts"
                show-text
                text-color="#f5222d"
              >
              </el-rate>
            </div>
          </div>
          <div class="row v-gap">
            <div class="tip"><span class="weight">*</span>客服</div>
            <div class="remarks">客服回复消息是否及时</div>
            <div class="value">
              <el-rate
                :value="detail ? detail.customerServiceScore : 0"
                disabled
                :colors="['#f5222d', '#f5222d', '#f5222d']"
                disabled-void-color="#d9d9d9"
                void-color="#d9d9d9"
                :texts="texts"
                show-text
                text-color="#f5222d"
              >
              </el-rate>
            </div>
          </div>
          <div class="row v-gap">
            <div class="tip"><span class="weight">*</span>售后</div>
            <div class="remarks">售后师傅积极响应</div>
            <div class="value">
              <el-rate
                :value="detail ? detail.afterSalesScore : 0"
                disabled
                :colors="['#f5222d', '#f5222d', '#f5222d']"
                disabled-void-color="#d9d9d9"
                void-color="#d9d9d9"
                :texts="texts"
                show-text
                text-color="#f5222d"
              >
              </el-rate>
            </div>
          </div>
        </div>
        <div class="block" style="margin-top: 24px">
          <div class="row"><div class="tip">商品评价</div></div>
          <div class="comment-txt">{{ detail ? detail.comment : "" }}</div>
        </div>
        <div class="block new-cls v-gap">
          <div class="public-pic-layout">
            <div
              class="item"
              v-for="(item, index) in detail && detail.imageList
                ? detail.imageList
                : []"
              :key="index"
            >
              <el-image
                :ref="`myImg${index}`"
                :src="item"
                fit="cover"
                class="icon"
                :initial-index="0"
                :preview-src-list="[item]"
              />
              <div class="preview">
                <el-image
                  :src="SeeIcon"
                  class="btn-icon"
                  @click="handlePreview(`myImg${index}`, true)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="h-line"></div>
        <div class="block" style="margin-top: 24px">
          <div class="row">
            <div class="tip" style="font-weight: 700">评价回复</div>
          </div>
          <div class="v-gap" v-if="!isReply">
            <el-input
              placeholder="请输入回复信息..."
              type="textarea"
              :rows="3"
              v-model="reply"
              maxlength="200"
            />
          </div>
          <div v-else class="comment-txt">{{ detail ? detail.reply : "" }}</div>
        </div>
        <div class="block new-cls v-gap">
          <div class="public-pic-layout">
            <div
              class="item"
              v-for="(item, index) in replyImageList"
              :key="index"
            >
              <el-image
                :ref="`myImg2${index}`"
                :src="item"
                fit="cover"
                class="icon"
                :initial-index="0"
                :preview-src-list="[item]"
              />
              <div class="preview">
                <el-image
                  :src="SeeIcon"
                  class="btn-icon"
                  @click="handlePreview(`myImg2${index}`, true)"
                />
                <el-image
                  v-if="!isReply"
                  :src="DelIcon"
                  class="btn-icon"
                  @click="handleDeleteImage(index)"
                />
              </div>
            </div>
            <div
              v-if="!isReply && replyImageList.length < IConst_Max_Pic_Count"
              class="item add-pic"
              @click="handleUpload()"
            >
              <div class="image-wrap">
                <el-image :src="AddIcon" />
              </div>
              <span class="text">上传图片</span>
            </div>
          </div>
          <div class="small-tip v-gap" v-if="!isReply">
            最多上传5张，图片格式jpg、png，图片大小不超过5M
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <span></span>
        <div>
          <el-button @click="handleClose" size="small">取 消</el-button>
          <el-button
            v-if="!isReply"
            type="primary"
            @click="handleConfirm()"
            size="small"
          >
            提交
          </el-button>
        </div>
      </span>
    </el-dialog>

    <UploadImageView
      ref="imgupload"
      request-id="1"
      :imgType="imgType"
      @upload="uploadSubmit"
    />
  </div>
</template>
<script>
import UploadImageView from "@/views/myQualification/components/UploadImage.vue";

const IConst_Small = require("@/assets/public/icon-small.png");
const IConst_See_Icon = require("@/assets/zz/see-icon.png");
const IConst_Del_Icon = require("@/assets/zz/del-icon.png");
const IConst_Add_Icon = require("@/assets/zz/add-icon.png");

import _ from "lodash";

import { replyComment, getCommentById } from "@/api/afterSales/serviceSheet";

export default {
  components: { UploadImageView },
  data() {
    return {
      texts: ["极差", "失望", "一般", "满意", "惊喜"],
      SeeIcon: IConst_See_Icon,
      DelIcon: IConst_Del_Icon,
      AddIcon: IConst_Add_Icon,

      imgType: "",
      IConst_Max_Pic_Count: 5,
      imageUrlList: [],

      selectedImageIndex: -1,
      smallIcon: IConst_Small,
      dialogVisible: false,

      queryObj: null,

      detail: {reply:null},

      productInfos: [],

      value: 3.7,

      reply: "",
      replyImageList: [],
    };
  },
  computed: {
    isReply() {
      return this.detail.reply ? true : false; //true 已回复  false 待恢复
    },
  },
  methods: {
    handleUpload() {
      this.$refs.imgupload.open(this.imgType);
    },
    uploadSubmit(obj) {
      const { type, url } = obj;
      this.replyImageList.push(url);
    },
    handlePreview(refName) {
      if (this.$refs[refName]) {
        if (this.$refs[refName] instanceof Array) {
          this.$refs[refName][0].showViewer = true;
        } else {
          this.$refs[refName].showViewer = true;
        }
      }
    },
    handleDeleteImage(index) {
      this.replyImageList.splice(index, 1);
    },
    handleConfirm() {
      if (!this.reply) {
        this.$message.warning("请输入您的评语");
        return;
      }
      let data = {
        id: this.queryObj.id,
        reply: this.reply,
      };
      if (this.replyImageList) {
        data.replyImage = this.replyImageList.join(",");
      }
      replyComment(data).then((res) => {
        if (res) {
          this.$message.warning("提交成功");
          this.$emit("update");
          this.handleClose();
        }
      });
    },
    doLoadDetail() {
      const params = {
        id: this.queryObj.id,
      };
      getCommentById(params).then((res) => {
        if (res.code == 0) {
          this.detail = res.data;

          if (this.detail.image) {
            this.detail.imageList = this.detail.image.split(",");
          }
          if (this.detail.replyImage) {
            this.replyImageList = this.detail.replyImage.split(",");
          }
        }
      });
    },
    clear() {
      this.selectedImageIndex = -1;
      this.detail = null;
      this.queryObj = null;
      this.reply = "";
      this.replyImageList = [];
    },

    handleClose() {
      this.dialogVisible = false;
    },
    init() {
      //
    },

    open(simpleItem) {
      this.clear();
      this.dialogVisible = true;
      this.queryObj = _.cloneDeep(simpleItem);

      this.doLoadDetail();
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  max-height: 60vh;
  overflow-y: auto;
  .txt-content {
    flex: 1;
    flex-shrink: 0;
    overflow-y: auto;
    max-height: 500px;
    padding-right: 6px;
    box-sizing: border-box;

    .public-tip {
      margin-top: 8px;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #32bf78;
      line-height: 22px;

      .small-icon {
        width: 52px;
        height: 24px;
        margin-right: 8px;
      }
    }

    .row {
      display: flex;
      padding: 4px 0;
      box-sizing: border-box;

      .small-tip {
        width: 4em;
        font-size: 14px;
        font-weight: 400;
        color: #595959;
        flex-shrink: 0;
      }

      .value {
        font-size: 14px;
        font-weight: 400;
        color: #262626;
      }
    }

    .big-title {
      font-size: 20px;
      font-weight: 500;
      text-align: left;
      color: #262626;
      line-height: 32px;
      margin-bottom: 8px;
      margin-top: 24px;
    }
  }
  .pic-content {
    flex-shrink: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    .img-frame {
      width: 424px;
      height: 424px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .big-img {
      width: 310px;
      height: 370px;
    }

    .multi {
      margin-top: 12px;
      display: flex;

      .muti-frame {
        width: calc(20% - 8px);
        aspect-ratio: 1;
        position: relative;
        margin-left: 8px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        cursor: pointer;

        &.selected {
          border: 1px solid #ff4d50;
        }

        .multi-img {
          position: absolute;
          left: 10%;
          top: 5%;
          width: 80%;
          height: 90%;
        }
      }
    }
  }

  .row {
    display: flex;
    align-items: center;

    .tip {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #595959;

      .weight {
        color: #f5222d;
      }
    }

    .remarks {
      width: 10em;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #8c8c8c;
      margin-left: 8px;
    }

    .value {
      margin-left: 38px;
    }

    .unit {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #f5222d;
      margin-left: 16px;
    }
  }

  .v-gap {
    margin-top: 8px;
  }

  .comment-txt {
    margin-top: 8px;
    min-height: 80px;
    background: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 8px 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #595959;
  }

  .h-line {
    margin-top: 24px;
    height: 1px;
    background: #f2f2f2;
  }

  .small-tip {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #8c8c8c;
  }
}

::v-deep .el-rate__icon {
  font-size: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;

  ::v-deep .el-button--text {
    &:active {
      color: #f99b0d;
    }
    color: #f99b0d;
  }
}
</style>
