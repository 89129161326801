<template>
  <div class="classification"
       v-loading="loading">
    <div class="card">
      <div class="staus_bar fl_bet border">
        <div class="detail_left">
          <div class="fl_cen">
            <div class="status">
              <el-image class="icon_status"
                        :src="getStatus(1, orderList.status)" />
              <div class="name"
                   :class="getStatus(3, orderList.status)">
                {{ getStatus(2, orderList.status) }}
              </div>
            </div>
            <div class="items">
              <div class="title">订单编号</div>
              <div style="height: 22px">{{ orderList.orderCode || '' }}</div>
            </div>
            <div class="items">
              <div class="title">下单时间</div>
              <div style="height: 22px">{{ orderList.orderTime || '' }}</div>
            </div>
            <div v-if="orderList.status == 1 && orderList.payTime"
                 class="items">
              <div class="title">付款时间</div>
              <div style="height: 22px; color: #e4393c">
                {{ orderList.payTime || ' ' }}
              </div>
            </div>
            <div class="items">
              <div class="title">售后电话</div>
              <div>
                {{ orderList.servicePhone || ' ' }}
              </div>
            </div>
          </div>
        </div>
        <div class="detail_right">
          <!-- <el-button v-if="
              Number(orderList.status) == 1 
            " type="danger emphasis_btn" @click.stop="qrCodeCreate(orderList)">立即付款</el-button> -->
          <el-button v-if="Number(orderList.status) == 8 || Number(orderList.status) == 3"
                     type="danger emphasis_btn"
                     @click="sureGoods">确认收货</el-button>
          <el-button v-if="Number(orderList.status) == 1 || Number(orderList.status) == 2"
                     class="cancel_btn"
                     :loading="isLoading"
                     @click.stop="cancelClick(id)">取消订单</el-button>
        </div>
      </div>
      <div class="staus_plan fl_left">
        <div :class="indexStep >= 1 ? 'active' : ''"
             class="step">
          <div class="fl_center">
            <div class="status">
              <el-image v-if="indexStep > 1"
                        class="icon_svg"
                        :src="icon" />
              <div v-else
                   class="idx">1</div>
            </div>
            <div class="name"
                 :class="indexStep > 1 ? 'sueccss' : ''">
              提交订单
            </div>
          </div>
          <div class="time">{{ orderList.orderTime || '' }}</div>
        </div>
        <div :class="indexStep >= 1 ? 'active' : ''"
             class="line" />
        <div :class="indexStep >= 2 ? 'active' : ''"
             class="step">
          <div class="fl_center">
            <div class="status fl_center">
              <el-image v-if="indexStep > 2"
                        class="icon_svg"
                        :src="icon" />
              <div v-else
                   class="idx">2</div>
            </div>
            <div class="name"
                 :class="indexStep > 2 ? 'sueccss' : ''">
              付款成功
            </div>
          </div>
          <div class="time">{{ orderList.payTime || '' }}</div>
        </div>
        <div :class="indexStep >= 2 ? 'active' : ''"
             class="line" />
        <div :class="indexStep >= 3 ? 'active' : ''"
             class="step">
          <div class="fl_center">
            <div class="status">
              <el-image v-if="indexStep > 3"
                        class="icon_svg"
                        :src="icon" />
              <div v-else
                   class="idx">3</div>
            </div>
            <div class="name"
                 :class="indexStep > 3 ? 'sueccss' : ''">
              商品出库
            </div>
          </div>
          <div class="time">{{ orderList.deliveryTime || '' }}</div>
        </div>
        <div :class="indexStep >= 3 ? 'active' : ''"
             class="line" />
        <div :class="indexStep >= 4 ? 'active' : ''"
             class="step">
          <div class="fl_center">
            <div class="status">
              <el-image v-if="indexStep > 4"
                        class="icon_svg"
                        :src="icon" />
              <div v-else
                   class="idx">4</div>
            </div>
            <div class="name"
                 :class="indexStep > 4 ? 'sueccss' : ''">
                 配送完成
            </div>
          </div>
          <div class="time">{{ orderList.signDate || '' }}</div>
        </div>
        <div :class="indexStep >= 4 ? 'active' : ''"
             class="line" />
        <div :class="indexStep >= 5 ? 'active' : ''"
             class="step">
          <div class="fl_center">
            <div class="status">
              <el-image v-if="indexStep > 5"
                        class="icon_svg"
                        :src="icon" />
              <div v-else
                   class="idx">5</div>
            </div>
            <div class="name"
                 :class="indexStep > 5 ? 'sueccss' : ''">
              交易完成
            </div>
          </div>
          <div class="time">{{ orderList.finishDate || '' }}</div>
        </div>
      </div>
    </div>
    <div class="info card">
      <el-tabs v-model="orderLogistics" class="headerTabs" @tab-click="handleClick" v-if="orderLogisticsList.length>0">
        <el-tab-pane :label="item.label" :name="item.expressDeliveryNumber" v-for="(item, index) in orderLogisticsList"
          :key="index">
          <span slot="label">
            {{ item.kuaidiName }} {{ item.expressDeliveryNumber }}
          </span>
        </el-tab-pane>
      </el-tabs>
      <el-collapse v-model="activeName">
        <el-collapse-item name="1">
          <template slot="title">
            <div style="line-height: 20px; height: 80px">
              <div style="color: #f56c6c; font-weight: 600">
                {{ expressData.step || '暂无物流数据' }}
              </div>
              <div>
                {{ expressData.description || '' }}
              </div>
            </div>
          </template>
          <div style="padding-top: 10px">
            <el-timeline v-if="expressData.child && expressData.child.length > 0">
              <el-timeline-item v-for="(activity, index) in expressData.child"
                                :key="index"
                                color="#F5222D">
                <div style="font-weight: 600">{{ activity.step || '' }}</div>
                <div>{{ activity.description || '' }}</div>
                <el-image v-if="activity.image"
                          style="display:block; width: 100px; height: 100px"
                          :src="activity.image || ''"
                          :preview-src-list="[activity.image || '']">
                </el-image>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="info card fl_cen">
      <div class="info_list">
        <div class="title">收件人信息</div>
        <div class="lists">
          <div class="item">
            <span>收货人：</span>{{ orderList.contactsName || '--' }}
          </div>
          <div class="item">
            <span>地址：</span>{{ orderList.province || ''
            }}{{ orderList.city || '' }}{{ orderList.district || ''
            }}{{ orderList.address || '' }}
          </div>
          <div class="item">
            <span>手机号：</span>{{ orderList.contactsPhone || '--' }}
          </div>
        </div>
      </div>
      <div class="info_list">
        <div class="title">配送信息</div>
        <!-- <div class="lists" v-if="orderList.status == 2 && !orderList.kuaidiName">
          <div class="list_input fl_cen">
            <div>
              <el-input class="formInput" v-model="form.nums" placeholder="填写快递单号"></el-input>
              <el-autocomplete class="formInput" v-model="form.companyName" placeholder="填写快递公司" :fetch-suggestions="querySearchAsync" @select="handleSelect" value-key="name"></el-autocomplete>
            </div>
            <el-button class="formbutton" :loading="isBtnLoading" @click.stop="addKuaidi">提 交</el-button>
          </div>
        </div> -->
        <div v-if="orderList.beDelivery == '1'"
             class="lists">
          <!-- <div class="title">配送信息</div> -->
          <div class="item">
            <span>司机姓名：</span>{{  orderList.deliveryDriver || '--' }}
          </div>
          <div class="item">
            <span>联系方式：</span>{{ orderList.driverPhone || '--' }}
          </div>
          <div class="item">
            <span>预计送达：</span>{{ orderList.finishDate || '--' }}
          </div>
        </div>
        <div v-else
             class="lists">
          <!-- <div class="title">配送信息</div> -->
          <div class="item">
            <span>快递公司：</span>{{ orderList.kuaidiName || '--' }}
          </div>
          <div class="item">
            <span>快递单号：</span>{{ orderList.number || '--' }}
          </div>
          <div class="item">
            <span>预计送达：</span>{{ orderList.finishDate || '--' }}
          </div>
        </div>
      </div>
      <div class="info_list">
        <div class="title">付款信息</div>
        <div class="lists">
          <div class="item">
            <span>商品总额：</span>￥{{ orderList.amount || '--' }}
          </div>
          <div class="item"><span>支付方式：</span>在线支付</div>

          <div class="item">
            <span>付款时间：</span>{{ orderList.payTime || '' }}
          </div>
        </div>
      </div>
    </div>
    <div class="commodity card">
      <publicTable :table-data="list"
                   :colum-obj="columObj"
                   :page-obj="pageObj" />
      <div class="price_detail">
        <div class="items">
          <span class="left">商品件数：</span>
          <span class="right">{{ orderList.sumQty || 0 }}件</span>
        </div>
        <div class="items">
          <span class="left">商品总价：</span>
          <span class="right">¥{{ orderList.amount || 0 }}</span>
        </div>
        <div class="items">
          <span class="left">运费：</span>
          <span class="right">¥{{ orderList.freightPrice || 0 }}</span>
        </div>
        <div class="items">
          <span class="left">应付总额：</span>
          <span class="right all">¥{{ orderList.totalPrice || 0 }}</span>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="isQrCode"
               width="800px"
               :destroy-on-close="true"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :before-close="qrCodeBtn"
               class="codeCont">
      <div class="qrcodeCon">
        <p class="qrTip">请尽快付款！订单号：{{ orderList.orderCode }}</p>
        <p class="qrdesc">请您在5分钟内完成支付，否则订单会被自动取消！</p>
        <div class="qrCont">
          <p>
            应付金额：
            <span>¥{{ total }}</span>
          </p>
          <div id="qrcode"
               ref="qrcode"
               class="qrcodeImg" />
        </div>
      </div>
    </el-dialog>
    <el-dialog title="确认收货"
               :visible.sync="dialogVisible"
               width="30%"
               :before-close="handleClose">
      <span>确认收货后将无法发起售后</span>
      <div v-if="url"
           style="margin-top: 10px;">
        <el-image style=" width: 60px; height: 60px"
                  :src="url"
                  :preview-src-list="[url]">
        </el-image>
        <span class="imgList_li_close"
              @click="imgDel(index, i)">
          <i class="el-icon-delete" />
        </span>
      </div>

      <span slot="footer"
            style="display: flex;justify-content: space-between;"
            class="dialog-footer">
        <div>
          <el-button :disabled="url != ''"
                     type="primary"
                     @click="goUpload"
                     size="small">上传图片</el-button>
        </div>
        <div>
          <el-button @click="dialogVisible = false"
                     size="small">取 消</el-button>
          <el-button type="primary"
                     @click="submit"
                     size="small">确 定</el-button>
        </div>
      </span>
    </el-dialog>
    <MyUpload ref="imgupload"
              @upload="uploadSubmit" />
  </div>
</template>
<script>
import {
  getPurchaseDetail,
  cancelPurchase,
  getKuaidi,
  payResult,
  payPurchase,
  expressStep,
  saleKuaidi,
  saleFinish,
  queryOrderExpressDeliveryListByOrderId,
} from '@/api/servicecenter/pendingPayment.js'
import QRCode from 'qrcodejs2'
import { mapState } from 'vuex'
import { checkImages } from '@/utils/status'
import { returnFloat, correspondingVal, accMul } from '@/utils/validate'
import publicTable from '@/components/publicTable'
import { mapGetters } from 'vuex'
import MyUpload from './components/upload.vue'
export default {
  name: 'PurchaseDetail',
  components: { publicTable, MyUpload },
  data () {
    return {
      orderLogisticsList: [ ], 
      loading: false,
      url: '',
      dialogVisible: false,
      activeName: '1', // 手风琴展开
      expressData: {
        step: ''
      },
      radio: '',
      isBtnLoading: false,
      form: {
        nums: '',
        companyName: '',
        company: ''
      },
      isLoading: false,
      paydialog: false,
      id: '',
      orderId: '',
      list: [],
      orderList: {},
      icon: require('@/icons/img/icon_detail_status.png'),
      indexStep: 0,
      pageObj: {
        // 分页对象
        isShow: false,
        position: 'right', // 分页组件位置
        total: 0,
        page: {
          size: 10,
          current: 1,
          goodsName: '',
          supplierId: '',
          type: ''
        }
      },
      tableData: [],
      columObj: {
        tableName: 'multipleTable',
        // 选择框
        isShow: false,
        selection: false,
        // 选择框根据条件是否可选
        selectable: () => {
          return true
        },
        lazy: 'true',
        loading: false,
        // column列,columType(列类型,可选text(默认为普通文字模式),input(input可编辑框),switch(switch开关),image(图片),operation(操作按钮))
        // prop(参数),label(列名),width(宽度),align(对齐方式),sortable(是否支持排序)
        columnData: [
          {
            imageText: true,
            prop: 'image',
            prop2: 'name',
            prop3: 'factory',
            label: '商品信息',
            width: '300',
            align: 'left',
            sortable: false,
            ownDefinedReturn: (row) => {
              return row.factory
            }
          },
          {
            text: true,
            prop: 'specification',
            label: '规格',
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              // returnFloat(accMul(row.qty, row.price))
              return row.specification
            }
          },
          {
            ownDefined: true,
            prop: 'price',
            label: '单价',
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              return ('￥' + returnFloat(row.price))
            }
          },

          {
            text: true,
            prop: 'qty',
            label: '数量',
            width: '200',
            align: 'center'
          },
          {
            text: true,
            prop: "batchNumber",
            label: "批号",
            width: "200",
            align: "center",
          },
          {
            text: true,
            prop: "traceableCode",
            label: "溯源码",
            width: "200",
            align: "center",
          },
          {
            ownStyle: true,
            prop: 'smallPrice',
            label: '小计',
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              return returnFloat(accMul(row.qty, row.price))
            }
          }
        ]
      },
      isQrCode: false,
      qrcode: null,
      total: '',
      orderPayId: '',
      status: ''
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState('App', {
      selectList: 'selectList'
    }),
    getStatus () {
      return (type, status) => checkImages(type, status)
    }
  },
  methods: {
    // 切换物流单号
       handleClick() {
      this.choseLogistics(); 
    },

    goUpload () {
      this.$refs['imgupload'].open('')
    },
    // 上传图片
    uploadSubmit (obj) {
      console.log(obj)
      this.url = obj.url
    },
    // 删除图片
    imgDel () {
      this.$confirm("此操作删除上传的图片, 是否确认操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.url = '';
        })
        .catch(() => {
          this.$message.warning("您已取消操作");
        });
    },
    // 取消确认收货
    handleClose () {
      this.dialogVisible = false
    },
    // 确认收货
    submit () {
      this.loading = true
      saleFinish(
        {
          id: this.orderList.id,
          image: this.url
        }
      ).then(res => {
        this.loading = false
        this.dialogVisible = false
        if (res.code == 0) {
          this.$message.success('收货成功')
          this.$router.push('/ordercenter/ordercenter-procurement')
        } else {
          this.$message.error(res.msg)
        }
      })
        .catch(err => {
          this.loading = false
          this.dialogVisible = false
        })
    },
    // 确认收货
    sureGoods () {
      this.dialogVisible = true
    },
    handleSelect (e) {
      this.form.company = e.value
    },
    querySearchAsync (queryString, cb) {
      getKuaidi({ name: queryString }).then((res) => {
        cb(res.data)
      })
    },
     // 当前选择的三方物流
     choseLogistics(type) {
      let item = {};
      for (let i = 0; i < this.orderLogisticsList.length; i++) {
        if (this.orderLogisticsList[i].expressDeliveryNumber == this.orderLogistics) {
          if (type == 0) {
            item = this.orderLogisticsList[type];
          } else {
            item = this.orderLogisticsList[i];
          }
          this.form.company = item.kuaidiId;
          this.form.companyName = item.kuaidiName; 
          this.form.nums = item.expressDeliveryNumber;
          this.form.orderId = this.id;
          this.form.orderExpressDeliveryId = item.id;
        }
      }
      this.getExpressStep();
    },
    //  获取快递100物流信息
    getExpressStep(){
      console.log(this.form.orderExpressDeliveryId)
      if(!this.form.orderExpressDeliveryId) return
      expressStep({
      orderId: this.id,
      orderExpressDeliveryId: this.form.orderExpressDeliveryId
      }).then((res) => {
        if (res.code == 0 && res.data) {
          this.expressData = res.data
        } else {
          this.expressData = []
          // this.$message.error('暂无数据')
        }
      })
    },
    // 查询订单物流信息
    queryOrderExpress() {
      queryOrderExpressDeliveryListByOrderId({
        orderId: this.id
      }).then((res) => {
        this.orderLogisticsList = res.data;
        this.orderLogistics = res.data[0].expressDeliveryNumber;
        // console.log(this.orderLogistics, 'this.orderLogistics')
        // console.log(res, 'resresresres') 
        this.choseLogistics();
      }).catch((err) => { })
    },
    // addKuaidi () {
    //   this.isBtnLoading = true
    //   let data = {
    //     id: this.id,
    //     kuaidiId: this.form.company,
    //     number: this.form.nums
    //   }
    //   if (!this.form.companyName) {
    //     this.$message.error('请填写快递公司～')
    //     this.isBtnLoading = false
    //     return false
    //   }
    //   if (!this.form.nums) {
    //     this.$message.error('请填写快递单号～')
    //     this.isBtnLoading = false
    //     return false
    //   }
    //   saleKuaidi(data)
    //     .then((response) => {
    //       let res = response.data
    //       if (res.code == 0) {
    //         this.isBtnLoading = false
    //         this.$message.success('快递信息提交成功～')
    //         this.getData()
    //       }
    //       else {
    //         this.isBtnLoading = false
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch(() => {
    //       this.$message.error('快递信息提交失败～')
    //       this.isBtnLoading = false
    //     })
    // },

    // 页码变化
    handleCurrentChange (e) {
      this.pageObj.pageData.current = e
    },
    // 条数变化
    handleSizeChange (e) {
      this.pageObj.pageData.size = e
      this.pageObj.pageData.current = 1
    },
    statusBtn (row) {
      return correspondingVal(
        'basePurchaseOrderEnum',
        row,
        'status',
        this.selectList
      )
    },
    qrCodeCreate () {
      payPurchase(this.id)
        .then((res) => {
          if (res.code == 0) {
            this.isQrCode = true
            this.qrlink = res.data.qrCode
            this.orderId = res.data.orderId
            this.total = res.data.amount
            this.$nextTick(() => {
              this.newqrcode()
              let setTime = setInterval(() => {
                if (this.isQrCode) {
                  if (this.status == '2') {
                    clearInterval(setTime)
                    this.isQrCode = false
                    this.$message.success('订单支付成功')
                  } else if (this.status == '3') {
                    clearInterval(setTime)
                  } else {
                    this.payResult(res.data.id)
                  }
                } else {
                  clearInterval(setTime)
                }
              }, 2000)
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {
          this.isQrCode = false
          // this.$message.error('请求错误，请联系管理员')
        })
    },
    qrCodeBtn () {
      this.status = '3'
      this.isQrCode = false
    },
    newqrcode () {
      if (this.qrcode) {
        this.qrcode.clear()
      }
      this.qrcode = new QRCode('qrcode', {
        width: 200, // 设置宽度，单位像素
        height: 200, // 设置高度，单位像素
        text: this.qrlink // 设置二维码内容或跳转地址
      })
    },
    payResult (id) {
      payResult(id).then((res) => {
        this.status = res.data.status
      })
    },
    getData () {
      getPurchaseDetail(this.id)
        .then((res) => {
          this.list = res.data.orderDetailVo
          this.statusStyle(res.data.status)
          this.orderList = res.data
        })
        .catch(() => {
          this.isLoading = false
        })
      // expressStep({ orderId: this.id }).then((res) => {
      //   if (res.code == 0 && res.data) {
      //     this.expressData = res.data
      //   } else {
      //     // this.$message.error('暂无数据')
      //   }
      // })
    },
    // 取消订单
    cancelClick (id) {
      this.isLoading = true
      this.$confirm('订单取消后，订单消失，需要您再次下单', '取消订单', {
        confirmButtonText: '确认',
        cancelButtonText: '返回',
        type: 'warning'
      })
        .then(() => {
          cancelPurchase(id)
            .then((res) => {
              if (res.code == 0) {
                this.isLoading = false
                this.$message.success('订单取消成功')
                this.getData()
              } else {
                this.$message.error(res.msg)
                this.isLoading = false
              }
            })
            .catch(() => {
              this.isLoading = false
            })
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    statusStyle (status) {
      status = Number(status)
      // 1.待付款,2.待发货, 3.已发货,4.待收货，5.交易取消, 6申请退款 7.已退款  8.已完成 
      if (status == 1 ) {
        this.indexStep = 1
      } else if (status == 2 || status == 11) {
        this.indexStep = 2
      } else if (status == 3) {
        this.indexStep = 3
      } else if (status == 8 || status == 4) {
        this.indexStep = 4
      } else if ( status == 9 || status == 10) {
        this.indexStep = 5
      } else {
        this.indexStep = 0
      }
    }
  },
  mounted () {
    const { id, orderId } = this.$route.query
    this.id = id
    this.orderId = orderId
    this.getData()
    this.queryOrderExpress();
    // expressStep({ orderId: this.id }).then((res) => {
    //   if (res.code == 0 && res.data) {
    //     this.expressData = res.data
    //   } else {
    //     // this.$message.error('暂无数据')
    //   }
    // })
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/main";
.classification {
  background: #eee;
  padding: 4px;
  .commodity {
    ::v-deep .el-table {
      .el-table__header {
        thead {
          th {
            background-color: #fff !important;
          }
        }
      }
    }
  }
  .imgList_li_close {
    vertical-align: top;
    position: relative;
    right: 15px;
    color: red;
    :hover {
      cursor: pointer;
    }
  }
}
</style>
