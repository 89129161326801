<template>
  <div>
    <section class="app-main">
      <transition name="fade-transform"
                  mode="out-in">
        <keep-alive :include="includeList">
          <router-view :key="key" />
        </keep-alive>
      </transition>
    </section>
    <div class="bottom">Copyright © 湖南春播云药库科技有限公司版权所有 客服热线：4000819577</div>
  </div>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key () {
      return this.$route.path
    }
  },
  data() {
    return {
      includeList: ['auditcompany','release','salesControl_publish_list']
    }
  }
}
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  padding: 20px;
  background: #eee;
  height: calc(100vh - 120px);
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  padding-bottom: 0px;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

.bottom {
  height: 40px;
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  color: #8c8c8c;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
