var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("div", { staticClass: "staus_bar fl_bet border" }, [
            _c("div", { staticClass: "detail_left" }, [
              _c("div", { staticClass: "fl_cen" }, [
                _c(
                  "div",
                  { staticClass: "status" },
                  [
                    _c("el-image", {
                      staticClass: "icon_status",
                      attrs: { src: _vm.getStatus(1, _vm.orderList.status) },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "name",
                        class: _vm.getStatus(3, _vm.orderList.status),
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getStatus(2, _vm.orderList.status)) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "items" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("订单编号")]),
                  _c("div", { staticStyle: { height: "22px" } }, [
                    _vm._v(_vm._s(_vm.orderList.orderCode || "")),
                  ]),
                ]),
                _c("div", { staticClass: "items" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("下单时间")]),
                  _c("div", { staticStyle: { height: "22px" } }, [
                    _vm._v(_vm._s(_vm.orderList.orderTime || "")),
                  ]),
                ]),
                _vm.orderList.status == 1 && _vm.orderList.payTime
                  ? _c("div", { staticClass: "items" }, [
                      _c("div", { staticClass: "title" }, [_vm._v("付款时间")]),
                      _c(
                        "div",
                        { staticStyle: { height: "22px", color: "#e4393c" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.orderList.payTime || " ") + " "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "items" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("售后电话")]),
                  _c(
                    "div",
                    { staticStyle: { height: "22px", color: "#e4393c" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.orderList.servicePhone || " ") + " "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "detail_right" },
              [
                Number(_vm.orderList.status) == 3
                  ? _c(
                      "el-button",
                      { attrs: { type: "danger emphasis_btn" } },
                      [_vm._v("确认收货")]
                    )
                  : _vm._e(),
                Number(_vm.orderList.status) == 1
                  ? _c(
                      "el-button",
                      {
                        staticClass: "cancel_btn",
                        attrs: { loading: _vm.isLoading },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.cancelClick(_vm.id)
                          },
                        },
                      },
                      [_vm._v("取消订单")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("Step", {
            attrs: { stepArray: _vm.stepArray, indexStep: 2, lineWord: true },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info card" },
        [
          _c(
            "el-descriptions",
            { attrs: { title: "售后单据信息" } },
            [
              _c("el-descriptions-item", { attrs: { label: "申请类型" } }, [
                _vm._v("退货"),
              ]),
              _c("el-descriptions-item", { attrs: { label: "申请金额" } }, [
                _vm._v("18100000000"),
              ]),
              _c("el-descriptions-item", { attrs: { label: "申请原因" } }, [
                _vm._v("苏州市"),
              ]),
              _c("el-descriptions-item", { attrs: { label: "申请用户" } }, [
                _vm._v("张三 "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "申请时间" } }, [
                _vm._v("2023-03-20"),
              ]),
              _c("el-descriptions-item", { attrs: { label: "问题描述" } }, [
                _vm._v("江苏省苏州市吴中区吴中大道"),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "售后凭证" } },
                [
                  _c("el-image", {
                    staticStyle: {
                      width: "150px",
                      height: "150px",
                      padding: "10px",
                    },
                    attrs: { src: _vm.avater },
                  }),
                  _c("el-image", {
                    staticStyle: {
                      width: "150px",
                      height: "150px",
                      padding: "10px",
                    },
                    attrs: { src: _vm.avater },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "info card fl_cen" }, [
        _c("div", { staticClass: "info_list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("商户收货信息")]),
          _c("div", { staticClass: "lists" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("收货人：")]),
              _vm._v(_vm._s(_vm.orderList.contactsName || "--") + " "),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("地址：")]),
              _vm._v(
                _vm._s(_vm.orderList.province || "") +
                  _vm._s(_vm.orderList.city || "") +
                  _vm._s(_vm.orderList.district || "") +
                  _vm._s(_vm.orderList.address || "") +
                  " "
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("手机号：")]),
              _vm._v(_vm._s(_vm.orderList.contactsPhone || "--") + " "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "info_list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("配送信息")]),
          _c("div", { staticClass: "lists" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("快递公司：")]),
              _vm._v(_vm._s(_vm.orderList.kuaidiName || "--") + " "),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("快递单号：")]),
              _vm._v(_vm._s(_vm.orderList.number || "--") + " "),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("预计送达：")]),
              _vm._v(_vm._s(_vm.orderList.finishDate || "--") + " "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "info_list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("付款信息")]),
          _c("div", { staticClass: "lists" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("商品总额：")]),
              _vm._v(_vm._s(_vm.orderList.amount || "--") + " "),
            ]),
            _vm._m(0),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("付款时间：")]),
              _vm._v(_vm._s(_vm.orderList.payTime || "") + " "),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "info card" },
        [
          !_vm.expressData.child || _vm.expressData.child.length == 0
            ? _c(
                "div",
                { staticStyle: { color: "#f56c6c", "font-weight": "600" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.expressData.step || "暂无物流数据") + " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.expressData.child && _vm.expressData.child.length > 0
            ? _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { name: "1" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "line-height": "20px",
                              height: "80px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#f56c6c",
                                  "font-weight": "600",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.expressData.step || "暂无物流数据"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.expressData.description || "") +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { "padding-top": "10px" } },
                        [
                          _vm.expressData.child &&
                          _vm.expressData.child.length > 0
                            ? _c(
                                "el-timeline",
                                _vm._l(
                                  _vm.expressData.child,
                                  function (activity, index) {
                                    return _c(
                                      "el-timeline-item",
                                      {
                                        key: index,
                                        attrs: { color: "#F5222D" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-weight": "600",
                                            },
                                          },
                                          [_vm._v(_vm._s(activity.step || ""))]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(activity.description || "")
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "commodity card" },
        [
          _c("publicTable", {
            attrs: {
              "table-data": _vm.list,
              "colum-obj": _vm.columObj,
              "page-obj": _vm.pageObj,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info card", staticStyle: { height: "300px" } },
        [
          _c(
            "el-steps",
            { attrs: { direction: "vertical", active: 1 } },
            [
              _c("el-step", {
                attrs: {
                  title: "对话1",
                  description:
                    "这是一段很长很长很长的描述性文字这是一段很长很长很长的描述性文字这是一段很长很长很长的描述性文字",
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "对话2",
                  description:
                    "这是一段很长很长很长的描述性文字这是一段很长很长很长的描述性文字这是一段很长很长很长的描述性文字",
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "对话3",
                  description:
                    "这是一段很长很长很长的描述性文字这是一段很长很长很长的描述性文字这是一段很长很长很长的描述性文字",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "codeCont",
          attrs: {
            visible: _vm.isQrCode,
            width: "800px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.qrCodeBtn,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isQrCode = $event
            },
          },
        },
        [
          _c("div", { staticClass: "qrcodeCon" }, [
            _c("p", { staticClass: "qrTip" }, [
              _vm._v("请尽快付款！订单号：" + _vm._s(_vm.orderList.orderCode)),
            ]),
            _c("p", { staticClass: "qrdesc" }, [
              _vm._v("请您在5分钟内完成支付，否则订单会被自动取消！"),
            ]),
            _c("div", { staticClass: "qrCont" }, [
              _c("p", [
                _vm._v(" 应付金额： "),
                _c("span", [_vm._v("¥" + _vm._s(_vm.total))]),
              ]),
              _c("div", {
                ref: "qrcode",
                staticClass: "qrcodeImg",
                attrs: { id: "qrcode" },
              }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("span", [_vm._v("支付方式：")]),
      _vm._v("在线支付"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }