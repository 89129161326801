<template>
  <div>
    <el-form ref="formTool" :model="formTool" label-width="100px">
     <div class="title-form">
      <span>
       企业名称: {{companyItem.sellerName}}
      </span>
      <span>
       企业税号: {{companyItem.sellerTaxpayerId}}
      </span>
     </div>
      <div class="header" style="margin: 10px 0">
        <el-form-item label="订单创建时间" prop="date">
          <el-date-picker
            v-model="formTool.date"
            value-format="yyyy-MM-dd"
            type="daterange"
            size="small"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item label="订单编号">
          <el-input
            v-model.trim="formTool.orderCode"
            placeholder="请输入"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item label="客户名称">
          <el-input
            v-model.trim="formTool.clientName"
            placeholder="请输入"
            clearable
            size="small"
            style="width: 240px"
          />
        </el-form-item>

        <el-form-item label-width="0px">
          <el-button size="small" @click="reset()" plain>重置</el-button>
          <el-button size="small" type="primary" @click="getList(1)"
            >查询</el-button
          >
          <el-button size="small" type="primary" @click="goAddTax()"
            >开票</el-button
          > 
        </el-form-item>
      </div>
      <MyTable
        :showpage="true"
        :rowKeyProps="'id'"
        :table-data="tableData"
        :index="index"
        :selection="selection"
        :page="page"
        :operation="operation"
        :table-option.sync="tableOption"
        @page-change="getList"
        @handleSelectionChange="handleSelectionChange"
      >
        <template slot="orderAmount" slot-scope="scope">
          {{ scope.row.orderAmount || "0.00" }}
        </template>
      </MyTable>
    </el-form>
  </div>
</template>
<script>
import MyTable from "@/components/myTable";

// import { updateRateRule } from "@/api/externalSalesRule";
import {
  queryClientAutomaticBillingVO,
  clientAutomaticBillingExcel,
  offlineBilling,
  getInvoicePdf,
  doInvoicing,
} from "@/api/invoice";
export default {
  components: { MyTable },
  props: {},
  computed: {
    visible() {
      return this.dialogShow;
    },
  },
  data() {
    return {
      stepInsecond: false,
      tableData: [],
      formTool: {},
      invoiceOptions: [],
      dialogShow: false,
      tableOption: [ 
        { label: "订单创建时间", prop: "orderTime" }, // 这里表示自定义列 , slot: true
        { label: "客户名称", prop: "clientName" }, // 这里表示自定义列 , slot: true
        { label: "订单金额", prop: "orderAmount", width: "120px", slot: true }, // 这里表示自定义列 , slot: true
        { label: "合计金额", prop: "amountTotal", width: "120px" }, // 这里表示自定义列 , slot: true
        { label: "税率/征收率", prop: "taxRate", width: "100px" }, // 这里表示自定义列 , slot: true
        { label: "税额", prop: "taxAmount", width: "100px" }, // 这里表示自定义列 , slot: true
        { label: "价税合计", prop: "isum", width: "120px" }, // 这里表示自定义列 , slot: true
        { label: "订单编号", prop: "orderCode", width: "200px" }, // 这里表示自定义列 , slot: true
        // { label: '操作', prop: 'menu' }
      ],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      index: true,
      operation: false,
      selection: true,
      companyItem: {}, // 选择的企业信息
      selectTotal: 0, // 选择的总金额
    };
  },
  created() {},
  methods: {
    rowKey(row) {
      //row-key就是要指定一个key标识这一行的数据,保证唯一即可
      return row.id;
    },
    // 获取列表
    getList(current) {
      let params = {};
       if(current){ 
        params.current = current;
        this.page.current = current;
      }else{
        params.current = this.page.current;
      }
      params.size = this.page.size; 
      params.tag = "section";
      params = Object.assign(params, this.formTool);
      params.titleId = this.companyItem.id; // 开票主体id

      if (params.date && params.date.length > 0) {
        params.startTime = params.date[0];
        params.endTime = params.date[1];
        delete params.date;
      }
      queryClientAutomaticBillingVO(params).then((res) => {
        if (res) {
          this.tableData = res.records;
          this.page.total = res.total;
        }
      });
    },

    // 勾选
    handleSelectionChange(val) {
      // console.log(val, "---val");
      this.selectList = val;
      this.countTotalMoney(val);
    },

    selectAll(selection) {
      this.selectionList = selection;
    },
    handleClose() {
      this.dialogShow = false;
    },
    reset() {
      this.formTool = {};
      this.getList();
    },
    /**
     * 发票开具
     * @param {*} e
     */
    async goAddTax() {
      if (!this.selectList.length) {
        this.$message.warning("请选择开票数据");
        return;
      }
      let invocieList = [];
      this.selectList.forEach((e) => {
        let obj = {
          itemIdSet: [e.id],
          totalAmount: e.payeeAmount,
          saleNum: this.companyItem.sellerTaxpayerId,
          companyName: this.companyItem.sellerName,
          customerSaleNum: e.customerSaleNum,
          customerName: e.customerName,
          customerAddress: e.customerAddress,
          customerTel: e.customerTel,
          customerBank: e.customerBank,
          customerAccount: e.customerAccount,
          invoiceType: this.companyItem.invoiceType,
          payeeShopNo:this.companyItem.payeeNo,
          remark: "", 
          titleId: this.companyItem.id, // 开票主体id
        };
        invocieList.push(obj);
      });
    
      console.log(invocieList, "---invocieList");  
      await doInvoicing(invocieList)
        .then((resp) => {
          if (resp) {
            this.$message.success("开票成功");
            this.$emit("submit", "two");
          } else {
            this.$message.error("开票失败，重新登陆"); 
            // 清空指定的session
            sessionStorage.removeItem("sellerTaxpayerId");
            // 开票失败，重新登陆
          }
        })
        .catch((err) => {
          if (err && err.msg) {
            this.$message.error(err.msg);
            // 开票失败，重新登陆
             sessionStorage.removeItem('sellerTaxpayerId')
          }
        });
    },
    countTotalMoney(arr) {
      let money = 0;
      // let tax = 0
      arr.forEach((v) => {
        if (v.payeeAmount) {
          money += parseFloat(v.payeeAmount);
        }
        // if (v.taxAmount) {
        //     tax += parseFloat(v.taxAmount)
        // }
      });
      this.selectTotal = money.toFixed(2);
      // this.selectTax = tax.toFixed(2)
    },

    init(row, type, item) {
      this.dialogShow = true;
      this.companyItem = item;
      console.log(item, "item");
      // console.log(row, "row");
      // console.log(type, "type");
      this.getList();
    },

    Invoicing() {
      this.$emit("Invoicing");
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";

.btnBox {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.classification {
  padding: 0px;

  .header {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0px 24px 0px 24px !important;
    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .top {
    display: flex;
    padding: 10px 0 10px 0;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .label {
        width: 120px;
      }

      .button {
        margin-right: 20px;

        ::v-deep .el-button {
          color: $subMenuActiveText;
          background-color: $butColor;
          border-color: $butColor;

          &:hover {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:active {
            color: $subMenuActiveText;
            background: $butColor;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:focus {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
        }
      }
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn-warp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.btn-list {
  margin: 0 5px;
}

.title-form {
  margin:10px auto;
  span {
    margin-right: 20px;
  }
}
</style>
