<!--  -->
<template>
  <div class="comContent">
    <!-- <div class="headerTop">
      <el-form
        ref="formTool"
        :model="formTool"
        :inline="true"
        style="text-align: left"
      >
        <el-form-item>
          <MyButton
            type="primary"
            size="small"
            icon="el-icon-plus"
            text="添加"
            @click="roleEdit(false, 'add')"
          />
        </el-form-item>
      </el-form>
    </div> -->

    <div class="center">
      <MyTable
        :table-data="tableData"
        :index="index"
        :selection="selection"
        :page="page"
        :showPagination="false"
        :operation="operation"
        :table-option.sync="tableOption"
        @page-change="getList"
        @handleSelectionChange="currentChange"
      >
        <template slot="caozuo" slot-scope="scope">
          <el-button type="text" @click="detail(scope.row)">详情</el-button>
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>
import MyButton from "@/components/myButton";
import MyTable from "@/components/myTable";
import { mapGetters } from "vuex";
import { fetchList } from "@/api/systemRole";
import { auditlog, shopHistoryDetails } from "@/api/auditcenter";

export default {
  components: { MyButton, MyTable }, // , MenuForm, MyTabs, MyTable, MyTypes
  data() {
    return {
      // 遮罩层
      loading: true,
      menuOptions: [],
      tableHight: 0,

      text: "",
      clearable: true,
      formTool: {
        shopName: "",
        concatPhone: "",
      },
      operation: false,
      selection: false,
      index: true,
      tableData: [],
      tableOption: [
        { label: "操作人", prop: "updateBy" },
        { label: "操作内容", prop: "auditOperat" },
        { label: "审核原因", prop: "remarks" },
        { label: "操作时间", prop: "updateTime" },
        { label: "操作", prop: "caozuo", slot: true, width: "400px" },
      ],
      page: { total: 10, current: 1, size: 10 },
      imgType: "",
      checkedKeys: [],
      auditId: "",
      checkedDsScope: [],
      defaultProps: {
        label: "name",
        value: "id",
      },
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
  },
  watch: {},
  created() {
    console.log("id", this.$route.query);
    this.auditId = this.$route.query.id;
    this.getList();
  },
  mounted() {},
  methods: {
    detail(row) {
      // 日志详情
      shopHistoryDetails(row.logId).then((res) => {
        if (res.code === 0) {
          if (res.data && res.data.id) {
            this.$router.push({
              path: "/audit/auditcenter-auditcompanydetail",
              query: {
                logId: row.logId,
              },
            });
          } else {
            this.$message("还没有日志数据")
          }
        }
      });
    },
    roleEditSure() {
      this.page.current = 1;
      this.getList();
    },
    getList() {
      this.listLoading = true;
      auditlog(this.auditId)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
            this.page.total = res.data.length;
          } else {
            this.$message.error("日志查询出错");
          }
        })
        .finally((res) => {
          this.listLoading = false;
        });
    },
    cancal() {
      this.dialogPermissionVisible = false;
    },

    reset() {
      this.page.current = 1;
      this.getList();
    },
    currentChange(val) {
      console.log("current", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.comContent {
  padding: 0px;
  .headerTop {
    height: 56px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    .el-form-item {
      margin-bottom: 0px !important;
    }
  }
  .center {
    margin: 0 24px;
    margin-top: 16px;
    height: calc(100% - 141px);
  }
}
::v-deep.myDialog {
  .el-dialog__body {
    height: 550px;
    overflow: auto;
  }
}
</style>
