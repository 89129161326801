<!--  -->
<template>
  <div class="classification">
    <div class="header">
      <span class="font">控销地域设置</span>
    </div>
    <div class="content">
      <div class="top">
        <el-form ref="formTool"
                 :model="formTool"
                 :inline="true"
                 style="text-align:left;">
          <el-form-item prop="name"
                        style="margin-bottom: 24px !important;"
                        label="控销组名称"
                        :rules="[
      { required: true, message: '请输入控销组名称' }
    ]">
            <el-input v-model="formTool.name"
                      placeholder="请输入控销组名称"
                      clearable
                      size="small" />
          </el-form-item>
        </el-form>
      </div>
      <div class="center">
        <el-tree :data="regionTreedata"
                 ref="tree"
                 show-checkbox
                 node-key="id"
                 :default-checked-keys="defaultChecked"
                 :props="defaultProps">
        </el-tree>
      </div>
      <div class="bottom">
        <el-button size="small"
                   type="primary"
                   @click="submit()">保存并提交</el-button>
        <el-button size="small"
                   style="margin-left:12px"
                   @click="cancel()">返回</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import {
  regionTree,
} from "@/api/businessPlatform/relationshipMaintenance";
import { insertOrUpdate, salesskuarearelevanceQuery } from '@/api/salesControl/commodity'

export default {
  name: 'WareProduct',
  components: {},
  data () {
    return {
      id: '',
      formTool: {
        name: ''
      },
      defaultChecked: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      regionTreedata: [],

    }
  },
  computed: {},
  watch: {},
  created () {
    this.id = this.$route.query?.id || ''
    this.getTree()
  },
  mounted () {
    if (this.id) {
      this.query(this.id)
    }
  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    getTree () {
      regionTree().then((res) => {
        if (res.code == 0) {
          this.regionTreedata = res.data;
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    submit () {
      this.$refs.formTool.validate(valid => {
        if (valid) {
          let arr = this.$refs.tree.getCheckedKeys()
          if (arr.length == 0) {
            this.$message.warning('请先选择地域')
            return
          }
          let params = {
            areaId: this.id,
            schemeName: this.formTool.name,
            areaList: arr,
          }
          insertOrUpdate(params).then(res => {
            if (res) {
              this.$message.success('提交成功')
              this.cancel()
            }
          })
        }
      })
    },
    query (id) {
      salesskuarearelevanceQuery(id).then(res => {
        this.formTool.name = res.schemeName
        this.$refs.tree.setCheckedKeys(res.saleCodeList)
      })

    },
    cancel () {
      this.$refs.tree.setCheckedKeys([])
      this.$router.back(-1)
      // this.$router.push({
      //   path: '/productManage/salesControl-publish-list',
      //   query: {
      //     tab: 'two'
      //   }
      // })
    }
  }
}
</script>
<style lang='scss' scoped>
.classification {
  padding: 0px;
  .top {
    border-bottom: 1px solid #f5f5f5;
  }
  .top-center {
    border-bottom: 1px solid #f5f5f5;
  }
  .content {
    padding: 24px;
    .center {
      padding: 24px 0;
    }
  }
}
.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px !important;
  border-bottom: 1px solid #f5f5f5;
  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }
  .el-form-item {
    margin: 0px 0px 0px 8px !important;
  }
}
</style>
