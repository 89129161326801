<!-- 选择单位弹窗 -->
<template>
  <div class="dialog">
    <el-dialog
      title="补款通知"
      :visible.sync="visible"
      :lock-scroll="true"
      top="50px"
      width="62%"
      :before-close="handleClose"
    >
      <div class="dialogBox">
        <div class="header">
          <el-form
            ref="formData"
            :model="formData"
            :inline="true"
            label-width="120px"
          >
            <el-row>
              <el-col :span="12">需求方：{{ unitItem.buyerName }}</el-col>
              <el-col :span="12"
                >服务方：{{ unitItem.sellerFeedGoodsName }}</el-col
              >
            </el-row>
            <!-- <div class="left_topic mb20" style="margin-top: 10px">服务信息</div>  -->
            <el-divider />
            <el-row>
              <el-col :span="12">
                <el-row>
                  <div class="goods-img">
                    <el-image
                      :src="unitItem.drugImage ? unitItem.drugImage : avater"
                      lazy
                      style=" 
                        width: 100px;
                        height: 100px;
                        border: solid 1px #dcdfe6;
                      "
                    />
                  </div>
                </el-row>
                <el-row>
                  <el-form-item label-width="140" label="药品信息：">
                    {{ unitItem.goodsName }}</el-form-item
                  >
                </el-row>
                <el-row>
                  <el-form-item label-width="140" label="药品规格：">
                    {{ unitItem.specs }}</el-form-item
                  >
                </el-row>
                <el-row>
                  <el-form-item label-width="140" label="厂商：">
                    {{ unitItem.factory }}</el-form-item
                  >
                </el-row>
                <el-row>
                  <el-form-item label-width="140" label="所需数量：">
                    {{ unitItem.askNumber }}</el-form-item
                  >
                </el-row>
                <el-row>
                  <el-form-item label-width="140" label="期望价格：">
                    ≤ ￥ {{ unitItem.askPrice }}</el-form-item
                  >
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <div class="goods-img">
                    <el-image
                      :src="unitItem.drugImage ? unitItem.drugImage : avater"
                      lazy
                      style="
                        width: 100px;
                        height: 100px;
                        border: solid 1px #dcdfe6;
                      "
                    />
                  </div>
                </el-row>
                <el-row>
                  <el-form-item label-width="140" label="药品信息：">
                    {{ unitItem.goodsName }}</el-form-item
                  >
                </el-row>
                <el-row>
                  <el-form-item label-width="140" label="药品规格：">
                    {{ unitItem.specs }}
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label-width="140" label="厂商：">
                    {{ unitItem.factory }}</el-form-item
                  >
                </el-row>
                <el-row>
                  <el-form-item label-width="140" label="供给数量：">
                    {{ unitItem.sellerFeedNumber }}</el-form-item
                  >
                </el-row>
                <el-row>
                  <el-form-item label-width="140" label="供应商报价：">
                    ￥{{ unitItem.sellerFeedPrice }}
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label-width="140" label="需支付定金：">
                   ￥ {{ unitItem.payDeposit }}
                  </el-form-item>
                </el-row>
              </el-col>
            </el-row>
            <el-divider />

            <div class="bt-rt">
              <el-button size="small" @click="submitCheck()" type="primary"
                >补款通知</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSeekDrug,
  acceptSeekDrug,
  informSeekDrug,
} from "@/api/saleRule/medicineFind";

import avater from "/public/img/default.png";
export default {
  props: {
    unitList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    unitItem: {
      type: Object,
      default: function () {
        return {
          sellerFeedPrice: "",
          sellerDrugSpecs: "",
          sellerFeedNumber: "",
          payDeposit: "",
        };
      },
    },
    informVisible: {
      type: Boolean,
      default: false,
    },
    showpage: true,
  },
  components: {},
  data() {
    return {
      widthNum: 0,
      heightNum: 0,
      avater: "",
      formData: {},
      item: {},
      goodsOneClassIdOptions: [],
      goodsTwoClassIdOptions: [],
      goodsThreeClassIdOptions: [],
      page: {
        current: 1,
        size: 10,
        total: 10,
      },
      formTool: {
        goodsOneClassId: "",
        goodsTwoClassId: "",
        goodsThreeClassId: "",
        name: "", // 药品名称
        factory: "", // 厂家关键字
        approvalNumber: "", // 批准号
      },
      productChooseItem: {},
      tableData: [],
      checkIndex: 0,
      // informVisible: false,
    };
  },
  computed: {
    visible: {
      get() {
        return this.informVisible;
      },
      set(val) {
        this.$emit("update:informVisible", val);
      },
    },
  },
  watch: {
    "sidebar.opened": {
      handler(newVal, oldVal) {
        this.widthNum = Math.floor(
          (this.$refs.tableCont.clientWidth - 48) / 222
        );
      },
    },
  },
  methods: {
    comfirm() {},
    cancel() {
      this.$parent.informVisible = false;
    },
    handleClose() {
      this.$parent.informVisible = false;
    },
    // 确认
    submitCheck() {
      this.$emit("confirmEmit", this.unitItem);
      this.$parent.informVisible = false;
    },
  },
  created() {
    // this.page.size = this.widthNum * this.heightNum;
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
.dialogBox {
  span {
    font-size: 14px;
    color: #595959;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 5px 15px;
  }
  .everyOneBox {
    border: 1px solid #dcdfe6;
  }
  .activeBox {
    border: 2px solid #F5222D;
  }
}
.contentCont {
  // height: calc(100% - 210px);
  // padding: 0px 24px 0 16px;
  // overflow: auto;
  // display: flex;
  // justify-content: center;
  .contentContinner {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.itemWarpList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.footer-bar {
  display: flex;
  justify-content: space-between;
}

.goods-img {
  // padding: 10px;
  // border:solid 1px #dcdfe6;
}
.bt-rt {
  display: flex;
  justify-content: flex-end;
}
</style>
