<template>
  <div class="child-task-detail-root">
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="958px"
      @closed="onClosed"
      v-loading="loading"
      class="child-task-dialog"
    >
      <div class="full-content">
        <div class="content form-content">
          <div class="task-base-info">
            <div class="base-info-item">
              <div class="tip">编号</div>
              <div class="value">{{ childtask ? childtask.code : "" }}</div>
            </div>
            <div class="base-info-item">
              <div class="tip">流程状态</div>
              <div class="value">
                {{ childtask ? status(childtask.taskStatus) : "" }}
              </div>
            </div>
            <div class="base-info-item">
              <div class="tip">提交人</div>
              <div class="value">{{ childtask ? childtask.createBy : "" }}</div>
            </div>
            <div class="base-info-item">
              <div class="tip">提交时间</div>
              <div class="value">
                {{ childtask ? childtask.subTime : "" }}
              </div>
            </div>
          </div>
          <div class="block">
            <div class="title">任务基本信息</div>
            <div class="flex-layout">
              <div class="flex-item">
                <div class="tip">任务名称<span class="weight">*</span></div>
                <div class="value">
                  <el-input
                    size="small"
                    disabled
                    :value="childtask ? childtask.taskName : ''"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">任务编码<span class="weight">*</span></div>
                <div class="value">
                  <el-input
                    size="small"
                    disabled
                    :value="childtask ? childtask.taskCode : ''"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">指标名称<span class="weight">*</span></div>
                <div class="value">
                  <el-input
                    size="small"
                    disabled
                    :value="childtask ? childtask.targetName : ''"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">CSO公司名称<span class="weight">*</span></div>
                <div class="value">
                  <el-input
                    size="small"
                    disabled
                    :value="childtask ? childtask.coopShopName : ''"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">执行次数<span class="weight">*</span></div>
                <div class="value">
                  <el-input
                    size="small"
                    disabled
                    :value="childtask ? childtask.taskNum : ''"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">单次执行金额<span class="weight">*</span></div>
                <div class="value">
                  <el-input
                    size="small"
                    disabled
                    :value="childtask ? childtask.taskPrice : ''"
                  >
                    <template slot="prefix"
                      ><span class="unit-icon">￥</span></template
                    >
                  </el-input>
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">任务类型<span class="weight">*</span></div>
                <div class="value">
                  <el-input
                    size="small"
                    disabled
                    :value="childtask ? taskType(childtask.taskType) : ''"
                  />
                </div>
              </div>

              <div class="flex-item" v-if="0">
                <div class="tip">截至日期<span class="weight">*</span></div>
                <div class="value">
                  <el-input
                    size="small"
                    disabled
                    :value="childtask ? childtask.targetCode : ''"
                  />
                </div>
              </div>
              <div class="flex-item long">
                <div class="tip">任务说明</div>
                <div class="value">
                  {{ childtask ? childtask.remark : "" }}
                  <!-- <el-input
                    size="small"
                    disabled
                    :value="childtask ? childtask.remark : ''"
                  /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="title">任务详情</div>
            <div class="pre-child-tast">
              <childtemplate
                :preview="true"
                :childTaskType="childtask ? childtask.taskType : -1"
                :childTaskDetail="childtask ? childtask.taskDetail : null"
              />
            </div>
          </div>
          <!-- 驳回记录 -->
          <div class="block rejected-layout" v-if="reasonList.length > 0">
            <div class="title">驳回记录</div>
            <div class="v-list">
              <div
                class="v-list-item"
                v-for="(item, index) in reasonList"
                :key="index"
              >
                <div class="left">
                  <div class="line up"></div>
                  <div class="dot"></div>
                  <div class="line down"></div>
                </div>
                <div class="datas-view">
                  <div class="reason">{{ item.refuseReason }}</div>
                  <div class="time">驳回人：{{ item.refuseUser }}</div>
                  <div class="time">驳回时间：{{ item.rejectionTime }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">关闭</el-button>
        <el-button
          v-if="showAuditOpinion"
          size="small"
          type="primary"
          @click="handleCheckOk"
          >通过</el-button
        >
        <el-button
          v-if="showAuditOpinion"
          size="small"
          type="primary"
          @click="handleCheckFail"
          >驳回</el-button
        >
      </div>
    </el-dialog>

    <rejustReason ref="refRejustReason" @onConfirm="handleAuditFail" />
  </div>
</template>

<script>
import IConst_Tool_Icon from "@/assets/compliance/tool.png";
import rejustReason from "@/views/compliancecso/metricsManager/dialog/rejustReason.vue";
import { childTaskHelp, taskHelp, IConst_clientTag } from "@/utils/viewHelp";
import {
  contractDoTaskDetail,
  contractDoTaskAudit,
  cosContractDoTaskAudit,
} from "@/api/compliance/childtask";
import childtemplate from "@/views/compliance/task/static/childtemplate.vue";
import dayjs from "dayjs";

export default {
  components: { rejustReason, childtemplate },
  data() {
    return {
      action: 3, //1 新增  2 编辑 3 查看 4 审核 (这里只有 3 和 4)
      dialogVisible: false,
      loading: false,

      id: "",
      clientTag: "", //医药公司名称：还是cso
      toolIcon: IConst_Tool_Icon,
      childtask: null, //子任务对象
    };
  },
  computed: {
    title() {
      let txt = "子任务";
      if (this.childtask) {
        txt = this.childtask.taskTypeText + "-" + txt;
      }
      return txt;
    },
    isLook() {
      return this.action === 3;
    },
    showAuditOpinion() {
      let childTaskStatus = Object.keys(childTaskHelp.taskStatusList);
      let show = !this.isLook;
      if (show && this.childtask) {
        if (this.clientTag === IConst_clientTag.yygs) {
          show = this.childtask.taskStatus == childTaskStatus[2];
        } else if (this.clientTag === IConst_clientTag.cso) {
          show = this.childtask.taskStatus == childTaskStatus[1];
        }
      }
      return show;
    },
    reasonList() {
      if (
        this.childtask &&
        Array.isArray(this.childtask.contractDoTaskRefuseList)
      ) {
        return this.childtask.contractDoTaskRefuseList;
      }
      return [];
    },
  },
  methods: {
    open(options = null) {
      if (options) {
        this.action = options.action ? options.action : 3;
        this.id = options.id;
        this.clientTag = options.clientTag;
        this.getDetail();
      }

      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    onClosed() {
      this.reset();
    },
    reset() {
      this.action = 1;
      this.id = "";
      this.clientTag = "";
      this.childtask = null;
      this.loading = false;
    },
    status(taskStatus) {
      return childTaskHelp.status(taskStatus);
    },
    taskType(taskType) {
      const findedItem = taskHelp.taskTypeList.find(
        (item) => item.value == taskType
      );
      return findedItem ? findedItem.label : "";
    },
    getDetail() {
      if (this.id) {
        this.loading = true;
        contractDoTaskDetail(this.id)
          .then((data) => {
            this.loading = false;
            if (data) {
              if (data.taskDetail && typeof data.taskDetail === "string") {
                data.taskDetail = JSON.parse(data.taskDetail);
                data = this.handleSpecialFiled(data);
              }
              this.childtask = data;
            }
          })
          .catch((ex) => {
            this.loading = false;
          });
      }
    },
    //处理某些特殊字段
    handleSpecialFiled(childtask) {
      if (childtask && childtask.taskDetail) {
        let taskDetail = childtask.taskDetail;
        switch (childtask.taskType) {
          case 1:
            {
              //客户拜访
              if (taskDetail.accessTime) {
                taskDetail.accessTime = dayjs(taskDetail.accessTime).format(
                  "YYYY-MM-DD"
                );
              }
            }
            break;
          case 4:
            {
              //终端开发
              if (taskDetail.purchaseTime) {
                taskDetail.purchaseTime = dayjs(taskDetail.purchaseTime).format(
                  "YYYY-MM-DD"
                );
              }
            }
            break;
          case 2:
            {
              //学术培训
              if (taskDetail.trainTime) {
                taskDetail.trainTime = dayjs(taskDetail.trainTime).format(
                  "YYYY-MM-DD"
                );
              }
            }
            break;
        }
      }
      return childtask;
    },
    handleCheckOk() {
      this.$confirm("通过后将不可修改，是否确定?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.doAudit(1);
        })
        .catch(() => {
          //
        });
    },
    handleCheckFail() {
      this.$refs.refRejustReason.open();
    },
    handleAuditFail(params) {
      this.doAudit(2, params.reason);
    },
    doAudit(type, reason = "") {
      let childTaskStatus = Object.keys(childTaskHelp.taskStatusList);
      let status = "";
      let fun = null;
      status = type === 1 ? "PASS" : "NOT_PASS";
      if (this.clientTag === IConst_clientTag.yygs) {
        fun = contractDoTaskAudit;
      } else if (this.clientTag === IConst_clientTag.cso) {
        fun = cosContractDoTaskAudit;
      }
      this.loading = true;
      const data = {
        idList: [this.id],
        ids: [this.id],
        status: status,
        rejectContent: type === 2 ? reason : "",
      };
      fun &&
        fun(data)
          .then((res) => {
            if (res) {
              this.$refs.refRejustReason.close();
              this.$emit("onRefresh");
              this.dialogVisible = false;
            }
            this.loading = false;
          })
          .catch((ex) => {
            this.loading = false;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.child-task-detail-root {
  .full-content {
    display: flex;
    height: 100%;

    .unit-icon {
      display: inline-flex;
      height: 100%;
      align-items: center;
    }

    .right-parts {
      height: 100%;
      overflow-y: auto;
      width: 280px;
      display: flex;
      flex-direction: column;
      background: #f9fafc;
      padding: 0 12px;
      box-sizing: border-box;

      .title {
        font-size: 15px;
        font-weight: bold;
        color: #262626;
        padding: 12px 12px;
        // border-bottom: 1px solid #f0f0f0;
      }
      .v-list {
        display: flex;
        flex-direction: column;

        .v-list-item {
          display: flex;

          .left {
            width: 14px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .line {
              width: 2px;
              background-color: #f0f0f0;
              &.up {
                height: 10px;
              }
              &.down {
                flex: 1;
              }
            }
            .dot {
              width: 8px;
              height: 8px;
              border-radius: 999px;
              background-color: #c0c0c0;
            }
          }

          .datas-view {
            flex: 1;
            padding: 6px 0;
            box-sizing: border-box;
            margin-left: 12px;
            display: flex;
            flex-direction: column;

            .time {
              font-size: 13px;
              font-weight: normal;
              color: #999;
            }

            .detail-info {
              border-radius: 4px;
              background: #fff;
              padding: 0 12px;
              box-sizing: border-box;
              border: 1px solid #f0f0f0;
              margin-top: 6px;

              .tip {
                font-size: 14px;
                color: #262626;
                padding: 8px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #f0f0f0;
              }

              .more-info {
                display: flex;
                justify-content: space-between;
                padding: 8px 0;
                box-sizing: border-box;

                .multi-info {
                  border-radius: 999px;
                  padding: 2px 8px;
                  box-sizing: border-box;
                  font-size: 12px;
                  color: #262626;
                  display: flex;
                  align-items: center;
                  background: #f0f0f0;

                  .small-icon {
                    width: 12px;
                    height: 12px;
                    margin-right: 4px;
                  }
                }

                .num {
                  border-radius: 999px;
                  padding: 2px 8px 2px 4px;
                  box-sizing: border-box;
                  font-size: 12px;
                  color: #262626;
                  display: flex;
                  align-items: center;
                  background: #f0f0f0;

                  .small-icon {
                    width: 18px;
                    height: 18px;
                    margin-right: 2px;
                  }
                }
              }
            }
          }

          &:first-child {
            .left {
              .line.up {
                visibility: hidden;
              }
            }
          }
          &:last-child {
            .left {
              .line.down {
                visibility: hidden;
              }
            }
          }
        }
      }
    }
    .form-content {
      flex: 1;
      overflow: auto;

      .task-base-info {
        margin-left: -16px;
        margin-right: -16px;
        margin-bottom: 16px;

        display: flex;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        .base-info-item {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 12px 16px;
          box-sizing: border-box;

          &:not(:last-child) {
            border-right: 1px solid #f0f0f0;
          }

          .tip {
            font-size: 13px;
            color: #999;
          }
          .value {
            font-size: 14px;
            color: #262626;
            margin-left: 6px;
          }
        }
      }

      .block {
        .title {
          padding: 12px 12px 12px 8px;
          font-size: 15px;
          font-weight: bold;
          color: #262626;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 4px;
            height: 20px;
            background: #1890ff;
            transform: translateY(-50%);
            border-radius: 2px;
          }
        }

        .remark {
          display: flex;
          justify-content: center;
          padding: 8px 0;
          box-sizing: border-box;
          background: #ffece1;
          font-size: 14px;
          color: #ffa37d;
        }

        .flex-layout {
          display: flex;
          flex-wrap: wrap;

          .flex-item {
            width: calc(25% - 8px);
            display: flex;
            flex-direction: column;
            margin-right: 8px;
            margin-bottom: 16px;

            &.harf-long {
              width: calc(50% - 8px);
            }

            &.long {
              width: 100%;
            }

            .tip {
              font-size: 14px;
              color: #262626;

              .weight {
                color: #f00;
                position: relative;
                top: 4px;
                margin-left: 2px;
              }
            }

            .value {
              margin-top: 4px;
              ::v-deep {
                .el-input {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .condition-layout {
        display: flex;
        align-items: center;
        .right {
          flex: 1;
          display: flex;
          justify-content: flex-end;
        }
      }

      .table-lalyout {
        margin-top: 8px;

        .table-op-col {
          .el-link {
            & + .el-link {
              margin-left: 8px;
            }
          }
        }
      }
    }

    .split-block {
      background: #f0f0f0;

      &.v {
        width: 6px;
      }
      &.h {
        width: auto;
        height: 6px;
      }
    }
  }

  .form-content {
    padding: 0 24px;
    box-sizing: border-box;
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 0;
      box-sizing: border-box;

      &.mutlti {
        align-items: flex-start;
      }

      &.contine {
        padding-top: 0;
      }

      &.big {
        .tip {
          height: 32px;
          line-height: 32px;
        }
      }

      .tip {
        width: 5em;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        text-align: right;
        color: #262626;
      }

      .value {
        margin-left: 16px;

        &.input {
          width: 204px;

          ::v-deep .el-date-editor.el-input,
          ::v-deep .el-date-editor.el-input__inner {
            width: 100%;
          }

          ::v-deep .el-select {
            width: 100%;
          }
        }

        &.fill {
          flex: 1;
          width: 0;
        }

        .small-tip {
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #8c8c8c;

          &.warning {
            color: #e6a23c;
          }
        }

        &.mutlti {
          flex: 1;
          width: 0;
          display: flex;

          &.v {
            flex-direction: column;

            .in-row {
              &:not(:first-child) {
                margin-top: 8px;
              }
            }
          }
        }

        .flag-block {
          width: 100%;
          background: #fafafa;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 8px 16px 16px 16px;
          box-sizing: border-box;
          flex-wrap: wrap;

          .flag-title {
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            margin-right: 8px;
          }

          & > * {
            margin-top: 8px;
          }
        }
      }

      .in-row {
        .value {
          margin-left: 0;
        }
      }
    }

    .opinion {
      color: #f00;
    }

    .goods-info {
      display: inline-flex;
      align-items: center;
      margin-right: 24px;

      .txt-info {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        .name {
          font-size: 16px;
          font-weight: 600;
          color: var(--color-26);
        }
        .factory {
          color: var(--color-59);
          font-size: 14px;
          margin-top: 3px;
        }
      }
    }
  }

  .rejected-layout {
    margin-top: 24px;
    .title {
      font-size: 15px;
      font-weight: 600;
      color: #262626;
      padding: 12px 0;
      box-sizing: border-box;
    }

    .v-list {
      display: flex;
      flex-direction: column;

      .v-list-item {
        display: flex;

        .left {
          width: 14px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .line {
            width: 2px;
            flex: 1;
            background-color: #f0f0f0;
          }
          .dot {
            width: 8px;
            height: 8px;
            border-radius: 999px;
            background-color: #c0c0c0;
          }
        }

        .datas-view {
          padding: 6px 0;
          box-sizing: border-box;
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          .reason {
            font-size: 15px;
            font-weight: normal;
            color: #262626;
          }
          .time {
            margin-top: 4px;
            font-size: 13px;
            font-weight: normal;
            color: #999;
          }
        }

        &:first-child {
          .left {
            .line.up {
              visibility: hidden;
            }
          }
        }
        &:last-child {
          .left {
            .line.down {
              visibility: hidden;
            }
          }
        }
      }
    }
  }

  ::v-deep .el-dialog__wrapper.child-task-dialog {
    display: flex;
    align-items: center;

    .el-dialog {
      margin-top: 0 !important;
      margin: 0 auto 0;
    }

    .el-dialog__body {
      max-height: 75vh;
      height: 75vh;
      overflow-y: auto;
      // min-height: 240px;
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
    }
  }
}
</style>
