var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "标签管理", visible: _vm.visible, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "100px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标签名称", prop: "labelName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "260px" },
                    attrs: { size: "small", maxlength: "10" },
                    model: {
                      value: _vm.form.labelName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "labelName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.labelName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签图片", prop: "labelImg" } },
                [
                  !_vm.form.labelImg
                    ? _c(
                        "div",
                        {
                          staticClass: "upload-image",
                          on: {
                            click: function ($event) {
                              return _vm.handleUpload()
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "image-wrap" },
                            [_c("el-image", { attrs: { src: _vm.AddIcon } })],
                            1
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "table-cell-pic-wrap" },
                        [
                          _c("el-image", {
                            ref: "labelImgRef",
                            staticClass: "table-cell-pic",
                            attrs: {
                              src: _vm.form.labelImg,
                              "initial-index": 0,
                              "preview-src-list": [_vm.form.labelImg],
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "preview" },
                            [
                              _c("el-image", {
                                staticClass: "btn-icon",
                                attrs: { src: _vm.SeeIcon },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePreview(`labelImgRef`)
                                  },
                                },
                              }),
                              _c("el-image", {
                                staticClass: "btn-icon",
                                attrs: { src: _vm.DelIcon },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDeleteImage()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("UploadImageView", {
        ref: "imgupload",
        attrs: { "request-id": "1", imgType: _vm.imgType },
        on: { upload: _vm.uploadSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }