<template>
  <div class="home">
    <div class="left">
      <leftPage></leftPage>
    </div>
    <div class="right">
      <div style="height: 50%;padding: 12px;border-bottom: 1px solid #ededed;">
        <div style="width:100%;display:flex">
          <Tabs style="width:calc(100% - 70px)" :tabsList="tabsList" class="title" :activeName.sync="tabsActiveName"
            @tab-click="handleChange" />
          <div style="width:70px;display: flex;justify-content: center;align-items: center;cursor: pointer;"
            @click="clickMore">更多</div>
        </div>
        <div class="rightBottom">
          <div class="weidu" v-if="total != 0">
            <span class="article">{{ total }}篇新文章未读</span>
            <span @click="articleAllRead" class="yidu article">{{ total == 0 ? '全部已读' : '一键全读' }}</span>
          </div>
          <div class="totalBox" ref="totalBox">
            <div :class="activeIndex == index ? 'activeBox everyBox' : 'everyBox'" v-for="item, index in tableData"
              :key="index" @click="cellClick(item, index)">
              <div class="readTag" v-if="item.readTag == 1"></div>
              <div class="unreadTag" v-else></div>
              <span class="title">{{ item.articleTitle }}</span>
              <img v-if="item.pinnedStatus == '1'" src="@/assets/zhiding.png" style="margin-left: 6px;" width="12px" />
            </div>
          </div>
          <!-- <el-pagination style="justify-content: flex-end;display: flex;"
                       @size-change="changePage"
                       @current-change="changePage"
                       :page-size.sync="page.size"
                       :current-page.sync="page.current"
                       :pager-count="5"
                       layout="prev, pager, next"
                       :total="page.total">
        </el-pagination> -->
        </div>
      </div>
      <!-- 营销活动 -->
      <div style="height: 50%;padding: 12px;" v-if="activeList && activeList.length > 0">
        <div
          style="border-bottom: 1px solid #ededed;padding-bottom: 12px;display: flex;justify-content: space-between;align-items: center;">
          <span style="font-size: 16px;font-weight: 600;">营销活动</span>
          <el-button size="small" type="primary" @click="allActive">全部活动</el-button>
        </div>
        <div style="height:calc(100% - 44px);overflow: auto;">
          <div class="everyActiveBox" v-for="(item, index) in activeList" :key="index">
            <div style="display: inline-block;" class="imageBox">
              <img :src=item.thumbnailUrl alt="" style="width: 40px;height: 40px;">
            </div>
            <div style="display: inline-block;" class="rightBox">
              <div style="display: flex;justify-content: space-between;align-items: center;padding: 2px 0;">
                <div class="title">
                  {{ item.name }}
                </div>
                <div style="width: 80px;">
                  <el-button size="mini" v-if="item.joinIdentification == 0" type="primary"
                    @click="active(item, 'join')">立即参加</el-button>
                  <el-button size="mini" v-if="item.joinIdentification == 1" type="primary"
                    @click="active(item, 'look')">查看</el-button>
                </div>
              </div>
              <div style="display: flex;justify-content: space-between;align-items: center;padding: 2px 0;">
                <div class="smallTitle">
                  {{ item.startTime ? item.startTime.substr(0, 10) : '' }} - {{ item.endTime ? item.endTime.substr(0, 10)
                    : '' }}
                </div>
                <div style="font-size: 14px;color: #F5222D;">
                  活动截止：{{ item.finishDuration }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="文章内容" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <div v-html="details" class="endText content-html"></div>
      <!-- <wangEnduit ref="wangEnduit"
                  :disabledHead="true"
                  :value="details"
                  :isDisabled="true" /> -->
    </el-dialog>
  </div>
</template>
  
<script>
import { getList } from '@/api/platformActivity/index'
import leftPage from './leftPage.vue'
import Tabs from "@/components/Tabs";
import wangEnduit from "@/components/wangEnduit";
import { queryHomePageInformationArticleList, queryInformationArticleById, articleAllRead, queryParentUnreadMsg } from '@/api/message/InformationClassification'
export default {
  props: {},
  data () {
    return {
      height: 75,
      tabsList: [
        // tabs 传的值
        {
          name: "0",
          label: "资讯文章",
          value: 0,
        },
      ],
      tabsActiveName: "0",
      total: 0,
      page: {
        total: 0,
        current: 1,
        size: 20
      },
      tableData: [],
      activeIndex: '',

      dialogVisible: false,
      details: '',
      activeList: [

      ]
    }
  },
  components: { Tabs, wangEnduit, leftPage },
  // async created () {
  //   await this.queryParentUnreadMsg()
  //   await this.queryHomePageInformationArticleList()
  // },
  async mounted () {
    await this.getList()
    await this.queryParentUnreadMsg()
    await this.queryHomePageInformationArticleList()
  },
  watch: {},
  methods: {
    // 获取营销活动列表
    async getList () {
      const res = await getList()
      if (res) {
        this.activeList = res
      }
    },
    // 所有营销活动
    allActive () {
      this.$router.push('/operationCenter/platformActivity-list')
    },
    // 单个营销活动
    active (item, status) {
      // console.log(item)
      this.$router.push({
        path: '/operationCenter/platformActivity-listDetail',
        query: {
          id: item.id,
          status: status
        }
      })
    },
    // 点击更多
    clickMore () {
      // this.tabsActiveName
      let name = ''
      this.tabsList.forEach(item => {
        if (item.name == this.tabsActiveName) {
          name = item.label
        }
      })
      this.$router.push({
        path: '/lookArticle/lookArticle-index',
        query: {
          tabsActiveName: this.tabsActiveName,
          name: name
        }
      })
    },
    // 切换页码
    changePage () {
      this.queryHomePageInformationArticleList()
    },
    // handleClose
    handleClose () {
      this.dialogVisible = false
    },
    // 点击行
    async cellClick (item, index) {
      this.activeIndex = index
      this.details = ''
      await this.queryInformationArticleById(item.id)
      this.dialogVisible = true
    },
    // 根据id查询资讯文章
    async queryInformationArticleById (id) {
      queryInformationArticleById(id).then(res => {
        if (res) {
          this.details = res.informationContent
          this.queryParentUnreadMsg()
          this.queryHomePageInformationArticleList()
        } else {
          this.details = ''
        }
      })
    },
    //切换tabs按钮
    handleChange () {
      this.queryHomePageInformationArticleList()
    },
    // 查询树形结构
    async queryParentUnreadMsg () {
      const res = await queryParentUnreadMsg()
      this.tabsList = [
        {
          name: "0",
          label: "资讯文章",
          value: 0,
        },
      ]
      this.total = 0
      if (res) {
        if (res.length > 0) {
          res.forEach(el => {
            this.tabsList.push({
              name: el.id,
              label: el.classifyName,
              value: el.unreadCount > 99 ? '99+' : el.unreadCount,
            })
            this.total += (el.unreadCount ? el.unreadCount : 0)
          });
          // this.tabsList[0].value = this.total
          // console.log(this.total)
          // this.tabsActiveName = this.tabsList[0].name
          if (this.total == 0) {
            this.$refs.totalBox.style.height = 'calc(100% - 10px)'
          } else {
            this.$refs.totalBox.style.height = 'calc(100% - 43px)'
          }
        }
      }
    },
    // 根据分类id查询资讯文章列表
    async queryHomePageInformationArticleList () {
      let params = {
        // current: this.page.current,
        // size: this.page.size,
        articleClassificationId: this.tabsActiveName,
        clientTag: '商户端',
        articleTitle: '',
      }
      const res = await queryHomePageInformationArticleList(params)
      if (res) {
        this.tableData = res
        // this.page.total = res.total
      } else {
        this.tableData = []
        // this.page.total = 0
      }
    },
    // 文章一键全部已读
    async articleAllRead () {
      if (this.total == 0) {
        return
      }
      const res = articleAllRead()
      if (res) {
        this.$message.success('操作成功')
        setTimeout(() => {
          this.queryParentUnreadMsg()
          this.queryHomePageInformationArticleList()
        }, 200)

      }
    }

  }
}

</script>
<style lang="scss" scoped>
.home {
  border-radius: 4px !important;
  background-color: #ffffff;
  height: calc(100vh - 140px);
  overflow-y: auto;
  display: flex;

  .left {
    width: calc(100% - 370px);
    padding: 16px;
    height: 100%;
    overflow: auto;
  }

  .right {
    width: 370px;
    // padding: 12px;
    border-left: 1px solid #ededed;
    height: 100%;

    .rightBottom {
      margin-top: 10px;
      height: calc(100% - 67px);
      overflow: auto;

      .totalBox {
        margin-top: 10px;
        height: calc(100% - 75px);
        overflow: auto;

        .everyBox {
          overflow: auto;
          display: flex;
          align-items: center;
          padding: 10px 5px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          border-bottom: 1px solid #ededed;

          .readTag {
            height: 5px;
            width: 5px;
            border-radius: 5px;
            background: black;
          }

          .unreadTag {
            height: 5px;
            width: 5px;
            border-radius: 5px;
            background: #f5222d;
          }

          .title {
            margin-left: 5px;
            display: inline-block;
            max-width: calc(100% - 22px);
            overflow: hidden;
            /* 第二步：让文本不会换行， 在同一行继续 */
            white-space: nowrap;
            /* 第三步：用省略号来代表未显示完的文本 */
            text-overflow: ellipsis;
          }
        }

        .activeBox {
          background: rgb(255, 242, 241);
        }
      }

      .weidu {
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;
        background: rgba(245, 90, 34, 0.08);
        border-radius: 4px;

        .article {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: left;
          color: #f55a22;
        }

        .yidu {
          cursor: pointer;
        }
      }
    }

    .everyActiveBox {
      display: flex;
      align-items: center;
      padding: 5px 0;
      border-bottom: 1px solid #ededed;

      .imageBox {
        width: 40px;
      }

      .rightBox {
        margin-left: 8px;
        width: calc(100% - 48px);

        .title {
          font-size: 16px;
          max-width: calc(100% - 80px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .smallTitle {
          color: rgb(191, 191, 191);
          max-width: calc(100% - 80px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 14px;
        }
      }
    }
  }

  ::v-deep .el-dialog__body {
    height: 600px;
    overflow: auto;
  }

  .endText {
    font-size: 14px;
  }

  .content-html {

    /* table 样式 */
    :deep table {
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
    }

    :deep table td,
    table th {
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      padding: 3px 5px;
    }

    :deep table th {
      border-bottom: 2px solid #ccc;
      text-align: center;
    }
  }

  ::v-deep .el-tabs__nav-wrap {
    display: flex;
    align-items: center;
  }
}
</style>
