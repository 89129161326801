<template>
  <div class="shop-info-root tab-content new-cls">
    <div class="form">
      <div class="block">
        <div class="block-title">
          <span class="text">售后收货信息</span>
        </div>
        <div class="block-content">
          <div class="row">
            <div class="tip"><span class="weight">*</span>收货人</div>
            <div class="value input big-w">
              <el-input
                size="small"
                placeholder="请输入"
                v-model="formIn.consignee"
                maxlength="20"
              />
            </div>
          </div>
          <div class="row">
            <div class="tip"><span class="weight">*</span>电话</div>
            <div class="value input big-w">
              <el-input
                size="small"
                placeholder="请输入"
                v-model="formIn.consigneePhone"
                maxlength="15"
              />
            </div>
          </div>
          <div class="row">
            <div class="tip"><span class="weight">*</span>收货地址</div>
            <div class="value input big-w">
              <el-input
                size="small"
                placeholder="请输入"
                v-model="formIn.consigneeAddress"
                maxlength="50"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="block-title">
          <span class="text">退换货流程</span>
        </div>
        <div class="block-content">
          <div class="row multi">
            <div class="tip"><span class="weight">*</span>文字说明</div>
            <div class="value input big-w">
              <el-input
                type="textarea"
                maxlength="2000"
                show-word-limit
                resize="none"
                :rows="6"
                placeholder="请输入"
                v-model="formIn.changingRefundingInstructions"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn">
      <el-button type="primary" class="fix-four" @click="doUpdateInfo"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import { queryAfterSalesSettings, updateAfterSalesSettings } from "@/api/shop";

export default {
  components: {},
  props:{
    allAreaCode:{
      type:Array,
      defult:[]
    }
  },
  data() {
    return {
      formIn: {
        shopId: "",
        consignee: "", //收货人
        consigneePhone: "", //收货人电话
        consigneeAddress: "", //收货地址
        changingRefundingInstructions: "", //退换货说明
      },
    };
  },
  created() {
    this.doLoadInfo();
  },
  mounted(){
    //console.log(this.allAreaCode,'---allAreaCode') 
  },
  methods: {
    doLoadInfo() {
      queryAfterSalesSettings().then((data) => {
        if (data) {
          for (let key in data) {
            if (this.formIn.hasOwnProperty(key) && data[key]) {
              this.formIn[key] = data[key];
            }
          }
        }
      });
    },
    doUpdateInfo() {
      if (!this.formIn.consignee) {
        this.$message.warning("请输入收货人");
        return;
      }
      if (!this.formIn.consigneePhone) {
        this.$message.warning("请输入收货人电话");
        return;
      }
      if (!this.formIn.consigneeAddress) {
        this.$message.warning("请输入收货地址");
        return;
      }
      if (!this.formIn.changingRefundingInstructions) {
        this.$message.warning("请输入退换货说明");
        return;
      }

      let data = {};
      _.merge(data, this.formIn);
      data.areaList = this.allAreaCode;
      updateAfterSalesSettings(data).then((res) => {
        if (res) {
          this.$message.success("保存成功");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
