import request, { exportFile } from "@/utils/request";

// 创建指标任务
export function contractTargetTaskAdd(data) {
  return request({
    url: "/shop/hg/contractTargetTask/add",
    method: "POST",
    data: data,
  });
}

// 编辑指标任务
export function contractTargetTaskEdit(data) {
  return request({
    url: "/shop/hg/contractTargetTask/edit",
    method: "POST",
    data: data,
  });
}

// 删除指标任务
export function contractTargetTaskDelete(id) {
  return request({
    url: `/shop/hg/contractTargetTask/del/${id}`,
    method: "DELETE",
  });
}

// 查看指标任务详情
export function contractTargetTaskDetail(id) {
  return request({
    url: `/shop/hg/contractTargetTask/detail/${id}`,
    method: "GET",
  });
}

// 查询指标任务列表
export function contractTargetTaskPage(data) {
  return request({
    url: "/shop/hg/contractTargetTask/page",
    method: "POST",
    data: data,
  });
}

//导出任务列表
export function contractTargetTaskExport(params) {
  return exportFile("/shop/hg/contractTargetTask/export", params);
}
