<template>
  <div class="comContent">
    <div class="title">
      每个商家最多添加3个商家标签。商家标签图片会盖在商品列表图上一同显示(图层要求
      （比例1：1，420x420px， 50kb以下,图片格式必须上传png）)
    </div>
    <div class="center">
      <el-table :data="tableData" row-key="key">
        <el-table-column label="标签" type="index" width="50">
        </el-table-column>
        <el-table-column label="标签名称">
          <template slot-scope="scope">
            <div class="table-content">
              <!-- <div class="input-layout">
                <el-input
                  size="small"
                  v-model="scope.row.labelName"
                  @blur="handleSave(scope.$index)"
                />
              </div> -->
              {{ scope.row.labelName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="图层">
          <template slot-scope="scope">
            <div class="table-content">
              <template>
                <!-- @click="handleUpload(scope.$index)" -->
                <div
                  v-if="!scope.row.image"
                  class="upload-image"
                >
                  <div class="image-wrap">
                    <el-image :src="AddIcon" />
                  </div>
                </div>
                <div v-else class="table-cell-pic-wrap">
                  <el-image
                    :ref="`ref${scope.$index}`"
                    :src="scope.row.image"
                    class="table-cell-pic"
                    :initial-index="0"
                    :preview-src-list="[scope.row.image]"
                  />
                  <div class="preview">
                    <el-image
                      :src="SeeIcon"
                      class="btn-icon"
                      @click="handlePreview(`ref${scope.$index}`)"
                    />
                    <!-- <el-image
                      :src="DelIcon"
                      class="btn-icon"
                      @click="handleDeleteImage(scope.$index)"
                    /> -->
                  </div>
                </div>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="编辑时间" prop="updateTime" />
        <el-table-column label="审核状态" prop="checkStatus">
          <template slot-scope="scope">
            <span>{{ showState(scope.$index) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" />
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <div class="table-content">
              <el-button type="text" size="small" @click="handleEdit(scope.$index)">编辑</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <UploadImageView
      ref="imgupload"
      request-id="1"
      @upload="uploadSubmit"
    />
    <LabelManagerDialog
      ref="labelManagerDialog"
      @refresh ="getLabelInfo"
    />
  </div>
</template>

<script>
import UploadImageView from "@/views/myQualification/components/UploadImage.vue";
import { generateUUId } from "@/views/myQualification/util/ViewHelp";
import LabelManagerDialog from "./components/LabelManagerDialog.vue";
const IConst_See_Icon = require("@/assets/zz/see-icon.png");
const IConst_Del_Icon = require("@/assets/zz/del-icon.png");
const IConst_Add_Icon = require("@/assets/zz/add-icon.png");

import { getLabelInfo, saveOrUpdateLabelInfo } from "@/api/goods";
import _ from "lodash";

export default {
  components: { UploadImageView,LabelManagerDialog },
  data() {
    return {
      tableData: [], //固定三项目

      SeeIcon: IConst_See_Icon,
      DelIcon: IConst_Del_Icon,
      AddIcon: IConst_Add_Icon,
    };
  },
  created() {
    //this.initViews();
    this.getLabelInfo();
  },
  methods: {
    getLabelInfo() {
      this.tableData = []
      getLabelInfo().then((data) => {
        if (data instanceof Array && data.length > 0) {
          this.tableData = data;
          let min = this.tableData.length;
          for (; min < 3; min++) {
            this.tableData.push(this.newRow());
          }
        } else {
          this.initViews();
        }
      });
    },
    handleSave(index) {
      const item = this.tableData[index];
      let data = {};
      _.merge(data, item);
      saveOrUpdateLabelInfo(data).then((res) => {
        if (res) {
          // if (!item.id) {
          this.getLabelInfo();
          // }
          // this.$message.success("更新成功");
        }
      });
    },
    initViews() {
      this.tableData.push(this.newRow());
      this.tableData.push(this.newRow());
      this.tableData.push(this.newRow());
    },
    newRow() {
      return {
        id: "",
        labelName: "",
        image: "",
        shopId: "", //商户id
        remark: "", //备注
        checkStatus: "", //
        updateTime: "",
        key: generateUUId(),
      };
    },
    showState(index) {
      //"审核状态0-驳回 1-待审核 2-审核通过"
      const checkStatusDesp = ["驳回", "待审核", "审核通过"];
      const item = this.tableData[index];
      if (item.checkStatus == '') {
        return ''
      }
      let i = +item.checkStatus;
      if (i >= 0 && i <= 2) {
        return checkStatusDesp[i];
      }
      return "";
    },
    handleUpload(index) {
      //this.currentBigIndex = bigIndex;
      this.$refs["imgupload"].open(index);
    },
    uploadSubmit(obj) {
      const { type, url } = obj;
      const item = this.tableData[type];
      item.image = url;
      item.key = generateUUId();
      this.handleSave(type);
    },
    handlePreview(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].showViewer = true;
      }
    },
    handleDeleteImage(index) {
      const item = this.tableData[index];
      item.image = "";
      item.key = generateUUId();
      this.handleSave(index);
    },
    handleEdit(index) {
      this.$refs["labelManagerDialog"].publicOpenDialog(this.tableData[index]);
    },
  },
};
</script>

<style lang="scss" scoped>
.comContent {
  padding: 0px;
  .title {
    height: 56px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f5f5ff;
    font-size: 14px;
  }
  .center {
    margin: 0 24px;
    margin-top: 16px;
    height: calc(100% - 131px);

    .add-layout {
      height: 60px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f2f2f2;
    }

    .upload-image {
      width: 88px;
      height: 88px;
      border: 1px dashed #c0c0c0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .image-wrap {
        width: 16px;
        height: 16px;
      }
    }

    .table-cell-pic-wrap {
      width: 88px;
      height: 88px;
      position: relative;

      .table-cell-pic {
        width: 100%;
        height: 100%;
      }

      .preview {
        position: absolute;
        visibility: hidden;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background: #000000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .btn-icon {
          width: 16px;
          height: 16px;
          cursor: pointer;
          & + .btn-icon {
            margin-top: 6px;
          }
        }
      }

      &:hover {
        .preview {
          visibility: visible;
        }
      }
    }
  }
}
</style>
