var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c("div", { staticClass: "header" }, [_vm._v("商品审核")]),
    _c(
      "div",
      { staticClass: "center" },
      [
        _c("GoodsInfo", { attrs: { "goods-info": _vm.info, isedit: false } }),
        _c(
          "div",
          { staticClass: "dia_btn" },
          [
            _c("MyButton", {
              staticClass: "btn_w150",
              attrs: { type: "", icon: "", text: "返回上一页" },
              on: { click: _vm.goBack },
            }),
            _c("MyButton", {
              staticClass: "btn_w150",
              attrs: { type: "primary", icon: "", text: "审核通过" },
              on: {
                click: function ($event) {
                  return _vm.openDialog(true)
                },
              },
            }),
            _c("MyButton", {
              staticClass: "btn_w150",
              attrs: { type: "primary", icon: "", text: "驳回" },
              on: {
                click: function ($event) {
                  return _vm.openDialog(false)
                },
              },
            }),
          ],
          1
        ),
        _c("Success", { ref: "sucDom", on: { sure: _vm.sucSubmit } }),
        _c("Err", { ref: "errDom", on: { sure: _vm.errSubmit } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }