
<!--  -->
<template>
  <div class="comContent">
    <div class="header">
      <span class="font">{{ payShopName }}</span>
    </div>
    <div class="header"
         style="border:none;padding-top:10px !important">
      <el-form ref="formTool"
               :model="formTool"
               :inline="true"
               style="text-align:left;">
        <el-form-item prop="payeeStatus"
                      style="margin: 0px !important;">
          <el-select v-model="formTool.payeeStatus"
                     size="small"
                     clearable
                     placeholder="请选择款项状态">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="orderCode">
          <el-input v-model="formTool.orderCode"
                    clearable
                    placeholder="请输入订单号"
                    size="small" />
        </el-form-item>
        <el-form-item prop="time">
          <el-date-picker v-model="formTool.time"
                          type="daterange"
                          value-format="yyyy-MM-dd"
                          clearable
                          size="small"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <MyButton type="primary"
                    text="查询"
                    @click="getcheckList(1)" />
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     @click="reset()">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     type="primary"
                     @click="downloadPayDetailPage()">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="center">
      <el-table :data="tableData"
                :span-method="arraySpanMethod"
                ref="machineTable"
                :header-cell-style="tableCellStyle"
                style="width: 100%;height: 100%;overflow: auto;"
                :expand-row-keys="expands"
                :row-key="getRowKeys">
        <el-table-column type="expand"
                         width="1">
          <template>
            <el-table :data="tableDataOne"
                      :header-cell-style="tableCellStyle"
                      style="width: 100%">
              <el-table-column prop="name"
                               align="center"
                               label="商品名称">
              </el-table-column>
              <el-table-column prop="specification"
                               align="center"
                               label="商品规格">
              </el-table-column>
              <el-table-column prop="goodsSkuUnit"
                               align="center"
                               label="单位">
              </el-table-column>
              <el-table-column prop="price"
                               align="center"
                               label="商品单价">
                <template slot-scope="scope">
                  <span>￥{{ scope.row.price }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="qty"
                               align="center"
                               label="商品件数">
              </el-table-column>
              <el-table-column prop="servicePrice"
                               align="center"
                               label="单件商品应收金额">
                <template slot-scope="scope">
                  <span>￥{{ scope.row.servicePrice }}</span>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="createDate"
                         label="创建时间">
        </el-table-column>
        <el-table-column prop="payShopName"
                         label="采购方名称">
        </el-table-column>
        <el-table-column prop="amount"
                         label="应收总金额">
          <template slot-scope="scope">
            <span>￥{{ scope.row.amount }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="toAmount"
                         label="到账总金额">
          <template slot-scope="scope">
            <span>￥{{ scope.row.toAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payShopName"
                         label="付款方名称"
                         width="100"
                         align="center">
          <template slot-scope="scope">
            <div class="goods-info-list"
                 style="display: flex; align-items: center"
                 v-for="(item, key) in scope.row.academicDTOList"
                 :key="key">
              <div class="unit-list">
                <div class="li">
                  {{ item.payShopName }}
                </div>
                <div class="li">
                  ￥{{ item.amount }}
                </div>
                <div class="li">
                  ￥{{ item.toAmount }}
                </div>
                <div class="li"
                     v-if="item.payShopStatus=='未收款'"
                     style="color:#F5222D">
                  未收款
                </div>
                <div class="li"
                     v-else>
                  {{ item.payShopStatus }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount"
                         label="应付总金额"
                         width="100"
                         align="center">
        </el-table-column>
        <el-table-column prop="toAmount"
                         label="实付总金额"
                         width="100"
                         align="center">
        </el-table-column>
        <el-table-column prop="payShopStatus"
                         label="状态"
                         width="100"
                         align="center">
        </el-table-column>
        <el-table-column label="操作"
                         width="180">
          <template slot-scope="scope">
            <el-link type="primary"
                     @click="expandChange(scope.row)"
                     :underline="false">订单明细</el-link>
            <el-tooltip placement="top">
              <div slot="content">
                订单号：{{ scope.row.orderCode }}<br />康贝支付流水号：{{
                  scope.row.paymentFlow
                }}
              </div>
              <span style="margin-left: 10px; color: #f5222d">流水明细</span>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-empty v-else
                style="height:100%"
                description="暂无数据" /> -->
    </div>
    <div class="el-page">
      <Pagination :total="page.total"
                  :page.sync="page.current"
                  :limit.sync="page.size"
                  :page-sizes="page.pageSizes"
                  @pagination="pageChange" />
    </div>
  </div>
</template>

<script>
import MyButton from '@/components/myButton'
import Pagination from '@/components/Pagination'
import { payDetailPage, downloadPayDetailPage } from "@/api/financial/paymentDetails";
import avater from "/public/img/default.png"
import { accountCheckingPageDetail } from "@/api/accountChecking/index";
export default {
  components: { MyButton, Pagination },
  data () {
    return {
      payShopName: '',
      payShopId: '',
      type: '',
      options: [{
        value: '0',
        label: '未收款'
      }, {
        value: '1',
        label: '已收款'
      }],
      expands: [],
      avater: avater,
      text: '',
      clearable: true,
      formTool: {
        payeeStatus: '',
        orderCode: '',
        time: [],
        approvalNumber: ''
      },
      tableDataOne: [],
      tableData: [
        {
          id: 1,
          date: '111',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          paymentInfoList: [
            { id: 1, name: "abc", money: 12 },
            { id: 0, name: "abc", money: 12 },
          ]
        }, {
          id: 2,
          date: '113',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄',
          paymentInfoList: [
            { id: 1, name: "abc", money: 12 },
            { id: 0, name: "abc", money: 12 },
          ]
        }, {
          id: 3,
          date: '222',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄',
          paymentInfoList: [
            { id: 1, name: "abc", money: 12 },
            { id: 0, name: "abc", money: 12 },
          ]
        }, {
          id: 4,
          date: '333',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄',
          paymentInfoList: [
            { id: 1, name: "abc", money: 12 },
            { id: 0, name: "abc", money: 12 },
          ]
        }
      ],
      page: {
        total: 10,
        current: 1,
        size: 10
      },
      tableCellStyle () {
        return { backgroundColor: '#edf6f6' }
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.payShopId = this.$route.query.payShopId || ''
    this.type = this.$route.query.type || ''
    this.payShopName = this.$route.query.payShopName || ''
    this.getcheckList()
  },
  mounted () {

  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      // 付款方名称后面几列合并
      if (column.label === "付款方名称") {
        return [1, 4];
      }
      // 后面的5列需隐藏的设置【0，0】
      if (
        columnIndex === 5 ||
        columnIndex === 6 ||
        columnIndex === 7 ||
        columnIndex === 8
      ) {
        return [0, 0];
      }
    },
    // 拓展列
    expandChange (row, rows) {
      if (this.expands.indexOf(row.orderId) < 0) {
        // 如果当前没有该扩展列，expands添加该列，扩展
        this.getDeatilList(row)
      } else {
        // 如果当前已经有该扩展列，expands清空，收回
        this.expands = []
      }
    },
    getRowKeys (row) {
      return row.orderId
    },
    getcheckList (current) {
      if (current) {
        this.page.current = current;
      }
      let params = {
        // type: this.type,
        payShopId: this.payShopId,
        current: this.page.current,
        size: this.page.size,
        payeeStatus: this.formTool.payeeStatus,
        orderCode: this.formTool.orderCode,
        startOrderTime: "",
        endOrderTime: "",
        // ...this.formTool
      };
      if (this.formTool.time && this.formTool.time.length > 1) {
        (params.startOrderTime = this.formTool.time[0]),
          (params.endOrderTime = this.formTool.time[1]);
      }
      payDetailPage(params).then((res) => {
        if (res) {
          this.tableData = res.records;
          this.page.total = res.total;
        }
      });
    },
    reset () {
      // this.formTool.storeName = ''
      this.$refs.formTool.resetFields()
      console.log(this.formTool, 'formTool')
      // this.getcheckList()
    },
    editDetail (row) {
      this.$router.push(
        {
          path: '/productManage/auditProduct-edit',
          query: { goodsId: row.goodsId }
        }
      )
    },
    // 展开明细列表
    getDeatilList (row) {
      let params = {
        type: this.type,
        orderId: row.orderId,
      };
      accountCheckingPageDetail(params).then((res) => {
        if (res) {
          this.tableDataOne = res;
          this.$forceUpdate();
          this.expands = [];
          this.expands.push(row.orderId);
        } else {
        }
      });
    },
    // 切换分页
    pageChange () {
      this.getcheckList()
    },
    // 导出
    downloadPayDetailPage () {
      let params = {
        type: this.type,
        payShopId: this.payShopId,
        // current: this.page.current,
        // size: this.page.size,
        payeeStatus: this.formTool.payeeStatus,
        orderCode: this.formTool.orderCode,
        startOrderTime: "",
        endOrderTime: "",
        // ...this.formTool
      };
      if (this.formTool.time && this.formTool.time.length > 1) {
        (params.startOrderTime = this.formTool.time[0]),
          (params.endOrderTime = this.formTool.time[1]);
      }
      downloadPayDetailPage(params).then((res) => {
        if (res) {
          this.$message.success('导出成功')
          // this.tableData = res.records;
          // this.page.total = res.total;
        }
      });
    }
  }
}
</script>
<style lang='scss' scoped>
.comContent {
  padding: 0px;
}
::v-deep .el-table__expand-icon {
  display: none;
}
.header {
  // height: 56px;
  // display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px !important;
  border-bottom: 1px solid #f5f5f5;
  .font {
    display: block;
    height: 56px;
    line-height: 56px;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }
  .el-form-item {
    margin: 0px 0px 0px 8px !important;
  }
}
.center {
  margin-top: 16px;
  padding: 0 24px 0 24px;
  height: calc(100% - 185px);
  ::v-deep .imageP {
    width: calc(100% - 110px);
    p {
      margin: 0 0 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.el-page {
  padding: 10px 24px 0px 24px !important;
}
.goods-info-list {
  border-bottom: solid 1px #ccc;
}
.goods-info-list:nth-last-child(1) {
  border: none;
}

.unit-list {
  display: flex;
  justify-content: center;
  align-items: center;
  .li {
    width: 100px;
  }
}
</style>
