<template>
  <div class="comContent">
    <div>
      <div class="left_topic mb20">基本信息</div>
      <div class="apply">
        <div class="firstBox">
          <span class="font">
            申请单号：
          </span>
          {{ comApplyInvoiceInfo.id }}
        </div>
        <div class="secondBox">
          <span class="font">
            申请时间：
          </span>
          {{ comApplyInvoiceInfo.createTime }}
        </div>
      </div>
      <div class="apply">
        <div class="firstBox">
          <span class="font">
            发票类型：
          </span>
          {{ getInvoiceType (comApplyInvoiceInfo.invoiceType)  }}
        </div>
        <div class="secondBox">
          <span class="font">
            开票时间：
          </span>
          {{ comApplyInvoiceInfo.billingTime }}
        </div>
      </div>
      <div class="apply">
        <div class="firstBox">
          <span class="font">
            开票金额：
          </span>
         ￥ {{ parseFloat(comApplyInvoiceInfo.aggregateAmount).toFixed(2) || '0.00' }}
        </div>
      </div>
    </div>
    <div>
      <div class="left_topic mb20"
           style="margin-top: 10px;">发票抬头信息</div>
      <div class="apply">
        <div class="firstBox">
          <span class="font">
            发票抬头：
          </span>
          {{ comInvoiceTitleInfoEntity.sellerName }}
        </div>
        <div class="secondBox">
          <span class="font">
            开户行：
          </span>
          {{ comInvoiceTitleInfoEntity.sellerBank }}
        </div>
      </div>
      <div class="apply">
        <div class="firstBox">
          <span class="font">
            地址：
          </span>
          {{ comInvoiceTitleInfoEntity.sellerAddress }}
        </div>
        <div class="secondBox">
          <span class="font">
            收件人：
          </span>
          {{ comInvoiceTitleInfoEntity.recipients }}
        </div>
      </div>
      <div class="apply">
        <div class="firstBox">
          <span class="font">
            电话：
          </span>
          {{ comInvoiceTitleInfoEntity.sellerTel }}
        </div>
        <div class="secondBox">
          <span class="font">
            联系电话：
          </span>
          {{ comInvoiceTitleInfoEntity.recipientsTel }}
        </div>
      </div>
      <div class="apply">
        <div class="firstBox">
          <span class="font">
            税号：
          </span>
          {{ comInvoiceTitleInfoEntity.sellerTaxpayerId }}
        </div>
        <div class="secondBox">
          <span class="font">
            收件地址：
          </span>
          {{ comInvoiceTitleInfoEntity.recipientsAddress	 }}
        </div>
      </div>
      <div class="apply">
        <div class="firstBox">
          <span class="font">
            开户行账号：
          </span>
          {{ comInvoiceTitleInfoEntity.sellerAccount }}
        </div>
      </div>
    </div>
    <div class="last">
      <div class="left_topic mb20"
           style="margin-top: 10px;">发票明细</div>
      <div class="center"
           style="position:relative">
        <MyTable :table-data="tableData"
                 :index="index"
                 :selection="selection"
                 :page="page"
                 :operation="operation"
                 :table-option.sync="tableOption"
                 @page-change="comapplyinvoiceinfoDetail">
          <!-- <template slot="a"
                    slot-scope="scope">
            <span>{{ scope.row.a == '11' ? 'kaipiao' : 'weikai'}}</span>
          </template> -->
          <!-- <template slot="c"
                    slot-scope="scope">
            <span>￥{{ scope.row.c }}</span>
          </template> -->
 
          <template slot="orderSum"
                    slot-scope="scope">
            <span>￥{{ parseFloat(scope.row.orderSum).toFixed(2) }}</span>
          </template>
          <template slot="commoditySum"
                    slot-scope="scope">
            <span>￥{{ parseFloat(scope.row.commoditySum).toFixed(2) }}</span>
          </template>
          <template slot="orderFreight"
                    slot-scope="scope">
            <span>￥{{ parseFloat(scope.row.orderFreight).toFixed(2) }}</span>
          </template>
          <template slot="orderCloseSum"
                    slot-scope="scope">
            <span>￥{{ parseFloat(scope.row.orderCloseSum).toFixed(2) }}</span>
          </template>
          <template slot="platformServiceCharge"
                    slot-scope="scope">
            <span>￥{{ parseFloat(scope.row.platformServiceCharge).toFixed(2) }}</span>
          </template>
        </MyTable>
      </div>
      <div class="bottom">
        <el-button @click="handleClose"
                   size="small">取消</el-button>
      </div>
    </div>
  </div>
</template>
  
<script>
import MyTable from '@/components/myTable'
import { comapplyinvoiceinfoDetail } from '@/api/applyBilling/index'
export default {
  props: {},
  components: { MyTable },
  data () {
    return {
      id: '',
      operation: false,
      selection: false,
      index: false,
      comApplyInvoiceInfo: {},
      comInvoiceTitleInfoEntity: {},
      tableData: [
      ],
      tableOption: [
        { label: '订单编号', prop: 'orderCode' }, // 这里表示自定义列
        // { label: '商品名称', prop: 'name' },
        // { label: '生产厂商', prop: 'factory' },
        { label: '订单创建时间', prop: 'creatTime' },
        { label: '订单总金额（元）', prop: 'orderSum' ,slot:true},
        { label: '订单商品金额（元）', prop: 'commoditySum' ,slot:true},
        // { label: '订单运费（元）', prop: 'orderFreight' ,slot:true},
        // { label: '订单应结算金额（元）', prop: 'orderCloseSum' ,slot:true},
        { label: '平台服务费金额（元）', prop: 'platformServiceCharge',slot:true },

      ],
      page: {
        total: 1,
        current: 1,
        size: 20,
        pageSizes: [10, 20, 30, 50]
      }
    }
  },
  created () {
    this.id = this.$route.query.id
    this.comapplyinvoiceinfoDetail()
  },
  mounted () {

  },
  watch: {},
  methods: {
    getInvoiceType (str) {
      let invoiceArr = ['', '普通发票（纸质）', '普通发票（电子）', '增值税专用发票（纸质）', '增值税专用发票（电子）']
      // == '1' ? '普通发票（纸质）' : comApplyInvoiceInfo.invoiceType == '2' ? '普通发票（电子）': comApplyInvoiceInfo.invoiceType == '3' ? '增值税专用发票（纸质）':comApplyInvoiceInfo.invoiceType == '4' ?'增值税专用发票（电子）':''
      if (!str) {
        return ''
      }
      let strArr = str.split(',')
      let tmp = []
      strArr.forEach(typeIndex => {
        tmp.push(invoiceArr[Number(typeIndex)])
      });
      return tmp.join(',')
    },
    handleClose () {
      this.$router.back(-1)
    },
    // 查询详情
    comapplyinvoiceinfoDetail () {
      let params = {
        id: this.id,
        size: this.page.size,
        current: this.page.current,
      }
      comapplyinvoiceinfoDetail(params).then(res => {
        if (res.code == 0) {
          this.comApplyInvoiceInfo = res.data.comApplyInvoiceInfo || {} 
          this.comInvoiceTitleInfoEntity = res.data.comInvoiceTitleInfoEntity || {}
          this.tableData = res.data.page.records || []
          this.page.total = res.data.page.total || 0
        } else {
          this.$message.error('res.msg')
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.comContent {
  padding-top: 24px;
  .apply {
    display: flex;
    .firstBox,
    .secondBox {
      width: 300px;
      font-size: 14px;
      padding: 5px 10px;
      color: #595959;
      .font {
        color: #8c8c8c;
      }
    }
    .secondBox {
      margin-left: 80px;
    }
  }
  .last {
    height: calc(100% - 289px);
    position: relative;
    .center {
      height: calc(100% - 65px);
    }
    .bottom {
      width: 30px;
      position: relative;
      top: 10px;
    }
  }
}
</style>
