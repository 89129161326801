import request from '@/utils/request'
// 控销
// external-sales-rule 获取业务员列表
export function getSalesUser(data) {
    return request({
        url: `/external-sales-rule/salesUser/page`,
        method: 'POST',
        data:data
    })
} 
 
// 根据条件分页查询对应业务员合作列表
export function getSalesCooperation(data) {
    return request({
        url: `/external-sales-rule/salesCooperation/page`,
        method: 'POST',
        data:data
    })
} 

// 建立外部控销用户与医药公司合作关系
export function salesCooperationExamine(data) {
    return request({
        url: `/external-sales-rule/salesCooperation/examine`,
        method: 'PUT',
        data:data
    })
} 

// /sales/area/dept/goods/rule
// 新增推广商品
export function addDeptGoods(data) {
    return request({
        url: `/external-sales-rule/sales/area/dept/goods/rule`,
        method: 'POST',
        data:data
    })
} 

// 重新提交推广商品
export function updateDeptGoods(data) {
    return request({
        url: `/external-sales-rule/sales/area/dept/goods/rule`,
        method: 'PUT',
        data:data
    })
}

// /sales/area/rule/list/{goodsId}/{deptId}
//推广规则列表
export function getGoodsRuleList(data) {
    return request({
        url: `/external-sales-rule/sales/area/rule/list/${data.goodsId}/${data.deptId}`,
        method: 'GET' 
    })
}

// /sales/area/dept/rate/rule
// 修改推广比例
export function updateRateRule(data) {
    return request({
        url: `/external-sales-rule/sales/area/dept/rate/rule`,
        method: 'PUT',
        data:data
    })
}

// /sales/area/dept/task/rule
// 修改规则任务
export function updateTaskRule(data) {
    return request({
        url: `/external-sales-rule/sales/area/dept/task/rule`,
        method: 'PUT',
        data:data
    })
}

// /poster/salesposterinfo/page  分页查询海报

export function getSalesposterinfo(data) {
    return request({
        url: `/external-sales-rule/poster/salesposterinfo/page`,
        method: 'GET',
        params:data 
    })
}

//当前区域可邀请的业务员
export function getSalesUserListPage(data) {
    return request({
        url: `/external-sales-rule/sales/area/getSalesUserListPage`,
        method: 'GET',
        params:data 
    })
}

// 邀请业务员
export function areaSalesInvite(data) {
    return request({
        url: `/external-sales-rule/sales/area/sales/invite/${data.userId}/${data.goodsId}`,
        method: 'POST',
        data:data
    })
} 

//根据商户查询数据权限判断前端按钮展示
export function buttonView() {
  return request({
      url: `/external-sales-rule/sales/auth/button/view`,
      method: 'GET'
  })
}


// /sales/area/rule/list/{goodsId}/{deptId}/{level}/{areaCode} 分级 获取 /优化版本  省市区查询推广规则列表
//推广规则列表
export function getGoodsRuleListLevel(data) {
    return request({
        url: `/external-sales-rule/sales/area/rule/list/${data.goodsId}/${data.deptId}/${data.level}/${data.areaCode}`,
        method: 'GET' 
    })
}