<template>
  <div class="fapiao">
    <el-dialog title="发票相关售后" :visible.sync="dialogVisibleFaPiao" width="848px">
      <div class="mainContent">
        <el-scrollbar>
          <el-form :model="form" label-width="120px">
            <el-form-item label="订单编号">
              <span>{{ orderDetail.orderCode }}</span>
            </el-form-item>
            <el-form-item label="申请原因" class="shenqingyuanyin">
              <el-radio-group v-model="form.applyReason" :disabled="true">
                <el-radio :label="'1'">发票相关-无票</el-radio>
                <el-radio :label="'2'">发票相关-错票</el-radio>
                <el-radio :label="'3'">发票相关-申请专票</el-radio>
              </el-radio-group>
              <div class="tebietixing">
                <div>
                  <div class="title">特别提醒</div>
                  <div class="">请认真核对客户的发票信息</div>
                </div>
                <div>
                  <el-button size="mini" @click="hedui">发票信息</el-button>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="发票有误信息" v-if="form.applyReason == 2" class="xinxi">
              <div class="typeView">
                <div v-for="(item, index) in typesArr" :key="index" @click="didClickType(index)">
                  <div v-if="item.select" class="typeRow" :class="item.select ? 'typeSelect' : ''">
                    <span>{{ item.name }}</span>
                    <img :src="skucheck" alt="" class="checkImage">
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="补充描述和凭证">
              <el-input placeholder="为了商家更好的处理您的售后问题，请填写补充描述和凭证" :disabled="true" v-model="form.remark" type="textarea"
                maxlength="200" show-word-limit />
            </el-form-item>
            <el-form-item label="">
              <el-upload class="upload-demo" :disabled="true" :accept="'.jpg,.jpeg,.pdf,.PDF,.JPG,.PNG,.png'"
                :action="uploadUrl" :header="header" multiple :limit="3"
                :file-list="form.supplementImage ? form.supplementImage : []" :on-preview="preview"
                :on-remove="(file, fileList) => removeUpload(form, file, fileList)"
                :on-success="(response, file, fileList) => successUpload(form, response, file, fileList)">
                <!-- <el-button type="primary" size="mini"
                  v-if="form.supplementImage.length < 3 && (btnStatus == 'submit')">点击上传</el-button> -->
              </el-upload>
            </el-form-item>
            <div class="dialogTitle" v-if="orderConsultHistoryList.length > 0">协商历史</div>
            <div v-if="orderConsultHistoryList.length > 0">
              <div class="lishi" v-for="(item, index) in orderConsultHistoryList" :key="index">
                <div class="xieshang">
                  <div class="title"> {{ item.consultPerson }}</div>
                  <div class="time">{{ item.consultTime }}</div>
                </div>
                <div class="wuliu">
                  {{ item.consultResult }}
                </div>
              </div>
            </div>
            <!-- <el-form-item style="margin-top: 16px;">
              <el-button type="primary" @click="onSubmit">提交申请</el-button>
            </el-form-item> -->
          </el-form>
        </el-scrollbar>

      </div>
      <template #footer>
        <div style="display: flex;align-items: center;">
          <div style="margin-left: auto;"></div>
          <el-button size="mini" @click="dialogVisibleFaPiao = false">返 回</el-button>
          <el-button size="mini" type="primary" @click="agree" v-if="btnStatus == 'reSubmit'">去开票</el-button>
          <el-button size="mini" type="primary" @click="reject" v-if="btnStatus == 'reSubmit'">驳 回</el-button>
          <!-- <el-button size="mini" type="primary" @click="downLoad" v-if="btnStatus == 'pass'">下载发票文件</el-button>
          <el-button size="mini" type="primary" @click="zaicishenqing"
            v-if="btnStatus == 'reject' || btnStatus == 'pass'">再次申请</el-button> -->
        </div>
      </template>
    </el-dialog>
    <!-- 预览 -->
    <el-dialog :visible.sync="pdfDialog" width="848px" title="详情" class="detail">
      <iframe v-if="pdfStr == 'pdf' || pdfStr == 'PDF'" :src="pdfUrl" style="width: 100%; height: 100%;"
        frameborder="0"></iframe>
      <img v-else :src="pdfUrl" frameborder="0" style="width: auto;height: 100%;object-fit: contain;" />
    </el-dialog>
    <!-- 发票详情 -->
    <el-dialog :visible.sync="fapiaoDialog" width="848px" title="发票抬头详情">
      <el-scrollbar>
        <el-form ref="ruleForm" :model="company" :rules="rules" label-width="110px">
          <el-form-item label="开票类型" prop="invoiceType">
            <el-checkbox-group size="small" v-model="company.invoiceType" :disabled="!isEdit">
              <el-checkbox :label="'1'">普通发票（纸质）</el-checkbox>
              <el-checkbox :label="'2'">普通发票（电子）</el-checkbox>
              <el-checkbox :label="'3'">增值税专用发票（纸质）</el-checkbox>
              <el-checkbox :label="'4'">增值税专用发票（电子）</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="发票抬头" prop="sellerName">
            <el-input v-model="company.sellerName" size="small" placeholder="请输入发票抬头" :disabled="!isEdit"
              style="width: 400px" max="100" />
          </el-form-item>
          <el-form-item label="发票税号" prop="sellerTaxpayerId">
            <el-input v-model="company.sellerTaxpayerId" size="small" placeholder="请输入发票税号" :disabled="!isEdit"
              style="width: 400px" />
          </el-form-item>
          <el-form-item label="注册地址" prop="sellerAddress">
            <el-input v-model="company.sellerAddress" size="small" type="textarea" max="200" placeholder="请输入注册地址"
              :disabled="!isEdit" style="width: 400px" />
          </el-form-item>
          <el-form-item label="注册电话" prop="sellerTel">
            <el-input disabled v-model="company.sellerTel" size="small" placeholder="请输入注册电话" :disabled="!isEdit"
              show-word-limit max="100" style="width: 400px" />
          </el-form-item>
          <el-form-item label="开户银行" prop="sellerBank">
            <el-input disabled v-model="company.sellerBank" size="small" placeholder="请输入开户银行" :disabled="!isEdit"
              max="100" style="width: 400px" />
          </el-form-item>
          <el-form-item label="开户账号" prop="sellerAccount">
            <el-input disabled v-model="company.sellerAccount" size="small" placeholder="请输入开户账号" :disabled="!isEdit"
              max="100" style="width: 400px" />
          </el-form-item>
          <el-form-item label="收件人" prop="recipients">
            <el-input disabled v-model="company.recipients" size="small" placeholder="请输入收件人" :disabled="!isEdit"
              max="100" style="width: 400px" />
          </el-form-item>
          <el-form-item label="联系电话" prop="recipientsTel">
            <el-input disabled v-model="company.recipientsTel" size="small" placeholder="请输入联系电话" :disabled="!isEdit"
              max="100" style="width: 400px" />
          </el-form-item>
          <el-form-item label="收件地址" prop="recipientsAddress">
            <el-input disabled v-model="company.recipientsAddress" size="small" placeholder="请输入收件地址" :disabled="!isEdit"
              max="100" style="width: 400px" />
          </el-form-item>
          <el-form-item label="邮箱" prop="recipientsEmil">
            <el-input disabled v-model="company.recipientsEmil" size="small" placeholder="请输入邮箱" :disabled="!isEdit"
              max="100" style="width: 400px" />
          </el-form-item>
          <el-form-item>
            <!-- <el-button :type="isEdit ? '' : 'primary'" icon="" :text="isEdit ? '取消修改' : '开启编辑'" @click="editChange" /> -->
            <!-- <el-button type="primary" icon="" @click="goSubmit(ruleForm)">保存</el-button> -->
            <el-button @click="fapiaoDialog = false" size="mini">返 回</el-button>
          </el-form-item>
        </el-form>
      </el-scrollbar>
    </el-dialog>

    <!-- 驳回 -->
    <el-dialog title="驳回原因" :visible.sync="bohuiDialog" class="reject">
      <el-input v-model="rejectReason" maxlength="100" show-word-limit></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bohuiDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="bohuiSubmit" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 上传发票 -->
    <el-dialog title="上传发票" :visible.sync="tongguoDialog" class="reject">
      <el-upload class="upload-demo" :accept="'.jpg,.jpeg,.pdf,.PDF,.JPG,.PNG,.png'" :action="uploadUrl" :header="header"
        multiple :limit="3" :file-list="invoiceImage ? invoiceImage : []" :on-preview="previewInvoiceImage"
        :on-remove="(file, fileList) => removeInvoiceImage(form, file, fileList)"
        :on-success="(response, file, fileList) => successInvoiceImage(form, response, file, fileList)">
        <el-button type="primary" size="mini" v-if="invoiceImage.length < 3">点击上传</el-button>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tongguoDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="tijiaoSubmit" size="small">提 交</el-button>
      </span>
    </el-dialog>
    <!-- 资质报告发票弹窗 -->
    <QualificationInvoiceDialog ref="qualificationInvoiceDialogRef" class="QualificationInvoiceDialog" />
  </div>
</template>
  
<script>
import QualificationInvoiceDialog from "../dialog/qualificationInvoiceDialog.vue";
import { getToken } from '@/utils/auth';
import defaultImage from '/public/img/default.png'
import skucheck from '@/assets/zz/selected-icon.png'
import dayjs from "dayjs";
import _ from 'lodash'
import useUserStore from '@/store/modules/user';
import { cominvoicetitleinfoDetailShopId, cominvoicetitleinfoSave, saveCon } from '@/api/financialcenter'
import { afterInvoice, getAfterInvoice, invoiceReject, uploadInvoice } from '@/api/myQualification'
import { isvalidatemobile, checkTax } from '@/utils/validate'
export default {
  props: {
    orderDetail: {
      type: Object,
      default: () => undefined
    }
  },
  data () {
    const validatePhone = (rule, value, callback) => {
      if (isvalidatemobile(value)[0]) {
        callback(new Error(isvalidatemobile(value)[1]))
      } else {
        callback()
      }
    }
    const validateNum = (rule, value, callback) => {
      if (checkTax(value)) {
        callback()
      } else {
        callback(new Error('税号错误'))
      }
    }
    return {
      invoiceImage: [],//发票图片数组
      rejectReason: '',
      tongguoDialog: false,
      bohuiDialog: false,
      skucheck: skucheck,
      btnStatus: 'submit',
      ruleForm: null,
      fapiaoDialog: false,
      dialogVisibleFaPiao: false,
      pdfUrl: '',
      pdfStr: '',
      pdfDialog: false,
      header: getToken(),
      uploadUrl: process.env.VUE_APP_BASE_API + `/product/goodsbase/upload`,
      typesArr: [
        {
          name: '发票类型',
          value: '1',
          select: false
        },
        {
          name: '公司名称',
          value: '2',
          select: false
        },
        {
          name: '税号',
          value: '3',
          select: false
        },
        {
          name: '开户银行',
          value: '4',
          select: false
        },
        {
          name: '开户账号',
          value: '5',
          select: false
        },
        {
          name: '公司地址',
          value: '6',
          select: false
        },
        {
          name: '联系电话',
          value: '7',
          select: false
        },
        {
          name: '确认盖章',
          value: '8',
          select: false
        },
        {
          name: '其他',
          value: '9',
          select: false
        }
      ],
      form: {
        remark: '',
        orderId: '',
        applyReason: '',
        invoiceErrorList: [],
        supplementImage: [],
      },
      isEdit: false,
      company: {
        recipientsEmil: '',
        recipientsAddress: '',
        recipientsTel: '',
        recipients: '',
        invoiceType: [],
        id: '',
        sellerName: '',
        sellerBank: '',
        sellerAccount: '',
        sellerAddress: '',
        sellerTel: '', // 销方电话
        sellerTaxpayerId: '', // 销方税号（纳税人识别号）,只能15、17、18、20位
      },
      rules: {
        invoiceType: [
          { required: true, message: '请选择开票类型', trigger: 'change' },
        ],
        sellerName: [
          { required: true, message: '请输入发票抬头', trigger: 'blur' },
        ],
        sellerTaxpayerId: [
          { required: true, validator: validateNum, trigger: 'blur' },
        ],
        sellerAddress: [
          { required: true, message: '请输入注册地址', trigger: 'blur' },
        ],
        sellerTel: [
          { required: true, validator: validatePhone, trigger: 'blur' },
        ],
        sellerBank: [
          { required: true, message: '请输入开户银行', trigger: 'blur' },
        ],
        sellerAccount: [
          { required: true, message: '请输入开户账号', trigger: 'blur' },
        ],
      },
      orderConsultHistoryList: [],
    }
  },
  components: { QualificationInvoiceDialog },
  created () {

  },
  mounted () {

  },
  watch: {},
  methods: {
    hedui () {
      this.getInfo()
    },

    async goSubmit (formEl) {
      if (!formEl) {
        return
      }
      await formEl.validate((validate, files) => {
        if (validate) {
          let params = {
            ...this.company
          }
          this.params.invoiceType = this.company.invoiceType.join(',')
          if (this.company.id) {
            cominvoicetitleinfoSave(params).then((res) => {
              if (res.code == 0) {
                this.$message.success('开票信息编辑成功')
              } else {
                this.$message.error('开票信息编辑失败')
              }
              getInfo()
              // isEdit = false
            })
          } else {
            cominvoicetitleinfoSave(params).then((res) => {
              if (res.code == 0) {
                this.$message.success('开票信息编辑成功')
              } else {
                this.$message.error('开票信息编辑失败')
              }
              getInfo()
              // isEdit = false
            })
          }
        }
      })
    },
    getInfo () {
      cominvoicetitleinfoDetailShopId(this.form.shopId).then(res => {
        if (res.code === 0) {
          if (res.data) {
            this.company = res.data
            this.company.invoiceType = this.company.invoiceType ? this.company.invoiceType.split(',') : []

            // this.fapiaoDialog = true
            this.$refs.qualificationInvoiceDialogRef.openDialog(this.company);
          } else {
            this.$message.warning('客户未上传发票信息，请您联系客户处理')
          }
        } else {
          this.$message.warning(res.msg);
        }
      })
    },
    async onSubmit () {
      let params = {}
      let arr = []
      if (this.form.applyReason && this.form.applyReason == 2) {
        this.typesArr.forEach(item => {
          if (item.select) {
            this.form.invoiceErrorList.push(item)
          }
        })
      }
      if (this.form.supplementImage && this.form.supplementImage.length > 0) {
        this.form.supplementImage.forEach(item => {
          arr.push(item.url)
        })
      }
      if (this.form.applyReason == 2 && this.form.invoiceErrorList.length == 0) {
        this.$message.warning('请先选择发票有误信息')
      }
      params.orderId = this.orderDetail.id
      params.remark = this.form.remark ? this.form.remark : '无 '
      params.applyReason = this.form.applyReason
      params.invoiceErrorList = this.form.invoiceErrorList.join(',')
      params.supplementImage = arr.join(',')
      const res = await afterInvoice(params)
      console.log(res, 'res')
      if (res) {
        this.$message.success('提交成功')
        this.dialogVisibleFaPiao = false
        submitSuccess()
        // emits('submit-success')
      }
    },

    // 点击已有的文件
    preview (file) {
      console.log(file)
      this.pdfUrl = file.response.data.url;
      this.pdfStr = (file.response.data.url).substring(file.response.data.url.length - 3)
      // console.log(this.pdfUrl)
      // console.log(this.pdfStr)
      this.pdfDialog = true;
    },

    // 上传成功
    successUpload (form, res, file, fileList) {
      form.supplementImage.push(
        {
          name: file.response.data.fileName,
          url: file.response.data.url,
        }
      )
    },

    // 移除文件
    removeUpload (form, file, fileList) {
      form.supplementImage.forEach((el, index) => {
        if (el.uid == file.uid) {
          form.supplementImage.splice(index, 1)
        }
      })
    },

    // 点击发票已有的文件
    previewInvoiceImage (file) {
      console.log(file)
      this.pdfUrl = file.response.data.url;
      this.pdfStr = (file.response.data.url).substring(file.response.data.url.length - 3)
      // console.log(this.pdfUrl)
      // console.log(this.pdfStr)
      this.pdfDialog = true;
    },

    // 上传发票成功
    successInvoiceImage (form, res, file, fileList) {
      this.invoiceImage.push(
        file
      )
    },

    // 移除发票文件
    removeInvoiceImage (form, file, fileList) {
      this.invoiceImage.forEach((el, index) => {
        if (el.uid == file.uid) {
          this.invoiceImage.splice(index, 1)
        }
      })
    },

    // 再次申请
    zaicishenqing () {
      this.form = {
        remark: '',
        orderId: '',
        applyReason: '',
        invoiceErrorList: [],
        supplementImage: [],
      }
      this.btnStatus = 'submit'
    },

    shenqingjieru () {
      console.log('申请介入成功')
    },

    didClickType (index) {
      // 选中
      // this.typesArr[index].select = !this.typesArr[index].select
    },
    // 初始化
    async init () {
      this.dialogVisibleFaPiao = true
      if (this.orderDetail.invoiceAfterSaleStatus) {
        let params = {
          orderId: this.orderDetail.id
        }
        const res = await getAfterInvoice(params)
        this.orderConsultHistoryList = res.orderConsultHistoryList ? res.orderConsultHistoryList : []
        this.form = res
        this.form.supplementImage = res.supplementImage ? res.supplementImage.split(',') : []
        this.form.invoiceErrorList = res.invoiceErrorList ? res.invoiceErrorList.split(',') : []
        let imageArr = _.cloneDeep(this.form.supplementImage)
        let arr = []
        if (imageArr.length > 0) {
          imageArr.forEach(item => {
            arr.push(
              {
                name: item.substring(57),
                response: {
                  data: {
                    url: item
                  }
                }
              }
            )
          })
        }
        this.form.supplementImage = arr
        if (this.form.invoiceErrorList.length > 0) {
          this.form.invoiceErrorList.forEach(item => {
            this.typesArr.forEach(el => {
              if (el.value == item) {
                el.select = true
              }
            })
          })
        }
      }
      console.log(this.typesArr)
      if (this.orderDetail.invoiceAfterSaleStatus == 'CHECKING' || this.orderDetail.invoiceAfterSaleStatus == 'PLATFORM_REJECT' || this.orderDetail.invoiceAfterSaleStatus == 'PLATFORM_PASS') {
        this.btnStatus = 'reSubmit'
      } else if (this.orderDetail.invoiceAfterSaleStatus == 'REJECT') {
        this.btnStatus = 'reject'
      } else if (this.orderDetail.invoiceAfterSaleStatus == 'PASS') {
        this.btnStatus = 'pass'
      }
      this.dialogVisibleFaPiao = true
    },
    // 下载发票文件
    async downLoad () {
      if (this.form.invoiceImage) {
        window.open(this.form.invoiceImage)
      }
    },
    // 驳回弹窗
    reject () {
      this.bohuiDialog = true
    },
    // 驳回
    async bohuiSubmit () {
      if (!this.rejectReason) {
        this.$message.warning('请输入驳回原因')
        return
      }
      let params = {
        id: this.form.id,
        rejectReason: this.rejectReason
      }
      const res = await invoiceReject(params)
      if (res) {
        this.$message.success('操作成功')
        this.bohuiDialog = false
        this.dialogVisibleFaPiao = false
        this.$parent.$parent.getData()
      }
    },
    // 去开票
    agree () {
      this.tongguoDialog = true
    },
    // 去开票
    async tijiaoSubmit () {
      this.tongguoDialog = true
      let arr = []

      if (!this.invoiceImage || this.invoiceImage.length == 0) {
        this.$message.warning('请上传发票文件')
        return
      }
      if (this.invoiceImage && this.invoiceImage.length > 0) {
        this.invoiceImage.forEach(item => {
          arr.push(item.response.data.url)
        })
      }
      let params = {
        id: this.form.id,
        invoiceImage: arr.join(',')
      }

      const res = await uploadInvoice(params)
      if (res) {
        this.$message.success('操作成功')
        this.tongguoDialog = false
        this.dialogVisibleFaPiao = false
        this.$parent.$parent.getData()
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.QualificationInvoiceDialog {
  ::v-deep .el-dialog__body {
    height: auto;
  }
}

::v-deep .el-checkbox__input.is-disabled+span.el-checkbox__label {
  display: inline-block !important;
  align-items: center;
}

.fapiao {
  .detail {
    ::v-deep .el-dialog__body {
      display: flex;
      overflow: auto;
      justify-content: center;
    }
  }

  .reject {
    ::v-deep .el-dialog__body {
      height: auto;
      overflow: auto;
    }
  }

  .mainContent {
    ::v-deep .el-upload--text {
      display: block;
    }

    ::v-deep .el-upload-list__item:hover .el-icon--close {
      display: block !important;
    }

    ::v-deep .el-upload-list__item .el-icon--upload-success {
      display: block !important;
    }

    .xinxi {
      ::v-deep .el-form-item__content {
        line-height: initial;
      }
    }


    ::v-deep .el-form-item__label {
      font-weight: 400;
    }

    ::v-deep .shenqingyuanyin {
      .el-form-item__content {
        display: block;
      }

      .tebietixing {
        padding: 10px;
        background-color: #FFF8F2;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          font-weight: 600;
        }
      }
    }

    .typeView {
      display: flex;
      flex-wrap: wrap;
      // align-items: center;
      // margin-bottom: 24px;
      cursor: pointer;
    }

    .typeRow {
      border: 1px solid #d9d9d9;
      padding: 5px;
      border-radius: 4px;
      color: #262626;
      font-size: 12px;
      margin-right: 5px;
      position: relative;
    }

    .typeSelect {
      border: 1px solid #f5222d;
    }

    .checkImage {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 15px;
      width: 15px;
    }

    .dialogTitle {
      font-size: 14px;
      line-height: 16px;
      padding-left: 10px;
      position: relative;
      text-align: left;
      color: #595959;
      margin-bottom: 12px;

      &:before {
        content: '';
        display: inline-block;
        width: 3px;
        height: 16px;
        background-color: #f5222d;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .lishi {
      padding: 10px 0;
      border-bottom: 1px solid #f2f2f2;

      .xieshang {
        display: flex;
        justify-content: space-between;

        .title {
          font-size: 14px;
          line-height: 16px;
          // padding-left: 10px;
          position: relative;
          text-align: left;
          color: #262626;
          margin-bottom: 12px;
        }

        .time {
          font-size: 12px;
          color: #595959;

        }
      }
    }
  }
}
</style>
