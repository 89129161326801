
<!--  -->
<template>
      <el-dialog
        title="设置抬头"
        :visible.sync="visible"
        :lock-scroll="true"
        width="618px"
        :before-close="handleClose"
      >
    <div style="margin-top:20px">
    
      <el-form ref="ruleForm"
               :model="company"
               :rules="rules"
               label-width="110px">
        <el-form-item label="发票抬头"
                      prop="companyName">
          <el-input v-model="company.companyName"
                    size="small"
                    placeholder="请输入发票抬头"
                    
                    style="width: 400px"
                    max="100" />
        </el-form-item>
        <el-form-item label="发票税号"
                      prop="companyTaxpayerId">
          <el-input v-model="company.companyTaxpayerId"
                    size="small"
                    placeholder="请输入发票税号"
                    style="width: 400px" />
        </el-form-item>
        <el-form-item label="注册地址"
                      prop="companyAddress">
          <el-input v-model="company.companyAddress"
                    size="small"
                    type="textarea"
                    max="200"
                    placeholder="请输入注册地址"
                    style="width: 400px" />
        </el-form-item>
        <el-form-item label="注册电话"
                      prop="companyTel">
          <el-input v-model="company.companyTel"
                    size="small"
                    placeholder="请输入注册电话"
                    show-word-limit
                    max="100"
                    style="width: 400px" />
        </el-form-item>
        <el-form-item label="开户银行"
                      prop="companyBank">
          <el-input v-model="company.companyBank"
                    size="small"
                    placeholder="请输入开户银行"
                    max="100"
                    style="width: 400px" />
        </el-form-item>
        <el-form-item label="开户账号"
                      prop="companyAccount">
          <el-input v-model="company.companyAccount"
                    size="small"
                    placeholder="请输入开户账号"
                    max="100"
                    style="width: 400px" />
        </el-form-item>
        <el-form-item label="收件人"
                      prop="recipients">
          <el-input v-model="company.recipients"
                    size="small"
                    placeholder="请输入收件人"
                    max="100"
                    style="width: 400px" />
        </el-form-item>
        <el-form-item label="联系电话"
                      prop="recipientsTel">
          <el-input v-model="company.recipientsTel"
                    size="small"
                    placeholder="请输入联系电话"
                    max="100"
                    style="width: 400px" />
        </el-form-item>
        <el-form-item label="收件地址"
                      prop="recipientsAddress">
          <el-input v-model="company.recipientsAddress"
                    size="small"
                    placeholder="请输入收件地址"
                    max="100"
                    style="width: 400px" />
        </el-form-item>
        <el-form-item label="邮箱"
                      prop="recipientsEmil">
          <el-input v-model="company.recipientsEmil"
                    size="small"
                    placeholder="请输入邮箱"
                    max="100"
                    style="width: 400px" />
        </el-form-item>
        <el-form-item>
          <MyButton 
                    type="primary"
                    icon=""
                    text="确认"
                    @click="goSubmit" />
        </el-form-item>
      </el-form>
    </div>
      </el-dialog>
  </template>
  
  <script>
  import MyButton from '@/components/myButton'
  import {
    csoEditInvoiceHeader
  } from "@/api/compliance/invoiceManager"
  import { isvalidatemobile, checkTax } from '@/utils/validate'
  const validateNum = (rule, value, callback) => {
    if (checkTax(value)) {
      callback()
    } else {
      callback(new Error('税号错误'))
    }
  }
  export default {
    components: { MyButton },
    data () {
        return {
          visible: false,
          toShopId: '',
        company: {
          recipientsEmil: '',
          recipientsAddress: '',
          recipientsTel: '',
          recipients: '',
          companyName: '',
          companyBank: '',
          companyAccount: '',
          companyAddress: '',
          companyTel: '', // 销方电话
          companyTaxpayerId: '', // 销方税号（纳税人识别号）,只能15、17、18、20位
        },
        rules: {
          companyName: [
            { required: true, message: '请输入发票抬头', trigger: 'blur' },
          ],
          companyTaxpayerId: [
            { required: true, validator: validateNum, trigger: 'blur' },
          ],
          companyAddress: [
            { required: true, message: '请输入注册地址', trigger: 'blur' },
          ],
          companyTel: [
            { required: true,  trigger: 'blur' },
          ],
          companyBank: [
            { required: true, message: '请输入开户银行', trigger: 'blur' },
          ],
          companyAccount: [
            { required: true, message: '请输入开户账号', trigger: 'blur' },
          ],
        },
      }
    },
    methods: {
      goSubmit () {
        this.$refs.ruleForm.validate((valid) => {
          console.log(valid)
          if (valid) {
            let params = {
              ...this.company
            }
            params.toShopId = this.toShopId;
            csoEditInvoiceHeader(params).then((res) => {
                if(res){
                    this.$message.success('保存成功')
                    this.visible = false;
                    this.$emit('refresh')
                }
              })
            }
        })
      },
      getInfo (item) {
        const {
            draftShopId,
            draftInvoiceHeader
        } = item;
        this.toShopId = draftShopId;
        if(draftInvoiceHeader){
            const {
                companyName,
                companyBank,
                companyAccount,
                companyAddress,
                companyTel, 
                companyTaxpayerId,
                recipients,
                recipientsTel,
                recipientsAddress,
                recipientsEmil
            } = draftInvoiceHeader;
            this.company = {
                companyName,
                companyBank,
                companyAccount,
                companyAddress,
                companyTel,
                companyTaxpayerId,
                recipients,
                recipientsTel,
                recipientsAddress,
                recipientsEmil
            }
        }
        this.visible = true;
      },
      handleClose () {
        this.company = {
          recipientsEmil: '',
          recipientsAddress: '',
          recipientsTel: '',
          recipients: '',
          companyName: '',
          companyBank: '',
          companyAccount: '',
          companyAddress: '',
          companyTel: '', // 销方电话
          companyTaxpayerId: '', // 销方税号（纳税人识别号）,只能15、17、18、20位
        } 
        this.visible = false;
    }
    }
  }
  </script>
  <style lang='scss' scoped>
  </style>
  