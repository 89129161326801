<template>
    <el-dialog title="提现" :visible.sync="dialogVisible" width="80%" :before-close="handleClose" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :inline="true">
            <!-- <el-form-item label="银行卡">
                <el-input v-model="form.cardNo" size="small" :disabled="true"></el-input>
            </el-form-item> -->
            <el-form-item label="提现金额">
                <el-input v-model="form.money" size="small" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="支付密码" v-if="!fundType">
                <el-input v-model.trim="form.passWord" size="small" show-password ></el-input>
            </el-form-item>
            <el-form-item>
                <span class="dialog-footer">
                    <el-button type="primary" @click="makeSure" size="small">确认提现</el-button>
                    <el-button @click="handleClose" size="small">返  回</el-button>
                </span>
            </el-form-item>
        </el-form>
        <el-table :data="tableData" style="width: 100%" :height="tableHeight" v-if="tableHeight"
            @selection-change="handleSelectionChange" ref="multipleTable">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="incomeTime" label="出入账时间" align="center">
            </el-table-column>
            <el-table-column prop="erpCode" label="客户ERP编码" align="center">
            </el-table-column>
            <el-table-column prop="customerName" label="客户名称" align="center">
            </el-table-column>
            <el-table-column label="入账金额（元）" align="center">
                <div slot-scope="scope">
                    <div>{{ scope.row.amount.toFixed(2) }}</div>
                </div>
            </el-table-column>
            <el-table-column prop="orderTime" label="订单创建时间" align="center">
            </el-table-column>
            <el-table-column prop="orderCode" label="订单ID" align="center">
            </el-table-column>
        </el-table>
        <div class="footerView">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[10, 20, 30, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
           
        </div>
    </el-dialog>
</template>
<script>
import { getMediCompanyWithDrawOrderList, doWithdraw, getMediDeptWithDrawOrderList, deptIncomeExpenditureWithdrawal} from "@/api/fund/fundApi";
import _ from "lodash";
export default {
    props: {
        dialogVisible: {
            type: Boolean,
            default: () => false,

        },
        tableHeight: {
            type: Number,
            default: () => 0
        },
        fundType:{
            type:String,
            default:()=>''
        }
    },
    data() {
        return {
            tableData: [],
            currentPage:1,
            pageSize:10,
            total:10,
            form:{
                // cardNo:'',
                money:'',
                passWord:''
            },
            selections:[],
        }
    },
    mounted() {
        // this.getList()
    },
    methods: {
        async getList(){
            const obj = {
                current:this.currentPage,
                size:this.pageSize
            }
            const resp = this.fundType == 'dept' ? await getMediDeptWithDrawOrderList(obj) : await getMediCompanyWithDrawOrderList(obj)
            if (resp && resp.records) {
                this.tableData = resp.records
                this.total = resp.total
            }else{
                this.tableData = []
            }
        },
        resetSelections(){
            // 清空选择的数据
            this.$refs.multipleTable.clearSelection();
        },
        resetPageInfo(){
            this.currentPage = 1
            this.pageSize = 10
            this.total = 10
        },
        handleClose() {
            this.$emit('dialogDimiss','')

        },
        handleSizeChange(e){
            this.pageSize = e
            this.getList()
        },
        handleCurrentChange(e){
            this.currentPage = e
            this.getList()
        },
        handleSelectionChange(val){
            // 勾选操作
            this.selections = val
            this.countTotalMoney()
        },
        countTotalMoney(){
            // 计算提现总金额
            let total = 0.0
            _.forEach(this.selections,(item,index)=>{
                total += Number(item.amount)*1000000
            })
            this.form.money = total/1000000 || ''
        },
        async makeSure(){
            if (!this.selections.length) {
                this.$message.warning('请选择订单')
                return
            }

            if ( 'dept' == this.fundType) {
                
                let tmp = []
                _.forEach(this.selections,(item,index)=>{
                    tmp.push(item.id)
                })
                let obj = {
                    itemIdSet:tmp,
                    amount: this.form.money,
                }
                const resp = await deptIncomeExpenditureWithdrawal(obj)
                if (resp) {
                    this.$message.success('申请成功')
                    this.handleClose()
                    this.resetSelections()
                    this.getList()
                    this.form.money = ''
                    this.$emit('deptWithdrawSuccess', '')
                }
            } else {
                
                if (!this.form.passWord) {
                    this.$message.warning('请输入密码')
                    return
                }
                let tmp = []
                _.forEach(this.selections,(item,index)=>{
                    tmp.push(item.id)
                })
                let obj = {
                    itemIdSet:tmp,
                    shopId:this.selections[0].shopId,
                    deptId:this.selections[0].deptId,
                    amount: this.form.money,
                    bankCardId: "",
                    merchantNo: "",
                    password: this.form.passWord,
                    postscript: ""
                }
                const resp = await doWithdraw(obj)
                if (resp) {
                    this.handleClose()
                    this.$emit('codeData', resp,tmp)
                    this.form.passWord = ''
                    this.form.money = ''
                }
            }

        },
    }
}
</script>
<style lang="scss" scoped>
    .footerView{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
    }
</style>