var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "el-form",
            {
              ref: "formTool",
              staticStyle: { "text-align": "left" },
              attrs: { model: _vm.formTool, inline: true },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "goodsName", label: "" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入商品名称",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.formTool.goodsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "goodsName", $$v)
                      },
                      expression: "formTool.goodsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "factory", label: "" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入生产厂家",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.formTool.factory,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "factory", $$v)
                      },
                      expression: "formTool.factory",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.reset()
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.detailHandle({}, "add")
                },
              },
            },
            [_vm._v("新增推广商品")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "center", staticStyle: { position: "relative" } },
        [
          _c(
            "MyTable",
            {
              attrs: {
                "table-data": _vm.tableData,
                index: _vm.index,
                selection: _vm.selection,
                page: _vm.page,
                operation: _vm.operation,
                "table-option": _vm.tableOption,
              },
              on: {
                "update:tableOption": function ($event) {
                  _vm.tableOption = $event
                },
                "update:table-option": function ($event) {
                  _vm.tableOption = $event
                },
                "page-change": _vm.getList,
                handleSelectionChange: _vm.handleSelectionChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "image",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticStyle: { width: "100px" } },
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                },
                                attrs: {
                                  src: scope.row.image
                                    ? scope.row.image
                                    : _vm.avater,
                                  lazy: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                width: "calc(100% - 110px)",
                                "margin-left": "10px",
                              },
                            },
                            [
                              _c("el-row", [
                                _vm._v(" " + _vm._s(scope.row.name) + " "),
                              ]),
                              _c("el-row", [
                                _vm._v(" " + _vm._s(scope.row.factory) + " "),
                              ]),
                              _c("el-row", [
                                _vm._v(
                                  " " + _vm._s(scope.row.specification) + " "
                                ),
                              ]),
                              _c("el-row", [
                                _vm._v(
                                  " " + _vm._s(scope.row.approvalNumber) + " "
                                ),
                              ]),
                              _c("el-row", [
                                _vm._v(
                                  " 商品编码： " +
                                    _vm._s(scope.row.goodsCode) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "salesUserAmount",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.salesUserAmount) +
                          " (" +
                          _vm._s(scope.row.applyUserAmount) +
                          ") "
                      ),
                    ]
                  },
                },
                {
                  key: "opration",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "btn-warp" },
                        [
                          scope.row.status == 1
                            ? _c(
                                "el-link",
                                {
                                  staticClass: "btn-list",
                                  attrs: { underline: false, type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.detailHandle(
                                        scope.row,
                                        "detail"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("详情")]
                              )
                            : _vm._e(),
                          scope.row.status == 1
                            ? _c(
                                "el-link",
                                {
                                  staticClass: "btn-list",
                                  attrs: { underline: false, type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.detailHandle(scope.row, "edit")
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              )
                            : _vm._e(),
                          scope.row.status == 2
                            ? _c(
                                "el-link",
                                {
                                  staticClass: "btn-list",
                                  attrs: { underline: false, type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.detailHandle(
                                        scope.row,
                                        "reset"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("重新提交")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "status",
                  fn: function (scope) {
                    return [
                      scope.row.status == 0
                        ? _c("span", { staticStyle: { color: "#E6A23C" } }, [
                            _c("i", { staticClass: "el-icon-success" }),
                            _vm._v(" 待审核"),
                          ])
                        : _vm._e(),
                      scope.row.status == 1
                        ? _c("span", { staticStyle: { color: "#67C23A" } }, [
                            _c("i", { staticClass: "el-icon-warning" }),
                            _vm._v(" 审核通过"),
                          ])
                        : _vm._e(),
                      scope.row.status == 2
                        ? _c("span", { staticStyle: { color: "#F5222d" } }, [
                            _c("i", { staticClass: "el-icon-warning" }),
                            _vm._v(" 审核驳回"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 50],
                  layout: "sizes, prev, pager, next",
                  total: _vm.page.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("goodsDialog", {
        ref: "goods-dailog",
        attrs: { dialogShow: _vm.dialogShow, btnType: _vm.btnType },
        on: { didSelectItem: _vm.didSelectItem },
      }),
      _c("goodsSettingDialog", {
        ref: "goods-setting-dailog",
        on: { getList: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }