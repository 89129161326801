<template>
  <div class="comContent">
    <div class="header">
      <span class="font">报名</span>
      <div class="step-frame">
        <StepView :current-index="localCurrentIndex" :step-list="stepList" />
      </div>
    </div>
    <div class="centent">
      <template v-if="localCurrentIndex == 1">
        <firstStep @changeStep="changeStep" :id="id" @getSkuList="getSkuList" />
      </template>
      <template v-else-if="localCurrentIndex == 2">
        <secondStep @changeStep="changeStep" :id="id" :skuList="skuList" />
      </template>
      <template v-else-if="localCurrentIndex == 3">
        <threeStep @changeStep="changeStep" :id="id" />
      </template>
    </div>
  </div>
</template>

<script>
import StepView from "@/views/myQualification/children/step";
import firstStep from './components/firstStep.vue'
import secondStep from './components/secondStep.vue'
import threeStep from './components/threeStep.vue'

export default {
  components: {
    StepView,
    firstStep,
    secondStep,
    threeStep
  },
  data () {
    return {
      id: '',
      skuList: [],
      stepList: [
        { name: "选择活动商品" },
        { name: "填写报名信息" },
        { name: "报名成功" },
      ],
      localCurrentIndex: 1,
    };
  },
  computed: {
    //
  },
  watch: {},
  async created () {
    this.id = this.$route.query.id || ''
  },
  mounted () {
  },
  methods: {
    changeStep (val) {
      this.localCurrentIndex = val
    },
    getSkuList (val) {
      this.skuList = val
    }
  },
};
</script>
<style lang="scss" scoped>
.comContent {
  display: flex;
  flex-direction: column;
  padding: 0 0 24px;
}

.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  box-sizing: border-box;
  border-bottom: 1px solid #f5f5f5;

  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }

  .step-frame {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}

.centent {
  flex: 1;
  height: 0;
  overflow-y: auto;
  padding: 0 24px;
  box-sizing: border-box;
}
</style>
