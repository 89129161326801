<!-- 客户报表列表 -->
<template>
  <div class="comContent body-box">
    <div class="body-tools">
      <div class="body-tools-left">
        <el-form ref="formTool" :model="formTool" :inline="true">
          <el-form-item label="" prop="">
            <el-input v-model="formTool.erp_id" size="small" placeholder="请输入客户ERP ID" clearable />
          </el-form-item>
          <el-form-item label="" prop="">
            <el-input v-model="formTool.pay_shop_name" size="small" placeholder="请输入客户名称" clearable />
          </el-form-item>
          <el-form-item label="" prop="">
            <el-input v-model="formTool.pay_concat_phone" size="small" placeholder="请输入客户电话" clearable />
          </el-form-item>
          <el-form-item label="" prop="">
            <el-cascader v-model="formTool.pay_district_name" size="small" ref="areaRef" placeholder="请选择所在区域" clearable
            :options="areaTreeData" :props="cascaderProps" @change="areaChange"></el-cascader>
          </el-form-item>
          <el-form-item label="" prop="">
            <el-select v-model="formTool.pay_shop_type_name" size="small" placeholder="请选择客户类型" clearable>
              <el-option v-for="item in typesOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="首次下单时间" prop="">
            <el-date-picker v-model="formTool.shp_first_ord_time" size="small" type="daterange" value-format="yyyy-MM-dd" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="最后下单时间" prop="">
            <el-date-picker v-model="formTool.shp_last_ord_time" size="small" type="daterange" value-format="yyyy-MM-dd" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="最后访问时间" prop="">
            <el-date-picker v-model="formTool.last_visit_time" size="small" type="daterange" value-format="yyyy-MM-dd" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item> -->
          <el-form-item label="" prop="phone">
            <MyButton type="primary" text="查询" @click="goSearch" />
            <el-button size="small" @click="formToolReset()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="body-tools-right">
        <MyButton type="primary" text="导出" @click="goExport" />
      </div>
    </div>
    <div class="body-table">
      <MyTable :table-data="tableData"
        max-height="calc(100% - 44px)"
        :index="false"
        :selection="false"
        :page="page"
        :operation="false"
        :table-option.sync="tableOption"
        @page-change="getList"
        @sort-change="sortChange"
        @handleSelectionChange="currentChange">
        <template slot="tools" slot-scope="scope">
          <div style="display:flex">
            <el-link type="primary" :underline="false" size="small" @click="goLink(scope.row)">查看详情</el-link>
          </div>
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable';
import { getAreaTree } from '@/api/shopmange/index';
import { getListByType } from "@/api/businessManage";
import { queryGoodsSalesEcharts, queryGoodsSalesEchartsExport } from "@/api/statistics";
export default {
  name:'ReportCustomerList',
  components: { MyButton, MyTable },
  data() {
    return {
      formTool: {
        erp_id:'', //-- 客户ERP ID
        pay_shop_name:'', //-- 客户名称
        pay_concat_phone:'', //-- 客户电话
        // pay_concat_name, //-- 联系人
        pay_district_name:[], //-- 客户所在区域
        pay_shop_type_name:'', //-- 客户类型
        // order_cnt, // -- 订单量
        // qty, //-- 商品销量
        // amount, //-- 采购额（元）
        // avg_order_amount, //-- 客单价（元）
        shp_first_ord_time:'', //-- 首次下单时间
        shp_last_ord_time:'', //-- 最后下单时间
        // last_visit_time, //-- 最后访问时间
      },
      typesOptions: [],
      tableData: [],
      tableOption: [
        { label: '客户ERP ID', prop: 'erp_id', width: '90px' },
        { label: '客户名称', prop: 'pay_shop_name', minWidth: '130px' },
        { label: '客户电话', prop: 'pay_concat_phone', width: '110px' },
        { label: '联系人', prop: 'pay_concat_name', width: '90px' },
        { label: '客户所在地区', prop: 'pay_district_name', width: '180px' },
        { label: '客户类型', prop: 'pay_shop_type_name', width: '90px' },
        { label: '订单量', prop: 'order_cnt', width: '90px',sortable:'custom' },
        { label: '商品销量', prop: 'qty', width: '90px',sortable:'custom' },
        { label: '采购额(元)', prop: 'amount', width: '120px',sortable:'custom' },
        { label: '客单价(元)', prop: 'avg_order_amount', width: '100px',sortable:'custom' },
        { label: '首次下单时间', prop: 'shp_first_ord_time', width: '150px',sortable:'custom' },
        { label: '最后下单时间', prop: 'shp_last_ord_time',sortable:'custom', width: '150px' },
        { label: '最后访问时间', prop: 'last_visit_time',sortable:'custom', width: '150px' },
        { label: '操作', prop: 'tools', slot: true, fixed:'right', width: '80px' }
      ],
      page: { total: 10, current: 1, size: 10 },
      sortStatus:'up',
      sortType:'1',// 排序字段: 订单量 1,商品销量 2,采购额（元） 3,客单价（元） 4,首次下单时间 5,最后下单时间 6,最后访问时间 7
      cascaderProps: {
        label:'rname',
        value:'id',
      },
      areaTreeData:[],
    };
  },
  computed: {
    shopId(){
      return this.$store.state.user.user_info.shopId;
    },
  },
  // watch: {},
  created() {
    this.getArea();
    this.getUserTypes();
    this.getList();
  },
  mounted() {},
  methods: {
    goLink(el){
      let url = `/reportCustomer/reportCustomer-reportDetail?id=${el.pay_shop_id}&name=${el.pay_shop_name}`;
      this.$router.push(url);
    },
    goSearch(){
      this.sortStatus = 'up';
      // this.sortType = '1';
      this.initData();
    },
    sortChange(el){
      const { column, prop, order } = el; // order释义：descending:降,ascending:升,为空时代表不选中
      console.log(column, prop, order)
      let propNames = {
        order_cnt: { label: '订单量', value: '1' },
        qty: { label: '商品销量', value: '2' },
        amount: { label: '采购额', value: '3' },
        avg_order_amount: { label: '客单价', value: '4' },
        shp_first_ord_time: { label: '首次下单时间', value: '5' },
        shp_last_ord_time: { label: '最后下单时间', value: '6' },
        last_visit_time: { label: '最后访问时间', value: '7' },
      }
      // 执行条件搜索操作
      this.sortStatus = order == 'descending'?'down':'up';
      // sortType:'1',// 排序字段: 订单量 1,商品销量 2,采购额（元） 3,客单价（元） 4,首次下单时间 5,最后下单时间 6,最后访问时间 7
      this.sortType = (order && propNames[prop]) ? propNames[prop].value : '1';
      this.initData();
    },
    //获取整个省市区的树
    getArea () {
      getAreaTree({ level: 3 }).then(res => {
        this.areaTreeData = res.data || [];
      })
    },
    areaChange(val){
      const el = this.$refs.areaRef.getCheckedNodes();
      let areas = []
      if(val.length>0){
        areas = el[0].pathNodes.map(li=>{
          return { id: li.value, name: li.label }
        })
      }
      console.log(areas);
    },
    getUserTypes(){
      // 获取客户/商户类型列表
      getListByType({
        type: 0, //0 客户类型  1商户类型
      }).then((res) => {
        this.typesOptions = res.data?res.data.map(li=>{
          return { value: li.typeCode, label: li.typeName }
        }):[]
      });
    },
    goExport(){
      let obj = this.getParams('export');
      queryGoodsSalesEchartsExport(obj,'客户报表列表'); //执行文件获取及下载
    },
    formToolReset () {
      this.formTool = {
        erp_id:'', //-- 客户ERP ID
        pay_shop_name:'', //-- 客户名称
        pay_concat_phone:'', //-- 客户电话
        // pay_concat_name, //-- 联系人
        pay_district_name:[], //-- 客户所在区域
        pay_shop_type_name:'', //-- 客户类型
        // order_cnt, // -- 订单量
        // qty, //-- 商品销量
        // amount, //-- 采购额（元）
        // avg_order_amount, //-- 客单价（元）
        shp_first_ord_time:'', //-- 首次下单时间
        shp_last_ord_time:'', //-- 最后下单时间
        // last_visit_time, //-- 最后访问时间
      }
      // this.$refs.formTool.resetFields()
      this.initData()
    },
    currentChange(){},
    initData(){
      this.page.current = 1;
      this.getList();
    },
    getList(){
      // console.log('getList-重新加载数据')
      let obj = this.getParams(this.sortStatus);
      let totalObj = this.getParams('total');
      queryGoodsSalesEcharts(obj).then(list=>{
        this.tableData = list || [];
      })
      queryGoodsSalesEcharts(totalObj).then(list=>{
        // 获取总条数
        let total = 0;
        if(list && list.length>0){
          total = parseInt(list[0].cnt);
        }
        this.page.total = total;
      })
    },
    getParams(status = 'up'){
      const { current, size } = this.page;
      const { erp_id, pay_shop_name, pay_concat_phone, pay_district_name, pay_shop_type_name, shp_first_ord_time, shp_last_ord_time } = this.formTool;
      // formTool: {
      //   erp_id:'', //-- 客户ERP ID
      //   pay_shop_name:'', //-- 客户名称
      //   pay_concat_phone:'', //-- 客户电话
      //   pay_district_name:[], //-- 客户所在区域
      //   pay_shop_type_name:'', //-- 客户类型
      //   shp_first_ord_time:'', //-- 首次下单时间
      //   shp_last_ord_time:'', //-- 最后下单时间
      // },
      let priviceCode = '';
      let cityCode = '';
      let areaCode = '';
      if(pay_district_name && pay_district_name.length>0){
        priviceCode = pay_district_name[0];
        cityCode = pay_district_name[1];
        if(pay_district_name.length>2){
          areaCode = pay_district_name[2];
        }
      }

      let firstSTime = '';
      let firstETime = '';
      if(shp_first_ord_time && shp_first_ord_time.length>0){
        firstSTime = shp_first_ord_time[0].replaceAll('-', '');
        firstETime = shp_first_ord_time[1].replaceAll('-', '');
      }
      let EndSTime = '';
      let EndETime = '';
      if(shp_last_ord_time && shp_last_ord_time.length>0){
        EndSTime = shp_last_ord_time[0].replaceAll('-', '');
        EndETime = shp_last_ord_time[1].replaceAll('-', '');
      }
      let types = {
        up: 54, down:55, total:56, export:57
      }
      let type = types[status];
      let params = [
        this.shopId,// 商户ID"1606205771220635650"
        erp_id || '',erp_id || '',// ERPID
        pay_shop_name || '',pay_shop_name || '', // 客户名称
        pay_concat_phone || '',pay_concat_phone || '', // 客户电话
        priviceCode,priviceCode, // 省编码
        cityCode,cityCode, // 市编码
        areaCode,areaCode, // 区编码
        pay_shop_type_name || '',pay_shop_type_name || '', // 客户类型编码
        firstSTime,firstSTime, // 首次下单时间(开始时间)
        firstETime,firstETime, // 首次下单时间(结束时间)
        EndSTime,EndSTime, // 最后下单时间(开始时间)
        EndETime,EndETime, // 最后下单时间(结束时间)
      ]
      let paramsMore = [
        this.sortType, // 排序字段: 订单量 1,商品销量 2,采购额（元） 3,客单价（元） 4,首次下单时间 5,最后下单时间 6,最后访问时间 7
        size, // 每页行数
        (current - 1)*size, // 第几行开始
      ]
      return { type, params: (status=='up' || status == 'down') ? [ ...params, ...paramsMore ] : params}
    },
  },
  // beforeCreate() { },
  // beforeMount() { },
  // beforeUpdate() { },
  // updated() { },
  // beforeDestroy() { },
  // destroyed() { },
  // activated() { },
}
</script>
<style lang='scss' scoped>
.body{
  &-tools{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 16px;
    &-left{
      width: calc(100% - 90px);
    }
    &-right{
      width: 90px;
      text-align: right;
    }
    ::v-deep .el-form{
      &-item{
        margin-bottom: 6px !important;
      }
    }
  }
  &-table{
    height: calc(100% - 110px);
  }
}
</style>
