import request from '@/utils/request'

// 商品一级分类列表
export function goodsclassOne() {
  return request({
    url: '/product/goodsclass/one',
    method: 'get'
  })
}

// 商品二级分类列表
export function goodsclassTwo(id) {
  return request({
    url: '/product/goodsclass/' + id,
    method: 'get'
  })
}

// 新增商品分类
export function goodsclass(data) {
  return request({
    url: '/product/goodsclass',
    method: 'post',
    data
  })
}

// 新增商品分类
export function oprationclassTreeDisplay(data) {
  return request({
    url: '/product/operationalclass/goodsOperationalClasss/tree',
    method: 'get',
    params: data
  })
}

export function goodsClassRelationsSelectGoods (data) {
  return request({
    url: '/product/operationalclass/goodsOperationalClassRelations/filterGoods',
    method: 'get',
    params: data
  })
}

export function goodsClassRelationsSearchGoods (data) {
  return request({
    url: '/product/operationalclass/goodsOperationalClassRelations/searchGoods',
    method: 'get',
    params: data
  })
}



