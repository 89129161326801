<template>
  <div class="three">
    <div>
      <div style="display: flex;justify-content: center;">
        <img src="@/assets/paysuccess.png" alt="">
      </div>
      <el-button size="small" style="margin-top: 12px;" @click="returnBack()">返回活动列表</el-button>
    </div>

  </div>

</div></template>
  
<script>

export default {
  props: {},
  data () {
    return {

    }
  },
  components: {},
  created () {

  },
  mounted () {

  },
  watch: {},
  methods: {
    returnBack () {
      this.$router.push('/operationCenter/platformActivity-list')
    }
  }
}

</script>
<style lang="scss" scoped>
.three {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
