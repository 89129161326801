var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tableMy" },
    [
      _c("MyTable", {
        attrs: {
          "table-data": _vm.tableData,
          index: _vm.index,
          selection: _vm.selection,
          page: _vm.page,
          operation: _vm.operation,
          "table-option": _vm.tableOption,
        },
        on: {
          "update:tableOption": function ($event) {
            _vm.tableOption = $event
          },
          "update:table-option": function ($event) {
            _vm.tableOption = $event
          },
          "page-change": _vm.getList,
          handleSelectionChange: _vm.handleSelectionChange,
        },
        scopedSlots: _vm._u([
          {
            key: "information",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "left",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "imageP", staticStyle: { width: "100%" } },
                      [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-weight": "600",
                              color: "#262626",
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.name || "-") + " ")]
                        ),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-weight": "600",
                              color: "#262626",
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.factory || "-") + " ")]
                        ),
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(scope.row.specification || "-") + " "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(scope.row.approvalNumber || "-") + " "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " 商品编码：" +
                              _vm._s(scope.row.goodsCode || "-") +
                              " "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " SKU编码：" + _vm._s(scope.row.skuId || "-") + " "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " ERP编码：" +
                              _vm._s(scope.row.medicineCode || "-") +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "image",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "column",
                      "align-items": "center",
                      "justify-content": "left",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          height: "100%px",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c("el-image", {
                          staticStyle: { width: "80px" },
                          attrs: {
                            src: scope.row.image || _vm.avater,
                            "preview-src-list": [scope.row.image || _vm.avater],
                            lazy: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "imageP",
                        staticStyle: {
                          width: "100%",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center",
                        },
                      },
                      [_c("p", [_vm._v(" " + _vm._s(scope.row.name) + " ")])]
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "price",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "left",
                    },
                  },
                  [
                    _c("div", { staticClass: "imageP" }, [
                      _c("p", [
                        _vm._v(
                          " 价格：￥" + _vm._s(scope.row.salePrice || "-") + " "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 市场价：￥" +
                            _vm._s(scope.row.marketPrice || "-") +
                            " "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 建议零售价：￥" +
                            _vm._s(scope.row.purchasePrice || "-") +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "inventory",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "left",
                    },
                  },
                  [
                    _c("div", { staticClass: "imageP" }, [
                      _c(
                        "p",
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content:
                                  "总库存=ERP同步库存/手工设置商品总库存",
                                placement: "top",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline",
                              }),
                            ]
                          ),
                          _vm._v(
                            " 总库存：" +
                              _vm._s(scope.row.goodsSkuInventory || "-") +
                              " "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content:
                                  "可售库存=ERP同步库存手工设置商品库存-订单占用库存-活动占用库存",
                                placement: "top",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline",
                              }),
                            ]
                          ),
                          _vm._v(" 可售库存：" + _vm._s("-") + " "),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content:
                                  "待付款、待发货、配送中、配送完成、正在售后状态占用库存",
                                placement: "top",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline",
                              }),
                            ]
                          ),
                          _vm._v(" 订单占用库存：" + _vm._s("-") + " "),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "活动预占库存",
                                placement: "top",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline",
                              }),
                            ]
                          ),
                          _vm._v(" 活动占用库存：" + _vm._s("-") + " "),
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          " 最近效期：" +
                            _vm._s(scope.row.expireTime || "-") +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "status",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "left",
                    },
                  },
                  [
                    _c("div", { staticClass: "imageP" }, [
                      _c("p", [_vm._v(" " + _vm._s(scope.row.updateBy) + " ")]),
                      _c("p", [
                        _vm._v(" " + _vm._s(scope.row.updateTime) + " "),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "caozuo",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _vm.activeName == "MERCHANT_REMOVAL"
                      ? _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.listing(scope.row)
                              },
                            },
                          },
                          [_vm._v("上架")]
                        )
                      : _vm._e(),
                    _vm.activeName == "ON_SALE"
                      ? _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.delist(scope.row)
                              },
                            },
                          },
                          [_vm._v("下架")]
                        )
                      : _vm._e(),
                    _c(
                      "el-link",
                      {
                        attrs: { underline: false, type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.detailHandle(scope.row)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                    _vm.activeName == "ON_SALE" ||
                    _vm.activeName == "MERCHANT_REMOVAL" ||
                    _vm.activeName == "SKU_AWAIT" ||
                    _vm.activeName == "SKU_FAIL" ||
                    _vm.activeName == "BASE_TEMPORARY_FAIL"
                      ? _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.editHandle(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.activeName == "BASE_ALREADY"
                      ? _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.addStash(scope.row)
                              },
                            },
                          },
                          [_vm._v("加入仓库")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }