var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("div", { staticClass: "search" }, [
        _c(
          "div",
          {},
          [
            _c("el-input", {
              staticStyle: { width: "150px" },
              attrs: { size: "small", placeholder: "商品名称", clearable: "" },
              model: {
                value: _vm.chemName,
                callback: function ($$v) {
                  _vm.chemName = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "chemName",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "everyItem" },
          [
            _c("el-input", {
              staticStyle: { width: "150px" },
              attrs: { size: "small", placeholder: "ERP编码" },
              model: {
                value: _vm.shopGoodsCode,
                callback: function ($$v) {
                  _vm.shopGoodsCode = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "shopGoodsCode",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "everyItem" },
          [
            _c("el-input", {
              staticStyle: { width: "150px" },
              attrs: { size: "small", clearable: "", placeholder: "生产厂家" },
              model: {
                value: _vm.factory,
                callback: function ($$v) {
                  _vm.factory = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "factory",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "everyItem" },
          [
            _c("el-input", {
              staticStyle: { width: "150px" },
              attrs: { size: "small", clearable: "", placeholder: "批准文号" },
              model: {
                value: _vm.nationalCode,
                callback: function ($$v) {
                  _vm.nationalCode = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "nationalCode",
              },
            }),
          ],
          1
        ),
        _vm.tabIndex == 0
          ? _c(
              "div",
              { staticClass: "everyItem" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "150px" },
                    attrs: {
                      clearable: "",
                      placeholder: "匹配结果",
                      size: "small",
                    },
                    model: {
                      value: _vm.result,
                      callback: function ($$v) {
                        _vm.result = $$v
                      },
                      expression: "result",
                    },
                  },
                  _vm._l(_vm.options, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "everyItem", staticStyle: { display: "flex" } },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.query(1)
                  },
                },
              },
              [_vm._v("查询")]
            ),
            _c(
              "el-button",
              { attrs: { size: "small", type: "" }, on: { click: _vm.reset } },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "buttonClass" }, [
        _vm.tabIndex == 0
          ? _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.match },
                  },
                  [_vm._v("批量确认匹配")]
                ),
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.importGoods } },
                  [_vm._v("批量导入商品")]
                ),
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.deleteGoods } },
                  [_vm._v("批量删除")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.tabIndex == 1
          ? _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.allPublishAction },
                  },
                  [_vm._v("全部上架")]
                ),
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.shelf } },
                  [_vm._v("批量上架")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.tabIndex == 3
          ? _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.rollBack },
                  },
                  [_vm._v("批量恢复")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.tabIndex,
                callback: function ($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex",
              },
            },
            _vm._l(_vm.tabsList, function (item, itemIndex) {
              return _c(
                "el-tab-pane",
                {
                  key: itemIndex,
                  attrs: {
                    label: item.name + "(" + item.count + ")",
                    name: item.itemIndex,
                  },
                },
                [
                  _c("MyTable", {
                    attrs: {
                      "table-data": _vm.tableData,
                      index: _vm.index,
                      selection: _vm.selection,
                      page: _vm.page,
                      operation: _vm.operation,
                      "table-option": _vm.tableOption,
                    },
                    on: {
                      "update:tableOption": function ($event) {
                        _vm.tableOption = $event
                      },
                      "update:table-option": function ($event) {
                        _vm.tableOption = $event
                      },
                      "page-change": _vm.getList,
                      handleSelectionChange: _vm.handleSelectionChange,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "companyInfo",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "goodsInfo" }, [
                                _c("div", [
                                  _c("div", { staticClass: "chargeInLine" }),
                                  _c("div", { staticClass: "chemName" }, [
                                    _vm._v(_vm._s(scope.row.chemName)),
                                  ]),
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row.specification)),
                                  ]),
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row.factory)),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      "批准文号：" +
                                        _vm._s(scope.row.nationalCode)
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      "商品ERP编码：" +
                                        _vm._s(scope.row.shopGoodsCode)
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: scope.row.origin,
                                          expression: "scope.row.origin",
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "产地：" + _vm._s(scope.row.origin)
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "chargeInLine" }),
                                ]),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "cbyykInfo",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "goodsInfo" }, [
                                _c("div", { staticClass: "chargeInLine" }),
                                scope.row.matchStatus >= 0
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "chemName",
                                          class: _vm.isDiff(
                                            scope.row.chemName,
                                            scope.row.goodsBaseV2DTO.name
                                          )
                                            ? "diffText"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.goodsBaseV2DTO.name
                                              )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          class: _vm.isDiff(
                                            scope.row.specification,
                                            scope.row.goodsBaseV2DTO
                                              .specification
                                          )
                                            ? "diffText"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.goodsBaseV2DTO
                                                  .specification
                                              )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          class: _vm.isDiff(
                                            scope.row.factory,
                                            scope.row.goodsBaseV2DTO.factory
                                          )
                                            ? "diffText"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.goodsBaseV2DTO.factory
                                              )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          class: _vm.isDiff(
                                            scope.row.nationalCode,
                                            scope.row.goodsBaseV2DTO
                                              .approvalNumber
                                          )
                                            ? "diffText"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            " 批准文号：" +
                                              _vm._s(
                                                scope.row.goodsBaseV2DTO
                                                  .approvalNumber
                                              )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                scope.row.goodsBaseV2DTO
                                                  .goodsCode,
                                              expression:
                                                "scope.row.goodsBaseV2DTO.goodsCode",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "商城编码：" +
                                              _vm._s(
                                                scope.row.goodsBaseV2DTO
                                                  .goodsCode || ""
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                scope.row.goodsBaseV2DTO.origin,
                                              expression:
                                                "scope.row.goodsBaseV2DTO.origin",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "产地：" +
                                              _vm._s(
                                                scope.row.goodsBaseV2DTO.origin
                                              )
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.tabIndex == 0
                                  ? _c("div", { staticClass: "chargeInLine" }, [
                                      scope.row.matchStatus == 1
                                        ? _c("div", [
                                            _c("i", {
                                              staticClass: "el-icon-success",
                                              staticStyle: { color: "#67C239" },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "chargeStatus" },
                                              [_vm._v("完全匹配")]
                                            ),
                                          ])
                                        : _vm._e(),
                                      scope.row.matchStatus == 0
                                        ? _c("div", [
                                            _c("i", {
                                              staticClass: "el-icon-warning",
                                              staticStyle: { color: "#F5A300" },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "chargeStatus" },
                                              [_vm._v("部分匹配")]
                                            ),
                                          ])
                                        : _vm._e(),
                                      scope.row.matchStatus == -1
                                        ? _c("div", [
                                            _c("i", {
                                              staticClass: "el-icon-error",
                                              staticStyle: { color: "#f5222d" },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "chargeStatus" },
                                              [_vm._v("匹配失败")]
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "pickText",
                                          on: {
                                            click: function ($event) {
                                              return _vm.matchId(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("选择匹配")]
                                      ),
                                    ])
                                  : _c("div", { staticClass: "chargeInLine" }),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "image",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    display: "block",
                                    width: "100px",
                                    height: "100px",
                                  },
                                },
                                [
                                  scope.row.goodsBaseV2DTO.image[0]
                                    ? _c("el-image", {
                                        attrs: {
                                          src: scope.row.goodsBaseV2DTO
                                            .image[0],
                                          alt: "",
                                          "preview-src-list":
                                            scope.row.goodsBaseV2DTO.image,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "erpProdDate",
                          fn: function (scope) {
                            return [
                              scope.row.erpProdDate
                                ? _c("div", [
                                    _vm._v(
                                      "生产日期：" +
                                        _vm._s(scope.row.erpProdDate)
                                    ),
                                  ])
                                : _vm._e(),
                              scope.row.erpValidity
                                ? _c("div", [
                                    _vm._v(
                                      "有效期至：" +
                                        _vm._s(scope.row.erpValidity)
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "handle",
                          fn: function (scope) {
                            return [
                              _vm.tabIndex == 0
                                ? _c(
                                    "div",
                                    { staticClass: "handleView" },
                                    [
                                      scope.row.matchStatus >= 0
                                        ? _c(
                                            "el-link",
                                            {
                                              attrs: { underline: false },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.makeSureMatch(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("确认匹配")]
                                          )
                                        : _vm._e(),
                                      scope.row.matchStatus <= 0
                                        ? _c(
                                            "el-link",
                                            {
                                              attrs: { underline: false },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addGoods(scope.row)
                                                },
                                              },
                                            },
                                            [_vm._v("创建商品")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { underline: false },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteOne(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.tabIndex == 1
                                ? _c(
                                    "div",
                                    { staticClass: "handleView" },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { underline: false },
                                          on: {
                                            click: function ($event) {
                                              return _vm.shelfSignal(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("商品上架")]
                                      ),
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { underline: false },
                                          on: {
                                            click: function ($event) {
                                              return _vm.cancelAssociationAction(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("取消匹配")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.tabIndex == 2
                                ? _c(
                                    "div",
                                    { staticClass: "handleView" },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { underline: false },
                                          on: {
                                            click: function ($event) {
                                              return _vm.supplementary(
                                                scope.row,
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查看上架详情")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.tabIndex == 3
                                ? _c(
                                    "div",
                                    { staticClass: "handleView" },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { underline: false },
                                          on: {
                                            click: function ($event) {
                                              return _vm.rollbackGoods(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("恢复")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("UnitDialog", {
        attrs: {
          "unit-list": _vm.unitList,
          "dialog-visible": _vm.dialogVisible,
        },
        on: { submitCheck: _vm.submitCheck },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量导入",
            visible: _vm.importVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.importVisible = $event
            },
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c("p", [_vm._v("1: 先下载导入模板，按格式填写数据")]),
                _c("p", { staticStyle: { color: "orange" } }, [
                  _vm._v(
                    "（注：Excel 中黄色为必填字段，其他为非必填字段，请按示例格式规范填写）"
                  ),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      icon: "el-icon-bottom",
                      type: "primary",
                    },
                    on: { click: _vm.fileTemplate },
                  },
                  [_vm._v("下载模板")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box" },
              [
                _c("p", [
                  _vm._v("2: 选择保存的Excel文件，点击导入即可完成批量导入"),
                ]),
                _c(
                  "el-upload",
                  {
                    staticClass: "upload-demo",
                    attrs: {
                      accept: ".xls",
                      "show-file-list": false,
                      action: _vm.action,
                      "on-success": _vm.success,
                      multiple: "",
                      headers: _vm.headers,
                      "on-error": _vm.uploadError,
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          icon: "el-icon-top",
                          type: "primary",
                        },
                      },
                      [_vm._v("表格导入")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择商品",
            visible: _vm.goodsVisible,
            width: "880px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.beforeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.goodsVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialogBody" }, [
            _c("p", [
              _vm._v(
                "商品名称：" +
                  _vm._s(_vm.params.chemName) +
                  "，规格：" +
                  _vm._s(_vm.params.specification) +
                  "，生产厂家：" +
                  _vm._s(_vm.params.factory)
              ),
            ]),
            _c("p", [
              _vm._v(
                "以下列表中，请选择商品信息一致的商品（规格数字一样的描述不一样的，可视为一致），如果无匹配的商品，请从新增上架途径单独上架。"
              ),
            ]),
            _c(
              "div",
              { staticStyle: { display: "flex", flex: "1" } },
              [
                _c("GoodsCard", {
                  attrs: {
                    params: _vm.params,
                    goodsData: _vm.goodsData,
                    activeIndex: _vm.activeIndex,
                  },
                  on: {
                    "update:activeIndex": function ($event) {
                      _vm.activeIndex = $event
                    },
                    "update:active-index": function ($event) {
                      _vm.activeIndex = $event
                    },
                    choiceItem: _vm.choiceItem,
                  },
                }),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "footerBtn",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.cancelSelect } },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.submit },
                  },
                  [_vm._v("提交")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }