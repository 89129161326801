var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "second" }, [
    _c(
      "div",
      { staticClass: "center" },
      [
        _c("MyTable", {
          attrs: {
            "table-data": _vm.tableData,
            index: _vm.index,
            selection: _vm.selection,
            page: _vm.page,
            operation: _vm.operation,
            "table-option": _vm.tableOption,
            showpage: _vm.showpage,
          },
          on: {
            "update:tableOption": function ($event) {
              _vm.tableOption = $event
            },
            "update:table-option": function ($event) {
              _vm.tableOption = $event
            },
            "page-change": _vm.getList,
            handleSelectionChange: _vm.handleSelectionChange,
          },
          scopedSlots: _vm._u([
            {
              key: "info",
              fn: function (scope) {
                return [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        width: "100%",
                      },
                    },
                    [
                      _c("el-image", {
                        staticStyle: { width: "80px", height: "80px" },
                        attrs: {
                          src: scope.row.image || _vm.avater,
                          "preview-src-list": [scope.row.image],
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "10px",
                            width: "calc(100% - 90px)",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "overFlow",
                              staticStyle: { "font-size": "18px" },
                            },
                            [_vm._v(_vm._s(scope.row.goodsName || "--"))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "overFlow",
                              staticStyle: {
                                "font-size": "16px",
                                color: "rgb(191, 191, 191)",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(scope.row.goodsFactory || "--") + " "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "overFlow" }, [
                            _vm._v(_vm._s(scope.row.approvalNumber || "--")),
                          ]),
                          _c("div", { staticClass: "overFlow" }, [
                            _vm._v(_vm._s(scope.row.specification || "--")),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "dangqian",
              fn: function (scope) {
                return [
                  _c("div", [_vm._v("￥" + _vm._s(scope.row.salePriceShow))]),
                ]
              },
            },
            {
              key: "jianyi",
              fn: function (scope) {
                return [
                  _c("div", [_vm._v("￥" + _vm._s(scope.row.skuPriceMax))]),
                  _c("div", [_vm._v("约" + _vm._s(scope.row.discount) + "折")]),
                ]
              },
            },
            {
              key: "price",
              fn: function (scope) {
                return [
                  _c(
                    "div",
                    { staticClass: "moneyStyle" },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          clear: "",
                          placeholder: "请输入活动价",
                        },
                        model: {
                          value: scope.row.activityPrice,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "activityPrice", $$v)
                          },
                          expression: "scope.row.activityPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "butie",
              fn: function (scope) {
                return [_c("div", [_vm._v(_vm._s(scope.row.skuInventoryMin))])]
              },
            },
            {
              key: "caozuo",
              fn: function (scope) {
                return [
                  _c(
                    "el-link",
                    {
                      attrs: { type: "primary", underline: false },
                      on: {
                        click: function ($event) {
                          return _vm.deleteData(scope.row.skuId)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
    _c("div", { staticClass: "bottom" }, [
      _c(
        "div",
        { staticStyle: { position: "absolute", left: "10px" } },
        [
          _vm._v("批量使用建议价格作为活动价 "),
          _c(
            "el-link",
            {
              staticStyle: { "margin-left": "16px" },
              attrs: { type: "primary", underline: false },
              on: { click: _vm.shiyong },
            },
            [_vm._v("确认使用")]
          ),
          _c(
            "el-link",
            {
              staticStyle: { "margin-left": "16px" },
              attrs: { type: "primary", underline: false },
              on: { click: _vm.empty },
            },
            [_vm._v("清空所有活动价")]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.reChoice },
            },
            [_vm._v("重选商品")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.submit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }