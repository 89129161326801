<!--
 * @FilePath: /eshop-ops-web/src/components/goodsThreeClass/index.vue
 * @Author: xiexingzhong2012@sina.cn
 * @Date: 2023-07-26 09:00:11
 * @LastEditTime: 2023-07-30 15:41:29
 * @LastEditors: xiexingzhong2012@sina.cn
 * @Description:  
 此模块为 商品基库=》商品三级分类 
-->
<template>
  <el-form-item :label="isShowLable ? lableText : null" prop="goodsOneClassId">
    <span
      v-if="isShowLable"
      slot="label"
      :class="
        changeForm.goodsOneClassId ||
        changeForm.goodsTwoClassId ||
        changeForm.goodsThreeClassId
          ? 'lable-color-correct'
          : ''
      "
      >{{ lableText }}</span
    >
    <div class="select-warp">
      <el-select
        v-model="formTool.goodsOneClassId"
        size="small"
        :placeholder="placeholderText"
        @change="changeOneClassId"
        :style="styleLable"
      >
        <el-option
          v-for="item in goodsOneClassIdOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-select
        v-model="formTool.goodsTwoClassId"
        size="small"
        placeholder="请选择"
        :style="styleLable"
        v-if="goodsTwoClassIdOptions.length > 0"
        @change="changeTwoClassId"
      >
        <el-option
          v-for="item in goodsTwoClassIdOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-select
        v-model="formTool.goodsThreeClassId"
        size="small"
        placeholder="请选择"
        v-if="goodsThreeClassIdOptions.length > 0"
        :style="styleLable"
      >
        <el-option
          v-for="item in goodsThreeClassIdOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </div>
  </el-form-item>
</template>
<script>
import { goodsclassOne, goodsclassTwo } from "@/api/commodityreference";
export default {
  components: {},
  props: {
    isShowLable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    placeholderText: {
      type: String,
      default() {
        return "请选择";
      },
    },
    lableText: {
      type: String,
      default() {
        return "商品分类";
      },
    },
    styleLable: {
      type: String,
      default() {
        return "width: 32%; margin-right: 2%";
      },
    },
    formTool: {
      type: Object,
      default() {
        return {};
      },
    },
    changeForm: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      goodsOneClassIdOptions: [],
      goodsTwoClassIdOptions: [],
      goodsThreeClassIdOptions: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // console.log(this.$route.query.id)
    if (!this.$route.query.id) {
      this.query();
    }
    this.$forceUpdate();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  methods: {
    // 重置表单
    resetForm() {
      this.goodsOneClassIdOptions = [];
      this.goodsTwoClassIdOptions = [];
      this.goodsThreeClassIdOptions = [];
      this.formTool.goodsOneClassId = "";
      this.formTool.goodsTwoClassId = "";
      this.formTool.goodsThreeClassId = "";
      this.query();
    },
    // 初次进来只查询一级分类 不联动
    query() {
      goodsclassOne()
        .then((res) => {
          if (res.code == 0) {
            this.goodsOneClassIdOptions = res.data;
          }
        })
        .catch((err) => {
        });
    },
    queryDetail() {
      // 回显查询 一级分类
      // console.log(this.formTool,'formTool');
      // console.log(this.formTool.goodsOneClassId,'goodsOneClassId');
      if (this.formTool.goodsOneClassId) {
        // console.log(this.formTool.goodsOneClassId);
        goodsclassOne()
          .then((res) => {
            if (res.code == 0) {
              this.goodsOneClassIdOptions = res.data;
              console.log(this.goodsOneClassIdOptions);
              this.$forceUpdate();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      // 回显查询 二级分类
      if (this.formTool.goodsOneClassId && this.formTool.goodsTwoClassId) {
        goodsclassTwo(this.formTool.goodsOneClassId)
          .then((res) => {
            if (res.code == 0) {
              this.goodsTwoClassIdOptions = res.data;
              this.$forceUpdate();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // 回显查询 三级分类
      if (
        this.formTool.goodsOneClassId &&
        this.formTool.goodsTwoClassId &&
        this.formTool.goodsThreeClassId
      ) {
        goodsclassTwo(this.formTool.goodsTwoClassId)
          .then((res) => {
            if (res.code == 0) {
              this.goodsThreeClassIdOptions = res.data;
              this.$forceUpdate();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    // 切换一级分类
    changeOneClassId(val) {
      if (val) {
        this.goodsclassTwo(val);
      }
    },
    // 切换二级分类
    changeTwoClassId(val) {
      if (val) {
        this.goodsThreeClassIdOptions = [];
        this.goodsclassThree(val);
      }
    },
    // 查询一级分类
    goodsclassOne() {
      goodsclassOne()
        .then((res) => {
          if (res.code == 0) {
            this.goodsOneClassIdOptions = res.data;
            if (this.goodsOneClassIdOptions.length > 0) {
              this.formTool.goodsOneClassId = this.goodsOneClassIdOptions[0].id;
            } else {
              this.formTool.goodsOneClassId = "";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询二级分类
    goodsclassTwo(id) {
      goodsclassTwo(id)
        .then((res) => {
          if (res.code == 0) {
            this.goodsTwoClassIdOptions = res.data;
            if (this.goodsTwoClassIdOptions.length > 0) {
              this.formTool.goodsTwoClassId = "";
              this.goodsThreeClassIdOptions = [];
            } else {
              this.formTool.goodsTwoClassId = "";
              this.goodsThreeClassIdOptions = [];
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询三级分类
    goodsclassThree(id) {
      goodsclassTwo(id)
        .then((res) => {
          if (res.code == 0) {
            this.goodsThreeClassIdOptions = res.data;
            if (this.goodsThreeClassIdOptions.length > 0) {
              this.formTool.goodsThreeClassId = "";
            } else {
              this.formTool.goodsThreeClassId = "";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.select-warp {
  display: flex;
  justify-content: flex-start;
}
.select-warp .el-select:nth-child(3) {
  margin-right: 0 !important;
}
.lable-color-correct {
  color: #f00;
}
</style>
