import request from '@/utils/request'
import { Message } from 'element-ui'
// 收款对账详情
export function detailPage(data) {
  return request({
    url: "/statistic/account/checking/detail/page",
    method: "get",
    params: data,
  });
}

// 导出:收款对账详情
export function downloadDetailPage(data) {
  return request({
    url: `/statistic/account/checking/detail/excel`,
    method: 'get',
    params:data,
    responseType: 'blob'
  }).then(response => {
    // 处理返回的文件流
    const blob = response.data
    if (blob && blob.size === 0) {
      Message.warning('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    const contentDisposition = response.headers['content-disposition'];
    const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1));
    console.log(fileName)
    link.download = fileName;
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
      Message.success('导出成功')
    }, 0)
  })
}


// 付款对账详情
export function payDetailPage(data) {
  return request({
    url: "/statistic/account/checking/payDetail/page",
    method: "get",
    params: data,
  });
}


// 导出:付款对账详情
export function downloadPayDetailPage(data) {
  return request({
    url: `/statistic/account/checking/payDetail/excel`,
    method: 'get',
    params:data,
    responseType: 'blob'
  }).then(response => {
    // 处理返回的文件流
    const blob = response.data
    if (blob && blob.size === 0) {
      Message.warning('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    const contentDisposition = response.headers['content-disposition'];
    const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1));
    console.log(fileName)
    link.download = fileName;
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
      Message.success('导出成功')
    }, 0)
  })
}