var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传开票",
            visible: _vm.dialogVisible,
            width: "70%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "left_topic" }, [_vm._v("发票抬头信息")]),
          ]),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-row",
                { staticClass: "list-t" },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "发票抬头：" + _vm._s(_vm.invoiceTitle.sellerName) + " "
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("开户行：" + _vm._s(_vm.invoiceTitle.sellerBank)),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "list-t" },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("地址：" + _vm._s(_vm.invoiceTitle.sellerAddress)),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("电话：" + _vm._s(_vm.invoiceTitle.sellerTel)),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "list-t" },
                [
                  _c("el-col", { attrs: { span: 12 } }),
                  _c("el-col", { attrs: { span: 12 } }),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "list-t" },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "税号：" + _vm._s(_vm.invoiceTitle.sellerTaxpayerId || "")
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "开户行账号：" +
                        _vm._s(_vm.invoiceTitle.sellerAccount || "")
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "list-t" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("span", [_vm._v("发票类型：")]),
                      _c(
                        "el-checkbox-group",
                        {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.invoiceTitle.invoiceTypes,
                            callback: function ($$v) {
                              _vm.$set(_vm.invoiceTitle, "invoiceTypes", $$v)
                            },
                            expression: "invoiceTitle.invoiceTypes",
                          },
                        },
                        _vm._l(_vm.invoicetypeData, function (item, index) {
                          return _c(
                            "el-checkbox",
                            {
                              key: index,
                              attrs: { label: item.id, name: item.name },
                            },
                            [_vm._v(" " + _vm._s(item.name || "") + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "left_topic" }, [_vm._v("上传发票")]),
          ]),
          _c("p", { staticStyle: { margin: "10px 0px" } }, [
            _vm._v(
              "上传附件信息（上传实物盖章发票清晰扫描文件，多张发票请扫描成一个PDF，文件命名规格:公司名称+结算单号）"
            ),
          ]),
          _c(
            "div",
            { staticStyle: { margin: "10px 0px" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    action: _vm.action,
                    "on-preview": _vm.handlePreview,
                    "on-remove": _vm.handleRemove,
                    "before-remove": _vm.beforeRemove,
                    limit: _vm.limit,
                    "on-exceed": _vm.handleExceed,
                    "file-list": _vm.fileList,
                    "on-success": _vm.onSuccessUpload,
                    accept: ".jpg, .png, .pdf",
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("点击上传")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm._v(
                        "附件不能超过10M，最多上传1个，文件格式PDF、JPG、PNG"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitUpload()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }