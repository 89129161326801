var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filedsContainer" }, [
    _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.shopName))]),
      _c(
        "div",
        { staticClass: "font-tip" },
        [
          _vm._v(" 企业类型：" + _vm._s(_vm.shopTypeName) + " "),
          this.status != null
            ? _c("MyTypes", { attrs: { type: String(this.status) } })
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "margin-left": "auto" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.loading,
                  },
                  on: { click: _vm.downLoad },
                },
                [_vm._v("下载资质")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "body" },
      [
        _c("userInfoDetail", {
          ref: "InfoDetail",
          attrs: {
            infoDetailData: _vm.infoDetailData,
            disabled: _vm.disabled,
            type: _vm.type,
            queryScopeListData: _vm.queryScopeListData,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c("el-button", { attrs: { size: "mini" }, on: { click: _vm.back } }, [
          _vm._v("返回上一页"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }