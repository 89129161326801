var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "show-manage-warp" },
      [
        _c(
          "el-form",
          {
            ref: "shopForm",
            attrs: {
              rules: _vm.rules,
              model: _vm.formIn,
              disabled: _vm.isDisabled,
            },
          },
          [
            _c("div", { staticClass: "form shop-manage" }, [
              _c("div", { staticClass: "title" }, [
                _c("span", { staticClass: "text" }, [_vm._v("售后信息")]),
              ]),
              _c(
                "div",
                { staticClass: "block-content" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "consignee",
                        label: "收货人",
                        "label-width": "120px",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "320px" },
                        attrs: {
                          size: "small",
                          placeholder: "请输入",
                          maxlength: "25",
                        },
                        model: {
                          value: _vm.formIn.consignee,
                          callback: function ($$v) {
                            _vm.$set(_vm.formIn, "consignee", $$v)
                          },
                          expression: "formIn.consignee",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "consigneePhone",
                        label: "电话",
                        "label-width": "120px",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "320px" },
                        attrs: {
                          size: "small",
                          placeholder: "请输入",
                          maxlength: "12",
                        },
                        model: {
                          value: _vm.formIn.consigneePhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.formIn, "consigneePhone", $$v)
                          },
                          expression: "formIn.consigneePhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "省市区",
                        "label-width": "120px",
                        prop: "provinceName",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "320px",
                                "margin-right": "5px",
                              },
                              attrs: { size: "mini", placeholder: "省" },
                              on: { change: _vm.selectedHandle },
                              model: {
                                value: _vm.formIn.provinceName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formIn, "provinceName", $$v)
                                },
                                expression: "formIn.provinceName",
                              },
                            },
                            _vm._l(_vm.provinceData, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.rname, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "320px",
                                "margin-right": "5px",
                              },
                              attrs: { size: "mini", placeholder: "市" },
                              on: { change: _vm.selectedHandleCity },
                              model: {
                                value: _vm.formIn.cityName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formIn, "cityName", $$v)
                                },
                                expression: "formIn.cityName",
                              },
                            },
                            _vm._l(_vm.cityData, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.rname, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "320px",
                                "margin-right": "5px",
                              },
                              attrs: { size: "mini", placeholder: "区" },
                              on: { change: _vm.selectedHandleArea },
                              model: {
                                value: _vm.formIn.areaName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formIn, "areaName", $$v)
                                },
                                expression: "formIn.areaName",
                              },
                            },
                            _vm._l(_vm.areaData, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.rname, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "consigneeAddress",
                        label: "收货地址",
                        "label-width": "120px",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "320px" },
                        attrs: {
                          size: "small",
                          placeholder: "请输入",
                          maxlength: "100",
                        },
                        model: {
                          value: _vm.formIn.consigneeAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.formIn, "consigneeAddress", $$v)
                          },
                          expression: "formIn.consigneeAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "title" }, [
                _c("span", { staticClass: "text" }, [_vm._v("退换货流程")]),
              ]),
              _c(
                "div",
                { staticClass: "block-content" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "changingRefundingInstructions",
                        label: "退换货流程",
                        "label-width": "120px",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "2000",
                          "show-word-limit": "",
                          resize: "none",
                          rows: 6,
                          placeholder: "请输入",
                        },
                        model: {
                          value: _vm.formIn.changingRefundingInstructions,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formIn,
                              "changingRefundingInstructions",
                              $$v
                            )
                          },
                          expression: "formIn.changingRefundingInstructions",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
        !_vm.isDisabled
          ? _c(
              "div",
              { staticStyle: { "margin-left": "30px", "margin-top": "40px" } },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "el_btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.goSave },
                  },
                  [_vm._v("提交审核")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }