var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "contentCont" }, [
      _c("div", [
        _c(
          "div",
          { ref: "headRef", staticClass: "head-img" },
          [_c("el-image", { attrs: { src: _vm.storeHead } })],
          1
        ),
        _c("div", { staticClass: "staus_warp" }, [
          _c(
            "div",
            { staticClass: "staus_plan" },
            _vm._l(_vm.indexStepList, function (item, key) {
              return _c("div", { staticClass: "step-item" }, [
                _c(
                  "div",
                  {
                    staticClass: "step",
                    class: item.step <= _vm.indexStep ? "active" : "",
                  },
                  [
                    _c("div", { staticClass: "fl_center" }, [
                      _c(
                        "div",
                        { staticClass: "status" },
                        [
                          _vm.indexStep > item.step
                            ? _c("el-image", {
                                staticClass: "icon_svg",
                                attrs: { src: _vm.icon },
                              })
                            : _c("div", { staticClass: "idx" }, [
                                _vm._v(_vm._s(item.step)),
                              ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "name",
                          class: _vm.indexStep > 1 ? "sueccss" : "",
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                    ]),
                  ]
                ),
                item.step < _vm.indexStepList.length
                  ? _c("div", {
                      staticClass: "line",
                      class: item.step <= _vm.indexStep ? "active" : "",
                    })
                  : _vm._e(),
              ])
            }),
            0
          ),
        ]),
      ]),
      _c("div", { staticClass: "contentInner" }, [
        _c(
          "div",
          {
            staticClass: "shopCotent",
            style: `min-height:${_vm.contentHeight}px;`,
          },
          [
            _vm.indexStep == 1
              ? _c("shopManageFirst", {
                  ref: "shop-manage-1",
                  on: { indexStepChange: _vm.indexStepChange },
                })
              : _vm._e(),
            _vm.indexStep == 2
              ? _c("shopManageTwo", {
                  ref: "shop-manage-2",
                  on: { indexStepChange: _vm.indexStepChange },
                })
              : _vm._e(),
            _vm.indexStep == 3
              ? _c("shopManageThree", {
                  ref: "shop-manage-3",
                  on: { indexStepChange: _vm.indexStepChange },
                })
              : _vm._e(),
            _vm.indexStep == 4
              ? _c("shopManageFour", {
                  ref: "shop-manage-4",
                  on: { indexStepChange: _vm.indexStepChange },
                })
              : _vm._e(),
            _vm.indexStep == 5
              ? _c("shopManageFive", {
                  ref: "shop-manage-5",
                  on: {
                    indexStepChange: _vm.indexStepChange,
                    shopSettingsExamine: _vm.shopSettingsExamine,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "footer-warp" }, [
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            { staticClass: "el_btn", on: { click: _vm.goBackHome } },
            [_vm._v("返回首页")]
          ),
          _vm.indexStep != 2
            ? _c(
                "el-button",
                {
                  staticClass: "el_btn",
                  attrs: { type: "plain" },
                  on: { click: _vm.goSave },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
          _vm.indexStep != 1
            ? _c(
                "el-button",
                {
                  staticClass: "el_btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.goBack },
                },
                [_vm._v("上一步")]
              )
            : _vm._e(),
          _vm.indexStep != 5
            ? _c(
                "el-button",
                {
                  staticClass: "el_btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.goNext },
                },
                [_vm._v("下一步")]
              )
            : _vm._e(),
          _vm.indexStep == 5
            ? _c(
                "el-button",
                {
                  staticClass: "el_btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }