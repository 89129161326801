<template>
  <div class="shop-info-root tab-content new-cls">
    <div class="form">
      <template v-if="0">
        <!-- 发货物流就是以前店铺公告里头的快递类型,罗列到这里来了 -->
        <div class="row">
          <div class="tip"><span class="weight">*</span>发货物流</div>
          <div class="value checkbox big-w">
            <el-select
              v-model="formIn.deliveryLogisticsId"
              placeholder="请选择"
              size="small"
              @change="handleChange"
            >
              <el-option
                v-for="item in indicatorList ? indicatorList : []"
                :key="item.id"
                :label="item.instructions"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row contine" v-if="formIn.medalPicture">
          <div class="tip"></div>
          <div class="value fill">
            <div class="small-tip">
              <span class="small-icon">
                <el-image :src="formIn.medalPicture" class="icon" />
              </span>
              <span>{{ formIn.medalName }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="tip"><span class="weight">*</span>发货省份</div>
          <div class="value checkbox big-w">
            <div>
              <el-select
                v-model="formIn.sendProvince"
                style="width: 168px; margin-right: 8px"
                value-key="id"
                placeholder="请选择省份"
                @change="selectedHandle"
                :disabled="isdisabled"
              >
                <el-option
                  v-for="item in provinceData"
                  :key="item.id"
                  :label="item.rname"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="formIn.sendCity"
                style="width: 168px"
                placeholder="请选择城市"
                value-key="id"
                @change="selectedHandleCity"
                :disabled="isdisabled"
              >
                <el-option
                  v-for="item in cityData"
                  :key="item.id"
                  :label="item.rname"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="tip"><span class="weight">*</span>发货时效</div>
        <div class="value checkbox big-w">
          <el-select
            v-model="formIn.deliveryAgeingId"
            placeholder="请选择"
            size="small"
            @change="handleChangeTime"
          >
            <el-option
              v-for="item in indicatorListTime ? indicatorListTime : []"
              :key="item.id"
              :label="item.instructions"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row" v-if="formIn.medalPicture">
          <div class="tip"></div>
          <div class="value fill">
            <div class="small-tip">
              <span class="small-icon">
                <el-image :src="formIn.medalPicture" class="icon" />
              </span>
              <span>{{ formIn.medalName }}</span>
            </div>
          </div>
      </div>
      <div class="row" v-if="showHours">
        <div class="tip"></div>
        <div class="value checkbox big-w">
          <el-select
            v-model="formIn.deliveryAgeingClosingTimeId"
            placeholder="请选择截单时间"
            size="small"
          >
            <el-option
              v-for="item in indicatorClosinTime ? indicatorClosinTime : []"
              :key="item.id"
              :label="item.instructions"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="bottom-btn">
      <el-button type="primary" class="fix-four" @click="doUpdateInfo" 
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import {
  queryShopDeliveryDistributionSettings,
  updateShopDeliveryDistributionSettings,
  getIndicatorInfo,
} from "@/api/shop";
import {
  shopExtendInfos,
  dictExpresses,
  getProvince,
  getCity,
  postShopExtendInfos,
  putShopExtendInfos,
} from "@/api/shopmange/index";

import _ from "lodash";

export default {
  components: {},
  props:{
    boundMoney:{
        type:Number,
        default:()=>0
    }
  },
  data() {
    return {
      isdisabled: false,
      indicatorList: [], //物流
      indicatorListTime: [], //发货 时效
      indicatorClosinTime: [], //截单时间
      provinceData: [], //省份的数组
      cityData: [], //市的数组

      formIn: {
        deliveryLogisticsId: "", //发货物流id
        deliveryLogisticsName: "", //发货物流名称
        deliveryAgeingId: "", //发货时效id
        deliveryAgeingName: "", //发货时效名称
        sendProvince: "", //发货省份代码
        sendProvinceName: "", //发货省份名称
        sendCity: "", //发货城市代码
        sendCityName: "", //发货城市名称
        medalPicture: "", //勋章图片
        medalName: "", //勋章名称
        shopId: "",

        deliveryAgeingClosingTimeId: "", //截单时间
        deliveryAgeingClosingTimeName: "", //截单时间名称
        weight:0
      },
    };
  },
  created() {
    this.getIndicatorInfo();
    this.getIndicatorInfoValidate();
    this.getProvince();
    this.doLoadInfo();
    this.getIndicatorInfoCloseTime();
  },
  computed: {
    showHours() {
      return this.formIn.deliveryAgeingName == "极速发货";
    },
  },
  methods: {
    getIndicatorInfo() {
      let type = "MATERIAL_FLOW";
      getIndicatorInfo(type).then((data) => {
        if (data instanceof Array) {
          this.indicatorList = data;
        }
      });
    },
    handleChangeTime(val) {
      if (val) {
        if (this.formIn.deliveryAgeingId) {
          const findedItem = this.indicatorListTime.find(
            (item) => this.formIn.deliveryAgeingId === item.id
          );
          this.formIn.deliveryAgeingName = findedItem.instructions;
          this.formIn.weight = findedItem && findedItem.competitivePower ? findedItem.competitivePower : 0;
          this.setMedaImage(findedItem)
        }
      }
    },
    setMedaImage(findedItem){
      this.formIn.medalPicture = findedItem && findedItem.medalPicture ? findedItem.medalPicture : "";
      this.formIn.medalName = findedItem && findedItem.medalName ? findedItem.medalName : "";
    },
    getIndicatorInfoValidate() {
      let type = "DELIVER_GOODS";
      getIndicatorInfo(type).then((data) => {
        if (data instanceof Array) {
          this.indicatorListTime = data;
        }
      });
    },
    getIndicatorInfoCloseTime() {
      let type = "CLOSING_TIME";
      getIndicatorInfo(type).then((data) => {
        if (data instanceof Array) {
          this.indicatorClosinTime = data;
        }
      });
    },
    getProvince() {
      getProvince().then((res) => {
        if (res.code == 0) {
          this.provinceData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },
    selectedHandle(val, noClear) {
      if (!noClear) {
        this.formIn.sendCity = "";
      }

      getCity({ id: val }).then((res) => {
        if (res.code == 0) {
          this.cityData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },
    selectedHandleCity(val) {
      //
    },
    handleChange(val) {
      if (val) {
        const findedItem = this.indicatorList.find((item) => item.id === val);
        this.setMedaImage(findedItem)
      }
    },
    doLoadInfo() {
      queryShopDeliveryDistributionSettings().then((data) => {
        if (data) {
          for (let key in data) {
            if (this.formIn.hasOwnProperty(key) && data[key]) {
              this.formIn[key] = data[key];
            }
          }
          if (this.formIn.sendProvince) {
            this.selectedHandle(this.formIn.sendProvince, true);
          }
        }
      });
    },
    doUpdateInfo() {
      // if (this.formIn.deliveryLogisticsId) {
      //   const findedItem = this.indicatorList.find(
      //     (item) => this.formIn.deliveryLogisticsId === item.id
      //   );
      //   this.formIn.deliveryLogisticsName = findedItem.instructions;
      // } else {
      //   this.$message.warning("请选择发货物流");
      //   return;
      // }
      // if (this.formIn.sendProvince) {
      //   const findedItem = this.provinceData.find(
      //     (item) => this.formIn.sendProvince === item.id
      //   );
      //   this.formIn.sendProvinceName = findedItem.rname;
      // } else {
      //   this.$message.warning("请选择省份");
      //   return;
      // }
      // if (this.formIn.sendCity) {
      //   const findedItem = this.cityData.find(
      //     (item) => this.formIn.sendCity === item.id
      //   );
      //   this.formIn.sendCityName = findedItem.rname;
      // } else {
      //   this.$message.warning("请选择省份城市");
      //   return;
      // }
      if (this.formIn.deliveryAgeingId) {
        const findedItem = this.indicatorListTime.find(
          (item) => this.formIn.deliveryAgeingId === item.id
        );
        this.formIn.deliveryAgeingName = findedItem.instructions;
      } else {
        this.$message.warning("请选择发货时效");
        return;
      }
  
      if (this.boundMoney == 0 && (this.formIn.deliveryAgeingName == '极速发货' || '24小时发货' == this.formIn.deliveryAgeingName)) {
          this.$message.warning('请您充值保证金后开启极速发货/24小时发货，该发货方式将提高您店铺商品的搜索排名')
          return
      }
      if (this.showHours) {
        //截单时间
        if (this.formIn.deliveryAgeingClosingTimeId) {
          const findedItem = this.indicatorClosinTime.find(
            (item) => this.formIn.deliveryAgeingClosingTimeId === item.id
          );
          this.formIn.deliveryAgeingClosingTimeName = findedItem.instructions;
        } else {
          this.$message.warning("请选择截单时间");
          return;
        }
      }
      let data = {};
      _.merge(data, this.formIn);
      updateShopDeliveryDistributionSettings(data).then((res) => {
        if (res) {
          if (!this.formIn.shopId) {
            this.doLoadInfo();
          }
          this.$message.success("保存成功");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
