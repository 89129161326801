import request from '@/utils/request'

// 获取免审商品列表
export function getNotcheckList(data) {
  return request({
    url: '/product/goodsbase/notcheck/page',
    method: 'get',
    params: data
  })
}

// 加入仓库
export function addSku(data) {
  return request({
    url: '/product/stock/sku/add/v2',
    method: 'post',
    data
  })
}

// 获取sku自营商品定价
export function support(data) {
  return request({
    url: '/product/stock/sku/support/v2',
    method: 'get',
    params: data
  })
}

// 编辑库存中查询sku信息
export function skuLists(data) {
  return request({
    url: '/product/stock/sku/lists/v2',
    method: 'get',
    params: data
  })
}

// 编辑库存中查询sku信息
export function skuStatus(params,data) {
  return request({
    url: `/product/goodsbase/skuStatus/${params}/${data}`,
    method: 'put'
  })
}

// 根据商品查询条件查询所有的厂家
export function queryFactory(data) {
  return request({
    url: `/product/goodsbase/notcheck/factory`,
    method: 'get',
    params:data,
  })
}  
