var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "补款通知",
            visible: _vm.visible,
            "lock-scroll": true,
            top: "50px",
            width: "62%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialogBox" }, [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c(
                  "el-form",
                  {
                    ref: "formData",
                    attrs: {
                      model: _vm.formData,
                      inline: true,
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm._v("需求方：" + _vm._s(_vm.unitItem.buyerName)),
                        ]),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm._v(
                            "服务方：" +
                              _vm._s(_vm.unitItem.sellerFeedGoodsName)
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("el-divider"),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c("el-row", [
                              _c(
                                "div",
                                { staticClass: "goods-img" },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                      border: "solid 1px #dcdfe6",
                                    },
                                    attrs: {
                                      src: _vm.unitItem.drugImage
                                        ? _vm.unitItem.drugImage
                                        : _vm.avater,
                                      lazy: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "140",
                                      label: "药品信息：",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.unitItem.goodsName))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "140",
                                      label: "药品规格：",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.unitItem.specs))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "140",
                                      label: "厂商：",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.unitItem.factory))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "140",
                                      label: "所需数量：",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.unitItem.askNumber))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "140",
                                      label: "期望价格：",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " ≤ ￥ " + _vm._s(_vm.unitItem.askPrice)
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c("el-row", [
                              _c(
                                "div",
                                { staticClass: "goods-img" },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                      border: "solid 1px #dcdfe6",
                                    },
                                    attrs: {
                                      src: _vm.unitItem.drugImage
                                        ? _vm.unitItem.drugImage
                                        : _vm.avater,
                                      lazy: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "140",
                                      label: "药品信息：",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.unitItem.goodsName))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "140",
                                      label: "药品规格：",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.unitItem.specs) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "140",
                                      label: "厂商：",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.unitItem.factory))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "140",
                                      label: "供给数量：",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.unitItem.sellerFeedNumber)
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "140",
                                      label: "供应商报价：",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " ￥" +
                                        _vm._s(_vm.unitItem.sellerFeedPrice) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "140",
                                      label: "需支付定金：",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " ￥ " +
                                        _vm._s(_vm.unitItem.payDeposit) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("el-divider"),
                    _c(
                      "div",
                      { staticClass: "bt-rt" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.submitCheck()
                              },
                            },
                          },
                          [_vm._v("补款通知")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }