// CHECKING:待审核，PASS:审核通过，REJECT审核不通过,PLATFORM_REJECT平台驳回，PLATFORM_PASS平台通过
export const certificationStatusStr = {   
  CHECKING: '待审核',
  PASS: '审核通过',
  REJECT: '已驳回',
  PLATFORM_REJECT: '已驳回',
  PLATFORM_PASS: '审核通过',
}
export const certificationStatusAction = {   
  CHECKING: '',
  PASS: '',
  REJECT: '提交',
  PLATFORM_REJECT: '',
  PLATFORM_PASS: '',
}
export default{
  certificationStatusStr,
  certificationStatusAction
}