import request from '@/utils/request'
import { Message } from 'element-ui'

export function purchaseOrderExcel(data) {
    return request({
        url: `/statistic/purchaseOrderExcel/findPage`,
        method: "POST",
        data
    });
}

export function purchaseOrderExportExcel(data) {
    return request({
        url: `/statistic/purchaseOrderExcel/exportExcel`,
        method: "POST", 
        data
    });
}


export function findStDataByRange(data) {
    return request({
        url: `/statistic/stProvinceAmt/findStDataByRange`,
        method: "POST", 
        data
    });
}

// 导出订单统计列表
export function exportOrderReportTableData(data) { 
    return request({
      url: `/statistic/purchaseOrderExcel/exportExcel`,
      method: 'get',
      params:data,
      responseType: 'blob'
    }).then(response => { 
      console.log(response,'res')
      // 处理返回的文件流
      const blob = response.data
      if ((blob && blob.size === 0) || response.size == 0) {
        Message.warning('内容为空，无法下载')
        return
      }
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      const contentDisposition = response.headers['content-disposition'];
      const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1));
      link.download = '订单数据导出'+ fileName;
      document.body.appendChild(link)
      link.click()
      window.setTimeout(function() {
        URL.revokeObjectURL(blob)
        document.body.removeChild(link)
      }, 0)
    })
  }