<template>
  <div class="qualification-result-root">
    <template v-if="status === IConst_State.Fail">
      <div class="pic-wrap">
        <el-image :src="failIcon" />
      </div>
      <span class="big-tip">资质审核不通过</span>

      <div class="dismissed-reason-layout">
        <span class="tip">驳回原因</span>
        <div class="text">
          {{ reason }}
        </div>
      </div>

      <div class="bottom-btn-layout">
        <el-button @click="handleChangeInfo">修改信息</el-button>
      </div>
    </template>
    <div class="info-wrap">
      <InfoDetail :disabled="true" />
      <div class="bottom-btn-layout" v-if="status === IConst_State.Sucess">
        <el-button @click="handleChangeInfo">修改信息</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import InfoDetail from "./children/infoDetail.vue";
const IConst_Fail_Icon = require("@/assets/zz/fail-icon.png");

import { IConst_State } from "./util/ViewHelp";

export default {
  components: { InfoDetail },
  data() {
    return {
      IConst_State: IConst_State,
      failIcon: IConst_Fail_Icon,
    };
  },
  computed: {
    status() {
      const status = this.$store.state.qualification.detail
        ? this.$store.state.qualification.detail.status
        : "";
      return status;
    },
    reason() {
      const reason = this.$store.state.qualification.detail
        ? this.$store.state.qualification.detail.remarks
        : "";
      return reason;
    },
  },
  methods: {
    handleChangeInfo() {
      this.$emit("change-info");
    },
  },
};
</script>

<style scoped lang="scss">
.qualification-result-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 1012px; //固定
  margin: 0 auto;

  .pic-wrap {
    margin-top: 40px;
    width: 80px;
    height: 80px;
  }

  .big-tip {
    margin-top: 24px;
    font-size: 24px;
    font-weight: 500;
    color: #262626;
  }

  .dismissed-reason-layout {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    .tip {
      font-size: 14px;
      font-weight: 400;
      color: #262626;
    }

    .text {
      margin-top: 8px;
      width: 648px;
      background: rgba(0, 0, 0, 0.04);
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      font-size: 14px;
      font-weight: 400;
      color: #8c8c8c;

      padding: 8px 16px 40px 16px;
      box-sizing: border-box;
      line-height: 22px;
    }
  }

  /**按钮层 */
  .bottom-btn-layout {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    ::v-deep .el-button {
      width: 120px;

      & + .el-button {
        margin-left: 12px;
      }
    }

    ::v-deep .el-button--danger {
      background-color: #f5222d;
      border-color: #f5222d;

      &:hover {
        background: #f78989;
        border-color: #f78989;
      }
    }
  }

  .info-wrap {
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
}
</style>
