var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "listContent" },
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          class: ["goodList", { actived: item.checked }],
          on: {
            click: function ($event) {
              return _vm.selectGoods(index)
            },
          },
        },
        [
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: item.checked,
                expression: "item.checked",
              },
            ],
            staticClass: "activeIcon el-icon-success",
          }),
          _c(
            "div",
            { staticClass: "img-div" },
            [
              _c("el-image", {
                staticClass: "l",
                attrs: { src: item.image, alt: "" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "r" }, [
            _c("div", [
              _c("span", { staticClass: "txt-line-1 name" }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ]),
            _c("div", [
              _c("span", { staticClass: "rr txt-line-1" }, [
                _vm._v(_vm._s(item.specification)),
              ]),
            ]),
            _c("div", [
              _c("span", { staticClass: "rr txt-line-1" }, [
                _vm._v(_vm._s(item.approvalNumber)),
              ]),
            ]),
            _c("div", [
              _c("span", { staticClass: "rr txt-line-1" }, [
                _vm._v(_vm._s(item.factory)),
              ]),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }