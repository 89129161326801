var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mainView2" }, [
    _c(
      "div",
      { ref: "goodsSalesStatMain", staticClass: "goodsSalesStatMain" },
      [
        _c(
          "div",
          { staticClass: "flexLineView mainHeight" },
          [
            _c(
              "div",
              { staticClass: "dropDownBtn" },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-left": "10px",
                      color: "#262626",
                      "font-size": "16px",
                      "font-weight": "600",
                    },
                  },
                  [
                    _vm._v("采购排名"),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#595959",
                          "font-size": "12px",
                          "font-weight": "400",
                          "padding-left": "10px",
                        },
                      },
                      [_vm._v("  数据更新时间" + _vm._s(_vm.timeStr))]
                    ),
                  ]
                ),
                _c("el-autocomplete", {
                  staticStyle: {
                    "margin-left": "10px",
                    width: "180px",
                    position: "relative",
                    top: "2px",
                  },
                  attrs: {
                    size: "mini",
                    "fetch-suggestions": _vm.querySearchAsync,
                    placeholder: "客户名称",
                  },
                  on: { blur: _vm.blurAuto, select: _vm.handleSelect },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: item.pay_shop_name,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "name",
                                  staticStyle: {
                                    overflow: "hidden",
                                    "white-space": "nowrap",
                                    "text-overflow": "ellipsis",
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.pay_shop_name) + " ")]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.state,
                    callback: function ($$v) {
                      _vm.state = $$v
                    },
                    expression: "state",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-table",
              {
                ref: "hilightTable",
                staticStyle: { width: "100%" },
                attrs: {
                  data:
                    _vm.tableDataChange.length != 0
                      ? _vm.tableDataChange
                      : _vm.procureTableData,
                  "highlight-current-row": true,
                  height: _vm.tableHeight - 38,
                },
                on: { "row-click": _vm.handleCurrentChange },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "rn", label: "排名", width: "50" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "shop_type_name", label: "客户类别" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "pay_shop_name", label: "客户名称" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "erp_id", label: "ERPID", width: "100" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "amount", label: "采购额(元)" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "cnt", label: "订单数" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "numberView" }, [
              _c("img", {
                staticClass: "numberImg",
                attrs: { src: _vm.line_1, alt: "" },
              }),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "lineView" }),
        _c(
          "div",
          { staticClass: "flexLineView mainHeight" },
          [
            _vm._m(0),
            _c(
              "el-table",
              {
                ref: "hilightTable2",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableDataPurchase,
                  "highlight-current-row": "",
                  height: _vm.tableHeight - 38,
                },
                on: { "row-click": _vm.handleCurrentChangeSecond },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "rn", label: "排名", width: "50" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "goods_name", label: "药品名称" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "goods_factory", label: "生产企业" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "qty", label: "销售数量", width: "100" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "amount", label: "销售金额(元)" },
                }),
                _c("el-table-column", {
                  attrs: { label: "销售占比", width: "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "percentView" }, [
                            _c("div", {
                              staticClass: "percentBg",
                              style: {
                                width: _vm.getPercent(scope.row.pct) + "%",
                              },
                            }),
                            _c("div", { staticClass: "percentText" }, [
                              _vm._v(
                                _vm._s(_vm.getPercent(scope.row.pct)) + "%"
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("div", { staticClass: "numberView" }, [
              _c("img", {
                staticClass: "numberImg",
                attrs: { src: _vm.line_2, alt: "" },
              }),
            ]),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        ref: "goodsSalesStatMain2",
        staticClass: "goodsSalesStatMain goodsSalesStatMain2",
      },
      [
        _c("div", { staticClass: "flexLineView mainHeight" }, [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "leftHalf" },
            [_c("highcharts", { attrs: { options: _vm.lineOptions } })],
            1
          ),
          _c("div", { staticClass: "numberView" }, [
            _c("img", {
              staticClass: "numberImg",
              attrs: { src: _vm.line_3, alt: "" },
            }),
          ]),
        ]),
        _c("div", { staticClass: "lineView" }),
        _c("div", { staticClass: "flexLineView mainHeight" }, [
          _vm._m(2),
          _c(
            "div",
            { staticClass: "leftHalf" },
            [_c("highcharts", { attrs: { options: _vm.lineOptions2 } })],
            1
          ),
          _c("div", { staticClass: "numberView" }, [
            _c("img", {
              staticClass: "numberImg",
              attrs: { src: _vm.line_3, alt: "" },
            }),
          ]),
        ]),
        _c("div", { staticClass: "lineView" }),
        _c(
          "div",
          { staticClass: "flexLineView mainHeight" },
          [
            _vm._m(3),
            _c("div", { staticClass: "numberView" }, [
              _c("img", {
                staticClass: "numberImg",
                attrs: { src: _vm.line_3, alt: "" },
              }),
            ]),
            _c(
              "el-table",
              {
                ref: "hilightTable3",
                staticStyle: { width: "100%" },
                attrs: {
                  data: !_vm.procureTableData ? [] : _vm.tableDataSameDistrict,
                  "highlight-current-row": "",
                  height: _vm.tableHeight - 38,
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "goods_name",
                    label: "药品名称",
                    fixed: "left",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "goods_factory", label: "生产企业" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "qty", label: "销售数量", width: "100" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "amount", label: "销售金额(元)" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropDownBtn" }, [
      _c(
        "span",
        {
          staticStyle: {
            "margin-left": "10px",
            color: "#262626",
            "font-size": "16px",
            "font-weight": "600",
          },
        },
        [_vm._v(" 采购金额 ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropDownBtn" }, [
      _c(
        "span",
        {
          staticStyle: {
            "margin-left": "10px",
            color: "#262626",
            "font-size": "16px",
            "font-weight": "600",
          },
        },
        [_vm._v(" 采购时段(时) ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropDownBtn" }, [
      _c(
        "span",
        {
          staticStyle: {
            "margin-left": "10px",
            color: "#262626",
            "font-size": "16px",
            "font-weight": "600",
          },
        },
        [_vm._v(" 采购频次(次) ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropDownBtn" }, [
      _c(
        "span",
        {
          staticStyle: {
            "margin-left": "10px",
            color: "#262626",
            "font-size": "16px",
            "font-weight": "600",
          },
        },
        [_vm._v(" 同区客户同期采购 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }