var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "classification",
    },
    [
      _c("Tabs", {
        attrs: { tabsList: _vm.tabsList, activeName: _vm.activeName },
        on: {
          "update:activeName": function ($event) {
            _vm.activeName = $event
          },
          "update:active-name": function ($event) {
            _vm.activeName = $event
          },
          "tab-click": _vm.handleChange,
        },
      }),
      _vm.activeName == "1"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "flex-box" },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { width: "calc(100% - 60px)" },
                    attrs: { gutter: 16 },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { size: "small", placeholder: "一级分类" },
                            on: { change: _vm.changeOneClassId },
                            model: {
                              value: _vm.pageObj.page.goodsOneClassId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.pageObj.page,
                                  "goodsOneClassId",
                                  $$v
                                )
                              },
                              expression: "pageObj.page.goodsOneClassId",
                            },
                          },
                          _vm._l(_vm.goodsOneClassIdOptions, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "small",
                              placeholder: "二级分类",
                              clearable: "",
                            },
                            on: { change: _vm.changeTwoClassId },
                            model: {
                              value: _vm.pageObj.page.goodsTwoClassId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.pageObj.page,
                                  "goodsTwoClassId",
                                  $$v
                                )
                              },
                              expression: "pageObj.page.goodsTwoClassId",
                            },
                          },
                          _vm._l(_vm.goodsTwoClassIdOptions, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "small",
                              placeholder: "三级分类",
                              clearable: "",
                            },
                            model: {
                              value: _vm.pageObj.page.goodsThreeClassId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.pageObj.page,
                                  "goodsThreeClassId",
                                  $$v
                                )
                              },
                              expression: "pageObj.page.goodsThreeClassId",
                            },
                          },
                          _vm._l(_vm.goodsThreeClassIdOptions, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "生产厂商",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.pageObj.page.factory,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageObj.page, "factory", $$v)
                            },
                            expression: "pageObj.page.factory",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "商品名称",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.pageObj.page.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageObj.page, "name", $$v)
                            },
                            expression: "pageObj.page.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.selectGoodsNounion(1)
                              },
                            },
                          },
                          [_vm._v("检索并选择商品")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      float: "right",
                      "margin-left": "32px",
                    },
                  },
                  [
                    _vm.btnType == "show"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.addList.length == 0,
                              size: "small",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.bathUpdateByProduct(1)
                              },
                            },
                          },
                          [_vm._v("保存")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          disabled: _vm.tableData.length == 0,
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.correlation()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "全部" +
                            _vm._s(_vm.btnType == "show" ? "" : "取消") +
                            "关联"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { ref: "tableCont", staticClass: "contentCont" }, [
              _c(
                "div",
                { staticClass: "contentInner" },
                _vm._l(_vm.tableData, function (item, index) {
                  return _c("GoodsList", {
                    key: index,
                    attrs: { item: item },
                    on: { addGunalian: _vm.addGunalian },
                  })
                }),
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "el-page" },
              [
                _c("Pagination", {
                  attrs: {
                    total: _vm.pageObj.total,
                    page: _vm.pageObj.page.current,
                    limit: _vm.pageObj.page.size,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.pageObj.page, "current", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.pageObj.page, "size", $event)
                    },
                    pagination: _vm.changeSize,
                  },
                }),
              ],
              1
            ),
          ])
        : _c(
            "div",
            { staticStyle: { padding: "10px 0px" } },
            [
              _c("el-cascader", {
                staticStyle: { width: "500px" },
                attrs: {
                  options: _vm.options,
                  "collapse-tags": "",
                  props: _vm.props,
                  clearable: "",
                },
                on: { change: _vm.changeNode },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }