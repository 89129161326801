var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent shopingcart" },
    [
      _c("div", { staticClass: "header" }, [_vm._v("加入药品")]),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c(
            "div",
            {
              staticClass: "left_topic mb20",
              staticStyle: { "margin-top": "10px" },
            },
            [_vm._v("基本信息")]
          ),
          _c("el-divider"),
          _c("div", { staticClass: "produceDetail" }, [
            _c(
              "div",
              { staticClass: "shopImg" },
              [
                _c("el-image", {
                  staticStyle: { width: "100px", height: "100px" },
                  attrs: { src: _vm.shopDetail.image || _vm.avater },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "introduce" }, [
              _c("div", { staticClass: "list name" }, [
                _vm._v(_vm._s(_vm.shopDetail.name)),
              ]),
              _c("div", { staticClass: "list factory mb20" }, [
                _vm._v(_vm._s(_vm.shopDetail.factory)),
              ]),
              _c("div", { staticClass: "list factory" }, [
                _vm._v(_vm._s(_vm.shopDetail.approvalNumber)),
              ]),
            ]),
          ]),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("商品价格")]),
          _c(
            "el-form",
            {
              ref: "shopingcart",
              attrs: {
                model: _vm.formTool,
                "label-position": "top",
                size: "small",
              },
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.formTool.SKUlist },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsSkuName",
                      label: "SKU名称",
                      "min-width": "160",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "SKUlist." + scope.$index + ".goodsSkuName",
                                  rules: {
                                    required: true,
                                    message: "请填写SKU名称",
                                    trigger: "blur",
                                  },
                                  "label-width": "0",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请填写SKU名称" },
                                  model: {
                                    value: scope.row.goodsSkuName,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "goodsSkuName", $$v)
                                    },
                                    expression: "scope.row.goodsSkuName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsSkuUnit",
                      label: "单位",
                      "min-width": "160",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { display: "flex" },
                                attrs: {
                                  prop:
                                    "SKUlist." + scope.$index + ".goodsSkuUnit",
                                  rules: {
                                    required: true,
                                    message: "单位",
                                    trigger: "blur",
                                  },
                                  "label-width": "0",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "50%" },
                                  attrs: {
                                    maxlength: "4",
                                    disabled: "",
                                    placeholder: "单位",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.changMoney(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.goodsSkuUnit,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "goodsSkuUnit", $$v)
                                    },
                                    expression: "scope.row.goodsSkuUnit",
                                  },
                                }),
                                _c(
                                  "el-link",
                                  {
                                    staticStyle: { "margin-left": "16px" },
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.choiceUnit(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("选择单位")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "medicineCode",
                      label: "第三方商品编码",
                      "min-width": "160",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "SKUlist." + scope.$index + ".medicineCode",
                                  rules: {
                                    required: true,
                                    message: "请填写第三方商品编码",
                                    trigger: "blur",
                                  },
                                  "label-width": "0",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请填写第三方商品编码",
                                  },
                                  model: {
                                    value: scope.row.medicineCode,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "medicineCode", $$v)
                                    },
                                    expression: "scope.row.medicineCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "salePrice",
                      label: "零售价/元",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "SKUlist." + scope.$index + ".salePrice",
                                  rules: {
                                    required: true,
                                    message: "请填写零售价",
                                    trigger: "blur",
                                  },
                                  "label-width": "0",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: !_vm.edit,
                                    placeholder: "请填写零售价",
                                  },
                                  model: {
                                    value: scope.row.salePrice,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "salePrice", $$v)
                                    },
                                    expression: "scope.row.salePrice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "marketPrice",
                      label: "市场价/元",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "SKUlist." + scope.$index + ".marketPrice",
                                  rules: {
                                    required: true,
                                    message: "请填写市场价",
                                    trigger: "blur",
                                  },
                                  "label-width": "0",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请填写市场价" },
                                  model: {
                                    value: scope.row.marketPrice,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "marketPrice", $$v)
                                    },
                                    expression: "scope.row.marketPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "purchasePrice",
                      label: "批发价/元",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "SKUlist." +
                                    scope.$index +
                                    ".purchasePrice",
                                  rules: {
                                    required: true,
                                    message: "请填写批发价",
                                    trigger: "blur",
                                  },
                                  "label-width": "0",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: !_vm.edit,
                                    placeholder: "请填写批发价",
                                  },
                                  model: {
                                    value: scope.row.purchasePrice,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "purchasePrice", $$v)
                                    },
                                    expression: "scope.row.purchasePrice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "startWholesale",
                      label: "起批量",
                      "min-width": "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "SKUlist." +
                                    scope.$index +
                                    ".startWholesale",
                                  rules: {
                                    required: true,
                                    message: "请填写起批量",
                                    trigger: "blur",
                                  },
                                  "label-width": "0",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: !_vm.edit,
                                        placeholder: "请填写起批量",
                                      },
                                      model: {
                                        value: scope.row.startWholesale,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "startWholesale",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.startWholesale",
                                      },
                                    }),
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: { disabled: !_vm.edit },
                                        model: {
                                          value: scope.row.useTimes,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "useTimes", $$v)
                                          },
                                          expression: "scope.row.useTimes",
                                        },
                                      },
                                      [_vm._v("倍数")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsSkuInventory",
                      label: "库存",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "SKUlist." +
                                    scope.$index +
                                    ".goodsSkuInventory",
                                  rules: {
                                    required: true,
                                    message: "请填写库存",
                                    trigger: "blur",
                                  },
                                  "label-width": "0",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请填写库存" },
                                  model: {
                                    value: scope.row.goodsSkuInventory,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "goodsSkuInventory",
                                        $$v
                                      )
                                    },
                                    expression: "scope.row.goodsSkuInventory",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "expireTime",
                      label: "有效期至",
                      width: "210",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "SKUlist." + scope.$index + ".expireTime",
                                  rules: {
                                    required: true,
                                    message: "请填写有效期至",
                                    trigger: "blur",
                                  },
                                  "label-width": "0",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    type: "datetime",
                                    placeholder: "选择日期",
                                  },
                                  model: {
                                    value: scope.row.expireTime,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "expireTime", $$v)
                                    },
                                    expression: "scope.row.expireTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "producedTime",
                      label: "生产日期",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "SKUlist." + scope.$index + ".producedTime",
                                  "label-width": "0",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "140px" },
                                  attrs: {
                                    type: "date",
                                    "value-format": "yyyy-MM-dd",
                                    placeholder: "选择日期",
                                  },
                                  model: {
                                    value: scope.row.producedTime,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "producedTime", $$v)
                                    },
                                    expression: "scope.row.producedTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.formTool.SKUlist.length != 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "20px",
                                      color: "#F6212D",
                                      "font-size": "14px",
                                    },
                                    attrs: {
                                      disabled: !_vm.edit,
                                      type: "text",
                                      size: "mini",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.deleteRow(
                                          scope.$index,
                                          _vm.formTool.SKUlist
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 移除 ")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm.edit
                ? _c(
                    "div",
                    {
                      staticClass: "addGoods",
                      on: {
                        click: function ($event) {
                          return _vm.addRow()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "addFont" }, [_vm._v(" + ")]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            color: "#595959",
                          },
                        },
                        [_vm._v(" 添加商品 ")]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt20", staticStyle: { "margin-bottom": "24px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.comfirm },
                },
                [_vm._v("提交并加入仓库")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("UnitDialog", {
        attrs: {
          "unit-list": _vm.unitList,
          "dialog-visible": _vm.dialogVisible,
        },
        on: { submitCheck: _vm.submitCheck },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }