var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c("div", { staticClass: "header" }, [_vm._v("企业资质审核明细")]),
    _c(
      "div",
      { staticClass: "center" },
      [
        _vm.btnShow == "1"
          ? _c("AuditCompanyInfo", {
              ref: "auditCompanyInfo",
              attrs: { mode: _vm.mode },
            })
          : _c("CompanyInfo", { ref: "infoBox" }),
        _vm.btnShow == "1"
          ? _c(
              "div",
              { staticClass: "status_txt" },
              [
                _vm._v(" 当前审核状态: "),
                _c("MyTypes", { attrs: { type: _vm.status } }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "dia_btn" },
          [
            _c("MyButton", {
              staticClass: "btn_w150",
              attrs: { type: "", icon: "", text: "返回上一页" },
              on: { click: _vm.goBack },
            }),
            _vm.btnShow == "1" && _vm.mode == "view"
              ? _c("MyButton", {
                  staticClass: "btn_w150",
                  attrs: { type: "primary", icon: "", text: "审核通过" },
                  on: {
                    click: function ($event) {
                      return _vm.openDialog(true)
                    },
                  },
                })
              : _vm._e(),
            _vm.btnShow == "1" && _vm.mode == "view"
              ? _c("MyButton", {
                  staticClass: "btn_w150",
                  attrs: { type: "primary", icon: "", text: "驳回" },
                  on: {
                    click: function ($event) {
                      return _vm.openDialog(false)
                    },
                  },
                })
              : _vm._e(),
            _vm.btnShow == "1" &&
            _vm.mode == "view" &&
            _vm.permissions.audit_detail_edit
              ? _c("MyButton", {
                  staticClass: "btn_w150",
                  attrs: { type: "primary", icon: "", text: "编辑" },
                  on: {
                    click: function ($event) {
                      return _vm.edit()
                    },
                  },
                })
              : _vm._e(),
            _vm.btnShow == "1" &&
            _vm.mode == "edit" &&
            _vm.permissions.audit_detail_edit
              ? _c("MyButton", {
                  staticClass: "btn_w150",
                  attrs: { type: "primary", icon: "", text: "取消编辑" },
                  on: {
                    click: function ($event) {
                      return _vm.cancel()
                    },
                  },
                })
              : _vm._e(),
            _vm.btnShow == "1" &&
            _vm.mode == "edit" &&
            _vm.permissions.audit_detail_edit
              ? _c("MyButton", {
                  staticClass: "btn_w150",
                  attrs: { type: "primary", icon: "", text: "确认调整" },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("Success", { ref: "sucDom", on: { sure: _vm.sucSubmit } }),
        _c("Err", { ref: "errDom", on: { sure: _vm.errSubmit } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }