import request from '@/utils/request'
import { Message } from 'element-ui'

// 新增供应商
// export function yccbSupplierAdd(data) {
//   return request({
//     url: `/product/supplier`,
//     method: "post",
//     data
//   });
// }

// 商铺审核
export function auditCompanyEdit(data) {
    return request({
        url: `/shop/shop/audit`,
        method: "put",
        data
    });
}

// 根据状态商铺列表查询
export function auditCompanyList(data) {
    return request({
        url: `/shop/shop/page`,
        method: "get",
        params: data
    });
}

// 日志详情
export function shopHistoryDetails(id) {
  return request({
      url: `shop/shop/history/details/${id}`,
      method: "get"
  });
}

// 商铺详情查询
export function auditCompanyDetail(id) {
    return request({
        url: `/shop/shop/details/${id}`,
        method: "get"
    });
}

// 商铺详情查询
export function updateAudit(data) {
    return request({
        url: `/shop/shop/update`,
        method: "put",
        data
    });
}

// 机构列表查询
export function deptTree() {
    return request({
        // url: `/admin/dept/tree`,
        // url: 'admin/organization/organizationtree',
        url: 'shop/organization/organizationtree',
        method: "get"
    });
}

// 商品审核查询
export function goodsCheckPages(data) {
    return request({
        url: '/product/goodsbase/check/page',
        method: "get",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: data
    });
}

// 商品审批确认
export function goodsCheckSure(data) {
  return request({
    url: '/product/goodsbase/check/enter',
    method: "put",
    data
  });
}

// 平台售后订单
export function aftersalePlatformPage(data) {
  return request({
    url: '/order/aftersale/platform/page',
    method: "get",
    params: data
  });
}

// 平台同意售后订单
export function aftersalePlatformrReject(data) {
  return request({
    url: '/order/aftersale/platform/reject',
    method: "put",
    data
  });
}
// 平台驳回售后订单
export function aftersalePlatformrAgree(data) {
  return request({
    url: '/order/aftersale/platform/agree',
    method: "put",
    data
  });
}

// 敏感词库分页查询
export function sensitivethesaurusPage(data) {
  return request({
    url: '/product/sensitivethesaurus/page',
    method: "get",
    params:data
  });
}

// 新增敏感词库
export function sensitivethesaurus(data) {
  return request({
    url: '/product/sensitivethesaurus',
    method: "post",
    data
  });
}
// 重置密码
export function resetPassword(data) {
  return request({
    url: '/member/manage/reset/password',
    method: "put",
    data
  });
}
// 通过id删除敏感词库
export function sensitivethesaurusRemove(data) {
  return request({
    url: '/product/sensitivethesaurus/batch/remove',
    method: "put",
    data
  });
}

// 根据业务编号获取审核记录
export function auditlog(data) {
  return request({
    url: `/shop/auditlog/${data}`,
    method: "get"
  });
}

// 商铺资质下载
export function downloadShopcertify(data) {
  return request({
    url: `/shop/shopcertify/downloadNew/${data}`,
    method: 'get',
    // responseType: 'blob'
  }).then(response => {
    // console.log(response)
    window.open(response.data)
    // 处理返回的文件流
    // const blob = response.data
    // if (blob && blob.size === 0) {
    //   Message.warning('内容为空，无法下载')
    //   return
    // }
    // const link = document.createElement('a')
    // link.href = URL.createObjectURL(blob)
    // const contentDisposition = response.headers['content-disposition'];
    // const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1));
    // console.log(fileName)
    // link.download = fileName;
    // document.body.appendChild(link)
    // link.click()
    // window.setTimeout(function() {
    //   URL.revokeObjectURL(blob)
    //   document.body.removeChild(link)
    // }, 0)
  })
}