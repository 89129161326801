<!--  -->
<template>
  <div class="classification">
    <div class="header">
      <!-- <span class="font">控销产品线</span> -->
      <el-form ref="formTool" :model="formTool" :inline="true" style="text-align: left">
        <el-form-item prop="name" style="margin: 0px !important;" label="">
          <el-input v-model="formTool.name" placeholder="请输入产品线名称" clearable size="small" />
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="clickSearch(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="reset()">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" v-if="permissions.sales_puduct_role_add" @click="addProductLine()">添加</el-button>
        </el-form-item>
      </el-form>
      <el-button v-if="permissions.excCooperationControlSalesSQL" size="small" type="primary"
        @click="addCooperationGoods()">创建合作控销产品</el-button>
    </div>

    <div class="center" style="position: relative">
      <MyTable :table-data="tableData" :index="index" :selection="selection" :page="page" :operation="operation"
        :table-option.sync="tableOption" @page-change="getList" @handleSelectionChange="handleSelectionChange">
        <template slot="className" slot-scope="scope">
          <el-row v-if="scope.row.productLineGoodsDTO">
            {{ scope.row.productLineGoodsDTO.className }}
          </el-row>
        </template>

        <template slot="image" slot-scope="scope">
          <el-row>
            <el-col style="width:80px">
              <el-image :src="scope.row.productLineGoodsDTO &&
                  scope.row.productLineGoodsDTO.goodsImg
                  ? scope.row.productLineGoodsDTO.goodsImg
                  : avater
                " lazy style="width: 80px; height: 80px" />
            </el-col>
            <el-col v-if="scope.row.productLineGoodsDTO" style="width:calc(100% - 90px);margin-left:10px">
              <!-- <el-row> {{scope.productLineGoodsDTO.name}} </el-row> -->
              <el-row> {{ scope.row.productLineGoodsDTO.goodsName }} </el-row>
              <el-row>
                {{ scope.row.productLineGoodsDTO.goodsSpecification }}
              </el-row>
              <el-row>
                {{ scope.row.productLineGoodsDTO.goodsFactory }}
              </el-row>
              <el-row>
                {{ scope.row.productLineGoodsDTO.goodsApprovalNumber }}
              </el-row>
            </el-col>
          </el-row>
        </template>

        <template slot="menu" slot-scope="scope">
          <div class="btn-warp">
            <el-link
              :underline="false"
              type="primary"
              @click="editHandle(scope.row)"
              >编辑</el-link
            >
            <el-link :underline="false" v-if="permissions.sales_puduct_price_delete" type="primary"
              @click="deleteHandle(scope.row)">删除</el-link>
            <el-link :underline="false"  type="primary"
              @click="associationHandle(scope.row)">控销策略</el-link>
            <el-link v-if="permissions.sales_puduct_price_add" :underline="false" type="primary"
              @click="priceHandle(scope.row)">控销定价</el-link>
          </div>
        </template>
      </MyTable>
    </div>
    <goodsDialog :dialogShow="dialogShow" @didSelectItem="didSelectItem" />
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import goodsDialog from "./components/cooperationControlSalesGoodsDialog.vue";
import { mapGetters } from 'vuex'
import { excSelfControlSQL } from "@/api/shopmange";
import {
  getProductLines,
  soldout,
  getQuantityApi,
  deleteProductLine,
} from "@/api/saleRule/productLine";
import { regionAreaTree } from "@/api/saleRule/saleF";
import avater from "/public/img/default.png";
import lodash from "lodash";
export default {
  components: { MyTable, goodsDialog },
  data() {
    return {
      avater: avater,
      formTool: {
        name: "",
      },
      selectionList: [],
      operation: true,
      selection: false,
      index: true,
      tableData: [],
      tableOption: [
        { label: "产品线名称", prop: "name" },
        { label: "产品线负责人", prop: "managerName" },
        { label: "控销产品", prop: "image", slot: true, minWidth: '210px' }, // 这里表示自定义列
        { label: "控销分类", prop: "className", slot: true },
      ],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      dialogShow: false
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getProductLines();
    this.getRegionAreaTree();
  },
  mounted() { },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
  computed: {
    ...mapGetters(['permissions']),
  },
  methods: {
    // 控销策略
    associationHandle(row) {
      this.$router.push({
        path: "/salesControlMange/salesControl-strategy-index",
        query: {
          id: row.id || 0,
        },
      });
    },
    // 控销定价
    priceHandle(row) {
      console.log(row, "row");
      this.$router.push({
        path: "/salesControlMange/salesControl-product-price",
        query: {
          isEditor: true,
          productLineId: row.id || null,
          goodsId: row.productLineGoodsDTO?.goodsId || null,
        },
      });
    },
    // 编辑
    editHandle(row) {
      window.sessionStorage.setItem("podurctItem", JSON.stringify(row));
      this.$router.push({
        path: "/salesControlMange/salesControl-product-add",
        query: {
          isEditor: true,
          id: row.id || 0,
        },
      });
    },
    deleteHandle(row) {
      const params = {
        id: row.id,
      };
      deleteProductLine(params).then((res) => {
        this.$message.success("删除成功");
        this.getList()
      });
    },
    //添加产品线
    addProductLine() {
      this.$router.push({
        path: "/salesControlMange/salesControl-product-add",
        query: {},
      });
    },
    // 详情
    delailHandle(row) {
      this.$router.push({
        path: "/productManage/sale-saleDetail",
        query: row,
      });
      console.log(row);
    },
    // 重置查询条件
    reset() {
      this.formTool = {};
      this.getList();
    },
    // 切换分页
    getList() {
      this.getProductLines();
    },
    // 勾选
    handleSelectionChange(val) {
      this.selectionList = val;
    },
    // 查询产品线
    getProductLines(current) {
      if (current) {
        // 搜索条件改变从第一页开始查询
        this.page.current = current;
      }
      if (this.formTool.name == "") {
        delete this.formTool.name;
      }
      const params = {
        ...this.formTool,
        current: this.page.current,
        size: this.page.size,
        status: 0, //查询非删除的
      };
      getProductLines(params).then((res) => {
        this.tableData = res;
        this.getQuantityApi();
      });
    },
    getQuantityApi() {
      const params = { status: 0 };
      getQuantityApi(params).then((res) => {
        console.log(res);
        this.page.total = parseInt(res);
      });
    },
    // 搜索
    clickSearch(current) {
      this.getProductLines(current);
    },
    // 获取省市区列表
    getRegionAreaTree() {
      let data = {
        level: 3,
      };
      regionAreaTree(data).then((res) => {
        if (+res.code === 0) {
          localStorage.setItem("cityData", JSON.stringify(res.data))
        }
      });
    },
    // 创建合作控销产品
    addCooperationGoods() {
      this.dialogShow = true
    },
    // 选择了合作控销产品之后
    didSelectItem(e) {
      let obj = {
        goodsId: lodash.map(e, 'goodsId')
      }
      excSelfControlSQL(obj).then((rsp) => {
        if (rsp && rsp.code == 0) {
          this.$message.success('执行成功')
          this.getList()
          this.dialogShow = false
        } else {
          this.$message.error(rsp.msg)
        }
      })
    },

  },
};
</script>
<style lang='scss' scoped>
@import "@/styles/variables.scss";

.classification {
  padding: 0px;

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .top {
    display: flex;
    padding: 10px 0 10px 0;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .label {
        width: 120px;
      }

      .button {
        margin-right: 20px;

        ::v-deep .el-button {
          color: $subMenuActiveText;
          background-color: $butColor;
          border-color: $butColor;

          &:hover {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:active {
            color: $subMenuActiveText;
            background: $butColor;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:focus {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
        }
      }
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn-warp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .el-link {
      // margin: 5px;
    }
  }
}
</style>
