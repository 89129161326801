<template>
  <div class="payMain">
    <div style="font-size: 14px;">
      <el-checkbox v-model="onLine" :disabled="true"></el-checkbox> <span style="margin-left:8px">线上支付是否开通</span>
    </div>
    <el-divider></el-divider>
    <div class="normalText">
      <el-checkbox v-model="payType" @change="changeCheckBox">是否使用线下支付</el-checkbox>
      <!-- <el-switch v-model="payType" active-color="#f5222d" inactive-color="#eeeeee" :disabled="boundMoney == 0">
      </el-switch> -->
    </div>
    <el-divider></el-divider>
    <el-form label-position="top" :model="userForm" ref="userForm" :disabled="true" v-if="payType">
      <el-form-item label="收款户名" prop="name">
        <el-input placeholder="请输入" v-model="userForm.name" style="width: 300px;"></el-input>
      </el-form-item>
      <el-form-item label="开户银行" prop="bank">
        <el-input placeholder="请输入" v-model="userForm.bank" style="width: 300px;"></el-input>
      </el-form-item>
      <el-form-item label="收款账户" prop="account">
        <el-input placeholder="请输入" v-model="userForm.account" style="width: 300px;"></el-input>
      </el-form-item>
    </el-form>
    <el-divider v-if="payType"></el-divider>
    <div class="inLine">
      <!-- <el-button type="primary" @click="submit" size="small" :disabled="boundMoney == 0">保存</el-button> -->
      <!-- <div class="infoText">缴纳保证金后才能开启。缴纳保证金会使店铺排序更靠前，获得更多商品曝光。</div> -->
    </div>

    <el-dialog title="开通线下支付" :close-on-click-modal="false" :visible.sync="dialogVisible" width="30%"
      :before-close="handleClose">
      <span>缴纳保证金后才能开启。缴纳保证金会使店铺排序更靠前，获得更多商品曝光。</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false; payType = false" size="small">取消</el-button>
        <el-button type="primary" @click="jiaona" size="small">去缴纳</el-button>
      </span>
    </el-dialog>

    <el-dialog title="开通线下支付" :close-on-click-modal="false" :visible.sync="dialogVisibleForm" width="30%"
      :before-close="handleCloseForm">
      <el-form label-position="right" label-width="80px" :rules="userRules" :model="user" ref="form">
        <el-form-item label="收款户名" prop="name">
          <el-input placeholder="请输入" v-model="user.name"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="bank">
          <el-input placeholder="请输入" v-model="user.bank"></el-input>
        </el-form-item>
        <el-form-item label="收款账户" prop="account">
          <el-input placeholder="请输入" v-model="user.account"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleForm = false; payType = false" size="small">取消</el-button>
        <el-button type="primary" @click="submit" size="small">确认</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import {
  updateOfflinePayment,
  queryOfflinePayment,
} from "@/api/shop";
export default {
  props: {
    boundMoney: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    return {
      onLine: true,
      dialogVisible: false,
      dialogVisibleForm: false,
      payType: false,
      userForm: {
        name: '',
        bank: '',
        account: '',
      },
      user: {
        name: '',
        bank: '',
        account: '',
      },
      userRules: {
        name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        bank: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        account: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },
      saveEnable: false
    }
  },
  async mounted () {
    this.query()


  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.payType = false
    },
    handleCloseForm () {
      this.dialogVisibleForm = false
      this.payType = false
    },
    // 查询是否开启
    async query () {
      const resp = await queryOfflinePayment({ shopId: null }) //accountName: "1"depositBank: "2"id: "1721728830094307330"offlinePaymentFlag: "YES"receivablesAccount: "3"
      if (resp) {
        this.userForm.name = resp.accountName
        this.userForm.bank = resp.depositBank
        this.userForm.account = resp.receivablesAccount
        this.payType = resp.offlinePaymentFlag == 'YES'

      }
    },
    // 去缴纳保证金
    jiaona () {
      this.$router.push('/financialcenter/surety-index')
    },
    // 切换是否开通
    changeCheckBox (val) {
      if (val) {
        if (this.boundMoney != 0) {
          this.user = { ...this.userForm }
          this.dialogVisibleForm = true
        } else {
          this.dialogVisible = true
        }
      } else {
        if (this.boundMoney != 0) {
          this.submitOff()
        }
      }
    },
    async submit () {
      try {
        const valid = await this.$refs.form.validate()
        if (valid) {
          let obj = {
            offlinePaymentFlag: this.payType ? 'YES' : 'NO',//offlinePaymentFlag	是否开启线下支付（YES：开启，NO：未开启）
            accountName: this.user.name,//收款户名
            depositBank: this.user.bank,//开户银行
            receivablesAccount: this.user.account,//收款账号
          }
          const resp = await updateOfflinePayment(obj)
          if (resp) {
            this.$message.success('更新成功')
            this.dialogVisibleForm = false
            this.query()
          }
        }

      } catch (error) {

      }

    },
    async submitOff () {
      try {
        const valid = await this.$refs.userForm.validate()
        if (valid) {
          let obj = {
            offlinePaymentFlag: this.payType ? 'YES' : 'NO',//offlinePaymentFlag	是否开启线下支付（YES：开启，NO：未开启）
            accountName: this.userForm.name,//收款户名
            depositBank: this.userForm.bank,//开户银行
            receivablesAccount: this.userForm.account,//收款账号
          }
          const resp = await updateOfflinePayment(obj)
          if (resp) {
            this.$message.success('更新成功')
            this.query()
          }
        }

      } catch (error) {

      }

    }
  }
}
</script>
<style lang="scss" scoped>
.payMain {
  padding: 24px;

  .normalText {
    font-size: 16px;
    font-weight: 400;
    color: #262626;
    line-height: 1.5;
  }

  .inLine {
    display: flex;
    align-items: center;

    .infoText {
      font-size: 14px;
      font-weight: 500;
      color: orange;
      margin-left: 12px;
    }
  }
}
</style>