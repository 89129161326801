import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
import { message } from "@/utils/resetMessage";
import router from "@/router";
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'http://client:8081', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});
// console.log(https://gateway.cbyyk.com)

// request拦截器
service.interceptors.request.use(
  (config) => {
    // console.log('config', config)
    // do something before request is sent
    // console.log(config.headers["isLogin"])
    if (!config.headers["isLogin"]) {
      config.headers["Authorization"] = "Bearer " + getToken();
    }
    // "Bearer pig::13211111114::77de92f0-2a2c-421a-9c3a-ee1f1186a1c1"
    // "Bearer cbwx_mall::13422122004::a207833a-d8a2-4d4c-9acd-0d1e0fb1bd34";
    // config.headers['Content-Type'] = 'multipart/json'
    // if (store.getters.token) {
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation
    //   config.headers["X-Token"] = getToken();
    // }
    // if (config['contentType'] === 'blob') {
    //   config.headers['Content-Type'] = 'multipart/form-data'
    // }
    // console.log(config.headers, 333)
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response;
    // if the custom code is not 20000, it is judged as an error.
    if (res.status != 200) {
      // Message({
      //     message: res.msg || res ||  "Error",
      //     type: "error",
      //     duration: 5 * 1000,
      // });
      message.error(res.msg || res || "Error");
      return Promise.reject(new Error(res.msg || "Error"));
    } else {
      if (res.data.code == -1) {
        // to re-login
        MessageBox.confirm(
          "登录状态已过期，您可以继续留在该页面，或者重新登录",
          "系统提示",
          {
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          //   store.dispatch("user/resetToken").then(() => {
          //       location.reload();
          //       return
          //   });
          store.dispatch("user/logout").then(() => {
            router.push({ path: "/login" });
          });
        });
      }

      if (res.data.code && res.data.code > 0) {
        if (
          res.data.code == 20007 ||
          res.data.code == 20010 ||
          res.data.code == 20011 ||
          res.data.code == 20008
        ) {
          return res.data;
        } else {
          if (res.data.msg) {
            // Message({
            //     message: res.data.msg || '',
            //     type: "error",
            //     duration: 5 * 1000,
            // });
            message.error(res.data.msg || "");
            return Promise.reject("");
          }
        }
      }
      if (res.headers["content-disposition"]) {
        return res;
      } else {
        return res.data;
      }
    }
  },
  (error) => {
    // 500 401 400
    console.log(error);
    if (error && error.response && error.response.data.msg) {
      // Message({
      //     message: error.response.data.msg || error,
      //     type: "error",
      //     duration: 5 * 1000,
      // });
      message.error(error.response.data.msg || error);
      return Promise.reject("Error");
    } else {
      return Promise.reject(error);
    }
  }
);

//导出文件
export function exportFile(url, params) {
  service({
    url: url,
    method: "GET",
    params,
    responseType: "blob",
  }).then((response) => {
    const blob = response.data;
    if (blob && blob.size === 0) {
      Message.warning("内容为空，无法下载");
      return;
    }
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    const contentDisposition = response.headers["content-disposition"];
    const fileName = decodeURIComponent(
      contentDisposition.substring(contentDisposition.indexOf("=") + 1)
    );
    console.log(fileName);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    window.setTimeout(function () {
      URL.revokeObjectURL(blob);
      document.body.removeChild(link);
      Message.success("导出成功");
    }, 0);
  });
}

export default service;
