var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "ruleFormCont" },
        [
          _vm._l(_vm.nallTypes, function (ul, index) {
            return [
              ul.show
                ? _c(
                    "div",
                    {
                      key: index,
                      staticStyle: { "margin-bottom": "30px" },
                      attrs: { "label-width": "800px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "800px",
                            "margin-bottom": "10px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  ul.type != "09" && ul.require
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "margin-right": "10px",
                                          },
                                        },
                                        [_vm._v("*")]
                                      )
                                    : _vm._e(),
                                  _vm._v(_vm._s(ul.name) + " "),
                                  _vm.showViewer
                                    ? _c("el-image-viewer", {
                                        attrs: {
                                          "on-close": _vm.closeViewer,
                                          "url-list": [_vm.url],
                                        },
                                      })
                                    : _vm._e(),
                                  ul.type != "09" && ul.type != "10"
                                    ? _c(
                                        "el-link",
                                        {
                                          staticStyle: {
                                            "margin-left": "15px",
                                            "font-size": "14px",
                                          },
                                          attrs: {
                                            type: "primary",
                                            underline: false,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.look(ul.type)
                                            },
                                          },
                                        },
                                        [_vm._v("查看示例 ")]
                                      )
                                    : _vm._e(),
                                  ul.type == "06" ||
                                  (_vm.code == "3" && ul.type == "08")
                                    ? _c(
                                        "el-link",
                                        {
                                          staticStyle: {
                                            "margin-left": "15px",
                                            "font-size": "14px",
                                          },
                                          attrs: {
                                            type: "primary",
                                            underline: false,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickDown(ul.type)
                                            },
                                          },
                                        },
                                        [_vm._v("下载模板 ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "red",
                            "font-size": "14px",
                            "padding-bottom": "5px",
                          },
                        },
                        [
                          ul.type == "01"
                            ? _c("span", [
                                _vm._v(
                                  " 营利性医疗机构必须上传营业执照，复印件请加盖鲜章 "
                                ),
                              ])
                            : _vm._e(),
                          ul.type == "04" ||
                          ul.type == "03" ||
                          ul.type == "02" ||
                          ul.type == "07" ||
                          ul.type == "05"
                            ? _c("span", [_vm._v(" 复印件加盖鲜章 ")])
                            : _vm._e(),
                        ]
                      ),
                      _c("div", { staticClass: "uploadCont" }, [
                        _c(
                          "div",
                          { staticClass: "imgList" },
                          [
                            _vm._l(
                              _vm.infoFormTwo[ul.keyImg],
                              function (li, i) {
                                return _c(
                                  "div",
                                  { key: i, staticClass: "imgList_li" },
                                  [
                                    _c("el-image", {
                                      staticClass: "imgList_li_img",
                                      attrs: {
                                        src: li,
                                        "preview-src-list":
                                          _vm.infoFormTwo[ul.keyImg],
                                      },
                                    }),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "imgList_li_close",
                                        on: {
                                          click: function ($event) {
                                            return _vm.imgDel(ul.keyImg, i)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "imgList_li imgList_li_add",
                                on: {
                                  click: function ($event) {
                                    return _vm.goUpload([ul.keyImg], ul.type)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-plus" })]
                            ),
                          ],
                          2
                        ),
                        ul.desc
                          ? _c("div", { staticClass: "desc" }, [
                              _vm._v(_vm._s(ul.desc)),
                            ])
                          : _vm._e(),
                        ul.type == "09"
                          ? _c(
                              "div",
                              { staticClass: "more_input" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "more_p",
                                    staticStyle: { "margin-bottom": "5px" },
                                  },
                                  [_vm._v("备注")]
                                ),
                                _c("el-input", {
                                  attrs: { type: "txtarea" },
                                  model: {
                                    value: _vm.infoFormTwo.remarks,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.infoFormTwo, "remarks", $$v)
                                    },
                                    expression: "infoFormTwo.remarks",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          }),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "30px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitFormTwo },
                },
                [_vm._v("下一步")]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "tip",
              staticStyle: { "margin-bottom": "14px", "margin-top": "24px" },
            },
            [
              _vm._v(
                " 提示：为保证结果准确性，请保证企业类型、证照编码与实际相符 "
              ),
            ]
          ),
        ],
        2
      ),
      _c("MyUpload", {
        ref: "imgupload",
        attrs: { "request-id": _vm.requestId, imgType: _vm.imgType },
        on: { upload: _vm.uploadSubmit },
      }),
      _c("el-dialog"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }