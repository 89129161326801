<!--  -->
<template>
  <div class="classification">
    <div class="header">
      <!-- <span class="font">仓库中的商品</span> -->
      <el-form ref="formTool"
               :model="formTool"
               :inline="true"
               style="text-align:left;">
        <el-form-item prop="value"
                      style="margin-left:0px !important">
          <el-select v-model="formTool.value"
                     style="width: 120px;"
                     size="small"
                     placeholder="请选择">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="name">
          <el-input v-model="formTool.name"
                    style="width: 120px;"
                    placeholder="请输入商品名称"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="approvalNumber">
          <el-input v-model="formTool.approvalNumber"
                    style="width: 120px;"
                    placeholder="请输入批准文号"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="value">
          <el-select v-model="formTool.value"
                     style="width: 120px;"
                     size="small"
                     placeholder="发票类型">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="value1">
          <el-date-picker v-model="formTool.value1"
                          size="small"
                          type="daterange"
                          value-format="yyyy-MM-dd"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     type="primary"
                     @click="clickSearch(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     @click="reset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="center"
         style="position:relative">
      <MyTable :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getList"
               @handleSelectionChange="handleSelectionChange">
        <template slot="a"
                  slot-scope="scope">
          <span>{{ scope.row.a == '11' ? 'kaipiao' : 'weikai'}}</span>
        </template>
        <template slot="c"
                  slot-scope="scope">
          <span>￥{{ scope.row.c }}</span>
        </template>
        <template slot="caozuo"
                  slot-scope="scope">
          <!-- <el-button type="button" size="mini" icon="el-icon-upload2" @click="deleteHandle(scope.row)">上架</el-button> -->
          <el-link :underline="false"
                   type="primary"
                   @click="detail(scope.row)">查看明细</el-link>
          <el-link :underline="false"
                   style="margin-left:16px"
                   type="primary"
                   @click="uploadBilling(scope.row)">上传发票</el-link>
        </template>
      </MyTable>
    </div>
    <el-dialog title="上传附件"
               :visible.sync="dialogVisible"
               width="40%"
               :before-close="handleClose">
      <span>上传附件信息（上传实物盖章发票清晰扫描文件，多张发票请扫描成一个PDF，文件命名规格：公司名称 + 结算单号）</span>
      <el-form ref="form"
               :model="form"
               :rules="rules"
               style="text-align:left;margin-top: 10px;">
        <el-form-item prop="fileList"
                      style="margin-left:0px !important;margin-bottom: 15px !important;">
          <el-upload class="upload-demo"
                     action="https://gateway.cbyyk.com/product/goodsbase/upload"
                     multiple
                     :on-remove="handleChange"
                     :on-success="successUpload"
                     :on-error="errorUpload"
                     accept=".jpg,.jpeg,.png,.pdf"
                     :file-list="form.fileList">
            <el-button size="small"
                       type="primary">点击上传</el-button>
            <div slot="tip"
                 class="el-upload__tip">附件不能超过10M，最多上传10个，文件格式PDF、JPG、PNG</div>
          </el-upload>
        </el-form-item>
        <el-form-item prop="company"
                      style="margin-left:0px !important;margin-bottom: 15px !important;">
          <el-input v-model="form.company"
                    style="width: 200px;"
                    placeholder="填写快递公司"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="num"
                      style="margin-left:0px !important;margin-bottom: 15px !important;">
          <el-input v-model="form.num"
                    onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
                    style="width: 200px;"
                    placeholder="填写快递单号"
                    clearable
                    size="small" />
        </el-form-item>
      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleClose"
                   size="small">取消</el-button>
        <el-button type="primary"
                   @click="submit"
                   size="small">提交发票</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MyTable from '@/components/myTable'
import { drugenumeration } from '@/utils/enumeration'
import { getWarebase } from '@/api/productManage/wareProduct'
import avater from "/public/img/default.png"

export default {
  name: 'WareProduct',
  components: { MyTable },
  data () {
    return {
      avater: avater,

      dialogVisible: false,
      value1: '',
      form: {
        fileList: [],
        company: '',
        num: '',
      },
      rules: {
        company: [
          { required: true, message: '请填写快递公司', trigger: 'blur' }
        ],
        num: [
          { required: true, message: '请填写快递公司', trigger: 'blur' }
        ]
      },
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: '',
      formTool: {
        value1: '',
        value: '',
        name: '',
        approvalNumber: ''
      },
      selectionList: [],
      operation: false,
      selection: false,
      index: false,
      tableData: [
        {
          a: 11,
          b: 11,
          c: 11,
        }
      ],
      tableOption: [
        { label: '发票抬头', prop: 'b' }, // 这里表示自定义列
        // { label: '商品名称', prop: 'name' },
        // { label: '生产厂商', prop: 'factory' },
        { label: '发票类型', prop: 'b' },
        { label: '开票状态', prop: 'a', slot: true },
        { label: '开票金额', prop: 'c', slot: true },
        { label: '申请时间', prop: 'b' },
        { label: '开票时间', prop: 'b' },
        { label: '物流公司', prop: 'b' },
        { label: '运单号', prop: 'b' },
        { label: '操作', prop: 'caozuo', slot: true, minWidth: '110px' },

      ],
      page: {
        total: 1,
        current: 1,
        size: 20,
        pageSizes: [10, 20, 30, 50]
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    // this.getWarebase()
  },
  mounted () {

  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    // 改变文件列表
    handleChange (file, fileList) {
      this.form.fileList = fileList
    },
    // 上传成功
    successUpload (response, file, fileList) {
      let obj = {
        name: response.data.fileName,
        url: response.data.url,
      }
      this.form.fileList.push(obj)
    },
    // 上传失败
    errorUpload (err, file, fileList) {
    },
    // 关闭弹窗
    handleClose () {
      this.$refs.form.resetFields()
      this.dialogVisible = false
    },
    // 确认提交
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      // this.dialogVisible = false
    },
    // 重置查询条件
    reset () {
      this.$refs.formTool.resetFields()
    },
    // 切换分页
    getList () {
      this.getWarebase()
    },
    // 勾选
    handleSelectionChange (val) {
      this.selectionList = val
    },
    // 查询出售中的商品
    getWarebase (current) {
      if (current) { // 搜索条件改变从第一页开始查询
        this.page.current = current
      }
      const params = {
        ...this.formTool,
        current: this.page.current,
        size: this.page.size
      }
      getWarebase(params).then(res => {
        if (res.code == 0 && res.data) {
          this.tableData = res.data.records
          this.page.total = res.data.total
          if (this.tableData.length > 0) {
            this.tableData.forEach(item => {
              item.type = drugenumeration(item.type)
            })
          }
        } else {
          this.tableData = []
          this.page.total = 0
        }
      })
    },
    // 搜索
    clickSearch (current) {
      this.getWarebase(current)
    },
    // 查看明细
    detail (row) {
      this.$router.push({
        path: '/financialcenter/financialcenter-applyBilling-detail',
        query: {
          id: row.id
        }
      })
    },
    // 上传发票
    uploadBilling () {
      this.dialogVisible = true
    }
  }
}
</script>
<style lang='scss' scoped>
.classification {
  padding: 0px;
}
.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px !important;
  border-bottom: 1px solid #f5f5f5;
  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }
  .el-form-item {
    margin: 0px 0px 0px 8px !important;
  }
}
.center {
  margin-top: 16px;
  padding: 0 24px 0 24px;
  height: calc(100% - 131px);
  ::v-deep .imageP {
    width: calc(100% - 110px);
    p {
      margin: 0 0 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
