<template>
    <div v-if="invoiceInfo">
        <p class="duanluo"  @click="didClickInvoice()">
            <el-link type="primary"  :underline="false" :style="{'color':color}" >{{invoiceInfoText}}</el-link><el-link style="color:#449FFF"  :underline="false">查看开票信息</el-link>
            <el-link style="color: #449FFF;"  :underline="false" v-show="canReceive">（可接受电子票）</el-link>
        </p>
    </div>
</template>
<script>
    export default {
        props: {
            invoiceInfo: {
                type: Object,
                default: () => {}
            }
        },
        computed: {
            invoiceInfoText(){
                let text = ''
                if (this.invoiceInfo.invoiceType == 1 || this.invoiceInfo.invoiceType == 2 )  {
                    text = '【普票】'
                } else if (this.invoiceInfo.invoiceType == 3 || this.invoiceInfo.invoiceType == 4) {
                    text = '【专票】'
                }
                return text
            },
            color(){
                let color = ''
                if (this.invoiceInfo.invoiceType == 1 || this.invoiceInfo.invoiceType == 2) {
                    color = '#449FFF'
                } else if (this.invoiceInfo.invoiceType == 3 || this.invoiceInfo.invoiceType == 4) {
                    color = '#f5222d'
                }
                return color
            },
            canReceive(){
                return this.invoiceInfo.invoiceType == 3 || this.invoiceInfo.invoiceType == 4
            }
        },
        data(){
            return{
                
            }
        },
        methods: {
            didClickInvoice(){
                this.$emit('didClickInvoice')
            }
        },
    }
</script>
<style lang="scss" scoped>

</style>