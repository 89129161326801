var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("searchForm", {
        attrs: {
          expanded: _vm.expanded,
          labelList: _vm.searchLabelList,
          searchData: _vm.searchData,
          showExport: true,
          isShowAddress: true,
        },
        on: {
          handleExpand: _vm.handleExpand,
          getList: _vm.getList,
          reset: _vm.resetForm,
          export: _vm.exportShopList,
        },
        model: {
          value: _vm.searchData,
          callback: function ($$v) {
            _vm.searchData = $$v
          },
          expression: "searchData",
        },
      }),
      _c("div", { staticClass: "statics" }, [
        _c(
          "div",
          {
            class: { item: true, red: _vm.slectedIndex === 0 },
            on: {
              click: function ($event) {
                return _vm.handleClickBlock(0)
              },
            },
          },
          [
            _c("div", { staticClass: "tip" }, [_vm._v("资质证件更新")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(" " + _vm._s(_vm.statisticsData.v1) + " "),
            ]),
          ]
        ),
        _c(
          "div",
          {
            class: { item: true, red: _vm.slectedIndex === 1 },
            on: {
              click: function ($event) {
                return _vm.handleClickBlock(1)
              },
            },
          },
          [
            _c("div", { staticClass: "tip" }, [_vm._v("资质证件过期")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(" " + _vm._s(_vm.statisticsData.v2) + " "),
            ]),
          ]
        ),
        _c(
          "div",
          {
            class: { item: true, red: _vm.slectedIndex === 2 },
            on: {
              click: function ($event) {
                return _vm.handleClickBlock(2)
              },
            },
          },
          [
            _c("div", { staticClass: "tip" }, [_vm._v("企业名称更新")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.statisticsData.v3)),
            ]),
          ]
        ),
        _c(
          "div",
          {
            class: { item: true, red: _vm.slectedIndex === 3 },
            on: {
              click: function ($event) {
                return _vm.handleClickBlock(3)
              },
            },
          },
          [
            _c("div", { staticClass: "tip" }, [_vm._v("地址更新")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.statisticsData.v4)),
            ]),
          ]
        ),
      ]),
      _c("myTable", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: {
          index: _vm.index,
          selection: false,
          page: _vm.page,
          showpage: true,
          operation: false,
          "table-data": _vm.tableData,
          "table-option": _vm.tableOption,
          expand: false,
        },
        on: {
          "update:tableOption": function ($event) {
            _vm.tableOption = $event
          },
          "update:table-option": function ($event) {
            _vm.tableOption = $event
          },
          "page-change": _vm.getList,
        },
        scopedSlots: _vm._u([
          {
            key: "shopStatus",
            fn: function (scope) {
              return [
                scope.row.shopStatus == 1
                  ? _c("span", [_vm._v(" 未开户 ")])
                  : _vm._e(),
                scope.row.shopStatus == 2
                  ? _c("span", [_vm._v(" 已开户 ")])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "operation",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  { staticStyle: { margin: "0 0px" } },
                  [
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.checkingQualification(scope.row, 1)
                          },
                        },
                      },
                      [_vm._v(" 查看资质 ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { margin: "0 0px" } },
                  [
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.showErpDailog(scope.row, 1)
                          },
                        },
                      },
                      [_vm._v(" 编辑ERP编码 ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { margin: "0 0px" } },
                  [
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.showCustomer(scope.row, 1)
                          },
                        },
                      },
                      [_vm._v(" 信息变更记录 ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑ERP编码",
            visible: _vm.visibleErp,
            width: "30%",
            "before-close": _vm.handleCloseErp,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleErp = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dialogFormErp",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.dialogFormErp,
                "status-icon": "",
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "dialog-body" },
                [
                  _c("el-form-item", { attrs: { label: "客户名称:" } }, [
                    _vm._v(" " + _vm._s(_vm.dialogFormErp.shopName)),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户ERP编码:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { size: "small", clearable: "" },
                        model: {
                          value: _vm.dialogFormErp.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialogFormErp, "number", $$v)
                          },
                          expression: "dialogFormErp.number",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex-end" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "8px" },
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleCloseErp()
                        },
                      },
                    },
                    [_vm._v("返回")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "8px" },
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitErp(1)
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-customer",
          attrs: {
            title: "客户信息变更日志",
            visible: _vm.visibleCustomer,
            width: "50%",
            "before-close": _vm.handleCloseCustomer,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleCustomer = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dialogFormCustomer",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.dialogFormCustomer,
                "status-icon": "",
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "dialog-center-customer" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableDataCustomer, border: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "changeLog", label: "变更内容" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "content-change" }, [
                                  _c("div", { staticClass: "list" }, [
                                    _vm._v("【经营范围】："),
                                  ]),
                                  _c("div", { staticClass: "list before" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row
                                            .businessScopeBeforeModification ||
                                            ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "list after" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row
                                            .businessScopeAfterModification ||
                                            ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "content-change" }, [
                                  _c("div", { staticClass: "list" }, [
                                    _vm._v("【收货地址变更】："),
                                  ]),
                                  _c("div", { staticClass: "list before" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row
                                            .basicInformationBeforeModification ||
                                            ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "list after" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row
                                            .basicInformationAfterModification ||
                                            ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "content-change" }, [
                                  _c("div", { staticClass: "list" }, [
                                    _vm._v("【药品经营许可证】："),
                                  ]),
                                  _c("div", { staticClass: "list" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.qualificationInformation ||
                                            ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "变更时间", prop: "changeTime" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [_vm._v(_vm._s(scope.row.changeTime))]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }