<!--  保证金变动记录 -->
 
<template>
  <div class="content">
    <div class="header">

      <el-form ref="formTool" :model="formTool" :inline="true" style="text-align: left">
        <el-row style="margin: 10px  0 10px">
          <el-form-item label="">
            <el-input v-model.trim="formTool.orderId" placeholder="请输入订单ID" clearable size="small" />
          </el-form-item>
          <el-form-item label="时间" prop="date">
            <el-date-picker style="width: 100%" v-model="formTool.date" value-format="yyyy-MM-dd" type="daterange"
              size="small" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" />
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="formTool.reason" clearable style="width: 160px;" size="small" placeholder="保证金变动原因">
              <el-option v-for="item in reasonOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="getList(1)">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="reset()">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="exportUrl()">导出</el-button>
          </el-form-item>
        </el-row>

      </el-form>
    </div>

    <div class="center" style="position: relative" :style="contentHeight ? `height:${contentHeight}px!important` : ''">
      <MyTable :table-data="tableData" :index="index" :selection="selection" :page="page" :operation="operation"
        :table-option.sync="tableOption" @page-change="getList" @handleSelectionChange="handleSelectionChange">
        <!-- <template slot="menu" slot-scope="scope">
          <el-link :underline="false" type="primary" @click="detailHandle(scope.row, '详情')" class="btn-list">详情</el-link>
          <el-link :underline="false" type="primary" @click="detailHandle(scope.row, '编辑')" class="btn-list">编辑</el-link>
        </template> -->

        <template slot="reason" slot-scope="scope">
          {{ scope.row.reason | filterReasonType }}
        </template>
        <!-- useAmount -->
        <template slot="amount" slot-scope="scope">
          <span>￥ {{ scope.row.amount ? parseFloat(scope.row.amount).toFixed(2) : '0.00' }}</span>
        </template>
        <template slot="useAmount" slot-scope="scope">
          <span v-if="scope.row.type == 1" class="add"> + <span> {{ scope.row.amount ?
            parseFloat(scope.row.amount).toFixed(2) : '0.00' }}</span></span>
          <span v-if="scope.row.type == 2" class="reduce"> - <span>{{ scope.row.amount ?
            parseFloat(scope.row.amount).toFixed(2) : '0.00' }}</span></span>
          <br />
          <span> <span>￥ {{ scope.row.useAmount ? parseFloat(scope.row.useAmount).toFixed(2) : '0.00' }}</span> </span>
        </template>
        <template slot="operation" slot-scope="scope">
          <div style="margin: 0 0px">
            <el-link type="primary" :underline="false" @click="detailClick(scope.row, '保证金详情')"
              style="margin-right: 10px">
              详情
            </el-link>
          </div>
        </template>
      </MyTable>
    </div>
    <addDialog ref="add-dialog" @getList="getList" />
    <detailDialog ref="detailRef"></detailDialog>
  </div>
</template>
  
<script>
const reasonOptions = [
  {
    value: '1',
    label: '保证金充值'
  },
  {
    value: '2',
    label: '订单资金冻结'
  },
  {
    value: '3',
    label: '服务费扣除'
  }, {
    value: '4',
    label: '售后资金扣减'
  }, {
    value: '5',
    label: '订单资金解冻'
  },
  {
    value: '6',
    label: '超时赔付'
  },
  {
    value: '7',
    label: '服务费冻结'
  },
  {
    value: '8',
    label: '服务费解冻'
  },
  {
    value: '9',
    label: '订单超时赔付金额冻结'
  },
  {
    value: '10',
    label: '订单超时赔付金额解冻'
  },
  {
    value: '11',
    label: '订单超时赔付金额扣减'
  },
  {
    value: '12',
    label: '平台增额'
  },
  {
    value: '13',
    label: '平台扣减'
  },
]
import detailDialog from "./detailDialog.vue";
import MyTable from "@/components/myTable";
import addDialog from "./components/chargeMoney.vue";
import avater from "/public/img/default.png";
import { userLogPage, guaranteeInfo, userLogExport, fileExport } from "@/api/surety";
export default {
  components: { MyTable, addDialog, detailDialog },
  data () {
    return {
      contentHeight: 0,
      activeName: 'first',
      avater: avater,
      formTool: {
      },
      operation: false,
      selection: false,
      index: true,
      tableData: [],
      tableOption: [
        { label: "时间", prop: "createTime" },
        { label: "保证金变动原因", prop: "reason", slot: true },
        { label: "订单编码", prop: "orderNo" },
        { label: "收支/余额（元）", prop: "useAmount", slot: true },
        { label: "操作", prop: "operation", slot: true, width: 100 },
      ],
      reasonOptions: reasonOptions,
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      dialogShow: false
    };
  },
  computed: {},
  filters: {
    filterReasonType (e) {
      for (let i = 0; i < reasonOptions.length; i++) {
        if (e == reasonOptions[i].value) return reasonOptions[i].label
      }
    },
  },
  watch: {},
  created () {
    this.getList();
  },
  mounted () { },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () {
    this.getWindowView()
  },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  computed: {

  },
  methods: {
    // 详情
    detailClick (row, title) {
      this.$refs.detailRef.open(row, title)
    },
    // 页码变化
    handleCurrentChange (e) {
      this.page.current = e
    },

    // 条数变化
    handleSizeChange (e) {
      this.page.size = e
      this.page.current = 1
    },

    detailHandle (row, btnType) {
      if (btnType == '编辑') {
        this.$refs['add-dialog'].init(row, 'edit')
      }
      if (btnType == '详情') {
        this.$refs['add-dialog'].init(row, 'detail')
      }
    },

    // 重置查询条件
    reset () {
      this.formTool = {};
      this.getList(1);
    },
    // userLogExport

    exportUrl () {
      const params = {
        ...this.formTool
      };
      if (this.formTool.date) {
        params.startCreateTime = this.formTool.date[0];
        params.endCreateTime = this.formTool.date[1];
      }
      delete params.date;
      fileExport('/payment/guarantee/merchant/userLogExport', 'POST', params).then((res) => { }).catch((err) => {

      })
    },
    // 搜索
    getList (current) {
      if (current) {
        this.page.current = current;
      }
      const params = {
        ...this.formTool,
        current: this.page.current,
        size: this.page.size
      };
      if (this.formTool.date) {
        params.startCreateTime = this.formTool.date[0];
        params.endCreateTime = this.formTool.date[1];
      }
      delete params.date;
      userLogPage(params).then((res) => {
        if (res) {
          this.tableData = res.records;
          this.page.total = res.total;
        } else {
          this.tableData = [];
          this.page.total = 0;
        }
      });
      this.$parent.query();
    },

    // 勾选
    handleSelectionChange (val) {
      this.selectionList = val;
    },
    getWindowView () {

      let innerHeight = window.innerHeight; //获取页面高度 
      let headHeight = 60;
      headHeight = this.$refs['formTool'].$el.clientHeight; //获取子页面head 高度  
      console.log(headHeight, '--headHeight')
      let contentHeight = innerHeight - 258 - headHeight;
      this.contentHeight = contentHeight;
    }
  },
};
</script>
<style lang='scss' scoped> .content {
   height: calc(100% - 120px);

   .header {
     width: calc(100% - 12px);
     margin: 10px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     // padding: 0px 24px 0px 24px !important;
     border-bottom: 1px solid #f5f5f5;

     .font {
       font-size: 16px;
       font-family: PingFangSC, PingFangSC-Medium;
       font-weight: 600;
       text-align: left;
       color: #262626;
     }

     .el-form-item {
       margin: 0px 8px 0px 0px !important;
     }
   }

   .center {
     margin-top: 16px;
     padding: 0 10px;

     //height: calc(100% - 200px); 
     .shangjia {
       position: absolute;
       z-index: 2;
       bottom: -45px;
     }
   }

   .btn-warp {
     display: flex;
     justify-content: space-around;

   }
 }

 .btn-list {
   margin: 0 10px;
 }

 ::v-deep .el-table {
   height: calc(100% - 120px) !important;
   overflow: auto;
 }

 .reduce {
   color: green;
 }

 .add {
   color: #F5222D;
 }
</style>
  