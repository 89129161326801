<template>
  <div class="navbar">
    <hamburger :is-active="sidebar.opened"
               class="hamburger-container"
               @toggleClick="toggleSideBar"
               style="padding-left:20px" />
    <breadcrumb class="breadcrumb-container" />
    <!--动态菜单-->
    <!-- <div class="dynamic-tabs">
      <el-tag
      :key="tag.index"
      v-for="tag in editableTabs"
      closable
      :disable-transitions="false"
      size="medium"
      @close="removeTab(tag)"
      style="margin-left: 10px;cursor: pointer;"
      :class="getTagTypeColor(tag)"
      @click="link(tag)"
      >
      {{tag.name}}
    </el-tag>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import { get } from 'lodash'

export default {
  components: {
    Breadcrumb,
    Hamburger
  },
  data () {
    return {
      editableTabsValue: '',
      editableTabs: []
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
    ]),
    ...mapState([
      'dynamicTabsStore'
    ])
  },
  watch: {
    dynamicTabsStore: {
      handler (newVal, oldVal) {
        this.editableTabs = Object.assign([], newVal.dynamicTabs);
      },
      deep: true
    }
  },
  methods: {
    toggleSideBar () {
      this.$store.dispatch('app/toggleSideBar')
    },
    /**
     * 删除tab
     * @param {Object} targetName
     */
    removeTab(target) {
      const path = target.path;
      this.$store.dispatch('dynamicTabsStore/removeDynamicTabs', path);
    },
    link(tab) {
      this.$router.push(tab.path);
      this.$store.dispatch('dynamicTabsStore/setCurrentTab', tab);
    },
    getTagTypeColor(tag) {
      const path = tag.path;
      const currentTab = this.$store.state.dynamicTabsStore.currentTab;
      if (path === currentTab.path) {
        return 'tab_selected';
      } else {
        return 'tab_normal';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 32px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  align-items: center;
  flex: 1;

  .hamburger-container {
    line-height: 32px;
    height: 100%;
    // float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    // float: left;
  }
  .dynamic-tabs{
    overflow-x: auto;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex:1;
    // ::v-deep .el-tabs__header {
    //   background-color: red;
    // }
    // ::v-deep .el-tabs__nav {
    //   background-color: chartreuse;
    // }
    // ::v-deep .el-tabs__nav-prev,
    // ::v-deep .el-tabs__nav-next {
    //   height: 32px;
    // }
    // ::v-deep .el-tabs__item {
    //   font-size: 11px;
    //   height: 32px;
    // }
    ::v-deep .el-tag .el-tag__close {
        color: #999!important;
    }
    ::v-deep .el-tag .el-tag__close:hover {
        background-color: #aab4c4!important;
    }
    .tab_selected {
      background-color: #f5222d;
      color: #fff;
      border: 1px solid #f5222d;
    }
   .tab_normal {
      background-color: #fff;
      color: #333;  
      border: 1px solid #d9d9d9;
   }
  }
}
</style>
