var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          staticStyle: { padding: "4px" },
          attrs: {
            model: _vm.formData,
            "label-position": "top",
            size: "small",
            rules: _vm.rules,
            "label-width": "100px",
            disabled: _vm.typeFreight == "detail",
          },
        },
        [
          _vm._l(_vm.formData.freightList, function (item, key) {
            return _c("div", { key: key, staticClass: "content-warp" }, [
              _c("div", { staticClass: "free-name flex-list flex-head" }, [
                key != 0
                  ? _c(
                      "div",
                      { staticClass: "flex-lf" },
                      [
                        key != 0
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "",
                                  prop: "freightList." + key + ".feeName",
                                  rules: _vm.rules.feeName,
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "300px" },
                                  attrs: {
                                    placeholder: "请输入运费模板名称",
                                    disabled: !item.showSave,
                                    size: "small",
                                  },
                                  model: {
                                    value: item.feeName,
                                    callback: function ($$v) {
                                      _vm.$set(item, "feeName", $$v)
                                    },
                                    expression: "item.feeName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                key == 0
                  ? _c("div", { staticClass: "flex-lf flex-lf-1" }, [
                      _c("div", { staticClass: "flex-title" }, [
                        _vm._v(" 所有地区 "),
                      ]),
                      _c("div", { staticClass: "flex-tips" }, [
                        _vm._v(
                          " 此运费模板为默认模板；新运费模板中地域将使用新模板的规则。 举例：新设置”湖南运费“模板，地域关联湖南省，湖南省的客户将使用”湖南运费“的计费规则 "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  [
                    !item.showSave
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "plain", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.editFreight(item, key)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    item.showSave
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "plain", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.saveFreight(item, key)
                              },
                            },
                          },
                          [_vm._v("保存")]
                        )
                      : _vm._e(),
                    key != 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteFreight(item)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "content-bottom" },
                [
                  key != 0
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "运费模版的区域",
                            prop: "freightList." + key + ".areaList",
                            rules: _vm.rules.areaList,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "area-tree-box",
                              staticStyle: {
                                width: "100%",
                                height: "120px",
                                "overflow-y": "auto",
                              },
                            },
                            [
                              _c("el-tree", {
                                ref: `areatree_${key}`,
                                refInFor: true,
                                attrs: {
                                  props: {
                                    label: "rname",
                                    children: "children",
                                  },
                                  data: _vm.areaTreeData,
                                  "default-checked-keys": item.defaultArea,
                                  "node-key": "id",
                                  "show-checkbox": "",
                                },
                                on: {
                                  check: (item, checked) => {
                                    _vm.handleCheckChange(item, checked, key)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "flex-list" }, [
                    _c(
                      "div",
                      { staticClass: "flex-item-e" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "fill-warp",
                            attrs: {
                              label: "发货物流",
                              prop:
                                "freightList." + key + ".deliveryLogisticsId",
                              rules: _vm.rules.deliveryLogisticsId,
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  size: "small",
                                  disabled: !item.showSave,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleChangeExpress(
                                      item.deliveryLogisticsId,
                                      key
                                    )
                                  },
                                },
                                model: {
                                  value: item.deliveryLogisticsId,
                                  callback: function ($$v) {
                                    _vm.$set(item, "deliveryLogisticsId", $$v)
                                  },
                                  expression: "item.deliveryLogisticsId",
                                },
                              },
                              _vm._l(
                                _vm.indicatorList ? _vm.indicatorList : [],
                                function (e) {
                                  return _c("el-option", {
                                    key: e.id,
                                    attrs: {
                                      label: e.instructions,
                                      value: e.id,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-item-e" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "满足以下金额包邮",
                              prop:
                                "freightList." +
                                key +
                                ".freeShippingOrderAmount",
                              rules: _vm.rules.freeShippingOrderAmount,
                            },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "请输入金额",
                                  disabled: !item.showSave,
                                },
                                model: {
                                  value: item.freeShippingOrderAmount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item,
                                      "freeShippingOrderAmount",
                                      $$v
                                    )
                                  },
                                  expression: "item.freeShippingOrderAmount",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "prefix" }, slot: "prefix" },
                                  [_vm._v("￥")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-list" }, [
                    _c(
                      "div",
                      { staticClass: "flex-item-e" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "不满足条件时收取以下金额运费",
                              prop: "freightList." + key + ".carriageAmount",
                              rules: _vm.rules.carriageAmount,
                            },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "请输入金额",
                                  disabled: !item.showSave,
                                },
                                model: {
                                  value: item.carriageAmount,
                                  callback: function ($$v) {
                                    _vm.$set(item, "carriageAmount", $$v)
                                  },
                                  expression: "item.carriageAmount",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "prefix" }, slot: "prefix" },
                                  [_vm._v("￥")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-item-e" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "低于等于以下金额不允许下单",
                              prop: "freightList." + key + ".minOrderAmount",
                              rules: _vm.rules.minOrderAmount,
                            },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "请输入金额",
                                  disabled: !item.showSave,
                                },
                                model: {
                                  value: item.minOrderAmount,
                                  callback: function ($$v) {
                                    _vm.$set(item, "minOrderAmount", $$v)
                                  },
                                  expression: "item.minOrderAmount",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "prefix" }, slot: "prefix" },
                                  [_vm._v("￥")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ])
          }),
          _c(
            "div",
            { staticClass: "add-warp" },
            [
              _c(
                "el-button",
                {
                  staticClass: "el_btn",
                  attrs: { type: "plain", size: "small" },
                  on: { click: _vm.addFreight },
                },
                [_vm._v("新增模板")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }