<!-- err -->
<template>
  <el-dialog title=""
             :visible.sync="dialogShow"
             width="60%"
            >
    <div class="dialogBox"> 
      <iframe src="https://yaochanchan.com/serviceAgreement" title="《药潺潺用户服务协议》" style="width: 100%; height:100%;border:none;"></iframe>
    </div>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="notAgree">不 同 意</el-button>
      <el-button type="primary"
                 @click="agree">同 意</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogShow: false
    }
  },
  methods: {
    agree () {
      // this.rememberAccount = true
      this.$emit('sure', true)
      this.dialogShow = false
    },
    notAgree () {
      // this.rememberAccount = false
      this.$emit('sure', false)
      this.dialogShow = false
    },
    open () {
      this.dialogShow = true
    },
    close () {
      this.dialogShow = false
    },
    // goSubmit() {
    //   this.$emit('sure', this.formTool.desc)
    //   setTimeout(() => {
    //     this.close()
    //   }, 300)
    // }
  }
}
</script>
<style lang='scss' scoped>
::v-deep .el-dialog {
  .el-dialog__body {
    // height: 500px;
    overflow: auto;
    .dialogBox {
      font-size: 18px;
      padding: 10px;
      height: 60vh;
      .title {
        text-align: center;

        font-weight: 600;
      }
      .descrption {
        font-weight: 600;
      }
      // .content {}
    }
  }

  .el-dialog__header {
    border: none;
  }
  .el-dialog__footer {
    border: none;
  }
}
</style>
