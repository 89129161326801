<template>
  <div class="mainView">
    <div class="topView">
      <div class="buttonView"
           style="display: block;">
        <Tabs :tabsList="tabsList"
              :activeName.sync="activeName"
              @tab-click="handleChange" />
      </div>
      <div class="buttonView"
           style="padding-top: 12px;">
        <el-radio-group v-model="tabPosition"
                        size="mini"
                        @input="monthYearChange">
          <el-radio-button label="weeks">周统计</el-radio-button>
          <el-radio-button label="month">月统计</el-radio-button>
          <el-radio-button label="year">年统计</el-radio-button>
          <el-radio-button label="custom">自定义</el-radio-button>
        </el-radio-group>
      </div>
      <div class="buttonView"
           style="overflow: auto;">
        <div style="height: 56px;"
             class="tabsTeShu"
             v-if="monthOrYear == 'weeks'">
          <el-tabs v-model="weeksSelectIndex"
                   @tab-click="handleClickWeeks">
            <el-tab-pane style="font-size: 14px;font-weight: 400;"
                         :label="item.startDate + '至' + item.endDate"
                         :name="item"
                         v-for="item,index in weeksArr"
                         :key="index"></el-tab-pane>
          </el-tabs>
        </div>
        <div style="height: 56px;"
             class="tabsTeShu">
          <el-tabs v-model="yearCheck"
                   @tab-click="handleClickYear"
                   v-if="monthOrYear == 'year'">
            <el-tab-pane :label="item"
                         :name="item"
                         v-for="item,index in yearArr"
                         :key="index"></el-tab-pane>
          </el-tabs>
        </div>
        <el-button type="text"
                   v-if="monthOrYear == 'month'"
                   style="margin-right: 20px;color: #595959;font-weight: 600;font-size: 16px;">{{year}}</el-button>
        <div style="height: 56px;"
             class="tabsTeShu">
          <el-tabs v-model="monthSelectIndex"
                   @tab-click="handleClick"
                   v-if="monthOrYear == 'month'">
            <el-tab-pane :label="item.str"
                         :name="index+''"
                         v-for="item,index in monthArr"
                         :key="index"></el-tab-pane>
          </el-tabs>
        </div>
        <div v-if="monthOrYear == 'custom'">
          <el-date-picker v-model="customDate"
                          @change="changeDate"
                          size="small"
                          type="daterange"
                          value-format="yyyy-MM-dd"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="goodsSalesStatMain"
         v-if="activeName == 'MERCHANT_REMOVAL'"
         ref="goodsSalesStatMain">
      <div class="flexLineView mainHeight">
        <div class="leftHalf">
          <highcharts :options="lineOptions" />
        </div>

        <el-table :data="tableDataChange.length != 0 ? []:tableData"
                  style="width: 100%;"
                  :height="tableHeight"
                  highlight-current-row
                  @current-change="handleCurrentChange"
                  ref="firstTable">
          <el-table-column prop="rn"
                           label="排名"
                           width="50">
          </el-table-column>
          <el-table-column prop="goods_name"
                           label="药品名称">
          </el-table-column>
          <el-table-column prop="goods_factory"
                           label="生产企业">
          </el-table-column>
          <el-table-column prop="qty"
                           label="销售数量"
                           width="100">
          </el-table-column>
          <el-table-column prop="amount"
                           label="销售金额(元)">
          </el-table-column>
          <el-table-column label="销售占比"
                           width="100">
            <template slot-scope="scope">
              <div class="percentView">
                <div class="percentBg"
                     :style="{width:getPercent(scope.row.pct)+'%'}"></div>
                <div class="percentText">{{getPercent(scope.row.pct)}}%</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="dropDownBtn"><el-dropdown @command="dropdownClickXiaoshou">
            <el-button type="default"
                       size="mini">
              {{controlsXiaoshou[controlSelectTypeXiaoshou]}}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">数量</el-dropdown-item>
              <el-dropdown-item command="2">金额</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <span style="margin-left:10px;color:#262626;font-size:16px;font-weight:600;">排名<span style="color:#595959;font-size:12px;font-weight:400;padding-left:10px;">&nbsp;&nbsp;数据更新时间{{timeStr}}</span></span>

          <el-dropdown @command="dropdownClick"
                       style="margin-left:10px">
            <el-button type="default"
                       size="mini">
              {{controls[controlSelectType]}}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="2">控销药</el-dropdown-item>
              <el-dropdown-item command="1">普药</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-autocomplete v-model="state"
                           size="mini"
                           style="margin-left:10px;width: 180px;position: relative;top: 2px;"
                           :fetch-suggestions="querySearchAsync"
                           placeholder="药品名称"
                           @blur="blurAuto"
                           @select="handleSelect">
            <template slot-scope="{ item }">

              <el-tooltip class="item"
                          effect="dark"
                          :content="item.goods_name"
                          placement="top">
                <div class="name"
                     style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                  {{ item.goods_name }}
                </div>
              </el-tooltip>
            </template>
          </el-autocomplete>

        </div>
        <div class="numberView">
          <img :src="number1"
               alt=""
               class="numberImg">
        </div>
      </div>
      <div class="lineView"></div>
      <div class="flexLineView">
        <div class="row">
          <highcharts :options="chartOptions"
                      v-if="chartOptions" />
          <div class="numberView">
            <img :src="number2"
                 alt=""
                 class="numberImg">
          </div>
        </div>
        <div class="lineViewHorizon"></div>
        <div class="secondTableView"
             v-if="categoryTableData.length">
          <div class="classNameView">
            <span style="font-weight: 600;color: #262626;font-size: 16px;margin-right: 10px;">采购用户</span>
            <!-- {{firstSelectData?firstSelectData.goods_one_class_name+'/'+firstSelectData.goods_two_class_name+'/'+firstSelectData.goods_three_class_name : ''}} -->
          </div>
          <el-table :data="categoryTableData"
                    style="width: 100%;"
                    highlight-current-row
                    :height="tableHeight - 38"
                    ref="hilightTable">
            <el-table-column prop="rn"
                             label="排名"
                             width="50">
            </el-table-column>
            <el-table-column prop="shop_type_name"
                             label="客户类别">
            </el-table-column>
            <el-table-column prop="pay_shop_name"
                             label="客户名称">
            </el-table-column>
            <el-table-column prop="erp_id"
                             label="ERPID"
                             width="100">
            </el-table-column>
            <el-table-column prop="qty"
                             label="采购数量"
                             width="100">
            </el-table-column>
            <el-table-column prop="price"
                             label="单价(元)">
            </el-table-column>
            <el-table-column prop="amount"
                             label="采购额(元)">
            </el-table-column>
            <!-- <el-table-column label="销售占比"
                             width="100">
              <template slot-scope="scope">
                <div class="percentView">
                  <div class="percentBg"
                       :style="{width:getPercent(scope.row.pct)+'%'}"></div>
                  <div class="percentText">{{getPercent(scope.row.pct)}}%</div>
                </div>
              </template>
            </el-table-column> -->
          </el-table>
          <div class="numberView">
            <img :src="number2"
                 alt=""
                 class="numberImg">
          </div>
        </div>
      </div>
    </div>
    <salesStatistics v-if="activeName == 'ON_SALE'"></salesStatistics>
    <customerSales v-if="activeName == 'PLATFORM_REMOVAL'"
                   @getDataEmit="getData"></customerSales>
  </div>
</template>
<script>
import salesStatistics from './salesStatistics.vue'
import customerSales from './customerSales.vue'
import Tabs from '@/components/Tabs'
import { queryGoodsSalesEcharts } from "@/api/statistics";
import number1 from "@/assets/number1.png";
import number2 from "@/assets/number2.png";
import dayjs from 'dayjs'
import { number } from 'echarts';
export default {
  components: { Tabs, salesStatistics, customerSales },
  data () {
    return {
      yearCheck: dayjs().format('YYYY'),
      yearArr: ['2023'],
      shopId: '',
      tableDataChange: [],
      state: '',
      selectState: {},
      timeout: null,
      controlsXiaoshou: ['', '数量', '金额'],
      controlSelectTypeXiaoshou: 1,
      timeStr: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      weeksSelectIndex: [],
      weeksArr: [],
      customDate: [],
      activeName: 'MERCHANT_REMOVAL',
      tabsList: [ // tabs 传的值
        {
          name: 'MERCHANT_REMOVAL',
          label: '单品销售',
          value: 0,
        },
        {
          name: 'ON_SALE',
          label: '销售统计',
          value: 0,
        },

        {
          name: 'PLATFORM_REMOVAL',
          label: '客户销售',
          value: 0,
        }
      ],
      tabPosition: 'month',
      eachRowWidth: 0,
      top10: [],
      tableData: [],
      categoryTableData: [],
      tableHeight: 0,
      chartOptions: undefined,
      lineOptions: {},
      controls: ['', '普药', '控销药'],
      controlSelectType: '2',
      monthArr: [],
      monthSelectIndex: 0,
      year: dayjs().format('YYYY'),
      monthOrYear: 'month',
      firstSelectData: undefined,
      number2: number2,
      number1: number1
    }
  },
  provide () {
    return {
      superParams: this
    }
  },
  created () {
    console.log(this.getWeekTime())
    this.weeksArr = this.getWeekTime()
  },

  async mounted () {
    const mainWindow = this.$refs.goodsSalesStatMain
    this.eachRowWidth = (mainWindow.offsetWidth - 12) / 2
    this.tableHeight = (mainWindow.offsetHeight - 12 - 24) / 2
    mainWindow.style.setProperty('--row-width', this.eachRowWidth + 'px')
    mainWindow.style.setProperty('--main-height', mainWindow.offsetHeight - 24 + 'px')
    await this.getMonthArr()
    await this.getYearArr()

    await this.queryShopId()
    if (this.shopId) {
      await this.getData(17)
    } else {
      await this.getData(11)
    }
  },

  methods: {
    async queryShopId () {
      let obj = {
        type: '16',
        params: [this.$store.state.user.user_info.userId]
      }
      const resp = await queryGoodsSalesEcharts(obj)
      if (resp.length > 0) {
        this.shopId = resp[0].shop_id
      }
    },
    blurAuto () {
      this.tableDataChange = []
      // console.log(this.state)
      this.setTableFirst(0, this.top10)
      if (this.monthOrYear != 'year') {
        if (this.shopId) {
          this.getData(18, this.top10[0])
        } else {
          this.getData(12, this.top10[0])
        }

      } else {
        if (this.shopId) {
          this.getData(19, this.top10[0])
        } else {
          this.getData(13, this.top10[0])
        }
      }

      if (this.shopId) {
        this.getData(20, this.top10[0])
      } else {
        this.getData(14, this.top10[0])
      }
    },
    // 选取下面的某一项
    handleSelect (item) {
      this.tableDataChange = [item]
      this.state = item.goods_name
      // this.selectState = item
      if (this.monthOrYear != 'year') {
        if (this.shopId) {
          this.getData(18, item)
        } else {
          this.getData(12, item)
        }
      } else {
        if (this.shopId) {
          this.getData(19, item)
        } else {
          this.getData(13, item)
        }
      }
      if (this.shopId) {
        this.getData(20, item)
      } else {
        this.getData(14, item)
      }
      this.setTableFirst(0, [item])
    },
    createStateFilter (queryString) {
      return (state) => {
        return (state.goods_name.indexOf(queryString) === 0);
      };
    },
    // 远程搜索药品
    querySearchAsync (queryString, cb) {
      var restaurants = this.top10.concat(this.tableData);
      var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      console.log(results, 'el-autocomplete')
      this.timeout = setTimeout(() => {
        cb(results);
      }, 500);
    },
    // 选取周
    handleClickWeeks () {
      this.state = ''
      // console.log(this.weeksSelectIndex, 'weeksSelectIndex')
      if (this.controlSelectTypeXiaoshou == 1) {
        if (this.shopId) {
          this.getData(17)
        } else {
          this.getData(11)
        }
      } else {
        if (this.shopId) {
          this.getData(21)
        } else {
          this.getData(15)
        }
      }

    },
    // 自定义日期
    changeDate () {
      this.state = ''
      // console.log(this.customDate, 'cus')
      if (this.controlSelectTypeXiaoshou == 1) {
        if (this.shopId) {
          this.getData(17)
        } else {
          this.getData(11)
        }
      } else {
        if (this.shopId) {
          this.getData(21)
        } else {
          this.getData(15)
        }
      }

    },
    //切换tabs按钮
    handleChange () {
      // this.getTableList(1)
    },
    async getData (type, data) {
      if (this.activeName != 'MERCHANT_REMOVAL') return;
      // 2023 8
      // type	类型（与报表端确认，一个接口一个类型） 月 1,2,3  /  年 4,5,6
      // params	参数组(示例： '202308','2' )  '202308','2','1696371675141570561'   /  时间  ， 2 控销  1普药 ，
      let startTime = ''
      let endTime = ''
      const m = this.monthArr[this.monthSelectIndex].m
      const day = new Date().getDate() < 10 ? '0' + (new Date().getDate() - 1) : (new Date().getDate() - 1)
      const lastDay = dayjs(this.year + m).endOf('month').format('YYYY-MM-DD')
      const month = new Date().getMonth() < 9 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
      // const postDate = this.monthOrYear == 'year' ? this.year : this.year + m
      // type = this.monthOrYear == 'year' ? type + 3 : type
      if (this.monthOrYear == 'weeks') {
        startTime = this.weeksSelectIndex.startDate.replaceAll('-', '')
        endTime = this.weeksSelectIndex.endDate.replaceAll('-', '')
      } else if (this.monthOrYear == 'month') {
        startTime = this.year + m + '01'
        if (m != month) {
          endTime = lastDay.replaceAll('-', '')
        } else {
          endTime = this.year + m + day
        }
      } else if (this.monthOrYear == 'year') {
        startTime = this.yearCheck + '0101'
        endTime = this.yearCheck + month + day
        if (Number(this.yearCheck) < Number(new Date().getFullYear())) {
          startTime = this.yearCheck + '0101'
          endTime = this.yearCheck + '1231'
        }

      } else if (this.monthOrYear == 'custom') {
        startTime = this.customDate[0].replaceAll('-', '')
        endTime = this.customDate[1].replaceAll('-', '')
      }
      let obj = {
        type: String(type),
      }
      if (type == 11 || type == 15) {
        // {"type":11,"params":["20230801","20230924","1"]} 参数格式
        obj['params'] = [startTime, endTime, String(this.controlSelectType)]
      } else if (type == 12) {
        // {"type":12,"params":["20230901","20230924","1","1674250188351930369","消肿止痛贴(1贴*20贴/盒)","20230901","20230924"]}  销售趋势-月
        obj['params'] = [startTime, endTime, String(this.controlSelectType), data.goods_id, data.goods_name, startTime, endTime] //goodsid  goodsname   shopid
      } else if (type == 13) {
        // {"type":13,"params":["20230101","20230924","20230101","20230924","2","1674250188351930369","消肿止痛贴(1贴*20贴/盒)"]} 销售趋势-年
        obj['params'] = [startTime, endTime, startTime, endTime, String(this.controlSelectType), data.goods_id, data.goods_name] //threeclassid
      } else if (type == 14) {
        // {"type":14,"params":["20230901","20230924","2","1674250188351930369","消肿止痛贴(1贴*20贴/盒)"]} 采购用户
        obj['params'] = [startTime, endTime, String(this.controlSelectType), data.goods_id, data.goods_name] //threeclassid
      }
      else if (type == 17 || type == 21) {
        // {"type":11,"params":["20230801","20230924","1"]} 参数格式
        obj['params'] = [startTime, endTime, String(this.controlSelectType), this.shopId]
      }
      else if (type == 18) {
        // {"type":12,"params":["20230901","20230924","1","1674250188351930369","消肿止痛贴(1贴*20贴/盒)","20230901","20230924"]}  销售趋势-月
        obj['params'] = [startTime, endTime, String(this.controlSelectType), data.goods_id, data.goods_name, this.shopId, startTime, endTime]
      }
      else if (type == 19) {
        // {"type":13,"params":["20230101","20230924","20230101","20230924","2","1674250188351930369","消肿止痛贴(1贴*20贴/盒)"]} 销售趋势-年
        obj['params'] = [startTime, endTime, startTime, endTime, String(this.controlSelectType), data.goods_id, data.goods_name, this.shopId]
      }
      else if (type == 20) {
        // {"type":14,"params":["20230901","20230924","2","1674250188351930369","消肿止痛贴(1贴*20贴/盒)"]} 采购用户
        obj['params'] = [startTime, endTime, String(this.controlSelectType), data.goods_id, data.goods_name, this.shopId]
      }
      // let obj = {
      //     type: 2,
      //     params:['202308','2','1674250188351930369','消肿止痛贴(1贴*20贴/盒)','1661257547503419393','202308'] //goodsid  goodsname   shopid
      // }
      // let obj = {
      //     type: 3,
      //     params:['202308','2','1696371675141570561' ] //threeclassid
      // }
      const resp = await queryGoodsSalesEcharts(obj)
      if (resp && resp.length) {
        if (type == 11 || type == 15 || type == 17 || type == 21) {
          // 按月1  按年4 
          if (resp.length > 10) {
            this.tableData = resp.slice(10, resp.length)
            this.top10 = resp.slice(0, 10)
          } else {
            this.tableData = []
            this.top10 = resp
          }
          const data = resp[0]
          this.firstSelectData = data
          if (this.monthOrYear != 'year') {
            if (this.shopId) {
              this.getData(18, data)
            } else {
              this.getData(12, data)
            }
          } else {
            if (this.shopId) {
              this.getData(19, data)
            } else {
              this.getData(13, data)
            }
          }
          if (this.shopId) {
            this.getData(20, data)
          } else {
            this.getData(14, data)
          }
          this.setTableFirst(0)


        } else if (type == 12 || type == 13 || type == 18 || type == 19) {
          this.setTableSecond(resp || [], data)
        } else if (type == 14 || type == 20) {
          const firstData = this.firstSelectData
          let didSelect = {}
          resp.forEach(e => {
            if (e.goods_id == firstData.goods_id && e.goods_name == firstData.goods_name && e.shop_id == firstData.shop_id) {
              e['select'] = true
              didSelect = e
            }
          });
          this.categoryTableData = resp
          setTimeout(() => {
            this.$nextTick(() => {
              this.$refs.hilightTable && this.$refs.hilightTable.setCurrentRow(didSelect);
            })
          }, 500);
        }

      } else {
        if (type == 11 || type == 15 || type == 17 || type == 21) {
          this.resetDefault()
        } else if (type == 12 || type == 13 || type == 18 || type == 19) {
          this.chartOptions = undefined
        } else if (type == 14 || type == 20) {
          this.categoryTableData = []
        }
      }
    },
    getRowKeys (row) {
      return row.goods_id + row.goods_name + row.shop_id
    },
    monthYearChange (e) {
      // 切换年 或者 月
      this.state = ''
      this.monthOrYear = e
      if (e != 'custom') {
        if (this.controlSelectTypeXiaoshou == 1) {
          if (this.shopId) {
            this.getData(17)
          } else {
            this.getData(11)
          }
        } else {
          if (this.shopId) {
            this.getData(21)
          } else {
            this.getData(15)
          }
        }
      }

    },
    getMonthArr () {
      // 初始化月份数组
      let m = Number(dayjs().format('MM'))
      let monthArr = []
      let that = this
      for (let index = 1; index <= m; index++) {
        if (!(new Date().getDate() == 1 && index == m)) {
          monthArr.unshift({ m: index < 10 ? '0' + index : index, str: index + '月' })
        }
      }
      that.monthSelectIndex = 0
      that.monthArr = monthArr
    },
    getPercent (v) {
      if (!v) {
        return '0.00'
      }
      return (v * 100).toFixed(2)
    },
    dropdownClick (e) {
      // 下拉选择控销或者普药
      this.state = ''
      this.controlSelectType = e
      if (this.controlSelectTypeXiaoshou == 1) {
        if (this.shopId) {
          this.getData(17)
        } else {
          this.getData(11)
        }
      } else {
        if (this.shopId) {
          this.getData(21)
        } else {
          this.getData(15)
        }
      }

    },
    dropdownClickXiaoshou (e) {
      // 下拉选择销售金额或者数量
      this.state = ''
      console.log(e, 'e')
      this.controlSelectTypeXiaoshou = e
      if (this.controlSelectTypeXiaoshou == 1) {
        if (this.shopId) {
          this.getData(17)
        } else {
          this.getData(11)
        }
      } else {
        if (this.shopId) {
          this.getData(21)
        } else {
          this.getData(15)
        }
      }

    },
    resetDefault () {
      // 重置默认数据
      this.chartOptions = undefined
      this.top10 = []
      this.tableData = []
      this.categoryTableData = []
      this.setTableFirst()
    },
    handleClassName (e) {
      if (e.row.select) {

        return 'qwer'
      }
    },
    handleClick (e) {
      this.state = ''
      // 点击月份
      this.monthSelectIndex = e.index
      if (this.controlSelectTypeXiaoshou == 1) {
        if (this.shopId) {
          this.getData(17)
        } else {
          this.getData(11)
        }
      } else {
        if (this.shopId) {
          this.getData(21)
        } else {
          this.getData(15)
        }
      }

    },
    handleCurrentChange (e) {
      // 点击第一个table的某一项
      this.firstSelectData = e
      if (this.monthOrYear != 'year') {
        if (this.shopId) {
          this.getData(18, e)
        } else {
          this.getData(12, e)
        }
      } else {
        if (this.shopId) {
          this.getData(19, e)
        } else {
          this.getData(13, e)
        }
      }
      if (this.shopId) {
        this.getData(20, e)
      } else {
        this.getData(14, e)
      }
      this.setTableFirst(99999)
    },
    setTableFirst (selectIndex = 0, data) {
      // 设置销量排名图表
      let tmp = []
      let arr = data ? data : this.top10
      arr.forEach((e, index) => {
        if (index == selectIndex) {
          // {linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },stops: [[0, "#FBBC5E"],[1, "#F99B0D"]]}
          if (this.controlSelectTypeXiaoshou == '1') {
            tmp.push({ ...e, name: 'TOP' + (index + 1), y: parseInt(e.qty), color: '#73DEB3' })
          } else {
            tmp.push({ ...e, name: 'TOP' + (index + 1), y: Number(e.amount), color: '#73DEB3' })
          }

        } else {
          // tmp.push({ ...e, name: 'TOP' + (index + 1), y: parseInt(e.qty), color: '#73A0FA' })
          if (this.controlSelectTypeXiaoshou == '1') {
            tmp.push({ ...e, name: 'TOP' + (index + 1), y: parseInt(e.qty), color: '#73A0FA' })
          } else {
            tmp.push({ ...e, name: 'TOP' + (index + 1), y: Number(e.amount), color: '#73A0FA' })
          }

        }

      });

      let _this = this

      let toolTipStr = this.controlSelectTypeXiaoshou != "1" ? "point.y:.2f" : "point.y"

      this.lineOptions = {
        credits: {
          enabled: false
        },
        exporting: { enabled: false },
        chart: {
          type: 'column',
          height: this.tableHeight,
          marginTop: '60',
        },

        title: {
          show: false,
          align: 'left',
          text: `<span style="color:#262626;font-size:16px;font-weight:600;"></span>`
        },
        // subtitle: {
        //     align: 'left',
        //     text: '数据更新时间'+ dayjs().subtract(1,'day').format('YYYY-MM-DD')
        // },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          title: {
            text: ''
          }

        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            // allowPointSelect: true,// 是否允许选中点
            events: {
              click: function (e) {
                const index = e.point.index
                _this.$refs.firstTable && _this.$refs.firstTable.setCurrentRow();
                _this.firstSelectData = e.point.options
                _this.setTableFirst(index)
                if (_this.monthOrYear != 'year') {
                  if (_this.shopId) {
                    _this.getData(18, e.point)
                  } else {
                    _this.getData(12, e.point)
                  }
                } else {
                  if (_this.shopId) {
                    _this.getData(19, e.point)
                  } else {
                    _this.getData(13, e.point)
                  }
                }
                // _this.getData(2, e.point.options)
                // _this.getData(14, e.point.options)
                if (_this.shopId) {
                  _this.getData(20, e.point.options)
                } else {
                  _this.getData(14, e.point.options)
                }

              },
            },
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              // format: '{point.y}'
              format: `{${toolTipStr}}`
            }
          }
        },
        tooltip: {
          headerFormat: `<span style="font-size:11px">{point.goods_name}</span><br>`,
          pointFormat: `<span style="font-size:14px">{point.goods_factory}</span><br><span style="color:{point.color}">{point.goods_name}</span>: <b>{${toolTipStr}}</b><br/>`
        },
        series: [
          {
            name: '',
            colorByPoint: false,
            data: tmp
          }
        ],
      }
    },
    setTableSecond (arr, data) {
      // 设置销售趋势图表
      let toolTipStr = this.controlSelectTypeXiaoshou != "1" ? "point.y:.2f" : "point.y"
      let tmp = []
      let days = []
      let name = ''
      arr.forEach(e => {
        days.push(e.id)
        if (this.controlSelectTypeXiaoshou == '1') {
          tmp.push(parseFloat(e.qty))
          name = '数量'
        } else {
          tmp.push(parseFloat(e.amount))
          name = '销售额'
        }
      });
      this.chartOptions = {
        colors: ['#F5222D'],
        credits: {
          enabled: false
        },
        chart: {
          height: this.tableHeight - 24
        },
        exporting: { enabled: false },
        title: {
          text: `<span style="color:#262626;font-size:16px;font-weight:600;">销售趋势<span style="color:#595959;font-size:12px;font-weight:400;">&nbsp;&nbsp;${data.goods_factory}</span></span>`,
          align: 'left',
          margin: 35
        },
        // subtitle: {
        //     text: data.shop_name,
        //     align: 'left'
        // },
        yAxis: {
          title: {
            enabled: false
            // text: '就业人数'
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          spline: {
            marker: {
              radius: 4,
              lineColor: '#666666',
              lineWidth: 1
            }
          }
        },
        xAxis: {
          categories: days,
        },
        tooltip: {
          headerFormat: `<span style="font-size:14px">{point.x}</span>`,
          pointFormat: `<span style="font-size:14px"></span><br><span style="color:{point.color}">${name}</span>: <b>{${toolTipStr}}</b><br/>`
        },
        series: [{
          name: name,
          data: tmp
        }],
      }
    },

    // 切换年份
    handleClickYear () {
      this.state = ''
      if (this.controlSelectTypeXiaoshou == 1) {
        if (this.shopId) {
          this.getData(17)
        } else {
          this.getData(11)
        }
      } else {
        if (this.shopId) {
          this.getData(21)
        } else {
          this.getData(15)
        }
      }
    },

    // 获取本年的所有周数据
    getWeekTime () {
      var start = new Date(dayjs().format('YYYY') + '-01-01');
      var end = new Date(dayjs().format('YYYY-MM-DD'));
      var sDate;
      var eDate;
      var dateArr = [];

      while (start <= end) {
        if (start.getDay() == 1 || (dateArr.length == 0 && !sDate)) {
          sDate = dayjs(new Date(start.getTime())).format('YYYY-MM-DD');
        }

        if ((sDate && start.getDay() == 0) || start.getTime() == end.getTime()) {
          eDate = dayjs(new Date(start.getTime())).format('YYYY-MM-DD');
        }
        if (sDate == eDate && (new Date(sDate).getDay() == 1) && (new Date(eDate).getDay() == 1)) {
          sDate = undefined;
          eDate = undefined;
        }
        if (eDate == dayjs(end).format('YYYY-MM-DD')) {
          eDate = dayjs().add(-1, 'day').format('YYYY-MM-DD');
        }
        if (sDate && eDate) {
          dateArr.push({ 'startDate': sDate, 'endDate': eDate });
          sDate = undefined;
          eDate = undefined;
        }

        start.setDate(start.getDate() + 1);
      }
      this.weeksSelectIndex = dateArr[dateArr.length - 1]
      return dateArr.reverse();
    },
    // 获取年份
    getYearArr () {
      // 数据从2023年开始  之前没有数据
      if (Number(new Date().getFullYear()) > 2023) {
        for (let index = 0; index < Number(new Date().getFullYear()) - 2023; index++) {
          if (!(new Date().getDate() == 1 && new Date().getFullYear() == (2023 + index + 1) && new Date().getMonth() == 0)) {
            this.yearArr.push(2023 + index + 1 + '')
          }
        }
      }
    }
  }

}

</script>
<style lang="scss" scoped>
$eachWidth: var(--row-width, "500px");
$mainHeight: var(--main-height, "600px");

::v-deep {
  .el-input--mini .el-input__inner {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 30px;
  }
}
.mainView {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  height: 100%;
  width: 100%;
  .topView {
    background-color: #fff;
    padding: 12px;
    padding-top: 0px;
    border-radius: 4px;
    overflow: hidden;
    ::v-deep .buttonView {
      display: flex;
      align-items: center;
      .tabsTeShu {
        .el-tabs__item {
          font-size: 14px;
          font-weight: 400;
        }
        .el-tabs__item.is-active {
          font-weight: 700;
        }
      }
    }
  }

  .goodsSalesStatMain {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: calc(100% - 165px - 12px);
    margin-top: 12px;

    .flexLineView {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: $eachWidth;

      .row {
        width: 100%;

        background-color: #fff;
        border-radius: 4px;
        overflow: hidden;
        height: calc(($mainHeight / 2));
        padding: 12px;
        position: relative;
      }
    }

    .mainHeight {
      height: $mainHeight + 24px;
      border-radius: 4px;
      overflow: hidden;

      background-color: #fff;
      position: relative;
      padding: 12px;
    }
    .dropDownBtn {
      position: absolute;
      top: 12px;
      left: 24px;
    }
    .numberView {
      position: absolute;
      top: 0px;
      left: 0px;
      .numberImg {
        width: 20px;
        height: 20px;
      }
    }
    .leftHalf {
      height: calc((($mainHeight) / 2));
    }
    .lineViewHorizon {
      height: 12px;
    }
    .secondTableView {
      position: relative;
      padding: 12px;
      background-color: #fff;
      border-radius: 4px;
      overflow: hidden;
      .classNameView {
        display: flex;
        align-items: center;
        height: 30px;
        font-size: 14px;
        color: #595959;
        background-color: #fff;
        padding-left: 12px;
      }
    }
    .lineView {
      width: 12px;
    }
  }
  .percentView {
    position: relative;
    z-index: 999;
    background-color: #f0f0f0;
    .percentBg {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: -1;
      background-color: #fbabaf;
    }
    .percentText {
      height: 100%;
      width: 100%;
      z-index: 999;
      text-align: right;
    }
  }
}
</style>