var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "", visible: _vm.dialogShow, width: "60%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogShow = $event
        },
      },
    },
    [
      _c("div", { staticClass: "dialogBox" }, [
        _c("iframe", {
          staticStyle: { width: "100%", height: "100%", border: "none" },
          attrs: {
            src: "https://yaochanchan.com/serviceAgreement",
            title: "《药潺潺用户服务协议》",
          },
        }),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.notAgree } }, [
            _vm._v("不 同 意"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.agree } },
            [_vm._v("同 意")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }