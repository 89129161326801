var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c("div", { staticClass: "top fl_bet" }, [
        _c("div", { staticClass: "name" }, [
          _vm._v(
            "购物车（全部" + _vm._s((_vm.list && _vm.list.length) || 0) + "）"
          ),
        ]),
      ]),
      _c("publicTable", {
        ref: "publicTable",
        attrs: {
          "table-data": _vm.list,
          "colum-obj": _vm.columObj,
          "page-obj": _vm.pageObj,
        },
        on: {
          switchChange: _vm.switchChange,
          editInputBlur: _vm.editInputBlur,
          rowClick: _vm.rowClick,
          handleSizeChange: _vm.handleSizeChange,
          handleCurrentChange: _vm.handleCurrentChange,
          handleSelectionChange: _vm.handleSelectionChange,
        },
      }),
      _c("div", { staticClass: "bottom fl_bet top" }, [
        _c(
          "div",
          { staticClass: "feature fl_center" },
          [
            _c("div", { staticClass: "sel" }, [
              _vm._v(" 已选择"),
              _c("b", [
                _vm._v(
                  _vm._s(
                    (_vm.multipleSelection && _vm.multipleSelection.length) || 0
                  )
                ),
              ]),
              _vm._v("种商品 "),
            ]),
            _c("div", { staticClass: "add_all fl_bet" }, [
              _c(
                "span",
                { staticClass: "sel", staticStyle: { "margin-right": "8px" } },
                [_vm._v("商品合计：")]
              ),
              _c(
                "span",
                {
                  staticClass: "price",
                  staticStyle: { "margin-right": "16px" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.isLoading ? "计算中" : "¥ " + _vm.totalMoney)
                  ),
                ]
              ),
            ]),
            _c(
              "el-button",
              {
                staticClass: "closing",
                attrs: { type: "primary" },
                on: { click: _vm.goDetail },
              },
              [_vm._v("去结算")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }