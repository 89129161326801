var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "classification" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: {
              click: function ($event) {
                return _vm.add()
              },
            },
          },
          [_vm._v("新增控销组")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "center", staticStyle: { position: "relative" } },
      [
        _c("Tabs", {
          attrs: { tabsList: _vm.tabsList, activeName: _vm.activeName },
          on: {
            "tab-click": _vm.handleClick,
            "update:activeName": function ($event) {
              _vm.activeName = $event
            },
            "update:active-name": function ($event) {
              _vm.activeName = $event
            },
          },
        }),
        _c("MyTable", {
          attrs: {
            "table-data": _vm.tableData,
            index: _vm.index,
            show: false,
            operation: _vm.operation,
            "table-option": _vm.tableOption,
          },
          on: {
            "update:tableOption": function ($event) {
              _vm.tableOption = $event
            },
            "update:table-option": function ($event) {
              _vm.tableOption = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "menu",
              fn: function (scope) {
                return [
                  _c(
                    "el-link",
                    {
                      staticClass: "btn",
                      attrs: { underline: false, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.edit(scope.row)
                        },
                      },
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "el-link",
                    {
                      staticClass: "btn",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { underline: false, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteData(scope.row)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }