var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "oss-file-upload-root", style: _vm.varStyle },
    [
      _vm.ossUpload
        ? _c(
            "el-upload",
            {
              ref: "refUpload",
              class: { "oss-file-upload": true, disabled: _vm.disabled },
              attrs: {
                "auto-upload": _vm.autoUpload,
                action: _vm.action,
                "on-preview": _vm.handlePreview,
                "on-remove": _vm.handleRemove,
                "before-remove": _vm.beforeRemove,
                limit: _vm.limit,
                "on-exceed": _vm.handleExceed,
                "file-list": _vm.fileList,
                "on-success": _vm.onSuccessUpload,
                disabled: _vm.disabled,
                accept: _vm.accept,
                "before-upload": _vm.onBeforeUpload,
                "on-change": _vm.handleVideoChange,
                "on-error": _vm.onError,
                multiple: _vm.multiple,
                "list-type": _vm.listType,
                "http-request": _vm.onHttpRequest,
              },
            },
            [
              !_vm.disabled
                ? _c(
                    "div",
                    { staticStyle: { display: "inline-block" } },
                    [
                      _vm.listType !== "picture-card"
                        ? _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v(" 点击上传 ")]
                          )
                        : _c("i", {
                            staticClass: "el-icon-plus",
                            attrs: { slot: "default" },
                            slot: "default",
                          }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _c(
            "el-upload",
            {
              ref: "refUpload",
              class: { "oss-file-upload": true, disabled: _vm.disabled },
              attrs: {
                "auto-upload": _vm.autoUpload,
                action: _vm.action,
                "on-preview": _vm.handlePreview,
                "on-remove": _vm.handleRemove,
                "before-remove": _vm.beforeRemove,
                limit: _vm.limit,
                "on-exceed": _vm.handleExceed,
                "file-list": _vm.fileList,
                "on-success": _vm.onSuccessUpload,
                disabled: _vm.disabled,
                accept: _vm.accept,
                "before-upload": _vm.onBeforeUpload,
                "on-change": _vm.handleVideoChange,
                "on-error": _vm.onError,
                multiple: _vm.multiple,
                "list-type": _vm.listType,
              },
            },
            [
              !_vm.disabled
                ? _c(
                    "div",
                    { staticStyle: { display: "inline-block" } },
                    [
                      _vm.listType !== "picture-card"
                        ? _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v(" 点击上传 ")]
                          )
                        : _c("i", {
                            staticClass: "el-icon-plus",
                            attrs: { slot: "default" },
                            slot: "default",
                          }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
      _vm.showViewer
        ? _c("el-image-viewer", {
            staticClass: "my-preview-viewer",
            attrs: { "on-close": _vm.closeViewer, "url-list": _vm.urls },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }