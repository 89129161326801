var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择单位",
            visible: _vm.visible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialogBox" },
            _vm._l(_vm.unitList, function (item, index) {
              return _c(
                "span",
                {
                  key: index,
                  class: _vm.checkIndex == index ? "activeBox" : "everyOneBox",
                  on: {
                    click: function ($event) {
                      return _vm.submitCheck(item, index)
                    },
                  },
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }