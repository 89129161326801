var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "classification",
    },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "staus_bar fl_bet border" }, [
          _c("div", { staticClass: "detail_left" }, [
            _c("div", { staticClass: "fl_cen" }, [
              _c(
                "div",
                { staticClass: "status" },
                [
                  _c("el-image", {
                    staticClass: "icon_status",
                    attrs: { src: _vm.getStatus(1, _vm.orderList.status) },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "name",
                      class: _vm.getStatus(3, _vm.orderList.status),
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getStatus(2, _vm.orderList.status)) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("订单编号")]),
                _c("div", { staticStyle: { height: "22px" } }, [
                  _vm._v(_vm._s(_vm.orderList.orderCode || "")),
                ]),
              ]),
              _c("div", { staticClass: "items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("下单时间")]),
                _c("div", { staticStyle: { height: "22px" } }, [
                  _vm._v(_vm._s(_vm.orderList.orderTime || "")),
                ]),
              ]),
              _vm.orderList.status == 1 && _vm.orderList.payTime
                ? _c("div", { staticClass: "items" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("付款时间")]),
                    _c(
                      "div",
                      { staticStyle: { height: "22px", color: "#e4393c" } },
                      [_vm._v(" " + _vm._s(_vm.orderList.payTime || " ") + " ")]
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("售后电话")]),
                _c("div", [
                  _vm._v(" " + _vm._s(_vm.orderList.servicePhone || " ") + " "),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "detail_right" },
            [
              Number(_vm.orderList.status) == 8 ||
              Number(_vm.orderList.status) == 3
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger emphasis_btn" },
                      on: { click: _vm.sureGoods },
                    },
                    [_vm._v("确认收货")]
                  )
                : _vm._e(),
              Number(_vm.orderList.status) == 1 ||
              Number(_vm.orderList.status) == 2
                ? _c(
                    "el-button",
                    {
                      staticClass: "cancel_btn",
                      attrs: { loading: _vm.isLoading },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.cancelClick(_vm.id)
                        },
                      },
                    },
                    [_vm._v("取消订单")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "staus_plan fl_left" }, [
          _c(
            "div",
            { staticClass: "step", class: _vm.indexStep >= 1 ? "active" : "" },
            [
              _c("div", { staticClass: "fl_center" }, [
                _c(
                  "div",
                  { staticClass: "status" },
                  [
                    _vm.indexStep > 1
                      ? _c("el-image", {
                          staticClass: "icon_svg",
                          attrs: { src: _vm.icon },
                        })
                      : _c("div", { staticClass: "idx" }, [_vm._v("1")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "name",
                    class: _vm.indexStep > 1 ? "sueccss" : "",
                  },
                  [_vm._v(" 提交订单 ")]
                ),
              ]),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.orderList.orderTime || "")),
              ]),
            ]
          ),
          _c("div", {
            staticClass: "line",
            class: _vm.indexStep >= 1 ? "active" : "",
          }),
          _c(
            "div",
            { staticClass: "step", class: _vm.indexStep >= 2 ? "active" : "" },
            [
              _c("div", { staticClass: "fl_center" }, [
                _c(
                  "div",
                  { staticClass: "status fl_center" },
                  [
                    _vm.indexStep > 2
                      ? _c("el-image", {
                          staticClass: "icon_svg",
                          attrs: { src: _vm.icon },
                        })
                      : _c("div", { staticClass: "idx" }, [_vm._v("2")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "name",
                    class: _vm.indexStep > 2 ? "sueccss" : "",
                  },
                  [_vm._v(" 付款成功 ")]
                ),
              ]),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.orderList.payTime || "")),
              ]),
            ]
          ),
          _c("div", {
            staticClass: "line",
            class: _vm.indexStep >= 2 ? "active" : "",
          }),
          _c(
            "div",
            { staticClass: "step", class: _vm.indexStep >= 3 ? "active" : "" },
            [
              _c("div", { staticClass: "fl_center" }, [
                _c(
                  "div",
                  { staticClass: "status" },
                  [
                    _vm.indexStep > 3
                      ? _c("el-image", {
                          staticClass: "icon_svg",
                          attrs: { src: _vm.icon },
                        })
                      : _c("div", { staticClass: "idx" }, [_vm._v("3")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "name",
                    class: _vm.indexStep > 3 ? "sueccss" : "",
                  },
                  [_vm._v(" 商品出库 ")]
                ),
              ]),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.orderList.deliveryTime || "")),
              ]),
            ]
          ),
          _c("div", {
            staticClass: "line",
            class: _vm.indexStep >= 3 ? "active" : "",
          }),
          _c(
            "div",
            { staticClass: "step", class: _vm.indexStep >= 4 ? "active" : "" },
            [
              _c("div", { staticClass: "fl_center" }, [
                _c(
                  "div",
                  { staticClass: "status" },
                  [
                    _vm.indexStep > 4
                      ? _c("el-image", {
                          staticClass: "icon_svg",
                          attrs: { src: _vm.icon },
                        })
                      : _c("div", { staticClass: "idx" }, [_vm._v("4")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "name",
                    class: _vm.indexStep > 4 ? "sueccss" : "",
                  },
                  [_vm._v(" 配送完成 ")]
                ),
              ]),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.orderList.signDate || "")),
              ]),
            ]
          ),
          _c("div", {
            staticClass: "line",
            class: _vm.indexStep >= 4 ? "active" : "",
          }),
          _c(
            "div",
            { staticClass: "step", class: _vm.indexStep >= 5 ? "active" : "" },
            [
              _c("div", { staticClass: "fl_center" }, [
                _c(
                  "div",
                  { staticClass: "status" },
                  [
                    _vm.indexStep > 5
                      ? _c("el-image", {
                          staticClass: "icon_svg",
                          attrs: { src: _vm.icon },
                        })
                      : _c("div", { staticClass: "idx" }, [_vm._v("5")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "name",
                    class: _vm.indexStep > 5 ? "sueccss" : "",
                  },
                  [_vm._v(" 交易完成 ")]
                ),
              ]),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.orderList.finishDate || "")),
              ]),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "info card" },
        [
          _vm.orderLogisticsList.length > 0
            ? _c(
                "el-tabs",
                {
                  staticClass: "headerTabs",
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.orderLogistics,
                    callback: function ($$v) {
                      _vm.orderLogistics = $$v
                    },
                    expression: "orderLogistics",
                  },
                },
                _vm._l(_vm.orderLogisticsList, function (item, index) {
                  return _c(
                    "el-tab-pane",
                    {
                      key: index,
                      attrs: {
                        label: item.label,
                        name: item.expressDeliveryNumber,
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _vm._v(
                          " " +
                            _vm._s(item.kuaidiName) +
                            " " +
                            _vm._s(item.expressDeliveryNumber) +
                            " "
                        ),
                      ]),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { name: "1" } },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "div",
                      {
                        staticStyle: { "line-height": "20px", height: "80px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#f56c6c",
                              "font-weight": "600",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.expressData.step || "暂无物流数据") +
                                " "
                            ),
                          ]
                        ),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.expressData.description || "") +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { "padding-top": "10px" } },
                    [
                      _vm.expressData.child && _vm.expressData.child.length > 0
                        ? _c(
                            "el-timeline",
                            _vm._l(
                              _vm.expressData.child,
                              function (activity, index) {
                                return _c(
                                  "el-timeline-item",
                                  { key: index, attrs: { color: "#F5222D" } },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { "font-weight": "600" } },
                                      [_vm._v(_vm._s(activity.step || ""))]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(activity.description || "")
                                      ),
                                    ]),
                                    activity.image
                                      ? _c("el-image", {
                                          staticStyle: {
                                            display: "block",
                                            width: "100px",
                                            height: "100px",
                                          },
                                          attrs: {
                                            src: activity.image || "",
                                            "preview-src-list": [
                                              activity.image || "",
                                            ],
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "info card fl_cen" }, [
        _c("div", { staticClass: "info_list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("收件人信息")]),
          _c("div", { staticClass: "lists" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("收货人：")]),
              _vm._v(_vm._s(_vm.orderList.contactsName || "--") + " "),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("地址：")]),
              _vm._v(
                _vm._s(_vm.orderList.province || "") +
                  _vm._s(_vm.orderList.city || "") +
                  _vm._s(_vm.orderList.district || "") +
                  _vm._s(_vm.orderList.address || "") +
                  " "
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("手机号：")]),
              _vm._v(_vm._s(_vm.orderList.contactsPhone || "--") + " "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "info_list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("配送信息")]),
          _vm.orderList.beDelivery == "1"
            ? _c("div", { staticClass: "lists" }, [
                _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("司机姓名：")]),
                  _vm._v(_vm._s(_vm.orderList.deliveryDriver || "--") + " "),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("联系方式：")]),
                  _vm._v(_vm._s(_vm.orderList.driverPhone || "--") + " "),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("预计送达：")]),
                  _vm._v(_vm._s(_vm.orderList.finishDate || "--") + " "),
                ]),
              ])
            : _c("div", { staticClass: "lists" }, [
                _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("快递公司：")]),
                  _vm._v(_vm._s(_vm.orderList.kuaidiName || "--") + " "),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("快递单号：")]),
                  _vm._v(_vm._s(_vm.orderList.number || "--") + " "),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("预计送达：")]),
                  _vm._v(_vm._s(_vm.orderList.finishDate || "--") + " "),
                ]),
              ]),
        ]),
        _c("div", { staticClass: "info_list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("付款信息")]),
          _c("div", { staticClass: "lists" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("商品总额：")]),
              _vm._v("￥" + _vm._s(_vm.orderList.amount || "--") + " "),
            ]),
            _vm._m(0),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("付款时间：")]),
              _vm._v(_vm._s(_vm.orderList.payTime || "") + " "),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "commodity card" },
        [
          _c("publicTable", {
            attrs: {
              "table-data": _vm.list,
              "colum-obj": _vm.columObj,
              "page-obj": _vm.pageObj,
            },
          }),
          _c("div", { staticClass: "price_detail" }, [
            _c("div", { staticClass: "items" }, [
              _c("span", { staticClass: "left" }, [_vm._v("商品件数：")]),
              _c("span", { staticClass: "right" }, [
                _vm._v(_vm._s(_vm.orderList.sumQty || 0) + "件"),
              ]),
            ]),
            _c("div", { staticClass: "items" }, [
              _c("span", { staticClass: "left" }, [_vm._v("商品总价：")]),
              _c("span", { staticClass: "right" }, [
                _vm._v("¥" + _vm._s(_vm.orderList.amount || 0)),
              ]),
            ]),
            _c("div", { staticClass: "items" }, [
              _c("span", { staticClass: "left" }, [_vm._v("运费：")]),
              _c("span", { staticClass: "right" }, [
                _vm._v("¥" + _vm._s(_vm.orderList.freightPrice || 0)),
              ]),
            ]),
            _c("div", { staticClass: "items" }, [
              _c("span", { staticClass: "left" }, [_vm._v("应付总额：")]),
              _c("span", { staticClass: "right all" }, [
                _vm._v("¥" + _vm._s(_vm.orderList.totalPrice || 0)),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "codeCont",
          attrs: {
            visible: _vm.isQrCode,
            width: "800px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.qrCodeBtn,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isQrCode = $event
            },
          },
        },
        [
          _c("div", { staticClass: "qrcodeCon" }, [
            _c("p", { staticClass: "qrTip" }, [
              _vm._v("请尽快付款！订单号：" + _vm._s(_vm.orderList.orderCode)),
            ]),
            _c("p", { staticClass: "qrdesc" }, [
              _vm._v("请您在5分钟内完成支付，否则订单会被自动取消！"),
            ]),
            _c("div", { staticClass: "qrCont" }, [
              _c("p", [
                _vm._v(" 应付金额： "),
                _c("span", [_vm._v("¥" + _vm._s(_vm.total))]),
              ]),
              _c("div", {
                ref: "qrcode",
                staticClass: "qrcodeImg",
                attrs: { id: "qrcode" },
              }),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确认收货",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("确认收货后将无法发起售后")]),
          _vm.url
            ? _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c("el-image", {
                    staticStyle: { width: "60px", height: "60px" },
                    attrs: { src: _vm.url, "preview-src-list": [_vm.url] },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "imgList_li_close",
                      on: {
                        click: function ($event) {
                          return _vm.imgDel(_vm.index, _vm.i)
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-delete" })]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
              },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.url != "",
                        type: "primary",
                        size: "small",
                      },
                      on: { click: _vm.goUpload },
                    },
                    [_vm._v("上传图片")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("MyUpload", { ref: "imgupload", on: { upload: _vm.uploadSubmit } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("span", [_vm._v("支付方式：")]),
      _vm._v("在线支付"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }