<template>
  <div class="filedsContainer">
    <div class="head">
      <div class="title">{{ shopName }}</div>
      <div class="font-tip">
        企业类型：{{ shopTypeName }}
        <MyTypes v-if="this.status != null"
                 :type="String(this.status)" />
        <div style="margin-left: auto;">
          <el-button size="small"
                     type="primary"
                     @click="downLoad"
                     :loading="loading">下载资质</el-button>
        </div>
      </div>
    </div>
    <div class="body">
      <userInfoDetail ref="InfoDetail"
                      :infoDetailData="infoDetailData"
                      :disabled="disabled"
                      :type="type"
                      :queryScopeListData="queryScopeListData" />
    </div>
    <div class="footer">
      <el-button size="mini"
                 @click="back">返回上一页</el-button>
    </div>
  </div>
</template>
  <script>
import userInfoDetail from "./children/userInfoDetail.vue";
import {
  getCertificationByShopId,
} from "@/api/myQualification";
import {
  queryScopeList
} from "@/api/comList";

import MyTypes from "@/components/myTypes";
import { downloadShopcertify } from '@/api/auditcenter'
export default {
  name: "QAuditDetail",
  components: { userInfoDetail, MyTypes },
  watch: {
    // modeValue: {
    //   immediate: true,
    //   handler: function (value) {
    //     console.log(value, "value");
    //   },
    // },
  },
  data () {
    return {
      loading: false,
      type: "view", // view
      status: null,
      roleId: null,
      relationType: null,
      shopTypeName: null,
      shopName: null,
      orgCode: null,
      deptId: null,
      disabled: true,
      remarks: "",
      infoDetailData: {},
      queryScopeListData: [],
    };
  },
  methods: {
    back () {
      this.$router.go(-1);
    },
    // 资质下载
    downLoad () {
      this.loading = true
      const {
        shopId,
      } = this.$route.query;
      downloadShopcertify(shopId).then(res => {
        this.loading = false
        if (res.code == 0) {

        } else {
          this.$message.error('资质下载出错')
        }
      })
        .catch(() => {
          this.loading = false
        })
    },
  },
  mounted () {
    // 查经营范围
    const {
      shopId,
    } = this.$route.query;
    queryScopeList().then((res) => {
      this.queryScopeListData = res.data;
    });
    if (shopId) {
      getCertificationByShopId(shopId).then((res) => {
        if(res.data ){
          this.infoDetailData = res.data;
          this.status = res.data.status;
          this.shopTypeName = res.data.shopTypeName;
          this.shopName = res.data.shopName;
        }
      });
    }
  },
};
  </script>
  <style lang="scss" scoped>
.filedsContainer {
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow-y: auto;
  .head {
    background: #ffffff;
    height: 76px;
    padding: 10px;
    line-height: 28px;
  }
  .font-tip {
    padding-left: 20px;
    font-size: 12px;
    display: flex;
  }
  .body {
    background: #ffffff;
    padding: 10px;
    margin-top: 10px;
    display: flex;
    flex: 1;
    overflow-y: auto;
  }
  .footer {
    padding: 20px;
    background: #ffffff;
  }
}
</style>
  