var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "harf-block" }, [
    _vm.formIn.status == "FAIL"
      ? _c("div", { staticClass: "form-content" }, [
          _vm._m(0),
          _c("div", { staticClass: "row mutlti big" }, [
            _c("div", { staticClass: "value fill" }, [
              _c("div", { staticClass: "flag-block" }, [
                _vm._v(" " + _vm._s(_vm.formIn.rejectReason) + " "),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.showFormLeft
      ? _c("div", { staticClass: "big-block" }, [
          _vm._m(1),
          _c("div", { staticClass: "form-content" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("ERP编码")]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      maxlength: "20",
                    },
                    on: { input: _vm.medicineCodeInput },
                    model: {
                      value: _vm.formIn.medicineCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "medicineCode", $$v)
                      },
                      expression: "formIn.medicineCode",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "positive-integer",
                        rawName: "v-positive-integer",
                      },
                    ],
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      disabled: _vm.formIn.beSynStock,
                      maxlength: "8",
                    },
                    model: {
                      value: _vm.formIn.goodsSkuInventory,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "goodsSkuInventory", $$v)
                      },
                      expression: "formIn.goodsSkuInventory",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "value fill" },
                [
                  _c(
                    "el-checkbox",
                    {
                      ref: "checkboxSynStock",
                      on: { change: _vm.stockChange },
                      model: {
                        value: _vm.formIn.beSynStock,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "beSynStock", $$v)
                        },
                        expression: "formIn.beSynStock",
                      },
                    },
                    [_vm._v("自动同步库存")]
                  ),
                ],
                1
              ),
            ]),
            _vm.selectType &&
            (_vm.selectType.label == "单品包邮" ||
              _vm.selectType.label == "特价")
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "tip" }, [_vm._v("原价")]),
                  _c(
                    "div",
                    { staticClass: "value input" },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "positive-five-limit",
                            rawName: "v-positive-five-limit",
                          },
                        ],
                        attrs: {
                          size: "small",
                          placeholder: "请输入",
                          disabled: _vm.formIn.beSynPrice,
                        },
                        model: {
                          value: _vm.formIn.originalPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.formIn, "originalPrice", $$v)
                          },
                          expression: "formIn.originalPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.formIn.businessType === 2
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("价格")]),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "positive-five-limit",
                              rawName: "v-positive-five-limit",
                            },
                          ],
                          attrs: {
                            size: "small",
                            placeholder: "请输入",
                            disabled: true,
                          },
                          model: {
                            value: _vm.formIn.xzztPrice,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "xzztPrice", $$v)
                            },
                            expression: "formIn.xzztPrice",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _c("div", [
                  _vm.selectType &&
                  (_vm.selectType.label == "一口价" ||
                    _vm.selectType.label == "特价")
                    ? _c("div", [
                        _c("div", { staticClass: "row" }, [
                          _vm._m(3),
                          _c(
                            "div",
                            { staticClass: "value input" },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "positive-five-limit",
                                    rawName: "v-positive-five-limit",
                                  },
                                ],
                                attrs: {
                                  size: "small",
                                  placeholder: "请输入",
                                  disabled:
                                    _vm.formIn.participationSubsidy ||
                                    _vm.formIn.beSynPrice,
                                },
                                model: {
                                  value: _vm.formIn.salePrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formIn, "salePrice", $$v)
                                  },
                                  expression: "formIn.salePrice",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.selectType && _vm.selectType.label == "一口价"
                            ? _c(
                                "div",
                                { staticClass: "value fill" },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      on: { change: _vm.erpPriceChange },
                                      model: {
                                        value: _vm.formIn.beSynPrice,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formIn,
                                            "beSynPrice",
                                            $$v
                                          )
                                        },
                                        expression: "formIn.beSynPrice",
                                      },
                                    },
                                    [_vm._v("自动同步价格")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _vm.formIn.participationSubsidy
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#262626",
                                  "font-size": "14px",
                                  "margin-left": "9em",
                                },
                              },
                              [
                                _vm._v(
                                  " 原价：¥" +
                                    _vm._s(_vm.formIn.originalPrice) +
                                    "，此商品正在参加平台补贴，点击去 "
                                ),
                                _c(
                                  "el-link",
                                  {
                                    staticClass: "btn",
                                    attrs: {
                                      underline: false,
                                      type: "primary",
                                    },
                                    on: { click: _vm.xiugaiPrice },
                                  },
                                  [_vm._v("修改价格")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
            _vm.selectType && _vm.selectType.label == "一口价"
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("市场价")]),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "positive-five-limit",
                              rawName: "v-positive-five-limit",
                            },
                          ],
                          attrs: { size: "small", placeholder: "请输入" },
                          model: {
                            value: _vm.formIn.marketPrice,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "marketPrice", $$v)
                            },
                            expression: "formIn.marketPrice",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("建议零售价")]),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "positive-five-limit",
                              rawName: "v-positive-five-limit",
                            },
                          ],
                          attrs: { size: "small", placeholder: "请输入" },
                          model: {
                            value: _vm.formIn.purchasePrice,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "purchasePrice", $$v)
                            },
                            expression: "formIn.purchasePrice",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.selectType && _vm.selectType.label == "单品包邮"
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _vm._m(4),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "positive-five-limit",
                              rawName: "v-positive-five-limit",
                            },
                          ],
                          attrs: { size: "small", placeholder: "请输入" },
                          model: {
                            value: _vm.formIn.groupBuyPrice,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "groupBuyPrice", $$v)
                            },
                            expression: "formIn.groupBuyPrice",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(5),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "positive-integer",
                              rawName: "v-positive-integer",
                            },
                          ],
                          attrs: {
                            size: "small",
                            placeholder: "请输入",
                            maxlength: "4",
                          },
                          model: {
                            value: _vm.formIn.multiple,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "multiple", $$v)
                            },
                            expression: "formIn.multiple",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          color: "#606266",
                          "margin-left": "20px",
                        },
                      },
                      [_vm._v("注意：包邮量必须是购买倍数的整数倍")]
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(6),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "positive-integer",
                              rawName: "v-positive-integer",
                            },
                          ],
                          attrs: {
                            size: "small",
                            placeholder: "请输入",
                            maxlength: "8",
                          },
                          model: {
                            value: _vm.formIn.userMaxTotalBuyCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "userMaxTotalBuyCount", $$v)
                            },
                            expression: "formIn.userMaxTotalBuyCount",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(7),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "positive-integer",
                              rawName: "v-positive-integer",
                            },
                          ],
                          attrs: {
                            size: "small",
                            placeholder: "请输入",
                            maxlength: "8",
                          },
                          model: {
                            value: _vm.formIn.maxGroupTotalCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "maxGroupTotalCount", $$v)
                            },
                            expression: "formIn.maxGroupTotalCount",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.selectType && _vm.selectType.label == "特价"
              ? _c("div", { staticClass: "row" }, [
                  _vm._m(8),
                  _c(
                    "div",
                    { staticClass: "value input" },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "positive-integer",
                            rawName: "v-positive-integer",
                          },
                        ],
                        attrs: {
                          size: "small",
                          placeholder: "请输入",
                          maxlength: "8",
                        },
                        model: {
                          value: _vm.formIn.activeInventory,
                          callback: function ($$v) {
                            _vm.$set(_vm.formIn, "activeInventory", $$v)
                          },
                          expression: "formIn.activeInventory",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "value fill" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.formIn.showInventoryFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "showInventoryFlag", $$v)
                            },
                            expression: "formIn.showInventoryFlag",
                          },
                        },
                        [_vm._v("是否展示给客户剩余库存量")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "row" }, [
              _vm._m(9),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", size: "small" },
                      model: {
                        value: _vm.formIn.goodsSkuUnit,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "goodsSkuUnit", $$v)
                        },
                        expression: "formIn.goodsSkuUnit",
                      },
                    },
                    _vm._l(_vm.unitList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm.selectType &&
              (_vm.selectType.label == "一口价" ||
                _vm.selectType.label == "特价")
                ? _c("div", { staticClass: "tip" }, [
                    _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    _vm._v("起批量"),
                  ])
                : _vm._e(),
              _vm.selectType && _vm.selectType.label == "单品包邮"
                ? _c("div", { staticClass: "tip" }, [
                    _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    _vm._v("包邮量"),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "positive-integer",
                        rawName: "v-positive-integer",
                      },
                    ],
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      maxlength: "4",
                    },
                    model: {
                      value: _vm.formIn.startWholesale,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "startWholesale", $$v)
                      },
                      expression: "formIn.startWholesale",
                    },
                  }),
                ],
                1
              ),
              _vm.selectType && _vm.selectType.label == "一口价"
                ? _c(
                    "div",
                    { staticClass: "value fill" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.formIn.useTimes,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "useTimes", $$v)
                            },
                            expression: "formIn.useTimes",
                          },
                        },
                        [_vm._v("倍数购买")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(10),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "请选择",
                      size: "small",
                      editable: false,
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.formIn.expireTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "expireTime", $$v)
                      },
                      expression: "formIn.expireTime",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(11),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "请选择",
                      size: "small",
                      editable: false,
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.formIn.producedTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "producedTime", $$v)
                      },
                      expression: "formIn.producedTime",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm.selectType && _vm.selectType.label == "特价"
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _vm._m(12),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "positive-integer",
                              rawName: "v-positive-integer",
                            },
                          ],
                          attrs: {
                            size: "small",
                            placeholder: "请输入",
                            maxlength: "4",
                          },
                          model: {
                            value: _vm.formIn.multiple,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "multiple", $$v)
                            },
                            expression: "formIn.multiple",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          color: "#606266",
                          "margin-left": "20px",
                        },
                      },
                      [_vm._v("注意：起拼量必须是购买倍数的整数倍")]
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(13),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "positive-integer",
                              rawName: "v-positive-integer",
                            },
                          ],
                          attrs: {
                            size: "small",
                            placeholder: "请输入",
                            maxlength: "8",
                          },
                          on: { input: _vm.changePurchaseUpperLimitInput },
                          model: {
                            value: _vm.formIn.purchaseUpperLimit,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "purchaseUpperLimit", $$v)
                            },
                            expression: "formIn.purchaseUpperLimit",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "value fill" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            on: { change: _vm.changePurchaseUpperLimit },
                            model: {
                              value: _vm.formIn.showUpperLimit,
                              callback: function ($$v) {
                                _vm.$set(_vm.formIn, "showUpperLimit", $$v)
                              },
                              expression: "formIn.showUpperLimit",
                            },
                          },
                          [_vm._v("不限")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("上限刷新周期")]),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value: _vm.formIn.upperLimitRefreshCycle,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formIn,
                                  "upperLimitRefreshCycle",
                                  $$v
                                )
                              },
                              expression: "formIn.upperLimitRefreshCycle",
                            },
                          },
                          _vm._l(_vm.upperLimitRefreshCycle, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("上限启用时间")]),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _vm.formIn.upperLimitRefreshCycle == 1
                          ? _c("el-date-picker", {
                              attrs: {
                                "picker-options": _vm.pickerOptions_day,
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                type: "datetime",
                                placeholder: "请选择",
                                size: "small",
                              },
                              model: {
                                value: _vm.formIn.upperLimitStartTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formIn,
                                    "upperLimitStartTime",
                                    $$v
                                  )
                                },
                                expression: "formIn.upperLimitStartTime",
                              },
                            })
                          : _vm._e(),
                        _vm.formIn.upperLimitRefreshCycle == 2
                          ? _c("el-date-picker", {
                              attrs: {
                                "picker-options": _vm.pickerOptions_day,
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                type: "datetime",
                                placeholder: "请选择",
                                size: "small",
                              },
                              model: {
                                value: _vm.formIn.upperLimitStartTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formIn,
                                    "upperLimitStartTime",
                                    $$v
                                  )
                                },
                                expression: "formIn.upperLimitStartTime",
                              },
                            })
                          : _vm._e(),
                        _vm.formIn.upperLimitRefreshCycle == 3
                          ? _c("el-date-picker", {
                              attrs: {
                                "picker-options": _vm.pickerOptions_week,
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                type: "datetime",
                                placeholder: "请选择",
                                size: "small",
                              },
                              model: {
                                value: _vm.formIn.upperLimitStartTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formIn,
                                    "upperLimitStartTime",
                                    $$v
                                  )
                                },
                                expression: "formIn.upperLimitStartTime",
                              },
                            })
                          : _vm._e(),
                        _vm.formIn.upperLimitRefreshCycle == 4
                          ? _c("el-date-picker", {
                              attrs: {
                                "picker-options": _vm.pickerOptions_month,
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                type: "datetime",
                                placeholder: "请选择",
                                size: "small",
                              },
                              model: {
                                value: _vm.formIn.upperLimitStartTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formIn,
                                    "upperLimitStartTime",
                                    $$v
                                  )
                                },
                                expression: "formIn.upperLimitStartTime",
                              },
                            })
                          : _vm._e(),
                        _vm.formIn.upperLimitRefreshCycle == 5
                          ? _c("el-date-picker", {
                              attrs: {
                                "picker-options": _vm.pickerOptions_day,
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                type: "datetime",
                                placeholder: "请选择",
                                size: "small",
                              },
                              model: {
                                value: _vm.formIn.upperLimitStartTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formIn,
                                    "upperLimitStartTime",
                                    $$v
                                  )
                                },
                                expression: "formIn.upperLimitStartTime",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._m(14),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(15),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            placeholder: "请选择",
                            size: "small",
                            "picker-options": _vm.pickerOptions,
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                          },
                          model: {
                            value: _vm.formIn.activityStartTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "activityStartTime", $$v)
                            },
                            expression: "formIn.activityStartTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "value fill small-tip" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { "true-label": 1, "false-label": 0 },
                            model: {
                              value: _vm.formIn.eventCountdownFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.formIn, "eventCountdownFlag", $$v)
                              },
                              expression: "formIn.eventCountdownFlag",
                            },
                          },
                          [_vm._v("是否显示倒计时样式")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(16),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            "picker-options": _vm.pickerOptions,
                            type: "datetime",
                            "default-time": "23:59:59",
                            placeholder: "请选择",
                            size: "small",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                          },
                          model: {
                            value: _vm.formIn.activityEndTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "activityEndTime", $$v)
                            },
                            expression: "formIn.activityEndTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._m(17),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(18),
                    _c("div", { staticClass: "radio-list" }, [
                      _c(
                        "div",
                        { staticClass: "list-a" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.formIn.uniqueFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formIn, "uniqueFlag", $$v)
                                },
                                expression: "formIn.uniqueFlag",
                              },
                            },
                            [_vm._v("订单中只能有此品一个特价")]
                          ),
                        ],
                        1
                      ),
                      _vm._m(19),
                      _c(
                        "div",
                        { staticClass: "list-a" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.formIn.uniqueFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formIn, "uniqueFlag", $$v)
                                },
                                expression: "formIn.uniqueFlag",
                              },
                            },
                            [_vm._v("能与其他特价商品凑单")]
                          ),
                        ],
                        1
                      ),
                      _vm._m(20),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "big-block" }, [
      _vm._m(21),
      _c("div", { staticClass: "form-content" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(22),
          _c(
            "div",
            { staticClass: "value input" },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入" },
                model: {
                  value: _vm.formIn.goodsSkuTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.formIn, "goodsSkuTitle", $$v)
                  },
                  expression: "formIn.goodsSkuTitle",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "tip" }, [_vm._v("产品批号")]),
          _c(
            "div",
            { staticClass: "value input" },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入" },
                model: {
                  value: _vm.formIn.batchNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.formIn, "batchNumber", $$v)
                  },
                  expression: "formIn.batchNumber",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row mutlti" }, [
          _c("div", { staticClass: "tip" }, [_vm._v("商品图片")]),
          _c(
            "div",
            { staticClass: "value mutlti v" },
            [
              _c(
                "div",
                { staticClass: "in-row" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.formIn.imgRegion,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "imgRegion", $$v)
                        },
                        expression: "formIn.imgRegion",
                      },
                    },
                    [_vm._v("使用平台图片")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.formIn.imgRegion,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "imgRegion", $$v)
                        },
                        expression: "formIn.imgRegion",
                      },
                    },
                    [_vm._v("上传图片")]
                  ),
                ],
                1
              ),
              _vm.formIn.imgRegion === 2
                ? [
                    _c("div", { staticClass: "in-row" }, [
                      _c(
                        "div",
                        { staticClass: "pic-layout" },
                        [
                          _vm._l(
                            _vm.imageUrlList ? _vm.imageUrlList : [],
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "item" },
                                [
                                  _c("el-image", {
                                    ref: `myImg${index}`,
                                    refInFor: true,
                                    staticClass: "icon",
                                    attrs: {
                                      src: item,
                                      fit: "cover",
                                      "initial-index": index,
                                      "preview-src-list": _vm.imageUrlList,
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "preview" },
                                    [
                                      _c("el-image", {
                                        staticClass: "btn-icon",
                                        attrs: { src: _vm.SeeIcon },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlePreview(
                                              `myImg${index}`
                                            )
                                          },
                                        },
                                      }),
                                      _c("el-image", {
                                        staticClass: "btn-icon",
                                        attrs: { src: _vm.DelIcon },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDeleteImage(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "item add-pic",
                              on: {
                                click: function ($event) {
                                  return _vm.handleUpload()
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "image-wrap" },
                                [
                                  _c("el-image", {
                                    attrs: { src: _vm.AddIcon },
                                  }),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "text" }, [
                                _vm._v("上传图片"),
                              ]),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]),
                    _vm._m(23),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "row mutlti big" }, [
          _c("div", { staticClass: "tip" }, [_vm._v("商品标签")]),
          _c("div", { staticClass: "value fill" }, [
            _c(
              "div",
              { staticClass: "flag-block" },
              _vm._l(
                _vm.shopLabelList ? _vm.shopLabelList : [],
                function (item) {
                  return _c(
                    "el-radio",
                    {
                      key: item.id,
                      attrs: { label: item.id },
                      model: {
                        value: _vm.formIn.shopLabelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "shopLabelId", $$v)
                        },
                        expression: "formIn.shopLabelId",
                      },
                    },
                    [_vm._v(" " + _vm._s(item.labelName) + " ")]
                  )
                }
              ),
              1
            ),
          ]),
        ]),
        _vm._m(24),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "tip" }, [_vm._v("商家店铺页排序")]),
          _c(
            "div",
            { staticClass: "value input" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入", maxlength: "5" },
                model: {
                  value: _vm.formIn.sortCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.formIn, "sortCode", $$v)
                  },
                  expression: "formIn.sortCode",
                },
              }),
            ],
            1
          ),
          _vm._m(25),
        ]),
        _c("div", { staticClass: "row mutlti" }, [
          _c("div", { staticClass: "tip" }, [_vm._v("批件药检报告等")]),
          _c(
            "div",
            { staticClass: "value input fill" },
            [
              _c("fileUpload", {
                staticStyle: { "margin-bottom": "10px" },
                on: { onRemoveHandler: _vm.onRemoveHandler },
                model: {
                  value: _vm.fileList,
                  callback: function ($$v) {
                    _vm.fileList = $$v
                  },
                  expression: "fileList",
                },
              }),
              _vm._m(26),
            ],
            1
          ),
        ]),
        _vm.selectType && _vm.selectType.label == "特价"
          ? _c(
              "div",
              { staticClass: "row" },
              [
                _c("div", { staticClass: "tip" }, [_vm._v("商品活动说明")]),
                _c("wangEnduit", {
                  staticStyle: { "margin-left": "20px" },
                  attrs: {
                    value: _vm.formIn.activityDescription,
                    "is-clear": _vm.isClear,
                    isDisabled: _vm.isDisabled,
                  },
                  on: { changeEndit: _vm.watchEndit },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-block" }, [
      _c("span", { staticClass: "text" }, [_vm._v("驳回原因")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-block" }, [
      _c("span", { staticClass: "text" }, [_vm._v("价格/库存")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v(" 总库存"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("价格"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("价格"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("购买倍数"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("单店采购上限"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("采购总上限"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v(" 活动库存"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("单位"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("有效期至"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("生产日期"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("购买倍数"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("单店采购上限"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "value fill small-tip" }, [
      _c("span", { staticStyle: { "font-size": "12px", color: "#606266" } }, [
        _vm._v(
          " 上限启用时间根据周期而定：活动期内- 上架时间起生效；单笔 - 设置后立即生效； 每天 - 设置当天00:00起效；每周 - 设置当周周一00:00起效；每月 - 设置当月1日00:00起效 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("活动开始时间"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("活动结束时间"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "value fill small-tip" }, [
      _c("span", { staticStyle: { "font-size": "12px", color: "#606266" } }, [
        _vm._v(
          " 商品在活动开始时间到活动结束时间之间显示在app/PC。超过活动结束时间后不可编辑或延长时间 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("能否特价凑单"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-a" }, [
      _c("span", { staticClass: "tips" }, [
        _vm._v("一笔订单中只能有此商品一个特价商品，其他特价商品不能一同下单"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-a" }, [
      _c("span", { staticClass: "tips" }, [
        _vm._v("一笔订单中添加此特价商品后，还能添加其他特价商品凑单"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-block" }, [
      _c("span", { staticClass: "text" }, [_vm._v("图片/其他")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("商品标题"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "in-row" }, [
      _c("span", { staticClass: "value small-tip" }, [
        _vm._v("注意：上传图片需要审核，最多五张，图片尺寸建议1080*1080px"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row contine" }, [
      _c("div", { staticClass: "tip" }),
      _c("div", { staticClass: "value fill small-tip" }, [
        _c("span", [
          _vm._v(
            " 标签是显示在app商品列表图上的边框/角标优惠信息。 商家标签可在商品管理-商家标签上传 "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "value small-tip" }, [
      _c("span", [_vm._v("注意：排序大的靠前，最大99999")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "small-tip" }, [
      _c("span", [_vm._v("上传pdf或图片，且不超过10M")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }