import _ from 'lodash'
const state = {
    dynamicTabs: [],
    currentTab: null
}

const mutations = {
    setDynamicTabs(state, dynamicTabs) {
        // 从已有的 tabs 中查找是否有相同的 path
        // 如果有相同的 path 则替换
        // 如果没有相同的 path 则添加
        let arrs = _.cloneDeep(state.dynamicTabs)
        const findIndex = _.findIndex(state.dynamicTabs, { path: dynamicTabs.path })
        if (findIndex > -1) {
            arrs.splice(findIndex, 1)
        }
        arrs.push(dynamicTabs)
        state.dynamicTabs = arrs
    },
    setCurrentTab(state, currentTab) {
        state.currentTab = currentTab
    }
}

const actions = {
    setDynamicTabs({ commit }, dynamicTab) {
        commit('setCurrentTab', dynamicTab)
        commit('setDynamicTabs', dynamicTab)
    },
    removeDynamicTabs({ commit }, path) {
        const findIndex = _.findIndex(state.dynamicTabs, { path: path })
        if (findIndex > -1) {
            state.dynamicTabs.splice(findIndex, 1)
        }   
    },
    setCurrentTab({ commit }, currentTab) {
        commit('setCurrentTab', currentTab)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}