export default  { //非药-供应的说明
		 
			showInputArr: [{
					name: '营业执照编码',
					require: true,
					prop:'idNumber'
				}, 
				{
					name: '采购/收货人姓名',
					require: false,
					prop: 'concatName'
				}, {
					name: '采购/收货人联系电话',
					require: false,
					prop: 'concatPhone'
				}, {
					name: '售后联系电话',
					require: true,
					prop: 'servicePhone'
				}
			],
			showImgArr: [
				{
					code: '01',
					name: '营业执照',
					require: true,
					images: [], //上传的图片初始化
					desc: '营利性医疗机构必须上传营业执照，复印件请加盖鲜章',
					credentialImage: '', //查看示列的图片
					showCredential: true, //是否显示查看示列 
					showDownload: false, //是否显示下载
					downlUrl: '', //下载模版地址
				},
				 
				{
					code: '03',
					name: '食品经营许可证',
					require: false,
					images: [], //上传的图片初始化
					desc: '复印件加盖鲜章',
					credentialImage: '', //查看示列的图片
					showCredential: true, //是否显示查看示列 
					showDownload: false, //是否显示下载
					downlUrl: '', //下载模版地址
				},  
				{
					code: '09',
					name: "其它",
					require: false,
					images: [], //上传的图片初始化
					desc: '',
					credentialImage: '', //查看示列的图片
					showCredential: false, //是否显示查看示列 
					showDownload: false, //是否显示下载
					downlUrl: '', //下载模版地址
				}
			]
		}