<template>
  <div class="content">
    <el-tabs v-model="activeName" class="headerTabs" @tab-click="handleChange">
      <el-tab-pane :label="item.label" v-for="(item, index) in tabsList" :key="index" :name="item.name">
        <span v-if="item.value == 0" slot="label">
          {{ item.label }}
          <i class="el-icon-warning" style="color: #E6A23C; " />
        </span>
        <span v-if="item.value > 0" slot="label">
          {{ item.label }}
          <i class="el-icon-success" style="color: #67C23A; " />
        </span>
      </el-tab-pane>
    </el-tabs>
    <div class="contentCont">
      <div class="contentInner">
        <div class="shopCotent" :style="`min-height:${contentHeight}px;`">
          <shopManageFirst v-if="indexStep == 1" ref="shop-manage-1" @queryInfo="queryInfo" />
          <shopManageTwo v-if="indexStep == 2" ref="shop-manage-2" @queryInfo="queryInfo" />
          <shopManageThree v-if="indexStep == 3" ref="shop-manage-3" @queryInfo="queryInfo" />
          <shopManageFour v-if="indexStep == 4" ref="shop-manage-4" @queryInfo="queryInfo" />
          <shopManageFive v-if="indexStep == 5" ref="shop-manage-5" @queryInfo="queryInfo" />
        </div>
        <div style="margin-left: 30px; margin-top:40px">
          <el-button class="el_btn" @click="goSave" type="plain" v-if="indexStep != 2">保存</el-button>
        </div>
      </div>
    </div>
    <div class="footer-warp">
      <div class="footer">
        <el-button class="el_btn" @click="submitForm" type="primary">提交审核</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import storeHead from "@/assets/storeHead.jpg"
import shopManageFirst from "./shopManageFirst.vue"
import shopManageTwo from "./shopManageTwo.vue"
import shopManageThree from "./shopManageThree.vue"
import shopManageFour from "./shopManageFour.vue"
import shopManageFive from "./shopManageFive.vue"
import { queryShopSettingsCompleteStatus, shopSettingsExamine, shopInformationInfoNew } from '@/api/shop'
import router from "@/router";

export default {
  components: {
    shopManageFirst,
    shopManageTwo,
    shopManageThree,
    shopManageFour,
    shopManageFive
  },
  inject: router.path == '/storeSettleIn' ? [] : ['reloadShopAll'],
  data() {
    return {
      activeName: '1',
      icon: require(`@/assets/icon_detail_status.png`),
      indexStep: 1,
      storeHead: storeHead,
      rules: {
        name: [
          {
            required: true,
            message: `请输入企业名称`,
            trigger: `blur`,
          },
        ],
      },
      tabsList: [{
        label: '店铺信息',
        completeName: 'SHOP_SETTINGS',
        name: '1',
        value: 0
      }, {
        label: '发货配置',
        completeName: 'SHIPPING_DISTRIBUTION_SETTINGS',
        name: '2',
        value: 0
      }, {
        label: '售后设置',
        completeName: 'AFTER_SALES_SETUP',
        name: '3',
        value: 0
      }, {
        label: '服务设置',
        completeName: 'DAY_SETTING',
        name: '4',
        value: 0
      }, {
        label: '开户设置',
        completeName: 'ACCOUNT_OPENING_SETTINGS',
        name: '5',
        value: 0
      }],
      indexStepList: [
        {
          step: 1,
          name: '店铺信息',
        }, {
          step: 2,
          name: '发货配置',
        }, {
          step: 3,
          name: '售后设置',
        }, {
          step: 4,
          name: '服务设置',
        }, {
          step: 5,
          name: '开户设置',
        }],
      contentHeight: 100,
      completeStatus: true, //是否可以提交审核
      auditStatus: '',//审核状态
    };
  },
  computed: {
    onlyPage() {
      return window.location.hash == '#/storeSettleIn'
    }
  },
  created() {
  },
  mounted() {
    // 查询店铺设置是否完成
    this.queryShopSettingsCompleteStatus();
    this.doLoadInfo();
  },
  methods: {
    queryInfo() {
      this.queryShopSettingsCompleteStatus(); // 查询店铺设置是否完成
      this.doLoadInfo(); // 查询详情
    },
    // 提交审核
    shopSettingsExamine() {
      shopSettingsExamine().then(res => {
        if (res) {
          this.$message.success('提交成功')
          if (this.$router.path != '/storeSettleIn') {
            this.reloadShopAll(); // 刷新店铺信息,更新提示状态 
          }
        }
      })
    },
    // 查询店铺设置是否完成
    queryShopSettingsCompleteStatus() {
      queryShopSettingsCompleteStatus().then(res => {
        if (res) {
          this.tabsList.forEach((item, key) => {
            item.value = res[key].completeStatus;
            if (res[key].completeStatus == 0) {
              this.completeStatus = false;
              // this.$store.dispatch('user/shopComplete', '123');
            }
          })
        }
      })
      // console.log(this.$store.state.user.shopComplete,'--shopComplete')
    },
    // 查询详情
    doLoadInfo() {
      shopInformationInfoNew().then((data) => {
        if (data) {
          this.auditStatus = data.auditStatus;
        }
      });
    },
    handleChange(e) {
      this.activeName = e.name;
      this.indexStep = Number(e.name);
      // console.log(this.indexStep)
    },
    goSave() {
      let refName = 'shop-manage-' + this.indexStep;
   
      this.$refs[refName].goSave(); 
      // 查询店铺设置是否完成
      this.queryShopSettingsCompleteStatus();
    },
    // 提交审核 
    async submitForm() { 
      queryShopSettingsCompleteStatus().then(res => {
        if (res) {
          this.tabsList.forEach((item, key) => {
            item.value = res[key].completeStatus;
          })
          const allOnes = this.tabsList.every(item => item.value == 1);
          // console.log(allOnes, 'allOnes')
          if (allOnes) {
            this.vilidateState();
          } else {
            this.$message.error('请先完成店铺信息填写');
          }
        }
      })
    },



    vilidateState() {
      // 查询详情
      shopInformationInfoNew().then((data) => {
        if (data) { 
          // if (data.auditStatus == 'AWAIT') {
          //   this.$message.error('店铺信息正在审核中，请耐心等待');
          // } else {
          //  this.subExamine();
          // }
          this.subExamine();
        }
      });
    },

    subExamine(){
      this.$confirm('提交审核前请先保存！', '提示', {
          confirmButtonText: '继续提交',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.shopSettingsExamine();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消提交'
          });
        }); 
    },

    goBackHome() {
      window.location.href = '/#/'
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";

.content {
  background-color: #fff;
  overflow: auto;
}

.allheight {
  min-height: 100vh !important;
}

.status {
  &_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;

    h3 {
      font-size: 30px;
      font-weight: 400;
      color: #333;
      line-height: 2;
    }
  }

  &_ico {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    color: #fff;
    font-size: 30px;
    line-height: 1;
    border-radius: 100%;
  }

  &_success {
    background: #32bf78;
  }

  &_err {
    background: #f56c6c;
  }
}

.contentCont {
  background-color: #fff;
  height: calc(100vh - 280px);
  background: #fff;
  overflow: auto;

  .staus_warp {
    width: 100%;
    height: 64px;
    background: #f5f5f5;
  }

  .staus_plan {
    padding: 24px 0px;
    display: flex;
    align-items: center;
    width: 1024px;
    margin: auto;
    justify-content: space-around;
    height: 64px;

    .step {
      .status {
        .idx {
          width: 32px;
          height: 32px;
          border-radius: 100%;
          border: 1px solid rgba(0, 0, 0, 0.35);
          font-size: 14px;
          text-align: center;
          color: rgba(0, 0, 0, 0.35);
          line-height: 32px;
          margin: 0 auto;
        }
      }

      .icon_svg {
        width: 32px;
        height: 32px;
      }

      .name {
        color: rgba(0, 0, 0, 0.35);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-left: 10px;
      }

      .time {
        position: absolute;
        left: 40px;
        width: 150px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #91939a;
        line-height: 22px;
      }
    }

    .line {
      margin: 0 8px;
      width: 48px;
      height: 2px;
      border: solid 1px rgba(0, 0, 0, 0.15);
    }

    .step {
      display: flex;
      justify-content: flex-start;
    }

    .step.active {
      .status .idx {
        border: 1px solid #F5222D;
        color: #fff;
        background: #F5222D;
      }

      .name {
        color: #F5222D;
      }

      .name.sueccss {
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .line.active {
      border: solid 1px #F5222D;
    }
  }
}

.fl_center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.step-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-warp {
  width: 100%;
  padding: 20px 0;
  min-height: 60px;
  border-top: solid 1px #eee;
}

.footer {
  margin-left: 30px;
  display: flex;
  justify-content: flex-start;
}

.el_btn {
  width: 160px;
}

.contentInner {
  width: 868px;
  //margin: 0 auto;
}

.shopCotent {
  width: 550px;
  margin-left: 30px;
}

.headerTabs {
  width: 1024px;
  padding: 0 20px;
  background: #fff;
  // margin: 0 auto;
}
</style>
