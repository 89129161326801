<template>
  <div class="show-manage-warp" style="width: 750px;">
    <el-form :rules="rules" :model="formIn" ref="formIn">
      <div class="form shop-manage">
        <div class="title">
          <span class="text">客户开户方式</span>
        </div>
        <el-form-item prop="accountOpeningMethodId" label="开户方式" label-width="100px">
          <el-select v-model="formIn.accountOpeningMethodId" placeholder="请选择" size="small" style="width:240px"
            @change="handleChangeMedalName">
            <el-option v-for="item in indicatorList ? indicatorList : []" :key="item.id" :label="item.instructions"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="" label="" label-width="100px" v-if="formIn.medalPicture">
          <span class="small-icon">
            <el-image :src="formIn.medalPicture" class="icon" style="width: 20px; height:20px;margin-right:10px;" />
          </span>
          <span style="line-height: 30px;">{{ formIn.medalName }}</span>
        </el-form-item>

        <el-form-item prop="accountOpeningInstructions" label="附加说明" label-width="100px">
          <el-input type="textarea" maxlength="200" show-word-limit  :autosize="{ minRows: 2, maxRows: 6}" placeholder="请输入"
           v-model="formIn.accountOpeningInstructions"></el-input>
        </el-form-item>
        <!-- 发票设置，等待调接口 -->
        <div class="title">
          <span class="text">发票设置</span>
        </div>
        <el-form-item prop="invoiceType" label="开票类型" label-width="100px">
          <el-checkbox-group v-model="formIn.invoiceType">
            <el-checkbox label="1">普通纸质发票</el-checkbox>
            <el-checkbox label="2">普通电子发票</el-checkbox>
            <el-checkbox label="3">纸质增值税专用发票</el-checkbox>
            <el-checkbox label="4">电子增值税专用发票</el-checkbox>
          </el-checkbox-group>
        </el-form-item> 

        <el-form-item prop="deliveryWay" label="送达时间" label-width="100px">
          <el-radio-group v-model="formIn.deliveryWay"  @change="handleChangeMedalName2">
            <el-radio :label="item.id" v-for="(item,key) in formIn.platformServiceIndicatorVos
            " :key="key">{{item.instructions}}</el-radio> 
          </el-radio-group>
          <div  class="label-tips" label-width="100px" v-if="formIn.medalPicture2"> 
            获得优质店铺标签 <el-image :src="formIn.medalPicture2" class="icon" style="width: 20px; height:20px;margin-right:10px; margin-top: 10px; margin-left:5px" />
          </div>
        </el-form-item>

        <el-form-item prop="remark" label="说明" label-width="100px" v-if="formIn.needRemark">
          <el-input v-model="formIn.remark" placeholder="请输入发票送达时间备注说明" size="small" />
        </el-form-item>

        <div class="title" style="margin-bottom: 0;">
          <span class="text">客户开户需提供的资质</span>
        </div>

        <el-form-item v-if="formIn.radio == 9" prop="desc" label="备注说明">
          <el-input type="textarea" maxlength="200" show-word-limit  :autosize="{ minRows: 2, maxRows: 6}"  placeholder="请输入发票送达时间备注说明" 
          v-model="formIn.desc"></el-input> 
        </el-form-item>  

        <el-tabs v-model="activeName" class="headerTabs" @tab-click="handleChange">
          <el-tab-pane :label="item.label" v-for="(item, index) in tabsList" :key="index" :name="item.name">
            <span v-if="item.value > 0" slot="label">
              {{ item.label }}
              <el-badge :value="item.value" class="item" />
            </span>
          </el-tab-pane>
        </el-tabs>

       
        <div class="cert-check-list-warp">
          <div v-for="(item, index) in shopMoversTypeList ? shopMoversTypeList : []" :key="index" class="cert-check-list">
            <span class="checkbox-b">
              <el-checkbox v-model="item.selected" @change="changeCheck(item)" :disabled="item.isDefault"></el-checkbox>
            </span>
            <span class="label-t">{{ item.name
            }}</span>
          </div>
        </div>
      </div>
    </el-form>

  </div>
</template>

<script>
import {
  queryShopOpenAccountSettings,
  updateShopSettingsExpansionInformation,
  getIndicatorInfo,
} from "@/api/shop";
import _ from "lodash";

import { queryDaySettings, updateDaySettings } from "@/api/shop";
import Tabs from '@/components/Tabs'
import router from "@/router";
import { getcheckList } from '@/api/productManage/auditProduct'
import { message } from "@/utils/resetMessage";

export default {
  components: { Tabs },
  inject: router.path == '/storeSettleIn' ? [] : ['reloadShopAll'],
  data() {
    var validateInvoiceType = (rule, value, callback) => {
                if (!value) {
                     callback(new Error('请选择开票方式'));
                }
                callback();
        };
    return { 
      indicatorList: [],
      activeName: '1',
      activeIndex: 0,
      certTypeList: [{ id: 1, label: '电子版开户，不用收纸质版材料' }, { id: 2, label: '纸质版开户' },],

      rules: {
        deliveryWay: [{required:true,message:'请选择送达方式',trigger:'change'}],
        invoiceType: [{required:true,message:'请选择开票方式',trigger:'change',validator:validateInvoiceType}], 
        remark: [{required:true,message:'请输入说明',trigger:'blur',}], 
      },
      tabsList: [
      ],
      formIn: {
        typeCode: '',
        invoiceType: [],
        remark:'',
        deliveryWay:'',
        medalPicture2:'',
        needRemark:0 ,
        accountOpeningMethodId:'', //开户方式
        accountOpeningInstructions:'', //附加说明
      },
      shopMoversTypeList: [
        // { label: "资质一", checked: true, value: 1, disabled: true }, 
        // { label: "资质四", checked: false, value: 4 }, 
      ],

    };
  },
  created() {
    this.getIndicatorInfo();
    this.doLoadInfo();
  },
  methods: {
    getcheckList() {
      const params = {
        current: this.page.current,
        size: this.page.size,
        ...this.formTool
      }
      getcheckList(params).then(res => {
        if (res.code === 0) {
          this.tableData = res.data.records
          this.page.total = res.data.total

          this.tableData.forEach(item => {
            item.type = drugenumeration(item.type)
          })
        }
      })
    },
    getIndicatorInfo() {
      let type = "OPEN_ACCOUNT";
      getIndicatorInfo(type).then((data) => {
        if (data instanceof Array) {
          this.indicatorList = data;
        }
      });
    },
    handleChangeMedalName(val) {
      if (val) {
        const findItem = this.indicatorList.find((item) => item.id === val); 
        this.formIn.medalPicture =
          findItem && findItem.medalPicture ? findItem.medalPicture : "";
        this.formIn.medalName =
          findItem && findItem.medalName ? findItem.medalName : "";
        this.formIn.weight =
          findItem && findItem.competitivePower ? findItem.competitivePower : 0;
      }
    },
    handleChangeMedalName2(val) { 
      // console.log(this.formIn.platformServiceIndicatorVos,'-this.formIn.platformServiceIndicatorVos')
      if (val) {
        const findItem = this.formIn.platformServiceIndicatorVos.find((item) => item.id === val); 
        this.formIn.medalPicture2 =
          findItem && findItem.medalPicture ? findItem.medalPicture : "";  
        this.formIn.needRemark = findItem.needRemark;
        if(!this.formIn.needRemark){
          this.formIn.remark = '';
        }
      }
    },
    changeCheck(e) {
      e.isDefault = false; //是否为平台默认资质证书
    },
    handleChange(e) {
      this.activeIndex = e.index;
      this.shopMoversTypeList = this.formIn.shopStartersTypeList[e.index].shopMoversTypeList;
    },
    submit() { },
    getIndicatorInfo() {
      let type = "OPEN_ACCOUNT";
      getIndicatorInfo(type).then((data) => {
        if (data instanceof Array) {
          this.indicatorList = data || []; 
        }
      });
    },
    goNext() {
      this.doUpdateInfo("NEXT")
    },
    goSave() {
      this.doUpdateInfo()
    },
    doLoadInfo() {
      queryShopOpenAccountSettings().then((data) => {
        // console.log(data, 'data')
        if (data) {
          let newData = _.cloneDeep(data);
          // 发票类型
          if(newData.invoiceIndicatorDto && newData.invoiceIndicatorDto.invoiceType){
            newData.invoiceType = newData.invoiceIndicatorDto.invoiceType.split(',')
          }
          // 送达时间
          if(newData.invoiceIndicatorDto && newData.invoiceIndicatorDto.deliveryWay){
            newData.deliveryWay = newData.invoiceIndicatorDto.deliveryWay; 
          } 
          //备注
          if(newData.invoiceIndicatorDto && newData.invoiceIndicatorDto.remark){
            newData.remark = newData.invoiceIndicatorDto.remark; 
          } 
          this.formIn = Object.assign(this.formIn,newData);
          if(newData.invoiceIndicatorDto && newData.invoiceIndicatorDto.deliveryWay){ 
            this.handleChangeMedalName2(newData.invoiceIndicatorDto.deliveryWay)
          } 
          let tabsList = [];
          for (let i = 0; i < newData.shopStartersTypeList.length; i++) {
            let obj = {
              name: newData.shopStartersTypeList[i].typeCode,
              label: newData.shopStartersTypeList[i].typeName,
              value: 0,
            };
            tabsList.push(obj);
          }
          this.tabsList = tabsList;
          // this.formIn.typeCode= data.shopStartersTypeList[0].typeCode;
          this.shopMoversTypeList = newData.shopStartersTypeList[0].shopMoversTypeList;
          if (newData.accountOpeningMethodId) {
            this.handleChangeMedalName(newData.accountOpeningMethodId)
          }
          
          
        }
      });
    },
    doUpdateInfo(notShowMassge) {
      this.$refs['formIn'].validate((valid)=>{
        if(valid){
            if (!this.formIn.accountOpeningMethodId) {
              this.$message.warning("请选择开户方式");
              return;
            }
            const findItem = this.indicatorList.find((item) => item.id === this.formIn.accountOpeningMethodId);
              this.formIn.accountOpeningMethodName =
                findItem && findItem.instructions ? findItem.instructions : "";  
            let data = {};
            data.invoiceUpdatePropertyDto ={};
            _.merge(data, this.formIn); 
            if(this.formIn.invoiceType){
              data.invoiceUpdatePropertyDto.invoiceType = this.formIn.invoiceType.join(',')
            }
            if(this.formIn.deliveryWay){
              data.invoiceUpdatePropertyDto.deliveryWay = this.formIn.deliveryWay;
            }
            data.invoiceUpdatePropertyDto.remark = this.formIn.remark || '';
            this.$loading({
              lock: true,
              text: "正在提交...",
              spinner: "el-icon-loading",
              color: "#fff",
              background: "rgba(0, 0, 0, 0.7)",
            }); 
            updateShopSettingsExpansionInformation(data).then((res) => {
              if (res) {
                this.$loading().close();
                // this.doLoadInfo();
                this.activeName = '1';
                if (!notShowMassge){
                  this.$message.success("保存成功");
                  // this.$store.dispatch('user/getShopInfo');
                  setTimeout(() => {
                    this.$message({
                      message: '请您及时提交审核，再次进入页面将会只记录待审核信息',
                      type: 'warning'
                    });
                  }, 1000);
                  if(this.$router.path != '/storeSettleIn'){ 
                    this.reloadShopAll(); // 刷新店铺信息,更新提示状态 
                  }
                }else{
                  this.$emit('shopSettingsExamine');// 提交审核
                  if(this.$router.path != '/storeSettleIn'){ 
                    this.reloadShopAll(); // 刷新店铺信息,更新提示状态 
                  }
                } 
                // this.$emit('queryInfo');// 刷新店铺信息
              }else{
                this.$loading().close();
              }
            }).catch(() => {
              this.$loading().close();
            }); 
        }
      }) 
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style/index.scss";


.cert-check-list-warp {
  margin-top: 20px;
}

.cert-check-list {
  line-height: 30px;
  display: flex;
  justify-content: flex-start;
}

.checkbox-b {
  width: 30px;
  height: 30px;
}

.label-t {
  color: #595959;
}
.icon_store {
  border: solid 1px orange; 
  padding:2px; 
  color:orange; 
  width:20px; 
  height:20px; 
  border-radius: 2px;
  display: block;
  line-height: 14px;
  margin-top: 10px;
  margin-left: 5px;
  font-size: 12px;
  text-align: center;
}
.label-tips {
  display: flex;
  justify-content: flex-start;
  color: #606266;
}

</style>
