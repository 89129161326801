import request from "@/utils/request";

// 我的售后订单
export function aftersalePage(params) {
  return request({
    url: "/order/aftersale/me/page",
    method: "get",
    params: params,
  });
}
// 获取枚举
export function aftersaleSubmit(data) {
  return request({
    url: "/order/aftersale/submit",
    method: "post",
    data,
  });
}
// 售后单据详情
export function aftersaleInfo(data) {
  return request({
    url: "/order/aftersale/info",
    method: "get",
    params: data,
  });
}
// 快递单提交
export function aftersaleBind(data) {
  return request({
    url: "/order/aftersale/bind",
    method: "post",
    data,
  });
}
// 卖家确认收货
export function aftersaleDelivery(data) {
  return request({
    url: "/order/aftersale/shop/delivery",
    method: "put",
    data,
  });
}

// 申请平台介入
export function aftersalePlatform(data) {
  return request({
    url: "/order/aftersale/platform",
    method: "put",
    data,
  });
}

// 撤销申请
export function aftersaleRepeal(data) {
  return request({
    url: "/order/aftersale/repeal",
    method: "put",
    data,
  });
}
// 卖家同意退货
export function aftersaleAgree(data) {
  return request({
    url: "/order/aftersale/shop/agree",
    method: "put",
    data,
  });
}
// 卖家拒绝退货
export function aftersaleReject(data) {
  return request({
    url: "/order/aftersale/shop/reject",
    method: "put",
    data,
  });
}

// 获取快递信息
export function expressStep(data) {
  return request({
    url: "/order/aftersale/step",
    method: "get",
    params: data,
  });
}
// 买家同意取消售卖
export function aftersaleBuyerAgree(data) {
  return request({
    url: "/order/aftersale/buyer/agree",
    method: "put",
    data,
  });
}

// 订单评论列表
export function getOrderCommentPage(params) {
  return request({
    url: "/order/order/comment/getOrderCommentPage",
    method: "get",
    params: params,
  });
}

// 评论订单
export function replyComment(data) {
  return request({
    url: "/order/order/comment/replyComment",
    method: "post",
    data: data,
  });
}

// 查询评论详情
export function getCommentById(params) {
  return request({
    url: "/order/order/comment/getCommentById",
    method: "get",
    params: params,
  });
}
