<template>
  <el-dialog :title="title" :visible.sync="visible" :lock-scroll="true" width="718px" :before-close="handleClose"
    append-to-body>
    <div class="">
      <div style="padding: 24px 0;">
        <el-form ref="form-data" :model="form" :inline="false" label-width="70px" label-position="right" :rules="rules"
          style="text-align:left;">

          <el-form-item prop="fileList" label="打款凭证">
            <imgUpload v-model="form.imageList" :isEdit="true" :limit="3" style="position:relative;top:-8px">
            </imgUpload>
          </el-form-item>

          <el-form-item prop="" label="驳回原因" v-if="form.paymentStatus == 3">
            <el-input v-model.trim="form.remark" placeholder="" clearable size="auto" type="textarea" :disabled="true" />
          </el-form-item>

          <div class="footer-btn">
            <div v-if="form.paymentStatus == 1" style="color:rgb(255, 153, 0)">
              凭证待审核
            </div>
            <div v-if="form.paymentStatus == 2" style="color:green">
              凭证已确认
            </div>
            <div v-if="form.paymentStatus == 3" style="color:#F5222D">
              凭证已驳回
            </div>
            <div>
              <el-button size="small" type="primary" @click="audit()"
                v-if="this.form.paymentStatus && this.form.paymentStatus == 1">审核通过</el-button>
              <el-button size="small" type="plain" @click="innerVisible = true"
                v-if="this.form.paymentStatus && this.form.paymentStatus == 1">驳回</el-button>
              <el-button size="small" type="plain" @click="handleClose()">返回</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <el-dialog width="718px" title="驳回原因" :visible.sync="innerVisible" :close-on-click-modal="false" append-to-body>
      <el-form ref="form-dailog" :model="form" :rules="rules" label-width="160px">
        <el-form-item prop="remark" label="请输入驳回原因">
          <el-input v-model.trim="form.remark" placeholder="请输入驳回原因" type="textarea"
          maxlength="100" show-word-limit
            :autosize="{ minRows: 2, maxRows: 6 }">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="reject()">确定驳回</el-button>
        <el-button size="small" type="plain" @click="handleCloseInner()">取消</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>
    
<script>
import fileUpload from "@/components/fileUpload";
import avater from "/public/img/default.png";
import imgUpload from '@/components/imgUpload';
import { payvoucherinfoGetinfo, payvoucherinfoCheck } from "@/api/offlinePayment";

export default {
  props: {},
  components: { imgUpload, fileUpload },

  data() {
    return {
      innerVisible: false,
      avater: avater,
      id: '',
      title: '',
      visible: false,
      pageType: null,
      voucherType: null,
      form: {
        remark: ''
      },
      rules: {
        remark: [
          { required: true, message: '请输入驳回原因', trigger: 'blur' }
        ]
      }, 
    }
  },
  created() {

  },
  mounted() {

  },
  watch: {},
  methods: {
    // 审核通过
    audit() {
      this.$confirm(' 是否审核通过?', '提示', {
        confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
      }).then(() => {
        this.changeStatus({ status: 2 })
      }).catch(() => {
        this.$message.warning('您已取消操作');
      });
    },

    // 驳回
    reject() {
      this.$refs['form-dailog'].validate((valid)=>{
        if(valid){
          this.changeStatus({ status: 3 })
        }
      }) 
    },

    // 提交审核状态
    changeStatus(row) {
      let params = {
        "id": this.form.id,
        "paymentStatus": row.status,
        "voucherType": this.voucherType,
        "remark": this.form.remark
      } 
      payvoucherinfoCheck(params).then((res) => {
        if (res) {
          console.log(res)
          if (row.status == 3) {
            this.form.remark = "";
            this.innerVisible = false;
            this.visible = false;
            this.$emit("queryTableData")
          } else {
            this.visible = false;
            this.$emit("queryTableData")
          }
          this.$message.success("提交成功")
        }
      }).catch((err) => { });
    },

    handleClose() {
      this.visible = false;
    },

    handleCloseInner() {
      this.form.remark = "";
      this.innerVisible = false;
    },
    init(row, type, pageType) { 
      this.form.remark = "";
      this.pageType = pageType;
      this.form.imageList = [];
      if (type == 'detail') {
        this.title = '查看凭证'; 
      } else {
        this.title = '提交凭证';
      }
      // console.log(row, '--row')
      let params = {};
      params.orderId = row.id;
      if (pageType == 'afterSale') {
        params.voucherType = 3; //售后 
        this.voucherType = 3;
      }
      if (pageType == 'sale') {
        params.voucherType = 2; //订单 
        this.voucherType = 2;
      }
      payvoucherinfoGetinfo(params).then((res) => {
        if (res) {
          this.form = res;
          if (res.voucherUrl) {
            this.form.imageList = res.voucherUrl.split(',')
          }
        }
      }).catch((err) => { });
      this.visible = true;
    },
  }
}

</script>
<style lang="scss" scoped>
.eachRow {
  background-color: #fff;
  border-radius: 4px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
}

.titleView {
  display: flex;
  align-items: center;

  .rowLine {
    width: 4px;
    border-color: 1px;
    background-color: var(--main-color);
    height: 16px;
    margin-right: 8px;
  }

  .eachRowTitle {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
  }

  .cpxhead {
    display: flex;
    align-items: center;
  }

  .mark {
    color: #f56c6c;
    font-size: 12px;
    margin-left: 10px;
    width: 200px;
    line-height: 1.5;
    display: inline-block;
    font-weight: bold;
  }
}

.goods-list-warp {
  width: 100%;
  margin-left: 100px;
  max-height: 500px;
  overflow-y: auto;
}

.goods-list {
  width: 50%;
  border-bottom: solid 1px #f5f5f5;
  padding-bottom: 10px;

  &:nth-last-child(1) {
    border-bottom: none;
  }
}

.footer-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
  
  