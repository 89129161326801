<template>
    <div class="listContent">
        <div :class="['goodList', { 'actived': item.checked }]" v-for="(item, index) in list"
            @click="selectGoods(index)" :key="index">
            <i class="activeIcon el-icon-success" v-show="item.checked"></i>
            <div class="img-div">
                <el-image class="l" :src="item.image" alt="" />
            </div>
            <div class="r">
                <div>
                    <span class="txt-line-1 name">{{ item.name }}</span>
                </div>
                <div>
                    <span class="rr txt-line-1">{{ item.specification }}</span>
                </div>
                <div>
                    <span class="rr txt-line-1">{{ item.approvalNumber }}</span>
                </div>
                <div>
                    <span class="rr txt-line-1">{{ item.factory }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import lodash from "lodash";
export default {
    props: {
        goodsList: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
       
    },
    data() {
        return {
            list:this.goodsList
        }
    },
    methods: {
        selectGoods(index) {
            let tempArr = lodash.cloneDeep(this.list)
            if (tempArr[index]['checked']) {
                tempArr[index]['checked'] = false
            } else {
                tempArr[index]['checked'] = true
            }
            this.list = tempArr
            this.$emit("chooseItem", lodash.filter(this.list,(e)=>{return e.checked}));
        }
    }
};
</script>
  
<style lang="scss" scoped>
.listContent {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // justify-content: space-between;

    .goodList {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 350px;
        width: 250px;
        box-sizing: border-box;
        border: 1px solid rgba(225, 225, 225, 1);
        border-radius: 4px;
        position: relative;
        margin-right: 10px;
        padding-top: 16px;
        &:nth-child(3n){
            margin-right: 0px;
        }
        .img-div{
            height: 214px;
            width: 214px;   
        }
        .l {
            width: 100%;
            height: 100%;   
            border-radius: 4px;
            object-fit: cover;
        }

        .r {
            width: 214px;
            display: flex;
            flex: 1;
            flex-direction: column;
            div {
                display: flex;
            }

            span {
                font-size: 13px;
                color: #333;
                margin-top: 10px;
            }
            .name {
                text-align: left;
                font-size: 16px;
                font-weight: 600;
                color: var(--color-26);
            }
            .rr {
                text-align: left;
                color: var(--color-59);
                font-size: 14px;
            }
        }

        &:hover {
            border: 2px solid var(--main-color) !important;
        }
    }

    .actived {
        border: 2px solid var(--main-color) !important;
    }

    .activeIcon {
        font-size: 40px;
        position: absolute;
        color: var(--main-color);
        right: -10px;
        top: -10px;
        z-index: 6;
    }
}</style>
  