<template>
  <div class="tableMy">
    <MyTable :table-data="tableData"
             :index="index"
             :selection="selection"
             :page="page"
             :operation="operation"
             :table-option.sync="tableOption"
             @page-change="getList"
             @handleSelectionChange="handleSelectionChange">
      <template slot="information"
                slot-scope="scope">
        <div style="display:flex;align-items:center;justify-content: left;">
          <div class="imageP"
               style="width: 100%;">
            <p style="font-weight:600;color:#262626">
              {{scope.row.name|| '-'}}
            </p>
            <p style="font-weight:600;color:#262626">
              {{scope.row.factory|| '-'}}
            </p>
            <p>
              {{scope.row.specification	|| '-'}}
            </p>
            <p>
              {{scope.row.approvalNumber|| '-'}}
            </p>
            <p>
              商品编码：{{scope.row.goodsCode|| '-'}}
            </p>
            <p>
              SKU编码：{{scope.row.skuId|| '-'}}
            </p>
            <p>
              ERP编码：{{scope.row.medicineCode|| '-'}}
            </p>
          </div>
        </div>

      </template>
      <template slot="image"
                slot-scope="scope">
        <div style="display:column;align-items:center;justify-content: left;">
          <div style="width:100%;height:100%px;display:flex;align-items:center;justify-content:center;">
            <el-image style="width:80px;"
                      :src="scope.row.image || avater"
                      :preview-src-list="[scope.row.image || avater]"
                      lazy />
          </div>

          <div class="imageP"
               style="width:100%;display:flex;align-items:center;justify-content: center;">
            <p>
              {{scope.row.name }}
            </p>
          </div>
        </div>

      </template>

      <template slot="price"
                slot-scope="scope">
        <div style="display:flex;align-items:center;justify-content: left;">
          <div class="imageP">
            <p>
              价格：￥{{scope.row.salePrice || '-'}}
            </p>
            <p>
              市场价：￥{{scope.row.marketPrice  || '-'}}
            </p>
            <p>
              建议零售价：￥{{scope.row.purchasePrice  || '-'}}
            </p>
          </div>
        </div>

      </template>
      <template slot="inventory"
                slot-scope="scope">
        <div style="display:flex;align-items:center;justify-content: left;">
          <div class="imageP">
            <p>
              <el-tooltip class="item"
                          effect="dark"
                          content="总库存=ERP同步库存/手工设置商品总库存"
                          placement="top">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
              总库存：{{scope.row.goodsSkuInventory|| '-'}}
            </p>
            <p>
              <el-tooltip class="item"
                          effect="dark"
                          content="可售库存=ERP同步库存手工设置商品库存-订单占用库存-活动占用库存"
                          placement="top">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip> 可售库存：{{'-'}}
            </p>
            <p>
              <el-tooltip class="item"
                          effect="dark"
                          content="待付款、待发货、配送中、配送完成、正在售后状态占用库存"
                          placement="top">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip> 订单占用库存：{{'-'}}
            </p>
            <p>
              <el-tooltip class="item"
                          effect="dark"
                          content="活动预占库存"
                          placement="top">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip> 活动占用库存：{{'-'}}
            </p>
            <p>
              最近效期：{{scope.row.expireTime|| '-'}}
            </p>
            <!-- <p>
              最远效期：{{scope.row.newExpireTime|| '-'}}
            </p> -->
            <!-- <p style="color: green;">
              ERP同步 :{{scope.row.beSynStock == '0' ? (scope.row.goodsSkuInventory || '-') : '-'}}
            </p> -->
          </div>
        </div>

      </template>
      <template slot="status"
                slot-scope="scope">
        <div style="display:flex;align-items:center;justify-content: left;">
          <div class="imageP">
            <!-- <p>
              {{getStatus(scope.row.status) }}
            </p> -->
            <p>
              {{scope.row.updateBy}}
            </p>
            <p>
              {{scope.row.updateTime}}
            </p>
          </div>
        </div>

      </template>
      <template slot="caozuo"
                slot-scope="scope">
        <!-- <el-button type="button" size="mini" icon="el-icon-upload2" @click="deleteHandle(scope.row)">上架</el-button> -->
        <div style="display: flex;justify-content: space-between;">
          <el-link :underline="false"
                   v-if="activeName == 'MERCHANT_REMOVAL'"
                   type="primary"
                   @click="listing(scope.row)">上架</el-link>
          <el-link :underline="false"
                   v-if="activeName == 'ON_SALE'"
                   type="primary"
                   @click="delist(scope.row)">下架</el-link>
          <el-link :underline="false"
                   type="primary"
                   @click="detailHandle(scope.row)">详情</el-link>
          <el-link :underline="false"
                   v-if="activeName == 'ON_SALE' || activeName == 'MERCHANT_REMOVAL' || activeName == 'SKU_AWAIT'|| activeName == 'SKU_FAIL'|| activeName == 'BASE_TEMPORARY_FAIL'"
                   type="primary"
                   @click="editHandle(scope.row)">编辑</el-link>
          <el-link :underline="false"
                   v-if="activeName == 'BASE_ALREADY'"
                   type="primary"
                   @click="addStash(scope.row)">加入仓库</el-link>
        </div>

        <!-- <div style="display: flex;justify-content: center;">
          <el-link :underline="false"
                   type="primary"
                   @click="logs(scope.row)">操作日志</el-link>
        </div> -->
      </template>
    </MyTable>
  </div>
</template>
  
<script>
import avater from "/public/img/default.png"
import MyTable from '@/components/myTable'
import { checkSkuStatus } from '@/utils/status'
export default {
  components: { MyTable },
  props: {
    tableData: {
      type: Array,
      default () {
        return []
      }
    },
    page: {
      type: Object,
      default () {
        return {}
      }
    },
    selectionList: {
      type: Array,
      default () {
        return []
      }
    },
    activeName: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      avater:avater,
      // selectionList: [],
      operation: false,
      selection: false,
      index: false,
      // tableData: [],
      tableOption: [
        { label: '商品信息', prop: 'information', slot: true, minWidth: '200px', }, // 这里表示自定义列
        { label: '展示名称', prop: 'image', slot: true, minWidth: '200px', align: 'center' }, // 这里表示自定义列
        // { label: '商品名称', prop: 'name' },
        // { label: '生产厂商', prop: 'factory' },
        { label: '价格', prop: 'price', slot: true, width: "260px" },
        { label: '库存', prop: 'inventory', slot: true, width: "260px" },
        // { label: '商品状态', prop: 'status', slot: true, width: "260px" },
        // { label: '批准文号', prop: 'approvalNumber', width: "260px" },
        { label: '操作', prop: 'caozuo', slot: true, width: "160px", align: 'center' },
        // { label: 'ERP编码', prop: 'medicineCode' },
        // { label: '发布时间', prop: 'createTime', width: "180px" }
      ],
      // page: {
      //   total: 1,
      //   current: 1,
      //   size: 20,
      // }
    }
  },
  computed: {
    getStatus () {
      return (status) => checkSkuStatus(status)
    }
  },
  created () {

  },
  mounted () {

  },
  watch: {},
  methods: {
    // 切换分页
    getList () {
      this.$emit('getList', this.page)
    },
    // 勾选
    handleSelectionChange (val) {
      this.$emit('handleSelectionChange', val)
    },
    // 日志
    logs (val) {
      this.$emit('logs', val)
    },
    // 编辑
    editHandle (val) {
      this.$emit('editHandle', val)
    },
    // 详情
    detailHandle (val) {
      this.$emit('detailHandle', val)
    },
    // 下架
    delist (val) {
      this.$emit('delist', val)
    },
    // 上架
    listing (val) {
      this.$emit('listing', val)
    },
    // 加入仓库
    addStash (val) {
      this.$emit('addStash', val)
    },
  }
}

</script>
<style lang="scss" scoped>
.tableMy {
  width: 100%;
  height: 100%;
}
</style>
