var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mySelect" },
    [
      _c(
        "el-select",
        {
          attrs: {
            multiple: _vm.multiple,
            "collapse-tags": _vm.collapse,
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            clearable: _vm.clearable,
            size: _vm.size,
            "value-key": _vm.valueKey,
          },
          on: { change: _vm.change },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        _vm._l(_vm.options, function (item) {
          return _c("el-option", {
            key: item.value,
            attrs: {
              label: item.label,
              value: _vm.valueKey == "value" ? item : item.value,
              disabled: item.disabled,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }