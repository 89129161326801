var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("div", { staticClass: "firstTopic text-left mb20 header" }, [
        _vm._v("商品信息"),
      ]),
      _c(
        "el-form",
        {
          ref: "goods-info",
          staticClass: "product",
          attrs: {
            "label-width": "120px",
            model: _vm.formTool,
            rules: _vm.rules,
            size: "small",
            disabled: _vm.disabled,
            "label-position": "right",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品类型", prop: "goodsType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.changeType(_vm.formTool.goodsType)
                            },
                          },
                          model: {
                            value: _vm.formTool.goodsType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "goodsType", $$v)
                            },
                            expression: "formTool.goodsType",
                          },
                        },
                        _vm._l(_vm.formTool.typeList, function (item) {
                          return _c(
                            "el-radio",
                            { key: item.id, attrs: { label: item.id } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formTool.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formTool,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formTool.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格", prop: "specification" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formTool.specification,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formTool,
                              "specification",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formTool.specification",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "批准文号", prop: "approvalNumber" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formTool.approvalNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formTool,
                              "approvalNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formTool.approvalNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "生产厂家", prop: "factory" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formTool.factory,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formTool,
                              "factory",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formTool.factory",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showProducer
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产地", prop: "origin" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.formTool.origin,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formTool,
                                  "origin",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formTool.origin",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌名", prop: "brand" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formTool.brand,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formTool,
                              "brand",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formTool.brand",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位", prop: "unit" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择", size: "small" },
                          model: {
                            value: _vm.formTool.unit,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "unit", $$v)
                            },
                            expression: "formTool.unit",
                          },
                        },
                        _vm._l(_vm.unitList, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("dynamic-attribute", {
                    ref: "dynamic-attribute",
                    attrs: {
                      formTool: _vm.formTool,
                      isShowAll: false,
                      pageSource: "1",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片", prop: "imageList" } },
                    [
                      _c("imgUpload", {
                        attrs: { limit: 5, isEdit: _vm.disabled },
                        model: {
                          value: _vm.formTool.imageList,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "imageList", $$v)
                          },
                          expression: "formTool.imageList",
                        },
                      }),
                      _c("div", { staticClass: "upload-tips" }, [
                        _vm._v(
                          " 上传图片大小需低于2M，图片格式PNG,JPG,JPEG，图片尺寸建议800 x 800px "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品详情" } },
                    [
                      _c("wangEnduit", {
                        attrs: {
                          value: _vm.formTool.detail,
                          "is-clear": _vm.isClear,
                          isDisabled: _vm.disabled,
                        },
                        on: { changeEndit: _vm.watchEndit },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
        ],
        1
      ),
      _c("div", { staticStyle: { margin: "20px 40px" } }, [
        _c(
          "div",
          { staticClass: "mt20" },
          [
            _c(
              "el-button",
              {
                staticStyle: { width: "160px" },
                attrs: { size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [_vm._v("返回")]
            ),
            _c(
              "el-button",
              {
                staticStyle: { width: "160px" },
                attrs: {
                  type: "primary",
                  size: "small",
                  disabled: _vm.disabled,
                },
                on: { click: _vm.submit },
              },
              [_vm._v("保存提交")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }