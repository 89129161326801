
<!--  -->
<template>
  <div class="comContent contentInner">
    <div class="titleInner">
      <MyTabs class="search"
              :tabi="tabsIndex"
              keyname="val"
              :tabs="tabsData"
              @change="tabChange" />
      <div class="title">
        <el-form ref="formTool"
                 :model="formTool"
                 :inline="true">
          <el-form-item prop="orderCode">
            <el-input v-model="formTool.orderCode"
                      clearable
                      placeholder="请输入订单编号"
                      size="small" />
          </el-form-item>
          <el-form-item prop="code">
            <el-input v-model="formTool.code"
                      clearable
                      placeholder="请输入售后单号"
                      size="small" />
          </el-form-item>
          <el-form-item prop="shopName">
            <el-input v-model="formTool.shopName"
                      clearable
                      placeholder="请输入申请人"
                      size="small" />
          </el-form-item>
          <el-form-item>
            <MyButton type="primary"
                      text="查询"
                      @click="goSearch" />
          </el-form-item>
          <el-form-item>
            <el-button size="small"
                       @click="reset()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>

    </div>
    <div class="center">
      <MyTable :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getcheckList"
               @handleSelectionChange="currentChange">
        <!-- <template slot="image" slot-scope="scope">
          <el-image :src="scope.row.image" style="width: 80px; height: 80px" />
        </template> -->
        <!-- <template slot="status"
                  slot-scope="scope">
          <MyTypes :type="scope.row.status" />
        </template>
        <template slot="address"
                  slot-scope="scope">
          <p>{{ scope.row.provinceName + scope.row.cityName + scope.row.areaName + scope.row.address }}</p>
        </template> -->
        <template slot="caozuo"
                  slot-scope="scope">
          <el-link :underline="false"
                   type="primary"
                   @click="editDetail(scope.row)">查看详情</el-link>
          <el-link :underline="false"
                   v-if="scope.row.platformStatus == 'PENDING'"
                   style="margin-left:10px"
                   type="primary"
                   @click="agree(scope.row)">同意售后</el-link>
          <el-link :underline="false"
                   v-if="scope.row.platformStatus == 'PENDING'"
                   style="margin-left:10px"
                   type="primary"
                   @click="reject(scope.row)">驳回售后</el-link>
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable'
import MyTabs from '@/components/myTabs'
import MyTypes from '@/components/myTypes'
import { auditTypes } from '@/utils/enumeration'
import { aftersalePlatformPage, aftersalePlatformrReject, aftersalePlatformrAgree } from '@/api/auditcenter'

export default {
  components: { MyButton, MyTable, MyTabs, MyTypes },
  data () {
    return {
      text: '',
      clearable: true,
      formTool: {
        code: '',
        shopName: '',
        orderCode: ''
      },
      tabsData: [
        { name: '待处理', num: 0, val: 'PENDING', numhide: false },
        { name: '同意售后', num: 0, val: 'AGREE', numhide: true },
        { name: '驳回', num: 0, val: 'REJECT', numhide: true },
      ],
      tabsIndex: 'PENDING',
      operation: false,
      selection: false,
      index: false,
      tableData: [],
      tableOption: [
        { label: '申请人', prop: 'payShopName' },
        { label: '诊所联系方式', prop: 'contactsPhone' }, // 这里表示自定义列 , slot: true
        { label: '售后商品', prop: 'goodsName' },
        { label: '售后单据类型', prop: 'type' },
        { label: '申请时间', prop: 'orderTime' },
        { label: '订单编号', prop: 'orderCode' },
        { label: '售后单号', prop: 'code' },
        { label: '售后商户', prop: 'payeeShopName' },
        { label: '商户联系方式', prop: 'payeeShopPhone' },
        { label: '操作', prop: 'caozuo', slot: true, width: '200' }
      ],
      page: {
        total: 10,
        current: 1,
        size: 10
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getcheckList()
  },
  mounted () {

  },
  methods: {
    tabChange (i) {
      // console.log('i:' + i)
      this.tabsIndex = i
      this.page.current = 1
      this.getcheckList()
    },
    getcheckList () {
      const { current, size } = this.page
      const params = {
        current, size, status: this.tabsIndex,
        ...this.formTool
      }
      aftersalePlatformPage(params).then(res => {
        if (res.code === 0) {
          const { records, total } = res.data.page
          this.page.total = total
          this.tabsData[0].num = res.data.pendingQty
          // records.forEach(item => {
          //   item.status = auditTypes(item.status)
          // })
          this.tableData = records
          // if (this.tabsIndex == '0') {
          //   this.tabsData[0].num = total
          // }
        }
      })
    },
    reset () {
      // this.formTool.storeName = ''
      this.$refs.formTool.resetFields()
      this.page.current = 1
      this.getcheckList()
    },
    // 查看详情
    editDetail (row) {
      this.$router.push({ path: '/audit/auditcenter-salesDetail', query: { afterSaleId: row.id } })
    },
    // 同意
    agree (row) {
      aftersalePlatformrAgree(
        {
          id: row.id
        }
      ).then(res => {
        if (res.code == 0) {
          this.$message.success('审核已通过')
          this.goSearch()
        } else {
          this.$message.error('审核失败')
        }
      })
    },
    // 驳回 
    reject (row) {
      aftersalePlatformrReject(
        {
          id: row.id
        }
      ).then(res => {
        if (res.code == 0) {
          this.$message.success('驳回已通过')
          this.goSearch()
        } else {
          this.$message.error('驳回失败')
        }
      })
    },
    currentChange (val) {
      console.log('current', val)
    },
    goSearch () {
      const { code, orderCode, shopName } = this.formTool
      this.page.current = 1
      this.getcheckList()
    },
    // pageChange(val) {
    //   console.log('page', val)
    // },
    // handleSelectionChange(val) {
    //   console.log(val)
    // }
  }
}
</script>
<style lang='scss' scoped>
.comContent {
  .titleInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 0px 14px 0 24px;
    .title {
      display: flex;
      justify-content: right;
      align-items: center;
      .el-form--inline > .el-form-item {
        margin-bottom: 0px !important;
      }
    }
    .search {
      align-items: center;
    }
  }
}
.contentInner {
  padding: 0px;
}
.center {
  margin-top: 16px;
  padding: 0px 24px;
  height: calc(100% - 136px);
}
</style>
