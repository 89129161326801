<template>
	<div class="countDownView" v-if="timeCheck(order)">
        <el-statistic
        ref="statistic"
        :format="timeFormat(order)"
        :value="timeValue(order)"
        time-indices
        :value-style="{color: '#F99B0D', fontSize: '14px'}"
        >
            <div slot="suffix" class="titleText">{{ titleText(order) }}</div>
        </el-statistic>
	</div>
</template>

<script>
// 状态定义 // <!--1.待付款,2.待发货, 3.已发货,4.待收货，5.交易取消, 6申请退款 7.已退款  8.配送已完成 9.已完成 11.首营待开户 12.待确款 -->
import dayjs from 'dayjs';
export default {
	props: {
		order: {
            type: Object,
            default: () => {
                return {};
            }
        },
	},
	data() {
		return {

		};
	},
    computed: {
      
    },
	methods: {
        timeCheck(order) {
			let deliveryDate = 0;
            switch (order.status) {
                case '1':
                    deliveryDate = order.orderTimeoutTimestamp ? Number( order.orderTimeoutTimestamp ) : 0;
                    break;
                case '2':
                    deliveryDate = order.deliveryTimeoutTimestamp ? Number( order.deliveryTimeoutTimestamp ) : 0;
                    break;
                case '3':
                    if(order.dispatchWay == '1'){
                        // 只有自主物流需要倒计时
                        deliveryDate = order.independentLogisticsTimeoutTimestamp ? Number( order.independentLogisticsTimeoutTimestamp ) : 0;
                    }
                    break;
                default:
                    break;
            }
			return deliveryDate > 0 
		},
        timeValue(order) {
            let value = 0;
            switch (order.status) {
                case '1':
                    value = dayjs(new Date()).valueOf() + Number(order.orderTimeoutTimestamp);
                    break;
                case '2':
                    value = dayjs(new Date()).valueOf() + Number(order.deliveryTimeoutTimestamp);
                    break;
                case '3':
                    value = dayjs(new Date()).valueOf() + Number(order.independentLogisticsTimeoutTimestamp);
                    break;
                default:
                    break;
            }
            return value;
        },
        timeFormat(order) {
            let format = "";
            switch (order.status) {
                case '1':
                    format = "mm:ss";
                    break;
                case '2':
                    format = "DD天HH:mm:ss";
                    break;
                case  '3':
                    format = "DD天HH:mm:ss";
                    break;
                default:
                    break;
            }
            return format;
        },
        titleText(order) {
            let title = "";
            switch (order.status) {
                case '1':
                    title = "未付款将自动取消";
                    break;
                case '2':
                    if (this.timeCheck(order)) {
                        title = "后未发货，将赔付￥" + order.compensationAmount;
                    }else{
                        title = "发货超时，已赔付￥" + order.compensationAmount;
                    }
                    break;
                case '3':
                    title = "将自动确认收货";
                    break;
                default:
                    break;
            }
            return title;
        },

	}
}
</script>

<style lang="scss" scoped>

.countDownView {
    display: flex;
    align-items: center;
    margin: 5px 0 0 0 ;
}
.titleText{
    color: #F99B0D;
    font-size: 14px;
}

</style>