import request from "@/utils/request";

// 拼团活动列表
export function groupBuyList(data) {
  return request({
    url: `/marketing/marketing/groupBuyInfos`,
    method: "get",
    params: data,
  });
}

// 添加拼团活动
export function addGroupBuy(data) {
  return request({
    url: `/marketing/marketing/groupBuyInfos`,
    method: "POST",
    data,
  });
}

// 拼团详情
export function groupBuyDetail(data) {
  return request({
    url: `/marketing/marketing/groupBuyInfos/${data.id}`,
    method: "get",
  });
}

// 删除拼团
export function delGroupBuy(data) {
  return request({
    url: `/marketing/marketing/groupBuyInfos/${data.id}`,
    method: "DELETE",
  });
}

// 拼团列表数量
export function groupBuyListLimit(data) {
  return request({
    url: `/marketing/marketing/groupBuyInfos/quantity`,
    method: "get",
    params: data
  });
}

// 编辑拼团
export function editGroupBuy(data) {
  return request({
    url: `/marketing/marketing/groupBuyInfos/${data.id}`,
    method: "PATCH",
    data
  });
}

// 发布拼团
export function publishGroupBuy(data) {
  return request({
    url: `/marketing/marketing/groupBuyInfos/publish/${data.id}`,
    method: "put",
  });
}

// 关闭拼团

export function closeGroupBuy(data) {
  return request({
    url: `/marketing/marketing/groupBuyInfos/close/${data.id}`,
    method: "put",
  });
}
