var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c("div", { staticClass: "topHeader" }, [
      _c("div", { staticClass: "choice" }, [_vm._v(" 选择商品 ")]),
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "formTool",
              staticStyle: { "text-align": "left", "padding-left": "10px" },
              attrs: {
                model: _vm.formTool,
                inline: true,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入药品名称", size: "small" },
                    model: {
                      value: _vm.formTool.goodsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "goodsName", $$v)
                      },
                      expression: "formTool.goodsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "factory" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入厂家关键词", size: "small" },
                    model: {
                      value: _vm.formTool.factory,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "factory", $$v)
                      },
                      expression: "formTool.factory",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "approvalNumber" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入药品批准文号", size: "small" },
                    model: {
                      value: _vm.formTool.approvalNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "approvalNumber", $$v)
                      },
                      expression: "formTool.approvalNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "approvalNumber" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.searchList()
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-right": "0px" },
                  attrs: { prop: "approvalNumber" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.reset()
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "contentCont" }, [
      _c(
        "div",
        { staticClass: "contentInner" },
        _vm._l(_vm.tableData, function (item, idnex) {
          return _c(
            "div",
            { key: idnex, staticClass: "itemCont" },
            [
              _c("div", { staticClass: "itemImg" }, [
                _c("img", {
                  staticStyle: {
                    width: "180px",
                    height: "180px",
                    padding: "15px 0px",
                  },
                  attrs: { src: item.image, alt: "" },
                }),
              ]),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: item.name,
                    placement: "top",
                  },
                },
                [
                  _c("div", { staticClass: "itemBox name" }, [
                    _vm._v(" " + _vm._s(item.name) + " "),
                  ]),
                ]
              ),
              _c("div", { staticClass: "itemBox factory" }, [
                _vm._v(" " + _vm._s(item.approvalNumber) + " "),
              ]),
              _c("div", { staticClass: "itemBox factory" }, [
                _vm._v(" " + _vm._s(item.factory) + " "),
              ]),
              _c("div", { staticClass: "itemBox factory" }, [
                _vm._v(" " + _vm._s(item.specification) + " "),
              ]),
              _c(
                "div",
                { staticClass: "itemBox shopCar" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-document",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleAddbuy(item)
                        },
                      },
                    },
                    [_vm._v("开始定价")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _c(
      "div",
      { staticClass: "el-page" },
      [
        _c("Pagination", {
          attrs: {
            total: _vm.page.total,
            page: _vm.page.current,
            limit: _vm.page.size,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.page, "current", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.page, "size", $event)
            },
            pagination: _vm.pageChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }