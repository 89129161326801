var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-detail-root" },
    [
      _c("el-form", { ref: "refForm", attrs: { model: _vm.formData } }, [
        _vm.formData.gridList && _vm.formData.gridList.length > 0
          ? _c(
              "div",
              { staticClass: "v-grid-list" },
              _vm._l(
                _vm.formData.gridList ? _vm.formData.gridList : [],
                function (bigItem, bigIndex) {
                  return _c(
                    "div",
                    { key: bigIndex, staticClass: "block-item" },
                    [
                      _c("div", { staticClass: "top-title" }, [
                        _c("div", { staticClass: "full-title" }, [
                          _c("div", { staticClass: "big-title" }, [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: bigItem.isMust,
                                    expression: "bigItem.isMust",
                                  },
                                ],
                                staticClass: "weight",
                              },
                              [_vm._v("*")]
                            ),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v(_vm._s(bigItem.title)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "remarks" }, [
                          _vm._v(" " + _vm._s(bigItem.description || "") + " "),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "content-layout" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "gridList." + bigIndex + ".imageUrlList",
                                rules: [
                                  {
                                    required: bigItem.isMust,
                                    message: "请上传" + bigItem.title,
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pic-layout" },
                                [
                                  _vm._l(
                                    bigItem.imageUrlList
                                      ? bigItem.imageUrlList
                                      : [],
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "item" },
                                        [
                                          _c("el-image", {
                                            ref: `myImgB${bigIndex}S${index}`,
                                            refInFor: true,
                                            staticClass: "icon",
                                            attrs: {
                                              src: item,
                                              fit: "cover",
                                              "initial-index": index,
                                              "preview-src-list":
                                                bigItem.imageUrlList,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "preview" },
                                            [
                                              _c("el-image", {
                                                staticClass: "btn-icon",
                                                attrs: { src: _vm.SeeIcon },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handlePreview(
                                                      `myImgB${bigIndex}S${index}`
                                                    )
                                                  },
                                                },
                                              }),
                                              !_vm.disabled
                                                ? _c("el-image", {
                                                    staticClass: "btn-icon",
                                                    attrs: { src: _vm.DelIcon },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleDeleteImage(
                                                          bigIndex,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  !_vm.hideAddPicBtn(bigItem)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "item add-pic",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleUpload(bigIndex)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "image-wrap" },
                                            [
                                              _c("el-image", {
                                                attrs: { src: _vm.AddIcon },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("span", { staticClass: "text" }, [
                                            _vm._v("上传照片"),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "input-layout" },
                            [
                              _vm._l(
                                bigItem.filedList,
                                function (subItem, subIndex) {
                                  return _c(
                                    "div",
                                    {
                                      key: subIndex,
                                      class: {
                                        "input-item": true,
                                        "fill-wrap":
                                          bigItem.filedList.length % 2 !== 0 &&
                                          subIndex ===
                                            bigItem.filedList.length - 1,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "gridList." +
                                              bigIndex +
                                              ".filedList." +
                                              subIndex +
                                              ".modeValue",
                                            rules: [
                                              {
                                                required: subItem.isMust,
                                                message: subItem.tip
                                                  ? subItem.tip + "不能为空"
                                                  : "值不能为空",
                                                trigger: "blur",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("DynamicFiled", {
                                            attrs: {
                                              type: subItem.type,
                                              disabled: _vm.disabled,
                                              "data-item": subItem,
                                            },
                                            model: {
                                              value: subItem.modeValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  subItem,
                                                  "modeValue",
                                                  $$v
                                                )
                                              },
                                              expression: "subItem.modeValue",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              bigItem.imageUrlList &&
                              bigItem.imageUrlList instanceof Array &&
                              bigItem.imageUrlList.length
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "有效期：" } },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "date",
                                              placeholder: "有效期至",
                                              size: "mini",
                                              disabled: _vm.type == "view",
                                            },
                                            model: {
                                              value: bigItem.periodValidity[1],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  bigItem.periodValidity,
                                                  1,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "bigItem.periodValidity[1]",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              0
            )
          : _vm._e(),
        _c("div", { staticClass: "bottom-dynamic-filed" }, [
          _vm.formData.filedList && _vm.formData.filedList.length > 0
            ? _c(
                "div",
                { staticClass: "input-layout" },
                _vm._l(_vm.formData.filedList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: {
                        "input-item": true,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "filedList." + index + ".modeValue",
                            rules: [
                              {
                                required: item.isMust,
                                message: item.tip
                                  ? item.tip + "不能为空"
                                  : "值不能为空",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("DynamicFiled", {
                            attrs: {
                              type: item.type,
                              disabled: _vm.disabled,
                              "data-item": item,
                            },
                            model: {
                              value: item.modeValue,
                              callback: function ($$v) {
                                _vm.$set(item, "modeValue", $$v)
                              },
                              expression: "item.modeValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                { attrs: { label: "经营范围：", required: "" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { disabled: _vm.type == "view" },
                      model: {
                        value: _vm.formData.checkList,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "checkList", $$v)
                        },
                        expression: "formData.checkList",
                      },
                    },
                    _vm._l(_vm.rangeList, function (item) {
                      return _c(
                        "el-checkbox",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("UploadImageView", {
        ref: "imgupload",
        attrs: { "request-id": "1", imgType: _vm.imgType },
        on: { upload: _vm.uploadSubmit },
      }),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: { "on-close": _vm.closeViewer, "url-list": _vm.urls },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }