import request from "@/utils/request";

// 拼团商品列表
export function groupBuyGoodsList(data) {
  return request({
    url: `/marketing/marketing/groupBuyDetails/manager/goodsList`,
    method: "get",
    params: data,
  });
}

// 新增活动商品
// export function addGroupBuyGoods(data) {
//   return request({
//     url: `/marketing/marketing/groupBuyDetails`,
//     method: "POST",
//     data,
//   });
// }
export function saveGoodsSkuConfig(data) {
  return request({
    url: `/marketing/marketing/groupBuyDetails/save/saveGoodsSkuConfig`,
    method: "POST",
    data,
  });
}

// 拼团商品详情
export function groupBuyGoodsDetail(data) {
  return request({
    url: `/marketing/marketing/groupBuyDetails/${data.id}`,
    method: "GET",
  });
}

// 删除活动商品
export function delGroupBuyGoods(data) {
  return request({
    url: `/marketing/marketing/groupBuyDetails/${data.id}`,
    method: "DELETE",
  });
}

// 活动商品列表数量
export function groupBuyGoodsListLimit(data) {
  return request({
    url: `/marketing/marketing/groupBuyDetails/quantity`,
    method: "get",
    data,
  });
}

// 编辑活动商品
export function editGroupBuyGoods(data) {
  return request({
    url: `/marketing/marketing/groupBuyDetails/groupBuyDetail/${data.id}`,
    method: "PUT",
    data,
  });
}

// 查询店铺商品列表
export function getShopGroupable(data) {
  return request({
    url: `/product/goodsbase/shopGroupable/page`,
    method: "get",
    params: data,
  });
}
