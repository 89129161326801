<!--
 * @FilePath: /eshop-ops-web/src/components/dynamicAttribute/index.vue
 * @Author: xiexingzhong2012@sina.cn
 * @Date: 2023-07-26 09:00:11
 * @LastEditTime: 2023-07-30 15:04:59
 * @LastEditors: xiexingzhong2012@sina.cn
 * @Description:  
 此模块为商品属性动态配置模块，接收4种模式的 数据，0.文本框，1多行文本框，2 下拉文本框 3 文件上传 
 ps 1.文件上传部分 此处数据为list 需要处理成string 格式的在外部 谁使用谁处理。
-->

<template>
  <div>
    <div v-for="(item, key) in formTool.extendParams" :key="key">
      <!-- {{item.required}} -->
      <el-row v-if="item.input == 0 && (item.goodsBaseShow || isShowAll)">
        <el-col :span="24">
          <el-form-item
            :label="item.name" 
          >
            <span
              slot="label"
              :class="
                changeExtendParams[item.fieldName] ? 'lable-color-correct' : ''
              "
              >{{ item.name }}</span>
            <el-input v-model="item.value" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-if="item.input == 1 && (item.goodsBaseShow || isShowAll)">
        <el-col :span="24">
          <el-form-item
            :label="item.name" 
          >
             <span
              slot="label"
              :class="
                changeExtendParams[item.fieldName] ? 'lable-color-correct' : ''
              "
              >{{ item.name }}</span>
            <el-input
              v-model="item.value"
              placeholder="请输入"
              type="textarea"
              :rows="2"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-if="item.input == 2 && (item.goodsBaseShow || isShowAll)">
        <el-col :span="24">
          <el-form-item
            :label="item.name" 
          >
             <span
              slot="label"
              :class="
                changeExtendParams[item.fieldName] ? 'lable-color-correct' : ''
              "
              >{{ item.name }}</span>
            <el-select v-model="item.value" size="small" placeholder="请选择">
              <el-option
                v-for="(e, i) in item.selectList"
                :key="i"
                :label="e"
                :value="e"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-if="item.input == 3 && (item.goodsBaseShow || isShowAll)">
        <el-col :span="24">
          <el-form-item
            :label="item.name" 
          >
             <span
              slot="label"
              :class="
                changeExtendParams[item.fieldName] ? 'lable-color-correct' : ''
              "
              >{{ item.name }}</span>
            <fileUpload
              v-model="formTool.fileList"
              @onRemoveHandler="onRemoveHandler"
            ></fileUpload>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import fileUpload from "@/components/fileUpload";
export default {
  components: { fileUpload },
  props: {
    formTool: {
      type: Object,
      default() {
        return {};
      },
    },
    changeExtendParams: {
      type: Object,
      default() {
        return {};
      },
    },
    isShowAll: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  methods: {
    // 删除上传的文件
    onRemoveHandler(file) {
      let index = this.formTool.fileList.indexOf(file);
      this.formTool.fileList.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.lable-color-correct {
  color: #f00;
}
</style>
