<!-- err -->
<template>
    <el-dialog title="图片上传" :visible.sync="dialogShow" width="408px">
      <div class="dia_content">
        <el-upload
          ref="uploadDom"
          drag
          :action="actions"
          accept=".png,.jpg,.jpeg"
          :limit="1"
          :show-file-list="false"
          :data="uploadData"
          :on-success="uploadSuccess"
          :on-error="uploadFail"
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过10M
          </div>
        </el-upload>
      </div>
    </el-dialog>
  </template>
  
  <script>
  import { imgUpload } from "@/api/user";
  
  export default {
    props: {
      imgType: {
        type: String,
        default: "",
      },
      memberId: {
        type: String,
        default: "",
      },
    },
   
    data() {
      return {
        imgs: [],
        dialogShow: false,
        type: null,
        actions:'http://test-cbyyk.tpddns.cn:8096/merchantOpen/uploadDoc' ,//测试环境： http://test-cbyyk.tpddns.cn:8096 生产环境： https://openpay.cbyzs.com
        uploadData:null
      };
    },
    methods: {
      open(type) {
        console.log(type);
        this.type = type;
        this.uploadData = {
            memberId:this.memberId,
            merchantType:'C',//
            type:type,//文件类型（idcard_front 身份证正面照 idcard_back身份证反面照 bankcard银行卡 business_license营业执照）
        }
        this.$nextTick(()=>{
          this.dialogShow = true;
        })
      },
      close() {
        this.dialogShow = false;
      },
      uploadSuccess(e,l){
        if (e.data) {
            this.close()
            this.$emit("upload", { data : e.data });
            this.$refs.uploadDom.clearFiles()
        }
      },
      uploadFail(e,l){
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .dia {
    &_content {
      position: relative;
      display: inline-block;
      & > * {
        display: inline-block;
      }
  
      .big-tip {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
  
        .text {
          font-size: 14px;
          color: #fff;
        }
      }
    }
    &_footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  </style>
  