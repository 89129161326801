<template>
  <div class="classification">
    <div class="metrics-infos">
      <div class="sigle-info">
        <span class="tip">指标名称：</span>
        <span>{{ target ? target.name : "" }}</span>
      </div>
      <div class="sigle-info">
        <span class="tip">合同名称：</span>
        <span>{{ target ? target.contractName : "" }}</span>
      </div>
      <div class="sigle-info">
        <span class="tip">医药公司名称：</span>
        <span>{{ target ? target.draftShopName : "" }}</span>
      </div>
    </div>
    <div class="header">
      <el-form
        ref="formTool"
        :model="formTool"
        :inline="true"
        style="text-align: left"
      >
        <el-form-item prop="name" label="">
          <el-input
            v-model="formTool.takeCode"
            placeholder="请输入任务编码"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="name" label="">
          <el-input
            v-model="formTool.takeName"
            placeholder="请输入任务名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="name" label="">
          <el-select
            v-model="formTool.taskStatus"
            style="width: 150px"
            size="small"
            placeholder="请选择状态"
            clearable
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button size="small" @click="handleBack">返回</el-button>
        <el-button size="small" @click="handleExport">导出</el-button>
      </div>
    </div>

    <div class="center" style="position: relative">
      <MyTable
        :index="false"
        :selection="false"
        :table-data="tableData"
        :page="page"
        :table-option.sync="tableOption"
        @page-change="getList"
      >
        <template slot="taskStatus" slot-scope="scope">
          <span>{{ status(scope.row.taskStatus) }}</span>
        </template>
        <template slot="createTime" slot-scope="scope">
          <div
            v-if="scope.row.createTime"
            style="display: flex; flex-direction: column"
          >
            <span v-for="(li, i) in scope.row.createTime.split(' ')" :key="i">{{
              li
            }}</span>
          </div>
        </template>
        <template slot="operate" slot-scope="scope">
          <div class="table-op-col">
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="handleLookTask(scope.$index)"
              >任务详情</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="handleLookChildTask(scope.$index)"
              >子任务列表</el-link
            >
          </div>
        </template>
      </MyTable>
    </div>

    <publicTaskDlg ref="refPublicTaskDlg" />
    <childTaskDetailDlg ref="refChildTaskDetailDlg" />
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import Tabs from "@/components/Tabs";
import {
  contractTargetTaskPage,
  contractTargetTaskDelete,
  contractTargetTaskExport,
} from "@/api/compliance/task";
import publicTaskDlg from "@/views/compliance/task/dialog/publicTaskDlg.vue";
import childTaskDetailDlg from "@/views/compliance/task/dialog/childTaskDetailDlg.vue";
import { taskHelp, IConst_clientTag } from "@/utils/viewHelp";
import { contractTargetDetail } from "@/api/compliance/metricsManager";

export default {
  components: {
    MyTable,
    Tabs,
    publicTaskDlg,
    childTaskDetailDlg,
  },
  data() {
    return {
      taskStatusValus: Object.keys(taskHelp.taskStatusList),
      targetId: null, //指标Id,必须存在
      target: null, //指标对象

      commandList: taskHelp.taskTypeList,

      formTool: { takeCode: "", takeName: "", taskStatus: "" },
      tableData: [],
      tableOption: [
        { label: "任务编码", prop: "code" },
        { label: "任务名称", prop: "name" },
        { label: "子任务数", prop: "toTaskCount" },
        { label: "CSO已审子任务数", prop: "csoExaminePassTaskCount" },
        { label: "医药公司已审子任务数", prop: "yygsExaminePassTaskCount" },
        { label: "任务金额", prop: "taskAmount" },
        { label: "CSO已审任务金额", prop: "csoExaminePassTaskAmount" },
        { label: "医药公司已审任务金额", prop: "yygsExaminePassTaskAmount" },
        { label: "状态", prop: "taskStatus", slot: true },
        { label: "创建人", prop: "createBy" },
        { label: "创建时间", prop: "createTime", width: "100px", slot: true },
        { label: "操作", prop: "operate", slot: true, width: "220px" },
      ],
      page: { total: 0, current: 1, size: 10 },

      selectedList: [], //选中的列表，待导出
    };
  },
  created() {
    this.targetId = this.$route.query.targetId;
    this.getTargetDetail();
    this.getList(1);
  },
  computed: {
    statusList() {
      let list = [];
      for (let key in taskHelp.taskStatusList) {
        list.push({ value: key, label: taskHelp.taskStatusList[key] });
      }

      return list;
    },
  },
  methods: {
    status(taskStatus) {
      return taskHelp.status(taskStatus);
    },
    handleClick(e) {
      //
    },
    handleExport() {
      const { current, size } = this.page;
      let params = {
        clientTag: IConst_clientTag.cso,
        targetId: this.targetId,
        current,
        size,
        ...this.formTool,
      };
      contractTargetTaskExport(params);
    },
    handlePublicTask(command) {
      const findedItem = this.commandList.find(
        (item) => item.value === command
      );
      if (findedItem) {
        this.openTask({ action: 1, command: findedItem.value });
      }
    },
    handleSelectionChange(list) {
      this.selectedList = list;
    },
    handleEdit(index) {
      const row = this.tableData[index];
    },
    getList(num) {
      if (num) {
        this.page.current = num;
      }
      const { current, size } = this.page;
      let params = {
        clientTag: IConst_clientTag.cso,
        targetId: this.targetId,
        current,
        size,
        ...this.formTool,
      };
      this.listLoading = true;
      contractTargetTaskPage(params)
        .then((res) => {
          this.tableData = res.records;
          this.page.total = res.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    handleRefresh() {
      this.getList(1);
    },
    handleReset() {
      for (let key in this.formTool) {
        this.formTool[key] = "";
      }
      this.getList(1);
    },
    handleUploadVoucher(index) {
      const row = this.tableData[index];
      this.openOpVoucher();
    },
    openOpVoucher(action = 1, id = null) {
      this.$refs.refOpVoucherDlg.open({ action: action, id: id });
    },
    handleBack() {
      this.$router.back();
    },
    handleLookTask(index) {
      const row = this.tableData[index];
      this.openTask({ action: 3, id: row.id });
    },
    handleEditTask(index) {
      const row = this.tableData[index];
      this.openTask({ action: 2, id: row.id });
    },
    handleDeleteTask(index) {
      const row = this.tableData[index];
      this.$confirm("您确定要删除当前任务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          contractTargetTaskDelete(row.id).then((res) => {
            if (res) {
              this.handleRefresh();
            }
          });
        })
        .catch(() => {
          //
        });
    },
    openTask(options) {
      this.$refs.refPublicTaskDlg.open({ ...options, targetId: this.targetId });
    },
    handleLookChildTask(index) {
      const row = this.tableData[index];

      this.$router.push({
        path: "/compliancecso/compliancecso-childtask-list",
        query: { targetId: this.targetId, taskId: row.id },
      });
    },
    getTargetDetail() {
      contractTargetDetail(this.targetId).then((data) => {
        if (data) {
          this.target = data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.classification {
  padding: 0px;

  .metrics-infos {
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0px 24px 0px 24px;
    box-sizing: border-box;
    font-size: 14px;
    color: #262626;

    .sigle-info {
      .tip {
        color: #a0a0a0;
      }
      + .sigle-info {
        margin-left: 12px;
      }
    }
  }

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px - 44px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn-warp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .el-link {
      // margin: 5px;
    }
  }

  .table-op-col {
    display: flex;
    .el-link {
      & + .el-link {
        margin-left: 8px;
      }
    }
  }
}
</style>
