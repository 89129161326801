var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "shop-info-root tab-content new-cls" }, [
    _c("div", { staticClass: "form" }, [
      _c("div", { staticClass: "block" }, [
        _vm._m(0),
        _c("div", { staticClass: "block-content" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "value input big-w" },
              [
                _c("el-input", {
                  attrs: {
                    size: "small",
                    placeholder: "请输入",
                    maxlength: "20",
                  },
                  model: {
                    value: _vm.formIn.consignee,
                    callback: function ($$v) {
                      _vm.$set(_vm.formIn, "consignee", $$v)
                    },
                    expression: "formIn.consignee",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(2),
            _c(
              "div",
              { staticClass: "value input big-w" },
              [
                _c("el-input", {
                  attrs: {
                    size: "small",
                    placeholder: "请输入",
                    maxlength: "15",
                  },
                  model: {
                    value: _vm.formIn.consigneePhone,
                    callback: function ($$v) {
                      _vm.$set(_vm.formIn, "consigneePhone", $$v)
                    },
                    expression: "formIn.consigneePhone",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(3),
            _c(
              "div",
              { staticClass: "value input big-w" },
              [
                _c("el-input", {
                  attrs: {
                    size: "small",
                    placeholder: "请输入",
                    maxlength: "50",
                  },
                  model: {
                    value: _vm.formIn.consigneeAddress,
                    callback: function ($$v) {
                      _vm.$set(_vm.formIn, "consigneeAddress", $$v)
                    },
                    expression: "formIn.consigneeAddress",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "block" }, [
        _vm._m(4),
        _c("div", { staticClass: "block-content" }, [
          _c("div", { staticClass: "row multi" }, [
            _vm._m(5),
            _c(
              "div",
              { staticClass: "value input big-w" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    maxlength: "2000",
                    "show-word-limit": "",
                    resize: "none",
                    rows: 6,
                    placeholder: "请输入",
                  },
                  model: {
                    value: _vm.formIn.changingRefundingInstructions,
                    callback: function ($$v) {
                      _vm.$set(_vm.formIn, "changingRefundingInstructions", $$v)
                    },
                    expression: "formIn.changingRefundingInstructions",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "bottom-btn" },
      [
        _c(
          "el-button",
          {
            staticClass: "fix-four",
            attrs: { type: "primary" },
            on: { click: _vm.doUpdateInfo },
          },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "block-title" }, [
      _c("span", { staticClass: "text" }, [_vm._v("售后收货信息")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("收货人"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("电话"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("收货地址"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "block-title" }, [
      _c("span", { staticClass: "text" }, [_vm._v("退换货流程")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("文字说明"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }