import request from '@/utils/request'

//查询首页活动列表
export function getList() {
    return request({
        url: `/marketing/platform/activity/client/get/activity/infos`,
        method: "get"
    });
}


//查询活动列表
export function getPage(data) {
  return request({
      url: `/marketing/plm/activity/queryPlmActivityVOPage`,
      method: "post",
      data
  });
}
// 查询活动详情
export function activityInfo(data) {
  return request({
      url: `/marketing/platform/activity/ops/get/activity/details`,
      method: "get",
      params:data
  });
}

// 查询活动商品（活动报名页）
export function queryActivitySku(data) {
  return request({
      url: `/product/stock/sku/querySkuPageByShopList`,
      method: "post",
      data
  });
}

// 查询平台活动sku信息
export function queryActivitySkuUnion(data) {
  return request({
      url: `/product/stock/sku/queryActivitySkuDetailList`,
      method: "post",
      data
  });
}

// 立即报名
export function activityApply(data) {
  return request({
      url: `/marketing/plm/activity/activityApply`,
      method: "post",
      data
  });
}

// 查询客户端商品列表
export function activityPage(data) {
  return request({
      url: `/marketing/platform/activity/client/get/activity/goods/page`,
      method: "GET",
      params:data
  });
}

// 编辑活动商品价格
export function updateActivityPrice(data) {
  return request({
      url: `/marketing/platform/activity/client/update/activity/goods/price`,
      method: "put",
      data
  });
}

// 删除活动商品
export function deleteApplyUnion(id) {
  return request({
      url: `/marketing/platform/activity/client/delete/activity/goods/${id}`,
      method: "DELETE"
  });
}

// 下线商品
export function offlineApplySkuUnion(id) {
  return request({
      url: `/marketing/platform/activity/apply/offlineApplySkuUnion/${id}`,
      method: "get"
  });
};

// 商户端查看平台补贴商品列表
export function subsidyGoodsPage(data) {
  return request({
      url: `/marketing/plm/subsidy/client/get/subsidy/goods/page`,
      method: "get",
      params:data
  });
}

// 编辑活动价格
export function updateGoodsPrice(data) {
  return request({
      url: `/marketing/plm/subsidy/update/client/subsidy/goods/price`,
      method: "put",
      data
  });
}

// 单个移除参与活动的商品
export function deleteSubsidyGoods(id) {
  return request({
      url: `/marketing/plm/subsidy/delete/subsidy/goods/${id}`,
      method: "delete"
  });
}