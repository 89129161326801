<template>
    <!-- 店铺设置,统一售后 -->
  <div class="content">
    <div class="show-manage-warp">
      <el-form :rules="rules" :model="formIn" ref="shopForm" disabled> 
        <div class="form shop-manage">
          <div class="title">
            <span class="text">售后信息</span>
          </div>
          <div class="block-content">
            <el-form-item prop="consignee" label="收货人" label-width="120px">
              <el-input size="small" placeholder="请输入" v-model="formIn.consignee" maxlength="25" style="width: 320px;"/>
            </el-form-item>
            <el-form-item prop="consigneePhone" label="电话" label-width="120px">
              <el-input size="small" placeholder="请输入" v-model="formIn.consigneePhone" maxlength="12"  style="width: 320px;"/>
            </el-form-item>
          <el-form-item prop="" label="省市区" label-width="120px">
            <el-input size="small" placeholder="请输入" v-model="formIn.provinceCityArea" maxlength="100"  style="width: 320px;" />
          </el-form-item>
            <el-form-item prop="consigneeAddress" label="收货地址" label-width="120px">
              <el-input size="small" placeholder="请输入" v-model="formIn.consigneeAddress" maxlength="100"  style="width: 320px;" />
            </el-form-item>
           
          </div>
  
          <div class="title">
            <span class="text">退换货流程</span>
          </div>
          <div class="block-content">
            <el-form-item prop="changingRefundingInstructions" label="退换货流程" label-width="120px">
              <el-input type="textarea" maxlength="2000" show-word-limit resize="none" :rows="6" placeholder="请输入"  
                v-model="formIn.changingRefundingInstructions"></el-input>
            </el-form-item>
          </div> 
        </div>
        
      </el-form>
   
    </div>
  </div>

</template>

<script>
   
import { getShipInfor, updateShipInfor, shopInformationInfoNew, saveOrUpdateInformation } from "@/api/shop";
import { queryAfterSalesSettings, updateAfterSalesSettings } from "@/api/shop";
import _ from "lodash";
import router from "@/router";

export default {
  components: {   },
  inject: ['reloadShopAll'],

  data() {
    return {  
      formIn: {
        consignee:'',
        consigneePhone:'',
        consigneeAddress:'',
        changingRefundingInstructions:'',
        provinceCityArea:'', //省市区
      },
      rules: { 
        consignee: [{ required: true, message: "请输入收货人", trigger: "blur" }], 
        consigneePhone: [{ required: true, message: "请输入电话", trigger: "blur" }], 
        consigneeAddress: [{ required: true, message: "请输入收货地址", trigger: "blur" }], 
        changingRefundingInstructions: [{ required: true, message: "请输入退换货流程", trigger: "blur" }], 
      },
      reg:'/^[\u4e00-\u9fa5]{2,25}([·](?!\d)){1,}$/'
    };
  },
  created() {
    this.doLoadInfo();
  },
  computed: {
    
  },
  methods: {
    goNext() {
      this.$refs.shopForm.validate((valid) => {
        if (valid) {
         this.doUpdateInfo("NEXT")
        } else {
          return false;
        }
      });
    },
    goSave() {
      this.$refs.shopForm.validate((valid) => {
        if (valid) {
         this.doUpdateInfo()
        } else {
          return false;
        }
      });
    },
    doLoadInfo() {
      queryAfterSalesSettings().then((data) => {
        if (data) {
          for (let key in data) {
            if (this.formIn.hasOwnProperty(key) && data[key]) {
              this.formIn[key] = data[key];
            }
          }
          this.formIn.provinceCityArea = ''; //省市区
          if(data.provinceName){
            this.formIn.provinceCityArea = data.provinceName   //省市区
          }
          if(data.cityName){
            this.formIn.provinceCityArea += data.cityName   //省市区
          }
          if(data.areaName){
            this.formIn.provinceCityArea += data.areaName   //省市区
          } 
        }
      });
    },
    doUpdateInfo(status) {
      if (!this.formIn.consignee) {
        this.$message.warning("请输入收货人");
        return;
      }
      if (!this.formIn.consigneePhone) {
        this.$message.warning("请输入收货人电话");
        return;
      }
      if (!this.formIn.consigneeAddress) {
        this.$message.warning("请输入收货地址");
        return;
      }
      if (!this.formIn.changingRefundingInstructions) {
        this.$message.warning("请输入退换货说明");
        return;
      }

      let data = {};
      _.merge(data, this.formIn);
      data.areaList = this.allAreaCode;
      updateAfterSalesSettings(data).then((res) => {
        if (res) {
          this.$message.success("保存成功"); 
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style/index.scss";
</style>
