var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qualification-result-root" },
    [
      _vm.status === _vm.IConst_State.Fail
        ? [
            _c(
              "div",
              { staticClass: "pic-wrap" },
              [_c("el-image", { attrs: { src: _vm.failIcon } })],
              1
            ),
            _c("span", { staticClass: "big-tip" }, [_vm._v("资质审核不通过")]),
            _c("div", { staticClass: "dismissed-reason-layout" }, [
              _c("span", { staticClass: "tip" }, [_vm._v("驳回原因")]),
              _c("div", { staticClass: "text" }, [
                _vm._v(" " + _vm._s(_vm.reason) + " "),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "bottom-btn-layout" },
              [
                _c("el-button", { on: { click: _vm.handleChangeInfo } }, [
                  _vm._v("修改信息"),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "info-wrap" },
        [
          _c("InfoDetail", { attrs: { disabled: true } }),
          _vm.status === _vm.IConst_State.Sucess
            ? _c(
                "div",
                { staticClass: "bottom-btn-layout" },
                [
                  _c("el-button", { on: { click: _vm.handleChangeInfo } }, [
                    _vm._v("修改信息"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }