var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c(
      "div",
      { staticClass: "header", staticStyle: { "font-weight": "600" } },
      [_vm._v("平台活动报名")]
    ),
    _c("div", { staticClass: "sortTitle" }, [
      _c(
        "span",
        { staticClass: "sort", staticStyle: { display: "flex" } },
        [
          _vm._v("发布时间排序 "),
          _c("Arrow", {
            staticStyle: { padding: "0 5px" },
            on: { clickTriangle: _vm.clickTriangle },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "sort second",
          style: { color: _vm.lookMineActive ? "#F5222D" : "" },
          on: { click: _vm.lookMine },
        },
        [_vm._v("仅看已参加的活动")]
      ),
    ]),
    _c("div", { staticClass: "displayBox" }, [
      _c(
        "div",
        { staticClass: "center" },
        _vm._l(_vm.activeList, function (item, index) {
          return _c("div", { key: index, staticClass: "everyActiveBox" }, [
            _c(
              "div",
              {
                staticClass: "imageBox",
                staticStyle: { display: "inline-block" },
              },
              [
                _c("img", {
                  staticStyle: { width: "80px", height: "80px" },
                  attrs: { src: item.pcImageUrl, alt: "" },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "rightBox",
                staticStyle: { display: "inline-block" },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      "align-items": "center",
                      padding: "2px 0",
                    },
                  },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(item.activityName) + " "),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      "flex-direction": "column",
                      padding: "2px 0",
                    },
                  },
                  [
                    _c("div", { staticClass: "smallTitle" }, [
                      _vm._v(
                        " 活动时间：" +
                          _vm._s(item.startTime ? item.startTime : "") +
                          " - " +
                          _vm._s(item.endTime ? item.endTime : "") +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "smallTitle" }, [
                      _vm._v(
                        " 发布时间：" +
                          _vm._s(item.createTime ? item.createTime : "") +
                          " "
                      ),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              [
                item.participationFlag
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.active(item, "look")
                          },
                        },
                      },
                      [_vm._v("查看")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.active(item, "join")
                          },
                        },
                      },
                      [_vm._v("立即报名")]
                    ),
              ],
              1
            ),
          ])
        }),
        0
      ),
    ]),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "flex-end",
          padding: "5px 0",
        },
      },
      [
        _c("el-pagination", {
          attrs: {
            "current-page": _vm.page.current,
            "page-size": _vm.page.size,
            "page-sizes": [10, 20, 30, 50],
            layout: "total, sizes, prev, pager, next",
            total: _vm.page.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }