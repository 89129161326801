var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "first" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "search-frame" },
        [
          _c("el-image", {
            staticClass: "search-icon",
            attrs: { src: _vm.searchIcon },
          }),
          _c(
            "div",
            { staticClass: "input-frame" },
            [
              _c("el-input", {
                model: {
                  value: _vm.keywords,
                  callback: function ($$v) {
                    _vm.keywords = $$v
                  },
                  expression: "keywords",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "search-btn", on: { click: _vm.handleSearch } },
            [_vm._v("搜索")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "center" },
      _vm._l(_vm.records, function (item, index) {
        return _c("goodsItem", {
          key: index,
          staticClass: "goodsItem",
          attrs: { item: item },
          on: { addGunalian: _vm.addGunalian },
        })
      }),
      1
    ),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c(
          "el-button",
          {
            staticClass: "bototmButton",
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.submit },
          },
          [_vm._v("确认商品")]
        ),
        _c("Pagination", {
          attrs: {
            total: _vm.page.total,
            page: _vm.page.current,
            limit: _vm.page.size,
            pageSizes: _vm.page.sizes,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.page, "current", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.page, "size", $event)
            },
            pagination: _vm.changeSize,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }