<!--  -->
<template>
  <div class='classification'
       v-loading="loading">
    <Tabs :tabsList="tabsList"
          :activeName.sync="activeName"
          @tab-click="handleChange" />
    <div v-if="activeName == '1'">
      <div class="flex-box">
        <el-row :gutter="16"
                style="width:calc(100% - 60px)">
          <el-col :span="4">
            <el-select v-model="pageObj.page.goodsOneClassId"
                       size="small"
                       placeholder="一级分类"
                       style="width:100%"
                       @change="changeOneClassId">
              <el-option v-for="item in goodsOneClassIdOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id" />
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="pageObj.page.goodsTwoClassId"
                       size="small"
                       placeholder="二级分类"
                       clearable
                       style="width:100%"
                       @change="changeTwoClassId">
              <el-option v-for="item in goodsTwoClassIdOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id" />
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="pageObj.page.goodsThreeClassId"
                       size="small"
                       placeholder="三级分类"
                       clearable
                       style="width:100%">
              <el-option v-for="item in goodsThreeClassIdOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id" />
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-input v-model="pageObj.page.factory"
                      size="small"
                      placeholder="生产厂商"
                      clearable
                      @keyup.enter.native="getList(1)" />
          </el-col>
          <el-col :span="4">
            <el-input v-model="pageObj.page.name"
                      size="small"
                      placeholder="商品名称"
                      clearable
                      @keyup.enter.native="getList(1)" />
          </el-col>
          <el-col :span="4">
            <!-- <el-input v-model="approvalNumber"
                      size="small"
                      placeholder="批准文号"
                      clearable
                      @keyup.enter.native="getList(1)" /> -->
            <!-- <el-select v-model="pageObj.page.union"
                       clearable
                       size="small"
                       placeholder="请选择">
              <el-option v-for="item in optionsList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select> -->
            <el-button size="small"
                       type="primary"
                       @click="selectGoodsNounion(1)">检索并选择商品</el-button>
          </el-col>
        </el-row>
        <div style="display:flex;float: right;margin-left:32px">
          <el-button v-if="btnType == 'show'"
                     :disabled="addList.length == 0"
                     size="small"
                     type="primary"
                     @click="bathUpdateByProduct(1)">保存</el-button>
          <el-button size="small"
                     :disabled="tableData.length == 0"
                     type="primary"
                     @click="correlation()">全部{{btnType == 'show'?'':'取消'}}关联</el-button>
        </div>
      </div>
      <div class="contentCont"
           ref="tableCont">
        <div class="contentInner">
          <GoodsList v-for="(item,index) in tableData"
                     :key="index"
                     :item="item"
                     @addGunalian="addGunalian" />
        </div>
      </div>
      <div class="el-page">
        <Pagination :total="pageObj.total"
                    :page.sync="pageObj.page.current"
                    :limit.sync="pageObj.page.size"
                    @pagination="changeSize" />
      </div>
    </div>
    <div v-else
         style="padding:10px 0px">
      <el-cascader style="width:500px"
                   :options="options"
                   collapse-tags
                   :props="props"
                   @change="changeNode"
                   clearable></el-cascader>
    </div>
  </div>
</template>

<script>
import Tabs from '@/components/Tabs'
import Pagination from '@/components/Pagination'
import GoodsList from './goodsList'
import { goodsclassOne, goodsclassTwo } from '@/api/classificationmanagement.js'
import { goodsclassTree, goodsOperationalClassRelationsSelectGoods, bathUpdateByGoodsClass, selectGoodsNounion, bathUpdateByProduct, removeAll, addAll } from '@/api/operationCenter/operationalClassification'
export default {
  components: { Tabs, Pagination, GoodsList },
  props: {
    rowData: {
      type: Object,
      data: {}
    }
  },
  data () {
    return {
      loading: false,
      btnType: 'showFalse',
      addList: [],
      activeName: '1',
      goodsOneClassIdOptions: [],
      goodsTwoClassIdOptions: [],
      goodsThreeClassIdOptions: [],
      pageObj: {
        // 分页对象
        position: 'right', // 分页组件位置
        total: 0,
        page: {
          size: 10,
          current: 1,
          // pageSizes: [12, 24, 36, 48],
          name: '',
          factory: '',
          union: '',
          goodsOneClassId: '',
          goodsTwoClassId: '',
          goodsThreeClassId: ''
        }
      },
      tableData: [],
      tabsList: [ // tabs 传的值
        {
          name: '1',
          label: '关联商品',
          value: 0,
        },
        // {
        //   name: '2',
        //   label: '关联药理分类',
        //   value: 0,
        // }
      ],

      props: {
        label: 'name',
        value: 'id',
        // multiple: true
      },
      options: []
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 全部关联或者全部取消关联
    correlation () {
      this.loading = true
      console.log(this.rowData, 'rowData')
      if (this.btnType == 'showFalse') {
        // 移除已关联分类
        let params = {
          oneGoodsOperationalClassId: this.rowData.parentId,
          twoGoodsOperationalClassId: this.rowData.id,
        }
        removeAll(params).then(() => {
          this.loading = false
          this.$message.success('操作成功')
          this.tableData = []
          this.pageObj.total = 0
        }).catch(() => {
          this.loading = false
        })
      } else {
        // 关联所有分类
        let params = {
          goodsOneClassId: this.pageObj.page.goodsOneClassId,
          goodsTwoClassId: this.pageObj.page.goodsTwoClassId,
          goodsThreeClassId: this.pageObj.page.goodsThreeClassId,
          name: this.pageObj.page.name,
          factory: this.pageObj.page.factory,
          approvalNumber: this.pageObj.page.approvalNumber,
          oneGoodsOperationalClassId: this.rowData.parentId,
          twoGoodsOperationalClassId: this.rowData.id
        }
        addAll(params).then(() => {
          this.loading = false
          this.$message.success('操作成功')
          this.tableData = []
          this.pageObj.total = 0
        }).catch(() => {
          this.loading = false
        })
      }
    },
    //切换tabs按钮
    handleChange () {
      console.log(this.activeName)
      if (this.activeName == '1') {
        this.goodsOperationalClassRelationsSelectGoods()
      }
    },
    // 条数变化
    changeSize () {
      if (this.btnType == 'show') {
        this.selectGoodsNounion()
      } else {
        this.goodsOperationalClassRelationsSelectGoods()
      }
    },
    // 切换一级分类
    changeOneClassId (val) {
      if (val) {
        this.goodsclassTwo(val)
      }
    },
    // 切换二级分类
    changeTwoClassId (val) {
      if (val) {
        this.goodsclassThree(val)
      }
    },
    // 查询一级分类
    goodsclassOne () {
      goodsclassOne().then(res => {
        if (res.code == 0) {
          this.goodsOneClassIdOptions = res.data
          if (this.goodsOneClassIdOptions.length > 0) {
            this.pageObj.page.goodsOneClassId = this.goodsOneClassIdOptions[0].id
            // this.goodsclassTwo(this.goodsOneClassIdOptions[0].id)
          } else {
            this.pageObj.page.goodsOneClassId = ''
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 查询二级分类
    goodsclassTwo (id) {
      goodsclassTwo(id).then(res => {
        if (res.code == 0) {
          this.goodsTwoClassIdOptions = res.data
          if (this.goodsTwoClassIdOptions.length > 0) {
            this.pageObj.page.goodsTwoClassId = ''
            this.pageObj.page.goodsThreeClassId = ''
            this.goodsThreeClassIdOptions = []
            // this.goodsclassThree(this.goodsTwoClassIdOptions[0].id)
          } else {
            this.pageObj.page.goodsTwoClassId = ''
            this.pageObj.page.goodsThreeClassId = ''
            this.goodsThreeClassIdOptions = []
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 查询三级分类
    goodsclassThree (id) {
      goodsclassTwo(id).then(res => {
        if (res.code == 0) {
          this.goodsThreeClassIdOptions = res.data
          if (this.goodsThreeClassIdOptions.length > 0) {
            this.pageObj.page.goodsThreeClassId = ''
          } else {
            this.pageObj.page.goodsThreeClassId = ''
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 初次进来只查询一级分类 不联动
    query () {
      goodsclassOne().then(res => {
        if (res.code == 0) {
          this.goodsOneClassIdOptions = res.data
          this.pageObj.page.goodsOneClassId = this.goodsOneClassIdOptions[0].id
          this.goodsclassTwo(this.pageObj.page.goodsOneClassId)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 切换选中
    changeNode (val) {
      let arr = [val['2']]
      let params = {
        goodsThreeClassIdSet: arr,
        goodsOperationalClassQO: this.rowData
      }
      bathUpdateByGoodsClass(params).then(res => {
        console.log(res)
      })
    },
    // 查询分类树
    goodsclassTree () {
      goodsclassTree().then(res => {
        if (res.code == 0) {
          this.options = res.data
        }
      })
    },
    // 查询关联分类列表
    goodsOperationalClassRelationsSelectGoods (current) {
      if (current) {
        this.btnType = 'showFalse'
        this.pageObj.page.current = current
      }
      let params = {
        ...this.pageObj.page
      }
      if (this.rowData.level == 1) {
        params.oneGoodsOperationalClassId = this.rowData.id
      } else {
        params.oneGoodsOperationalClassId = this.rowData.parentId
        params.twoGoodsOperationalClassId = this.rowData.id
      }
      goodsOperationalClassRelationsSelectGoods(params).then(res => {
        console.log(res)
        this.tableData = res?.records ?? []
        this.pageObj.total = res.total
      })
    },
    // 查询未关联分类
    selectGoodsNounion (current) {
      this.addList = []
      if (current) {
        this.btnType = 'show'
        this.pageObj.page.current = current
      }
      let params = {
        ...this.pageObj.page
      }
      if (this.rowData.level == 1) {
        params.oneGoodsOperationalClassId = this.rowData.id
      } else {
        params.oneGoodsOperationalClassId = this.rowData.parentId
        params.twoGoodsOperationalClassId = this.rowData.id
      }
      selectGoodsNounion(params).then(res => {
        console.log(res)
        this.tableData = res?.records ?? []
        this.pageObj.total = res.total
      })
    },
    // 添加关联分类
    addGunalian (item) {
      console.log(this.addList, 'addList')
      if (item.clickNone) {
        this.addList.push(item)
      } else {
        if (this.addList.length > 0) {
          this.addList.forEach((el, index) => {
            if (el.barCode == item.barCode) {
              this.addList.splice(index, 1)
            }
          })
        }
      }
    },
    // 真正关联的接口
    bathUpdateByProduct () {
      let arr = []
      this.addList.forEach(item => {
        arr.push(item.goodsId)
      })
      let params = {
        goodsIdSet: arr,
        goodsOperationalClassQO: this.rowData,
      }
      bathUpdateByProduct(params).then(res => {
        console.log(res)
        this.goodsOperationalClassRelationsSelectGoods(1)
      })
    }
  },
  created () {
    this.query()
    this.goodsclassTree()
    this.goodsOperationalClassRelationsSelectGoods()
  },
  mounted () {
  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
}
</script>
<style lang='scss' scoped>
.classification {
  height: 100%;
  padding: 0px;
  overflow: hidden;
  .tabs {
    ::v-deep .headerTabs {
      padding: 0px;
    }
  }
  .flex-box {
    width: 100%;
    display: flex;
    // padding: 10px 0;
    // flex-wrap: wrap;
    padding: 12px 0px;

    // border-bottom: 1px solid #f5f5f5;
    .el-row {
      .el-col {
        padding-right: 0px !important;
      }
    }

    .tab_sea {
      padding: 0px 5px;
    }
  }

  .contentCont {
    height: 532px;
    overflow: auto;
    padding: 0 0px;

    .contentInner {
      // height: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
  .el-page {
    padding: 0px !important;
  }
}
</style>