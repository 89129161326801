<template>
  <div class="divideAccount">
    <div class="tit">分账设置</div>
    <el-form label-width="100px"
             label-position="left">
      <el-form-item label="启用分账：">
        <el-switch v-model="enable"
                   @change="changeSwitch"> </el-switch>
      </el-form-item>
    </el-form>
    <el-form ref="accountForm"
             :model="accountForm"
             :rules="rules"
             label-width="100px"
             label-position="left"
             v-show="enable">
      <el-form-item label="规则名称："
                    prop="ruleName">
        <el-input v-model="accountForm.ruleName"
                  size="mini"
                  style="width: 60%"></el-input>
      </el-form-item>
      <div class="listItem"
           v-for="(item, index) in accountForm.ledgerRulesItemList"
           :key="index">
        <div class="itemTit">分账对象设置</div>
        <div class="del"
             v-if="index > 0">
          <el-button type="danger"
                     size="small"
                     @click="del(index)">- 移除分账对象</el-button>
        </div>
        <el-form-item label="商户名称："
                      :prop="'ledgerRulesItemList.' + index + '.shopName'"
                      :rules="rules.shopName">
          <el-input v-model="item.shopName"
                    size="mini"
                    style="width: 50%"
                    readonly /><el-button style="margin-left: 10px"
                     size="mini"
                     type="primary"
                     @click="chooseShop(index, 'ledger')">选择商户</el-button>
        </el-form-item>
        <el-form-item label="康贝商户号：">
          <span>{{ item.merchantNo }}</span>
        </el-form-item>
        <el-form-item label="费用类型："
                      :prop="'ledgerRulesItemList.' + index + '.costType'"
                      :rules="rules.costType">
          <el-select v-model="item.costType"
                     placeholder="请选择费用类型">
            <el-option v-for="itemC in typeList"
                       :label="itemC.value"
                       :value="itemC.code"
                       :key="itemC.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分账金额:"
                      :prop="'ledgerRulesItemList.' + index + '.amount'"
                      :rules="rules.amount">
          <el-input v-model="item.amount"
                    type="number"
                    size="mini"
                    style="width: 30%"></el-input>
          元
        </el-form-item>
        <el-form-item class="cost"
                      label="待付费用"
                      :prop="'ledgerRulesItemList.' + index + '.includeService'">
          <el-switch v-model="item.includeService"></el-switch>
          <span class="mark">
            备注：本项为春播组织内部地办支付给省办咨询服务费专有选项；</span>
        </el-form-item>
        <div v-if="item.includeService">
          <el-form-item label="商户名称："
                        :prop="'ledgerRulesItemList.' + index + '.serviceShopName'"
                        :rules="rules.serviceShopName">
            <el-input v-model="item.serviceShopName"
                      size="mini"
                      style="width: 50%"
                      readonly /><el-button style="margin-left: 10px"
                       size="mini"
                       type="primary"
                       @click="chooseShop(index, 'service')">选择商户</el-button>
          </el-form-item>
          <el-form-item label="分账金额:"
                        :prop="'ledgerRulesItemList.' + index + '.serviceAmount'"
                        :rules="rules.serviceAmount">
            <el-input v-model="item.serviceAmount"
                      type="number"
                      size="mini"
                      style="width: 30%"></el-input>
            元
          </el-form-item>
        </div>
      </div>
      <div class="add">
        <el-button style="background: none; border: 1px solid #F5222D; color: #F5222D"
                   size="small"
                   @click="add">+ 新增分账对象</el-button>
      </div>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('accountForm')">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
    <el-dialog title="选择商户"
               :visible.sync="dialogTableVisible"
               style="posisiton: relative">
      <div class="search">
        <span>商家名称：</span>
        <el-input v-model="shopName"
                  size="small"
                  placeholder="请输入商家名称"
                  style="width: 300px" />
        <el-button size="small"
                   type="primary"
                   style="margin-left: 8px"
                   @click="merchantInfo(1)">查询</el-button>
        <el-button size="small"
                   style="margin-left: 8px"
                   @click="reset">重置</el-button>
      </div>
      <div class="center">
        <MyTable :table-data="tableDatadialog"
                 :page="page"
                 :operation="operation"
                 :table-option.sync="tableOption"
                 @page-change="getcheckList">
          <template slot="caozuo"
                    slot-scope="scope">
            <el-link :underline="false"
                     type="primary"
                     @click="addShop(scope.row)">确定</el-link>
          </template>
        </MyTable>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import MyTable from "@/components/myTable";
import { merchantInfo } from "@/api/divisionrulemanagement/divisionrule";
import {
  changeLedgerRuleStatus,
  getLedgerRulesDetail,
  ledgerRulesCheckExits,
  ledgerRulesSave,
  ledgerRulesUpdate,
  geCostType,
} from "@/api/saleRule/productLine";
import { moneyFix } from "@/utils/validate";
export default {
  components: { MyTable },
  props: ["skuId"],
  data () {
    return {
      enable: false,
      accountForm: {
        ruleName: "",
        status: true,
        ledgerRulesItemList: [
          {
            shopName: "",
            amount: "",
            merchantNo: "",
            shopId: "",
            includeService: false,
            costType: "",
            serviceMerchantNo: "",
            serviceShopName: "",
            serviceShopId: "",
            serviceAmount: "",
          },
        ],
      },
      typeList: [],
      rules: {
        ruleName: [
          { required: true, message: "请输入规则名称", trigger: "blur" },
        ],
        shopName: [
          { required: true, message: "请选择商户", trigger: "change" },
        ],
        costType: [
          { required: true, message: "请选择分账类型", trigger: "change" },
        ],
        serviceShopName: [
          { required: true, message: "请选择商户", trigger: "change" },
        ],
        amount: [{ required: true, validator: moneyFix, trigger: "blur" }],
        serviceAmount: [
          { required: true, validator: moneyFix, trigger: "blur" },
        ],
      },
      dialogTableVisible: false,
      tableOption: [
        { label: "康贝商户号", prop: "merchantNo" },
        { label: "康贝商户名称", prop: "shopName" },
        { label: "操作", prop: "caozuo", slot: true, width: "60px" },
      ],
      operation: false,
      tableDatadialog: [],
      shopName: "",
      page: {
        total: 10,
        current: 1,
        size: 10,
      },
      sleShopindex: 0,
      isHavRules: false, //是否有分账
      shopSleType: "",
    };
  },
  created () {
    this.merchantInfo();
    this.getCostType();
  },
  methods: {
    add () {
      this.accountForm.ledgerRulesItemList =
        this.accountForm.ledgerRulesItemList.concat(
          this.$options.data().accountForm.ledgerRulesItemList
        );
    },
    getCostType () {
      geCostType().then((res) => {
        if (+res.code === 0) {
          this.typeList = res.data;
        }
      });
    },
    del (idx) {
      this.accountForm.ledgerRulesItemList.splice(idx, 1);
    },
    // 选择商户
    chooseShop (index, type) {
      this.sleShopindex = index;
      this.shopSleType = type;
      this.dialogTableVisible = true;
    },
    // 重置
    reset () {
      this.shopName = "";
    },
    // 查询商户名称
    getcheckList () {
      this.merchantInfo();
    },
    // 确定选择商户
    addShop (row) {
      if (this.shopSleType == "service") {
        this.accountForm.ledgerRulesItemList[
          this.sleShopindex
        ].serviceShopName = row.shopName;
        this.accountForm.ledgerRulesItemList[
          this.sleShopindex
        ].serviceMerchantNo = row.merchantNo;
        this.accountForm.ledgerRulesItemList[this.sleShopindex].serviceShopId =
          row.shopId;
      } else {
        this.accountForm.ledgerRulesItemList[this.sleShopindex].shopName =
          row.shopName;
        this.accountForm.ledgerRulesItemList[this.sleShopindex].merchantNo =
          row.merchantNo;
        this.accountForm.ledgerRulesItemList[this.sleShopindex].shopId =
          row.shopId;
      }
      this.dialogTableVisible = false;
    },
    // 查询商户
    merchantInfo (current) {
      if (current) {
        this.page.current = current;
      }
      let params = {
        ...this.page,
        shopName: this.shopName,
      };
      merchantInfo(params).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.tableDatadialog = res.data.records;
            this.page.total = res.data.total;
          }
        }
      });
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isHavRules) {
            this.updataRules();
          } else {
            this.saveLedgerRules();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 保存分账
    saveLedgerRules () {
      let data = {
        ...this.accountForm,
        skuId: this.skuId,
      };
      ledgerRulesSave(data).then((res) => {
        // if (res.code == 0) {
        this.saveSuccess();
        // } else {
        //   this.$message.error(res.msg)
        // }

      });
    },
    // 获取分账详情
    getRulesDetail (skuId) {
      getLedgerRulesDetail({ skuId: skuId }).then((res) => {
        if (res) {
          this.accountForm.id = res.id;
          this.accountForm.ruleName = res.ruleName;
          this.accountForm.ledgerRulesItemList = this.copyObj(
            res.ledgerRulesItemList
          );
        }
      });
    },
    copyObj (list) {
      let tempList = [];
      if (list && list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          tempList.push({
            shopName: list[i].shopName,
            amount: list[i].amount,
            merchantNo: list[i].merchantNo,
            shopId: list[i].shopId,
            includeService: list[i].includeService,
            costType: list[i].costType,
            serviceShopName: list[i].serviceShopName,
            serviceMerchantNo: list[i].serviceMerchantNo,
            serviceShopId: list[i].serviceShopId,
            serviceAmount: list[i].serviceAmount,
          });
        }
      }
      return tempList;
    },
    // 是否停用分账
    changeSwitch (e) {
      this.accountForm = this.$options.data().accountForm;
      if (!e && this.isHavRules) {
        this.changeLedgerRuleStatusFun();
      }
    },
    // 更新分账
    updataRules () {
      let data = {
        ...this.accountForm,
        skuId: this.skuId,
      };
      ledgerRulesUpdate(data).then((res) => {
        if (res.code == 0) {
          this.saveSuccess();
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    saveSuccess () {
      this.$message.success("保存成功");
      this.$emit("save");
    },
    // 停用分账
    changeLedgerRuleStatusFun () {
      changeLedgerRuleStatus({ skuId: this.skuId }).then((res) => {
        if (res) {
          this.$message.success("已停用");
          this.isHavRules = false;
          this.$data.accountForm = this.$options.data().accountForm;
        }
      });
    },
    // 取消
    cancel () {
      this.$emit("save");
    },
    // 判断是否有分账
    checkExits (skuId) {
      ledgerRulesCheckExits({ skuId: skuId }).then((res) => {
        this.enable = res;
        this.isHavRules = res;
        this.$data.accountForm = this.$options.data().accountForm;
        if (res) {
          this.getRulesDetail(skuId);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.divideAccount {
  padding-left: 20px;
  box-sizing: border-box;
  .tit {
    font-size: 16px;
    position: relative;
  }
  .tit::before {
    content: "";
    display: inline-block;
    width: 3px;
    height: 14px;
    background-color: #f5222d;
    position: absolute;
    top: 2px;
    left: -10px;
  }
  .listItem {
    margin-top: 10px;
    border: solid 1px #f5f5f5;
    padding: 10px 20px;
    box-sizing: border-box;
    position: relative;
    .itemTit {
      margin-bottom: 10px;
      font-size: 16px;
      color: #000000;
      font-weight: 7000;
    }
    .del {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;
    }
  }
  .add {
    margin-top: 6px;
    margin-bottom: 30px;
  }
  .mark {
    color: #f56c6c;
    font-size: 12px;
    margin-left: 10px;
    width: 200px;
    line-height: 1.5;
    display: inline-block;
    font-weight: bold;
    vertical-align: middle;
  }
  .cost {
    ::v-deep .el-form-item__label {
      font-size: 16px;
      color: #000000;
      font-weight: 7000;
    }
  }
}
</style>
