/*
 * @FilePath: /eshop-ops-web/src/utils/getComList.js
 * @Description: 获取常用公共list 
 * @Author: xiexingzhong2012@sina.cn
 * @Date: 2023-07-26 17:15:36
 * @LastEditTime: 2023-07-27 17:06:22
 * @LastEditors: xiexingzhong2012@sina.cn
 */

import {
    getGoodsType, queryScopeList
} from "@/api/comList";

function getGoodsTypeFn() {
    let dataList = [];
    return getGoodsType()
        .then((res) => {
            if (res.code == 0) {
                return dataList = res.data;
            } else {
                return dataList = []
            }
        })
        .catch((err) => {
            console.log(err);
        });

}

//查询所有经营数据，不分页
function queryScopeListFn() {
    let dataList = [];
    return queryScopeList()
        .then((res) => {
            if (res.code == 0) {
                return dataList = res.data;
            } else {
                return dataList = []
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

export default {
    goodsType: getGoodsTypeFn(),
    scopeList: queryScopeListFn() //经营范围
}
