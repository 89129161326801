var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "detail comContent" }, [
    _c("div", { staticClass: "tit" }, [_vm._v(_vm._s(_vm.pageTitle))]),
    _c(
      "div",
      { staticClass: "form" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "120px",
              disabled: _vm.type == 2,
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "拼团活动名称", prop: "name" } },
              [
                _c("el-input", {
                  staticStyle: { width: "30%" },
                  attrs: {
                    size: "small",
                    maxlength: "50",
                    placeholder: "请输入活动名称",
                  },
                  model: {
                    value: _vm.ruleForm.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "name", $$v)
                    },
                    expression: "ruleForm.name",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "拼团活动说明", prop: "remark" } },
              [
                _c("el-input", {
                  staticStyle: { width: "30%" },
                  attrs: { type: "textarea", placeholder: "请输入活动说明" },
                  model: {
                    value: _vm.ruleForm.remark,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "remark", $$v)
                    },
                    expression: "ruleForm.remark",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "活动开始时间", prop: "date" } },
              [
                _c("el-date-picker", {
                  staticStyle: { width: "30%" },
                  attrs: {
                    "value-format": "yyyy-MM-dd",
                    type: "daterange",
                    size: "small",
                    "range-separator": "-",
                    "start-placeholder": "拼团开始",
                    "end-placeholder": "拼团结束",
                  },
                  model: {
                    value: _vm.ruleForm.date,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "date", $$v)
                    },
                    expression: "ruleForm.date",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "成团人数", prop: "minMemberCount" } },
              [
                _c("el-input", {
                  staticStyle: { width: "30%" },
                  attrs: {
                    size: "small",
                    maxlength: "10",
                    placeholder: "请输入成团人数",
                    oninput:
                      "\n              if (value.length > 8) {\n                value = value.slice(0, 8);\n              }\n              value = value.replace(/[^0-9]/g, '');\n            ",
                  },
                  model: {
                    value: _vm.ruleForm.minMemberCount,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "minMemberCount", $$v)
                    },
                    expression: "ruleForm.minMemberCount",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "是否自动成团", prop: "ignoreLimit" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.ruleForm.ignoreLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "ignoreLimit", $$v)
                      },
                      expression: "ruleForm.ignoreLimit",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 0 } }, [_vm._v("是")]),
                    _c("el-radio", { attrs: { label: 1 } }, [_vm._v("否")]),
                  ],
                  1
                ),
              ],
              1
            ),
            !_vm.isDisabled
              ? _c(
                  "el-form-item",
                  { staticStyle: { "text-align": "left" } },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "small" }, on: { click: _vm.cancel } },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.submitForm("ruleForm")
                          },
                        },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.isDisabled
          ? _c(
              "div",
              {
                staticStyle: {
                  "text-align": "left",
                  "margin-top": "10px",
                  "padding-left": "120px",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.cancel },
                  },
                  [_vm._v("返回")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }