var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("div", { staticClass: "shourujilu" }, [
        _c("span", [
          _vm._v("待商业付款 "),
          _c("span", { staticStyle: { color: "red" } }, [
            _vm._v(" " + _vm._s(_vm.waitPay) + " "),
          ]),
          _vm._v(" 条"),
        ]),
        _c("span", { staticStyle: { "margin-left": "100px" } }, [
          _vm._v("审核未通过，待商业处理 "),
          _c("span", { staticStyle: { color: "red" } }, [
            _vm._v(" " + _vm._s(_vm.failAudit) + " "),
          ]),
          _vm._v(" 条"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "title" },
            [
              _c("div", { staticClass: "detail" }, [_vm._v("应缴纳佣金记录")]),
              _c(
                "el-form",
                {
                  ref: "formTool",
                  staticStyle: { "text-align": "left" },
                  attrs: { model: _vm.formTool, inline: true },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "small",
                            clearable: "",
                            placeholder: "状态",
                          },
                          model: {
                            value: _vm.auditType,
                            callback: function ($$v) {
                              _vm.auditType = $$v
                            },
                            expression: "auditType",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.value, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("MyButton", {
                        attrs: { type: "primary", text: "查询" },
                        on: {
                          click: function ($event) {
                            return _vm.getcheckList(1)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("MyButton", {
                        attrs: { type: "", text: "重置" },
                        on: {
                          click: function ($event) {
                            return _vm.reset()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c("div", { staticClass: "search_income" }),
          _c(
            "div",
            { staticClass: "center" },
            [
              _c("MyTable", {
                staticClass: "myMoney",
                attrs: {
                  "table-data": _vm.tableData,
                  index: _vm.index,
                  selection: _vm.selection,
                  page: _vm.page,
                  operation: _vm.operation,
                  "table-option": _vm.tableOption,
                },
                on: {
                  "update:tableOption": function ($event) {
                    _vm.tableOption = $event
                  },
                  "update:table-option": function ($event) {
                    _vm.tableOption = $event
                  },
                  "page-change": _vm.getcheckList,
                },
                scopedSlots: _vm._u([
                  {
                    key: "amount",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v("￥" + _vm._s(scope.row.amount))]),
                      ]
                    },
                  },
                  {
                    key: "discountAmount",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v("￥" + _vm._s(scope.row.discountAmount)),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "actualAmount",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v("￥" + _vm._s(scope.row.actualAmount)),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "pingzhneg",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.look(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 查看凭证")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "caozuo",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              disabled:
                                scope.row.status != "待商业付款" &&
                                scope.row.status != "审核未通过",
                              underline: false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.audit(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 发起审核")]
                        ),
                        _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.detail(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 查看明细")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editorInvoice,
            title: "选择发票抬头",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editorInvoice = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "invoiceForm",
              attrs: {
                model: _vm.invoiceForm,
                rules: _vm.invoiceRules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "发票类型", prop: "opentype" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.invoiceForm.opentype,
                        callback: function ($$v) {
                          _vm.$set(_vm.invoiceForm, "opentype", $$v)
                        },
                        expression: "invoiceForm.opentype",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("普票")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("专票")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "抬头类型", prop: "headerType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.invoiceForm.headerType,
                        callback: function ($$v) {
                          _vm.$set(_vm.invoiceForm, "headerType", $$v)
                        },
                        expression: "invoiceForm.headerType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("个人")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("企业")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发票抬头", prop: "buyerName" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "260px" },
                      attrs: { placeholder: "请选择抬头", "value-key": "id" },
                      on: { change: _vm.selectBtn },
                      model: {
                        value: _vm.invoiceForm.buyerId,
                        callback: function ($$v) {
                          _vm.$set(_vm.invoiceForm, "buyerId", $$v)
                        },
                        expression: "invoiceForm.buyerId",
                      },
                    },
                    _vm._l(_vm.selectedData, function (i, k) {
                      return _c(
                        "el-option",
                        { key: k, attrs: { label: i.buyerName, value: i } },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(i.buyerName)),
                          ]),
                          i.preferred
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "right",
                                    color: "#F5222D",
                                    "font-size": "12px",
                                  },
                                },
                                [_vm._v("默认")]
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "纳税人识别号", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "260px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.invoiceForm.buyerTaxpayerId,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoiceForm, "buyerTaxpayerId", $$v)
                      },
                      expression: "invoiceForm.buyerTaxpayerId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-bottom": "10px",
                    "margin-left": "60px",
                    color: "#f99b0c",
                    cursor: "pointer",
                  },
                  on: { click: _vm.needSelected },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.needCan ? "收起非必要信息" : "展开非必要信息"
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.needCan
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.invoiceForm.buyerAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.invoiceForm, "buyerAddress", $$v)
                              },
                              expression: "invoiceForm.buyerAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "电话" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.invoiceForm.buyerRecipientPhone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.invoiceForm,
                                  "buyerRecipientPhone",
                                  $$v
                                )
                              },
                              expression: "invoiceForm.buyerRecipientPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "开户行" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.invoiceForm.buyerBank,
                              callback: function ($$v) {
                                _vm.$set(_vm.invoiceForm, "buyerBank", $$v)
                              },
                              expression: "invoiceForm.buyerBank",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "开户行账号" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.invoiceForm.buyerAccount,
                              callback: function ($$v) {
                                _vm.$set(_vm.invoiceForm, "buyerAccount", $$v)
                              },
                              expression: "invoiceForm.buyerAccount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "发票内容" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.invoiceForm.desc,
                        callback: function ($$v) {
                          _vm.$set(_vm.invoiceForm, "desc", $$v)
                        },
                        expression: "invoiceForm.desc",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("商品明细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "260px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.invoiceForm.buyerRecipientMail,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoiceForm, "buyerRecipientMail", $$v)
                      },
                      expression: "invoiceForm.buyerRecipientMail",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.editorInvoice = false
                      _vm.invoiceForm = {}
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.selectContent()
                    },
                  },
                },
                [_vm._v("选择开票内容")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择开票内容",
            visible: _vm.dialogVisible,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "detailTable" },
            [
              _c("MyTable", {
                staticClass: "detailTableHeight",
                attrs: {
                  "table-data": _vm.invoiceTbleData,
                  selection: _vm.invoiceSelection,
                  page: _vm.invoicePage,
                  operation: _vm.invoiceOperation,
                  "table-option": _vm.invoiceTableOption,
                },
                on: {
                  "update:tableOption": function ($event) {
                    _vm.invoiceTableOption = $event
                  },
                  "update:table-option": function ($event) {
                    _vm.invoiceTableOption = $event
                  },
                  "page-change": _vm.getcheckList,
                  handleSelectionChange: _vm.handleSelectionChange,
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.sureBtn },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "付款备注", visible: _vm.payVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.payVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "请输入内容",
              disabled: _vm.pingzhengDisabled,
              maxlength: "200",
              "show-word-limit": "",
            },
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
          _c(
            "ImgUpload",
            {
              staticStyle: { position: "relative", top: "-8px" },
              attrs: { isEdit: _vm.pingzhengDisabled, limit: 3 },
              model: {
                value: _vm.imageList,
                callback: function ($$v) {
                  _vm.imageList = $$v
                },
                expression: "imageList",
              },
            },
            [
              _c("template", { slot: "tip" }, [
                _c(
                  "p",
                  {
                    staticClass: "el-upload__tip",
                    staticStyle: { margin: "0px" },
                  },
                  [
                    _vm._v(
                      " 备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过5M;最多上传三张图片 "
                    ),
                  ]
                ),
              ]),
            ],
            2
          ),
          !_vm.pingzhengDisabled
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.payVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "明细", visible: _vm.detailVisible, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.detailVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "search_income" }, [
            _c("div", [
              _vm._v(" 收入："),
              _c("span", [_vm._v("￥" + _vm._s(_vm.totalPayeeAmount))]),
              _vm._v("元 "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "detailTable" },
            [
              _c("MyTable", {
                staticClass: "detailTableHeight",
                attrs: {
                  "table-data": _vm.detailTbleData,
                  selection: _vm.detailSelection,
                  page: _vm.detailPage,
                  operation: _vm.detailOperation,
                  "table-option": _vm.detailTableOption,
                },
                on: {
                  "update:tableOption": function ($event) {
                    _vm.detailTableOption = $event
                  },
                  "update:table-option": function ($event) {
                    _vm.detailTableOption = $event
                  },
                  "page-change": _vm.detailChange,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "header" }, [
      _c("div", { staticClass: "first" }, [
        _c("p", [_vm._v("佣金缴纳规则：")]),
        _c("span", { staticClass: "everySpan" }, [
          _vm._v(
            "1. 当企业信息中的佣金结算方式为“月结”时，系统每月1号凌晨生成上个月的佣金缴纳记录"
          ),
        ]),
        _c("span", { staticClass: "everySpan" }, [
          _vm._v(
            "2. 针对“待商业付款”的记录需要您在约定的最后付款时间前足额缴纳，并上传付款凭证"
          ),
        ]),
        _c("span", { staticClass: "everySpan" }, [
          _vm._v(
            "3. 针对“审核未通过”的记录需要您在约定的最后付款时间前处理完毕"
          ),
        ]),
        _c("span", { staticClass: "everySpan" }, [
          _vm._v(
            "4. 佣金缴纳记录在约定时间内未处理完成，记录将标记为“已逾期”，若您存在已逾期的佣金缴纳记录，系统将限制您的申请提现功能，当佣金缴纳记录处理完成后，系统将自动恢复您的申请提现功能，为了保障您的使用体验，请按时足额缴纳佣金，并及时处理缴纳记录"
          ),
        ]),
        _c("span", { staticClass: "everySpan" }, [
          _vm._v(
            "5. 请将“实际需缴纳佣金金额”转账至下面的平台对公账户，平台财务将在1个工作日内确认完成"
          ),
        ]),
      ]),
      _c("div", { staticClass: "second" }, [
        _c("div", { staticClass: "everySpan" }, [
          _c("span", [_vm._v("公司名称：")]),
          _vm._v(" 湖南春播云药库科技有限公司"),
        ]),
        _c("div", { staticClass: "everySpan" }, [
          _c("span", [_vm._v("开户行： ")]),
          _vm._v(" 湖南春播云药库科技有限公司"),
        ]),
        _c("div", { staticClass: "everySpan" }, [
          _c("span", [_vm._v("银行账户：")]),
          _vm._v(" 湖南春播云药库科技有限公司"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }