<template>
  <div class="shop-info-root tab-content new-cls">
    <div class="form">
      <div class="block">
        <div class="block-content">
          <div class="row">
            <div class="tip"><span class="weight">*</span>工作时间</div>
            <div class="value fill">
              <el-checkbox
                v-model="item.checked"
                v-for="(item, index) in weekDays"
                :key="index"
                >{{ item.label }}</el-checkbox
              >
            </div>
          </div>
          <div class="row">
            <div class="tip"></div>
            <div class="value fill">
              <el-button size="small" type="primary" @click="doUpdateInfo"
                >立即提交</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="block-title">
          <span class="text">非考核时间段</span>
          <span class="desp">（如库存盘点、ERP切换等，最长时间为30天）</span>
        </div>
        <div class="block-content">
          <div class="row">
            <div class="tip">起止时间</div>
            <div class="value input big-w">
              <el-date-picker
                size="small"
                v-model="dnonAssessmentTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <template v-if="0">
            <div class="row">
              <div class="tip">截单时间</div>
              <div class="value input big-w">
                <el-date-picker
                  size="small"
                  v-model="dnonAssessmentCutOffTime"
                  type="datetime"
                  placeholder="请选择"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="row contine">
              <div class="tip"></div>
              <div class="value fill">
                <div class="small-tip">
                  <span
                    >截单时间提示为[2023年9月28日15时截单],显示在app或PC供应商名前面</span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="tip">发货时间</div>
              <div class="value input big-w">
                <el-date-picker
                  size="small"
                  v-model="dnonAssessmentReturnTime"
                  type="datetime"
                  placeholder="请选择"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="row contine">
              <div class="tip"></div>
              <div class="value fill">
                <div class="small-tip">
                  <span
                    >发货时间提示为[2023年9月28日15时发货],显示在app或PC供应商名前面</span
                  >
                </div>
              </div>
            </div>
          </template>
          <div class="row">
            <div class="tip"></div>
            <div class="value fill">
              <el-button size="small" type="primary" @click="doUpdateInfo"
                >保存</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryDaySettings, updateDaySettings } from "@/api/shop";
import dayjs from "dayjs";

export default {
  components: {},
  data() {
    return {
      weekDays: [
        { label: "周一", checked: false, value: 1 },
        { label: "周二", checked: false, value: 2 },
        { label: "周三", checked: false, value: 3 },
        { label: "周四", checked: false, value: 4 },
        { label: "周五", checked: false, value: 5 },
        { label: "周六", checked: false, value: 6 },
        { label: "周日", checked: false, value: 7 },
      ],

      dnonAssessmentTime: "",
      dnonAssessmentCutOffTime: "",
      dnonAssessmentReturnTime: "",

      formIn: {
        workingHours: "", //工作时间
        nonAssessmentStartTime: "", //非考核开始时间
        nonAssessmentEndTime: "", //非考核结束时间
        nonAssessmentCutOffTime: "", //非考核截单时间
        nonAssessmentReturnTime: "", //非考核发货时间
      },
    };
  },
  created() {
    this.doLoadInfo();
  },
  methods: {
    doLoadInfo() {
      queryDaySettings().then((data) => {
        if (data) {
          if (data.workingHours) {
            let arr = data.workingHours.split(",");
            this.weekDays.forEach((item) => {
              if (arr.includes(item.label) || arr.includes("" + item.value)) {
                item.checked = true;
              }
            });
          }
          if (data.nonAssessmentStartTime && data.nonAssessmentEndTime) {
            this.dnonAssessmentTime = [
              dayjs(data.nonAssessmentStartTime).toDate(),
              dayjs(data.nonAssessmentEndTime).toDate(),
            ];
          }

          if (data.nonAssessmentCutOffTime) {
            this.dnonAssessmentCutOffTime = dayjs(
              data.nonAssessmentCutOffTime
            ).toDate();
          }

          if (data.nonAssessmentReturnTime) {
            this.dnonAssessmentReturnTime = dayjs(
              data.nonAssessmentReturnTime
            ).toDate();
          }
        }
      });
    },
    doUpdateInfo() {
      let workingHours = "";
      this.weekDays.forEach((item) => {
        if (item.checked) {
          if (workingHours) {
            workingHours += ",";
          }
          workingHours += item.value;
        }
      });
      if (!workingHours) {
        this.$message.warning("请选择工作时间");
        return;
      }
      this.formIn.workingHours = workingHours;

      if (
        this.dnonAssessmentTime instanceof Array &&
        this.dnonAssessmentTime.length === 2
      ) {
        this.formIn.nonAssessmentStartTime = dayjs(
          this.dnonAssessmentTime[0]
        ).format("YYYY-MM-DD HH:mm:ss");

        this.formIn.nonAssessmentEndTime = dayjs(
          this.dnonAssessmentTime[1]
        ).format("YYYY-MM-DD HH:mm:ss");
      }
      if (this.dnonAssessmentCutOffTime) {
        this.formIn.nonAssessmentCutOffTime = dayjs(
          this.dnonAssessmentCutOffTime
        ).format("YYYY-MM-DD HH:mm:ss");
      }
      if (this.dnonAssessmentReturnTime) {
        this.formIn.nonAssessmentReturnTime = dayjs(
          this.dnonAssessmentReturnTime
        ).format("YYYY-MM-DD HH:mm:ss");
      }

      let data = {};
      _.merge(data, this.formIn);
      updateDaySettings(data).then((res) => {
        if (res) {
          this.$message.success("保存成功");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
