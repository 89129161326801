var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c("div", { staticClass: "header" }, [
      _vm._m(0),
      _c("div", { staticClass: "info-list" }, [
        _c("span", { staticClass: "list" }, [
          _vm._v(" 总订单数:"),
          _c("span", [
            _vm._v(" " + _vm._s(_vm.stProvinceAmtPo.orderNum || 0) + " "),
          ]),
        ]),
        _c("span", { staticClass: "list" }, [
          _vm._v(" 总销售额:"),
          _c("span", [
            _vm._v(
              "￥" +
                _vm._s(_vm._f("pirceFormatter")(_vm.stProvinceAmtPo.orderAmt)) +
                " "
            ),
          ]),
        ]),
        _c("span", { staticClass: "list" }, [
          _vm._v(" 省区公司应收:"),
          _c("span", [
            _vm._v(
              "￥ " +
                _vm._s(
                  _vm._f("pirceFormatter")(
                    _vm.stProvinceAmtPo.provinceReceivableAmt
                  )
                ) +
                " "
            ),
          ]),
        ]),
        _c("span", { staticClass: "list" }, [
          _vm._v(" 省区公司实收:"),
          _c("span", [
            _vm._v(
              "￥ " +
                _vm._s(
                  _vm._f("pirceFormatter")(
                    _vm.stProvinceAmtPo.provinceArrivalAmt
                  )
                ) +
                " "
            ),
          ]),
        ]),
        _c("span", { staticClass: "list" }, [
          _vm._v(" 省区公司未收:"),
          _c("span", [
            _vm._v(
              "￥ " +
                _vm._s(
                  _vm._f("pirceFormatter")(
                    _vm.stProvinceAmtPo.provinceNotyetAmt
                  )
                ) +
                " "
            ),
          ]),
        ]),
      ]),
    ]),
    _vm.isReport
      ? _c("div", { staticClass: "reportContent" }, [
          _c("div", {
            staticStyle: { width: "100%", height: "300px" },
            attrs: { id: "main" },
          }),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "search-from" },
      [
        _c(
          "el-form",
          {
            ref: "searchForm",
            staticStyle: { "text-align": "left" },
            attrs: { model: _vm.searchForm, inline: true },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        class: _vm.dateKey == 3 ? "active-button" : "",
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.setDate(3)
                          },
                        },
                      },
                      [_vm._v("三个月")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        class: _vm.dateKey == 2 ? "active-button" : "",
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.setDate(2)
                          },
                        },
                      },
                      [_vm._v("近一月")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        class: _vm.dateKey == 1 ? "active-button" : "",
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.setDate(1)
                          },
                        },
                      },
                      [_vm._v("近一周")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "140px" } },
                  [
                    _c("el-date-picker", {
                      staticClass: "date-picker",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        type: "daterange",
                        size: "small",
                        "range-separator": "-",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "picker-options": _vm.pickerOptions,
                      },
                      model: {
                        value: _vm.searchForm.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "date", $$v)
                        },
                        expression: "searchForm.date",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-form-item",
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入诊所名称",
                        clearable: "",
                        maxlength: "20",
                        size: "small",
                      },
                      model: {
                        value: _vm.searchForm.clinicName,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "clinicName", $$v)
                        },
                        expression: "searchForm.clinicName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入父订单号",
                        clearable: "",
                        maxlength: "20",
                        size: "small",
                      },
                      model: {
                        value: _vm.searchForm.relatedOrderCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "relatedOrderCode", $$v)
                        },
                        expression: "searchForm.relatedOrderCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入商户名称",
                        clearable: "",
                        maxlength: "20",
                        size: "small",
                      },
                      model: {
                        value: _vm.searchForm.shopName,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "shopName", $$v)
                        },
                        expression: "searchForm.shopName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { size: "mini", placeholder: "请选择省份" },
                        on: { change: _vm.selectedHandle },
                        model: {
                          value: _vm.searchForm.province,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "province", $$v)
                          },
                          expression: "searchForm.province",
                        },
                      },
                      _vm._l(_vm.provinceData, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.rname, value: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { size: "mini", placeholder: "请选择地市区" },
                        on: { change: _vm.selectedHandleCity },
                        model: {
                          value: _vm.searchForm.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "city", $$v)
                          },
                          expression: "searchForm.city",
                        },
                      },
                      _vm._l(_vm.cityData, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.rname, value: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { size: "mini", placeholder: "请选择区县" },
                        on: { change: _vm.selectedHandleArea },
                        model: {
                          value: _vm.searchForm.district,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "district", $$v)
                          },
                          expression: "searchForm.district",
                        },
                      },
                      _vm._l(_vm.areaData, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.rname, value: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.exportUrl(1)
                          },
                        },
                      },
                      [_vm._v("导出")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.reset("searchForm")
                          },
                        },
                      },
                      [_vm._v("重置")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("reportTable", {
          attrs: {
            "table-data": _vm.tableData,
            index: _vm.index,
            selection: _vm.selection,
            page: _vm.page,
            showpage: true,
            operation: _vm.operation,
            "table-option": _vm.tableOption,
            expand: true,
          },
          on: {
            "update:tableOption": function ($event) {
              _vm.tableOption = $event
            },
            "update:table-option": function ($event) {
              _vm.tableOption = $event
            },
            "page-change": _vm.getList,
          },
          scopedSlots: _vm._u([
            {
              key: "goodsInfoList",
              fn: function (scope) {
                return _vm._l(scope.row.goodsInfoList, function (item, key) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass: "goods-info-list",
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "80px",
                            height: "80px",
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("el-image", {
                            staticStyle: { width: "80px" },
                            attrs: {
                              src: item.goodsImage || _vm.avater,
                              lazy: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "imageP",
                          staticStyle: { "line-height": "30px" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-weight": "600",
                                color: "#262626",
                              },
                            },
                            [_vm._v(" " + _vm._s(item.goodsName) + " ")]
                          ),
                          _c("p", [_vm._v(" " + _vm._s(item.goodsSpec) + " ")]),
                          _c("p", [
                            _vm._v(" " + _vm._s(item.goodsFactory) + " "),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "unit-list" }, [
                        _c("div", { staticClass: "li" }, [
                          _vm._v(
                            "¥ " + _vm._s(parseFloat(item.price).toFixed(2))
                          ),
                        ]),
                        _c("div", { staticClass: "li" }, [
                          _vm._v(" " + _vm._s(item.qty) + " "),
                        ]),
                        _c("div", { staticClass: "li" }, [
                          _vm._v(" " + _vm._s(item.unit) + " "),
                        ]),
                      ]),
                    ]
                  )
                })
              },
            },
            {
              key: "orderTime",
              fn: function (scope) {
                return [
                  _vm._v(" " + _vm._s(scope.row.orderTime.slice(0, 10)) + " "),
                ]
              },
            },
            {
              key: "orderStatus",
              fn: function (scope) {
                return _vm._l(scope.row.detailList, function (item, key) {
                  return _c("div", { key: key }, [
                    item.shopFlag == "医药公司"
                      ? _c("span", [
                          _vm._v(" " + _vm._s(item.orderStatus) + " "),
                        ])
                      : _vm._e(),
                  ])
                })
              },
            },
            {
              key: "purchaseAmount",
              fn: function (scope) {
                return [
                  _vm._v(
                    " ¥ " +
                      _vm._s(parseFloat(scope.row.purchaseAmount).toFixed(2)) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "expand",
              fn: function (scope) {
                return [
                  _c("myTable", {
                    attrs: {
                      "table-data": scope.row.detailList,
                      index: _vm.index,
                      selection: _vm.selection,
                      operation: false,
                      "table-option": _vm.tableInfoOption,
                      expand: false,
                    },
                    on: {
                      "update:tableOption": function ($event) {
                        _vm.tableInfoOption = $event
                      },
                      "update:table-option": function ($event) {
                        _vm.tableInfoOption = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "arrivalAmount",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " ¥ " +
                                  _vm._s(
                                    parseFloat(scope.row.arrivalAmount).toFixed(
                                      2
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _vm._v(" 订单/资金统计明细报表"),
      _c("span", [_vm._v("（统计到昨日的订单）")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }