<!--  -->
<template>
  <div class="classification">
    <div class="header">
      <span class="font">对账单</span>
      <el-form ref="formTool"
               :model="formTool"
               :inline="true"
               style="text-align:left;">
        <el-form-item prop="orderCode">
          <el-input v-model="formTool.orderCode"
                    style="width: 140px;"
                    placeholder="订单编号"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="deptName">
          <el-input v-model="formTool.deptName"
                    style="width: 140px;"
                    placeholder="运营分类"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="date">
          <el-date-picker v-model="formTool.date"
                          value-format="yyyy-MM-dd"
                          size="small"
                          type="daterange"
                          range-separator="-"
                          start-placeholder="订单创建"
                          end-placeholder="起止日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="keywords">
          <el-input v-model="formTool.keywords"
                    style="width: 140px;"
                    placeholder="客户ERP编码"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="channel">
          <el-select v-model="formTool.channel"
                     style="width: 140px;"
                     size="small"
                     placeholder="支付渠道"
                     clearable
                     >
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="status">
          <el-select v-model="formTool.status"
                     style="width: 140px;"
                     size="small"
                     placeholder="结算状态">
            <el-option v-for="item in optionsSecond"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     type="primary"
                     @click="clickSearch(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     @click="reset()">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     type="primary"
                     @click="exportExcel()">导出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="center"
         style="position:relative" v-loading="loading" element-loading-text="正在加载页面，请稍候！">
      <MyTable :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getList"
               @handleSelectionChange="handleSelectionChange">
        <template slot-scope="scope"
                  slot="province">
          <span>{{ scope.row.province + scope.row.city + scope.row.district}}</span>
        </template>

        <template slot-scope="scope"
                  slot="totalPrice">
          <span>{{ scope.row.totalPrice.toFixed(2)}}</span>
        </template>
        <template slot-scope="scope"
                  slot="freightPrice">
          <span>{{ scope.row.freightPrice.toFixed(2)}}</span>
        </template>
        <template slot-scope="scope"
                  slot="realityPrice">
          <span>{{ scope.row.realityPrice.toFixed(2)}}</span>
        </template>
        <template slot-scope="scope"
                  slot="platformSubsidyPrice">
          <span>{{ scope.row.platformSubsidyPrice.toFixed(2)}}</span>
        </template>
        <template slot-scope="scope"
                  slot="refundAmount">
          <span>{{ scope.row.refundAmount.toFixed(2)}}</span>
        </template>
        <template slot-scope="scope"
                  slot="platformServiceMoney">
          <span>{{ scope.row.platformServiceMoney.toFixed(2)}}</span>
        </template>
        <template slot-scope="scope"
                  slot="promotionMoney">
          <span>{{ scope.row.promotionMoney.toFixed(2)}}</span>
        </template>
        <template slot-scope="scope"
                  slot="settlementMoney">
          <span>{{ scope.row.settlementMoney.toFixed(2)}}</span>
        </template>
        <template slot-scope="scope"
                  slot="receivableMoney">
          <span>{{ scope.row.receivableMoney.toFixed(2)}}</span>
        </template>
        
        <template slot-scope="scope"
                  slot="status">
          <span>{{ scope.row.status == '1' ? '已结算': '未结算' }}</span>
        </template>
        <!-- orderCode -->
        <template slot-scope="scope"
                  slot="orderCode">
          <span @click="goToDetail(scope.row)" class="link-btn"> 
           {{ scope.row.orderCode}} 
          </span>
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>
import MyTable from '@/components/myTable'
import { drugenumeration } from '@/utils/enumeration'
import { pageBills, billsExport } from '@/api/productManage/wareProduct'
import avater from "/public/img/default.png"

export default {
  name: 'WareProduct',
  components: { MyTable },
  data () {
    return {
      options: [
        {
          value: '支付宝',
          label: '支付宝'
        },
        {
          value: '微信',
          label: '微信'
        },
        {
          value: '康贝',
          label: '康贝'
        },
        {
          value:'线下打款',
          label:'线下打款'
        },
        {
          value:'余额',
          label:'余额支付、余额组合支付'
        }
      ],
      optionsSecond: [
        {
          value: '1',
          label: '已结算'
        },
        {
          value: '0',
          label: '未结算'
        }
      ],
      avater: avater,
      formTool: {
        orderCode: '',
        keywords: '',
        date: [],
        channel: '',
        status: '',
        deptName: ''
      },
      selectionList: [],
      operation: false,
      selection: false,
      index: false,
      tableData: [],
      tableOption: [
        // { label: '商品信息', prop: 'image', slot: true, minWidth: '200px' }, // 这里表示自定义列
        // { label: '商品名称', prop: 'name' },
        // { label: '生产厂商', prop: 'factory' },
        { label: '订单创建时间', prop: 'createTime', width: '160px' },
        { label: '出入账时间', prop: 'actualTime', width: '160px' },
        { label: '订单编号', prop: 'orderCode', width: '160px' ,slot: true },
        { label: '运营分类', prop: 'deptName', width: '160px' },
        { label: '客户ERP编码', prop: 'erpCode', width: '120px' },
        { label: '客户名称', prop: 'payShopName', width: '220px' },
        { label: '客户所在区域', prop: 'province', width: '220px', slot: true },
        { label: '商品总额（元）', prop: 'totalPrice', width: '120px', slot: true },
        { label: '运费（元）', prop: 'freightPrice', width: '120px', slot: true },
        { label: '商家优惠金额（元）', prop: 'realityPrice', width: '140px', slot: true },
        { label: '平台补贴（元）', prop: 'platformSubsidyPrice', width: '120px', slot: true },
        { label: '退款金额（元）', prop: 'refundAmount', width: '120px', slot: true },
        { label: '技术服务费（元）', prop: 'platformServiceMoney', width: '140px', slot: true },
        { label: '推广服务费（元）', prop: 'promotionMoney', width: '140px', slot: true },
        { label: '应收金额（元）', prop: 'receivableMoney', width: '120px', slot: true },
        { label: '结算金额（元）', prop: 'settlementMoney', width: '120px', slot: true }, 
        { label: '支付渠道', prop: 'channel' },
        { label: '结算状态', prop: 'status', slot: true },
      ],
      loading: false,
      page: {
        total: 0,
        current: 1,
        size: 20,
        pageSizes: [10, 20, 30, 50]
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.pageBills()
  },
  mounted () {

  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    goToDetail(row){
      this.$router.push({path: '/fund/fund-reconciliationDetail', query: { id: row.id }})
    },
    // 导出
    exportExcel () {
      const params = {
        orderCode: this.formTool.orderCode,
        keywords: this.formTool.keywords,
        startTime: this.formTool.date?.length > 0 ? this.formTool.date[0] + ' 00:00:00' : '',
        endTime: this.formTool.date?.length > 0 ? this.formTool.date[1] + ' 00:00:00' : '',
        channel: this.formTool.channel,
        status: this.formTool.status,
        deptName: this.formTool.deptName
      }
      billsExport(params).then(res => {
        console.log(res, 'res')
      })
    },
    // 重置查询条件
    reset () {
      this.$refs.formTool.resetFields()
    },
    // 切换分页
    getList () {
      this.pageBills()
    },
    // 勾选
    handleSelectionChange (val) {
      this.selectionList = val
    },
    // 查询出售中的商品
    pageBills (current) {
      if (current) { // 搜索条件改变从第一页开始查询
        this.page.current = current
      }
      this.loading = true;
      const params = {
        orderCode: this.formTool.orderCode,
        keywords: this.formTool.keywords,
        startTime: this.formTool.date?.length > 0 ? this.formTool.date[0] + ' 00:00:00' : '',
        endTime: this.formTool.date?.length > 0 ? this.formTool.date[1] + ' 00:00:00' : '',
        channel: this.formTool.channel,
        status: this.formTool.status,
        current: this.page.current,
        size: this.page.size,
        deptName: this.formTool.deptName
      }
      pageBills(params).then(res => {
        if (res) {
          this.tableData = res.records
          this.page.total = res.total;
           this.loading = false;

        } else {
          this.tableData = []
          this.page.total = 0;
          this.loading = false;
        }
      }).catch((err)=>{this.loading = false;})
    },
    // 搜索
    clickSearch (current) {
      this.pageBills(current)
    }
  }
}
</script>
<style lang='scss' scoped>
.classification {
  padding: 0px;
}
.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px !important;
  border-bottom: 1px solid #f5f5f5;
  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }
  .el-form-item {
    margin: 0px 0px 0px 8px !important;
  }
}
.center {
  margin-top: 16px;
  padding: 0 24px 0 24px;
  height: calc(100% - 131px);
  ::v-deep .imageP {
    width: calc(100% - 110px);
    p {
      margin: 0 0 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .shangjia {
    position: absolute;
    z-index: 2;
    bottom: -45px;
  }
}
.link-btn {
  color: #f5222d;
  cursor: pointer;
}
</style>
