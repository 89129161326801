<template>
  <!-- 添加或修改菜单对话框 -->
  <el-dialog :title="type == 'add' || type == 'product' ? '新增' : '编辑'"
             :visible.sync="visible"
             :before-close="cancle"
             :close-on-click-modal="false">
    <el-form ref="dataForm"
             :model="form"
             :rules="rules"
             label-width="100px">
      <el-form-item label="登录用户名"
                    prop="username"
                    v-if="type != 'product'">
        <el-input v-model="form.username"
                  placeholder="请输入登录用户名"
                  :disabled="type == 'see' || type == 'edit'" />
      </el-form-item>
      <el-form-item label="用户姓名"
                    prop="fullName">
        <el-input v-model="form.fullName"
                  placeholder="请输入用户姓名"
                  :disabled="type == 'see'" />
      </el-form-item>
      <el-form-item label="联系方式"
                    prop="phone">
        <el-input v-model="form.phone"
                  placeholder="请输入联系方式"
                  maxlength="11"
                  :disabled="type == 'see'" />
      </el-form-item>
      <el-form-item label="用户角色"
                    prop="roleIdSet">
        <el-select v-model="form.roleIdSet"
                   style="width:100%"
                   multiple
                   placeholder="请选择用户角色">
          <el-option v-for="item in options"
                     :key="item.roleId"
                     :label="item.roleName"
                     :value="item.roleId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="密码"
                    prop="password"
                    v-if="type == 'product'">
        <el-input v-model="form.password"
                  type="password"
                  placeholder="请输入密码" />
      </el-form-item>
      <!-- <el-form-item v-if="type == 'see'" label="创建时间" prop="">
        <el-input v-model="form.createTime" disabled />
      </el-form-item> -->
    </el-form>
    <div v-if="type != 'see'"
         slot="footer"
         class="dialog-footer">
      <el-button @click="cancle"
                 size="small">取 消</el-button>
      <el-button type="primary"
                 :loading="loading"
                 size="small"
                 @click="dataFormSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  addShopObj,
  delObj,
  fetchRoleTree,
  permissionUpd,
  putObj,
  manage,
} from "@/api/systemUser";
import { fetchList, getShopRolePage } from '@/api/systemRole'
import { manageAddUser } from "@/api/saleRule/productLine";
import { encryption } from '@/utils/index'
// import { addShopObj, fetchMenuTree, getObj, putObj } from '@/api/system'
// import Treeselect from '@riophae/vue-treeselect'
// import iconList from '@/const/iconList'
// import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "MenuForm",
  // components: { Treeselect },
  data () {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      loading: false,
      type: "add",
      // 是否显示弹出层
      visible: false,
      form: {
        roleIdSet: [],
        fullName: "",
        username: "",
        phone: "",
        userId: "",
        password: "",
      },
      // 表单校验
      rules: {
        fullName: [
          { required: true, message: "用户姓名不能为空", trigger: "blur" },
        ],
        username: [
          { required: true, message: "登录用户名不能为空", trigger: "blur" },
        ],
        phone: [{ required: true, message: "联系方式不能为空", trigger: "blur" }],
        roleIdSet: [
          { required: true, message: "用户角色不能为空", trigger: "change" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created () {
    this.getList()
  },
  methods: {
    init (info, type) {
      this.form = {
        roleIdSet: [],
        fullName: "",
        username: "",
        phone: "",
      };
      this.type = type;
      if (type != "add") {
        console.log(info, 'inf')
        const { phone, username, fullName, userId, roleIdSet } = info;
        this.form = { phone, username, fullName, userId, roleIdSet };
      } else {
        this.form = { fullName: "", username: "", roleIdSet: [], phone: "" };
      }
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate()
      })
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.loading = true
          const { phone, username, fullName, userId, password, roleIdSet } = this.form;
          const data = { phone, username, fullName, userId, roleIdSet };
          if (this.type == "edit") {
            manage(data).then((res) => {
              const { code, data, msg } = res;
              if (code == 0 && data) {
                this.$message.success("修改成功");
              } else {
                this.$message.error("修改用户失败-" + msg);
              }
              this.visible = false;
              setTimeout(() => {
                this.loading = false
              }, 300)
              this.$emit("sure");
            }).catch(() => {
              this.loading = false
            });
          } else if (this.type == "product") {
            let tempData = { phone, password, fullName };
            const questData = encryption({ data: tempData, key: '8vkv0#e^D@mjv%VS', param: ['password'] })
            manageAddUser(questData).then((res) => {
              const { code, data, msg } = res;
              if (code == 0 && data) {
                this.$message.success("添加成功");
              } else {
                this.$message.error("添加用户失败-" + msg);
              }
              this.visible = false;
              setTimeout(() => {
                this.loading = false
              }, 300)
              // this.$emit("sure");
            }).catch(() => {
              this.loading = false
            });
          } else {
            data.userType = 0;  //用户类型 0-普通用户 1-部门用户
            addShopObj(data).then((res) => {
              const { code, data, msg } = res;
              if (code == 0 && data) {
                this.$message.success("添加成功");
              } else {
                this.$message.error("添加用户失败-" + msg);
              }
              this.visible = false;
              setTimeout(() => {
                this.loading = false
              }, 300)
              this.$emit("sure");
            }).catch(() => {
              this.loading = false
            });
          }

          // return
          // if (roleId) {
          //   data.roleId = roleId
          //   putObj(data).then(() => {
          //     this.$message.success('修改成功')
          //     this.visible = false
          //     this.$emit('sure')
          //   })
          // } else {
          //   addShopObj(data).then(() => {
          //     this.$message.success('添加成功')
          //     this.visible = false
          //     this.$emit('sure')
          //   })
          // }
        }
      });
    },
    cancle () {
      this.$refs.dataForm.resetFields()
      this.visible = false
    },
    getList () {
      let params = {
        current: 1,
        size: 100
      }
      this.listLoading = true
      getShopRolePage(params).then((res) => {
        this.options = res.data.records
        // this.page.total = res.data.total
        // this.listLoading = false
      }).catch(() => {
        // this.listLoading = false
      })
    },
  },
};
</script>
