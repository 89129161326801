var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v(
          " 每个商家最多添加3个商家标签。商家标签图片会盖在商品列表图上一同显示(图层要求 （比例1：1，420x420px， 50kb以下,图片格式必须上传png）) "
        ),
      ]),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, "row-key": "key" } },
            [
              _c("el-table-column", {
                attrs: { label: "标签", type: "index", width: "50" },
              }),
              _c("el-table-column", {
                attrs: { label: "标签名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "table-content" }, [
                          _vm._v(" " + _vm._s(scope.row.labelName) + " "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "图层" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "table-content" },
                          [
                            [
                              !scope.row.image
                                ? _c("div", { staticClass: "upload-image" }, [
                                    _c(
                                      "div",
                                      { staticClass: "image-wrap" },
                                      [
                                        _c("el-image", {
                                          attrs: { src: _vm.AddIcon },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _c(
                                    "div",
                                    { staticClass: "table-cell-pic-wrap" },
                                    [
                                      _c("el-image", {
                                        ref: `ref${scope.$index}`,
                                        staticClass: "table-cell-pic",
                                        attrs: {
                                          src: scope.row.image,
                                          "initial-index": 0,
                                          "preview-src-list": [scope.row.image],
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "preview" },
                                        [
                                          _c("el-image", {
                                            staticClass: "btn-icon",
                                            attrs: { src: _vm.SeeIcon },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handlePreview(
                                                  `ref${scope.$index}`
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "编辑时间", prop: "updateTime" },
              }),
              _c("el-table-column", {
                attrs: { label: "审核状态", prop: "checkStatus" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.showState(scope.$index))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "备注", prop: "remark" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "table-content" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.$index)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("UploadImageView", {
        ref: "imgupload",
        attrs: { "request-id": "1" },
        on: { upload: _vm.uploadSubmit },
      }),
      _c("LabelManagerDialog", {
        ref: "labelManagerDialog",
        on: { refresh: _vm.getLabelInfo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }