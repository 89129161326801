import request from '@/utils/request'

// 获取树形结构
export function getInformationClassifyTree() {
    return request({
        url: `/message/information/classify/getInformationClassifyTree/${'商户端'}`,
        method: "get",
        
    });
}

// 删除资讯分类
export function deleteClassify(id) {
  return request({
      url: `/message/information/classify/${id}`,
      method: "DELETE",
      
  });
}

// 根据父级id获取子级信息,如果查询父级别则传0
export function queryInformationClassifyByParentId(id) {
  return request({
      url: `/message/information/classify/queryInformationClassifyByParentId/${id}/${'商户端'}`,
      method: "get",
  });
}

// 新增资讯分类
export function addClassify(data) {
  return request({
      url: `/message/information/classify`,
      method: "post",
      data
  });
}

// 修改资讯分类
export function editClassify(data) {
  return request({
      url: `/message/information/classify`,
      method: "put",
      data
  });
}

// 查询资讯文章列表
export function queryInformationArticleList(data) {
  return request({
      url: `/message/information/article/queryInformationArticleList`,
      method: "post",
      data
  });
}

// 修改资讯文章置顶状态
export function updatePinnedStatus(data) {
  return request({
      url: `/message/information/article/updatePinnedStatus`,
      method: "put",
      data
  });
}

// 修改资讯文章状态
export function updateStatus(data) {
  return request({
      url: `/message/information/article/updateStatus`,
      method: "put",
      data
  });
}

// 删除资讯文章
export function articleDelete(id) {
  return request({
      url: `/message/information/article/${id}`,
      method: "DELETE"
  });
}

// 新增资讯文章
export function article(data) {
  return request({
      url: `/message/information/article`,
      method: "POST",
      data
  });
}

// 根据id查询资讯文章
export function queryInformationArticleById(id) {
  return request({
      url: `/message/information/article/queryInformationArticleById/${id}/${'商户端'}`,
      method: "GET"
  });
}

// 编辑资讯文章
export function editArticle(data) {
  return request({
      url: `/message/information/article`,
      method: "put",
      data
  });
}
// 根据分类id查询资讯文章列表
export function queryInformationArticleByClassificationIdList(data) {
  return request({
      url: `/message/information/article/queryInformationArticleByClassificationIdList`,
      method: "post",
      data
  });
}

// 文章全部已读
export function articleAllRead() {
  return request({
      url: `/message/information/article/articleAllRead/${'商户端'}`,
      method: "put"
  });
}
// 根据clientTag获取父级信息和父级阅读信息
export function queryParentUnreadMsg() {
  return request({
      url: `/message/information/classify/queryParentUnreadMsg/${'商户端'}`,
      method: "GET"
  });
}

// 根据分类id查询资讯文章列表
export function queryHomePageInformationArticleList(data) {
  return request({
      url: `/message/information/article/queryHomePageInformationArticleList`,
      method: "post",
      data
  });
}