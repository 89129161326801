import request from "@/utils/request";
import { Message } from 'element-ui'


// cso合作品销售报表-分页查询
export function csoSaleReportPage(data) {
  return request({
    url: "/statistic/csoSaleReport/findPage",
    method: "POST",
    data
  });
}

// cso合作品销售报表-导出excel
export function csoSaleReportExport(data) {
  return request({
    url: `/statistic/csoSaleReport/exportExcel`,
    method: "get",
    params: data,
    responseType: 'blob'
  }).then(response => {
    // console.log(response,'res')
    // 处理返回的文件流
    const blob = response.data
    if ((blob && blob.size === 0) || response.size == 0) {
      Message.warning('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    const contentDisposition = response.headers['content-disposition'];
    const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1));
    link.download = '合作品销售报表数据导出'+ fileName;
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  });
}
