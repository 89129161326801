<template>
  <div class="goodsContainer">
    <div class="topView">
      <el-form ref="formTool" :model="formTool" size="small" :inline="true">
        <el-form-item label="" label-width="120px">
          <el-input v-model="formTool.name" placeholder="ERP编码/名称" />
        </el-form-item>
        <el-form-item label="" label-width="120px">
          <el-input v-model="formTool.factory" placeholder="生产厂家" />
        </el-form-item>
        <el-form-item label="" label-width="120px">
          <el-input v-model="formTool.branch" placeholder="批准文号" />
        </el-form-item>
        <div style="display: flex">
          <el-radio-group
            v-model="tabPosition"
            size="mini"
            @input="monthYearChange"
          >
            <el-radio-button label="week">本周</el-radio-button>
            <el-radio-button label="month">本月</el-radio-button>
            <el-radio-button label="year">本年</el-radio-button>
          </el-radio-group>
          <el-form-item label="">
            <el-date-picker
              v-model="formTool.date"
              value-format="yyyy-MM-dd"
              type="daterange"
              size="small"
              :picker-options="pickerOptions"
              range-separator="-"
              :clearable="false"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              style="width: 300px"
            />
          </el-form-item>
          <div class="btn-box">
            <el-button type="primary" size="small" @click="getList(1)"
              >查 询</el-button
            >
            <el-button size="small" @click="handleReset">重 置</el-button>
            <el-button size="small" style="float: right" @click="handleExport"
              >导出</el-button
            >
          </div>
        </div>
      </el-form>
      <div
        class="center"
        :style="contentHeight ? `height:${contentHeight}px!important` : ''"
      >
        <MyTable
          :table-data="tableData"
          :index="index"
          :selection="selection"
          :page="page"
          :operation="operation"
          :table-option.sync="tableOption"
          @page-change="handlePageChange"
          @sort-change="sortChange"
        >
          <template slot="goods_info" slot-scope="scope">
            <div>
              <div>商品名称：{{ scope.row.goods_name }}</div>
              <div>厂家：{{ scope.row.goods_factory }}</div>
              <div>ERP编码：{{ scope.row.erp_id }}</div>
              <div>批准文号：{{ scope.row.approval_number }}</div>
              <div>规格：{{ scope.row.goods_specification }}</div>
            </div>
          </template>
          <template slot="status" slot-scope="scope">
            <el-link
              :underline="false"
              style="margin-left: 16px"
              type="primary"
              class="btn"
              @click="handleDetail(scope.row)"
              >查看详情</el-link
            >
          </template>
        </MyTable>
      </div>
    </div>
    <GoodsDetailDlg ref="goodsDetail" />
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import GoodsDetailDlg from "./dialog/goodsDetail";
import { queryGoodsSalesEcharts, excelExport } from "@/api/statistics";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
export default {
  components: { MyTable, GoodsDetailDlg },
  data() {
    return {
      operation: false,
      selection: false,
      index: false,
      contentHeight: 0,
      formTool: {
        name: "",
        factory: "",
        branch: "",
        date: [],
      },
      tableData: [],
      tabPosition: "year",
      tableOption: [
        { label: "商品信息", prop: "goods_info", width: "250px", slot: true },
        // { label: "ERP编码", prop: "erp_id" },
        // { label: "商品名称", prop: "goods_name" },
        // { label: "厂家", prop: "goods_factory" },
        // { label: "规格", prop: "goods_specification" },
        // { label: "批准文号", prop: "approval_number" },
        { label: "库存", prop: "goods_sku_inventory" },
        {
          label: "销量",
          prop: "qty",
          sortable: "custom",
          customHeader: "true",
          content: "支付成功订单销量合计",
        },
        {
          label: "销售额",
          prop: "amount",
          sortable: "custom",
          customHeader: "true",
          content: "支付成功订单销售额合计",
        },
        { label: "实付总金额", prop: "paid_amount", width: "100px" },
        {
          label: "点击量",
          prop: "click_cnt",
          sortable: "custom",
          customHeader: "true",
          content: "该商品各SKU被点击的次数合计",
        },
        {
          label: "访客量",
          prop: "shop_visit_cnt",
          sortable: "custom",
          customHeader: "true",
          content: "访问该商品各SKU的客户数合计",
        },
        // {
        //   label: "加购次数",
        //   prop: "add_cart_cnt",
        //   sortable: "custom",
        //   customHeader: "true",
        //   content: "该商品各SKU合计被客户加入购物车的次数",
        //   width: "120px",
        // },
        // {
        //   label: "加购客户数",
        //   prop: "add_cart_shop_cnt",
        //   sortable: "custom",
        //   customHeader: "true",
        //   content: "该商品各SKU的加购客户数合计",
        //   width: "130px",
        // },
        {
          label: "采购客户数",
          prop: "order_shop_cnt",
          sortable: "custom",
          customHeader: "true",
          content: "该商品各SKU支付成功的客户数合计",
        },
        {
          label: "采购订单数",
          prop: "order_cnt",
          sortable: "custom",
          customHeader: "true",
          content: "下单该商品并支付成功的订单数",
        },
        // {
        //   label: "收藏数",
        //   prop: "add_favorite_cnt",
        //   customHeader: "true",
        //   content: "该商品的各SKU合计被客户收藏的次数",
        //   sortable: "custom",
        //   width: "110px",
        // },
        { label: "操作", prop: "status", slot: true },
      ],
      page: {
        total: 0,
        current: 1,
        size: 30,
        pageSizes: [30, 50, 100, 500],
      },
      type: 70,
      order: "2",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        },
      },
    };
  },
  computed: {
    shopId() {
      return this.$store.state.user.user_info.shopId;
    },
  },
  created() {
    this.monthYearChange(this.tabPosition);
    this.getList();
  },
  updated() {
    this.getWindowView();
  },
  methods: {
    getWindowView() {
      let innerHeight = window.innerHeight; //获取页面高度
      let headHeight = 60;
      headHeight = this.$refs["formTool"].$el.clientHeight; //获取子页面head 高度
      let contentHeight = innerHeight - 238 - headHeight;
      this.contentHeight = contentHeight;
    },
    monthYearChange(val) {
      const now = new Date(); //当前日期
      const nowDayOfWeek = now.getDay(); //; //今天本周的第几天 周一 至 周天
      const nowDay = now.getDate(); //当前日
      const nowMonth = now.getMonth(); //当前月
      const nowYear = now.getFullYear(); //当前年
      this.formTool.date = [];
      let startDate = "";
      let lastDate = "";
      if (val === "week") {
        // 过去 7 天 不包含今天
        // startDate = dayjs().subtract(7, "day").format("YYYY-MM-DD");
        // lastDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        // 获取从本周 周一到周天的日期
        dayjs.locale("zh-cn");
        // 开始日期一定是周一，结束日期不超过今天
        const mondayInWeek = dayjs().startOf("week").format("YYYY-MM-DD");
        const todayInWeek = dayjs().format("YYYY-MM-DD");
        startDate = mondayInWeek;
        lastDate = todayInWeek;
      } else if (val === "month") {
        startDate = this.formatDate(new Date(nowYear, nowMonth, 1));
        lastDate = this.formatDate(new Date(nowYear, nowMonth, nowDay));
      } else {
        startDate = this.formatDate(new Date(nowYear, 0, 1));
        lastDate = this.formatDate(new Date(nowYear, nowMonth, nowDay));
      }
      if (startDate !== lastDate) {
        lastDate = this.formatDate(new Date(nowYear, nowMonth, nowDay));
      }
      this.formTool.date.push(startDate);
      this.formTool.date.push(lastDate);
    },
    //格式化日期：yyyy-MM-dd
    formatDate(date) {
      const myyear = date.getFullYear();
      let mymonth = date.getMonth() + 1;
      let myweekday = date.getDate();
      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      return myyear + "-" + mymonth + "-" + myweekday;
    },
    //获得某月的天数
    getMonthDays(myMonth) {
      const nowYear = new Date().getYear(); //当前年
      const monthStartDate = new Date(nowYear, myMonth, 1);
      const monthEndDate = new Date(nowYear, myMonth + 1, 1);
      const days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
      return days;
    },
    handleReset() {
      this.formTool = {
        name: "",
        factory: "",
        branch: "",
        date: [],
      };
      this.tabPosition = "year";
      this.monthYearChange(this.tabPosition);
    },
    handleExport() {
      const list = this.getParam();
      list.pop();
      list.pop();
      list.pop();
      const param = {
        type: 72,
        params: list,
      };
      excelExport(param).then((res) => {});
    },
    handlePageChange() {
      this.getList();
    },
    // 表格排序
    sortChange(val) {
      this.order = this.getOrder(val.prop);
      if (val.order == "ascending") {
        this.type = 69;
      } else {
        this.type = 70;
      }
      this.getList(1);
    },
    // 根据选择的字段获取请求参数
    getOrder(val) {
      let getValue = "2";
      switch (val) {
        case "qty":
          getValue = "1";
          break;
        case "amount":
          getValue = "2";
          break;
        case "click_cnt":
          getValue = "3";
          break;
        case "shop_visit_cnt":
          getValue = "4";
          break;
        case "add_cart_cnt":
          getValue = "5";
          break;
        case "add_cart_shop_cnt":
          getValue = "6";
          break;
        case "order_shop_cnt":
          getValue = "7";
          break;
        case "order_cnt":
          getValue = "8";
          break;
        case "add_favorite_cnt":
          getValue = "9";
          break;
        default:
          getValue = "2";
          break;
      }
      return getValue;
    },
    getList(current) {
      if (current) {
        this.page.current = current;
      }

      let list = this.getParam();
      this.getTotal(list);
      this.getTable(list);
    },
    // 获取请求参数
    getParam() {
      // [开始时间,结束时间,商户Id,
      // 开始时间,结束时间,
      // ERP编码/名称,ERP编码/名称,
      // 厂家,厂家,
      // 批准文号,批准文号,
      // 商户ID,排序字段,每页行数,第几行开始]
      console.log("this.type", this.type);
      const date1 = this.correctData(this.formTool.date[0]).replace(/-/g, "");
      const date2 = this.correctData(this.formTool.date[1]).replace(/-/g, "");
      let params = [
        date1,
        date2,
        this.correctData(this.shopId),
        date1,
        date2,
        this.correctData(this.formTool.name),
        this.correctData(this.formTool.name),
        this.correctData(this.formTool.factory),
        this.correctData(this.formTool.factory),
        this.correctData(this.formTool.branch),
        this.correctData(this.formTool.branch),
        this.correctData(this.shopId),
        this.correctData(this.order),
        this.page.size,
        (this.page.current - 1) * this.page.size,
      ];
      if (
        this.type === 69 ||
        this.type === 70 ||
        this.type === 71 ||
        this.type === 72 ||
        this.type === 73 ||
        this.type === 74 ||
        this.type === 75 ||
        this.type === 76
      ) {
        params.unshift(date2);
        params.unshift(date1);
      }

      return params;
    },
    // 格式化数据
    correctData(data) {
      return data || "";
    },
    // 请求表格数据升序/降序
    getTable(list) {
      const param = {
        params: list,
        type: this.type,
      };
      queryGoodsSalesEcharts(param).then((res) => {
        if (res) {
          this.tableData = res;
        }
        //  else {
        //   this.tableData = [];
        // }
      });
    },
    // 请求总数
    getTotal(list) {
      const param = {
        params: list,
        type: 71,
      };
      queryGoodsSalesEcharts(param).then((res) => {
        if (res) {
          this.page.total = Number(res[0].cnt);
        } else {
          this.page.total = 0;
        }
      });
    },
    handleDetail(row) {
      const param = {
        date: this.formTool.date,
        tabPosition: this.tabPosition,
      };
      this.$refs.goodsDetail.init(row, param);
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsContainer {
  height: 100%;
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;
  ::v-deep .el-form-item {
    margin-bottom: 16px !important;
  }
  ::v-deep .el-radio-group {
    margin-right: 10px;
  }
  ::v-deep .el-radio-button--mini .el-radio-button__inner {
    padding: 9px 15px;
  }
  .center {
    border-top: 1px solid #f2f2f2;
    padding-top: 12px;
  }
  .btn-box {
    margin-left: 8px;
    flex: 1;
  }
}
</style>
