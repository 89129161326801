
<!--  -->
<template>
  <div class="comContent contentInner">
    <div class="titleInner">
      <MyTabs class="search"
              :tabi="tabsIndex"
              keyname="val"
              :tabs="tabsData"
              @change="tabChange" />
      <div class="title">
        <el-form ref="formTool"
                 :model="formTool"
                 :inline="true"
                 style="text-align:left;">
          <el-form-item prop="name">
            <el-input v-model="formTool.name"
                      placeholder="请输入商品名称"
                      size="small" />
          </el-form-item>
          <el-form-item prop="factory">
            <el-input v-model="formTool.factory"
                      placeholder="请输入生产厂家"
                      size="small" />
          </el-form-item>
          <el-form-item>
            <MyButton type="primary"
                      text="查询"
                      @click="getList()" />
          </el-form-item>
          <el-form-item>
            <MyButton type=""
                      text="重置"
                      @click="reset()" />
          </el-form-item>
          <el-form-item>
            <!-- <MyButton type="primary"
                      text="敏感词库"
                      @click="sensitiveLexicon()" /> -->
          </el-form-item>
        </el-form>
      </div>

    </div>
    <div class="center">
      <MyTable :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getList">
        <template slot="image"
                  slot-scope="scope">
          <el-image :src="scope.row.image"
                    style="width: 80px; height: 80px" />
        </template>
        <template slot="status"
                  slot-scope="scope">
          <MyTypes :type="scope.row.status" />
        </template>
        <template slot="menu"
                  slot-scope="scope">
          <!-- <el-button type="text" size="small" @click="editDetail(scope.row)">审核</el-button> -->
          <el-link :underline="false"
                   type="primary"
                   :disabled="tabsIndex!= 'AWAIT'"
                   @click="editDetail(scope.row)">审核</el-link>
        </template>
      </MyTable>
    </div>
    <el-dialog title="敏感词库"
               :visible.sync="dialogVisible"
               class="dialogBox"
               width="1000px">

      <div>
        <div>
          <span style="font-size:18px;margin-right:10px">敏感词库</span>
          <span>商品标题、商品描述中含有敏感词的商品会被自动驳回</span>
        </div>
        <div class="bigBox">
          <div class="everyOne"
               style="padding-right:10px;border-right:1px solid #ccc">
            <div>
              <el-input v-model="input"
                        size="small"
                        clearable
                        style="width:200px"
                        placeholder="请输入内容"></el-input>
              <el-button size="small"
                         type="primary"
                         style="margin-left:8px"
                         @click="search(1)">搜索</el-button>
              <el-button size="small"
                         type=""
                         @click="deleteScopes">删除</el-button>
            </div>
            <div style="height:calc(100% - 70px)">
              <MyTable :table-data="tableDataDialog"
                       :index="indexDialog"
                       :selection="selectionDialog"
                       :page="pageDialog"
                       :operation="operationDialog"
                       :table-option.sync="tableOptionDialog"
                       @page-change="getListDialog"
                       @handleSelectionChange="handleSelectionChange">
                <template slot="caozuo"
                          slot-scope="scope">
                  <!-- <el-button type="text" size="small" @click="editDetail(scope.row)">审核</el-button> -->
                  <el-link :underline="false"
                           type="primary"
                           @click="deleteScope(1,scope.row)">移除</el-link>
                </template>
              </MyTable>
            </div>
          </div>
          <div class="everyOne"
               style="margin-left:10px">
            <div style="height:100%;width:100%">
              <el-input type="textarea"
                        style="height:calc(100% - 75px)"
                        placeholder="例如：依赖性|上瘾"
                        v-model="textarea"
                        show-word-limit>
              </el-input>
              <p>注：输入格式为 敏感词|敏感词，敏感词间分隔符为“|”。</p>
              <div style="text-align:end">
                <el-button size="small"
                           @click="dialogVisible = false">关闭</el-button>
                <el-button size="small"
                           type="primary"
                           @click="inputSensitiveWords">录入</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable'
import MyTabs from '@/components/myTabs'
import MyTypes from '@/components/myTypes'
import { drugenumeration } from '@/utils/enumeration'
import { goodsCheckPages, sensitivethesaurusPage, sensitivethesaurus, sensitivethesaurusRemove } from '@/api/auditcenter'
export default {
  components: { MyButton, MyTable, MyTabs, MyTypes },
  data () {
    return {
      // dialog
      selectedList: [],
      textarea: '',
      input: '',
      tableDataDialog: [
      ],
      indexDialog: false,
      selectionDialog: true,
      pageDialog: {
        total: 10,
        current: 1,
        size: 10
      },
      operationDialog: false,
      tableOptionDialog: [
        { label: '商品信息', prop: 'sensitiveWord' },
        { label: '操作', prop: 'caozuo', slot: true, width: '80px' },
      ],

      dialogVisible: false, // 敏感词库的弹窗
      text: '',
      clearable: true,
      // tabsIndex: 0,
      // tabsData: [{ name: '待审核', num: 0 }, { name: '审核通过', num: 0 }, { name: '驳回', num: 0 }],
      tabsData: [
        { name: '待审核', num: 0, val: 'AWAIT', numhide: false },
        { name: '审核通过', num: 0, val: 'ALREADY', numhide: true },
        { name: '驳回', num: 0, val: 'FAIL', numhide: true },
      ],
      tabsIndex: 'AWAIT',
      formTool: {
        name: '',
        factory: ''
      },
      operation: true,
      selection: false,
      index: false,
      tableData: [],
      tableOption: [
        // { label: '编号', prop: 'goodsId' },
        { label: '商品图', prop: 'image', slot: true }, // 这里表示自定义列
        { label: '商品信息', prop: 'name' },
        { label: '税务分类编码', prop: 'taxCode' },
        { label: '生产厂家', prop: 'factory' },
        { label: '批准文号', prop: 'approvalNumber' },
        { label: '申请时间', prop: 'createTime' },
        { label: '状态', prop: 'status', slot: true }
      ],
      page: {
        total: 10,
        current: 1,
        size: 10
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getList()
  },
  mounted () { },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    // 敏感词库弹框下面的方法
    // 敏感弹窗的分页
    getListDialog () {
      this.search()
    },
    // 搜索敏感词
    search (current) {
      if (current) {
        this.pageDialog.current = current
      }
      let params = {
        current: this.pageDialog.current,
        size: this.pageDialog.size,
        sensitiveWord: this.input,
      }
      sensitivethesaurusPage(params).then(res => {
        if (res.code == 0) {
          console.log(res)
          this.tableDataDialog = res.data.records
          this.pageDialog.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    // 批量删除敏感词 type = 2
    deleteScopes () {
      if (this.selectedList.length == 0) {
        this.$message.warning('请先选择数据')
      } else {
        this.deleteScope(2, this.selectedList)
      }
    },
    // 单条删除敏感词 type = 1
    deleteScope (type, row) {
      let params = []
      if (type == 1) {
        params.push(row.id)
      } else {
        row.forEach(item => {
          params.push(item.id)
        })
      }
      sensitivethesaurusRemove(params).then(res => {
        if (res.code == 0) {
          console.log(res)
          this.input = ''
          this.search(1)
          this.$message.success('删除成功')
        } else {
          this.$message.error(res.msg)
        }
      })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    // 录入敏感词
    inputSensitiveWords () {
      let params = {
        sensitiveWord: this.textarea
      }
      sensitivethesaurus(params).then(res => {
        if (res.code == 0) {
          console.log(res)
          this.$message.success('录入成功')
          this.textarea = ''
          this.search(1)
        } else {
          this.$message.error(res.msg)
        }
      })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    // 敏感词库
    sensitiveLexicon () {
      this.search(1)
      this.dialogVisible = true
    },
    tabChange (i) {
      // console.log(i)
      this.tabsIndex = i
      this.page.current = 1
      this.getList()
    },
    getList () {
      const { current, size } = this.page
      const params = { current, size, status: this.tabsIndex, ...this.formTool }
      goodsCheckPages(params).then(res => {
        // console.log(res)
        if (res.code == 0) {
          this.tableData = res.data.goodsListVos.records
          this.page.total = res.data.goodsListVos.total
          if (this.tabsIndex == 'AWAIT') {
            this.tabsData[0].num = res.data.sumQty
          }
        }
      })
    },
    reset () {
      // this.formTool.storeName = ''
      this.$refs.formTool.resetFields()
      this.page.current = 1
      this.getList()
    },
    editDetail (row) {
      this.$router.push({ path: '/audit/auditcenter-auditgoodsdetail', query: { goodsId: row.goodsId } })
    },
    handleSelectionChange (val) {
      console.log(val)
      this.selectedList = val
    }
  }
}
</script>
<style lang='scss' scoped>
.contentInner {
  padding: 0px;

  .dialogBox {
    .el-dialog__header {
      display: none;
    }
    .bigBox {
      padding-top: 20px;
      display: flex;
      height: 600px;
      .everyOne {
        width: calc(100% - 30px);
        // padding-bottom: 20px;
        ::v-deep .el-textarea__inner {
          height: 100%;
        }
      }
    }
  }
}
.comContent {
  .titleInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 0px 24px;
    .title {
      display: flex;
      justify-content: right;
      align-items: center;
      .el-form--inline > .el-form-item {
        margin-bottom: 0px !important;
      }
    }
    .search {
      align-items: center;
    }
  }
}
.center {
  margin-top: 16px;
  padding: 0px 24px;
  height: calc(100% - 136px);
}
.sh {
  &_type {
    position: relative;
    color: #666;
    &:before {
      content: "";
      display: inline-block;
      margin-right: 5px;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      // vertical-align: middle;
      background: #f99b0d;
    }
    &.success {
      &:before {
        background: #F5222D;
      }
    }
    &.err {
      &:before {
        background: #ff4d4f;
      }
    }
  }
}
</style>
