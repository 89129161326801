import Vue from "vue";

Vue.directive('positive-integer', {
    bind: function (el, binding, vnode) { 
        const regex =/^[1-9]\d*$/u; //允许正整数 
        el.addEventListener('input', function (e) {
            let inputValue = e.target.value;
            let filteredValue = inputValue.replace(/[\u4e00-\u9fa5]/g, ''); // 使用正则表达式过滤中文字符  
            // 使用正则表达式限制输入为正整数  
            if (!regex.test(inputValue)) {
                // 如果输入的不是正整数，则删除非法字符  
                // e.target.value = inputValue.slice(0, -1);
                e.target.value = filteredValue.slice(0, -1);  
            }
        });
    }
  });
  
  Vue.directive('positive-tow-money', {
    bind: function (el, binding, vnode) {  
        const regex = /^([1-9]\d*(\.\d{1,2})?)|0(\.\d{1,2})$/; // 匹配正数并保留两位小数  
  
        el.addEventListener('input', function (e) {  
            let inputValue = e.target.value;  
          
            // 添加过滤中文的需求  
            let filteredValue = inputValue.replace(/[\u4e00-\u9fa5]/g, ''); // 使用正则表达式过滤中文字符  
          
            // 使用正则表达式限制输入为正数并保留两位小数  
            if (!regex.test(filteredValue)) {  
                // 如果输入不满足条件，则删除非法字符  
                e.target.value = filteredValue.slice(0, -1);  
            } else {  
                // 如果输入是正数，且最多保留两位小数，更新元素的值  
                e.target.value = filteredValue;  
            }  
        });
    }
  });

  Vue.directive('positive-five-limit', {
    bind: function (el, binding, vnode) {  
        el.addEventListener('input', function (e) {  
            const regex = /^(\d{1,4}|\d{5})(\.\d{0,2})?$/;  // 保留最多5位正整数和两位小数  
            let inputValue = e.target.value;  
            // 添加过滤中文的需求  
            let filteredValue = inputValue.replace(/[\u4e00-\u9fa5]/g, ''); // 使用正则表达式过滤中文字符   
            if (!regex.test(filteredValue)) {  
                e.target.value = filteredValue.slice(0, filteredValue.length - 1);  
                if(!regex.test(e.target.value)){
                    e.target.value = ''; 
                }
              } else { 
                e.target.value = filteredValue;  
            }     
        });
    }
  });

    
  Vue.directive('positive-tow-float', {
    bind: function (el, binding, vnode) {  
        const regex = /^(-?[1-9]\d*(\.\d{1,2})?)|0(\.\d{1,2})$/; // 匹配正数、负数并保留两位小数  
  
        el.addEventListener('input', function (e) {  
            let inputValue = e.target.value;  
            let filteredValue = inputValue; // 过滤中文字符  
          
            if (!regex.test(filteredValue)) {  
                // 判断是否是小数点，如果不是则删除非法字符，如果是小数点则继续保留  
                if (filteredValue.slice(-1) !== '.') {  
                    e.target.value = filteredValue.slice(0, -1);  
                } else {  
                    e.target.value = filteredValue;  
                }  
            } else {  
                e.target.value = filteredValue;  
            }  
        });
    }
  });