<template>
  <div class="show-manage-warp">
    <div class="form">
      <el-form label-position="top" :rules="rules" :model="formIn" ref="freightHead">
        <div class="freight-head">
          <div>
            <el-form-item prop="deliveryAgeingId" label="发货时效" label-width="140px">
              <el-select v-model="formIn.deliveryAgeingId" placeholder="请选择" size="small" @change="handleChangeTime"
                style="width:240px" label-width="140px">
                <el-option v-for="item in indicatorListTime ? indicatorListTime : []" :key="item.id"
                  :label="item.instructions" :value="item.id">
                </el-option>
              </el-select>
              <div v-if="formIn.medalPicture">
                <span class="small-icon">
                  <el-image :src="formIn.medalPicture" class="icon"
                    style="width: 20px; height:20px;margin-right:10px; margin-top: 10px;" />
                </span>
                <span style="line-height: 30px;">{{ formIn.medalName }}</span>
              </div>
            </el-form-item>

            <el-form-item prop="deliveryAgeingClosingTimeId" label="请选择截单时间" label-width="140px" v-if="showHours">
              <el-select v-model="formIn.deliveryAgeingClosingTimeId" placeholder="请选择截单时间" size="small"
                label-width="140px" style="width:240px">
                <el-option v-for="item in indicatorClosinTime ? indicatorClosinTime : []" :key="item.id"
                  :label="item.instructions" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-button @click="saveFreight()" type="plain" size="small">保存</el-button>
          </div>
        </div>
        <freight ref="freightRef" :key="key" :formIn="formIn" @validateHead="validateHead"
          @queryShopExpressFees="shopExpressFees" @toNext="toNext" :boundMoney="boundMoney" />
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  queryShopDeliveryDistributionSettings,
  updateShopDeliveryDistributionSettings,
  getIndicatorInfo,
  shopExpressFees,
  getShipInfor,
  guaranteeDetail,
  shopInformationInfoNew
} from "@/api/shop";

import freight from "./components/freight.vue"

import {
  getProvince,
  getCity,
} from "@/api/shopmange/index";

import _ from "lodash";
import router from "@/router";
export default {
  components: { freight },
  inject: ['reloadShopAll'],
  data () {
    return {
      key: 1,
      isdisabled: false,
      indicatorList: [], //物流
      indicatorListTime: [], //发货 时效
      indicatorClosinTime: [], //截单时间
      provinceData: [], //省份的数组
      cityData: [], //市的数组
      rules: {
        deliveryAgeingId: [
          { required: true, message: "请选择发货时效", trigger: "blur" },
        ],
        deliveryAgeingClosingTimeId: [
          { required: true, message: "请选择截单时间", trigger: "blur" },
        ],
      },
      formIn: {
        deliveryAgeingId: "", //发货时效id
        deliveryAgeingName: "", //发货时效名称
        // deliveryLogisticsId: "", //发货物流id
        // deliveryLogisticsName: "", //发货物流名称 
        // sendProvince: "", //发货省份代码
        // sendProvinceName: "", //发货省份名称
        // sendCity: "", //发货城市代码
        // sendCityName: "", //发货城市名称
        // medalPicture: "", //勋章图片
        // medalName: "", //勋章名称
        shopId: "",
        deliveryAgeingClosingTimeId: "", //截单时间
        deliveryAgeingClosingTimeName: "", //截单时间名称
        weight: 0,
        freightList: [], //物流费用 
      },
      boundMoney: 0,//保证金
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    showHours () {
      return this.formIn.deliveryAgeingName == "极速发货";
    },
  },
  methods: {
    init () {
      this.key = Math.random();
      this.getIndicatorInfo();
      this.getIndicatorInfoValidate();
      this.getProvince();
      this.getIndicatorInfoCloseTime();
      this.shopExpressFees();
      this.getShopInfoAction()
    },
    getShopInfoAction () {
      shopInformationInfoNew().then((data) => {
        if (data && data.shopId) {
          this.getShopInfo(data.shopId)
        }
      });
    },
    async getShopInfo (shopId) {
      const res = await guaranteeDetail({ shopId })
      if (res && res.useAmount && res.useAmount > 0) {
        // 存在保证金余额 
        this.boundMoney = res.useAmount
      }
    },
    saveFreight () {
      if (this.showHours) {
        //截单时间
        if (this.formIn.deliveryAgeingClosingTimeId) {
          const findedItem = this.indicatorClosinTime.find(
            (item) => this.formIn.deliveryAgeingClosingTimeId === item.id
          );
          this.formIn.deliveryAgeingClosingTimeName = findedItem.instructions;
        } else {
          this.$message.warning("请选择截单时间");
          return;
        }
      }
      this.$refs['freightRef'].saveFreightFrist()
      this.$nextTick(() => {
        this.$emit('queryInfo');// 刷新店铺信息  
      })
      this.reloadShopAll(); // 刷新店铺信息,更新提示状态 
    },
    //  获取物流费用 
    shopExpressFees () {
      shopExpressFees().then((res) => {
        if (res) {
          let newFreightList = [];
          newFreightList = _.cloneDeep(res);
          if (newFreightList.length == 0) {
            return
          }
          newFreightList.map(item => {
            // console.log(item, '---item');
            item.areaList.map(area => {
              if (area.province) {
                area.id = area.province
              }
              if (area.provinceName) {
                area.rname = area.provinceName
              }
              if (area.city) {
                area.id = area.city
              }
              if (area.cityName) {
                area.rname = area.cityName
              }
            })
          })

          this.setDefualtArea(newFreightList);
          this.$refs['freightRef'].init(newFreightList);
          this.formIn.freightList = newFreightList;
          if (res[0]) {
            this.formIn.deliveryAgeingId = res[0].deliveryAgeingId;
            this.formIn.deliveryAgeingClosingTimeId = res[0].deliveryAgeingClosingTimeId;
          }
          if (this.formIn.deliveryAgeingId) {
            this.handleChangeTime(this.formIn.deliveryAgeingId)
          }
        }
      });
    },
    // 设置默认地区
    setDefualtArea (newFreightList) {
      for (let i = 0; i < newFreightList.length; i++) {
        let item = newFreightList[i];
        item.defaultArea = [];
        item.areaList.map(area => {
          if (area.province) {
            item.defaultArea.push(area.province)
          }
          if (area.city) {
            item.defaultArea.push(area.city)
          }
        })
      }
    },

    // 下一步
    goNext () {
      this.$refs['freightHead'].validate((valid) => {
        if (valid) {
          this.$refs['freightRef'].goNext();
        } else {
          return false;
        }
      });
    },
    toNext () {
      this.$emit('indexStepChange', 3)
    },
    validateHead () {
      this.$refs['freightHead'].validate((valid) => {
        if (valid) {
          return true;
        } else {
          return false;
        }
      });
    },
    // 获取物流
    getIndicatorInfo () {
      let type = "MATERIAL_FLOW";
      getIndicatorInfo(type).then((data) => {
        if (data instanceof Array) {
          this.indicatorList = data;
        }
      });
    },
    // 发货时效
    handleChangeTime (val) {
      if (val) {
        if (this.formIn.deliveryAgeingId) {
          const findedItem = this.indicatorListTime.find(
            (item) => this.formIn.deliveryAgeingId === item.id
          );
          this.formIn.deliveryAgeingName = findedItem.instructions;
          this.formIn.weight = findedItem && findedItem.competitivePower ? findedItem.competitivePower : 0;
          this.setMedaImage(findedItem)
        }
      }
    },
    // 设置勋章图片
    setMedaImage (findedItem) {
      this.formIn.medalPicture = findedItem && findedItem.medalPicture ? findedItem.medalPicture : "";
      this.formIn.medalName = findedItem && findedItem.medalName ? findedItem.medalName : "";
    },
    // 获取发货时效
    getIndicatorInfoValidate () {
      let type = "DELIVER_GOODS";
      getIndicatorInfo(type).then((data) => {
        if (data instanceof Array) {
          this.indicatorListTime = data;
        }
      });
    },
    // 获取截单时间
    getIndicatorInfoCloseTime () {
      let type = "CLOSING_TIME";
      getIndicatorInfo(type).then((data) => {
        if (data instanceof Array) {
          this.indicatorClosinTime = data;
        }
      });
    },
    // 获取省份
    getProvince () {
      getProvince().then((res) => {
        if (res.code == 0) {
          this.provinceData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },
    // 获取城市
    selectedHandle (val, noClear) {
      if (!noClear) {
        this.formIn.sendCity = "";
      } else {
        this.formIn.sendCity = val;
      }
      getCity({ id: val }).then((res) => {
        if (res.code == 0) {
          this.cityData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },

    handleChange (val) {
      if (val) {
        const findedItem = this.indicatorList.find((item) => item.id === val);
        this.setMedaImage(findedItem)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style/index.scss";

.freight-head {
  display: flex;
  justify-content: space-between;
}
</style>
