<template>
  <div class="filedsContainer">

      <div class="head">
          <div class="tabs">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane label="发票申请" name="first">
                  </el-tab-pane>
                  <el-tab-pane label="发票记录" name="second">
                  </el-tab-pane>
                  <el-tab-pane label="发票信息" name="third">
                  </el-tab-pane>
                
              </el-tabs>
          </div>
         
      </div> 
      <div class="body">
         <div class="chlidren">
          <invoiceApply v-if="activeName == 'first'" />
          <invoiceRecords v-if="activeName == 'second'" />
          <invoiceInfo v-if="activeName == 'third'" /> 
         </div>
      </div>
  </div>
</template>
<script>
import invoiceInfo from "./components/invoiceInfo.vue"; 
import invoiceRecords from "./components/invoiceRecords.vue"; 
import invoiceApply from "./components/invoiceApply.vue"; 
 

export default {
  name: "invoiceManage",
  components: { invoiceApply, invoiceRecords,invoiceInfo },
  watch: {
      modeValue: {
          immediate: true,
          handler: function (value) {
              console.log(value, "value");
          },
      },
  },
  data() {
      return {
          disabled: false, 
          qualified: 1,
          activeName: "first",
          type: "approve", // view
          status: null,
          roleId: null,
          relationType: null,
          shopTypeName: null,
          shopName: null,
          orgCode: null,
          deptId: null,
          remarks: "",
          infoDetailData: {},
          queryScopeListData: [],
      };
  },
  methods: {
      handleClick(tab, event) {
        this.$store.dispatch("tabStatus/setInvoiceManageTab",this.activeName)
        //   console.log(tab, event);
      },
      back() {
          this.$router.go(-1);
      },
      submit() {

      },
      // 初始化缓存，本地如无此shopId缓存，存入一条缓存。此缓存为数组。
      initSeesion(){
             // 检查状态: 0-合格，1-不合格
      let shopItem = {
          shopId: this.$route.query.shopId,
          afterSales:{
              qualifiedSelect:1, //检查状态
              remarks:'', //检查意见
              infoList:[] //检查lsit
          },
          credentials:{
              qualifiedSelect:1,
              remarks:'',
              infoList:[]
          },
          goods:{
              qualifiedSelect:1,
              remarks:'',
              infoList:[]
          },
          order:{
              qualifiedSelect:1,
              remarks:'',
              infoList:[]
          },
      }
        window.sessionStorage.setItem('checkShopItemSession',JSON.stringify(shopItem))
      }
  },
  mounted() {
    if(this.$store.state.tabStatus.invoiceManageTab){
        this.activeName = this.$store.state.tabStatus.invoiceManageTab;
    }
      let checkShopItemSession = window.sessionStorage.getItem('checkShopItemSession');
      if(!checkShopItemSession){ 
          this.initSeesion()
      }else if(checkShopItemSession && JSON.parse(checkShopItemSession).shopId != this.$route.query.shopId) {
          this.initSeesion()
      }

  },
};
</script>
<style lang="scss" scoped>
.filedsContainer {
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow-y: auto;

  .head {
      background: #ffffff;
      height: 76px;
      padding: 10px 20px 0px 20px;
      line-height: 28px;
      display: flex;
      justify-content: space-between;
      border-bottom: solid 1px #F2F2F2;
  }

  .tabs {
      height: 60px;
  }

  .font-tip {
      padding-left: 20px;
      font-size: 12px;
  }

  .body {
      background: #ffffff;
      padding: 20px 20px 10px;
      display: flex;
      flex: 1;
      overflow-y: auto;
  }

  .chlidren {
    width: 100%;
  }

  .footer {
      padding: 20px 0 0;
      background: #ffffff;
  }

  .check-cotent {
      margin-top: 10px;
      //  width: calc(100% - 200px);
  }
}
</style>
