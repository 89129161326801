var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-product-info-root" },
    [
      _c("div", { staticClass: "product-info" }, [
        _c(
          "div",
          { staticClass: "pic-frame" },
          [
            _c("el-image", {
              staticClass: "pic",
              attrs: { src: _vm.smallIcon },
            }),
          ],
          1
        ),
        _vm._m(0),
        _c("div", { staticClass: "flag" }, [_vm._v("一口价")]),
      ]),
      _c("div", { staticClass: "big-content" }, [
        _c("div", { staticClass: "harf-block" }, [
          _c("div", { staticClass: "big-block" }, [
            _vm._m(1),
            _c("div", { staticClass: "form-content" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("ERP编码")]),
                _c(
                  "div",
                  { staticClass: "value input" },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入" },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("价格")]),
                _c(
                  "div",
                  { staticClass: "value input" },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "value fill" },
                  [_c("el-checkbox", [_vm._v("自动同步价格")])],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("市场价")]),
                _c(
                  "div",
                  { staticClass: "value input" },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入" },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("建议零售价")]),
                _c(
                  "div",
                  { staticClass: "value input" },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入" },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("总库存")]),
                _c(
                  "div",
                  { staticClass: "value input" },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "value fill" },
                  [_c("el-checkbox", [_vm._v("自动同步库存")])],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("单位")]),
                _c(
                  "div",
                  { staticClass: "value input" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", size: "small" },
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("起批量")]),
                _c(
                  "div",
                  { staticClass: "value input" },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "value fill" },
                  [_c("el-checkbox", [_vm._v("倍数购买")])],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("有效期至")]),
                _c(
                  "div",
                  { staticClass: "value input" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        placeholder: "请选择",
                        size: "small",
                      },
                      model: {
                        value: _vm.value1,
                        callback: function ($$v) {
                          _vm.value1 = $$v
                        },
                        expression: "value1",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("生产日期")]),
                _c(
                  "div",
                  { staticClass: "value input" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        placeholder: "请选择",
                        size: "small",
                      },
                      model: {
                        value: _vm.value1,
                        callback: function ($$v) {
                          _vm.value1 = $$v
                        },
                        expression: "value1",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "big-block" }, [
            _vm._m(2),
            _c("div", { staticClass: "form-content" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("商品标题")]),
                _c(
                  "div",
                  { staticClass: "value input" },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入" },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("产品批号")]),
                _c(
                  "div",
                  { staticClass: "value input" },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入" },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row mutlti" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("商品图片")]),
                _c("div", { staticClass: "value mutlti v" }, [
                  _c(
                    "div",
                    { staticClass: "in-row" },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.radio,
                            callback: function ($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio",
                          },
                        },
                        [_vm._v("使用平台图片")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.radio,
                            callback: function ($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio",
                          },
                        },
                        [_vm._v("上传图片")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "in-row" }, [
                    _c(
                      "div",
                      { staticClass: "pic-layout" },
                      [
                        _vm._l(
                          _vm.imageUrlList ? _vm.imageUrlList : [],
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "item" },
                              [
                                _c("el-image", {
                                  ref: `myImg${index}`,
                                  refInFor: true,
                                  staticClass: "icon",
                                  attrs: {
                                    src: item,
                                    fit: "cover",
                                    "initial-index": index,
                                    "preview-src-list": _vm.imageUrlList,
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "preview" },
                                  [
                                    _c("el-image", {
                                      staticClass: "btn-icon",
                                      attrs: { src: _vm.SeeIcon },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlePreview(
                                            `myImg${index}`
                                          )
                                        },
                                      },
                                    }),
                                    _c("el-image", {
                                      staticClass: "btn-icon",
                                      attrs: { src: _vm.DelIcon },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDeleteImage(index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item add-pic",
                            on: {
                              click: function ($event) {
                                return _vm.handleUpload()
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "image-wrap" },
                              [_c("el-image", { attrs: { src: _vm.AddIcon } })],
                              1
                            ),
                            _c("span", { staticClass: "text" }, [
                              _vm._v("上传图片"),
                            ]),
                          ]
                        ),
                      ],
                      2
                    ),
                  ]),
                  _vm._m(3),
                ]),
              ]),
              _c("div", { staticClass: "row mutlti big" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("商品标签")]),
                _c("div", { staticClass: "value fill" }, [
                  _c(
                    "div",
                    { staticClass: "flag-block" },
                    [
                      _c("span", { staticClass: "flag-title" }, [
                        _vm._v("商品标签"),
                      ]),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.radio,
                            callback: function ($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio",
                          },
                        },
                        [_vm._v("药潺潺")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.radio,
                            callback: function ($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio",
                          },
                        },
                        [_vm._v("尾货清仓")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "3" },
                          model: {
                            value: _vm.radio,
                            callback: function ($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio",
                          },
                        },
                        [_vm._v("特惠")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._m(4),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("商家店铺页排序")]),
                _c(
                  "div",
                  { staticClass: "value input" },
                  [_c("el-input", { attrs: { placeholder: "请输入" } })],
                  1
                ),
                _vm._m(5),
              ]),
              _c("div", { staticClass: "row mutlti" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("批件药检报告等")]),
                _c(
                  "div",
                  { staticClass: "value input fill" },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "upload-demo",
                        attrs: {
                          action: _vm.action,
                          "on-preview": _vm.handlePreviewUpload,
                          "on-remove": _vm.handleRemove,
                          "before-remove": _vm.beforeRemove,
                          multiple: "",
                          limit: _vm.limit,
                          "on-exceed": _vm.handleExceed,
                          "file-list": _vm.fileList,
                          "on-success": _vm.onSuccessUpload,
                          accept: ".jpg, .png, .pdf,.bmp,.jpeg",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "sigle-row-up-btns" },
                          [
                            _c("el-button", { attrs: { size: "small" } }, [
                              _vm._v("点击上传"),
                            ]),
                            _c("div", { staticClass: "el-upload__tip" }, [
                              _vm._v("上传pdf或图片，且不超过10M"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "harf-block" }, [
          _c("div", { staticClass: "big-block" }, [
            _vm._m(6),
            _c("div", { staticClass: "form-content" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("配置方式")]),
                _c(
                  "div",
                  { staticClass: "value fill" },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "1" },
                        model: {
                          value: _vm.radio,
                          callback: function ($$v) {
                            _vm.radio = $$v
                          },
                          expression: "radio",
                        },
                      },
                      [_vm._v(" 设置区域、客户类型及黑名单 ")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "2" },
                        model: {
                          value: _vm.radio,
                          callback: function ($$v) {
                            _vm.radio = $$v
                          },
                          expression: "radio",
                        },
                      },
                      [_vm._v("白名单")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row mutlti big" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("地域")]),
                _c("div", { staticClass: "value fill" }, [
                  _c(
                    "div",
                    { staticClass: "flag-block" },
                    _vm._l([1, 1, 1, 1, 1, 1], function (item, index) {
                      return _c(
                        "el-radio",
                        {
                          key: index,
                          attrs: { label: "1" },
                          model: {
                            value: _vm.radio,
                            callback: function ($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio",
                          },
                        },
                        [_vm._v(" 药潺潺 ")]
                      )
                    }),
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "row contine" }, [
                _c("div", { staticClass: "tip" }),
                _vm._m(7),
                _c(
                  "div",
                  { staticClass: "value" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", icon: "el-icon-plus" },
                        on: { click: _vm.handleAddArea },
                      },
                      [_vm._v("新增地域")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row mutlti big" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("客户类型")]),
                _c("div", { staticClass: "value fill" }, [
                  _c(
                    "div",
                    { staticClass: "flag-block" },
                    _vm._l([1, 1, 1, 1, 1, 1, 1, 1], function (item, index) {
                      return _c("el-checkbox", { key: index }, [
                        _vm._v(" 诊所 "),
                      ])
                    }),
                    1
                  ),
                ]),
              ]),
              _vm._m(8),
              _c("div", { staticClass: "row mutlti big" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("黑名单")]),
                _c("div", { staticClass: "value fill" }, [
                  _c(
                    "div",
                    { staticClass: "flag-block" },
                    _vm._l([1, 1, 1, 1, 1, 1], function (item, index) {
                      return _c(
                        "el-radio",
                        {
                          key: index,
                          attrs: { label: "1" },
                          model: {
                            value: _vm.radio,
                            callback: function ($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio",
                          },
                        },
                        [_vm._v(" 药潺潺 ")]
                      )
                    }),
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "row contine" }, [
                _c("div", { staticClass: "tip" }),
                _vm._m(9),
                _c(
                  "div",
                  { staticClass: "value" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", icon: "el-icon-plus" },
                        on: { click: _vm.handleAddUserGroup },
                      },
                      [_vm._v(" 新增用户组 ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("el-button", { on: { click: _vm.handleBack } }, [
            _vm._v("返回上一页"),
          ]),
          _c("el-button", { attrs: { type: "primary" } }, [_vm._v("发布商品")]),
        ],
        1
      ),
      _c("UploadImageView", {
        ref: "imgupload",
        attrs: { "request-id": "1", imgType: _vm.imgType },
        on: { upload: _vm.uploadSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "txt-content" }, [
      _c("div", { staticClass: "big-title" }, [_vm._v("美林 布洛芬混悬液")]),
      _c("div", { staticClass: "desp" }, [_vm._v("200ml*1支/盒")]),
      _c("div", { staticClass: "desp" }, [
        _vm._v("天津中新药业集团股份有限公司第六中药厂"),
      ]),
      _c("div", { staticClass: "desp" }, [_vm._v("国药准字Z12020025")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-block" }, [
      _c("span", { staticClass: "text" }, [_vm._v("价格/库存")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-block" }, [
      _c("span", { staticClass: "text" }, [_vm._v("图片/其他")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "in-row" }, [
      _c("span", { staticClass: "value small-tip" }, [
        _vm._v("注意：上传图片需要审核，最多五张，图片尺寸建议1080*1080px"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row contine" }, [
      _c("div", { staticClass: "tip" }),
      _c("div", { staticClass: "value fill small-tip" }, [
        _c("span", [
          _vm._v(
            " 标签是显示在app商品列表图上的边框/角标优惠信息。 商家标签可在商品管理-商家标签上传 "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "value small-tip" }, [
      _c("span", [_vm._v("注意：排序大的靠前，最大99999")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-block" }, [
      _c("span", { staticClass: "text" }, [_vm._v("销售规则")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "value fill small-tip" }, [
      _c("span", [_vm._v(" 注意：仅限已选地域对应客户可购买当前商品。 ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row contine" }, [
      _c("div", { staticClass: "tip" }),
      _c("div", { staticClass: "value fill small-tip" }, [
        _c("span", [
          _vm._v(" 注意：被勾选的客户类型对应的客户可购买当前商品。 "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "value fill small-tip" }, [
      _c("span", [_vm._v(" 注意：黑名单客户不可购买当前商品。 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }