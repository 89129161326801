<template>
  <div class="public-task-detail-root">
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="1018px"
      @closed="onClosed"
      v-loading="loading"
      class="public-task-dialog"
    >
      <div class="full-content">
        <div class="left">
          <div class="title">切换任务类型</div>
          <div class="v-list">
            <div
              @click="handleSelectTaskType(index)"
              :class="{
                'v-list-item': true,
                active: currentCommand === item.value,
                disabled: isLook,
              }"
              v-for="(item, index) in commandList"
              :key="index"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="split-block v"></div>
        <div class="content form-content">
          <div class="block">
            <div class="title">任务基本信息</div>
            <div class="flex-layout">
              <div class="flex-item">
                <div class="tip">任务名称<span class="weight">*</span></div>
                <div class="value">
                  <el-input
                    size="small"
                    v-model="name"
                    :disabled="isLook"
                    maxlength="20"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">任务编码</div>
                <div class="value">
                  <el-input
                    size="small"
                    disabled
                    placeholder="创建后自动生成"
                    :value="code"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">关联指标<span class="weight">*</span></div>
                <div class="value">
                  <!-- <el-select
                    size="small"
                    v-model="value"
                    placeholder="请选择"
                    disabled
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select> -->
                  <el-input
                    size="small"
                    disabled
                    placeholder=""
                    :value="target ? target.name : ''"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">CSO公司名称</div>
                <div class="value">
                  <el-input
                    size="small"
                    disabled
                    placeholder=""
                    :value="target ? target.coopShopName : ''"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">执行次数<span class="weight">*</span></div>
                <div class="value">
                  <el-input
                    size="small"
                    type="number"
                    :disabled="isLook"
                    maxlength="2"
                    v-model.trim="taskNum"
                    @input="(e) => thresholdReg('taskNum', e)"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">单次执行金额<span class="weight">*</span></div>
                <div class="value">
                  <el-input
                    size="small"
                    type="number"
                    :disabled="isLook"
                    maxlength="10"
                    v-model.trim="taskPrice"
                    @input="(e) => thresholdReg('taskPrice', e)"
                  />
                </div>
              </div>
              <div class="flex-item long">
                <div class="tip">任务说明</div>
                <div class="value">
                  <el-input
                    size="small"
                    maxlength="200"
                    v-model="remark"
                    :disabled="isLook"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="split-block h"></div>
          <div class="block">
            <div class="remark">
              以下为任务执行表单，由CSO公司执行时填写，您仅可预览。
            </div>
            <div class="title">执行详情</div>
            <div style="margin-top: 16px">
              <childtemplate :childTaskType="childTaskType" />
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">关闭</el-button>
        <el-button
          v-if="!isLook"
          size="small"
          type="primary"
          @click="handleSave"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { splitMoney } from "@/utils/validate";

import { taskHelp } from "@/utils/viewHelp";
import { contractTargetDetail } from "@/api/compliance/metricsManager";
import {
  contractTargetTaskDetail,
  contractTargetTaskEdit,
  contractTargetTaskAdd,
} from "@/api/compliance/task";
import childtemplate from "@/views/compliance/task/static/childtemplate.vue";

export default {
  components: { childtemplate },
  data() {
    return {
      action: 1, //1 新增  2 编辑 3 查看, 4 审查
      dialogVisible: false,
      loading: false,

      id: "",
      targetId: "", //指标id
      target: null, //指标对象

      name: "", //任务名称
      code: "", // 任务编码 只显示
      taskNum: "", //执行次数
      taskPrice: "", //任务单次金额
      remark: "", //任务说明

      commandList: taskHelp.taskTypeList,
      currentCommand: "",
    };
  },
  computed: {
    title() {
      let txt = "任务";
      if (this.action === 2) {
        txt = `编辑${txt}`;
      } else if (this.action === 3) {
        txt = `查看${txt}`;
      } else {
        txt = `发布${txt}`;
      }
      return txt;
    },
    isLook() {
      return this && this.action === 3;
    },
    showAuditOpinion() {
      return this.isLook && this.auditStatus === 2;
    },
    childTaskType() {
      return this.currentCommand ? +this.currentCommand : -1;
    },
  },
  methods: {
    open(options = null) {
      if (options) {
        this.action = options.action ? options.action : 1;
        this.currentCommand = options.command;
        this.id = options.id ? options.id : "";
        this.targetId = options.targetId;
        this.getTargetDetail();
        this.getDetail();
      }

      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    onClosed() {
      this.reset();
    },
    reset() {
      this.action = 1;

      this.id = "";
      this.targetId = "";
      this.target = null;

      this.name = "";
      this.code = "";
      this.taskNum = "";
      this.taskPrice = "";
      this.remark = "";

      this.currentCommand = "";
      this.loading = false;
    },
    handleSelectTaskType(index) {
      if (this.isLook) {
        return;
      }
      const item = this.commandList[index];
      this.currentCommand = item.value;
    },
    thresholdReg(key, value) {
      if (value) {
        let reg = null;
        let bit = 4;
        if (key === "taskNum") {
          //执行次数
          reg = /^\d{1,4}?$/;
          bit = 4;
        } else if (key === "taskPrice") {
          //单次执行金额
          reg = /^\d{1,5}(\.\d{0,2})?$/;
          bit = 5;
        }
        if (!reg.test(value)) {
          this[key] = splitMoney(value, bit, 0);
        }
      }
    },
    getDetail() {
      if (this.id) {
        this.loading = true;
        contractTargetTaskDetail(this.id)
          .then((data) => {
            this.loading = false;
            if (data) {
              this.posterName = data.posterName;

              this.currentCommand = data.taskType;
              this.name = data.name;
              this.taskNum = data.taskNum;
              this.taskPrice = data.taskPrice;
              this.remark = data.remark;
              this.code = data.code;
            }
          })
          .catch((ex) => {
            this.loading = false;
          });
      }
    },
    handleSave() {
      if (!this.name) {
        this.$message.warning("请填写任务名称!");
        return;
      }
      if (!this.taskNum || +this.taskNum <= 0) {
        this.$message.warning("请填写执行次数!");
        return;
      }
      if (!this.taskPrice || +this.taskPrice <= 0) {
        this.$message.warning("请填写单次执行金额!");
        return;
      }

      if (!this.target) {
        this.$message.warning("指标详情还在加载中!");
        return;
      }
      this.loading = true;

      const data = {
        id: this.id ? this.id : "",
        targetId: this.targetId,
        name: this.name,
        code: this.id ? this.code : "",
        taskType: this.currentCommand,
        taskNum: this.taskNum,
        taskPrice: this.taskPrice,
        remark: this.remark,

        targetCode: this.target.code,
        targetName: this.target.name,
        contractId: this.target.contractId,
        contractCode: this.target.contractCode,
        draftShopName: this.target.draftShopName,
        draftShopId: this.target.draftShopId,
        coopShopId: this.target.coopShopId,
        coopShopName: this.target.coopShopName,
      };

      if (this.id) {
        contractTargetTaskEdit(data)
          .then((res) => {
            if (res) {
              this.$emit("onRefresh");
              this.dialogVisible = false;
            }
            this.loading = false;
          })
          .catch((ex) => {
            this.loading = false;
          });
      } else {
        contractTargetTaskAdd(data)
          .then((res) => {
            if (res) {
              this.$emit("onRefresh");
              this.dialogVisible = false;
            }
            this.loading = false;
          })
          .catch((ex) => {
            this.loading = false;
          });
      }
    },
    getTargetDetail() {
      contractTargetDetail(this.targetId).then((data) => {
        if (data) {
          this.target = data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.public-task-detail-root {
  .full-content {
    display: flex;
    height: 100%;
    .left {
      width: 160px;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 15px;
        font-weight: bold;
        color: #262626;
        padding: 12px 12px 12px 24px;
        box-sizing: border-box;
        // border-bottom: 1px solid #f0f0f0;
      }

      .v-list {
        margin-top: 4px;

        .v-list-item {
          padding: 12px 12px 12px 24px;
          box-sizing: border-box;
          cursor: pointer;
          &.disabled {
            cursor: not-allowed;
          }
          &.active {
            background-color: #f5222d;
            color: #fff;
            &.disabled {
              background-color: rgba(0, 0, 0, 0.04);
              color: #8c8c8c;
            }
          }
        }
      }
    }
    .form-content {
      flex: 1;
      margin-left: 12px;
      overflow: auto;

      .block {
        .title {
          padding: 12px 12px 12px 8px;
          font-size: 15px;
          font-weight: bold;
          color: #262626;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 4px;
            height: 20px;
            background: #1890ff;
            transform: translateY(-50%);
            border-radius: 2px;
          }
        }

        .remark {
          display: flex;
          justify-content: center;
          padding: 8px 0;
          box-sizing: border-box;
          background: #ffece1;
          font-size: 14px;
          color: #ffa37d;
        }

        .flex-layout {
          display: flex;
          flex-wrap: wrap;

          .flex-item {
            width: calc(25% - 8px);
            display: flex;
            flex-direction: column;
            margin-right: 8px;
            margin-bottom: 16px;

            &.long {
              width: calc(50% - 8px);
            }

            .tip {
              font-size: 14px;
              color: #262626;

              .weight {
                color: #f00;
                position: relative;
                top: 4px;
                margin-left: 2px;
              }
            }

            .value {
              margin-top: 4px;
              ::v-deep {
                .el-input {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .split-block {
      background: #f0f0f0;

      &.v {
        width: 6px;
      }
      &.h {
        width: auto;
        height: 6px;
      }
    }
  }

  .form-content {
    padding-right: 16px;
    box-sizing: border-box;
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 0;
      box-sizing: border-box;

      &.mutlti {
        align-items: flex-start;
      }

      &.contine {
        padding-top: 0;
      }

      &.big {
        .tip {
          height: 32px;
          line-height: 32px;
        }
      }

      .tip {
        width: 5em;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        text-align: right;
        color: #262626;
      }

      .value {
        margin-left: 16px;

        &.input {
          width: 204px;

          ::v-deep .el-date-editor.el-input,
          ::v-deep .el-date-editor.el-input__inner {
            width: 100%;
          }

          ::v-deep .el-select {
            width: 100%;
          }
        }

        &.fill {
          flex: 1;
          width: 0;
        }

        .small-tip {
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #8c8c8c;

          &.warning {
            color: #e6a23c;
          }
        }

        &.mutlti {
          flex: 1;
          width: 0;
          display: flex;

          &.v {
            flex-direction: column;

            .in-row {
              &:not(:first-child) {
                margin-top: 8px;
              }
            }
          }
        }

        .flag-block {
          width: 100%;
          background: #fafafa;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 8px 16px 16px 16px;
          box-sizing: border-box;
          flex-wrap: wrap;

          .flag-title {
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            margin-right: 8px;
          }

          & > * {
            margin-top: 8px;
          }
        }
      }

      .in-row {
        .value {
          margin-left: 0;
        }
      }
    }

    .opinion {
      color: #f00;
    }

    .goods-info {
      display: inline-flex;
      align-items: center;
      margin-right: 24px;

      .txt-info {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        .name {
          font-size: 16px;
          font-weight: 600;
          color: var(--color-26);
        }
        .factory {
          color: var(--color-59);
          font-size: 14px;
          margin-top: 3px;
        }
      }
    }
  }

  ::v-deep .el-dialog__wrapper.public-task-dialog {
    display: flex;
    align-items: center;

    .el-dialog {
      margin-top: 0 !important;
      margin: 0 auto 0;
    }

    .el-dialog__body {
      max-height: 75vh;
      height: 75vh;
      overflow-y: auto;
      // min-height: 240px;
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
    }
  }
}
</style>
