var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c("div", { staticStyle: { height: "16px" } }),
    _c(
      "div",
      { staticClass: "headerLine" },
      [
        _c(
          "el-form",
          { ref: "formRef", attrs: { model: _vm.formTool, inline: true } },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "selfControllerId" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { "max-width": "130px" },
                    attrs: {
                      size: "small",
                      placeholder: "自营控销品",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formTool.selfControllerId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "selfControllerId", $$v)
                      },
                      expression: "formTool.selfControllerId",
                    },
                  },
                  _vm._l(_vm.selfControllerOptions, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "payeeShopName" } },
              [
                _c("el-input", {
                  attrs: { size: "small", placeholder: "所属医药公司" },
                  model: {
                    value: _vm.formTool.payeeShopName,
                    callback: function ($$v) {
                      _vm.$set(_vm.formTool, "payeeShopName", $$v)
                    },
                    expression: "formTool.payeeShopName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "selfGoodsId" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      size: "small",
                      placeholder: "请选择自营商品",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formTool.selfGoodsId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "selfGoodsId", $$v)
                      },
                      expression: "formTool.selfGoodsId",
                    },
                  },
                  _vm._l(_vm.selfGoodsList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "areaCompanyId" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      size: "small",
                      placeholder: "请选择地区公司",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formTool.areaCompanyId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "areaCompanyId", $$v)
                      },
                      expression: "formTool.areaCompanyId",
                    },
                  },
                  _vm._l(_vm.areaCompanyList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.shopName, value: item.shopId },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "dealType" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { "max-width": "130px" },
                    attrs: {
                      size: "small",
                      placeholder: "全部",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formTool.dealType,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "dealType", $$v)
                      },
                      expression: "formTool.dealType",
                    },
                  },
                  _vm._l(_vm.dealTypeOptions, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "orderStatus" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      size: "small",
                      placeholder: "请选择订单状态",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formTool.orderStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "orderStatus", $$v)
                      },
                      expression: "formTool.orderStatus",
                    },
                  },
                  _vm._l(_vm.orderStatusList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.getList("search")
                      },
                    },
                  },
                  [_vm._v("检索")]
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.resetForm } },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.exportXLSX } },
                  [_vm._v("导出")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form",
          [
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "auto" },
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.commitAll },
                  },
                  [_vm._v("一键审单")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "headerLine" },
      [
        _c(
          "el-form",
          { ref: "formRef2", attrs: { model: _vm.formTool, inline: true } },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "province" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "130px" },
                    attrs: { size: "small", placeholder: "请选择省" },
                    on: { change: _vm.provinceBtn },
                    model: {
                      value: _vm.formTool.province,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "province", $$v)
                      },
                      expression: "formTool.province",
                    },
                  },
                  _vm._l(_vm.provinceData, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.rname, value: item.id },
                    })
                  }),
                  1
                ),
                _c(
                  "el-select",
                  {
                    staticStyle: { "margin-left": "10px", width: "130px" },
                    attrs: { size: "small", placeholder: "请选择市" },
                    on: { change: _vm.cityBtn },
                    model: {
                      value: _vm.formTool.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "city", $$v)
                      },
                      expression: "formTool.city",
                    },
                  },
                  _vm._l(_vm.cityData, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.rname, value: item.id },
                    })
                  }),
                  1
                ),
                _c(
                  "el-select",
                  {
                    staticStyle: { "margin-left": "10px", width: "130px" },
                    attrs: { size: "small", placeholder: "请选择区" },
                    on: { change: _vm.areaBtn },
                    model: {
                      value: _vm.formTool.area,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "area", $$v)
                      },
                      expression: "formTool.area",
                    },
                  },
                  _vm._l(_vm.areaData, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.rname, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "clinicName" } },
              [
                _c("el-input", {
                  attrs: { size: "small", placeholder: "按诊所名称" },
                  model: {
                    value: _vm.formTool.clinicName,
                    callback: function ($$v) {
                      _vm.$set(_vm.formTool, "clinicName", $$v)
                    },
                    expression: "formTool.clinicName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "name" } },
              [
                _c("el-input", {
                  attrs: { size: "small", placeholder: "按用户名" },
                  model: {
                    value: _vm.formTool.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formTool, "name", $$v)
                    },
                    expression: "formTool.name",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form",
          [
            _c(
              "el-form-item",
              [
                _c("el-switch", {
                  attrs: {
                    size: "small",
                    "active-color": "#13ce66",
                    "inactive-color": "#ff4949",
                    "active-text": "自动审单",
                    "inactive-text": "非自动审单",
                  },
                  on: { change: _vm.openCheck },
                  model: {
                    value: _vm.shendan,
                    callback: function ($$v) {
                      _vm.shendan = $$v
                    },
                    expression: "shendan",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "center" },
      [
        _c("MyTable", {
          attrs: {
            "table-data": _vm.tableData,
            showpage: true,
            selection: true,
            page: _vm.page,
            index: false,
            "table-option": _vm.tableOption,
            selectableCanBeDisable: true,
          },
          on: {
            "update:tableOption": function ($event) {
              _vm.tableOption = $event
            },
            "update:table-option": function ($event) {
              _vm.tableOption = $event
            },
            handleSelectionChange: _vm.handleSelectionChange,
            "page-change": _vm.getList,
          },
          scopedSlots: _vm._u([
            {
              key: "clinicName",
              fn: function (scope) {
                return [
                  _c(
                    "p",
                    { staticStyle: { "font-weight": "600", color: "#262626" } },
                    [_vm._v(" " + _vm._s(scope.row.clinicName) + " ")]
                  ),
                  _c("p", { staticStyle: { color: "#666" } }, [
                    _vm._v(" " + _vm._s(scope.row.loginName) + " "),
                  ]),
                ]
              },
            },
            {
              key: "name",
              fn: function (scope) {
                return [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "80px",
                            height: "80px",
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("el-image", {
                            staticStyle: { width: "80px" },
                            attrs: { src: scope.row.image, lazy: "" },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "imageP" }, [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-weight": "600",
                              color: "#262626",
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.name) + " ")]
                        ),
                        _c("p", [
                          _vm._v(" " + _vm._s(scope.row.specification) + " "),
                        ]),
                        _c("p", [
                          _vm._v(" " + _vm._s(scope.row.factory) + " "),
                        ]),
                      ]),
                    ]
                  ),
                ]
              },
            },
            {
              key: "amount",
              fn: function (scope) {
                return [
                  _c("span", [_vm._v(" ￥" + _vm._s(scope.row.amount) + " ")]),
                ]
              },
            },
            {
              key: "price",
              fn: function (scope) {
                return [
                  _c("span", [_vm._v(" ￥" + _vm._s(scope.row.price) + " ")]),
                ]
              },
            },
            {
              key: "dzCheckStatus",
              fn: function (scope) {
                return [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c("span", [_vm._v("省区公司")]),
                      scope.row.szCheckStatus
                        ? _c(
                            "div",
                            [
                              _c("el-icon", {
                                staticClass: "el-icon-success",
                                staticStyle: {
                                  color: "#32bf78",
                                  margin: "0px 5px",
                                },
                              }),
                              _c("span", [
                                _vm._v(_vm._s(scope.row.szCheckDate || "")),
                              ]),
                            ],
                            1
                          )
                        : _c("el-icon", {
                            staticClass: "el-icon-error",
                            staticStyle: {
                              color: "#FF4D4F",
                              margin: "0px 5px",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c("span", [_vm._v("地区公司")]),
                      scope.row.dzCheckStatus
                        ? _c(
                            "div",
                            [
                              _c("el-icon", {
                                staticClass: "el-icon-success",
                                staticStyle: {
                                  color: "#32bf78",
                                  margin: "0px 5px",
                                },
                              }),
                              _c("span", [
                                _vm._v(_vm._s(scope.row.dzCheckDate || "")),
                              ]),
                            ],
                            1
                          )
                        : _c("el-icon", {
                            staticClass: "el-icon-error",
                            staticStyle: {
                              color: "#FF4D4F",
                              margin: "0px 5px",
                            },
                          }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "deal",
              fn: function (scope) {
                return [
                  scope.row.beAllowOperation
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { color: "#F5222D" },
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.commitSignal(scope.row)
                            },
                          },
                        },
                        [_vm._v("确认")]
                      )
                    : _c(
                        "el-button",
                        {
                          staticStyle: { color: "#666" },
                          attrs: { type: "text", size: "small" },
                        },
                        [
                          _vm._v(
                            _vm._s(scope.row.dzCheckDate ? "已确认" : "确认")
                          ),
                        ]
                      ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }