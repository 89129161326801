const getDefaultState = () => {
  return {
    ////发布商品
    publicConfig: {
      selectType: null, //选择的类型
      currentStep: "", //当前步骤
      goodsDetail: null,
      fillProductInfoForm: null, //fillProductInfo.vue全部state数据
    },
  };
};

const state = getDefaultState();

const mutations = {
  SET_SELECT_TYPE: (state, doload) => {
    state.publicConfig.selectType = doload;
  },
  SET_STEP: (state, doload) => {
    state.publicConfig.currentStep = doload;
  },
  SET_DETAIL: (state, doload) => {
    state.publicConfig.goodsDetail = doload;
  },
  SET_FILLINFO: (state, doload) => {
    state.publicConfig.fillProductInfoForm = doload;
  },
};

const actions = {
  setSlectType({ commit }, doload) {
    commit("SET_SELECT_TYPE", doload);
  },
  setStep({ commit }, doload) {
    commit("SET_STEP", doload);
  },
  setDetail({ commit }, doload) {
    commit("SET_DETAIL", doload);
  },
  setFillInfo({ commit }, doload) {
    commit("SET_FILLINFO", doload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
