var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-root" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            title: _vm.title,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "1158px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.onClosed,
          },
        },
        [
          _c("div", { staticClass: "full-content" }, [
            _c("div", { staticClass: "content form-content" }, [
              _c("div", { staticClass: "task-base-info" }, [
                _c("div", { staticClass: "base-info-item" }, [
                  _c("div", { staticClass: "tip" }, [_vm._v("编号")]),
                  _c("div", { staticClass: "value" }, [_vm._v("xxxx23423")]),
                ]),
                _c("div", { staticClass: "base-info-item" }, [
                  _c("div", { staticClass: "tip" }, [_vm._v("流程状态")]),
                  _c("div", { staticClass: "value" }, [_vm._v("执行中")]),
                ]),
                _c("div", { staticClass: "base-info-item" }, [
                  _c("div", { staticClass: "tip" }, [_vm._v("创建人")]),
                  _c("div", { staticClass: "value" }, [_vm._v("FMK")]),
                ]),
                _c("div", { staticClass: "base-info-item" }, [
                  _c("div", { staticClass: "tip" }, [_vm._v("创建时间")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v("2020-11-23 14:24"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "block" }, [
                _c("div", { staticClass: "title" }, [_vm._v("任务基本信息")]),
                _c("div", { staticClass: "flex-layout" }, [
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("任务名称"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [_c("el-input", { attrs: { size: "small" } })],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("任务编码")]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            disabled: "",
                            placeholder: "创建后自动生成",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("关联指标"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { size: "small", placeholder: "请选择" },
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          },
                          _vm._l(_vm.options, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("CSO公司名称")]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            disabled: "",
                            placeholder: "由所选指标数据关联",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("执行次数"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            type: "number",
                            maxlength: "2",
                          },
                          model: {
                            value: _vm.discountl,
                            callback: function ($$v) {
                              _vm.discountl =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "discountl",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v(" 单次执行金额"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            type: "number",
                            maxlength: "10",
                          },
                          model: {
                            value: _vm.discountl,
                            callback: function ($$v) {
                              _vm.discountl =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "discountl",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("任务类型")]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [_c("el-input", { attrs: { size: "small" } })],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item long" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("任务说明")]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: { size: "small", maxlength: "200" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "block" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("子任务列表(100条)"),
                ]),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "condition-layout" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.radio,
                            callback: function ($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio",
                          },
                        },
                        _vm._l(_vm.childTaskStatusList, function (item, index) {
                          return _c(
                            "el-radio-button",
                            { key: index, attrs: { label: item.id } },
                            [
                              _vm._v(
                                _vm._s(item.label) +
                                  "(" +
                                  _vm._s(item.value) +
                                  ")"
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "240px",
                              "margin-right": "8px",
                            },
                            attrs: {
                              placeholder: "搜索子任务/执行人/子任务金额",
                              clearable: "",
                              size: "small",
                            },
                          }),
                          _c("el-button", { attrs: { size: "small" } }, [
                            _vm._v(" 查询 "),
                          ]),
                          _c("el-button", { attrs: { size: "small" } }, [
                            _vm._v(" 批量审核 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "table-lalyout" },
                    [
                      _c("MyTable", {
                        attrs: {
                          index: true,
                          selection: true,
                          "table-data": _vm.tableData,
                          page: _vm.page,
                          "table-option": _vm.tableOption,
                        },
                        on: {
                          "update:tableOption": function ($event) {
                            _vm.tableOption = $event
                          },
                          "update:table-option": function ($event) {
                            _vm.tableOption = $event
                          },
                          handleSelectionChange: _vm.handleSelectionChange,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "operate",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "table-op-col" },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          underline: false,
                                          size: "small",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleLook(scope.$index)
                                          },
                                        },
                                      },
                                      [_vm._v("查看")]
                                    ),
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          underline: false,
                                          size: "small",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleLook(scope.$index)
                                          },
                                        },
                                      },
                                      [_vm._v("审核")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "right-parts" }, [
              _c("div", { staticClass: "title" }, [_vm._v("任务日记")]),
              _c(
                "div",
                { staticClass: "v-list" },
                _vm._l([1, 1, 1, 1, 1, 1], function (item, index) {
                  return _c("div", { key: index, staticClass: "v-list-item" }, [
                    _c("div", { staticClass: "left" }, [
                      _c("div", { staticClass: "line up" }),
                      _c("div", { staticClass: "dot" }),
                      _c("div", { staticClass: "line down" }),
                    ]),
                    _c("div", { staticClass: "datas-view" }, [
                      _c("div", { staticClass: "time" }, [
                        _vm._v("2023-01-23 14:23"),
                      ]),
                      _c("div", { staticClass: "detail-info" }, [
                        _c("div", { staticClass: "tip" }, [_vm._v("创建人")]),
                        _c("div", { staticClass: "more-info" }, [
                          _c("div", { staticClass: "multi-info" }, [
                            _vm._v("FMS发起任务"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "num" },
                            [
                              _c("el-image", {
                                staticClass: "small-icon",
                                attrs: { src: _vm.toolIcon },
                              }),
                              _c("span", [_vm._v("10")]),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v("关闭")]
              ),
              !_vm.isLook
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }