var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail comContent" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "170px",
            disabled: _vm.type == 2,
          },
        },
        [
          _c("div", { staticClass: "activeInfo" }, [
            _c("div", { staticClass: "tit" }, [_vm._v("拼团活动信息")]),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("el-form-item", { attrs: { label: "活动名称" } }, [
                  _vm._v(" " + _vm._s(_vm.groupInfo.name) + " "),
                ]),
                _c("el-form-item", { attrs: { label: "活动说明" } }, [
                  _vm._v(" " + _vm._s(_vm.groupInfo.remark) + " "),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: "拼团活动时间" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        disabled: "",
                        "value-format": "yyyy-MM-dd",
                        type: "daterange",
                        size: "small",
                        "range-separator": "-",
                        "start-placeholder": "拼团开始",
                        "end-placeholder": "拼团结束",
                      },
                      model: {
                        value: _vm.groupInfo.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.groupInfo, "date", $$v)
                        },
                        expression: "groupInfo.date",
                      },
                    }),
                    _c("span", { staticClass: "mark" }, [
                      _vm._v(" 活动开始前可修改拼团信息，开始后不允许修改"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "goodsInfo" },
            [
              _c("div", { staticClass: "tit" }, [_vm._v("拼团商品信息")]),
              _vm.goodsInfo && _vm.goodsInfo.goodsId
                ? _c(
                    "div",
                    {
                      staticClass: "goodsInfoBox",
                      on: { click: _vm.openGoodsListTemp },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.goodsInfo.image || _vm.goodsInfo.goodsImg,
                        },
                      }),
                      _c("div", { staticClass: "info" }, [
                        _c("div", [
                          _vm._v(
                            "商品名称：" +
                              _vm._s(
                                _vm.goodsInfo.name || _vm.goodsInfo.goodsName
                              )
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            " 规格：" +
                              _vm._s(
                                _vm.goodsInfo.specification ||
                                  _vm.goodsInfo.goodsSpecification
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            " 生产厂家：" +
                              _vm._s(
                                _vm.goodsInfo.factory ||
                                  _vm.goodsInfo.goodsFactory
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "零售价：￥" +
                              _vm._s(Number(_vm.goodsInfo.salePrice).toFixed(2))
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.openGoodsList },
                    },
                    [_vm._v("选择商品")]
                  ),
            ],
            1
          ),
          _vm.goodsInfo && _vm.goodsInfo.goodsId
            ? _c(
                "div",
                { staticClass: "spellGoodsConfig" },
                [
                  _c("div", { staticClass: "tit" }, [_vm._v("配置拼团商品")]),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "拼团价格(￥)",
                            prop: "groupBuyPrice",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "30%" },
                            attrs: { size: "small", placeholder: "请输入" },
                            model: {
                              value: _vm.ruleForm.groupBuyPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "groupBuyPrice", $$v)
                              },
                              expression: "ruleForm.groupBuyPrice",
                            },
                          }),
                          _c("span", { staticClass: "mark" }, [
                            _vm._v(" 每家参团药店采购价格"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "起拼下限",
                            prop: "minSingleBuyCount",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "30%" },
                            attrs: {
                              size: "small",
                              type: "number",
                              placeholder: "请输入",
                              oninput:
                                "\n              if (value.length > 4) {\n                value = value.slice(0, 4);\n              }\n              value = value.replace(/[^0-9]/g, '');\n            ",
                            },
                            model: {
                              value: _vm.ruleForm.minSingleBuyCount,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "minSingleBuyCount", $$v)
                              },
                              expression: "ruleForm.minSingleBuyCount",
                            },
                          }),
                          _c("span", { staticClass: "mark" }, [
                            _vm._v(" 每家参团药店单次最低采购数量"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "累计拼团上限",
                            prop: "userMaxTotalBuyCount",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "30%" },
                            attrs: {
                              size: "small",
                              type: "number",
                              placeholder: "请输入",
                              oninput:
                                "\n              if (value.length > 8) {\n                value = value.slice(0, 8);\n              }\n              value = value.replace(/[^0-9]/g, '');\n            ",
                            },
                            model: {
                              value: _vm.ruleForm.userMaxTotalBuyCount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "userMaxTotalBuyCount",
                                  $$v
                                )
                              },
                              expression: "ruleForm.userMaxTotalBuyCount",
                            },
                          }),
                          _c("span", { staticClass: "mark" }, [
                            _vm._v(" 每家参团药店累计最高采购数量"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "拼团活动库存",
                            prop: "maxGroupTotalCount",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "30%" },
                            attrs: {
                              size: "small",
                              type: "number",
                              placeholder: "请输入",
                              oninput:
                                "\n              if (value.length > 8) {\n                value = value.slice(0, 8);\n              }\n              value = value.replace(/[^0-9]/g, '');\n            ",
                            },
                            model: {
                              value: _vm.ruleForm.maxGroupTotalCount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "maxGroupTotalCount",
                                  $$v
                                )
                              },
                              expression: "ruleForm.maxGroupTotalCount",
                            },
                          }),
                          _c("span", { staticClass: "mark" }, [
                            _vm._v(" 所有参团药店采购总数量不能大于该数值"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.isDisabled
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.cancel },
                            },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm("ruleForm")
                                },
                              },
                            },
                            [_vm._v("提交")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.isDisabled
        ? _c(
            "div",
            { staticStyle: { "text-align": "left", "margin-top": "10px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("goodsDialog", {
        attrs: { "dialog-visible": _vm.dialogVisible },
        on: { chooseGoodsItem: _vm.chooseGoodsItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }