var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "classification" }, [
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("div", { staticClass: "staus_bar fl_bet border" }, [
          _c("div", { staticClass: "detail_left" }, [
            _c("div", { staticClass: "fl_cen" }, [
              _c(
                "div",
                { staticClass: "status" },
                [
                  _c("el-image", {
                    staticClass: "icon_status",
                    attrs: { src: _vm.getStatus(_vm.orderList.type) },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("订单编号")]),
                _c("div", { staticStyle: { height: "22px" } }, [
                  _vm._v(_vm._s(_vm.orderList.orderCode || "")),
                ]),
              ]),
              _c("div", { staticClass: "items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("售后单号")]),
                _c("div", { staticStyle: { height: "22px" } }, [
                  _vm._v(_vm._s(_vm.orderList.code || "")),
                ]),
              ]),
              _c("div", { staticClass: "items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("售后金额")]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      height: "22px",
                      color: "#e4393c",
                      "font-size": "18px",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.orderList.amount || "") + " ")]
                ),
              ]),
            ]),
          ]),
        ]),
        _c("Step", {
          attrs: {
            stepArray: _vm.stepArray,
            indexStep: _vm.indexStep,
            lineWord: true,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "info card" },
      [
        _c(
          "el-descriptions",
          { attrs: { title: "售后单据信息" } },
          [
            _c("el-descriptions-item", { attrs: { label: "申请类型" } }, [
              _vm._v(_vm._s(_vm.orderList.type == "TK" ? "退款" : "退货退款")),
            ]),
            _c("el-descriptions-item", { attrs: { label: "申请金额" } }, [
              _vm._v(_vm._s(_vm.orderList.amount)),
            ]),
            _c("el-descriptions-item", { attrs: { label: "申请原因" } }, [
              _vm._v(_vm._s(_vm.orderList.afterSaleReason)),
            ]),
            _c("el-descriptions-item", { attrs: { label: "申请用户" } }, [
              _vm._v(_vm._s(_vm.orderList.payShopName) + " "),
            ]),
            _c("el-descriptions-item", { attrs: { label: "申请时间" } }, [
              _vm._v(_vm._s(_vm.orderList.orderTime)),
            ]),
            _c("el-descriptions-item", { attrs: { label: "问题描述" } }, [
              _vm._v(_vm._s(_vm.orderList.remark)),
            ]),
            _c(
              "el-descriptions-item",
              { attrs: { label: "售后凭证" } },
              [
                _c("ImgUpload", {
                  staticStyle: { position: "relative", top: "-8px" },
                  attrs: { isEdit: true, limit: 3 },
                  model: {
                    value: _vm.orderList.afterSaleVoucher,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderList, "afterSaleVoucher", $$v)
                    },
                    expression: "orderList.afterSaleVoucher",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "info card fl_cen" }, [
      _c("div", { staticClass: "info_list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("商户收货信息")]),
        _c("div", { staticClass: "lists" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("收货人：")]),
            _vm._v(_vm._s(_vm.orderList.contactsName || "--") + " "),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("地址：")]),
            _vm._v(
              _vm._s(_vm.orderList.province || "") +
                _vm._s(_vm.orderList.city || "") +
                _vm._s(_vm.orderList.district || "") +
                _vm._s(_vm.orderList.address || "") +
                " "
            ),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("手机号：")]),
            _vm._v(_vm._s(_vm.orderList.contactsPhone || "--") + " "),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "info_list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("配送信息")]),
        _vm.indexStep == 3 || _vm.orderList.type == "THTK"
          ? _c("div", { staticClass: "lists" }, [
              _c(
                "div",
                {
                  staticClass: "list_input fl_cen",
                  staticStyle: { "margin-top": "0px" },
                },
                [
                  _c("el-autocomplete", {
                    staticClass: "formInput",
                    attrs: {
                      placeholder: "填写快递公司",
                      "fetch-suggestions": _vm.querySearchAsync,
                      "value-key": "name",
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.form.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "companyName", $$v)
                      },
                      expression: "form.companyName",
                    },
                  }),
                  _c("el-input", {
                    staticClass: "formInput",
                    attrs: { placeholder: "填写快递单号" },
                    model: {
                      value: _vm.form.nums,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "nums", $$v)
                      },
                      expression: "form.nums",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "formbutton",
                      attrs: { loading: _vm.isBtnLoading },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.addKuaidi.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("提 交")]
                  ),
                ],
                1
              ),
            ])
          : _c("div", { staticClass: "lists" }, [
              _c("div", { staticClass: "item" }, [
                _c("span", [_vm._v("快递公司：")]),
                _vm._v(_vm._s(_vm.orderList.kuaidiName || "--") + " "),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", [_vm._v("快递单号：")]),
                _vm._v(_vm._s(_vm.orderList.number || "--") + " "),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", [_vm._v("预计送达：")]),
                _vm._v(_vm._s(_vm.orderList.finishDate || "--") + " "),
              ]),
            ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "info card" },
      [
        _c("h4", [_vm._v("物流数据")]),
        !_vm.expressData.child || _vm.expressData.child.length == 0
          ? _c(
              "div",
              { staticStyle: { color: "#f56c6c", "font-weight": "600" } },
              [
                _vm._v(
                  " " + _vm._s(_vm.expressData.step || "暂无物流数据") + " "
                ),
              ]
            )
          : _vm._e(),
        _vm.expressData.child && _vm.expressData.child.length > 0
          ? _c(
              "el-collapse",
              {
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c(
                  "el-collapse-item",
                  { attrs: { name: "1" } },
                  [
                    _c("template", { slot: "title" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "line-height": "20px",
                            height: "80px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#f56c6c",
                                "font-weight": "600",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.expressData.step || "暂无物流数据"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.expressData.description || "") +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticStyle: { "padding-top": "10px" } },
                      [
                        _vm.expressData.child &&
                        _vm.expressData.child.length > 0
                          ? _c(
                              "el-timeline",
                              _vm._l(
                                _vm.expressData.child,
                                function (activity, index) {
                                  return _c(
                                    "el-timeline-item",
                                    { key: index, attrs: { color: "#F5222D" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "font-weight": "600" },
                                        },
                                        [_vm._v(_vm._s(activity.step || ""))]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(activity.description || "")
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "commodity card" },
      [
        _c("h4", [_vm._v("商品信息")]),
        _c("publicTable", {
          attrs: {
            "table-data": _vm.orderList.detailList,
            "colum-obj": _vm.columObj,
            "page-obj": _vm.pageObj,
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "info card" }, [
      _c("h4", [_vm._v("协商历史")]),
      _c(
        "div",
        {},
        _vm._l(_vm.orderList.historyList, function (item, index) {
          return _c("div", { key: index, staticClass: "everyStep" }, [
            _c("div", { staticClass: "everyOne" }, [
              _c("span", [_vm._v(_vm._s(item.consultPerson))]),
              _c(
                "span",
                { staticStyle: { "font-size": "14px", color: "#595959" } },
                [_vm._v(_vm._s(item.consultTime))]
              ),
            ]),
            _c("div", { staticClass: "detail" }, [
              _vm._v(" " + _vm._s(item.consultResult) + " "),
            ]),
            item.consultImage
              ? _c(
                  "div",
                  { staticClass: "detail" },
                  [
                    _c("ImgUpload", {
                      staticStyle: { position: "relative", top: "0px" },
                      attrs: { isEdit: true },
                      model: {
                        value: item.consultImage,
                        callback: function ($$v) {
                          _vm.$set(item, "consultImage", $$v)
                        },
                        expression: "item.consultImage",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }