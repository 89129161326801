var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "itemCont" },
    [
      _vm.item.clickNone
        ? _c("div", { staticClass: "status_ico status_success" }, [
            _c("i", { staticClass: "el-icon-check" }),
          ])
        : _vm._e(),
      _vm.item.union
        ? _c(
            "div",
            {
              staticClass: "status_ico status_error",
              on: {
                click: function ($event) {
                  return _vm.remove(_vm.item)
                },
              },
            },
            [_c("i", { staticClass: "el-icon-close" })]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "itemImg",
          on: {
            click: function ($event) {
              return _vm.add(_vm.item)
            },
          },
        },
        [
          _c("img", {
            staticStyle: {
              width: "180px",
              height: "180px",
              padding: "15px 0px",
            },
            attrs: { src: _vm.item.image || _vm.avater, alt: "" },
          }),
        ]
      ),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: _vm.item.name, placement: "top" },
        },
        [
          _c("div", { staticClass: "itemBox name" }, [
            _vm._v(" " + _vm._s(_vm.item.name || "-") + " "),
          ]),
        ]
      ),
      _c("div", { staticClass: "itemBox factory" }, [
        _vm._v(" " + _vm._s(_vm.item.factory || "-") + " "),
      ]),
      _c("div", { staticClass: "itemBox factory" }, [
        _vm._v(" " + _vm._s(_vm.item.approvalNumber || "-") + " "),
      ]),
      _c(
        "div",
        {
          staticClass: "itemBox factory",
          staticStyle: { "padding-bottom": "10px" },
        },
        [_vm._v(" " + _vm._s(_vm.item.specification || "-") + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }