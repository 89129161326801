<template>
  <div class="show-manage-warp">
    <el-form :rules="rules" :model="formIn" ref="shopForm">
      <div class="form shop-manage">
        <div class="title">
          <span class="text">工作时间</span>
        </div>
        <el-form-item prop="workingHours" label="" label-width="0px">
          <el-checkbox v-model="item.checked" v-for="(item, index) in weekDays" :key="index" @change="checkHours">{{
            item.label
          }}</el-checkbox>
        </el-form-item>

        <div class="title">
          <span class="text">非考核时间段</span>
          <span class="desp">（如库存盘点、ERP切换等，最长时间为30天）</span>
        </div>
        <el-form-item prop="" label="起止时间" label-width="100px" style="margin-top: 10px;">
          <el-date-picker size="small" v-model="formIn.dnonAssessmentTime" type="datetimerange" range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
      </div>
    </el-form>

  </div>
</template>

<script>
import { queryDaySettings, updateDaySettings } from "@/api/shop";
import dayjs from "dayjs";
import router from "@/router";
export default {
  components: {},
  inject:  ['reloadShopAll'],

  data() {
    return {
      weekDays: [
        { label: "周一", checked: false, value: 1 },
        { label: "周二", checked: false, value: 2 },
        { label: "周三", checked: false, value: 3 },
        { label: "周四", checked: false, value: 4 },
        { label: "周五", checked: false, value: 5 },
        { label: "周六", checked: false, value: 6 },
        { label: "周日", checked: false, value: 7 },
      ],

      dnonAssessmentTime: "",
      dnonAssessmentCutOffTime: "",
      dnonAssessmentReturnTime: "",
      rules: {
        workingHours: [
          { required: true, message: "请选择工作时间", trigger: "blur" },
        ],
        dnonAssessmentTime: [
          { required: true, message: "请选择非考核起止时间", trigger: "blur" },
        ],
      },
      formIn: {
        dnonAssessmentTime:"",
        workingHours: "", //工作时间
        nonAssessmentStartTime: "", //非考核开始时间
        nonAssessmentEndTime: "", //非考核结束时间
        nonAssessmentCutOffTime: "", //非考核截单时间
        nonAssessmentReturnTime: "", //非考核发货时间
      },
    };
  },
  created() {
    this.doLoadInfo();
  },
  methods: {
    goNext() {
      this.$refs.shopForm.validate((valid) => {
        if (valid) {
          this.doUpdateInfo("NEXT")
        } else {
          return false;
        }
      });
    },
    goSave() {
      this.$refs.shopForm.validate((valid) => {
        if (valid) {
         this.doUpdateInfo()
        } else {
          return false;
        }
      });
    },
    checkHours() {
      let workingHours = "";
      this.weekDays.forEach((item) => {
        if (item.checked) {
          if (workingHours) {
            workingHours += ",";
          }
          workingHours += item.value;
        }
      });
      this.formIn.workingHours = workingHours;
    },
    doLoadInfo() {
      queryDaySettings().then((data) => {
        if (data) {
          if (data.workingHours) {
            let arr = data.workingHours.split(",");
            this.weekDays.forEach((item) => {
              if (arr.includes(item.label) || arr.includes("" + item.value)) {
                item.checked = true;
              }
            });
            this.formIn.workingHours = data.workingHours;
          }
          if (data.nonAssessmentStartTime && data.nonAssessmentEndTime) {
            this.formIn.dnonAssessmentTime = [
              dayjs(data.nonAssessmentStartTime).toDate(),
              dayjs(data.nonAssessmentEndTime).toDate(),
            ];
          }

          if (data.nonAssessmentCutOffTime) {
            this.dnonAssessmentCutOffTime = dayjs(
              data.nonAssessmentCutOffTime
            ).toDate();
          }

          if (data.nonAssessmentReturnTime) {
            this.dnonAssessmentReturnTime = dayjs(
              data.nonAssessmentReturnTime
            ).toDate();
          }
        }
      });
    },
    doUpdateInfo(status) {  
      if (
        this.formIn.dnonAssessmentTime instanceof Array &&
        this.formIn.dnonAssessmentTime.length === 2
      ) {
        this.formIn.nonAssessmentStartTime = dayjs(
          this.formIn.dnonAssessmentTime[0]
        ).format("YYYY-MM-DD HH:mm:ss");

        this.formIn.nonAssessmentEndTime = dayjs(
          this.formIn.dnonAssessmentTime[1]
        ).format("YYYY-MM-DD HH:mm:ss");
      }
      if (this.dnonAssessmentCutOffTime) {
        this.formIn.nonAssessmentCutOffTime = dayjs(
          this.dnonAssessmentCutOffTime
        ).format("YYYY-MM-DD HH:mm:ss");
      }
      if (this.dnonAssessmentReturnTime) {
        this.formIn.nonAssessmentReturnTime = dayjs(
          this.dnonAssessmentReturnTime
        ).format("YYYY-MM-DD HH:mm:ss");
      }

      let data = {};
      _.merge(data, this.formIn);
      console.log(data);
      this.$loading({
            lock: true,
            text: "正在提交...",
            spinner: "el-icon-loading",
            color: "#fff",
            background: "rgba(0, 0, 0, 0.7)",
          }); 
      updateDaySettings(data).then((res) => {
        if (res) {
          this.$loading().close();
          if(status === "NEXT"){
            this.$message.success("保存成功");
            this.$emit('indexStepChange', 5)
            this.$emit('queryInfo');// 刷新店铺信息
            this.reloadShopAll(); // 刷新店铺信息,更新提示状态 
          }else{
            this.$message.success("保存成功");
            this.$emit('indexStepChange', 4)
            this.reloadShopAll(); // 刷新店铺信息,更新提示状态 
            this.$emit('queryInfo');// 刷新店铺信息
          }
        
        }else{
          this.$loading().close();
        }
      }).catch(() => {
        this.$loading().close();
      })
    },

  },
};
</script>

<style lang="scss" scoped>
@import "./style/index.scss";

 
.desp {
  font-size: 14px;
  color: #333;
  font-weight: normal;
}
</style>
