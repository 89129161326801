<template>
  <div class="zt__table">
    <!-- :max-height="maxHeight" -->
    <el-table
      v-loading="isLoading"
      :max-height="maxHeight"
      highlight-current-row
      :selectableCanBeDisable="selectableCanBeDisable"
      :data="tableData"
      :border="border"
      @sort-change="sortChange"
      @cell-click="cellClick"
      @selection-change="handleSelectionChange"
      @current-change="handleCurrentChange"
      @row-click="rowClick"
      ref="tableRef"
      :row-key="getRowKey"
    >
      <el-table-column
        v-if="selection"
        type="selection"
        width="55"
        :selectable="selectable"
        align="left"
        :reserve-selection="reserveSelection"
      />
      <el-table-column v-if="index" type="index" label="序号" align="left" width="50">
        <template slot-scope="scope">
          <span>{{ scope.$index + (page.current - 1) * page.size + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="expand" type="expand" label="" align="left" width="50">
        <template slot-scope="props">
          <slot name="expand" :row="props.row" :$index="props.$index" />
        </template>
      </el-table-column>

      <template v-for="(item, i) in tableOption">
        <el-table-column
          :key="i"
          :min-width="item.minWidth"
          :max-width="item.maxWidth"
          :width="item.width"
          :prop="item.prop"
          :label="item.label"
          :align="item.align || 'left'"
          :sortable="item.sortable || false"
          :show-overflow-tooltip="item.overHidden || false"
          :render-header="item.renderHeader"
          :fixed="item.fixed"
          v-if="item.prop != 'countDownTimeAfter'"
        >
          <template slot-scope="scope">
            <slot
              v-if="item.slot"
              :name="scope.column.property"
              :row="scope.row"
              :$index="scope.$index"
            />
            <span v-else-if="item.type && item.type == 'money'">
              {{ parseFloat(scope.row[scope.column.property] || 0).toFixed(2) }}
            </span>
            <span v-else>
              {{ scope.row[scope.column.property] }}
            </span>
          </template>
          <template slot="header" v-if="item.customHeader">
            {{ item.label }}
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.content"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <div v-if="item.prop == 'countDownTimeAfter'">
          <el-table-column
            :key="i"
            :min-width="item.minWidth"
            :max-width="item.maxWidth"
            :width="item.width"
            :prop="item.prop"
            :label="item.label"
            :align="item.align || 'left'"
            :sortable="item.sortable || false"
            :show-overflow-tooltip="item.overHidden || false"
            :fixed="item.fixed"
          >
            <template slot="header">
              {{ item.label }}

              <el-tooltip placement="bottom">
                <div slot="content">
                  <div style="width: 300px">
                    <div style="color: #fff; margin-left: 10px; line-height: 24px">
                      超出倒计时的处理方式
                    </div>
                    <div style="color: #fff; margin-left: 10px; line-height: 24px">
                      线上支付仅退款审核-自动退款;
                    </div>
                    <div style="color: #fff; margin-left: 10px; line-height: 24px">
                      线下支付仅退款审核-自动扣除对应金额的保证金;
                    </div> 
                    <div style="color: #fff; margin-left: 10px; line-height: 24px">
                      线上/线下支付退款退货审核-自动审核通过;
                    </div>
                    <div style="color: #fff; margin-left: 10px; line-height: 24px">
                      线上/线下支付退货退款 确认收货-自动确认收货；
                    </div>
                    <div style="color: #fff; margin-left: 10px; line-height: 24px">
                      线下支付退货退款/仅退款 提交打款凭证-自动扣除对应金额的保证金;
                    </div>
                  </div>
                </div>
                <span> <i :class="`el-icon-warning`"></i></span>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <slot
                v-if="item.slot"
                :name="scope.column.property"
                :row="scope.row"
                :$index="scope.$index"
              />
            </template>
          </el-table-column>
        </div>
      </template>
      <el-table-column v-if="operation" label="操作" align="left">
        <template slot-scope="scope">
          <slot name="menu" :row="scope.row" :$index="scope.$index" />
        </template>
      </el-table-column>
      <template slot="empty">
        <el-empty v-if="tableData.length == 0" description="" />
      </template>
    </el-table>
    <!-- <el-empty v-else
              style="height:100%"
              description="暂无数据" /> -->

    <Pagination
      v-show="page.total > 0 && showpage"
      :total="page.total"
      :page-sizes="page.pageSizes"
      :page.sync="page.current"
      :limit.sync="page.size"
      @pagination="pageChange"
    />
  </div>
</template>

<script>
import Pagination from "../Pagination";
export default {
  name: "ZtTable",
  components: {
    Pagination,
  },
  props: {
    selectableCanBeDisable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    rowKeyProps: {
      type: String,
      default() {
        return "";
      },
    },
    maxHeight: {
      type: String,
      default() {
        return "100%";
      },
    },
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    selection: {
      type: Boolean,
      default() {
        return false;
      },
    }, // 是否设置勾选，默认不设置
    index: {
      type: Boolean,
      default() {
        return true;
      },
    }, // 是否设置序号，默认设置
    showpage: {
      // 是否显示分页组件
      type: Boolean,
      default() {
        return true;
      },
    },
    expand: {
      // 是否显示expand扩展组件
      type: Boolean,
      default() {
        return false;
      },
    },
    border: {
      type: Boolean,
      default() {
        return false;
      },
    }, // 是否设置边框，默认不要
    operation: {
      type: Boolean,
      default() {
        return false;
      },
    }, // 是否有操作列，默认无
    tableData: {
      type: Array,
      default() {
        return [];
      },
    }, // 列表数据
    tableOption: {
      type: Array,
      default() {
        return [];
      },
    }, // 表头
    page: {
      type: Object,
      default() {
        return {
          total: 0,
          current: 1,
          page: 10,
          pageSizes: [10, 20, 30, 50],
        };
      },
    }, // 分页
    reserveSelection: {
      type: Boolean,
      default() {
        return false;
      },
    }, //是否可以翻页保留勾选
  },
  computed: {
    // currentPage: {
    //   get() {
    //     return this.page.current
    //   },
    //   set(val) {
    //     this.$emit('update:page.current', val)
    //   }
    // },
    // limit: {
    //   get() {
    //     return this.page.size
    //   },
    //   set(val) {
    //     this.$emit('update:page.size', val)
    //   }
    // }
  },
  watch: {
    tableData(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$nextTick(() => {
          this.$refs.tableRef.bodyWrapper.scrollTop = 0;
          this.$refs.tableRef.doLayout();
        });
      }
    },
  },
  methods: {
    getRowKey(row) { 
          if(this.rowKeyProps){ 
                return row[this.rowKeyProps]
          }else{
                return row.skuCode
          }
      },
    selectable(row, index) {
      // 可点击确认按钮
      if (this.$props.selectableCanBeDisable) {
        return row.beAllowOperation;
      } else {
        return true;
      }
    },
    pageChange(e) {
      // this.$emit("page-change", e);
      this.$emit("page-change");
    },
    cellClick(row, column, cell, event) {
      this.$emit("cell-click", { row, column, cell, event });
    },
    rowClick(row, column, event) {
      this.$emit("rowClick", { row, column, event });
    },
    handleSelectionChange(val) {
      this.$emit("handleSelectionChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
    sortChange(val) {
      this.$emit("sort-change", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.zt__table {
  height: 100%;
  width: 100%;
  ::v-deep .el-table {
    height: 100%;
    .el-table__body-wrapper {
      height: calc(100% - 48px);
      overflow: auto;
      // z-index: 1;
      // overflow-x: hidden;
    }
    .el-table__fixed-body-wrapper {
      // overflow-y: auto; // fixed的列也要超出滚动
      height: calc(100% - 48px); // 出现滚动条需要比表格短10px
    }
  }
  ::v-deep .el-pagination {
    padding: 10px 0 0 0;
  }
}
</style>
