<template>
  <div class="status-root">
    <template v-if="status === 1">
      <div class="pic-wrap">
        <el-image :src="sucessIcon" />
      </div>
      <span class="big-tip">您已提交资质，请等待审核...</span>

      <div class="bottom-btn-layout">
        <el-button @click="handleLook">查看资质</el-button>
        <el-button @click="handleExit">退出</el-button>
      </div>
      <el-button @click="handleVarify" style="width:252px;margin-top: 16px;" v-if="linkStep == 1">电子合同认证</el-button>
      <div class="step2" v-else-if="linkStep == 2">
        <span>电子合同认证已完成？请进行免签授权</span>
        <div @click="_copy">场景码HSKF87394
          <p>复制</p>
        </div>
        <el-button @click="handleGoLoginFDD" style="width:252px;margin-top: 16px;" >免签授权</el-button>
      </div>
    </template>
    <template v-else-if="status === 2">
      <!-- //查看资质 -->
      <InfoDetail :disabled="true" />
      <div class="bottom-btn-layout">
        <el-button @click="handleChangeInfo">修改信息</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import InfoDetail from "./infoDetail.vue";
import { getCompanyAuthUrl } from "@/api/invoice";
import { queryShop } from "@/api/shopmange";
const IConst_Sucess_Icon = require("@/assets/paysuccess.png");
const IConst_Fail_Icon = require("@/assets/zz/fail-icon.png");
export default {
  components: { InfoDetail },
  data() {
    return {
      sucessIcon: IConst_Sucess_Icon,
      status: 1,
      linkUrl:'',
      linkStep:1,
      linkUrlLogin:'https://uat-cloud.fadada.com'
    };
  },
  created(){
    queryShop().then((resp)=>{
      if (resp.data && resp.data.id) {
        this.getCompanyAuthUrlAction(resp.data.id)
      }
    })
  },
  methods: {
    handleLook() {
      this.status = 2;
    },
    _copy(){
      navigator.clipboard.writeText('HSKF87394')
			.then(() => {
				this.$message.success('复制成功');
			})
			.catch(err => {
				this.$message.error('复制失败');
			});
    },
    handleGoLoginFDD(){
      // 电子合同登录
      window.open(this.linkUrlLogin,'_blank')
    },
    handleVarify(){
      // 电子合同认证
      if (this.linkUrl) {
        this.linkStep = 2
        window.open(this.linkUrl,'_blank')
      }else{
        this.$message.warning('打开链接失败')
      }
    },  
    getCompanyAuthUrlAction(shopId){
      const obj = {
        shopId
      }
      getCompanyAuthUrl(obj).then((resp)=>{
        if(resp){
          this.linkUrl = resp
        }
      })
    },
    handleExit() {
      this.$store.dispatch("qualification/setDetail",null);
      this.$store.dispatch("qualification/setSelectedCompanyTypes",null);
      this.$store.dispatch("user/logout").then(() => {
        this.$router.push({ path: "/login" });
      });
    },
    handleChangeInfo() {
      this.$emit("change-info");
    },
  },
};
</script>

<style scoped lang="scss">
.status-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  .pic-wrap {
    margin-top: 40px;
    width: 80px;
    height: 80px;
  }

  .big-tip {
    margin-top: 24px;
    font-size: 24px;
    font-weight: 500;
    color: #262626;
  }

  /**按钮层 */
  .bottom-btn-layout {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    ::v-deep .el-button {
      width: 120px;

      & + .el-button {
        margin-left: 12px;
      }
    }

    ::v-deep .el-button--danger {
      background-color: #f5222d;
      border-color: #f5222d;

      &:hover {
        background: #f78989;
        border-color: #f78989;
      }
    }
  }
  .step2{
    margin-top: 16px;
    span{
      font-size: 14px;
      font-weight: 600;
    }
    div{
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #595959;
      p{
        color: #f5222d;
        padding-left: 12px;
        cursor: pointer;
      }
    }
  }
}
</style>
