import request from "@/utils/request";

// 创建指标
export function contractTargetAdd(data) {
  return request({
    url: "/shop/hg/contractTarget/add",
    method: "POST",
    data: data,
  });
}

// 编辑指标
export function contractTargetEdit(data) {
  return request({
    url: "/shop/hg/contractTarget/edit",
    method: "POST",
    data: data,
  });
}

// 删除指标
export function contractTargetDelete(id) {
  return request({
    url: `/shop/hg/contractTarget/del/${id}`,
    method: "DELETE",
  });
}

// 查询指标列表
export function contractTargetPage(data) {
  return request({
    url: "/shop/hg/contractTarget/page",
    method: "POST",
    data: data,
  });
}

// 查询指标详情
export function contractTargetDetail(id) {
  return request({
    url: `/shop/hg/contractTarget/detail/${id}`,
    method: "GET",
  });
}

// 上传凭证
export function uploadingVoucher(data) {
  return request({
    url: "/shop/hg/contractTarget/uploadingVoucher",
    method: "POST",
    data: data,
  });
}

// 更新凭证
export function updateVoucher(data) {
  return request({
    url: "/shop/hg/contractTarget/updateVoucher",
    method: "POST",
    data: data,
  });
}

// 审核凭证
export function examineVoucher(data) {
  return request({
    url: "/shop/hg/contractTarget/examineVoucher",
    method: "POST",
    data: data,
  });
}

// 指标统计导出
export function exportContractTarget(data) {
  return request({
    url: "/shop/hg/contractTarget/export",
    method: "GET",
    responseType: "blob",
    params: data,
  });
}