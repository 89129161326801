
<!--  -->
<template>
  <div class="comContent">
    <div class="shourujilu">
      <span>待商业付款
        <span style="color:red">
          {{waitPay}}
        </span>
        条</span>
      <span style="margin-left:100px">审核未通过，待商业处理
        <span style="color:red">
          {{failAudit}}
        </span>
        条</span>
    </div>
    <div class="content">
      <header class="header">
        <div class="first">
          <p>佣金缴纳规则：</p>
          <span class="everySpan">1. 当企业信息中的佣金结算方式为“月结”时，系统每月1号凌晨生成上个月的佣金缴纳记录</span>
          <span class="everySpan">2. 针对“待商业付款”的记录需要您在约定的最后付款时间前足额缴纳，并上传付款凭证</span>
          <span class="everySpan">3. 针对“审核未通过”的记录需要您在约定的最后付款时间前处理完毕</span>
          <span class="everySpan">4. 佣金缴纳记录在约定时间内未处理完成，记录将标记为“已逾期”，若您存在已逾期的佣金缴纳记录，系统将限制您的申请提现功能，当佣金缴纳记录处理完成后，系统将自动恢复您的申请提现功能，为了保障您的使用体验，请按时足额缴纳佣金，并及时处理缴纳记录</span>
          <span class="everySpan">5. 请将“实际需缴纳佣金金额”转账至下面的平台对公账户，平台财务将在1个工作日内确认完成</span>
        </div>
        <div class="second">
          <div class="everySpan"><span>公司名称：</span> 湖南春播云药库科技有限公司</div>
          <div class="everySpan"><span>开户行： </span> 湖南春播云药库科技有限公司</div>
          <div class="everySpan"><span>银行账户：</span> 湖南春播云药库科技有限公司</div>
        </div>
      </header>
      <div class="title">
        <div class="detail">应缴纳佣金记录</div>
        <el-form ref="formTool"
                 :model="formTool"
                 :inline="true"
                 style="text-align:left;">
          <!-- <el-form-item prop="id">
            <el-input v-model="formTool.orderCode"
                      placeholder="请输入订单号"
                      size="small" />
          </el-form-item> -->
          <el-form-item prop="status">
            <el-select size="small"
                       v-model="auditType"
                       clearable
                       placeholder="状态">
              <el-option v-for="item in options"
                         :key="item.code"
                         :label="item.value"
                         :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item prop="date">
            <el-date-picker v-model="formTool.date"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            size="small"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期" />
          </el-form-item> -->
          <el-form-item>
            <MyButton type="primary"
                      text="查询"
                      @click="getcheckList(1)" />
          </el-form-item>
          <el-form-item>
            <MyButton type=""
                      text="重置"
                      @click="reset()" />
          </el-form-item>
          <!-- <el-form-item>
            <MyButton type="primary"
                      text="批量开票"
                      @click="watchBtn()" />
          </el-form-item> -->
        </el-form>
      </div>

      <el-divider />
      <div class="search_income">
        <!-- <div>
          收入：<span>{{ money }}</span>元
        </div>
        <div style="margin-left:100px">
          退款：<span>{{ money }}</span>元
        </div> -->
      </div>
      <div class="center">
        <MyTable :table-data="tableData"
                 class="myMoney"
                 :index="index"
                 :selection="selection"
                 :page="page"
                 :operation="operation"
                 :table-option.sync="tableOption"
                 @page-change="getcheckList">
          <template slot="amount"
                    slot-scope="scope">
            <span>￥{{scope.row.amount}}</span>
          </template>
          <template slot="discountAmount"
                    slot-scope="scope">
            <span>￥{{scope.row.discountAmount}}</span>
          </template>
          <template slot="actualAmount"
                    slot-scope="scope">
            <span>￥{{scope.row.actualAmount}}</span>
          </template>
          <template slot="pingzhneg"
                    slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="look(scope.row)"> 查看凭证</el-link>
          </template>
          <template slot="caozuo"
                    slot-scope="scope">
            <el-link type="primary"
                     :disabled="scope.row.status != '待商业付款' && scope.row.status != '审核未通过'"
                     :underline="false"
                     @click="audit(scope.row)"> 发起审核</el-link>
            <el-link type="primary"
                     :underline="false"
                     style="margin-left:10px"
                     @click="detail(scope.row)"> 查看明细</el-link>
          </template>
        </MyTable>
      </div>
    </div>
    <el-dialog :visible.sync="editorInvoice"
               title="选择发票抬头"
               width="500px">
      <el-form ref="invoiceForm"
               :model="invoiceForm"
               :rules="invoiceRules"
               label-width="120px">
        <el-form-item label="发票类型"
                      prop="opentype">
          <el-radio-group v-model="invoiceForm.opentype"
                          disabled>
            <el-radio :label="0">普票</el-radio>
            <el-radio :label="1">专票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="抬头类型"
                      prop="headerType">
          <el-radio-group v-model="invoiceForm.headerType">
            <el-radio :label="0">个人</el-radio>
            <el-radio :label="1">企业</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="发票抬头"
                      prop="buyerName">
          <el-select v-model="invoiceForm.buyerId"
                     style="width: 260px"
                     placeholder="请选择抬头"
                     @change="selectBtn"
                     value-key="id">
            <el-option v-for="(i, k) in selectedData"
                       :key="k"
                       :label="i.buyerName"
                       :value="i">
              <span style="float: left">{{ i.buyerName }}</span>
              <span v-if="i.preferred"
                    style="float: right; color: #F5222D; font-size: 12px">默认</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="纳税人识别号"
                      prop="name">
          <el-input v-model="invoiceForm.buyerTaxpayerId"
                    disabled
                    style="width: 260px" />
        </el-form-item>
        <p style="margin-bottom: 10px;margin-left: 60px;color: #f99b0c;cursor: pointer;"
           @click="needSelected">
          {{ needCan ? '收起非必要信息' : '展开非必要信息' }}
        </p>
        <div v-if="needCan">
          <el-form-item label="地址">
            <el-input v-model="invoiceForm.buyerAddress"
                      disabled
                      style="width: 260px" />
          </el-form-item>
          <el-form-item label="电话">
            <el-input v-model="invoiceForm.buyerRecipientPhone"
                      disabled
                      style="width: 260px" />
          </el-form-item>
          <el-form-item label="开户行">
            <el-input v-model="invoiceForm.buyerBank"
                      disabled
                      style="width: 260px" />
          </el-form-item>
          <el-form-item label="开户行账号">
            <el-input v-model="invoiceForm.buyerAccount"
                      disabled
                      style="width: 260px" />
          </el-form-item>
        </div>
        <el-form-item label="发票内容">
          <el-radio-group v-model="invoiceForm.desc">
            <el-radio :label="1">商品明细</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="邮箱"
                      prop="name">
          <el-input v-model="invoiceForm.buyerRecipientMail"
                    disabled
                    style="width: 260px" />
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button size="small"
                   @click="editorInvoice = false; invoiceForm = {};">取消</el-button>
        <el-button size="small"
                   type="primary"
                   @click="selectContent()">选择开票内容</el-button>
      </div>
    </el-dialog>
    <!-- 选择开票内容 -->
    <el-dialog title="选择开票内容"
               :visible.sync="dialogVisible"
               width="70%">
      <div class="detailTable">
        <MyTable :table-data="invoiceTbleData"
                 class="detailTableHeight"
                 :selection="invoiceSelection"
                 :page="invoicePage"
                 :operation="invoiceOperation"
                 :table-option.sync="invoiceTableOption"
                 @page-change="getcheckList"
                 @handleSelectionChange="handleSelectionChange">
        </MyTable>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button size="small"
                   @click="dialogVisible = false">取消</el-button>
        <el-button size="small"
                   type="primary"
                   @click="sureBtn">确定</el-button>
      </span>
    </el-dialog>
    <!-- 发起审核 -->
    <el-dialog title="付款备注"
               :visible.sync="payVisible"
               width="30%">
      <el-input type="textarea"
                placeholder="请输入内容"
                :disabled="pingzhengDisabled"
                v-model="textarea"
                maxlength="200"
                show-word-limit>
      </el-input>
      <ImgUpload v-model="imageList"
                 :isEdit="pingzhengDisabled"
                 :limit="3"
                 style="position:relative;top:-8px">
        <template slot="tip">
          <p class="el-upload__tip"
             style="margin:0px">
            备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过5M;最多上传三张图片
          </p>
        </template>
      </ImgUpload>
      <span slot="footer"
            class="dialog-footer"
            v-if="!pingzhengDisabled">
        <el-button size="small"
                   @click="payVisible = false">取消</el-button>
        <el-button size="small"
                   type="primary"
                   @click="submit">提交</el-button>
      </span>
    </el-dialog>
    <!-- 查看明细 -->
    <el-dialog title="明细"
               :visible.sync="detailVisible"
               width="50%">
      <div class="search_income">
        <div>
          收入：<span>￥{{ totalPayeeAmount }}</span>元
        </div>
        <!-- <div style="margin-left:100px">
          退款：<span>{{ money }}</span>元
        </div> -->
      </div>
      <div class="detailTable">
        <MyTable :table-data="detailTbleData"
                 class="detailTableHeight"
                 :selection="detailSelection"
                 :page="detailPage"
                 :operation="detailOperation"
                 :table-option.sync="detailTableOption"
                 @page-change="detailChange">
        </MyTable>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ImgUpload from '@/components/imgUpload'
import { getInvoiceHeader, riseOpen } from '@/api/servicecenter/pendingPayment.js'
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable'
import { drugenumeration } from '@/utils/enumeration'
// import { getcheckList } from '@/api/productManage/auditProduct'
import { commissionRecord, commissionAuditSubmit, commissionQuantity, payeeRecordPage, auditTypeList } from '@/api/financialcenter.js'
import dayjs from 'dayjs'
export default {
  components: { MyButton, MyTable, ImgUpload },
  data () {
    return {
      commissionDate: '',//审核时间
      totalPayeeAmount: '',
      failAudit: 0, // 审核未通过
      waitPay: 0, // 待付款
      auditId: '', // 当前行ID
      // 凭证禁用
      pingzhengDisabled: false,
      // 付款备注
      payVisible: false,
      textarea: '',
      imageList: [],

      // 发票表格内的内容
      detailVisible: false,
      detailSelection: false,
      detailPage: {
        total: 10,
        current: 1,
        size: 10
      },
      detailTbleData: [
        {
          id: 12321312,
          paySerialNo: 12321312,
          orderCode: 12321312,
          finishTime: 12321312,
          payAmount: 12321312,
        },
        {
          id: 12321312,
          paySerialNo: 12321312,
          orderCode: 12321312,
          finishTime: 12321312,
          payAmount: 12321312,
        },
        {
          id: 12321312,
          paySerialNo: 12321312,
          orderCode: 12321312,
          finishTime: 12321312,
          payAmount: 12321312,
        },
        {
          id: 12321312,
          paySerialNo: 12321312,
          orderCode: 12321312,
          finishTime: 12321312,
          payAmount: 12321312,
        },
        {
          id: 12321312,
          paySerialNo: 12321312,
          orderCode: 12321312,
          finishTime: 12321312,
          payAmount: 12321312,
        },
        {
          id: 12321312,
          paySerialNo: 12321312,
          orderCode: 12321312,
          finishTime: 12321312,
          payAmount: 12321312,
        }

      ],
      detailOperation: false,
      detailTableOption: [
        { label: '交易流水号', prop: 'paySerialNo' },
        { label: '订单号', prop: 'orderCode' },
        { label: '金额', prop: 'payAmount' },
        { label: '记录时间', prop: 'createTime' }
      ],

      // 发票表格内的内容
      invoiceSelection: true,
      invoicePage: {
        total: 10,
        current: 1,
        size: 10
      },
      invoiceTbleData: [
        {
          id: 12321312,
          paySerialNo: 12321312,
          orderCode: 12321312,
          finishTime: 12321312,
          payAmount: 12321312,
        },
        {
          id: 12321312,
          paySerialNo: 12321312,
          orderCode: 12321312,
          finishTime: 12321312,
          payAmount: 12321312,
        },
        {
          id: 12321312,
          paySerialNo: 12321312,
          orderCode: 12321312,
          finishTime: 12321312,
          payAmount: 12321312,
        },
        {
          id: 12321312,
          paySerialNo: 12321312,
          orderCode: 12321312,
          finishTime: 12321312,
          payAmount: 12321312,
        },
        {
          id: 12321312,
          paySerialNo: 12321312,
          orderCode: 12321312,
          finishTime: 12321312,
          payAmount: 12321312,
        },
        {
          id: 12321312,
          paySerialNo: 12321312,
          orderCode: 12321312,
          finishTime: 12321312,
          payAmount: 12321312,
        }

      ],
      invoiceOperation: false,
      invoiceTableOption: [
        { label: '佣金收取年份', prop: 'id', width: '120' },
        { label: '应缴纳佣金金额', prop: 'paySerialNo', width: '120' }, // 这里表示自定义列, slot: true
        { label: '佣金优惠金额', prop: 'orderCode', width: '120' },
        { label: '实际需缴纳佣金金额', prop: 'finishTime', width: '160' },
        { label: '生成时间', prop: 'finishTime', width: '120' },
        { label: '最后付款时间', prop: 'payAmount', width: '120' },
        { label: '是否逾期', prop: 'payAmount' },
        { label: '确认收款时间', prop: 'payAmount', width: '120' },
        { label: '状态', prop: 'payAmount' },
        { label: '备注/原因', prop: 'payAmount' },
      ],

      // 选择开票内容
      dialogVisible: false,
      invoiceObj: {},
      // 抬头列表
      selectedData: [],
      // 是否显示必填项目
      needCan: false,
      // 发票抬头弹窗的对象
      invoiceForm: { opentype: 0, headerType: 0, desc: 1 },
      // 发票抬头的弹窗
      editorInvoice: false,
      // 发票抬头的表单规则
      invoiceRules: {
        opentype: [
          { required: true, message: '请选择发票类型', trigger: 'change' }
        ],
        headerType: [
          { required: true, message: '请选择抬头类型', trigger: 'change' }
        ],
        buyerId: [
          { required: true, message: '请选择发票抬头', trigger: 'change' }
        ]
      },

      options: [],
      auditType: '',

      text: '',
      clearable: true,
      totalPayeeAmmount: '0.00',
      money: '0.00',
      formTool: {
        orderCode: '',
        date: []
      },
      operation: false,
      selection: false,
      index: false,
      tableData: [
      ],
      tableOption: [
        { label: '佣金收取年份', prop: 'commissionDate', width: '120' },
        { label: '应缴纳佣金金额', prop: 'amount', slot: true, width: '120' }, // 这里表示自定义列, slot: true
        { label: '佣金优惠金额', prop: 'discountAmount', slot: true, width: '120' },
        { label: '实际需缴纳佣金金额', prop: 'actualAmount', slot: true, width: '160' },
        { label: '生成时间', prop: 'initTime' },
        { label: '最后付款时间', prop: 'lastPayTime', width: '120' },
        { label: '是否逾期', prop: 'dlay' },
        { label: '付款凭证', prop: 'pingzhneg', slot: true },
        { label: '确认收款时间', prop: 'confirmTime', width: '120' },
        { label: '状态', prop: 'status' },
        { label: '备注/原因', prop: 'remark' },
        { label: '操作', prop: 'caozuo', slot: true, width: '160' },
      ],
      page: {
        total: 10,
        current: 1,
        size: 10
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.auditTypeList()
    // this.getcheckList()
    this.commissionQuantity()
    this.commissionRecord()
  },
  mounted () {

  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    // 审核状态列表
    auditTypeList () {
      auditTypeList().then(res => {
        if (res.code == 0) {
          this.options = res.data
        }
      })
    },
    // 详情分页
    detailChange () {
      this.detail()
    },
    // 佣金数量查询
    commissionQuantity () {
      commissionQuantity().then(res => {
        if (res.code == 0) {
          console.log(res)
          this.failAudit = res.data[0].count
          this.waitPay = res.data[1].count
        }
      })
    },
    // 发起审核
    submit () {
      if (this.imageList.length == 0) {
        this.$message.error('请上传付款凭证')
      } else {
        let params = {
          payCertificate: this.imageList.join(),
          id: this.auditId,
          remark: this.textarea
        }
        commissionAuditSubmit(params).then(res => {
          if (res.code == 0) {
            this.$message.success('成功发起审核')
            this.commissionRecord()
          }
        })
        this.payVisible = false
      }
    },
    // 选择开票内容
    selectContent () {
      console.log(this.invoiceForm, 'selectContent')
      this.editorInvoice = false
      this.dialogVisible = true
    },
    // 选择抬头
    selectBtn (val) {
      const that = this
      this.selectedData.forEach((res) => {
        if (res == val) {
          that.invoiceForm = res
          that.invoiceForm.buyerId = res
          this.$set(this.invoiceForm, 'opentype', 0)
          this.$set(this.invoiceForm, 'desc', 1)
          this.$set(this.invoiceForm, 'headerType', 0)
        }
      })
    },
    // 收起非必填
    needSelected () {
      this.needCan = !this.needCan
    },
    // 申请开票
    watchBtn () {
      // if (i.serialStatus == 'OPEN') {
      getInvoiceHeader().then((res) => {
        this.selectedData = res.data
        if (this.selectedData.length > 0) {
          this.invoiceForm = this.selectedData[0]
          this.$set(this.invoiceForm, 'opentype', 0)
          this.$set(this.invoiceForm, 'desc', 1)
          this.$set(this.invoiceForm, 'headerType', 0)
          this.$set(this.invoiceForm, 'buyerId', this.selectedData[0])
        }
      })
      // }
      // this.invoiceObj = i
      this.editorInvoice = true
    },
    // 申请开票确定按钮
    sureBtn () {
      this.$refs['invoiceForm'].validate((valid) => {
        if (valid) {
          console.log(this.invoiceForm, 'this.invoiceForm')
          riseOpen({
            id: this.invoiceObj.id,
            riseId: this.invoiceForm.buyerId,
            invoiceType: this.invoiceForm.opentype,
            openType: this.invoiceForm.headerType
          }).then((res) => {
            this.queryTableData(1)
          })
        } else {
          return false
        }
      })
    },
    // 查看凭证
    look (row) {
      this.textarea = row.remark
      this.imageList = row.payCertificate ? row.payCertificate.split(',') : []
      this.pingzhengDisabled = true
      this.payVisible = true
    },
    // 审核
    audit (row) {
      this.textarea = ''
      this.imageList = []
      this.pingzhengDisabled = false
      this.payVisible = true
      this.auditId = row.id
    },
    // 查看明细
    detail (row) {
      this.detailPage.current = row ? 1 : this.detailPage.current
      this.commissionDate = row ? row.commissionDate : this.commissionDate
      this.detailVisible = true
      console.log(this.commissionDate)
      let params = {
        current: this.detailPage.current,
        size: this.detailPage.size,
        orderCode: '',
        status: '',
        startDate: '',
        endDate: '',
      }
      params.startDate = this.commissionDate + '-01'
      let nextMonth = dayjs(params.startDate).get('month') < 11 ? dayjs(params.startDate).get('month') + 2 : 1
      let nextYear = dayjs(params.startDate).get('month') < 11 ? dayjs(params.startDate).get('year') : dayjs(params.startDate).get('year') + 1
      params.endDate = nextYear + '-' + (nextMonth < 10 ? '0' + nextMonth : nextMonth) + '-' + '01'
      console.log(params)
      payeeRecordPage(params).then(res => {
        if (res.code == 0) {
          console.log(res)
          this.detailTbleData = res.data.pageData.records
          this.detailPage.total = res.data.pageData.total
          this.totalPayeeAmount = res.data.totalPayeeAmount
        }
      })
    },
    commissionRecord () {
      let params = {
        current: 1, // 页码
        size: 10, // 分页大小
        auditType: this.auditType,
        // orderCode: this.formTool.orderCode, // 订单id
        // startDate: !this.formTool.date || this.formTool.date.length == 0 ? '' : this.formTool.date[0], // 开始时间
        // endDate: !this.formTool.date || this.formTool.date.length == 0 ? '' : this.formTool.date[1] // 结束时间
      }
      // this.loadingBtn = true
      commissionRecord(params).then(res => {
        if (res.code === 0) {
          if (res.data.records) {
            this.tableData = res.data.records
          } else {
            this.tableData = []
          }
          this.page.total = res.data.total
          // this.totalPayeeAmmount = res.data.totalPayeeAmmount
          // if (this.tableData.length > 0) {
          //   this.tableData.forEach(item => {
          //     item.type = drugenumeration(item.type)
          //     let a = 0
          //     this.tableData.forEach(item => {
          //       a = (a * 100 + item.realAmount * 100) / 100
          //     })
          //     this.money = a.toFixed(2)
          //   })
          // } else {
          //   this.money = 0
          // }
        }
        // setTimeout(() => {
        //   // this.loadingBtn = false
        // }, 500)
      })
        .catch(() => {
          // this.loadingBtn = false
        })
    },
    getcheckList (current) {
      if (current) {
        this.page.current = current
      }
      const params = {
        current: this.page.current,
        size: this.page.size,
        auditType: this.auditType,
        // orderCode: this.formTool.orderCode, // 订单id
        // startDate: !this.formTool.date || this.formTool.date.length == 0 ? '' : this.formTool.date[0], // 开始时间
        // endDate: !this.formTool.date || this.formTool.date.length == 0 ? '' : this.formTool.date[1] // 结束时间
      }
      commissionRecord(params).then(res => {
        if (res.code == 0) {
          if (res.data.records) {
            this.tableData = res.data.records
          } else {
            this.tableData = []
          }
          this.page.total = res.data.total
          // this.totalPayeeAmmount = res.data.totalPayeeAmmount
          // if (this.tableData.length > 0) {
          //   this.tableData.forEach(item => {
          //     item.type = drugenumeration(item.type)
          //     let a = 0
          //     this.tableData.forEach(item => {
          //       a = (a * 100 + item.realAmount * 100) / 100
          //     })
          //     this.money = a.toFixed(2)
          //   })
          // } else {
          //   this.money = 0
          // }
        }
      })
    },
    reset () {
      this.auditType = ''
      // this.formTool.orderCode = ''
      // this.formTool.date = []
      // this.getcheckList()
    },
    handleSelectionChange (val) {
      console.log(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.comContent {
  padding: 0px;
}
.shourujilu {
  height: 56px;
  line-height: 56px;
  font-weight: 600;
  color: #262626;
  font-size: 16px;
  padding: 0 24px;
  border-bottom: 1px solid #f5f5ff;
}
.content {
  margin: 0 24px;
  height: calc(100% - 80px);
}
.header {
  // display: flex;
  padding-top: 12px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  padding-bottom: 20px;
  // border-bottom: 1px solid;
  .first .everySpan {
    display: block;
    color: #595959;
    font-family: PingFangSC !important;
    line-height: 21px;
  }
  .second {
    // width: 200px;
    padding-top: 10px;
    .everySpan {
      font-size: 16px;
      display: block;
      color: red;
      span {
        vertical-align: middle;
        display: inline-block;
        width: 80px !important;
        text-align: justify;
        text-align-last: justify;
      }
    }
  }
  .total {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #8c8c8c;
    padding: 20px 0px;
  }
  .number {
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #f6212d;
    .xiaosu {
      font-size: 24px;
    }
  }
}
.search_income {
  display: flex;
  margin: 15px 0;
  font-size: 14px;
  color: #8c8c8c;
  text-align: left;
  div {
    width: 200px;
  }
  span {
    font-size: 20px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    color: #f6212d;
  }
}
.comContent {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-form-item {
      margin-bottom: 0px !important;
    }
    .detail {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }
  }
  .center {
    // height: calc(100% - 215px);
    ::v-deep .myMoney {
      .el-table__body td:last-child {
        color: red;
      }
    }
  }
}
.detailTable {
  height: 500px;
  overflow: auto;
  .detailTableHeight {
    height: calc(100% - 12px);
  }
}
</style>
