var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: !_vm.form.menuId ? "新增" : "编辑",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { key: "aa1", attrs: { label: "菜单类型", prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "0" } }, [
                            _vm._v("菜单"),
                          ]),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("按钮"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { key: "aa2", attrs: { label: "上级菜单" } },
            [
              _c("el-cascader", {
                attrs: {
                  size: "small",
                  props: { checkStrictly: true },
                  options: _vm.menuOptions,
                },
                model: {
                  value: _vm.form.parentId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "parentId", $$v)
                  },
                  expression: "form.parentId",
                },
              }),
            ],
            1
          ),
          _vm.form.type === "0"
            ? _c(
                "el-form-item",
                { key: "aa3", attrs: { label: "图标", prop: "icon" } },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom-start",
                        width: "750",
                        trigger: "click",
                      },
                      on: {
                        show: function ($event) {
                          return _vm.$refs["iconSelect"].reset()
                        },
                      },
                    },
                    [
                      _c("IconSelect", {
                        ref: "iconSelect",
                        on: { selected: _vm.selected },
                      }),
                      _c(
                        "el-input",
                        {
                          attrs: {
                            slot: "reference",
                            size: "small",
                            placeholder: "点击选择图标",
                          },
                          slot: "reference",
                          model: {
                            value: _vm.form.icon,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "icon", $$v)
                            },
                            expression: "form.icon",
                          },
                        },
                        [
                          _vm.form.icon && !_vm.form.icon.includes("el-icon")
                            ? _c("svg-icon", {
                                staticClass: "el-input__icon",
                                staticStyle: {
                                  height: "20px",
                                  width: "16px",
                                  "margin-top": "12px",
                                  "margin-left": "4px",
                                },
                                attrs: {
                                  slot: "prefix",
                                  "icon-class": _vm.form.icon,
                                },
                                slot: "prefix",
                              })
                            : _vm._e(),
                          _vm.form.icon && _vm.form.icon.includes("el-icon")
                            ? _c("i", {
                                class: [
                                  _vm.form.icon,
                                  "sub-el-icon",
                                  "el-input__icon",
                                ],
                                attrs: { slot: "prefix" },
                                slot: "prefix",
                              })
                            : _vm._e(),
                          !_vm.form.icon
                            ? _c("i", {
                                staticClass: "el-icon-search el-input__icon",
                                attrs: { slot: "prefix" },
                                slot: "prefix",
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { key: "aa4", attrs: { label: "菜单名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入菜单名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _vm.form.type !== "1"
            ? _c(
                "el-form-item",
                {
                  key: "aa5",
                  attrs: { size: "small", label: "路由地址", prop: "path" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入路由地址" },
                    model: {
                      value: _vm.form.path,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "path", $$v)
                      },
                      expression: "form.path",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type === "1"
            ? _c(
                "el-form-item",
                {
                  key: "aa6",
                  attrs: { label: "权限标识", prop: "permission" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入权限标识",
                      maxlength: "50",
                    },
                    model: {
                      value: _vm.form.permission,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "permission", $$v)
                      },
                      expression: "form.permission",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { key: "aa7", attrs: { label: "排序", prop: "sortOrder" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          size: "small",
                          "controls-position": "right",
                          min: 0,
                          step: 1,
                        },
                        model: {
                          value: _vm.form.sortOrder,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sortOrder", $$v)
                          },
                          expression: "form.sortOrder",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _vm.form.type !== "1"
                    ? _c(
                        "el-form-item",
                        {
                          key: "aa8",
                          attrs: { label: "路由缓冲", prop: "keepAlive" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.form.keepAlive,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "keepAlive", $$v)
                                },
                                expression: "form.keepAlive",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "0" } }, [
                                _vm._v("否"),
                              ]),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("是"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      key: "aa9",
                      attrs: { label: "显示在菜单", prop: "keepAlive" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.form.showState,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "showState", $$v)
                            },
                            expression: "form.showState",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "0" } }, [
                            _vm._v("显示"),
                          ]),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("不显示"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.dataFormSubmit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }