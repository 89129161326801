import request from '@/utils/request'

// 查询商铺下的所有建议
export function list(data) {
  return request({
    url: '/shop/compliance/compadviseinfo/list',
    method: 'get',
    params: data
  })
}

// ops查看列表
export function page(data) {
  return request({
    url: '/shop/compliance/compadviseinfo/page',
    method: 'get',
    params: data
  })
}

// 新增建议
export function insert(data) {
  return request({
    url: '/shop/compliance/compadviseinfo/insert',
    method: 'post',
    data
  })
}