<template>
  <div class="shop-info-root tab-content new-cls">
    <el-form :rules="rules" :model="formIn" ref="shopForm">
      <el-row>
        <div class="form form-top">
          <div class="lable-tips">
            <el-tag type="warning" v-if="formIn.auditStatus == 'PRESERVE' ">店铺信息和店铺公告正在审核中</el-tag>
            <el-tag type="danger"  v-if="formIn.auditStatus == 'NOT_PASS'">审核驳回</el-tag>
            <!-- <el-tag type="success" v-if="formIn.auditStatus == 'PASS' ">审核通过</el-tag> -->
          </div>
          <!-- auditStatus -->
          <div class="row" v-if="formIn.auditStatus == 'NOT_PASS'">
            <div class="tip">驳回原因</div>
            <div class="value input  big-w">
              <el-input autosize placeholder="" v-model="formIn.remark" :disabled="true" type="textarea" />
            </div>
          </div>
          <div class="row">
            <!-- <div class="tip">店铺名称</div> -->
            <el-form-item prop="shopName" label="店铺名称" style="width: 500px;" label-width="80px">
              <el-input size="small" placeholder="请输入" v-model="formIn.shopName" maxlength="20"
                style="width: 400px; margin-left: 10px;" />
            </el-form-item>
          </div>
          <div class="h-dot-line"></div>
        </div>
      </el-row>
      <el-row>

        <el-col :span="12">
          <div class="form ">
            <div class="row multi">
              <div class="tip">店铺Logo</div>
              <div class="value fill">
                <div class="public-pic-layout">
                  <div v-if="formIn.shopLogo" class="item">
                    <el-image :ref="`myImgLogo`" :src="formIn.shopLogo" fit="cover" class="icon" :initial-index="0"
                      :preview-src-list="[formIn.shopLogo]" />
                    <div class="preview">
                      <el-image :src="SeeIcon" class="btn-icon" @click="handlePreview(`myImgLogo`, false)" />
                      <el-image :src="DelIcon" class="btn-icon" @click="handleDeleteImage('logo')" />
                    </div>
                  </div>
                  <div v-else class="item add-pic" @click="handleUpload('logo')">
                    <div class="image-wrap">
                      <el-image :src="AddIcon" />
                    </div>
                    <span class="text">上传图片</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="tip">客服电话</div>
              <div class="value input  big-w">
                <el-input size="small" placeholder="请输入" v-model="formIn.shopServiceTel" maxlength="15" />
              </div>
            </div>
            <div class="row">
              <div class="tip">公司名称</div>
              <div class="value input  big-w">
                <el-input size="small" placeholder="请输入" v-model="formIn.shopCompanyName" maxlength="30" />
              </div>
            </div>
            <div class="row">
              <div class="tip">公司地址</div>
              <div class="value input  big-w">
                <el-input size="small" placeholder="请输入" v-model="formIn.shopCompanyAddress" maxlength="50" />
              </div>
            </div>
            <div class="row">
              <div class="tip">运营负责人</div>
              <div class="value input  big-w">
                <el-input size="small" placeholder="请输入" v-model="formIn.shopHeadOperations" maxlength="15" />
              </div>
            </div>
            <div class="row">
              <div class="tip">联系电话</div>
              <div class="value input  big-w">
                <el-input size="small" placeholder="请输入" v-model="formIn.shopContactNumber" maxlength="15" />
              </div>
            </div>
            <div class="row">
              <div class="tip">ERP编码</div>
              <div class="value input  big-w">
                <el-input size="small" placeholder="请输入" v-model="formIn.shopErpCode" maxlength="20" />
              </div>
            </div>
            <!-- 下面的是用来显示，不能修改 -->
            <div class="row">
              <div class="tip">经营范围</div>
              <div class="scope-list">
                <!-- <el-input size="small" placeholder="请输入" /> -->
                <span v-for="(item, index) in shopDetail && shopDetail.businessScopeList
                  ? shopDetail.businessScopeList
                  : []" :key="index">
                  {{ item.name }}</span>
              </div>
            </div>
 
            <div class="row multi">
              <div class="tip">公司证件</div>
              <div class="public-pic-layout-cert">
                <div class="item" v-for="(item, index) in shopDetail && 
                  shopDetail.certificateInformationVOList
                  ? shopDetail.certificateInformationVOList
                    : []" :key="index">
                  <el-image :ref="`myImg${index}`" :src="item.certificateImage" fit="cover" class="icon"
                  style="width: 100%; height:100%"
                    :initial-index="0" :preview-src-list="[item.certificateImage]" />
                  <div class="preview">
                    <el-image :src="SeeIcon" class="btn-icon" @click="handlePreview(`myImg${index}`, true)" />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </el-col>
        <el-col :span="12">
          <div class="form-rt">
            <el-form-item label="销售政策与条款" style="width: 615px" label-width="120px">
              <el-input type="textarea" v-model="formIn.shopExtendInfo.orderDealTimeRule" maxlength="50" show-word-limit
                resize="none" :rows="4" placeholder="例如：工作日早八点半，晚5点半，非工作日不处理。"></el-input>
            </el-form-item>
            <el-form-item label="配送政策" style="width: 615px" label-width="120px">
              <el-input type="textarea" v-model="formIn.shopExtendInfo.sendRule" maxlength="50" show-word-limit
                resize="none" :rows="4" placeholder="例如：每天15点前的订单当天发，15点后的订单第二天发，周六日节假日正常发货"></el-input>
            </el-form-item>
            <el-form-item label="企业介绍" style="width: 615px" label-width="120px">
              <el-input type="textarea" v-model="formIn.shopExtendInfo.shopDesc" maxlength="200" show-word-limit
                resize="none" :rows="6" placeholder="例如：电子版开户，无需提供纸质资质，发票随货同行。"></el-input>
            </el-form-item>
            <el-form-item label="店铺公告" style="width: 615px" label-width="120px">
              <el-input type="textarea" v-model="formIn.shopExtendInfo.shopAnnouncement" maxlength="200" show-word-limit
                resize="none" :rows="6" placeholder="例如：店铺公告:本公司于9月2日至9月24日升级系统，升级期间暂停发货。"></el-input>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div class="bottom-btn">
      <el-button v-if="showSave" class="fix-four" @click="doUpdateInfo('PRESERVE')">保存</el-button>
      <el-button type="primary" class="fix-four" @click="doUpdateInfo('NOT_PASS')">提交审核</el-button>
    </div>

    <UploadImageView ref="imgupload" request-id="1" :imgType="imgType" @upload="uploadSubmit" />
  </div>
</template>

<script>
import UploadImageView from "@/views/myQualification/components/UploadImage.vue";

const IConst_Small = require("@/assets/public/icon-small.png");
const IConst_See_Icon = require("@/assets/zz/see-icon.png");
const IConst_Del_Icon = require("@/assets/zz/del-icon.png");
const IConst_Add_Icon = require("@/assets/zz/add-icon.png");

import { getShipInfor, updateShipInfor, shopInformationInfoNew, saveOrUpdateInformation } from "@/api/shop";

import _ from "lodash";

export default {
  components: { UploadImageView },
  data() {
    return {
      SeeIcon: IConst_See_Icon,
      DelIcon: IConst_Del_Icon,
      AddIcon: IConst_Add_Icon,

      imgType: "",
      IConst_Max_Pic_Count: 5,
      imageUrlList: [],

      shopDetail: {

      },

      formIn: {
        remark: '',//驳回原因
        auditStatus: "", //状态码
        shopId: "",
        shopName: "", //店铺名称
        shopLogo: "", //店铺logo
        shopServiceTel: "", // 客户电话
        shopCompanyName: "", //店铺公司名称
        shopCompanyAddress: "", //店铺公司地址
        shopHeadOperations: "", //店铺运营负责人
        shopContactNumber: "", //联系电话
        shopErpCode: "", //店铺erp编码
        shopExtendInfo: {}
      },
      rules: {
        shopName: [{ required: true, message: "请输入店铺名称", trigger: "blur" }],
      }
    };
  },
  created() {
    this.doLoadInfo();
  },
  computed: {
    showSave() {
      return !this.formIn.auditStatus || this.formIn.auditStatus === "PRESERVE";
    },
  },
  methods: {
    doLoadInfo() {
      shopInformationInfoNew().then((data) => {
        if (data) {
          this.shopDetail = data; 
          for (let key in data) {
            if (this.formIn.hasOwnProperty(key) && data[key]) {
              this.formIn[key] = data[key];
            }
          }
        }
      });
    },
    doUpdateInfo(auditStatus) {
      this.$refs['shopForm'].validate((valid) => {
        if (valid) {
          let data = {};
          _.merge(data, this.formIn);
          // if (auditStatus) {
          //   data.auditStatus = auditStatus;
          // }
          data.shopExtendInfo = this.formIn.shopExtendInfo
          saveOrUpdateInformation(data).then((res) => {
            if (res) {
              if (auditStatus === "NOT_PASS") {
                this.doLoadInfo();
                this.$message.success("提交成功");
              } else {
                this.$message.success("保存成功");
              }
            }
          });
        }
      })
      // if(!this.formIn.shopName){
      //   this.$message.warning("请填写店铺名称！");
      //   return
      // }

    },
    handleUpload(tag) {
      this.$refs.imgupload.open(tag);
    },
    uploadSubmit(obj) {
      const { type, url } = obj;
      if (type === "logo") {
        this.formIn.shopLogo = url;
      }
    },
    handlePreview(refName) {
      if (this.$refs[refName]) {
        if (this.$refs[refName] instanceof Array) {
          this.$refs[refName][0].showViewer = true;
        } else {
          this.$refs[refName].showViewer = true;
        }
      }
    },
    handleDeleteImage(tag) {
      if (tag === "logo") {
        this.formIn.shopLogo = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
.lable-tips {
  margin-left: 90px;
}
.public-pic-layout-cert {
  width: 500px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left:10px;
  .item {
   width: 86px;
   height: 86px;
   margin:  5px;
   background: #ffffff;
   border: 1px solid #d9d9d9;
   border-radius: 4px; 
   overflow: hidden;
  }
}
</style>
