<template>
  <div class="">
    <div class="search-header">
      <div class="row">
        <div class="btn" @click="handleBack">
          <el-image :src="backIcon" class="back-icon" />
          返回活动类型
        </div>
        <div class="centern">
          <span>搜索商品的</span>
          <span class="weight">名称/厂家/批准文号</span>
        </div>
      </div>
      <div class="row">
        <div class="search-frame">
          <el-image :src="searchIcon" class="search-icon" />
          <div class="input-frame">
            <el-input />
          </div>
          <div class="search-btn">搜索</div>
        </div>
      </div>
    </div>
    <div class="big-title">
      <span class="txt">厂家 (12)</span>
    </div>
    <div class="grid">
      <div
        :class="{ item: true, focus: index === 1 }"
        v-for="(item, index) in [1, 1, 1, 1, 1, 1, 1, 1]"
        :key="index"
      >
        广西宝瑞坦制药有限公司广西宝瑞坦制药有限公司
      </div>
    </div>
    <div class="big-title">
      <span class="txt">商品 (12)</span>
      <span class="small-tip h-gap">若未能满足您的上架需求，可点击</span>
      <span class="small-tip add-btn" @click="addProduct()">发布商品信息</span>
    </div>
    <div ref="tableCont" class="contentCont">
      <div class="contentContinner">
        <productItem
          v-for="(item, idnex) in tableData"
          :key="idnex"
          :item="item"
          @click.native="handleShowDetail(idnex)"
        />
      </div>
    </div>
    <div class="el-page">
      <Pagination
        :total="page.total"
        :page.sync="page.current"
        :limit.sync="page.size"
        :page-sizes="pageSizes"
        @pagination="pageChange"
      />
    </div>

    <ProductDetailDialog
      ref="refProductDetailDialog"
      @confirm="handleConfirm()"
    />
  </div>
</template>

<script>
import productItem from "./productItem.vue";
import MyButton from "@/components/myButton";
import Pagination from "@/components/Pagination";
import {
  getNotcheckList,
  queryFactory,
} from "@/api/productManage/publishProduct";
import { goodsclassOne, goodsclassTwo } from "@/api/commodityreference";
import { mapGetters } from "vuex";
import ProductDetailDialog from "../dialog/ProductDetailDialog.vue";

const ICONST_backIcon = require("@/assets/public/back.png");
const ICONST_searchIcon = require("@/assets/public/search_icon.png");

export default {
  components: { MyButton, Pagination, productItem, ProductDetailDialog },
  data() {
    return {
      backIcon: ICONST_backIcon,
      searchIcon: ICONST_searchIcon,

      text: "",
      clearable: true,
      goodsOneClassIdOptions: [],
      goodsTwoClassIdOptions: [],
      goodsThreeClassIdOptions: [],
      page: {
        current: 1,
        size: 10,
        total: 10,
      },
      formTool: {
        goodsOneClassId: "",
        goodsTwoClassId: "",
        goodsThreeClassId: "",
        name: "", // 药品名称
        factory: "", // 厂家关键字
        approvalNumber: "", // 批准号
        keyword: "",
      },
      factoryList: [],
      tableData: [],
      pageSizes: [],
      widthNum: 0,
      heightNum: 0,
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
  watch: {
    "sidebar.opened": {
      handler(newVal, oldVal) {
        this.widthNum = Math.floor(
          (this.$refs.tableCont.clientWidth - 48) / 222
        );
      },
    },
  },
  mounted() {
    // 屏幕尺寸变化就重新赋值
    this.setSize();
  },
  methods: {
    handleBack() {
      this.$emit("preStep");
    },
    handleShowDetail(index) {
      const item = this.tableData[index];

      this.$refs.refProductDetailDialog.open(item);
    },
    handleConfirm(index) {
      this.$emit("nextStep");
    },
    // 根据关键词查询生产厂家列表
    queryFactoryFn() {
      if (!this.formTool.keyword) return;
      const params = {
        isSalesRule: 0,
        ...this.formTool,
      };
      queryFactory(params).then((res) => {
        if (res) {
          this.factoryList = res;
        } else {
          this.factoryList = [];
        }
      });
    },
    clearGoodsFactroy() {
      this.factoryList = [];
      this.formTool.keyword = "";
      this.page.current = 1;
      this.getList();
    },
    setSize(e) {
      // this.widthNum = Math.floor((this.$refs.tableCont.clientWidth - 48) / 222);
      // this.heightNum = Math.ceil(this.$refs.tableCont.clientHeight / 332);
      // this.page.size = this.widthNum * this.heightNum;
      this.pageSizes = [
        this.page.size,
        this.page.size * 2,
        this.page.size * 3,
        this.page.size * 4,
      ];
      this.getList();
      this.query();
    },
    // 初次进来只查询一级分类 不联动
    query() {
      goodsclassOne()
        .then((res) => {
          if (res.code == 0) {
            this.goodsOneClassIdOptions = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换一级分类
    changeOneClassId(val) {
      if (val) {
        this.goodsclassTwo(val);
      }
    },
    // 切换二级分类
    changeTwoClassId(val) {
      if (val) {
        this.goodsclassThree(val);
      }
    },
    // 查询一级分类
    goodsclassOne() {
      goodsclassOne()
        .then((res) => {
          if (res.code == 0) {
            this.goodsOneClassIdOptions = res.data;
            if (this.goodsOneClassIdOptions.length > 0) {
              this.formTool.goodsOneClassId = this.goodsOneClassIdOptions[0].id;
              // this.goodsclassTwo(this.goodsOneClassIdOptions[0].id)
            } else {
              this.formTool.goodsOneClassId = "";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询二级分类
    goodsclassTwo(id) {
      goodsclassTwo(id)
        .then((res) => {
          if (res.code == 0) {
            this.goodsTwoClassIdOptions = res.data;
            if (this.goodsTwoClassIdOptions.length > 0) {
              this.formTool.goodsTwoClassId = "";
              this.formTool.goodsThreeClassId = "";
              this.goodsThreeClassIdOptions = [];
              // this.goodsclassThree(this.goodsTwoClassIdOptions[0].id)
            } else {
              this.formTool.goodsTwoClassId = "";
              this.formTool.goodsThreeClassId = "";
              this.goodsThreeClassIdOptions = [];
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询三级分类
    goodsclassThree(id) {
      goodsclassTwo(id)
        .then((res) => {
          if (res.code == 0) {
            this.goodsThreeClassIdOptions = res.data;
            if (this.goodsThreeClassIdOptions.length > 0) {
              this.formTool.goodsThreeClassId = "";
            } else {
              this.formTool.goodsThreeClassId = "";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleAddbuy(item) {
      // 加入购物车
      this.$router.push({
        path: "/productManage/publishProduct-shopingcart",
        query: item,
      });
    },
    pageChange(value) {
      this.page.size = value.limit;
      this.page.current = value.page;
      this.getList();
    },
    reset() {
      this.$refs.formTool.resetFields();
      this.goodsTwoClassIdOptions = [];
      this.goodsThreeClassIdOptions = [];
      this.formTool.keyword = "";
      this.factoryList = [];
      this.getList(1);
    },
    addProduct(row) {
      console.log(row);
      // this.$router.push('/productManage/publishProduct-addProduct')
      this.$router.push("/productManage/auditProduct-addGoods");
    },
    getList(current) {
      if (current) {
        this.page.current = current;
      }
      const params = {
        current: this.page.current ? this.page.current : 1,
        size: this.page.size ? this.page.size : 10,
        isSalesRule: 0,
        ...this.formTool,
      };
      getNotcheckList(params).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    handleSelectionChange(val) {
      console.log(val);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";

.comContent {
  height: calc(100vh - 130px);
  padding: 0px;

  .search-header {
    padding: 32px 0 16px 0;
    box-sizing: border-box;

    .row {
      margin: 0 auto;
      width: 600px;
      display: flex;
      align-items: center;

      .btn {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #8c8c8c;
        cursor: pointer;

        .back-icon {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }

      .centern {
        margin-left: 52px;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        color: #595959;
        line-height: 28px;

        .weight {
          color: #f5222d;
          margin-left: 4px;
        }
      }

      .search-frame {
        margin-top: 12px;
        width: 100%;
        height: 40px;
        border-radius: 50%;
        border: 2px solid #ff4d4f;
        border-radius: 22px;
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08),
          0px 3px 6px -4px rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;

        .search-icon {
          width: 24px;
          height: 24px;
          margin-left: 16px;
        }

        .input-frame {
          flex: 1;

          ::v-deep .el-input__inner {
            border: 0;
            height: 100%;
          }
        }

        .search-btn {
          width: 100px;
          height: 40px;
          background: #f5222d;
          border-radius: 0px 20px 20px 0px;
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
}

.big-title {
  height: 56px;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  align-items: center;

  .txt {
    font-size: 16px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }

  .small-tip {
    font-size: 14px;
    font-weight: 400;
    color: #595959;

    &.add-btn {
      color: #f5222d;
      cursor: pointer;
    }
  }
}

.h-gap {
  margin-left: 8px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  padding-bottom: 24px;
  box-sizing: border-box;

  .item {
    width: calc(20% - 12px);
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #595959;
    margin-left: 12px;
    margin-top: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;

    &.focus {
      color: #f5222d;
    }
  }
}

.contentCont {
  // height: calc(100% - 210px);
  padding: 0px 24px 0 16px;
  // overflow: auto;
  display: flex;
  justify-content: flex-start;

  .contentContinner {
    width: 100%;
    display: flex;
    margin: 0x auto;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  // .contentInner {
  //   height: 100%;
  //   display: flex;
  //   justify-content: flex-start;
  //   flex-wrap: wrap;
  //   .itemCont {
  //     width: 18%;
  //     min-width: 200px;
  //     height: 330px;
  //     border: 1px solid #ccc;
  //     margin-right: 20px;
  //     margin-bottom: 10px;
  //     .name {
  //       display: block !important;
  //       font-size: 16px;
  //       font-weight: 600;
  //     }
  //     .factory {
  //       color: #bbb;
  //     }
  //     .itemBox {
  //       padding: 3px 10px;
  //       display: flex;
  //       justify-content: flex-start;
  //       white-space: nowrap;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //     }
  //     .itemImg {
  //       height: 180px;
  //       width: 100%;
  //       display: flex;
  //       justify-content: center;
  //     }
  //     .price {
  //       font-weight: 700;
  //       justify-content: end;
  //     }
  //     .shopCar {
  //       margin-top: 20px;
  //       justify-content: center;
  //     }
  //   }
  // }
}

.el-page {
  position: relative;
  float: right;
  padding: 10px 24px 0 24px !important;
}
</style>
