<template>
  <div class="content">
    <Tabs :tabsList="tabsList" style="padding: 0 24px" :activeName="activeVal" @tab-click="handleChange">
    </Tabs>
    <!-- 支付设置 -->
    <PayTypeSetting v-if="activeVal == '-99'" :boundMoney="boundMoney" />
    <!-- 商铺信息 -->
    <ShopInfoView v-if="activeVal == '-10'" />
    <!-- 开户设置 -->
    <NewAccountView v-if="activeVal == '-9'" />
    <!-- 发货配置设置 -->
    <SendConfigView v-if="activeVal == '-8'" :boundMoney="boundMoney" />
    <!-- 售后设置 -->
    <AfterSaleConfigView v-if="activeVal == '-7'" :allAreaCode="allAreaCode" />
    <!-- 店铺公告 -->
    <Announcement ref="Announcement" v-if="activeVal == '1'"></Announcement>
    <!-- 运费设置 -->
    <div v-if="activeVal == '2'">
      <div class="freight-condition">
        <el-form :inline="true" size="small" :model="searchFrom" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="searchFrom.feeName" style="width: 200px" placeholder="请输入运费名称"
              @input="searchHandle"></el-input>
          </el-form-item>
          <el-form-item v-if="0">
            <el-select v-model="searchFrom.feeType" style="width: 200px" placeholder="请选择运费类型" @change="searchHandle"
              clearable>
              <el-option v-for="item in [
                { label: '商家承担运费 ', value: 0 },
                { label: '自定义运费', value: 1 },
              ]" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker size="small" style="margin-right: 10px" v-model="searchFrom.createTime" @change="searchHandle"
              format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="daterange" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-plus" @click="handleFreight">新增运费</el-button>
          </el-form-item>
        </el-form>
      </div>

      <Freight ref="Freight" v-if="activeVal == '2'"></Freight>
    </div>
    <!-- 售后地址 -->
    <div v-if="activeVal == '3'">
      <div class="freight-condition">
        <el-form :inline="true" size="small" :model="searchFrom" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="searchFromAfterMarket.receiver" style="width: 200px" placeholder="请输入收货人"
              @input="searchHandleAfterMarket"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="searchFromAfterMarket.receiverPhone" style="width: 200px" placeholder="请输入联系电话"
              @input="searchHandleAfterMarket"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="searchFromAfterMarket.receiverAddrDetail" style="width: 200px" placeholder="请输入收货地址"
              @input="searchHandleAfterMarket"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-plus" size="small" @click="onSubmit">新增地址</el-button>
          </el-form-item>
        </el-form>
      </div>
      <Aftermarket ref="Aftermarket" v-if="activeVal == '3'"></Aftermarket>
    </div>
    <!-- 运营管理 -->
    <OperationConfigView v-if="activeVal == '-6'" />
    <!-- 工作日设置 -->
    <WorkdayConfigView v-if="activeVal == '-5'" />

    <BillingSetup ref="billingSetup" v-if="activeVal == '4'"></BillingSetup>
    <RateInformation ref="rateInformation" v-if="activeVal == '5'"></RateInformation>


    <el-dialog title="新增运费" :visible.sync="dialogVisible" width="480px" :before-close="handleCloseFee">
      <el-form :model="ruleForm" label-position="top" size="small" :rules="rules" ref="ruleFormFee" label-width="100px"
        style="padding: 4px;" :disabled="typeFreight == 'detail'" class="demo-ruleForm">
        <el-form-item label="区域" prop="areaList">
          <div style="width: 100%;height: 120px;overflow-y: auto;">
            <el-tree :props="{ label: 'rname', children: 'children', }" :data="areaTreeData" node-key="id" ref="tree"
              show-checkbox>
            </el-tree>
          </div>

        </el-form-item>
        <el-form-item label="是否包邮" prop="feeType">
          <el-radio-group v-model="ruleForm.feeType">
            <el-radio :label="0">商家承担运费</el-radio>
            <el-radio :label="1">自定义运费</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="发货物流" class="fill-warp" prop="deliveryLogisticsId">
          <el-select v-model="ruleForm.deliveryLogisticsId" placeholder="请选择" size="small" @change="handleChangeExpress">
            <el-option v-for="item in indicatorList ? indicatorList : []" :key="item.id" :label="item.instructions"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="medalView" v-if="ruleForm.medalPicture">
            <el-image :src="ruleForm.medalPicture" class="medalImage" />
            <span>{{ ruleForm.medalName }}</span>
          </div>
        </el-form-item>
        <el-form-item label="运费名称" prop="feeName">
          <el-input v-model="ruleForm.feeName" placeholder="请输入运费名称"></el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.feeType" label="满足以下金额包邮" prop="freeShippingOrderAmount">
          <el-input v-model="ruleForm.freeShippingOrderAmount" placeholder="请输入金额">
            <div slot="prefix">￥</div>
          </el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.feeType" label="不满足条件时收取以下金额运费" prop="carriageAmount">
          <el-input v-model="ruleForm.carriageAmount" placeholder="请输入金额">
            <div slot="prefix">￥</div>
          </el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.feeType" label="低于等于以下金额不允许下单" prop="minOrderAmount">
          <el-input v-model="ruleForm.minOrderAmount" placeholder="请输入金额">
            <div slot="prefix">￥</div>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseFee">取 消</el-button>
        <el-button :disabled="typeFreight == 'detail'" type="primary" @click="postShopExpressFees">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="dialogAddressLook ? '查看地址' : '新增地址'" :visible.sync="dialogAddress" width="480px"
      :before-close="handleClose" :close-on-click-modal="false">
      <el-form :model="ruleForm" label-position="top" size="small" :rules="rules" ref="ruleForm" label-width="100px"
        style="padding: 4px" :disabled="typeAfterMarket == 'detail'" class="demo-ruleForm">
        <el-form-item label="生效省份" prop="areaList">
          <div style="width: 100%; height: 120px; overflow-y: auto">
            <el-tree :props="{ label: 'rname', children: 'children' }" :data="areaTreeData" node-key="id" ref="tree"
              show-checkbox>
            </el-tree>
          </div>
        </el-form-item>

        <el-form-item label="收货人" required>
          <el-input v-model="ruleForm.receiver" placeholder="请输入收货人，限10个字"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" required>
          <el-input v-model="ruleForm.receiverPhone" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="收货地址" required>
          <el-select v-model="ruleForm.province" style="width: 130px; margin-right: 8px" value-key="id"
            placeholder="请选择省份" @change="selectedHandle">
            <el-option v-for="item in provinceData" :key="item.id" :label="item.rname" :value="item">
            </el-option>
          </el-select>
          <el-select v-model="ruleForm.city" style="width: 138px; margin-right: 8px" placeholder="请选择城市" value-key="id"
            @change="selectedHandleCity">
            <el-option v-for="item in cityData" :key="item.id" :label="item.rname" :value="item">
            </el-option>
          </el-select>
          <el-select v-model="ruleForm.area" style="width: 138px" placeholder="请选择区域" value-key="id"
            @change="selectedHandleArea">
            <el-option v-for="item in areaData" :key="item.id" :label="item.rname" :value="item">
            </el-option>
          </el-select>
          <el-input style="margin-top: 8px" v-model="ruleForm.receiverAddrDetail" placeholder="请输入详细地址">
          </el-input>
        </el-form-item>
        <el-form-item label="快递说明" required>
          <el-input v-model="ruleForm.expressDesc" placeholder="请输入快递说明，例如：退货不支持申通、邮政、天天快递。">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="!dialogAddressLook">
        <el-button @click="resetForm('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="afterMarketBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Tabs from "@/components/Tabs";
import Announcement from "./components/announcement";
import Freight from "./components/freight";
import Aftermarket from "./components/aftermarket";
import BillingSetup from "./components/billingSetup.vue";
import RateInformation from "./components/rateInformation.vue";

import ShopInfoView from "./children/shopInfo.vue";
import NewAccountView from "./children/newAccount.vue";
import SendConfigView from "./children/sendConfig.vue";
import AfterSaleConfigView from "./children/afterSaleConfig.vue";
import OperationConfigView from "./children/operationConfig.vue";
import WorkdayConfigView from "./children/workdayConfig.vue";
import PayTypeSetting from './children/payTypeSetting.vue'
import {
  queryShopDeliveryDistributionSettings,
  updateShopDeliveryDistributionSettings,
  getIndicatorInfo,
  getShipInfor,
  guaranteeDetail,
  shopInformationInfoNew
} from "@/api/shop";
import {
  getProvince,
  getCity,
  getArea,
  postShopExpressFees,
  putShopExpressFee,
  getAreaTree,
  shopCustomerServiceAddresses,
  putCustomerServiceAddresses,
} from "@/api/shopmange/index";

export default {
  components: {
    Tabs,
    Announcement,
    Freight,
    Aftermarket,
    BillingSetup,
    RateInformation,
    ShopInfoView,
    NewAccountView,
    SendConfigView,
    AfterSaleConfigView,
    OperationConfigView,
    WorkdayConfigView,
    PayTypeSetting
  },
  props: {
    boundMoney: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    var checkNumber = (rule, value, callback) => {
      // const reg = /^[0-9]*$/;
      const reg = /^([1-9][0-9]*)+(.[0-9]{1,4})?$/;
      if (!value) {
        return callback(new Error("请输入"));
      }
      setTimeout(() => {
        if (!reg.test(value)) {
          // if ( Number(value) ) {
          callback(new Error("请输入正确的金额"));
        } else {
          callback();
        }
      }, 300);
    };
    var checkArea = (rule, value, callback) => {
      const checkArray = this.$refs.tree.getCheckedNodes();
      if (!checkArray || !checkArray.length) {
        return callback(new Error("请选择区域"));
      }
      callback();
    };

    return {
      indicatorList: [], //物流

      dialogAddress: false,
      dialogAddressLook: false,
      formLabelAlign: {},
      dialogVisible: false,
      activeVal: "-99",

      searchFrom: {},

      searchFromAfterMarket: {},

      value: "",
      // 只展示支付方式，其他的隐藏，店铺信息，发货配置，售后设置，服务设置，开户设置，转移到 新的店铺设置 storeSettleIn
      tabsList: [
        // tabs 传的值
        // {
        //   name: "-10",
        //   label: "店铺信息和公告",
        //   value: 0,
        // },
        // {
        //   name: "1",
        //   label: "店铺公告",
        //   value: 0,
        // },
        // {
        //   name: "-9",
        //   label: "开户设置",
        //   value: 0,
        // },
        {
          name: "-99",
          label: "支付方式",
          value: 0,
        },
        // {
        //   name: "4",
        //   label: "开票设置",
        //   value: 0,
        // },
        // {
        //   name: "5",
        //   label: "费率信息",
        //   value: 0,
        // },
        // {
        //   name: "2",
        //   label: "运费设置",
        //   value: 0,
        // },
        // {
        //   name: "-8",
        //   label: "发货配送设置",
        //   value: 0,
        // },
        // {
        //   name: "-7",
        //   label: "售后设置",
        //   value: 0,
        // },
        // {
        //   name: "3",
        //   label: "售后地址",
        //   value: 0,
        // },
        //下一期需求
        // {
        //   name: "-6",
        //   label: "运营管理",
        //   value: 0,
        // },
        // {
        //   name: "-5",
        //   label: "工作日设置",
        //   value: 0,
        // },
      ],
      options: [

      ],
      value: "",

      ruleForm: {},
      rules: {
        deliveryLogisticsId: [
          { required: true, message: "请选择发货物流", trigger: "blur" },
        ],
        areaList: [
          // { required: true, message: "请选择区域", trigger: "blur" }
          { required: true, validator: checkArea, trigger: "blur" },
        ],
        feeType: [
          { required: true, message: "请选择是否包邮", trigger: "change" },
        ],
        feeName: [
          { required: true, message: "请填写运费名称", trigger: "blur" },
        ],
        freeShippingOrderAmount: [
          { required: true, validator: checkNumber, trigger: "blur" },
          // { required: true, message: "请填写金额", trigger: "blur" },
        ],
        carriageAmount: [
          { required: true, validator: checkNumber, trigger: "blur" },
        ],
        minOrderAmount: [
          { required: true, validator: checkNumber, trigger: "blur" },
        ],
      },
      provinceData: [],
      cityData: [],
      areaData: [],
      propsData: {},
      areaTreeData: [],

      // 运费设置弹窗类型typeFreight  详情 detail  编辑 editor  新增 add
      typeFreight: "add",
      //售后设置弹窗类型typeAfterMarket  详情 detail  编辑 editor  新增 add
      typeAfterMarket: "add",
      boundMoney: 0,//店铺是否存在保证金
      allAreaCode: [] //获取所有省市code
    };
  },
  mounted () {
    this.getArea();
    this.getProvinceData();
    this.getIndicatorInfo();
    this.getShopInfoAction()
  },
  methods: {
    handleChangeExpress (e) {

      const findedItem = this.indicatorList.find((item) => item.id == e);
      if (findedItem.medalPicture) {
        this.$set(this.ruleForm, "medalPicture", findedItem.medalPicture);
        this.$set(this.ruleForm, "medalName", findedItem.medalName);
      } else {
        this.$set(this.ruleForm, "medalPicture", "");
        this.$set(this.ruleForm, "medalName", null);
      }

    },
    getShopInfoAction () {
      // getShipInfor().then((data) => {
      //   if (data && data.shopId) {
      //     this.getShopInfo(data.shopId)
      //   }
      // });
      shopInformationInfoNew().then((data) => {
        console.log(data, '---data')
        if (data && data.shopId) {
          this.getShopInfo(data.shopId)
        }
      });
    },
    async getShopInfo (shopId) {
      const res = await guaranteeDetail({ shopId })
      if (res && res.useAmount && res.useAmount > 0) {
        // 存在保证金余额
        this.boundMoney = res.useAmount
      }
    },
    getIndicatorInfo () {
      let type = "MATERIAL_FLOW";
      getIndicatorInfo(type).then((data) => {
        if (data instanceof Array) {
          this.indicatorList = data;
        }
      });
    },
    //运费设置中获取详情展示的弹窗
    getFreightDetail (res) {
      this.typeFreight = res.type;
      this.ruleForm = res;
      this.dialogVisible = true;
      this.$nextTick(() => {
        let newArray = [];
        res.areaList.forEach((re) => {
          newArray.push({ id: re.city, label: re.cityName });
        });
        this.$refs.tree.setCheckedNodes(newArray);
      });
    },
    //售后设置中获取详情展示的弹窗
    getafterMarketDetail (res) {
      this.typeAfterMarket = res.caozuoType;
      res.province = {
        id: Number(res.receiverProvince),
        rname: res.receiverProvinceName,
      };
      this.getCityBtn(res.province);
      res.city = { id: Number(res.receiverCity), rname: res.receiverCityName };
      this.getAreaBtn(res.city);
      res.area = { id: Number(res.receiverArea), rname: res.receiverAreaName };
      this.dialogAddressLook = res.caozuoType == "detail";
      this.dialogAddress = true;
      this.$nextTick(() => {
        let newArray = [];
        res.areaList.forEach((re) => {
          newArray.push({ id: re.city, label: re.cityName });
        });
        this.ruleForm = res;
        this.$refs.tree.setCheckedNodes(newArray);
      });
    },
    //获取整个省市区的树
    getArea () {
      getAreaTree({ level: 2 }).then((res) => {
        if (res.code == 0) {
          this.areaTreeData = res.data;
          let newAreaTreeData = JSON.parse(JSON.stringify(this.areaTreeData));
          this.getAllAreaCode(newAreaTreeData);
          // console.log(this.allAreaCode,'---this.allAreaCode.')
        }
      });
    },

    // 获取省市的对象，给店铺设置用
    getAllAreaCode (newAreaTreeData) {
      for (let i = 0; i < newAreaTreeData.length; i++) {
        // level
        if (newAreaTreeData[i].level == 1) {
          this.allAreaCode.push({
            provinceName: newAreaTreeData[i].rname,
            province: newAreaTreeData[i].id,
            city: "",
            cityName: "",
          });
        }
        if (newAreaTreeData[i].level == 2) {
          this.allAreaCode.push({
            cityName: newAreaTreeData[i].rname,
            city: newAreaTreeData[i].id,
            provinceName: "",
            province: "",
          });
        }
        if (newAreaTreeData[i].children) {
          this.getAllAreaCode(newAreaTreeData[i].children)
        }
      }
    },

    //切换tabs按钮
    handleChange (val) {
      this.activeVal = val;
    },

    onSubmit () {
      this.typeAfterMarket = "add";
      this.dialogAddress = true;
      this.dialogAddressLook = false;
      this.getProvinceData();
    },
    //新增运费按钮事件
    handleFreight () {
      this.typeFreight = "add";
      this.dialogVisible = true;
      this.getProvinceData();
    },
    getProvinceData () {
      getProvince().then((res) => {
        if (res.code == 0) {
          this.provinceData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },
    handleCloseFee () {
      this.dialogVisible = false;
      this.resetForm("ruleFormFee");
    },
    handleClose () {
      this.dialogAddress = false;
      this.resetForm("ruleForm");
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm (formName) {
      this.dialogAddress = false;
      this.dialogVisible = false;
      this.$refs[formName].resetFields();
      this.$data["ruleForm"] = JSON.parse(
        JSON.stringify(this.$options.data()["ruleForm"])
      );
      this.$refs.tree.setCheckedNodes([]);
    },
    searchHandle () {
      this.searchFrom.beginDate = this.searchFrom.createTime?.[0] ?? "";
      this.searchFrom.endDate = this.searchFrom.createTime?.[1] ?? "";
      delete this.searchFrom.createTime;
      this.$refs["Freight"].getList(this.searchFrom);
    },
    searchHandleAfterMarket () {
      // this.searchFromAfterMarket.beginDate = this.searchFrom.createTime?.[0] ?? "";
      // this.searchFrom.endDate = this.searchFrom.createTime?.[1] ?? "";
      // delete this.searchFrom.createTime;
      this.$refs["Aftermarket"].getList(this.searchFromAfterMarket);
    },
    //省份的选择按钮
    selectedHandle (val) {
      this.$set(this.ruleForm, "city", {});
      this.getCityBtn(val);
    },
    getCityBtn (val) {
      getCity({ id: val.id }).then((res) => {
        if (res.code == 0) {
          this.cityData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },
    //城市的选择按钮
    selectedHandleCity (val) {
      this.$set(this.ruleForm, "city", val);
      this.getAreaBtn(val);
    },
    getAreaBtn (val) {
      getArea({ id: val.id }).then((res) => {
        if (res.code == 0) {
          this.areaData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },
    //地区的选择按钮
    selectedHandleArea (val) {
      this.$set(this.ruleForm, "area", val);
    },
    handleArea (val) {
      console.log(this.ruleForm);
    },
    postShopExpressFees () {
      // 新增运费
      let checkArray = this.$refs.tree.getCheckedNodes();
      console.log(checkArray);
      let areaList = [];
      console.log(this.ruleForm, '--this.ruleForm')
      this.$refs.ruleFormFee.validate((valid) => {
        if (valid) {
          // if (
          //   this.ruleForm.freeShippingOrderAmount > this.ruleForm.carriageAmount
          // ) {
          //   this.$message.error("起配金额不能大于包邮金额");
          //   return;
          // }

          checkArray.forEach((element) => {
            if (element.parentId == -1) {
              areaList.push({
                province: element.id,
                provinceName: element.rname,
                city: "",
                cityName: "",
              });
            } else {
              areaList.push({
                province: "",
                provinceName: "",
                city: element.id,
                cityName: element.rname,
              });
            }
          });
          this.ruleForm.areaList = areaList;

          //发货物流
          if (this.ruleForm.deliveryLogisticsId) {
            const findedItem = this.indicatorList.find(
              (item) => item.id == this.ruleForm.deliveryLogisticsId
            );
            if (findedItem) {
              this.ruleForm.deliveryLogisticsName = findedItem.instructions;
            }
          }

          if (this.ruleForm.feeType == 0) {
            this.ruleForm.freeShippingOrderAmount = "";
            this.ruleForm.carriageAmount = "";
            this.ruleForm.minOrderAmount = "";
          }

          if (this.ruleForm.id) {
            putShopExpressFee(this.ruleForm).then((res) => {
              this.dialogVisible = false;
              this.$refs["Freight"].getList(this.searchFrom);
              this.resetForm("ruleFormFee");
            });
          } else {
            postShopExpressFees(this.ruleForm).then((res) => {
              this.dialogVisible = false;
              this.$refs["Freight"].getList(this.searchFrom);
              this.resetForm("ruleFormFee");
            });
          }
        }
      });
    },
    afterMarketBtn () {
      // 新增地址
      let checkArray = this.$refs.tree.getCheckedNodes();
      let areaList = [];
      checkArray.forEach((element) => {
        if (element.parentId == -1) {
          areaList.push({
            province: element.id,
            provinceName: element.rname,
            city: "",
            cityName: "",
          });
        } else {
          areaList.push({
            province: "",
            provinceName: "",
            city: element.id,
            cityName: element.rname,
          });
        }
      });
      this.ruleForm.areaList = areaList;
      this.ruleForm.receiverProvince = this.ruleForm.province.id;
      this.ruleForm.receiverProvinceName = this.ruleForm.province.rname;
      this.ruleForm.receiverCity = this.ruleForm.city.id;
      this.ruleForm.receiverCityName = this.ruleForm.city.rname;
      this.ruleForm.receiverArea = this.ruleForm.area.id;
      this.ruleForm.receiverAreaName = this.ruleForm.area.rname;

      if (this.ruleForm.id) {
        putCustomerServiceAddresses(this.ruleForm).then((res) => {
          this.$message({ type: "success", message: "修改成功！" });
          this.dialogAddress = false;
          this.resetForm("ruleForm");
          this.$refs.Aftermarket.getList();
          this.deleteArea();
        });
      } else {
        shopCustomerServiceAddresses(this.ruleForm).then((res) => {
          this.$message({ type: "success", message: "保存成功！" });
          this.dialogAddress = false;
          this.resetForm("ruleForm");
          this.$refs.Aftermarket.getList();
          this.deleteArea();
        });
      }
    },
    deleteArea () {
      delete this.ruleForm.province;
      delete this.ruleForm.city;
      delete this.ruleForm.area;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  background-color: #fff;
  height: calc(100vh - 140px);

  ::v-deep .el-tabs__item {
    padding: 0 12px;
    margin-right: 0px;
  }

  .freight-condition {
    padding: 24px;
    box-sizing: border-box;
    text-align: left;
  }
}

.fill-warp {
  ::v-deep .el-select {
    width: 100%;
  }
}

.medalView {
  display: flex;
  align-items: center;

  .medalImage {
    height: 20px;
    width: 20px;
  }
}
</style>
