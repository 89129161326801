import request from "@/utils/request";
// 创建会话
export function shopExtendInfos(data) {
  return request({
    url: `/shop/shop/shopExtendInfos`,
    method: "GET",
    params: data,
  });
}

// 创建会话
export function dictExpresses(data) {
  return request({
    url: `/shop/shop/dictExpresses`,
    method: "GET",
    params: data,
  });
}

// 获取省份的接口
export function getProvince(data) {
  return request({
    url: `/shop/region/province`,
    method: "GET",
    params: data,
  });
}

//获取市的接口
export function getCity(data) {
  return request({
    url: `/shop/region/city/${data.id}`,
    method: "GET",
    params: data,
  });
}

//新增公告
export function postShopExtendInfos(data) {
  return request({
    url: `/shop/shop/shopExtendInfos`,
    method: "POST",
    data,
  });
}
// 修改公告
export function putShopExtendInfos(data) {
  return request({
    url: `/shop/shop/shopExtendInfos/${data.id}`,
    method: "PUT",
    data,
  });
}

//获取运费设置列表

export function getShopExpressFees(data) {
  return request({
    url: `/shop/shop/shopExpressFees`,
    method: "GET",
    params: data,
  });
}

// 新增运费
export function postShopExpressFees(data) {
  return request({
    url: `/shop/shop/shopExpressFees`,
    method: "POST",
    data,
  });
}
//获取运费设置详情
export function getShopExpressFee(data) {
  return request({
    url: `/shop/shop/shopExpressFees/${data.id}`,
    method: "GET",
    params: data,
  });
}

//删除运费设置详情
export function deleteShopExpressFee(data) {
  return request({
    url: `/shop/shop/shopExpressFees/${data.id}`,
    method: "DELETE",
    data,
  });
}
//修改运费设置详情
export function putShopExpressFee(data) {
  return request({
    url: `/shop/shop/shopExpressFees/${data.id}`,
    method: "PUT",
    data,
  });
}

//获取整个地区树

export function getAreaTree(data) {
  return request({
    url: `/shop/region/areaTree/${data.level}`,
    method: "GET",
    params: data,
  });
}

//获取地区

export function getArea(data) {
  return request({
    url: `/shop/region/area/${data.id}`,
    method: "GET",
    params: data,
  });
}

//售后地址新增
export function shopCustomerServiceAddresses(data) {
  return request({
    url: `/shop/shop/shopCustomerServiceAddresses`,
    method: "POST",
    data,
  });
}

//售后地址修改
export function putCustomerServiceAddresses(data) {
  return request({
    url: `/shop/shop/shopCustomerServiceAddresses/${data.id}`,
    method: "PUT",
    data,
  });
}

//售后地址删除
export function deleteCustomerServiceAddresses(data) {
  return request({
    url: `/shop/shop/shopCustomerServiceAddresses/${data.id}`,
    method: "DELETE",
    data,
  });
}
//获取售后地址的列表
export function getShopCustomerServiceAddresses(data) {
  return request({
    url: `/shop/shop/shopCustomerServiceAddresses`,
    method: "GET",
    params: data,
  });
}

//获取售后地址的列表
export function getShopCustomerServiceAddressesNew(params) {
  return request({
    url: `/shop/shop/shopCustomerServiceAddresses/new/list`,
    method: "GET",
    params: params,
  });
}

//售后地址新增
export function getShopCustomerServiceAddresse(data) {
  return request({
    url: `/shop/shop/shopCustomerServiceAddresses/${data.id}`,
    method: "GET",
    params: data,
  });
}
//获取自营商品名称列表
export function getSelfGoodsList(data) {
  return request({
    url: `/product/goodsbase/goods/self`,
    method: "GET",
    params: data,
  });
}
//获取自营控销商品名称列表
export function getSelfControllerGoodsList(data) {
  return request({
    url: `/product/goodsbase/goods/kx/goods`,
    method: "GET",
    params: data,
  });
}
// 执行创建自营控销SQL
export function excSelfControlSQL(data) {
  return request({
    url: "/sales-rule/v1/related/onekey",
    method: "post",
    data,
  });
}
//根据用户获取地办列表
export function getAreaCompanyListByUser(data) {
  return request({
    url: `/shop/agencyshop/dz/list`,
    method: "GET",
    params: data,
  });
}
//自营控销订单省地办审批列表
export function getSelfControllerWaitDealList(data) {
  return request({
    url: `/order/order/purchase/check/page`,
    method: "GET",
    params: data,
  });
}
//自营控销订单省地办审核列表审核
export function updateSelfControllerWaitDea(data) {
  return request({
    url: `/order/order/purchase/batch/check`,
    method: "POST",
    data,
  });
}

//查询开票信息
export function cominvoicepropertyDetail(data) {
  return request({
    url: `/invoice/compliance/cominvoiceproperty/detail`,
    method: "get",
    params: data,
  });
}

//添加发票抬头信息
export function cominvoicepropertyDave(data) {
  return request({
    url: `/invoice/compliance/cominvoiceproperty/save`,
    method: "post",
    data,
  });
}

//根据商铺ID获取经营范围列表
export function queryShopScopeByShopIdList(data) {
  return request({
    url: `/shop/shopManage/queryShopScopeByShopIdList`,
    method: "post",
    data,
  });
}

// 审单导出
export function exportOrder(data) {
  request({
    url: "/order/order/purchase/check/selfGoodsCheckListExport",
    method: "get",
    responseType: "blob",
    params: data,
  }).then((response) => {
    // 处理返回的文件流
    const blob = response.data;
    if (blob && blob.size === 0) {
      Message.warning("内容为空，无法下载");
      return;
    }
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([blob]));
    link.download = "统计.xlsx";
    link.click();
    window.setTimeout(function () {
      window.URL.revokeObjectURL(link.href);
    }, 0);
  });
}
// 查询当前shop
export function queryShop(data) {
  return request({
    url: `/shop/shop/queryShop`,
    method: "get",
    params: data,
  });
}
/**
 * 查询当前用户是否已处理
 */
export function queryShopMoney(data) {
  return request({
    url: `/shop/shop/securityDeposit/tips/current/status`,
    method: "get",
    params: data,
  });
}
//用户处理提醒
export function dealMoneyInfo(data) {
  return request({
    url: `/shop/shop/securityDeposit/tips/current/do`,
    method: "post",
    data,
  });
}
