import request from '@/utils/request'

// 查询列表
export function getShopPayRecord(data) {
  return request({
    url: `/payment/payeeRecord/getShopPayRecord`,
    method: "get",
    params: data
  });
}

// 手工上传发票
export function manual(data) {
  return request({
    url: "/order/invoice/manual",
    method: "post",
    contentType: 'blob',
    data
  });
}

// 获取销方发票
export function getPage(data) {
  return request({
    url: "/invoice/sellerInfo/get",
    method: "get",
    params: data,
  });
}

// 获取发票
export function updateCon(data) {
  return request({
    url: "/invoice/sellerInfo/update",
    method: "post",
    data,
  });
}

export function saveCon(data) {
  return request({
    url: "/invoice/sellerInfo/save",
    method: "post",
    data,
  });
}
// 获取购方发票
export function getbuyInfo(data) {
  return request({
    url: "/invoice/buyInfo/get",
    method: "get",
    params: data,
  });
}
// 购方发票修改
export function editbuyInfo(data) {
  return request({
    url: "/invoice/buyInfo/update",
    method: "post",
    data,
  });
}
// 卖家开出的发票分页查询 销方
export function sellerPage(data) {
  return request({
    url: "/invoice/invoiceRecord/sellerPage",
    method: "get",
    params: data,
  });
}
// 买家收到的发票分页查询 买方
export function buyerPage(data) {
  return request({
    url: "/invoice/invoiceRecord/buyerPage",
    method: "get",
    params: data,
  });
}
// 发票枚举列表
export function getContentType(data) {
  return request({
    url: "/invoice/invoiceRecord/getContentType",
    method: "get",
    params: data,
  });
}
// 发票枚举列表
export function purchaseDetail(data) {
  return request({
    url: "/order/order/purchase/code/" + data,
    method: "get"
  });
}
// 待开票订单查询
export function busiPage(data) {
  return request({
    url: "/order/invoice/busi/page",
    method: "get",
    params: data,
  });
}

// 购买方发票抬头
export function getInvoiceHeader(data) {
  return request({
    url: `/invoice/buyInfo/get`,
    method: "get",
    params: data
  });
}

// 买方抬头信息保存
export function buyInfoSave(data) {
  return request({
    url: `/invoice/buyInfo/save`,
    method: "post",
    data,
  });
}

// 买方抬头信息更新
export function buyInfoUupdate(data) {
  return request({
    url: `/invoice/buyInfo/update`,
    method: "post",
    data,
  });
}

export function MockLogin(data) {
  return request({
    url: `/vue-admin-template/user/login`,
    method: "post",
    data,
  });
}

// 佣金账单
export function commissionRecord(data) {
  return request({
    url: `/payment/commission/record`,
    method: "get",
    params:data,
  });
}
// 佣金审核提交
export function commissionAuditSubmit(data) {
  return request({
    url: `/payment/commission/commissionAuditSubmit`,
    method: "put",
    data,
  });
}
// 佣金审核驳回
export function commissionOverrule(data) {
  return request({
    url: `/payment/commission/commissionOverrule`,
    method: "put",
    data,
  });
}
// 佣金数量查询
export function commissionQuantity(data) {
  return request({
    url: `/payment/commission/commissionQuantity`,
    method: "get",
    params:data,
  });
}
// 佣金流水明细查询
export function payeeRecordPage(data) {
  return request({
    url: `/payment/payeeRecord/payeeRecordPage`,
    method: "get",
    params:data,
  });
}
// 审核状态列表
export function auditTypeList(data) {
  return request({
    url: `/payment/commission/auditTypeList`,
    method: "get",
    params:data,
  });
}
// 获取账户康贝余额
export function getAccountData(data) {
  return request({
    url: `/payment/commission/getAccountData`,
    method: "get",
    params:data,
  });
}

// 佣金账单平台端
export function managerRecord(data) {
  return request({
    url: `/payment/commission/managerRecord`,
    method: "get",
    params:data,
  });
}
// 佣金审核通过
export function commissionAudit(data) {
  return request({
    url: `/payment/commission/commissionAudit`,
    method: "put",
    data,
  });
}

// 查询发票抬头信息-新
export function cominvoicetitleinfoDetail() {
  return request({
    url: `/invoice/compliance/cominvoicetitleinfo/sales/detail`,
    method: "get"
  });
}

// 添加发票抬头信息-新
export function cominvoicetitleinfoSave(data) {
  return request({
    url: `/invoice/compliance/cominvoicetitleinfo/sales/save`,
    method: "post",
    data:data
  });
}
// 获取提现人的银行卡集合
export function getBankListUrl(data) {
  return request({
    url: `/payment/commission/getBankListUrl`,
    method: "get",
    params:data,
  });
}
// 获取提现人的银行卡集合
export function kangBeiWithdrawal(data) {
  return request({
    url: `/payment/KangBeiWithdrawal/withdrawal`,
    method: "post",
    data,
  });
}
// 提现短信验证
export function withdrawalValida(data) {
  return request({
    url: `/payment/KangBeiWithdrawal/withdrawalValida`,
    method: "post",
    data,
  });
}
// 余额低于保证金不允许提现
export function obtainWithdrawalEligibility(shopId) {
  return request({
    url: `/shop/shopManage/obtainWithdrawalEligibility/${shopId}`,
    method: "get",
    params:{}
  });
}

// 查询发票抬头信息-新
export function cominvoicetitleinfoDetailShopId(shopId) {
  return request({
    url: `/invoice/compliance/cominvoicetitleinfo/seller/detail/${shopId}`,
    method: "get"
  });
}

// /payment/payeeRecord/exportShopPayRecord   我的收入 导出
export function payeeRecordExportShopPayRecord(data) {
  return request({
    url: `/payment/payeeRecord/exportShopPayRecord`,
    method: 'get',
    params:data,
    responseType: 'blob'
  }).then(response => {
    // 处理返回的文件流
    const blob = response.data
    if (blob && blob.size === 0) {
      Message.warning('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    const contentDisposition = response.headers['content-disposition'];
    const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1));
    console.log(fileName)
    link.download = fileName;
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
      Message.success('导出成功')
    }, 0)
  })
}