<template>
  <div class="search-stastics">
    <div
      class="center"
      :style="contentHeight ? `height:${contentHeight}px!important` : ''"
    >
      <MyTable class="inner-table" :table-data="tableData" :table-option.sync="tableOption" :index="false" v-loading="listLoading">
        <template slot-scope="scope" slot="percent">
          <el-progress
            :percentage="scope.row.percent"
            :text-inside="false"
            :show-text="false"
          ></el-progress>
        </template>
      </MyTable>
    </div>
  </div>
</template>
<script>
/** 搜索报表 */
import { queryGoodsSalesEcharts } from "@/api/statistics";
import MyTable from "@/components/myTable";

export default {
  name: 'SearchStastics',
  components: { MyTable },
  data() {
    return {
      page: {
        current: 1,
        size: 20,
        total: 0,
      },
      formTool: {
        date: [],
      },
      tableData: [],
      listLoading: false,
      totalPercent: 0, // 总热度
      tableOption: [
        { label: "关键词", prop: "keyword", width: 300 },
        { label: "搜索热度", prop: "percent", slot: true, width: 200},
      ],
      contentHeight: 0
    };
  },
  mounted() {
    this.getTableList();
    this.getWindowView()
  },
  methods: {
    getWindowView() {
        let innerHeight = window.innerHeight; //获取页面高度
        // padding 导航 底部
        let contentHeight = innerHeight - 32 - 80 - 40;
        this.contentHeight = contentHeight;
      },
    // 获取列表
    getTableList() {
      this.listLoading = true;
      let params = this.getParam();
      queryGoodsSalesEcharts({ params, type: 119 })
        .then((res) => {
          if (res) {
            res.forEach((item) => {
              this.totalPercent += Number(item.search_cnt || 0);
            });
            res.forEach((item) => {
              item.percent = Number(
                (
                  (Number(item.search_cnt || 0) /  res[0].search_cnt) *
                  100
                ).toFixed(2)
              );
            });
            this.tableData = res;
          }
        })
        .finally(() => (this.listLoading = false));
    },
    // 获取请求参数
    getParam() {
      // [开始时间,结束时间,开始时间,结束时间,
      // 商品编码/名称,商品编码/名称,
      // 厂家,厂家,
      // 批准文号,批准文号,
      // 排序字段,每页行数,第几行开始]
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      return [
        "",
        "",
        this.formatDate(start),
        this.formatDate(start),
        this.formatDate(end),
        this.formatDate(end),
        this.formatDate(start),
        this.formatDate(start),
        this.formatDate(end),
        this.formatDate(end),
        50,
        0,
      ];
    },
    // 格式化数据
    correctData(data) {
      return data || "";
    },
    //格式化日期：yyyy-MM-dd
    formatDate(date) {
      const myyear = date.getFullYear();
      let mymonth = date.getMonth() + 1;
      let myweekday = date.getDate();
      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      return `${myyear}${mymonth}${myweekday}`;
    },
  },
};
</script>  
<style lang="scss" scoped>
.search-stastics {
  // margin: 16px;
  height: 100%;
  .inner-table {
    width: 100%;
    ::v-deep .el-table {
      padding: 16px;
    }
  }
}
</style>  