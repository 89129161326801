
<!--  -->
<template>
  <div class="comContent">
    <div class="header">
      <span class="font">经营范围</span>
      <el-form ref="formTool"
               :model="formTool"
               :inline="true"
               style="text-align:left;">
        <!-- <el-form-item prop="name">
          <el-input v-model="formTool.name"
                    placeholder="请输入商品名称"
                    size="small" />
        </el-form-item> -->
        <el-form-item prop="shopName">
          <el-input v-model="formTool.shopName"
                    clearable
                    placeholder="请输入公司名称"
                    size="small" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     size="small"
                     @click="getcheckList(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     @click="reset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="center">
      <MyTable v-if="tableData.length > 0"
               :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getcheckList"
               @handleSelectionChange="handleSelectionChange">
        <template slot="provice"
                  slot-scope="scope"><span>
            {{scope.row.provinceName + scope.row.cityName + scope.row.areaName}}
          </span>
        </template>
        <template slot="caozuo"
                  slot-scope="scope">
          <!-- <el-button type="button" size="mini" icon="el-icon-edit" @click="editDetail(scope.row)">编辑</el-button> -->
          <el-link :underline="false"
                   type="primary"
                   @click="editDetail(scope.row)">编辑</el-link>
        </template>
      </MyTable>
      <el-empty v-else
                style="height:100%"
                description="暂无数据" />
    </div>
    <el-dialog title="商户经营地域范围"
               :visible.sync="dialogVisible"
               :before-close="cancel"
               v-loading="loading"
               width="30%">
      <div>
        <el-tree :data="treeData"
                 ref="tree"
                 show-checkbox
                 node-key="id"
                 :default-expanded-keys="expandedKeys"
                 :default-checked-keys="[]"
                 :props="defaultProps"
                 @check="check">
        </el-tree>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button size="small"
                   @click="cancel">取消</el-button>
        <el-button size="small"
                   type="primary"
                   @click="manageRegion">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable'
import { drugAgencyList, regionTree, manageRegionTree, manageRegion } from '@/api/supplierManagement/index'
export default {
  components: { MyButton, MyTable },
  data () {
    return {
      loading: false,
      expandedKeys: [],
      shopId: '',
      dialogVisible: false,
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      formTool: {
        shopName: ''
      },
      operation: false,
      selection: false,
      index: false,
      tableData: [],
      tableOption: [
        { label: '公司名称', prop: 'shopName' },
        { label: '省市区/县', prop: 'provice', slot: true, },
        { label: '入驻时间', prop: 'createTime' },
        { label: '商户ID', prop: 'id' },
        { label: '操作', prop: 'caozuo', slot: true, width: '80' },

      ],
      page: {
        total: 10,
        current: 1,
        size: 10
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getcheckList()
    this.regionTree()
  },
  mounted () {

  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    // 复选框选中按钮
    check (data, item) {
      console.log(item)
      console.log(data)
    },
    // 获取省市区树
    regionTree () {
      regionTree().then(res => {
        if (res.code == 0) {
          this.treeData = res.data
        }
      })
    },
    // 根据商铺ID获取经营区域树列表
    manageRegionTree (shopId) {
      this.loading = true
      manageRegionTree(shopId).then(res => {
        let arr = []
        if (res.code == 0) {
          if (res.data && res.data.length > 0) {
            res.data.forEach(item => {
              arr.push(item.id)
            })
            this.expandedKeys = arr
            this.$refs.tree.setCheckedKeys(arr)
            this.loading = false
          } else {
            this.loading = false
            this.expandedKeys = []
          }
        } else {
          this.loading = false
          this.expandedKeys = []
        }
      })
    },
    // 编辑卖家经营区域
    manageRegion () {
      let arr = this.$refs.tree.getCheckedNodes()
      console.log(arr)
      let arrKeys = []
      if (arr.length > 0) {
        arr.forEach(item => {
          let obj = {
            id: item.id,
            name: item.name,
            parentId: item.parentId,
          }
          arrKeys.push(obj)
        })
      }
      if (arrKeys.length == 0) {
        this.$message.warning('请先选择区域')
      } else {
        const params = {
          shopId: this.shopId,
          regionList: arrKeys
        }
        this.loading = true
        manageRegion(params).then(res => {
          if (res.code == 0) {
            this.$message.success('编辑成功')
            this.loading = false
            this.dialogVisible = false
            this.$refs.tree.setCheckedNodes([])
          }
        })
      }
    },
    // 查询表格数据
    getcheckList (current) {
      if (current) {
        this.page.current = current
      }
      const params = {
        current: this.page.current,
        size: this.page.size,
        ...this.formTool
      }
      drugAgencyList(params).then(res => {
        if (res.code === 0) {
          this.tableData = res.data.records
          this.page.total = res.data.total
        }
      })
    },
    // 重置
    reset () {
      // this.formTool.storeName = ''
      this.$refs.formTool.resetFields()
      // this.getcheckList()
    },
    // 编辑
    editDetail (row) {
      this.shopId = row.id
      this.dialogVisible = true
      this.$nextTick(() => {
        this.manageRegionTree(row.id)
      })

    },
    // 取消 
    cancel () {
      this.dialogVisible = false
      this.$refs.tree.setCheckedNodes([])
    },
    handleSelectionChange (val) {

    }
  }
}
</script>
<style lang='scss' scoped>
.comContent {
  padding: 0px;
  ::v-deep .el-dialog__body {
    height: 500px;
    overflow: auto;
  }
}
.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px !important;
  border-bottom: 1px solid #f5f5f5;
  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }
  .el-form-item {
    margin: 0px 0px 0px 8px !important;
  }
}
.center {
  margin-top: 16px;
  padding: 0 24px 0 24px;
  height: calc(100% - 131px);
}
</style>
