<template>
  <div class="classification card new">
    <div class="inner">
      <div class="bindView">
        <span style="color: #262626;font-size: 16px;font-weight: 600;font-family: PingFangSC, PingFangSC-Medium;">康贝号绑定</span>
        <el-button type="primary"
                   size="small"
                   @click="submitBtn">添加康贝号</el-button>
      </div>
      <el-divider></el-divider>
      <el-table :data="merchantList"
                style="width: 100%">
        <el-table-column prop="merchantNo"
                         label="康贝号"
                         width="180px">
        </el-table-column>
        <el-table-column prop="merchantName"
                         label="康贝账户名称"
                         width="280px">
        </el-table-column>
        <el-table-column label="操作"
                         width="">
          <template slot-scope="scope">
            <el-button type="text"
                       v-if="scope.row.defaultNo"
                       style="color: #8c8c8c;">默认账户</el-button>
            <el-button type="text"
                       @click="setDefaultMerchantAction(scope.row)"
                       v-else>设为默认</el-button>
            <el-button type="text"
                       v-if="scope.row.status"
                       @click="qiyong(scope.row)">禁用</el-button>
            <el-button type="text"
                       @click="qiyong(scope.row)"
                       v-else>启用</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog width="30%"
               title="商户绑定"
               :visible.sync="innerVisible"
               :close-on-click-modal="false"
               append-to-body>
      <el-form ref="form"
               :model="form"
               :rules="rules"
               label-width="80px">
        <el-form-item prop="merchantNo"
                      label="康贝商户">
          <el-input v-model.trim="form.merchantNo"
                    auto-complete="off"
                    placeholder="请输入商户号">
            <i slot="prefix"
               class="el-icon-mobile-phone"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="verifyCode"
                      label="验证码">
          <el-input class="verification-code"
                    v-model.trim="form.verifyCode"
                    auto-complete="off"
                    placeholder="请输入验证码">
            <i slot="prefix"
               class="el-icon-key"
               style="position:absolute;top:12px"></i>
            <template slot="append">
              <span :class="[{ display: msgKey }, { active: form.merchantNo }]"
                    class="msg-text"
                    @click="handleSend">{{ msgText }}</span>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button size="small"
                   @click="
            innerVisible = false
          ">取 消</el-button>
        <el-button size="small"
                   :loading="loading"
                   type="primary"
                   @click="
            innerVisible = true
            sureBtn()
          ">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
const MSGINIT = '发送验证码',
  MSGSCUCCESS = '${time}秒后重发',
  MSGTIME = 60

import { currentDetails } from '@/api/myQualification/index'
import { getUserInfo, merchantBind, findPhoneByMerchant, getMerchantList, setDefaultMerchant, merchantEnable, merchantDisable } from '@/api/user.js'
// import { getStore } from '@/util/store'
export default {
  data () {
    return {
      loading: false,
      merchantNo: '',
      rules: {
        merchantNo: [
          { required: true, message: '请输入商户号', trigger: 'change' },
          // { validator: this.checkMerchantNo, trigger: 'change' }
        ],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: 'change' }
        ],
      },
      innerVisible: false,
      form: {
        merchantNo: '',
        verifyCode: '',
      },
      msgKey: false,
      msgText: MSGINIT,
      msgTime: MSGTIME,
      phone: '',
      merchantName: '',
      merchantList: []
      // userInfo: {},
    }
  },
  async created () {
    this.currentDetails()
    // await getUserInfo(getStore({ name: 'userId' })).then((res) => {
    //   this.userInfo = res.data.data
    //   this.userInfo.role = []
    //   this.userInfo.post = []
    //   res.data.data.roleList.map((item) => {
    //     this.userInfo.role.push(item.roleName)
    //   })
    //   res.data.data.postList.map((item) => {
    //     this.userInfo.post.push(item.postName)
    //   })
    // })
  },
  computed: {
    userInfo () {
      console.log(this.$store.state.user.user_info)
      return this.$store.state.user.user_info
    },
  },
  methods: {
    //  启用/禁用 
    qiyong (row) {
      if (row.status == true) {
        merchantDisable(row.id).then(res => {
          if (res) {
            this.$message.success('禁用成功')
            this.currentDetails()
          }
        })
      } else {
        merchantEnable(row.id).then(res => {
          if (res) {
            this.$message.success('启用成功')
            this.currentDetails()
          }
        })
      }
    },
    // 校验康贝账户
    checkMerchantNo (rule, value, callback) {
      if (value[0] == 'A' || value[0] == 'a') {
        callback(new Error('仅支持公司与企业的康贝号绑定，个人康贝号不支持绑定'));
      } else {
        callback();
      }
    },
    // 查询康贝账户
    async currentDetails () {
      const resp = await getMerchantList()
      if (resp) {
        this.merchantList = resp
      }
      // currentDetails().then(res => {
      //   if (res.code == 0) {
      //     this.merchantNo = res.data?.merchantNo ?? ''
      //   } else {
      //     this.$message.error(res.msg)
      //   }
      // })
    },
    async setDefaultMerchantAction (item) {
      let obj = {
        shopMerchantNoId: item.id
      }
      const resp = await setDefaultMerchant(obj)
      if (resp) {
        this.$message.success('设置成功')
        this.currentDetails()
      }
    },
    submitBtn () {
      console.log(999)
      this.innerVisible = true
      this.$refs.form.resetFields()
    },
    sureBtn () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          merchantBind({
            phone: this.phone,
            verifyCode: this.form.verifyCode,
            merchantName: this.merchantName
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success('商户绑定成功')
              this.merchantNo = this.form.merchantNo
              this.innerVisible = false
              this.form = {
                merchantNo: '',
                verifyCode: '',
              }
              this.currentDetails();
            } else {
              this.$message.error(res.msg)
              // this.form = {
              //   merchantNo: '',
              //   verifyCode: '',
              // }
            }
            this.loading = false
          }).catch(err => {
            this.loading = false
          })
        }
      })
    },
    handleSend () {
      // 判断是否可以发送（时间限制）
      if (this.msgKey) return
      // 发送验证码
      this.$refs.form.validateField('merchantNo', (valid) => {
        if (!valid) {
          findPhoneByMerchant({ merchantNo: this.form.merchantNo }).then(
            (res) => {
              if (res) {
                this.$message.success('验证码发送成功')
                this.phone = res.regPhone
                this.merchantName = res.merchantName
                this.timeCacl()
              }
            }
          )
        }
      })
    },
    timeCacl () {
      // 计时避免重复发送
      this.msgText = MSGSCUCCESS.replace('${time}', this.msgTime)
      this.msgKey = true
      const time = setInterval(() => {
        this.msgTime--
        this.msgText = MSGSCUCCESS.replace('${time}', this.msgTime)
        if (this.msgTime === 0) {
          this.msgTime = MSGTIME
          this.msgText = MSGINIT
          this.msgKey = false
          clearInterval(time)
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-group__append {
  cursor: pointer;
}
.classification {
  padding-top: 24px;
  .msg-text {
    display: block;
    width: 101px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    padding: 12px 20px;
  }
}
.bindView {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
