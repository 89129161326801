var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择产品",
            visible: _vm.visible,
            "lock-scroll": true,
            top: "50px",
            width: "62%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialogBox" }, [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c(
                  "el-form",
                  {
                    ref: "formData",
                    attrs: {
                      model: _vm.formData,
                      inline: true,
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "goodsOneClassId" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { "max-width": "130px" },
                            attrs: {
                              size: "small",
                              placeholder: "一级分类",
                              clearable: "",
                            },
                            on: { change: _vm.changeOneClassId },
                            model: {
                              value: _vm.formTool.goodsOneClassId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formTool, "goodsOneClassId", $$v)
                              },
                              expression: "formTool.goodsOneClassId",
                            },
                          },
                          _vm._l(_vm.goodsOneClassIdOptions, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "goodsTwoClassId" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { "max-width": "130px" },
                            attrs: {
                              size: "small",
                              placeholder: "二级分类",
                              clearable: "",
                            },
                            on: { change: _vm.changeTwoClassId },
                            model: {
                              value: _vm.formTool.goodsTwoClassId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formTool, "goodsTwoClassId", $$v)
                              },
                              expression: "formTool.goodsTwoClassId",
                            },
                          },
                          _vm._l(_vm.goodsTwoClassIdOptions, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "goodsThreeClassId" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { "max-width": "130px" },
                            attrs: {
                              size: "small",
                              placeholder: "三级分类",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formTool.goodsThreeClassId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formTool, "goodsThreeClassId", $$v)
                              },
                              expression: "formTool.goodsThreeClassId",
                            },
                          },
                          _vm._l(_vm.goodsThreeClassIdOptions, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "name" } },
                      [
                        _c("el-input", {
                          staticStyle: { "max-width": "140px" },
                          attrs: {
                            placeholder: "请输入药品名称",
                            size: "small",
                          },
                          model: {
                            value: _vm.formTool.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "name", $$v)
                            },
                            expression: "formTool.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "factory" } },
                      [
                        _c("el-input", {
                          staticStyle: { "max-width": "150px" },
                          attrs: {
                            placeholder: "请输入厂家关键词",
                            size: "small",
                          },
                          model: {
                            value: _vm.formTool.factory,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "factory", $$v)
                            },
                            expression: "formTool.factory",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "approvalNumber" } },
                      [
                        _c("el-input", {
                          staticStyle: { "max-width": "160px" },
                          attrs: {
                            placeholder: "请输入药品批准文号",
                            size: "small",
                          },
                          model: {
                            value: _vm.formTool.approvalNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "approvalNumber", $$v)
                            },
                            expression: "formTool.approvalNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.getList()
                              },
                            },
                          },
                          [_vm._v("检索")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.reset()
                              },
                            },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { ref: "tableCont", staticClass: "contentCont" }, [
              _c(
                "div",
                { staticClass: "contentContinner" },
                _vm._l(_vm.tableData, function (item, idnex) {
                  return _c("tableItem", {
                    key: idnex,
                    attrs: { item: item },
                    on: { chooseProduct: _vm.chooseProduct },
                  })
                }),
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "mt20 footer-bar",
                staticStyle: { "margin-bottom": "24px" },
              },
              [
                _c("Pagination", {
                  attrs: {
                    total: _vm.page.total,
                    "page-sizes": _vm.page.pageSizes,
                    page: _vm.page.current,
                    limit: _vm.page.size,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.page, "current", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.page, "size", $event)
                    },
                    pagination: _vm.pageChange,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }