// 复制剪切板功能
import Vue from 'vue'
import Clipboard from 'clipboard'

function clipboardSuccess() {
  Vue.prototype.$message.success('复制到剪切板成功')
}

function clipboardError() {
  Vue.prototype.$message.error('复制到剪切板失败')
}

export default function handleClipboard(text, event) {
  const clipboard = new Clipboard(event.target, {
    text: () => text
  })
  clipboard.on('success', () => {
    clipboardSuccess()
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    clipboardError()
    clipboard.destroy()
  })
  clipboard.onClick(event)
}

export function webClip(txt){
  if(!txt){
    return;
  }
  if (navigator.clipboard && window.isSecureContext) {
  // if (false) {
    navigator.clipboard.writeText(txt)
    .then(() => {
      clipboardSuccess()
    })
    .catch(err => {
      clipboardError()
    });
  }else{
    const textArea = document.createElement('textarea');
    textArea.value = txt;
    textArea.style.position = 'fixed';
    textArea.style.top = '-100vh';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if(successful){
        clipboardSuccess()
      }else{
        clipboardError()
      }
    } catch (err) {
      clipboardError()
    }
    document.body.removeChild(textArea);
  }
}