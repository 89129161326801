<template>
    <div class="dialog">
        <el-dialog :title="title" :visible.sync="visible" :lock-scroll="true" width="1000px" :before-close="handleClose" :close-on-click-modal="false">
            <el-form ref="formTool" :model="formTool" :inline="true" style="text-align: left" label-width="140px"  >
                <div class="goodList">
                    <div class="img-div">
                        <el-image class="l" :src="formTool.image ? formTool.image : avater" alt="" />
                    </div>
                    <div class="r">
                        <div>
                            <span class="txt-line-1 name">{{ formTool.name }}</span>
                        </div>
                        <div>
                            <span class="rr txt-line-1">{{ formTool.specification }}</span>
                        </div>
                        <div>
                            <span class="rr txt-line-1">{{ formTool.approvalNumber }}</span>
                        </div>
                        <div>
                            <span class="rr txt-line-1">{{ formTool.factory }}</span>
                        </div>
                    </div>
                </div>

                <el-row class="reject-c" v-if="pageType != 'add'">
                    <div class="title">
                        驳回原因
                    </div>
                    <el-input v-model="formTool.remark" type="textarea" clearable :disabled="true" style="width:50%" />

                </el-row>


                <div class="left_topic mb20" style="margin-top: 10px;">推广费用设置</div>
                <el-row class="list">
                    <el-form-item prop="salesUserRate" label="推广费用比例"
                        :rules="{ required: true, message: '', trigger: 'blur' , validator: checkNumber}">
                        <el-input v-model="formTool.salesUserRate" placeholder="请输入" clearable size="small"
                            @input="salesUserRateSet" :disabled="isDisabled"  />
                    </el-form-item>
                    % (输入范围5~50)

                </el-row>
                <el-row class="list">
                    <el-form-item prop="promoteRate" label="推广服务费比例">
                        <el-input v-model="formTool.promoteRate" disabled clearable size="small" />
                    </el-form-item>
                    %

                </el-row>

                <!-- <el-row class="list">
                    <el-form-item prop="rate" label="技术服务费比例">
                        <el-input v-model="formTool.rate" clearable size="small" disabled />
                    </el-form-item>
                    %
                </el-row> -->

                <div class="left_topic mb20" style="margin-top: 10px">试用期任务及合作期限</div>
                <el-row class="list">
                    <el-form-item prop="probationDays" label="试用期"
                        :rules="{ required: true, message: '请填写试用期', trigger: 'blur' }">
                        <el-input v-model="formTool.probationDays" placeholder="请输入" clearable size="small"
                            :disabled="isDisabled" />

                    </el-form-item>
                    天 <span style="margin-left: 10px; color:#999">建议不超过90天</span>
                </el-row>

                <el-row class="list">
                    <el-form-item prop="probationAmount" label="试用任务要求"
                        :rules="{ required: true, message: '请填写试用任务要求', trigger: 'blur' }">
                        <el-input v-model="formTool.probationAmount" placeholder="请输入" clearable size="small"
                            :disabled="isDisabled" />
                    </el-form-item>
                    {{ formTool.goodsSkuUnit || '' }}
                </el-row>
                <el-row class="list">
                    <el-form-item prop="cooperateDays" label="合作期"
                        :rules="{ required: true, message: '请填写合作期', trigger: 'blur' }">
                        <el-input v-model="formTool.cooperateDays" placeholder="请输入" clearable size="small"
                            :disabled="isDisabled" />
                    </el-form-item>
                    天 <span style="margin-left: 10px; color:#999">建议大于365天</span>
                </el-row>
            </el-form>
            <div class="btnBox" v-if="!isDisabled">
                <el-button size="small" @click="handleClose">取消</el-button>
                <el-button type="primary" size="small" @click="chooseGoods">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import avater from "/public/img/default.png";

import goodsItem from "./controlSalesGoodsItem.vue";
import { addDeptGoods, updateDeptGoods } from "@/api/externalSalesRule";
import { getUnitFeeRate } from "@/api/product";
export default {
    components: {
        goodsItem
    },
    props: {
        btnType: {
            type: String,
            default: ''
        },
    },
    computed: {
        visible() {
            return this.dialogShow
        }
    },
    data() {
      
        return { 
            avater: avater,
            showForm: false, //是否展示表单
            dialogShow: false,
            isDisabled: false,
            pageType: '',
            info: {},
            formTool: {
                salesUserRate: '',
                probationDays: '',
                cooperateDays: '',
                probationAmount: '',
                salesUserAmount: '',
                promoteRate: '',
                rate: ''
            },
            title: '',
            goodsList: [],
            selectItems: [],
            page: {
                total: 10,
                current: 1,
                size: 10,
                pageSizes: [10, 20, 30, 50],
            },
            promoteRateReuslt:'' //返回的推广服务费比例
        }
    },
    mounted() {
    },
    methods: {
        checkNumber (rule, value, callback) { 
            if(value<5 || value>50){
                callback(new Error("输入范围5~50，必须是整数")); 
            } 
            const reg = /^(\d{1,8}(\.\d{0})?)$/;   // 金额验证，最多两位小数
            if (!reg.test(value)) { 
                    callback(new Error("输入范围5~50，必须是整数"));
                } else { 
                    callback();
                }
        },
        salesUserRateSet(val) { 
            if(val > 50){
                this.formTool.salesUserRate = 50;
                val = 50;
            } 
            
             this.formTool.promoteRate = val * this.promoteRateReuslt ;
             this.formTool.promoteRate =  this.formTool.promoteRate.toFixed(2);
        },
        init(row, type) {
            this.pageType = type;
            console.log(row, '---row')
            const newRow = Object.assign(this.formTool, row);
            this.formTool = newRow;  
            this.title = ''; 
            
            if (type == 'reset') {
                this.isDisabled = false;
                this.title = '重新提交推广商品';
                if (newRow.salesUserRate) {
                    this.formTool.salesUserRate = newRow.salesUserRate * 100;
                } 
            }
            if (type == 'add') {
                this.isDisabled = false;
                this.title = '新增推广商品'; 
                this.formTool.salesUserRate = "";
                this.formTool.probationDays = "";
                this.formTool.cooperateDays = "";
                this.formTool.probationAmount = "";
                this.formTool.salesUserAmount = "";
                this.formTool.promoteRate = ""; 
            }

            this.dialogShow = true;
            this.$forceUpdate();
            this.getUnitFeeRate(type, newRow);  
        },

        getUnitFeeRate(type, newRow) {
            getUnitFeeRate({ shopId: this.formTool.shopId, goodsId: this.formTool.id }).then((res) => {
                if (res) {
                    this.formTool.goodsUnit = res.goodsUnit;
                    this.formTool.rate =( res.rate * 100).toFixed(2);
                    // this.formTool.promoteRate = res.promoteRate * 100;
                    this.promoteRateReuslt = res.promoteRate;
                    if (type == 'reset') {
                        if (newRow.salesUserRate) {
                            this.formTool.promoteRate = newRow.salesUserRate * res.rate; //推广服务费显示
                            this.formTool.promoteRate =  this.formTool.promoteRate.toFixed(2)
                            console.log(this.formTool.promoteRate)
                        }
                    } 
                }
            }).catch(err => {
                if (err && err.msg) {
                    this.$message.error(err.msg)
                }
            })
        },
        // 搜索
        change(e) {
            console.log(e)
        },
        // 页码变化
        handleCurrentChange(e) {
            this.page.current = e
        },
        // 条数变化
        handleSizeChange(e) {
            this.page.size = e
            this.page.current = 1
        },

        handleClose() {
            this.dialogShow = false;
        },
        chooseItem(arr) {
            this.selectItems = arr;
            // this.pageType = 'addProduct';
            // this.showForm = true;
        },
        cancel() {
            this.dialogShow = false;
        },
        chooseGoods() {
            
            if(this.formTool.salesUserRate<5 || this.formTool.salesUserRate>50){
                this.$message.error('推广费用比例，设置范围值5%~50%') 
                return
            } 
            let params = {}; 
            params.salesUserRate = this.formTool.salesUserRate / 100;
            params.probationDays = this.formTool.probationDays;
            params.cooperateDays = this.formTool.cooperateDays;
            params.probationAmount = this.formTool.probationAmount;
            params.goodsId = this.formTool.id;
            params.deptUserId = this.formTool.deptUserId;
            if (this.pageType == 'reset') {
                params.deptId = this.formTool.deptId;
                updateDeptGoods(params).then((res) => {
                    if (res) {
                        this.$message.success('提交成功')
                        this.$emit('getList')
                        this.handleClose();
                    }
                });
            } else {
                addDeptGoods(params).then((res) => {
                    if (res) {
                        this.$message.success('提交成功')
                        this.$emit('getList')
                        this.handleClose();
                    }
                });
            }

        }
    }
}
</script>
<style lang="scss">
.btnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.serach {
    margin-bottom: 10px;
}

.goods-content {
    max-height: 70vh;
    overflow-y: auto;
}

.goodList {
    display: flex;
    justify-content: space-around;

    &:nth-child(3n) {
        margin-right: 0px;
    }

    .img-div {
        height: 88px;
        width: 88px;
        margin: 0 10px;

        image {
            width: 100%;
            height: 100%;
        }
    }

    .l {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
    }

    .r {
        width: 214px;
        display: flex;
        flex: 1;
        flex-direction: column;

        div {
            display: flex;
        }

        span {
            font-size: 13px;
            color: #333;
            //margin-top: 10px;
        }

        .name {
            text-align: left;
            font-size: 16px;
            font-weight: 600;
            color: var(--color-26);
        }

        .rr {
            text-align: left;
            color: var(--color-59);
            font-size: 14px;
        }
    }

    &:hover {
        //  border: 2px solid var(--main-color) !important;
    }
}

.reject-c {
    margin-top: 10px;

    .title {
        line-height: 30px;
    }
}

.list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    line-height: 40px;
}
</style>