var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent noticeDetail" }, [
    _c("div", { staticClass: "tit" }, [
      _vm._v(" " + _vm._s(_vm.pageTitle) + " "),
    ]),
    _c(
      "div",
      { staticClass: "form" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "100px",
              disabled: _vm.type == 2,
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "标题", prop: "title" } },
              [
                _c("el-input", {
                  staticStyle: { width: "30%" },
                  attrs: { size: "small", maxlength: "50" },
                  model: {
                    value: _vm.ruleForm.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "title", $$v)
                    },
                    expression: "ruleForm.title",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "公告类型", prop: "type" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { size: "small", placeholder: "请选择公告类型" },
                    model: {
                      value: _vm.ruleForm.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "type", $$v)
                      },
                      expression: "ruleForm.type",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "公告", value: 1 } }),
                    _c("el-option", { attrs: { label: "质量公示", value: 2 } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "排序", prop: "sort" } },
              [
                _c("el-input", {
                  staticStyle: { width: "30%" },
                  attrs: {
                    size: "small",
                    type: "number",
                    oninput:
                      "\n              if (value.length > 3) {\n                value = value.slice(0, 3);\n              }\n              value = value.replace(/[^0-9]/g, '');\n            ",
                  },
                  model: {
                    value: _vm.ruleForm.sort,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "sort", $$v)
                    },
                    expression: "ruleForm.sort",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "公告内容", prop: "content" } },
              [
                _c("wangEnduit", {
                  attrs: {
                    value: _vm.ruleForm.content,
                    "is-clear": _vm.isClear,
                    "is-disabled": _vm.isDisabled,
                  },
                  on: { changeEndit: _vm.watchEndit },
                }),
              ],
              1
            ),
            !_vm.isDisabled
              ? _c(
                  "el-form-item",
                  { staticStyle: { "text-align": "right" } },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "small" }, on: { click: _vm.cancel } },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.submitForm("ruleForm")
                          },
                        },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.isDisabled
          ? _c(
              "div",
              { staticStyle: { "text-align": "right", "margin-top": "10px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.cancel },
                  },
                  [_vm._v("返回")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }