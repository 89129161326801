var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "voucher-detail-root" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "voucher-dialog",
          attrs: {
            title: _vm.title,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "518px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.onClosed,
          },
        },
        [
          _c("div", { staticClass: "content form-content" }, [
            _c("div", { staticClass: "row big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("凭证名称")]),
              _c(
                "div",
                { staticClass: "value input fill" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      maxlength: "50",
                      disabled: _vm.isDisabled,
                      placeholder: "请输入凭证名称",
                    },
                    model: {
                      value: _vm.voucherName,
                      callback: function ($$v) {
                        _vm.voucherName = $$v
                      },
                      expression: "voucherName",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("凭证金额")]),
              _c(
                "div",
                { staticClass: "value input fill" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      type: "number",
                      maxlength: "10",
                      disabled: _vm.isDisabled,
                      placeholder: "请输入凭证金额",
                    },
                    on: { input: (e) => _vm.thresholdReg("voucherPrice", e) },
                    model: {
                      value: _vm.voucherPrice,
                      callback: function ($$v) {
                        _vm.voucherPrice =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "voucherPrice",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("附件")]),
              _c(
                "div",
                { staticClass: "value input fill" },
                [
                  _c("ossFileUpload", {
                    ref: "refPicFileUpload",
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      limit: _vm.maxFileCount,
                      accept: _vm.picFileExt,
                      maxFileLength: 10,
                      ossUpload: true,
                      ossPathDir: "shop/pic/",
                      disabled: _vm.isDisabled,
                    },
                    model: {
                      value: _vm.picFileList,
                      callback: function ($$v) {
                        _vm.picFileList = $$v
                      },
                      expression: "picFileList",
                    },
                  }),
                  !_vm.isDisabled
                    ? _c("div", { staticClass: "small-tip warning" }, [
                        _c("span", [
                          _vm._v(
                            "最多支持" +
                              _vm._s(_vm.maxFileCount) +
                              "张，单张不超过10M； 支持 .png .jpg .pdf"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row big small" }, [
              _c("div", { staticClass: "tip" }),
              _c("div", { staticClass: "value input fill" }, [
                _vm._v(" 建议您使用康贝转账，平台支付工具便捷安全 "),
              ]),
            ]),
            _c("div", { staticClass: "row big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("凭证类型")]),
              _c(
                "div",
                { staticClass: "value input fill" },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.isDisabled },
                      model: {
                        value: _vm.voucherType,
                        callback: function ($$v) {
                          _vm.voucherType = $$v
                        },
                        expression: "voucherType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("康贝转账"),
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("其它")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm.voucherRefusalList && _vm.voucherRefusalList.length > 0
              ? _c("div", { staticClass: "rejected-layout" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("驳回记录")]),
                  _c(
                    "div",
                    { staticClass: "v-list" },
                    _vm._l(_vm.voucherRefusalList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "v-list-item" },
                        [
                          _c("div", { staticClass: "left" }, [
                            _c("div", { staticClass: "line up" }),
                            _c("div", { staticClass: "dot" }),
                            _c("div", { staticClass: "line down" }),
                          ]),
                          _c("div", { staticClass: "datas-view" }, [
                            _c("div", { staticClass: "reason" }, [
                              _vm._v(_vm._s(item.reason)),
                            ]),
                            _c("div", { staticClass: "time" }, [
                              _vm._v("驳回时间：" + _vm._s(item.createTime)),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v("关闭")]
              ),
              _vm.isUpdate
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
              _vm.isCheck
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleAuditOk },
                    },
                    [_vm._v("通过")]
                  )
                : _vm._e(),
              _vm.isCheck
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleRejust },
                    },
                    [_vm._v("驳回")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c("rejustReason", {
        ref: "refRejustReason",
        on: { onConfirm: _vm.handleAuditFail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }