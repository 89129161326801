import request from '@/utils/request'
import { Message } from 'element-ui'

// 登录
export function invoiceLogin(data) {
  return request({
    url: "/invoice/elephantInvoice/login",
    method: "post",
    data
  });
}
// 责任人类型列表
export function getPersonInChargeType(data) {
  return request({
    url: "/invoice/elephantInvoice/getPersonInChargeType",
    method: "get",
    params:data
  });
}
// 组织类型
export function getOrgType(data) {
  return request({
    url: "/invoice/elephantInvoice/getOrgType",
    method: "get",
    params:data
  });
}
// 税务机关
export function getTaxCode(data) {
  return request({
    url: "/invoice/invoiceDic/getTaxCode",
    method: "get",
    params:data
  });
}
// 邮编
export function getZipCode(data) {
  return request({
    url: "/invoice/invoiceDic/getZipCode",
    method: "get",
    params:data
  });
}
// 纳税人类型
export function  getTaxPayerType(data) {
  return request({
    url: "/invoice/elephantInvoice/getTaxPayerType",
    method: "get",
    params:data
  });
}
// 获取税控设备类型列表
export function  getTaxDriverType(data) {
  return request({
    url: "/invoice/elephantInvoice/getTaxDriverType",
    method: "get",
    params:data
  });
}
// 保存开票信息
export function  taxSave(data) {
  return request({
    url: "/invoice/elephantInvoice/save",
    method: "post",
    data
  });
}

// 查询已保存的开票信息 by shopId
export function  getTaxInfoByShopId(shopId) {
  return request({
    url: `/invoice/elephantInvoice/get/${shopId}`,
    method: "get",
    params:{}
  });
}
// 实名二维码生成

// 查询已保存的开票信息 by shopId
export function  getInvoiceQrCode(data) {
  return request({
    url: `/invoice/elephantInvoice/invoiceQrCode`,
    method: "post",
    data
  });
}

// 获取企业授权链接
export function getCompanyAuthUrl(data) {
    return request({
        url: `/invoice/faddContract/getCompanyAuthUrl`,
        method: "get",
        params: data,
    });
}
// 获取发票类型
export function getInvoiceType(data) {
  return request({
      url: `/invoice/elephantInvoice/getInvoiceType`,
      method: "get",
      params: data,
  });
}
// 发票系统查询省地医药公司下商户
export function getShopsUnderShop(data) {
  return request({
      url: `/shop/dfc/invoice`,
      method: "get",
      params: data,
  });
}
// 获取诊所抬头
export function cominvoicetitleinfo(shopId) {
  return request({
      url: `/invoice/compliance/cominvoicetitleinfo/seller/detail/${shopId}`,
      method: "get",
      params: {},
  });
}

// 根据shopid查询维护的开票信息
export function getShopInfoByShopId(shopId) {
  return request({
      url: `/invoice/elephantInvoice/get/${shopId}`,
      method: "get",
      params: {},
  });
}
// 根据收款方和付款方查询未开票数据
export function getInvoiceTotalBySellerIdAndPayerId(data) {
  return request({
      url: `/invoice/invoiceData/totalAmount`,
      method: "get",
      params: data,
  });
}
// 根据收款方和付款方查询未开票数据
export function getInvoiceListBySellerIdAndPayerId(data) {
  return request({
      url: `/invoice/invoiceData/page`,
      method: "get",
      params: data,
  });
}
// 开具发票
export function  doInvoice(data) {
  return request({
    url: `/invoice/invoiceData/doInvoice`,
    method: "post",
    data
  });
}
// 开票记录查询
export function getInvoicePageList(data) {
  return request({
      url: `/invoice/invoiceData/invoicePage`,
      method: "get",
      params: data,
  });
}
// 蓝字发票开具金额
export function getInvoiceTotalAmount(data) {
  return request({
      url: `/invoice/invoiceData/invoiceTotalAmount`,
      method: "get",
      params: data,
  });
}
// 企业基本信息查询接口
export function  getCompanyInfo(data) {
  return request({
    url: `/invoice/elephantInvoice/companyInfo`,
    method: "post",
    data
  });
}
// 获取发票pdf base64内容
export function getInvoicePdf(data) {
  return request({
      url: `/invoice/invoiceData/getInvoicePdf`,
      method: "get",
      params: data,
  });
}

// 实名认证二维码 结果查询
export function  invoiceQrCodeQuery(data) {
  return request({
    url: `/invoice/elephantInvoice/invoiceQrCodeQuery`,
    method: "post",
    data
  });
}


//收到发票记录查询
export function getCustomerInvoicePage(data) {
  return request({
      url: `/invoice/invoiceData/customerInvoicePage`,
      method: "get",
      params: data,
  });
}

// /invoiceData/getSaleList
// 销售方列表
export function getSaleList(data) {
  return request({
      url: `/invoice/invoiceData/getSaleList`,
      method: "get",
      params: data,
  });
}


// ------------------发票管理里面接口----------------------
// /invoice/compliance/comapplyinvoiceinfo/sales/seller/page  			----------------------------查询申请开票记录
// /invoice/compliance/comapplyinvoiceinfo/sales/save  			----------------------------申请发票保存
// /invoice/compliance/cominvoicetitleinfo/sales/detail 			----------------------------查询发票抬头信息
// /invoice/compliance/cominvoicetitleinfo/sales/save			----------------------------发票抬头保存
// /order/invoice/sales/technology/open/invoice 	----------------------------春播技术费开票 发票得到订单信息通过订单代码
// /statistic/account/checking/sales/closePay/page 		---------------------------- 结算对账
// /statistic/account/checking/sales/closePay/open 		----------------------------结算对账-申请开票

// --------查询申请开票记录
export function getComapplyinvoiceinfo(data) {
  return request({
      url: `/invoice/compliance/comapplyinvoiceinfo/sales/seller/page`,
      method: "get",
      params: data,
  });
} 

// ----------------------------申请发票保存
export function saveComapplyinvoiceinfo(data) {
  return request({
      url: `/invoice/compliance/comapplyinvoiceinfo/sales/save`,
      method: "post",
      data: data,
  });
} 

// ----------------------------查询发票抬头信息
export function getCominvoicetitleinfo(data) {
  return request({
      url: `/invoice/compliance/cominvoicetitleinfo/sales/detail`,
      method: "get",
      params: data,
  });
} 

// ----------------------------编辑 发票抬头信息
export function saveCominvoicetitleinfo(data) {
  return request({
      url: `/invoice/compliance/cominvoicetitleinfo/sales/save`,
      method: "post",
      data: data,
  });
} 

// /statistic/account/checking/sales/closePay/page ---------------------------- 结算对账（发票申请页面列表）
export function closePayGet(data) {
  return request({
      url: `/statistic/account/checking/sales/closePay/page`,
      method: "get",
      params: data,
  });
} 


// 结算对账-申请开票
export function closePayOpen(data) {
  return request({
      url: `/statistic/account/checking/sales/closePay/open`,
      method: "get",
      params: data,
  });
} 

// 春播技术费开票
export function invoice(data) {
  return request({
    url: `/order/invoice/sales/technology/open/invoice`,
    method: 'get',
    params:data
  })
}


// /clientAutomaticBilling/queryClientAutomaticBillingVO
// 查询自动开票信息
export function queryClientAutomaticBillingVO(data) {
  return request({
    url: `/invoice/clientAutomaticBilling/queryClientAutomaticBillingVO`,
    method: 'post',
    data:data
  })
}

// 查询自动开票 操作历史
// 根据开票id查询订单开票记录
export function queryBillingRecordVO(data) {
  return request({
    url: `/invoice/clientAutomaticBilling/queryBillingRecordVO`,
    method: 'get',
    params:data
  })
}

// /clientAutomaticBilling/clientAutomaticBillingExcel
// 根据查询条件导出客户开票excel表格
export function clientAutomaticBillingExcel(
  data,
  apiUrl = "/invoice/clientAutomaticBilling/clientAutomaticBillingExcel",
  exportName = "客户自动开票列表"
) {
  return request({
    url: apiUrl,
    method: "get",
    data: data,
    responseType: "blob",
  }).then((response) => {
    console.log(response, "res");
    // 处理返回的文件流
    const blob = response.data;
    if ((blob && blob.size === 0) || response.size == 0) {
      Message.warning("内容为空，无法下载");
      return;
    }
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    const contentDisposition = response.headers["content-disposition"];
    const fileName = decodeURIComponent(
      contentDisposition.substring(contentDisposition.indexOf("=") + 1)
    );
    link.download = exportName + "数据导出" + fileName;
    document.body.appendChild(link);
    link.click();
    window.setTimeout(function () {
      URL.revokeObjectURL(blob);
      document.body.removeChild(link);
    }, 0);
  });
}

// 已线下开票
// /invoice/clientAutomaticBilling/offlineBilling

export function offlineBilling(data) {
  return request({
    url: `/invoice/clientAutomaticBilling/offlineBilling`,
    method: 'post',
    data:data
  })
}
 

// invoice/elephantInvoice/list
// 查询企业列表
export function getElephantInvoiceList(data) {
  return request({
    url: `/invoice/elephantInvoice/list`,
    method: 'get',
    params:data
  })
}

// invoice/elephantInvoice/getById
// 根据id查询企业信息
export function getElephantInvoiceById(id) {
  return request({
    url: `/invoice/elephantInvoice/getById/${id}`,
    method: 'get',
   
  })
}

// 验证康康贝账号发送短信验证码 
// invoice/elephantInvoice/sendKBSms
export function sendKBSms(data) {
  return request({
    url: `/invoice/elephantInvoice/sendKBSms`,
    method: 'post',
    params:data
  })
}

// 绑定康贝号提交 
// invoice/elephantInvoice/bindKB
export function bindKB(data) {
  return request({
    url: `/invoice/elephantInvoice/bindKB`,
    method: 'post',
    data:data
  })
}

// 修改企业抬头信息
// invoice/elephantInvoice/update
export function updateElephantInvoice(data) {
  return request({
    url: `/invoice/elephantInvoice/update`,
    method: 'post',
    data:data
  })
}

// /clientAutomaticBilling/queryUnbilledQuantity
// 查询未开票数量
export function queryUnbilledQuantity(data) {
  return request({
    url: `/invoice/clientAutomaticBilling/queryUnbilledQuantity`,
    method: 'GET',
    params:data
  })
}

// invoice/elephantInvoice/listByLoginUser
// 查询企业列表
export function listByLoginUser(data) {
  return request({
    url: `/invoice/elephantInvoice/listByLoginUser`,
    method: 'GET',
    params:data
  })
}

// /clientAutomaticBilling/invoicing
// 自动开票
export function  doInvoicing(data) {
  return request({
    url: `/invoice/clientAutomaticBilling/invoicing`,
    method: "post",
    data
  });
}

// /invoice/invoiceTitleRateTypes
// 查询发票税率
export function invoiceTitleRateTypes(data) {
  return request({
    url: `/invoice/invoice/invoiceTitleRateTypes`,
    method: "GET",
    params:data
  });
}
// 查询大象发票类型列表
export function getElephantInvoiceTypeList(data) {
  return request({
    url: `/invoice/fp/invoiceRate/getDXInvoiceType`,
    method: "GET",
    params:data
  });
}
//20240625 搜索发票项目
export function searchInvoiceProjectRate(data) {
  return request({
    url: `/invoice/fp/invoiceProjectRate/searchInvoiceProjectRate`,
    method: "GET",
    params:data
  });
}
// 20240625 CSO 公司开发票
export function openInvoiceCreateBizRecord(data) {
  return request({
    url: `/invoice/fp/invoiceBizRecord/openInvoiceCreateBizRecord`,
    method: "POST", 
    data:data
  });
}