import request from "@/utils/request";
// 手工上传发票
// export function manual(data) {
//   return request({
//     url: "/order/invoice/manual",
//     method: "post",
//     contentType: 'blob',
//     data
//   });
// }

// 医药公司分页查询合同
export function contractPage(data) {
  return request({
    url: "/shop/hg/contract/page",
    method: "get",
    params: data,
  });
}

// 查看合同详情
export function contractDetail(id) {
  return request({
    url: `/shop/hg/contract/detail/${id}`,
    method: "get",
    params: {},
  });
}

// 编辑合同商品
export function contractEdit(data) {
  return request({
    url: "/shop/hg/contract/editGoods",
    method: "PATCH",
    data,
  });
}

// 获取企业电子签授权链接
export function contractAuthSign(params) {
  return request({
    url: `/invoice/faddContract/auth/sign`,
    method: "get",
    params: params,
  });
}

// 查询授权状态
export function companyAuthStatus(params) {
  return request({
    url: `/invoice/faddContract/queryCompanyAuthStatus`,
    method: "get",
    params: params,
  });
}

// 创建合同任务接口
export function companyAuthCreate(params) {
  return request({
    url: `/invoice/faddContract/auth/create`,
    method: "get",
    params: params,
  });
}
// 获取编辑合同文件地址
export function contractEditTaskUrl(params) {
  return request({
    url: `/invoice/faddContract/edit/taskUrl`,
    method: "get",
    params: params,
  });
}

// 获取查看合同文件地址
export function contractViewTaskUrl(params) {
  return request({
    url: `/invoice/faddContract/view/taskUrl`,
    method: "get",
    params: params,
  });
}
// 获取签署合同文件地址
export function contractSignTaskUrl(params) {
  return request({
    url: `/invoice/faddContract/sign/taskUrl`,
    method: "get",
    params: params,
  });
}

// 医药公司分页查询合同
export function contractCsoPage(data) {
  return request({
    url: "/shop/hg/contract/csoPage",
    method: "get",
    params: data,
  });
}

// 立即同步签署任务列表
export function contractTaskList(data) {
  return request({
    url: "/invoice/faddContract/queryTaskList",
    method: "get",
    params: data,
  });
}
// 立即同步签署任务列表CSO
export function contractCSOTaskList(data) {
  return request({
    url: "/invoice/faddContract/queryCsoTaskList",
    method: "get",
    params: data,
  });
}

// 法大大开通电子签重定向保存openId
export function faddOpen(data) {
  return request({
    url: "/shop/shop/fadd/open",
    method: "get",
    params: data,
  });
}


// 搜索合并
export function searchContract(params) {
  return request({
    url: "/shop/hg/contract/searchContract",
    method: "get",
    params: params,
  });
}
