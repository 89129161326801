<template>
  <div class="info_box">
    <!-- <el-divider content-position="left">文字资料</el-divider>
    <div class="info_ul">
      <div class="info_li">
        <div class="info_title">企业名称：</div>
        <div class="info_r">{{ info.shopName || '' }}</div>
      </div>
      <div class="info_li">
        <div class="info_title">企业类型：</div>
        <div class="info_r">{{ info.shopTypeName || '' }}</div>
      </div>
      <div class="info_li">
        <div class="info_title">医疗机构执业许可证：</div>
        <div class="info_r">{{ info.certifys[0].licenceNo || '' }}</div>
      </div>
      <div v-if="info.shopTypeCode == '3' || info.shopTypeCode == '4'" class="info_li">
        <div class="info_title">营业执照编码：</div>
        <div class="info_r">{{ info.certifys[0].idNumber || '' }}</div>
      </div>
      <div class="info_li">
        <div class="info_title">法人姓名：</div>
        <div class="info_r">{{ info.concatName || '' }}</div>
      </div>
      <div class="info_li">
        <div class="info_title">法人联系电话：</div>
        <div class="info_r">{{ info.concatPhone || '' }}</div>
      </div>
      <div class="info_li">
        <div class="info_title">售后服务电话：</div>
        <div class="info_r">{{ info.servicePhone || '' }}</div>
      </div>
      <div class="info_li">
        <div class="info_title">经营范围：</div>
        <div class="info_r"><span v-for="(li,i) in info.scopes" :key="i" class="info_txt">{{ li }}</span></div>
      </div>

      <div class="info_li">
        <div class="info_title">所在区域：</div>
        <div class="info_r">{{ info.provinceName+info.cityName+info.areaName+info.address }}</div>
      </div>
      <div class="info_li">
        <div class="info_title">发票类型：</div>
        <div class="info_r"><span v-for="(li,i) in info.invoiceTypes" :key="i" class="info_txt">{{ li }}</span></div>
      </div>
    </div>
    <el-divider content-position="left">图片资料</el-divider>
    <div class="info_ul" style="max-width:800px;margin-bottom:30px;">
      <div v-for="(ul,i) in certifys" :key="i" class="info_img_box">
        <div class="info_img_txt">
          <p>{{ allTypes[ul.code] }}</p>
          <p v-if="ul.code != '09'">证件有效期 {{ (ul.startValidDate || '') +' 至 '+ (ul.endValidDate || '') }}</p>
        </div>
        <div class="info_imgs">
          <el-image v-for="(li,j) in ul.imgs" :key="j" class="info_imgs_li" :src="li" :preview-src-list="ul.imgs" />
        </div>
        <div v-if="ul.code == '09'" class="info_r">备注：{{ info.remarks || '无备注' }}</div>
      </div>
    </div> -->
    <div>
      <el-form ref="info"
               :model="info"
               label-width="100px"
               class="ruleFormCont"
               label-position="top">
        <el-form-item label="企业类型"
                      class="formItemOne"
                      prop="selectNum">
          <div class="selectCont">
            <div v-for="(item, index) in shopList"
                 :key="index"
                 :class="{
                itemCont: true,
                mainActive: info.shopTypeName === item.typeName,
                mainBorder: info.shopTypeName != item.typeName,
              }"
                 @click="selectBtn(item, index)">
              <el-image :src="getStatus(info.shopTypeName, item.typeName)"
                        style="display: flex; margin-right: 10px" />
              {{ item.typeName }}
            </div>
          </div>
        </el-form-item>
        <div v-if="info.certifys.length > 0">
          <el-form-item label="医疗机构执业许可证编码"
                        v-if="info.shopTypeCode == '1'"
                        prop="licenceNo">
            <el-input v-model="info.licenceNo"
                      placeholder="请输入医疗机构执业许可证编码"
                      style="width: 400px"
                      :disabled="mode === 'view'" />
          </el-form-item>
        </div>
        <div v-if="info.certifys.length > 0">
          <el-form-item v-if="
              info.shopTypeCode == '2' ||
              info.shopTypeCode == '3' ||
              info.shopTypeCode == '4'
            "
                        label="营业执照编码"
                        prop="idNumber">
            <el-input v-model="info.certifys[0].idNumber"
                      placeholder="请输入营业执照编码"
                      :disabled="mode === 'view'"
                      style="width: 400px" />
          </el-form-item>
        </div>
        <el-form-item label="采购/收货人姓名"
                      prop="concatName">
          <el-input v-model="info.concatName"
                    placeholder="请输入采购/收货人姓名"
                    style="width: 400px"
                    :disabled="mode === 'view'" />
        </el-form-item>
        <el-form-item label="采购/收货人联系电话"
                      prop="concatPhone">
          <el-input v-model="info.concatPhone"
                    placeholder="请输入采购/收货人联系电话"
                    style="width: 400px"
                    :disabled="mode === 'view'" />
        </el-form-item>
        <el-form-item label="售后联系电话"
                      prop="servicePhone">
          <el-input v-model="info.servicePhone"
                    placeholder="请输入售后联系电话"
                    style="width: 400px"
                    :disabled="mode === 'view'" />
        </el-form-item>
        <el-form-item v-if="mode === 'view'"
                      label="经营范围"
                      :rules="[
            { required: true, message: `请选择经营范围`, trigger: `change` },
          ]"
                      prop="scopeIds">
          <el-checkbox-group v-model="info.scopeIds">
            <el-checkbox v-for="(item, index) in areaList"
                         :key="index + 'r'"
                         :label="item.id"
                         :name="item.id">{{ item.scopeName || "" }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="企业名称"
                      prop="name">
          <el-input v-model="info.shopName"
                    placeholder="请输入企业名称"
                    style="width: 400px"
                    :disabled="mode === 'view'" />
        </el-form-item>
        <el-form-item label="所在区域"
                      prop="province">
          <el-select :disabled="mode === 'view'"
                     v-model="info.provinceName"
                     @change="provinceBtn"
                     placeholder="请选择省"
                     style="width: 126px">
            <el-option v-for="(item, index) in provinceData"
                       :key="index"
                       :label="item.rname"
                       :value="item.rname" />
          </el-select>
          <el-select v-model="info.cityName"
                     placeholder="请选择市"
                     @change="cityBtn"
                     :disabled="mode === 'view'"
                     style="margin-left: 12px; width: 126px">
            <el-option v-for="(item, index) in cityData"
                       :key="index"
                       :label="item.rname"
                       :value="item.rname" />
          </el-select>
          <el-select v-model="info.areaName"
                     placeholder="请选择区"
                     @change="areaBtn"
                     :disabled="mode === 'view'"
                     style="margin-left: 11px; width: 126px">
            <el-option v-for="(item, index) in areaData"
                       :key="index"
                       :label="item.rname"
                       :value="item.rname" />
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址"
                      prop="address">
          <el-input v-model="info.address"
                    :disabled="mode === 'view'"
                    placeholder="请输入详细地址"
                    style="width: 400px" />
        </el-form-item>
        <el-form-item label="发票类型"
                      prop="invoiceType">
          <el-checkbox-group v-model="info.invoiceType">
            <el-checkbox v-for="(item, index) in invoicetypeData"
                         :key="index"
                         :disabled="mode === 'view'"
                         :label="item.code"
                         :name="item.name">{{ item.name || "" }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div class="ruleForm">
        <template v-for="(ul, index) in certifys">
          <div :key="index"
               label-width="800px"
               style="margin-bottom: 30px">
            <!-- :prop="infoFormTwo[ul.keyTime]" -->
            <!-- slot="label" -->
            <div style="width: 600px; margin-bottom: 10px">
              <div style="
                  display: flex;
                  justify-content: space-between;
                  font-size: 14px;
                  color: #262626;
                  align-items: center;
                ">
                <div>
                  <span v-if="ul.code != '09' && ul.require"
                        style="color: red; margin-right: 10px">*</span>{{
                    ul.code == "08" && info.shopTypeCode == "1"
                      ? "开票信息"
                      : allTypes[ul.code]
                  }}
                </div>
                <div v-if="
                    ul.code != '08' &&
                    ul.code != '09' &&
                    ul.code != '10' &&
                    ul.code != '06' &&
                    ul.code != '04'
                  ">
                  <el-checkbox v-model="ul.checkedOne"
                               @change="changeOne(ul)">效期10年</el-checkbox>
                  <el-checkbox v-model="ul.checkedTwo"
                               @change="changeTwo(ul)">效期20年</el-checkbox>
                </div>
                <div v-if="ul.code == '06'">
                  <el-checkbox v-model="ul.checkedOne"
                               @change="changeOne(ul)">效期1年</el-checkbox>
                  <el-checkbox v-model="ul.checkedTwo"
                               @change="changeTwo(ul)">年底</el-checkbox>
                </div>
                <div v-if="ul.code == '04'">
                  <el-checkbox v-model="ul.checkedOne"
                               @change="changeOne(ul)">效期3年</el-checkbox>
                  <el-checkbox v-model="ul.checkedTwo"
                               @change="changeTwo(ul)">效期5年</el-checkbox>
                </div>
                <!-- <div>
                  <el-checkbox v-model="ul.checkedOne" @change="changeOne(ul)"
                    >效期30年</el-checkbox
                  >
                  <el-checkbox v-model="ul.checkedTwo" @change="changeTwo(ul)"
                    >永久</el-checkbox
                  >
                </div> -->
                <div>
                  <span style="margin-right: 10px; font-weight: 400; color: #999"
                        class="mainColor">有效期</span>
                  <el-date-picker v-model="ul.date"
                                  @change="changeDate(ul)"
                                  :clearable="false"
                                  type="daterange"
                                  size="mini"
                                  format="yyyy-MM-dd"
                                  value-format="yyyy-MM-dd"
                                  range-separator="-"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期" />
                </div>
              </div>
            </div>
            <div class="uploadCont">
              <div class="imgList">
                <div v-for="(li, i) in ul.imgs"
                     :key="i"
                     class="imgList_li">
                  <!-- <el-image class="imgList_li_img"
                            style=""
                            :src="li"
                            :preview-src-list="ul.imgs"
                            :disabled="mode === 'view'" /> -->
                  <img :src="li"
                       style="width:100%;height: 100%;"
                       @click="clickImg(li)"
                       alt="">
                  <p class="imgList_li_close"
                     v-if="mode === 'edit'"
                     @click="imgDel(index, i)">
                    <i class="el-icon-delete" />
                  </p>
                </div>
                <div class="imgList_li imgList_li_add"
                     v-if="mode === 'edit'"
                     @click="goUpload(index, ul.type)">
                  <i class="el-icon-plus" />
                </div>
              </div>
              <div v-if="ul.desc"
                   class="desc">{{ ul.desc }}</div>
              <div v-if="ul.code == '09'"
                   class="more_input">
                <p class="more_p"
                   style="margin-bottom: 5px">备注</p>
                <el-input v-if="ul.code == '09'"
                          v-model="info.remarks"
                          type="txtarea"
                          :disabled="mode === 'view'" />
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <MyUpload ref="imgupload"
              :request-id="info.requestId"
              :imgType="imgType"
              @upload="uploadSubmit" />
    <div style="width: 30%;
    height: 100%;
    position: fixed;overflow: hidden;
    left: 0;
    top: 0px;
    z-index: 1031;"
         @mouseenter="zoomEnable=true"
         @mouseleave="zoomEnable=false"
         v-if="dialogDiv">
      <imageViewer v-if="showViewer"
                   :zoomEnable="zoomEnable"
                   ref="imageViewer"
                   :append-to-body="false"
                   style="width:30%;height:100%;overflow: hidden;"
                   :on-close="closeViewer"
                   :url-list="[src]" />
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { checkZizhiImages } from "@/utils/status";
import imageViewer from './imageViewer.vue'
// import top from '../index/top/index.vue'
// import { mapGetters } from 'vuex'
import {
  getShoparea,
  getUserarea,
  getinvoicetypeList,
} from "@/api/merchantsettlement/index";
import {
  regionProvince,
  regionCity,
  regionArea,
} from "@/api/merchantsettlement/index";
import MyUpload from "../../merchantsettlement/myupload.vue";

export default {
  components: {
    MyUpload,
    imageViewer
  },
  data () {
    return {
      src: '',
      showViewer: false,
      dialogDiv: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "效期30年",
            onClick (picker) {
              const end = new Date(
                new Date().getFullYear() + 30,
                new Date().getMonth(),
                new Date().getDate()
              );
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "永久",
            onClick (picker) {
              const end = new Date("9999/01/01");
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      licenceNo: "",
      provinceData: [],
      cityData: [],
      areaData: [],
      invoicetypeData: [], // 发票类型
      // 地区的列表
      areaList: [],
      info: {
        scopeIds: [],
        invoiceTypes: [],
        invoiceType: [],
        certifys: [],
      },
      imgType: "",
      currentImgIndex: 0,
      // 不同身份的列表
      shopList: [],
      allTypes: {
        "01": "营业执照",
        "02": "药品经营许可证",
        "03": "食品经营许可证",
        "04": "医疗机构许可证",
        "05": "被委托人身份证复印件(正反两面)",
        "06": "授权委托书",
        "07": "第二类医疗器械经营备案凭证",
        "08": "开票信息和开户许可证",
        "10": "质保协议",
        "09": "其他",
      },
      zoomEnable: false
    };
  },
  props: {
    mode: {
      type: String,
      default: () => {
        return "view";
      },
    },
  },
  watch: {},
  computed: {
    getStatus () {
      return (name, qiyeName) => checkZizhiImages(name, qiyeName);
    },
    certifys () {
      let nowDate = dayjs().format("YYYY-MM-DD");
      let futureDate = dayjs(this.nowDate).add(1, "year").format("YYYY-MM-DD");
      const { certifys } = this.info;
      certifys.forEach((element) => {
        let imgs = [];
        element.images.forEach((el) => {
          imgs.push(el.certityImage);
        });
        this.$set(element, "checkedOne", false);
        this.$set(element, "checkedTwo", false);
        this.$set(element, "imgs", imgs);
        this.$set(element, "date", [nowDate, futureDate]);
        if (element.code == "04") {
          this.licenceNo = element.licenceNo;
          futureDate = dayjs(nowDate)
            .add(5, "year")
            .add(-1, "day")
            .format("YYYY-MM-DD");
          element.date = [nowDate, futureDate];
        }
        // if (element.code == "06") {
        //   element.date = [
        //     nowDate,
        //     futureDate
        //   ];
        // }
      });
      return certifys;
    },
  },
  created () {
    this.getShoparea();
    this.getUserarea();
    this.getinvoicetypeList();
  },
  methods: {
    // 关闭图片弹窗
    closeViewer () {
      this.showViewer = false
      this.dialogDiv = false
    },
    // 点击图片
    clickImg (li) {
      this.src = li
      this.dialogDiv = true
      // console.log(li)
      this.$nextTick(() => {
        this.showViewer = true
      })
    },
    imgDel (index, i) {
      this.$confirm("此操作删除上传的图片, 是否确认操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.info.certifys[index].images.splice(i, 1);
        })
        .catch(() => {
          this.$message.warning("您已取消操作");
        });
    },
    uploadSubmit (obj) {
      const { type, url } = obj;
      this.info.certifys[this.currentImgIndex].images.push({
        certityImage: url,
        certityType: type,
      });
    },
    goUpload (index, type) {
      // console.log(type)
      this.currentImgIndex = index;
      this.imgType = type;
      this.$refs["imgupload"].open(type);
    },
    provinceBtn (item) {
      console.log("item", item);
      this.cityData = [];
      this.areaData = [];
      if (!item) {
        this.info.city = ``;
        this.info.area = ``;
        this.info.cityName = "";
        this.info.areaName = "";
      } else {
        const province = this.provinceData.find(p => p.rname === item).id;
        regionCity(province).then((res) => {
          this.cityData = res.data;
          this.city = ``;
          this.area = ``;
          console.log('this.info', this.info)
          this.info.province = province
          this.info.cityName = "";
          this.info.areaName = "";
        });
      }
    },
    // 改变选项
    changeOne (row) {
      console.log(row, "row");
      let nowDate = row.date[0];
      let futureDate = dayjs(nowDate)
        .add(10, "year")
        .add(-1, "day")
        .format("YYYY-MM-DD");
      switch (row.code) {
        case "01":
          futureDate = dayjs(nowDate).add(10, "year").format("YYYY-MM-DD");
          break;
        case "01":
          futureDate = dayjs(nowDate).add(10, "year").format("YYYY-MM-DD");
          break;
        case "02":
          futureDate = dayjs(nowDate).add(10, "year").format("YYYY-MM-DD");
          break;
        case "03":
          futureDate = dayjs(nowDate).add(10, "year").format("YYYY-MM-DD");
          break;
        case "04":
          futureDate = dayjs(nowDate)
            .add(3, "year")
            .add(-1, "day")
            .format("YYYY-MM-DD");

          break;
        case "05":
          futureDate = dayjs(nowDate).add(10, "year").format("YYYY-MM-DD");
          break;
        case "06":
          futureDate = dayjs(nowDate).add(1, "year").format("YYYY-MM-DD");
          break;
        default:
          futureDate = dayjs(nowDate).add(10, "year").format("YYYY-MM-DD");
          break;
      }
      row.checkedTwo = false;
      row.date = [nowDate, futureDate];
    },
    changeTwo (row) {
      row.checkedOne = false;
      console.log(row, "row");
      let nowDate = row.date[0];
      let futureDate = dayjs(nowDate)
        .add(20, "year")
        .add(-1, "day")
        .format("YYYY-MM-DD");
      switch (row.code) {
        case "01":
          futureDate = dayjs(nowDate).add(20, "year").format("YYYY-MM-DD");
          break;
        case "01":
          futureDate = dayjs(nowDate).add(20, "year").format("YYYY-MM-DD");
          break;
        case "02":
          futureDate = dayjs(nowDate).add(20, "year").format("YYYY-MM-DD");
          break;
        case "03":
          futureDate = dayjs(nowDate).add(20, "year").format("YYYY-MM-DD");
          break;
        case "04":
          futureDate = dayjs(nowDate)
            .add(5, "year")
            .add(-1, "day")
            .format("YYYY-MM-DD");

          break;
        case "05":
          futureDate = dayjs(nowDate).add(20, "year").format("YYYY-MM-DD");
          break;
        case "06":
          futureDate = new Date().getFullYear() + "-12-31";
          break;
        default:
          futureDate = dayjs(nowDate).add(20, "year").format("YYYY-MM-DD");
          break;
      }
      row.date = [nowDate, futureDate];
    },
    changeDate (row) {
      let nowDate = row.date[0];
      let futureDate = dayjs(this.nowDate).add(30, "year").format("YYYY-MM-DD");
      row.checkedOne = false;
      row.checkedTwo = false;
      if (row.date[0] == nowDate && row.date[1] == futureDate) {
        row.checkedOne = true;
      } else if (row.date[0] == nowDate && row.date[1] == "9999-01-01") {
        row.checkedTwo = true;
      }
    },
    cityBtn (item) {
      this.areaData = [];
      if (!item) {
        this.area = ``;
        this.info.areaName = "";
      } else {
        const city = this.cityData.find(c => c.rname === item).id
        // const cityIndex = this.cityData.findIndex((res) => res.id === item);
        // this.formTool.cityName = this.cityData[cityIndex].rname;
        regionArea(city).then((res) => {
          this.area = "";
          this.info.areaName = "";
          this.info.city = city
          this.areaData = res.data;
        });
      }
    },
    areaBtn (item) {
      if (!item) {
        this.area = ``;
      } else {
        this.info.area = this.areaData.find(a => a.rname === item).id
        // const areaIndex = this.areaData.findIndex((res) => res.id === item);
        // this.formTool.areaName = this.areaData[areaIndex].rname;
      }
    },
    // // 改变选项
    // changeOne(row) {
    //   let nowDate = dayjs().format("YYYY-MM-DD");
    //   let futureDate = dayjs(this.nowDate).add(30, "year").format("YYYY-MM-DD");
    //   row.checkedTwo = false;
    //   row.date = [nowDate, futureDate];
    // },
    // changeTwo(row) {
    //   let nowDate = dayjs().format("YYYY-MM-DD");
    //   row.checkedOne = false;
    //   row.date = [nowDate, "9999-01-01"];
    // // },
    // changeDate(row) {
    //   let nowDate = dayjs().format("YYYY-MM-DD");
    //   let futureDate = dayjs(this.nowDate).add(30, "year").format("YYYY-MM-DD");
    //   row.checkedOne = false;
    //   row.checkedTwo = false;
    //   if (row.date[0] == nowDate && row.date[1] == futureDate) {
    //     row.checkedOne = true;
    //   } else if (row.date[0] == nowDate && row.date[1] == "9999-01-01") {
    //     row.checkedTwo = true;
    //   }
    // },
    setInfo (info) {
      this.info = info;
      this.initPosition(info);
      if (!this.info.invoiceType) {
        getinvoicetypeList().then((res) => {
          const invoicetypeData = res.data;
          this.info.invoiceType = invoicetypeData
            .filter((item) => this.info.invoiceTypes.includes(item.name))
            .map((item) => item.code);
        });
      }
    },
    initPosition (info) {
      regionProvince()
        .then((res) => {
          if (res.code === 0) {
            this.provinceData = res.data;
            return regionCity(info.province);
          }
        })
        .then((res) => {
          this.cityData = res.data;
          return regionArea(info.city);
        })
        .then((res) => {
          this.areaData = res.data;
        });
    },
    getInfo () {
      return this.info;
    },
    getinvoicetypeList () {
      getinvoicetypeList().then((res) => {
        if (res.code === 0) {
          this.invoicetypeData = res.data;
        }
      });
    },
    getUserarea () {
      getUserarea().then((res) => {
        if (res.code === 0) {
          this.areaList = res.data;
        }
      });
    },
    getShoparea () {
      getShoparea(0).then((res) => {
        if (res.code === 0) {
          this.shopList = res.data;
          // this.numberValidateForm.typeCode = res.data[0].typeCode;
        }
      });
    },

  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
::v-deep .el-form--label-top .el-form-item__label {
  padding: 8px !important;
}
.info_box {
  width: 600px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  .formItemOne {
    width: 800px;
    position: relative;
    left: -16%;
  }
}
.down {
  margin-top: 60px;
  margin-bottom: 60px;
}
.tip {
  background: #fff9e6;
  border: 1px solid #ffdb88;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #595959;
  line-height: 22px;
  padding: 10px 20px;
}

.ruleFormCont {
  width: 600px;
  // padding-top: 30px;
  .selectCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .itemCont {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22%;
      height: 70px;
      // border: 2px solid #{$borderColor};
      border-radius: 4px;
      font-size: #{$fontSize};
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      color: #{$fontColor};
      text-align: center;
      line-height: 70px;
      position: relative;
      cursor: pointer;
      width: 195px;
      height: 76px;
      background: linear-gradient(90deg, #e4e4e4, #f8f8f8);
      border-radius: 8px;
    }
    .mainActive {
      // border: 2px solid #{$borderColorActive};
      color: #{$fontColorActive};
      width: 195px;
      height: 76px;
      background: linear-gradient(90deg, #ffd4d5, #ffecec);
      border-radius: 8px;
      /* 三角形 */
      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        right: 0px;
        border-bottom: 25px solid #{$borderColorActive};
        border-left: 25px solid transparent;
        border-bottom-right-radius: 8px;
      }

      /* 三角形勾 */
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 6px;
        background: transparent;
        bottom: 6px;
        right: 3px;
        border: 2px solid white;
        border-top: none;
        border-right: none;
        transform: rotate(-55deg);
        z-index: 9;
      }
    }
  }
  .uploadCont {
    width: 600px;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    overflow-x: scroll;
  }
}
.allheight {
  height: 100vh !important;
}
.status {
  &_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    h3 {
      font-size: 30px;
      font-weight: 400;
      color: #333;
      line-height: 2;
    }
  }
  &_ico {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    color: #fff;
    font-size: 30px;
    line-height: 1;
    border-radius: 100%;
  }
  &_success {
    background: #32bf78;
  }
  &_err {
    background: #f56c6c;
  }
}

.contentCont {
  .contentInner {
    padding: 20px;
    background: #fff;
    height: calc(100vh - 140px);
    overflow-y: scroll;
    // height: calc(100vh - 48px);
    .tip {
      background: #fff9e6;
      border: 1px solid #ffdb88;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #595959;
      line-height: 22px;
      padding: 10px 20px;
    }
    .staus_plan {
      overflow: hidden;
      padding: 32px 32px 54px 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .step {
        position: relative;
        width: auto;
        height: 32px;
        .status {
          margin-right: 8px;
          .idx {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            border: 1px solid #e5e7ee;
            font-size: 14px;
            text-align: center;
            color: #91939a;
            line-height: 32px;
            margin: 0 auto;
          }
        }
        .icon_svg {
          width: 32px;
          height: 32px;
        }

        .name {
          color: #91939a;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          margin-top: 10px;
        }
        .time {
          position: absolute;
          left: 40px;
          width: 150px;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #91939a;
          line-height: 22px;
        }
      }
      .line {
        margin: 0 16px;
        width: 98px;
        height: 3px;
        background: #e5e7ee;
        border-radius: 1px;
      }
      .step.active {
        .status .idx {
          border: 1px solid #f5222d;
          color: #fff;
          background: #f5222d;
        }
        .name {
          color: #f5222d;
        }
        .name.sueccss {
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .line.active {
        background: #f5222d;
      }
    }
    .formCont {
      width: 800px;
      display: flex;
      margin: 0 auto;
      justify-content: center;
    }
  }
}
.imgList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &_li {
    display: block;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
    &:hover {
      .imgList_li_close {
        top: 5px;
      }
    }
    &_img {
      width: 100px;
      height: 100px;
    }
    &_close {
      position: absolute;
      z-index: 2;
      right: 5px;
      top: -50px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin: 0;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #f00;
    }
    &_add {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      cursor: pointer;
      background: #f5f5f5;
      border-radius: 6px;
      font-size: 36px;
      line-height: 1;
      color: #bbb;
      &:hover {
        border: 1px solid #3db4b9;
      }
    }
  }
}
.down {
  margin-top: 60px;
  margin-bottom: 60px;
}
.tip {
  background: #fff9e6;
  border: 1px solid #ffdb88;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #595959;
  line-height: 22px;
  padding: 10px 20px;
}

.ruleForm {
  width: 600px;
  padding-bottom: 30px;
  .selectCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .itemCont {
      width: 22%;
      height: 70px;
      border: 2px solid #{$borderColor};
      border-radius: 4px;
      font-size: #{$fontSize};
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      color: #{$fontColor};
      text-align: center;
      line-height: 70px;
      position: relative;
      cursor: pointer;
    }
    .mainActive {
      border: 2px solid #{$borderColorActive};
      color: #{$fontColorActive};
      /* 三角形 */
      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        right: 0px;
        border-bottom: 25px solid #{$borderColorActive};
        border-left: 25px solid transparent;
        border-bottom-right-radius: 8px;
      }

      /* 三角形勾 */
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 6px;
        background: transparent;
        bottom: 6px;
        right: 3px;
        border: 2px solid white;
        border-top: none;
        border-right: none;
        transform: rotate(-55deg);
        z-index: 9;
      }
    }
  }
  .uploadCont {
    width: 600px;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    // overflow-x: scroll;
  }
}
</style>
