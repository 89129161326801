<template>
    <div class="harf-block"  v-if="showRules">
        <div class="big-block">
            <div class="title-block">
                <span class="text">销售规则</span>
            </div>
            <div class="form-content">
                <div class="row">
                    <div class="tip"><span class="weight">*</span>配置方式</div>
                    <div class="value fill">
                        <el-radio v-model="formIn.type" :label="0">
                            设置区域、客户类型及黑名单
                        </el-radio>
                        <el-radio v-model="formIn.type" :label="1">白名单</el-radio>
                    </div>
                </div>
                <template v-if="formIn.type === 0">
                    <div class="row mutlti big">
                        <div class="tip">地域</div>
                        <div class="value fill">
                            <div class="flag-block" v-if="areaIdList">
                                <el-radio v-model="formIn.areaId" :label="item.areaId" :key="item.areaId"
                                    v-for="item in areaIdList ? areaIdList : []">
                                    {{ item.schemeName }}
                                </el-radio>
                            </div>
                        </div>
                    </div>
                    <div class="row contine">
                        <div class="tip"></div>
                        <div class="value fill small-tip">
                            <span> 注意：仅限已选地域对应客户可购买当前商品。 </span>
                        </div>
                        <div class="value">
                            <el-button size="small" icon="el-icon-plus" @click="handleAddArea">新增地域</el-button>
                        </div>
                    </div>
                    <div class="row mutlti big">
                        <div class="tip">客户类型</div>
                        <div class="value fill">
                            <div class="flag-block" v-if="listData">
                                <el-checkbox style="margin-right: 30px" v-model="checkAll"
                                    :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
                                <el-checkbox-group v-model="formIn.shopTypeCode" @change="changeCheckList">
                                    <el-checkbox :label="item.typeCode" :key="item.typeCode"
                                        v-for="item in listData ? listData : []">{{
                                            item.typeName }}</el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </div>
                    </div>
                    <div class="row contine">
                        <div class="tip"></div>
                        <div class="value fill small-tip">
                            <span>
                                注意：被勾选的客户类型对应的客户可购买当前商品。
                            </span>
                        </div>
                    </div>
                </template>
                <div class="row mutlti big">
                    <div class="tip">
                        {{ formIn.type == "1" ? "白名单" : "黑名单" }}
                    </div>
                    <div class="value fill">
                        <div class="flag-block">
                            <el-radio v-model="formIn.groupId" :label="item.id" :key="item.id"
                                v-for="item in userList ? userList : []">
                                {{ item.schemeName }}
                            </el-radio>
                        </div>
                    </div>
                </div>
                <div class="row contine">
                    <div class="tip"></div>
                    <div class="value fill small-tip">
                        <span> 注意：黑名单客户不可购买当前商品。 </span>
                    </div>
                    <div class="value">
                        <el-button size="small" icon="el-icon-plus" @click="handleAddUserGroup">
                            新增用户组
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import avater from "/public/img/default.png"
export default {
    props: {
        formIn: {
            type: Object,
            default: () => {
                return {}
            }
        },
        checkAll: {
            type: Boolean,
            default: () => {
                return true ;   //全选客户类型
            }
        },
        userList:{
            type: Array,
            default: () => {
                return []
            }
        },
        areaIdList:{
            type: Array,
            default: () => {
                return []
            }
        },
        listData:{
            type: Array,
            default: () => {
                return []
            }
        },
        isIndeterminate: {
            type: Boolean,
            default: () => {
                return true ;    //全选，不确定效果,选择了部分
            }
        },
    },
    data() {
        return {
            avater: avater,
            showRules:false
        }
    },
    mounted(){
        this.showRules = true;
    },
    methods: {
        handleAddArea(){
            this.emit('handleAddArea')
        },
        handleCheckAllChange(){
            this.emit('handleCheckAllChange') 
        },
        changeCheckList(){
            this.emit('changeCheckList') 
        },
        handleAddUserGroup(){
            this.emit('handleAddUserGroup') 
        },
    }

}
</script>
<style lang="scss" scoped>
@import "../style/index.scss";
</style>

  