
<!--  -->
<template>
  <div class="comContent">
    <!-- <MyTabs class="myTabs"
            :tabi="tabsIndex"
            :tabs="tabsData"
            numhide
            @change="tabChange" /> -->
    <Tabs @tab-click="handleClick"
          style="padding:0 24px"
          :tabsList="tabsList"
          :activeName.sync="activeName"></Tabs>
    <div class="topHeader">
      <div style="width:calc(100% - 200px)">
        <el-form ref="formTool"
                 :model="formTool"
                 :inline="true"
                 style="display:flex;">
          <el-form-item prop="code"
                        size="small">
            <el-input v-model="formTool.code"
                      clearable
                      placeholder="服务单号" />
          </el-form-item>
          <el-form-item prop="orderCode"
                        size="small">
            <el-input v-model="formTool.orderCode"
                      clearable
                      placeholder="订单编号" />
          </el-form-item>
          <el-form-item prop="goodsName"
                        size="small">
            <el-input v-model="formTool.goodsName"
                      clearable
                      placeholder="商品名称" />
          </el-form-item>
          <el-form-item prop="shopName"
                        size="small">
            <el-input v-model="formTool.shopName"
                      clearable
                      placeholder="商户名称" />
          </el-form-item>
          <el-form-item prop="date"
                        size="small">
            <el-date-picker v-model="formTool.date"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期" />
          </el-form-item>
          <el-form-item prop="content"
                        size="small">
            <el-select v-model="formTool.applyForType"
                       clearable
                       placeholder="申请类型">
              <el-option v-for="li in options"
                         :key="li.value"
                         :label="li.label"
                         :value="li.value" />
            </el-select>
          </el-form-item>
          <!-- <el-form-item prop="content"
                        size="small">
            <el-select v-model="formTool.content"
                       clearable
                       placeholder="售后发起方">
              <el-option v-for="li in options"
                         :key="li.value"
                         :label="li.label"
                         :value="li.value" />
            </el-select>
          </el-form-item> -->
        </el-form>
      </div>
      <div style="width:128px">
        <MyButton type="primary"
                  text="查询"
                  @click="getList(1)" />
        <MyButton type=""
                  text="重置"
                  @click="reset()" />
      </div>

    </div>

    <div class="center">
      <MyTable v-if="tableData.length > 0"
               :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getList">
        <template slot="caozuo"
                  slot-scope="scope">
          <el-link type="primary"
                   v-if="scope.row.type == '商家退款' && scope.row.status == '审核中'"
                   :underline="false"
                   @click="agree(scope.row)">
            同意
          </el-link>
          <el-link style="margin-left:10px"
                   type="primary"
                   :underline="false"
                   @click="detail(scope.row)">
            详情
          </el-link>
          <!-- <el-link type="primary"
                   style="margin-left:10px"
                   :underline="false"
                   @click="detail(scope.row)">
            同意
          </el-link>
          <el-link type="primary"
                   style="margin-left:10px"
                   :underline="false"
                   @click="reject(scope.row)">
            驳回
          </el-link>
          <el-link type="primary"
                   style="margin-left:10px"
                   :underline="false"
                   @click="detail(scope.row)">
            确认收货
          </el-link> -->
        </template>
      </MyTable>
      <el-empty v-else
                style="height:100%"
                description="暂无数据" />
    </div>
    <el-dialog title="拒绝说明"
               :visible.sync="dialogVisible"
               width="30%">
      <el-input type="textarea"
                placeholder="请输入内容"
                v-model="textarea"
                maxlength="200"
                show-word-limit>
      </el-input>
      <ImgUpload v-model="imageList"
                 :limit="3"
                 style="position:relative;top:-8px">
        <template slot="tip">
          <p class="el-upload__tip"
             style="margin:0px">
            备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过5M;最多上传三张图片
          </p>
        </template>
      </ImgUpload>
      <span slot="footer"
            class="dialog-footer">
        <el-button size="small"
                   @click="dialogVisible = false">取消</el-button>
        <el-button size="small"
                   type="primary"
                   @click="submit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ImgUpload from '@/components/imgUpload'
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable'
import Tabs from '@/components/Tabs'
import { drugenumeration } from '@/utils/enumeration'
import { aftersalePage, aftersaleBuyerAgree } from '@/api/afterSales/serviceSheet'
export default {
  components: { MyButton, MyTable, Tabs, ImgUpload },
  data () {
    return {
      options: [
        {
          label: '退货退款',
          value: 'THTK'
        },
        {
          label: '退款',
          value: 'TK'
        },
        {
          label: '商家退款',
          value: 'SJTK'
        },
      ],

      dialogVisible: false,
      textarea: '',

      imageList: [],
      tabsList: [ // tabs 传的值
        {
          name: 'first',
          label: '全部售后单',
          value: 0,
        },
        {
          name: 'CHECKING',
          label: '审核中',
          value: 0,
        },
        {
          name: 'WAIT_SALE',
          label: '待退货',
          value: 0,
        },
        {
          name: 'SALEING',
          label: '退货中',
          value: 0,
        },
        {
          name: 'REFUNDING',
          label: '退款中',
          value: 0,
        },
        {
          name: 'FINISH',
          label: '退款成功',
          value: 0,
        },
        {
          name: 'CANCEL',
          label: '退款关闭',
          value: 0,
        },
        {
          name: 'REJECT',
          label: '驳回',
          value: 0,
        }
      ],
      activeName: 'first', // tabs 传的值
      formTool: {
        date: [],
        goodsName: '',
        shopName: '',
        orderCode: '',
        code: '',
        applyForType: ''
      },
      operation: false,
      selection: false,
      index: false,
      tableData: [
      ],
      tableOption: [
        { label: '商品名称', prop: 'goodsName' },
        { label: '数量', prop: 'qty', width: '60px', align: 'center' },
        { label: '商户名称', prop: 'payeeShopName' }, // 这里表示自定义列, slot: true
        { label: '应退金额', prop: 'amount', width: '70px', align: 'center' },
        { label: '申请时间', prop: 'orderTime' },
        { label: '联系方式', prop: 'payeeShopPhone' },
        // { label: '订单编号', prop: 'orderCode' },
        { label: '申请类型', prop: 'type' },
        { label: '操作', prop: 'caozuo', width: '80px', slot: true, align: 'center' }
      ],
      page: {
        total: 10,
        current: 1,
        size: 10
      }
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    if(this.$store.state.tabStatus.serviceSheetTab){
      this.activeName = this.$store.state.tabStatus.serviceSheetTab;
      this.handleClick()
    }else{
      this.getList() 
    }
  },
  methods: {
    // 买家同意取消售后
    agree (row) {
      aftersaleBuyerAgree({ id: row.id }).then(res => {
        if (res.code == 0) {
          this.$message.success('等待退款')
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 查询
    getList (current) {
      if (current) {
        this.page.current = current
      }
      let params = {
        ...this.formTool,
        type: 'pay',
        status: this.activeName == 'first' ? '' : this.activeName,
        size: this.page.size,
        current: this.page.current,
        startOrderTime: '',
        endOrderTime: ''
      }
      params.startOrderTime = params.date ? params.date[0] : ''
      params.endOrderTime = params.date ? params.date[1] : ''
      aftersalePage(params).then(res => {
        if (res.code == '') {
          this.tabsList[1].value = res.data.checkingQty
          this.tabsList[2].value = res.data.waitSaleQty
          this.tabsList[3].value = res.data.saleQty
          this.tabsList[4].value = res.data.refundingQty
          this.tabsList[7].value = res.data.rejectQty
          this.tableData = res.data.page.records
          this.page.total = res.data.page.total
        }
      })
    },
    // 重置查询条件
    reset () {
      // this.formTool.storeName = ''
      this.$refs.formTool.resetFields()
      this.formTool = { date: null, invoiceNo: '', content: '' }
      this.page.current = 1
      this.getList()
    },
    // 点击tab
    handleClick () {
      this.getList(1)
      this.$store.dispatch('tabStatus/setServiceSheetTab', this.activeName) //缓存tab状态

    },
    // 查看详情
    detail (row) {
      this.$router.push({ path: '/ordercenter/ordercenter-serviceSheetDetail', query: { afterSaleId: row.id } })
    },
    // 点击表格驳回
    reject (row) {
      console.log(row)
      this.dialogVisible = true
    },
    // 提交
    submit () {
      this.dialogVisible = false
      console.log(this.imageList)
    }
  }
}
</script>
<style lang='scss' scoped>
.comContent {
  padding: 0px;
}
.expand {
  &_box {
    padding: 20px 30px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  &_li {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 45%;
    margin-right: 3%;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 10px;
    word-break: break-all;
    strong {
      margin-right: 10px;
      font-weight: 400;
      color: #000;
    }
    span {
      color: #666;
    }
    &_more {
      position: relative;
    }
    &_link {
      color: #f5222d;
      &:hover {
        color: #3db4b9;
      }
    }
    &_img {
      width: 100px;
      height: 100px;
    }
  }
}
.center {
  margin: 0 24px;
  height: calc(100% - 170px);
}
.myTabs {
  height: 56px;
  padding: 0 24px;
  border-bottom: 1px solid #f5f5f5 !important;
}
.topHeader {
  padding: 0 24px;
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  line-height: 56px;
}
.el-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
}
</style>
