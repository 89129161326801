var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-type-root grid" },
    _vm._l(
      _vm.beMainDept ? _vm.typeList : _vm.typeList2,
      function (item, index) {
        return _c(
          "div",
          {
            key: index,
            class: { item: true, focus: _vm.selectedIndex === index },
            on: {
              click: function ($event) {
                return _vm.handleSelect(index)
              },
            },
          },
          [
            _c("el-image", { staticClass: "bg", attrs: { src: _vm.bgIcon } }),
            _c("el-image", { staticClass: "icon", attrs: { src: item.url } }),
            _c("div", { staticClass: "right-content" }, [
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.label))]),
              _c("div", { staticClass: "desp" }, [
                _vm._v(_vm._s(item.remarks)),
              ]),
            ]),
          ],
          1
        )
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }