export default  { //药店
 

	showInputArr: [{
			name: '药品经营许可证编码',
			require: true,
			prop: ''
		},
		{
			name: '采购/收货人姓名',
			require: true,
			prop: 'concatName'
		}, {
			name: '采购/收货人联系电话',
			require: true,
			prop: 'concatPhone'
		}, {
			name: '售后联系电话',
			require: true,
			prop: 'servicePhone'
		}
	],

	showImgArr: [{
			code: '01',
			name: '营业执照',
			require: true,
			images: [], //上传的图片初始化
			desc: '营利性医疗机构必须上传营业执照，复印件请加盖鲜章',
			credentialImage: '', //查看示列的图片
			showCredential: true, //是否显示查看示列 
			showDownload: false, //是否显示下载
			downlUrl: '', //下载模版地址
		},
		{
			code: '02',
			name: '药品经营许可证',
			require: true,
			images: [], //上传的图片初始化
			desc: '复印件加盖鲜章',
			credentialImage: '', //查看示列的图片
			showCredential: true, //是否显示查看示列 
			showDownload: false, //是否显示下载
			downlUrl: '', //下载模版地址
		},
		{
			code: '05',
			name: "被委托人身份证复印件(正反两面)",
			require: true,
			images: [], //上传的图片初始化
			desc: '复印件加盖鲜章',
			credentialImage: '', //查看示列的图片
			showCredential: true, //是否显示查看示列 
			showDownload: false, //是否显示下载
			downlUrl: '', //下载模版地址
		},
		{
			code: '06',
			name: "授权委托书",
			require: true,
			images: [], //上传的图片初始化
			desc: '复印件加盖鲜章',
			credentialImage: '', //查看示列的图片
			showCredential: true, //是否显示查看示列 
			showDownload: true, //是否显示下载
			downlUrl: '', //下载模版地址
		},
		{
			code: '08',
			name: "开票信息",
			require: true,
			images: [], //上传的图片初始化
			desc: '',
			credentialImage: '', //查看示列的图片
			showCredential: true, //是否显示查看示列 
			showDownload: false, //是否显示下载
			downlUrl: '', //下载模版地址
		},
		{
			code: '09',
			name: "其它",
			require: false,
			images: [], //上传的图片初始化
			desc: '',
			credentialImage: '', //查看示列的图片
			showCredential: true, //是否显示查看示列 
			showDownload: false, //是否显示下载
			downlUrl: '', //下载模版地址
		}
	]

}