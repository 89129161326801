<template>
  <div class="comContent">
    <div class="search-from">
      <el-form ref="searchForm"
               :model="searchForm"
               :inline="true"
               style="text-align: left">
        <el-form-item>
          <el-select size="mini"
                     v-model="searchForm.payeeStatus"
                     placeholder="收款状态"
                     style="width: 120px">
            <el-option v-for="item in payeeStatusList"
                       :label="item.name"
                       :value="item.id"
                       :key="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-input v-model="searchForm.shopName"
                    placeholder="采购方名称"
                    clearable
                    maxlength="20"
                    size="small"
                    style="width: 120px" />
        </el-form-item>

        <!-- <el-form-item>
          <el-input v-model="searchForm.shopAccount"
                    placeholder="采购方账号"
                    clearable
                    maxlength="20"
                    size="small"
                    style="width: 120px" />
        </el-form-item> -->

        <!-- <el-form-item>
          <el-input v-model="searchForm.payShopName"
                    placeholder="付款方名称"
                    clearable
                    maxlength="20"
                    size="small"
                    style="width: 120px" />
        </el-form-item> -->

        <!-- <el-form-item>
          <el-input
            v-model="searchForm.payShopAccount"
            placeholder="付款方账号"
            clearable
            maxlength="20"
            size="small"
            style="width: 120px"
          />
        </el-form-item> -->

        <el-form-item>
          <el-input v-model="searchForm.orderCode"
                    placeholder="订单号"
                    clearable
                    maxlength="20"
                    size="small"
                    style="width: 120px" />
        </el-form-item>

        <!-- <el-form-item>
          <el-input v-model="searchForm.goodsName"
                    placeholder="商品名称"
                    clearable
                    maxlength="20"
                    size="small"
                    style="width: 120px" />
        </el-form-item>

        <el-form-item>
          <el-input v-model="searchForm.price"
                    placeholder="商品单价"
                    clearable
                    maxlength="20"
                    size="small"
                    style="width: 120px" />
        </el-form-item> -->

        <el-form-item >
          <el-date-picker style="width: 270px"
                          v-model="searchForm.date"
                          type="daterange"
                          size="small"
                          value-format="yyyy-MM-dd"
                          range-separator="-"
                          start-placeholder="对账开始日期"
                          end-placeholder="对账结束日期" />
        </el-form-item>
        <!-- 企业类型现在只有诊所，此字段暂时不进入查询数据 -->
        <!-- <el-form-item>
          <el-select size="mini"
                     v-model="searchForm.companyType"
                     placeholder="企业类型"
                     style="width: 120px">
            <el-option v-for="item in companyList"
                       :label="item.name"
                       :value="item.id"
                       :key="item.id"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item>
          <el-select size="mini"
                     v-model="searchForm.province"
                     placeholder="省"
                     @change="selectedHandle"
                     style="width: 120px">
            <el-option v-for="item in provinceData"
                       :label="item.rname"
                       :value="item.id"
                       :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select size="mini"
                     v-model="searchForm.city"
                     placeholder="市"
                     @change="selectedHandleCity"
                     style="width: 120px">
            <el-option v-for="item in cityData"
                       :label="item.rname"
                       :value="item.id"
                       :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select size="mini"
                     v-model="searchForm.district"
                     placeholder="区"
                     style="width: 120px">
            <el-option v-for="item in areaData"
                       :label="item.rname"
                       :value="item.id"
                       :key="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button size="small"
                     type="primary"
                     @click="getList(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     @click="reset('searchForm')">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     type="primary"
                     @click="exportUrl(1)">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <paymentTable :pageType="'payment-page'"
                    :table-data="tableData"
                    :index="index"
                    :selection="false"
                    :page="page"
                    v-loading="loading"
                    :showpage="true"
                    :operation="false"
                    :table-option.sync="tableOption"
                    @detail-operate="detailOperate"
                    @page-change="getList"
                    @handleSelectionChange="handleSelectionChange"
                    :expand="true">
        <template slot="payShopNameList"
                  slot-scope="scope">
          <div class="goods-info-list"
               style="display: flex; align-items: center"
               v-for="(item, key) in scope.row.academicDTOList"
               :key="key">
            <div class="unit-list">
              <div class="goods-name"
                   style="line-height: 30px">
                {{ item.payShopName }}
              </div>
              <div class="li">
                {{ item.amount }}
              </div>
              <div class="li">
                {{ item.toAmount }}
              </div>
              <div class="li">
                {{ item.payShopStatus }}
              </div>
            </div>
          </div>
        </template>

        <template slot="payShopName"
                  slot-scope="scope">
          <span @click="toDetailPage(scope.row)"
                class="to-deatil">{{
            scope.row.payShopName
          }}</span>
        </template>
        <template slot="expand"
                  slot-scope="scope">
          <myTable :table-data="detailList"
                   :index="index"
                   :selection="false"
                   :operation="false"
                   :table-option.sync="tableInfoOption"
                   :expand="false">
          </myTable>
        </template>
      </paymentTable>
    </div>
  </div>
</template>
<script>
import paymentTable from "./paymentTable";
import myTable from "@/components/myTable";
import avater from "/public/img/default.png";
import { getProvince, getCity, getArea } from "@/api/shopmange/index";
import { dfcTree } from "@/api/businessPlatform/relationshipMaintenance";
import {
  accountCheckingPayPage,
  accountCheckingPageDetail,
  accountCheckingPayExcel,
} from "@/api/accountChecking/index";
export default {
  components: { paymentTable, myTable },
  props: {
    accountType: {
      type: Number,
      default () {
        return 0;
      },
    },
  },
  data () {
    return {
      loading: false,
      selectData: new Date(),
      avater: avater,
      statusList: [],
      searchForm: {
        date: [],
      },
      payeeStatusList: [
        {
          id: 0,
          name: "未收款",
        },
        {
          id: 1,
          name: "已收款",
        },
      ], //企业类型
      companyList: [
        {
          id: 1,
          name: "诊所",
        },
        {
          id: 2,
          name: "春播运营组织",
        },
        {
          id: 3,
          name: "医药公司",
        },
      ], //企业类型

      areaData: [],
      cityData: [],
      provinceData: [],
      allTreedata: [], //省级组织树
      cityTree: [], //地级组织树
      name: "",
      dateKey: 2,
      operation: false,
      selection: true,
      index: false,
      tableData: [],
      detailList: [],
      tableInfoData: [],
      stProvinceAmtPo: {},
      tableOption: [
        { label: "创建时间", prop: "createDate", width: 160 },
        { label: "采购方名称", prop: "payShopName", width: 260, slot: true },
        // { label: "订单号", prop: "orderCode", width: 200 },
        { label: "订单状态", prop: "orderStatus", width: 120 },
        { label: "采购方企业类型", prop: "payShopType", width: 130 },
        { label: "省", prop: "province", width: 120 },
        { label: "市", prop: "city", width: 140 },
        { label: "区", prop: "district", width: 140 },
        { label: "省组织", prop: "provinceOrgName", width: 120 },
        { label: "地组织", prop: "cityOrgName", width: 120 },
        {
          label: "应收总金额",
          prop: "amount",
          width: 120,
        },
        { label: "到账总金额", prop: "toAmount", width: 120 },
        { label: "收款状态", prop: "payeeStatus", width: 120 },

        {
          label: "付款方名称",
          prop: "payShopNameList",
          width: 200,
          slot: true,
        },
        { label: "应付总金额", prop: "", width: 140 },
        { label: "实付总金额", prop: "", width: 140 },
        { label: "状态", prop: "", width: 120 },
      ],
      tableInfoOption: [
        { label: "", prop: "", width: 100 },
        { label: "商品名称", prop: "name" },
        { label: "商品规格", prop: "specification" },
        { label: "单位", prop: "goodsSkuUnit" },
        { label: "商品单价", prop: "price" },
        { label: "商品件数", prop: "qty" },
        { label: "单件商品应收金额", prop: "servicePrice" },
      ],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      isReport: true,
    };
  },
  filters: {
    pirceFormatter (price) {
      if (price != null) {
        return price.toFixed(2);
      } else {
        return "0.00";
      }
    },
  },
  created () { },
  mounted () {
    this.searchForm.type = this.accountType; // 账户类型对应页面类型，角色：1.省地医药，2.学术，3.云药库
    // 获取省市区 地域树
    this.getProvinceData();
    // 获取省地办 组织树
    this.getTeamTree();
    this.getList(1);
  },
  methods: {
    // 展开明细列表
    getDeatilList (row) {
      let params = {
        type: this.searchForm.type,
        orderId: row.orderId,
      };
      delete params.date;
      accountCheckingPageDetail(params).then((res) => {
        // console.log(res);
        if (res) {
          this.detailList = res;
        } else {
        }
      });
    },
    // 跳转详情页面
    toDetailPage (row, type) {
      let queryParams = `payShopId=${row.payShopId}&type=${this.searchForm.type}&payShopName=${row.payShopName}`;
      this.$router.push(
        "./financialcenter-reconciliation-paymentDetails?" + queryParams
      );
    },
    // 操作按钮
    detailOperate (row, type) {
      if (type == 1) {
        this.getDeatilList(row);
      }
    },
    // 表格 多选
    handleSelectionChange (e) {
      console.log(e);
    },
    // 获取整个团队组织树
    getTeamTree () {
      dfcTree({ type: "" }).then((res) => {
        if (res.code == 0) {
          this.allTreedata = res.data;
          // console.log(this.allTreedata, "---allTreedata");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    chooseProvinceTeam (e) {
      this.searchForm.provinceOrgName = e.name;
      this.searchForm.provinceOrgCode = e.weight;
      // this.cityTree = [];
      this.cityTree = e.children;
      if (this.searchForm.cityOrgCode) {
        this.searchForm.cityOrgName = "";
        this.searchForm.cityOrgCode = "";
      }
    },
    // 选择市级组织
    chooseCityTeam (e) {
      this.searchForm.cityOrgName = e.name;
      this.searchForm.cityOrgCode = e.weight;
      this.$forceUpdate();
    },
    exportUrl () {
      let params = {
        ...this.searchForm,
        startOrderTime: this.searchForm.date[0] || null,
        endOrderTime: this.searchForm.date[1] || null,
      };
      delete params.date;
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let apiPath = "/statistic/account/checking/pay/excel";
      let exportName = "付款对账列表";
      try {
        accountCheckingPayExcel(params, apiPath, exportName).then((res) => {
          setTimeout(() => {
            loading.close();
          }, 500);
        });
      } catch (error) {
        setTimeout(() => {
          loading.close();
        }, 2000);
      }
    },

    reset (formName) {
      //清空方法
      for (var key in this.searchForm) {
        this.searchForm[key] = "";
      }
      this.searchForm.date = [];
      this.page.current = 1;
      this.getList();
      // console.log(this.searchForm);
    },
    getList (type) {
      this.loading = true;
      if (type == 1) {
        this.page.current = 1;
      }
      let params = {
        ...this.searchForm,
        startOrderTime: this.searchForm.date[0] || null,
        endOrderTime: this.searchForm.date[1] || null,
        current: this.page.current,
        size: this.page.size,
      };
      delete params.date;
      accountCheckingPayPage(params).then((res) => {
        // console.log(res);
        this.loading = false;
        if (res) {
          this.tableData = res.records;
          this.page.total = res.total;
        } else {
        }
      });
    },

    //省份的选择按钮
    selectedHandle (val) {
      console.log(val, "val");
      this.getCityBtn(val);
    },

    //城市的选择按钮
    selectedHandleCity (val) {
      this.getAreaBtn(val);
    },
    getProvinceData () {
      getProvince().then((res) => {
        if (res.code == 0) {
          this.provinceData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },
    getCityBtn (val) {
      getCity({ id: val }).then((res) => {
        if (res.code == 0) {
          this.cityData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },

    getAreaBtn (val) {
      getArea({ id: val }).then((res) => {
        if (res.code == 0) {
          this.areaData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.reportContent {
  background: #fff;
  // padding:0 0 10px 0;
  margin-top: -0px;
}
.header {
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  .title {
    line-height: 30px;
    font-size: 18px;
    span {
      font-size: 14px;
      // color: #595959;
    }
  }
}
.info-list {
  line-height: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  // margin-left: 105px;
  margin-top: 20px;
  // margin: 0 auto;
  .list {
    margin-right: 20px;
    span {
      font-size: 21px;
      color: #f5222d;
    }
  }
}
.date-picker {
  margin-left: 20px;
}
.active-button {
  background: #ffece8;
  color: #f5222d;
  border: solid 1px #ffece8;
}
.goods-name {
  width: 200px;
  margin-left: 0px;
  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.goods-info-list {
  border-bottom: solid 1px #ccc;
}
.goods-info-list:nth-last-child(1) {
  border: none;
}

.unit-list {
  display: flex;
  .li {
    width: 140px;
  }
}
.search-from {
  margin-top: 20px;
}
.to-deatil {
  color: #f5222d;
  cursor: pointer;
}
</style>