<template>
    <div class="listContent">
        <el-table :data="goodsList" style="width: 100%;margin-bottom: 20px;" row-key="id" border   ref="multipleTable"
            element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 0.8)" @selection-change="handleSelectionChange" type="selection"
            v-loading="loading" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
            <el-table-column type="selection" width="55">
            </el-table-column>

            <el-table-column prop="post" label="商品信息" width="500">
                <template slot-scope="scope">
                    <div class="goodList">
                        <div class="img-div">
                            <el-image class="l" :src="scope.row.image ? scope.row.image :avater" alt="" />
                        </div>
                        <div class="r">
                            <div>
                                <span class="txt-line-1 name">{{ scope.row.name }}</span>
                            </div>
                            <div>
                                <span class="rr txt-line-1">{{ scope.row.specification }}</span>
                            </div>
                            <div>
                                <span class="rr txt-line-1">{{ scope.row.approvalNumber }}</span>
                            </div>
                            <div>
                                <span class="rr txt-line-1">{{ scope.row.factory }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="post" label="销售价格">
                <template slot-scope="scope">
                    <div>
                       ￥ {{ scope.row.minPrice }} ~ {{ scope.row.maxPrice }}
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
  
<script>
import avater from "/public/img/default.png";

import lodash from "lodash";
export default {
    props: {
        goodsList: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {

    },
    data() {
        return {
            avater:avater,
            loading:false,
            list: this.goodsList
        }
    },
    methods: {
        handleSelectionChange(val) {
            if (val.length > 1) {
                this.$refs.multipleTable.clearSelection();
                this.$refs.multipleTable.toggleRowSelection(val.pop());
            } else {
                this.tableSelect = val;
                this.$emit('chooseItem',val)
            } 
        } 
    }
};
</script>
  
<style lang="scss" scoped>
.listContent {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // justify-content: space-between;

    .goodList {
        display: flex;
        justify-content: space-around;

        &:nth-child(3n) {
            margin-right: 0px;
        }

        .img-div {
            height: 88px;
            width: 88px;
            margin:0 10px;
            image {
            width: 100%;
            height: 100%;
           }
        }

        .l {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
        }

        .r {
            width: 214px;
            display: flex;
            flex: 1;
            flex-direction: column;

            div {
                display: flex;
            }

            span {
                font-size: 13px;
                color: #333;
                //margin-top: 10px;
            }

            .name {
                text-align: left;
                font-size: 16px;
                font-weight: 600;
                color: var(--color-26);
            }

            .rr {
                text-align: left;
                color: var(--color-59);
                font-size: 14px;
            }
        }

        &:hover {
          //  border: 2px solid var(--main-color) !important;
        }
    }

    .actived {
        border: 2px solid var(--main-color) !important;
    }

    .activeIcon {
        font-size: 40px;
        position: absolute;
        color: var(--main-color);
        right: -10px;
        top: -10px;
        z-index: 6;
    }
}

::v-deep .el-table__header {
    .el-checkbox {
      display: none;
    }
  }


</style>
  