let OSS = require("ali-oss");
import { getCBOssToken } from "@/api/salesControl/academic";
// 阿里云上传 本地不超过5 GB大小的文件、图片、视频等资源上传到OSS
export class OSSUpdate {
  credentials = {};
  client = {};
  constructor() {
    this.init();
  }
  init() {
    this.getToken();
  }
  getToken() {
    getCBOssToken().then((data) => {
      this.credentials = data || {};
      const { accessKeyId, accessKeySecret, regionId, bucket, securityToken } =
        data || {};
      this.client = new OSS({
        region: regionId, //创建的时候，bucket所在的区域，华北2->oss-cn-beijing ；其他的可以去百度
        accessKeyId: accessKeyId, // 阿里云控制台创建的AccessKey
        accessKeySecret: accessKeySecret, //阿里云控制台创建的AccessSecret
        stsToken: securityToken,
        bucket: bucket, //创建的bucket的名称
        secure: true,
      });
    });
  }
  upload(fullFileName, file, options = {}) {
    return this.client.multipartUpload(fullFileName, file, options);
  }
  // 上传
  async put(ObjName, fileUrl) {
    try {
      let result = await this.client.put(`${ObjName}`, fileUrl);
      // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称
      return result;
    } catch (e) {
      console.log(e);
    }
  }
  // 上传成功之后，转换真实的地址
  async signatureUrl(ObjName) {
    try {
      let result = await this.client.signatureUrl(`${ObjName}`);
      return result;
    } catch (e) {
      console.log(e);
    }
  }
  getFileNameUUID() {
    function rx() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return `${+new Date()}_${rx()}${rx()}`;
  }
}

export function base64ToFile(base64, fileName) {
  let arr = base64.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
}

/**时间秒 转换到 00:00:00格式的字符串 */
export function duration2str(param) {
  let ret = "00:00:00";
  const duration = +param;
  if (duration > 0) {
    let remaining = duration;
    const hour = parseInt(remaining / 3600);
    remaining = parseInt(remaining % 3600);
    const minute = parseInt(remaining / 60);
    remaining = parseInt(remaining % 60);
    const second = parseInt(remaining);
    ret = "";

    ret = String(hour).padStart(2, "0") + ":";

    ret += String(minute).padStart(2, "0") + ":";
    ret += String(second).padStart(2, "0");
  }
  return ret;
}

/**
 * 获取oss生成的原始文件名称
 * fullFileName 可能是后端的url
 */
export function getOssSrcFileName(fullFileName) {
  let name = fullFileName;
  if (fullFileName) {
    let position = fullFileName.lastIndexOf("/");
    if (position >= 0) {
      fullFileName = fullFileName.substring(position + 1);
    }
    if (fullFileName) {
      name = fullFileName;
      let arrNames = fullFileName.split("_");
      if (Array.isArray(arrNames) && arrNames.length > 2) {
        arrNames = arrNames.slice(0, arrNames.length - 2);
        name = arrNames.join("_")
      }
    }
  }
  if (name) {
    name = decodeURI(name);
  }

  return name;
}

export function getUploadFileUrl(item) {
  let netUrl = "";
  if (item) {
    if (item.response && item.response.data && item.response.data.url) {
      netUrl = item.response.data.url;
    }
    if (!netUrl && item.url) {
      //可能是已经上传过的
      netUrl = item.url;
    }
  }
  return netUrl;
}

export function parseToUploadUrl(newUrl) {
  if (newUrl) {
    const name = getOssSrcFileName(newUrl); //newUrl.split("/");
    return { name: name, url: newUrl };
  }
  return null;
}
