var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dynamic-item" }, [
    _c("div", { staticClass: "tip" }, [
      _vm.dataItem && _vm.dataItem.isMust
        ? _c("span", { staticClass: "weight" }, [_vm._v("*")])
        : _vm._e(),
      _c("span", { staticClass: "text" }, [
        _vm._v(_vm._s(_vm.dataItem?.tip ?? "")),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "value-wrap fill-wrap" },
      [
        _vm.componentType === _vm.IConst_Field.Input
          ? [
              _c("el-input", {
                attrs: {
                  maxlength: "100",
                  placeholder: "请输入",
                  disabled: _vm.disabled,
                },
                model: {
                  value: _vm.localValue,
                  callback: function ($$v) {
                    _vm.localValue = $$v
                  },
                  expression: "localValue",
                },
              }),
            ]
          : _vm._e(),
        _vm.componentType === _vm.IConst_Field.Pull ||
        _vm.componentType === _vm.IConst_Field.MutilPull
          ? [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择",
                    multiple: _vm.componentType === _vm.IConst_Field.MutilPull,
                    disabled: _vm.disabled,
                  },
                  model: {
                    value: _vm.localValue,
                    callback: function ($$v) {
                      _vm.localValue = $$v
                    },
                    expression: "localValue",
                  },
                },
                _vm._l(
                  _vm.dataItem.options ? _vm.dataItem.options : [],
                  function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }
                ),
                1
              ),
            ]
          : _vm._e(),
        _vm.componentType === _vm.IConst_Field.Area
          ? [
              _c("ArearSelect", {
                attrs: {
                  disabled: _vm.disabled,
                  valueName: _vm.dataItem.modeValueName,
                },
                on: {
                  change: _vm.handleChange,
                  "update:valueName": function ($event) {
                    return _vm.$set(_vm.dataItem, "modeValueName", $event)
                  },
                  "update:value-name": function ($event) {
                    return _vm.$set(_vm.dataItem, "modeValueName", $event)
                  },
                },
                model: {
                  value: _vm.localValue,
                  callback: function ($$v) {
                    _vm.localValue = $$v
                  },
                  expression: "localValue",
                },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }