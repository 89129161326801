var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.visible,
            "lock-scroll": true,
            width: "1000px",
            "show-close": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formTool",
              staticStyle: { "text-align": "left" },
              attrs: {
                model: _vm.formTool,
                inline: true,
                "label-width": "140px",
              },
            },
            [
              _c("div", { staticClass: "tips" }, [
                _vm._v(
                  " 请您及时下架召回批号的商品，已采购/销售的商品请做退货处理 "
                ),
              ]),
              _c(
                "div",
                { staticClass: "goodsContent" },
                _vm._l(_vm.recallList, function (item, key) {
                  return _c("div", { staticClass: "list-warp" }, [
                    _c("div", { staticClass: "goodList" }, [
                      _c(
                        "div",
                        { staticClass: "img-div" },
                        [
                          _c("el-image", {
                            staticClass: "l",
                            attrs: {
                              src: item.image ? item.image : _vm.avater,
                              alt: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "r" }, [
                        _c("div", [
                          _c("span", { staticClass: "txt-line-1 name" }, [
                            _vm._v(_vm._s(item.goodsName)),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "rr txt-line-1" }, [
                            _vm._v(_vm._s(item.factory)),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "rr txt-line-1" }, [
                            _vm._v(_vm._s(item.specification)),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "list-rt" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "left_topic mb20",
                            staticStyle: { "margin-top": "10px" },
                          },
                          [_vm._v("召回批号")]
                        ),
                        _c(
                          "el-row",
                          { staticClass: "list" },
                          _vm._l(item.batchNumberList, function (item, key) {
                            return _c("span", [
                              _vm._v(" " + _vm._s(item) + " "),
                            ])
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ]
          ),
          !_vm.isDisabled
            ? _c(
                "div",
                { staticClass: "btnBox" },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.next } },
                    [_vm._v("稍后处理")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("已处理")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }