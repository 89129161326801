import request from '@/utils/request'

 //收款对账列表
export function accountCheckingPage(params) {
  return request({
    url: '/statistic/account/checking/page',
    method: 'get',
    params: params
  })
}
 
// 收款对账明细
export function accountCheckingPageDetail(params) {
  return request({
    url: '/statistic/account/checking/order/detail',
    method: 'get',
    params: params
  })
} 
 
// 付款列表
export function accountCheckingPayPage(params) {
  return request({
    url: '/statistic/account/checking/pay/page',
    method: 'get',
    params: params
  })
} 

// /account/checking/pay/excel  导出:付款对账表 
// 收款对账导出(/statistic/account/checking/payExcel)

export function accountCheckingPayExcel(data,apiUrl,exportName) { 
  return request({
    url: apiUrl,
    method: 'get',
    params:data,
    responseType: 'blob'
  }).then(response => { 
    console.log(response,'res')
    // 处理返回的文件流
    const blob = response.data
    if ((blob && blob.size === 0) || response.size == 0) {
      Message.warning('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    const contentDisposition = response.headers['content-disposition'];
    const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1));
    link.download = exportName + '数据导出'+ fileName;
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  })
}