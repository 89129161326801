var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "设置抬头",
        visible: _vm.visible,
        "lock-scroll": true,
        width: "618px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.company,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "发票抬头", prop: "companyName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      size: "small",
                      placeholder: "请输入发票抬头",
                      max: "100",
                    },
                    model: {
                      value: _vm.company.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "companyName", $$v)
                      },
                      expression: "company.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发票税号", prop: "companyTaxpayerId" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { size: "small", placeholder: "请输入发票税号" },
                    model: {
                      value: _vm.company.companyTaxpayerId,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "companyTaxpayerId", $$v)
                      },
                      expression: "company.companyTaxpayerId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注册地址", prop: "companyAddress" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      size: "small",
                      type: "textarea",
                      max: "200",
                      placeholder: "请输入注册地址",
                    },
                    model: {
                      value: _vm.company.companyAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "companyAddress", $$v)
                      },
                      expression: "company.companyAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注册电话", prop: "companyTel" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      size: "small",
                      placeholder: "请输入注册电话",
                      "show-word-limit": "",
                      max: "100",
                    },
                    model: {
                      value: _vm.company.companyTel,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "companyTel", $$v)
                      },
                      expression: "company.companyTel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开户银行", prop: "companyBank" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      size: "small",
                      placeholder: "请输入开户银行",
                      max: "100",
                    },
                    model: {
                      value: _vm.company.companyBank,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "companyBank", $$v)
                      },
                      expression: "company.companyBank",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开户账号", prop: "companyAccount" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      size: "small",
                      placeholder: "请输入开户账号",
                      max: "100",
                    },
                    model: {
                      value: _vm.company.companyAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "companyAccount", $$v)
                      },
                      expression: "company.companyAccount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件人", prop: "recipients" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      size: "small",
                      placeholder: "请输入收件人",
                      max: "100",
                    },
                    model: {
                      value: _vm.company.recipients,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "recipients", $$v)
                      },
                      expression: "company.recipients",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话", prop: "recipientsTel" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      size: "small",
                      placeholder: "请输入联系电话",
                      max: "100",
                    },
                    model: {
                      value: _vm.company.recipientsTel,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "recipientsTel", $$v)
                      },
                      expression: "company.recipientsTel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件地址", prop: "recipientsAddress" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      size: "small",
                      placeholder: "请输入收件地址",
                      max: "100",
                    },
                    model: {
                      value: _vm.company.recipientsAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "recipientsAddress", $$v)
                      },
                      expression: "company.recipientsAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱", prop: "recipientsEmil" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      size: "small",
                      placeholder: "请输入邮箱",
                      max: "100",
                    },
                    model: {
                      value: _vm.company.recipientsEmil,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "recipientsEmil", $$v)
                      },
                      expression: "company.recipientsEmil",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("MyButton", {
                    attrs: { type: "primary", icon: "", text: "确认" },
                    on: { click: _vm.goSubmit },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }