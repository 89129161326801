<template>
  <div class="login-container">
    <div class="login-weaper">
      <div class="login-left animate__animated animate__fadeInLeft">
        <img class="img" src="/img/login.png" alt />
      </div>
      <div class="login-border animate__animated animate__fadeInRight">
        <div class="login-main" v-show="loginStatus == '1'">
          <div style="display: flex; justify-content: center; margin-bottom: 68px;" class="logo-img">
            <img class="img" src="/img/loginTop.png" alt />
          </div>
          <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on"
            label-position="left">
            <el-form-item prop="username">
              <span class="svg-container">
                <!-- <svg-icon icon-class="user" /> -->
                <img class="img" src="../../assets/login/people.png" alt />
              </span>
              <el-input ref="username" v-model.trim="loginForm.username" placeholder="请输入账号" size="small" name="username"
                type="text" tabindex="1" auto-complete="on" />
            </el-form-item>

            <el-form-item prop="password">
              <span class="svg-container">
                <img class="img" src="../../assets/login/suo.png" alt />
              </span>
              <el-input :key="passwordType" ref="password" v-model.trim="loginForm.password" :type="passwordType"
                placeholder="请输入密码" name="password" tabindex="2" auto-complete="on" size="small"
                @keyup.enter.native="handleLogin" />
              <span class="show-pwd" @click="showPwd">
                <!-- <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" /> -->
                <img class="img" :src="passwordType == 'password' ? close : eyes" alt />
              </span>
            </el-form-item>

            <div class="forgetPassword">
              <div>
                <el-checkbox v-model="account" />
                <span class="lianjie">记住密码 </span>
              </div>
              <div>
                <!-- <el-checkbox v-model="rememberAccount" /> -->
                <span class="lianjie forgetPasswordFont" @click="routeWangji">忘记密码？
                </span>
              </div>
            </div>

            <!-- <div style="display: flex;align-items: center;margin:24px 0px;padding-left:10px">
              <el-checkbox v-model="rememberAccount" />
              <span class="lianjie">同意
                <span style="color:#F5222D;cursor: pointer;"
                      @click="infoShow">
                  《药潺潺库用户服务协议》
                </span>
              </span>
            </div> -->

            <el-button :loading="loading" type="primary" class="btnClass"
              @click.native.prevent="handleLogin">登录</el-button>
          </el-form>
          <div style="
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 24px;
            ">
            <!-- <el-checkbox v-model="rememberAccount" /> -->
            <span class="lianjie forgetPasswordFont" @click="routeZhuce" style="padding-left: 0px">立即注册
            </span>
          </div>
          <!-- <div style="display: flex;align-items: center;margin-top:24px;padding-left:10px">
            <el-checkbox v-model="rememberAccount" />
            <span class="lianjie">同意
              <span style="color:#F5222D;cursor: pointer;"
                    @click="infoShow">
                《药潺潺库用户服务协议》
              </span>
            </span>
          </div> -->
        </div>
        <div class="login-main" v-show="loginStatus == '2' || loginStatus == '4'">
          <div class="zhuceTitle">
            <!-- <img class="img"
                 src="/img/loginTop.png"
                 alt> -->
            {{ loginStatus == "2" ? "欢迎注册" : "找回密码" }}
          </div>
          <el-form ref="registerForm" :model="registerForm" :rules="registerRules" class="login-form" auto-complete="on"
            label-position="left">
            <el-form-item prop="tel" style="margin-bottom: 16px !important">
              <span class="svg-container">
                <!-- <svg-icon icon-class="user" /> -->
                <img class="img" src="../../assets/login/shouji.png" alt />
              </span>
              <el-input ref="tel" v-model="registerForm.tel" placeholder="请输入手机号" size="small" name="tel" type="number"
                tabindex="1" />
            </el-form-item>

            <el-form-item prop="yanzheng" style="margin-bottom: 16px !important">
              <span class="svg-container">
                <img class="img" src="../../assets/login/dunpai.png" alt />
              </span>
              <el-input ref="yanzheng" v-model="registerForm.yanzheng" type="number" placeholder="请输入验证码" tabindex="2"
                size="small" />
              <span class="yanzhengma">
                <!-- 获取验证码 -->
                <div class="chongfa">
                  <span style="cursor: pointer" v-show="registerStatus == '1'" @click="acquire">获取验证码</span>
                  <span style="cursor: pointer" v-show="registerStatus == '3'" @click="acquireNew">重新发送</span>
                  <el-statistic v-show="registerStatus == '2'" :value="deadline2" @finish="finish" format="ss"
                    time-indices>
                    <template slot="suffix"> 后重发 </template>
                  </el-statistic>
                </div>

                <!-- <img class="img"
                     :src="passwordType == 'password' ? close : eyes"
                     alt> -->
              </span>
            </el-form-item>
            <el-form-item prop="onepassword" style="margin-bottom: 16px !important">
              <span class="svg-container">
                <img class="img" src="../../assets/login/suo.png" alt />
              </span>
              <el-input ref="firstPasswordType" v-model="registerForm.onepassword" :type="firstPasswordType"
                placeholder="请输入密码" name="password" auto-complete="on" size="small" />
              <span class="show-pwd" @click="showFirstPwd">
                <img class="img" :src="firstPasswordType == 'password' ? close : eyes" alt />
              </span>
            </el-form-item>

            <el-form-item prop="twopassword" style="margin-bottom: 32px !important">
              <span class="svg-container">
                <img class="img" src="../../assets/login/suo.png" alt />
              </span>
              <el-input ref="secondPasswordType" v-model="registerForm.twopassword" :type="secondPasswordType"
                placeholder="请再次输入密码" name="password" auto-complete="on" size="small" />
              <span class="show-pwd" @click="showSecondPwd">
                <img class="img" :src="secondPasswordType == 'password' ? close : eyes" alt />
              </span>
            </el-form-item>
            <div v-if="loginStatus == '2'" class="forgetPassword" style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin: 24px 0px;
                padding-left: 10px;
              ">
              <el-checkbox v-model="rememberAccount" />
              <span class="lianjie">同意
                <span style="color: #f5222d; cursor: pointer" @click="infoShow">
                  《药潺潺用户服务协议》
                </span>
              </span>
            </div>
            <el-button :loading="sureLoading" type="primary" class="btnClass"
              :disabled="!(rememberAccount || loginStatus == '4')" @click.native.prevent="handleRegister">确认</el-button>
          </el-form>
          <div style="
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 16px;
            ">
            <span class="lianjie forgetPasswordFont" @click="loginStatus = '1'" style="padding-left: 0px">返回登录
            </span>
          </div>
        </div>
        <div class="login-main" v-show="loginStatus == '3'">
          <div style="
              display: flex;
              justify-content: center;
              margin-bottom: 32px;
              margin-top: 105px;
            ">
            <img class="img" src="../../assets/login/agree.png" alt />
          </div>
          <div class="success" style="display: flex; justify-content: center; margin-bottom: 16px">
            恭喜！注册成功
          </div>
          <div class="jumpLogin" style="display: flex; justify-content: center">
            请继续完善账户的资质信息
          </div>
          <div class="login-form" style="
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 60px;
            ">
            <el-button type="primary" class="btnClass" @click.native.prevent="submitLogin">确认并补充资质</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-copyright">
      Copyright © 湖南春播云药库科技有限公司版权所有 客服热线：4000819577
    </div>
    <ServeInfo ref="serveinfo" @sure="goCheck" />
    <div v-if="tokenLoading" class="loading_box">
      <div class="loading_content">
        <i class="el-icon-loading" />
      </div>
    </div>
  </div>
</template>

<script>
import { encryption } from "@/utils/index";
import close from "@/assets/login/close-eyes.png";
import eyes from "@/assets/login/eyes.png";
import "@/styles/login.scss";
import { validUsername } from "@/utils/validate";
import { randomLenNum } from "@/utils/index";
import { queryShopMoney, dealMoneyInfo } from "@/api/shopmange";
import { informationGuide } from "@/api/shop"
import {
  getStatus,
  sendCode,
  register,
  forgetPassword,
  getInfo,
} from "@/api/user";

import website from "@/utils/website";
import ServeInfo from "./serveOnline.vue";
import { guaranteeInfo } from "@/api/surety";

export default {
  name: "Login",
  components: {
    ServeInfo,
  },
  data() {
    // const validateUsername = (rule, value, callback) => {
    //   if (value.length < 3) {
    //     callback(new Error('请输入账号'))
    //   } else {
    //     callback()
    //   }
    // }
    // const validatePassword = (rule, value, callback) => {
    //   if (value.length < 3) {
    //     callback(new Error('The password can not be less than 6 digits'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      sureLoading: false, // 注册loading
      firstPasswordType: "password", // 注册第一个input类型
      secondPasswordType: "password", // 注册第二个input类型
      registerStatus: "1", // 1-获取，2-倒计时，3-重新发送
      deadline2: Date.now() + 1000 * 60, //倒计时
      // 注册表单
      registerForm: {
        tel: "", // 13488188001,13211111114,13488188002
        yanzheng: "", // Aa123456
        onepassword: "",
        twopassword: "",
      },
      // 注册规则
      registerRules: {
        // username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        tel: [
          { required: true, trigger: "blur", message: "请输入手机号" },
          {
            min: 11,
            max: 11,
            message: "请输入长度11位的手机号",
            trigger: "blur",
          },
        ],
        yanzheng: [
          { required: true, trigger: "blur", message: "请输入验证码" },
          { min: 6, max: 6, message: "请输入6位验证码", trigger: "blur" },
        ],
        onepassword: [
          { required: true, trigger: "blur", message: "请输入密码" },
          { min: 6, max: 20, message: "请输入6-20位有效密码", trigger: "blur" },
        ],
        twopassword: [
          { required: true, trigger: "blur", message: "请再次输入密码" },
          { min: 6, max: 20, message: "请输入6-20有效密码", trigger: "blur" },
        ],
      },
      loginStatus: "1", // 1-登录页面，2-注册页面，3-注册成功页面，4-找回密码
      eyes: eyes, // 眼睛图片
      close: close, // 眼睛关闭图片
      account: false, // 记住密码勾选
      tokenLoading: false,
      code: {
        src: "/code",
        value: "",
        len: 4,
        type: "image",
      },
      loginForm: {
        username: "", // 13488188001,13211111114,13488188002
        password: "", // Aa123456
        code: "1",
        randomStr: "",
      },
      loginRules: {
        // username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        username: [{ required: true, trigger: "blur", message: "请输入账号" }],
        password: [{ required: true, trigger: "blur", message: "请输入密码" }],
      },
      loading: false,
      passwordType: "password",
      redirect: undefined,
      rememberAccount: true,
      routes: {
        home: "/",
      },
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener("keyup", this.keyupLogin);
  },
  created() {
    this.$store.dispatch("user/resetToken").then(() => {
      const { allRoutes } = this;
      console.log("route.query", this.$route.query);
      const { accessToken, url, ids, loginStatus } = this.$route.query;
      if (loginStatus) {
        this.loginStatus = loginStatus;
        return;
      }
      if (accessToken && url) {
        this.tokenLoading = true;
        setTimeout(() => {
          this.tokenLoading = false;
        }, 500);
        console.log(ids);
        this.$store.dispatch("user/setTokenNew", accessToken).then(() => {
          let query = {};
          if (url == "/drugprocurement/shoppingcart-confirmOrder") {
            // query = { ids: JSON.parse(ids) }
            query = { ids: ids };
          }
          this.tokenLoading = true;

          this.checkStatus(url, query);
        });
      }
      if (website.validateCode) {
        this.refreshCode();
      }
    });
    
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.keyupLogin);
  },
  methods: {
    //监听回车键，登录
    keyupLogin(e) {
      if (e.keyCode == 13) {
        this.handleLogin();
      }
    },
    // 注册成功直接跳过登录
    submitLogin() {
      // this.$refs.registerForm.resetFields()
      this.loginForm.password = this.registerForm.onepassword;
      this.loginForm.username = this.registerForm.tel;
      this.handleLogin();
    },
    // 注册页面确认按钮
    handleRegister() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          // 手机正则表达式判断
          const reg = /^(13|14|15|16|17|18|19)[0-9]{9}$/;
          if (!reg.test(this.registerForm.tel)) {
            this.$message.error('请输入正确的手机号码')
            return;
          }
          if (this.registerForm.onepassword != this.registerForm.twopassword) {
            this.$message.error("两次密码输入不一致，请重新输入");
            return;
          }
          this.sureLoading = true;
          const obj = encryption({
            data: this.registerForm,
            key: "8vkv0#e^D@mjv%VS",
            param: ["onepassword"],
          });
          let params = {
            type: "3",
            mobile: obj.tel,
            code: obj.yanzheng,
            password: obj.onepassword,
          };
          if (this.loginStatus == "4") {
            params.type = "2";
            forgetPassword(params).then((res) => {
              if (res.code == 0) {
                this.sureLoading = false;
                if (this.loginStatus == "4") {
                  this.loginStatus = "1";
                } else {
                  this.loginStatus = "3";
                }
                this.$refs.loginForm.resetFields();
              } else {
                this.sureLoading = false;
                // this.$message.error(res.msg);
              }
            }).catch(() => {
              this.sureLoading = false;
            });
          } else {
            register(params).then((res) => {
              if (res.code == 0) {
                this.sureLoading = false;
                if (this.loginStatus == "4") {
                  this.loginStatus = "1";
                } else {
                  this.loginStatus = "3";
                }
                this.$refs.loginForm.resetFields();
              } else {
                this.sureLoading = false;
                // this.$message.error(res.msg);
              }
            }).catch(() => {
              this.sureLoading = false;
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 注册第一个输入密码眼睛事件
    showFirstPwd() {
      if (this.firstPasswordType === "password") {
        this.firstPasswordType = "";
      } else {
        this.firstPasswordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.firstPasswordType.focus();
      });
    },
    // 注册第二个输入密码眼睛事件
    showSecondPwd() {
      if (this.secondPasswordType === "password") {
        this.secondPasswordType = "";
      } else {
        this.secondPasswordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.secondPasswordType.focus();
      });
    },
    // 倒计时完成
    finish(data) {
      this.registerStatus = "3";
    },
    // 重新获取验证码
    acquireNew() {
      this.acquire();
    },
    // 点击获取验证码
    acquire() {
      let params = {
        type: "3",
        mobile: this.registerForm.tel,
      };
      if (!params.mobile) {
        this.$message.error("请先输入手机号码");
        return;
      }
      if (this.loginStatus == "4") {
        params.type = "2";
      }
      sendCode(params).then((res) => {
        if (res.code == 0) {
          this.registerStatus = "2";
          this.deadline2 = Date.now() + 1000 * 60;
        } else {
          // this.$message.error(res.msg);
        }
      }).catch(() => {
      });
    },
    // 切换登录或者注册状态
    routeZhuce() {
      this.$refs.loginForm.resetFields();
      this.$refs.registerForm.resetFields();
      this.registerStatus = "1";
      this.loginStatus = "2";
    },
    // 忘记密码按钮
    routeWangji() {
      this.$refs.loginForm.resetFields();
      this.$refs.registerForm.resetFields();
      this.registerStatus = "1";
      this.loginStatus = "4";
    },
    infoShow() {
      this.$refs["serveinfo"].open();
    },
    goCheck(val) {
      this.rememberAccount = val || false;
    },
    refreshCode() {
      this.loginForm.randomStr = randomLenNum(this.code.len, true);
      if (this.code.type === "text") {
        this.code.value = randomLenNum(this.code.len);
      }
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("user/login", this.loginForm)
            .then((res) => {
              this.checkStatus();
              this.checkMoney()
              this.loading = false;
              // this.$router.push({ path: "/storeSettleIn" });
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    async checkMoney() {
       // 查询商户的保证金余额
        guaranteeInfo({}).then(res => { 
          console.log(res,'保证金余额') 
            if(res.payThreshold && res.payThreshold > (res.useAmount + res.lockAmount)){
              // 不显示取消按钮
              this.$confirm('您的保证金余额不足，请及时缴纳', '提示', {
                confirmButtonText: '确定', 
                showCancelButton: false,
                // cancelButtonText: '下次提醒',
              }).then(() => {
                // this.dealMoneyDialog()
              }).catch(() => {

              });
            }

            if(res.stopThreshold && res.stopThreshold > (res.useAmount + res.lockAmount)){
              this.$confirm('您的保证金余额不足,您无法提现，请及时缴纳', '提示', {
                confirmButtonText: '确定',
                showCancelButton: false,
                // cancelButtonText: '下次提醒',
              }).then(() => {
                // this.dealMoneyDialog()
              }).catch(() => {

              });
            }

        })
        
    },
    async dealMoneyDialog() {
      // await dealMoneyInfo();
    },
    checkStatus(val, query) {
      getStatus().then((res) => {
        // res.data.status = 2;  //测试代码

        const { code, data, msg } = res;
        const { status } = data;

        if (code == 0) {
          if (status == null) {

            this.$router.push({ path: "/mymerchantsettlementb" });
            return;
          }
          if (status == 0) {
            this.$message.warning("资质正在审核中，请耐心等待");
            this.$router.push({ path: "/qualificationDetails" });
            return;
          }
          if (status == 1) {
            // this.$alert('您的资质审核失败，请重新提交', '审核结果', {
            //   confirmButtonText: '确定',
            //   callback: () => {
            //     this.$router.push({ path: '/qualificationDetails' })
            //   }
            // });
            this.$router.push({ path: "/qualificationDetails" });
            return;
          }
          if (status == 2) { 
            
            informationGuide().then((res) => {
              console.log(res);
                if (res) { 
                  console.log(res);
                  this.$router.push({ path: "/storeSettleIn" });
                } else {
                  if (val) {
                    this.$router.push({ path: val, query });
                  } else {
                    // 当前用户资质审核通过 可以进入系统
                    // this.$router.push({ path: '/' }) 
                    getInfo().then((data) => {
                      if (data && data.data.defaultMenuPath) {
                        this.$router.push({ path: data.data.defaultMenuPath });
                      } else {
                        this.$router.push({ path: "/" });
                      }
                    });
                  }
                }
              }).catch(() => { 
                if (val) {
                  this.$router.push({ path: val, query });
                } else {
                  getInfo().then((data) => {
                    if (data && data.data.defaultMenuPath) {
                      this.$router.push({ path: data.data.defaultMenuPath });
                    } else {
                      this.$router.push({ path: "/" });
                    }
                  });
                }
              });  
            return;
          }
        }
       
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.loading {
  &_box {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    cursor: pointer;
  }

  &_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 130px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 60px;
    border-radius: 10px;
  }
}

.login-container {
  .forgetPasswordFont {
    cursor: pointer;
    font-size: 14px;
    // color: #F5222D;
    color: #f5222d;
  }

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;

    .el-form-item {
      margin-bottom: 24px !important;

      ::v-deep .el-input__inner:focus {
        border-color: #f5222d;
      }
    }

    .btnClass {
      width: 356px;
      height: 64px;
      background: #f5222d;
      border-color: #f5222d;
      border-radius: 32px;
      opacity: 0.99;
      font-size: 24px;
      // font-family: PingFang SC, PingFang SC-Semibold;
      // font-weight: 600;
      color: #ffffff;
    }

    .forgetPassword {
      padding: 0 10px;
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;

      ::v-deep .el-checkbox__inner {
        color: #f5222d !important;
        // background-color: #f5222d;
        border-color: #f5222d !important;
      }

      ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #f5222d !important;
      }

      .forgetPasswordFont {
        cursor: pointer;
        font-size: 14px;
        color: #f5222d;
      }
    }
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    position: absolute;
    z-index: 12;
    color: $dark_gray;
    vertical-align: middle;
    top: 20px;
    left: 20px;
    width: 30px;
    display: inline-block;
  }

  .show-pwd {
    position: absolute;
    right: 24px;
    top: 20px;
    font-size: 18px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .login-main {
    .jumpLogin {
      height: 24px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: rgba(89, 89, 89);
      line-height: 24px;
    }

    .success {
      height: 40px;
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      color: rgba(38, 38, 38);
      line-height: 40px;
    }

    .zhuceTitle {
      margin: 0px 0px 40px 19px;
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: left;
      color: rgba(0, 0, 0);
      line-height: 45px;
    }

    .yanzhengma {
      position: absolute;
      right: 24px;
      top: 10px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #f5222d;

      .el-statistic {
        position: relative;
        top: 10px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: right;
        line-height: 18px;

        ::v-deep .con {
          color: #f5222d !important;
        }
      }
    }
  }
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #f5222d;
}

::v-deep .el-input {
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.bottom-copyright {
  position: fixed;
  bottom: 16px;
  left: 42%;
  color: #545965;
  font-size: 12px;
}

.lianjie {
  color: #000000;
  padding-left: 10px;
  font-size: 14px;
  // cursor: pointer;
}
.logo-img {
   width: 360px;
   img {
    width: 100%;
  }
}
</style>
