var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "public-task-detail-root" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "public-task-dialog",
          attrs: {
            title: _vm.title,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "1018px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.onClosed,
          },
        },
        [
          _c("div", { staticClass: "full-content" }, [
            _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "title" }, [_vm._v("切换任务类型")]),
              _c(
                "div",
                { staticClass: "v-list" },
                _vm._l(_vm.commandList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: {
                        "v-list-item": true,
                        active: _vm.currentCommand === item.value,
                        disabled: _vm.isLook,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSelectTaskType(index)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.label) + " ")]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "split-block v" }),
            _c("div", { staticClass: "content form-content" }, [
              _c("div", { staticClass: "block" }, [
                _c("div", { staticClass: "title" }, [_vm._v("任务基本信息")]),
                _c("div", { staticClass: "flex-layout" }, [
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("任务名称"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            disabled: _vm.isLook,
                            maxlength: "20",
                          },
                          model: {
                            value: _vm.name,
                            callback: function ($$v) {
                              _vm.name = $$v
                            },
                            expression: "name",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("任务编码")]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            disabled: "",
                            placeholder: "创建后自动生成",
                            value: _vm.code,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("关联指标"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            disabled: "",
                            placeholder: "",
                            value: _vm.target ? _vm.target.name : "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("CSO公司名称")]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            disabled: "",
                            placeholder: "",
                            value: _vm.target ? _vm.target.coopShopName : "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("执行次数"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            type: "number",
                            disabled: _vm.isLook,
                            maxlength: "2",
                          },
                          on: { input: (e) => _vm.thresholdReg("taskNum", e) },
                          model: {
                            value: _vm.taskNum,
                            callback: function ($$v) {
                              _vm.taskNum =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "taskNum",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("单次执行金额"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            type: "number",
                            disabled: _vm.isLook,
                            maxlength: "10",
                          },
                          on: {
                            input: (e) => _vm.thresholdReg("taskPrice", e),
                          },
                          model: {
                            value: _vm.taskPrice,
                            callback: function ($$v) {
                              _vm.taskPrice =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "taskPrice",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item long" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("任务说明")]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            maxlength: "200",
                            disabled: _vm.isLook,
                          },
                          model: {
                            value: _vm.remark,
                            callback: function ($$v) {
                              _vm.remark = $$v
                            },
                            expression: "remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "split-block h" }),
              _c("div", { staticClass: "block" }, [
                _c("div", { staticClass: "remark" }, [
                  _vm._v(
                    " 以下为任务执行表单，由CSO公司执行时填写，您仅可预览。 "
                  ),
                ]),
                _c("div", { staticClass: "title" }, [_vm._v("执行详情")]),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "16px" } },
                  [
                    _c("childtemplate", {
                      attrs: { childTaskType: _vm.childTaskType },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v("关闭")]
              ),
              !_vm.isLook
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }