<template>
  <div>
    <div class="contentCont">
      <div>
        <div class="head-img" ref="headRef">
          <el-image :src="storeHead"></el-image>
        </div>
        <div class="staus_warp">
          <div class="staus_plan">
            <div v-for="(item, key) in indexStepList" class="step-item">
              <div :class="item.step <= indexStep ? 'active' : ''" class="step">
                <div class="fl_center">
                  <div class="status">
                    <el-image v-if="indexStep > item.step" class="icon_svg" :src="icon" />
                    <div v-else class="idx">{{ item.step }}</div>
                  </div>
                  <div class="name" :class="indexStep > 1 ? 'sueccss' : ''">
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div :class="item.step <= indexStep ? 'active' : ''" class="line" v-if="item.step < indexStepList.length" />
            </div>
          </div>
        </div>
      </div>
      <div class="contentInner">
        <div class="shopCotent" :style="`min-height:${contentHeight}px;`">
          <shopManageFirst v-if="indexStep == 1" ref="shop-manage-1" @indexStepChange="indexStepChange" />
          <shopManageTwo v-if="indexStep == 2" ref="shop-manage-2" @indexStepChange="indexStepChange" />
          <shopManageThree v-if="indexStep == 3" ref="shop-manage-3" @indexStepChange="indexStepChange" />
          <shopManageFour v-if="indexStep == 4" ref="shop-manage-4" @indexStepChange="indexStepChange" />
          <shopManageFive v-if="indexStep == 5" ref="shop-manage-5" @indexStepChange="indexStepChange" @shopSettingsExamine="shopSettingsExamine"/>
        </div>
      </div>
    </div>
    <div class="footer-warp">
      <div class="footer">
        <el-button class="el_btn" @click="goBackHome">返回首页</el-button>
        <el-button class="el_btn" @click="goSave" type="plain" v-if="indexStep != 2">保存</el-button>
        <el-button class="el_btn" @click="goBack" type="primary" v-if="indexStep != 1">上一步</el-button>
        <el-button class="el_btn" @click="goNext" type="primary" v-if="indexStep != 5">下一步</el-button>
        <el-button class="el_btn" @click="submitForm" type="primary" v-if="indexStep == 5">提交</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import storeHead from "@/assets/storeHead.jpg"
import shopManageFirst from "./shopManageFirst.vue"
import shopManageTwo from "./shopManageTwo.vue"
import shopManageThree from "./shopManageThree.vue"
import shopManageFour from "./shopManageFour.vue"
import shopManageFive from "./shopManageFive.vue"
import {shopSettingsExamine,queryShopSettingsCompleteStatus} from '@/api/shop'

export default {
  components: {
    shopManageFirst,
    shopManageTwo,
    shopManageThree,
    shopManageFour,
    shopManageFive
  },
  provide() {
        return {
          reloadShopAll: this.queryShopSettingsCompleteStatus
        }
    },
  data() {
    return {
      icon: require(`@/assets/icon_detail_status.png`),
      indexStep:1,
      storeHead: storeHead,
      rules: {
        name: [
          {
            required: true,
            message: `请输入企业名称`,
            trigger: `blur`,
          },
        ],
      },
      tabsList: [{
        label: '店铺信息',
        completeName: 'SHOP_SETTINGS',
        name: '1',
        value: 0
      }, {
        label: '发货配置',
        completeName: 'SHIPPING_DISTRIBUTION_SETTINGS',
        name: '2',
        value: 0
      }, {
        label: '售后设置',
        completeName: 'AFTER_SALES_SETUP',
        name: '3',
        value: 0
      }, {
        label: '服务设置',
        completeName: 'DAY_SETTING',
        name: '4',
        value: 0
      }, {
        label: '开户设置',
        completeName: 'ACCOUNT_OPENING_SETTINGS',
        name: '5',
        value: 0
      }],
      indexStepList: [
        {
          step: 1,
          name: '店铺信息',
        }, {
          step: 2,
          name: '发货配置',
        }, {
          step: 3,
          name: '售后设置',
        }, {
          step: 4,
          name: '服务设置',
        }, {
          step: 5,
          name: '开户设置',
        }],
      contentHeight: 100,
      completeStatus: true,  // 是否可以提交审核
    };
  },
  computed: {
    onlyPage() {
      return window.location.hash == '#/storeSettleIn'
    }
  },
  created() {

  },
  mounted() { 
  },
  methods: {
    shopSettingsExamine() {
      shopSettingsExamine().then(res => {
        if (res) {
          this.$message.success('提交成功'); 
          window.location.href = '/#/'
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    // 查询店铺设置是否完成
    queryShopSettingsCompleteStatus() { 
      queryShopSettingsCompleteStatus().then(res => {
        if (res) { 
          this.tabsList.forEach((item,key) => { 
            item.value = res[key].completeStatus;
            if(res[key].completeStatus == 0) {
              this.completeStatus = false;
            }
          })
        }
      })
    },
    goNext() {
      let refName = 'shop-manage-' + this.indexStep;
      this.$refs[refName].goNext('NEXT');  
    },
    indexStepChange(val) {
      this.indexStep = val;
    },
    goBack() {
      this.indexStep -= 1;
    },
    goSave() {
      let refName = 'shop-manage-' + this.indexStep;
      this.$refs[refName].goSave();
      // 查询店铺设置是否完成
      this.queryShopSettingsCompleteStatus();
    },
     // 提交审核
     submitForm() { 
      let refName = 'shop-manage-' + this.indexStep;
      this.$refs[refName].doUpdateInfo(true); 
    },
    // 修改步骤
    setStep(val, code) {
      this.indexStep += 1;
      if (code) {
        this.typeCode = code
      }
    },
    goBackHome() {
      window.location.href = '/#/'
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";

.allheight {
  min-height: 100vh !important;
}

.status {
  &_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;

    h3 {
      font-size: 30px;
      font-weight: 400;
      color: #333;
      line-height: 2;
    }
  }

  &_ico {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    color: #fff;
    font-size: 30px;
    line-height: 1;
    border-radius: 100%;
  }

  &_success {
    background: #32bf78;
  }

  &_err {
    background: #f56c6c;
  }
}

.contentCont {
  min-height: calc(100vh - 81px);
  padding-bottom: 100px;

  .staus_warp {
    width: 100%;
    height: 64px;
    background: #f5f5f5;
  }

  .staus_plan {
    padding: 24px 0px;
    display: flex;
    align-items: center;
    width: 1024px;
    margin: auto;
    justify-content: space-around;
    height: 64px;

    .step {
      .status {
        .idx {
          width: 32px;
          height: 32px;
          border-radius: 100%;
          border: 1px solid rgba(0, 0, 0, 0.35);
          font-size: 14px;
          text-align: center;
          color: rgba(0, 0, 0, 0.35);
          line-height: 32px;
          margin: 0 auto;
        }
      }

      .icon_svg {
        width: 32px;
        height: 32px;
      }

      .name {
        color: rgba(0, 0, 0, 0.35);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-left: 10px;
      }

      .time {
        position: absolute;
        left: 40px;
        width: 150px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #91939a;
        line-height: 22px;
      }
    }

    .line {
      margin: 0 8px;
      width: 48px;
      height: 2px;
      border: solid 1px rgba(0, 0, 0, 0.15);
    }

    .step {
      display: flex;
      justify-content: flex-start;
    }

    .step.active {
      .status .idx {
        border: 1px solid #F5222D;
        color: #fff;
        background: #F5222D;
      }

      .name {
        color: #F5222D;
      }

      .name.sueccss {
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .line.active {
      border: solid 1px #F5222D;
    }
  }
}

.fl_center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.step-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-warp {
  width: 100%;
  padding: 20px 0;
  border-top: solid 1px #eee;
  position: fixed;
  bottom: 0;
  height: 80px;
  background: #fff;
  z-index: 111;
}

.footer {
  width: 552px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}

.el_btn {
  width: 160px;
}

.contentInner {
  width: 1024px;
  margin: 0 auto;
}

.shopCotent {
   
}
</style>
