<!--
 * @FilePath: /eshop-ops-web/src/views/goodsManage/baseDepot/details.vue
 * @Description: 
 * @Author: xiexingzhong2012@sina.cn
 * @Date: 2023-07-24 17:09:29
 * @LastEditTime: 2023-07-30 11:15:47
 * @LastEditors: xiexingzhong2012@sina.cn
-->
<template>
  <div class="comContent">
    <div class="firstTopic text-left mb20 header">{{ title }}</div>
    <goodsInfo ref="goods-Info" @submit-goods="submit" @back="goBack" @reject-goods="rejectGoods" :pageType="pageType" />
  </div>
</template>
<script>
// addGsoodsbase
import {
  addGoodsCorrection,
} from "@/api/goodsManage/baseDepot.js";
import goodsInfo from "./components/goodsInfo.vue";
export default {
  components: { goodsInfo },
  data() {
    return {
      title: "商品详情",
      pageType: 1, //pageType 1商品基库-》 查看  2商品基库-》 编辑（操作：保存提交，返回）  3商品基库-》 创建 （操作：保存提交，返回）     4  商家申请-》详情 （操作：创建，驳回） 5 商家申请-》创建商品基础库 （操作：保存提交，返回）
    };
  },
  filters: {},
  created() { },
  mounted() {
    const { pageType } = this.$route.query;
    console.log("pageType", pageType);
    if (pageType != undefined) {
      this.pageType = pageType;
    }
  },
  methods: {
    handleClick() { },
    /**
     * @description: 保存商品详情
     * @return {*}
     */
    addGoodsCorrection(params) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      addGoodsCorrection(params).then((res) => {
        loading.close();
        if (res && res.code == 0) {
          setTimeout(() => {
            this.$message({
              message: `提交成功！`,
              type: "success",
            });
            this.submitBack();
          }, 2000);
        }
      }).catch(() => {
        loading.close();
      })
    },



    // 提交按钮
    submit(params) {
      this.addGoodsCorrection(params);
    },
    // goBack 返回
    goBack() { 
      if (this.pageType == 2 || this.pageType == 3 || this.pageType == 5) {
        this.$confirm(
          "未保存的内容将会丢失，请问是否确认返回上一级？",
          "返回上一级",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$router.push("./salesControl-commodity-index"); 
          })
          .catch(() => { });
      } else if (this.pageType == 1) {
        if (this.$route.query.sourcePage != undefined) {
          this.$router.push(this.$route.query.sourcePage);
        } else {
          this.$router.push("./salesControl-commodity-index"); 
        }
      }
    },

    submitBack() {
      if (this.$route.query.sourcePage != undefined) {
        this.$router.push(this.$route.query.sourcePage);
      } else {
        this.$router.push("./salesControl-commodity-index"); 
      }
      
    },

    // 驳回
    rejectGoods() { },
  },
};
</script>
<style lang="scss" scoped>
@import url("./styles/index.scss");
</style>
