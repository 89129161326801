<template>
  <el-dialog :title="title" :visible.sync="visible" :lock-scroll="true" width="718px" :before-close="handleClose"
    append-to-body>
    <div class="">
      <div style="padding: 24px 0;">
        <el-form ref="form-data" :model="form" :inline="false" label-width="150px" label-position="right" :rules="rules"
          style="text-align:left;">
          <el-form-item prop="remitTitle" label="收款户名">
            <el-input v-model.trim="form.remitTitle" style="width: 400px;" placeholder="" clearable size="small"
              :disabled="true" show-limit-word max-length="50" />
          </el-form-item>

          <el-form-item prop="bank" label="开户银行">
            <el-input v-model.trim="form.bank" style="width: 400px;" placeholder="" clearable size="small" type="text"
              :maxlength="18" :disabled="true" />
          </el-form-item>

          <el-form-item prop="remitNo" label="收款账号">
            <el-input v-model.trim="form.remitNo" style="width: 400px;" placeholder="" clearable size="small"
              v-positive-integer show-limit-word :maxlength="8" :disabled="true" />
          </el-form-item>

          <el-form-item prop="imageList" label="打款凭证">
            <imgUpload v-model="form.imageList" :isEdit="isDisabled" :limit="3" style="position:relative;top:-8px">
              <template slot="tip">
                <p class="el-upload__tip" style="margin:0px">
                  备注:图片格式为jpg或png，图片大小不得超过5M;最多上传三张图片
                </p>
              </template>
            </imgUpload>
          </el-form-item>
          <el-form-item>
            <div class="footer-btn">
              <el-button size="small" type="primary" @click="saveSubmit()" :disabled="isDisabled">保存</el-button>
              <el-button size="small" type="plain" @click="handleClose()">返回</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>
    
<script>
import fileUpload from "@/components/fileUpload";
import avater from "/public/img/default.png";
import imgUpload from '@/components/imgUpload';
import { payvoucherinfoAdd, payvoucherinfoGetinfo, payvoucherinfoResubmit } from "@/api/offlinePayment";
import { informationPlatform } from '@/api/shop'

export default {
  props: {},
  components: { imgUpload, fileUpload },

  data() {
    return {
      avater: avater,
      disabled: false,
      id: null,
      pageType: '',
      isDisabled: false,
      form: {
        imageList: [],
        remitNo: '',
        remitTitle: '',
        bank: '',
      },
      rules: {
        imageList: [{ required: true, message: "请上传凭证", trigger: "change" }],
      },
      title: '',
      visible: false,
    }
  },
  created() {
  },
  mounted() {
    this.getShopInfo()
  },
  watch: {},
  methods: {
    getShopInfo() {
      informationPlatform().then(res => {
        if (res) {
          this.form.remitTitle = res.yykAccountName;
          this.form.bank = res.bankDeposit;
          this.form.remitNo = res.bankCardNumber;
        }
      })
    },
    restrictInput() {
      // 限制输入为正整数  
      const regex = /^[1-9]\d*$/;
      if (!regex.test(this.inputValue)) {
        this.form.amount = this.form.amount.slice(0, -1);
      }
    },

    saveSubmit() {
      let params = {};
      console.log(this.id, '--this.id')
      // if (this.form.imageList.length == 0) {
      //   this.$message.error('请上传凭证！');
      //   return;
      // }
      this.$refs['form-data'].validate((valid) => {
        params.voucherUrl = this.form.imageList.join(',');
        if (valid) {
          if (this.id != null) {
            params.id = this.id;
            payvoucherinfoResubmit(params).then((res) => {
              if (res) {
                this.$message.success('提交成功');
                this.$emit('getList')
                this.handleClose();
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            params.serverImgUrl = this.form.imageList.join(',');
            payvoucherinfoAdd(params).then((res) => {
              if (res) {
                this.$message.success('提交成功');
                this.$emit('getList')
                this.handleClose();
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      })
    },
    // payvoucherinfoGetinfo
    handleClose() {
      this.visible = false;
      this.$emit("handleClose")
    },
    init(row, type) {
      this.form.imageList =[];
      // console.log(row,'---row')
      if (type == 'reset') {
        this.id = row.id;
        this.title = '重新提交凭证';
      } else {
        this.title = '提交凭证';
      }
      this.visible = true;
    },
  }
}

</script>
<style lang="scss" scoped>
.eachRow {
  background-color: #fff;
  border-radius: 4px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
}

.titleView {
  display: flex;
  align-items: center;

  .rowLine {
    width: 4px;
    border-color: 1px;
    background-color: var(--main-color);
    height: 16px;
    margin-right: 8px;
  }

  .eachRowTitle {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
  }

  .cpxhead {
    display: flex;
    align-items: center;
  }

  .mark {
    color: #f56c6c;
    font-size: 12px;
    margin-left: 10px;
    width: 200px;
    line-height: 1.5;
    display: inline-block;
    font-weight: bold;
  }
}

.goods-list-warp {
  width: 100%;
  margin-left: 100px;
  max-height: 500px;
  overflow-y: auto;
}

.goods-list {
  width: 50%;
  border-bottom: solid 1px #f5f5f5;
  padding-bottom: 10px;

  &:nth-last-child(1) {
    border-bottom: none;
  }
}

.footer-btn {
  width: 410px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}</style>
  
  