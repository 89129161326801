<!--:span="24"
 * @FilePath: /eshop-ops-web/src/views/goodsManage/baseDepot/components/goodsInfo.vue
 * @Description: 
 * @Author: xiexingzhong2012@sina.cn
 * @Date: 2023-07-24 20:01:25
 * @LastEditTime: 2023-07-30 20:22:44
 * @LastEditors: xiexingzhong2012@sina.cn
-->
<template>
  <div class="">
    <el-col :span="24">
      <el-row>
        <el-col :span="24">
          <el-form-item label="商品类型" prop="goodsType" v-if="source == 1">
            <el-radio-group
              v-model="formTool.goodsType"
              @change="changeType(formTool.goodsType)"
            >
              <el-radio
                v-for="item in formTool.typeList"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <div v-if="source == 2" style="margin-top: 54px"></div>

      <el-row>
        <el-col
          :span="24" 
        >
          <goods-three-class
            ref="three-class"
            :formTool="formTool"
            :isShowLable="true"
            :lableText="'商品分类'"
                :changeForm="changeForm"
          ></goods-three-class>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            label="产品名称"
            prop="name" 
          >
            <span
              slot="label"
              :class="changeForm.name ? 'lable-color-correct' : ''"
              >产品名称</span
            >
            <el-input v-model="formTool.name" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            label="经营范围"
            prop="businessScopeId" 
          >  
          <span
              slot="label"
              :class="changeForm.businessScopeId ? 'lable-color-correct' : ''"
              >经营范围</span
            > 
            <el-select
              v-model="formTool.businessScopeId"
              size="small"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in formTool.scopeList"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            label="规格"
            prop="specification"  
          >
           <span
              slot="label"
              :class="changeForm.specification ? 'lable-color-correct' : ''"
              >规格</span
            >
            <el-input v-model="formTool.specification" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            label="批准文号"
            prop="approvalNumber" 
          >
           <span
              slot="label"
              :class="changeForm.approvalNumber ? 'lable-color-correct' : ''"
              >批准文号</span
            >
            <el-input v-model="formTool.approvalNumber" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            label="生产厂家"
            prop="factory" 
          >
            <span
              slot="label"
              :class="changeForm.factory ? 'lable-color-correct' : ''"
              >生产厂家</span
            >
            <el-input v-model="formTool.factory" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-if="showProducer">
        <el-col :span="24">
          <el-form-item
            label="产地"
            prop="origin" 
          > 
            <el-input v-model="formTool.origin" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row >
        <el-col :span="24">
          <el-form-item
            label="品牌名"
            prop="brand" 
          > 
            <el-input v-model="formTool.brand" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item label="单位" prop="unit"> 
            <el-select v-model="formTool.unit" placeholder="请选择" size="small" style="width: 100%">
              <el-option v-for="item in unitList" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      
      <!-- 商品分类属性 -->
      <dynamic-attribute
        ref="dynamic-attribute"
        :formTool="formTool"
        :changeExtendParams="changeExtendParams"
        :isShowAll="true"
      ></dynamic-attribute>

      <el-row>
        <el-col :span="24">
          <el-form-item
            label="图片"
            prop="imageList"
          
          >
             <span
              slot="label"
              :class="changeForm.imageList ? 'lable-color-correct' : ''"
              >图片</span
            >
            <imgUpload
              v-model="formTool.imageList"
              :limit="5"
              :isEdit="true"
            />
            <div class="upload-tips">
              上传图片大小需低于2M，图片格式PNG,JPG,JPEG，图片尺寸建议800 x
              800px
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-col>
  </div>
</template>
<script>
import imgUpload from "@/components/imgUpload";
import fileUpload from "@/components/fileUpload";
import wangEnduit from "@/components/wangEnduit";
import DynamicAttribute from "@/components/dynamicAttribute";
import GoodsThreeClass from "@/components/goodsThreeClass";
import getComList from "@/utils/getComList";
import {
  getAttributeByTypeId,
  getCompGoodsBaseDetail,
} from "@/api/goodsManage/baseDepot.js";
import {
  getunitList, 
} from "@/api/productManage/auditProduct";
export default {
  components: {
    imgUpload,
    wangEnduit,
    fileUpload,
    DynamicAttribute,
    GoodsThreeClass, //商品三级分类
  },
  props: {
    formTool: {
      type: Object,
      default() {
        return {};
      },
    },
    changeForm: {
      type: Object,
      default() {
        return {};
      },
    },
    changeExtendParams: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    source: {
      type: Number,
      default() {
        return 1;
      },
    },
  },
  data() {
    return {
      unitList:[],
      showProducer:false
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getunitList(); 
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  methods: {
    // 获取单位字典
     getunitList() {
      getunitList().then((res) => {
        if (res.code == 0) {
          this.unitList = res.data || [];
        }
      });
    },
       // 判断当前商品类型是否是中药饮片，是的话显示产地必填
       isShowProducer(goodsType, goodsTypeList) {
      let show = false;
      for (let i = 0; i < goodsTypeList.length; i++) {
        if (goodsType == goodsTypeList[i].id && goodsTypeList[i].name.indexOf('中药') != -1) {
          // console.log(goodsTypeList[i].name, '------------name') 
          show = true;
        }
      }
      this.showProducer = show;
    },
    /**
     * @functionName: onRemoveHandler
     * @description: 删除上传的文件
     * @return {*}
     */

    init(scopeList) {
      console.log(scopeList, "scopeList");
      this.formTool.scopeList = scopeList;
    },
    queryThreeClass() {
      if (this.$refs["three-class"]) {
        console.log(this.formTool, "---this.formTool", this.source);
        // this.$refs["three-class"].query(); //回显三级分类
        this.$refs["three-class"].queryDetail(); //回显三级分类
      }
    },

    onRemoveHandler(file) {
      let index = this.formTool.fileList.indexOf(file);
      this.formTool.fileList.splice(index, 1);
    },
    changeType(typeId) {
      this.getAttributeByTypeId(typeId);
    }, 
    
    getAttributeByTypeId(typeId) {
      getAttributeByTypeId({
        typeId: typeId,
        isMerchant: false, //是否根据商户去查，运营端传false 查全部
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let extendParams = res.data;

          for (let i = 0; i < extendParams.length; i++) {
            if (extendParams[i].input == "2") {
              extendParams[i].selectList =
                extendParams[i].selectEnum.split("，");
            }
            if (extendParams[i].input == "3") {
              extendParams[i].selectList =
                extendParams[i].selectEnum.split(",");
            }
            extendParams[i].value = "";
          }
          this.formTool.extendParams = extendParams;
          this.$forceUpdate();
          this.$nextTick(() => {
            this.$refs["goods-info"].clearValidate();
            this.strTofileList(this.formTool.fileListStr);
          });
        } else {
          this.$message({
            message: res.msg,
            type: "erro",
          });
        }
      });
    },


    watchEndit(val) {
      // 监听富文本编辑传过来的数据
      this.formTool.detail = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.comContent {
  padding: 0px;
}
.addGoods {
  width: 100%;
  height: 40px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  .addFont {
    // border: 1px dashed #d9d9d9;
    display: flex;
    width: 18px;
    font-size: 16px;
    justify-content: center;
    margin-right: 5px;
  }
}
.header {
  height: 56px;
  line-height: 56px;
  padding: 0 24px;
  border: 1px solid #f5f5f5;
}
.product {
  // margin: 0 24px;
  margin-bottom: 24px;
}
.firstTitle {
  font-size: 16px;
  color: #262626;
  font-weight: 700;
}
.secondTitle {
  font-size: 14px;
  // color: #999999;
}
.upload-tips {
  font-size: 12px;
  color: #8c8c8c;
}

.lable-color-correct {
  color: #f00;
}
</style>
