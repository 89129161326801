var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.editorInvoice, title: "发票抬头", width: "500px" },
      on: {
        "update:visible": function ($event) {
          _vm.editorInvoice = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "invoiceForm",
          attrs: {
            model: _vm.invoiceForm,
            rules: _vm.invoiceRules,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "是否默认发票", prop: "preferred" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.invoiceForm.preferred,
                    callback: function ($$v) {
                      _vm.$set(_vm.invoiceForm, "preferred", $$v)
                    },
                    expression: "invoiceForm.preferred",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("否")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发票类型", prop: "buyerProperty" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.invoiceForm.buyerProperty,
                    callback: function ($$v) {
                      _vm.$set(_vm.invoiceForm, "buyerProperty", $$v)
                    },
                    expression: "invoiceForm.buyerProperty",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "ENTERPRISE" } }, [
                    _vm._v("企业"),
                  ]),
                  _c("el-radio", { attrs: { label: "PERSONAL" } }, [
                    _vm._v("个人"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发票抬头", prop: "buyerName" } },
            [
              _c("el-input", {
                attrs: { size: "small" },
                model: {
                  value: _vm.invoiceForm.buyerName,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoiceForm, "buyerName", $$v)
                  },
                  expression: "invoiceForm.buyerName",
                },
              }),
            ],
            1
          ),
          _vm.invoiceForm.buyerProperty != "PERSONAL"
            ? _c(
                "el-form-item",
                { attrs: { label: "纳税人识别号", prop: "buyerTaxpayerId" } },
                [
                  _c("el-input", {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.invoiceForm.buyerTaxpayerId,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoiceForm, "buyerTaxpayerId", $$v)
                      },
                      expression: "invoiceForm.buyerTaxpayerId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.invoiceForm.buyerProperty != "PERSONAL"
            ? _c(
                "el-form-item",
                { attrs: { label: "地址", prop: "buyerAddress" } },
                [
                  _c("el-input", {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.invoiceForm.buyerAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoiceForm, "buyerAddress", $$v)
                      },
                      expression: "invoiceForm.buyerAddress",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.invoiceForm.buyerProperty != "PERSONAL"
            ? _c(
                "el-form-item",
                { attrs: { label: "电话", prop: "buyerRecipientPhone" } },
                [
                  _c("el-input", {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.invoiceForm.buyerRecipientPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoiceForm, "buyerRecipientPhone", $$v)
                      },
                      expression: "invoiceForm.buyerRecipientPhone",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.invoiceForm.buyerProperty != "PERSONAL"
            ? _c(
                "el-form-item",
                { attrs: { label: "开户行", prop: "buyerBank" } },
                [
                  _c("el-input", {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.invoiceForm.buyerBank,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoiceForm, "buyerBank", $$v)
                      },
                      expression: "invoiceForm.buyerBank",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.invoiceForm.buyerProperty != "PERSONAL"
            ? _c(
                "el-form-item",
                { attrs: { label: "开户行账号", prop: "buyerAccount" } },
                [
                  _c("el-input", {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.invoiceForm.buyerAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoiceForm, "buyerAccount", $$v)
                      },
                      expression: "invoiceForm.buyerAccount",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.invoiceForm.buyerProperty != "PERSONAL"
            ? _c(
                "el-form-item",
                { attrs: { label: "邮箱", prop: "buyerRecipientMail" } },
                [
                  _c("el-input", {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.invoiceForm.buyerRecipientMail,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoiceForm, "buyerRecipientMail", $$v)
                      },
                      expression: "invoiceForm.buyerRecipientMail",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.goSubmit()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }