<!-- 选择单位弹窗 -->
<template>
  <div class="dialog">
    <el-dialog
      title="创建产品线负责人"
      :visible.sync="visible"
      :lock-scroll="true"
      top="10%"
      width="400px"
      :before-close="handleClose"
    >
      <div class="dialogBox">
        <el-form
          ref="formData"
          :model="formData"
          :inline="true"
          label-width="120px"
        >
          <el-form-item label="用户名称" prop="name">
            <el-input
              v-model="formData.name"
              placeholder="请输入用户名称"
              size="small"
              style="max-width: 200px"
            />
          </el-form-item>
          <el-form-item label="联系方式" prop="name">
            <el-input
              v-model="formData.name"
              placeholder="请输入联系方式"
              size="small"
              style="max-width: 200px"
            />
          </el-form-item>
          <el-form-item label="密码" prop="name">
            <el-input
              v-model="formData.name"
              placeholder="请输入密码"
              size="small"
              type="password"
              style="max-width: 200px"
            />
          </el-form-item>
          <el-form-item label="确认密码" prop="name">
            <el-input
              v-model="formData.name"
              placeholder="请确认密码"
              type="password"
              size="small"
              style="max-width: 200px"
            />
          </el-form-item>
        </el-form>
        <div class="mt20 footer-bar" style="margin-bottom: 24px"> 
            <el-button type="primary" size="small" @click="comfirm"
              >提交</el-button
            >
            <el-button type="" size="small" @click="cancel">取消</el-button>
          
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import avater from "/public/img/default.png";
import Pagination from "@/components/Pagination";
export default {
  props: {
    unitList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    showpage: true,
  },
  components: {
    Pagination,
  },
  data() {
    return { 
      avater: "",
      formData: {},
      item: {}, 
      page: {
        current: 1,
        size: 10,
        total: 10,
      }, 
      // dialogVisible: false,
    };
  },
  computed: {
    visible: {
      get() {
        return this.dialogVisible;
      },
      set(val) {
        this.$emit("update:dialogVisible", val);
      },
    },
  },
  watch: {},
  methods: {
    comfirm() {},
    cancel() {
      this.$parent.cretdialogVisible = false;
    },
    pageChange(value) {
      this.page.size = value.limit;
      this.page.current = value.page;
      this.getList();
    },

    handleSelectionChange(val) {},
    handleCurrentChange(val) {},

    changeOneClassId() {},
    changeTwoClassId() {},
    // 确认选中的单位
    submitCheck(item, index) {
      this.checkIndex = index;
      // this.formData.skus[this.tableIndex].unit = item
      this.$emit("submitCheck", item);
      this.$parent.dialogVisible = false;
    },
    // 选中
    handleClose() {
      this.$parent.dialogVisible = false;
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
@import "@/styles/variables.scss";
.dialogBox {
  span {
    font-size: 14px;
    color: #595959;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 5px 15px;
  }
  .everyOneBox {
    border: 1px solid #dcdfe6;
  }
  .activeBox {
    border: 2px solid #F5222D;
  }
}
.itemCont {
  width: 214px;
  height: 305px;
  border: 1px solid #ccc;
  margin: 0 0 8px 8px;
  border-radius: 4px;

  &:hover {
    border: 2px solid;
    border-color: $borderColorActive;
  }

  .factory {
    color: #595959;
    font-size: 14px;
    height: 22px;
  }

  .itemBox {
    padding: 0px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 22px;
    margin: 0;
  }

  .name {
    display: block !important;
    font-size: 16px;
    font-weight: 600;
    padding: 4px 10px;
  }

  .itemImg {
    height: 180px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .price {
    font-weight: 700;
    justify-content: end;
  }

  .shopCar {
    margin-top: 5px;
    justify-content: center;

    .el-button {
      width: 100%;
      padding: 8px 20px;
    }
  }
}
.itemWarpList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.footer-bar {
  padding-right: 45px;
  display: flex;
   justify-content: flex-end;
  // justify-content: space-between;
}
</style>
