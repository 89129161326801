<template>
  <div class="tableMy">
    <MyTable :table-data="tableData"
             :index="index"
             :selection="selection"
             :page="page"
             :operation="operation"
             :table-option.sync="tableOption"
             @page-change="getList"
             :reserveSelection="true"  
             @handleSelectionChange="handleSelectionChange">
      <template slot="information"
                slot-scope="scope">
        <div style="display: flex; align-items: center; justify-content: left">
          <div class="imageP"
               style="width: 100%">
            <div style="font-weight: 600; color: #262626"  v-if=" activeName !='BASE_AWAIT' && activeName !='BASE_ALREADY' && activeName !='BASE_TEMPORARY_FAIL'">
              {{ scope.row.goodsSkuTitle || "-" }}
            </div>
            <div style="font-weight: 600; color: #262626"  v-if=" activeName =='BASE_AWAIT' || activeName =='BASE_ALREADY' || activeName =='BASE_TEMPORARY_FAIL'">
              {{ scope.row.name || "-" }}
            </div>
            <div style="font-weight: 600; color: #262626">
              {{ scope.row.factory || "-" }}
            </div>
            <p>
              {{ scope.row.specification || "-" }}
            </p>
            <p>

              {{ scope.row.approvalNumber || "-" }}
            </p>
            <div>商品编码：{{ scope.row.goodsCode || "-" }}</div>
            <div>SKU编码：{{ scope.row.skuCode || "-" }}</div>
            <div>ERP编码：{{ scope.row.medicineCode || "-" }}</div>
          </div>
        </div>
      </template>
      <template slot="image"
                slot-scope="scope">
        <div style="display: column; align-items: center; justify-content: left">
          <div style="
              width: 100%;
              height: 100%px;
              display: flex;
              align-items: center;
              justify-content: center;
            ">
            <el-image style="width: 80px; height: 80px;"
                      :src="scope.row.goodsSkuImage || scope.row.image || avater"
                      :preview-src-list="[scope.row.goodsSkuImage || scope.row.image || avater]"
                      lazy />
          </div>

          <div class="imageP"
               style="
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            ">
            <p>
              {{ scope.row.name }}
            </p>
            <p>排序：{{ scope.row.sortCode || "-" }}</p>
          </div>
        </div>
      </template>

      <template slot="price"
                slot-scope="scope">
        <div style="display: flex; align-items: center; justify-content: left"
             class="price-cell">
          <div class="imageP">
            <p class="price-edit-select" v-if=" activeName !='BASE_AWAIT' && activeName !='BASE_ALREADY' && activeName !='BASE_TEMPORARY_FAIL'">
              <el-select v-model="scope.row.beSynPrice"
                         placeholder=""
                         size="small"
                         @change="(val) => handleChangeForSynPrice(scope.$index, val)">
                <el-option v-for="item in priceChangeFlags"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"
                           :disabled="item.disabled">
                </el-option>
              </el-select>
            </p>
            <p>价格：￥{{ scope.row.salePrice || "-" }}</p>
            <p>市场价：￥{{ scope.row.marketPrice || "-" }}</p>
            <p>建议零售价：￥{{ scope.row.purchasePrice || "-" }}</p>
          </div>

          <el-button v-if="!scope.row.beSynPrice && activeName !='BASE_AWAIT' && activeName !='BASE_ALREADY' && activeName !='BASE_TEMPORARY_FAIL' && scope.row.activityType == 1 "
                     type="text"
                     icon="el-icon-edit-outline"
                     class="edit-btn"
                     @click="hanldeEditPrice(scope.$index)" />
        </div>
      </template>
      <template slot="inventory"
                slot-scope="scope">
        <div style="display: flex; align-items: center; justify-content: left"
             class="price-cell">
          <div class="imageP">
            <p class="price-edit-select" v-if=" activeName !='BASE_AWAIT' && activeName !='BASE_ALREADY' && activeName !='BASE_TEMPORARY_FAIL'">
              <el-select v-model="scope.row.beSynStock"
                         placeholder=""
                         size="small"
                         @change="(val) => handleChangeForSynStock(scope.$index, val)">
                <el-option v-for="item in priceChangeStockFlags"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"
                           :disabled="item.disabled">
                </el-option>
              </el-select>
            </p>
            <p>
              <!-- <el-tooltip
                class="item"
                effect="dark"
                content="总库存=ERP同步库存/手工设置商品总库存"
                placement="top"
              >
                <i class="el-icon-warning-outline"></i>
              </el-tooltip> -->
              总库存：{{ scope.row.goodsSkuInventory || "0" }}
            </p>

            <p>有效期至：{{ scope.row.expireTime || "-" }}</p>
            <p>生产日期：{{ scope.row.producedTime || "-" }}</p>
            <p style="color: green">
              ERP同步 :{{scope.row.erpSyncount || "-"}}
            </p>
          </div>

          <el-button v-if="!scope.row.beSynStock  && activeName !='BASE_AWAIT' && activeName !='BASE_ALREADY' && activeName !='BASE_TEMPORARY_FAIL'"
                     type="text"
                     icon="el-icon-edit-outline"
                     class="edit-btn"
                     @click="hanldeEditStock(scope.$index)" />
        </div>
      </template>

      <template slot="areaSchemeName"
                slot-scope="scope">
        <div style="display: flex; align-items: center; justify-content: left">
          <div class="imageP"> 
            <p v-if="scope.row.type == 0">区域：{{ scope.row.areaSchemeName || "-" }}</p>
            <p>
              客户类型：{{getShopTypeStr(scope.row.shopTypeCode)}}
            </p>
            <p>
              {{ `${scope.row.type == 0 ? "黑名单" : "白名单"}` }}：{{
                scope.row.groupSchemeName || ""
              }}
            </p>
          </div>
        </div>
      </template>

      <template slot="status"
                slot-scope="scope">
        <div style="display: flex; align-items: center; justify-content: left">
          <div class="imageP">
            <p>
              {{ getStatus(scope.row.status) }}
            </p>
            <p>
              {{ scope.row.updateBy }}
            </p>
            <p>
              {{ scope.row.updateTime }}
            </p>
          </div>
        </div>
      </template>
      <template slot="saleSite"
                slot-scope="scope">
        <div style="display: flex; align-items: center; justify-content: left">
          <div class="imageP">
            <p>采购店数：{{ scope.row.purchaseShopCount }}</p>
            <p>采购数量：{{ scope.row.purchaseGoodsCount  }}</p>
            <p>采购金额（元）：{{ scope.row.countMoney  }}</p>
          </div>
        </div>
      </template>
      <template slot="remarks"
                slot-scope="scope">
        <div class="">
          <p>活动类型：{{ showActivityName(scope.row.activityType) }}</p>
        </div>
        <div class="gift-tag" v-if="scope.row.beGift">
          <p> 已参与满赠活动</p>
        </div>
        <div class="">
          <p v-if="scope.row.affiliationArea">归属组织：{{ scope.row.affiliationArea }} </p>
          <p v-if="scope.row.affiliationClinic">归属诊所：{{ scope.row.affiliationClinic }} </p>
        </div>
      </template>
      <template slot="caozuo"
                slot-scope="scope"> 
        <div class="tabl-op-col">
          <el-link :underline="false"
                   v-if="activeName == 'MERCHANT_REMOVAL'"
                   type="primary"
                   @click="listing(scope.row)">上架</el-link>
          <el-link :underline="false"
                   v-if="activeName == 'ON_SALE'"
                   type="primary"
                   @click="delist(scope.row)">下架</el-link>
          <el-link :underline="false"
                   type="primary"
                   v-if=" activeName !='BASE_AWAIT' && activeName !='BASE_ALREADY' "
                   @click="detailHandle(scope.row)">详情</el-link>
      
                   <el-link :underline="false"
                   v-if=" activeName !='BASE_AWAIT' && activeName !='BASE_ALREADY' "
                   type="primary"
                   @click="editHandle(scope.row)">编辑</el-link> 

                   <el-link :underline="false"
                   v-if=" activeName !='BASE_AWAIT' && activeName !='BASE_ALREADY' && activeName !='BASE_TEMPORARY_FAIL'  && scope.row.activityType !='4'  "
                   type="primary"
                   @click="fullGit(scope.row)">单品送赠品</el-link>
        </div>

        <div style="display: flex;justify-content: center;">
          <el-link :underline="false"
                   type="primary"
                   v-if=" activeName !='BASE_AWAIT' && activeName !='BASE_ALREADY' && activeName !='BASE_TEMPORARY_FAIL'"
                   @click="logs(scope.row)">日志</el-link>
        </div>
      </template>
    </MyTable>
  </div>
</template>

<script>
import avater from "/public/img/default.png";
import MyTable from "@/components/myTable";
import { checkSkuStatus } from "@/utils/status";

import { synErpStock, synErpPrice } from "@/api/goods";
import { set } from "nprogress";

export default {
  components: { MyTable },
  props: {
    tableData: {
      type: Array,
      default () {
        return [];
      },
    },
    page: {
      type: Object,
      default () {
        return {};
      },
    },
    selectionList: {
      type: Array,
      default () {
        return [];
      },
    },
    activeName: {
      type: String,
      default () {
        return "";
      },
    },
    selectionOpt: {
      type: Boolean,
      default: false,
    },
    shopTypeList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      priceChangeFlags: [
        { label: "不同步ERP价格", value: false },
        { label: "同步ERP价格", value: true },
      ],
      priceChangeStockFlags: [
        { label: "不同步ERP库存", value: false },
        { label: "同步ERP库存", value: true },
      ],
      avater: avater,
      // selectionList: [],
      operation: false,
      selection: this.selectionOpt,
      index: false,
      // tableData: [],
      tableOption: [
        {
          label: "商品信息",
          prop: "information",
          slot: true,
          minWidth: "200px",
        }, // 这里表示自定义列
        {
          label: "展示名称",
          prop: "image",
          slot: true,
          minWidth: "200px",
          align: "center",
        }, // 这里表示自定义列
        // { label: '商品名称', prop: 'name' },

        { label: "价格", prop: "price", slot: true, width: "260px" },
        { label: "库存", prop: "inventory", slot: true, width: "260px" },
        {
          label: "控销规则",
          prop: "areaSchemeName",
          slot: true,
          width: "260px",
        },
        { label: "销售情况", prop: "saleSite", slot: true, width: "200px" },
        // { label: "商品状态", prop: "status", slot: true, width: "160px" },
        // { label: '批准文号', prop: 'approvalNumber', width: "260px" },
        { label: "备注", prop: "remarks", slot: true, width: "260px" },
        {
          label: "操作",
          prop: "caozuo",
          slot: true,
          width: "80",
          align: "center",
          fixed: "right",
        },
        // { label: 'ERP编码', prop: 'medicineCode' },
        // { label: '发布时间', prop: 'createTime', width: "180px" }
      ],
      // page: {
      //   total: 1,
      //   current: 1,
      //   size: 20,
      // }
    };
  },
  computed: {
    getStatus () {
      return (status) => checkSkuStatus(status);
    },
  },
  created () { },
  mounted () { },
  watch: {},
  methods: {
    fullGit(row){
      // /单品满赠
      this.$router.push('./salesControl-fullGift-index?goodsSkuId=' + row.skuId +'&goodsId=' + row.goodsId)
    },
    getShopTypeStr (v) {
      if (!v) {
        return '-'
      }
      const arr = v.split(',')
      let strArr = []
      arr.forEach((e) => {
        this.shopTypeList.forEach((l) => {
          if (e == l.typeCode) {
            strArr.push(l.typeName)
          }
        })
      })
      return strArr.join(',')
    },
    handleChangeForSynPrice (index, val) {
      // if (val) {
      const row = this.tableData[index];
      const data = {
        skuId: row.skuId,
        beSynPrice: val,
      };
      synErpPrice(data).then((res) => {
        if (res) {
          this.$message.success("操作成功");
        }else{
          const loading = this.$loading({
            lock: true,
            text: 'Loading', 
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.1)'
          })
          setTimeout(() => {
          this.getList()
          loading.close()
        }, 500);
       
        }
      }).catch(()=>{
        const loading = this.$loading({
            lock: true,
            text: 'Loading', 
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.1)'
          })
        setTimeout(() => {
          this.getList()
          loading.close()
        }, 500);
      });
      // }
    },
    handleChangeForSynStock (index, val) {
      //  if (val) {
      const row = this.tableData[index];
      const data = {
        skuId: row.skuId,
        beSynStock: val,
      };
      synErpStock(data).then((res) => {
        if (res) {
          this.$message.success("操作成功");
        }
      });
      // }
    },
    showActivityName (activityType) {
      const typeList = [
        {
          label: "一口价",
          value: 1,
        },
        {
          label: "特价",
          value: 2,
        },
        {
          label: "单品包邮",
          value: 3,
        },
        {
          label: "赠品",
          value: 4,
        },
      ];
      const findedItem = typeList.find((item) => item.value == activityType);
      return findedItem ? findedItem.label : "-";
    },
    // 切换分页
    getList () {
      this.$emit("getList", this.page);
    },
    // 勾选
    handleSelectionChange (val) {
      this.$emit("handleSelectionChange", val);
    },
    // 日志
    logs (val) {
      this.$emit("logs", val);
    },
    // 编辑
    editHandle (val) {
      this.$emit("editHandle", val);
    },
    // 详情
    detailHandle (val) {
      this.$emit("detailHandle", val);
    },
    // 下架
    delist (val) {
      this.$emit("delist", val);
    },
    // 上架
    listing (val) {
      this.$emit("listing", val);
    },
    // 加入仓库
    addStash (val) {
      this.$emit("addStash", val);
    },
    hanldeEditPrice (index) {
      this.$emit("editPrice", index);
    },
    hanldeEditStock (index) {
      this.$emit("editStock", index);
    },
  },
};
</script>
<style lang="scss" scoped>
.tableMy {
  width: 100%;
  height: 100%;

  .price-cell {
    //position: relative; //编辑按钮不要相对它，而是相对于它的父级

    .edit-btn {
      position: absolute;
      top: 0;
      right: 24px;

      ::v-deep .el-icon-edit-outline {
        color: #9a9a9a;
      }
    }
  }

  .price-edit-select {
    width: 120px;

    ::v-deep .el-select {
      .el-input--small .el-input__inner {
        background-color: transparent;
        border: 1px solid transparent;
        color: #f5222d;
        display: inline-block;
        height: 28px;
        line-height: 28px;
        padding: 0 0;
      }

      .el-input--small .el-input__icon {
        height: 28px;
        line-height: 28px;
      }

      .el-input__icon {
        width: 16px;
      }

      .el-icon-arrow-up:before {
        content: "\e78f";
        color: #f5222d;
      }
    }
  }

  .tabl-op-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * {
      margin: 3px 0;
    }
  }
}
.gift-tag {
  color: #f5222d;
}
</style>
