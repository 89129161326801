<template>
  <div class="qualiAfterSaleMain"
    v-if="btnStr(orderDetail.invoiceAfterSaleStatus) || btnStr(orderDetail.certificationStatus)">
    <div class="header">
      <div class="left_topic ">资质售后</div>
    </div>
    <div class="inLine">
      <div class="inLine afterSaleApplyView" v-if="btnStr(orderDetail.invoiceAfterSaleStatus)">
        <span class="title">发票相关</span>
        <el-button size="small" type="primary" @click="handleOpenFaPiao">{{
          btnStr(orderDetail.invoiceAfterSaleStatus) || '申请售后'
        }}</el-button>
      </div>
      <div class="inLine afterSaleApplyView" v-if="btnStr(orderDetail.certificationStatus)">
        <span class="title">随货资质</span>
        <el-button size="small" type="primary" @click="handleOpen">{{
          btnStr(orderDetail.certificationStatus) || '申请售后'
        }}</el-button>
        <!-- <el-button size="small" type="primary" @click="handleOpen">申请中</el-button>
                <el-button size="small" type="primary" @click="handleOpen">申请驳回</el-button>
                <el-button size="small" type="primary" @click="handleOpen">申请通过</el-button> -->
      </div>
    </div>
    <AfterInvoice :orderDetail="orderDetail" ref="AfterInvoice"></AfterInvoice>
    <el-dialog title="资质相关售后" :visible.sync="dialogVisible" width="848px" :before-close="handleClose">
      <div class="mainContent">
        <el-scrollbar wrap-style="overflow-x:hidden;">
          <div class="dialogTitle">企业相关资质</div>
          <div class="typeView">
            <div v-if="companyTypes && companyTypes.length > 0">
              <div v-for="(item, index) in companyTypes" :key="index" class="typeCell">
                <div class="typeCheck typeSelect" @click="didClickType(index)">
                  <span>{{ item.name }}</span>
                  <img :src="skucheck" alt="" class="checkImage">
                </div>
                <div class="uploadView">
                  <el-upload class="upload-demo" :disabled="orderDetail.certificationStatus != 'CHECKING'"
                    :accept="'.jpg,.jpeg,.pdf,.PDF,.JPG,.PNG,.png'" :action="uploadUrl" :header="header" multiple
                    :limit="3" :file-list="item.image ? item.image : []" :on-preview="preview"
                    :on-remove="(file, fileList) => removeUpload(item, file, fileList)"
                    :on-success="(response, file, fileList) => successUpload(item, response, file, fileList)">
                    <el-button size="mini" type="primary"
                      v-if="item.image.length < 3 && orderDetail.certificationStatus == 'CHECKING'">点击上传</el-button>
                    <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
                  </el-upload>
                </div>
              </div>
            </div>
            <div v-else>无</div>
          </div>
          <div class="dialogTitle" style="margin-top: 24px;">商品相关资质</div>
          <div class="goodsView">
            <div v-if="goodsTypes && goodsTypes.length > 0">
              <div v-for="(item, index) in goodsTypes" :key="index" class="goodsItem">
                <img :src="item.goodsImage || defaultImage" alt="" class="goodsImage">
                <div class="column goodsInnerView">
                  <div class="goodsName">
                    {{ item.goodsName }}
                  </div>
                  <div class="goodsAfterSaleType" v-if="item.typeDetail">
                    <el-checkbox-group size="small" v-model="item.check" disabled>
                      <el-checkbox label="1" style="display: flex;align-items: center;min-height: 35px;"
                        v-if="item.check.indexOf('1') > -1">药检报告
                        <div class="uploadView">
                          <el-upload :disabled="orderDetail.certificationStatus != 'CHECKING'" class="upload-demo"
                            :accept="'.jpg,.jpeg,.pdf,.PDF,.JPG,.PNG,.png'" :action="uploadUrl" :header="header" multiple
                            :limit="3" :file-list="item.image ? item.image : []" :on-preview="preview"
                            :on-remove="(file, fileList) => removeUpload(item, file, fileList)"
                            :on-success="(response, file, fileList) => successUpload(item, response, file, fileList)">
                            <el-button size="mini" type="primary"
                              v-if="item.image.length < 3 && orderDetail.certificationStatus == 'CHECKING'">点击上传</el-button>
                            <!-- <div slot=" tip"
                  class="el-upload__tip">只能上传jpg/png文件，且不超过500kb
            </div> -->
                          </el-upload>
                        </div>
                      </el-checkbox>
                      <el-checkbox label="2" style="display: flex;align-items: center;min-height: 35px;"
                        v-if="item.check.indexOf('2') > -1">首营资料
                        <div class="uploadView">
                          <el-upload :disabled="orderDetail.certificationStatus != 'CHECKING'" class="upload-demo"
                            :accept="'.jpg,.jpeg,.pdf,.PDF,.JPG,.PNG,.png'" :action="uploadUrl" :header="header" multiple
                            :limit="3" :file-list="item.imageExtend ? item.imageExtend : []" :on-preview="preview"
                            :on-remove="(file, fileList) => imageExtendRemoveUpload(item, file, fileList)"
                            :on-success="(response, file, fileList) => imageExtendUpload(item, response, file, fileList)">
                            <el-button size="mini" type="primary"
                              v-if="item.imageExtend.length < 3 && orderDetail.certificationStatus == 'CHECKING'">点击上传</el-button>
                            <!-- <div slot=" tip"
                  class="el-upload__tip">只能上传jpg/png文件，且不超过500kb
            </div> -->
                          </el-upload>
                        </div>
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>

                </div>
              </div>
            </div>
            <div v-else>
              <div>无</div>
            </div>
          </div>
          <div class="dialogTitle" style="margin-top: 24px;">其他</div>
          <div class="typeView">
            <div v-if="otherTypes && otherTypes.length > 0">
              <div v-for="(item, index) in  otherTypes" :key="index" class="typeCell">
                <div class="typeCheck typeSelect">
                  <span>出库单</span>
                  <img :src="skucheck" alt="" class="checkImage">
                </div>
                <div class="uploadView">
                  <el-upload :disabled="orderDetail.certificationStatus != 'CHECKING'" class="upload-demo"
                    :accept="'.jpg,.jpeg,.pdf,.PDF,.JPG,.PNG,.png'" :action="uploadUrl" :header="header" multiple
                    :limit="3" :file-list="item.image ? item.image : []" :on-preview="preview"
                    :on-remove="(file, fileList) => removeUpload(item, file, fileList)"
                    :on-success="(response, file, fileList) => successUpload(item, response, file, fileList)">
                    <el-button size="mini" type="primary"
                      v-if="item.image.length < 3 && orderDetail.certificationStatus == 'CHECKING'">点击上传</el-button>
                    <!-- <div slot=" tip"
              class="el-upload__tip">只能上传jpg/png文件，且不超过500kb
        </div> -->
                  </el-upload>
                </div>
              </div>
            </div>
            <div v-else>无</div>
            <div class="remarkView">
              <div class="remarkInfo">具体说明（有其他证件资质需求请说明）</div>
              <el-input :disabled="true" v-model="remark" :rows="3" type="textarea" placeholder="请输入" />
            </div>
          </div>
          <div class="dialogTitle" style="margin-top: 24px;">协商历史</div>
          <div class="hisView" v-for="(item, index) in orderConsultHistoryList" :key="index">
            <div class="column">
              <div class="shopName">{{ item.consultPerson || '' }}</div>
              <div class="hisInfo">{{ item.consultResult || '' }}</div>
            </div>
            <div class="time">{{ item.consultTime || '' }}</div>
          </div>
        </el-scrollbar>

      </div>
      <template slot="footer">
        <div style="display: flex;align-items: center;">
          <div style="margin-left: auto;"></div>
          <el-button @click="dialogVisible = false" size="small">返 回</el-button>
          <el-button v-if="orderDetail.certificationStatus == 'CHECKING'" @click="bohui" :loading="receiveLoading"
            size="small">驳 回</el-button>
          <el-button v-if="orderDetail.certificationStatus == 'CHECKING'" type="primary" @click="submit"
            :loading="receiveLoading" size="small">提 交</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog :visible.sync="pdfDialog" width="848px" title="详情" class="detail">
      <iframe v-if="pdfStr == 'pdf' || pdfStr == 'PDF'" :src="pdfUrl" style="width: 100%; height: 100%;"
        frameborder="0"></iframe>
      <img v-else :src="pdfUrl" frameborder="0" style="width: auto;height: 100%;object-fit: contain;"></img>
    </el-dialog>


    <el-dialog title="驳回原因" :visible.sync="bohuiDialog" class="reject">
      <el-input v-model="rejectReason"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bohuiDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="bohuiSubmit" size="small">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import AfterInvoice from './afterInvoice.vue';
import defaultImage from '/public/img/default.png'
import skucheck from '@/assets/zz/selected-icon.png'
import { getCertificationByOrderId, putCertificationByOrderId, reject } from "@/api/myQualification";
import { certificationStatusAction, certificationStatusStr } from "@/utils/certificationStatus";
import dayjs from "dayjs";
import _ from 'lodash'
const IConst_See_Icon = require("@/assets/zz/see-icon.png");
const IConst_Del_Icon = require("@/assets/zz/del-icon.png");
const IConst_Add_Icon = require("@/assets/zz/add-icon.png");
import { getToken } from "@/utils/auth";
export default {
  components: { AfterInvoice },
  props: {
    orderDetail: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      id: '',
      orderId: '',
      header: getToken(),
      uploadUrl: process.env.VUE_APP_BASE_API + '/product/goodsbase/upload',
      SeeIcon: IConst_See_Icon,
      DelIcon: IConst_Del_Icon,
      AddIcon: IConst_Add_Icon,
      defaultImage: defaultImage,
      skucheck,
      dialogVisible: false,
      receiveLoading: false,
      companyTypes: [],//店铺资质
      goodsTypes: [],//商品资质
      otherTypes: [],//其他资质
      orderConsultHistoryList: [],//协商历史
      typesArr: [{ name: '企业首营资料', value: '1', select: false }, { name: '购销合同', value: '2', select: false }],
      checkboxGroup1: [''],
      pdfDialog: false,
      pdfUrl: '',
      pdfStr: '',

      bohuiDialog: false,
      rejectReason: '',

      remark: '',
      form: {
        fileList: []
      },
    }
  },
  created () {
    const { id, orderId } = this.$route.query
    this.id = id
    this.orderId = id
  },
  methods: {
    // 驳回
    bohui () {
      this.bohuiDialog = true
    },
    // 驳回掉接口
    async bohuiSubmit () {
      if (!this.rejectReason) {
        this.$message.warning('请先输入驳回原因')
        return
      }
      let params = {
        rejectReason: this.rejectReason,
        afterSaleId: this.id,
        type: 0,
      }
      const res = await reject(params)
      if (res) {
        this.$message.success('驳回成功')
        this.bohuiDialog = false
        this.dialogVisible = false
        this.$parent.getData()
      }
    },
    //获取按钮文字
    btnStr (status) {
      // console.log(certificationStatusStr[status], 'certificationStatusStr[status]')
      return certificationStatusStr[status] || ''
    },
    // 点击已有的文件
    preview (file) {
      console.log(file)
      this.pdfUrl = file.url;
      this.pdfStr = (file.url).substring(file.url.length - 3)
      // console.log(this.pdfUrl)
      // console.log(this.pdfStr)
      this.pdfDialog = true;
    },
    // 上传成功
    successUpload (item, res, file, fileList) {
      item.image.push(
        {
          name: file.response.data.fileName,
          url: file.response.data.url,
        }
      )
    },
    imageExtendUpload (item, res, file, fileList) {
      item.imageExtend.push(
        {
          name: file.response.data.fileName,
          url: file.response.data.url,
        }
      )
    },
    imageExtendRemoveUpload (item, file, fileList) {
      item.imageExtend.forEach((el, index) => {
        if (el.uid == file.uid) {
          item.image.splice(index, 1)
        }
      })
    },
    // 移除文件
    removeUpload (item, file, fileList) {
      if (this.orderDetail.certificationStatus != 'CHECKING') {
        this.$message.warning('审核中不可删除')
        return
      }
      item.image.forEach((el, index) => {
        if (el.uid == file.uid) {
          item.image.splice(index, 1)
        }
      })
    },
    // 获取详情
    async getDetail () {
      for (let index = -3; index < 0; index++) {
        const last = dayjs().add(index, "years").format('YYYY')
        this.typesArr.push({ name: last + '年度报告', value: index + 6, select: false })
      }
      let params = { orderId: this.orderId }
      const r = await getCertificationByOrderId(params)
      if (r) {
        this.id = r.id
        this.orderId = r.orderId
        const { companyTypes, goodsTypes, otherTypes, orderConsultHistoryList, remark } = r
        if (companyTypes && companyTypes.length) {
          let arr = _.cloneDeep(companyTypes)
          arr.forEach(e => {
            this.typesArr.forEach((l) => {
              if (e.typeDetail == l.value) {
                e['name'] = l.name
              }
            })
            let imageArr = e.image ? _.cloneDeep(e.image).split(',') : []
            let newArr = []
            if (imageArr.length > 0) {
              imageArr.forEach(el => {
                let obj = {
                  name: el.substring(57),
                  url: el
                }
                newArr.push(obj)
              })
            }
            e.image = newArr
          });
          this.companyTypes = arr
        }
        if (goodsTypes && goodsTypes.length) {
          let arr = _.cloneDeep(goodsTypes)
          arr.forEach((e) => {
            e['check'] = e.typeDetail ? (e.typeDetail).split(',') : []
            // e['images'] = e.image ? (e.image).split(',') : []
            let imageArr = e.image ? _.cloneDeep(e.image).split(',') : []
            let newArr = []
            if (imageArr.length > 0) {
              imageArr.forEach(el => {
                let obj = {
                  name: el.substring(57),
                  url: el
                }
                newArr.push(obj)
              })
            }
            e.image = newArr

            let imageExtendArr = e.imageExtend ? _.cloneDeep(e.imageExtend).split(',') : []
            let newArrimageExtend = []
            if (imageExtendArr.length > 0) {
              imageExtendArr.forEach(el => {
                let obj = {
                  name: el.substring(57),
                  url: el
                }
                newArrimageExtend.push(obj)
              })
            }
            e.imageExtend = newArrimageExtend
          })
          this.goodsTypes = arr
        }
        if (otherTypes && otherTypes.length > 0) {
          otherTypes.forEach(item => {
            let imageArr = item.image ? _.cloneDeep(item.image).split(',') : []
            let newArr = []
            if (imageArr.length > 0) {
              imageArr.forEach(el => {
                let obj = {
                  name: el.substring(57),
                  url: el
                }
                newArr.push(obj)
              })
            }
            item.image = newArr
          })
        }
        if (otherTypes && otherTypes.length) {
          this.otherTypes = otherTypes
        }
        if (orderConsultHistoryList && orderConsultHistoryList.length) {
          this.orderConsultHistoryList = orderConsultHistoryList
        }
        this.remark = remark || '无'
      }

    },
    // 提交
    async submit () {
      let companyarr = _.cloneDeep(this.companyTypes)
      let goodsarr = _.cloneDeep(this.goodsTypes)
      let otherarr = _.cloneDeep(this.otherTypes)
      if (companyarr && companyarr.length > 0) {
        companyarr.map(item => {
          if (item.image && item.image.length > 0) {
            let arr = []
            item.image.forEach(el => {
              arr.push(el.url)
            })
            item.image = arr.join(',')
          } else {
            item.image = ''
          }
        })
      }
      if (goodsarr && goodsarr.length > 0) {
        goodsarr.forEach(item => {
          if (item.image && item.image.length > 0) {
            let arr = []
            item.image.forEach(el => {
              arr.push(el.url)
            })
            item.image = arr.join(',')
          } else {
            item.image = ''
          }

          if (item.imageExtend && item.imageExtend.length > 0) {
            let arr = []
            item.imageExtend.forEach(el => {
              arr.push(el.url)
            })
            item.imageExtend = arr.join(',')
          } else {
            item.imageExtend = ''
          }
        })
      }
      if (otherarr && otherarr.length > 0) {
        otherarr.forEach(item => {
          if (item.image && item.image.length > 0) {
            let arr = []
            item.image.forEach(el => {
              arr.push(el.url)
            })
            item.image = arr.join(',')
          } else {
            item.image = ''
          }
        })
      }
      let params = {
        id: this.id,
        orderId: this.orderId,
        remark: this.remark,
        companyTypes: companyarr,
        goodsTypes: goodsarr,
        otherTypes: otherarr
      }
      this.receiveLoading = true
      const res = await putCertificationByOrderId(params)
      if (res) {
        this.$parent.getData()
        this.$message.success('提交成功')
        this.dialogVisible = false
        this.receiveLoading = false
      } else {
        this.$message.error('提交失败')
      }
    },
    didClickType (index) {
      // 选中
      this.companyTypes[index].select = !this.companyTypes[index].select
    },

    async handleOpen () {
      await this.getDetail()
      this.dialogVisible = true
    },

    handleClose () {
      this.dialogVisible = false
    },
    handleChange (file, fileList) {
      this.form.fileList = fileList
    },
    uploadSubmit (e) {
      // debugger
    },
    handlePreview (refName) {
      // if (this.$refs[refName]) {
      //     if (this.$refs[refName] instanceof Array) {
      //         this.$refs[refName][0].showViewer = true;
      //     } else {
      //         this.$refs[refName].showViewer = true;
      //     }
      // }
    },
    handleDeleteImage (index) {
      // this.replyImageList.splice(index, 1);
    },
    getCheckList (e) {
      if (!e) {
        return ''
      }
      console.log(e.split(','));

      return e.split(',')
    },
    // 发票相关
    handleOpenFaPiao () {
      // console.log('发票')
      this.$refs.AfterInvoice.init()
    }
  }
}
</script>
<style lang="scss" scoped>
.detail {
  ::v-deep .el-dialog__body {
    display: flex;
    overflow: auto;
    justify-content: center;
  }
}

::v-deep .upload-demo {
  display: flex;
  // width: 150px;
}

::v-deep .el-checkbox__input.is-disabled+span.el-checkbox__label {
  display: flex;
  align-items: center;
}

::v-deep .el-upload-list__item:hover .el-icon--close {
  display: none !important;
}

::v-deep .el-upload-list__item .el-icon--upload-success {
  display: none !important;
}


::v-deep .el-upload-list__item:first-child {
  margin-top: 0;
}

::v-deep .el-dialog__body {
  height: 600px;
  overflow: auto;
}

.reject {
  ::v-deep .el-dialog__body {
    height: auto;
    overflow: auto;
  }
}

.qualiAfterSaleMain {
  background-color: #fff;
  padding: 32px;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 24px;

  .header {
    .left_topic {
      font-size: 16px;
      line-height: 16px;
      padding-left: 16px;
      position: relative;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #f5222d;

      &:before {
        content: '';
        display: inline-block;
        width: 3px;
        height: 16px;
        background-color: #f5222d;
        position: absolute;
        top: 0;
        left: 0;
      }
    }


  }

  .inLine {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .afterSaleApplyView {
      width: 400px;
    }

    .title {
      font-size: 14px;
      color: #595959;
      line-height: 30px;
      margin: 0px 15px;
    }
  }

  .mainContent {
    width: 800px;
  }

  .dialogTitle {
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
    position: relative;
    text-align: left;
    color: #595959;
    margin-bottom: 12px;

    &:before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 16px;
      background-color: #f5222d;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .typeView {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .typeCell {
      display: flex;
      align-items: center;
      margin: 5px 0px;

      .typeCheck {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        color: #262626;
        font-size: 12px;
        margin-right: 5px;
        position: relative;
        min-width: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;

        .checkImage {
          position: absolute;
          bottom: 0;
          right: 0;
          height: 15px;
          width: 15px;
        }
      }

      .typeSelect {
        border: 1px solid #f5222d;
      }


    }
  }

  .uploadView {
    display: flex;
    align-items: center;
    margin-left: 10px;

    .uploadImg {
      width: 86px;
      height: 86px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      position: relative;
      overflow: hidden;
      margin-left: 8px;

      .icon {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .preview {
        position: absolute;
        visibility: hidden;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background: #000000;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn-icon {
          width: 16px;
          height: 16px;
          cursor: pointer;

          &+.btn-icon {
            margin-left: 8px;
          }
        }
      }

      &:hover {
        .preview {
          visibility: visible;
        }
      }

      &.add-pic {
        border: 1px dashed #d9d9d9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .image-wrap {
          border: 1px dashed #d9d9d9;
          width: 20px;
          height: 20px;

          &>* {
            width: 100%;
            height: 100%;
          }
        }

        .text {
          font-size: 14px;
          font-weight: 400;
          color: #595959;
          margin-top: 8px;
        }
      }

    }
  }

  .goodsView {
    display: flex;
    flex-wrap: wrap;

    .goodsItem {
      width: 100%;
      min-height: 80px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .goodsImage {
        height: 60px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        overflow: hidden;
        padding: 4px;
      }

      .goodsInnerView {
        width: 200px;
      }

      .column {
        display: flex;
        flex-direction: column;
        margin-left: 12px;

        .goodsName {
          font-size: 14px;
          color: #262626;
        }

        .goodsAfterSaleType {
          display: flex;
          align-items: center;
          margin-top: 5px;
        }
      }

    }

  }

  .hisView {
    display: flex;
    border-bottom: 1px solid #d9d9d9;
    padding: 12px 0px;
    justify-content: space-between;

    .shopName {
      font-size: 14px;
      color: #262626;
      font-weight: 600;
    }

    .hisInfo {
      font-size: 12px;
      color: #595959;
      margin-top: 12px;
    }
  }

  .remarkView {
    padding: 5px 0px;

    .remarkInfo {
      line-height: 16px;
      text-align: left;
      color: #595959;
      margin-bottom: 12px;
    }
  }
}
</style>
