// 近N月 - new Date()
export function getRecentMonth_Date (n) {
    let result = '';
    const datenow = new Date();
    const dateend =
      datenow.getFullYear().toString() +
      '-' +
      (datenow.getMonth() + 1).toString() +
      '-' +
      datenow.getDate().toString();
        datenow.setMonth(datenow.getMonth() - n);
        datenow.setDate(datenow.getDate() + 1); 

        let dyear = datenow.getFullYear();
        let dmonth = datenow.getMonth() + 1;
        dmonth = dmonth < 10 ? 0 + dmonth : dmonth;
        let dday = datenow.getDate();
        const datestart =
          dyear.toString() + '-' + dmonth.toString() + '-' + dday.toString();
        result += datestart + ',';
       result += dateend;
        console.log(datestart);
        return result;
  }

  // 近N天 - new Date()
export function getRecentDay_Date (n) {
    var dd = new Date();
    dd.setDate(dd.getDate() - n); //获取n天前的日期
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1; //获取当前月份的日期
    var d = dd.getDate();
    let day = y + '-' + m + '-' + d;
    console.log(day);
    return day;
  }
  