import request from '@/utils/request'
import { Message } from 'element-ui'
// 结算对账
export function closePayPage(data) {
  return request({
    url: `/statistic/account/checking/closePay/page`,
    method: 'get',
    params:data
  })
}

// 结算对账-申请开票
export function closePayOpen(data) {
  return request({
    url: `/statistic/account/checking/closePay/open`,
    method: 'get',
    params:data
  })
}

// 春播技术费开票
export function invoice(data) {
  return request({
    url: `/order/invoice/technology/open/invoice`,
    method: 'get',
    params:data
  })
}
