import request from '@/utils/request'

// 申请售后获取参数
export function aftersaleParam(params) {
  return request({
    url: '/order/aftersale/param',
    method: 'get',
    params: params
  })
}

// 获取枚举
export function aftersaleEnum(params) {
  return request({
    url: '/order/aftersale/enum',
    method: 'get',
    params: params
  })
}

// 提交申请
export function aftersaleSubmit(data) {
  return request({
    url: '/order/aftersale/submit',
    method: 'post',
    data
  })
}
// 卖家取消售卖向买家发送短信
export function afterSaleBuyerSms(params) {
  return request({
    url: '/order/aftersale/afterSaleBuyerSms',
    method: 'post',
    params: params
  })
}