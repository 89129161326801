<template>
  <div class="comContent">
    <!-- <div class="left_topic mb20" style="margin-top: 10px">控销策略</div> -->

    <div class="content">
      <div class="structure">
        <div class="searchArea"
             style="padding:0px;justify-content: space-between;">
          <span class="tit">组织架构名称</span>
          <el-input class="searchIpt"
                    placeholder="请输入组织架构名称"
                    suffix-icon="el-icon-search"
                    size="mini"
                    style="visibility: auto"
                    v-model="treeVal1">
          </el-input>
        </div>
        <div class="treeBoxParent">
          <div class="treeBox">
            <div class="title">组织架构</div>
            <el-tree node-key="id"
                     ref="tree1"
                     :data="treedata"
                     :props="defaultProps"
                     :highlight-current="true"
                     @node-click="gettreeData"
                     :expand-on-click-node="false"
                     :filter-node-method="filterNode"></el-tree>
          </div>
          <div class="footer">
            <el-button type="primary"
                       size="small"
                       :disabled="!treedata|| treedata.length == 0"
                       @click="showOutDialog">区域设置</el-button>
          </div>
          <!-- <div class="treeBox">
            <div class="title">组织架构</div>
            <el-tree :data="treedata"
                     :props="defaultProps"
                     node-key="id"
                     :highlight-current="true"
                     @node-click="gettreeData"
                     :expand-on-click-node="false"></el-tree>
          </div> -->
        </div>
      </div>
      <div class="resultArea">
        <div class="resultHearder">{{ resultHearderTit }}</div>
        <div class="resultContent">
          <div class="contentItemMini item">
            <div class="title">组织关联商户</div>
            <div class="searchArea architecture">
              <!-- <span class="tit">经销架构</span> -->
              <div  style="display:flex;align-items:center;justify-content: space-between;">
                <el-select v-model="proviceValue"
                           filterable
                           clearable
                           size="mini"
                           style="padding:5px 0 0 0"
                           placeholder="请选择省级供应商">
                  <el-option v-for="item in proviceOptions"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
                <div>
                  <el-button type="primary"
                  style="margin-left:5px;height: fit-content;visibility: hidden;"
                  size="mini">添加</el-button>
                  <el-checkbox v-model="dataUnityFlag" :true-label="1" :false-label="0"  style="margin-left:5px;" @change="checkDataOnlyOne" v-if="checkNodeDetail && checkNodeDetail.weight  && authorizationShow">
                    数据统一查看
                  </el-checkbox>  
                </div>
              </div>
              <div>
              </div>
              <div style="display:flex;align-items:center;justify-content: space-between;">
                <el-select v-model="companyValue"
                           clearable
                           filterable
                           size="mini"
                           style="padding:5px 0"
                           placeholder="请选择公司">
                  <el-option v-for="item in options"
                             :key="item.shopId"
                             :label="item.shopName"
                             :value="item.shopId">
                  </el-option>
                </el-select>
                <el-button type="primary"
                           :disabled="checkNodeDetail == null || !proviceValue || !companyValue"
                           style="margin-left:5px;height: fit-content;"
                           size="mini"
                           @click="dfcAdd">添加</el-button>
              </div>
            </div>
            <div class="list">
              <div class="listContent">
                <ul>
                  <li v-for="(item, index) in teamList"
                      :key="index">
                    <div class="item">
                      <div class="nameBox">
                        <div class="name"
                             style="color:#aaaaaa;">{{ item.type }}</div>
                        <!-- <el-button type="primary"
                                   style="margin-left:5px;height: fit-content;justify-content: space-between;"
                                   size="mini"
                                   @click="accredit(item)">{{ item.beAuthorization == 1 ? '已授权' : '未授权' }}</el-button> -->
                      </div>
                      <div class="desc">
                        <div class="name"
                             style="color:#333">{{ item.shopName }}</div>
                        <el-button type="primary"
                                   style="margin-left:5px;height: fit-content;justify-content: space-between;"
                                   size="mini"
                                   @click="dfcDel(item)">移除</el-button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="contentItem item">
            <el-transfer filterable
                         style="display:flex"
                         ref="transfer"
                         :filter-method="filterMethod"
                         :titles="['地区公司未分配市场', '地区公司已分配市场']"
                         filter-placeholder="请输入诊所名称"
                         v-model="data"
                         :data="allData">
              <el-divider></el-divider>
              <div slot-scope="{ option }"
                   class="spanList">{{ option.address || ''}}
                <div class="spanTitle">{{ option.shopName || '' }}</div>
              </div>
              <span slot="left-footer">待分配（{{allData.length - data.length}}）</span>
              <div slot="right-footer"
                   style="display: flex;align-items: center;justify-content: space-between;flex: 1;">
                <span>已分配（{{data.length}}）</span>
                <el-button class="transfer-footer"
                           type="primary"
                           size="small"
                           @click="submit">确认分配</el-button>
              </div>
            </el-transfer>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="区域设置"
               :visible.sync="showEditDialog"
               width="60%"
               :close-on-click-modal="false"
               :before-close="outDialogClose">
      <el-dialog width="30%"
                 :title="insideTit"
                 :visible.sync="showInsideDialog"
                 append-to-body
                 :close-on-click-modal="false"
                 :before-close="handleClose">
        <div class="editArea">
          <el-form :model="dialogForm"
                   status-icon
                   :rules="dialogRules"
                   ref="dialogForm"
                   label-width="100px"
                   class="demo-ruleForm">
            <el-form-item label="组织架构"
                          prop="name">
              <el-input v-model="dialogForm.name"
                        maxlength="10"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary"
                         size="small"
                         @click="addNode('dialogForm')">提交</el-button>
              <el-button type="primary"
                         size="small"
                         @click="resetForm('dialogForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
      <div class="dialogContent">
        <div class="cLeft item">
          <div class="cHeader">
            <div class="tit">组织架构</div>
            <div class="ipt">
              <el-input size="mini"
                        placeholder="请您输入组织名称"
                        suffix-icon="el-icon-search"
                        style="visibility: auto"
                        v-model="zhTreeVal" />
            </div>
            <!-- <el-button type="primary"
                       size="mini"
                       @click="editNode(1)">添加</el-button>
            <el-button type="primary"
                       size="mini"
                       @click="editNode(2)">编辑</el-button>
            <el-button type="primary"
                       size="mini"
                       @click="editNode(3)">删除</el-button> -->
          </div>
          <div class="dialogtree">
            <el-tree node-key="id"
                     ref="zhtree"
                     :data="treedata"
                     :props="defaultProps"
                     :highlight-current="true"
                     @node-click="getNodeKey"
                     :expand-on-click-node="false"
                     :filter-node-method="filterNode"></el-tree>
          </div>
        </div>
        <div class="cRight item">
          <div class="cHeader">
            <div class="tit">销售地域</div>
            <el-input size="mini"
                      placeholder="请输入地域名称"
                      suffix-icon="el-icon-search"
                      style="visibility: auto"
                      v-model="cityVal" />
          </div>
          <div class="dialogtree">
            <el-tree ref="cityTree"
                     show-checkbox
                     node-key="id"
                     :default-expanded-keys="expandedKeys"
                     :default-checked-keys="checkedKeys"
                     :data="regionTreedata"
                     :props="defaultProps"
                     :filter-node-method="filterNode"></el-tree>
          </div>
        </div>
      </div>
      <div class="outDialogBtn">
        <el-button size="mini"
                   @click="outDialogClose">取消</el-button>
        <el-button type="primary"
                   size="mini"
                   @click="teamOrgAreaRelationsBatchFun">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  dfcTree,
  orgTree,
  dfcQuery,
  dfcRel,
  dfcAdd,
  dfcDel,
  alreadyAllocation,
  waitAllocation,
  enterAllocation,
  addOrg,
  editOrg,
  deleteOrg,
  orgArea,
  dfcBatch,
  authorization,
  dataUnityView,
  dfcDataUnity
} from "@/api/businessPlatform/relationshipMaintenance";
// /organization/data/unity/view
const debounce = (function () {
  let timer = 0;
  return function (func, delay) {
    clearTimeout(timer);
    timer = setTimeout(func, delay);
  };
})();
export default {
  data () {
    return {
      dataReadOnlyOne:0, //数据统一查看
      weightArray: [], // 省级组织架构数组
      treeVal1: '',
      // 穿梭框的数据
      data: [],
      alreadyData: [],
      allData: [],
      unallocatedData: [],
      filterMethod (query, item) {
        try {
          return item.shopName.indexOf(query) > -1;
        } catch (error) {
          console.log('shopName信息有误')
        }
      },
    
      // 树的数据
      treedata: [],
      regionTreedata: [], // 行政区树的数据
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id'
      },
      proviceValue: '', // 省级下拉框的值
      proviceOptions: [ // 省办
        {
          value: '01',
          label: '省级九和供应商'
        },
        {
          value: '02',
          label: '地级九和供应商'
        },
        {
          value: '04',
          label: '推广服务商'
        },
        {
          value: '05',
          label: '合作医药公司'
        }],
      options: [  // 下拉列表的数据
      ],
      companyValue: '', // 公司下拉选项的数据
      resultHearderTit: "",
      organizationData: [],
      teamList: [], //团队人员列表
      teamTempList: [],
      buyerList: [], //分配买家列表
      buyerTempList: [],
      assignedBuyerList: [], //已分配买家列表
      assignedBuyerTempList: [], //已分配买家列表
      checkNodeDetail: null, // 组织架构的选中节点
      checkNodeDetailXingZheng: null, //  选中的行政区节点
      showEditDialog: false, // 组织架构外层弹框
      showInsideDialog: false, // 编辑节点弹框
      insideTit: "", //内层弹框标题
      dialogForm: {
        //添加编辑组织名字
        name: "",
        id: null,
      },
      dialogRules: {
        //添加编辑组织架构校验
        name: [
          { required: true, message: "请输入组织架构名称", trigger: "blur" },
        ],
      },
      zhTreeVal: "",
      checkedKeys: [],
      expandedKeys: [],
      cityVal: "",
      dataUnityFlag:0, //数据统一查看
      authorizationShow:false, //授权开关 是否授权（是否是主省办）
    };
  },
  watch: {
    treeVal1 (val) {
      debounce(() => {
        this.$refs.tree1.filter(val);
      }, 200);
    },
    zhTreeVal (val) {
      debounce(() => {
        this.$refs.zhtree.filter(val);
      }, 200);
    },
    cityVal (val) {
      debounce(() => {
        this.$refs.cityTree.filter(val);
      }, 500);
    },
    teamName (val) {
      debounce(() => {
        this.filterList(val, 1);
      }, 100);
    },
    buyerVal1 (val) {
      debounce(() => {
        this.filterList(val, 2);
      }, 100);
    },
    buyerVal2 (val) {
      debounce(() => {
        this.filterList(val, 3);
      }, 100);
    },
  },
  created () {
    this.pageId = this.$route.query.id;
    this.cityData = JSON.parse(localStorage.getItem("cityData"));
    this.getTeamOrgsProductLine(); 
    this.dfcQuery() 
  
  },
  methods: {
    // 数据统一查看
    checkDataOnlyOne(e){ 
      console.log(e)
      // 数据统一查看 ，请求后端接口 dataUnityFlag 数据是否统一查看：0:独立，1:统一
      dataUnityView({orgCode:this.checkNodeDetail.weight,dataUnityFlag:e}).then(res=>{
        // if(res.code == 0){
        //   this.$message.success('操作成功')
        // }else{
        //   this.$message.error(res.msg)
        // }
      }) 
    }, 
    // 数据统一查看
    getDfcDataUnity(){
      dfcDataUnity({orgCode:this.checkNodeDetail.weight}).then(res=>{ 
        if(res){
          this.authorizationShow =  res.beAuthorization; //是否授权（是否是主省办）
          this.dataUnityFlag = res.dataUnityFlag;
        }
      }) 
    },

    // 授权开关
    accredit (item) {
      let params = {
        beAuthorization: item.beAuthorization == 1 ? 0 : 1,
        type: item.type,
        shopId: item.shopId
      }
      authorization(params).then(res => {
        if (res.code == 0) {
          if (params.beAuthorization == 0) {
            this.$message.success('已取消授权')
          } else {
            this.$message.success('授权成功')
          }
        } else {
          this.$message.error('查询错误')
        }
      })
    },
    // 重置
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    // 点击组织架构树node节点
    getNodeKey (e) {
      this.checkNodeDetail = e;
      // console.log(e)
      let data = {
        orgCode: e.weight
      };
      this.getTeamOrgAreaRelationsOrgArea(data);
    },
    // 根据组织id 获取城市列表
    getTeamOrgAreaRelationsOrgArea (data) {
      orgArea(data).then((res) => {
        if (res) {
          let arr = this.fittlerCityId(res);
          this.$nextTick(() => {
            this.expandedKeys = arr;
            // this.pollingKey(arr, 100);
            this.$refs.cityTree.setCheckedKeys(arr);
          });
        }
      });
    },
    // 过滤选中城市id数组
    fittlerCityId (data) {
      let arr = [];
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          arr.push(data[i].areaCode);
        }
      }
      return arr;
    },
    // 获取选中节点需要的关联参数
    getCityCheckedNodes () {
      let c_list = this.$refs.cityTree.getCheckedNodes();
      let areaVoList = [];
      if (c_list && c_list.length > 0) {
        for (let i = 0; i < c_list.length; i++) {
          areaVoList.push({
            areaCode: c_list[i].id,
            areaName: c_list[i].name,
          });
        }
      }
      return areaVoList;
    },
    handleClose () {
      // this.$refs["dialogForm"].resetFields();
      this.$data.dialogForm = this.$options.data().dialogForm;
      this.showInsideDialog = false;
    },
    editNode (state) {
      this.formStatus = state;
      if (this.checkNodeDetail) {
        this.dialogForm = JSON.parse(JSON.stringify(this.checkNodeDetail));
      }
      if (state === 1) {
        //添加
        if (!this.checkNodeDetail || this.checkNodeDetail.parentId != '-1') {
          this.$message.warning('请先选择省级组织')
          return
        }
        this.insideTit = "新增";
        this.showInsideDialog = true;
        this.dialogForm.name = "";
      } else if (state === 2) {
        if (!this.checkNodeDetail || !this.checkNodeDetail.id || this.checkNodeDetail.id === null) {
          this.failedMessage();
          return false;
        }
        //编辑
        this.insideTit = "编辑";
        this.showInsideDialog = true;
      } else {
        //删除
        if (!this.checkNodeDetail || !this.checkNodeDetail.id || this.checkNodeDetail.id === null) {
          this.failedMessage();
          return false;
        }
        let data =
          this.checkNodeDetail.weight

        this.openMessageBox(data, 2);
      }
    },
    failedMessage () {
      this.$message({
        message: "请先选择组织架构",
        type: "warning",
      });
    },
    // 确认弹框
    openMessageBox (data, state) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delTeamOrgsFun(data);
        })
        .catch(() => { });
    },
    // 添加组织
    addTeamOrgsFun (data) {
      addOrg(data).then((res) => {
        this.openMessage(1);
      });
    },
    // 编辑组织
    editTeamOrgsFun (data) {
      editOrg(data).then((res) => {
        this.checkNodeDetail = ''
        this.dialogForm = {
          //添加编辑组织名字
          name: "",
          id: null,
        },
          this.openMessage(2);
      });
    },
    // 删除组织
    delTeamOrgsFun (data) {
      deleteOrg(data).then((res) => {
        this.checkNodeDetail = ''
        this.dialogForm = {
          //添加编辑组织名字
          name: "",
          id: null,
        },
          this.openMessage(3);
      });
    },
    // 消息弹框
    openMessage (state) {
      let msg = "";
      msg = +state === 1 ? "添加成功" : +state === 2 ? "修改成功" : "删除成功";
      this.$message({
        message: msg,
        type: "success",
      });
      this.getTeamOrgsProductLine();
    },
    addNode (formName) {
      let data = {
        orgCode: this.dialogForm.weight ? this.dialogForm.weight : '',
        orgName: this.dialogForm.name,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (+this.formStatus === 1) {
            this.addTeamOrgsFun(data);
          } else if (+this.formStatus === 2) {
            this.editTeamOrgsFun(data);
          }
          this.showInsideDialog = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 组织地区关联确认
    teamOrgAreaRelationsBatchFun () {
      let list = this.getCityCheckedNodes();
      console.log(list)
      console.log(this.checkNodeDetail)
      if (list.length == 0 || !this.checkNodeDetail) {
        this.$message.warning('请先选择组织架构和销售地域')
        return
      }
      let data = {
        orgCode: this.checkNodeDetail.weight,
        areaVoList: list,
      };
      dfcBatch(data).then((res) => {
        this.$message({
          message: "关联成功",
          type: "success",
        });
        this.outDialogClose();
      });
    },
    outDialogClose () {
      this.$nextTick(() => {
        this.$data.checkNodeDetail = this.$options.data().checkNodeDetail;
        this.$refs.zhtree.setCurrentKey(null);
        this.$refs.cityTree.setCheckedKeys([]);
        this.expandedKeys = null;
        this.cityData = JSON.parse(JSON.stringify(this.cityData));
      });
      this.showEditDialog = false;
    },
    // 区域设置
    showOutDialog () {
      this.regionTree()
    },
    // 查询组织关联商户
    dfcRel (orgCode) {
      let params = {
        orgCode: orgCode
      }
      dfcRel(params).then((res) => {
        if (res.code == 0) {
          this.teamList = res.data;
          this.getDfcDataUnity(); //数据统一查看
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    // 查询市区下拉列表
    dfcQuery () {
      dfcQuery().then((res) => {
        if (res.code == 0) {
          this.options = res.data;
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    // 确认分配
    submit () {
      if (!this.checkNodeDetail) {
        this.$message.warning('请先选择组织架构')
        return
      } else {
        let params = {
          shopIds: this.data,
          orgCode: this.checkNodeDetail.weight,
        }
        enterAllocation(params).then(res => {
          if (res.code == 0) {
            this.$message.success('分配成功')
            this.waitAllocation(this.checkNodeDetail)
            this.alreadyAllocation(this.checkNodeDetail)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    // 添加组织关联商户
    dfcAdd () {
      let data = {
        orgCode: this.checkNodeDetail.weight,
        shopId: this.companyValue,
        type: this.proviceValue
      }
      dfcAdd(data).then((res) => {
        if (res.code == 0) {
          this.$message.success('添加成功')
          this.companyValue = ''
          this.proviceValue = ''
          this.dfcRel(this.checkNodeDetail.weight)
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    // 移除商户
    dfcDel (item) {
      dfcDel(item.deptId).then((res) => {
        if (res.code == 0) {
          this.$message.success('移除成功')
          this.dfcRel(this.checkNodeDetail.weight)
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    //树过滤不查找下级
    filterNode (value, data) {
      if (!value) return true;
      return data.name
        ? data.name.indexOf(value) !== -1
        : data.rname.indexOf(value) !== -1;
    },
    // 普通列表过滤
    filterList (val, type) {
      if (+type === 1) {
        this.teamList = this.teamTempList.filter((item) => {
          return item.name.indexOf(val) !== -1;
        });
      } else if (+type === 2) {
        this.buyerList = this.buyerTempList.filter((item) => {
          return item.shopName.indexOf(val) !== -1;
        });
      } else if (+type === 3) {
        this.assignedBuyerList = this.assignedBuyerTempList.filter((item) => {
          return item.shopName.indexOf(val) !== -1;
        });
      }
    },
    // 获取行政区树
    regionTree () {
      orgTree(this.weightArray).then((res) => {
        this.regionTreedata = res;
        this.showEditDialog = true;
      });
    },
    // 获取组织架构
    getTeamOrgsProductLine () {
      dfcTree({ type: 2 }).then((res) => {
        if (res.code == 0) {
          this.treedata = res.data;
          if (res.data && res.data.length > 0) {
            this.weightArray = res.data.map(item => {
              return item.weight
            })
          }
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    // 点击组织架构树node节点
    gettreeData (e) {
      this.$refs.transfer.clearQuery('left')
      this.$refs.transfer.clearQuery('right')
      this.companyValue = ''
      this.proviceValue = ''
      this.checkNodeDetail = e;
      this.dfcRel(e.weight);
      this.alreadyAllocation(e)
      this.waitAllocation(e)
    },
    // 点击外层的组织架构树
    // getoutCheckKey (e) {
    //   this.checkNodeDetailXingZheng = e
    //   this.waitAllocation(e)
    // },
    // 未分配买家列表
    waitAllocation (data) {
      let params = {
        areaId: data.areaId,
        name: ''
      }
      waitAllocation(params).then((res) => {
        this.unallocatedData = [];
        if (res.code && res.code != 0) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          this.unallocatedData = [];
        } else {
          if (res.data && res.data.length > 0) {
            res.data.forEach(item => {
              let obj = {
                address: item.address,
                shopName: item.shopName,
                key: item.shopId
              }
              this.unallocatedData.push(obj)
            })
            this.allData = this.unallocatedData.concat(this.alreadyData)
          } else {
            this.allData = this.unallocatedData.concat(this.alreadyData)
          }
        }
      });
    },
    // 已分配买家列表
    alreadyAllocation (data) {
      let params = {
        orgCode: data.weight
      }
      alreadyAllocation(params).then((res) => {
        this.alreadyData = []
        this.data = [];
        if (res.code && res.code != 0) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          this.data = [];
        } else {
          if (res.data && res.data.length > 0) {
            res.data.forEach(item => {
              let obj = {
                address: item.address,
                shopName: item.shopName,
                key: item.shopId
              }
              this.data.push(item.shopId)
              this.alreadyData.push(obj)
            })
            this.allData = this.alreadyData.concat(this.unallocatedData)
          } else {
            this.allData = this.alreadyData.concat(this.unallocatedData)
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.comContent {
  padding: 0px;
  background: none;
}
.content {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  .structure {
    border-radius: 4px;
    padding: 8px 10px;
    width: 260px;
    background: #ffffff;
    .footer {
      border: 1px solid #dbdbdb;
      border-top: none;
    }
    .searchArea {
      border-bottom: none;
    }
    .treeBoxParent {
      // display: flex;
      // justify-content: space-between;
      .treeBox {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        // padding-top: 10px;
        height: calc(100vh - 245px);
        overflow-y: auto;
        scrollbar-width: none; /* Firefox隐藏滚动条 */
        -ms-overflow-style: none; /* Internet Explorer 10+隐藏滚动条 */
        border: 1px solid #dbdbdb;
        // border-top: none;
        margin-top: 4px;
        ::-webkit-scrollbar {
          width: 0; /* Safari,Chrome 隐藏滚动条 */
          height: 0; /* Safari,Chrome 隐藏滚动条 */
          display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
        }
        .title {
          height: 40px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          background: #fff2f1;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          font-size: 16px;
          color: #f5222d;
          border-bottom: 1px solid #dbdbdb;
          font-weight: 700;
          text-align: center;
        }
      }
    }
  }
  ::v-deep
    .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    color: #f5222d;
    font-weight: bold;
    background-color: #fff !important;
  }
  .searchArea {
    display: -webkit-flex;
    display: flex;
    font-size: 12px;
    color: #333;
    align-items: center;
    height: 40px;
    padding: 0 10px;
    border-bottom: 1px solid #dbdbdb;
    .tit {
      flex-shrink: 0;
      margin-right: 10px;
      font-size: 16px;
    }
  }
  .architecture {
    display: inline-table;
  }
  .resultArea {
    -webkit-flex: 1;
    flex: 1;
    margin-left: 10px;
    padding: 8px 10px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 4px;
    .resultHearder {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #303133;
      margin-bottom: 4px;
    }
    .resultContent {
      height: calc(100% - 40px);
      display: -webkit-flex;
      display: flex;
      justify-content: space-between;
      flex: 1;

      .contentItem {
        flex: 0 0 63%;
        // border: 1px solid #dbdbdb;
      }
      .contentItemMini {
        flex: 0 0 35%;
        border: 1px solid #dbdbdb;
      }
      .item {
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        .list {
          height: calc(100vh - 340px);
          overflow-y: auto;
          padding: 10px;
          .listContent {
            ul {
              list-style: none;
              margin: 0;
              padding: 0;
              li {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                border-radius: 2px;
                .item {
                  flex: 1;
                  padding: 4px;
                  box-sizing: border-box;
                  .nameBox {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .name {
                      color: #333;
                      font-size: 14px;
                    }
                    .price {
                      color: rgb(245, 154, 35);
                      font-size: 14px;
                    }
                    .editIcon {
                      font-size: 16px;
                      cursor: pointer;
                    }
                  }
                  .desc {
                    display: flex;
                    margin-top: 10px;
                    color: #aaaaaa;
                    font-size: 14px;
                    justify-content: space-between;
                    align-items: center;
                  }
                }
              }
              .selLi {
                background: rgba(236, 245, 255, 1);
              }
            }
          }
        }
      }
      .title {
        height: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background: #fff2f1;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 16px;
        color: #f5222d;
        border-bottom: 1px solid #dbdbdb;
        font-weight: 700;
        text-align: center;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: right;
    padding: 0 20px;
    height: 50px;
    align-items: center;
    border-top: 1px solid #dbdbdb;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    .num {
      flex: 1;
      font-size: 12px;
      color: #333;
      text-align: center;
      padding-left: 20px;
    }
  }
}
// 弹框样式
.outDialogBtn {
  display: flex;
  justify-content: right;
  align-items: center;
  height: 40px;
}
.dialogContent {
  display: flex;
  .item {
    flex: 1;
    border: 1px solid #dbdbdb;
    .cHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      .tit {
        font-size: 14px;
        color: #333;
        margin-right: 20px;
        flex-shrink: 0;
      }
      .ipt {
        flex: 1;
        margin-right: 20px;
      }
    }
  }
  .cRight {
    margin-left: 20px;
  }
  .dialogtree {
    border-top: 1px solid #dbdbdb;
    box-sizing: border-box;
    padding: 10px;
    min-height: 100px;
    max-height: 300px;
    overflow: auto;
  }
  ::v-deep
    .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    color: #f5222d;
    font-weight: bold;
    background-color: #fff !important;
  }
}
::v-deep .custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
::v-deep {
  .el-transfer {
    height: 100%;
    width: 100%;
    .el-transfer-panel {
      width: calc(50% - 20px);
      .el-transfer-panel__item {
        height: auto;
        .spanList {
          color: rgb(170, 170, 170);
        }
        .spanTitle {
          color: rgb(51, 51, 51);
        }
      }
      .el-transfer-panel__header {
        background: #fff2f1;
        .el-checkbox__label {
          font-weight: 700;
          color: #f5222d;
          span {
            display: none;
          }
        }
      }
      .el-transfer-panel__footer {
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .el-transfer-panel__filter {
        border-bottom: solid 1px #dbdbdb;
        margin: 0;
        padding: 23px 15px;
      }
      .el-input__prefix {
        left: 10px;
      }
    }
    .el-transfer-panel__body {
      height: calc(100% - 55px);
      .el-checkbox-group {
        height: calc(100% - 62px);
      }
    }
  }
  .el-transfer__buttons {
    display: inline-grid;
    margin: auto auto;
    padding: 0px 10px;
    .el-button {
      margin-left: 0px;
      padding: 7px 15px;
      font-size: 12px;
    }
  }
}
</style>