var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.btnStr(_vm.orderDetail.invoiceAfterSaleStatus) ||
    _vm.btnStr(_vm.orderDetail.certificationStatus)
    ? _c(
        "div",
        { staticClass: "qualiAfterSaleMain" },
        [
          _vm._m(0),
          _c("div", { staticClass: "inLine" }, [
            _vm.btnStr(_vm.orderDetail.invoiceAfterSaleStatus)
              ? _c(
                  "div",
                  { staticClass: "inLine afterSaleApplyView" },
                  [
                    _c("span", { staticClass: "title" }, [_vm._v("发票相关")]),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.handleOpenFaPiao },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.btnStr(
                              _vm.orderDetail.invoiceAfterSaleStatus
                            ) || "申请售后"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.btnStr(_vm.orderDetail.certificationStatus)
              ? _c(
                  "div",
                  { staticClass: "inLine afterSaleApplyView" },
                  [
                    _c("span", { staticClass: "title" }, [_vm._v("随货资质")]),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.handleOpen },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.btnStr(_vm.orderDetail.certificationStatus) ||
                              "申请售后"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("AfterInvoice", {
            ref: "AfterInvoice",
            attrs: { orderDetail: _vm.orderDetail },
          }),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "资质相关售后",
                visible: _vm.dialogVisible,
                width: "848px",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "mainContent" },
                [
                  _c(
                    "el-scrollbar",
                    { attrs: { "wrap-style": "overflow-x:hidden;" } },
                    [
                      _c("div", { staticClass: "dialogTitle" }, [
                        _vm._v("企业相关资质"),
                      ]),
                      _c("div", { staticClass: "typeView" }, [
                        _vm.companyTypes && _vm.companyTypes.length > 0
                          ? _c(
                              "div",
                              _vm._l(_vm.companyTypes, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "typeCell" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "typeCheck typeSelect",
                                        on: {
                                          click: function ($event) {
                                            return _vm.didClickType(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [_vm._v(_vm._s(item.name))]),
                                        _c("img", {
                                          staticClass: "checkImage",
                                          attrs: { src: _vm.skucheck, alt: "" },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "uploadView" },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              disabled:
                                                _vm.orderDetail
                                                  .certificationStatus !=
                                                "CHECKING",
                                              accept:
                                                ".jpg,.jpeg,.pdf,.PDF,.JPG,.PNG,.png",
                                              action: _vm.uploadUrl,
                                              header: _vm.header,
                                              multiple: "",
                                              limit: 3,
                                              "file-list": item.image
                                                ? item.image
                                                : [],
                                              "on-preview": _vm.preview,
                                              "on-remove": (file, fileList) =>
                                                _vm.removeUpload(
                                                  item,
                                                  file,
                                                  fileList
                                                ),
                                              "on-success": (
                                                response,
                                                file,
                                                fileList
                                              ) =>
                                                _vm.successUpload(
                                                  item,
                                                  response,
                                                  file,
                                                  fileList
                                                ),
                                            },
                                          },
                                          [
                                            item.image.length < 3 &&
                                            _vm.orderDetail
                                              .certificationStatus == "CHECKING"
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      type: "primary",
                                                    },
                                                  },
                                                  [_vm._v("点击上传")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _c("div", [_vm._v("无")]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "dialogTitle",
                          staticStyle: { "margin-top": "24px" },
                        },
                        [_vm._v("商品相关资质")]
                      ),
                      _c("div", { staticClass: "goodsView" }, [
                        _vm.goodsTypes && _vm.goodsTypes.length > 0
                          ? _c(
                              "div",
                              _vm._l(_vm.goodsTypes, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "goodsItem" },
                                  [
                                    _c("img", {
                                      staticClass: "goodsImage",
                                      attrs: {
                                        src:
                                          item.goodsImage || _vm.defaultImage,
                                        alt: "",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "column goodsInnerView" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "goodsName" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.goodsName) + " "
                                            ),
                                          ]
                                        ),
                                        item.typeDetail
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "goodsAfterSaleType",
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    attrs: {
                                                      size: "small",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value: item.check,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "check",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.check",
                                                    },
                                                  },
                                                  [
                                                    item.check.indexOf("1") > -1
                                                      ? _c(
                                                          "el-checkbox",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "align-items":
                                                                "center",
                                                              "min-height":
                                                                "35px",
                                                            },
                                                            attrs: {
                                                              label: "1",
                                                            },
                                                          },
                                                          [
                                                            _vm._v("药检报告 "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "uploadView",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-upload",
                                                                  {
                                                                    staticClass:
                                                                      "upload-demo",
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm
                                                                          .orderDetail
                                                                          .certificationStatus !=
                                                                        "CHECKING",
                                                                      accept:
                                                                        ".jpg,.jpeg,.pdf,.PDF,.JPG,.PNG,.png",
                                                                      action:
                                                                        _vm.uploadUrl,
                                                                      header:
                                                                        _vm.header,
                                                                      multiple:
                                                                        "",
                                                                      limit: 3,
                                                                      "file-list":
                                                                        item.image
                                                                          ? item.image
                                                                          : [],
                                                                      "on-preview":
                                                                        _vm.preview,
                                                                      "on-remove":
                                                                        (
                                                                          file,
                                                                          fileList
                                                                        ) =>
                                                                          _vm.removeUpload(
                                                                            item,
                                                                            file,
                                                                            fileList
                                                                          ),
                                                                      "on-success":
                                                                        (
                                                                          response,
                                                                          file,
                                                                          fileList
                                                                        ) =>
                                                                          _vm.successUpload(
                                                                            item,
                                                                            response,
                                                                            file,
                                                                            fileList
                                                                          ),
                                                                    },
                                                                  },
                                                                  [
                                                                    item.image
                                                                      .length <
                                                                      3 &&
                                                                    _vm
                                                                      .orderDetail
                                                                      .certificationStatus ==
                                                                      "CHECKING"
                                                                      ? _c(
                                                                          "el-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                size: "mini",
                                                                                type: "primary",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "点击上传"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    item.check.indexOf("2") > -1
                                                      ? _c(
                                                          "el-checkbox",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "align-items":
                                                                "center",
                                                              "min-height":
                                                                "35px",
                                                            },
                                                            attrs: {
                                                              label: "2",
                                                            },
                                                          },
                                                          [
                                                            _vm._v("首营资料 "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "uploadView",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-upload",
                                                                  {
                                                                    staticClass:
                                                                      "upload-demo",
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm
                                                                          .orderDetail
                                                                          .certificationStatus !=
                                                                        "CHECKING",
                                                                      accept:
                                                                        ".jpg,.jpeg,.pdf,.PDF,.JPG,.PNG,.png",
                                                                      action:
                                                                        _vm.uploadUrl,
                                                                      header:
                                                                        _vm.header,
                                                                      multiple:
                                                                        "",
                                                                      limit: 3,
                                                                      "file-list":
                                                                        item.imageExtend
                                                                          ? item.imageExtend
                                                                          : [],
                                                                      "on-preview":
                                                                        _vm.preview,
                                                                      "on-remove":
                                                                        (
                                                                          file,
                                                                          fileList
                                                                        ) =>
                                                                          _vm.imageExtendRemoveUpload(
                                                                            item,
                                                                            file,
                                                                            fileList
                                                                          ),
                                                                      "on-success":
                                                                        (
                                                                          response,
                                                                          file,
                                                                          fileList
                                                                        ) =>
                                                                          _vm.imageExtendUpload(
                                                                            item,
                                                                            response,
                                                                            file,
                                                                            fileList
                                                                          ),
                                                                    },
                                                                  },
                                                                  [
                                                                    item
                                                                      .imageExtend
                                                                      .length <
                                                                      3 &&
                                                                    _vm
                                                                      .orderDetail
                                                                      .certificationStatus ==
                                                                      "CHECKING"
                                                                      ? _c(
                                                                          "el-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                size: "mini",
                                                                                type: "primary",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "点击上传"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _c("div", [_c("div", [_vm._v("无")])]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "dialogTitle",
                          staticStyle: { "margin-top": "24px" },
                        },
                        [_vm._v("其他")]
                      ),
                      _c("div", { staticClass: "typeView" }, [
                        _vm.otherTypes && _vm.otherTypes.length > 0
                          ? _c(
                              "div",
                              _vm._l(_vm.otherTypes, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "typeCell" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "typeCheck typeSelect" },
                                      [
                                        _c("span", [_vm._v("出库单")]),
                                        _c("img", {
                                          staticClass: "checkImage",
                                          attrs: { src: _vm.skucheck, alt: "" },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "uploadView" },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              disabled:
                                                _vm.orderDetail
                                                  .certificationStatus !=
                                                "CHECKING",
                                              accept:
                                                ".jpg,.jpeg,.pdf,.PDF,.JPG,.PNG,.png",
                                              action: _vm.uploadUrl,
                                              header: _vm.header,
                                              multiple: "",
                                              limit: 3,
                                              "file-list": item.image
                                                ? item.image
                                                : [],
                                              "on-preview": _vm.preview,
                                              "on-remove": (file, fileList) =>
                                                _vm.removeUpload(
                                                  item,
                                                  file,
                                                  fileList
                                                ),
                                              "on-success": (
                                                response,
                                                file,
                                                fileList
                                              ) =>
                                                _vm.successUpload(
                                                  item,
                                                  response,
                                                  file,
                                                  fileList
                                                ),
                                            },
                                          },
                                          [
                                            item.image.length < 3 &&
                                            _vm.orderDetail
                                              .certificationStatus == "CHECKING"
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      type: "primary",
                                                    },
                                                  },
                                                  [_vm._v("点击上传")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _c("div", [_vm._v("无")]),
                        _c(
                          "div",
                          { staticClass: "remarkView" },
                          [
                            _c("div", { staticClass: "remarkInfo" }, [
                              _vm._v("具体说明（有其他证件资质需求请说明）"),
                            ]),
                            _c("el-input", {
                              attrs: {
                                disabled: true,
                                rows: 3,
                                type: "textarea",
                                placeholder: "请输入",
                              },
                              model: {
                                value: _vm.remark,
                                callback: function ($$v) {
                                  _vm.remark = $$v
                                },
                                expression: "remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "dialogTitle",
                          staticStyle: { "margin-top": "24px" },
                        },
                        [_vm._v("协商历史")]
                      ),
                      _vm._l(
                        _vm.orderConsultHistoryList,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "hisView" },
                            [
                              _c("div", { staticClass: "column" }, [
                                _c("div", { staticClass: "shopName" }, [
                                  _vm._v(_vm._s(item.consultPerson || "")),
                                ]),
                                _c("div", { staticClass: "hisInfo" }, [
                                  _vm._v(_vm._s(item.consultResult || "")),
                                ]),
                              ]),
                              _c("div", { staticClass: "time" }, [
                                _vm._v(_vm._s(item.consultTime || "")),
                              ]),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("div", { staticStyle: { "margin-left": "auto" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = false
                          },
                        },
                      },
                      [_vm._v("返 回")]
                    ),
                    _vm.orderDetail.certificationStatus == "CHECKING"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              loading: _vm.receiveLoading,
                              size: "small",
                            },
                            on: { click: _vm.bohui },
                          },
                          [_vm._v("驳 回")]
                        )
                      : _vm._e(),
                    _vm.orderDetail.certificationStatus == "CHECKING"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              loading: _vm.receiveLoading,
                              size: "small",
                            },
                            on: { click: _vm.submit },
                          },
                          [_vm._v("提 交")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "el-dialog",
            {
              staticClass: "detail",
              attrs: { visible: _vm.pdfDialog, width: "848px", title: "详情" },
              on: {
                "update:visible": function ($event) {
                  _vm.pdfDialog = $event
                },
              },
            },
            [
              _vm.pdfStr == "pdf" || _vm.pdfStr == "PDF"
                ? _c("iframe", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: _vm.pdfUrl, frameborder: "0" },
                  })
                : _c("img", {
                    staticStyle: {
                      width: "auto",
                      height: "100%",
                      "object-fit": "contain",
                    },
                    attrs: { src: _vm.pdfUrl, frameborder: "0" },
                  }),
            ]
          ),
          _c(
            "el-dialog",
            {
              staticClass: "reject",
              attrs: { title: "驳回原因", visible: _vm.bohuiDialog },
              on: {
                "update:visible": function ($event) {
                  _vm.bohuiDialog = $event
                },
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.rejectReason,
                  callback: function ($$v) {
                    _vm.rejectReason = $$v
                  },
                  expression: "rejectReason",
                },
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.bohuiDialog = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.bohuiSubmit },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "left_topic" }, [_vm._v("资质售后")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }