var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "commodity card" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "commemt-list" },
      _vm._l(_vm.commentList, function (item, key) {
        return _c(
          "div",
          { key: key, staticClass: "list comment-hover" },
          [
            _c(
              "el-row",
              { staticClass: "list-title" },
              [
                _c("el-col", { staticClass: "name", attrs: { span: 12 } }, [
                  _vm._v(_vm._s(item.createBy)),
                ]),
                _c("el-col", { staticClass: "time", attrs: { span: 12 } }, [
                  _vm._v(_vm._s(item.updateTime)),
                ]),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-row",
                  { staticClass: "list-image-row" },
                  [
                    _c(
                      "el-col",
                      { staticClass: "list-star", attrs: { span: 12 } },
                      [
                        item.source == 2
                          ? _c("div", [
                              _vm._v(" " + _vm._s(item.comment) + " "),
                            ])
                          : _vm._e(),
                        item.source == 1
                          ? _c("div", [
                              _c("div", { staticClass: "label-list" }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("商品是否与描述相符 "),
                                ]),
                                _c(
                                  "div",
                                  {},
                                  [
                                    _c("el-rate", {
                                      attrs: {
                                        max: 5,
                                        "show-score": false,
                                        "allow-half": false,
                                        "show-text": "",
                                        disabled: _vm.disabled,
                                        texts: _vm.texts,
                                        colors: _vm.colors,
                                      },
                                      model: {
                                        value: item.goodsScore,
                                        callback: function ($$v) {
                                          _vm.$set(item, "goodsScore", $$v)
                                        },
                                        expression: "item.goodsScore",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "label-list" }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("物流是否按时到达"),
                                ]),
                                _c(
                                  "div",
                                  {},
                                  [
                                    _c("el-rate", {
                                      attrs: {
                                        max: 5,
                                        "show-score": false,
                                        "allow-half": false,
                                        "show-text": "",
                                        disabled: _vm.disabled,
                                        texts: _vm.texts,
                                        colors: _vm.colors,
                                      },
                                      model: {
                                        value: item.logisticScore,
                                        callback: function ($$v) {
                                          _vm.$set(item, "logisticScore", $$v)
                                        },
                                        expression: "item.logisticScore",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "label-list" }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("客服回复消息是否及时"),
                                ]),
                                _c(
                                  "div",
                                  {},
                                  [
                                    _c("el-rate", {
                                      attrs: {
                                        max: 5,
                                        "show-score": false,
                                        "allow-half": false,
                                        "show-text": "",
                                        disabled: _vm.disabled,
                                        texts: _vm.texts,
                                        colors: _vm.colors,
                                      },
                                      model: {
                                        value: item.customerServiceScore,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item,
                                            "customerServiceScore",
                                            $$v
                                          )
                                        },
                                        expression: "item.customerServiceScore",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "label-list" }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("售后是否积极响应"),
                                ]),
                                _c(
                                  "div",
                                  {},
                                  [
                                    _c("el-rate", {
                                      attrs: {
                                        max: 5,
                                        "show-score": false,
                                        "allow-half": false,
                                        "show-text": "",
                                        disabled: _vm.disabled,
                                        texts: _vm.texts,
                                        colors: _vm.colors,
                                      },
                                      model: {
                                        value: item.afterSalesScore,
                                        callback: function ($$v) {
                                          _vm.$set(item, "afterSalesScore", $$v)
                                        },
                                        expression: "item.afterSalesScore",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c("el-col", { attrs: { span: 12 } }, [
                      item.imageList
                        ? _c(
                            "div",
                            { staticClass: "list-image" },
                            [
                              _c("ImgUpload", {
                                attrs: { limit: 5, isEdit: _vm.disabled },
                                model: {
                                  value: item.imageList,
                                  callback: function ($$v) {
                                    _vm.$set(item, "imageList", $$v)
                                  },
                                  expression: "item.imageList",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                item.source == 1
                  ? _c("el-row", { staticClass: "list-comment" }, [
                      _c("div", [_vm._v(" " + _vm._s(item.comment) + " ")]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      }),
      0
    ),
    _vm.commentList.length == 0
      ? _c("div", { staticClass: "no-commnet" }, [_vm._v(" 暂无评论 ")])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "left_topic" }, [_vm._v("订单评论")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }