var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "numberValidateForm",
          staticClass: "ruleFormCont",
          attrs: {
            model: _vm.numberValidateForm,
            "label-width": "100px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "formItemOne",
              attrs: {
                label: "企业类型",
                rules: [
                  { required: true, message: `请选择类型`, trigger: `change` },
                ],
                prop: "selectNum",
              },
            },
            [
              _c(
                "div",
                { staticClass: "selectCont" },
                _vm._l(_vm.shopList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: {
                        itemCont: true,
                        mainActive: _vm.numberValidateForm.selectNum === index,
                        mainBorder: _vm.numberValidateForm.selectNum != index,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectBtn(item, index)
                        },
                      },
                    },
                    [
                      _c("el-image", {
                        staticStyle: {
                          display: "flex",
                          "margin-right": "10px",
                        },
                        attrs: {
                          src: _vm.getStatus(_vm.shopTypeName, item.typeName),
                        },
                      }),
                      _vm._v(" " + _vm._s(item.typeName) + " "),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          ),
          _vm.numberValidateForm.selectNum === 0
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "医疗机构执业许可证编码",
                        rules: [
                          {
                            required: true,
                            message: `请输入医疗机构执业许可证编码`,
                            trigger: `blur`,
                          },
                        ],
                        prop: "licenceNo",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          placeholder: "请输入医疗机构执业许可证编码",
                          disabled: _vm.iseditor,
                        },
                        model: {
                          value: _vm.numberValidateForm.licenceNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.numberValidateForm, "licenceNo", $$v)
                          },
                          expression: "numberValidateForm.licenceNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "营业执照编码",
                    rules: [
                      {
                        required: _vm.numberValidateForm.selectNum != 0,
                        message: `请输入营业执照编码`,
                        trigger: `blur`,
                      },
                    ],
                    prop: "idNumber",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      placeholder: "请输入营业执照编码",
                      disabled: _vm.iseditor,
                    },
                    model: {
                      value: _vm.numberValidateForm.idNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.numberValidateForm, "idNumber", $$v)
                      },
                      expression: "numberValidateForm.idNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "采购/收货人姓名",
                rules:
                  _vm.numberValidateForm.selectNum == 0 ||
                  _vm.numberValidateForm.selectNum == 1
                    ? [
                        {
                          required: true,
                          message: `请输入医疗机构执业许可证编码`,
                          trigger: `blur`,
                        },
                      ]
                    : [
                        {
                          required: false,
                          message: `请输入采购/收货人姓名`,
                          trigger: `blur`,
                        },
                      ],
                prop: "concatName",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  placeholder: "请输入采购/收货人姓名",
                  disabled: _vm.iseditor,
                },
                model: {
                  value: _vm.numberValidateForm.concatName,
                  callback: function ($$v) {
                    _vm.$set(_vm.numberValidateForm, "concatName", $$v)
                  },
                  expression: "numberValidateForm.concatName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "采购/收货人联系电话",
                rules:
                  _vm.numberValidateForm.selectNum == 0 ||
                  _vm.numberValidateForm.selectNum == 1
                    ? [
                        {
                          required: true,
                          message: `请输入采购/收货人联系电话`,
                          trigger: `blur`,
                        },
                      ]
                    : [
                        {
                          required: false,
                          message: `请输入采购/收货人联系电话`,
                          trigger: `blur`,
                        },
                      ],
                prop: "concatPhone",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  maxlength: "15",
                  placeholder: "请输入采购/收货人联系电话",
                  disabled: _vm.iseditor,
                },
                model: {
                  value: _vm.numberValidateForm.concatPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.numberValidateForm, "concatPhone", $$v)
                  },
                  expression: "numberValidateForm.concatPhone",
                },
              }),
            ],
            1
          ),
          _vm.numberValidateForm.selectNum == 0
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "采购/收货人身份证号",
                    rules: [
                      {
                        required: true,
                        message: `请输入采购/收货人身份证号`,
                        trigger: `blur`,
                      },
                    ],
                    prop: "concatIdCard",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      maxlength: "18",
                      placeholder: "请输入采购/收货人身份证号",
                      disabled: _vm.iseditor,
                    },
                    model: {
                      value: _vm.numberValidateForm.concatIdCard,
                      callback: function ($$v) {
                        _vm.$set(_vm.numberValidateForm, "concatIdCard", $$v)
                      },
                      expression: "numberValidateForm.concatIdCard",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "售后联系电话",
                rules: [
                  {
                    required: true,
                    message: `请输入售后服务手机号码`,
                    trigger: `blur`,
                  },
                ],
                prop: "servicePhone",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  maxlength: "15",
                  placeholder: "请输入售后联系电话",
                  disabled: _vm.iseditor,
                },
                model: {
                  value: _vm.numberValidateForm.servicePhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.numberValidateForm, "servicePhone", $$v)
                  },
                  expression: "numberValidateForm.servicePhone",
                },
              }),
            ],
            1
          ),
          !_vm.iseditor
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.formload,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("numberValidateForm")
                        },
                      },
                    },
                    [_vm._v("信息认证")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.iseditor
        ? _c("span", { staticClass: "tip down" }, [
            _vm._v(
              " 提示：为保证结果准确性，请保证企业类型、证照编码与实际相符 "
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }