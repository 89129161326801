export default {

	showInputArr: [{
		name: "营业执照编码",
		require: true,
		prop: "idNumber"
	},
	{
		name: '采购/收货人姓名',
		require: false,
		prop: 'concatName'
	}, {
		name: '采购/收货人联系电话',
		require: false,
		prop: 'concatPhone'
	}, {
		name: '售后联系电话',
		require: true,
		prop: 'servicePhone'
	}
	],
	showImgArr: [{
		code: "01",
		name: "营业执照",
		require: true,
		images: [],
		desc: "营利性医疗机构必须上传营业执照，复印件请加盖鲜章",
		credentialImage: "",
		showCredential: true,
		showDownload: false,
		downlUrl: ""
	}, {
		code: "02",
		name: "药品经营许可证",
		require: true,
		images: [],
		desc: "复印件加盖鲜章",
		credentialImage: "",
		showCredential: true,
		showDownload: false,
		downlUrl: ""
	}, {
		code: "03",
		name: "食品经营许可证",
		require: false,
		images: [],
		desc: "复印件加盖鲜章",
		credentialImage: "",
		showCredential: true,
		showDownload: false,
		downlUrl: ""
	}, {
		code: "05",
		name: "被委托人身份证复印件(正反两面)",
		require: true,
		images: [],
		desc: "复印件加盖鲜章",
		credentialImage: "",
		showCredential: true,
		showDownload: false,
		downlUrl: ""
	}, {
		code: "06",
		name: "授权委托书",
		require: true,
		images: [],
		desc: "复印件加盖鲜章",
		credentialImage: "",
		showCredential: true,
		showDownload: true,
		downlUrl: ""
	}, {
		code: "07",
		name: "第二类医疗器械经营备案凭证",
		require: true,
		images: [],
		desc: "复印件加盖鲜章",
		credentialImage: "",
		showCredential: true,
		showDownload: true,
		downlUrl: ""
	}, {
		code: "08",
		name: "开票信息",
		require: true,
		images: [],
		desc: "",
		credentialImage: "",
		showCredential: true,
		showDownload: true,
		downlUrl: ""
	}, {
		code: "10",
		name: "质保协议",
		require: true,
		images: [],
		desc: "",
		credentialImage: "",
		showCredential: true,
		showDownload: true,
		downlUrl: ""
	}, {
		code: "09",
		name: "其它",
		require: false,
		images: [],
		desc: "",
		credentialImage: "",
		showCredential: true,
		showDownload: false,
		downlUrl: ""
	}]
};