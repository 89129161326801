<!--  -->
<template>
  <div class="comContent">
    <Tabs
      @tab-click="handleClick"
      style="padding: 0 24px"
      :tabsList="tabsList"
      :activeName.sync="activeName"
    ></Tabs>
    <div class="topHeader">
      <div style="width: calc(100% - 200px)">
        <el-form
          ref="formTool"
          :model="formTool"
          :inline="true"
          style="display: flex"
        >
          <template>
            <div
              class="item big"
              style="display: flex; justify-content: center"
            >
              <el-form-item prop="shopName" size="small">
                <el-input
                  v-model="formTool.shopName"
                  clearable
                  placeholder="客户名称"
                />
              </el-form-item>
            </div>
          </template>
        </el-form>
      </div>
      <div style="width: 220px; text-align: right">
        <MyButton type="primary" text="查询" @click="getList(1)" />
        <MyButton type="" text="重置" @click="reset()" />
      </div>
    </div>

    <div class="center">
      <MyTable
        v-if="tableData.length > 0"
        :table-data="tableData"
        :index="index"
        :selection="selection"
        :page="page"
        :operation="operation"
        :table-option.sync="tableOption"
        @page-change="getList"
      >
        <template slot="op" slot-scope="scope">
          <el-link
            type="primary"
            :underline="false"
            @click="handleLookComment(scope.$index)"
          >
            查看评论
          </el-link>
          <el-link
            type="primary"
            style="margin-left: 10px"
            :underline="false"
            @click="handleLookOrder(scope.$index)"
          >
            查看订单
          </el-link>
        </template>
      </MyTable>
      <el-empty v-else style="height: 100%" description="暂无数据" />
    </div>

    <CommentDlg ref="refCommentDlg" @update="handleRefresh" />
  </div>
</template>

<script>
import ImgUpload from "@/components/imgUpload";
import MyButton from "@/components/myButton";
import MyTable from "@/components/myTable";
import Tabs from "@/components/Tabs";
import { buttonView } from "@/api/externalSalesRule/index";
import { getOrderCommentPage } from "@/api/afterSales/serviceSheet";

import CommentDlg from "./dialog/commentDlg.vue";

export default {
  components: { MyButton, MyTable, Tabs, ImgUpload, CommentDlg },
  data() {
    return {
      afterSalesControl: "", // 售后控制
      rowId: "",

      dialogVisible: false,
      textarea: "",

      imageList: [],
      tabsList: [
        {
          name: "1",
          label: "待回复",
          value: 0,
          status: 0,
        },
        {
          name: "2",
          label: "已回复",
          value: 0,
          status: 1,
        },
      ],
      activeName: "1", // tabs 传的值

      formTool: {
        shopName: "",
      },
      operation: false,
      selection: false,
      index: false,
      tableData: [],
      tableOption: [],
      page: {
        total: 0,
        current: 1,
        size: 10,
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    // if (this.$store.state.comment.activeName) {
    //   this.activeName = this.$store.state.comment.activeName;
    //   this.$store.dispatch("comment/setActiveName", "");
    // }
    // this.initTableOption();
    // this.getList();
  },
  mounted() {
     if(this.$store.state.tabStatus.commentsTab){
      this.activeName = this.$store.state.tabStatus.commentsTab;   
     }
     this.initTableOption(); 
     this.getList(); 
  },
  methods: {
    initTableOption() {
      let tableOption = [
        { label: "客户名称", prop: "createBy" },
        { label: "评论时间", prop: "createTime" },
      ];
      if (this.activeName === "2") {
        tableOption = tableOption.concat([
          { label: "回复时间", prop: "updateTime" },
        ]);
      }
      tableOption = tableOption.concat([
        { label: "订单编号", prop: "orderCode" },
        {
          label: "操作",
          prop: "op",
          slot: true,
          width: "200px",
          align: "center",
        },
      ]);

      this.tableOption = tableOption;
    },
    handleRefresh() {
      this.getList(1);
    },
    handleLookOrder(index) {
      const item = this.tableData[index];
      this.goDetail(item.orderId, item.orderId, item.selfCheckStatus);
    },
    handleLookComment(index) {
      const item = this.tableData[index];

      this.$refs.refCommentDlg.open(item);
    },
    goDetail(id, orderId, selfCheckStatus) {
      if (selfCheckStatus) {
        this.$alert(
          "自营商品的订单需要地区公司、省区公司审核完成，医药公司再做发货",
          "订单审核中",
          {
            confirmButtonText: "确定",
            callback: () => {},
          }
        );
        return;
      }

      this.$store.dispatch("comment/setActiveName", this.activeName);

      this.$router.push({
        path: "/ordercenter/ordercenter-detail",
        query: { id: id, orderId: orderId },
      });
    },
    getList(current) {
      if (current) {
        this.page.current = current;
      }
      const findedItem = this.tabsList.find(
        (item) => item.name === this.activeName
      );
      let params = {
        ...this.formTool,
        status: findedItem ? findedItem.status : "",
        size: this.page.size,
        current: this.page.current,
      };

      getOrderCommentPage(params).then((data) => {
        if (data) {
          this.tableData = data.records;
          this.page.total = data.total;
        } else {
          this.tableData = [];
          this.page.total = 0;
        }
      });
    },
    // 重置查询条件
    reset() {
      this.formTool = { date: null, invoiceNo: "", content: "" };
      this.page.current = 1;
      this.getList();
    },
    // 点击tab
    handleClick() {
      this.$store.dispatch("tabStatus/setCommentsTab", this.activeName);
      this.initTableOption();
      this.getList(1);
    },
    // 查看详情
    detail(row) {
      this.$router.push({
        path: "/ordercenter/ordercenter-afterSalesDocumentDetail",
        query: { afterSaleId: row.id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.comContent {
  padding: 0px;
}
.expand {
  &_box {
    padding: 20px 30px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  &_li {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 45%;
    margin-right: 3%;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 10px;
    word-break: break-all;
    strong {
      margin-right: 10px;
      font-weight: 400;
      color: #000;
    }
    span {
      color: #666;
    }
    &_more {
      position: relative;
    }
    &_link {
      color: #f5222d;
      &:hover {
        color: #3db4b9;
      }
    }
    &_img {
      width: 100px;
      height: 100px;
    }
  }
}
.center {
  margin: 0 24px;
  height: calc(100% - 170px);
}
.myTabs {
  height: 56px;
  padding: 0 24px;
  border-bottom: 1px solid #f5f5f5 !important;
}
.topHeader {
  padding: 0 24px;
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  line-height: 56px;
}
.el-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
}
::v-deep .el-dialog__footer {
  border-top: 1px #f5f5f5 solid;
  padding-top: 15px;
}
</style>
