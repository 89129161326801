<!--  -->
<template>
  <div class='classification' v-loading="loading">
    <!-- <Tabs :tabsList="tabsList"
          :activeName.sync="activeName"
          @tab-click="handleChange" /> -->
    <div>
      <div class="flex-box">
        <el-row :gutter="16" style="width:calc(100% - 60px)">
          <el-col :span="4">
            <el-select v-model="pageObj.page.oneGoodsOperationalClassId" size="small" placeholder="一级分类"
              style="width:100%" @change="changeOneClassId">
              <el-option v-for="item in goodsOneClassIdOptions" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="pageObj.page.twoGoodsOperationalClassId" size="small" placeholder="二级分类" clearable
              style="width:100%" @change="changeTwoClassId">
              <el-option v-for="item in goodsTwoClassIdOptions" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-input v-model="pageObj.page.factory" size="small" placeholder="生产厂商" clearable
              @keyup.enter.native="searchResult(1)" />
          </el-col>
          <el-col :span="4">
            <el-input v-model="pageObj.page.name" size="small" placeholder="商品名称" clearable
              @keyup.enter.native="searchResult(1)" />
          </el-col>
          <el-col :span="4">
            <el-button size="small" type="primary" @click="searchResult(1)">检索</el-button>
          </el-col>
        </el-row>
        <div style="display:flex;float: right;margin-left:32px">
          <el-button v-if="isShowSave" :disabled="status != '0'" size="small" type="primary"
            @click="bathUpdateByProduct(1)">保存</el-button>
          <!-- <el-button size="small"
                     :disabled="records.length == 0"
                     type="primary"
                     @click="correlation()">全部{{btnType == 'show'?'':'取消'}}关联</el-button> -->
        </div>
      </div>
      <div class="contentCont" ref="tableCont">
        <div class="contentInner" v-if="records.length > 0">
          <CouponGoodsList v-for="(item, index) in records" :key="index" :item="item"
            @removeSelectedItem="updateSelectedGoods" @addGunalian="addGunalian" />
        </div>
        <el-empty v-else style="height:100%;width:100%" description="暂无选中的指定商品" />
      </div>
      <div class="el-page" v-if="!isShowSelectedResult">
        <Pagination :total="pageObj.total" :page.sync="pageObj.page.current" :limit.sync="pageObj.page.size"
          :pageSizes="pageObj.pageSizes" @pagination="changeSize" />
      </div>
    </div>
    <!-- <div v-else
         style="padding:10px 0px">
      <el-cascader style="width:500px"
                   :options="options"
                   collapse-tags
                   :props="props"
                   @change="changeNode"
                   clearable></el-cascader>
    </div> -->
  </div>
</template>

<script>
import Tabs from '@/components/Tabs'
import Pagination from '@/components/Pagination'
import CouponGoodsList from './couponGoodsList'
import { oprationclassTreeDisplay } from '@/api/classificationmanagement.js'
import { goodsClassRelationsSelectGoods, bathUpdateByProduct } from '@/api/classificationmanagement'
import { mapGetters } from 'vuex'
export default {
  components: { Tabs, Pagination, CouponGoodsList },
  props: {
    rowData: {
      type: Object,
      data: {}
    },
    status: {
      type: String,
      data: ''
    },
  },
  data () {
    return {
      loading: false,
      btnType: 'show',
      isShowSelectedResult: false,
      addList: [],
      activeName: '1',
      goodsOneClassIdOptions: [],
      goodsTwoClassIdOptions: [],
      goodsThreeClassIdOptions: [],
      pageObj: {
        // 分页对象
        position: 'right', // 分页组件位置
        total: 0,
        pageSizes: [12, 24, 36, 48],
        page: {
          size: 12,
          current: 1,
          name: '',
          factory: '',
          oneGoodsOperationalClassId: '',
          twoGoodsOperationalClassId: '',
        }
      },
      tableData: [],
      records: [],
      tabsList: [ // tabs 传的值
        {
          name: '1',
          label: '指定商品',
          value: 0,
        },
      ],

      props: {
        label: 'name',
        value: 'id',
      },
    };
  },
  computed: {
    // ...mapGetters(["couponGoods"]),
    isShowSave () {
      return !this.isShowSelectedResult
    }
  },
  watch: {},
  methods: {
    updateSelectedGoods (val) {
      if (this.isShowSelectedResult) {
        this.records = this.records.filter(item => {
          return item.skuId != val.skuId
        })
        this.$emit('choice', this.records)
      }
    },
    // 条数变化
    changeSize () {
      this.goodsOperationalClassRelationsSelectGoods()
    },
    // 切换一级分类
    changeOneClassId (val) {
      if (val) {
        this.goodsclassTwo(val)
      }
    },
    // 切换二级分类
    changeTwoClassId (val) {
      if (val) {
        this.goodsclassThree(val)
      }
    },
    searchResult (current) {
      this.isShowSelectedResult = false
      this.goodsOperationalClassRelationsSelectGoods(current)
    },
    // 查询一级分类
    async goodsclassOne () {
      return new Promise((resolve, reject) => {
        oprationclassTreeDisplay().then(res => {
          this.goodsOneClassIdOptions = res
          let oneClassOptionId = ""
          if (this.goodsOneClassIdOptions.length > 0) {
            oneClassOptionId = this.goodsOneClassIdOptions[0].id
          } else {
            this.pageObj.page.oneGoodsOperationalClassId = ''
          }
          // this.pageObj.page.oneGoodsOperationalClassId = oneClassOptionId
          // resolve(oneClassOptionId)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 查询二级分类
    goodsclassTwo (id) {
      this.goodsTwoClassIdOptions = this.goodsOneClassIdOptions.find(item => item.id == id).children
      this.pageObj.page.twoGoodsOperationalClassId = ''
    },
    // 初次进来只查询一级分类 不联动
    async query () {
      this.goodsOperationalClassRelationsSelectGoods()
      const oneClassOptionId = await this.goodsclassOne()
      // this.changeOneClassId(oneClassOptionId)
    },

    // 查询关联分类列表
    goodsOperationalClassRelationsSelectGoods (current) {
      if (current) {
        // this.btnType = 'showFalse'
        this.pageObj.page.current = current
      }
      let params = {
        ...this.pageObj.page,
      }
      goodsClassRelationsSelectGoods(params).then(res => {
        this.records = res.records
        this.pageObj.total = res.total
        if (this.rowData.skuList.length > 0) {
          this.records.forEach(item => {
            this.rowData.skuList.forEach(el => {
              if (item.skuId == el.skuId) {
                this.$set(item, 'clickNone', true)
              }
            })
          })
        } else { 
          if(res.records.length>0) return;
          this.searchResult()
        }
      })
    },

    // 添加关联分类
    addGunalian (item) {
      console.log(this.addList, JSON.stringify('addList'))
      if (item.clickNone) {
        this.rowData.skuList.push(item)
      } else {
        if (this.rowData.skuList.length > 0) {
          this.rowData.skuList.forEach((el, index) => {
            if (el.skuId == item.skuId) {
              this.rowData.skuList.splice(index, 1)
            }
          })
        }
      }
    },
    // 真正关联的接口
    bathUpdateByProduct () {
      // this.$store.dispatch('coupon/addGoods', this.addList)
      this.$emit('choice', this.rowData.skuList)
      this.$message.success("保存成功")
      this.$parent.beforeClose()

    }
  },
  created () {
    this.query().then(res => {
      if (this.isShowSelectedResult) {
        // this.goodsOperationalClassRelationsSelectGoods()
      }
    })
  },
  mounted () {
  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
}
</script>
<style lang='scss' scoped>
.classification {
  height: 100%;
  padding: 0px;
  overflow: hidden;

  .tabs {
    ::v-deep .headerTabs {
      padding: 0px;
    }
  }

  .flex-box {
    width: 100%;
    display: flex;
    // padding: 10px 0;
    // flex-wrap: wrap;
    padding: 12px 0px;

    // border-bottom: 1px solid #f5f5f5;
    .el-row {
      .el-col {
        padding-right: 0px !important;
      }
    }

    .tab_sea {
      padding: 0px 5px;
    }
  }

  .contentCont {
    height: 532px;
    overflow: auto;
    padding: 0 0px;

    .contentInner {
      // height: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  .el-page {
    padding: 0px !important;
  }
}
</style>