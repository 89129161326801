<!-- 新批量发布商品 -->
<template>
  <div class='comContent'>
    
    <div class="search">
      <div class="">
        <el-input size="small" v-model.trim="chemName" placeholder="商品名称" clearable style="width:150px"></el-input>
      </div>
      <div class="everyItem">
        <el-input size="small" v-model.trim="shopGoodsCode" placeholder="ERP编码" style="width:150px"></el-input>
      </div>
      <div class="everyItem">
        <el-input size="small" clearable v-model.trim="factory" placeholder="生产厂家" style="width:150px"></el-input>
      </div>
      <!-- <div class="everyItem">
        <el-input size="small" clearable v-model.trim="barCode" placeholder="69码" style="width:150px"></el-input>
      </div> -->
      <div class="everyItem">
        <el-input size="small" clearable v-model.trim="nationalCode" placeholder="批准文号" style="width:150px"></el-input>
      </div>
      <div class="everyItem" v-if="tabIndex == 0">
        <el-select v-model="result" clearable="" placeholder="匹配结果" size="small" style="width:150px">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
 
      <div class="everyItem" style="display:flex">
        <el-button size="small" type="primary" @click="query(1)">查询</el-button>
        <el-button size="small" type="" @click="reset">重置</el-button>
      </div>
    </div>
    <div class="buttonClass">
      <div class="buttons" v-if="tabIndex == 0">
        <el-button size="small" type="primary" @click="match">批量确认匹配</el-button>
        <!-- <el-button :disabled="activeName != '2'"
                   size="small"
                   type="primary"
                   @click="shelf">一键上架</el-button> -->
        <el-button size="small" @click="importGoods">批量导入商品</el-button>
        <!-- <el-button size="small"
                   @click="uploadPicture">批量上传商品图片</el-button>
        <el-button size="small"
                   @click="deriveGoods">导出商品</el-button>
        <el-button size="small"
                   @click="importRecord">商品导入记录</el-button> -->
        <el-button size="small" @click="deleteGoods">批量删除</el-button>
      </div>
      <div class="buttons" v-if="tabIndex == 1">
        <el-button size="small" type="primary" @click="allPublishAction">全部上架</el-button>
        <el-button size="small" @click="shelf">批量上架</el-button>
      </div>
      <div class="buttons" v-if="tabIndex == 3">
        <el-button size="small" type="primary" @click="rollBack">批量恢复</el-button>
      </div>

      <!-- <div class="buttonFont">
        注：该列表内的商品包含“ERP对接抓取的商品” 和 “Excel批量上传的商品”，如您未对接ERP则可点击“批量导入商品”按钮上传商品
      </div> -->
    </div>
    <div class="bottom">
      <el-tabs v-model="tabIndex" @tab-click="handleClick">
        <el-tab-pane :label="item.name + '(' + item.count + ')'" :name="item.itemIndex"
          v-for="item, itemIndex in tabsList" :key="itemIndex">
          <MyTable :table-data="tableData" :index="index" :selection="selection" :page="page" :operation="operation"
            :table-option.sync="tableOption" @page-change="getList" @handleSelectionChange="handleSelectionChange">
            <template slot="companyInfo" slot-scope="scope">
              <div class="goodsInfo">
                <div>
                  <div class="chargeInLine"></div>
                  <div class="chemName">{{ scope.row.chemName }}</div>
                  <div>{{ scope.row.specification }}</div>
                  <div>{{ scope.row.factory }}</div>
                  <div>批准文号：{{ scope.row.nationalCode }}</div>
                  <div>商品ERP编码：{{ scope.row.shopGoodsCode }}</div>
                  <!-- <div v-show="scope.row.packUnit">单位：{{scope.row.packUnit}}</div> -->
                  <div v-show="scope.row.origin">产地：{{ scope.row.origin }}</div>
                  <div class="chargeInLine"></div>
                </div>
              </div>
            </template>
            <template slot="cbyykInfo" slot-scope="scope">
              <div class="goodsInfo">
                <div class="chargeInLine"></div>
                <div v-if="scope.row.matchStatus >= 0">
                  <!-- -1匹配失败 0部分匹配 1完全匹配-->
                  <div class="chemName"
                    :class="isDiff(scope.row.chemName, scope.row.goodsBaseV2DTO.name) ? 'diffText' : ''">
                    {{ scope.row.goodsBaseV2DTO.name }}</div>
                  <div :class="isDiff(scope.row.specification, scope.row.goodsBaseV2DTO.specification) ? 'diffText' : ''">
                    {{ scope.row.goodsBaseV2DTO.specification }}</div>
                  <div :class="isDiff(scope.row.factory, scope.row.goodsBaseV2DTO.factory) ? 'diffText' : ''">
                    {{ scope.row.goodsBaseV2DTO.factory }}</div>
                  <div :class="isDiff(scope.row.nationalCode, scope.row.goodsBaseV2DTO.approvalNumber) ? 'diffText' : ''">
                    批准文号：{{ scope.row.goodsBaseV2DTO.approvalNumber }}</div>
                  <div v-show="scope.row.goodsBaseV2DTO.goodsCode">商城编码：{{ scope.row.goodsBaseV2DTO.goodsCode || '' }}
                  </div>
                  <div v-show="scope.row.goodsBaseV2DTO.origin">产地：{{ scope.row.goodsBaseV2DTO.origin }}</div>
                </div>
                <div class="chargeInLine" v-if="tabIndex == 0">
                  <div v-if="scope.row.matchStatus == 1">
                    <i class="el-icon-success" style="color: #67C239;"></i>
                    <span class="chargeStatus">完全匹配</span>
                  </div>
                  <div v-if="scope.row.matchStatus == 0">
                    <i class="el-icon-warning" style="color: #F5A300;"></i>
                    <span class="chargeStatus">部分匹配</span>
                  </div>
                  <div v-if="scope.row.matchStatus == -1">
                    <i class="el-icon-error" style="color: #f5222d;"></i>
                    <span class="chargeStatus">匹配失败</span>
                  </div>
                  <span class="pickText" @click="matchId(scope.row)">选择匹配</span>
                </div>
                <div class="chargeInLine" v-else></div>
              </div>
            </template>
            <template slot="image" slot-scope="scope">
              <span style="display:block;width:100px;height:100px">

                <el-image v-if="scope.row.goodsBaseV2DTO.image[0]" :src="scope.row.goodsBaseV2DTO.image[0]" alt=""
                  :preview-src-list="scope.row.goodsBaseV2DTO.image">

                </el-image>
              </span>
            </template>
            <template slot="erpProdDate" slot-scope="scope">
              <div v-if="scope.row.erpProdDate">生产日期：{{ scope.row.erpProdDate }}</div>
              <div v-if="scope.row.erpValidity">有效期至：{{ scope.row.erpValidity }}</div>
            </template>
            <template slot="handle" slot-scope="scope">
              <div class="handleView" v-if="tabIndex == 0">
                <el-link :underline="false" v-if="scope.row.matchStatus >= 0"
                  @click="makeSureMatch(scope.row)">确认匹配</el-link>
                <el-link :underline="false" v-if="scope.row.matchStatus <= 0" @click="addGoods(scope.row)">创建商品</el-link>
                <el-link :underline="false" @click="deleteOne(scope.row)">删除</el-link>
              </div>
              <div class="handleView" v-else-if="tabIndex == 1">
                <el-link :underline="false" @click="shelfSignal(scope.row)">商品上架</el-link>
                <el-link :underline="false" @click="cancelAssociationAction(scope.row)">取消匹配</el-link>
              </div>
              <div class="handleView" v-else-if="tabIndex == 2">
                <el-link :underline="false" @click="supplementary(scope.row, true)">查看上架详情</el-link>
              </div>
              <div class="handleView" v-else-if="tabIndex == 3">
                <el-link :underline="false" @click="rollbackGoods(scope.row)">恢复</el-link>
              </div>
            </template>
          </MyTable>
        </el-tab-pane>
      </el-tabs>

    </div>
    <UnitDialog :unit-list="unitList" :dialog-visible="dialogVisible" @submitCheck="submitCheck" />
    <!-- 批量导入 -->
    <el-dialog title="批量导入" :visible.sync="importVisible" width="30%">
      <div>
        <div class="box">
          <p>1: 先下载导入模板，按格式填写数据</p>
          <p style="color:orange">（注：Excel 中黄色为必填字段，其他为非必填字段，请按示例格式规范填写）</p>
          <el-button size="small" icon="el-icon-bottom" type="primary" @click="fileTemplate">下载模板</el-button>
        </div>
        <div class="box">
          <p>2: 选择保存的Excel文件，点击导入即可完成批量导入</p>
          <!-- <p style="color:orange">（注：仅限 xlsx 格式）</p> -->
          <el-upload class="upload-demo" accept=".xls" :show-file-list="false" :action="action" :on-success="success"
            multiple :headers="headers" :on-error="uploadError">
            <el-button size="small" icon="el-icon-top" type="primary">表格导入</el-button>
          </el-upload>
        </div>
      </div>
      
    </el-dialog>
    <!-- 匹配商品 -->
    <el-dialog title="选择商品" :visible.sync="goodsVisible" width="880px" :close-on-click-modal="false"
      :close-on-press-escape="false" :before-close="beforeClose">
      <div class="dialogBody">
        <p>商品名称：{{ params.chemName }}，规格：{{ params.specification }}，生产厂家：{{ params.factory }}</p>
        <p>以下列表中，请选择商品信息一致的商品（规格数字一样的描述不一样的，可视为一致），如果无匹配的商品，请从新增上架途径单独上架。</p>
        <div style="display:flex; flex:1;">
          <GoodsCard :params="params" :goodsData="goodsData" :activeIndex.sync="activeIndex" @choiceItem="choiceItem" />
        </div>
        <span slot="footer" class="footerBtn">
          <el-button size="small" @click="cancelSelect">取消</el-button>
          <el-button size="small" type="primary" @click="submit">提交</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ImgUpload from '@/components/imgUpload'
import GoodsCard from "./goodsCard"
import { getToken } from "@/utils/auth";
import { batchAssociations, undoDeleteRecords, cancelAssociation, allPublish, fileTemplate, targetDataPage, MatchDataConfirm, getList, goodsSkuSyncCaches, goodsBases, associations, goodsSkuSyncCachesPackUnit, quantity, deleteRecords, goodsSkuSyncCacheStatistics, goodsSkuSyncCachesPublish, goodsSkuSyncCacheStatisticsStates } from '@/api/release'
import { addImages } from '@/api/commodityreference'
import { getunitList } from '@/api/productManage/auditProduct'
import UnitDialog from '@/components/unitDialog'
import MyTable from '@/components/myTable'
export default {
  name: 'release',
  components: { MyTable, UnitDialog, GoodsCard, ImgUpload },
  data () {
    return {
      options: [{
        value: '1',
        label: '完全匹配'
      }, {
        value: '2',
        label: '部分匹配'
      }, {
        value: '3',
        label: '匹配失败'
      }],
      result: '',
      params: {}, // 当前行数据
      selections: [], // 选中的列表数据
      numberZero: 0,
      numberOne: 0,
      numberTwo: 0,
      waiBuId: '', // 外面的id
      choiceGoods: '', // 选中的商品
      activeIndex: null, // 商品选中索引
      goodsVisible: false, // 匹配商品
      goodsData: [
      ],
      importVisible: false,
      action: process.env.VUE_APP_BASE_API + '/drug/goods/goodsSkuImportRecords/file', // 批量导入地址
      resultdialogVisible: false,
      dialogVisible: false, // 单位
      tableIndex: 0,
      unitList: [],
      isLoading: false,
      operation: false, // table组件中的操作按钮
      selection: true, // 表格是否支持选择
      index: false, // 表格中的序号
      tabsList: [{ name: '待匹配', count: 0, state: 0 }, { name: '待上架', count: 0, state: 2 }, { name: '已上架', count: 0, state: 3 }, { name: '已删除', count: 0, state: 4 }],
      tabIndex: 0,
      tableData: [], // 表格数据
      tableOption: [ // table中的columns
        // { label: '编号', prop: 'goodsId' },
        { label: '本公司商品信息', prop: 'companyInfo', slot: true, minWidth: '25%' },
        { label: '云药库商品信息', prop: 'cbyykInfo', slot: true, minWidth: '25%' },
        { label: '云药库图片', prop: 'image', slot: true, minWidth: '10%' },
        { label: 'ERP同步日期', prop: 'erpProdDate', slot: true, minWidth: '20%' },
        { label: 'ERP同步价格', prop: 'erpPrice', minWidth: '10%' },
        { label: 'ERP同步库存', prop: 'erpStock', minWidth: '10%' },
        { label: '操作', prop: 'handle', slot: true, width: '100', align: 'center' },
        // { label: '匹配状态', prop: 'matchResultTypeStr', width: '160' },
        // { label: '商品来源', prop: 'syncSourceTypeStr' },
        // { label: '平台商品ID', prop: 'goodsId', width: '120' },
        // { label: '商品名称', prop: 'chemName', width: '200' },
        // { label: '生产厂家', prop: 'factory', minWidth: '200' },
        // { label: '产地', prop: 'factory' },
        // { label: '规格', prop: 'specification', minWidth: '200' },
        // { label: '条码', prop: 'barCode' },
        // { label: '批准文号', prop: 'nationalCode', minWidth: '200' },
        // { label: '最小包装单位', prop: 'packUnit', slot: true },
        // { label: '价格', prop: 'price', slot: true },
        // { label: '库存', prop: 'qty' },
        // { label: '匹配ID', prop: 'id', slot: true },
        // { label: '是否有图', prop: 'status' },
      ],
      page: { // 表格中的page
        total: 10,
        current: 1,
        size: 10
      },
      activeName: '0', // tab
      // 导入列表
      importOptions: [
        {
          value: '0',
          label: 'EXCEL导入'
        }, {
          value: '1',
          label: 'ERP对接'
        }
      ],
      // 匹配结果列表
      resultOptions: [
        {
          label: '待匹配',
          value: '0'
        },
        {
          label: '匹配成功',
          value: '1'
        }, {
          label: '国药准字缺失',
          value: '2'
        },
        {
          label: '未匹配到国药准字',
          value: '3'
        },
        {
          label: '匹配到国药准字',
          value: '4'
        },
        {
          label: '匹配失败',
          value: '100'
        }
      ],
      // 有无图片列表
      imgOptions: [
        {
          label: '有图',
          value: '1'
        },
        {
          label: '无图',
          value: '2'
        }
      ],
      chemName: '',
      barCode: '',
      factory: '',
      nationalCode: '',
      syncSourceType: '',
      matchResultType: '',
      shopGoodsCode: '',
      matchResult: {
        success: 0,
        noNationalCode: 0,
        nationalCodeReject: 0,
        nationalCodeMany: 0,
        reject: 0,
      }
    };
  },
  computed: {
    headers: function () {
      return {
        Authorization: 'Bearer ' + getToken(),
      }
    },
  },
  watch: {

  },
 
  methods: {
    getNeedFreshList () {
      this.query()
      this.goodsSkuSyncCacheStatisticsStates()
    },
    beforeClose (done) {
      // 取消选择商品
      this.activeIndex = null
      done()
    },
    cancelSelect () {
      // 取消选择商品
      this.activeIndex = null
      this.goodsVisible = false
    },
    // 全部上架
    allPublishAction () {
      let _this = this
      this.$confirm("此操作会将全部商品上架, 是否确认操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        allPublish().then((resp) => {
          // _this.$message.success('上架成功')
          if (resp && resp.customCode == -1) {
            // this.$message.success(res.msg + '')
            this.$message({
              type: 'warning',
              dangerouslyUseHTMLString: true,
              message: `<p>${resp.msg}</p><br/>${resp.data}`
            });
          } else {
            this.$message.success('上架成功')
          }

          _this.getNeedFreshList()
        })
      });

    },
    cancelAssociationAction (e) {
      cancelAssociation(e).then(() => {
        this.$message.success('取消匹配成功')
        this.getNeedFreshList()
      })

    },
    async rollBackAction (arr) {
      // 恢复删除数据
      let tmp = []
      arr.forEach((e) => {
        tmp.push(e.id)
      })
      let obj = {
        cacheIdSet: tmp
      }
      const resp = await undoDeleteRecords(obj)
      if (resp) {
        this.$message.success('恢复成功')
        this.getNeedFreshList()
      }
    },
    rollbackGoods (e) {
      this.rollBackAction([e])
    },
    rollBack () {
      if (!this.selections.length) {
        this.$message.warning('请先选择数据')
        return
      }
      this.rollBackAction(this.selections)
    },
    makeSureMatch (item) {
      // 确认匹配
      let _this = this
      this.$confirm("此操作匹配商品, 是否确认操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        _this.matchAction([{ goodsId: item.goodsBaseV2DTO.id, skuCacheId: item.id }])
      });
    },

    async matchAction (arr) {
      let obj = {
        list: arr
      }
      batchAssociations(obj).then((res) => {
        this.$message.success('操作成功')
        this.getNeedFreshList()
      })
    },

    isDiff (oldValue, newValue) {
      if (oldValue == newValue) {
        return false
      }
      return true
    },
    // 上传成功
    onSuccessUpload (row) {
      this.params = row
      this.$nextTick(() => {
        this.concatImage()
      })
    },
    onRemoveHandler (row) {
      this.params = row
      // const params = {
      //   goodsId: this.params.goodsId,
      //   image: ''
      // }
      // addImages(params).then((res) => {
      //   if (res.code == 0) {
      //     this.$message.success('删除成功')
      //   }
      // })
    },
    // 关联商品和图片
    concatImage () {
      const params = {
        goodsId: this.params.goodsId,
        image: this.params.goodsBaseV2DTO.image[0]
      }
      addImages(params).then((res) => {
        if (res.code == 0) {
          this.$message.success('上传成功')
        }
      })
    },
    // 选中商品
    choiceItem (item) {
      this.choiceGoods = item
    },
    // 选择商品 提交
    submit () {
      if (this.activeIndex == null) {
        this.$message.warning('请选择商品')
        return
      }
      this.goodsVisible = false
      let params = {
        goodsId: this.choiceGoods.id,
      }
      associations(this.waiBuId, params).then(res => {
        this.activeIndex = null
        this.$message.success('操作成功')
        this.goodsSkuSyncCacheStatisticsStates()
        this.query(1)
        this.goodsSkuSyncCacheStatistics()
      })
    },
    // 匹配ID
    matchId (row) {
      this.goodsVisible = true
      this.waiBuId = row.id
      this.params = row
      let params = {
        size: 100,
        current: 1,
        approvalNumber: row.nationalCode,
        skuCacheSpecification: row.specification,
        name: row.chemName,//
        factory: row.factory,//
        origin: row.origin,//
      }
      goodsBases(params).then(res => {
        this.goodsData = res
        if (this.goodsData.length > 0) {
          this.choiceGoods = this.goodsData[0]
        }
      })
    },
    // 下载模板
    fileTemplate () {
      fileTemplate().then(res => {
        window.location.href = res
      })
    },
    uploadError(err){
      const parseError = JSON.parse(err.message)
      const {code ,msg} = parseError
      if(code === 1){
        this.$message.error(msg)
      }
    },
    // 上传成功
    success (res) {
      if (res.code === 1) {
        this.$message.error(res.msg)
      } else {
        this.$message.success('上传成功')
        this.importVisible = false
        this.goodsSkuSyncCacheStatisticsStates()
        // this.getunitList()
        this.query(1)
        this.goodsSkuSyncCacheStatistics()
        // this.getmatchTask(this.clickId)
      }
    },
    // 获取单位字典
    getunitList () {
      getunitList().then(res => {
        if (res.code == 0) {
          this.unitList = res.data || []
          // this.tableData[0].packUnit = this.unitList.length > 0 ? this.unitList[0] : ''
        }
      })
    },
    // 选择单位
    unitDetail (row, index) {
      this.waiBuId = row.id
      this.tableIndex = index
      this.dialogVisible = true
    },
    // 确认选中的单位
    submitCheck (item) {
      this.tableData[this.tableIndex].packUnit = item
      let params = {
        packUnit: item
      }
      goodsSkuSyncCachesPackUnit(this.waiBuId, params).then(res => {
        this.$message.success('修改成功')
        this.getNeedFreshList()
      })
    },
    // 获取page
    getList () {

      this.getNeedFreshList()
    },
    // 选中
    handleSelectionChange (val) {
      this.selections = val
    },
    // 查询
    query (current) {
    
      if (current) {
        this.page.current = current
      }
      console.log(this.result, 'this.result')
      let params = {
        matchResult: this.result ? this.result : null,
        chemName: this.chemName ? this.chemName : null,
        barCode: this.barCode ? this.barCode : null,
        factory: this.factory ? this.factory : null,
        nationalCode: this.nationalCode ? this.nationalCode : null,
        syncSourceType: this.syncSourceType ? this.syncSourceType : null,
        matchResultType: this.matchResultType ? this.matchResultType : null,
        status: 0,
        size: this.page.size,
        current: this.page.current,
        shopGoodsCode: this.shopGoodsCode || null
      }
      if (this.tabIndex == 0) {
        params['state'] = 0
      } else if (this.tabIndex == 1) {
        params['state'] = 2
      } else if (this.tabIndex == 2) {
        params['state'] = 3
      } else if (this.tabIndex == 3) {
        params['status'] = 1
      }

      goodsSkuSyncCaches(params).then(res => {
        if (res.length > 0) {
          res.forEach(item => {
            if (item.goodsBaseV2DTO) {
              if (item.goodsBaseV2DTO.image) {
                item.goodsBaseV2DTO.image = item.goodsBaseV2DTO.image.split(',')
              } else {
                this.$set(item.goodsBaseV2DTO, 'image', [])
              }
            } else {
              this.$set(item, 'goodsBaseV2DTO', {})
              this.$set(item.goodsBaseV2DTO, 'image', [])
            }
          })
        }
        this.tableData = res
        // beAllowOperation
      })
      quantity(params).then(res => {
        this.page.total = Number(res)
      })
    },
    // 查询头部数据
    goodsSkuSyncCacheStatistics () {
      goodsSkuSyncCacheStatistics().then(res => {
        if (res.length > 0) {
          res.forEach(item => {
            switch (item.matchResultType) {
              case 1:
                this.matchResult.success = item.quantity;
                break;
              case 2:
                this.matchResult.noNationalCode = item.quantity;
                break;
              case 3:
                this.matchResult.nationalCodeReject = item.quantity;
                break;
              case 4:
                this.matchResult.nationalCodeMany = item.quantity;
                break;
              case 100:
                this.matchResult.reject = item.quantity;
                break;

            }
          })
          this.resultdialogVisible = true
        }

      })
    },
    // 重置
    reset () {
      this.result = ''
      this.shopGoodsCode = ''
      this.chemName = '',
        this.barCode = '',
        this.factory = '',
        this.nationalCode = '',
        this.syncSourceType = '',
        this.matchResultType = '',
        this.page.current = 1
    },
    // 单个上架
    shelfSignal (e) {
      this.shelfAction([e])
    },
    // 批量上架
    shelfAction (arr) {
      let params = {
        cacheIdSet: []
      }
      arr.forEach(item => {
        params.cacheIdSet.push(item.id)
      })
      goodsSkuSyncCachesPublish(params).then(res => {

        if (res && res.customCode == -1) {
          // this.$message.success(res.msg + '')
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: `<p>${res.msg}</p><br/>${res.data}`
          });
        } else {
          this.$message.success('上架成功')
        }


        this.query(1)
        this.goodsSkuSyncCacheStatisticsStates()
        this.goodsSkuSyncCacheStatistics()
        // this.goodsSkuSyncCacheStatistics()
      })
    },
    // 上架
    shelf () {
      if (!this.selections.length) {
        this.$message.warning('请先选择数据')
        return
      }
      this.shelfAction(this.selections)
    },
    // 重新匹配
    match () {
      let _this = this
      this.$confirm("此操作将批量匹配商品, 是否确认操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {

        let tmp = []
        _this.selections.forEach(item => {
          tmp.push({
            goodsId: item.goodsBaseV2DTO.id,
            skuCacheId: item.id
          })
        });
        if (!tmp.length) {
          _this.$message.warning('请选择匹配数据')
          return
        }
        _this.matchAction(tmp)
      });

    },
    // 批量导入商品
    importGoods () {
      this.importVisible = true
    },
    // 批量上传商品图片
    uploadPicture () {

    },

    // 导出商品
    deriveGoods () {

    },
    // 商品导入记录
    importRecord () {

    },
    // 删除
    deleteGoods () {
      if (this.selections.length > 0) {
        let _this = this
        this.$confirm("此操作将批量删除商品, 是否确认操作?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          _this.deleteAction(_this.selections)
        });
      } else {
        this.$message.warning('请先选择数据')
      }

    },
    deleteOne (e) {
      let _this = this
      this.$confirm("此操作将删除商品, 是否确认操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        _this.deleteAction([e])
      });
    },
    deleteAction (arr) {
      let params = {
        cacheIdSet: []
      }
      arr.forEach(item => {
        params.cacheIdSet.push(item.id)
      }
      )
      deleteRecords(params).then(res => {
        this.$message.success('删除成功')
        this.query(1)
        this.goodsSkuSyncCacheStatistics()
        this.goodsSkuSyncCacheStatisticsStates()
      })
    },
    // tab切换
    handleClick () {
      this.result = ''
      this.tableData = []  
      // console.log(this.tabIndex,'---this.tabIndex')
      this.$store.dispatch('tabStatus/setReleaseTab', this.tabIndex);
      this.query(1)
    },
    addGoods (e) {
      this.$router.push({ path: '/productManage/auditProduct-addGoods', query: { passedInfo: e } })

    },
    // 补充商品信息
    supplementary (row, editType) {
      console.log(row, 'row')
      // id=1713818636348076033&activeName=add&type=add
      this.$router.push({
        path: '/productManage/publishProduct-addGoodsToStore', query: {
          id: row.goodsBaseV2DTO.id,
          activeName: 'detail',
          type: 'detail',
        }
      })
    },
    resetTabList () {
      this.tabsList = [{ name: '待匹配', count: 0, state: 0 }, { name: '待上架', count: 0, state: 2 }, { name: '已上架', count: 0, state: 3 }, { name: '已删除', count: 0, state: 4 }]
    },
    // 查询未发布已发布数据
    goodsSkuSyncCacheStatisticsStates () {
      this.resetTabList()
      let params = {
        shopId: 0
      }
      goodsSkuSyncCacheStatisticsStates(params).then(res => {
        if (res.length > 0) {
          res.forEach(item => {
            this.tabsList.forEach((tab) => {
              if (tab.state == item.state) {
                tab.count = item.quantity || 0
              }
            })
          })
        } else {
          this.resetTabList()
        }
      })
    },
  },
  created () {
    let tab = this.$route.query.tab ? this.$route.query.tab : '0'
    this.tabIndex = tab 
    this.goodsSkuSyncCacheStatisticsStates()
    this.getunitList()
    this.query()
    this.goodsSkuSyncCacheStatistics()
  },
  mounted() {  
  
  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { 
  },
  beforeDestroy () { },
  destroyed () { },
  activated () {
    let tab = this.$route.query.tab ? this.$route.query.tab : '0'
    this.tabIndex = tab; 
    if(this.$store.state.tabStatus.releaseTab && this.$route.query.tab ==undefined){ 
      this.tabIndex = this.$store.state.tabStatus.releaseTab;
    } 
    this.goodsSkuSyncCacheStatisticsStates()
    this.getunitList()
    this.query()
    this.goodsSkuSyncCacheStatistics()
  },
}
</script>
<style lang='scss' scoped>
.comContent {
  .header {
    .title {
      height: 56px;
      line-height: 56px;
      font-size: 16px;
      font-weight: 700;
      color: #262626;
    }

    .headerBox {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .everyOneBox {
        width: 49.5%;
        padding: 15px;
        font-size: 14px;
        color: #595959;
        border: 1px solid #f5f5f5;
        border-radius: 4px;

        .number {
          font-size: 16px;
          color: #262626;
        }

        .secodeBox {
          width: 100%;
          margin-top: 15px;
        }
      }
    }
  }

  .search {
    display: flex;
    padding: 24px 0;

    .everyItem {
      margin-left: 8px;
    }
  }

  .buttonClass {
    .buttons {
      display: flex;
    }

    .buttonFont {
      padding-top: 15px;
      font-size: 14px;
      color: #595959;
    }
  }

  .bottom {
    margin-top: 16px;
    height: calc(100% - 128px);

    ::v-deep .el-tabs {
      height: 100%;

      .el-tabs__header {
        border-bottom: 1px solid #f5f5f5;
      }

      .el-tabs__content {
        height: calc(100% - 56px);

        .el-tab-pane {
          height: calc(100% - 45px);

          .activeBox {
            height: 100%;
          }
        }
      }
    }
  }

  ::v-deep .zt__table {
    .el-table {
      .el-table__body-wrapper {
        height: calc(100% - 65px);
      }
    }
  }
}

.el-button--primary.is-disabled {
  background: #f5222d;
  border-color: #f5222d;
}

.goodsInfo {
  padding-right: 12px;
  font-size: 14px;
  color: #595959;

  .chemName {
    font-weight: 600;
    font-size: 14px;
    color: #262626;
  }

  .pickText {
    text-decoration: underline;
    color: #262626;
    cursor: pointer;
    margin-left: 10px;
  }

  .chargeInLine {
    display: flex;
    align-items: center;
    height: 25px;

    .chargeStatus {
      font-size: 14px;
      color: #595959;
    }
  }

  .diffText {
    color: #f5222d;
  }
}

.handleView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footerBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dialogBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 60vh;
  overflow-x: hidden;
  position: relative;
}
</style>