<template>
  <el-dialog
    title="上传附件"
    :visible.sync="visible"
    :lock-scroll="true"
    width="618px"
    :before-close="handleClose"
  >
    <ossFileUpload
      ref="refPicFileUpload"
      v-model="picFileList"
      :limit="maxFileCount"
      :accept="picFileExt"
      :maxFileLength="10"
      :ossUpload="true"
      :ossPathDir="'shop/pic/'"
      style="margin-bottom: 10px"
      :disabled="disabled"
    >
    </ossFileUpload>

    <div class="dialog-footer">
      <el-button @click="handleClose" size="small">取消</el-button>
      <el-button type="primary" @click="handleSubmit" size="small"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import ossFileUpload from "@/components/ossFileUpload";
import { csoUploadInvoice } from "@/api/compliance/invoiceManager";
export default {
  name: "UploadTaxFileDialog",
  components: {
    ossFileUpload,
  },
  data() {
    return {
      picFileList: [],
      picFileExt: " .png, .jpg, .pdf,.jpeg",
      maxFileCount: 1,
      visible: false,
      disabled: false,
      id: "",
    };
  },
  methods: {
    handleClose() {
      this.$emit("close");
      this.visible = false;
      this.id = "";
      this.picFileList = [];
    },
    async handleSubmit() {
      let obj = {
        id: this.id,
        csoInvoiceUrl: "",
        csoInvoiceName: "",
      };
      this.picFileList.forEach((item) => {
        const response = item.response;
        if (response && response.data) {
          const { url, fileName } = response.data;
          obj.csoInvoiceUrl = url;
          obj.csoInvoiceName = fileName;
        }
      });
      const res = await csoUploadInvoice(obj);
      if (res) {
        this.$message.success("上传成功");
        this.$emit("onRefresh");
        this.handleClose();
      }
    },
    publicOpen(row) {
      this.visible = true;
      const { id } = row;
      this.id = id;
      this.$nextTick(() => {
        this.$refs.refPicFileUpload.init(); //初始化oss上传
      });
    },
  },
};
</script>

<style scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
