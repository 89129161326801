<template>
    <div class="main">
        <el-form ref="formTool" :inline="true">
            <div class="header">
                <div>
                    <el-input style="width: 130px" v-model="customerName" size="small" placeholder="客户名称" clearable/>
                    <el-input style="width: 130px;margin: 0px 16px;" v-model="customerAccount" size="small" placeholder="客户账号" clearable/>
                    <el-select v-model="customerType" style="width: 80px" size="small">
                        <el-option label="诊所" value="诊所" />
                    </el-select>
                    <el-button size="small" @click="getList('search')" type="primary" style="margin-left: 16px;">查询</el-button>
                </div>
                <el-button size="small" @click="makeFixPriceAction" type="primary">生成定价对象</el-button>
            </div>
        </el-form>
        <MyTable :table-data="tableData" :showpage="true" :selection="true" :page="page" :index="false"
            :table-option.sync="tableOption" @handleSelectionChange="handleSelectionChange" @page-change="getList">

        </MyTable>
    </div>
</template>
<script>
import MyTable from '@/components/myTable'
import {  getNoCheckShop } from "@/api/saleRule/productLine";
export default {
    components: { MyTable},
    props: {
        goodsId: {
            type: String,
            default: () => {
                return ''
            }
        },
        orgCode:{
            type: String,
            default: () => {
                return ''
            }
        },

    },
    mounted(){
        this.getList()
    },
    data() {
        return {
            customerName: "",
            customerAccount: "",
            tableData: [],
            tableOption: [
                { label: '客户名称', prop: 'shopName' },
                { label: '客户账号', prop: 'userName',width:"130px" },
                { label: '客户地址', prop: 'address' },
            ],
            page: {
                total: 10,
                current: 1,
                size: 10
            },
            checkedArr:[],
            customerType:'诊所'
        }
    },
    methods: {
        
        getList(searchType) {
            if (searchType && searchType == 'search') {
                this.page.current = 1
            }
            const params = {
                current: this.page.current,
                size: this.page.size,
                goodsId:this.goodsId,
                orgCode:this.orgCode,
                name:this.customerName,
                userName:this.customerAccount
            }
            getNoCheckShop(params).then(res => {
                if (res.records && res.records.length) {
                    this.tableData = res.records
                    this.page.total = res.total
                } else {
                    this.tableData = []
                }

            })
        },
        handleSelectionChange(e){
            this.checkedArr = e
        },
        makeFixPriceAction(){
            this.$emit('didClickMakeFix',this.checkedArr)
        }
    }
}
</script>
<style lang="scss">

.main {
    width: 100%;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px #EBEEF5 solid;
}
</style>