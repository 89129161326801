var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "form-content" },
        [
          _c(
            "el-form",
            {
              ref: "formTool",
              attrs: {
                "label-width": "140px",
                model: _vm.formTool,
                rules: _vm.rules,
                disabled: _vm.isDisabled,
              },
            },
            [
              _c(
                "el-row",
                [
                  _vm.formTool.rejectReason && _vm.formTool.status == "REJECT"
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "审核意见" } },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "450px",
                                  "margin-right": "10px",
                                },
                                attrs: {
                                  size: "small",
                                  type: "textarea",
                                  autosize: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.formTool.rejectReason,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formTool, "rejectReason", $$v)
                                  },
                                  expression: "formTool.rejectReason",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "促销标题", prop: "promotionTitle" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "450px",
                              "margin-right": "10px",
                            },
                            attrs: {
                              placeholder: "请输入",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formTool.promotionTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.formTool, "promotionTitle", $$v)
                              },
                              expression: "formTool.promotionTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "赠品商品选择", prop: "giftSkuId" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "plain" },
                              on: { click: _vm.chooseGoods },
                            },
                            [_vm._v("选择赠品")]
                          ),
                          _vm._l(_vm.goodsItem, function (item, key) {
                            return _c(
                              "div",
                              { key: key, staticClass: "goodList" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "img-div" },
                                  [
                                    _c("el-image", {
                                      staticClass: "l",
                                      attrs: {
                                        src: item.image
                                          ? item.image
                                          : _vm.avater,
                                        alt: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "r" }, [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "txt-line-1 name" },
                                      [_vm._v(_vm._s(item.goodsSkuName))]
                                    ),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "rr txt-line-1" },
                                      [_vm._v(_vm._s(item.specification))]
                                    ),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "rr txt-line-1" },
                                      [
                                        _vm._v(
                                          " 有效期至： " +
                                            _vm._s(
                                              item.expireTime
                                                ? item.expireTime.slice(0, 11)
                                                : "-"
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "赠送活动配置", prop: "type" } },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.changeRadioType },
                                  model: {
                                    value: _vm.formTool.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formTool, "type", $$v)
                                    },
                                    expression: "formTool.type",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { key: 1, attrs: { label: 1 } },
                                    [_vm._v("买满一定数量赠送")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { key: 2, attrs: { label: 2 } },
                                    [_vm._v("每买满一定数量赠送")]
                                  ),
                                ],
                                1
                              ),
                              _vm.formTool.type == 1
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { size: "small", type: "plain" },
                                      on: { click: _vm.addLevel },
                                    },
                                    [_vm._v("添加阶梯")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._l(_vm.formTool.configList, function (item, key) {
                            return _c(
                              "div",
                              { key: key },
                              [
                                _vm._v(" 买满 "),
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "positive-integer",
                                      rawName: "v-positive-integer",
                                    },
                                  ],
                                  staticStyle: {
                                    width: "140px",
                                    "margin-right": "10px",
                                    "margin-left": "10px",
                                  },
                                  attrs: {
                                    size: "small",
                                    placeholder: "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: item.everyQty,
                                    callback: function ($$v) {
                                      _vm.$set(item, "everyQty", $$v)
                                    },
                                    expression: "item.everyQty",
                                  },
                                }),
                                _vm._v(" 赠送 "),
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "positive-integer",
                                      rawName: "v-positive-integer",
                                    },
                                  ],
                                  staticStyle: {
                                    width: "140px",
                                    "margin-right": "18px",
                                    "margin-left": "10px",
                                  },
                                  attrs: {
                                    size: "small",
                                    placeholder: "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: item.giftQty,
                                    callback: function ($$v) {
                                      _vm.$set(item, "giftQty", $$v)
                                    },
                                    expression: "item.giftQty",
                                  },
                                }),
                                _vm.formTool.configList.length > 1
                                  ? _c("el-button", {
                                      attrs: {
                                        size: "small",
                                        type: "danger",
                                        icon: "el-icon-delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeLevel(key)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "赠品数量限制", prop: "giftStock" } },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.formTool.giftStockCheck,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formTool,
                                        "giftStockCheck",
                                        $$v
                                      )
                                    },
                                    expression: "formTool.giftStockCheck",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { key: 1, attrs: { label: true } },
                                    [_vm._v("按赠品可用库存")]
                                  ),
                                ],
                                1
                              ),
                              _c("el-input", {
                                staticStyle: {
                                  width: "140px",
                                  "margin-left": "10px",
                                },
                                attrs: {
                                  size: "small",
                                  placeholder: "",
                                  clearable: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.formTool.giftStock,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formTool, "giftStock", $$v)
                                  },
                                  expression: "formTool.giftStock",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "单店赠送上限",
                            prop: "purchaseUpperCheck",
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "140px",
                                  "margin-left": "10px",
                                },
                                attrs: {
                                  size: "small",
                                  placeholder: "",
                                  clearable: "",
                                },
                                on: { input: _vm.changeCheck2 },
                                model: {
                                  value: _vm.formTool.purchaseUpperLimit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formTool,
                                      "purchaseUpperLimit",
                                      $$v
                                    )
                                  },
                                  expression: "formTool.purchaseUpperLimit",
                                },
                              }),
                              _c(
                                "el-checkbox",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  on: { input: _vm.changeCheck },
                                  model: {
                                    value: _vm.formTool.purchaseUpperCheck,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formTool,
                                        "purchaseUpperCheck",
                                        $$v
                                      )
                                    },
                                    expression: "formTool.purchaseUpperCheck",
                                  },
                                },
                                [_vm._v("不限")]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "tips" }, [
                            _vm._v(
                              " 单个药店赠送上限：一个药店在下方设定的周期内可赠送的最高数量 "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上限刷新周期",
                            prop: "upperLimitRefreshCycle",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { placeholder: "请选择", size: "small" },
                              model: {
                                value: _vm.formTool.upperLimitRefreshCycle,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formTool,
                                    "upperLimitRefreshCycle",
                                    $$v
                                  )
                                },
                                expression: "formTool.upperLimitRefreshCycle",
                              },
                            },
                            _vm._l(_vm.upperLimitRefreshCycle, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上限启用时间",
                            prop: "upperLimitStartTime",
                          },
                        },
                        [
                          _vm.formTool.upperLimitRefreshCycle == 1
                            ? _c("el-date-picker", {
                                attrs: {
                                  "picker-options": _vm.pickerOptions_day,
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "请选择",
                                  size: "small",
                                },
                                model: {
                                  value: _vm.formTool.upperLimitStartTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formTool,
                                      "upperLimitStartTime",
                                      $$v
                                    )
                                  },
                                  expression: "formTool.upperLimitStartTime",
                                },
                              })
                            : _vm._e(),
                          _vm.formTool.upperLimitRefreshCycle == 2
                            ? _c("el-date-picker", {
                                attrs: {
                                  "picker-options": _vm.pickerOptions_day,
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "请选择",
                                  size: "small",
                                },
                                model: {
                                  value: _vm.formTool.upperLimitStartTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formTool,
                                      "upperLimitStartTime",
                                      $$v
                                    )
                                  },
                                  expression: "formTool.upperLimitStartTime",
                                },
                              })
                            : _vm._e(),
                          _vm.formTool.upperLimitRefreshCycle == 3
                            ? _c("el-date-picker", {
                                attrs: {
                                  "picker-options": _vm.pickerOptions_day,
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "请选择",
                                  size: "small",
                                },
                                model: {
                                  value: _vm.formTool.upperLimitStartTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formTool,
                                      "upperLimitStartTime",
                                      $$v
                                    )
                                  },
                                  expression: "formTool.upperLimitStartTime",
                                },
                              })
                            : _vm._e(),
                          _vm.formTool.upperLimitRefreshCycle == 4
                            ? _c("el-date-picker", {
                                attrs: {
                                  "picker-options": _vm.pickerOptions_week,
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "请选择",
                                  size: "small",
                                },
                                model: {
                                  value: _vm.formTool.upperLimitStartTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formTool,
                                      "upperLimitStartTime",
                                      $$v
                                    )
                                  },
                                  expression: "formTool.upperLimitStartTime",
                                },
                              })
                            : _vm._e(),
                          _vm.formTool.upperLimitRefreshCycle == 5
                            ? _c("el-date-picker", {
                                attrs: {
                                  "picker-options": _vm.pickerOptions_month,
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "请选择",
                                  size: "small",
                                },
                                model: {
                                  value: _vm.formTool.upperLimitStartTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formTool,
                                      "upperLimitStartTime",
                                      $$v
                                    )
                                  },
                                  expression: "formTool.upperLimitStartTime",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tips",
                          staticStyle: {
                            "margin-left": "140px",
                            "margin-bottom": "10px",
                          },
                        },
                        [
                          _vm._v(
                            " 上限启用时间根据周期而定：活动期内- 上架时间起生效；单笔 - 设置后立即生效；"
                          ),
                          _c("br"),
                          _vm._v(
                            " 每天 - 设置当天00:00起效；每周 - 设置当周周一00:00起效；每月 - 设置当月1日00:00起效 "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _vm.formTool.giftSkuId
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "date", label: "促销时间" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "picker-options": _vm.formTool.giftSkuId
                                    ? _vm.pickerOptionsGift
                                    : null,
                                  type: "datetimerange",
                                  size: "small",
                                  "range-separator": "-",
                                  "start-placeholder": "开始时间",
                                  "end-placeholder": "结束时间",
                                  disabled:
                                    _vm.fromPage == "edit" ? true : false,
                                  "default-time": ["00:00:00", "23:59:59"],
                                },
                                model: {
                                  value: _vm.formTool.date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formTool, "date", $$v)
                                  },
                                  expression: "formTool.date",
                                },
                              }),
                              _c("div", { staticClass: "tips" }, [
                                _vm._v(
                                  " 促销时间不得超过以下任一时间范围：原品活动时间范围、赠品活动时间范围 "
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动图片" } },
                        [
                          _c("imgUpload", {
                            attrs: { limit: 1, isEdit: _vm.isDisabled },
                            model: {
                              value: _vm.formTool.imageList,
                              callback: function ($$v) {
                                _vm.$set(_vm.formTool, "imageList", $$v)
                              },
                              expression: "formTool.imageList",
                            },
                          }),
                          _c("div", { staticClass: "tips" }, [
                            _vm._v(
                              " (图片格式PNG,JPG,JPEG , 800*800px, 建议不要超过1M)。 "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "flex-box-bt" }, [
            _c(
              "div",
              { staticClass: "submit-btn" },
              [
                !_vm.isDisabled
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.submit },
                      },
                      [_vm._v("提交")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "plain" },
                    on: { click: _vm.goBack },
                  },
                  [_vm._v("返回上一页")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "选择赠品",
            top: "10vh",
            width: "1000px",
            "before-close": _vm.beforeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("fullGiftClassification", {
                ref: "chooseGoods",
                attrs: { status: _vm.formTool.status },
                on: { choice: _vm.choice },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tips tips-top" }, [
      _c("div", [
        _c("span", [_vm._v("配置注意：")]),
        _vm._v(
          "配置新的赠品活动前，请先确认erp是否支持0元开单、开票，如果不支持，请先不要上架“赠送平台实际商品”的赠品活动，否则会导致erp开单失败，影响发货。"
        ),
        _c("br"),
      ]),
      _c("div", [_vm._v(" 赠品活动下架条件：1、赠品可用库存；"), _c("br")]),
      _c("div", [_vm._v(" 赠送数量；2、促销时间结束；"), _c("br")]),
      _c("div", [
        _vm._v(
          " 注意：活动期间，不可以编辑修改活动内容，您可以先手动下架该活动，然后再编辑修改。"
        ),
        _c("br"),
      ]),
      _c("div", [
        _vm._v(
          " 活动图片需要平台审核，审核通过后，促销图片将在赠品活动上架期间替换原商品的列表页和详情页主图显示给客户。"
        ),
        _c("br"),
      ]),
      _c("div", [
        _vm._v(
          " 赠品商品在活动期间是在售商品时，请合理控制库存，尽量减少同商品编码的在售活动数，防止超卖或库存不足。"
        ),
        _c("br"),
      ]),
      _c("div", [
        _vm._v(
          " 赠品数量设置为“赠送数量上限”时，需要填写活动最大赠送数量，赠送商品数量达到此上限时，会下架该赠品活动；设置为“按赠品商品库存”时，赠品数量会按照原赠品的库存数量进行扣减。"
        ),
        _c("br"),
      ]),
      _c("div", [
        _vm._v(
          " 累计买满一定数量赠送，购买数量达到一定值，获得此数量对应的赠送量；"
        ),
        _c("br"),
      ]),
      _c("div", [
        _vm._v(
          " 每买满一定数量进行赠送，例如每买满10赠送1个：买10赠1、买20赠2、买30赠3，依次类推。"
        ),
        _c("br"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }