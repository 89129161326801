
<!--  -->
<template>
  <div class="comContent">
    <div class="title">
      <div>
        <el-form ref="formTool"
                 :model="formTool"
                 :inline="true">
          <el-form-item label="登录用户名"
                        prop="username">
            <el-input v-model="formTool.username"
                      size="small"
                      placeholder="请输入登录用户名" />
          </el-form-item>
          <el-form-item label="用户姓名"
                        prop="fullName">
            <el-input v-model="formTool.fullName"
                      size="small"
                      placeholder="请输入用户姓名" />
          </el-form-item>
          <el-form-item label="联系方式"
                        prop="phone">
            <el-input v-model="formTool.phone"
                      size="small"
                      placeholder="请输入联系方式" />
          </el-form-item>
        </el-form>
      </div>
      <div>
        <MyButton type="primary"
                  text="查询"
                  @click="goSearch" />
        <el-button size="small"
                   @click="reset()">重置</el-button>
        <MyButton type="primary"
                  icon="el-icon-plus"
                  text="添加用户"
                  @click="userEdit(false,'add')" />
      </div>
    </div>
    <div class="center">
      <MyTable :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getList"
               @handleSelectionChange="currentChange">
        <template slot="xuhao"
                  slot-scope="scope">
          <span>{{page.total -  (scope.$index + ((page.current - 1) * page.size))}}</span>
        </template>
        <template slot="caozuo"
                  slot-scope="scope">
          <!-- <el-button v-if="permissions.sys_role_add" type="text" icon="el-icon-view"
            @click="userEdit(scope.row,'see')">查看</el-button> -->
          <div style="display:flex">
            <el-link v-if="permissions.sys_role_edit"
                     type="primary"
                     :underline="false"
                     size="small"
                     @click="editUserInfo(scope.row,'edit')">编辑</el-link>
            <el-link type="primary"
                     style="margin-left:8px"
                     :underline="false"
                     size="small"
                     @click="resetPassword(scope.row,'edit')">重置密码</el-link>
            <el-link type="primary"
                     style="margin-left:8px"
                     :underline="false"
                     size="small"
                     @click="accountTransfer(scope.row,'edit')">账号转移</el-link>
          </div>

          <!-- <el-button v-if="permissions.sys_role_perm" type="text" size="small" icon="el-icon-plus"
            @click="handlePermission(scope.row, scope.index)">权限</el-button>
          <el-button v-if="permissions.sys_role_del" type="text" size="small" icon="el-icon-delete"
            @click="handleDelete(scope.row, scope.index)">删除</el-button> -->
        </template>
      </MyTable>
      <el-dialog :visible.sync="dialogPermissionVisible"
                 :close-on-click-modal="false"
                 title="分配权限">
        <div class="dialog-main-tree">
          <el-tree ref="menuTree"
                   :data="treeData"
                   :default-checked-keys="checkedKeys"
                   :check-strictly="false"
                   :props="defaultProps"
                   :filter-node-method="filterNode"
                   class="filter-tree"
                   node-key="id"
                   highlight-current
                   show-checkbox
                   default-expand-all />
        </div>
        <div slot="footer"
             class="dialog-footer">
          <el-button type="primary"
                     @click="updatePermession(roleId)">更 新</el-button>
          <el-button type="default"
                     @click="cancal()">取消</el-button>
        </div>
      </el-dialog>
      <UserEdit ref="userEdit"
                @sure="userEditSure" />
      <el-dialog title="重置密码"
                 :visible.sync="dialogVisible"
                 width="30%">
        <span>是否将
          <span style="color:#F5222D">
            {{item.username}}
          </span>
          <span style="color:#F5222D">
            {{item.fullName}}
          </span>
          的账号密码重置为初始密码Aa123456
        </span>
        <span slot="footer"
              class="dialog-footer">
          <el-button size="small"
                     @click="dialogVisible = false">取 消</el-button>
          <el-button size="small"
                     :loading="loadingResst"
                     type="primary"
                     @click="sureSubmit()">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="账号转移"
                 :visible.sync="accountTransferDialog"
                 :before-close="accountTransferCancle"
                 width="50%">
        <el-form :model="ruleForm"
                 :rules="rules"
                 ref="ruleForm"
                 label-width="180px"
                 class="demo-ruleForm">
          <el-form-item label="现有登录用户名"
                        prop="oldUsername">
            <el-input type="text"
                      disabled
                      v-model="ruleForm.oldUsername"></el-input>
          </el-form-item>
          <el-form-item label="新的登录用户名"
                        prop="newUsername">
            <el-input type="text"
                      v-model="ruleForm.newUsername"></el-input>
          </el-form-item>
          <el-form-item label="再次输入新的登录用户名"
                        prop="newUsernameSure">
            <el-input v-model="ruleForm.newUsernameSure"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button size="small"
                     @click="accountTransferCancle()">取 消</el-button>
          <el-button size="small"
                     :loading="loadingResst"
                     type="primary"
                     @click="accountTransferSubmit()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import MyButton from '@/components/myButton'
import UserEdit from './userEdit.vue'
import MyTable from '@/components/myTable'
// import MyTabs from '@/components/myTabs'
// import MyTypes from '@/components/myTypes'
import { auditTypes } from '@/utils/enumeration'
import { auditCompanyList, resetPassword } from '@/api/auditcenter'
import { mapGetters } from 'vuex'
import { addObj, delObj, fetchList, putObj, sysRoles, transfer } from '@/api/systemUser'
// import { clearMenuCache, fetchMenuTree } from '@/api/system'

export default {
  components: { MyButton, MyTable, UserEdit }, // , MenuForm, MyTabs, MyTable, MyTypes
  data () {
    return {
      accountTransferDialog: false, // 账号转移
      ruleForm: {
        oldUsername: '',
        newUsername: '',
        newUsernameSure: '',
      },
      rules: {
        oldUsername: [
          { required: true, message: '请输入现有登录用户名', trigger: 'blur' }
        ],
        newUsername: [
          { required: true, message: '请输入新的登录用户名', trigger: 'blur' }
        ],
        newUsernameSure: [
          { required: true, message: '请输入新的登录用户名', trigger: 'blur' }
        ],
      },
      loadingResst: false,
      item: {},
      dialogVisible: false, // 重置密码
      addOrUpdateVisible: false,
      // 遮罩层
      loading: true,
      // 菜单表格树数据
      menuList: [],
      // 菜单树选项
      menuOptions: [],
      tableHight: 0,

      text: '',
      clearable: true,
      formTool: {
        username: '',
        fullName: '',
        phone: ''
      },
      operation: false,
      selection: false,
      index: false, // 不展示序号，自己重新定义了序号
      tableData: [],
      tableOption: [
        { label: '序号', prop: 'xuhao', slot: true, width: '100px' },
        { label: '登录用户名', prop: 'username' },
        { label: '用户姓名', prop: 'fullName' }, // 这里表示自定义列 , slot: true
        // { label: '在职状态', prop: 'roleCode' },
        { label: '联系方式', prop: 'phone' },
        // { label: '用户ID', prop: 'userCode' },
        // { label: '角色', prop: 'roleDesc' },
        { label: '创建时间', prop: 'createTime' },
        // { label: '状态', prop: 'status', slot: true }
        { label: '操作', prop: 'caozuo', slot: true, width: '220px' }
      ],
      page: { total: 10, current: 1, size: 10 },
      treeData: [],
      checkedKeys: [],
      checkedDsScope: [],
      defaultProps: {
        label: 'name',
        value: 'id',
      },
      roleId: undefined,
      roleCode: undefined,
      rolesOptions: undefined,
      dialogPermissionVisible: false,
    }
  },
  computed: {
    ...mapGetters(['permissions']),
  },
  watch: {},
  created () {
    this.getList()
  },
  mounted () { },
  methods: {
    // 取消账号转移
    accountTransferCancle () {
      this.$refs.ruleForm.resetFields()
      this.accountTransferDialog = false
    },
    // 确定账号转移
    accountTransferSubmit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.ruleForm.newUsernameSure == this.ruleForm.newUsername) {
            let params = {
              oldUsername: this.ruleForm.oldUsername,
              newUsername: this.ruleForm.newUsername,
            }
            transfer(params).then(res => {
              if (res.code == 0) {
                this.getList()
                this.$message.success('账号转移成功')
                this.$refs.ruleForm.resetFields()
                this.accountTransferDialog = false
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            this.$message.error('两次输入的新的登录用户名不一致')
          }
        }
      })
    },
    // 账号转移
    accountTransfer (row) {
      this.ruleForm.oldUsername = row.username
      this.accountTransferDialog = true
      // this.ruleForm.fullName = row.fullName
    },
    // 重置密码
    resetPassword (row) {
      // console.log(row, 'row')
      this.item = row
      this.dialogVisible = true
    },
    // 确认修改密码
    sureSubmit () {
      let params = {
        userId: this.item.userId
      }
      this.loadingResst = true
      resetPassword(params).then(res => {
        if (res.code == 0) {
          this.loadingResst = false
          this.$message.success('重置密码成功')
          this.dialogVisible = false
        } else {
          this.loadingResst = false
          this.$message.error(res.msg)
          this.dialogVisible = false
        }
      })
    },
    // 用户信息编辑
    editUserInfo (obj, type) {
      sysRoles(obj.userId).then(res => {
        obj.roleIdSet = []
        if (res.length > 0) {
          res.forEach(item => {
            obj.roleIdSet.push(item.roleId)
          })
        }
        this.$refs['userEdit'].init(obj, type)
      })
    },
    goSearch () {
      const { username, fullName, phone } = this.formTool
      // console.log(username, fullName, phone)
      if (username || fullName || phone) {
        this.page.current = 1
        this.getList()
      } else {
        this.$message.warning('请输入至少一项检索内容')
      }
    },
    userEditSure () {
      // console.log(val)
      this.page.current = 1
      this.getList()
    },
    userEdit (obj, type) {
      this.$refs['userEdit'].init(obj, type)
    },
    addOrUpdateHandle (isEdit, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(isEdit, id)
      })
    },
    getList () {
      console.log(this.page, 'getList')
      const { current, size } = this.page
      let d = { current, size, ...this.formTool }
      this.listLoading = true
      fetchList(d).then((res) => {
        this.tableData = res.data.records
        this.page.total = res.data.total
        this.listLoading = false
      }).catch(() => {
        this.listLoading = false
      })
    },
    cancal () {
      this.dialogPermissionVisible = false
    },
    handlePermission (row) {
      fetchRoleTree(row.roleId).then((res) => {
        this.checkedKeys = res.data
        return fetchMenuTree()
      }).then((res) => {
        this.treeData = res.data
        // 解析出所有的太监节点
        this.checkedKeys = this.resolveAllEunuchNodeId(this.treeData, this.checkedKeys, [])
        this.dialogPermissionVisible = true
        this.roleId = row.roleId
        this.roleCode = row.roleCode
      })
    },
    updatePermession (roleId) {
      this.menuIds = ''
      this.menuIds = this.$refs.menuTree.getCheckedKeys().join(',').concat(',')
        .concat(this.$refs.menuTree.getHalfCheckedKeys().join(','))
      permissionUpd(roleId, this.menuIds).then(() => {
        this.dialogPermissionVisible = false
        // this.$store.dispatch('GetMenu', { type: false })
        this.$notify.success('修改成功')
      })
    },
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    resolveAllEunuchNodeId (json, idArr, temp) {
      for (let i = 0; i < json.length; i++) {
        const item = json[i]
        // 存在子节点，递归遍历;不存在子节点，将json的id添加到临时数组中
        if (item.children && item.children.length !== 0) {
          this.resolveAllEunuchNodeId(item.children, idArr, temp)
        } else {
          temp.push(idArr.filter((id) => id === item.id))
        }
      }
      return temp
    },
    handleDelete (row) {
      this.$confirm('是否确认删除名称为"' + row.roleName + '"' + '"的数据项?', '警告',
        { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }
      ).then(function () {
        return delObj(row.roleId)
      }).then(() => {
        this.page.current = 1
        this.getList()
        this.$notify.success('删除成功')
      })
    },
    reset () {
      this.formTool = { username: '', fullName: '', phone: '' }
      // this.$refs.formTool.resetFields()
      this.page.current = 1
      this.getList()
    },
    currentChange (val) {
      console.log('current', val)
    },
    // pageChange(val) {
    //   console.log('page', val)
    // },
    // handleSelectionChange(val) {
    //   console.log(val)
    // }
  }
}
</script>
<style lang='scss' scoped>
.comContent {
  padding: 0px;
  .title {
    height: 56px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f5f5ff;
    .el-form-item {
      margin-bottom: 0px !important;
    }
  }
  .center {
    margin: 0 24px;
    margin-top: 16px;
    height: calc(100% - 131px);
  }
}
</style>
