var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mainView" },
    [
      _c("div", { staticClass: "topView" }, [
        _c(
          "div",
          { staticClass: "buttonView", staticStyle: { display: "block" } },
          [
            _c("Tabs", {
              attrs: { tabsList: _vm.tabsList, activeName: _vm.activeName },
              on: {
                "update:activeName": function ($event) {
                  _vm.activeName = $event
                },
                "update:active-name": function ($event) {
                  _vm.activeName = $event
                },
                "tab-click": _vm.handleChange,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "buttonView", staticStyle: { "padding-top": "12px" } },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "mini" },
                on: { input: _vm.monthYearChange },
                model: {
                  value: _vm.tabPosition,
                  callback: function ($$v) {
                    _vm.tabPosition = $$v
                  },
                  expression: "tabPosition",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "weeks" } }, [
                  _vm._v("周统计"),
                ]),
                _c("el-radio-button", { attrs: { label: "month" } }, [
                  _vm._v("月统计"),
                ]),
                _c("el-radio-button", { attrs: { label: "year" } }, [
                  _vm._v("年统计"),
                ]),
                _c("el-radio-button", { attrs: { label: "custom" } }, [
                  _vm._v("自定义"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "buttonView", staticStyle: { overflow: "auto" } },
          [
            _vm.monthOrYear == "weeks"
              ? _c(
                  "div",
                  { staticClass: "tabsTeShu", staticStyle: { height: "56px" } },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.handleClickWeeks },
                        model: {
                          value: _vm.weeksSelectIndex,
                          callback: function ($$v) {
                            _vm.weeksSelectIndex = $$v
                          },
                          expression: "weeksSelectIndex",
                        },
                      },
                      _vm._l(_vm.weeksArr, function (item, index) {
                        return _c("el-tab-pane", {
                          key: index,
                          staticStyle: {
                            "font-size": "14px",
                            "font-weight": "400",
                          },
                          attrs: {
                            label: item.startDate + "至" + item.endDate,
                            name: item,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "tabsTeShu", staticStyle: { height: "56px" } },
              [
                _vm.monthOrYear == "year"
                  ? _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.handleClickYear },
                        model: {
                          value: _vm.yearCheck,
                          callback: function ($$v) {
                            _vm.yearCheck = $$v
                          },
                          expression: "yearCheck",
                        },
                      },
                      _vm._l(_vm.yearArr, function (item, index) {
                        return _c("el-tab-pane", {
                          key: index,
                          attrs: { label: item, name: item },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.monthOrYear == "month"
              ? _c(
                  "el-button",
                  {
                    staticStyle: {
                      "margin-right": "20px",
                      color: "#595959",
                      "font-weight": "600",
                      "font-size": "16px",
                    },
                    attrs: { type: "text" },
                  },
                  [_vm._v(_vm._s(_vm.year))]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "tabsTeShu", staticStyle: { height: "56px" } },
              [
                _vm.monthOrYear == "month"
                  ? _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.handleClick },
                        model: {
                          value: _vm.monthSelectIndex,
                          callback: function ($$v) {
                            _vm.monthSelectIndex = $$v
                          },
                          expression: "monthSelectIndex",
                        },
                      },
                      _vm._l(_vm.monthArr, function (item, index) {
                        return _c("el-tab-pane", {
                          key: index,
                          attrs: { label: item.str, name: index + "" },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.monthOrYear == "custom"
              ? _c(
                  "div",
                  [
                    _c("el-date-picker", {
                      attrs: {
                        size: "small",
                        type: "daterange",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      on: { change: _vm.changeDate },
                      model: {
                        value: _vm.customDate,
                        callback: function ($$v) {
                          _vm.customDate = $$v
                        },
                        expression: "customDate",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.activeName == "MERCHANT_REMOVAL"
        ? _c(
            "div",
            { ref: "goodsSalesStatMain", staticClass: "goodsSalesStatMain" },
            [
              _c(
                "div",
                { staticClass: "flexLineView mainHeight" },
                [
                  _c(
                    "div",
                    { staticClass: "leftHalf" },
                    [_c("highcharts", { attrs: { options: _vm.lineOptions } })],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      ref: "firstTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data:
                          _vm.tableDataChange.length != 0 ? [] : _vm.tableData,
                        height: _vm.tableHeight,
                        "highlight-current-row": "",
                      },
                      on: { "current-change": _vm.handleCurrentChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "rn", label: "排名", width: "50" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "goods_name", label: "药品名称" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "goods_factory", label: "生产企业" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "qty", label: "销售数量", width: "100" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "amount", label: "销售金额(元)" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "销售占比", width: "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "percentView" }, [
                                    _c("div", {
                                      staticClass: "percentBg",
                                      style: {
                                        width:
                                          _vm.getPercent(scope.row.pct) + "%",
                                      },
                                    }),
                                    _c("div", { staticClass: "percentText" }, [
                                      _vm._v(
                                        _vm._s(_vm.getPercent(scope.row.pct)) +
                                          "%"
                                      ),
                                    ]),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1103807461
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "dropDownBtn" },
                    [
                      _c(
                        "el-dropdown",
                        { on: { command: _vm.dropdownClickXiaoshou } },
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "default", size: "mini" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.controlsXiaoshou[
                                      _vm.controlSelectTypeXiaoshou
                                    ]
                                  )
                              ),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]
                          ),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "1" } },
                                [_vm._v("数量")]
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "2" } },
                                [_vm._v("金额")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-left": "10px",
                            color: "#262626",
                            "font-size": "16px",
                            "font-weight": "600",
                          },
                        },
                        [
                          _vm._v("排名"),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#595959",
                                "font-size": "12px",
                                "font-weight": "400",
                                "padding-left": "10px",
                              },
                            },
                            [_vm._v("  数据更新时间" + _vm._s(_vm.timeStr))]
                          ),
                        ]
                      ),
                      _c(
                        "el-dropdown",
                        {
                          staticStyle: { "margin-left": "10px" },
                          on: { command: _vm.dropdownClick },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "default", size: "mini" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.controls[_vm.controlSelectType])
                              ),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]
                          ),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "2" } },
                                [_vm._v("控销药")]
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "1" } },
                                [_vm._v("普药")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-autocomplete", {
                        staticStyle: {
                          "margin-left": "10px",
                          width: "180px",
                          position: "relative",
                          top: "2px",
                        },
                        attrs: {
                          size: "mini",
                          "fetch-suggestions": _vm.querySearchAsync,
                          placeholder: "药品名称",
                        },
                        on: { blur: _vm.blurAuto, select: _vm.handleSelect },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: item.goods_name,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "name",
                                          staticStyle: {
                                            overflow: "hidden",
                                            "white-space": "nowrap",
                                            "text-overflow": "ellipsis",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.goods_name) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3923182055
                        ),
                        model: {
                          value: _vm.state,
                          callback: function ($$v) {
                            _vm.state = $$v
                          },
                          expression: "state",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "numberView" }, [
                    _c("img", {
                      staticClass: "numberImg",
                      attrs: { src: _vm.number1, alt: "" },
                    }),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "lineView" }),
              _c("div", { staticClass: "flexLineView" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _vm.chartOptions
                      ? _c("highcharts", {
                          attrs: { options: _vm.chartOptions },
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "numberView" }, [
                      _c("img", {
                        staticClass: "numberImg",
                        attrs: { src: _vm.number2, alt: "" },
                      }),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "lineViewHorizon" }),
                _vm.categoryTableData.length
                  ? _c(
                      "div",
                      { staticClass: "secondTableView" },
                      [
                        _vm._m(0),
                        _c(
                          "el-table",
                          {
                            ref: "hilightTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.categoryTableData,
                              "highlight-current-row": "",
                              height: _vm.tableHeight - 38,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "rn", label: "排名", width: "50" },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "shop_type_name",
                                label: "客户类别",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "pay_shop_name",
                                label: "客户名称",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "erp_id",
                                label: "ERPID",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "qty",
                                label: "采购数量",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "price", label: "单价(元)" },
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "amount", label: "采购额(元)" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "numberView" }, [
                          _c("img", {
                            staticClass: "numberImg",
                            attrs: { src: _vm.number2, alt: "" },
                          }),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
      _vm.activeName == "ON_SALE" ? _c("salesStatistics") : _vm._e(),
      _vm.activeName == "PLATFORM_REMOVAL"
        ? _c("customerSales", { on: { getDataEmit: _vm.getData } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "classNameView" }, [
      _c(
        "span",
        {
          staticStyle: {
            "font-weight": "600",
            color: "#262626",
            "font-size": "16px",
            "margin-right": "10px",
          },
        },
        [_vm._v("采购用户")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }