<!-- 保证金充值审核-->
 
<template>
  <div class="content">

    <div class="header"><el-button size="small" @click="exportUrl()" class="export-btn">导出</el-button> </div>
    <div class="center" style="position: relative">
      <MyTable :table-data="tableData" :index="index" :selection="selection" :page="page" :operation="operation"
        :table-option.sync="tableOption" @page-change="getList" @handleSelectionChange="handleSelectionChange">
        <template slot="status" slot-scope="scope">
          <span v-if="scope.row.status == 'submit'">
            待审核
          </span>
          <span v-if="scope.row.status == 'pass'">
            审核通过
          </span>
          <span v-if="scope.row.status == 'fail'">
            驳回
          </span>
        </template>
        <template slot="menu" slot-scope="scope">
          <el-link :underline="false" type="primary" @click="detailHandle(scope.row, '详情')" class="btn-list">详情</el-link>
          <el-link :underline="false" type="primary" @click="detailHandle(scope.row, '查看电子凭证')"
            v-if="scope.row.status == 'pass'" class="btn-list">查看电子凭证</el-link>
        </template>
        <template slot="amount" slot-scope="scope">
          <span>￥ {{ scope.row.amount ? parseFloat(scope.row.amount).toFixed(2) : '0.00' }}</span>
        </template>

      </MyTable>
    </div>
    <addDialog ref="add-dialog" @getList="getList" />
  </div>
</template>
    
<script>

import { guaranteeApproveInfo, historyApprovePage, fileExport } from "@/api/surety";

import MyTable from "@/components/myTable";
import addDialog from "./components/chargeMoney.vue";
import avater from "/public/img/default.png";
export default {
  components: { MyTable, addDialog },
  data () {
    return {
      activeName: 'first',
      avater: avater,
      formTool: {
      },
      btnType: 'add',
      selectionList: [],
      operation: true,
      selection: false,
      index: true,
      tableData: [],
      tableOption: [
        { label: "申请ID", prop: "bizNo" },
        { label: "申请金额", prop: "amount", slot: true },
        { label: "审核状态", prop: "status", slot: true },
        { label: "审核时间", prop: "approveTime" },
        { label: "提交审核时间", prop: "createTime" },
        { label: "审核意见", prop: "remarks" },
      ],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      dialogShow: false
    };
  },
  computed: {},
  filters: {

    // filterLayout(e) {
    //   for (let i = 0; i < showOptions.length; i++) {
    //     if (e == showOptions[i].value) return showOptions[i].label
    //   }
    // },
  },
  watch: {},
  created () {
    this.getList();
  },
  mounted () { },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  computed: {

  },
  methods: {

    // 页码变化
    handleCurrentChange (e) {
      this.page.current = e
    },

    // 条数变化
    handleSizeChange (e) {
      this.page.size = e
      this.page.current = 1
    },

    downloadCert (approveId) {
      fileExport(`/shop/guarantee/merchant/approve/downloadCert?approveId=${approveId}`, 'GET', {}).then((res) => { }).catch((err) => {

      })
    },

    detailHandle (row, btnType) {
      if (btnType == '查看电子凭证') {
        this.downloadCert(row.id)
      }
      if (btnType == '详情') {
        this.$refs['add-dialog'].init(row, 'detail')
      }
    },

    // 重置查询条件
    reset () {
      this.formTool = {};
      this.getList(1);
    },

    exportUrl () {
      const params = {
        ...this.formTool,
        current: this.page.current,
        size: this.page.size
      };
      if (this.formTool.date) {
        params.startCreateTime = this.formTool.date[0];
        params.endCreateTime = this.formTool.date[1];
      }
      delete params.date;
      fileExport('/shop/guarantee/platform/approve/export', 'GET', params).then((res) => { }).catch((err) => {

      })
    },

    // 搜索
    getList (current) {

      if (current) {
        this.page.current = current;
      }
      const params = {
        ...this.formTool,
        current: this.page.current,
        size: this.page.size
      };

      historyApprovePage(params).then((res) => {
        if (res) {
          this.tableData = res.records;
          this.page.total = res.total;
        } else {
          this.tableData = [];
          this.page.total = 0;
        }
      });
    },
    // 勾选
    handleSelectionChange (val) {
      this.selectionList = val;
    },

  },
};
</script>
<style lang='scss' scoped> .content {
   height: calc(100% - 120px);

   .header {
     width: calc(100% - 12px);
     margin: 10px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     // padding: 0px 24px 0px 24px !important;

     position: relative;

     .font {
       font-size: 16px;
       font-family: PingFangSC, PingFangSC-Medium;
       font-weight: 600;
       text-align: left;
       color: #262626;
     }

     .el-form-item {
       margin: 0px 0px 0px 0px !important;
     }
   }

   .center {
     margin-top: 16px;
     padding: 0 0px;
     height: calc(100% + 35px);

     .shangjia {
       position: absolute;
       z-index: 2;
       bottom: -45px;
     }
   }

   .btn-warp {
     display: flex;
     justify-content: space-around;

   }
 }

 .btn-list {
   margin: 0 10px;
 }

 ::v-deep .el-table {
   height: calc(100% - 120px) !important;
   overflow: auto;
 }

 .export-btn {
   width: 102px;
   position: absolute;
   top: -50px;
   right: 14px;
 }
</style>
    