<template>
  <div class="dialog">
    <el-dialog
      title="选择商品"
      :visible.sync="visible"
      width="86%"
      :close-on-click-modal="false"
      :before-close="cancel"
    >
      <div class="content">
        <el-form
          ref="searchForm"
          :model="searchForm"
          :inline="true"
          style="text-align: left"
        >
          <el-form-item prop="goodsName">
            <el-input
              style="width: 160px"
              v-model="searchForm.goodsName"
              placeholder="请输入商品名称"
              clearable
              size="small"
            />
          </el-form-item>
          <el-form-item prop="factory">
            <el-input
              v-model="searchForm.factory"
              style="width: 160px"
              placeholder="请输入生产厂家"
              clearable
              size="small"
            />
          </el-form-item>
          <el-form-item prop="approvalNumber">
            <el-input
              v-model="searchForm.approvalNumber"
              style="width: 160px"
              placeholder="请输入批准文号"
              clearable
              size="small"
            />
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="search(1)"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="reset('searchForm')"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
        <div v-if="goodsList && goodsList.length > 0">
          <goodsItem :goods-list="goodsList" @chooseItem="chooseItem" />
          <div class="footer">
            <Pagination
              :total="page.total"
              :page-sizes="page.pageSizes"
              :page.sync="page.current"
              :limit.sync="page.size"
              @pagination="pageChange"
            />
            <div class="btnBox">
              <el-button size="small" @click="cancel">取消</el-button>
              <el-button type="primary" size="small" @click="chooseGoods"
                >提交</el-button
              >
            </div>
          </div>
        </div>
        <div class="noData" v-else>
          没有内容~
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import goodsItem from "./goodsItem";
import Pagination from "@/components/Pagination";
import { getShopGroupable } from "@/api/spellGroup/spellGroupGoods";
export default {
  components: { goodsItem, Pagination },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchForm: {
        goodsName: "",
        factory: "",
        approvalNumber: "",
      },
      goodsList: [],
      page: {
        current: 1,
        size: 10,
        total: 10,
      },
      selItem: {},
    };
  },
  computed: {
    visible() {
      return this.dialogVisible;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const params = {
        current: this.page.current,
        size: this.page.size,
        ...this.searchForm,
      };
      getShopGroupable(params).then((res) => {
        if (+res.code === 0) {
          this.goodsList = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    pageChange(value) {
      this.page.size = value.limit;
      this.page.current = value.page;
      this.getList();
    },
    cancel() {
      this.$parent.dialogVisible = false;
      this.$refs["searchForm"].resetFields();
    },
    chooseGoods() {
      if(this.selItem && this.selItem.goodsId) {
        this.$emit("chooseGoodsItem", this.selItem);
        this.$parent.dialogVisible = false;
      } else {
        this.$message.warning("请先选择商品")
      }
    },
    search(current) {
      if (current) {
        this.page.current = current;
      }
      this.getList();
    },
    reset(formName) {
      this.$refs[formName].resetFields();
      this.getList();
    },
    // 选择商品
    chooseItem(index) {
      this.selItem = this.goodsList[index];
    }
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: space-between;
}
.noData {
  text-align: center;
  padding: 30px 0;
  color: #333;
}
</style>
