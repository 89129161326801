var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "companyWarp" }, [
    _c(
      "div",
      { staticClass: "formView" },
      [
        _c(
          "el-form",
          {
            ref: "loginForm",
            attrs: {
              model: _vm.loginForm,
              "label-width": "100px",
              rules: _vm.rules,
            },
          },
          [
            _c(
              "el-form-item",
              { staticClass: "formLabel", attrs: { label: "销售方税号" } },
              [
                _c("el-input", {
                  staticClass: "formInput",
                  attrs: { placeholder: "请输入", size: "small", disabled: "" },
                  model: {
                    value: _vm.loginForm.sellerTaxpayerId,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "sellerTaxpayerId", $$v)
                    },
                    expression: "loginForm.sellerTaxpayerId",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "formLabel",
                attrs: { label: "用户名", prop: "account" },
              },
              [
                _c("el-input", {
                  staticClass: "formInput",
                  attrs: { placeholder: "请输入", size: "small" },
                  model: {
                    value: _vm.loginForm.account,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "account", $$v)
                    },
                    expression: "loginForm.account",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "formLabel",
                attrs: { label: "密码", prop: "password" },
              },
              [
                _c("el-input", {
                  staticClass: "formInput",
                  attrs: {
                    placeholder: "请输入",
                    size: "small",
                    "show-password": "",
                  },
                  model: {
                    value: _vm.loginForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "password", $$v)
                    },
                    expression: "loginForm.password",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "formLabel",
                attrs: { label: "责任人类型", prop: "personType" },
              },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "formInput",
                    attrs: { size: "small" },
                    model: {
                      value: _vm.loginForm.personType,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "personType", $$v)
                      },
                      expression: "loginForm.personType",
                    },
                  },
                  _vm._l(_vm.personTypeList, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.codeText, value: item.codeValue },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "formButton",
            staticStyle: { width: "calc(20vw + 100px)", "margin-top": "30px" },
            attrs: { type: "primary", loading: _vm.loginStatus == "logining" },
            on: {
              click: function ($event) {
                return _vm.login()
              },
            },
          },
          [_vm._v("验证并登录")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }