var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main" }, [
    _c("span", {
      staticClass: "arrUp",
      style: { "border-bottom-color": _vm.color1 },
      on: { click: _vm.clickUp },
    }),
    _c("span", {
      staticClass: "arrDown",
      style: { "border-top-color": _vm.color2 },
      on: { click: _vm.clickDown },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }