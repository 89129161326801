<template>
  <div class="qualification-page-root comContent">
    <template v-if="state === 1">
      <QualificationStep :current-index="currentIndex" />
    </template>
    <template v-else-if="state === 2">
      <QualificationResult @change-info="handleChangeInfo" />
    </template>
  </div>
</template>

<script>
import QualificationStep from "./qualificationStep";
import QualificationResult from "./qualificationResult";

import { getCertifyDetail } from "@/api/myQualification/index";

import { IConst_State, viewHelp } from "./util/ViewHelp";
import _ from "lodash";

export default {
  components: { QualificationStep, QualificationResult },
  data() {
    return {
      state: -1, //1 申请 2 结果
      currentIndex: 1,
    };
  },
  created() {
    this.doLoadCertifyDetail(); 
  },
  methods: {
    handleChangeInfo() {
      this.state = 1;
    },

    doLoadCertifyDetail() {
      const params = {};

      getCertifyDetail(params).then((res) => {
        if (res.code == 0) {
          //  res.data = null; // 测试
          //debugger;
          if (res.data) {
            // res.data.status = IConst_State.Sucess; //测试

            const shopDetail = res.data;

            //信息填写详情，已经申请
            this.$store.dispatch(
              "qualification/setDetail",
              _.cloneDeep(shopDetail)
            );

            //保存下所选择的企业类型
            if (shopDetail.shopTypeCode) {
              let selectedCompanyTypes = [
                {
                  typeCode: shopDetail.shopTypeCode,
                  typeName: shopDetail.shopTypeName,
                },
              ];

              this.$store.dispatch(
                "qualification/setSelectedCompanyTypes",
                _.cloneDeep(selectedCompanyTypes)
              );
            }

            const status = shopDetail.status;
            
            if (
              status === IConst_State.Fail ||
              status === IConst_State.Sucess
            ) {
              this.state = 2;
             
              // this.currentIndex = 1;
            } else if (
              status === IConst_State.Wait ||
              status === IConst_State.Wait_20
            ) {
              this.state = 1;
              this.currentIndex = 3;
            } else { 
              //草稿状态
              this.state = 1; 
              // console.log(shopDetail,'--shopDetail')
              if(shopDetail.shopTypeCode == null || shopDetail.shopTypeCode == 'null'  || !shopDetail.id){
                this.currentIndex = 1;  
              }else{ 
                this.currentIndex = 2; 
              }
            }
           
          } else {
            //还未申请
            this.state = 1;
            this.currentIndex = 1;
          }
        } else {
          viewHelp.showErrorMsg(this.$message, res);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.qualification-page-root {
}
</style>
