<template>
  <div class="comContent">
    <div class="header" style="font-weight: 600;border-bottom: 1px solid #ededed;padding-bottom: 12px;">
      活动名称
    </div>
    <div class="center">
      <p style="font-weight: 400;">活动时间：{{ info.startTime ? info.startTime : '' }}~{{ info.endTime ?
        info.endTime :
        ''
      }}</p>
      <el-button size="small" type="primary" @click="active(info)" v-if="info.goodsFrom == 1">立即报名</el-button>
      <div class="content">
        <wangEnduit ref="wangEnduit" :value="info.description" :isDisabled="true" />
      </div>
    </div>
  </div>
</template>
  
<script>
import { activityInfo } from '@/api/platformActivity/index'
import wangEnduit from '@/components/wangEnduit'
export default {
  props: {},
  data () {
    return {
      id: '',
      status: '',
      info: {}
    }
  },
  components: { wangEnduit },
  async created () {
    this.id = this.$route.query.id || ''
    this.status = this.$route.query.status || ''
    await this.activityInfo()
  },
  mounted () {
    this.$refs.wangEnduit.editor.disable()
  },
  watch: {},
  methods: {
    // 报名跳转
    active (info) {
      this.$router.push({
        path: '/operationCenter/platformActivity-signUp',
        query: {
          id: info.id
        }
      })
    },
    // 查询详情
    async activityInfo () {
      let params = {
        id: this.id
      }
      const res = await activityInfo(params)
      if (res) {
        this.info = res
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.comContent {
  padding: 24px 24px 0 24px;

  .center {
    height: calc(100% - 67px);
  }

  .content {
    margin-top: 12px;
    height: calc(100% - 82px);

    .editor {
      height: 100%;

      ::v-deep .text {
        height: calc(100% - 42px);
      }
    }
  }
}
</style>
