var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "classification" }, [
    _vm._m(0),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c(
            "el-form",
            {
              ref: "formTool",
              staticStyle: { "text-align": "left" },
              attrs: { model: _vm.formTool, inline: true },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "24px !important" },
                  attrs: {
                    prop: "name",
                    label: "控销组名称",
                    rules: [{ required: true, message: "请输入控销组名称" }],
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入控销组名称",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.formTool.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "name", $$v)
                      },
                      expression: "formTool.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("el-tree", {
            ref: "tree",
            attrs: {
              data: _vm.regionTreedata,
              "show-checkbox": "",
              "node-key": "id",
              "default-checked-keys": _vm.defaultChecked,
              props: _vm.defaultProps,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v("保存并提交")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "12px" },
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.cancel()
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("span", { staticClass: "font" }, [_vm._v("控销地域设置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }