<template>
  <div class="goodsDetailDlg">
    <el-dialog
            title="商品明细数据"
            :visible.sync="dialogVisible"
            width="92%"
            :before-close="handleClose"
    >
      <div class="goods-info">
        <div class="title">商品信息</div>
        <div class="info">
          <div>
            <span class="color-59">商品编码：</span>
            <span class="color-26">{{ goodsDetail.goods_code }}</span>
          </div>
          <div>
            <span class="color-59">商品名称：</span>
            <span class="color-26">{{ goodsDetail.goods_name }}</span>
          </div>
          <div>
            <span class="color-59">厂家：</span>
            <span class="color-26">{{ goodsDetail.goods_factory }}</span>
          </div>
          <div>
            <span class="color-59">规格：</span>
            <span class="color-26">{{ goodsDetail.goods_specification }}</span>
          </div>
          <div>
            <span class="color-59">批准文号：</span>
            <span class="color-26">{{ goodsDetail.approval_number }}</span>
          </div>
        </div>
      </div>
      <div class="goods-detail">
        <div class="title">商品明细</div>
        <el-form ref="formTool" label-width="90px" :model="formTool" size="small">
          <el-row>
            <el-col :span="6">
              <el-form-item label="SKU编码：">
                <el-input v-model="formTool.factory" placeholder="请输入" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="display: flex">
            <el-radio-group v-model="tabPosition" size="mini" @input="monthYearChange">
              <el-radio-button label="week">本周</el-radio-button>
              <el-radio-button label="month">本月</el-radio-button>
              <el-radio-button label="year">本年度</el-radio-button>
            </el-radio-group>
            <el-form-item label="自定义时间：" label-width="120px">
              <el-date-picker
                      v-model="formTool.date"
                      value-format="yyyy-MM-dd"
                      type="daterange"
                      size="small"
                      range-separator="-"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      :picker-options="pickerOptions"
              />
            </el-form-item>
            <div class="btn-box">
              <el-button type="primary" size="small" @click="getList(1)">查 询</el-button>
              <el-button size="small" @click="handleReset">重 置</el-button>
              <el-button size="small" style="float: right" @click="handleExport"
              >导出</el-button
              >
            </div>
          </el-row>
        </el-form>
        <div
                class="center"
                :style="contentHeight ? `height:${contentHeight}px!important` : ''"
        >
          <MyTable
                  :table-data="tableData"
                  :index="index"
                  :selection="selection"
                  :page="page"
                  :operation="operation"
                  :table-option.sync="tableOption"
                  @page-change="handlePageChange"
                  @sort-change="sortChange"
          >
            <template slot="customer_price" slot-scope="scope">
              <div v-if="scope.row.order_shop_cnt && scope.row.amount">{{parseFloat(scope.row.amount / scope.row.order_shop_cnt).toFixed(2)}}</div>
            </template>
            <template slot="order_cvr" slot-scope="scope">
              {{scope.row.order_cvr ? parseFloat((scope.row.order_cvr * 100)).toFixed(4) + '%' : ''}}
            </template>
            <template slot="pay_cvr" slot-scope="scope">
              {{scope.row.pay_cvr ? parseFloat(scope.row.pay_cvr * 100).toFixed(4) + '%' : ''}}
            </template>
          </MyTable>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import MyTable from "@/components/myTable";
  import { queryGoodsSalesEcharts, excelExport } from "@/api/statistics";

  export default {
    components: { MyTable },
    data() {
      return {
        dialogVisible: false,
        formTool: {
          name: "",
          branch: "",
          date: [],
        },
        tableData: [],
        tabPosition: "year",
        operation: false,
        selection: false,
        index: false,
        contentHeight: 400,
        tableOption: [
          { label: "SKU编码", prop: "sku_code" },
          { label: "活动类型", prop: "activity_type_name" },
          { label: "价格", prop: "price", sortable: "custom" },
          { label: "库存", prop: "goods_sku_inventory"  },
          {
            label: "销量",
            prop: "qty",
            sortable: "custom",
            customHeader: "true",
            content: "支付成功订单销量合计",
            width: "100px",
          },
          {
            label: "销售额",
            prop: "amount",
            sortable: "custom",
            customHeader: "true",
            content: "支付成功订单销售额合计",
            width: "100px",
          },
          { label: "实付总金额", prop: "paid_amount"  },
          { label: "客单价", prop: "customer_price", slot: true  },
          {
            label: "平均每单购买量",
            prop: "avg_order_qty",
            sortable: "custom",
            customHeader: "true",
            content: "平均每单购买量=销量 / 采购订单数",
            width: "160px",
          },
          {
            label: "点击量",
            prop: "click_cnt",
            sortable: "custom",
            customHeader: "true",
            content: "该SKU被点击的次数",
            width: "100px",
          },
          {
            label: "访客量",
            prop: "shop_visit_cnt",
            sortable: "custom",
            customHeader: "true",
            content: "访问该SKU的客户数",
            width: "100px",
          },
          // {
          //   label: "加购客户数",
          //   prop: "add_cart_shop_cnt",
          //   sortable: "custom",
          //   customHeader: "true",
          //   content: "该SKU的加购客户数",
          //   width: "130px",
          // },
          {
            label: "采购客户数",
            prop: "order_shop_cnt",
            sortable: "custom",
            customHeader: "true",
            content: "该SKU支付成功的客户数",
            width: "130px",
          },
          {
            label: "采购订单数",
            prop: "order_cnt",
            sortable: "custom",
            customHeader: "true",
            content: "该SKU支付成功的订单数",
            width: "130px",
          },
          // {
          //   label: "订单转化率",
          //   prop: "order_cvr",
          //   sortable: "custom",
          //   customHeader: "true",
          //   content: "订单转化率=该SKU的订单数/该SKU的访客量*100%",
          //   width: "126px",
          //   slot: true
          // },
          // { label: "支付转化率", prop: "pay_cvr", sortable: "custom", width: "165px", slot: true,
          //   customHeader: "true",
          //   content: "该SKU支付成功订单数/该SKU订单数*100%",},
        ],
        page: {
          total: 0,
          current: 1,
          size: 10,
          pageSizes: [10,30, 50, 100, 500],
        },
        goodsDetail: {},
        type: 74,
        order: '3',
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e7
          }
        }
      };
    },
    computed: {
      shopId(){
        return this.$store.state.user.user_info.shopId;
      },
    },
    methods: {
      init(row, param) {
        this.dialogVisible = true;
        this.goodsDetail = {... row}
        // this.monthYearChange(this.tabPosition)
        const getParam = {...param}
        this.tabPosition = getParam.tabPosition
        this.formTool.date = getParam.date
        this.getList();
      },
      handleClose() {
        this.dialogVisible = false;
        this.handleReset()
      },
      handleReset() {
        this.formTool = {
          name: "",
          branch: "",
          date: [],
        };
        this.tabPosition = 'year'
        this.monthYearChange(this.tabPosition)
      },
      monthYearChange(val) {
        const now = new Date(); //当前日期
        const nowDayOfWeek = now.getDay(); //今天本周的第几天
        const nowDay = now.getDate(); //当前日
        const nowMonth = now.getMonth(); //当前月
        const nowYear = now.getFullYear(); //当前年
        this.formTool.date = []
        let startDate = ''
        let lastDate = ''
        if (val === 'week') {
          startDate = this.formatDate(new Date(nowYear, nowMonth, nowDay - (7 - nowDayOfWeek)))
          lastDate = this.formatDate(new Date(nowYear, nowMonth, nowDay))
        } else if (val === 'month') {
          startDate = this.formatDate(new Date(nowYear, nowMonth, 1))
          lastDate = this.formatDate(new Date(nowYear, nowMonth, nowDay))
        } else {
          startDate = this.formatDate(new Date(nowYear, 0, 1))
          lastDate = this.formatDate(new Date(nowYear, nowMonth, nowDay))
        }
        if (startDate !== lastDate) {
          lastDate = this.formatDate(new Date(nowYear, nowMonth, nowDay - 1))
        }
        this.formTool.date.push(startDate)
        this.formTool.date.push(lastDate)
      },
      //格式化日期：yyyy-MM-dd
      formatDate(date) {
        const myyear = date.getFullYear();
        let mymonth = date.getMonth() + 1;
        let myweekday = date.getDate();
        if (mymonth < 10) {
          mymonth = "0" + mymonth;
        }
        if (myweekday < 10) {
          myweekday = "0" + myweekday;
        }
        return (myyear + "-" + mymonth + "-" + myweekday);
      },
      //获得某月的天数
      getMonthDays(myMonth) {
        const nowYear = new Date().getYear(); //当前年
        const monthStartDate = new Date(nowYear, myMonth, 1);
        const monthEndDate = new Date(nowYear, myMonth + 1, 1);
        const days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
        return days;
      },
      getList(current) {
        if (current) {
          this.page.current = current;
        }

        let list = this.getParam()
        this.getTotal(list)
        this.getTable(list)
      },
      // 获取请求参数
      getParam() {
        // [开始时间,结束时间,商品Id,商户ID,
        // 开始时间,结束时间,开始时间,结束时间,
        // 商户ID,商品Id,开始时间,结束时间,
        // SKU编码,SKU编码,商户ID,
        // 排序字段,每页行数,第几行开始]
        const date1 = this.correctData(this.formTool.date[0]).replace(/-/g, '')
        const date2 = this.correctData(this.formTool.date[1]).replace(/-/g, '')
        const goods_id = this.correctData(this.goodsDetail.goods_id).replace(/-/g, '')
        let params = [date1, date2, goods_id, this.correctData(this.shopId),
          date1, date2, date1, date2,
          this.correctData(this.shopId), goods_id, date1, date2,
          this.correctData(this.formTool.factory), this.correctData(this.formTool.factory), this.correctData(this.shopId),
          this.correctData(this.order), this.page.size, (this.page.current - 1) * this.page.size]
          if(this.type === 69 ||this.type === 70 || this.type === 71 || this.type === 72 || this.type === 73 || this.type === 74 ||this.type === 75 || this.type === 76){
            params.unshift(date2)
            params.unshift(date1)
          }
        return params
      },
      // 格式化数据
      correctData(data) {
        return data || ''
      },
      // 请求表格数据升序/降序
      getTable(list) {
        const param = {
          params: list,
          type: this.type
        }
        queryGoodsSalesEcharts(param).then((res) => {
          if (res) {
            this.tableData = res;
          } else {
            this.tableData = [];
          }
        });
      },
      // 请求总数
      getTotal(list) {
        const param = {
          params: list,
          type: 75
        }
        queryGoodsSalesEcharts(param).then((res) => {
          if (res) {
            this.page.total = Number(res[0].cnt);
          } else {
            this.page.total = 0;
          }
        });
      },
      handleExport() {
        const list = this.getParam()
        list.pop()
        list.pop()
        list.pop()
        const param = {
          type: 76,
          params: list
        }
        excelExport(param).then((res) => {
        });
      },
      handlePageChange(val) {
        const { page, limit} = val
        this.page.current = page;
        this.getList();
      },
      // 表格排序
      sortChange(val) {
        this.order = this.getOrder(val.prop);
        if (val.order == "ascending") {
          this.type = 73
        } else {
          this.type = 74
        }
        this.getList();
      },
      // 根据选择的字段获取请求参数
      getOrder(val) {
        let getValue = '3'
        switch (val) {
          case 'price':
            getValue = '1'
            break
          case 'qty':
            getValue = '2'
            break
          case 'amount':
            getValue = '3'
            break
          case 'avg_order_qty':
            getValue = '4'
            break
          case 'click_cnt':
            getValue = '5'
            break
          case 'shop_visit_cnt':
            getValue = '6'
            break
          case 'add_cart_shop_cnt':
            getValue = '7'
            break
          case 'order_shop_cnt':
            getValue = '8'
            break
          case 'order_cnt':
            getValue = '9'
            break
          case 'order_cvr':
            getValue = '10'
            break
          case 'pay_cvr':
            getValue = '11'
            break
          default:
            getValue = '3'
            break;
        }
        return getValue
      },
    },
  };
</script>

<style lang="scss" scoped>
  .goodsDetailDlg {
    .goods-info,
    .goods-detail {
      .title {
        font-size: 16px;
        font-weight: bold;
        border-left: 3px solid red;
        padding-left: 10px;
        margin-bottom: 24px;
        color: #f6212d;
      }
    }
    .goods-info {
      .info {
        line-height: 22px;
        display: flex;
        .color-59 {
          color: #595959;
        }
        .color-26 {
          color: #262626;
          margin-right: 24px;
        }
      }
    }
    .goods-detail {
      margin-top: 32px;
      .btn-box {
        margin-left: 12px;
        flex: 1;
      }
    }
    .center {
      ::v-deep .zt__table {
        height: 90%;
      }
    }
  }
</style>
