var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("div", { staticClass: "status_box" }, [
        _vm.status == 0
          ? _c("div", { staticClass: "status_img" }, [
              _c("img", {
                attrs: { src: require("../../assets/wait.png"), alt: "" },
              }),
            ])
          : _vm._e(),
        _vm.status == 1
          ? _c("div", { staticClass: "status_img" }, [
              _c("img", {
                attrs: { src: require("../../assets/reject.png"), alt: "" },
              }),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "status_title" }, [
        _vm.status == 0
          ? _c("div", {}, [_vm._v("资质正在审核中，请耐心等待")])
          : _vm._e(),
        _vm.status == 1 ? _c("div", {}, [_vm._v("资质已驳回")]) : _vm._e(),
        _vm.status == 1
          ? _c("div", { staticClass: "desc" }, [
              _vm._v(" " + _vm._s(_vm.info.remarks) + " "),
            ])
          : _vm._e(),
      ]),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("Credential", {
            ref: "infoBox",
            attrs: { pageType: "qualificationDetails" },
            on: { selectBtn: _vm.selectBtn },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }