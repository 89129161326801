var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c(
        "div",
        { staticClass: "center" },
        [
          _c(
            "div",
            {
              staticClass: "left_topic mb20",
              staticStyle: { "margin-top": "10px" },
            },
            [_vm._v("产品线基本信息")]
          ),
          _c("el-divider"),
          _c(
            "el-form",
            {
              ref: "productLine",
              staticClass: "ruleFormCont",
              attrs: {
                model: _vm.productLineFrom,
                "label-width": "100px",
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品线名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "325px" },
                    attrs: { placeholder: "请输入20字以内" },
                    model: {
                      value: _vm.productLineFrom.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.productLineFrom, "name", $$v)
                      },
                      expression: "productLineFrom.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "产品负责人", prop: "mangerHead" } },
                [
                  _c(
                    "div",
                    { staticClass: "cpxhead" },
                    [
                      _c(
                        "el-autocomplete",
                        {
                          attrs: {
                            "popper-class": "my-autocomplete",
                            "fetch-suggestions": _vm.mangerSearch,
                            "trigger-on-focus": false,
                            placeholder: "请输入内容",
                            "max-length": "11",
                          },
                          on: { select: _vm.selManger },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ item }) {
                                return [
                                  _c("div", { staticClass: "fzr" }, [
                                    _c("span", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.fullName)),
                                    ]),
                                    _c("span", { staticClass: "addr" }, [
                                      _vm._v(_vm._s(item.phone)),
                                    ]),
                                  ]),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.productLineFrom.mangerHead,
                            callback: function ($$v) {
                              _vm.$set(_vm.productLineFrom, "mangerHead", $$v)
                            },
                            expression: "productLineFrom.mangerHead",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-search el-input__icon",
                            attrs: { slot: "suffix" },
                            slot: "suffix",
                          }),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "add",
                          staticStyle: { "margin-left": "20px" },
                          attrs: {
                            type: "primary",
                            icon: "el-icon-circle-plus",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.userEdit(false, "product")
                            },
                          },
                        },
                        [_vm._v(" 创建")]
                      ),
                      _c("span", { staticClass: "mark" }, [
                        _vm._v(
                          "产品线负责人可以查看、编辑产品线，控制关联团队和产品"
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "产品线说明", prop: "description" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "325px" },
                    attrs: {
                      placeholder: "请输入100字以内",
                      type: "textarea",
                      rows: 2,
                    },
                    model: {
                      value: _vm.productLineFrom.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.productLineFrom, "description", $$v)
                      },
                      expression: "productLineFrom.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "left_topic mb20",
                  staticStyle: { "margin-top": "10px" },
                },
                [_vm._v("产品信息")]
              ),
              _c("el-divider"),
              _c(
                "div",
                { staticClass: "produceDetail" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c("el-form-item", { attrs: { label: "控销产品" } }, [
                            _c(
                              "div",
                              { staticClass: "shopImg" },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _c(
                                        "section",
                                        { staticClass: "product-info" },
                                        [
                                          _vm.productItem.name
                                            ? _c("el-image", {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px",
                                                },
                                                attrs: {
                                                  src:
                                                    _vm.productItem.image ||
                                                    _vm.avater,
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "section",
                                            { staticClass: "info" },
                                            [
                                              _vm.productItem.name
                                                ? _c("section", [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.productItem.name
                                                        )
                                                      ),
                                                    ]),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.productItem
                                                            .factory
                                                        )
                                                      ),
                                                    ]),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.productItem
                                                            .specification
                                                        )
                                                      ),
                                                    ]),
                                                  ])
                                                : _vm._e(),
                                              _c(
                                                "section",
                                                { staticClass: "choose" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "code" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.productItem
                                                              .approvalNumber
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  !_vm.isEditor
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "primary",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.chooseProduct,
                                                          },
                                                        },
                                                        [_vm._v("选择产品")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "控销分类", prop: "className" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    "margin-left": "8px",
                                    width: "325px",
                                  },
                                  attrs: {
                                    size: "small",
                                    "value-key": "productLineFrom.classId",
                                    placeholder: "请选择控销分类",
                                  },
                                  on: { change: _vm.handleSelectGroup },
                                  model: {
                                    value: _vm.productLineFrom.className,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.productLineFrom,
                                        "className",
                                        $$v
                                      )
                                    },
                                    expression: "productLineFrom.className",
                                  },
                                },
                                _vm._l(_vm.salesControlType, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt20", staticStyle: { "margin-bottom": "24px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.comfirm },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "", size: "small" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("productDialog", {
        attrs: {
          "unit-list": _vm.unitList,
          "dialog-visible": _vm.dialogVisible,
        },
        on: { chooseProudctItem: _vm.chooseProudctItem },
      }),
      _c("UserEdit", { ref: "userEdit", on: { sure: _vm.userEditSure } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }