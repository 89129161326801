<template>
  <div class="new-dlg-root">
    <el-dialog
      class="dialog-common"
      title="关联商品"
      :visible.sync="dialogVisible"
      width="900px"
      @closed="onClosed"
    >
      <div>
        <div>
          商品名称：
          <el-input
            placeholder="输入商品名称"
            style="width: 200px"
            size="small"
            @change="change"
            v-model="dialogInput"
          >
          </el-input>
        </div>
        <div>
          <MyTable
            :table-data="dialogtableData"
            :index="dialogindex"
            :selection="false"
            :page="dialogpage"
            :operation="dialogoperation"
            :table-option.sync="dialogtableOption"
            @page-change="dialoggetList"
            @handleSelectionChange="currentChange"
          >
            <template slot="select" slot-scope="scope">
              <el-checkbox
                v-model="scope.row.selected"
                @change="handleSelect(scope.$index)"
              ></el-checkbox>
            </template>
            <template slot="posterName" slot-scope="scope">
              <div
                style="
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <img
                  :src="scope.row.image || avater"
                  style="width: 80px; height: 80px"
                  alt=""
                />
                <div style="margin-left: 10px">
                  <p style="margin: 3px 0">{{ scope.row.name }}</p>
                  <p style="margin: 3px 0">{{ scope.row.factory }}</p>
                  <p style="margin: 3px 0">{{ scope.row.specification }}</p>
                </div>
              </div>
            </template>
            <template slot="price" slot-scope="scope">
              <span
                >￥{{ scope.row.lowestSalePrice }}~￥{{
                  scope.row.highestSalePrice
                }}</span
              >
            </template>
          </MyTable>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
        <el-button @click="handleClose" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import avater from "/public/img/default.png";
import MyTable from "@/components/myTable";
import { relevancePage } from "@/api/salesControl/commodity";
export default {
  props: {},
  data() {
    return {
      avater: avater,
      dialogVisible: false,
      dialogInput: "",
      // 下面是弹窗中的
      selectedData: [], //目前是单选;
      dialogoperation: false,
      dialogselection: false,
      dialogindex: false, // 不展示序号，自己重新定义了序号
      dialogtableData: [],
      dialogtableOption: [
        { label: "", prop: "select", slot: "true", width: 45 },
        { label: "商品信息", prop: "posterName", slot: "true" },
        { label: "销售价格(元)", prop: "price", slot: "true" },
      ],
      dialogpage: { total: 10, current: 1, size: 10 },
    };
  },
  components: { MyTable },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    currentChange(val) {
      this.selectedData = val; //checked选择商品
    },
    submit() {
      this.$emit("choose", this.selectedData);
      this.dialogVisible = false;
    },
    // 弹窗切换分页
    dialoggetList() {
      this.relevancePage();
    },
    // 弹窗搜索事件
    change() {
      this.relevancePage();
    },
    // 关闭弹窗
    handleClose() {
      this.close();
    },
    // 选择商品
    open() {
      this.dialogVisible = true;
      this.relevancePage();
    },
    close() {
      this.dialogVisible = false;
    },
    onClosed() {
      this.release();
    },
    release() {
      this.dialogpage.current = 1;
      this.dialogInput = "";
      this.selectedData = [];
      this.dialogtableData = [];
    },
    relevancePage() {
      const { current, size } = this.dialogpage;
      let params = {
        current,
        size,
        name: this.dialogInput,
      };
      relevancePage(params).then((res) => {
        this.dialogtableData = res.records;
        this.dialogpage.total = res.total;
      });
    },
    handleSelect(index) {
      const row = this.dialogtableData[index];
      if (row.selected) {
        this.selectedData.forEach((item) => {
          if (item != row) {
            item.selected = false;
          }
        });
        this.selectedData.push(row);
      } else {
        this.selectedData.forEach((item) => {
          item.selected = false;
        });
        this.selectedData = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table .el-table__cell:first-child {
    // width: 45px;
    // .cell {
    //   width: 45px;
    // }
  }
}
</style>
