<template>
  <div class="tab-content new-cls">
    <div class="freight-condition">
      <el-form
        :inline="true"
        size="small"
        :model="searchFrom"
        class="demo-form-inline"
      >
        <el-form-item>
          <el-input
            v-model="searchFrom.feeName"
            style="width: 200px"
            placeholder="请输入运费电话"
            @input="searchHandle"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchFrom.feeName"
            style="width: 200px"
            placeholder="请输入运费姓名"
            @input="searchHandle"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchFrom.feeType"
            style="width: 200px"
            placeholder="请选择商品分类"
            @change="searchHandle"
          >
            <el-option
              v-for="item in [
                { label: '商家承担运费 ', value: 0 },
                { label: '自定义运费', value: 1 },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" icon="el-icon-plus" @click="handleAdd"
            >新增运营</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <MyTable
      :table-data="tableData"
      :index="index"
      :selection="selection"
      :page="page"
      :showpage="false"
      :operation="operation"
      :table-option.sync="tableOption"
      @page-change="getList"
    >
      <template slot="menu" slot-scope="scope">
        <el-link
          type="primary"
          style="margin-left: 10px"
          :underline="false"
          @click="editorBtn(scope.row)"
        >
          编辑
        </el-link>
        <el-link
          type="primary"
          style="margin-left: 10px"
          :underline="false"
          @click="deleteBtn(scope.row)"
        >
          禁用
        </el-link>
      </template>
      <template slot="address" slot-scope="scope">
        <span
          >{{ scope.row.receiverProvinceName }}{{ scope.row.receiverCityName
          }}{{ scope.row.receiverAreaName
          }}{{ scope.row.receiverAddrDetail }}</span
        >
      </template>
      <template slot="province" slot-scope="scope">
        <span>{{ areaList(scope.row.areaList) }}</span>
      </template>
    </MyTable>

    <NewOperatorDlg ref="refNewOperatorDlg" />
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import NewOperatorDlg from "../dialog/newOperatorDlg.vue";

export default {
  components: { MyTable, NewOperatorDlg },
  data() {
    return {
      searchFrom: {},
      tableData: [],
      index: false,
      tableOption: [
        { label: "运营电话", prop: "receiver" },
        { label: "联系电话", prop: "运营姓名" }, // 这里表示自定义列, slot: true
        { label: "收货地址", prop: "在售商品数", slot: true },
        { label: "快递说明", prop: "商品分类" },
        { label: "ERP编码", prop: "createTime" },
        {
          label: "操作",
          prop: "menu",
          slot: true,
          width: "200px",
          align: "left",
        },
      ],
    };
  },
  methods: {
    handleAdd() {
      this.$refs.refNewOperatorDlg.open();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
