var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "el-form",
            {
              ref: "formTool",
              staticStyle: { "text-align": "left" },
              attrs: { model: _vm.formTool, inline: true },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-left": "0px !important" },
                  attrs: { prop: "invoiceStatus" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: {
                        clearable: "",
                        size: "small",
                        placeholder: "开票状态",
                      },
                      model: {
                        value: _vm.formTool.invoiceStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.formTool, "invoiceStatus", $$v)
                        },
                        expression: "formTool.invoiceStatus",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "id" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      placeholder: "发票申请编号",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.formTool.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "id", $$v)
                      },
                      expression: "formTool.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "value1" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      size: "small",
                      type: "daterange",
                      "value-format": "yyyy-MM-dd",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.clickSearch(1)
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.reset()
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "center", staticStyle: { position: "relative" } },
        [
          _c("MyTable", {
            attrs: {
              "table-data": _vm.tableData,
              index: _vm.index,
              selection: _vm.selection,
              page: _vm.page,
              operation: _vm.operation,
              "table-option": _vm.tableOption,
            },
            on: {
              "update:tableOption": function ($event) {
                _vm.tableOption = $event
              },
              "update:table-option": function ($event) {
                _vm.tableOption = $event
              },
              "page-change": _vm.getList,
              handleSelectionChange: _vm.handleSelectionChange,
            },
            scopedSlots: _vm._u([
              {
                key: "invoiceType",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("invoiceTypeFilter")(
                            scope.row.invoiceType,
                            scope.row.invoiceType
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "invoiceStatus",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.invoiceStatus == 0
                            ? "申请开票"
                            : scope.row.invoiceStatus == 1
                            ? "已开票"
                            : ""
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "aggregateAmount",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v("￥" + _vm._s(scope.row.aggregateAmount)),
                    ]),
                  ]
                },
              },
              {
                key: "caozuo",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { underline: false, type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.detail(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看明细")]
                    ),
                    scope.row.invoiceStatus == 1
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "16px" },
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.downloadBilling(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看发票")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传附件",
            visible: _vm.dialogVisible,
            width: "40%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "上传附件信息（上传实物盖章发票清晰扫描文件，多张发票请扫描成一个PDF，文件命名规格：公司名称 + 结算单号）"
            ),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "text-align": "left", "margin-top": "10px" },
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "margin-left": "0px !important",
                    "margin-bottom": "15px !important",
                  },
                  attrs: { prop: "fileList" },
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action:
                          "https://gateway.cbyyk.com/product/goodsbase/upload",
                        multiple: "",
                        "on-remove": _vm.handleChange,
                        "on-success": _vm.successUpload,
                        "on-error": _vm.errorUpload,
                        accept: ".jpg,.jpeg,.png,.pdf",
                        "file-list": _vm.form.fileList,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            "附件不能超过10M，最多上传10个，文件格式PDF、JPG、PNG"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "margin-left": "0px !important",
                    "margin-bottom": "15px !important",
                  },
                  attrs: { prop: "logistics" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: "填写快递公司",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.form.logistics,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "logistics", $$v)
                      },
                      expression: "form.logistics",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "margin-left": "0px !important",
                    "margin-bottom": "15px !important",
                  },
                  attrs: { prop: "trackingNumber" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      onkeyup:
                        "this.value=this.value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                      placeholder: "填写快递单号",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.form.trackingNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "trackingNumber", $$v)
                      },
                      expression: "form.trackingNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit },
                },
                [_vm._v("提交发票")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }