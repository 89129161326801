var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "harf-block" }, [
    _vm.showFormLeft
      ? _c("div", { staticClass: "big-block" }, [
          _vm._m(0),
          _c("div", { staticClass: "form-content" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("ERP编码")]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      maxlength: "20",
                    },
                    model: {
                      value: _vm.formIn.medicineCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "medicineCode", $$v)
                      },
                      expression: "formIn.medicineCode",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "tip" }, [
                _vm.formIn.chargeFlag || _vm.goodsId != _vm.xiaocaihuId
                  ? _c("span", { staticClass: "weight" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v(" 价格(元)"),
              ]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "positive-five-limit",
                        rawName: "v-positive-five-limit",
                      },
                    ],
                    attrs: { size: "small", placeholder: "请输入" },
                    model: {
                      value: _vm.formIn.salePrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "salePrice", $$v)
                      },
                      expression: "formIn.salePrice",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      disabled: _vm.formIn.beSynStock,
                      maxlength: "8",
                    },
                    model: {
                      value: _vm.formIn.goodsSkuInventory,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "goodsSkuInventory", $$v)
                      },
                      expression: "formIn.goodsSkuInventory",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "value fill" },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { disabled: !_vm.formIn.medicineCode },
                      on: { change: _vm.stockChange },
                      model: {
                        value: _vm.formIn.beSynStock,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "beSynStock", $$v)
                        },
                        expression: "formIn.beSynStock",
                      },
                    },
                    [_vm._v("自动同步库存")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", size: "small" },
                      model: {
                        value: _vm.formIn.goodsSkuUnit,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "goodsSkuUnit", $$v)
                        },
                        expression: "formIn.goodsSkuUnit",
                      },
                    },
                    _vm._l(_vm.unitList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(3),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "请选择",
                      size: "small",
                      editable: false,
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.formIn.expireTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "expireTime", $$v)
                      },
                      expression: "formIn.expireTime",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(4),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "请选择",
                      size: "small",
                      editable: false,
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.formIn.producedTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "producedTime", $$v)
                      },
                      expression: "formIn.producedTime",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(5),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "请选择",
                      disabled: _vm.fromPage == "edit" ? true : false,
                      size: "small",
                      "picker-options": _vm.pickerOptions,
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    model: {
                      value: _vm.formIn.activityStartTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "activityStartTime", $$v)
                      },
                      expression: "formIn.activityStartTime",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(6),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "picker-options": _vm.pickerOptions,
                      type: "datetime",
                      "default-time": "23:59:59",
                      disabled: _vm.fromPage == "edit" ? true : false,
                      placeholder: "请选择",
                      size: "small",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    model: {
                      value: _vm.formIn.activityEndTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "activityEndTime", $$v)
                      },
                      expression: "formIn.activityEndTime",
                    },
                  }),
                ],
                1
              ),
              _vm._m(7),
            ]),
            _vm.goodsId == _vm.xiaocaihuId
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "tip" }, [_vm._v(" 收取赠品金")]),
                  _c(
                    "div",
                    { staticClass: "value fill" },
                    [
                      _c("el-checkbox", {
                        attrs: { "true-label": 1, "false-label": 0 },
                        model: {
                          value: _vm.formIn.chargeFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.formIn, "chargeFlag", $$v)
                          },
                          expression: "formIn.chargeFlag",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#f5222d",
                            "font-size": "12px",
                          },
                        },
                        [
                          _vm._v(
                            " 赠品收取金额时，订单只能整单退货，退货时将赠品金额一并退回 "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "big-block" }, [
      _vm._m(8),
      _c("div", { staticClass: "form-content" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(9),
          _c(
            "div",
            { staticClass: "value input" },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入" },
                model: {
                  value: _vm.formIn.goodsSkuTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.formIn, "goodsSkuTitle", $$v)
                  },
                  expression: "formIn.goodsSkuTitle",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "tip" }, [_vm._v("产品批号")]),
          _c(
            "div",
            { staticClass: "value input" },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入" },
                model: {
                  value: _vm.formIn.batchNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.formIn, "batchNumber", $$v)
                  },
                  expression: "formIn.batchNumber",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row mutlti" }, [
          _c("div", { staticClass: "tip" }, [_vm._v("商品图片")]),
          _c(
            "div",
            { staticClass: "value mutlti v" },
            [
              _c(
                "div",
                { staticClass: "in-row" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.formIn.imgRegion,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "imgRegion", $$v)
                        },
                        expression: "formIn.imgRegion",
                      },
                    },
                    [_vm._v("使用平台图片")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.formIn.imgRegion,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "imgRegion", $$v)
                        },
                        expression: "formIn.imgRegion",
                      },
                    },
                    [_vm._v("上传图片")]
                  ),
                ],
                1
              ),
              _vm.formIn.imgRegion === 2
                ? [
                    _c("div", { staticClass: "in-row" }, [
                      _c(
                        "div",
                        { staticClass: "pic-layout" },
                        [
                          _vm._l(
                            _vm.imageUrlList ? _vm.imageUrlList : [],
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "item" },
                                [
                                  _c("el-image", {
                                    ref: `myImg${index}`,
                                    refInFor: true,
                                    staticClass: "icon",
                                    attrs: {
                                      src: item,
                                      fit: "cover",
                                      "initial-index": index,
                                      "preview-src-list": _vm.imageUrlList,
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "preview" },
                                    [
                                      _c("el-image", {
                                        staticClass: "btn-icon",
                                        attrs: { src: _vm.SeeIcon },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlePreview(
                                              `myImg${index}`
                                            )
                                          },
                                        },
                                      }),
                                      _c("el-image", {
                                        staticClass: "btn-icon",
                                        attrs: { src: _vm.DelIcon },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDeleteImage(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "item add-pic",
                              on: {
                                click: function ($event) {
                                  return _vm.handleUpload()
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "image-wrap" },
                                [
                                  _c("el-image", {
                                    attrs: { src: _vm.AddIcon },
                                  }),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "text" }, [
                                _vm._v("上传图片"),
                              ]),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]),
                    _vm._m(10),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "row mutlti big" }, [
          _c("div", { staticClass: "tip" }, [_vm._v("商品标签")]),
          _c("div", { staticClass: "value fill" }, [
            _c(
              "div",
              { staticClass: "flag-block" },
              _vm._l(
                _vm.shopLabelList ? _vm.shopLabelList : [],
                function (item) {
                  return _c(
                    "el-radio",
                    {
                      key: item.id,
                      attrs: { label: item.id },
                      model: {
                        value: _vm.formIn.shopLabelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "shopLabelId", $$v)
                        },
                        expression: "formIn.shopLabelId",
                      },
                    },
                    [_vm._v(" " + _vm._s(item.labelName) + " ")]
                  )
                }
              ),
              1
            ),
          ]),
        ]),
        _vm._m(11),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "tip" }, [_vm._v("商家店铺页排序")]),
          _c(
            "div",
            { staticClass: "value input" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入", maxlength: "5" },
                model: {
                  value: _vm.formIn.sortCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.formIn, "sortCode", $$v)
                  },
                  expression: "formIn.sortCode",
                },
              }),
            ],
            1
          ),
          _vm._m(12),
        ]),
        _c("div", { staticClass: "row mutlti" }, [
          _c("div", { staticClass: "tip" }, [_vm._v("批件药检报告等")]),
          _c(
            "div",
            { staticClass: "value input fill" },
            [
              _c("fileUpload", {
                staticStyle: { "margin-bottom": "10px" },
                on: { onRemoveHandler: _vm.onRemoveHandler },
                model: {
                  value: _vm.fileList,
                  callback: function ($$v) {
                    _vm.fileList = $$v
                  },
                  expression: "fileList",
                },
              }),
              _vm._m(13),
            ],
            1
          ),
        ]),
        _vm.selectType && _vm.selectType.label == "特价"
          ? _c(
              "div",
              { staticClass: "row" },
              [
                _c("div", { staticClass: "tip" }, [_vm._v("商品活动说明")]),
                _c("wangEnduit", {
                  staticStyle: { "margin-left": "20px" },
                  attrs: {
                    value: _vm.formIn.activityDescription,
                    "is-clear": _vm.isClear,
                    isDisabled: _vm.isDisabled,
                  },
                  on: { changeEndit: _vm.watchEndit },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-block" }, [
      _c("span", { staticClass: "text" }, [_vm._v("价格/库存")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v(" 总库存"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("单位"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("有效期至"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("生产日期"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("活动开始时间"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("活动结束时间"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "value fill small-tip" }, [
      _c("span", { staticStyle: { "font-size": "12px", color: "#606266" } }, [
        _vm._v(
          " 商品在活动开始时间到活动结束时间之间显示在app/PC。超过活动结束时间后不可编辑或延长时间 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-block" }, [
      _c("span", { staticClass: "text" }, [_vm._v("图片/其他")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("商品标题"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "in-row" }, [
      _c("span", { staticClass: "value small-tip" }, [
        _vm._v("注意：上传图片需要审核，最多五张，图片尺寸建议1080*1080px"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row contine" }, [
      _c("div", { staticClass: "tip" }),
      _c("div", { staticClass: "value fill small-tip" }, [
        _c("span", [
          _vm._v(
            " 标签是显示在app商品列表图上的边框/角标优惠信息。 商家标签可在商品管理-商家标签上传 "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "value small-tip" }, [
      _c("span", [_vm._v("注意：排序大的靠前，最大99999")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "small-tip" }, [
      _c("span", [_vm._v("上传pdf或图片，且不超过10M")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }