<template>
  <div class="static-template">
    <div class="template-item" v-for="(item, index) in list" :key="index">
      <div class="tip" v-if="!item.hide">
        {{ item.filedLabel }}<span v-if="item.isMust" class="weight">*</span>
      </div>
      <!-- 普通输入域 -->
      <div
        class="value"
        v-if="!item.hide && item.type === fieldType.Iconst_Input"
      >
        <span v-if="preview">{{
          childTaskDetail ? childTaskDetail[item.fieldName] : ""
        }}</span>
        <el-input v-else disabled size="small" placeholder="" />
      </div>
      <!-- 图片域 -->
      <div
        class="value"
        v-else-if="!item.hide && item.type === fieldType.Iconst_Pic"
      >
        <ossFileUpload
          v-if="preview"
          :value="fileList(item)"
          :ossUpload="true"
          :withCover="true"
          :disabled="true"
          :picSize="88"
          listType="picture-card"
        />
        <div v-else class="pic-views">
          <div class="select-pic">
            <i class="el-icon-plus"></i>
            <span></span>
          </div>
          <!-- <div class="small-tip">单个图片/文件大小不超过300M</div>
          <div class="small-tip">支持格式：gif、png、jpg、jpeg、bmp</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ossFileUpload from "@/components/ossFileUpload";
import { taskHelp, childTaskHelp } from "@/utils/viewHelp";
import { parseToUploadUrl } from "@/utils/oss";

export default {
  components: { ossFileUpload },
  props: {
    //是不是预览
    preview: {
      type: Boolean,
      default: false,
    },
    childTaskType: {
      type: Number,
      default: -1,
    },
    childTaskDetail: {
      type: Object | null,
      default: null,
    },
  },
  data() {
    return {
      fieldType: childTaskHelp.fieldType,
      value: "",
    };
  },
  computed: {
    list() {
      if (this.childTaskType > 0) {
        const findedItem = taskHelp.taskTypeList.find(
          (item) => item.value === this.childTaskType
        );
        if (findedItem && Array.isArray(findedItem.template)) {
          return findedItem.template;
        }
      }

      return [];
    },
  },
  methods: {
    fileList(item) {
      if (this.childTaskDetail && this.childTaskDetail[item.fieldName]) {
        const imgUrl = this.childTaskDetail[item.fieldName];

        const urls = imgUrl.split(",");
        const picFileList = urls.map((item) => {
          return parseToUploadUrl(item);
        });
        return picFileList;
      }

      return [];
    },
  },
};
</script>
<style lang="scss" scoped>
.static-template {
  .template-item {
    .tip {
      font-size: 14px;
      color: #a0a0a0;

      .weight {
        color: #f00;
        position: relative;
        top: 4px;
        margin-left: 2px;
      }
    }

    .value {
      margin-top: 4px;
      color: #000;

      ::v-deep {
        .el-input {
          width: 100%;
        }
      }

      .pic-views {
        display: flex;
        flex-direction: column;

        .small-tip {
          font-size: 12px;
          color: #a0a0a0;
          margin-top: 12px;
        }
      }

      .select-pic {
        width: 110px;
        height: 110px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px dashed #f0f0f0;
        padding: 24px;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }
  }

  .position {
    background: #eff3fd;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 4px;
  }

  & > * {
    margin-bottom: 12px;
  }
}
</style>
