import request from '@/utils/request'
// 分页查询
export function skuPage(data) {
  return request({
    url: `/product/compliance/sku/page`,
    method: 'get',
    params: data
  })
}

// 根据基库商品id查询商品对应的sku信息
export function complianceSku(data) {
  return request({
    url: `/product/compliance/sku/goodsBase/${data}`,
    method: 'get'
  })
}
// 修改sku上架状态
export function skuStatus(data) {
  return request({
    url: `/product/compliance/sku/status`,
    method: 'put',
    params:data
  })
}


// 商品加入仓库
export function warehouseAdd(data) {
  return request({
    url: `/product/compliance/sku/warehouse/add`,
    method: 'post',
    data
  })
}

// 批量修改sku信息
export function batchUpdate(data) {
  return request({
    url: `/product/compliance/sku/batchUpdate`,
    method: 'put',
    data
  })
}

// sku商品信息修改
export function skuUpdate(data) {
  return request({
    url: `/product/compliance/sku/update`,
    method: 'put',
    data
  })
}


// 新增sku
export function skuAdd(data) {
  return request({
    url: `/product/compliance/sku/add`,
    method: 'post',
    data
  })
}

// 根据sku id查询sk详情
export function skuId(skuId) {
  return request({
    url: `/product/compliance/sku/${skuId}`,
    method: 'get'
  })
}

// 根据goodsSkuId查询商品变更记录
export function queryGoodsSkuEditLogList(goodsSkuId) {
  return request({
    url: `/product/monitor/goodsSkuEditLog/queryGoodsSkuEditLogList/${goodsSkuId}`,
    method: 'get'
  })
}