var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "classification" }, [
    _c("div", { staticClass: "topHeader" }, [
      _c("div", { staticClass: "fenlei" }, [_vm._v("定价管理")]),
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "formTool",
              staticStyle: { "text-align": "left" },
              attrs: { model: _vm.formTool, inline: true },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "goodsName" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入商品名称",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.formTool.goodsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "goodsName", $$v)
                      },
                      expression: "formTool.goodsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "factory" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入厂家关键词",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.formTool.factory,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "factory", $$v)
                      },
                      expression: "formTool.factory",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "approvalNumber" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入批准文号",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.formTool.approvalNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "approvalNumber", $$v)
                      },
                      expression: "formTool.approvalNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { "margin-left": "0px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.clickSearch(1)
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.reset()
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-left": "38px", "margin-right": "0px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.clickBtn },
                    },
                    [_vm._v("+ 新建定价")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "center" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              "span-method": _vm.objectSpanMethod,
              border: "",
              align: "left",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "id",
                label: "商品信息",
                width: "300",
                align: "left",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "imgBox" }, [
                        _c("div", {}, [
                          _c("img", {
                            staticStyle: { width: "80px", height: "80px" },
                            attrs: { src: scope.row.image, alt: "" },
                          }),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "fontBox",
                            staticStyle: { "padidng-left": "20px" },
                          },
                          [
                            _c("span", { staticClass: "spanFont jiaolang" }, [
                              _vm._v(_vm._s(scope.row.goodsName)),
                            ]),
                            _c("span", { staticClass: "spanFont" }, [
                              _vm._v(_vm._s(scope.row.approvalNumber)),
                            ]),
                            _c("span", { staticClass: "spanFont company" }, [
                              _vm._v(_vm._s(scope.row.factory)),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "regionName", label: "定价区域", align: "left" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "agencyTypeName",
                label: "机构类型",
                align: "left",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "retailPrice",
                label: "零售价（元）",
                align: "left",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "wholesalePrice",
                label: "批发价（元）",
                align: "left",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "wholesaleMinAmount",
                label: "起订量",
                align: "left",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "amount3", label: "最后操作人", align: "left" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _c("p", [_vm._v(_vm._s(scope.row.createTime))]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              scope.row.updateBy
                                ? scope.row.updateBy
                                : scope.row.createBy
                            )
                          ),
                        ]),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "amount3", label: "操作", align: "left" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        [
                          _c(
                            "el-link",
                            {
                              staticStyle: { "margin-right": "10px" },
                              attrs: { type: "primary", underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.clickPrice(scope.row.goodsRegionId)
                                },
                              },
                            },
                            [_vm._v("定价")]
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary", underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.clickDel(scope.row.goodsRegionId)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "el-page" },
      [
        _c("Pagination", {
          attrs: {
            total: _vm.page.total,
            page: _vm.page.current,
            limit: _vm.page.size,
            "page-sizes": _vm.page.pageSizes,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.page, "current", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.page, "size", $event)
            },
            pagination: _vm.pageChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }