var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("Tabs", {
        staticStyle: { padding: "0 24px" },
        attrs: { tabsList: _vm.tabsList, activeName: _vm.activeVal },
        on: { "tab-click": _vm.handleChange },
      }),
      _vm.activeVal == "-99"
        ? _c("PayTypeSetting", { attrs: { boundMoney: _vm.boundMoney } })
        : _vm._e(),
      _vm.activeVal == "-10" ? _c("ShopInfoView") : _vm._e(),
      _vm.activeVal == "-9" ? _c("NewAccountView") : _vm._e(),
      _vm.activeVal == "-8"
        ? _c("SendConfigView", { attrs: { boundMoney: _vm.boundMoney } })
        : _vm._e(),
      _vm.activeVal == "-7"
        ? _c("AfterSaleConfigView", { attrs: { allAreaCode: _vm.allAreaCode } })
        : _vm._e(),
      _vm.activeVal == "1"
        ? _c("Announcement", { ref: "Announcement" })
        : _vm._e(),
      _vm.activeVal == "2"
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "freight-condition" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "demo-form-inline",
                      attrs: {
                        inline: true,
                        size: "small",
                        model: _vm.searchFrom,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: "请输入运费名称" },
                            on: { input: _vm.searchHandle },
                            model: {
                              value: _vm.searchFrom.feeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchFrom, "feeName", $$v)
                              },
                              expression: "searchFrom.feeName",
                            },
                          }),
                        ],
                        1
                      ),
                      0
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    placeholder: "请选择运费类型",
                                    clearable: "",
                                  },
                                  on: { change: _vm.searchHandle },
                                  model: {
                                    value: _vm.searchFrom.feeType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchFrom, "feeType", $$v)
                                    },
                                    expression: "searchFrom.feeType",
                                  },
                                },
                                _vm._l(
                                  [
                                    { label: "商家承担运费 ", value: 0 },
                                    { label: "自定义运费", value: 1 },
                                  ],
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        [
                          _c("el-date-picker", {
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              size: "small",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd",
                              type: "daterange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            on: { change: _vm.searchHandle },
                            model: {
                              value: _vm.searchFrom.createTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchFrom, "createTime", $$v)
                              },
                              expression: "searchFrom.createTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", icon: "el-icon-plus" },
                              on: { click: _vm.handleFreight },
                            },
                            [_vm._v("新增运费")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.activeVal == "2"
                ? _c("Freight", { ref: "Freight" })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.activeVal == "3"
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "freight-condition" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "demo-form-inline",
                      attrs: {
                        inline: true,
                        size: "small",
                        model: _vm.searchFrom,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: "请输入收货人" },
                            on: { input: _vm.searchHandleAfterMarket },
                            model: {
                              value: _vm.searchFromAfterMarket.receiver,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchFromAfterMarket,
                                  "receiver",
                                  $$v
                                )
                              },
                              expression: "searchFromAfterMarket.receiver",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: "请输入联系电话" },
                            on: { input: _vm.searchHandleAfterMarket },
                            model: {
                              value: _vm.searchFromAfterMarket.receiverPhone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchFromAfterMarket,
                                  "receiverPhone",
                                  $$v
                                )
                              },
                              expression: "searchFromAfterMarket.receiverPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: "请输入收货地址" },
                            on: { input: _vm.searchHandleAfterMarket },
                            model: {
                              value:
                                _vm.searchFromAfterMarket.receiverAddrDetail,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchFromAfterMarket,
                                  "receiverAddrDetail",
                                  $$v
                                )
                              },
                              expression:
                                "searchFromAfterMarket.receiverAddrDetail",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-plus",
                                size: "small",
                              },
                              on: { click: _vm.onSubmit },
                            },
                            [_vm._v("新增地址")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.activeVal == "3"
                ? _c("Aftermarket", { ref: "Aftermarket" })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.activeVal == "-6" ? _c("OperationConfigView") : _vm._e(),
      _vm.activeVal == "-5" ? _c("WorkdayConfigView") : _vm._e(),
      _vm.activeVal == "4"
        ? _c("BillingSetup", { ref: "billingSetup" })
        : _vm._e(),
      _vm.activeVal == "5"
        ? _c("RateInformation", { ref: "rateInformation" })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增运费",
            visible: _vm.dialogVisible,
            width: "480px",
            "before-close": _vm.handleCloseFee,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleFormFee",
              staticClass: "demo-ruleForm",
              staticStyle: { padding: "4px" },
              attrs: {
                model: _vm.ruleForm,
                "label-position": "top",
                size: "small",
                rules: _vm.rules,
                "label-width": "100px",
                disabled: _vm.typeFreight == "detail",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "区域", prop: "areaList" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        height: "120px",
                        "overflow-y": "auto",
                      },
                    },
                    [
                      _c("el-tree", {
                        ref: "tree",
                        attrs: {
                          props: { label: "rname", children: "children" },
                          data: _vm.areaTreeData,
                          "node-key": "id",
                          "show-checkbox": "",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否包邮", prop: "feeType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.feeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "feeType", $$v)
                        },
                        expression: "ruleForm.feeType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("商家承担运费"),
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("自定义运费"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "fill-warp",
                  attrs: { label: "发货物流", prop: "deliveryLogisticsId" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", size: "small" },
                      on: { change: _vm.handleChangeExpress },
                      model: {
                        value: _vm.ruleForm.deliveryLogisticsId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "deliveryLogisticsId", $$v)
                        },
                        expression: "ruleForm.deliveryLogisticsId",
                      },
                    },
                    _vm._l(
                      _vm.indicatorList ? _vm.indicatorList : [],
                      function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.instructions, value: item.id },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c("el-form-item", [
                _vm.ruleForm.medalPicture
                  ? _c(
                      "div",
                      { staticClass: "medalView" },
                      [
                        _c("el-image", {
                          staticClass: "medalImage",
                          attrs: { src: _vm.ruleForm.medalPicture },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.ruleForm.medalName))]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "运费名称", prop: "feeName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入运费名称" },
                    model: {
                      value: _vm.ruleForm.feeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "feeName", $$v)
                      },
                      expression: "ruleForm.feeName",
                    },
                  }),
                ],
                1
              ),
              _vm.ruleForm.feeType
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "满足以下金额包邮",
                        prop: "freeShippingOrderAmount",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入金额" },
                          model: {
                            value: _vm.ruleForm.freeShippingOrderAmount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "freeShippingOrderAmount",
                                $$v
                              )
                            },
                            expression: "ruleForm.freeShippingOrderAmount",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "prefix" }, slot: "prefix" },
                            [_vm._v("￥")]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.ruleForm.feeType
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "不满足条件时收取以下金额运费",
                        prop: "carriageAmount",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入金额" },
                          model: {
                            value: _vm.ruleForm.carriageAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "carriageAmount", $$v)
                            },
                            expression: "ruleForm.carriageAmount",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "prefix" }, slot: "prefix" },
                            [_vm._v("￥")]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.ruleForm.feeType
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "低于等于以下金额不允许下单",
                        prop: "minOrderAmount",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入金额" },
                          model: {
                            value: _vm.ruleForm.minOrderAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "minOrderAmount", $$v)
                            },
                            expression: "ruleForm.minOrderAmount",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "prefix" }, slot: "prefix" },
                            [_vm._v("￥")]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCloseFee } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.typeFreight == "detail",
                    type: "primary",
                  },
                  on: { click: _vm.postShopExpressFees },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogAddressLook ? "查看地址" : "新增地址",
            visible: _vm.dialogAddress,
            width: "480px",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAddress = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              staticStyle: { padding: "4px" },
              attrs: {
                model: _vm.ruleForm,
                "label-position": "top",
                size: "small",
                rules: _vm.rules,
                "label-width": "100px",
                disabled: _vm.typeAfterMarket == "detail",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "生效省份", prop: "areaList" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        height: "120px",
                        "overflow-y": "auto",
                      },
                    },
                    [
                      _c("el-tree", {
                        ref: "tree",
                        attrs: {
                          props: { label: "rname", children: "children" },
                          data: _vm.areaTreeData,
                          "node-key": "id",
                          "show-checkbox": "",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收货人", required: "" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入收货人，限10个字" },
                    model: {
                      value: _vm.ruleForm.receiver,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "receiver", $$v)
                      },
                      expression: "ruleForm.receiver",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话", required: "" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入联系电话" },
                    model: {
                      value: _vm.ruleForm.receiverPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "receiverPhone", $$v)
                      },
                      expression: "ruleForm.receiverPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收货地址", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "130px", "margin-right": "8px" },
                      attrs: { "value-key": "id", placeholder: "请选择省份" },
                      on: { change: _vm.selectedHandle },
                      model: {
                        value: _vm.ruleForm.province,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "province", $$v)
                        },
                        expression: "ruleForm.province",
                      },
                    },
                    _vm._l(_vm.provinceData, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.rname, value: item },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "138px", "margin-right": "8px" },
                      attrs: { placeholder: "请选择城市", "value-key": "id" },
                      on: { change: _vm.selectedHandleCity },
                      model: {
                        value: _vm.ruleForm.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "city", $$v)
                        },
                        expression: "ruleForm.city",
                      },
                    },
                    _vm._l(_vm.cityData, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.rname, value: item },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "138px" },
                      attrs: { placeholder: "请选择区域", "value-key": "id" },
                      on: { change: _vm.selectedHandleArea },
                      model: {
                        value: _vm.ruleForm.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "area", $$v)
                        },
                        expression: "ruleForm.area",
                      },
                    },
                    _vm._l(_vm.areaData, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.rname, value: item },
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticStyle: { "margin-top": "8px" },
                    attrs: { placeholder: "请输入详细地址" },
                    model: {
                      value: _vm.ruleForm.receiverAddrDetail,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "receiverAddrDetail", $$v)
                      },
                      expression: "ruleForm.receiverAddrDetail",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "快递说明", required: "" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        "请输入快递说明，例如：退货不支持申通、邮政、天天快递。",
                    },
                    model: {
                      value: _vm.ruleForm.expressDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "expressDesc", $$v)
                      },
                      expression: "ruleForm.expressDesc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.dialogAddressLook
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.afterMarketBtn },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }