<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    dot: {
      type: Boolean,
      default: false
    },
    dotValue: {
      type: Number,
      default: 0
    }
  },
  render(h, context) {
    const { icon, title, dot } = context.props
    const vnodes = []

    if (icon) {
      if (icon.includes('el-icon')) {
        vnodes.push(<i class={[icon, 'sub-el-icon']} />)
      } else {
        vnodes.push(<svg-icon icon-class={icon}/>)
      }
    }
    // 变动徽标 47641
    if (title) {
        vnodes.push(
          <span slot="title" class={dot ? 'menu-title dot' : 'menu-title'}>{(title)}</span>)
      }
    return vnodes
  }
}
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
}
.dot {
  position: relative;
}
.dot::after {
  content: '';
    display: inline-block;
    border-radius: 50%;
    background-color: #f5222d;
    /* margin-right: 5px; */
    position: absolute;
    top: 0;
    width: 6px;
    height: 6px;
    right: -7px;
}
</style>
