var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.visible,
            "lock-scroll": true,
            "close-on-click-modal": false,
            width: "818px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("section", [
            _c(
              "div",
              { staticClass: "serach" },
              [
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: {
                    "prefix-icon": "el-icon-search",
                    placeholder: "请输入商品名称",
                    clearable: "",
                    size: "small",
                  },
                  on: { change: _vm.change },
                  model: {
                    value: _vm.formTool.goodsName,
                    callback: function ($$v) {
                      _vm.$set(_vm.formTool, "goodsName", $$v)
                    },
                    expression: "formTool.goodsName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("goodsItem", {
                  staticClass: "goods-content",
                  attrs: { goodsList: _vm.goodsList },
                  on: { chooseItem: _vm.chooseItem },
                }),
                _c("el-pagination", {
                  attrs: {
                    "page-sizes": _vm.page.pageSizes,
                    layout: "total, sizes, prev, pager, next",
                    total: _vm.page.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "btnBox" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.chooseGoods },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }