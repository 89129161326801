<template>
  <div class="itemCont">
    <div class="status_ico status_success"
         v-if="item.clickNone"><i class="el-icon-check" /></div>
    <div class="status_ico status_error"
         v-if="item.union"
         @click="remove(item)"><i class="el-icon-close" /></div>
    <div class="itemImg"
         @click="add(item)">
      <img :src="item.image || avater"
           alt=""
           style="width: 180px; height: 180px; padding: 15px 0px">
    </div>
    <el-tooltip class="item"
                effect="dark"
                :content="item.goodsName"
                placement="top">
      <div class="itemBox name">
        {{ item.goodsName|| '-' }}
      </div>
    </el-tooltip>
    <div class="itemBox factory">
      {{ item.goodsFactory || '-'}}
    </div>
    <div class="itemBox factory">
      {{ item.approvalNumber || '-'}}
    </div>
    <div class="itemBox factory"
         style="padding-bottom:10px">
      {{ item.specification || '-'}}
    </div>
  </div>

</template>
<script>
import avater from "/public/img/default.png"
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      clickNone: false,
      avater: avater,
    }
  },
  methods: {
    remove (item) {
      console.log(item)
      // this.$store.dispatch('coupon/removeGoods', item)
      this.$emit('removeSelectedItem', item)
    },
    add (item) {
      console.log('before item', item)
      if (item.union) {
        return
      }
      this.clickNone = !item.clickNone
      this.$set(item, 'clickNone', this.clickNone)
      this.$emit('addGunalian', {
        ...item,
        clickNone: !!item.clickNone
      })
    }
  }

}
</script>
<style lang="scss" scoped>
.itemCont {
  position: relative;
  width: 13.6%;
  min-width: 225px;
  // height: 300px;
  border: 1px solid #dcdfe6;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  .status_ico {
    position: absolute;
    right: 0px;
    height: 30px;
    width: 30px;
    background: green;
    border-radius: 30px;
    cursor: pointer;
    .el-icon-check,
    .el-icon-close {
      font-weight: 700;
      color: white;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .status_error {
    background: red;
  }
  .name {
    display: block !important;
    font-size: 16px;
    font-weight: 600;
  }
  .factory {
    color: #595959;
    font-size: 14px;
  }
  .itemBox {
    padding: 3px 10px;
    display: flex;
    justify-content: flex-start;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
  .itemImg {
    height: 180px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .price {
    font-weight: 700;
    justify-content: end;
  }
  .shopCar {
    margin-top: 20px;
    justify-content: center;
  }
}
</style>
