<template>
    <div id="cooperationGoodsList">
        <!-- 搜索区域 -->
        <div class="search-box">
            <!-- ellform表单 -->
            <el-form :inline="true" :model="searchForm">
                <el-form-item label="商品名称">
                    <el-input v-model="searchForm.goodsName" placeholder="请输入商品名称" size="small"></el-input>
                </el-form-item>
                <!-- <el-form-item label="申请类型">
                    <el-select v-model="searchForm.type" size="small">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="发起申请" value="1"></el-option>
                        <el-option label="向我申请" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="允许查看">
                    <el-select v-model="searchForm.status" size="small">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="无申请" value="0"></el-option>
                        <el-option label="申请查看" value="1"></el-option>
                        <el-option label="通过" value="2"></el-option>
                        <el-option label="拒绝" value="3"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item>
                    <el-button type="primary" @click="search" size="small">搜索</el-button>
                    <el-button type="default" @click="reset" size="small">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格区域 -->
        <div class="table-box" v-if="tableHeight">
            <el-table 
                :data="list" 
                :height="tableHeight"
            >
                <!-- 序号 -->
                <!-- <el-table-column type="index" label="序号"></el-table-column> -->
                <el-table-column label="商品信息" width="300">
                    <template slot-scope="scope">
                        <div style="display: flex;align-items: center;">
                            <!-- 图片 -->
                            <div>
                                <el-image 
                                    style="width: 80px; height: 80px;"
                                    :src="scope.row.goodsImage"
                                    :preview-src-list="[scope.row.goodsImage]"
                                    lazy 
                                />
                            </div>
                            <div class="goods-info">
                                <!-- 商品名称 -->
                                <div class="goods-name">{{scope.row.goodsName}}</div>
                                <!-- 厂家 -->
                                <div>{{scope.row.factory}}</div>
                                <!-- 规格 -->
                                <div>{{scope.row.specification}}</div>
                                <!-- 批准文号 -->
                                <div>{{scope.row.approvalNumber}}</div>
                                <!-- 商品编码 -->
                                <div>商品编码：{{scope.row.goodsCode || '-'}}</div>
                                <!-- sku编码 -->
                                <div>SKU编码：{{scope.row.skuCode || '-'}}</div>
                                <!-- erp编码 -->
                                <div>ERP编码：{{scope.row.medicineCode || '-'}}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="产品线名称" prop="productLineName" width="120">
                    <template slot-scope="scope">
                        <div>
                            <div>{{scope.row.productLineName}}</div>
                            <div>{{scope.row.productLineCode}}</div>
                        </div>
                    </template>
                </el-table-column>
                <!-- 商品价格 -->
                <el-table-column label="商品价格" prop="price" width="250">
                    <template slot-scope="scope">
                        <!-- <div v-if="scope.row.goodsPrice !=null">¥{{scope.row.goodsPrice}}</div> -->
                        <!-- <p>价格：￥{{ scope.row.salePrice || "-" }}</p> -->
                        <p>价格：￥{{ scope.row.minCooperativeIncome || "-" }}</p>
                        <p>市场价：￥{{ scope.row.marketPrice || "-" }}</p>
                        <p>建议零售价：￥{{ scope.row.purchasePrice || "-" }}</p>
                    </template>
                </el-table-column>
                <!-- 库存 -->
                <el-table-column label="库存" prop="stock" width="300">
                    <template slot-scope="scope">
                        <p>总库存：{{ scope.row.goodsSkuInventory || "0" }}</p>
                        <p>有效期至：{{ scope.row.expireTime || "-" }}</p>
                        <p>生产日期：{{ scope.row.producedTime || "-" }}</p>
                        <!-- <p style="color: green">ERP同步 :{{scope.row.erpSyncount || "-"}}</p> -->
                    </template>
                </el-table-column>
                <!-- 控销规则 -->
                <el-table-column label="控销规则" prop="saleRule" width="300">  
                    <template slot-scope="scope">
                        <p v-if="scope.row.areaSchemeName">区域：{{ scope.row.areaSchemeName || "-" }}</p>
                        <p>客户类型：{{getShopTypeStr(scope.row.shopTypeCode)}}</p>
                        <p >黑名单：{{scope.row.groupSchemeName || "-"}}</p>
                    </template>
                </el-table-column>
                <!-- 销售情况 -->
                <!-- <el-table-column label="销售情况" prop="saleCount" width="200"> 
                    <template slot-scope="scope">
                        <p>采购店数：{{ scope.row.purchaseShopCount }}</p>
                        <p>采购数量：{{ scope.row.purchaseGoodsCount  }}</p>
                        <p>采购金额（元）：{{ scope.row.countMoney  }}</p>
                    </template>
                </el-table-column> -->
                <!-- 合作收入 -->
                <!-- <el-table-column label="合作收入" prop="cooperativeIncome" width="120">
                    <template slot-scope="scope">
                        <div v-if="scope.row.cooperativeIncome !=null">¥{{scope.row.cooperativeIncome}}</div>
                    </template>
                </el-table-column> -->
                <el-table-column label="审核状态" width="100">
                    <template slot-scope="scope">
                        <!-- // 根据状态显示图片 saleStatus	申请状态: 0. 待申请,1. 待审核,2 已通过,3 已驳回,4 已取消合作 -->
                        <span v-if="scope.row.saleStatus === 2">已审核</span>
                        <span v-else-if="scope.row.saleStatus === 3">驳回</span>
                        <span v-else-if="scope.row.saleStatus === 1">待审核</span>
                        <span v-else-if="scope.row.saleStatus === 4">已取消</span>
                    </template>
                </el-table-column>
                <el-table-column label="申请时间" prop="applyTime" width="200"></el-table-column>
                <el-table-column label="操作" width="100" fixed="right" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="handleRevoke(scope.$index)" v-if="scope.row.saleStatus === 1">撤回合作申请</el-button>
                        <div v-if="scope.row.saleStatus === 2">
                            <el-button type="text" @click="handleSalesControl(scope.row)">销售控制</el-button>
                            <el-button type="text" @click="handlePublishProducts(scope.row,'add')" v-if="!scope.row.goodsSkuId">发布商品</el-button>
                            <el-button type="text" @click="handlePublishProducts(scope.row,'edit')" v-if="scope.row.goodsSkuId">编辑商品</el-button>
                            <div v-if="scope.row.goodsStatus">
                                <el-button type="text" @click="handleDown(scope.row)" v-if="scope.row.goodsStatus === 'PUTAWAY'">下架</el-button>
                                <el-button type="text" @click="handleUp(scope.row)" v-else>上架</el-button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页区域 -->
        <div class="pagination-box">
            <el-pagination
                background
                :page-sizes="[10, 20, 30, 50,2]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :current-page="currentPage" 
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">

            </el-pagination>
        </div>
        <!-- 弹窗区域 -->
        <ProductSalesControlDialog :visible="dialogVisible" :product="product" @cancel="handleCancel"/>
    </div>
</template>

<script>
import ProductSalesControlDialog from "./components/ProductSalesControlDialog.vue"
import { 
    querySellerCooperativeProductPage,
    plcBagSaleRelationsRevocation,
    agencyControlPinSkuPutaway,
    agencyControlPinSkuSoldout
 } from "@/api/saleRule/productLine"
 import {
  getEnableListByType,
} from "@/api/productManage/auditProduct";
export default {
    name: "CooperationGoodsList",
    components: {
        ProductSalesControlDialog
    },
    data() {
        return {
            list: [],
            searchForm: {
                goodsName: '',
                type: '',
                status: ''
            },
            currentPage: 1,
            pageSize: 10,
            total: 10,
            dialogVisible: false,
            product:null,
            shopTypeList:[],
            tableHeight: 0,
        }
    },
    methods: {
         // 获取类型
        getEnableListByType () {
            return new Promise((resolve, reject) => {
                getEnableListByType({type: 0}).then(res => {
                    if (res.code == 0) {
                        this.shopTypeList = res.data;
                        resolve(res.data)
                    } else {
                        reject(res.msg)
                    }
                })
            })
        },
        // 获取数据
        getData() {
            querySellerCooperativeProductPage({
                current: this.currentPage,
                size: this.pageSize,
                goodsName: this.searchForm.goodsName,
            }).then(res => {
                if(res.records && res.records.length > 0){
                    const { records, total } = res;
                    this.total = total;
                    this.list = records;
                }else{
                    this.total = 0;
                    this.list = [];
                }
            })
        },
        search() {
            // 搜索功能
            this.currentPage = 1;
            this.getData();
        },
        reset() {
            this.searchForm = {
                goodsName: '',
                type: '',
                status: ''
            }
            // 重置功能
            this.searchForm = {
                goodsName: '',
                type: '',
                status: ''
            };
            this.currentPage = 1;
            this.getData();
        },
        // 待审核撤回申请
        handleRevoke(index) {
            this.$alert('撤回合作申请', '提示', {
                confirmButtonText: '确定',
                showCancelButton: true,
                callback: action => {
                    // 下架功能
                    if(action === 'confirm'){
                        plcBagSaleRelationsRevocation({
                            id: this.list[index].id
                        }).then(res => {
                            if(res){
                                this.$message.success('撤回成功');
                                this.getData();
                            }else{
                                this.$message.error('撤回失败');
                            }
                        })
                    }
                }
            });
            
        },
        // 销售控制
        handleSalesControl(item) {
            // 销售控制功能
            this.product = item;
            this.dialogVisible = true;
        },
        // 发布商品
        handlePublish(index) {

        },
        // 上架
        handleUp(row) {
            this.$alert('上架商品', '提示', {
                confirmButtonText: '确定',
                showCancelButton: true,
                callback: action => {
                    if(action === 'confirm'){
                        this.handleUpAction(row)
                    }
                }
            });
        },
        // 上架操作
        handleUpAction(item){
            agencyControlPinSkuPutaway({
                adPlanId: item.id,
                goodsSkuId: item.goodsSkuId,
            }).then(res => {
                if(res){
                    this.$message.success('上架成功');
                    this.getData();
                }else{
                    this.$message.error('上架失败');
                }
            })
        },
        // 下架
        handleDown(row) {
            this.$alert('下架商品', '提示', {
                confirmButtonText: '确定',
                showCancelButton: true,
                callback: action => {
                    // 下架功能
                    if(action === 'confirm'){
                        this.handledownAction(row)
                    }
                }
            });
        },
        // 下架操作
        handledownAction(item){
            agencyControlPinSkuSoldout({
                adPlanId: item.adPlanId,
                goodsSkuId: item.goodsSkuId,
            }).then(res => {
                if(res){
                    this.$message.success('下架成功');
                    this.getData();
                }else{
                    this.$message.error('下架失败');
                }
            })
        },
        handleCancel() {
            // 关闭弹窗
            this.dialogVisible = false
        },
        handleDelete(index) {
            // 删除功能
        },
        handleCurrentChange(currentPage) {
            // 页码切换功能
            this.currentPage = currentPage;
            this.getData();
        },
        handleSizeChange(pageSize) {
            // 切换每页显示数量功能
            this.currentPage = 1;
            this.pageSize = pageSize;
            this.getData();
        },
        // 发布商品
        handlePublishProducts(row,type) { 
            console.log(row,'--row')
            // 发布商品功能
            this.$router.push({
              path: '/salesControlMange/salesControl-cooperation-publishProducts',
               query: {
                   id: row.id,
                   pageType: type,
                   minCooperativeIncome: row.minCooperativeIncome,
               }
            })
            window.sessionStorage.setItem('agentProductItem', JSON.stringify(row));
        },
        getShopTypeStr (v) {
            if (!v) {
                return '-'
            }
            const arr = v.split(',')
            let strArr = []
            arr.forEach((e) => {
                this.shopTypeList.forEach((l) => {
                if (e == l.typeCode) {
                    strArr.push(l.typeName)
                }
                })
            })
            return strArr.join(',')
        },
    },
    mounted() {
        this.tableHeight = document.documentElement.clientHeight - 300;
    },
    async created() {
        // 获取数据
        // calc(100vh - 260px)
        await this.getEnableListByType()
        this.getData()
    }
}
</script>


<style scoped lang="scss">
    .search-box {
        display: flex;
        align-items: center;
    }
    .table-box {
        padding: 24px;
        background-color: #fff;
        // height: calc(100vh - 260px);
        .el-table {
            // 超出滚动 撑满
        }
        .goods-info{
            margin-left: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            font-size: 14px;
            color: #262626;
            .goods-name{
                font-weight: 600;
                color: #000;
                font-size: 14px;
            }
            span{
                margin-bottom: 5px;
            }
        }
    }
    .pagination-box {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
</style>