<!--  -->
<template>
  <div class="comContent">
    <!-- <el-page-header content="企业资质审核明细" style="padding-top:16px" @back="goBack" /> -->
    <div class="header">企业资质审核明细</div> 
    <!-- <div class="center" style="margin-top:25px;"> -->
    <!-- 内容组件展示部分 -->
    <!-- <p>{{ info.shopName }} - {{ info.status }}</p> -->
    <!-- </div> -->
    <!-- :info="info" -->
    <div class="center">
      <AuditCompanyInfo :mode="mode"
                        ref="auditCompanyInfo"
                        v-if="btnShow == '1'"></AuditCompanyInfo>
      <CompanyInfo ref="infoBox"
                   v-else />
      <div class="status_txt"
           v-if="btnShow == '1'">
        当前审核状态:
        <MyTypes :type="status" />
      </div>
      <div class="dia_btn">
        <MyButton type=""
                  icon=""
                  text="返回上一页"
                  class="btn_w150"
                  @click="goBack" />
        <MyButton v-if="btnShow == '1' && mode == 'view'"
                  type="primary"
                  icon=""
                  text="审核通过"
                  class="btn_w150"
                  @click="openDialog(true)" />
        <MyButton v-if="btnShow == '1' && mode == 'view'"
                  type="primary"
                  icon=""
                  text="驳回"
                  class="btn_w150"
                  @click="openDialog(false)" />
        <MyButton v-if="
            btnShow == '1' && mode == 'view' && permissions.audit_detail_edit
          "
                  type="primary"
                  icon=""
                  text="编辑"
                  class="btn_w150"
                  @click="edit()" />
        <MyButton v-if="
            btnShow == '1' && mode == 'edit' && permissions.audit_detail_edit
          "
                  type="primary"
                  icon=""
                  text="取消编辑"
                  class="btn_w150"
                  @click="cancel()" />
        <MyButton v-if="
            btnShow == '1' && mode == 'edit' && permissions.audit_detail_edit
          "
                  type="primary"
                  icon=""
                  text="确认调整"
                  class="btn_w150"
                  @click="save()" />
      </div>
      <Success ref="sucDom"
               @sure="sucSubmit" />
      <Err ref="errDom"
           @sure="errSubmit" />
    </div>
  </div>
</template>

<script>
import MyTypes from "@/components/myTypes";
import MyButton from "@/components/myButton";
import CompanyInfo from "./components/companyInfo";
import AuditCompanyInfo from "./components/auditCompanyInfo";
import { auditTypes } from "@/utils/enumeration";
import {
  auditCompanyDetail,
  auditCompanyEdit,
  updateAudit,
  shopHistoryDetails
} from "@/api/auditcenter";
import Success from "./components/success.vue";
import Err from "./components/err.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    MyButton,
    Success,
    Err,
    CompanyInfo,
    MyTypes,
    AuditCompanyInfo,
  }, //, MyTable
  data () {
    return {
      btnShow: "",
      auditTrue: false,
      reqId: null,
      mode: "view",
      id: null,
      info: {
        shopName: "",
        status: "",
      },
      status: "",
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    statusText () {
      return auditTypes(this.status);
    },
  },
  watch: {},
  created () {
    const { id, btnShow, logId } = this.$route.query;
    this.btnShow = btnShow;
    if (id) {
      this.reqId = id;
      this.getInfo();
    } else if (logId) {
      this.getHistoryInfo(logId)
    }
  },
  mounted () {
  },
  methods: {
    openDialog (val) {
      if (this.btnShow == "1" && val) {
        if (this.info.scopeIds.length == 0) {
          this.$message.warning("请先选择卖家经营范围");
          return;
        }
      }
      let t = this;
      this.auditTrue = val;
      if (val) {
        this.$refs["sucDom"].open();
      } else {
        this.$refs["errDom"].open();
      }
    },
    edit () {
      this.mode = "edit";
    },
    cancel () {
      this.mode = "view";
    },
    save () {
      // console.log(this.info.idNumber)
      // console.log(this.info.certifys[0].idNumber)
      if (this.info.shopTypeCode != '1') {
        this.info.idNumber = this.info.certifys[0].idNumber;
      }
      updateAudit({
        ...this.info,
        invoiceType: this.info.invoiceType.join(','),
        certifys: this.info.certifys.map((item) => {
          return {
            ...item,
            images: item.imgs,
          };
        }),
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("保存成功");
          this.mode = "view";
          // this.getInfo()
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    sucSubmit (obj) {
      let certifyList = [];
      this.info.certifys.forEach((element) => {
        let obj = {
          code: element.code,
          startValidDate: element.date[0],
          endValidDate: element.date[1],
        };
        certifyList.push(obj);
      });
      let d = {
        scopeIds: this.info.scopeIds.join(","),
        certifyList: certifyList,
        ...obj,
        // relationType: "01", // 00.商户 01.省总 02.地总 03，诊所
        // deptId: 415, // 组织机构ID
        // orgCode: "ty94qfEn", // 组织机构CODE
        // roleId: 2, // 角色待定
        status: "2", // 1.未通过，2.通过
      };
      this.goAudit(d);
    },
    errSubmit (desc) {
      // console.log('remarks:'+desc)
      this.goAudit({ remarks: desc, status: "1" });
    },
    goAudit (obj) {
      let d = { ...obj, shopId: this.id };
      auditCompanyEdit(d).then((res) => {
        if (res.code === 0) {
          this.$message.success("操作成功");
          this.goBack();
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    getInfo () {
      auditCompanyDetail(this.reqId).then((res) => {
        // console.log(res)
        if (res.code === 0) {
          this.info = res.data;
          this.$set(this.info, "scopeIds", []);
          this.id = res.data.id;
          this.status = this.info.status;
          console.log(
            'this.$refs["auditCompanyInfo"].setInfo(res.data)',
            this.$refs["auditCompanyInfo"]
          );
          if (this.btnShow != "1") this.$refs["infoBox"].getInfo(res.data);
          else if (this.btnShow == "1") {
            this.$refs["auditCompanyInfo"].setInfo(res.data);
          }
          this.info.status = auditTypes(this.info.status)
        }
      });
    },
    // 日志详情
    getHistoryInfo (id) {
      shopHistoryDetails(id).then((res) => {
        if (res.code === 0) {
          this.info = res.data || [];
          this.$set(this.info, "scopeIds", []);
          this.id = res.data?.id;
          this.info.status = 2
          this.$set(this.info, "disabled", true);
          if (this.btnShow != "1") this.$refs["infoBox"].getInfo(this.info);
          else if (this.btnShow == "1") {
            this.$refs["auditCompanyInfo"].setInfo(this.info);
          }

          // this.$refs["infoBox"].getInfo(this.info);
          // this.info.status = auditTypes(this.info.status)
        }
      });
    },
    goBack () {
      // console.log('go back');
      this.$router.push("/audit/auditcenter-auditcompany");

      // this.$router.go(-1)：后退+刷新；
      // this.$router.go(0)：刷新；
      // this.$router.go(1) ：前进
      // this.$router.back():后退 ；
      // this.$router.back(0) 刷新；
      // this.$router.back(1)：前进
    },
  },
};
</script>
<style lang="scss" scoped>
.comContent {
  padding: 0px;
}
.header {
  height: 56px;
  line-height: 56px;
  padding: 0 24px;
  border-bottom: 1px solid #f2f2f2;
}
.center {
  margin: 0 24px;
}
.btn_w150 {
  width: 150px;
  margin-right: 8px;
}
.dia {
  &_btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;
  }
}
.status {
  &_txt {
    margin: 10px 0;
    font-size: 14px;
    span {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
