<template>
  <div class="warp">
    <div class="payMain"> 
      <!-- 收款账户信息使用康贝的账户信息 -->
      <el-form label-position="top" :model="userForm" ref="userForm" :disabled="true" >
        <el-form-item label="收款户名" prop="name">
          <el-input   v-model="userForm.name" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="bank">
          <el-input  v-model="userForm.bank" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="收款账户" prop="account">
          <el-input   v-model="userForm.account" style="width: 300px;"></el-input>
        </el-form-item>
      </el-form>
      
      <el-divider></el-divider> 

      <div class="normalText">
        <el-checkbox v-model="payType" @change="changeCheckBox">开通线下对公支付</el-checkbox>
        <div class="infoText">请您先充值保证金，再开通线下对公支付。</div>
      </div>

      <el-dialog title="开通线下支付" :close-on-click-modal="false" :visible.sync="dialogVisible" width="30%"
        :before-close="handleClose">
        <span>
          {{ OfflineMsg }}
        </span>
        <!-- <span>缴纳保证金后才能开启。缴纳保证金会使店铺排序更靠前，获得更多商品曝光。</span> -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false; payType = false" size="small">取消</el-button>
          <el-button type="primary" @click="jiaona" size="small">去缴纳</el-button>
        </span>
      </el-dialog>
   
    </div>
  </div>
 
</template>
<script>
import {
  updateOfflinePayment,
  queryOfflinePayment,
  validOfflineOpening 
} from "@/api/shop";
export default {
  props: {
    boundMoney: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    return {
      onLine: true,
      dialogVisible: false,
      dialogVisibleForm: false,
      payType: false,
      userForm: {
        name: '',
        bank: '',
        account: '',
      },
      user: {
        name: '',
        bank: '',
        account: '',
      },
      userRules: {
        name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        bank: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        account: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },
      saveEnable: false,
      OfflineMsg: '', // 线下支付提示信息
    }
  },
  async mounted () {
    this.query() 
  },
  methods: {
    // 1.查询康贝账户，没开通，提示去开通，
    //2. 开通线下支付时，判断有无充值保证金，若没有充值保证金则引导用户充值保证金 。
    //


    handleClose () {
      this.dialogVisible = false
      this.payType = false
    },
    handleCloseForm () {
      this.dialogVisibleForm = false
      this.payType = false
    },
    // 查询是否开启
    async query () {
      const resp = await queryOfflinePayment({ shopId: null }) //accountName: "1"depositBank: "2"id: "1721728830094307330"offlinePaymentFlag: "YES"receivablesAccount: "3"
      if (resp) {
        this.userForm.name = resp.accountName
        this.userForm.bank = resp.depositBank
        this.userForm.account = resp.receivablesAccount
        this.payType = resp.offlinePaymentFlag == 'YES' 
      }
    },
    // 去缴纳保证金
    jiaona () {
      this.$router.push('/financialcenter/surety-index')
    },
    // 切换是否开通
    changeCheckBox (val) {
      validOfflineOpening().then(res => {
        if (res) {
          switch (res.code){
            case 0: 
            this.dialogVisible = false;
            this.submitOff()
              break;
            case 1:
              //   '未设置收款账户' ;
              this.OfflineMsg = '请您设置收款账户，再开通线下对公支付';
              this.dialogVisible = true 
              break;
            case 2:
            // '保证金不足' ;
            this.OfflineMsg = '缴纳保证金后才能开启，缴纳保证金会使店铺排序更靠前，获得更多商品曝光'; 
            this.dialogVisible = true 
              break; 
          }
        }  
      }) 
    },
 
    async submitOff () {
      try {
        const valid = await this.$refs.userForm.validate()
        if (valid) {
          let obj = {
            offlinePaymentFlag: this.payType ? 'YES' : 'NO',//offlinePaymentFlag	是否开启线下支付（YES：开启，NO：未开启）
            accountName: this.userForm.name,//收款户名
            depositBank: this.userForm.bank,//开户银行
            receivablesAccount: this.userForm.account,//收款账号
          }
          const resp = await updateOfflinePayment(obj)
          if (resp) {
            this.$message.success('更新成功')
            this.query()
          }
        }

      } catch (error) {

      }

    }
  }
}
</script>
<style lang="scss" scoped>
.warp {
  background-color: #fff;
  width: 100%;
  min-height: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 24px; 
}
.payMain {
  //width: 300px;
  .normalText {
    font-size: 16px;
    font-weight: 400;
    color: #262626;
    line-height: 1.5;
  }

  .inLine {
    display: flex;
    align-items: center;

    .infoText {
      font-size: 14px;
      font-weight: 500;
      color: orange;
      margin-left: 12px;
    }
  }
}
</style>