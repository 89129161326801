<template>
  <div class="mainView">
    <div class="header">加入仓库</div>
    <div class="eachRow"
         style="border-bottom: 1px solid #f5f5f5;">
      <div v-if="form.goodsBaseInfo && form.goodsBaseInfo.status == 'SY_SOLDOUT'">
        <span>
          下架原因
        </span>
        <div style="padding: 10px 0;width: 600px;">
          <el-input type="textarea"
                    :value="form.goodsBaseInfo.checkReason"
                    disabled></el-input>
        </div>

      </div>
      <div class="titleView">
        <div class="rowLine"></div>
        <span class="eachRowTitle">商品基本信息</span>
      </div>
      <div class="goodsInfoView">
        <el-button type="text"
                   class="detailBtn"
                   v-if="activeName == 'SKU_FAIL' || activeName == 'SKU_AWAIT' || activeName == 'add'"
                   @click="detailError">详情/纠错</el-button>
        <el-image style="width: 100px;height: 100px;"
                  :src="form.goodsBaseInfo.image || avater"
                  :preview-src-list="[form.goodsBaseInfo.image || avater]"
                  alt="" />
        <div class="goodsInfoText">
          <div class="textInLine">
            <span class="textBetween">产品名称</span>
            <span>{{ form.goodsBaseInfo.name || '-'}}</span>
          </div>
          <div class="textInLine">
            <span class="textBetween">批准文号</span>
            <span>{{ form.goodsBaseInfo.approvalNumber || '-'}}</span>
          </div>
          <div class="textInLine">
            <span class="textBetween">规格</span>
            <span>{{ form.goodsBaseInfo.specification || '-'}}</span>
          </div>
          <div class="textInLine">
            <span class="textBetween">生产厂家</span>
            <span>{{ form.goodsBaseInfo.factory || '-'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="eachRow">
      <div class="titleView">
        <div class="rowLine"></div>
        <span class="eachRowTitle">SKU信息</span>
      </div>
      <div class="table"  v-for="(item,index) in form.skuList" :key="index" style="padding: 8px 0;border-bottom: 1px solid #f5f5f5;">
        <div class="tableBox" >
          <span class="spanStyle">
            SKU状态
            <el-input :disabled="true"
                      class="inputStyle"
                      :value="getStatus(item.status)"
                      size="mini"></el-input>
          </span>
          <span class="spanStyle">
            ERP编码
            <el-input :disabled="true"
                      class="inputStyle"
                      size="mini"
                      v-model="item.medicineCode"></el-input>
          </span>
          <span class="spanStyle">
            价格/元
            <el-input :disabled="true"
                      class="inputStyle"
                      size="mini"
                      v-model="item.salePrice"></el-input>
          </span>
          <span class="spanStyle">
            市场价/元
            <el-input :disabled="true"
                      class="inputStyle"
                      size="mini"
                      v-model="item.marketPrice"></el-input>
          </span>
          <span class="spanStyle">
            建议零售价/元
            <el-input :disabled="true"
                      class="inputStyle"
                      size="mini"
                      v-model="item.purchasePrice"></el-input>
          </span>
         
        </div>
        <div class="tableBox2">
          <div class="tableBox2Inline">
            <el-link v-show="!disabled"
                     :disabled="item.status == 'PUTAWAY'||item.status == 'AWAIT' || item.status == 'FAIL'|| item.status == 'SY_SOLDOUT'"
                     :underline="false"
                     type="primary"
                     @click="listing(item)">上架</el-link>
            <el-link v-show="!disabled"
                     :disabled="item.status == 'SOLDOUT'||item.status == 'AWAIT'||item.status == 'FAIL' || item.status == 'SY_SOLDOUT'"
                     :underline="false"
                     style="margin-left:16px"
                     type="primary"
                     @click="delist(item)">下架</el-link>
          </div>
          <div class="tableBox2Inline">
            <el-link :underline="false"
                     type="primary"
                     @click="detail(item)">详情</el-link>
            <el-link v-show="!disabled"
                     :disabled="item.status == 'SY_SOLDOUT'"
                     :underline="false"
                     style="margin-left:16px"
                     type="primary"
                     @click="goAddSku(item)">编辑</el-link>
            <el-link :underline="false"
                     type="primary"
                     style="margin-left:16px"
                     @click="caozuo(item)">操作日志</el-link>
          </div>
          </div>
      </div>
      <div class="addGoods"
           v-if="!disabled"
           @click="goAddSku()">
        <span class="addFont">
          +
        </span>
        <span style="font-size:14px;color:#595959">
          添加SKU
        </span>
      </div>
      <div style="margin-top:10px">
        <!-- <el-button v-if="!disabled"
                   type="primary"
                   style="width:160px"
                   size="small"
                   @click="submit">保存并提交</el-button> -->
        <el-button size="small"
                   style="width:160px"
                   @click="back()">返回</el-button>
      </div>
      <el-dialog title="商品信息变更日志"
                 :visible.sync="dialogVisible"
                 width="70%"
                 :before-close="handleClose">
        <div style="height: 600px; width: 100%;overflow: auto;">
          <MyTable :tableData="tableData"
                   :index="false"
                   :operation="false"
                   :table-option.sync="tableOption"
                   style="width: 100%;height: 100%;">
          </MyTable>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import MyTable from '@/components/myTable'
import { checkSkuStatus } from '@/utils/status'
import avater from "/public/img/default.png"
import imgUpload from '@/components/imgUpload'
import { complianceSku, skuStatus, warehouseAdd, batchUpdate, queryGoodsSkuEditLogList } from '@/api/commodityList/index'
export default {
  components: { imgUpload, MyTable },
  data () {
    return {
      dialogVisible: false,
      avater: avater,
      id: '',
      disabled: false,
      type: '',
      activeName: '',
      form: {
        goodsBaseInfo:{}},
      tableData: [
      ],
      tableOption: [
        { label: '操作人', prop: 'operName' },
        { label: 'IP地址', prop: 'operIp' },
        { label: '变更前', prop: 'preEdit' },
        { label: '变更后', prop: 'postEdit' },
        { label: '变更时间', prop: 'createTime' }
      ]
    }
  },
  created () {
    this.activeName = this.$route.query.activeName
    this.type = this.$route.query.type
    this.id = this.$route.query.id
    if (this.type == 'edit') {
      // this.query()
    } else if (this.type == 'detail') {
      this.disabled = true
    }
    this.complianceSku()
  },
  computed: {
    getStatus () {
      return status => checkSkuStatus(status)
    },
  },
  methods: {
    // 手动关闭
    handleClose () {
      this.dialogVisible = false
    },
    // 操作日志
    caozuo (item) {
      queryGoodsSkuEditLogList(item.id).then(res => {
        if (res.code == 0) {
          this.tableData = res.data
        } else {
          this.tableData = []
        }
      })
      this.dialogVisible = true
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload (file) {
      const isJPEG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isJPG = file.type === 'image/jpg';
      const isPDF = file.type === 'application/pdf';
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG && !isPNG && !isJPEG && !isPDF) {
        this.$message.error('上传文件只能是jpeg/png/jpeg/pdf格式!');
        return false
      }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!');
        return false
      }
      return true;
    },
    onError (err) {
      const data = JSON.parse(JSON.stringify(err))
      if (data.status != 200) {
        this.$message.error('上传失败')
      }
    },
    handlePreview () {

    },
    handleExceed () {

    },
    handleRemove () {

    },
    beforeRemove () {

    },
    // 新增或者编辑
    goAddSku (item) {
      let params = item ? { fromPage: 'edit', id: item.id, name: this.form.goodsBaseInfo.name, activeName: this.activeName } : { fromPage: 'add', id: this.id, name: this.form.goodsBaseInfo.name, activeName: this.activeName }
      // /goodsManage-addGoodsSku
      this.$router.push({
        path: '/productManage/publishProduct-addGoodsSku',
        query: params,
      })
    },
    // 详情
    detail (item) {
      this.$router.push({
        path: '/productManage/publishProduct-addGoodsSku',
        query: {
          fromPage: 'detail',
          id: item.id,
          name: this.form.goodsBaseInfo.name,
          activeName: this.activeName
        }
      })
    },
    // 上架
    listing (item) {
      let params = {
        id: item.id,
        status: 'PUTAWAY'
      }
      skuStatus(params).then(res => {
        if (res.code == 0) {
          this.$message.success('上架成功')
          this.complianceSku()
        } else {
          this.$message.success(res.msg)
        }
      })
    },
    // 下架
    delist (item) {
      let params = {
        id: item.id,
        status: 'SOLDOUT'
      }
      skuStatus(params).then(res => {
        if (res.code == 0) {
          this.$message.success('下架成功')
          this.complianceSku()
        } else {
          this.$message.success('下架失败')
        }
      })
    },
    // 详情纠错
    detailError () {
      this.$router.push(
        {
          path: '/productManage/goodsManage-correct-details',
          query: {
            id: this.id,
            status: this.activeName,
            type: this.type
          }
        }
      )
    },
    // 保存并提交
    submit () {
      let params = {
        goodsId: this.id,
        ...this.form.skuList,
      }
      batchUpdate(params).then(res => {
        if (res.code == 0) {
          this.$message.success('保存成功')
          this.$router.back(-1)
        } else {
          this.$message.error('保存失败')
        }
      })
    },
    // 返回
    back () {
      this.$router.back(-1)
    },
    // 根据基库商品id查询商品对应的sku信息
    complianceSku () {
      complianceSku(this.id).then(res => {
        if (res.code == 0) {
          this.form = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>
<style lang="scss">
.mainView {
  height: 100%;
  overflow: auto;
  .header {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
    height: 56px;
    line-height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid #f2f2f2;
  }
  background-color: #fff;
  .eachRow {
    background-color: #fff;
    border-radius: 4px;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;

    .table {
      padding: 16px 16px;
      display: flex;
      align-items: center;
      // border-bottom: 1px solid #f5f5f5;
      .tableBox {
        display: flex;
        flex: 1;
        justify-content: space-between;
        // align-content: center;
        align-items: center;
        .spanStyle {
          // width: calc((100% - 240px) / 5);
          font-size: 14px;
          .inputStyle {
            // width: calc(100% - 95px);
            width: 120px;
            margin-left: 0px;
          }
        }
      }
      .tableBox2{
        display: flex;
        flex-direction: column;
        margin-left: 36px;
        .tableBox2Inline{
          height: 26px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .titleView {
    display: flex;
    align-items: center;

    .rowLine {
      width: 4px;
      border-color: 1px;
      background-color: var(--main-color);
      height: 16px;
      margin-right: 8px;
    }

    .eachRowTitle {
      font-size: 16px;
      color: #f5222d;
      font-weight: 600;
    }
  }

  .goodsInfoView {
    display: flex;
    align-items: center;
    padding: 15px;
    margin-top: 30px;
    position: relative;

    .detailBtn {
      position: absolute;
      top: 0px;
      right: 15px;
    }

    img {
      width: 130;
      height: 130px;
    }

    .goodsInfoText {
      font-size: 14px;
      color: #262626;
      display: flex;
      flex-direction: column;
      height: 130px;
      justify-content: space-between;
      margin-left: 45px;

      .textInLine {
        display: flex;
        align-items: center;

        .textBetween {
          width: 70px;
          text-align-last: justify;
          margin-right: 50px;
        }
      }
    }
  }

  .fillView {
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }

    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
  .addGoods {
    width: 100%;
    height: 40px;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
    .addFont {
      // border: 1px dashed #d9d9d9;
      display: flex;
      width: 18px;
      font-size: 16px;
      justify-content: center;
      margin-right: 5px;
    }
  }
}
</style>