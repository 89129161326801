<template>
  <div class="fill-product-info-root">
    <!-- 商品下架原因 -->
    <div class="sold-out" v-if="formIn.platformCheckReason">
      <div class="sold-out-title">平台下架原因</div>
      <div class="sold-out-content">
        {{ formIn.platformCheckReason }}
      </div>
    </div>
    <div class="product-info">
      <div class="pic-frame">
        <!-- <el-image
          :src="goodsDetail ? goodsDetail.compGoodsBaseVo.image : ''"
          class="pic"
        /> -->
        <img :src="goodsDetail ? goodsDetail.compGoodsBaseVo.image : ''" class="pic"  v-if="goodsDetail && goodsDetail.compGoodsBaseVo && goodsDetail.compGoodsBaseVo.image"/>

      </div>
      <div class="txt-content">
        <div class="big-title">
          {{ goodsDetail ? goodsDetail.compGoodsBaseVo.name : "" }}
        </div>
        <div class="desp">
          {{ goodsDetail ? goodsDetail.compGoodsBaseVo.specification : "" }}
        </div>
        <div class="desp">
          {{ goodsDetail ? goodsDetail.compGoodsBaseVo.factory : "" }}
        </div>
        <div class="desp">
          {{ goodsDetail ? goodsDetail.compGoodsBaseVo.approvalNumber : "" }}
        </div>
      </div>
      <div class="flag">{{ formIn.activityType | selectTypeDetail }}</div>
    </div>
    <el-form :disabled="isDisabled">
      <div class="big-content">
        <formItem
          v-if="
            formIn.activityType == 1 ||
            formIn.activityType == 2 ||
            formIn.activityType == 3
          "
          :fileList="fileList"
          ref="formItem"
          :isDisabled="isDisabled"
          :AddIcon="AddIcon"
          :unitList="unitList"
          :name="goodsDetail.compGoodsBaseVo.name"
          :shopLabelList="shopLabelList"
          :imageUrlList="imageUrlList"
          :formIn="formIn"
          @handleUpload="handleUpload"
          @handleAddUserGroup="handleAddUserGroup"
          @onRemoveHandler="onRemoveHandler"
        />
        <formItemGift
          v-if="formIn.activityType == 4"
          ref="formItem"
          :isDisabled="isDisabled"
          :fileList="fileList"
          :AddIcon="AddIcon"
          :unitList="unitList"
          :shopLabelList="shopLabelList"
          :imageUrlList="imageUrlList"
          :formIn="formIn"
          @handleUpload="handleUpload"
          @handleAddUserGroup="handleAddUserGroup"
          @onRemoveHandler="onRemoveHandler"
        />

        <div class="harf-block" v-if="formIn.activityType != 4">
          <div class="big-block">
            <div class="title-block">
              <span class="text">销售规则</span>
            </div>
            <div class="form-content">
              <div class="row">
                <div class="tip"><span class="weight">*</span>配置方式</div>
                <div class="value fill">
                  <el-radio-group v-model="formIn.type" @change="salesgroupinfo">
                    <el-radio :label="0"> 设置区域、客户类型及黑名单 </el-radio>
                    <el-radio :label="1">白名单</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <template v-if="formIn.type === 0">
                <div class="row mutlti big">
                  <div class="tip">地域</div>
                  <div class="value fill">
                    <div class="flag-block">
                      <el-radio
                        v-model="formIn.areaId"
                        :label="item.areaId"
                        :key="item.areaId"
                        v-for="item in areaIdList ? areaIdList : []"
                      >
                        {{ item.schemeName }}
                      </el-radio>
                    </div>
                  </div>
                </div>
                <div class="row contine">
                  <div class="tip"></div>
                  <div class="value fill small-tip">
                    <span> 注意：仅限已选地域对应客户可购买当前商品。 </span>
                  </div>
                  <div class="value">
                    <el-button size="small" icon="el-icon-plus" @click="handleAddArea"
                      >新增地域</el-button
                    >
                  </div>
                </div>
              </template>
              <div class="row mutlti big" v-if="formIn.type == 0">
                <div class="tip">客户类型</div>
                <div class="value fill">
                  <div class="flag-block">
                    <el-checkbox
                      style="margin-right: 30px"
                      v-model="checkAll"
                      :indeterminate="isIndeterminate"
                      @change="handleCheckAllChange"
                      >全选</el-checkbox
                    >
                    <el-checkbox-group
                      v-model="formIn.shopTypeCode"
                      @change="changeCheckList"
                    >
                      <el-checkbox
                        :label="item.typeCode"
                        :key="item.typeCode"
                        v-for="item in listData ? listData : []"
                        >{{ item.typeName }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </div>
              </div>
              <div class="row mutlti big" v-if="formIn.type == 1">
                <div class="tip">客户类型</div>
                <div class="value fill">
                  <div class="flag-block">
                    <el-checkbox
                      style="margin-right: 30px"
                      v-model="checkAll"
                      :indeterminate="isIndeterminate"
                      :disabled="true"
                      >全选</el-checkbox
                    >
                    <el-checkbox-group v-model="formIn.shopTypeCodeNew" :disabled="true">
                      <el-checkbox
                        :label="item.typeCode"
                        :key="item.typeCode"
                        v-for="item in listData ? listData : []"
                        >{{ item.typeName }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </div>
              </div>

              <div class="row contine">
                <div class="tip"></div>
                <div class="value fill small-tip">
                  <span> 注意：被勾选的客户类型对应的客户可购买当前商品。 </span>
                </div>
              </div>
              <div class="row mutlti big">
                <div class="tip">
                  {{ formIn.type == "1" ? "白名单" : "黑名单" }}
                </div>
                <div class="value fill">
                  <div class="flag-block">
                    <el-radio
                      v-model="formIn.groupId"
                      :label="item.id"
                      :key="item.id"
                      v-for="item in userList ? userList : []"
                    >
                      {{ item.schemeName }}
                    </el-radio>
                  </div>
                </div>
              </div>
              <div class="row mutlti big" v-if="formIn.type == 1">
                <div class="tip">指定客户(九和)</div>
                <div class="value fill">
                  <div class="flag-block">
                    <el-radio
                      v-model="formIn.groupId"
                      :label="item.id"
                      :key="item.id"
                      v-for="item in userList2 ? userList2 : []"
                    >
                      {{ item.schemeName }}
                    </el-radio>
                  </div>
                </div>
              </div>
              <div class="row contine">
                <div class="tip"></div>
                <div class="value fill small-tip">
                  <span> 注意：黑名单客户不可购买当前商品。 </span>
                </div>
                <div class="value">
                  <el-button size="small" icon="el-icon-plus" @click="handleAddUserGroup">
                    新增用户组
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="gloabl-btn" @click="handleLookOtherSku(formIn)">查看其他sku</div> -->
      </div>
    </el-form>

    <div class="bottom">
      <el-button @click="handleBack">返回上一页</el-button>
      <el-button type="primary" @click="doSubmit" v-if="fromPage == 'edit'"
        >修改保存商品</el-button
      >
    </div>

    <UploadImageView
      ref="imgupload"
      request-id="1"
      :imgType="imgType"
      @upload="uploadSubmit"
    />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import UploadImageView from "@/views/myQualification/components/UploadImage.vue";

const IConst_Small = require("@/assets/public/icon-small.png");
const IConst_See_Icon = require("@/assets/zz/see-icon.png");
const IConst_Del_Icon = require("@/assets/zz/del-icon.png");
const IConst_Add_Icon = require("@/assets/zz/add-icon.png");

import { getunitList, getEnableListByType } from "@/api/productManage/auditProduct";

// 查询商品基础库详情
import { getCompGoodsBaseDetail } from "@/api/goodsManage/baseDepot";
// getCompGoodsBaseDetail
import {
  skuUpdate,
  skuAdd,
  skuId,
  groupInfos,
  salesgroupinfo,
  getErpStock,
  getErpPrice,
  getErpDate,
  skuUpdateExemption,
} from "@/api/salesControl/commodity";
import { getLabelInfoList } from "@/api/goods";
import dayjs from "dayjs";
import _ from "lodash";
import fileUpload from "@/components/fileUpload";
import formItem from "../components/formItem.vue";
import formItemGift from "../components/formItemGift.vue";

let g_save = false;

export default {
  components: { ElImageViewer, UploadImageView, fileUpload, formItem, formItemGift },
  data() {
    return {
      smallIcon: IConst_Small,

      SeeIcon: IConst_See_Icon,
      DelIcon: IConst_Del_Icon,
      AddIcon: IConst_Add_Icon,

      action: process.env.VUE_APP_BASE_API + "/product/goodsbase/upload",

      imgType: "",
      IConst_Max_Pic_Count: 5,
      imageUrlList: [],
      limit: 1,

      fileList: [], //批件/药检报告等

      shopLabelList: [], //可选商品标签

      unitList: [], //可选单位

      areaIdList: [
        {
          areaId: "0000028",
          schemeName: "不限",
        },
      ], //地域列表

      listData: [], // 客户类型类别

      userList: [], //用户组列表

      isIndeterminate: false, //全选，不确定效果,选择了部分
      checkAll: true, //全选客户类型

      formIn: {
        originalPrice: null, // 原价 (单品包邮)
        activityDescription: "", //活动说明
        upperLimitRefreshCycle: 1, //上限刷新周期
        upperLimitStartTime: "", //上限启用时间
        eventCountdownFlag: "", //是否显示倒计时样式
        activityStartTime: "", //活动开始时间
        activityEndTime: "", //活动结束时间
        purchaseUpperLimit: 0, //单个药店采购上限。为空或0表示不限制
        beSynDate: false, //同步有效期和生产日期
        medicineCode: "", //ERP编码
        salePrice: "", //价格
        beSynPrice: false, //自动同步价格
        marketPrice: "", //市场价
        purchasePrice: "", //建议零售价
        goodsSkuInventory: "", //总库存
        beSynStock: false, //自动同步库存
        goodsSkuUnit: "", //单位
        startWholesale: "", //起批量
        useTimes: false, //配数购买
        expireTime: "", //有效期至
        producedTime: "", //生产日期
        goodsSkuTitle: "", //商品标题
        batchNumber: "", //产品批号
        imgRegion: 1, //1 使用平台图片 2 上传图片
        shopLabelId: "", //商品标签  数据来源待确认
        sortCode: "1", //店铺页排序
        unit: null, //单位
        type: 0, //配置方式 0 设置区域、客户类型及黑名单  1 白名单
        areaId: "0000028", //地域组id
        shopTypeCode: [], //客户类型：1.诊所,2.春播运营平台,3.医药公司
        groupId: "", //用户组
        groupBuyPrice: "", //成团价
        userMaxTotalBuyCount: "", //	单用户累计购买上限		false
        maxGroupTotalCount: "", //	拼团总量上限
        multiple: 1, //购买倍数
        activeInventory: "", //活动库存
        shopTypeCodeNew: [], //白名单默认客户类型选择全部，并且不可以修改
        uniqueFlag: 1, //能否特价凑单 0 能 1 不能
        showInventoryFlag: 0, //是否展示给客户剩余库存量 0 不展示 1 展示
        chargeFlag:0, //收取赠品金 0 否 1是
      },
      goodsDetail: { compGoodsBaseVo: {} },
      fromPage: "", //页面类型
      isDisabled: false, //是否禁止输入,
      selectType: {},
      userList2: [],
    };
  },
  computed: {
    // goodsDetail() {
    //   return this.$store.state.publicGoods.publicConfig.goodsDetail;
    // },
  },
  filters: {
    selectTypeDetail(e) {
      if (e == "1") return "一口价";
      if (e == "2") return "特价";
      if (e == "3") return "单品包邮";
      if (e == "4") return "赠品";
    },
  },
  created() {
    // this.initViews();
    this.doLoadLabelInfoList();
    this.getunitList();
    this.groupInfos();
    this.getEnableListByType();
    this.getGoodsBaseDetail();
    this.getDetail();
  },
  beforeDestroy() {
    if (g_save) {
      const fill = _.cloneDeep(this.$data);
      this.$store.dispatch("publicGoods/setFillInfo", fill);
      g_save = false;
    }
  },
  mounted() {},
  methods: {
    getDetail() {
      if (this.$route.query.fromPage == "detail") {
        this.isDisabled = true;
      }
      if (
        this.$route.query.fromPage == "detail" ||
        this.$route.query.fromPage == "edit"
      ) {
        this.fromPage = this.$route.query.fromPage;
        // 查询详情
        let params = {};
        params.skuId = this.$route.query.skuId;
        this.formIn.skuId = this.$route.query.skuId;
        skuId(params.skuId).then((res) => {
          this.formIn = Object.assign(this.formIn, res);
          this.formIn.imgRegion = res.goodsSkuImageList ? 2 : 1;
          if (res.goodsSkuImageList) {
            this.imageUrlList = res.goodsSkuImageList.split(",");
          }
          if (res.goodsSkuFileList) {
            this.fileList = JSON.parse(res.goodsSkuFileList);
            if (this.$refs["formItem"]) {
              this.$refs["formItem"].showFileList(this.fileList);
            }
          }

          if (this.formIn.type != undefined && this.formIn.type != null) {
            this.formIn.type = parseInt(this.formIn.type);
          }
          if (res.shopTypeCode) {
            this.formIn.shopTypeCode = res.shopTypeCode.split(",");
          } else {
            this.formIn.shopTypeCode = [];
            this.checkAll = false;
          }
          this.formIn.multiple = res.multiple || 1; //
          if (this.formIn.activityType == 1) {
            this.selectType.label = "一口价";
          }
          if (this.formIn.activityType == 2) {
            this.selectType.label = "特价";
          }
          if (this.formIn.activityType == 3) {
            this.selectType.label = "单品包邮";
          }
          if (this.formIn.activityType == 4) {
            this.selectType.label = "赠品";
          }
        });
      }
    },
    // 删除上传的文件
    onRemoveHandler(file) {
      let index = this.fileList.indexOf(file);
      this.fileList.splice(index, 1);
    },
    getGoodsBaseDetail() {
      if (
        this.$route.query.fromPage == "detail" ||
        this.$route.query.fromPage == "edit"
      ) {
        // 查询详情
        let params = {};
        params.isMerchant = true;
        params.id = this.$route.query.goodsId;
        this.formIn.goodsId = this.$route.query.skuId;
        getCompGoodsBaseDetail(params).then((res) => {
          this.goodsDetail.compGoodsBaseVo = res.data.compGoodsBaseVo;
          // console.log(this.goodsDetail.compGoodsBaseV)
          this.$nextTick(() => {
            this.salesgroupinfo();
          });
        });
      }
    },

    initViews() {
      let publicConfig = this.$store.state.publicGoods.publicConfig;
      if (publicConfig) {
        if (publicConfig.fillProductInfoForm) {
          if (publicConfig.fillProductInfoForm.formIn) {
            let formInStore = publicConfig.fillProductInfoForm.formIn;
            for (let key in formInStore) {
              if (this.formIn.hasOwnProperty(key)) {
                this.formIn[key] = formInStore[key];
              }
            }
          }
        }

        this.$store.dispatch("publicGoods/setFillInfo", null);
      }
      if (this.goodsDetail) {
        const compGoodsBaseVo = this.goodsDetail.compGoodsBaseVo;
        this.formIn.goodsSkuTitle =
          `${compGoodsBaseVo.brand ? compGoodsBaseVo.brand + " " : ""}` +
          compGoodsBaseVo.name +
          " " +
          compGoodsBaseVo.specification;
      }
    },
    doSubmit() {
      //校验输入

      this.formIn = Object.assign(this.formIn, this.$refs["formItem"].formIn);
      // console.log(this.formIn, '---this.formIn')
      if (this.$refs["formItem"].fileList) {
        this.fileList = this.$refs["formItem"].fileList;
      }
      if (this.selectType.label == '赠品') { 
        if(this.formIn.chargeFlag && !this.formIn.salePrice) {
          this.$message.warning("请输入价格");
          return;
        }else if (!this.formIn.salePrice) {
          this.formIn.salePrice = 0;  
        } 
      } 
      
      if (this.formIn.goodsSkuInventory == "") {
        this.$message.warning("请输入总库存");
        return;
      }

      console.log(this.selectType, "this.selectType");

      //校验输入
      if (this.selectType.label == "单品包邮") {
        if (!this.formIn.groupBuyPrice || this.formIn.groupBuyPrice == 0.0) {
          this.$message.warning("请输入价格");
          return;
        }
        if (!this.formIn.userMaxTotalBuyCount) {
          this.$message.warning("请输入单店采购上限");
          return;
        }
        if (!this.formIn.maxGroupTotalCount) {
          this.$message.warning("请输入采购总上限");
          return;
        }
      }

      if (this.selectType.label == "一口价") {
        if (!this.formIn.salePrice || this.formIn.salePrice == 0.0) {
          this.$message.warning("请输入价格");
          return;
        }
      }

      

      if (this.selectType.label == "单品包邮" || this.selectType.label == "特价") {
        if (!this.formIn.multiple) {
          this.$message.warning("请输入购买倍数");
        }
      }

      if (this.selectType.label == "特价") {
        if (!this.formIn.activeInventory) {
          this.$message.warning("请输入活动库存");
          return;
        }
        // if (this.formIn.purchaseUpperLimit != 0 && this.formIn.purchaseUpperLimit != null) {
        //   this.$message.warning("请输入单店采购上限");
        //   return;
        // }

        if (this.formIn.uniqueFlag != 1 && this.formIn.uniqueFlag != 0) {
          this.$message.warning("请选择能否特价凑单");
          return;
        }
      }

      if (this.formIn.activityType == 1 || this.formIn.activityType == 2) {
        if (!this.formIn.startWholesale) {
          this.$message.warning("请输入起批量");
          return;
        }
      }
      if (this.formIn.activityType == 3) {
        if (!this.formIn.startWholesale) {
          this.$message.warning("请输入包邮量");
          return;
        }
      }

      if (!this.formIn.expireTime) {
        this.$message.warning("请选择有效期至");
        return;
      }
      if (!this.formIn.producedTime) {
        this.$message.warning("请选择生产日期");
        return;
      }
      if (this.formIn.startWholesale % this.formIn.multiple != 0) {
        if (this.formIn.activityType == 3) {
          this.$message.warning("包邮量必须是购买倍数的整数倍");
        } else {
          this.$message.warning("起批量必须是购买倍数的整数倍");
        }
        return;
      }
      if (
        new Date(this.formIn.producedTime).getTime() >
        new Date(this.formIn.expireTime).getTime()
      ) {
        this.$message.warning("生产日期大于有效期，请重新选择");
        return;
      }
      if (!this.formIn.goodsSkuTitle) {
        this.$message.warning("请输入商品标题");
        return;
      }

      let data = {
        goodsId: this.goodsDetail.compGoodsBaseVo.id,
        activityType: this.formIn.activityType,
      };
      //详情

      //输入框相关
      _.merge(data, this.formIn);

      data.expireTime = this.formIn.expireTime
        ? dayjs(this.formIn.expireTime).format("YYYY-MM-DD HH:mm:ss")
        : "";
      data.producedTime = this.formIn.producedTime
        ? dayjs(this.formIn.producedTime).format("YYYY-MM-DD HH:mm:ss")
        : "";
      //做某些字段的特殊处理
      data.goodsSkuFileList =
        this.fileList.length > 0 ? JSON.stringify(this.fileList) : "";

      data.shopTypeCode = "";

      if (
        this.formIn.imgRegion == 2 &&
        this.imageUrlList instanceof Array &&
        this.imageUrlList.length > 0
      ) {
        data.goodsSkuImage = this.imageUrlList[0];
        data.goodsSkuImageList = this.imageUrlList.join(",");
      }

      if (this.imageUrlList.length == 0) {
        data.goodsSkuImageList = "";
        data.goodsSkuImage = "";
      }

      // 使用平台时，清空商品图片
      if (this.formIn.imgRegion == 1) {
        data.goodsSkuImage = "";
        data.goodsSkuImageList = "";
      }
      if (this.fromPage == "edit") {
        data.id = this.formIn.skuId;
        data.goodsId = this.formIn.goodsId;
      }
      if (this.formIn.activityType == 4) {
        data.unit = "盒";
      }
      if (this.formIn.type == 1) {
        data.shopTypeCode = this.formIn.shopTypeCodeNew.join(",");
      } else if (
        this.formIn.shopTypeCode instanceof Array &&
        this.formIn.shopTypeCode.length > 0
      ) {
        data.shopTypeCode = this.formIn.shopTypeCode.join(",");
      }
         this.$loading({
            lock: true,
            text: "正在提交...",
            spinner: "el-icon-loading",
            color: "#fff",
            background: "rgba(0, 0, 0, 0.7)",
          });
      if (this.formIn.activityType == 3) {
        data.userMaxTotalBuyCount = this.formIn.userMaxTotalBuyCount;
        data.maxGroupTotalCount = this.formIn.maxGroupTotalCount;
        data.groupBuyPrice = this.formIn.groupBuyPrice;
        data.minSingleBuyCount = this.formIn.startWholesale;
        data.multiple = this.formIn.multiple;

        skuUpdateExemption(data).then((res) => {
          if (res) {
            this.$message.success("提交成功");
            this.$loading().close(); 
            this.handleBack();
          }else{
            this.$loading().close(); 
          }
        }).catch(()=>{ 
          this.$loading().close(); 
        });
      } else {
        skuUpdate(data).then((res) => {
          if (res) {
            this.$message.success("提交成功");
            this.$loading().close();
            this.handleBack();
          }else{
            this.$loading().close(); 
          }
        }).catch(()=>{
          this.$loading().close(); 
        });
      }
    },
    changeCheckList(value) {
      console.log(value, "-value");
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.listData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.listData.length;
    },
    handleCheckAllChange(val) {
      console.log(val, "-val");
      this.formIn.shopTypeCode = [];
      if (val && this.listData instanceof Array) {
        this.listData.forEach((item) => {
          this.formIn.shopTypeCode.push(item.typeCode);
        });
      }
      this.isIndeterminate = false;
      console.log(this.formIn.shopTypeCode, "-- this.formIn.shopTypeCode");
    },
    doLoadLabelInfoList() {
      getLabelInfoList({ type: 2 }).then((data) => {
        if (data instanceof Array && data.length > 0) {
          this.shopLabelList = data;
          console.log(this.shopLabelList, "--- this.shopLabelList");
        }
      });
    },
    // 获取单位字典
    getunitList() {
      getunitList().then((res) => {
        if (res.code == 0) {
          this.unitList = res.data || [];
        }
      });
    },
    // 查询地域组信息
    groupInfos() {
      groupInfos().then((res) => {
        this.areaIdList  = [ {
          areaId: "0000028",
          schemeName: "不限",
        }];
        this.areaIdList = this.areaIdList.concat(res);
      });
    },
    // 查询用户组信息
    salesgroupinfo() {
      let params = {};
      // if(this.formIn.type==1){
      //   params.type = 1;
      // }else{
      //   params.type = 0
      // }
      params.type = 1;
      salesgroupinfo(params).then((res) => {
        this.userList = res;
        if (params.type == 1) {
          this.salesgroupinfo2();
        }
      });
    },
    salesgroupinfo2() {
      let params = {};
      params.type = 2;
      salesgroupinfo(params).then((res) => {
        this.userList2 = res;
      });
    },
    // 获取类型
    getEnableListByType() {
      let params = {
        type: 0,
      };
      getEnableListByType(params).then((res) => {
        console.log(res, "getEnableListByType");
        if (res.code == 0) {
          this.listData = res.data;

          if (this.listData && this.listData.length > 0 && this.checkAll) {
            this.formIn.shopTypeCode = [];
            this.listData.forEach((item) => {
              this.formIn.shopTypeCode.push(item.typeCode);
            });
          }

          // 白名单获取所有客户类型
          this.formIn.shopTypeCodeNew = [];
          this.listData.forEach((item) => {
            this.formIn.shopTypeCodeNew.push(item.typeCode);
          });
        }
      });
    },
    updateCheckAllView() {
      //
    },
    handleAddArea() {
      g_save = true;

      this.$router.push("/productManage/salesControl-controlPinForm-areaTemplate");
    },
    handleAddUserGroup() {
      g_save = true;

      this.$router.push("/productManage/salesControl-controlPinForm-userTemplate");
    },
    handleLookOtherSku(row) {
      g_save = true;

      this.$router.push({
        path: "/productManage/salesControl-publish-addGoodsToStore",
        query: {
          activeName: "add",
          id: row.goodsId,
          type: "add",
        },
      });
    },
    handleUpload(bigIndex) {
      //this.currentBigIndex = bigIndex;
      this.$refs["imgupload"].open(this.imgType);
    },
    uploadSubmit(obj) {
      const { type, url } = obj;
      this.imageUrlList.push(url);
    },
    handlePreview(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName][0].showViewer = true;
      }
    },
    handleDeleteImage(samllIndex) {
      this.imageUrlList.splice(samllIndex, 1);
      console.log(this.imageUrlList, "this.imageUrlList");
    },
    handleBack() {
      // this.$emit("preStep");
      this.$router.replace({
        path: "/productManage/salesControl-commodity-list",
      });
    },

    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePreviewUpload(file) {
      console.log(file);
      if (
        file.url.indexOf("http") > -1 ||
        file.url.indexOf(".PDF") > -1 ||
        file.url.indexOf(".png") > -1 ||
        file.url.indexOf(".jpg") > -1 ||
        file.url.indexOf(".jpeg") > -1
      ) {
        window.open(file.url);
      } else {
        this.url = file.url;
        this.showViewer = true;
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    success(res) {
      console.log(res);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
