var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "company-type-root" }, [
    _c(
      "div",
      { staticClass: "pic-wrap" },
      [
        _c("el-image", {
          attrs: { src: require("@/assets/zz/top-tip-pic.png") },
        }),
      ],
      1
    ),
    _c("span", { staticClass: "big-tip" }, [
      _vm._v("入驻问题欢迎拨打客服热线: 4000819577"),
    ]),
    _c(
      "div",
      { staticClass: "gird-type" },
      _vm._l(_vm.companyTypeList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            class: { item: true, selected: item.selected },
            on: {
              click: function ($event) {
                return _vm.handleClick(item)
              },
            },
          },
          [
            _vm._v(" " + _vm._s(item.typeName) + " "),
            _c("el-image", {
              staticClass: "selected-icon",
              attrs: { src: require("@/assets/zz/selected-icon.png") },
            }),
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "bottom-btn-layout" },
      [
        _c(
          "el-button",
          { attrs: { type: "danger" }, on: { click: _vm.handleNextStep } },
          [_vm._v("下一步")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }