<template>
    <div >
        <el-dialog title="资质需求" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <div class="title">企业资质</div>
            <div class="desc" >{{companyQualification}}</div>
            <div class="title">商品药检报告</div>
            <div class="desc">{{goodsReport[0]}}</div>
            <div class="title">商品首营资料</div>
            <div class="desc">{{goodsReport[1]}}</div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dialogVisible:false,//弹窗显示隐藏
            reportData:{},
            list: [
                { name: "企业首营资质", value: "1",type: 1 },
                { name: "购销合同", value: "2",type: 1 },
            ],
        }
    },
    computed: {
      // 计算企业资质
        companyQualification(){
            const {orderCertification} = this.reportData;
            if(!orderCertification || !orderCertification.companyTypes){
                return '';
            }
            const {companyTypes} = orderCertification;
            let arr = [];
            this.list.forEach(item => {
               if(companyTypes && companyTypes.length){
                companyTypes.forEach(type => {
                    if(type.typeDetail == item.value){
                        arr.push(item.name);
                    }
                });
                }
            });
            return arr.join('，');
        },
        goodsReport(){
            const {orderCertification} = this.reportData;
            if(!orderCertification || !orderCertification.goodsTypes){
                return '';
            }
            const { goodsTypes} = orderCertification;
            let mediCheckReport = [];
            let firstCheck = [];
            if(goodsTypes && goodsTypes.length){
                goodsTypes.forEach(type => {
                    const {typeDetail,goodsName} = type;
                    if (typeDetail && typeDetail.length) {
                        if(typeDetail.indexOf('1') != -1){
                            mediCheckReport.push(goodsName);
                        }
                        if(typeDetail.indexOf('2') != -1){
                            firstCheck.push(goodsName);
                        }
                    }
                });
            }
            return [ mediCheckReport.join('，'),firstCheck.join('，') ];
        },
    },
    created () {
        this.initYears();
    },
    methods: {
        openDialog(e){
            this.reportData = e;
            this.dialogVisible = true;
        },
        handleClose() {
            this.dialogVisible = false;
        },
        initYears(){
            // 初始化年份
            let years = [];
            let date = new Date();
            let year = date.getFullYear();
            for (let i = 1; i < 4; i++) {
                years.unshift(year - i);
            }
            let reportYears = years.map((item,index) => {
                return {
                name: `${item}年度报告`,
                value: index+3 +'',
                type: 1
                };
            });
            this.list = [...this.list, ...reportYears];
        },
    }
}
</script>
<style lang="scss" scoped>
.title{
    font-size: 16px;
    font-weight: bold;
    color: #262626;
}
.desc{
    font-size: 14px;
    color: #595959;
    margin: 10px 0px 20px 0px;
    line-height: 1.5;
}
</style>