
<!--  -->
<template>
  <div class="comContent">
 
    <div class="status_box">
      <div class="status_img" v-if="status == 0">
        <img src="../../assets/wait.png" alt="" />
      </div>
      <div class="status_img" v-if="status == 1">
        <img src="../../assets/reject.png" alt="" />
      </div>
      <!-- <h3>资质审核已通过</h3> -->
    </div>

    <div class="status_title">
      <div class="" v-if="status == 0">资质正在审核中，请耐心等待</div>
      <div class="" v-if="status == 1">资质已驳回</div>
      <div class="desc" v-if="status == 1">
        {{ info.remarks }}
      </div>
    </div>
    <!-- <h3>资质审核已通过</h3> -->

    <el-divider></el-divider>
   
    <div class="center">
      <Credential
        ref="infoBox"
        :pageType="'qualificationDetails'"
        @selectBtn="selectBtn"
      />
 
    </div>
  </div>
</template>

<script>
// import MyTypes from '@/components/myTypes'
// import MyButton from '@/components/myButton'
 
import Credential from "@/components/credential";
import { drugenumeration } from "@/utils/enumeration";
import { auditTypes } from "@/utils/enumeration";
import { auditCompanyDetail, auditCompanyEdit } from "@/api/auditcenter";
import { currentDetails } from "@/api/myQualification/index";
import  credentialCompanyType  from "@/utils/credential.js";

import { 
  shopTypeCodeFilter,
} from "@/utils/companyQualifications.js";
export default {
  components: {  Credential }, //, MyTable
  data() {
    return {
      btnShow: false,
      auditTrue: false,
      reqId: null,
      id: null,
      info: {
        shopName: "",
        status: "",
      },
      sourceData:{},
      status: "",
  
    };
  },
  computed: {
    statusText() {
      return auditTypes(this.status);
    },
  },
  watch: {},
  created() {
    // this.getInfo();
    // console.log(this.$route.query)
    // console.log(this.$route.params)
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() { 
      currentDetails().then((res) => {
        if (res.code === 0) {
          this.info = res.data;
          this.sourceData ={... res.data};//深拷贝保留初始后台返回数据，以确保切换企业类型时候 原数据不乱
          this.id = res.data.id;
          this.status = this.info.status;
          let shopTypeCode = this.info.shopTypeCode +''; 
          let resp= credentialCompanyType(shopTypeCode);
          // console.log(resp,'resp')
          this.info.showInputArr  = resp.showInputArr;
          this.fliterData(this.sourceData,resp.showImgArr);
          // this.info.status = auditTypes(this.info.status)
        }
      });
    },

    fliterData(info) {
      let shopTypeCodeArr = [];
      let shopTypeCode = info.shopTypeCode + "";
      switch (shopTypeCode) {
        case "1":
          shopTypeCodeArr = this.shopTypeCodeArr_1;
          break;
        case "2":
          shopTypeCodeArr = this.shopTypeCodeArr_2;
          break;
        case "3":
          shopTypeCodeArr = this.shopTypeCodeArr_3;
          break;
        case "4":
          shopTypeCodeArr = this.shopTypeCodeArr_4;
          break;
      } 
      let newData = shopTypeCodeFilter(
        showImgArr,
        info.certifys,
        info.licenceNo,
        info.shopId
      );
      console.log(newData, "newData--");
      this.info.certifys = [...newData]; 
      this.$refs["infoBox"].getInfo(this.info);
    },

    selectBtn(item) {
      console.log(item); 
      this.info.shopTypeName = item.typeName;
      this.info.shopTypeCode = item.typeCode ;
      let shopTypeCode = this.info.shopTypeCode +'';
      let resp= credentialCompanyType(shopTypeCode); 
      this.info.showInputArr  = resp.showInputArr;
      this.fliterData(this.sourceData,resp.showImgArr);
    },
    submit() {
      this.$router.push("/mymerchantsettlementb");
    },
  },
};
</script>
<style lang='scss' scoped>
.comContent {
  height: 100%;
  padding: 0px;
}
.header {
  height: 56px;
  line-height: 56px;
  padding: 0 24px;
  border-bottom: 1px solid #f2f2f2;
}
.center {
  margin: 0 24px;
}
.btn_w150 {
  width: 150px;
  margin-right: 8px;
}
.dia {
  &_btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;
  }
}
.status {
  &_txt {
    margin: 10px 0;
    font-size: 14px;
    span {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.status {
  .fontClass {
    font-size: 24px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: left;
    color: #262626;
  }
  .fontClassSecond {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #595959;
  }
  &_font {
    margin-left: 30px;
    font-weight: 600;
    color: #595959;
    &_everyOne {
      margin: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      &_submit {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 126px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #595959;
        line-height: 22px;
      }
    }
  }
  &_box {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;

    h3 {
      font-size: 30px;
      font-weight: 400;
      color: #333;
      line-height: 2;
    }
  }

  &_ico {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    color: #fff;
    font-size: 30px;
    line-height: 1;
    border-radius: 100%;
  }

  &_success {
    background: #32bf78;
  }

  &_err {
    background: #f56c6c;
  }
}
.status_img {
  width: 128px;
  text-align: center;
  img {
    width: 64px;
    height: 64px;
  }
}
.status_box {
  text-align: center;
}
.status_title {
  text-align: center;
  margin: 20px 0;
  .desc {
    color: #999;
    font-size: 14px;
  }
}
</style>
