var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c("el-descriptions", {
        attrs: {
          title: "收货人信息",
          direction: "vertical",
          column: 6,
          border: "",
          "label-class-name": "items",
        },
      }),
      _c("div", { staticClass: "addressDesc" }, [
        _c("div", { staticClass: "descLeft" }, [
          _c("span", { staticClass: "purseName" }, [
            _vm._v(_vm._s(_vm.addressData.clinicName || "--")),
          ]),
          _c("p", { staticClass: "detail" }, [
            _vm._v(
              " " +
                _vm._s(_vm.addressData.concatName || "--") +
                "   " +
                _vm._s(_vm.addressData.concatPhone || "--") +
                "   " +
                _vm._s(_vm.addressData.provinceName || "--") +
                "  " +
                _vm._s(_vm.addressData.cityName || "--") +
                "  " +
                _vm._s(_vm.addressData.areaName || "--") +
                "  " +
                _vm._s(_vm.addressData.address || "--") +
                " "
            ),
          ]),
        ]),
      ]),
      _c("el-descriptions", {
        staticClass: "goods",
        attrs: { title: "商品清单", direction: "vertical" },
      }),
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("p", { staticClass: "companyDiv" }, [
              _vm._v(_vm._s(item.shopName)),
            ]),
            _c("publicTable", {
              attrs: {
                "table-data": item.quickShopList,
                "colum-obj": _vm.columObj,
                "page-obj": _vm.pageObj,
              },
              on: {
                switchChange: _vm.switchChange,
                editInputBlur: _vm.editInputBlur,
                rowClick: _vm.rowClick,
                handleSizeChange: _vm.handleSizeChange,
                handleCurrentChange: _vm.handleCurrentChange,
                handleSelectionChange: _vm.handleSelectionChange,
              },
            }),
            _c("div", { staticClass: "bottomDiv" }, [
              _c("span", [_vm._v("商品：￥")]),
              _c("span", [
                _vm._v(
                  _vm._s(item.goodsPrice == "0" ? "0.00" : item.goodsPrice)
                ),
              ]),
              _c("span", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v("运费：￥"),
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(item.freightPrice == "0" ? "0.00" : item.freightPrice)
                ),
              ]),
              _c("span", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v("店铺合计：￥"),
              ]),
              _c("span", [
                _vm._v(_vm._s(item.shopPrice == "0" ? "0.00" : item.shopPrice)),
              ]),
            ]),
          ],
          1
        )
      }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("div", { staticStyle: { "font-size": "16px" } }, [
            _vm._v(" 商品合计： "),
            _c(
              "span",
              { staticStyle: { color: "#595959", "font-size": "18px" } },
              [
                _vm._v(
                  "¥" +
                    _vm._s(
                      _vm.sumGoodsPrice == "0" ? "0.00" : _vm.sumGoodsPrice
                    )
                ),
              ]
            ),
          ]),
          _c("div", { staticStyle: { "font-size": "16px" } }, [
            _vm._v(" 运费： "),
            _c(
              "span",
              { staticStyle: { color: "#595959", "font-size": "18px" } },
              [
                _vm._v(
                  "¥" +
                    _vm._s(
                      _vm.sumFreightPrice == "0" ? "0.00" : _vm.sumFreightPrice
                    )
                ),
              ]
            ),
          ]),
          _c("div", [
            _vm._v(" 应付总额： "),
            _c("span", [
              _vm._v("¥" + _vm._s(_vm.total == "0" ? "0.00" : _vm.total)),
            ]),
          ]),
          _c(
            "div",
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancels } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: _vm.isLoading,
                    loading: _vm.submitLoading,
                  },
                  on: { click: _vm.addSubmit },
                },
                [_vm._v("提交订单")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "goods_con",
          attrs: {
            title: "选择地址",
            visible: _vm.dialogAddress,
            width: "800px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAddress = $event
            },
          },
        },
        [
          _c("div", { staticClass: "pop_detail" }, [
            _vm.gridData && _vm.gridData.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.gridData, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class: [
                          "fl_bet",
                          "addressList",
                          _vm.selectionVal == item.id ? "activeBg" : "",
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.getRow(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "descLeft" }, [
                          _c("span", { staticClass: "purseName" }, [
                            _vm._v(_vm._s(item.name || "--")),
                          ]),
                          _c("p", { staticClass: "detail" }, [
                            _vm._v(
                              " " +
                                _vm._s(item.contactsName || "--") +
                                "   " +
                                _vm._s(item.contactsPhone || "--") +
                                "   " +
                                _vm._s(item.province || "--") +
                                "  " +
                                _vm._s(item.city || "--") +
                                "  " +
                                _vm._s(item.district || "--") +
                                "  " +
                                _vm._s(item.address || "--") +
                                " "
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "descRight" },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: item.id },
                                on: {
                                  change: function ($event) {
                                    return _vm.getRow(item)
                                  },
                                },
                                model: {
                                  value: _vm.selectionVal,
                                  callback: function ($$v) {
                                    _vm.selectionVal = $$v
                                  },
                                  expression: "selectionVal",
                                },
                              },
                              [_vm._v(" ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "goods_con",
          attrs: {
            title: "支付窗口",
            visible: _vm.dialogBtob,
            width: "800px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogBtob = $event
            },
          },
        },
        [
          _c("payCont", {
            attrs: { "data-option": _vm.payData },
            on: { suceeBtn: _vm.suceeBtn },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "codeCont",
          attrs: {
            visible: _vm.isQrCode,
            width: "800px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.qrCodeBtn,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isQrCode = $event
            },
          },
        },
        [
          _c("div", { staticClass: "qrcodeCon" }, [
            _c("p", { staticClass: "qrTip" }, [
              _vm._v(
                "订单提交成功，请尽快付款！订单号：" + _vm._s(_vm.orderCode)
              ),
            ]),
            _c("p", { staticClass: "qrdesc" }, [
              _vm._v("请您在5分钟内完成支付，否则订单会被自动取消！"),
            ]),
            _c("div", { staticClass: "qrCont" }, [
              _c("p", [
                _vm._v(" 应付金额： "),
                _c("span", [_vm._v("¥" + _vm._s(_vm.total))]),
              ]),
              _c("div", {
                ref: "qrcode",
                staticClass: "qrcodeImg",
                attrs: { id: "qrcode" },
              }),
            ]),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }