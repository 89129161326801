<template>
  <div class="harf-block"> 
    <div class="big-block" v-if="showFormLeft">
      <div class="title-block">
        <span class="text">价格/库存</span>
      </div>
      <div class="form-content">
        <div class="row">
          <div class="tip">ERP编码</div>
          <div class="value input">
            <el-input
              size="small"
              placeholder="请输入"
              v-model="formIn.medicineCode"
              @input="medicineCodeInput"
              maxlength="20"
            />
          </div>
        </div>
        <!-- <div class="row">
          <div class="tip"><span class="weight">*</span>价格</div>
          <div class="value input">
            <el-input
              size="small"
              placeholder="请输入"
              v-model="formIn.salePrice"
              v-positive-five-limit
              :disabled="formIn.participationSubsidy || formIn.beSynPrice"
            />
          </div> 
        </div> -->

        <div class="row">
          <div class="tip"><span class="weight">*</span> 总库存</div>
          <div class="value input">
            <el-input
              size="small"
              placeholder="请输入"
              v-model="formIn.goodsSkuInventory"
              :disabled="formIn.beSynStock"
              maxlength="8"
              v-positive-integer
            />
          </div>
          <div class="value fill">
            <el-checkbox
              v-model="formIn.beSynStock"
              ref="checkboxSynStock"
              @change="stockChange"
              >自动同步库存</el-checkbox
            >
          </div>
        </div> 
        <div class="row">
          <div class="tip">价格</div>
          <div class="value input">
            <el-input
              size="small"
              placeholder="请输入"
              v-model="minCooperativeIncome"
              maxlength="20"
              disabled
            />
          </div>
        </div>
        <div class="row">
          <div class="tip">市场价</div>
          <div class="value input">
            <el-input
              size="small"
              placeholder="请输入"
              v-model="formIn.marketPrice"
              maxlength="20"
            />
          </div>
        </div>
        <div class="row">
          <div class="tip">建议零售价</div>
          <div class="value input">
            <el-input
              size="small"
              placeholder="请输入"
              v-model="formIn.purchasePrice"
              maxlength="20"
            />
          </div>
        </div>
        <div class="row">
          <div class="tip"><span class="weight">*</span>单位</div>
          <div class="value input">
            <el-select
              v-model="formIn.goodsSkuUnit"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in unitList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="row">
          <div class="tip"><span class="weight">*</span>起批量</div>

          <div class="value input">
            <el-input
              size="small"
              placeholder="请输入"
              v-model="formIn.startWholesale"
              maxlength="4"
              v-positive-integer
            />
          </div>

          <div class="value fill">
            <el-checkbox v-model="formIn.useTimes">倍数购买</el-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="tip"><span class="weight">*</span>有效期至</div>
          <div class="value input">
            <el-date-picker
              v-model="formIn.expireTime"
              type="date"
              placeholder="请选择"
              size="small"
              :editable="false"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="row">
          <div class="tip"><span class="weight">*</span>生产日期</div>
          <div class="value input">
            <el-date-picker
              v-model="formIn.producedTime"
              type="date"
              placeholder="请选择"
              size="small"
              :editable="false"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>

    <div class="big-block">
      <div class="title-block">
        <span class="text">图片/其他</span>
      </div>
      <div class="form-content">
        <div class="row">
          <div class="tip"><span class="weight">*</span>商品标题</div>
          <div class="value input goods-title">
            <!-- <el-input
            disabled
              size="small"
              placeholder="请输入"
              v-model="formIn.goodsSkuTitle"
            /> -->
            {{ formIn.goodsSkuTitle }}
          </div>
        </div>
        
        <div class="row">
          <div class="tip">产品批号</div>
          <div class="value input">
            <el-input
              size="small"
              placeholder="请输入"
              v-model="formIn.batchNumber"
            />
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import avater from "/public/img/default.png"; 
const IConst_Small = require("@/assets/public/icon-small.png");
const IConst_See_Icon = require("@/assets/zz/see-icon.png");
const IConst_Del_Icon = require("@/assets/zz/del-icon.png");
const IConst_Add_Icon = require("@/assets/zz/add-icon.png");
import { 
  getErpStock, 
} from "@/api/salesControl/commodity";
import { getLabelInfoList } from "@/api/goods";
import _ from "lodash";
export default {
  components: {  },
  props: {
    formIn: {
      type: Object,
      default: () => {
        return {
          goodsSkuUnit: "", 
          goodsSkuTitle: "", //商品标题 
          batchNumber: "",
          shopLabelId: "", 
          useTimes:false,
          beSynStock:false,
          salePrice: 0,// 价格
          marketPrice: 0, // 市场价
          purchasePrice: 0, // 建议零售价
        };
      },
    },
    minCooperativeIncome: {
      type: String,
      default: () => {
        return '';
      },
    },
    selectType: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fileList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    unitList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    imageUrlList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    shopLabelList: {
      type: Array,
      default: () => {
        return [];
      },
    },

    isDisabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    name: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      smallIcon: IConst_Small,
      SeeIcon: IConst_See_Icon,
      DelIcon: IConst_Del_Icon,
      AddIcon: IConst_Add_Icon, 
      avater: avater,
      showFormLeft: false,
      isClear: false, // 清除富文本编辑内容 
      ischangeStock: true, // 可以勾选库存/或价格
      
    };
  },
  beforeMount() {
    // 获取当前日期
    const currentDate = new Date(); 
  },
  mounted() { 
    this.showFormLeft = true;
    this.$forceUpdate(); 
  },
  methods: {
  
    medicineCodeInput() {
      if (!this.formIn.medicineCode) { 
        this.formIn.beSynStock = false;
        this.formIn.ischangeStock = false;
        this.$forceUpdate();
      }
    }, 
    async stockChange(e) {
      if (e) {
        if (!this.formIn.medicineCode) {
          this.$message.warning("请您先设置ERP编码");
          this.$nextTick(() => {
            this.formIn.beSynStock = false;
          });
          return;
        }
        const resp = await getErpStock({
          medicineCode: this.formIn.medicineCode,
        });
        this.$set(this.formIn, "goodsSkuInventory", String(resp));
      } else {
        console.log(e, "---e2");
      }
    }, 
   
  },
};
</script>
<style lang="scss" scoped>
@import "../style/index.scss";

.tips {
  font-size: 12px;
  color: #606266;
  margin-left: 20px;
}

.radio-list {
  width: calc(100% - 180px);
  margin-left: 20px;

  .list-a {
    width: 100%;
    line-height: 30px;
  }

  .tips {
    font-size: 12px;
    color: #606266;
  }
}
.goods-title{
  font-size: 14px;
  color: #303133;
}
</style>
