var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "图片上传", visible: _vm.dialogShow, width: "408px" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogShow = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia_content" },
        [
          _c(
            "el-upload",
            {
              ref: "uploadDom",
              attrs: {
                drag: "",
                action: "",
                accept: _vm.accept,
                limit: 1,
                "show-file-list": false,
                "http-request": _vm.uploadSubmit,
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(
                    " 只能上传" + _vm._s(_vm.acceptInfo) + "文件，且不超过10M "
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.uploading,
                  expression: "uploading",
                },
              ],
              staticClass: "big-tip",
            },
            [_c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.bigTip))])]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }