var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "show-manage-warp", staticStyle: { width: "750px" } },
    [
      _c(
        "el-form",
        { ref: "formIn", attrs: { rules: _vm.rules, model: _vm.formIn } },
        [
          _c(
            "div",
            { staticClass: "form shop-manage" },
            [
              _c("div", { staticClass: "title" }, [
                _c("span", { staticClass: "text" }, [_vm._v("客户开户方式")]),
              ]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "accountOpeningMethodId",
                    label: "开户方式",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "240px" },
                      attrs: { placeholder: "请选择", size: "small" },
                      on: { change: _vm.handleChangeMedalName },
                      model: {
                        value: _vm.formIn.accountOpeningMethodId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "accountOpeningMethodId", $$v)
                        },
                        expression: "formIn.accountOpeningMethodId",
                      },
                    },
                    _vm._l(
                      _vm.indicatorList ? _vm.indicatorList : [],
                      function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.instructions, value: item.id },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _vm.formIn.medalPicture
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "", label: "", "label-width": "100px" } },
                    [
                      _c(
                        "span",
                        { staticClass: "small-icon" },
                        [
                          _c("el-image", {
                            staticClass: "icon",
                            staticStyle: {
                              width: "20px",
                              height: "20px",
                              "margin-right": "10px",
                            },
                            attrs: { src: _vm.formIn.medalPicture },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticStyle: { "line-height": "30px" } }, [
                        _vm._v(_vm._s(_vm.formIn.medalName)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "accountOpeningInstructions",
                    label: "附加说明",
                    "label-width": "100px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "200",
                      "show-word-limit": "",
                      autosize: { minRows: 2, maxRows: 6 },
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.formIn.accountOpeningInstructions,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "accountOpeningInstructions", $$v)
                      },
                      expression: "formIn.accountOpeningInstructions",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "title" }, [
                _c("span", { staticClass: "text" }, [_vm._v("发票设置")]),
              ]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "invoiceType",
                    label: "开票类型",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.formIn.invoiceType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "invoiceType", $$v)
                        },
                        expression: "formIn.invoiceType",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "1" } }, [
                        _vm._v("普通纸质发票"),
                      ]),
                      _c("el-checkbox", { attrs: { label: "2" } }, [
                        _vm._v("普通电子发票"),
                      ]),
                      _c("el-checkbox", { attrs: { label: "3" } }, [
                        _vm._v("纸质增值税专用发票"),
                      ]),
                      _c("el-checkbox", { attrs: { label: "4" } }, [
                        _vm._v("电子增值税专用发票"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "deliveryWay",
                    label: "送达时间",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.handleChangeMedalName2 },
                      model: {
                        value: _vm.formIn.deliveryWay,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "deliveryWay", $$v)
                        },
                        expression: "formIn.deliveryWay",
                      },
                    },
                    _vm._l(
                      _vm.formIn.platformServiceIndicatorVos,
                      function (item, key) {
                        return _c(
                          "el-radio",
                          { key: key, attrs: { label: item.id } },
                          [_vm._v(_vm._s(item.instructions))]
                        )
                      }
                    ),
                    1
                  ),
                  _vm.formIn.medalPicture2
                    ? _c(
                        "div",
                        {
                          staticClass: "label-tips",
                          attrs: { "label-width": "100px" },
                        },
                        [
                          _vm._v(" 获得优质店铺标签 "),
                          _c("el-image", {
                            staticClass: "icon",
                            staticStyle: {
                              width: "20px",
                              height: "20px",
                              "margin-right": "10px",
                              "margin-top": "10px",
                              "margin-left": "5px",
                            },
                            attrs: { src: _vm.formIn.medalPicture2 },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.formIn.needRemark
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "remark",
                        label: "说明",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入发票送达时间备注说明",
                          size: "small",
                        },
                        model: {
                          value: _vm.formIn.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.formIn, "remark", $$v)
                          },
                          expression: "formIn.remark",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "title", staticStyle: { "margin-bottom": "0" } },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v("客户开户需提供的资质"),
                  ]),
                ]
              ),
              _vm.formIn.radio == 9
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "desc", label: "备注说明" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "200",
                          "show-word-limit": "",
                          autosize: { minRows: 2, maxRows: 6 },
                          placeholder: "请输入发票送达时间备注说明",
                        },
                        model: {
                          value: _vm.formIn.desc,
                          callback: function ($$v) {
                            _vm.$set(_vm.formIn, "desc", $$v)
                          },
                          expression: "formIn.desc",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-tabs",
                {
                  staticClass: "headerTabs",
                  on: { "tab-click": _vm.handleChange },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                _vm._l(_vm.tabsList, function (item, index) {
                  return _c(
                    "el-tab-pane",
                    {
                      key: index,
                      attrs: { label: item.label, name: item.name },
                    },
                    [
                      item.value > 0
                        ? _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _vm._v(" " + _vm._s(item.label) + " "),
                              _c("el-badge", {
                                staticClass: "item",
                                attrs: { value: item.value },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                1
              ),
              _c(
                "div",
                { staticClass: "cert-check-list-warp" },
                _vm._l(
                  _vm.shopMoversTypeList ? _vm.shopMoversTypeList : [],
                  function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "cert-check-list" },
                      [
                        _c(
                          "span",
                          { staticClass: "checkbox-b" },
                          [
                            _c("el-checkbox", {
                              attrs: { disabled: item.isDefault },
                              on: {
                                change: function ($event) {
                                  return _vm.changeCheck(item)
                                },
                              },
                              model: {
                                value: item.selected,
                                callback: function ($$v) {
                                  _vm.$set(item, "selected", $$v)
                                },
                                expression: "item.selected",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "label-t" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ]
                    )
                  }
                ),
                0
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }