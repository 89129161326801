<!--  -->
<template>
  <div class="classification">
    <div class="header">
      <!-- <span class="font">仓库中的商品</span> -->
      <el-form ref="formTool"
               :model="formTool"
               :inline="true"
               style="text-align:left;">
        <el-form-item prop="invoiceStatus"
                      style="margin-left:0px !important">
          <el-select v-model="formTool.invoiceStatus"
                     clearable
                     style="width: 120px;"
                     size="small"
                     placeholder="开票状态">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="id">
          <el-input v-model="formTool.id"
                    style="width: 120px;"
                    placeholder="发票申请编号"
                    clearable
                    size="small" />
        </el-form-item>
        <!-- <el-form-item prop="sellerName">
          <el-input v-model="formTool.sellerName"
                    style="width: 120px;"
                    placeholder="发票抬头"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="invoiceType">
          <el-select v-model="formTool.invoiceType"
                     clearable
                     style="width: 120px;"
                     size="small"
                     placeholder="发票类型">
            <el-option v-for="item in typeOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item prop="value1">
          <el-date-picker v-model="date"
                          size="small"
                          type="daterange"
                          value-format="yyyy-MM-dd"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     type="primary"
                     @click="clickSearch(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     @click="reset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="center"
         style="position:relative">
      <MyTable :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getList"
               @handleSelectionChange="handleSelectionChange">
        <template slot="invoiceType"
                  slot-scope="scope">
                  <span>{{ scope.row.invoiceType | invoiceTypeFilter(scope.row.invoiceType) }}</span>
          <!-- <span>{{ scope.row.invoiceType == '1' ? '普通发票（纸质）' : scope.row.invoiceType == '2' ? '普通发票（电子）': scope.row.invoiceType == '3' ? '增值税专用发票（纸质）':scope.row.invoiceType == '4' ?'增值税专用发票（电子）':''}}</span> -->
        </template>
        <template slot="invoiceStatus"
                  slot-scope="scope">
          <span>{{ scope.row.invoiceStatus == 0 ?'申请开票':scope.row.invoiceStatus == 1 ? '已开票' :''}}</span>
        </template>
        <template slot="aggregateAmount"
                  slot-scope="scope">
          <span>￥{{ scope.row.aggregateAmount }}</span>
        </template>
        <template slot="caozuo"
                  slot-scope="scope">
          <!-- <el-button type="button" size="mini" icon="el-icon-upload2" @click="deleteHandle(scope.row)">上架</el-button> -->
          <el-link :underline="false"
                   type="primary"
                   @click="detail(scope.row)">查看明细</el-link>
          <!-- <el-link :underline="false"
                   v-if="scope.row.invoiceStatus == 0"
                   style="margin-left:16px"
                   type="primary"
                   @click="uploadBilling(scope.row)">上传发票</el-link> -->
          <el-link :underline="false"
                   v-if="scope.row.invoiceStatus == 1"
                   style="margin-left:16px"
                   type="primary"
                   @click="downloadBilling(scope.row)">查看发票</el-link>
        </template>
      </MyTable>
    </div>
    <el-dialog title="上传附件"
               :visible.sync="dialogVisible"
               width="40%"
               :before-close="handleClose">
      <span>上传附件信息（上传实物盖章发票清晰扫描文件，多张发票请扫描成一个PDF，文件命名规格：公司名称 + 结算单号）</span>
      <el-form ref="form"
               :model="form"
               :rules="rules"
               style="text-align:left;margin-top: 10px;">
        <el-form-item prop="fileList"
                      style="margin-left:0px !important;margin-bottom: 15px !important;">
          <el-upload class="upload-demo"
                     action="https://gateway.cbyyk.com/product/goodsbase/upload"
                     multiple
                     :on-remove="handleChange"
                     :on-success="successUpload"
                     :on-error="errorUpload"
                     accept=".jpg,.jpeg,.png,.pdf"
                     :file-list="form.fileList">
            <el-button size="small"
                       type="primary">点击上传</el-button>
            <div slot="tip"
                 class="el-upload__tip">附件不能超过10M，最多上传10个，文件格式PDF、JPG、PNG</div>
          </el-upload>
        </el-form-item>
        <el-form-item prop="logistics"
                      style="margin-left:0px !important;margin-bottom: 15px !important;">
          <el-input v-model="form.logistics"
                    style="width: 200px;"
                    placeholder="填写快递公司"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="trackingNumber"
                      style="margin-left:0px !important;margin-bottom: 15px !important;">
          <el-input v-model="form.trackingNumber"
                    onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
                    style="width: 200px;"
                    placeholder="填写快递单号"
                    clearable
                    size="small" />
        </el-form-item>
      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleClose"
                   size="small">取消</el-button>
        <el-button type="primary"
                   @click="submit"
                   size="small">提交发票</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MyTable from '@/components/myTable'
import { drugenumeration } from '@/utils/enumeration'
import { comapplyinvoiceinfoPage, uploading, downloadfapiao } from '@/api/applyBilling/index'
import avater from "/public/img/default.png"

export default {
  name: 'WareProduct',
  components: { MyTable },
  data () {
    return {
      avater: avater,
      id: '',
      dialogVisible: false,
      date: '',
      form: {
        fileList: [],
        logistics: '',
        trackingNumber: '',
      },
      rules: {
        logistics: [
          { required: true, message: '请填写快递公司', trigger: 'blur' }
        ],
        trackingNumber: [
          { required: true, message: '请填写快递公司', trigger: 'blur' }
        ]
      },
      options: [
        {
          value: '0',
          label: '申请开票'
        },
        {
          value: '1',
          label: '已开票'

        }],
      typeOptions: [
        {
          value: '1',
          label: '普通发票（纸质）'
        },
        {
          value: '2',
          label: '普通发票（电子）'

        }, {
          value: '3',
          label: '增值税专用发票（纸质）'

        },
        {
          value: '4',
          label: '增值税专用发票（电子）'

        }
      ],
      value: '',
      formTool: {
        invoiceStatus: '',
        // invoiceType: '',
        id: '',
        // sellerName: ''
      },
      selectionList: [],
      operation: false,
      selection: false,
      index: false,
      tableData: [
      ],
      tableOption: [
        { label: '发票抬头', prop: 'sellerName' }, // 这里表示自定义列
        // { label: '商品名称', prop: 'name' },
        // { label: '生产厂商', prop: 'factory' },
        { label: '发票类型', prop: 'invoiceType', slot: true },
        { label: '开票状态', prop: 'invoiceStatus', slot: true },
        { label: '开票金额(元)', prop: 'aggregateAmount', slot: true },
        { label: '申请时间', prop: 'createTime' },
        { label: '开票时间', prop: 'billingTime' },
        { label: '物流公司', prop: 'logistics' },
        { label: '运单号', prop: 'trackingNumber' },
        { label: '操作', prop: 'caozuo', slot: true, minWidth: '110px' },

      ],
      page: {
        total: 1,
        current: 1,
        size: 20,
        pageSizes: [10, 20, 30, 50]
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.comapplyinvoiceinfoPage()
  },
  mounted () {

  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  filters: {
    invoiceTypeFilter(str) {
      let arr = [];
      let invoiceTypeStr = '';
      if (str && str.indexOf(',') != -1) {
        arr = str.split(',');

      } else if (str && str.indexOf(',') == -1) {
        arr = [str]
      }
      if (arr.length == 0) return '';
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] == '1') {
          invoiceTypeStr += '普通发票（纸质）' + ','
        }
        if (arr[i] == '2') {
          invoiceTypeStr += '普通发票（电子）' + ','
        }
        if (arr[i] == '3') {
          invoiceTypeStr += '增值税专用发票（纸质）' + ','
        }
        if (arr[i] == '4') {
          invoiceTypeStr += '增值税专用发票（电子）' + ','
        }
      }
      // 去除最后一个 ','
      if (str && invoiceTypeStr.indexOf(',') != -1 ) { 
        invoiceTypeStr = invoiceTypeStr.slice(0,invoiceTypeStr.length-1)
      }
      return invoiceTypeStr;
    }
  },
  methods: {
    // 改变文件列表
    handleChange (file, fileList) {
      this.form.fileList = fileList
    },
    // 下载发票
    downloadBilling (row) {
      // this.$message.success('下载成功')
      downloadfapiao(row.id).then(res => {
        console.log(res)
      })
    },
    // 上传成功
    successUpload (response, file, fileList) {
      let obj = {
        name: response.data.fileName,
        url: response.data.url,
      }
      this.form.fileList.push(obj)
    },
    // 上传失败
    errorUpload (err, file, fileList) {
      this.$message.error('上传失败')
    },
    // 关闭弹窗
    handleClose () {
      this.$refs.form.resetFields()
      this.dialogVisible = false
    },
    // 确认提交
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let url = []
          if (this.form.fileList.length == 0) {
            this.$message.warning('请先上传发票文件')
          } else {
            this.form.fileList.forEach(item => {
              url.push(item.url)
            })
            let params = {
              id: this.id,
              trackingNumber: this.form.trackingNumber,
              logistics: this.form.logistics,
              image: url.join(),
            }
            console.log(params)
            uploading(params).then(res => {
              if (res.code == 0) {
                this.$message.success('上传成功')
                this.comapplyinvoiceinfoPage()
              } else {
                this.$message.error('上传失败')
              }
              this.dialogVisible = false
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      // this.dialogVisible = false
    },
    // 重置查询条件
    reset () {
      this.date = []
      this.$refs.formTool.resetFields()
    },
    // 切换分页
    getList () {
      this.comapplyinvoiceinfoPage()
    },
    // 勾选
    handleSelectionChange (val) {
      this.selectionList = val
    },
    // 查询出售中的商品
    comapplyinvoiceinfoPage (current) {
      if (current) { // 搜索条件改变从第一页开始查询
        this.page.current = current
      }
      const params = {
        ...this.formTool,
        strTime: this.date ? this.date[0] : '',
        endTime: this.date ? this.date[1] : '',
        current: this.page.current,
        size: this.page.size
      }
      comapplyinvoiceinfoPage(params).then(res => {
        if (res.code == 0 && res.data) {
          this.tableData = res.data.records
          this.page.total = res.data.total
          if (this.tableData.length > 0) {
            this.tableData.forEach(item => {
              item.type = drugenumeration(item.type)
            })
          }
        } else {
          this.tableData = []
          this.page.total = 0
        }
      })
    },
    // 搜索
    clickSearch (current) {
      this.comapplyinvoiceinfoPage(current)
    },
    // 查看明细
    detail (row) {
      this.$router.push({
        path: '/financialcenter/financialcenter-applyBilling-detail',
        query: {
          id: row.id
        }
      })
    },
    // 上传发票
    uploadBilling (row) {
      this.id = row.id
      this.dialogVisible = true
    }
  }
}
</script>
<style lang='scss' scoped>
.classification {
  padding: 0px;
}
.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px !important;
  border-bottom: 1px solid #f5f5f5;
  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }
  .el-form-item {
    margin: 0px 0px 0px 8px !important;
  }
}
.center {
  margin-top: 16px;
  padding: 0 24px 0 24px;
  height: calc(100% - 131px);
  ::v-deep .imageP {
    width: calc(100% - 110px);
    p {
      margin: 0 0 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
