
<!--  -->
<template>
  <div class="comContent">
    <div class="header">
      <span class="font">运营分类</span>
      <el-form ref="formTool"
               :model="formTool"
               :inline="true"
               style="text-align:left;">
        <!-- <el-form-item prop="name">
          <el-input v-model="formTool.name"
                    placeholder="请输入商品名称"
                    size="small" />
        </el-form-item>
        <el-form-item prop="approvalNumber">
          <el-input v-model="formTool.approvalNumber"
                    placeholder="请输入批准文号"
                    size="small" />
        </el-form-item> -->
        <el-form-item>
          <MyButton type="primary"
                    text="增加分类"
                    @click="addGoodsOperationalClasss()" />
        </el-form-item>
        <!-- <el-form-item>
          <el-button size="small"
                     @click="reset()">批量删除</el-button>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="center">
      <el-table v-if="tableData.length > 0"
                :data="tableData"
                style="width: 100%;margin-bottom: 20px;"
                row-key="id"
                @selection-change="handleSelectionChange"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <!-- <el-table-column type="selection"
                         align="center"
                         width="55">
        </el-table-column> -->
        <el-table-column prop="name"
                         align="left"
                         label="分类名称"
                         width="190">
          <template slot-scope="scope">
            <el-input v-model="scope.row.name"
                      :maxlength="60"
                      @blur="goodsOperationalClasss(scope.row)"
                      style="width:140px"
                      placeholder="请输入内容"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="classImg"
                         align="left"
                         label="分类图标"
                         width="190">
          <template slot-scope="scope">
            <img v-if="scope.row.classImg"
                 style="width:100px;height:100px"
                 :src="scope.row.classImg"
                 alt="">
            <el-upload v-else
                       class="upload-demo"
                       :headers="headers"
                       :action="action"
                       accept=".jpg,.jpeg,.png,.gif"
                       multiple
                       :on-success="uploadSuccess">
              <div class="uploadBox"
                   @click="upload(scope.row,'classImg')">
                +
              </div>
              <div slot="tip"
                   class="el-upload__tip">上传图片大小需低于2M，图片格式PNG,JPG,JPEG</div>
            </el-upload>
          </template>
        </el-table-column>
        <el-table-column prop="classThemeImg"
                         align="left"
                         label="主题图标"
                         width="190">
          <template slot-scope="scope">
            <img v-if="scope.row.classThemeImg"
                 style="width:100px;height:100px"
                 :src="scope.row.classThemeImg"
                 alt="">
            <el-upload v-else
                       class="upload-demo"
                       :headers="headers"
                       :action="action"
                       accept=".jpg,.jpeg,.png,.gif"
                       multiple
                       :on-success="uploadSuccess">
              <div class="uploadBox"
                   @click="upload(scope.row,'classThemeImg')">
                +
              </div>
              <div slot="tip"
                   class="el-upload__tip">上传图片大小需低于2M，图片格式PNG,JPG,JPEG</div>
            </el-upload>
          </template>
        </el-table-column>
        <el-table-column align="left"
                         label="排序"
                         width="200">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="primary"
                         size="small"
                         icon="el-icon-sort-up"
                         @click="clickSortUp(scope.row)"></el-button>
              <el-button type="primary"
                         size="small"
                         icon="el-icon-arrow-up"
                         @click="clickArrowUp(scope.row,'up')"></el-button>
              <el-button type="primary"
                         size="small"
                         icon="el-icon-arrow-down"
                         @click="clickArrowUp(scope.row,'down')"></el-button>
              <el-button type="primary"
                         size="small"
                         icon="el-icon-sort-down"
                         @click="clickSortDown(scope.row)"></el-button>
            </el-button-group>
          </template>
        </el-table-column>
        <el-table-column align="left"
                         prop="createTime"
                         label="创建时间"
                         width="180">
        </el-table-column>
        <!-- <el-table-column align="center"
                         prop="address"
                         label="地址">
        </el-table-column> -->
        <el-table-column align="left"
                         width="120"
                         prop="display"
                         label="店铺首页显示">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.display"
                         @change="goodsOperationalClasss(scope.row)">显示</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column align="left"
                         prop="caozuo"
                         label="操作">
          <template slot-scope="scope">
            <el-link v-if="scope.row.level == '2'"
                     type="primary"
                     :underline="false"
                     @click="gunalian(scope.row)">关联商品或分类</el-link>
            <el-link type="primary"
                     style="margin-left:8px"
                     :underline="false"
                     @click="deleteGoodsOperationalClasss(scope.row)">删除分类</el-link>
            <el-link v-if="scope.row.level == '1'"
                     type="primary"
                     style="margin-left:8px"
                     :underline="false"
                     @click="addGoodsOperationalClasss(scope.row)">新增分类</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-empty v-else
                style="height:100%"
                description="暂无数据" />
    </div>
    <!-- <div class="el-page">
      <Pagination :total="page.total"
                  :page.sync="page.current"
                  :limit.sync="page.size"
                  :page-sizes="page.pageSizes"
                  @pagination="pageChange" />
    </div> -->
    <el-dialog :visible.sync="dialogVisible"
               title="关联商品或分类"
               top="10vh"
               width="1000px"
               :before-close="beforeClose">
      <AssociativeClassification v-if="dialogVisible"
                                 :rowData="rowData"></AssociativeClassification>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import lodash from 'lodash'
import AssociativeClassification from './dialog/associativeClassification'
import Pagination from '@/components/Pagination'
import ImgUpload from '@/components/imgUpload'
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable'
import { deepClone } from "@/utils/index"
import { goodsOperationalClasssPage, goodsOperationalClasss, deleteGoodsOperationalClasss, addGoodsOperationalClasss, goodsOperationalClasssTop, goodsOperationalClasssTail, exchange } from '@/api/operationCenter/operationalClassification'
export default {
  components: { MyButton, MyTable, ImgUpload, Pagination, AssociativeClassification },
  data () {
    return {
      imgType: '', // 上传图片类型
      rightNowRow: {}, // 当前行
      action: 'https://gateway.cbyyk.com/product/goodsbase/upload',
      // action: process.env.VUE_APP_BASE_API + '/product/goodsbase/upload',
      rowData: {},
      dialogVisible: false,
      formTool: {},
      page: {
        total: 10,
        current: 1,
        size: 20,
        pageSizes: [20, 30, 50, 100]
      },
      tableData: [
      ],
    }
  },
  computed: {
    headers: function () {
      return {
        Authorization: 'Bearer ' + getToken(),
      }
    },
  },
  watch: {},
  created () {
    this.goodsOperationalClasssPage()
    // this.getcheckList()
  },
  mounted () {

  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    // 关闭弹窗
    beforeClose () {
      this.dialogVisible = false
    },
    // 分页
    pageChange () {

    },
    // 关联数据
    gunalian (row) {
      this.dialogVisible = true
      this.rowData = JSON.parse(JSON.stringify(row))
    },
    // 删除数据
    deleteData (row) {
      console.log(row)
    },
    // 全选
    handleSelectionChange (val) {
      console.log(val)
    },
    // 点击置顶
    clickSortUp (row) {
      goodsOperationalClasssTop(row.id).then(res => {
        console.log(res)
        this.goodsOperationalClasssPage()
      })
    },
    // 点击上升或者下降
    clickArrowUp (row, typeWay) {
      let params = {
        frontClassId: row.id,
        afterClassId: row.id
      }
      let nowIndex = null, levelIndex = null, nowTabeLength = 0
      nowIndex = this.tableData.findIndex(res => res.id == row.parentId)
      if (nowIndex < 0) {
        nowTabeLength = this.tableData.length
        nowIndex = this.tableData.findIndex(res => res.id == row.id)
      } else {
        nowTabeLength = this.tableData[nowIndex].children.length
      }
      if (row.level == 1) {
        if (nowTabeLength > 1 && nowIndex > 0 && typeWay == 'up') {
          params.afterClassId = this.tableData[nowIndex - 1].id
        } else if (nowTabeLength > 1 && nowIndex != nowTabeLength - 1 && typeWay == 'down') {
          params.frontClassId = this.tableData[nowIndex + 1].id

        } else if (nowTabeLength == 1 || nowIndex == nowTabeLength - 1 || (nowTabeLength > 1 && nowIndex == 0)) {
          this.$message(`已是${typeWay == 'up' ? '第一条' : '最后一条'}数据！`)
          return
        }
      } else {
        nowIndex = this.tableData.findIndex(res => res.id == row.parentId)
        levelIndex = this.tableData[nowIndex]?.children?.findIndex(res => res.id == row.id)
        if (nowTabeLength > 1 && levelIndex > 0 && typeWay == 'up') {
          params.afterClassId = this.tableData[nowIndex]?.children[levelIndex - 1]?.id
        } else if (nowTabeLength > 1 && levelIndex != nowTabeLength - 1 && typeWay == 'down') {
          params.frontClassId = this.tableData[nowIndex]?.children[levelIndex + 1]?.id

        } else if (nowTabeLength == 1 || levelIndex == nowTabeLength - 1 || (nowTabeLength > 1 && levelIndex == 0)) {
          this.$message(`已是${typeWay == 'up' ? '第一条' : '最后一条'}数据！`)
          return
        }
      }
      console.log(params)
      exchange(params).then(res => {
        this.goodsOperationalClasssPage()
      })
    },
    // 点击下降
    clickArrowDown (row) {
      console.log(row)

    },
    // 点击沉底
    clickSortDown (row) {
      goodsOperationalClasssTail(row.id).then(res => {
        console.log(res)
        this.goodsOperationalClasssPage()
      })
    },
    // 查询列表
    goodsOperationalClasssPage () {
      goodsOperationalClasssPage().then(res => {
        // this.tableData = res.records
        // this.page.total = res.total
        this.tableData = res
      })
    },
    // 保存更改
    saveChanges (row) {
      this.goodsOperationalClasss(row)
    },
    // 真实修改
    goodsOperationalClasss (data) {
      goodsOperationalClasss(data).then(res => {
        console.log(res)
      })
    },
    // 删除
    deleteGoodsOperationalClasss (data) {
      deleteGoodsOperationalClasss(data).then(res => {
        console.log(res)
        this.goodsOperationalClasssPage()
      })
    },
    // 新增
    addGoodsOperationalClasss (data) {
      let params = {
        level: '',
        parentId: '',
      }
      if (data && data.level && data.level == 1) {
        params.level = 2
        params.parentId = data.id
      } else {
        params.level = 1
      }
      addGoodsOperationalClasss(params).then(res => {
        console.log(res)
        this.goodsOperationalClasssPage()
      })
    },
    // 上传
    upload (row, type) {
      this.rightNowRow = row
      this.imgType = type
    },
    // 上传成功
    uploadSuccess (res) {
      let url = ''
      if (res.code == 0) {
        url = res.data?.url
        if (this.imgType == 'classImg') {
          this.rightNowRow.classImg = url
        } else {
          this.rightNowRow.classThemeImg = url
        }

        this.goodsOperationalClasss(this.rightNowRow)
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.comContent {
  padding: 0px;
  ::v-deep .el-dialog__body {
    height: 700px;
    padding-top: 0px;
  }
}
.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px !important;
  border-bottom: 1px solid #f5f5f5;
  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }
  .el-form-item {
    margin: 0px 0px 0px 8px !important;
  }
}
.center {
  margin-top: 16px;
  padding: 0 24px 24px 24px;
  overflow: auto;
  height: calc(100% - 100px);
  .uploadBox {
    height: 80px;
    width: 80px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #ebeef5;
    border-radius: 4px;
    color: #8c8c8c;
  }
  ::v-deep .vue-draggable {
    justify-content: center;
  }
}
.el-page {
  padding: 0 24px !important;
}
</style>
