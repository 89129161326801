<template>
    <div class="fundMain">
        <el-scrollbar style="height: calc(100vh - 260px);width: 100%;" wrap-style="overflow-x:hidden;">
            <div class="statusCenter">
                {{statusText }}
            </div>
            <div class="columnCenter">
                <div class="inLine">
                    <div class="formView">
                        <div class="topView">
                            <span class="require">*</span>
                            <span class="title">法人身份证正反面</span>
                            <!-- <el-link :underline="false" style="margin-left: auto;color: #f5222d;">查看示例</el-link> -->
                        </div>
                        <el-divider></el-divider>
                        <div class="idcardImg">
                            <div  class="imgViewSurround">
                                <div class="addImage">
                                    <el-image :src="IConst_Add_Icon" />
                                </div>
                                <img :src="idcard_front" alt="" v-if="idcard_front" class="img"
                                    @click.stop="handlePreview('idcard_front')" />
                                <el-image :src="IConst_Del_Icon" class="delicon" v-if="idcard_front"
                                    @click.stop="deleImage('idcard_front')" />
                            </div>
                            <div style="width: 12px;"></div>
                            <div class="imgViewSurround">
                                <div class="addImage">
                                    <el-image :src="IConst_Add_Icon" />
                                </div>
                                <img :src="idcard_back" alt="" v-if="idcard_back" class="img"
                                    @click.stop="handlePreview('idcard_back')" />
                                <el-image :src="IConst_Del_Icon" class="delicon" v-if="idcard_back"
                                    @click.stop="deleImage('idcard_back')" />
                            </div>
                        </div>
                        <div class="inputView">
                            <el-form>
                                <el-form-item label="身份证号码" required>
                                    <el-input placeholder="请输入" size="small" v-model="form.idCard.idCardNo" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="有效期" required>
                                    <el-date-picker v-model="form.validateTimeIdcard" type="daterange" range-separator="至"
                                        start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%;" size="small"
                                       :disabled="true"></el-date-picker>
                                </el-form-item>
                                <el-form-item>
                                    <el-radio-group v-model="form.timeType" :disabled="true">
                                        <el-radio label="固定期限">固定期限</el-radio>
                                        <el-radio label="长期有效">长期有效</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <div class="formView">
                        <div class="topView">
                            <span class="require">*</span>
                            <span class="title">营业执照</span>
                            <!-- <el-link :underline="false" style="margin-left: auto;color: #f5222d;">查看示例</el-link> -->
                        </div>
                        <el-divider></el-divider>
                        <div class="idcardImg">
                            <div class="imgViewSurround">
                                <div class="addImage">
                                    <el-image :src="IConst_Add_Icon" />
                                </div>
                                <img :src="business_license" alt="" v-if="business_license" class="img"
                                    @click.stop="handlePreview('business_license')" />
                                <el-image :src="IConst_Del_Icon" class="delicon" v-if="business_license"
                                    @click.stop="deleImage('business_license')" />
                            </div>
                        </div>
                        <div class="inputView">
                            <el-form>
                                <el-form-item label="统一社会信用代码" required>
                                    <el-input placeholder="请输入" size="small"
                                        v-model="form.merchant.businessLicenseNo" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="有效期至" required>
                                    <el-date-picker v-model="form.validateTimeBusiness" type="date" placeholder="选择日期"
                                        style="width: 100%;" size="small"  :disabled="true"
                                        ></el-date-picker>
                                </el-form-item>
                                <el-form-item>
                                    <el-radio-group v-model="form.businessTimeType" :disabled="true">
                                        <el-radio label="固定期限">固定期限</el-radio>
                                        <el-radio label="长期有效">长期有效</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
                <div class="inLine bottom">
                    <el-form :inline="true" label-position="top">
                        <el-form-item label="法人姓名" required>
                            <el-input placeholder="请输入" size="small" v-model="form.idCard.realName"
                                style="width: 300px;" :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item label="法人手机号" required>
                            <el-input placeholder="请输入" size="small" v-model="form.merchant.legalPhone"
                                style="width: 300px;" :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item label="商户名称" required>
                            <el-input placeholder="请输入" size="small" v-model="form.merchant.merchantName"
                                style="width: 300px;" :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item label="所在地区" required>
                            <el-cascader :options="areaList" clearable size="small" placeholder="请选择地区"
                                v-model="form.merchant.addressInfo" :props="areaProps" style="width: 300px;" ref="areaRef" :disabled="true">
                                <template slot-scope="{ node, data }">
                                    <span>{{ data.title }}</span>
                                </template>
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="详细地址" required>
                            <el-input placeholder="请输入" size="small" v-model="form.merchant.addrDetail"
                                style="width: 300px;" :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item label="注册邮箱">
                            <el-input placeholder="请输入" size="small" v-model="form.merchant.regEmail"
                                style="width: 300px;" :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item label="商户别名（选填）">
                            <el-input placeholder="请输入" size="small" v-model="form.merchant.merchantNameAlias"
                                style="width: 300px;" :disabled="true"></el-input>
                        </el-form-item>
                    </el-form>
                </div>

            </div>
            <div class="columnCenter">
                <div class="inLine" style="align-items: flex-start;">
                    <div class="formView">
                        <div class="topView">
                            <span class="require">*</span>
                            <span class="title">企业基本用户信息</span>
                        </div>
                        <el-divider></el-divider>
                        <div class="inputView">
                            <el-form>
                                <el-form-item label="开户行" required>
                                    <el-select v-model="form.merchant.bankName" filterable remote reserve-keyword value-key="itemCode"
                                        placeholder="请输入" size="small" style="width:100%;" :disabled="true">
                                        <el-option v-for="item,index in bankList" :key="index" :label="item.itemName"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="基本账户号" required>
                                    <el-input placeholder="请输入" size="small" v-model="form.merchant.bankCardNo" :disabled="true"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <div class="formView">
                        <div class="topView">
                            <span class="require">*</span>
                            <span class="title">绑定银行卡信息（用于收款）</span>
                        </div>
                        <el-divider></el-divider>
                        <div class="inputView">
                            <el-form>
                                <el-form-item>
                                    <el-radio-group v-model="form.accountType" :disabled="true">
                                        <el-radio label="同基本户">同基本户</el-radio>
                                        <el-radio label="其他对公账户">其他对公账户</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="开户行" required v-if="form.accountType == '其他对公账户'">
                                    <el-select v-model="form.bankCard.bankName" filterable remote reserve-keyword value-key="itemCode"
                                        placeholder="请输入" size="small" style="width:100%;" :disabled="true">
                                        <el-option v-for="item,index in bankList" :key="index" :label="item.itemName"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="开户地" required>
                                    <el-cascader :options="areaList" clearable size="small" placeholder="请选择开户地"
                                        v-model="form.bankCard.addressInfo" :props="areaProps" style="width: 100%;" :disabled="true">
                                        <template slot-scope="{ node, data }">
                                            <span>{{ data.title }}</span>
                                        </template>
                                    </el-cascader>
                                </el-form-item>
                                <el-form-item label="开户支行" required>
                                    <!-- <el-input placeholder="请输入" size="small" v-model="form.idcard"></el-input> -->
                                    <el-select v-model="form.bankCard.linkedBrbankname" placeholder="请选择开户支行" size="small" style="width:100%;" value-key="cnaps_code"  :disabled="true">
                                        <el-option v-for="item,index in linkBankList" :key="index" :label="item.brabank_name"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="银行卡号" required v-if="form.accountType == '其他对公账户'">
                                    <el-input placeholder="请输入" size="small" v-model="form.bankCard.bankCardNo" :disabled="true"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
        <div>
            <el-button type="defalut" size="small" @click="goBack">返回</el-button>
            <el-button type="primary" size="small" @click="goNext">重新提交</el-button>
        </div>
        <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="urls" />
    </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import selectIcon from "@/assets/zz/selected-icon.png";
import IConst_Add_Icon from "@/assets/zz/add-icon.png"
import IConst_Del_Icon from "@/assets/zz/del-icon.png"
import { preRegister, dicItemExtList, cityAll, getBankCode, applyVerifyCode , openAcctApply, verifyCode, queryAuditResult, getShopMerchantNo} from "@/api/fund/fundApi";
import dayjs from "dayjs";
import _ from  'lodash'
export default {
    components: {
        ElImageViewer
    },
    data() {
        return {
            areaProps: { value: "id", label: "title" },
            areaList: [],//开户地列表
            bankList: [],//银行列表
            linkBankList:[],//开户行支行
            authTypeIndex: 0,
            selectIcon: selectIcon,
            IConst_Add_Icon: IConst_Add_Icon,
            IConst_Del_Icon: IConst_Del_Icon,
            action: process.env.VUE_APP_BASE_API + '/shop/shopcertify/upload',
            idcard_front: '',
            idcard_back: '',
            msgCode:'',
            business_license: '',
            form: {
                timeType: '',//身份证有效期
                businessTimeType: '',//营业执照有效期
                accountType: '同基本户',//账户类型  对公|基本户
                address: '',
                validateTimeIdcard: '',
                validateTimeBusiness: '',
                memberId: '',// "97becc1f90404a3aa1132108f0f1dd85",
                password: '',// "C2HbcMis6K7lkcaLf0C9e0UoV40q8IO07ZdagcI46QSzUzdWiZzLs6ZZjxxmkC67uFmI/sFJmgjnGP4qL+YdDUHuH3Ax4VR4vAuQwk2Wrn2Q/qo7Zilkf84B3aAJcEEwuwYXqh97SdkejHy8o1wPre4FjEF25g5YPV58e5jY7Lk=",
                merchant: { //商户信息
                    merchantName: '',// "湖南永正医药有限公司",
                    merchantNameAlias: '',// "湖南永正医药有限公司",
                    merchantType: '',// "C",
                    regEmail: '',// "cc@hzypk.com",
                    businessLicenseNo: '',// "91430100329379204L",
                    addrProvinceCode: '',// "430000",
                    addrCityCode: '',// "430100",
                    addrAreaCode: '',// "430121",
                    addrDetail: '',//"湖南省长沙市长沙县黄花镇合心路95号A栋4层413房",
                    businessValidStart: '',// "2015-01-21",
                    businessValidEnd: '',// "9999-12-31",
                    contactsName: '',// "范永芳",
                    contactsPhone: '',// "18907323218",
                    legalPhone: '',// "18907323218",
                    bankCode: '',// "01040000",
                    bankName: '',// "中国银行",
                    bankCardNo: '',// "611942008888"
                    addressInfo:null
                },
                idCard: {
                    idCardNo: '',// "41280119830315001X",
                    realName: '',// "范永芳",
                    periodOfValidStart: '',// "2015-02-17",
                    periodOfValidEnd: '',// "2035-02-17"
                },
                bankCard: { //企业银行卡信息，对公账户
                    bankCode: '',// "01040000",
                    bankName: '',// "中国银行",
                    bankCardNo: '',// "611942008888",
                    linkedBrbankno: '',// "104551004137",
                    linkedBrbankname: '',//"中国银行股份有限公司长沙市星沙汽配城支行",
                    linkedAcctname: '',// "湖南永正医药有限公司",
                    addrProvinceCode: '',// "430000",
                    addrCityCode: '',// "430100"
                    linkedBrbankInfo:null,
                    addressInfo:null
                }
            },
            showViewer: false,
            urls: [],
            deadline2: Date.now() + 1000 * 60, //倒计时
            canEdit:false,
            statusText:''
        }
    },
    computed: {},
    created(){},
    mounted() {},
    methods: {
        resetAreaList(arr){
            this.areaList = arr
        },
        resetForm(e){
            this.form = _.cloneDeep(e)
        },
        resetImageUrl(e){
            this.idcard_front = e.idcard_front || ''
            this.idcard_back = e.idcard_back || ''
            this.business_license = e.business_license || ''
        },
        showStatusText(status){
            //    0待审核 1审核通过 -1审核驳回修改 -2审核拒绝 3作废
            switch (status) {
                case 0:
                    this.statusText = '待审核'
                    break;
                case 1:
                    this.statusText = '审核通过'
                    break;
                case -1:
                    this.statusText = '审核驳回修改'
                    break;
                case -2:
                    this.statusText = '审核拒绝'
                    break;
                case 3:
                    this.statusText = '作废'
                    break;
              
                default:
                    break;
            }
        },
        handlePreview(type) {
            // 预览
            this.urls = [this[type]]
            this.showViewer = true
        },
        closeViewer() {
            this.showViewer = false
        },
        goBack() {
           this.$router.back()

        },
        async goNext() {
           this.$emit('edit')
        },
    }
}
</script>
<style lang="scss" scoped>
.fundMain {
    background-color: #fff;
    width: 100%;
    min-height: 100%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    .statusCenter{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #595959;
        font-weight: 700;
    }

    .stepView {
        width: 40vw;

        .el-steps {
            padding: 0px !important;
            margin: 0px !important;
        }
    }

    .columnCenter {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .notiView {
        font-size: 16px;
        color: #595959;
        border: 1px solid #d9d9d9;
        width: 30vw;
        height: 100px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        margin-top: 48px;
    }

    .authType {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 24px 0px;

        .eachRow {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            overflow: hidden;
            background-color: #fafafa;
            padding: 12px;
            margin: 0px 12px;
            position: relative;
            cursor: pointer;

            .name {
                font-size: 16px;
                color: #262626;
                font-weight: 600;
            }

            .desc {
                font-size: 14px;
                color: #595959;
                line-height: 1.5;
                width: 160px;
                margin-top: 12px;
            }

            .divSelect {
                color: #f5222d;
            }

            .icon {
                position: absolute;
                right: 0px;
                bottom: 0px;
            }
        }

        .rowSelect {
            background-color: #FEEAEA;

        }
    }

    .inLine {
        display: flex;
        align-items: center;

        .formView {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            width: 500px;
            margin: 24px 12px;

            .topView {
                display: flex;
                align-items: center;
                margin: 12px;

                .require {
                    color: #f5222d;
                }

                .title {
                    font-weight: 700;
                    font-size: 16px;
                    margin-left: 12px;
                }
            }

            .idcardImg {
                display: flex;
                align-items: center;
                margin: 12px;

                .imgViewSurround {
                    border: 1px solid #d9d9d9;
                    border-radius: 4px;
                    width: 160px;
                    height: 100px;
                    position: relative;
                    .addImage{
                        display: flex;align-items: center;justify-content: center;height: 100%;
                    }
                }
            }

            .inputView {
                margin: 12px;
            }
        }
    }

    .bottom {
        width: 1000px;
        margin: 12px;
    }

    .img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .delicon {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 4px;

        :hover {
            background-color: #000;
        }
    }
}
</style>