import request from "@/utils/request";

// oss临时授权
export function getCBOssToken() {
  return request({
    url: "/external-sales-rule/oss/getToken",
    method: "get",
  });
}

// 新增学术材料
export function addScienceProfile(data) {
  return request({
    url: `/external-sales-rule/sales/scienceProfile/add`,
    method: "post",
    data: data,
  });
}

// 修改学术材料
export function updateScienceProfile(data) {
  return request({
    url: `/external-sales-rule/sales/scienceProfile/update`,
    method: "post",
    data: data,
  });
}

// 查询学术材料列表
export function querySalesScienceProfilePage(data) {
  return request({
    url: `/external-sales-rule/sales/scienceProfile/querySalesScienceProfilePage`,
    method: "post",
    data: data,
  });
}

// 查询学术材料详情
export function querySalesScienceProfileById(params) {
  return request({
    url: `/external-sales-rule/sales/scienceProfile/querySalesScienceProfileById`,
    method: "get",
    params: params,
  });
}
