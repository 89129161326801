import request from "@/utils/request";
import { Message } from 'element-ui'

// 分页查询CSO业务员
export function csoMemberPage(data) {
  return request({
    url: "/shop/hg/csoMember/page",
    method: "get",
    params: data,
  });
}

// 添加CSO业务员
export function csoMemberAdd(data) {
  return request({
    url: "/shop/hg/csoMember/add",
    method: "POST",
    data
  });
}

// 编辑CSO业务员
export function csoMemberEdit(data) {
  return request({
    url: "/shop/hg/csoMember/edit",
    method: "PATCH",
    data
  });
}

// 查看CSO业务员
export function csoMemberDetail(id) {
  return request({
    url: `/shop/hg/csoMember/detail/${id}`,
    method: "get",
    params: {},
  });
}

// 导出CSO业务员
export function csoMemberExport(data) {
  return request({
    url: `/shop/hg/csoMember/export`,
    method: "get",
    params: data,
    responseType: 'blob'
  }).then(response => { 
    // console.log(response,'res')
    // 处理返回的文件流
    const blob = response.data
    if ((blob && blob.size === 0) || response.size == 0) {
      Message.warning('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    const contentDisposition = response.headers['content-disposition'];
    const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1));
    link.download = '业务员数据导出'+ fileName;
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  });
}
