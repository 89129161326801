<template>
  <div class="classification">
    <div class="header">
      <el-form ref="formTool" :model="formTool" :inline="true" style="text-align: left">
        <el-form-item prop="name" label="">
          <el-input v-model="formTool.contractName" placeholder="请输入合同名称" clearable size="small" />
        </el-form-item>
        <el-form-item prop="name" label="">
          <el-input v-model="formTool.contractCode" placeholder="请输入合同编码" clearable size="small" />
        </el-form-item>
        <el-form-item prop="name" label="">
          <el-input v-model="formTool.goodsName" style="width: 180px;" placeholder="请输入关联产品名称" clearable size="small" />
        </el-form-item>
        <el-form-item prop="name" label="">
          <el-input v-model="formTool.draftShopName" placeholder="请输入协议公司" clearable size="small" />
        </el-form-item>
        <el-form-item prop="name" label="">
          <el-select v-model="formTool.contractStatus" size="small" placeholder="请选择合同状态" clearable>
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button size="small" type="primary" :disabled="!authDisable" @click="openLink(authUrl)">电子合同认证</el-button>
        <el-button
          size="small"
          type="primary"
          :disabled="authDisable"
          @click="goQueryTaskList()"
          v-loading="queryLoading"
          >立即同步合同</el-button
        >
      </div>
    </div>
    <div class="center" style="position: relative">
      <MyTable :table-data="tableData" :page="page" :table-option.sync="tableOption" @page-change="tablePageChange">
        <template slot="contractGoodsList" slot-scope="scope">
          <div v-if="scope.row.contractGoodsList && scope.row.contractGoodsList.length>0">
            <el-tooltip class="item" effect="dark" :disabled="scope.row.contractGoodsList.length <= 4" placement="top-start">
              <div>
                <template v-for="(li,i) in scope.row.contractGoodsList">
                  <div :key="i" v-if="i <= 4">{{ li.goodsName }}</div>
                  <!-- + ' '+ li.specification -->
                </template>
            </div>
            <div slot="content">
              <div v-for="(li,i) in scope.row.contractGoodsList" :key="i" style="line-height: 1.4;">{{ li.goodsName }}</div>
              <!-- + ' '+ li.specification -->
            </div>
            </el-tooltip>
          </div>
          <div v-else>无关联商品</div>
        </template>
        <template slot="contractStatus" slot-scope="scope">
          <span>{{ scope.row.contractStatus | typeTxt(typeOptions) }}</span>
        </template>
        <template slot="updateTime" slot-scope="scope">
          <div v-if="scope.row.updateTime" style="display: flex;flex-direction: column;">
            <span v-for="(li,i) in scope.row.updateTime.split(' ')" :key="i">{{ li }}</span>
          </div>
        </template>
        <template slot="createTime" slot-scope="scope">
          <div v-if="scope.row.createTime" style="display: flex;flex-direction: column;">
            <span v-for="(li,i) in scope.row.createTime.split(' ')" :key="i">{{ li }}</span>
          </div>
        </template>
        <template slot="tools" slot-scope="scope">
          <div class="table-op-col">
            <!-- <el-link type="primary" :underline="false" size="small" v-if="scope.row.contractStatus == 1" @click="openOpDetail(2, scope.row)">编辑合同信息</el-link> -->
            <!-- <el-link type="primary" :underline="false" size="small" @click="openOpDetail(3, scope.row)">查看合同文件</el-link> -->
            <!-- <el-link type="primary" :underline="false" size="small" v-if="[3,4,5].includes(scope.row.contractStatus)" @click="openLink(scope.row.contractUrl)">查看合同文件</el-link> -->
            <el-link type="primary" :underline="false" size="small" v-if="!([0,1].includes(scope.row.contractStatus))" @click="openOpDetail(3, scope.row)">查看合同信息</el-link>
            <el-link type="primary" :underline="false" size="small" v-if="[2].includes(scope.row.contractStatus)" @click="getContractSignUrl(scope.row.contractCode,'jump')">签署电子合同</el-link>
            <el-link type="primary" :underline="false" size="small" v-if="[2].includes(scope.row.contractStatus)" @click="getContractSignUrl(scope.row.contractCode)">复制签署链接</el-link>
            <el-link type="primary" :underline="false" size="small" v-if="!([0,1,2].includes(scope.row.contractStatus))" @click="getContractViewUrl(scope.row.contractCode)">查看电子合同</el-link>
          </div>
        </template>
      </MyTable>
    </div>
    <OpDetail ref="refOpDetail" @onRefresh="handleRefresh" />
  </div>
</template>

<script>
import MyTable from "@/components/myTable/index.vue";
import OpDetail from "./dialog/op-detail.vue";
import { contractCsoPage, contractAuthSign, companyAuthStatus, contractSignTaskUrl, contractViewTaskUrl,
  faddOpen,contractCSOTaskList, } from "@/api/compliance/contractManager";
import { webClip } from "@/utils/clipboard";
export default {
  components: { MyTable, OpDetail },
  data() {
    return {
      queryLoading: false, // 同步按钮加载
      authDisable: true,
      authUrl: null,
      formTool: {
        contractName: '',
        contractCode: '',
        goodsName: "",
        draftShopName: '',
        contractStatus: "",
        clientTag:'YYGS',
      },
      tableData: [],
      tableOption: [
        { label: "合同名称", prop: "contractName", maxWidth: "200px" },
        { label: "合同编号", prop: "contractCode", width: "190px" },
        { label: "关联产品", prop: "contractGoodsList", slot: true },
        { label: "协议公司", prop: "draftShopName", width: "140px" },
        { label: "合同状态", prop: "contractStatus", width: "100px", slot: true },
        { label: "更新时间", prop: "updateTime", width: "100px", slot: true },
        { label: "创建时间", prop: "createTime", width: "100px", slot: true },
        { label: "操作", prop: "tools", slot: true, width: "190px" },
      ],
      page: { total: 0, current: 1, size: 10 },
      typeOptions: [
        // { label: '任务创建中', value: 0 },
        // { label: '新建合同', value: 1 },
        { label: '签署中', value: 2 },
        { label: '已签署', value: 3 },
        { label: '已逾期', value: 4 },
        { label: '任务已结束', value: 5 },
        { label: '任务异常停止', value: 6 },
        { label: '作废中', value: 7 },
        { label: '已作废', value: 8 },
      ],
    };
  },
  filters: {
    typeTxt: (val,options = [])=>{
      let item = options.find(li=>li.value == val);
      return item ? item.label : '';
    }
  },
  computed: {},
  created() {},
  mounted(){
    this.getList(1);
    // this.getAuthUrl();
    let clientCorpId = this.$route.query.clientCorpId;
    let openCorpId = this.$route.query.openCorpId;
    if(clientCorpId && openCorpId){
      this.getAuthResult(clientCorpId,openCorpId)
    }else{
      this.getAuthUrl();
    }
  },
  methods: {
    goQueryTaskList() {
      this.queryLoading = true;
      this.$message.success("合同同步中，请耐心等待");
      setTimeout(() => {
        this.queryLoading = false;
      }, 5000);
      contractCSOTaskList({}).then((res) => {
        if (res) {
          this.$message.success("合同同步成功");
          this.getList(1);
        }
      }).finally(() => {
        this.queryLoading = false;
      });
    },
    getAuthResult(clientCorpId,openCorpId){
      faddOpen({clientCorpId,openCorpId}).then(res=>{
        this.getAuthUrl();
      })
    },
    openOpDetail(action = 1, row = null) {
      this.$refs.refOpDetail.open({ action, row });
    },
    getAuthUrl(){
      this.authUrl = null;
      this.authDisable = true;
      companyAuthStatus({}).then((res)=>{
        // console.log(res)
        if(res !== 'authorized'){
          // 未进行电子合同认证,获取跳转链接
          contractAuthSign({cso:1}).then((resNew)=>{
            this.authUrl = resNew;
            this.authDisable = resNew? true: false;
          })
        }else{
          this.authDisable = false;
        }
      })
    },
    getContractViewUrl(contractCode) {
      contractViewTaskUrl({ contractCode }).then((res) => {
        this.openLink(res);
      })
    },
    getContractSignUrl(contractCode,type = 'copy') {
      contractSignTaskUrl({ contractCode }).then((res) => {
        if(type == 'copy'){
          this.copyUrl(res);
        }else{
          this.openLink(res);
        }
      })
    },
    getList(num) {
      if (num) {
        this.page.current = num;
      }
      const { current, size } = this.page;
      let params = { current, size, ...this.formTool };
      this.listLoading = true;
      setTimeout(()=>{
        this.listLoading = false;
      },2000)
      contractCsoPage(params)
        .then((res) => {
          this.tableData = res.records;
          this.page.total = res.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    copyUrl(src) {
      // let src = row.contractShareUrl;
      if(!src){
        this.$message.warning('合同地址为空，无法复制');
        return;
      }
      webClip(src);
    },
    tablePageChange(){
      // console.log(this.page)
      this.getList();
    },
    openLink(url = null){
      if(!url){
        this.$message.warning('跳转链接为空，请确认后重试');
        return;
      }
      console.log('跳转链接为：',url)
      // let testUrl = 'https://www.baidu.com/s?wd=月光';
      window.open(url);// 打开外部地址
    },
    handleRefresh() {
      this.getList(1);
    },
    handleReset() {
      for (let key in this.formTool) {
        this.formTool[key] = "";
      }
      this.formTool.clientTag = 'YYGS';
      this.getList(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.classification {
  padding: 0px;

  .header {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px 8px 24px !important;
    border-bottom: 1px solid #f5f5f5;
    flex-wrap: wrap;
    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
    ::v-deep .el-input{
      width: 150px;
    }
  }

  background: white;

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);
  }

  .table-op-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    // .el-link {
    //   &+.el-link {
    //     margin-left: 8px;
    //   }
    // }
  }
}
</style>
