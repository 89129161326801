<template>
  <div class="detail-root">
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="918px"
      @closed="onClosed"
      v-loading="loading"
    >
      <div class="content form-content">
        <div class="row mutlti" v-if="showAuditOpinion">
          <div class="tip">审核意见</div>
          <div class="value input fill">
            <span class="opinion">{{ auditOpinion }}</span>
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip">名称</div>
          <div class="value input fill">
            <el-input
              size="small"
              maxlength="20"
              v-model="posterName"
              :disabled="isLook"
            ></el-input>
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip">资料内容</div>
          <div class="value input fill">
            <ossFileUpload
              ref="refPicFileUpload"
              v-model="picFileList"
              :limit="maxFileCount"
              :accept="picFileExt"
              :maxFileLength="10"
              :ossUpload="true"
              :ossPathDir="'shop/pic/'"
              style="margin-bottom: 10px"
              :disabled="isLook"
            >
            </ossFileUpload>
            <div class="small-tip warning" v-if="!isLook">
              <span
                >上传pdf或图片，且不超过10M，最多上传{{
                  maxFileCount
                }}个。上传多个文件时，将按文件上传的顺序给业务员展示资料</span
              >
            </div>
          </div>
        </div>
        <div class="row mutlti big" style="margin-top: 12px">
          <div class="tip">视频内容</div>
          <div class="value input fill">
            <ossFileUpload
              ref="refVideoFileUpload"
              v-model="videoFileList"
              :limit="maxFileCount"
              :accept="videoFileExt"
              :maxFileLength="300"
              :ossUpload="true"
              :ossPathDir="'shop/video/'"
              :withCover="true"
              style="margin-bottom: 10px"
              :disabled="isLook"
            >
            </ossFileUpload>
            <div class="small-tip warning" v-if="!isLook">
              <span
                >上传MP4，且不超过300M，最多上传{{
                  maxFileCount
                }}个。当文件过大时，将导致业务员长时间等待下载视频，建议压缩文件后上传系统</span
              >
            </div>
          </div>
        </div>
        <div class="row mutlti big" style="margin-top: 12px">
          <div class="tip">商品信息</div>
          <div class="value">
            <div class="goods-info" v-if="goodsInfo">
              <el-image
                :src="goodsInfo.image ? goodsInfo.image : avater"
                lazy
                style="width: 100px; height: 100px"
              />
              <div class="txt-info">
                <div class="name">{{ goodsInfo.name }}</div>
                <div class="factory">{{ goodsInfo.factory }}</div>
                <div class="factory">{{ goodsInfo.specification }}</div>
                <div class="factory">{{ goodsInfo.approvalNumber }}</div>
                <div class="factory">商品编码：{{ goodsInfo.goodsCode }}</div>
              </div>
            </div>
          </div>
          <el-button
            v-if="!isLook"
            size="small"
            type="primary"
            @click="handleSelectGoods"
            >选择商品</el-button
          >
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">取 消</el-button>
        <el-button
          v-if="!isLook"
          size="small"
          type="primary"
          @click="handleSave"
          >保存并提交</el-button
        >
      </div>
    </el-dialog>

    <selectGoods ref="refSelectGoods" @choose="didSelectItem" />
  </div>
</template>

<script>
import ossFileUpload from "@/components/ossFileUpload";
import { getOssSrcFileName } from "@/utils/oss";
import selectGoods from "./select-goods.vue";
import avater from "/public/img/default.png";
import {
  addScienceProfile,
  querySalesScienceProfileById,
  updateScienceProfile,
} from "@/api/salesControl/academic";

export default {
  components: { ossFileUpload, selectGoods },
  data() {
    return {
      action: 1, //1 新增  2 编辑 3 查看
      dialogVisible: false,
      posterId: null,
      loading: false,
      maxFileCount: 9,
      /**
       * {
          fileName: "check_2024060511154657176078.png",
          name: "check_2024060511154657176078.png",
          url: "https://eshop-develop.oss-cn-hangzhou.aliyuncs.com/goods/check_2024060511154657176078.png",
        },
       */
      picFileList: [],
      picFileExt: ".jpg, .png, .pdf,.bmp,.jpeg",
      videoFileList: [],
      videoFileExt: ".mp4", //只支持mp4
      // ".avi, .flv, .mp4, .3gp, .rm, .rmvb, .mkv, .vob, .wmv, .asf, .mov, .mpeg, .mwv",

      avater: avater,

      btnType: "add",
      auditStatus: "", //审核状态
      auditOpinion: "", //审核意见
      posterName: "", //名称
      goodsInfo: null, //选择的商品
    };
  },
  computed: {
    title() {
      let txt = "资料";
      if (this.action === 2) {
        txt = `编辑${txt}`;
      } else if (this.action === 3) {
        txt = `查看${txt}`;
      } else {
        txt = `新增${txt}`;
      }
      return txt;
    },
    isLook() {
      return this.action === 3;
    },
    showAuditOpinion() {
      return this.isLook && this.auditStatus === 2;
    },
  },
  methods: {
    open(options = null) {
      if (options) {
        this.action = options.action ? options.action : 1;
        this.posterId = options.posterId ? options.posterId : null;
        this.getDetail();
      }

      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.refPicFileUpload.init(); //初始化oss上传
        this.$refs.refVideoFileUpload.init();
      });
    },
    close() {
      this.dialogVisible = false;
    },
    onClosed() {
      this.reset();
    },
    reset() {
      this.action = 1;
      this.posterId = "";
      this.posterName = "";
      this.auditStatus = "";
      this.auditOpinion = "";
      this.picFileList = [];
      this.videoFileList = [];
      this.goodsInfo = null;
      this.$refs.refPicFileUpload.release();
      this.$refs.refVideoFileUpload.release();
    },
    onRemoveHandlerForPic(file) {
      let index = this.picFileList.indexOf(file);
      this.picFileList.splice(index, 1);
    },
    onRemoveHandlerForVideo(file) {
      let index = this.videoFileList.indexOf(file);
      this.videoFileList.splice(index, 1);
    },
    handleSelectGoods() {
      this.$refs.refSelectGoods.open();
    },
    didSelectItem(arr) {
      this.goodsInfo = arr && arr.length > 0 ? arr[0] : null;
    },
    getDetail() {
      if (this.posterId) {
        this.loading = true;
        querySalesScienceProfileById({ id: this.posterId })
          .then((data) => {
            this.loading = false;
            if (data) {
              this.posterName = data.posterName;

              this.auditStatus = data.auditStatus; //审核状态
              this.auditOpinion = data.auditOpinion; //审核意见

              this.goodsInfo = {
                goodsId: data.goodsId,
                name: data.goodsName,
                factory: data.factory,
                specification: data.specification,
                goodsCode: data.goodsCode,
                image: data.goodsImage,
                approvalNumber: data.approvalNumber,
              };
              if (Array.isArray(data.details)) {
                let picFileList = [];
                let videoFileList = [];
                data.details.forEach((item) => {
                  if (item) {
                    /**{name:"",url:""} */
                    let urlObj = this.parseToUploadUrl(item.contentUrl);
                    if (urlObj && urlObj.name && urlObj.url) {
                      if (item.type === "DATA_CONTENT") {
                        picFileList.push(urlObj);
                      } else if (item.type === "VIDEO_CONTENT") {
                        if (item.surfaceDrawingUrl) {
                          urlObj.surfaceDrawingUrl = item.surfaceDrawingUrl;
                        }
                        if (item.videoDuration) {
                          urlObj.videoDuration = item.videoDuration;
                        }
                        videoFileList.push(urlObj);
                      }
                    }
                  }
                });

                this.picFileList = picFileList;
                this.videoFileList = videoFileList;
              }
            }
          })
          .catch((ex) => {});
      }
    },
    handleSave() {
      if (!this.posterName) {
        this.$message.warning("请填写名称!");
        return;
      }
      if (this.picFileList.length === 0 && this.videoFileList.length === 0) {
        this.$message.warning("资料(或视频)内容附件至少上传一个!");
        return;
      }
      // if (this.videoFileList.length === 0) {
      //   this.$message.warning("请上传视频内容附件!");
      //   return;
      // }
      if (!this.goodsInfo) {
        this.$message.warning("请选择商品!");
        return;
      }
      const data = {
        id: this.posterId ? this.posterId : "",
        posterName: this.posterName,
        goodsId: this.goodsInfo.goodsId,
        goodsName: this.goodsInfo.name,
        factory: this.goodsInfo.factory,
        specification: this.goodsInfo.specification,
        goodsCode: this.goodsInfo.goodsCode,
        goodsImage: this.goodsInfo.image,
        approvalNumber: this.goodsInfo.approvalNumber,
        details: [],
      };
      this.picFileList.forEach((item) => {
        let url = this.getUploadFileUrl(item);
        if (url) {
          data.details.push({ type: "DATA_CONTENT", contentUrl: url });
        }
      });
      this.videoFileList.forEach((item) => {
        let url = this.getUploadFileUrl(item);
        if (url) {
          let videoObj = { type: "VIDEO_CONTENT", contentUrl: url };
          if (item.surfaceDrawingUrl) {
            videoObj.surfaceDrawingUrl = item.surfaceDrawingUrl;
          }
          if (item.videoDuration) {
            videoObj.videoDuration = item.videoDuration;
          }
          data.details.push(videoObj);
        }
      });
      if (this.posterId) {
        //编辑
        updateScienceProfile(data).then((res) => {
          if (res) {
            this.$emit("onRefresh");
            this.dialogVisible = false;
          }
        });
      } else {
        //保存
        addScienceProfile(data).then((res) => {
          if (res) {
            this.$emit("onRefresh");
            this.dialogVisible = false;
          }
        });
      }
    },
    getUploadFileUrl(item) {
      let netUrl = "";
      if (item) {
        if (item.url) {
          //可能是已经上传过的
          netUrl = item.url;
        } else if (
          //服务器返回过来的
          item.response &&
          item.response.data &&
          item.response.data.url
        ) {
          netUrl = item.response.data.url;
        }
      }
      return netUrl;
    },
    parseToUploadUrl(newUrl) {
      if (newUrl) {
        const name = getOssSrcFileName(newUrl); //newUrl.split("/");
        return { name: name, url: newUrl };
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-root {
  .form-content {
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 0;
      box-sizing: border-box;

      &.mutlti {
        align-items: flex-start;
      }

      &.contine {
        padding-top: 0;
      }

      &.big {
        .tip {
          height: 32px;
          line-height: 32px;
        }
      }

      .tip {
        width: 5em;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        text-align: right;
        color: #262626;
      }

      .value {
        margin-left: 16px;

        &.input {
          width: 204px;

          ::v-deep .el-date-editor.el-input,
          ::v-deep .el-date-editor.el-input__inner {
            width: 100%;
          }
        }

        &.fill {
          flex: 1;
          width: 0;
        }

        .small-tip {
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #8c8c8c;

          &.warning {
            color: #e6a23c;
          }
        }

        &.mutlti {
          flex: 1;
          width: 0;
          display: flex;

          &.v {
            flex-direction: column;

            .in-row {
              &:not(:first-child) {
                margin-top: 8px;
              }
            }
          }
        }

        .flag-block {
          width: 100%;
          background: #fafafa;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 8px 16px 16px 16px;
          box-sizing: border-box;
          flex-wrap: wrap;

          .flag-title {
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            margin-right: 8px;
          }

          & > * {
            margin-top: 8px;
          }
        }
      }

      .in-row {
        .value {
          margin-left: 0;
        }
      }
    }

    .opinion {
      color: #f00;
    }

    .goods-info {
      display: inline-flex;
      align-items: center;
      margin-right: 24px;

      .txt-info {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        .name {
          font-size: 16px;
          font-weight: 600;
          color: var(--color-26);
        }
        .factory {
          color: var(--color-59);
          font-size: 14px;
          margin-top: 3px;
        }
      }
    }
  }

  ::v-deep .el-upload {
    width: auto;
  }
  ::v-deep .el-dialog__body {
    max-height: 60vh;
    overflow-y: auto;
    min-height: 240px;
  }
}
</style>
