var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classfication" },
    [
      _c("el-scrollbar", { staticStyle: { height: "calc(100vh - 148px)" } }, [
        _c("div", { staticClass: "topView" }, [
          _c(
            "div",
            {
              staticStyle: {
                padding: "10px 0px",
                "font-weight": "600",
                "font-size": "18px",
              },
            },
            [_vm._v("蓝字发票开具")]
          ),
          _c(
            "div",
            { staticClass: "topViewForm" },
            _vm._l(_vm.invoiceArr, function (item, index) {
              return _c("div", { staticClass: "invoiceView" }, [
                _c("div", { staticClass: "titleView" }, [
                  _c("div", { staticClass: "titleViewLine" }),
                  _c("span", [_vm._v(_vm._s(item.title))]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "formView",
                    class:
                      index == _vm.invoiceArr.length - 1 ? "hideBorder" : "",
                  },
                  [
                    _c(
                      "el-form",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "名称", "label-width": "150px" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "90%" },
                                attrs: {
                                  size: "small",
                                  disabled: item.type == "saler",
                                  "remote-method": _vm.remoteMethod,
                                  loading: _vm.loading,
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  clearable: "",
                                },
                                on: { change: _vm.changeShop },
                                model: {
                                  value: item.sellerName,
                                  callback: function ($$v) {
                                    _vm.$set(item, "sellerName", $$v)
                                  },
                                  expression: "item.sellerName",
                                },
                              },
                              _vm._l(_vm.options, function (item) {
                                return _c("el-option", {
                                  key: item.shopId,
                                  attrs: {
                                    label: item.shopName,
                                    value: item.shopId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "150px" } },
                          [
                            _c("template", { slot: "label" }, [
                              _c("div", { staticClass: "formItem" }, [
                                _c("span", [
                                  _vm._v("统一社会信用代码纳税人识别号"),
                                ]),
                              ]),
                            ]),
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: { size: "small", disabled: "" },
                              model: {
                                value: item.sellerTaxpayerId,
                                callback: function ($$v) {
                                  _vm.$set(item, "sellerTaxpayerId", $$v)
                                },
                                expression: "item.sellerTaxpayerId",
                              },
                            }),
                          ],
                          2
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "地址", "label-width": "150px" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: { size: "small", disabled: "" },
                              model: {
                                value: item.detailAddress,
                                callback: function ($$v) {
                                  _vm.$set(item, "detailAddress", $$v)
                                },
                                expression: "item.detailAddress",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "电话", "label-width": "150px" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: { size: "small", disabled: "" },
                              model: {
                                value: item.sellerTel,
                                callback: function ($$v) {
                                  _vm.$set(item, "sellerTel", $$v)
                                },
                                expression: "item.sellerTel",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "开户行", "label-width": "150px" },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: { size: "small", disabled: "" },
                              model: {
                                value: item.sellerBank,
                                callback: function ($$v) {
                                  _vm.$set(item, "sellerBank", $$v)
                                },
                                expression: "item.sellerBank",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "账号", "label-width": "150px" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: { size: "small", disabled: "" },
                              model: {
                                value: item.sellerAccount,
                                callback: function ($$v) {
                                  _vm.$set(item, "sellerAccount", $$v)
                                },
                                expression: "item.sellerAccount",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]),
        _c("div", [
          _c("div", { staticClass: "centerView" }, [
            _c(
              "div",
              { staticClass: "topTitle" },
              [
                _c("div", { staticClass: "title" }, [_vm._v("发票明细")]),
                _c("el-date-picker", {
                  staticStyle: { width: "150px" },
                  attrs: {
                    type: "month",
                    placeholder: "选择月",
                    size: "small",
                  },
                  on: { change: _vm.changeMonth },
                  model: {
                    value: _vm.monthValue,
                    callback: function ($$v) {
                      _vm.monthValue = $$v
                    },
                    expression: "monthValue",
                  },
                }),
                _c("span", { staticClass: "titleMoney" }, [
                  _vm._v("未开票金额:" + _vm._s(_vm.monthTotal)),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "centerForm" },
              [
                _c(
                  "el-table",
                  {
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "55",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "项目名称", width: "300" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_vm._v(_vm._s(scope.row.projectName))]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "specification", label: "型号规格" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "unit",
                        label: "单位",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "number",
                        label: "数量",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "单价（含税）",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "￥" + _vm._s(_vm.fixtoTwo(scope.row.price))
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "index",
                        label: "全额（含税）",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "￥" +
                                  _vm._s(_vm.fixtoTwo(scope.row.payeeAmount))
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "index",
                        label: "税率/征收率",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(_vm.getTaxtRate(scope.row.taxRate)) + "%"
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "index",
                        label: "税额",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "￥" + _vm._s(_vm.fixtoTwo(scope.row.taxAmount))
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("Pagination", {
                  attrs: {
                    total: _vm.page.total,
                    page: _vm.page.current,
                    limit: _vm.page.size,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.page, "current", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.page, "size", $event)
                    },
                    pagination: _vm.changePage,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "centerFooter" }, [
              _c("div", { staticClass: "footerText" }, [
                _vm._v("价格合计（大写）："),
              ]),
              _c("div", { staticClass: "footerText priceRed" }, [
                _vm._v(_vm._s(_vm.toChies(_vm.selectTotal))),
              ]),
              _c("div", { staticStyle: { width: "16px" } }),
              _c("div", { staticClass: "footerText" }, [_vm._v("合计税额：")]),
              _c("div", { staticClass: "footerText priceRed" }, [
                _vm._v("¥" + _vm._s(_vm.selectTax)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "centerView" }, [
            _c(
              "div",
              {
                staticStyle: {
                  padding: "10px 0px",
                  "font-weight": "600",
                  "font-size": "18px",
                },
              },
              [_vm._v("备注信息")]
            ),
            _c(
              "div",
              { staticClass: "renarkInput" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    maxlength: "200",
                    placeholder: "请输入内容",
                    autosize: { minRows: 4, maxRows: 8 },
                  },
                  model: {
                    value: _vm.remark,
                    callback: function ($$v) {
                      _vm.remark = $$v
                    },
                    expression: "remark",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "centerView" }, [
            _c(
              "div",
              { staticClass: "centerFooter" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "100px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.goAddTax },
                  },
                  [_vm._v("发票开具")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }