
<!--  -->
<template>
  <div class="comContent">
    <div class="header">
      <span class="font">审核中的商品</span>
      <el-form ref="formTool"
               :model="formTool"
               :inline="true"
               style="text-align:left;">
        <el-form-item prop="name">
          <el-input v-model="formTool.name"
                    placeholder="请输入商品名称"
                    size="small" />
        </el-form-item>
        <el-form-item prop="approvalNumber">
          <el-input v-model="formTool.approvalNumber"
                    placeholder="请输入批准文号"
                    size="small" />
        </el-form-item>
        <el-form-item>
          <MyButton type="primary"
                    text="查询"
                    @click="getcheckList()" />
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     @click="reset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="center">
      <MyTable v-if="tableData.length > 0"
               :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getcheckList"
               @handleSelectionChange="handleSelectionChange">
        <template slot="image"
                  slot-scope="scope">
          <div style="display:flex;align-items:center">
            <div style="width:80px;height:80px;display:flex;align-items:center">
              <el-image :src="scope.row.image || avater"
                        lazy
                        style="width: 80px" />
            </div>

            <div class="imageP">
              <p style="font-weight:600;color:#262626">
                {{scope.row.name}}
              </p>
              <p>
                {{scope.row.specification}}
              </p>
              <p>
                {{scope.row.factory}}
              </p>
              <p>
                {{scope.row.barCode}}
              </p>
            </div>
          </div>

        </template>
        <template slot="menu"
                  slot-scope="scope">
          <!-- <el-button type="button" size="mini" icon="el-icon-edit" @click="editDetail(scope.row)">编辑</el-button> -->
          <el-link :underline="false"
                   type="primary"
                   @click="editDetail(scope.row)">编辑</el-link>
        </template>
      </MyTable>
      <el-empty v-else
                style="height:100%"
                description="暂无数据" />
    </div>
  </div>
</template>

<script>
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable'
import { drugenumeration } from '@/utils/enumeration'
import { getcheckList } from '@/api/productManage/auditProduct'
import avater from "/public/img/default.png"
export default {
  components: { MyButton, MyTable },
  data () {
    return {
      avater: avater,
      text: '',
      clearable: true,
      formTool: {
        name: '',
        approvalNumber: ''
      },
      operation: true,
      selection: false,
      index: false,
      tableData: [],
      tableOption: [
        { label: '商品信息', prop: 'image', slot: true, minWidth: '200px' }, // 这里表示自定义列
        // { label: '商品名称', prop: 'name' },
        // { label: '生产厂商', prop: 'factory' },
        { label: '批准文号', prop: 'approvalNumber' },
        { label: '商品属性', prop: 'type' },
        { label: 'ERP编码', prop: 'medicineCode' },
        { label: '发布时间', prop: 'createTime', width: '200px'}
      ],
      page: {
        total: 10,
        current: 1,
        size: 10
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getcheckList()
  },
  mounted () {

  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    getcheckList () {
      const params = {
        current: this.page.current,
        size: this.page.size,
        ...this.formTool
      }
      getcheckList(params).then(res => {
        if (res.code === 0) {
          this.tableData = res.data.records
          this.page.total = res.data.total

          this.tableData.forEach(item => {
            item.type = drugenumeration(item.type)
          })
        }
      })
    },
    reset () {
      // this.formTool.storeName = ''
      this.$refs.formTool.resetFields()
      this.getcheckList()
    },
    editDetail (row) {
      this.$router.push(
        {
          path: '/productManage/auditProduct-edit',
          query: { goodsId: row.goodsId }
        }
      )
    },
    handleSelectionChange (val) {

    }
  }
}
</script>
<style lang='scss' scoped>
.comContent {
  padding: 0px;
}
.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px !important;
  border-bottom: 1px solid #f5f5f5;
  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }
  .el-form-item {
    margin: 0px 0px 0px 8px !important;
  }
}
.center {
  margin-top: 16px;
  padding: 0 24px 0 24px;
  height: calc(100% - 131px);
  ::v-deep .imageP {
    width: calc(100% - 110px);
    p {
      margin: 0 0 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
