export function checkStatus(type, statu) {
  // <!-- 1.待付款, 2.已付款, 3.已发货, 4.交易成功,5.交易取消,6. 订单未提交 7退款成功  8已完成  9支付失败 -->
  // <!--1.待付款,2.待发货, 3.已发货,4.待收货，5.交易取消, 6申请退款 7.已退款  8.已完成  -->
  if (type == 1) {
    if (statu == 1 || statu == 11 || statu == 12 ) {
      return require('@/icons/img/icon_status_wait_pay.png')
    } else if (statu == 2) {
      return require('@/icons/img/icon_status_shipments.png')
    } else if (statu == 3) {
      return require('@/icons/img/icon_status_way.png')
    } else if (statu == 4) {
      return require('@/icons/img/icon_status_take.png')
    } else if (statu == 5) {
      return require('@/icons/img/icon_status_refund.png')
    } else if (statu == 6) {
      return require('@/icons/img/icon_status_refund.png')
    } else if (statu == 7) {
      return require('@/icons/img/icon_status_refund.png')
    } else if (statu == 8) {
      return require('@/icons/img/icon_status_finish.png')
    } else if (statu == 9) {
      return require('@/icons/img/icon_status_finish.png')
    }  
  }
  if (type == 2) {
    if (statu == 1) {
      return '等待付款'
    } else if (statu == 2) {
      return '待发货'
    } else if (statu == 3) {
      return '已发货'
    } else if (statu == 4) {
      return '待收货'
    } else if (statu == 5) {
      return '交易取消'
    } else if (statu == 6) {
      return '申请退款'
    } else if (statu == 7) {
      return '已退款'
    } else if (statu == 8) {
      return '配送完成'
    } else if (statu == 9) {
      return '已完成'
    } else if (statu == 11) {
      return '待开户'
    } else if (statu == 12) {
      return '待确款'
    }
  }
  if (type == 3) {
    if (statu == 1) {
      return 'yellow'
    } else if (statu == 2) {
      return 'yellow'
    } else if (statu == 3) {
      return 'green'
    } else {
      return 'gray'
    }
  }
}
export function checkImages(type, statu) {
  if (type == 1) {
    if (statu == 1 || statu == 11 || statu == 12) {
      return require('@/icons/img/icon_detail_await.png')
    } else if (statu == 2) {
      return require('@/icons/img/icon_detail_sendOut.png')
    } else if (statu == 3) {
      return require('@/icons/img/icon_detail_deliver_goods.png')
    } else if (statu == 4) {
      return require('@/icons/img/icon_detail_deliver_goods.png')
    } else if (statu == 5) {
      return require('@/icons/img/icon_detail_refund.png')
    } else if (statu == 6) {
      return require('@/icons/img/icon_detail_refund.png')
    } else if (statu == 7) {
      return require('@/icons/img/icon_detail_refund.png')
    } else if (statu == 8) {
      return require('@/icons/img/icon_detail_refund.png')
    } else if (statu == 9) {
      return require('@/icons/img/icon_detail_refund.png')
    }
  }
  // 1.待付款,2.待发货, 3.已发货,4.待收货，5.交易取消, 6申请退款 7.已退款  8.已完成
  if (type == 2) {
    if (statu == 1) {
      return '待付款'
    } else if (statu == 2) {
      return '待发货'
    } else if (statu == 3) {
      return '已发货'
    } else if (statu == 4) {
      return '待收货'
    } else if (statu == 5) {
      return '交易取消'
    } else if (statu == 6) {
      return '申请退款'
    } else if (statu == 7) {
      return '已退款'
    } else if (statu == 8) {
      return '配送完成'
    } else if (statu == 9) {
      return '已完成'
    }else if (statu == 11) {
      return '待开户'
    }else if (statu == 12) {
      return '待确款'
    }
  }
  if (type == 3) {
    if (statu == 1 || statu == 2 || statu == 4) {
      return 'yellow'
    } else if (statu == 3) {
      return 'green'
    }
  }
}
export function checkReturnImages(type) {
  if(type == 'TK') {
    return require('@/icons/img/tk.png')
  } else {
    return require('@/icons/img/thtk.png')
  }
}
export function checkZizhiImages(name, qiyeName) {
 console.log(name,qiyeName)
 if( qiyeName == '诊所') {
   if(name != qiyeName) {
    return require('@/assets/zizhi/zhensuojujue.png')
   } else {
    return require('@/assets/zizhi/zhensuotongguo.png')
   }
 }
  if( qiyeName == '春播运营平台') {
    if(name != qiyeName) {
      return require('@/assets/zizhi/yunyingjujue.png')
    } else {
      return require('@/assets/zizhi/yunyingtongguo.png')
    }
  }
  if( qiyeName == '医药公司') {
    if(name != qiyeName) {
      return require('@/assets/zizhi/gongsijujue.png')
    } else {
      return require('@/assets/zizhi/gongsitongguo.png')
    }
  }
  if( qiyeName == '生产企业') {
    if(name != qiyeName) {
      return require('@/assets/zizhi/qiyejujue.png')
    } else {
      return require('@/assets/zizhi/qiyetongguo.png')
    }
  }
}

export function checkSkuStatus (status) {
  switch (status) {
    case 'PUTAWAY':
      return '上架';
    case 'SOLDOUT':
      return '下架';
    case 'AWAIT':
      return'待审核';
    case 'FAIL':
      return '审核失败';
    case 'ALREADY':
      return '已审核';
    case 'SY_SOLDOUT':
      return '超限下架';
    default:
      return'';
  }
}