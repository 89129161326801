import request from '@/utils/request'

// 仓库中的商品列表
export function getWarebase(data) {
  return request({
    url: '/product/goodsbase/warehouse/page',
    method: 'get',
    params: data
  })
}
// 批量上架
// export function batchPutaway(data) {
//   return request({
//     url: '/product/stock/sku/batchPutaway',
//     method: 'put',
//     data
//   })
// }

// 单条上架
export function putaway(data) {
  return request({
    url: '/product/stock/batchPutaway',
    method: 'put',
    data
  })
}

// 对账单查询
export function pageBills(data) {
  return request({
    url: '/statistic/sales/order/bills/page',
    method: 'GET',
    params:data
  })
}

// 对账单查询
export function billsExport(data) {
  return request({
    url: '/statistic/sales/order/bills/export',
    method: 'GET',
    params:data,
    responseType: 'blob'
  }).then(response => {
    // console.log(response)
    // window.open(response.data)
    // 处理返回的文件流
    const blob = response.data
    if (blob && blob.size === 0) {
      Message.warning('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    const contentDisposition = response.headers['content-disposition'];
    const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1));
    console.log(fileName)
    link.download = fileName;
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  })
}