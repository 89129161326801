<template>
  <div class="billing">
    <el-form :model="formInline" ref="formInline" :rules="rules">
      <el-form-item label="开票类型：" prop="invoiceType">
        <el-checkbox-group v-model="formInline.invoiceType">
          <el-checkbox :label="'1'">普通发票（纸质）</el-checkbox>
          <el-checkbox :label="'2'">普通发票（电子）</el-checkbox>
          <el-checkbox :label="'3'">增值税专用发票（纸质）</el-checkbox>
          <el-checkbox :label="'4'">增值税专用发票（电子）</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="送达时间：" prop="deliveryWay">
        <el-radio-group v-model="formInline.deliveryWay">
          <el-radio :label="item.id" v-for="item, index in radioList" :key="index">{{ item.instructions }}</el-radio>
          <!-- <el-radio :label="'2'">月开</el-radio>
          <el-radio :label="'3'">其他（请在其他说明中备注）</el-radio> -->
        </el-radio-group>
      </el-form-item>
      <el-form-item label="说明：" v-if="formInline.deliveryWay == '1729306113932353538'" prop="remark">
        <el-input type="textarea" style="width: 500px;" v-model="formInline.remark" maxlength="200"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  
<script>
import { cominvoicepropertyDave, cominvoicepropertyDetail } from '@/api/shopmange/index'
export default {
  props: {},
  data () {
    return {
      radioList: [],
      formInline: {
        remark: '',
        deliveryWay: '',
        invoiceType: []
      },
      rules: {
        user: [
          { required: true, message: '开票类型不能为空', trigger: 'change' },
        ],
        region: [
          { required: true, message: '送达时间不能为空', trigger: 'change' },
        ],
        // text: [
        //   { required: true, message: '说明不能为空', trigger: 'blur' },
        // ]
      }
    }
  },
  components: {},
  created () {
    this.getInfo()
  },
  mounted () {

  },
  watch: {},
  methods: {
    getInfo () {
      cominvoicepropertyDetail().then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.radioList = res.data.allIndicator
            this.formInline = res.data
            this.formInline.invoiceType = res.data.invoiceType ? res.data.invoiceType.split(',') : []
            console.log(this.formInline)
          }
        }
      })
    },

    onSubmit () {
      this.$refs.formInline.validate(valid => {
        if (valid) {
          let params = {
            ...this.formInline
          }
          delete params.invoiceType
          params.invoiceType = this.formInline.invoiceType.join()
          console.log(params)
          if (this.formInline.deliveryWay == '3' && !this.formInline.remark) {
            this.$message.warning('请填写说明')
          } else {
            cominvoicepropertyDave(params).then(res => {
              if (res.code == 0) {
                this.$message.success('编辑成功')
                this.getInfo()
              } else {
                this.$message.error('编辑失败')
              }
            })
          }
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.billing {
  padding: 24px;
}
</style>

