<template>
    <div class="comContent">
        <div class="tips tips-top">
            <div>
                <span>配置注意：</span>配置新的赠品活动前，请先确认erp是否支持0元开单、开票，如果不支持，请先不要上架“赠送平台实际商品”的赠品活动，否则会导致erp开单失败，影响发货。<br />
            </div>
            <div>
                赠品活动下架条件：1、赠品可用库存；<br />

            </div>
            <div>
                赠送数量；2、促销时间结束；<br />
            </div>

            <div>
                注意：活动期间，不可以编辑修改活动内容，您可以先手动下架该活动，然后再编辑修改。<br />
            </div>

            <div>
                活动图片需要平台审核，审核通过后，促销图片将在赠品活动上架期间替换原商品的列表页和详情页主图显示给客户。<br />

            </div>

            <div>
                赠品商品在活动期间是在售商品时，请合理控制库存，尽量减少同商品编码的在售活动数，防止超卖或库存不足。<br />

            </div>

            <div>
                赠品数量设置为“赠送数量上限”时，需要填写活动最大赠送数量，赠送商品数量达到此上限时，会下架该赠品活动；设置为“按赠品商品库存”时，赠品数量会按照原赠品的库存数量进行扣减。<br />

            </div>

            <div>
                累计买满一定数量赠送，购买数量达到一定值，获得此数量对应的赠送量；<br />

            </div>

            <div>
                每买满一定数量进行赠送，例如每买满10赠送1个：买10赠1、买20赠2、买30赠3，依次类推。<br />

            </div>

        </div>
        <div class="form-content">
            <el-form label-width="140px" :model="formTool" :rules="rules" ref="formTool" :disabled="isDisabled">
                <el-row>
                    <el-col :span="24" v-if="formTool.rejectReason && formTool.status == 'REJECT'">
                        <el-form-item label="审核意见">
                            <el-input v-model="formTool.rejectReason" size="small" type="textarea" autosize disabled
                                style="width: 450px; margin-right: 10px;" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="促销标题" prop="promotionTitle">
                            <el-input v-model="formTool.promotionTitle" placeholder="请输入" size="small" clearable
                                style="width: 450px; margin-right: 10px;" />
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row>
                    <el-col :span="24">
                        <el-form-item label="赠品商品选择" prop="giftSkuId">
                            <el-button size="small" type="plain" @click="chooseGoods">选择赠品</el-button>
                            <div class="goodList" v-for="(item, key) in goodsItem" :key="key">
                                <div class="img-div">
                                    <el-image class="l" :src="item.image ? item.image : avater" alt="" />
                                </div>
                                <div class="r">
                                    <div>
                                        <span class="txt-line-1 name">{{ item.goodsSkuName }}</span>
                                    </div>
                                    <div>
                                        <span class="rr txt-line-1">{{ item.specification }}</span>
                                    </div>
                                    <div>
                                        <span class="rr txt-line-1"> 有效期至： {{ item.expireTime ? item.expireTime.slice(0, 11)
                                            : '-' }}</span>
                                    </div>
                                </div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="赠送活动配置" prop="type">
                            <div>
                                <el-radio-group v-model="formTool.type" @change="changeRadioType">
                                    <el-radio :key="1" :label="1">买满一定数量赠送</el-radio>
                                    <el-radio :key="2" :label="2">每买满一定数量赠送</el-radio>
                                </el-radio-group>
                                <el-button size="small" type="plain" @click="addLevel" v-if="formTool.type == 1"
                                    style="margin-left: 10px;">添加阶梯</el-button>
                            </div>

                            <div v-for="(item, key) in formTool.configList" :key="key">
                                买满 <el-input size="small" v-model="item.everyQty" placeholder="" clearable
                                    v-positive-integer style="width: 140px; margin-right: 10px;margin-left: 10px;" />
                                赠送 <el-input size="small" v-model="item.giftQty" placeholder="" clearable v-positive-integer
                                    style="width: 140px; margin-right: 18px;margin-left: 10px;" />
                                <el-button size="small" type="danger" icon="el-icon-delete"
                                    v-if="formTool.configList.length > 1" @click="removeLevel(key)"></el-button>
                            </div>

                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="赠品数量限制" prop="giftStock">
                            <div>
                                <el-radio-group v-model="formTool.giftStockCheck">
                                    <el-radio :key="1" :label="true">按赠品可用库存</el-radio>
                                </el-radio-group>
                                <!-- <el-checkbox v-model="formTool.giftStockCheck"  @input="changeCheck">按赠品可用库存</el-checkbox> -->
                                <el-input size="small" v-model="formTool.giftStock" placeholder="" clearable disabled
                                    style="width: 140px; margin-left: 10px;" />
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">

                        <el-form-item label="单店赠送上限" prop="purchaseUpperCheck">
                            <div>
                                <el-input size="small" v-model="formTool.purchaseUpperLimit" placeholder="" clearable
                                    @input="changeCheck2" style="width: 140px; margin-left: 10px;" />
                                <el-checkbox @input="changeCheck" v-model="formTool.purchaseUpperCheck"
                                    style="margin-left: 10px;">不限</el-checkbox>
                            </div>
                            <div class="tips">
                                单个药店赠送上限：一个药店在下方设定的周期内可赠送的最高数量
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="上限刷新周期" prop="upperLimitRefreshCycle">
                            <el-select v-model="formTool.upperLimitRefreshCycle" placeholder="请选择" size="small"
                                style="width: 400px;">
                                <el-option v-for="item in upperLimitRefreshCycle" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="上限启用时间" prop="upperLimitStartTime">
                            <el-date-picker v-model="formTool.upperLimitStartTime" :picker-options="pickerOptions_day"
                                value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择"
                                v-if="formTool.upperLimitRefreshCycle == 1" size="small">
                            </el-date-picker>
                            <el-date-picker v-model="formTool.upperLimitStartTime" :picker-options="pickerOptions_day"
                                value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择"
                                v-if="formTool.upperLimitRefreshCycle == 2" size="small">
                            </el-date-picker>
                            <el-date-picker v-model="formTool.upperLimitStartTime" :picker-options="pickerOptions_day"
                                value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择"
                                v-if="formTool.upperLimitRefreshCycle == 3" size="small">
                            </el-date-picker>
                            <el-date-picker v-model="formTool.upperLimitStartTime" :picker-options="pickerOptions_week"
                                value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择"
                                v-if="formTool.upperLimitRefreshCycle == 4" size="small">
                            </el-date-picker>
                            <el-date-picker v-model="formTool.upperLimitStartTime" :picker-options="pickerOptions_month"
                                value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择"
                                v-if="formTool.upperLimitRefreshCycle == 5" size="small">
                            </el-date-picker>
                        </el-form-item>
                        <div class="tips" style="margin-left: 140px;margin-bottom: 10px;">
                            上限启用时间根据周期而定：活动期内- 上架时间起生效；单笔 - 设置后立即生效；<br />
                            每天 - 设置当天00:00起效；每周 - 设置当周周一00:00起效；每月 - 设置当月1日00:00起效
                        </div>
                    </el-col>
                </el-row>

                <!-- <el-row>
                    <el-col :span="24">
                        <el-form-item label="促销详情" prop="promotionDetail">
                            <div>
                                <el-input size="small" v-model="formTool.promotionDetail" placeholder="请填写200字以内促销详情"
                                    clearable show-word-limit maxlength="200" :rows="3" type="textarea"
                                    style="width: 450px;" />
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row> -->

                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="date" label="促销时间" v-if="formTool.giftSkuId">
                            <el-date-picker style="width: 400px" v-model="formTool.date" value-format="yyyy-MM-dd HH:mm:ss"
                                :picker-options="formTool.giftSkuId ? pickerOptionsGift : null" type="datetimerange"
                                size="small" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间"
                                :disabled="fromPage == 'edit' ? true : false" :default-time="['00:00:00', '23:59:59']" />
                            <div class="tips">
                                促销时间不得超过以下任一时间范围：原品活动时间范围、赠品活动时间范围
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="活动图片">
                            <imgUpload v-model="formTool.imageList" :limit="1" :isEdit="isDisabled" />
                            <div class="tips">
                                (图片格式PNG,JPG,JPEG , 800*800px, 建议不要超过1M)。
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div class="flex-box-bt">
                <div class="submit-btn">
                    <el-button size="small" type="primary" @click="submit" v-if="!isDisabled">提交</el-button>
                </div>
                <div>
                    <el-button size="small" type="plain" @click="goBack">返回上一页</el-button>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="dialogVisible" title="选择赠品" top="10vh" width="1000px" :before-close="beforeClose">
            <fullGiftClassification v-if="dialogVisible" @choice="choice" :status="formTool.status" ref="chooseGoods">
            </fullGiftClassification>
        </el-dialog>
    </div>
</template>
<script>
import fullGiftClassification from "./components/fullGiftClassification";

import imgUpload from "@/components/imgUpload";
import myTable from "@/components/myTable";
import goodsDialog from "./components/goodsDialog";
import { giftPayeeSaveOrUpdate, giftPayeeDetail, queryFullGiftPage, queryFullGiftTime } from "@/api/gift";
import { time } from "echarts";

export default {
    components: { myTable, goodsDialog, imgUpload, fullGiftClassification },
    props: {},
    data() {
        return {
            dialogVisible: false,
            pickerOptions: {
                disabledDate(date) {
                    // 如果没有后面的-8.64e7就是不可以选择今天的
                    return date.getTime() < Date.now() - 8.64e7;
                },
            },
            pickerOptions_a: {},
            isDisabled: false,
            expanded: false,
            isExpanded: false,
            loading: false,
            operation: true,
            selection: true,
            index: false,
            page: {
                total: 0,
                current: 1,
                size: 10,
                pageSizes: [10, 20, 30, 50],
            },
            tableData: [],
            tableOption: [
                { label: "活动名称", prop: "promotionTitle", },
                { label: "活动时间", prop: "activeTime", slot: true, width: 330 },
                { label: "已补贴金额", prop: "activitySubsidyMoney", type: 'money' },
                { label: "参加店铺总数", prop: "storeSum", },
                { label: "参加SKU总数", prop: "skuSum", },
                { label: "活动状态", prop: "status", slot: true },
                { label: "更新人", prop: "updateBy", },
                { label: "更新时间", prop: "updateTime", },
                { label: "操作", prop: "operation", slot: true },
            ],

            formTool: {
                date: [],
                imageList: [],
                promotionTitle: '',
                // promotionDetail: '',
                giftStock: null,
                giftSkuId: '',
                type: 1,
                giftStockCheck: true,
                // everyQty买满数量  giftQty 赠送数量
                configList: [{ everyQty: '', giftQty: '' }],
                giftQty: '', //赠品数量
                upperLimitStartTime: '', //上限启用时间
                upperLimitRefreshCycle: 1, //上限刷新周期
                purchaseUpperLimit: '', //单店赠送上限
                purchaseUpperCheck: false, //单店赠送上限
            },
            rules: {
                promotionTitle: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
                date: [{ required: true, message: "请输入促销时间", trigger: "blur" }],
                // imageList: [{ required: true, message: "请上传活动图片", trigger: "change" }],
                configList: [{ required: true, message: "请输入赠送活动配置", trigger: "blur" }],
                // promotionDetail: [{ required: true, message: "请输入促销详情", trigger: "blur" }], 
                giftSkuId: [{ required: true, message: "请选择赠品", trigger: "blur" }],
                type: [{ required: true, message: "请选择赠送活动配置", trigger: "blur" }],
                giftStock: [{ required: true, message: "请填写赠品数量限制", trigger: "blur" }],
                // upperLimitStartTime: [{ required: true, message: "请选择上限启用时间", trigger: "blur" }],
                // upperLimitRefreshCycle: [{ required: true, message: "请选择上限刷新周期", trigger: "blur" }],
                purchaseUpperCheck: [{ required: true, message: "", trigger: "blur" }],
            },
            goodsItem: [],
            upperLimitRefreshCycle: [
                {
                    label: '活动期内',
                    value: 1
                },
                {
                    label: '单笔',
                    value: 2
                },
                {
                    label: '每天 (每天00:00~24:00)',
                    value: 3
                },
                {
                    label: '每周 (周一00:00~周日24:00)',
                    value: 4
                },
                {
                    label: '每月 (每月1号00:00~每月最后一天24:00)',
                    value: 5
                }
            ],
            pickerOptions: {
                disabledDate(date) {
                    // 如果没有后面的-8.64e7就是不可以选择今天的
                    return date.getTime() < Date.now() - 8.64e7;
                },
            },
            pickerOptions_month: { //限制 只能从本月一号到今天开始
                disabledDate(time) {
                    const currentDate = new Date();
                    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                    return time.getTime() < firstDayOfMonth.getTime() || time.getTime() > currentDate.getTime();
                }
            },
            pickerOptions_day: { //限制 只能等于今天
                disabledDate(time) {
                    const currentDate = new Date();
                    // 一天的毫秒数
                    const MillisecondsADay = 24 * 60 * 60 * 1000
                    //  今日零点时间戳
                    const timestamp = Math.floor(new Date(new Date().setHours(0, 0, 0, 0)).getTime())
                    const tomorrowTimeStamp = timestamp + MillisecondsADay - 1;
                    return time.getTime() < timestamp || time.getTime() > tomorrowTimeStamp;
                }
            },
            pickerOptions_week: {  // 只能从本周一到今天开始 
                disabledDate(time) {
                    const currentDate = new Date();
                    // console.log(currentDate, '---currentDate')
                    // 一天的毫秒数
                    const MillisecondsADay = 24 * 60 * 60 * 1000
                    //  今日零点时间戳
                    const timestamp = Math.floor(new Date(new Date().setHours(0, 0, 0, 0)).getTime())
                    const day = currentDate.getDay();

                    let diff = 0; // 今天与本周一差的天数
                    if (day == 1) {
                        diff = 0; // 今天与本周一差的天数
                    } else if (day == 0) {
                        diff = 6; // 今天与本周一差的天数
                    } else {
                        diff = day - 1; // 今天与本周一差的天数
                    }

                    const weekTimeStamp = timestamp - (MillisecondsADay * diff);
                    // 时间戳转日期格式  
                    return time.getTime() < weekTimeStamp || time.getTime() > currentDate.getTime();
                }
            },
            fromPage: '', //来源页面
            limitStartTime: '', //赠品原品时间限制
            limitEndTime: '', //赠品原品时间限制
            pickerOptionsGift: {
                disabledDate: (time) => {
                    console.log(this.limitStartTime)
                    if (this.limitStartTime && this.limitEndTime) { 
                        return time.getTime() < new Date(this.limitStartTime) || time.getTime() > new Date(this.limitEndTime);
                    }
                }
            },
        };
    },

    created() { },

    watch: {

    },
    mounted() {
        if (this.$route.query.type) {
            this.fromPage = this.$route.query.type
        }
        if (this.$route.query.id) {
            this.query();
        } else {
            this.changeCheck2()
        }
        if (this.$route.query.goodsSkuId) {
            this.goodsSkuId = this.$route.query.goodsSkuId.split(','); 
        }
        if (this.$route.query.type && this.$route.query.type == 'detail') {
            this.isDisabled = true
        } else {
            this.isDisabled = false
        }

    },
    methods: {
        // 切换类型
        changeRadioType(){  
            this.formTool.configList = [{
                everyQty : '',
                giftQty: '',
            }]
        },

        // 获取赠品原品时间限制
        queryFullGiftTime() {
            let params = {};
            params.goodsSkuIds = this.goodsSkuId;
           
            params.giftSkuId = this.formTool.giftSkuId;
            if (params.goodsSkuIds && params.giftSkuId) {
                queryFullGiftTime(params).then((res) => {
                   this.limitStartTime = res.startTime;
                   this.limitEndTime = res.endTime;
                }).catch((err) => {
                    return {
                        startTime: '',
                        endTime: ''
                    }
                })
            }
        },
        changeCheck() {
            if (this.formTool.purchaseUpperCheck) {
                this.formTool.purchaseUpperLimit = ''
            }
        },
        changeCheck2() {
            if (this.formTool.purchaseUpperLimit) {
                this.formTool.purchaseUpperCheck = false
            } else {
                this.formTool.purchaseUpperCheck = true
            }
        },
        query() {
            giftPayeeDetail(this.$route.query.id).then((res) => {
                if (res) {
                    this.formTool = Object.assign(this.formTool, res);
                    if (res.promotionImage) {
                        this.formTool.imageList = res.promotionImage.split(',');
                    }
                    let date = [];
                    if (res.startTime) {
                        date.push(res.startTime)
                    }
                    if (res.endTime) {
                        date.push(res.endTime)
                    }
                    this.formTool.date = date;
                    let goodsItem = [];
                    let obj = {};
                    if (res.giftSkuName) {
                        obj.giftSkuTitle = res.giftSkuTitle;
                        obj.goodsSkuName = res.giftSkuName;
                        obj.image = res.giftSkuImage;
                        obj.specification = res.giftSkuSpecification;
                        obj.expireTime = res.giftExpireTime;
                        goodsItem.push(obj)
                    }
                    this.goodsItem = goodsItem;
                    this.changeCheck();
                    this.changeCheck2();
                }
            })
        },

        submit() {
            if (!this.formTool.giftStockCheck && !this.formTool.giftStock) {
                this.$message.error('请填写赠品数量限制')
                return
            }
            this.changeCheck();
            let configList = this.formTool.configList;
            // console.log(this.formTool, '---this.formTool')
            this.$refs['formTool'].validate((valid) => {
                if (this.formTool.configList && this.formTool.configList.length) {
                    for (let i = 0; i < configList.length; i++) {
                        if (!configList[i].everyQty) {
                            this.$message.error('请填写买满数量')
                            return
                        }
                        if (!configList[i].giftQty) {
                            this.$message.error('请填写赠送数量')
                            return
                        }

                    }
                }
                if (valid) {
                    let params = {};
                    params = Object.assign(params, this.formTool);
                    params.startTime = this.formTool.date[0];
                    params.endTime = this.formTool.date[1];
                    params.promotionImage = this.formTool.imageList.join(',');
                    params.goodsSkuId = this.goodsSkuId;
                    this.isDisabled = true;
                    this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.1)'
                    })
                    giftPayeeSaveOrUpdate(params).then((res) => {
                        this.isDisabled = false;
                        this.$loading().close();
                        this.$message.success('操作成功！')
                        this.$router.back()
                    }).catch((err) => {  
                        this.isDisabled = false;
                        this.$loading().close();
                    })
                } else {

                }
            })

        },

        // 选择商品
        chooseGoods() {
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs['chooseGoods'].init()
            })
        },
        choice(val) {
            this.formTool.giftSkuId = val[0].id;
            this.goodsItem = val;
            this.formTool.giftStock = val[0].goodsSkuInventory; //赠品库存
            this.queryFullGiftTime();
        },
        beforeClose() {
            this.dialogVisible = false;
        },
        goBack() {
            this.$router.back()
        },
        addLevel() {
            if (this.formTool.configList.length == 20) {
                this.$message.warning('最多添加20条！')
                return
            }
            this.formTool.configList.push({ everyQty: '', giftQty: '' });
        },
        removeLevel(index) {
            if (this.formTool.configList.length == 1) {
                this.$message.warning('最少保留1条！')
                return
            }
            this.formTool.configList.splice(index, 1)
        },

        createGift() {
            let params = {};
            giftPayeeSaveOrUpdate(params).then((res) => { }).catch((err) => { })
        }
    },
};
</script>
<style lang="scss" scoped>
::v-deep.el-table {
    height: calc(100% - 138px) !important;
}

::v-deep.zt__table {
    height: calc(100% - 118px) !important;
}

::v-deep.el-table .el-table__body-wrapper {
    height: calc(100% - 78px) !important;
    overflow-y: scroll !important;
}

.expand-form {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.flex-box {
    width: calc(100% - 280px);
    margin: 20px 140px;
    display: flex;
    justify-content: space-between;
    // border-top: solid 1px #eee;
    padding-top: 10px;
}

.submit-btn {
    margin: 0 10px;
}

.flex-box-bt {
    width: calc(100% - 280px);
    margin: 20px 120px;
    display: flex;
    justify-content: flex-start;
    padding-top: 10px;
}

.flex-box-lf {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;

    .title {
        line-height: 30px;
        margin-left: 20px;
    }
}

.form-content {
    margin-top: 30px;
}

.tips {
    color: #8c8c8c;
    font-size: 12px;
    margin: 0;
    // margin-left: 140px;
}

.txt-line-1 {
    line-height: 24px;
}

.tips-top {
    margin-top: 20px;
    line-height: 24px;

    span {
        color: orange;
    }

    div {
        text-indent: 2em;
    }
}
</style>
  