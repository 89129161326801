var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fill-product-info-root container" }, [
    _c("div", { staticClass: "product-info" }, [
      _c("div", { staticClass: "pic-frame" }, [
        _vm.goodsBaseInfo && _vm.goodsBaseInfo.goodsImage
          ? _c("img", {
              staticClass: "pic",
              attrs: {
                src: _vm.goodsBaseInfo ? _vm.goodsBaseInfo.goodsImage : "",
              },
            })
          : _vm._e(),
      ]),
      _c("div", { staticClass: "txt-content" }, [
        _c("div", { staticClass: "big-title desp" }, [
          _c("span", [_vm._v(" 商品名称")]),
          _vm._v(
            " " +
              _vm._s(_vm.goodsBaseInfo ? _vm.goodsBaseInfo.goodsName : "") +
              " "
          ),
        ]),
        _c("div", { staticClass: "desp" }, [
          _c("span", [_vm._v("规      格")]),
          _vm._v(
            " " +
              _vm._s(_vm.goodsBaseInfo ? _vm.goodsBaseInfo.specification : "") +
              " "
          ),
        ]),
        _c("div", { staticClass: "desp" }, [
          _c("span", [_vm._v("生产厂家")]),
          _vm._v(
            " " +
              _vm._s(_vm.goodsBaseInfo ? _vm.goodsBaseInfo.factory : "") +
              " "
          ),
        ]),
        _c("div", { staticClass: "desp" }, [
          _c("span", [_vm._v("批准文号")]),
          _vm._v(
            " " +
              _vm._s(
                _vm.goodsBaseInfo ? _vm.goodsBaseInfo.approvalNumber : ""
              ) +
              " "
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "big-content" },
      [
        _c("formItem", {
          ref: "formItem",
          attrs: {
            formIn: _vm.formIn,
            AddIcon: _vm.AddIcon,
            unitList: _vm.unitList,
            shopLabelList: _vm.shopLabelList,
            minCooperativeIncome: _vm.minCooperativeIncome,
          },
          on: { inputDidChange: _vm.inputDidChange },
        }),
        _c("div", { staticClass: "harf-block" }, [
          _c("section", { staticClass: "big-block" }, [
            _vm._m(0),
            _c("section", { staticClass: "form-content" }, [
              _c("div", { staticClass: "row mutlti big" }, [
                _vm._m(1),
                _c("div", { staticClass: "value fill" }, [
                  _c(
                    "div",
                    { staticClass: "flag-block" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticStyle: { "margin-right": "30px" },
                          attrs: { indeterminate: _vm.isIndeterminate },
                          on: { change: _vm.handleCheckAllChange },
                          model: {
                            value: _vm.checkAll,
                            callback: function ($$v) {
                              _vm.checkAll = $$v
                            },
                            expression: "checkAll",
                          },
                        },
                        [_vm._v("全选")]
                      ),
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.changeCheckList },
                          model: {
                            value: _vm.formIn.shopTypeCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "shopTypeCode", $$v)
                            },
                            expression: "formIn.shopTypeCode",
                          },
                        },
                        _vm._l(
                          _vm.listData ? _vm.listData : [],
                          function (item) {
                            return _vm.listData && _vm.listData.length > 0
                              ? _c(
                                  "el-checkbox",
                                  {
                                    key: item.typeCode,
                                    attrs: { label: item.typeCode },
                                  },
                                  [_vm._v(_vm._s(item.typeName))]
                                )
                              : _vm._e()
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._m(2),
              _c("div", { staticClass: "row mutlti big" }, [
                _c("div", { staticClass: "tip" }, [_vm._v("黑名单")]),
                _c("div", { staticClass: "value fill" }, [
                  _c(
                    "div",
                    { staticClass: "flag-block" },
                    _vm._l(_vm.userList ? _vm.userList : [], function (item) {
                      return _c(
                        "el-radio",
                        {
                          key: item.id,
                          attrs: { label: item.id },
                          model: {
                            value: _vm.formIn.groupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "groupId", $$v)
                            },
                            expression: "formIn.groupId",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.schemeName) + " ")]
                      )
                    }),
                    1
                  ),
                ]),
              ]),
              _vm._m(3),
            ]),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c("el-button", { on: { click: _vm.handleBack } }, [
          _vm._v("返回上一页"),
        ]),
        _vm.pageType == "add"
          ? _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.doSubmit("add")
                  },
                },
              },
              [_vm._v("发布商品")]
            )
          : _vm._e(),
        _vm.pageType == "edit"
          ? _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.doSubmit("edit")
                  },
                },
              },
              [_vm._v("保存")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-block" }, [
      _c("span", { staticClass: "text" }, [_vm._v("销售规则")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v(" 客户类型"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row contine" }, [
      _c("div", { staticClass: "tip" }),
      _c("div", { staticClass: "value fill small-tip" }, [
        _c("span", [
          _vm._v(" 注意：被勾选的客户类型对应的客户可购买当前商品。 "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row contine" }, [
      _c("div", { staticClass: "tip" }),
      _c("div", { staticClass: "value fill small-tip" }, [
        _c("span", [_vm._v(" 注意：黑名单客户不可购买当前商品。 ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }