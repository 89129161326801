<template>
  <div class="comContent noticeDetail">
    <div class="tit">
      {{ pageTitle }}
    </div>
    <div class="form">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        :disabled="type == 2"
        class="demo-ruleForm"
      >
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="ruleForm.title"
            size="small"
            maxlength="50"
            style="width: 30%"
          ></el-input>
        </el-form-item>
        <el-form-item label="公告类型" prop="type">
          <el-select
            v-model="ruleForm.type"
            size="small"
            placeholder="请选择公告类型"
          >
            <el-option label="公告" :value="1"></el-option>
            <el-option label="质量公示" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input
            v-model="ruleForm.sort"
            size="small"
            type="number"
            oninput="
                if (value.length > 3) {
                  value = value.slice(0, 3);
                }
                value = value.replace(/[^0-9]/g, '');
              "
            style="width: 30%"
          ></el-input>
        </el-form-item>
        <el-form-item label="公告内容" prop="content">
          <wangEnduit
            :value="ruleForm.content"
            :is-clear="isClear"
            :is-disabled="isDisabled"
            @changeEndit="watchEndit"
          />
        </el-form-item>
        <el-form-item style="text-align: right" v-if="!isDisabled">
          <el-button size="small" @click="cancel">取消</el-button>
          <el-button type="primary" size="small" @click="submitForm('ruleForm')"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
      <div style="text-align: right; margin-top: 10px" v-if="isDisabled">
        <el-button type="primary" size="small" @click="cancel">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import wangEnduit from "@/components/wangEnduit";
import { addNotice, editNotice, detail } from "@/api/operationCenter/notice";
export default {
  components: { wangEnduit },
  data() {
    return {
      // title: "新增公告",
      ruleForm: {
        title: "",
        type: null,
        sort: "",
        content: "",
      },
      isClear: false,
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        type: [
          { required: true, message: "请选择公告类型", trigger: "change" },
        ],
        content: [
          { required: true, message: "请填写公告内容", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    noticeId: function () {
      return this.$route.query.id;
    },
    type: function () {
      return this.$route.query.type;
    },
    pageTitle: function () {
      return this.$route.query.type == 1
        ? "公告编辑"
        : this.$route.query.type == 2
        ? "公告详情"
        : "公告新增";
    },
    isDisabled: function () {
      return this.$route.query.type == 2 ? true : false;
    },
  },
  created() {
    if (this.noticeId) {
      this.getDetail();
    }
  },
  methods: {
    watchEndit(val) {
      // 监听富文本编辑传过来的数据
      this.ruleForm.content = val;
    },
    getDetail() {
      detail({ id: this.noticeId }).then((res) => {
        if (res) {
          this.ruleForm.title = res.title;
          this.ruleForm.type = res.type;
          this.ruleForm.sort = res.sort;
          this.ruleForm.content = res.content;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            ...this.ruleForm,
          };
          if (this.type == 1) {
            data.id = this.noticeId;
            this.editNoticeFun(data);
          } else if (this.type == 2) {
          } else {
            this.addNoticeFun(data);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancel() {
      this.$router.replace("/operationCenter/operationCenter-notice-index");
    },
    editNoticeFun(data) {
      editNotice(data).then((res) => {
        if (res) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.cancel();
        }
      });
    },
    addNoticeFun(data) {
      addNotice(data).then((res) => {
        if (res) {
          this.$message({
            message: "新增成功",
            type: "success",
          });
          this.cancel();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.noticeDetail {
  .tit {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #262626;
    padding: 20px 0;
  }
}
</style>
