import { render, staticRenderFns } from "./orderInvoiceComponnet.vue?vue&type=template&id=543cdae8&scoped=true"
import script from "./orderInvoiceComponnet.vue?vue&type=script&lang=js"
export * from "./orderInvoiceComponnet.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "543cdae8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/godata/pipelines/eShop-Portal-Web-Release-Prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('543cdae8')) {
      api.createRecord('543cdae8', component.options)
    } else {
      api.reload('543cdae8', component.options)
    }
    module.hot.accept("./orderInvoiceComponnet.vue?vue&type=template&id=543cdae8&scoped=true", function () {
      api.rerender('543cdae8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/order/components/orderInvoiceComponnet.vue"
export default component.exports