var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info_box" },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "info",
              staticClass: "ruleFormCont",
              attrs: {
                model: _vm.info,
                "label-width": "100px",
                "label-position": "top",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "formItemOne",
                  attrs: { label: "企业类型", prop: "selectNum" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "selectCont" },
                    _vm._l(_vm.shopList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          class: {
                            itemCont: true,
                            mainActive: _vm.info.shopTypeName === item.typeName,
                            mainBorder: _vm.info.shopTypeName != item.typeName,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectBtn(item, index)
                            },
                          },
                        },
                        [
                          _c("el-image", {
                            staticStyle: {
                              display: "flex",
                              "margin-right": "10px",
                            },
                            attrs: {
                              src: _vm.getStatus(
                                _vm.info.shopTypeName,
                                item.typeName
                              ),
                            },
                          }),
                          _vm._v(" " + _vm._s(item.typeName) + " "),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              ),
              _vm.info.certifys.length > 0
                ? _c(
                    "div",
                    [
                      _vm.info.shopTypeCode == "1"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "医疗机构执业许可证编码",
                                prop: "licenceNo",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  placeholder: "请输入医疗机构执业许可证编码",
                                  disabled: _vm.mode === "view",
                                },
                                model: {
                                  value: _vm.info.licenceNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.info, "licenceNo", $$v)
                                  },
                                  expression: "info.licenceNo",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.info.certifys.length > 0
                ? _c(
                    "div",
                    [
                      _vm.info.shopTypeCode == "2" ||
                      _vm.info.shopTypeCode == "3" ||
                      _vm.info.shopTypeCode == "4"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "营业执照编码",
                                prop: "idNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  placeholder: "请输入营业执照编码",
                                  disabled: _vm.mode === "view",
                                },
                                model: {
                                  value: _vm.info.certifys[0].idNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.info.certifys[0],
                                      "idNumber",
                                      $$v
                                    )
                                  },
                                  expression: "info.certifys[0].idNumber",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "采购/收货人姓名", prop: "concatName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      placeholder: "请输入采购/收货人姓名",
                      disabled: _vm.mode === "view",
                    },
                    model: {
                      value: _vm.info.concatName,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "concatName", $$v)
                      },
                      expression: "info.concatName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "采购/收货人联系电话", prop: "concatPhone" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      placeholder: "请输入采购/收货人联系电话",
                      disabled: _vm.mode === "view",
                    },
                    model: {
                      value: _vm.info.concatPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "concatPhone", $$v)
                      },
                      expression: "info.concatPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售后联系电话", prop: "servicePhone" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      placeholder: "请输入售后联系电话",
                      disabled: _vm.mode === "view",
                    },
                    model: {
                      value: _vm.info.servicePhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "servicePhone", $$v)
                      },
                      expression: "info.servicePhone",
                    },
                  }),
                ],
                1
              ),
              _vm.mode === "view"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "经营范围",
                        rules: [
                          {
                            required: true,
                            message: `请选择经营范围`,
                            trigger: `change`,
                          },
                        ],
                        prop: "scopeIds",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.info.scopeIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "scopeIds", $$v)
                            },
                            expression: "info.scopeIds",
                          },
                        },
                        _vm._l(_vm.areaList, function (item, index) {
                          return _c(
                            "el-checkbox",
                            {
                              key: index + "r",
                              attrs: { label: item.id, name: item.id },
                            },
                            [_vm._v(_vm._s(item.scopeName || ""))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "企业名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      placeholder: "请输入企业名称",
                      disabled: _vm.mode === "view",
                    },
                    model: {
                      value: _vm.info.shopName,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "shopName", $$v)
                      },
                      expression: "info.shopName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所在区域", prop: "province" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "126px" },
                      attrs: {
                        disabled: _vm.mode === "view",
                        placeholder: "请选择省",
                      },
                      on: { change: _vm.provinceBtn },
                      model: {
                        value: _vm.info.provinceName,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "provinceName", $$v)
                        },
                        expression: "info.provinceName",
                      },
                    },
                    _vm._l(_vm.provinceData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.rname, value: item.rname },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-left": "12px", width: "126px" },
                      attrs: {
                        placeholder: "请选择市",
                        disabled: _vm.mode === "view",
                      },
                      on: { change: _vm.cityBtn },
                      model: {
                        value: _vm.info.cityName,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "cityName", $$v)
                        },
                        expression: "info.cityName",
                      },
                    },
                    _vm._l(_vm.cityData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.rname, value: item.rname },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-left": "11px", width: "126px" },
                      attrs: {
                        placeholder: "请选择区",
                        disabled: _vm.mode === "view",
                      },
                      on: { change: _vm.areaBtn },
                      model: {
                        value: _vm.info.areaName,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "areaName", $$v)
                        },
                        expression: "info.areaName",
                      },
                    },
                    _vm._l(_vm.areaData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.rname, value: item.rname },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址", prop: "address" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      disabled: _vm.mode === "view",
                      placeholder: "请输入详细地址",
                    },
                    model: {
                      value: _vm.info.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "address", $$v)
                      },
                      expression: "info.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发票类型", prop: "invoiceType" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.info.invoiceType,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "invoiceType", $$v)
                        },
                        expression: "info.invoiceType",
                      },
                    },
                    _vm._l(_vm.invoicetypeData, function (item, index) {
                      return _c(
                        "el-checkbox",
                        {
                          key: index,
                          attrs: {
                            disabled: _vm.mode === "view",
                            label: item.code,
                            name: item.name,
                          },
                        },
                        [_vm._v(_vm._s(item.name || ""))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ruleForm" },
            [
              _vm._l(_vm.certifys, function (ul, index) {
                return [
                  _c(
                    "div",
                    {
                      key: index,
                      staticStyle: { "margin-bottom": "30px" },
                      attrs: { "label-width": "800px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "600px",
                            "margin-bottom": "10px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                "font-size": "14px",
                                color: "#262626",
                                "align-items": "center",
                              },
                            },
                            [
                              _c("div", [
                                ul.code != "09" && ul.require
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "margin-right": "10px",
                                        },
                                      },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  _vm._s(
                                    ul.code == "08" &&
                                      _vm.info.shopTypeCode == "1"
                                      ? "开票信息"
                                      : _vm.allTypes[ul.code]
                                  ) + " "
                                ),
                              ]),
                              ul.code != "08" &&
                              ul.code != "09" &&
                              ul.code != "10" &&
                              ul.code != "06" &&
                              ul.code != "04"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeOne(ul)
                                            },
                                          },
                                          model: {
                                            value: ul.checkedOne,
                                            callback: function ($$v) {
                                              _vm.$set(ul, "checkedOne", $$v)
                                            },
                                            expression: "ul.checkedOne",
                                          },
                                        },
                                        [_vm._v("效期10年")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeTwo(ul)
                                            },
                                          },
                                          model: {
                                            value: ul.checkedTwo,
                                            callback: function ($$v) {
                                              _vm.$set(ul, "checkedTwo", $$v)
                                            },
                                            expression: "ul.checkedTwo",
                                          },
                                        },
                                        [_vm._v("效期20年")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              ul.code == "06"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeOne(ul)
                                            },
                                          },
                                          model: {
                                            value: ul.checkedOne,
                                            callback: function ($$v) {
                                              _vm.$set(ul, "checkedOne", $$v)
                                            },
                                            expression: "ul.checkedOne",
                                          },
                                        },
                                        [_vm._v("效期1年")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeTwo(ul)
                                            },
                                          },
                                          model: {
                                            value: ul.checkedTwo,
                                            callback: function ($$v) {
                                              _vm.$set(ul, "checkedTwo", $$v)
                                            },
                                            expression: "ul.checkedTwo",
                                          },
                                        },
                                        [_vm._v("年底")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              ul.code == "04"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeOne(ul)
                                            },
                                          },
                                          model: {
                                            value: ul.checkedOne,
                                            callback: function ($$v) {
                                              _vm.$set(ul, "checkedOne", $$v)
                                            },
                                            expression: "ul.checkedOne",
                                          },
                                        },
                                        [_vm._v("效期3年")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeTwo(ul)
                                            },
                                          },
                                          model: {
                                            value: ul.checkedTwo,
                                            callback: function ($$v) {
                                              _vm.$set(ul, "checkedTwo", $$v)
                                            },
                                            expression: "ul.checkedTwo",
                                          },
                                        },
                                        [_vm._v("效期5年")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mainColor",
                                      staticStyle: {
                                        "margin-right": "10px",
                                        "font-weight": "400",
                                        color: "#999",
                                      },
                                    },
                                    [_vm._v("有效期")]
                                  ),
                                  _c("el-date-picker", {
                                    attrs: {
                                      clearable: false,
                                      type: "daterange",
                                      size: "mini",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      "range-separator": "-",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeDate(ul)
                                      },
                                    },
                                    model: {
                                      value: ul.date,
                                      callback: function ($$v) {
                                        _vm.$set(ul, "date", $$v)
                                      },
                                      expression: "ul.date",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "uploadCont" }, [
                        _c(
                          "div",
                          { staticClass: "imgList" },
                          [
                            _vm._l(ul.imgs, function (li, i) {
                              return _c(
                                "div",
                                { key: i, staticClass: "imgList_li" },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: { src: li, alt: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickImg(li)
                                      },
                                    },
                                  }),
                                  _vm.mode === "edit"
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "imgList_li_close",
                                          on: {
                                            click: function ($event) {
                                              return _vm.imgDel(index, i)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                            _vm.mode === "edit"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "imgList_li imgList_li_add",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goUpload(index, ul.type)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-plus" })]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                        ul.desc
                          ? _c("div", { staticClass: "desc" }, [
                              _vm._v(_vm._s(ul.desc)),
                            ])
                          : _vm._e(),
                        ul.code == "09"
                          ? _c(
                              "div",
                              { staticClass: "more_input" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "more_p",
                                    staticStyle: { "margin-bottom": "5px" },
                                  },
                                  [_vm._v("备注")]
                                ),
                                ul.code == "09"
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "txtarea",
                                        disabled: _vm.mode === "view",
                                      },
                                      model: {
                                        value: _vm.info.remarks,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.info, "remarks", $$v)
                                        },
                                        expression: "info.remarks",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("MyUpload", {
        ref: "imgupload",
        attrs: { "request-id": _vm.info.requestId, imgType: _vm.imgType },
        on: { upload: _vm.uploadSubmit },
      }),
      _vm.dialogDiv
        ? _c(
            "div",
            {
              staticStyle: {
                width: "30%",
                height: "100%",
                position: "fixed",
                overflow: "hidden",
                left: "0",
                top: "0px",
                "z-index": "1031",
              },
              on: {
                mouseenter: function ($event) {
                  _vm.zoomEnable = true
                },
                mouseleave: function ($event) {
                  _vm.zoomEnable = false
                },
              },
            },
            [
              _vm.showViewer
                ? _c("imageViewer", {
                    ref: "imageViewer",
                    staticStyle: {
                      width: "30%",
                      height: "100%",
                      overflow: "hidden",
                    },
                    attrs: {
                      zoomEnable: _vm.zoomEnable,
                      "append-to-body": false,
                      "on-close": _vm.closeViewer,
                      "url-list": [_vm.src],
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }