var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs_box" },
    _vm._l(_vm.tabs, function (li, i) {
      return _c(
        "p",
        {
          key: i,
          class: { act: _vm.tabi == (_vm.keyname ? li[_vm.keyname] : i) },
          on: {
            click: function ($event) {
              return _vm.tabChange(_vm.keyname ? li[_vm.keyname] : i)
            },
          },
        },
        [
          _vm._v(" " + _vm._s(li.name) + " "),
          !(_vm.numhide || li.numhide)
            ? _c("span", [_vm._v(_vm._s("(" + li.num + ")"))])
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }