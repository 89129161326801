<template>
  <div class="salesContainer">
    <div class="topView">
      <el-tabs v-model="tabPosition" @tab-click="monthYearChange">
        <el-tab-pane label="按日统计" name="day" />
        <el-tab-pane label="按月统计" name="month" />
        <el-tab-pane label="按年统计" name="year" />
      </el-tabs>
      <el-form ref="formTool" :model="formTool" size="small">
        <el-row style="display: flex; margin-top: 16px">
          <el-form-item label="" v-if="tabPosition === 'day'">
            <el-date-picker
                    v-model="formTool.date"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    size="small"
                    range-separator="-"
                    :clearable="false"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    :picker-options="pickerOptions"
            />
          </el-form-item>
          <el-radio-group
                  v-model="monthTabPosition"
                  size="mini"
                  @input="yearChange"
                  v-if="tabPosition === 'month'"
          >
            <el-radio-button label="lastYear">2024年</el-radio-button>
            <el-radio-button label="currentYear">2023年</el-radio-button>
          </el-radio-group>
          <div class="btn-box">
            <el-button
                    type="primary"
                    size="small"
                    @click="getList(1)"
                    v-if="tabPosition === 'day'"
            >查 询</el-button
            >
            <el-button size="small" @click="handleReset" v-if="tabPosition === 'day'">
              重 置
            </el-button>
            <el-button
                    size="small"
                    style="float: right; margin-bottom: 14px"
                    @click="handleExport"
            >
              导出
            </el-button>
          </div>
        </el-row>
      </el-form>
    </div>
    <div
            class="center"
            :style="contentHeight ? `height:${contentHeight}px!important` : ''"
    >
      <MyTable
              :table-data="tableData"
              :index="index"
              :selection="selection"
              :page="page"
              :showpage="showpage"
              :operation="operation"
              :table-option.sync="tableOption"
              @page-change="getList"
      >
        <template slot="order_cvr" slot-scope="scope">
          {{scope.row.order_cvr ? parseFloat((scope.row.order_cvr * 100)).toFixed(4) + '%' : ''}}
        </template>
        <template slot="pay_cvr" slot-scope="scope">
          {{scope.row.pay_cvr ? parseFloat(scope.row.pay_cvr * 100).toFixed(4) + '%' : ''}}
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>
  import MyTable from "@/components/myTable";
  import { queryGoodsSalesEcharts, excelExport } from "@/api/statistics";

  export default {
    components: { MyTable },
    data() {
      return {
        operation: false,
        selection: false,
        index: false,
        tabPosition: "day",
        monthTabPosition: "lastYear",
        formTool: {
          date: [],
        },
        formMonth: [],
        contentHeight: 0,
        tableData: [],
        tableOption: [
          { label: "日期", prop: "biz_date" },
          {
            label: "销售额",
            prop: "amount",
            customHeader: "true",
            content: "支付成功订单销售额合计",
          },
          {
            label: "采购订单数",
            prop: "order_cnt",
            customHeader: "true",
            content: "支付成功的订单数",
          },
          {
            label: "采购客户数",
            prop: "order_shop_cnt",
            customHeader: "true",
            content: "下单并支付成功的客户数合计",
          },
          {
            label: "客单价",
            prop: "avg_order_amount",
            customHeader: "true",
            content: "客单价=销售额/采购订单数",
          },
          {
            label: "订单转化率",
            prop: "order_cvr",
            customHeader: "true",
            content: "订单转化率=订单数/访客量*100%",
            slot: true
          },
          {
            label: "支付转化率",
            prop: "pay_cvr",
            customHeader: "true",
            content: "支付转化率=支付成功订单数/订单数*100%",
            slot: true
          },
        ],
        page: {
          total: 0,
          current: 1,
          size: 30,
          pageSizes: [30, 50, 100, 500],
        },
        showpage: true,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e7
          }
        }
      };
    },
    computed: {
      shopId(){
        return this.$store.state.user.user_info.shopId;
      },
    },
    created() {
      this.handleReset()
      this.getList();
    },
    updated() {
      this.getWindowView();
    },
    methods: {
      getWindowView() {
        let innerHeight = window.innerHeight; //获取页面高度
        let headHeight = 60;
        headHeight = this.$refs["formTool"].$el.clientHeight; //获取子页面head 高度
        let contentHeight = innerHeight - 280 - headHeight;
        this.contentHeight = contentHeight;
      },
      monthYearChange(tab, event) {
        const val = tab.name
        if (val === "day") {
          this.tableOption[0].label = "日期";
          this.tableOption[0].prop = "biz_date";
          this.showpage = true
          this.getDayData()
        } else if (val === "month") {
          this.tableOption[0].label = "月份";
          this.tableOption[0].prop = "biz_month";
          this.showpage = false
          this.yearChange(this.monthTabPosition)
          this.getTableMonth()
        } else if (val === "year") {
          this.tableOption[0].label = "年份";
          this.tableOption[0].prop = "biz_year";
          this.showpage = false
          this.getTableYear()
        }
      },
      yearChange(val) {
        let date1 = ''
        let date2 = ''
        this.formMonth = []
        if (val === 'lastYear') {
          date1 = this.formatDate(new Date(2024, 0, 1))
          date2 = this.formatDate(new Date(2024, 11, 31, 23, 59, 59, 999))
        } else {
          date1 = this.formatDate(new Date(2023, 0, 1))
          date2 = this.formatDate(new Date(2023, 11, 31, 23, 59, 59, 999))
        }
        this.formMonth.push(date1)
        this.formMonth.push(date2)
        this.getTableMonth()
      },
      //格式化日期：yyyy-MM-dd
      formatDate(date) {
        const myyear = date.getFullYear();
        let mymonth = date.getMonth() + 1;
        let myweekday = date.getDate();
        if (mymonth < 10) {
          mymonth = "0" + mymonth;
        }
        if (myweekday < 10) {
          myweekday = "0" + myweekday;
        }
        return (myyear + "-" + mymonth + "-" + myweekday);
      },
      getList(current) {
        if (current) {
          this.page.current = current;
        }
        this.getDayData()
      },
      // 格式化数据
      correctData(data) {
        return data || ''
      },
      // 获取日数据
      getDayData() {
        this.getTotal()
        this.getTableDay()
      },
      // 日请求参数
      getParamDay() {
        // [开始时间,结束时间,商户ID,
        // 开始时间,结束时间,商户ID,
        // 开始时间,结束时间,商户ID,
        // 每页行数,第几行开始]
        const date1 = this.correctData(this.formTool.date[0]).replace(/-/g, '')
        const date2 = this.correctData(this.formTool.date[1]).replace(/-/g, '')
        return [date1, date2, this.correctData(this.shopId),
          date1, date2, this.correctData(this.shopId),
          date1, date2, this.correctData(this.shopId),
          this.page.size, (this.page.current - 1) * 30]
      },
      // 请求日表格数据
      getTableDay() {
        const param = {
          params: this.getParamDay(),
          type: 77
        }
        queryGoodsSalesEcharts(param).then((res) => {
          if (res) {
            this.tableData = res;
          } else {
            this.tableData = [];
          }
        });
      },
      // 请求总数
      getTotal() {
        const param = {
          params: this.getParamDay(),
          type: 78
        }
        this.page.total = 0
        queryGoodsSalesEcharts(param).then((res) => {
          if (res) {
            this.page.total = Number(res[0].cnt);
          } else {
            this.page.total = 0;
          }
        });
      },
      // 请求月参数
      getParamMonth() {
        // [开始时间,结束时间,商户ID,开始时间,结束时间,商户ID,开始时间,结束时间,商户ID]
        const date1 = this.correctData(this.formMonth[0]).replace(/-/g, '')
        const date2 = this.correctData(this.formMonth[1]).replace(/-/g, '')
        return [date1, date2, this.correctData(this.shopId), date1, date2, this.correctData(this.shopId), date1, date2, this.correctData(this.shopId)]
      },
      // 获取月数据
      getTableMonth() {
        const param = {
          params: this.getParamMonth(),
          type: 80
        }
        queryGoodsSalesEcharts(param).then((res) => {
          if (res) {
            this.tableData = res;
          } else {
            this.tableData = [];
          }
        });
      },
      // 请求年参数
      getParamYear() {
        return [this.correctData(this.shopId), this.correctData(this.shopId), this.correctData(this.shopId)]
      },
      // 获取年数据
      getTableYear() {
        const param = {
          params: this.getParamYear(),
          type: 82
        }
        queryGoodsSalesEcharts(param).then((res) => {
          if (res) {
            this.tableData = res;
          } else {
            this.tableData = [];
          }
        });
      },
      handleReset() {
        const staData = new Date()
        staData.setDate(new Date().getDate() - 1)
        const endData = new Date()
        endData.setDate(new Date().getDate() - 7)
        this.formTool.date.push(this.formatDate(endData))
        this.formTool.date.push(this.formatDate(staData))
      },
      handleExport() {
        const param = {
          params: [],
          type: 79
        }
        if (this.tabPosition === 'day') {
          param.params = this.getParamDay()
          param.params.pop()
          param.params.pop()
        } else if (this.tabPosition === 'month') {
          param.type = 81
          param.params = this.getParamMonth()
        } else {
          param.type = 83
          param.params = this.getParamYear()
        }
        this.onExport(param)
      },
      onExport(param) {
        excelExport(param).then((res) => {
        });
      },
      handleDetail() {},
    },
  };
</script>

<style lang="scss" scoped>
  .salesContainer {
    height: 100%;
    background-color: #fff;
    padding: 12px;
    border-radius: 4px;
    .topView {
      ::v-deep .el-tabs {
        border-bottom: 1px solid #f2f2f2;
      }
      ::v-deep .el-form-item {
        margin-bottom: 14px !important;
      }
      .btn-box {
        margin-left: 12px;
        flex: 1;
      }
    }
    .center {
      border-top: 1px solid #f2f2f2;
      padding-top: 12px;
    }
  }
</style>
