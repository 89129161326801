var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "formTool",
          staticStyle: { "text-align": "left" },
          attrs: { inline: true },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.areaData },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "55",
                  selectable: _vm.selectable,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "客户名称",
                  prop: "customerShopName",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "销售定价", prop: "price", width: "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "unit-box" }, [
                          _c(
                            "span",
                            [
                              _c("el-input", {
                                staticClass: "tag-input",
                                staticStyle: { width: "100px" },
                                attrs: {
                                  disabled:
                                    scope.row.status == "PUTAWAY"
                                      ? true
                                      : false,
                                  size: "mini",
                                  oninput:
                                    "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                                },
                                model: {
                                  value: scope.row.price,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "price", $$v)
                                  },
                                  expression: "scope.row.price",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "unit-s",
                              on: {
                                click: function ($event) {
                                  return _vm.openDaliog(scope.row, scope.$index)
                                },
                              },
                            },
                            [
                              _c(
                                "el-tag",
                                {
                                  staticStyle: { border: "1px solid #f5222d" },
                                  attrs: { type: "primary", size: "medium" },
                                },
                                [
                                  _vm._v(
                                    " 元/" + _vm._s(scope.row.goodsSkuUnit)
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "合作医药公司名称",
                  prop: "shopName",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              disabled:
                                scope.row.status == "PUTAWAY" ? true : false,
                            },
                            on: {
                              change: (val) => {
                                _vm.reservationChange(val, scope.$index, 1)
                              },
                            },
                            model: {
                              value: scope.row.shopName,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "shopName", $$v)
                              },
                              expression: "scope.row.shopName",
                            },
                          },
                          _vm._l(_vm.medicineManager, function (item) {
                            return _c("el-option", {
                              key: item.shopId,
                              attrs: { label: item.shopName, value: item },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "医药公司康贝",
                  prop: "shopName",
                  width: "130",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              disabled:
                                scope.row.status == "PUTAWAY" ? true : false,
                            },
                            on: {
                              "visible-change": (val) => {
                                // getListByShopIdFn(val, scope.row.drugLedgerRules.shopId,1);
                                _vm.getListByShopIdFn(val, scope.row, 1)
                              },
                              change: (val) => {
                                _vm.merchantNoChange(
                                  val,
                                  scope.$index,
                                  1,
                                  scope.row
                                )
                              },
                            },
                            model: {
                              value: scope.row.merchantNo,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "merchantNo", $$v)
                              },
                              expression: "scope.row.merchantNo",
                            },
                          },
                          _vm._l(_vm.listByShopId, function (item, key) {
                            return _c(
                              "el-option",
                              {
                                key: key,
                                attrs: { label: item.merchantNo, value: item },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#8492a6",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " 康贝号: " + _vm._s(item.merchantNo)
                                    ),
                                  ]
                                ),
                                _c("span", [
                                  _vm._v(
                                    " 康贝店铺: " + _vm._s(item.merchantName)
                                  ),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "销售金额", prop: "amount", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          [
                            _c("el-input", {
                              staticClass: "tag-input",
                              staticStyle: { width: "100px" },
                              attrs: {
                                disabled:
                                  scope.row.status == "PUTAWAY" ? true : false,
                                size: "mini",
                                oninput:
                                  "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                              },
                              model: {
                                value: scope.row.amount,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "amount", $$v)
                                },
                                expression: "scope.row.amount",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "地区公司名称",
                  prop: "city_shopName",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              clearable: "",
                              disabled:
                                scope.row.status == "PUTAWAY" ? true : false,
                            },
                            on: {
                              change: (val) => {
                                _vm.reservationChange(val, scope.$index, 2)
                              },
                            },
                            model: {
                              value: scope.row.city_shopName,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "city_shopName", $$v)
                              },
                              expression: "scope.row.city_shopName",
                            },
                          },
                          _vm._l(_vm.cityManager, function (item, index) {
                            return _c("el-option", {
                              key: item.shopId + index,
                              attrs: { label: item.shopName, value: item },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "地区公司康贝",
                  prop: "city_shopName",
                  width: "130",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              clearable: "",
                              disabled:
                                scope.row.status == "PUTAWAY" ? true : false,
                            },
                            on: {
                              "visible-change": (val) => {
                                //  getListByShopIdFn(val, scope.row.cityLedgerRules.shopId,2);
                                _vm.getListByShopIdFn(val, scope.row, 2)
                              },
                              change: (val) => {
                                _vm.merchantNoChange(
                                  val,
                                  scope.$index,
                                  2,
                                  scope.row
                                )
                              },
                            },
                            model: {
                              value: scope.row.city_merchantNo,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "city_merchantNo", $$v)
                              },
                              expression: "scope.row.city_merchantNo",
                            },
                          },
                          _vm._l(_vm.listByShopId2, function (item, key) {
                            return _c(
                              "el-option",
                              {
                                key: key,
                                attrs: { label: item.merchantNo, value: item },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#8492a6",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " 康贝号: " + _vm._s(item.merchantNo)
                                    ),
                                  ]
                                ),
                                _c("span", [
                                  _vm._v(
                                    " 康贝店铺: " + _vm._s(item.merchantName)
                                  ),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "服务费金额",
                  prop: "city_amount",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          [
                            _c("el-input", {
                              staticStyle: { width: "100px" },
                              attrs: {
                                disabled:
                                  scope.row.city_shopName == "" ||
                                  scope.row.status == "PUTAWAY"
                                    ? true
                                    : false,
                                size: "mini",
                                oninput:
                                  "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                              },
                              model: {
                                value: scope.row.city_amount,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "city_amount", $$v)
                                },
                                expression: "scope.row.city_amount",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "省区公司名称",
                  prop: "serviceShopName",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              disabled:
                                scope.row.status == "PUTAWAY" ? true : false,
                            },
                            on: {
                              change: (val) => {
                                _vm.reservationChange(val, scope.$index, 3)
                              },
                            },
                            model: {
                              value: scope.row.serviceShopName,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "serviceShopName", $$v)
                              },
                              expression: "scope.row.serviceShopName",
                            },
                          },
                          _vm._l(_vm.provinceManager, function (item) {
                            return _c("el-option", {
                              key: item.shopId,
                              attrs: { label: item.shopName, value: item },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "省区公司康贝",
                  prop: "serviceShopName",
                  width: "130",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              disabled:
                                scope.row.status == "PUTAWAY" ? true : false,
                            },
                            on: {
                              "visible-change": (val) => {
                                // getListByShopIdFn(val, scope.row.cityLedgerRules.serviceShopId,3);
                                _vm.getListByShopIdFn(val, scope.row, 3)
                              },
                              change: (val) => {
                                _vm.merchantNoChange(
                                  val,
                                  scope.$index,
                                  3,
                                  scope.row
                                )
                              },
                            },
                            model: {
                              value: scope.row.serviceMerchantNo,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "serviceMerchantNo", $$v)
                              },
                              expression: "scope.row.serviceMerchantNo",
                            },
                          },
                          _vm._l(_vm.listByShopId3, function (item, key) {
                            return _c(
                              "el-option",
                              {
                                key: key,
                                attrs: { label: item.merchantNo, value: item },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#8492a6",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " 康贝号: " + _vm._s(item.merchantNo)
                                    ),
                                  ]
                                ),
                                _c("span", [
                                  _vm._v(
                                    " 康贝店铺: " + _vm._s(item.merchantName)
                                  ),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "地区需转账省区",
                  prop: "serviceAmount",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          [
                            _c("el-input", {
                              staticStyle: { width: "100px" },
                              attrs: {
                                disabled:
                                  scope.row.status == "PUTAWAY" ? true : false,
                                size: "mini",
                                oninput:
                                  "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                              },
                              model: {
                                value: scope.row.serviceAmount,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "serviceAmount", $$v)
                                },
                                expression: "scope.row.serviceAmount",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "省学术公司",
                  prop: "studyShopName",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              disabled:
                                scope.row.status == "PUTAWAY" ? true : false,
                            },
                            on: {
                              change: (val) => {
                                _vm.reservationChange(val, scope.$index, 4)
                              },
                            },
                            model: {
                              value: scope.row.studyShopName,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "studyShopName", $$v)
                              },
                              expression: "scope.row.studyShopName",
                            },
                          },
                          _vm._l(_vm.provinceManager, function (item) {
                            return _c("el-option", {
                              key: item.shopId,
                              attrs: { label: item.shopName, value: item },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "省学术康贝",
                  prop: "studyMerchantNo",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              disabled:
                                scope.row.status == "PUTAWAY" ? true : false,
                            },
                            on: {
                              "visible-change": (val) => {
                                // getListByShopIdFn(val, scope.row.cityLedgerRules.serviceShopId,3);
                                _vm.getListByShopIdFn(val, scope.row, 4)
                              },
                              change: (val) => {
                                _vm.merchantNoChange(
                                  val,
                                  scope.$index,
                                  4,
                                  scope.row
                                )
                              },
                            },
                            model: {
                              value: scope.row.studyMerchantNo,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "studyMerchantNo", $$v)
                              },
                              expression: "scope.row.studyMerchantNo",
                            },
                          },
                          _vm._l(_vm.listByShopId4, function (item, key) {
                            return _c(
                              "el-option",
                              {
                                key: key,
                                attrs: { label: item.merchantNo, value: item },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#8492a6",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " 康贝号: " + _vm._s(item.merchantNo)
                                    ),
                                  ]
                                ),
                                _c("span", [
                                  _vm._v(
                                    " 康贝店铺: " + _vm._s(item.merchantName)
                                  ),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "省区需转学术",
                  prop: "studyAmount",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "unit-box" }, [
                          _c(
                            "span",
                            [
                              _c("el-input", {
                                staticStyle: { width: "100px" },
                                attrs: {
                                  disabled:
                                    scope.row.status == "PUTAWAY"
                                      ? true
                                      : false,
                                  size: "mini",
                                  oninput:
                                    "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                                },
                                model: {
                                  value: scope.row.studyAmount,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "studyAmount", $$v)
                                  },
                                  expression: "scope.row.studyAmount",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "启用状态",
                  prop: "status",
                  align: "center",
                  fixed: "right",
                  "min-width": "105",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == "PUTAWAY"
                          ? _c("span", { staticStyle: { color: "#f5222d" } }, [
                              _vm._v("已启用"),
                            ])
                          : _vm._e(),
                        scope.row.status == "SOLDOUT"
                          ? _c("span", [_vm._v("未启用")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("Pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.areaData.length > 0,
            expression: "areaData.length > 0",
          },
        ],
        attrs: {
          total: _vm.page.total,
          "page-sizes": _vm.page.pageSizes,
          page: _vm.page.current,
          limit: _vm.page.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.page, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.page, "size", $event)
          },
          pagination: _vm.pageChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择单位",
            visible: _vm.dialogVisible,
            width: "400px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content-box" },
            _vm._l(_vm.unitList, function (item, key) {
              return _c(
                "span",
                {
                  key: key,
                  class: _vm.unitKey == key ? "unit active" : "unit",
                  on: {
                    click: function ($event) {
                      return _vm.choseUnit(item, key)
                    },
                  },
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.choseUnitSure },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }