<!--  -->
<template>
  <div class="classification">
    <div class="header">
      <!-- <span class="font">控销申请-买家</span> -->
      <el-form ref="formTool"
               :model="formTool"
               :inline="true"
               style="text-align: left">
        <el-form-item prop="name"
                      label=""
                      style="margin: 0px !important;">
          <el-input v-model="formTool.name"
                    placeholder="请输入申请人姓名"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="name"
                      label="">
          <el-input v-model="formTool.phone"
                    placeholder="请输入申请人电话"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     type="primary"
                     @click="clickSearch(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     @click="reset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="center"
         style="position: relative">
      <Tabs @tab-click="handleClick"
            :tabsList="tabsList"
            :activeName.sync="activeName"></Tabs>
      <MyTable :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getList"
               @handleSelectionChange="handleSelectionChange">
        <template slot="address"
                  slot-scope="scope">
          <el-row>
            {{ scope.row.address }}
          </el-row>
        </template>

        <template slot="demoState"
                  slot-scope="scope">
          <el-row v-if="scope.row.demoState == 0"> 待审核 </el-row>
          <el-row v-if="scope.row.demoState == 1"> 已通过 </el-row>
          <el-row v-if="scope.row.demoState == 2"> 已驳回 </el-row>
        </template>

        <template slot="caozuo"
                  slot-scope="scope">
          <el-link :underline="false"
                   type="primary"
                   class="btn"
                   @click="delailHandle(scope.row)">查看</el-link>
          <el-link :underline="false"
                   v-if=" scope.row.demoState == 0"
                   style="margin-left:10px"
                   type="primary"
                   class="btn"
                   @click="aduitHandle(scope.row, 1)">审核通过</el-link>
          <el-link :underline="false"
                   v-if=" scope.row.demoState == 0"
                   style="margin-left:10px"
                   type="primary"
                   class="btn"
                   @click="aduitHandle(scope.row, 2)">驳回</el-link>
          <!-- <el-link
            :underline="false"
            style="margin-left: 16px"
            type="primary"
            @click="delailHandle(scope.row)"
            >详情</el-link
          > -->
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import { drugenumeration } from "@/utils/enumeration";
import {
  getTeamTerminalApplys,
  soldout,
  teamTerminalApplysAgree,
  teamTerminalApplysReject,
} from "@/api/saleRule/productLine";
import avater from "/public/img/default.png";
import Tabs from "@/components/Tabs";
export default {
  components: { MyTable, Tabs },
  data () {
    return {
      avater: avater,
      formTool: {
        name: "",
        phone: "",
      },
      activeName: "first", // tabs 传的值
      tabsList: [
        // tabs 传的值
        {
          name: "first",
          label: "全部申请",
          value: 0,
        },
        {
          name: "aduit",
          label: "待审核",
          value: 0,
        },
        {
          name: "pass",
          label: "已通过",
          value: 0,
        },
        {
          name: "fail",
          label: "已驳回",
          value: 0,
        },
      ],
      selectionList: [],
      operation: false,
      selection: false,
      index: true,
      tableData: [],
      tableOption: [
        { label: "负责人姓名", prop: "managerName" },
        { label: "负责人联系方式", prop: "contactPhone" },
        { label: "申请类型", prop: "applyType" },
        { label: "申请控销品", prop: "goodsName" },
        { label: "省市地", prop: "address", slot: true }, // 这里表示自定义列
        { label: "审核状态", prop: "demoState", slot: true }, //0待审，1通过，2驳回
        { label: "申请时间", prop: "createTime" },
        { label: "操作", prop: "caozuo", slot: true, width: '180px' }
      ],
      page: {
        total: 0,
        current: 1,
        size: 20,
        pageSizes: [10, 20, 30, 50],
      },
    };
  },
  computed: {},
  watch: {},
  created () {
   
  },
  mounted () {
    if(this.$store.state.tabStatus.salesControlBuyerTab){
      this.activeName = this.$store.state.tabStatus.salesControlBuyerTab; 
      this.handleClick(this.activeName); 
    }else{
      this.getList(); 
    }
  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    // tab 切换
    handleClick (e) {
      console.log(e);
      let state = "";
      if (e == "first") {
        state = "";
      }
      if (e == "aduit") {
        state = 0;
      }
      if (e == "pass") {
        state = 1;
      }
      if (e == "fail") {
        state = 2;
      }
      this.$store.dispatch("tabStatus/setSalesControlBuyerTab",e)
      this.state = state;
      this.getNameList(1);
    },

    delailHandle (row) {
      this.$router.push({
        path: "/salesControlMange/salesControl-buyer-detail",
        query: {
          id: row.id,
        },
      });
      console.log(row);
    },

    // 重置查询条件
    reset () {
      this.formTool = {
        name: "",
        approvalNumber: "",
      };
      this.getList();
    },

    // 切换分页
    getList () {
      this.getNameList();
    },
    // 勾选
    handleSelectionChange (val) {
      this.selectionList = val;
    },
    // 查询出售中的商品
    getNameList (current) {
      if (current) {
        // 搜索条件改变从第一页开始查询
        this.page.current = current;
      }
      // debugger
      const params = {
        ...this.formTool,
        current: this.page.current,
        size: this.page.size,
        state: this.state,
      };
      getTeamTerminalApplys(params).then((res) => {
        if (res) {
          this.tableData = res.page.records;
          this.tabsList[1].value = res.awaitCheckQty;
          // console.log(this.tableData, "image");
          this.page.total = res.page.total;
          if (this.tableData.length > 0) {
            this.tableData.forEach((item) => {
              item.type = drugenumeration(item.type);
            });
          }
        } else {
          this.tableData = [];
          this.page.total = 0;
        }
      });
    },
    // 审核
    aduitHandle (row, type) {
      const params = {
        id: row.id,
      };
      if (type == 1) {
        teamTerminalApplysAgree(params).then((res) => {
          if (res) {
            this.$message.success("操作成功");
            this.getList();
          }
        });
      } else {
        teamTerminalApplysReject(params).then((res) => {
          if (res) {
            this.$message.success("操作成功");
            this.getList();
          }
        });
      }
    },
    // 搜索
    clickSearch (current) {
      this.getNameList(current);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
.classification {
  padding: 0px;
  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;
    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }
    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }
  background: white;
  .top {
    display: flex;
    padding: 10px 0 10px 0;
    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      .label {
        width: 120px;
      }
      .button {
        margin-right: 20px;
        ::v-deep .el-button {
          color: $subMenuActiveText;
          background-color: $butColor;
          border-color: $butColor;
          &:hover {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
          &:active {
            color: $subMenuActiveText;
            background: $butColor;
            background-color: $butColor;
            border-color: $butColor;
          }
          &:focus {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
        }
      }
    }
  }
  .center {
    padding: 0 24px;
    height: calc(100% - 165px);
    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }
  .btn {
    // margin: 0 10px;
  }
}
</style>
