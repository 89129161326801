var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form" },
    [
      _c("div", { staticClass: "tit" }, [_vm._v("拼团商品")]),
      _c(
        "el-form",
        {
          ref: "searchForm",
          staticStyle: { "text-align": "left" },
          attrs: { model: _vm.searchForm, inline: true },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "140px" },
                attrs: {
                  placeholder: "拼团名称",
                  clearable: "",
                  size: "small",
                },
                model: {
                  value: _vm.searchForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "name", $$v)
                  },
                  expression: "searchForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "goodsName" } },
            [
              _c("el-input", {
                staticStyle: { width: "140px" },
                attrs: {
                  placeholder: "商品名称",
                  clearable: "",
                  size: "small",
                },
                model: {
                  value: _vm.searchForm.goodsName,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "goodsName", $$v)
                  },
                  expression: "searchForm.goodsName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "goodsApprovalNumber" } },
            [
              _c("el-input", {
                staticStyle: { width: "140px" },
                attrs: {
                  placeholder: "商品批准文号",
                  clearable: "",
                  size: "small",
                },
                model: {
                  value: _vm.searchForm.goodsApprovalNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "goodsApprovalNumber", $$v)
                  },
                  expression: "searchForm.goodsApprovalNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "date" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  size: "small",
                  "range-separator": "-",
                  "start-placeholder": "活动开始",
                  "end-placeholder": "活动结束",
                },
                model: {
                  value: _vm.searchForm.date,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "date", $$v)
                  },
                  expression: "searchForm.date",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.search(1)
                    },
                  },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.reset("searchForm")
                    },
                  },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }