<!--  -->
<template>
  <div class="classification">
    <el-form ref="formTool" :inline="true" style="text-align: left">
      <div class="header">
        <section>
          <el-form-item>
            <el-select v-model="goodsType" style="width: 130px" size="small">
              <el-option label="自营商品" value="自营商品" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select size="small" @change="(val) => { goodsBaseChange(val); }" v-model="goodsBaseName" style="width: 130px">
              <el-option v-for="(item, index) in goodsBaseTypeList" :key="index" :label="item.name" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select size="small" disabled v-model="orgName" style="width: 130px">
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select size="small" @change="chooseArea" v-model="areaName" style="width: 130px" clearable>
              <el-option v-for="(item, index) in areaList" :key="index" :label="item.orgName" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="customerType" style="width: 80px" size="small">
                <el-option label="诊所" value="诊所" />
            </el-select>
          </el-form-item>
          <el-form-item prop="clinicName">
            <el-input style="width: 130px" v-model="customName" size="small" placeholder="客户名称" clearable/>
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="query()" type="primary">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="reset()" type="info">重置</el-button>
          </el-form-item>
        </section>
        <section>
          <el-button size="small" @click="updatePrice()" type="default" plain>批量设置定价</el-button>
          <el-button size="small" @click="addFixPriceInfo()" type="primary" plain>增加定价对象</el-button>
          <el-button size="small" @click="submitProductPrice()" type="primary">保存</el-button>
        </section>
      </div>
    </el-form>
    <div class="tag-box">
      <span>
        <span style="font-weight: 600;color: #333;margin-right: 20px;">地区默认定价</span>
        <span>销售定价</span>
        <span class="price-view">¥{{ priceFixedTwo(deatilData.salePrice )}}</span>
        <span>元/{{ tableTagForm.goodsSkuUnit }}</span>
      </span>
      <span class="list">合作医药公司<span class="price-view">¥{{priceFixedTwo(deatilData.drugAmount)}}</span>元</span>
      <span class="list">地区公司<span class="price-view">¥{{priceFixedTwo(deatilData.areaAmount)}}</span>元</span>
      <span class="list">地区公司转省区公司服务费<span class="price-view">¥{{ priceFixedTwo(deatilData.provinceAmount) }}</span>元</span>
      <!-- <span class="list">省区公司向学术资金专用账户转款<span class="price-view">¥{{ '60.00'}}</span>元</span> -->
    </div>
    <div class="content-table">
      <ProvinceFixPriceTable 
        ref="self-price-table" 
        :unit-list="unitList" 
        :goodsName="goodsBaseName"
        :goodsId="goodsBaseId" 
        :orgCode="areaCode || orgCode" 
        :orgName="areaName || orgName"
        :shopName="customName"
        @goodsSkuPrice="goodsSkuPriceList"
        @rowDidChange="rowDidChange"
      ></ProvinceFixPriceTable>
    </div>
    <el-dialog title="选择单位" :visible.sync="dialogVisible" width="400px" :before-close="handleClose">
      <div class="content-box">
        <span :class="unitKey == key ? 'unit active' : 'unit'" v-for="(item, key) in unitList" :key="key"
          @click="choseUnit(item, key)">{{ item }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="choseUnitSure">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="增加定价对象" :visible.sync="fixPriceDialog" width="60vw" :before-close="handleCloseFixPrice">
      <ProvinceFixPriceAddDialog :goodsId="goodsBaseId" :orgCode="areaCode || orgCode" @didClickMakeFix="didClickMakeFix" ref="fixPriceDialog"/>
    </el-dialog>
    <el-dialog title="批量设置定价" :visible.sync="fixPriceDialogMutil" width="950px" :before-close="handleCloseFixPriceMutil">
      <ProvinceFixPriceUpdateMutilDialog 
        ref="fixPriceDialogMutil"
        :orgCode="areaCode || orgCode" 
        :unit-list="unitList" 
        :customerDatas="multiArr" 
        @closePage="fixPriceDialogMutil=false" 
        :goodsName="goodsBaseName"
        :goodsId="goodsBaseId" 
        @addMutilSuccess="addMutilSuccess"
      />
    </el-dialog>
  </div>
</template>
  
<script>
import {
  getUnit,
  goodsBaseType,
  getOnlyAreaCompany,
  addShopPriceSkuByProvince,
  getOrgProductLineSkuList
} from "@/api/saleRule/productLine";
import { regionAreaTree } from "@/api/saleRule/saleF";
import ProvinceFixPriceAddDialog from './dialog/provinceFixPriceAddDialog.vue'
import ProvinceFixPriceUpdateMutilDialog from './dialog/provinceFixPriceUpdateMutilDialog.vue'
import ProvinceFixPriceTable from './components/provinceFixPriceTable.vue'
export default {
  components: { ProvinceFixPriceAddDialog, ProvinceFixPriceTable, ProvinceFixPriceUpdateMutilDialog },
  data() {
    return {
      goodsType: "自营商品",
      provinceName: "湖南省",
      areaList: [],
      areaName:'',
      areaCode:'',
      orgName:'',//省区名称
      orgCode:'',//省区code
      tableTagForm: {
        price: "",
        amount: "",
        city_amount: "",
        serviceAmount: "",
        goodsSkuUnit: "盒",
        amountZk: '60.00'
      },
      price: 0,

      tableData: [],
      unitList: [],

      page: {
        total: 0,
        current: 1,
        size: 20,
        pageSizes: [10, 20, 30, 50],
      },
      accountId: "",
      isAccount: false,
      dialogVisible: false,
      unitKey: 0,
      goodsBaseName: "",
      goodsBaseId: "",
      goodsBaseTypeList: [], //商品类型
      customName: "",
      fixPriceDialog: false,
      fixPriceDialogMutil:false,
      deatilData:{},
      customerType:'诊所',
      multiArr:[]
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getOnlyAreaCompanyAction()
    this.getUnit();
    this.goodsBaseType();
  },
  mounted() { },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
  methods: {
    handleCloseFixPrice() {
      this.fixPriceDialog = false
    },
    handleCloseFixPriceMutil() {
      this.fixPriceDialogMutil = false

    },
    addMutilSuccess(){
      this.handleCloseFixPriceMutil()
      this.$refs['fixPriceDialogMutil'].resetData()
      this.multiArr = []
      this.query()
    },
    didClickMakeFix(e) {
      if (!e.length) {
        this.$message.warning('请选择生成定价对象')
        return
      }
      if (!this.deatilData.goodsSkuPrice) {
        this.$message.warning('获取产品线 sku 失败')
        return
      }
      let obj = {
        goodsSkuPriceDTO:this.deatilData.goodsSkuPrice,
        parentOrgCode: this.areaCode?this.areaCode:this.orgCode,
        parentOrgName: this.areaName?this.areaName:this.orgName,
        shopInfoList: e
      }
      addShopPriceSkuByProvince(obj).then((res)=>{
        if (res) {
          // 新增成功定价对象后 弹窗消失/查询定价列表/提示成功
          this.fixPriceDialog = false
          this.query()
          this.$message.success('新增成功')
        }
      })
    },
    chooseArea(e) {
      this.areaCode = e.orgCode;
      this.areaName = e.orgName;
    },
    goodsBaseChange(val) {
      this.goodsBaseId = val.goodsId;
      this.goodsBaseName = val.name;
    },

    // 查询单位集合
    getUnit() {
      let params = {};
      getUnit(params).then((res) => {
        console.log(res, "unit");
        if (res && res.code == 0) {
          this.unitList = res.data;
        }
      });
    },

    // 选择单位 弹窗
    openDaliog(row, rowIndex) {
      this.dialogVisible = true;
    },

    // 选择单位choseUnit
    choseUnit(e, key) {
      this.unitKey = key;
      this.tableTagForm.goodsSkuUnit = e;
      if (this.tableTagForm.goodsSkuUnit == '件') {
        this.tableTagForm.amountZk = '720.00';
      }
    },
    getOnlyAreaCompanyAction(){
      getOnlyAreaCompany({}).then((res)=>{
        if(res.areas){
          this.areaList = res.areas
          this.orgCode = res.orgCode
          this.orgName = res.orgName
        }
      })
    },
    // 获取自营商品列表 // 自营商品type固定传1
    goodsBaseType() {
      goodsBaseType(1).then((res) => {
        console.log(res, "res");
        if (res) {
          this.goodsBaseTypeList = res;
          this.goodsBaseId = res[0].goodsId;
          this.goodsBaseName = res[0].name;
          this.query();
        }
      });
    },
    updatePrice(){
      // 批量设置定价
      if (!this.multiArr.length) {
        this.$message.warning('请选择客户')
        return
      }
      this.fixPriceDialogMutil = true;
    },
    addFixPriceInfo(){
      // 生成定价对象前查询一次产品线信息，防止用户点击后未查询产品线信息，导致查询条件和查询结果对不上
      this.query()
      this.fixPriceDialog = true
      this.$refs['fixPriceDialog']?.getList('search')
    },
    // 确认选择单位
    choseUnitSure() {
      this.dialogVisible = false;
    },
    // 批量保存设置
    submitProductPrice(node) {
      this.$refs["self-price-table"].submit();
    },

    // 全局应用 顶部设置的价格数据
    dataSynchronize() {
      if (this.tableTagForm.price == "") {
        this.$message({
          message: "请输入销售定价",
          type: "warning",
        });
        return;
      }
      if (this.tableTagForm.amount == "") {
        this.$message({
          message: "请输入合作医药公司-销售金额",
          type: "warning",
        });
        return;
      }

      if (this.tableTagForm.serviceAmount == "") {
        this.$message({
          message: "请输入地区需转账省区服务费",
          type: "warning",
        });
        return;
      }
      this.$refs["self-price-table"].save(this.tableTagForm);
    },

    handleClose() {
      this.dialogVisible = false;
    },

    // 重置
    reset() {
      this.areaCode= "";
      this.areaName = "";
      this.customName = ""
      this.query()
      // this.$refs["self-price-table"].init(this.provinceCode);
      // Object.keys(this.tableTagForm).forEach(
      //   (key) => (this.tableTagForm[key] = "")
      // );
      // this.tableTagForm.goodsSkuUnit = "盒";
      // this.tableTagForm.amountZk = '60.00';

    },
    query() {
      let params = {
        goodsId: this.goodsBaseId,
        orgCode: this.areaCode?this.areaCode :this.orgCode,
        orgName: this.areaName?this.areaName:this.orgName,
        shopName : this.customName,
      };
      getOrgProductLineSkuList(params).then((res) => {
        this.$refs["self-price-table"].init();
          if (res && res.goodsSkuPrice) {
            this.deatilData = res
          }else{
            this.deatilData = {}  
          }
        }).catch((error)=>{
          this.$refs["self-price-table"].resetData();
          this.deatilData = {}
        });
    },
    goodsSkuPriceList(e){
      this.goodsSkuPrice = e
    },
    priceFixedTwo(price){
      if (price && price > 0 ) {
        return price.toFixed(2)
      }else{
        return '0.00'
      }
    },
    rowDidChange(e){
      if (e && e.length) {
        this.multiArr = e
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";

.classification {
  padding: 0px;

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px !important;
    border-bottom: 1px solid #f5f5f5;

    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 8px 0px 0px !important;
    }
  }

  background: white;

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);
  }
}

.btn-warp {
  margin: 15px;
}

.content-table {
  padding: 10px 20px;
}

.tag-input {
  width: 100px;
}

.tag-box {
  padding: 10px;
  margin: 10px 15px;
  background: rgba(255, 255, 0, 0.246);
  line-height: 40px;
  border-radius: 5px;
  font-size: 14px;
  color: #666;

  .list {
    margin-left: 10px;
  }

  .price-view {
    background-color: #fafafa;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 0px 10px;
  }
}

.unit-tag {
  cursor: pointer;
}

.content-box {
  margin: 10px;

  .unit {
    padding: 5px;
    border: solid 1px #eee;
    color: #333;
    border-radius: 5px;
    margin: 0 5px;
    line-height: 40px;
  }

  .unit:hover {
    background: rgb(255, 73, 73);
    color: #fff;
    cursor: pointer;
  }

  .active {
    background: rgb(255, 73, 73);
    color: #fff;
  }
}

.unit-box {
  display: flex;
}

.unit-s {
  margin: 5px;
  cursor: pointer;
  color: #000;
}
</style>
  