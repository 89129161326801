var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发票信息",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.showType == "pic"
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.lookText } },
                    [_vm._v("查看文字信息")]
                  ),
                  _c("el-image", {
                    staticStyle: { width: "100%", height: "30vw" },
                    attrs: { src: _vm.invoiceData.image },
                  }),
                ],
                1
              )
            : _vm.showType == "text"
            ? _c(
                "div",
                [
                  !_vm.invoiceTypeDefault
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.lookPic },
                        },
                        [_vm._v("查看图片信息")]
                      )
                    : _vm._e(),
                  _c("p", { staticClass: "title" }, [
                    _vm._v(
                      "注意：文字信息由店员编辑，可能存在编辑错误的风险，请仔细核对，建议以开票资料为准。"
                    ),
                  ]),
                  _c("p", { staticClass: "desc" }, [
                    _vm._v(
                      "发票类型：" +
                        _vm._s(_vm.invoiceTypeDefault ? "普票" : "专票")
                    ),
                  ]),
                  _c(
                    "p",
                    { staticClass: "desc" },
                    [
                      _vm._v(
                        "税      号：" +
                          _vm._s(_vm.invoiceData.sellerTaxpayerId) +
                          " "
                      ),
                      _c(
                        "el-link",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.copyText(
                                _vm.invoiceData.sellerTaxpayerId,
                                $event
                              )
                            },
                          },
                        },
                        [_vm._v("复制")]
                      ),
                    ],
                    1
                  ),
                  !_vm.invoiceTypeDefault
                    ? _c(
                        "p",
                        { staticClass: "desc" },
                        [
                          _vm._v(
                            "开户银行：" + _vm._s(_vm.invoiceData.sellerBank)
                          ),
                          _c(
                            "el-link",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(
                                    _vm.invoiceData.sellerBank,
                                    $event
                                  )
                                },
                              },
                            },
                            [_vm._v("复制")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.invoiceTypeDefault
                    ? _c(
                        "p",
                        { staticClass: "desc" },
                        [
                          _vm._v(
                            "开户账号：" + _vm._s(_vm.invoiceData.sellerAccount)
                          ),
                          _c(
                            "el-link",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(
                                    _vm.invoiceData.sellerAccount,
                                    $event
                                  )
                                },
                              },
                            },
                            [_vm._v("复制")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "p",
                    { staticClass: "desc" },
                    [
                      _vm._v(
                        "公司名称：" + _vm._s(_vm.invoiceData.sellerName) + " "
                      ),
                      _c(
                        "el-link",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.copyText(
                                _vm.invoiceData.sellerName,
                                $event
                              )
                            },
                          },
                        },
                        [_vm._v("复制")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "desc" },
                    [
                      _vm._v(
                        "公司地址：" +
                          _vm._s(_vm.invoiceData.sellerAddress) +
                          " "
                      ),
                      _c(
                        "el-link",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.copyText(
                                _vm.invoiceData.sellerAddress,
                                $event
                              )
                            },
                          },
                        },
                        [_vm._v("复制")]
                      ),
                    ],
                    1
                  ),
                  !_vm.invoiceTypeDefault
                    ? _c(
                        "p",
                        { staticClass: "desc" },
                        [
                          _vm._v(
                            "联系电话：" + _vm._s(_vm.invoiceData.sellerTel)
                          ),
                          _c(
                            "el-link",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(
                                    _vm.invoiceData.sellerTel,
                                    $event
                                  )
                                },
                              },
                            },
                            [_vm._v("复制")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }