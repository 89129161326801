<template>
    <div class="classification">
        <div class="topText">
            <span>开票业务</span>
        </div>
        <div class="centerView">
            <div class="eachrow" v-for="item in applicationList" @click="goNext(item)">
                <img :src="require('@/assets/'+item.icon)" alt="">
                <span>{{item.name}}</span>
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        data(){
            return{
                applicationList:[
                    {name:'开票信息维护',icon:"invoiceinfo.png"},
                    {name:'蓝字发票开具',icon:"invoiceblue.png"},
                    {name:'红字发票开具',icon:"invoicered.png"},
                ]
            }
        },
        methods:{
            goNext(e){
                switch (e.name) {
                    case "蓝字发票开具":
                        this.$router.push({
                            path:'/invoiceIndex'
                        })
                        break;
                
                    default:
                        break;
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
.topText{
    margin-top: 16px;
    span{
        color: #262626;
        font-size: 16px;
        font-weight: 600;
    }
}
.centerView{
    display: flex;
    align-items: center;
    justify-content: center;
    .eachrow{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 250px;
        margin: 0px 44px;
        cursor: pointer;
        img{
            width: 100px;
            height: 100px;
        }
        span{
            margin-top: 26px;
            font-size: 18px;
            font-weight: 600;
            color: #262626;
        }
    }
}
</style>