var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "classification" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "conditions" },
      [
        _c(
          "div",
          { staticStyle: { "margin-top": "16px" } },
          [
            _c(
              "el-form",
              { ref: "formRef", attrs: { model: _vm.formRef, inline: true } },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "goodsCode",
                      label: "商品编码",
                      "label-width": "70px",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入内容" },
                      model: {
                        value: _vm.formRef.goodsCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.formRef, "goodsCode", $$v)
                        },
                        expression: "formRef.goodsCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "medicineCode", label: "商品ERP编码" } },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入内容" },
                      model: {
                        value: _vm.formRef.medicineCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.formRef, "medicineCode", $$v)
                        },
                        expression: "formRef.medicineCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "goodsName", label: "商品名称" } },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入内容" },
                      model: {
                        value: _vm.formRef.goodsName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formRef, "goodsName", $$v)
                        },
                        expression: "formRef.goodsName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "factory", label: "生产厂家" } },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入内容" },
                      model: {
                        value: _vm.formRef.factory,
                        callback: function ($$v) {
                          _vm.$set(_vm.formRef, "factory", $$v)
                        },
                        expression: "formRef.factory",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "batchNumber", label: "产品批号" } },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入内容" },
                      model: {
                        value: _vm.formRef.batchNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.formRef, "batchNumber", $$v)
                        },
                        expression: "formRef.batchNumber",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-form",
              { ref: "formTool", attrs: { model: _vm.formTool, inline: true } },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "goodsOneClassId", label: "商品分类" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { "max-width": "200px" },
                        attrs: {
                          size: "small",
                          placeholder: "一级分类",
                          clearable: "",
                        },
                        on: { change: _vm.changeOneClassId },
                        model: {
                          value: _vm.formTool.goodsOneClassId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "goodsOneClassId", $$v)
                          },
                          expression: "formTool.goodsOneClassId",
                        },
                      },
                      _vm._l(_vm.goodsOneClassIdOptions, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        staticStyle: {
                          "max-width": "200px",
                          "margin-left": "16px",
                        },
                        attrs: {
                          size: "small",
                          placeholder: "二级分类",
                          clearable: "",
                        },
                        on: { change: _vm.changeTwoClassId },
                        model: {
                          value: _vm.formTool.goodsTwoClassId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "goodsTwoClassId", $$v)
                          },
                          expression: "formTool.goodsTwoClassId",
                        },
                      },
                      _vm._l(_vm.goodsTwoClassIdOptions, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        staticStyle: {
                          "max-width": "200px",
                          "margin-left": "16px",
                        },
                        attrs: {
                          size: "small",
                          placeholder: "三级分类",
                          clearable: "",
                        },
                        model: {
                          value: _vm.formTool.goodsThreeClassId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "goodsThreeClassId", $$v)
                          },
                          expression: "formTool.goodsThreeClassId",
                        },
                      },
                      _vm._l(_vm.goodsThreeClassIdOptions, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.getTableList(1)
                          },
                        },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: { click: _vm.resetForm },
                      },
                      [_vm._v("重置")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("Tabs", {
          attrs: { tabsList: _vm.tabsList, activeName: _vm.activeName },
          on: {
            "update:activeName": function ($event) {
              _vm.activeName = $event
            },
            "update:active-name": function ($event) {
              _vm.activeName = $event
            },
            "tab-click": _vm.handleChange,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "center", staticStyle: { position: "relative" } },
      [
        _c("Table", {
          staticStyle: { width: "100%", height: "100%" },
          attrs: {
            tableData: _vm.tableData,
            activeName: _vm.activeName,
            selectionList: _vm.selectionList,
            page: _vm.page,
          },
          on: {
            getList: _vm.getList,
            handleSelectionChange: _vm.handleSelectionChange,
            detailHandle: _vm.detailHandle,
            listing: _vm.listing,
            delist: _vm.delist,
            editHandle: _vm.editHandle,
            addStash: _vm.addStash,
            logs: _vm.logs,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("span", { staticClass: "font" }, [_vm._v("商品列表")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }