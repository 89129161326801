<template>
  <div class="comContent">
    <div class="shourujilu">
      收入记录
    </div>
    <div class="content">
      <header class="header">
        <!-- <div>
          <div class="total">收入总金额(元)</div>
          <div class="number">
            <span>￥{{ amtTotal }}</span>
          </div>
        </div> -->
        <div style="">
          <div class="total">可提现总金额(元)</div>
          <div class="number">
            <span>￥{{ amtAvailable }}</span>
          </div>
        </div>
        <div>
          <div class="total">
            <el-button size="large"
                       type="primary"
                       @click="getMoney"
                       :disabled="!enableWithdraw">提现</el-button>
          </div>
        </div>
      </header>
      <div class="title">
        <div class="detail">收入明细</div>
        <el-form ref="formTool"
                 :model="formTool"
                 :inline="true"
                 style="text-align:left;">
          <el-form-item prop="id">
            <el-input v-model="formTool.orderCode"
                      clearable
                      placeholder="请输入订单号"
                      size="small" />
          </el-form-item>
          <el-form-item prop="status">
            <el-select size="small"
                       v-model="formTool.status"
                       clearable
                       placeholder="结算状态">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="date">
            <el-date-picker v-model="formTool.date"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            size="small"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期" />
          </el-form-item>
          <el-form-item>
            <MyButton type="primary"
                      text="查询"
                      @click="getcheckList(1)" />
          </el-form-item>
          <el-form-item>
            <MyButton type=""
                      text="重置"
                      @click="reset()" />
          </el-form-item>
          <el-form-item>
            <MyButton type=""
                      text="导出"
                      @click="exportShopPayRecord()" />
          </el-form-item>
        </el-form>
      </div>
      <el-divider />
      <div class="center">
        <MyTable :table-data="tableData"
                 class="myMoney"
                 :index="index"
                 :selection="selection"
                 :page="page"
                 :operation="operation"
                 :table-option.sync="tableOption"
                 @page-change="getcheckList"
                 @handleSelectionChange="handleSelectionChange">
          <template slot="price"
                    slot-scope="scope">
            <span :style="scope.row.type != 'payee' ? 'color:orange':''">{{scope.row.type == 'payee' ? '+￥':'-￥'}}{{scope.row.payAmount}}</span>
          </template>
          <template slot="paySerialNo"
                    slot-scope="scope">
            <el-tooltip class="item"
                        effect="dark"
                        placement="top">
              <div slot="content"> 支付流水号：{{ scope.row.paySerialNo }}</div>
              <span>详情</span>
            </el-tooltip>

          </template>
        </MyTable>
        <div class="search_income">
          <div>
            收入：<span style="color:green;font-weight: 400">￥{{ totalPayeeAmount }}</span>
          </div>
          <div style="margin-left:15px">
            退款：<span style="color:orange;font-weight: 400">￥{{ totalRefundAmount }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提现"
               :visible.async="moneyVisible"
               width="500px"
               :before-close="handleCloseMoney"
               :close-on-press-escape="false"
               :close-on-click-modal="false">
      <el-form ref="moneyForm"
               :model="moneyForm"
               label-width="120px"
               :rules="moneyRules">
        <el-form-item label="银行卡"
                      prop="bank">
          <el-select size="small"
                     v-model="moneyForm.bank"
                     clearable
                     placeholder="结算状态"
                     style="width: 260px;">
            <el-option v-for="item in bankList"
                       :key="item.bankCode"
                       :value="item.bankCardId"
                       :label="item.nameNumber">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提现金额"
                      prop="money">
          <el-input v-model="moneyForm.money"
                    clearable
                    style="width: 260px;"
                    placeholder="请输入"
                    size="small" />
        </el-form-item>
        <el-form-item label="支付密码"
                      prop="password">
          <el-input v-model="moneyForm.password"
                    clearable
                    show-password
                    style="width: 260px;"
                    placeholder="请输入"
                    size="small" />
        </el-form-item>
        <el-form-item label="备注"
                      v-show="parseFloat(moneyForm.money)>50000"
                      prop="postscript">
          <el-input v-model="moneyForm.postscript"
                    clearable
                    style="width: 260px;"
                    placeholder="提现超过 5 万请输入"
                    size="small" />
        </el-form-item>
        <el-form-item>
          <div style="width: 260px;display: flex;align-items: center;justify-content: flex-end;">
            <el-button type="default"
                       size="small"
                       @click="handleCloseMoney">返回</el-button>
            <el-button type="primary"
                       size="small"
                       @click="handleMoneyAction">确认</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="短信验证码"
               :visible.async="codeVisible"
               width="500px"
               :before-close="handleCloseCode">
      <el-form label-width="120px">
        <el-form-item label="验证码">
          <el-input v-model="varifyCode"
                    style="width: 260px;"
                    placeholder="请输入"
                    size="small" />
        </el-form-item>
        <el-form-item>
          <div style="width: 260px;display: flex;align-items: center;justify-content: flex-end;">
            <el-button type="default"
                       size="small"
                       @click="handleCloseCode">返回</el-button>
            <el-button type="primary"
                       size="small"
                       @click="withdrawalValidaAction">确认</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable'
import { getcheckList } from '@/api/productManage/auditProduct'
import { getShopPayRecord, getAccountData, getBankListUrl, kangBeiWithdrawal, withdrawalValida, obtainWithdrawalEligibility ,payeeRecordExportShopPayRecord} from '@/api/financialcenter.js'
import { currentDetails } from '@/api/myQualification/index'
export default {
  components: { MyButton, MyTable },
  data () {
    return {
      amtAvailable: '',
      amtTotal: '',
      options: [{
        value: 'true',
        label: '已结算'
      }, {
        value: 'false',
        label: '未结算'
      }],
      value: '',
      text: '',
      clearable: true,
      totalPayeeAmount: '0.00', // 收入
      totalRefundAmount: '', // 退款
      money: '0.00',
      formTool: {
        orderCode: '',
        status: '',
        date: []
      },
      operation: false,
      selection: false,
      index: false,
      tableData: [],
      tableOption: [
        { label: '收入金额', prop: 'price', slot: true, },
        { label: '结算状态', prop: 'status' },
        { label: '采购方', prop: 'payerClinicName' },
        { label: '代付款方', prop: 'replacePayerShopName' },
        { label: '订单创建时间', prop: 'createTime' },
        { label: '资金到账时间', prop: 'finishTime' },
        { label: '订单号', prop: 'orderCode' },
        { label: '交易详情', prop: 'paySerialNo', slot: true }, // 这里表示自定义列, slot: true

      ],
      page: {
        total: 10,
        current: 1,
        size: 10
      },
      moneyVisible: false,
      moneyForm: {
        bank: '',
        password: '',
        money: ''
      },
      moneyRules: {
        bank: [
          { required: true, message: '请选择银行卡', trigger: 'blur' }
        ],
        money: [
          { required: true, message: '请输入提现金额', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],

      },
      merchantNo: '',
      bankList: [],
      codeVisible: false,
      orderB2bDataVO: undefined,
      varifyCode: '',
      enableWithdraw: false,//是否允许提现
    }
  },
  computed: {},
  watch: {},
  created () {
    // this.getcheckList()
    this.getAccountData()
    this.getShopPayRecord()
    this.getBankList()
  },
  mounted () {

  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    exportShopPayRecord(){
      let params ={};
      params.orderCode = this.formTool.orderCode;
      params.status = this.formTool.status;
      params.startDate = this.formTool.date[0] || "";
      params.endDate = this.formTool.date[1]  || "";
      payeeRecordExportShopPayRecord(params).then((res)=>{}).catch((err)=>{})
    },
    getMoney () {
      this.moneyVisible = true
    },
    handleCloseMoney () {
      this.moneyVisible = false
    },
    handleCloseCode () {
      this.codeVisible = false
    },
    // 提现
    handleMoneyAction () {
      this.$refs['moneyForm'].validate((validate) => {
        if (!validate) {
          return
        }
        if (parseFloat(this.moneyForm.money) > 50000 && !this.moneyForm.postscript) {
          this.$message.warning('超过 5 万请输入备注')
          return
        }
        const obj = {
          amount: this.moneyForm.money,
          bankCardId: this.moneyForm.bank,
          merchantNo: this.merchantNo,
          password: this.moneyForm.password,
          postscript: this.moneyForm.postscript
        }
        kangBeiWithdrawal(obj).then((resp) => {
          if (resp && resp.orderB2bDataVO) {
            this.codeVisible = true
            this.orderB2bDataVO = resp.orderB2bDataVO
          }
        })

      })
    },
    // 提现短信验证码验证
    withdrawalValidaAction () {
      const orderB2bDataVO = this.orderB2bDataVO
      if (!this.varifyCode) {
        this.$message.warning('请输入验证码')
        return
      }
      const obj = {
        payerId: orderB2bDataVO.userId,
        token: orderB2bDataVO.token,
        totalAmount: orderB2bDataVO.totalAmount,
        txnSeqno: orderB2bDataVO.txnSeqno,
        verifyCode: this.varifyCode,
      }
      withdrawalValida(obj).then((resp) => {
        if (resp && resp.code == 0 && resp.data) {
          this.$message.success(resp.data.retMsg)
          this.codeVisible = false
          this.moneyVisible = false
        }
      })
    },
    //获取账户康贝余额
    getAccountData () {
      getAccountData().then(res => {
        if (res.code == 0) {
          if(res.data){
            this.amtAvailable = res.data.amtAvailable
            this.amtTotal = res.data.amtTotal
          } 
        }
      })
      // 获取用户康贝详情
      currentDetails().then((res) => {
        if (res.code == 0) {
          const { merchantNo, id } = res.data
          this.merchantNo = merchantNo
          this.getBankList(merchantNo)
          this.obtainWithdrawalEligibilityAction(id)
        }
      })
    },
    /**
     * 获取当前康贝的银行卡列表
     * @param {merchantNo}  
     */
    async getBankList (merchantNo) {
      const resp = await getBankListUrl({ merchantNo: merchantNo })
      if (resp && resp.data && resp.data.length) {
        resp.data.forEach(item => {
          this.$set(item, 'nameNumber', `${item.bankName} (${item.bankCardNo.substr(-4)})`)
        })
        this.bankList = resp.data
      }
    },
    /**
     * 余额低于保证金不允许提现
     * @param shopId
     */
    async obtainWithdrawalEligibilityAction (shopId) {
      const resp = await obtainWithdrawalEligibility(shopId)
      if (resp && resp.data) {
        this.enableWithdraw = true
      }

    },
    getShopPayRecord () {
      let params = {
        current: 1, // 页码
        size: 10, // 分页大小
        orderCode: this.formTool.orderCode, // 订单id
        status: this.formTool.status,
        startDate: !this.formTool.date || this.formTool.date.length == 0 ? '' : this.formTool.date[0], // 开始时间
        endDate: !this.formTool.date || this.formTool.date.length == 0 ? '' : this.formTool.date[1] // 结束时间
      }
      // this.loadingBtn = true
      getShopPayRecord(params).then(res => {
        if (res.code === 0) {
          if (res.data) {
            this.tableData = res.data.pageData.records
            if (this.tableData.length > 0) {
              this.tableData.forEach(item => {
                if (item.status) {
                  item.status = '已结算'
                } else {
                  item.status = '未结算'
                }
              })
            }
          } else {
            this.tableData = []
          }
          this.page.total = res.data.pageData.total
          this.totalPayeeAmount = res.data.totalPayeeAmount
          this.totalRefundAmount = res.data.totalRefundAmount
        }

      })
        .catch(() => {
          // this.loadingBtn = false
        })
    },
    getcheckList (current) {
      if (current) {
        this.page.current = current
      }
      const params = {
        current: this.page.current,
        size: this.page.size,
        orderCode: this.formTool.orderCode, // 订单id
        status: this.formTool.status,
        startDate: !this.formTool.date || this.formTool.date.length == 0 ? '' : this.formTool.date[0], // 开始时间
        endDate: !this.formTool.date || this.formTool.date.length == 0 ? '' : this.formTool.date[1] // 结束时间
      }
      getShopPayRecord(params).then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.tableData = res.data.pageData.records
            if (this.tableData.length > 0) {
              this.tableData.forEach(item => {
                if (item.status) {
                  item.status = '已结算'
                } else {
                  item.status = '未结算'
                }
              })
            }
          } else {
            this.tableData = []
          }
          this.page.total = res.data.pageData.total
          this.totalPayeeAmount = res.data.totalPayeeAmount
          this.totalRefundAmount = res.data.totalRefundAmount
          // if (this.tableData.length > 0) {
          //   this.tableData.forEach(item => {
          //     // item.type = drugenumeration(item.type)
          //     let a = 0
          //     this.tableData.forEach(item => {
          //       a = (a * 100 + item.payAmount * 100) / 100
          //     })
          //     this.money = a.toFixed(2)
          //   })
          // } else {
          //   this.money = 0
          // }
        }
      })
    },
    reset () {
      this.formTool.orderCode = ''
      this.formTool.status = ''
      this.formTool.date = []
      // this.getcheckList()
    },
    handleSelectionChange (val) {

    }
  }
}
</script>
<style lang='scss' scoped>
.comContent {
  padding: 0px;
}
.shourujilu {
  height: 56px;
  line-height: 56px;
  font-weight: 600;
  color: #262626;
  font-size: 16px;
  padding: 0 24px;
  border-bottom: 1px solid #f5f5ff;
}
.content {
  margin: 0 24px;
  height: calc(100% - 80px);
}
.header {
  display: flex;
  padding-top: 12px;
  font-size: 16px;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 600;
  padding-bottom: 40px;
  // border-bottom: 1px solid;
  div {
    width: 200px;
  }
  .total {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #262626;
    padding: 20px 0px;
    height: 70px;
    display: flex;
    align-items: center;
  }
  .number {
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #f6212d;
    .xiaosu {
      font-size: 24px;
    }
  }
}
.search_income {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
  font-size: 14px;
  color: #262626;
  text-align: left;
  // div {
  // width: 140px;
  // }
  span {
    font-size: 20px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    color: #f6212d;
  }
}
.comContent {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-form-item {
      margin-bottom: 0px !important;
    }
    .detail {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }
  }
  .center {
    height: calc(100% - 235px);
    ::v-deep .myMoney {
      .el-table__body td:last-child {
        color: red;
      }
    }
  }
}
::v-deep .pagination-container {
  .el-pagination {
    text-align: left;
  }
}
</style>
