<template>
  <div class="detail-root">
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="1158px"
      @closed="onClosed"
      v-loading="loading"
    >
      <div class="full-content">
        <div class="content form-content">
          <div class="task-base-info">
            <div class="base-info-item">
              <div class="tip">编号</div>
              <div class="value">xxxx23423</div>
            </div>
            <div class="base-info-item">
              <div class="tip">流程状态</div>
              <div class="value">执行中</div>
            </div>
            <div class="base-info-item">
              <div class="tip">创建人</div>
              <div class="value">FMK</div>
            </div>
            <div class="base-info-item">
              <div class="tip">创建时间</div>
              <div class="value">2020-11-23 14:24</div>
            </div>
          </div>
          <div class="block">
            <div class="title">任务基本信息</div>
            <div class="flex-layout">
              <div class="flex-item">
                <div class="tip">任务名称<span class="weight">*</span></div>
                <div class="value"><el-input size="small" /></div>
              </div>
              <div class="flex-item">
                <div class="tip">任务编码</div>
                <div class="value">
                  <el-input size="small" disabled placeholder="创建后自动生成" />
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">关联指标<span class="weight">*</span></div>
                <div class="value">
                  <el-select size="small" v-model="value" placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">CSO公司名称</div>
                <div class="value">
                  <el-input
                    size="small"
                    disabled
                    placeholder="由所选指标数据关联"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">执行次数<span class="weight">*</span></div>
                <div class="value">
                  <el-input
                    size="small"
                    type="number"
                    maxlength="2"
                    v-model.trim="discountl"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">
                  单次执行金额<span class="weight">*</span>
                </div>
                <div class="value">
                  <el-input
                    size="small"
                    type="number"
                    maxlength="10"
                    v-model.trim="discountl"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div class="tip">任务类型</div>
                <div class="value">
                  <el-input size="small" />
                </div>
              </div>
              <div class="flex-item long">
                <div class="tip">任务说明</div>
                <div class="value">
                  <el-input size="small" maxlength="200" />
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="title">子任务列表(100条)</div>
            <div>
              <div class="condition-layout">
                <el-radio-group size="small" v-model="radio">
                  <el-radio-button
                    :label="item.id"
                    v-for="(item, index) in childTaskStatusList"
                    :key="index"
                    >{{ item.label }}({{ item.value }})</el-radio-button
                  >
                </el-radio-group>
                <div class="right">
                  <el-input
                    style="width: 240px; margin-right: 8px"
                    placeholder="搜索子任务/执行人/子任务金额"
                    clearable
                    size="small"
                  />
                  <el-button size="small"> 查询 </el-button>
                  <el-button size="small"> 批量审核 </el-button>
                </div>
              </div>
              <div class="table-lalyout">
                <MyTable
                  :index="true"
                  :selection="true"
                  :table-data="tableData"
                  :page="page"
                  :table-option.sync="tableOption"
                  @handleSelectionChange="handleSelectionChange"
                >
                  <template slot="operate" slot-scope="scope">
                    <div class="table-op-col">
                      <el-link
                        type="primary"
                        :underline="false"
                        size="small"
                        @click="handleLook(scope.$index)"
                        >查看</el-link
                      >
                      <el-link
                        type="primary"
                        :underline="false"
                        size="small"
                        @click="handleLook(scope.$index)"
                        >审核</el-link
                      >
                    </div>
                  </template>
                </MyTable>
              </div>
            </div>
          </div>
        </div>
        <div class="right-parts">
          <div class="title">任务日记</div>
          <div class="v-list">
            <div
              class="v-list-item"
              v-for="(item, index) in [1, 1, 1, 1, 1, 1]"
              :key="index"
            >
              <div class="left">
                <div class="line up"></div>
                <div class="dot"></div>
                <div class="line down"></div>
              </div>
              <div class="datas-view">
                <div class="time">2023-01-23 14:23</div>
                <div class="detail-info">
                  <div class="tip">创建人</div>
                  <div class="more-info">
                    <div class="multi-info">FMS发起任务</div>
                    <div class="num">
                      <el-image class="small-icon" :src="toolIcon" />
                      <span>10</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">关闭</el-button>
        <el-button
          v-if="!isLook"
          size="small"
          type="primary"
          @click="handleSave"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { splitMoney } from "@/utils/validate";
import MyTable from "@/components/myTable";
import IConst_Tool_Icon from "@/assets/compliance/tool.png";

import {
  addScienceProfile,
  querySalesScienceProfileById,
  updateScienceProfile,
} from "@/api/salesControl/academic";

export default {
  components: { MyTable },
  data() {
    return {
      action: 3, //1 新增  2 编辑 3 查看
      dialogVisible: false,
      posterId: null,
      loading: false,

      toolIcon: IConst_Tool_Icon,

      options: [],
      discountl: "",
      value:"",

      commandList: [
        { value: "info", label: "信息调研" },
        { value: "visit", label: "终端拜访" },
        { value: "active", label: "学术活动" },
      ],
      currentCommand: "info",

      radio: 1,
      childTaskStatusList: [
        { id: 1, label: "全部", value: 0 },
        { id: 2, label: "审核中", value: 0 },
        { id: 3, label: "已通过", value: 0 },
        { id: 4, label: "未通过", value: 0 },
      ],

      tableData: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      tableOption: [
        { label: "子任务编码", prop: "id" },
        { label: "子任务名称", prop: "posterName" },
        { label: "提交人", prop: "posterName" },
        { label: "提交时间", prop: "posterName" },
        { label: "子任务金额", prop: "auditStatus" },
        { label: "审核状态", prop: "goodsName" },
        { label: "操作", prop: "operate", slot: true, width: "100px" },
      ],
      page: { total: 0, current: 1, size: 10 },
      selectedList: [], //选中的列表，待导出
    };
  },
  computed: {
    title() {
      let txt = "任务";
      if (this.action === 3) {
        txt = `终端拜访${txt}`;
      }
      return txt;
    },
    isLook() {
      return this.action === 3;
    },
    showAuditOpinion() {
      return this.isLook && this.auditStatus === 2;
    },
  },
  methods: {
    open(options = null) {
      if (options) {
        this.action = options.action ? options.action : 1;
        this.currentCommand = options.command ? options.command : "info";
        this.getDetail();
      }

      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    onClosed() {
      this.reset();
    },
    reset() {
      this.action = 1;
    },
    handleSelectionChange(list) {
      this.selectedList = list;
    },
    handleSelectTaskType(index) {
      const item = this.commandList[index];
      this.currentCommand = item.value;
    },
    thresholdReg2(key, value, index, i) {
      // 9999999.99
      if (value) {
        let reg = /^\d{1,7}(\.\d{0,2})?$/;
        if (!reg.test(value)) {
          this.discountl = splitMoney(value, 7, 2);
        }
      }
    },
    getDetail() {
      if (this.posterId) {
        this.loading = true;
        querySalesScienceProfileById({ id: this.posterId })
          .then((data) => {
            this.loading = false;
            if (data) {
              this.posterName = data.posterName;

              this.auditStatus = data.auditStatus; //审核状态
              this.auditOpinion = data.auditOpinion; //审核意见

              this.goodsInfo = {
                goodsId: data.goodsId,
                name: data.goodsName,
                factory: data.factory,
                specification: data.specification,
                goodsCode: data.goodsCode,
                image: data.goodsImage,
                approvalNumber: data.approvalNumber,
              };
              if (Array.isArray(data.details)) {
                let picFileList = [];
                let videoFileList = [];
                data.details.forEach((item) => {
                  if (item) {
                    /**{name:"",url:""} */
                    let urlObj = this.parseToUploadUrl(item.contentUrl);
                    if (urlObj && urlObj.name && urlObj.url) {
                      if (item.type === "DATA_CONTENT") {
                        picFileList.push(urlObj);
                      } else if (item.type === "VIDEO_CONTENT") {
                        if (item.surfaceDrawingUrl) {
                          urlObj.surfaceDrawingUrl = item.surfaceDrawingUrl;
                        }
                        if (item.videoDuration) {
                          urlObj.videoDuration = item.videoDuration;
                        }
                        videoFileList.push(urlObj);
                      }
                    }
                  }
                });

                this.picFileList = picFileList;
                this.videoFileList = videoFileList;
              }
            }
          })
          .catch((ex) => {});
      }
    },
    handleSave() {
      if (!this.posterName) {
        this.$message.warning("请填写名称!");
        return;
      }
      if (this.picFileList.length === 0) {
        this.$message.warning("请上传资料内容附件!");
        return;
      }
      if (this.videoFileList.length === 0) {
        this.$message.warning("请上传视频内容附件!");
        return;
      }
      if (!this.goodsInfo) {
        this.$message.warning("请选择商品!");
        return;
      }
      const data = {
        id: this.posterId ? this.posterId : "",
        posterName: this.posterName,
        goodsId: this.goodsInfo.goodsId,
        goodsName: this.goodsInfo.name,
        factory: this.goodsInfo.factory,
        specification: this.goodsInfo.specification,
        goodsCode: this.goodsInfo.goodsCode,
        goodsImage: this.goodsInfo.image,
        approvalNumber: this.goodsInfo.approvalNumber,
        details: [],
      };
      this.picFileList.forEach((item) => {
        let url = this.getUploadFileUrl(item);
        if (url) {
          data.details.push({ type: "DATA_CONTENT", contentUrl: url });
        }
      });
      this.videoFileList.forEach((item) => {
        let url = this.getUploadFileUrl(item);
        if (url) {
          let videoObj = { type: "VIDEO_CONTENT", contentUrl: url };
          if (item.surfaceDrawingUrl) {
            videoObj.surfaceDrawingUrl = item.surfaceDrawingUrl;
          }
          if (item.videoDuration) {
            videoObj.videoDuration = item.videoDuration;
          }
          data.details.push(videoObj);
        }
      });
      if (this.posterId) {
        //编辑
        updateScienceProfile(data).then((res) => {
          if (res) {
            this.$emit("onRefresh");
            this.dialogVisible = false;
          }
        });
      } else {
        //保存
        addScienceProfile(data).then((res) => {
          if (res) {
            this.$emit("onRefresh");
            this.dialogVisible = false;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-root {
  .full-content {
    display: flex;
    height: 100%;
    .right-parts {
      height: 100%;
      overflow-y: auto;
      width: 280px;
      display: flex;
      flex-direction: column;
      background: #f9fafc;
      padding: 0 12px;
      box-sizing: border-box;

      .title {
        font-size: 15px;
        font-weight: bold;
        color: #262626;
        padding: 12px 12px;
        // border-bottom: 1px solid #f0f0f0;
      }
      .v-list {
        display: flex;
        flex-direction: column;

        .v-list-item {
          display: flex;

          .left {
            width: 14px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .line {
              width: 2px;
              background-color: #f0f0f0;
              &.up {
                height: 10px;
              }
              &.down {
                flex: 1;
              }
            }
            .dot {
              width: 8px;
              height: 8px;
              border-radius: 999px;
              background-color: #c0c0c0;
            }
          }

          .datas-view {
            flex: 1;
            padding: 6px 0;
            box-sizing: border-box;
            margin-left: 12px;
            display: flex;
            flex-direction: column;

            .time {
              font-size: 13px;
              font-weight: normal;
              color: #999;
            }

            .detail-info {
              border-radius: 4px;
              background: #fff;
              padding: 0 12px;
              box-sizing: border-box;
              border: 1px solid #f0f0f0;
              margin-top: 6px;

              .tip {
                font-size: 14px;
                color: #262626;
                padding: 8px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #f0f0f0;
              }

              .more-info {
                display: flex;
                justify-content: space-between;
                padding: 8px 0;
                box-sizing: border-box;

                .multi-info {
                  border-radius: 999px;
                  padding: 2px 8px;
                  box-sizing: border-box;
                  font-size: 12px;
                  color: #262626;
                  display: flex;
                  align-items: center;
                  background: #f0f0f0;

                  .small-icon {
                    width: 12px;
                    height: 12px;
                    margin-right: 4px;
                  }
                }

                .num {
                  border-radius: 999px;
                  padding: 2px 8px 2px 4px;
                  box-sizing: border-box;
                  font-size: 12px;
                  color: #262626;
                  display: flex;
                  align-items: center;
                  background: #f0f0f0;

                  .small-icon {
                    width: 18px;
                    height: 18px;
                    margin-right: 2px;
                  }
                }
              }
            }
          }

          &:first-child {
            .left {
              .line.up {
                visibility: hidden;
              }
            }
          }
          &:last-child {
            .left {
              .line.down {
                visibility: hidden;
              }
            }
          }
        }
      }
    }
    .form-content {
      flex: 1;
      overflow: auto;

      .task-base-info {
        margin-left: -16px;
        margin-right: -16px;
        margin-bottom: 16px;

        display: flex;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        .base-info-item {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 12px 16px;
          box-sizing: border-box;

          &:not(:last-child) {
            border-right: 1px solid #f0f0f0;
          }

          .tip {
            font-size: 13px;
            color: #999;
          }
          .value {
            font-size: 14px;
            color: #262626;
            margin-left: 6px;
          }
        }
      }

      .block {
        .title {
          padding: 12px 12px 12px 8px;
          font-size: 15px;
          font-weight: bold;
          color: #262626;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 4px;
            height: 20px;
            background: #1890ff;
            transform: translateY(-50%);
            border-radius: 2px;
          }
        }

        .remark {
          display: flex;
          justify-content: center;
          padding: 8px 0;
          box-sizing: border-box;
          background: #ffece1;
          font-size: 14px;
          color: #ffa37d;
        }

        .flex-layout {
          display: flex;
          flex-wrap: wrap;

          .flex-item {
            width: calc(25% - 8px);
            display: flex;
            flex-direction: column;
            margin-right: 8px;
            margin-bottom: 16px;

            &.long {
              width: 100%;
            }

            .tip {
              font-size: 14px;
              color: #262626;

              .weight {
                color: #f00;
                position: relative;
                top: 4px;
                margin-left: 2px;
              }
            }

            .value {
              margin-top: 4px;
              ::v-deep {
                .el-input {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .condition-layout {
        display: flex;
        align-items: center;
        .right {
          flex: 1;
          display: flex;
          justify-content: flex-end;
        }
      }

      .table-lalyout {
        margin-top: 8px;

        .table-op-col {
          .el-link {
            & + .el-link {
              margin-left: 8px;
            }
          }
        }
      }
    }

    .split-block {
      background: #f0f0f0;

      &.v {
        width: 6px;
      }
      &.h {
        width: auto;
        height: 6px;
      }
    }
  }

  .form-content {
    padding: 0 16px;
    box-sizing: border-box;
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 0;
      box-sizing: border-box;

      &.mutlti {
        align-items: flex-start;
      }

      &.contine {
        padding-top: 0;
      }

      &.big {
        .tip {
          height: 32px;
          line-height: 32px;
        }
      }

      .tip {
        width: 5em;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        text-align: right;
        color: #262626;
      }

      .value {
        margin-left: 16px;

        &.input {
          width: 204px;

          ::v-deep .el-date-editor.el-input,
          ::v-deep .el-date-editor.el-input__inner {
            width: 100%;
          }

          ::v-deep .el-select {
            width: 100%;
          }
        }

        &.fill {
          flex: 1;
          width: 0;
        }

        .small-tip {
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #8c8c8c;

          &.warning {
            color: #e6a23c;
          }
        }

        &.mutlti {
          flex: 1;
          width: 0;
          display: flex;

          &.v {
            flex-direction: column;

            .in-row {
              &:not(:first-child) {
                margin-top: 8px;
              }
            }
          }
        }

        .flag-block {
          width: 100%;
          background: #fafafa;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 8px 16px 16px 16px;
          box-sizing: border-box;
          flex-wrap: wrap;

          .flag-title {
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            margin-right: 8px;
          }

          & > * {
            margin-top: 8px;
          }
        }
      }

      .in-row {
        .value {
          margin-left: 0;
        }
      }
    }

    .opinion {
      color: #f00;
    }

    .goods-info {
      display: inline-flex;
      align-items: center;
      margin-right: 24px;

      .txt-info {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        .name {
          font-size: 16px;
          font-weight: 600;
          color: var(--color-26);
        }
        .factory {
          color: var(--color-59);
          font-size: 14px;
          margin-top: 3px;
        }
      }
    }
  }

  ::v-deep .el-upload {
    width: auto;
  }
  ::v-deep .el-dialog__body {
    max-height: 75vh;
    height: 75vh;
    overflow-y: auto;
    // min-height: 240px;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  ::v-deep {
    .el-dialog__wrapper {
      display: flex;
      align-items: center;

      .el-dialog {
        margin-top: 0 !important;
        margin: 0 auto 0;
      }
    }
    .el-table th.el-table__cell {
      background-color: #fafafa;
    }
  }
}
</style>
