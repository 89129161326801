<template>
  <div>
    <el-dialog title="请确定要发布的商品"
               :visible.sync="dialogVisible"
               width="918px"
               :before-close="handleClose">
      <div class="content">
        <div class="txt-content">
          <div class="row">
            <div class="value">
              请确认商品信息与实际一致，以下信息在发布后不可修改
            </div>
          </div>

          <div class="public-tip">
            <el-image :src="smallIcon"
                      class="small-icon" />
            <span>该商品无需提交平台审核，可直接发布上架</span>
          </div>

          <div class="big-title">
            {{ detail ? detail.compGoodsBaseVo.name : "" }}
          </div>
          <div>
            <div class="row"
                 v-for="(item, index) in productInfos"
                 :key="index">
              <div class="small-tip commo-text-ideograph">{{ item.tip }}</div>
              ：
              <div class="value"
                   v-if="item.input == 3">
                <div v-for="inner,index in item.value"
                     :key="index"
                     style="margin-bottom: 5px;">
                  <a :href="inner">{{ inner }}</a>
                </div>
              </div>
              <div class="value"
                   v-else>
                {{ item.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="pic-content">
          <div class="img-frame">
            <el-image class="big-img"
                      :src="
                selectedImageIndex >= 0
                  ? detail.compGoodsBaseVo.imageList[selectedImageIndex]
                  : detail
                  ? detail.compGoodsBaseVo.image
                  : ''
              " />
          </div>
          <div class="multi small pic">
            <div :class="{
                'muti-frame': true,
                selected: selectedImageIndex == index,
              }"
                 v-for="(item, index) in detail
                ? detail.compGoodsBaseVo.imageList
                : []"
                 :key="index"
                 @click="selectedImageIndex = index">
              <el-image class="multi-img"
                        :src="item" />
            </div>
          </div>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="text"
                   icon="el-icon-warning-outline"
                   @click="handleDetailError">
          商品纠错
        </el-button>
        <div>
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary"
                     @click="handleConfirm()"
                     :disabled="!detail">确认，下一步</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { commentSave } from "@/api/businessManage";

const IConst_Small = require("@/assets/public/icon-small.png");
import { getCompGoodsBaseDetail } from "@/api/goodsManage/baseDepot.js";
import _ from "lodash";

export default {
  components: {},
  props: {
    requestId: {
      type: String,
      default: () => "",
    },

    limit: {
      type: Number,
      default: () => 10,
    },
  },
  computed: {
    headers: function () {
      return {
        Authorization: "Bearer " + getToken(),
      };
    },
  },
  data () {
    return {
      selectedImageIndex: -1,
      smallIcon: IConst_Small,
      dialogVisible: false,

      queryObj: null,

      detail: null,

      productInfos: [],
    };
  },
  methods: {
    clear () {
      this.selectedImageIndex = -1;
      this.detail = null;
      this.queryObj = null;
      this.productInfos = [];
    },
    handleDetailError () {
      //地址待确定
      // this.$router.push({
      //   path: "/productManage/goodsManage-correct-details",
      //   query: {
      //     id: this.id,
      //     status: this.activeName,
      //     type: this.type,
      //   },
      // });
      this.dialogVisible = false;
      this.$router.push(
        {
          path: '/productManage/goodsManage-correct-details',
          query: {
            id: this.queryObj.goodsId,
            status: 'add',
            type: 'add',
            sourcePage: '/productManage/salesControl-commodity-list'
          }
        }
      )
    },
    handleClose () {
      this.dialogVisible = false;
    },
    init () {
      //
    },
    handleConfirm () {
      this.dialogVisible = false;

      this.$store.dispatch("publicGoods/setDetail", this.detail);

      this.$emit("confirm");
    },
    submitUpload () {
      const { comment } = this.formData;

      let imageUrl = this.imageList.join(",");
      let params = {
        comment,
        image: imageUrl,
        orderCode: this.orderCode,
        source: 2, //来源(1-大商城，2-卖家端)
      };
      // console.log(params)
      if (!comment) {
        this.$message.warning("请填写评论内容！");
        return;
      }

      commentSave(params)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("提交成功");
            this.dialogVisible = false;
          } else {
            this.$message.error(res.msg);
            this.handleClose();
          }
        })
        .catch((err) => {
          this.handleClose();
        });
    },
    doLoadDetail () {
      if (!this.queryObj || !this.queryObj.goodsId) {
        return;
      }
      const params = {
        id: this.queryObj.goodsId,
        isMerchant: true, //false 查所有拓展属性 true查询必填
      };
      getCompGoodsBaseDetail(params).then((res) => {
        if (res.code === 0) {
          this.detail = res.data;
          let productInfos = [];
          const compGoodsBaseVo = this.detail.compGoodsBaseVo;
          const extendParams = this.detail.extendParams;
          if (
            compGoodsBaseVo.imageList &&
            !(compGoodsBaseVo.imageList instanceof Array)
          ) {
            compGoodsBaseVo.imageList = compGoodsBaseVo.imageList.split(",");
          }

          /**
           *  { tip: "规格", value: "200ml*1支/盒" },
        { tip: "生产厂家", value: "200ml*1支/盒" },
        { tip: "批准文号", value: "200ml*1支/盒" },
        { tip: "品牌名", value: "200ml*1支/盒" },
        { tip: "成分", value: "200ml*1支/盒" },
        { tip: "剂型", value: "200ml*1支/盒" },
        { tip: "形状", value: "200ml*1支/盒" },
        { tip: "功能主治", value: "200ml*1支/盒" },
        { tip: "用法用量", value: "200ml*1支/盒" },
        { tip: "不良反应", value: "200ml*1支/盒" },
           */
          if (compGoodsBaseVo.specification) {
            productInfos.push({
              tip: "规格",
              value: compGoodsBaseVo.specification,
            });
          }
          if (compGoodsBaseVo.factory) {
            productInfos.push({
              tip: "生产厂家",
              value: compGoodsBaseVo.factory,
            });
          }
          if (compGoodsBaseVo.approvalNumber) {
            productInfos.push({
              tip: "批准文号",
              value: compGoodsBaseVo.approvalNumber,
            });
          }
          // if (compGoodsBaseVo.approvalNumber) {
          //   productInfos.push({
          //     tip: "品牌名",
          //     value: compGoodsBaseVo.approvalNumber,
          //   });
          // }
          if (extendParams instanceof Array) {
            extendParams.forEach((item) => {
              if (item.name && item.value) {
                if (item.input == 3) {
                  productInfos.push({
                    tip: item.name,
                    value: item.value.split(','),
                    input: item.input,
                  });
                } else {
                  productInfos.push({
                    tip: item.name,
                    value: item.value,
                    input: item.input,
                  });
                }
              }
            });
          }

          this.productInfos = productInfos;
        }
      });
    },

    open (simpleItem) {
      this.clear();
      this.dialogVisible = true;
      this.queryObj = _.cloneDeep(simpleItem);

      this.doLoadDetail();
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: row;

  .txt-content {
    flex: 1;
    flex-shrink: 0;
    overflow-y: auto;
    max-height: 500px;
    padding-right: 6px;
    box-sizing: border-box;

    .public-tip {
      margin-top: 8px;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #32bf78;
      line-height: 22px;

      .small-icon {
        width: 52px;
        height: 24px;
        margin-right: 8px;
      }
    }

    .row {
      display: flex;
      padding: 4px 0;
      box-sizing: border-box;

      .small-tip {
        width: 4em;
        font-size: 14px;
        font-weight: 400;
        color: #595959;
        flex-shrink: 0;
      }

      .value {
        font-size: 14px;
        font-weight: 400;
        color: #262626;
      }
    }

    .big-title {
      font-size: 20px;
      font-weight: 500;
      text-align: left;
      color: #262626;
      line-height: 32px;
      margin-bottom: 8px;
      margin-top: 24px;
    }
  }
  .pic-content {
    flex-shrink: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    .img-frame {
      width: 424px;
      height: 424px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .big-img {
      width: 310px;
      height: 370px;
    }

    .multi {
      margin-top: 12px;
      display: flex;

      .muti-frame {
        width: calc(20% - 8px);
        aspect-ratio: 1;
        position: relative;
        margin-left: 8px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        cursor: pointer;

        &.selected {
          border: 1px solid #ff4d50;
        }

        .multi-img {
          position: absolute;
          left: 10%;
          top: 5%;
          width: 80%;
          height: 90%;
        }
      }
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: space-between;

  ::v-deep .el-button--text {
    &:active {
      color: #f99b0d;
    }
    color: #f99b0d;
  }
}
</style>
