<template>
  <div class="lookArticle">
    <div class="top-box">
      <div class="top-tabs">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            v-for="(tItem, tIdx) in tabs"
            :label="tItem.classifyName"
            :name="tItem.id"
            :key="tItem.id"
          >
            <el-radio-group
              v-if="activeName == tItem.id && tItem.children && tItem.children.length"
              v-model="tabPosition"
              @input="secondClick"
              style="margin-top: 16px"
            >
              <el-radio-button :label="sItem.id" v-for="(sItem, sIdx) in tItem.children" :key="sIdx"
                >{{ sItem.classifyName }}
              </el-radio-button>
            </el-radio-group>
          </el-tab-pane>
        </el-tabs>
        <div class="third-list flex" v-if="thirdList && thirdList.length">
          <span
            v-for="(item, idx) in thirdList"
            :key="item.id"
            :class="thirdId == item.id ? 'third-active' : 'third'"
            @click="handleThird(item.id, idx)"
          >
            {{ item.classifyName }}
          </span>
        </div>
      </div>
      <div class="header">
        <span style="display: flex">
          <el-input
            v-model="input"
            clearable
            size="small"
            placeholder="请输入内容"
          ></el-input>
          <el-button
            type="primary"
            size="small"
            style="margin-left: 8px"
            @click="search(1)"
            >搜索</el-button
          >
        </span>
      </div>
    </div>
    <div class="line"></div>
    <div style="width: 100% - 12px; margin-left: 12px; height: 100%" class="firstBox">
      <div class="body">
        <div class="center">
          <MyTable
            :table-data="tableData"
            :index="index"
            :selection="selection"
            :page="page"
            :operation="operation"
            :table-option.sync="tableOption"
            @cell-click="cellClick"
            @page-change="search"
          >
            <template slot="articleTitle" slot-scope="scope">
              <div style="display: flex; align-items: center">
                <span>{{ scope.row.articleTitle }}</span>
                <img
                  v-if="scope.row.pinnedStatus == '1'"
                  src="@/assets/zhiding.png"
                  style="margin-left: 6px"
                  width="12px"
                />
              </div>
            </template>
          </MyTable>
        </div>
      </div>
    </div>
     

    <el-dialog
      :title="`${firstName ? firstName : '--'} > ${secondName ? secondName : '--'} > ${
        threeName ? threeName : '--'
      }`"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <div style="font-size: 16px; text-align: center; font-weight: bold">
        {{ articleTitle }}
      </div>
      <div v-html="details" class="endText content-html"></div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getInformationClassifyTree,
  queryInformationArticleByClassificationIdList,
  queryInformationArticleById,
} from "@/api/message/InformationClassification";
import MyTable from "@/components/myTable";
import wangEnduit from "@/components/wangEnduit";

export default {
  props: {},
  data() {
    return {
      firstName: "",
      secondName: "",
      threeName: "",

      articleClassificationId: "",
      input: "",
      details: "",

      dialogVisible: false,
      operation: false,

      selection: false,
      index: true,
      tableData: [],
      tableOption: [
        { label: "文章标题", prop: "articleTitle", slot: true }, // 这里表示自定义列
        { label: "发布时间", prop: "releaseTime" },
      ],
      page: {
        total: 0,
        current: 1,
        size: 20,
      },
      data: [],
      defaultProps: {
        children: "children",
        label: "classifyName",
        id: "id",
      },
      activeName: "",
      firstIndex: 0,
      secondIndex: 0,
      tabs: [],
      tabPosition: "",
      thirdId: 0,
      thirdList: [],
      articleTitle: "",
    };
  },
  components: { MyTable, wangEnduit },
  created() {
   
  },
  mounted() {
    this.init(); 
    if(this.$store.state.tabStatus.lookArticleTab){  
      this.getInformationClassifyTree(); 
      let obj = JSON.parse(this.$store.state.tabStatus.lookArticleTab);
      this.activeName = obj.activeName;
      this.articleClassificationId = obj.activeName;
      
    }else{
      this.getInformationClassifyTree(); 
    }
  },
  watch: {},
  methods: {
    init() {
      let tabsActiveName = this.$route.query.tabsActiveName;
      let name = this.$route.query.name;
      if (tabsActiveName && tabsActiveName != "0") {
        this.articleClassificationId = tabsActiveName;
      }
      if (name) {
        this.firstName = name;
      }
    },
    // 根据分类id查询资讯文章列表
    queryInformationArticleByClassificationIdList() {
      let params = {
        current: this.page.current,
        size: this.page.size,
        articleClassificationId: this.articleClassificationId
          ? this.articleClassificationId
          : this.tabs[0].id,
        clientTag: "商户端",
        articleTitle: this.input,
      };
      queryInformationArticleByClassificationIdList(params).then((res) => {
        if (res && res.records) {
          this.tableData = res.records;
          this.page.total = res.total;
        } else {
          this.tableData = [];
          this.page.total = 0;
        }
      });
    },
    // 查询树形结构
    getInformationClassifyTree() {
      // if (current) { // 搜索条件改变从第一页开始查询
      //   this.page.current = current
      // }
      // const params = {
      //   current: this.page.current,
      //   size: this.page.size
      // }
      getInformationClassifyTree().then((res) => {
        this.tabs = [];
        if (res) {
          this.data = res;
          // this.page.total = res.data.total
          this.tabs = res;
          this.activeName = this.articleClassificationId
            ? this.articleClassificationId
            : res[0].id;
          const { tabs } = this;

          if (this.articleClassificationId || this.tabPosition) {
            this.firstIndex = this.tabs.findIndex((res) => res.id === this.activeName);
            let item = {
              index: this.tabPosition ? 0 : this.firstIndex,
            };
            this.handleClick(item);
          }
          if (tabs && tabs.length && tabs[0].children && tabs[0].children.length) {
            this.tabPosition = tabs[0].children[0].id;
            if (tabs[0].children[0].children && tabs[0].children[0].children.length) {
              this.thirdList = tabs[0].children[0].children;
            }
          }
        } else {
          this.data = [];
          // this.page.total = 0
        }
      });
    },
    // handleClose
    handleClose() {
      this.dialogVisible = false;
    },
    // 点击行
    async cellClick(scope) {
      this.details = "";
      this.articleTitle = "";
      await this.queryInformationArticleById(scope.row.id);
      this.dialogVisible = true;
    },
    // 根据id查询资讯文章
    async queryInformationArticleById(id) {
      console.log("res.informationContent==");
      queryInformationArticleById(id).then((res) => {
        if (res) {
          this.articleTitle = res.articleTitle;
          this.details = res.informationContent;
          this.$nextTick(() => {
            this.firstName = res.articlePrimaryClassificationName;
            this.secondName = res.articleSecondaryClassificationName;
            this.threeName = res.articleThreeClassificationName;
          });
        } else {
          this.details = "";
          this.articleTitle = "";
        }
      });
    },
    // 点击节点
    handleNodeClick(data) {
      console.log(data);
      let pNode = this.$refs.tree.getNode(data).parent.data;
      if (data.parentId == 0) {
        this.firstName = data.classifyName;
        this.secondName = "";
        this.threeName = "";
      } else if (pNode.parentId == 0) {
        this.firstName = pNode.classifyName;
        this.secondName = data.classifyName;
        this.threeName = "";
      } else {
        let arr = data.nodeInfo.split(",");
        this.data.forEach((item) => {
          if (item.id == arr[2]) {
            this.firstName = item.classifyName;
            this.secondName = pNode.classifyName;
            this.threeName = data.classifyName;
          }
        });
      }
      this.articleClassificationId = data.id;
      this.input = "";
      this.search(1);
    },
    // 搜索
    search(current) {
      if (current) {
        this.page.current = current;
      }
      this.queryInformationArticleByClassificationIdList();
    },
    handleClick(val) { 
      this.clickTab(val.index);
      this.$store.dispatch('tabStatus/setLookArticleTab',JSON.stringify({
        activeName:this.activeName,
        index:val.index
      }))  
      this.search();
    },
    
    clickTab(index) {
      this.thirdId = ""; 
      const { children } = this.tabs[index];
      console.log("children==", children);
      this.firstIndex = index; 
      this.articleClassificationId = this.activeName;
      if (children && children.length) {
        this.tabPosition = children[0].id;
        this.articleClassificationId = children[0].id;
        if (children[0].children && children[0].children.length) {
          this.thirdList = children[0].children;
        } else {
          this.thirdList = [];
        }
      } else {
        this.thirdList = [];
      } 
    }, 

    secondClick(id) {
      this.thirdId = "";
      this.articleClassificationId = this.tabPosition;
      this.secondIndex = this.tabs[this.firstIndex].children.findIndex(
        (res) => res.id === id
      );
      this.thirdList = this.tabs[this.firstIndex].children[this.secondIndex].children;
      this.search();
    },
    handleThird(id) {
      this.articleClassificationId = id;
      this.thirdId = id;
      this.search();
    },
  },
};
</script>
<style lang="scss" scoped>
.lookArticle {
  background: #fff;
  height: 100%;
  padding: 0 16px;
  position: relative;
  .top-box {
    display: flex;
    justify-content: space-between;
    .top-tabs {
      .third-list {
        margin-top: 16px;
        .third-active,
        .third {
          background: rgba(207, 20, 34, 0.06);
          border-radius: 14px;
          line-height: 20px;
          color: #cf1422;
          font-size: 12px;
          padding: 4px 16px;
          margin-right: 10px;
          cursor: pointer;
        }
        .third {
          color: #595959;
          background: #f5f6f7;
        }
      }
    }
    .header {
      margin-top: 12px;
      height: 45px;
    }
  }
  .line {
    height: 1px;
    width: 100%;
    background: #ededed;
    position: absolute;
    top: 56px;
    left: 0;
  }
  ::v-deep .el-radio-button__inner {
    padding: 5px 16px;
    line-height: 22px;
  }

  .firstBox {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      margin-top: 20px;
    }
    .body {
      height: calc(100% - 140px);
      background: #fff;
      padding: 12px 0;
      .center {
        height: calc(100% - 65px);
      }
    }
  }
  ::v-deep .el-dialog__body {
    height: 600px;
    overflow: auto;
  }
  .endText {
    font-size: 14px;
  }
  .content-html {
    /* table 样式 */
    :deep table {
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
    }
    :deep table td,
    table th {
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      padding: 3px 5px;
    }
    :deep table th {
      border-bottom: 2px solid #ccc;
      text-align: center;
    }
  }
}
</style>
