var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "goodsSalesStatMainOne", staticClass: "goodsSalesStatMain" },
    [
      _c("div", { staticClass: "flexLineView" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", {
            staticStyle: { height: "100%", width: "100%" },
            attrs: { id: "mainChart" },
          }),
          _vm.monthOrYear != "custom"
            ? _c("div", { staticClass: "dropDownBtn" }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-top": "6px",
                      "margin-left": "12px",
                      color: "#262626",
                      "font-size": "16px",
                      "font-weight": "600",
                    },
                  },
                  [_vm._v("全国销售统计")]
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      display: "flex",
                      "margin-top": "6px",
                      "margin-left": "12px",
                      color: "#262626",
                      "font-size": "16px",
                      "font-weight": "600",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c("span", { staticClass: "rightFont" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.monthOrYear == "weeks"
                                  ? "本周销售(元)"
                                  : _vm.monthOrYear == "month"
                                  ? "本月销售(元)"
                                  : _vm.monthOrYear == "year"
                                  ? "本年销售(元)"
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "rightNumber",
                            staticStyle: { margin: "auto" },
                          },
                          [_vm._v(_vm._s(_vm.amount))]
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                          display: "flex",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c("span", { staticClass: "rightFont" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.monthOrYear == "weeks"
                                  ? "环比上周"
                                  : _vm.monthOrYear == "month"
                                  ? "环比上月"
                                  : _vm.monthOrYear == "year"
                                  ? "环比上年"
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                        Number(_vm.chain_flag) > 0
                          ? _c(
                              "span",
                              {
                                staticClass: "rightNumber",
                                staticStyle: {
                                  margin: "auto",
                                  color: "#32ae7c",
                                },
                              },
                              [_vm._v("↑")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "rightNumber",
                                staticStyle: { margin: "auto", color: "red" },
                              },
                              [_vm._v("↓")]
                            ),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "numberView" }, [
            _c("img", {
              staticClass: "numberImg",
              attrs: { src: _vm.number1, alt: "" },
            }),
          ]),
        ]),
        _c("div", { staticClass: "lineViewHorizon" }),
        _c("div", { staticClass: "row" }, [
          _c("div", {
            staticStyle: { height: "100%", width: "100%" },
            attrs: { id: "mainChartSecond" },
          }),
          _c(
            "div",
            {
              staticClass: "dropDownBtn",
              staticStyle: { "justify-content": "flex-start" },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-top": "6px",
                    "margin-left": "12px",
                    color: "#262626",
                    "font-size": "16px",
                    "font-weight": "600",
                  },
                },
                [_vm._v("区域销售统计")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-top": "6px",
                    "margin-left": "12px",
                    color: "#262626",
                    "font-size": "16px",
                    "font-weight": "600",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                      },
                    },
                    [
                      _c("span", { staticClass: "rightFont" }, [
                        _vm._v(" " + _vm._s(_vm.province) + " "),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "numberView" }, [
            _c("img", {
              staticClass: "numberImg",
              attrs: { src: _vm.number3, alt: "" },
            }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "lineView" }),
      _c("div", { staticClass: "flexLineView" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm._m(0),
            _c(
              "el-table",
              {
                ref: "hilightTable",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.categoryTableData,
                  "highlight-current-row": "",
                  height: _vm.tableHeight - 38,
                },
                on: { "row-click": _vm.rowClick },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "rn", label: "排名", width: "50" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "province_name", label: "区域" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "amount", label: "销售额（元）" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "pct", label: "占比", width: "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "percentView" }, [
                            _c("div", {
                              staticClass: "percentBg",
                              style: {
                                width: _vm.getPercent(scope.row.pct) + "%",
                              },
                            }),
                            _c("div", { staticClass: "percentText" }, [
                              _vm._v(
                                _vm._s(_vm.getPercent(scope.row.pct)) + "%"
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("div", { staticClass: "numberView" }, [
              _c("img", {
                staticClass: "numberImg",
                attrs: { src: _vm.number2, alt: "" },
              }),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "lineViewHorizon" }),
        _c(
          "div",
          { staticClass: "secondTableView" },
          [
            _c("div", { staticClass: "dropDownBtn" }, [
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-top": "6px",
                    "margin-left": "12px",
                    color: "#262626",
                    "font-size": "16px",
                    "font-weight": "600",
                  },
                },
                [_vm._v("商品")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-top": "6px",
                    "margin-left": "12px",
                    color: "#262626",
                    "font-size": "16px",
                    "font-weight": "600",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                      },
                    },
                    [
                      _c("span", { staticClass: "rightFont" }, [
                        _vm._v(" 商品总数 "),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "rightNumber",
                          staticStyle: { margin: "auto" },
                        },
                        [_vm._v(_vm._s(_vm.all_goods_total_cnt))]
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-left": "10px",
                        display: "flex",
                        "flex-direction": "column",
                      },
                    },
                    [
                      _c("span", { staticClass: "rightFont" }, [
                        _vm._v(" 控销商品数 "),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "rightNumber",
                          staticStyle: { margin: "auto" },
                        },
                        [_vm._v(_vm._s(_vm.all_goods_ctr_sale_cnt))]
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-left": "10px",
                        display: "flex",
                        "flex-direction": "column",
                      },
                    },
                    [
                      _c("span", { staticClass: "rightFont" }, [
                        _vm._v(" 普药商品数 "),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "rightNumber",
                          staticStyle: { margin: "auto" },
                        },
                        [_vm._v(_vm._s(_vm.all_goods_putaway_cnt))]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _c(
              "el-table",
              {
                ref: "hilightTableSecond",
                staticStyle: { width: "100%", "margin-top": "56px" },
                attrs: {
                  data: _vm.categoryTableDataSecond,
                  "highlight-current-row": "",
                  height: _vm.tableHeight - 63,
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "rn", label: "排名", width: "50" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "goods_one_class_name", label: "分类" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "goods_total_cnt", label: "商品总数" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "goods_putaway_cnt", label: "普药商品数" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "goods_ctr_sale_cnt",
                    label: "控销商品数",
                    width: "100",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "amount", label: "销售额(元)" },
                }),
                _c("el-table-column", {
                  attrs: { label: "销售占比", width: "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "percentView" }, [
                            _c("div", {
                              staticClass: "percentBg",
                              style: {
                                width: _vm.getPercent(scope.row.pct) + "%",
                              },
                            }),
                            _c("div", { staticClass: "percentText" }, [
                              _vm._v(
                                _vm._s(_vm.getPercent(scope.row.pct)) + "%"
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("div", { staticClass: "numberView" }, [
              _c("img", {
                staticClass: "numberImg",
                attrs: { src: _vm.number3, alt: "" },
              }),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "classNameView", staticStyle: { "margin-top": "10px" } },
      [
        _c(
          "span",
          {
            staticStyle: {
              "margin-left": "12px",
              "font-weight": "600",
              color: "#262626",
              "font-size": "16px",
            },
          },
          [_vm._v("区域销售排名")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }