var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order" },
    [
      _vm._m(0),
      _c("el-divider"),
      _vm._l(_vm.tableData, function (propsrow, row) {
        return _c(
          "div",
          {
            key: row,
            staticStyle: {
              display: "flex",
              flex: "1",
              "border-radius": "4px",
              margin: "10px 0px",
              "flex-direction": "column",
              overflow: "hidden",
              "border-left": "1px solid #ebeef5",
              "border-right": "1px solid #ebeef5",
              "border-bottom": "1px solid #ebeef5",
            },
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  "background-color": "#edf6f6",
                  width: "100%",
                  display: "flex",
                  "align-items": "center",
                  padding: "10px 12px",
                  position: "relative",
                },
              },
              [
                _c("div", { staticClass: "item" }),
                _c("span", { staticClass: "descText" }, [
                  _vm._v(" 创建时间："),
                ]),
                _c("span", { staticClass: "descText" }, [
                  _vm._v(_vm._s(propsrow.orderTime)),
                ]),
                _c("span", { staticClass: "marrinLeft" }, [
                  _vm._v(
                    _vm._s(
                      propsrow.status == "1" ? "应付总额：" : "实付总额："
                    ) + " "
                  ),
                ]),
                _c("span", { staticClass: "descText" }, [
                  _vm._v("￥" + _vm._s(propsrow.copeAmount) + " "),
                ]),
                _c("span", { staticClass: "marrinLeft" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.routerType == "procument" ? "商户" : "用户") +
                      "："
                  ),
                ]),
                _c("span", { staticClass: "descText" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.routerType == "procument"
                          ? propsrow.payeeShopName
                          : propsrow.payShopName
                      ) +
                      " "
                  ),
                ]),
                _c("span", { staticClass: "marrinLeft" }, [_vm._v("订单号：")]),
                _c("span", { staticClass: "descText" }, [
                  _vm._v(_vm._s(propsrow.orderCode)),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "lastStatus",
                    staticStyle: { "margin-left": "auto" },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("el-image", {
                          staticClass: "icon_status",
                          staticStyle: { height: "20px", width: "20px" },
                          attrs: { src: _vm.getStatus(1, propsrow.status) },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "descText",
                            class: _vm.getStatus(3, propsrow.status),
                          },
                          [_vm._v(_vm._s(_vm.getStatus(2, propsrow.status)))]
                        ),
                      ],
                      1
                    ),
                    _c("TimeoutTag", { attrs: { order: propsrow } }),
                  ],
                  1
                ),
                propsrow.confirmReceiptDeadlineTimestamp && propsrow.status != 9
                  ? _c(
                      "span",
                      { staticStyle: { color: "orange" } },
                      [
                        _c(
                          "el-statistic",
                          {
                            ref: "statistic",
                            refInFor: true,
                            attrs: {
                              format: "DD天HH:mm:ss",
                              value:
                                Date.now() +
                                parseInt(
                                  propsrow.confirmReceiptDeadlineTimestamp
                                ),
                              title: "",
                              "time-indices": "",
                              "value-style": {
                                color: "#f5222d",
                                fontSize: "14px",
                              },
                            },
                            on: {
                              finish: function ($event) {
                                return _vm.hilarity(propsrow, row)
                              },
                            },
                          },
                          [_vm._v(" 后订单完成 ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  flex: "1",
                  "align-items": "center",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "column",
                    },
                  },
                  _vm._l(propsrow.orderDetailVo, function (item, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            flex: "1",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "thClass columnFlex",
                              staticStyle: {
                                width: "350px",
                                "border-right": "1px solid #ebeef5",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "imageBox" },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "80px",
                                      height: "80px",
                                    },
                                    attrs: { src: item.image || _vm.avater },
                                  }),
                                  _c("div", [
                                    _c("p", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                    _c("p", { staticClass: "duanluo pihao" }, [
                                      _vm._v(_vm._s(item.approvalNumber)),
                                    ]),
                                    _c("p", { staticClass: "duanluo" }, [
                                      _vm._v(_vm._s(item.factory)),
                                    ]),
                                    _c("p", { staticClass: "duanluo" }, [
                                      _vm._v(_vm._s(item.specification)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                _vm._l(
                                  item.giftGoodsList,
                                  function (giftTtem, giftIndex) {
                                    return _c(
                                      "div",
                                      { key: giftIndex, staticClass: "inLine" },
                                      [
                                        _c("GiftTag"),
                                        _c(
                                          "span",
                                          { staticClass: "inLineName" },
                                          [_vm._v(_vm._s(giftTtem.name))]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "inLineQty" },
                                          [_vm._v("x" + _vm._s(giftTtem.qty))]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "thClass",
                              staticStyle: { width: "80px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    flex: "1",
                                    "justify-content": "center",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _vm.routerType == "procument"
                                    ? _c("p", { staticClass: "duanluo" }, [
                                        _vm._v(
                                          " ￥" + _vm._s(item.price) + " "
                                        ),
                                      ])
                                    : _c("p", { staticClass: "duanluo" }, [
                                        _vm._v(
                                          " ￥" +
                                            _vm._s(
                                              item.servicePrice || item.price
                                            ) +
                                            " "
                                        ),
                                      ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "thClass",
                              staticStyle: { width: "120px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    flex: "1",
                                    "justify-content": "center",
                                    "flex-direction": "column",
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "duanluo",
                                      class:
                                        item.shipmentsAmount != item.qty
                                          ? "titleRed"
                                          : "",
                                    },
                                    [
                                      _vm._v(
                                        " 发货量：" +
                                          _vm._s(item.shipmentsAmount || "0") +
                                          " "
                                      ),
                                    ]
                                  ),
                                  item.shipmentsAmount != item.qty
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "duanluo",
                                          class:
                                            item.shipmentsAmount != item.qty
                                              ? "titleRed"
                                              : "",
                                        },
                                        [
                                          _vm._v(
                                            " 需求量：" + _vm._s(item.qty) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "thClass",
                              staticStyle: { width: "120px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "submit",
                                  staticStyle: {
                                    display: "flex",
                                    flex: "1",
                                    "justify-content": "center",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _vm.routerType == "procument"
                                    ? _c(
                                        "el-link",
                                        {
                                          staticClass: "btn",
                                          attrs: {
                                            disabled:
                                              !(
                                                propsrow.status == "8" &&
                                                (item.afterSaleStatus ==
                                                  "SHOP_REJECT" ||
                                                  !item.afterSaleStatus)
                                              ) ||
                                              (_vm.deliverControl == "1" &&
                                                _vm.routerType != "procument"),
                                            underline: false,
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.applyService(
                                                item,
                                                "APPLY_FOR"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("申请售后")]
                                      )
                                    : _c(
                                        "el-link",
                                        {
                                          staticClass: "btn",
                                          staticStyle: {
                                            color: "#595959 !important",
                                          },
                                          attrs: {
                                            disabled: _vm.getDisableStatus(
                                              propsrow,
                                              item
                                            ),
                                            underline: false,
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.cancelSale(
                                                item,
                                                "CANCEL_SALE",
                                                propsrow
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("发起退货")]
                                      ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        "font-weight": "400",
                                        color: "#595959 !important",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.btnStr(
                                              propsrow.invoiceAfterSaleStatus
                                            )
                                              ? `发票售后` +
                                                  _vm.btnStr(
                                                    propsrow.invoiceAfterSaleStatus
                                                  )
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        "font-weight": "400",
                                        color: "#595959 !important",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.btnStr(
                                              propsrow.certificationStatus
                                            )
                                              ? `资质售后` +
                                                  _vm.btnStr(
                                                    propsrow.certificationStatus
                                                  )
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  }),
                  0
                ),
                _c("tr", { staticStyle: { display: "flex", flex: "1" } }, [
                  _c(
                    "th",
                    {
                      staticClass: "thClass",
                      staticStyle: {
                        "text-align": "left",
                        display: "flex",
                        flex: "1",
                        "padding-left": "12px",
                      },
                      style: {
                        height:
                          propsrow.orderDetailVo.length * _vm.lineHeight + "px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            flex: "1",
                            "flex-direction": "column",
                            "justify-content": "center",
                          },
                        },
                        [
                          !propsrow.erpShopCode && _vm.routerType == "sale"
                            ? _c(
                                "p",
                                {
                                  staticClass: "duanluo",
                                  staticStyle: {
                                    color: "#f5222d",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkQualification(propsrow)
                                    },
                                  },
                                },
                                [_vm._v(" 下载首营资质 ")]
                              )
                            : _vm._e(),
                          _vm.routerType == "sale" &&
                          _vm.deliverControl != "1" &&
                          propsrow.status != "12"
                            ? _c(
                                "p",
                                {
                                  staticClass: "duanluo",
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showErpDailog(propsrow)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " ERP编码：" +
                                      _vm._s(propsrow.erpShopCode) +
                                      " "
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-edit",
                                    staticStyle: { color: "#f5222d" },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm.routerType == "sale" &&
                          _vm.deliverControl == "1" &&
                          propsrow.status != "12"
                            ? _c("p", { staticClass: "duanluo" }, [
                                _vm._v(
                                  " ERP编码：" +
                                    _vm._s(propsrow.erpShopCode) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          propsrow.orderDetailVo.length &&
                          propsrow.orderDetailVo[0].certificationFlag
                            ? _c(
                                "p",
                                {
                                  staticClass: "duanluo",
                                  staticStyle: {
                                    color: "red !important",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkQualification(propsrow)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        propsrow.orderDetailVo[0]
                                          .certificationFlag
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          propsrow.orderDetailVo.length &&
                          propsrow.orderDetailVo[0].contactsName
                            ? _c("p", { staticClass: "duanluo" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      propsrow.orderDetailVo[0].contactsName
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          propsrow.orderDetailVo.length &&
                          propsrow.orderDetailVo[0].contactsPhone
                            ? _c("p", { staticClass: "duanluo" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      propsrow.orderDetailVo[0].contactsPhone
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          propsrow.orderDetailVo.length &&
                          propsrow.orderDetailVo[0].address
                            ? _c("p", { staticClass: "duanluo" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(propsrow.orderDetailVo[0].address) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          propsrow.invoiceTitleInfo
                            ? _c("OrderInvoiceComponnet", {
                                attrs: {
                                  invoiceInfo: propsrow.invoiceTitleInfo,
                                },
                                on: {
                                  didClickInvoice: function ($event) {
                                    return _vm.showQualificationInvoiceDialog(
                                      propsrow.invoiceTitleInfo
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticClass: "thClass",
                      staticStyle: {
                        "text-align": "left",
                        display: "flex",
                        flex: "1",
                      },
                      style: {
                        height:
                          propsrow.orderDetailVo.length * _vm.lineHeight + "px",
                      },
                    },
                    [
                      _c("div", { staticStyle: { "padding-left": "12px" } }, [
                        _c("p", { staticClass: "duanluo" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                propsrow.orderDetailVo.length
                                  ? propsrow.orderDetailVo[0].expressTime
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                        _c("p", { staticClass: "duanluo" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                propsrow.orderDetailVo.length
                                  ? propsrow.orderDetailVo[0].expressStatus
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                        _c("p", { staticClass: "duanluo txt-line-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                propsrow.orderDetailVo.length
                                  ? propsrow.orderDetailVo[0].expressDesc
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "span",
                          { staticClass: "detail" },
                          [
                            propsrow.orderDetailVo.length &&
                            propsrow.orderDetailVo[0].expressTime
                              ? _c(
                                  "el-link",
                                  {
                                    staticClass: "btn",
                                    attrs: {
                                      underline: false,
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goWuliuDetail(
                                          propsrow.id,
                                          propsrow.orderId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("物流详情")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticClass: "thClass",
                      staticStyle: { width: "120px" },
                      style: {
                        height:
                          propsrow.orderDetailVo.length * _vm.lineHeight + "px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "submit",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                            "align-items": "center",
                            "flex-direction": "column",
                            flex: "1",
                          },
                        },
                        [
                          _c(
                            "el-link",
                            {
                              staticClass: "btn",
                              attrs: { underline: false, type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.goDetail(
                                    propsrow.id,
                                    propsrow.orderId,
                                    propsrow.selfCheckStatus
                                  )
                                },
                              },
                            },
                            [_vm._v("订单详情")]
                          ),
                          propsrow.status == "1" &&
                          _vm.routerType == "procument"
                            ? _c(
                                "el-link",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    disabled: _vm.loading,
                                    underline: false,
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.payBtn(propsrow)
                                    },
                                  },
                                },
                                [_vm._v("付款")]
                              )
                            : _vm._e(),
                          _vm.routerType != "procument"
                            ? _c(
                                "el-link",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    disabled:
                                      _vm.loading ||
                                      (_vm.deliverControl == "1" &&
                                        _vm.routerType != "procument"),
                                    underline: false,
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkQualification(propsrow)
                                    },
                                  },
                                },
                                [_vm._v("查看资质")]
                              )
                            : _vm._e(),
                          _vm.showViewer
                            ? _c("el-image-viewer", {
                                attrs: {
                                  "on-close": _vm.closeViewer,
                                  "url-list": [_vm.url],
                                },
                              })
                            : _vm._e(),
                          propsrow.status == "9" &&
                          propsrow.serialStatus &&
                          _vm.routerType == "procument" &&
                          propsrow.serialStatus != "ALREADY"
                            ? _c(
                                "el-link",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    disabled:
                                      (propsrow.serialStatus != "OPEN" &&
                                        propsrow.serialStatus != "ERROR") ||
                                      (_vm.deliverControl == "1" &&
                                        _vm.routerType != "procument"),
                                    underline: false,
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.watchBtn(propsrow)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      propsrow.serialStatus == "OPEN"
                                        ? "申请开票"
                                        : propsrow.serialStatus == "CHECK"
                                        ? "开票审核中"
                                        : propsrow.serialStatus == "ING"
                                        ? "开票中"
                                        : propsrow.serialStatus == "ERROR"
                                        ? "开票失败"
                                        : ""
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.routerType == "sale" &&
                          propsrow.serialStatus == "OPEN" &&
                          propsrow.status != "12" &&
                          propsrow.status != "1" &&
                          propsrow.status != "5"
                            ? _c(
                                "el-link",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    disabled:
                                      (propsrow.serialStatus != "OPEN" &&
                                        propsrow.serialStatus != "ERROR") ||
                                      (_vm.deliverControl == "1" &&
                                        _vm.routerType != "procument"),
                                    underline: false,
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.uploadInvoice(propsrow)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      propsrow.serialStatus == "OPEN"
                                        ? "上传发票"
                                        : ""
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          propsrow.serialStatus &&
                          propsrow.serialStatus == "ALREADY"
                            ? _c(
                                "el-link",
                                {
                                  staticClass: "btn",
                                  attrs: { underline: false, type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.look(propsrow)
                                    },
                                  },
                                },
                                [_vm._v("查看发票 ")]
                              )
                            : _vm._e(),
                          propsrow.contractId &&
                          (_vm.routerType == "procument" ||
                            _vm.routerType == "sale")
                            ? _c(
                                "el-link",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    disabled:
                                      _vm.deliverControl == "1" &&
                                      _vm.routerType != "procument",
                                    underline: false,
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.lookContract(propsrow)
                                    },
                                  },
                                },
                                [_vm._v("查看合同 ")]
                              )
                            : _vm._e(),
                          propsrow.beExistsVoucher
                            ? _c(
                                "el-link",
                                {
                                  staticClass: "btn",
                                  attrs: { underline: false, type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkCertificate(propsrow)
                                    },
                                  },
                                },
                                [_vm._v("查看凭证 ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: propsrow.orderCertification,
                    expression: "propsrow.orderCertification",
                  },
                ],
                staticClass: "duanluo qulificationText",
                on: {
                  click: function ($event) {
                    return _vm.showQualificationDialog(propsrow)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-info",
                  staticStyle: { color: "#f5222d" },
                }),
                _c("el-link", [_vm._v("资质需求：点击查看用户随货资质需求")]),
              ],
              1
            ),
            propsrow.orderMessage
              ? _c(
                  "el-tooltip",
                  {
                    attrs: { effect: "dark", placement: "top" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "max-width": "20vw",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(propsrow.orderMessage || "") +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "duanluo remarkText txt-line-1" },
                      [
                        _c("span", [
                          _vm._v(
                            " 药店留言：" +
                              _vm._s(propsrow.orderMessage || "") +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑ERP编码",
            visible: _vm.visibleErp,
            width: "30%",
            "before-close": _vm.handleCloseErp,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleErp = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dialogFormErp",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.dialogFormErp,
                "status-icon": "",
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "dialog-body" },
                [
                  _c("el-form-item", { attrs: { label: "客户名称:" } }, [
                    _vm._v(" " + _vm._s(_vm.dialogFormErp.payShopName)),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户ERP编码:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { size: "small", clearable: "" },
                        model: {
                          value: _vm.dialogFormErp.erpShopCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialogFormErp, "erpShopCode", $$v)
                          },
                          expression: "dialogFormErp.erpShopCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex-end" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "8px" },
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleCloseErp()
                        },
                      },
                    },
                    [_vm._v("返回")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "8px" },
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitErp(1)
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("UploadInvoice", {
        ref: "uploadInvoice",
        attrs: { limit: 1 },
        on: { getList: _vm.getList },
      }),
      _c("CommentOrder", { ref: "commentOrder" }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { display: "flex", "align-items": "center", flex: "1" } },
      [
        _c("div", { staticStyle: { display: "flex" } }, [
          _c("div", { staticStyle: { width: "350px" } }, [
            _c("span", { staticClass: "titleClass" }, [_vm._v("商品信息")]),
          ]),
          _c("div", { staticStyle: { width: "80px" } }, [
            _c("span", { staticClass: "titleClass" }, [_vm._v("单价（元）")]),
          ]),
          _c("div", { staticStyle: { width: "120px" } }, [
            _c("span", { staticClass: "titleClass" }, [_vm._v("数量")]),
          ]),
          _c("div", { staticStyle: { width: "120px" } }, [
            _c("span", { staticClass: "titleClass" }, [_vm._v("商品操作")]),
          ]),
        ]),
        _c("div", { staticStyle: { display: "flex", flex: "1" } }, [
          _c("div", { staticStyle: { display: "flex", flex: "1" } }, [
            _c("span", { staticClass: "titleClass" }, [_vm._v("收货信息")]),
          ]),
          _c("div", { staticStyle: { display: "flex", flex: "1" } }, [
            _c("span", { staticClass: "titleClass" }, [_vm._v("物流信息")]),
          ]),
          _c("div", { staticStyle: { width: "120px" } }, [
            _c("span", { staticClass: "titleClass" }, [_vm._v("操作")]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }