<template>
  <el-dialog
    width="518px"
    title="商户绑定"
    :visible.sync="innerVisible"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item prop="payeeNo" label="康贝商户">
        <el-input
          v-model.trim="form.payeeNo"
          auto-complete="off"
          placeholder="请输入商户号"
        >
          <i slot="prefix" class="el-icon-mobile-phone"></i>
        </el-input>
      </el-form-item>
      <el-form-item prop="verifyCode" label="验证码">
        <el-input
          class="verification-code"
          v-model.trim="form.verifyCode"
          auto-complete="off"
          placeholder="请输入验证码"
        >
          <i
            slot="prefix"
            class="el-icon-key"
            style="position: absolute; top: 12px"
          ></i>
          <template slot="append">
            <span
              :class="[{ display: msgKey }, { active: form.payeeNo }]"
              class="msg-text"
              @click="handleSend"
              >{{ msgText }}</span
            >
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="innerVisible = false">取 消</el-button>
      <el-button
        size="small"
        :loading="loading"
        type="primary"
        @click="
          innerVisible = true;
          sureBtn();
        "
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
// import { updateRateRule } from "@/api/externalSalesRule";
const MSGINIT = "发送验证码",
  MSGSCUCCESS = "${time}秒后重发",
  MSGTIME = 60;

import { currentDetails } from "@/api/myQualification/index";
import {
  getUserInfo,
  merchantBind,
  findPhoneByMerchant,
  getMerchantList,
  setDefaultMerchant,
  merchantEnable,
  merchantDisable,
} from "@/api/user.js";

import { bindKB, sendKBSms } from "@/api/invoice";
export default {
  components: {},
  props: {},
  computed: {
    userInfo() {
      //   console.log(this.$store.state.user.user_info);
      return this.$store.state.user.user_info;
    },
  },
  data() {
    return {
      loading: false,
      payeeNo: "",
      id: "",
      rules: {
        payeeNo: [
          { required: true, message: "请输入商户号", trigger: "change" },
          // { validator: this.checkpayeeNo, trigger: 'change' }
        ],
        verifyCode: [
          { required: true, message: "请输入验证码", trigger: "change" },
        ],
      },
      innerVisible: false,
      form: {
        payeeNo: "",
        verifyCode: "",
      },
      msgKey: false,
      msgText: MSGINIT,
      msgTime: MSGTIME,
      phone: "",
      merchantName: "",
      merchantList: [],
    };
  },
  created() {},
  methods: {
    submitBtn() {
      // console.log(999);
      this.innerVisible = true;
      this.$refs.form.resetFields();
    },
    sureBtn() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          bindKB({
            id: this.id,
            phone: this.phone,
            payeeNo: this.form.payeeNo,
            verifyCode: this.form.verifyCode,
          })
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("商户绑定成功");
                this.payeeNo = this.form.payeeNo;
                this.innerVisible = false;
                this.form = {
                  payeeNo: "",
                  verifyCode: "",
                };
                this.$emit("getList");
              } else {
                this.$message.error(res.msg);
              }
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      });
    },
    handleSend() {
      // 判断是否可以发送（时间限制）
      if (this.msgKey) return;
      // 发送验证码
      this.$refs.form.validateField("payeeNo", (valid) => {
        if (!valid) {
          sendKBSms({ payeeNo: this.form.payeeNo }).then((res) => {
            if (res) {
              this.$message.success("验证码发送成功");
              this.phone = res.data;
              // this.merchantName = res.merchantName;
              this.timeCacl();
            }
          });
        }
      });
    },
    timeCacl() {
      // 计时避免重复发送
      this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
      this.msgKey = true;
      const time = setInterval(() => {
        this.msgTime--;
        this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
        if (this.msgTime === 0) {
          this.msgTime = MSGTIME;
          this.msgText = MSGINIT;
          this.msgKey = false;
          clearInterval(time);
        }
      }, 1000);
    },
    init(row) {
      this.innerVisible = true;
      this.id = row.id;
      if (row.payeeNo) {
        this.form.payeeNo = row.payeeNo;
      }
    },
  },
};
</script>
<style lang="scss">
.btnBox {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
