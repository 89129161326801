var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "status-root" },
    [
      _vm.status === 1
        ? [
            _c(
              "div",
              { staticClass: "pic-wrap" },
              [_c("el-image", { attrs: { src: _vm.sucessIcon } })],
              1
            ),
            _c("span", { staticClass: "big-tip" }, [
              _vm._v("您已提交资质，请等待审核..."),
            ]),
            _c(
              "div",
              { staticClass: "bottom-btn-layout" },
              [
                _c("el-button", { on: { click: _vm.handleLook } }, [
                  _vm._v("查看资质"),
                ]),
                _c("el-button", { on: { click: _vm.handleExit } }, [
                  _vm._v("退出"),
                ]),
              ],
              1
            ),
            _vm.linkStep == 1
              ? _c(
                  "el-button",
                  {
                    staticStyle: { width: "252px", "margin-top": "16px" },
                    on: { click: _vm.handleVarify },
                  },
                  [_vm._v("电子合同认证")]
                )
              : _vm.linkStep == 2
              ? _c(
                  "div",
                  { staticClass: "step2" },
                  [
                    _c("span", [_vm._v("电子合同认证已完成？请进行免签授权")]),
                    _c("div", { on: { click: _vm._copy } }, [
                      _vm._v("场景码HSKF87394 "),
                      _c("p", [_vm._v("复制")]),
                    ]),
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "252px", "margin-top": "16px" },
                        on: { click: _vm.handleGoLoginFDD },
                      },
                      [_vm._v("免签授权")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm.status === 2
        ? [
            _c("InfoDetail", { attrs: { disabled: true } }),
            _c(
              "div",
              { staticClass: "bottom-btn-layout" },
              [
                _c("el-button", { on: { click: _vm.handleChangeInfo } }, [
                  _vm._v("修改信息"),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }