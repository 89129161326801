var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c("div", { staticClass: "header" }, [_vm._v(" 定价区域 ")]),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "top" }, [
            _c("div", { staticClass: "imgBox" }, [
              _c("div", {}, [
                _c("img", {
                  staticStyle: { width: "80px", height: "80px" },
                  attrs: { src: _vm.headData.image, alt: "" },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "fontBox",
                  staticStyle: { "padidng-left": "20px" },
                },
                [
                  _c("span", { staticClass: "spanFont jiaolang" }, [
                    _vm._v(_vm._s(_vm.headData.goodsName)),
                  ]),
                  _c("span", { staticClass: "spanFont" }, [
                    _vm._v(_vm._s(_vm.headData.approvalNumber)),
                  ]),
                  _c("span", { staticClass: "spanFont company" }, [
                    _vm._v(_vm._s(_vm.headData.factory)),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "center" },
            [
              _c(
                "el-form",
                { ref: "formName", attrs: { model: _vm.studentData } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.studentData.tableData,
                        "span-method": _vm.objectSpanMethod,
                        border: "",
                        align: "left",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "regionName",
                          label: "定价区域",
                          align: "left",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "20px" } },
                                  [_vm._v(_vm._s(scope.row.regionName) + " ")]
                                ),
                                _vm.addType == "true"
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          underline: false,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.click(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("定义")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "agencyTypeName",
                          label: "机构类型",
                          align: "left",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "retailPrice",
                          label: "零售价（元）",
                          rules: _vm.rules.retailPrice,
                          align: "right",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      size: "small",
                                      prop:
                                        "tableData." +
                                        scope.$index +
                                        ".retailPrice",
                                      rules: _vm.rules.retailPrice,
                                    },
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        "controls-position": "right",
                                        min: 0,
                                      },
                                      model: {
                                        value: scope.row.retailPrice,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "retailPrice",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.retailPrice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "wholesalePrice",
                          label: "批发价（元）",
                          rules: _vm.rules.wholesalePrice,
                          align: "right",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      size: "small",
                                      prop:
                                        "tableData." +
                                        scope.$index +
                                        ".wholesalePrice",
                                      rules: _vm.rules.wholesalePrice,
                                    },
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        "controls-position": "right",
                                        min: 0,
                                      },
                                      model: {
                                        value: scope.row.wholesalePrice,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "wholesalePrice",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.wholesalePrice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "wholesaleMinAmount",
                          rules: _vm.rules.wholesaleMinAmount,
                          label: "起订量",
                          align: "right",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      size: "small",
                                      prop:
                                        "tableData." +
                                        scope.$index +
                                        ".wholesaleMinAmount",
                                      rules: _vm.rules.wholesaleMinAmount,
                                    },
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        "controls-position": "right",
                                        min: 0,
                                      },
                                      model: {
                                        value: scope.row.wholesaleMinAmount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "wholesaleMinAmount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scope.row.wholesaleMinAmount",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.addType == "true"
            ? _c(
                "div",
                {
                  staticClass: "addGoods",
                  on: {
                    click: function ($event) {
                      return _vm.add()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "addFont" }, [_vm._v(" + ")]),
                  _c(
                    "span",
                    { staticStyle: { "font-size": "14px", color: "#595959" } },
                    [_vm._v(" 新增定价区域 ")]
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "bottom" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitDetail(1)
                    },
                  },
                },
                [_vm._v("保存定价区域")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增区域",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formTool",
              staticStyle: { "text-align": "left" },
              attrs: { model: _vm.formTool, inline: true },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "name", label: "区域名称：" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入区域",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.formTool.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "name", $$v)
                      },
                      expression: "formTool.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "max-height": "300px", overflow: "auto" } },
            [
              _c("el-tree", {
                ref: "tree",
                staticClass: "filter-tree",
                attrs: {
                  "show-checkbox": "",
                  "node-key": "id",
                  "highlight-current": "",
                  data: _vm.data,
                  props: _vm.defaultProps,
                  "default-expand-all": "",
                  "filter-node-method": _vm.filterNode,
                },
                on: { check: _vm.check },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }