<!--  -->
<template>
  <div class="classification">
    <div class="header" style="margin: 10px">
      <el-form
        ref="formTool"
        :model="formTool"
        :inline="true"
        style="text-align: left"
      >
        <el-form-item label="订单创建时间" prop="date">
          <el-date-picker
            style="width: 100%"
            v-model="formTool.date"
            value-format="yyyy-MM-dd"
            type="daterange"
            size="small"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item label="订单编号">
          <el-input
            v-model.trim="formTool.orderCode"
            placeholder="请输入"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item label="客户名称">
          <el-input
            v-model.trim="formTool.clientName"
            placeholder="请输入"
            clearable
            size="small"
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item label="开票状态">
          <el-select
            v-model="formTool.billingStatus"
            clearable
            style="width: 160px"
            size="small"
          >
            <el-option
              v-for="(item, index) in invoiceOptions"
              :key="index"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button size="small" @click="reset()" plain>重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="exportUrl()">导出</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="Invoicing()"
            >去开票</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="center" style="position: relative">
      <MyTable
        :showpage="true"
        :table-data="tableData"
        :index="index"
        :selection="selection"
        :page="page"
        :operation="operation"
        :table-option.sync="tableOption"
        @page-change="getList"
        @handleSelectionChange="handleSelectionChange"
      >
        <template slot-scope="scope" slot="caozuo">
          <el-link
            :underline="false"
            type="primary"
            @click="operationHistory(scope.row)"
            class="btn-list"
            >操作历史</el-link
          >
          <el-link
            :underline="false"
            type="primary"
            @click="lookPdf(scope.row)"
            v-if="scope.row.billingStatus == 'FINISHED'"
            class="btn-list"
            >发票详情</el-link
          >
          <el-link
            :underline="false"
            type="primary"
            @click="offlineInvoice(scope.row)"
            v-if="scope.row.billingStatus == 'NOTINVOICED'"
            class="btn-list"
            >已线下开票</el-link
          >
        </template>
        <template slot="billingStatus" slot-scope="scope">
          {{ scope.row.billingStatus | billingStatusType }}
        </template>
        <!-- 合计金额 amountTotal -->
        <!-- taxRate 税率 -->
        <!-- taxAmount 税额 -->
        <template slot="orderAmount" slot-scope="scope">
          {{ scope.row.orderAmount || "0.00" }}
        </template>
        <template slot="amountTotal" slot-scope="scope">
         <span v-if="scope.row.billingStatus !='NOTINVOICED'"> {{ scope.row.amountTotal  || "0.00" }}</span>
         <span v-else></span>
        </template>
        <template slot="taxRate" slot-scope="scope">
         <span  v-if="scope.row.billingStatus !='NOTINVOICED'"> {{ scope.row.taxRate  || "0.00" }}</span>
         <span v-else></span> 
        </template>

        <template slot="taxAmount" slot-scope="scope">
         <span  v-if="scope.row.billingStatus !='NOTINVOICED'"> {{ scope.row.taxAmount  || "0.00" }}</span>
         <span v-else></span> 
        </template>
      </MyTable>
    </div>

    <settingTaxRate ref="setting-tax-rate" @getList="getList" />
    <settingKB ref="setting-kb" @getList="getList" />
    <invocieOprateHistory ref="invocie-oprate-history" />
    <InvoicingDialog ref="invoice-dailog" />
    <el-dialog :visible.sync="pdfDialog"
               title="发票详情">
      <iframe v-if="pdfUrl"
              :src="'data:application/pdf;base64,' + pdfUrl"
              style="width: 100%; height: 500px;"
              frameborder="0"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  queryClientAutomaticBillingVO,
  clientAutomaticBillingExcel,
  offlineBilling,
  getInvoicePdf,
} from "@/api/invoice";
import { isJSONObject } from "@/utils";
import settingTaxRate from "./components/settingTaxRate.vue";
import settingKB from "./components/settingKB.vue";
import avater from "/public/img/default.png";
import invocieOprateHistory from "./components/invoiceOprateHistory.vue";
import InvoicingDialog from "./components/invoicingStepsDialog.vue";
import MyTable from "@/components/myTable";

const billingStatusOptions = [
  { id: "", label: "全部" },
  { id: "FINISHED", label: "开票成功" },
  { id: "FAIL", label: "开票失败" },
  { id: "NOTINVOICED", label: "待开票" },
  { id: "PROCESS", label: "开票中" },
  { id: "OFFLINEBILLINGFINISHED", label: "线下开票" },
];

const billingStatusFilters = {
  billingStatusType: function (value) {
    if (!value) return "";
    const options = billingStatusOptions.find((option) => option.id === value);
    return options ? options.label : "";
  },
};
export default {
  components: {
    settingTaxRate,
    settingKB,
    invocieOprateHistory,
    InvoicingDialog,
    MyTable,
  },
  data() {
    return {
      invoiceOptions: billingStatusOptions,
      formTool: {
        billingStatus: "",
      },
      activeName: 1,
      avater: avater,
      operation: false,
      selection: false,
      index: true,
      tableData: [],
      tableOption: [
        // orderTime
        { label: "订单创建时间", prop: "orderTime" }, // 这里表示自定义列 , slot: true
        { label: "客户名称", prop: "clientName" }, // 这里表示自定义列 , slot: true
        { label: "订单金额", prop: "orderAmount", width: "120px", slot: true }, // 这里表示自定义列 , slot: true
        { label: "合计金额", prop: "amountTotal", width: "120px", slot: true }, // 这里表示自定义列 , slot: true
        { label: "税率/征收率", prop: "taxRate", width: "100px", slot: true }, // 这里表示自定义列 , slot: true
        { label: "税额", prop: "taxAmount", width: "100px" , slot: true}, // 这里表示自定义列 , slot: true
        { label: "价税合计", prop: "isum", width: "120px" }, // 这里表示自定义列 , slot: true
        {
          label: "开票状态",
          prop: "billingStatus",
          width: "100px",
          slot: true,
        }, // 这里表示自定义列 , slot: true
        { label: "订单编号", prop: "orderCode", width: "200px" }, // 这里表示自定义列 , slot: true
        { label: "操作", prop: "caozuo", slot: true, width: "240px" },

        // { label: '操作', prop: 'menu' }
      ],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },

      dialogShow: false,
      loading: false,
      tableKey: 0,
      pdfDialog:false,
      pdfUrl:'',
    };
  },
  filters: billingStatusFilters,

  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getList();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  computed: {
    ...mapGetters(["permissions"]),
  },
  methods: {
    reset(){
      this.formTool = {
        billingStatus: "",
      }
      this.getList(1);
    },
    exportUrl() {
      let params = {};
      params.tag = "own";
      params = Object.assign(params, this.formTool);
      if (params.date && params.date.length > 0) {
        params.startTime = params.date[0];
        params.endTime = params.date[1];
        delete params.date;
      }
      clientAutomaticBillingExcel(params).then((res) => {
        console.log(res);
      });
    },
    operationHistory(row) {
      this.$refs["invocie-oprate-history"].init(row);
    },
 
    // 新建
    createAccount() {
      this.$router.push({
        path: "./invoiceCenter-invoiceInfoManege",
        query: {
          type: "add",
        },
      });
    },

    //offlineInvoice
    offlineInvoice(row) {
      // 弹窗提示
      this.$confirm(
        "若您已经在线下开票，请确认已经在线下开票，此订单将不会再提醒您进行开票?",
        "提醒",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          // 确定
          // 调用已线下开票接口
          let params = {};
          params.itemIdSet = [row.id];
          offlineBilling(params).then((res) => {
            if (res) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          // 取消
        });
    },

    // 去开票
    Invoicing(row) {
      this.$refs["invoice-dailog"].init(row);
    },

    // 设置税率
    settingTaxRate(row) {
      this.$refs["setting-tax-rate"].init(row);
    },

    settingKB(row) {
      this.$refs["setting-kb"].init(row);
    },

    selectFun() {
      this.selection = true;
    },

    async lookPdf(item) {
      const obj = {
        recordId: item.recordId,
      };
      const resp = await getInvoicePdf(obj);
      if (resp) {
        this.pdfDialog = true;
        this.pdfUrl = resp;
      }
    },

    // 勾选
    handleSelectionChange(val) {},

    selectAll(selection) {
      this.selectionList = selection;
    },

    // 获取列表
    getList(current) {
      let params = {};
      params.size = this.page.size;
      if(current){
        params.current = current;
        this.page.current = current;
      }else{
        params.current = this.page.current;
      }
      params.tag = "own";
      params = Object.assign(params, this.formTool);
      if (params.date && params.date.length > 0) {
        params.startTime = params.date[0];
        params.endTime = params.date[1];
        delete params.date;
      }
      queryClientAutomaticBillingVO(params).then((res) => {
        if (res) {
          this.tableData = res.records;
          this.page.total = res.total;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
.el-table--border {
  border: 1px solid #f5f5f5 !important;
}
.classification {
  padding: 0px;

  .header {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 161px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn-warp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.btn-list {
  margin: 0 5px;
}
</style>
