import request from "@/utils/request";

// 运营分类列表
export function goodsOperationalClasssPage(data) {
  return request({
    url: `/product/operationalclass/goodsOperationalClasss/tree`,
    method: "get",
    params:data
  });
}

// 获取分类树
export function goodsclassTree() {
  return request({
    url: `/product/goodsclass/tree`,
    method: "get"
  });
}

// 根据运营分类和药理分类筛选关联商品数据
export function goodsOperationalClassRelationsSelectGoods(data) {
  return request({
    url: `/product/operationalclass/goodsOperationalClassRelations/selectGoodsUnion`,
    method: "get",
    params:data
  });
}

// 根据运营分类和药理分类筛选未关联商品数据
export function selectGoodsNounion(data) {
  return request({
    url: `/product/operationalclass/goodsOperationalClassRelations/selectGoodsNoUnion`,
    method: "get",
    params:data
  });
}
// 批量更运营分类商品关联药理分类管理
export function bathUpdateByGoodsClass(data) {
  return request({
    url: `/product/operationalclass/goodsOperationalClassRelations/bathUpdateByGoodsClass`,
    method: "PATCH",
    data
  });
}

// 批量新增运营分类商品关联
export function bathUpdateByProduct(data) {
  return request({
    url: `/product/operationalclass/goodsOperationalClassRelations/bathUpdateByProduct`,
    method: "PATCH",
    data
  });
}
// 删除运营分类关系
export function goodsOperationalClassRelations(data) {
  return request({
    url: `/product/operationalclass/goodsOperationalClassRelations/${data}`,
    method: "DELETE"
  });
}
// 修改（属性值为null时会清空数据）
export function goodsOperationalClasss(data) {
  return request({
    url: `/product/operationalclass/goodsOperationalClasss/${data.id}`,
    method: "PUT",
    data
  });
}

// 删除（属性值为null时会清空数据）
export function deleteGoodsOperationalClasss(data) {
  return request({
    url: `/product/operationalclass/goodsOperationalClasss/${data.id}`,
    method: "delete",
    data
  });
}
// 新增

export function addGoodsOperationalClasss(data) {
  return request({
    url: `/product/operationalclass/goodsOperationalClasss`,
    method: "post",
    data
  });
}

// 置顶
export function goodsOperationalClasssTop(id) {
  return request({
    url: `/product/operationalclass/goodsOperationalClasss/top/${id}`,
    method: "PATCH"
  });
}

// 置尾
export function goodsOperationalClasssTail(id) {
  return request({
    url: `/product/operationalclass/goodsOperationalClasss/tail/${id}`,
    method: "PATCH"
  });
}
// 交换
export function exchange(data) {
  return request({
    url: `/product/operationalclass/goodsOperationalClasss/exchange`,
    method: "POST",
    data
  });
}

// 删除运营分类所有关系
export function removeAll(data) {
  return request({
    url: `/product/operationalclass/goodsOperationalClassRelations/removeAll`,
    method: "DELETE",
    data
  });
}

// 根据页面查询条件添加分类和商品关系（所有）
export function addAll(data) {
  return request({
    url: `/product/operationalclass/goodsOperationalClassRelations/addAll`,
    method: "POST",
    data
  });
}