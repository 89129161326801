var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("MyTabs", {
        staticClass: "myTabs",
        attrs: { tabi: _vm.tabsIndex, tabs: _vm.tabsData, numhide: "" },
        on: { change: _vm.tabChange },
      }),
      _c("div", { staticClass: "topHeader" }, [
        _c(
          "div",
          { staticStyle: { height: "40px" } },
          [
            _c(
              "el-form",
              { ref: "formTool", attrs: { model: _vm.formTool, inline: true } },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "invoiceNo" } },
                  [
                    _c("el-input", {
                      attrs: {
                        clearable: "",
                        placeholder: "发票号码/订单编号",
                        size: "small",
                      },
                      model: {
                        value: _vm.formTool.invoiceNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.formTool, "invoiceNo", $$v)
                        },
                        expression: "formTool.invoiceNo",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "content" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          placeholder: "发票内容",
                          size: "mini",
                        },
                        model: {
                          value: _vm.formTool.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "content", $$v)
                          },
                          expression: "formTool.content",
                        },
                      },
                      _vm._l(_vm.contentType, function (li) {
                        return _c("el-option", {
                          key: li.value,
                          attrs: { label: li.label, value: li.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "date" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        size: "small",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "-",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      model: {
                        value: _vm.formTool.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.formTool, "date", $$v)
                        },
                        expression: "formTool.date",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("MyButton", {
              attrs: { type: "primary", text: "查询" },
              on: {
                click: function ($event) {
                  return _vm.getList()
                },
              },
            }),
            _c("MyButton", {
              attrs: { type: "", text: "重置" },
              on: {
                click: function ($event) {
                  return _vm.reset()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("MyTable", {
            attrs: {
              "table-data": _vm.tableData,
              index: _vm.index,
              expand: "",
              selection: _vm.selection,
              page: _vm.page,
              operation: _vm.operation,
              "table-option": _vm.tableOption,
            },
            on: {
              "update:tableOption": function ($event) {
                _vm.tableOption = $event
              },
              "update:table-option": function ($event) {
                _vm.tableOption = $event
              },
              "page-change": _vm.getList,
              handleSelectionChange: _vm.handleSelectionChange,
            },
            scopedSlots: _vm._u([
              {
                key: "expand",
                fn: function (props) {
                  return [
                    _c("div", { staticClass: "expand_box" }, [
                      _c("div", { staticClass: "expand_li" }, [
                        _c("strong", [_vm._v("表id：")]),
                        _c("span", [_vm._v(_vm._s(props.row.id))]),
                      ]),
                      _c("div", { staticClass: "expand_li" }, [
                        _c("strong", [_vm._v("订单号：")]),
                        _c("span", [_vm._v(_vm._s(props.row.orderId))]),
                      ]),
                      _c("div", { staticClass: "expand_li" }, [
                        _c("strong", [_vm._v("开票流水号：")]),
                        _c("span", [_vm._v(_vm._s(props.row.serialNo))]),
                      ]),
                      _c("div", { staticClass: "expand_li" }, [
                        _c("strong", [_vm._v("发票号：")]),
                        _c("span", [_vm._v(_vm._s(props.row.invoiceNo))]),
                      ]),
                      _c("div", { staticClass: "expand_li" }, [
                        _c("strong", [_vm._v("开票时间：")]),
                        _c("span", [_vm._v(_vm._s(props.row.invoiceTime))]),
                      ]),
                      _c("div", { staticClass: "expand_li" }, [
                        _c("strong", [_vm._v("申请开票时间：")]),
                        _c("span", [_vm._v(_vm._s(props.row.submitTime))]),
                      ]),
                      _c("div", { staticClass: "expand_li" }, [
                        _c("strong", [_vm._v("开票完成时间：")]),
                        _c("span", [_vm._v(_vm._s(props.row.finishTime))]),
                      ]),
                      _c("div", { staticClass: "expand_li" }, [
                        _c("strong", [_vm._v("发票抬头：")]),
                        _c("span", [_vm._v(_vm._s(props.row.title))]),
                      ]),
                      _c("div", { staticClass: "expand_li" }, [
                        _c("strong", [_vm._v("发票状态：")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.allStatus[props.row.status])),
                        ]),
                      ]),
                      _c("div", { staticClass: "expand_li" }, [
                        _c("strong", [_vm._v("发票类型：")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.allTypes[props.row.type])),
                        ]),
                      ]),
                      _c("div", { staticClass: "expand_li" }, [
                        _c("strong", [_vm._v("发票金额：")]),
                        _c("span", [_vm._v(_vm._s(props.row.amount))]),
                      ]),
                      _c("div", { staticClass: "expand_li" }, [
                        _c("strong", [_vm._v("发票内容：")]),
                        _c("span", [_vm._v(_vm._s(props.row.contentDes))]),
                      ]),
                      _c("div", { staticClass: "expand_li" }, [
                        _c("strong", [_vm._v("创建者：")]),
                        _c("span", [_vm._v(_vm._s(props.row.createBy))]),
                      ]),
                      props.row.imgUrl
                        ? _c("div", { staticClass: "expand_li" }, [
                            _c("strong", [_vm._v("发票预览：")]),
                            _c(
                              "div",
                              { staticClass: "expand_li_more" },
                              [
                                _c("el-image", {
                                  staticClass: "expand_li_img",
                                  attrs: {
                                    src: props.row.imgUrl,
                                    "preview-src-list": [props.row.imgUrl],
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      props.row.fileUrl
                        ? _c("div", { staticClass: "expand_li" }, [
                            _c("strong", [_vm._v("发票PDF地址：")]),
                            _c("div", { staticClass: "expand_li_more" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "expand_li_link",
                                  attrs: {
                                    href: props.row.fileUrl,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("查看文件")]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }