import request from "@/utils/request";
// 分页查询
export function skuPage(data) {
  return request({
    url: `/product/sales/sku/page`,
    method: "get",
    params: data,
  });
}

// 根据基库商品id查询商品对应的sku信息
export function complianceSku(data) {
  return request({
    url: `/product/sales/sku/goodsBase/${data}`,
    method: "get",
  });
}
// 修改sku上架状态
export function skuStatus(data) {
  return request({
    url: `/product/sales/sku/status`,
    method: "put",
    params: data,
  });
}

// 商品加入仓库
export function warehouseAdd(data) {
  return request({
    url: `/product/sales/sku/warehouse/add`,
    method: "post",
    data,
  });
}

// 批量修改sku信息
export function batchUpdate(data) {
  return request({
    url: `/product/sales/sku/batchUpdate`,
    method: "put",
    data,
  });
}

// sku商品信息修改
export function skuUpdate(data) {
  return request({
    url: `/product/sales/sku/update`,
    method: "put",
    data,
  });
}

// 新增sku
export function skuAdd(data) {
  return request({
    url: `/product/sales/sku/add`,
    method: "post",
    data,
  });
}

// 新增单品包邮 postage
export function postageAddSku(data) {
  return request({
    url: `/product/sales/sku/add/exemption/postage`,
    method: "post",
    data,
  });
}

// 根据sku id查询sk详情
export function skuId(skuId) {
  return request({
    url: `/product/sales/sku/${skuId}`,
    method: "get",
  });
}

// 根据goodsSkuId查询商品变更记录
export function queryGoodsSkuEditLogList(goodsSkuId) {
  return request({
    url: `/product/monitor/goodsSkuEditLog/queryGoodsSkuEditLogList/${goodsSkuId}`,
    method: "get",
  });
}

// 查询地域组信息
export function groupInfos() {
  return request({
    url: `/product/sales/salesskuarearelevance/groupInfos`,
    method: "get",
  });
}

// 查询详情地域组信息
export function salesskuarearelevanceQuery(areaId) {
  return request({
    url: `/product/sales/salesskuarearelevance/${areaId}`,
    method: "get",
  });
}

// 添加或更新地域组信息
export function insertOrUpdate(data) {
  return request({
    url: `/product/sales/salesskuarearelevance/insertOrUpdate`,
    method: "POST",
    data: data,
  });
}
// 删除地域组信息
export function salesskuarearelevance(areaId) {
  return request({
    url: `/product/sales/salesskuarearelevance/${areaId}`,
    method: "delete",
  });
}

// 查询用户组信息
export function salesgroupinfo(data) {
  return request({
    url: `/product/sales/salesgroupinfo/groupInfos`,
    method: "get",
    params: data,
  });
}

// 添加或更新用户组信息
export function salesgroupinfoInsertOrUpdate(data) {
  return request({
    url: `/product/sales/salesgroupinfo/insertOrUpdate`,
    method: "POST",
    data: data,
  });
}
// 删除用户组信息
export function salesgroupinfoDelete(id) {
  return request({
    url: `/product/sales/salesgroupinfo/${id}`,
    method: "delete",
  });
}
// 查询用户组信息详情
export function salesgroupinfoDetail(id) {
  return request({
    url: `/product/sales/salesgroupinfo/${id}`,
    method: "get",
  });
}
// 根据导入的excel进行添加客户
export function salesgroupclientinfoInsert(data) {
  return request({
    url: `/product/sales/salesgroupclientinfo/insert`,
    method: "post",
    data,
  });
}

// 下载excel模板
export function downloadFile(data) {
  return request({
    url: `/product/sales/salesgroupclientinfo/download`,
    method: "get",
    params: data,
  });
}

// 查询控销组下的分页客户数据
export function salesgroupclientinfoPage(data) {
  return request({
    url: `/product/sales/salesgroupclientinfo/page`,
    method: "get",
    params: data,
  });
}
// 删除客户信息
export function salesgroupclientinfoId(id) {
  return request({
    url: `/product/sales/salesgroupclientinfo/${id}`,
    method: "DELETE",
  });
}

// 添加部门信息
export function insert(data) {
  return request({
    url: `/external-sales-rule/dept/externalsalesdeptinfo/insertDeptInfo`,
    method: "POST",
    data,
  });
}

// 更新部门信息
export function update(data) {
  return request({
    url: `/external-sales-rule/dept/externalsalesdeptinfo/update`,
    method: "put",
    data,
  });
}

// 查询部门详情
export function externalsalesdeptinfo(id) {
  return request({
    url: `/external-sales-rule/dept/externalsalesdeptinfo/${id}`,
    method: "get",
  });
}

//
// 分页查询部门信息
export function externalsalesdeptinfoPage(data) {
  return request({
    url: `/external-sales-rule/dept/externalsalesdeptinfo/page`,
    method: "get",
    params: data,
  });
}

// 更新部门状态
export function updateStatus(data) {
  return request({
    url: `/external-sales-rule/dept/externalsalesdeptinfo/update/status`,
    method: "put",
    params: data,
  });
}

// 分页查询海报
export function salesposterinfoPage(data) {
  return request({
    url: `/external-sales-rule/poster/salesposterinfo/page`,
    method: "get",
    params: data,
  });
}
// 查询关联商品信息
export function relevancePage(data) {
  return request({
    url: `/product/sales/sku/relevance/page`,
    method: "get",
    params: data,
  });
}

// 关联商品
export function salesposterinfoRelevance(data) {
  return request({
    url: `/external-sales-rule/poster/salesposterinfo/relevance`,
    method: "put",
    data,
  });
}

// 更新或者添加
export function operation(data) {
  return request({
    url: `/external-sales-rule/poster/salesposterinfo/operation`,
    method: "put",
    data: data,
  });
}

// salesposterinfo
//更新图文海报信息
export function salesposterinfoUpdate(data) {
  return request({
    url: `/external-sales-rule/poster/salesposterinfo`,
    method: "post",
    data: data,
  });
}

//添加图文海报信息
export function salesposterinfoAdd(data) {
  return request({
    url: `/external-sales-rule/poster/salesposterinfo`,
    method: "put",
    data: data,
  });
}

// 查询详情
export function salesposterinfo(id) {
  return request({
    url: `/external-sales-rule/poster/salesposterinfo/${id}`,
    method: "get",
  });
}

// 获取商户下部门用户列表
export function listByBelongUserId(data) {
  return request({
    url: `/member/info/shop/user/listByBelongUserId`,
    method: "get",
    params: data,
  });
}

//添加sku的时候erp同步库存
// medicineCode 	erp编码
export function getErpStock(data) {
  return request({
    url: `/product/sales/sku/add/erpStock`,
    method: "get",
    params: data,
  });
}
//添加sku的时候erp同步价格
// medicineCode 	erp编码
export function getErpPrice(data) {
  return request({
    url: `/product/sales/sku/add/erpPrice`,
    method: "get",
    params: data,
  });
}

//添加sku的时候 同步有效期和生产日期
export function getErpDate(data) {
  return request({
    url: `/product/sales/sku/add/erpDate`,
    method: "get",
    params: data,
  });
}

// 根据登录人查询部门信息
export function queryDeptShopInfoByLogUser(data) {
  return request({
    url: `/external-sales-rule/dept/externalsalesdeptinfo/queryDeptShopInfoByLogUser`,
    method: "get",
    params: data,
  });
}

//单品包邮sku商品信息修改
export function skuUpdateExemption(data) {
  return request({
    url: `/product/sales/sku/update/exemption/postage`,
    method: "put",
    data: data,
  });
}

//检测商户是否注册康贝号
export function checkMerchantRegisterKB(data) {
  return request({
    url: `/shop/shop/checkMerchantRegisterKB`,
    method: "get",
    params: data,
  });
}

// /add/full/gift/sku 满赠添加
export function saveOrUpdateGiftSku(data) {
  return request({
    url: `/product/sales/sku/add/full/gift/sku`,
    method: "POST",
    data,
  });
}

// 查询满赠商品
export function getGiftSku(data) {
  return request({
    url: `/product/sales/sku/get/full/gift/page`,
    method: "POST",
    data,
  });
}
