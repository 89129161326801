<template>
    <div>
        <el-dialog title="发票信息" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <div  v-if="showType == 'pic'">
                <el-button type="primary" @click="lookText" >查看文字信息</el-button>
                <el-image :src="invoiceData.image" style="width: 100%;height: 30vw;"></el-image>
            </div>
            <div v-else-if="showType == 'text'">
                <el-button type="primary" @click="lookPic" v-if="!invoiceTypeDefault">查看图片信息</el-button>
                <p class="title">注意：文字信息由店员编辑，可能存在编辑错误的风险，请仔细核对，建议以开票资料为准。</p>
                <p class="desc">发票类型：{{invoiceTypeDefault ? '普票' : '专票'}}</p>
                <p class="desc">税&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号：{{ invoiceData.sellerTaxpayerId }} <el-link @click="copyText(invoiceData.sellerTaxpayerId, $event)">复制</el-link> </p>
                <p class="desc" v-if="!invoiceTypeDefault">开户银行：{{invoiceData.sellerBank}}<el-link @click="copyText(invoiceData.sellerBank, $event)">复制</el-link> </p>
                <p class="desc" v-if="!invoiceTypeDefault">开户账号：{{invoiceData.sellerAccount}}<el-link @click="copyText(invoiceData.sellerAccount, $event)">复制</el-link> </p>
                <p class="desc">公司名称：{{invoiceData.sellerName}} <el-link  @click="copyText(invoiceData.sellerName, $event)">复制</el-link> </p>
                <p class="desc">公司地址：{{invoiceData.sellerAddress}} <el-link  @click="copyText(invoiceData.sellerAddress, $event)">复制</el-link> </p>
                <p class="desc" v-if="!invoiceTypeDefault">联系电话：{{invoiceData.sellerTel}}<el-link @click="copyText(invoiceData.sellerTel, $event)">复制</el-link> </p>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import clip from "@/utils/clipboard";
export default {
    data() {
        return {
            dialogVisible:false,//弹窗显示隐藏
            showType:'text',//展示类型 text:文字信息，pic:图片信息
            invoiceData:{}
        }
    },
    computed: {
        // 是否是普票或专票
        invoiceTypeDefault(){
            let isNormalInvoice = true
            if (this.invoiceData.invoiceType == 1 || this.invoiceData.invoiceType == 2 )  {
                // text = '普票'
                isNormalInvoice = true
            } else if (this.invoiceData.invoiceType == 3 || this.invoiceData.invoiceType == 4) {
                // text = '专票'
                isNormalInvoice = false
            }
            return isNormalInvoice
        },

    },
    methods: {
        // 查看文字信息
        lookText(){
            this.showType = 'text';
        },
        // 查看图片信息
        lookPic(){
            this.showType = 'pic';
        },
        // 复制
        copyText(str,event){
            clip(str,event)
            // let that = this;
            // navigator.clipboard.writeText(str).then(function() {
            //     that.$message({
            //         message: '复制成功',
            //         type: 'success'
            //     });
            // }, function() {
            // });
        },
        openDialog(e){
            this.invoiceData = e;
            this.dialogVisible = true;
        },
        handleClose() {
            this.dialogVisible = false;
        }
    }
}
</script>
<style lang="scss" scoped>
.title{
    font-size: 14px;
    font-weight: bold;
    color: #f5222d;
    margin-bottom: 10px;
}
.desc{
    font-size: 14px;
    color: #595959;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>