<template>
  <div class="dialogMain">
    <el-form label-width="80px"
             label-position="left">
      <el-form-item label="发票类型">
        <el-select v-model="form.invoiceType"
                   size="small">
          <el-option :label="item.codeText"
                     :value="item.codeValue"
                     v-for="item,index in invoiceTypeList"
                     :key="index" />
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="发票票种">
                <el-select v-model="form.invoiceCategory"  size="small">
                    <el-option label="消肿止痛贴" value="消肿止痛贴" />
                </el-select>
            </el-form-item> -->
      <!-- <el-form-item label="特定业务">
                <el-select v-model="form.invoiceBusiness"  size="small">
                    <el-option label="消肿止痛贴" value="消肿止痛贴" />
                </el-select>
            </el-form-item>
            <el-form-item label="减按征税">
                <el-select v-model="form.invoiceFee"  size="small">
                    <el-option label="消肿止痛贴" value="消肿止痛贴" />
                </el-select>
            </el-form-item> -->
      <el-form-item>
        <div class="rowEnd">
          <el-button size="small"
                     @click="cancel">取消</el-button>
          <el-button type="primary"
                     size="small"
                     @click="addSure">确定</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { getInvoiceType } from "@/api/invoice";
export default {
  data () {
    return {
      form: {
        invoiceType: '',
        invoiceCategory: '',
        invoiceBusiness: '',
        invoiceFee: '',
      },
      invoiceTypeList: []
    }
  },
  mounted () {
    this.getInvoiceTypeAction()
  },
  methods: {
    async getInvoiceTypeAction () {
      const resp = await getInvoiceType()
      if (resp) {
        let tmp = []
        for (const key in resp) {
          if (Object.hasOwnProperty.call(resp, key)) {
            const element = resp[key];
            tmp.push({
              codeValue: key,
              codeText: element
            })
          }
        }
        this.invoiceTypeList = tmp
      }

    },
    cancel () {
      this.$emit('cancelAddDialog')
    },
    addSure () {
      this.$emit('addSureAction', { data: this.form })
    }
  }
}
</script>
<style lang="scss" scoped>
.dialogMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .rowEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    .el-button {
      width: 77px;
    }
  }
}
</style>