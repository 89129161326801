var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        "lock-scroll": true,
        width: "718px",
        "before-close": _vm.handleClose,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", {}, [
        _c(
          "div",
          { staticStyle: { padding: "24px 0" } },
          [
            _c(
              "el-form",
              {
                ref: "form-data",
                staticStyle: { "text-align": "left" },
                attrs: {
                  model: _vm.form,
                  inline: false,
                  "label-width": "70px",
                  "label-position": "right",
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "fileList", label: "打款凭证" } },
                  [
                    _c("imgUpload", {
                      staticStyle: { position: "relative", top: "-8px" },
                      attrs: { isEdit: _vm.isDisabled, limit: 3 },
                      model: {
                        value: _vm.form.imageList,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "imageList", $$v)
                        },
                        expression: "form.imageList",
                      },
                    }),
                  ],
                  1
                ),
                _vm.form.paymentStatus == 3
                  ? _c(
                      "el-form-item",
                      { attrs: { prop: "", label: "驳回原因" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "",
                            clearable: "",
                            size: "auto",
                            type: "textarea",
                            disabled: true,
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "remark",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.remark",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "footer-btn" }, [
                  _vm.form.paymentStatus == 1
                    ? _c(
                        "div",
                        { staticStyle: { color: "rgb(255, 153, 0)" } },
                        [_vm._v(" 凭证待审核 ")]
                      )
                    : _vm._e(),
                  _vm.form.paymentStatus == 2
                    ? _c("div", { staticStyle: { color: "green" } }, [
                        _vm._v(" 凭证已确认 "),
                      ])
                    : _vm._e(),
                  _vm.form.paymentStatus == 3
                    ? _c("div", { staticStyle: { color: "#F5222D" } }, [
                        _vm._v(" 凭证已驳回 "),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _vm.form.paymentStatus == 3
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.resetSubmit()
                                },
                              },
                            },
                            [_vm._v("重新提交")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "plain" },
                          on: {
                            click: function ($event) {
                              return _vm.handleClose()
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }