var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goodsDetailDlg" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品明细数据",
            visible: _vm.dialogVisible,
            width: "92%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "goods-info" }, [
            _c("div", { staticClass: "title" }, [_vm._v("商品信息")]),
            _c("div", { staticClass: "info" }, [
              _c("div", [
                _c("span", { staticClass: "color-59" }, [_vm._v("商品编码：")]),
                _c("span", { staticClass: "color-26" }, [
                  _vm._v(_vm._s(_vm.goodsDetail.goods_code)),
                ]),
              ]),
              _c("div", [
                _c("span", { staticClass: "color-59" }, [_vm._v("商品名称：")]),
                _c("span", { staticClass: "color-26" }, [
                  _vm._v(_vm._s(_vm.goodsDetail.goods_name)),
                ]),
              ]),
              _c("div", [
                _c("span", { staticClass: "color-59" }, [_vm._v("厂家：")]),
                _c("span", { staticClass: "color-26" }, [
                  _vm._v(_vm._s(_vm.goodsDetail.goods_factory)),
                ]),
              ]),
              _c("div", [
                _c("span", { staticClass: "color-59" }, [_vm._v("规格：")]),
                _c("span", { staticClass: "color-26" }, [
                  _vm._v(_vm._s(_vm.goodsDetail.goods_specification)),
                ]),
              ]),
              _c("div", [
                _c("span", { staticClass: "color-59" }, [_vm._v("批准文号：")]),
                _c("span", { staticClass: "color-26" }, [
                  _vm._v(_vm._s(_vm.goodsDetail.approval_number)),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "goods-detail" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("商品明细")]),
              _c(
                "el-form",
                {
                  ref: "formTool",
                  attrs: {
                    "label-width": "90px",
                    model: _vm.formTool,
                    size: "small",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "SKU编码：" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.formTool.factory,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formTool, "factory", $$v)
                                  },
                                  expression: "formTool.factory",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          on: { input: _vm.monthYearChange },
                          model: {
                            value: _vm.tabPosition,
                            callback: function ($$v) {
                              _vm.tabPosition = $$v
                            },
                            expression: "tabPosition",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "week" } }, [
                            _vm._v("本周"),
                          ]),
                          _c("el-radio-button", { attrs: { label: "month" } }, [
                            _vm._v("本月"),
                          ]),
                          _c("el-radio-button", { attrs: { label: "year" } }, [
                            _vm._v("本年度"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "自定义时间：",
                            "label-width": "120px",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "daterange",
                              size: "small",
                              "range-separator": "-",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              "picker-options": _vm.pickerOptions,
                            },
                            model: {
                              value: _vm.formTool.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.formTool, "date", $$v)
                              },
                              expression: "formTool.date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "btn-box" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                            },
                            [_vm._v("查 询")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.handleReset },
                            },
                            [_vm._v("重 置")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "right" },
                              attrs: { size: "small" },
                              on: { click: _vm.handleExport },
                            },
                            [_vm._v("导出")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "center",
                  style: _vm.contentHeight
                    ? `height:${_vm.contentHeight}px!important`
                    : "",
                },
                [
                  _c("MyTable", {
                    attrs: {
                      "table-data": _vm.tableData,
                      index: _vm.index,
                      selection: _vm.selection,
                      page: _vm.page,
                      operation: _vm.operation,
                      "table-option": _vm.tableOption,
                    },
                    on: {
                      "update:tableOption": function ($event) {
                        _vm.tableOption = $event
                      },
                      "update:table-option": function ($event) {
                        _vm.tableOption = $event
                      },
                      "page-change": _vm.handlePageChange,
                      "sort-change": _vm.sortChange,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "customer_price",
                        fn: function (scope) {
                          return [
                            scope.row.order_shop_cnt && scope.row.amount
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      parseFloat(
                                        scope.row.amount /
                                          scope.row.order_shop_cnt
                                      ).toFixed(2)
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "order_cvr",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.order_cvr
                                    ? parseFloat(
                                        scope.row.order_cvr * 100
                                      ).toFixed(4) + "%"
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "pay_cvr",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.pay_cvr
                                    ? parseFloat(
                                        scope.row.pay_cvr * 100
                                      ).toFixed(4) + "%"
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }