<template>
  <div class="classification"
       v-loading="loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="eachRow">
      <div class="titleView">
        <div class="rowLine"></div>
        <span class="eachRowTitle">SKU信息</span>
      </div>
      <el-divider></el-divider>
      <div v-if="form.status == 'SY_SOLDOUT'">
        <span>
          下架原因
        </span>
        <div style="padding: 10px 0;width: 600px;">
          <el-input type="textarea"
                    :value="form.checkReason"
                    disabled></el-input>
        </div>

      </div>
      <div v-if="form.status == 'FAIL'">
        <span>
          驳回原因
        </span>
        <div style="padding: 10px 0;">
          <el-input type="textarea"
                    v-model="form.rejectReason"
                    disabled></el-input>
        </div>

      </div>
      <el-row :gutter="30">
        <el-form ref="form"
                 :disabled="disabled"
                 :model="form"
                 :rules="rules"
                 label-position="top">
          <el-col :span="6">
            <el-form-item label="ERP编码">
              <el-input v-model="form.medicineCode"
                        size="small"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="teshu">
              <el-checkbox v-model="form.beSynPrice">自动同步价格（ERP系统对接后生效）</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="teshu">
              <el-checkbox v-model="form.beSynStock"
                           @change="changeBeSynStock">自动同步库存（ERP系统对接后生效）</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="teshu">
              <!-- <div class="siwtchView">
                 <el-switch
                  v-model="scope.row.status"
                  active-value="PUTAWAY"
                  inactive-value="SOLDOUT"
                  active-color="#67c23a"
                  inactive-color="#999"
                  active-text="上架"
                  inactive-text="下架"
                  class="switchStyle"
                  v-if="scope.row.productLineId"
                  @change="changeStatus(scope.row, scope.$index)"
                />
            </div>  -->
              <div class="siwtchView">
                <el-switch size="small"
                           :disabled="form.status == 'AWAIT' || form.status == 'FAIL' "
                           class="switchStyle"
                           v-model="shangxiajia"
                           @change="change"
                           active-color="#13ce66"
                           inactive-color="#ff4949"
                           active-text="上架"
                           inactive-text="下架">
                </el-switch>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="价格/元"
                          prop="salePrice">
              <el-input v-model="form.salePrice"
                        :disabled="form.beSynPrice"
                        type="number"
                        size="small"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="市场价/元">
              <el-input v-model="form.marketPrice"
                        :disabled="form.beSynPrice"
                        type="number"
                        size="small"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="建议零售价/元">
              <el-input v-model="form.purchasePrice"
                        :disabled="form.beSynPrice"
                        type="number"
                        size="small"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="远效期至">
              <el-date-picker v-model="form.newExpireTime"
                              style="width: 100%;"
                              size="small"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              type="datetime"
                              placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item label="有效期至"
                          prop="expireTime">
              <el-date-picker v-model="form.expireTime"
                              style="width: 100%;"
                              size="small"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              type="datetime"
                              placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="生产日期"
                          prop="producedTime">
              <el-date-picker v-model="form.producedTime"
                              style="width: 100%;"
                              size="small"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              type="datetime"
                              placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="起批量"
                          prop="startWholesale">
              <el-input v-model="form.startWholesale"
                        type="number"
                        size="small"
                        placeholder="请输入"
                        style="width: calc(100% - 100px);"></el-input>
              <el-checkbox v-model="form.useTimes"
                           style="margin-left:16px">倍数购买</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="单位"
                          prop="goodsSkuUnit">
              <el-select v-model="form.goodsSkuUnit"
                         style="width: 100%;"
                         size="small"
                         placeholder="请选择">
                <el-option v-for="item in options"
                           :key="item"
                           :label="item"
                           :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="总库存"
                          prop="goodsSkuInventory">
              <el-input v-model="form.goodsSkuInventory"
                        :disabled="form.beSynStock"
                        type="number"
                        size="small"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="单个药店采购上限">
              <el-input v-model="form.purchaseUpperLimit"
                        :disabled="purchaseUpperLimit"
                        type="number"
                        size="small"
                        placeholder="请输入"
                        style="width: calc(100% - 75px);"></el-input>
              <el-checkbox v-model="purchaseUpperLimit"
                           @change="changeBox"
                           style="margin-left:16px">不限</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="上限刷新周期">
              <el-input v-model="form.upperLimitRefreshCycle"
                        type="number"
                        size="small"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="上限启用日期">
              <el-date-picker v-model="form.upperLimitStartTime"
                              style="width: 100%;"
                              size="small"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              type="datetime"
                              placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="装箱量">
              <el-input v-model="form.goodsSkuBox"
                        type="number"
                        size="small"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="商品标题"
                          prop="goodsSkuTitle">
              <el-input v-model="form.goodsSkuTitle"
                        size="small"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="SKU名称">
              <el-input v-model="form.goodsSkuName"
                        size="small"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="产品批号">
              <el-input v-model="form.batchNumber"
                        onkeyup="this.value=this.value.replace(/[^\w_]/g,'');"
                        size="small"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div style="display: flex;">
        <div style="display: flex;">
          <span style="font-size: 14px;margin-top: 10px;">批件/药检报告等：</span>
          <el-upload class="upload-demo"
                     :disabled="disabled"
                     action="https://gateway.cbyyk.com/product/goodsbase/upload"
                     multiple
                     :on-preview="preview"
                     :on-remove="handleChange"
                     :on-success="successUpload"
                     :on-error="errorUpload"
                     accept=".jpg,.jpeg,.png,.pdf"
                     :file-list="fileList">
            <el-button size="small"
                       type="primary">点击上传</el-button>
            <div slot="tip"
                 class="el-upload__tip">上传pdf或图片，且不超过10M</div>
          </el-upload>
        </div>
        <div style="display: flex; margin-left: 40px;">
          <span style="font-size: 14px;margin-top: 10px;">商品图片：</span>
          <div style="padding-top:8px">
            <el-radio-group v-model="imgRegion"
                            :disabled="disabled">
              <el-radio :label="1">使用平台图片</el-radio>
              <el-radio :label="2">上传图片</el-radio>
            </el-radio-group>
            <imgUpload v-model="imageList"
                       :isEdit="disabled"
                       v-show="imgRegion == 2"
                       style="position:relative;top:0px;">
              <template slot="tip">
                <p class="el-upload__tip"
                   style="margin:10px 0 14px">
                  备注:图片格式为jpg或png，建议尺寸800*800，图片大小不得超过2M;
                </p>
              </template>
            </imgUpload>
          </div>
        </div>
      </div>

      <div style="margin-top:10px">
        <el-button v-show="!disabled"
                   type="primary"
                   style="width:160px"
                   size="small"
                   @click="submit">保存并提交</el-button>
        <el-button size="small"
                   style="width:160px"
                   @click="back()">返回</el-button>
      </div>
      <el-image-viewer v-if="showViewer"
                       :on-close="closeViewer"
                       :url-list="[url]" />
    </div>
  </div>
</template>
<script>
import imgUpload from '@/components/imgUpload'
import { getunitList } from '@/api/productManage/auditProduct'
import { skuUpdate, skuAdd, skuId } from '@/api/commodityList/index'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components: { imgUpload, ElImageViewer },
  data () {
    return {
      showViewer: false,
      url: '',
      loading: false,
      id: '',
      name: '',
      disabled: false,
      fromPage: '',
      options: [],
      shangxiajia: false,
      purchaseUpperLimit: false,
      activeName: '',
      imgRegion: 1,
      imageList: [],
      fileList: [
      ],
      form: {
        batchNumber: '',
        goodsSkuName: '',
        purchasePrice: '',
        salePrice: '',
        marketPrice: '',
        startWholesale: '',
        goodsSkuName: '',
        goodsSkuUnit: '',
        goodsSkuInventory: '',
        newProducedTime: '',
        newExpireTime: '',
        expireTime: '',
        producedTime: '',
        useTimes: '',
        goodsSkuBox: '',
        status: '',
        beSynPrice: false,
        beSynStock: false,
        goodsSkuFileList: [],
        purchaseUpperLimit: '',
        upperLimitRefreshCycle: '',
        upperLimitStartTime: '',
        goodsSkuTitle: '',
        goodsSkuImage: '',
        goodsSkuImageList: '',
      },
      rules: {
        goodsSkuUnit: [
          { required: true, message: '请选择单位', trigger: 'change' }
        ],
        salePrice: [
          { required: true, message: '请填写价格', trigger: 'blur' }
        ],
        goodsSkuInventory: [
          { required: true, message: '请填写库存', trigger: 'blur' }
        ],
        expireTime: [
          { required: true, message: '请选择近效期', trigger: 'change' }
        ],
        startWholesale: [
          { required: true, message: '请填写起批量', trigger: 'blur' }
        ],
        producedTime: [
          { required: true, message: '请选择最老生产日期', trigger: 'change' }
        ],
        goodsSkuTitle: [
          { required: true, message: '请填写商品标题', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getunitList()
    this.activeName = this.$route.query.activeName
    this.name = this.$route.query.name
    this.id = this.$route.query.id
    this.fromPage = this.$route.query.fromPage
    if (this.fromPage == 'edit') {
      this.query()
    } else if (this.fromPage == 'detail') {
      this.query()
      this.disabled = true
    } else {
      this.form.goodsSkuTitle = this.name
    }
  },
  mounted () {
    console.log(this.$route.query);
  },
  methods: {
    // 获取单位字典
    getunitList () {
      getunitList().then(res => {
        if (res.code == 0) {
          this.options = res.data || []
        }
      })
    },
    // 点击已上传的文件
    preview (file) {
      if (file.url.indexOf('.pdf') > -1 || file.url.indexOf('.PDF') > -1) {
        window.open(file.url)
      } else {
        this.url = file.url
        this.showViewer = true
      }
    },
    // 关闭弹窗
    closeViewer () {
      this.showViewer = false
    },
    // 改变文件列表
    handleChange (file, fileList) {
      this.fileList = fileList
    },
    // 上传成功
    successUpload (response, file, fileList) {
      let obj = {
        name: response.data.fileName,
        url: response.data.url,
      }
      this.fileList.push(obj)
    },
    // 上传失败
    errorUpload (err, file, fileList) {
    },
    // 上下架
    change (val) {
      if (val) {
        this.form.status = 'PUTAWAY'
      } else {
        this.form.status = 'SOLDOUT'
      }
    },
    // 上下限
    changeBox (val) {
      if (val) {
        this.form.purchaseUpperLimit = 0
      } else {
        this.form.purchaseUpperLimit = ''
      }
    },
    // 同步库存
    changeBeSynStock () {
      // if (val) {
      //   this.form.purchaseUpperLimit = 0
      // } else {
      //   this.form.purchaseUpperLimit = ''
      // }
    },
    // 保存并提交
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          let params = {
            goodsId: this.id ? this.id : '',
            ...this.form
          }
          if ((new Date(params.producedTime)).getTime() > (new Date(params.expireTime)).getTime()) {
            this.$message.warning('生产日期大于有效期，请重新选择')
            this.loading = false
            return
          }
          if (this.imgRegion == 2 && this.imageList.length == 0) {
            this.$message.warning('请上传图片')
            this.loading = false
            return
          }
          if (this.imgRegion == 2) {
            params.goodsSkuImage = this.imageList[0]
            params.goodsSkuImageList = this.imageList ? this.imageList.join(',') : ''
          } else {
            params.goodsSkuImage = ''
            params.goodsSkuImageList = ''
          }
          params.goodsSkuFileList = this.fileList.length > 0 ? JSON.stringify(this.fileList) : ''
          if (this.fromPage == 'add') {
            skuAdd([params]).then(res => {
              if (res.code == 0) {
                this.loading = false
                this.$message.success('新增成功')
                this.$router.back(-1)
              } else {
                this.loading = false
                this.$message.error('新增失败')
              }
            }).catch(err => {
              this.loading = false
            })
          } else {
            skuUpdate(params).then(res => {
              if (res.code == 0) {
                this.loading = false
                this.$message.success('保存成功')
                this.$router.back(-1)
              } else {
                this.loading = false
                this.$message.error('保存失败')
              }
            }).catch(err => {
              this.loading = false
            })
          }
        }
      })
    },
    // 返回
    back () {
      this.$router.back(-1)
    },
    // 查询sku信息
    query () {
      skuId(this.id).then(res => {
        if (res.code == 0) {
          this.form = res.data
          this.$set(this.form, 'expireTime', res.data.expireTime ? res.data.expireTime : '')
          this.$set(this.form, 'producedTime', res.data.producedTime ? res.data.producedTime : '')
          // this.form.goodsSkuTitle = this.name
          this.imageList = res.data.goodsSkuImageList ? res.data.goodsSkuImageList.split(',') : []
          this.imgRegion = res.data.goodsSkuImageList ? 2 : 1
          this.fileList = res.data.goodsSkuFileList ? JSON.parse(res.data.goodsSkuFileList) : []
          this.shangxiajia = res.data.status == 'PUTAWAY' ? true : false
        } else {
          this.$message.error(res.msg)
        }
      })

    }
  }
}
</script>
<style lang="scss">
.siwtchView {
  .switchStyle {
    height: 22px;
    line-height: 22px;
  }

  .el-switch__label {
    width: 65px !important;
    height: 20px !important;
    position: absolute;
    left: 0px !important;
    border-radius: 15px;
    display: none;
    color: #fff !important;

    &--left {
      z-index: 9;
      left: 25px !important;
    }

    &--right {
      z-index: 9;
      // left: -20px !important;
    }

    &.is-active {
      display: block;
    }
  }

  .el-switch__core {
    width: 65px !important;
    height: 20px !important;
    border-radius: 15px;

    &::after {
      // top: 4px;
      // left: 5px;
      // width: 20px;
      // height: 20px;
      // margin-left: -8px;
      border-radius: 10px;
    }
  }

  .is-checked {
    .el-switch__core {
      &::after {
        left: 55px !important;
        margin-left: -8px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.teshu {
  position: relative;
  top: 20px;
}
::v-deep .el-form-item {
  height: 62px;
  .el-form-item__content {
    // height: 100%;
  }
}
.eachRow {
  background-color: #fff;
  border-radius: 4px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
}

.titleView {
  display: flex;
  align-items: center;

  .rowLine {
    width: 4px;
    border-color: 1px;
    background-color: var(--main-color);
    height: 16px;
    margin-right: 8px;
  }

  .eachRowTitle {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
  }
}

.goodsInfoView {
  display: flex;
  align-items: center;
  padding: 15px;
  margin-top: 30px;
  position: relative;

  .detailBtn {
    position: absolute;
    top: 0px;
    right: 15px;
  }

  img {
    width: 130;
    height: 130px;
  }

  .goodsInfoText {
    font-size: 14px;
    color: #262626;
    display: flex;
    flex-direction: column;
    height: 130px;
    justify-content: space-between;
    margin-left: 45px;

    .textInLine {
      display: flex;
      align-items: center;

      .textBetween {
        width: 70px;
        text-align-last: justify;
        margin-right: 50px;
      }
    }
  }
}

.fillView {
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>