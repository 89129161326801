<template>
  <div id="app">
    <router-view />
    <recallPop ref="recallPop"/>
  </div>
</template>

<script>
import { getToken, removeToken } from "@/utils/auth"; // get token from cookie

import recallPop from "@/components/messageDialog/recallPop"; 
export default {
  components: { recallPop },
  name: 'App',
  data() {
    return { dialogVisible: true }
  },
  // 监听,当路由发生变化的时候执行
watch: {
  $route: {
    handler: function(val, oldVal){
     
       if(getToken()){
        // console.log('123111') 
        this.$refs['recallPop'].getRecallList() 
      } 
    },
    // 深度观察监听
    deep: true
  }
},
  mounted() { 
    // console.log('123')
    //  this.$refs['recallPop'].getRecallList() 
   },
  methods: { 
  }
}
</script>
