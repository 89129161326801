var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loginMain" },
    [
      _vm._m(0),
      _vm.taxInfo && _vm.taxInfoShow
        ? _c(
            "div",
            { staticClass: "formView" },
            [
              _c(
                "el-form",
                {
                  ref: "loginForm",
                  attrs: {
                    model: _vm.loginForm,
                    "label-width": "100px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "formLabel",
                      attrs: { label: "销售方税号" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "formInput",
                        attrs: {
                          placeholder: "请输入",
                          size: "small",
                          disabled: "",
                        },
                        model: {
                          value: _vm.taxInfo.sellerTaxpayerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.taxInfo, "sellerTaxpayerId", $$v)
                          },
                          expression: "taxInfo.sellerTaxpayerId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "formLabel",
                      attrs: { label: "用户名", prop: "account" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "formInput",
                        attrs: { placeholder: "请输入", size: "small" },
                        model: {
                          value: _vm.loginForm.account,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "account", $$v)
                          },
                          expression: "loginForm.account",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "formLabel",
                      attrs: { label: "密码", prop: "password" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "formInput",
                        attrs: {
                          placeholder: "请输入",
                          size: "small",
                          "show-password": "",
                        },
                        model: {
                          value: _vm.loginForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "password", $$v)
                          },
                          expression: "loginForm.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "formLabel",
                      attrs: { label: "责任人类型", prop: "personType" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "formInput",
                          attrs: { size: "small" },
                          model: {
                            value: _vm.loginForm.personType,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "personType", $$v)
                            },
                            expression: "loginForm.personType",
                          },
                        },
                        _vm._l(_vm.personTypeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.codeText,
                              value: item.codeValue,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "formButton",
                  staticStyle: {
                    width: "calc(20vw + 100px)",
                    "margin-top": "30px",
                  },
                  attrs: {
                    type: "primary",
                    loading: _vm.loginStatus == "logining",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.login()
                    },
                  },
                },
                [_vm._v("验证并登录")]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.taxInfo && _vm.taxInfoShow
        ? _c(
            "div",
            { staticClass: "unAuthView" },
            [
              _c("img", {
                staticStyle: { width: "35vw", height: "100%" },
                attrs: { src: _vm.unAuth, alt: "" },
              }),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.goAuth } },
                [_vm._v("跳转至“数电配置”菜单")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.codeDialogShow,
            title: "短信验证",
            width: "30%",
            "before-close": _vm.codeDialogWillDismiss,
            "append-to-body": "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "codeDialogContainer" },
            [
              _c("div", { staticClass: "warView" }, [
                _c("i", {
                  staticClass: "el-icon-warning",
                  staticStyle: {
                    color: "#f99b0d",
                    "margin-right": "10px",
                    "margin-top": "3px",
                  },
                }),
                _c("span", [
                  _vm._v(
                    "为了您的帐号安全和信用安全，请进行安全验证。验证码将以短信形式发送至电子税务局绑定的手机号."
                  ),
                ]),
              ]),
              _c("div", { staticStyle: { height: "24px" } }),
              _c(
                "el-form",
                [
                  _c("el-form-item", { attrs: { label: "验证码" } }, [
                    _c(
                      "div",
                      { staticClass: "rowInLine" },
                      [
                        _c("el-input", {
                          staticStyle: { wdith: "100px" },
                          attrs: { placeholder: "请输入", size: "small" },
                          model: {
                            value: _vm.code,
                            callback: function ($$v) {
                              _vm.code = $$v
                            },
                            expression: "code",
                          },
                        }),
                        _c("div", { staticStyle: { width: "24px" } }),
                        _vm.sendStatus == "init"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.acquire },
                              },
                              [_vm._v("发送")]
                            )
                          : _vm.sendStatus == "sending"
                          ? _c(
                              "el-statistic",
                              {
                                attrs: {
                                  value: _vm.deadline2,
                                  format: "ss",
                                  "time-indices": "",
                                },
                                on: { finish: _vm.finish },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v(" 秒后重发 "),
                                ]),
                              ],
                              2
                            )
                          : _vm.sendStatus == "reSend"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.acquire },
                              },
                              [_vm._v("重新发送")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c("div", { staticStyle: { height: "24px" } }),
              _c(
                "div",
                { staticClass: "btnView" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", plain: "", size: "large" },
                      on: { click: _vm.codeDialogWillDismiss },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "large" },
                      on: { click: _vm.varifyCode },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.taxInfo
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.qrcodeDialogShow,
                title: "身份认证",
                width: "25%",
                "before-close": _vm.qrcodeDialogWillDismiss,
                "append-to-body": "",
              },
            },
            [
              _c(
                "div",
                { staticClass: "codeDialogContainer" },
                [
                  _c("div", { staticClass: "warView" }, [
                    _c("i", {
                      staticClass: "el-icon-warning",
                      staticStyle: {
                        color: "#f99b0d",
                        "margin-right": "10px",
                        "margin-top": "3px",
                      },
                    }),
                    _c("span", [
                      _vm._v("请使用税务APP扫描下方二维码进行身份认证。"),
                    ]),
                  ]),
                  _c("div", { staticStyle: { height: "10px" } }),
                  _c(
                    "el-form",
                    {
                      attrs: {
                        "label-width": "100px",
                        "label-position": "left",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "销方名称" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              size: "small",
                              disabled: "",
                            },
                            model: {
                              value: _vm.taxInfo.sellerName,
                              callback: function ($$v) {
                                _vm.$set(_vm.taxInfo, "sellerName", $$v)
                              },
                              expression: "taxInfo.sellerName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "电子税局帐号" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              size: "small",
                              disabled: "",
                            },
                            model: {
                              value: _vm.taxInfo.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.taxInfo, "mobile", $$v)
                              },
                              expression: "taxInfo.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", {
                    ref: "qrcode",
                    staticClass: "qrcodeImg",
                    attrs: { id: "qrcode" },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "freshView",
                      on: { click: _vm.qrcodeDialogWillShow },
                    },
                    [
                      _c("i", { staticClass: "el-icon-refresh" }),
                      _c("span", [_vm._v("刷新二维码")]),
                    ]
                  ),
                  _c("div", { staticClass: "freshView" }, [
                    _c("span", [
                      _vm._v(
                        "可将二维码图片发给认证人员进行身份认证，手机端完成认证后自动同步认证结果，开展后续数电业务操作"
                      ),
                    ]),
                  ]),
                  _c("div", { staticStyle: { height: "20px" } }),
                  _c(
                    "div",
                    { staticClass: "btnView" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "default", plain: "", size: "large" },
                          on: { click: _vm.qrcodeDialogWillDismiss },
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "large" },
                          on: { click: _vm.varifyScan },
                        },
                        [_vm._v("我已扫码")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "warView" }, [
      _c("i", {
        staticClass: "el-icon-warning",
        staticStyle: {
          color: "#f99b0d",
          "margin-right": "10px",
          "margin-top": "3px",
        },
      }),
      _c("span", [
        _vm._v(
          "本平台发票管理为全电发票管理，包括增值税专用发票、普通发票，是全面数字化的发票，是与纸质发票具有同等法律效力的全新发票，不以纸质形式存在、不用介质支撑、不需申请领用。电子发票将纸质发票的票面信息全面数字化，通过标签管理将多个票种集成归并为电子发票单一票种，设立税务数字账户，实现全国统一赋码、智能赋予发票开具金额总额度、自动流转交付。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }