<template>
  <div class="comContent"> 
    <div class="centent">
      <FillProductInfoView @preStep="handlePreStep" />
    </div>
  </div>
</template>

<script>
 
import FillProductInfoView from "./children/fillProductInfoDetail.vue";

export default {
  components: { 
    FillProductInfoView,
  },
  data() {
    return {
      stepList: [
        { name: "活动类型" },
        { name: "选择商品" },
        { name: "填写商品信息" },
      ],
      localCurrentIndex: 1,
    };
  },
  computed: {
    //
  },
  watch: {},
  mounted() {
    this.initViews();
  },
  methods: {
    initViews() {
      let publicConfig = this.$store.state.publicGoods.publicConfig;
      if (publicConfig) {
        if (publicConfig.currentStep) {
          this.localCurrentIndex = publicConfig.currentStep;
        }
      }
    },
    handlePreStep() {
      this.localCurrentIndex--;
     // this.$store.dispatch("publicGoods/setStep", this.localCurrentIndex);
    },
     
  },
};
</script>
<style lang="scss" scoped>
.comContent {
  display: flex;
  flex-direction: column;
  padding: 0 0 24px;
}
.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  box-sizing: border-box;
  border-bottom: 1px solid #f5f5f5;

  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }

  .step-frame {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}

.centent {
  flex: 1;
  height: 0;
  overflow-y: auto;
  padding: 0 24px;
  box-sizing: border-box;
}
</style>
