<template>
    <div class="dialog">
        <el-dialog title="创建合作控销产品" :visible.sync="visible" :lock-scroll="true"  width="818px" 
            :before-close="handleClose">
            <div v-if="goodsList && goodsList.length > 0">
                <goodsItem :goods-list="goodsList" @chooseItem="chooseItem" />
            </div>
             <div class="btnBox">
              <el-button size="small" @click="cancel">取消</el-button>
              <el-button type="primary" size="small" @click="chooseGoods">确认</el-button
              >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import goodsItem from "./cooperationControlSalesGoodsItem.vue";
import { getSelfControllerGoodsList } from "@/api/shopmange";
export default {
    components:{
        goodsItem
    },
    props: {
        dialogShow:{
            type:Boolean,
            default:false
        }
    },
    computed:{
        visible(){
            return this.dialogShow
        }
    },
    data() {
        return {
            goodsList:[],
            selectItems:[]
        }
    },
    created() {
        this.getGoods();
    },
    methods: {
        getGoods() {
            const params = {};
            getSelfControllerGoodsList(params).then((res) => {
                if (res && res.length) {
                    this.goodsList = res
                }
            });
        },
        handleClose() {
            this.$parent.dialogShow = false;
        },
        chooseItem(arr) {
            this.selectItems = arr
        },
        cancel(){
            this.$parent.dialogShow = false;
        },
        chooseGoods(){
            if (!this.selectItems.length) {
                this.$message.warning('请选择控销产品')
                return
            }
            this.$emit('didSelectItem',this.selectItems)
        }
    }
}
</script>
<style lang="scss">
    .btnBox{
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }
</style>