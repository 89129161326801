<template>
  <div class="contentCont">
    <MyTable
      :table-data="tableData"
      :index="index"
      :selection="selection"
      :page="page"
      :operation="operation"
      :showpage="false"
      :table-option.sync="tableOption"
      @page-change="getList"
    >
      <template slot="menu" slot-scope="scope">
        <el-link
          type="primary"
          :underline="false"
          @click="detailBtn(scope.row)"
        >
          详情
        </el-link>
        <el-link
          type="primary"
          style="margin-left: 10px"
          :underline="false"
          @click="editorBtn(scope.row)"
        >
          修改
        </el-link>
        <el-link
          type="primary"
          style="margin-left: 10px"
          :underline="false"
          @click="deleteBtn(scope.row)"
        >
          删除
        </el-link>
      </template>
      <template slot="feeType" slot-scope="scope">
        <span>{{ scope.row.feeType ? "自定义运费" : "商家承担运费" }}</span>
      </template>
      <template slot="carriageAmount" slot-scope="scope">
        <span>{{ scope.row.feeType ? scope.row.carriageAmount : ''  }}</span>
      </template>
      <template slot="freeShippingOrderAmount" slot-scope="scope">
        <span>{{ scope.row.feeType ? scope.row.freeShippingOrderAmount : '' }}</span>
      </template>
      <template slot="minOrderAmount" slot-scope="scope">
        <span>{{ scope.row.feeType ? scope.row.minOrderAmount : '' }}</span>
      </template>
    </MyTable>
  </div>
</template>
<script>
import MyTable from "@/components/myTable";
import {
  getShopExpressFees,
  getShopExpressFee,
  deleteShopExpressFee,
} from "@/api/shopmange/index";
export default {
  components: { MyTable },
  data() {
    return {
      operation: false,
      selection: false,
      index: false,
      tableData: [],
      tableOption: [
        { label: "运费名称", prop: "feeName", width: "180px" },
        { label: "发货物流", prop: "deliveryLogisticsName", width: "120px" },
        {
          label: "起配金额",
          prop: "minOrderAmount",
          width: "120px",
          slot:true
        },
        {
          label: "包邮金额",
          prop: "freeShippingOrderAmount",
          width: "120px",
          slot:true

        },
        { label: "运费", prop: "carriageAmount", width: "120px" , slot:true
},

        { label: "更新人", prop: "updateUserName", width: "120px" }, // 这里表示自定义列, slot: true
        { label: "更新时间", prop: "updateTime", width: "160px" },
        {
          label: "操作",
          prop: "menu",
          slot: true,
          width: "200px",
          align: "center",
        },
      ],
      page: {
        total: 10,
        current: 1,
        size: 10,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    detailBtn(row) {
      getShopExpressFee({ id: row.id }).then((res) => {
        // console.log(res);
        res.type = "detail";
        this.$parent.getFreightDetail(res);
      });
    },
    editorBtn(row) {
      getShopExpressFee({ id: row.id }).then((res) => {
        res.type = "editor";
        this.$parent.getFreightDetail(res);
      });
    },
    getList(val) {
      getShopExpressFees(val ?? {}).then((res) => {
        this.tableData = res ?? [];
      });
    },
    //删除按钮
    deleteBtn(row) {
      this.$confirm("此操作将删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteShopExpressFee({ id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.contentCont {
  height: calc(100% - 33px);
  overflow: auto;
  padding: 24px;
  padding-top: 0px;
}
</style>
