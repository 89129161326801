import request from '@/utils/request'
import { Message } from 'element-ui'
// 获取订单商品详情
export function goodorderDetail(orderId) {
  return request({
    url: `/order/order/purchase/detail/${orderId}`,
    method: 'get'
  })
}

// 支付订单
export function payPurchase(data) {
  return request({
    url: '/order/order/purchase/pay/' + data,
    method: 'get'
  })
}

// 查询所有订单的
export function buyPageList(data) {
  return request({
    url: `/order/order/purchase/mepur/page`,
    method: 'get',
    params: data
  })
}

// 取消订单
export function cancelPurchase(data) {
  return request({
    url: '/order/aftersale/cancel/' + data,
    method: 'get'

  })
}

// 订单退款
export function returnPurchase(data) {
  return request({
    url: '/order/order/sale/return/' + data,
    method: 'get'
  })
}

// 获取订单支付状态
export function payResult(data) {
  return request({
    url: `/order/order/purchase/pay/result/${data}`,
    method: 'get'
  })
}

// 发票抬头
export function getInvoiceHeader(data) {
  return request({
    url: `/invoice/buyInfo/get`,
    method: 'get',
    params: data
  })
}

// 确定申请开票
export function riseOpen(data) {
  return request({
    url: `/order/invoice/rise/open`,
    method: 'post',
    data
  })
}

// 通过id查询详情（采购订单）
export function getPurchaseDetail(data) {
  return request({
    url: '/order/order/purchase/' + data,
    method: 'get'
  })
}
// 通过id查询详情（销售订单）
export function getPurchaseDetailSaleInfo(data) {
  return request({
    url: '/order/order/purchase/sale/info/' + data,
    method: 'get'
  })
}
// 快递查询
export function getKuaidi(data) {
  return request({
    url: `/order/kuaidi/page`,
    method: 'get',
    params: data
  })
}

// 获取快递信息
export function expressStep(data) {
  return request({
    url: '/order/express/step',
    method: 'get',
    params: data
  })
}

// 销售:快递信息填入
export function saleKuaidi(data) {
  return request({
    url: `/order/order/sale/kuaidi`,
    method: 'post',
    data
  })
}

// 给对应的商户开票
export function surePrint(data) {
  return request({
    url: `/order/invoice/print`,
    method: 'post',
    data
  })
}

// 已收服务费退货
export function purchaseRefundPage(data) {
  return request({
    url: `/order/order/purchase/refund/page`,
    method: 'get',
    params:data
  })
}

// 确认收货
export function saleFinish(data) {
  return request({
    url: `/order/order/sale/finish`,
    method: 'post',
    data
  })
}

// 查看发票
export function invoiceRecord(orderId) {
 
  return request({
    url: `/invoice/invoiceRecord/${orderId}`,
    method: 'get',
   
    responseType: 'blob'
  }).then(response => {
    console.log(response,'res')
    // 处理返回的文件流
    const blob = response.data
    if ((blob && blob.size === 0) || response.size == 0) {
      Message.warning('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    const contentDisposition = response.headers['content-disposition'];
    const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1));
    link.download = fileName;
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  })
}


// getDownloadUrl 查看合同

export function getDownloadUrl(data) {
 
  return request({
    url: `/invoice/faddContract/getDownloadUrl`,
    method: "get",
    params: data
});
}

// 自主配送填入
export function delivery(data) {
  return request({
    url: `/order/order/sale/delivery`,
    method: 'post',
    data
  })
}

// 导出销售订单
export function exportTableData(data) {
  return request({
    url: `/order/order/purchase/mesale/export`,
    method: 'get',
    params:data,
    responseType: 'blob'
  }).then(response => {
    console.log(response,'res')
    // 处理返回的文件流
    const blob = response.data
    if ((blob && blob.size === 0) || response.size == 0) {
      Message.warning('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    const contentDisposition = response.headers['content-disposition'];
    const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1));
    link.download = fileName;
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  })
}

// 根据订单code查询评论列表
export function commentList(data) {
  return request({
      url: `/order/order/comment/list/${data.orderCode}`,
      method: 'GET', 
  })
}


// 销售:新增快递信息
export function saveExpress(data) {
  return request({
    url: `/order/expressDelivery/saveExpress`,
    method: 'post',
    data
  })
}
// 销售:更新物流信息 

export function updateExpress(data) {
  return request({
    url: `/order/expressDelivery/updateExpress`,
    method: 'post',
    data
  })
}

// 根据订单code查询评论列表
export function queryOrderExpressDeliveryListByOrderId(data) {
  return request({
      url: `/order/expressDelivery/queryOrderExpressDeliveryListByOrderId/${data.orderId}`,
      method: 'GET', 
  })
}


// 新增快递自营信息 /expressDelivery/saveSelfOwnedLogistics
export function saveSelfOwnedLogistics(data) {
  return request({
    url: `/order/expressDelivery/saveSelfOwnedLogistics`,
    method: 'post',
    data
  })
}