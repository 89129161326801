<template>
  <div class="oss-file-upload-root" :style="varStyle">
    <!-- 两个el-upload，去除 http-request 参数不同(因为这个参数拦截后就没法采用原有的上传)，其它参数保持完全一致 , 只会有一个存在-->
    <el-upload
      v-if="ossUpload"
      ref="refUpload"
      :class="{ 'oss-file-upload': true, disabled: disabled }"
      :auto-upload="autoUpload"
      :action="action"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :limit="limit"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :on-success="onSuccessUpload"
      :disabled="disabled"
      :accept="accept"
      :before-upload="onBeforeUpload"
      :on-change="handleVideoChange"
      :on-error="onError"
      :multiple="multiple"
      :list-type="listType"
      :http-request="onHttpRequest"
      ><div v-if="!disabled" style="display: inline-block">
        <el-button
          v-if="listType !== 'picture-card'"
          size="small"
          type="primary"
        >
          点击上传
        </el-button>
        <i v-else slot="default" class="el-icon-plus"></i>
      </div>
    </el-upload>
    <el-upload
      v-else
      ref="refUpload"
      :class="{ 'oss-file-upload': true, disabled: disabled }"
      :auto-upload="autoUpload"
      :action="action"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :limit="limit"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :on-success="onSuccessUpload"
      :disabled="disabled"
      :accept="accept"
      :before-upload="onBeforeUpload"
      :on-change="handleVideoChange"
      :on-error="onError"
      :multiple="multiple"
      :list-type="listType"
    >
      <div v-if="!disabled" style="display: inline-block">
        <el-button
          v-if="listType !== 'picture-card'"
          size="small"
          type="primary"
        >
          点击上传
        </el-button>
        <i v-else slot="default" class="el-icon-plus"></i>
      </div>
    </el-upload>

    <el-image-viewer
      class="my-preview-viewer"
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="urls"
    />
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import {
  OSSUpdate,
  base64ToFile,
  duration2str,
  getOssSrcFileName,
} from "@/utils/oss";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  name: "OssFileUpload",
  components: { ElImageViewer },
  props: {
    // 图片数据(图片url组成的数组) 通过v-model传递
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    listType: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 99,
    },
    accept: {
      type: String,
      default: "",
    },
    autoUpload: {
      type: Boolean,
      default: true,
    },
    maxFileLength: {
      type: Number,
      default: 0, //0:  <=0 不校验文件大小，否则就是限制的文件大小 以 M 为单位,
    },
    outAction: {
      type: String,
      default: "",
    },
    ossUpload: {
      type: Boolean,
      default: true,
    },
    ossPathDir: {
      type: String,
      default: "shop/upload/", //oss上传的文件存放路径
    },
    //要不要获取视频封面
    withCover: {
      type: Boolean,
      default: false,
    },
    /**listType="picture-card" 的时候,预览图片的大小支持外部设置 */
    picSize: {
      type: Number,
      default: 108,
    },
  },
  data() {
    let picSize = this.picSize;
    return {
      // action: "https://gateway.cbyyk.com/product/goodsbase/upload",
      action: process.env.VUE_APP_BASE_API + "/product/goodsbase/upload",

      ossUpdate: null,

      showViewer: false,
      urls: [],

      varStyle: {
        "--picSize": picSize + "px",
      },
    };
  },

  computed: {
    headers: function () {
      return {
        Authorization: "Bearer " + getToken(),
      };
    },
    fileList: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {},
  created() {
    if (this.outAction) {
      this.action = this.outAction;
    }
  },
  mounted() {
    //
  },

  methods: {
    init() {
      this.initOss();
    },
    release() {
      this.unInitOss();
    },
    initOss() {
      if (!this.disabled && this.ossUpload) {
        //不是禁止状态,可能会去上传文件
        this.ossUpdate = new OSSUpdate();
      }
    },
    unInitOss() {
      this.ossUpdate = null;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePreview(file) {
      if (file) {
        let url = file.url;
        if (
          !url &&
          file.response &&
          file.response.data &&
          file.response.data.url
        ) {
          url = file.response.data.url;
        }
        if (url) {
          if (this.listType === "picture-card") {
            this.urls = [url];
            this.showViewer = true;
          } else {
            window.open(url);
            //window.location.href = url;
          }
        }
      }
    },
    closeViewer() {
      this.showViewer = false;
      this.urls = [];
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeRemove(file, fileList) {
      if (file && file.raw && file.raw.invalid) {
        //无效文件,不需要弹窗，直接删除 invalid 在 onBeforeUpload 中赋值
        return true;
      }
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    onSuccessUpload(res, file, fileList) {
      if (res && res.code === 0 && res.data && res.data.url) {
        // let datas = res.data;
        // datas.name = res.data.fileName;
        /**res.data.fileName  服务器的文件名称，这里由由多段组成 - 分割的 */
        let fileName = getOssSrcFileName(res.data.fileName);
        file.name = fileName; //显示服务器端的名称

        this.fileList = fileList;
      } else {
        this.$message({ type: "error", message: res.msg });
      }
    },
    onError(err, file, fileList) {
      if (err && err.status && err.status != 200) {
        this.$message.error("上传失败");
      } else {
        this.$message.error("上传失败，网络错误！");
      }
    },
    onBeforeUpload(file) {
      if (this.accept && file && file.name) {
        const accept = this.accept.toLowerCase();
        const arr = file.name.split(".");
        if (arr && arr.length > 0) {
          const extName = "." + arr[arr.length - 1].toLowerCase();
          if (accept.indexOf(extName) === -1) {
            file.invalid = true;
            this.$message.error("请上传正确的类型文件！！");
            return false;
          }
        }
      }
      if (this.maxFileLength > 0 && file && file.size) {
        const maxSize = this.maxFileLength * 1024 * 1024; //byte
        if (file.size > maxSize) {
          file.invalid = true;
          this.$message.error(`文件不能超过${this.maxFileLength}M！！`);
          return false;
        }
      }
      return true;
    },
    onHttpRequest(option) {
      if (this.ossUpload) {
        const position = option.file.name.lastIndexOf(".");
        const srcExtName = option.file.name.substring(position + 1);
        const srcFileName = option.file.name.substring(0, position);
        const fileName =
          srcFileName +
          "_" +
          this.ossUpdate.getFileNameUUID() +
          "." +
          srcExtName;
        const fullFileName = this.ossPathDir + fileName;
        const e = { total: option.file.size, percent: 0 };
        this.ossUpdate
          .upload(fullFileName, option.file, {
            progress: function (percentage) {
              let percent = percentage * 100;

              if (e.total > 0) {
                e.percent = percent;
              }

              option.onProgress(e);
            },
            // partSize: 102400, // 指定上传的每个分片的大小，范围为100 KB~5 GB。单个分片默认大小为1 * 1024 * 1024（即1 MB）
          })
          .then((response) => {
            if (response && response.res && response.res.status === 200) {
              const res = response.res;
              const fullUrl = res.requestUrls[0];
              const pos = fullUrl.lastIndexOf("?");
              const url = pos >= 0 ? fullUrl.substring(0, pos) : fullUrl;
              let serveFileName = fileName;
              let position = url.lastIndexOf("/");
              if (position >= 0) {
                serveFileName = url.substring(position + 1);
              }

              //构建一个正常上传的响应
              const sResponse = {
                code: 0,
                msg: "success",
                data: {
                  fileName: serveFileName,
                  url: url,
                },
                success: true,
              };
              option.onSuccess(sResponse);
            } else {
              option.onError({ status: 201 });
            }
          })
          .catch((err) => {
            option.onError(err);
          });
      }
    },
    handleVideoChange(file) {
      if (
        this.withCover &&
        file &&
        file.raw.type === "video/mp4" &&
        (!file.surfaceDrawingUrl || !file.videoDuration)
      ) {
        this.getVideoInfo(file.raw).then((infoObj) => {
          if (infoObj) {
            file.videoDuration = infoObj.duration;
            file.surfaceDrawingUrl = infoObj.cover;
          }
        });
      }
    },
    getVideoInfo(file) {
      const _this = this;
      return new Promise((resolve, reject) => {
        const video = document.createElement("video");

        video.src = URL.createObjectURL(file);

        video.setAttribute("crossOrigin", "anonymous"); //处理跨域
        video.currentTime = 1;
        video.addEventListener("canplay", () => {
          const canvas = document.createElement("canvas");
          let clientWidth = video.videoWidth;
          let clientHeight = video.videoHeight;
          canvas.width = clientWidth;
          canvas.height = clientHeight;
          canvas
            .getContext("2d")
            .drawImage(video, 0, 0, clientWidth, clientHeight);
          const cover = canvas.toDataURL("image/png");

          const fileName = _this.ossUpdate.getFileNameUUID() + ".png";
          const coverFile = base64ToFile(cover, fileName);
          _this.uploadCoverFile(coverFile, fileName, (objRes) => {
            if (objRes.code === 0 && objRes.url) {
              const sduration = duration2str(video.duration);
              resolve({ duration: sduration, cover: objRes.url });
            }
            URL.revokeObjectURL(video.src); // 清除URL对象
          });
        });
      });
    },
    uploadCoverFile(file, fileName, cb) {
      const fullFileName = "shop/cover/" + fileName;
      this.ossUpdate
        .upload(fullFileName, file, {})
        .then((response) => {
          if (response && response.res && response.res.status === 200) {
            const res = response.res;
            const fullUrl = res.requestUrls[0];
            const pos = fullUrl.lastIndexOf("?");
            const url = pos >= 0 ? fullUrl.substring(0, pos) : fullUrl;
            console.log("cover:", url);
            cb && cb({ code: 0, url: url });
          } else {
            cb && cb({ code: 1 });
          }
        })
        .catch((err) => {
          cb && cb({ code: -1 });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.oss-file-upload-root {
  ::v-deep {
    .el-upload-list--picture-card {
      .el-upload-list__item {
        width: var(--picSize);
        height: var(--picSize);
      }
      //查看的情况下,不显示绿色勾
      &.is-disabled {
        .el-upload-list__item-status-label {
          display: none;
        }
      }
    }

    .el-upload {
      &.el-upload--picture-card {
        width: 108px;
        height: 108px;
        line-height: 108px;
      }

      &.el-upload--text {
        width: auto;
      }
    }
  }

  .oss-file-upload {
    &.disabled {
      ::v-deep .el-upload {
        display: none;
      }
    }
  }
}
</style>

<style lang="scss">
.el-image-viewer__wrapper.my-preview-viewer {
  z-index: 9999 !important;
}
</style>
