import request from "@/utils/request";

// 产品线列表
export function getProductLines(data) {
  return request({
    url: "/sales-rule/product/productLines/withGoods",
    method: "get",
    params: data,
  });
}

export function addProductLines(data) {
  return request({
    url: "/sales-rule/product/productLines",
    method: "post",
    data: data,
  });
}

//   控销分类列表
export function getClassLines(data) {
  return request({
    url: "/sales-rule/product/productLineGoodsClasss",
    method: "get",
    params: data,
  });
}

//   控销分类列表
export function getListNotBlank(data) {
  return request({
    url: "/sales-rule/product/productLineGoodsClasss/listNotBlank",
    method: "get",
    params: data,
  });
}


// 控销分类总数

export function getQuantity(data) {
  return request({
    url: "/sales-rule/product/productLineGoodsClasss/quantity",
    method: "get",
    params: data,
  });
}
//   控销分类新增
export function addClassLines(data) {
  return request({
    url: "/sales-rule/product/productLineGoodsClasss",
    method: "post",
    data: data,
  });
}

// deleteClassLines删除控销分类
export function deleteClassLines(data) {
  return request({
    url: `/sales-rule/product/productLineGoodsClasss/${data.id}`,
    method: "delete",
    // params: data
  });
}

// 修改（属性值为null时会清空数据）
export function editProductLineGoodsClasss(data) {
  return request({
    url: `/sales-rule/product/productLineGoodsClasss/${data.id}`,
    method: "PUT",
    data,
  });
}

// 团队地域
export function getAreaTree(data) {
  return request({
    url: `/shop/region/areaTree/${data.level}`,
    method: "get",
  });
}

// 地域价格
export function getAreaPriceTree(data) {
  return request({
    url: "/sales-rule/product/productLineAreaPrices",
    method: "get",
    params: data,
  });
}

// 产品线数量
export function getQuantityApi(data) {
  return request({
    url: "/sales-rule/product/productLines/quantity",
    method: "get",
    params: data,
  });
}

// 产品线修改
export function editProductLines(data) {
  return request({
    url: `/sales-rule/product/productLines/${data.id}`,
    method: "put",
    data: data,
  });
}

// 删除产品线
export function deleteProductLine(data) {
  return request({
    url: `/sales-rule/product/productLines/${data.id}`,
    method: "delete",
    params: data,
  });
}

// editProductLinesPrice 修改产品价格
export function editProductLinesPriceBatch(data) {
  return request({
    url: `/sales-rule/product/productLineAreaPrices/batch`,
    method: "post",
    data: data,
  });
}

// 卖家申请列表
export function getTeamApplys(data) {
  return request({
    url: "/sales-rule/team/teamApplys",
    method: "get",
    params: data,
  });
}

// 控销卖家详情页
export function getTeamDetail(data) {
  return request({
    url: `/sales-rule/team/teamApplys/${data.id}`,
    method: "get",
    // params: data
  });
}

// 卖家同意申请
export function teamApplysAgree(data) {
  return request({
    url: `/sales-rule/team/teamApplys/agree`,
    method: "put",
    data: data,
  });
}
// 卖家驳回申请

export function teamApplysReject(data) {
  return request({
    url: `/sales-rule/team/teamApplys/reject`,
    method: "put",
    data: data,
  });
}

//  控销买家申请列表
export function getTeamTerminalApplys(data) {
  return request({
    url: "/sales-rule/team/teamTerminalApplys",
    method: "get",
    params: data,
  });
}

// 控销买家详情页
export function getBuyerDetail(data) {
  return request({
    url: `/sales-rule/team/teamTerminalApplys/${data.id}`,
    method: "get",
    // params: data
  });
}

// 控销买家同意
export function teamTerminalApplysAgree(data) {
  return request({
    url: `/sales-rule/team/teamTerminalApplys/agree`,
    method: "put",
    data: data,
  });
}
// 控销买家驳回

export function teamTerminalApplysReject(data) {
  return request({
    url: `/sales-rule/team/teamTerminalApplys/reject`,
    method: "put",
    data: data,
  });
}

// 置顶
export function classsTop(id) {
  return request({
    url: `/sales-rule/product/productLineGoodsClasss/top/${id}`,
    method: "PATCH",
  });
}

// 置尾
export function classsTail(id) {
  return request({
    url: `/sales-rule/product/productLineGoodsClasss/tail/${id}`,
    method: "PATCH",
  });
}
// 交换
export function classsExchange(data) {
  return request({
    url: `/sales-rule/product/productLineGoodsClasss/exchange`,
    method: "POST",
    data,
  });
}

// 产品线负责人列表
export function getShopUserList(params) {
  return request({
    url: `member/info/shop/user/list`,
    method: "get",
    params,
  });
}

// 添加产品线负责人
export function manageAddUser(data) {
  return request({
    url: `/member/manage/add/user`,
    method: "POST",
    data,
  });
}

// 保存分账规则
export function ledgerRulesSave(data) {
  return request({
    url: `/product/ledgerRules/shop/save`,
    method: "POST",
    data,
  });
}

// 查看分账详情
export function getLedgerRulesDetail(data) {
  return request({
    url: `/product/ledgerRules/getShopLedgerRulesItems/${data.skuId}`,
    method: "get",
  });
}

// 商铺启停分账规则
export function changeLedgerRuleStatus(data) {
  return request({
    url: `/product/ledgerRules/shopChangeLedgerRuleStatus/${data.skuId}`,
    method: "delete",
  });
}

// 更新分账规则
export function ledgerRulesUpdate(data) {
  return request({
    url: `/product/ledgerRules/update`,
    method: "POST",
    data,
  });
}

// 查看分账详情
export function ledgerRulesCheckExits(data) {
  return request({
    url: `/product/ledgerRules/checkExits/${data.skuId}`,
    method: "get",
  });
}

// 获取分账类型
export function geCostType(data) {
  return request({
    url: `/product/ledgerRules/geCostType`,
    method: "get",
    data
  });
}

//unit获取单位字典

export function getUnit(params) {
  return request({
    url: `/product/stock/unit`,
    method: "get",
    params
  });
}

//  统一分账获取省地办 /dfc/unify/sdy

export function getAreaCompany(params) {
  return request({
    url: `/shop/dfc/unify/sdy/v2`,
    method: "get",
    params
  });
}


// /goodsbase/goods/self/{type} 获取自营商品列表
export function goodsBaseType(id) {
  return request({
    url: `/product/goodsbase/goods/self/${id}`,
    method: "GET",
  });
}



// 查询商品统一分账列表
// /product/productLineAreaPrices/getSelfPriceAndLedgerRule
export function getSelfPriceAndLedgerRule(data) {
  return request({
    url: `/sales-rule/product/productLineAreaPrices/getSelfPriceAndLedgerRule`,
    method: "POST",
    data,
  });
}

// 更新sku状态
export function skuUpdateStatus(params) {
  return request({
    url: `/product/goodsbase/skuStatus/${params.skuId}/${params.status}`,
    method: "PUT", 
  });
}

// 获取产品线价格列表
// /sales-rule/product/productLineAreaPrices/getProductLineSkuList
export function getProductLineSkuList(data) {
  return request({
    url: `/sales-rule/product/productLineAreaPrices/getProductLineSkuList`,
    method: "POST", 
    data,
  });
}
// 根据登录人获取所有地办
export function getOnlyAreaCompany() {
  return request({
    url: `/shop/dfc/unify/dz`,
    method: "GET",
  });
}
// 地区公司已分配(提供给统一分账使用)
export function getNoCheckShop(data) {
  return request({
    url: `/sales-rule/product/productLineAreaPrices/getNoCheckShop`,
    method: "GET",
    params:data,
  });
}
// 获取产品线组织价格分账sku信息
export function getOrgProductLineSkuList(data) {
  return request({
    url: `/sales-rule/product/productLineAreaPrices/getOrgProductLineSkuList`,
    method: "POST",
    data,
  });
}
// 店铺定价信息分页查询
export function getPageShopSkuPrice(data) {
  return request({
    url: `/sales-rule/product/productLineAreaPrices/pageShopSkuPrice`,
    method: "POST",
    data,
  });
}
// 批量新增组织店铺定价和sku
export function addShopPriceSkuByProvince(data) {
  return request({
    url: `/sales-rule/product/productLineAreaPrices/addShopPriceSku`,
    method: "POST",
    data,
  });
}
// 批量新增自营商品店铺定价和分账
export function shopSelfBatchByProvince(data) {
  return request({
    url: `/sales-rule/product/productLineAreaPrices/shopSelfBatch`,
    method: "POST",
    data,
  });
}

// /merchant/listByShopId
export function getListByShopId(params) {
  return request({
    url: `/shop/merchant/listByShopId`,
    method: "get", 
    params:params
  });
}
// ******************** 商户 pc 发布合作产品分页列表*********************************
export function querySellerCooperationProductPage(data) {
  return request({
    url: `/sales-rule/plc/plcBagSaleRelations/querySellerCooperationProductPage`,
    method: "POST",
    data,
  });
}
// ******************* 卖家向代理申请合作 **********
export function sellerApplyCooperation(data) {
  return request({
    url: `/sales-rule/plc/plcBagSaleRelations/sellerApplyCooperation`,
    method: "POST",
    data,
  });
}
// 商品PC-合作产品列表分页列表信息
export function querySellerCooperativeProductPage(data) {
  return request({
    url: `/sales-rule/plc/plcBagSaleRelations/querySellerCooperativeProductPage`,
    method: "POST",
    data,
  });
}
// 商家撤回分销商的申请
export function plcBagSaleRelationsRevocation(data) {
  return request({
    url: `/sales-rule/plc/plcBagSaleRelations/revocation?id=${data.id}`,
    method: "POST",
    data,
  });
}
// 商家查询合作产品销售控制区域
export function querySaleAreaListBySalePlanId(salePlanId) {
  return request({
    url: `/sales-rule/plc/plcBagSaleAreas/querySaleAreaList/${salePlanId}`,
    method: "GET",
  });
}
// 商家修改合作产品销售控制区域
export function editSaleAreaStatus(data) {
  return request({
    url: `/sales-rule/plc/plcBagSaleAreas/editSaleAreaStatus`,
    method: "POST",
    data,
  });
}
// 代理控销 sku 上架
export function agencyControlPinSkuPutaway(data) {
  return request({
    url: `/product/sales/sku/agencyControlPinSkuPutaway`,
    method: "POST",
    data,
  });
}
// 控销 sku 下架
export function agencyControlPinSkuSoldout(data) {
  return request({
    url: `/product/sales/sku/agencyControlPinSkuSoldout`,
    method: "POST",
    data,
  });
}
