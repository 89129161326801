<template>
  <div class="companyWarp">
    <div class="formView">
      <el-form
        ref="loginForm"
        :model="loginForm"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item label="销售方税号" class="formLabel">
          <el-input
            v-model="loginForm.sellerTaxpayerId"
            placeholder="请输入"
            class="formInput"
            size="small"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="用户名" class="formLabel" prop="account">
          <el-input
            v-model="loginForm.account"
            placeholder="请输入"
            class="formInput"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" class="formLabel" prop="password">
          <el-input
            v-model="loginForm.password"
            placeholder="请输入"
            class="formInput"
            size="small"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="责任人类型" class="formLabel" prop="personType">
          <el-select
            v-model="loginForm.personType"
            class="formInput"
            size="small"
          >
            <el-option
              v-for="(item, index) in personTypeList"
              :key="index"
              :label="item.codeText"
              :value="item.codeValue"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        class="formButton"
        style="width: calc(20vw + 100px); margin-top: 30px"
        @click="login()"
        :loading="loginStatus == 'logining'"
        >验证并登录</el-button
      >
    </div>
  </div>
</template>
<script>
// import { updateRateRule } from "@/api/externalSalesRule";

export default {
  components: {},
  props: {},
  computed: {
    visible() {
      return this.dialogShow;
    },
  },
  data() {
    return {
      formTool: {
        taxAuthoritiesName: "",
        sellerTaxpayerId: "",
      },
      loginStatus: "",
      personTypeList: [],
      loginForm: {},
      rules: {},
      companyList: [],
      tableData: [],
      formTool: {},
      dialogShow: false,
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.dialogShow = false;
    },
    submit() {
    },
    login() {
      this.$emit("submit", "two"); 
    },
    init(row, rate, promoteRate) {
      this.dialogShow = true;
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";

.btnBox {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.classification {
  padding: 0px;

  .header {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0px 24px 0px 24px !important;
    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .top {
    display: flex;
    padding: 10px 0 10px 0;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .label {
        width: 120px;
      }

      .button {
        margin-right: 20px;

        ::v-deep .el-button {
          color: $subMenuActiveText;
          background-color: $butColor;
          border-color: $butColor;

          &:hover {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:active {
            color: $subMenuActiveText;
            background: $butColor;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:focus {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
        }
      }
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn-warp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.btn-list {
  margin: 0 5px;
}
.companyWarp {
  width: 400px;
  margin: 20px auto;
}

.formView {
  width: 500px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .formLabel {
    text-align-last: justify;
  }
  .formInput {
    text-align-last: left;
    width: 20vw;
  }
}
</style>
