<!--  -->
<template>
  <div class="classification">
    <div class="center" style="position: relative">
      <Tabs
        @tab-click="handleClick"
        :tabsList="tabsList"
        :activeName.sync="activeName"
      ></Tabs>
      <MyTable
        :table-data="tableData"
        :index="index"
        :selection="selection"
        :page="page"
        :operation="operation"
        :table-option.sync="tableOption"
        @page-change="getList"
        @handleSelectionChange="handleSelectionChange"
      >
        <template slot="address" slot-scope="scope">
          <el-row>
            {{ scope.row.address }}
          </el-row>
        </template>

        <template slot="goodsName" slot-scope="scope">
          <el-row>
            <el-col :span="8">
              <el-image
                :src="scope.row.drugImage ? scope.row.drugImage : avater"
                lazy
                style="width: 80px; height: 80px;border-radius:4px"
              />
            </el-col>
            <el-col :span="16">
              <el-row :span="16">
                <el-col> {{ scope.row.goodsName }} </el-col>
                <el-col> {{ scope.row.specs }} </el-col>
                <el-col> {{ scope.row.factory }} </el-col>
              </el-row>
            </el-col>
          </el-row>
        </template>
        <template slot="askPrice" slot-scope="scope">
          <el-row> 单价： ¥  {{ scope.row.askPrice }} </el-row>
          <el-row> 数量：{{ scope.row.askNumber }} </el-row>
        </template>

        <template slot="state" slot-scope="scope">
          <el-row v-if="scope.row.state == 1"> 派单中 </el-row>
          <el-row v-if="scope.row.state == 2">
            待支付
            <div> ¥  {{ scope.row.payDeposit }}</div></el-row
          >
          <el-row v-if="scope.row.state == 3">
            已支付
            <div> ¥  {{ scope.row.payDeposit }}</div></el-row
          >
          <el-row v-if="scope.row.state == 4">
            已支付
            <div> ¥  {{ scope.row.payDeposit }}</div></el-row
          >
          <el-row v-if="scope.row.state == 5"> 寻药完成 </el-row>
          <el-row v-if="scope.row.state == 6"> 关闭 </el-row>
        </template>

        <template slot="menu" slot-scope="scope">
          <el-link
            v-if="scope.row.state == 1"
            :underline="false"
            type="primary"
            class="btn"
            @click="confirmHandle(scope.row, 1)"
            >接单</el-link
          >
          <el-link
            v-if="scope.row.state == 3"
            :underline="false"
            type="primary"
            class="btn"
            @click="confirmHandle(scope.row, 2)"
            >补款通知</el-link
          >
        </template>
      </MyTable>
    </div>
    <acceptDialog
      :unit-list="unitList"
      :unit-item="unitItem"
      :accept-visible="acceptVisible"
      @confirmEmit="acceptEmit"
    />
    <informDialog
      :unit-list="unitList"
      :unit-item="unitItem"
      :inform-visible="informVisible"
      @confirmEmit="informEmit"
    />
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import acceptDialog from "./components/acceptDialog";
import informDialog from "./components/informDialog";

import { drugenumeration } from "@/utils/enumeration";

import {
  getSeekDrug,
  acceptSeekDrug,
  informSeekDrug,
} from "@/api/saleRule/medicineFind";
import avater from "/public/img/default.png";
import Tabs from "@/components/Tabs";
export default {
  components: { MyTable, Tabs, acceptDialog, informDialog },
  data() {
    return {
      avater: avater,
      formTool: {
        name: "",
        phone: "",
      },
      unitList: ["粒", "盒"],
      unitItem: {},
      acceptVisible: false,
      informVisible: false,
      activeName: "first", // tabs 传的值
      tabsList: [
        // tabs 传的值
        {
          name: "first",
          label: "需求单",
          value: 0,
          state: "",
        },
        {
          name: "two",
          label: "待寻药",
          value: 0,
        },
        {
          name: "three",
          label: "已完成",
          value: 0,
          value: 0,
        },
      ],
      selectionList: [],
      operation: true,
      selection: false,
      index: true,
      tableData: [],
      tableOption: [
        { label: "药品信息", prop: "goodsName", slot: true, width: 300 },
        { label: "需求信息", prop: "askPrice", slot: true },
        { label: "联系人", prop: "contactName" },
        { label: "联系人方式", prop: "contactPhone" },
        { label: "联系地址", prop: "address", slot: true }, // 这里表示自定义列
        { label: "定金状态", prop: "state", slot: true },
      ],
      state: 1,
      page: {
        total: 0,
        current: 1,
        size: 20,
        pageSizes: [10, 20, 30, 50],
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getList();
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  methods: {
    // tab 切换
    handleClick(e) {
      console.log(e);
      let state = "";
      if (e == "first") {
        state = 1;
      }
      if (e == "two") {
        state = 2;
      }
      if (e == "three") {
        state = 5;
      }

      this.state = state;
      this.getNameList(1);
    },

    delailHandle(row) {
      this.$router.push({
        path: "/salesControlMange/salesControl-team-detail",
        query: {
          id: row.id,
        },
      });
      console.log(row);
    },

    // 重置查询条件
    reset() {
      this.formTool = {
        name: "",
        approvalNumber: "",
      };
      this.getList();
    },

    // 切换分页
    getList() {
      this.getNameList();
    },
    // 勾选
    handleSelectionChange(val) {
      this.selectionList = val;
    },
    // 获取tab状态码
    getState() {
      let state = "";
      for (let i = 0; i < this.tabsList.length; i++) {
        if (this.activeName === this.tabsList[i].name) {
          state = this.tabsList[i].state;
        }
      }
      return state;
    },
    // 查询列表
    getNameList(current) {
      if (current) {
        // 搜索条件改变从第一页开始查询
        this.page.current = current;
      }
      // debugger
      const params = {
        status: this.state,
      };
      getSeekDrug(params).then((res) => {
        if (res && res.code == 0) {
          this.page.total = res.data.total;
          this.tableData = res.data.records;
        } else {
          this.tableData = [];
          this.page.total = 0;
        }
      });
    },
    acceptEmit(item) {
      console.log(item,'item') 
      let params = {
        id: item.id,
        sellerDrugSpecs: item.sellerDrugSpecs,
        sellerFeedPrice: item.sellerFeedPrice,
        sellerFeedNumber: item.askNumber,
        payDeposit: item.payDeposit,
      };
      console.log(params,'params') 
      acceptSeekDrug(params).then((res) => {
        if (res) {
          this.$message.success("操作成功");
          this.getList();
        }
      });
    },
    informEmit(item) {
      let params = {
        id: item.id,
      };
      informSeekDrug(params).then((res) => {
        if (res) {
          this.$message.success("操作成功");
          this.getList();
        }
      });
    },
    confirmHandle(row, type) {
      const params = {
        id: row.id,
      };
      if (type == 1) {
        this.acceptVisible = true;
        this.unitItem = row;
      } else {
        this.informVisible = true;
        this.unitItem = row;
      }
    },
    // 搜索
    clickSearch(current) {
      this.getNameList(current);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
.classification {
  padding: 0px;
  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;
    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }
    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }
  background: white;
  .top {
    display: flex;
    padding: 10px 0 10px 0;
    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      .label {
        width: 120px;
      }
      .button {
        margin-right: 20px;
        ::v-deep .el-button {
          color: $subMenuActiveText;
          background-color: $butColor;
          border-color: $butColor;
          &:hover {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
          &:active {
            color: $subMenuActiveText;
            background: $butColor;
            background-color: $butColor;
            border-color: $butColor;
          }
          &:focus {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
        }
      }
    }
  }
  .center {
    // margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);
    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }
  .btn {
    // margin: 0 10px;
    text-align: left;
  }
}
::v-deep .el-image {
  border-radius: 4px;
}
</style>
