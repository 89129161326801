var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        "lock-scroll": true,
        width: "718px",
        "before-close": _vm.handleClose,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", {}, [
        _c(
          "div",
          { staticStyle: { padding: "24px 0" } },
          [
            _c(
              "el-form",
              {
                ref: "form-data",
                staticStyle: { "text-align": "left" },
                attrs: {
                  model: _vm.form,
                  inline: false,
                  "label-width": "150px",
                  "label-position": "right",
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "remitTitle", label: "收款户名" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        placeholder: "",
                        clearable: "",
                        size: "small",
                        disabled: true,
                        "show-limit-word": "",
                        "max-length": "50",
                      },
                      model: {
                        value: _vm.form.remitTitle,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "remitTitle",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.remitTitle",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "bank", label: "开户银行" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        placeholder: "",
                        clearable: "",
                        size: "small",
                        type: "text",
                        maxlength: 18,
                        disabled: true,
                      },
                      model: {
                        value: _vm.form.bank,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "bank",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.bank",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "remitNo", label: "收款账号" } },
                  [
                    _c("el-input", {
                      directives: [
                        {
                          name: "positive-integer",
                          rawName: "v-positive-integer",
                        },
                      ],
                      staticStyle: { width: "400px" },
                      attrs: {
                        placeholder: "",
                        clearable: "",
                        size: "small",
                        "show-limit-word": "",
                        maxlength: 8,
                        disabled: true,
                      },
                      model: {
                        value: _vm.form.remitNo,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "remitNo",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.remitNo",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "imageList", label: "打款凭证" } },
                  [
                    _c(
                      "imgUpload",
                      {
                        staticStyle: { position: "relative", top: "-8px" },
                        attrs: { isEdit: _vm.isDisabled, limit: 3 },
                        model: {
                          value: _vm.form.imageList,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "imageList", $$v)
                          },
                          expression: "form.imageList",
                        },
                      },
                      [
                        _c("template", { slot: "tip" }, [
                          _c(
                            "p",
                            {
                              staticClass: "el-upload__tip",
                              staticStyle: { margin: "0px" },
                            },
                            [
                              _vm._v(
                                " 备注:图片格式为jpg或png，图片大小不得超过5M;最多上传三张图片 "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c("el-form-item", [
                  _c(
                    "div",
                    { staticClass: "footer-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            type: "primary",
                            disabled: _vm.isDisabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveSubmit()
                            },
                          },
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "plain" },
                          on: {
                            click: function ($event) {
                              return _vm.handleClose()
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }