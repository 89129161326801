<template>
  <el-dialog title="商品信息变更日志"
             :visible.sync="dialogVisible"
             width="70%"
             :before-close="handleClose">
    <div style="height: 600px; width: 100%;overflow: auto;">
      <MyTable :tableData="tableData"
               :index="false"
               :operation="false"
               :table-option.sync="tableOption"
               style="width: 100%;height: 100%;">
      </MyTable>
    </div>
  </el-dialog>
</template>
<script>
import { queryGoodsSkuEditLogList } from '@/api/salesControl/commodity'
import MyTable from '@/components/myTable'
export default {
  components: { MyTable },
  data () {
    return {
      dialogVisible: false,
      tableData: [],
      tableOption: [
        { label: '操作人', prop: 'operName' },
        { label: 'IP地址', prop: 'operIp' },
        { label: '变更前', prop: 'preEdit' },
        { label: '变更后', prop: 'postEdit' },
        { label: '变更时间', prop: 'createTime' }
      ]
    }
  },
  methods: {
    // 手动关闭
    handleClose () {
      this.$emit('didClose')
    },
    closeDlg () {
      this.dialogVisible = false
    },
    handleOpen (e) {
      this.dialogVisible = true
      this.caozuo(e)
    },
    // 操作日志
    caozuo (item) {
      queryGoodsSkuEditLogList(item.skuId).then(res => {
        if (res.code == 0) {
          this.tableData = res.data
        } else {
          this.tableData = []
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped></style>