var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "salesContainer" }, [
    _c(
      "div",
      { staticClass: "topView" },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.monthYearChange },
            model: {
              value: _vm.tabPosition,
              callback: function ($$v) {
                _vm.tabPosition = $$v
              },
              expression: "tabPosition",
            },
          },
          [
            _c("el-tab-pane", { attrs: { label: "按日统计", name: "day" } }),
            _c("el-tab-pane", { attrs: { label: "按月统计", name: "month" } }),
            _c("el-tab-pane", { attrs: { label: "按年统计", name: "year" } }),
          ],
          1
        ),
        _c(
          "el-form",
          { ref: "formTool", attrs: { model: _vm.formTool, size: "small" } },
          [
            _c(
              "el-row",
              { staticStyle: { display: "flex", "margin-top": "16px" } },
              [
                _vm.tabPosition === "day"
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            "value-format": "yyyy-MM-dd",
                            type: "daterange",
                            size: "small",
                            "range-separator": "-",
                            clearable: false,
                            "start-placeholder": "开始时间",
                            "end-placeholder": "结束时间",
                            "picker-options": _vm.pickerOptions,
                          },
                          model: {
                            value: _vm.formTool.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "date", $$v)
                            },
                            expression: "formTool.date",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.tabPosition === "month"
                  ? _c(
                      "el-radio-group",
                      {
                        attrs: { size: "mini" },
                        on: { input: _vm.yearChange },
                        model: {
                          value: _vm.monthTabPosition,
                          callback: function ($$v) {
                            _vm.monthTabPosition = $$v
                          },
                          expression: "monthTabPosition",
                        },
                      },
                      [
                        _c(
                          "el-radio-button",
                          { attrs: { label: "lastYear" } },
                          [_vm._v("2024年")]
                        ),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "currentYear" } },
                          [_vm._v("2023年")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "btn-box" },
                  [
                    _vm.tabPosition === "day"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                          },
                          [_vm._v("查 询")]
                        )
                      : _vm._e(),
                    _vm.tabPosition === "day"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: { click: _vm.handleReset },
                          },
                          [_vm._v(" 重 置 ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          float: "right",
                          "margin-bottom": "14px",
                        },
                        attrs: { size: "small" },
                        on: { click: _vm.handleExport },
                      },
                      [_vm._v(" 导出 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "center",
        style: _vm.contentHeight
          ? `height:${_vm.contentHeight}px!important`
          : "",
      },
      [
        _c("MyTable", {
          attrs: {
            "table-data": _vm.tableData,
            index: _vm.index,
            selection: _vm.selection,
            page: _vm.page,
            showpage: _vm.showpage,
            operation: _vm.operation,
            "table-option": _vm.tableOption,
          },
          on: {
            "update:tableOption": function ($event) {
              _vm.tableOption = $event
            },
            "update:table-option": function ($event) {
              _vm.tableOption = $event
            },
            "page-change": _vm.getList,
          },
          scopedSlots: _vm._u([
            {
              key: "order_cvr",
              fn: function (scope) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        scope.row.order_cvr
                          ? parseFloat(scope.row.order_cvr * 100).toFixed(4) +
                              "%"
                          : ""
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "pay_cvr",
              fn: function (scope) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        scope.row.pay_cvr
                          ? parseFloat(scope.row.pay_cvr * 100).toFixed(4) + "%"
                          : ""
                      ) +
                      " "
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }