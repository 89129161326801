<template>
  <div>
    <el-form :model="formData" label-position="top" size="small" :rules="rules" ref="ruleForm" label-width="100px"
      style="padding: 4px;" :disabled="typeFreight == 'detail'" class="demo-ruleForm">
      <div class="content-warp" v-for="(item, key) in formData.freightList" :key="key">
        <div class="free-name flex-list flex-head">
          <div v-if="key != 0" class="flex-lf">
            <el-form-item label="" :prop="'freightList.' + key + '.feeName'" v-if="key != 0" :rules="rules.feeName">
              <el-input v-model="item.feeName" placeholder="请输入运费模板名称" style="width: 300px;" :disabled="!item.showSave"
                size="small"></el-input>
            </el-form-item>

          </div>
          <div v-if="key == 0" class="flex-lf flex-lf-1">
            <div class="flex-title">
              所有地区
            </div>
            <div class="flex-tips">
              此运费模板为默认模板；新运费模板中地域将使用新模板的规则。
              举例：新设置”湖南运费“模板，地域关联湖南省，湖南省的客户将使用”湖南运费“的计费规则
            </div>
          </div>
          <div>
            <el-button @click="editFreight(item, key)" type="plain" size="small" v-if="!item.showSave">编辑</el-button>
            <el-button @click="saveFreight(item, key)" type="plain" size="small" v-if="item.showSave">保存</el-button>
            <el-button @click="deleteFreight(item)" type="primary" size="small" v-if="key != 0">删除</el-button>
          </div>
        </div>
        <div class="content-bottom">
          <el-form-item label="运费模版的区域" v-if="key != 0" :prop="'freightList.' + key + '.areaList'"
            :rules="rules.areaList">
            <div style="width: 100%;height: 120px;overflow-y: auto;" class="area-tree-box">
              <el-tree :props="{ label: 'rname', children: 'children', }" :data="areaTreeData"
                :default-checked-keys="item.defaultArea"
                @check="(item, checked) => { handleCheckChange(item, checked, key) }" node-key="id"
                :ref="`areatree_${key}`" show-checkbox>
              </el-tree>
            </div>
          </el-form-item>

          <div class="flex-list">
            <div class="flex-item-e">
              <el-form-item label="发货物流" class="fill-warp" :prop="'freightList.' + key + '.deliveryLogisticsId'"
                :rules="rules.deliveryLogisticsId">
                <el-select v-model="item.deliveryLogisticsId" placeholder="请选择" size="small" :disabled="!item.showSave"
                  @change="handleChangeExpress(item.deliveryLogisticsId, key)">
                  <el-option v-for="e in indicatorList ? indicatorList : []" :key="e.id" :label="e.instructions"
                    :value="e.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="flex-item-e">
              <el-form-item label="满足以下金额包邮" :prop="'freightList.' + key + '.freeShippingOrderAmount'"
                :rules="rules.freeShippingOrderAmount">
                <el-input v-model="item.freeShippingOrderAmount" placeholder="请输入金额" :disabled="!item.showSave">
                  <div slot="prefix">￥</div>
                </el-input>
              </el-form-item>
            </div>
          </div>

          <div class="flex-list">
            <div class="flex-item-e">
              <el-form-item label="不满足条件时收取以下金额运费" :prop="'freightList.' + key + '.carriageAmount'"
                :rules="rules.carriageAmount">
                <el-input v-model="item.carriageAmount" placeholder="请输入金额" :disabled="!item.showSave">
                  <div slot="prefix">￥</div>
                </el-input>
              </el-form-item>
            </div>

            <div class="flex-item-e">
              <el-form-item label="低于等于以下金额不允许下单" :prop="'freightList.' + key + '.minOrderAmount'"
                :rules="rules.minOrderAmount">
                <el-input v-model="item.minOrderAmount" placeholder="请输入金额" :disabled="!item.showSave">
                  <div slot="prefix">￥</div>
                </el-input>
              </el-form-item>
            </div>
          </div>

        </div>
      </div>

      <div class="add-warp">
        <el-button class="el_btn" @click="addFreight" type="plain" size="small">新增模板</el-button>
      </div>

    </el-form>
  </div>
</template>
  
<script>

import _ from "lodash";
import {
  getProvince,
  getAreaTree,
} from "@/api/shopmange/index";
import {
  getIndicatorInfo,
  updateShopExpressFees,
  editShopExpressFees,
  shopExpressFees,
  deleteShopExpressFees
} from "@/api/shop";
import { set } from "nprogress";
import router from "@/router";
export default {
  components: {},
  props: {
    formIn: {
      type: Object,
      default: {}
    },
    boundMoney: {
      type: Number,
      default: () => 0
    }
  },
  inject: router.path == '/storeSettleIn' ? [] : ['reloadShopAll'],
  data () {
    var checkNumber = (rule, value, callback) => { 
      if(value == 0)  callback();
      if (value < 0) {
        callback(new Error("请输入非负整数"));
      }
      const reg = /^[0-9]\d*$/;   // 金额验证，最多两位小数
      setTimeout(() => {
        if (!reg.test(value)) {
          callback(new Error("请输入非负整数"));
        } else {
          callback();
        }
      }, 300);
 
    };

    var checkNumber2 = (rule, value, callback) => {
      const reg = /^[1-9]\d*$/;   // 金额验证，最多两位小数
      setTimeout(() => {
        if (!reg.test(value)) {
          callback(new Error("请输入正整数"));
        } else {
          callback();
        }
      }, 300);
    };

    var checkAreaList = (rule, value, callback) => {
      let arr = [];
      arr = rule.field.split('.');
      let key = arr[1];
      this.$nextTick(() => {
        if (this.formData.freightList[key].areaList.length == 0) {
          callback(new Error("请选择区域"));
        } else {
          callback();
        }
      });
    };
    return {
      allId: [], //所有的id
      // 运费设置弹窗类型typeFreight  详情 detail  编辑 editor  新增 add
      typeFreight: "add",
      areaTreeData: [],
      formData: {
        freightList: [{
          feeType: 1,
          freeShippingOrderAmount: null,
          carriageAmount: null,
          minOrderAmount: null,
          feeName: '主运费模板', //默认第一个模板名称
          deliveryLogisticsId: null,
          deliveryLogisticsName: null,
          areaList: [],
          showSave: true,
          masterFreightFlag: 1, //是否为默认运费模板 0否 1是
        },],
      },
      rules: {
        feeName: [{ required: true, message: "请输入", trigger: "blur" }],
        deliveryLogisticsId: [{ required: true, message: "请选择", trigger: "change" }],
        freeShippingOrderAmount: [{ required: true, trigger: "change", validator: checkNumber2 }],
        carriageAmount: [{ required: true, trigger: "change", validator: checkNumber2 }],
        minOrderAmount: [{ required: true, trigger: "change", validator: checkNumber }],
        areaList: [
          { required: true, message: "请选择区域", trigger: "change", validator: checkAreaList },
        ],
      },
      allAreaCode: [],
      new_task_form: {
        "case_checkAll": false //全选按钮的绑定值
      },
      indicatorList: [],
      isIndeterminate: false,//全选按钮的全选，半选样式
    };
  },
  created () {
    this.getArea();
    this.getIndicatorInfo()//发货物流  
  },
  mounted () {

  },
  computed: {
    showHours () {
      return this.formIn.deliveryAgeingName == "极速发货";
    },
  },
  methods: {
    handleChangeExpress (e, key) {
      const findedItem = this.indicatorList.find((item) => item.id == e);
      this.formData.freightList[key].deliveryLogisticsName = findedItem.instructions;
    },
    init (freightList) {
      this.allId = []
      this.formData.freightList = freightList;
      if (this.formData.freightList.length > 0) {
        this.formData.freightList.forEach((item, index) => {
          if (index > 0) {
            if (item.areaList.length > 0) {
              item.areaList.forEach((item2, index2) => {
                if (item2.province) {
                  this.allId.push(item2.province)
                } else if (item2.city) {
                  this.allId.push(item2.city)
                } else {
                  this.allId.push(item2.area)
                }
              })
            }
          }
        })
      }
    },

    // 获取第一个模板的地区
    getFirstFreightArea () {
      this.formData.freightList[0].areaList = [];
      this.formData.freightList[0].areaList = this.allAreaCode;
    },
    // 编辑运费模板
    editFreight (e, key) {
      this.formData.freightList[key].showSave = true;
      this.$forceUpdate();
    },
    // 删除运费模板
    deleteFreight (e) {
      if (e.id) {
        deleteShopExpressFees(e.id).then((res) => {
          if (res) {
            this.$message.success("删除成功");
            this.$emit('queryShopExpressFees');
          }
        });
      } else {
        this.formData.freightList.splice(this.formData.freightList.indexOf(e), 1);
      }
    },
    saveFreightFrist () {
      this.saveFreight(this.formData.freightList[0], 0);
    },
    //  保存运费模板
    saveFreight (e, key) {

      if (this.boundMoney == 0 && (this.formIn.deliveryAgeingName == '极速发货' || '24小时发货' == this.formIn.deliveryAgeingName)) {
        this.$message.warning('请您充值保证金后开启极速发货/24小时发货，该发货方式将提高您店铺商品的搜索排名')
        return
      }

      let isVlidate = this.$emit('validateHead');
      if (!isVlidate) return;
      let validateArr = ['freightList.' + key + '.feeName', 'freightList.' + key + '.deliveryLogisticsId', 'freightList.' + key + '.freeShippingOrderAmount', 'freightList.' + key + '.carriageAmount', 'freightList.' + key + '.minOrderAmount', 'freightList.' + key + '.areaList'];
      let isVlidate2 = true;
      this.$refs['ruleForm'].validateField(validateArr, err => {
        if (err) {
          isVlidate2 = false
        }
      })

      setTimeout(() => {
        if (!isVlidate2) return
        e.deliveryAgeingId = this.formIn.deliveryAgeingId;
        e.deliveryAgeingName = this.formIn.deliveryAgeingName;
        if (e.deliveryAgeingName == '极速发货' || e.deliveryAgeingName == '急速发货') {
          if (this.formIn.deliveryAgeingClosingTimeId) {
            e.deliveryAgeingClosingTimeId = this.formIn.deliveryAgeingClosingTimeId;
            e.deliveryAgeingClosingTimeName = this.formIn.deliveryAgeingClosingTimeName;
          } else {
            this.$message.error('请先选择截单时间!')
            return
          }

        }

        if (key == 0) {
          e.areaList = this.allAreaCode;
        } else {
          e.newAreaList = [];
          this.getAreaCodeModel(e.areaList, key);
          e.areaList = _.cloneDeep(e.newAreaList);
        }
        if (e.id) {
          this.$loading({
            lock: true,
            text: "正在提交...",
            spinner: "el-icon-loading",
            color: "#fff",
            background: "rgba(0, 0, 0, 0.7)",
          });
          editShopExpressFees(e).then((res) => {
            if (res) {
              this.$loading().close();
              this.$message.success("保存成功");
              this.$nextTick(() => {
                this.$parent.$parent.init()
              })
              this.refreshAreaTree(res, key);
              if (this.$router.path != '/storeSettleIn') {
                this.reloadShopAll(); // 刷新店铺信息,更新提示状态 
              }
            } else {
              this.$loading().close();
              this.$message.error(res.msg);
            }
          }).catch((err) => {
            this.$loading().close();
          });
        } else {
          updateShopExpressFees(e).then((res) => {
            if (res) {
              this.$message.success("保存成功");
              this.$nextTick(() => {
                this.$parent.$parent.init()
              })
              this.refreshAreaTree(res, key);
              if (this.$router.path != '/storeSettleIn') {
                this.reloadShopAll(); // 刷新店铺信息,更新提示状态 
              }
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      }, 500)

    },

    // 刷新区域树
    refreshAreaTree (res, key) {
      res.showSave = false;
      this.$set(this.formData.freightList, key, res);
    },

    // 获取发货物流
    getIndicatorInfo () {
      let type = "MATERIAL_FLOW";
      getIndicatorInfo(type).then((data) => {
        if (data instanceof Array) {
          this.indicatorList = data;
        }
      });
    },
    // 下一步
    goNext () {
      // console.log('tonext1')
      let key = this.formData.freightList.length - 1;
      let validateArr = ['freightList.' + key + '.feeName', 'freightList.' + key + '.deliveryLogisticsId', 'freightList.' + key + '.freeShippingOrderAmount', 'freightList.' + key + '.carriageAmount', 'freightList.' + key + '.minOrderAmount', 'freightList.' + key + '.areaList'];
      let isVlidate2 = true;
      this.$refs['ruleForm'].validateField(validateArr, err => {
        if (err) {
          isVlidate2 = false
          return
        }
      })
      if (!isVlidate2) {
        this.$message.error('请先完善运费模板信息')
        return
      } else {
        for (let i = 0; i < this.formData.freightList.length; i++) {
          let item = this.formData.freightList[i];
          if (!item.id || item.showSave == true) {
            this.$message.error(`请先保存【运费模板${item.feeName}】`)
            return
          }
        }
        this.$emit('toNext', 3)
      }

      // this.$refs['ruleForm'].validate((valid) => {
      //     if (valid) { 
      //         console.log('tonext')
      //         this.$emit('toNext', 3)
      //     } else {
      //         this.$message.error('请先完善运费模板信息')
      //     }
      // })
    },
    //  选择区域
    handleCheckChange (item, checked, key) {
      if (checked.checkedKeys.indexOf(item.id) == -1) {
        this.formData.freightList[key].areaList = checked.checkedNodes;
      } else {
        if (this.allId.indexOf(String(item.id)) > -1) {
          this.$message.warning('该地区在其他模板中已存在')
          this.$nextTick(() => {
            this.$refs[`areatree_${key}`][0].setChecked(item.id, false, true);
          })
          return
        }
        this.formData.freightList[key].areaList = checked.checkedNodes;
      }
    },
    // 新增模板
    addFreight () {
      let item = {
        areaList: [],
        feeType: 1,
        freeShippingOrderAmount: null,
        carriageAmount: null,
        minOrderAmount: null,
        feeName: null,
        deliveryLogisticsId: null,
        showSave: true, //是否显示保存按钮
        masterFreightFlag: 0 //是否为默认运费模板 0否 1是
      };

      let isVlidate = this.$emit('validateHead');
      if (!isVlidate) return;
      if (this.formData.freightList.length == 10) {
        this.$message.error('最多只能添加10个运费模板')
        return
      };
      let key = this.formData.freightList.length - 1;
      let validateArr = ['freightList.' + key + '.feeName', 'freightList.' + key + '.deliveryLogisticsId', 'freightList.' + key + '.freeShippingOrderAmount', 'freightList.' + key + '.carriageAmount', 'freightList.' + key + '.minOrderAmount', 'freightList.' + key + '.areaList'];
      let isVlidate2 = true;
      this.$refs['ruleForm'].validateField(validateArr, err => {
        if (err) {
          isVlidate2 = false
          return
        }
      })
      if (!isVlidate2) return
      this.formData.freightList.push(item)
      // console.log( this.formData.freightList,'--- this.formData.freightList') 
    },
    // 封装省市区模型 用于保存(兼容查询回显的数据)
    getAreaCodeModel (newAreaTreeData, key) {
      for (let i = 0; i < newAreaTreeData.length; i++) {
        if (newAreaTreeData[i].province || newAreaTreeData[i].city) {
          if (newAreaTreeData[i].province) {
            this.formData.freightList[key].newAreaList.push({
              provinceName: newAreaTreeData[i].provinceName,
              province: newAreaTreeData[i].province,
              city: "",
              cityName: "",
            });
          }
          if (newAreaTreeData[i].city) {
            this.formData.freightList[key].newAreaList.push({
              cityName: newAreaTreeData[i].cityName,
              city: newAreaTreeData[i].city,
              provinceName: "",
              province: "",
            });
          }
        } else {
          if (newAreaTreeData[i].level == 1) {
            this.formData.freightList[key].newAreaList.push({
              provinceName: newAreaTreeData[i].rname,
              province: newAreaTreeData[i].id,
              city: "",
              cityName: "",
            });
          }
          if (newAreaTreeData[i].level == 2) {
            this.formData.freightList[key].newAreaList.push({
              cityName: newAreaTreeData[i].rname,
              city: newAreaTreeData[i].id,
              provinceName: "",
              province: "",
            });
          }
        }

        if (newAreaTreeData[i].children) {
          this.getAreaCodeModel(newAreaTreeData[i].children, key)
        }
      }
    },

    //获取整个省市区的树
    getArea () {
      getAreaTree({ level: 2 }).then((res) => {
        if (res.code == 0) {
          this.areaTreeData = res.data;
          let newAreaTreeData = JSON.parse(JSON.stringify(this.areaTreeData));
          this.getAllAreaCode(newAreaTreeData);
        }
      });
    },

    // 获取省市的对象，给店铺设置默认模板使用（第一个模板）
    getAllAreaCode (newAreaTreeData) {
      for (let i = 0; i < newAreaTreeData.length; i++) {
        // level
        if (newAreaTreeData[i].level == 1) {
          this.allAreaCode.push({
            provinceName: newAreaTreeData[i].rname,
            province: newAreaTreeData[i].id,
            city: "",
            cityName: "",
          });
        }
        if (newAreaTreeData[i].level == 2) {
          this.allAreaCode.push({
            cityName: newAreaTreeData[i].rname,
            city: newAreaTreeData[i].id,
            provinceName: "",
            province: "",
          });
        }
        if (newAreaTreeData[i].children) {
          this.getAllAreaCode(newAreaTreeData[i].children)
        }
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.content-warp {
  width: 552px;
  // height: 488px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin: 0px auto 22px;
}

.add-warp {
  width: 552px;
  margin: 10px auto;
}

.content {
  background-color: #fff;
  height: calc(100vh - 140px);

  ::v-deep .el-tabs__item {
    padding: 0 12px;
    margin-right: 0px;
  }

  .freight-condition {
    padding: 24px;
    box-sizing: border-box;
    text-align: left;
  }
}

.fill-warp {
  ::v-deep .el-select {
    width: 100%;
  }
}

.content-bottom {
  padding: 0 10px 10px;
}

.area-tree-box {
  border: solid 1px #eee;
  min-height: 200px;
  padding: 5px;
}

.free-name {}

.flex-head {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #eee;
}

.title {}

.flex-list {
  display: flex;
  justify-content: space-between;
}

.flex-lf {
  display: flex;
  justify-content: flex-start;
  line-height: 24px;
  width: 400px;

}

.flex-lf-1 {
  width: 420px;

  .flex-title {
    width: 60px;
    display: block;
    font-size: 12px;
  }

  .flex-tips {
    line-height: 20px;
    font-size: 12px;
    width: 360px;
  }
}

.flex-item-e {
  width: 48%;
}
</style>
  