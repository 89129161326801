import request from "@/utils/request";

// 子任务列表
export function contractDoTaskPage(data) {
  return request({
    url: "/shop/hg/contractDoTask/page",
    method: "POST",
    data: data,
  });
}

// 子任务详情
export function contractDoTaskDetail(id) {
  return request({
    url: `/shop/hg/contractDoTask/detail/${id}`,
    method: "GET",
  });
}

// 审核子任务(单个或多个) 医药公司审核
export function contractDoTaskAudit(data) {
  return request({
    url: "/shop/hg/contractDoTask/check",
    method: "POST",
    data: data,
  });
}

//审核子任务(单个或多个) cos公司审核
export function cosContractDoTaskAudit(data) {
  return request({
    url: "/shop/hg/contractDoTask/cosAuditContractDoTask",
    method: "POST",
    data: data,
  });
}
