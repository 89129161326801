<template>
  <div class="classification">
    <div class="eachRow">
      <div class="titleView">
        <div class="rowLine"></div>
        <span class="eachRowTitle">部门基本信息</span>
      </div>
      <el-divider></el-divider>
    </div>
    <div style="padding: 24px 0;">
      <el-form ref="form"
               :model="form"
               :inline="false"
               :rules="rules"
               label-width="100px"
               label-position="right"
               style="text-align:left;">
        <el-form-item prop="deptName"
                      label="部门名称">
          <el-input v-model="form.deptName"
                    style="width: 300px;"
                    placeholder="部门名称"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="deptPrincipal"
                      label="部门负责人">
          <div class="cpxhead">
            <el-autocomplete popper-class="my-autocomplete"
                             size="small"
                             v-model="form.deptPrincipal"
                             :fetch-suggestions="mangerSearch"
                             :trigger-on-focus="true"
                             placeholder="请输入内容"
                             max-length="11"
                             @select="selManger">
              <i class="el-icon-search el-input__icon"
                 slot="suffix"> </i>
              <template slot-scope="{ item }">
                <!-- <div class="name">name：{{ item.fullName }}</div> -->
                <div class="fzr">
                  <span class="name"
                        style="margin-right: 10px;">{{ item.fullName }}</span>
                  <span class="addr">{{ item.phone }}</span>
                </div>
              </template>
            </el-autocomplete>
            <el-button type="primary"
                       icon="el-icon-circle-plus"
                       size="small"
                       class="add"
                       style="margin-left: 20px"
                       @click="userEdit(false, 'add')">
              创建</el-button>
          </div>
        </el-form-item>
        <el-form-item prop="operationName"
                      label="运营姓名">
          <el-input v-model="form.operationName"
                    style="width: 300px;"
                    placeholder="运营姓名"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="operationPhone"
                      label="运营电话">
          <el-input v-model="form.operationPhone"
                    style="width: 300px;"
                    placeholder="运营电话"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="deptErpId"
                      label="部门ERPid">
          <el-input v-model="form.deptErpId"
                    style="width: 300px;"
                    placeholder="部门ERPid"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     type="primary"
                     @click="submit()">保存</el-button>
          <el-button size="small"
                     style="margin-left:12px"
                     @click="cancel()">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <UserEdit ref="userEdit"
              @sure="userEditSure" />
  </div>
</template>
  
<script>
import {
  getShopUserList,
} from "@/api/saleRule/productLine";
import { insert, update, externalsalesdeptinfo, listByBelongUserId } from '@/api/salesControl/commodity'
import UserEdit from "./userEdit.vue";
const debounce = (function () {
  let timer = 0;
  return function (func, delay) {
    clearTimeout(timer);
    timer = setTimeout(func, delay);
  };
})();
export default {
  props: {},
  data () {
    return {
      id: '',
      form: {
        userId: '',
        deptName: '',
        deptPrincipal: '',
        deptErpId: '',
        operationName: '',
        operationPhone: '',
      },
      rules: {
        deptName: [{ required: true, message: '请输入部门名称', trigger: 'blur' }],
        deptPrincipal: [{ required: true, message: '请输入部门负责人', trigger: ['change', 'blur'] }],
        deptErpId: [{ required: true, message: '请输入部门erpid', trigger: 'blur' }],
        operationName: [{ required: true, message: '请输入运营姓名', trigger: 'blur' }],
        operationPhone: [{ required: true, message: '请输入运营电话', trigger: 'blur' }],
      }

    }
  },
  components: { UserEdit },
  created () {
    this.id = this.$route.query?.id || ''
    if (this.id) {
      this.query(this.id)
    }
  },
  mounted () {

  },
  watch: {},
  methods: {
    userEdit (obj, type) {
      this.$refs["userEdit"].init(obj, type);
    },
    userEditSure () { },
    // 选择产品线负责人
    selManger (item) {
      // this.mangerHeadInfo = item;
      this.form.deptPrincipal = item.phone;
      this.form.userId = item.userId;
    },
    mangerSearch (queryString, cb) {
      console.log('-----------');

      debounce(() => {
        listByBelongUserId({
          phone: queryString
        }).then((res) => {
          cb(res || []);
        });
      }, 300);
    },
    submit () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let params = {
            ...this.form,
            id: this.id
          }
          if (this.id) {
            update(params).then(res => {
              this.$message.success('保存成功')
              this.$router.push('/salesControlMange/salesControl-section-list')
            })
          } else {
            insert(params).then(res => {
              this.$message.success('保存成功')
              this.$router.push('/salesControlMange/salesControl-section-list')
            })
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    cancel () {
      this.$router.push('/salesControlMange/salesControl-section-list')
    },
    query (id) {
      externalsalesdeptinfo(id).then(res => {
        this.form = res
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.eachRow {
  background-color: #fff;
  border-radius: 4px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
}

.titleView {
  display: flex;
  align-items: center;

  .rowLine {
    width: 4px;
    border-color: 1px;
    background-color: var(--main-color);
    height: 16px;
    margin-right: 8px;
  }

  .eachRowTitle {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
  }
  .cpxhead {
    display: flex;
    align-items: center;
  }
  .mark {
    color: #f56c6c;
    font-size: 12px;
    margin-left: 10px;
    width: 200px;
    line-height: 1.5;
    display: inline-block;
    font-weight: bold;
  }
  .fzr {
    display: flex;
    align-items: center;
    .name {
      margin-right: 10px;
    }
  }
}
</style>

