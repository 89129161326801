var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("div", { staticClass: "staus_bar fl_bet border" }, [
            _c("div", { staticClass: "detail_left" }, [
              _c("div", { staticClass: "fl_cen" }, [
                _c(
                  "div",
                  { staticClass: "status" },
                  [
                    _c("el-image", {
                      staticClass: "icon_status",
                      attrs: { src: _vm.getStatus(_vm.orderList.type) },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "items" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("订单编号")]),
                  _c("div", { staticStyle: { height: "22px" } }, [
                    _vm._v(_vm._s(_vm.orderList.orderCode || "")),
                  ]),
                ]),
                _c("div", { staticClass: "items" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("售后单号")]),
                  _c("div", { staticStyle: { height: "22px" } }, [
                    _vm._v(_vm._s(_vm.orderList.code || "")),
                  ]),
                ]),
                _c("div", { staticClass: "items" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("售后金额")]),
                  _c("div", { staticStyle: { height: "22px" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#e4393c", "font-size": "18px" },
                      },
                      [_vm._v(" ￥" + _vm._s(_vm.orderList.amount || "") + " ")]
                    ),
                    _vm._v(" ( "),
                    _vm.orderList.balanceAmount &&
                    parseFloat(_vm.orderList.balanceAmount) > 0
                      ? _c("span", [
                          _vm._v(
                            " 余额支付 ￥ " +
                              _vm._s(
                                parseFloat(_vm.orderList.balanceAmount).toFixed(
                                  2
                                ) || "--"
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.orderList.balanceAmount &&
                    parseFloat(_vm.orderList.balanceAmount) > 0 &&
                    parseFloat(_vm.orderList.amount) -
                      parseFloat(_vm.orderList.balanceAmount) >
                      0
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.orderList.payTypeName) +
                              " ￥ " +
                              _vm._s(
                                (
                                  parseFloat(_vm.orderList.amount) -
                                  parseFloat(_vm.orderList.balanceAmount)
                                ).toFixed(2)
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    parseFloat(_vm.orderList.balanceAmount) == 0
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.orderList.payTypeName) +
                              " ￥ " +
                              _vm._s(
                                parseFloat(_vm.orderList.amount).toFixed(2) ||
                                  "--"
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" ) "),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("Step", {
            attrs: {
              stepArray: _vm.stepArray,
              indexStep: _vm.indexStep,
              lineWord: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info card" },
        [
          _c(
            "el-descriptions",
            { attrs: { title: "售后单据信息" } },
            [
              _c("el-descriptions-item", { attrs: { label: "申请类型" } }, [
                _vm._v(
                  _vm._s(_vm.orderList.type == "TK" ? "退款" : "退货退款")
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "申请金额" } }, [
                _vm._v("￥" + _vm._s(_vm.orderList.amount)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "申请原因" } }, [
                _vm._v(_vm._s(_vm.orderList.afterSaleReason)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "申请用户" } }, [
                _vm._v(
                  _vm._s(
                    _vm.orderList.type == "SJTK"
                      ? _vm.orderList.payeeShopName
                      : _vm.orderList.payShopName
                  ) + " "
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "申请时间" } }, [
                _vm._v(_vm._s(_vm.orderList.orderTime)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "问题描述" } }, [
                _vm._v(_vm._s(_vm.orderList.remark)),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "售后凭证" } },
                [
                  _c("ImgUpload", {
                    staticStyle: { position: "relative", top: "-8px" },
                    attrs: { isEdit: true, limit: 3 },
                    model: {
                      value: _vm.orderList.afterSaleVoucher,
                      callback: function ($$v) {
                        _vm.$set(_vm.orderList, "afterSaleVoucher", $$v)
                      },
                      expression: "orderList.afterSaleVoucher",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "info card fl_cen" }, [
        _c("div", { staticClass: "info_list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("商户收货信息")]),
          _c("div", { staticClass: "lists" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("收货人：")]),
              _vm._v(_vm._s(_vm.orderList.contactsName || "--") + " "),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("地址：")]),
              _vm._v(
                _vm._s(_vm.orderList.province || "") +
                  _vm._s(_vm.orderList.city || "") +
                  _vm._s(_vm.orderList.district || "") +
                  _vm._s(_vm.orderList.address || "") +
                  " "
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("手机号：")]),
              _vm._v(_vm._s(_vm.orderList.payeeShopPhone || "--") + " "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "info_list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("配送信息")]),
          _c("div", { staticClass: "lists" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("快递公司：")]),
              _vm._v(_vm._s(_vm.orderList.kuaidiName || "--") + " "),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("快递单号：")]),
              _vm._v(_vm._s(_vm.orderList.number || "--") + " "),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("预计送达：")]),
              _vm._v(_vm._s(_vm.orderList.finishDate || "--") + " "),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "info card" },
        [
          _c("h4", [_vm._v("物流数据")]),
          !_vm.expressData.child || _vm.expressData.child.length == 0
            ? _c(
                "div",
                { staticStyle: { color: "#f56c6c", "font-weight": "600" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.expressData.step || "暂无物流数据") + " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.expressData.child && _vm.expressData.child.length > 0
            ? _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { name: "1" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "line-height": "20px",
                              height: "80px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#f56c6c",
                                  "font-weight": "600",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.expressData.step || "暂无物流数据"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.expressData.description || "") +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { "padding-top": "10px" } },
                        [
                          _vm.expressData.child &&
                          _vm.expressData.child.length > 0
                            ? _c(
                                "el-timeline",
                                _vm._l(
                                  _vm.expressData.child,
                                  function (activity, index) {
                                    return _c(
                                      "el-timeline-item",
                                      {
                                        key: index,
                                        attrs: { color: "#F5222D" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-weight": "600",
                                            },
                                          },
                                          [_vm._v(_vm._s(activity.step || ""))]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(activity.description || "")
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "commodity card" },
        [
          _c("h4", [_vm._v("商品信息")]),
          _c("publicTable", {
            attrs: {
              "table-data": _vm.orderList.detailList
                ? _vm.orderList.detailList
                : [],
              "colum-obj": _vm.columObj,
              "page-obj": _vm.pageObj,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "info card" }, [
        _c("h4", [_vm._v("协商历史")]),
        _c(
          "div",
          {},
          _vm._l(_vm.orderList.historyList, function (item, index) {
            return _c("div", { key: index, staticClass: "everyStep" }, [
              _c("div", { staticClass: "everyOne" }, [
                _c("span", [_vm._v(_vm._s(item.consultPerson))]),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px", color: "#595959" } },
                  [_vm._v(_vm._s(item.consultTime))]
                ),
              ]),
              _c("div", { staticClass: "detail" }, [
                _vm._v(" " + _vm._s(item.consultResult) + " "),
              ]),
              _c(
                "div",
                { staticClass: "detail" },
                [
                  _c("ImgUpload", {
                    staticStyle: { position: "relative", top: "-8px" },
                    attrs: { isEdit: true },
                    model: {
                      value: item.consultImage,
                      callback: function ($$v) {
                        _vm.$set(item, "consultImage", $$v)
                      },
                      expression: "item.consultImage",
                    },
                  }),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "拒绝说明",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "请输入内容",
              maxlength: "200",
              "show-word-limit": "",
            },
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
          _c(
            "ImgUpload",
            {
              staticStyle: { position: "relative", top: "0px" },
              attrs: { limit: 3 },
              model: {
                value: _vm.imageList,
                callback: function ($$v) {
                  _vm.imageList = $$v
                },
                expression: "imageList",
              },
            },
            [
              _c("template", { slot: "tip" }, [
                _c(
                  "p",
                  {
                    staticClass: "el-upload__tip",
                    staticStyle: { margin: "0px" },
                  },
                  [
                    _vm._v(
                      " 备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过5M;最多上传三张图片 "
                    ),
                  ]
                ),
              ]),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submit },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("resetDialog", {
        ref: "rest-dialog",
        on: { submitAgree: _vm.sureGoods, aftersaleInfo: _vm.aftersaleInfo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }