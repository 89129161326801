<template>
  <el-breadcrumb class="app-breadcrumb"
                 separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in levelList"
                          :key="item.path">
        <span v-if="item.redirect==='noRedirect'||index==levelList.length-1"
              class="no-redirect">{{ item.meta.title }}</span>
        <a v-else
           @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import * as pathToRegexp from 'path-to-regexp'

export default {
  data () {
    return {
      levelList: null
    }
  },
  watch: {
    $route () {
      this.getBreadcrumb()
    }
  },
  created () {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb () {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
      if (this.$route.name == 'salesControl-product-add' && matched.length) {
        // 控销产品线区分 新增和编辑
        matched.forEach((routerItem)=>{
          if (routerItem.name == 'salesControl-product-add' && this.$route.query && this.$route.query.isEditor) {
            routerItem.meta.title = '编辑产品'
          }else[
          routerItem.meta.title = '添加产品'
          ]
        })
      }
      const first = matched[0]
      if (!this.isDashboard(first)) {
        matched = [
          // {
          //   path: '/dashboard',
          //   meta: { title: '首页' }
          // }
        ].concat(matched)
      }

      this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
    },
    isDashboard (route) {
      const name = route && route.name
      if (!name) {
        return false
      }
      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase()
    },
    pathCompile (path) {
      const { params } = this.$route
      var toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink (item) {
      const { redirect, path } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(this.pathCompile(path))
    }
  }
}
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 32px;
  margin-left: 8px;

  .no-redirect {
    color: #f5222d;
    cursor: text;
  }
}
</style>
