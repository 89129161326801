var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "search-header" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "btn", on: { click: _vm.handleBack } },
            [
              _c("el-image", {
                staticClass: "back-icon",
                attrs: { src: _vm.backIcon },
              }),
              _vm._v(" 返回活动类型 "),
            ],
            1
          ),
          _vm._m(0),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "search-frame" },
            [
              _c("el-image", {
                staticClass: "search-icon",
                attrs: { src: _vm.searchIcon },
              }),
              _c("div", { staticClass: "input-frame" }, [_c("el-input")], 1),
              _c("div", { staticClass: "search-btn" }, [_vm._v("搜索")]),
            ],
            1
          ),
        ]),
      ]),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "grid" },
        _vm._l([1, 1, 1, 1, 1, 1, 1, 1], function (item, index) {
          return _c(
            "div",
            { key: index, class: { item: true, focus: index === 1 } },
            [_vm._v(" 广西宝瑞坦制药有限公司广西宝瑞坦制药有限公司 ")]
          )
        }),
        0
      ),
      _c("div", { staticClass: "big-title" }, [
        _c("span", { staticClass: "txt" }, [_vm._v("商品 (12)")]),
        _c("span", { staticClass: "small-tip h-gap" }, [
          _vm._v("若未能满足您的上架需求，可点击"),
        ]),
        _c(
          "span",
          {
            staticClass: "small-tip add-btn",
            on: {
              click: function ($event) {
                return _vm.addProduct()
              },
            },
          },
          [_vm._v("发布商品信息")]
        ),
      ]),
      _c("div", { ref: "tableCont", staticClass: "contentCont" }, [
        _c(
          "div",
          { staticClass: "contentContinner" },
          _vm._l(_vm.tableData, function (item, idnex) {
            return _c("productItem", {
              key: idnex,
              attrs: { item: item },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleShowDetail(idnex)
                },
              },
            })
          }),
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "el-page" },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.page.total,
              page: _vm.page.current,
              limit: _vm.page.size,
              "page-sizes": _vm.pageSizes,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.page, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.page, "size", $event)
              },
              pagination: _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _c("ProductDetailDialog", {
        ref: "refProductDetailDialog",
        on: {
          confirm: function ($event) {
            return _vm.handleConfirm()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "centern" }, [
      _c("span", [_vm._v("搜索商品的")]),
      _c("span", { staticClass: "weight" }, [_vm._v("名称/厂家/批准文号")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "big-title" }, [
      _c("span", { staticClass: "txt" }, [_vm._v("厂家 (12)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }