
<!--  -->
<template>
  <div class="comContent">
    <Tabs @tab-click="handleClick" style="padding:0 24px" :tabsList="tabsList" :activeName.sync="activeName"></Tabs>
    <div class="topHeader">
      <div style="width:calc(100% - 230px)">
        <el-form ref="formTool" :model="formTool" :inline="true" style="display:flex;">

          <!-- 未展开 -->
          <template v-if="!expanded">
            <div class="item big" style="display: flex;justify-content: center;">
              <el-input style="width: 300px;margin-right: 16px;" v-model="formTool.keyWords" size="small"
                placeholder="服务单号/订单编号/商品名称/客户名称" />
            </div>
          </template>

          <!-- 展开 -->
          <template v-else>
            <div class="item big" style="display: flex;justify-content: center;">
              <el-form-item prop="code" size="small">
                <el-input v-model="formTool.code" clearable placeholder="服务单号" />
              </el-form-item>
              <el-form-item prop="orderCode" size="small">
                <el-input v-model="formTool.orderCode" clearable placeholder="订单编号" />
              </el-form-item>
              <el-form-item prop="goodsName" size="small">
                <el-input v-model="formTool.goodsName" clearable placeholder="商品名称" />
              </el-form-item>
              <el-form-item prop="shopName" size="small">
                <el-input v-model="formTool.shopName" clearable placeholder="客户名称" />
              </el-form-item>
              <el-form-item prop="date" size="small">
                <el-date-picker v-model="formTool.date" type="daterange" value-format="yyyy-MM-dd" range-separator="-"
                  start-placeholder="开始日期" end-placeholder="结束日期" />
              </el-form-item>
              <el-form-item prop="content" size="small">
                <el-select v-model="formTool.applyForType" clearable placeholder="申请类型">
                  <el-option v-for="li in options" :key="li.value" :label="li.label" :value="li.value" />
                </el-select>
              </el-form-item>
            </div>
          </template>

          <!-- <el-form-item prop="content"
                        size="small">
            <el-select v-model="formTool.content"
                       clearable
                       placeholder="售后发起方">
              <el-option v-for="li in options"
                         :key="li.value"
                         :label="li.label"
                         :value="li.value" />
                  </el-select>
                </el-form-item> -->
        </el-form>
      </div>
      <div style="width:220px">
        <MyButton type="primary" text="查询" @click="getList(1)" />
        <MyButton type="" text="重置" @click="reset()" />
        <el-button type="text" size="small" @click="handleExpand()">
          {{ expanded ? "收起" : "展开" }}
          <i :class="{
            'el-icon-arrow-up': expanded,
            'el-icon-arrow-down': !expanded,
            'el-icon--right': true,
          }"></i>
        </el-button>
      </div>
    </div>

    <div class="center">
      <MyTable v-if="tableData.length > 0" :table-data="tableData" :index="index" :selection="selection" :page="page"
        :operation="operation" :table-option.sync="tableOption" @page-change="getList">
        <template slot="amount" slot-scope="scope">
          <span>
            ￥{{ scope.row.amount }}
          </span>
        </template>

        <!-- 待举证剩余时间 -->
          <template slot="countDownTime" slot-scope="scope" v-if="activeName =='WAIT_EVIDENCE' || activeName =='REJECT' ">  
            <el-statistic
            @finish="hilarity(scope.row, scope.$index)"
            ref="statistic" 
            format="HH:mm:ss"
            :value="Date.now() + parseInt(scope.row.evidenceRemainTimestamp )"
            title=""
            time-indices 
            :value-style="{ color: '#f5222d' ,fontSize:'14px' }"
            v-if="scope.row.evidenceRemainTimestamp>0"
          >
          </el-statistic>
        </template>
           <!-- 审核中 处理倒计时 -->
           <template slot="countDownTimeAfter" slot-scope="scope" v-if="activeName =='CHECKING' ||  activeName =='SALEING' ||  activeName =='WAIT_SALE' || activeName =='REFUNDING'">  
            <!-- 审核超时时间戳 auditRemainTimestamp-->
            <el-statistic
            @finish="hilarity(scope.row, scope.$index)"
            ref="statistic" 
            format="DD天HH:mm:ss"
            :value="Date.now() + parseInt(scope.row.auditRemainTimestamp) "
            title=""
            time-indices 
            :value-style="{ color: '#f5222d' ,fontSize:'14px' }" 
            v-if="activeName =='CHECKING'  && scope.row.auditRemainTimestamp >0 "
          >
          </el-statistic>

          <!-- 商家退款倒计时 /// ps :商户线下打款 提交打款凭证 -->

          <el-statistic @finish="hilarity(scope.row, scope.$index)" ref="statistic" format="DD天HH:mm:ss"
          :value="Date.now() + parseInt(scope.row.offlinePaymentRefundsTimestamp)" title="" time-indices
          :value-style="{ color: '#f5222d', fontSize: '14px' }"
          v-if="activeName == 'REFUNDING' && scope.row.offlinePaymentRefundsTimestamp > 0">
        </el-statistic>

          <!-- 用户售后，发货超时时间戳 deliveryRemainTimestamp-->
          <el-statistic @finish="hilarity(scope.row, scope.$index)" ref="statistic" format="DD天HH:mm:ss"
            :value="Date.now() + parseInt(scope.row.deliveryRemainTimestamp)" title="" time-indices
            :value-style="{ color: '#f5222d', fontSize: '14px' }"
            v-if="activeName == 'WAIT_SALE' && scope.row.deliveryRemainTimestamp > 0">
          </el-statistic>

          <!--收货超时时间戳 receiveTimeout -->
          <el-statistic @finish="hilarity(scope.row, scope.$index)" ref="statistic" format="DD天HH:mm:ss"
            :value="Date.now() + parseInt(scope.row.receiveRemainTimestamp)" title="" time-indices
            :value-style="{ color: '#f5222d', fontSize: '14px' }"
            v-if="activeName == 'SALEING' && scope.row.receiveRemainTimestamp > 0">
          </el-statistic>

        </template>
        <template slot="caozuo" slot-scope="scope">
          <el-link type="primary" :underline="false" @click="detail(scope.row)">
            详情
          </el-link>

          <el-link type="primary" :disabled="afterSalesControl == '1'"
            v-if="(scope.row.status == '审核中' || scope.row.status == '待确款') && (scope.row.type == '退款' || scope.row.type == '退货退款') && !scope.row.beExistsVoucher && scope.row.payType == 3"
            style="margin-left:10px" :underline="false" @click="submitAgreeBefore(scope.row, scope.row.type)">
            同意
          </el-link>

          <el-link type="primary" :disabled="afterSalesControl == '1'"
            v-if="(scope.row.status == '审核中' || scope.row.status == '待确款') && (scope.row.type == '退款' || scope.row.type == '退货退款') && scope.row.payType != 3"
            style="margin-left:10px" :underline="false" @click="submitAgreeBefore(scope.row, scope.row.type)">
            同意
          </el-link>


          <el-link type="primary" :disabled="afterSalesControl == '1'" v-if="scope.row.platformStatus == 'WAIT_EVIDENCE'"
            style="margin-left:10px" :underline="false" @click="submitProof(scope.row, scope.row.type)">
            举证
          </el-link>

          <el-link type="primary" :disabled="afterSalesControl == '1'"
            v-if="(scope.row.status == '审核中' || scope.row.status == '待确款') && scope.row.type != '商家退款' && !scope.row.beExistsVoucher"
            style="margin-left:10px" :underline="false" @click="aftersaleReject(scope.row)">
            驳回
          </el-link>

          <el-link type="primary" :disabled="afterSalesControl == '1'" v-if="scope.row.status == '退货中'"
            style="margin-left:10px" :underline="false" @click="clickAftersaleDelivery(scope.row)">
            确认收货
          </el-link>

          <el-link type="primary" :disabled="afterSalesControl == '1'"
            v-if="scope.row.status == '退款中' && scope.row.payType == 3 && !scope.row.beExistsVoucher" style="margin-left:10px" :underline="false"
            @click="uploadCredentials(scope.row)">
            上传凭证
          </el-link>
          <el-link type="primary" style="margin-left:10px" v-if="scope.row.beExistsVoucher" :underline="false"
            @click="checkCertificate(scope.row)">
            查看凭证
          </el-link>
          <el-link type="primary" style="margin-left:10px" v-if="scope.row.status == '待确款' && scope.row.beExistsVoucher" :underline="false"
            @click="modifyCredentials(scope.row)">
            修改凭证
          </el-link>
        </template>
      </MyTable>
      <el-empty v-else style="height:100%" description="暂无数据" />
    </div>
    <el-dialog title="拒绝说明" :visible.sync="dialogVisible" width="30%">
      <el-input type="textarea" placeholder="请输入内容" v-model="textarea" maxlength="200" show-word-limit>
      </el-input>
      <ImgUpload v-model="imageList" :limit="3" style="position:relative;top:0px">
        <template slot="tip">
          <p class="el-upload__tip" style="margin:0px">
            备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过5M;最多上传三张图片
          </p>
        </template>
      </ImgUpload>
      <!-- <el-divider></el-divider> -->

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>

    <!-- 重新提交的时候 显示 提交凭证的按钮 -->
    <!-- <resetDialog ref="rest-dialog" @submitAgree="aftersaleDelivery" @getList="getList" /> -->

    <!--新增、修改凭证 -->
    <resetDialog ref="rest-dialog" @getList="getList" />

    <!--查看凭证状态、重新提交 -->
    <checkDialog ref="check-dialog" @resetSubmit="resetSubmit" />

    <proofDialog ref="proof-dialog" @getList="getList" />
  </div>
</template>

<script>
import ImgUpload from '@/components/imgUpload'
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable'
import Tabs from '@/components/Tabs'
import { buttonView } from '@/api/externalSalesRule/index'
import { aftersalePage, aftersaleReject, aftersaleAgree, aftersaleDelivery } from '@/api/afterSales/serviceSheet'

import resetDialog from "./components/afterSubmitCertificate.vue";
import checkDialog from "./components/afterCheckCertificate.vue";
import proofDialog from "./components/proofDailog.vue";
export default {
  components: { MyButton, MyTable, Tabs, ImgUpload, resetDialog, checkDialog, proofDialog },
  data() {
    return {

      times: 10000,//倒计时
      expanded: '',
      afterSalesControl: '', // 售后控制
      rowId: '',
      options: [
        {
          label: '退货退款',
          value: 'THTK'
        },
        {
          label: '退款',
          value: 'TK'
        },
        {
          label: '商家退款',
          value: 'SJTK'
        },
      ],

      dialogVisible: false,
      textarea: '',

      imageList: [],
      tabsList: [ // tabs 传的值
        {
          name: 'first',
          label: '全部售后单',
          value: 0,
        },
        {
          name: 'CHECKING',
          label: '审核中',
          value: 0,
        },
        {
          name: 'WAIT_EVIDENCE',
          label: '待举证',
          value: 0,
        },
        {
          name: 'WAIT_SALE',
          label: '待退货',
          value: 0,
        },
        {
          name: 'SALEING',
          label: '退货中',
          value: 0,
        },
        {
          name: 'REFUNDING',
          label: '退款中',
          value: 0,
        },
        {
          name: 'FINISH',
          label: '退款成功',
          value: 0,
        },
        {
          name: 'CANCEL',
          label: '退款关闭',
          value: 0,
        },
        {
          name: 'REJECT',
          label: '驳回',
          value: 0,
        },
        {
          name: 'WAIT_ENTER',
          label: '待确款',
          value: 0,
        }
      ],
      activeName: 'first', // tabs 传的值
      formTool: {
        keyWords: '',
        date: [],
        goodsName: '',
        shopName: '',
        orderCode: '',
        code: '',
        applyForType: ''
      },
      operation: false,
      selection: false,
      index: false,
      tableData: [
      ],
      tableOption: [],
      tableOptionFrist: [
        { label: '商品名称', prop: 'goodsName' },
        { label: '客户名称', prop: 'payShopName' }, // 这里表示自定义列, slot: true
        { label: '订单编号', prop: 'orderCode' },
        { label: '服务单号', prop: 'code' },
        { label: '申请时间', prop: 'orderTime' },
        { label: '客户联系方式', prop: 'contactsPhone' },
        { label: '数量', prop: 'qty' },
        { label: '应退金额', prop: 'amount', slot: true },
        // { label: '售后发起方', prop: 'type' },
        { label: '申请类型', prop: 'type' },
        { label: '操作', prop: 'caozuo', slot: true, width: '200px', align: 'center' }
      ],
      tableOptionProof: [
        { label: '商品名称', prop: 'goodsName' },
        { label: '客户名称', prop: 'payShopName' }, // 这里表示自定义列, slot: true
        { label: '订单编号', prop: 'orderCode' },
        { label: '服务单号', prop: 'code' }, 
        { label: '申请时间', prop: 'orderTime' },
        { label: '客户联系方式', prop: 'contactsPhone' },
        { label: '数量', prop: 'qty' },
        { label: '应退金额', prop: 'amount', slot: true },
        { label: '申请类型', prop: 'type' },
        { label: '剩余时间', prop: 'countDownTime', slot: true, width: '140px', align: 'left' },
        { label: '操作', prop: 'caozuo', slot: true, width: '200px', align: 'center' }
      ],
      tableOptionAfter: [
        { label: '商品名称', prop: 'goodsName' },
        { label: '客户名称', prop: 'payShopName' }, // 这里表示自定义列, slot: true
        { label: '订单编号', prop: 'orderCode' },
        { label: '服务单号', prop: 'code' }, 
        { label: '申请时间', prop: 'orderTime' },
        { label: '客户联系方式', prop: 'contactsPhone' },
        { label: '数量', prop: 'qty' },
        { label: '应退金额', prop: 'amount', slot: true },
        { label: '申请类型', prop: 'type' },
        {
          label: '处理倒计时', prop: 'countDownTimeAfter', slot: true, width: '140px', align: 'left'
        },
        { label: '操作', prop: 'caozuo', slot: true, width: '200px', align: 'center' }
      ],
      page: {
        total: 10,
        current: 1,
        size: 10
      }
    }
  },
  computed: {},
  watch: {},
  created() {
   
  },
  mounted() {
    this.tableOption = this.tableOptionFrist;
    this.buttonView()
    if(this.$store.state.tabStatus.afterSalesDocumentTab){
      this.activeName = this.$store.state.tabStatus.afterSalesDocumentTab;
      this.handleClick();
    }else{ 
      this.getList()
    }
  },
  methods: {
    renderHeader(h, { colum }, item) {
      return h('div', [
        h('span', '处理倒计时'),
        h('el-tooltip', {
          props: {
            content: '',
            content:
             <div><span style="color: #fff;margin-left:10px ">超出倒计时的处理方式   </span><br />
              <span style="color: #fff;margin-left:10px ">线上支付仅退款审核-自动退款;</span><br />
              <span style="color: #fff;margin-left:10px ">线下支付仅退款审核-自动扣除对应金额的保证金;  </span><br />
              <span style="color: #fff;margin-left:10px ">线上/线下支付退款退货审核-自动审核通过;</span><br />
              <span style="color: #fff;margin-left:10px ">线上/线下支付退货退款 确认收货-自动确认收货； </span><br />
              <span style="color: #fff;margin-left:10px "> 线下支付退货退款/仅退款 提交打款凭证-自动扣除对应金额的保证金;</span><br />
            </div>,
            placement: 'bottom',
            effect: 'dark',
          },
        }, [
          h('i', { class: 'el-icon-warning' })
        ])

      ]);
    }
    ,
    hilarity(e, index) {
      // this.tableData[index].countDownTime = ''
      // this.getList()
    },
    // 举证
    submitProof(row, type) {
      this.$refs['proof-dialog'].init(row, 'proof', type)
    },
    handleExpand() {
      this.expanded = !this.expanded;
    },
    // 根据商户查询数据权限判断前端按钮展示
    buttonView() {
      buttonView().then(res => {
        if (res) {
          this.afterSalesControl = res.afterSalesControl
        }
      })
    },
    // 查询
    getList(current) {
      if (current) {
        this.page.current = current
      }
      let params = {
        ...this.formTool,
        type: 'payee',
        status: this.activeName == 'first' ? '' : this.activeName,
        size: this.page.size,
        current: this.page.current,
        startOrderTime: '',
        endOrderTime: ''
      }
      if (this.expanded) {
        params.keyWords = "";
      } else {
        params.keyWords = this.formTool.keyWords;
      }
      params.startOrderTime = params.date ? params.date[0] : ''
      params.endOrderTime = params.date ? params.date[1] : ''
      aftersalePage(params).then(res => {
        // 当待审核事项 全部处理完以后,更新菜单红点提示
        this.tabsList && this.tabsList.length > 1 && this.tabsList[1].value > 0 && this.updateMenuDot(res)
        //
        if (res.code == 0) {
          this.tabsList[1].value = res.data.checkingQty
          this.tabsList[2].value = res.data.waitEvidenceQty //待举证
          this.tabsList[3].value = res.data.waitSaleQty   //待退货
          this.tabsList[4].value = res.data.saleQty     //退货中
          this.tabsList[5].value = res.data.refundingQty  //退款中
          this.tabsList[8].value = res.data.rejectQty    //驳回
          this.tabsList[9].value = res.data.waitEnterQty  //待确款
          this.tableData = res.data.page.records         //表格数据
          this.page.total = res.data.page.total          //总条数
        }
      })
    },
    // 重置查询条件
    reset() {
      // this.formTool.storeName = ''
      this.$refs.formTool.resetFields()
      this.formTool = { date: null, invoiceNo: '', content: '' }
      this.page.current = 1
      this.getList()
    },
    // 点击tab
    handleClick() {
      this.tableOption = [];
      if (this.activeName == 'WAIT_EVIDENCE' || this.activeName == 'REJECT') {
        this.$set(this,'tableOption',this.tableOptionProof)
      } else if (this.activeName == 'CHECKING' || this.activeName == 'SALEING' || this.activeName == 'REFUNDING' || this.activeName == 'WAIT_SALE') { 
        this.$set(this,'tableOption',this.tableOptionAfter)
      } else { 
        this.$set(this,'tableOption',this.tableOptionFrist)
      }
      this.$store.dispatch('tabStatus/setAfterSalesDocumentTab', this.activeName)
      this.getList(1)
      this.$forceUpdate();

    },
    // 查看详情
    detail(row) {
      this.$router.push({ path: '/ordercenter/ordercenter-afterSalesDocumentDetail', query: { afterSaleId: row.id } })
    },
    // 上传凭证后回调
    emitSubmit() {

    },
    resetSubmit(row) {
      this.$refs['rest-dialog'].init(row, 'reset');
    },
    // 查看凭证
    checkCertificate(row) {
      this.$refs['check-dialog'].init(row, 'detail', 'afterSale')
    },
    // 上传凭证
    uploadCredentials(row) {
      this.$refs['rest-dialog'].init(row, 'after');
    },
    // 修改凭证
    modifyCredentials(row) {
      this.$refs['rest-dialog'].init(row, 'reset');
    },
    // 点击同意确认收货
    clickAftersaleDelivery(row, rejectVoucher) {
      console.log(row)
      this.$confirm('此操作不可取消, 是否确认收货?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.aftersaleDelivery(row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },

    submitAgreeBefore(row, type) {
      // if (row.payType == 3) {
      //   if (type == '退货退款') {
      //     this.submitAgree(row)
      //   }
      //   if (type == '退款') {
      //     this.$refs['rest-dialog'].init(row, 'after');
      //   }
      // } else {
      //   this.submitAgree(row)
      // }
      this.submitAgree(row)

    },

    // 提交同意请求
    submitAgree(row, rejectVoucher) {
      this.$confirm('此操作将同意退款, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: row.id
        }
        rejectVoucher ? params.rejectVoucher = rejectVoucher : null
        aftersaleAgree(
          params
        ).then(res => {
          if (res.code == 0) {
            if (row.payType == 3) {
              this.$message.success('提交成功')
            } else {
              this.$message.success('款项将在48小时内原路返回')
            }
            this.getList()
          } else {
            this.$message.error(res.msg)
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });


    },

    // 点击表格确认收货
    aftersaleDelivery(row) {
      aftersaleDelivery(
        {
          id: row.id
        }
      ).then(res => {
        if (res.code == 0) {
          this.$message.success('确认收货')
          this.getList()
        } else {
          this.$message.error('收货失败')
        }
      })
    },
    // 点击表格驳回
    aftersaleReject(row) {
      this.rowId = row.id
      this.dialogVisible = true
    },
    /**
     * 设置菜单红点
     */
     updateMenuDot (res) {
      //当待处理事项 全部处理完以后,才做菜单更新
      if (!res || res.data.checkingQty != 0) {
        return
      }
      this.$store.dispatch('ClearRoutesDot', this.$route.path )
    },
    // 提交
    submit() {
      let params = {
        id: this.rowId,
        remark: this.textarea,
        rejectVoucher: this.imageList.length > 0 ? this.imageList.join(',') : ''
      }
      aftersaleReject(params).then(res => {
        if (res.code == 0) {
          this.$message.success('已驳回')
          this.textarea = ''
          this.imageList = []
          this.getList()
          this.dialogVisible = false
        } else {
          this.$message.error('驳回失败')
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.comContent {
  padding: 0px;
}

.expand {
  &_box {
    padding: 20px 30px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &_li {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 45%;
    margin-right: 3%;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 10px;
    word-break: break-all;

    strong {
      margin-right: 10px;
      font-weight: 400;
      color: #000;
    }

    span {
      color: #666;
    }

    &_more {
      position: relative;
    }

    &_link {
      color: #f5222d;

      &:hover {
        color: #3db4b9;
      }
    }

    &_img {
      width: 100px;
      height: 100px;
    }
  }
}

.center {
  margin: 0 24px;
  height: calc(100% - 170px);
}

.myTabs {
  height: 56px;
  padding: 0 24px;
  border-bottom: 1px solid #f5f5f5 !important;
}

.topHeader {
  padding: 0 24px;
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  line-height: 56px;
}

.el-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
}

::v-deep .el-dialog__footer {
  border-top: 1px #f5f5f5 solid;
  padding-top: 15px;
}

::v-deep .tooltip__popper {
  max-width: 200px !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  word-break: break-all !important;
}

::v-deep .el-statistic {
  text-align: left;
}
</style>
