<template>
  <div>
    <el-form ref="numberValidateFormOne"
             :model="numberValidateFormOne"
             label-width="100px"
             class="ruleFormCont"
             label-position="top"
             :rules="rules">
      <el-form-item label="企业名称"
                    prop="name">
        <el-input v-model="numberValidateFormOne.name"
                  placeholder="请输入企业名称"
                  style="width: 38vw" />
      </el-form-item>
      <el-form-item label="所在区域"
                    prop="provinceName">
                    <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;width: 38vw;">
                      <el-select v-model="numberValidateFormOne.provinceName"
                                 placeholder="请选择省"
                                 style="width: 12vw"
                                 @change="provinceBtn">
                        <el-option v-for="(item, index) in provinceData"
                                   :key="index"
                                   :label="item.rname"
                                   :value="item.id" />
                      </el-select>
                      <el-select v-model="numberValidateFormOne.cityName"
                                 placeholder="请选择市"
                                 style="width: 12vw;"
                                 @change="cityBtn">
                        <el-option v-for="(item, index) in cityData"
                                   :key="index"
                                   :label="item.rname"
                                   :value="item.id" />
                      </el-select>
                      <el-select v-model="numberValidateFormOne.areaName"
                                 placeholder="请选择区"
                                 style=" width: 12vw;"
                                 @change="areaBtn">
                        <el-option v-for="(item, index) in areaData"
                                   :key="index"
                                   :label="item.rname"
                                   :value="item.id" />
                      </el-select>
                    </div>
      </el-form-item>
      <el-form-item label="详细地址"
                    prop="address">
        <el-input v-model="numberValidateFormOne.address"
                  placeholder="请输入详细地址"
                  style="width: 38vw" />
      </el-form-item>
      <el-form-item label="发票类型"
                    prop="invoiceType">
        <el-checkbox-group v-model="numberValidateFormOne.invoiceType">
          <el-checkbox v-for="(item, index) in invoicetypeData"
                       :key="index"
                       :label="item.code"
                       :name="item.name">{{ item.name || "" }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   :loading="formOneload"
                   size="large"
                   @click="submitFormOne('numberValidateFormOne')">下一步</el-button>
      </el-form-item>
      <span class="tip"
            style="margin-bottom: 20px">
        提示：为保证结果准确性，请保证企业类型、证照编码与实际相符
      </span>
    </el-form>
  </div>
</template>
<script>
// import top from '../index/top/index.vue'
// import { mapGetters } from 'vuex'
import {
  regionProvince,
  regionCity,
  regionArea,
  getinvoicetypeList,
  postshopSubmit,
  postShopapplyHistory,
  getShopapplyHistory
} from "@/api/merchantsettlement/index";
// import { regionProvince, regionCity, regionArea } from '@/api/index'
export default {
  components: {},
  data () {
    return {
      invoicetypeData: [],
      formOneload: false,
      numberValidateFormOne: {
        name: '',
        province: "",
        provinceName: "",
        city: "",
        cityName: "",
        area: "",
        areaName: "",
        invoiceType: [],
        address: ''
      },
      provinceData: {},
      cityData: [],
      areaData: [],
      // 按钮等待
      formload: false,
      // // 表单对象的赋值
      // numberValidateForm: {},
      // 地区的列表
      areaList: [],
      // 不同身份的列表
      shopList: [],
      rules: {
        typeCode: [
          { required: true, message: `请选择类型`, trigger: `change` },
        ],
        licenceNo: [
          {
            required: true,
            message: `请输入医疗机构执业许可证`,
            trigger: `blur`,
          },
        ],
        idNumber: [
          { required: true, message: `请输入营业执照编码`, trigger: `blur` },
        ],
        concatName: [
          { required: true, message: `请输入法人名字`, trigger: `blur` },
        ],
        concatPhone: [
          { required: true, message: `请输入法人手机号码`, trigger: `blur` },
        ],
        scopeIds: [
          { required: true, message: `请选择经营范围`, trigger: `change` },
        ],
        name: [
          {
            required: true,
            message: `请输入企业名称`,
            trigger: `blur`,
          },
        ],
        provinceName:[
          {
            required: true,
            message: `请选择省/市/区`,
            trigger: `change`,
          },
        ],
        province: [
          {
            required: true,
            message: `请选择省份`,
            trigger: `change`,
          },
        ],
        city: [
          {
            required: true,
            message: `请选择地级市`,
            trigger: `change`,
          },
        ],
        area: [
          {
            required: true,
            message: `请选择市级区`,
            trigger: `change`,
          },
        ],
        address: [
          {
            required: true,
            message: `请输入详细地址`,
            trigger: `blur`,
          },
        ],
        invoiceType: [
          {
            required: true,
            message: `请选择发票类型`,
            trigger: `change`,
          },
        ],
      },
    };
  },
  props: {
    requestId: {
      type: String,
      default: "",
    },
    code: {
      type: String,
      default: "",
    },
  },
  mounted () {
    this.getinvoicetypeList();
    this.getProvince();

  },
  methods: {
    // 获取缓存的数据
    getShopapplyHistoryAction () {
      let typeCode = this.code
      getShopapplyHistory(typeCode, 2).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.numberValidateFormOne.name = res.data.name
            this.numberValidateFormOne.province = res.data.province
            this.numberValidateFormOne.provinceName = res.data.provinceName
            this.numberValidateFormOne.city = res.data.city
            this.numberValidateFormOne.cityName = res.data.cityName
            this.numberValidateFormOne.area = res.data.area
            this.numberValidateFormOne.areaName = res.data.areaName
            this.numberValidateFormOne.address = res.data.address
            if (res.data.invoiceType && res.data.invoiceType.length) {
              let invArr = res.data.invoiceType.split(',')
              let arr = []
              if (this.invoicetypeData && this.invoicetypeData.length) {
                this.invoicetypeData.forEach((e) => {
                  invArr.forEach((l) => {
                    if (e.code == l) {
                      arr.push(parseInt(l))
                    }
                  })
                })
              }
              this.numberValidateFormOne.invoiceType = arr
            }
          }
        }
      })
    },
    submitFormOne (formName) {
      this.formOneload = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const {
            address, area, areaName, city, cityName, invoiceType, name, province, provinceName
          } = this.numberValidateFormOne
          const d = {
            address, area, areaName, city, cityName, name, province, provinceName,
            invoiceType: invoiceType.join(','), requestId: this.requestId, typeCode: this.code
          }
          // this.numberValidateFormOne.invoiceType =
          //   this.numberValidateFormOne.invoiceType.toString();
          // this.numberValidateFormOne.requestId = this.requestId;
          postShopapplyHistory(d, 2).then(() => { })
          postshopSubmit(d).then((res) => {
            const { code, data } = res
            if (code === 0 && data && data.result) {
              this.$message.success(`提交成功！`);
              this.$emit("setStep", data.requestId);

            } else {
              this.$message.error(res.msg);
            }
            this.formOneload = false;
          }).catch(() => {
            this.formOneload = false;
          });
        } else {
          this.formOneload = false;
          return false;
        }
      });
    },
    getProvince () {
      regionProvince().then((res) => {
        if (res.code === 0) {
          this.provinceData = res.data;
          this.getShopapplyHistoryAction()
        }
      });
    },
    getinvoicetypeList () {
      getinvoicetypeList().then((res) => {
        if (res.code === 0) {
          this.invoicetypeData = res.data.reverse();
          this.numberValidateFormOne.invoiceType = [this.invoicetypeData[0].code]
        }
      });
    },
    provinceBtn (item) {
      this.cityData = [];
      this.areaData = [];
      const provinceIndex = this.provinceData.findIndex(
        (res) => res.id === item
      );
      this.numberValidateFormOne.provinceName =
        this.provinceData[provinceIndex].rname;
      this.numberValidateFormOne.province =
        this.provinceData[provinceIndex].id;
      regionCity(item).then((res) => {
        this.cityData = res.data;
        this.numberValidateFormOne.city = ``;
        this.numberValidateFormOne.area = ``;
      });
    },
    cityBtn (item) {
      this.areaData = [];
      const cityIndex = this.cityData.findIndex((res) => res.id === item);
      this.numberValidateFormOne.cityName = this.cityData[cityIndex].rname;
      this.numberValidateFormOne.city = this.cityData[cityIndex].id;
      regionArea(item).then((res) => {
        this.numberValidateFormOne.area = "";
        this.areaData = res.data;
      });
    },
    areaBtn (item) {
      const areaIndex = this.areaData.findIndex((res) => res.id === item);
      this.numberValidateFormOne.areaName = this.areaData[areaIndex].rname;
      this.numberValidateFormOne.area = this.areaData[areaIndex].id;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.down {
  margin-top: 24px;
  margin-bottom: 14px;
}
.tip {
  display: block;
  background: #fff9e6;
  border: 1px solid #ffdb88;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #595959;
  line-height: 22px;
  padding: 10px 20px;
  width: 38vw;
}

.ruleFormCont {
  width: 600px;
  // padding-bottom: 30px;
  .selectCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .itemCont {
      width: 22%;
      height: 70px;
      border: 2px solid #{$borderColor};
      border-radius: 4px;
      font-size: #{$fontSize};
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      color: #{$fontColor};
      text-align: center;
      line-height: 70px;
      position: relative;
      cursor: pointer;
    }
    .mainActive {
      border: 2px solid #{$borderColorActive};
      color: #{$fontColorActive};
      /* 三角形 */
      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        right: 0px;
        border-bottom: 25px solid #{$borderColorActive};
        border-left: 25px solid transparent;
        border-bottom-right-radius: 8px;
      }

      /* 三角形勾 */
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 6px;
        background: transparent;
        bottom: 6px;
        right: 3px;
        border: 2px solid white;
        border-top: none;
        border-right: none;
        transform: rotate(-55deg);
        z-index: 9;
      }
    }
  }
  .uploadCont {
    width: 600px;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    overflow-x: scroll;
  }
}
</style>
