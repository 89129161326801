<template>
    <div class="harf-block">
        <div class="big-block" v-if="showFormLeft">
            <div class="title-block">
                <span class="text">价格/库存</span>
            </div>
            <div class="form-content">
                <div class="row">
                    <div class="tip">ERP编码</div>
                    <div class="value input">
                        <el-input size="small" placeholder="请输入" v-model="formIn.medicineCode"  maxlength="20"/>
                    </div>
                </div>
                <div class="row" >
                    <div class="tip"><span class="weight" v-if="formIn.chargeFlag || ( goodsId != xiaocaihuId) ">*</span>  价格(元)</div> 
                    <div class="value input">
                        <el-input size="small" placeholder="请输入" v-model="formIn.salePrice"  v-positive-five-limit />
                    </div> 
                </div>
                <div class="row">
                    <div class="tip"> <span class="weight">*</span> 总库存</div>
                    <div class="value input">
                        <el-input size="small" placeholder="请输入" v-model="formIn.goodsSkuInventory"
                            :disabled="formIn.beSynStock" maxlength="8"/>
                    </div>
                    <div class="value fill">
                        <el-checkbox v-model="formIn.beSynStock" :disabled="!formIn.medicineCode"
                            @change="stockChange">自动同步库存</el-checkbox>
                    </div>
                </div> 
                <div class="row">
                    <div class="tip"><span class="weight">*</span>单位</div>
                    <div class="value input">
                        <el-select v-model="formIn.goodsSkuUnit" placeholder="请选择" size="small">
                            <el-option v-for="item in unitList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="row">
                    <div class="tip"><span class="weight">*</span>有效期至</div>
                    <div class="value input">
                        <el-date-picker v-model="formIn.expireTime" type="date" placeholder="请选择" size="small"  :editable="false" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                    <!-- <div class="value fill">
                        <el-checkbox v-model="formIn.beSynDate" :disabled="!formIn.medicineCode"
                            @change="dateChangeSyn">自动同步有效期和生产日期</el-checkbox>
                    </div> -->
                </div>
                <div class="row">
                    <div class="tip"><span class="weight">*</span>生产日期</div>
                    <div class="value input">
                        <el-date-picker v-model="formIn.producedTime" type="date" placeholder="请选择" size="small"  :editable="false" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                </div> 
                
                <div class="row">
                    <div class="tip"><span class="weight">*</span>活动开始时间</div>
                    <div class="value input">
                        <el-date-picker v-model="formIn.activityStartTime" type="datetime" placeholder="请选择"
                            :disabled="fromPage=='edit'?true:false"
                            size="small" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </div>
                    <!-- <div class="value fill small-tip">
                         <el-checkbox v-model="formIn.eventCountdownFlag" :true-label="1" :false-label="0">是否显示倒计时样式</el-checkbox> 
                    </div> -->
                </div>

                <div class="row">
                    <div class="tip"><span class="weight">*</span>活动结束时间</div>
                    <div class="value input">
                        <el-date-picker v-model="formIn.activityEndTime" :picker-options="pickerOptions" type="datetime"
                        default-time="23:59:59"
                        :disabled="fromPage=='edit'?true:false"
                            placeholder="请选择" size="small" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </div>
                    <div class="value fill small-tip">
                        <span style="font-size:12px;color:#606266;">
                            商品在活动开始时间到活动结束时间之间显示在app/PC。超过活动结束时间后不可编辑或延长时间
                        </span>
                    </div>
                </div>

                <div class="row" v-if="goodsId == xiaocaihuId ">
                    <div class="tip">  收取赠品金</div> 
                    <div class="value fill">
                        <el-checkbox v-model="formIn.chargeFlag"  :true-label="1" :false-label="0" > </el-checkbox>
                        <span style="color: #f5222d; font-size: 12px;">
                            赠品收取金额时，订单只能整单退货，退货时将赠品金额一并退回
                        </span> 
                    </div>
                </div> 

            </div>
        </div>

        <div class="big-block">
            <div class="title-block">
                <span class="text">图片/其他</span>
            </div>
            <div class="form-content">
                <div class="row">
                    <div class="tip"><span class="weight">*</span>商品标题</div>
                    <div class="value input">
                        <el-input size="small" placeholder="请输入" v-model="formIn.goodsSkuTitle" />
                    </div>
                </div>

                <div class="row">
                    <div class="tip">产品批号</div>
                    <div class="value input">
                        <el-input size="small" placeholder="请输入" v-model="formIn.batchNumber"   />
                    </div>
                </div>

                <div class="row mutlti">
                    <div class="tip">商品图片</div>
                    <div class="value mutlti v">
                        <div class="in-row">
                            <el-radio v-model="formIn.imgRegion" :label="1">使用平台图片</el-radio>
                            <el-radio v-model="formIn.imgRegion" :label="2">上传图片</el-radio>
                        </div>
                        <template v-if="formIn.imgRegion === 2">
                            <div class="in-row">
                                <div class="pic-layout">
                                    <div class="item" v-for="(item, index) in imageUrlList
                                        ? imageUrlList
                                        : []" :key="index">
                                        <el-image :ref="`myImg${index}`" :src="item" fit="cover" class="icon"
                                            :initial-index="index" :preview-src-list="imageUrlList" />
                                        <div class="preview">
                                            <el-image :src="SeeIcon" class="btn-icon"
                                                @click="handlePreview(`myImg${index}`)" />
                                            <el-image :src="DelIcon" class="btn-icon" @click="handleDeleteImage(index)" />
                                        </div>
                                    </div>
                                    <div class="item add-pic" @click="handleUpload()">
                                        <div class="image-wrap">
                                            <el-image :src="AddIcon" />
                                        </div>
                                        <span class="text">上传图片</span>
                                    </div>
                                </div>
                            </div>
                            <div class="in-row">
                                <span class="value small-tip">注意：上传图片需要审核，最多五张，图片尺寸建议1080*1080px</span>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="row mutlti big">
                    <div class="tip">商品标签</div>
                    <div class="value fill">
                        <div class="flag-block">
                            <!-- <span class="flag-title">商品标签</span> -->
                            <el-radio v-model="formIn.shopLabelId" :label="item.id" :key="item.id"
                                v-for="item in shopLabelList ? shopLabelList : []">
                                {{ item.labelName }}
                            </el-radio>
                        </div>
                    </div>
                </div>
                <div class="row contine">
                    <div class="tip"></div>
                    <div class="value fill small-tip">
                        <span>
                            标签是显示在app商品列表图上的边框/角标优惠信息。
                            商家标签可在商品管理-商家标签上传
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="tip">商家店铺页排序</div>
                    <div class="value input">
                        <el-input placeholder="请输入" v-model="formIn.sortCode" maxlength="5" />
                    </div>
                    <div class="value small-tip">
                        <span>注意：排序大的靠前，最大99999</span>
                    </div>
                </div>
                <div class="row mutlti">
                    <div class="tip">批件药检报告等</div>
                    <div class="value input fill">
                        <fileUpload v-model="fileList" @onRemoveHandler="onRemoveHandler" style="margin-bottom: 10px;">
                        </fileUpload>
                        <div class="small-tip">
                            <span>上传pdf或图片，且不超过10M</span>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="selectType && selectType.label == '特价'">
                    <div class="tip">商品活动说明</div>
                    <wangEnduit :value="formIn.activityDescription" :is-clear="isClear" :isDisabled="isDisabled"
                        @changeEndit="watchEndit" style="margin-left: 20px;" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import avater from "/public/img/default.png"
import fileUpload from "@/components/fileUpload";
import wangEnduit from "@/components/wangEnduit";
const IConst_Small = require("@/assets/public/icon-small.png");
const IConst_See_Icon = require("@/assets/zz/see-icon.png");
const IConst_Del_Icon = require("@/assets/zz/del-icon.png");
const IConst_Add_Icon = require("@/assets/zz/add-icon.png");
import {
  skuUpdate,
  skuAdd,
  skuId,
  groupInfos, 
  getErpStock,
  getErpPrice,
  postageAddSku,
  getErpDate
} from "@/api/salesControl/commodity";
import { getLabelInfoList } from "@/api/goods";
export default {
    components: { fileUpload, wangEnduit },
    props: {
        formIn: {
            type: Object,
            default: () => {
                return {
                    chargeFlag:0,  //0是不收费，1是收费
                    goodsSkuTitle: "", //商品标题
                    imgRegion: 1, //1 使用平台图片 2 上传图片
                    batchNumber: '',
                    shopLabelId: '', 
                    activityDescription:'',
                }
            }
        },
        selectType: {
            type: Object,
            default: () => {
                return {}
            }
        },
        fileList: {
            type: Array,
            default: () => {
                return []
            }
        },
        unitList: {
            type: Array,
            default: () => {
                return []
            }
        },
        imageUrlList: {
            type: Array,
            default: () => {
                return []
            }
        },
        shopLabelList: {
            type: Array,
            default: () => {
                return []
            }
        }, 
       
        isDisabled: {
            type: Boolean,
            default: () => {
                return false
            }
        },
    },
    data() {
        return { 
            xiaocaihuId:'1734837933549641729', // 小柴胡goodsId
            smallIcon: IConst_Small, 
            SeeIcon: IConst_See_Icon,
            DelIcon: IConst_Del_Icon,
            AddIcon: IConst_Add_Icon,
            pickerOptions: {
                disabledDate(date) {
                    // 如果没有后面的-8.64e7就是不可以选择今天的
                    return date.getTime() < Date.now() - 8.64e7;
                },
            },

            upperLimitRefreshCycle: [
                {
                    label: '活动期内',
                    value: 1
                },
                {
                    label: '单笔',
                    value: 2
                },
                {
                    label: '每天 (每天00:00~24:00)',
                    value: 5
                },
                {
                    label: '每周 (周一00:00~周日24:00)',
                    value: 3
                },
                {
                    label: '每月 (每月1号00:00~每月最后一天24:00)',
                    value: 4
                }
            ],
            fromPage: '', // 来源页面
            avater: avater,
            showFormLeft: false,
            isClear: false, // 清除富文本编辑内容
            goodsId:''
        }
    },
    beforeMount() { 
    },
    mounted() { 
        if (this.$route.query.fromPage) { 
            this.fromPage = this.$route.query.fromPage
        }
        if(this.$route.query.goodsId) {
            this.goodsId = this.$route.query.goodsId;
        }
        setTimeout(() => { 
            if (this.formIn.activityType == 1) {
                this.selectType.label = '一口价'
            }
            if (this.formIn.activityType == 2) {
                this.selectType.label = '特价'
            }
            if (this.formIn.activityType == 3) {
                this.selectType.label = '单品包邮'
            }
            if (this.formIn.activityType == 4) {
                this.selectType.label = '赠品'; 
            }
            this.showFormLeft = true
        }, 500)
        // console.log(this.formIn,'--this.formIn')
        console.log(this.selectType) 
        if(this.selectType && this.selectType.goodsId){
            this.goodsId = this.selectType.goodsId;  
        }
        this.changePurchaseUpperLimit();
        this.$forceUpdate();
    },
    methods: {
        handlePreview (refName) {
            if (this.$refs[refName]) {
                this.$refs[refName][0].showViewer = true;
            }
        },
        changePurchaseUpperLimit(){
            if(!this.formIn.purchaseUpperLimit){
                this.formIn.showUpperLimit = true
            }else{
                this.formIn.showUpperLimit = false 
            }
        },
        disabledDate2(time) {
            const today = new Date();
            const currentDay = today.getDay();
            const monday = new Date(today.setDate(today.getDate() - currentDay + (currentDay === 0 ? -6 : 1))); // 计算本周一的日期  

            return time.getTime() < monday.getTime() || time.getTime() > today.getTime();
        },
        watchEndit(val) {
            // 监听富文本编辑传过来的数据
            this.formIn.activityDescription = val;
        }, 
       
        async stockChange(e) {
            if(!this.formIn.medicineCode){
                this.$message.warning('请您先设置ERP编码') 
                this.$nextTick(() => {
                        this.formIn.beSynStock = false;
                    });
                return
            }
        },
        async stockChange(e) {
            // erp同步库存状态发生变化
            if (e) {
                const resp = await getErpStock({ medicineCode: this.formIn.medicineCode }) 
                this.$set(this.formIn,"goodsSkuInventory",String(resp))   
            } else { 
                this.$set(this.formIn,"goodsSkuInventory",'0')   
            }
        },
        dateChangeSyn() {
            let params = {};
            params.medicineCode = this.formIn.medicineCode;   
            if (!this.formIn.beSynDate) {
                this.$set(this.formIn,"producedTime",'')  
                this.$set(this.formIn,"expireTime",'')   
                return
            }
            getErpDate(params).then((res) => { 
                if (res.producedTime) {
                    this.$set(this.formIn,"producedTime",res.producedTime)  
                } 
                if (res.expireTime) {
                    this.$set(this.formIn,"expireTime",res.expireTime)   
                }
                if (!res.expireTime && !res.producedTime) {
                    this.formIn.beSynDate = false; 
                    this.$message.warning('ERP未推送有效期和生产日期，请您手动设置!')
                } else {
                    this.formIn.beSynDate = true;
                }
            }).catch((err) => { })
        },
        handleDeleteImage (samllIndex) {
        this.imageUrlList.splice(samllIndex, 1);
        },
        handleUpload() {
            this.$emit('handleUpload')
        },
        handleAddUserGroup() {
            this.$emit('handleAddUserGroup')
        },  
        // 删除上传的文件
        onRemoveHandler(file) {
            let index = this.fileList.indexOf(file);
            this.fileList.splice(index, 1);
        },
    }

}
</script>
<style lang="scss" scoped>
@import "../style/index.scss";
</style>

  