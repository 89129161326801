<!--  -->
<template>
  <div class="classification">
    <div class="header">
      <span class="font">商品列表</span>
    </div>
    <div class="conditions">
      <div style="margin-top:16px">
        <el-form ref="formRef"
                 :model="formRef"
                 :inline="true">
          <el-form-item prop="goodsCode"
                        label="商品编码"
                        label-width="70px">
            <el-input v-model="formRef.goodsCode"
                      size="small"
                      placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item prop="medicineCode"
                        label="商品ERP编码">
            <el-input v-model="formRef.medicineCode"
                      size="small"
                      placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item prop="goodsName"
                        label="商品名称">
            <el-input v-model="formRef.goodsName"
                      size="small"
                      placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item prop="factory"
                        label="生产厂家">
            <el-input v-model="formRef.factory"
                      size="small"
                      placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item prop="batchNumber"
                        label="产品批号">
            <el-input v-model="formRef.batchNumber"
                      size="small"
                      placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-form ref="formTool"
                 :model="formTool"
                 :inline="true">
          <el-form-item prop="goodsOneClassId"
                        label="商品分类">
            <el-select v-model="formTool.goodsOneClassId"
                       size="small"
                       placeholder="一级分类"
                       clearable
                       style="max-width:200px"
                       @change="changeOneClassId">
              <el-option v-for="item in goodsOneClassIdOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id" />
            </el-select>
            <el-select v-model="formTool.goodsTwoClassId"
                       size="small"
                       placeholder="二级分类"
                       clearable
                       style="max-width:200px;margin-left:16px"
                       @change="changeTwoClassId">
              <el-option v-for="item in goodsTwoClassIdOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id" />
            </el-select>
            <el-select v-model="formTool.goodsThreeClassId"
                       size="small"
                       placeholder="三级分类"
                       clearable
                       style="max-width:200px;margin-left:16px">
              <el-option v-for="item in goodsThreeClassIdOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       size="small"
                       @click="getTableList(1)">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="small"
                       @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div>
        <el-button size="small"
                   type="primary"
                   @click="clickBtn">批量上架</el-button>
        <el-button size="small"
                   type="primary"
                   @click="clickBtn">批量下架</el-button>
        <el-button size="small"
                   type="primary"
                   @click="clickBtn">导出</el-button>
      </div> -->
      <Tabs :tabsList="tabsList"
            :activeName.sync="activeName"
            @tab-click="handleChange" />
    </div>
    <div class="center"
         style="position:relative">
      <Table :tableData="tableData"
             :activeName="activeName"
             :selectionList="selectionList"
             :page="page"
             @getList="getList"
             @handleSelectionChange="handleSelectionChange"
             @detailHandle="detailHandle"
             @listing="listing"
             @delist="delist"
             @editHandle="editHandle"
             @addStash="addStash"
             @logs="logs"
             style="width: 100%;height: 100%;"></Table>
    </div>
  </div>
</template>

<script>
// import MyTable from '@/components/myTable'
import Table from './table.vue'
import Tabs from '@/components/Tabs'
import { goodsclassOne, goodsclassTwo } from '@/api/commodityreference'
import { skuPage, skuStatus } from '@/api/commodityList/index'
import avater from "/public/img/default.png"

export default {
  name: 'WareProduct',
  components: { Tabs, Table },
  data () {
    return {
      activeName: 'ON_SALE',
      tabsList: [ // tabs 传的值
        {
          name: 'ON_SALE',
          label: '销售中',
          value: 0,
        },
        {
          name: 'MERCHANT_REMOVAL',
          label: '商家下架',
          value: 0,
        },
        {
          name: 'PLATFORM_REMOVAL',
          label: '平台下架',
          value: 0,
        },
        {
          name: 'BASE_AWAIT',
          label: '基库待审核',
          value: 0,
        },
        {
          name: 'BASE_ALREADY',
          label: '审核通过',
          value: 0,
        },
        {
          name: 'SKU_AWAIT',
          label: '上架待审核',
          value: 0,
        },
        {
          name: 'SKU_FAIL',
          label: '商品审核未通过',
          value: 0,
        },
        {
          name: 'BASE_TEMPORARY_FAIL',
          label: '基库审核未通过',
          value: 0,
        }
      ],
      goodsOneClassIdOptions: [],
      goodsTwoClassIdOptions: [],
      goodsThreeClassIdOptions: [],
      avater: avater,
      formRef: {
        batchNumber: '',
        goodsCode: '',
        medicineCode: '',
        goodsName: '',
        factory: '',
      },
      formTool: {
        goodsOneClassId: '',
        goodsTwoClassId: '',
        goodsThreeClassId: '',
      },
      selectionList: [],
      operation: true,
      selection: true,
      index: true,
      tableData: [],
      tableOption: [
        { label: '商品信息', prop: 'image', slot: true, minWidth: '200px' }, // 这里表示自定义列
        // { label: '商品名称', prop: 'name' },
        // { label: '生产厂商', prop: 'factory' },
        { label: '批准文号', prop: 'approvalNumber', width: "260px" },
        { label: '商品属性', prop: 'type' },
        { label: 'ERP编码', prop: 'medicineCode' },
        { label: '发布时间', prop: 'createTime', width: "180px" }
      ],
      page: {
        total: 0,
        current: 1,
        size: 20,
        pageSizes: [10, 20, 30, 50]
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.query()
    this.getTableList()
    // this.getWarebase()
  },
  mounted () {

  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    //切换tabs按钮
    handleChange () {
      this.getTableList(1)
    },
    // // 编辑sku
    // editHandle (row) {
    //   this.$router.push({
    //     path: '/productManage/wareProduct-editSku',
    //     query: row
    //   })
    //   console.log(row)
    // },
    // 批量下架
    clickBtn () {
      if (this.selectionList.length == 0) {
        this.$message.warning('请先选择要下架的数据')
      } else {
        var arr = []
        this.selectionList.forEach(item => {
          const obj = {
            goodsId: item.goodsId
          }
          arr.push(obj)
        })
        this.batchPutaway(arr)
      }
    },
    // 上架接口
    batchPutaway (params) {
      putaway(params).then(res => {
        if (res.code === 0) {
          this.$message.success('批量上架成功')
          this.resetForm()
          this.getTableList(1)
        } else {
          this.$message.error('批量上架失败')
        }
      })
    },
    // 重置查询条件
    resetForm () {
      this.page.current = 1
      this.page.size = 10
      this.$refs.formRef.resetFields()
      this.formTool.goodsOneClassId = ''
      this.formTool.goodsTwoClassId = ''
      this.formTool.goodsThreeClassId = ''
    },
    // 单条上架
    deleteHandle (row) {
      const params = [{
        goodsId: row.goodsId
      }]
      putaway(params).then(res => {
        if (res.code === 0) {
          this.$message.success('上架成功')
          this.resetForm()
          this.getTableList(1)
        } else {
          this.$message.error('上架失败')
        }
      })
    },
    // 切换分页
    getList (page) {
      this.page.current = page.current
      this.page.size = page.size
      this.getTableList()
    },
    // 勾选
    handleSelectionChange (val) {
      this.selectionList = val
      console.log(val)
    },
    // 查询
    getTableList (current) {
      if (current) { // 搜索条件改变从第一页开始查询
        this.page.current = current
      }
      const params = {
        ...this.formRef,
        ...this.formTool,
        status: this.activeName,
        current: this.page.current,
        size: this.page.size
      }
      console.log(params)
      skuPage(params).then(res => {
        if (res.code == 0 && res.data) {
          this.tableData = res.data.page.records
          this.page.total = res.data.page.total
          this.tabsList[0].value = res.data.staticVo.onSaleCount
          this.tabsList[1].value = res.data.staticVo.merchantRemovalCount
          this.tabsList[3].value = res.data.staticVo.baseAwaitCount
          this.tabsList[5].value = res.data.staticVo.skuAwaitCount
          this.tabsList[6].value = res.data.staticVo.skuFailCount
          this.tabsList[7].value = res.data.staticVo.baseTemporaryFailCount
        } else {
          this.tableData = []
          this.page.total = 0
        }
      })
    },
    // 日志
    logs (val) {
      console.log(val)
    },
    // 编辑
    editHandle (val) {
      if (this.activeName == 'BASE_TEMPORARY_FAIL' || this.activeName == 'BASE_AWAIT') {
        this.$router.push({
          path: '/productManage/goodsManage-edit-details',
          query: {
            activeName: this.activeName,
            goodsTemporaryId: val.goodsTemporaryId,
            type: 'edit'
          }
        })
      } else {
        this.$router.push({
          path: '/productManage/publishProduct-addGoodsToStore',
          query: {
            activeName: this.activeName,
            id: val.goodsId,
            type: 'edit'
          }
        })
      }
    },
    // 详情
    detailHandle (val) {
      if (this.activeName == 'BASE_TEMPORARY_FAIL' || this.activeName == 'BASE_AWAIT') {
        this.$router.push({
          path: '/productManage/goodsManage-edit-details',
          query: {
            activeName: this.activeName,
            goodsTemporaryId: val.goodsTemporaryId,
            type: 'detail'
          }
        })
      } else {
        this.$router.push({
          path: '/productManage/publishProduct-addGoodsToStore',
          query: {
            activeName: this.activeName,
            id: val.goodsId,
            type: 'detail'
          }
        })
      }
    },
    // 上架
    listing (item) {
      let params = {
        id: item.skuId,
        status: 'PUTAWAY'
      }
      skuStatus(params).then(res => {
        if (res.code == 0) {
          this.$message.success('上架成功')
          this.getTableList()
        } else {
          this.$message.success(res.msg)
        }
      })
    },
    // 下架
    delist (item) {
      let params = {
        id: item.skuId,
        status: 'SOLDOUT'
      }
      skuStatus(params).then(res => {
        if (res.code == 0) {
          this.$message.success('下架成功')
          this.getTableList()
        } else {
          this.$message.success('下架失败')
        }
      })
    },
    // 加入仓库
    addStash (val) {
      console.log(val)
      this.$router.push({
        path: '/productManage/publishProduct-addGoodsToStore',
        query: {
          activeName: this.activeName,
          id: val.goodsId,
          type: 'add'
        }
      })
    },
    // 搜索
    // clickSearch (current) {
    //   this.getWarebase(current)
    // },
    // 初次进来只查询一级分类 不联动
    query () {
      goodsclassOne().then(res => {
        if (res.code == 0) {
          this.goodsOneClassIdOptions = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 切换一级分类
    changeOneClassId (val) {
      if (val) {
        this.goodsclassTwo(val)
      } else {
        this.formTool.goodsTwoClassId = ''
        this.goodsTwoClassIdOptions = []
        this.formTool.goodsThreeClassId = ''
        this.goodsThreeClassIdOptions = []
      }
    },
    // 切换二级分类
    changeTwoClassId (val) {
      if (val) {
        this.goodsclassThree(val)
      } else {
        this.formTool.goodsThreeClassId = ''
        this.goodsThreeClassIdOptions = []
      }
    },
    // 查询一级分类
    goodsclassOne () {
      goodsclassOne().then(res => {
        if (res.code == 0) {
          this.goodsOneClassIdOptions = res.data
          if (this.goodsOneClassIdOptions.length > 0) {
            this.formTool.goodsOneClassId = this.goodsOneClassIdOptions[0].id
            // this.goodsclassTwo(this.goodsOneClassIdOptions[0].id)
          } else {
            this.formTool.goodsOneClassId = ''
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 查询二级分类
    goodsclassTwo (id) {
      goodsclassTwo(id).then(res => {
        if (res.code == 0) {
          this.goodsTwoClassIdOptions = res.data
          if (this.goodsTwoClassIdOptions.length > 0) {
            this.formTool.goodsTwoClassId = ''
            this.formTool.goodsThreeClassId = ''
            this.goodsThreeClassIdOptions = []
            // this.goodsclassThree(this.goodsTwoClassIdOptions[0].id)
          } else {
            this.formTool.goodsTwoClassId = ''
            this.formTool.goodsThreeClassId = ''
            this.goodsThreeClassIdOptions = []
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 查询三级分类
    goodsclassThree (id) {
      goodsclassTwo(id).then(res => {
        if (res.code == 0) {
          this.goodsThreeClassIdOptions = res.data
          if (this.goodsThreeClassIdOptions.length > 0) {
            this.formTool.goodsThreeClassId = ''
          } else {
            this.formTool.goodsThreeClassId = ''
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
  }
}
</script>
<style lang='scss' scoped>
.classification {
  padding: 0px;
}
.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px !important;
  border-bottom: 1px solid #f5f5f5;
  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }
  .el-form-item {
    margin: 0px 0px 0px 8px !important;
  }
}
.conditions {
  padding: 0 24px 0 24px;
}
.center {
  margin-top: 16px;
  padding: 0 24px 0 24px;
  height: calc(100% - 305px);
  ::v-deep .imageP {
    // width: calc(100% - 110px);
    p {
      margin: 0 0 0 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
</style>
