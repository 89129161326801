var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          height: "100px",
        },
      },
      [
        _c("img", {
          staticStyle: { width: "40px", heihgt: "40px" },
          attrs: { src: require("../../../assets/paysuccess.png"), alt: "" },
        }),
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "20px",
              "font-weight": "600",
              color: "#262626",
              "margin-left": "10px",
            },
          },
          [_vm._v("您已成功付款：")]
        ),
        _c("span", { staticStyle: { "font-size": "16px", color: "#F5222D" } }, [
          _vm._v("¥"),
        ]),
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "24px",
              "font-weight": "600",
              color: "#F5222D",
            },
          },
          [_vm._v(_vm._s(_vm.payInfo.sumPrice))]
        ),
      ]
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          height: "100px",
        },
      },
      [
        _c("img", {
          staticStyle: { width: "20px", heihgt: "20px" },
          attrs: { src: require("../../../assets/payaddress.png"), alt: "" },
        }),
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "16px",
              "font-weight": "600",
              color: "#262626",
            },
          },
          [_vm._v("货物寄送至：")]
        ),
        _c("span", { staticStyle: { "font-size": "16px", color: "#262626" } }, [
          _vm._v(
            _vm._s(_vm.payInfo.provinceName) +
              _vm._s(_vm.payInfo.cityName) +
              " " +
              _vm._s(_vm.payInfo.areaName) +
              " " +
              _vm._s(_vm.payInfo.address) +
              "（" +
              _vm._s(_vm.payInfo.concatName) +
              " 收） " +
              _vm._s(_vm.payInfo.concatPhone)
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          height: "100px",
        },
      },
      [
        _c(
          "p",
          {
            staticStyle: {
              "font-size": "16px",
              color: "#F5222D",
              cursor: "pointer",
            },
            on: { click: _vm.goGoodsList },
          },
          [_vm._v("已买到商品列表")]
        ),
        _c("div", { staticClass: "line" }),
        _c("span", { staticStyle: { "font-size": "16px", color: "#595959" } }, [
          _vm._v("供应商：" + _vm._s(_vm.getSupplier(_vm.payInfo.quickVoList))),
        ]),
      ]
    ),
    _c("div", {
      staticStyle: {
        width: "95%",
        height: "1px",
        border: "1px dashed #d9d9d9",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }