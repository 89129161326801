var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order" },
    [
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "table",
                      _vm._l(props.row.children, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("th", [_vm._v(" " + _vm._s(item.name) + " ")]),
                          _c("th", [_vm._v(" " + _vm._s(item.name) + " ")]),
                          _c("th", [_vm._v(" " + _vm._s(item.name) + " ")]),
                          _c("th", [_vm._v(" " + _vm._s(item.name) + " ")]),
                          _c("th", [_vm._v(" " + _vm._s(item.name) + " ")]),
                        ])
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "商品 ID", prop: "id" } }),
          _c("el-table-column", { attrs: { label: "商品名称", prop: "name" } }),
          _c("el-table-column", { attrs: { label: "描述", prop: "desc" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }