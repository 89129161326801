var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content", on: { click: _vm.getChatInfo } }, [
    _c("img", { attrs: { src: require("@/assets/chatservice.png"), alt: "" } }),
    _c("span", [_vm._v("进入客服")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }