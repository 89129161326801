<template>
    <div>
      <el-form ref="formTool" :model="formTool" label-width="100px">
       <div class="title-form">
        <span>
         企业名称: {{companyItem.sellerName}}
        </span>
        <span>
         企业税号: {{companyItem.sellerTaxpayerId}}
        </span>
       </div>
        <div class="header" style="margin: 10px 0">
          <el-form-item label="创建时间" prop="date">
            <el-date-picker
              v-model="formTool.date"
              value-format="yyyy-MM-dd"
              type="daterange"
              size="small"
              range-separator="-"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            />
          </el-form-item>
          <el-form-item prop="contractName" label="">
          <el-input style="width: 180px" v-model="formTool.contractName" placeholder="请搜索合同名称" clearable size="small" />
        </el-form-item>
        <el-form-item prop="draftShopName" label="">
          <el-input style="width: 180px" v-model="formTool.draftShopName" placeholder="请搜索医药公司名称" clearable size="small" />
        </el-form-item>
  
          <el-form-item label-width="0px">
            <el-button size="small" @click="reset()" plain>重置</el-button>
            <el-button size="small" type="primary" @click="getList(1)"
              >查询</el-button
            >
            <el-button size="small" type="primary" @click="goAddTax()"
              >开票</el-button
            > 
          </el-form-item>
        </div>
        <MyTable
          :showpage="true"
          :rowKeyProps="'id'"
          :table-data="tableData"
          :index="index"
          :selection="selection"
          :page="page"
          :operation="operation"
          :table-option.sync="tableOption"
          @page-change="getList"
          @handleSelectionChange="handleSelectionChange"
          :selectableCanBeDisable="true"
        >
          <template slot-scope="props" slot="csoInvoiceStatus">
            <span v-if="props.row.csoInvoiceStatus === 0">待开票</span>
            <span v-if="props.row.csoInvoiceStatus === 1">开票中</span>
            <span v-if="props.row.csoInvoiceStatus === 2">开票成功</span>
            <span v-if="props.row.csoInvoiceStatus === 3">开票失败</span>
          </template>
          <template slot="orderAmount" slot-scope="scope">
            {{ scope.row.orderAmount || "0.00" }}
          </template>
        </MyTable>
      </el-form>
    </div>
  </template>
  <script>
  import MyTable from "@/components/myTable";
  import {
    openInvoiceCreateBizRecord,
  } from "@/api/invoice";
  import 
{
   geCsotInvoiceList,
} from "@/api/compliance/invoiceManager"
  export default {
    components: { MyTable },
    props: {},
    computed: {
      visible() {
        return this.dialogShow;
      },
    },
    data() {
      return {
        stepInsecond: false,
        tableData: [],
        formTool: {
          date: [],
          contractName: "",
          draftShopName: "",
        },
        invoiceOptions: [],
        dialogShow: false,
        tableOption: [
          { label: "合同名称", prop: "contractName" },
          { label: "医药公司", prop: "draftShopName" },
          { label: "指标名称", prop: "name" },
          { label: "指标金额", prop: "price" },
          { label: "税率/征收率", prop: "csoTaxRate" },
          { label: "税额", prop: "csoTaxAmount" },
          { label: "价税合计", prop: "csoTotalAmount" },
          { label: "状态", prop: "csoInvoiceStatus" ,slot: true, width: "90px"},
          { label: "创建人", prop: "createBy", width: "120px" },
          { label: "创建时间", prop: "createTime", width: "90px" },
        ],
        page: {
          total: 0,
          current: 1,
          size: 10,
          pageSizes: [10, 20, 30, 50],
        },
        index: true,
        operation: false,
        selection: true,
        companyItem: {}, // 选择的企业信息
        selectTotal: 0, // 选择的总金额
      };
    },
    created() {},
    methods: {
      rowKey(row) {
        //row-key就是要指定一个key标识这一行的数据,保证唯一即可
        return row.id;
      },
      // 获取列表
      getList(current) {
        let params = {};
         if(current){ 
          params.current = current;
          this.page.current = current;
        }else{
          params.current = this.page.current;
        }
        params.size = this.page.size; 
        params.tag = "section";
        params = Object.assign(params, this.formTool);
        params.titleId = this.companyItem.id; // 开票主体id
  
        if (params.date && params.date.length > 0) {
          params.startTime = params.date[0];
          params.endTime = params.date[1];
          delete params.date;
        }
        geCsotInvoiceList(params).then((res) => {
          if (res) {
            // 如果 csoInvoiceStatus === 0 才能选中， beAllowOperation
            this.tableData = res.records.map((item) => {
              item.beAllowOperation = item.csoInvoiceStatus === 0;
              return item;
            });
            this.page.total = res.total;
          }
        });
      },
  
      // 勾选
      handleSelectionChange(val) {
        // console.log(val, "---val");
        this.selectList = val;
        this.countTotalMoney(val);
      },
  
      selectAll(selection) {
        this.selectionList = selection;
      },
      handleClose() {
        this.dialogShow = false;
      },
      reset() {
        this.formTool = {};
        this.getList();
      },
      /**
       * 发票开具
       * @param {*} e
       */
      async goAddTax() {
        if (!this.selectList.length) {
          this.$message.warning("请选择开票数据");
          return;
        }
        let targetIds = [];
        this.selectList.forEach((e) => {
          targetIds.push(e.id);
        });
        const obj = {
          targetIds: targetIds,
          openInvoiceMainId: this.companyItem.id,
        };
        await openInvoiceCreateBizRecord(obj)
          .then((resp) => {
            if (resp) {
              this.$message.success("开票成功");
              this.$emit("submit", "two");
            } else {
              this.$message.error("开票失败，重新登陆"); 
              // 清空指定的session
              sessionStorage.removeItem("sellerTaxpayerId");
              // 开票失败，重新登陆
            }
          })
          .catch((err) => {
            if (err && err.msg) {
              this.$message.error(err.msg);
              // 开票失败，重新登陆
               sessionStorage.removeItem('sellerTaxpayerId')
            }
          });
      },
      countTotalMoney(arr) {
        let money = 0;
        // let tax = 0
        arr.forEach((v) => {
          if (v.payeeAmount) {
            money += parseFloat(v.payeeAmount);
          }
          // if (v.taxAmount) {
          //     tax += parseFloat(v.taxAmount)
          // }
        });
        this.selectTotal = money.toFixed(2);
        // this.selectTax = tax.toFixed(2)
      },
  
      init(row, type, item) {
        this.dialogShow = true;
        this.companyItem = item;
        console.log(item, "item");
        // console.log(row, "row");
        // console.log(type, "type");
        this.getList();
      },
  
      Invoicing() {
        this.$emit("Invoicing");
      },
    },
  };
  </script>
  <style lang="scss">
  @import "@/styles/variables.scss";
  
  .btnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
  
  .classification {
    padding: 0px;
  
    .header {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0px 24px 0px 24px !important;
      .font {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 600;
        text-align: left;
        color: #262626;
      }
  
      .el-form-item {
        margin: 0px 0px 0px 8px !important;
      }
    }
  
    background: white;
  
    .top {
      display: flex;
      padding: 10px 0 10px 0;
  
      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
  
        .label {
          width: 120px;
        }
  
        .button {
          margin-right: 20px;
  
          ::v-deep .el-button {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
  
            &:hover {
              color: $subMenuActiveText;
              background-color: $butColor;
              border-color: $butColor;
            }
  
            &:active {
              color: $subMenuActiveText;
              background: $butColor;
              background-color: $butColor;
              border-color: $butColor;
            }
  
            &:focus {
              color: $subMenuActiveText;
              background-color: $butColor;
              border-color: $butColor;
            }
          }
        }
      }
    }
  
    .center {
      margin-top: 16px;
      padding: 0 24px;
      height: calc(100% - 131px);
  
      .shangjia {
        position: absolute;
        z-index: 2;
        bottom: -45px;
      }
    }
  
    .btn-warp {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  
  .btn-list {
    margin: 0 5px;
  }
  
  .title-form {
    margin:10px auto;
    span {
      margin-right: 20px;
    }
  }
  </style>
  