var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "商品信息变更日志",
        visible: _vm.dialogVisible,
        width: "70%",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { height: "600px", width: "100%", overflow: "auto" } },
        [
          _c("MyTable", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: {
              tableData: _vm.tableData,
              index: false,
              operation: false,
              "table-option": _vm.tableOption,
            },
            on: {
              "update:tableOption": function ($event) {
                _vm.tableOption = $event
              },
              "update:table-option": function ($event) {
                _vm.tableOption = $event
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }