<template>
  <div class="comContent">
    <!-- <div class="header">产品线基本信息</div> -->
    <div class="center">
      <div class="left_topic mb20"
           style="margin-top: 10px">产品线基本信息</div>
      <el-divider />
      <el-form ref="productLine"
               :model="productLineFrom"
               label-width="100px"
               class="ruleFormCont"
               label-position="left"
               :rules="rules">
        <el-form-item label="产品线名称"
                      prop="name">
          <el-input v-model="productLineFrom.name"
                    placeholder="请输入20字以内"
                    style="width: 325px" />
        </el-form-item>
        <el-form-item label="产品负责人"
                      prop="mangerHead">
          <div class="cpxhead">
            <el-autocomplete popper-class="my-autocomplete"
                             v-model="productLineFrom.mangerHead"
                             :fetch-suggestions="mangerSearch"
                             :trigger-on-focus="false"
                             placeholder="请输入内容"
                             max-length="11"
                             @select="selManger">
              <i class="el-icon-search el-input__icon"
                 slot="suffix"> </i>
              <template slot-scope="{ item }">
                <!-- <div class="name">name：{{ item.fullName }}</div> -->
                <div class="fzr">
                  <span class="name">{{ item.fullName }}</span>
                  <span class="addr">{{ item.phone }}</span>
                </div>
              </template>
            </el-autocomplete>
            <el-button type="primary"
                       icon="el-icon-circle-plus"
                       class="add"
                       style="margin-left: 20px"
                       @click="userEdit(false, 'product')">
              创建</el-button>
            <span class="mark">产品线负责人可以查看、编辑产品线，控制关联团队和产品</span>
          </div>
        </el-form-item>
        <el-form-item label="产品线说明"
                      prop="description">
          <el-input v-model="productLineFrom.description"
                    placeholder="请输入100字以内"
                    style="width: 325px"
                    type="textarea"
                    :rows="2">
          </el-input>
        </el-form-item>
        <div class="left_topic mb20"
             style="margin-top: 10px">产品信息</div>
        <el-divider />
        <div class="produceDetail">
          <el-row>
            <el-col>
              <el-form-item label="控销产品">
                <div class="shopImg">
                  <el-row>
                    <el-col :span="24">
                      <section class="product-info">
                        <el-image style="width: 100px; height: 100px"
                                  :src="productItem.image || avater"
                                  v-if="productItem.name" />
                        <section class="info">
                          <section v-if="productItem.name">
                            <div>{{ productItem.name }}</div>
                            <div>{{ productItem.factory }}</div>
                            <div>{{ productItem.specification }}</div>
                          </section>
                          <section class="choose">
                            <div class="code">
                              {{ productItem.approvalNumber }}
                            </div>
                            <el-button v-if="!isEditor"
                                       type="primary"
                                       size="small"
                                       @click="chooseProduct">选择产品</el-button>
                          </section>
                        </section>
                      </section>
                    </el-col>
                  </el-row>
                </div>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="控销分类"
                            prop="className">
                <el-select v-model="productLineFrom.className"
                           size="small"
                           value-key="productLineFrom.classId"
                           placeholder="请选择控销分类"
                           style="margin-left: 8px; width: 325px"
                           @change="handleSelectGroup">
                  <el-option v-for="item in salesControlType"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div class="mt20"
           style="margin-bottom: 24px">
        <el-button type="primary"
                   size="small"
                   @click="comfirm">提交</el-button>
        <el-button type=""
                   size="small"
                   @click="cancel">取消</el-button>
      </div>
    </div>
    <productDialog :unit-list="unitList"
                   :dialog-visible="dialogVisible"
                   @chooseProudctItem="chooseProudctItem" />
    <UserEdit ref="userEdit"
              @sure="userEditSure" />
  </div>
</template>
<script>
import productDialog from "./components/productDialog";
import productCreateDialog from "./components/productCreateDialog";
import UserEdit from "./components/userEdit.vue";
import {
  addProductLines,
  editProductLines,
  getShopUserList,
  getListNotBlank,
} from "@/api/saleRule/productLine";
import { fetchList } from "@/api/systemUser";
import avater from "/public/img/default.png";
const debounce = (function () {
  let timer = 0;
  return function (func, delay) {
    clearTimeout(timer);
    timer = setTimeout(func, delay);
  };
})();
export default {
  components: { productDialog, productCreateDialog, UserEdit },
  data () {
    return {
      isEditor: false,
      tableIndex: 0, // 点击单位表格中的index
      // checkIndex: 0, // 选中的index
      dialogVisible: false, // 弹窗
      unitList: ["粒", "盒"],
      avater: avater,
      createProductdialogVisible: false,
      productLineFrom: {
        id: null,
        name: "",
        description: "",
        productLineGoodsQO: {},
        mangerHead: "",
        classId: '',
        className: ''
      },
      productItem: {},
      page: {
        total: 0,
        current: 1,
        size: 100,
      },
      salesControlType: [],
      rules: {
        // description: [
        //   { required: true, message: `请填写产品线说明`, trigger: `blur` },
        // ],
        name: [
          { required: true, message: `请填写产品线名称`, trigger: `blur` },
        ],
        mangerHead: [
          { required: true, message: `请选择产品线负责人`, trigger: `change` },
        ],
        className: [
          { required: true, message: `请选择控销分类`, trigger: `change` },
        ],
      },
      mangerHeadInfo: null,
    };
  },
  created () {
    this.isEditor = Boolean(this.$route.query.isEditor) || false;
    this.id = this.$route.query.id || null;
    this.shopDetail = this.$route.query;
    this.getClassList();
    this.initEditor();
  },
  methods: {
    initEditor () {
      if (this.isEditor) {
        let productItem = JSON.parse(sessionStorage.getItem("podurctItem"));
        this.mangerHeadInfo = {
          userId: productItem.managerId,
          username: productItem.managerName,
        }
        this.productLineFrom.mangerHead = productItem.managerName;
        this.productLineFrom.name = productItem.name;
        this.productLineFrom.description = productItem.description;
        this.productLineFrom.classId =
          productItem.productLineGoodsDTO.classId + "";
        this.productLineFrom.className =
          productItem.productLineGoodsDTO.className;
        this.productItem.name = productItem.productLineGoodsDTO.goodsName;
        this.productItem.image = productItem.productLineGoodsDTO.goodsImg;
        this.productItem.specification =
          productItem.productLineGoodsDTO.goodsSpecification;
        this.productItem.approvalNumber =
          productItem.productLineGoodsDTO.goodsApprovalNumber;
        this.productItem.goodsId = productItem.productLineGoodsDTO.goodsId;
      }
    },
    userEdit (obj, type) {
      this.$refs["userEdit"].init(obj, type);
    },
    userEditSure () { },
    // 选择产品线负责人
    selManger (item) {
      this.mangerHeadInfo = item;
      this.productLineFrom.mangerHead = item.phone;
    },
    mangerSearch (queryString, cb) {
      console.log('-----------');

      debounce(() => {
        getShopUserList({
          phone: queryString,
          size: 20,
          current: 1,
        }).then((res) => {
          cb(res.data || []);
        });
      }, 300);
    },
    // 获取分类列表
    getClassList () {
      getListNotBlank().then((res) => {
        console.log(res);
        this.salesControlType = res;
      });
    },
    handleSelectGroup (val) {
      for (let i = 0; i < this.salesControlType.length; i++) {
        if (val == this.salesControlType[i].id) {
          this.productLineFrom.className = this.salesControlType[i].name;
          this.productLineFrom.classId = this.salesControlType[i].id;
        }
      }
      this.$forceUpdate();
    },

    // 创建产品线负责人
    createProductCommander () {
      this.createProductdialogVisible = true;
    },

    // 提交产品
    submitCreateCheck () { },

    // 选择产品
    chooseProduct () {
      this.dialogVisible = true;
    },

    chooseProudctItem (item) {
      // console.log(item);
      this.productItem = item;
    },

    // 提交
    comfirm () {
      console.log(this.mangerHeadInfo);
      if (
        this.mangerHeadInfo == null ||
        !this.mangerHeadInfo.userId
        // ||this.mangerHeadInfo.phone != this.productLineFrom.mangerHead
      ) {
        this.productLineFrom.mangerHead = "";
      }
      this.$refs["productLine"].validate((valid) => {
        if (!this.productItem.goodsId) {
          this.$message.error("请选择产品");
          return;
        }
        if (valid) {
          const params = {
            id: this.id,
            name: this.productLineFrom.name,
            description: this.productLineFrom.description,
            managerId: this.mangerHeadInfo.userId,
            managerName: this.mangerHeadInfo.username,
            // managerPhone: this.mangerHeadInfo.phone,
            productLineGoodsQO: {
              classId: this.productLineFrom.classId,
              className: this.productLineFrom.className,
              goodsId: this.productItem.goodsId,
              goodsName: this.productItem.name,
              goodsFactory: this.productItem.factory,
              goodsSpecification: this.productItem.specification,
              goodsApprovalNumber: this.productItem.approvalNumber,
              goodsImg: this.productItem.image,
            },
          };

          console.log(params);
          if (this.id == null || this.id == undefined) {
            delete this.id;
          }

          if (this.isEditor) {
            // 修改产品线
            editProductLines(params).then((res) => {
              if (res) {
                this.$message.success("修改成功");
                this.cancel();
              } else {
                this.$message.success("修改失败");
              }
            });
          } else {
            addProductLines(params).then((res) => {
              if (res) {
                this.$message.success("新增成功");
                this.cancel();
              } else {
                this.$message.success("新增失败");
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      console.log(this.productLineFrom);
    },

    // 返回
    cancel () {
      this.$router.push("/salesControlMange/salesControl-product-index");
    },
  },
};
</script>
<style lang="scss" scoped>
.comContent {
  padding: 10px 0;
}

.header {
  padding: 0 24px;
  height: 56px;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 700;
  text-align: left;
  color: #262626;
  line-height: 56px;
  border: 1px solid #f5f5f5;
}
.center {
  margin: 0 24px;
}

.product-info {
  display: flex;
  justify-content: flex-start;
  .info {
    margin-left: 12px;
    div {
      line-height: 30px;
    }
  }
}
.choose {
  min-width: 325px;
  display: flex;
  justify-content: space-between;
}
.code {
  // width: 100px;
  display: flex;
  flex: 1;
}
.cpxhead {
  display: flex;
  align-items: center;
  .fzr {
    display: flex;
    align-items: center;
    .name {
      margin-right: 10px;
    }
  }
}
.mark {
  color: #f56c6c;
  font-size: 12px;
  margin-left: 10px;
  width: 200px;
  line-height: 1.5;
  display: inline-block;
  font-weight: bold;
}

::v-deep.el-form-item {
  margin-bottom: 22px !important;
}
</style>
