var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "listContent" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "multipleTable",
          staticStyle: { width: "100%", "margin-bottom": "20px" },
          attrs: {
            data: _vm.goodsList,
            "row-key": "id",
            border: "",
            "element-loading-text": "拼命加载中",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(255, 255, 255, 0.8)",
            type: "selection",
            "tree-props": { children: "children", hasChildren: "hasChildren" },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { prop: "post", label: "商品信息", width: "500" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "goodList" }, [
                      _c(
                        "div",
                        { staticClass: "img-div" },
                        [
                          _c("el-image", {
                            staticClass: "l",
                            attrs: {
                              src: scope.row.image
                                ? scope.row.image
                                : _vm.avater,
                              alt: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "r" }, [
                        _c("div", [
                          _c("span", { staticClass: "txt-line-1 name" }, [
                            _vm._v(_vm._s(scope.row.name)),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "rr txt-line-1" }, [
                            _vm._v(_vm._s(scope.row.specification)),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "rr txt-line-1" }, [
                            _vm._v(_vm._s(scope.row.approvalNumber)),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "rr txt-line-1" }, [
                            _vm._v(_vm._s(scope.row.factory)),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "post", label: "销售价格" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        " ￥ " +
                          _vm._s(scope.row.minPrice) +
                          " ~ " +
                          _vm._s(scope.row.maxPrice) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }