<template>
  <div class="fill-product-info-root">
    <div class="product-info">
      <div class="pic-frame">
        <el-image :src="smallIcon" class="pic" />
      </div>
      <div class="txt-content">
        <div class="big-title">美林 布洛芬混悬液</div>
        <div class="desp">200ml*1支/盒</div>
        <div class="desp">天津中新药业集团股份有限公司第六中药厂</div>
        <div class="desp">国药准字Z12020025</div>
      </div>
      <div class="flag">一口价</div>
    </div>
    <div class="big-content">
      <div class="harf-block">
        <div class="big-block">
          <div class="title-block">
            <span class="text">价格/库存</span>
          </div>
          <div class="form-content">
            <div class="row">
              <div class="tip">ERP编码</div>
              <div class="value input">
                <el-input size="small" placeholder="请输入" />
              </div>
            </div>
            <div class="row">
              <div class="tip">价格</div>
              <div class="value input">
                <el-input size="small" placeholder="请输入" />
              </div>
              <div class="value fill">
                <el-checkbox>自动同步价格</el-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="tip">市场价</div>
              <div class="value input">
                <el-input size="small" placeholder="请输入" />
              </div>
            </div>
            <div class="row">
              <div class="tip">建议零售价</div>
              <div class="value input">
                <el-input size="small" placeholder="请输入" />
              </div>
            </div>
            <div class="row">
              <div class="tip">总库存</div>
              <div class="value input">
                <el-input size="small" placeholder="请输入" />
              </div>
              <div class="value fill">
                <el-checkbox>自动同步库存</el-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="tip">单位</div>
              <div class="value input">
                <el-select v-model="value" placeholder="请选择" size="small">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="tip">起批量</div>
              <div class="value input">
                <el-input size="small" placeholder="请输入" />
              </div>
              <div class="value fill">
                <el-checkbox>倍数购买</el-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="tip">有效期至</div>
              <div class="value input">
                <el-date-picker v-model="value1" type="date" placeholder="请选择" size="small">
                </el-date-picker>
              </div>
            </div>
            <div class="row">
              <div class="tip">生产日期</div>
              <div class="value input">
                <el-date-picker v-model="value1" type="date" placeholder="请选择" size="small">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="big-block">
          <div class="title-block">
            <span class="text">图片/其他</span>
          </div>
          <div class="form-content">
            <div class="row">
              <div class="tip">商品标题</div>
              <div class="value input">
                <el-input size="small" placeholder="请输入" />
              </div>
            </div>
            <div class="row">
              <div class="tip">产品批号</div>
              <div class="value input">
                <el-input size="small" placeholder="请输入" />
              </div>
            </div>
            <div class="row mutlti">
              <div class="tip">商品图片</div>
              <div class="value mutlti v">
                <div class="in-row">
                  <el-radio v-model="radio" label="1">使用平台图片</el-radio>
                  <el-radio v-model="radio" label="2">上传图片</el-radio>
                </div>
                <div class="in-row">
                  <div class="pic-layout">
                    <div class="item" v-for="(item, index) in imageUrlList ? imageUrlList : []" :key="index">
                      <el-image :ref="`myImg${index}`" :src="item" fit="cover" class="icon" :initial-index="index"
                        :preview-src-list="imageUrlList" />
                      <div class="preview">
                        <el-image :src="SeeIcon" class="btn-icon" @click="handlePreview(`myImg${index}`)" />
                        <el-image :src="DelIcon" class="btn-icon" @click="handleDeleteImage(index)" />
                      </div>
                    </div>
                    <div class="item add-pic" @click="handleUpload()">
                      <div class="image-wrap">
                        <el-image :src="AddIcon" />
                      </div>
                      <span class="text">上传图片</span>
                    </div>
                  </div>
                </div>
                <div class="in-row">
                  <span class="value small-tip">注意：上传图片需要审核，最多五张，图片尺寸建议1080*1080px</span>
                </div>
              </div>
            </div>
            <div class="row mutlti big">
              <div class="tip">商品标签</div>
              <div class="value fill">
                <div class="flag-block">
                  <span class="flag-title">商品标签</span>
                  <el-radio v-model="radio" label="1">药潺潺</el-radio>
                  <el-radio v-model="radio" label="2">尾货清仓</el-radio>
                  <el-radio v-model="radio" label="3">特惠</el-radio>
                </div>
              </div>
            </div>
            <div class="row contine">
              <div class="tip"></div>
              <div class="value fill small-tip">
                <span>
                  标签是显示在app商品列表图上的边框/角标优惠信息。
                  商家标签可在商品管理-商家标签上传
                </span>
              </div>
            </div>
            <div class="row">
              <div class="tip">商家店铺页排序</div>
              <div class="value input">
                <el-input placeholder="请输入" />
              </div>
              <div class="value small-tip">
                <span>注意：排序大的靠前，最大99999</span>
              </div>
            </div>
            <div class="row mutlti">
              <div class="tip">批件药检报告等</div>
              <div class="value input fill">
                <el-upload class="upload-demo" :action="action" :on-preview="handlePreviewUpload"
                  :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="limit"
                  :on-exceed="handleExceed" :file-list="fileList" :on-success="onSuccessUpload"
                  accept=".jpg, .png, .pdf,.bmp,.jpeg">
                  <div class="sigle-row-up-btns">
                    <el-button size="small">点击上传</el-button>
                    <div class="el-upload__tip">上传pdf或图片，且不超过10M</div>
                  </div>
                </el-upload>
                <!-- <span>上传pdf或图片，且不超过10M</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="harf-block">
        <div class="big-block">
          <div class="title-block">
            <span class="text">销售规则</span>
          </div>
          <div class="form-content">
            <div class="row">
              <div class="tip">配置方式</div>
              <div class="value fill">
                <el-radio v-model="radio" label="1">
                  设置区域、客户类型及黑名单
                </el-radio>
                <el-radio v-model="radio" label="2">白名单</el-radio>
              </div>
            </div>
            <div class="row mutlti big">
              <div class="tip">地域</div>
              <div class="value fill">
                <div class="flag-block">
                  <el-radio v-model="radio" label="1" v-for="(item, index) in [1, 1, 1, 1, 1, 1]" :key="index">
                    药潺潺
                  </el-radio>
                </div>
              </div>
            </div>
            <div class="row contine">
              <div class="tip"></div>
              <div class="value fill small-tip">
                <span> 注意：仅限已选地域对应客户可购买当前商品。 </span>
              </div>
              <div class="value">
                <el-button size="small" icon="el-icon-plus" @click="handleAddArea">新增地域</el-button>
              </div>
            </div>
            <div class="row mutlti big">
              <div class="tip">客户类型</div>
              <div class="value fill">
                <div class="flag-block">
                  <el-checkbox v-for="(item, index) in [1, 1, 1, 1, 1, 1, 1, 1]" :key="index">
                    诊所
                  </el-checkbox>
                </div>
              </div>
            </div>
            <div class="row contine">
              <div class="tip"></div>
              <div class="value fill small-tip">
                <span> 注意：被勾选的客户类型对应的客户可购买当前商品。 </span>
              </div>
            </div>
            <div class="row mutlti big">
              <div class="tip">黑名单</div>
              <div class="value fill">
                <div class="flag-block">
                  <el-radio v-model="radio" label="1" v-for="(item, index) in [1, 1, 1, 1, 1, 1]" :key="index">
                    药潺潺
                  </el-radio>
                </div>
              </div>
            </div>
            <div class="row contine">
              <div class="tip"></div>
              <div class="value fill small-tip">
                <span> 注意：黑名单客户不可购买当前商品。 </span>
              </div>
              <div class="value">
                <el-button size="small" icon="el-icon-plus" @click="handleAddUserGroup">
                  新增用户组
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="gloabl-btn" @click="handleLookOtherSku">查看其他sku</div> -->
    </div>
    <div class="bottom">
      <el-button @click="handleBack">返回上一页</el-button>
      <el-button type="primary">发布商品</el-button>
    </div>

    <UploadImageView ref="imgupload" request-id="1" :imgType="imgType" @upload="uploadSubmit" />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import UploadImageView from "@/views/myQualification/components/UploadImage.vue";

const IConst_Small = require("@/assets/public/icon-small.png");
const IConst_See_Icon = require("@/assets/zz/see-icon.png");
const IConst_Del_Icon = require("@/assets/zz/del-icon.png");
const IConst_Add_Icon = require("@/assets/zz/add-icon.png");

export default {
  components: { ElImageViewer, UploadImageView },
  data () {
    return {
      smallIcon: IConst_Small,

      SeeIcon: IConst_See_Icon,
      DelIcon: IConst_Del_Icon,
      AddIcon: IConst_Add_Icon,

      action: process.env.VUE_APP_BASE_API + "/product/goodsbase/upload",

      imgType: "",
      IConst_Max_Pic_Count: 5,
      imageUrlList: [],
      limit: 1,
      fileList: [],
    };
  },
  methods: {
    handleAddArea () {
      this.$router.push(
        "/productManage/salesControl-controlPinForm-areaTemplate"
      );
    },
    handleAddUserGroup () {
      this.$router.push(
        "/productManage/salesControl-controlPinForm-userTemplate"
      );
    },
    handleLookOtherSku () {
      this.$router.push({
        path: "/productManage/publishProduct-addGoodsToStore",
        query: {
          activeName: "add",
          id: "1715202889905967106",
          type: "add",
        },
      });
    },
    handleUpload (bigIndex) {
      //this.currentBigIndex = bigIndex;
      this.$refs["imgupload"].open(this.imgType);
    },
    uploadSubmit (obj) {
      const { type, url } = obj;
      this.imageUrlList.push(url);
    },
    handlePreview (refName) {
      if (this.$refs[refName]) {
        this.$refs[refName][0].showViewer = true;
      }
    },
    handleDeleteImage (samllIndex) {
      this.imageUrlList.splice(samllIndex, 1);
    },
    handleBack () {
      this.$emit("preStep");
    },

    handleRemove (file, fileList) {
      console.log(file, fileList);
    },
    handlePreviewUpload (file) {
      console.log(file);
    },
    handleExceed (files, fileList) {
      this.$message.warning(
        `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    onSuccessUpload (res, file, fileList) { 
      this.fileLength++;
      // 这里需要根据你自己的接口返回数据格式和层级来自行修改
      if (res.data) {
        // 判断接口上传成功
        if (this.fileList.length < this.limit) {
          // 未超限时，把接口返回的图片url地址添加到fileList
          let datas = res.data;
          datas.name = res.data.fileName;
          this.fileList.push(datas);
        }
      } else {
        // 判断接口上传失败
        this.syncElUpload();
        this.$refs.uploadRef.clearFiles();
        this.$message({ type: "error", message: res.msg });
      }
      if (this.fileLength === fileList.length) {
        this.isUploading = false;
      }
    },
    success (res) {
      console.log(res);
    },
  },
};
</script>

<style lang="scss" scoped>
.fill-product-info-root {
  .product-info {
    width: 548px;
    height: 146px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    padding: 24px;
    box-sizing: border-box;
    position: relative;

    .pic-frame {
      width: 98px;
      height: 98px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      .pic {
        width: 72px;
        height: 86px;
      }
    }

    .txt-content {
      flex: 1;
      margin-left: 30px;

      .big-title {
        font-size: 16px;
        font-weight: 500;
        color: #262626;
        line-height: 32px;
      }

      .desp {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #595959;
        line-height: 22px;
      }
    }

    .flag {
      position: absolute;
      top: 0;
      right: 0;
      width: 72px;
      height: 28px;
      background: #ffb536;
      border-radius: 0px 4px 0px 8px;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .big-content {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    position: relative;

    .gloabl-btn {
      position: absolute;
      right: 0;
      bottom: 36px;
      font-size: 14px;
      font-weight: 400;
      color: #8c8c8c;
      cursor: pointer;
    }

    .harf-block {
      flex: 1;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;

      &:first-child {
        border-right: 1px solid #f2f2f2;
        margin-right: 16px;
        padding-right: 16px;
        box-sizing: border-box;
      }

      .big-block {
        &:not(:first-child) {
          margin-top: 12px;
        }
      }

      .title-block {
        height: 40px;
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 12px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          width: 3px;
          height: 16px;
          background: #f5222d;
          border-radius: 2px;
          transform: translateY(-50%);
        }

        .text {
          font-size: 16px;
          font-weight: 600;
          text-align: left;
          color: #f6212d;
        }
      }

      .form-content {
        .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px 0;
          box-sizing: border-box;

          &.mutlti {
            align-items: flex-start;
          }

          &.contine {
            padding-top: 0;
          }

          &.big {
            .tip {
              padding-top: 16px;
            }
          }

          .tip {
            width: 5em;
            text-align: right;
            font-size: 14px;
            font-weight: 400;
            text-align: right;
            color: #262626;
          }

          .value {
            margin-left: 16px;

            &.input {
              width: 204px;

              ::v-deep .el-date-editor.el-input,
              ::v-deep .el-date-editor.el-input__inner {
                width: 100%;
              }
            }

            &.fill {
              flex: 1;
              width: 0;
            }

            &.small-tip {
              flex: 1;
              width: 0;
              font-size: 14px;
              font-weight: 400;
              text-align: left;
              color: #8c8c8c;
            }

            &.mutlti {
              flex: 1;
              width: 0;
              display: flex;

              &.v {
                flex-direction: column;

                .in-row {
                  &:not(:first-child) {
                    margin-top: 8px;
                  }
                }
              }
            }

            .flag-block {
              width: 100%;
              background: #fafafa;
              border: 1px solid #d9d9d9;
              border-radius: 4px;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              padding: 8px 16px 16px 16px;
              box-sizing: border-box;
              flex-wrap: wrap;

              .flag-title {
                font-size: 14px;
                font-weight: 400;
                color: #262626;
                margin-right: 8px;
              }

              &>* {
                margin-top: 8px;
              }
            }
          }

          .in-row {
            .value {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .bottom {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    border-top: 1px solid #f2f2f2;
  }

  .pic-layout {
    display: flex;

    .item {
      width: 86px;
      height: 86px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      position: relative;
      overflow: hidden;

      &+.item {
        margin-left: 8px;
      }

      .icon {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .preview {
        position: absolute;
        visibility: hidden;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background: #000000;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn-icon {
          width: 16px;
          height: 16px;
          cursor: pointer;

          &+.btn-icon {
            margin-left: 8px;
          }
        }
      }

      &:hover {
        .preview {
          visibility: visible;
        }
      }

      &.add-pic {
        border: 1px dashed #d9d9d9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .image-wrap {
          border: 1px dashed #d9d9d9;
          width: 20px;
          height: 20px;

          &>* {
            width: 100%;
            height: 100%;
          }
        }

        .text {
          font-size: 14px;
          font-weight: 400;
          color: #595959;
          margin-top: 8px;
        }
      }
    }
  }

  .sigle-row-up-btns {
    display: flex;

    .el-upload__tip {
      margin-left: 8px;
    }
  }
}
</style>
