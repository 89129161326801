var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "area-select-root" },
    [
      _c("el-cascader", {
        attrs: {
          props: _vm.cprops,
          options: _vm.provinceData,
          disabled: _vm.disabled,
        },
        on: { change: _vm.handleChange },
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }