<template>
  <div class="dynamic-item">
    <div class="tip">
      <span class="weight" v-if="dataItem && dataItem.isMust">*</span>
      <span class="text">{{ dataItem?.tip ?? "" }}</span>
    </div>
    <div class="value-wrap fill-wrap">
      <template v-if="componentType === IConst_Field.Input">
        <el-input
          maxlength="100"
          placeholder="请输入"
          :disabled="disabled"
          v-model="localValue"
        />
      </template>
      <template
        v-if="
          componentType === IConst_Field.Pull ||
          componentType === IConst_Field.MutilPull
        "
      >
        <el-select
          v-model="localValue"
          placeholder="请选择"
          :multiple="componentType === IConst_Field.MutilPull"
          :disabled="disabled"
        >
          <el-option
            v-for="item in dataItem.options ? dataItem.options : []"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
      <template v-if="componentType === IConst_Field.Area">
        <ArearSelect
          @change="handleChange"
          :disabled="disabled"
          v-model="localValue"
          :valueName.sync="dataItem.modeValueName"
        />
      </template>
    </div>
  </div>
</template>

<script>
/**
 * 录入方式（1-文本框，2-下拉框，3-地址选择器，4-复选框）
 */
import ArearSelect from "./arearSelect.vue";
import { IConst_Field } from "../util/ViewHelp";

export default {
  components: { ArearSelect },
  props: {
    type: {
      default: () => 1,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    value: {
      //modeValue
      type: [String, Array, null],
      default: () => "",
    },
    dataItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      IConst_Field: IConst_Field,
      localValue: "",
      areaInfo: {},
    };
  },
  computed: {
    componentType() {
      //1 文本输入 2 单选 3 多选 4 区域选择
      return this.type;
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.localValue = newVal;
        }
      },
      immediate: true,
    },
    localValue: {
      handler(newVal, oldVal) {
        this.$emit("input", newVal);
      },
      // immediate: true,
    },
  },
  methods: {
    handleChange(areaInfo) {
      /** areaInfo = {
       * provinceName: ,
        cityName: ,
        areaName: ,

        province: , //省份编码
        city: , //城市编码
        area: , //区域编码
      }
       */
      this.$emit('areaChange')
    },
   
  },
};
</script>

<style scoped lang="scss">
.dynamic-item {
  width: 100%;
  display: flex;
  flex-direction: column;

  .tip {
    display: flex;
    align-items: center;

    .weight {
      width: 8px;
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #f6212d;
      line-height: 22px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      color: #262626;
    }
  }

  .value-wrap {
    width: 100%;
    margin-top: 6px;

    ::v-deep .el-input__inner,
    ::v-deep .el-cascader {
      height: 32px;
      line-height: 32px;
    }

    ::v-deep .el-input__icon {
      line-height: 32px;
    }
  }

  .fill-wrap {
    & > * {
      width: 100%;
    }
    ::v-deep .el-cascader {
      width: 100%;
    }
  }
}
</style>
