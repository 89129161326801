var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formRef",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.ruleForm, "label-width": "140px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "操作时间：", prop: "money" } },
                [_c("span", [_vm._v(_vm._s(_vm.row.createTime))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "变动金额（元）：", prop: "money" } },
                [
                  _vm.row.type == 1
                    ? _c("span", { staticClass: "add" }, [
                        _vm._v(" + "),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.row.amount
                                  ? parseFloat(_vm.row.amount).toFixed(2)
                                  : "0.00"
                              )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.row.type == 2
                    ? _c("span", { staticClass: "reduce" }, [
                        _vm._v(" - "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.row.amount
                                ? parseFloat(_vm.row.amount).toFixed(2)
                                : "0.00"
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "操作原因：", prop: "money" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("filterReason")(_vm.row.reason))),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }