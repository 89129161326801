<!--  -->
<template>
  <div class="classification">
    <div class="header">
      <span class="font">控销定价</span>
    </div>
    <div class="content">
      <div class="left">
        <div class="itemTit">销售地域</div>
        <el-form ref="formTool"
                 :model="formTool"
                 :inline="true"
                 style="text-align: left">
          <div class="control-price">
            <!-- <el-form-item prop="name" label="销售地域">
          <el-input
            v-model="formTool.name"
            placeholder="请输入地域名称"
            clearable
            size="small"
            style="width: 300px"
            suffix-icon="el-icon-search"
          />
        </el-form-item> -->

            <el-divider />

            <div class="custom-tree-container">
              <div class="block">
                <el-tree :props="defaultProps"
                         :data="newAreaList"
                         node-key="id"
                         :expand-on-click-node="false">
                  <span class="custom-tree-node"
                        slot-scope="{ data }">
                    <span>{{ data.rname }}</span>
                    <div>
                      <input v-model="data.price"
                             size="mini"
                             @input="changePrice(data)"
                             style="text-align: right" />
                    </div>
                    <span class="addAccount"
                          v-if="data.goodsSkuPriceId && data.goodsSkuPriceId != ''"
                          @click="addAccount(data.goodsSkuPriceId)">分账设置</span>
                  </span>
                </el-tree>
              </div>
            </div>
          </div>
        </el-form>

        <div class="btn-warp">
          <el-button size="small"
                     @click="editProductPrice()"
                     type="primary">保存</el-button>
          <el-button size="small"
                     @click="cancel()">取消</el-button>
        </div>
      </div>
      <div class="right">
        <DivideAccounts ref="dA"
                        @save="save"
                        :sku-id="accountId"
                        v-if="isAccount" />
      </div>
    </div>
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import DivideAccounts from "./components/divideAccounts";
import {
  getAreaTree,
  soldout,
  getAreaPriceTree,
  quantityApi,
  editProductLinesPriceBatch,
} from "@/api/saleRule/productLine";
import avater from "/public/img/default.png";
export default {
  components: { MyTable, DivideAccounts },
  data () {
    return {
      defaultProps: {
        label: "label",
        children: "children",
      },
      newAreaList: [],
      areaList: [],
      avater: avater,
      formTool: {
        name: "",
      },
      price: 0,
      selectionList: [],
      operation: true,
      selection: false,
      index: true,
      priceList: [],
      commitPriceList: [],
      changeId: null,
      tableOption: [
        { label: "产品线名称", prop: "name" },
        { label: "产品线负责人", prop: "name" },
        { label: "控销产品", prop: "image", slot: true }, // 这里表示自定义列
        { label: "控销分类", prop: "type" },
      ],
      page: {
        total: 0,
        current: 1,
        size: 20,
        pageSizes: [10, 20, 30, 50],
      },
      accountId: "",
      isAccount: false
    };
  },
  computed: {},
  watch: {},
  created () {
    this.getAreaTree();
    this.goodsId = this.$route.query.goodsId;
    this.productLineId = this.$route.query.productLineId;
  },
  mounted () { },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    clickPrice (data) { },
    // ergodic(item, row) {
    //   for (const i in item) {
    //     if (item[i].children) {
    //       for (let j = 0; j < item[i].children.length; j++) {
    //         item[i].children[j].price = this.price;
    //       }
    //       this.ergodic(item[i].children); // 说明没到最内层，那就继续递归调用自己，继续找
    //     } else {
    //       return;
    //     }
    //   }
    // },

    changePrice (row) {
      this.price = row.price;
      this.changeId = row.id;
      // this.ergodic(this.areaList, row); // 递归调用，更改数据
      this.$forceUpdate();
    },

    // 根据有的价格地区 筛选数据
    filterPrice (item, list) {
      for (let k = 0; k < list.length; k++) {
        for (const i in item) {
          if (
            list[k].price != "" &&
            list[k].price != undefined &&
            list[k].price != null &&
            item[i].id == list[k].areaCode
          ) {
            item[i].price = list[k].price;
            item[i].priceId = list[k].id;
            item[i].goodsSkuPriceId = list[k].goodsSkuPriceId;
          } else {
          }
          if (item[i].children) {
            for (let j = 0; j < item[i].children.length; j++) {
              if (
                list[k].price != "" &&
                list[k].price &&
                item[i].children[j].id == list[k].areaCode
              ) {
                item[i].children[j].price = list[k].price;
                item[i].children[j].priceId = list[k].id;
                item[i].children[j].goodsSkuPriceId = list[k].goodsSkuPriceId;
              } else {
              }
              let levelTwo = item[i].children;
              levelTwo.map((item) => {
                let threeLevel = item.children;
                if (threeLevel) {
                  for (let i = 0; i < threeLevel.length; i++) {
                    if (threeLevel[i].id == list[k].areaCode) {
                      threeLevel[i].price = list[k].price;
                      threeLevel[i].priceId = list[k].id;
                      threeLevel[i].goodsSkuPriceId = list[k].goodsSkuPriceId;
                    }
                  }
                }
              });
            }
          } else {
            return;
          }
        }
      }
    },

    filterGetPrice (item) {
      for (let i = 0; i < item.length; i++) {
        let levelOne = item[i];
        if (levelOne.price) {
          this.pushArr(levelOne);
        }
        let levelTwo = levelOne.children;
        for (let j = 0; j < levelTwo.length; j++) {
          if (levelTwo[j].price != undefined) {
            this.pushArr(levelTwo[j]);
          }

          let levelThree = levelTwo[j].children;
          if (levelThree) {
            for (let k = 0; k < levelThree.length; k++) {
              if (levelThree[k].price != undefined) {
                this.pushArr(levelThree[k]);
              }
            }
          }
        }
      }
    },

    pushArr (item) {
      this.commitPriceList.push({
        price: parseFloat(item.price),
        goodsId: this.goodsId,
        productLineId: this.productLineId,
        areaCode: item.id,
        areaName: item.rname,
        // level: item.level,
      });
    },

    // 修改价格
    editProductPrice (node) {
      this.commitPriceList = [];
      this.filterGetPrice(this.areaList);
      editProductLinesPriceBatch(this.commitPriceList).then((res) => {
        // if (res.code == 0) {
        this.$message.success("修改成功");
        // this.getAreaTree();
        this.cancel();
        // } else {
        //   this.$message.error(res.msg)
        // }
      });

    },

    // 勾选
    handleSelectionChange (val) {
      this.selectionList = val;
    },

    // 查询  // 获取团队地域树
    getAreaTree (current) {
      this.areaList = JSON.parse(localStorage.getItem("cityData"));
      this.getAreaPriceTree();
      // const params = {
      //   level: 3,
      // };
      // getAreaTree(params).then((res) => {
      //   console.log(res);
      //   if (res.code == 0 && res.data) {
      //     this.areaList = res.data;
      //     this.getAreaPriceTree();
      //   } else {
      //     this.page.total = 0;
      //   }
      // });
    },

    getAreaPriceTree () {
      const params = {
        productLineId: this.$route.query.productLineId,
      };
      getAreaPriceTree(params).then((res) => {
        this.priceList = res;
        this.filterPrice(this.areaList, this.priceList);
        this.newAreaList = this.areaList;
      });
    },

    // getQuantityApi() {},

    // 返回
    cancel () {
      this.$router.push("/salesControlMange/salesControl-product-index");
    },

    // 搜索
    clickSearch (current) {
      this.getAreaTree(current);
    },
    // 分账设置
    addAccount (id) {
      if (this.isAccount && this.accountId == id) {
        return
      }
      this.isAccount = this.accountId != id ? true : !this.isAccount;
      this.accountId = id;
      this.$nextTick(() => {
        this.$refs.dA.checkExits(id);
      })
    },
    // 保存成功、
    save () {
      this.isAccount = false;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
.classification {
  padding: 0px;
  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;
    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }
    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }
  background: white;

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);
  }
  .control-price {
    width: 400px;
    padding: 10px;
    // border: solid 1px #f5f5f5;
    border-radius: 5px;
  }
  .btn-warp {
    margin: 15px;
  }
  .custom-tree-container {
    font-size: 14px;
  }
  .custom-tree-node {
    width: 400px;
    display: flex;
    justify-content: space-between;
    input {
      width: 100px;
      line-height: 20px;
      height: 20px;
      border-radius: 2px;
      color: #333;
      border: solid 1px #f5f5f5;
      margin-right: 2px;
    }
    input:focus {
      outline: 1px solid #f5222d;
      border: none;
    }
  }
  .addAccount {
    display: inline-block;
    font-size: 12px;
    color: #0da0a7;
  }
  .content {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    .left {
      flex: 1;
      border: solid 1px #f5f5f5;
      .itemTit {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        padding: 10px;
        box-sizing: border-box;
        border-bottom: solid 1px #f5f5f5;
      }
    }
    .right {
      flex: 1;
      border: solid 1px #f5f5f5;
      margin-left: 20px;
      padding: 10px;
    }
  }
  ::deep .el-divider {
    height: 0;
  }
}
</style>
