<template>
  <div class="step-root h-step-list">
    <div
      :class="{
        item: true,
        focus: currentIndex === index + 1,
        finished: currentIndex > index + 1,
      }"
      v-for="(item, index) in stepList ? stepList : []"
      :key="index"
    >
      <div class="full-number">
        <span class="number">
          {{ index + 1 }}
        </span>
        <el-image
          class="finish-icon"
          :src="require('@/assets/icon_detail_status.png')"
        ></el-image>
      </div>
      <span class="name">{{ item.name }}</span>
      <div class="split-line" v-if="index < stepList.length - 1"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentIndex: {
      type: Number,
      default: () => 1,
    },
    stepList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.step-root {
  --focus-color: #f5222d;

  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  &.h-step-list {
    display: flex;
    align-items: center;

    .item {
      display: flex;
      align-items: center;

      .full-number {
        width: 32px;
        height: 32px;
        position: relative;

        .number {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          border-radius: 50%;
          border: 1px solid rgba(0, 0, 0, 0.15);
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;

          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.25);
        }

        .finish-icon {
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }

      .name {
        margin-left: 8px;
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        opacity: 0.45;
      }

      .split-line {
        margin: 0 16px;
        width: 128px;
        height: 2px;
        border-bottom: 2px dashed #969798;
        box-sizing: border-box;
      }

      &.focus {
        .number {
          border-color: var(--focus-color);
          background-color: var(--focus-color);
          color: #fff;
          font-weight: 400;
        }

        .name {
          font-weight: 600;
          color: #262626;
          opacity: 1;
        }
      }

      &.finished {
        .number {
          display: none;
        }
        .finish-icon {
          display: block;
        }
      }
    }
  }
}
</style>
