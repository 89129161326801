var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c("div", { staticClass: "header" }, [
      _c("span", { staticClass: "font" }, [_vm._v("发布商品")]),
      _c(
        "div",
        { staticClass: "step-frame" },
        [
          _c("StepView", {
            attrs: {
              "current-index": _vm.localCurrentIndex,
              "step-list": _vm.stepList,
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "centent" },
      [
        _vm.localCurrentIndex === 1
          ? [_c("SelectTypeView", { on: { nextStep: _vm.handleNextStep } })]
          : _vm.localCurrentIndex === 2
          ? [
              _c("SelectProductView", {
                on: {
                  preStep: _vm.handlePreStep,
                  nextStep: _vm.handleNextStep,
                },
              }),
            ]
          : _vm.localCurrentIndex === 3
          ? [
              _c("FillProductInfoView", {
                ref: "fillProductInfo",
                on: { preStep: _vm.handlePreStep },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }