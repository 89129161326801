var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "span-method": _vm.arraySpanMethod,
            "default-expand-all": true,
          },
        },
        [
          _c("el-table-column", {
            staticStyle: { width: "1px" },
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "table",
                      {
                        staticClass: "builtinTableClass",
                        attrs: { rules: "all", frame: "border" },
                      },
                      _vm._l(props.row.orderDetailVo, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c(
                            "th",
                            {
                              staticClass: "thClass",
                              staticStyle: { "min-width": "398px" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "imageBox" },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "80px",
                                      height: "80px",
                                    },
                                    attrs: { src: item.image || _vm.avater },
                                  }),
                                  _c("div", [
                                    _c("p", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                    _c("p", { staticClass: "duanluo pihao" }, [
                                      _vm._v(_vm._s(item.approvalNumber)),
                                    ]),
                                    _c("p", { staticClass: "duanluo" }, [
                                      _vm._v(_vm._s(item.factory)),
                                    ]),
                                    _c("p", { staticClass: "duanluo" }, [
                                      _vm._v(_vm._s(item.specification)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "thClass",
                              staticStyle: { "min-width": "200px" },
                            },
                            [
                              _c("p", { staticClass: "duanluo" }, [
                                _vm._v(_vm._s(item.qty)),
                              ]),
                            ]
                          ),
                          index == 0
                            ? _c(
                                "th",
                                {
                                  staticClass: "thClass",
                                  staticStyle: { "min-width": "200px" },
                                  attrs: {
                                    rowspan: props.row.orderDetailVo.length + 1,
                                  },
                                },
                                [
                                  _c("p", { staticClass: "duanluo" }, [
                                    _vm._v("￥" + _vm._s(props.row.copeAmount)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          index == 0
                            ? _c(
                                "th",
                                {
                                  staticClass: "thClass",
                                  staticStyle: { "min-width": "200px" },
                                  attrs: {
                                    rowspan: props.row.orderDetailVo.length + 1,
                                  },
                                },
                                [
                                  _c("p", { staticClass: "duanluo" }, [
                                    _vm._v(
                                      "￥" +
                                        _vm._s(
                                          props.row.status != "9"
                                            ? "0"
                                            : props.row.copeAmount
                                        )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          index == 0
                            ? _c(
                                "th",
                                {
                                  staticClass: "thClass",
                                  staticStyle: { "min-width": "200px" },
                                  attrs: {
                                    rowspan: props.row.orderDetailVo.length + 1,
                                  },
                                },
                                [
                                  _c("div", [
                                    _c("p", { staticClass: "duanluo submit" }, [
                                      _vm._v(_vm._s(item.payeeShopName)),
                                    ]),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          index == 0 && _vm.routerType == "pending"
                            ? _c(
                                "th",
                                {
                                  staticClass: "thClass",
                                  staticStyle: { "min-width": "200px" },
                                  attrs: {
                                    rowspan: props.row.orderDetailVo.length + 1,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "submit" },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          staticClass: "btn",
                                          attrs: {
                                            disabled: props.row.status == "9",
                                            underline: false,
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.payBtn(props.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.row.status == "9"
                                                  ? "已 转 账"
                                                  : "待 转 账"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品信息",
              prop: "id",
              "min-width": "350",
              align: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      scope.row.newTag
                        ? _c("div", { staticClass: "xiaoyuandian item" })
                        : _vm._e(),
                      _c("span", [_vm._v(" 创建时间：")]),
                      _c("span", [_vm._v(_vm._s(scope.row.orderTime))]),
                      _c("span", { staticClass: "marrinLeft" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.routerType == "pending"
                                ? "收款方："
                                : "买家："
                            )
                        ),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.routerType == "pending"
                              ? scope.row.payeeShopName
                              : scope.row.payShopName
                          )
                        ),
                      ]),
                      _c("span", { staticClass: "marrinLeft" }, [
                        _vm._v("订单号："),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            scope.row.relatedOrderCode
                              ? scope.row.relatedOrderCode
                              : scope.row.orderCode
                          )
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "数量",
              prop: "desc",
              align: "left",
              "min-width": "200",
            },
          }),
          _vm.routerType == "pending"
            ? _c("el-table-column", {
                attrs: {
                  label: "服务费应付金额",
                  prop: "desc",
                  align: "left",
                  "min-width": "200",
                },
              })
            : _c("el-table-column", {
                attrs: {
                  label: "服务费应收金额",
                  prop: "desc",
                  align: "left",
                  "min-width": "200",
                },
              }),
          _vm.routerType == "pending"
            ? _c("el-table-column", {
                attrs: {
                  label: "服务费实付金额",
                  prop: "desc",
                  align: "left",
                  "min-width": "200",
                },
              })
            : _c("el-table-column", {
                attrs: {
                  label: "服务费实收金额",
                  prop: "desc",
                  align: "left",
                  "min-width": "200",
                },
              }),
          _c("el-table-column", {
            attrs: {
              label: "收款方",
              prop: "desc",
              align: "left",
              "min-width": "200",
            },
          }),
          _vm.routerType == "pending"
            ? _c("el-table-column", {
                attrs: {
                  label: "操作",
                  prop: "desc",
                  align: "left",
                  "min-width": "200",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }