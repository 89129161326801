<template>
  <div class="groupList">
    <div class="tit">拼团活动</div>
    <ul>
      <li v-for="item in groupList" @click="toDetail(item.id)" :key="item.id">{{ item.name }}</li>
    </ul>
  </div>
</template>

<script>
import { groupBuyList } from "@/api/spellGroup/spellGroupActive";
export default {
  name: "GroupList",
  // props: {
  //   groupList: {
  //     type: Array,
  //     default: [],
  //   },
  // },
  data() {
    return {
      page: {
        current: 1,
        size: 10,
      },
      groupList: [],
    };
  },
  created() {
    this.getGroupList();
  },
  methods: {
    getGroupList() {
      let data = {
        ...this.page,
        state: "1,2",
      };
      groupBuyList(data).then((res) => {
        this.groupList = res;
      });
    },
    toDetail(id){
      this.$router.push({
        path: "/spellGroup/spellGroup-goodsList-detail",
        query: {
          groupBuyId: id,
          type: 3
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.groupList {
  .tit {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #262626;
    // padding: 10px 0;
    line-height: 48px;
  }
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    li {
      padding: 5px 15px;
      border-radius: 4px;
      border: 1px solid #F5222D;
      color: #F5222D;
      font-size: 14px;
      letter-spacing: 1px;
      margin-right: 10px;
      cursor: pointer;
      align-items: center;
      display: flex;
      background: rgba(245, 34, 45, 0.1);
      flex-shrink: 0;
      // margin-bottom: 10px;
    }
  }
}
</style>
