<!-- 保证金充值审核-->
 
<template>
  <div class="content">
    <div class="header">

      <el-form ref="formTool" :model="formTool" :inline="true" style="text-align: left">
        <el-row style="margin: 10px  0 10px">
          <el-form-item label="">
            <el-input v-model.trim="formTool.orderCode" placeholder="请输入订单ID" clearable size="small" />
          </el-form-item>
          <el-form-item label="订单创建时间" prop="date">
            <el-date-picker style="width: 100%" v-model="formTool.date" value-format="yyyy-MM-dd" type="daterange"
              size="small" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" />
          </el-form-item>
          <el-form-item label="">
            <el-input v-model.trim="formTool.erpShopCode" placeholder="请输入客户ERP编码/客户名称" clearable size="small"
              style="width: 240px;" />
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="formTool.settlementStatus" clearable style="width: 160px;" size="small"
              placeholder="请选择结算状态">
              <el-option v-for="item in settlementStatusOptions" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="formTool.paymentStatus" clearable style="width: 160px;" size="small"
              placeholder="请选择缴费状态">
              <el-option v-for="item in paymentStatusOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="getList(1)">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="reset()">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="exportUrl()">导出</el-button>
          </el-form-item>
          <el-form-item>
            <el-button :disabled="father.obj.presentDate > father.obj.finallyPaymentTime" size="small" type="primary"
              @click="rechargeSubmit()">缴费</el-button>
          </el-form-item>
        </el-row>

      </el-form>
    </div>

    <div class="center" style="position: relative" :style="contentHeight ? `height:${contentHeight}px!important` : ''">
      <MyTable :table-data="tableData" :index="index" :selection="selection" :page="page" :operation="operation"
        :table-option.sync="tableOption" @page-change="getList" @handleSelectionChange="handleSelectionChange">
        <template slot="settlementStatus" slot-scope="scope">
          {{ scope.row.settlementStatus | settlementStatus }}
        </template>
        <template slot="settlementType" slot-scope="scope">
          {{ scope.row.settlementType | settlementType }}
        </template>
        <template slot="paymentStatus" slot-scope="scope">
          {{ scope.row.paymentStatus | paymentStatus }}
        </template>
        <template slot="province" slot-scope="scope">
          {{ scope.row.province }}
          {{ scope.row.city }}
          {{ scope.row.district }}
        </template>
      </MyTable>
    </div>
    <!-- 缴费统计弹窗 -->
    <addDialog ref="add-dialog" @getList="getList" @showDetail="showDetailDialog"/>
    <!-- 明细弹窗 -->
    <paymentMoney ref="payment-money" @getList="getList" />
  </div>
</template>
    
<script>
const settlementTypeOptions = [
  { value: 1, label: "线下打款" },
  { value: 2, label: "扣除保证金" },
];

const settlementStatusOptions = [
  { value: 0, label: "未结算" },
  { value: 1, label: "已结算" },
];

const paymentStatusOptions = [
  { value: 0, label: "未缴费" },
  { value: 1, label: "已缴费" },
];

const settlementFilters = {
  settlementType: function (value) {
    const options = settlementTypeOptions.find((option) => option.value === value);
    return options ? options.label : '';
  },
  settlementStatus: function (value) {
    const options = settlementStatusOptions.find((option) =>
      option.value == value);
    return options ? options.label : value === 0 ? '未结算' : '';
  },
  paymentStatus: function (value) {
    const options = paymentStatusOptions.find((option) =>
      option.value == value);
    return options ? options.label : value === 0 ? '未缴费' : '';
  },
};
import { fileExport } from "@/api/surety";
import { orderVoucherRelationGet } from "@/api/offlinePayment";
import MyTable from "@/components/myTable";
import addDialog from "./components/paymentMoneyTotal.vue";
import paymentMoney from "./components/paymentMoney.vue";
// import addDialog from "./components/submitCertificate.vue";
import avater from "/public/img/default.png";
import dayjs from "dayjs";
export default {
  components: { MyTable, addDialog,paymentMoney },
  inject: ["father"],
  data () {
    return {
      settlementStatusOptions: settlementStatusOptions,
      settlementTypeOptions: settlementTypeOptions,
      paymentStatusOptions: paymentStatusOptions,
      activeName: 'second',
      avater: avater,
      formTool: {
      },
      btnType: 'add',
      selectionList: [],
      operation: false,
      selection: false,
      index: true,
      tableData: [],
      tableOption: [
        { label: "订单创建时间", prop: "createTime", width: 160 },
        { label: "订单完结时间", prop: "updateTime", width: 160 },
        { label: "订单ID", prop: "orderCode", width: 200 },
        { label: "客户ERP编码", prop: "erpShopCode" },
        { label: "运营项目", prop: "deptName" },
        { label: "客户名称", prop: "payShopName" },
        { label: "客户所在区域", prop: "province", slot: true },
        { label: "商品总额（元）", prop: "amount", type: 'money' },
        { label: "运费（元）", prop: "freightPrice", type: 'money' },
        { label: "商家优惠金额（元）", prop: "coupon", type: 'money' },
        { label: "平台补贴（元）", prop: "platformSubsidy", type: 'money' },
        { label: "退款金额（元）", prop: "refundAmount", type: 'money' },
        { label: "技术服务费（元）", prop: "technicalMoney", type: 'money' },
        { label: "推广服务费（元）", prop: "promotionMoney", type: 'money' },
        { label: "结算金额（元）", prop: "settlementAmount", type: 'money' },
        { label: "结算状态", prop: "settlementStatus", slot: true },
        { label: "缴费状态", prop: "paymentStatus", slot: true },
        { label: "缴费方式", prop: "settlementType", slot: true },
      ],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      dialogShow: false,
      contentHeight: 0,
    };
  },
  computed: {},
  filters: settlementFilters,
  watch: {},
  created () {
    console.log(this.father, '---father')
    this.getList();
  },
  mounted () {
  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { this.getWindowView() },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  computed: {

  },
  methods: {
    showDetailDialog(e){
      const { month } = e
      // 获取月份段
      const monthStart = dayjs(month).startOf('month').format('YYYY-MM-DD')
      const monthEnd = dayjs(month).endOf('month').format('YYYY-MM-DD')
      this.$refs['payment-money'].init({ monthStart, monthEnd })
    },
    async rechargeSubmit (row = {}) {
      this.$refs['add-dialog'].init(row, 'add')
    },
    getWindowView () {
      let innerHeight = window.innerHeight; //获取页面高度 
      let headHeight = 60;
      headHeight = this.$refs['formTool'].$el.clientHeight; //获取子页面head 高度  
      console.log(headHeight, '--headHeight')
      let contentHeight = innerHeight - 208 - headHeight;
      this.contentHeight = contentHeight;
    },

    // 页码变化
    handleCurrentChange (e) {
      this.page.current = e
    },

    // 条数变化
    handleSizeChange (e) {
      this.page.size = e
      this.page.current = 1
    },

    // 重置查询条件
    reset () {
      this.formTool = {};
      this.getList(1);
    },

    exportUrl () {
      const params = {
        ...this.formTool,
        current: this.page.current,
        size: this.page.size
      };
      if (this.formTool.date) {
        params.strTime = this.formTool.date[0];
        params.endTime = this.formTool.date[1];
      }
      delete params.date;
      fileExport('/order/purchase/order/voucher/relation/get/excel', 'GET', params).then((res) => { }).catch((err) => {

      })
    },

    // 搜索
    getList (current) {
      if (current) {
        this.page.current = current;
      }
      const params = {
        ...this.formTool,
        current: this.page.current,
        size: this.page.size
      };
      if (this.formTool.date) {
        params.strTime = this.formTool.date[0];
        params.endTime = this.formTool.date[1];
      }
      delete params.date;
      orderVoucherRelationGet(params).then((res) => {
        if (res) {
          this.tableData = res.records;
          this.page.total = res.total;
        } else {
          this.tableData = [];
          this.page.total = 0;
        }
      });
    },
    // 勾选
    handleSelectionChange (val) {
      this.selectionList = val;
    },

  },
};
</script>
<style lang='scss' scoped>
.content {
  .center {}

  .btn-warp {
    display: flex;
    justify-content: space-around;

  }
}

.btn-list {
  margin: 0 10px;
}

::v-deep .el-table {
  height: calc(100% - 120px) !important;
  overflow: auto;
}
</style>
    