<template>
  <div class="classification">
    <div class="header">
      <el-form
        ref="formTool"
        :model="formTool"
        :inline="true"
        style="text-align: left"
      >
        <el-form-item prop="name" label="关联商品">
          <el-input
            v-model="formTool.goodsName"
            placeholder="请输入"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="name" label="审核状态">
          <el-select
            v-model="formTool.auditStatus"
            style="width: 150px"
            size="small"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in auditStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button size="small" @click="openOpDetail">新增资料</el-button>
    </div>

    <div class="center" style="position: relative">
      <MyTable
        :table-data="tableData"
        :page="page"
        :table-option.sync="tableOption"
        @page-change="getList"
      >
        <template slot="auditStatus" slot-scope="scope">
          <span>{{
            scope.row.auditStatus == "0"
              ? "待审核"
              : scope.row.auditStatus == "1"
              ? "审核通过"
              : scope.row.auditStatus == "2"
              ? "驳回"
              : ""
          }}</span>
        </template>
        <template slot="operate" slot-scope="scope">
          <div class="table-op-col">
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="handleEdit(scope.$index)"
              >编辑</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="handleLook(scope.$index)"
              >查看</el-link
            >
          </div>
        </template>
      </MyTable>
    </div>
    <OpDetail ref="refOpDetail" @onRefresh="handleRefresh" />
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import OpDetail from "./diglog/op-detail.vue";
import { querySalesScienceProfilePage } from "@/api/salesControl/academic";

export default {
  components: { MyTable, OpDetail },
  data() {
    return {
      auditStatusList: [
        { value: 0, label: "待审核" },
        { value: 1, label: "审核通过" },
        { value: 2, label: "驳回" },
      ],
      formTool: { goodsName: "", auditStatus: "" },
      tableData: [],
      tableOption: [
        { label: "资料ID", prop: "code" },
        { label: "海报名称", prop: "posterName" },
        { label: "审核状态", prop: "auditStatus", slot: true },
        { label: "创建时间", prop: "createTime" },
        { label: "关联商品", prop: "goodsName" },
        { label: "操作", prop: "operate", slot: true, width: "220px" },
      ],
      page: { total: 10, current: 1, size: 10 },
    };
  },
  created() {
    this.getList(1);
  },
  methods: {
    handleLook(index) {
      const row = this.tableData[index];

      this.openOpDetail(3, row.id);
    },
    handleEdit(index) {
      const row = this.tableData[index];

      this.openOpDetail(2, row.id);
    },
    openOpDetail(action = 1, posterId = null) {
      this.$refs.refOpDetail.open({ action: action, posterId: posterId });
    },
    getList(num) {
      if (num) {
        this.page.current = num;
      }
      const { current, size } = this.page;
      let params = { sourceType: 1, current, size, ...this.formTool };
      this.listLoading = true;
      querySalesScienceProfilePage(params)
        .then((res) => {
          this.tableData = res.records;
          this.page.total = res.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    handleRefresh() {
      this.getList(1);
    },
    handleReset() {
      for (let key in this.formTool) {
        this.formTool[key] = "";
      }
      this.getList(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.classification {
  padding: 0px;

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn-warp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .el-link {
      // margin: 5px;
    }
  }

  .table-op-col {
    display: flex;
    .el-link {
      & + .el-link {
        margin-left: 8px;
      }
    }
  }
}
</style>
