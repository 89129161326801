var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c("div", [
      _c("div", { staticClass: "left_topic mb20" }, [_vm._v("基本信息")]),
      _c("div", { staticClass: "apply" }, [
        _c("div", { staticClass: "firstBox" }, [
          _c("span", { staticClass: "font" }, [_vm._v(" 申请单号： ")]),
          _vm._v(" " + _vm._s(_vm.comApplyInvoiceInfo.id) + " "),
        ]),
        _c("div", { staticClass: "secondBox" }, [
          _c("span", { staticClass: "font" }, [_vm._v(" 申请时间： ")]),
          _vm._v(" " + _vm._s(_vm.comApplyInvoiceInfo.createTime) + " "),
        ]),
      ]),
      _c("div", { staticClass: "apply" }, [
        _c("div", { staticClass: "firstBox" }, [
          _c("span", { staticClass: "font" }, [_vm._v(" 发票类型： ")]),
          _vm._v(
            " " +
              _vm._s(_vm.getInvoiceType(_vm.comApplyInvoiceInfo.invoiceType)) +
              " "
          ),
        ]),
        _c("div", { staticClass: "secondBox" }, [
          _c("span", { staticClass: "font" }, [_vm._v(" 开票时间： ")]),
          _vm._v(" " + _vm._s(_vm.comApplyInvoiceInfo.billingTime) + " "),
        ]),
      ]),
      _c("div", { staticClass: "apply" }, [
        _c("div", { staticClass: "firstBox" }, [
          _c("span", { staticClass: "font" }, [_vm._v(" 开票金额： ")]),
          _vm._v(
            " ￥ " +
              _vm._s(
                parseFloat(_vm.comApplyInvoiceInfo.aggregateAmount).toFixed(
                  2
                ) || "0.00"
              ) +
              " "
          ),
        ]),
      ]),
    ]),
    _c("div", [
      _c(
        "div",
        {
          staticClass: "left_topic mb20",
          staticStyle: { "margin-top": "10px" },
        },
        [_vm._v("发票抬头信息")]
      ),
      _c("div", { staticClass: "apply" }, [
        _c("div", { staticClass: "firstBox" }, [
          _c("span", { staticClass: "font" }, [_vm._v(" 发票抬头： ")]),
          _vm._v(" " + _vm._s(_vm.comInvoiceTitleInfoEntity.sellerName) + " "),
        ]),
        _c("div", { staticClass: "secondBox" }, [
          _c("span", { staticClass: "font" }, [_vm._v(" 开户行： ")]),
          _vm._v(" " + _vm._s(_vm.comInvoiceTitleInfoEntity.sellerBank) + " "),
        ]),
      ]),
      _c("div", { staticClass: "apply" }, [
        _c("div", { staticClass: "firstBox" }, [
          _c("span", { staticClass: "font" }, [_vm._v(" 地址： ")]),
          _vm._v(
            " " + _vm._s(_vm.comInvoiceTitleInfoEntity.sellerAddress) + " "
          ),
        ]),
        _c("div", { staticClass: "secondBox" }, [
          _c("span", { staticClass: "font" }, [_vm._v(" 收件人： ")]),
          _vm._v(" " + _vm._s(_vm.comInvoiceTitleInfoEntity.recipients) + " "),
        ]),
      ]),
      _c("div", { staticClass: "apply" }, [
        _c("div", { staticClass: "firstBox" }, [
          _c("span", { staticClass: "font" }, [_vm._v(" 电话： ")]),
          _vm._v(" " + _vm._s(_vm.comInvoiceTitleInfoEntity.sellerTel) + " "),
        ]),
        _c("div", { staticClass: "secondBox" }, [
          _c("span", { staticClass: "font" }, [_vm._v(" 联系电话： ")]),
          _vm._v(
            " " + _vm._s(_vm.comInvoiceTitleInfoEntity.recipientsTel) + " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "apply" }, [
        _c("div", { staticClass: "firstBox" }, [
          _c("span", { staticClass: "font" }, [_vm._v(" 税号： ")]),
          _vm._v(
            " " + _vm._s(_vm.comInvoiceTitleInfoEntity.sellerTaxpayerId) + " "
          ),
        ]),
        _c("div", { staticClass: "secondBox" }, [
          _c("span", { staticClass: "font" }, [_vm._v(" 收件地址： ")]),
          _vm._v(
            " " + _vm._s(_vm.comInvoiceTitleInfoEntity.recipientsAddress) + " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "apply" }, [
        _c("div", { staticClass: "firstBox" }, [
          _c("span", { staticClass: "font" }, [_vm._v(" 开户行账号： ")]),
          _vm._v(
            " " + _vm._s(_vm.comInvoiceTitleInfoEntity.sellerAccount) + " "
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "last" }, [
      _c(
        "div",
        {
          staticClass: "left_topic mb20",
          staticStyle: { "margin-top": "10px" },
        },
        [_vm._v("发票明细")]
      ),
      _c(
        "div",
        { staticClass: "center", staticStyle: { position: "relative" } },
        [
          _c("MyTable", {
            attrs: {
              "table-data": _vm.tableData,
              index: _vm.index,
              selection: _vm.selection,
              page: _vm.page,
              operation: _vm.operation,
              "table-option": _vm.tableOption,
            },
            on: {
              "update:tableOption": function ($event) {
                _vm.tableOption = $event
              },
              "update:table-option": function ($event) {
                _vm.tableOption = $event
              },
              "page-change": _vm.comapplyinvoiceinfoDetail,
            },
            scopedSlots: _vm._u([
              {
                key: "orderSum",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "￥" + _vm._s(parseFloat(scope.row.orderSum).toFixed(2))
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "commoditySum",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "￥" +
                          _vm._s(parseFloat(scope.row.commoditySum).toFixed(2))
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "orderFreight",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "￥" +
                          _vm._s(parseFloat(scope.row.orderFreight).toFixed(2))
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "orderCloseSum",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "￥" +
                          _vm._s(parseFloat(scope.row.orderCloseSum).toFixed(2))
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "platformServiceCharge",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "￥" +
                          _vm._s(
                            parseFloat(scope.row.platformServiceCharge).toFixed(
                              2
                            )
                          )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }