var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.product?.goodsName || "",
        visible: _vm.visible,
        width: "600px",
        "before-close": _vm.handleCancel,
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        opened: _vm.getTree,
      },
    },
    [
      _c("div", { staticClass: "sales-control-dialog" }, [
        _c("div", { staticClass: "structure" }, [
          _c(
            "div",
            { staticClass: "searchArea" },
            [
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.handleAllSales } },
                [_vm._v("一键全部销售")]
              ),
              _c("el-input", {
                staticClass: "searchIpt",
                staticStyle: { visibility: "auto" },
                attrs: {
                  placeholder: "请输入架构名称",
                  "suffix-icon": "el-icon-search",
                  size: "mini",
                },
                model: {
                  value: _vm.treeSearchText,
                  callback: function ($$v) {
                    _vm.treeSearchText = $$v
                  },
                  expression: "treeSearchText",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "treeBox" },
            [
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  "node-key": "id",
                  data: _vm.organizationData,
                  "highlight-current": true,
                  props: _vm.defaultProps,
                  "filter-node-method": _vm.filterNode,
                  "default-expand-all": false,
                  "expand-on-click-node": false,
                  "default-expanded-keys": _vm.currentExpandKeys,
                },
                on: {
                  "node-click": _vm.getCheckKey,
                  "node-expand": _vm.saveCurrentExpandKeys,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ node, data }) {
                      return _c("div", { staticClass: "custom-tree-node" }, [
                        _c("span", [_vm._v(_vm._s(data.name))]),
                        data.item
                          ? _c(
                              "div",
                              [
                                _c("div", [_vm._v(_vm._s(data.item.price))]),
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#f5222d",
                                    "inactive-color": "#8c8c8c",
                                    "active-text": "已销售",
                                    "inactive-text": "未销售",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleSave(data)
                                    },
                                  },
                                  model: {
                                    value: data.item.checked,
                                    callback: function ($$v) {
                                      _vm.$set(data.item, "checked", $$v)
                                    },
                                    expression: "data.item.checked",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }