var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contentCont" }, [
    _c(
      "div",
      { staticClass: "contentInner" },
      _vm._l(_vm.newRecord, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "itemCont", attrs: { item: item } },
          [
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.add(item)
                  },
                },
              },
              [
                item.clickNone
                  ? _c("div", { staticClass: "status_ico status_success" }, [
                      _c("i", { staticClass: "el-icon-check" }),
                    ])
                  : _vm._e(),
                item.union
                  ? _c(
                      "div",
                      {
                        staticClass: "status_ico status_error",
                        on: {
                          click: function ($event) {
                            return _vm.remove(item)
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-close" })]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "itemImg" }, [
                  _c("img", {
                    staticStyle: {
                      width: "180px",
                      height: "180px",
                      padding: "15px 0px",
                    },
                    attrs: { src: item.image || _vm.avater, alt: "" },
                  }),
                ]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: item.goodsSkuTitle,
                      placement: "top",
                    },
                  },
                  [
                    _c("div", { staticClass: "itemBox name text-ellipsis" }, [
                      _vm._v(" " + _vm._s(item.goodsSkuTitle || "-") + " "),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "itemBox factory" }, [
                  _vm._v(" " + _vm._s(item.factory || "-") + " "),
                ]),
                _c("div", { staticClass: "itemBox factory" }, [
                  _vm._v(
                    " 有效期至： " +
                      _vm._s(
                        item.expireTime ? item.expireTime.slice(0, 11) : "-"
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "itemBox factory",
                    staticStyle: { "padding-bottom": "10px" },
                  },
                  [_vm._v(" " + _vm._s(item.specification || "-") + " ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "itemBox factory",
                    staticStyle: { "padding-bottom": "10px" },
                  },
                  [_vm._v(" " + _vm._s(item.salePrice || "-") + " ")]
                ),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }