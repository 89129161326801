<template>
    <div class="companyWarp">
      <el-form
        label-width="120px"
        :rules="rules"
        required
        ref="formTool"
        :model="formTool"
      >
        <el-form-item label="企业名称">
          <el-select
            v-model="formTool.sellerName"
            style="width: 100%"
            @change="shopNameChange"
            filterable
            value-key="code"
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="item.id"
              :label="item.sellerName"
              :value="item"
            />
          </el-select>
        </el-form-item>
  
        <el-form-item label="企业税号" prop="sellerTaxpayerId">
          <el-input
            placeholder=""
            style="width: 100%"
            disabled
            v-model="formTool.sellerTaxpayerId"
          ></el-input>
        </el-form-item>
  
        <el-form-item label="康贝号" prop="payeeNo">
          <el-input
            placeholder=""
            style="width: 100%"
            disabled
            v-model="formTool.payeeNo"
          ></el-input>
        </el-form-item>
  
        <div class="btnBoxWarp">
          <el-button size="small" @click="handleClose">取消</el-button>
          <el-button size="small" type="primary" @click="submit">确认</el-button>
        </div>
      </el-form>
    </div>
  </template>
  <script>
  import {listByLoginUser} from "@/api/invoice";
  export default {
    components: {},
    props: {},
    computed: {
      visible() {
        return this.dialogShow;
      },
    },
    data() {
      return {
        formTool: {
          clientName:'',
          taxAuthoritiesName: "",
          sellerTaxpayerId: "",
          sellerShopId:'',
          id:'',
          sellerName: "",
          taxRate:null, // 税率
        },
        chooseCompany:{},
        rules: {},
        companyList: [],
        tableData: [],
        formTool: {},
        dialogShow: false,
      };
    },
    created() {},
    methods: {
       // 获取列表
      getList() {  
        listByLoginUser().then((res) => {
          if (res) { 
            this.companyList = res.data;
            // console.log(   this.companyList,'----   this.companyList')
          }
        });
      },
      shopNameChange(item) {
        // console.log(item,'item')
        this.formTool.sellerShopId = item.sellerShopId;
        this.formTool.id = item.id;
        this.formTool.sellerName = item.sellerName;
        this.formTool.payeeNo = item.payeeNo;
        this.formTool.sellerTaxpayerId = item.sellerTaxpayerId;
        this.formTool.taxRate = item.taxRate;
        this.chooseCompany = item;
      }, 
      handleClose() {
        this.$emit("close");
      },
      submit() {
        // taxRate
        if(!this.formTool.sellerName){
          this.$message.error('请选择企业名称');
          return;
        }
        // if(this.formTool.taxRate == null || this.formTool.taxRate == ''){
        //   this.$message.error('未设置默认税率');
        //   return;
        // }
         let sellerTaxpayerId = JSON.stringify(this.formTool.sellerTaxpayerId);  
         console.log(window.sessionStorage.getItem('sellerTaxpayerId'),'sessionStorage')
         console.log(sellerTaxpayerId,'sellerTaxpayerId')
        if(window.sessionStorage.getItem('sellerTaxpayerId') ==  sellerTaxpayerId){
          this.$emit("submit", "two", this.formTool.id,this.chooseCompany);
        }else{
          this.$emit("submit", "first", this.formTool.id,this.chooseCompany);
          }
        },
  
      init(row) { 
        this.dialogShow = true; 
        this.getList();
      },
      Invoicing() {},
    },
  };
  </script>
  <style lang="scss">
  @import "@/styles/variables.scss";
  
  .btnBoxWarp {
    width: 280px;
    display: flex;
    justify-content: center;
    margin-left: 120px;
  }
  
  .classification {
    padding: 0px;
  
    .header {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0px 24px 0px 24px !important;
      .font {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 600;
        text-align: left;
        color: #262626;
      }
  
      .el-form-item {
        margin: 0px 0px 0px 8px !important;
      }
    }
  
    background: white;
  
    .top {
      display: flex;
      padding: 10px 0 10px 0;
  
      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
  
        .label {
          width: 120px;
        }
  
        .button {
          margin-right: 20px;
  
          ::v-deep .el-button {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
  
            &:hover {
              color: $subMenuActiveText;
              background-color: $butColor;
              border-color: $butColor;
            }
  
            &:active {
              color: $subMenuActiveText;
              background: $butColor;
              background-color: $butColor;
              border-color: $butColor;
            }
  
            &:focus {
              color: $subMenuActiveText;
              background-color: $butColor;
              border-color: $butColor;
            }
          }
        }
      }
    }
  
    .center {
      margin-top: 16px;
      padding: 0 24px;
      height: calc(100% - 131px);
  
      .shangjia {
        position: absolute;
        z-index: 2;
        bottom: -45px;
      }
    }
  
    .btn-warp {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  
  .btn-list {
    margin: 0 5px;
  }
  .companyWarp {
    width: 400px;
    margin: 20px auto;
  }
  </style>
  