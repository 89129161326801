import request from "@/utils/request";

// 获取厂家
export function getFactorys(params) {
  return request({
    url: `/product/goodsbase/query/factory`,
    method: "GET",
    params: params,
  });
}

// 获取面审列表
export function getNotcheckGoods(params) {
  return request({
    url: `/product/goodsbase/getNotcheck/page`,
    method: "GET",
    params,
  });
}

//获取商品标签
export function getLabelInfoList(params) {
  return request({
    url: `/product/sales/shoplabel/getLabelInfo`,
    method: "GET",
    params,
  });
}

//同步sku库存
/**
 *
 * {skuId:""}
 */
export function synErpStock(params) {
  return request({
    url: `/product/sales/sku/erpStock`,
    method: "POST",
    params: params,
  });
}
//同步sku库存
/**
 *
 * {skuId:""}
 */
export function synErpPrice(params) {
  return request({
    url: `/product/sales/sku/erpPrice`,
    method: "POST",
    params: params,
  });
}

//更新价格或库存
export function updatePriceOrStock(data) {
  return request({
    url: `/product/sales/sku/update/priceOrStock`,
    method: "PUT",
    data,
  });
}

//批量sku上下架
export function updateSkuStatusList(data) {
  return request({
    url: `/product/sales/sku/status/list`,
    method: "POST",
    data,
  });
}

//导出商品
export function exportSkuExcel(params) {
  return request({
    url: `/product/sales/sku/export/excle`,
    method: "GET",
    params,
    responseType: "blob",
  });
}

//商家标签 查询
export function getLabelInfo() {
  return request({
    url: `/product/sales/shoplabel/getLabelInfo`,
    method: "GET",
  });
}

//商家标签更新
export function saveOrUpdateLabelInfo(data) {
  return request({
    url: `/product/sales/shoplabel/saveOrUpdateLabelInfo`,
    method: "POST",
    data,
  });
}
