// 列表tab 切换保留选中状态
const state = {
  goodsTab:null ,// 商品列表 tab 选中值
  releaseTab:null,  //批量发布 tab 选中值
  controlPinFormTab:null,  //控销模板 tab 选中值
  procurementTab:null,  //我的采购 tab 选中值
  salesTab:null,  //我的销售 tab 选中值
  serviceSheetTab:null,  //售后服务单 tab 选中值
  afterSalesDocumentTab:null,  //售后单据 tab 选中值
  commentsTab:null,  //评论管理 tab 选中值
  invoiceManageTab:null,  //财务中心/发票管理 tab 选中值
  suretyTab:null,  //财务中心/保证金 tab 选中值
  pendingPaymentTab:null,  //财务中心/待付服务费 tab 选中值 
  paidPaymentTab:null,  //财务中心/应收服务费 tab 选中值
  salesControlBuyerTab:null,  //控销买家  tab 选中值
  salesControlTeamTab:null,  //控销团队  tab 选中值
  platformActivityTab:null,  //平台活动  tab 选中值
  platformSubsidyTab:null,  //平台补贴  tab 选中值 
  lookArticleTab:null,  //资讯文章  tab 选中值
};

const mutations = {
  SET_GOODS_TAB: (state, data) => { 
    state.goodsTab = data;
  },
  SET_RELEASE_TAB: (state, data) => { 
    state.releaseTab = data;
  },
  SET_CONTROLPINFORM_TAB: (state, data) => { 
    state.controlPinFormTab = data;
  },
  SET_PROCUREMENT_TAB: (state, data) => { 
    state.procurementTab = data;
  },
  SET_SALES_TAB: (state, data) => { 
    state.salesTab = data;
  },
  SET_SERVICE_SHEET_TAB: (state, data) => { 
    state.serviceSheetTab = data;
  },
  SET_AFTER_SALES_DOCUMENT_TAB: (state, data) => { 
    state.afterSalesDocumentTab = data;
  },
  SET_COMMENTS_TAB: (state, data) => { 
    state.commentsTab = data;
  },
  SET_INVOICE_MANAGE_TAB: (state, data) => { 
    state.invoiceManageTab = data;
  },
  SET_SURETY_TAB: (state, data) => { 
    state.suretyTab = data;
  },
  SET_PENDING_PAYMENT_TAB: (state, data) => { 
    state.pendingPaymentTab = data;
  },
  SET_PAID_PAYMENT_TAB: (state, data) => { 
    state.paidPaymentTab = data;
  },
  SET_SALES_CONTROL_BUYER_TAB: (state, data) => { 
    state.salesControlBuyerTab = data;
  },
  SET_SALES_CONTROL_TEAM_TAB: (state, data) => { 
    state.salesControlTeamTab = data;
  },
  SET_PLATFORM_ACTIVITY_TAB: (state, data) => { 
    state.platformActivityTab = data;
  },
  SET_PLATFORM_SUBSIDY_TAB: (state, data) => { 
    state.platformSubsidyTab = data;
  },
  SET_LOOK_ARTICLE_TAB: (state, data) => { 
    state.lookArticleTab = data;
  },
};
const actions = { 
  // tab 选中值 设置缓存    // this.$store.state.goodsTab //获取缓存值
  //商品管理》 商品列表 路由地址:  /productManage/salesControl-commodity-list 
 
  setGoodsTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_GOODS_TAB", tab);
      resolve();
    });
  },
   
  //商品管理》 批量发布 productManage/release-release 
  setReleaseTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_RELEASE_TAB", tab);
      resolve();
    });
  }, 

  //商品管理》 控销模板
  setControlPinFormTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_CONTROLPINFORM_TAB", tab);
      resolve();
    });
  }, 
  
  //订单中心》 我的采购
  setProcurementTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_PROCUREMENT_TAB", tab);
      resolve();
    });
  },

  //订单中心》我的销售

  setSalesTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_SALES_TAB", tab);
      resolve();
    });
  },

  //serviceSheet订单中心》  售后服务单 
  setServiceSheetTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_SERVICE_SHEET_TAB", tab);
      resolve();
    });
  }, 

  //afterSalesDocument 订单中心》  售后单据
  setAfterSalesDocumentTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_AFTER_SALES_DOCUMENT_TAB", tab);
      resolve();
    });
  },

  //comments 评论管理
  setCommentsTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_COMMENTS_TAB", tab);
      resolve();
    });
  },

  //invoiceManage 财务中心》 发票管理
  setInvoiceManageTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_INVOICE_MANAGE_TAB", tab);
      resolve();
    });
  },

  //surety 财务中心》 保证金
  setSuretyTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_SURETY_TAB", tab);
      resolve();
    });
  },

  //pendingPayment 财务中心》 待付服务费
  setPendingPaymentTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_PENDING_PAYMENT_TAB", tab);
      resolve();
    });
  },

  //paidPayment 财务中心》 应收服务费
  setPaidPaymentTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_PAID_PAYMENT_TAB", tab);
      resolve();
    });
  },
  
  //salesControlBuyer 控销买家
  setSalesControlBuyerTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_SALES_CONTROL_BUYER_TAB", tab);
      resolve();
    });
  },

  //salesControlTeam 控销团队
  setSalesControlTeamTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_SALES_CONTROL_TEAM_TAB", tab);
      resolve();
    });
  },

  //platformActivity 平台活动
  setPlatformActivityTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_PLATFORM_ACTIVITY_TAB", tab);
      resolve();
    });
  },

  //platformSubsidy 平台补贴
  setPlatformSubsidyTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_PLATFORM_SUBSIDY_TAB", tab);
      resolve();
    });
  },

  //lookArticle 资讯文章
  setLookArticleTab({ commit }, tab) {
    return new Promise((resolve, reject) => { 
      commit("SET_LOOK_ARTICLE_TAB", tab);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
