const getApplyStatusImage = (saleStatus) => {
    // saleStatus	申请状态: 0. 待申请,1. 待审核,2 已通过,3 已驳回,4 已取消合作
    const applyImage = 'https://eshop-prod.oss-cn-chengdu.aliyuncs.com/front_static/cooperation-apply.png'//申请中
    const applyPassImage = 'https://eshop-prod.oss-cn-chengdu.aliyuncs.com/front_static/cooperation-ing.png'//合作中
    const applyRefuseImage = 'https://eshop-prod.oss-cn-chengdu.aliyuncs.com/front_static/cooperation-reject.png'//拒绝
  if (saleStatus === 0) {
    return null
  } else if (saleStatus === 1) {
    return applyImage
  } else if (saleStatus === 2) {
    return applyPassImage
  } else if (saleStatus === 3) {
    return applyRefuseImage
  } else if (saleStatus === 4) {
    return null
  }else {
    return null
  }
}
export default getApplyStatusImage