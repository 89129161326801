import request from "@/utils/request";
import qs from "qs";
import website from "@/utils/website";
import Vue from "vue";
// export function login(data) {
//   return request({
//     url: '/vue-admin-template/user/login',
//     method: 'post',
//     data
//   })
// }

const scope = "server";
export const loginByUsername = (username, password, code, randomStr) => {
  const grant_type = "password";
  let dataObj = qs.stringify({ username: username, password: password });

  let basicAuth = "Basic " + window.btoa(website.formLoginClient);

  return request({
    url: "/auth/oauth2/token",
    headers: {
      isLogin: true,
      Authorization: basicAuth,
    },
    method: "post",
    params: { randomStr, code, grant_type, scope },
    data: dataObj,
  });
};

export const getStatus = () => {
  return request({
    url: "/shop/shop/status",
    method: "get",
  });
};

export function getMenu(id) {
  return request({
    url: "/admin/menu",
    params: { parentId: id },
    method: "get",
  });
}

export function getInfo(userId) {
  checkEnablePasswordFreePayment(); 
  return request({
    // url: `member/manage/${userId}`,
    // url: 'admin/user/info',
    url: "/member/info/detailed",
    method: "get",
    params: {},
  });
}


// 检查商户状态 冻结状态提示
export function queryShopManageByLogin() { 
  return request({
    url: "/shop/shopManage/queryShopManageByLogin",
    method: "get",
    params: {},
  });
}

// export function getInfoa(token) {
//   return request({
//     url: '/vue-admin-template/user/info',
//     method: 'get',
//     params: { token }
//   })
// }

export function logout() {
  return request({
    url: "/vue-admin-template/user/logout",
    method: "post",
  });
}

// //用户详情查询
// export function getUserInfo(data) {
//   return request({
//     url: "/admin/user/"+data,
//     method: "get",
//   })
// }

export function imgUpload(data, onUploadProgress = null) {
  return request({
    url: `/shop/shopcertify/upload`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "post",
    data,
    onUploadProgress,
  });
}

// 用户详情查询
export function getUserInfo(data) {
  return request({
    url: "/admin/user/" + data,
    method: "get",
  });
}

// 修改密码
export function editPassword(data) {
  return request({
    // url: "/admin/user/modify/password",
    url: "/member/manage/modify/password",
    method: "put",
    data,
  });
}

// 修改密码
export function getRolesList(data) {
  return request({
    url: "/admin/role/shop/list",
    // url: '/member/manage/modify/password',
    method: "get",
    params: data,
  });
}

// 发送短信验证码
export function sendCode(data) {
  return request({
    url: "/member/sms/send/code",
    method: "post",
    data,
  });
}
// 会员注册
export function register(data) {
  return request({
    url: "/member/register",
    method: "post",
    data,
  });
}
// 确认修改密码
export function forgetPassword(data) {
  return request({
    url: "/member/forgetPassword/confirm-modify",
    method: "put",
    data,
  });
}
// 绑定商户
export function merchantBind(data) {
  return request({
    url: `/shop/merchant/bind`,
    method: "post",
    data,
  });
}
// 获取多康贝号列表
export function getMerchantList(data) {
  return request({
    url: `/shop/merchant/listAll`,
    method: "get",
    params: data,
  });
}
// 设置默认康贝号
export function setDefaultMerchant(data) {
  return request({
    url: `/shop/merchant/setDefault`,
    method: "get",
    params: data,
  });
}

// 根据商户编码获取手机号码并发送手机号码
export function findPhoneByMerchant(data) {
  return request({
    url: `/shop/merchant/v1/findPhoneByMerchant`,
    method: "get",
    params: data,
  });
}


// 启用康贝账号
export function merchantEnable(id) {
  return request({
    url: `/shop/merchant/enable/${id}`,
    method: "PUT",
  });
}
// 启用康贝账号
export function merchantDisable(id) {
  return request({
    url: `/shop/merchant/disable/${id}`,
    method: "PUT",
  });
}

// 检测当前登录地办，省办店铺是否开通代扣
export function checkEnablePasswordFreePayment(data) {
  // return
  const resp = request({
    url: `/payment/account/checkAccount`,
    method: "post",
    params: data,
  });

  resp.then((responseData) => {
    // const h = this.$createElement;
    const vm = new Vue({
      render: (h) =>
        h(
          "div",
          {
            attrs: { id: "checkEnablePasswordFreePayment" },
            style: {
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: "0px",
              left: "0px",
              position: "absolute",
              zIndex: "9999",
            },
          },
          [
            h(
              "div",
              {
                style: {
                  background: "#000000",
                  opacity: "0.5",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                },
              },
              null
            ),
            h(
              "div",
              {
                style: {
                  borderRadius: "4px",
                  position: "absolute",
                  width: "1360px",
                  height: "786px",
                },
              },
              [
                h("img", {
                  attrs: {
                    src: "https://eshop-prod.oss-cn-chengdu.aliyuncs.com/goods/955c1d4e64554717b18a9947f8d0e685.png",
                  },
                  style: { width: "100%", height: "100%" },
                }),
                h(
                  "div",
                  {
                    style: {
                      position: "absolute",
                      top: "155px",
                      left: "213px",
                      display: "flex",
                      alignItems: "center",
                    },
                  },
                  [
                    h(
                      "div",
                      {
                        on: {
                          click() {
                            document.body.removeChild(vm.$el);
                            checkEnablePasswordFreePayment();
                          },
                        },
                        style: {
                          width: "80px",
                          height: "32px",
                          backgroundColor: "#1676FE",
                          color: "#fff",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                        },
                      },
                      "刷新"
                    ),
                    h(
                      "div",
                      {
                        style: {
                          color: "#8C8C8C",
                          fontSize: "16px",
                          marginLeft: "10px",
                        },
                      },
                      "已开通免密支付？请点击刷新按钮"
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
    }).$mount();
    if (!responseData) {
      let view = document.getElementById("checkEnablePasswordFreePayment");
      if (view) {
        document.body.removeChild(view);
      }
      document.body.appendChild(vm.$el);
    } else {
      let view = document.getElementById("checkEnablePasswordFreePayment");
      if (view) {
        document.body.removeChild(view);
      }
    }
  });
}
// 获取ERP以及店铺状态
export const getShopStatusAndERPStatus = () => {
  return request({
    url: '/statistic/shop/status',
    method: 'get'
  })
}