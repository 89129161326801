<template>
  <div class="listContent">
    <div :class="['goodList', {'actived': isActived === index}]"
         v-for="(item, index) in list"
         @click="selectGoods(index)"
         :key="index">
      <i class="activeIcon el-icon-success"
         v-show="isActived === index"></i>
      <div class="l">
        <img :src="item.image"
             alt="" />
      </div>
      <div class="r">
        <div>
          <span class="t">名称</span>
          <span class="name rr">{{ item.name }}</span>
        </div>
        <div>
          <span class="t">规格</span>
          <span class="rr">{{ item.specification }}</span>
        </div>
        <div>
          <span class="t">批准文号</span>
          <span class="rr">{{ item.approvalNumber }}</span>
        </div>
        <div>
          <span class="t">厂家</span>
          <span class="rr">{{ item.factory }}</span>
        </div>
        <div>
          <span class="t">售价</span>
          <span class="rr">￥{{ item.salePrice }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    goodsList: {
      type: Array,
      default () {
        return [];
      },
    },
  },
  computed: {
    list () {
      return this.goodsList;
    },
  },
  data () {
    return {
      isActived: ""
    }
  },
  methods: {
    selectGoods (index) {
      this.isActived = index;
      this.$emit("chooseItem", index);
    }
  }
};
</script>

<style lang="scss" scoped>
.listContent {
  display: flex;
  flex-wrap: wrap;
  .goodList {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    height: 252px;
    width: 490px;
    padding: 16px;
    box-sizing: border-box;
    border: 1px solid rgba(225, 225, 225, 1);
    border-radius: 4px;
    margin-right: 20px;
    position: relative;
    .l {
      flex: 1;
      height: 220px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .r {
      flex: 1;
      margin-left: 10px;
      div {
        display: flex;
      }
      span {
        font-size: 13px;
        color: #333;
        margin-top: 10px;
      }
      .name {
        font-size: 16px;
      }
      .b {
        margin-top: 20px;
      }
      .t {
        margin-right: 10px;
        width: 80px;
        text-align: right;
        display: inline-block;
      }
      .rr {
        flex: 1;
      }
    }
    &:hover {
      border: 2px solid #0da1a8 !important;
    }
  }
  .actived {
    border: 2px solid #0da1a8 !important;
  }
  .activeIcon {
    font-size: 40px;
    position: absolute;
    color: #0da1a8;
    right: -10px;
    top: -10px;
    z-index: 6;
  }
}
</style>
