var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent contentInner" },
    [
      _c(
        "div",
        { staticClass: "titleInner" },
        [
          _c("MyTabs", {
            staticClass: "search",
            attrs: { tabi: _vm.tabsIndex, keyname: "val", tabs: _vm.tabsData },
            on: { change: _vm.tabChange },
          }),
          _c(
            "div",
            { staticClass: "title" },
            [
              _c(
                "el-form",
                {
                  ref: "formTool",
                  staticStyle: { "text-align": "left" },
                  attrs: { model: _vm.formTool, inline: true },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品名称", size: "small" },
                        model: {
                          value: _vm.formTool.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "name", $$v)
                          },
                          expression: "formTool.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "factory" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入生产厂家", size: "small" },
                        model: {
                          value: _vm.formTool.factory,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "factory", $$v)
                          },
                          expression: "formTool.factory",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("MyButton", {
                        attrs: { type: "primary", text: "查询" },
                        on: {
                          click: function ($event) {
                            return _vm.getList()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("MyButton", {
                        attrs: { type: "", text: "重置" },
                        on: {
                          click: function ($event) {
                            return _vm.reset()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("MyTable", {
            attrs: {
              "table-data": _vm.tableData,
              index: _vm.index,
              selection: _vm.selection,
              page: _vm.page,
              operation: _vm.operation,
              "table-option": _vm.tableOption,
            },
            on: {
              "update:tableOption": function ($event) {
                _vm.tableOption = $event
              },
              "update:table-option": function ($event) {
                _vm.tableOption = $event
              },
              "page-change": _vm.getList,
            },
            scopedSlots: _vm._u([
              {
                key: "image",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "80px", height: "80px" },
                      attrs: { src: scope.row.image },
                    }),
                  ]
                },
              },
              {
                key: "status",
                fn: function (scope) {
                  return [_c("MyTypes", { attrs: { type: scope.row.status } })]
                },
              },
              {
                key: "menu",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          type: "primary",
                          disabled: _vm.tabsIndex != "AWAIT",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.editDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v("审核")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialogBox",
          attrs: {
            title: "敏感词库",
            visible: _vm.dialogVisible,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", [
            _c("div", [
              _c(
                "span",
                {
                  staticStyle: { "font-size": "18px", "margin-right": "10px" },
                },
                [_vm._v("敏感词库")]
              ),
              _c("span", [
                _vm._v("商品标题、商品描述中含有敏感词的商品会被自动驳回"),
              ]),
            ]),
            _c("div", { staticClass: "bigBox" }, [
              _c(
                "div",
                {
                  staticClass: "everyOne",
                  staticStyle: {
                    "padding-right": "10px",
                    "border-right": "1px solid #ccc",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          size: "small",
                          clearable: "",
                          placeholder: "请输入内容",
                        },
                        model: {
                          value: _vm.input,
                          callback: function ($$v) {
                            _vm.input = $$v
                          },
                          expression: "input",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "8px" },
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.search(1)
                            },
                          },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "" },
                          on: { click: _vm.deleteScopes },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { height: "calc(100% - 70px)" } },
                    [
                      _c("MyTable", {
                        attrs: {
                          "table-data": _vm.tableDataDialog,
                          index: _vm.indexDialog,
                          selection: _vm.selectionDialog,
                          page: _vm.pageDialog,
                          operation: _vm.operationDialog,
                          "table-option": _vm.tableOptionDialog,
                        },
                        on: {
                          "update:tableOption": function ($event) {
                            _vm.tableOptionDialog = $event
                          },
                          "update:table-option": function ($event) {
                            _vm.tableOptionDialog = $event
                          },
                          "page-change": _vm.getListDialog,
                          handleSelectionChange: _vm.handleSelectionChange,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "caozuo",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      underline: false,
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteScope(1, scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("移除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "everyOne",
                  staticStyle: { "margin-left": "10px" },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "100%", width: "100%" } },
                    [
                      _c("el-input", {
                        staticStyle: { height: "calc(100% - 75px)" },
                        attrs: {
                          type: "textarea",
                          placeholder: "例如：依赖性|上瘾",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.textarea,
                          callback: function ($$v) {
                            _vm.textarea = $$v
                          },
                          expression: "textarea",
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          "注：输入格式为 敏感词|敏感词，敏感词间分隔符为“|”。"
                        ),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "end" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  _vm.dialogVisible = false
                                },
                              },
                            },
                            [_vm._v("关闭")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.inputSensitiveWords },
                            },
                            [_vm._v("录入")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }