var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("el-alert", {
        attrs: {
          type: "warning",
          "show-icon": "",
          title:
            "请设置你的开票方式，若未设置普通电子发票，则买家不能申请开电子发票。",
        },
      }),
      _c(
        "div",
        { staticClass: "centerBox" },
        [
          _c(
            "el-form",
            {
              ref: "formTool",
              attrs: {
                model: _vm.formTool,
                "label-width": "120px",
                inline: false,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": "50px" } },
                [
                  _c("h3", { staticClass: "form_h3" }, [_vm._v("普票")]),
                  _c("el-divider"),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "checkList", label: "开票类型" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.formTool.checkList,
                        callback: function ($$v) {
                          _vm.$set(_vm.formTool, "checkList", $$v)
                        },
                        expression: "formTool.checkList",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "1" } }, [
                        _vm._v("普通纸质发票"),
                      ]),
                      _c("el-checkbox", { attrs: { label: "2" } }, [
                        _vm._v("普通电子发票"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "radio", label: "送达时间" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formTool.radio,
                        callback: function ($$v) {
                          _vm.$set(_vm.formTool, "radio", $$v)
                        },
                        expression: "formTool.radio",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("随货")]),
                      _c("el-radio", { attrs: { label: 6 } }, [_vm._v("月开")]),
                      _c("el-radio", { attrs: { label: 9 } }, [
                        _vm._v("其他(请在其他说明中备注)"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.formTool.radio == 9
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "desc", label: "备注说明" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入发票送达时间备注说明",
                          size: "small",
                        },
                        model: {
                          value: _vm.formTool.desc,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "desc", $$v)
                          },
                          expression: "formTool.desc",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { "label-width": "50px" } },
                [
                  _c("h3", { staticClass: "form_h3" }, [_vm._v("专票")]),
                  _c("el-divider"),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "checkList2", label: "开票类型" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.formTool.checkList2,
                        callback: function ($$v) {
                          _vm.$set(_vm.formTool, "checkList2", $$v)
                        },
                        expression: "formTool.checkList2",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "1" } }, [
                        _vm._v("支持开专票"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("MyButton", {
                    staticStyle: { width: "120px" },
                    attrs: { type: "primary", icon: "", text: "保存" },
                    on: { click: _vm.goSubmit },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }