var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-input", {
    attrs: {
      size: _vm.size,
      type: _vm.type,
      placeholder: _vm.placeholder,
      clearable: _vm.clearable,
      disabled: _vm.disabled,
    },
    on: { input: _vm.change },
    model: {
      value: _vm.inputText,
      callback: function ($$v) {
        _vm.inputText = $$v
      },
      expression: "inputText",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }