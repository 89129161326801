var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tableHeight
    ? _c(
        "el-table",
        {
          ref: "tableRef",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, height: _vm.tableHeight },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "incomeTime", label: "出入账时间" },
          }),
          _c("el-table-column", {
            attrs: { prop: "erpCode", label: "客户ERP编码" },
          }),
          _c("el-table-column", {
            attrs: { prop: "customerName", label: "客户名称" },
          }),
          _c("el-table-column", {
            attrs: { label: "收支金额（元）", align: "right" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return _c("div", { staticClass: "flexRight" }, [
                      _c("div", [
                        _vm._v(
                          _vm._s(scope.row.amountType == "INCOME" ? "+" : "-") +
                            _vm._s(scope.row.amount.toFixed(2))
                        ),
                      ]),
                    ])
                  },
                },
              ],
              null,
              false,
              241726436
            ),
          }),
          _c("el-table-column", {
            attrs: { prop: "type", label: "收支类型" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return _c("div", {}, [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.amountType == "INCOME" ? "收入" : "提现"
                          ) +
                          " "
                      ),
                    ])
                  },
                },
              ],
              null,
              false,
              3335517180
            ),
          }),
          _c("el-table-column", {
            attrs: { prop: "orderTime", label: "订单创建时间" },
          }),
          _c("el-table-column", {
            attrs: { prop: "orderCode", label: "订单ID" },
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "银行是否已清算" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return _c("div", {}, [_vm._v(" 是 ")])
                  },
                },
              ],
              null,
              false,
              3599161343
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }