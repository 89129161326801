<template>
  <div class="goodsSalesStatMain"
       ref="goodsSalesStatMainOne">
    <div class="flexLineView">
      <div class="row">
        <div id="mainChart"
             style="height: 100%;width: 100%;"></div>
        <div class="dropDownBtn"
             v-if="monthOrYear != 'custom'">
          <span style="margin-top:6px;margin-left:12px;color:#262626;font-size:16px;font-weight:600;">全国销售统计</span>
          <span style="display:flex;margin-top:6px;margin-left:12px;color:#262626;font-size:16px;font-weight:600;">
            <span style="display: flex;flex-direction: column;">
              <span class="rightFont">
                {{ monthOrYear == 'weeks' ? '本周销售(元)' : monthOrYear == 'month' ? '本月销售(元)': monthOrYear == 'year' ? '本年销售(元)' : '' }}
              </span>
              <span style="margin: auto;"
                    class="rightNumber">{{ amount }}</span>
            </span>
            <span style="margin-left:10px;display: flex;flex-direction: column;">
              <span class="rightFont">
                {{ monthOrYear == 'weeks' ? '环比上周' : monthOrYear == 'month' ? '环比上月': monthOrYear == 'year' ? '环比上年' : '' }}
              </span>
              <span style="margin: auto;color: #32ae7c;"
                    class="rightNumber"
                    v-if="Number(chain_flag) > 0">↑</span>
              <span style="margin: auto;color: red;"
                    class="rightNumber"
                    v-else>↓</span>
            </span>
          </span>

        </div>
        <div class="numberView">
          <img :src="number1"
               alt=""
               class="numberImg">
        </div>
      </div>
      <div class="lineViewHorizon"></div>
      <div class="row">
        <div id="mainChartSecond"
             style="height: 100%;width: 100%;"></div>
        <div class="dropDownBtn"
             style="justify-content: flex-start;">

          <span style="margin-top:6px;margin-left:12px;color:#262626;font-size:16px;font-weight:600;">区域销售统计</span>
          <span style="display:flex;margin-top:6px;margin-left:12px;color:#262626;font-size:16px;font-weight:600;">
            <span style="display: flex;flex-direction: column;">
              <span class="rightFont">
                {{ province }}
              </span>
            </span>

          </span>

        </div>
        <div class="numberView">
          <img :src="number3"
               alt=""
               class="numberImg">
        </div>
      </div>
    </div>
    <div class="lineView"></div>
    <div class="flexLineView">
      <div class="row">
        <div class="classNameView"
             style="margin-top: 10px;">
          <span style="margin-left:12px;font-weight: 600;color: #262626;font-size: 16px;">区域销售排名</span>
          <!-- {{firstSelectData?firstSelectData.goods_one_class_name+'/'+firstSelectData.goods_two_class_name+'/'+firstSelectData.goods_three_class_name : ''}} -->
        </div>
        <el-table :data="categoryTableData"
                  @row-click="rowClick"
                  style="width: 100%;"
                  highlight-current-row
                  :height="tableHeight - 38"
                  ref="hilightTable">
          <el-table-column prop="rn"
                           label="排名"
                           width="50">
          </el-table-column>
          <el-table-column prop="province_name"
                           label="区域">
          </el-table-column>
          <el-table-column prop="amount"
                           label="销售额（元）">
          </el-table-column>
          <el-table-column prop="pct"
                           label="占比"
                           width="100">
            <template slot-scope="scope">
              <div class="percentView">
                <div class="percentBg"
                     :style="{width:getPercent(scope.row.pct)+'%'}"></div>
                <div class="percentText">{{getPercent(scope.row.pct)}}%</div>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="qty"
                           label="采购数量"
                           width="100">
          </el-table-column>
          <el-table-column prop="price"
                           label="单价(元)">
          </el-table-column>
          <el-table-column prop="amount"
                           label="采购额(元)">
          </el-table-column> -->
          <!-- <el-table-column label="销售占比"
                             width="100">
              <template slot-scope="scope">
                <div class="percentView">
                  <div class="percentBg"
                       :style="{width:getPercent(scope.row.pct)+'%'}"></div>
                  <div class="percentText">{{getPercent(scope.row.pct)}}%</div>
                </div>
              </template>
            </el-table-column> -->
        </el-table>
        <div class="numberView">
          <img :src="number2"
               alt=""
               class="numberImg">
        </div>
      </div>
      <div class="lineViewHorizon"></div>
      <div class="secondTableView">
        <div class="dropDownBtn">
          <span style="margin-top:6px;margin-left:12px;color:#262626;font-size:16px;font-weight:600;">商品</span>
          <span style="display:flex;margin-top:6px;margin-left:12px;color:#262626;font-size:16px;font-weight:600;">
            <span style="display: flex;flex-direction: column;">
              <span class="rightFont">
                商品总数
              </span>
              <span style="margin: auto;"
                    class="rightNumber">{{ all_goods_total_cnt }}</span>
            </span>
            <span style="margin-left:10px;display: flex;flex-direction: column;">
              <span class="rightFont">
                控销商品数
              </span>
              <span style="margin: auto;"
                    class="rightNumber">{{ all_goods_ctr_sale_cnt }}</span>
            </span>
            <span style="margin-left:10px;display: flex;flex-direction: column;">
              <span class="rightFont">
                普药商品数
              </span>
              <span style="margin: auto;"
                    class="rightNumber">{{ all_goods_putaway_cnt }}</span>
            </span>
          </span>

        </div>
        <el-table :data="categoryTableDataSecond"
                  style="width: 100%;margin-top: 56px;"
                  highlight-current-row
                  :height="tableHeight - 63"
                  ref="hilightTableSecond">
          <el-table-column prop="rn"
                           label="排名"
                           width="50">
          </el-table-column>
          <el-table-column prop="goods_one_class_name"
                           label="分类">
          </el-table-column>
          <el-table-column prop="goods_total_cnt"
                           label="商品总数">
          </el-table-column>
          <el-table-column prop="goods_putaway_cnt"
                           label="普药商品数">
          </el-table-column>
          <el-table-column prop="goods_ctr_sale_cnt"
                           label="控销商品数"
                           width="100">
          </el-table-column>
          <el-table-column prop="amount"
                           label="销售额(元)">
          </el-table-column>
          <el-table-column label="销售占比"
                           width="100">
            <template slot-scope="scope">
              <div class="percentView">
                <div class="percentBg"
                     :style="{width:getPercent(scope.row.pct)+'%'}"></div>
                <div class="percentText">{{getPercent(scope.row.pct)}}%</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="numberView">
          <img :src="number3"
               alt=""
               class="numberImg">
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { queryGoodsSalesEcharts } from "@/api/statistics";
import number1 from "@/assets/number1.png";
import number2 from "@/assets/number2.png";
import number3 from "@/assets/number3.png";
import dayjs from 'dayjs'
import _ from 'lodash'
export default {
  props: {},
  data () {
    return {
      chain_flag: '',
      amount: '', // 本月销售
      all_goods_total_cnt: '',
      all_goods_putaway_cnt: '',
      all_goods_ctr_sale_cnt: '',
      province: '', // 省份名字
      startTimeSecond: '', // 表二的时间记录下来
      endTimeSecond: '', // 表二的时间记录下来
      shopId: '',
      selectIndex: 0, // 选中1号图表
      screenWidth: '',
      number2: number2,
      number3: number3,
      number1: number1,
      tableHeight: 0,
      tableDataChange: [],
      tableData: [],
      categoryTableData: [],
      categoryTableDataSecond: [],
      customDate: '',
      monthSelectIndex: 0,
      weeksSelectIndex: 0,
      monthOrYear: '',
      chartDataOne: [],
      chartDataAxisOne: [],
      chartDataTwo: [],
      chartDataAxisTwo: []
    }
  },
  computed: {
    opened () {
      return this.$store.state.app.sidebar.opened;　　//需要监听的数据
    }
  },
  components: {},
  inject: ['superParams'],
  watch: { //监听数据变化
    opened (newV, oldV) {
      this.$echarts.init(document.getElementById('mainChart')).dispose()
      this.$echarts.init(document.getElementById('mainChartSecond')).dispose()
      const mainWindow = this.$refs.goodsSalesStatMainOne
      setTimeout(() => {
        this.eachRowWidth = (mainWindow.offsetWidth - 12) / 2
        this.tableHeight = (mainWindow.offsetHeight - 12 - 24) / 2
        mainWindow.style.setProperty('--row-width', this.eachRowWidth + 'px')
        mainWindow.style.setProperty('--main-height', mainWindow.offsetHeight - 24 + 'px')
        this.drawChart();
        this.mainChartSecond()
      }, 300)

    },
    //年统计时间监听
    'superParams.yearCheck': {
      handler: function (val, oldVal) {
        // this.weeksSelectIndex = this.superParams.weeksSelectIndex;
        // console.log("监听weeksSelectIndex对象", val); //自定义
        if (this.shopId) {
          this.getData(30)
          this.getData(32)
        } else {
          this.getData(23)
          this.getData(25)
        }
      },
      deep: true,
    },
    //周统计时间监听
    'superParams.weeksSelectIndex': {
      handler: function (val, oldVal) {
        this.weeksSelectIndex = this.superParams.weeksSelectIndex;
        // console.log("监听weeksSelectIndex对象", val); //自定义
        if (this.shopId) {
          this.getData(30)
          this.getData(32)
        } else {
          this.getData(23)
          this.getData(25)
        }
      },
      deep: true,
    },

    //月统计监听
    'superParams.monthSelectIndex': {
      handler: function (val, oldVal) {
        this.monthSelectIndex = this.superParams.monthSelectIndex;
        // console.log("监听monthSelectIndex对象", val);
        if (this.shopId) {
          this.getData(30)
          this.getData(32)
        } else {
          this.getData(23)
          this.getData(25)
        }
      },
      deep: true,
    },

    //自定义时间监听
    'superParams.customDate': {
      handler: function (val, oldVal) {
        this.customDate = this.superParams.customDate;
        // console.log("监听customDate对象", val); //自定义 
        if (this.shopId) {
          this.getData(30)
          // this.getData(32)
        } else {
          this.getData(23)
          // this.getData(25)
        }
      },
      deep: true,
    },

    //时间类型切换监听 monthOrYear
    'superParams.monthOrYear': {
      immediate: true, // 将立即以表达式的当前值触发回调
      handler: function (val, oldVal) {
        // console.log("监听tabPosition对象", val); //周，年，月，自定义 统计切换 
        this.monthOrYear = this.superParams.monthOrYear; // 时间类型 
        this.shopId = this.superParams.shopId
        // console.log(this.shopId, 'shopId')
        if (this.shopId) {
          if (this.superParams.monthOrYear != 'custom') {
            this.getData(32)
          }
          if (this.superParams.monthOrYear != 'year') {
            this.getData(30)
          } else {
            this.getData(31)
          }
        } else {
          if (this.superParams.monthOrYear != 'custom') {
            this.getData(25)
          }
          if (this.superParams.monthOrYear != 'year') {
            this.getData(23)
          } else {
            this.getData(24)
          }
        }
      },
      deep: true,
    },
  },
  created () {
  },
  mounted () {
    const mainWindow = this.$refs.goodsSalesStatMainOne
    this.eachRowWidth = (mainWindow.offsetWidth - 12) / 2
    this.tableHeight = (mainWindow.offsetHeight - 12 - 24) / 2
    mainWindow.style.setProperty('--row-width', this.eachRowWidth + 'px')
    mainWindow.style.setProperty('--main-height', mainWindow.offsetHeight - 24 + 'px')
    this.drawChart();
    this.mainChartSecond()
  },
  // watch: {
  //   opened (newV, oldV) {
  //     this.$echarts.init(document.getElementById('mainChart')).dispose()
  //     this.$echarts.init(document.getElementById('mainChartSecond')).dispose()
  //     const mainWindow = this.$refs.goodsSalesStatMainOne
  //     setTimeout(() => {
  //       this.eachRowWidth = (mainWindow.offsetWidth - 12) / 2
  //       this.tableHeight = (mainWindow.offsetHeight - 12 - 24) / 2
  //       mainWindow.style.setProperty('--row-width', this.eachRowWidth + 'px')
  //       mainWindow.style.setProperty('--main-height', mainWindow.offsetHeight - 24 + 'px')
  //       this.drawChart();
  //       this.mainChartSecond()
  //     }, 300)

  //   }
  // },
  methods: {
    drawChart () {
      this.$echarts.init(document.getElementById('mainChart')).dispose()
      //2. 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById('mainChart'));
      //3. 指定图表的配置项和数据
      let that = this
      var option = {
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {
          // trigger: 'axis',
          // confine: true,
          formatter: function (params) {
            console.log(params, 'params')
            let str = '';
            str += `${'销售额（元）'}<br/>`;
            str += `<span class="chart-tooltip-color" style="display: inline-block; margin-right: 10px; width: 10px; height: 10px;background-color: ${params.color}; border-radius:100%; margin-right: 5px"></span>`;
            str += `${params.value}`;
            return str;
          }
        },
        // legend: {    //图例组件
        //   data: ['销量']
        // },
        xAxis: {
          data: this.chartDataAxisOne,
          axisTick: {
            show: false
          }
        },
        yAxis: {},
        series: [{
          name: '销量',
          type: 'bar',
          color: '#73A0FA',
          data: this.chartDataOne,
          itemStyle: {
            normal: {
              label: true,
              color: function (params) {
                if (params.dataIndex == that.selectIndex) {
                  return '#73DEB3'
                } else {
                  return '#73A0FA'
                }
              }
            }
          }
        }]
      };
      myChart.on('click', function (param) {
        //获取自定义变量barIds的值,barIds要和option的series里自定义的一样
        // console.log(param.dataIndex)
        that.selectIndex = param.dataIndex
        if (that.shopId) {
          that.getData(33, that.tableData[param.dataIndex])
        } else {
          that.getData(26, that.tableData[param.dataIndex])
        }
        myChart.resize();
        // console.log(param)

      });
      //4.使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    mainChartSecond () {
      this.$echarts.init(document.getElementById('mainChartSecond')).dispose()
      //2. 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById('mainChartSecond'));
      //3. 指定图表的配置项和数据
      let that = this
      var option = {
        tooltip: {
          trigger: 'axis',
          confine: true,
          formatter: function (params) {
            console.log(params, 'params')
            if (params instanceof Array) {
              let str = '';
              str += `${params[0].axisValue}<br/>`;
              params.forEach((m, index) => {
                str += `<span class="chart-tooltip-color" style="display: inline-block; margin-right: 10px; width: 10px; height: 10px;background-color: ${m.color}; border-radius:100%; margin-right: 5px"></span>`;
                str += `${m.seriesName}：${m.value}`;
                str += `<span style="margin-right:5px"></span>${index % 3 === 0 ? '<br/>' : ''}`; //一排放几个可根据实际情况改变
              });
              return str;
            }
          }
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          top: '15%',
          icon: 'roundRect',
          // selected: {
          //   '武汉市': false,
          // }
        },
        grid: {
          top: '30%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: that.chartDataAxisTwo,
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value'
        },
        series: that.chartDataTwo
      };
      //4.使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    getPercent (v) {
      if (!v) {
        return '0.00'
      }
      return (v * 100).toFixed(2)
    },
    handleCurrentChange (e) {
      // 点击第一个table的某一项
      // this.firstSelectData = e
      // if (this.superParams.monthOrYear != 'year') {
      //   this.getData(12, e)
      // } else {
      //   this.getData(13, e)
      // }
      // this.getData(14, e)
      // this.setTableFirst(99999)
    },
    // 请求数据
    async getData (type, data) {
      // 2023 8
      // type	类型（与报表端确认，一个接口一个类型） 月 1,2,3  /  年 4,5,6
      // params	参数组(示例： '202308','2' )  '202308','2','1696371675141570561'   /  时间  ， 2 控销  1普药 ，
      let startTime = ''
      let endTime = ''
      const m = this.superParams.monthArr[this.superParams.monthSelectIndex].m
      const day = (new Date().getDate() - 1) < 10 ? '0' + (new Date().getDate() - 1) : (new Date().getDate() - 1)
      const lastDay = dayjs(this.superParams.year + m).endOf('month').format('YYYY-MM-DD')
      const month = new Date().getMonth() < 9 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
      // const postDate = this.superParams.monthOrYear == 'year' ? this.year : this.year + m
      // type = this.superParams.monthOrYear == 'year' ? type + 3 : type
      if (this.superParams.monthOrYear == 'weeks') {
        startTime = this.superParams.weeksSelectIndex.startDate.replaceAll('-', '')
        endTime = this.superParams.weeksSelectIndex.endDate.replaceAll('-', '')
      } else if (this.superParams.monthOrYear == 'month') {
        startTime = this.superParams.year + m + '01'
        if (m != month) {
          endTime = lastDay.replaceAll('-', '')
        } else {
          endTime = this.superParams.year + m + day
        }
      } else if (this.superParams.monthOrYear == 'year') {
        startTime = this.superParams.yearCheck + '0101'
        endTime = this.superParams.yearCheck + month + day
        if (Number(this.yearCheck) < Number(new Date().getFullYear())) {
          startTime = this.superParams.yearCheck + '0101'
          endTime = this.superParams.yearCheck + '1231'
        }
      } else if (this.superParams.monthOrYear == 'custom') {
        startTime = this.superParams.customDate[0].replaceAll('-', '')
        endTime = this.superParams.customDate[1].replaceAll('-', '')
      }
      let obj = {
        type: String(type),
      }
      if (type == 23 || type == 24) { // 全国销售统计
        // {"type":23,"params":["20230801","20230924","20230801","20230924",]} 参数格式
        obj['params'] = [startTime, endTime, startTime, endTime]
      } else if (type == 25) { // 全国销售统计 环比接口
        // {"type":25,"params":[本期开始日期,本期结束日期,上期开始日期,上期结束日期,本期开始日期,本期结束日期,上期开始日期,上期结束日期]} 

        let lastStartTime = ''
        let lastEndTime = ''
        if (this.superParams.monthOrYear == 'weeks') {
          lastStartTime = dayjs(this.superParams.weeksSelectIndex.startDate).add(-7, 'day').format('YYYYMMDD')
          lastEndTime = dayjs(this.superParams.weeksSelectIndex.endDate).add(-7, 'day').format('YYYYMMDD')
        } else if (this.superParams.monthOrYear == 'month') {
          startTime = this.superParams.year + '-' + m + '-01'
          if (m != month) {
            endTime = lastDay
          } else {
            endTime = this.superParams.year + '-' + m + '-' + day
          }
          lastStartTime = dayjs(startTime).add(-1, 'month').format('YYYYMMDD')
          lastEndTime = dayjs(endTime).add(-1, 'month').format('YYYYMMDD')
        } else if (this.superParams.monthOrYear == 'year') {
          startTime = this.superParams.yearCheck + '-01-01'
          endTime = this.superParams.yearCheck + '-' + month + '-' + day
          lastStartTime = dayjs(startTime).add(-1, 'year').format('YYYYMMDD')
          lastEndTime = dayjs(endTime).add(-1, 'year').format('YYYYMMDD')
        } else if (this.superParams.monthOrYear == 'custom') {
          return
        }
        obj['params'] = [dayjs(startTime).format('YYYYMMDD'), dayjs(endTime).format('YYYYMMDD'), lastStartTime, lastEndTime, dayjs(startTime).format('YYYYMMDD'), dayjs(endTime).format('YYYYMMDD'), lastStartTime, lastEndTime,] //goodsid  goodsname   shopid
      } else if (type == 26) { // 区域销售排名
        // {"type":26,"params":["20230101","20230924"]}
        if (data.biz_date) {
          // 上面的时间为其他的情况
          obj['params'] = [data.biz_date, data.biz_date]
        } else {
          // 上面的时间为年的情况

          let checkDate = this.tableData[this.selectIndex].id.slice(0, 4) + '-' + this.tableData[this.selectIndex].id.slice(4)
          let selectMonth = new Date(checkDate).getMonth()
          // console.log(selectMonth)
          if (selectMonth < new Date().getMonth()) {
            let monthNow = selectMonth < 9 ? '0' + (selectMonth + 1) : (selectMonth + 1)
            obj['params'] = [this.superParams.yearCheck + monthNow + '01', dayjs(this.superParams.yearCheck + monthNow).endOf('month').format('YYYYMMDD')]
          } else {
            let monthNow = selectMonth < 9 ? '0' + (selectMonth + 1) : (selectMonth + 1)
            obj['params'] = [this.superParams.yearCheck + monthNow + '01', this.superParams.yearCheck + monthNow + day]
          }

        }

      } else if (type == 27) { // 区域销售统计 周 月 自定义
        // {"type":27,"params":[区域 开始日期 结束日期 区域]} 
        obj['params'] = [data.province_code, data.startTime, data.endTime, data.province_code] //threeclassid
      } else if (type == 28) {  // 区域销售统计 年
        // {"type":28,"params":[开始日期 结束日期 区域 开始日期 结束日期 区域]} 
        obj['params'] = [data.startTime, data.endTime, data.province_code, data.startTime, data.endTime, data.province_code] //threeclassid
      } else if (type == 29) { // 商品
        // {"type":29,"params":[开始日期 结束日期 区域 结束日期]} 
        obj['params'] = [data.startTime, data.endTime, data.province_code, data.endTime] //threeclassid
      } else if (type == 30 || type == 31) { // 全国销售统计
        // {"type":23,"params":["20230801","20230924","20230801","20230924",shopId]} 参数格式
        obj['params'] = [startTime, endTime, startTime, endTime, this.shopId]
      } else if (type == 32) { // 全国销售统计 环比接口
        // {"type":25,"params":["20230901","20230924",shopId]} 
        let lastStartTime = ''
        let lastEndTime = ''
        if (this.superParams.monthOrYear == 'weeks') {
          lastStartTime = dayjs(this.superParams.weeksSelectIndex.startDate).add(-7, 'day').format('YYYYMMDD')
          lastEndTime = dayjs(this.superParams.weeksSelectIndex.endDate).add(-7, 'day').format('YYYYMMDD')
        } else if (this.superParams.monthOrYear == 'month') {
          startTime = this.superParams.year + '-' + m + '-01'
          if (m != month) {
            endTime = lastDay
          } else {
            endTime = this.superParams.year + '-' + m + '-' + day
          }
          lastStartTime = dayjs(startTime).add(-1, 'month').format('YYYYMMDD')
          lastEndTime = dayjs(endTime).add(-1, 'month').format('YYYYMMDD')
        } else if (this.superParams.monthOrYear == 'year') {
          startTime = this.superParams.yearCheck + '-01-01'
          endTime = this.superParams.yearCheck + '-' + month + '-' + day
          lastStartTime = dayjs(startTime).add(-1, 'year').format('YYYYMMDD')
          lastEndTime = dayjs(endTime).add(-1, 'year').format('YYYYMMDD')
        } else if (this.superParams.monthOrYear == 'custom') {
          return
        }
        obj['params'] = [dayjs(startTime).format('YYYYMMDD'), dayjs(endTime).format('YYYYMMDD'), lastStartTime, lastEndTime, dayjs(startTime).format('YYYYMMDD'), dayjs(endTime).format('YYYYMMDD'), lastStartTime, lastEndTime, this.shopId]
        // obj['params'] = [startTime, endTime, this.shopId] //goodsid  goodsname   shopid
      } else if (type == 33) { // 区域销售排名
        if (data.biz_date) {
          // 上面的时间为其他的情况
          obj['params'] = [data.biz_date, data.biz_date, this.shopId]
        } else {
          // 上面的时间为年的情况

          let checkDate = this.tableData[this.selectIndex].id.slice(0, 4) + '-' + this.tableData[this.selectIndex].id.slice(4)
          let selectMonth = new Date(checkDate).getMonth()
          // console.log(selectMonth)
          if (selectMonth < new Date().getMonth()) {
            let monthNow = selectMonth < 9 ? '0' + (selectMonth + 1) : (selectMonth + 1)
            obj['params'] = [this.superParams.yearCheck + monthNow + '01', dayjs(this.superParams.yearCheck + monthNow).endOf('month').format('YYYYMMDD'), this.shopId]
          } else {
            let monthNow = selectMonth < 9 ? '0' + (selectMonth + 1) : (selectMonth + 1)
            obj['params'] = [this.superParams.yearCheck + monthNow + '01', this.superParams.yearCheck + monthNow + day, this.shopId]
          }

        }

        // {"type":26,"params":["20230101","20230924",shopId]}
      } else if (type == 34) { // 区域销售统计 周 月 自定义
        // {"type":27,"params":[区域 开始日期 结束日期 区域]} 
        obj['params'] = [data.province_code, data.startTime, data.endTime, data.province_code, this.shopId] //threeclassid
      } else if (type == 35) {  // 区域销售统计 年
        // {"type":28,"params":[开始日期 结束日期 区域 开始日期 结束日期 区域,shopId]} 
        obj['params'] = [data.startTime, data.endTime, data.province_code, data.startTime, data.endTime, data.province_code, this.shopId] //threeclassid
      } else if (type == 36) { // 商品
        // {"type":29,"params":[开始日期 结束日期 区域 shipId  结束日期,shopId]} 
        obj['params'] = [data.startTime, data.endTime, data.province_code, this.shopId, data.endTime, this.shopId] //threeclassid
      }
      const resp = await queryGoodsSalesEcharts(obj)
      // console.log(resp)
      if (resp && resp.length) {
        if (type == 23 || type == 24 || type == 30 || type == 31) {
          this.selectIndex = 0
          this.chartDataOne = []
          this.chartDataAxisOne = []
          resp.forEach(item => {
            this.chartDataOne.push(item.amount)
            this.chartDataAxisOne.push(item.id)
          })
          this.tableData = resp

          this.drawChart()
          const data = resp[0]
          this.firstSelectData = data
          if (this.shopId) {
            this.getData(33, data)
          } else {
            this.getData(26, data)
          }
          // this.setTableFirst(0)
        } else if (type == 26 || type == 33) {
          // console.log(resp, '26-33')
          this.categoryTableData = resp
          let data = resp[0]
          this.$refs.hilightTable.setCurrentRow(data)
          this.startTimeSecond = obj.params[0]
          this.endTimeSecond = obj.params[1]
          this.province = data.province_name
          data.startTime = obj.params[0]
          data.endTime = obj.params[1]
          if (this.shopId) {
            if (this.superParams.monthOrYear == 'year') {
              this.getData(35, data)
            } else {
              this.getData(34, data)
            }
            this.getData(36, data)
          } else {
            if (this.superParams.monthOrYear == 'year') {
              this.getData(28, data)
            } else {
              this.getData(27, data)
            }
            this.getData(29, data)
          }
          // this.setTableSecond(resp || [], data)
        } else if (type == 27 || type == 34 || type == 28 || type == 35) {
          this.chartDataAxisTwo = []
          this.chartDataTwo = []
          // 按 city_code 分组 
          const resultArr = _.groupBy(resp, 'city_code')
          // [{ 'city_code' : [{ name:'',city_code:'' }] }]
          let echarsArr = []
          for (const el in resultArr) {
            if (Object.hasOwnProperty.call(resultArr, el)) {
              const cityArr = resultArr[el];
              let cityAmount = []
              let cityData = { name: '', data: [], xArr: [], type: 'line' }
              cityArr.forEach(city => {
                cityData.xArr.push(city.id)
                cityAmount.push(city.amount)
              });
              cityData.name = cityArr[0].city_name
              cityData.data = cityAmount
              echarsArr.push(cityData)
            }
          }
          // x轴坐标
          this.chartDataAxisTwo = echarsArr[0].xArr
          this.chartDataTwo = echarsArr
          // console.log(this.chartDataAxisTwo)
          // console.log(this.chartDataTwo)
          this.mainChartSecond()
        } else if (type == 29 || type == 36) {
          this.categoryTableDataSecond = resp
          this.all_goods_total_cnt = resp[0].all_goods_total_cnt
          this.all_goods_putaway_cnt = resp[0].all_goods_putaway_cnt
          this.all_goods_ctr_sale_cnt = resp[0].all_goods_ctr_sale_cnt
        } else if (type == 25 || type == 32) {
          this.amount = resp[0].amount
          this.chain_flag = resp[0].chain_flag
        }

      } else {
        if (type == 23 || type == 24 || type == 30 || type == 31) {
          this.resetDefault()
        } else if (type == 27 || type == 34 || type == 28 || type == 35) {
          this.chartDataAxisTwo = []
          this.chartDataTwo = []
          this.mainChartSecond()

        } else if (type == 26 || type == 33) {
          this.all_goods_total_cnt = '-'
          this.all_goods_putaway_cnt = '-'
          this.all_goods_ctr_sale_cnt = '-'
          this.categoryTableData = []
          this.chartDataAxisTwo = []
          this.chartDataTwo = []
          this.mainChartSecond()
          this.categoryTableDataSecond = []
        } else if (type == 29 || type == 36) {
          this.categoryTableDataSecond = []
          this.all_goods_total_cnt = '-'
          this.all_goods_putaway_cnt = '-'
          this.all_goods_ctr_sale_cnt = '-'
        } else if (type == 25 || type == 32) {
          this.amount = ''
          this.chain_flag = ''
        }
      }
    },
    resetDefault () {
      // 重置默认数据
      this.chartDataOne = []
      this.chartDataAxisOne = []
      this.tableData = []
      this.categoryTableData = []
      this.drawChart()
    },
    rowClick (item) {
      // console.log(item)
      this.province = item.province_name
      let data = item
      data.startTime = this.startTimeSecond
      data.endTime = this.endTimeSecond
      if (this.shopId) {
        if (this.superParams.monthOrYear == 'year') {
          this.getData(35, data)
        } else {
          this.getData(34, data)
        }
        this.getData(36, data)
      } else {
        if (this.superParams.monthOrYear == 'year') {
          this.getData(28, data)
        } else {
          this.getData(27, data)
        }
        this.getData(29, data)
      }
    }
  }
}

</script>
<style lang="scss" scoped>
$eachWidth: var(--row-width, "500px");
$mainHeight: var(--main-height, "600px");
.goodsSalesStatMain {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: calc(100% - 165px - 12px);
  margin-top: 12px;

  .flexLineView {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: calc(50% - 6px);

    .row {
      width: 100%;
      background-color: #fff;
      border-radius: 4px;
      overflow: hidden;
      height: calc(50% - 6px);
      padding: 12px;
      position: relative;
    }
  }

  .mainHeight {
    height: $mainHeight + 24px;
    border-radius: 4px;
    overflow: hidden;

    background-color: #fff;
    position: relative;
    padding: 12px;
  }
  .dropDownBtn {
    width: calc(100% - 24px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 12px;
    // left: 24px;
    .rightFont {
      margin: auto;
      height: 22px;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #595959;
      line-height: 22px;
    }
    .rightNumber {
      margin: auto;
      height: 23px;
      font-size: 20px;
      font-family: Helvetica Neue, Helvetica Neue-Regular;
      font-weight: 400;
      color: #262626;
      line-height: 24px;
    }
  }
  .numberView {
    position: absolute;
    top: 0px;
    left: 0px;
    .numberImg {
      width: 20px;
      height: 20px;
    }
  }
  .leftHalf {
    height: calc((($mainHeight) / 2));
  }
  .lineViewHorizon {
    height: 12px;
  }
  .secondTableView {
    height: calc(50% - 6px);
    position: relative;
    padding: 12px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    .classNameView {
      display: flex;
      align-items: center;
      height: 30px;
      font-size: 14px;
      color: #595959;
      background-color: #fff;
      padding-left: 12px;
    }
  }
  .lineView {
    width: 12px;
  }
}
.percentView {
  position: relative;
  z-index: 999;
  background-color: #f0f0f0;
  .percentBg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: -1;
    background-color: #fbabaf;
  }
  .percentText {
    height: 100%;
    width: 100%;
    z-index: 999;
    text-align: right;
  }
}
</style>
