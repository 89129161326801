<template>
  <div class="contentCont">
    <div class="contentInner">
      <div class="itemCont" v-for="(item, index) in newRecord" :key="index" :item="item">
        <div @click="add(item)">
          <div class="status_ico status_success" v-if="item.clickNone"><i class="el-icon-check" /></div>
          <!-- {{ item.clickNone }} -->
          <div class="status_ico status_error" v-if="item.union" @click="remove(item)"><i class="el-icon-close" /></div>
          <div class="itemImg">
            <img :src="item.image || avater" alt="" style="width: 180px; height: 180px; padding: 15px 0px">
          </div>
          <el-tooltip class="item" effect="dark" :content="item.goodsSkuTitle" placement="top">
            <div class="itemBox name text-ellipsis">
              {{ item.goodsSkuTitle || '-' }}
            </div>
          </el-tooltip>
          <div class="itemBox factory">
            {{ item.factory || '-' }}
          </div>
          <div class="itemBox factory">
            有效期至： {{ item.expireTime ? item.expireTime.slice(0,11) : '-' }}
          </div>
          <!-- <div class="itemBox factory">
            {{ item.approvalNumber || '-' }}
          </div> -->
          <div class="itemBox factory" style="padding-bottom:10px">
            {{ item.specification || '-' }}
          </div>
          <div class="itemBox factory" style="padding-bottom:10px">
            {{ item.salePrice || '-' }}
          </div>

        </div>

      </div>
    </div>
  </div>
</template>
<script>
import avater from "/public/img/default.png"
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    records: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      clickNone: false,
      avater: avater 
    }
  },
  computed:{
    newRecord:{
      get(){
        return this.records
      },
      set(val){
        return this.$emit('updateRecords', val)
      }
    }
  },
  methods: {
    remove(item) {
      console.log(item)
      // this.$store.dispatch('coupon/removeGoods', item)
      this.$emit('removeSelectedItem', item)
    },
    add(item) {
      let newRecord = JSON.parse(JSON.stringify(this.newRecord))
      for (let i = 0; i < newRecord.length; i++) {
        if (newRecord[i].id == item.id) {
          newRecord[i].clickNone = !newRecord[i].clickNone;
        } else {
          newRecord[i].clickNone = false;
        }
      }
      item.clickNone = !item.clickNone;
      console.log(this.newRecord)
      this.$set(this, 'newRecord', newRecord) 
      this.$emit('addGunalian', item)
    }
  }

}
</script>
<style lang="scss" scoped>
.itemCont {
  position: relative;
  width: 13.6%;
  min-width: 225px;
  // height: 300px;
  border: 1px solid #dcdfe6;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;

  .status_ico {
    position: absolute;
    right: 0px;
    height: 30px;
    width: 30px;
    background: green;
    border-radius: 30px;
    cursor: pointer;

    .el-icon-check,
    .el-icon-close {
      font-weight: 700;
      color: white;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .status_error {
    background: red;
  }

  .name {
    display: block !important;
    font-size: 16px;
    font-weight: 600;
  }

  .factory {
    color: #595959;
    font-size: 14px;
  }

  .itemBox {
    padding: 3px 10px;
    display: flex;
    justify-content: flex-start;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }

  .itemImg {
    height: 180px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .price {
    font-weight: 700;
    justify-content: end;
  }

  .shopCar {
    margin-top: 20px;
    justify-content: center;
  }
}

.contentCont {
  height: 532px;
  overflow: auto;
  padding: 0 0px;

  .contentInner {
    // height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
.text-ellipsis{
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
</style>
