<template>
  <div>
    <el-form ref="numberValidateForm"
             :model="numberValidateForm"
             label-width="100px"
             class="ruleFormCont"
             label-position="top">
      <el-form-item label="企业类型"
                    class="formItemOne"
                    :rules="[{ required: true, message: `请选择类型`, trigger: `change` }]"
                    prop="selectNum">
        <div class="selectCont">
          <div v-for="(item, index) in shopList"
               :key="index"
               :class="{
              itemCont: true,
              mainActive: numberValidateForm.selectNum === index,
              mainBorder: numberValidateForm.selectNum != index,
            }"
               @click="selectBtn(item, index)">
            <el-image :src="getStatus(shopTypeName, item.typeName)"
                      style="display: flex; margin-right: 10px" />
            {{ item.typeName }}
          </div>
        </div>
      </el-form-item>
      <div v-if="numberValidateForm.selectNum === 0">
        <el-form-item label="医疗机构执业许可证编码"
                      :rules="[
            {
              required: true,
              message: `请输入医疗机构执业许可证编码`,
              trigger: `blur`,
            },
          ]"
                      prop="licenceNo">
          <el-input v-model="numberValidateForm.licenceNo"
                    placeholder="请输入医疗机构执业许可证编码"
                    style="width: 400px"
                    :disabled="iseditor" />
        </el-form-item>
      </div>
      <div>
        <el-form-item label="营业执照编码"
                      :rules="[
            { required: numberValidateForm.selectNum != 0, message: `请输入营业执照编码`, trigger: `blur` },
          ]"
                      prop="idNumber">
          <el-input v-model="numberValidateForm.idNumber"
                    placeholder="请输入营业执照编码"
                    style="width: 400px"
                    :disabled="iseditor" />
        </el-form-item>
      </div>
      <el-form-item label="采购/收货人姓名"
                    :rules="
          numberValidateForm.selectNum == 0 || numberValidateForm.selectNum == 1
            ? [
                {
                  required: true,
                  message: `请输入医疗机构执业许可证编码`,
                  trigger: `blur`,
                },
              ]
            : [
                {
                  required: false,
                  message: `请输入采购/收货人姓名`,
                  trigger: `blur`,
                },
              ]
        "
                    prop="concatName">
        <el-input v-model="numberValidateForm.concatName"
                  placeholder="请输入采购/收货人姓名"
                  style="width: 400px"
                  :disabled="iseditor" />
      </el-form-item>
      <el-form-item label="采购/收货人联系电话"
                    :rules="
          numberValidateForm.selectNum == 0 || numberValidateForm.selectNum == 1
            ? [
                {
                  required: true,
                  message: `请输入采购/收货人联系电话`,
                  trigger: `blur`,
                },
              ]
            : [
                {
                  required: false,
                  message: `请输入采购/收货人联系电话`,
                  trigger: `blur`,
                },
              ]
        "
                    prop="concatPhone">
        <el-input v-model="numberValidateForm.concatPhone"
                  maxlength="15"
                  placeholder="请输入采购/收货人联系电话"
                  style="width: 400px"
                  :disabled="iseditor" />
      </el-form-item>
      <el-form-item v-if="numberValidateForm.selectNum == 0 "
                    label="采购/收货人身份证号"
                    :rules="[
          {
            required: true,
            message: `请输入采购/收货人身份证号`,
            trigger: `blur`,
          },
        ]"
                    prop="concatIdCard">
        <el-input v-model="numberValidateForm.concatIdCard"
                  maxlength="18"
                  placeholder="请输入采购/收货人身份证号"
                  style="width: 400px"
                  :disabled="iseditor" />
      </el-form-item>
      <el-form-item label="售后联系电话"
                    :rules="[
          {
            required: true,
            message: `请输入售后服务手机号码`,
            trigger: `blur`,
          },
        ]"
                    prop="servicePhone">
        <el-input v-model="numberValidateForm.servicePhone"
                  maxlength="15"
                  placeholder="请输入售后联系电话"
                  style="width: 400px"
                  :disabled="iseditor" />
      </el-form-item>
      <!-- <el-form-item label="经营范围"
                    :rules="[{ required: true, message: `请选择经营范围`, trigger: `change` }]"
                    prop="scopeIds">
        <el-checkbox-group v-model="numberValidateForm.scopeIds">
          <el-checkbox v-for="(item, index) in areaList"
                       :key="index + 'r'"
                       :label="item.id"
                       :name="item.id"
                       :disabled="iseditor">{{ item.scopeName || "" }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item> -->
      <el-form-item v-if="!iseditor">
        <el-button type="primary"
                   size="small"
                   :loading="formload"
                   @click="submitForm('numberValidateForm')">信息认证</el-button>
      </el-form-item>
    </el-form>
    <span v-if="!iseditor"
          class="tip down">
      提示：为保证结果准确性，请保证企业类型、证照编码与实际相符
    </span>
  </div>
</template>
<script>
// import top from '../index/top/index.vue'
// import { mapGetters } from 'vuex'
import { checkZizhiImages } from "@/utils/status";
import {
  getShoparea,
  getUserarea,
  postShopapply,
  postShopapplyHistory,
  getShopapplyHistory
} from "@/api/merchantsettlement/index";
// import { regionProvince, regionCity, regionArea } from '@/api/index'
import { MessageBox, Message } from "element-ui";
export default {
  components: {},
  data () {
    return {
      shopTypeName: "诊所",
      // 按钮等待
      formload: false,
      // // 表单对象的赋值
      numberValidateForm: {
        selectNum: '',
        typeCode: "",
        licenceNo: "", // 医疗机构执业许可证编码
        idNumber: "", // 营业执照编码
        concatName: "", // 负责人姓名
        concatPhone: "", // 负责人电话
        concatIdCard: "",//负责人身份证件号
        servicePhone: "", // 服务电话
        scopeIds: [], // 经营范围
      },
      // 地区的列表
      areaList: [],
      // 不同身份的列表
      shopList: [],
      rules: {
        typeCode: [
          { required: true, message: `请选择类型`, trigger: `change` },
        ],
        licenceNo: [
          {
            required: true,
            message: `请输入医疗机构执业许可证编码`,
            trigger: `blur`,
          },
        ],
        idNumber: [
          { required: true, message: `请输入营业执照编码`, trigger: `blur` },
        ],

        concatName: [
          { required: true, message: `请输入采购/收货人姓名`, trigger: `blur` },
        ],
        concatPhone: [
          {
            required: true,
            message: `请输入采购/收货人联系电话`,
            trigger: `blur`,
          },
        ],
        concatIdCard: [
          {
            required: true,
            message: `请输入采购/收货人身份证号`,
            trigger: `blur`,
          },
        ],
        servicePhone: [
          {
            required: true,
            message: `请输入售后服务手机号码`,
            trigger: `blur`,
          },
        ],
        scopeIds: [
          { required: true, message: `请选择经营范围`, trigger: `change` },
        ],
      },
      cbTypes:[{typeCode:1,typeName:'省级九和供应'},{typeCode:2,typeName:'地级九和供应'},{typeCode:3,typeName:'推广服务商'}],
      radioType:0,
      bannerImage:'https://eshop-develop.oss-cn-hangzhou.aliyuncs.com/shop/966213d0bbf947b4bf861efed9b02311.png'
    };
  },
  props: {
    // selectNum: {
    //   type: Number,
    //   default: 0,
    // },
    // numberValidateForm: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       scopeIds: [],
    //     };
    //   },
    // },
    iseditor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getStatus () {
      return (name, qiyeName) => checkZizhiImages(name, qiyeName);
    },
  },
  watch: {
    // numberValidateForm: {
    //   handler(newVal) {
    //     console.log(newVal);
    //     this.watchData()
    //   },
    //   deep: true,
    // },
  },
  mounted () {
    this.getShoparea();
    this.getUserarea();

  },
  methods: {
    watchData () {
      // console.log('1233')
    },
    getShoparea () {
      getShoparea(0).then((res) => {
        if (res.code === 0) {
          // this.shopList = res.data;
          this.shopList = [
            {typeCode: "1",typeName:"基层诊所"},
            {typeCode: "2",typeName:"药店"},
            {typeCode: "3",typeName:"民营医疗"},
            {typeCode: "4",typeName:"医药公司"},
            {typeCode: "5",typeName:"非药经营-采购方"},
            {typeCode: "6",typeName:"非药经营-供应商"},
            {typeCode: "7",typeName:"生产企业"},
            {typeCode: "8",typeName:"春播组织"},
          ]
          // this.numberValidateForm.typeCode = res.data[0].typeCode;
          this.getShopapplyHistoryAction()
        }
      });
    },
    getUserarea () {
      getUserarea().then((res) => {
        if (res.code === 0) {
          this.areaList = res.data;
        }
      });
    },
    // 获取缓存的数据
    getShopapplyHistoryAction () {
      let typeCode = this.numberValidateForm.typeCode
      getShopapplyHistory(typeCode, 1).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.numberValidateForm.licenceNo = res.data.licenceNo || ''; // 医疗机构执业许可证编码
            this.numberValidateForm.idNumber = res.data.idNumber || ''; // 营业执照编码
            this.numberValidateForm.concatName = res.data.concatName || ''; // 负责人姓名
            this.numberValidateForm.concatPhone = res.data.concatPhone || ''; // 负责人电话
            this.numberValidateForm.concatIdCard = res.data.concatIdCard || ''; // 负责人身份证号
            this.numberValidateForm.servicePhone = res.data.servicePhone || ''; // 服务电话 
          }
        }
      })
    },

    selectBtn (item, index) {
      this.$refs.numberValidateForm.resetFields();
      this.shopTypeName = item.typeName;
      this.numberValidateForm.selectNum = index;
      this.numberValidateForm.typeCode = item.typeCode;
      this.getShopapplyHistoryAction();
    },
    submitForm (formName) {
      this.formload = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const {
            selectNum,
            typeCode,
            licenceNo, // 医疗机构执业许可证编码
            idNumber, // 营业执照编码
            concatName, // 负责人姓名
            concatPhone, // 负责人电话
            concatIdCard,//负责人身份证号
            servicePhone, // 售后服务电话
            scopeIds, // 经营范围
          } = this.numberValidateForm;
          let d = {
            typeCode,
            concatName,
            servicePhone,
            concatPhone,
            concatIdCard,
            scopeIds: scopeIds.join(","),
          };
          if (selectNum == 0) {
            d.licenceNo = licenceNo;
          }
          d.idNumber = idNumber;
          // if (selectNum == 1 || selectNum == 2 || selectNum == 3) {
          // }
          // this.numberValidateForm.scopeIds =
          //   this.numberValidateForm.scopeIds.toString();
          // console.log(d)
          // this.formload = false;
          postShopapplyHistory(d, 1).then(() => { })
          postShopapply(d)
            .then((res) => {
              const { code, data } = res;
              if (code === 0 && data && data.result) {
                this.$message.success(`提交成功！`);
                this.$emit("setStep", data.requestId, typeCode);

              } else {
                this.$message.error(res.msg);
              }
              this.formload = false;
            })
            .catch((res) => {
              this.formload = false;
            });
        } else {
          this.formload = false;
          return false;
        }
      });
    },
    submitConfirm(formName){
      let that = this
      MessageBox.confirm(
          `<h4>请确认您选择的企业类型为XXX</br>XXX企业类型的说明</h4>`,
          '企业类型确认',
          {
            confirmButtonText: '确认',
            cancelButtonText: '返回',
            dangerouslyUseHTMLString: true,
            // type: 'warning',
          }
        )
          .then((e) => {
            that.submitForm(formName)
          })
          .catch(() => {
           
          })
      }
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.down {
  margin-top: 24px;
  margin-bottom: 14px;
  width: 38vw;
  margin: auto;
}
.tip {
  display: block;
  background: #fff9e6;
  border: 1px solid #ffdb88;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #595959;
  line-height: 22px;
  padding: 10px 20px;
}

.ruleFormCont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
  .formItemOne {
    width: 63vw;
    position: relative;
    margin: auto;
  }
  // width: 600px;
  // padding-bottom: 30px;
  .selectCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .itemCont {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      color: #595959;
      text-align: center;
      line-height: 70px;
      position: relative;
      cursor: pointer;
      width: 15vw;
      height: 76px;
      background: linear-gradient(90deg, #e4e4e4, #f8f8f8);
      border-radius: 8px;
      margin-top: 8px;
    }
    .mainActive {
      color: #f5222d;
      width: 15vw;
      height: 76px;
      background: linear-gradient(90deg, #ffd4d5, #ffecec);
      border-radius: 8px;
      /* 三角形 */
      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        right: 0px;
        border-bottom: 25px solid #{$borderColorActive};
        border-left: 25px solid transparent;
        border-bottom-right-radius: 8px;
      }

      /* 三角形勾 */
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 6px;
        background: transparent;
        bottom: 6px;
        right: 3px;
        border: 2px solid white;
        border-top: none;
        border-right: none;
        transform: rotate(-55deg);
        z-index: 9;
      }
    }
    
  }
}

.b_back_view{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 63vw;
    margin: auto;
    margin-top: 40px;
  }
  .b_back_image{
    width: 63vw;
    height: 150px;
  }
  .b_back_text{
    color: #595959;
    font-size: 14px;
    margin-top: 16px;
    font-weight: 400;
  }
  .notification_view{
    width: 38vw;
    background: #e4fffb;
    border: 1px solid #74cfcb;
    border-radius: 4px;
    padding:8px 16px;
    margin-bottom: 16px;
  }
  .notification_text{
    color: #595959;
    font-size: 14px;
    font-weight: 600;
  }
  .radio_view{
    width: 38vw;
    padding:8px 0px;
    margin-bottom: 16px;
  }
</style>
