<template>
  <div
    :class="
      item.marketPrice != null && Number(item.marketPrice) > 0
        ? 'teshu'
        : 'itemCont'
    "
  >
    <div class="boxTop">
      <el-tooltip
        placement="right"
        effect="light"
        v-if="item.marketPrice != null && Number(item.marketPrice) > 0"
      >
        <div slot="content">
          <img
            src="../../assets//dalibaohuodong.png"
            alt=""
            style="width: 200px; height: 242px"
          />
        </div>
        <img
          v-if="item.marketPrice != null && Number(item.marketPrice) > 0"
          class="dalibao"
          src="../../assets/dalibao.png"
          alt=""
        />
      </el-tooltip>
      <div class="itemImg">
        <el-image
          :src="item.image || avater"
          alt=""
          lazy
          :preview-src-list="item.image ? [item.image] : []"
          style="width: 188px; height: 188px; padding: 10px"
        />
      </div>
      <div class="rightBox">
        <div class="itemBox name" style="padding-bottom: 20px">
          {{ item.name }}
        </div>
        <div class="itemBox factory">
          {{ item.specification }}
        </div>
        <div class="itemBox factory">
          有效期至：{{
            item.expireTime ? item.expireTime.substring(0, 10) : ""
          }}
        </div>
        <div class="itemBox factory">
          {{ item.approvalNumber }}
        </div>
        <div class="itemBox factory">
          {{ item.factory }}
        </div>
        <div
          class="itemBox"
          style="width: 100%; justify-content: space-between; padding-top: 10px"
        >
          <div
            style="width: 100%; display: flex; justify-content: space-between"
          >
            <div
              class="price"
              style="
                color: red;
                font-size: 20px;
                justify-content: start;
                color: #f6212d;
              "
            >
              <span style="font-size: 14px"> ￥ </span>
              {{ item.salePrice }}
            </div>
            <div
              class="price"
              style="
                justify-content: start;
                font-size: 12px;
                color: #595959;
                line-height: 26px;
              "
            >
              <div
                v-if="item.marketPrice != null && Number(item.marketPrice) > 0"
                style="font-weight: 400"
              >
                <span style="text-decoration: line-through; color: #8c8c8c"
                  >￥{{ item.marketPrice }}</span
                >
                <span style="font-size: 16px; color: #595959; margin-left: 3px"
                  >{{
                    (
                      (Number(item.salePrice) / Number(item.marketPrice)) *
                      10
                    ).toFixed(1)
                  }}折</span
                >
              </div>
              <span v-else> 数量：{{ item.goodsSkuInventory }} </span>
            </div>
          </div>
          <div style="justify-content: end" />
        </div>
      </div>
    </div>
    <div class="bottomBox">
      <div>
        <img
          src="../../assets/shop.png"
          alt=""
          style="width: 20px; heihgt: 20px; position: relative; top: 5px"
        />
        <span class="company" @click="handleDetail(item)">
          {{ item.shopName }}
        </span>
        <img
          @click="handleChat(item)"
          src="@/assets/call.png"
          alt=""
          style="
            width: 20px;
            heihgt: 20px;
            position: relative;
            top: 5px;
            cursor: pointer;
          "
        />
      </div>
      <div>
        <el-tooltip
          placement="right"
          effect="light"
          v-if="item.marketPrice != null && Number(item.marketPrice) > 0"
        >
          <div slot="content">
            <img
              src="../../assets//dalibaohuodong.png"
              alt=""
              style="width: 200px; height: 242px"
            />
          </div>
          <el-button
            type=""
            :class="
              item.marketPrice != null && Number(item.marketPrice) > 0
                ? 'teshuBtn'
                : ''
            "
            size="small"
            icon="el-icon-shopping-cart-1"
            class="gouwuche"
            @click="handleAddbuy(item)"
          >
            购物车</el-button
          >
        </el-tooltip>
        <el-button
          v-else
          type=""
          :class="
            item.marketPrice != null && Number(item.marketPrice) > 0
              ? 'teshuBtn'
              : ''
          "
          size="small"
          icon="el-icon-shopping-cart-1"
          class="gouwuche"
          @click="handleAddbuy(item)"
        >
          购物车</el-button
        >
      </div>
    </div>
    <!-- <el-dialog
      :title="item.shopName + '客服'"
      :visible.sync="dialogFormVisible"
    >
      <div style="display: flex; justify-content: space-between">
        <iframe
          :src="urlLink"
          style="width: 1200px; height: 610px;background-color: #f6212d;"
          frameborder="0"
        ></iframe> -->
        <!-- <div class="right">
          <p class="itemTitle">我的订单</p>
          <div v-if="orderList.length">
            <div class="itemInner"
                 v-for="(item,index) in orderList"
                 :key="index">
              <el-image :src="item.image||avater"></el-image>
              <div class="itemDesc">
                <p class="title"
                   style="">{{ item.orderCode }}</p>
                <p>{{ item.specification }}</p>
                <p class="price"> <span style="font-size:12px">
                    ￥
                  </span>
                  {{ item.amount?item.amount:'' }}</p>
                <p>待收货</p>
              </div>
            </div>
          </div>
          <el-empty v-else
                    style="height:100%;width:100%"
                    description="暂无数据" />
        </div> -->
      <!-- </div>
    </el-dialog> -->
  </div>
</template>
<script>
import avater from "/public/img/default.png";
import { createChat, sendChat, purchaseWait } from "@/api/chat";
import { doChatServices } from "@/api/chatRoom/chatServices"
import { getToken } from "@/utils/auth";
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      avater: avater,
      dialogFormVisible: false,
      sessionObj: {},
      urlLink: "",
      orderList: [],
    };
  },
  methods: {
    handleAddbuy(item) {
      // 加入购物车
      this.$parent.handleAddbuy(item);
    },
    receiveMessage(event){
      alert('got it')
      if (event.data.sign == 1) {  //from Site B
				if (event.data.msg === 'getMsg' && event.data.sign == 1) {
					//alert("post message complate, close the timer.");
					clearInterval(this.timeOfmsg);
				}
			}
    },
    handleChat(item) {
      // 客服聊天
      const data = {shopId :item.shopId ,token :getToken(),personType:'2'}//必传店铺 id / 用户 token/ 用户类型 （1：商家 2：客户）
      doChatServices(data)

      // createChat({ receiverId: item.shopId }).then((res) => {
      //   if (res.code == 0) {
      //     this.sessionObj = res.data;

      //     let params = {
      //       toAccount: this.sessionObj.fromAccount,
      //       fromAccount: this.sessionObj.toAccount,
      //       ope: 0,
      //       type: 0,
      //       body: `您好，我是${item.shopName}的客服！`,
      //     };
      //     sendChat(params).then((red) => {
      //       // this.urlLink = `http://localhost:5173/?appKey=${this.sessionObj.appKey}&fromAccount=${this.sessionObj.fromAccount}&fromToken=${this.sessionObj.fromToken}`;
      //       // this.dialogFormVisible = true;
            
      //     });
      //     purchaseWait({ payeeShopId: item.shopId }).then((res) => {
      //       console.log(res);
      //       if (res.code == 0) {
      //         this.orderList = res.data?.orderList ?? [];
      //       }
      //     });
      //   }
      // });
    },
    handleDetail(item) {
      let val = getToken();
      console.log(val);
      if (val) {
        window.open(
          `https://shop.cbyyk.com/login?accessToken=${val}&shopId=${item.shopId}&url=/`
        );
      }
      // location.href = `http://localhost:8081?accessToken=${'Bearer ' + getToken()}&shopId=${item.shopId}&url='/'`
    },
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 200px;
  height: 610px;
  padding: 8px;
  border-top: 1px solid #dbe0e8;
  border-right: 1px solid #dbe0e8;
  border-bottom: 1px solid #dbe0e8;
  .itemTitle {
    margin: 0px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
  }
  .itemInner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    p {
      margin: 0px;
    }
    .el-image {
      width: 80px;
      height: 80px;
    }
    .itemDesc {
      width: calc(100% - 88px);
      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;

        font-size: 12px;
        line-height: 20px;
      }
      .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }
      .price {
        color: #f6212d;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
// .goodsCards {
//   width: 100%;
//   height: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   padding-top: 24px;
//   align-content: flex-start;
.itemCont,
.teshu {
  position: relative;
  // width: 16.1%;
  // min-width: 214px;
  height: 229px;
  border: 1px solid #dcdfe6;
  margin: 0px 10px 10px 0px;
  border-radius: 4px;
  .el-dialog__body{
    padding: 0px;
  }
  .boxTop {
    display: flex;
    .dalibao {
      position: absolute;
      width: 98px;
      z-index: 2;
      height: 40px;
      right: 0px;
    }
    .itemBox {
      width: 207px;
      padding: 3px 10px 3px 0px;
      // display: flex;
      // justify-content: flex-start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #262626;
    }
  }
  .bottomBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    .company {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #8c8c8c;
      position: relative;
      cursor: pointer;
    }
  }
  .name {
    display: block !important;
    font-size: 16px;
    font-weight: 600;
  }
  .factory {
    color: #8c8c8c !important;
    font-size: 14px;
  }

  .itemImg {
    height: 180px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .rightBox {
    padding-top: 15px;
  }
  .price {
    font-weight: 700;
    justify-content: end;
    font-size: 12px;
    color: #595959;
  }
  .shopCar {
    justify-content: center;
  }
  .gouwuche {
    color: #f5222d;
    background: #ffffff;
    border: 1px solid #ff4d50;
    &:hover {
      color: #fff;
      background: #f5222d;
      border: 1px solid #f5222d;
    }
  }
}
.teshu {
  background: #fff2f1;
  border: 1px solid #ffa39e;
  .teshuBtn {
    color: #f6212d;
    background: #ffffff;
    border: 1px solid #ff4d50;
    &:hover {
      color: #ffffff;
      background: #f6212d;
      border: 1px solid #ff4d50;
    }
  }
  
}
// }
</style>
