<template>
  <div class="info_box">

    <div>
      <el-form ref="info"
               :model="info"
               label-width="100px"
               class="ruleFormCont"
               label-position="top"
               :disabled="info.disabled">
        <el-form-item label="企业类型"
                      class="formItemOne"
                      prop="selectNum">
          <div class="selectCont">
            <div v-for="(item, index) in shopList"
                 v-show="info.shopTypeName === item.typeName"
                 :key="index"
                 :class="{
                itemCont: true,
                disabled: info.disabled,
                mainActive: info.shopTypeName === item.typeName,
                mainBorder: info.shopTypeName != item.typeName,
              }"
                 @click="selectBtn(item, index)">
              <el-image :src="getStatus(info.shopTypeName, item.typeName)"
                        style="display: flex; margin-right: 10px" />
              {{ item.typeName }}
            </div>
          </div>
        </el-form-item>
        <div v-if="info.certifys.length > 0">
          <el-form-item label="医疗机构执业许可证编码"
                        v-if="info.shopTypeCode == '1'"
                        prop="licenceNo">
            <el-input v-model="licenceNo"
                      placeholder="请输入医疗机构执业许可证编码"
                      style="width: 400px"
                      :disabled="isDisabled" />
          </el-form-item>
        </div>
        <div v-if="info.certifys.length > 0">
          <el-form-item v-if="
              info.shopTypeCode == '2' ||
              info.shopTypeCode == '3' ||
              info.shopTypeCode == '4'
            "
                        label="营业执照编码"
                        prop="idNumber">
            <el-input v-model="info.certifys[0].idNumber"
                      placeholder="请输入营业执照编码"
                      style="width: 400px"
                      :disabled="isDisabled" />
          </el-form-item>
        </div>
        <el-form-item label="采购/收货人姓名"
                      prop="concatName">
          <el-input v-model="info.concatName"
                    placeholder="请输入采购/收货人姓名"
                    style="width: 400px"
                    :disabled="isDisabled" />
        </el-form-item>
        <el-form-item label="采购/收货人联系电话"
                      prop="concatPhone">
          <el-input v-model="info.concatPhone"
                    placeholder="请输入采购/收货人联系电话"
                    style="width: 400px"
                    :disabled="isDisabled" />
        </el-form-item>
        <el-form-item label="售后联系电话"
                      prop="servicePhone">
          <el-input v-model="info.servicePhone"
                    placeholder="请输入售后联系电话"
                    style="width: 400px"
                    :disabled="isDisabled" />
        </el-form-item>
        <el-form-item label="经营范围"
                      prop="scopeIds"
                      v-if="this.info.status == 2">
          <el-checkbox-group v-model="info.scopes"
                             disabled>
            <el-checkbox v-for="(item, index) in info.scopes"
                         :key="index + 'r'"
                         :label="item"
                         :name="item"
                         :disabled="!isDisabled">{{ item || "" }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="企业名称"
                      prop="name">
          <el-input v-model="info.shopName"
                    placeholder="请输入企业名称"
                    style="width: 400px"
                    :disabled="isDisabled" />
        </el-form-item>
        <el-form-item label="所在区域"
                      prop="province">
          <el-select v-model="info.provinceName"
                     placeholder="请选择省"
                     :disabled="isDisabled"
                     style="width: 126px"
                     @change="selectedHandle">
            <el-option v-for="(item, index) in provinceData"
                       :key="index"
                       :label="item.rname"
                       :value="item.id" />
          </el-select>
          <el-select v-model="info.cityName"
                     placeholder="请选择市"
                     :disabled="isDisabled"
                     style="margin-left: 12px; width: 126px"
                     @change="selectedHandleCity">
            <el-option v-for="(item, index) in cityData"
                       :key="index"
                       :label="item.rname"
                       :value="item.id" />
          </el-select>
          <el-select v-model="info.areaName"
                     placeholder="请选择区"
                     :disabled="isDisabled"
                     style="margin-left: 11px; width: 126px"
                     @change="selectedHandleArea">
            <el-option v-for="(item, index) in areaData"
                       :key="index"
                       :label="item.rname"
                       :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址"
                      prop="address">
          <el-input v-model="info.address"
                    :disabled="isDisabled"
                    placeholder="请输入详细地址"
                    style="width: 400px" />
        </el-form-item>
        <el-form-item label="发票类型"
                      prop="invoiceType">
          <el-checkbox-group v-model="info.invoiceTypes"
                             :disabled="isDisabled">
            <el-checkbox v-for="(item, index) in invoicetypeData"
                         :key="index"
                         :label="item.name"
                         :name="item.name">{{ item.name || "" }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div class="ruleForm">
        <template v-for="(ul, index) in certifys">
          <div :key="index"
               label-width="800px"
               style="margin-bottom: 30px">
            <!-- :prop="infoFormTwo[ul.keyTime]" -->
            <!-- slot="label" -->
            <div style="width: 600px; margin-bottom: 10px">
              <div style="
                  display: flex;
                  justify-content: space-between;
                  font-size: 14px;
                  color: #262626;
                ">
                <div>
                  <span v-if="ul.code != '09' && ul.require"
                        style="color: red; margin-right: 10px">*</span>{{
                    ul.code == "08" 
                      ? "开票信息"
                      : allTypes[ul.code]
                  }}
                </div>
                <div>
                  <span style="margin-right: 10px; font-weight: 400; color: #999"
                        class="mainColor">有效期</span>
                  <el-date-picker v-model="ul.date"
                                  type="daterange"
                                  size="mini"
                                  format="yyyy-MM-dd"
                                  value-format="yyyy-MM-dd"
                                  range-separator="-"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期"
                                  disabled />
                </div>
              </div>
            </div>
            <div class="uploadCont">
              <div :class="['imgList', { disabled: info.disabled }]">
                <div v-for="(li, i) in ul.images"
                     :key="i"
                     class="imgList_li">
                  <el-image class="imgList_li_img"
                            style=""
                            :src="li"
                            :preview-src-list="ul.images"
                            :disabled="isDisabled" />
                  <p class="imgList_li_close"
                     @click="imgDel(index, i)"
                     v-if="pageType == 'qualificationDetails'">
                    x
                  </p>
                </div>
                <div class="imgList_li imgList_li_add"
                     @click="goUpload(ul)"
                     v-if="!isDisabled">
                  <i class="el-icon-plus" />
                </div>
              </div>

              <div v-if="ul.desc"
                   class="desc">{{ ul.desc }}</div>
              <div v-if="ul.code == '09'"
                   class="more_input">
                <p class="more_p"
                   style="margin-bottom: 5px">备注</p>
                <el-input v-if="ul.code == '09'"
                          v-model="info.remarks"
                          type="txtarea"
                          :disabled="isDisabled" />
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="submit-btn"
           v-if="info.status != 2 && pageType == 'qualificationDetails'">
        <el-button type="primary"
                   size="small"
                   @click="goSubmit">提交</el-button>
      </div>
    </div>

    <MyUpload ref="imgupload"
              :request-id="info.requestId"
              :imgType="imgType"
              @upload="uploadSubmit" />
  </div>
</template>
<script>
import {
  checkZizhiImages
} from "@/utils/status";
import formUpload from "../../merchantsettlement/formUpload.vue";
import MyUpload from "../../merchantsettlement/myupload.vue";

// import top from '../index/top/index.vue'
// import { mapGetters } from 'vuex'
import {
  getShoparea,
  getUserarea,
  getinvoicetypeList,
  updateCertify,
} from "@/api/merchantsettlement/index";
import {
  getProvince,
  getCity,
  getArea
} from "@/api/shopmange/index";
import {
  shopTypeCodeArr_1,
  shopTypeCodeArr_2,
  shopTypeCodeArr_3,
  shopTypeCodeArr_4,
  shopTypeCodeFilter
} from "@/utils/companyQualifications.js";
import {
  regionAreaTree
} from "@/api/saleRule/saleF";
// import { regionProvince, regionCity, regionArea } from '@/api/index'
export default {
  components: {
    formUpload,
    MyUpload
  },
  data () {
    return {
      imgType: "",
      licenceNo: "",
      provinceData: [],
      cityData: [],
      areaData: [],
      invoicetypeData: [], // 发票类型
      // 地区的列表
      areaList: [],
      // 不同身份的列表
      shopList: [],
      allTypes: {
        "01": "营业执照",
        "02": "药品经营许可证",
        "03": "食品经营许可证",
        "04": "医疗机构许可证",
        "05": "被委托人身份证复印件(正反两面)",
        "06": "授权委托书",
        "07": "第二类医疗器械经营备案凭证",
        "08": "开票信息和开户许可证",
        "09": "其他",
        "10": "质保协议",
      },
      shopTypeCodeArr_1: shopTypeCodeArr_1,
      shopTypeCodeArr_2: shopTypeCodeArr_2,
      shopTypeCodeArr_3: shopTypeCodeArr_3,
      shopTypeCodeArr_4: shopTypeCodeArr_4,
      info: {
        scopeIds: [],
        invoiceTypes: [],
        certifys: [],
      },
      isDisabled: false,
      infoFormTwo: [],
    };
  },
  props: {
    // info: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       scopeIds: [],
    //       invoiceTypes: [],
    //       certifys: [],
    //     };
    //   },
    // },
    pageType: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  computed: {
    getStatus () {
      return (name, qiyeName) => checkZizhiImages(name, qiyeName);
    },
    certifys () {
      let certifys = this.info.certifys;
      let shopTypeCodeArr = [];
      let shopTypeCode = this.info.shopTypeCode + '';

      switch (shopTypeCode) {
        case '1':
          shopTypeCodeArr = this.shopTypeCodeArr_1;
          break;
        case '2':
          shopTypeCodeArr = this.shopTypeCodeArr_2;
          break;
        case '3':
          shopTypeCodeArr = this.shopTypeCodeArr_3;
          break;
        case '4':
          shopTypeCodeArr = this.shopTypeCodeArr_4;
          break;
      }
      certifys.forEach((element) => {
        this.$set(element, "date", [
          element.startValidDate || "",
          element.endValidDate || "",
        ]);
      });
      let newData = shopTypeCodeFilter(shopTypeCodeArr, certifys, this.licenceNo, this.shopId);
      return newData.map((li) => {
        return {
          ...li,
          images: li.images.map((el) => el.certityImage),
          certityType: li.images.map((el) => el.certityType),
        };
      });
    },
  },
  mounted () {
    this.getShoparea();
    this.getUserarea();
    this.getRegionAreaTree();
    this.getinvoicetypeList();
    console.log(this.pageType, "pageType");
  },
  methods: {
    selectBtn (item, index) {
      if (this.pageType != 'qualificationDetails') return;
      this.info.shopTypeName = item.typeName;
      this.info.shopTypeCode = item.typeCode;
    },

    goEnd (val) {
      this.endStatus = val || false;
    },
    getInfo (info) {
      this.info = info;
      this.shopId = info.id;
      if (this.info.licenceNo) {
        this.licenceNo = this.info.licenceNo;
      }
      if (this.info.status == 2) {
        this.isDisabled = true;
      }
      this.isDisabled = true;
    },
    // 获取省市区列表
    getRegionAreaTree (leve) {
      let data = {
        level: 3,
      };
      regionAreaTree(data).then((res) => {
        if (res.code === 0) {
          this.provinceData = res.data;
          if (this.info.province) {
            this.getCityBtn(this.info.province);
          }
          if (this.info.city) {
            this.getAreaBtn(this.info.city);
          }
        }
      });
    },
    selectedHandle (val) {
      console.log(val, "val");
      this.info.cityName = "";
      this.info.areaName = "";
      this.info.province = val + "";
      this.provinceData.forEach((item) => {
        if (item.id == val) {
          this.info.provinceName = item.rname;
        }
      });
      this.getCityBtn(val);
    },

    getCityBtn (val) {
      getCity({
        id: val
      }).then((res) => {
        if (res.code == 0) {
          this.cityData = res.data;
        } else {
          this.$message({
            type: "warning",
            message: res.msg
          });
        }
      });
    },
    //城市的选择按钮
    selectedHandleCity (val) {
      console.log(val);
      this.info.city = val + "";
      this.cityData.forEach((item) => {
        if (item.id == val) {
          this.info.cityName = item.rname;
        }
      });
      this.info.areaName = "";
      this.getAreaBtn(val);
    },
    getAreaBtn (val) {
      getArea({
        id: val
      }).then((res) => {
        if (res.code == 0) {
          this.areaData = res.data;
        } else {
          this.$message({
            type: "warning",
            message: res.msg
          });
        }
      });
    },
    //地区的选择按钮
    selectedHandleArea (val) {
      this.info.area = val + "";
      this.areaData.forEach((item) => {
        if (item.id == val) {
          this.info.areaName = item.rname;
        }
      });
    },
    // 发票类型列表
    getinvoicetypeList () {
      getinvoicetypeList().then((res) => {
        if (res.code === 0) {
          this.invoicetypeData = res.data;
          console.log(this.invoicetypeData);
          // ;
        }
      });
    },
    getUserarea () {
      getUserarea().then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.areaList = res.data;
        }
      });
    },
    // 商户经营范围
    getShoparea () {
      getShoparea(0).then((res) => {
        if (res.code === 0) {
          this.shopList = res.data || [];
          // this.numberValidateForm.typeCode = res.data[0].typeCode;
        }
      });
    },

    imgDel (index, i) {
      this.$confirm("此操作删除上传的图片, 是否确认操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        console.log(index, i);
        let dd = {
          ...this.info.certifys
        };
        let arrs = dd[index].images;
        arrs.splice(i, 1);
      });
    },

    goUpload (type) {
      console.log(type);
      this.imgType = type;
      this.$refs["imgupload"].open(type);
    },

    // 提交修改
    uploadSubmit (obj) {
      const {
        type,
        url
      } = obj;
      // console.log(type);
      console.log(url);
      console.log("obj", obj);
      let certifys1 = this.info.certifys;
      certifys1.forEach((element) => {
        console.log("element", element);

        if (element.code == type.code) {
          element.images.push({
            certityImage: url,
            id: null,
            certityType: element.code,
          });
        }
      });
      // this.infoFormTwo[type].push(url);
    },
    // 提交修改
    goSubmit (obj) {
      let certifyList = [];
      this.info.certifys.forEach((element) => {
        // console.log(element);
        let imagesArr = [];
        for (let i = 0; i < element.images.length; i++) {
          imagesArr.push(element.images[i].certityImage);
        }
        let obj = {
          code: element.code,
          // startValidDate: element.date[0],
          // endValidDate: element.date[1],
          images: imagesArr,
        };
        certifyList.push(obj);
      });
      let d = {
        scopeIds: this.info.scopeIds ? this.info.scopeIds.join(",") : "",
        certifys: certifyList,
        ...obj,
        // relationType: "01", // 00.商户 01.省总 02.地总 03，诊所
        // deptId: 415, // 组织机构ID
        // orgCode: "ty94qfEn", // 组织机构CODE
        // roleId: 2, // 角色待定
        // status: "2", // 1.未通过，2.通过
      };

      this.updateCertify(d);
    },

    updateCertify (obj) {
      // 处理发票类型数据 invoicetypeData
      this.info.invoiceType = "";
      this.info.invoiceTypes.map((item) => {
        for (let i = 0; i < this.invoicetypeData.length; i++) {
          if (item == this.invoicetypeData[i].name) {
            this.info.invoiceType += this.invoicetypeData[i].code + ",";
          }
        }
      });

      this.info.invoiceType = this.info.invoiceType.substring(
        0,
        this.info.invoiceType.lastIndexOf(",")
      );

      const {
        requestId,
        remarks,
        concatName,
        concatPhone,
        province,
        provinceName,
        city,
        cityName,
        area,
        areaName,
        address,
        invoiceType,
        servicePhone,
        shopName,
        shopTypeCode,
        id,
        invoiceTypes,
      } = this.info;
      const {
        licenceNo,
        shopId
      } = this;
      let d = {
        ...obj,
        requestId,
        remarks,
        licenceNo,
        concatName,
        concatPhone,
        province,
        provinceName,
        city,
        cityName,
        area,
        areaName,
        address,
        invoiceType,
        servicePhone,
        shopName,
        shopTypeCode,
        id,
        invoiceTypes,
        idNumber: this.info.certifys[0].idNumber || "",
      };
      console.log(d, "d");

      updateCertify(d).then((res) => {
        if (res.code === 0) {
          this.$message.success("操作成功");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

::v-deep .el-form--label-top .el-form-item__label {
  padding: 8px !important;
}

.info_box {
  width: 600px;
  display: flex;
  margin: 0 auto;
  justify-content: center;

  .formItemOne {
    // width: 800px;
    // position: relative;
    // left: -16%;
  }
}

.down {
  margin-top: 60px;
  margin-bottom: 60px;
}

.tip {
  background: #fff9e6;
  border: 1px solid #ffdb88;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #595959;
  line-height: 22px;
  padding: 10px 20px;
}

.ruleFormCont {
  width: 600px;

  // padding-top: 30px;
  .selectCont {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .itemCont {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22%;
      height: 70px;
      // border: 2px solid #{$borderColor};
      border-radius: 4px;
      font-size: #{$fontSize};
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      color: #{$fontColor};
      text-align: center;
      line-height: 70px;
      position: relative;
      cursor: pointer;
      width: 195px;
      height: 76px;
      background: linear-gradient(90deg, #e4e4e4, #f8f8f8);
      border-radius: 8px;
    }

    .disabled {
      pointer-events: none;
    }

    .mainActive {
      // border: 2px solid #{$borderColorActive};
      color: #{$fontColorActive};
      width: 195px;
      height: 76px;
      background: linear-gradient(90deg, #ffd4d5, #ffecec);
      border-radius: 8px;

      /* 三角形 */
      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        right: 0px;
        border-bottom: 25px solid #{$borderColorActive};
        border-left: 25px solid transparent;
        border-bottom-right-radius: 8px;
      }

      /* 三角形勾 */
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 6px;
        background: transparent;
        bottom: 6px;
        right: 3px;
        border: 2px solid white;
        border-top: none;
        border-right: none;
        transform: rotate(-55deg);
        z-index: 9;
      }
    }
  }

  .uploadCont {
    width: 600px;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    overflow-x: scroll;
  }
}

.allheight {
  height: 100vh !important;
}

.status {
  &_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;

    h3 {
      font-size: 30px;
      font-weight: 400;
      color: #333;
      line-height: 2;
    }
  }

  &_ico {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    color: #fff;
    font-size: 30px;
    line-height: 1;
    border-radius: 100%;
  }

  &_success {
    background: #32bf78;
  }

  &_err {
    background: #f56c6c;
  }
}

.contentCont {
  .contentInner {
    padding: 20px;
    background: #fff;
    height: calc(100vh - 140px);
    overflow-y: scroll;

    // height: calc(100vh - 48px);
    .tip {
      background: #fff9e6;
      border: 1px solid #ffdb88;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #595959;
      line-height: 22px;
      padding: 10px 20px;
    }

    .staus_plan {
      overflow: hidden;
      padding: 32px 32px 54px 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .step {
        position: relative;
        width: auto;
        height: 32px;

        .status {
          margin-right: 8px;

          .idx {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            border: 1px solid #e5e7ee;
            font-size: 14px;
            text-align: center;
            color: #91939a;
            line-height: 32px;
            margin: 0 auto;
          }
        }

        .icon_svg {
          width: 32px;
          height: 32px;
        }

        .name {
          color: #91939a;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          margin-top: 10px;
        }

        .time {
          position: absolute;
          left: 40px;
          width: 150px;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #91939a;
          line-height: 22px;
        }
      }

      .line {
        margin: 0 16px;
        width: 98px;
        height: 3px;
        background: #e5e7ee;
        border-radius: 1px;
      }

      .step.active {
        .status .idx {
          border: 1px solid #f5222d;
          color: #fff;
          background: #f5222d;
        }

        .name {
          color: #f5222d;
        }

        .name.sueccss {
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
        }
      }

      .line.active {
        background: #f5222d;
      }
    }

    .formCont {
      width: 800px;
      display: flex;
      margin: 0 auto;
      justify-content: center;
    }
  }
}

.imgList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &_li {
    display: block;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
    margin-right: 10px;
    position: relative;
    overflow: hidden;

    &:hover {
      .imgList_li_close {
        color: #666;
        background: #ffffff55;
      }
    }

    &_img {
      width: 100px;
      height: 100px;
    }

    &_close {
      position: absolute;
      z-index: 13;
      right: 2px;
      top: 2px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin: 0;
      display: flex;
      // align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #999;
      background: #ffffff87;
      border-radius: 50%;
      text-align: center;
      line-height: 16px;
    }

    &_add {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      cursor: pointer;
      background: #f5f5f5;
      border-radius: 6px;
      font-size: 36px;
      line-height: 1;
      color: #bbb;

      &:hover {
        border: 1px solid #3db4b9;
      }
    }
  }
}

.disabled {
  pointer-events: none;
}

.down {
  margin-top: 60px;
  margin-bottom: 60px;
}

.tip {
  background: #fff9e6;
  border: 1px solid #ffdb88;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #595959;
  line-height: 22px;
  padding: 10px 20px;
}

.ruleForm {
  width: 600px;
  padding-bottom: 30px;

  .selectCont {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .itemCont {
      width: 22%;
      height: 70px;
      border: 2px solid #{$borderColor};
      border-radius: 4px;
      font-size: #{$fontSize};
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      color: #{$fontColor};
      text-align: center;
      line-height: 70px;
      position: relative;
      cursor: pointer;
    }

    .mainActive {
      border: 2px solid #{$borderColorActive};
      color: #{$fontColorActive};

      /* 三角形 */
      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        right: 0px;
        border-bottom: 25px solid #{$borderColorActive};
        border-left: 25px solid transparent;
        border-bottom-right-radius: 8px;
      }

      /* 三角形勾 */
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 6px;
        background: transparent;
        bottom: 6px;
        right: 3px;
        border: 2px solid white;
        border-top: none;
        border-right: none;
        transform: rotate(-55deg);
        z-index: 9;
      }
    }
  }

  .uploadCont {
    width: 600px;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    // overflow-x: scroll;
  }
}

.submit-btn {
  width: 100%;
  text-align: center;
  margin: 20px auto;
}
</style>