var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "show-manage-warp new-cls" },
    [
      _c(
        "el-form",
        { ref: "shopForm", attrs: { rules: _vm.rules, model: _vm.formIn } },
        [
          _c(
            "div",
            { staticClass: "form" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "shopName",
                    label: "店铺名称",
                    "label-width": "140px",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: {
                      size: "small",
                      placeholder: "请输入店铺名称",
                      maxlength: "7",
                    },
                    model: {
                      value: _vm.formIn.shopName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "shopName", $$v)
                      },
                      expression: "formIn.shopName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    prop: "shopLogo",
                    label: "店铺LOGO",
                    "label-width": "140px",
                  },
                },
                [
                  _c("div", { staticClass: "public-pic-layout" }, [
                    _vm.formIn.shopLogo
                      ? _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c("el-image", {
                              ref: `myImgLogo`,
                              staticClass: "icon",
                              attrs: {
                                src: _vm.formIn.shopLogo,
                                fit: "cover",
                                "initial-index": 0,
                                "preview-src-list": [_vm.formIn.shopLogo],
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "preview" },
                              [
                                _c("el-image", {
                                  staticClass: "btn-icon",
                                  attrs: { src: _vm.SeeIcon },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePreview(
                                        `myImgLogo`,
                                        false
                                      )
                                    },
                                  },
                                }),
                                _c("el-image", {
                                  staticClass: "btn-icon",
                                  attrs: { src: _vm.DelIcon },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDeleteImage("logo")
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "item add-pic",
                            on: {
                              click: function ($event) {
                                return _vm.handleUpload("logo")
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "image-wrap" },
                              [_c("el-image", { attrs: { src: _vm.AddIcon } })],
                              1
                            ),
                            _c("span", { staticClass: "text" }, [
                              _vm._v("上传图片"),
                            ]),
                          ]
                        ),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    prop: "shopServiceTel",
                    label: "客服电话",
                    "label-width": "140px",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: {
                      size: "small",
                      placeholder: "请输入客服电话",
                      maxlength: "15",
                    },
                    model: {
                      value: _vm.formIn.shopServiceTel,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "shopServiceTel", $$v)
                      },
                      expression: "formIn.shopServiceTel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    prop: "shopHeadOperations",
                    label: "运营负责人",
                    "label-width": "140px",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: {
                      size: "small",
                      placeholder: "请输入运营负责人",
                      maxlength: "15",
                    },
                    model: {
                      value: _vm.formIn.shopHeadOperations,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "shopHeadOperations", $$v)
                      },
                      expression: "formIn.shopHeadOperations",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    prop: "shopContactNumber",
                    label: "运营联系电话",
                    "label-width": "140px",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: {
                      size: "small",
                      placeholder: "请输入运营联系电话",
                      maxlength: "15",
                    },
                    model: {
                      value: _vm.formIn.shopContactNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "shopContactNumber", $$v)
                      },
                      expression: "formIn.shopContactNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    label: "销售政策与条款",
                    "label-width": "140px",
                    prop: "salesPolicy",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "440px" },
                    attrs: {
                      type: "textarea",
                      maxlength: "100",
                      "show-word-limit": "",
                      resize: "none",
                      rows: 4,
                      placeholder: "请填写100字以内销售政策与条款",
                    },
                    model: {
                      value: _vm.formIn.salesPolicy,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "salesPolicy", $$v)
                      },
                      expression: "formIn.salesPolicy",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    label: "配送政策",
                    "label-width": "140px",
                    prop: "sendRule",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "440px" },
                    attrs: {
                      type: "textarea",
                      maxlength: "100",
                      "show-word-limit": "",
                      resize: "none",
                      rows: 4,
                      placeholder: "请填写100字以内配送政策",
                    },
                    model: {
                      value: _vm.formIn.sendRule,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "sendRule", $$v)
                      },
                      expression: "formIn.sendRule",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    label: "企业介绍",
                    "label-width": "140px",
                    prop: "shopDesc",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "440px" },
                    attrs: {
                      type: "textarea",
                      maxlength: "200",
                      "show-word-limit": "",
                      resize: "none",
                      rows: 6,
                      placeholder: "请填写200字以内企业介绍",
                    },
                    model: {
                      value: _vm.formIn.shopDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "shopDesc", $$v)
                      },
                      expression: "formIn.shopDesc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    label: "店铺公告",
                    "label-width": "140px",
                    prop: "shopAnnouncement",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "440px" },
                    attrs: {
                      type: "textarea",
                      maxlength: "200",
                      "show-word-limit": "",
                      resize: "none",
                      rows: 6,
                      placeholder: "请填写200字以内店铺公告",
                    },
                    model: {
                      value: _vm.formIn.shopAnnouncement,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "shopAnnouncement", $$v)
                      },
                      expression: "formIn.shopAnnouncement",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("UploadImageView", {
        ref: "imgupload",
        attrs: { "request-id": "1", imgType: _vm.imgType },
        on: { upload: _vm.uploadSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }