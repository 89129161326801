<!-- err -->
<template>
  <el-dialog title="图片上传" :visible.sync="dialogShow" width="408px">
    <div class="dia_content">
      <el-upload
        ref="uploadDom"
        drag
        action=""
        :accept="accept"
        :limit="1"
        :show-file-list="false"
        :http-request="uploadSubmit"
      >
        <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div slot="tip" class="el-upload__tip">
          只能上传{{acceptInfo}}文件，且不超过10M
        </div>
      </el-upload>

      <div class="big-tip" v-show="uploading">
        <span class="text">{{ bigTip }}</span>
      </div>
    </div>
    <!-- <span slot="footer" class="dia_footer">
      <el-button @click="dialogShow = false">取 消</el-button>
      <el-button type="primary" @click="goSubmit">确定上传</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
import { imgUpload } from "@/api/user";

export default {
  props: {
    requestId: {
      type: String,
      default: "",
    },
    imgType: {
      type: String,
      default: "",
    },
    accept:{
      type:String,
      default:'.png,.jpg,.jpeg'
    },
    acceptInfo:{
      type:String,
      default:'jpg/png'
    }
  },
  data () {
    return {
      imgs: [],
      dialogShow: false,
      type: null,
      uploading: false, //是否正在上传
      bigTip: "开始上传...",
    };
  },
  methods: {
    open (type) {
      console.log(type);
      this.type = type;
      this.dialogShow = true;
    },
    close () {
      this.dialogShow = false;
    },
    uploadSubmit (el) {
      let t = this;
      const { type, requestId } = this;

      this.uploading = true;
      this.bigTip = "开始上传...";

      const { file } = el;
      let formData = new FormData();
      formData.append("file", el.file);
      formData.append("type", this.imgType);
      formData.append("requestId", requestId);
      // let d = { file: el.file, type: '01', requestId: this.requestId }
      imgUpload(formData, this.onUploadProgress)
        .then((res) => {
          this.uploading = false;
          // console.log(res)
          const { code, data } = res;
          if (code == 0) {
            this.$emit("upload", { type, url: data.certityImage });
            setTimeout(() => {
              t.$message.success("图片上传成功");
              t.$refs["uploadDom"].clearFiles();
              t.close();
            }, 100);
          } else {
            t.$message.error(res.msg);
          }
        })
        .catch((ex) => {
          this.uploading = false;
        });
    },
    onUploadProgress (event) {
      // console.log("===progress event:", event);
      let progress = 0;
      if (event && event.progress) {
        progress = event.progress * 100;
      }
      this.bigTip = `正在上传，进度 ${progress.toFixed(2)}%`;
    },
    fileChange (el, els) {
      console.log(el, els);
      this.imgs = els;
    },
    goSubmit () {
      this.$emit("sure", this.formTool.desc);
      setTimeout(() => {
        this.close();
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.dia {
  &_content {
    position: relative;
    display: inline-block;

    &>* {
      display: inline-block;
    }

    .big-tip {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.6);
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        font-size: 14px;
        color: #fff;
      }
    }
  }

  &_footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
