
<!--  -->
<template>
  <div class="comContent">
    <div class="header">
      <el-form ref="searchTool" :model="searchTool" :inline="true" style="text-align: left;display: flex">
        <el-form-item prop="name" style="margin:0px !important">
          <el-input v-model="searchTool.name" placeholder="搜索活动名称/商品名称" size="small" />
        </el-form-item>
        <el-form-item prop="status" style="margin-left:16px !important">
          <el-select v-model="searchTool.status" clearable size="small" placeholder="请选择">
            <el-option v-for="item in optionsStatus" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyButton type="primary" text="检索" @click="getActivityList()" />
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="reset()">重置</el-button>
        </el-form-item>
        <el-form-item>
          <MyButton type="primary" text="新建活动" @click="initActivity()" />
        </el-form-item>
      </el-form>
    </div>
    <div class="center">
      <div class="left-part">
        <MyTable :table-data="tableData" :index="index" :showpage="false" :selection="false" :page="page"
          :operation="false" @rowClick="rowClick" :table-option.sync="tableOption">
          <template slot="startDate" slot-scope="scope">
            <span>
              {{ scope.row.startDate + '-' + scope.row.endDate }}
            </span>
            <!-- <el-link :underline="false"
                     type="primary"
                     @click="editDetail(scope.row)">详情</el-link>
            <el-link :underline="false"
                     v-if="scope.row.status === 1"
                     style="margin-left: 10px"
                     type="primary"
                     @click="submit(scope.row)">提交</el-link> -->
          </template>
          <template slot="status" slot-scope="scope">
            <span>
              {{ checkStatus(scope.row.status) }}
            </span>
          </template>
          <template slot="inventoryNumber" slot-scope="scope">
            <span>
              {{ (scope.row.inventoryNumber) + '/' + (scope.row.claimsNumber) }}
            </span>
          </template>
        </MyTable>
      </div>

      <div class="rt-content right-part">
        <div class="right-header" v-if="mode === 'edit'">
          <el-button v-if="clickRow.status == '0' || clickRow == 'add'" size="small" type="primary"
            @click="save('0')">保存</el-button>
          <el-button v-if="clickRow.status == '0' || clickRow == 'add'" size="small" type="primary"
            @click="save('1')">提交</el-button>
          <el-button v-if="clickRow.status == '2'" size="small" type="primary" @click="cancellation()">作废</el-button>
          <el-button v-if="clickRow.status == '2'" size="small" type="primary" @click="stop()">停止</el-button>
          <el-button
            v-if="clickRow.status == '0' || clickRow.status == '1' || (clickRow.status && clickRow.claimsNumber == 0)"
            size="small" type="primary" @click="deleteActivity()">删除</el-button>
        </div>
        <!-- <el-divider v-if="mode === 'edit'"/> -->
        <el-form :disabled="formTool.status != '0'" v-if="clickRow" ref="couponForm" label-width="100px" class="product"
          :model="formTool" :rules="rules" size="small">
          <div class="base-info">
            <div class="left_topic mb20">基本信息</div>
            <div style="width: 100%;display: flex;justify-content: space-between;">
              <div style="">
                <el-row :gutter="10">
                  <el-col :span="24">
                    <el-form-item label="活动名称" prop="name">
                      <el-input v-model.trim="formTool.name" :disabled="mode === 'view'" placeholder="请输入活动名称" maxlength="10"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="24">
                    <el-form-item label="活动时间" prop="date">
                      <el-date-picker :disabled="mode === 'view'" v-model="formTool.date" value-format="yyyy-MM-dd"
                        type="daterange" size="small" range-separator="-" start-placeholder="开始日期"
                        end-placeholder="结束日期" />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="24">
                    <el-form-item label="活动类型" prop="type">
                      <el-select size="small" v-model="formTool.type" :disabled="mode === 'view'" clearable
                        placeholder="活动类型">
                        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="24">
                    <el-form-item label="活动预算" prop="limitFlag">
                      <el-radio-group v-model="formTool.limitFlag" @input="changeHuodong">
                        <el-radio :label="0">不限</el-radio>
                        <el-radio :label="1">限制</el-radio>
                      </el-radio-group>
                      <el-input 
                        v-model.trim="formTool.limitMoney" 
                        min="0" 
                        type="number"
                        style="width: 140px;margin-left:16px"
                        v-if="formTool.limitFlag == 1" size="small" placeholder="请输入上限"
                        @input="(e)=>limitMoneyReg(e)"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div style="width: 49%;display: flex;justify-content: space-around;align-items: center;"
                v-if="clickRow.inventoryNumber">
                <div style="display: flex;flex-direction: column;color: black;font-size: 26px;font-weight: 600;">
                  <span> 总量 </span>
                  <span> {{ formTool.inventoryNumber }} </span>
                </div>
                <div style="display: flex;flex-direction: column;color: black;font-size: 26px;font-weight: 600;">
                  <span> 已领 </span>
                  <span> {{ formTool.claimsNumber }} </span>
                </div>
                <div style="display: flex;flex-direction: column;color: black;font-size: 26px;font-weight: 600;">
                  <span> 已用 </span>
                  <span> {{ formTool.useNumber }} </span>
                </div>
              </div>
            </div>

          </div>
          <el-divider />
          <div class="base-info">
            <el-button v-if="clickRow.status == '0' || clickRow == 'add'" size="mini" style="z-index: 1;" class="anniu"
              type="primary" @click="addCouponList()">新建</el-button>
            <div class="left_topic mb20">优惠券设置

            </div>
            <div class="custom-table">
              <div v-for="item, index in formTool.couponList" :key="index"
                style="width: 520px;padding: 10px;background-color: #eee;margin: 0 10px 10px 0;">

                <el-form-item label="优惠券名称" :prop="'couponList[' + index + '].name'"
                  :rules="[{ required: true, message: '优惠券名称不能为空', trigger: 'blur' }]">
                  <div style="display: flex;justify-content: space-between;">
                    <el-input v-model.trim="item.name" size="small" style="width: 160px;" placeholder="优惠券名称" maxlength="10"></el-input>
                    <el-button size="mini" v-if="formTool.couponList.length > 1" class="anniu" type="primary"
                      @click="deleteCouponList(index)">删除</el-button>
                  </div>
                </el-form-item>
                <el-form-item label="有效时间" :prop="'couponList[' + index + '].date'"
                  :rules="[{ required: true, message: '有效时间不能为空', trigger: 'change' }]">
                  <el-date-picker v-model="item.date" size="small" type="daterange" value-format="yyyy-MM-dd"
                    range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="券类型" :prop="'couponList[' + index + '].couponType'"
                  :rules="[{ required: true, message: '券类型不能为空', trigger: 'change' }]">
                  <el-radio-group v-model="item.couponType">
                    <el-radio :label="0">满减券</el-radio>
                    <el-radio :label="1">满折券</el-radio>
                    <el-radio :label="2">满数量减券</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="券总量" :prop="'couponList[' + index + '].inventoryNumber'"
                  :rules="[{ required: true, message: '券总量不能为空', trigger: 'blur' }]">
                  <el-input 
                    min="0" 
                    v-model.trim="item.inventoryNumber" 
                    type="number" 
                    size="small" 
                    style="width: 160px;"
                    placeholder="券总量" 
                    @input="(e)=>inventoryNumberReg(e,index)"
                  >
                  </el-input>
                  <span v-if="formTool.status != 0">
                    已领{{ item.claimsNumber }}/已用{{ item.useNumber }}
                  </span>
                </el-form-item>
                <div>适用对象</div>
                <el-form-item label="客户类型" :prop="'couponList[' + index + '].shopTypeCode'"
                  :rules="[{ required: true, message: '客户类型不能为空', trigger: 'change' }]">
                  <el-checkbox-group v-model="item.shopTypeCode">
                    <el-checkbox v-for="item, index in shopTypeCodeList" :key="index" :label="item.value" name="type">{{
                      item.label }}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="新老客户" :prop="'couponList[' + index + '].limitUserType'"
                  :rules="[{ required: true, message: '客户类型不能为空', trigger: 'change' }]">
                  <el-checkbox-group v-model="item.limitUserType">
                    <el-checkbox label="0" name="type">新客户</el-checkbox>
                    <el-checkbox label="1" name="type">老客户</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="每人限领" :prop="'couponList[' + index + '].limitUserNumberFlag'"
                  :rules="validatorEveryBody(item)">
                  <el-radio-group v-model="item.limitUserNumberFlag">
                    <el-radio :label="0">不限</el-radio>
                    <el-radio :label="1">限制</el-radio>
                    <el-input min="0" v-model.number="item.limitUserNumber" onkeyup="value=value.replace(/[^\d]/g,'')"
                      type="number" v-if="item.limitUserNumberFlag == 1" size="small" style="width: 80px;"
                      placeholder="请输入金额"></el-input>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="领取方式" :prop="'couponList[' + index + '].claimsType'" :rules="validatorMethods(item)">
                  <el-radio-group v-model="item.claimsType">
                    <el-radio :label="0">消费前发</el-radio>
                    <el-radio :label="1">消费后发</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item v-if="item.couponType == 0" label="优惠方式" :prop="'couponList[' + index + '].reductionType'">
                  <el-radio-group v-model="item.reductionType">
                    <el-radio :label="0">循环满减</el-radio>
                    <el-radio :label="1">阶梯满减</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item v-if="item.reductionType == 0 && item.couponType == 0" label=""
                  :prop="'couponList[' + index + '].reductionType'" :rules="validatorMethod(item)">
                  <div>
                    每满
                    <el-input 
                      min="0" 
                      v-model.trim="item.itemList[0].threshold" 
                      type="number" size="small"
                      style="width: 100px;" 
                      placeholder="金额"
                      @input="(e)=>thresholdReg('threshold', e,index,0)"
                    >
                    </el-input>
                    元减
                    <el-input 
                      min="0" 
                      v-model.trim="item.itemList[0].discount" 
                      type="number" 
                      size="small"
                      style="width: 100px;" placeholder="金额"
                      @input="(e)=>thresholdReg('discount', e,index,0)"
                    >
                    </el-input>
                    元
                  </div>
                </el-form-item>

                <el-form-item v-if="item.reductionType == 1 && item.couponType == 0" label=""
                  :prop="'couponList[' + index + '].reductionType'" :rules="validatorMethod(item)">
                  <div v-for="el, i in item.itemList" :key="i">
                    一次买满
                    <el-input 
                      min="0" 
                      v-model.trim="el.threshold" 
                      type="number" 
                      size="small" 
                      style="width: 100px;"
                      placeholder="金额"
                      @input="(e)=>thresholdReg('threshold', e,index,i)"
                    >
                    </el-input>
                    元减
                    <el-input 
                      min="0" 
                      v-model.trim="el.discount" 
                      type="number" 
                      size="small" 
                      style="width: 100px;"
                      placeholder="金额"
                      @input="(e)=>thresholdReg('discount', e,index,i)"
                    >
                    </el-input>
                    元
                    <el-link type="primary" v-if="item.itemList.length > 1" :disabled="formTool.status != '0'"
                      style="margin-left: 12px;" @click="deleteRow(index, i)" :underline="false"> 删除</el-link>
                    <el-link type="primary" v-if="i == item.itemList.length - 1" :disabled="formTool.status != '0'"
                      style="margin-left: 12px;" :underline="false" @click="addRow(index, i)"> 增加下一阶梯</el-link>
                  </div>
                </el-form-item>

                <!-- 券类型为满折 -->
                <el-form-item v-if="item.couponType == 1" label="优惠方式" :prop="'couponList[' + index + '].reductionType'">
                  <div v-for="el, i in item.itemList" :key="i">
                    满
                    <el-input 
                      min="0" 
                      v-model.trim="el.threshold" 
                      type="number" 
                      size="small" 
                      style="width: 100px;"
                      placeholder="金额"
                      @input="(e)=>thresholdReg('threshold', e,index,i)"
                    >
                    </el-input>
                    元享
                    <el-input 
                      min="0" 
                      v-model.trim="el.discount" 
                      type="number" 
                      size="small" 
                      style="width: 100px;"
                      placeholder="折"
                      @input="(e)=>thresholdReg('discount', e,index,i)"
                    >
                    </el-input>
                    折
                    <el-link type="primary" v-if="item.itemList.length > 1" :disabled="formTool.status != '0'"
                      style="margin-left: 12px;" @click="deleteRow(index, i)" :underline="false"> 删除</el-link>
                    <el-link type="primary" v-if="i == item.itemList.length - 1" :disabled="formTool.status != '0'"
                      style="margin-left: 12px;" :underline="false" @click="addRow(index, i)"> 增加下一阶梯</el-link>
                  </div>
                </el-form-item>

                <!-- 券类型为满数量减 -->
                <el-form-item v-if="item.couponType == 2" label="优惠方式" :prop="'couponList[' + index + '].reductionType'">
                  <el-radio-group v-model="item.reductionType">
                    <el-radio :label="0">循环满数量减</el-radio>
                    <el-radio :label="1">阶梯满数量减</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item v-if="item.reductionType == 0 && item.couponType == 2" label=""
                  :prop="'couponList[' + index + '].reductionType'" :rules="validatorMethod(item)">
                  <div>
                    每满
                    <el-input 
                      min="0" 
                      v-model.trim="item.itemList[0].threshold" 
                      type="number"
                       size="small"
                      style="width: 100px;" 
                      placeholder="数量"
                      @input="(e)=>thresholdReg('threshold', e,index,0)"
                    >
                    </el-input>
                    销售单位减
                    <el-input 
                      min="0" 
                      v-model.trim="item.itemList[0].discount" 
                      type="number" 
                      size="small"
                      style="width: 100px;" 
                      placeholder="金额"
                      @input="(e)=>thresholdReg('discount', e,index,0)"
                    >
                    </el-input>
                    元
                  </div>
                </el-form-item>

                <el-form-item v-if="item.reductionType == 1 && item.couponType == 2" label=""
                  :prop="'couponList[' + index + '].reductionType'" :rules="validatorMethod(item)">
                  <div v-for="el, i in item.itemList" :key="i">
                    一次买满
                    <el-input 
                      min="0" 
                      v-model.trim="el.threshold" 
                      type="number" 
                      size="small" 
                      style="width: 100px;"
                      placeholder="数量"
                      @input="(e)=>thresholdReg('threshold', e,index,i)"
                    >
                    </el-input>
                    销售单位减
                    <el-input 
                      min="0" 
                      v-model.trim="el.discount" 
                      type="number" 
                      size="small" 
                      style="width: 100px;"
                      placeholder="金额"
                      @input="(e)=>thresholdReg('discount', e,index,i)"
                    >
                    </el-input>
                    元
                    <el-link type="primary" v-if="item.itemList.length > 1" :disabled="formTool.status != '0'"
                      style="margin-left: 12px;" @click="deleteRow(index, i)" :underline="false"> 删除</el-link>
                    <el-link type="primary" v-if="i == item.itemList.length - 1" :disabled="formTool.status != '0'"
                      style="margin-left: 12px;" :underline="false" @click="addRow(index, i)"> 增加下一阶梯</el-link>
                  </div>
                </el-form-item>

                <el-form-item label="单张最多减" :prop="'couponList[' + index + '].limitReductionMoneyFlag'"
                  :rules="validatorjine(item)">
                  <el-radio-group v-model="item.limitReductionMoneyFlag">
                    <el-radio :label="0" :disabled="zuiduojianDisabled">不限</el-radio>
                    <el-radio :label="1">限制</el-radio>
                    <el-input 
                      v-if="item.limitReductionMoneyFlag == 1" 
                      size="small" 
                      style="width: 120px;"
                      v-model.trim="item.limitReductionMoney" 
                      type="number"
                      placeholder="请输入金额"
                      maxlength="5"
                      @input="(e)=>limitReductionMoneyReg(e,index)"
                    >
                    </el-input>
                  </el-radio-group>
                </el-form-item>

                <el-form-item label="适用商品" :prop="'couponList[' + index + '].limitGoods'"
                  :rules="validatorShangpin(item)">
                  <el-radio-group v-model="item.limitGoods">
                    <el-radio :label="0">全部商品</el-radio>
                    <el-radio :label="1">指定商品</el-radio>

                  </el-radio-group>
                  <el-button style="margin-left: 12px;" type="primary" size="small" :disabled="false"
                    v-if="item.skuList.length > 0" :underline="false" @click="chooseGood(item)"> 已选</el-button>
                  <!-- <div style="margin-left:10px;cursor: pointer;" @click="chooseGood(item)">
                    {{ item.skuList.length > 0 ? `已选${item.skuList.length}` : '' }}
                  </div> -->
                  <el-button type="primary" size="small" :disabled="false" v-if="item.limitGoods == 1"
                    style="margin-left: 12px;" :underline="false" @click="chooseGood(item)"> 选择</el-button>
                </el-form-item>

              </div>
            </div>
          </div>
        </el-form>
      </div>

      <el-dialog :visible.sync="dialogVisible" title="指定商品" top="10vh" width="1000px" :before-close="beforeClose">
        <TicketAssociativeClassification v-if="dialogVisible" @choice="choice" :status="formTool.status"
          :rowData="rowData">
        </TicketAssociativeClassification>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import MyButton from "@/components/myButton";
import MyTable from "@/components/myTable";
import TicketAssociativeClassification from "./dialog/ticketAssociativeClassification";
import { getCompanyTypes } from "@/api/myQualification/index";
import {
  getCouponActivitys,
  addCouponActivity,
  getCouponActivityDetail,
  submitCouponActivity,
  activityInfo,
  addActivityInfo,
  editActivityInfo,
  deleteActivityInfo,
  stopActivityInfo,
  disableActivityInfo
} from "@/api/coupon";
import { mapGetters } from "vuex";

import avater from "/public/img/default.png";
import { splitMoney } from "@/utils/validate";
export default {
  components: { MyButton, MyTable, TicketAssociativeClassification },
  data () {
    const validaMoney = (rule, value, callback) => {
      if (!value) {
        callback()
      } else if (value == '1' && (!this.formTool.limitMoney)) {
        callback(new Error('请输入金额'))
      } else {
        callback()
      }
    }
    return {
      optionsStatus: [
        {
          value: '0',
          label: '草稿'
        },
        {
          value: '1',
          label: '待开始'
        },
        {
          value: '2',
          label: '进行中'
        },
        {
          value: '3',
          label: '已结束'
        },
        {
          value: '4',
          label: '已停止'
        },
        {
          value: '5',
          label: '已作废'
        },
      ],
      shopTypeCodeList: [], // 客户类型列表
      clickRow: '', // 点击行的数据
      zuiduojianDisabled: false, // 单张最多减的禁用
      avater: avater,
      text: "",
      clearable: true,
      mode: "edit",
      searchTool: {
        name: "",
        status: ''
      },
      dialogVisible: false,
      formTool: {
        inventoryNumber: '',
        claimsNumber: '',
        useNumber: '',
        name: "",
        startDate: "",
        endDate: "",
        type: 1,
        limitFlag: 0,
        limitMoney: '', // 限制的金额
        couponList: [
          {
            useAreaType: '1',
            name: "", // SKU名称
            date: [], // 时间
            effectiveTime: '', // 有效时间开始
            expirationTime: '', // 有效时间结束
            couponType: 0,// 券类型
            inventoryNumber: '', // 券总量
            shopTypeCode: [], // 客户类型
            limitUserType: ['0', '1'], // 新老客户
            limitUserNumberFlag: 0, // 每人限领
            limitUserNumber: 0,
            claimsType: 0, // 领取方式
            reductionType: 0, // 优惠方式
            limitReductionMoneyFlag: 0,// 单张最多减
            limitReductionMoney: 0, // 单张最多减免金额
            itemList: [{
              discount: '',
              threshold: '',
            }
            ],
            limitGoods: 0, //适用商品
            skuList: [],
          }
        ],

      },
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        date: [{ required: true, message: "请选择活动时间", trigger: "blur" }],
        type: [{ required: true, message: "请选择活动类型", trigger: "blur" }],
        limitFlag: [{ required: true, validator: validaMoney, trigger: "change" }]
      },
      ticketOptions: [
        {
          name: "店铺券",
          id: "SHOP",
        },
        {
          name: "商品券",
          id: "PRODUCT",
        },
      ],
      options: [
        {
          name: "店铺活动",
          id: 1,
        },
      ],
      rowData: {},
      index: false,
      tableData: [],
      tableOption: [
        { label: "活动名称", prop: "name", minWidth: "120px" }, // 这里表示自定义列
        { label: "活动时间", prop: "startDate", minWidth: "100px", slot: true },
        { label: "状态", prop: "status", slot: true },
        { label: "总量/已领", prop: "inventoryNumber", slot: true }
      ],
      page: {
        total: 10,
        current: 1,
        size: 999,
      },
    };
  },
  computed: {
    ...mapGetters(["couponGoods"]),
  },
  watch: {},
  async created () {
    await this.getCompanyTypes()
    this.getActivityList();
  },
  mounted () { },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    thresholdReg (key,value,index,i) {
      // 限制金额不超过6位数，小数点后0位
      console.log('value',value);
      if (value) {
        let reg = /^\d{1,5}(\.\d{0,2})?$/
        if (!reg.test(value)) {
          this.formTool.couponList[index].itemList[i][key] = splitMoney(value,5,2)
        }
      }
    },
    limitMoneyReg (value) {
      // 活动预算限制金额不超过7位数，小数点后2位
      if (value) {
        let reg = /^\d{1,7}(\.\d{0,2})?$/
        if (!reg.test(value)) {
          this.formTool.limitMoney = splitMoney(value,7,2)
        }
      }
    },
    inventoryNumberReg (value,index) {
      // 限制券总量
      // 限制金额不超过6位数，小数点后0位
      console.log('value',value);
      
      if (value) {
        // let reg = /^\d{1,6}$/
        //正则 只能输入整数 6位数
        let reg = /^[1-9][0-9]{0,5}$/
        if (!reg.test(value)) {
          this.formTool.couponList[index].inventoryNumber = splitMoney(value,6,0)
        }
      }
    },
    limitReductionMoneyReg ( value,index ) {
      // 限制单张最多减免金额
      // 限制金额不超过5位数，小数点后两位
      if (value) {
        let reg = /^\d{1,5}(\.\d{0,2})?$/
        if (!reg.test(value)) {
          this.formTool.couponList[index].limitReductionMoney = splitMoney(value,5,2)
        }
      }
    },
    // 获取企业类型
    async getCompanyTypes () {
      const params = {
        type: 0,
      };
      getCompanyTypes(params).then(res => {
        if (res.code == 0 && res.data) {
          res.data.forEach(item => {
            let obj = {
              label: item.typeName,
              value: item.typeCode,
            }
            this.shopTypeCodeList.push(obj)
          })
        }
      })
    },
    // 点击左侧列表行
    rowClick (row) {
      activityInfo(this.clickRow = row.row.id).then(res => {
        if (res) {
          this.formTool = res
          this.zuiduojianDisabled = this.formTool.limitFlag == 1 ? true : false
          this.$set(this.formTool, 'date', [res.startDate, res.endDate])
          if (this.formTool.couponList) {
            this.formTool.couponList.forEach(el => {
              this.$set(el, 'date', [el.effectiveTime, el.expirationTime])
              this.$set(el, 'limitUserType', el.limitUserType.split(','))
              this.$set(el, 'shopTypeCode', el.shopTypeCode.split(','))
            })
          }
          this.clickRow = this.formTool
        }
      })
      // console.log(row.row)

    },
    // 校验活动状态
    checkStatus (status) {
      switch (status) {
        case 0:
          return '草稿'
          break;
        case 1:
          return '待开始'
          break;
        case 2:
          return '进行中'
          break;
        case 3:
          return '已结束'
          break;
        case 4:
          return '已停止'
          break;
        case 5:
          return '已作废'
          break;
        default:
          return ''
          break;
      }
    },
    // 领取方式
    validatorMethods (item) {
      return [
        {
          // validator: (rule, value, callback) => {
          //   if (!value) {
          //     callback()
          //   } else if (value == 1 && !item.methodsJine) {
          //     callback(new Error('领取方式不能为空'))
          //   } else {
          //     callback()
          //   }
          // },
          required: true,
          trigger: ['change']
        }
      ]
    },
    // 每人限领
    validatorEveryBody (item) {
      return [
        {
          validator: (rule, value, callback) => {
            if (!value) {
              callback()
            } else if (value == 1 && !item.limitUserNumber) {
              callback(new Error('每人限领不能为空'))
            } else {
              callback()
            }
          },
          required: true,
          trigger: ['change']
        }
      ]
    },
    // 适用商品
    validatorShangpin (item) {
      return [
        {
          validator: (rule, value, callback) => {
            if (!value) {
              callback()
            } else if (value == 1 && item.skuList.length == 0) {
              callback(new Error('商品数量不能为空'))
            } else {
              callback()
            }
          },
          required: true,
          trigger: ['change']
        }
      ]
    },
    // 优惠方式
    validatorMethod (item) {
      let _this = this
      return [
        {
          validator: (rule, value, callback) => {
            if (item.couponType == 1) {
              let tmp = 0
              item.itemList.forEach(el => {
                console.log(el);
                if (!Number(el.threshold) || !Number(el.discount)) {
                  tmp++
                }
              })
              if (tmp > 0) {
                callback(new Error('金额不能为空'))
              } else {
                callback()
              }
            } else {
              if (!value && (!item.itemList[0].threshold || !item.itemList[0].discount)) {
                callback(new Error('金额不能为空'))
              } else if (value == 1) {
                let tmp = 0
                item.itemList.forEach(el => {
                  console.log(el);
                  if (!Number(el.threshold) || !Number(el.discount)) {
                    tmp++
                  }
                })
                if (tmp > 0) {
                  callback(new Error('金额不能为空'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            }

          },
          required: true,
          trigger: ['change', 'blur']
        }
      ]
    },
    // 优惠金额
    validatorjine (item) {
      return [
        {
          validator: (rule, value, callback) => {
            if (!value) {
              callback()
            } else if (value == 1 && !item.limitReductionMoney) {
              callback(new Error('金额不能为空'))
            } else if (item.limitReductionMoney<0) {
              callback(new Error('金额不能为负数'))
            }else {
              callback()
            }
          },
          required: true,
          trigger: 'change'
        }
      ]
    },
    // 删除行
    deleteRow (index, i) {
      this.formTool.couponList[index].itemList.splice(i, 1)
    },
    // 增加行
    addRow (index, i) {
      this.formTool.couponList[index].itemList.push(
        {
          discount: '',
          threshold: '',
        }
      )
    },
    // 删除优惠券数组
    deleteCouponList (index) {
      this.formTool.couponList.splice(index, 1)
    },
    // 新建优惠券数组
    addCouponList () {
      let obj = {
        useAreaType: '1',
        name: "", // SKU名称
        date: [], // 时间
        effectiveTime: '', // 有效时间开始
        expirationTime: '', // 有效时间结束
        couponType: 0,// 券类型
        inventoryNumber: '', // 券总量
        shopTypeCode: [], // 客户类型
        limitUserType: ['0', '1'], // 新老客户
        limitUserNumberFlag: 0, // 每人限领
        limitUserNumber: 0,
        claimsType: 0, // 领取方式
        reductionType: 0, // 优惠方式
        limitReductionMoneyFlag: 0,// 单张最多减
        limitReductionMoney: 0, // 单张最多减免金额
        itemList: [{
          discount: '',
          threshold: '',
        }
        ],
        limitGoods: 0, //适用商品
        skuList: [],
      }
      if (this.formTool.limitFlag == 1) {
        obj.limitReductionMoneyFlag = 1
      }
      this.shopTypeCodeList.forEach(item => {
        obj.shopTypeCode.push(item.value)
      })
      this.formTool.couponList.push(obj)
      // console.log(this.formTool.couponList)
    },
    // 新建活动
    initActivity () {
      this.mode = "edit";
      this.clickRow = 'add'
      this.formTool = {
        status: '0',
        inventoryNumber: '',
        claimsNumber: '',
        useNumber: '',
        name: "",
        startDate: "",
        endDate: "",
        type: 1,
        limitFlag: 0,
        limitMoney: '', // 限制的金额
        couponList: [
          {
            useAreaType: '1',
            name: "", // SKU名称
            date: [], // 时间
            effectiveTime: '', // 有效时间开始
            expirationTime: '', // 有效时间结束
            couponType: 0,// 券类型
            inventoryNumber: '', // 券总量
            shopTypeCode: [], // 客户类型
            limitUserType: ['0', '1'], // 新老客户
            limitUserNumberFlag: 0, // 每人限领
            limitUserNumber: 0,
            claimsType: 0, // 领取方式
            reductionType: 0, // 优惠方式
            limitReductionMoneyFlag: 0,// 单张最多减
            limitReductionMoney: 0, // 单张最多减免金额
            itemList: [{
              discount: '',
              threshold: '',
            }
            ],
            limitGoods: 0, //适用商品
            skuList: [],
          }
        ],

      }
      this.shopTypeCodeList.forEach(item => {
        this.formTool.couponList[0].shopTypeCode.push(item.value)
      })
    },
    // 更改活动预算
    changeHuodong (val) {

      this.formTool.couponList.forEach(item => {
        if (val == 1) {
          item.limitReductionMoneyFlag = 1
          this.zuiduojianDisabled = true
        } else {
          item.limitReductionMoneyFlag = 0
          this.zuiduojianDisabled = false
        }
      })
    },
    // 关闭弹窗
    beforeClose () {
      this.dialogVisible = false;
    },
    // 查询
    getActivityList () {
      const params = {
        current: this.page.current,
        size: this.page.size,
        ...this.searchTool,
      };
      getCouponActivitys(params).then((res) => {
        this.tableData = res.records;
        // this.page.total = res.data.total
        // this.tableData.forEach(item => {
        //   item.type = drugenumeration(item.type)
        // })
      });
    },
    reset () {
      this.$refs.searchTool.resetFields();
      this.getActivityList();
    },
    // 选择商品
    chooseGood (row) {
      this.dialogVisible = true;
      this.rowData = row;
    },
    choice (val) {
      this.rowData.skuList = val
      // this.beforeClose()
    },
    // 提交
    submit (data) {
      editActivityInfo(data).then((res) => {
        this.$message.success("提交成功");
        // this.mode = "view";
        this.clickRow = ''
        this.getActivityList();
      });
    },
    // 保存
    save (str) {
      console.log(this.$refs["couponForm"], 'this.$refs["couponForm"]')
      this.$refs["couponForm"].validate((valid) => {
        if (!valid) {
          return;
        }
        const data = {
          ...this.formTool,
          couponList: this.formTool.couponList.map((item) => {
            return {
              ...item,
              effectiveTime: item.date[0],
              expirationTime: item.date[1]
            };
          }),
          startDate: this.formTool.date[0],
          endDate: this.formTool.date[1],

        };
        data.couponList.forEach(item => {
          this.$set(item, 'shopTypeCode', item.shopTypeCode.join(','))
          this.$set(item, 'limitUserType', item.limitUserType.join(','))
        })
        data.status = str
        if (this.formTool.id) {
          this.submit(data)
        } else {
          addActivityInfo(data).then((res) => {
            if (res) {
              // this.formTool.id = res.id;
              this.$message.success("保存成功");
              // this.mode = "view";
              this.clickRow = ''
              this.getActivityList();
            }
          });
        }

      });
    },
    // 作废
    cancellation () {

      disableActivityInfo(this.formTool.id).then(res => {
        if (res) {
          this.$message.success('操作成功')
          this.clickRow = ''
          this.getActivityList();
        }
      })
    },
    // 停止
    stop () {
      stopActivityInfo(this.formTool.id).then(res => {
        if (res) {
          this.$message.success('操作成功')
          this.clickRow = ''
          this.getActivityList();
        }
      })
    },
    // 删除
    deleteActivity () {
      deleteActivityInfo(this.formTool.id).then(res => {
        if (res) {
          this.$message.success('操作成功')
          this.clickRow = ''
          this.getActivityList();
        }
      })
    },
    editDetail (row) {
      getCouponActivityDetail(row.id).then((res) => {
        this.formTool = res;
        this.formTool.date = [res.startDate, res.endDate];
        this.formTool.type = Number(res.type);
        this.formTool.couponList = this.formTool.couponList.map((item) => {
          return {
            ...item,
            expireTime: [item.effectiveTime, item.expirationTime],
          };
        });
        this.mode = "view";
      });

      // this.$router.push(
      //   {
      //     path: '/productManage/auditProduct-edit',
      //     query: { goodsId: row.goodsId }
      //   }
      // )
    },
  },
};
</script>
<style lang='scss' scoped>
.comContent {
  padding: 0;
}

.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px !important;
  border-bottom: 1px solid #f5f5f5;

  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }

  .el-form-item {
    margin: 0px 0px 0px 8px !important;
  }
}

.center {
  margin-top: 16px;
  padding: 0 24px 10px 24px;
  height: calc(100% - 81px);
  display: flex;
  justify-content: space-between;

  .left-part {
    // float: left;
    width: 25%;
    border: 1px solid #ccc;
    // padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 2px;

    ::v-deep .el-table__body-wrapper {
      min-height: 400px;
    }
  }

  .right-part {
    // float: right;
    width: 75%;
    border: solid 1px #ccc;
    border-radius: 4px;
    // padding: 0 10px 10px;
    // height: min-content;
    // max-height:  calc(100vh - 24px);
    min-height: 550px;
    margin-bottom: 10px;
    margin-left: 10px;
    overflow-y: scroll;

    .right-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      //  background: #f5f5f5;
      padding: 4px 10px 4px;

      border-bottom: 1px solid #ebeef5;
      margin-bottom: 10px;
    }

    .base-info {
      position: relative;
      margin-top: 10px;
      // border-top: 1px solid #ccc;
      padding: 10px 10px 10px;
      border-radius: 5px;

      .anniu {
        position: absolute;
        right: 10px;
        top: 0px;
      }

      .custom-table {
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
      }

      ::v-deep .el-table {
        height: 100%;

        .el-table__body-wrapper {
          overflow-x: auto !important;
        }
      }

      .sec-content {
        margin-bottom: 20px;
        cursor: pointer;
      }

      .row-item {
        display: flex;
        white-space: nowrap;

        ::v-deep .e-select {
          width: 80px !important;
        }
      }

      .rule {
        display: flex;

        ::v-deep .el-input__inner {
          width: 90px;
        }
      }

      ::v-deep .el-form-item__content {
        // display: flex;
        align-items: center;
      }

      .content {
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }

  ::v-deep .imageP {
    width: calc(100% - 110px);

    p {
      margin: 0 0 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.rt-content {
  // padding: 0 10px 10px;
}
</style>
