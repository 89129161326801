import request from '@/utils/request'

// user

export function fetchList(query) {
  return request({
    // url: '/admin/user/page',
    url: '/member/manage/page',
    method: 'get',
    params: query
  })
}

export function addObj(obj) {
  return request({
    // url: '/admin/user',
    url: '/member/info/create',
    method: 'post',
    data: obj
  })
}
 // 添加店铺的用户
export function addShopObj(obj) {
  return request({
    // url: '/admin/user',
    url: '/member/manage/create',
    method: 'post',
    data: obj
  })
}

export function getObj(id) {
  return request({
    url: '/admin/user/' + id,
    method: 'get'
  })
}

export function delObj(id) {
  return request({
    url: '/admin/user/' + id,
    method: 'delete'
  })
}

export function putObj(obj) {
  return request({
    url: '/admin/user',
    method: 'put',
    data: obj
  })
}

export function isExsit(params) {
  return request({
    url: '/admin/user/check/exsit',
    method: 'get',
    params: params
  })
}
// 编辑用户信息
export function manage(data) {
  return request({
    url: '/member/manage',
    method: 'put',
    data
  })
}

// 获取用户角色列表
export function sysRoles(data) {
  return request({
    url: `/admin/user/sysRoles?userId=${data}`,
    method: 'get',
  })
}

// 账号转移
export function transfer(data) {
  return request({
    url: `/member/manage/account/transfer`,
    method: 'put',
    data:data
  })
}


// 店铺用户管理分页查询
export function getShopUserPage(data) {
  return request({
    url: `/member/manage/getShopUserPage`,
    method: 'get',
    params:data
  })
}