<template>
  <div class="fill-product-info-root container">
    <div class="product-info">
      <div class="pic-frame">
        <img
          :src="goodsBaseInfo ? goodsBaseInfo.goodsImage : ''"
          class="pic"
          v-if="goodsBaseInfo && goodsBaseInfo.goodsImage"
        />
      </div>
      <div class="txt-content">
        <div class="big-title desp">
          <span> 商品名称</span>
          {{ goodsBaseInfo ? goodsBaseInfo.goodsName : "" }}
        </div>
        <div class="desp">
          <span>规&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;格</span>
          {{ goodsBaseInfo ? goodsBaseInfo.specification : "" }}
        </div>
        <div class="desp">
          <span>生产厂家</span> {{ goodsBaseInfo ? goodsBaseInfo.factory : "" }}
        </div>
        <div class="desp">
          <span>批准文号</span>
          {{ goodsBaseInfo ? goodsBaseInfo.approvalNumber : "" }}
        </div>
      </div>
    </div>
    <div class="big-content">
      <formItem
        ref="formItem"
        :formIn="formIn"
        :AddIcon="AddIcon"
        :unitList="unitList"
        :shopLabelList="shopLabelList"
        @inputDidChange="inputDidChange"
        :minCooperativeIncome="minCooperativeIncome"
      />

      <div class="harf-block">
        <section class="big-block">
          <div class="title-block">
            <span class="text">销售规则</span>
          </div>

          <section class="form-content">
            <div class="row mutlti big">
              <div class="tip"><span class="weight">*</span> 客户类型</div>
              <div class="value fill">
                <div class="flag-block">
                  <el-checkbox
                    style="margin-right: 30px"
                    v-model="checkAll"
                    :indeterminate="isIndeterminate"
                    @change="handleCheckAllChange"
                    >全选</el-checkbox
                  >
                  <el-checkbox-group
                    v-model="formIn.shopTypeCode"
                    @change="changeCheckList"
                  >
                    <el-checkbox
                      v-if="listData && listData.length > 0"
                      :label="item.typeCode"
                      :key="item.typeCode"
                      v-for="item in listData ? listData : []"
                      >{{ item.typeName }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
            </div>

            <div class="row contine">
              <div class="tip"></div>
              <div class="value fill small-tip">
                <span> 注意：被勾选的客户类型对应的客户可购买当前商品。 </span>
              </div>
            </div>
            <div class="row mutlti big">
              <div class="tip">黑名单</div>
              <div class="value fill">
                <div class="flag-block">
                  <el-radio
                    v-model="formIn.groupId"
                    :label="item.id"
                    :key="item.id"
                    v-for="item in userList ? userList : []"
                  >
                    {{ item.schemeName }}
                  </el-radio>
                </div>
              </div>
            </div>

            <div class="row contine">
              <div class="tip"></div>
              <div class="value fill small-tip">
                <span> 注意：黑名单客户不可购买当前商品。 </span>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
    <div class="bottom">
      <el-button @click="handleBack">返回上一页</el-button>
      <el-button
        type="primary"
        @click="doSubmit('add')"
        v-if="pageType == 'add'"
        >发布商品</el-button
      >
      <el-button
        type="primary"
        @click="doSubmit('edit')"
        v-if="pageType == 'edit'"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import UploadImageView from "@/views/myQualification/components/UploadImage.vue";
import formItem from "./components/formItem.vue";
const IConst_Small = require("@/assets/public/icon-small.png");
const IConst_See_Icon = require("@/assets/zz/see-icon.png");
const IConst_Del_Icon = require("@/assets/zz/del-icon.png");
const IConst_Add_Icon = require("@/assets/zz/add-icon.png");

import {
  getunitList,
  getEnableListByType,
} from "@/api/productManage/auditProduct";
import { salesgroupinfo } from "@/api/salesControl/commodity";
import { getLabelInfoList } from "@/api/goods";
import {
  addAgencyControlPinSku,
  updateAgencyControlPinSku,
  queryAgencyControlPinSku,
} from "@/api/product";
import dayjs from "dayjs";
import _ from "lodash";

let g_save = false;
import fileUpload from "@/components/fileUpload";

export default {
  components: { ElImageViewer, UploadImageView, fileUpload, formItem },
  data() {
    return {
      smallIcon: IConst_Small,
      SeeIcon: IConst_See_Icon,
      DelIcon: IConst_Del_Icon,
      AddIcon: IConst_Add_Icon,
      action: process.env.VUE_APP_BASE_API + "/product/goodsbase/upload",
      imgType: "",
      limit: 1,
      shopLabelList: [], //可选商品标签
      unitList: [], //可选单位
      listData: [], // 客户类型类别
      userList: [], //用户组列表
      isIndeterminate: false, //全选，不确定效果,选择了部分
      checkAll: true, //全选客户类型

      formIn: {
        beSynDate: false, //同步有效期和生产日期
        medicineCode: "", //ERP编码
        salePrice: "", //价格
        marketPrice: "", //市场价格
        purchasePrice: "", //采购价格
        goodsSkuInventory: "", //总库存
        beSynStock: false, //自动同步库存
        goodsSkuUnit: "", //单位
        startWholesale: "", //起批量
        expireTime: "", //有效期至
        producedTime: "", //生产日期
        goodsSkuTitle: "", //商品标题
        batchNumber: "", //产品批号
        shopLabelId: "", //商品标签  数据来源待确认
        type: 1, //配置方式 0 设置区域、客户类型及黑名单  1 白名单
        shopTypeCode: [], //客户类型：1.诊所,2.春播运营平台,3.医药公司
        groupId: "", //用户组
        useTimes: false, //配数购买
      },
      goodsBaseInfo: {}, //商品基础详情
      goodsDetail: {}, //商品详情
      pageType: "", //页面类型 add 新增 edit 编辑
      minCooperativeIncome:'',//多控销显示价格
    };
  },
  computed: {},
  created() {
    this.initViews();
    this.queryGoodsDetail();
    this.doLoadLabelInfoList();
    this.getunitList();
    this.salesgroupinfo();
    this.getEnableListByType();
    this.pageType = this.$route.query.pageType;
    this.minCooperativeIncome = this.$route.query.minCooperativeIncome;
  },
  beforeDestroy() {},
  methods: {
    initViews() {
      let sessionItemGoods = window.sessionStorage.getItem("agentProductItem");
      this.goodsBaseInfo = JSON.parse(sessionItemGoods);
    },

    // 查询商品详情
    queryGoodsDetail() {
      console.log("this.goodsBaseInfo", this.goodsBaseInfo);
      const { goodsName, brand ,specification} = this.goodsBaseInfo;
      this.formIn.goodsSkuTitle = `${brand || ''} ${goodsName || ''} ${specification || ''}`;
      if (!this.goodsBaseInfo.goodsSkuId) {
        return;
      }
      queryAgencyControlPinSku(this.goodsBaseInfo.goodsSkuId).then((res) => {
        if (res) {
          this.goodsDetail = res;
          this.formIn = Object.assign(this.formIn, this.goodsDetail);
          this.formIn.goodsSkuTitle = `${brand || ''} ${goodsName || ''} ${specification || ''}`;
          if (res.shopTypeCode) {
            this.formIn.shopTypeCode = res.shopTypeCode.split(",");
          } else {
            this.formIn.shopTypeCode = [];
          }
          console.log("this.formIn", this.formIn);
        }
      });
    },

    doSubmit(type) {
      this.formIn = Object.assign(this.formIn, this.$refs["formItem"].formIn);
      if (this.formIn.goodsSkuInventory == "") {
        this.$message.warning("请输入总库存");
        return;
      }
      if (!this.formIn.startWholesale) {
        this.$message.warning("请输入起批量");
        return;
      }
      if (!this.formIn.expireTime) {
        this.$message.warning("请选择有效期至");
        return;
      }
      if (!this.formIn.producedTime) {
        this.$message.warning("请选择生产日期");
        return;
      }
      if (
        new Date(this.formIn.producedTime).getTime() >
        new Date(this.formIn.expireTime).getTime()
      ) {
        this.$message.warning("生产日期大于有效期，请重新选择");
        return;
      }

      // if (!this.formIn.goodsSkuTitle) {
      //   this.$message.warning("请输入商品标题");
      //   return;
      // }

      if(!this.formIn.shopTypeCode || this.formIn.shopTypeCode.length ==0){
        this.$message.warning("请输入客户类型");
        return;
      }

      let data = {
        goodsId: this.goodsBaseInfo.goodsId,
        adPlanId: this.goodsBaseInfo.adPlanId,
      };

      //输入框相关
      _.merge(data, this.formIn);

      data.expireTime = this.formIn.expireTime
        ? dayjs(this.formIn.expireTime).format("YYYY-MM-DD HH:mm:ss")
        : "";

      data.producedTime = this.formIn.producedTime
        ? dayjs(this.formIn.producedTime).format("YYYY-MM-DD HH:mm:ss")
        : "";

      data.shopTypeCode = "";

      if (
        this.formIn.shopTypeCode instanceof Array &&
        this.formIn.shopTypeCode.length > 0
      ) {
        data.shopTypeCode = this.formIn.shopTypeCode.join(",");
      }

      this.$loading({
        lock: true,
        text: "正在提交...",
        spinner: "el-icon-loading",
        color: "#fff",
        background: "rgba(0, 0, 0, 0.7)",
      });

      if (type == "add") {
        this.addAgentProductSku(data);
      } else if (type == "edit") {
        this.editAgentProductSku(data);
      }
    },

    addAgentProductSku(data) {
      data.id = this.goodsBaseInfo.id; //产品线id
      addAgencyControlPinSku(data)
        .then((res) => {
          if (res) {
            this.$message.success("发布商品成功");
            this.$loading().close();
            this.handleBack(1);
          } else {
            this.$loading().close();
          }
        })
        .catch(() => {
          this.$loading().close();
        });
    },

    editAgentProductSku(data) {
      data.id = this.goodsBaseInfo.goodsSkuId;
      updateAgencyControlPinSku(data)
        .then((res) => {
          if (res) {
            this.$message.success("保存成功");
            this.$loading().close();
            this.handleBack(1);
          } else {
            this.$loading().close();
          }
        })
        .catch(() => {
          this.$loading().close();
        });
    },

    changeCheckList(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.listData.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.listData.length;
    },
    handleCheckAllChange(val) {
      this.formIn.shopTypeCode = [];
      if (val && this.listData instanceof Array) {
        this.listData.forEach((item) => {
          this.formIn.shopTypeCode.push(item.typeCode);
        });
      } 
      this.isIndeterminate = false;
    },

    // 获取标签列表
    doLoadLabelInfoList() {
      getLabelInfoList(null).then((data) => {
        if (data instanceof Array && data.length > 0) {
          this.shopLabelList = data;
        } else {
          this.shopLabelList = [];
        }
      });
    },

    // 获取单位字典
    getunitList() {
      getunitList().then((res) => {
        if (res.code == 0) {
          this.unitList = res.data || [];
        }
      });
    },

    // 查询用户组信息
    salesgroupinfo() {
      let params = {};
      params.type = 1;
      salesgroupinfo(params).then((res) => {
        this.userList = res;
        if (params.type == 1) {
        }
      });
    },

    // 获取类型
    getEnableListByType() {
      let params = {
        type: 0,
      };
      getEnableListByType(params).then((res) => {
        if (res.code == 0) {
          this.listData = res.data; // 客户类型类别
          if (this.pageType == "add") {
            this.handleCheckAllChange(true);
          }
        }
      });
    },

    handleBack(index) {
      this.$router.go(-1);
    },
    // 市场价格输入框
    inputDidChange(val) {
      console.log('val', val);
      
      const { key, value } = val;
      this.formIn[key] = value;
      console.log('this.formIn', this.formIn);
      
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style/index.scss";
.desp span {
  width: 80px;
  display: inline-block;
}
.big-title {
}
.weight {
  color: #f5222d;
}
</style>
