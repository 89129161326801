<template>
  <div class="comContent">
    <div class="header" style="font-weight: 600;">平台活动报名</div>

    <div class="sortTitle">
      <span class="sort" style="display: flex;">发布时间排序
        <Arrow style="padding: 0 5px;" @clickTriangle="clickTriangle"></Arrow>
      </span>
      <span class="sort second" @click="lookMine" :style="{ color: (lookMineActive ? '#F5222D' : '') }">仅看已参加的活动</span>
    </div>

    <div class="displayBox">
      <div class="center">
        <div class="everyActiveBox" v-for="(item, index) in activeList" :key="index">
          <div style="display: inline-block;" class="imageBox">
            <img :src=item.pcImageUrl alt="" style="width: 80px;height: 80px;">
          </div>
          <div style="display: inline-block;" class="rightBox">
            <div style="display: flex;justify-content: space-between;align-items: center;padding: 2px 0;">
              <div class="title">
                {{ item.activityName }}
              </div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-direction: column; padding: 2px 0;">
              <div class="smallTitle">
                活动时间：{{ item.startTime ? item.startTime: '' }} - {{ item.endTime ? item.endTime :
                  ''
                }}
              </div>
              <div class="smallTitle">
                发布时间：{{ item.createTime ? item.createTime : '' }} 
              </div>
            </div>
          </div>
          <div>
            <el-button v-if="item.participationFlag" size="small" type="primary"
              @click="active(item, 'look')">查看</el-button>
            <el-button v-else size="small" type="primary" @click="active(item, 'join')">立即报名</el-button>
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex;justify-content: flex-end;padding: 5px 0;">
      <el-pagination :current-page="page.current" :page-size="page.size" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :page-sizes="[10, 20, 30, 50]" layout="total, sizes, prev, pager, next"
        :total="page.total">
      </el-pagination>
    </div>
  </div>
</template>
  
<script>
import { getPage } from '@/api/platformActivity/index'
import Arrow from './components/arrow.vue'
export default {
  props: {},
  data () {
    return {
      arrow: true,
      lookMineActive: false,
      activeList: [],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50]
      }
    }
  },
  components: { Arrow },
  async created () {
    await this.getPage()
  },
  mounted () {

  },
  watch: {},
  methods: {
    // 点击排序
    clickTriangle (val) {
      // console.log("list---------clickTriangle", val);
      this.arrow = val
      this.getPage()
    },
    // 查看我参加的
    lookMine () {
      this.page.current = 1
      this.lookMineActive = !this.lookMineActive
      this.getPage()
    },
    // 查询数据
    async getPage () {
      let params = {
        current: this.page.current,
        size: this.page.size,
        sort: this.arrow ? '0' : '1',
        participationFlag: this.lookMineActive ? '1' : '0',
        shopId: this.$store.state.user.user_info.shopId
      }
      const res = await getPage(params)
      if (res) {
        this.activeList = res.records
        this.page.total = res.total
      }
    },
    // 跳转
    active (item, status) {
      this.$router.push({
        path: '/operationCenter/platformActivity-listDetail',
        query: {
          id: item.id,
          status: status
        }
      })
    },
    handleSizeChange (val) {
      this.page.size = val
      this.getPage()
    },
    handleCurrentChange (val) {
      this.page.current = val
      this.getPage()
    }
  }
}

</script>
<style lang="scss" scoped>
.comContent {
  padding: 24px 24px 0 24px;

  .sortTitle {
    display: flex;
    padding: 12px;
    width: 100%;
    background-color: rgb(245, 243, 243);
    margin-top: 12px;

    .sort {
      font-size: 14px;
      color: gray;
    }

    .second {
      margin-left: 12px;
      cursor: pointer;
    }
  }



  .displayBox {
    height: calc(100% - 120px);
    overflow: auto
  }

  .center {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 12px;
    // height: calc(100% - 85px);
    // overflow: auto;

    .everyActiveBox {
      padding: 12px;
      margin-bottom: 12px;
      width: calc(50% - 6px);
      border: 1px solid #ededed;
      display: flex;
      align-items: center;

      .imageBox {
        width: 80px;
      }

      .rightBox {
        margin-left: 8px;
        width: calc(100% - 188px);

        .title {
          font-size: 16px;
          max-width: calc(100% - 88px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .smallTitle {
          color: rgb(191, 191, 191);
          max-width: calc(100% - 88px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
