var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("div", { staticClass: "left" }, [_c("leftPage")], 1),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          {
            staticStyle: {
              height: "50%",
              padding: "12px",
              "border-bottom": "1px solid #ededed",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { width: "100%", display: "flex" } },
              [
                _c("Tabs", {
                  staticClass: "title",
                  staticStyle: { width: "calc(100% - 70px)" },
                  attrs: {
                    tabsList: _vm.tabsList,
                    activeName: _vm.tabsActiveName,
                  },
                  on: {
                    "update:activeName": function ($event) {
                      _vm.tabsActiveName = $event
                    },
                    "update:active-name": function ($event) {
                      _vm.tabsActiveName = $event
                    },
                    "tab-click": _vm.handleChange,
                  },
                }),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "70px",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                      cursor: "pointer",
                    },
                    on: { click: _vm.clickMore },
                  },
                  [_vm._v("更多")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "rightBottom" }, [
              _vm.total != 0
                ? _c("div", { staticClass: "weidu" }, [
                    _c("span", { staticClass: "article" }, [
                      _vm._v(_vm._s(_vm.total) + "篇新文章未读"),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "yidu article",
                        on: { click: _vm.articleAllRead },
                      },
                      [_vm._v(_vm._s(_vm.total == 0 ? "全部已读" : "一键全读"))]
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { ref: "totalBox", staticClass: "totalBox" },
                _vm._l(_vm.tableData, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class:
                        _vm.activeIndex == index
                          ? "activeBox everyBox"
                          : "everyBox",
                      on: {
                        click: function ($event) {
                          return _vm.cellClick(item, index)
                        },
                      },
                    },
                    [
                      item.readTag == 1
                        ? _c("div", { staticClass: "readTag" })
                        : _c("div", { staticClass: "unreadTag" }),
                      _c("span", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.articleTitle)),
                      ]),
                      item.pinnedStatus == "1"
                        ? _c("img", {
                            staticStyle: { "margin-left": "6px" },
                            attrs: {
                              src: require("@/assets/zhiding.png"),
                              width: "12px",
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]
        ),
        _vm.activeList && _vm.activeList.length > 0
          ? _c("div", { staticStyle: { height: "50%", padding: "12px" } }, [
              _c(
                "div",
                {
                  staticStyle: {
                    "border-bottom": "1px solid #ededed",
                    "padding-bottom": "12px",
                    display: "flex",
                    "justify-content": "space-between",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "600",
                      },
                    },
                    [_vm._v("营销活动")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.allActive },
                    },
                    [_vm._v("全部活动")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    height: "calc(100% - 44px)",
                    overflow: "auto",
                  },
                },
                _vm._l(_vm.activeList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "everyActiveBox" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "imageBox",
                          staticStyle: { display: "inline-block" },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "40px", height: "40px" },
                            attrs: { src: item.thumbnailUrl, alt: "" },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "rightBox",
                          staticStyle: { display: "inline-block" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                "align-items": "center",
                                padding: "2px 0",
                              },
                            },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v(" " + _vm._s(item.name) + " "),
                              ]),
                              _c(
                                "div",
                                { staticStyle: { width: "80px" } },
                                [
                                  item.joinIdentification == 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.active(item, "join")
                                            },
                                          },
                                        },
                                        [_vm._v("立即参加")]
                                      )
                                    : _vm._e(),
                                  item.joinIdentification == 1
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.active(item, "look")
                                            },
                                          },
                                        },
                                        [_vm._v("查看")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                "align-items": "center",
                                padding: "2px 0",
                              },
                            },
                            [
                              _c("div", { staticClass: "smallTitle" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.startTime
                                        ? item.startTime.substr(0, 10)
                                        : ""
                                    ) +
                                    " - " +
                                    _vm._s(
                                      item.endTime
                                        ? item.endTime.substr(0, 10)
                                        : ""
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#F5222D",
                                  },
                                },
                                [
                                  _vm._v(
                                    " 活动截止：" +
                                      _vm._s(item.finishDuration) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "文章内容",
            visible: _vm.dialogVisible,
            width: "70%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", {
            staticClass: "endText content-html",
            domProps: { innerHTML: _vm._s(_vm.details) },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }