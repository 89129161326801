var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info_box" },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "info",
              staticClass: "ruleFormCont",
              attrs: {
                model: _vm.info,
                "label-width": "100px",
                "label-position": "top",
                disabled: _vm.info.disabled,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "formItemOne",
                  attrs: { label: "企业类型", prop: "selectNum" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "selectCont" },
                    _vm._l(_vm.shopList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          class: {
                            itemCont: true,
                            mainActive: _vm.info.shopTypeName === item.typeName,
                            mainBorder: _vm.info.shopTypeName != item.typeName,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectBtn(item, index)
                            },
                          },
                        },
                        [
                          _c("el-image", {
                            staticStyle: {
                              display: "flex",
                              "margin-right": "10px",
                            },
                            attrs: {
                              src: _vm.getStatus(
                                _vm.info.shopTypeName,
                                item.typeName
                              ),
                            },
                          }),
                          _vm._v(" " + _vm._s(item.typeName) + " "),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              ),
              _vm._l(_vm.info.showInputArr, function (item, key) {
                return _c(
                  "el-form-item",
                  {
                    key: key,
                    attrs: {
                      label: item.name,
                      prop: item.prop,
                      rules: {
                        required: item.require,
                        message: `请填写${item.name}`,
                        trigger: "blur",
                      },
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        placeholder: `请输入${item.name}`,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.info[item.prop],
                        callback: function ($$v) {
                          _vm.$set(_vm.info, item.prop, $$v)
                        },
                        expression: "info[item.prop]",
                      },
                    }),
                  ],
                  1
                )
              }),
              this.info.status == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "经营范围", prop: "scopeIds" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.info.scopes,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "scopes", $$v)
                            },
                            expression: "info.scopes",
                          },
                        },
                        _vm._l(_vm.info.scopes, function (item, index) {
                          return _c(
                            "el-checkbox",
                            {
                              key: index + "r",
                              attrs: {
                                label: item,
                                name: item,
                                disabled: !_vm.isDisabled,
                              },
                            },
                            [_vm._v(_vm._s(item || ""))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "企业名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      placeholder: "请输入企业名称",
                      disabled: _vm.isDisabled,
                    },
                    model: {
                      value: _vm.info.shopName,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "shopName", $$v)
                      },
                      expression: "info.shopName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所在区域", prop: "province" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "126px" },
                      attrs: {
                        placeholder: "请选择省",
                        disabled: _vm.isDisabled,
                      },
                      on: { change: _vm.selectedHandle },
                      model: {
                        value: _vm.info.provinceName,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "provinceName", $$v)
                        },
                        expression: "info.provinceName",
                      },
                    },
                    _vm._l(_vm.provinceData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.rname, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-left": "12px", width: "126px" },
                      attrs: {
                        placeholder: "请选择市",
                        disabled: _vm.isDisabled,
                      },
                      on: { change: _vm.selectedHandleCity },
                      model: {
                        value: _vm.info.cityName,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "cityName", $$v)
                        },
                        expression: "info.cityName",
                      },
                    },
                    _vm._l(_vm.cityData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.rname, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-left": "11px", width: "126px" },
                      attrs: {
                        placeholder: "请选择区",
                        disabled: _vm.isDisabled,
                      },
                      on: { change: _vm.selectedHandleArea },
                      model: {
                        value: _vm.info.areaName,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "areaName", $$v)
                        },
                        expression: "info.areaName",
                      },
                    },
                    _vm._l(_vm.areaData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.rname, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址", prop: "address" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      disabled: _vm.isDisabled,
                      placeholder: "请输入详细地址",
                    },
                    model: {
                      value: _vm.info.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "address", $$v)
                      },
                      expression: "info.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发票类型", prop: "invoiceType" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { disabled: _vm.isDisabled },
                      model: {
                        value: _vm.info.invoiceTypes,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "invoiceTypes", $$v)
                        },
                        expression: "info.invoiceTypes",
                      },
                    },
                    _vm._l(_vm.invoicetypeData, function (item, index) {
                      return _c(
                        "el-checkbox",
                        {
                          key: index,
                          attrs: { label: item.name, name: item.name },
                        },
                        [_vm._v(_vm._s(item.name || ""))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "ruleForm" },
            [
              _vm._l(_vm.certifys, function (ul, index) {
                return [
                  _c(
                    "div",
                    {
                      key: index,
                      staticStyle: { "margin-bottom": "30px" },
                      attrs: { "label-width": "800px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "600px",
                            "margin-bottom": "10px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                "font-size": "14px",
                                color: "#262626",
                              },
                            },
                            [
                              _c("div", [
                                ul.code != "09" && ul.require
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "margin-right": "10px",
                                        },
                                      },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  _vm._s(
                                    ul.code == "08"
                                      ? "开票信息"
                                      : _vm.allTypes[ul.code]
                                  ) + " "
                                ),
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mainColor",
                                      staticStyle: {
                                        "margin-right": "10px",
                                        "font-weight": "400",
                                        color: "#999",
                                      },
                                    },
                                    [_vm._v("有效期")]
                                  ),
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      size: "mini",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      "range-separator": "-",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      disabled: "",
                                    },
                                    model: {
                                      value: ul.date,
                                      callback: function ($$v) {
                                        _vm.$set(ul, "date", $$v)
                                      },
                                      expression: "ul.date",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "uploadCont" }, [
                        _c(
                          "div",
                          {
                            class: ["imgList", { disabled: _vm.info.disabled }],
                          },
                          [
                            _vm._l(ul.images, function (li, i) {
                              return _c(
                                "div",
                                { key: i, staticClass: "imgList_li" },
                                [
                                  _c("el-image", {
                                    staticClass: "imgList_li_img",
                                    attrs: {
                                      src: li,
                                      "preview-src-list": ul.images,
                                      disabled: _vm.isDisabled,
                                    },
                                  }),
                                  !_vm.isDisabled
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "imgList_li_close",
                                          on: {
                                            click: function ($event) {
                                              return _vm.imgDel(index, i)
                                            },
                                          },
                                        },
                                        [_vm._v(" x ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            !_vm.isDisabled
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "imgList_li imgList_li_add",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goUpload(ul)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-plus" })]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                        ul.desc
                          ? _c("div", { staticClass: "desc" }, [
                              _vm._v(_vm._s(ul.desc)),
                            ])
                          : _vm._e(),
                        ul.code == "09"
                          ? _c(
                              "div",
                              { staticClass: "more_input" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "more_p",
                                    staticStyle: { "margin-bottom": "5px" },
                                  },
                                  [_vm._v("备注")]
                                ),
                                ul.code == "09"
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "txtarea",
                                        disabled: _vm.isDisabled,
                                      },
                                      model: {
                                        value: _vm.info.remarks,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.info, "remarks", $$v)
                                        },
                                        expression: "info.remarks",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
          _vm.info.status != 2 && _vm.pageType == "qualificationDetails"
            ? _c(
                "div",
                { staticClass: "submit-btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.goSubmit },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("MyUpload", {
        ref: "imgupload",
        attrs: { "request-id": _vm.info.requestId, imgType: _vm.imgType },
        on: { upload: _vm.uploadSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }