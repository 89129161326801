var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "el-col",
        { attrs: { span: 24 } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _vm.source == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "商品类型", prop: "goodsType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.changeType(_vm.formTool.goodsType)
                                },
                              },
                              model: {
                                value: _vm.formTool.goodsType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formTool, "goodsType", $$v)
                                },
                                expression: "formTool.goodsType",
                              },
                            },
                            _vm._l(_vm.formTool.typeList, function (item) {
                              return _c(
                                "el-radio",
                                { key: item.id, attrs: { label: item.id } },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.source == 2
            ? _c("div", { staticStyle: { "margin-top": "54px" } })
            : _vm._e(),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("goods-three-class", {
                    ref: "three-class",
                    attrs: {
                      formTool: _vm.formTool,
                      isShowLable: true,
                      lableText: "商品分类",
                      changeForm: _vm.changeForm,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称", prop: "name" } },
                    [
                      _c(
                        "span",
                        {
                          class: _vm.changeForm.name
                            ? "lable-color-correct"
                            : "",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [_vm._v("产品名称")]
                      ),
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formTool.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "name", $$v)
                          },
                          expression: "formTool.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "经营范围", prop: "businessScopeId" } },
                    [
                      _c(
                        "span",
                        {
                          class: _vm.changeForm.businessScopeId
                            ? "lable-color-correct"
                            : "",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [_vm._v("经营范围")]
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { size: "small", placeholder: "请选择" },
                          model: {
                            value: _vm.formTool.businessScopeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "businessScopeId", $$v)
                            },
                            expression: "formTool.businessScopeId",
                          },
                        },
                        _vm._l(_vm.formTool.scopeList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格", prop: "specification" } },
                    [
                      _c(
                        "span",
                        {
                          class: _vm.changeForm.specification
                            ? "lable-color-correct"
                            : "",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [_vm._v("规格")]
                      ),
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formTool.specification,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "specification", $$v)
                          },
                          expression: "formTool.specification",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "批准文号", prop: "approvalNumber" } },
                    [
                      _c(
                        "span",
                        {
                          class: _vm.changeForm.approvalNumber
                            ? "lable-color-correct"
                            : "",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [_vm._v("批准文号")]
                      ),
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formTool.approvalNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "approvalNumber", $$v)
                          },
                          expression: "formTool.approvalNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "生产厂家", prop: "factory" } },
                    [
                      _c(
                        "span",
                        {
                          class: _vm.changeForm.factory
                            ? "lable-color-correct"
                            : "",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [_vm._v("生产厂家")]
                      ),
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formTool.factory,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "factory", $$v)
                          },
                          expression: "formTool.factory",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showProducer
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产地", prop: "origin" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.formTool.origin,
                              callback: function ($$v) {
                                _vm.$set(_vm.formTool, "origin", $$v)
                              },
                              expression: "formTool.origin",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌名", prop: "brand" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formTool.brand,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "brand", $$v)
                          },
                          expression: "formTool.brand",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位", prop: "unit" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择", size: "small" },
                          model: {
                            value: _vm.formTool.unit,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "unit", $$v)
                            },
                            expression: "formTool.unit",
                          },
                        },
                        _vm._l(_vm.unitList, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("dynamic-attribute", {
            ref: "dynamic-attribute",
            attrs: {
              formTool: _vm.formTool,
              changeExtendParams: _vm.changeExtendParams,
              isShowAll: true,
            },
          }),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片", prop: "imageList" } },
                    [
                      _c(
                        "span",
                        {
                          class: _vm.changeForm.imageList
                            ? "lable-color-correct"
                            : "",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [_vm._v("图片")]
                      ),
                      _c("imgUpload", {
                        attrs: { limit: 5, isEdit: true },
                        model: {
                          value: _vm.formTool.imageList,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "imageList", $$v)
                          },
                          expression: "formTool.imageList",
                        },
                      }),
                      _c("div", { staticClass: "upload-tips" }, [
                        _vm._v(
                          " 上传图片大小需低于2M，图片格式PNG,JPG,JPEG，图片尺寸建议800 x 800px "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }