var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "companyWarp" },
    [
      _c(
        "el-form",
        {
          ref: "formTool",
          attrs: {
            "label-width": "120px",
            rules: _vm.rules,
            required: "",
            model: _vm.formTool,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "企业名称" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { filterable: "", "value-key": "code" },
                  on: { change: _vm.shopNameChange },
                  model: {
                    value: _vm.formTool.sellerName,
                    callback: function ($$v) {
                      _vm.$set(_vm.formTool, "sellerName", $$v)
                    },
                    expression: "formTool.sellerName",
                  },
                },
                _vm._l(_vm.companyList, function (item, index) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.sellerName, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "企业税号", prop: "sellerTaxpayerId" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: { placeholder: "", disabled: "" },
                model: {
                  value: _vm.formTool.sellerTaxpayerId,
                  callback: function ($$v) {
                    _vm.$set(_vm.formTool, "sellerTaxpayerId", $$v)
                  },
                  expression: "formTool.sellerTaxpayerId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "康贝号", prop: "payeeNo" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: { placeholder: "", disabled: "" },
                model: {
                  value: _vm.formTool.payeeNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.formTool, "payeeNo", $$v)
                  },
                  expression: "formTool.payeeNo",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btnBoxWarp" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submit },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }