var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Activity name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Activity zone" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "please select your zone" },
                  model: {
                    value: _vm.form.region,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "region", $$v)
                    },
                    expression: "form.region",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "Zone one", value: "shanghai" },
                  }),
                  _c("el-option", {
                    attrs: { label: "Zone two", value: "beijing" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Activity time" } },
            [
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: { type: "date", placeholder: "Pick a date" },
                    model: {
                      value: _vm.form.date1,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "date1", $$v)
                      },
                      expression: "form.date1",
                    },
                  }),
                ],
                1
              ),
              _c("el-col", { staticClass: "line", attrs: { span: 2 } }, [
                _vm._v("-"),
              ]),
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c("el-time-picker", {
                    staticStyle: { width: "100%" },
                    attrs: { type: "fixed-time", placeholder: "Pick a time" },
                    model: {
                      value: _vm.form.date2,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "date2", $$v)
                      },
                      expression: "form.date2",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Instant delivery" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.delivery,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "delivery", $$v)
                  },
                  expression: "form.delivery",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Activity type" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [
                  _c("el-checkbox", {
                    attrs: { label: "Online activities", name: "type" },
                  }),
                  _c("el-checkbox", {
                    attrs: { label: "Promotion activities", name: "type" },
                  }),
                  _c("el-checkbox", {
                    attrs: { label: "Offline activities", name: "type" },
                  }),
                  _c("el-checkbox", {
                    attrs: { label: "Simple brand exposure", name: "type" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Resources" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.resource,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "resource", $$v)
                    },
                    expression: "form.resource",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "Sponsor" } }),
                  _c("el-radio", { attrs: { label: "Venue" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Activity form" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.form.desc,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "desc", $$v)
                  },
                  expression: "form.desc",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("Create")]
              ),
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("Cancel"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }