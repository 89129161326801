var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.invoiceInfo
    ? _c("div", [
        _c(
          "p",
          {
            staticClass: "duanluo",
            on: {
              click: function ($event) {
                return _vm.didClickInvoice()
              },
            },
          },
          [
            _c(
              "el-link",
              {
                style: { color: _vm.color },
                attrs: { type: "primary", underline: false },
              },
              [_vm._v(_vm._s(_vm.invoiceInfoText))]
            ),
            _c(
              "el-link",
              {
                staticStyle: { color: "#449FFF" },
                attrs: { underline: false },
              },
              [_vm._v("查看开票信息")]
            ),
            _c(
              "el-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.canReceive,
                    expression: "canReceive",
                  },
                ],
                staticStyle: { color: "#449FFF" },
                attrs: { underline: false },
              },
              [_vm._v("（可接受电子票）")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }