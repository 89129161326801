var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dashboard-container" }, [
    _vm._v(" 首页 "),
    _c(
      "div",
      [
        _c(
          "el-button",
          {
            directives: [
              {
                name: "hasPermi",
                rawName: "v-hasPermi",
                value: _vm.perma,
                expression: "perma",
              },
            ],
            attrs: { type: "primary", icon: "el-icon-plus" },
          },
          [_vm._v("新增")]
        ),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "hasPermi",
                rawName: "v-hasPermi",
                value: _vm.permb,
                expression: "permb",
              },
            ],
            attrs: { type: "primary", icon: "el-icon-plus" },
          },
          [_vm._v("编辑")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }