<template>
  <div class="area-select-root">
    <el-cascader
      v-model="localValue"
      :props="cprops"
      :options="provinceData"
      @change="handleChange"
      :disabled="disabled"
    ></el-cascader>
  </div>
</template>

<script>
import { regionAreaTree } from "@/api/saleRule/saleF";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    value: {
      //modeValue
      type: [String, Array, null],
      default: () => "",
    },
    valueName: {
      //区域对应的名称
      type: [String, Array, null],
      default: () => "",
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.findNames(newVal);
          this.localValue = newVal;
        }
      },
      immediate: true,
    },
    localValue: {
      handler(newVal, oldVal) {
        this.findNames(newVal);

        this.$emit("input", newVal);

        let valueName = [];
        if (newVal) {
          if (newVal.length) {
            valueName = [this.provinceName, this.cityName];
          }
          if (newVal.length === 3) {
            //可能没有第三级
            valueName.push(this.areaName);
          }
        }
        this.$emit("update:valueName", valueName);
      },
      //  immediate: true,
    },
  },
  data() {
    return {
      provinceName: "",
      cityName: "",
      areaName: "",

      province: "", //省份编码
      city: "", //城市编码
      area: "", //区域编码

      provinceData: [],
      cityData: [],
      areaData: [],

      localValue: [],

      cprops: { value: "id", label: "rname" },
    };
  },
  created() {
    this.getRegionAreaTree();
  },
  methods: {
    findNames(val) {
      if (val && val instanceof Array && val.length > 0) {
        this.province = val[0]; //省份编码
        this.city = val.length > 1 ? val[1] : ""; //城市编码
        this.area = val.length > 2 ? val[2] : ""; //区域编码

        const findedItemForProvince = this.provinceData.find(
          (item) => item.id === this.province
        );
        if (findedItemForProvince) {
          this.provinceName = findedItemForProvince.rname;

          const findedItemForCity =
            this.city &&
            findedItemForProvince.children.find(
              (item) => item.id === this.city
            );
          if (findedItemForCity) {
            this.cityName = findedItemForCity.rname;

            const findedItemForArea =
              this.area &&
              findedItemForCity.children.find((item) => item.id === this.area);
            if (findedItemForArea) {
              this.areaName = findedItemForArea.rname;
            }
          }
        }
      }
    },
    handleChange(val) {
      this.findNames(val);

      const newVal = {
        provinceName: this.provinceName,
        cityName: this.cityName,
        areaName: this.areaName,

        province: this.province,
        city: this.city,
        area: this.area,
      };
      this.$emit("change", newVal);
    },

    // 获取省市区列表
    getRegionAreaTree(leve) {
      let data = {
        level: 3,
      };
      regionAreaTree(data).then((res) => {
        if (res.code === 0) {
          this.provinceData = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.area-select-root {
  display: flex;
  align-items: center;

  ::v-deep .el-select {
    flex: 1;

    & + .el-select {
      margin-left: 8px;
    }
  }
}
</style>
