<template>
  <div class="main">
    <span class="arrUp" @click="clickUp" :style="{ 'border-bottom-color': color1 }"></span>
    <span class="arrDown" @click="clickDown" :style="{ 'border-top-color': color2 }"></span>
  </div>
</template>

<script>
export default {
  name: "SmallTriangle",
  data () {
    return {
      color1: "gray",
      color2: "#F5222D",
      isPositiveSequence: true
    }
  },
  methods: {
    clickDown () {
      if (this.isPositiveSequence) {
        return
      }
      this.isPositiveSequence = true
      this.temp()
      this.$emit('clickTriangle', true);
      console.log("SmallTriangle---------clickDown");
    },
    clickUp () {
      if (!this.isPositiveSequence) {
        return
      }
      this.isPositiveSequence = false
      this.temp()
      this.$emit('clickTriangle', false);
      console.log("SmallTriangle---------clickUp");
    },
    temp () {
      let temp = this.color1;
      this.color1 = this.color2;
      this.color2 = temp;
    }
  }
}
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.arrUp {
  width: 0;
  height: 0;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 6px solid gray;
  cursor: pointer;
}

.arrDown {
  width: 0;
  height: 0;
  margin-top: 2px;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-top: 6px solid gray;
  cursor: pointer;
}
</style>

