var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "static-template" },
    _vm._l(_vm.list, function (item, index) {
      return _c("div", { key: index, staticClass: "template-item" }, [
        !item.hide
          ? _c("div", { staticClass: "tip" }, [
              _vm._v(" " + _vm._s(item.filedLabel)),
              item.isMust
                ? _c("span", { staticClass: "weight" }, [_vm._v("*")])
                : _vm._e(),
            ])
          : _vm._e(),
        !item.hide && item.type === _vm.fieldType.Iconst_Input
          ? _c(
              "div",
              { staticClass: "value" },
              [
                _vm.preview
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.childTaskDetail
                            ? _vm.childTaskDetail[item.fieldName]
                            : ""
                        )
                      ),
                    ])
                  : _c("el-input", {
                      attrs: { disabled: "", size: "small", placeholder: "" },
                    }),
              ],
              1
            )
          : !item.hide && item.type === _vm.fieldType.Iconst_Pic
          ? _c(
              "div",
              { staticClass: "value" },
              [
                _vm.preview
                  ? _c("ossFileUpload", {
                      attrs: {
                        value: _vm.fileList(item),
                        ossUpload: true,
                        withCover: true,
                        disabled: true,
                        picSize: 88,
                        listType: "picture-card",
                      },
                    })
                  : _c("div", { staticClass: "pic-views" }, [
                      _c("div", { staticClass: "select-pic" }, [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _c("span"),
                      ]),
                    ]),
              ],
              1
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }