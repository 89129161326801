var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mainView" },
    [
      _c("el-scrollbar", { staticStyle: { height: "calc(100vh - 148px)" } }, [
        _c(
          "div",
          { staticClass: "eachRow flexRowCenter" },
          [
            _vm._l(_vm.dateTypeList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "dateType",
                  class: _vm.dateSelect == item ? "dateTypeSelect" : "",
                  on: {
                    click: function ($event) {
                      return _vm.handleClickDateType(item)
                    },
                  },
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            _c("el-date-picker", {
              ref: "datePicker",
              attrs: {
                type: "daterange",
                "unlink-panels": "",
                size: "small",
                "range-separator": "-",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                "picker-options": _vm.pickerOptions,
                clearable: false,
              },
              on: { change: _vm.didSelectDate },
              model: {
                value: _vm.timeRange,
                callback: function ($$v) {
                  _vm.timeRange = $$v
                },
                expression: "timeRange",
              },
            }),
            _c("div", { staticStyle: { width: "16px" } }),
            _c(
              "el-select",
              {
                staticStyle: { width: "130px" },
                attrs: { size: "small" },
                model: {
                  value: _vm.goodsType,
                  callback: function ($$v) {
                    _vm.goodsType = $$v
                  },
                  expression: "goodsType",
                },
              },
              _vm._l(_vm.controlGoodsTypes, function (item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "16px" },
                attrs: { size: "small", type: "default", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.reset()
                  },
                },
              },
              [_vm._v("重置")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.query()
                  },
                },
              },
              [_vm._v("查询")]
            ),
          ],
          2
        ),
        _c("div", { staticClass: "divider" }),
        _c("div", { staticClass: "eachRow" }, [
          _c("div", { staticClass: "flexRowCenter" }, [
            _c("span", { staticClass: "titleName" }, [
              _vm._v(_vm._s(_vm.userInfo.shopName || "省区")),
            ]),
            _c("span", { staticClass: "timeText" }, [
              _vm._v("统计时间：" + _vm._s(_vm.getTimeShow())),
            ]),
            _c("span", { staticClass: "timeText" }, [
              _vm._v("更新时间：" + _vm._s(_vm.freshTime)),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "columnflexRowCenter",
              staticStyle: { height: "100px" },
            },
            _vm._l(_vm.orderCountList, function (item, index) {
              return _c("div", { key: index, staticClass: "columnView" }, [
                _c("span", { staticClass: "columnViewTitle" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _c("div", { staticClass: "flexRowEnd" }, [
                  _c("span", { staticClass: "money" }, [
                    _vm._v(_vm._s(_vm.fixStr(item.count, item.key))),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "divider" }),
        _c(
          "div",
          { staticClass: "eachRow" },
          [
            _c(
              "el-table",
              {
                ref: "elTable",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData,
                  height: "240",
                  "highlight-current-row": "",
                },
                on: { "current-change": _vm.handleCurrentChange },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "排名", type: "index", width: "60" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "district", label: "地区" },
                }),
                _c("el-table-column", {
                  attrs: { label: "库存（件）" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.inventory || 0)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "剩余库存（件）" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.remainingInventory || 0)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "采购件数（件）" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.provincialBusinessOrderNumber || 0
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "应付总额（元）" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              "¥" +
                                _vm._s(
                                  _vm.fixtoTwo(
                                    scope.row.provincialBusinessNeedPayTotal
                                  )
                                )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "provincialBusinessOrderVolumeTotal",
                    label: "采购订单",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "provincialBusinessOrderFulfillmentQuantity",
                    label: "完成订单",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "完成收货（件）" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row
                                  .provincialBusinessNumberOfCompletedProducts ||
                                  0
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "应到总额（元）" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              "¥" +
                                _vm._s(
                                  _vm.fixtoTwo(
                                    scope.row
                                      .provincialBusinessAmountReceivableTotal
                                  )
                                )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "实到总额（元）" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              "¥" +
                                _vm._s(
                                  _vm.fixtoTwo(
                                    scope.row
                                      .provincialBusinessAmountReceivedTotal
                                  )
                                )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "divider" }),
        _c(
          "div",
          { staticClass: "eachRow" },
          [
            _c("div", { staticClass: "flexRowCenter" }, [
              _c("span", { staticClass: "titleName" }, [
                _vm._v(_vm._s(_vm.provinceSelectData?.district || "")),
              ]),
              _c("span", { staticClass: "timeText" }, [
                _vm._v("统计时间：" + _vm._s(_vm.getTimeShow())),
              ]),
            ]),
            _c("div", { staticClass: "orderHeader" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "27px",
                    display: "flex",
                    "align-items": "center",
                    flex: "1",
                  },
                },
                [
                  _c("div", { staticClass: "flex1" }),
                  _c("div", { staticClass: "flex2" }, [_vm._v("待发货")]),
                  _c("div", { staticClass: "flex2" }, [_vm._v("配送中")]),
                  _c("div", { staticClass: "flex2" }, [_vm._v("配送完成")]),
                  _c("div", { staticClass: "flex2" }, [_vm._v("完成订单")]),
                  _c("div", { staticClass: "flex2" }, [_vm._v("取消订单")]),
                  _c("div", { staticClass: "flex2" }, [_vm._v("售后订单")]),
                ]
              ),
              _c("div", { staticClass: "orderRow" }, [
                _c("div", { staticClass: "flex1" }, [_vm._v("采购订单")]),
                _c("div", { staticClass: "flex2 orderNumberText" }, [
                  _vm._v(_vm._s(_vm.goodsWaitingToBeSent?.orderQuantity || 0)),
                ]),
                _c("div", { staticClass: "flex2 orderNumberText" }, [
                  _vm._v(_vm._s(_vm.inDelivery?.orderQuantity || 0)),
                ]),
                _c("div", { staticClass: "flex2 orderNumberText" }, [
                  _vm._v(_vm._s(_vm.deliveryCompleted?.orderQuantity || 0)),
                ]),
                _c("div", { staticClass: "flex2 orderNumberText" }, [
                  _vm._v(_vm._s(_vm.completed?.orderQuantity || 0)),
                ]),
                _c("div", { staticClass: "flex2 orderNumberText" }, [
                  _vm._v(_vm._s(_vm.cancelAnOrder?.orderQuantity || 0)),
                ]),
                _c("div", { staticClass: "flex2 orderNumberText" }, [
                  _vm._v(_vm._s(_vm.afterSale?.orderQuantity || 0)),
                ]),
              ]),
              _c("div", { staticClass: "orderRow" }, [
                _c("div", { staticClass: "flex1" }, [_vm._v("采购件数")]),
                _c("div", { staticClass: "flex2 orderNumberText" }, [
                  _vm._v(_vm._s(_vm.goodsWaitingToBeSent?.goodsQuantity || 0)),
                  _c("span", { staticClass: "orderTypeText" }, [_vm._v("件")]),
                ]),
                _c("div", { staticClass: "flex2 orderNumberText" }, [
                  _vm._v(_vm._s(_vm.inDelivery?.goodsQuantity || 0)),
                  _c("span", { staticClass: "orderTypeText" }, [_vm._v("件")]),
                ]),
                _c("div", { staticClass: "flex2 orderNumberText" }, [
                  _vm._v(_vm._s(_vm.deliveryCompleted?.goodsQuantity || 0)),
                  _c("span", { staticClass: "orderTypeText" }, [_vm._v("件")]),
                ]),
                _c("div", { staticClass: "flex2 orderNumberText" }, [
                  _vm._v(_vm._s(_vm.completed?.goodsQuantity || 0)),
                  _c("span", { staticClass: "orderTypeText" }, [_vm._v("件")]),
                ]),
                _c("div", { staticClass: "flex2 orderNumberText" }, [
                  _vm._v(_vm._s(_vm.cancelAnOrder?.goodsQuantity || 0)),
                  _c("span", { staticClass: "orderTypeText" }, [_vm._v("件")]),
                ]),
                _c("div", { staticClass: "flex2 orderNumberText" }, [
                  _vm._v(_vm._s(_vm.afterSale?.goodsQuantity || 0)),
                  _c("span", { staticClass: "orderTypeText" }, [_vm._v("件")]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flexRowCenter", staticStyle: { height: "64px" } },
              [
                _c("span", { staticClass: "titleName" }, [
                  _vm._v("地办收取服务费明细"),
                ]),
                _c("span", { staticClass: "timeText" }, [
                  _vm._v("统计时间：" + _vm._s(_vm.getTimeShow())),
                ]),
              ]
            ),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.provinceData },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "排名", width: "60" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.getIndex(scope.$index))),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "localOfficeName",
                    label: "地区",
                    "min-width": "20%",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "regionalCompanyName",
                    label: "采购方名称",
                    "min-width": "50%",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "应到总额（元）", "min-width": "15%" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              "¥" +
                                _vm._s(
                                  _vm.fixtoTwo(scope.row.splitAccountReceivable)
                                )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "实到总额（元）", "min-width": "15%" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              "¥" +
                                _vm._s(
                                  _vm.fixtoTwo(scope.row.separateAccountIncome)
                                )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("div", { staticClass: "divider" }),
            _c("Pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.page.total > 0,
                  expression: "page.total>0",
                },
              ],
              attrs: {
                total: _vm.page.total,
                "page-sizes": _vm.page.pageSizes,
                page: _vm.page.current,
                limit: _vm.page.size,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.page, "current", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.page, "size", $event)
                },
                pagination: _vm.pageChange,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }