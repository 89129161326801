var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          {
            staticClass: "left_topic mb20",
            staticStyle: { "margin-top": "10px" },
          },
          [_vm._v("控销规则")]
        ),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  size: "small",
                  type: "primary",
                  disabled: _vm.pageType == "detail",
                },
                on: {
                  click: function ($event) {
                    return _vm.settingBatchRate()
                  },
                },
              },
              [_vm._v("批量设置比例")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  size: "small",
                  type: "primary",
                  disabled: _vm.pageType == "detail",
                },
                on: {
                  click: function ($event) {
                    return _vm.settingBatchTask()
                  },
                },
              },
              [_vm._v("批量设置任务")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "plain" },
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "center", staticStyle: { position: "relative" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              key: _vm.tableKey,
              ref: "tableRef",
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                border: "",
                "element-loading-text": "拼命加载中",
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255, 255, 255, 0.8)",
                type: "selection",
                lazy: true,
                load: _vm.loadFn,
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
              on: {
                "select-all": _vm.selectAll,
                select: _vm.selectFun,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "rname",
                  label: "区域",
                  sortable: "",
                  width: "300",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.rname) + " "),
                        scope.row.applyAmount &&
                        parseInt(scope.row.applyAmount) > 0
                          ? _c("el-badge", {
                              staticClass: "mark",
                              staticStyle: { "margin-top": "0px" },
                              attrs: { value: scope.row.applyAmount },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "售价",
                  sortable: "",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.minPrice
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.minPrice) +
                                  " ~ " +
                                  _vm._s(scope.row.maxPrice) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                staticStyle: { width: "150px" },
                attrs: { prop: "salesUserRate", label: "业务员推广服务费比例" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.accMul(scope.row.salesUserRate, 100)) +
                            " % "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                staticStyle: { width: "150px" },
                attrs: { prop: "goodsScopeRate", label: "平台技术服务费比例" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.accMul(scope.row.goodsScopeRate, 100)) +
                            " % "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                staticStyle: { width: "150px" },
                attrs: {
                  prop: "extendServiceChargeScale",
                  label: "平台推广服务费比例",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.accMul(
                                scope.row.extendServiceChargeScale,
                                100
                              )
                            ) +
                            " % "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "invitationNumber", label: "业务员" },
              }),
              _c("el-table-column", {
                attrs: { prop: "probationDays", label: "试用期（天）" },
              }),
              _c("el-table-column", {
                attrs: { prop: "probationAmount", label: "试用期任务" },
              }),
              _c("el-table-column", {
                attrs: { prop: "actualAmount", label: "总销量" },
              }),
              _c("el-table-column", {
                attrs: { prop: "cooperateStartTime", label: "开始合作时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "cooperateDays", label: "合作期（天）" },
              }),
              _c("el-table-column", {
                attrs: { prop: "post", label: "操作", width: "300" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.level == 3
                          ? [
                              _c(
                                "el-link",
                                {
                                  staticClass: "btn-list",
                                  attrs: {
                                    underline: false,
                                    type: "primary",
                                    disabled: _vm.pageType == "detail",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.settingRatio(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("设置比例")]
                              ),
                              _c(
                                "el-link",
                                {
                                  staticClass: "btn-list",
                                  attrs: {
                                    underline: false,
                                    type: "primary",
                                    disabled: _vm.pageType == "detail",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.settingTask(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("设置任务")]
                              ),
                              _c(
                                "el-link",
                                {
                                  staticClass: "btn-list",
                                  attrs: {
                                    underline: false,
                                    type: "primary",
                                    disabled: _vm.pageType == "detail",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.settingSalesman(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("邀请合作")]
                              ),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("settingScale", {
        ref: "setting-scale",
        on: { getList: _vm.getGoodsRuleList },
      }),
      _c("settingTask", {
        ref: "setting-task",
        attrs: { goodsUnit: _vm.goodsUnit },
        on: { getList: _vm.getGoodsRuleList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }