var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "itemCont" },
    [
      _c("div", { staticClass: "icon-flag" }, [_vm._v("免审")]),
      _c(
        "div",
        { staticClass: "itemImg" },
        [
          _c("el-image", {
            staticStyle: {
              width: "180px",
              height: "180px",
              padding: "15px 0px",
            },
            attrs: { lazy: "", src: _vm.item.image || _vm.avater, alt: "" },
          }),
        ],
        1
      ),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: (_vm.item.brand || "") + _vm.item.name,
            placement: "top",
          },
        },
        [
          _c("div", { staticClass: "itemBox name" }, [
            _vm._v(
              " " +
                _vm._s(_vm.item.brand || "") +
                " " +
                _vm._s(_vm.item.name || "") +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: _vm.item.factory,
            placement: "top",
          },
        },
        [
          _c("div", { staticClass: "itemBox factory" }, [
            _c("span", [_vm._v("厂家")]),
            _vm._v(" " + _vm._s(_vm.item.factory || "") + " "),
          ]),
        ]
      ),
      _c("p", { staticClass: "itemBox factory" }, [
        _c("span", [_vm._v("批文")]),
        _vm._v(" " + _vm._s(_vm.item.approvalNumber || "") + " "),
      ]),
      _c("div", { staticClass: "itemBox factory" }, [
        _c("span", [_vm._v("规格")]),
        _vm._v(_vm._s(_vm.item.specification || "") + " "),
      ]),
      _vm.item.origin
        ? _c("div", { staticClass: "itemBox factory" }, [
            _c("span", [_vm._v("产地")]),
            _vm._v(_vm._s(_vm.item.origin || "") + " "),
          ])
        : _vm._e(),
      _c("div", { staticClass: "itemBox factory" }, [
        _c("span", [_vm._v("编码")]),
        _vm._v(_vm._s(_vm.item.goodsCode || "") + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }