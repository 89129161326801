<template>
  <div class="contentForm">
    <el-form size="mini" label-width="80px" :model="formLabelAlign">
      <!-- 移到发货配送设置里头 -->
      <template v-if="0">
        <el-form-item label="快递类型">
          <div class="line">
            <el-input v-model="formLabelAlign.expressesName" style="width: 344px" disabled></el-input>

            <el-popover placement="right" width="623" trigger="click" v-model="visibleCan">
              <div>
                <div class="titleClass">
                  <p style="margin: 0px">快递公司</p>
                  <i class="el-icon-close" style="font-size: 16px" @click="visibleCan = false"></i>
                </div>
                <div class="kuaidiClass">
                  <div v-for="(item, index) in dictExpressesData" :key="index" @click="selectHandle(item)"
                    :class="{ active: isSelected(item) }">
                    <p class="name">{{ item.name }}</p>
                    <p class="code">{{ item.code }}</p>
                  </div>
                </div>
                <p style="
                    font-size: 16px;
                    color: #262626;
                    line-height: 24px;
                    font-weight: 600;
                    margin: 0px;
                  ">
                  快运公司
                </p>
                <div class="kuaidiClass">
                  <div v-for="(item, index) in ExpressesData" :key="index" @click="selectHandle(item)"
                    :class="{ active: isSelected(item) }">
                    <p class="name">{{ item.name }}</p>
                    <p class="code">{{ item.code }}</p>
                  </div>
                </div>
              </div>
              <el-button slot="reference" type="primary" size="small" :disabled="isdisabled">选择快递</el-button>
            </el-popover>
          </div>
        </el-form-item>
        <el-form-item label="发货省份">
          <el-select v-model="formLabelAlign.province" style="width: 168px; margin-right: 8px" value-key="id"
            placeholder="请选择省份" @change="selectedHandle" :disabled="isdisabled">
            <el-option v-for="item in provinceData" :key="item.id" :label="item.rname" :value="item">
            </el-option>
          </el-select>
          <el-select v-model="formLabelAlign.city" style="width: 168px" placeholder="请选择城市" value-key="id"
            @change="selectedHandleCity" :disabled="isdisabled">
            <el-option v-for="item in cityData" :key="item.id" :label="item.rname" :value="item">
            </el-option>
          </el-select>
          <!-- </div> -->
        </el-form-item>
      </template>
      <el-form-item label="销售政策与条款" style="width: 615px" label-width="120px">
        <el-input type="textarea" v-model="formLabelAlign.orderDealTimeRule" maxlength="50" show-word-limit resize="none"
          :rows="4" :disabled="isdisabled" placeholder="例如：工作日早八点半，晚5点半，非工作日不处理。"></el-input>
      </el-form-item>
      <el-form-item label="配送政策" style="width: 615px" label-width="120px">
        <el-input type="textarea" v-model="formLabelAlign.sendRule" maxlength="50" show-word-limit resize="none" :rows="4"
          :disabled="isdisabled" placeholder="例如：每天15点前的订单当天发，15点后的订单第二天发，周六日节假日正常发货"></el-input>
      </el-form-item>
      <el-form-item label="企业介绍" style="width: 615px" label-width="120px">
        <el-input type="textarea" v-model="formLabelAlign.shopDesc" maxlength="200" show-word-limit resize="none"
          :rows="6" :disabled="isdisabled" placeholder="例如：电子版开户，无需提供纸质资质，发票随货同行。"></el-input>
      </el-form-item>
      <el-form-item label="店铺公告" style="width: 615px" label-width="120px">
        <el-input type="textarea" v-model="formLabelAlign.shopAnnouncement" maxlength="200" show-word-limit resize="none"
          :rows="6" :disabled="isdisabled" placeholder="例如：店铺公告:本公司于9月2日至9月24日升级系统，开级期国暂信发货。"></el-input>
      </el-form-item>
      <el-form-item style="margin-top: 40px">
        <div>
          <el-button type="primary" style="width: 160px" size="small" @click="handleSave"
            v-if="!isdisabled || !formLabelAlign.id">保存</el-button>
          <el-button type="primary" style="width: 160px" size="small" @click="editorHandle"
            v-if="formLabelAlign.id && isdisabled">编辑</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  shopExtendInfos,
  dictExpresses,
  getProvince,
  getCity,
  postShopExtendInfos,
  putShopExtendInfos,
} from "@/api/shopmange/index";
export default {
  data () {
    return {
      isdisabled: false,
      value: "",
      formLabelAlign: {},
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
      ],
      visible: false,
      dictExpressesData: [],
      ExpressesData: [],
      activeVal: { id: "" },
      visibleCan: false,
      provinceData: [], //省份的数组
      cityData: [], //市的数组
      expressesList: [], //关联快递的数组
    };
  },
  mounted () {
    this.getExpresses();
    this.getProvince();
    this.getShopExtendInfos();
  },
  methods: {
    getShopExtendInfos () {
      shopExtendInfos().then((res) => {
        let newObj = res?.[0];
        newObj.city = { id: newObj.sendCity, rname: newObj.sendCityName };
        newObj.province = {
          id: newObj.sendProvince,
          rname: newObj.sendProvinceName,
        };
        this.expressesList = newObj.shopExpressList;
        let expressesData = newObj.shopExpressList.map((res) => {
          return res.expName;
        });
        newObj.expressesName = expressesData.join("/");
        this.selectedHandle(newObj.province);
        this.formLabelAlign = newObj;
      });
    },
    getExpresses () {
      dictExpresses().then((res) => {
        this.dictExpressesData = res.filter((r) => r.type == 0);
        this.ExpressesData = res.filter((r) => r.type == 1);
      });
    },
    getProvince () {
      getProvince().then((res) => {
        if (res.code == 0) {
          this.provinceData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },
    selectedHandle (val) {
      this.$set(this.formLabelAlign, "city", {});
      // getCity({ id: val.id }).then((res) => {
      //   if (res.code == 0) {
      //     this.cityData = res.data;
      //   } else {
      //     this.$message({ type: "warning", message: res.msg });
      //   }
      // });
    },
    selectedHandleCity (val) {
      this.$set(this.formLabelAlign, "city", val);
    },
    selectHandle (val) {
      this.activeVal = val;
      let expressesArray = this.expressesList;
      let selectIndex = expressesArray.findIndex(
        (res) => (res.code ? res.code : res.expCode) == val.code
      );
      if (selectIndex == -1) {
        expressesArray.push(val);
      } else {
        expressesArray.splice(selectIndex, 1);
      }
      this.expressesList = expressesArray;
      let expressesData = expressesArray.map((res) => {
        return res.name || res.expName;
      });
      this.formLabelAlign.expressesName = expressesData.join("/");
      this.isSelected(val);
    },
    //判断是否选中了
    isSelected (val) {
      let result = false;
      let expressesArray = this.expressesList;
      if (expressesArray.length == 0) {
        result = false;
      } else {
        let selectIndex = expressesArray.findIndex(
          (res) => (res.code ? res.code : res.expCode) == val.code
        );
        if (selectIndex == -1) {
          result = false;
        } else {
          result = true;
        }
      }
      return result;
    },
    handleSave () {
      this.expressesList.map((res) => {
        res.expCode = res.code ?? res.expCode;
        res.expName = res.name ?? res.expName;
      });
      // return
      let params = {
        id: this.formLabelAlign.id,
        sendProvince: this.formLabelAlign.province?.id,
        sendProvinceName: this.formLabelAlign.province?.rname,
        sendCity: this.formLabelAlign.city?.id,
        sendCityName: this.formLabelAlign.city?.rname,
        orderDealTimeRule: this.formLabelAlign.orderDealTimeRule,
        sendRule: this.formLabelAlign.sendRule,
        shopDesc: this.formLabelAlign.shopDesc,
        shopAnnouncement: this.formLabelAlign.shopAnnouncement,
        shopExpressList: this.expressesList,
      };
      if (this.formLabelAlign.id) {
        putShopExtendInfos(params).then((res) => {
          this.$message({ type: "success", message: "修改成功！" });
          this.isdisabled = true;
          this.getShopExtendInfos();
        });
      } else {
        postShopExtendInfos(params).then((res) => {
          this.$message({ type: "success", message: "保存成功！" });
          this.isdisabled = true;
          this.getShopExtendInfos();
        });
      }
    },
    editorHandle () {
      this.isdisabled = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.contentForm {
  height: calc(100% - 57px);
  overflow: auto;
  padding: 20px;
  width: 100%;

  .line {
    display: flex;
    justify-content: space-between;
  }
}
</style>
