<template>
  <div>
    <el-dialog
      title="新增运营"
      :visible.sync="dialogVisible"
      width="400px"
      :before-close="handleClose"
    >
      <el-form size="small" class="form-centent">
        <div class="row">
          <div class="tip commo-text-ideograph">运营姓名</div>
          <div class="value"><el-input /></div>
        </div>
        <div class="row">
          <div class="tip commo-text-ideograph">运营电话</div>
          <div class="value"><el-input /></div>
        </div>
        <div class="row">
          <div class="tip commo-text-ideograph">在售商品数</div>
          <div class="value"><el-input /></div>
        </div>
        <div class="row">
          <div class="tip commo-text-ideograph">商品分类</div>
          <div class="value">
            <el-select placeholder="请选择商品分类" @change="searchHandle">
              <el-option
                v-for="item in [
                  { label: '商家承担运费 ', value: 0 },
                  { label: '自定义运费', value: 1 },
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row">
          <div class="tip commo-text-ideograph">ERP编码</div>
          <div class="value"><el-input /></div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitUpload()">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { commentSave } from "@/api/businessManage";

export default {
  components: {},
  props: {
    requestId: {
      type: String,
      default: () => "",
    },

    limit: {
      type: Number,
      default: () => 10,
    },
  },
  computed: {
    headers: function () {
      return {
        Authorization: "Bearer " + getToken(),
      };
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    init() {
      //
    },
    submitUpload() {
      const { comment } = this.formData;

      let imageUrl = this.imageList.join(",");
      let params = {
        comment,
        image: imageUrl,
        orderCode: this.orderCode,
        source: 2, //来源(1-大商城，2-卖家端)
      };
      // console.log(params)
      if (!comment) {
        this.$message.warning("请填写评论内容！");
        return;
      }

      commentSave(params)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("提交成功");
            this.dialogVisible = false;
          } else {
            this.$message.error(res.msg);
            this.handleClose();
          }
        })
        .catch((err) => {
          this.handleClose();
        });
    },

    open() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
.form-centent {
  .row {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 8px;
    }

    .tip {
      width: 5em;
    }

    .value {
      width: 240px;
      margin-left: 8px;

      & > * {
        width: 100%;
      }
    }
  }
}
</style>
