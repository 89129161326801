var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fapiao" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发票相关售后",
            visible: _vm.dialogVisibleFaPiao,
            width: "848px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleFaPiao = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c("div", { staticStyle: { "margin-left": "auto" } }),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisibleFaPiao = false
                            },
                          },
                        },
                        [_vm._v("返 回")]
                      ),
                      _vm.btnStatus == "reSubmit"
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.agree },
                            },
                            [_vm._v("去开票")]
                          )
                        : _vm._e(),
                      _vm.btnStatus == "reSubmit"
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.reject },
                            },
                            [_vm._v("驳 回")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "mainContent" },
            [
              _c(
                "el-scrollbar",
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.form, "label-width": "120px" } },
                    [
                      _c("el-form-item", { attrs: { label: "订单编号" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.orderDetail.orderCode))]),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "shenqingyuanyin",
                          attrs: { label: "申请原因" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: true },
                              model: {
                                value: _vm.form.applyReason,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "applyReason", $$v)
                                },
                                expression: "form.applyReason",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("发票相关-无票"),
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("发票相关-错票"),
                              ]),
                              _c("el-radio", { attrs: { label: "3" } }, [
                                _vm._v("发票相关-申请专票"),
                              ]),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "tebietixing" }, [
                            _c("div", [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("特别提醒"),
                              ]),
                              _c("div", {}, [
                                _vm._v("请认真核对客户的发票信息"),
                              ]),
                            ]),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: { click: _vm.hedui },
                                  },
                                  [_vm._v("发票信息")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm.form.applyReason == 2
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "xinxi",
                              attrs: { label: "发票有误信息" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "typeView" },
                                _vm._l(_vm.typesArr, function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      on: {
                                        click: function ($event) {
                                          return _vm.didClickType(index)
                                        },
                                      },
                                    },
                                    [
                                      item.select
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "typeRow",
                                              class: item.select
                                                ? "typeSelect"
                                                : "",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.name)),
                                              ]),
                                              _c("img", {
                                                staticClass: "checkImage",
                                                attrs: {
                                                  src: _vm.skucheck,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "补充描述和凭证" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder:
                                "为了商家更好的处理您的售后问题，请填写补充描述和凭证",
                              disabled: true,
                              type: "textarea",
                              maxlength: "200",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c("el-upload", {
                            staticClass: "upload-demo",
                            attrs: {
                              disabled: true,
                              accept: ".jpg,.jpeg,.pdf,.PDF,.JPG,.PNG,.png",
                              action: _vm.uploadUrl,
                              header: _vm.header,
                              multiple: "",
                              limit: 3,
                              "file-list": _vm.form.supplementImage
                                ? _vm.form.supplementImage
                                : [],
                              "on-preview": _vm.preview,
                              "on-remove": (file, fileList) =>
                                _vm.removeUpload(_vm.form, file, fileList),
                              "on-success": (response, file, fileList) =>
                                _vm.successUpload(
                                  _vm.form,
                                  response,
                                  file,
                                  fileList
                                ),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.orderConsultHistoryList.length > 0
                        ? _c("div", { staticClass: "dialogTitle" }, [
                            _vm._v("协商历史"),
                          ])
                        : _vm._e(),
                      _vm.orderConsultHistoryList.length > 0
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.orderConsultHistoryList,
                              function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "lishi" },
                                  [
                                    _c("div", { staticClass: "xieshang" }, [
                                      _c("div", { staticClass: "title" }, [
                                        _vm._v(
                                          " " + _vm._s(item.consultPerson)
                                        ),
                                      ]),
                                      _c("div", { staticClass: "time" }, [
                                        _vm._v(_vm._s(item.consultTime)),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "wuliu" }, [
                                      _vm._v(
                                        " " + _vm._s(item.consultResult) + " "
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "detail",
          attrs: { visible: _vm.pdfDialog, width: "848px", title: "详情" },
          on: {
            "update:visible": function ($event) {
              _vm.pdfDialog = $event
            },
          },
        },
        [
          _vm.pdfStr == "pdf" || _vm.pdfStr == "PDF"
            ? _c("iframe", {
                staticStyle: { width: "100%", height: "100%" },
                attrs: { src: _vm.pdfUrl, frameborder: "0" },
              })
            : _c("img", {
                staticStyle: {
                  width: "auto",
                  height: "100%",
                  "object-fit": "contain",
                },
                attrs: { src: _vm.pdfUrl, frameborder: "0" },
              }),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.fapiaoDialog,
            width: "848px",
            title: "发票抬头详情",
          },
          on: {
            "update:visible": function ($event) {
              _vm.fapiaoDialog = $event
            },
          },
        },
        [
          _c(
            "el-scrollbar",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.company,
                    rules: _vm.rules,
                    "label-width": "110px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开票类型", prop: "invoiceType" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          attrs: { size: "small", disabled: !_vm.isEdit },
                          model: {
                            value: _vm.company.invoiceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.company, "invoiceType", $$v)
                            },
                            expression: "company.invoiceType",
                          },
                        },
                        [
                          _c("el-checkbox", { attrs: { label: "1" } }, [
                            _vm._v("普通发票（纸质）"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "2" } }, [
                            _vm._v("普通发票（电子）"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "3" } }, [
                            _vm._v("增值税专用发票（纸质）"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "4" } }, [
                            _vm._v("增值税专用发票（电子）"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票抬头", prop: "sellerName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          size: "small",
                          placeholder: "请输入发票抬头",
                          disabled: !_vm.isEdit,
                          max: "100",
                        },
                        model: {
                          value: _vm.company.sellerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "sellerName", $$v)
                          },
                          expression: "company.sellerName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票税号", prop: "sellerTaxpayerId" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          size: "small",
                          placeholder: "请输入发票税号",
                          disabled: !_vm.isEdit,
                        },
                        model: {
                          value: _vm.company.sellerTaxpayerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "sellerTaxpayerId", $$v)
                          },
                          expression: "company.sellerTaxpayerId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "注册地址", prop: "sellerAddress" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          size: "small",
                          type: "textarea",
                          max: "200",
                          placeholder: "请输入注册地址",
                          disabled: !_vm.isEdit,
                        },
                        model: {
                          value: _vm.company.sellerAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "sellerAddress", $$v)
                          },
                          expression: "company.sellerAddress",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "注册电话", prop: "sellerTel" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          disabled: "",
                          size: "small",
                          placeholder: "请输入注册电话",
                          disabled: !_vm.isEdit,
                          "show-word-limit": "",
                          max: "100",
                        },
                        model: {
                          value: _vm.company.sellerTel,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "sellerTel", $$v)
                          },
                          expression: "company.sellerTel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开户银行", prop: "sellerBank" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          disabled: "",
                          size: "small",
                          placeholder: "请输入开户银行",
                          disabled: !_vm.isEdit,
                          max: "100",
                        },
                        model: {
                          value: _vm.company.sellerBank,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "sellerBank", $$v)
                          },
                          expression: "company.sellerBank",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开户账号", prop: "sellerAccount" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          disabled: "",
                          size: "small",
                          placeholder: "请输入开户账号",
                          disabled: !_vm.isEdit,
                          max: "100",
                        },
                        model: {
                          value: _vm.company.sellerAccount,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "sellerAccount", $$v)
                          },
                          expression: "company.sellerAccount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件人", prop: "recipients" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          disabled: "",
                          size: "small",
                          placeholder: "请输入收件人",
                          disabled: !_vm.isEdit,
                          max: "100",
                        },
                        model: {
                          value: _vm.company.recipients,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "recipients", $$v)
                          },
                          expression: "company.recipients",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话", prop: "recipientsTel" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          disabled: "",
                          size: "small",
                          placeholder: "请输入联系电话",
                          disabled: !_vm.isEdit,
                          max: "100",
                        },
                        model: {
                          value: _vm.company.recipientsTel,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "recipientsTel", $$v)
                          },
                          expression: "company.recipientsTel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件地址", prop: "recipientsAddress" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          disabled: "",
                          size: "small",
                          placeholder: "请输入收件地址",
                          disabled: !_vm.isEdit,
                          max: "100",
                        },
                        model: {
                          value: _vm.company.recipientsAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "recipientsAddress", $$v)
                          },
                          expression: "company.recipientsAddress",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮箱", prop: "recipientsEmil" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          disabled: "",
                          size: "small",
                          placeholder: "请输入邮箱",
                          disabled: !_vm.isEdit,
                          max: "100",
                        },
                        model: {
                          value: _vm.company.recipientsEmil,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "recipientsEmil", $$v)
                          },
                          expression: "company.recipientsEmil",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.fapiaoDialog = false
                            },
                          },
                        },
                        [_vm._v("返 回")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "reject",
          attrs: { title: "驳回原因", visible: _vm.bohuiDialog },
          on: {
            "update:visible": function ($event) {
              _vm.bohuiDialog = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { maxlength: "100", "show-word-limit": "" },
            model: {
              value: _vm.rejectReason,
              callback: function ($$v) {
                _vm.rejectReason = $$v
              },
              expression: "rejectReason",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.bohuiDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.bohuiSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "reject",
          attrs: { title: "上传发票", visible: _vm.tongguoDialog },
          on: {
            "update:visible": function ($event) {
              _vm.tongguoDialog = $event
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              staticClass: "upload-demo",
              attrs: {
                accept: ".jpg,.jpeg,.pdf,.PDF,.JPG,.PNG,.png",
                action: _vm.uploadUrl,
                header: _vm.header,
                multiple: "",
                limit: 3,
                "file-list": _vm.invoiceImage ? _vm.invoiceImage : [],
                "on-preview": _vm.previewInvoiceImage,
                "on-remove": (file, fileList) =>
                  _vm.removeInvoiceImage(_vm.form, file, fileList),
                "on-success": (response, file, fileList) =>
                  _vm.successInvoiceImage(_vm.form, response, file, fileList),
              },
            },
            [
              _vm.invoiceImage.length < 3
                ? _c(
                    "el-button",
                    { attrs: { type: "primary", size: "mini" } },
                    [_vm._v("点击上传")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.tongguoDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.tijiaoSubmit },
                },
                [_vm._v("提 交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("QualificationInvoiceDialog", {
        ref: "qualificationInvoiceDialogRef",
        staticClass: "QualificationInvoiceDialog",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }