<template>
    <el-dialog :title="title" :visible.sync="visible" :lock-scroll="true" width="1218px" :before-close="handleClose">
      <div class="">
        <div>
          请您在每月的{{bondMonthDay}}号前，缴纳上个月的应付金额，超时将会扣除保证金
        </div>
        <div class="center" style="position: relative">
          <MyTable :table-data="tableData" :index="index" :selection="selection" :page="page" :operation="operation"
            :table-option.sync="tableOption" @page-change="getList">
            <template slot="edit" slot-scope="scope">
                <el-link type="primary" @click="lookDetail(scope.row)">查看明细</el-link>
            </template>
          </MyTable>
          <div style="padding: 24px 0;">
            总计:￥{{ data ? data.monthAmount : '' }}
          </div>
        </div>
        <div style="padding: 24px 0;">
          <el-form ref="form-data" :model="form" :inline="false" label-width="150px" label-position="right"
            style="text-align:left;">
            <div class="footer-btn">
              <el-button size="small" type="primary" @click="saveSubmit()" :disabled="isDisabled">确认</el-button>
              <el-button size="small" type="plain" @click="handleClose()">返回</el-button>
            </div>
          </el-form>
        </div>
      </div>
      <addDialog ref="add-dialog" @handleClose="handleClose" />
    </el-dialog>
  </template>
      
  <script>
  import addDialog from "./submitCertificate.vue";
  import MyTable from "@/components/myTable";
  import { queryPaymentAmount } from "@/api/offlinePayment";
  import avater from "/public/img/default.png";
  import _ from "lodash";
  export default {
    props: {},
    components: { MyTable, addDialog },
  
    data() {
      return {
  
        avater: avater,
        disabled: false,
        id: '',
        pageType: '',
        isDisabled: false,
        form: {
  
        },
  
        title: '',
        index: true,
        selection: false,
        visible: false,
        fileListShow: true,
        operation: false,
        data:null,
        tableData: [],
        tableOption: [
          { label: "项目", prop: "projectName" },
          { label: "金额（元）", prop: "amount", },
          { label: "月份", prop: "month",  },
          { label: "状态", prop: "statusText"},
          { label: "操作", prop: "edit", slot:true},
        ],
        page: {
          total: 0,
          current: 1,
          size: 10,
          pageSizes: [10, 20, 30, 50],
        },
      }
    },
    created() {
  
    },
    mounted() {
  
    },
    computed: {
        bondMonthDay(){
            const month = this.$store.state.shop.platformBond;
            return month ? month.finallyPaymentTime : '';
        }
    },
    watch: {},
    methods: {
        lookDetail(e){
            this.$emit('showDetail',e)
        },
      saveSubmit() {
        this.$refs['add-dialog'].init(this.form, 'edit')
      },
      async getList(current) {
        const resp = await queryPaymentAmount();
        if (resp) {
            const {monthPaymentAmountVOList,projectName} = resp;
            monthPaymentAmountVOList.forEach(item => {
              item.projectName = projectName
              item.statusText = '待付款'
            })
            this.data = resp;
            this.tableData = _.cloneDeep(monthPaymentAmountVOList);
            this.visible = true;
        }
      },
      // 页码变化
      handleCurrentChange(e) {
        this.page.current = e
      },
  
      // 条数变化
      handleSizeChange(e) {
        this.page.size = e
        this.page.current = 1
      },
  
      init(row, type) {
        this.title = '应付金额';
        this.getList();
        this.form = row;
      },
  
      handleClose() {
        this.visible = false;
      },
    }
  }
  
  </script>
  <style lang="scss" scoped>
  .eachRow {
    background-color: #fff;
    border-radius: 4px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
  }
  
  .titleView {
    display: flex;
    align-items: center;
  
    .rowLine {
      width: 4px;
      border-color: 1px;
      background-color: var(--main-color);
      height: 16px;
      margin-right: 8px;
    }
  
    .eachRowTitle {
      font-size: 16px;
      color: #262626;
      font-weight: 600;
    }
  
    .cpxhead {
      display: flex;
      align-items: center;
    }
  
    .mark {
      color: #f56c6c;
      font-size: 12px;
      margin-left: 10px;
      width: 200px;
      line-height: 1.5;
      display: inline-block;
      font-weight: bold;
    }
  }
  
  .goods-list-warp {
    width: 100%;
    margin-left: 100px;
    max-height: 500px;
    overflow-y: auto;
  }
  
  .goods-list {
    width: 50%;
    border-bottom: solid 1px #f5f5f5;
    padding-bottom: 10px;
  
    &:nth-last-child(1) {
      border-bottom: none;
    }
  }
  
  .footer-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  </style>
    
    