<template>
    <div class="publish-cooperation-goods">
        <!-- 搜索区域 商品名称 ｜ 商户名称 ｜ 产品线 ID -->
        <div class="search-area">
            <el-input v-model="form.goodsName" placeholder="请输入商品名称" clearable></el-input>
            <el-input v-model="form.shopName" placeholder="请输入商户名称" clearable></el-input>
            <el-input v-model="form.bagCode" placeholder="请输入产品线名称" clearable></el-input>
            <el-button type="primary" @click="searchGoods" >搜 索</el-button>
            <el-button  @click="resetSearch" >重置</el-button>
        </div>
        <el-scrollbar class="goods-list">
            <el-row :gutter="20">
                <el-col :span="5" class="el-col-5" v-for="(item, index) in list" :key="index" >
                    <div class="el-card">
                        <!-- 商品审核状态图片 -->
                        <img :src="showImg(item.saleStatus)" alt="" class="status-img" v-show="showImg(item.saleStatus)"/>
                        <div class="el-card__header">
                            <div>{{item.productLineName || ''}}</div>
                            <div class="el-card__header-id">{{item.productLineCode || ''}}</div>
                        </div>
                        <div class="el-card__body" @click="handleClick(item)">
                            <img :src="item.goodsImage" alt="" class="el-card__img" />
                            <div class="el-card__content">
                                <!-- 商品名称 -->
                                <div class="el-card__title">{{ item.goodsName || '-' }} </div>
                                <!-- 商品规格 -->
                                <div class="el-card__desc">{{ item.specification  || '-' }}</div>
                                <!-- 生产厂家 -->
                                <div class="el-card__desc">{{ item.factory || '-' }}</div>
                                <div class="el-card__price" v-if="!item.price">未定价</div>
                                <div class="el-card__price" v-else>单个预计收入：¥{{ item.price  }}</div>
                                <!-- 医药公司 -->
                                <div class="el-card__desc">{{ item.shopName || '-' }}</div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-scrollbar>
        <!-- 分页 -->
        <div class="pagination">
            <el-pagination
                layout="total, sizes, prev, pager, next, jumper"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 50,2]"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { MessageBox } from 'element-ui'
import { 
    querySellerCooperationProductPage,
    sellerApplyCooperation
 } from "@/api/saleRule/productLine"
import getApplyStatusImage from './utils/cooperationImage'
export default {
    data() {
        return {
            list: [],
            form: {
                goodsName: '',// 商品名称
                shopName: '',// 商户名称
                bagCode: '',// 产品线 ID
            },
            currentPage: 1,
            pageSize: 10,
            total: 10
        }
    },
    methods: {
        // 搜索商品
        searchGoods() {
            this.currentPage = 1;
            this.getGoodsList();
        },
        // 重置搜索条件
        resetSearch() {
            this.form.goodsName = '';
            this.form.shopName = '';
            this.form.bagCode = '';
            this.currentPage = 1;
            this.getGoodsList();
        },
        // 页码变化
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getGoodsList();
        },
        handleSizeChange(pageSize) {
            // 切换每页显示数量功能
            this.currentPage = 1;
            this.pageSize = pageSize;
            this.getGoodsList();
        },
        // 申请合作

        applyCooperation(item) {
            const obj = {
                bagId: item.productLineId,
                salePlanId: item.id
            }
            sellerApplyCooperation(obj).then(res => {
                if(res) {
                    this.$message.success('申请成功，请等待审核');
                    this.getGoodsList();
                }else{
                    this.$message.error(res.message);
                }
            })
        },
        // 点击商品
        handleClick(item) {
            // 根据状态显示图片 saleStatus	申请状态: 0. 待申请,1. 待审核,2 已通过,3 已驳回,4 已取消合作
            if(item.saleStatus === 1 || item.saleStatus === 2 ){
                return
            }
            MessageBox.confirm('代理审核通过后将会给您分配销售区域，您可以在被分配的销售区域中上架、销售商品', '申请合作', {
                confirmButtonText: '申请合作',
                cancelButtonText: '返回',
                type: 'info'
            }).then(() => {
                // 申请合作
                this.applyCooperation(item);
            }).catch(() => {
                // 取消发布
            });
        },
        // 获取商品列表
        getGoodsList() {
            querySellerCooperationProductPage({
                current: this.currentPage,
                size: this.pageSize,
                goodsName: this.form.goodsName,
                shopName: this.form.shopName,
                bagCode: this.form.bagCode
            }).then(res => {
                if(res.records && res.records.length > 0){
                    const { records, total } = res;
                    this.total = total;
                    this.list = records;
                }else{
                    this.total = 0;
                    this.list = [];
                }
            })
        },
        // 根据状态显示图片 saleStatus	申请状态: 0. 待申请,1. 待审核,2 已通过,3 已驳回,4 已取消合作

        showImg(saleStatus) {
            return getApplyStatusImage(saleStatus);
        }
           

    },
    created() {
        this.getGoodsList();
    }
}
</script>


<style scoped lang="scss">
.publish-cooperation-goods {
    display: flex;
    flex-direction: column;
    flex: 1;
    .search-area {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .el-input {
            width: 200px;
            margin-right: 20px;
        }
    }

    .goods-list {
        height: calc(100vh - 250px);
        overflow: hidden;
        width: 100%;
        .el-col-5 {
            width: 20%;
            padding-bottom: 12px;
        }

        .el-card {
            border: none;
            box-shadow: none;
            display: flex;
            flex:1;
            flex-direction: column;
            position: relative;
            .status-img {
                width: 50px;
                height: 50px;
                position: absolute;
                top: 10px;
                right: 10px;
            }
            .el-card__header {
                padding: 10px;
                font-size: 14px;
                font-weight: bold;
                color: #333;

                .el-card__header-id {
                    font-size: 12px;
                    color: #999;
                    margin-top: 10px;
                }
            }

            .el-card__body {
                padding: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                flex:1;
                
                .el-card__img {
                    width: 50%;
                    object-fit: cover;
                    aspect-ratio: 1/1;
                }

                .el-card__content {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    flex:1;
                    width: 100%;

                    .el-card__title {
                        font-size: 14px;
                        color: #333;
                        margin-top: 10px;
                        font-weight: 600;
                        line-height: 1.5;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 90%;
                        text-align: center;
                    }

                    .el-card__desc {
                        font-size: 12px;
                        color: #999;
                        margin-top: 5px;
                        line-height: 1.2;
                        text-align: center;
                        // 单行省略
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 90%;
                    }

                    .el-card__price {
                        font-size: 14px;
                        font-weight: bold;
                        color: #ff4949;
                        margin-top: 5px;
                        line-height: 1.2;
                        span {
                            font-size: 14px;
                            font-weight: bold;
                            color: #ff4949;
                        }
                    }
                }
            }
        }
    }
    .pagination {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
    }
    
}
</style>