/*
 * @FilePath: /eshop-ops-web/src/api/comList/index.js
 * @Description: 常用 公共list api接口
 * @Author: xiexingzhong2012@sina.cn
 * @Date: 2023-07-26 17:21:02
 * @LastEditTime: 2023-07-28 09:30:21
 * @LastEditors: xiexingzhong2012@sina.cn
 */
import request from '@/utils/request'

/**
 * @description: 
 * @param {*} params
 * @return {*}
 */ 
// 商品类型列表
export function getGoodsType() {
  return request({
      url: '/product/compgoodstype/getGoodsType',
      method: 'get'
  })
}

// 查询所有经营数据，不分页
export function queryScopeList(data) {
  return request({
    url: "/product/compBusinessScope/queryScopeList",
    method: "post",
    params: data,
  });
}
