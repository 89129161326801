<template>
  <div class="detail-root">
    <el-dialog title="审核发票附件" :close-on-click-modal="false" :visible.sync="dialogVisible" width="600px"
      @closed="onClosed">
      <div class="content form-content">
        <div class="row mutlti big">
          <div class="tip">审核结果</div>
          <div class="value input radio">
            <el-radio-group v-model="formData.radio">
              <el-radio :label="1">通过</el-radio>
              <el-radio :label="2">驳回</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="row mutlti big" v-if="formData.radio == 1">
          <div class="tip"></div>
          <div class="value input">
            <div class="warntxt">请确认是否审核通过发票附件?</div>
          </div>
        </div>
        <div class="row mutlti big" v-if="formData.radio == 2">
          <div class="tip">驳回理由</div>
          <div class="value input">
            <el-input size="small" type="textarea" maxlength="20" v-model="formData.desc" placeholder="请输入驳回理由"
              :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSave">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { csoMemberAdd, csoMemberEdit } from "@/api/complianceCso/salesman";
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      mainId: null,
      formData: {
        desc: "",
        radio: 1,
      },
      memberInfo: null,
    };
  },
  computed: {},
  methods: {
    open(row = null) {
      if (row) {
        this.mainId = row.id;
        this.dialogVisible = true;
      }
    },
    close() {
      this.dialogVisible = false;
    },
    onClosed() {
      this.reset();
    },
    reset() {
      this.mainId = "";
      this.formData = {
        desc: "",
        radio: 1,
      };
      this.memberInfo = null;
    },
    handleSave() {
      const { radio, desc } = this.formData;
      if(radio == 2 && !desc){
        this.$message.warning('驳回理由不能为空');return;
      }
      const data = {
        ...this.formData,
      };
      if (this.mainId) {
        data.id = this.mainId;
        //编辑
        // csoMemberEdit(data).then((res) => {
        //   if (res) {
        //     this.$emit("onRefresh");
        //     this.dialogVisible = false;
        //   }
        // });
      } else {
        //保存
        // csoMemberAdd(data).then((res) => {
        //   if (res) {
        //     this.$emit("onRefresh");
        //     this.dialogVisible = false;
        //   }
        // });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-root {
  .form-content {
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 0;
      box-sizing: border-box;

      &.mutlti {
        align-items: flex-start;
      }

      &.big {
        .tip {
          height: 32px;
          line-height: 32px;
        }
      }

      .tip {
        width: auto;
        font-size: 15px;
        font-weight: 400;
        text-align: right;
        color: #262626;
      }

      .value {
        margin-left: 16px;

        .warntxt {
          display: flex;
          height: 80px;
          align-items: center;
          justify-content: flex-start;
          font-size: 20px;
          color: var(--main-color);
        }

        &.input {
          width: calc(100% - 120px);
        }

        &.radio {
          min-height: 32px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  ::v-deep .el-dialog__body {
    max-height: 60vh;
    overflow-y: auto;
    min-height: 200px;
  }
}
</style>
