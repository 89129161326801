var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contentCont" },
    [
      _c("MyTable", {
        attrs: {
          "table-data": _vm.tableData,
          index: _vm.index,
          selection: _vm.selection,
          page: _vm.page,
          operation: _vm.operation,
          showpage: false,
          "table-option": _vm.tableOption,
        },
        on: {
          "update:tableOption": function ($event) {
            _vm.tableOption = $event
          },
          "update:table-option": function ($event) {
            _vm.tableOption = $event
          },
          "page-change": _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }