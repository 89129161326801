var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-product-info-root" },
    [
      _vm.formIn.platformCheckReason
        ? _c("div", { staticClass: "sold-out" }, [
            _c("div", { staticClass: "sold-out-title" }, [
              _vm._v("平台下架原因"),
            ]),
            _c("div", { staticClass: "sold-out-content" }, [
              _vm._v(" " + _vm._s(_vm.formIn.platformCheckReason) + " "),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "product-info" }, [
        _c("div", { staticClass: "pic-frame" }, [
          _vm.goodsDetail &&
          _vm.goodsDetail.compGoodsBaseVo &&
          _vm.goodsDetail.compGoodsBaseVo.image
            ? _c("img", {
                staticClass: "pic",
                attrs: {
                  src: _vm.goodsDetail
                    ? _vm.goodsDetail.compGoodsBaseVo.image
                    : "",
                },
              })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "txt-content" }, [
          _c("div", { staticClass: "big-title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.goodsDetail ? _vm.goodsDetail.compGoodsBaseVo.name : ""
                ) +
                " "
            ),
          ]),
          _c("div", { staticClass: "desp" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.goodsDetail
                    ? _vm.goodsDetail.compGoodsBaseVo.specification
                    : ""
                ) +
                " "
            ),
          ]),
          _c("div", { staticClass: "desp" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.goodsDetail ? _vm.goodsDetail.compGoodsBaseVo.factory : ""
                ) +
                " "
            ),
          ]),
          _c("div", { staticClass: "desp" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.goodsDetail
                    ? _vm.goodsDetail.compGoodsBaseVo.approvalNumber
                    : ""
                ) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "flag" }, [
          _vm._v(_vm._s(_vm._f("selectTypeDetail")(_vm.formIn.activityType))),
        ]),
      ]),
      _c("el-form", { attrs: { disabled: _vm.isDisabled } }, [
        _c(
          "div",
          { staticClass: "big-content" },
          [
            _vm.formIn.activityType == 1 ||
            _vm.formIn.activityType == 2 ||
            _vm.formIn.activityType == 3
              ? _c("formItem", {
                  ref: "formItem",
                  attrs: {
                    fileList: _vm.fileList,
                    isDisabled: _vm.isDisabled,
                    AddIcon: _vm.AddIcon,
                    unitList: _vm.unitList,
                    name: _vm.goodsDetail.compGoodsBaseVo.name,
                    shopLabelList: _vm.shopLabelList,
                    imageUrlList: _vm.imageUrlList,
                    formIn: _vm.formIn,
                  },
                  on: {
                    handleUpload: _vm.handleUpload,
                    handleAddUserGroup: _vm.handleAddUserGroup,
                    onRemoveHandler: _vm.onRemoveHandler,
                  },
                })
              : _vm._e(),
            _vm.formIn.activityType == 4
              ? _c("formItemGift", {
                  ref: "formItem",
                  attrs: {
                    isDisabled: _vm.isDisabled,
                    fileList: _vm.fileList,
                    AddIcon: _vm.AddIcon,
                    unitList: _vm.unitList,
                    shopLabelList: _vm.shopLabelList,
                    imageUrlList: _vm.imageUrlList,
                    formIn: _vm.formIn,
                  },
                  on: {
                    handleUpload: _vm.handleUpload,
                    handleAddUserGroup: _vm.handleAddUserGroup,
                    onRemoveHandler: _vm.onRemoveHandler,
                  },
                })
              : _vm._e(),
            _vm.formIn.activityType != 4
              ? _c("div", { staticClass: "harf-block" }, [
                  _c("div", { staticClass: "big-block" }, [
                    _c("div", { staticClass: "title-block" }, [
                      _c("span", { staticClass: "text" }, [_vm._v("销售规则")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-content" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "tip" }, [
                            _c("span", { staticClass: "weight" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v("配置方式"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "value fill" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.salesgroupinfo },
                                  model: {
                                    value: _vm.formIn.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formIn, "type", $$v)
                                    },
                                    expression: "formIn.type",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v(" 设置区域、客户类型及黑名单 "),
                                  ]),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("白名单"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm.formIn.type === 0
                          ? [
                              _c("div", { staticClass: "row mutlti big" }, [
                                _c("div", { staticClass: "tip" }, [
                                  _vm._v("地域"),
                                ]),
                                _c("div", { staticClass: "value fill" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flag-block" },
                                    _vm._l(
                                      _vm.areaIdList ? _vm.areaIdList : [],
                                      function (item) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: item.areaId,
                                            attrs: { label: item.areaId },
                                            model: {
                                              value: _vm.formIn.areaId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formIn,
                                                  "areaId",
                                                  $$v
                                                )
                                              },
                                              expression: "formIn.areaId",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.schemeName) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "row contine" }, [
                                _c("div", { staticClass: "tip" }),
                                _c(
                                  "div",
                                  { staticClass: "value fill small-tip" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " 注意：仅限已选地域对应客户可购买当前商品。 "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "value" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          icon: "el-icon-plus",
                                        },
                                        on: { click: _vm.handleAddArea },
                                      },
                                      [_vm._v("新增地域")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        _vm.formIn.type == 0
                          ? _c("div", { staticClass: "row mutlti big" }, [
                              _c("div", { staticClass: "tip" }, [
                                _vm._v("客户类型"),
                              ]),
                              _c("div", { staticClass: "value fill" }, [
                                _c(
                                  "div",
                                  { staticClass: "flag-block" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticStyle: { "margin-right": "30px" },
                                        attrs: {
                                          indeterminate: _vm.isIndeterminate,
                                        },
                                        on: {
                                          change: _vm.handleCheckAllChange,
                                        },
                                        model: {
                                          value: _vm.checkAll,
                                          callback: function ($$v) {
                                            _vm.checkAll = $$v
                                          },
                                          expression: "checkAll",
                                        },
                                      },
                                      [_vm._v("全选")]
                                    ),
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        on: { change: _vm.changeCheckList },
                                        model: {
                                          value: _vm.formIn.shopTypeCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formIn,
                                              "shopTypeCode",
                                              $$v
                                            )
                                          },
                                          expression: "formIn.shopTypeCode",
                                        },
                                      },
                                      _vm._l(
                                        _vm.listData ? _vm.listData : [],
                                        function (item) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: item.typeCode,
                                              attrs: { label: item.typeCode },
                                            },
                                            [_vm._v(_vm._s(item.typeName))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.formIn.type == 1
                          ? _c("div", { staticClass: "row mutlti big" }, [
                              _c("div", { staticClass: "tip" }, [
                                _vm._v("客户类型"),
                              ]),
                              _c("div", { staticClass: "value fill" }, [
                                _c(
                                  "div",
                                  { staticClass: "flag-block" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticStyle: { "margin-right": "30px" },
                                        attrs: {
                                          indeterminate: _vm.isIndeterminate,
                                          disabled: true,
                                        },
                                        model: {
                                          value: _vm.checkAll,
                                          callback: function ($$v) {
                                            _vm.checkAll = $$v
                                          },
                                          expression: "checkAll",
                                        },
                                      },
                                      [_vm._v("全选")]
                                    ),
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        attrs: { disabled: true },
                                        model: {
                                          value: _vm.formIn.shopTypeCodeNew,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formIn,
                                              "shopTypeCodeNew",
                                              $$v
                                            )
                                          },
                                          expression: "formIn.shopTypeCodeNew",
                                        },
                                      },
                                      _vm._l(
                                        _vm.listData ? _vm.listData : [],
                                        function (item) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: item.typeCode,
                                              attrs: { label: item.typeCode },
                                            },
                                            [_vm._v(_vm._s(item.typeName))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "row contine" }, [
                          _c("div", { staticClass: "tip" }),
                          _c("div", { staticClass: "value fill small-tip" }, [
                            _c("span", [
                              _vm._v(
                                " 注意：被勾选的客户类型对应的客户可购买当前商品。 "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "row mutlti big" }, [
                          _c("div", { staticClass: "tip" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formIn.type == "1" ? "白名单" : "黑名单"
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "value fill" }, [
                            _c(
                              "div",
                              { staticClass: "flag-block" },
                              _vm._l(
                                _vm.userList ? _vm.userList : [],
                                function (item) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: item.id,
                                      attrs: { label: item.id },
                                      model: {
                                        value: _vm.formIn.groupId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formIn, "groupId", $$v)
                                        },
                                        expression: "formIn.groupId",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.schemeName) + " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ]),
                        ]),
                        _vm.formIn.type == 1
                          ? _c("div", { staticClass: "row mutlti big" }, [
                              _c("div", { staticClass: "tip" }, [
                                _vm._v("指定客户(九和)"),
                              ]),
                              _c("div", { staticClass: "value fill" }, [
                                _c(
                                  "div",
                                  { staticClass: "flag-block" },
                                  _vm._l(
                                    _vm.userList2 ? _vm.userList2 : [],
                                    function (item) {
                                      return _c(
                                        "el-radio",
                                        {
                                          key: item.id,
                                          attrs: { label: item.id },
                                          model: {
                                            value: _vm.formIn.groupId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formIn,
                                                "groupId",
                                                $$v
                                              )
                                            },
                                            expression: "formIn.groupId",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.schemeName) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "row contine" }, [
                          _c("div", { staticClass: "tip" }),
                          _c("div", { staticClass: "value fill small-tip" }, [
                            _c("span", [
                              _vm._v(" 注意：黑名单客户不可购买当前商品。 "),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "value" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "small",
                                    icon: "el-icon-plus",
                                  },
                                  on: { click: _vm.handleAddUserGroup },
                                },
                                [_vm._v(" 新增用户组 ")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("el-button", { on: { click: _vm.handleBack } }, [
            _vm._v("返回上一页"),
          ]),
          _vm.fromPage == "edit"
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doSubmit } },
                [_vm._v("修改保存商品")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("UploadImageView", {
        ref: "imgupload",
        attrs: { "request-id": "1", imgType: _vm.imgType },
        on: { upload: _vm.uploadSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }