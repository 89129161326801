import request from "@/utils/request";

// 查询客户类型列表
export function getListByType(params) {
  return request({
    url: `/shop/shoptype/getListByType`,
    method: "GET",
    params,
  });
}

// 客户列表查询 /erpShop/getErpShopPage

export function getErpShopPage(params) {
  return request({
    url: `/shop/erpShop/getErpShopPage`,
    method: "post",
    data: params,
  });
}

// 获取客户信息变更日志
// export function changeShopInfo(params) {
//     return request({
//         url: `/shop/erpShop/changeShopInfo`,
//         method: 'GET',
//         params
//     })
// }

// 获取客户信息变更日志
export function changeShopInfo(data) {
  return request({
    url: `/shop/userCompCertificatesRecords/queryBuyerChangeRecord`,
    method: "POST",
    data: data,
  });
}

// 客户列表 客户信息 更新 、、编辑
export function updateErpShop(data) {
  return request({
    url: `/shop/erpShop`,
    method: "PUT",
    data: data,
  });
}

// 客户列表 导出
export function exportShopList(
  data,
  apiUrl = "/shop/erpShop/exportShopList",
  exportName = "客户列表"
) {
  return request({
    url: apiUrl,
    method: "get",
    params: data,
    responseType: "blob",
  }).then((response) => {
    console.log(response, "res");
    // 处理返回的文件流
    const blob = response.data;
    if ((blob && blob.size === 0) || response.size == 0) {
      Message.warning("内容为空，无法下载");
      return;
    }
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    const contentDisposition = response.headers["content-disposition"];
    const fileName = decodeURIComponent(
      contentDisposition.substring(contentDisposition.indexOf("=") + 1)
    );
    link.download = exportName + "数据导出" + fileName;
    document.body.appendChild(link);
    link.click();
    window.setTimeout(function () {
      URL.revokeObjectURL(blob);
      document.body.removeChild(link);
    }, 0);
  });
}

///order/purchase/updateErpShopCode 更新ERP客户编码 (销售订单列表页）

export function updateErpShopCode(data) {
  return request({
    url: `/order/order/purchase/updateErpShopCode`,
    method: "Post",
    data: data,
  });
}

//  上传发票
export function uploadInvoice(data) {
  return request({
    url: `/order/invoice/uploadInvoice`,
    method: "Post",
    data: data,
  });
}

// 查询发票抬头
export function invoiceTitleQuery(data) {
  return request({
    url: `/invoice/compliance/cominvoicetitleinfo/seller/detail/${data.shopId}`,
    method: "GET",
  });
}

// 新增订单评论
export function commentSave(data) {
  return request({
    url: "/order/order/comment/save",
    method: "post",
    data,
  });
}

//客户列表条件统计
export function getErpShopSum() {
  return request({
    url: "/shop/erpShop/getErpShopSum",
    method: "get",
  });
}
