import request from "@/utils/request";

// 查询缴费列表
export function orderVoucherRelationGet(data) {
  return request({
    url: `/order/purchase/order/voucher/relation/get/page`,
    method: "get",
    params:data
  });
}


// 查看凭证列表
export function payvoucherinfoGet(data) {
  return request({
    url: `/order/purchase/payvoucherinfo/shop`,
    method: "get",
    params:data
  });
}

//卖家缴费详情 
export function relationPayGetList(data) {
  return request({
    url: `/order/purchase/order/voucher/relation/get/pay/list`,
    method: "get",
    params:data
  });
}
 
//卖家 提交缴费凭证 
export function payvoucherinfoAdd(data) {
  return request({
    url: `/order/purchase/payvoucherinfo/add`,
    method: "POST",
    data:data
  });
}


// 根据订单id查询凭证信息
export function payvoucherinfoGetinfo(data) {
  return request({
    url: `/order/purchase/payvoucherinfo/get/info`,
    method: "get",
    params:data
  });
}

// 卖家查看缴费完成后的详情  查看缴费明细 
export function relationGetPayList(data) {
  return request({
    url: `/order/purchase/order/voucher/relation/get/pay/detail`,
    method: "get",
    params:data
  });
}

// 卖家 重新提交凭证到平台 
export function payvoucherinfoResubmit(data) {
  return request({
    url: `/order/purchase/payvoucherinfo/resubmit`,
    method: "PUT",
    data:data
  });
}

// 审核凭证
export function payvoucherinfoCheck(data) {
  return request({
    url: `/order/purchase/payvoucherinfo/check`,
    method: "PUT",
    data:data
  });
}

// aftersaleSubmitVocher
// 售后 提交线下打款凭证 
export function aftersaleSubmitVocher(data) {
  return request({
    url: `/order/aftersale/submit/voucher`,
    method: "GET",
    params:data
  });
}

// 查询应付金额平台配置信息
export function configuration() {
  return request({
    url: `/shop/platform/parameter/setting/query/plm/configuration`,
    method: "GET"
  });
}
// 获取商铺应付费用
export function queryPaymentAmount(data) {
  return request({
    url: "order/purchase/order/voucher/relation/query/payment/amount",
    method: "get",
    params:data
  });
}