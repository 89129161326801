var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "listContent" },
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          class: ["goodList", { actived: _vm.isActived === index }],
          on: {
            click: function ($event) {
              return _vm.selectGoods(index)
            },
          },
        },
        [
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isActived === index,
                expression: "isActived === index",
              },
            ],
            staticClass: "activeIcon el-icon-success",
          }),
          _c("div", { staticClass: "l" }, [
            _c("img", { attrs: { src: item.image, alt: "" } }),
          ]),
          _c("div", { staticClass: "r" }, [
            _c("div", [
              _c("span", { staticClass: "t" }, [_vm._v("名称")]),
              _c("span", { staticClass: "name rr" }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ]),
            _c("div", [
              _c("span", { staticClass: "t" }, [_vm._v("规格")]),
              _c("span", { staticClass: "rr" }, [
                _vm._v(_vm._s(item.specification)),
              ]),
            ]),
            _c("div", [
              _c("span", { staticClass: "t" }, [_vm._v("批准文号")]),
              _c("span", { staticClass: "rr" }, [
                _vm._v(_vm._s(item.approvalNumber)),
              ]),
            ]),
            _c("div", [
              _c("span", { staticClass: "t" }, [_vm._v("厂家")]),
              _c("span", { staticClass: "rr" }, [_vm._v(_vm._s(item.factory))]),
            ]),
            _c("div", [
              _c("span", { staticClass: "t" }, [_vm._v("售价")]),
              _c("span", { staticClass: "rr" }, [
                _vm._v("￥" + _vm._s(item.salePrice)),
              ]),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }