<template>
  <div class="comContent">
    <!-- 搜索 -->
    <div class="search">
      <div class="">
        <el-input size="small" v-model="activityName" placeholder="活动名称" clearable min-width="50px"></el-input>
      </div>
      <!-- <div class="everyItem">
        <el-input size="small"
                  v-model="input"
                  placeholder="ERP编码"
                  min-width="50px"></el-input>
      </div> -->
      <div class="everyItem">
        <el-input size="small" clearable v-model="goodsName" placeholder="商品名称" min-width="50px"></el-input>
      </div>
      <div class="everyItem">
        <el-input size="small" clearable v-model="goodsCode" placeholder="商品编号" min-width="50px"></el-input>
      </div>
      <!-- <div class="everyItem">
        <el-select v-model="checkStatus" size="small" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div> -->
      <!-- <div class="everyItem">
        <el-select v-model="value" size="small" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div> -->
      <div class="everyItem">
        <el-date-picker v-model="activeDate" size="small" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
          start-placeholder="活动开始" end-placeholder="活动结束">
        </el-date-picker>
      </div>

      <div class="everyItem">
        <el-date-picker v-model="createDate" size="small" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
          start-placeholder="添加开始" end-placeholder="添加结束">
        </el-date-picker>
      </div>
      <div class="everyItem" style="display:flex">
        <el-button size="small" type="primary" @click="getList(1)">查询</el-button>
        <el-button size="small" type="" @click="reset">重置</el-button>
      </div>
    </div>
    <!-- tab -->
    <Tabs :tabsList="tabsList" :activeName.sync="activeName" @tab-click="handleChange" />
    <div class="center" style="position:relative">
      <MyTable :table-data="tableData" :index="index" :selection="selection" :page="page" :operation="operation"
        :table-option.sync="tableOption" @page-change="getList" @handleSelectionChange="handleSelectionChange">

        <template slot="checkStatus" slot-scope="scope">
          <span :style="{ cursor: scope.row.checkStatus == 1 ? 'pointer' : '' }" @click="clickNoAgree(scope.row)">{{
            getStatus(String(scope.row.checkStatus)) }}</span>
        </template>

        <template slot="status" slot-scope="scope">
          <span>{{ getActiveStatus(String(scope.row.status)) }}</span>
        </template>

        <template slot="info" slot-scope="scope" v-if="scope.row.platformActivityGoodsInfoVO">

          <div style="display: flex;align-items: center;">
            <div style="width: 80px; height: 80px">
              <el-image style="width: 80px; height: 80px" :src="scope.row.platformActivityGoodsInfoVO.image || avater"
                :preview-src-list="[scope.row.platformActivityGoodsInfoVO.image || avater]">
              </el-image>
            </div>
            <div style="margin-left: 10px;">
              <div style="font-size: 16px;">{{ scope.row.platformActivityGoodsInfoVO.goodsName || '--' }}</div>
              <div style="font-size: 14px;color: rgb(191, 191, 191);">{{
                scope.row.platformActivityGoodsInfoVO.factory || '--' }}</div>
              <div>{{ scope.row.platformActivityGoodsInfoVO.approvalNumber || '--' }}</div>
              <div>{{ scope.row.platformActivityGoodsInfoVO.specification || '--' }}</div>
            </div>
          </div>
        </template>

        <template slot="activityPrice" slot-scope="scope">
          <div>￥{{ scope.row.activityPrice || '' }}</div>
        </template>

        <!-- <template slot="butie" slot-scope="scope" v-if="scope.row.platformActivityGoodsOrderInfoVO">
          <div>￥{{ scope.row.platformActivityGoodsOrderInfoVO.discountMoney || '0.00' }}</div>
        </template> -->

        <template slot="huodongkucun" slot-scope="scope">
          <div>活动总限购数量：{{ scope.row.inventory || '' }}</div>
          <!-- <div>单客户限购数量：{{ '4' }}</div> -->
          <div style="color: red;">剩余活动库存：{{ scope.row.residueInventory || '--' }}</div>
        </template>

        <!-- <template slot="kucun" slot-scope="scope">
          <div>库存是否同步ERP：{{ '是' }}</div>
          <div>{{ '4' }}</div>
        </template> -->

        <template slot="shuju" slot-scope="scope" v-if="scope.row.platformActivityGoodsOrderInfoVO">
          <div>采购店数：{{ scope.row.platformActivityGoodsOrderInfoVO.purchaseShopCount || 0 }}</div>
          <div>采购数量：{{ scope.row.platformActivityGoodsOrderInfoVO.purchaseGoodsCount || 0 }}</div>
          <div>采购金额（元）：{{ scope.row.platformActivityGoodsOrderInfoVO.countMoney || 0 }}</div>
        </template>

        <template slot="startTime" slot-scope="scope">
          <div>{{ scope.row.startTime || '' }}~{{ scope.row.endTime || '' }}</div>
        </template>
        <!--goodsStatus 状态：0-正常，1-下线 -->
        <template slot-scope="scope" slot="caozuo">
          <el-link type="primary" :underline="false" @click="edit(scope.row)" v-if="[0,1,3].includes(scope.row.status)">编辑</el-link>
          <!-- <el-link
            v-if="(scope.row.checkStatus == '2') && (scope.row.status == '1' || scope.row.status == '3') && scope.row.goodsStatus == '0'"
            type="primary" style="margin-left:16px" :underline="false" @click="downline(scope.row)">下线</el-link> -->
          <el-link type="primary" style="margin-left:16px" :underline="false"
          v-if="[0,1,3].includes(scope.row.status)"
            @click="deleteActivety(scope.row)">移除</el-link>
        </template>
      </MyTable>
    </div>
    <el-dialog title="移除" :visible.sync="dialogVisible" width="500px">
      <span>是否确认把此商品移除活动？移除后商品还可添加至活动中</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="deleteApplyUnion" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="编辑活动价格及库存" :visible.sync="dialogVisibleS" width="600px">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="建议价格">
          <span>￥{{ this.formInline.priceBefore }}</span>
        </el-form-item>
        <el-form-item label="活动价格" class='moneyStyle'>
          <el-input size="small" v-model="formInline.price" placeholder="活动价格"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleS = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import avater from "/public/img/default.png"
import { activityPage, updateActivityPrice, deleteApplyUnion, offlineApplySkuUnion } from '@/api/platformActivity/index'
import Tabs from '@/components/Tabs'
import MyTable from '@/components/myTable'
export default {
  props: {},
  data () {
    return {
      avater: avater,
      row: {},

      activityName: '',
      goodsName: '',
      goodsCode: '',
      checkStatus: '',
      activeDate: [],
      createDate: [],

      dialogVisible: false,
      dialogVisibleS: false,
      formInline: {
        price: '0',
        priceBefore: '0',
      },

      value: '',
      options: [
        {
          value: '0',
          label: '待开始'
        },
        {
          value: '1',
          label: '进行中'
        },
        {
          value: '2',
          label: '已结束'
        },
        {
          value: '3',
          label: '已停止'
        },
      ],
      activeName: '9',
      tabsList: [
        {
          name: '9',
          label: '全部',
          value: 0,
        },
        {
          name: '1',
          label: '未开始',
          value: 0,
        },
        {
          name: '2',
          label: '已停止',
          value: 0,
        },
        {
          name: '3',
          label: '进行中',
          value: 0,
        },
        {
          name: '4',
          label: '已结束',
          value: 0,
        }
      ],
      selectionList: [],
      operation: false,
      selection: false,
      index: false,
      tableData: [{ createTime: '12312', status: '1', checkStatus: '1' }],
      tableOption: [
        { label: '活动名称', prop: 'activityName', width: '160px' },
        // { label: '审核状态', prop: 'checkStatus', width: '100px', slot: true },
        { label: '活动状态', prop: 'status', width: '100px', slot: true },
        { label: '商品信息', prop: 'info', width: '380px', slot: true },
        { label: '活动价格', prop: 'activityPrice', width: '200px', slot: true },
        // { label: '平台补贴', prop: 'butie', width: '200px', slot: true },
        // { label: '活动库存', prop: 'huodongkucun', width: '200px', slot: true },
        { label: '销售数据', prop: 'shuju', width: '200px', slot: true },
        { label: '活动时间', prop: 'startTime', width: '200px', slot: true },
        { label: '商品添加时间', prop: 'createTime', width: '200px' },
        { label: '操作', prop: 'caozuo', width: '200px', slot: true },
      ],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50]
      }
    }
  },
  components: { Tabs, MyTable },
  async created () {
    // await this.getList()
  },
  async mounted () {
    if(this.$store.state.tabStatus.platformActivityTab){
      this.activeName = this.$store.state.tabStatus.platformActivityTab;
      await this.getList()
    }else{
      await this.getList()
    }
  },
  watch: {},
  methods: {
    // 获取状态
    getStatus (status) {
      switch (status) {
        case '0':
          return '待审核';
          break;
        case '1':
          return '未通过';
          break;
        case '2':
          return '已通过';
          break;
        default:
          return ''
          break;
      }
    },
    // 获取活动状态
    getActiveStatus (status) {
      switch (status) {
        case '0':
          return '草稿';
          break;
        case '1':
          return '待开始';
          break;
        case '2':
          return '已停止';
          break;
        case '3':
          return '进行中';
          break;
        case '4':
          return '已结束';
          break;
        default:
          return ''
          break;
      }
    },
    // 勾选的数据
    handleSelectionChange (val) {
      this.selectionList = val
    },
    // 查询列表数据
    async getList (current) {
      if (current) {
        this.page.current = current
      }
      let params = {
        current: this.page.current,
        size: this.page.size,
        activityStatus: this.activeName,
        activityName: this.activityName,
        goodsName: this.goodsName,
        goodsCode: this.goodsCode,
        checkStatus: this.checkStatus,
        strTime: this.activeDate && this.activeDate.length > 0 ? this.activeDate[0] : '',
        endTime: this.activeDate && this.activeDate.length > 0 ? this.activeDate[1] : '',
        setStrTime: this.createDate && this.createDate.length > 0 ? this.createDate[0] : '',
        setEndTime: this.createDate && this.createDate.length > 0 ? this.createDate[1] : '',
      }
      const res = await activityPage(params)
      this.tableData = res.page.records
      this.page.total = res.page.total
      this.tabsList[0].value = res.all
      this.tabsList[1].value = res.notStart
      this.tabsList[2].value = res.stopped
      this.tabsList[3].value = res.underway
      this.tabsList[4].value = res.finished

    },
    // tab切换
    handleChange (val) {
      this.$store.dispatch('tabStatus/setPlatformActivityTab',this.activeName)
      this.getList()
    },
    // 重置
    reset () {
      this.activityName = ''
      this.goodsName = ''
      this.goodsCode = ''
      this.checkStatus = ''
      this.activeDate = []
      this.createDate = []
    },
    // 编辑
    edit (row) {
      this.row = row
      this.formInline.price = row.activityPrice
      this.formInline.priceBefore = row.skuPriceMax
      console.log(this.formInline.priceBefore, 'this.formInline.priceBefore')
      this.dialogVisibleS = true
    },
    // 未通过
    clickNoAgree (row) {
      console.log(row)
      if (row.checkStatus != '1') {
        return
      }
      this.dialogVisible = true
    },
    // 编辑
    async submit () {
      if (this.formInline.price > this.formInline.priceBefore) {
        this.$message.error('价格不能大于建议价格')
        return
      }
      let params = {
        unionId: this.row.unionId,
        adjustedPrice: this.formInline.price,
        suggestPrice: this.formInline.priceBefore,
      }
      const res = await updateActivityPrice(params)
      if (res) {
        this.$message.success('操作成功')
        this.getList()
        this.dialogVisibleS = false
      }
    },
    // 下线
    async downline (row) {
      let params = {
        id: row.unionId
      }
      const res = await offlineApplySkuUnion(params.id)
      if (res) {
        this.$message.success('操作成功')
        this.getList()
      }
    },
    // 删除
    async deleteActivety (row) {
      this.dialogVisible = true
      this.row = row

    },
    // 确认删除
    async deleteApplyUnion () {
      const res = await deleteApplyUnion(this.row.unionId)
      if (res) {
        this.$message.success('操作成功')
        this.getList()
        this.dialogVisible = false
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.comContent {
  .search {
    display: flex;
    padding: 24px 0;
    border-bottom: 1px solid #ededed;

    .everyItem {
      margin-left: 8px;
    }
  }

  .center {
    height: calc(100% - 175px);
  }

  ::v-deep .el-form-item {
    width: 50%;
    margin-right: 0px;
  }

  ::v-deep .moneyStyle {
    .el-input::before {
      content: "￥"; // content: "\FFE5";
      position: absolute;
      left: 5px;
      top: 0;
      color: #595959;
      font-size: 14px;
    }

    .el-input__inner {
      padding: 0 20px !important;
    }
  }
}
</style>
