<template>
    <div class="info_box">
      <div>
        <el-form
          ref="info"
          :model="info"
          label-width="100px"
          class="ruleFormCont"
          label-position="top"
          :disabled="info.disabled"
        >
          <el-form-item label="企业类型" class="formItemOne" prop="selectNum">
            <div class="selectCont">
              <div
                v-for="(item, index) in shopList"
                :key="index"
                :class="{
                  itemCont: true, 
                  mainActive: info.shopTypeName === item.typeName,
                  mainBorder: info.shopTypeName != item.typeName,
                }"
                @click="selectBtn(item, index)"
              >
                <el-image
                  :src="getStatus(info.shopTypeName, item.typeName)"
                  style="display: flex; margin-right: 10px"
                />
                {{ item.typeName }}
              </div>
            </div>
          </el-form-item>

          <el-form-item :label="item.name" :prop="item.prop"   :rules="{required: item.require, message: `请填写${item.name}`, trigger: 'blur'}" v-for="(item,key) in info.showInputArr" :key="key">
            <el-input
              v-model="info[item.prop]"
              :placeholder="`请输入${item.name}`"
              style="width: 400px"
              :disabled="isDisabled"
            />
          </el-form-item>
          <!-- <div v-if="info.certifys.length > 0">
            <el-form-item
              label="医疗机构执业许可证编码"
              v-if="info.shopTypeCode == '1'"
              prop="licenceNo"
            >
              <el-input
                v-model="licenceNo"
                placeholder="请输入医疗机构执业许可证编码"
                style="width: 400px"
                :disabled="isDisabled"
              />
            </el-form-item>
          </div>
          <div v-if="info.certifys.length > 0">
            <el-form-item
              v-if="
                info.shopTypeCode == '2' ||
                info.shopTypeCode == '3' ||
                info.shopTypeCode == '4'
              "
              label="营业执照编码"
              prop="idNumber"
            >
              <el-input
                v-model="info.certifys[0].idNumber"
                placeholder="请输入营业执照编码"
                style="width: 400px"
                :disabled="isDisabled"
              />
            </el-form-item>
          </div>
          <el-form-item label="采购/收货人姓名" prop="concatName">
            <el-input
              v-model="info.concatName"
              placeholder="请输入采购/收货人姓名"
              style="width: 400px"
              :disabled="isDisabled"
            />
          </el-form-item>
          <el-form-item label="采购/收货人联系电话" prop="concatPhone">
            <el-input
              v-model="info.concatPhone"
              placeholder="请输入采购/收货人联系电话"
              style="width: 400px"
              :disabled="isDisabled"
            />
          </el-form-item>
          <el-form-item label="售后联系电话" prop="servicePhone">
            <el-input
              v-model="info.servicePhone"
              placeholder="请输入售后联系电话"
              style="width: 400px"
              :disabled="isDisabled"
            />
          </el-form-item> --> 
          <el-form-item
            label="经营范围"
            prop="scopeIds"
            v-if="this.info.status == 2"
          >
            <el-checkbox-group v-model="info.scopes" disabled>
              <el-checkbox
                v-for="(item, index) in info.scopes"
                :key="index + 'r'"
                :label="item"
                :name="item"
                :disabled="!isDisabled"
                >{{ item || "" }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="企业名称" prop="name">
            <el-input
              v-model="info.shopName"
              placeholder="请输入企业名称"
              style="width: 400px"
              :disabled="isDisabled"
            />
          </el-form-item>
          <el-form-item label="所在区域" prop="province">
            <el-select
              v-model="info.provinceName"
              placeholder="请选择省"
              :disabled="isDisabled"
              style="width: 126px"
              @change="selectedHandle"
            >
              <el-option
                v-for="(item, index) in provinceData"
                :key="index"
                :label="item.rname"
                :value="item.id"
              />
            </el-select>
            <el-select
              v-model="info.cityName"
              placeholder="请选择市"
              :disabled="isDisabled"
              style="margin-left: 12px; width: 126px"
              @change="selectedHandleCity"
            >
              <el-option
                v-for="(item, index) in cityData"
                :key="index"
                :label="item.rname"
                :value="item.id"
              />
            </el-select>
            <el-select
              v-model="info.areaName"
              placeholder="请选择区"
              :disabled="isDisabled"
              style="margin-left: 11px; width: 126px"
              @change="selectedHandleArea"
            >
              <el-option
                v-for="(item, index) in areaData"
                :key="index"
                :label="item.rname"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input
              v-model="info.address"
              :disabled="isDisabled"
              placeholder="请输入详细地址"
              style="width: 400px"
            />
          </el-form-item>

          <el-form-item label="发票类型" prop="invoiceType">
            <el-checkbox-group v-model="info.invoiceTypes" :disabled="isDisabled">
              <el-checkbox
                v-for="(item, index) in invoicetypeData"
                :key="index"
                :label="item.name"
                :name="item.name"
                >{{ item.name || "" }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>

        
        <div class="ruleForm">
          <template v-for="(ul, index) in certifys">
            <div :key="index" label-width="800px" style="margin-bottom: 30px">
              <!-- :prop="infoFormTwo[ul.keyTime]" -->
              <!-- slot="label" -->
              <div style="width: 600px; margin-bottom: 10px">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    color: #262626;
                  "
                >
                  <div>
                    <span
                      v-if="ul.code != '09' && ul.require"
                      style="color: red; margin-right: 10px"
                      >*</span
                    >{{ ul.code == "08" ? "开票信息" : allTypes[ul.code] }}
                  </div>
                  <div>
                    <span
                      style="margin-right: 10px; font-weight: 400; color: #999"
                      class="mainColor"
                      >有效期</span
                    >
                    <el-date-picker
                      v-model="ul.date"
                      type="daterange"
                      size="mini"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="uploadCont">
                <div :class="['imgList', { disabled: info.disabled }]">
                  <div v-for="(li, i) in ul.images" :key="i" class="imgList_li">
                    <el-image
                      class="imgList_li_img"
                      style=""
                      :src="li"
                      :preview-src-list="ul.images"
                      :disabled="isDisabled"
                    />
                    <p
                      class="imgList_li_close"
                      @click="imgDel(index, i)"
                      v-if="!isDisabled"
                    >
                      x
                    </p>
                  </div>
                  <div
                    class="imgList_li imgList_li_add"
                    @click="goUpload(ul)"
                    v-if="!isDisabled"
                  >
                    <i class="el-icon-plus" />
                  </div>
                </div>
  
                <div v-if="ul.desc" class="desc">{{ ul.desc }}</div>
                <div v-if="ul.code == '09'" class="more_input">
                  <p class="more_p" style="margin-bottom: 5px">备注</p>
                  <el-input
                    v-if="ul.code == '09'"
                    v-model="info.remarks"
                    type="txtarea"
                    :disabled="isDisabled"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
  
        <div
          class="submit-btn"
          v-if="info.status != 2 && pageType == 'qualificationDetails'"
        >
          <el-button type="primary" size="small" @click="goSubmit"
            >提交</el-button
          >
        </div>
      </div>
  
      <MyUpload
        ref="imgupload"
        :request-id="info.requestId"
        :imgType="imgType"
        @upload="uploadSubmit"
      />
    </div>
  </template>
  <script>
  import { checkZizhiImages } from "@/utils/status";
  import formUpload from "@/views/merchantsettlement/formUpload.vue";
  import MyUpload from "@/views/merchantsettlement/myupload.vue";
  
  // import top from '../index/top/index.vue'
  // import { mapGetters } from 'vuex'
  import {
    getShoparea,
    getUserarea,
    getinvoicetypeList,
    updateCertify,
  } from "@/api/merchantsettlement/index";
  import { getProvince, getCity, getArea } from "@/api/shopmange/index"; 
  // import { Message } from 'element-ui'  
  import { regionAreaTree } from "@/api/saleRule/saleF";
  // import { regionProvince, regionCity, regionArea } from '@/api/index'
  export default {
    components: {
      formUpload,
      MyUpload,
    },
    data() {
      return {
        imgType: "",
        licenceNo: "",
        provinceData: [],
        cityData: [],
        areaData: [],
        invoicetypeData: [], // 发票类型
        // 地区的列表
        areaList: [],
        // 不同身份的列表
        shopList: [],
        allTypes: {
          "01": "营业执照",
          "02": "药品经营许可证",
          "03": "食品经营许可证",
          "04": "医疗机构许可证",
          "05": "被委托人身份证复印件(正反两面)",
          "06": "授权委托书",
          "07": "第二类医疗器械经营备案凭证",
          "08": "开票信息和开户许可证",
          "09": "其他",
          "10": "质保协议",
        },
    
        info: {
          scopeIds: [],
          invoiceTypes: [],
          certifys: [],
        },
        isDisabled: false,
        infoFormTwo: [],
      };
    },
    props: {
      // info: {
      //   type: Object,
      //   default: () => {
      //     return {
      //       scopeIds: [],
      //       invoiceTypes: [],
      //       certifys: [],
      //     };
      //   },
      // },
      pageType: {
        type: String,
        default: () => {
          return "";
        },
      },
    },
    computed: {
      getStatus() {
        return (name, qiyeName) => checkZizhiImages(name, qiyeName);
      },
      certifys() {
        const { certifys = [] } = this.info;
  
        certifys.forEach((element) => {
          this.$set(element, "date", [
            element.startValidDate || "",
            element.endValidDate || "",
          ]);
          if (element.code == "04") {
            this.licenceNo = element.licenceNo;
          }
        });
  
        return certifys.map((li) => {
          return {
            ...li,
            images: li.images.map((el) => el.certityImage),
            certityType: li.images.map((el) => el.certityType),
          };
        });
      },
    },
    mounted() {
      this.getShoparea();
      this.getUserarea();
      this.getRegionAreaTree();
      this.getinvoicetypeList();
      console.log(this.pageType, "pageType");
    },
    methods: {
      selectBtn(item, index) {   
        // if (this.pageType != "qualificationDetails" &&  this.pageType != "auditCompanyDetail" ) {
        //   return;
        // } 
        this.$emit("selectBtn", item);
      },
  
      goEnd(val) {
        this.endStatus = val || false;
      },
      getInfo(info) {
        this.info = { ...info };      
        this.shopId = info.id;
        if (this.info.licenceNo) {
          this.licenceNo = this.info.licenceNo;
        }
        if (this.info.status == 2 || this.pageType != "qualificationDetails") {
          this.isDisabled = true;
        }
      },
      // 获取省市区列表
      getRegionAreaTree(leve) {
        let data = {
          level: 3,
        };
        regionAreaTree(data).then((res) => {
          if (res.code === 0) {
            this.provinceData = res.data;
            if (this.info.province) {
              this.getCityBtn(this.info.province);
            }
            if (this.info.city) {
              this.getAreaBtn(this.info.city);
            }
          }
        });
      },
      selectedHandle(val) {
        console.log(val, "val");
        this.info.cityName = "";
        this.info.areaName = "";
        this.info.province = val + "";
        this.provinceData.forEach((item) => {
          if (item.id == val) {
            this.info.provinceName = item.rname;
          }
        });
        this.getCityBtn(val);
      },
  
      getCityBtn(val) {
        getCity({
          id: val,
        }).then((res) => {
          if (res.code == 0) {
            this.cityData = res.data;
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        });
      },
      //城市的选择按钮
      selectedHandleCity(val) {
        console.log(val);
        this.info.city = val + "";
        this.cityData.forEach((item) => {
          if (item.id == val) {
            this.info.cityName = item.rname;
          }
        });
        this.info.areaName = "";
        this.getAreaBtn(val);
      },
      getAreaBtn(val) {
        getArea({
          id: val,
        }).then((res) => {
          if (res.code == 0) {
            this.areaData = res.data;
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        });
      },
      //地区的选择按钮
      selectedHandleArea(val) {
        this.info.area = val + "";
        this.areaData.forEach((item) => {
          if (item.id == val) {
            this.info.areaName = item.rname;
          }
        });
      },
      // 发票类型列表
      getinvoicetypeList() {
        getinvoicetypeList().then((res) => {
          if (res.code === 0) {
            this.invoicetypeData = res.data;
            console.log(this.invoicetypeData);
            // ;
          }
        });
      },
      getUserarea() {
        getUserarea().then((res) => {
          console.log(res);
          if (res.code === 0) {
            this.areaList = res.data;
          }
        });
      },
      // 商户经营范围
      getShoparea() {
        getShoparea(0).then((res) => {
          if (res.code === 0) {
            this.shopList = res.data || [];
            // this.numberValidateForm.typeCode = res.data[0].typeCode;
          }
        });
      },
  
      imgDel(index, i) {
        this.$confirm("此操作删除上传的图片, 是否确认操作?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          console.log(index, i);
          let dd = {
            ...this.info.certifys,
          };
          let arrs = dd[index].images;
          arrs.splice(i, 1);
        });
      },
  
      goUpload(type) {
        console.log(type);
        this.imgType = type;
        this.$refs["imgupload"].open(type);
      },
  
      // 提交修改
      uploadSubmit(obj) {
        const { type, url } = obj;
        // console.log(type);
        console.log(url);
        //   console.log("obj", obj);
        let certifys1 = this.info.certifys;
        certifys1.forEach((element) => {
          if (element.code == type.code) {
            element.images.push({
              certityImage: url,
              id: null,
              certityType: element.code,
            });
          }
        });  
        // this.infoFormTwo[type].push(url);
      },
      // 提交修改
      goSubmit(obj) {
          let flag= false
        this.info.certifys.forEach((element) => {
           
          if (element.require && element.images.length == 0) { 
            this.$message({
              type: "warning",
              message: "请上传" + element.name,
            });
            flag= true; 
          }
        });
        if(flag) {
          return
        }
        let certifyList = [];
  
        this.info.certifys.forEach((element) => {
          // console.log(element);
          let imagesArr = [];
          for (let i = 0; i < element.images.length; i++) {
            imagesArr.push(element.images[i].certityImage);
          }
          let obj = {
            code: element.code,
            // startValidDate: element.date[0],
            // endValidDate: element.date[1],
            images: imagesArr,
          };
          certifyList.push(obj);
        });
   
        let d = {
          scopeIds: this.info.scopeIds ? this.info.scopeIds.join(",") : "",
          certifys: certifyList,
          ...obj,
          // relationType: "01", // 00.商户 01.省总 02.地总 03，诊所
          // deptId: 415, // 组织机构ID
          // orgCode: "ty94qfEn", // 组织机构CODE
          // roleId: 2, // 角色待定
          // status: "2", // 1.未通过，2.通过
        };
  
        this.updateCertify(d);
      },
  
      updateCertify(obj) {
        // 处理发票类型数据 invoicetypeData
        this.info.invoiceType = "";
        this.info.invoiceTypes.map((item) => {
          for (let i = 0; i < this.invoicetypeData.length; i++) {
            if (item == this.invoicetypeData[i].name) {
              this.info.invoiceType += this.invoicetypeData[i].code + ",";
            }
          }
        });
  
        this.info.invoiceType = this.info.invoiceType.substring(
          0,
          this.info.invoiceType.lastIndexOf(",")
        );
  
        const {
          requestId,
          remarks,
          concatName,
          concatPhone,
          province,
          provinceName,
          city,
          cityName,
          area,
          areaName,
          address,
          invoiceType,
          servicePhone,
          shopName,
          shopTypeCode,
          id,
          invoiceTypes,
          licenceNo
        } = this.info;
        const {  shopId } = this;
        let d = {
          ...obj,
          requestId,
          remarks,
          licenceNo,
          concatName,
          concatPhone,
          province,
          provinceName,
          city,
          cityName,
          area,
          areaName,
          address,
          invoiceType,
          servicePhone,
          shopName,
          shopTypeCode,
          id,
          invoiceTypes,
          idNumber: this.info.certifys[0].idNumber || "",
        };
        console.log(d, "d"); 
        updateCertify(d).then((res) => {
          if (res.code === 0) {
            this.$message.success("操作成功");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            this.$message.warning(res.msg);
          }
        });
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  @import "~@/styles/variables.scss";
  @import "./styles/index.scss";
  
  </style>