const state = {
  detail: null,
  selectedCompanyTypes: null, //Array
};

const mutations = {
  SET_DETAIL: (state, load) => {
    state.detail = load;
  },
  SET_selectedCompanyTypes: (state, load) => {
    state.selectedCompanyTypes = load;
  },
};

const actions = {
  setDetail({ commit }, load) {
    commit("SET_DETAIL", load);
  },
  setSelectedCompanyTypes({ commit }, load) {
    commit("SET_selectedCompanyTypes", load);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
