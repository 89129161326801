<template>
  <div class="classification">
    <div class="eachRow">
      <div class="titleView">
        <div class="rowLine"></div>
        <span class="eachRowTitle">投诉建议</span>
      </div>
      <div class="content">
        <el-form :model="formInline"
                 label-width="80px"
                 ref="formInline"
                 class="demo-form-inline"
                 :rules="rules">
          <el-form-item prop="adviseInfo"
                        label="评价">
            <el-input type="textarea"
                      style="width: 600px;"
                      maxlength="200"
                      show-word-limit
                      v-model="formInline.adviseInfo"
                      placeholder="评价"></el-input>
          </el-form-item>
          <el-form-item label="上传图片">
            <ImgUpload v-model="imageList"
                       :limit="5"
                       style="position:relative;top:-8px">
              <template slot="tip">
                <p class="el-upload__tip"
                   style="margin:0px">
                  备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过5M
                </p>
              </template>
            </ImgUpload>
          </el-form-item>

          <el-form-item size="large">
            <el-button type="primary"
                       size="small"
                       @click="onSubmit">提交</el-button>
            <el-button size="small"
                       @click="cancle">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
  
<script>
import ImgUpload from '@/components/imgUpload'
import { page, insert, list } from '@/api/suggestion'
export default {
  props: {},
  data () {
    return {
      formInline: {
        adviseInfo: '',

      },
      imageList: [],
      item: {},
      rules: {
        adviseInfo: [
          { required: true, message: '请输入评价', trigger: 'blur' },
        ]
      },
    }
  },
  components: { ImgUpload },
  created () {

  },
  mounted () {

  },
  watch: {},
  methods: {
    onSubmit () {
      this.$refs.formInline.validate(val => {
        if (val) {
          let params = {
            source: '2',
            ...this.formInline,
            image: this.imageList.join() || ''
          }
          insert(params).then(res => {
            if (res.code == 0) {
              this.$message.success('提交成功')
              this.$router.push('/suggestion/suggestion-index')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    cancle () {
      this.$router.push('/suggestion/suggestion-index')
    }
  }
}

</script>
<style lang="scss" scoped>
.eachRow {
  // height: calc(100% - 24px);
  // overflow: auto;
  margin-top: 24px;
}
.titleView {
  display: flex;
  align-items: center;
  position: relative;

  .rowLine {
    width: 4px;
    border-color: 1px;
    background-color: var(--main-color);
    height: 16px;
    margin-right: 8px;
  }

  .eachRowTitle {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
  }
}
.content {
  padding: 16px 0;
  height: calc(100% - 51px);
  overflow: auto;
  .title {
    padding: 16px 0px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    .pingtai {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
