var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "three" }, [
    _c(
      "div",
      [
        _vm._m(0),
        _c(
          "el-button",
          {
            staticStyle: { "margin-top": "12px" },
            attrs: { size: "small" },
            on: {
              click: function ($event) {
                return _vm.returnBack()
              },
            },
          },
          [_vm._v("返回活动列表")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "center" } },
      [
        _c("img", {
          attrs: { src: require("@/assets/paysuccess.png"), alt: "" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }