var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "cooperationGoodsList" } },
    [
      _c(
        "div",
        { staticClass: "search-box" },
        [
          _c(
            "el-form",
            { attrs: { inline: true, model: _vm.searchForm } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品名称", size: "small" },
                    model: {
                      value: _vm.searchForm.goodsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "goodsName", $$v)
                      },
                      expression: "searchForm.goodsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.search },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: { click: _vm.reset },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.tableHeight
        ? _c(
            "div",
            { staticClass: "table-box" },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.list, height: _vm.tableHeight } },
                [
                  _c("el-table-column", {
                    attrs: { label: "商品信息", width: "300" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "80px",
                                          height: "80px",
                                        },
                                        attrs: {
                                          src: scope.row.goodsImage,
                                          "preview-src-list": [
                                            scope.row.goodsImage,
                                          ],
                                          lazy: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "goods-info" }, [
                                    _c("div", { staticClass: "goods-name" }, [
                                      _vm._v(_vm._s(scope.row.goodsName)),
                                    ]),
                                    _c("div", [
                                      _vm._v(_vm._s(scope.row.factory)),
                                    ]),
                                    _c("div", [
                                      _vm._v(_vm._s(scope.row.specification)),
                                    ]),
                                    _c("div", [
                                      _vm._v(_vm._s(scope.row.approvalNumber)),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        "商品编码：" +
                                          _vm._s(scope.row.goodsCode || "-")
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        "SKU编码：" +
                                          _vm._s(scope.row.skuCode || "-")
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        "ERP编码：" +
                                          _vm._s(scope.row.medicineCode || "-")
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2375066304
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品线名称",
                      prop: "productLineName",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _c("div", [
                                  _vm._v(_vm._s(scope.row.productLineName)),
                                ]),
                                _c("div", [
                                  _vm._v(_vm._s(scope.row.productLineCode)),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      359841266
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商品价格", prop: "price", width: "250" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("p", [
                                _vm._v(
                                  "价格：￥" +
                                    _vm._s(
                                      scope.row.minCooperativeIncome || "-"
                                    )
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "市场价：￥" +
                                    _vm._s(scope.row.marketPrice || "-")
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "建议零售价：￥" +
                                    _vm._s(scope.row.purchasePrice || "-")
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3446017649
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "库存", prop: "stock", width: "300" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("p", [
                                _vm._v(
                                  "总库存：" +
                                    _vm._s(scope.row.goodsSkuInventory || "0")
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "有效期至：" +
                                    _vm._s(scope.row.expireTime || "-")
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "生产日期：" +
                                    _vm._s(scope.row.producedTime || "-")
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1829290814
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "控销规则",
                      prop: "saleRule",
                      width: "300",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.areaSchemeName
                                ? _c("p", [
                                    _vm._v(
                                      "区域：" +
                                        _vm._s(scope.row.areaSchemeName || "-")
                                    ),
                                  ])
                                : _vm._e(),
                              _c("p", [
                                _vm._v(
                                  "客户类型：" +
                                    _vm._s(
                                      _vm.getShopTypeStr(scope.row.shopTypeCode)
                                    )
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "黑名单：" +
                                    _vm._s(scope.row.groupSchemeName || "-")
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4061582495
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "审核状态", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.saleStatus === 2
                                ? _c("span", [_vm._v("已审核")])
                                : scope.row.saleStatus === 3
                                ? _c("span", [_vm._v("驳回")])
                                : scope.row.saleStatus === 1
                                ? _c("span", [_vm._v("待审核")])
                                : scope.row.saleStatus === 4
                                ? _c("span", [_vm._v("已取消")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      907908689
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "申请时间",
                      prop: "applyTime",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "100",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.saleStatus === 1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleRevoke(scope.$index)
                                        },
                                      },
                                    },
                                    [_vm._v("撤回合作申请")]
                                  )
                                : _vm._e(),
                              scope.row.saleStatus === 2
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleSalesControl(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("销售控制")]
                                      ),
                                      !scope.row.goodsSkuId
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handlePublishProducts(
                                                    scope.row,
                                                    "add"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("发布商品")]
                                          )
                                        : _vm._e(),
                                      scope.row.goodsSkuId
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handlePublishProducts(
                                                    scope.row,
                                                    "edit"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("编辑商品")]
                                          )
                                        : _vm._e(),
                                      scope.row.goodsStatus
                                        ? _c(
                                            "div",
                                            [
                                              scope.row.goodsStatus ===
                                              "PUTAWAY"
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleDown(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("下架")]
                                                  )
                                                : _c(
                                                    "el-button",
                                                    {
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUp(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("上架")]
                                                  ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1169764567
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "pagination-box" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [10, 20, 30, 50, 2],
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("ProductSalesControlDialog", {
        attrs: { visible: _vm.dialogVisible, product: _vm.product },
        on: { cancel: _vm.handleCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }