var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mainView" },
    [
      _c("div", { staticClass: "flexRowCenter" }, [
        _c("div", { staticClass: "titleDiv" }, [_vm._v("销售定价")]),
        _c(
          "div",
          {
            staticClass: "inputDiv",
            staticStyle: { display: "flex", "align-items": "center" },
          },
          [
            _c(
              "el-input",
              {
                attrs: {
                  placeholder: "请输入",
                  size: "small",
                  oninput:
                    "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                },
                model: {
                  value: _vm.form.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "price", $$v)
                  },
                  expression: "form.price",
                },
              },
              [
                _c("template", { slot: "prefix" }, [
                  _c("div", { staticClass: "prefixView" }, [_vm._v("¥")]),
                ]),
              ],
              2
            ),
            _c(
              "span",
              {
                staticStyle: { "margin-left": "10px" },
                on: {
                  click: function ($event) {
                    return _vm.openDaliog()
                  },
                },
              },
              [
                _c(
                  "el-tag",
                  {
                    staticStyle: { border: "1px solid #F5222D" },
                    attrs: { type: "primary", size: "medium" },
                  },
                  [_vm._v(" " + _vm._s(_vm.form.goodsSkuUnit))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "flexRowCenter" }, [
        _c("div", { staticClass: "flexRowCenter" }, [
          _c("div", { staticClass: "titleDiv" }, [_vm._v("合作医药公司")]),
          _c(
            "div",
            { staticClass: "inputDiv" },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "small" },
                  on: {
                    change: (val) => {
                      _vm.reservationChange(val, 1)
                    },
                  },
                  model: {
                    value: _vm.form.shopName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "shopName", $$v)
                    },
                    expression: "form.shopName",
                  },
                },
                _vm._l(_vm.medicineManager, function (item) {
                  return _c("el-option", {
                    key: item.shopId,
                    attrs: { label: item.shopName, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "flexRowCenter" }, [
          _c("div", { staticClass: "titleDiv" }, [_vm._v("医药公司康贝")]),
          _c(
            "div",
            { staticClass: "inputDiv" },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "small" },
                  on: {
                    change: (val) => {
                      _vm.reservationChange(val, 1)
                    },
                  },
                  model: {
                    value: _vm.form.merchantNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "merchantNo", $$v)
                    },
                    expression: "form.merchantNo",
                  },
                },
                _vm._l(_vm.form.merchantNoArr, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "flexRowCenter" }, [
          _c("div", { staticClass: "titleDiv" }, [_vm._v("销售金额")]),
          _c(
            "div",
            { staticClass: "inputDiv" },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入",
                  size: "small",
                  oninput:
                    "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                },
                model: {
                  value: _vm.form.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "amount", $$v)
                  },
                  expression: "form.amount",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "flexRowCenter" }, [
        _c("div", { staticClass: "flexRowCenter" }, [
          _c("div", { staticClass: "titleDiv" }, [_vm._v("地区公司名称")]),
          _c(
            "div",
            { staticClass: "inputDiv" },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "small", clearable: "" },
                  on: {
                    change: (val) => {
                      _vm.reservationChange(val, 2)
                    },
                  },
                  model: {
                    value: _vm.form.city_shopName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "city_shopName", $$v)
                    },
                    expression: "form.city_shopName",
                  },
                },
                _vm._l(_vm.cityManager, function (item, index) {
                  return _c("el-option", {
                    key: item.shopId + index,
                    attrs: { label: item.shopName, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "flexRowCenter" }, [
          _c("div", { staticClass: "titleDiv" }, [_vm._v("地区公司康贝")]),
          _c(
            "div",
            { staticClass: "inputDiv" },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "small", clearable: "" },
                  on: {
                    change: (val) => {
                      _vm.reservationChange(val, 2)
                    },
                  },
                  model: {
                    value: _vm.form.city_merchantNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "city_merchantNo", $$v)
                    },
                    expression: "form.city_merchantNo",
                  },
                },
                _vm._l(_vm.form.city_merchantNoArr, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "flexRowCenter" }, [
          _c("div", { staticClass: "titleDiv" }, [_vm._v("服务费金额")]),
          _c(
            "div",
            { staticClass: "inputDiv" },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入",
                  clearable: "",
                  size: "small",
                  disabled: !_vm.form.city_shopId,
                  oninput:
                    "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                },
                model: {
                  value: _vm.form.city_amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "city_amount", $$v)
                  },
                  expression: "form.city_amount",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "flexRowCenter" }, [
        _c("div", { staticClass: "flexRowCenter" }, [
          _c("div", { staticClass: "titleDiv" }, [_vm._v("省区公司名称")]),
          _c(
            "div",
            { staticClass: "inputDiv" },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "small" },
                  on: {
                    change: (val) => {
                      _vm.reservationChange(val, 3)
                    },
                  },
                  model: {
                    value: _vm.form.serviceShopName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "serviceShopName", $$v)
                    },
                    expression: "form.serviceShopName",
                  },
                },
                _vm._l(_vm.provinceManager, function (item) {
                  return _c("el-option", {
                    key: item.shopId,
                    attrs: { label: item.shopName, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "flexRowCenter" }, [
          _c("div", { staticClass: "titleDiv" }, [_vm._v("省公司康贝")]),
          _c(
            "div",
            { staticClass: "inputDiv" },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "small" },
                  on: {
                    change: (val) => {
                      _vm.reservationChange(val, 3)
                    },
                  },
                  model: {
                    value: _vm.form.serviceMerchantNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "serviceMerchantNo", $$v)
                    },
                    expression: "form.serviceMerchantNo",
                  },
                },
                _vm._l(_vm.form.serviceMerchantNoArr, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "flexRowCenter" }, [
          _c("div", { staticClass: "titleDiv" }, [_vm._v("地区需转省区公司")]),
          _c(
            "div",
            { staticClass: "inputDiv" },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入",
                  size: "small",
                  oninput:
                    "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                },
                model: {
                  value: _vm.form.serviceAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "serviceAmount", $$v)
                  },
                  expression: "form.serviceAmount",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticStyle: { height: "30px" } }),
      _c("div", { staticClass: "flexRowCenter" }, [
        _c("div", { staticClass: "flexRowCenter" }),
        _c("div", { staticClass: "flexRowCenter" }),
        _c("div", { staticClass: "flexRowCenter" }, [
          _c(
            "div",
            { staticClass: "flexEnd" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.saveInfo },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "default" },
                  on: { click: _vm.backPage },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "选择单位",
            visible: _vm.dialogVisible,
            width: "400px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content-box" },
            _vm._l(_vm.unitList, function (item, key) {
              return _c(
                "span",
                {
                  key: key,
                  class: _vm.unitKey == key ? "unit active" : "unit",
                  on: {
                    click: function ($event) {
                      return _vm.choseUnit(item, key)
                    },
                  },
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.choseUnitSure },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }