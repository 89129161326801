<template>
  <div class="comContent">
    <!-- <div class="left_topic mb20" style="margin-top: 10px">控销策略</div> -->
    <div class="content">
      <div class="structure">
        <div class="searchArea"
             style="justify-content: space-between;padding: 0px;">
          <span class="tit">经销架构</span>
          <el-input class="searchIpt"
                    placeholder="请输入架构名称"
                    suffix-icon="el-icon-search"
                    size="mini"
                    style="visibility: auto"
                    v-model="treeVal1">
          </el-input>
        </div>
        <div class="treeBox">
          <el-tree node-key="id"
                   ref="tree1"
                   :data="organizationData"
                   :highlight-current="true"
                   @node-click="getoutCheckKey"
                   :props="defaultProps"
                   :filter-node-method="filterNode"></el-tree>
        </div>
        <div class="footer">
          <el-button type="primary"
                     size="small"
                     @click="showOutDialog">区域设置</el-button>
        </div>
      </div>
      <div class="resultArea">
        <div class="resultHearder">{{ resultHearderTit || ' ' }} </div>
        <div class="resultContent">
          <div class="contentItemMini item">
            <div class="title">选择团队人员</div>
            <div class="searchArea">
              <!-- <span class="tit">经销架构</span> -->
              <el-input class="searchIpt"
                        placeholder="请输入人员名称"
                        suffix-icon="el-icon-search"
                        size="mini"
                        style="visibility: auto"
                        v-model="teamName">
              </el-input>
            </div>
            <div class="list">
              <div class="listContent">
                <ul>
                  <li v-for="(item, index) in teamList"
                      :class="{ selLi: selTeam === item.id }"
                      @click="selItem(item.id, item.name, index)"
                      :key="item.id">
                    <div class="item">
                      <div class="nameBox">
                        <div class="name">{{ item.name }}</div>
                        <div class="name">{{ item.teamOrgName }}</div>
                      </div>
                      <div class="desc">
                        {{ item.userRole == 1 ? "经理" : "业务员" }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer">
              <el-button type="primary"
                         size="small"
                         @click="addTeam">添加</el-button>
              <el-button type="primary"
                         size="small"
                         @click="delTeamFun">删除</el-button>
            </div>
          </div>
          <div class="contentItem item">
            <div class="title">分配买家</div>
            <div class="searchArea">
              <span class="tit"
                    style="font-size: 14px;">未被分配买家</span>
              <el-input class="searchIpt"
                        placeholder="请输入买家名称"
                        suffix-icon="el-icon-search"
                        size="mini"
                        style="visibility: auto"
                        v-model="buyerVal1">
              </el-input>
            </div>
            <div class="list">
              <div class="listContent">
                <el-checkbox-group v-model="checkList">
                  <ul>
                    <li v-for="(item, index) in buyerList"
                        :key="item.shopId">
                      <el-checkbox :label="index"><br /></el-checkbox>
                      <div class="item">
                        <div class="nameBox">
                          <div class="name">{{ item.shopName }}</div>
                        </div>
                        <div class="desc">{{ item.areaName }}</div>
                      </div>
                    </li>
                  </ul>
                </el-checkbox-group>
              </div>
            </div>
            <div class="footer"
                 style="justify-content: space-between;">
              <div class="num">终端数({{ buyerList.length }})</div>
              <el-button type="primary"
                         size="small"
                         @click="teamTerminalsBatchFun">确认分配</el-button>
            </div>
          </div>
          <div class="contentItem item">
            <div class="title">已分配买家</div>
            <div class="searchArea">
              <span class="tit" v-if="assignbuyName">{{ assignbuyName }}</span>
              <el-input class="searchIpt"
                        placeholder="请输入买家名称"
                        suffix-icon="el-icon-search"
                        size="mini"
                        style="visibility: auto"
                        v-model="buyerVal2">
              </el-input>
            </div>
            <div class="list">
              <div class="listContent">
                <el-checkbox-group v-model="assignedCheckList">
                  <ul>
                    <li v-for="item in assignedBuyerList"
                        :key="item.id">
                      <el-checkbox :label="item.id"><br /></el-checkbox>
                      <div class="item">
                        <div class="nameBox">
                          <div class="name">{{ item.shopName }}</div>
                          <div class="price">
                            <span>单次限购</span><span>{{ item.singleMaxBuyQuantity }}</span>
                          </div>
                          <div class="price">
                            <span>单月限次</span><span>{{ item.monthMaxBuyTimes }}</span>
                          </div>
                          <div class="editIcon"
                               @click="toEditPrice(item.id)">
                            <i class="el-icon-edit"></i>
                          </div>
                        </div>
                        <div class="desc">{{ item.areaName }}</div>
                      </div>
                    </li>
                  </ul>
                </el-checkbox-group>
              </div>
            </div>
            <div class="footer">
              <el-button size="small"
                         type="primary"
                         @click="teamTerminalsBatchDelFun">取消分配</el-button>
              <el-button type="primary"
                         size="small"
                         @click="openUpdateQuantityDialog">采购定量</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="区域设置"
               :visible.sync="showEditDialog"
               width="60%"
               :close-on-click-modal="false"
               :before-close="outDialogClose">
      <el-dialog width="30%"
                 :title="insideTit"
                 :visible.sync="showInsideDialog"
                 append-to-body
                 :close-on-click-modal="false"
                 :before-close="handleClose">
        <div class="editArea">
          <el-form :model="dialogForm"
                   status-icon
                   :rules="dialogRules"
                   ref="dialogForm"
                   label-width="100px"
                   class="demo-ruleForm">
            <el-form-item label="组织架构"
                          prop="name">
              <el-input v-model="dialogForm.name"
                        maxlength="10"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary"
                         @click="addNode('dialogForm')">提交</el-button>
              <el-button type="primary"
                         @click="resetForm('dialogForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
      <div class="dialogContent">
        <div class="cLeft item">
          <div class="cHeader">
            <div class="tit">组织架构</div>
            <div class="ipt">
              <el-input size="small"
                        placeholder="请您输入组织名称"
                        suffix-icon="el-icon-search"
                        style="visibility: auto"
                        v-model="zhTreeVal" />
            </div>
            <el-button type="primary"
                       size="small"
                       @click="editNode(1)">添加</el-button>
            <el-button type="primary"
                       size="small"
                       @click="editNode(2)">编辑</el-button>
            <el-button type="primary"
                       size="small"
                       @click="editNode(3)">删除</el-button>
          </div>
          <div class="dialogtree">
            <el-tree ref="zhtree"
                     :data="organizationData"
                     node-key="id"
                     :highlight-current="true"
                     :default-expand-all="true"
                     @node-click="getNodeKey"
                     :props="defaultProps"
                     :filter-node-method="filterNode">
            </el-tree>
          </div>
        </div>
        <div class="cRight item">
          <div class="cHeader">
            <div class="tit">销售地域</div>
            <el-input size="mini"
                      placeholder="请输入地域名称"
                      suffix-icon="el-icon-search"
                      style="visibility: auto"
                      v-model="cityVal" />
          </div>
          <div class="dialogtree">
            <el-tree ref="cityTree"
                     show-checkbox
                     node-key="id"
                     :default-expanded-keys="expandedKeys"
                     :default-checked-keys="checkedKeys"
                     :data="cityData"
                     :props="cityDefaultProps"
                     :filter-node-method="filterNode"></el-tree>
          </div>
        </div>
      </div>
      <div class="outDialogBtn"
           slot="footer">
        <el-button size="small"
                   @click="outDialogClose">取消</el-button>
        <el-button type="primary"
                   size="small"
                   @click="teamOrgAreaRelationsBatchFun">确认</el-button>
      </div>
    </el-dialog>
    <!-- 选择团队成员新增弹框 -->
    <el-dialog width="30%"
               title="添加团队成员"
               :visible.sync="addTeamDialog"
               :close-on-click-modal="false">
      <div class="teamDialogContent">
        <el-form :model="addTeamForm"
                 :rules="addTeamRules"
                 ref="addTeamForm"
                 label-width="80px">
          <el-form-item label="姓名"
                        prop="name">
            <el-input v-model="addTeamForm.name"
                      max="8"></el-input>
          </el-form-item>
          <el-form-item label="手机号"
                        prop="phone">
            <el-input v-model="addTeamForm.phone"
                      maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="角色"
                        prop="userRole">
            <el-select v-model="addTeamForm.userRole"
                       placeholder="请选择角色">
              <el-option label="业务员"
                         value="0"></el-option>
              <el-option label="经理"
                         value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="resetForm('addTeamForm')">取消</el-button>
            <el-button type="primary"
                       @click="submitAddTeam('addTeamForm')">确认</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog width="30%"
               title="采购定量"
               :visible.sync="updateQuantityDialog"
               :close-on-click-modal="false">
      <div class="teamDialogContent">
        <el-form :model="updateQuantityForm"
                 :rules="updateQuantityRules"
                 ref="updateQuantityForm"
                 label-width="120px">
          <el-form-item label="单次采购定量"
                        prop="quantity">
            <el-input size="small"
                      type="number"
                      oninput="
                if (value.length > 4) {
                  value = value.slice(0, 4);
                }
                value = value.replace(/[^0-9]/g, '');
              "
                      v-model="updateQuantityForm.quantity"></el-input>
          </el-form-item>
          <el-form-item label="月度采购次数"
                        prop="monthMaxBuyTimes">
            <el-input size="small"
                      type="number"
                      oninput="
                if (value.length > 2) {
                  value = value.slice(0, 2);
                }
                value = value.replace(/[^0-9]/g, '');
              "
                      v-model="updateQuantityForm.monthMaxBuyTimes"></el-input>
          </el-form-item>
          <el-form-item style="margin-top: 20px">
            <el-button @click="resetForm('updateQuantityForm')">取消</el-button>
            <el-button type="primary"
                       @click="submitQuantity('updateQuantityForm')">确认</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  teamOrgsProductLine,
  regionAreaTree,
  teamOrgAreaRelationsOrgArea,
  addTeamOrgs,
  editTeamOrgs,
  delTeamOrgs,
  teamMembersListAllSons,
  addTeamMembers,
  delTeamMembers,
  teamTerminalsList,
  teamTerminals,
  teamOrgAreaRelationsBatch,
  teamTerminalsBatch,
  teamTerminalsBatchDel,
  batchUpdateQuantity,
} from "@/api/saleRule/saleF";
import { validatemobile } from "@/utils/validate";
const debounce = (function () {
  let timer = 0;
  return function (func, delay) {
    clearTimeout(timer);
    timer = setTimeout(func, delay);
  };
})();
export default {
  data () {
    return {
      resultHearderTit: "",
      organizationData: [],
      teamList: [], //团队人员列表
      teamTempList: [],
      buyerList: [], //分配买家列表
      buyerTempList: [],
      assignedBuyerList: [], //已分配买家列表
      assignedBuyerTempList: [], //已分配买家列表
      checkNodeDetail: {
        id: null,
        name: "",
      },
      checkList: [],
      assignedCheckList: [],
      showEditDialog: false, // 组织架构外层弹框
      showInsideDialog: false, // 编辑节点弹框
      updateQuantityDialog: false, //采购定量
      addTeamForm: {
        name: "",
        phone: "",
        userRole: null,
        teamId: null,
        teamOrgId: null,
        teamOrgName: "",
      },
      dialogForm: {
        //添加编辑组织名字
        name: "",
        id: null,
      },
      updateQuantityForm: {
        quantity: "",
        monthMaxBuyTimes: "",
      },
      dialogRules: {
        //添加编辑组织架构校验
        name: [
          { required: true, message: "请输入组织架构名称", trigger: "blur" },
        ],
      },
      addTeamRules: {
        name: [{ required: true, message: "请输入成员姓名", trigger: "blur" }],
        // phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        phone: [{ required: true, validator: validatemobile, trigger: "blur" }],
        userRole: [
          { required: true, message: "请选择角色", trigger: "change" },
        ],
      },
      updateQuantityRules: {
        quantity: [
          { required: true, message: "请输入每次最大购买量", trigger: "blur" },
        ],
        monthMaxBuyTimes: [
          {
            required: true,
            message: "请输入每月最大采购次数",
            trigger: "blur",
          },
        ],
      },
      insideTit: "", //内层弹框标题
      defaultProps: {
        children: "children",
        label: "name",
      },
      cityDefaultProps: {
        children: "children",
        label: "rname",
      },
      structure: "",
      expandedKeys: [],
      checkedKeys: [],
      cityData: [],
      formStatus: 0,
      addTeamDialog: false,
      selTeam: null,
      assignbuyName: "",
      pageId: null,
      selTeamIndex: 0,
      // 搜索框value
      treeVal1: "",
      zhTreeVal: "",
      cityVal: "",
      teamName: "",
      buyerVal1: "",
      buyerVal2: "",
    };
  },
  watch: {
    treeVal1 (val) {
      debounce(() => {
        this.$refs.tree1.filter(val);
      }, 200);
    },
    zhTreeVal (val) {
      debounce(() => {
        this.$refs.zhtree.filter(val);
      }, 200);
    },
    cityVal (val) {
      debounce(() => {
        this.$refs.cityTree.filter(val);
      }, 500);
    },
    teamName (val) {
      debounce(() => {
        this.filterList(val, 1);
      }, 100);
    },
    buyerVal1 (val) {
      debounce(() => {
        this.filterList(val, 2);
      }, 100);
    },
    buyerVal2 (val) {
      debounce(() => {
        this.filterList(val, 3);
      }, 100);
    },
  },
  created () {
    this.pageId = this.$route.query.id;
    this.cityData = JSON.parse(localStorage.getItem("cityData"));
    this.getTeamOrgsProductLine();
    // this.getRegionAreaTree();
  },
  methods: {
    //树过滤不查找下级
    filterNode (value, data) {
      if (!value) return true;
      return data.name
        ? data.name.indexOf(value) !== -1
        : data.rname.indexOf(value) !== -1;
    },
    // // 树过滤包含下级
    // filterNode(value, data, node) {
    //   console.log(value, data, node);
    //   if (!value) return true;
    //   let parentNode = node.parent,
    //   labels = [node.label],
    //   level = 1;
    //   while (level < node.level) {
    //     labels = [...labels, parentNode.label];
    //     parentNode = parentNode.parent;
    //     level++;
    //   }
    //   return labels.some((label) => label.indexOf(value) !== -1);
    // },
    // 普通列表过滤
    filterList (val, type) {
      if (+type === 1) {
        this.teamList = this.teamTempList.filter((item) => {
          return item.name.indexOf(val) !== -1;
        });
      } else if (+type === 2) {
        this.buyerList = this.buyerTempList.filter((item) => {
          return item.shopName.indexOf(val) !== -1;
        });
      } else if (+type === 3) {
        this.assignedBuyerList = this.assignedBuyerTempList.filter((item) => {
          return item.shopName.indexOf(val) !== -1;
        });
      }
    },
    // 一些数据初始化
    someValInit (type) {
      this.buyerList = [];
      this.buyerTempList = [];
      this.assignedBuyerList = [];
      this.assignedBuyerTempList = [];
      (this.buyerVal1 = ""), (this.buyerVal2 = "");
    },
    // 获取省市区列表
    // getRegionAreaTree() {
    //   let data = {
    //     level: 3,
    //   };
    //   regionAreaTree(data).then((res) => {
    //     if (+res.code === 0) {
    //       this.cityData = res.data;
    //     }
    //   });
    // },
    // 根据组织架构获取团队成员
    getTeamMembersListAllSons (data) {
      teamMembersListAllSons(data).then((res) => {
        this.teamList = res;
        this.teamTempList = res;
        this.teamName = "";
        this.someValInit();
      });
    },
    // 获取组织架构
    getTeamOrgsProductLine () {
      // this.pageId = "1650735345936277506";
      let data = {
        id: this.pageId,
      };
      teamOrgsProductLine(data).then((res) => {
        this.organizationData = res;
      });
    },
    // 根据组织id 获取城市列表
    getTeamOrgAreaRelationsOrgArea (data) {
      teamOrgAreaRelationsOrgArea(data).then((res) => {
        let arr = this.fittlerCityId(res);
        this.$nextTick(() => {
          this.expandedKeys = arr;
          // this.pollingKey(arr, 100);
          this.$refs.cityTree.setCheckedKeys(arr);
        });
      });
    },
    // 轮询
    pollingKey (list, size) {
      let n = 1;
      let newList = [];
      if (list.length > size) {
        let Time = setInterval(() => {
          if (n * size > list.length) {
            this.expandedKeys = newList;
            clearInterval(Time);
          } else {
            newList = newList.concat(list.slice(newList.length, n * size));
            n++;
            console.log(newList, list.length);
          }
        }, 200);
      } else {
        this.expandedKeys = list;
      }
    },
    // 过滤选中城市id数组
    fittlerCityId (data) {
      let arr = [];
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          arr.push(data[i].areaCode);
        }
      }
      return arr;
    },
    // 点击组织架构树node节点
    getNodeKey (e) {
      this.checkNodeDetail = e;
      let data = {
        teamId: e.teamId,
        teamOrgId: e.id,
        size: 10000,
      };
      this.getTeamOrgAreaRelationsOrgArea(data);
    },
    // 点击外层的组织架构树
    getoutCheckKey (e) {
      let data = {
        teamId: e.teamId,
        teamOrgId: e.id,
      };
      let titList = this.treeFindPath(
        this.organizationData,
        (data) => data.name === e.name
      );
      this.resultHearderTit =
        titList.length > 1 ? titList.join("-") : titList[0];
      this.addTeamForm.teamId = e.teamId;
      this.addTeamForm.teamOrgId = e.id;
      this.addTeamForm.teamOrgName = e.name;
      this.getTeamMembersListAllSons(data);
    },
    // 根据name获取上级所有父节点name
    treeFindPath (tree, func, path = []) {
      if (!tree) return [];
      for (const data of tree) {
        // 这里按照你的需求来存放最后返回的内容吧
        path.push(data.name);
        if (func(data)) return path;
        if (data.children) {
          const findChildren = this.treeFindPath(data.children, func, path);
          if (findChildren.length) return findChildren;
        }
        path.pop();
      }
      return [];
    },
    editNode (state) {
      this.formStatus = state;
      this.dialogForm = JSON.parse(JSON.stringify(this.checkNodeDetail));
      if (!this.checkNodeDetail.id || this.checkNodeDetail.id === null) {
        this.failedMessage();
        return false;
      }
      if (state === 1) {
        //添加
        this.insideTit = "新增";
        this.showInsideDialog = true;
        this.dialogForm.name = "";
      } else if (state === 2) {
        //编辑
        this.insideTit = "编辑";
        this.showInsideDialog = true;
      } else {
        //删除
        let data = {
          id: this.checkNodeDetail.id,
        };
        if (this.checkNodeDetail.parentId == "0") {
          this.$message({
            message: "顶级组织不可被删除！",
            type: "warning",
          });
          return false;
        }
        this.openMessageBox(data, 2);
      }
    },
    addNode (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (+this.formStatus === 1) {
            let data = {
              parentId: this.dialogForm.id,
              teamId: this.dialogForm.teamId || this.pageId,
              name: this.dialogForm.name,
            };
            this.addTeamOrgsFun(data);
          } else if (+this.formStatus === 2) {
            this.editTeamOrgsFun(this.dialogForm);
          }
          this.showInsideDialog = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
      this.addTeamDialog = false;
      this.updateQuantityDialog = false;
    },
    handleClose () {
      // this.$refs["dialogForm"].resetFields();
      this.$data.dialogForm = this.$options.data().dialogForm;
      this.showInsideDialog = false;
    },
    outDialogClose () {
      this.$nextTick(() => {
        this.$data.checkNodeDetail = this.$options.data().checkNodeDetail;
        this.$refs.zhtree.setCurrentKey(null);
        this.$refs.cityTree.setCheckedKeys([]);
        this.expandedKeys = null;
        this.cityData = JSON.parse(JSON.stringify(this.cityData));
      });
      this.showEditDialog = false;
    },
    showOutDialog () {
      this.showEditDialog = true;
    },
    // 添加组织
    addTeamOrgsFun (data) {
      addTeamOrgs(data).then((res) => {
        this.openMessage(1);
      });
    },
    // 编辑组织
    editTeamOrgsFun (data) {
      editTeamOrgs(data).then((res) => {
        this.openMessage(2);
      });
    },
    // 删除组织
    delTeamOrgsFun (data) {
      delTeamOrgs(data).then((res) => {
        this.openMessage(3);
      });
    },
    // 添加团队成员
    addTeamMembersFun (data) {
      let obj = {
        teamId: data.teamId,
        teamOrgId: data.teamOrgId,
      };
      addTeamMembers(data).then((res) => {
        this.addTeamDialog = false;
        this.resetForm("addTeamForm");
        this.getTeamMembersListAllSons(obj);
      });
    },
    // 确认添加团队成员
    submitAddTeam (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.addTeamForm);
          this.addTeamMembersFun(data);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 点击团队人员添加按钮
    addTeam () {
      if (!this.addTeamForm.teamId || this.addTeamForm.teamId === null) {
        this.failedMessage();
        return false;
      }
      this.addTeamDialog = true;
    },
    // 点击删除团队成员
    delTeamFun () {
      if (!this.selTeam || this.selTeam === null) {
        this.$message({
          message: "请先选择团队成员",
          type: "warning",
        });
        return false;
      }
      let data = {
        id: this.selTeam,
      };
      this.openMessageBox(data, 1);
    },
    // 消息弹框
    openMessage (state) {
      let msg = "";
      msg = +state === 1 ? "添加成功" : +state === 2 ? "修改成功" : "删除成功";
      this.$message({
        message: msg,
        type: "success",
      });
      this.getTeamOrgsProductLine();
    },
    failedMessage () {
      this.$message({
        message: "请先选择组织架构",
        type: "warning",
      });
    },
    selItem (
      id = this.selTeam,
      name = this.assignbuyName,
      index = this.selTeamIndex
    ) {
      this.selTeamIndex = index;
      this.selTeam = id;
      this.assignbuyName = name;
      this.someValInit();
      this.getTeamTerminalsList({ id: id });
      this.getTeamTerminals({ teamMemberId: id, size: 999, status: 0 });
      this.checkList = [];
    },
    // 未分配买家列表
    getTeamTerminalsList (data) {
      teamTerminalsList(data).then((res) => {
        if (res.code && res.code != 0) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          this.buyerList = [];
        } else {
          this.buyerList = res;
          this.buyerTempList = res;
        }
      });
    },
    // 已分配买家列表
    getTeamTerminals (data) {
      teamTerminals(data).then((res) => {
        if (res.code && res.code != 0) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          this.assignedBuyerList = [];
        } else {
          this.assignedBuyerList = res;
          this.assignedBuyerTempList = res;
        }
      });
    },
    //批量取消分配
    teamTerminalsBatchDelFun () {
      if (!this.assignedCheckList || this.assignedCheckList.length === 0) {
        this.$message({
          message: "请先选择修改对象",
          type: "warning",
        });
        return false;
      }
      teamTerminalsBatchDel(this.assignedCheckList).then((res) => {
        this.selItem();
      });
    },
    // 打开采购定量弹框
    openUpdateQuantityDialog () {
      if (!this.assignedCheckList || this.assignedCheckList.length === 0) {
        this.$message({
          message: "请先选择修改对象",
          type: "warning",
        });
        return false;
      }
      this.updateQuantityDialog = true;
    },
    // 点击采购定量单个编辑
    toEditPrice (id) {
      this.assignedCheckList = [id];
      this.updateQuantityDialog = true;
    },
    // 批量采购定量
    batchUpdateQuantityFun (data) {
      batchUpdateQuantity(data).then((res) => {
        this.getTeamTerminals({
          teamMemberId: this.selTeam,
          size: 999,
          status: 0,
        });
        this.assignedCheckList = [];
        this.updateQuantityDialog = false;
        this.resetForm("updateQuantityForm");
      });
    },
    submitQuantity (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            idList: this.assignedCheckList,
            ...this.updateQuantityForm,
          };
          this.batchUpdateQuantityFun(data);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 确认弹框
    openMessageBox (data, state) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (state === 1) {
            delTeamMembers(data).then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              let obj = {
                teamId: this.addTeamForm.teamId,
                teamOrgId: this.addTeamForm.teamOrgId,
              };
              this.getTeamMembersListAllSons(obj);
              this.someValInit();
            });
          } else {
            this.delTeamOrgsFun(data);
          }
        })
        .catch(() => { });
    },
    // 组织地区关联确认
    teamOrgAreaRelationsBatchFun () {
      let list = this.getCityCheckedNodes();
      let data = {
        teamId: this.checkNodeDetail.teamId,
        teamOrgId: this.checkNodeDetail.id,
        areaVoList: list,
        size: 10000,
      };
      teamOrgAreaRelationsBatch(data).then((res) => {
        this.$message({
          message: "关联成功",
          type: "success",
        });
        this.outDialogClose();
      });
    },
    // 获取批量已选的分配的数组
    getTeamTerminalsBatch () {
      let list = [];
      for (let i = 0; i < this.checkList.length; i++) {
        list.push(this.buyerList[this.checkList[i]]);
        list[i].teamId = this.teamList[this.selTeamIndex].teamId;
        list[i].teamOrgId = this.teamList[this.selTeamIndex].teamOrgId;
        list[i].teamMemberId = this.teamList[this.selTeamIndex].id;
        list[i]["productLineId"] = this.pageId;
      }
      return list;
    },
    // 批量分配
    teamTerminalsBatchFun () {
      if (this.checkList && this.checkList.length > 0) {
        let list = this.getTeamTerminalsBatch();
        teamTerminalsBatch(list).then((res) => {
          this.selItem();
        });
      } else {
        this.$message({
          message: "请先选择分配买家",
          type: "warning",
        });
      }
    },
    // 获取选中节点需要的关联参数
    getCityCheckedNodes () {
      let c_list = this.$refs.cityTree.getCheckedNodes();
      let areaVoList = [];
      if (c_list && c_list.length > 0) {
        for (let i = 0; i < c_list.length; i++) {
          areaVoList.push({
            areaCode: c_list[i].id,
            areaName: c_list[i].rname,
          });
        }
      }
      return areaVoList;
    },
  },
};
</script>
<style lang="scss" scoped>
.comContent {
  padding: 0px;
  background: none;
}
.content {
  display: -webkit-flex;
  display: flex;
  height: 100%;
    .searchArea {
    display: -webkit-flex;
    display: flex;
    font-size: 12px;
    color: #333;
    align-items: center;
    height: 50px;
    padding: 0 10px ;
    border-bottom: 1px solid #dbdbdb;
    .tit {
      font-size: 16px;
      flex-shrink: 0;
      margin-right: 10px;
    }
  }
  .structure {
    padding: 8px 10px;
    width: 260px;
    background: #ffffff;
    .footer {
      border: 1px solid #dbdbdb;
      border-top: none;
      border-radius: 0 0 4px 4px;
    }
    .treeBox {
      border-radius: 4px 4px 0 0;
      padding-top: 10px;
      height: calc(100vh - 257px);
      overflow-y: auto;
      scrollbar-width: none; /* Firefox隐藏滚动条 */
      -ms-overflow-style: none; /* Internet Explorer 10+隐藏滚动条 */
      border: 1px solid #dbdbdb;
      // border-top: none;
      ::-webkit-scrollbar {
        width: 0; /* Safari,Chrome 隐藏滚动条 */
        height: 0; /* Safari,Chrome 隐藏滚动条 */
        display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
      }
    }
     .searchArea {
      border-bottom: none;
     }
  }
  ::v-deep
    .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    color: #f5222d;
    font-weight: bold;
    background-color: #fff !important;
  }

  .resultArea {
    -webkit-flex: 1;
    flex: 1;
    margin-left: 10px;
    padding: 8px 10px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    .resultHearder {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #303133;  
       margin-bottom:10px ;
    }
    .resultContent {
      display: -webkit-flex;
      display: flex;
      justify-content: space-between;
      flex: 1;
      height: calc(100% - 50px);
      .contentItem {
        border-radius: 4px;
        flex: 0 0 36%;
        border: 1px solid #dbdbdb;
      }
      .contentItemMini {
        border-radius: 4px;
        flex: 0 0 25%;
        border: 1px solid #dbdbdb;
      }
      .item {
        display: flex;
        flex-direction: column;
        .list {
          height: calc(100vh - 337px);
          overflow-y: auto;
          padding: 10px;
          .listContent {
            ul {
              list-style: none;
              margin: 0;
              padding: 0;
              li {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                border-radius: 2px;
                .item {
                  flex: 1;
                  padding: 4px;
                  box-sizing: border-box;
                  .nameBox {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .name {
                      color: #333;
                      font-size: 14px;
                    }
                    .price {
                      color: rgb(245, 154, 35);
                      font-size: 14px;
                    }
                    .editIcon {
                      font-size: 16px;
                      cursor: pointer;
                    }
                  }
                  .desc {
                    margin-top: 10px;
                    color: #aaaaaa;
                    font-size: 14px;
                  }
                }
              }
              .selLi {
                background: rgba(236, 245, 255, 1);
              }
            }
          }
        }
      }
      .title {
        height: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background: #fff2f1;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 16px;
        color: #f5222d;
        border-bottom: 1px solid #dbdbdb;
        font-weight: 700;
        text-align: center;
      }
    }
  }
  
  .footer {
    display: flex;
    justify-content: right;
    padding: 0 10px;
    height: 50px;
    align-items: center;
    border-top: 1px solid #dbdbdb;
    .num {
      // flex: 1;
      font-size: 14px;
      color: #333;
      text-align: center;
      // padding-left: 20px;
    }
  }
}
// 弹框样式
.outDialogBtn {
  display: flex;
  justify-content: right;
  align-items: center;
  height: 40px;
}
.dialogContent {
  display: flex;
  .item {
    flex: 1;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    .cHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      .tit {
        font-size: 14px;
        color: #333;
        margin-right: 8px;
        flex-shrink: 0;
      }
      .ipt {
        flex: 1;
        margin-right: 8px;
      }
    }
  }
  .cRight {
    margin-left: 20px;
  }
  .dialogtree {
    border-top: 1px solid #dbdbdb;
    box-sizing: border-box;
    padding: 10px;
    min-height: 100px;
    max-height: 300px;
    overflow: auto;
  }
  ::v-deep
    .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    color: #f5222d;
    font-weight: bold;
    background-color: #fff !important;
  }
}
</style>