var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.type == "add" || _vm.type == "product" ? "新增" : "编辑",
        visible: _vm.visible,
        "before-close": _vm.cancle,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _vm.type != "product"
            ? _c(
                "el-form-item",
                { attrs: { label: "登录用户名", prop: "username" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入登录用户名",
                      disabled: _vm.type == "see" || _vm.type == "edit",
                    },
                    model: {
                      value: _vm.form.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "用户姓名", prop: "fullName" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入用户姓名",
                  disabled: _vm.type == "see",
                },
                model: {
                  value: _vm.form.fullName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fullName", $$v)
                  },
                  expression: "form.fullName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系方式", prop: "phone" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入联系方式",
                  maxlength: "11",
                  disabled: _vm.type == "see",
                },
                model: {
                  value: _vm.form.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户角色", prop: "roleIdSet" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { multiple: "", placeholder: "请选择用户角色" },
                  model: {
                    value: _vm.form.roleIdSet,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "roleIdSet", $$v)
                    },
                    expression: "form.roleIdSet",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.roleId,
                    attrs: { label: item.roleName, value: item.roleId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.type == "product"
            ? _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { type: "password", placeholder: "请输入密码" },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.type != "see"
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancle } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading,
                    size: "small",
                  },
                  on: { click: _vm.dataFormSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }