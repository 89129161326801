<template>
  <div class="leftPage">
    <div class="title">
      <span class="huizong">今日运营汇总</span>
      <span>{{ nowDate }}</span>
      <span>{{ nowDay }}</span>
    </div>
    <div class="topBox">
      <div class="everyBox" v-for="item, index in topBoxData" :key="index">
        <div class="biaoti">
          {{ item.label }}
          <span v-if="index == 0" style="color:#8c8c8c">(元)</span>
        </div>
        <div class="number">
          {{ item.value }}
          <span v-if="index == 0" style="font-size:20px"></span>
        </div>
        <div class="yesterday">
          昨日
          <span>
            {{ item.yesterday }}
            <span v-if="index == 0" style="font-size:12px"></span>
          </span>
        </div>
        <div class="ratio" style="font-weight:500">
          环比
          <span :style="item.ratio >= 0 ? 'color:#F5222D' : 'color:#17A83C'">
            <img src="@/assets/shangzhang.png" style="width:8px" alt="" v-if="item.ratio >= 0">
            <img src="@/assets/xiadie.png" style="width:8px" alt="" v-else>
            {{ item.ratio >= 0 ? '+' : '' }} {{ item.ratio }} %
          </span>

        </div>
      </div>
    </div>
    <div class="itemBox">
      <div class="title">订单中心</div>
      <div class="mainBox">
        <div class="everyBox" :style="{ cursor: item.path ? 'pointer' : '' }" @click="tiaozhuan(item)"
          v-for="item, index in firstBoxData" :key="index">
          <div class="everyTitle" :style="{ padding: index == 0 || index == 7 ? '0 0 0 0px' : '0 0 0 40px' }">{{
            item.label }}
          </div>
          <div class="everyNumber"
            :style="{ color: `${item.color}`, border: index == 6 ? 'none' : '', padding: index == 0 || index == 7 ? ' 0 0 0 0px' : '0 0 0 40px' }">
            {{ item.value }}</div>
        </div>
      </div>
    </div>
    <div class="itemBox">
      <div class="title">客户中心</div>
      <div class="mainBox">
        <div class="everyBox" v-for="item, index in secondBoxData" @click="tiaozhuan(item)"
          :style="{ cursor: item.path ? 'pointer' : '' }" :key="index">
          <div class="everyTitle" :style="{ padding: index == 0 || index == 7 ? '0 0 0 0px' : '0 0 0 40px' }">{{
            item.label }}
          </div>
          <div class="everyNumber"
            :style="{ color: `${item.color}`, border: index == 6 ? 'none' : '', padding: index == 0 || index == 7 ? ' 0 0 0 0px' : '0 0 0 40px' }">
            {{ item.value }}</div>
        </div>
      </div>
    </div>
    <div class="itemBox">
      <div class="title">商品中心</div>
      <div class="mainBox">
        <div class="everyBox" v-for="item, index in thirdBoxData" @click="tiaozhuan(item)" :key="index"
          :style="{ cursor: item.path ? 'pointer' : '' }">
          <div class="everyTitle" :style="{ padding: index == 0 || index == 7 ? '0 0 0 0px' : '0 0 0 40px' }">{{
            item.label }}
          </div>
          <div class="everyNumber"
            :style="{ color: `${item.color}`, border: index == 6 || index == 8 ? 'none' : '', padding: index == 0 || index == 7 ? ' 0 0 0 0px' : '0 0 0 40px' }">
            {{ item.value }}</div>
        </div>
      </div>
    </div>
    <div class="itemBox">
      <div class="title">营销推广</div>
      <div class="mainBox">
        <div class="everyBox" v-for="item, index in fourthBoxData" @click="tiaozhuan(item)" :key="index"
          :style="{ cursor: item.path ? 'pointer' : '', margin: index == 7 || index == 8 ? '20px 0 0 0' : '', }">
          <div class="everyTitle" :style="{ padding: index == 0 || index == 7 ? '0 0 0 0px' : '0 0 0 40px' }">{{
            item.label }}
          </div>
          <div class="everyNumber"
            :style="{ color: `${item.color}`, border: index == 6 || index == 8 ? 'none' : '', padding: index == 0 || index == 7 ? ' 0 0 0 0px' : '0 0 0 40px' }">
            {{ item.value }}<span v-if="item.value2">/{{ item.value2 }}</span>
          </div>
          <div class="day" v-if="item.label != '推广商品数' && item.label != '推广合作/待审核' && item.label != '单品包邮商品数'"
            :style="{ border: index == 6 || index == 8 ? 'none' : '', padding: index == 0 || index == 7 ? ' 0 0 0 0px' : '0 0 0 40px' }">
            昨日 {{ item.yesterday }}
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
  
<script>
import { adaption } from '@/api/home/index'
import dayjs from 'dayjs'

export default {
  props: {},
  data () {
    return {
      shopId: this.$store.state.user.user_info.shopId,
      deptId: '',
      nowDate: dayjs().format('YYYY/MM/DD'),
      nowDay: '',
      // 今日运营汇总
      topBoxData: [
        {
          label: '支付金额',
          value: '0.00',
          yesterday: '0',
          ratio: '0.0000',
        },
        {
          label: '成交订单',
          value: '0',
          yesterday: '0',
          ratio: '0.0000',
        },
        {
          label: '支付买家',
          value: '0',
          yesterday: '0',
          ratio: '0.0000',
        },
        {
          label: '访客数',
          value: '0',
          yesterday: '0',
          ratio: '0.0000',
        },
        {
          label: '商品浏览数',
          value: '0',
          yesterday: '0',
          ratio: '0.0000',
        }
      ],
      // 订单中心
      firstBoxData: [
        {
          label: '待支付',
          value: '0',
          color: '#262626',
          path: '/ordercenter/ordercenter-sales',
          tab: '1'
        },
        {
          label: '待确认',
          value: '0',
          color: '#BFBFBF',
          path: '/ordercenter/ordercenter-sales',
          tab: '12'
        },
        {
          label: '待开户',
          value: '0',
          color: '#262626',
          path: '/ordercenter/ordercenter-sales',
          tab: '11'
        },
        {
          label: '待发货',
          value: '0',
          color: '#262626',
          path: '/ordercenter/ordercenter-sales',
          tab: '2'
        },
        {
          label: '将超时',
          value: '0',
          color: '#F55A22',
          path: '/ordercenter/ordercenter-sales',
          tab: '2'
        },
        {
          label: '已超时',
          value: '0',
          color: '#F5222D',
          path: '/ordercenter/ordercenter-sales',
          tab: '2'
        },
        {
          label: '售后处理',
          value: '0',
          color: '#262626',
          path: '/ordercenter/ordercenter-sales',
          tab: '10'
        }
      ],
      // 客户中心
      secondBoxData: [
        {
          label: '今日新增',
          value: '0',
          color: '#262626',
          path: '/customer/businessManage-customer-index',
          tab: ''
        },
        {
          label: '客户总数',
          value: '0',
          color: '#262626',
          path: '/customer/businessManage-customer-index',
          tab: ''
        },
        {
          label: '未开户',
          value: '0',
          color: '#262626',
          path: '/customer/businessManage-customer-index',
          tab: '未开户'
        },
        {
          label: '店名更改',
          value: '0',
          color: '#262626',
          path: '/customer/businessManage-customer-index',
          tab: '2'
        },
        {
          label: '地址更改',
          value: '0',
          color: '#262626',
          path: '/customer/businessManage-customer-index',
          tab: '3'
        },
        {
          label: '发票更改',
          value: '0',
          color: '#BFBFBF',
          path: '/customer/businessManage-customer-index',
          tab: ''
        },
        {
          label: '资质更改',
          value: '0',
          color: '#262626',
          path: '/customer/businessManage-customer-index',
          tab: '0'
        },
      ],
      // 商品中心
      thirdBoxData: [
        {
          label: '在售商品',
          value: '0',
          color: '#262626',
          path: '/productManage/salesControl-commodity-list',
          tab: 'ON_SALE'

        },
        {
          label: '未标价',
          value: '0',
          color: '#BFBFBF',
          path: '/productManage/salesControl-commodity-list',
          tab: 'UNPRICED'
        },
        {
          label: '未上架',
          value: '0',
          color: '#262626',
          path: '/productManage/release-release',
          tab: '1'
        },
        {
          label: '上架待审核',
          value: '0',
          color: '#262626',
          path: '/productManage/salesControl-commodity-list',
          tab: 'SKU_AWAIT'
        },
        {
          label: '商家下架',
          value: '0',
          color: '#F55A22',
          path: '/productManage/salesControl-commodity-list',
          tab: 'MERCHANT_REMOVAL'
        },
        {
          label: '平台下架',
          value: '0',
          color: '#262626',
          path: '/productManage/salesControl-commodity-list',
          tab: 'PLATFORM_REMOVAL'
        },
        {
          label: '库存不足7天',
          value: '0',
          color: '#F55A22'
        },
        {
          label: '30天未动销',
          value: '0',
          color: '#F55A22'
        },
        {
          label: '近效期',
          value: '0',
          color: '#F55A22'
        },
      ],
      // 营销推广
      fourthBoxData: [
        {
          label: '券数',
          value: '0',
          yesterday: '0',
          color: '#262626',
          path: '',
          tab: ''

        },
        {
          label: '领券量',
          value: '0',
          yesterday: '0',
          color: '#262626',
          path: '',
          tab: ''
        },
        {
          label: '用券采购额(元)',
          value: '0',
          yesterday: '0',
          color: '#262626',
          path: '',
          tab: ''
        },
        {
          label: '单品包邮商品数',
          value: '0',
          yesterday: '0',
          color: '#262626',
          path: '',
          tab: ''
        },
        {
          label: '单品包邮订单数',
          value: '0',
          yesterday: '0',
          color: '#262626',
          path: '',
          tab: ''
        },
        {
          label: '单品包邮销售额(元)',
          value: '0',
          yesterday: '0',
          color: '#262626',
          path: '',
          tab: ''
        },
        {
          label: '推广商品数',
          value: '0',
          yesterday: '0',
          color: '#262626',
          path: '',
          tab: ''
        },
        {
          label: '推广合作/待审核',
          value: '0',
          value2: '0',
          yesterday: '0',
          color: '#262626',
          path: '',
          tab: ''
        },
        {
          label: '推广销售额(元)',
          value: '0',
          yesterday: '0',
          color: '#262626',
          path: '',
          tab: ''
        },
      ],
    }
  },
  components: {},
  async created () {
    // await this.queryShopId()
    await this.queryDeptId()
    await this.jiusanNowDate()
    // await this.adaption()
    await this.adaption(6)
    await this.adaption(7)
    await this.adaption(8)
    await this.adaption(11)
    await this.adaption(12)
  },
  mounted () {

  },
  watch: {},
  methods: {
    // 路径跳转
    tiaozhuan (item) {
      if (item.path) {
        let obj = {
          path: item.path,
          query: {
            tab: item.tab
          }
        }
        if (item.label == '将超时') {
          obj.query.ageingTag = '1'
        }
        this.$router.push(obj)
      }
    },
    // 计算今天是星期几
    async jiusanNowDate () {
      let a = new Date().getDay()
      switch (a) {
        case 0:
          this.nowDay = '星期日'
          break;
        case 1:
          this.nowDay = '星期一'
          break;
        case 2:
          this.nowDay = '星期二'
          break;
        case 3:
          this.nowDay = '星期三'
          break;
        case 4:
          this.nowDay = '星期四'
          break;
        case 5:
          this.nowDay = '星期五'
          break;
        case 6:
          this.nowDay = '星期六'
          break;
        default:
          break;
      }
    },
    // 首页实时数据
    async adaption (type) {
      let params = {
        type: type,
        params: [this.shopId]
      }
      switch (type) {
        case 6:
          params.params = [this.shopId, this.shopId, this.shopId, this.shopId, this.shopId]
          break;
        case 8:
          params.params = [this.shopId, this.shopId, this.shopId, this.shopId, this.shopId, this.shopId, this.shopId]
          break;
        case 11:
          params.params = [this.shopId, this.shopId, this.shopId, this.deptId, this.deptId]
          break;
        case 12:
          params.params = [this.shopId, this.shopId, this.shopId, this.shopId, this.shopId, this.deptId, this.shopId, this.shopId, this.shopId]
          break;
        default:
          break;
      }
      adaption(params).then(res => {
        if (res) {
          switch (type) {
            case 6:
              this.topBoxData[0].value = res[0].sale_amt ? res[0].sale_amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'
              this.topBoxData[1].value = res[0].sale_num ? res[0].sale_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.topBoxData[2].value = res[0].pay_shop_num ? res[0].pay_shop_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.topBoxData[3].value = res[0].uv ? res[0].uv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.topBoxData[4].value = res[0].goods_pv ? res[0].goods_pv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'

              this.topBoxData[0].yesterday = res[0].sale_amt_yday ? res[0].sale_amt_yday.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'
              this.topBoxData[1].yesterday = res[0].sale_num_yday ? res[0].sale_num_yday.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.topBoxData[2].yesterday = res[0].pay_shop_num_yday ? res[0].pay_shop_num_yday.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.topBoxData[3].yesterday = res[0].uv_yday ? res[0].uv_yday.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.topBoxData[4].yesterday = res[0].goods_pv_yday ? res[0].goods_pv_yday.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'

              this.topBoxData[0].ratio = res[0].sale_amt_ror ? (res[0].sale_amt_ror * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'
              this.topBoxData[1].ratio = res[0].sale_num_ror ? (res[0].sale_num_ror * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'
              this.topBoxData[2].ratio = res[0].pay_shop_num_ror ? (res[0].pay_shop_num_ror * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'
              this.topBoxData[3].ratio = res[0].uv_ror ? (res[0].uv_ror * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'
              this.topBoxData[4].ratio = res[0].goods_pv_ror ? (res[0].goods_pv_ror * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'
              break;
            case 7:
              this.secondBoxData[0].value = res[0].new_custom_num ? res[0].new_custom_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.secondBoxData[1].value = res[0].total_custom_num ? res[0].total_custom_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.secondBoxData[2].value = res[0].non_open_account ? res[0].non_open_account.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.secondBoxData[3].value = res[0].shop_name_change ? res[0].shop_name_change.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.secondBoxData[4].value = res[0].address_change ? res[0].address_change.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.secondBoxData[5].value = res[0].invoice_change ? res[0].invoice_change.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.secondBoxData[6].value = res[0].qualified_change ? res[0].qualified_change.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              break;
            case 8:
              this.thirdBoxData[0].value = res[0].onsale_goods_num ? res[0].onsale_goods_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.thirdBoxData[1].value = res[0].non_sale_price ? res[0].non_sale_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.thirdBoxData[2].value = res[0].non_onsale ? res[0].non_onsale.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.thirdBoxData[3].value = res[0].onsale_need_approve ? res[0].onsale_need_approve.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.thirdBoxData[4].value = res[0].phmc_offsale ? res[0].phmc_offsale.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.thirdBoxData[5].value = res[0].platform_offsale ? res[0].platform_offsale.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.thirdBoxData[6].value = res[0].low_7_days_stock ? res[0].low_7_days_stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.thirdBoxData[7].value = res[0].non_sale_30_days ? res[0].non_sale_30_days.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.thirdBoxData[8].value = res[0].near_expire_time ? res[0].near_expire_time.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              break;
            case 11:
              this.firstBoxData[0].value = res[0].need_pay_num ? res[0].need_pay_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.firstBoxData[1].value = res[0].need_confirm_pay_num ? res[0].need_confirm_pay_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.firstBoxData[2].value = res[0].need_open_account ? res[0].need_open_account.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.firstBoxData[3].value = res[0].need_deliver_num ? res[0].need_deliver_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.firstBoxData[4].value = res[0].will_time_out_num ? res[0].will_time_out_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.firstBoxData[5].value = res[0].time_out_num ? res[0].time_out_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.firstBoxData[6].value = res[0].after_sale_num ? res[0].after_sale_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              break;
            case 12:
              this.fourthBoxData[0].value = res[0].coupon_cnt ? res[0].coupon_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.fourthBoxData[1].value = res[0].collection_cnt ? res[0].collection_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.fourthBoxData[2].value = res[0].coupon_amount ? res[0].coupon_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'
              this.fourthBoxData[3].value = res[0].goods_cnt ? res[0].goods_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.fourthBoxData[4].value = res[0].single_cnt ? res[0].single_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.fourthBoxData[5].value = res[0].single_amount ? res[0].single_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'
              this.fourthBoxData[6].value = res[0].ctrl_goods_cnt ? res[0].ctrl_goods_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.fourthBoxData[7].value = res[0].ctrl_pass_cnt ? res[0].ctrl_pass_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.fourthBoxData[7].value2 = res[0].ctrl_await_cnt ? res[0].ctrl_await_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.fourthBoxData[8].value = res[0].ctrl_amount ? res[0].ctrl_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'

              this.fourthBoxData[0].yesterday = res[0].last_coupon_cnt ? res[0].last_coupon_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.fourthBoxData[1].yesterday = res[0].last_collection_cnt ? res[0].last_collection_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.fourthBoxData[2].yesterday = res[0].last_coupon_amount ? res[0].last_coupon_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'
              // this.fourthBoxData[3].yesterday = res[0].goods_cnt ? res[0].goods_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.fourthBoxData[4].yesterday = res[0].last_single_cnt ? res[0].last_single_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.fourthBoxData[5].yesterday = res[0].last_single_amount ? res[0].last_single_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'
              // this.fourthBoxData[6].yesterday = res[0].ctrl_goods_cnt ? res[0].ctrl_goods_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              // this.fourthBoxData[7].yesterday = res[0].ctrl_pass_cnt ? res[0].ctrl_pass_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
              this.fourthBoxData[8].yesterday = res[0].last_ctrl_amount ? res[0].last_ctrl_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'
              break;
            default:
              break;
          }
        }
      })
    },
    // async queryShopId () {
    //   let obj = {
    //     type: '1',
    //     params: [this.$store.state.user.user_info.userId]
    //   }
    //   const resp = await adaption(obj)
    //   if (resp.length > 0) {
    //     this.shopId = resp[0].shop_id
    //   }
    // },
    async queryDeptId () {
      let obj = {
        type: '13',
        params: [this.$store.state.user.user_info.userId]
      }
      const resp = await adaption(obj)
      if (resp.length > 0) {
        this.deptId = resp[0].dept_id
      }
    },
  }
}

</script>
<style lang="scss" scoped>
@font-face {
  font-family: "myFont";
  src: url("../../fonts/BebasNeue-1.otf");
}

.leftPage {
  .title {
    display: flex;
    align-items: center;

    span {
      margin-left: 9px;
      font-size: 12px;
      font-family: Helvetica, Helvetica-Regular;
      font-weight: 400;
      text-align: left;
      color: #8c8c8c;
      line-height: 20px;
    }

    .huizong {
      margin-left: 0px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
      line-height: 24px;
    }
  }

  .topBox {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    .everyBox {
      width: calc(20% - 6.4px);
      padding: 16px;
      // height: 132px;
      border: 1px solid #ededed;
      border-radius: 4px;

      .biaoti {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: left;
        color: #262626;
        line-height: 17px;
      }

      .number {
        font-size: 30px;
        font-family: "myFont";
        font-weight: 500;
        text-align: left;
        color: #262626;
        line-height: 37px;
        letter-spacing: 0.2px;
      }

      .yesterday {
        height: 20px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #8c8c8c;
        line-height: 20px;
      }

      .ratio {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #8c8c8c;
        line-height: 20px;
      }
    }
  }

  .itemBox {
    margin-top: 16px;
    padding: 16px;
    border: 1px solid #ededed;
    border-radius: 4px;

    .title {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
      line-height: 24px;
    }

    .mainBox {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      // margin-top: 20px;
    }

    .everyBox {
      width: 14.28%;
      margin-top: 14px;

      .everyTitle {
        padding-left: 40px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #595959;
        line-height: 17px;
      }

      .everyNumber {
        padding-left: 40px;
        border-right: 1px solid #ededed;
        font-size: 30px;
        font-family: "myFont";
        font-weight: 500;
        text-align: left;
        color: #262626;
        line-height: 37px;
        letter-spacing: 0.2px;
      }

      .day {
        font-size: 12px;
        color: #595959;
      }
    }
  }
}
</style>

