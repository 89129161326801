<!--  -->
<template>
  <div class="classification">
    <div class="header">
      <div class="left_topic mb20" style="margin-top: 10px">发票抬头列表</div>
      <div>
        <el-button
          size="small"
          type="primary"
          @click="createAccount()" 
          >新建</el-button
        >
      </div>
    </div>

    <div class="center" style="position: relative">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        :border="false"
        @select-all="selectAll"
        @select="selectFun"
        ref="tableRef"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)"
        @selection-change="handleSelectionChange"
        type="selection"
        :key="tableKey"
        :lazy="true"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column type="index" label="序号" width="55" />
        <el-table-column prop="sellerName" label="企业名称"></el-table-column>
        <el-table-column prop="sellerTaxpayerId" label="企业税号"></el-table-column>
        <el-table-column prop="payeeNo" label="康贝号"></el-table-column>
        <el-table-column prop="taxRate" label="税率/征收率"></el-table-column>

        <el-table-column prop="post" label="操作" width="300">
          <template slot-scope="scope">
            <el-link
              :underline="false"
              type="primary"
              @click="goInovice(scope.row)" 
              class="btn-list"
              >进入数电设置</el-link
            >
            <el-link
              :underline="false"
              type="primary"
              @click="settingKB(scope.row)" 
              class="btn-list"
              >编辑康贝号</el-link
            >
            <el-link
              :underline="false"
              type="primary"
              @click="settingTaxRate(scope.row)" 
              class="btn-list"
              >自动开票设置</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <settingTaxRate ref="setting-tax-rate" @getList="getList"  /> 
    <settingKB ref="setting-kb" @getList="getList" />
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getElephantInvoiceById ,listByLoginUser} from "@/api/invoice";
import { isJSONObject } from "@/utils";
import settingTaxRate from "./components/settingTaxRate.vue";
import settingKB from "./components/settingKB.vue";
import avater from "/public/img/default.png";
export default {
  components: { settingTaxRate , settingKB},
  data() {
    return {
      activeName: 1,
      avater: avater,
      operation: true,
      selection: false,
      index: true,
      tableData: [],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      dialogShow: false,
      loading: false,
      tableKey: 0,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getList();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  computed: {
    ...mapGetters(["permissions"]),
  },
  methods: {
    handleClick() {},
    // 新建
    createAccount() {
      this.$router.push({
        path: "./invoiceCenter-invoiceInfoManege",
        query: {
          type: "add",
        },
      });
    },

    // 进入数电设置
    goInovice(row) {
      this.$router.push({
        path: "./invoiceCenter-invoiceInfoManege",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },

    // 设置税率
    settingTaxRate(row) {
      this.$refs["setting-tax-rate"].init(row);
    },

    settingKB(row) {
      this.$refs["setting-kb"].init(row);
    }, 

    selectFun() {
      this.selection = true;
    },

    // 勾选
    handleSelectionChange(val) {},

    selectAll(selection) {
      this.selectionList = selection;
    },

    // 获取列表
    getList() { 
      listByLoginUser().then((res) => {
        if (res) {
          this.tableData = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";

.classification {
  padding: 0px;

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .top {
    display: flex;
    padding: 10px 0 10px 0;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .label {
        width: 120px;
      }

      .button {
        margin-right: 20px;

        ::v-deep .el-button {
          color: $subMenuActiveText;
          background-color: $butColor;
          border-color: $butColor;

          &:hover {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:active {
            color: $subMenuActiveText;
            background: $butColor;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:focus {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
        }
      }
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn-warp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.btn-list {
  margin: 0 5px;
}
</style>
