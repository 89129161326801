var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择商品",
            visible: _vm.visible,
            width: "86%",
            "close-on-click-modal": false,
            "before-close": _vm.cancel,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticStyle: { "text-align": "left" },
                  attrs: { model: _vm.searchForm, inline: true },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "goodsName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "160px" },
                        attrs: {
                          placeholder: "请输入商品名称",
                          clearable: "",
                          size: "small",
                        },
                        model: {
                          value: _vm.searchForm.goodsName,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "goodsName", $$v)
                          },
                          expression: "searchForm.goodsName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "factory" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "160px" },
                        attrs: {
                          placeholder: "请输入生产厂家",
                          clearable: "",
                          size: "small",
                        },
                        model: {
                          value: _vm.searchForm.factory,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "factory", $$v)
                          },
                          expression: "searchForm.factory",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "approvalNumber" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "160px" },
                        attrs: {
                          placeholder: "请输入批准文号",
                          clearable: "",
                          size: "small",
                        },
                        model: {
                          value: _vm.searchForm.approvalNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "approvalNumber", $$v)
                          },
                          expression: "searchForm.approvalNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.search(1)
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.reset("searchForm")
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.goodsList && _vm.goodsList.length > 0
                ? _c(
                    "div",
                    [
                      _c("goodsItem", {
                        attrs: { "goods-list": _vm.goodsList },
                        on: { chooseItem: _vm.chooseItem },
                      }),
                      _c(
                        "div",
                        { staticClass: "footer" },
                        [
                          _c("Pagination", {
                            attrs: {
                              total: _vm.page.total,
                              "page-sizes": _vm.page.pageSizes,
                              page: _vm.page.current,
                              limit: _vm.page.size,
                            },
                            on: {
                              "update:page": function ($event) {
                                return _vm.$set(_vm.page, "current", $event)
                              },
                              "update:limit": function ($event) {
                                return _vm.$set(_vm.page, "size", $event)
                              },
                              pagination: _vm.pageChange,
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "btnBox" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: { click: _vm.cancel },
                                },
                                [_vm._v("取消")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.chooseGoods },
                                },
                                [_vm._v("提交")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", { staticClass: "noData" }, [_vm._v(" 没有内容~ ")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }