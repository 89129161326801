//  对应企业类型companyType
import companyType_1 from '../utils/credential/companyType_1.js';
import companyType_2 from '../utils/credential/companyType_2.js';
import companyType_3 from '../utils/credential/companyType_3.js';
import companyType_4 from '../utils/credential/companyType_4.js';
import companyType_5 from '../utils/credential/companyType_5.js';
import companyType_6 from '../utils/credential/companyType_6.js';
import companyType_7 from '../utils/credential/companyType_7.js';
import companyType_8 from '../utils/credential/companyType_8.js';
import companyType_9 from '../utils/credential/companyType_9.js';

export default function credentialCompanyType(companyType,code)  {

	var credentialJson = {};
	  switch (companyType){
	 	case '1':
		credentialJson = companyType_1;
	 		break;
		case '2':
		credentialJson = companyType_2;
		case '3':
		credentialJson = companyType_3;
			break;
		case '4':
		credentialJson = companyType_4;
			break;	
		case '5':
		credentialJson = companyType_5;
			break;
		case '6':
		credentialJson = companyType_6;
			break;	
		case '7':
		credentialJson = companyType_7;
			break;
		case '8':
		credentialJson = companyType_8;
			break;
		case '9':
		credentialJson = companyType_9;
			break;						
	 	default:
	 		break;
	 }
	  
	return credentialJson
}