var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-root" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            title: _vm.title,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "918px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.onClosed,
          },
        },
        [
          _c("div", { staticClass: "content form-content" }, [
            _vm.showAuditOpinion
              ? _c("div", { staticClass: "row mutlti" }, [
                  _c("div", { staticClass: "tip" }, [_vm._v("审核意见")]),
                  _c("div", { staticClass: "value input fill" }, [
                    _c("span", { staticClass: "opinion" }, [
                      _vm._v(_vm._s(_vm.auditOpinion)),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("名称")]),
              _c(
                "div",
                { staticClass: "value input fill" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      maxlength: "20",
                      disabled: _vm.isLook,
                    },
                    model: {
                      value: _vm.posterName,
                      callback: function ($$v) {
                        _vm.posterName = $$v
                      },
                      expression: "posterName",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("资料内容")]),
              _c(
                "div",
                { staticClass: "value input fill" },
                [
                  _c("ossFileUpload", {
                    ref: "refPicFileUpload",
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      limit: _vm.maxFileCount,
                      accept: _vm.picFileExt,
                      maxFileLength: 10,
                      ossUpload: true,
                      ossPathDir: "shop/pic/",
                      disabled: _vm.isLook,
                    },
                    model: {
                      value: _vm.picFileList,
                      callback: function ($$v) {
                        _vm.picFileList = $$v
                      },
                      expression: "picFileList",
                    },
                  }),
                  !_vm.isLook
                    ? _c("div", { staticClass: "small-tip warning" }, [
                        _c("span", [
                          _vm._v(
                            "上传pdf或图片，且不超过10M，最多上传" +
                              _vm._s(_vm.maxFileCount) +
                              "个。上传多个文件时，将按文件上传的顺序给业务员展示资料"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "row mutlti big",
                staticStyle: { "margin-top": "12px" },
              },
              [
                _c("div", { staticClass: "tip" }, [_vm._v("视频内容")]),
                _c(
                  "div",
                  { staticClass: "value input fill" },
                  [
                    _c("ossFileUpload", {
                      ref: "refVideoFileUpload",
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: {
                        limit: _vm.maxFileCount,
                        accept: _vm.videoFileExt,
                        maxFileLength: 300,
                        ossUpload: true,
                        ossPathDir: "shop/video/",
                        withCover: true,
                        disabled: _vm.isLook,
                      },
                      model: {
                        value: _vm.videoFileList,
                        callback: function ($$v) {
                          _vm.videoFileList = $$v
                        },
                        expression: "videoFileList",
                      },
                    }),
                    !_vm.isLook
                      ? _c("div", { staticClass: "small-tip warning" }, [
                          _c("span", [
                            _vm._v(
                              "上传MP4，且不超过300M，最多上传" +
                                _vm._s(_vm.maxFileCount) +
                                "个。当文件过大时，将导致业务员长时间等待下载视频，建议压缩文件后上传系统"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "row mutlti big",
                staticStyle: { "margin-top": "12px" },
              },
              [
                _c("div", { staticClass: "tip" }, [_vm._v("商品信息")]),
                _c("div", { staticClass: "value" }, [
                  _vm.goodsInfo
                    ? _c(
                        "div",
                        { staticClass: "goods-info" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: {
                              src: _vm.goodsInfo.image
                                ? _vm.goodsInfo.image
                                : _vm.avater,
                              lazy: "",
                            },
                          }),
                          _c("div", { staticClass: "txt-info" }, [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(_vm.goodsInfo.name)),
                            ]),
                            _c("div", { staticClass: "factory" }, [
                              _vm._v(_vm._s(_vm.goodsInfo.factory)),
                            ]),
                            _c("div", { staticClass: "factory" }, [
                              _vm._v(_vm._s(_vm.goodsInfo.specification)),
                            ]),
                            _c("div", { staticClass: "factory" }, [
                              _vm._v(_vm._s(_vm.goodsInfo.approvalNumber)),
                            ]),
                            _c("div", { staticClass: "factory" }, [
                              _vm._v(
                                "商品编码：" + _vm._s(_vm.goodsInfo.goodsCode)
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                !_vm.isLook
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.handleSelectGoods },
                      },
                      [_vm._v("选择商品")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
              !_vm.isLook
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("保存并提交")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c("selectGoods", {
        ref: "refSelectGoods",
        on: { choose: _vm.didSelectItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }