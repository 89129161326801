import request from "@/utils/request";

// 查询当前商铺详情信息
export function currentDetails() {
  return request({
    url: `/shop/shop/current/details`,
    method: "get",
  });
}

// 校验有效资质
export function validCertify() {
  return request({
    url: `/shop/shop/check/validCertify`,
    method: "get",
  });
}

//查询资质详情
export function getCertifyDetail(params) {
  return request({
    url: `shop/compShopCertify/current/details`,
    method: "get",
    params,
  });
}

//获取企业类型
/**type : 0 客户(大商城) 1 商户（卖家端） */
export function getCompanyTypes(params) {
  return request({
    // url: `shop/shoptype/getListByType`,
    url: `shop/shoptype/getEnableListByType`,
    method: "get",
    params,
  });
}

//暂存草稿
export function doTmpSaveCertify(data) {
  return request({
    url: `shop/compShopCertify/certify/tempSave`,
    method: "post",
    data,
  });
}

//提交和修改
export function doCommitCertify(data) {
  return request({
    url: `shop/compShopCertify/certify/commit`,
    method: "post",
    data,
  });
}
// 根据 requestid 查询资质详情
export function getCertificationByRequestId(requestId) {
  return request({
      url: `/shop/compShopCertify/details/${requestId}`,
      method: "get",
      params: {}
  });
}
// 根据 shopId 查询资质详情
export function getCertificationByShopId(shopId) {
  return request({
      url: `/shop/compShopCertify/detailsByShopId/${shopId}`,
      method: "get",
      params: {}
  });
}
// 资质售后详情
export function getCertificationByOrderId(data) {
  return request({
      url: `/order/certification`,
      method: "get",
      params: data 
    });
}
// 商户资质临时信息提交
export function doCommitCertifyTempData(data) {
  return request({
    url: `/shop/compShopCertify/certify/temp/data`,
    method: "post",
    data,
  });
}

// 提交资质售后
export function putCertificationByOrderId(data) {
  return request({
      url: `/order/certification`,
      method: "put",
      data
  });
}

// 商家驳回资质售后
export function reject(data) {
  return request({
      url: `/order/certification/payee/reject`,
      method: "put",
      data
  });
}
// 诊所申请发票售后
export function afterInvoice(data) {
  return request({
    url: `order/after/invoice`,
    method: 'post',
    data,
  });
}

// 诊所发票售后详情
export function getAfterInvoice(data) {
  return request({
    url: `order/after/invoice`,
    method: 'get',
    params:data,
  });
}

// 商家驳回发票售后
export function invoiceReject(data) {
  return request({
    url: `order/after/invoice/payee/reject`,
    method: 'put',
    data,
  });
}

// 商家上传发票
export function uploadInvoice(data) {
  return request({
    url: `order/after/invoice`,
    method: 'put',
    data,
  });
}