var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { staticClass: "app-main" },
      [
        _c(
          "transition",
          { attrs: { name: "fade-transform", mode: "out-in" } },
          [
            _c(
              "keep-alive",
              { attrs: { include: _vm.includeList } },
              [_c("router-view", { key: _vm.key })],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "bottom" }, [
      _vm._v(
        "Copyright © 湖南春播云药库科技有限公司版权所有 客服热线：4000819577"
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }