import request from "@/utils/request";

// 获取不同身份
export function getShoparea(data) {
  return request({
    url: `/shop/shoptype/list?status=${data}`,
    method: "get",
  });
}

// 获取不同的商品类型
export function getUserarea() {
  return request({
    url: "/shop/shopscope/list",
    method: "get",
  });
}

export function postShopapply(data) {
  return request({
    url: "/shop/shop/apply",
    method: "post",
    data: data,
  });
}
// 缓存提交前两步
export function postShopapplyHistory(data,step) {
  return request({
    url: `/shop/shop/certificate/applycache/${step}`,
    method: "post",
    data: data,
  });
}
// 获取缓存前两步数据
export function getShopapplyHistory(typeCode,step) {
  return request({
    url: `/shop/shop/certificate/applycache/${typeCode}/${step}`,
    method: "get",
    data: {},
  });
}
// 缓存提交第三步
export function postShopapplyHistoryStep3(data,typeCode) {
  return request({
    url: `/shop/shop/certificate/baseShopCache/${typeCode}`,
    method: "post",
    data: data,
  });
}
// 获取缓存第三步数据
export function getShopapplyHistoryStep3(typeCode) {
  return request({
    url: `/shop/shop/certificate/baseShopCache/${typeCode}`,
    method: "get",
    data: {},
  });
}
// 获取发票类型
export function getinvoicetypeList() {
  return request({
    url: "/shop/shop/invoicetype/list",
    method: "get",
  });
}

// 获取省份列表
export function regionProvince() {
  return request({
    url: `/shop/region/province`,
    method: "get",
  });
}
// 获取市列表
export function regionCity(data) {
  return request({
    url: `/shop/region/city/${data}`,
    method: "get",
  });
}
// 获取区列表
export function regionArea(data) {
  return request({
    url: `/shop/region/area/${data}`,
    method: "get",
  });
}
// 第二步的内容提交
export function postshopSubmit(data) {
  return request({
    url: "/shop/shop/submit",
    method: "post",
    data,
  });
}

// 商户信息提交完成
export function shopFinish(data) {
  return request({
    url: `/shop/shop/finish`,
    method: "post",
    data,
  });
}
  
// 商户信息资质修改
export function updateCertify(data) {
  return request({
    url: `/shop/shop/update`,
    method: "put",
    data,
  });
}

// 导出当前查询条件下的资质列表
export function download(data) {
  return request({
    url: `/shop/shop/export`,
    method: 'get',
    params:data,
    responseType: 'blob'
  }).then(response => {
    // 处理返回的文件流
    const blob = response.data
    if (blob && blob.size === 0) {
      Message.warning('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    const contentDisposition = response.headers['content-disposition'];
    const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1));
    console.log(fileName)
    link.download = fileName;
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  })
}

// 是否已开启自动审单
export function autoCheck(data) {
  return request({
    url: `/shop/dfc/auto/check`,
    method: "get",
    // data,
  });
}

// 打开-关闭自动审单
export function openCheck(data) {
  return request({
    url: `/shop/dfc/open/check`,
    method: "put",
    // data,
  });
}