
<template>
  <div class="classification">
    <div class="tips">
      请您在每月的{{ obj.finallyPaymentTime }}号前，缴纳上个月的应付金额，超时将无法缴纳，并且{{ obj.debitDate }}号在保证金中扣除上个月应付金额<br />
      每月应付金额累计（包含从之前月份中累计到现在的应付金额）≤{{ obj.waitingServiceFeeCap }}元时，可以不在当月缴纳应付金额、累计到下月。
    </div>
    <div class="header">
      <el-form ref="formTool" :inline="true" style="text-align: left">
        <div>
          <el-form-item prop="nameLike" label="">
            <el-tabs v-model="activeName">
              <el-tab-pane label="应付订单" name="first">
              </el-tab-pane>
              <el-tab-pane label="缴费记录" name="second">
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <payableOrders ref="recharge-aduit-list" v-if="activeName == 'first'" />
    <recordList ref="record-list" v-if="activeName == 'second'" />
  </div>
</template>
  
<script>
import { configuration } from "@/api/offlinePayment";
import MyTable from "@/components/myTable";
import recordList from "./recordList.vue";
import payableOrders from "./payableOrders.vue";
export default {
  components: { MyTable, recordList, payableOrders },
  provide () {
    return {
      father: this,
    };
  },
  data () {
    return {
      useAmount: null,
      lockAmount: null,
      activeName: 'first',
      obj: {
        finallyPaymentTime: '',
        debitDate: '',
        waitingServiceFeeCap: 0,
        presentDate: ''
      }
    };
  },
  computed: {},
  watch: {},
  created () {
    this.configuration()
  },
  mounted () {

  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  computed: {
  },
  methods: {
    getList () {
      this.activeName = 'second';
      this.$refs['recharge-aduit-list'].getList()
    },
    configuration () {
      configuration().then(res => {
        if (res) {
          this.$store.dispatch('shop/setPlatformBond', res);
          this.obj = res;
        }
      })
    }

  },
  filters: {
    filterMoney (e) {
      return e ? parseFloat(e).toFixed(2) : '0.00';
    },
  },

};
</script>
<style lang='scss' scoped>
@import "@/styles/variables.scss";

.classification {
  background: white;
  overflow: inherit;

  //padding: 0;
  .header {
    width: calc(100% - 0px);
    margin: 0 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }


  .center {
    margin-top: 16px;
    padding: 0 0px !important;
    height: calc(100% - 131px);

  }

  .btn-warp {
    display: flex;
    justify-content: space-around;

  }
}

.header-money {
  width: calc(100% - 40px);
  margin: 20px 20px 0;
  display: flex;
  justify-content: space-between;
  font-size: 16px;

}

.cloum-lf {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
}

.cloum-i {
  width: 300px;

  span {
    color: #F5222D;
  }
}

.tips {
  font-size: 14px;
  color: #8c8c8c;
  margin-top: 20px;
  margin-left: 0px;
  margin-bottom: 0;
}
</style>
  