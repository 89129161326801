<!--  -->
<template>
  <div class="classification">
    <div class="title">
      <el-button size="small" type="primary" @click="add()">新增控销组</el-button>
    </div>
    <div class="center" style="position: relative">
      <Tabs @tab-click="handleClick" :tabsList="tabsList" :activeName.sync="activeName"></Tabs>
      <MyTable :table-data="tableData" :index="index" :show="false" :operation="operation"
        :table-option.sync="tableOption">
        <template slot="menu" slot-scope="scope">
          <el-link :underline="false" type="primary" class="btn" @click="edit(scope.row)">编辑</el-link>
          <el-link :underline="false" style="margin-left:10px" type="primary" class="btn"
            @click="deleteData(scope.row)">删除</el-link>
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import Tabs from "@/components/Tabs";
import { groupInfos, salesskuarearelevance, salesgroupinfo, salesgroupinfoDelete } from '@/api/salesControl/commodity'
export default {
  components: { MyTable, Tabs },
  data () {
    return {
      activeName: "first", // tabs 传的值
      tabsList: [
        // tabs 传的值
        {
          name: "first",
          label: "用户组列表",
          value: 0,
        },
        {
          name: "two",
          label: "地域组列表",
          value: 0,
        },
        {
          name: "three",
          label: "指定客户(九和)组列表",
          value: 0,
        },
      ],
      selectionList: [],
      operation: true,
      selection: false,
      index: false,
      tableData: [
       
      ],
      tableOption: [
        { label: "控销组名称", prop: "schemeName" },
        { label: "控销组ID", prop: "areaId" },
        { label: "控销药店", prop: "saleSareaName" },
        { label: "创建时间", prop: "createTime" },
        { label: "更新时间", prop: "updateTime" }
      ],
    };
  },
  computed: {},
  watch: {
    activeName: {
      handler (newValue, old) { 
        if(this.$store.state.tabStatus.controlPinFormTab && this.$route.query.tab ==undefined){ 
          this.activeName = this.$store.state.tabStatus.controlPinFormTab; 
        }  
        if (newValue == 'first') {
          this.tableOption[1].prop = "id"
          this.tableOption[2].label = "控销药店"
          
          this.tableOption[2].prop = "salesDrugstore" 
          this.salesgroupinfo()
        } else if (newValue == 'three') {
          this.tableOption[1].prop = "id"
          this.tableOption[2].label = "控销药店"
          this.tableOption[2].prop = "salesDrugstore"
          
          this.salesgroupinfo()
        } else {
          this.tableOption[1].prop = "areaId"
          this.tableOption[2].label = "控销地域"
          this.tableOption[2].prop = "saleSareaName" 
          this.groupInfos()
        }
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    // this.getList();
    this.activeName = this.$route.query?.tab || this.activeName
  },
  mounted () {
    // console.log( this.activeName,'---activeName') 
   
   },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    // tab 切换
    handleClick (e) {
      // console.log(e,'--e') 
      this.$store.dispatch('tabStatus/setControlPinFormTab', e);
    },
    // 新增
    add () {
      if (this.activeName == 'first' || this.activeName == 'three') {
        this.$router.push({
          path: "/productManage/salesControl-controlPinForm-userTemplate",
          query: {
            id: '',
            activeName: this.activeName
          }
        });
      } else {
        this.$router.push({
          path: "/productManage/salesControl-controlPinForm-areaTemplate",
          query: {
            id: '',
          }
        });
      }
    },
    // 编辑
    edit (row) {
      if (this.activeName == 'first' || this.activeName == 'three') {
        this.$router.push({
          path: "/productManage/salesControl-controlPinForm-userTemplate",
          query: {
            id: row.id,
            activeName: this.activeName
          }
        });
      } else {
        this.$router.push({
          path: "/productManage/salesControl-controlPinForm-areaTemplate",
          query: {
            id: row.areaId,
          }
        });
      }
    },
    // 删除数据
    deleteData (row) {
      if (this.activeName == 'first' || this.activeName == 'three') {
        // 用户组删除
        salesgroupinfoDelete(row.id).then(res => {
          if (res) {
            this.$message.success('删除成功')
            this.salesgroupinfo()
          }
        })
      } else {
        // 地域组删除
        salesskuarearelevance(row.areaId).then(res => {
          if (res) {
            this.$message.success('删除成功')
            this.groupInfos()
          }
        })
      }
    },
    // 查询地域组信息
    groupInfos () {
      groupInfos().then(res => {
        if (res) {
          this.tableData = res
          if (this.tableData.length > 0) {
            this.tableData.forEach(item => {
              item.saleSareaName = item.saleSareaName.split(',')
              if (item.saleSareaName.length > 10) {
                item.saleSareaName = item.saleSareaName.slice(0, 10).join(',') + '...';
              } else {
                item.saleSareaName = item.saleSareaName.join(',')
              }
            })
          }
        }
      })
    },
    // 查询用户组信息
    salesgroupinfo () {
      // 类型 1-控销用户组 2-九和用户组
      let type = 1;
      if (this.activeName == 'frist') {
        type = 1;
      }
      if (this.activeName == 'three') {
        type = 2;
      }
      let parmas = {};
      parmas.type = type;
      salesgroupinfo(parmas).then(res => {
        if (res) {
          this.tableData = res
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";

.classification {
  padding: 0;

  .title {
    z-index: 2;
    position: absolute;
    top: 12px;
    right: 24px;
  }

  position: relative;

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .top {
    display: flex;
    padding: 10px 0 10px 0;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .label {
        width: 120px;
      }

      .button {
        margin-right: 20px;

        ::v-deep .el-button {
          color: $subMenuActiveText;
          background-color: $butColor;
          border-color: $butColor;

          &:hover {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:active {
            color: $subMenuActiveText;
            background: $butColor;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:focus {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
        }
      }
    }
  }

  .center {
    // margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 58px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn {
    // margin: 0 10px;
    text-align: left;
  }
}

::v-deep .el-image {
  border-radius: 4px;
}
</style>
