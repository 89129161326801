<template>
  <div class="classification">
    <div class="card">
      <div class="staus_bar fl_bet border">
        <div class="detail_left">
          <div class="fl_cen">
            <div class="status">
              <el-image class="icon_status" :src="getStatus(orderList.type)" />
              <!-- <div class="name"
                   :class="getStatus(3, orderList.status)">
                {{ getStatus(2, orderList.status) }}
              </div> -->
            </div>
            <div class="items">
              <div class="title">订单编号</div>
              <div style="height: 22px">{{ orderList.orderCode || '' }}</div>
            </div>
            <div class="items">
              <div class="title">售后单号</div>
              <div style="height: 22px">{{ orderList.code || '' }}</div>
            </div>
            <div class="items">
              <div class="title">售后金额</div>
              <div style="height: 22px; ">
                <!--  -->
                <span style="color: #e4393c; font-size:18px">
                  ￥{{ orderList.amount || '' }}
                </span>

                ( <span v-if="orderList.balanceAmount && parseFloat(orderList.balanceAmount) > 0">
                  余额支付 ￥ {{ parseFloat(orderList.balanceAmount).toFixed(2) || '--' }}
                </span>
                <span
                  v-if="orderList.balanceAmount && parseFloat(orderList.balanceAmount) > 0 && (parseFloat(orderList.amount) - parseFloat(orderList.balanceAmount) > 0)"> 
                    {{ orderList.payTypeName }}
                  ￥ {{ (parseFloat(orderList.amount) - parseFloat(orderList.balanceAmount)).toFixed(2) }}
                </span>
                <span v-if="parseFloat(orderList.balanceAmount) == 0"> 
                    {{ orderList.payTypeName }}
                  ￥ {{ parseFloat(orderList.amount).toFixed(2) || '--' }}
                </span>
                )
                <!-- <span>(余额￥ 1.40 ，微信支付￥ 2.00)</span> -->
              </div>
            </div>
          </div>
        </div>
        <!-- 商家退款不显示审核按钮 -->
        <!-- <div class="detail_right">

          <el-button v-if="orderList.status == 'CHECKING' && orderList.type != 'SJTK'"
            :disabled="afterSalesControl == '1'" type="primary" size="small" @click="submitAgree()">同意</el-button>
          <el-button v-if="(orderList.status == 'CHECKING' || orderList.status == 'SALEING') && orderList.type != 'SJTK'"
            :disabled="afterSalesControl == '1'" type="" size="small" @click="aftersaleReject()">驳回</el-button>
          <el-button v-if="orderList.type == 'THTK' && indexStep == 4 && orderList.operationType != 'CANCEL_SALE'"
            :disabled="afterSalesControl == '1'" size="small" type="danger emphasis_btn"
            @click="clickAftersaleDelivery(afterSaleId)">确认收货</el-button>
        </div> -->
      </div>
      <Step :stepArray="stepArray" :indexStep="indexStep" :lineWord="true" />
      <!-- <div class="merchant">
        <h4 v-if="(orderList.type == 'THTK' && indexStep < 3 &&orderList.operationType != 'CANCEL_SALE') || (orderList.type == 'TK' && indexStep < 3 &&orderList.operationType != 'CANCEL_SALE')">请等待商家处理</h4>
        <div v-if="(orderList.type == 'THTK' && indexStep < 3 &&orderList.operationType != 'CANCEL_SALE') || (orderList.type == 'TK' && indexStep < 3 &&orderList.operationType != 'CANCEL_SALE')"
             style="border-bottom:1px solid #f5f5f5"
             class="firstBox">
          您已成功发起退款申请，请耐心等待商家处理
        </div>
        <div class="firstBox secondBox"
             v-if="(orderList.type == 'THTK' && indexStep < 3 &&orderList.operationType != 'CANCEL_SALE') || (orderList.type == 'TK' && indexStep < 3) &&orderList.operationType != 'CANCEL_SALE'">
          如果商家拒绝，您可以再次发起，商家会重新处理
        </div>
        <el-button type="primary"
                   v-if="(orderList.type == 'THTK' && indexStep < 3 &&orderList.operationType != 'CANCEL_SALE') || (orderList.type == 'TK' && indexStep < 3 &&orderList.operationType != 'CANCEL_SALE')"
                   @click="edit()">修改申请</el-button>
        <div class="firstBox secondBox">
          您还可以：
          <el-link type="primary"
                   :underline="false"
                   @click="applyPlatform">申请平台介入</el-link>
          <el-link type="primary"
                   :underline="false"
                   style="margin-left:10px"
                   v-if="(orderList.type == 'THTK' && indexStep < 4 &&orderList.operationType != 'CANCEL_SALE') || (orderList.type == 'TK' && indexStep < 3 &&orderList.operationType != 'CANCEL_SALE')"
                   @click="aftersaleRepeal">撤销申请</el-link>
        </div>
      </div> -->
    </div>
    <div class="info card">
      <el-descriptions title="售后单据信息">
        <el-descriptions-item label="申请类型">{{ orderList.type == "TK" ? '退款' : '退货退款' }}</el-descriptions-item>
        <el-descriptions-item label="申请金额">￥{{ orderList.amount }}</el-descriptions-item>
        <el-descriptions-item label="申请原因">{{ orderList.afterSaleReason }}</el-descriptions-item>
        <el-descriptions-item label="申请用户">{{ orderList.type == 'SJTK' ? orderList.payeeShopName : orderList.payShopName
        }} </el-descriptions-item>
        <el-descriptions-item label="申请时间">{{ orderList.orderTime }}</el-descriptions-item>
        <el-descriptions-item label="问题描述">{{ orderList.remark }}</el-descriptions-item>
        <el-descriptions-item label="售后凭证">
          <ImgUpload v-model="orderList.afterSaleVoucher" :isEdit="true" :limit="3" style="position:relative;top:-8px">
            <!-- <template slot="tip">
                <p class="el-upload__tip"
                   style="margin:0px">
                  备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过5M;第一张图片会作为商品展示图
                </p>
              </template> -->
          </ImgUpload>
        </el-descriptions-item>
      </el-descriptions>
    </div>

    <div class="info card fl_cen">
      <div class="info_list">
        <div class="title">商户收货信息</div>
        <div class="lists">
          <div class="item">
            <span>收货人：</span>{{ orderList.contactsName || '--' }}
          </div>
          <div class="item">
            <span>地址：</span>{{ orderList.province || ''
            }}{{ orderList.city || '' }}{{ orderList.district || ''
}}{{ orderList.address || '' }}
          </div>
          <div class="item">
            <span>手机号：</span>{{ orderList.payeeShopPhone || '--' }}
          </div>
        </div>
      </div>
      <div class="info_list">
        <div class="title">配送信息</div>
        <!-- <div v-if="indexStep == 3 && orderList.type == 'THTK'"
             class="lists">
          <div class="list_input fl_cen"
               style="margin-top:0px">
            <div>
              <el-input v-model="form.nums"
                        class="formInput"
                        placeholder="填写快递单号" />
              <el-autocomplete v-model="form.companyName"
                               class="formInput"
                               placeholder="填写快递公司"
                               :fetch-suggestions="querySearchAsync"
                               value-key="name"
                               @select="handleSelect" />
            </div>
            <el-button class="formbutton"
                       :loading="isBtnLoading"
                       @click.stop="addKuaidi">提 交</el-button>
          </div>
        </div> -->
        <div class="lists">
          <div class="item">
            <span>快递公司：</span>{{ orderList.kuaidiName || '--' }}
          </div>
          <div class="item">
            <span>快递单号：</span>{{ orderList.number || '--' }}
          </div>
          <div class="item">
            <span>预计送达：</span>{{ orderList.finishDate || '--' }}
          </div>
        </div>
      </div>
      <!-- <div class="info_list">
        <div class="title">付款信息</div>
        <div class="lists">
          <div class="item">
            <span>商品总额：</span>{{ orderList.amount || '--' }}
          </div>
          <div class="item"><span>支付方式：</span>在线支付</div>

          <div class="item">
            <span>付款时间：</span>{{ orderList.payTime || '' }}
          </div>
        </div>
      </div> -->
    </div>
    <div class="info card">
      <h4>物流数据</h4>
      <div v-if="!expressData.child || expressData.child.length == 0" style="color: #f56c6c; font-weight: 600">
        {{ expressData.step || '暂无物流数据' }}
      </div>
      <el-collapse v-model="activeName" v-if="expressData.child && expressData.child.length > 0">
        <el-collapse-item name="1">
          <template slot="title">
            <div style="line-height: 20px; height: 80px">
              <div style="color: #f56c6c; font-weight: 600">
                {{ expressData.step || '暂无物流数据' }}
              </div>
              <div>
                {{ expressData.description || '' }}
              </div>
            </div>
          </template>
          <div style="padding-top: 10px">
            <el-timeline v-if="expressData.child && expressData.child.length > 0">
              <el-timeline-item v-for="(activity, index) in expressData.child" :key="index" color="#F5222D">
                <div style="font-weight: 600">{{ activity.step || '' }}</div>
                <div>{{ activity.description || '' }}</div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="commodity card">
      <h4>商品信息</h4>
      <publicTable :table-data="orderList.detailList ? orderList.detailList : []" :colum-obj="columObj"
        :page-obj="pageObj" />
    </div>
    <div class="info card">
      <h4>协商历史</h4>
      <div class="">
        <div class="everyStep" v-for="(item, index) in orderList.historyList" :key="index">
          <div class="everyOne">
            <span>{{ item.consultPerson }}</span>
            <span style="font-size: 14px;color:#595959">{{ item.consultTime }}</span>
          </div>
          <div class="detail">
            {{ item.consultResult }}
          </div>
          <div class="detail">
            <ImgUpload v-model="item.consultImage" :isEdit="true" style="position:relative;top:-8px">
            </ImgUpload>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="拒绝说明" :visible.sync="dialogVisible" width="30%">
      <el-input type="textarea" placeholder="请输入内容" v-model="textarea" maxlength="200" show-word-limit>
      </el-input>
      <ImgUpload v-model="imageList" :limit="3" style="position:relative;top:0px">
        <template slot="tip">
          <p class="el-upload__tip" style="margin:0px">
            备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过5M;最多上传三张图片
          </p>
        </template>
      </ImgUpload>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>

    <resetDialog ref="rest-dialog" @submitAgree="sureGoods" @aftersaleInfo="aftersaleInfo" />

  </div>
</template>
<script>
import { buttonView } from '@/api/externalSalesRule/index'
import {
  cancelPurchase,
  getKuaidi,
  payResult,
  payPurchase,
  // expressStep,
  saleKuaidi
} from '@/api/servicecenter/pendingPayment.js'
// 所有售后相关的
import { aftersaleInfo, aftersaleBind, aftersaleDelivery, aftersalePlatform, aftersaleRepeal, expressStep, aftersaleAgree, aftersaleReject } from '@/api/afterSales/serviceSheet'
import QRCode from 'qrcodejs2'
import { mapState } from 'vuex'
import { checkReturnImages } from '@/utils/status'
import { returnFloat, correspondingVal, accMul } from '@/utils/validate'
import publicTable from '@/components/publicTable'
import { mapGetters } from 'vuex'
import Step from '@/components/step'
import avater from "/public/img/default.png"
import ImgUpload from '@/components/imgUpload'
import resetDialog from "./components/afterSubmitCertificate.vue";

export default {
  name: 'PurchaseDetail',
  components: { publicTable, Step, ImgUpload, resetDialog },
  created () {
    this.buttonView()
    this.afterSaleId = this.$route.query.afterSaleId
  },
  data () {
    return {
      afterSalesControl: '', // 售后控制
      rowId: '',
      imageList: [],
      textarea: '',
      dialogVisible: false,

      afterSaleId: '', // 传过来的id
      activeName: '1', // 手风琴展开
      expressData: {
        step: ''
      },
      radio: '',
      isBtnLoading: false,
      form: {
        nums: '',
        companyName: '',
        company: ''
      },
      isLoading: false,
      paydialog: false,
      id: '',
      orderId: '',
      list: [],
      orderList: {},
      icon: require('@/icons/img/icon_detail_status.png'),
      indexStep: 2,
      pageObj: {
        // 分页对象
        isShow: false,
        position: 'right', // 分页组件位置
        total: 0,
        page: {
          size: 10,
          current: 1,
          goodsName: '',
          supplierId: '',
          type: ''
        }
      },
      tableData: [],
      columObj: {
        tableName: 'multipleTable',
        // 选择框
        isShow: false,
        selection: false,
        // 选择框根据条件是否可选
        selectable: () => {
          return true
        },
        lazy: 'true',
        loading: false,
        // column列,columType(列类型,可选text(默认为普通文字模式),input(input可编辑框),switch(switch开关),image(图片),operation(操作按钮))
        // prop(参数),label(列名),width(宽度),align(对齐方式),sortable(是否支持排序)
        columnData: [
          {
            imageText: true,
            prop: 'goodsImage',
            prop2: 'goodsName',
            prop3: 'goodsFactory',
            label: '商品信息',
            width: '300',
            align: 'left',
            sortable: false,
            ownDefinedReturn: (row) => {
              return row.goodsFactory
            }
          },
          {
            text: true,
            prop: 'goodsSpecification',
            label: '规格',
            width: '',
            align: 'left',
            ownDefinedReturn: (row) => {
              // returnFloat(accMul(row.qty, row.price))
              return row.goodsSpecification
            }
          },
          {
            text: true,
            prop: 'approvalNumber',
            label: '批准文号',
            width: '200',
            align: 'left'
          },
          {
            // text: true,
            prop: 'amount',
            ownStyle: true,
            label: '应退金额',
            width: '200',
            align: 'left',
            ownDefinedReturn: (row) => {
              return row.amount
            }
          },
          {
            text: true,
            prop: 'qty',
            label: '数量',
            width: '200',
            align: 'center'
          },
          // {
          //   ownStyle: true,
          //   prop: 'amount',
          //   label: '小计',
          //   width: '',
          //   align: 'center',
          //   ownDefinedReturn: (row) => {
          //     return returnFloat(accMul(row.qty, row.amount))
          //   }
          // }
        ]
      },
      isQrCode: false,
      qrcode: null,
      total: '',
      orderPayId: '',
      status: '',
      stepArray: [{ name: '提交申请', desc: '' }, { name: '卖家审批', desc: '' }, { name: '买家退货', desc: '' }, { name: '卖家收货', desc: '' }, { name: '退款中', desc: '' }, { name: '完成', desc: '' }],
      avater: avater
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState('App', {
      selectList: 'selectList'
    }),
    getStatus () {
      return (type) => checkReturnImages(type)
    }
  },
  methods: {
    // 根据商户查询数据权限判断前端按钮展示
    buttonView () {
      buttonView().then(res => {
        if (res) {
          this.afterSalesControl = res.afterSalesControl
        }
      })
    },
    // 查询详情
    aftersaleInfo () {
      aftersaleInfo({ afterSaleId: this.afterSaleId }).then(res => {
        if (res.code == 0) {
          this.orderList = res.data;
          this.expressStep()
          if (this.orderList.operationType == 'CANCEL_SALE') {
            this.stepArray = [{ name: '提交申请', desc: res.data.orderTime || '' }, { name: '退款中' }, { name: '完成' }]
            this.indexStep = 2;
          } else {
            if (this.orderList.type == 'THTK') {
              this.stepArray = [{ name: '提交申请', desc: res.data.orderTime || '' }, { name: '卖家审批', desc: res.data.sellerExamineTime || '' }, { name: '买家退货', desc: res.data.returnGoodsTime || '' }, { name: '卖家收货', desc: res.data.finishDate || '' }, { name: '退款中', desc: res.data.finishDate || '' }, { name: '完成', desc: res.data.finishDate || '' }]
              switch (this.orderList.status) {
                case 'CHECKING':
                  this.indexStep = 2;
                  break;
                case 'WAIT_SALE':
                  this.indexStep = 3;
                  break;
                case 'SALEING':
                  this.indexStep = 4;
                  break;
                case 'REFUNDING':
                  this.indexStep = 5;
                  break;
                case 'FINISH':
                  this.indexStep = 6;
                  break;
                default:
                  this.indexStep = 1;
                  break;
              }
            } else {
              this.stepArray = [{ name: '提交申请', desc: res.data.orderTime || '' }, { name: '买家审批', desc: res.data.sellerExamineTime || '' }, { name: '退款中', desc: res.data.finishDate || '' }, { name: '完成', desc: res.data.finishDate || '' }]
              switch (this.orderList.status) {
                case 'CHECKING':
                  this.indexStep = 2;
                  break;
                case 'REFUNDING':
                  this.indexStep = 3;
                  break;
                case 'FINISH':
                  this.indexStep = 4;
                  break;
                default:
                  this.indexStep = 1;
                  break;
              }
            }
            if (this.orderList.status == 'REJECT') {
              this.stepArray = [{ name: '提交申请', desc: res.data.orderTime || '' }, { name: '驳回' }]
              this.indexStep = 2;
            } else if (this.orderList.status == 'CANCEL') {
              this.stepArray = [{ name: '提交申请', desc: res.data.orderTime || '' }, { name: '取消' }]
              this.indexStep = 2;
            }
          }

          this.orderList.afterSaleVoucher = this.orderList.afterSaleVoucher ? this.orderList.afterSaleVoucher.split(',') : []
          if (this.orderList.historyList.length > 0) {
            this.orderList.historyList.forEach(item => {
              if (item.consultImage) {
                item.consultImage = item.consultImage.split(',')
              } else {
                item.consultImage = []
              }
            })
          }

        }
      })
    },
    // 修改申请
    edit () {
      this.$router.push(
        {
          path: '/ordercenter/ordercenter-afterSale',
          query: {
            detailId: this.orderList.orderDetailId,
            type: 'APPLY_FOR',
            indexStep: 2,
            routerType: 'procument'
          }
        }
      )
    },
    // 撤销申请
    aftersaleRepeal () {
      aftersaleRepeal(
        {
          id: this.orderList.id
        }
      ).then(res => {
        if (res.code == 0) {
          this.$message.success('撤销申请成功')
          this.$router.push('/ordercenter/ordercenter-serviceSheet')
        } else {
          this.$message.success(res.msg)
        }
      })
    },
    handleSelect (e) {
      this.form.company = e.value
    },
    querySearchAsync (queryString, cb) {
      getKuaidi({ name: queryString }).then((res) => {
        console.log(res.data)
        cb(res.data)
      })
    },
    // 申请平台介入
    applyPlatform () {
      aftersalePlatform(
        {
          id: this.orderList.id
        }
      ).then(res => {
        if (res.code == 0) {
          this.$message.success('申请平台介入成功')
          this.aftersaleInfo()
        } else {
          this.$message.success(res.msg)
        }
      })
    },
    // 快递提交
    addKuaidi () {
      this.isBtnLoading = true
      let data = {
        id: this.orderList.id,
        kuaidiId: this.form.company,
        number: this.form.nums
      }
      if (!this.form.companyName) {
        this.$message.error('请填写快递公司～')
        this.isBtnLoading = false
        return false
      }
      if (!this.form.nums) {
        this.$message.error('请填写快递单号～')
        this.isBtnLoading = false
        return false
      }
      aftersaleBind(data)
        .then((response) => {
          if (response.code == 0) {
            this.isBtnLoading = false
            this.$message.success('快递信息提交成功～')
            this.aftersaleInfo()
          }
        })
        .catch(() => {
          this.isBtnLoading = false
        })
    },
    // 点击表格驳回
    aftersaleReject () {
      this.rowId = this.orderList.id
      this.dialogVisible = true
    },
    // 提交
    submit () {
      let params = {
        id: this.rowId,
        remark: this.textarea,
        rejectVoucher: this.imageList.length > 0 ? this.imageList.join(',') : ''
      }
      aftersaleReject(params).then(res => {
        if (res.code == 0) {
          this.$message.success('已驳回')
          this.textarea = ''
          this.imageList = []
          this.aftersaleInfo()
          this.dialogVisible = false
        } else {
          this.$message.error('驳回失败')
        }
      })
    },
    // 点击同意确认收货
    clickAftersaleDelivery (id, rejectVoucher) {
      console.log(id)
      this.$confirm('此操作不可取消, 是否确认收货?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.aftersaleDelivery(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 点击表格确认收货
    aftersaleDelivery (id) {
      aftersaleDelivery(
        {
          id: id
        }
      ).then(res => {
        if (res.code == 0) {
          this.$message.success('确认收货')
          this.aftersaleInfo()
          this.buttonView()
        } else {
          this.$message.error('收货失败')
        }
      })
    },
    // 点击同意
    // clickAftersaleDelivery() {

    //   let params = {
    //     id: this.orderList.id,
    //     orderId: this.orderList.orderId,
    //     accountName: this.orderList.accountName,
    //     depositBank: this.orderList.depositBank,
    //     receivablesAccount: this.orderList.receivablesAccount,
    //   };
    //   if (this.orderList.payType == 3) {
    //     console.log(params, 'params')
    //     this.$refs['rest-dialog'].init(params, 'afterDetail');
    //   } else {
    //     this.$confirm('此操作将同意确认收货, 是否继续?', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }).then(() => {
    //       this.sureGoods();
    //     }).catch(() => {
    //       this.$message({
    //         type: 'info',
    //         message: '已取消'
    //       });
    //     });
    //   }
    // },

    // 提交同意请求
    submitAgree (rejectVoucher) {
      let params = {
        id: this.orderList.id,
        orderId: this.orderList.orderId,
        accountName: this.orderList.accountName,
        depositBank: this.orderList.depositBank,
        receivablesAccount: this.orderList.receivablesAccount,
      };
      // if (this.orderList.payType == 3) {
      //   if(this.orderList.type == 'TK'){
      //     this.$refs['rest-dialog'].init(params, 'afterDetail'); 
      //   }else{
      //     this.submitAgreeFn(rejectVoucher); 
      //   } 
      // } else { 
      //   this.submitAgreeFn(rejectVoucher); 
      // } 
      this.submitAgreeFn(rejectVoucher);
    },

    submitAgreeFn (rejectVoucher) {
      let params = {};
      rejectVoucher ? params = {
        id: this.orderList.id,
        rejectVoucher: rejectVoucher
      } : params = {
        id: this.orderList.id
      }
      aftersaleAgree(
        params
      ).then(res => {
        if (res.code == 0) {
          this.$message.success('款项将在48小时内原路返回')
          this.aftersaleInfo()
        } else {
          this.$message.error('审核失败')
        }
      })
    },
    // 确认收货
    sureGoods () {
      aftersaleDelivery(
        {
          id: this.orderList.id
        }
      ).then(res => {
        if (res.code == 0) {
          console.log(res)
          this.$message.success('收货成功')
          this.aftersaleInfo()
        }
      })
    },

    // 页码变化
    handleCurrentChange (e) {
      this.pageObj.pageData.current = e
    },
    // 条数变化
    handleSizeChange (e) {
      this.pageObj.pageData.size = e
      this.pageObj.pageData.current = 1
    },
    statusBtn (row) {
      return correspondingVal(
        'basePurchaseOrderEnum',
        row,
        'status',
        this.selectList
      )
    },
    qrCodeCreate () {
      payPurchase(this.id)
        .then((res) => {
          if (res.code == 0) {
            this.isQrCode = true
            this.qrlink = res.data.qrCode
            this.orderId = res.data.orderId
            this.total = res.data.amount
            this.$nextTick(() => {
              this.newqrcode()
              let setTime = setInterval(() => {
                if (this.isQrCode) {
                  if (this.status == '2') {
                    clearInterval(setTime)
                    this.isQrCode = false
                    this.$message.success('订单支付成功')
                  } else if (this.status == '3') {
                    clearInterval(setTime)
                  } else {
                    this.payResult(res.data.id)
                  }
                } else {
                  clearInterval(setTime)
                }
              }, 2000)
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {
          this.isQrCode = false
        })
    },
    qrCodeBtn () {
      this.status = '3'
      this.isQrCode = false
    },
    newqrcode () {
      if (this.qrcode) {
        this.qrcode.clear()
      }
      this.qrcode = new QRCode('qrcode', {
        width: 200, // 设置宽度，单位像素
        height: 200, // 设置高度，单位像素
        text: this.qrlink // 设置二维码内容或跳转地址
      })
    },
    payResult (id) {
      payResult(id).then((res) => {
        this.status = res.data.status
      })
    },
    // 取消订单
    cancelClick (id) {
      this.isLoading = true
      this.$confirm('订单取消后，订单消失，需要您再次下单', '取消订单', {
        confirmButtonText: '确认',
        cancelButtonText: '返回',
        type: 'warning'
      })
        .then(() => {
          cancelPurchase(id)
            .then((response) => {
              let res = response.data
              if (res.code == 0) {
                this.isLoading = false
                this.$message.success('订单取消成功')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch(() => {
              this.isLoading = false
            })
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    statusStyle (status) {
      status = Number(status)
      // 1.待付款,2.待发货, 3.已发货,4.待收货，5.交易取消, 6申请退款 7.已退款  8.已完成
      if (status == 1) {
        this.indexStep = 1
      } else if (status == 2 || status == 11) {
        this.indexStep = 2
      } else if (status == 3) {
        this.indexStep = 3
      } else if (status == 8 || status == 4) {
        this.indexStep = 4
      } else if (status == 9 || status == 10) {
        this.indexStep = 5
      } else {
        this.indexStep = 0
      }
    },
    expressStep () {
      expressStep({ orderId: this.orderList.id }).then((res) => {
        if (res.code == 0 && res.data) {
          this.expressData = res.data
        } else {
          // this.$message.error('暂无数据')
        }
      })
    }
  },
  mounted () {
    this.afterSaleId = this.$route.query.afterSaleId
    this.aftersaleInfo()

  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/main";

.classification {
  background: #eee;
  padding: 0px 0px;

  .commodity {
    ::v-deep .el-table {
      .el-table__header {
        thead {
          th {
            // background-color: #fff !important;
          }
        }
      }
    }
  }

  .merchant {
    padding: 32px;

    .firstBox {
      padding: 10px 0px;
      font-size: 14px;
    }

    .secondBox {
      color: #91939a;
    }
  }

  .everyStep {
    padding: 10px 0;
    border-bottom: 1px solid #f5f5f5;

    .detail {
      font-size: 14px;
      margin-top: 5px;
      color: #595959;
    }

    .everyOne {
      display: flex;
      justify-content: space-between;
    }
  }

  ::v-deep .el-descriptions__title {
    color: #262626;
  }

  h4 {
    font-size: 16px;
    color: #262626;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

.info_list {
  .title {
    font-size: 16px;
  }

  .lists {
    padding-top: 8px;

    .item {
      margin-bottom: 8px;
    }
  }
}
</style>
