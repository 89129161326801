<!-- 取消售卖 -->
<template>
  <div class="commodity card">
    <div class="header">
      <div class="left_topic ">订单评论</div>
    </div>
    <div class="commemt-list">
      <div class="list comment-hover" v-for="(item, key) in commentList" :key="key">
        <el-row class="list-title">
          <el-col :span="12" class="name">{{ item.createBy }}</el-col>
          <el-col :span="12" class="time">{{ item.updateTime }}</el-col>
        </el-row>
        <div>
          <el-row class="list-image-row">
            <el-col :span="12" class="list-star">
              <div v-if="item.source == 2">
                {{ item.comment }}
              </div>
              <div v-if="item.source == 1">
                <div class="label-list">
                  <div class="title">商品是否与描述相符 </div>
                  <div style="">
                    <el-rate :max="5" :show-score="false" :allow-half="false" v-model="item.goodsScore" show-text
                      :disabled="disabled" :texts="texts" :colors="colors">
                    </el-rate>
                  </div>
                </div>
                <div class="label-list">
                  <div class="title">物流是否按时到达</div>
                  <div style="">
                    <el-rate :max="5" :show-score="false" :allow-half="false" v-model="item.logisticScore" show-text
                      :disabled="disabled" :texts="texts" :colors="colors">
                    </el-rate>
                  </div>
                </div>
                <div class="label-list">
                  <div class="title">客服回复消息是否及时</div>
                  <div style="">
                    <el-rate :max="5" :show-score="false" :allow-half="false" v-model="item.customerServiceScore"
                      show-text :disabled="disabled" :texts="texts" :colors="colors">
                    </el-rate>
                  </div>
                </div>
                <div class="label-list">
                  <div class="title">售后是否积极响应</div>
                  <div style="">
                    <el-rate :max="5" :show-score="false" :allow-half="false" v-model="item.afterSalesScore" show-text
                      :disabled="disabled" :texts="texts" :colors="colors">
                    </el-rate>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="list-image" v-if="item.imageList">
                <ImgUpload v-model="item.imageList" :limit="5" :isEdit="disabled"></ImgUpload>
              </div>
            </el-col>
          </el-row>
          <el-row class="list-comment" v-if="item.source == 1">
            <div>
              {{ item.comment }}
            </div>
          </el-row>
        </div>

      </div>
    </div>
    <div class="no-commnet" v-if="commentList.length == 0">
      暂无评论
    </div>
  </div>
</template>
  
<script>
import ImgUpload from "@/components/imgUpload";

export default {
  components: { ImgUpload },
  data () {
    return {
      disabled: true,
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      texts: ["极差", "失望", "一般", "满意", "惊喜"],
    };
  },
  props: {
    commentList: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  computed: {},
  watch: {},
  methods: {

  },
  created () {

  },
  mounted () {

  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
}
</script>
<style lang='scss' scoped>
@import "../../styles/main";

.classification {
  background: #eee;

  .commodity {
    ::v-deep .el-table {
      .el-table__header {
        thead {
          th {
            background-color: #fff !important;
          }
        }
      }
    }
  }

  .fl_cen {
    justify-content: flex-start;
  }

  .list_input {
    margin-top: 0px;
  }

  .info_list {
    ::v-deep .el-tabs__header {
      line-height: inherit;
      height: auto;
    }

    ::v-deep .el-tabs__item {
      height: auto;
      line-height: inherit;
      font-size: 14px;
    }
  }
}

.commemt-list {
  .list {
    display: block;
    padding: 10px;
    border-bottom: 1px solid #ebeef5;

    &:nth-last-child(1) {
      border-bottom: none;
    }
  }

  .list-title {
    font-size: 14px;
    color: #595959;
    display: flex;
    justify-content: space-between;
    line-height: 30px;

    .name {
      font-size: 16px;
      font-weight: bold;
    }

    .time {
      text-align: right;
    }
  }

  .list-t {
    font-size: 14px;
    color: #595959;
    line-height: 30px;
    padding: 0 10px;

    .title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
}

.list-star {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #595959;
}

.label-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;

  .title {
    width: 200px;
  }
}

.comment-hover {}

.no-commnet {
  font-size: 14px;
  color: #595959;
  line-height: 30px;
  padding: 15px;
}

.headerTabs {
  margin-bottom: 30px;
  margin-top: -20px;
}

.list-image {
  display: flex;
  justify-content: flex-end;

}

.formbutton {
  height: 92px;
  margin-top: 3px;
}

.list-comment {
  font-size: 14px;
  color: #595959;
  line-height: 30px;
}

.list-image-row {
  display: flex;
  justify-content: space-between;
}

.flex-row {
  display: flex;
  justify-content: space-between;
}

::v-deep.uploadWrapper ul {
  padding-inline-start: 0px !important;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}

::v-deep.vue-draggable {}
</style>