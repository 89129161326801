var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-root" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            title: _vm.title,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "518px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.onClosed,
          },
        },
        [
          _c("div", { staticClass: "content form-content" }, [
            _c("div", { staticClass: "row big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("合同名称")]),
              _c(
                "div",
                { staticClass: "value input fill" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "small",
                        filterable: "",
                        placeholder: "请选择合同名称",
                        "value-key": "id",
                      },
                      model: {
                        value: _vm.selectedContract,
                        callback: function ($$v) {
                          _vm.selectedContract = $$v
                        },
                        expression: "selectedContract",
                      },
                    },
                    _vm._l(_vm.contractList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.contractName, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("合作公司")]),
              _c("div", { staticClass: "value input fill" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.selectedContract
                        ? _vm.selectedContract.coopShopName
                        : ""
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "row big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("指标名称")]),
              _c(
                "div",
                { staticClass: "value input fill" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      maxlength: "50",
                      disabled: _vm.isLook,
                      placeholder: "请输入指标名称",
                    },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("指标金额")]),
              _c(
                "div",
                { staticClass: "value input fill" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      type: "number",
                      maxlength: "10",
                      disabled: _vm.isLook || !_vm.canModifyPrice,
                      placeholder: "请输入指标金额",
                    },
                    on: { input: (e) => _vm.thresholdReg("price", e) },
                    model: {
                      value: _vm.price,
                      callback: function ($$v) {
                        _vm.price = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "price",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v("关闭")]
              ),
              !_vm.isLook
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }