<!--  -->
<template>
  <div class="classification">
    <div class="header">
      <span class="font">控销用户设置</span>
    </div>
    <div class="top">
      <el-form ref="formTool"
               :model="formTool"
               :inline="true"
               style="text-align:left;">
        <el-form-item prop="schemeName"
                      style="margin-bottom: 24px !important;"
                      label="控销组名称"
                      :rules="[
      { required: true, message: '请输入控销组名称' }
    ]">
          <el-input v-model="formTool.schemeName"
                    placeholder="请输入控销组名称"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     type="primary"
                     @click="addTemplate()">保存</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     @click="cancel()">返回</el-button>
        </el-form-item>
        <el-form-item prop="name"
                      style="margin-bottom: 24px !important;"
                      label="导入客户">
          <el-upload class="upload-demo"
                     :action="activeType==1?action:actionJh"
                     :data="actionData"
                     :before-upload="beforeUpload"
                     multiple
                     :name="'multipartFile'"
                     :auto-upload="true"
                     :headers="headers"
                     :on-success="success"
                     :on-error="error"
                     :show-file-list="false">
            <el-button size="small"
                       type="primary">导入excel文件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     @click="downLoad()">下载模板</el-button>
        </el-form-item>

      </el-form>
    </div>
    <div class="top-center">
      <el-form ref="form"
               :model="form"
               :inline="true"
               style="text-align:left;">
        <el-form-item prop="clientName"
                      style="margin-bottom: 16px !important;"
                      label="">
          <el-input v-model="form.clientName"
                    placeholder="客户名称"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="clientErpId"
                      style="margin-bottom: 16px !important;"
                      label="">
          <el-input v-model="form.clientErpId"
                    placeholder="客户ERPID"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item prop="clientPhone"
                      style="margin-bottom: 16px !important;"
                      label="">
          <el-input v-model="form.clientPhone"
                    placeholder="手机号"
                    clearable
                    size="small" />
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     type="primary"
                     @click="getWarebase(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small"
                     @click="reset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="center"
         style="position:relative">
      <MyTable :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getList"
               @handleSelectionChange="handleSelectionChange">
        <template slot="menu"
                  slot-scope="scope">
          <!-- <el-button type="button" size="mini" icon="el-icon-upload2" @click="deleteHandle(scope.row)">上架</el-button> -->
          <el-link :underline="false"
                   type="primary"
                   @click="deleteHandle(scope.row)">删除</el-link>
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>
import MyTable from '@/components/myTable'
import { getToken } from "@/utils/auth";
import { drugenumeration } from '@/utils/enumeration'
import { salesgroupinfoInsertOrUpdate, salesgroupclientinfoPage, salesgroupclientinfoId, salesgroupinfoDetail, downloadFile } from '@/api/salesControl/commodity'
import avater from "/public/img/default.png"

export default {
  name: 'WareProduct',
  components: { MyTable },
  data () {
    return {
      id: '',
      action: process.env.VUE_APP_BASE_API + '/product/sales/salesgroupclientinfo/insert', // 上传地址
      actionJh: process.env.VUE_APP_BASE_API + '/product/sales/salesgroupclientinfo/insert/jh', // 上传地址
      actionData: {
        groupId: ''
      },
      avater: avater,
      formTool: {
        schemeName: ''
      },
      form: {
        clientName: '',
        clientErpId: '',
        clientPhone: '',
      },
      selectionList: [],
      operation: true,
      selection: false,
      index: false,
      tableData: [
      ],
      tableOption: [
        { label: '客户名称', prop: 'clientName', width: "260px" },
        { label: '客户ERPID', prop: 'clientErpId' },
        { label: '手机号', prop: 'clientPhone' }
      ],
      page: {
        total: 0,
        current: 1,
        size: 20,
        pageSizes: [10, 20, 30, 50]
      },
      activeType:1
    }
  },
  computed: {
    headers: function () {
      return {
        Authorization: 'Bearer ' + getToken(),
      }
    },
  },
  watch: {},
  created () {
    this.id = this.$route.query?.id || ''
    this.actionData.groupId = this.id
    if (this.id) {
      this.salesgroupinfoDetail(this.id)
      this.getWarebase()
    }
  },
  mounted () {
    if(this.$route.query.activeName =='three' || this.$route.query.type == 2){
        this.activeType = 2
      }
  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    // 保存模板
    addTemplate () {
      let type = 1;
      if(this.$route.query.activeName =='three' || this.$route.query.type == 2) {
        type = 2;
      }
      this.$refs.formTool.validate(res => {
        if (res) {
          let params = {
            id: this.id,
            schemeName: this.formTool.schemeName,
            type:type,
          }
          salesgroupinfoInsertOrUpdate(params).then(res => {
            this.actionData.groupId = res
            this.$message.success('保存成功')
          })
        }
      })
    },
    // deleteHandle 删除
    deleteHandle (row) {
      salesgroupclientinfoId(row.id).then(res => {
        this.$message.success('删除成功')
        this.getWarebase()
      })
    },
    // 下载模板
    downLoad () {
      let type =1;
      if(this.$route.query.activeName =='three' || this.$route.query.type == 2){
        type = 2
      }
      let params ={}; 
      params.type = type;
      downloadFile(params).then(res => {
        window.open(res)
      })
    },
    // 上传之前
    beforeUpload (file) {
      console.log(file)
    },
    // 上传文件成功
    success (res) {
      if (!res.code) {
        this.$message.success('上传成功')
        this.cancel()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传文件失败
    error (err) {
      this.$message.error(err.msg)
    },
    // 重置查询条件
    reset () {
      this.$refs.form.resetFields()
    },
    // 切换分页
    getList () {
      this.getWarebase()
    },
    // 勾选
    handleSelectionChange (val) {
      this.selectionList = val
    },
    // 查询列表
    getWarebase (current) {
      if (current) { // 搜索条件改变从第一页开始查询
        this.page.current = current
      }
      const params = {
        ...this.form,
        current: this.page.current,
        size: this.page.size,
        groupId: this.id
      }
      salesgroupclientinfoPage(params).then(res => {
        this.tableData = res.records
        this.page.total = res.total
      })
    },
    cancel () {
      // this.$router.push({
      //   path: '/salesControlMange/salesControl-controlPinForm-list',
      //   query: {
      //     tab: 'first'
      //   }
      // })
      this.$router.back(-1)
    },
    // 查询详情
    salesgroupinfoDetail (id) {
      salesgroupinfoDetail(id).then(res => {
        this.formTool.schemeName = res.schemeName
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.classification {
  padding: 0px;
  .top {
    margin: 24px 24px 0 24px;
    border-bottom: 1px solid #f5f5f5;
  }
  .top-center {
    margin: 16px 24px 0 24px;
    border-bottom: 1px solid #f5f5f5;
  }
}
.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px !important;
  border-bottom: 1px solid #f5f5f5;
  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }
  .el-form-item {
    margin: 0px 0px 0px 8px !important;
  }
}
.center {
  margin-top: 16px;
  padding: 0 24px 0 24px;
  height: calc(100% - 290px);
  ::v-deep .imageP {
    width: calc(100% - 110px);
    p {
      margin: 0 0 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .shangjia {
    position: absolute;
    z-index: 2;
    bottom: -45px;
  }
}
</style>
