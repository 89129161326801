var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            action: _vm.action,
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            "before-remove": _vm.beforeRemove,
            multiple: "",
            limit: _vm.limit,
            "on-exceed": _vm.handleExceed,
            "file-list": _vm.fileList,
            "on-success": _vm.onSuccessUpload,
            disabled: _vm.isEdit,
          },
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small", type: "primary", disabled: _vm.isEdit } },
            [_vm._v("点击上传")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }