<template>
    <div class="main">
        <div style="display: flex;align-items: center;height: 100px;">
            <img src="../../../assets/paysuccess.png" alt="" style="width: 40px; heihgt: 40px;" />
            <span style="font-size: 20px;font-weight: 600;color: #262626;margin-left: 10px;">您已成功付款：</span>
            <span style="font-size: 16px;color: #F5222D;">¥</span>
            <span style="font-size: 24px;font-weight: 600;color: #F5222D;">{{payInfo.sumPrice}}</span>
        </div>
        <div style="display: flex;align-items: center;height: 100px;">
            <img src="../../../assets/payaddress.png" alt="" style="width: 20px; heihgt: 20px;" />
            <span style="font-size: 16px;font-weight: 600;color: #262626;">货物寄送至：</span>
            <span style="font-size: 16px;color: #262626;">{{ payInfo.provinceName }}{{ payInfo.cityName }} {{ payInfo.areaName }} {{ payInfo.address }}（{{ payInfo.concatName }}  收） {{ payInfo.concatPhone }}</span>
        </div>
        <div style="display: flex;align-items: center;height: 100px;">
            <p style="font-size: 16px;color: #F5222D;cursor: pointer;" @click="goGoodsList">已买到商品列表</p>
            <div class="line"></div>
            <!-- <span style="font-size: 16px;color: #F5222D;">货物寄送至：</span>
            <div class="line"></div> -->
            <span style="font-size: 16px;color: #595959;">供应商：{{getSupplier(payInfo.quickVoList)}}</span>
        </div>
        <div style="width: 95%;height: 1px;border: 1px dashed #d9d9d9;">

        </div>
    </div>
</template>
<script >
import { getToken } from "@/utils/auth";

export default {
    components: {},
    props: {
        payInfo: {
            type: Object,
            default: () => {
            return {}
            },
        }
    },
    data () {
        return {

        }
    },
    methods:{
        
        goGoodsList(){
            const linkUrl =  process.env.VUE_APP_API_BASE_URL
            window.open(`${linkUrl}#/login?accessToken=${getToken()}&url=/ordercenter/ordercenter-procurement`, "_blank");
        },
        getSupplier(arr){
            if (arr && arr.length) {
                let tmp = []
                arr.forEach(item => {
                    tmp.push(item.shopName)
                });
                return tmp.join(',')
            }else{
                return ''
            }
        }
    }
}


</script>
<style lang="scss" scoped>
.main{
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.line{
    width: 1px;
    height: 14px;
    background: #bfbfbf;
    margin: 0px 10px ;
}
</style>