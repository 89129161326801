var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v(" 企业资质明细 ")]),
      _c(
        "span",
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.downLoad()
                },
              },
            },
            [_vm._v("下载资质")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "center" },
      [
        _c("CompanyInfo", { ref: "infoBox" }),
        _vm.btnShow
          ? _c(
              "div",
              { staticClass: "status_txt" },
              [_vm._v("当前审核状态: "), _c("MyTypes")],
              1
            )
          : _vm._e(),
        _vm.btnShow
          ? _c(
              "div",
              { staticClass: "dia_btn" },
              [
                _c("MyButton", {
                  staticClass: "btn_w150",
                  attrs: { type: "", icon: "", text: "返回上一页" },
                  on: { click: _vm.goBack },
                }),
                _c("MyButton", {
                  staticClass: "btn_w150",
                  attrs: { type: "primary", icon: "", text: "审核通过" },
                  on: {
                    click: function ($event) {
                      return _vm.openDialog(true)
                    },
                  },
                }),
                _c("MyButton", {
                  staticClass: "btn_w150",
                  attrs: { type: "danger", icon: "", text: "驳回" },
                  on: {
                    click: function ($event) {
                      return _vm.openDialog(false)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }