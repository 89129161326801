var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _vm.tabsIndex == 1
        ? _c("MyButton", {
            staticStyle: { position: "absolute", right: "30px", top: "10px" },
            attrs: { type: "primary", icon: "", text: "新增发票抬头" },
            on: {
              click: function ($event) {
                return _vm.addHead(null)
              },
            },
          })
        : _vm._e(),
      _c("MyTabs", {
        staticClass: "myTabs",
        attrs: { tabi: _vm.tabsIndex, tabs: _vm.tabsData, numhide: "" },
        on: { change: _vm.tabChange },
      }),
      _vm.tabsIndex == 0 ? _c("div", [_c("InvoiceHeadMy")], 1) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }