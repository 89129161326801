<template>
  <payment :accountType="2"></payment>
</template>
<script>
import payment from "./payment";
export default {
  components: { payment },
  data() {
    return {};
  },
  filters: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
</style>