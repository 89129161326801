<template>
  <div class="harf-block">
    <div class="form-content" v-if="formIn.status == 'FAIL'">
      <div class="title-block">
        <span class="text">驳回原因</span>
      </div>
      <div class="row mutlti big">
        <!-- //<div class="tip">驳回原因</div> -->
        <div class="value fill">
          <div class="flag-block">
            {{ formIn.rejectReason }}
          </div>
        </div>
      </div>
    </div>

    <div class="big-block" v-if="showFormLeft">
      <div class="title-block">
        <span class="text">价格/库存</span>
      </div>
      <div class="form-content">
        <div class="row">
          <div class="tip">ERP编码</div>
          <div class="value input">
            <el-input size="small" placeholder="请输入" v-model="formIn.medicineCode" @input="medicineCodeInput" maxlength="20"/>
          </div>
        </div>

        <div class="row">
          <div class="tip"> <span class="weight">*</span> 总库存</div>
          <div class="value input">
            <el-input size="small" placeholder="请输入" v-model="formIn.goodsSkuInventory" :disabled="formIn.beSynStock" maxlength="8" v-positive-integer/>
          </div>
          <div class="value fill">
            <el-checkbox v-model="formIn.beSynStock" ref="checkboxSynStock" @change="stockChange">自动同步库存</el-checkbox>
          </div>
        </div>

        <!-- 特价或单品包邮 -->
        <div class="row" v-if="selectType && (selectType.label == '单品包邮' || selectType.label == '特价')">
          <div class="tip">原价</div>
          <div class="value input">
            <el-input size="small" placeholder="请输入" v-model="formIn.originalPrice" :disabled="formIn.beSynPrice"  v-positive-five-limit />
          </div>
        </div>
        <!-- businessType === 2 消肿止痛贴 -->
        <div v-if="formIn.businessType === 2">
          <div class="row">
            <div class="tip">价格</div>
            <div class="value input">
              <el-input size="small" placeholder="请输入" v-model="formIn.xzztPrice"  v-positive-five-limit
                :disabled="true" />
            </div>
          </div>
        </div>
        <!-- 一口价 或特价-->
        <div v-else>
          <div v-if="selectType && (selectType.label == '一口价' || selectType.label == '特价')">
            <div class="row">
              <div class="tip"><span class="weight">*</span>价格</div>
              <div class="value input">
                <el-input size="small" placeholder="请输入" v-model="formIn.salePrice"  v-positive-five-limit
                  :disabled="formIn.participationSubsidy || formIn.beSynPrice" />
              </div>
              <div class="value fill" v-if="selectType && (selectType.label == '一口价')" >
                <el-checkbox v-model="formIn.beSynPrice" @change="erpPriceChange">自动同步价格</el-checkbox>
              </div>
            </div>
            <div v-if="formIn.participationSubsidy" style="color: #262626;font-size: 14px;margin-left:9em">
              原价：¥{{ formIn.originalPrice }}，此商品正在参加平台补贴，点击去
              <el-link :underline="false" type="primary" class="btn" @click="xiugaiPrice">修改价格</el-link>
            </div>
          </div>
        </div>
        <!-- 一口价 -->
        <div v-if="selectType && selectType.label == '一口价'">
          <div class="row">
            <div class="tip">市场价</div>
            <div class="value input"> 
              <el-input size="small" placeholder="请输入" v-model="formIn.marketPrice" v-positive-five-limit  />
            </div>
          </div>
          <div class="row">
            <div class="tip">建议零售价</div>
            <div class="value input">
              <el-input size="small" placeholder="请输入" v-model="formIn.purchasePrice" v-positive-five-limit />
            </div>
          </div>
        </div>

        <!-- 单品包邮 -->
        <div v-if="selectType && selectType.label == '单品包邮'">
          <div class="row">
            <div class="tip"><span class="weight">*</span>价格</div>
            <div class="value input">
              <el-input size="small" placeholder="请输入" v-model="formIn.groupBuyPrice" v-positive-five-limit />
            </div>
          </div>

          <div class="row">
            <div class="tip"><span class="weight">*</span>购买倍数</div>
            <div class="value input">
              <el-input size="small" v-positive-integer placeholder="请输入" v-model="formIn.multiple"  maxlength="4"/>
            </div>
            <span style="font-size:12px;color:#606266;margin-left:20px;">注意：包邮量必须是购买倍数的整数倍</span>
          </div>
          <div class="row">
            <div class="tip"><span class="weight">*</span>单店采购上限</div>
            <div class="value input">
              <el-input size="small" placeholder="请输入" v-model="formIn.userMaxTotalBuyCount" maxlength="8" v-positive-integer/>
            </div>
          </div>

          <div class="row">
            <div class="tip"><span class="weight">*</span>采购总上限</div>
            <div class="value input">
              <el-input size="small" placeholder="请输入" v-model="formIn.maxGroupTotalCount" maxlength="8" v-positive-integer/>
            </div>
          </div>

        </div>

        <div class="row" v-if="selectType && (selectType.label == '特价')">
          <div class="tip"> <span class="weight">*</span> 活动库存</div>
          <div class="value input">
            <el-input size="small" placeholder="请输入" v-model="formIn.activeInventory" maxlength="8" v-positive-integer/>
          </div>
          <div class="value fill">
            <el-checkbox v-model="formIn.showInventoryFlag" :true-label="1" :false-label="0">是否展示给客户剩余库存量</el-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="tip"><span class="weight">*</span>单位</div>
          <div class="value input">
            <el-select v-model="formIn.goodsSkuUnit" placeholder="请选择" size="small">
              <el-option v-for="item in unitList" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="row">
          <div class="tip" v-if="selectType && (selectType.label == '一口价' || selectType.label == '特价')"><span
              class="weight">*</span>起批量</div>
          <div class="tip" v-if="selectType && selectType.label == '单品包邮'"><span class="weight">*</span>包邮量</div>
          <div class="value input">
            <el-input size="small" placeholder="请输入" v-model="formIn.startWholesale" maxlength="4" v-positive-integer/>
          </div>

          <div class="value fill" v-if="selectType && selectType.label == '一口价'">
            <el-checkbox v-model="formIn.useTimes">倍数购买</el-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="tip"><span class="weight">*</span>有效期至</div>
          <div class="value input">
            <el-date-picker v-model="formIn.expireTime" type="date" placeholder="请选择" size="small" :editable="false"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
          <!-- <div class="value fill">
                        <el-checkbox v-model="formIn.beSynDate" :disabled="!formIn.medicineCode"
                            @change="dateChangeSyn">自动同步有效期和生产日期</el-checkbox>
                    </div> -->
        </div>
        <div class="row">
          <div class="tip"><span class="weight">*</span>生产日期</div>
          <div class="value input">
            <el-date-picker v-model="formIn.producedTime" type="date" placeholder="请选择" size="small" :editable="false"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
        </div>

        <!-- 特价 -->
        <div v-if="selectType && selectType.label == '特价'">
          <div class="row">
            <div class="tip"><span class="weight">*</span>购买倍数</div>
            <div class="value input">
              <el-input size="small" v-positive-integer placeholder="请输入" v-model="formIn.multiple" maxlength="4"/>
            </div>
            <span style="font-size:12px;color:#606266;margin-left:20px;">注意：起拼量必须是购买倍数的整数倍</span>
          </div>
          <div class="row">
            <div class="tip"><span class="weight">*</span>单店采购上限</div>
            <div class="value input">
              <el-input size="small" placeholder="请输入" v-model="formIn.purchaseUpperLimit"
                 maxlength="8" v-positive-integer
                @input="changePurchaseUpperLimitInput" />
            </div>
            <div class="value fill">
              <el-checkbox v-model="formIn.showUpperLimit" @change="changePurchaseUpperLimit">不限</el-checkbox>
            </div>
          </div>
          <!-- 上限刷新周期 -->
          <div class="row">
            <div class="tip">上限刷新周期</div>
            <div class="value input">
              <el-select v-model="formIn.upperLimitRefreshCycle" placeholder="请选择" size="small">
                <el-option v-for="item in upperLimitRefreshCycle" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="tip">上限启用时间</div>
            <div class="value input">
              <el-date-picker v-model="formIn.upperLimitStartTime" :picker-options="pickerOptions_day"
                value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择"
                v-if="formIn.upperLimitRefreshCycle == 1" size="small">
              </el-date-picker>
              <el-date-picker v-model="formIn.upperLimitStartTime" :picker-options="pickerOptions_day"
                value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择"
                v-if="formIn.upperLimitRefreshCycle == 2" size="small">
              </el-date-picker>
              <el-date-picker v-model="formIn.upperLimitStartTime" :picker-options="pickerOptions_week"
                value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择"
                v-if="formIn.upperLimitRefreshCycle == 3" size="small">
              </el-date-picker>
              <el-date-picker v-model="formIn.upperLimitStartTime" :picker-options="pickerOptions_month"
                value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择"
                v-if="formIn.upperLimitRefreshCycle == 4" size="small">
              </el-date-picker>
              <el-date-picker v-model="formIn.upperLimitStartTime" :picker-options="pickerOptions_day"
                value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择"
                v-if="formIn.upperLimitRefreshCycle == 5" size="small">
              </el-date-picker>

            </div>
            <div class="value fill small-tip">
              <span style="font-size:12px;color:#606266;">
                上限启用时间根据周期而定：活动期内- 上架时间起生效；单笔 - 设置后立即生效；
                每天 - 设置当天00:00起效；每周 - 设置当周周一00:00起效；每月 - 设置当月1日00:00起效
              </span>
            </div>
          </div>
          <!-- _month -->

          <div class="row">
            <div class="tip"><span class="weight">*</span>活动开始时间</div>
            <div class="value input">
              <el-date-picker v-model="formIn.activityStartTime" type="datetime" placeholder="请选择" size="small"
                :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </div>
            <div class="value fill small-tip">
              <el-checkbox v-model="formIn.eventCountdownFlag" :true-label="1" :false-label="0">是否显示倒计时样式</el-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="tip"><span class="weight">*</span>活动结束时间</div>
            <div class="value input">
              <el-date-picker v-model="formIn.activityEndTime" :picker-options="pickerOptions" type="datetime" default-time="23:59:59"
                placeholder="请选择" size="small" value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </div>
            <div class="value fill small-tip">
              <span style="font-size:12px;color:#606266;">
                商品在活动开始时间到活动结束时间之间显示在app/PC。超过活动结束时间后不可编辑或延长时间
              </span>
            </div>
          </div>
          <div class="row">
            <div class="tip"><span class="weight">*</span>能否特价凑单</div>
            <div class="radio-list">
              <div class="list-a">
                <el-radio v-model="formIn.uniqueFlag" :label="1">订单中只能有此品一个特价</el-radio>
              </div>
              <div class="list-a">
                <span class="tips">一笔订单中只能有此商品一个特价商品，其他特价商品不能一同下单</span>
              </div>
              <div class="list-a">
                <el-radio v-model="formIn.uniqueFlag" :label="0">能与其他特价商品凑单</el-radio>
              </div>
              <div class="list-a">
                <span class="tips">一笔订单中添加此特价商品后，还能添加其他特价商品凑单</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="big-block">
      <div class="title-block">
        <span class="text">图片/其他</span>
      </div>
      <div class="form-content">
        <div class="row">
          <div class="tip"><span class="weight">*</span>商品标题</div>
          <div class="value input">
            <el-input size="small" placeholder="请输入" v-model="formIn.goodsSkuTitle" />
          </div>
        </div>

        <div class="row">
          <div class="tip">产品批号</div>
          <div class="value input">
            <el-input size="small" placeholder="请输入" v-model="formIn.batchNumber" />
          </div>
        </div>

        <div class="row mutlti">
          <div class="tip">商品图片</div>
          <div class="value mutlti v">
            <div class="in-row">
              <el-radio v-model="formIn.imgRegion" :label="1">使用平台图片</el-radio>
              <el-radio v-model="formIn.imgRegion" :label="2">上传图片</el-radio>
            </div>
            <template v-if="formIn.imgRegion === 2">
              <div class="in-row">
                <div class="pic-layout">
                  <div class="item" v-for="(item, index) in imageUrlList
                    ? imageUrlList
                    : []" :key="index">
                    <el-image :ref="`myImg${index}`" :src="item" fit="cover" class="icon" :initial-index="index"
                      :preview-src-list="imageUrlList" />
                    <div class="preview">
                      <el-image :src="SeeIcon" class="btn-icon" @click="handlePreview(`myImg${index}`)" />
                      <el-image :src="DelIcon" class="btn-icon" @click="handleDeleteImage(index)" />
                    </div>
                  </div>
                  <div class="item add-pic" @click="handleUpload()">
                    <div class="image-wrap">
                      <el-image :src="AddIcon" />
                    </div>
                    <span class="text">上传图片</span>
                  </div>
                </div>
              </div>
              <div class="in-row">
                <span class="value small-tip">注意：上传图片需要审核，最多五张，图片尺寸建议1080*1080px</span>
              </div>
            </template>
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip">商品标签</div>
          <div class="value fill">
            <div class="flag-block">
              <!-- <span class="flag-title">商品标签</span> -->
              <el-radio v-model="formIn.shopLabelId" :label="item.id" :key="item.id"
                v-for="item in shopLabelList ? shopLabelList : []">
                {{ item.labelName }}
              </el-radio>
            </div>
          </div>
        </div>

        <div class="row contine">
          <div class="tip"></div>
          <div class="value fill small-tip">
            <span>
              标签是显示在app商品列表图上的边框/角标优惠信息。
              商家标签可在商品管理-商家标签上传
            </span>
          </div>
        </div>
        <div class="row">
          <div class="tip">商家店铺页排序</div>
          <div class="value input">
            <el-input placeholder="请输入" v-model="formIn.sortCode" maxlength="5" />
          </div>
          <div class="value small-tip">
            <span>注意：排序大的靠前，最大99999</span>
          </div>
        </div>
        <div class="row mutlti">
          <div class="tip">批件药检报告等</div>
          <div class="value input fill">
            <fileUpload v-model="fileList" @onRemoveHandler="onRemoveHandler" style="margin-bottom: 10px;">
            </fileUpload>
            <div class="small-tip">
              <span>上传pdf或图片，且不超过10M</span>
            </div>
          </div>
        </div>

        <div class="row" v-if="selectType && selectType.label == '特价'">
          <div class="tip">商品活动说明</div>
          <wangEnduit :value="formIn.activityDescription" :is-clear="isClear" :isDisabled="isDisabled"
            @changeEndit="watchEndit" style="margin-left: 20px;" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import avater from "/public/img/default.png"
import fileUpload from "@/components/fileUpload";
import wangEnduit from "@/components/wangEnduit";
const IConst_Small = require("@/assets/public/icon-small.png");
const IConst_See_Icon = require("@/assets/zz/see-icon.png");
const IConst_Del_Icon = require("@/assets/zz/del-icon.png");
const IConst_Add_Icon = require("@/assets/zz/add-icon.png");
import {
  skuUpdate,
  skuAdd,
  skuId,
  groupInfos,
  getErpStock,
  getErpPrice,
  postageAddSku,
  getErpDate
} from "@/api/salesControl/commodity";
import { getLabelInfoList } from "@/api/goods";
import _ from "lodash";
export default {
  components: { fileUpload, wangEnduit },
  props: {
    formIn: {
      type: Object,
      default: () => {
        return {
          goodsSkuUnit:'',
          originalPrice: null,
          goodsSkuTitle: "", //商品标题
          imgRegion: 1, //1 使用平台图片 2 上传图片
          batchNumber: '',
          shopLabelId: '',
          activityDescription: '',
          uniqueFlag: 1, //能否特价凑单 0 能 1 不能
          showInventoryFlag: 0, //是否展示给客户剩余库存量 0 不展示 1 展示
        }
      }
    },
    selectType: {
      type: Object,
      default: () => {
        return {}
      }
    },
    fileList: {
      type: Array,
      default: () => {
        return []
      }
    },
    unitList: {
      type: Array,
      default: () => {
        return []
      }
    },
    imageUrlList: {
      type: Array,
      default: () => {
        return []
      }
    },
    shopLabelList: {
      type: Array,
      default: () => {
        return []
      }
    },

    isDisabled: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    name: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data () {
    return {
      smallIcon: IConst_Small,
      SeeIcon: IConst_See_Icon,
      DelIcon: IConst_Del_Icon,
      AddIcon: IConst_Add_Icon,
      pickerOptions: {
        disabledDate (date) {
          // 如果没有后面的-8.64e7就是不可以选择今天的
          return date.getTime() < Date.now() - 8.64e7;
        },
      },

      upperLimitRefreshCycle: [
        {
          label: '活动期内',
          value: 1
        },
        {
          label: '单笔',
          value: 2
        },
        {
          label: '每天 (每天00:00~24:00)',
          value: 5
        },
        {
          label: '每周 (周一00:00~周日24:00)',
          value: 3
        },
        {
          label: '每月 (每月1号00:00~每月最后一天24:00)',
          value: 4
        }
      ],
      avater: avater,
      showFormLeft: false,
      isClear: false, // 清除富文本编辑内容
      pickerOptions_month: { //限制 只能从本月一号到今天开始
        disabledDate (time) {
          const currentDate = new Date();
          const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
          return time.getTime() < firstDayOfMonth.getTime() || time.getTime() > currentDate.getTime();
        }
      },
      pickerOptions_day: { //限制 只能等于今天
        disabledDate (time) {
          const currentDate = new Date();
          // 一天的毫秒数
          const MillisecondsADay = 24 * 60 * 60 * 1000
          //  今日零点时间戳
          const timestamp = Math.floor(new Date(new Date().setHours(0, 0, 0, 0)).getTime())
          const tomorrowTimeStamp = timestamp + MillisecondsADay - 1;
          return time.getTime() < timestamp || time.getTime() > tomorrowTimeStamp;
        }
      },
      pickerOptions_week: {  // 只能从本周一到今天开始 
        disabledDate (time) {
          const currentDate = new Date();
          // console.log(currentDate, '---currentDate')
          // 一天的毫秒数
          const MillisecondsADay = 24 * 60 * 60 * 1000
          //  今日零点时间戳
          const timestamp = Math.floor(new Date(new Date().setHours(0, 0, 0, 0)).getTime())
          const day = currentDate.getDay();

          let diff = 0; // 今天与本周一差的天数
          if (day == 1) {
            diff = 0; // 今天与本周一差的天数
          } else if (day == 0) {
            diff = 6; // 今天与本周一差的天数
          } else {
            diff = day - 1; // 今天与本周一差的天数
          }

          const weekTimeStamp = timestamp - (MillisecondsADay * diff);
          // 时间戳转日期格式  
          return time.getTime() < weekTimeStamp || time.getTime() > currentDate.getTime();
        }
      },
      ischangeStock: true,// 可以勾选库存/或价格 
      showUpperLimit: false, // 是否显示上限
    }
  },
  beforeMount () {

    // 获取当前日期  
    const currentDate = new Date();

    // 获取本周一的日期  
    const firstDayOfWeek = new Date(currentDate.setDate(currentDate.getDate() - (currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1)));
    // console.log(firstDayOfWeek,'---firstDayOfWeek')
    // 将日期格式化为所需的格式，例如 "YYYY-MM-DD"  
    const formattedDate = `${firstDayOfWeek.getFullYear()}-${('0' + (firstDayOfWeek.getMonth() + 1)).slice(-2)}-${('0' + firstDayOfWeek.getDate()).slice(-2)}`;

    // console.log(formattedDate); // 输出本周一的日期，例如 "2023-05-01"
  },
  mounted () {
   
    setTimeout(() => {
      if (this.formIn.activityType == 1) {
        this.selectType.label = '一口价'
      }
      if (this.formIn.activityType == 2) {
        this.selectType.label = '特价'
      }
      if (this.formIn.activityType == 3) {
        this.selectType.label = '单品包邮'
      }
      if (this.formIn.activityType == 4) {
        this.selectType.label = '赠品'
      }
      this.showFormLeft = true
    }, 500)

    this.changePurchaseUpperLimit();
    this.changePurchaseUpperLimitInput();
    this.$forceUpdate();
    console.log(this.formIn,'formIn')
    // console.log(this.formIn.beSynPrice, '---formIn.beSynPrice')
  },
  methods: {
     
    // 修改价格
    xiugaiPrice () {
      this.$router.push(
        {
          path: '/shopOperationCenter/platformSubsidy-product',
          query: {
            name: this.name,
          }
        }
      )
    },
    medicineCodeInput () {
      if (!this.formIn.medicineCode) {
        this.formIn.beSynPrice = false;
        this.formIn.beSynStock = false;
        this.formIn.ischangeStock = false;
        this.$forceUpdate()
      }
    },
    handlePreview (refName) {
      if (this.$refs[refName]) {
        this.$refs[refName][0].showViewer = true;
      }
    },
    changePurchaseUpperLimitInput () {
      if (this.formIn.purchaseUpperLimit) {
        this.formIn.showUpperLimit = false;
      } else {
        this.formIn.showUpperLimit = true;
      }
    },
    changePurchaseUpperLimit () {
      if (this.formIn.showUpperLimit) {
        this.formIn.purchaseUpperLimit = ""
      }
    },
    disabledDate2 (time) {
      const today = new Date();
      const currentDay = today.getDay();
      const monday = new Date(today.setDate(today.getDate() - currentDay + (currentDay === 0 ? -6 : 1))); // 计算本周一的日期  

      return time.getTime() < monday.getTime() || time.getTime() > today.getTime();
    },
    watchEndit (val) {
      // 监听富文本编辑传过来的数据
      this.formIn.activityDescription = val;
    },

    async erpPriceChange (e) {
      if (!this.formIn.medicineCode) {
        this.$message.warning('请您先设置ERP编码')
        this.$nextTick(() => {
          this.formIn.beSynPrice = false;
        });
        return
      }
      // console.log(this.formIn.beSynPrice, '--- this.formIn.beSynPrice')
      // erp同步价格状态发生变化
      if (e) {
        const resp = await getErpPrice({ medicineCode: this.formIn.medicineCode })
        if (resp) {
          this.$set(this.formIn, "salePrice", String(resp))
        } else {
          this.formIn.beSynPrice = false;
          this.$message.warning('ERP未推送商品价格数据或商品价格为0，请您手动设置价格')
        }
      }
    },
    async stockChange (e) {

      if (e) {
        if (!this.formIn.medicineCode) {
          this.$message.warning('请您先设置ERP编码')
          this.$nextTick(() => {
            this.formIn.beSynStock = false;
          });
          return
        }
        const resp = await getErpStock({ medicineCode: this.formIn.medicineCode })
        this.$set(this.formIn, "goodsSkuInventory", String(resp))
      } else {
        console.log(e, '---e2')
      }

    },
    dateChangeSyn () {
      let params = {};
      params.medicineCode = this.formIn.medicineCode;
      if (!this.formIn.beSynDate) {
        this.$set(this.formIn, "producedTime", '')
        this.$set(this.formIn, "expireTime", '')
        return
      }
      getErpDate(params).then((res) => {
        if (res.producedTime) {
          this.$set(this.formIn, "producedTime", res.producedTime)
        }
        if (res.expireTime) {
          this.$set(this.formIn, "expireTime", res.expireTime)
        }
        if (!res.expireTime && !res.producedTime) {
          this.formIn.beSynDate = false;
          this.$message.warning('ERP未推送有效期和生产日期，请您手动设置!')
        } else {
          this.formIn.beSynDate = true;
        }
      }).catch((err) => { })
    },
    handleDeleteImage (samllIndex) {
      this.imageUrlList.splice(samllIndex, 1);
    },
    handleUpload () {
      this.$emit('handleUpload')
    },
    handleAddUserGroup () {
      this.$emit('handleAddUserGroup')
    },
    // 删除上传的文件
    onRemoveHandler (file) {
      let index = this.fileList.indexOf(file);
      this.fileList.splice(index, 1);
    },
    showFileList (arr) {
      this.fileList = [];
      this.fileList.push(arr);
      // console.log(this.fileList, '--this.fileList')
      this.$forceUpdate()
    }
  }

}
</script>
<style lang="scss" scoped>
@import "../style/index.scss";

.tips {
  font-size: 12px;
  color: #606266;
  margin-left: 20px;
}

.radio-list {
  width: calc(100% - 180px);
  margin-left: 20px;

  .list-a {
    width: 100%;
    line-height: 30px;
  }

  .tips {
    font-size: 12px;
    color: #606266;
  }
}
</style>

  