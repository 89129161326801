var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "20px" } },
    [
      _c("div", { staticStyle: { padding: "24px" } }, [
        _vm._v(" 收取发票的类型、抬头信息 "),
      ]),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.company,
            rules: _vm.rules,
            "label-width": "110px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "开票类型", prop: "invoiceType" } },
            [
              _c(
                "el-checkbox-group",
                {
                  attrs: { disabled: !_vm.isEdit },
                  model: {
                    value: _vm.company.invoiceType,
                    callback: function ($$v) {
                      _vm.$set(_vm.company, "invoiceType", $$v)
                    },
                    expression: "company.invoiceType",
                  },
                },
                [
                  _c("el-checkbox", { attrs: { label: "1" } }, [
                    _vm._v("普通发票（纸质）"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "2" } }, [
                    _vm._v("普通发票（电子）"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "3" } }, [
                    _vm._v("增值税专用发票（纸质）"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "4" } }, [
                    _vm._v("增值税专用发票（电子）"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发票抬头", prop: "sellerName" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  size: "small",
                  placeholder: "请输入发票抬头",
                  disabled: !_vm.isEdit,
                  max: "100",
                },
                model: {
                  value: _vm.company.sellerName,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "sellerName", $$v)
                  },
                  expression: "company.sellerName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发票税号", prop: "sellerTaxpayerId" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  size: "small",
                  placeholder: "请输入发票税号",
                  disabled: !_vm.isEdit,
                },
                model: {
                  value: _vm.company.sellerTaxpayerId,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "sellerTaxpayerId", $$v)
                  },
                  expression: "company.sellerTaxpayerId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "注册地址", prop: "sellerAddress" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  size: "small",
                  type: "textarea",
                  max: "200",
                  placeholder: "请输入注册地址",
                  disabled: !_vm.isEdit,
                },
                model: {
                  value: _vm.company.sellerAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "sellerAddress", $$v)
                  },
                  expression: "company.sellerAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "注册电话", prop: "sellerTel" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  size: "small",
                  placeholder: "请输入注册电话",
                  disabled: !_vm.isEdit,
                  "show-word-limit": "",
                  max: "100",
                },
                model: {
                  value: _vm.company.sellerTel,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "sellerTel", $$v)
                  },
                  expression: "company.sellerTel",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开户银行", prop: "sellerBank" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  size: "small",
                  placeholder: "请输入开户银行",
                  disabled: !_vm.isEdit,
                  max: "100",
                },
                model: {
                  value: _vm.company.sellerBank,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "sellerBank", $$v)
                  },
                  expression: "company.sellerBank",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开户账号", prop: "sellerAccount" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  size: "small",
                  placeholder: "请输入开户账号",
                  disabled: !_vm.isEdit,
                  max: "100",
                },
                model: {
                  value: _vm.company.sellerAccount,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "sellerAccount", $$v)
                  },
                  expression: "company.sellerAccount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收件人", prop: "recipients" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  size: "small",
                  placeholder: "请输入收件人",
                  disabled: !_vm.isEdit,
                  max: "100",
                },
                model: {
                  value: _vm.company.recipients,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "recipients", $$v)
                  },
                  expression: "company.recipients",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话", prop: "recipientsTel" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  size: "small",
                  placeholder: "请输入联系电话",
                  disabled: !_vm.isEdit,
                  max: "100",
                },
                model: {
                  value: _vm.company.recipientsTel,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "recipientsTel", $$v)
                  },
                  expression: "company.recipientsTel",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收件地址", prop: "recipientsAddress" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  size: "small",
                  placeholder: "请输入收件地址",
                  disabled: !_vm.isEdit,
                  max: "100",
                },
                model: {
                  value: _vm.company.recipientsAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "recipientsAddress", $$v)
                  },
                  expression: "company.recipientsAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "邮箱", prop: "recipientsEmil" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  size: "small",
                  placeholder: "请输入邮箱",
                  disabled: !_vm.isEdit,
                  max: "100",
                },
                model: {
                  value: _vm.company.recipientsEmil,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "recipientsEmil", $$v)
                  },
                  expression: "company.recipientsEmil",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("MyButton", {
                attrs: {
                  type: _vm.isEdit ? "" : "primary",
                  icon: "",
                  text: _vm.isEdit ? "取消修改" : "开启编辑",
                },
                on: { click: _vm.editChange },
              }),
              _vm.isEdit
                ? _c("MyButton", {
                    attrs: { type: "primary", icon: "", text: "确认修改" },
                    on: { click: _vm.goSubmit },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }