<template>
  <el-dialog :title="title" :visible.sync="visible" :lock-scroll="true" width="718px" :before-close="handleClose"
    append-to-body>
    <div class="">
      <div style="padding: 24px 0;">
        <el-form ref="form-data" :model="form" :inline="false" label-width="150px" label-position="right" :rules="rules"
          style="text-align:left;"> 
        
          <el-form-item label=" ">
            <span v-if="balanceAmount && parseFloat(balanceAmount)>0" > 
              本单应退金额  ￥ {{ parseFloat(balanceAmount).toFixed(2) || '--' }}  
            </span>
            <span v-if="balanceAmount && parseFloat(balanceAmount)>0" > 
              余额退款  ￥ {{ parseFloat(balanceAmount).toFixed(2) || '--' }}  
            </span>
            <span v-if="balanceAmount && parseFloat(balanceAmount)>0  && (parseFloat(amount) - parseFloat(balanceAmount) >0)" style="color: #f5222d;">
               {{ payType == 1 ? '微信支付/支付宝支付/康贝支付' :payType == 2 ? '康贝支付' :payType == 3? '需线下打款' :'' }}
               ￥  {{ (parseFloat(amount) - parseFloat(balanceAmount)).toFixed(2) }}
            </span>  
            <div>

            </div>
          </el-form-item>

          <el-form-item label="收款户名">
            <el-input v-model.trim="accountName" style="width: 400px;" placeholder="" clearable size="small"
              :disabled="true" show-limit-word max-length="50" />
          </el-form-item>

          <el-form-item label="开户银行">
            <el-input v-model.trim="depositBank" style="width: 400px;" placeholder="" clearable size="small" type="text"
              :maxlength="18" :disabled="true" />
          </el-form-item>

          <el-form-item label="收款账号">
            <el-input v-model.trim="receivablesAccount" style="width: 400px;" placeholder="" clearable size="small"
              v-positive-integer show-limit-word :maxlength="8" :disabled="true" />
          </el-form-item>

          <el-form-item prop="imageList" label="打款凭证">
            <imgUpload v-model="form.imageList" :isEdit="isDisabled" :limit="3" style="position:relative;top:-8px">
              <template slot="tip">
                <p class="el-upload__tip" style="margin:0px">
                  备注:图片格式为jpg或png，图片大小不得超过5M;最多上传三张图片
                </p>
              </template>
            </imgUpload>
          </el-form-item>
          <el-form-item>
            <div class="footer-btn">
              <el-button size="small" type="primary" @click="saveSubmit()" :disabled="isDisabled">保存</el-button>
              <el-button size="small" type="plain" @click="handleClose()">返回</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>
    
<script>
import fileUpload from "@/components/fileUpload";
import avater from "/public/img/default.png";
import imgUpload from '@/components/imgUpload';
import { payvoucherinfoAdd, payvoucherinfoGetinfo, payvoucherinfoResubmit,aftersaleSubmitVocher } from "@/api/offlinePayment";

export default {
  props: {},
  components: { imgUpload, fileUpload },

  data() {
    return {
      avater: avater,
      disabled: false,
      id: null,
      pageType: '',
      isDisabled: false,
      accountName: '',
      depositBank: '',
      receivablesAccount: '',
      form: {
        imageList: [], 
      },
      rules: {
        imageList: [{ required: true, message: "请上传凭证", trigger: "change" }],
      },
      title: '',
      visible: false,
      orderId:null,
      balanceAmount:null,
      amount:null,
      payType:null, //支付方式
    }
  },
  created() {
  },
  mounted() {

  },
  watch: {},
  methods: { 
    saveSubmit() {
      let params = {}; 
      this.$refs['form-data'].validate((valid) => { 
        if (valid) { 
          if( this.id) {
            params.id = this.id
          } 
          params.voucherImage	 = this.form.imageList.join(','); 
          console.log(params,'--params')
            aftersaleSubmitVocher(params).then((res) => {
               if (this.pageType == 'after') { //列表页 
                this.$emit('getList') 
              } else { // 详情页
                this.$emit('aftersaleInfo') 
              }
              this.handleClose();
            })
        }
      })

    },
    // payvoucherinfoGetinfo
    handleClose() {
      this.visible = false;
      this.$emit("handleClose")
    },
    init(row, type) {  
      console.log(row,'---row')
      this.id = row.id;
      if (type == 'reset') { 
        this.title = '重新提交凭证';
      } else {  
        this.title = '提交凭证';
      } 
      this.form.imageList = [];
      this.pageType = type;
      this.visible = true;
      this.payType = row.payType; //支付方式
      this.amount = row.amount; //支付金额 (总额)
      this.balanceAmount = row.balanceAmount; //余额支付
      this.accountName = row.accountName;  //收款户名
      this.depositBank = row.depositBank; //开户银行
      this.receivablesAccount = row.receivablesAccount;  //收款账号  
    },
  }
}

</script>
<style lang="scss" scoped>
.eachRow {
  background-color: #fff;
  border-radius: 4px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
}

.titleView {
  display: flex;
  align-items: center;

  .rowLine {
    width: 4px;
    border-color: 1px;
    background-color: var(--main-color);
    height: 16px;
    margin-right: 8px;
  }

  .eachRowTitle {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
  }

  .cpxhead {
    display: flex;
    align-items: center;
  }

  .mark {
    color: #f56c6c;
    font-size: 12px;
    margin-left: 10px;
    width: 200px;
    line-height: 1.5;
    display: inline-block;
    font-weight: bold;
  }
}

.goods-list-warp {
  width: 100%;
  margin-left: 100px;
  max-height: 500px;
  overflow-y: auto;
}

.goods-list {
  width: 50%;
  border-bottom: solid 1px #f5f5f5;
  padding-bottom: 10px;

  &:nth-last-child(1) {
    border-bottom: none;
  }
}

.footer-btn {
  width: 410px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
</style>
  
  