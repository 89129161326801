var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "el-form",
            {
              ref: "formTool",
              staticStyle: { "text-align": "left" },
              attrs: { model: _vm.formTool, inline: true },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { margin: "0px !important" },
                  attrs: { prop: "name", label: "" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入产品线名称",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.formTool.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "name", $$v)
                      },
                      expression: "formTool.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.clickSearch(1)
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.reset()
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _vm.permissions.sales_puduct_role_add
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.addProductLine()
                            },
                          },
                        },
                        [_vm._v("添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.permissions.excCooperationControlSalesSQL
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addCooperationGoods()
                    },
                  },
                },
                [_vm._v("创建合作控销产品")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "center", staticStyle: { position: "relative" } },
        [
          _c("MyTable", {
            attrs: {
              "table-data": _vm.tableData,
              index: _vm.index,
              selection: _vm.selection,
              page: _vm.page,
              operation: _vm.operation,
              "table-option": _vm.tableOption,
            },
            on: {
              "update:tableOption": function ($event) {
                _vm.tableOption = $event
              },
              "update:table-option": function ($event) {
                _vm.tableOption = $event
              },
              "page-change": _vm.getList,
              handleSelectionChange: _vm.handleSelectionChange,
            },
            scopedSlots: _vm._u([
              {
                key: "className",
                fn: function (scope) {
                  return [
                    scope.row.productLineGoodsDTO
                      ? _c("el-row", [
                          _vm._v(
                            " " +
                              _vm._s(scope.row.productLineGoodsDTO.className) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "image",
                fn: function (scope) {
                  return [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticStyle: { width: "80px" } },
                          [
                            _c("el-image", {
                              staticStyle: { width: "80px", height: "80px" },
                              attrs: {
                                src:
                                  scope.row.productLineGoodsDTO &&
                                  scope.row.productLineGoodsDTO.goodsImg
                                    ? scope.row.productLineGoodsDTO.goodsImg
                                    : _vm.avater,
                                lazy: "",
                              },
                            }),
                          ],
                          1
                        ),
                        scope.row.productLineGoodsDTO
                          ? _c(
                              "el-col",
                              {
                                staticStyle: {
                                  width: "calc(100% - 90px)",
                                  "margin-left": "10px",
                                },
                              },
                              [
                                _c("el-row", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.productLineGoodsDTO.goodsName
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("el-row", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.productLineGoodsDTO
                                          .goodsSpecification
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("el-row", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.productLineGoodsDTO
                                          .goodsFactory
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("el-row", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.productLineGoodsDTO
                                          .goodsApprovalNumber
                                      ) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "menu",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "btn-warp" },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.editHandle(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm.permissions.sales_puduct_price_delete
                          ? _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteHandle(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                        _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.associationHandle(scope.row)
                              },
                            },
                          },
                          [_vm._v("控销策略")]
                        ),
                        _vm.permissions.sales_puduct_price_add
                          ? _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.priceHandle(scope.row)
                                  },
                                },
                              },
                              [_vm._v("控销定价")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("goodsDialog", {
        attrs: { dialogShow: _vm.dialogShow },
        on: { didSelectItem: _vm.didSelectItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }