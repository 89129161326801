import request from '@/utils/request'

// 获取医药公司列表
export function drugAgencyList(data) {
  return request({
    url: '/shop/shop/drugAgency/list',
    method: 'get',
    params:data
  })
}

// 获取省市区树
export function regionTree() {
  return request({
    url: '/shop/region/tree',
    method: 'get'
  })
}
// 根据商铺ID获取经营区域
export function manageRegionTree(shopId) {
  return request({
    url: `/shop/manageRegion/list/${shopId}`,
    method: 'get'
  })
}
// 编辑卖家经营区域
export function manageRegion(data) {
  return request({
    url: `/shop/manageRegion`,
    method: 'put',
    data
  })
}