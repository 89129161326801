<template>
  <div class="second">
    <div class="center">
      <MyTable :table-data="tableData" :index="index" :selection="selection" :page="page" :operation="operation"
        :table-option.sync="tableOption" :showpage="showpage" @page-change="getList"
        @handleSelectionChange="handleSelectionChange">

        <template slot="info" slot-scope="scope">

          <div style="display: flex;align-items: center;width: 100%;">
            <el-image style="width: 80px; height: 80px" :src="scope.row.image || avater"
              :preview-src-list="[scope.row.image]">
            </el-image>
            <div style="margin-left: 10px;width: calc(100% - 90px);">
              <div class="overFlow" style="font-size: 18px;">{{ scope.row.goodsName || '--' }}</div>
              <div class="overFlow" style="font-size: 16px;color: rgb(191, 191, 191);">{{ scope.row.goodsFactory || '--'
              }}
              </div>
              <div class="overFlow">{{ scope.row.approvalNumber || '--' }}</div>
              <div class="overFlow">{{ scope.row.specification || '--' }}</div>
            </div>
          </div>
        </template>

        <template slot="dangqian" slot-scope="scope">
          <div>￥{{ scope.row.salePriceShow }}</div>
        </template>

        <template slot="jianyi" slot-scope="scope">
          <div>￥{{ scope.row.skuPriceMax }}</div>
          <div>约{{ scope.row.discount }}折</div>
        </template>
        <!-- <template slot="pingtaibutie" slot-scope="scope">
          <div v-if="scope.row.type == 1">
            <div>{{ scope.row.discount }}折</div>
            <div>{{ scope.row.singleGoodsLimitFlag ? `单笔订单上限 ${scope.row.singleGoodsLimit}` : '单笔订单无上限' }}</div>
          </div>
          <div v-else-if="scope.row.type == 2">
            <div>减固定金额 ¥ {{ scope.row.discount }}</div>
          </div>
          <div v-else> -- </div>
        </template> -->
        <template slot="price" slot-scope="scope">
          <div class="moneyStyle">
            <el-input size="small" clear v-model="scope.row.activityPrice" placeholder="请输入活动价">
            </el-input>
          </div>
        </template>

        <template slot="butie" slot-scope="scope">
          <div>{{ scope.row.skuInventoryMin }}</div>
        </template>

        <!-- <template slot="huodongkucun" slot-scope="scope">
          <div>
            <el-input size="small" clear v-model="scope.row.inventory" placeholder="请输入库存">
            </el-input>
          </div>
        </template> -->

        <template slot-scope="scope" slot="caozuo">
          <el-link type="primary" :underline="false" @click="deleteData(scope.row.skuId)">删除</el-link>
        </template>
      </MyTable>
    </div>
    <div class="bottom">
      <div style="position: absolute;left: 10px;">批量使用建议价格作为活动价
        <el-link type="primary" :underline="false" style="margin-left:16px" @click="shiyong">确认使用</el-link>
        <el-link type="primary" :underline="false" style="margin-left:16px" @click="empty">清空所有活动价</el-link>
      </div>
      <div>
        <el-button type="primary" size="small" @click="reChoice">重选商品</el-button>
        <el-button type="primary" size="small" @click="submit">提交</el-button>
      </div>
    </div>
  </div>
</template>
  
<script>
import { queryActivitySkuUnion, activityApply } from '@/api/platformActivity/index'
import MyTable from '@/components/myTable'
import avater from "/public/img/default.png"
export default {
  props: ['skuList', 'id'],
  data () {
    return {
      avater: avater,
      selectionList: [],
      operation: false,
      showpage: false,
      selection: false,
      index: false,
      tableData: [],
      tableOption: [
        { label: '商品信息', prop: 'info', width: '380px', slot: true },
        { label: '当前价格', prop: 'dangqian', slot: true },
        { label: '商品限价', prop: 'jianyi', slot: true },
        // { label: '平台补贴', prop: 'pingtaibutie', slot: true },
        { label: '活动价', prop: 'price', slot: true },
        { label: '库存下限', prop: 'butie', slot: true },
        // { label: '活动库存', prop: 'huodongkucun', slot: true },
        { label: '操作', prop: 'caozuo', width: '60px', slot: true },
      ],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50]
      }
    }
  },
  components: { MyTable },
  async created () {
    await this.getList()
  },
  mounted () {

  },
  watch: {},
  methods: {
    // 勾选的数据
    handleSelectionChange (val) {
      this.selectionList = val
    },
    // 查询列表数据
    async getList (current) {
      if (current) {
        this.page.current = current
      }
      let params = {
        activityId: this.id,
        goodList: this.skuList,
      }
      const res = await queryActivitySkuUnion(params)
      if (res) {
        // console.log(res)
        this.tableData = res
      }
    },
    // 使用
    shiyong () {
      this.tableData.map(item => {
        this.$set(item, 'activityPrice', item.skuPriceMax)
        return {
          ...item
        }
      })
    },
    // 清空
    empty () {
      this.tableData.map(item => {
        this.$set(item, 'activityPrice', '')
        return {
          ...item
        }
      })
    },
    // 删除数据
    deleteData (skuId) {
      let newIndex = ''
      this.tableData.map((el, index) => {
        if (el.skuId == skuId) {
          newIndex = index
        }
      })
      this.tableData.splice(newIndex, 1)
    },
    // 提交
    async submit () {
      if (this.tableData.length == 0) {
        this.$message.error('请添加商品')
        return
      }
      let a = this.tableData.some(item => {
        return Number(item.activityPrice) > Number(item.skuPriceMax)
      })
      if (a) {
        this.$message.error('活动价不能大于建议价')
        return
      }
      let arr = []
      this.tableData.map(item => {
        let obj = {
          ruleId: item.ruleId,
          goodsId: item.goodsId,
          goodsSkuId: item.skuId,
          shopId: item.shopId,
          shopName: item.shopName,
          goodsName: item.goodsName,
          goodsCode: item.goodsCode,
          activityPrice: item.activityPrice
        }
        arr.push(obj)
      })
      let params = {
        activityId: this.id,
        activityApplyGoodsDetailsList: arr
      }
      const res = await activityApply(params)
      if (res) {
        this.$message.success('报名成功')
        this.$emit('changeStep', 3)
      }
    },
    // 重选商品
    reChoice () {
      this.$emit('changeStep', 1)
    }
  }
}

</script>
<style lang="scss" scoped>
.second {
  height: 100%;

  .center {
    height: calc(100% - 55px);

    .overFlow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

    }

    ::v-deep .moneyStyle {
      .el-input::before {
        content: "￥"; // content: "\FFE5";
        position: absolute;
        left: 5px;
        top: 4px;
        color: #595959;
        font-size: 14px;
      }

      .el-input__inner {
        padding: 0 20px !important;
      }
    }
  }

  .bottom {
    position: relative;
    display: flex;
    // margin-top: 52px;
    justify-content: center;
    align-items: center;
    border: 1px solid #ededed;
    padding: 10px;
  }
}
</style>
