var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "structure" }, [
          _c(
            "div",
            {
              staticClass: "searchArea",
              staticStyle: {
                "justify-content": "space-between",
                padding: "0px",
              },
            },
            [
              _c("span", { staticClass: "tit" }, [_vm._v("经销架构")]),
              _c("el-input", {
                staticClass: "searchIpt",
                staticStyle: { visibility: "auto" },
                attrs: {
                  placeholder: "请输入架构名称",
                  "suffix-icon": "el-icon-search",
                  size: "mini",
                },
                model: {
                  value: _vm.treeVal1,
                  callback: function ($$v) {
                    _vm.treeVal1 = $$v
                  },
                  expression: "treeVal1",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "treeBox" },
            [
              _c("el-tree", {
                ref: "tree1",
                attrs: {
                  "node-key": "id",
                  data: _vm.organizationData,
                  "highlight-current": true,
                  props: _vm.defaultProps,
                  "filter-node-method": _vm.filterNode,
                },
                on: { "node-click": _vm.getoutCheckKey },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.showOutDialog },
                },
                [_vm._v("区域设置")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "resultArea" }, [
          _c("div", { staticClass: "resultHearder" }, [
            _vm._v(_vm._s(_vm.resultHearderTit || " ") + " "),
          ]),
          _c("div", { staticClass: "resultContent" }, [
            _c("div", { staticClass: "contentItemMini item" }, [
              _c("div", { staticClass: "title" }, [_vm._v("选择团队人员")]),
              _c(
                "div",
                { staticClass: "searchArea" },
                [
                  _c("el-input", {
                    staticClass: "searchIpt",
                    staticStyle: { visibility: "auto" },
                    attrs: {
                      placeholder: "请输入人员名称",
                      "suffix-icon": "el-icon-search",
                      size: "mini",
                    },
                    model: {
                      value: _vm.teamName,
                      callback: function ($$v) {
                        _vm.teamName = $$v
                      },
                      expression: "teamName",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "list" }, [
                _c("div", { staticClass: "listContent" }, [
                  _c(
                    "ul",
                    _vm._l(_vm.teamList, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: item.id,
                          class: { selLi: _vm.selTeam === item.id },
                          on: {
                            click: function ($event) {
                              return _vm.selItem(item.id, item.name, index)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "item" }, [
                            _c("div", { staticClass: "nameBox" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.teamOrgName)),
                              ]),
                            ]),
                            _c("div", { staticClass: "desc" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.userRole == 1 ? "经理" : "业务员"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.addTeam },
                    },
                    [_vm._v("添加")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.delTeamFun },
                    },
                    [_vm._v("删除")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "contentItem item" }, [
              _c("div", { staticClass: "title" }, [_vm._v("分配买家")]),
              _c(
                "div",
                { staticClass: "searchArea" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "tit",
                      staticStyle: { "font-size": "14px" },
                    },
                    [_vm._v("未被分配买家")]
                  ),
                  _c("el-input", {
                    staticClass: "searchIpt",
                    staticStyle: { visibility: "auto" },
                    attrs: {
                      placeholder: "请输入买家名称",
                      "suffix-icon": "el-icon-search",
                      size: "mini",
                    },
                    model: {
                      value: _vm.buyerVal1,
                      callback: function ($$v) {
                        _vm.buyerVal1 = $$v
                      },
                      expression: "buyerVal1",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "list" }, [
                _c(
                  "div",
                  { staticClass: "listContent" },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.checkList,
                          callback: function ($$v) {
                            _vm.checkList = $$v
                          },
                          expression: "checkList",
                        },
                      },
                      [
                        _c(
                          "ul",
                          _vm._l(_vm.buyerList, function (item, index) {
                            return _c(
                              "li",
                              { key: item.shopId },
                              [
                                _c("el-checkbox", { attrs: { label: index } }, [
                                  _c("br"),
                                ]),
                                _c("div", { staticClass: "item" }, [
                                  _c("div", { staticClass: "nameBox" }, [
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.shopName)),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "desc" }, [
                                    _vm._v(_vm._s(item.areaName)),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "footer",
                  staticStyle: { "justify-content": "space-between" },
                },
                [
                  _c("div", { staticClass: "num" }, [
                    _vm._v("终端数(" + _vm._s(_vm.buyerList.length) + ")"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.teamTerminalsBatchFun },
                    },
                    [_vm._v("确认分配")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "contentItem item" }, [
              _c("div", { staticClass: "title" }, [_vm._v("已分配买家")]),
              _c(
                "div",
                { staticClass: "searchArea" },
                [
                  _vm.assignbuyName
                    ? _c("span", { staticClass: "tit" }, [
                        _vm._v(_vm._s(_vm.assignbuyName)),
                      ])
                    : _vm._e(),
                  _c("el-input", {
                    staticClass: "searchIpt",
                    staticStyle: { visibility: "auto" },
                    attrs: {
                      placeholder: "请输入买家名称",
                      "suffix-icon": "el-icon-search",
                      size: "mini",
                    },
                    model: {
                      value: _vm.buyerVal2,
                      callback: function ($$v) {
                        _vm.buyerVal2 = $$v
                      },
                      expression: "buyerVal2",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "list" }, [
                _c(
                  "div",
                  { staticClass: "listContent" },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.assignedCheckList,
                          callback: function ($$v) {
                            _vm.assignedCheckList = $$v
                          },
                          expression: "assignedCheckList",
                        },
                      },
                      [
                        _c(
                          "ul",
                          _vm._l(_vm.assignedBuyerList, function (item) {
                            return _c(
                              "li",
                              { key: item.id },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: item.id } },
                                  [_c("br")]
                                ),
                                _c("div", { staticClass: "item" }, [
                                  _c("div", { staticClass: "nameBox" }, [
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.shopName)),
                                    ]),
                                    _c("div", { staticClass: "price" }, [
                                      _c("span", [_vm._v("单次限购")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(item.singleMaxBuyQuantity)
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "price" }, [
                                      _c("span", [_vm._v("单月限次")]),
                                      _c("span", [
                                        _vm._v(_vm._s(item.monthMaxBuyTimes)),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "editIcon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.toEditPrice(item.id)
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "el-icon-edit" })]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "desc" }, [
                                    _vm._v(_vm._s(item.areaName)),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.teamTerminalsBatchDelFun },
                    },
                    [_vm._v("取消分配")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.openUpdateQuantityDialog },
                    },
                    [_vm._v("采购定量")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "区域设置",
            visible: _vm.showEditDialog,
            width: "60%",
            "close-on-click-modal": false,
            "before-close": _vm.outDialogClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showEditDialog = $event
            },
          },
        },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                width: "30%",
                title: _vm.insideTit,
                visible: _vm.showInsideDialog,
                "append-to-body": "",
                "close-on-click-modal": false,
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showInsideDialog = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "editArea" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dialogForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.dialogForm,
                        "status-icon": "",
                        rules: _vm.dialogRules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "组织架构", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "10" },
                            model: {
                              value: _vm.dialogForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.dialogForm, "name", $$v)
                              },
                              expression: "dialogForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.addNode("dialogForm")
                                },
                              },
                            },
                            [_vm._v("提交")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.resetForm("dialogForm")
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "dialogContent" }, [
            _c("div", { staticClass: "cLeft item" }, [
              _c(
                "div",
                { staticClass: "cHeader" },
                [
                  _c("div", { staticClass: "tit" }, [_vm._v("组织架构")]),
                  _c(
                    "div",
                    { staticClass: "ipt" },
                    [
                      _c("el-input", {
                        staticStyle: { visibility: "auto" },
                        attrs: {
                          size: "small",
                          placeholder: "请您输入组织名称",
                          "suffix-icon": "el-icon-search",
                        },
                        model: {
                          value: _vm.zhTreeVal,
                          callback: function ($$v) {
                            _vm.zhTreeVal = $$v
                          },
                          expression: "zhTreeVal",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.editNode(1)
                        },
                      },
                    },
                    [_vm._v("添加")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.editNode(2)
                        },
                      },
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.editNode(3)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "dialogtree" },
                [
                  _c("el-tree", {
                    ref: "zhtree",
                    attrs: {
                      data: _vm.organizationData,
                      "node-key": "id",
                      "highlight-current": true,
                      "default-expand-all": true,
                      props: _vm.defaultProps,
                      "filter-node-method": _vm.filterNode,
                    },
                    on: { "node-click": _vm.getNodeKey },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "cRight item" }, [
              _c(
                "div",
                { staticClass: "cHeader" },
                [
                  _c("div", { staticClass: "tit" }, [_vm._v("销售地域")]),
                  _c("el-input", {
                    staticStyle: { visibility: "auto" },
                    attrs: {
                      size: "mini",
                      placeholder: "请输入地域名称",
                      "suffix-icon": "el-icon-search",
                    },
                    model: {
                      value: _vm.cityVal,
                      callback: function ($$v) {
                        _vm.cityVal = $$v
                      },
                      expression: "cityVal",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "dialogtree" },
                [
                  _c("el-tree", {
                    ref: "cityTree",
                    attrs: {
                      "show-checkbox": "",
                      "node-key": "id",
                      "default-expanded-keys": _vm.expandedKeys,
                      "default-checked-keys": _vm.checkedKeys,
                      data: _vm.cityData,
                      props: _vm.cityDefaultProps,
                      "filter-node-method": _vm.filterNode,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "outDialogBtn",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.outDialogClose } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.teamOrgAreaRelationsBatchFun },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            title: "添加团队成员",
            visible: _vm.addTeamDialog,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addTeamDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "teamDialogContent" },
            [
              _c(
                "el-form",
                {
                  ref: "addTeamForm",
                  attrs: {
                    model: _vm.addTeamForm,
                    rules: _vm.addTeamRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { max: "8" },
                        model: {
                          value: _vm.addTeamForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.addTeamForm, "name", $$v)
                          },
                          expression: "addTeamForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "11" },
                        model: {
                          value: _vm.addTeamForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.addTeamForm, "phone", $$v)
                          },
                          expression: "addTeamForm.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色", prop: "userRole" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择角色" },
                          model: {
                            value: _vm.addTeamForm.userRole,
                            callback: function ($$v) {
                              _vm.$set(_vm.addTeamForm, "userRole", $$v)
                            },
                            expression: "addTeamForm.userRole",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "业务员", value: "0" },
                          }),
                          _c("el-option", {
                            attrs: { label: "经理", value: "1" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("addTeamForm")
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitAddTeam("addTeamForm")
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            title: "采购定量",
            visible: _vm.updateQuantityDialog,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.updateQuantityDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "teamDialogContent" },
            [
              _c(
                "el-form",
                {
                  ref: "updateQuantityForm",
                  attrs: {
                    model: _vm.updateQuantityForm,
                    rules: _vm.updateQuantityRules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单次采购定量", prop: "quantity" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          type: "number",
                          oninput:
                            "\n              if (value.length > 4) {\n                value = value.slice(0, 4);\n              }\n              value = value.replace(/[^0-9]/g, '');\n            ",
                        },
                        model: {
                          value: _vm.updateQuantityForm.quantity,
                          callback: function ($$v) {
                            _vm.$set(_vm.updateQuantityForm, "quantity", $$v)
                          },
                          expression: "updateQuantityForm.quantity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "月度采购次数",
                        prop: "monthMaxBuyTimes",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          type: "number",
                          oninput:
                            "\n              if (value.length > 2) {\n                value = value.slice(0, 2);\n              }\n              value = value.replace(/[^0-9]/g, '');\n            ",
                        },
                        model: {
                          value: _vm.updateQuantityForm.monthMaxBuyTimes,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.updateQuantityForm,
                              "monthMaxBuyTimes",
                              $$v
                            )
                          },
                          expression: "updateQuantityForm.monthMaxBuyTimes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("updateQuantityForm")
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitQuantity("updateQuantityForm")
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }