<template>
  <div class="mainView2">

    <div class="goodsSalesStatMain" ref="goodsSalesStatMain">
      <div class="flexLineView mainHeight">

        <div class="dropDownBtn">
          <span style="margin-left:10px;color:#262626;font-size:16px;font-weight:600;">采购排名<span
              style="color:#595959;font-size:12px;font-weight:400;padding-left:10px;">&nbsp;&nbsp;数据更新时间{{ timeStr
              }}</span></span>
          <el-autocomplete v-model="state" size="mini" style="margin-left:10px;width: 180px;position: relative;top: 2px;"
            :fetch-suggestions="querySearchAsync" placeholder="客户名称" @blur="blurAuto" @select="handleSelect">
            <template slot-scope="{ item }">
              <el-tooltip class="item" effect="dark" :content="item.pay_shop_name" placement="top">
                <div class="name" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                  {{ item.pay_shop_name }}
                </div>
              </el-tooltip>
            </template>
          </el-autocomplete>
        </div>

        <el-table :data="tableDataChange.length != 0 ? tableDataChange : procureTableData" style="width: 100%;"
          :highlight-current-row="true" :height="tableHeight - 38" @row-click="handleCurrentChange" ref="hilightTable">
          <el-table-column prop="rn" label="排名" width="50">
          </el-table-column>
          <el-table-column prop="shop_type_name" label="客户类别">
          </el-table-column>
          <el-table-column prop="pay_shop_name" label="客户名称">
          </el-table-column>
          <el-table-column prop="erp_id" label="ERPID" width="100">
          </el-table-column>
          <el-table-column prop="amount" label="采购额(元)">
          </el-table-column>
          <el-table-column prop="cnt" label="订单数">
          </el-table-column>

        </el-table>

        <div class="numberView">
          <img :src="line_1" alt="" class="numberImg">
        </div>
      </div>

      <div class="lineView"></div>

      <div class="flexLineView mainHeight">
        <div class="dropDownBtn">
          <span style="margin-left:10px;color:#262626;font-size:16px;font-weight:600;">
            采购金额 </span>
        </div>

        <el-table :data="tableDataPurchase" style="width: 100%;" highlight-current-row :height="tableHeight - 38"
          @row-click="handleCurrentChangeSecond" ref="hilightTable2">

          <el-table-column prop="rn" label="排名" width="50">
          </el-table-column>
          <el-table-column prop="goods_name" label="药品名称">
          </el-table-column>
          <el-table-column prop="goods_factory" label="生产企业">
          </el-table-column>
          <el-table-column prop="qty" label="销售数量" width="100">
          </el-table-column>
          <el-table-column prop="amount" label="销售金额(元)">
          </el-table-column>
          <el-table-column label="销售占比" width="100">
            <template slot-scope="scope">
              <div class="percentView">
                <div class="percentBg" :style="{ width: getPercent(scope.row.pct) + '%' }"></div>
                <div class="percentText">{{ getPercent(scope.row.pct) }}%</div>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="numberView">
          <img :src="line_2" alt="" class="numberImg">
        </div>
      </div>
    </div>

    <div class="goodsSalesStatMain goodsSalesStatMain2 " ref="goodsSalesStatMain2">
      <div class="flexLineView mainHeight">
        <div class="dropDownBtn">
          <span style="margin-left:10px;color:#262626;font-size:16px;font-weight:600;">
            采购时段(时) </span>
        </div>
        <div class="leftHalf">
          <highcharts :options="lineOptions" />
        </div>
        <div class="numberView">
          <img :src="line_3" alt="" class="numberImg">
        </div>
      </div>

      <div class="lineView"></div>
      <div class="flexLineView mainHeight">
        <div class="dropDownBtn">
          <span style="margin-left:10px;color:#262626;font-size:16px;font-weight:600;">
            采购频次(次) </span>
        </div>
        <div class="leftHalf">
          <highcharts :options="lineOptions2" />
        </div>
        <div class="numberView">
          <img :src="line_3" alt="" class="numberImg">
        </div>
      </div>
      <div class="lineView"></div>

      <div class="flexLineView mainHeight">
        <div class="dropDownBtn">
          <span style="margin-left:10px;color:#262626;font-size:16px;font-weight:600;">
            同区客户同期采购 </span>
        </div>
        <div class="numberView">
          <img :src="line_3" alt="" class="numberImg">
        </div>

        <el-table :data="!procureTableData ? [] : tableDataSameDistrict" style="width: 100%;" highlight-current-row
          :height="tableHeight - 38" ref="hilightTable3">
          <el-table-column prop="goods_name" label="药品名称" fixed="left">
          </el-table-column>
          <el-table-column prop="goods_factory" label="生产企业">
          </el-table-column>
          <el-table-column prop="qty" label="销售数量" width="100">
          </el-table-column>
          <el-table-column prop="amount" label="销售金额(元)">
          </el-table-column>

        </el-table>
      </div>

    </div>
  </div>
</template>
<script>
import Tabs from '@/components/Tabs'
import { queryGoodsSalesEcharts } from "@/api/statistics";
import number1 from "@/assets/number1.png";
import number2 from "@/assets/number2.png";
import line_1 from "@/assets/Line_1.png";
import line_2 from "@/assets/Line_2.png";
import line_3 from "@/assets/Line_3.png";
import dayjs from 'dayjs'
export default {
  components: { Tabs },
  data() {
    return {
      firstCurrent: 0,
      line_1: line_1,
      line_2: line_2,
      line_3: line_3,
      tableDataChange: [],
      state: '',
      selectState: {},
      timeout: null,
      controlsXiaoshou: ['', '数量', '金额'],
      controlSelectTypeXiaoshou: 1,
      timeStr: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      weeksSelectIndex: [],
      weeksArr: [],
      customDate: [],
      activeName: 'MERCHANT_REMOVAL',
      tabsList: [ // tabs 传的值

      ],
      tabPosition: 'month',
      eachRowWidth: 0,
      top10: [],
      tableData: [],
      procureTableData: [],
      tableHeight: 0,
      chartOptions: undefined,
      lineOptions: {},
      lineOptions2: {},
      controls: ['', '普药', '控销药'],
      controlSelectType: '2',
      monthArr: [],
      monthSelectIndex: 0,
      year: dayjs().format('YYYY'),
      monthOrYear: 'month',
      firstSelectData: undefined,
      initLoad: true,
      shopId: null,
      tableDataPurchase: [],
      tableDataSameDistrict: []//同区客户同期采购
    }
  },
  inject: ['superParams'],
  watch: { //监听父级数据变化

    //周统计时间监听
    'superParams.weeksSelectIndex': {
      immediate: true, // 将立即以表达式的当前值触发回调
      handler: function (val, oldVal) {
        this.weeksSelectIndex = this.superParams.weeksSelectIndex;
        // console.log("监听weeksSelectIndex对象", val, oldVal); //自定义
        // console.log(val, oldVal);
        this.changeDateType(this.monthOrYear);
      },
      deep: true,
    },

    //月统计监听
    'superParams.monthSelectIndex': {
      immediate: true, // 将立即以表达式的当前值触发回调
      handler: function (val, oldVal) {
        this.monthSelectIndex = this.superParams.monthSelectIndex;
        // console.log("监听monthSelectIndex对象", val, oldVal);
        this.changeDateType(this.monthOrYear);
        // console.log(this.$parent.monthArr, '--monthArr')
      },
      deep: true,
    },

    //自定义时间监听
    'superParams.customDate': {
      immediate: true, // 将立即以表达式的当前值触发回调
      handler: function (val, oldVal) {
        // this.customDate = this.superParams.customDate;
        // console.log("监听customDate对象", val, oldVal); //自定义 
        this.changeDateType(this.monthOrYear);
      },
      deep: true,
    },

    //时间类型切换监听 monthOrYear
    'superParams.monthOrYear': {
      immediate: true, // 将立即以表达式的当前值触发回调
      handler: function (val, oldVal) {
        // console.log("监听tabPosition对象", val, oldVal); //周，年，月，自定义 统计切换 
        console.log(this.superParams.year, '--year')
        this.monthOrYear = this.superParams.monthOrYear; // 时间类型 
        this.changeDateType(this.monthOrYear);
      },
      deep: true,
    },

    //时间类型切换监听 monthOrYear
    'superParams.yearCheck': {
      immediate: true, // 将立即以表达式的当前值触发回调
      handler: function (val, oldVal) {
        // console.log("监听tabPosition对象", val, oldVal); //周，年，月，自定义 统计切换 
        console.log(this.superParams.yearCheck, '--year')
        this.year = this.superParams.yearCheck; // 时间类型 
        this.changeDateType(this.monthOrYear);
      },
      deep: true,
    },

  },
  created() {
    // console.log(this.getWeekTime()) 
  },

  mounted() {
    this.queryShopId();
  },

  methods: {
    changeDateType(e) {
      this.tableDataChange = [];
      this.state = ''
      if (this.initLoad) return;
      switch (e) {
        case 'weeks':
          this.getData(37);
          break;
        case 'month':
          this.getData(37);
          break;
        case 'year':
          this.getData(37);
          break;
        case 'custom':
          this.getData(37);
          break;
      }
    },

    async init() {
      this.initLoad = false;
      this.monthArr = this.superParams.monthArr;
      const mainWindow = this.$refs.goodsSalesStatMain;
      const mainWindow2 = this.$refs.goodsSalesStatMain2;
      this.tableHeight = (mainWindow.offsetHeight - 12 - 24) / 2
      this.tableHeight2 = (mainWindow2.offsetHeight - 12 - 24)
      // console.log(this.tableHeight2, '---tableHeight2') 
      this.getData(37)
    },

    // 获取医药公司Id
    async queryShopId() {
      let obj = {
        type: '16',
        params: [this.$store.state.user.user_info.userId]
      }
      const resp = await queryGoodsSalesEcharts(obj)
      if (resp.length > 0) {
        this.shopId = resp[0].shop_id;
        // console.log(this.shopId,'---shopId')
      }
      this.init();
    },

    setTableFirst(lineOptions, data) {
      // 设置采购时段图表
      let tmp = []
      let arr = data ? data : this.tableDataProcureTime;



      if (arr) {
        arr.forEach((e, index) => {
            tmp.push({ ...e, name: e.grou, y: parseFloat(e.amount) , color: '#73A0FA' })
          }); 
      }
     
      let toolTipStr = lineOptions == 'lineOptions' ?  `<span style="font-size:14px"><b style="color:{point.color}">时段：</b>{point.grou}</span><br/><b style="color:{point.color}">金额：</b>{point.y:.2f}`  : `<span style="font-size:14px"><b style="color:{point.color}">频次：</b>{point.grou}</span> <br/><b style="color:{point.color}">金额：</b>{point.y:.2f}` 
      let _this = this
      this[lineOptions] = {
        credits: {
          enabled: false
        },
        exporting: { enabled: false },
        chart: {
          type: 'column',
          height: this.tableHeight2,
          marginTop: '60',
        },

        title: {
          show: false,
          align: 'left',
          text: `<span style="color:#262626;font-size:16px;font-weight:600;"></span>`
        },
        // subtitle: {
        //     align: 'left',
        //     text: '数据更新时间'+ dayjs().subtract(1,'day').format('YYYY-MM-DD')
        // },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            // allowPointSelect: true,// 是否允许选中点
            events: {
              click: function (e) {
                const index = e.point.index
              },
            },
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              // format: '{point.y}'
              format: '{point.y:.2f}' //2位小数
            }
          }
        },
        tooltip: {
          headerFormat: `<span style="font-size:11px">{point.grou}</span><br>`,
          pointFormat: toolTipStr
        },

        series: [{
          name: '',
          colorByPoint: false,
          data: tmp,
        }
        ]
      }
    },

    blurAuto() {
      console.log('失去焦点');
      if (!this.state) {
        this.tableDataChange = [];
      }
    },

    // 选取下面的某一项
    handleSelect(item) {
      console.log('选取下面的某一项', item)
      this.tableDataChange = [item]
      // this.procureTableData = [item]
      this.pay_shop_id = item.pay_shop_id;
      this.state = item.pay_shop_name;
      this.getData(38, item);
      this.$refs.hilightTable.setCurrentRow(item)

    },

    handleCurrentChange(e, index) {
      console.log(e, '----1e')
      // console.log(index, '----index') 
      // e.shop_id = e.pay_shop_id;
      this.pay_shop_id = e.pay_shop_id;
      this.getData(38, e);
      // // 点击第一个table的某一项 
    },

    // 选择采购金额的一项
    handleCurrentChangeSecond(e) {
      // console.log(e, '----e')
      this.getData(39, e);
      this.getData(40, e);
      // // 点击第一个table的某一项
    },

    createStateFilter(queryString) {
      return (state) => {
        // console.log(state)
        return (state.pay_shop_name.indexOf(queryString) === 0);
      };
    },

    // 远程搜索客户名称
    querySearchAsync(queryString, cb) {
      // console.log(this.procureTableData, '---this.procureTableData')
      // console.log(cb, '---cb')
      var restaurants = this.top10.concat(this.procureTableData);
      var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      // console.log(results, 'el-autocomplete')
      this.timeout = setTimeout(() => {
        cb(results);
      }, 500);
    },

    getPercent(v) {
      if (!v) {
        return '0.00'
      }
      return (v * 100).toFixed(2)
    },

    async getData(type, data) {
      this.monthArr = this.superParams.monthArr;
      this.customDate = this.superParams.customDate;
      // 2023 8
      // type	类型（与报表端确认，一个接口一个类型） 月 1,2,3  /  年 4,5,6
      // params	参数组(示例： '202308','2' )  '202308','2','1696371675141570561'   /  时间  ， 2 控销  1普药 ，
      let startTime = ''
      let endTime = ''
      // console.log(this.monthArr)
      const m = this.monthArr[this.monthSelectIndex].m;
      const day = new Date().getDate() < 10 ? '0' + (new Date().getDate() - 1) : (new Date().getDate() - 1)
      const lastDay = dayjs(this.year + m).endOf('month').format('YYYY-MM-DD')
      const month = new Date().getMonth() < 9 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
      //周时间处理
      if (this.monthOrYear == 'weeks') {
        startTime = this.weeksSelectIndex.startDate.replaceAll('-', '')
        endTime = this.weeksSelectIndex.endDate.replaceAll('-', '')
      } //月时间处理
      else if (this.monthOrYear == 'month') {
        startTime = this.year + m + '01'
        if (m != month) {
          endTime = lastDay.replaceAll('-', '')
        } else {
          endTime = this.year + m + day
        }
      }
      //年时间处理
      else if (this.monthOrYear == 'year') {
        // 获取当前年份
        let date = new Date(); //获取当前时间
        let year = date.getFullYear(); //获取当前年份
        if (this.year == year) {
          startTime = this.year + '0101'
          endTime = this.year + month + day
        } else {
          startTime = this.year + '0101'
          endTime = this.year + '1231'
        }

      }
      //自定义时间处理
      else if (this.monthOrYear == 'custom') {
        if (this.customDate.length > 0) {
          startTime = this.customDate[0].replaceAll('-', '')
          endTime = this.customDate[1].replaceAll('-', '')
        }

      }
      // console.log(type, startTime, endTime)
      this.requestGoodsSalesEcharts(type, { startTime: startTime, endTime: endTime, ...data })

    },

    // 请求报表数据。
    async requestGoodsSalesEcharts(type, dataParmas) {
      // console.log(dataParmas, '----dataParmas')

      let obj = {
        type: String(type),
      }
      if (this.shopId != null) {
        switch (type) {
          case 37:
            obj.type = '42'
            break;
          case 38:
            obj.type = '43'
            break;
          case 39:
            obj.type = '44'
            break;
          case 40:
            obj.type = '45'
            break;
          case 41:
            obj.type = '46'
            break;

          default:
            break;
        }

      }

      if (!dataParmas.startTime) return

      if (type == 37) {
        obj['params'] = [dataParmas.startTime, dataParmas.endTime]
      }

      if (type == 38) {
        obj['params'] = [dataParmas.startTime, dataParmas.endTime, this.pay_shop_id]
      }

      if (type == 39 || type == 40) {
        obj['params'] = [dataParmas.startTime, dataParmas.endTime, this.pay_shop_id, dataParmas.goods_id]
      }

      if (type == 41) {
        obj['params'] = [dataParmas.startTime, dataParmas.endTime, dataParmas.areaCodeIn]
      }

      this.shopId ? obj['params'].push(this.shopId) : obj['params'] = obj['params'] //医药公司Id

      // 37	L1 采购排名   [开始日期,结束日期]
      // 38	L2 采购金额   [开始日期,结束日期,shopID] 
      // 39	L3 采购时段(时) [开始日期,结束日期,shopID,商品ID] 
      // 40	L3 采购频次(次) [开始日期,结束日期,shopID,商品ID]
      // 41	L3 同区客户同期采购  [开始日期,结束日期,地区编码(in)]
      // 42	L1 采购排名(医药公司) [开始日期,结束日期,医药公司Id]
      // 43	L2 采购金额(医药公司) [开始日期,结束日期,shopID,医药公司Id]
      // 44	L3 采购时段(时)(医药公司) [开始日期,结束日期,shopID,商品ID,医药公司Id] 
      // 45	L3 采购频次(次)(医药公司) [开始日期,结束日期,shopID,商品ID,医药公司Id] 
      // 46	L3 同区客户同期采购(医药公司) [开始日期,结束日期,地区编码(in),医药公司Id]
      const resp = await queryGoodsSalesEcharts(obj);

      if (resp && resp.length) {
        // 采购排名
        if (type == 37) {
          this.procureTableData = [];
          this.tableDataPurchase = [];
          this.tableDataProcureTime = [];
          this.tableDataFrequency = [];
          this.tableDataSameDistrict = [];
          this.procureTableData = resp; //采购报表
          this.procureTableDataSelectAll = resp; //采购报表全部
          this.pay_shop_id = this.procureTableData[0].pay_shop_id;
          this.requestGoodsSalesEcharts(38, { startTime: dataParmas.startTime, endTime: dataParmas.endTime })
          this.$refs.hilightTable.setCurrentRow(this.procureTableData[0])

        }
        // 采购金额
        if (type == 38) {
          this.tableDataPurchase = [];
          this.tableDataProcureTime = [];
          this.tableDataFrequency = [];
          this.tableDataSameDistrict = [];
          this.tableDataPurchase = resp; //采购金额报表
          // console.log(dataParmas.pay_shop_id, '----dataParmas.pay_shop_id')
          // console.log(this.tableDataPurchase, '--- this.tableDataPurchase');
          // console.log(this.procureTableData, '--- this.procureTableData');
          // let district_code = ['611021 洛南县','451123 富川瑶族自治县'];
          let district_code = [];
          // 区域code
          district_code = dataParmas.district_code ? dataParmas.district_code.split(',') : this.procureTableData[0].district_code.split(',');
          let areaCodeIn = '';
          for (let i = 0; i < district_code.length; i++) {
            areaCodeIn += district_code[i].slice(0, 6) + ',';
          }
          areaCodeIn = areaCodeIn.slice(0, areaCodeIn.length - 1)
          dataParmas ? this.$refs.hilightTable2.setCurrentRow(this.tableDataPurchase[0]) : this.$refs.hilightTable.setCurrentRow2(dataParmas)

          // console.log(this.tableDataPurchase[0], '-tableDataPurchase')
          this.requestGoodsSalesEcharts(39, {
            startTime: dataParmas.startTime, endTime: dataParmas.endTime,
            goods_id: dataParmas.goods_id ? dataParmas.goods_id : this.tableDataPurchase[0].goods_id,
          });

          this.requestGoodsSalesEcharts(40, {
            startTime: dataParmas.startTime, endTime: dataParmas.endTime,
            goods_id: dataParmas.goods_id ? dataParmas.goods_id : this.tableDataPurchase[0].goods_id,
          })

          this.requestGoodsSalesEcharts(41, { startTime: dataParmas.startTime, endTime: dataParmas.endTime, areaCodeIn: areaCodeIn })

        }
        // 采购时段(时)
        if (type == 39) {
          this.tableDataProcureTime = [];
          this.tableDataProcureTime = resp; //采购时段报表 
          this.setTableFirst('lineOptions', this.tableDataProcureTime);
        }
        // 采购频次
        if (type == 40) {
          this.tableDataFrequency = [];
          this.tableDataFrequency = resp; //采购频次报表 
          // console.log(this.tableDataFrequency, '---tableDataFrequency')
          this.setTableFirst('lineOptions2', this.tableDataFrequency);
        }
        //同区客户同期采购
        if (type == 41) {
          this.tableDataSameDistrict = [];
          this.tableDataSameDistrict = resp; //同区客户同期采购报表 
          // console.log(this.tableDataFrequency, '---tableDataFrequency')
        }
      } else { 
        this.setTableFirst('lineOptions', this.tableDataProcureTime);
        this.setTableFirst('lineOptions2', this.tableDataFrequency);
      }

    }

  }

}

</script>
<style lang="scss" scoped>
$eachWidth: var(--row-width, "500px");
$mainHeight: var(--main-height, "600px");

::v-deep {
  .el-input--mini .el-input__inner {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 30px;
  }
}

.mainView2 {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  height: 100%;
  width: 100%;

  .topView {
    background-color: #fff;
    padding: 12px;
    padding-top: 0px;
    border-radius: 4px;
    overflow: hidden;

    ::v-deep .buttonView {
      display: flex;
      align-items: center;

      .tabsTeShu {
        .el-tabs__item {
          font-size: 14px;
          font-weight: 400;
        }

        .el-tabs__item.is-active {
          font-weight: 700;
        }
      }
    }
  }

  .goodsSalesStatMain {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: calc(65% - 165px - 12px);
    max-height: 600px;
    margin-top: 12px;

    .flexLineView {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: $eachWidth;

      .row {
        width: 100%;

        background-color: #fff;
        border-radius: 4px;
        overflow: hidden;
        height: calc(($mainHeight / 2));
        padding: 12px;
        position: relative;
      }
    }

    .mainHeight {
      height: $mainHeight + 24px;
      border-radius: 4px;
      overflow: hidden;

      background-color: #fff;
      position: relative;
      padding: 12px;
    }

    .dropDownBtn {
      // position: absolute;
      top: 12px;
      left: 24px;
      height: 40px;
    }

    .numberView {
      position: absolute;
      top: 0px;
      left: 0px;

      .numberImg {
        width: 20px;
        height: 20px;
      }
    }

    .leftHalf {
      height: calc((($mainHeight) / 2) - 200px);
    }

    .lineViewHorizon {
      height: 12px;
    }

    .secondTableView {
      position: relative;
      padding: 12px;
      background-color: #fff;
      border-radius: 4px;
      overflow: hidden;

      .classNameView {
        display: flex;
        align-items: center;
        height: 30px;
        font-size: 14px;
        color: #595959;
        background-color: #fff;
        padding-left: 12px;
      }
    }

    .lineView {
      width: 12px;
    }
  }

  .goodsSalesStatMain2 {
    //height: 450px;
    height: calc(45% - 105px - 12px);
    // max-height: 400px;
  }

  .percentView {
    position: relative;
    z-index: 999;
    background-color: #f0f0f0;

    .percentBg {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: -1;
      background-color: #fbabaf;
    }

    .percentText {
      height: 100%;
      width: 100%;
      z-index: 999;
      text-align: right;
    }
  }

}

::v-deep.el-table .warning-row {
  background: rgb(255, 242, 241);
}
</style>