var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "itemCont" },
    [
      _c(
        "div",
        { staticClass: "itemImg" },
        [
          _c("el-image", {
            staticStyle: {
              width: "180px",
              height: "180px",
              padding: "15px 0px",
            },
            attrs: {
              lazy: "",
              src: _vm.item.image || _vm.avater,
              alt: "",
              "preview-src-list": _vm.item.image ? [_vm.item.image] : [],
            },
          }),
        ],
        1
      ),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: _vm.item.name, placement: "top" },
        },
        [
          _c("div", { staticClass: "itemBox name" }, [
            _vm._v(" " + _vm._s(_vm.item.name || "") + " "),
          ]),
        ]
      ),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: _vm.item.factory,
            placement: "top",
          },
        },
        [
          _c("div", { staticClass: "itemBox factory" }, [
            _vm._v(" " + _vm._s(_vm.item.factory || "") + " "),
          ]),
        ]
      ),
      _c("p", { staticClass: "itemBox factory" }, [
        _vm._v(" " + _vm._s(_vm.item.approvalNumber || "") + " "),
      ]),
      _c("div", { staticClass: "itemBox factory" }, [
        _vm._v(" " + _vm._s(_vm.item.specification || "") + " "),
      ]),
      _c(
        "div",
        { staticClass: "itemBox shopCar" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function ($event) {
                  return _vm.handleAddbuy(_vm.item)
                },
              },
            },
            [_vm._v(" 加入仓库")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }