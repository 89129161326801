var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("div", { staticClass: "firstTopic text-left mb20 header" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c("goodsInfo", {
        ref: "goods-Info",
        attrs: { pageType: _vm.pageType },
        on: {
          "submit-goods": _vm.submit,
          back: _vm.goBack,
          "reject-goods": _vm.rejectGoods,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }