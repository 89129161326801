const state = {
  activeName: "1",
};

const mutations = {
  SET_activeName: (state, load) => {
    state.activeName = load;
  },
};

const actions = {
  setActiveName({ commit }, load) {
    commit("SET_activeName", load);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
