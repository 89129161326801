
<!--  -->
<template>
  <div class="comContent">
    <div class="title">
      <div>
        <el-form ref="formTool"
                 :model="formTool"
                 :inline="true">
          <el-form-item label="部门名称"
                        prop="deptName">
            <el-input v-model="formTool.deptName"
                      style="width: 150px;"
                      size="small"
                      placeholder="请输入部门名称" />
          </el-form-item>
          <el-form-item label="部门负责人"
                        prop="deptPrincipal">
            <el-input v-model="formTool.deptPrincipal"
                      style="width: 150px;"
                      size="small"
                      placeholder="请输入部门负责人" />
          </el-form-item>
          <el-form-item label="部门ERPid"
                        prop="deptErpId">
            <el-input v-model="formTool.deptErpId"
                      style="width: 150px;"
                      size="small"
                      placeholder="请输入部门ERPid" />
          </el-form-item>
          <el-form-item label="状态"
                        prop="status">
            <el-select v-model="formTool.status"
                       style="width: 150px;"
                       size="small"
                       placeholder="请选择状态">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>
      </div>
      <div>
        <MyButton type="primary"
                  text="查询"
                  @click="getList(1)" />
        <el-button size="small"
                   @click="reset()">重置</el-button>
        <MyButton type="primary"
                  text="新建部门"
                  @click="editInfo()" />
      </div>
    </div>
    <div class="center">
      <MyTable :table-data="tableData"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getList"
               @handleSelectionChange="currentChange">
        <template slot="status"
                  slot-scope="scope">
          <span>{{scope.row.status ? '开启':'关闭'}}</span>
        </template>
        <template slot="caozuo"
                  slot-scope="scope">
          <div style="display:flex">
            <el-link type="primary"
                     :underline="false"
                     size="small"
                     @click="editInfo(scope.row)" v-if="!scope.row.beMainDept">编辑</el-link>
            <el-link type="primary"
                     style="margin-left:8px"
                     :underline="false"
                     size="small"
                     @click="updateStatus(scope.row,'edit')" v-if="!scope.row.beMainDept" >{{scope.row.status ? '关闭':'开启'}}</el-link>
          </div>
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable'
import { externalsalesdeptinfoPage, updateStatus } from '@/api/salesControl/commodity'


export default {
  components: { MyButton, MyTable }, // , MenuForm, MyTabs, MyTable, MyTypes
  data () {
    return {
      clearable: true,
      formTool: {
        deptName: '',
        deptPrincipal: '',
        deptErpId: '',
        status: ''
      },
      options: [{
        value: '1',
        label: '开启'
      }, {
        value: '0',
        label: '关闭'
      }],
      operation: false,
      selection: false,
      index: false, // 不展示序号，自己重新定义了序号
      tableData: [
      ],
      tableOption: [
        { label: '部门名称', prop: 'deptName' },
        { label: '部门负责人', prop: 'deptPrincipal' }, // 这里表示自定义列 , slot: true
        // { label: '在职状态', prop: 'roleCode' },
        { label: '运营联系电话', prop: 'operationPhone' },
        // { label: '用户ID', prop: 'userCode' },
        // { label: '角色', prop: 'roleDesc' },
        { label: '部门ERPid', prop: 'deptErpId' },
        { label: '状态', prop: 'status', slot: true },
        { label: '操作', prop: 'caozuo', slot: true, width: '220px' }
      ],
      page: { total: 0, current: 1, size: 10 },
      beMainDept:true, //是不是主部门
    }
  },
  computed: {
  },
  watch: {},
  created () {
    this.getList()
  },
  mounted () { 
  
},
  methods: {
    
    // 新增或编辑部门 
    editInfo (row) {
      if (row) {
        this.$router.push({
          path: '/salesControlMange/salesControl-section-add',
          query: {
            id: row.id
          }
        })
      } else {
        this.$router.push({
          path: '/salesControlMange/salesControl-section-add',
          query: {
            id: ''
          }
        })
      }
    },
    // 查询列表
    getList (num) {
      if (num) {
        this.page.current = num
      }
      console.log(this.page, 'getList')
      const { current, size } = this.page
      let d = { current, size, ...this.formTool }
      this.listLoading = true
      externalsalesdeptinfoPage(d).then((res) => {
        this.tableData = res.records
        this.page.total = res.total
        this.listLoading = false
      }).catch(() => {
        this.listLoading = false
      })
    },
    // 更新部门状态
    updateStatus (row) {
      let params = {
        id: row.id,
        deptErpId: row.deptErpId,
        status: ''
      }
      if (row.status) {
        params.status = "0"
      } else {
        params.status = "1"
      }
      updateStatus(params).then(res => {
        this.$message.success('状态更改成功')
        this.getList()
      })
    },
    cancal () {
      this.dialogPermissionVisible = false
    },
    reset () {
      this.formTool = { deptName: '', deptPrincipal: '', deptErpId: '' }
      // this.$refs.formTool.resetFields()
      this.page.current = 1
      this.getList(1)
    },
    currentChange (val) {
      console.log('current', val)
    },
  }
}
</script>
<style lang='scss' scoped>
.comContent {
  padding: 0px;
  .title {
    height: 56px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f5f5ff;
    .el-form-item {
      margin-bottom: 0px !important;
    }
  }
  .center {
    margin: 0 24px;
    margin-top: 16px;
    height: calc(100% - 131px);
  }
}
</style>
