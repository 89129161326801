var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _vm.sidebar.opened
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "16px",
                      height: "12px",
                      padding: "20px",
                      "font-family": "PingFang SC, PingFang SC-Regular",
                      "font-weight": "400",
                      "text-align": "left",
                      color: "#8c8c8c",
                      "line-height": "12px",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/caidan.png"),
                        width: "16px",
                        alt: "",
                      },
                    }),
                    _vm._v(" 常用操作 "),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "ownPath" },
                  _vm._l(_vm.path, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "smallBox",
                        on: {
                          click: function ($event) {
                            return _vm.clickPath(item.menuUrl)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.menuName) + " ")]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "unique-opened": false,
                "active-text-color": _vm.variables.menuActiveText,
                "collapse-transition": false,
                mode: "vertical",
              },
              on: { select: _vm.handleSelect },
            },
            _vm._l(_vm.permission_routes, function (route) {
              return _c("sidebar-item", {
                key: route.id,
                attrs: { item: route, "base-path": route.path },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }