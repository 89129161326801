var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "classification" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("span", { staticClass: "font" }, [_vm._v("仓库中的商品")]),
        _c(
          "el-form",
          {
            ref: "formTool",
            staticStyle: { "text-align": "left" },
            attrs: { model: _vm.formTool, inline: true },
          },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "name" } },
              [
                _c("el-input", {
                  attrs: {
                    placeholder: "请输入商品名称",
                    clearable: "",
                    size: "small",
                  },
                  model: {
                    value: _vm.formTool.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formTool, "name", $$v)
                    },
                    expression: "formTool.name",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "approvalNumber" } },
              [
                _c("el-input", {
                  attrs: {
                    placeholder: "请输入批准文号",
                    clearable: "",
                    size: "small",
                  },
                  model: {
                    value: _vm.formTool.approvalNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.formTool, "approvalNumber", $$v)
                    },
                    expression: "formTool.approvalNumber",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.clickSearch(1)
                      },
                    },
                  },
                  [_vm._v("查询")]
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.reset()
                      },
                    },
                  },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "center", staticStyle: { position: "relative" } },
      [
        _c(
          "div",
          { staticClass: "shangjia" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.clickBtn },
              },
              [_vm._v("批量上架")]
            ),
          ],
          1
        ),
        _c("MyTable", {
          attrs: {
            "table-data": _vm.tableData,
            index: _vm.index,
            selection: _vm.selection,
            page: _vm.page,
            operation: _vm.operation,
            "table-option": _vm.tableOption,
          },
          on: {
            "update:tableOption": function ($event) {
              _vm.tableOption = $event
            },
            "update:table-option": function ($event) {
              _vm.tableOption = $event
            },
            "page-change": _vm.getList,
            handleSelectionChange: _vm.handleSelectionChange,
          },
          scopedSlots: _vm._u([
            {
              key: "image",
              fn: function (scope) {
                return [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "80px",
                            height: "80px",
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("el-image", {
                            staticStyle: { width: "80px" },
                            attrs: {
                              src: scope.row.image || _vm.avater,
                              lazy: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "imageP" }, [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-weight": "600",
                              color: "#262626",
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.name) + " ")]
                        ),
                        _c("p", [
                          _vm._v(" " + _vm._s(scope.row.specification) + " "),
                        ]),
                        _c("p", [
                          _vm._v(" " + _vm._s(scope.row.factory) + " "),
                        ]),
                        _c("p", [
                          _vm._v(" " + _vm._s(scope.row.barCode) + " "),
                        ]),
                      ]),
                    ]
                  ),
                ]
              },
            },
            {
              key: "menu",
              fn: function (scope) {
                return [
                  _c(
                    "el-link",
                    {
                      attrs: { underline: false, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteHandle(scope.row)
                        },
                      },
                    },
                    [_vm._v("上架")]
                  ),
                  _c(
                    "el-link",
                    {
                      staticStyle: { "margin-left": "16px" },
                      attrs: { underline: false, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.editHandle(scope.row)
                        },
                      },
                    },
                    [_vm._v("编辑")]
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }