var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-dlg-root" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog-common",
          attrs: {
            title: "关联商品",
            visible: _vm.dialogVisible,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.onClosed,
          },
        },
        [
          _c("div", [
            _c(
              "div",
              [
                _vm._v(" 商品名称： "),
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "输入商品名称", size: "small" },
                  on: { change: _vm.inputChange },
                  model: {
                    value: _vm.searchContent,
                    callback: function ($$v) {
                      _vm.searchContent = $$v
                    },
                    expression: "searchContent",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "goods-content-box" },
              [
                _vm.dialogtableData && _vm.dialogtableData.length > 0
                  ? _c(
                      "div",
                      { staticClass: "goods-box" },
                      _vm._l(_vm.dialogtableData, function (li, i) {
                        return _c("div", { key: i, staticClass: "goods-li" }, [
                          _c(
                            "div",
                            { staticClass: "goods-li-img" },
                            [
                              _c("el-image", {
                                staticClass: "goods-li-img-el",
                                attrs: {
                                  fit: "contain",
                                  src: li.image || _vm.avater,
                                  "preview-src-list": [li.image || _vm.avater],
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "goods-li-content",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  li.selected = !li.selected
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "goods-li-name" }, [
                                _vm._v(_vm._s(li.goodsName)),
                              ]),
                              _c("div", { staticClass: "goods-li-txt" }, [
                                _vm._v(_vm._s(li.goodsFactory)),
                              ]),
                              _c("div", { staticClass: "goods-li-txt" }, [
                                _vm._v(_vm._s(li.approvalNumber)),
                              ]),
                              _c("div", { staticClass: "goods-li-txt" }, [
                                _vm._v(_vm._s(li.specification)),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "goods-li-check",
                                  class: { act: li.selected },
                                },
                                [_c("i", { staticClass: "el-icon-check" })]
                              ),
                            ]
                          ),
                        ])
                      }),
                      0
                    )
                  : _c("div", { staticClass: "goods-empty" }, [
                      _vm._v("商品数据为空"),
                    ]),
                _vm.dialogtableData && _vm.dialogtableData.length > 0
                  ? _c("Pagination", {
                      attrs: {
                        total: _vm.dialogpage.total,
                        page: _vm.dialogpage.current,
                        limit: _vm.dialogpage.size,
                        "page-sizes": _vm.dialogpage.pageSizes,
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.dialogpage, "current", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.dialogpage, "size", $event)
                        },
                        pagination: _vm.pageChange,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }