
<!--  -->
<template>
  <div class="comContent">
    <div style="height: 16px;"></div>
    <div class="headerLine">
      <el-form ref="formRef" :model="formTool" :inline="true">
        <el-form-item prop="selfControllerId">
          <el-select v-model="formTool.selfControllerId" size="small" placeholder="自营控销品" clearable
            style="max-width:130px">
            <el-option v-for="item in selfControllerOptions" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>

           <!-- payeeShopName -->
        <el-form-item prop="payeeShopName"> 
          <el-input v-model="formTool.payeeShopName" size="small" placeholder="所属医药公司" /> 
        </el-form-item>

        <el-form-item prop="selfGoodsId">
          <el-select v-model="formTool.selfGoodsId" size="small" placeholder="请选择自营商品" clearable>
            <el-option v-for="item in selfGoodsList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        
     
        <el-form-item prop="areaCompanyId">
          <el-select v-model="formTool.areaCompanyId" size="small" placeholder="请选择地区公司" clearable>
            <el-option v-for="item in areaCompanyList" :key="item.id" :label="item.shopName" :value="item.shopId" />
          </el-select>

        </el-form-item>
        <el-form-item prop="dealType">
          <el-select v-model="formTool.dealType" size="small" placeholder="全部" clearable style="max-width:130px">
            <el-option v-for="item in dealTypeOptions" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>

        <el-form-item prop="orderStatus">
          <el-select v-model="formTool.orderStatus" size="small" placeholder="请选择订单状态" clearable >
            <el-option v-for="item in orderStatusList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>

        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList('search')">检索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="resetForm">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="exportXLSX">导出</el-button>
        </el-form-item>
      </el-form>
      <el-form>
        <el-form-item>
          <el-button size="small" type="primary" style="margin-left: auto;" @click="commitAll">一键审单</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="headerLine">

      <el-form ref="formRef2" :model="formTool" :inline="true">
        <el-form-item prop="province">
          <el-select v-model="formTool.province" size="small" placeholder="请选择省" style="width: 130px;"
            @change="provinceBtn">
            <el-option v-for="(item, index) in provinceData" :key="index" :label="item.rname" :value="item.id" />
          </el-select>
          <el-select v-model="formTool.city" size="small" placeholder="请选择市" style="margin-left: 10px; width: 130px"
            @change="cityBtn">
            <el-option v-for="(item, index) in cityData" :key="index" :label="item.rname" :value="item.id" />
          </el-select>
          <el-select v-model="formTool.area" size="small" placeholder="请选择区" style="margin-left: 10px; width: 130px"
            @change="areaBtn">
            <el-option v-for="(item, index) in areaData" :key="index" :label="item.rname" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item prop="clinicName">
          <el-input v-model="formTool.clinicName" size="small" placeholder="按诊所名称" />
        </el-form-item>
        <el-form-item prop="name">
          <el-input v-model="formTool.name" size="small" placeholder="按用户名" />
        </el-form-item>
        <!-- <el-form-item>
          <el-button size="small"
                     @click="resetForm">重置</el-button>
        </el-form-item> -->
      </el-form>
      <el-form>
        <el-form-item>
          <el-switch size="small" v-model="shendan" @change="openCheck" active-color="#13ce66" inactive-color="#ff4949"
            active-text="自动审单" inactive-text="非自动审单">
          </el-switch>
        </el-form-item>
      </el-form>


      <!-- <div style="margin-left: auto;">
        <el-button size="small"
                   type="primary"
                   @click="commitAll">一键审单</el-button>
      </div> -->
    </div>
    <div class="center">
      <MyTable :table-data="tableData" :showpage="true" :selection="true" :page="page" :index="false"
        :table-option.sync="tableOption" @handleSelectionChange="handleSelectionChange" @page-change="getList"
        :selectableCanBeDisable="true">
        <template slot="clinicName" slot-scope="scope">
          <p style="font-weight:600;color:#262626">
            {{ scope.row.clinicName }}
          </p>
          <p style="color:#666">
            {{ scope.row.loginName }}
          </p>
        </template>
        <template slot="name" slot-scope="scope">
          <div style="display:flex;align-items:center;">
            <div style="width:80px;height:80px;display:flex;align-items:center">
              <el-image :src="scope.row.image" lazy style="width: 80px;" />
            </div>
            <div class="imageP">
              <p style="font-weight:600;color:#262626">
                {{ scope.row.name }}
              </p>
              <p>
                {{ scope.row.specification }}
              </p>
              <p>
                {{ scope.row.factory }}
              </p>
            </div>
          </div>
        </template>
        <template slot="amount" slot-scope="scope">
          <span>
            ￥{{ scope.row.amount }}
          </span>
        </template>
        <template slot="price" slot-scope="scope">
          <span>
            ￥{{ scope.row.price }}
          </span>
        </template>
        <template slot="dzCheckStatus" slot-scope="scope">
          <div style="display: flex;align-items: center;">
            <span>省区公司</span>
            <div v-if="scope.row.szCheckStatus">
              <el-icon class="el-icon-success" style="color: #32bf78;margin: 0px 5px;"></el-icon>
              <span>{{ scope.row.szCheckDate || '' }}</span>
            </div>
            <el-icon v-else class="el-icon-error" style="color: #FF4D4F;margin: 0px 5px;"></el-icon>
          </div>
          <div style="display: flex;align-items: center;">
            <span>地区公司</span>
            <div v-if="scope.row.dzCheckStatus">
              <el-icon class="el-icon-success" style="color: #32bf78;margin: 0px 5px;"></el-icon>
              <span>{{ scope.row.dzCheckDate || '' }}</span>
            </div>
            <el-icon v-else class="el-icon-error" style="color: #FF4D4F;margin: 0px 5px;"></el-icon>
          </div>
        </template>

        <template slot="deal" slot-scope="scope" fixed>
          <el-button v-if="scope.row.beAllowOperation" type="text" style="color: #F5222D" size="small"
            @click="commitSignal(scope.row)">确认</el-button>
          <el-button v-else type="text" style="color: #666;" size="small">{{ scope.row.dzCheckDate ? '已确认' : '确认' }}</el-button>
          <!-- <el-switch  v-model="scope.row.status" active-color="#F5222D" inactive-color="#999999"
              active-text="通过" inactive-text="待审" class="switchStyle"  /> -->
          <!-- <el-button-group>
              <el-button type="primary" style="background-color: #fff;">确定</el-button>
              <el-button type="default" style="background-color: #262626;">未确定</el-button>
            </el-button-group> -->
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>
import {
  regionProvince,
  regionCity,
  regionArea,
  autoCheck,
  openCheck
} from "@/api/merchantsettlement/index";
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable'
import TicketAssociativeClassification from "./dialog/ticketAssociativeClassification";
import { getSelfGoodsList, getAreaCompanyListByUser, getSelfControllerWaitDealList, updateSelfControllerWaitDea, exportOrder } from '@/api/shopmange/index'
import { goodsclassOne } from '@/api/commodityreference'
import avater from "/public/img/default.png"
export default {
  components: { MyButton, MyTable, TicketAssociativeClassification },
  data() {
    return {
      shendan: true,
      avater: avater,
      text: '',
      clearable: true,
      mode: 'edit',
      goodsOneClassIdOptions: [],
      goodsOneClassId: '',
      formTool: {
        name: '',
        province: "",
        provinceName: "",
        city: "",
        cityName: "",
        area: "",
        areaName: "",
        selfControllerId: '',
        selfGoodsId: "",//自营商品 id
        areaCompanyId: "",//地区公司 id
        dealType: "",//审核状态
        clinicName: "",//诊所名称
        orderStatus:'',
        payeeShopName:''
      },
      selfControllerOptions: [{ name: '自营控销品', id: '1' }],
      dealTypeOptions: [{ name: '地办待审', id: '1' }, { name: '省办待审', id: '2' }, { name: '通过', id: '3' }, { name: '全部', id: '' }],
      provinceData: {},
      cityData: [],
      areaData: [],
      rules: {
        // name: [
        //   { required: true, message: '请输入活动名称', trigger: 'blur' },
        // ],
        // date: [
        //   { required: true, message: '请选择活动时间', trigger: 'blur' },
        // ],
        // type: [
        //   { required: true, message: '请选择活动类型', trigger: 'blur' },
        // ],
      },
      tableData: [],
      tableOption: [
        { label: '诊所名称', prop: 'clinicName', width: "150px", slot: true },
        { label: '所属公司', prop: 'payeeShopName', width: "150px" },
        { label: '采购商品', prop: 'name', slot: true, minWidth: "200px" },
        { label: '采购总金额', prop: 'amount', slot: true },
        { label: '采购数量', prop: 'qty', },
        { label: '采购单价', prop: 'price', slot: true },
        { label: '采购时间', prop: 'orderTime', width: "200px" },
        { label: '收货地址', prop: 'address', width: "150px" },
        { label: '隶属地区公司', prop: 'dzShopName', width: "150px" }, 
        { label: '订单状态', prop: 'orderStatus', width: "150px" },
        { label: '审核状态', prop: 'dzCheckStatus', slot: true, width: '250px' },
        { label: '操作', prop: 'deal', slot: true, align: 'center', width: '120px' },
      ],
      orderStatusList: [

        {
          name: "待付款",
          id: 1,
        },
        {
          name: "待发货",
          id: 2,
        },
        {
          name: "配送中",
          id: 3,
        },
        // {
        //   name: "交易取消",
        //   id: 5,
        // },
        {
          name: "申请退款",
          id: 6,
        },
        {
          name: "已退款",
          id: 7,
        },
        {
          name: "签收",
          id: 8,
        },
        {
          name: "完成",
          id: 9,
        },
        {
          name: "售后",
          id: 10,
        },
        {
          name: "待开户",
          id: 11,
        }
      ],
      page: {
        total: 10,
        current: 1,
        size: 10
      },
      selectList: [],
      selfGoodsList: [],//自营商品
      areaCompanyList: [],//地办列表
    }
  },
  computed: {

  },
  watch: {
    "formTool.province": {
      handler(newValue, oldValue) {
        this.formTool.city = ``;
        this.formTool.area = ``;
        this.cityData = [];
        this.areaData = [];
      }

    }
  },
  created() {
    this.autoCheck()
    this.getList()
    this.query()
  },
  mounted() {
    this.getProvince();
    this.getSelectTyleList()
  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
  methods: {
    // 是否已开启自动审单
    autoCheck() {
      autoCheck().then(res => {
        if (res.code == 0) {
          this.shendan = res.data
        }
      })
    },
    // 打开-关闭自动审单
    openCheck() {
      openCheck().then(res => {
        if (res.code == 0) {
          this.shendan = res.data
          this.$message.success('操作成功')
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    initActivity() {
      this.mode = 'edit'
      this.formTool = {
        name: '',
      }
    },
    query() {
      goodsclassOne().then(res => {
        if (res.code == 0) {
          this.goodsOneClassIdOptions = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getSelectTyleList() {
      let user_info = this.$store.state.user
      getSelfGoodsList({}).then((resp) => {
        if (resp) {
          let tmp = []
          resp.map((e) => {
            tmp.push({
              id: e,
              name: e
            })
          })
          this.selfGoodsList = tmp
        }
      })
      getAreaCompanyListByUser({}).then((resp) => {
        if (resp.code == 0 && resp.data) {
          this.areaCompanyList = resp.data
        }
      })
    },
    getList(searchType) {
      if (searchType && searchType == 'search') {
        this.page.current = 1
      }
      let params = this.pendParams()
      getSelfControllerWaitDealList(params).then(res => {
        if (res.code == 0 && res.data && res.data.records) {
          this.tableData = res.data.records
          this.page.total = res.data.total
        } else {
          this.tableData = []
        }

      })
    },

    pendParams() {
      const params = {
        current: this.page.current,
        size: this.page.size,
        goodsName: this.formTool.selfGoodsId,
        dzShopId: this.formTool.areaCompanyId,//	地办商户id	query	false	
        province: this.formTool.province,//	省	query	false	
        city: this.formTool.city,//	市	query	false	
        area: this.formTool.area,//	区	query	false	
        clinicName: this.formTool.clinicName,//	诊所名称	query	false	
        loginName: this.formTool.name,//	登录名	query	false	
        status: this.formTool.dealType,//	审核状态：1:地区公司待审;2:省区公司待审;3,通过
        orderStatus:this.formTool.orderStatus, //订单状态
        payeeShopName:this.formTool.payeeShopName, //所属医药公司
      }

      return params
    },

    getProvince() {
      regionProvince().then((res) => {
        if (res.code === 0) {
          this.provinceData = res.data;
        }
      });
    },
    provinceBtn(item) {
      this.cityData = [];
      this.areaData = [];
      const provinceIndex = this.provinceData.findIndex(
        (res) => res.id === item
      );
      this.formTool.provinceName =
        this.provinceData[provinceIndex].rname;
      this.formTool.province =
        this.provinceData[provinceIndex].id;
      regionCity(item).then((res) => {
        this.cityData = res.data;
        this.formTool.city = ``;
        this.formTool.area = ``;
      });
    },
    cityBtn(item) {
      this.areaData = [];
      const cityIndex = this.cityData.findIndex((res) => res.id === item);
      this.formTool.cityName = this.cityData[cityIndex].rname;
      this.formTool.city = this.cityData[cityIndex].id;
      regionArea(item).then((res) => {
        this.formTool.area = "";
        this.areaData = res.data;
      });
    },
    areaBtn(item) {
      const areaIndex = this.areaData.findIndex((res) => res.id === item);
      this.formTool.areaName = this.areaData[areaIndex].rname;
      this.formTool.area = this.areaData[areaIndex].id;
    },
    changeOneClassId() {

    },
    resetForm() {
      this.$refs.formRef && this.$refs.formRef.resetFields()
      this.$refs.formRef2 && this.$refs.formRef2.resetFields()
      this.formTool.provinceName = ''
      this.formTool.province = '';
      this.formTool.payeeShopName = '';
      this.formTool.orderStatus = '';
      this.getList('search')
    },
    exportXLSX() {
      // 导出
      let params = this.pendParams()
      exportOrder(params);
    },
    commitAll() {
      // 一键审单
      if (!this.selectList.length) {
        this.$message.warning('请先选择数据')
        return
      }
      let that = this
      this.$alert('所有勾选的商品将进行审核确认，确认后不可撤销操作', '审核商品销售', {
        confirmButtonText: '确认',
        cancelButtonText: '返回',
        showCancelButton: true,
        callback: action => {
          if (action == 'confirm') {
            that.checkAction(that.selectList)
          }
        }
      });
    },
    commitSignal(item) {
      // 单个审核
      console.log(item);
      let that = this
      this.$alert('确认后不可撤销操作', '审核商品销售', {
        confirmButtonText: '确认',
        cancelButtonText: '返回',
        showCancelButton: true,
        callback: action => {
          if (action == 'confirm') {
            that.checkAction([item])
          }
          // this.$message({
          //   type: 'info',
          //   message: `action: ${action}`
          // });
        }
      });
    },
    checkAction(list) {
      let tmp = []
      let that = this
      list.forEach((e) => {
        tmp.push(e.orderId)
      })
      let params = {
        orderIds: tmp
      }
      updateSelfControllerWaitDea(params).then((res) => {
        if (res.code == 0) {
          this.$message.success('审单成功')
          that.getList()
        }
      })
    },
    handleSelectionChange(val) {
      this.selectList = val
    },
    handleSelectable(row) {
      // debugger
      return row.beAllowOperation
    }
  }
}
</script>
<style lang='scss' scoped>
.headerLine {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.center {
  height: calc(100% - 168px);
}

.imageP {
  // width: calc(100% - 110px);

  p {
    margin: 0 0 0 10px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
}
</style>
