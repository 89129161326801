var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c(
        "el-form",
        {
          ref: "formTool",
          staticStyle: { "text-align": "left" },
          attrs: { inline: true },
        },
        [
          _c("div", { staticClass: "header" }, [
            _c(
              "section",
              [
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "130px" },
                        attrs: { size: "small" },
                        model: {
                          value: _vm.goodsType,
                          callback: function ($$v) {
                            _vm.goodsType = $$v
                          },
                          expression: "goodsType",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "自营商品", value: "自营商品" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "130px" },
                        attrs: { size: "small" },
                        on: {
                          change: (val) => {
                            _vm.goodsBaseChange(val)
                          },
                        },
                        model: {
                          value: _vm.goodsBaseName,
                          callback: function ($$v) {
                            _vm.goodsBaseName = $$v
                          },
                          expression: "goodsBaseName",
                        },
                      },
                      _vm._l(_vm.goodsBaseTypeList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c("el-select", {
                      staticStyle: { width: "130px" },
                      attrs: { size: "small", disabled: "" },
                      model: {
                        value: _vm.orgName,
                        callback: function ($$v) {
                          _vm.orgName = $$v
                        },
                        expression: "orgName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "130px" },
                        attrs: { size: "small", clearable: "" },
                        on: { change: _vm.chooseArea },
                        model: {
                          value: _vm.areaName,
                          callback: function ($$v) {
                            _vm.areaName = $$v
                          },
                          expression: "areaName",
                        },
                      },
                      _vm._l(_vm.areaList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.orgName, value: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "80px" },
                        attrs: { size: "small" },
                        model: {
                          value: _vm.customerType,
                          callback: function ($$v) {
                            _vm.customerType = $$v
                          },
                          expression: "customerType",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "诊所", value: "诊所" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "clinicName" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "130px" },
                      attrs: {
                        size: "small",
                        placeholder: "客户名称",
                        clearable: "",
                      },
                      model: {
                        value: _vm.customName,
                        callback: function ($$v) {
                          _vm.customName = $$v
                        },
                        expression: "customName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.query()
                          },
                        },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "info" },
                        on: {
                          click: function ($event) {
                            return _vm.reset()
                          },
                        },
                      },
                      [_vm._v("重置")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "section",
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "default", plain: "" },
                    on: {
                      click: function ($event) {
                        return _vm.updatePrice()
                      },
                    },
                  },
                  [_vm._v("批量设置定价")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary", plain: "" },
                    on: {
                      click: function ($event) {
                        return _vm.addFixPriceInfo()
                      },
                    },
                  },
                  [_vm._v("增加定价对象")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.submitProductPrice()
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "tag-box" }, [
        _c("span", [
          _c(
            "span",
            {
              staticStyle: {
                "font-weight": "600",
                color: "#333",
                "margin-right": "20px",
              },
            },
            [_vm._v("地区默认定价")]
          ),
          _c("span", [_vm._v("销售定价")]),
          _c("span", { staticClass: "price-view" }, [
            _vm._v("¥" + _vm._s(_vm.priceFixedTwo(_vm.deatilData.salePrice))),
          ]),
          _c("span", [_vm._v("元/" + _vm._s(_vm.tableTagForm.goodsSkuUnit))]),
        ]),
        _c("span", { staticClass: "list" }, [
          _vm._v("合作医药公司"),
          _c("span", { staticClass: "price-view" }, [
            _vm._v("¥" + _vm._s(_vm.priceFixedTwo(_vm.deatilData.drugAmount))),
          ]),
          _vm._v("元"),
        ]),
        _c("span", { staticClass: "list" }, [
          _vm._v("地区公司"),
          _c("span", { staticClass: "price-view" }, [
            _vm._v("¥" + _vm._s(_vm.priceFixedTwo(_vm.deatilData.areaAmount))),
          ]),
          _vm._v("元"),
        ]),
        _c("span", { staticClass: "list" }, [
          _vm._v("地区公司转省区公司服务费"),
          _c("span", { staticClass: "price-view" }, [
            _vm._v(
              "¥" + _vm._s(_vm.priceFixedTwo(_vm.deatilData.provinceAmount))
            ),
          ]),
          _vm._v("元"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "content-table" },
        [
          _c("ProvinceFixPriceTable", {
            ref: "self-price-table",
            attrs: {
              "unit-list": _vm.unitList,
              goodsName: _vm.goodsBaseName,
              goodsId: _vm.goodsBaseId,
              orgCode: _vm.areaCode || _vm.orgCode,
              orgName: _vm.areaName || _vm.orgName,
              shopName: _vm.customName,
            },
            on: {
              goodsSkuPrice: _vm.goodsSkuPriceList,
              rowDidChange: _vm.rowDidChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择单位",
            visible: _vm.dialogVisible,
            width: "400px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content-box" },
            _vm._l(_vm.unitList, function (item, key) {
              return _c(
                "span",
                {
                  key: key,
                  class: _vm.unitKey == key ? "unit active" : "unit",
                  on: {
                    click: function ($event) {
                      return _vm.choseUnit(item, key)
                    },
                  },
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.choseUnitSure },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "增加定价对象",
            visible: _vm.fixPriceDialog,
            width: "60vw",
            "before-close": _vm.handleCloseFixPrice,
          },
          on: {
            "update:visible": function ($event) {
              _vm.fixPriceDialog = $event
            },
          },
        },
        [
          _c("ProvinceFixPriceAddDialog", {
            ref: "fixPriceDialog",
            attrs: {
              goodsId: _vm.goodsBaseId,
              orgCode: _vm.areaCode || _vm.orgCode,
            },
            on: { didClickMakeFix: _vm.didClickMakeFix },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量设置定价",
            visible: _vm.fixPriceDialogMutil,
            width: "950px",
            "before-close": _vm.handleCloseFixPriceMutil,
          },
          on: {
            "update:visible": function ($event) {
              _vm.fixPriceDialogMutil = $event
            },
          },
        },
        [
          _c("ProvinceFixPriceUpdateMutilDialog", {
            ref: "fixPriceDialogMutil",
            attrs: {
              orgCode: _vm.areaCode || _vm.orgCode,
              "unit-list": _vm.unitList,
              customerDatas: _vm.multiArr,
              goodsName: _vm.goodsBaseName,
              goodsId: _vm.goodsBaseId,
            },
            on: {
              closePage: function ($event) {
                _vm.fixPriceDialogMutil = false
              },
              addMutilSuccess: _vm.addMutilSuccess,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }