var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goodsContainer" },
    [
      _c(
        "div",
        { staticClass: "topView" },
        [
          _c(
            "el-form",
            {
              ref: "formTool",
              attrs: { model: _vm.formTool, size: "small", inline: true },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "", "label-width": "120px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "ERP编码/名称" },
                    model: {
                      value: _vm.formTool.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "name", $$v)
                      },
                      expression: "formTool.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", "label-width": "120px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "生产厂家" },
                    model: {
                      value: _vm.formTool.factory,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "factory", $$v)
                      },
                      expression: "formTool.factory",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", "label-width": "120px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "批准文号" },
                    model: {
                      value: _vm.formTool.branch,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "branch", $$v)
                      },
                      expression: "formTool.branch",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      on: { input: _vm.monthYearChange },
                      model: {
                        value: _vm.tabPosition,
                        callback: function ($$v) {
                          _vm.tabPosition = $$v
                        },
                        expression: "tabPosition",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "week" } }, [
                        _vm._v("本周"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "month" } }, [
                        _vm._v("本月"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "year" } }, [
                        _vm._v("本年"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          type: "daterange",
                          size: "small",
                          "picker-options": _vm.pickerOptions,
                          "range-separator": "-",
                          clearable: false,
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                        },
                        model: {
                          value: _vm.formTool.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "date", $$v)
                          },
                          expression: "formTool.date",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "btn-box" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                        },
                        [_vm._v("查 询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.handleReset },
                        },
                        [_vm._v("重 置")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: { size: "small" },
                          on: { click: _vm.handleExport },
                        },
                        [_vm._v("导出")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "center",
              style: _vm.contentHeight
                ? `height:${_vm.contentHeight}px!important`
                : "",
            },
            [
              _c("MyTable", {
                attrs: {
                  "table-data": _vm.tableData,
                  index: _vm.index,
                  selection: _vm.selection,
                  page: _vm.page,
                  operation: _vm.operation,
                  "table-option": _vm.tableOption,
                },
                on: {
                  "update:tableOption": function ($event) {
                    _vm.tableOption = $event
                  },
                  "update:table-option": function ($event) {
                    _vm.tableOption = $event
                  },
                  "page-change": _vm.handlePageChange,
                  "sort-change": _vm.sortChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "goods_info",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _c("div", [
                            _vm._v("商品名称：" + _vm._s(scope.row.goods_name)),
                          ]),
                          _c("div", [
                            _vm._v("厂家：" + _vm._s(scope.row.goods_factory)),
                          ]),
                          _c("div", [
                            _vm._v("ERP编码：" + _vm._s(scope.row.erp_id)),
                          ]),
                          _c("div", [
                            _vm._v(
                              "批准文号：" + _vm._s(scope.row.approval_number)
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              "规格：" + _vm._s(scope.row.goods_specification)
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "status",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            staticClass: "btn",
                            staticStyle: { "margin-left": "16px" },
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看详情")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("GoodsDetailDlg", { ref: "goodsDetail" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }