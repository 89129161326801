var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-type-root grid" },
    _vm._l(
      [
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1,
      ],
      function (item, index) {
        return _c(
          "div",
          {
            key: index,
            class: { item: true, focus: _vm.selectedIndex === index },
            on: {
              click: function ($event) {
                return _vm.handleSelect(index)
              },
            },
          },
          [
            _c("el-image", { staticClass: "bg", attrs: { src: _vm.bgIcon } }),
            _c("el-image", { staticClass: "icon", attrs: { src: _vm.icon } }),
            _vm._m(0, true),
          ],
          1
        )
      }
    ),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right-content" }, [
      _c("div", { staticClass: "title" }, [_vm._v("一口价")]),
      _c("div", { staticClass: "desp" }, [
        _vm._v("不可以使用商家券不可以使用商家券"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }