<!-- 客户报表详情下级 -->
<template>
  <div class="comContent body-box">
    <div class="body-title">
      <div class="body-title-left">{{name || ''}}</div>
      <div class="body-title-right"><el-button size="small" @click="goBack">返回</el-button></div>
    </div>
    <div class="body-tools">
      <div class="body-tools-left">
        <el-form ref="formTool" :model="formTool" :inline="true">
          <el-form-item label="" prop="">
            <el-input v-model="formTool.orderCode" size="small" placeholder="请输入订单编号" clearable />
          </el-form-item>
          <el-form-item label="" prop="">
            <el-date-picker v-model="formTool.orderTime" size="small" type="daterange" value-format="yyyy-MM-dd" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="" prop="phone">
            <MyButton type="primary" text="查询" @click="goSearch" />
            <el-button size="small" @click="formToolReset()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="body-tools-right">
        <!-- <MyButton type="primary" text="返回" @click="goBack" /> -->
      </div>
    </div>
    <div>
      <MyTabs class="search" :tabi="tabsVal" keyname="val" :tabs="tabsData" @change="tabChange" />
    </div>
    <div class="body-table">
      <MyTable :table-data="tableData"
        max-height="calc(100% - 44px)"
        :index="false"
        :selection="false"
        :page="page"
        :operation="false"
        :table-option.sync="tableOption"
        @page-change="getList"
        @handleSelectionChange="currentChange">
        <template slot="tools" slot-scope="scope">
          <div style="display:flex">
            <el-link type="primary" :underline="false" size="small" @click="goLink(scope.row)">订单明细</el-link>
          </div>
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>

import MyButton from '@/components/myButton';
import MyTable from '@/components/myTable';
import MyTabs from '@/components/myTabs';
import { queryGoodsSalesEcharts } from "@/api/statistics";
export default {
  name:'ReportCustomerDetailMore',
  components: { MyButton, MyTable, MyTabs },
  data() {
    return {
      id:'',
      name:'',
      tabsData: [
        { name: '销售订单', num: 0, val: 'sold', numhide: true },
        { name: '售后订单', num: 0, val: 'afterSale', numhide: true },
      ],
      tabsVal: 'sold',
      formTool: {
        orderCode:'',
        orderTime:[],
      },
      tableData: [],
      tableOption: [
        { label: '订单创建时间', prop: 'order_time', width: '160px' },
        { label: '订单编号', prop: 'related_order_code', minWidth: '140px' },
        { label: '订单状态', prop: 'status_name', minWidth: '140px' },
        { label: '订单金额(元)', prop: 'amount', minWidth: '90px' },
        { label: '操作', prop: 'tools', slot: true, width: '80px' }
      ],
      page: { total: 10, current: 1, size: 10 },
    };
  },
  computed: {},
  watch: {},
  created() {
    let query = this.$route.query;
    if(query.id && query.name){
      this.id = query.id;
      this.name = query.name;
      this.initData();
    }else{
      this.goBack();
    }
    this.tabsVal = 'sold';
  },
  mounted() {},
  methods: {
    tabChange (val) {
      // console.log(val)
      this.tabsVal = val;
      this.initData();
      // this.$store.dispatch('user/setMTabName', val)
    },
    goLink(el){
      // let url = type==1?'/reportCustomer/reportCustomer-reportDetail':'/reportCustomer/reportCustomer-reportDetailMore'
      // let url = `/business/businessManage-order-detail?id=${el.order_id}`; // related_order_code,order_id
      let url = `/ordercenter/ordercenter-detail?id=${el.order_id}`; // related_order_code,order_id
      if(this.tabsVal == 'afterSale'){
        url = `/ordercenter/ordercenter-afterSalesDocumentDetail?afterSaleId=${el.order_id}`;
      }
      // http://localhost:8081/#/ordercenter/ordercenter-detail?id=1746701878178213889
      this.$router.push(url);
    },
    goSearch(){
      this.initData();
    },
    formToolReset () {
      this.formTool = {
        orderCode:'',
        orderTime:[]
      }
      // this.$refs.formTool.resetFields()
      this.initData();
    },
    currentChange(){},
    initData(){
      this.page.current = 1;
      this.getList();
    },
    getList(){
      let obj = this.getParams('list');
      let totalObj = this.getParams('total');
      queryGoodsSalesEcharts(obj).then(list=>{
        this.tableData = list || [];
      })
      queryGoodsSalesEcharts(totalObj).then(list=>{
        // 获取总条数
        let total = 0;
        if(list && list.length>0){
          total = parseInt(list[0].cnt);
        }
        this.page.total = total;
      })
    },
    getParams(status = 'list'){
      const { current, size } = this.page;
      const { orderCode, orderTime } = this.formTool;
      let firstSTime = '';
      let firstETime = '';
      if(orderTime && orderTime.length>0){
        firstSTime = orderTime[0].replaceAll('-', '');
        firstETime = orderTime[1].replaceAll('-', '');
      }
      let types = {
        sold: { list: 84, total: 85 },
        afterSale: { list: 86, total: 87 },
      }
      let type = types[this.tabsVal][status];
      let params = [
        this.id,// 客户ID
        orderCode,orderCode, // 订单编号
        firstSTime,firstSTime, // 开始时间
        firstETime,firstETime, // 结束时间
      ]
      let paramsMore = [
        size, // 每页行数
        (current - 1)*size, // 第几行开始
      ]
      return { type, params: (status=='list') ? [ ...params, ...paramsMore ] : params}
    },
    goBack () {
      // console.log('go back');
      // this.$router.push("/reportCustomer/reportCustomer-reportList");

      // this.$router.go(-1)：后退+刷新；
      // this.$router.go(0)：刷新；
      // this.$router.go(1) ：前进
      this.$router.back(); //:后退
      // this.$router.back(0) 刷新;
      // this.$router.back(1)：前进
    },
  },
  // beforeCreate() { },
  // beforeMount() { },
  // beforeUpdate() { },
  // updated() { },
  // beforeDestroy() { },
  // destroyed() { },
  // activated() { },
}
</script>
<style lang='scss' scoped>
.body{
  &-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid #eee;
    &-left{
      width: calc(100% - 90px);
      line-height: 1;
      font-size: 26px;
      font-weight: 600;
      color: #000;
    }
    &-right{
      width: 90px;
      text-align: right;
    }
  }
  &-tools{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 16px;
    &-left{
      width: calc(100% - 90px);
    }
    &-right{
      width: 90px;
      text-align: right;
    }
    ::v-deep .el-form{
      &-item{
        margin-bottom: 6px !important;
      }
    }
  }
  &-table{
    height: calc(100% - 190px);
  }
}
</style>
