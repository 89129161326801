<template>
  <div class="comContent shopingcart">
    <!-- <div class="header">产品线基本信息</div> -->
    <div class="center">
      <!-- <div class="left_topic mb20" style="margin-top: 10px">控销申请信息</div> -->
      <!-- <el-divider /> -->
      <el-form ref="productLine"
               :model="formData"
               label-width="100px"
               class="ruleFormCont"
               label-position="right"
               :rules="rules">
        <!-- <el-form-item label="产品线名称" prop="name">
          <el-input
            v-model="formData.productLineName"
            placeholder="请输入20字以内"
            style="width: 325px"
            :disabled="isDisabled"
          />
        </el-form-item>

        <el-row>
          <el-col>
            <el-form-item label="商品信息" prop="name">
              <div class="shop-info">
                <section>
                  <el-image
                    style="width: 100px; height: 100px; margin: 10px"
                    :src="formData.goodsImage || avater"
                  />
                </section>
                <section class="info-list">
                  <div>{{ formData.goodsName }}</div>
                  <div>{{ formData.goodsFactory }}</div>
                  <div>{{ formData.specification }}</div>
                </section>
              </div>
            </el-form-item>
          </el-col>
        </el-row> -->

        <div class="left_topic mb20"
             style="margin: 12px 0;margin-bottom: 24px;">控销申请信息</div>
        <div class="produceDetail">
          <el-row>
            <el-col>
              <el-form-item label="团队类型"
                            prop="name">
                <el-input v-model="formData.applyType"
                          size="small"
                          style="width: 325px"
                          :disabled="isDisabled" />
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="负责人姓名"
                            prop="name">
                <el-input v-model="formData.managerName"
                          size="small"
                          style="width: 325px"
                          :disabled="isDisabled" />
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="团队人数"
                            prop="teamSize">
                <el-input v-model="formData.teamSize"
                          size="small"
                          style="width: 325px"
                          :disabled="isDisabled" />
              </el-form-item>

              <el-form-item label="省市地"
                            prop="name">
                <el-input v-model="formData.address"
                          size="small"
                          style="width: 325px"
                          :disabled="isDisabled" />
              </el-form-item>
              <el-form-item label="联系方式"
                            prop="name">
                <el-input v-model="formData.contactPhone"
                          size="small"
                          style="width: 325px"
                          :disabled="isDisabled" />
              </el-form-item>
              <el-form-item label="优势描述"
                            prop="name">
                <el-input v-model="formData.description"
                          size="small"
                          style="width: 325px"
                          :disabled="isDisabled"
                          type="textarea"
                          :rows="2" />
              </el-form-item>
              <el-form-item label="控销品名称"
                            prop="name">
                <el-input v-model="formData.goodsName"
                          size="small"
                          style="width: 325px"
                          :disabled="isDisabled" />
              </el-form-item>
              <el-form-item label="控销商品图"
                            prop="name">
                <el-image v-for="item in this.formData.goodsImage"
                          :key="item"
                          style="width: 100px; height: 100px; margin: 10px"
                          :src="item || avater" />
              </el-form-item>
              <el-form-item label="控销品卖点"
                            v-if="formData.applyType == '控销团队'"
                            prop="name">
                <el-input v-model="formData.goodsDescription"
                          style="width: 325px"
                          size="small"
                          :disabled="isDisabled"
                          type="textarea"
                          :rows="2" />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div class="mt20"
           style="margin-bottom: 24px;padding-left: 100px;">
        <el-button type=""
                   size="small"
                   @click="cancel">取消</el-button>
      </div>
    </div>
    <UnitDialog :unit-list="unitList"
                :dialog-visible="dialogVisible"
                @submitCheck="submitCheck" />
  </div>
</template>
<script>
import UnitDialog from "@/components/unitDialog";
import { getTeamDetail } from "@/api/saleRule/productLine";

import avater from "/public/img/default.png";
export default {
  components: { UnitDialog },
  data () {
    return {
      tableIndex: 0, // 点击单位表格中的index
      // checkIndex: 0, // 选中的index
      dialogVisible: false, // 弹窗
      unitList: ["粒", "盒"],
      avater: avater,
      isDisabled: true,
      formData: {},
      detailBuyer: {},
      salesControlType: [
        {
          name: "体制调理类商品区",
          id: 1,
        },
        {
          name: "商控专区",
          id: 2,
        },
        {
          name: "心脑血管类商品区",
          id: 3,
        },
        {
          name: "脾胃类商品区",
          id: 4,
        },
      ],
      rules: {
        typeCode: [
          { required: true, message: `请选择类型`, trigger: `change` },
        ],
        licenceNo: [
          {
            required: true,
            message: `请输入医疗机构执业许可证`,
            trigger: `blur`,
          },
        ],
      },
    };
  },
  created () {
    this.shopDetail = this.$route.query;
    this.getBuyerDetail();
  },
  methods: {
    submitCheck () { },
    // 返回
    cancel () {
      this.$router.push("/salesControlMange/salesControl-team-index");
    },
    // 获取详情
    getBuyerDetail () {
      let params = {
        id: this.$route.query.id,
      };
      getTeamDetail(params).then((res) => {
        if (res) {
          this.formData = res;
          this.formData.goodsImage = this.formData.goodsImage ? this.formData.goodsImage.split(",") : []
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.addGoods {
  width: 100%;
  height: 40px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  .addFont {
    // border: 1px dashed #d9d9d9;
    display: flex;
    width: 18px;
    font-size: 16px;
    justify-content: center;
    margin-right: 5px;
  }
}
.header {
  padding: 0 24px;
  height: 56px;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 700;
  text-align: left;
  color: #262626;
  line-height: 56px;
  border: 1px solid #f5f5f5;
}
.center {
  margin: 0 24px;
}
.shopingcart {
  padding: 0px;
  .produceDetail {
    display: flex;
    .introduce {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .name {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #262626;
        margin-bottom: 10px;
      }
      .factory {
        color: #595959;
        font-size: 14px;
      }
    }
  }
  ::v-deep .el-table th.el-table__cell > .cell {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #8c8c8c;
  }
  ::v-deep .el-table .cell {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.shop-info {
  // width: 500px;
  display: flex;
  justify-content: flex-start;
}
</style>
