<template>
  <collection :accountType="2"></collection>
</template>
<script>
import collection from "./collection";
export default {
  components: { collection },
  data() {
    return {};
  },
  filters: {},
  created() {},
  mounted() { 
  
  },
  methods: {},
};
</script>
<style lang="scss">
</style>