<template>
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo"
          :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <div v-if="sidebar.opened">
        <div style="font-size:16px;height: 12px;padding: 20px;font-family: PingFang SC, PingFang SC-Regular;font-weight: 400;text-align: left;color: #8c8c8c;line-height: 12px;">
          <img src="@/assets/caidan.png"
               width="16px"
               alt="">
          常用操作
        </div>
        <div class="ownPath">
          <div v-for="item,index in path"
               :key="index"
               class="smallBox"
               style=""
               @click="clickPath(item.menuUrl)">
            {{ item.menuName }}
          </div>
        </div>
      </div>
      <el-menu :default-active="activeMenu"
               :collapse="isCollapse"
               :background-color="variables.menuBg"
               :text-color="variables.menuText"
               :unique-opened="false"
               :active-text-color="variables.menuActiveText"
               :collapse-transition="false"
               @select="handleSelect"
               mode="vertical">

        <sidebar-item v-for="route in permission_routes"
                      :key="route.id"
                      :item="route"
                      :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import lodash from "lodash";
import Cookies from 'js-cookie'
import { memberMenuClickFrequency, updateClickFrequency } from '@/api/system'

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters([
      'permission_routes',
      'sidebar'
    ]),
    activeMenu () {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo () {
      return this.$store.state.settings.sidebarLogo
    },
    variables () {
      return variables
    },
    isCollapse () {
      return !this.sidebar.opened
    }
  },
  data () {
    return {
      userId: '',
      path: []
    }
  },
  created () {
    this.userId = this.$store.state.user.user_info.userId
    memberMenuClickFrequency(this.userId).then(res => {
      if (res.code == 0) {
        this.path = res.data
      }
    })
    // this.path = JSON.parse(Cookies.get('path')) ? JSON.parse(Cookies.get('path')) : []
  },
  methods: {
    handleSelect (key, keyPath) {
      let a = keyPath[1].split('/')
      let obj = {}
      this.permission_routes.map(item => {
        if (item.children) {
          item.children.forEach(el => {
            if (el.path == a[2]) {
              obj = {
                title: el.meta.title,
                path: keyPath[1]
              }
            }
          })
        }
        return obj
      })
      let params = {
        userId: this.userId,
        userName: this.$store.state.user.user_info.username,
        menuName: obj.title,
        menuUrl: obj.path
      }
      if(!params.userId || !params.userName || !params.menuName || !params.menuUrl) return
      updateClickFrequency(params).then(res => {
        if (res.code == 0) {
          console.log(res)
        }
      })
      // 下面的代码是前端写死
      // let havePath = lodash.findIndex(this.path, (e) => {
      //   return keyPath[1] == e.path
      // })
      // if (havePath < 0) {
      //   if (this.path.length > 3) {
      //     this.path.splice(this.path.length - 1, 1)
      //   }
      //   this.path.unshift(obj)
      // }
      // Cookies.set('path', JSON.stringify(this.path))
    },
    clickPath (path) {
      this.$router.push(path)
    }
  }
}
</script>

 <style lang="scss" scoped>
::v-deep .el-submenu {
  margin: 8px 0 !important;
}
.ownPath {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px 20px;
  .smallBox {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80px;
    height: 22px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #262626;
    line-height: 22px;
    padding: 3px 10px;
    padding-left: 0px;
    &:hover {
      cursor: pointer;
      color: red;
      color: red;
    }
  }
}
</style>