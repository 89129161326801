<template>
  <div class="classification">
    <div class="header">
      <el-form ref="formRef" :model="formTool" :inline="true" @submit.native.prevent style="text-align: left">
        <el-form-item prop="orderCode" label="">
          <el-input style="width: 160px" v-model="formTool.orderCode" placeholder="订单编号" clearable size="small" />
        </el-form-item>
        <el-form-item prop="payShopName" label="">
          <el-input style="width: 160px" v-model="formTool.payShopName" placeholder="客户名称" clearable size="small" />
        </el-form-item>
        <el-form-item prop="goodsName" label="">
          <el-input style="width: 160px" v-model="formTool.goodsName" placeholder="产品名称" clearable size="small" />
        </el-form-item>
        <el-form-item prop="time" label="创建时间">
          <el-date-picker v-model="formTool.time" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)">查询</el-button>
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button size="small" type="primary" plain @click="goExport">导出</el-button>
      </div>
    </div>
    <div class="center" style="position: relative">
      <MyTable :index="false" :table-data="tableData" :page="page" :table-option.sync="tableOption" @page-change="tablePageChange">
        <!-- <template slot="tools" slot-scope="scope">
          <div class="table-op-col">
            <el-link type="primary" :underline="false" size="small" @click="openOpDetail(2, scope.row)">编辑</el-link>
          </div>
        </template> -->
      </MyTable>
    </div>
  </div>
</template>

<script>
import MyTable from "@/components/myTable/index.vue";
import { csoSaleReportPage, csoSaleReportExport } from "@/api/complianceCso/salesreport";
import { sum, round } from "lodash";

export default {
  components: {
    MyTable,
  },
  data() {
    return {
      formTool: { goodsName: "", payShopName: '', orderCode: '', time: '' },
      tableData: [],
      tableOption: [
        { label: "订单创建时间", prop: "createTime" },
        { label: "订单编号", prop: "orderCode" },
        { label: "客户名称", prop: "payShopName" },
        { label: "产品名称", prop: "goodsName" },
        { label: "规格", prop: "goodsSpecification" },
        { label: "数量", prop: "qty" },
        { label: "交易金额(元)", prop: "tradeAmount" },
        { label: "售后金额(元)", prop: "refundAmount" },
        { label: "结算金额(元)", prop: "settlementAmount" },
        { label: "结算状态", prop: "settlementStatusName" },
        // { label: "操作", prop: "tools", slot: true, width: "80px" },
      ],
      page: { total: 0, current: 1, size: 10 },
      pickerOptions: {
        disabledDate(time) {
          const maxToday = new Date()
          maxToday.setHours(23, 59, 59, 999)
          return time.getTime() > maxToday.getTime()
        }
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(num) {
      if (num) {
        this.page.current = num;
      }
      const { current, size } = this.page;
      let params = {
        pageNum: current,
        pageSize: size,
        ...this.getparamsData(),
      };
      csoSaleReportPage(params)
        .then((res) => {
          if(res.code == 0){
            const { list, total, pageNum } = res.data;
            let totalItem = {
              createTime:'小计',
              orderCode:'',
              payShopName:'',
              goodsName:'',
              goodsSpecification:'',
              qty:0,
              tradeAmount:0,
              refundAmount:0,
              settlementAmount:0,
              settlementStatusName:'',
            }
            let newList = (list || [])
            // 计算小计并四舍五入
            totalItem.qty = round(sum(newList.map(li=>li.qty)),2);
            totalItem.tradeAmount = round(sum(newList.map(li=>li.tradeAmount)),2);
            totalItem.refundAmount = round(sum(newList.map(li=>li.refundAmount)),2);
            totalItem.settlementAmount = round(sum(newList.map(li=>li.settlementAmount)),2);
            newList.push(totalItem);
            this.tableData = newList;
            this.page.total = total;
            this.page.current = pageNum;
          }
        })
    },
    getparamsData(){
      const { goodsName, payShopName, orderCode, time } = this.formTool;
      return {
        goodsName, payShopName, orderCode,
        startTime: (time && time.length) > 0 ? time[0] : '',
        endTime: (time && time.length) > 0 ? time[1] : '',
      };
    },
    handleRefresh() {
      this.getList(1);
    },
    tablePageChange() {
      this.getList();
    },
    handleReset() {
      for (let key in this.formTool) {
        this.formTool[key] = "";
      }
      this.getList(1);
    },
    goExport() {
      csoSaleReportExport(this.getparamsData()).then(res => {
        console.log('导出信息成功', res);
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.classification {
  padding: 0px;
  background: white;

  .header {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);
  }

  .table-op-col {
    display: flex;
    align-items: center;

    .el-link {
      &+.el-link {
        margin-left: 8px;
      }
    }
  }
}
</style>