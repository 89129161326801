var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-root" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            title: _vm.title,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "918px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.onClosed,
          },
        },
        [
          _vm.mainInfo
            ? _c(
                "div",
                { staticClass: "content form-content form-content-new" },
                [
                  _c("div", { staticClass: "row mutlti big" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("合同名称")]),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            maxlength: "20",
                            disabled: "",
                          },
                          model: {
                            value: _vm.mainInfo.contractName,
                            callback: function ($$v) {
                              _vm.$set(_vm.mainInfo, "contractName", $$v)
                            },
                            expression: "mainInfo.contractName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row mutlti big" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("合同编码")]),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            maxlength: "20",
                            disabled: "",
                          },
                          model: {
                            value: _vm.mainInfo.contractCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.mainInfo, "contractCode", $$v)
                            },
                            expression: "mainInfo.contractCode",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row mutlti big" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("合作公司")]),
                    _c(
                      "div",
                      { staticClass: "value input" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            maxlength: "20",
                            disabled: "",
                          },
                          model: {
                            value: _vm.mainInfo.coopShopName,
                            callback: function ($$v) {
                              _vm.$set(_vm.mainInfo, "coopShopName", $$v)
                            },
                            expression: "mainInfo.coopShopName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "content form-content" }, [
            _c(
              "div",
              {
                staticClass: "row mutlti big",
                staticStyle: { "margin-top": "12px" },
              },
              [
                _c("div", { staticClass: "tip" }, [_vm._v("关联产品")]),
                _c(
                  "div",
                  { staticClass: "value" },
                  _vm._l(_vm.goodsList, function (li, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "goods-info" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src: li.image || _vm.avater,
                            lazy: "",
                            "preview-src-list": [li.image || _vm.avater],
                          },
                        }),
                        _c("div", { staticClass: "txt-info" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(li.goodsName)),
                          ]),
                          _c("div", { staticClass: "factory" }, [
                            _vm._v(_vm._s(li.factory)),
                          ]),
                          _c("div", { staticClass: "factory" }, [
                            _vm._v(_vm._s(li.specification)),
                          ]),
                          _c("div", { staticClass: "factory" }, [
                            _vm._v(_vm._s(li.approvalNumber)),
                          ]),
                          _c("div", { staticClass: "factory" }, [
                            _vm._v("商品编码：" + _vm._s(li.goodsCode)),
                          ]),
                        ]),
                        _vm.action == 1 || _vm.action == 2
                          ? _c(
                              "div",
                              {
                                staticClass: "goods-info-del",
                                on: {
                                  click: function ($event) {
                                    return _vm.goodsDel(i)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
                !_vm.isLook
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.handleSelectGoods },
                      },
                      [_vm._v("选择产品")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("合同范围")]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-tree", {
                    key: _vm.treeKey,
                    ref: "treeRef",
                    attrs: {
                      data: _vm.treeData,
                      "show-checkbox": "",
                      "node-key": "id",
                      props: _vm.defaultProps,
                      "default-checked-keys": _vm.defaultCheckedData,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
              !_vm.isLook
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("保存并提交")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c("selectGoods", {
        ref: "refSelectGoods",
        on: { choose: _vm.didSelectItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }