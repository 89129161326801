<template>
  <div class="comContent shopingcart">
    <div class="header">详情</div>
    <div class="center">
      <div class="left_topic mb20"
           style="margin-top:10px">基本信息</div>
      <el-divider />
      <div class="produceDetail">
        <div class="shopImg">
          <el-image style="width: 100px; height: 100px"
                    :src="shopDetail.image || avater" />
        </div>
        <div class="introduce">
          <div class="list name">{{ shopDetail.name }}</div>
          <div class="list factory mb20">{{ shopDetail.factory }}</div>
          <div class="list factory">{{ shopDetail.approvalNumber }}</div>
        </div>
      </div>
      <el-divider />
      <div class="left_topic mb20">商品价格</div>
      <el-form ref="shopingcart"
               :model="formTool"
               label-position="top"
               size="small">
        <el-table :data="formTool.SKUlist"
                  style="width: 100%">
          <el-table-column prop="goodsSkuName"
                           label="SKU名称"
                           min-width="160">
            <template slot-scope="scope">
              <el-form-item :prop="'SKUlist.'+scope.$index+'.goodsSkuName'"
                            label-width="0">
                <el-input v-model="scope.row.goodsSkuName"
                          placeholder="请填写SKU名称" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="goodsSkuUnit"
                           label="单位"
                           min-width="160">
            <template slot-scope="scope">
              <el-form-item :prop="'SKUlist.'+scope.$index+'.goodsSkuUnit'"
                            label-width="0"
                            style="display:flex;">
                <el-input v-model="scope.row.goodsSkuUnit"
                          style="width:50%"
                          maxlength="4"
                          placeholder="单位"
                          @input="changMoney(scope.$index,scope.row)" />
                <el-link style="margin-left:16px"
                         type="primary"
                         :underline="false"
                         @click="choiceUnit(scope.$index)">选择单位</el-link>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="medicineCode"
                           label="第三方商品编码"
                           min-width="160">
            <template slot-scope="scope">
              <el-form-item :prop="'SKUlist.'+scope.$index+'.medicineCode'"
                            label-width="0">
                <el-input v-model="scope.row.medicineCode"
                          placeholder="请填写第三方商品编码" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="salePrice"
                           label="零售价/元"
                           min-width="120">
            <template slot-scope="scope">
              <el-form-item :prop="'SKUlist.'+scope.$index+'.salePrice'"
                            label-width="0">
                <el-input v-model="scope.row.salePrice"
                          disabled
                          placeholder="请填写零售价" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="marketPrice"
                           label="市场价/元"
                           min-width="120">
            <template slot-scope="scope">
              <el-form-item :prop="'SKUlist.'+scope.$index+'.marketPrice'"
                            label-width="0">
                <el-input v-model="scope.row.marketPrice"
                          placeholder="请填写市场价" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="purchasePrice"
                           label="批发价/元"
                           min-width="120">
            <template slot-scope="scope">
              <el-form-item :prop="'SKUlist.'+scope.$index+'.purchasePrice'"
                            label-width="0">
                <el-input v-model="scope.row.purchasePrice"
                          placeholder="请填写批发价" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="startWholesale"
                           label="起批量"
                           min-width="180">
            <template slot-scope="scope">
              <el-form-item :prop="'SKUlist.'+scope.$index+'.startWholesale'"
                            label-width="0">
                <div style="display:flex">
                  <el-input v-model="scope.row.startWholesale"
                            placeholder="请填写起批量" />
                  <el-checkbox v-model="scope.row.useTimes"
                               style="margin-left:10px">倍数</el-checkbox>
                </div>

              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="goodsSkuInventory"
                           label="库存"
                           min-width="120">
            <template slot-scope="scope">
              <el-form-item :prop="'SKUlist.'+scope.$index+'.goodsSkuInventory'"
                            label-width="0">
                <el-input v-model="scope.row.goodsSkuInventory"
                          placeholder="请填写库存" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="expireTime"
                           label="有效期至"
                           width="210">
            <template slot-scope="scope">
              <el-form-item :prop="'SKUlist.'+scope.$index+'.expireTime'"
                            label-width="0">
                <el-date-picker v-model="scope.row.expireTime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetime"
                                placeholder="选择日期"
                                style="width:200px" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="producedTime"
                           label="生产日期"
                           width="150">
            <template slot-scope="scope">
              <el-form-item :prop="'SKUlist.'+scope.$index+'.producedTime'"
                            label-width="0">
                <el-date-picker v-model="scope.row.producedTime"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                style="width:140px" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column fixed="right"
                           label="操作"
                           width="120">
            <template slot-scope="scope">
              <el-link :style="scope.row.status == 'PUTAWAY'? 'margin-left:16px;color:#595959' : 'margin-left:16px;'"
                       :disabled="scope.row.status == 'PUTAWAY'"
                       type="primary"
                       :underline="false"
                       @click="deleteRow(scope.$index, scope.row.id,1)">上架</el-link>
              <el-link :style="scope.row.status == 'SOLDOUT'? 'margin-left:16px;color:#595959' : 'margin-left:16px;'"
                       :disabled="scope.row.status == 'SOLDOUT'"
                       type="primary"
                       :underline="false"
                       @click="deleteRow(scope.$index, scope.row.id,2)">下架</el-link>
              <!-- <el-button :disabled="scope.row.status == 'PUTAWAY'"
                         style="margin-bottom:20px;color:#F5222D;font-size:14px"
                         type="text"
                         size="mini"
                         @click.native.prevent="deleteRow(scope.$index, scope.row.id,1)">
                上架
              </el-button>
              <el-button :disabled="scope.row.status == 'SOLDOUT'"
                         style="margin-bottom:20px;color:#F5222D;font-size:14px"
                         type="text"
                         size="mini"
                         @click.native.prevent="deleteRow(scope.$index, scope.row.id,2)">
                下架
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="mt20"
           style="margin-bottom:24px">
        <el-button type="primary"
                   size="small"
                   @click="comfirm">保存</el-button>
        <el-button type=""
                   size="small"
                   @click="cancel">返回</el-button>
      </div>
    </div>
    <UnitDialog :unit-list="unitList"
                :dialog-visible="dialogVisible"
                @submitCheck="submitCheck" />
  </div>
</template>
<script>
import UnitDialog from '@/components/unitDialog'
import { addSku, skuLists, skuStatus } from '@/api/productManage/publishProduct'
import avater from "/public/img/default.png"
export default {
  components: { UnitDialog },
  data () {
    return {
      tableIndex: 0, // 点击单位表格中的index
      // checkIndex: 0, // 选中的index
      dialogVisible: false, // 弹窗
      unitList: ['粒', '盒'],
      avater: avater,
      srcList: ['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'],
      edit: true, // 编辑零售价，批发价，起批量
      shopDetail: {},
      formTool: {
        SKUlist: [
          {
            goodsSkuName: '', // SKU名称
            goodsSkuUnit: '', // 单位
            useTimes: false,
            salePrice: '', // 零售价
            marketPrice: '', // 市场价
            purchasePrice: '', // 批发价
            startWholesale: '', // 起批量
            goodsSkuInventory: '', // 库存
            expireTime: '', // 有效期至
            producedTime: '', // 生产日期
            medicineCode: '' // 第三方商品编码
          }
        ]
      }
    }
  },
  created () {
    this.skuLists()
    this.shopDetail = this.$route.query
  },
  methods: {
    // 选择单位
    choiceUnit (index) {
      this.tableIndex = index
      this.dialogVisible = true
    },
    // 确认选中的单位
    submitCheck (item) {
      this.formTool.SKUlist[this.tableIndex].goodsSkuUnit = item
    },
    // 返回
    cancel () {
      this.$router.push('/productManage/sale-index')
    },
    // 删除行
    deleteRow (index, rows, status) {
      console.log(rows)
      skuStatus(rows, status).then(res => {
        console.log(res)
        if (status == 1) {
          this.skuLists()
          this.$message.success('上架成功')
        } else {
          this.skuLists()
          this.$message.success('下架成功')
        }

      })
      // rows.splice(index, 1)
    },
    // 获取sku自营商品定价
    skuLists () {
      let params = {
        goodsId: this.$route.query.goodsId
      }
      skuLists(params).then(res => {
        if (res.code == 0) {
          if (res.data.length != 0) {
            let arr = []
            res.data.forEach(item => {
              if (item.salePrice) {
                arr.push(item)
              }
            })
            this.formTool.SKUlist = arr
          }
        }
      })
    },
    // 提交
    comfirm () {
      const _that = this
      this.$refs['shopingcart'].validate((valid) => {
        const params = Object.assign({}, this.formTool)
        params.SKUlist.forEach(item => {
          item.goodsId = _that.shopDetail.goodsId
        })
        console.log(params.SKUlist)
        if (valid) {
          addSku(params.SKUlist).then(res => {
            if (res.code === 0) {
              this.$message.success('提交成功')
              this.$router.push('/productManage/sale-index')
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  }
}

</script>
<style lang="scss" scoped>
.addGoods {
  width: 100%;
  height: 40px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  .addFont {
    // border: 1px dashed #d9d9d9;
    display: flex;
    width: 18px;
    font-size: 16px;
    justify-content: center;
    margin-right: 5px;
  }
}
.header {
  padding: 0 24px;
  height: 56px;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 700;
  text-align: left;
  color: #262626;
  line-height: 56px;
  border: 1px solid #f5f5f5;
}
.center {
  margin: 0 24px;
}
.shopingcart {
  padding: 0px;
  .produceDetail {
    display: flex;
    .introduce {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .name {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #262626;
        margin-bottom: 10px;
      }
      .factory {
        color: #595959;
        font-size: 14px;
      }
    }
  }
  ::v-deep .el-table th.el-table__cell > .cell {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #8c8c8c;
  }
  ::v-deep .el-table .cell {
    padding-left: 5px;
    padding-right: 5px;
    height: 32px;
    line-height: 32px;
  }
}
</style>
