<template>
  <div class="comContent">
    <div class="header">
      <div class="title">
        订单/资金统计明细报表<span>（统计到昨日的订单）</span>
      </div>
      <div class="info-list">
        <span class="list">
          总订单数:<span> {{ stProvinceAmtPo.orderNum || 0 }} </span>
        </span>
        <span class="list">
          总销售额:<span
            >￥{{ stProvinceAmtPo.orderAmt | pirceFormatter }}
          </span>
        </span>
        <span class="list">
          省区公司应收:<span
            >￥ {{ stProvinceAmtPo.provinceReceivableAmt | pirceFormatter }}
          </span>
        </span>
        <span class="list">
          省区公司实收:<span
            >￥ {{ stProvinceAmtPo.provinceArrivalAmt | pirceFormatter }}
          </span>
        </span>
        <span class="list">
          省区公司未收:<span
            >￥ {{ stProvinceAmtPo.provinceNotyetAmt | pirceFormatter }}
          </span>
        </span>
      </div>
    </div>

    <div class="reportContent" v-if="isReport">
      <div id="main" style="width: 100%; height: 300px"></div>
    </div>
    <div class="search-from">
      <el-form
        ref="searchForm"
        :model="searchForm"
        :inline="true"
        style="text-align: left"
      >
        <div>
          <el-form-item>
            <el-button
              size="small"
              @click="setDate(3)"
              :class="dateKey == 3 ? 'active-button' : ''"
              >三个月</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              @click="setDate(2)"
              :class="dateKey == 2 ? 'active-button' : ''"
              >近一月</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              @click="setDate(1)"
              :class="dateKey == 1 ? 'active-button' : ''"
              >近一周</el-button
            >
          </el-form-item>
          <el-form-item label-width="140px">
            <el-date-picker
              style="width: 100%"
              v-model="searchForm.date"
              value-format="yyyy-MM-dd"
              type="daterange"
              size="small"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              class="date-picker"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </div>

        <div>
          <el-form-item>
            <el-input
              v-model="searchForm.clinicName"
              placeholder="请输入诊所名称"
              clearable
              maxlength="20"
              size="small"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="searchForm.relatedOrderCode"
              placeholder="请输入父订单号"
              clearable
              maxlength="20"
              size="small"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="searchForm.shopName"
              placeholder="请输入商户名称"
              clearable
              maxlength="20"
              size="small"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              size="mini"
              v-model="searchForm.province"
              placeholder="请选择省份"
              @change="selectedHandle"
            >
              <el-option
                v-for="item in provinceData"
                :label="item.rname"
                :value="item"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              size="mini"
              v-model="searchForm.city"
              placeholder="请选择地市区"
              @change="selectedHandleCity"
            >
              <el-option
                v-for="item in cityData"
                :label="item.rname"
                :value="item"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              size="mini"
              v-model="searchForm.district"
              placeholder="请选择区县"
              @change="selectedHandleArea"
            >
              <el-option
                v-for="item in areaData"
                :label="item.rname"
                :value="item"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button size="small" type="primary" @click="exportUrl(1)"
              >导出</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="getList(1)"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="reset('searchForm')"
              >重置</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="content">
      <reportTable
        :table-data="tableData"
        :index="index"
        :selection="selection"
        :page="page"
        :showpage="true"
        :operation="operation"
        :table-option.sync="tableOption"
        @page-change="getList"
        :expand="true"
      >
        <template slot="goodsInfoList" slot-scope="scope">
          <div
            class="goods-info-list"
            style="display: flex; align-items: center"
            v-for="(item, key) in scope.row.goodsInfoList"
            :key="key"
          >
            <div
              style="
                width: 80px;
                height: 80px;
                display: flex;
                align-items: center;
              "
            >
              <el-image
                :src="item.goodsImage || avater"
                lazy
                style="width: 80px"
              />
            </div>

            <div class="imageP" style="line-height: 30px">
              <p style="font-weight: 600; color: #262626">
                {{ item.goodsName }}
              </p>
              <p>
                {{ item.goodsSpec }}
              </p>
              <p>
                {{ item.goodsFactory }}
              </p>
            </div>
            <div class="unit-list">
              <div class="li">¥ {{ parseFloat(item.price).toFixed(2) }}</div>
              <div class="li">
                {{ item.qty }}
              </div>
              <div class="li">
                {{ item.unit }}
              </div>
            </div>
          </div>
        </template>
        <!-- orderTime -->
        <template slot="orderTime" slot-scope="scope">
          {{ scope.row.orderTime.slice(0, 10) }}
        </template>
        <template slot="orderStatus" slot-scope="scope">
          <div v-for="(item, key) in scope.row.detailList" :key="key">
            <span v-if="item.shopFlag == '医药公司'">
              {{ item.orderStatus }}
            </span>
          </div>
        </template>

        <template slot="purchaseAmount" slot-scope="scope">
          ¥ {{ parseFloat(scope.row.purchaseAmount).toFixed(2) }}
        </template>

        <!-- purchaseAmount -->
        <template slot="expand" slot-scope="scope">
          <myTable
            :table-data="scope.row.detailList"
            :index="index"
            :selection="selection"
            :operation="false"
            :table-option.sync="tableInfoOption"
            :expand="false"
          >
            <!-- arrivalAmount -->
            <template slot="arrivalAmount" slot-scope="scope">
              ¥ {{ parseFloat(scope.row.arrivalAmount).toFixed(2) }}
            </template>
          </myTable>
        </template>
      </reportTable>
    </div>
  </div>
</template>
<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import { LineChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  DataZoomComponent,
} from "echarts/components";
import "echarts/lib/component/dataZoom";

// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
import { getRecentMonth_Date, getRecentDay_Date } from "@/utils/moment.js";
// 注册必须的组件
import myTable from "@/components/myTable";
import reportTable from "./myTable";
import avater from "/public/img/default.png";
import { getProvince, getCity, getArea } from "@/api/shopmange/index";
import {
  purchaseOrderExcel,
  purchaseOrderExportExcel,
  findStDataByRange,
  exportOrderReportTableData,
} from "@/api/orderReport/index";
// import { resTestData } from "./test.js";
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

export default {
  components: { myTable, reportTable },
  data() {
    return {
      // setDisabled: {
      // disabledDate(time) {
      //   return time.getTime() > Date.now();  // 可选历史天、可选当前天、不可选未来天
      //   // return time.getTime() > Date.now() - 8.64e7;  // 可选历史天、不可选当前天、不可选未来天
      //   // return time.getTime() < Date.now() - 8.64e7;  // 不可选历史天、可选当前天、可选未来天
      //   return time.getTime() < Date.now(); // 不可选历史天、不可选当前天、可选未来天
      // },
      // },
      selectData: new Date(),
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectData = minDate.getTime();
        },
        disabledDate: (time) => {
          return (
            time.getTime() > new Date() ||
            time.getTime() < this.selectData - 365 * 24 * 3600 * 1000 //选择范围1年内日期（1年前和今天后禁选）
          );
        },
      },
      avater: avater,
      // 地区转账状态
      statusList: [],
      searchForm: {
        date: [],
      },
      areaData: [],
      cityData: [],
      provinceData: [],
      name: "123444",
      dateKey: 2,
      operation: false,
      selection: false,
      index: false,
      tableData: [], //模拟测试数据
      tableInfoData: [],
      stProvinceAmtPo: {},
      tableOption: [
        { label: "时间", prop: "orderTime", slot: true, width: 100 },
        { label: "商品信息", prop: "goodsInfoList", slot: true, width: 300 },
        { label: "单价", prop: "price", width: 100, slot: true },
        { label: "数量", prop: "qty", width: 100 },
        { label: "单位", prop: "unit", width: 100 },
        { label: "采购诊所", prop: "purchaseClinicName", width: 140 },
        { label: "采购金额", prop: "purchaseAmount", slot: true, width: 120 },
        { label: "订单状态", prop: "orderStatus", slot: true, width: 120 },
        { label: "省份", prop: "province", width: 120 },
        { label: "城市", prop: "city", width: 140 },
        { label: "区县", prop: "district", width: 140 },
        { label: "父订单号", prop: "relatedOrderCode", width: 180 },
      ],
      tableInfoOption: [
        { label: "商户类型", prop: "shopFlag" },
        { label: "商户名称", prop: "shopName" },
        { label: "应到账金额", prop: "arrivalAmount", slot: true },
        { label: "到账时间", prop: "arrivalTime" },
        // { label: "订单状态", prop: "orderStatus" },
        { label: "子订单号", prop: "orderCode" },
        { label: "支付流水号", prop: "paymentFlow" },
      ],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      isReport: true,
      option: {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          name: "日期",
          type: "category",
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "#666666",
              // f5222d
            },
          },
          axisLabel: {
            showMaxLabel: true, //显示最大刻度
            textStyle: {
              color: "#ccc",
            },
          },
          data: [],
          // boundaryGap: false,
        },
        yAxis: {
          type: "value",
          name: "销售额/元",
        },
        dataZoom: {
          type: "inside",
        },
        series: [
          {
            data: [],
            type: "line",
            // encode: {
            //   // 将 "amount" 列映射到 X 轴。
            //   x: "amount",
            //   // 将 "score" 列映射到 Y 轴。
            //   y: "score",
            // },
          },
        ],
        // dataset: {
        //   source: [
        //     ["score", "amount", "product"],
        //     [89.3, "2023-06-28", "Matcha Latte"],
        //     [57.1, "2023-06-29", "Milk Tea"],
        //     [74.4, "2023-06-30", "Cheese Cocoa"],
        //   ],
        // },
      },
    };
  },
  filters: {
    pirceFormatter(price) {
      if (price != null) {
        return price.toFixed(2);
      } else {
        return "0.00";
      }
    },
  },
  created() {
    this.setDate(2);
    this.getProvinceData();
    this.getList();
  },
  mounted() {},
  methods: {
    initDataCharts() {
      // 指定图表的配置项和数据
      if (document.getElementById("main")) {
        this.myChart = echarts.init(document.getElementById("main"));
        // 使用刚指定的配置项和数据显示图表。
        this.myChart.setOption(this.option, true);
      }
    },
    setDate(key) {
      this.dateKey = key;
      switch (key) {
        case 3:
          // 获取最近3个月
          this.searchForm.date = getRecentMonth_Date(3).split(",");
          break;
        case 2:
          // 获取最近一个月
          this.searchForm.date = getRecentMonth_Date(1).split(",");
          break;
        case 1:
          // 获取最近一个周
          this.searchForm.date = [getRecentDay_Date(6), getRecentDay_Date(0)];
          break;

        default:
          break;
      }
    },
    exportUrl() {
      let params = {
        ...this.searchForm,
        orderDateStart: this.searchForm.date[0] || null,
        orderDateEnd: this.searchForm.date[1] || null,
        pageNum: this.page.current,
        pageSize: this.page.size,
      };
      // let paramsStr = this.getQueryObject(params);
      // 导出报表
      // if (process.env.VUE_APP_BASE_API) {
      //   window.location.href =
      //     process.env.VUE_APP_BASE_API +
      //     "/statistic/purchaseOrderExcel/exportExcel" +
      //     paramsStr;
      // }
      exportOrderReportTableData(params).then((res) => {
        console.log(res);
      });
    },
    //json转url参数
    getQueryObject(requestParams) {
      let params = [];
      Object.entries(requestParams).forEach(([key, value]) => {
        let param = key + "=" + value;
        params.push(param);
      });
      return "?" + params.join("&");
    },
    reset(formName) {
      //清空方法
      for (var key in this.searchForm) {
        this.searchForm[key] = "";
      }
      this.setDate(2);
      this.getList();
      console.log(this.searchForm);
    },
    getList() {
      let params = {
        ...this.searchForm,
        orderDateStart: this.searchForm.date[0] || null,
        orderDateEnd: this.searchForm.date[1] || null,
        pageNum: this.page.current,
        pageSize: this.page.size,
      };
      purchaseOrderExcel(params).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.list;
          this.page.total = res.data.total;
        } else {
        }
      });
      this.findStDataByRangeGet();
    },

    findStDataByRangeGet() {
      let params = {
        startDate: this.searchForm.date[0] || null,
        endDate: this.searchForm.date[1] || null,
      };
      findStDataByRange(params).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.option.series[0].data = res.data.axisLine.series;
          this.option.xAxis.data = res.data.axisLine.xaxis;
          this.stProvinceAmtPo = res.data.stProvinceAmtPo;
          this.initDataCharts();
        } else {
        }
      });
    },
    //省份的选择按钮
    selectedHandle(val) {
      console.log(val, "val");
      this.$set(this.searchForm, "province", val.rname);
      this.$set(this.searchForm, "provinceCode", val.id);
      this.getCityBtn(val);
    },
    selectedHandleArea(val) {
      this.$set(this.searchForm, "district", val.rname);
      this.$set(this.searchForm, "districtCode", val.id);
    },
    //城市的选择按钮
    selectedHandleCity(val) {
      this.$set(this.searchForm, "city", val.rname);
      this.$set(this.searchForm, "cityCode", val.id);

      this.getAreaBtn(val);
    },
    getProvinceData() {
      getProvince().then((res) => {
        if (res.code == 0) {
          this.provinceData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },
    getCityBtn(val) {
      getCity({ id: val.id }).then((res) => {
        if (res.code == 0) {
          this.cityData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },

    getAreaBtn(val) {
      getArea({ id: val.id }).then((res) => {
        if (res.code == 0) {
          this.areaData = res.data;
        } else {
          this.$message({ type: "warning", message: res.msg });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.reportContent {
  background: #fff;
  // padding:0 0 10px 0;
  margin-top: -20px;
}
.header {
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  .title {
    line-height: 30px;
    font-size: 18px;
    span {
      font-size: 14px;
      // color: #595959;
    }
  }
}
.info-list {
  line-height: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  // margin-left: 105px;
  margin-top: 20px;
  // margin: 0 auto;
  .list {
    margin-right: 20px;
    span {
      font-size: 21px;
      color: #f5222d;
    }
  }
}
.date-picker {
  margin-left: 20px;
}
.active-button {
  background: #ffece8;
  color: #f5222d;
  border: solid 1px #ffece8;
}
.imageP {
  width: 220px;
  margin-left: 10px;
  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.goods-info-list {
  border-bottom: solid 1px #ccc;
}
.goods-info-list:nth-last-child(1) {
  border: none;
}
.price-info {
  width: 100px;
  height: 90px;
  border-bottom: solid 1px #ccc;
  text-align: center;
}
.unit-list {
  display: flex;
  .li {
    width: 95px;
  }
}
.search-from {
  margin-top: -20px;
}
</style>