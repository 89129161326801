<template>
    <div>
        <el-dialog title="标签管理" :visible.sync="visible" width="50%">
            <el-form :model="form" label-width="100px" ref="form" :rules="rules">
                <!-- 标签名称 -->
                <el-form-item label="标签名称" prop="labelName">
                    <el-input v-model.trim="form.labelName" style="width: 260px" size="small" maxlength="10"></el-input>
                </el-form-item>
                <!-- 标签图片 -->
                <el-form-item label="标签图片" prop="labelImg">
                    <div
                        v-if="!form.labelImg"
                        class="upload-image"
                        @click="handleUpload()"
                        >
                        <div class="image-wrap">
                            <el-image :src="AddIcon" />
                        </div>
                    </div>
                    <div v-else class="table-cell-pic-wrap">
                        <el-image
                            ref="labelImgRef"
                            :src="form.labelImg"
                            class="table-cell-pic"
                            :initial-index="0"
                            :preview-src-list="[form.labelImg]"
                        />
                        <div class="preview">
                            <el-image
                            :src="SeeIcon"
                            class="btn-icon"
                            @click="handlePreview(`labelImgRef`)"
                            />
                            <el-image
                            :src="DelIcon"
                            class="btn-icon"
                            @click="handleDeleteImage()"
                        />
                    </div>
                  </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleSave">保存</el-button>
                <el-button @click="visible = false">取消</el-button>
            </div>
        </el-dialog>
        <UploadImageView ref="imgupload" request-id="1" :imgType="imgType" @upload="uploadSubmit" />
    </div>
</template>
<script>
import UploadImageView from "@/views/myQualification/components/UploadImage.vue";
import { getLabelInfo, saveOrUpdateLabelInfo } from "@/api/goods";
import { generateUUId } from "@/views/myQualification/util/ViewHelp";
const IConst_See_Icon = require("@/assets/zz/see-icon.png");
const IConst_Del_Icon = require("@/assets/zz/del-icon.png");
const IConst_Add_Icon = require("@/assets/zz/add-icon.png");

import _ from "lodash";
export default {
    name: "labelManagerDialog",
    components: {
        UploadImageView
    },
    data() {
        return {
            form: {
                labelName: "",
                labelImg: "",
                labelId: ""
            },
            imgType: "labelImg",
            visible: false,
            SeeIcon: IConst_See_Icon,
            DelIcon: IConst_Del_Icon,
            AddIcon: IConst_Add_Icon,
            data:null,
            rules: {
                labelName: [
                    { required: true, message: "请输入标签名称", trigger: "blur" }
                ],
                labelImg: [
                    { required: true, message: "请上传标签图片", trigger: "blur" }
                ]
            }
        };
    },
    methods: {
        handleUpload() {
            //this.currentBigIndex = bigIndex;
            this.$refs["imgupload"].open();
        },
        uploadSubmit(obj) {
            const {  url } = obj;
            this.form.labelImg = url;
        },
        handlePreview(refName) {
            if (this.$refs[refName]) {
                this.$refs[refName].showViewer = true;
            }
        },
        handleDeleteImage() {
            this.form.labelImg = "";
        },
        handleSave() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    let obj = _.cloneDeep(this.data);
                    obj.key = generateUUId();
                    if (this.form.labelName) {
                        obj.labelName = this.form.labelName;
                    }
                    if (this.form.labelImg) {
                        obj.image = this.form.labelImg;
                    }
                    saveOrUpdateLabelInfo(obj).then((res) => {
                        if (res) {
                            this.$message.success("保存成功");
                            this.visible = false;
                            this.$emit("refresh");
                        }
                    });        
                }
            });
            
        },
        publicOpenDialog(e){
            this.visible = true;
            this.data = e;
            this.form.labelId = e.id;
            this.form.labelName = e.labelName;
            this.form.labelImg = e.image;
        }
    }
};
</script>
<style lang="scss" scoped>
.upload-image {
      width: 200px;
      height: 200px;
      border: 1px dashed #c0c0c0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .image-wrap {
        width: 16px;
        height: 16px;
      }
    }
 .table-cell-pic-wrap {
      width: 200px;
      height: 200px;
      position: relative;

      .table-cell-pic {
        width: 100%;
        height: 100%;
      }

 .preview {
    position: absolute;
    visibility: hidden;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .btn-icon {
        width: 16px;
        height: 16px;
        cursor: pointer;
        & + .btn-icon {
        margin-top: 6px;
        }
    }
}

    &:hover {
        .preview {
            visibility: visible;
        }
    }
}
</style>