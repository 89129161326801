import _ from "lodash";

export const IConst_State = {
  None: null, //草稿
  Wait: "0", //等待审核中
  Fail: "1", //审核失败
  Sucess: "2", //审核成功
  Wait_20: "20", //20.初审通过,也是显示等待审核中
};

export const IConst_Field = {
  Input: 1, //输入
  Pull: 2, //下拉单选
  Area: 3, //区域选择
  MutilPull: 4, //多选
};

export const generateUUId = () => {
  const uuid = new Date().getTime() + Math.random().toString(36).substr(2);
  return uuid;
};

export const viewHelp = {
  /**服务器数据转换到本地数据进行显示 */
  assistQualificationDetail(serveData) {
    let formData = { filedList: [], gridList: [], key: generateUUId() };
    if (serveData) {
      //生成详情信息相关的域
      if (serveData.certifyList && serveData.certifyList instanceof Array) {
        const gridList = [];
        //证件相关
        serveData.certifyList.forEach((item) => {
          let certifyItem = {
            isMust: item.required,
            title: item.certificateName,
            imageUrlList: item.certificateImages ? item.certificateImages : [],
            filedList: [],
            example: item.example, //示例
            template: item.template, //模版
            extObj: _.cloneDeep(item),
          };

          const filedList = this.assistQualificationDynFields(item.fields);
          certifyItem.filedList = [...filedList];

          gridList.push(certifyItem);
        });

        formData.gridList = [...gridList];
      }
      //底部动态域
      const filedList = this.assistQualificationDynFields(
        serveData.commonFieldList
      );
      formData.filedList = [...filedList];
    }
    return formData;
  },
  assistQualificationDynFields(fieldList) {
    const filedList = [];
    if (fieldList && fieldList instanceof Array) {
      //
      fieldList.forEach((item) => {
        let localField = {
          type: item.inputType,
          isMust: item.required,
          modeValue: "",
          modeValueName: "",
          options: [], // //单选，多选要，其它的不需要
          tip: item.fieldNameText,
          extObj: _.cloneDeep(item),
        };

        if (item.inputType === IConst_Field.Area) {
          if (item.fieldValue) {
            const areaArr = item.fieldValue.split(",");
            if (areaArr.length % 2 === 0) {
              //合理的区域数据 "999990,999991,999992,体验省,体验市,体验一区"
              const len = areaArr.length / 2;
              localField.modeValue = [];
              for (let i = 0; i < len; i++) {
                localField.modeValue.push(+areaArr[i]);
              }
              localField.modeValueName = [];

              for (let i = len; i < areaArr.length; i++) {
                localField.modeValueName.push(areaArr[i]);
              }
            }
          }
        } else if (item.inputType === IConst_Field.Input) {
          localField.modeValue = item.fieldValue ? item.fieldValue : "";
        } else if (
          item.inputType === IConst_Field.Pull ||
          item.inputType === IConst_Field.MutilPull
        ) {
          localField.options = [];
          if (item.selectEnum) {
            try {
              const jsonObj = JSON.parse(item.selectEnum);
              if (jsonObj) {
                for (let key in jsonObj) {
                  localField.options.push({
                    value: key,
                    label: jsonObj[key],
                    extObj: _.cloneDeep(jsonObj),
                  });
                }
              }
            } catch (e) {
              console.log(e);
            }
            //
          }
          if (item.fieldValue) {
            if (item.inputType === IConst_Field.Pull) {
              localField.modeValue = item.fieldValue; //单选不是数组
            } else {
              localField.modeValue = item.fieldValue.split(",");
            }
          }
        }

        filedList.push(localField);
      });
    }
    return filedList;
  },
  /**对外 本地数据生成服务器数据,准备上传 */
  generateServeData(localFormData) {
    const serveData = { certifyList: [], commonFieldList: [] };
    if (localFormData) {
      if (localFormData.gridList && localFormData.gridList instanceof Array) {
        const certifyList = [];
        localFormData.gridList.forEach((item) => {
          let certifyItem = {
            certityType: item.extObj.certityType,
            certificateId: item.extObj.certificateId,
            certificateImages: [],
            fields: [],
          };

          //资质图片
          if (item.imageUrlList && item.imageUrlList instanceof Array) {
            certifyItem.certificateImages = [...item.imageUrlList];
          }
          //资质动态域
          const fields = this.generateFieldServerData(item.filedList);

          certifyItem.fields = [...fields];

          certifyList.push(certifyItem);
        });

        serveData.certifyList = [...certifyList];
      }
      //底部动态域
      const fields = this.generateFieldServerData(localFormData.filedList);

      serveData.commonFieldList = [...fields];
    }

    return serveData;
  },
  generateFieldServerData(fieldList) {
    const fields = [];
    if (fieldList && fieldList instanceof Array) {
      fieldList.forEach((item) => {
        let fieldItem = {
          fieldName: item.extObj.fieldName,
          fieldValue: "",
          inputType: item.extObj.inputType, //域类型
        };

        if (item.modeValue) {
          if (item.type === IConst_Field.Area) {
            let fieldValue = [];
            if (item.modeValue instanceof Array) {
              //区域code
              fieldValue = item.modeValue.join(",");
            }
            if (item.modeValueName instanceof Array) {
              //区域code对应的名称
              fieldValue += "," + item.modeValueName.join(",");
            }
            fieldItem.fieldValue = fieldValue;
          } else if (
            item.type === IConst_Field.Pull ||
            item.type === IConst_Field.MutilPull
          ) {
            if (item.type === IConst_Field.Pull) {
              fieldItem.fieldValue = item.modeValue ? item.modeValue : "";
            } else {
              if (item.modeValue instanceof Array) {
                fieldItem.fieldValue = item.modeValue.join(",");
              }
            }
          } else if (item.type === IConst_Field.Input) {
            fieldItem.fieldValue = item.modeValue;
          }
        }

        fields.push(fieldItem);
      });
    }
    return fields;
  },
  showErrorMsg(messageBox, res) {
    const msg = res?.msg ?? "未知错误";
    messageBox.error(msg);
  },
  showSuccessMsg(messageBox, msg) {
    messageBox.success(msg);
  },
};
