<template>
  <div class="invoiceMain">
    <div class="topView">
      <div class="topTitle"
           v-if="companyInfo">
        <span>可用授信额度</span>
        <div class="topMoney">¥{{ fixtoTwo(companyInfo.availableQuota) }}</div>
        <span class="topDetail">总授信额度：¥{{fixtoTwo(companyInfo.quota)}}</span>
      </div>
      <div class="topTitle"
           v-if="totalAmountData">
        <span>已开具金额</span>
        <span class="topMoney">¥{{fixtoTwo(totalAmountData.totalAmount)}}</span>
        <span class="topDetail">累计税额：¥{{fixtoTwo(totalAmountData.taxAmount)}} <span>发票：{{ totalAmountData.total }} 张</span> </span>
      </div>
    </div>
    <div class="selectView">
      <el-input v-model="formTool.clinicName"
                size="small"
                style="width: 180px;"
                placeholder="请输入客户名称/发票" />
      <div style="width: 8px;"></div>
      <el-select v-model="formTool.invoiceType"
                 size="small">
        <el-option :label="item.codeText"
                   :value="item.codeValue"
                   v-for="item,index in invoiceTypeList"
                   :key="index" />
      </el-select>
      <div style="width: 8px;"></div>
      <!-- <el-select v-model="goodsType" style="width: 130px" size="small">
                <el-option label="消肿止痛贴" value="消肿止痛贴" />
            </el-select> -->
      <div style="width: 8px;"></div>
      <el-date-picker v-model="timeRange"
                      type="daterange"
                      unlink-panels
                      size="small"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions"
                      @change="didSelectDate"
                      ref="datePicker"
                      :clearable="false">
      </el-date-picker>
      <div style="width: 8px;"></div>
      <el-button size="small"
                 @click="reset()"
                 type="default"
                 plain
                 style="margin-left: 8px;">重置</el-button>
      <el-button size="small"
                 @click="query()"
                 type="primary">查询</el-button>
    </div>
    <div class="tblView"
         ref="tblContainer">
      <div class="btnArr">
        <el-button size="small"
                   @click="addDialog=true"
                   type="default"
                   plain
                   style="margin-left: 8px;">新增</el-button>
        <!-- <el-button size="small" @click="reset()" type="default" plain style="margin-left: 8px;">删除</el-button>
                <el-button size="small" @click="reset()" type="default" plain style="margin-left: 8px;">导出</el-button>
                <el-button size="small" @click="reset()" type="default" plain style="margin-left: 8px;">导入</el-button>
                <el-button size="small" @click="reset()" type="default" plain style="margin-left: 8px;">生成凭证</el-button> -->
      </div>
      <el-table v-if="tblHeight"
                :data="dataList"
                style="width: 100%;"
                :max-height="getTblHeight">
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column label="发票总类">
          <template slot-scope="scope">
            <span>{{ getLocalOfficeName(scope.row.invoiceType) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="taxDate"
                         label="开票日期"></el-table-column>
        <el-table-column prop="taxNumber"
                         label="发票号码"></el-table-column>
        <el-table-column prop="customerName"
                         label="客户名称"></el-table-column>
        <el-table-column label="合计金额">
          <template slot-scope="scope">
            <span>¥{{ fixtoTwo(scope.row.excludingTaxAmount) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="taxRate"
                         label="税率">
          <template slot-scope="scope">{{ getTaxtRate( scope.row.taxRate) }}%</template>
        </el-table-column>
        <el-table-column label="合计税额">
          <template slot-scope="scope">
            <span>¥{{ fixtoTwo(scope.row.taxAmount) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="价税合计">
          <template slot-scope="scope">
            <span>¥{{ fixtoTwo(scope.row.totalAmount) }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="regionalCompanyName" label="认证状态">

                </el-table-column> -->
        <el-table-column fixed="right"
                         label="操作"
                         width="66">
          <template slot-scope="scope">
            <el-button @click.native.prevent="lookPdf(scope.row)"
                       class="editBtn"
                       type="text">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="page.total"
                  :page-sizes="page.pageSizes"
                  :page.sync="page.current"
                  :limit.sync="page.size"
                  @pagination="pageChange" />
    </div>
    <el-dialog :visible.sync="addDialog"
               title="立即开票"
               width="520px">
      <InvoiceDialog @cancelAddDialog="cancelAddDialog"
                     @addSureAction="addSureAction" />
    </el-dialog>
    <el-dialog :visible.sync="pdfDialog"
               title="发票详情">
      <iframe v-if="pdfUrl"
              :src="'data:application/pdf;base64,' + pdfUrl"
              style="width: 100%; height: 500px;"
              frameborder="0"></iframe>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import NProgress from 'nprogress' // progress bar
import dayjs from 'dayjs'
import InvoiceDialog from './components/invoiceDialog'
import { getInvoicePageList, getInvoiceType, getInvoiceTotalAmount, getCompanyInfo, getInvoicePdf } from "@/api/invoice";
export default {
  components: { Pagination, InvoiceDialog },
  data () {
    return {
      formTool: {
        clinicName: '',
        invoiceType: '',
      },
      invoiceTypeList: [],
      timeRange: [],
      goodsType: "消肿止痛贴",
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近六个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dataList: [],
      page: {
        total: 10,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50, 100]
      },
      addDialog: false,
      pdfDialog: false,
      pdfUrl: '',
      tblHeight: null,
      totalAmountData: null,
      companyInfo: null
    }
  },
  computed: {
    getTblHeight () {
      let h = 400
      if (typeof (this.tblHeight) == 'number') {
        h = this.tblHeight - 53 - 44
      }
      return h + 'px'
    }
  },
  mounted () {
    // console.log(this.$refs['tblContainer'].offsetHeight);
    if (this.$refs['tblContainer']) {
      this.tblHeight = this.$refs['tblContainer'].offsetHeight
    }
    this.getInvoiceTypeAction()
  },
  methods: {

    didSelectDate () {

    },
    pageChange () {

    },
    getLocalOfficeName (v) {
      for (let index = 0; index < this.invoiceTypeList.length; index++) {
        const element = this.invoiceTypeList[index];
        if (v == element.codeValue) {
          return element.codeText
        }
      }
    },
    getTaxtRate (e) {
      if (!e) {
        return '0'
      } else {
        return parseFloat(e) * 100
      }
    },
    fixtoTwo (str = 0.00) {
      if (str) {
        str = parseFloat(str)
        return str.toFixed(2).toString()
      }
      return '0.00'
    },
    async query () {
      const obj = {
        current: this.page.current,
        size: this.page.size,
        taxNumber: this.formTool.clinicName,//发票号码
        invoiceType: this.formTool.invoiceType,//	发票种类,可用值:GENERAL,DEDICATED
        // businessType:'',//	业务类型,可用值:SALE,PROCURED,OFFICE,FREIGHT,TRAVEL,OTHER
      }
      if (this.timeRange && this.timeRange.length) {
        obj['startDate'] = dayjs(this.timeRange[0]).format('YYYY-MM-DD')//开始时间
        obj['endDate'] = dayjs(this.timeRange[1]).format('YYYY-MM-DD')//结束时间
      }
      const resp = await getInvoicePageList(obj)
      if (resp && resp.records && resp.records.length) {
        this.dataList = resp.records
        this.page.total = resp.total
      } else {
        this.dataList = []
        this.page.total = 0
      }
      const totalResp = await getInvoiceTotalAmount(obj)
      if (totalResp) {
        this.totalAmountData = totalResp
      } else {
        this.totalAmountData = null
      }
      const infoResp = await getCompanyInfo()
      if (infoResp) {
        this.companyInfo = infoResp
      } else {
        this.companyInfo = null
      }
    },
    reset () {
      this.timeRange = []
      this.formTool.clinicName = ''
      this.formTool.invoiceType = ''
    },
    async lookPdf (item) {
      const obj = {
        recordId: item.id
      }
      const resp = await getInvoicePdf(obj)
      if (resp) {
        this.pdfDialog = true
        this.pdfUrl = resp
      }
    },
    addSureAction (e) {
      // 立即开票
      this.$router.push({
        path: `/invoiceAdd`,
        query: {
          invoiceType: e.data.invoiceType
        }
      })
    },
    cancelAddDialog () {
      this.addDialog = false
    },
    async getInvoiceTypeAction () {
      const resp = await getInvoiceType()
      if (resp) {
        let tmp = []
        for (const key in resp) {
          if (Object.hasOwnProperty.call(resp, key)) {
            const element = resp[key];
            tmp.push({
              codeValue: key,
              codeText: element
            })
          }
        }
        this.invoiceTypeList = tmp
        this.query()
      }

    },
  }
}
</script>
<style lang="scss" scoped>
.invoiceMain {
  background-color: #fff;
  display: flex;
  flex: 1;
  padding: 16px;
  border-radius: 4px;
  flex-direction: column;
  height: calc(100vh - 148px);

  .topView {
    display: flex;
    align-items: center;

    .topTitle {
      display: flex;
      flex-direction: column;
      color: #595959;
      font-size: 14px;
      font-weight: 400;
      width: 250px;

      .topMoney {
        color: #262626;
        font-size: 20px;
        font-weight: 600;
        margin: 10px 0px;
      }

      .topDetail {
        font-size: 12px;
        color: #bfbfbf;
      }
    }
  }

  .selectView {
    display: flex;
    align-items: center;
    margin: 30px 0px;
  }

  .tblView {
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    display: flex;
    flex: 1;
    flex-direction: column;
    .btnArr {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 10px;
    }
    .editBtn {
      cursor: pointer;
      color: var(--main-color);
    }
  }

  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 280px;
  }
}
</style>