<template>
  <div class="search-from">
    <el-form ref="searchData"
             :inline="true"
             style="text-align: left">
      <span v-for="(item, key) in labelList"
            class="search-from-item">
        <el-form-item v-if="item.type == 'input'">
          <el-input v-model="searchData[item.fieldName]"
                    :disabled="item.disabled"
                    :placeholder="item.name"
                    clearable
                    maxlength="20"
                    size="small"
                    :style="
              item.width ? `width: ${item.width}px` : ` width:${defaultWidth}px`
            " />
        </el-form-item>
        <el-form-item v-if="item.type == 'select'">
          <el-select v-model="searchData[item.fieldName]"
                     size="small"
                     clearable
                     :placeholder="`${item.name}`"
                     style="width: 100%"
                     :disabled="item.disabled"
                     :style="
              item.width ? `width: ${item.width}px` : ` width:${defaultWidth}px`
            ">
            <el-option v-for="e in item.select"
                       :label="e.name"
                       :value="e.id"
                       :key="e.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="item.type == 'province'">
          <el-select v-model="searchData[item.fieldName]"
                     size="small"
                     :placeholder="`${item.name}`"
                     style="width: 100%"
                     :disabled="item.disabled"
                     :style="
              item.width ? `width: ${item.width}px` : ` width:${defaultWidth}px`
            "
                     @change="changeProvince">
            <el-option v-for="e in provinceData"
                       :label="e.rname"
                       :value="e"
                       :key="e.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="item.type == 'city'">
          <el-select v-model="searchData[item.fieldName]"
                     size="small"
                     :placeholder="`${item.name}`"
                     style="width: 100%"
                     :disabled="item.disabled"
                     :style="
              item.width ? `width: ${item.width}px` : ` width:${defaultWidth}px`
            "
                     @change="changeCity">
            <el-option v-for="e in cityData"
                       :label="e.rname"
                       :value="e"
                       :key="e.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="item.type == 'area'">
          <el-select v-model="searchData[item.fieldName]"
                     size="small"
                     :placeholder="`${item.name}`"
                     style="width: 100%"
                     :disabled="item.disabled"
                     :style="
              item.width ? `width: ${item.width}px` : ` width:${defaultWidth}px`
            "
                     @change="changeArea">
            <el-option v-for="e in areaData"
                       :label="e.rname"
                       :value="e"
                       :key="e.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="item.type == 'date'">
          <el-date-picker v-model="searchData[item.fieldName]"
                          value-format="yyyy-MM-dd"
                          size="small"
                          clearable
                          type="daterange"
                          range-separator="-"
                          :style="item.width ? `width: ${item.width}px;` : `width: 200px;`"
                          :start-placeholder="
              item.startPlaceholder ? item.startPlaceholder : '开始日期'
            "
                          :end-placeholder="
              item.endPlaceholder ? item.endPlaceholder : '结束日期'
            ">
          </el-date-picker>
        </el-form-item>
      </span>

      <el-form-item>
        <el-button size="small"
                   type="primary"
                   @click="getList(1)">查询</el-button>
      </el-form-item>

      <el-form-item>
        <el-button size="small"
                   @click="reset()">重置</el-button>
      </el-form-item>

      <el-form-item v-if="showExport">
        <el-button size="small"
                   @click="exportForm()">导出</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="text"
                   size="small"
                   @click="handleExpand()">
          {{ expanded ? "收起" : "展开"
            }}<i :class="{
                'el-icon-arrow-up': expanded,
                'el-icon-arrow-down': !expanded,
                'el-icon--right': true,
              }"></i>
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { regionAreaTree } from "@/api/saleRule/saleF";

export default {
  components: {},
  props: {
    expanded: {
      type: Boolean,
      default () {
        return false;
      },
    },
    labelList: {
      type: Array,
      default () {
        return [];
      },
    },
    showExport: {
      type: Boolean,
      default () {
        return false;
      },
    },
    isShowAddress: {
      type: Boolean,
      default () {
        return false;
      },
    },
    searchData: {
      type: Object,
      default () {
        return {};
      },
    },
  },
  data () {
    return {
      // expanded: false,
      defaultWidth: 140,
      dateArr: [],
      provinceData: [],
      cityData: [],
      areaData: [],
    };
  },
  filters: {},
  created () { },
  mounted () {
    console.log(this.searchData);
    if (this.isShowAddress) {
      this.getRegionAreaTree();
    }
  },
  methods: {
    handleExpand () {
      this.expanded = !this.expanded;
      this.$emit('handleExpand', this.expanded)
    },
    getList () {
      if (this.expanded) {
        this.searchData.keyWords = '';
      } else {
        // params.keyWords = this.formTool.keyWords;
      }
      this.$emit("input", this.searchData);
      this.$emit("getList", 1);
    },

    reset () {
      this.cityData = [];
      this.areaData = [];
      this.$emit("reset");
    },

    exportForm () {
      if (this.expanded) {
        this.searchData.keyWords = '';
      } else {
        // params.keyWords = this.formTool.keyWords;
      }
      this.$emit("export");
    },

    // 获取省市区列表
    getRegionAreaTree (leve) {
      let data = {
        level: 3,
      };
      regionAreaTree(data).then((res) => {
        if (res.code === 0) {
          this.provinceData = res.data;
          console.log(res.data);
        }
      });
    },

    changeProvince (e) {
      console.log(e);
      this.searchData.province = e.rname;
      this.searchData.city = "";
      this.cityData = e.children;
    },

    changeCity (e) {
      this.searchData.area = "";
      this.searchData.city = e.rname;
      this.areaData = e.children;
      this.$forceUpdate();
    },

    changeArea (e) {
      this.searchData.area = e.rname;
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
.search-from {
  margin-top: 10px;
}
.search-from-item {
}
</style>
