var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "shop-info-root tab-content new-cls" }, [
    _c("div", { staticClass: "form" }, [
      _c("div", { staticClass: "block" }, [
        _c("div", { staticClass: "block-content" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "value fill" },
              _vm._l(_vm.weekDays, function (item, index) {
                return _c(
                  "el-checkbox",
                  {
                    key: index,
                    model: {
                      value: item.checked,
                      callback: function ($$v) {
                        _vm.$set(item, "checked", $$v)
                      },
                      expression: "item.checked",
                    },
                  },
                  [_vm._v(_vm._s(item.label))]
                )
              }),
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "tip" }),
            _c(
              "div",
              { staticClass: "value fill" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.doUpdateInfo },
                  },
                  [_vm._v("立即提交")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "block" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "block-content" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("起止时间")]),
              _c(
                "div",
                { staticClass: "value input big-w" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      size: "small",
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.dnonAssessmentTime,
                      callback: function ($$v) {
                        _vm.dnonAssessmentTime = $$v
                      },
                      expression: "dnonAssessmentTime",
                    },
                  }),
                ],
                1
              ),
            ]),
            0
              ? [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("截单时间")]),
                    _c(
                      "div",
                      { staticClass: "value input big-w" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            size: "small",
                            type: "datetime",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.dnonAssessmentCutOffTime,
                            callback: function ($$v) {
                              _vm.dnonAssessmentCutOffTime = $$v
                            },
                            expression: "dnonAssessmentCutOffTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._m(2),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("发货时间")]),
                    _c(
                      "div",
                      { staticClass: "value input big-w" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            size: "small",
                            type: "datetime",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.dnonAssessmentReturnTime,
                            callback: function ($$v) {
                              _vm.dnonAssessmentReturnTime = $$v
                            },
                            expression: "dnonAssessmentReturnTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._m(3),
                ]
              : _vm._e(),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "tip" }),
              _c(
                "div",
                { staticClass: "value fill" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.doUpdateInfo },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("工作时间"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "block-title" }, [
      _c("span", { staticClass: "text" }, [_vm._v("非考核时间段")]),
      _c("span", { staticClass: "desp" }, [
        _vm._v("（如库存盘点、ERP切换等，最长时间为30天）"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row contine" }, [
      _c("div", { staticClass: "tip" }),
      _c("div", { staticClass: "value fill" }, [
        _c("div", { staticClass: "small-tip" }, [
          _c("span", [
            _vm._v(
              "截单时间提示为[2023年9月28日15时截单],显示在app或PC供应商名前面"
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row contine" }, [
      _c("div", { staticClass: "tip" }),
      _c("div", { staticClass: "value fill" }, [
        _c("div", { staticClass: "small-tip" }, [
          _c("span", [
            _vm._v(
              "发货时间提示为[2023年9月28日15时发货],显示在app或PC供应商名前面"
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }