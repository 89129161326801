<template>
    <div class="dialog">
        <el-dialog :title="title" :visible.sync="visible" :lock-scroll="true" width="1000px" :show-close="false" :close-on-click-modal="false">
            <el-form ref="formTool" :model="formTool" :inline="true" style="text-align: left" label-width="140px">
                <div class="tips">
                    请您及时下架召回批号的商品，已采购/销售的商品请做退货处理
                </div>
                <div class="goodsContent">
                    <div class="list-warp" v-for="(item, key) in recallList">
                        <div class="goodList">
                            <div class="img-div">
                                <el-image class="l" :src="item.image ? item.image : avater" alt="" />
                            </div>
                            <div class="r">
                                <div>
                                    <span class="txt-line-1 name">{{ item.goodsName }}</span>
                                </div>
                                <div>
                                    <span class="rr txt-line-1">{{ item.factory }}</span>
                                </div>
                                <div>
                                    <span class="rr txt-line-1">{{ item.specification }}</span>
                                </div>
                                <!-- <div>
                                    <span class="rr txt-line-1">{{ formTool.approvalNumber }}</span>
                                </div> -->
                            </div>
                        </div>

                        <div class="list-rt">
                            <div class="left_topic mb20" style="margin-top: 10px">召回批号</div>
                            <el-row class="list">
                                <span v-for="(item, key) in item.batchNumberList"> {{ item }} </span>
                            </el-row>
                        </div>
                    </div>
                </div>

            </el-form>
            <div class="btnBox" v-if="!isDisabled">
                <el-button size="small" @click="next">稍后处理</el-button>
                <el-button size="small" type="primary" @click="submit">已处理</el-button>
                <!-- <el-button type="primary" size="small" @click="chooseGoods">确认</el-button> -->
            </div>
        </el-dialog>
    </div>
</template>
<script>
import avater from "/public/img/default.png";

import { getRecallList, readerList } from "@/api/message.js";
export default {
    components: {
    },
    props: {
        btnType: {
            type: String,
            default: ''
        },
    },
    computed: {
        visible() {
            return this.dialogShow
        }
    },
    data() {
        return {
            recallList: [],
            checkListOptons: ['1', '2'],
            avater: avater,
            showForm: false, //是否展示表单
            dialogShow: false,
            isDisabled: false,
            pageType: '',
            info: {},
            formTool: {},
            title: '召回商品',
            goodsList: [],
            selectItems: [],
            messageHome: {
                isNextRemind: false,//是否 下次提醒  设置为true 退出登录前不再提醒，退出登录的时候 把这个值设置为false
                // isHandle: false  //是否 已处理  值为true 的时候同一个标题不再提醒
            },
            page: {
                total: 10,
                current: 1,
                size: 10,
                pageSizes: [10, 20, 30, 50],
            },
        }
    },
    mounted() {

    },
    methods: {

        getRecallList() {
            getRecallList().then((res) => {
                if (res) {
                    this.recallList = res;
                    if (this.recallList.length == 0) {
                        return
                    }
                    if (window.localStorage.getItem('messageHome')) {
                        let messageHomeSotre = JSON.parse(window.localStorage.getItem('messageHome') || '');
                        if (messageHomeSotre.isNextRemind) {
                            console.log(messageHomeSotre.isNextRemind)
                            return // 已处理/下次提醒 （并且缓存的标题和返回的标题同一个） ，不弹
                        }
                    }
                    this.dialogShow = true;
                }
            });
        },
        next() {
            this.messageHome.isNextRemind = true;
            window.localStorage.setItem('messageHome', JSON.stringify(this.messageHome))
            this.dialogShow = false;
        },
        submit() {
            readerList().then((res) => {
                if (res) {
                    // this.$message.success('提交成功！')
                    this.dialogShow = false;
                } else {
                    if (res.msg) {
                        this.$message.error('提交失败！')
                        // this.$message.success(res.msg)
                    }
                }
            });
        },

        handleClose() {
            this.dialogShow = false;
        },

        cancel() {
            this.dialogShow = false;
        },

    }
}
</script>
<style lang="scss">
.btnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.serach {
    margin-bottom: 10px;
}

.goods-content {
    max-height: 70vh;
    overflow-y: auto;
}

.goodList {
    display: flex;
    justify-content: space-around;

    &:nth-child(3n) {
        margin-right: 0px;
    }

    .img-div {
        height: 88px;
        width: 88px;
        margin: 0 10px;

        image {
            width: 100%;
            height: 100%;
        }
    }

    .l {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
    }

    .r {
        width: 214px;
        display: flex;
        flex: 1;
        flex-direction: column;

        div {
            display: flex;
        }

        span {
            font-size: 13px;
            color: #333;
            //margin-top: 10px;
        }

        .name {
            text-align: left;
            font-size: 16px;
            font-weight: 600;
            color: var(--color-26);
        }

        .rr {
            text-align: left;
            color: var(--color-59);
            font-size: 14px;
        }
    }

    &:hover {
        //  border: 2px solid var(--main-color) !important;
    }
}

.reject-c {
    margin-top: 10px;

    .title {
        line-height: 30px;
    }
}

.list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    line-height: 40px;

    span {
        margin: 0 10px;
    }
}

.list-warp {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: solid 1px #eee;
}

.goodsContent {
    height: 50vh;
    overflow-y: auto;
}

.list-rt {
    width: 50%;
}

.tips {
    margin: 20px auto;
}
</style>