/*
 * @FilePath: /eshop-ops-web/src/api/goodsManage/baseDepot.js
 * @Description: 商品基础库
 * @Author: xiexingzhong2012@sina.cn
 * @Date: 2023-07-24 19:33:23
 * @LastEditTime: 2023-07-30 09:46:32
 * @LastEditors: xiexingzhong2012@sina.cn
 */
import request from '@/utils/request'
import { Message } from 'element-ui'

// 根据id 查询属性
export function getAttributeByTypeId(data) {
  return request({
    url: "/product/compgoodsattribute/getAttributeByTypeId",
    method: "get",
    params: data,
  });
}

// /compliance/goodsbase/getCompGoodsBaseDetail
// 查询商品基础库详情
export function getCompGoodsBaseDetail(data) {
  return request({
    url: "/product/compliance/goodsbase/getCompGoodsBaseDetail",
    method: "get",
    params: data,
  });
}

 

//发布 新增商品
export function goodstemporarySave(data) {
  return request({
    url: "/product/compgoodstemporary/save",
    method: "post",
    data: data,
  });
}


// 纠错 查询详情信息
export function getGoodsbaseDetail(data) {
  return request({
    url: `/product/compliance/goodsbase/${data.id}`,
    method: "get", 
  });
}
 
 
//  商品纠错审核
export function correctionCheck(data) {
  return request({
    url: "/product/compliance/correction/check",
    method: "post",
    data: data,
  });
}

//发布 新增商品纠错
export function addGoodsCorrection(data) {
  return request({
    url: "/product/compliance/correction/addGoodsCorrection",
    method: "post",
    data: data,
  });
}



// 查询商品详情 临时表
export function getGoodsTemporaryDetail(data) {
  return request({
    url: `/product/compgoodstemporary/getGoodsTemporaryDetail`,
    method: "get", 
    params: data
  });
}

// /compgoodstemporary/update
// 修改商品临时表
export function compgoodstemporaryUpdate(data) {
  return request({
    url: "/product/compgoodstemporary/update",
    method: "post",
    data: data,
  });
}