var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "controlSalesManCoopeationStatistic",
      staticClass: "controlSalesManCoopeationStatistic",
    },
    [
      _c(
        "div",
        { ref: "formContent", staticClass: "form-content" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, inline: "" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "业务员ID" } },
                [
                  _c("el-input", {
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.form.salesmanId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "salesmanId", $$v)
                      },
                      expression: "form.salesmanId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合作商品名称" } },
                [
                  _c("el-input", {
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.form.cooperateGoodsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cooperateGoodsName", $$v)
                      },
                      expression: "form.cooperateGoodsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "provinceName" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "120px",
                            "margin-right": "5px",
                          },
                          attrs: { size: "mini", placeholder: "省" },
                          on: { change: _vm.selectedHandle },
                          model: {
                            value: _vm.form.provinceName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "provinceName", $$v)
                            },
                            expression: "form.provinceName",
                          },
                        },
                        _vm._l(_vm.provinceData, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.rname, value: item },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "120px",
                            "margin-right": "5px",
                          },
                          attrs: { size: "mini", placeholder: "市" },
                          on: { change: _vm.selectedHandleCity },
                          model: {
                            value: _vm.form.cityName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cityName", $$v)
                            },
                            expression: "form.cityName",
                          },
                        },
                        _vm._l(_vm.cityData, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.rname, value: item },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "120px",
                            "margin-right": "5px",
                          },
                          attrs: { size: "mini", placeholder: "区" },
                          on: { change: _vm.selectedHandleArea },
                          model: {
                            value: _vm.form.areaName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "areaName", $$v)
                            },
                            expression: "form.areaName",
                          },
                        },
                        _vm._l(_vm.areaData, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.rname, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "申请状态" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.form.applyStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "applyStatus", $$v)
                        },
                        expression: "form.applyStatus",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "全部", value: "" } }),
                      _c("el-option", {
                        attrs: { label: "申请中", value: "0" },
                      }),
                      _c("el-option", {
                        attrs: { label: "合作中", value: "1" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleReset },
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.handleSearch },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.tableHeight
        ? _c("div", { staticClass: "table-content" }, [
            _c(
              "div",
              { staticClass: "table-content" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      border: "",
                      height: _vm.tableHeight,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "商品信息",
                        prop: "salesmanId",
                        width: "320",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "goods-info" }, [
                                  _c("div", { staticClass: "goods-img" }, [
                                    _c("img", {
                                      attrs: {
                                        src: "https://eshop-develop.oss-cn-hangzhou.aliyuncs.com/goods/aoliao_2024061214165576482228.jpg",
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                  _c("div", [
                                    _c("div", [_vm._v("复方甘草片")]),
                                    _c("div", [_vm._v("青海制药有限公司")]),
                                    _c("div", [_vm._v("100片")]),
                                    _c("div", [_vm._v("国药准字2021-01-01")]),
                                    _c("div", [_vm._v("商品编码：CP123456")]),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3832378981
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "关联部门",
                        prop: "departmentName",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "业务员ID", prop: "salesmanId" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "申请合作-所在省", prop: "provinceName" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "申请合作-所在市", prop: "cityName" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "申请合作-所在区", prop: "areaName" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "客户数量", prop: "customerCount" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "已接品种数量（剩余可接数量）",
                        prop: "goodsCount",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "已接品种类型",
                        prop: "goodsType",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "累计销售数量", prop: "salesCount" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "累计销售金额", prop: "salesMoney" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "可负责客户类型",
                        prop: "fuzeGoodsType",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "可负责商品类型",
                        prop: "fuzeGoodsType",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "申请时间",
                        prop: "applyTime",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "审核时间",
                        prop: "checkTime",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        width: "150",
                        fixed: "right",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleAgree(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("同意合作")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleReject(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("驳回合作")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        233322241
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pagination" },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, sizes, prev, pager, next, jumper",
                    "page-size": 10,
                    total: 100,
                  },
                  on: { "current-change": _vm.handlePageChange },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }