var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goodsCards" },
    _vm._l(_vm.goodsData, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "itemCont",
          class: _vm.active == index ? "activeClass" : "remove",
          on: {
            click: function ($event) {
              return _vm.choiceItem(item, index)
            },
          },
        },
        [
          _c("div", { staticClass: "boxTop" }, [
            _c(
              "div",
              { staticClass: "itemImg" },
              [
                _c("el-image", {
                  staticStyle: {
                    width: "188px",
                    height: "188px",
                    padding: "10px",
                  },
                  attrs: {
                    src: item.image || _vm.avater,
                    alt: "",
                    lazy: "",
                    "preview-src-list": item.image ? [item.image] : [],
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "rightBox" }, [
              _c(
                "div",
                {
                  staticClass: "itemBox name",
                  style:
                    _vm.params.chemName != item.name
                      ? "color:red;padding-bottom:20px"
                      : "padding-bottom:20px",
                },
                [_vm._v(" 名称：" + _vm._s(item.name || "--") + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "itemBox factory",
                  style:
                    _vm.params.specification != item.specification
                      ? "color:red !important"
                      : "",
                },
                [_vm._v(" 规格：" + _vm._s(item.specification || "--") + " ")]
              ),
              _c("div", { staticClass: "itemBox factory" }, [
                _vm._v(" 商品条形码：" + _vm._s(item.barCode || "--") + " "),
              ]),
              _c("div", { staticClass: "itemBox factory" }, [
                _vm._v(
                  " 批准文号：" + _vm._s(item.approvalNumber || "--") + " "
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "itemBox factory",
                  style:
                    _vm.params.factory != item.factory
                      ? "color:red !important"
                      : "",
                },
                [_vm._v(" 厂家：" + _vm._s(item.factory || "--") + " ")]
              ),
              _c("div", { staticClass: "itemBox factory" }, [
                _vm._v(" 中包装：" + _vm._s(item.middlePack || "--") + " "),
              ]),
              _c("div", { staticClass: "itemBox factory" }, [
                _vm._v(" 大包装：" + _vm._s(item.bigPack || "--") + " "),
              ]),
              item.origin
                ? _c("div", { staticClass: "itemBox factory" }, [
                    _vm._v(" 产地：" + _vm._s(item.origin || "--") + " "),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }