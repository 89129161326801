<template>
  <div class="contentCont">
    <MyTable :table-data="tableData"
             :index="index"
             :selection="selection"
             :page="page"
             :operation="operation"
             :showpage="false"
             :table-option.sync="tableOption"
             @page-change="getList">
    </MyTable>

  </div>
</template>
<script>
import MyTable from '@/components/myTable'
import { queryShopScopeByShopIdList } from '@/api/shopmange/index'
export default {
  components: { MyTable },
  data () {
    return {
      operation: false,
      selection: false,
      index: false,
      tableData: [],
      tableOption: [
        { label: '商品经营范围', prop: 'scopeName', width: '180px' },
        { label: '费率（单位：百分比）', prop: 'scopeRate' },
      ],
      page: {
        total: 10,
        current: 1,
        size: 10
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      queryShopScopeByShopIdList([]).then(res => {
        if (res.code == 0) {
          this.tableData = res.data
        } else {
          this.tableData = []
        }

      })
    },
  }
}
</script>
<style lang="scss" scoped>
.contentCont {
  height: calc(100% - 33px);
  overflow: auto;
  padding: 24px;
  padding-top: 0px;
}
</style>