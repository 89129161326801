<template>
    <div class="controlSalesManCoopeationStatistic" ref="controlSalesManCoopeationStatistic">
        <div class="form-content" ref="formContent">
            <el-form ref="form" :model="form" inline>
                <!-- 业务员ID -->
                <el-form-item label="业务员ID">
                    <el-input v-model="form.salesmanId"  size="mini"></el-input>
                </el-form-item>
                <!-- 合作商品名称 -->
                <el-form-item label="合作商品名称">
                    <el-input v-model="form.cooperateGoodsName"  size="mini"></el-input>
                </el-form-item>
                <!-- 省市区 -->
                <el-form-item label="" prop="provinceName">
                    <div>
                        <el-select size="mini" v-model="form.provinceName" placeholder="省" @change="selectedHandle"
                            style="width: 120px;margin-right: 5px;">
                            <el-option v-for="item in provinceData" :label="item.rname" :value="item"
                                :key="item.id"></el-option>
                        </el-select>
                        <el-select size="mini" v-model="form.cityName" placeholder="市" @change="selectedHandleCity"
                            style="width: 120px;margin-right: 5px;">
                            <el-option v-for="item in cityData" :label="item.rname" :value="item"
                                :key="item.id"></el-option>
                        </el-select>
                        <el-select size="mini" v-model="form.areaName" @change="selectedHandleArea" placeholder="区"
                            style="width: 120px;margin-right: 5px;">
                            <el-option v-for="item in areaData" :label="item.rname" :value="item"
                                :key="item.id"></el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <!-- 申请状态 -->
                <el-form-item label="申请状态">
                    <el-select size="mini" v-model="form.applyStatus" placeholder="请选择" style="width: 120px;">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="申请中" value="0"></el-option>
                        <el-option label="合作中" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <!-- 查询 重置按钮 -->
                <el-form-item>
                    <el-button  size="small" @click="handleReset">重置</el-button>
                    <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-content" v-if="tableHeight">
            <div class="table-content">
                <el-table :data="tableData" border style="width: 100%" :height="tableHeight">
                    <el-table-column label="商品信息" prop="salesmanId" width="320">
                        <template slot-scope="scope">
                            <div class="goods-info">
                                <div class="goods-img">
                                    <img src="https://eshop-develop.oss-cn-hangzhou.aliyuncs.com/goods/aoliao_2024061214165576482228.jpg" alt="">
                                </div>
                                <div>
                                    <div>复方甘草片</div>
                                    <div>青海制药有限公司</div>
                                    <div>100片</div>
                                    <div>国药准字2021-01-01</div>
                                    <div>商品编码：CP123456</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="关联部门" prop="departmentName" width="150"></el-table-column>
                    <!-- 业务员ID -->
                    <el-table-column label="业务员ID" prop="salesmanId"></el-table-column>
                    <!-- 申请合作-所在省 -->
                    <el-table-column label="申请合作-所在省" prop="provinceName"></el-table-column>
                    <!-- 申请合作-所在市 -->
                    <el-table-column label="申请合作-所在市" prop="cityName"></el-table-column>
                    <!-- 申请合作-所在区 -->
                    <el-table-column label="申请合作-所在区" prop="areaName"></el-table-column>
                    <!-- 客户数量 -->
                    <el-table-column label="客户数量" prop="customerCount"></el-table-column>
                    <!-- 已接品种数量（剩余可接数量） -->
                    <el-table-column label="已接品种数量（剩余可接数量）" prop="goodsCount" width="150"></el-table-column>
                    <!-- 已接品种类型 -->
                    <el-table-column label="已接品种类型" prop="goodsType" width="150"></el-table-column>
                    <!-- 累计销售数量 -->
                    <el-table-column label="累计销售数量" prop="salesCount"></el-table-column>
                    <!-- 累计销售金额 -->
                    <el-table-column label="累计销售金额" prop="salesMoney"></el-table-column>
                    <!-- 可负责客户类型 -->
                    <el-table-column label="可负责客户类型" prop="fuzeGoodsType" width="150"></el-table-column>
                    <!-- 可负责商品类型 -->
                    <el-table-column label="可负责商品类型" prop="fuzeGoodsType" width="150"></el-table-column>
                    <!-- 申请时间 -->
                    <el-table-column label="申请时间" prop="applyTime" width="150"></el-table-column>
                    <!-- 审核时间 -->
                    <el-table-column label="审核时间" prop="checkTime" width="150"></el-table-column>
                    <!-- 操作 -->
                    <el-table-column label="操作" width="150" fixed="right" align="center">
                        <template slot-scope="scope">
                            <!-- 同意合作 -->
                            <el-button type="text" size="mini" @click="handleAgree(scope.$index)">同意合作</el-button>
                            <!-- 驳回合作 -->
                            <el-button type="text" size="mini" @click="handleReject(scope.$index)">驳回合作</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    :page-size="10"
                    :total="100"
                    @current-change="handlePageChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { getProvince, getCity, getArea } from "@/api/shopmange/index";
import _ from "lodash";
export default {
    name: 'controlSalesManCoopeationStatistic',
    data() {
        return {
            form: {
                salesmanId: "",// 业务员ID
                cooperateGoodsName: "",// 合作商品名称
                provinceName: "",// 省
                province: "",// 省ID
                cityName: "",// 市
                city: "",// 市ID
                areaName: "",// 区
                area: "",// 区ID
                applyStatus: "",// 申请状态
            },
            provinceData: [], //省份数据
            cityData: [], //城市数据
            areaData: [], //区域数据
            tableData: [
                {
                    departmentName: "青海省分公司",
                    salesmanId: "123456",
                    cooperateGoodsName: "复方甘草片",
                    goodsCode: "CP123456",
                    applyStatus: "申请中",
                    provinceName: "青海省",
                    cityName: "西宁市",
                    areaName: "城北区",
                    customerCount: 100,
                    goodsCount: 100,
                    goodsType: "风寒感冒",
                    salesCount: 100,
                    salesMoney: 10000,
                    fuzeCustomerCount: 100,
                    fuzeGoodsCount: 100,
                    fuzeGoodsType: "风寒感冒",
                    applyTime: "2021-01-01 12:00:00",
                    checkTime: "2021-01-01 12:00:00",
                },
                {
                    departmentName: "青海省分公司",
                    salesmanId: "123456",
                    cooperateGoodsName: "复方甘草片",
                    goodsCode: "CP123456",
                    applyStatus: "申请中",
                    provinceName: "青海省",
                    cityName: "西宁市",
                    areaName: "城北区",
                    customerCount: 100,
                    goodsCount: 100,
                    goodsType: "风寒感冒",
                    salesCount: 100,
                    salesMoney: 10000,
                    fuzeCustomerCount: 100,
                    fuzeGoodsCount: 100,
                    fuzeGoodsType: "风寒感冒",
                    applyTime: "2021-01-01 12:00:00",
                    checkTime: "2021-01-01 12:00:00",
                },
                {
                    departmentName: "青海省分公司",
                    salesmanId: "123456",
                    cooperateGoodsName: "复方甘草片",
                    goodsCode: "CP123456",
                    applyStatus: "申请中",
                    provinceName: "青海省",
                    cityName: "西宁市",
                    areaName: "城北区",
                    customerCount: 100,
                    goodsCount: 100,
                    goodsType: "风寒感冒",
                    salesCount: 100,
                    salesMoney: 10000,
                    fuzeCustomerCount: 100,
                    fuzeGoodsCount: 100,
                    fuzeGoodsType: "风寒感冒",
                    applyTime: "2021-01-01 12:00:00",
                    checkTime: "2021-01-01 12:00:00",
                },
                {
                    departmentName: "青海省分公司",
                    salesmanId: "123456",
                    cooperateGoodsName: "复方甘草片",
                    goodsCode: "CP123456",
                    applyStatus: "申请中",
                    provinceName: "青海省",
                    cityName: "西宁市",
                    areaName: "城北区",
                    customerCount: 100,
                    goodsCount: 100,
                    goodsType: "风寒感冒",
                    salesCount: 100,
                    salesMoney: 10000,
                    fuzeCustomerCount: 100,
                    fuzeGoodsCount: 100,
                    fuzeGoodsType: "风寒感冒",
                    applyTime: "2021-01-01 12:00:00",
                    checkTime: "2021-01-01 12:00:00",
                },
                {
                    departmentName: "青海省分公司",
                    salesmanId: "123456",
                    cooperateGoodsName: "复方甘草片",
                    goodsCode: "CP123456",
                    applyStatus: "申请中",
                    provinceName: "青海省",
                    cityName: "西宁市",
                    areaName: "城北区",
                    customerCount: 100,
                    goodsCount: 100,
                    goodsType: "风寒感冒",
                    salesCount: 100,
                    salesMoney: 10000,
                    fuzeCustomerCount: 100,
                    fuzeGoodsCount: 100,
                    fuzeGoodsType: "风寒感冒",
                    applyTime: "2021-01-01 12:00:00",
                    checkTime: "2021-01-01 12:00:00",
                },
                {
                    departmentName: "青海省分公司",
                    salesmanId: "123456",
                    cooperateGoodsName: "复方甘草片",
                    goodsCode: "CP123456",
                    applyStatus: "申请中",
                    provinceName: "青海省",
                    cityName: "西宁市",
                    areaName: "城北区",
                    customerCount: 100,
                    goodsCount: 100,
                    goodsType: "风寒感冒",
                    salesCount: 100,
                    salesMoney: 10000,
                    fuzeCustomerCount: 100,
                    fuzeGoodsCount: 100,
                    fuzeGoodsType: "风寒感冒",
                    applyTime: "2021-01-01 12:00:00",
                    checkTime: "2021-01-01 12:00:00",
                },
            ], //表格数据
            tableHeight: 0, //表格高度
            formHeight: 0, //表单高度
        }
    },
    created() {
        this.getProvinceData(); //获取省地区域树 
    },
    mounted() {
        this.handleSizeChange();
        window.addEventListener("resize", this.resizeHandler);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizeHandler);
    },
    methods: {
        resizeHandler() {
            this.handleSizeChange()
        },
        handleSizeChange:_.throttle(function () {
            this.formHeight = this.$refs.formContent.offsetHeight;
            this.tableHeight = this.$refs.controlSalesManCoopeationStatistic.offsetHeight - this.formHeight - 50;
        }, 1000),
        handlePageChange(val) {
            // this.$emit("pageChange", val);
        },
        handleAgree(index) {
            // this.$emit("agree", index);
        },
        handleReject(index) {
            // this.$emit("reject", index);
        },
        handleSearch() {
            // this.$emit("search", this.form);
        },
        handleReset() {
            this.form = {
                salesmanId: "",// 业务员ID
                cooperateGoodsName: "",// 合作商品名称
                provinceName: "",// 省
                province: "",// 省ID
                cityName: "",// 市
                city: "",// 市ID
                areaName: "",// 区
                area: "",// 区ID
                applyStatus: "",// 申请状态
            };
            this.getProvinceData(); //获取省地区域树 
        },
        //省份的选择按钮
        selectedHandle(val) {
            // console.log(val, "val");
            this.form.provinceName = val.rname;
            this.form.province = val.id;
            this.cityData = [];
            this.form.cityName = "";
            this.form.city = "";
            this.areaData = [];
            this.form.areaName = "";
            this.form.area = "";
            this.getCityBtn(val.id);
        },

        //城市的选择按钮
        selectedHandleCity(val) {
            this.form.cityName = val.rname;
            this.form.city = val.id;
            this.areaData = [];
            this.form.areaName = "";
            this.form.area = "";
            this.getAreaBtn(val.id);
        },

        //区域的选择按钮
        selectedHandleArea(val) {
            this.form.areaName = val.rname;
            this.form.area = val.id;
        },
        getProvinceData() {
            getProvince().then((res) => {
                if (res.code == 0) {
                    this.provinceData = res.data;
                } else {
                    this.$message({ type: "warning", message: res.msg });
                }
            });
        },
        getCityBtn(val) {
            getCity({ id: val }).then((res) => {
                if (res.code == 0) {
                    this.cityData = res.data;
                } else {
                    this.$message({ type: "warning", message: res.msg });
                }
            });
        },

        getAreaBtn(val) {
            getArea({ id: val }).then((res) => {
                if (res.code == 0) {
                    this.areaData = res.data;
                } else {
                    this.$message({ type: "warning", message: res.msg });
                }
            });
        },
    }
}
</script>

<style scoped lang="scss">
.controlSalesManCoopeationStatistic {
    height: calc(100vh - 145px);
    background-color: #fff;
    border-radius: 4px;
    .form-content{
        padding: 10px 15px 0px 15px;
    }
    .table-content{
        margin: 0px 10px;
        .table-content{
            .goods-info{
                display: flex;
                align-items: center;
                .goods-img{
                    width: 100px;
                    height: 100px;
                    margin: 0px 10px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .pagination{
            margin-top: 10px;
            text-align: right;
        }
    }
}
</style>