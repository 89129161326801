import request from '@/utils/request'
import { Message } from 'element-ui'

// 卖家端 满赠促销活动 列表
export function giftPayeeList(data) {
  return request({
    url: "/product/gift/payee/list",
    method: "get",
    params: data,
  });
}

// 卖家端 满赠促销活动 新增或修改
export function giftPayeeSaveOrUpdate(data) {
  return request({
    url: "/product/gift",
    method: "post",
    data: data,
  });
}

// 卖家端 满赠促销活动  详情
export function giftPayeeDetail(id) {
  return request({
    url: `/product/gift/${id}`,
    method: "get",

  });
}

// /compliance/goodsbase/queryGoodsBasePage
// 查询基库商品列表
export function queryGoodsBasePage(data) {
  return request({
    url: "/product/compliance/goodsbase/queryGoodsBasePage",
    method: "get",
    params: data,
  });
}

// /sales/sku/get/full/gift/page
// 查询满赠商品
export function queryFullGiftPage(data) {
  return request({
    url: "/product/sales/sku/get/full/gift/page",
    method: "get",
    params: data,
  });
}
 
// 赠品，审核
export function giftAudit(data) {
  return request({
    url: `/product/gift/audit`,
    method: "put",
    data: data,
  });
}

///sales/sku/get/full/gift/time

//  获取赠品原品时间限制
export function queryFullGiftTime(data) {
  return request({
    url: "/product/sales/sku/get/full/gift/time",
    method: "post",
    data: data,
  });
}