var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "myButton" },
    [
      _c("p", [_vm._v("封装组件示例")]),
      _c("MyButton", {
        attrs: {
          type: "success",
          icon: "el-icon-delete",
          text: "删除",
          disabled: _vm.disabled,
          loading: _vm.loading,
          size: "medium",
          round: _vm.round,
          plain: _vm.plain,
          circle: _vm.circle,
        },
        on: { click: _vm.clickBtn },
      }),
      _c("MyInput", {
        attrs: { text: _vm.text, clearable: _vm.clearable },
        on: { change: _vm.change },
      }),
      _c("imgUpload", {
        model: {
          value: _vm.image,
          callback: function ($$v) {
            _vm.image = $$v
          },
          expression: "image",
        },
      }),
      _c("wangEnduit", {
        attrs: { value: _vm.details, "is-clear": _vm.isClear },
        on: { changeEndit: _vm.watchEndit },
      }),
      _c("MySelect", {
        attrs: {
          options: _vm.options,
          "value-key": _vm.valueKey,
          multiple: _vm.multiple,
          collapse: _vm.collapse,
          text: _vm.textSelect,
          clearable: _vm.clearable,
          disabled: _vm.disabled,
        },
        on: { change: _vm.changeSelect },
      }),
      _c("MyTable", {
        attrs: {
          "table-data": _vm.tableData,
          page: _vm.page,
          operation: _vm.operation,
          "table-option": _vm.tableOption,
        },
        on: {
          "update:tableOption": function ($event) {
            _vm.tableOption = $event
          },
          "update:table-option": function ($event) {
            _vm.tableOption = $event
          },
          "page-change": _vm.getList,
          "cell-click": _vm.cellClick,
        },
        scopedSlots: _vm._u([
          {
            key: "ranks",
            fn: function (scope) {
              return [_c("el-tag", [_vm._v(_vm._s(scope.row.ranks))])]
            },
          },
          {
            key: "menu",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "button",
                      size: "mini",
                      icon: "el-icon-delete",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.deleteHandle(scope.row)
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }