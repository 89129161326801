var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("div", { staticClass: "search" }, [
        _c(
          "div",
          {},
          [
            _c("el-input", {
              attrs: {
                size: "small",
                placeholder: "活动名称",
                clearable: "",
                "min-width": "50px",
              },
              model: {
                value: _vm.activityName,
                callback: function ($$v) {
                  _vm.activityName = $$v
                },
                expression: "activityName",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "everyItem" },
          [
            _c("el-input", {
              attrs: {
                size: "small",
                clearable: "",
                placeholder: "商品名称",
                "min-width": "50px",
              },
              model: {
                value: _vm.goodsName,
                callback: function ($$v) {
                  _vm.goodsName = $$v
                },
                expression: "goodsName",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "everyItem" },
          [
            _c("el-input", {
              attrs: {
                size: "small",
                clearable: "",
                placeholder: "商品编号",
                "min-width": "50px",
              },
              model: {
                value: _vm.goodsCode,
                callback: function ($$v) {
                  _vm.goodsCode = $$v
                },
                expression: "goodsCode",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "everyItem" },
          [
            _c("el-date-picker", {
              attrs: {
                size: "small",
                "value-format": "yyyy-MM-dd",
                type: "daterange",
                "range-separator": "至",
                "start-placeholder": "活动开始",
                "end-placeholder": "活动结束",
              },
              model: {
                value: _vm.activeDate,
                callback: function ($$v) {
                  _vm.activeDate = $$v
                },
                expression: "activeDate",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "everyItem" },
          [
            _c("el-date-picker", {
              attrs: {
                size: "small",
                "value-format": "yyyy-MM-dd",
                type: "daterange",
                "range-separator": "至",
                "start-placeholder": "添加开始",
                "end-placeholder": "添加结束",
              },
              model: {
                value: _vm.createDate,
                callback: function ($$v) {
                  _vm.createDate = $$v
                },
                expression: "createDate",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "everyItem", staticStyle: { display: "flex" } },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.getList(1)
                  },
                },
              },
              [_vm._v("查询")]
            ),
            _c(
              "el-button",
              { attrs: { size: "small", type: "" }, on: { click: _vm.reset } },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
      ]),
      _c("Tabs", {
        attrs: { tabsList: _vm.tabsList, activeName: _vm.activeName },
        on: {
          "update:activeName": function ($event) {
            _vm.activeName = $event
          },
          "update:active-name": function ($event) {
            _vm.activeName = $event
          },
          "tab-click": _vm.handleChange,
        },
      }),
      _c(
        "div",
        { staticClass: "center", staticStyle: { position: "relative" } },
        [
          _c("MyTable", {
            attrs: {
              "table-data": _vm.tableData,
              index: _vm.index,
              selection: _vm.selection,
              page: _vm.page,
              operation: _vm.operation,
              "table-option": _vm.tableOption,
            },
            on: {
              "update:tableOption": function ($event) {
                _vm.tableOption = $event
              },
              "update:table-option": function ($event) {
                _vm.tableOption = $event
              },
              "page-change": _vm.getList,
              handleSelectionChange: _vm.handleSelectionChange,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "status",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.getActiveStatus(String(scope.row.status)))
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "info",
                  fn: function (scope) {
                    return scope.row.platformActivityGoodsInfoVO
                      ? [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "80px",
                                    height: "80px",
                                  },
                                },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "80px",
                                      height: "80px",
                                    },
                                    attrs: {
                                      src:
                                        scope.row.platformActivityGoodsInfoVO
                                          .image || _vm.avater,
                                      "preview-src-list": [
                                        scope.row.platformActivityGoodsInfoVO
                                          .image || _vm.avater,
                                      ],
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "10px" } },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "16px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.platformActivityGoodsInfoVO
                                            .goodsName || "--"
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        color: "rgb(191, 191, 191)",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.platformActivityGoodsInfoVO
                                            .factory || "--"
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.platformActivityGoodsInfoVO
                                          .approvalNumber || "--"
                                      )
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.platformActivityGoodsInfoVO
                                          .specification || "--"
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      : undefined
                  },
                },
                {
                  key: "price",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          "￥" +
                            _vm._s(
                              scope.row.platformActivityGoodsInfoVO.price || ""
                            )
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "butie",
                  fn: function (scope) {
                    return scope.row.platformActivityGoodsOrderInfoVO
                      ? [
                          _c("div", [
                            _vm._v(
                              "￥" + _vm._s(scope.row.subsidyPrice || "0.00")
                            ),
                          ]),
                        ]
                      : undefined
                  },
                },
                {
                  key: "guize",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          " 一次买满" +
                            _vm._s(scope.row.threshold) +
                            "元，" +
                            _vm._s(
                              scope.row.type == 1
                                ? `商品${scope.row.discount}折`
                                : `优惠${scope.row.discount}元`
                            ) +
                            " "
                        ),
                      ]),
                      _c("span", [
                        _vm._v(
                          " 单个商品" +
                            _vm._s(
                              scope.row.singleGoodsLimitFlag == 1
                                ? `上限金额${scope.row.singleGoodsLimit}元`
                                : `无上限`
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "huodongkucun",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          "活动总限购数量：" + _vm._s(scope.row.inventory || "")
                        ),
                      ]),
                      _c("div", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          "剩余活动库存：" +
                            _vm._s(scope.row.residueInventory || "--")
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "shuju",
                  fn: function (scope) {
                    return scope.row.platformActivityGoodsOrderInfoVO
                      ? [
                          _c("div", [
                            _vm._v(
                              "采购店数：" +
                                _vm._s(
                                  scope.row.platformActivityGoodsOrderInfoVO
                                    .purchaseShopCount || 0
                                )
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              "采购数量：" +
                                _vm._s(
                                  scope.row.platformActivityGoodsOrderInfoVO
                                    .purchaseGoodsCount || 0
                                )
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              "采购金额（元）：" +
                                _vm._s(
                                  scope.row.platformActivityGoodsOrderInfoVO
                                    .countMoney || "0.00"
                                )
                            ),
                          ]),
                        ]
                      : undefined
                  },
                },
                {
                  key: "startTime",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(scope.row.startTime || "") +
                            "~" +
                            _vm._s(scope.row.endTime || "")
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "caozuo",
                  fn: function (scope) {
                    return [
                      scope.row.status != 2 && scope.row.status != 4
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary", underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(scope.row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          )
                        : _vm._e(),
                      scope.row.status != 2 && scope.row.status != 4
                        ? _c(
                            "el-link",
                            {
                              staticStyle: { "margin-left": "16px" },
                              attrs: { type: "primary", underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteActivety(scope.row)
                                },
                              },
                            },
                            [_vm._v("移除")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看原因",
            visible: _vm.dialogVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("这是驳回原因")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑活动价格及库存",
            visible: _vm.dialogVisibleS,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleS = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.formInline },
            },
            [
              _c("el-form-item", { attrs: { label: "商品原价" } }, [
                _c("span", [
                  _vm._v("￥" + _vm._s(_vm.formInline.originalPrice)),
                ]),
              ]),
              _c(
                "el-form-item",
                { staticClass: "moneyStyle", attrs: { label: "活动价格" } },
                [
                  _c("el-input", {
                    attrs: { size: "small", placeholder: "审批人" },
                    model: {
                      value: _vm.formInline.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "price", $$v)
                      },
                      expression: "formInline.price",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", [
            _vm._v(
              "注意：补贴商品价格修改后 ，不能高于上次修改后的价格（￥" +
                _vm._s(_vm.formInline.priceBefore) +
                "）"
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisibleS = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "移除",
            visible: _vm.dialogVisibleDelete,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleDelete = $event
            },
          },
        },
        [
          _c("span", [_vm._v("是否确认把此商品移除活动？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisibleDelete = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.deleteApplyUnion },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }