<template>
    <div class="dialog">
        <el-dialog title="设置比例" :visible.sync="visible" :lock-scroll="true" width="818px" :before-close="handleClose">
            <el-form ref="formTool" :model="formTool" label-width="180px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="salesUserRate" label="业务员推广服务费比例"
                            :rules="{ required: true, message: '请填写业务员推广服务费比例', trigger: 'blur' }">
                            <el-input v-model="formTool.salesUserRate" placeholder="请输入" clearable size="small"
                                style="width:50%" @input="salesUserRateSet" />
                            % (输入范围5~50)
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="24">
                        <el-form-item prop="rate" label="技术服务费比例">
                            <el-input v-model="formTool.rate" clearable size="small" :disabled="true" style="width:50%" />
                            %
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="promoteRate" label="平台推广服务费比例">
                            <el-input v-model="formTool.promoteRate" clearable size="small" :disabled="true"
                                style="width:50%" />
                            %
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="btnBox">
                    <el-button size="small" @click="handleClose">取消</el-button>
                    <el-button type="primary" size="small" @click="submit">确认</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>

import { getSelfControllerGoodsList } from "@/api/shopmange";
import { updateRateRule } from "@/api/externalSalesRule";


export default {
    components: {

    },
    props: {

    },
    computed: {
        visible() {
            return this.dialogShow
        }
    },
    data() {
        return {
            formTool: {
                salesUserRate: '',
                rate: '',
                promoteRate: ''
            },
            dialogShow: false,
            areaList: [],
            newAreaList: [],
            promoteRateNew:''
        }
    },
    created() {

    },
    methods: {
        salesUserRateSet(val) {
            if (val >50) {
                this.formTool.salesUserRate = 50;
                val = 50;
            }
            let obj = {};
            obj.promoteRate = null;
            obj = Object.assign(obj, { promoteRate: this.promoteRateNew })
            let promoteRate = 1;
            promoteRate = val * JSON.parse(JSON.stringify( obj.promoteRate )) / 100;  
            // console.log(promoteRate,'---promoteRate')
            this.formTool.promoteRate  = promoteRate.toFixed(2) ;
        },
        handleClose() {
            this.dialogShow = false;
        },
        submit() {
            if (this.formTool.salesUserRate < 5 || this.formTool.salesUserRate > 50) {
                this.$message.error('服务费比例，设置范围值5%~50%')
                return
            }
            let params = {};
            params.goodsId = this.$route.query.goodsId;
            params.deptId = this.$route.query.deptId;
            params.salesUserRate = this.formTool.salesUserRate / 100;
            params.areaList = this.newAreaList;
            if (params.areaList.length == 0) {
                this.$message.error('请选择设置区域！');
                return;
            }
            console.log(this.formTool)
            updateRateRule(params).then((res) => {
                if (res) {
                    this.$message.success('提交成功');
                    this.$emit('getList')
                    this.handleClose();
                }
            });
        },

        areaTreeFind(list) {
            this.newAreaList = [];
            for (let i = 0; i < list.length; i++) {
                if(this.newAreaList.indexOf(list[i].id) == -1){
                   this.newAreaList.push(list[i].id); 
                }
            }
        },

        init(row, rate, promoteRate) {
            if(!row || row.length == 0) return this.$message.error('请选择区域');
            this.dialogShow = true;
            this.areaList = row;
            this.formTool.rate = (rate * 100).toFixed(2);
            this.formTool.salesUserRate = row[0].salesUserRate * 100; //回显的业务员推广服务费比例 
            this.formTool.salesUserRate = this.formTool.salesUserRate.toFixed(2);
            this.formTool.promoteRate = promoteRate * 100 *  row[0].salesUserRate; //回显的平台推广服务费比例
            this.formTool.promoteRate = this.formTool.promoteRate.toFixed(2);
            this.promoteRateNew =  promoteRate * 100; 

            this.newAreaList = [];
            console.log(this.areaList, 'areaList')
            this.areaTreeFind(this.areaList);
            // console.log(this.newAreaList, 'newAreaList')
        }
    }
}
</script>
<style lang="scss">
.btnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}</style>