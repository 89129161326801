var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c("div", { staticClass: "header" }, [
      _c("span", { staticClass: "font" }, [_vm._v("报名")]),
      _c(
        "div",
        { staticClass: "step-frame" },
        [
          _c("StepView", {
            attrs: {
              "current-index": _vm.localCurrentIndex,
              "step-list": _vm.stepList,
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "centent" },
      [
        _vm.localCurrentIndex == 1
          ? [
              _c("firstStep", {
                attrs: { id: _vm.id },
                on: { changeStep: _vm.changeStep, getSkuList: _vm.getSkuList },
              }),
            ]
          : _vm.localCurrentIndex == 2
          ? [
              _c("secondStep", {
                attrs: { id: _vm.id, skuList: _vm.skuList },
                on: { changeStep: _vm.changeStep },
              }),
            ]
          : _vm.localCurrentIndex == 3
          ? [
              _c("threeStep", {
                attrs: { id: _vm.id },
                on: { changeStep: _vm.changeStep },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }