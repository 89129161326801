var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增运营",
            visible: _vm.dialogVisible,
            width: "400px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { staticClass: "form-centent", attrs: { size: "small" } },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip commo-text-ideograph" }, [
                  _vm._v("运营姓名"),
                ]),
                _c("div", { staticClass: "value" }, [_c("el-input")], 1),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip commo-text-ideograph" }, [
                  _vm._v("运营电话"),
                ]),
                _c("div", { staticClass: "value" }, [_c("el-input")], 1),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip commo-text-ideograph" }, [
                  _vm._v("在售商品数"),
                ]),
                _c("div", { staticClass: "value" }, [_c("el-input")], 1),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip commo-text-ideograph" }, [
                  _vm._v("商品分类"),
                ]),
                _c(
                  "div",
                  { staticClass: "value" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择商品分类" },
                        on: { change: _vm.searchHandle },
                      },
                      _vm._l(
                        [
                          { label: "商家承担运费 ", value: 0 },
                          { label: "自定义运费", value: 1 },
                        ],
                        function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "tip commo-text-ideograph" }, [
                  _vm._v("ERP编码"),
                ]),
                _c("div", { staticClass: "value" }, [_c("el-input")], 1),
              ]),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitUpload()
                    },
                  },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }