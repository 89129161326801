<template>
  <div class="form">
    <div class="tit">拼团商品</div>
    <el-form
      ref="searchForm"
      :model="searchForm"
      :inline="true"
      style="text-align: left"
    >
      <el-form-item prop="name">
        <el-input
          style="width: 140px;"
          v-model="searchForm.name"
          placeholder="拼团名称"
          clearable
          size="small"
        />
      </el-form-item>
      <el-form-item prop="goodsName">
        <el-input
          v-model="searchForm.goodsName"
          style="width: 140px;"
          placeholder="商品名称"
          clearable
          size="small"
        />
      </el-form-item>
      <el-form-item prop="goodsApprovalNumber">
        <el-input
          v-model="searchForm.goodsApprovalNumber"
          style="width: 140px;"
          placeholder="商品批准文号"
          clearable
          size="small"
        />
      </el-form-item>
      <!-- <el-form-item prop="ignoreLimit">
        <el-select
          size="mini"
          style="width: 120px;"
          v-model="searchForm.ignoreLimit"
          placeholder="是否成团"
        >
          <el-option label="是" value="1"></el-option>
          <el-option label="否" value="0"></el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item prop="status">
        <el-select
          size="mini"
          v-model="searchForm.status"
          style="width: 120px;"
          placeholder="是否阶梯价"
        >
          <el-option label="阶梯价" value="1"></el-option>
          <el-option label="非阶梯价" value="0"></el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item prop="status">
        <el-select
          size="mini"
          v-model="searchForm.status"
          style="width: 120px;"
          placeholder="活动状态"
        >
          <el-option
            v-for="item in statusList"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item prop="date">
        <el-date-picker
          v-model="searchForm.date"
          value-format="yyyy-MM-dd"
          type="daterange"
          size="small"
          range-separator="-"
          start-placeholder="活动开始"
          end-placeholder="活动结束"
        />
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="search(1)"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button size="small" @click="reset('searchForm')">重置</el-button>
      </el-form-item>
      <!-- <el-form-item>
        <el-button size="small" type="primary" @click="add()">导出</el-button>
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        name: "",
        goodsName: "",
        goodsApprovalNumber: "",
        date: [],
      },
    };
  },
  methods: {
    search(current){
      this.$emit("search", current, this.searchForm);
    },
    reset(formName) {
      this.$refs[formName].resetFields();
      this.$emit("search");
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  .tit {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #262626;
    padding: 10px 0;
  }
}
::v-deep .el-form-item {
  margin-bottom: 0px!important;
}
::v-deep .el-form-item__content {
  line-height: 32px;
}
</style>
