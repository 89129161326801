var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "zt__table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "tableRef",
          attrs: {
            "max-height": _vm.maxHeight,
            "highlight-current-row": "",
            selectableCanBeDisable: _vm.selectableCanBeDisable,
            data: _vm.tableData,
            border: _vm.border,
            "row-key": _vm.getRowKey,
          },
          on: {
            "sort-change": _vm.sortChange,
            "cell-click": _vm.cellClick,
            "selection-change": _vm.handleSelectionChange,
            "current-change": _vm.handleCurrentChange,
            "row-click": _vm.rowClick,
          },
        },
        [
          _vm.selection
            ? _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "55",
                  selectable: _vm.selectable,
                  align: "left",
                  "reserve-selection": _vm.reserveSelection,
                },
              })
            : _vm._e(),
          _vm.index
            ? _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: "left",
                  width: "50",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.$index +
                                  (_vm.page.current - 1) * _vm.page.size +
                                  1
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3796683090
                ),
              })
            : _vm._e(),
          _vm.expand
            ? _c("el-table-column", {
                attrs: {
                  type: "expand",
                  label: "",
                  align: "left",
                  width: "50",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _vm._t("expand", null, {
                            row: props.row,
                            $index: props.$index,
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
          _vm._l(_vm.tableOption, function (item, i) {
            return [
              item.prop != "countDownTimeAfter"
                ? _c(
                    "el-table-column",
                    {
                      key: i,
                      attrs: {
                        "min-width": item.minWidth,
                        "max-width": item.maxWidth,
                        width: item.width,
                        prop: item.prop,
                        label: item.label,
                        align: item.align || "left",
                        sortable: item.sortable || false,
                        "show-overflow-tooltip": item.overHidden || false,
                        "render-header": item.renderHeader,
                        fixed: item.fixed,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                item.slot
                                  ? _vm._t(scope.column.property, null, {
                                      row: scope.row,
                                      $index: scope.$index,
                                    })
                                  : item.type && item.type == "money"
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            parseFloat(
                                              scope.row[
                                                scope.column.property
                                              ] || 0
                                            ).toFixed(2)
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row[scope.column.property]
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      item.customHeader
                        ? _c(
                            "template",
                            { slot: "header" },
                            [
                              _vm._v(" " + _vm._s(item.label) + " "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: item.content,
                                    placement: "top",
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-question" })]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              item.prop == "countDownTimeAfter"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table-column",
                        {
                          key: i,
                          attrs: {
                            "min-width": item.minWidth,
                            "max-width": item.maxWidth,
                            width: item.width,
                            prop: item.prop,
                            label: item.label,
                            align: item.align || "left",
                            sortable: item.sortable || false,
                            "show-overflow-tooltip": item.overHidden || false,
                            fixed: item.fixed,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    item.slot
                                      ? _vm._t(scope.column.property, null, {
                                          row: scope.row,
                                          $index: scope.$index,
                                        })
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "template",
                            { slot: "header" },
                            [
                              _vm._v(" " + _vm._s(item.label) + " "),
                              _c(
                                "el-tooltip",
                                { attrs: { placement: "bottom" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { width: "300px" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#fff",
                                                "margin-left": "10px",
                                                "line-height": "24px",
                                              },
                                            },
                                            [_vm._v(" 超出倒计时的处理方式 ")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#fff",
                                                "margin-left": "10px",
                                                "line-height": "24px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " 线上支付仅退款审核-自动退款; "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#fff",
                                                "margin-left": "10px",
                                                "line-height": "24px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " 线下支付仅退款审核-自动扣除对应金额的保证金; "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#fff",
                                                "margin-left": "10px",
                                                "line-height": "24px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " 线上/线下支付退款退货审核-自动审核通过; "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#fff",
                                                "margin-left": "10px",
                                                "line-height": "24px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " 线上/线下支付退货退款 确认收货-自动确认收货； "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#fff",
                                                "margin-left": "10px",
                                                "line-height": "24px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " 线下支付退货退款/仅退款 提交打款凭证-自动扣除对应金额的保证金; "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("span", [
                                    _c("i", { class: `el-icon-warning` }),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          }),
          _vm.operation
            ? _c("el-table-column", {
                attrs: { label: "操作", align: "left" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._t("menu", null, {
                            row: scope.row,
                            $index: scope.$index,
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
          _c(
            "template",
            { slot: "empty" },
            [
              _vm.tableData.length == 0
                ? _c("el-empty", { attrs: { description: "" } })
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _c("Pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.page.total > 0 && _vm.showpage,
            expression: "page.total > 0 && showpage",
          },
        ],
        attrs: {
          total: _vm.page.total,
          "page-sizes": _vm.page.pageSizes,
          page: _vm.page.current,
          limit: _vm.page.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.page, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.page, "size", $event)
          },
          pagination: _vm.pageChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }