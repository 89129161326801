<!-- 控销-商品管理 -->
<template>
  <div class="classification">
    <div class="header"> 
      <el-form ref="formTool" :model="formTool" :inline="true" style="text-align: left">
        <!-- <el-form-item prop="id" label="">
          <el-input v-model="formTool.id" placeholder="请输入商品ID" clearable size="small" />
        </el-form-item>
        <el-form-item prop="goodsCode" label="">
          <el-input v-model="formTool.goodsCode" placeholder="请输入商品ERP编码" clearable size="small" />
        </el-form-item> -->
        <el-form-item prop="goodsName" label="">
          <el-input v-model="formTool.goodsName" placeholder="请输入商品名称" clearable size="small" />
        </el-form-item>
        <el-form-item prop="factory" label="">
          <el-input v-model="formTool.factory" placeholder="请输入生产厂家" clearable size="small" />
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="reset()">重置</el-button>
        </el-form-item>

      </el-form>
      <el-button size="small" type="primary" @click="detailHandle({}, 'add')">新增推广商品</el-button>
    </div>

    <div class="center" style="position: relative">
      <MyTable :table-data="tableData" :index="index" :selection="selection" :page="page" :operation="operation"
        :table-option.sync="tableOption" @page-change="getList" @handleSelectionChange="handleSelectionChange"> 
        <template slot="image" slot-scope="scope">
          <el-row>
            <el-col style="width:100px">
              <el-image :src="scope.row.image
                ? scope.row.image
                : avater
                " lazy style="width: 100px; height: 100px" />
            </el-col>
            <el-col   style="width:calc(100% - 110px);margin-left:10px">

              <el-row> {{ scope.row.name }} </el-row> 
              <el-row>
                {{ scope.row.factory }}
              </el-row>
              <el-row>
                {{ scope.row.specification }}
              </el-row>
              <el-row>
                {{ scope.row.approvalNumber }}
              </el-row>
              <el-row>
                商品编码： {{ scope.row.goodsCode }}
              </el-row>
              
            </el-col>
          </el-row>
        </template>

        <!-- salesUserAmount  -->
        <template slot="salesUserAmount" slot-scope="scope">
          {{ scope.row.salesUserAmount  }} ({{ scope.row.applyUserAmount }})
        </template>

        <template slot="opration" slot-scope="scope">
          <div class="btn-warp">
            <el-link :underline="false" type="primary" @click="detailHandle(scope.row, 'detail')" v-if="scope.row.status == 1 "
              class="btn-list">详情</el-link>
            <el-link :underline="false" type="primary" @click="detailHandle(scope.row, 'edit')" v-if="scope.row.status == 1 "
              class="btn-list">编辑</el-link> 
              <el-link :underline="false" type="primary" @click="detailHandle(scope.row, 'reset')" v-if="scope.row.status == 2 "
              class="btn-list">重新提交</el-link> 
          </div>
        </template>

        <template slot="status" slot-scope="scope"> 
          <span style="color:#E6A23C" v-if="scope.row.status == 0"><i class="el-icon-success" ></i>  待审核</span> 
          <span style="color:#67C23A"  v-if="scope.row.status == 1 "><i class="el-icon-warning"></i>  审核通过</span>  
          <span style="color:#F5222d"  v-if="scope.row.status == 2 "><i class="el-icon-warning"></i> 审核驳回</span>  
        </template> 

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 50]" layout="sizes, prev, pager, next" :total="page.total">
        </el-pagination>

      </MyTable>
    </div>
    <goodsDialog :dialogShow="dialogShow" :btnType="btnType" ref="goods-dailog" @didSelectItem="didSelectItem" />
    <goodsSettingDialog ref="goods-setting-dailog" @getList="getList" />
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import goodsDialog from "./components/controlSalesGoodsDialog.vue";
import goodsSettingDialog from "./components/settingGoodsDialog.vue";
import { mapGetters } from 'vuex'

import {
  getSalesRelation,
} from "@/api/product";
 
import avater from "/public/img/default.png";

export default {
  components: { MyTable, goodsDialog, goodsSettingDialog },
  data() {
    return {
      avater: avater,
      formTool: {
        goodsName: "",
      },
      btnType: 'add',
      selectionList: [],
      operation: false,
      selection: false,
      index: true,
      tableData: [],
      tableOption: [
        { label: "商品信息", prop: "image", slot: true, minWidth: '230px' }, // 这里表示自定义列
        { label: "业务人员（申请合作）", prop: "salesUserAmount", slot: true,width:180 },
        { label: "关联部门", prop: "deptName" },
        { label: "审核状态", prop: "status", slot: true},
        { label: "商品名称", prop: "name" }, 
        { label: "操作", prop: "opration", slot: true, align: "center"},
      ],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      dialogShow: false
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getSalesRelation();
    // this.getRegionAreaTree();
  },
  mounted() { },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
  computed: {
    ...mapGetters(['permissions']),
  },
  methods: {
    didSelectItem(row) {
      // 选择商品
      this.$refs['goods-setting-dailog'].init(row, 'add')
    },
    // 页码变化
    handleCurrentChange(e) {
      this.page.current = e
      this.getList();
    },
    // 条数变化
    handleSizeChange(e) {
      this.page.size = e
      this.page.current = 1
      this.getList();
    },

    detailHandle(row, btnType) {
      if(btnType =='reset'){
        this.$refs['goods-setting-dailog'].init(row, 'reset')  
      }

      if(btnType =='add'){
        this.$refs['goods-dailog'].init(row, btnType) 
      }

      if(btnType =='edit' || btnType =='detail' ){
        this.$router.push('./salesControl-rules-index?pageType='+ btnType +'&goodsId=' +row.id +'&deptId=' + row.deptId  +'&shopId=' + row.shopId  )
      }
      
      // else{
      //   // this.$refs['goods-setting-dailog'].init(row, btnType) 
      // }
    },
    // 重置查询条件
    reset() {
      this.formTool = {};
      this.getList(1);
    },
    // 搜索
    getList(current) {
      this.getSalesRelation(current);
    },
    // 勾选
    handleSelectionChange(val) {
      this.selectionList = val;
    },
    // 查询 部门负责人查询控销商品列表
    getSalesRelation(current) {
      if (current) {
        // 搜索条件改变从第一页开始查询
        this.page.current = current;
      }
      if (this.formTool.name == "") {
        delete this.formTool.name;
      }
      const params = {
        ...this.formTool,
        current: this.page.current,
        size: this.page.size 
      };
      getSalesRelation(params).then((res) => {
        this.tableData = res.records;
        this.page.total = res.total;
      });
    }, 
  },
};
</script>
<style lang='scss' scoped>
@import "@/styles/variables.scss";

.classification {
  padding: 0px;

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .top {
    display: flex;
    padding: 10px 0 10px 0;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .label {
        width: 120px;
      }

      .button {
        margin-right: 20px;

        ::v-deep .el-button {
          color: $subMenuActiveText;
          background-color: $butColor;
          border-color: $butColor;

          &:hover {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:active {
            color: $subMenuActiveText;
            background: $butColor;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:focus {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
        }
      }
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn-warp {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    justify-content: flex-start;

    .el-link {
      margin: 5px 0px;
    }
  }
}
</style>
