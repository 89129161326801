<template>
  <div class="qualification-step-root">
    <StepView :current-index="localCurrentIndex" :step-list="stepList" />
    <section class="content">
      <template v-if="localCurrentIndex === 1">
        <CompanyTypeView @next-step="handleNextStep" />
      </template>
      <template v-else-if="localCurrentIndex === 2">
        <InfoDetailView @pre-step="handlePreStep" @submit="handleSubmit" />
      </template>
      <template v-else-if="localCurrentIndex === 3">
        <StatusView @change-info="handleChangeInfo" />
      </template>
    </section>
  </div>
</template>

<script>
import StepView from "./children/step";
import CompanyTypeView from "./children/companyType";
import InfoDetailView from "./children/infoDetail";
import StatusView from "./children/status";

import _ from "lodash";

export default {
  components: { StepView, CompanyTypeView, InfoDetailView, StatusView },
  props: {
    currentIndex: {
      type: Number,
      default: () => 1,
    },
  },
  data() {
    return {
      stepList: [
        { name: "企业类型" },
        { name: "信息上传" },
        { name: "等待审核" },
      ],
      localCurrentIndex: 1,
    };
  },
  watch: {
    currentIndex: {
      handler() {
        this.localCurrentIndex = this.currentIndex;
      },
      immediate: true,
    },
  },
 
  methods: {
    handleNextStep(selectedCompanyTypes) {
      //选择完类型,进入到资质信息填写页面
      this.$store.dispatch(
        "qualification/setSelectedCompanyTypes",
        _.cloneDeep(selectedCompanyTypes)
      );

      //判断类型是不是和详情的类型一致,不一致，情况缓存
      if (this.$store.state.qualification.detail) {
        const detail = this.$store.state.qualification.detail;

        const typeCode = selectedCompanyTypes[0].typeCode;
        if (detail.shopTypeCode != typeCode) {
          this.$store.dispatch("qualification/setDetail", null);
        }
      }

      this.localCurrentIndex = 2;
    },
    handlePreStep() {
      //公司类型选择
      this.localCurrentIndex = 1;
    },
    handleSubmit() {
      //提交,成功后，进入到等待审核状态
      this.localCurrentIndex = 3;
    },
    handleChangeInfo() {
      //修改
      this.localCurrentIndex = 2;
    },
  },
};
</script>

<style scoped lang="scss">
.qualification-step-root {
  padding-top: 48px;
  padding-bottom: 40px;
  box-sizing: border-box;

  .content {
    padding: 0;
    box-sizing: border-box;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 1012px; //固定
  }
}
</style>
