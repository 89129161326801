<template>
  <div>
    <el-upload
      class="upload-demo"
      :action="action"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      multiple
      :limit="limit"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :on-success="onSuccessUpload"
      :disabled="isEdit"
    >
      <el-button size="small" type="primary" :disabled="isEdit">点击上传</el-button>
      <!-- <div slot="tip" class="el-upload__tip">
       
      </div> -->
    </el-upload>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";

export default {
  name: "ImgUpload",
  components: {},
  props: {
    // 图片数据(图片url组成的数组) 通过v-model传递
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    isEdit: {
      // 是否能编辑
      type: Boolean,
      default: false,
    },
    // 限制上传的图片数量
    limit: {
      type: Number,
      default: 99,
    },
    // 限制上传图片的文件大小(kb)
    size: {
      type: Number,
      default: 500,
    },
    // 是否是单图上传(单图上传就是已传图片和上传按钮重叠)
    isSingle: {
      type: Boolean,
      default: false,
    },
    // 图片显示的宽度(px)
    width: {
      type: Number,
      default: 100,
    },
    // 图片显示的高度(px)
    height: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      // action: "https://gateway.cbyyk.com/product/goodsbase/upload",
      action: process.env.VUE_APP_BASE_API + '/product/goodsbase/upload',
      fileLength: 0, // 记录多张上传的状态
      isUploading: false, // 正在上传状态
      isFirstMount: true, // 控制防止重复回显,
    };
  },

  computed: {
    headers: function () {
      return {
        Authorization: "Bearer " + getToken(),
      };
    },
    // 文件数组数据
    fileList: {
      get() {
        return this.value;
      },
      set(val) {
        if (val.length < this.fileList.length) {
          // 判断是删除文件时同步el-upload数据
          this.syncElUpload(val);
        }
        // 同步v-model
        this.$emit("input", val);
      },
    },
    // 控制达到最大限制时隐藏上传按钮
    isMaxHidden() {
      return this.fileList.length >= this.limit;
    },
  },
  watch: {},
  mounted() {},

  methods: {
    // 同步el-upload数据
    syncElUpload(val) {
      const fileList = val || this.fileList;
      this.$refs.uploadRef.uploadFiles = fileList.map((v, i) => {
        return {
          name: v,
          url: v,
          status: "success",
        };
      });
      this.isFirstMount = false;
    },
    handleRemove(file, fileList) { 
      if (file.response) {
        this.$emit("onRemoveHandler", file.response.data);
      } else {
        this.$emit("onRemoveHandler", file);
      }
    },
    handlePreview(file) {
      console.log(file);
      if(file.url){
        window.location.href = file.url;  
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    onSuccessUpload(res, file, fileList) {
      this.fileLength++;
      // 这里需要根据你自己的接口返回数据格式和层级来自行修改
      if (res.data) {
        // 判断接口上传成功
        if (this.fileList.length < this.limit) {
          // 未超限时，把接口返回的图片url地址添加到fileList
          let datas = res.data;
          datas.name = res.data.fileName;
          this.$emit("onSuccessUpload",datas);
          this.fileList.push(datas);
        }
      } else {
        // 判断接口上传失败
        this.syncElUpload();
        this.$refs.uploadRef.clearFiles();
        this.$message({ type: "error", message: res.msg });
      }
      if (this.fileLength === fileList.length) {
        this.isUploading = false;
      }
    },
    // 上传图片失败
    onError(err, file, fileList) {
      const data = JSON.parse(JSON.stringify(err));
      // eslint-disable-next-line eqeqeq
      if (data.status != 200) {
        this.fileLength = 0;
        this.$refs.uploadRef.clearFiles();
        this.$message.error("上传失败");
      }
      this.isUploading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload {
  width: 100%;
  height: 100%;
  text-align: left;
}

// 上传按钮
.uploadIcon {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #c0ccda;
  background-color: #fbfdff;
  border-radius: 6px;
  font-size: 20px;
  color: #999;

  .limitTxt,
  .uploading {
    position: absolute;
    bottom: 10%;
    left: 0;
    width: 100%;
    font-size: 14px;
    text-align: center;
  }
}

// 拖拽
.vue-draggable {
  display: flex;
  flex-wrap: wrap;

  .draggable-item {
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #ddd;
    border-radius: 6px;
    position: relative;
    overflow: hidden;

    .el-image {
      width: 100%;
      height: 100%;
    }
    .shadow {
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity 0.3s;
      color: #fff;
      font-size: 20px;
      line-height: 20px;
      padding: 2px;
      cursor: pointer;
    }
    &:hover {
      .shadow {
        opacity: 1;
      }
    }
  }
  &.hideShadow {
    .shadow {
      display: none;
    }
  }
  &.single {
    overflow: hidden;
    position: relative;

    .draggable-item {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
  &.maxHidden {
    .uploadBox {
      display: none;
    }
  }
}
// el-image
.el-image-viewer__wrapper {
  .el-image-viewer__mask {
    opacity: 0.8;
  }
  .el-icon-circle-close {
    color: #fff;
  }
}
</style>
