var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "com_type",
      class: {
        success: _vm.type == "ALREADY" || _vm.type == "2",
        err: _vm.type == "FAIL" || _vm.type == "1",
      },
    },
    [_vm._v(" " + _vm._s(_vm.typeText || "待审核") + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }