import { render, staticRenderFns } from "./SelectType.vue?vue&type=template&id=2b2b0742&scoped=true"
import script from "./SelectType.vue?vue&type=script&lang=js"
export * from "./SelectType.vue?vue&type=script&lang=js"
import style0 from "./SelectType.vue?vue&type=style&index=0&id=2b2b0742&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b2b0742",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/godata/pipelines/eShop-Portal-Web-Release-Prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b2b0742')) {
      api.createRecord('2b2b0742', component.options)
    } else {
      api.reload('2b2b0742', component.options)
    }
    module.hot.accept("./SelectType.vue?vue&type=template&id=2b2b0742&scoped=true", function () {
      api.rerender('2b2b0742', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/salesControl/publish/children/SelectType.vue"
export default component.exports