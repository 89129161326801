var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tableMy" },
    [
      _c("MyTable", {
        attrs: {
          "table-data": _vm.tableData,
          index: _vm.index,
          selection: _vm.selection,
          page: _vm.page,
          operation: _vm.operation,
          "table-option": _vm.tableOption,
          reserveSelection: true,
        },
        on: {
          "update:tableOption": function ($event) {
            _vm.tableOption = $event
          },
          "update:table-option": function ($event) {
            _vm.tableOption = $event
          },
          "page-change": _vm.getList,
          handleSelectionChange: _vm.handleSelectionChange,
        },
        scopedSlots: _vm._u([
          {
            key: "information",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "left",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "imageP", staticStyle: { width: "100%" } },
                      [
                        _vm.activeName != "BASE_AWAIT" &&
                        _vm.activeName != "BASE_ALREADY" &&
                        _vm.activeName != "BASE_TEMPORARY_FAIL"
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-weight": "600",
                                  color: "#262626",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.goodsSkuTitle || "-") +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.activeName == "BASE_AWAIT" ||
                        _vm.activeName == "BASE_ALREADY" ||
                        _vm.activeName == "BASE_TEMPORARY_FAIL"
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-weight": "600",
                                  color: "#262626",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(scope.row.name || "-") + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "600",
                              color: "#262626",
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.factory || "-") + " ")]
                        ),
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(scope.row.specification || "-") + " "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(scope.row.approvalNumber || "-") + " "
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "商品编码：" + _vm._s(scope.row.goodsCode || "-")
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "SKU编码：" + _vm._s(scope.row.skuCode || "-")
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "ERP编码：" + _vm._s(scope.row.medicineCode || "-")
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "image",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "column",
                      "align-items": "center",
                      "justify-content": "left",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          height: "100%px",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c("el-image", {
                          staticStyle: { width: "80px", height: "80px" },
                          attrs: {
                            src:
                              scope.row.goodsSkuImage ||
                              scope.row.image ||
                              _vm.avater,
                            "preview-src-list": [
                              scope.row.goodsSkuImage ||
                                scope.row.image ||
                                _vm.avater,
                            ],
                            lazy: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "imageP",
                        staticStyle: {
                          width: "100%",
                          display: "flex",
                          "flex-direction": "column",
                          "align-items": "center",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c("p", [_vm._v(" " + _vm._s(scope.row.name) + " ")]),
                        _c("p", [
                          _vm._v("排序：" + _vm._s(scope.row.sortCode || "-")),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "price",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "price-cell",
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "left",
                    },
                  },
                  [
                    _c("div", { staticClass: "imageP" }, [
                      _vm.activeName != "BASE_AWAIT" &&
                      _vm.activeName != "BASE_ALREADY" &&
                      _vm.activeName != "BASE_TEMPORARY_FAIL"
                        ? _c(
                            "p",
                            { staticClass: "price-edit-select" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "", size: "small" },
                                  on: {
                                    change: (val) =>
                                      _vm.handleChangeForSynPrice(
                                        scope.$index,
                                        val
                                      ),
                                  },
                                  model: {
                                    value: scope.row.beSynPrice,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "beSynPrice", $$v)
                                    },
                                    expression: "scope.row.beSynPrice",
                                  },
                                },
                                _vm._l(_vm.priceChangeFlags, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                      disabled: item.disabled,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("p", [
                        _vm._v("价格：￥" + _vm._s(scope.row.salePrice || "-")),
                      ]),
                      _c("p", [
                        _vm._v(
                          "市场价：￥" + _vm._s(scope.row.marketPrice || "-")
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "建议零售价：￥" +
                            _vm._s(scope.row.purchasePrice || "-")
                        ),
                      ]),
                    ]),
                    !scope.row.beSynPrice &&
                    _vm.activeName != "BASE_AWAIT" &&
                    _vm.activeName != "BASE_ALREADY" &&
                    _vm.activeName != "BASE_TEMPORARY_FAIL" &&
                    scope.row.activityType == 1
                      ? _c("el-button", {
                          staticClass: "edit-btn",
                          attrs: { type: "text", icon: "el-icon-edit-outline" },
                          on: {
                            click: function ($event) {
                              return _vm.hanldeEditPrice(scope.$index)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "inventory",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "price-cell",
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "left",
                    },
                  },
                  [
                    _c("div", { staticClass: "imageP" }, [
                      _vm.activeName != "BASE_AWAIT" &&
                      _vm.activeName != "BASE_ALREADY" &&
                      _vm.activeName != "BASE_TEMPORARY_FAIL"
                        ? _c(
                            "p",
                            { staticClass: "price-edit-select" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "", size: "small" },
                                  on: {
                                    change: (val) =>
                                      _vm.handleChangeForSynStock(
                                        scope.$index,
                                        val
                                      ),
                                  },
                                  model: {
                                    value: scope.row.beSynStock,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "beSynStock", $$v)
                                    },
                                    expression: "scope.row.beSynStock",
                                  },
                                },
                                _vm._l(
                                  _vm.priceChangeStockFlags,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                        disabled: item.disabled,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("p", [
                        _vm._v(
                          " 总库存：" +
                            _vm._s(scope.row.goodsSkuInventory || "0") +
                            " "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "有效期至：" + _vm._s(scope.row.expireTime || "-")
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "生产日期：" + _vm._s(scope.row.producedTime || "-")
                        ),
                      ]),
                      _c("p", { staticStyle: { color: "green" } }, [
                        _vm._v(
                          " ERP同步 :" +
                            _vm._s(scope.row.erpSyncount || "-") +
                            " "
                        ),
                      ]),
                    ]),
                    !scope.row.beSynStock &&
                    _vm.activeName != "BASE_AWAIT" &&
                    _vm.activeName != "BASE_ALREADY" &&
                    _vm.activeName != "BASE_TEMPORARY_FAIL"
                      ? _c("el-button", {
                          staticClass: "edit-btn",
                          attrs: { type: "text", icon: "el-icon-edit-outline" },
                          on: {
                            click: function ($event) {
                              return _vm.hanldeEditStock(scope.$index)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "areaSchemeName",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "left",
                    },
                  },
                  [
                    _c("div", { staticClass: "imageP" }, [
                      scope.row.type == 0
                        ? _c("p", [
                            _vm._v(
                              "区域：" + _vm._s(scope.row.areaSchemeName || "-")
                            ),
                          ])
                        : _vm._e(),
                      _c("p", [
                        _vm._v(
                          " 客户类型：" +
                            _vm._s(_vm.getShopTypeStr(scope.row.shopTypeCode)) +
                            " "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              `${scope.row.type == 0 ? "黑名单" : "白名单"}`
                            ) +
                            "：" +
                            _vm._s(scope.row.groupSchemeName || "") +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "status",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "left",
                    },
                  },
                  [
                    _c("div", { staticClass: "imageP" }, [
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.getStatus(scope.row.status)) + " "
                        ),
                      ]),
                      _c("p", [_vm._v(" " + _vm._s(scope.row.updateBy) + " ")]),
                      _c("p", [
                        _vm._v(" " + _vm._s(scope.row.updateTime) + " "),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "saleSite",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "left",
                    },
                  },
                  [
                    _c("div", { staticClass: "imageP" }, [
                      _c("p", [
                        _vm._v(
                          "采购店数：" + _vm._s(scope.row.purchaseShopCount)
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "采购数量：" + _vm._s(scope.row.purchaseGoodsCount)
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "采购金额（元）：" + _vm._s(scope.row.countMoney)
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "remarks",
            fn: function (scope) {
              return [
                _c("div", {}, [
                  _c("p", [
                    _vm._v(
                      "活动类型：" +
                        _vm._s(_vm.showActivityName(scope.row.activityType))
                    ),
                  ]),
                ]),
                scope.row.beGift
                  ? _c("div", { staticClass: "gift-tag" }, [
                      _c("p", [_vm._v(" 已参与满赠活动")]),
                    ])
                  : _vm._e(),
                _c("div", {}, [
                  scope.row.affiliationArea
                    ? _c("p", [
                        _vm._v(
                          "归属组织：" + _vm._s(scope.row.affiliationArea) + " "
                        ),
                      ])
                    : _vm._e(),
                  scope.row.affiliationClinic
                    ? _c("p", [
                        _vm._v(
                          "归属诊所：" +
                            _vm._s(scope.row.affiliationClinic) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            },
          },
          {
            key: "caozuo",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  { staticClass: "tabl-op-col" },
                  [
                    _vm.activeName == "MERCHANT_REMOVAL"
                      ? _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.listing(scope.row)
                              },
                            },
                          },
                          [_vm._v("上架")]
                        )
                      : _vm._e(),
                    _vm.activeName == "ON_SALE"
                      ? _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.delist(scope.row)
                              },
                            },
                          },
                          [_vm._v("下架")]
                        )
                      : _vm._e(),
                    _vm.activeName != "BASE_AWAIT" &&
                    _vm.activeName != "BASE_ALREADY"
                      ? _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.detailHandle(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        )
                      : _vm._e(),
                    _vm.activeName != "BASE_AWAIT" &&
                    _vm.activeName != "BASE_ALREADY"
                      ? _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.editHandle(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.activeName != "BASE_AWAIT" &&
                    _vm.activeName != "BASE_ALREADY" &&
                    _vm.activeName != "BASE_TEMPORARY_FAIL" &&
                    scope.row.activityType != "4"
                      ? _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.fullGit(scope.row)
                              },
                            },
                          },
                          [_vm._v("单品送赠品")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _vm.activeName != "BASE_AWAIT" &&
                    _vm.activeName != "BASE_ALREADY" &&
                    _vm.activeName != "BASE_TEMPORARY_FAIL"
                      ? _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.logs(scope.row)
                              },
                            },
                          },
                          [_vm._v("日志")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }