//  allTypes_1 => shopTypeCode =1  商户类型
//  allTypes_2 => shopTypeCode =2
//  allTypes_3 => shopTypeCode =3
//  allTypes_4 => shopTypeCode =4

// 诊所 allTypes_1
export const shopTypeCodeArr_1 = [{
	code: "01",
	name: "营业执照",
	require: false,
	images:[] 
}, {
	code: "04",
	name: "医疗机构许可证",
	require: true,
	images:[] 
},

{
	code: "05",
	name: "被委托人身份证复印件(正反两面)",
	require: true,
	images:[] 
},
{
	code: "06",
	name: "授权委托书",
	require: true,
	images:[] 
},
{
	code: "08",
	name: "开票信息",
	require: false,
	images:[] 
},
{
	code: "09",
	name: "其他",
	require: false,
	images:[] 
}
];
// 春播运营平台 allTypes_2
export const shopTypeCodeArr_2 = [{
	code: "01",
	name: "营业执照",
	require: true,
	images:[] 
}, {
	code: "03",
	name: "食品经营许可证",
	require: false,
	images:[] 
},
{
	code: "08",
	name: "开票信息",
	require: false,
	images:[] 
},
{
	code: "09",
	name: "其他",
	require: false,
	images:[] 
}
];

// 医药公司 allTypes_3
export const shopTypeCodeArr_3 = [{
	code: "01",
	name: "营业执照",
	require: true,
	images:[],
}, {
	code: "02",
	name: "药品经营许可证",
	require: true,
	images:[] 
}, {
	code: "03",
	name: "食品经营许可证",
	require: false,
	images:[] 
},
{
	code: "05",
	name: "被委托人身份证复印件(正反两面)",
	require: true,
	images:[] 
},
{
	code: "06",
	name: "授权委托书",
	require: true,
	images:[] 
},
{
	code: "07",
	name: "第二类医疗器械经营备案凭证",
	require: false,
	images:[] 
},
{
	code: "08",
	name: "开票信息",
	require: true,
	images:[] 
},
{
	code: "10",
	name: "质保协议",
	require: true,
	images:[] 
},
{
	code: "09",
	name: "其他",
	require: false,
	images:[] 
}
];

// 生产企业 allTypes_4
export const shopTypeCodeArr_4 = [{
	code: "01",
	name: "营业执照",
	require: true,
	images:[] 
}, {
	code: "02",
	name: "药品经营许可证",
	require: true,
	images:[] 
}, {
	code: "03",
	name: "食品经营许可证",
	require: false,
	images:[] 
},
{
	code: "05",
	name: "被委托人身份证复印件(正反两面)",
	require: true,
	images:[] 
},
{
	code: "06",
	name: "授权委托书",
	require: true,
	images:[] 
},
{
	code: "07",
	name: "第二类医疗器械经营备案凭证",
	require: false,
	images:[] 
},
{
	code: "09",
	name: "其他",
	require: false,
	images:[] 
}
];



// allTypes_All （所有类型数据值，不直接调用）
export const allTypes_All = [{
	code: "01",
	name: "营业执照",
	require: false
}, {
	code: "02",
	name: "药品经营许可证",
	require: false
}, {
	code: "03",
	name: "食品经营许可证",
	require: false
}, {
	code: "04",
	name: "医疗机构许可证",
	require: false
},
{
	code: "05",
	name: "被委托人身份证复印件(正反两面)",
	require: false
},
{
	code: "06",
	name: "授权委托书",
	require: false
},
{
	code: "07",
	name: "第二类医疗器械经营备案凭证",
	require: false
},
{
	code: "08",
	name: "开票信息",
	require: false
},
{
	code: "10",
	name: "质保协议",
	require: false
},
{
	code: "09",
	name: "其他",
	require: false
}

];

// 为了兼容老用户数据，保持所有数据一致。把用户数据在静态数据中没有的补充进去
export function shopTypeCodeFilter(staticData, oldData, licenceNo, shopId) {
	// console.log(staticData, 'staticData');
	// console.log(oldData);
	let newData = [...staticData];
	// 初始化静态数据
 
	for (let i = 0; i < newData.length; i++) {
		// newData[i].images = [
		// 	{
		// 		certityImage: '',
		// 		certityType: newData[i].code,
		// 		id: null
		// 	}
		// ];
		newData[i].images = [];
		newData[i].startValidDate = null;
		newData[i].endValidDate = null;
		newData[i].idNumber = null;
		newData[i].licenceNo = null;
		newData[i].date = ['123']
		newData[i].shopId = shopId;
		if (newData[i].code == "04") {
			newData[i].licenceNo = licenceNo;
		}
	}
	// console.log(newData, 'newData123')
	// console.log(oldData, 'oldData111')
	// 把用户数据在静态数据中有相同的code替换静态数据
	for (let i = 0; i < newData.length; i++) {
		if (oldData.length > 0) {
			oldData.map((item) => {
				if (item['code'] == newData[i].code) {
					if(item['images'].length==0){
						// item['images'] = [{
						// 	certityImage: '',
						// 	certityType: newData[i].code,
						// 	id: null
						// }]
						item['images'] = []
					}
					item.require = newData[i].require;
					item.name = newData[i].name;
					newData[i] = item;
				}
			})
		}
	}
	return newData
	console.log(newData, 'newData')
	// console.log(oldData,'oldData')
}