<template>
  <div class="detail comContent">
    <div class="tit">{{ pageTitle }}</div>
    <div class="form">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        :disabled="type == 2"
      >
        <el-form-item label="拼团活动名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            size="small"
            maxlength="50"
            placeholder="请输入活动名称"
            style="width: 30%"
          ></el-input>
        </el-form-item>
        <el-form-item label="拼团活动说明" prop="remark">
          <el-input
            type="textarea"
            placeholder="请输入活动说明"
            style="width: 30%"
            v-model="ruleForm.remark"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动开始时间" prop="date">
          <el-date-picker
            style="width: 30%"
            v-model="ruleForm.date"
            value-format="yyyy-MM-dd"
            type="daterange"
            size="small"
            range-separator="-"
            start-placeholder="拼团开始"
            end-placeholder="拼团结束"
          />
        </el-form-item>
        <el-form-item label="成团人数" prop="minMemberCount">
          <el-input
            v-model="ruleForm.minMemberCount"
            size="small"
            maxlength="10"
            placeholder="请输入成团人数"
            oninput="
                if (value.length > 8) {
                  value = value.slice(0, 8);
                }
                value = value.replace(/[^0-9]/g, '');
              "
            style="width: 30%"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否自动成团" prop="ignoreLimit">
          <el-radio-group v-model="ruleForm.ignoreLimit">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="text-align: left" v-if="!isDisabled">
          <el-button size="small" @click="cancel">取消</el-button>
          <el-button type="primary" size="small" @click="submitForm('ruleForm')"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
      <div style="text-align: left; margin-top: 10px; padding-left:120px" v-if="isDisabled">
        <el-button   type="primary" size="small" @click="cancel">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  addGroupBuy,
  editGroupBuy,
  groupBuyDetail,
} from "@/api/spellGroup/spellGroupActive";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        remark: "",
        date: [],
        minMemberCount: "",
        ignoreLimit: 0,
        shopId: "",
        shopName: "",
      },
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        remark: [
          { required: true, message: "请输入拼团活动说明", trigger: "blur" },
        ],
        date: [
          { required: true, message: "请选择活动时间", trigger: "change" },
        ],
        minMemberCount: [
          { required: true, message: "请输入成团人数", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    groupId: function () {
      return this.$route.query.id;
    },
    type: function () {
      return this.$route.query.type;
    },
    pageTitle: function () {
      return this.$route.query.type == 1
        ? "拼团活动_编辑"
        : this.$route.query.type == 2
        ? "拼团活动_基本信息"
        : "拼团活动_新增";
    },
    isDisabled: function () {
      return this.$route.query.type == 2 ? true : false;
    },
  },
  created() {
    if (this.groupId) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      groupBuyDetail({ id: this.groupId }).then((res) => {
        if (res) {
          this.ruleForm.name = res.name;
          this.ruleForm.remark = res.remark;
          this.ruleForm.minMemberCount = res.minMemberCount;
          this.ruleForm.ignoreLimit = res.ignoreLimit;
          this.ruleForm.shopName = res.shopName;
          this.ruleForm.shopId = res.shopId;
          this.ruleForm.date = [res.startDate, res.endDate];
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.filtterForm();
          if (this.type == 1) {
            data.id = this.groupId;
            this.editGroupFun(data);
          } else if (this.type == 2) {
          } else {
            this.addGroupFun(data);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancel() {
      this.$router.replace("/spellGroup/spellGroup-spellGroupList-index");
    },
    editGroupFun(data) {
      editGroupBuy(data).then((res) => {
        if (res) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.cancel();
        }
      });
    },
    addGroupFun(data) {
      addGroupBuy(data).then((res) => {
        if (res) {
          this.$message({
            message: "新增成功",
            type: "success",
          });
          this.cancel();
        }
      });
    },
    filtterForm() {
      let data = {};
      for (const key in this.ruleForm) {
        if (key != "date") {
          data[key] = this.ruleForm[key];
        }
      }
      data["startDate"] = this.ruleForm.date[0];
      data["endDate"] = this.ruleForm.date[1];
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  .tit {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #262626;
    // padding: 20px 0;
    line-height: 48px;
  }
}
::v-deep .el-range-editor.is-disabled {
  background-color:rgba(0, 0, 0, 0.04)!important;
  color: #8c8c8c!important;
  border: solid 1px rgba(0, 0, 0, 0.15);
}
::v-deep .el-range-editor.is-disabled input , ::v-deep .el-range-separator{
  color: #8c8c8c!important; 
  background:none!important;
}
::v-deep .el-range-editor.is-disabled input {
  background:none!important;
}

 
</style>
