<template>
  <div :class="classObj" class="app-wrapper">
    <div class="headerTopcont">
      <img class="logoImg" src="@/assets/logo-chunbo.png" width="100px" height="40px" alt="">
      <!-- <img class="dosce" src="@/assets/dosce.png" width="109px" height="15px" alt=""> -->
      <div class="headerCompany">
        <div class="companyName">{{ shopData ? shopData.shopName : '' }}</div>
        <span>店铺状态：{{ shopData ? shopStatus[shopData.shopStatus] : '' }}</span>
        <span class="erp">ERP对接：{{ shopData ? erpStatus[shopData.shopErpStatus] : '' }}</span>
      </div>
      <div class="right-menu">
        <div style="margin-right: 16px;color: white;">
          <span @click="tousu" style="cursor: pointer;
                font-size: 14px;position: relative;top: -1px;">投诉建议</span>
        </div>
        <el-dropdown class="avatar-container" trigger="click">
          <div class="avatar-wrapper">
            <!-- <img :src="avatar+'?imageView2/1/w/80/h/80'" class="user-avatar">
            <i class="el-icon-caret-bottom" /> -->
            <span class="user-avatar">{{ name }}</span>
            <i class="el-icon-arrow-down el-icon--right downIcon" />
          </div>
          <el-dropdown-menu slot="dropdown" class="user-dropdown">
            <el-dropdown-item @click.native="goInfo">
              <span style="display:block;">个人中心</span>
            </el-dropdown-item>
            <el-dropdown-item @click.native="logout">
              <span style="display:block;">退出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 店铺信息未设置完提示 -->
    <template>
      <div :class="(showTips || !shopCompleteStatus) ? 'tips' : 'tips_none'">
        <div class="text" v-if="!shopCompleteStatus">
          <i class="el-icon-warning" style="color: #F5A300;"></i>
          如需发布商品，请先完成店铺设置 <span @click="goShopManage" v-if="beMainDept">去设置》</span>
        </div>
        <div class="text" v-if="shopInfo && shopInfo.auditStatus == 'NOT_PASS'">
          <i class="el-icon-warning" style="color: #F5A300;"></i>店铺审核未通过，请修改后重新提交审核 <span
            @click="showRemark" v-if="beMainDept" >查看原因</span>
        </div>
        <div class="text" v-if="shopInfo && shopInfo.auditStatus == 'AWAIT'">
          <i class="el-icon-warning" style="color: #F5A300;"></i>店铺审核中，请耐心等待
        </div>
        <div class="text" v-if="shopInfo && shopInfo.auditStatus == 'PRESERVE'">
          <i class="el-icon-warning" style="color: #F5A300;"></i>店铺信息已保存为草稿
           <div v-if="beMainDept" @click="goShopManage" :class="!isShopSettingPage?'isShopSetting' :'notShopSetting' " >，请提交审核</div>
        </div>
      </div> 
      <!-- 子部门 --> 
      <div :class="deptStatus =='NOT_PASS' && !beMainDept ? 'tips' : 'tips_none'"> 
        <div class="text" >
          <i class="el-icon-warning" style="color: #F5A300;"></i>售后设置审核未通过，请修改后重新提交审核 <span
          @click="showAfterRemarkFn">查看原因</span>  
         </div> 
      </div> 
      <!-- 子部门 -->
      <div :class="deptStatus =='AWAIT' && !beMainDept ? 'tips' : 'tips_none'"> 
        <div class="text" >
          <i class="el-icon-warning" style="color: #F5A300;"></i>售后设置审核中，请耐心等待  
         </div> 
      </div> 
      
      <el-dialog title="店铺信息审核未通过原因" :visible.sync="showShopManageRemark" width="30%">
        <div style="height: 200px;overflow-y: auto;">
          <div v-if="shopInfo && shopInfo.auditStatus == 'NOT_PASS'">
            <div style="margin: 20px 0px 20px 20px;">
              <span style="color: 000;">{{ shopInfo.remark }}</span>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeRemark">关闭</el-button>
        </span>
      </el-dialog>
      <el-dialog title="驳回原因" :visible.sync="showAfterRemark" width="30%">
        <div style="height: 200px;overflow-y: auto;">
          <div style="margin: 20px 0px 20px 20px;">
            <span style="color: 000;">{{deptRemark}}</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeAfterRemark">我知道了</el-button>
          <el-button @click="goAfterInfo" type="primary">去修改</el-button>
        </span>
      </el-dialog>
    </template>
    <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <navbar />
      </div>
      <app-main />
    </div>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import variables from '@/styles/variables.scss'
import { mapGetters } from 'vuex'
import { getShopStatusAndERPStatus } from '@/api/user'
import { queryShopSettingsCompleteStatus, shopInformationInfoNew,queryDeptAfterSalesSettingsStatus } from '@/api/shop'
import { queryDeptShopInfoByLogUser } from "@/api/salesControl/commodity";

export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain
  },
  mixins: [ResizeMixin],
  provide() {
        return {
          reloadShopAll: this.getShopInfoStatus
        }
    },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar', 'name', 
    ]),
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    },
    variables() {
      return variables
    }, 
  },
  
  data() {
    return {
      shopStatus: {
        'normal': '正常',
        'freeze': '冻结',
      },
      erpStatus: {
        'unconnect': '未对接',
        'normal': '正常',
        'disconnected': '已断开',
      },
      shopData: null,
      showTips: false, //是否显示提示
      showShopManageRemark: false,//店铺信息审核未通过的提示
      showShopManageSetting: false,//店铺信息未设置完提示
      auditStatus: '',//店铺信息审核状态 
      shopCompleteStatus: false,//店铺信息是否设置完成
      shopInfo: {
        remark: '',//驳回原因
        auditStatus: '',//店铺信息审核状态
      },//店铺信息
      showAfterRemark: false,//售后设置驳回原因 弹窗
      deptStatus: null,//部门售后设置状态
      deptRemark: '',//部门售后设置驳回原因
      beMainDept:true,
      isShopSettingPage: false, // 是否是店铺设置页面
    }
  },
  created() {
    this.deptShopInfoByLogUser() 
    this.getStatus() 
    this.getShopInfoStatus()   // 查询店铺设置是否完成,店铺信息审核状态   
    this.$store.dispatch('user/getShopInfo') 
  },
  mounted() { 
    this.$store.dispatch('user/getShopInfo')
  },
  watch: {
    '$route': function () {
      this.getShopInfoStatus()
    },  
  },
  methods: {
        // 查询是否是主部门
   deptShopInfoByLogUser() {
      queryDeptShopInfoByLogUser().then(res => {
        if (res) {
          if (res.beMainDept) {
            this.beMainDept = true;
          } else {
            this.beMainDept = false;
          }
        }
      }).catch((err) => { })
    },
    // 跳转售后设置
    goAfterInfo() {
      this.showAfterRemark = false;
      this.$router.push('/salesControlMange/afterSalesSetting-independent')
    },
    // 关闭AfterRemark
    closeAfterRemark() {
      this.showAfterRemark = false;
    },
    //showAfterRemark
    showAfterRemarkFn() {
      this.showAfterRemark = true;
    },
    // 获取店铺信息填写状态
    getShopInfoStatus() {
      // console.log('获取店铺信息填写状态') 
      queryShopSettingsCompleteStatus().then(res => {
        if (res) {
          const allOnes = res.every(item => item.completeStatus == 1); 
          this.shopCompleteStatus = allOnes; 
          
        }
      })
      this.getShopInfoStatusAduit();// 获取店铺信息审核状态
      this.queryDeptAfterSalesSettingsStatusFn();// 查询挂靠部门售后设置状态
      let path = this.$route.path;
      if (path && path.indexOf('storeSettleIn-shopManage') > -1) {
        this.isShopSettingPage = true;
      }else{
        this.isShopSettingPage = false;
      }
    },
    // 获取部门信息设置状态
    queryDeptAfterSalesSettingsStatusFn() {
      queryDeptAfterSalesSettingsStatus().then(res => {
        if (res) {
          // 状态：AWAIT：待审核，NOT_PASS：审核未通过, NOT_SETTING： 未配置
          this.deptStatus = res.status; // 部门状态，没返回状态为null（非子部门或统一售后不返回此状态）
          this.deptRemark = res.checkReason; // 驳回原因， 
        }
      })
    },
    // 获取店铺信息审核状态
    getShopInfoStatusAduit() { 
      shopInformationInfoNew().then(res => {
        // console.log(res, '----res------')
        if (res) {  
          this.shopInfo.auditStatus = res.auditStatus;  
          this.shopInfo.remark = res.remark;
          if(res.auditStatus == 'NOT_PASS' || res.auditStatus == 'AWAIT' || res.auditStatus == 'PRESERVE'){
           this.showTips = true;
          }
          if(res.auditStatus == 'PASS'){
            this.showTips = false;
          }
        }
      })
    },
    // 查看店铺信息审核未通过原因
    showRemark() {
      this.showShopManageRemark = true;
    },
    // 关闭店铺信息审核未通过原因
    closeRemark() {
      this.showShopManageRemark = false;
    },
    // 跳转店铺设置
    goShopManage() {
      this.$router.push('/shopManage/storeSettleIn-shopManage')
    },
    async getStatus() {
      // 店铺状态 normal：正常 freeze：冻结
      // 店铺ERP状态 unconnect:未对接 normal：正常 disconnected：已断开
      const r = await getShopStatusAndERPStatus()
      if (r) {
        this.shopData = r
      }
    },
    tousu() {
      this.$router.push('/suggestion/suggestion-index')
    },
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    async logout() {
      this.$store.dispatch("qualification/setDetail", null);
      this.$store.dispatch("qualification/setSelectedCompanyTypes", null);
      localStorage.removeItem('hasRoles')

      if (window.localStorage.getItem('messageHome')) {
        let messageHomeSotre = JSON.parse(window.localStorage.getItem('messageHome'));
        messageHomeSotre.isNextRemind = false;
        window.localStorage.setItem('messageHome', JSON.stringify(messageHomeSotre))
      }

      await this.$store.dispatch('user/logout')
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      this.$router.push(`/login`)
    },
    async goInfo() {
      // await this.$store.dispatch('user/logout')
      this.$router.push(`/user/info`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  .headerTopcont {
    height: #{$headerHeight};
    background: #cf1422;

    .headerCompany {
      display: inline-block;
      vertical-align: top;
      height: 48px;
      /* line-height: 48px; */
      margin-left: 15px;

      .companyName {
        margin-top: 0px;
        height: 22px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #ffffff;
        line-height: 22px;
      }

      span {
        opacity: 0.8;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        line-height: 22px;
      }
    }

    .logoImg {
      margin: 3px 16px;
    }

    .dosce {
      margin: 11px 0px 17px;
    }
  }

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }

  .right-menu {
    display: flex;
    float: right;
    height: 100%;
    line-height: 48px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        position: relative;

        .user-avatar {
          cursor: pointer;
          color: #fff;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }

        .downIcon {
          cursor: pointer;
          color: #fff;
        }
      }
    }
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}

.erp {
  margin: 0px 20px;
}

.tips {
  width: 100%;
  height: 40px;
  background: #fff9e6;
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  margin: 0;

  span {
    color: #F5222D;
    cursor: pointer;
  }
}

.tips_none {
  //display: none;
  opacity: 0;
  width: 100%;
  height: 0px;
}
.isShopSetting{
  color: #F5222D;
  cursor: pointer;
  display: inline-block;
}
.notShopSetting{
  color: #F5222D;
  display: inline-block;
}
</style>
