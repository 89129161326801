var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table_li" }, [
    _c("div", { staticClass: "table_li_title" }, [
      _c("p", [_vm._v("订单日期：" + _vm._s(_vm.info.time))]),
      _c("p", [_vm._v("订单号：" + _vm._s(_vm.info.ordernum))]),
    ]),
    _c("div", { staticClass: "table_el" }, [
      _c(
        "table",
        { attrs: { cellspacing: "0", cellpadding: "8" } },
        [
          _vm._m(0),
          _vm._l(_vm.info.goods, function (li, j) {
            return _c("tr", { key: j }, [
              _c("td", [_c("div", [_vm._v(_vm._s(li.name))])]),
              _c("td", [_c("div", [_vm._v(_vm._s(li.price))])]),
              _c("td", [_c("div", [_vm._v("x" + _vm._s(li.num))])]),
              j == 0
                ? _c("td", { attrs: { rowspan: _vm.info.goods.length } }, [
                    _c("div", [_vm._v(_vm._s(_vm.info.pay))]),
                  ])
                : _vm._e(),
              j == 0
                ? _c("td", { attrs: { rowspan: _vm.info.goods.length } }, [
                    _c("div", [_vm._v(_vm._s(_vm.info.realypay))]),
                  ])
                : _vm._e(),
              j == 0
                ? _c("td", { attrs: { rowspan: _vm.info.goods.length } }, [
                    _c("div", [_vm._v(_vm._s(_vm.info.yun))]),
                  ])
                : _vm._e(),
              j == 0
                ? _c("td", { attrs: { rowspan: _vm.info.goods.length } }, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.elClick(1)
                          },
                        },
                      },
                      [_vm._v("操作按钮")]
                    ),
                  ])
                : _vm._e(),
            ])
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("商品")]),
      _c("th", [_vm._v("单价")]),
      _c("th", [_vm._v("数量")]),
      _c("th", [_vm._v("应付款")]),
      _c("th", [_vm._v("实付款")]),
      _c("th", [_vm._v("开票金额")]),
      _c("th", [_vm._v("交易操作")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }