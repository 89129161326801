<!--  -->
<template>
  <div class="classification">
    <div class="header">
      <div class="left_topic mb20" style="margin-top: 10px">控销规则</div>
      <div>
        <el-button size="small" type="primary" @click="settingBatchRate()"
          :disabled="pageType == 'detail'">批量设置比例</el-button>
        <el-button size="small" type="primary" @click="settingBatchTask()"
          :disabled="pageType == 'detail'">批量设置任务</el-button>
        <el-button size="small" type="plain" @click="back()">返回</el-button>
      </div>
    </div>

    <div class="center" style="position: relative">
      <el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" row-key="id" border @select-all="selectAll"
        @select="selectFun" ref="tableRef" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)" @selection-change="handleSelectionChange" type="selection"
        :key="tableKey"
        :lazy="true" :load="loadFn" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        v-loading="loading">
        <el-table-column type="selection" width="55" >
        </el-table-column>
        <el-table-column prop="rname" label="区域" sortable width="300">
          <template slot-scope="scope">
             {{ scope.row.rname }} 
             <!-- 省市区角标 -->
             <el-badge class="mark" :value="scope.row.applyAmount" style="margin-top: 0px;" v-if="scope.row.applyAmount && parseInt(scope.row.applyAmount) > 0" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="售价" sortable width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.minPrice"> {{ scope.row.minPrice }} ~ {{ scope.row.maxPrice }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="salesUserRate" label="业务员推广服务费比例" style="width: 150px;">
          <template slot-scope="scope">
            {{ accMul(scope.row.salesUserRate, 100) }} %
          </template>
        </el-table-column>
        <el-table-column prop="goodsScopeRate" label="平台技术服务费比例" style="width: 150px;">
          <template slot-scope="scope">
            {{ accMul(scope.row.goodsScopeRate, 100) }} %
          </template>
        </el-table-column>
        <el-table-column prop="extendServiceChargeScale" label="平台推广服务费比例" style="width: 150px;">
          <template slot-scope="scope">
            {{ accMul(scope.row.extendServiceChargeScale, 100) }} %
            <!-- 乘法保证精度计算 -->
            <!-- {{ (scope.row.extendServiceChargeScale * 100 ).toFixed(4) }}   -->
          </template>
        </el-table-column>
        <el-table-column prop="invitationNumber" label="业务员">
        </el-table-column>
        <el-table-column prop="probationDays" label="试用期（天）">
        </el-table-column>
        <el-table-column prop="probationAmount" label="试用期任务">
        </el-table-column>
        <el-table-column prop="actualAmount" label="总销量">
        </el-table-column>
        <el-table-column prop="cooperateStartTime" label="开始合作时间">
        </el-table-column>
        <el-table-column prop="cooperateDays" label="合作期（天）">
        </el-table-column>
        <el-table-column prop="post" label="操作" width="300">
          <template slot-scope="scope" v-if="(scope.row.level ==3 ) ">
            <el-link :underline="false" type="primary" @click="settingRatio(scope.row)" :disabled="pageType == 'detail'"
              class="btn-list" >设置比例</el-link>
              <!-- 没有合作的业务员时，不能更改试用期任务 -->
            <el-link :underline="false" type="primary" @click="settingTask(scope.row)" :disabled="pageType == 'detail'"
              class="btn-list" >设置任务</el-link>
            <el-link :underline="false" type="primary" @click="settingSalesman(scope.row)" 
              :disabled="pageType == 'detail'" class="btn-list">邀请合作</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <settingScale ref="setting-scale" @getList="getGoodsRuleList" />
    <settingTask ref="setting-task" @getList="getGoodsRuleList" :goodsUnit="goodsUnit"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getGoodsRuleList, getGoodsRuleListLevel } from "@/api/externalSalesRule";
import { getUnitFeeRate } from "@/api/product";
import { isJSONObject } from "@/utils"
import settingTask from "./components/settingTask.vue";
import settingScale from "./components/settingScale.vue";
import avater from "/public/img/default.png";
export default {
  components: { settingTask, settingScale },
  data() {
    return {
      activeName: 1,
      avater: avater,
      selectionList: [],
      operation: true,
      selection: false,
      index: true,
      tableData: [],
      areaList: [],
      pageType: '',
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
      dialogShow: false,
      loading: false,
      promoteRate: '',
      tableKey:0,
      goodsUnit:'',//商品单位
    };
  },
  computed: {},
  watch: {},
  created() {

  },
  mounted() {
    this.goodsId = this.$route.query.goodsId;
    this.deptId = this.$route.query.deptId;
    this.shopId = this.$route.query.shopId;
    this.pageType = this.$route.query.pageType;
    this.getUnitFeeRate();
    this.getGoodsRuleList();
  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
  computed: {
    ...mapGetters(['permissions']),
  },
  methods: {
    handleClick() { },
    accMul(arg1, arg2) {
      if (!arg1) return '0.00';
      var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
      try { m += s1.split(".")[1].length } catch (e) { }
      try { m += s2.split(".")[1].length } catch (e) { }
      var money = Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
      return money.toFixed(4)
    },
    // 设置业务员
    settingSalesman(row) {
      this.$router.push({
        path: "/salesControlMange/salesControl-rules-salesMan?areaCode=" + row.id + '&goodsId=' + this.goodsId + '&shopId=' + this.shopId,
        query: '',
      });
    },
    getUnitFeeRate() {
      getUnitFeeRate({ shopId: this.shopId, goodsId: this.goodsId }).then((res) => {
        if (res) {
          this.rate = res.rate;
          this.promoteRate = res.promoteRate;
          this.goodsUnit = res.goodsUnit;
        }
      }).catch(err => {
        if (err && err.msg) {
          this.$message.error(err.msg)
        }
      })
    },

    // 设置任务
    settingTask(row) {
      this.$refs['setting-task'].init([row])
    },
    settingBatchTask() {
      this.$refs['setting-task'].init(this.areaList)
    },
    settingBatchRate() {
      console.log(this.areaList, 'this.areaList')
      this.$refs['setting-scale'].init(this.areaList, this.rate, this.promoteRate)
    },
    settingRatio(row) {
      console.log(row, '---row')
      console.log(this.promoteRate,'---this.promoteRate')
      this.$refs['setting-scale'].init([row], this.rate,this.promoteRate)
    },

    back() {
      this.$router.push('./salesControl-goodsManage-index')
    },

    // 勾选
    handleSelectionChange(val) {
    
      this.areaList = val;
      this.selectionList = val;
      console.log(this.selectionList, '---this.selectionList')
      console.log(this.areaList, '---val')
      // this.selectChildren(this.selectionList)
    }, 

    toggleSelection(row, select) {
      if (row) {
        // 懒加载之后出现的数据，在 tableData 里面的没有的，

        // 懒加载取到的数据，若是不重新赋值给 tableData 对象，在 tableData 中是不会显示这部分数据的。

        // 最主要最主要的一点代码：

        // 将懒加载获取的数据，重新赋值给当前父节点的 children 字段。
        console.log(select, '---select')
        console.log(row, '---row')
        this.$nextTick(() => {
          this.$refs['tableRef'] &&
            this.$refs['tableRef'].toggleRowSelection(row, select)
        })
      }
    },
    // 选择全部
    selectAll(selection) {
      // tabledata第一层只要有在selection里面就是全选
      const selected = selection.some(el => {
        const tableDataIds = this.tableData.map(j => j.id)
        return tableDataIds.includes(el.id)
      })
      // tableDate第一层只要有不在selection里面就是全不选
      const isCancel = !this.tableData.every(el => {
        const selectIds = selection.map(j => j.id)
        return selectIds.includes(el.id)
      })
      if (selected) {
        selection.map(el => {
          if (el.children) {
            // 解决子组件没有被勾选到
            this.setChildren(el.children, true)
          }
        })
      }
      if (isCancel) {
        this.tableData.map(el => {
          if (el.children) {
            // 解决子组件没有被勾选到
            this.setChildren(el.children, false)
          }
        })
      }
    },
    setChildren(children, type) {
      // console.log(type, '---type')
      // 编辑多个子层级
      children.map(j => {
        this.toggleSelection(j, type)
        // console.log(j, 'jjjjj')
        j.selected = type;
        if (j.children) {
          this.setChildren(j.children, type)
        }
      })

    },
    selectFun(selection, row) {
      const hasSelect = selection.some(el => {
        return row.id === el.id
      })

      // console.log(hasSelect, '---hasSelect')
      // console.log(row, '---row')
      row.selected = hasSelect;
      if (hasSelect) {
        if (row.children) {
          // 解决子组件没有被勾选到 
          this.setChildren(row.children, true)
        }
      } else {
        if (row.children) {
          this.setChildren(row.children, false)
        }
      }
      this.selectParent(this.tableData, row, hasSelect)
    },


    selectParent(tableData, hasSelect) {
      for (let i = 0; i < tableData.length; i++) {
        // 循环层级 如果此级有下级，并且下级全部选中，那么此选中状态为true； 否则为false
        let selectedOne = false;
        if (tableData[i].children) {
          for (let j = 0; j < tableData[i].children.length; j++) {
            // 判断第2级，如果是点击的取消
            let twoSelect = false;
            if (tableData[i].children[j].children) {
              console.log(tableData[i].children[j].children)
              // 判断第2级是不是有一个选中
              if (tableData[i].children[j].children.findIndex(target => target.selected === true) == -1) {
                console.log('验证不通过')
                twoSelect = false;
              } else {
                console.log('验证通过')
                twoSelect = true;
                this.$refs['tableRef'].toggleRowSelection(tableData[i], true);
                // this.$refs['tableRef'].toggleRowSelection(tableData[i], true);
              }
              this.$refs['tableRef'].toggleRowSelection(tableData[i].children[j], twoSelect);
            }

            // 判断第1级是不是有一个选中
            if (tableData[i].children.findIndex(target => target.selected === true) == -1) {
              selectedOne = false;
              console.log('二级验证不通过')
            } else {
              selectedOne = true;
              console.log('二级验证通过')
            }

            if (!twoSelect && !selectedOne) {
              console.log('设置省级fasle')
              this.$refs['tableRef'].toggleRowSelection(tableData[i], false);
            } else {
              console.log('设置省级true')
              this.$refs['tableRef'].toggleRowSelection(tableData[i], true);
              
            } 
          }

        }
      }
    },

    async loadFn(tree, treeNode, resolve) {
        // console.log(this.treeNodeList,'--this.treeNodeList3')
      try {
          const res = await this.getGoodsRuleList(tree) 
          if (res && res.length) {
            resolve(this.treeNodeList)
          }
      } catch (error) {
        // reject()
      }  
    },


    // 获取推广规则列表
    getGoodsRuleList(tree) { 
     return  new Promise((resolve, reject) => {
              let data = {
                goodsId: this.goodsId,
                deptId: this.deptId,
                level: tree != undefined ? tree.level : 0,
                areaCode: tree != undefined ? tree.id : 0,
              };

              // 获取省一级 level :0，areaCode:0 , 
              //获取市 level :1 areaCode:省级areaCode ,
              //获取区 level :2, areaCode:市areaCode

              this.loading = true;
              getGoodsRuleListLevel(data).then((res) => {
                if (res) {
                  if (!tree) {
                    this.tableData =[];
                    this.tableData = res;
                    this.tableKey += 1;
                    this.loading = false;
                  } else {
                    this.treeNodeList = res;
                    tree.children = res;
                    this.loading = false;
                  }
                    resolve(this.treeNodeList)
                }
                setTimeout(() => {
                  this.loading = false;
                }, 500)
              }); 
       })
    },


    // selectAll(e) {
    //   console.log(e)
    // }

  },
};
</script>
<style lang='scss' scoped>
@import "@/styles/variables.scss";

.classification {
  padding: 0px;

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .top {
    display: flex;
    padding: 10px 0 10px 0;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .label {
        width: 120px;
      }

      .button {
        margin-right: 20px;

        ::v-deep .el-button {
          color: $subMenuActiveText;
          background-color: $butColor;
          border-color: $butColor;

          &:hover {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:active {
            color: $subMenuActiveText;
            background: $butColor;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:focus {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
        }
      }
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn-warp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;


  }
}

.btn-list {
  margin: 0 5px;
}
</style>
