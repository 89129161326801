<!-- 客户报表详情 -->
<template>
  <div class="comContent body-box">
    <div class="body-title">
      <div class="body-title-left">经济开发区XX诊所</div>
      <div class="body-title-right"><el-button size="small" @click="goBack">返回</el-button></div>
    </div>
    <div class="body-tools">
      <div class="body-tools-left">
        <el-form ref="formTool" :model="formTool" :inline="true">
          <el-form-item label="" prop="">
            <el-input v-model="formTool.name" size="small" placeholder="请输入客户姓名" clearable />
          </el-form-item>
          <el-form-item label="首次下单时间" prop="">
            <el-date-picker v-model="formTool.value1" size="small" type="daterange" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="最后下单时间" prop="">
            <el-date-picker v-model="formTool.value2" size="small" type="daterange" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="最后访问时间" prop="">
            <el-date-picker v-model="formTool.value3" size="small" type="daterange" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="" prop="phone">
            <MyButton type="primary" text="查询" @click="goSearch" />
            <el-button size="small" @click="formToolReset()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="body-tools-right">
        <!-- <MyButton type="primary" text="返回" @click="goBack" /> -->
      </div>
    </div>
    <div class="body-table">
      <MyTable :table-data="tableData"
        max-height="calc(100% - 44px)"
        :index="false"
        :selection="false"
        :page="page"
        :operation="false"
        :table-option.sync="tableOption"
        @page-change="getList"
        @sort-change="sortChange"
        @handleSelectionChange="currentChange">
        <template slot="type" slot-scope="scope">
          <span>{{ scope.row.type=='ios'?'苹果系统':'安卓系统' }}</span>
        </template>
        <template slot="tools" slot-scope="scope">
          <div style="display:flex">
            <el-link type="primary" :underline="false" size="small" @click="goLink('2',scope.row)">查看详情</el-link>
          </div>
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>

import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable';
export default {
  name:'ReportCustomerDetail',
  components: { MyButton, MyTable },
  data() {
    return {
      formTool: {
        value:'',
        value1:'',
        value2:'',
        value3:'',
        value4:'',
      },
      tableData: [{
        id: '12987126',
        name: '好滋好味鸡蛋仔',
        category: '江浙小吃、小吃零食',
        desc: '荷兰优质淡奶，奶香浓而不腻',
        address: '上海市普陀区真北路',
        shop: '王小虎夫妻店',
        shopId: '10333',
        version:'1.0.0'
      }],
      
      tableOption: [
        { label: '商户名称', prop: 'version', minWidth: '140px' },
        { label: '运营人员及联系方式', prop: 'version', minWidth: '140px' },
        { label: '订单量', prop: 'version', minWidth: '90px',sortable:'custom' },
        { label: '商品数量', prop: 'version', minWidth: '90px',sortable:'custom' },
        { label: '采购额', prop: 'version', minWidth: '90px',sortable:'custom' },
        { label: '客单价', prop: 'version', minWidth: '90px',sortable:'custom' },
        { label: '首次下单时间', slot: true, prop: 'type', width: '120px',sortable:'custom' },
        { label: '最后下单时间', prop: 'remark',sortable:'custom', width: '120px' },
        { label: '最后访问时间', prop: 'createTime',sortable:'custom', width: '120px' },
        { label: '操作', prop: 'tools', slot: true, width: '80px' }
      ],
      page: { total: 10, current: 1, size: 10 },
    };
  },
  computed: {},
  watch: {},
  created() {

  },
  mounted() {

  },
  methods: {
    goLink(type="1"){
      let url = type==1?'/reportCustomer/reportCustomer-reportDetail':'/reportCustomer/reportCustomer-reportDetailMore?id=2133&cid=23423'
      this.$router.push(url);
    },
    goSearch(){},
    sortChange(el){
      const { column, prop, order } = el; // order释义：descending:降,ascending:升,为空时代表不选中
      console.log(column, prop, order)
      // 执行条件搜索操作
    },
    formToolReset () {
      this.formTool = { username: '', fullName: '', phone: '' }
      // this.$refs.formTool.resetFields()
      this.page.current = 1
      // this.getList()
    },
    currentChange(){},
    pageChange (e) {
      console.log(e,this.page)
      // this.$emit('page-change')
    },
    initData(){
      this.page.current = 1;
      this.getList();
    },
    getList(){
      console.log('getList-重新加载数据')
    },
    goBack () {
      // console.log('go back');
      this.$router.push("/reportCustomer/reportCustomer-reportList");

      // this.$router.go(-1)：后退+刷新；
      // this.$router.go(0)：刷新；
      // this.$router.go(1) ：前进
      // this.$router.back(); //:后退
      // this.$router.back(0) 刷新；
      // this.$router.back(1)：前进
    },
  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
}
</script>
<style lang='scss' scoped>
.body{
  &-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid #eee;
    &-left{
      width: calc(100% - 90px);
      line-height: 1;
      font-size: 26px;
      font-weight: 600;
      color: #000;
    }
    &-right{
      width: 90px;
      text-align: right;
    }
  }
  &-tools{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 16px;
    &-left{
      width: calc(100% - 90px);
    }
    &-right{
      width: 90px;
      text-align: right;
    }
    ::v-deep .el-form{
      &-item{
        margin-bottom: 6px !important;
      }
    }
  }
  &-table{
    height: calc(100% - 130px);
  }
}
</style>
