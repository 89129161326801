var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goodsDetailDlg" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "搜索明细数据",
            visible: _vm.dialogVisible,
            width: "80%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "goods-info" }, [
            _c("div", { staticClass: "title" }, [_vm._v("关键词")]),
            _c("div", { staticClass: "info" }, [
              _vm._v(_vm._s(_vm.goodsDetail.name)),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "goods-detail" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("搜索明细")]),
              _c(
                "el-tabs",
                {
                  attrs: { size: "mini" },
                  on: { "tab-click": _vm.tabChange },
                  model: {
                    value: _vm.tabPosition,
                    callback: function ($$v) {
                      _vm.tabPosition = $$v
                    },
                    expression: "tabPosition",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "搜索趋势", name: "searchTrend" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "推荐SKU", name: "recommendSKU" },
                  }),
                ],
                1
              ),
              _vm.tabPosition == "searchTrend"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { size: "mini" },
                          on: { input: _vm.trendChange },
                          model: {
                            value: _vm.trendTabPosition,
                            callback: function ($$v) {
                              _vm.trendTabPosition = $$v
                            },
                            expression: "trendTabPosition",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "day" } }, [
                            _vm._v("当日趋势"),
                          ]),
                          _c("el-radio-button", { attrs: { label: "month" } }, [
                            _vm._v("最近30天趋势"),
                          ]),
                        ],
                        1
                      ),
                      _vm.chartOptions
                        ? _c("highcharts", {
                            attrs: { options: _vm.chartOptions },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tabPosition == "recommendSKU"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          ref: "formTool",
                          attrs: {
                            "label-width": "90px",
                            model: _vm.formTool,
                            size: "small",
                            inline: true,
                          },
                        },
                        [
                          _c(
                            "el-row",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: _vm.formTool.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formTool, "name", $$v)
                                      },
                                      expression: "formTool.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "yyyy-MM-dd",
                                      type: "daterange",
                                      size: "small",
                                      "range-separator": "-",
                                      "start-placeholder": "开始时间",
                                      "end-placeholder": "结束时间",
                                    },
                                    model: {
                                      value: _vm.formTool.date,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formTool, "date", $$v)
                                      },
                                      expression: "formTool.date",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "btn-box" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getList(1)
                                        },
                                      },
                                    },
                                    [_vm._v("查 询")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small" },
                                      on: { click: _vm.handleReset },
                                    },
                                    [_vm._v("重 置")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { float: "right" },
                                      attrs: { size: "small" },
                                      on: { click: _vm.handleExport },
                                    },
                                    [_vm._v("导出")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "center",
                          style: _vm.contentHeight
                            ? `height:${_vm.contentHeight}px!important`
                            : "",
                        },
                        [
                          _c("MyTable", {
                            attrs: {
                              "table-data": _vm.tableData,
                              index: _vm.index,
                              selection: _vm.selection,
                              page: _vm.page,
                              operation: _vm.operation,
                              "table-option": _vm.tableOption,
                            },
                            on: {
                              "update:tableOption": function ($event) {
                                _vm.tableOption = $event
                              },
                              "update:table-option": function ($event) {
                                _vm.tableOption = $event
                              },
                              "page-change": _vm.getList,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }