import request from "@/utils/request";
import { Message } from "element-ui";

//保证金---  submitCert 商户提交打款凭证
export function submitCert(data) {
    return request({
        url: "/shop/guarantee/merchant/submitCert",
        method: "post",
        data: data,
    });
}

//保证金--- 变动日志列表接口
export function userLogPage(data) {
    return request({
        url: "/payment/guarantee/merchant/userLogPage",
        method: "post",
        data: data,
    });
}

//保证金---  查询商户申请保证金列表
export function historyApprovePage(data) {
    return request({
        url: "/shop/guarantee/merchant/approvePage",
        method: "post",
        data: data,
    });
}

// 保证金详情接口
export function guaranteeInfo(data) {
    return request({
        url: "/payment/guarantee/merchant/info",
        method: "GET",
        params: data,
    });
}

// 保证金 审核详情接口
export function guaranteeApproveInfo(data) {
    return request({
        url: "/shop/guarantee/merchant/approve/info",
        method: "GET",
        params: data,
    });
}

// 获取打款凭证信息
export function getCertInfo(data) {
    return request({
        url: "/shop/guarantee/merchant/approve/getCertInfo",
        method: "GET",
        params: data,
    });
}

// 下载打款凭
// export function downloadCert(data) {
//   return request({
//     url: '/shop/guarantee/merchant/approve/downloadCert',
//     method: 'GET',
//     params:data
//   })
// }
 

export function fileExport(apiUrl,methodType,data) {
  return request({
      url: apiUrl,
      method:methodType?methodType: "GET",
      data,
      responseType: "blob",
  }).then((response) => {
      // 处理返回的文件流
      const blob = response.data;
      if (blob && blob.size === 0) {
          Message.warning("内容为空，无法下载");
          return;
      }
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      const contentDisposition = response.headers["content-disposition"];
      const fileName = decodeURIComponent(
          contentDisposition.substring(contentDisposition.indexOf("=") + 1)
      );
      // console.log(fileName)
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      window.setTimeout(function () {
          URL.revokeObjectURL(blob);
          document.body.removeChild(link);
          Message.success("导出成功！");
      }, 0);
  });
}


