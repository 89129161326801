import request from '@/utils/request'

// 查询申请开票记录 
export function comapplyinvoiceinfoPage(data) {
  return request({
    url: `/invoice/compliance/comapplyinvoiceinfo/seller/page`,
    method: 'get',
    params: data
  })
}

// 查看详情 
export function comapplyinvoiceinfoDetail(data) {
  return request({
    url: `/invoice/compliance/comapplyinvoiceinfo/${data.id}`,
    method: 'get',
    params:data
  })
}

// 上传票据
export function uploading(data) {
  return request({
    url: `/invoice/compliance/comapplyinvoiceinfo/uploading`,
    method: 'POST',
    data
  })
}

// 发票下载
export function downloadfapiao(data) {
  return request({
    url: `/invoice/compliance/comapplyinvoiceinfo/download/${data}`,
    method: 'get',
    responseType: 'blob'
  }).then(response => {
    // 处理返回的文件流
    const blob = response.data
    if (blob && blob.size === 0) {
      Message.warning('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    const contentDisposition = response.headers['content-disposition'];
    const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1));
    console.log(fileName)
    link.download = fileName;
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  })
}