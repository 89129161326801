<template>
  <div>
    <el-dialog
      title="上传开票"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <div class="header">
        <div class="left_topic ">发票抬头信息</div>
      </div>
      <div class="content">
        <el-row class="list-t">
          <el-col :span="12">发票抬头：{{ invoiceTitle.sellerName }} </el-col>
          <el-col :span="12">开户行：{{ invoiceTitle.sellerBank }}</el-col>
        </el-row>
        <el-row class="list-t">
          <el-col :span="12">地址：{{ invoiceTitle.sellerAddress }}</el-col>
          <el-col :span="12">电话：{{ invoiceTitle.sellerTel }}</el-col>
        </el-row>
        <el-row class="list-t">
          <el-col :span="12"></el-col>
          <el-col :span="12"></el-col>
        </el-row>
        <el-row class="list-t">
          <el-col :span="12">税号：{{ invoiceTitle.sellerTaxpayerId || '' }}</el-col>
          <el-col :span="12">开户行账号：{{ invoiceTitle.sellerAccount || '' }}</el-col>
        </el-row>
        <el-row class="list-t">
          <el-col :span="24">
            <span>发票类型：</span>
            <el-checkbox-group v-model="invoiceTitle.invoiceTypes" :disabled="true">
              <el-checkbox
                v-for="(item, index) in invoicetypeData"
                :key="index"
                :label="item.id"
                :name="item.name"
              >
                {{ item.name || "" }}
              </el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </div>
      <div class="header">
        <div class="left_topic ">上传发票</div>
      </div>
      <p style="margin: 10px 0px">上传附件信息（上传实物盖章发票清晰扫描文件，多张发票请扫描成一个PDF，文件命名规格:公司名称+结算单号）</p>
      <div  style="margin: 10px 0px">
        <el-upload
          class="upload-demo"
          :action="action"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :limit="limit"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :on-success="onSuccessUpload"
          accept=".jpg, .png, .pdf"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">附件不能超过10M，最多上传1个，文件格式PDF、JPG、PNG</div>
        </el-upload>
      </div>
     
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitUpload()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// comInvoiceTitleInfo
import { invoiceTitleQuery, uploadInvoice } from "@/api/businessManage";
// import { shopcertifyUpload } from '@/api/examination'
export default {
  props: {
    requestId: {
      type: String,
      default: () => "",
    },
    typeImg: {
      type: String,
      default: () => "",
    },
    limit: {
      type: Number,
      default: () => 10,
    },
  },
  computed: {
    headers: function () {
      return {
        Authorization: "Bearer " + getToken(),
      };
    },
  },
  data() {
    return {
      action: process.env.VUE_APP_BASE_API + "/product/goodsbase/upload",
      dialogImageUrl: "",
      dialogVisible: false,
      file: {},
      fileList: [],
      showDialog: false,
      fileData: {},
      invoiceTitle: {
        invoiceTypes: [],
      },
      invoicetypeData: [
        {
          id: "1",
          name: "普通发票（纸质）",
        },
        {
          id: "2",
          name: "普通发票（电子）",
        },
        {
          id: "3",
          name: "增值税专用发票（纸质）",
        },
        {
          id: "4",
          name: "增值税专用发票（电子）",
        },
      ],
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    submitUpload() { 
      if (this.fileList.length == 0) {
        this.$message.error("请上传发票！");
        return;
      }
      let fileUrl = "";
      for (let i = 0; i < this.fileList.length; i++) {
        fileUrl += this.fileList[i].url + ",";
      }
      console.log(fileUrl, "fileUrl");
      let parmas = {
        id: this.orderId,
        url: fileUrl,
        sellerName:this.invoiceTitle.sellerName,
      };
      uploadInvoice(parmas).then((res) => {
        if (res.code == 0) {
          this.$message.success("上传成功！");
          this.dialogVisible = false;
          this.$emit("getList")
          this.fileList =[];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    uploadFaPiao(row) {
      this.fileList =[]
      console.log(row)
      this.orderId = row.id;
      this.dialogVisible = true;
      invoiceTitleQuery({
        shopId: row.payShopId,
      }).then((res) => {
        if (res.data && res.data.invoiceType) {
          let invoiceTitle = res.data;
          invoiceTitle.invoiceTypes = res.data.invoiceType.split(","); 
          console.log( invoiceTitle.invoiceTypes)
          this.invoiceTitle = invoiceTitle;
          this.$forceUpdate();
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.limit} 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    onSuccessUpload(res, file, fileList) {
      // console.log(res,'---')
      // console.log( this.fileList,' this.fileList---')
      this.fileLength++;
      // 这里需要根据你自己的接口返回数据格式和层级来自行修改
      if (res.data) {
        // 判断接口上传成功
        if (this.fileList.length < this.limit) {
          // 未超限时，把接口返回的图片url地址添加到fileList
          let datas = res.data;
          datas.name = res.data.fileName;
          this.fileList.push(datas);
        }
      } else {
        // 判断接口上传失败
        this.syncElUpload();
        this.$refs.uploadRef.clearFiles();
        this.$message({ type: "error", message: res.msg });
      }
      if (this.fileLength === fileList.length) {
        this.isUploading = false;
      }
    },
    success(res) {
      console.log(res);
    },
  },
};
</script>
<style scoped>
.list-t {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
}
.header { 
  font-size: 16px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.content {
  margin-bottom: 20px;
}
.el-upload__tip{
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}
</style>
