<!-- 售后 -->
<template>
  <div class='afterSale classification'>
    <div style="padding:0px ">
      <StepWay :step-array="[{ name: '提交申请' }, { name: '卖家审批' }, { name: '买家退货' }, { name: '卖家收货' }, { name: '退款中' }, { name: '完成' }]"
               :index-step="indexStep"
               @stepBtn="stepBtn" />
      <div style="display: flex;">
        <el-form ref="ruleForm"
                 :model="ruleForm"
                 :rules="rules"
                 size="small"
                 label-width="100px"
                 class="demo-ruleForm">
          <el-form-item label="售后商品"
                        prop="name"
                        style="display:flex;align-items: center;">
            <div style="display:flex;align-items: center;margin-left: -100px;">
              <img :src="ruleForm.goodsImage || avater"
                   width="100px"
                   alt="">
              <div style="margin-left:20px">
                <p style="margin:0;line-height:20px">{{ruleForm.goodsName || ''}}</p>
                <p style="margin:0;line-height:20px">{{ruleForm.goodsSpecification || ''}}</p>
                <p style="margin:0;line-height:20px">{{ruleForm.goodsFactory || ''}}</p>
                <p style="margin:0;line-height:20px">{{ruleForm.approvalNumber || ''}}</p>
              </div>

            </div>
          </el-form-item>
          <el-form-item label="售后数量"
                        prop="qty">
            <el-input-number v-model="ruleForm.qty" 
                             @change="changeQty"
                             :min="1"
                             label="售后数量"></el-input-number>
          </el-form-item>
          <el-form-item label="售后方式"
                        prop="type">
            <el-select v-model="ruleForm.type"
                       placeholder="请选择售后方式">
              <el-option v-for="(item,index) in methodList"
                         :key="index"
                         :label="item.value"
                         :value="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="售后原因"
                        prop="afterSaleReason">
            <el-select v-model="ruleForm.afterSaleReason"
                       placeholder="请选择售后原因">
              <el-option v-for="(item,index) in reasonList"
                         :key="index"
                         :label="item.value"
                         :value="item.name"></el-option> 
            </el-select>
          </el-form-item>
          <el-form-item label="售后金额"
                        prop="amount">
            <el-input style="width:200px" 
                      v-model="ruleForm.amount"
                      placeholder="请输入"></el-input> 元
          </el-form-item>
          <el-form-item label="说明"
                        prop="remark">
            <el-input v-model="ruleForm.remark"
                      type="textarea" 
                      maxlength="200"
                      show-word-limit 
                      placeholder="200字以内" />
          </el-form-item>
          <el-form-item label="图片"
                        style="margin-bottom:0px!important">
            <ImgUpload v-model="ruleForm.afterSaleVoucher"
                       :limit="3"
                       style="position:relative;top:-8px">
              <template slot="tip">
                <p class="el-upload__tip"
                   style="margin:0px">
                  备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过5M;第一张图片会作为商品展示图
                </p>
              </template>
            </ImgUpload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       @click="submitForm('ruleForm')">提交</el-button>
            <el-button @click="resetForm('ruleForm')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import avater from "/public/img/default.png"
import StepWay from '@/components/step'
import { 
  aftersaleParam, 
  aftersaleEnum, 
  aftersaleSubmit,
  afterSaleBuyerSms
} from '@/api/afterSales/afterSales'
import ImgUpload from '@/components/imgUpload'
export default {
  components: { StepWay, ImgUpload },
  data () {
    return {
      price: '', // 单价
      routerType: '',//哪里跳转过来的
      reasonList: [],// 原因
      methodList: [],// 方法
      avater: avater,
      detailId: '',
      type: '',
      buyerShopId: '',// 买家店铺id
      indexStep: 1,
      ruleForm: {
        goodsImage: '',
        goodsName: '',
        goodsSpecification: '',
        goodsFactory: '',
        approvalNumber: '',
        type: '',
        afterSaleReason: '',
        remark: '',
        qty: '',
        afterSaleVoucher: []
      },
      rules: {
        type: [
          { required: true, message: '请选择售后方式', trigger: 'change' }
        ],
        afterSaleReason: [
          { required: true, message: '请选择售后原因', trigger: 'change' }
        ],
        qty: [
          { required: true, message: '请输入售后数量', trigger: 'change' }
        ],
        amount: [
          { required: true, message: '请输入退款金额', trigger: 'blur' }
        ],
      }
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 改变数量
    changeQty () {
      if (this.ruleForm.type == 'THTK') {
        this.ruleForm.amount = (this.price * this.ruleForm.qty).toFixed(2)
      }
    },
    // 查询售后详情
    aftersaleParam (detailId, type) {
      let params = {
        detailId: detailId,
        type: type
      }
      aftersaleParam(params).then(res => {
        if (res.code == 0) {
          this.ruleForm.qty = res.data.qty
          this.ruleForm = { ...res.data }
          this.ruleForm.afterSaleVoucher = this.ruleForm.afterSaleVoucher ? this.ruleForm.afterSaleVoucher.split(',') : []
          this.price = (this.ruleForm.amount / this.ruleForm.qty).toFixed(2)
          this.ruleForm.type = this.ruleForm.type ? this.ruleForm.type : this.methodList[0].name
          this.ruleForm.afterSaleReason = this.ruleForm.afterSaleReason ? this.ruleForm.afterSaleReason : this.reasonList[0].name
        }
      })
    },
    // 获取枚举
    async aftersaleEnum () {
      // 售后方式
      await aftersaleEnum({
        type: this.routerType == 'procument' || this.routerType == 'serviceSheet' ? 'OrderAfterSaleType_pay' : 'OrderAfterSaleType_cancel'
      }).then(res => {
        if (res.code == 0) {
          this.methodList = res.data
          console.log(this.methodList)
        }
      })
      // 售后原因
      await aftersaleEnum({
        type: 'afterSaleReason'
      }).then(res => {
        if (res.code == 0) {
          this.reasonList = res.data
          console.log(this.reasonList)
        }
      })
      await this.aftersaleParam(this.detailId, this.type)
    },
    // 步骤条点击事件
    stepBtn (val) {
      console.log(val)
    },
    // 提交
    submitForm (ruleForm) {
      this.$confirm('确定提交', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '返回',
      })
        .then(() => {
          this.submitAction(ruleForm)
        })
        .catch(() => {
          
        })
    },
    submitAction(ruleForm){
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let imageList = ''
          if (this.ruleForm.afterSaleVoucher.length > 0) {
            imageList = this.ruleForm.afterSaleVoucher.join(',')
          }
          let params = {
            ...this.ruleForm,
            detailId: this.detailId
          }
          // 申请售后提交订单 // 需要加类型
          // @Schema(description = "1 商家申请 2 买家申请")
          // private String applyType;
          params.applyType = '1';
          params.afterSaleVoucher = imageList
          params.operationType = this.routerType == 'procument' || this.routerType == 'serviceSheet' ? 'OrderAfterSaleType_pay' : 'OrderAfterSaleType_cancel'
          aftersaleSubmit(params).then(res => {
            if (res.code == 0) {
              // 发送短信
              this.afterSaleBuyerSmsFunc()
              this.$message.success('提交成功')
              if (this.routerType == 'procument') {
                this.$router.push('/ordercenter/ordercenter-procurement')
              } else if (this.routerType == 'sale') {
                this.$router.push('/ordercenter/ordercenter-sales')
              } else {
                this.$router.push('/ordercenter/ordercenter-serviceSheet')
              }
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    // 取消售卖时发短信

    afterSaleBuyerSmsFunc () {
      if(!this.buyerShopId)return;
      let params = {
        buyerShopId: this.buyerShopId
      }
      afterSaleBuyerSms(params).then(res => {})
    },
    resetForm () {
      if (this.routerType == 'procument') {
        this.$router.push('/ordercenter/ordercenter-procurement')
      } else if (this.routerType == 'sale') {
        this.$router.push('/ordercenter/ordercenter-sales')
      } else {
        this.$router.push('/ordercenter/ordercenter-serviceSheet')
      }

    }
  },
  created () {
    const { type, detailId,payShopId: buyerShopId } = this.$route.query
    this.detailId = this.$route.query.detailId
    this.buyerShopId = buyerShopId;
    this.routerType = this.$route.query.routerType
    this.type = type
    if (this.$route.query.indexStep) {
      this.indexStep = Number(this.$route.query.indexStep)
    }
    // this.aftersaleParam(detailId, type)
    this.aftersaleEnum()
  },
  mounted () {

  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
}
</script>
<style lang='scss' scoped>
.afterSale {
  padding: 24px;
  height: 100%;
  width: 100%;
}
</style>