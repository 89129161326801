var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shop-info-root tab-content new-cls" },
    [
      _c(
        "el-form",
        { ref: "shopForm", attrs: { rules: _vm.rules, model: _vm.formIn } },
        [
          _c("el-row", [
            _c("div", { staticClass: "form form-top" }, [
              _c(
                "div",
                { staticClass: "lable-tips" },
                [
                  _vm.formIn.auditStatus == "PRESERVE"
                    ? _c("el-tag", { attrs: { type: "warning" } }, [
                        _vm._v("店铺信息和店铺公告正在审核中"),
                      ])
                    : _vm._e(),
                  _vm.formIn.auditStatus == "NOT_PASS"
                    ? _c("el-tag", { attrs: { type: "danger" } }, [
                        _vm._v("审核驳回"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm.formIn.auditStatus == "NOT_PASS"
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("驳回原因")]),
                    _c(
                      "div",
                      { staticClass: "value input big-w" },
                      [
                        _c("el-input", {
                          attrs: {
                            autosize: "",
                            placeholder: "",
                            disabled: true,
                            type: "textarea",
                          },
                          model: {
                            value: _vm.formIn.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "remark", $$v)
                            },
                            expression: "formIn.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "500px" },
                      attrs: {
                        prop: "shopName",
                        label: "店铺名称",
                        "label-width": "80px",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px", "margin-left": "10px" },
                        attrs: {
                          size: "small",
                          placeholder: "请输入",
                          maxlength: "20",
                        },
                        model: {
                          value: _vm.formIn.shopName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formIn, "shopName", $$v)
                          },
                          expression: "formIn.shopName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "h-dot-line" }),
            ]),
          ]),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "form" }, [
                  _c("div", { staticClass: "row multi" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("店铺Logo")]),
                    _c("div", { staticClass: "value fill" }, [
                      _c("div", { staticClass: "public-pic-layout" }, [
                        _vm.formIn.shopLogo
                          ? _c(
                              "div",
                              { staticClass: "item" },
                              [
                                _c("el-image", {
                                  ref: `myImgLogo`,
                                  staticClass: "icon",
                                  attrs: {
                                    src: _vm.formIn.shopLogo,
                                    fit: "cover",
                                    "initial-index": 0,
                                    "preview-src-list": [_vm.formIn.shopLogo],
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "preview" },
                                  [
                                    _c("el-image", {
                                      staticClass: "btn-icon",
                                      attrs: { src: _vm.SeeIcon },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlePreview(
                                            `myImgLogo`,
                                            false
                                          )
                                        },
                                      },
                                    }),
                                    _c("el-image", {
                                      staticClass: "btn-icon",
                                      attrs: { src: _vm.DelIcon },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDeleteImage("logo")
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "item add-pic",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUpload("logo")
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "image-wrap" },
                                  [
                                    _c("el-image", {
                                      attrs: { src: _vm.AddIcon },
                                    }),
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "text" }, [
                                  _vm._v("上传图片"),
                                ]),
                              ]
                            ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("客服电话")]),
                    _c(
                      "div",
                      { staticClass: "value input big-w" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "请输入",
                            maxlength: "15",
                          },
                          model: {
                            value: _vm.formIn.shopServiceTel,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "shopServiceTel", $$v)
                            },
                            expression: "formIn.shopServiceTel",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("公司名称")]),
                    _c(
                      "div",
                      { staticClass: "value input big-w" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "请输入",
                            maxlength: "30",
                          },
                          model: {
                            value: _vm.formIn.shopCompanyName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "shopCompanyName", $$v)
                            },
                            expression: "formIn.shopCompanyName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("公司地址")]),
                    _c(
                      "div",
                      { staticClass: "value input big-w" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "请输入",
                            maxlength: "50",
                          },
                          model: {
                            value: _vm.formIn.shopCompanyAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "shopCompanyAddress", $$v)
                            },
                            expression: "formIn.shopCompanyAddress",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("运营负责人")]),
                    _c(
                      "div",
                      { staticClass: "value input big-w" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "请输入",
                            maxlength: "15",
                          },
                          model: {
                            value: _vm.formIn.shopHeadOperations,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "shopHeadOperations", $$v)
                            },
                            expression: "formIn.shopHeadOperations",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("联系电话")]),
                    _c(
                      "div",
                      { staticClass: "value input big-w" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "请输入",
                            maxlength: "15",
                          },
                          model: {
                            value: _vm.formIn.shopContactNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "shopContactNumber", $$v)
                            },
                            expression: "formIn.shopContactNumber",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("ERP编码")]),
                    _c(
                      "div",
                      { staticClass: "value input big-w" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "请输入",
                            maxlength: "20",
                          },
                          model: {
                            value: _vm.formIn.shopErpCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.formIn, "shopErpCode", $$v)
                            },
                            expression: "formIn.shopErpCode",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("经营范围")]),
                    _c(
                      "div",
                      { staticClass: "scope-list" },
                      _vm._l(
                        _vm.shopDetail && _vm.shopDetail.businessScopeList
                          ? _vm.shopDetail.businessScopeList
                          : [],
                        function (item, index) {
                          return _c("span", { key: index }, [
                            _vm._v(" " + _vm._s(item.name)),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "row multi" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("公司证件")]),
                    _c(
                      "div",
                      { staticClass: "public-pic-layout-cert" },
                      _vm._l(
                        _vm.shopDetail &&
                          _vm.shopDetail.certificateInformationVOList
                          ? _vm.shopDetail.certificateInformationVOList
                          : [],
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "item" },
                            [
                              _c("el-image", {
                                ref: `myImg${index}`,
                                refInFor: true,
                                staticClass: "icon",
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: item.certificateImage,
                                  fit: "cover",
                                  "initial-index": 0,
                                  "preview-src-list": [item.certificateImage],
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "preview" },
                                [
                                  _c("el-image", {
                                    staticClass: "btn-icon",
                                    attrs: { src: _vm.SeeIcon },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlePreview(
                                          `myImg${index}`,
                                          true
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "form-rt" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "615px" },
                        attrs: {
                          label: "销售政策与条款",
                          "label-width": "120px",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            maxlength: "50",
                            "show-word-limit": "",
                            resize: "none",
                            rows: 4,
                            placeholder:
                              "例如：工作日早八点半，晚5点半，非工作日不处理。",
                          },
                          model: {
                            value: _vm.formIn.shopExtendInfo.orderDealTimeRule,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formIn.shopExtendInfo,
                                "orderDealTimeRule",
                                $$v
                              )
                            },
                            expression:
                              "formIn.shopExtendInfo.orderDealTimeRule",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "615px" },
                        attrs: { label: "配送政策", "label-width": "120px" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            maxlength: "50",
                            "show-word-limit": "",
                            resize: "none",
                            rows: 4,
                            placeholder:
                              "例如：每天15点前的订单当天发，15点后的订单第二天发，周六日节假日正常发货",
                          },
                          model: {
                            value: _vm.formIn.shopExtendInfo.sendRule,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formIn.shopExtendInfo,
                                "sendRule",
                                $$v
                              )
                            },
                            expression: "formIn.shopExtendInfo.sendRule",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "615px" },
                        attrs: { label: "企业介绍", "label-width": "120px" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            maxlength: "200",
                            "show-word-limit": "",
                            resize: "none",
                            rows: 6,
                            placeholder:
                              "例如：电子版开户，无需提供纸质资质，发票随货同行。",
                          },
                          model: {
                            value: _vm.formIn.shopExtendInfo.shopDesc,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formIn.shopExtendInfo,
                                "shopDesc",
                                $$v
                              )
                            },
                            expression: "formIn.shopExtendInfo.shopDesc",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "615px" },
                        attrs: { label: "店铺公告", "label-width": "120px" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            maxlength: "200",
                            "show-word-limit": "",
                            resize: "none",
                            rows: 6,
                            placeholder:
                              "例如：店铺公告:本公司于9月2日至9月24日升级系统，升级期间暂停发货。",
                          },
                          model: {
                            value: _vm.formIn.shopExtendInfo.shopAnnouncement,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formIn.shopExtendInfo,
                                "shopAnnouncement",
                                $$v
                              )
                            },
                            expression:
                              "formIn.shopExtendInfo.shopAnnouncement",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom-btn" },
        [
          _vm.showSave
            ? _c(
                "el-button",
                {
                  staticClass: "fix-four",
                  on: {
                    click: function ($event) {
                      return _vm.doUpdateInfo("PRESERVE")
                    },
                  },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticClass: "fix-four",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.doUpdateInfo("NOT_PASS")
                },
              },
            },
            [_vm._v("提交审核")]
          ),
        ],
        1
      ),
      _c("UploadImageView", {
        ref: "imgupload",
        attrs: { "request-id": "1", imgType: _vm.imgType },
        on: { upload: _vm.uploadSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }