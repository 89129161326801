import request from "@/utils/request";

// 公告列表
export function noticeList(data) {
  return request({
    url: `/message/announcement/page`,
    method: "get",
    params:data
  });
}

// 上架or发布
export function putOn(data) {
  return request({
    url: `message/announcement/putOn/${data.id}`,
    method: 'put',
  })
}

// 下架
export function putOff(data) {
  return request({
    url: `message/announcement/putOff/${data.id}`,
    method: 'put',
  })
}

// 删除
export function del(data) {
  return request({
    url: `message/announcement/${data.id}`,
    method: 'DELETE',
  })
}

// 详情
export function detail(data) {
  return request({
    url: `/message/announcement/${data.id}`,
    method: "get"
  });
}

// 新增
export function addNotice(data) {
  return request({
    url: `/message/announcement`,
    method: "post",
    data
  });
}

// 修改
export function editNotice(data) {
  return request({
    url: `/message/announcement/${data.id}`,
    method: 'put',
    data
  })
}
