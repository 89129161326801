<template>
  <div class="comContent">
    <div class="header">
      <span class="font">发布商品</span>
      <div class="step-frame">
        <StepView :current-index="localCurrentIndex" :step-list="stepList" />
      </div>
    </div>
    <div class="centent">
      <template v-if="localCurrentIndex === 1">
        <SelectTypeView @nextStep="handleNextStep" />
      </template>
      <template v-else-if="localCurrentIndex === 2">
        <SelectProductView
          @preStep="handlePreStep"
          @nextStep="handleNextStep"
        />
      </template>
      <template v-else-if="localCurrentIndex === 3">
        <FillProductInfoView @preStep="handlePreStep" />
      </template>
    </div>
  </div>
</template>

<script>
import StepView from "@/views/myQualification/children/step";
import SelectTypeView from "./children/SelectType.vue";
import SelectProductView from "./children/SelectProduct.vue";
import FillProductInfoView from "./children/fillProductInfo.vue";

export default {
  components: {
    StepView,
    SelectTypeView,
    SelectProductView,
    FillProductInfoView,
  },
  data() {
    return {
      stepList: [
        { name: "活动类型" },
        { name: "选择商品" },
        { name: "填写商品信息" },
      ],
      localCurrentIndex: 1,
    };
  },
  computed: {
    //
  },
  watch: {},
  mounted() {
    //
  },
  methods: {
    handlePreStep() {
      this.localCurrentIndex--;
    },
    handleNextStep(val) {
      this.localCurrentIndex++;
    },
  },
};
</script>
<style lang="scss" scoped>
.comContent {
  display: flex;
  flex-direction: column;
  padding: 0 0 24px;
}
.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  box-sizing: border-box;
  border-bottom: 1px solid #f5f5f5;

  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }

  .step-frame {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}

.centent {
  flex: 1;
  height: 0;
  overflow-y: auto;
  padding: 0 24px;
  box-sizing: border-box;
}
</style>
