<!--
 * @FilePath: /eshop-ops-web/src/views/goodsManage/baseDepot/details.vue
 * @Description: 
 * @Author: xiexingzhong2012@sina.cn
 * @Date: 2023-07-24 17:09:29
 * @LastEditTime: 2023-07-30 11:15:47
 * @LastEditors: xiexingzhong2012@sina.cn
-->
<template>
  <div class="comContent">
    <div class="firstTopic text-left mb20 header">{{ title }}</div>
    <goodsInfo ref="goods-Info" @submit-goods="submit" @back="goBack" @reject-goods="rejectGoods" />
  </div>
</template>
<script>
// addGsoodsbase
import { compgoodstemporaryUpdate } from "@/api/goodsManage/baseDepot.js";
import goodsInfo from "./components/goodsInfo.vue";
export default {
  components: { goodsInfo },
  data() {
    return {
      title: "商品详情",
    };
  },
  filters: {},
  created() { },
  mounted() {
    const { pageType, goodsTemporaryId } = this.$route.query;

  },
  methods: {
    handleClick() { },
    /**
     * @description: 基础库重新提交编辑
     * @return {*}
     */
    compgoodstemporaryUpdate(params) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      compgoodstemporaryUpdate(params).then((res) => {
        loading.close();
        if (res && res.code == 0) {
          setTimeout(() => {
            this.$message({
              message: `提交成功！`,
              type: "success",
            });
            this.submitBack();
          }, 2000);
        }
      }).catch(() => {
        loading.close();
      })
    },

    // 提交按钮
    submit(params) {
      this.compgoodstemporaryUpdate(params);
    },
    // goBack 返回
    goBack() {
      if (this.$route.query.sourcePage != undefined) {
        this.$router.push(this.$route.query.sourcePage);
      } else {
        this.$router.push("./commodityList-index");
      }
    },

    submitBack() {
      if (this.$route.query.sourcePage != undefined) {
        this.$router.push(this.$route.query.sourcePage);
      } else {
        this.$router.push("./commodityList-index");
      }

    },

    // 驳回
    rejectGoods() { },
  },
};
</script>
<style lang="scss" scoped>
@import url("./styles/index.scss");
</style>
