var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c("div", { staticClass: "login-weaper" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "login-border animate__animated animate__fadeInRight",
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loginStatus == "1",
                    expression: "loginStatus == '1'",
                  },
                ],
                staticClass: "login-main",
              },
              [
                _vm._m(1),
                _c(
                  "el-form",
                  {
                    ref: "loginForm",
                    staticClass: "login-form",
                    attrs: {
                      model: _vm.loginForm,
                      rules: _vm.loginRules,
                      "auto-complete": "on",
                      "label-position": "left",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "username" } },
                      [
                        _c("span", { staticClass: "svg-container" }, [
                          _c("img", {
                            staticClass: "img",
                            attrs: {
                              src: require("../../assets/login/people.png"),
                              alt: "",
                            },
                          }),
                        ]),
                        _c("el-input", {
                          ref: "username",
                          attrs: {
                            placeholder: "请输入账号",
                            size: "small",
                            name: "username",
                            type: "text",
                            tabindex: "1",
                            "auto-complete": "on",
                          },
                          model: {
                            value: _vm.loginForm.username,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.loginForm,
                                "username",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "loginForm.username",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "password" } },
                      [
                        _c("span", { staticClass: "svg-container" }, [
                          _c("img", {
                            staticClass: "img",
                            attrs: {
                              src: require("../../assets/login/suo.png"),
                              alt: "",
                            },
                          }),
                        ]),
                        _c("el-input", {
                          key: _vm.passwordType,
                          ref: "password",
                          attrs: {
                            type: _vm.passwordType,
                            placeholder: "请输入密码",
                            name: "password",
                            tabindex: "2",
                            "auto-complete": "on",
                            size: "small",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleLogin.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.loginForm.password,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.loginForm,
                                "password",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "loginForm.password",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "show-pwd",
                            on: { click: _vm.showPwd },
                          },
                          [
                            _c("img", {
                              staticClass: "img",
                              attrs: {
                                src:
                                  _vm.passwordType == "password"
                                    ? _vm.close
                                    : _vm.eyes,
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "forgetPassword" }, [
                      _c(
                        "div",
                        [
                          _c("el-checkbox", {
                            model: {
                              value: _vm.account,
                              callback: function ($$v) {
                                _vm.account = $$v
                              },
                              expression: "account",
                            },
                          }),
                          _c("span", { staticClass: "lianjie" }, [
                            _vm._v("记住密码 "),
                          ]),
                        ],
                        1
                      ),
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass: "lianjie forgetPasswordFont",
                            on: { click: _vm.routeWangji },
                          },
                          [_vm._v("忘记密码？ ")]
                        ),
                      ]),
                    ]),
                    _c(
                      "el-button",
                      {
                        staticClass: "btnClass",
                        attrs: { loading: _vm.loading, type: "primary" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleLogin.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("登录")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "center",
                      "margin-top": "24px",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "lianjie forgetPasswordFont",
                        staticStyle: { "padding-left": "0px" },
                        on: { click: _vm.routeZhuce },
                      },
                      [_vm._v("立即注册 ")]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loginStatus == "2" || _vm.loginStatus == "4",
                    expression: "loginStatus == '2' || loginStatus == '4'",
                  },
                ],
                staticClass: "login-main",
              },
              [
                _c("div", { staticClass: "zhuceTitle" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.loginStatus == "2" ? "欢迎注册" : "找回密码") +
                      " "
                  ),
                ]),
                _c(
                  "el-form",
                  {
                    ref: "registerForm",
                    staticClass: "login-form",
                    attrs: {
                      model: _vm.registerForm,
                      rules: _vm.registerRules,
                      "auto-complete": "on",
                      "label-position": "left",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "16px !important" },
                        attrs: { prop: "tel" },
                      },
                      [
                        _c("span", { staticClass: "svg-container" }, [
                          _c("img", {
                            staticClass: "img",
                            attrs: {
                              src: require("../../assets/login/shouji.png"),
                              alt: "",
                            },
                          }),
                        ]),
                        _c("el-input", {
                          ref: "tel",
                          attrs: {
                            placeholder: "请输入手机号",
                            size: "small",
                            name: "tel",
                            type: "number",
                            tabindex: "1",
                          },
                          model: {
                            value: _vm.registerForm.tel,
                            callback: function ($$v) {
                              _vm.$set(_vm.registerForm, "tel", $$v)
                            },
                            expression: "registerForm.tel",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "16px !important" },
                        attrs: { prop: "yanzheng" },
                      },
                      [
                        _c("span", { staticClass: "svg-container" }, [
                          _c("img", {
                            staticClass: "img",
                            attrs: {
                              src: require("../../assets/login/dunpai.png"),
                              alt: "",
                            },
                          }),
                        ]),
                        _c("el-input", {
                          ref: "yanzheng",
                          attrs: {
                            type: "number",
                            placeholder: "请输入验证码",
                            tabindex: "2",
                            size: "small",
                          },
                          model: {
                            value: _vm.registerForm.yanzheng,
                            callback: function ($$v) {
                              _vm.$set(_vm.registerForm, "yanzheng", $$v)
                            },
                            expression: "registerForm.yanzheng",
                          },
                        }),
                        _c("span", { staticClass: "yanzhengma" }, [
                          _c(
                            "div",
                            { staticClass: "chongfa" },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.registerStatus == "1",
                                      expression: "registerStatus == '1'",
                                    },
                                  ],
                                  staticStyle: { cursor: "pointer" },
                                  on: { click: _vm.acquire },
                                },
                                [_vm._v("获取验证码")]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.registerStatus == "3",
                                      expression: "registerStatus == '3'",
                                    },
                                  ],
                                  staticStyle: { cursor: "pointer" },
                                  on: { click: _vm.acquireNew },
                                },
                                [_vm._v("重新发送")]
                              ),
                              _c(
                                "el-statistic",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.registerStatus == "2",
                                      expression: "registerStatus == '2'",
                                    },
                                  ],
                                  attrs: {
                                    value: _vm.deadline2,
                                    format: "ss",
                                    "time-indices": "",
                                  },
                                  on: { finish: _vm.finish },
                                },
                                [
                                  _c("template", { slot: "suffix" }, [
                                    _vm._v(" 后重发 "),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "16px !important" },
                        attrs: { prop: "onepassword" },
                      },
                      [
                        _c("span", { staticClass: "svg-container" }, [
                          _c("img", {
                            staticClass: "img",
                            attrs: {
                              src: require("../../assets/login/suo.png"),
                              alt: "",
                            },
                          }),
                        ]),
                        _c("el-input", {
                          ref: "firstPasswordType",
                          attrs: {
                            type: _vm.firstPasswordType,
                            placeholder: "请输入密码",
                            name: "password",
                            "auto-complete": "on",
                            size: "small",
                          },
                          model: {
                            value: _vm.registerForm.onepassword,
                            callback: function ($$v) {
                              _vm.$set(_vm.registerForm, "onepassword", $$v)
                            },
                            expression: "registerForm.onepassword",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "show-pwd",
                            on: { click: _vm.showFirstPwd },
                          },
                          [
                            _c("img", {
                              staticClass: "img",
                              attrs: {
                                src:
                                  _vm.firstPasswordType == "password"
                                    ? _vm.close
                                    : _vm.eyes,
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "32px !important" },
                        attrs: { prop: "twopassword" },
                      },
                      [
                        _c("span", { staticClass: "svg-container" }, [
                          _c("img", {
                            staticClass: "img",
                            attrs: {
                              src: require("../../assets/login/suo.png"),
                              alt: "",
                            },
                          }),
                        ]),
                        _c("el-input", {
                          ref: "secondPasswordType",
                          attrs: {
                            type: _vm.secondPasswordType,
                            placeholder: "请再次输入密码",
                            name: "password",
                            "auto-complete": "on",
                            size: "small",
                          },
                          model: {
                            value: _vm.registerForm.twopassword,
                            callback: function ($$v) {
                              _vm.$set(_vm.registerForm, "twopassword", $$v)
                            },
                            expression: "registerForm.twopassword",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "show-pwd",
                            on: { click: _vm.showSecondPwd },
                          },
                          [
                            _c("img", {
                              staticClass: "img",
                              attrs: {
                                src:
                                  _vm.secondPasswordType == "password"
                                    ? _vm.close
                                    : _vm.eyes,
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.loginStatus == "2"
                      ? _c(
                          "div",
                          {
                            staticClass: "forgetPassword",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "flex-start",
                              "align-items": "center",
                              margin: "24px 0px",
                              "padding-left": "10px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.rememberAccount,
                                callback: function ($$v) {
                                  _vm.rememberAccount = $$v
                                },
                                expression: "rememberAccount",
                              },
                            }),
                            _c("span", { staticClass: "lianjie" }, [
                              _vm._v("同意 "),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#f5222d",
                                    cursor: "pointer",
                                  },
                                  on: { click: _vm.infoShow },
                                },
                                [_vm._v(" 《药潺潺用户服务协议》 ")]
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        staticClass: "btnClass",
                        attrs: {
                          loading: _vm.sureLoading,
                          type: "primary",
                          disabled: !(
                            _vm.rememberAccount || _vm.loginStatus == "4"
                          ),
                        },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleRegister.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("确认")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "center",
                      "margin-top": "16px",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "lianjie forgetPasswordFont",
                        staticStyle: { "padding-left": "0px" },
                        on: {
                          click: function ($event) {
                            _vm.loginStatus = "1"
                          },
                        },
                      },
                      [_vm._v("返回登录 ")]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loginStatus == "3",
                    expression: "loginStatus == '3'",
                  },
                ],
                staticClass: "login-main",
              },
              [
                _vm._m(2),
                _c(
                  "div",
                  {
                    staticClass: "success",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                      "margin-bottom": "16px",
                    },
                  },
                  [_vm._v(" 恭喜！注册成功 ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "jumpLogin",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [_vm._v(" 请继续完善账户的资质信息 ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "login-form",
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "center",
                      "margin-top": "60px",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btnClass",
                        attrs: { type: "primary" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.submitLogin.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("确认并补充资质")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "bottom-copyright" }, [
        _vm._v(
          " Copyright © 湖南春播云药库科技有限公司版权所有 客服热线：4000819577 "
        ),
      ]),
      _c("ServeInfo", { ref: "serveinfo", on: { sure: _vm.goCheck } }),
      _vm.tokenLoading
        ? _c("div", { staticClass: "loading_box" }, [_vm._m(3)])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "login-left animate__animated animate__fadeInLeft" },
      [
        _c("img", {
          staticClass: "img",
          attrs: { src: "/img/login.png", alt: "" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "logo-img",
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-bottom": "68px",
        },
      },
      [
        _c("img", {
          staticClass: "img",
          attrs: { src: "/img/loginTop.png", alt: "" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-bottom": "32px",
          "margin-top": "105px",
        },
      },
      [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("../../assets/login/agree.png"), alt: "" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading_content" }, [
      _c("i", { staticClass: "el-icon-loading" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }