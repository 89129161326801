<template>
  <div class="new-dlg-root">
    <el-dialog
      class="dialog-common"
      title="关联商品"
      :visible.sync="dialogVisible"
      width="900px"
      @closed="onClosed"
    >
      <div>
        <div>
          商品名称：
          <el-input
            placeholder="输入商品名称"
            style="width: 200px"
            size="small"
            @change="inputChange"
            v-model="searchContent"
          >
          </el-input>
        </div>
        <div class="goods-content-box">
          <div
            class="goods-box"
            v-if="dialogtableData && dialogtableData.length > 0"
          >
            <div class="goods-li" v-for="(li, i) in dialogtableData" :key="i">
              <div class="goods-li-img">
                <el-image
                  class="goods-li-img-el"
                  fit="contain"
                  :src="li.image || avater"
                  :preview-src-list="[li.image || avater]"
                ></el-image>
              </div>
              <div class="goods-li-content" @click.stop="handleSelectItem(li)">
                <div class="goods-li-name">{{ li.goodsName }}</div>
                <div class="goods-li-txt">{{ li.goodsFactory }}</div>
                <div class="goods-li-txt">{{ li.approvalNumber }}</div>
                <div class="goods-li-txt">{{ li.specification }}</div>
                <div class="goods-li-check" :class="{ act: isSelected(li) }">
                  <i class="el-icon-check"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="goods-empty" v-else>商品数据为空</div>
          <Pagination
            v-if="dialogtableData && dialogtableData.length > 0"
            :total="dialogpage.total"
            :page.sync="dialogpage.current"
            :limit.sync="dialogpage.size"
            @pagination="pageChange"
            :page-sizes="dialogpage.pageSizes"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
        <el-button @click="handleClose" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import avater from "/public/img/default.png";
import Pagination from "@/components/Pagination";
import { goodsClassRelationsSearchGoods } from "@/api/classificationmanagement";
export default {
  props: {},
  data() {
    return {
      avater: avater,
      dialogVisible: false,
      searchContent: "",
      dialogtableData: [],
      dialogpage: {
        total: 0,
        current: 1,
        size: 8,
        pageSizes: [8, 16, 30, 50],
      },
      selectedGoods: [], //选择的商品
    };
  },
  components: { Pagination },
  // created() {},
  mounted() {},
  // watch: {},
  methods: {
    submit() {
      let selectedList = this.selectedGoods;//this.dialogtableData.filter((li) => li.selected);
      if (selectedList && selectedList.length > 0) {
        this.$emit("choose", selectedList);
        this.dialogVisible = false;
      } else {
        this.$message.warning("请选择关联药品");
      }
    },
    // 弹窗搜索事件
    inputChange() {
      this.dialogpage.current = 1;
      this.getData();
    },
    // 切换分页
    pageChange() {
      this.getData();
    },
    // 关闭弹窗
    handleClose() {
      this.close();
    },
    // 选择商品
    open(selectedGoods) {
      this.dialogVisible = true;
      this.dialogpage.current = 1;
      this.selectedGoods = selectedGoods;
      this.getData();
    },
    close() {
      this.dialogVisible = false;
    },
    onClosed() {
      this.initData();
    },
    initData() {
      this.dialogpage.current = 1;
      this.dialogpage.size = 8;
      this.searchContent = "";
      this.dialogtableData = [];
    },
    getData() {
      const { current, size } = this.dialogpage;
      let params = {
        current,
        size,
        name: this.searchContent,
        factory: "",
        oneGoodsOperationalClassId: "",
        twoGoodsOperationalClassId: "",
      };
      goodsClassRelationsSearchGoods(params).then((res) => {
        this.dialogtableData = res.records.map((li) => {
          return { ...li, selected: false };
        });
        this.dialogpage.total = res.total;
      });
    },
    handleSelectItem(item) {
      if (Array.isArray(this.selectedGoods)) {
        const pos = this.selectedGoods.findIndex(
          (subItem) => subItem.goodsId === item.goodsId
        );
        if (pos >= 0) {
          this.selectedGoods.splice(pos, 1);
        } else {
          this.selectedGoods.push(item);
        }
      } else {
        this.selectedGoods = [item];
      }
    },
    isSelected(item) {
      if (Array.isArray(this.selectedGoods)) {
        const pos = this.selectedGoods.findIndex(
          (subItem) => subItem.goodsId === item.goodsId
        );
        return pos >= 0 ? true : false;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.goods {
  &-content-box {
    margin-top: 10px;
  }

  &-box {
    display: flex;
    align-items: normal;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  &-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 240px;
    font-size: 20px;
    color: #999;
  }
  &-li {
    width: 204px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    padding-bottom: 4px;
    overflow: hidden;

    &:hover {
      border: 1px solid var(--main-color);
    }

    &:nth-child(4n) {
      margin-right: 0;
    }

    &-img {
      &-el {
        width: 100%;
        height: 180px;
      }
    }

    &-content {
      position: relative;
      cursor: pointer;
    }

    &-name {
      margin: 0 8px;
      font-size: 18px;
      color: #666;
      margin-bottom: 4px;
      font-weight: 600;
    }

    &-txt {
      margin: 0 8px;
      line-height: 1.4;
      color: #999;
    }

    &-check {
      position: absolute;
      z-index: 10;
      top: -180px;
      right: 5px;
      width: 20px;
      height: 20px;
      background: #fff;
      border: 1px solid #eee;
      border-radius: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;

      &.act {
        background: var(--main-color);
        border: 1px solid var(--main-color);
      }
    }
  }
}
</style>
