
<!--  -->
<template>
  <div class="comContent contentInner">
    <div class="topSearch">
      <el-row class="top"
              :gutter="8"
              style="width:100%">
        <el-form ref="formTool"
                 :model="formTool"
                 :inline="true">
          <el-col :span="4">
            <el-form-item prop="province">
              <el-select v-model="formTool.province"
                         size="small"
                         clearable
                         placeholder="请选择省"
                         @change="provinceBtn">
                <el-option v-for="(item, index) in provinceData"
                           :key="index"
                           :label="item.rname"
                           :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="city">
              <el-select v-model="formTool.city"
                         size="small"
                         clearable
                         placeholder="请选择市"
                         @change="cityBtn">
                <el-option v-for="(item, index) in cityData"
                           :key="index"
                           :label="item.rname"
                           :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="area">
              <el-select v-model="formTool.area"
                         size="small"
                         clearable
                         placeholder="请选择区"
                         @change="areaBtn">
                <el-option v-for="(item, index) in areaData"
                           :key="index"
                           :label="item.rname"
                           :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="shopTypeCode">
              <el-select v-model="formTool.shopTypeCode"
                         size="small"
                         clearable
                         placeholder="商户类型">
                <el-option v-for="(item, index) in shopList"
                           :key="index"
                           :label="item.typeName"
                           :value="item.typeCode" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="shopName">
              <el-input v-model="formTool.shopName"
                        clearable
                        placeholder="企业名称"
                        size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="concatPhone">
              <el-input v-model="formTool.concatPhone"
                        clearable
                        placeholder="登录用户名"
                        size="small" />
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="second">
        <div style="display: inline-block;margin-right:16px"
             v-if="tabsIndex!= '2' && tabsIndex!= '1' && tabsIndex!= '-2'">
          <span class="demonstration"
                style="">提交日期：
          </span>
          <el-date-picker v-model="value1"
                          :picker-options="pickerOptions"
                          value-format="yyyy-MM-dd"
                          size="small"
                          clearable
                          type="daterange"
                          range-separator="-"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div style="display: inline-block;margin-right:16px"
             v-if="tabsIndex!= '0' && tabsIndex!= '-2'&& tabsIndex!= '-1'">
          <span class="demonstration">审核日期：</span>
          <el-date-picker v-model="value2"
                          value-format="yyyy-MM-dd"
                          :picker-options="pickerOptions"
                          size="small"
                          clearable
                          type="daterange"
                          range-separator="-"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <MyButton type="primary"
                  text="查询"
                  @click="goSearch" />
        <el-button size="small"
                   @click="reset()">重置</el-button>
        <el-button size="small"
                   @click="download()">导出</el-button>
      </div>
    </div>
    <div class="titleInner">
      <MyTabs class="search"
              :tabi="tabsIndex"
              keyname="val"
              :tabs="tabsData"
              @change="tabChange" />

    </div>
    <div class="center">
      <MyTable :table-data="tableData"
               @sort-change="sortChange"
               :index="index"
               :selection="selection"
               :page="page"
               :operation="operation"
               :table-option.sync="tableOption"
               @page-change="getcheckList"
               @handleSelectionChange="currentChange">
        <!-- <template slot="image" slot-scope="scope">
          <el-image :src="scope.row.image" style="width: 80px; height: 80px" />
        </template> -->
        <template slot="status"
                  slot-scope="scope">
          <MyTypes :type="scope.row.status" />
        </template>
        <template slot="address"
                  slot-scope="scope">
          <p>{{ scope.row.provinceName + scope.row.cityName + scope.row.areaName + scope.row.address }}</p>
        </template>
        <template slot="menu"
                  slot-scope="scope">
          <!-- <el-button type="text" size="small" @click="editDetail(scope.row)">审核</el-button> -->
          <el-link :underline="false"
                   type="primary"
                   v-if="tabsIndex== '0'"
                   @click="editDetail(scope.row)">审核</el-link>
          <el-link :underline="false"
                   style="margin-left:8px"
                   type="primary"
                   @click="detailInfo(scope.row)">详情</el-link>
          <!-- <el-link :underline="false"
                   style="margin-left:8px"
                   type="primary"
                   @click="uploadZizhi(scope.row)">资质下载</el-link> -->
          <el-link v-if="tabsIndex!= '-2'"
                   :underline="false"
                   style="margin-left:8px"
                   type="primary"
                   @click="auditHistory(scope.row)">审核日志</el-link>
        </template>
      </MyTable>
    </div>
    <el-dialog title="操作日志"
               width="700px"
               border
               :visible.sync="dialogTableVisible">
      <el-table :data="gridData"
                style="height:100%;overflow:auto">
        <el-table-column type="index"
                         label="序号" />
        <el-table-column property="updateBy"
                         label="操作人"
                         width="120"></el-table-column>
        <el-table-column label="操作内容"
                         width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.auditOperat == '2'? '审核通过':'驳回' }}</span>
          </template>
        </el-table-column>
        <el-table-column property="remarks"
                         label="备注"
                         width="200"></el-table-column>
        <el-table-column property="updateTime"
                         label="操作时间"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import MyButton from '@/components/myButton'
import MyTable from '@/components/myTable'
import MyTabs from '@/components/myTabs'
import MyTypes from '@/components/myTypes'
import { auditCompanyList, downloadShopcertify } from '@/api/auditcenter'
import { getShoparea, regionProvince, regionCity, regionArea, download } from "@/api/merchantsettlement/index";
import dayjs from 'dayjs'

export default {
  name: 'auditcompany',
  components: { MyButton, MyTable, MyTabs, MyTypes },
  data () {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "昨日",
            onClick (picker) {
              const start = dayjs().add(-1, 'day').format('YYYY-MM-DD');
              const end = dayjs().add(-1, 'day').format('YYYY-MM-DD');
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "今日",
            onClick (picker) {
              const end = dayjs().add(0, 'day').format('YYYY-MM-DD');
              const start = dayjs().add(0, 'day').format('YYYY-MM-DD');
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近3日",
            onClick (picker) {
              const start = dayjs().add(-3, 'day').format('YYYY-MM-DD');
              const end = dayjs().add(0, 'day').format('YYYY-MM-DD');
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近7日",
            onClick (picker) {
              const start = dayjs().add(-7, 'day').format('YYYY-MM-DD');
              const end = dayjs().add(0, 'day').format('YYYY-MM-DD');
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近30日",
            onClick (picker) {
              const start = dayjs().add(-30, 'day').format('YYYY-MM-DD');
              const end = dayjs().add(0, 'day').format('YYYY-MM-DD');
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      order: 'DESC',//升序或者降序
      radio1: '',
      // 商户类型
      shopList: [
      ],
      dialogTableVisible: false,
      gridData: [],

      text: '',
      clearable: true,
      provinceData: {},
      cityData: [],
      areaData: [],
      value1: [], // 提交时间
      value2: [], // 审核时间
      formTool: {
        shopTypeCode: '',
        province: '',
        city: '',
        area: '',
        shopName: '',
        concatPhone: '',
      },
      tabsData: [
        { name: '全部', num: 0, val: '-1', numhide: true },
        { name: '待审核', num: 0, val: '0', numhide: true },
        { name: '审核通过', num: 0, val: '2', numhide: true },
        { name: '驳回', num: 0, val: '1', numhide: true },
        { name: '资质准备中', num: 0, val: '-2', numhide: true },
      ],
      tabsIndex: '-1',
      operation: true,
      selection: false,
      index: false,
      tableData: [],
      tableOption: [
        // { label: '编号', prop: 'id' },
        { label: '企业名称', prop: 'shopName' }, // 这里表示自定义列 , slot: true
        { label: '企业类型', prop: 'shopTypeName' },
        { label: '登录用户名', prop: 'loginUserName' },
        { label: '提交时间', prop: 'submitTime', sortable: 'custom' },
        { label: '审核时间', prop: 'auditTime' },
        { label: '详细地址', prop: 'address', slot: true, width: '400px' },
        { label: '状态', prop: 'status', slot: true }
        // { label: '操作', prop: 'menu' }
      ],
      page: {
        total: 0,
        current: 1,
        size: 30,
        pageSizes: [30, 50, 100, 500]
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getShoparea();
    this.getProvince();
    this.getcheckList()
  },
  mounted () {

  },
  activated () {
    console.log('这是一个被缓存的组件')
    this.getcheckList()
  },
  methods: {
    // 表格排序
    sortChange (val) {
      if (val.order == 'ascending') {
        this.order = 'ASC'
      } else {
        this.order = 'DESC'
      }
      this.getcheckList()
    },
    getShoparea () {
      getShoparea(0).then((res) => {
        if (res.code === 0) {
          this.shopList = res.data;
          // this.numberValidateForm.typeCode = res.data[0].typeCode;
        }
      });
    },
    getProvince () {
      regionProvince().then((res) => {
        if (res.code === 0) {
          this.provinceData = res.data;
        }
      });
    },
    provinceBtn (item) {
      console.log(item, 'item')
      this.cityData = [];
      this.areaData = [];
      if (!item) {
        this.formTool.city = ``;
        this.formTool.area = ``;
      } else {
        // const provinceIndex = this.provinceData.findIndex(
        //   (res) => res.id === item
        // );
        // this.formTool.provinceName =
        //   this.provinceData[provinceIndex].rname;
        regionCity(item).then((res) => {
          this.cityData = res.data;
          this.formTool.city = ``;
          this.formTool.area = ``;
        });
      }
    },
    cityBtn (item) {
      this.areaData = [];
      if (!item) {
        this.formTool.area = ``;
      } else {
        // const cityIndex = this.cityData.findIndex((res) => res.id === item);
        // this.formTool.cityName = this.cityData[cityIndex].rname;
        regionArea(item).then((res) => {
          this.formTool.area = "";
          this.areaData = res.data;
        });
      }

    },
    areaBtn (item) {
      if (!item) {
        this.formTool.area = ``;
      } else {
        // const areaIndex = this.areaData.findIndex((res) => res.id === item);
        // this.formTool.areaName = this.areaData[areaIndex].rname;
      }
    },
    // 资质下载
    uploadZizhi (row) {
      downloadShopcertify(row.id).then(res => {
        if (res.code == 0) {

        } else {
          this.$message.error('资质下载出错')
        }
      })
    },
    // 导出
    download () {
      const { current, size } = this.page
      let params = {
        current, size, status: this.tabsIndex,
        ...this.formTool
      }
      params.startSubmitTime = !this.value1 || this.value1.length == 0 ? '' : this.value1[0]
      params.endSubmitTime = !this.value1 || this.value1.length == 0 ? '' : this.value1[1]
      params.startAuditTime = !this.value2 || this.value2.length == 0 ? '' : this.value2[0]
      params.endAuditTime = !this.value2 || this.value2.length == 0 ? '' : this.value2[1]
      download(params).then(res => {
        console.log(res)
      })
    },
    // 查看日志
    auditHistory (row) {
      this.$router.push({
        path: "/audit/auditcenter-auditlog",
        query: {
          id: row.id
        }
      })
    },
    tabChange (i) {
      // console.log('i:' + i)
      this.tabsIndex = i
      this.page.current = 1
      this.radio1 = ''
      this.value1 = []
      this.value2 = []
      if (i == '-1') {
        this.tableOption = [
          // { label: '编号', prop: 'id' },
          { label: '企业名称', prop: 'shopName' }, // 这里表示自定义列 , slot: true
          { label: '企业类型', prop: 'shopTypeName' },
          { label: '登录用户名', prop: 'loginUserName' },
          { label: '提交时间', prop: 'submitTime', sortable: 'custom' },
          { label: '审核时间', prop: 'auditTime' },
          { label: '详细地址', prop: 'address', slot: true, width: '400px' },
          { label: '状态', prop: 'status', slot: true }
          // { label: '操作', prop: 'menu' }
        ]
      } else if (i == '0') {
        this.tableOption = [
          // { label: '编号', prop: 'id' },
          { label: '企业名称', prop: 'shopName' }, // 这里表示自定义列 , slot: true
          { label: '企业类型', prop: 'shopTypeName' },
          { label: '登录用户名', prop: 'loginUserName' },
          { label: '提交时间', prop: 'submitTime', sortable: 'custom' },
          // { label: '审核时间', prop: 'auditTime' },
          { label: '详细地址', prop: 'address', slot: true, width: '400px' },
          { label: '状态', prop: 'status', slot: true }
          // { label: '操作', prop: 'menu' }
        ]
      } else if (i == '-2') {
        this.tableOption = [
          // { label: '编号', prop: 'id' },
          { label: '企业名称', prop: 'shopName' }, // 这里表示自定义列 , slot: true
          { label: '企业类型', prop: 'shopTypeName' },
          { label: '登录用户名', prop: 'loginUserName' },
          // { label: '提交时间', prop: 'submitTime', sortable: 'custom' },
          // { label: '审核时间', prop: 'auditTime' },
          { label: '详细地址', prop: 'address', slot: true, width: '400px' },
          { label: '状态', prop: 'status', slot: true }
          // { label: '操作', prop: 'menu' }
        ]
      } else {
        this.tableOption = [
          // { label: '编号', prop: 'id' },
          { label: '企业名称', prop: 'shopName' }, // 这里表示自定义列 , slot: true
          { label: '企业类型', prop: 'shopTypeName' },
          { label: '登录用户名', prop: 'loginUserName' },
          // { label: '提交时间', prop: 'submitTime', sortable: 'custom' },
          { label: '审核时间', prop: 'auditTime' },
          { label: '详细地址', prop: 'address', slot: true, width: '400px' },
          { label: '状态', prop: 'status', slot: true }
          // { label: '操作', prop: 'menu' }
        ]
      }

      this.getcheckList()
    },
    getcheckList () {
      const { current, size } = this.page
      let params = {
        current, size, status: this.tabsIndex, sort: this.order,
        ...this.formTool
      }
      params.startSubmitTime = !this.value1 || this.value1.length == 0 ? '' : this.value1[0]
      params.endSubmitTime = !this.value1 || this.value1.length == 0 ? '' : this.value1[1]
      params.startAuditTime = !this.value2 || this.value2.length == 0 ? '' : this.value2[0]
      params.endAuditTime = !this.value2 || this.value2.length == 0 ? '' : this.value2[1]
      auditCompanyList(params).then(res => {
        if (res.code === 0) {
          if (!res.data) {
            this.$message.warning('当前查询条件下无数据')
            this.tableData = []
            this.page.total = 0
            this.tabsData.forEach(item => {
              // item.num = 0
              item.numhide = true
            })
            return
          }
          const { records, total } = res.data
          this.page.total = total
          // records.forEach(item => {
          //   item.status = auditTypes(item.status)
          // })
          this.tableData = records
          this.tabsData.forEach(item => {
            if (item.val == this.tabsIndex) {
              item.num = total
              item.numhide = false
            } else {
              item.numhide = true
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset () {
      this.value1 = []
      this.value2 = []
      // this.formTool.storeName = ''
      this.$refs.formTool.resetFields()
      this.page.current = 1
      this.getcheckList()
    },
    editDetail (row) {
      this.$router.push({ path: '/audit/auditcenter-auditcompanydetail', query: { id: row.requestId, btnShow: '1' } })
    },
    detailInfo (row) {
      this.$router.push({ path: '/audit/auditcenter-auditcompanydetail', query: { id: row.requestId, btnShow: '2' } })
    },
    currentChange (val) {
      console.log('current', val)
    },
    goSearch () {
      this.radio1 = ''
      this.page.current = 1
      this.getcheckList()

    },
    // pageChange(val) {
    //   console.log('page', val)
    // },
    // handleSelectionChange(val) {
    //   console.log(val)
    // }
  }
}
</script>
<style lang='scss' scoped>
.comContent {
  ::v-deep .el-dialog__body {
    height: 500px;
  }
  .second {
    height: 56px;
    padding: 0 24px;
  }
  .top {
    height: 56px;
    padding: 0 24px;
    display: flex;
    // justify-content: center;
    align-items: center;
    .el-radio {
      margin-right: 10px;
    }
    .demonstration {
      color: #595959;
      font-size: 14px;
    }
    ::v-deep .el-form-item {
      margin-bottom: 0px !important;
      margin-right: 0px !important;
      .el-date-editor.el-input__inner {
        width: auto !important;
      }
    }
  }
  .titleInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 0px 24px;
    .title {
      display: flex;
      justify-content: right;
      align-items: center;
    }
    .search {
      align-items: center;
    }
  }
}
.contentInner {
  padding: 0px;
}
.center {
  margin-top: 16px;
  padding: 0px 24px;
  height: calc(100% - 250px);
}
</style>
