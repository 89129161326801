var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent shopingcart" },
    [
      _c(
        "div",
        { staticClass: "center" },
        [
          _c(
            "div",
            {
              staticClass: "left_topic mb20",
              staticStyle: { margin: "12px 0", "margin-bottom": "24px" },
            },
            [_vm._v("控销申请信息")]
          ),
          _c(
            "el-form",
            {
              ref: "productLine",
              staticClass: "ruleFormCont",
              attrs: {
                model: _vm.formData,
                "label-width": "100px",
                "label-position": "right",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品线名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "325px" },
                    attrs: {
                      size: "small",
                      placeholder: "请输入20字以内",
                      disabled: _vm.isDisabled,
                    },
                    model: {
                      value: _vm.formData.productLineName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "productLineName", $$v)
                      },
                      expression: "formData.productLineName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品信息", prop: "name" } },
                        [
                          _c("div", { staticClass: "shop-info" }, [
                            _c(
                              "section",
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                    margin: "0px",
                                    "border-radius": "4px",
                                  },
                                  attrs: {
                                    src: _vm.formData.goodsImage || _vm.avater,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("section", { staticClass: "info-list" }, [
                              _c(
                                "div",
                                { staticStyle: { "line-height": "20px" } },
                                [_vm._v(_vm._s(_vm.formData.goodsName))]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "line-height": "20px" } },
                                [_vm._v(_vm._s(_vm.formData.goodsFactory))]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "line-height": "20px" } },
                                [_vm._v(_vm._s(_vm.formData.specification))]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "left_topic mb20",
                  staticStyle: { "margin-top": "10px" },
                },
                [_vm._v("控销申请信息")]
              ),
              _c(
                "div",
                { staticClass: "produceDetail" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "团队类型", prop: "name" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "325px" },
                                attrs: {
                                  size: "small",
                                  disabled: _vm.isDisabled,
                                },
                                model: {
                                  value: _vm.formData.applyType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "applyType", $$v)
                                  },
                                  expression: "formData.applyType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "负责人姓名", prop: "name" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "325px" },
                                attrs: {
                                  size: "small",
                                  disabled: _vm.isDisabled,
                                },
                                model: {
                                  value: _vm.formData.managerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "managerName", $$v)
                                  },
                                  expression: "formData.managerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "省市地", prop: "name" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "325px" },
                                attrs: {
                                  size: "small",
                                  disabled: _vm.isDisabled,
                                },
                                model: {
                                  value: _vm.formData.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "address", $$v)
                                  },
                                  expression: "formData.address",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系方式", prop: "name" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "325px" },
                                attrs: {
                                  size: "small",
                                  disabled: _vm.isDisabled,
                                },
                                model: {
                                  value: _vm.formData.contactPhone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "contactPhone", $$v)
                                  },
                                  expression: "formData.contactPhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "mt20",
              staticStyle: { "margin-bottom": "24px", "padding-left": "100px" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "", size: "small" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("UnitDialog", {
        attrs: {
          "unit-list": _vm.unitList,
          "dialog-visible": _vm.dialogVisible,
        },
        on: { submitCheck: _vm.submitCheck },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }