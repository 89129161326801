import request  from '@/utils/request';

// 用户读取召回信息列表
export function getRecallList(data) {
    return request({
      url: `/message/recall/getRecallList`,
      method: "POST",
      data: data,
    });
  }

//   /recall/readerList

export function readerList(data) {
    return request({
      url: `/message/recall/readerList`,
      method: "POST",
      data: data,
    });
  }

 