<!--
 * @FilePath: /eshop-ops-web/src/views/goodsManage/baseDepot/components/goodsInfo.vue
 * @Description: 
 * @Author: xiexingzhong2012@sina.cn
 * @Date: 2023-07-24 20:01:25
 * @LastEditTime: 2023-07-30 20:22:44
 * @LastEditors: xiexingzhong2012@sina.cn
-->
<template>
  <div class="comContent">
    <div class="firstTopic text-left mb20 header">商品信息</div>
    <el-form ref="goods-info"
             label-width="120px"
             :model="formTool"
             :rules="rules"
             size="small"
             class="product"
             :disabled="disabled"
             label-position="right">
      <el-row>
        <el-col :span="24">
          <el-form-item label="商品类型"
                        prop="goodsType">
            <el-radio-group v-model="formTool.goodsType"
                            @change="changeType(formTool.goodsType)">
              <el-radio v-for="item in formTool.typeList"
                        :key="item.id"
                        :label="item.id">{{ item.name }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="产品名称"
                        prop="name">
            <el-input v-model="formTool.name"
                      placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="规格"
                        prop="specification">
            <el-input v-model="formTool.specification"
                      placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="批准文号"
                        prop="approvalNumber">
            <el-input v-model="formTool.approvalNumber"
                      placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="生产厂家"
                        prop="factory">
            <el-input v-model="formTool.factory"
                      placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <!-- 商品分类属性 -->
          <dynamic-attribute ref="dynamic-attribute"
                             :formTool="formTool"
                             :isShowAll="false"
                             pageSource="1"></dynamic-attribute>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item label="图片"
                        prop="imageList">
            <imgUpload v-model="formTool.imageList"
                       :limit="5"
                       :isEdit="disabled" />
            <div class="upload-tips">
              上传图片大小需低于2M，图片格式PNG,JPG,JPEG，图片尺寸建议800 x
              800px
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="商品详情">
            <wangEnduit :value="formTool.detail"
                        :is-clear="isClear"
                        :isDisabled="disabled"
                        @changeEndit="watchEndit" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-divider />
    </el-form>
    <div style="margin: 20px 40px">
      <div class="mt20">
        <el-button size="small"
                   style="width: 160px"
                   @click="back()">返回</el-button>
        <el-button type="primary"
                   style="width: 160px"
                   size="small"
                   @click="submit"
                   :disabled="disabled">保存提交</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import imgUpload from "@/components/imgUpload";
import fileUpload from "@/components/fileUpload";
import wangEnduit from "@/components/wangEnduit";
import DynamicAttribute from "@/components/dynamicAttribute";
// import GoodsThreeClass from "@/components/goodsThreeClass";
import getComList from "@/utils/getComList";
import {
  getAttributeByTypeId,
  getCompGoodsBaseDetail,
  goodstemporarySave,
} from "@/api/goodsManage/baseDepot.js";

export default {
  components: {
    imgUpload,
    wangEnduit,
    fileUpload,
    DynamicAttribute,
    // GoodsThreeClass, //商品三级分类
  },
  props: {},
  data () {
    return {
      id: null,
      pageType: null,
      disabled: false,
      formTool: {
        goodsType: null,
        details: null, // 商品详情
        // goodsOneClassId: "",
        // goodsTwoClassId: "",
        // goodsThreeClassId: "",
        typeList: [],
        name: "", // 产品名称
        imgList: "",
        // imgList: "https://eshop-prod.oss-cn-chengdu.aliyuncs.com/goods/400b20db7f2540d3ae16832d88a5dcc5.jpeg",
        imageList: [], // 图片
        fileListStr: "",
        fileList: [],
        extendParams: [], //拓展属性
        businessScopeId: null, //经营范围
        specification: null,
        approvalNumber: null,
        factory: null,
      },

      rules: {
        name: [{ required: true, message: "请输入产名称", trigger: "blur" }],
        goodsType: [
          { required: true, message: "请输入商品类型", trigger: "blur" },
        ],
        // businessScopeId: [
        //   { required: true, message: "请输入经营范围", trigger: "blur" },
        // ],
        specification: [
          { required: true, message: "请输入规格", trigger: "blur" },
        ],
        approvalNumber: [
          { required: true, message: "请输入批准文号", trigger: "blur" },
        ],
        factory: [
          { required: true, message: "请输入生产厂家", trigger: "blur" },
        ],
        // goodsOneClassId: [
        //   { required: true, message: "请输入第一级分类", trigger: "change" },
        // ],
        // goodsTwoClassId: [
        //   { required: true, message: "请输入第二级分类", trigger: "change" },
        // ],
        // goodsThreeClassId: [
        //   { required: true, message: "请输入第三级分类", trigger: "change" },
        // ],
        imageList: [
          { required: true, message: "请上传图片", trigger: "change" },
        ],
      },
      isClear: false, // 清除富文本编辑内容
    };
  },
  computed: {},
  watch: {},
  created () { },
  mounted () {
    this.pageType = this.$route.query.pageType;
    if (this.pageType == 4 || this.pageType == 1) {
      this.disabled = true;
    }
    this.typeList();
  },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
  methods: {
    goNext () {
      this.$router.push(
        "./goodsManage-baseDepot-details?pageType=5&id" + this.$route.query.id
      );
    },
    async typeList () {
      this.formTool.typeList = await getComList.goodsType; // 商品类型
      this.formTool.scopeList = await getComList.scopeList; //经营范围
      if (this.formTool.typeList.length > 0) {
        this.formTool.goodsType = this.formTool.typeList[0].id;
        this.getAttributeByTypeId(this.formTool.goodsType);
      }
      // 查询详情信息
      if (this.$route.query.id != undefined) {
        this.id = this.$route.query.id;
        console.log(this.$route.query.id);
        this.getDetail(this.$route.query.id);
      }
      this.$forceUpdate();
    },
    changeType (typeId) {
      this.getAttributeByTypeId(typeId);
    },
    back () {
      // this.$emit("back");
      this.$router.back(-1)
    },
    /**
     * @functionName: getAttributeByTypeId
     * @description: 根据id 查询商品属性
     * @param id
     * @return {*}
     */
    getAttributeByTypeId (typeId) {
      getAttributeByTypeId({
        typeId: typeId,
        isMerchant: false, //是否根据商户去查，运营端传false 查全部
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let extendParams = res.data;

          for (let i = 0; i < extendParams.length; i++) {
            if (extendParams[i].input == "2") {
              extendParams[i].selectList =
                extendParams[i].selectEnum.split("，");
            }
            if (extendParams[i].input == "3") {
              extendParams[i].selectList =
                extendParams[i].selectEnum.split(",");
            }
            extendParams[i].value = "";
          }
          this.formTool.extendParams = extendParams;
          this.$forceUpdate();
          this.$nextTick(() => {
            this.$refs["goods-info"].clearValidate();
            this.strTofileList(this.formTool.fileListStr);
          });
        } else {
          this.$message({
            message: res.msg,
            type: "erro",
          });
        }
      });
    },

    /**
     * @description: 初始化加载 配置的 list： 1. 商品类型 2. 经营范围
     * @return {*}
     */
    formatterStr () {
      if (
        this.formTool.imgList &&
        this.formTool.imgList.indexOf("http") != -1
      ) {
        this.formTool.imageList = this.strToArr(this.formTool.imgList);
        console.log(this.formTool.imageList);
      }
    },

    /**
     * @functionName: onRemoveHandler
     * @description: 删除上传的文件
     * @return {*}
     */
    onRemoveHandler (file) {
      let index = this.formTool.fileList.indexOf(file);
      this.formTool.fileList.splice(index, 1);
    },

    /**
     * @description: submit 方法 提交form 数据给业务调用方
     * @return {*} fromlToll
     */
    submit () {
      const params = Object.assign({}, this.formTool);
      let fileListStr = "";

      for (let j = 0; j < this.formTool.fileList.length; j++) {
        fileListStr += this.formTool.fileList[j].url + ",";
      }

      this.formTool.fileListStr = fileListStr;
      let extendParamsList = [...this.formTool.extendParams];
      let extendParamsObj = {};
      for (let i = 0; i < extendParamsList.length; i++) {
        if (
          this.formTool.fileList.length > 0 &&
          extendParamsList[i].input == "3"
        ) {
          extendParamsObj[extendParamsList[i].fieldName] =
            this.formTool.fileListStr;
        } else {
          extendParamsObj[extendParamsList[i].fieldName] =
            extendParamsList[i].value;
        }
      }

      params.extendParams = extendParamsObj;
      params.image = params.imageList[0];
      params.imageList = params.imageList.join();
      // console.log(params);
      const {
        name,
        // goodsOneClassId,
        // goodsTwoClassId,
        // goodsThreeClassId,
        imageList,
        goodsType,
        extendParams,
        // businessScopeId,
        specification,
        approvalNumber,
        factory,
        detail,
        image,
      } = params;
      let compGoodsTemporaryEntity = {
        name,
        // goodsOneClassId,
        // goodsTwoClassId,
        // goodsThreeClassId,
        imageList,
        goodsType,
        // businessScopeId,
        specification,
        approvalNumber,
        factory,
        detail,
        image,
      };
      let paramsData = {};
      paramsData.compGoodsTemporaryEntity = compGoodsTemporaryEntity;
      paramsData.extendParams = extendParams;
      if (this.id != undefined) {
        paramsData.compGoodsTemporaryEntity.id = this.id;
      }

      this.$refs["goods-info"].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "请填写完整信息！",
            type: "warning",
          });
        } else {
          // this.$emit("submit-goods", paramsData);
          this.saveGoodsDetail(paramsData);
        }
      });
    },

    saveGoodsDetail (params) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      goodstemporarySave(params).then((res) => {
        loading.close();
        if (res && res.code == 0) {
          setTimeout(() => {
            this.$message({
              message: `提交成功！`,
              type: "success",
            });
            this.$router.push("/productManage/salesControl-publish-list");
          }, 2000);
        }
      }).catch((err) => {
        loading.close();
      });
    },

    // 驳回
    rejectGoods (row) {
      let params = {};
      this.$emit("reject-goods", parmas);
    },

    watchEndit (val) {
      // 监听富文本编辑传过来的数据
      this.formTool.detail = val;
    },

    // 上传图片的字符串转 list
    strToArr (str) {
      let list = [];
      let newStr = str;
      if (newStr.indexOf(",") != -1) {
        let arr = newStr.split(",");
        arr.map((item) => {
          if (item.indexOf("http") != -1) {
            list.push(item);
          }
        });
      } else if (newStr.indexOf("http") != -1) {
        list.push(newStr);
      }
      return list;
    },

    // 上传文件的字符串转 fileList
    strTofileList (fileListStr) {
      console.log(fileListStr);
      let fileList = [];
      let newStr = fileListStr;
      if (newStr && newStr.indexOf(",") != -1) {
        let arr = newStr.split(",");
        arr.map((item) => {
          if (item.indexOf("http") != -1) {
            fileList.push({
              name:
                item.indexOf(item) != -1
                  ? item.substring(item.lastIndexOf("/") + 1)
                  : item,
              url: item,
            });
          }
        });
      } else if (newStr && newStr.indexOf("http") != -1) {
        fileList.push({
          name: newStr.substring(newStr.lastIndexOf("/") + 1),
          url: newStr,
        });
      }
      return fileList;
    },
  },
};
</script>
<style lang="scss" scoped>
.comContent {
  padding: 0px;
}
.addGoods {
  width: 100%;
  height: 40px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  .addFont {
    // border: 1px dashed #d9d9d9;
    display: flex;
    width: 18px;
    font-size: 16px;
    justify-content: center;
    margin-right: 5px;
  }
}
.header {
  height: 56px;
  line-height: 56px;
  padding: 0 24px;
  border-bottom: 1px solid #f5f5f5;
}
.product {
  // margin: 0 24px;
  margin-bottom: 24px;
}
.firstTitle {
  font-size: 16px;
  color: #262626;
  font-weight: 700;
}
.secondTitle {
  font-size: 14px;
  // color: #999999;
}
.upload-tips {
  font-size: 12px;
  color: #8c8c8c;
}
</style>
