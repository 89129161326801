var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comContent" }, [
    _c(
      "div",
      { staticClass: "center" },
      [
        _c("MyTable", {
          attrs: {
            "table-data": _vm.tableData,
            index: _vm.index,
            selection: _vm.selection,
            page: _vm.page,
            showPagination: false,
            operation: _vm.operation,
            "table-option": _vm.tableOption,
          },
          on: {
            "update:tableOption": function ($event) {
              _vm.tableOption = $event
            },
            "update:table-option": function ($event) {
              _vm.tableOption = $event
            },
            "page-change": _vm.getList,
            handleSelectionChange: _vm.currentChange,
          },
          scopedSlots: _vm._u([
            {
              key: "caozuo",
              fn: function (scope) {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.detail(scope.row)
                        },
                      },
                    },
                    [_vm._v("详情")]
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }