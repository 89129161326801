<template>
    <div style="display: flex;align-items: center;height: 70px;">
        <div class="eachStep">
            <img src="../../../assets/payStep1.png" alt="" style="width: 100%;height: 100%;" />
            <div class="eachTitle" style="color: #F5222D;">1. 确定订单信息</div>
        </div>
        <div class="eachStep">
            <img src="../../../assets/payStep2.png" alt="" style="width: 400px; heihgt: 32px;" />
            <span class="eachTitle" style="color: #ffffff;">2. 付款给供应商</span>
        </div>
        <div class="eachStep">
            <img src="../../../assets/payStep3.png" alt="" style="width: 400px; heihgt: 32px;" />
            <span class="eachTitle" style="color: #262626;">3. 确定收货</span>
        </div>
    </div>
</template>
<script >
    export default {
        data () {
            return {

            }
        },
        methods:{

        }
    }
</script>
<style lang="scss" scoped>
.eachStep {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 32px;
    position: relative;
}

.eachTitle {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}
</style>