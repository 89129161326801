<template>
  <el-dialog :title="title" :visible.sync="visible" :lock-scroll="true" width="1218px" :before-close="handleClose">
    <div>
      请您在每月的10号前，缴纳上个月的应付金额，超时将会扣除保证金
    </div>
    <div class="">
      <div class="center" style="position: relative">
        <MyTable :table-data="tableData" :index="index" :selection="selection" :page="page" :operation="operation"
          :table-option.sync="tableOption" @page-change="getList">
          <template slot="reason" slot-scope="scope">
            {{ scope.row.reason | filterReasonType }}
          </template>
        </MyTable>
      </div> 
    </div>
    
  </el-dialog>
</template>
    
<script>
import addDialog from "./submitCertificate.vue";
import MyTable from "@/components/myTable";
import fileUpload from "@/components/fileUpload";
import { relationGetPayList } from "@/api/offlinePayment";
import avater from "/public/img/default.png";
import imgUpload from '@/components/imgUpload';
export default {
  props: {},
  components: { imgUpload, fileUpload, MyTable, addDialog },

  data() {
    return {
     
      avater: avater,
      disabled: false,
      id: '',
      pageType: '',
      isDisabled: false,
      form: {

      },

      title: '',
      index: true,
      selection: false,
      visible: false,
      fileListShow: true,
      operation: false,
      tableData: [],
      tableOption: [
        { label: "订单创建时间", prop: "createTime", width: 160 },
        { label: "订单ID", prop: "orderCode", width: 200 },
        { label: "客户ERP编码", prop: "erpShopCode" },
        { label: "运营项目", prop: "deptName" },
        { label: "客户名称", prop: "payShopName" },
        { label: "客户所在区域", prop: "province", slot: true },
        { label: "商品总额（元）", prop: "amount", type: 'money' },
        { label: "运费（元）", prop: "freightPrice", type: 'money' },
        { label: "商家优惠金额（元）", prop: "coupon", type: 'money' },
        { label: "平台补贴（元）", prop: "platformSubsidy", type: 'money' },
        { label: "退款金额（元）", prop: "refundAmount", type: 'money' },
        { label: "技术服务费（元）", prop: "technicalMoney", type: 'money' },
        { label: "推广服务费（元）", prop: "promotionMoney", type: 'money' },
        { label: "结算金额（元）", prop: "settlementAmount", type: 'money' },
      ],
      page: {
        total: 0,
        current: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50],
      },
    }
  },
  created() {

  },
  mounted() {

  },
  watch: {},
  methods: {
    saveSubmit() {
      this.$refs['add-dialog'].init(this.form, 'edit')
    },
    getList(current) {
      if (current) {
        this.page.current = current;
      }
      const params = {
        current: this.page.current,
        size: this.page.size
      };
      params.voucherId = this.form.voucherId;

      relationGetPayList(params).then((res) => {
        if (res) {
          this.tableData = res.records;
          this.page.total = res.total;
        } else {
          this.tableData = [];
          this.page.total = 0;
        }
        this.visible = true;
      }).catch((err) => { });
    },
    // 页码变化
    handleCurrentChange(e) {
      this.page.current = e
    },

    // 条数变化
    handleSizeChange(e) {
      this.page.size = e
      this.page.current = 1
    },

    init(row, type) {
      this.title = '缴费明细'; 
      this.form = row;
      this.form.voucherId = row.id;
      this.getList();
    },

    handleClose() {
      this.visible = false;
    },
  }
}

</script>
<style lang="scss" scoped>
.eachRow {
  background-color: #fff;
  border-radius: 4px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
}

.titleView {
  display: flex;
  align-items: center;

  .rowLine {
    width: 4px;
    border-color: 1px;
    background-color: var(--main-color);
    height: 16px;
    margin-right: 8px;
  }

  .eachRowTitle {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
  }

  .cpxhead {
    display: flex;
    align-items: center;
  }

  .mark {
    color: #f56c6c;
    font-size: 12px;
    margin-left: 10px;
    width: 200px;
    line-height: 1.5;
    display: inline-block;
    font-weight: bold;
  }
}

.goods-list-warp {
  width: 100%;
  margin-left: 100px;
  max-height: 500px;
  overflow-y: auto;
}

.goods-list {
  width: 50%;
  border-bottom: solid 1px #f5f5f5;
  padding-bottom: 10px;

  &:nth-last-child(1) {
    border-bottom: none;
  }
}

.footer-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
  
  