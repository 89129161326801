var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "step-root h-step-list" },
    _vm._l(_vm.stepList ? _vm.stepList : [], function (item, index) {
      return _c(
        "div",
        {
          key: index,
          class: {
            item: true,
            focus: _vm.currentIndex === index + 1,
            finished: _vm.currentIndex > index + 1,
          },
        },
        [
          _c(
            "div",
            { staticClass: "full-number" },
            [
              _c("span", { staticClass: "number" }, [
                _vm._v(" " + _vm._s(index + 1) + " "),
              ]),
              _c("el-image", {
                staticClass: "finish-icon",
                attrs: { src: require("@/assets/icon_detail_status.png") },
              }),
            ],
            1
          ),
          _c("span", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
          index < _vm.stepList.length - 1
            ? _c("div", { staticClass: "split-line" })
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }