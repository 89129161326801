<template>
    <el-dialog title="关联商品" :visible.sync="dialogVisible" width="900px" height="1000px" :before-close="handleClose">
        <div>
            <div>商品名称：
                <el-input placeholder="输入商品名称" style="width: 200px;" size="small" @change="change" v-model="dialogInput">
                </el-input>
            </div>
            <div style="height: 800px;">
                <MyTable :table-data="dialogtableData" style="height: calc(100% - 30px);" :index="dialogindex"
                    :selection="dialogselection" :page="dialogpage" :operation="dialogoperation"
                    :table-option.sync="dialogtableOption" @page-change="dialoggetList"
                    @handleSelectionChange="currentChange">
                    <template slot="posterName" slot-scope="scope">
                        <div style="display: flex;justify-content: flex-start;align-items: center;">
                            <img :src="scope.row.image || avater" style="width: 80px;height: 80px;" alt="">
                            <div style="margin-left: 10px;">
                                <p style="margin: 3px 0;">{{ scope.row.name }}</p>
                                <p style="margin: 3px 0;">{{ scope.row.factory }}</p>
                                <p style="margin: 3px 0;">{{ scope.row.specification }}</p>
                            </div>
                        </div>
                    </template>
                    <template slot="price" slot-scope="scope">
                        <span>￥{{ scope.row.lowestSalePrice }}~￥{{ scope.row.highestSalePrice }}</span>
                    </template>
                </MyTable>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit" size="small">确 定</el-button>
            <el-button @click="handleClose" size="small">取 消</el-button>

        </span>
    </el-dialog>
</template>
    
<script>

import avater from "/public/img/default.png"
import MyTable from '@/components/myTable'
import { relevancePage } from '@/api/salesControl/commodity'
export default {
    props: {},
    data() {
        return {
            dialogVisible: false,
            dialogInput: '',
            // 下面是弹窗中的
            selectedData: [],
            dialogoperation: false,
            dialogselection: true,
            dialogindex: false, // 不展示序号，自己重新定义了序号
            dialogtableData: [
            ],
            dialogtableOption: [
                { label: '商品信息', prop: 'posterName', slot: 'true' },
                { label: '销售价格(元)', prop: 'price', slot: 'true' },
            ],
            dialogpage: { total: 10, current: 1, size: 10 },
        }
    },
    components: { MyTable },
    created() {

    },
    mounted() {
    },
    watch: {},
    methods: {
        currentChange(val) {
            this.selectedData = val;//checked选择商品
        },
        submit() {
         console.log(this.selectedData)
         this.$emit("chooseGoodsEmit",this.selectedData);
         this.dialogVisible = false
        },
        // 弹窗切换分页
        dialoggetList() {
            this.relevancePage();
        },
        // 弹窗搜索事件
        change() {
            this.relevancePage()
        },
        // 关闭弹窗
        handleClose() {
            this.dialogpage.current = 1
            this.dialogInput = ''
            this.dialogVisible = false
        },
        // 选择商品
        open() {
            this.dialogVisible = true;
            this.relevancePage();
        },
        relevancePage() {
            const { current, size } = this.dialogpage
            let params = {
                current, size,
                name: this.dialogInput
            }
            relevancePage(params).then(res => {
                this.dialogtableData = res.records
                this.dialogpage.total = res.total 
            })
        }
    }
}

</script>
<style lang="scss" scoped></style>
  
  