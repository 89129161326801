<!-- 选择单位弹窗 -->
<template>
  <div class="dialog">
    <el-dialog title="选择产品"
               :visible.sync="visible"
               :lock-scroll="true"
               top="50px"
               width="62%"
               :before-close="handleClose">
      <div class="dialogBox">
        <div class="header">
          <el-form ref="formData"
                   :model="formData"
                   :inline="true"
                   label-width="120px">
            <el-form-item prop="goodsOneClassId">
              <el-select v-model="formTool.goodsOneClassId"
                         size="small"
                         placeholder="一级分类"
                         clearable
                         style="max-width: 130px"
                         @change="changeOneClassId">
                <el-option v-for="item in goodsOneClassIdOptions"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item prop="goodsTwoClassId">
              <el-select v-model="formTool.goodsTwoClassId"
                         size="small"
                         placeholder="二级分类"
                         clearable
                         style="max-width: 130px"
                         @change="changeTwoClassId">
                <el-option v-for="item in goodsTwoClassIdOptions"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item prop="goodsThreeClassId">
              <el-select v-model="formTool.goodsThreeClassId"
                         size="small"
                         placeholder="三级分类"
                         clearable
                         style="max-width: 130px">
                <el-option v-for="item in goodsThreeClassIdOptions"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item prop="name">
              <el-input v-model="formTool.name"
                        placeholder="请输入药品名称"
                        size="small"
                        style="max-width: 140px" />
            </el-form-item>
            <el-form-item prop="factory">
              <el-input v-model="formTool.factory"
                        placeholder="请输入厂家关键词"
                        size="small"
                        style="max-width: 150px" />
            </el-form-item>
            <el-form-item prop="approvalNumber">
              <el-input v-model="formTool.approvalNumber"
                        placeholder="请输入药品批准文号"
                        size="small"
                        style="max-width: 160px" />
            </el-form-item>
            <el-form-item>
              <el-button size="small"
                         @click="getList()">检索</el-button>
              <el-button size="small"
                         @click="reset()">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div ref="tableCont"
             class="contentCont">
          <div class="contentContinner">
            <tableItem @chooseProduct="chooseProduct"
                       v-for="(item, idnex) in tableData"
                       :key="idnex"
                       :item="item" />
          </div>
        </div>

        <div class="mt20 footer-bar"
             style="margin-bottom: 24px">
          <Pagination :total="page.total"
                      :page-sizes="page.pageSizes"
                      :page.sync="page.current"
                      :limit.sync="page.size"
                      @pagination="pageChange" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tableItem from "./tableItem.vue";
import MyButton from "@/components/myButton";
import Pagination from "@/components/Pagination";
import { getNotcheckList } from "@/api/productManage/publishProduct";
import {
  goodsclassOne,
  goodsclassTwo,
  quantityApi,
} from "@/api/commodityreference";
import avater from "/public/img/default.png";
export default {
  props: {
    unitList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    showpage: true,
  },
  components: { MyButton, Pagination, tableItem },
  data () {
    return {
      widthNum: 0,
      heightNum: 0,
      avater: "",
      formData: {},
      item: {},
      goodsOneClassIdOptions: [],
      goodsTwoClassIdOptions: [],
      goodsThreeClassIdOptions: [],
      page: {
        current: 1,
        size: 10,
        total: 10,
      },
      formTool: {
        isAddProduct: 1,
        goodsOneClassId: "",
        goodsTwoClassId: "",
        goodsThreeClassId: "",
        name: "", // 药品名称
        factory: "", // 厂家关键字
        approvalNumber: "", // 批准号
      },
      productChooseItem: {},
      tableData: [],
      checkIndex: 0,
      // dialogVisible: false,
    };
  },
  computed: {
    visible: {
      get () {
        return this.dialogVisible;
      },
      set (val) {
        this.$emit("update:dialogVisible", val);
      },
    },
  },
  watch: {
    "sidebar.opened": {
      handler (newVal, oldVal) {
        this.widthNum = Math.floor(
          (this.$refs.tableCont.clientWidth - 48) / 222
        );
      },
    },
  },
  methods: {
    // 选择产品
    chooseProduct (item) {
      console.log(item);
      this.productChooseItem = item;
      this.$parent.dialogVisible = false;
      this.$emit("chooseProudctItem", item);
    },
    comfirm () { },
    cancel () {
      this.$parent.dialogVisible = false;
    },
    reset () {
      this.formTool = {};
      this.getList();
    },
    pageChange (value) {
      this.page.size = value.limit;
      this.page.current = value.page;
      this.getList();
    }, // 初次进来只查询一级分类 不联动
    query () {
      goodsclassOne()
        .then((res) => {
          if (res.code == 0) {
            this.goodsOneClassIdOptions = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换一级分类
    changeOneClassId (val) {
      if (val) {
        this.goodsclassTwo(val);
      }
    },
    // 切换二级分类
    changeTwoClassId (val) {
      if (val) {
        this.goodsclassThree(val);
      }
    },

    // 查询一级分类
    goodsclassOne () {
      goodsclassOne()
        .then((res) => {
          if (res.code == 0) {
            this.goodsOneClassIdOptions = res.data;
            if (this.goodsOneClassIdOptions.length > 0) {
              this.formTool.goodsOneClassId = this.goodsOneClassIdOptions[0].id;
              this.goodsclassTwo(this.goodsOneClassIdOptions[0].id);
            } else {
              this.formTool.goodsOneClassId = "";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询二级分类
    goodsclassTwo (id) {
      goodsclassTwo(id)
        .then((res) => {
          if (res.code == 0) {
            this.goodsTwoClassIdOptions = res.data;
            if (this.goodsTwoClassIdOptions.length > 0) {
              this.formTool.goodsTwoClassId = "";
              this.formTool.goodsThreeClassId = "";
              this.goodsThreeClassIdOptions = [];
              this.goodsclassThree(this.goodsTwoClassIdOptions[0].id);
            } else {
              this.formTool.goodsTwoClassId = "";
              this.formTool.goodsThreeClassId = "";
              this.goodsThreeClassIdOptions = [];
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询三级分类
    goodsclassThree (id) {
      goodsclassTwo(id)
        .then((res) => {
          if (res.code == 0) {
            this.goodsThreeClassIdOptions = res.data;
            if (this.goodsThreeClassIdOptions.length > 0) {
              this.formTool.goodsThreeClassId = "";
            } else {
              this.formTool.goodsThreeClassId = "";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleSelectionChange (val) { },
    handleCurrentChange (val) { },

    // 确认选中的单位
    submitCheck (item, index) {
      this.checkIndex = index;
      // this.formData.skus[this.tableIndex].unit = item
      this.$emit("submitCheck", item);
      this.$parent.dialogVisible = false;
    },
    getList () {
      const params = {
        current: this.page.current,
        size: this.page.size,
        ...this.formTool,
      };
      getNotcheckList(params).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 选中
    handleClose () {
      this.$parent.dialogVisible = false;
    },
  },
  created () {
    // this.page.size = this.widthNum * this.heightNum;
    this.getList();
    this.query();
  },
  mounted () { },
  beforeCreate () { },
  beforeMount () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  activated () { },
};
</script>
<style lang='scss' scoped>
@import "@/styles/variables.scss";
.dialogBox {
  span {
    font-size: 14px;
    color: #595959;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 5px 15px;
  }
  .everyOneBox {
    border: 1px solid #dcdfe6;
  }
  .activeBox {
    border: 2px solid #f5222d;
  }
}
.contentCont {
  // height: calc(100% - 210px);
  // padding: 0px 24px 0 16px;
  // overflow: auto;
  // display: flex;
  // justify-content: center;
  .contentContinner {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.itemWarpList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.footer-bar {
  display: flex;
  justify-content: space-between;
}
</style>
