import request from '@/utils/request'
import { Message } from 'element-ui'

// 省区订单，商品数量统计
export function queryProvinceOrderDetailsStatistics(data) {
    return request({
        url: '/statistic/headquartersFinancialStatistics/queryProvinceOrderDetailsStatistics',
        method: 'post',
        data
    })
}
// 根据开始时间，结束时间，当日，当月标识查询全国相关收帐信息
export function queryFinancialCollectionStatistics(data) {
    return request({
        url: '/statistic/headquartersFinancialStatistics/queryFinancialCollectionStatistics',
        method: 'post',
        data
    })
}

// 根据省办orgCode获取地办分账信息
export function provinceOfficeOfAcquisitionPage(data) {
    return request({
        url: '/statistic/headquartersFinancialStatistics/provinceOfficeOfAcquisitionPage',
        method: 'get',
        params: data
    })
}
// 财务统计导出
export function exportStatistics(data) {
    request({
        url: '/statistic/headquartersFinancialStatistics/export',
        method: 'post',
        responseType: 'blob',
        data
    }).then(response => {
        // 处理返回的文件流
        const blob = response.data
        if (blob && blob.size === 0) {
          Message.warning('内容为空，无法下载')
          return
        }
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = '统计.xlsx'
        link.click()
        window.setTimeout(function() {
            window.URL.revokeObjectURL(link.href)
        }, 0)
      })
}

// 根据省办orgCode获取地办分账信息导出
export function exportProvinceStatistics(data) {
    request({
        url: '/statistic/headquartersFinancialStatistics/provinceOfficeOfAcquisitionExport',
        method: 'get',
        responseType: 'blob',
        params: data
    }).then(response => {
        // 处理返回的文件流
        const blob = response.data
        if (blob && blob.size === 0) {
          Message.warning('内容为空，无法下载')
          return
        }
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = '地区统计.xlsx'
        link.click()
        window.setTimeout(function() {
            window.URL.revokeObjectURL(link.href)
        }, 0)
      })
}

// 自适应统计设置报表
export function queryGoodsSalesEcharts(data) {
  return request({
      url: '/statistic/settings/adaption',
      method: 'post',
      data
  })
}
// 自适应统计设置报表导出
export function queryGoodsSalesEchartsExport(data,fileName = '') {
  return request({
      url: '/statistic/settings/export',
      method: 'post',
      data,
      responseType: 'blob'
  }).then(response => {
    // 处理返回的文件流
    const blob = response.data
    if (blob && blob.size === 0) {
      Message.warning('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([blob]));
    link.download = (fileName || '报表导出')+ '.xlsx'
    link.click()
    window.setTimeout(function() {
        window.URL.revokeObjectURL(link.href)
    }, 0)
  })
}
// 查询新消肿，胃药的商品信息
export function getSelfControlGoodsType(data) {
    return request({
        url: '/statistic/goodsBaser/selectByGoodsCode',
        method: 'get',
        params:data
    })
}

export function getStatisticSalesOrderBillsDetail(data) {
    return request({
        url: `/statistic/sales/order/bills/detail/${data.orderId}`,
        method: 'get',
        params:data
    })
}



export function excelExport(data) {
    return request({
        url: `/statistic/settings/export`,
        method: "post",
        responseType: 'blob',
        data:data
    }).then(response => {
        // 处理返回的文件流
        const blob = response.data
        if (blob && blob.size === 0) {
            Message.warning('内容为空，无法下载')
            return
        }
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        const contentDisposition = response.headers['content-disposition'];
        const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1));
        console.log(fileName)
        link.download = fileName;
        document.body.appendChild(link)
        link.click()
        window.setTimeout(function() {
            URL.revokeObjectURL(blob)
            document.body.removeChild(link)
            Message.success('导出成功')
        }, 0)
    });
}

