<template>
  <div class="fundCenterView"
       ref="fundCenterView">
    <el-row>
      <el-col :span="6">
        <div class="grid-content ">
          <div class="inline">
            <img :src="fundamount"
                 alt=""
                 class="icon">
            <span class="title">订单收入总金额（元）</span>
          </div>
          <div class="moneyLine">
            <span class="moneyType">￥</span>
            <span class="money">{{ getMoney(canWithdrawMoney) }}</span>
            <el-button size="mini"
                       type="primary"
                       @click="doWithDraw">提现</el-button>
            <el-button type="primary"
                       :loading="loading"
                       size="mini"
                       icon="el-icon-refresh-right"
                       @click="getWithDrawMoney"></el-button>
          </div>
        </div>
      </el-col>
    
    </el-row>
    <div class="tableView">
      <div class="tableTabs">
        <el-tabs v-model="tabActiveName"
                 @tab-click="handleClick">
          <el-tab-pane :name="item.name"
                       :label="item.name"
                       v-for="item, index in tabsList"
                       :key="index">
            <span v-if="item.count  > 0"
                  slot="label">{{item.name}}
              <el-badge :value="item.count"
                        class="item" />
            </span>
          </el-tab-pane>
        </el-tabs>
        <el-form ref="form"
                 :model="form"
                 :inline="true">
          <el-form-item>
            <el-input v-model.trim="form.orderId"
                      size="small"
                      placeholder="请输入订单ID"
                      clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker v-model="form.dateValue"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="订单开始日期"
                            end-placeholder="订单结束日期"
                            size="small"
                            clearable>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="form.erpOrName"
                      size="small"
                      placeholder="请输入客户ERP编码/客户名称"
                      style="width:240px"
                      clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.type"
                       placeholder="收支类型"
                       size="small"
                       clearable>
              <el-option v-for="item in withdrawType"
                         :key="item.code"
                         :label="item.value"
                         :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small"
                       type="primary"
                       @click="queryFirstPage">查询</el-button>
            <el-button size="small"
                       type="primary"
                       @click="exportWithdraw">导出</el-button>
          </el-form-item>
        </el-form>
      </div>
      <withdrawListTable :tableHeight="tableHeight"
                         v-show="tabActiveName == '收支明细'"
                         ref="withdrawTable"
                         @pageInfoDidChange="pageInfoDidChange" />
      <moneyListTable :tableHeight="tableHeight"
                      v-show="tabActiveName == '提现申请'"
                      ref="applyTable"
                      @pageInfoDidChange="pageInfoDidChange"
                      @agreeWithdraw="agreeWithdraw" />
      <div class="paninationView">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-sizes="[10, 20, 30, 50, 100]"
                       :page-size="pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </div>
    <fundWithdrawDialog :tableHeight="dialogTableHeight"
                        @codeData="didClickConfirm"
                        :dialogVisible="withdrawDialogVisible"
                        @dialogDimiss="didClickCancel"
                        ref="withDrawDialogRef" />
    <el-dialog title="短信验证码"
               :visible.async="codeVisible"
               width="500px"
               :before-close="handleCloseCode">
      <el-form label-width="120px">
        <el-form-item label="验证码">
          <el-input v-model="varifyCode"
                    style="width: 260px;"
                    placeholder="请输入"
                    size="small" />
        </el-form-item>
        <el-form-item>
          <div style="width: 260px;display: flex;align-items: center;justify-content: flex-end;">
            <el-button type="default"
                       size="small"
                       @click="handleCloseCode">返回</el-button>
            <el-button type="primary"
                       size="small"
                       @click="withdrawalValidaAction">确认</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="提现"
               :visible.sync="agreeDialogVisible"
               width="80%"
               :close-on-click-modal="false">
      <el-form ref="form"
               :model="agreeForm"
               :inline="true">
        <el-form-item label="提现金额">
          <el-input v-model="agreeForm.money"
                    size="small"
                    :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="支付密码" >
          <el-input v-model.trim="agreeForm.passWord"
                    size="small"
                    show-password></el-input>
        </el-form-item>
        <el-form-item>
          <span class="dialog-footer">
            <el-button type="primary"
                       @click="makeSure"
                       size="small">确认提现</el-button>
            <el-button @click="agreeDialogVisible=false"
                       size="small">返 回</el-button>
          </span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import fundWithdrawDialog from "./component/fundWithdrawDialog";
import withdrawListTable from "./component/withdrawListTable";
import moneyListTable from "./component/moneyListTable";
import fundaccount from "@/assets/fundaccount.png";
import fundcard from "@/assets/fundcard.png";
import fundamount from "@/assets/fundamount.png";
import check from "@/assets/check.png";
import uncheck from "@/assets/uncheck.png";
import { withdrawalMoney, submitCnt, withdrawalValida, downloadWithdrawList, doDeptWithdraw, withdrawalValidaByDept } from "@/api/fund/fundApi";
import dayjs from "dayjs";
import _ from "lodash";
import { guaranteeInfo } from "@/api/surety";

export default {
  components: { fundWithdrawDialog, withdrawListTable, moneyListTable },
  data () {
    return {
      loading: false,
      tabsList: [{ name: '收支明细', count: '' }, { name: '提现申请', count: '' }],
      tabActiveName: '收支明细',
      tableHeight: 0,
      dialogTableHeight: 0,
      withdrawDialogVisible: false,
      fundaccount: fundaccount,
      fundamount: fundamount,
      fundcard: fundcard,
      uncheck: uncheck,
      check: check,
      autoWithdraw: false,
      form: {
        erpOrName: '',
        dateValue: [],
        type: '',
        orderId: ''
      },
      varifyCode: '',//短信验证码
      codeVisible: false,
      currentPage: 1,
      pageSize: 10,
      total: 10,
      codeValidateData: undefined,//短信验证
      codeValidateSelectOrderList: [],//短信验证再次验证选择的订单
      withdrawType: [{ code: 'INCOME', value: '收入' }, { code: 'EXPENDITURE', value: '提现' }],
      canWithdrawMoney: '',//可提现金额
      agreeForm: {
        money: '',
        passWord: '',
        id: ''
      },
      agreeDialogVisible: false,
      isAgreeOrSelfWithdraw: 'self',//医药公司提现或者同意部门提现
    }
  },
  mounted () {
    this.tableHeight = this.$refs.fundCenterView.offsetHeight - 120 - 56 - 56 - 40
    this.dialogTableHeight = this.$refs.fundCenterView.offsetHeight * 0.6
    this.getWithDrawMoney()
    this.getSubmitCnt()
    this.getList()
  },
  methods: {
    agreeWithdraw (e) {
      // 医药公司同意部门提现
      this.agreeForm.money = e.amount.toFixed(2)
      this.agreeForm.id = e.id
      this.agreeDialogVisible = true
    },
    async makeSure () {
      // 医药公司同意提现 确认
      if (!this.agreeForm.passWord) {
        this.$message.warning('请输入密码')
        return
      }
      let obj = {
        id: this.agreeForm.id,
        amount: this.agreeForm.money,
        password: this.agreeForm.passWord,
      }
      const resp = await doDeptWithdraw(obj)
      if (resp) {
        this.isAgreeOrSelfWithdraw = 'dept'
        this.agreeDialogVisible = false
        this.codeValidateData = resp
        this.codeVisible = true
      }
    },
    getMoney (e) {
      if (!e) {
        return '0.00'
      } else {
        return e.toFixed(2)
      }
    },
    pendParams (type = 'query') {
      let obj = type == 'query' ? { current: this.currentPage, size: this.pageSize } : {}

      obj['name'] = this.form.erpOrName || null //	请输入客户ERP编码/客户名称	query	false	
      obj['orderCode'] = this.form.orderId || null //	订单编码	query	false	
      if (this.form.dateValue && this.form.dateValue.length) {
        // startDate:'',//	订单创建开始时间	query	false	
        // endDate:'',// 订单创建结束时间
        obj['startDate'] = dayjs(this.form.dateValue[0]).format('YYYY-MM-DD')
        obj['endDate'] = dayjs(this.form.dateValue[1]).format('YYYY-MM-DD')
      }
      obj['amountType'] = this.form.type || null //amountType:'',//	余额变动类型,可用值:INCOME,EXPENDITURE	query	false	

      return obj
    },
    queryFirstPage () {
      this.currentPage = 1
      this.getList()
    },
    exportWithdraw () {
      // 导出提现记录
      const obj = this.pendParams('export')

      downloadWithdrawList(obj)
    },
    doWithDraw () {
      // 判断 保证金阈值 
      guaranteeInfo({}).then(res => {
        let lockAmount = res.lockAmount || 0;
        let useAmount = res.useAmount || 0;
        let stopThreshold = res.stopThreshold || 0;
        if (lockAmount + useAmount < stopThreshold) {
          this.$message.warning("当前保证金余额小于保证金阈值，无法提现。")
          return
        } else { 
          this.isAgreeOrSelfWithdraw = 'self'
          this.$refs.withDrawDialogRef.getList()
          this.withdrawDialogVisible = true
        }
      })  
    },
    async getSubmitCnt () {
      // 获取部门申请数量
      const resp = await submitCnt()
      if (resp) {
        this.tabsList = [{ name: '收支明细', count: '' }, { name: '提现申请', count: resp || '' }]
      }
    },
    async getWithDrawMoney () {
      this.loading = true
      const resp = await withdrawalMoney()
      if (resp) {
        setTimeout(() => {
          this.loading = false
        }, 1000)

        this.canWithdrawMoney = resp
      } else {
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }
    },
    handleClick (e) {
      this.currentPage = 1
      this.getList()
    },
    async didClickConfirm (e, v) {
      this.codeValidateData = e
      this.codeValidateSelectOrderList = v
      this.codeVisible = true
    },
    didClickCancel () {
      this.withdrawDialogVisible = false
    },
    openAutoWithdraw () {
      this.autoWithdraw = true
    },
    handleCloseCode () {
      this.codeVisible = false
      this.$refs.withDrawDialogRef.resetSelections()
    },
    async withdrawalValidaAction () {
      if (this.isAgreeOrSelfWithdraw == 'self') {
        // 医药公司体现
        const { orderB2bDataVO } = this.codeValidateData
        let obj = {
          itemIdSet: this.codeValidateSelectOrderList,
          payerId: orderB2bDataVO.userId,
          token: orderB2bDataVO.token,
          totalAmount: orderB2bDataVO.totalAmount,
          txnSeqno: orderB2bDataVO.txnSeqno,
          verifyCode: this.varifyCode,
          paySerialNo: this.codeValidateData.paySerialNo
        }
        const resp = await withdrawalValida(obj)
        if (resp) {
          this.codeVisible = false
          this.$message.success('提现成功')
          this.getList()
          this.$refs.withDrawDialogRef.getList()
        }
      } else {
        // 同意部门提现
        const { orderB2bDataVO } = this.codeValidateData
        let obj = {
          id: this.agreeForm.id,
          payerId: orderB2bDataVO.userId,
          token: orderB2bDataVO.token,
          totalAmount: orderB2bDataVO.totalAmount,
          txnSeqno: orderB2bDataVO.txnSeqno,
          verifyCode: this.varifyCode,
          paySerialNo: this.codeValidateData.paySerialNo
        }
        const resp = await withdrawalValidaByDept(obj)
        if (resp) {
          this.codeVisible = false
          this.agreeForm.id = ''
          this.agreeForm.money = ''
          this.agreeForm.passWord = ''
          this.$message.success('提现成功')
          this.getList() //获取当前的部门提现申请列表
          this.getSubmitCnt() //重新获取部门提现申请的数量
        }
      }

    },
    getList () {

      const obj = this.pendParams()

      if (this.tabActiveName == '收支明细') {
        this.$refs.withdrawTable?.getList(obj)
      } else {
        this.$refs.applyTable?.getList(obj)
      }
    },
    handleSizeChange (e) {
      // this.resetPageInfo()
      this.pageSize = e
      this.getList()
    },
    handleCurrentChange (e) {
      // this.resetPageInfo()
      this.currentPage = e
      this.getList()
    },
    applyAccount () {
      // 申请开户
      this.$router.push({
        path: '/fund/fund-fundAccount',

      })
    },
    pageInfoDidChange (e) {
      // 分页数据改变  得到总页数等 current: 1   total: 95
      this.currentPage = e.current
      this.total = e.total
    }
  }
}
</script>
<style lang="scss" scoped>
.fundCenterView {
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  height: 100%;

  .row-bg {
    background: #fff;
  }

  .grid-content {
    height: 120px;
    padding: 24px;
  }

  .tableView {
    background-color: #fff;
    padding: 0px 24px;

    .tableTabs {
      ::v-deep(.el-tabs__active-bar) {
        bottom: 6px !important;
      }

      ::v-deep(.el-date-editor .el-range-input) {
        width: 50%;
      }
    }

    .flexRight {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
  }

  .inline {
    display: flex;
    align-items: center;

    .icon {
      width: 22px;
      height: 22px;
    }

    .title {
      color: #595959;
      font-size: 14px;
      margin-left: 5px;
    }
  }

  .moneyLine {
    display: flex;
    align-items: flex-end;
    color: rgba($color: #000000, $alpha: 0.85);
    margin-top: 10px;

    .moneyType {
      font-weight: 700;
      font-style: 16px;
      // margin-bottom: 3px;
    }

    .money {
      font-weight: 700;
      font-size: 30px;
      line-height: 1;
      margin-right: 12px;
    }
  }

  .account {
    margin-top: 10px;
    color: #262626;
    font-size: 14px;
    font-weight: 400;
  }

  .cardView {
    margin-top: 10px;
  }

  .checkView {
    display: flex;
    align-items: center;
    margin-left: 24px;
    cursor: pointer;
  }
  .paninationView {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>