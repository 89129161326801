<template>
  <div>
    <el-dialog
      title="设置库存"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form size="small" class="form-centent">
        <div class="row">
          <div class="col">
            <div class="tip">当前库存</div>
            <div class="value">
              <el-input
                disabled
                :value="
                  rowData && rowData.goodsSkuInventory
                    ? rowData.goodsSkuInventory
                    : ''
                "
              />
            </div>
          </div>
          <div class="col">
            <div class="tip">库存调整为</div>
            <div class="value">
              <el-input v-model="formIn.goodsSkuInventory" />
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="doUpdate()">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { commentSave } from "@/api/businessManage";
import _ from "lodash";
import { skuUpdate } from "@/api/salesControl/commodity";
import { updatePriceOrStock } from "@/api/goods";

export default {
  components: {},
  props: {
    requestId: {
      type: String,
      default: () => "",
    },

    limit: {
      type: Number,
      default: () => 10,
    },
  },
  computed: {
    headers: function () {
      return {
        Authorization: "Bearer " + getToken(),
      };
    },
  },
  data() {
    return {
      dialogVisible: false,
      rowData: null,

      formIn: {
        goodsSkuInventory: "",
      },
    };
  },
  methods: {
    doUpdate() {
      if (!this.formIn.goodsSkuInventory) {
        this.$message.error("请输入当前库存调整数");
        return;
      }

      let data = { id: this.rowData.skuId, beSynStock: false }; //能编辑，肯定是不同步
      // _.merge(data, this.rowData);
      _.merge(data, this.formIn);

      updatePriceOrStock(data).then((res) => {
        if (res) {
          this.$message.success("更新价格成功");
          this.$emit("updateSuccess");
          this.handleClose();
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    init() {
      //
    },
    clear() {
      this.rowData = null;
      for (let key in this.formIn) {
        this.formIn[key] = "";
      }
    },
    open(item) {
      this.clear();
      this.dialogVisible = true;
      this.rowData = _.cloneDeep(item);
    },
  },
};
</script>
<style scoped lang="scss">
.form-centent {
  .row {
    display: flex;

    &:not(:first-child) {
      margin-top: 24px;
    }

    .col {
      flex: 1;
      display: flex;
      flex-direction: column;

      &:nth-child(2) {
        margin-left: 24px;
      }

      .tip {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
