<!--:span="24"
 * @FilePath: /eshop-ops-web/src/views/goodsManage/baseDepot/components/goodsInfo.vue
 * @Description: 
 * @Author: xiexingzhong2012@sina.cn
 * @Date: 2023-07-24 20:01:25
 * @LastEditTime: 2023-07-30 20:22:44
 * @LastEditors: xiexingzhong2012@sina.cn
-->
<template>
  <div class="">
    <div style="mainView">
      <el-row class="lable-list">
        <el-col :span="24">
          <el-form
            ref="formOld"
            label-width="120px"
            size="small"
            :disabled="true"
            label-position="right"
          >
            <formList
              ref="form-list"
              :formTool="formOld"
              :source="1"
            ></formList>
          </el-form>
        </el-col>
        <!-- <el-col :span="11">
          <el-form
            ref="goods-info"
            label-width="120px"
            :model="formTool"
            :rules="rules"
            size="small"
            :disabled="false"
            label-position="right"
          >
            <formList
              :changeForm="changeForm"
              :changeExtendParams="changeExtendParams"
              ref="form-tool"
              :formTool="formTool"
              :source="2"
            ></formList>
          </el-form>
        </el-col> -->
      </el-row>
    </div>
    <el-row :gutter="2">
      <el-col :span="24">
        <el-form
          label-width="120px"
          size="small"
          :disabled="true"
          label-position="right"
        >
          <el-form-item label="商品详情">
            <wangEnduit
              :value="formOld.detail"
              :is-clear="isClear"
              :isDisabled="true"
              @changeEndit="watchEndit"
            />
          </el-form-item>
        </el-form>
      </el-col>

      <!-- <el-col :span="24">
        <el-form
          label-width="120px"
          :model="formTool"
          :rules="rules"
          size="small"
          :disabled="disabled"
          label-position="right"
        >
          <el-form-item
            label="商品详情(纠错)" 
          >
           <span
            slot="label"
            :class="changeForm.detail ? 'lable-color-correct' : ''"
            >商品详情(纠错)</span
          >
            <wangEnduit
              :value="formTool.detail"
              :is-clear="isClear"
              :isDisabled="false"
              @changeEndit="watchEndit"
            />
          </el-form-item>
        </el-form>
      </el-col> -->
    </el-row>

    <div class="mt20">
      <el-button
        type="primary"
        style="width: 160px"
        size="small"
        @click="nextPage"
        >下一步</el-button
      >
      <el-button size="small" style="width: 160px" @click="back()"
        >返回</el-button
      >
    </div>
  </div>
</template>
<script>
// ON_SALE:销售中，
// MERCHANT_REMOVAL.商家下架，
// PLATFORM_REMOVAL:平台下架，
// BASE_AWAIT:基库待审核，
// BASE_ALREADY:审核通过，
// SKU_AWAIT：上架待审核，
// SKU_FAIL：商品审核未通过，
// BASE_TEMPORARY_FAIL：基库审核未通过
import imgUpload from "@/components/imgUpload";
import fileUpload from "@/components/fileUpload";
import wangEnduit from "@/components/wangEnduit";
import DynamicAttribute from "@/components/dynamicAttribute";
import GoodsThreeClass from "@/components/goodsThreeClass";
import getComList from "@/utils/getComList";
import formList from "./formList";

import {
  getAttributeByTypeId,
  getCompGoodsBaseDetail,
} from "@/api/goodsManage/baseDepot.js";

export default {
  components: {
    imgUpload,
    wangEnduit,
    fileUpload,
    DynamicAttribute,
    GoodsThreeClass, //商品三级分类
    formList,
  },
  props: {},
  data() {
    return {
      id: null,
      status: null,
      pageType: null,
      disabled: false,
      formTool: {
        goodsType: null,
        details: null, // 商品详情
        goodsOneClassId: "",
        goodsTwoClassId: "",
        goodsThreeClassId: "",
        typeList: [],
        name: "", // 产品名称
        imageList: [], // 图片
        fileListStr: "",
        fileList: [],
        extendParams: [], //拓展属性
        businessScopeId: null, //经营范围
        specification: null,
        approvalNumber: null,
        factory: null,
      },

      formOld: {
        goodsType: null,
        details: null, // 商品详情
        goodsOneClassId: "",
        goodsTwoClassId: "",
        goodsThreeClassId: "",
        typeList: [],
        name: "", // 产品名称
        imageList: [], // 图片
        fileListStr: "",
        fileList: [],
        extendParams: [], //拓展属性
        businessScopeId: null, //经营范围
        specification: null,
        approvalNumber: null,
        factory: null,
      },
      changeForm: {},
      rules: {
        name: [{ required: true, message: "请输入产名称", trigger: "blur" }],
        goodsType: [
          { required: true, message: "请输入商品类型", trigger: "blur" },
        ],
        businessScopeId: [
          { required: true, message: "请输入经营范围", trigger: "blur" },
        ],
        specification: [
          { required: true, message: "请输入规格", trigger: "blur" },
        ],
        approvalNumber: [
          { required: true, message: "请输入批准文号", trigger: "blur" },
        ],
        factory: [
          { required: true, message: "请输入生产厂家", trigger: "blur" },
        ],
        goodsOneClassId: [
          { required: true, message: "请输入第一级分类", trigger: "change" },
        ],
        goodsTwoClassId: [
          { required: true, message: "请输入第二级分类", trigger: "change" },
        ],
        goodsThreeClassId: [
          { required: true, message: "请输入第三级分类", trigger: "change" },
        ],
        imageList: [
          { required: true, message: "请上传图片", trigger: "change" },
        ],
      },
      isClear: false, // 清除富文本编辑内容
      changeExtendParams: {},
    };
  },
  computed: {},
  watch: {
    formTool: {
      handler(newVal, oldVal) {
        this.watchFormToolValue(newVal, oldVal);
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    if (this.$route.query.status) {
      this.status = this.$route.query.status;
    }
    if (this.$route.query.type !== "edit") {
      this.disabled = true;
    }
    this.typeList();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  methods: {
    nextPage(){
      this.$router.push({
        path: '/productManage/publishProduct-addGoodsToStore', query: {
          id:  this.$route.query.id,
          activeName: 'add',
          type: 'add'
        }
      })
    },
    goNext() {
      this.$router.push(
        "./goodsManage-baseDepot-details?pageType=5&id" + this.$route.query.id
      );
    },
    async typeList() {
      this.formTool.typeList = await getComList.goodsType; // 商品类型
      this.formOld.typeList = await getComList.goodsType; // 商品类型
      // this.formTool.scopeList = await getComList.scopeList; //经营范围
      let scopeList = await getComList.scopeList; //经营范围
      this.$set(this.formOld, "scopeList", scopeList); // 子组件渲染前set数据。 避免 父组件更新，子组件未更新
      this.$set(this.formTool, "scopeList", scopeList);

      // 查询详情信息
      if (this.$route.query.id != undefined) {
        this.id = this.$route.query.id;
        this.getDetail(this.$route.query.id);
      }
      this.$forceUpdate();
    },

    back() {
      // this.$emit("back");
      // productManage/publishProduct-index
      this.$router.push({
        path: '/productManage/publishProduct-index', query: {
          
        }
      })
    },

    // 监听属性值变化
    watchFormToolValue(newVal, oldVal) {
      // console.log("变化了");
      this.compare(this.formTool, this.formOld);
      this.compare2(this.formTool.extendParams, this.formOld.extendParams);
    },

    compare(obj1, obj2) {
      for (let key in obj1) {
        if (key == "imageList") {
          if (obj1[key].join() != obj2[key].join()) {
            this.$set(this.changeForm, key, true);
          } else {
            this.$set(this.changeForm, key, false);
          }
        } else {
          if (obj1[key] != obj2[key]) {
            this.$set(this.changeForm, key, true);
          } else {
            this.$set(this.changeForm, key, false);
          }
        }
      }
    },

    compare2(obj1, obj2) {
      if (obj1) {
        for (let i = 0; i < obj1.length; i++) {
          if (obj1[i].value != obj2[i].value) {
            this.changeExtendParams[obj1[i].fieldName] = true;
          } else {
            this.changeExtendParams[obj1[i].fieldName] = false;
          }
          if (obj1[i].value == "" && obj2[i].value == null) {
            this.changeExtendParams[obj1[i].fieldName] = false;
          }
        }
      }

      console.log(this.changeExtendParams, "fieldName");
    },

    /**
     * @description: 初始化加载 配置的 list： 1. 商品类型 2. 经营范围
     * @return {*}
     */
    formatterStr(formTool) {
      if (formTool.fileListStr && formTool.fileListStr.indexOf("http") != -1) {
        formTool.fileList = this.strTofileList(formTool.fileListStr);
      }
      if (formTool.imageList && formTool.imageList.indexOf("http") != -1) {
        formTool.imageList = this.strToArr(formTool.imageList);
      } else {
        formTool.imageList = [];
      }
    },

    getDetail(id) {
      /**
       * @description:   // 1.获取商品详情信息，2.映射到formTool 对象 。
       * @return {*}
       */
      const params = {
        id: id,
        isMerchant: true, //false 查所有拓展属性 true查询必填
      };
      getCompGoodsBaseDetail(params).then((res) => {
        if (res.code === 0) {
          // console.log(res);

          // 基库详情
          let compGoodsBaseVo = res.data.compGoodsBaseVo;
          this.formOld = Object.assign(this.formOld, compGoodsBaseVo); //合并2个对象
          this.formOld.extendParams = res.data.extendParams;
          this.formTool = JSON.parse(JSON.stringify(this.formOld));

          this.$nextTick(() => {
            this.extendParamsFormatter();
            // console.log(this.formOld.extendParams, "---extendParams11111");
            // this.$refs["form-tool"].init(this.formTool.scopeList);
            this.$refs["form-list"].init(this.formOld.scopeList);
            // this.$refs["form-tool"].queryThreeClass();
            this.$refs["form-list"].queryThreeClass();
          });

          this.formatterStr(this.formTool); //数据类型转换
          this.formatterStr(this.formOld); //数据类型转换
          this.$refs["form-list"].isShowProducer(this.formOld.goodsType, this.formOld.typeList);
          this.$forceUpdate();
        }
      });
    },

    extendParamsFormatter() {
      for (let i = 0; i < this.formOld.extendParams.length; i++) {
        if (this.formOld.extendParams[i].input == "2") {
          this.formOld.extendParams[i].selectList =
            this.formOld.extendParams[i].selectEnum.split("，");
        }
        if (this.formOld.extendParams[i].input == "3") {
          this.formOld.fileList = this.strTofileList(
            this.formOld.extendParams[i].value
          );
        }
      }

      for (let i = 0; i < this.formTool.extendParams.length; i++) {
        if (this.formTool.extendParams[i].input == "2") {
          this.formTool.extendParams[i].selectList =
            this.formTool.extendParams[i].selectEnum.split("，");
        }
        if (this.formTool.extendParams[i].input == "3") {
          this.formTool.fileList = this.strTofileList(
            this.formTool.extendParams[i].value
          );
        }
      }
    },

    /**
     * @functionName: onRemoveHandler
     * @description: 删除上传的文件
     * @return {*}
     */
    onRemoveHandler(file) {
      let index = this.formTool.fileList.indexOf(file);
      this.formTool.fileList.splice(index, 1);
    },

    /**
     * @description: submit 方法 提交form 数据给业务调用方
     * @return {*} fromlToll
     */
    submit() {
      const params = Object.assign({}, this.formTool);
      let fileListStr = "";

      if (this.formTool.fileList) {
        for (let j = 0; j < this.formTool.fileList.length; j++) {
          fileListStr += this.formTool.fileList[j].url + ",";
        }
      }
      this.formTool.fileListStr = fileListStr;

      if (this.formTool.fileList) {
        for (let i = 0; i < this.formTool.extendParams.length; i++) {
          if (
            this.formTool.fileList.length > 0 &&
            this.formTool.extendParams[i].input == "3"
          ) {
            this.formTool.extendParams[i].value = this.formTool.fileListStr;
          }
        }
      }

      if (this.formTool.extendParams) {
        let extendParamsList = [...this.formTool.extendParams];
        let extendParamsObj = {};
        for (let i = 0; i < extendParamsList.length; i++) {
          extendParamsObj[extendParamsList[i].fieldName] =
            extendParamsList[i].value;
        }
        params.extendParams = extendParamsObj;
      } else {
        this.formTool.extendParams = [];
      }

      params.image = params.imageList[0];
      params.imageList = params.imageList.join();

      // console.log(params);
      const {
        name,
        goodsOneClassId,
        goodsTwoClassId,
        goodsThreeClassId,
        imageList,
        goodsType,
        extendParams,
        businessScopeId,
        specification,
        approvalNumber,
        factory,
        detail,
        image,
        type,
        status,
        origin,
        brand,
        unit
      } = params;
      let paramsData = {
        name,
        goodsOneClassId,
        goodsTwoClassId,
        goodsThreeClassId,
        imageList,
        goodsType,
        businessScopeId,
        specification,
        approvalNumber,
        factory,
        detail,
        image,
        status,
        origin,
        brand,
        unit
      };
      if (this.id != undefined) {
        paramsData.goodsId = this.id;
      }
      paramsData.typeId = goodsType;
      paramsData.goodsType = type;
      console.log(paramsData);
      // compGoodsBaseCorrection
      this.$refs["goods-info"].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "请填写完整信息！",
            type: "warning",
          });
        } else {
          this.$emit("submit-goods", {
            compGoodsBaseCorrection: paramsData,
            extendParams: extendParams,
          });
        }
      });
    },

    // 驳回
    rejectGoods(row) {
      let params = {};
      this.$emit("reject-goods", parmas);
    },

    watchEndit(val) {
      // 监听富文本编辑传过来的数据
      this.formTool.detail = val;
    },

    // 上传图片的字符串转 list
    strToArr(str) {
      let list = [];
      let newStr = str;
      if (newStr.indexOf(",") != -1) {
        let arr = newStr.split(",");
        arr.map((item) => {
          if (item.indexOf("http") != -1) {
            list.push(item);
          }
        });
      } else if (newStr.indexOf("http") != -1) {
        list.push(newStr);
      }
      return list;
    },

    // 上传文件的字符串转 fileList
    strTofileList(fileListStr) {
      console.log(fileListStr);
      let fileList = [];
      let newStr = fileListStr;
      if (newStr && newStr.indexOf(",") != -1) {
        let arr = newStr.split(",");
        arr.map((item) => {
          if (item.indexOf("http") != -1) {
            fileList.push({
              name:
                item.indexOf(item) != -1
                  ? item.substring(item.lastIndexOf("/") + 1)
                  : item,
              url: item,
            });
          }
        });
      } else if (newStr && newStr.indexOf("http") != -1) {
        fileList.push({
          name: newStr.substring(newStr.lastIndexOf("/") + 1),
          url: newStr,
        });
      }
      return fileList;
    },
  },
};
</script>
<style lang="scss" scoped>
.mainView {
  width: 100%;
  display: flex;
}
.comContent {
  padding: 0px;
}
.addGoods {
  width: 100%;
  height: 40px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  .addFont {
    // border: 1px dashed #d9d9d9;
    display: flex;
    width: 18px;
    font-size: 16px;
    justify-content: center;
    margin-right: 5px;
  }
}
.header {
  height: 56px;
  line-height: 56px;
  padding: 0 24px;
  border-bottom: 1px solid #f5f5f5;
}
.product {
  // margin: 0 24px;
  margin-bottom: 24px;
}
.firstTitle {
  font-size: 16px;
  color: #262626;
  font-weight: 700;
}
.secondTitle {
  font-size: 14px;
  // color: #999999;
}
.upload-tips {
  font-size: 12px;
  color: #8c8c8c;
}
.lable-list {
  display: flex;
  justify-content: space-between;
}
.lable-color-correct {
  color: #f00;
}
</style>
