var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "append-to-body": "",
            title: "税务分类编码",
            visible: _vm.dialogEdit,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEdit = $event
            },
          },
        },
        [
          _c("tax-form", {
            on: { closeDialog: _vm.closeDialog, transferTax: _vm.transferTax },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "firstTopic text-left mb20 header" }, [
        _vm._v("商品信息"),
      ]),
      _c(
        "el-form",
        {
          ref: "editForm",
          staticClass: "product",
          attrs: {
            "label-width": "120px",
            model: _vm.formTool,
            rules: _vm.rules,
            size: "small",
          },
        },
        [
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("基本信息")]),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品属性" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formTool.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "type", $$v)
                            },
                            expression: "formTool.type",
                          },
                        },
                        _vm._l(_vm.propertyList, function (item) {
                          return _c(
                            "el-radio",
                            { key: item.value, attrs: { label: item.value } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { staticStyle: { display: "flex" }, attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商品分类",
                        prop: "goodsOneClassId",
                        rules: {
                          required: true,
                          message: "请选择一级分类",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { size: "small", placeholder: "一级分类" },
                          on: { change: _vm.changeOneClassId },
                          model: {
                            value: _vm.formTool.goodsOneClassId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "goodsOneClassId", $$v)
                            },
                            expression: "formTool.goodsOneClassId",
                          },
                        },
                        _vm._l(_vm.goodsOneClassIdOptions, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "0px",
                        prop: "goodsTwoClassId",
                        rules: {
                          required: true,
                          message: "请选择二级分类",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { "margin-left": "8px" },
                          attrs: { size: "small", placeholder: "二级分类" },
                          on: { change: _vm.changeTwoClassId },
                          model: {
                            value: _vm.formTool.goodsTwoClassId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "goodsTwoClassId", $$v)
                            },
                            expression: "formTool.goodsTwoClassId",
                          },
                        },
                        _vm._l(_vm.goodsTwoClassIdOptions, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "0px",
                        prop: "goodsThreeClassId",
                        rules: {
                          required: true,
                          message: "请选择三级分类",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { "margin-left": "8px" },
                          attrs: { size: "small", placeholder: "三级分类" },
                          model: {
                            value: _vm.formTool.goodsThreeClassId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "goodsThreeClassId", $$v)
                            },
                            expression: "formTool.goodsThreeClassId",
                          },
                        },
                        _vm._l(_vm.goodsThreeClassIdOptions, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品名称" },
                        model: {
                          value: _vm.formTool.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "name", $$v)
                          },
                          expression: "formTool.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品简称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品简称" },
                        model: {
                          value: _vm.formTool.shortName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "shortName", $$v)
                          },
                          expression: "formTool.shortName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("详细信息")]),
          _vm.formTool.type == "DRUG"
            ? _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "通用名",
                            prop: "commonName",
                            rules: {
                              required: true,
                              message: "请填写通用名",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入通用名" },
                            model: {
                              value: _vm.formTool.commonName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formTool, "commonName", $$v)
                              },
                              expression: "formTool.commonName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0px!important" },
                      attrs: { label: "图片" },
                    },
                    [
                      _c(
                        "imgUpload",
                        {
                          staticStyle: { position: "relative", top: "-8px" },
                          model: {
                            value: _vm.formTool.imageList,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "imageList", $$v)
                            },
                            expression: "formTool.imageList",
                          },
                        },
                        [
                          _c("template", { slot: "tip" }, [
                            _c(
                              "p",
                              {
                                staticClass: "el-upload__tip",
                                staticStyle: { margin: "0px" },
                              },
                              [
                                _vm._v(
                                  " 备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过5M;第一张图片会作为商品展示图 "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品详情" } },
                    [
                      _c("wangEnduit", {
                        ref: "wangEnduit",
                        attrs: {
                          value: _vm.formTool.details,
                          "is-clear": _vm.isClear,
                          disabled: "",
                        },
                        on: { changeEndit: _vm.watchEndit },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [
            _vm._v("商品价格标签"),
          ]),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "售价前缀" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入售价前缀" },
                        model: {
                          value: _vm.formTool.sellingPricePrefix,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "sellingPricePrefix", $$v)
                          },
                          expression: "formTool.sellingPricePrefix",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "市场价" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formTool.marketPriceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "marketPriceType", $$v)
                            },
                            expression: "formTool.marketPriceType",
                          },
                        },
                        _vm._l(_vm.priceKindList, function (item) {
                          return _c(
                            "el-radio",
                            { key: item.value, attrs: { label: item.value } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "价格标签模式" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formTool.priceTagType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formTool, "priceTagType", $$v)
                            },
                            expression: "formTool.priceTagType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "cash" } }, [
                            _vm._v("现金"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("商品价格")]),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.formTool.skus },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "goodsSkuName",
                  label: "SKU名称",
                  "min-width": "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".goodsSkuName",
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "请填写SKU名称",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changMoney(scope.$index, scope.row)
                                },
                              },
                              model: {
                                value: scope.row.goodsSkuName,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "goodsSkuName", $$v)
                                },
                                expression: "scope.row.goodsSkuName",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsSkuUnit",
                  label: "单位",
                  "min-width": "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { display: "flex" },
                            attrs: {
                              prop: "skus." + scope.$index + ".goodsSkuUnit",
                              rules: {
                                required: true,
                                message: "单位",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "50%" },
                              attrs: {
                                maxlength: "4",
                                disabled: "",
                                placeholder: "单位",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changMoney(scope.$index, scope.row)
                                },
                              },
                              model: {
                                value: scope.row.goodsSkuUnit,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "goodsSkuUnit", $$v)
                                },
                                expression: "scope.row.goodsSkuUnit",
                              },
                            }),
                            _c(
                              "el-link",
                              {
                                staticStyle: { "margin-left": "16px" },
                                attrs: { type: "primary", underline: false },
                                on: {
                                  click: function ($event) {
                                    return _vm.choiceUnit(scope.$index)
                                  },
                                },
                              },
                              [_vm._v("选择单位")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "medicineCode",
                  label: "第三方商品编码",
                  "min-width": "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".medicineCode",
                              rules: {
                                required: true,
                                message: "请填写第三方商品编码",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请填写第三方商品编码" },
                              model: {
                                value: scope.row.medicineCode,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "medicineCode", $$v)
                                },
                                expression: "scope.row.medicineCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "salePrice",
                  label: "零售价/元",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".salePrice",
                              rules: {
                                required: true,
                                message: "请填写零售价",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请填写零售价" },
                              on: {
                                input: function ($event) {
                                  return _vm.changMoney(scope.$index, scope.row)
                                },
                              },
                              model: {
                                value: scope.row.salePrice,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "salePrice", $$v)
                                },
                                expression: "scope.row.salePrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "marketPrice",
                  label: "市场价/元",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".marketPrice",
                              rules: {
                                required: true,
                                message: "请填写市场价",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请填写市场价" },
                              on: {
                                input: function ($event) {
                                  return _vm.changMoney(scope.$index, scope.row)
                                },
                              },
                              model: {
                                value: scope.row.marketPrice,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "marketPrice", $$v)
                                },
                                expression: "scope.row.marketPrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "purchasePrice",
                  label: "批发价/元",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".purchasePrice",
                              rules: {
                                required: true,
                                message: "请填写批发价",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请填写批发价" },
                              on: {
                                input: function ($event) {
                                  return _vm.changMoney(scope.$index, scope.row)
                                },
                              },
                              model: {
                                value: scope.row.purchasePrice,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "purchasePrice", $$v)
                                },
                                expression: "scope.row.purchasePrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "startWholesale",
                  label: "起批量",
                  "min-width": "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".startWholesale",
                              rules: {
                                required: true,
                                message: "请填写起批量",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请填写起批量" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.changMoney(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.startWholesale,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "startWholesale", $$v)
                                    },
                                    expression: "scope.row.startWholesale",
                                  },
                                }),
                                _c(
                                  "el-checkbox",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    model: {
                                      value: scope.row.useTimes,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "useTimes", $$v)
                                      },
                                      expression: "scope.row.useTimes",
                                    },
                                  },
                                  [_vm._v("倍数")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsSkuInventory",
                  label: "库存",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "skus." + scope.$index + ".goodsSkuInventory",
                              rules: {
                                required: true,
                                message: "请填写库存",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请填写库存" },
                              model: {
                                value: scope.row.goodsSkuInventory,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "goodsSkuInventory", $$v)
                                },
                                expression: "scope.row.goodsSkuInventory",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "expireTime", label: "有效期至", width: "210" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".expireTime",
                              rules: {
                                required: true,
                                message: "请填写有效期至",
                                trigger: "blur",
                              },
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                type: "datetime",
                                placeholder: "选择日期",
                              },
                              model: {
                                value: scope.row.expireTime,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "expireTime", $$v)
                                },
                                expression: "scope.row.expireTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "producedTime",
                  label: "生产日期",
                  width: "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "skus." + scope.$index + ".producedTime",
                              "label-width": "0",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "140px" },
                              attrs: {
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: "选择日期",
                              },
                              model: {
                                value: scope.row.producedTime,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "producedTime", $$v)
                                },
                                expression: "scope.row.producedTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  width: "120",
                  align: "left",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.formTool.skus.length != 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  "margin-bottom": "20px",
                                  color: "#F6212D",
                                  "font-size": "14px",
                                },
                                attrs: { type: "text", size: "mini" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteRow(
                                      scope.$index,
                                      _vm.formTool.skus
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 移除 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "addGoods",
              on: {
                click: function ($event) {
                  return _vm.addRow()
                },
              },
            },
            [
              _c("span", { staticClass: "addFont" }, [_vm._v(" + ")]),
              _c(
                "span",
                { staticStyle: { "font-size": "14px", color: "#595959" } },
                [_vm._v(" 添加商品 ")]
              ),
            ]
          ),
          _c("el-divider"),
          _c(
            "div",
            {
              staticClass: "left_topic mb20",
              staticStyle: { "margin-top": "16px" },
            },
            [_vm._v("税务编码")]
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "税务分类编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入税务分类编码" },
                        model: {
                          value: _vm.formTool.taxCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "taxCode", $$v)
                          },
                          expression: "formTool.taxCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.searchTax()
                        },
                      },
                    },
                    [_vm._v("搜索税务分类编码")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "金蝶云编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入金蝶云编码" },
                        model: {
                          value: _vm.formTool.kingdeeCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "kingdeeCode", $$v)
                          },
                          expression: "formTool.kingdeeCode",
                        },
                      }),
                      _c("p", { staticClass: "markedWords mt10" }, [
                        _vm._v(
                          "备注：金蝶云编码仅适用金蝶云用户，非金蝶用户可以不用填写。"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("规格参数")]),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "规格",
                        prop: "specification",
                        rules: {
                          required: true,
                          message: "请填写规格",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入规格" },
                        model: {
                          value: _vm.formTool.specification,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "specification", $$v)
                          },
                          expression: "formTool.specification",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "生产厂家",
                        prop: "factory",
                        rules: {
                          required: true,
                          message: "请填写生产厂家",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入生产厂家" },
                        model: {
                          value: _vm.formTool.factory,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "factory", $$v)
                          },
                          expression: "formTool.factory",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "批准文号",
                        prop: "approvalNumber",
                        rules: {
                          required:
                            _vm.formTool.type == "TCMDP" ||
                            _vm.formTool.type == "OTHER"
                              ? false
                              : true,
                          message: "请填写批准文号",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入批准文号" },
                        model: {
                          value: _vm.formTool.approvalNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "approvalNumber", $$v)
                          },
                          expression: "formTool.approvalNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _vm.formTool.type == "TCMDP"
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "等级", prop: "level" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入等级" },
                            model: {
                              value: _vm.formTool.level,
                              callback: function ($$v) {
                                _vm.$set(_vm.formTool, "level", $$v)
                              },
                              expression: "formTool.level",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _vm.formTool.type == "TCMDP"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产地", prop: "placeOrigin" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入产地" },
                            model: {
                              value: _vm.formTool.placeOrigin,
                              callback: function ($$v) {
                                _vm.$set(_vm.formTool, "placeOrigin", $$v)
                              },
                              expression: "formTool.placeOrigin",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "存储条件",
                        prop: "storageCondition",
                        rules: {
                          required: _vm.formTool.type == "DRUG" ? true : false,
                          message: "请填写存储条件",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入存储条件" },
                        model: {
                          value: _vm.formTool.storageCondition,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "storageCondition", $$v)
                          },
                          expression: "formTool.storageCondition",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _vm.formTool.type == "DRUG"
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "处方类型",
                            prop: "prescriptionType",
                            rules: {
                              required: true,
                              message: "请填写处方类型",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入处方类型" },
                            model: {
                              value: _vm.formTool.prescriptionType,
                              callback: function ($$v) {
                                _vm.$set(_vm.formTool, "prescriptionType", $$v)
                              },
                              expression: "formTool.prescriptionType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _vm.formTool.type == "DRUG"
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "剂型",
                            prop: "dosageForm",
                            rules: {
                              required: true,
                              message: "请填写剂型",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入剂型" },
                            model: {
                              value: _vm.formTool.dosageForm,
                              callback: function ($$v) {
                                _vm.$set(_vm.formTool, "dosageForm", $$v)
                              },
                              expression: "formTool.dosageForm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "中包装",
                        prop: "middlePack",
                        rules: {
                          required: true,
                          message: "请填写中包装",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入中包装" },
                        model: {
                          value: _vm.formTool.middlePack,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "middlePack", $$v)
                          },
                          expression: "formTool.middlePack",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "大包装",
                        prop: "bigPack",
                        rules: {
                          required: true,
                          message: "请填写大包装",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入大包装" },
                        model: {
                          value: _vm.formTool.bigPack,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "bigPack", $$v)
                          },
                          expression: "formTool.bigPack",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商品条形码",
                        prop: "barCode",
                        rules: {
                          required: true,
                          message: "请填写商品条形码",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品条形码" },
                        model: {
                          value: _vm.formTool.barCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "barCode", $$v)
                          },
                          expression: "formTool.barCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("p", { staticClass: "markedWords mt10" }, [
            _vm._v(
              "温馨提示：部分商品包装更换频繁，如货品与图片不一致，请以收到的商品实物为准。如页面存在有效期信息，为库存产品最近有效期，实际产品有效期以到货产品有效期为准。如发现商品存在质量问题，请拨打药潺潺库质量投诉专线电话：400-888-6688"
            ),
          ]),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("溯源信息")]),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0px !important" },
                      attrs: {
                        label: "码上放心追溯码",
                        "label-width": "120px",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入码上放心追溯码" },
                        model: {
                          value: _vm.formTool.barCodeRetrospection,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "barCodeRetrospection", $$v)
                          },
                          expression: "formTool.barCodeRetrospection",
                        },
                      }),
                      _c("imgUpload", {
                        attrs: { limit: 1 },
                        model: {
                          value: _vm.formTool.barCodeImage,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTool, "barCodeImage", $$v)
                          },
                          expression: "formTool.barCodeImage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("服务与承诺")]),
          _c(
            "div",
            { staticClass: "pl20" },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.formTool.servicePromise,
                    callback: function ($$v) {
                      _vm.$set(_vm.formTool, "servicePromise", $$v)
                    },
                    expression: "formTool.servicePromise",
                  },
                },
                [
                  _c("el-checkbox", { attrs: { label: "exemption_postage" } }, [
                    _vm._v("直邮包邮"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "lightning" } }, [
                    _vm._v("闪电配送"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "ticket_sale" } }, [
                    _vm._v("带票销售"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("服务说明")]),
          _c("div", { staticClass: "pl20" }, [
            _c("div", { staticClass: "firstTitle mb10" }, [
              _vm._v("商品价格说明"),
            ]),
            _c("div", { staticClass: "secondTitle mb20" }, [
              _vm._v(
                "促销活动、商品进价调整、市场环境等因素均会影响商品售价，最终以订单结算页价格为准，购买前请仔细核对"
              ),
            ]),
            _c("div", { staticClass: "firstTitle mb10" }, [
              _vm._v("产品包装说明"),
            ]),
            _c("div", { staticClass: "secondTitle mb20" }, [
              _vm._v(
                "厂家药品批次存在更新，涉及内外包装、说明书等，以到货实物为准，如有疑问，可点击纠错按钮或联系客服"
              ),
            ]),
            _c("div", { staticClass: "firstTitle mb10" }, [
              _vm._v("商品购买权限说明"),
            ]),
            _c("div", { staticClass: "secondTitle mb20" }, [
              _vm._v(
                "内容由于药品特殊性，部分商品提示暂无购买权限，属于正常情况，具体以购买页面为准"
              ),
            ]),
          ]),
          _c("el-divider"),
          _c("div", { staticClass: "left_topic mb20" }, [_vm._v("配送说明")]),
          _c("div", { staticClass: "secondTitle pl20" }, [
            _vm._v("下单成功后，商家立即安排配送。"),
          ]),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "pl20", staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "160px" },
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "160px" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("UnitDialog", {
        attrs: {
          "unit-list": _vm.unitList,
          "dialog-visible": _vm.dialogVisible,
        },
        on: { submitCheck: _vm.submitCheck },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }