<template>
  <el-dialog :title="title" :visible.sync="visible" :lock-scroll="true" width="718px" :before-close="handleClose"
    append-to-body>
    <div class="">
      <div style="padding: 24px 0;">
        <el-form ref="form-data" :model="form" :inline="false" label-width="70px" label-position="right" :rules="rules"
          style="text-align:left;">

          <el-form-item prop="fileList" label="打款凭证">
            <imgUpload v-model="form.imageList" :isEdit="true" :limit="3" style="position:relative;top:-8px">
            </imgUpload>
          </el-form-item>

          <el-form-item prop="" label="驳回原因" v-if="form.paymentStatus == 3">
            <el-input v-model.trim="form.remark" placeholder="" clearable size="auto" type="textarea" :disabled="true" />
          </el-form-item>

          <div class="footer-btn">
            <div v-if="form.paymentStatus == 1" style="color:rgb(255, 153, 0)">
              凭证待审核
            </div>
            <div v-if="form.paymentStatus == 2" style="color:green">
              凭证已确认
            </div>
            <div v-if="form.paymentStatus == 3" style="color:#F5222D">
              凭证已驳回
            </div>
            <div>
              <el-button size="small" type="primary" @click="resetSubmit()" v-if="form.paymentStatus == 3">重新提交</el-button>
              <el-button size="small" type="plain" @click="handleClose()">返回</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>
    
<script>
import fileUpload from "@/components/fileUpload";
import avater from "/public/img/default.png";
import imgUpload from '@/components/imgUpload';
import { payvoucherinfoGetinfo } from "@/api/offlinePayment";

export default {
  props: {},
  components: { imgUpload, fileUpload },

  data() {
    return {
      avater: avater,
      disabled: false,
      id: '',
      pageType: '',
      isDisabled: false,
      form: {
        remark: ''
      },
      rules: {
      },
      title: '',
      visible: false,
    }
  },
  created() {

  },
  mounted() {

  },
  watch: {},
  methods: {
    resetSubmit() {
      // 重新提交
      this.$emit("resetSubmit", {id:this.orderId, accountName: this.accountName, depositBank: this.depositBank, receivablesAccount: this.receivablesAccount });
      this.visible = false;
    },

    handleClose() {
      this.visible = false;
      this.$emit("handleClose")
    },

    init(row, type, pageType) {
      this.accountName = row.accountName;  //收款户名
      this.depositBank = row.depositBank; //开户银行
      this.receivablesAccount = row.receivablesAccount;  //收款账号 

      this.form.remark = "";
      this.pageType = pageType;
      this.form.imageList = [];
      if (type == 'detail') {
        this.title = '查看凭证';
      } else {
        this.title = '提交凭证';
      }
      // console.log(row, '--row')
      let params = {};
      params.orderId = row.id;
      this.orderId = row.id;
      if (pageType == 'afterSale') {
        params.voucherType = 3; //售后 
        this.voucherType = 3;
      }
      if (pageType == 'sale') {
        params.voucherType = 2; //订单 
        this.voucherType = 2;
      }
      payvoucherinfoGetinfo(params).then((res) => {
        if (res) {
          this.form = res;
          if (res.voucherUrl) {
            this.form.imageList = res.voucherUrl.split(',')
          }
        }
      }).catch((err) => { });
      this.visible = true;
    },
  }
}

</script>
<style lang="scss" scoped>
.eachRow {
  background-color: #fff;
  border-radius: 4px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
}

.titleView {
  display: flex;
  align-items: center;

  .rowLine {
    width: 4px;
    border-color: 1px;
    background-color: var(--main-color);
    height: 16px;
    margin-right: 8px;
  }

  .eachRowTitle {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
  }

  .cpxhead {
    display: flex;
    align-items: center;
  }

  .mark {
    color: #f56c6c;
    font-size: 12px;
    margin-left: 10px;
    width: 200px;
    line-height: 1.5;
    display: inline-block;
    font-weight: bold;
  }
}

.goods-list-warp {
  width: 100%;
  margin-left: 100px;
  max-height: 500px;
  overflow-y: auto;
}

.goods-list {
  width: 50%;
  border-bottom: solid 1px #f5f5f5;
  padding-bottom: 10px;

  &:nth-last-child(1) {
    border-bottom: none;
  }
}

.footer-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
  
  