var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          height: "70px",
        },
      },
      [
        _c("div", { staticClass: "eachStep" }, [
          _c("img", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: { src: require("../../../assets/payStep1.png"), alt: "" },
          }),
          _c(
            "div",
            { staticClass: "eachTitle", staticStyle: { color: "#F5222D" } },
            [_vm._v("1. 确定订单信息")]
          ),
        ]),
        _c("div", { staticClass: "eachStep" }, [
          _c("img", {
            staticStyle: { width: "400px", heihgt: "32px" },
            attrs: { src: require("../../../assets/payStep2.png"), alt: "" },
          }),
          _c(
            "span",
            { staticClass: "eachTitle", staticStyle: { color: "#ffffff" } },
            [_vm._v("2. 付款给供应商")]
          ),
        ]),
        _c("div", { staticClass: "eachStep" }, [
          _c("img", {
            staticStyle: { width: "400px", heihgt: "32px" },
            attrs: { src: require("../../../assets/payStep3.png"), alt: "" },
          }),
          _c(
            "span",
            { staticClass: "eachTitle", staticStyle: { color: "#262626" } },
            [_vm._v("3. 确定收货")]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }